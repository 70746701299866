import { MouseEvent } from "react";
import { AgEventAPI } from "@ai360/core";

export interface AgBytesItem {
    activeYn: boolean;
    fullName: string;
    guid: string;
    id: string;
    name: string;
    parentGuid: string;
    physicalStateId: string;
}

export interface IObservationData {
    activeYn: boolean;
    categoryIndex: number;
    categoryName: string;
    guid: string;
    name: string;
    useOnly: boolean;
}

export interface IPicklistOption {
    label: string;
    value: string;
}

export interface IThumbnailItem {
    guid: string | null;
    scoutingPhoto?: AgEventAPI.IScoutingDetailPhoto | AgEventAPI.IScoutingSitePhoto | null;
}

export class ThumbnailItem implements IThumbnailItem {
    constructor(
        public guid: string | null,
        public scoutingPhoto?:
            | AgEventAPI.IScoutingDetailPhoto
            | AgEventAPI.IScoutingSitePhoto
            | null
    ) {}
}

export interface IAddPhotoLinkItemProps {
    formatMessage: (msgObj: Record<string, any>) => string;
    index: number;
    messages: Record<string, any>;
    onAdd: (fileObject: Record<string, any>) => void;
    itemCount: number;
}

export interface IThumbnailCardProps {
    onClick: (e: MouseEvent) => void;
    thumbnailItem: IThumbnailItem;
}
