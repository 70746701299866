import { connect } from "react-redux";
import { IrrigationEventForm } from "./event-irrigation-form";
import {
    actions as picklistActions,
    selectors as picklistSelectors,
    picklistNames,
} from "~/core/picklist";
import { actions as unitActions, selectors as unitSelectors, unitNames } from "~/core/units";
import { getFieldInfoModel } from "../../../../../field-module/selectors";

import { actions as recsEventsActions } from "~/recs-events";
import { messages } from "./i18n-messages";
import { IrrigationIcon } from "../icons";

const mapStateToProps = (state) => ({
    irrigationType: picklistSelectors
        .getRawPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_IRRIGATION_TYPE)
        )
        .map((item) => ({
            label: item.value,
            value: item.picklistValueGuid,
            id: item.id,
        })),
    irrigationUnit: unitSelectors.getUnitPicklistOptionsFromCode(
        state,
        unitNames.getUnitCode(unitNames.UNIT_IRRIGATION_VOLUME)
    ),
    fieldModel: getFieldInfoModel(state),
    unitTime: unitSelectors.getUnitPicklistOptionsFromCode(
        state,
        unitNames.getUnitCode(unitNames.UNIT_TIME)
    ),
    unitLength: unitSelectors.getUnitPicklistOptionsFromCode(
        state,
        unitNames.getUnitCode(unitNames.UNIT_LENGTH)
    ),
});

const mapDispatchToProps = (dispatch) => ({
    fetchPicklistData: (payload) => dispatch(picklistActions.fetchPicklistData(payload)),
    fetchUnitData: (payload) => dispatch(unitActions.fetchUnitData(payload)),
    onUpdateCurrentAgEventAreaAgEventModel: (fieldGuid, agEventTransactionTypeGuid, newProps) =>
        dispatch(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                fieldGuid,
                agEventTransactionTypeGuid,
                newProps
            )
        ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onUpdateCurrentAgEventAreaAgEventModel: (newProps) =>
            dispatchProps.onUpdateCurrentAgEventAreaAgEventModel(
                ownProps.fieldGuid,
                ownProps.agEventTransactionTypeGuid,
                newProps
            ),
    };
};

export const formLabelMessage = messages.irrigationFormLabelText;
export const formLabelIcon = IrrigationIcon;

export const EventIrrigationForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(IrrigationEventForm);
