import { MergeStatus } from "~/admin/setup/report-books/data/interfaces";
import * as actions from "./actions";
import { ReducerAction } from "./actions";

const initialState = {
    reportBooks: [],
};
export const SETUP_REPORT_BOOKS_DATA_KEY = "SETUP_REPORT_BOOKS_DATA";

export function setupReportBooksReducer(
    state = initialState,
    action: ReducerAction = {
        filteredLocationList: null,
        loadingModal: null,
        loadingOrgLevelGuids: null,
        loadingReportBookReports: null,
        loadingReportBooks: null,
        reportBookOrgLevelGuids: null,
        mergeStatus: MergeStatus.pending,
        reportBookReports: null,
        reportBooks: null,
        reportBookOptionErrors: [],
        reportBookNames: null,
        reportOptions: [],
        reportTypes: null,
        selectedReportBookTypeGuid: null,
        selectedReportOptionGuid: null,
        soilTypeAttributes: null,
        type: null,
    }
): Record<string, unknown> {
    switch (action.type) {
        case actions.SET_FILTERED_LOCATION_LIST:
            return Object.freeze({
                ...state,
                filteredLocationList: action.filteredLocationList,
            });
        case actions.SET_LOADING_MODAL:
            return Object.freeze({
                ...state,
                loadingModal: action.loadingModal,
            });
        case actions.SET_LOADING_ORG_LEVEL_GUIDS:
            return Object.freeze({
                ...state,
                loadingOrgLevelGuids: action.loadingOrgLevelGuids,
            });
        case actions.SET_LOADING_REPORT_BOOK_REPORTS:
            return Object.freeze({
                ...state,
                loadingReportBookReports: action.loadingReportBookReports,
            });
        case actions.SET_LOADING_REPORT_BOOKS:
            return Object.freeze({
                ...state,
                loadingReportBooks: action.loadingReportBooks,
            });
        case actions.SET_MERGE_STATUS:
            return Object.freeze({
                ...state,
                mergeStatus: action.mergeStatus,
            });
        case actions.SET_REPORT_BOOK_ORG_LEVEL_GUIDS:
            return Object.freeze({
                ...state,
                reportBookOrgLevelGuids: action.reportBookOrgLevelGuids,
            });
        case actions.SET_REPORT_BOOK_REPORTS:
            return Object.freeze({
                ...state,
                reportBookReports: action.reportBookReports,
            });
        case actions.SET_REPORT_BOOK_TYPE_NAMES:
            return Object.freeze({
                ...state,
                reportBookNames: action.reportBookNames,
            });
        case actions.SET_REPORT_BOOK_TYPES:
            return Object.freeze({
                ...state,
                reportBooks: action.reportBooks,
            });
        case actions.SET_SOIL_TYPE_ATTRIBUTES:
            return Object.freeze({
                ...state,
                soilTypeAttributes: action.soilTypeAttributes,
            });
        case actions.SET_REPORT_OPTIONS:
            return Object.freeze({
                ...state,
                reportOptions: action.reportOptions,
            });
        case actions.SET_REPORT_OPTION_ERRORS:
            return Object.freeze({
                ...state,
                reportBookOptionErrors: action.reportBookOptionErrors,
            });
        case actions.SET_REPORT_TYPES:
            return Object.freeze({
                ...state,
                reportTypes: action.reportTypes,
            });
        case actions.SET_SELECTED_REPORT_BOOK_TYPE_GUID:
            return Object.freeze({
                ...state,
                selectedReportBookTypeGuid: action.selectedReportBookTypeGuid,
            });
        case actions.SET_SELECTED_REPORT_OPTION_GUID:
            return Object.freeze({
                ...state,
                selectedReportOptionGuid: action.selectedReportOptionGuid,
            });
        default:
            return state;
    }
}
