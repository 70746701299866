import React, { Component } from "react";
import { model } from "../data";
import { mapNameToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import { messages } from "../../i18n-messages";
import { InjectedIntlProps } from "react-intl";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
// Components
import { DialogBox, DialogBoxFooterType, NoLink, SelectInput, ZeroToInfiniteGrid } from "~/core";
import { withEditableGrid } from "~/hocs";
import { adminData, GUID, ID, NAME, VALUE } from "~/admin/data";
// Constants
// Types
import { ITraitGeneState, ITraitGeneProps } from "../interfaces";

class TraitGene extends Component<ITraitGeneProps & InjectedIntlProps, ITraitGeneState> {
    constructor(props) {
        super(props);
        this.state = {
            editOptions: [],
            editingItem: false,
        };
    }

    initializeDropdowns = (options) => {
        this.setState({
            editOptions: prepareSelectableOptions(options, {
                guid: GUID,
                label: NAME,
                id: ID,
            }),
        });
    };

    initializeTraitGeneData = ({ record = [], options }) => {
        if (record) {
            return record.map((recordItem) => {
                const selectedTraitGeneName = options.find((traitGeneItem) => {
                    return traitGeneItem.name === recordItem[model.PROPS_GENE_NAME];
                });
                recordItem[model.PROPS_GENE_NAME] =
                    selectedTraitGeneName && selectedTraitGeneName[NAME];
                return recordItem;
            });
        }
        return record;
    };

    onEdit = (record, index) => {
        this.initializeDropdowns(this.props.options);
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [model.PROPS_GENE_NAME],
                key: VALUE,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.initializeDropdowns(this.props.options);
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record && record.length > 0) {
            if (nextProps.options) {
                this.initializeTraitGeneData(nextProps);
            }
        }
    }

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, toggleModal } = this.props;
        return (
            <DialogBox
                action="save"
                actionDisabled={!editData[model.PROPS_GENE_NAME]}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.traitGene
                )}
            >
                <SelectInput
                    required
                    tabIndex={0}
                    autoFocus
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    openOnFocus={false}
                    options={this.state.editOptions}
                    placeholderText={formatMessage(messages.geneName)}
                    value={mapNameToPicklistValue({
                        options: this.state.editOptions,
                        selectedName: editData[model.PROPS_GENE_NAME],
                    })}
                    onChange={(value) => {
                        this.props.onChange({
                            [model.PROPS_GENE_NAME]: value ? value.name : "",
                        });
                    }}
                />
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [model.PROPS_GENE_NAME]: {
                                title: formatMessage(messages.name),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.traitGene)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(TraitGene);
