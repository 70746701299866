import * as model from "./model";
import { createService } from "~/hocs/crud";
import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
import {
    CropAPI,
    GrowthStageOrderAPI,
    HierarchyAPI,
    ImportAttributeAPI,
    NutrientTargetValueAPI,
} from "@ai360/core";
import {
    PICKLIST_CROP_PURPOSE,
    PICKLIST_PLANT_PART,
    PICKLIST_SAMPLE_TYPE,
    PICKLIST_IRRIGATION_CATEGORY,
    PICKLIST_WATER_PURPOSE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { UNIT_CROP_PRICE, getUnitCode } from "~/core/units/unit-names";

export const MODEL_NAME = "targetValue";
export const EXPORT_FILE_NAME = "NutrientTargetValueExport";

export const AUTO_SEARCH_URL = NutrientTargetValueAPI.AUTO_SEARCH_URL;
export const CREATE = NutrientTargetValueAPI.CREATE;
export const DELETE = NutrientTargetValueAPI.DELETE;
export const EXPORT_URL = NutrientTargetValueAPI.EXPORT_URL;
export const GETRECORD = NutrientTargetValueAPI.GETRECORD;
export const IMPORT_URL = NutrientTargetValueAPI.IMPORT_URL;
export const IMPORT_VALID_URL = NutrientTargetValueAPI.IMPORT_VALID_URL;
export const NEXT_ID = NutrientTargetValueAPI.NEXT_ID;
export const SELECT_ALL = NutrientTargetValueAPI.SELECT_ALL;
export const UPDATE = NutrientTargetValueAPI.UPDATE;
export const URL = NutrientTargetValueAPI.URL;

// Request payload
export const REQUEST_PAYLOAD_FILTER = "targetValueFilter";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "targetValuePageOptions";
export const REQUEST_PAYLOAD_SORT_LIST = "targetValueSortList";

//Dropdowns
export const REQUEST_CROP = CropAPI.REQUEST_CROP;
export const REQUEST_CROP_CLASS = CropAPI.REQUEST_CROP_CLASS;
export const REQUEST_GROWTH_STAGE_ORDER = GrowthStageOrderAPI.REQUEST_TISSUE_GROWTH_STAGE_ORDER;
export const REQUEST_IMPORT_ATTRIBUTE = ImportAttributeAPI.REQUEST_IMPORT_ATTRIBUTE;
export const REQUEST_ORG_LEVEL = HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS_GUID;

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        activeYn: "",
        attributeName: "",
        cropClassName: "",
        cropName: "",
        locationLevel: "",
        modifiedDate: "",
        sampleTypeName: "",
        targetValue: "",
        targetValueId: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "SampleTypeName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultNutrientTargetValue = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_ATTRIBUTE_NAME]: "",
    [model.PROPS_CAN_DELETE]: "",
    [model.PROPS_CROPS_CLASS_NAME]: "",
    [model.PROPS_CROP_LIST]: [],
    [model.PROPS_CROP_NAME]: "",
    [model.PROPS_IMPORT_ATTRIBUTE_LIST]: [],
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_NUTRIENT_TARGET_VALUE]: "",
    [model.PROPS_NUTRIENT_TARGET_VALUE_GUID]: "",
    [model.PROPS_NUTRIENT_TARGET_VALUE_ID]: "",
    [model.PROPS_ORG_LEVEL_LIST]: [],
    [model.PROPS_ORG_LEVEL_NAME]: "",
    [model.PROPS_PLANT_PART_LIST]: [],
    [model.PROPS_SAMPLE_TYPE_GUID]: "",
    [model.PROPS_SAMPLE_TYPE_NAME]: "",
    [model.PROPS_IRRIGATION_CATEGORY_GUID]: "",
    [model.PROPS_IRRIGATION_CATEGORY]: "",
    [model.PROPS_WATER_PURPOSE_GUID]: "",
    [model.PROPS_WATER_PURPOSE]: "",
});

export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
    [PICKLIST_SAMPLE_TYPE]: getPickListCode(PICKLIST_SAMPLE_TYPE),
    [PICKLIST_PLANT_PART]: getPickListCode(PICKLIST_PLANT_PART),
    [PICKLIST_IRRIGATION_CATEGORY]: getPickListCode(PICKLIST_IRRIGATION_CATEGORY),
    [PICKLIST_WATER_PURPOSE]: getPickListCode(PICKLIST_WATER_PURPOSE),
};

export const unitTypes = {
    [UNIT_CROP_PRICE]: getUnitCode(UNIT_CROP_PRICE),
};

export const dropdowns = {
    [model.PROPS_CROP_NAME]: REQUEST_CROP,
    [model.PROPS_CROP_CLASS_NAME]: {
        url: REQUEST_CROP_CLASS,
        model: "00000000-0000-0000-0000-000000000000",
    },
    [model.PROPS_IMPORT_ATTRIBUTE]: REQUEST_IMPORT_ATTRIBUTE,
    [model.PROPS_ORG_LEVEL]: { url: REQUEST_ORG_LEVEL, model: "_" },
};

export const service = createService({
    id: model.PROPS_NUTRIENT_TARGET_VALUE_ID,
    guid: model.PROPS_NUTRIENT_TARGET_VALUE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    dropdowns,
    unitTypes,
    urls: {
        AUTO_SEARCH_URL,
        CREATE,
        DELETE,
        EXPORT_URL,
        GETRECORD,
        IMPORT_URL,
        IMPORT_VALID_URL,
        NEXT_ID,
        SELECT_ALL,
        UPDATE,
        URL,
    },
    _defaultLabels: {
        [model.PROPS_NUTRIENT_TARGET_VALUE_ID]: {
            label: "nutrientTargetValueId",
            gridCol: 5,
        },
        [model.PROPS_SAMPLE_TYPE_NAME]: {
            label: "sampleTypeName",
            gridCol: 10,
        },
        [model.PROPS_ORG_LEVEL_NAME]: {
            label: "orgLevelName",
            gridCol: 10,
            sortNameOverRide: "locationLevel",
        },
        [model.PROPS_ATTRIBUTE_NAME]: { label: "attributeName", gridCol: 10 },
        [model.PROPS_NUTRIENT_TARGET_VALUE]: {
            label: "nutrientTargetValue",
            gridCol: 5,
        },
        [model.PROPS_CROP_NAME]: { label: "cropName", gridCol: 10 },
        [model.PROPS_CROPS_CLASS_NAME]: { label: "cropClassName", gridCol: 10 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
    },
    getDefaultRecord: () => Object.assign({}, defaultNutrientTargetValue()),
    defaultSort,
});
