import * as model from "./model";

const productPackageListDefaultRecord = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_AGGATEWAY_PACKAGE_ID]: "",
    [model.PROPS_AGRIAN_PACKAGE_ID]: "",
    [model.PROPS_CAN_DELETE]: false,
    [model.PROPS_DOT_SHIPPING_DESCRIPTION]: "",
    [model.PROPS_LONG_DESCRIPTION]: "",
    [model.PROPS_MFR_PART_NUMBER]: "",
    [model.PROPS_PACKAGE_SIZE]: "",
    [model.PROPS_PACKAGE_UNIT_ABBREVIATION]: "",
    [model.PROPS_PACKAGE_UNIT_GUID]: "",
    [model.PROPS_PACKAGE_UNIT_NAME]: "",
    [model.PROPS_PRODUCT_GUID]: "",
    [model.PROPS_PRODUCT_ID]: "",
    [model.PROPS_PRODUCT_NAME]: "",
    [model.PROPS_PRODUCT_PACKAGE_BACKOFFICE_PRODUCT_CODES]: [],
    [model.PROPS_PRODUCT_PACKAGE_GTINS]: [],
    [model.PROPS_PRODUCT_PACKAGE_GUID]: "",
    [model.PROPS_PRODUCT_PACKAGE_ID]: null,
    [model.PROPS_SEED_COUNT]: "",
    [model.PROPS_SEED_COUNT_UNIT_ABBREVIATION]: "",
    [model.PROPS_SEED_COUNT_UNIT_GUID]: "",
    [model.PROPS_SEED_COUNT_UNIT_NAME]: "",
    [model.PROPS_SEED_SIZE]: "",
    [model.PROPS_UPC_CODE]: "",
});

export default productPackageListDefaultRecord;
