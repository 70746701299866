import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
    fetchAttributes,
    fetchAttributesSuccess,
    fetchAttributesFailed,
    fetchTreeAttributes,
    fetchTreeAttributesFailed,
    fetchTreeAttributesSuccess,
    saveAssignment,
    saveAssignmentSuccess,
    saveAssignmentFailed,
    saveAssignmentAttribute,
    saveAssignmentAttributeSuccess,
    saveAssignmentAttributeFailed,
    fetchLocationHierarchy,
    fetchLocationHierarchySuccess,
    fetchLocationHierarchyFailed,
    fetchLocationHierarchyEntities,
    fetchLocationHierarchyEntitiesSuccess,
    fetchLocationHierarchyEntitiesFailed,
    requestSearchString,
    requestSearchStringSuccess,
    requestSearchStringFailed,
    fetchEntities,
    fetchEntitiesSuccess,
    fetchEntitiesFailed,
    fetchUserCompanyList,
    fetchUserCompanyListSuccess,
    setCompanyUserPreference,
} from "./actions";
import { Request } from "@ai360/core";
import { ZapperAPI, UserAPI } from "@ai360/core";
// selectors
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { messages } from "../../i18n-messages";

export function* fetchLocationHierarchyRequest({ payload }) {
    const { searchedVal, companyUserGuid } = payload;
    const UserGuid = companyUserGuid || (yield select(getTheUserGuid));
    const requestOptions = {
        UserGuid,
        Model: searchedVal,
        LoggedInUserGuid: companyUserGuid,
    };
    try {
        const response = yield call(
            Request.post,
            ZapperAPI.FETCH_LOCATION_HIERARCHY_URL,
            requestOptions
        );
        if (response) {
            yield put(fetchLocationHierarchySuccess({ response }));
        } else {
            yield put(fetchLocationHierarchyFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${
                fetchLocationHierarchyEntities().type
            } with payload ${JSON.stringify(requestOptions)}`,
            error
        );
        yield put(fetchLocationHierarchyFailed(error));
    }
}

export function* watchFetchLocationHierarchy() {
    yield takeEvery(fetchLocationHierarchy, fetchLocationHierarchyRequest);
}

export function* fetchLocationHierarchyEntityRequest({ payload }) {
    const UserGuid = payload.loggedInUserGuid || (yield select(getTheUserGuid));
    const requestOptions = { UserGuid, Model: payload.model };
    try {
        const response = yield call(
            Request.post,
            ZapperAPI.FETCH_LOCATION_HIERARCHY_ENTITIES_URL,
            requestOptions
        );
        if (response) {
            yield put(
                fetchLocationHierarchyEntitiesSuccess({
                    response: response.filter((x) => x.activeYN),
                })
            );
        } else {
            yield put(fetchLocationHierarchyEntitiesFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${
                fetchLocationHierarchyEntities().type
            } with payload ${JSON.stringify(requestOptions)}`,
            error
        );
        yield put(fetchLocationHierarchyEntitiesFailed(error));
    }
}

export function* watchFetchLocationEntityHierarchy() {
    yield takeEvery(fetchLocationHierarchyEntities, fetchLocationHierarchyEntityRequest);
}

export function* saveAssignmentRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        yield call(Request.post, ZapperAPI.SAVE_ASSIGNMENT_URL, requestOptions);
        yield put(saveAssignmentSuccess());
        yield put(
            notificationActions.pushToasterMessage(
                messages.saveBatchZapperSuccessful,
                MSGTYPE.SUCCESS
            )
        );
    } catch (error) {
        console.error(
            `Failed to process action ${saveAssignment().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(saveAssignmentFailed(error));
        yield put(
            notificationActions.pushToasterMessage(messages.saveBatchZapperFailed, MSGTYPE.ERROR)
        );
    }
}

export function* watchSaveAssignment() {
    yield takeEvery(saveAssignment, saveAssignmentRequest);
}

export function* watchSaveAssignmentAttribute() {
    yield takeEvery(saveAssignmentAttribute, saveAssignmentAttributeRequest);
}

export function* saveAssignmentAttributeRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        yield call(Request.post, ZapperAPI.SAVE_ASSIGNMENTS_ENTITIES_TAB_URL, requestOptions);
        yield put(saveAssignmentAttributeSuccess());
        yield put(
            notificationActions.pushToasterMessage(
                messages.saveBatchZapperSuccessful,
                MSGTYPE.SUCCESS
            )
        );
    } catch (error) {
        console.error(
            `Failed to process action ${saveAssignment().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(saveAssignmentAttributeFailed(error));
        yield put(
            notificationActions.pushToasterMessage(messages.saveBatchZapperFailed, MSGTYPE.ERROR)
        );
    }
}

export function* fetchAttributesRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(Request.post, ZapperAPI.FETCH_ATTRIBUTES_URL, requestOptions);
        if (response) {
            yield put(fetchAttributesSuccess({ response }));
        } else {
            yield put(fetchAttributesFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${fetchAttributes().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(fetchAttributesFailed(error));
    }
}

export function* watchFetchAttributes() {
    yield takeEvery(fetchAttributes, fetchAttributesRequest);
}

export function* fetchAttributesTreeRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };

    try {
        const response = yield call(
            Request.post,
            ZapperAPI.FETCH_ATTRIBUTES_TREE_URL,
            requestOptions
        );
        if (response) {
            yield put(fetchTreeAttributesSuccess({ response }));
        } else {
            yield put(fetchTreeAttributesFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${fetchTreeAttributes().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(fetchTreeAttributesFailed(error));
    }
}

export function* watchFetchAttributesTree() {
    yield takeEvery(fetchTreeAttributes, fetchAttributesTreeRequest);
}

export function* processSearchStringRequest({ payload }) {
    const { searchString, url, modelName, companyUserGuid } = payload;
    const UserGuid = companyUserGuid || (yield select(getTheUserGuid));
    const requestOptions = { UserGuid, Model: searchString };
    try {
        const response = yield call(Request.post, url, requestOptions);
        if (response) {
            yield put(requestSearchStringSuccess({ [modelName]: response }));
        } else {
            yield put(requestSearchStringFailed({ [modelName]: "No Data" }));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${
                requestSearchStringFailed().type
            } with payload ${JSON.stringify(requestOptions)}`,
            error
        );
        yield put(requestSearchStringFailed(error));
    }
}

export function* watchAutoSearchRequest() {
    yield takeEvery(requestSearchString, processSearchStringRequest);
}

export function* fetchEntitiesRequest({ payload }) {
    const { data, url, modelName } = payload;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: data };
    try {
        const response = yield call(Request.post, url, requestOptions);
        if (response) {
            yield put(
                fetchEntitiesSuccess({
                    [modelName]: response.filter((x) => x.activeYN),
                })
            );
        } else {
            yield put(fetchEntitiesFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${fetchEntities().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(fetchEntitiesFailed(error));
    }
}

export function* watchFetchEntity() {
    yield takeEvery(fetchEntities, fetchEntitiesRequest);
}

function* processFetchUserCompanyList(action) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid };
    let lastUsedOwner = {};
    try {
        const userCompanyList = yield call(
            Request.post,
            ZapperAPI.GET_USER_COMPANY_LIST,
            requestOptions
        );
        const lastUsedOwnerObj = yield call(
            UserAPI.getUserPreferenceByKey,
            UserGuid,
            UserAPI.UserPreferenceKeys.batchZapperLastUsedOwnerGuid
        );
        const selectedCompany = userCompanyList.find(
            (item) => item.companyGuid === lastUsedOwnerObj.value
        );
        if (selectedCompany) {
            lastUsedOwner = {
                companyGuid: selectedCompany.companyGuid,
                userGuid: selectedCompany.userGuid,
            };
        }
        yield put(
            fetchUserCompanyListSuccess({
                userCompanyList,
                lastUsedOwner,
            })
        );
    } catch (error) {
        yield put(
            notificationActions.apiCallError(error, action, messages.fetchUserCompanyListError)
        );
    }
}

export function* watchFetchUserCompanyList() {
    yield takeEvery(fetchUserCompanyList, processFetchUserCompanyList);
}

function* processSetCompanyUserPreference(action) {
    const { payload } = action;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(
            UserAPI.saveUserPreference,
            userGuid,
            UserAPI.UserPreferenceKeys.batchZapperLastUsedOwnerGuid,
            payload
        );
    } catch (error) {
        yield put(
            notificationActions.apiCallError(error, action, messages.setCompanyUserPreferenceError)
        );
    }
}

export function* watchSetCompanyUserPreference() {
    yield takeEvery(setCompanyUserPreference, processSetCompanyUserPreference);
}

const BatchZapperSagas = function* () {
    yield all([
        fork(watchFetchAttributes),
        fork(watchFetchAttributesTree),
        fork(watchSaveAssignment),
        fork(watchSaveAssignmentAttribute),
        fork(watchFetchLocationHierarchy),
        fork(watchFetchLocationEntityHierarchy),
        fork(watchAutoSearchRequest),
        fork(watchFetchEntity),
        fork(watchFetchUserCompanyList),
        fork(watchSetCompanyUserPreference),
    ]);
};

export default BatchZapperSagas;
