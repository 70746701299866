import { defineMessages } from "react-intl";

export const messages = defineMessages({
    adf: { id: "eventInfo.harvestEvent.adf", defaultMessage: "ADF (%)" },
    amount: {
        id: "eventInfo.harvestEvent.amount",
        defaultMessage: "Amount ($)",
    },
    averageMoisture: {
        id: "eventInfo.harvestEvent.averageMoisture",
        defaultMessage: "Avg Moisture (%)",
    },
    averageTestWeight: {
        id: "eventInfo.harvestEvent.averageTestWeight",
        defaultMessage: "Avg Test Weight (lb/bu)",
    },
    averageUnitWeight: {
        id: "eventInfo.harvestEvent.averageUnitWeight",
        defaultMessage: "Avg Unit Weight (lb)",
    },
    averageLintYield: {
        id: "eventInfo.harvestEvent.averageLintYield",
        defaultMessage: "Avg Lint Yield",
    },
    averageYield: {
        id: "eventInfo.harvestEvent.averageYield",
        defaultMessage: "Average Yield",
    },
    baleType: {
        id: "eventInfo.harvestEvent.baleType",
        defaultMessage: "Bale Type",
    },
    balesWindrow: {
        id: "eventInfo.harvestEvent.balesWindrow",
        defaultMessage: "Bales / Windrow",
    },
    brandOrganization: {
        id: "eventInfo.harvestEvent.brandOrganization",
        defaultMessage: "Brand Organization",
    },
    ca: { id: "eventInfo.harvestEvent.ca", defaultMessage: "CA (%)" },
    colorGrade: {
        id: "eventInfo.harvestEvent.colorGrade",
        defaultMessage: "Color Grade",
    },
    cottonGrade: {
        id: "eventInfo.harvestEvent.cottonGrade",
        defaultMessage: "Cotton Grade",
    },
    cp: { id: "eventInfo.harvestEvent.cp", defaultMessage: "CP (%)" },
    crop: { id: "eventInfo.harvestEvent.crop", defaultMessage: "Crop" },
    cropPurpose: {
        id: "eventInfo.harvestEvent.cropPurpose",
        defaultMessage: "Crop Purpose",
    },
    cropQualityRating: {
        id: "eventInfo.harvestEvent.cropQualityRating",
        defaultMessage: "Crop Quality Rating",
    },
    currentPrice: {
        id: "eventInfo.harvestEvent.currentPrice",
        defaultMessage: "Current Price ($)",
    },
    cutting: {
        id: "eventInfo.harvestEvent.cutting",
        defaultMessage: "Cutting (#)",
    },
    damaged: {
        id: "eventInfo.harvestEvent.damaged",
        defaultMessage: "Damaged (%)",
    },
    daysInWindrow: {
        id: "eventInfo.harvestEvent.daysInWindrow",
        defaultMessage: "Days in Windrow",
    },
    ddm: { id: "eventInfo.harvestEvent.ddm", defaultMessage: "DDM (%)" },
    dmi: { id: "eventInfo.harvestEvent.dmi", defaultMessage: "DMI (%)" },
    feedQuality: {
        id: "eventInfo.harvestEvent.feedQuality",
        defaultMessage: "Feed Quality",
    },
    foreignMatter: {
        id: "eventInfo.harvestEvent.foreignMatter",
        defaultMessage: "Foreign Matter (%)",
    },
    gain: { id: "eventInfo.harvestEvent.gain", defaultMessage: "Gain" },
    harvestFormLabelText: {
        id: "eventInfo.harvestEvent.harvestFormLabelText",
        defaultMessage: "Harvest",
    },
    inertMatter: {
        id: "eventInfo.harvestEvent.inertMatter",
        defaultMessage: "Inert Matter (%)",
    },
    lactation: {
        id: "eventInfo.harvestEvent.lactation",
        defaultMessage: "Lactation",
    },
    largeSize: {
        id: "eventInfo.harvestEvent.largeSize",
        defaultMessage: "Large Size (%)",
    },
    ldpPayment: {
        id: "eventInfo.harvestEvent.ldpPayment",
        defaultMessage: "LDP Payment",
    },
    leafGrade: {
        id: "eventInfo.harvestEvent.leafGrade",
        defaultMessage: "Leaf Grade",
    },
    lintPercent: {
        id: "eventInfo.harvestEvent.lintPercent",
        defaultMessage: "Lint %",
    },
    location: {
        id: "eventInfo.harvestEvent.location",
        defaultMessage: "Location",
    },
    maintenance: {
        id: "eventInfo.harvestEvent.maintenance",
        defaultMessage: "Maintenance",
    },
    micronaire: {
        id: "eventInfo.harvestEvent.micronaire",
        defaultMessage: "Micronaire",
    },
    module: {
        id: "eventInfo.harvestEvent.module",
        defaultMessage: "Module (#)",
    },
    ndf: { id: "eventInfo.harvestEvent.ndf", defaultMessage: "NDF (%)" },
    netEnergy: {
        id: "eventInfo.harvestEvent.netEnergy",
        defaultMessage: "Net Energy",
    },
    numberOfBales: {
        id: "eventInfo.harvestEvent.numberOfBales",
        defaultMessage: "Number Of Bales",
    },
    oilContent: {
        id: "eventInfo.harvestEvent.oilContent",
        defaultMessage: "Oil Content (%)",
    },
    overallRating: {
        id: "eventInfo.harvestEvent.overallRating",
        defaultMessage: "Overall Rating",
    },
    p: { id: "eventInfo.harvestEvent.p", defaultMessage: "P (%)" },
    premiumSoldPrice: {
        id: "eventInfo.harvestEvent.premiumSoldPrice",
        defaultMessage: "Premium Sold Price ($)",
    },
    priceUnit: {
        id: "eventInfo.harvestEvent.priceUnit",
        defaultMessage: "Price Unit",
    },
    prices: { id: "eventInfo.harvestEvent.prices", defaultMessage: "Prices" },
    proteinContent: {
        id: "eventInfo.harvestEvent.proteinContent",
        defaultMessage: "Protein Content (%)",
    },
    rainedOnWindrow: {
        id: "eventInfo.harvestEvent.rainedOnWindrow",
        defaultMessage: "Rained On Windrow",
    },
    rfq: { id: "eventInfo.harvestEvent.rfq", defaultMessage: "RFQ (%)" },
    rfv: { id: "eventInfo.harvestEvent.rfv", defaultMessage: "RFV (%)" },
    soldPrice: {
        id: "eventInfo.harvestEvent.soldPrice",
        defaultMessage: "Sold Price ($)",
    },
    stapleLength: {
        id: "eventInfo.harvestEvent.stapleLength",
        defaultMessage: "Staple Length",
    },
    strength: {
        id: "eventInfo.harvestEvent.strength",
        defaultMessage: "Strength",
    },
    tdn: { id: "eventInfo.harvestEvent.tdn", defaultMessage: "TDN (%)" },
    totalIncome: {
        id: "eventInfo.harvestEvent.totalIncome",
        defaultMessage: "Total Income ($)",
    },
    uniformityIndex: {
        id: "eventInfo.harvestEvent.uniformityIndex",
        defaultMessage: "Uniformity Index",
    },
    unit: { id: "eventInfo.harvestEvent.unit", defaultMessage: "Unit" },
    varietyHybrid: {
        id: "eventInfo.harvestEvent.varietyHybrid",
        defaultMessage: "Variety/Hybrid",
    },
    yieldUnit: {
        id: "eventInfo.harvestEvent.yieldUnit",
        defaultMessage: "Yield Unit",
    },
});
