import React, { Component, FocusEvent } from "react";
import classnames from "classnames";
import onClickOutside from "react-onclickoutside";
import { SearchInput } from "../search-input/search-input";
import "./auto-search-input.css";

export interface IDataObject {
    orgLevelGuid?: string;
    orgLevelName?: string;
    selected?: boolean;
}
export interface IAutoSearchInput_Props {
    required?: boolean;
    classNames?: string;
    filteredData?: IDataObject[];
    keys?: string[];
    onBlur?: (event?: FocusEvent) => void;
    onFocus?: (event?: FocusEvent) => void;
    onItemSelection?: (data: IDataObject) => void;
    onSearchChange: (value: string) => void;
    placeholderText?: string;
    renderListItem?: (data: IDataObject, key?: number | string) => void;
    showTopLabel?: boolean;
    subtitle?: string;
    title?: string;
    value?: string;
    noOptionRenderer?: (hasFocus?: boolean, data?: IDataObject, value?: any) => void;
}

export interface IAutoSearchInput_State {
    filteredData: IDataObject[];
    hasFocus: boolean;
    value: string;
    isSearching: boolean;
    noOptions: boolean;
}

class AutoSearchInput_ extends Component<IAutoSearchInput_Props, IAutoSearchInput_State> {
    static defaultProps = {
        filteredData: [],
        classNames: "",
        autoFocus: false,
        noOptionRenderer: () => null,
    };

    constructor(props: IAutoSearchInput_Props) {
        super(props);
        this.state = {
            filteredData: [],
            hasFocus: false,
            value: "",
            isSearching: false,
            noOptions: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.value && nextProps.filteredData !== this.state.filteredData) {
            this.setState({
                filteredData: nextProps.filteredData,
                noOptions: true,
            });
        } else {
            this.setState({ noOptions: false });
        }

        if (!this.state.isSearching && nextProps.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    handleClickOutside(): void {
        this.onBlur();
    }

    private onBlur = (): void => {
        this.setState(
            {
                hasFocus: false,
            },
            () => {
                if (this.props.onBlur) {
                    this.props.onBlur();
                }
            }
        );
    };

    private onFocus = (): void => {
        this.setState(
            {
                hasFocus: true,
            },
            () => {
                if (this.props.onFocus) {
                    this.props.onFocus();
                }
            }
        );
    };

    private onSearchChange = (value: string): void => {
        if (value) {
            this.setState({
                value,
                isSearching: true,
            });
        } else {
            this.setState({
                value: "",
                filteredData: [],
            });
        }
        if (this.props.onSearchChange) {
            this.props.onSearchChange(value);
        }
    };

    private onItemSelection = (data?: IDataObject): void => {
        this.setState(
            {
                filteredData: [],
                hasFocus: false,
                isSearching: false,
            },
            () => {
                if (this.props.onItemSelection) {
                    this.props.onItemSelection(data);
                }
            }
        );
    };

    private renderListItem = (data: IDataObject, key?: number | string) => {
        let content = null;
        if (this.props.renderListItem) {
            content = this.props.renderListItem(data);
        }
        return (
            <div
                key={key}
                className="autosearch-list-item"
                onClick={() => this.onItemSelection(data)}
            >
                {content}
            </div>
        );
    };

    render() {
        const { noOptions, hasFocus, filteredData, value } = this.state;
        return (
            <div
                className={classnames(
                    this.props.classNames,
                    "auto-search-input select-form-input-container"
                )}
                onFocus={this.onFocus}
            >
                <SearchInput
                    {...this.props}
                    showTopLabel={this.props.showTopLabel}
                    onSearchChange={this.onSearchChange}
                    searchValue={this.state.value}
                />
                {this.state.hasFocus && this.state.filteredData.length > 0 && (
                    <div className="auto-search-options">
                        {this.state.filteredData.map((data, index) =>
                            this.renderListItem(data as IDataObject, index)
                        )}
                    </div>
                )}
                {noOptions &&
                    this.props.noOptionRenderer(hasFocus, filteredData as IDataObject, value)}
            </div>
        );
    }
}

export const AutoSearchInput = onClickOutside(AutoSearchInput_);
