import { delay } from "redux-saga";
import {
    actionChannel,
    all,
    call,
    fork,
    join,
    put,
    select,
    take,
    takeLatest,
} from "redux-saga/effects";
import { defineMessages } from "react-intl";
import { MSGTYPE } from "~/notifications";

import { actions as accordionActions, model as accordionModel } from "~/accordion";

import { actions as fileImportActions, selectors as fileImportSelectors } from "~/file-import";

import { actions as loginActions } from "~/login";
import { actions as messagingActions } from "~/messaging";
import { actions as notificationActions } from "~/notifications";
import { FileImportAPI } from "@ai360/core";

import { getTheUserGuid } from "~/login/selectors";

import {
    createImportTypeAccordionItems,
    createImportTemplateAccordionItems,
    createImportFileAccordionItems,
} from "./components/import-list/import-accordion/import-accordion";
import { importWizardSaga } from "./components/import-wizard/sagas";

import * as selectors from "./selectors";
import * as actions from "./actions";
import { logFirebaseEvent } from "~/utils/firebase";
import { IImportType } from "~/file-import/model";

const messages = defineMessages({
    noTelematicFilesMessage: {
        id: "apiError.noTelematicFilesMessage",
        defaultMessage: "No New Files",
    },
    onsiteConnectionPending: {
        id: "apiError.onsiteConnectionPending",
        defaultMessage: "Pending Onsite Connection Setup",
    },
});

export const checkDataExchange = function* () {
    try {
        const userGuid = yield select(getTheUserGuid);
        yield put(fileImportActions.setIsTelematicsProcessing(true));
        const telematicsCounts = yield call(FileImportAPI.getOnsiteFileList, userGuid, null);
        if (telematicsCounts === 0) {
            yield put(
                notificationActions.pushToasterMessage(
                    messages.noTelematicFilesMessage,
                    MSGTYPE.WARNING
                )
            );
            yield put(fileImportActions.setIsTelematicsProcessing(false));
        } else {
            yield put(fileImportActions.fetchTelematicsCounts());
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
        yield put(fileImportActions.setIsTelematicsProcessing(false));
        return;
    }
};

export const getCanDeleteStatusCodes = function* () {
    let canDeleteStatusCodes;
    try {
        canDeleteStatusCodes = yield call(FileImportAPI.getValidDeleteStatusList);
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
        return;
    }
    yield put(actions.setCanDeleteStatusCodes(canDeleteStatusCodes));
};

export const handleRefresh = function* () {
    while (true) {
        yield take(fileImportActions.REFRESH_IMPORT_DATA);
        yield take(fileImportActions.REFRESH_IMPORT_TYPE_TEMPLATE_INFO_COMPLETE);
        const refreshTask = yield fork(refreshImportAccordion);
        const refreshCompleteAction = yield take(fileImportActions.REFRESH_IMPORT_DATA_COMPLETE);
        yield join(refreshTask);
        yield* onRefreshImportDataComplete(refreshCompleteAction);
    }
};

export const onClearAllUsersTabFiles = function* () {
    yield put(fileImportActions.setFilterIncludeOtherUsers(true));
    yield take(fileImportActions.REMOVE_ALL_DATA);
    yield* resetAccordion();
};

export const onDeleteImportFileInfoItem = function* (action) {
    const { itemDimIdx } = action.payload;
    console.assert(itemDimIdx.length === 3);

    const itemList = yield select(selectors.getAccordionItems);
    const { importFileGuid } = accordionModel.getItem(itemList, itemDimIdx).payload;
    yield put(actions.deselectAllImportFileInfoItems(new Set([importFileGuid])));
    yield put(fileImportActions.deleteImportFileInfoList([importFileGuid]));
};

export const onDeleteSelectedItems = function* (action) {
    logFirebaseEvent("delete_selected_import_files");
    const { itemDimIdx } = action.payload;
    console.assert(new Set([0, 1, 2]).has(itemDimIdx.length));

    const { selectedImportFileGuidList } = yield select(selectors.getModuleState);
    const dimIdxToImportFileGuidSetMapSelector =
        selectors.getDimIdxToImportFileGuidSetMapSelector(itemDimIdx);
    const childIfoGuidSet = yield select(dimIdxToImportFileGuidSetMapSelector);

    const selectedChildIfoGuidSet = new Set(
        [...childIfoGuidSet].filter((childGuid) => selectedImportFileGuidList.has(childGuid))
    );

    yield put(actions.deselectAllImportFileInfoItems(selectedChildIfoGuidSet));
    yield put(fileImportActions.deleteImportFileInfoList([...selectedChildIfoGuidSet]));
};

export const onImportFileInfoFetched = function* (action) {
    const { importTypeFilterGuid } = yield select(selectors.getModuleState);
    if (action.payload.importFileInfoList.length === 0) {
        return;
    }
    const { templateGuid } = action.payload.importFileInfoList[0];
    const { importTypeGuid } = yield select(fileImportSelectors.getImportTemplate, templateGuid);
    if (importTypeFilterGuid && importTypeFilterGuid !== importTypeGuid) {
        return;
    }
    const items = createImportFileAccordionItems(action.payload.importFileInfoList);
    const accordionId = yield select(selectors.getAccordionId);
    const impTemplateIndex = yield select(
        selectors.getImportTemplateDimIdx,
        importTypeGuid,
        templateGuid
    );
    yield put(
        accordionActions.updateAccordionItem(accordionId, impTemplateIndex, {
            children: items,
        })
    );
};

export const onImportTypeInfoFiltered = function* (action) {
    let { fileImportTypes } = action.payload;
    if (fileImportTypes.length === 0) {
        return;
    }

    const { importTypeFilterGuid } = yield select(selectors.getModuleState);
    if (importTypeFilterGuid != null) {
        const importType = fileImportTypes.find(
            (importType) => importType.guid === importTypeFilterGuid
        );
        if (importType == null) {
            // filtering for type that has no items (changed user); reset filter
            yield put(actions.resetTypeFilter());
        } else {
            fileImportTypes = [importType];
        }
    }

    const items = createImportTypeAccordionItems(fileImportTypes);
    for (const [i, importType] of fileImportTypes.entries()) {
        const { templateList } = importType;
        const templateItems = createImportTemplateAccordionItems(templateList);
        items[i] = accordionModel.AccordionItem.updateAccordionItem(items[i], {
            children: templateItems,
        });
    }
    const accordionId = yield select(selectors.getAccordionId);
    yield put(accordionActions.addAccordionItemArray(accordionId, items));
};

export const onRefreshImportDataComplete = function* (action) {
    const { forceRefreshTemplateGuidSet, newTemplateGuidSet } = action.payload;

    const toExpandGuidSet =
        forceRefreshTemplateGuidSet != null ? forceRefreshTemplateGuidSet : newTemplateGuidSet;

    for (const templateGuid of toExpandGuidSet) {
        // expand all these templates/types
        const template = yield select(fileImportSelectors.getImportTemplate, templateGuid);
        if (template == null) {
            continue;
        }
        const { importTypeGuid } = template;
        const accordionId = yield select(selectors.getAccordionId);
        let itemList = yield select(selectors.getAccordionItems);
        const typeDimIdx = yield select(selectors.getImportTypeDimIdx, importTypeGuid);
        if (!accordionModel.getItem(itemList, typeDimIdx).expanded) {
            yield put(accordionActions.expandAccordionItem(accordionId, typeDimIdx));
            yield put(fileImportActions.setTypeExpanded(importTypeGuid, true));
        }
        itemList = yield select(selectors.getAccordionItems);
        const templateDimIdx = yield select(
            selectors.getImportTemplateDimIdx,
            importTypeGuid,
            templateGuid
        );
        if (!accordionModel.getItem(itemList, templateDimIdx).expanded) {
            yield put(accordionActions.expandAccordionItem(accordionId, templateDimIdx));
            yield put(fileImportActions.setTemplateExpanded(templateGuid, true));
        }
        itemList = yield select(selectors.getAccordionItems);
        yield put(
            actions.setScrollToIndex(
                accordionModel.getFlatIdxFromDimIdx(itemList, templateDimIdx) - 1
            )
        );
    }
};

export const resetAccordion = function* () {
    const accordionId = yield select(selectors.getAccordionId);
    yield put(accordionActions.removeAllAccordionItems(accordionId));
};

export const refreshImportAccordion = function* () {
    const { importTypeFilterGuid } = yield select(selectors.getModuleState);
    const importTypeMap = yield select(fileImportSelectors.getImportTypes);
    const importTypeGuidToTemplateListMap = yield select(
        fileImportSelectors.getImportTypeGuidToTemplateListMap
    );
    const importTemplateGuidToImportFileListMap = yield select(
        fileImportSelectors.getImportTemplateGuidToImportFileListMap
    );

    const importTypes: IImportType[] =
        importTypeFilterGuid != null
            ? [importTypeMap.get(importTypeFilterGuid)]
            : Array.from(importTypeMap.values());

    const typeItems = createImportTypeAccordionItems(importTypes);
    for (const [i, importType] of importTypes.entries()) {
        const templates = importTypeGuidToTemplateListMap.get(importType.guid);
        console.assert(templates != null && templates.length > 0);
        const templateItems = createImportTemplateAccordionItems(templates);
        for (const [j, template] of templates.entries()) {
            const ifoList = importTemplateGuidToImportFileListMap.get(template.templateGuid);
            if (ifoList) {
                const ifoItems = createImportFileAccordionItems(
                    ifoList.sort((a, b) => {
                        return (
                            Number(new Date(b.uploadDatetime)) - Number(new Date(a.uploadDatetime))
                        );
                    })
                );
                templateItems[j] = accordionModel.AccordionItem.updateAccordionItem(
                    templateItems[j],
                    { children: ifoItems }
                );
            }
        }
        typeItems[i] = accordionModel.AccordionItem.updateAccordionItem(typeItems[i], {
            children: templateItems,
        });
    }

    yield put(actions.setScrollToIndex(null));
    const accordionId = yield select(selectors.getAccordionId);
    yield put(accordionActions.replaceAllAccordionItems(accordionId, typeItems));

    if (importTypeFilterGuid != null) {
        // Only expand the type; we don't update the sticky pref
        yield put(accordionActions.expandAllAccordionItems(accordionId, [1]));
    }
};

export const refreshSelectedItems = function* (action) {
    const { selectedImportFileGuidList } = yield select(selectors.getModuleState);

    if (selectedImportFileGuidList.size === 0) {
        return;
    }

    if (
        action.type === fileImportActions.REMOVE_ALL_DATA ||
        action.type === fileImportActions.FETCH_IMPORT_FILE_TYPES
    ) {
        yield put(actions.deselectAllImportFileInfoItems(selectedImportFileGuidList));
        return;
    }

    const importFiles = yield select(fileImportSelectors.getImportFiles);
    const removedIfoGuids = new Set(
        [...selectedImportFileGuidList].filter((guid) => importFiles.has(guid))
    );
    yield put(actions.deselectAllImportFileInfoItems(removedIfoGuids));
};

export const importModuleMainSaga = function* () {
    yield fork(handleRefresh);

    yield takeLatest([fileImportActions.UPDATE_SEARCH], resetAccordion);

    yield takeLatest(
        [fileImportActions.DELETE_IMPORT_FILE_INFO_LIST, actions.SET_FILTER_TYPE_GUID],
        refreshImportAccordion
    );

    yield takeLatest(
        [
            fileImportActions.FETCH_IMPORT_FILE_TYPES,
            fileImportActions.REFRESH_IMPORT_TYPE_TEMPLATE_INFO_COMPLETE,
            fileImportActions.REMOVE_ALL_DATA,
        ],
        refreshSelectedItems
    );

    yield takeLatest(actions.CLEAR_ALL_USERS_TAB_FILES, onClearAllUsersTabFiles);
    yield takeLatest(loginActions.SET_USER_INFO_COMPLETE, getCanDeleteStatusCodes);
    yield takeLatest(actions.CHECK_DATA_EXCHANGE, checkDataExchange);

    const accordionChangingActions = [
        fileImportActions.FETCH_IMPORT_FILE_TYPES,
        fileImportActions.IMPORT_TYPES_TMPLTS_FILTER_COMPLETE,
        fileImportActions.IMPORT_FILE_INFO_FETCH_SUCCEEDED,
        fileImportActions.SET_FILTER_INCLUDE_OTHER_USERS,
        actions.DELETE_SELECTED_IMPORT_FILE_INFO_ITEMS,
        actions.DELETE_IMPORT_FILE_INFO_ITEM,
    ];

    const channel = yield actionChannel(accordionChangingActions);

    while (true) {
        const action = yield take(channel);
        if (action.type === fileImportActions.FETCH_IMPORT_FILE_TYPES) {
            yield* resetAccordion();
        } else if (action.type === fileImportActions.IMPORT_TYPES_TMPLTS_FILTER_COMPLETE) {
            yield* onImportTypeInfoFiltered(action);
        } else if (action.type === fileImportActions.IMPORT_FILE_INFO_FETCH_SUCCEEDED) {
            yield* onImportFileInfoFetched(action);
        } else if (action.type === fileImportActions.SET_FILTER_INCLUDE_OTHER_USERS) {
            yield* resetAccordion();
        } else if (action.type === actions.DELETE_SELECTED_IMPORT_FILE_INFO_ITEMS) {
            yield* onDeleteSelectedItems(action);
        } else if (action.type === actions.DELETE_IMPORT_FILE_INFO_ITEM) {
            yield* onDeleteImportFileInfoItem(action);
        } else {
            throw new Error("unreachable");
        }
    }
};

export const watchTelematics = function* () {
    while (true) {
        yield call(delay, 300 * 1000); // 5 minute updates, only if processing.  This should catch any hung files.
        const isTelematicsProcessing = yield select(fileImportSelectors.getIsTelematicsProcessing);
        if (isTelematicsProcessing) {
            yield put(fileImportActions.fetchTelematicsCounts());
        }
    }
};

export const messageSubscriptions = function* () {
    yield put(
        messagingActions.subscribe(0, {
            eventName: "onsiteConnectionPending",
            action: () =>
                notificationActions.pushToasterMessage(
                    messages.onsiteConnectionPending,
                    MSGTYPE.WARNING
                ),
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "failedOrganizationFileRequests",
            action: (message) => notificationActions.pushToasterMessage(message, MSGTYPE.WARNING),
        })
    );
};

export const importModuleSaga = function* () {
    yield all([
        importModuleMainSaga(),
        importWizardSaga(),
        watchTelematics(),
        messageSubscriptions(),
    ]);
};
