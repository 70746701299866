import { connect } from "react-redux";
import { selectors as picklistSelectors } from "~/core/picklist";
import * as selectors from "../../../selectors";
import * as actions from "../../../actions";
import {
    PICKLIST_CROPPING_SEASON,
    PICKLIST_CROPPING_SEASON_CYCLE,
    PICKLIST_CROP_PURPOSE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { FilesPanel_ } from "../components/files-panel";
import { needs } from "~/hocs/needs";

const picklists = {
    [PICKLIST_CROPPING_SEASON]: getPickListCode(PICKLIST_CROPPING_SEASON),
    [PICKLIST_CROPPING_SEASON_CYCLE]: getPickListCode(PICKLIST_CROPPING_SEASON_CYCLE),
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
};

const mapStateToProps = (state) => {
    const picklistData = picklistSelectors.getPicklistState(state);
    const newPicklistData = {};

    for (const key of Object.keys(picklists)) {
        newPicklistData[key] = picklistData[picklists[key]];
    }

    return {
        ...newPicklistData,
        importFilterListData: selectors.getImportFilterListData(state),
        importFileGuidList: selectors.getImportFileGuidList(state),
        equipmentFilter: selectors.getImportFilterEquipmentData(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    updateImportFilterListData: (d) => dispatch(actions.setImportFilterListData(d)),
    updateImageryAttribute: (req, cb) => dispatch(actions.updateImageryAttribute(req, cb)),
    removeImportFiles: (importFileGuidList) =>
        dispatch(actions.removeImportFiles(importFileGuidList)),
    assignMatchedToUnmatched: (index, assignment) =>
        dispatch(actions.assignMatchedToUnmatched(index, assignment)),
    // Note :- This action is to be dispatched using needs prop
    addEquipmentImportFilter: (model) => actions.addEquipmentImportFilter(model),
    needs: (actions) => needs(actions, dispatch),
});

export const FilesPanel = connect(mapStateToProps, mapDispatchToProps)(FilesPanel_);
