import { IAction } from "./interfaces";
import * as actions from "./actions";
import Immutable from "immutable";

const initialState = {
    listening: Immutable.Set<string>(),
};

export function authenticationReducer(state = initialState, action: IAction): any {
    switch (action.type) {
        case actions.LISTEN: {
            return {
                ...state,
                listening: state.listening.add(action.name),
            };
        }
        case actions.RECEIVED: {
            return {
                ...state,
                listening: state.listening.delete(action.name),
            };
        }
        default:
            return state;
    }
}
