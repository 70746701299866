import { defineMessages } from "react-intl";

export default defineMessages({
    farm: {
        id: "fieldDetails.farm",
        defaultMessage: "Farm:",
    },
    field: {
        id: "fieldDetails.field",
        defaultMessage: "Field:",
    },
    fieldClassificationText: {
        id: "fieldDetails.fieldClassificationText",
        defaultMessage: "Field Classification",
    },
    location: {
        id: "fieldDetails.location",
        defaultMessage: "Location:",
    },
    fieldNameErrorMsg: {
        id: "fieldDetails.fieldNameErrorMsg",
        defaultMessage: "Please enter a field name.",
    },
    fieldBoundaryErrorMsg: {
        id: "fieldDetails.fieldBoundaryErrorMsg",
        defaultMessage: "Please add a field boundary",
    },
    generalInfoText: {
        id: "fieldDetails.generalInfoText",
        defaultMessage: "General Information",
    },
    fieldNamePlaceholderText: {
        id: "fieldDetails.fieldNamePlaceholderText",
        defaultMessage: "Field Name",
    },
    fieldIsActiveLabel: {
        id: "fieldDetails.fieldIsActiveLabel",
        defaultMessage: "Active",
    },
    fieldFarmNamePlaceholderText: {
        id: "fieldDetails.fieldFarmNamePlaceholderText",
        defaultMessage: "Farm Name",
    },
    irrigatedPlaceHolderText: {
        id: "fieldDetails.irrigatedPlaceHolderText",
        defaultMessage: "Irrigated",
    },
    irrigationTypePlaceholderText: {
        id: "fieldDetails.irrigationTypePlaceholderText",
        defaultMessage: "Irrigation Type",
    },
    certifiedOrganicText: {
        id: "fieldDetails.certifiedOrganicText",
        defaultMessage: "Certified Organic",
    },
    certOrganicVerifyTitle: {
        id: "fieldDetails.certOrganicVerifyTitle",
        defaultMessage: "Warning",
    },
    certOrganicVerifyMessage: {
        id: "fieldDetails.certOrganicVerifyMessage",
        defaultMessage:
            "You are changing the status that sets whether this field is Certified Organic or not. Are you sure you want to make this change?",
    },
    directionsPlaceholderText: {
        id: "fieldDetails.directionsPlaceholderText",
        defaultMessage: "Directions",
    },
    drainageTileText: {
        id: "fieldDetails.drainageTileText",
        defaultMessage: "Drainage Tile",
    },
    fieldNotesPlaceholderText: {
        id: "fieldDetails.fieldNotesPlaceholderText",
        defaultMessage: "Field Notes",
    },
    cropInfoText: {
        id: "fieldDetails.cropInfoText",
        defaultMessage: "Crop Info",
    },
    cropPlaceholderText: {
        id: "fieldDetails.cropPlaceholderText",
        defaultMessage: "Crop",
    },
    cropPurposePlaceholderText: {
        id: "fieldDetails.cropPurposePlaceholderText",
        defaultMessage: "Crop Purpose",
    },
    brandPlaceholderText: {
        id: "fieldDetails.brandPlaceholderText",
        defaultMessage: "Brand/Organization",
    },
    varietyPlaceholderText: {
        id: "fieldDetails.varietyPlaceholderText",
        defaultMessage: "Variety/Hybrid",
    },
    plantingDatePlaceholderText: {
        id: "fieldDetails.plantingDatePlaceholderText",
        defaultMessage: "Crop Planting Date",
    },
    usdaInfoText: {
        id: "fieldDetails.usdaInfoText",
        defaultMessage: "USDA / Legal Info",
    },
    legalDescriptionPlaceholderText: {
        id: "fieldDetails.legalDescriptionPlaceholderText",
        defaultMessage: "Legal Description",
    },
    fsaAcresPlaceholderText: {
        id: "fieldDetails.fsaAcresPlaceholderText",
        defaultMessage: "FSA Acres",
    },
    cluAcresPlaceholderText: {
        id: "fieldDetails.cluAcresPlaceholderText",
        defaultMessage: "CLU Acres",
    },
    statePlaceholderText: {
        id: "fieldDetails.statePlaceholderText",
        defaultMessage: "State",
    },
    countyPlaceholderText: {
        id: "fieldDetails.countyPlaceholderText",
        defaultMessage: "County",
    },
    townshipPlaceholderText: {
        id: "fieldDetails.townshipPlaceholderText",
        defaultMessage: "Township",
    },
    rangePlaceholderText: {
        id: "fieldDetails.rangePlaceholderText",
        defaultMessage: "Range",
    },
    sectionPlaceholderText: {
        id: "fieldDetails.sectionPlaceholderText",
        defaultMessage: "Section",
    },
    apnPlaceholderText: {
        id: "fieldDetails.apnPlaceholderText",
        defaultMessage: "APN",
    },
    cluIdPlaceholderText: {
        id: "fieldDetails.cluIdPlaceholderText",
        defaultMessage: "CLU ID",
    },
    fsaFarmNumberPlaceholderText: {
        id: "fieldDetails.fsaFarmNumberPlaceholderText",
        defaultMessage: "FSA Farm Number",
    },
    fsaFieldNumberPlaceholderText: {
        id: "fieldDetails.fsaFieldNumberPlaceholderText",
        defaultMessage: "FSA Field Number",
    },
    fsaTractNumberPlaceholderText: {
        id: "fieldDetails.fsaTractNumberPlaceholderText",
        defaultMessage: "FSA Tract Number",
    },
    watershedNamePlaceholderText: {
        id: "fieldDetails.watershedNamePlaceholderText",
        defaultMessage: "Watershed Name",
    },
    watershedNumberPlaceholderText: {
        id: "fieldDetails.watershedNumberPlaceholderText",
        defaultMessage: "Watershed Number",
    },
    dominantSoilSeriesPlaceholderText: {
        id: "fieldDetails.dominantSoilSeriesPlaceholderText",
        defaultMessage: "Dominant Soil Series",
    },
    dominantSoilTexturePlaceholderText: {
        id: "fieldDetails.dominantSoilTexturePlaceholderText",
        defaultMessage: "Dominant Soil Texture",
    },
    insuranceText: {
        id: "fieldDetails.insuranceText",
        defaultMessage: "Insurance",
    },
    insurancePolicyNumPlaceholderText: {
        id: "fieldDetails.insurancePolicyNumPlaceholderText",
        defaultMessage: "Insurance Policy Number",
    },
    insurancePolicyTypePlaceholderText: {
        id: "fieldDetails.insurancePolicyTypePlaceholderText",
        defaultMessage: "Insurance Policy Type",
    },
    highRiskLandText: {
        id: "fieldDetails.highRiskLandText",
        defaultMessage: "High-Risk Land",
    },
    insuraceInfoByCropLabel: {
        id: "fieldDetails.insuraceInfoByCropLabel",
        defaultMessage: "Insurance Info Per Crop",
    },
    yieldUnitPlaceholderText: {
        id: "fieldDetails.yieldUnitPlaceholderText",
        defaultMessage: "Yield Unit",
    },
    countyTYieldPlaceholderText: {
        id: "fieldDetails.countyTYieldPlaceholderText",
        defaultMessage: "County T-Yield",
    },
    averageYieldPlaceholderText: {
        id: "fieldDetails.averageYieldPlaceholderText",
        defaultMessage: "Average Yield",
    },
    addCropYieldText: {
        id: "fieldDetails.addCropYieldText",
        defaultMessage: "+ Add Crop Yield",
    },
    leaseSplitText: {
        id: "fieldDetails.leaseSplitText",
        defaultMessage: "Lease / Billing Splits Info",
    },
    addFieldLeaseText: {
        id: "fieldDetails.addFieldLeaseText",
        defaultMessage: "+ Add Field Lease",
    },
    sharePercentOfProductTypePlaceholderText: {
        id: "fieldDetails.sharePercentOfProductTypePlaceholderText",
        defaultMessage: "Share (%) of Product Type",
    },
    shareProductTypePlaceholderText: {
        id: "fieldDetails.shareProductTypePlaceholderText",
        defaultMessage: "Share Product Type",
    },
    landlordNamePlaceholderText: {
        id: "fieldDetails.landlordNamePlaceholderText",
        defaultMessage: "Landlord Name",
    },
    cropSharePercentPlaceholderText: {
        id: "fieldDetails.cropSharePercentPlaceholderText",
        defaultMessage: "Crop Share (%)",
    },
    billingSplitsInfoLabel: {
        id: "fieldDetails.billingSplitsInfoLabel",
        defaultMessage: "Billing Splits Detail",
    },
    leaseExpireDatePlaceholderText: {
        id: "fieldDetails.leaseExpireDatePlaceholderText",
        defaultMessage: "Lease Expire Date",
    },
    leaseTypePlaceholderText: {
        id: "fieldDetails.leaseTypePlaceholderText",
        defaultMessage: "Lease Type",
    },
    agvanceFieldIdPlaceholderText: {
        id: "fieldDetails.agvanceFieldIdPlaceholderText",
        defaultMessage: "Agvance Field ID",
    },
    crossReferenceText: {
        id: "fieldDetails.crossReferenceText",
        defaultMessage: "Cross Reference",
    },
});
