import { createAction } from "redux-act";
export const VALIDATE_CROP_LIST_TOGGLE = "VALIDATE_CROP_LIST_TOGGLE";
export const VALIDATE_EQUATION_TEXT = "VALIDATE_EQUATION_TEXT";
export const VALIDATE_EQUATION_FAILED = "VALIDATE_EQUATION_FAILED";
export const VALIDATE_EQUATION_SUCCESS = "VALIDATE_EQUATION_SUCCESS";
export const VALIDATE_EQUATION_TOGGLE = "VALIDATE_EQUATION_TOGGLE";

export const TEST_EQUATION_TEXT = "TEST_EQUATION_TEXT";
export const TEST_EQUATION_FAILED = "TEST_EQUATION_FAILED";
export const TEST_EQUATION_SUCCESS = "TEST_EQUATION_SUCCESS";

export const SET_EQUATION_PARAMETERS_PICKLIST = "SET_EQUATION_PARAMETERS_PICKLIST";
export const FETCH_EQUATION_PARAMETER_PICK_LIST = "FETCH_EQUATION_PARAMETER_PICKLIST";

export const validateCropListToggle = createAction(VALIDATE_CROP_LIST_TOGGLE, (isValid) => isValid);
export const validateEquation = createAction(VALIDATE_EQUATION_TEXT);
export const validateEquationFailed = createAction(VALIDATE_EQUATION_FAILED);
export const validateEquationSuccess = createAction(VALIDATE_EQUATION_SUCCESS);
export const validateEquationToggle = createAction(
    VALIDATE_EQUATION_TOGGLE,
    (isValid, equationRepaired = false, equation = "") => ({
        isValid,
        equationRepaired,
        equation,
    })
);

export const testEquation = createAction(TEST_EQUATION_TEXT);
export const testEquationFailed = createAction(TEST_EQUATION_FAILED);
export const testEquationSuccess = createAction(TEST_EQUATION_SUCCESS);

export const setEquationParametersPicklist = createAction(SET_EQUATION_PARAMETERS_PICKLIST);
export const fetchEquationParameterPickList = createAction(FETCH_EQUATION_PARAMETER_PICK_LIST);
