import { ADMIN_STATE_KEY } from "~/admin";
import { TEMPLATE_MANAGER_DATA_KEY } from "./reducer";
import * as model from "./model";
import { createSelector } from "reselect";

export const getModuleState = (state) => state[ADMIN_STATE_KEY][TEMPLATE_MANAGER_DATA_KEY];

export const getCurrentTab = (state) => getModuleState(state).currentTab;

export const getFileMetaData = (state) => getModuleState(state).store;

export const getImportTemplateList = createSelector(
    getFileMetaData,
    (store) => store.importTemplateList
);

export const getCurrentTabTemplate = createSelector(
    getFileMetaData,
    getCurrentTab,
    (store, currentTab) => store.importTemplateList[currentTab]
);

export const getMergedFileMetaData = createSelector(
    getFileMetaData,
    getCurrentTabTemplate,
    (metaData, currentTemplate) => ({
        ...metaData,
        [model.PROPS_TEMPLATE_NAME]: currentTemplate
            ? currentTemplate[model.PROPS_TEMPLATE_NAME]
            : null,
        [model.PROPS_DELIMITER]: currentTemplate ? currentTemplate[model.PROPS_DELIMITER] : null,
        [model.PROPS_FILE_TYPES]: currentTemplate ? currentTemplate[model.PROPS_FILE_TYPES] : null,
        [model.PROPS_IGNORE_NEGATIVE_VALUES]: currentTemplate
            ? currentTemplate[model.PROPS_IGNORE_NEGATIVE_VALUES]
            : null,
        [model.PROPS_IMPORT_SAMPLE_TEMPLATE_GUID]: currentTemplate
            ? currentTemplate[model.PROPS_IMPORT_SAMPLE_TEMPLATE_GUID]
            : null,
        [model.PROPS_MANUAL_ONLY]: currentTemplate
            ? currentTemplate[model.PROPS_MANUAL_ONLY]
            : null,
        [model.PROPS_STATISTICAL_OUTLIERS_YN]: currentTemplate
            ? currentTemplate[model.PROPS_STATISTICAL_OUTLIERS_YN]
            : null,
        [model.PROPS_VENDOR_LIST]: currentTemplate
            ? currentTemplate[model.PROPS_VENDOR_LIST]
            : null,
    })
);
