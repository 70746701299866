import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { MenuItem } from "./menu-item";

export const MenuPositions = {
    BOTTOM_RIGHT: "BOTTOM_RIGHT",
    BOTTOM_LEFT: "BOTTOM_LEFT",
    LEFT_SIDE: "LEFT_SIDE",
    RIGHT_SIDE: "RIGHT_SIDE",
};

export class MenuItems extends Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        onWindowClick: PropTypes.func.isRequired,
        parent: PropTypes.any,
        position: PropTypes.oneOf(Object.keys(MenuPositions).map((k) => MenuPositions[k])),
        yOffset: PropTypes.number,
    };

    static defaultProps = {
        position: MenuPositions.BOTTOM_LEFT,
        yOffset: 0,
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            currentPosition: MenuPositions.BOTTOM_LEFT,
            position: {
                top: "inherit",
                left: "inherit",
                right: "inherit",
            },
        };
        this.reposition = this.reposition.bind(this);
    }

    setPositionForBottomLeft() {
        this.setState({
            currentPosition: MenuPositions.BOTTOM_LEFT,
            position: {
                top: "inherit",
                left: "inherit",
                right: "inherit",
            },
        });
    }
    setPositionForBottomRight(parentRect) {
        this.setState({
            currentPosition: MenuPositions.BOTTOM_RIGHT,
            position: {
                top: "inherit",
                left: "auto",
                right: window.innerWidth - parentRect.right + "px",
            },
        });
    }

    setPositionForRightTop(parentRect) {
        this.setState({
            currentPosition: MenuPositions.RIGHT_SIDE,
            position: {
                top: parentRect.top + this.props.yOffset + "px",
                left: parentRect.right + "px",
                right: "auto",
            },
        });
    }
    setPositionForRightBottom(parentRect) {
        this.setState({
            currentPosition: MenuPositions.RIGHT_SIDE,
            position: {
                top: "auto",
                bottom: window.innerHeight - parentRect.bottom + this.props.yOffset + "px",
                left: parentRect.right + "px",
                right: "auto",
            },
        });
    }
    setPositionForLeftTop(parentRect) {
        this.setState({
            currentPosition: MenuPositions.LEFT_SIDE,
            position: {
                top: parentRect.top + this.props.yOffset + "px",
                left: "auto",
                right: window.innerWidth - parentRect.left + "px",
            },
        });
    }
    setPositionForLeftBottom(parentRect) {
        this.setState({
            currentPosition: MenuPositions.LEFT_SIDE,
            position: {
                top: "auto",
                bottom: window.innerHeight - parentRect.bottom + this.props.yOffset + "px",
                left: "auto",
                right: window.innerWidth - parentRect.left + "px",
            },
        });
    }

    reposition() {
        const listElement = this.menuList;
        const listRect = listElement.getBoundingClientRect();
        const parentElement = listElement.parentElement;
        const parentRect = parentElement.getBoundingClientRect();

        switch (this.props.position) {
            case MenuPositions.BOTTOM_RIGHT:
                if (parentRect.right - listRect.width < 0 || window.innerWidth < 1025) {
                    //bottom right would place this off the page, so switch to bottom left. innerwidth check accounts for ipad
                    this.setPositionForBottomLeft(parentRect);
                } else {
                    //placement OK
                    this.setPositionForBottomRight(parentRect);
                }
                break;
            case MenuPositions.BOTTOM_LEFT:
                if (window.innerWidth < parentRect.left + listRect.width) {
                    //bottom left would place this off the page, so switch to bottom right
                    this.setPositionForBottomRight(parentRect);
                } else {
                    //placement OK
                    this.setPositionForBottomLeft(parentRect);
                }
                break;
            case MenuPositions.LEFT_SIDE:
                if (
                    parentRect.left - listRect.width > 0 &&
                    window.innerHeight > parentRect.top + listRect.height
                ) {
                    //left and top OK
                    this.setPositionForLeftTop(parentRect);
                } else if (
                    parentRect.left - listRect.width > 0 &&
                    window.innerHeight < parentRect.top + listRect.height
                ) {
                    //left OK, but top would place bottom off page, so switch to left bottom
                    this.setPositionForLeftBottom(parentRect);
                } else if (
                    parentRect.left - listRect.width < 0 &&
                    window.innerHeight > parentRect.top + listRect.height
                ) {
                    //top OK, but left side would be off page, so switch to right top
                    this.setPositionForRightTop(parentRect);
                } else {
                    //left side would be off page & top would place bottom off page, so switch to right bottom
                    this.setPositionForRightBottom(parentRect);
                }
                break;
            case MenuPositions.RIGHT_SIDE:
                if (
                    window.innerWidth > parentRect.right + listRect.width &&
                    window.innerWidth > 1024 &&
                    window.innerHeight > parentRect.top + listRect.height &&
                    !window.touch
                ) {
                    //right and top OK. innerwidth check accounts for ipad
                    this.setPositionForRightTop(parentRect);
                } else if (
                    window.innerWidth > parentRect.right + listRect.width &&
                    window.innerWidth > 1024 &&
                    window.innerHeight < parentRect.top + listRect.height &&
                    !window.touch
                ) {
                    //right OK, but top would place bottom off page, so switch to right bottom. innerwidth check accounts for ipad
                    this.setPositionForRightBottom(parentRect);
                } else if (window.innerHeight > parentRect.top + listRect.height) {
                    //top OK, but right side would be off page, so switch to left top
                    this.setPositionForLeftTop(parentRect);
                } else {
                    //right side would be off page & top would place bottom off page, so switch to left bottom
                    this.setPositionForLeftBottom(parentRect);
                }
                break;
            default:
            //do nothing - defaultProps should handle
        }
    }

    componentDidMount() {
        if (window) {
            window.addEventListener("click", this.props.onWindowClick, false);
            window.addEventListener("resize", this.reposition, false);
            this.reposition();
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener("click", this.props.onWindowClick, false);
            window.removeEventListener("resize", this.reposition, false);
        }
    }

    render() {
        const items = this.props.items;
        const divStyle = this.state.position;
        const clsNames = ["menu-items-drop-down"];

        switch (this.props.position) {
            case MenuPositions.BOTTOM_LEFT:
            case MenuPositions.BOTTOM_RIGHT:
                clsNames.push("menu-position-bottom");
                break;
            case MenuPositions.LEFT_SIDE:
                clsNames.push("menu-position-left");
                break;
            case MenuPositions.RIGHT_SIDE:
                clsNames.push("menu-position-right");
                break;
            default:
        }
        return (
            <div
                className={classnames(clsNames)}
                style={divStyle}
                ref={(menuList) => {
                    this.menuList = menuList;
                }}
            >
                <ul>
                    {items.map((item) => (
                        <MenuItem
                            key={item.key}
                            label={item.label}
                            action={item.action}
                            routeString={item.routeStr}
                            subMenuItems={item.subMenuItems}
                            onWindowClick={this.props.onWindowClick}
                            parent={this.props.parent}
                            disabled={item.disabled}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}
