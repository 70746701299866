import { createReducer } from "redux-act";
import * as actions from "./actions";

export const SYSTEM_DATA_KEY = "SYSTEM_DATA";

const initialState = {
    systemSettings: {},
};

export const systemReducer = createReducer(
    {
        [actions.fetchSystemSettingsSuccess]: (state, systemSettings) => ({
            ...state,
            systemSettings,
        }),
    },
    initialState
);
