import * as actions from "./actions";
import Immutable from "immutable";

const initialState: IWindowState = {
    open: Immutable.Map<string, Window>(),
};

interface IWindowState {
    open: Immutable.Map<string, Window>;
}

export function windowReducer(state = initialState, action: actions.IWindowAction): IWindowState {
    switch (action.type) {
        case actions.OPEN: {
            const openedWindow = window.open(action.url);

            return {
                ...state,
                open: state.open.set(action.name, openedWindow),
            };
        }
        case actions.REDIRECT: {
            const foundWindow = state.open.get(action.name);

            foundWindow.location.replace(action.url);

            return {
                ...state,
            };
        }
        case actions.CLOSE: {
            const foundWindow = state.open.get(action.name);

            foundWindow.close();

            return {
                ...state,
            };
        }
        case actions.CLOSING: {
            return {
                ...state,
                open: state.open.delete(action.name),
            };
        }
        default:
            return state;
    }
}
