import PropTypes from "prop-types";

import { model } from "~/admin/setup/owner/data";

export const shape = {
    // [model.PROPS_ADD_EMAIL_VISIBLE]: PropTypes.bool,
    // [model.PROPS_ADD_USER_ACCOUNT_VISIBLE]: PropTypes.bool,
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_ADDRESS_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_ADMIN_LOGIN]: PropTypes.string,
    [model.PROPS_AGBYTES_ADMIN_EMAIL_LIST]: PropTypes.array,
    [model.PROPS_BRANDING_YN]: PropTypes.bool,
    [model.PROPS_COMPANY_GUID]: PropTypes.string,
    [model.PROPS_COMPANY_ID]: PropTypes.string,
    [model.PROPS_COMPANY_LOCATION_ID]: PropTypes.number,
    [model.PROPS_COMPANY_LOGO]: PropTypes.string,
    [model.PROPS_COMPANY_TYPE_GUID]: PropTypes.string,
    [model.PROPS_COMPANY_TYPE_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_COMPANY_UI_LABEL_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_COST_CENTER_NUMBER]: PropTypes.number,
    [model.PROPS_COUNTRIES_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_DATA_POOLING]: PropTypes.bool,
    [model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT]: PropTypes.bool,
    [model.PROPS_EMAIL_ADDRESS]: PropTypes.string,
    [model.PROPS_ERROR_MESSAGE]: PropTypes.string,
    [model.PROPS_HAS_CHILDREN]: PropTypes.bool,
    [model.PROPS_IS_ACTIVE]: PropTypes.bool,
    [model.PROPS_MOBILE_COMPANY_ICON]: PropTypes.string,
    [model.PROPS_MOBILE_UI_THEME_GUID]: PropTypes.string,
    [model.PROPS_NOTES]: PropTypes.string,
    [model.PROPS_ORG_LEVEL_ADDRESS_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_ORG_LEVEL_CITY]: PropTypes.string,
    [model.PROPS_ORG_LEVEL_GUID]: PropTypes.string,
    [model.PROPS_ORG_LEVEL_NAME]: PropTypes.string,
    [model.PROPS_ORG_LEVEL_STATE_ABBR]: PropTypes.string,
    [model.PROPS_ORG_LEVEL_URL_LIST]: PropTypes.array, //.oneOf(["array", "object"]),
    [model.PROPS_ORG_TYPE_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_OWNER_BRANDING_NAME]: PropTypes.string,
    [model.PROPS_OWNER_PHONE_NUMBER_LIST]: PropTypes.array, //.oneOf(["array", "object"]),
    [model.PROPS_PERSON_ORG_LEVEL_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_POLICY_LAST_UPDATED]: PropTypes.string,
    [model.PROPS_PRIVACY_POLICY_TEXT]: PropTypes.string,
    [model.PROPS_PRIVACY_POLICY_TITLE]: PropTypes.string,
    [model.PROPS_RESPONSE_FROM_EMAIL]: PropTypes.string,
    [model.PROPS_SEND_RESULTS_TO_EMAIL_ADDRESS]: PropTypes.string,
    [model.PROPS_STATES_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_SYSTEM_LOGO]: PropTypes.string,
    [model.PROPS_SYSTEM_LOGO_DARK]: PropTypes.string,
    [model.PROPS_SYSTEM_LOGO_LIGHT]: PropTypes.string,
    [model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]: PropTypes.array, //.oneOf(["array", "object"]),
    [model.PROPS_THEME_LIST]: PropTypes.array, //oneOf(["array", "object"]),
    [model.PROPS_UI_THEME_LIST]: PropTypes.array,
    [model.PROPS_UNIT_SYSTEM_GUID]: PropTypes.string,
    [model.PROPS_WEB_COMPANY_ICON]: PropTypes.string,
    [model.PROPS_WEB_UI_THEME_GUID]: PropTypes.string,
};

export default PropTypes.shape(shape);
