import React, { Component, ReactNode } from "react";
import { SelectInput } from "~/core";
import classnames from "classnames";
import { ISearchData } from "../../interfaces";
import { ISelectOption } from "~/core/components/select-input/model";

export interface IFilterInputProps {
    attr: string;
    data: ISearchData | ISearchData[];
    label: string;
    onEnter?: (attr: string, value: string) => void;
    onSelectionChange: (value: string, attr: string) => void;
    onTextChange: (value: string, attr: string) => void;
    value?: string;
}

export interface IFilterInputState {
    value: string;
    options: ISelectOption<string>[];
}
class FilterInput extends Component<IFilterInputProps, IFilterInputState> {
    static defaultProps = {
        data: [],
    };

    constructor(props: IFilterInputProps) {
        super(props);
        this.state = {
            value: props.value,
            options: props.data.map((i) => ({
                value: i.value,
                label: i.value,
            })),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IFilterInputProps): void {
        if (this.state.value && nextProps.data !== this.props.data) {
            const options = nextProps.data.map((i) => ({
                value: i.value,
                label: i.value,
            }));
            this.setState({
                options,
            });
        }
    }

    public onChange = (value: string): void => {
        const { attr, onSelectionChange, onTextChange } = this.props;
        if (!value) {
            this.setState({
                options: [],
            });
            onSelectionChange(value, attr);
        } else {
            onTextChange(value, attr);
        }
        this.setState({
            value,
        });
    };

    private onEnterPress = (value: string): void => {
        const { attr, onEnter } = this.props;
        if (onEnter) {
            onEnter(attr, value);
        }
    };

    public onSelection = (selectedItem: string): void => {
        const { attr, onSelectionChange } = this.props;
        onSelectionChange(selectedItem, attr);
        this.setState({
            value: selectedItem,
        });
    };

    public onKeyDown = (event: KeyboardEvent): void => {
        if (event.key === "Tab" || event.key === "Enter") {
            const { attr, onSelectionChange } = this.props;
            const { value } = this.state;
            onSelectionChange(value, attr);
        }
    };

    public renderListItem = (data: ISearchData): ReactNode => {
        return <div title={data.value}>{data.value}</div>;
    };

    render(): ReactNode {
        const { label } = this.props;
        const containerClasses = [
            "filter-input",
            this.state.value ? "has-filter-value" : null,
            this.state.value && this.state.options.length > 0 ? "list-expanded" : null,
        ].join(" ");
        const classNames = classnames(containerClasses, "search-input", "filter-input");
        return (
            <div className={classNames}>
                <SelectInput
                    arrowRenderer={() => null}
                    autoFocus={false}
                    clearFilterInputOnBlur={false}
                    clearable={false}
                    containerClassNames={[containerClasses]}
                    minOptionsWidth={150}
                    noOptionsRenderer={null}
                    onChange={this.onSelection}
                    onEnter={this.onEnterPress}
                    onInputChange={this.onChange}
                    options={this.state.options}
                    placeholderText={label}
                    showTopLabel={false}
                    allowEmptyOptions
                />
            </div>
        );
    }
}

export default FilterInput;
