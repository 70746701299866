import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const LayersIcon = withSvgIcon(
    <g>
        <path d="M249 74.9l229.9 85.2L249 245.3 19.1 160.1 249 74.9m0-20.2c-2.4 0-4.7.4-7 1.3L12.1 141.2C4.2 144.1-1 151.7-1 160.1c0 8.4 5.2 16 13.1 18.9L242 264.2c2.3.8 4.6 1.3 7 1.3 2.4 0 4.7-.4 7-1.3L485.9 179c7.9-2.9 13.1-10.5 13.1-18.9 0-8.4-5.2-16-13.1-18.9L256 56c-2.3-.9-4.6-1.3-7-1.3z" />
        <path d="M249 326c-1.2 0-2.3-.2-3.5-.6L7.3 237.8c-5.2-1.9-7.9-7.7-6-12.9 1.9-5.2 7.7-7.9 12.9-6L249 305.2 483.8 219c5.2-1.9 11 .8 12.9 6 1.9 5.2-.8 11-6 12.9l-238.3 87.5c-1.1.4-2.2.6-3.4.6z" />
        <path d="M249 384.7c-1.2 0-2.3-.2-3.5-.6L7.3 296.6c-5.2-1.9-7.9-7.7-6-12.9 1.9-5.2 7.7-7.9 12.9-6L249 363.9l234.8-86.2c5.2-1.9 11 .8 12.9 6 1.9 5.2-.8 11-6 12.9l-238.3 87.5c-1.1.4-2.2.6-3.4.6z" />
        <path d="M249 445.3c-1.2 0-2.3-.2-3.5-.6L7.3 357.1c-5.2-1.9-7.9-7.7-6-12.9 1.9-5.2 7.7-7.9 12.9-6L249 424.5l234.8-86.2c5.2-1.9 11 .8 12.9 6 1.9 5.2-.8 11-6 12.9l-238.3 87.5c-1.1.4-2.2.6-3.4.6z" />
    </g>
);
