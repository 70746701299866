export const keywords = {
    AG_LEADER: "Ag Leader",
    APPLICATION: "Application",
    EC_DATA: "EC Data",
    EDIT: "EDIT",
    FIELD_BOUNDARY: "Field Boundary",
    FILE_SIZE: "0.00",
    HARVEST: "Harvest",
    IMAGERY_FILE_NAME: "GeoTIFF (*.tiff, *.tif)",
    JOHN_DEERE: "John Deere",
    MANAGEMENT_AREA: "Management Area",
    MODUS_FILE_NAME: "MODUS (*.xml)",
    PLANTING: "Planting",
    RAVEN: "Raven",
    SAMPLING_NEMATODE: "Sampling - Nematode",
    SAMPLING_SOIL: "Sampling - Soil",
    SAMPLING_TISSUE: "Sampling - Tissue",
    SAMPLING_WATER: "Sampling - Water",
};
