import { connect } from "react-redux";
import { SlidingPanelHeader } from "./../sliding-panel-header";
import { getBreadcrumbs } from "./selectors";
import { BREADCRUMBS } from "./reducer";

const mapStateToProps = (state) => ({
    [BREADCRUMBS]: getBreadcrumbs(state),
});

export default connect(mapStateToProps)(SlidingPanelHeader);
