import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";

import Color from "@arcgis/core/Color";
import type SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import type TextSymbol from "@arcgis/core/symbols/TextSymbol";

import { DialogBox, DialogBoxFooterType, Checkbox, ColorPicker, NoLink, Section } from "~/core";
import { actions as notificationActions } from "~/notifications";
import { FieldAPI, ReportAPI, ReportsModels } from "@ai360/core";
import { SymbolUtils } from "@ai360/core";
import { messages } from "../i18n-messages";
import { getDefaultReportImageConfig } from "../utils";

import "./report-field-symbology-options.css";

interface IReportFieldSymbologyOptionsProps {
    dialogOptions: any;
    dialogTitle: string;
    isOpen: boolean;
    letterIcon: string;
    onChange: any;
    onClose: any;
    reportId: string;
    reportImageConfig: any;
    userGuid: string;
}

interface IReportFieldSymbologyOptionsState {
    labelFields: any[];
    labelSymbol: TextSymbol;
    saveInvalid: boolean;
    symbol: SimpleFillSymbol;
}

class ReportFieldSymbologyOptions_ extends Component<
    IReportFieldSymbologyOptionsProps & InjectedIntlProps,
    IReportFieldSymbologyOptionsState
> {
    constructor(props) {
        super(props);
        const ric =
            (typeof this.props.reportImageConfig === "string"
                ? JSON.parse(this.props.reportImageConfig)
                : this.props.reportImageConfig) || this._getDefaultReportImageConfig();
        const symbol =
            ric.symbols && ric.symbols[ReportsModels.layerType.fieldBoundary]
                ? (SymbolUtils.fromJSON(
                      ric.symbols[ReportsModels.layerType.fieldBoundary]
                  ) as SimpleFillSymbol)
                : null;
        const labelSymbol =
            ric.fieldLabels && ric.fieldLabels.symbol
                ? (SymbolUtils.fromJSON(ric.fieldLabels.symbol) as TextSymbol)
                : null;
        this.state = {
            labelFields: ric.fieldLabelFields,
            labelSymbol: labelSymbol,
            saveInvalid: false,
            symbol: symbol,
        };
    }

    _getDefaultReportImageConfig() {
        const reportId = this.props.reportId || ReportAPI.ReportNames.REPORT_BOUNDARY_MAP;
        return getDefaultReportImageConfig(reportId);
    }

    _getFieldLabelFields(
        labelFields = [
            FieldAPI.mappedFieldBoundaryFields.fieldName,
            FieldAPI.mappedFieldBoundaryFields.fieldArea,
        ]
    ) {
        const { formatMessage } = this.props.intl;
        return [
            {
                title: formatMessage(messages.fieldLabelFieldsFarmName),
                field: FieldAPI.mappedFieldBoundaryFields.farmName,
                enabled: labelFields.indexOf(FieldAPI.mappedFieldBoundaryFields.farmName) > -1,
            },
            {
                title: formatMessage(messages.fieldLabelFieldsFieldName),
                field: FieldAPI.mappedFieldBoundaryFields.fieldName,
                enabled: labelFields.indexOf(FieldAPI.mappedFieldBoundaryFields.fieldName) > -1,
            },
            {
                title: formatMessage(messages.fieldLabelFieldsArea),
                field: FieldAPI.mappedFieldBoundaryFields.fieldArea,
                enabled: labelFields.indexOf(FieldAPI.mappedFieldBoundaryFields.fieldArea) > -1,
            },
            {
                title: formatMessage(messages.fieldLabelFieldsLatLong),
                field: "centroid",
                enabled: labelFields.indexOf("centroid") > -1,
            },
        ];
    }

    _getLegendColorPicker(label, color, onColorChange) {
        const { formatMessage } = this.props.intl;
        return (
            <React.Fragment>
                <div
                    className="checkbox-label"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <ColorPicker
                        hex={color.toHex()}
                        onChange={onColorChange}
                        transparencyLabel={`${formatMessage(messages.transparency)}`}
                        type="compacttransparency"
                        value={color}
                    />
                </div>
                <div className="checkbox-label">
                    <span className="checkbox-label-text-span">{label}</span>
                </div>
            </React.Fragment>
        );
    }

    _getBoundaryColorOptions() {
        const { dialogOptions } = this.props;
        const { formatMessage } = this.props.intl;
        const { symbol } = this.state;
        const show =
            dialogOptions && Object.hasOwn(dialogOptions, "showBoundaryOptions")
                ? dialogOptions.showBoundaryOptions
                : true;
        if (!show || !symbol) {
            return null;
        }
        const sectionHeader =
            dialogOptions && Object.hasOwn(dialogOptions, "boundaryOptionSectionHeader")
                ? dialogOptions.boundaryOptionSectionHeader
                : formatMessage(messages.fieldSymbologyBoundaryColor);
        const sectionHeaderText = sectionHeader ? `${sectionHeader}:` : sectionHeader;
        return (
            <Section headerText={sectionHeaderText}>
                <Checkbox
                    renderLabel={() => {
                        return this._getLegendColorPicker(
                            formatMessage(messages.border),
                            symbol.outline.color,
                            (value) => {
                                symbol.outline.color = Color.fromArray([
                                    value.r,
                                    value.g,
                                    value.b,
                                    value.a,
                                ]);
                                this.setState({ symbol: symbol });
                            }
                        );
                    }}
                    onChange={(e, value) => {
                        symbol.outline.color.a = value ? 1 : 0;
                        this.setState({ symbol: symbol });
                    }}
                    value={symbol.outline.color.a > 0 ? true : false}
                />
                <Checkbox
                    renderLabel={() => {
                        return this._getLegendColorPicker(
                            formatMessage(messages.fill),
                            symbol.color,
                            (value) => {
                                symbol.color = Color.fromArray([
                                    value.r,
                                    value.g,
                                    value.b,
                                    value.a,
                                ]);
                                this.setState({ symbol: symbol });
                            }
                        );
                    }}
                    onChange={(e, value) => {
                        symbol.color.a = value ? 1 : 0;
                        this.setState({ symbol: symbol });
                    }}
                    value={symbol.color.a > 0 ? true : false}
                />
            </Section>
        );
    }

    _getLabelOptions() {
        const { dialogOptions } = this.props;
        const { formatMessage } = this.props.intl;
        const { labelFields, labelSymbol } = this.state;
        const show =
            dialogOptions && Object.hasOwn(dialogOptions, "showLabelOptions")
                ? dialogOptions.showLabelOptions
                : true;
        if (!show) {
            return null;
        }

        const sectionHeader =
            dialogOptions && Object.hasOwn(dialogOptions, "labelOptionSectionHeader")
                ? dialogOptions.labelOptionSectionHeader
                : formatMessage(messages.fieldSymbologyBoundaryLabels);
        const sectionHeaderText = sectionHeader ? `${sectionHeader}:` : sectionHeader;
        return (
            <Section headerText={sectionHeaderText}>
                <div className="checkbox-div no-checkbox">
                    {this._getLegendColorPicker(
                        formatMessage(messages.color),
                        labelSymbol.color,
                        (value) => {
                            labelSymbol.color = Color.fromArray([
                                value.r,
                                value.g,
                                value.b,
                                value.a,
                            ]);
                            this.setState({ labelSymbol: labelSymbol });
                        }
                    )}
                </div>
                <React.Fragment>
                    {this._getFieldLabelFields(labelFields).map((f) => (
                        <Checkbox
                            key={f.title}
                            label={f.title}
                            onChange={(e, value) => {
                                this._setLabelFields(f.field, value);
                            }}
                            value={f.enabled}
                        />
                    ))}
                </React.Fragment>
            </Section>
        );
    }

    _onSave() {
        const sym = this.state.symbol;
        if (sym && sym.color.a === 0 && sym.outline.color.a === 0) {
            this.setState({ saveInvalid: true });
            return;
        }

        const config: any = {
            fieldLabelFields: this.state.labelFields,
            fieldLabels: {
                symbol: this.state.labelSymbol.toJSON(),
            },
        };

        if (sym) {
            config.symbols = {
                [ReportsModels.layerType.fieldBoundary]: sym.toJSON(),
            };
        }
        this.props.onChange(config);
    }

    _onOpen() {
        if (this.props.reportImageConfig) {
            this._resetBoundaryState(this.props.reportImageConfig);
            this._resetLabelState(this.props.reportImageConfig);
        } else {
            this._resetToDefaults();
        }
    }

    _resetBoundaryState(reportImageConfig) {
        if (
            reportImageConfig &&
            reportImageConfig.symbols &&
            reportImageConfig.symbols[ReportsModels.layerType.fieldBoundary]
        ) {
            const symbol = SymbolUtils.fromJSON(
                reportImageConfig.symbols[ReportsModels.layerType.fieldBoundary]
            ) as SimpleFillSymbol;
            this.setState({
                symbol: symbol,
            });
        }
    }

    _resetLabelState(reportImageConfig) {
        if (
            reportImageConfig &&
            reportImageConfig.fieldLabels &&
            reportImageConfig.fieldLabels.symbol
        ) {
            const labelSymbol = SymbolUtils.fromJSON(
                reportImageConfig.fieldLabels.symbol
            ) as TextSymbol;
            this.setState({
                labelFields: reportImageConfig.fieldLabelFields,
                labelSymbol: labelSymbol,
            });
        }
    }

    _resetToDefaults() {
        const defaultRIC = this._getDefaultReportImageConfig();
        this._resetBoundaryState(defaultRIC);
        this._resetLabelState(defaultRIC);
    }

    _setLabelFields(field, enabled) {
        //order of fields in array matters
        const fields = this._getFieldLabelFields(this.state.labelFields)
            .filter((f) => {
                return (f.field === field && enabled) || (f.field !== field && f.enabled);
            })
            .map((fm) => fm.field);
        this.setState({ labelFields: fields });
    }

    render() {
        const { dialogTitle, isOpen, letterIcon, onClose } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <DialogBox
                    className="report-field-symbology-dialog"
                    draggable
                    unrestricted
                    isOpen={isOpen}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    letterIcon={letterIcon}
                    onAction={() => this._onSave()}
                    onClose={onClose}
                    onOpen={() => this._onOpen()}
                    title={dialogTitle}
                >
                    {this._getBoundaryColorOptions()}
                    {this._getLabelOptions()}
                    <Section>
                        <NoLink
                            className="reset"
                            label={formatMessage(messages.resetToDefault)}
                            onClick={() => {
                                this._resetToDefaults();
                            }}
                        />
                    </Section>
                </DialogBox>
                <DialogBox
                    isOpen={this.state.saveInvalid}
                    isModal={true}
                    draggable={true}
                    onClose={() => this.setState({ saveInvalid: false })}
                    title={formatMessage(messages.invalidSymbology)}
                    footerType={DialogBoxFooterType.NONE}
                >
                    {formatMessage(messages.invalidSymbologyMessage)}
                </DialogBox>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
});

export const ReportFieldSymbologyOptions = connect(
    null,
    mapDispatchToProps
)(injectIntl(ReportFieldSymbologyOptions_));
