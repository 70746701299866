import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DialogBox, DialogBoxFooterType, NoLink, ZeroToInfiniteGrid, Section } from "~/core";
import { withEditableGrid } from "~/hocs";
import { formatDate, getCountryGuid, prepareSelectableOptions } from "~/admin/utils";
import { getTheUserGuid } from "~/login";
import { requestState } from "./data/actions";
import { getCountryStateList } from "./data/selectors";
import { messages } from "./../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import _ from "lodash";
import { LicenseItem } from "./license-item";

// Constants
import * as constants from "./constants";

// Style
import "./license.css";

class License extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        licenseType: PropTypes.array,
        countriesList: PropTypes.array,
        stateList: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        renderDeleteModal: PropTypes.func,
        needs: PropTypes.func,
        fetchState: PropTypes.func,
        countryStateList: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            editOptions: [],
            editingItem: false,
            countryGuid: "",
            records: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.record !== this.state.records) {
            this.setState({ records: this.initializeRecords(nextProps) });
        }
    }

    initializeRecords = ({ record = [], licenseType }) => {
        if (record) {
            return record.map((recordItem) => {
                const selectedLicense = _.find(
                    licenseType,
                    (data) => data.value.guid === recordItem[constants.PROPS_LICENSE_TYPE_GUID]
                );
                recordItem[constants.PROPS_LICENSE_TYPE_NAME] =
                    selectedLicense && selectedLicense.value.name;
                if (recordItem[constants.PROPS_EXPIRATION_DATE]) {
                    recordItem[constants.PROPS_EXPIRATION_DATE] = formatDate(
                        recordItem[constants.PROPS_EXPIRATION_DATE],
                        "M/D/YYYY"
                    );
                }
                return recordItem;
            });
        }
        return record;
    };

    onEdit = (record, index) => {
        this.setState({
            editingItem: true,
            countryGuid: record[constants.PROPS_COUNTRY_GUID],
        });
        const keysToUpdate = [
            {
                name: [constants.PROPS_LICENSE_TYPE_GUID],
                key: "picklistValueGuid",
            },
            [constants.PROPS_LICENSE_NUMBER],
            {
                name: [constants.PROPS_STATE_GUID],
                key: "stateGuid",
            },
            {
                name: [constants.PROPS_COUNTRY_GUID],
                key: "countryGuid",
            },
            [constants.PROPS_EXPIRATION_DATE],
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    requestState = (guid) => {
        if (guid) {
            if (!(guid in this.props.countryStateList)) {
                this.props.needs([this.props.fetchState(guid)]);
            }
        } else {
            console.error(`${guid} value is null`);
        }
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    getState = () => {
        const { countryGuid } = this.state;
        if (this.props.countryStateList[countryGuid]) {
            return prepareSelectableOptions(this.props.countryStateList[countryGuid], {
                guid: constants.PROPS_STATE_GUID,
                label: "abbreviation",
            });
        } else {
            if (this.props.stateList && "label" in this.props.stateList[0]) {
                return this.props.stateList;
            }
            return prepareSelectableOptions(this.props.stateList, {
                guid: constants.PROPS_STATE_GUID,
                label: "abbreviation",
            });
        }
    };

    onCountryChange = (value, cb) => {
        if (value) {
            this.setState({ countryGuid: value.guid });
            if (typeof cb === "function") {
                cb(value.guid);
            }
        }
    };

    renderAddEditModal = () => {
        const {
            formatMessage,
            isEditing,
            editData,
            onAddOrEditItem,
            onChange,
            toggleModal,
            licenseType,
            countriesList,
        } = this.props;
        const { getState, onCountryChange, requestState } = this;
        const props = {
            licenseType,
            formatMessage,
            onChange,
            editData,
            getCountryGuid,
            getState,
            countriesList,
            onCountryChange,
            requestState,
        };
        const actionDisabled = !(
            editData[constants.PROPS_LICENSE_TYPE_GUID] ||
            editData[constants.PROPS_LICENSE_NUMBER] ||
            editData[constants.PROPS_EXPIRATION_DATE]
        );
        return (
            <DialogBox
                action="save"
                actionDisabled={actionDisabled}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.license
                )}
                className="license-dialog"
            >
                <LicenseItem {...props} />
            </DialogBox>
        );
    };

    render() {
        const { formatMessage, renderDeleteModal } = this.props;
        const { records } = this.state;
        return (
            <Section headerText={formatMessage(messages.licenseHeading)} className="license-header">
                {!(records && records.length > 0) ? null : (
                    <ZeroToInfiniteGrid
                        records={records}
                        columns={{
                            [constants.PROPS_LICENSE_TYPE_NAME]: {
                                title: formatMessage(messages.type),
                            },
                            [constants.PROPS_LICENSE_NUMBER]: {
                                title: formatMessage(messages.number),
                            },
                            [constants.PROPS_EXPIRATION_DATE]: {
                                title: formatMessage(messages.expiration),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="sub-description-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.license)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </Section>
        );
    }
}

const mapStateToProps = (state) => ({
    UserGuid: getTheUserGuid(state),
    countryStateList: getCountryStateList(state),
});
const mapDispatchToProps = (dispatch) => {
    return {
        fetchState: (v) => dispatch(requestState(v)),
    };
};

export default withEditableGrid(connect(mapStateToProps, mapDispatchToProps)(License));
