import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    TextArea,
    SelectInput,
    DateInput,
    NumericInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { mapToPicklistValue, prepareSelectableOptions, formatDate } from "~/admin/utils";
import { messages } from "../../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import * as unitService from "~/core/units/unit-names";
import moment from "moment";
import TableCell from "~/core/components/tables/data-table/components/table-cell";
import { adminData, GUID, NAME } from "~/admin/data";

const PROPS_FUEL_ADDED = "fuelAdded";
const PROPS_FUEL_ADDED_DATE = "fuelAddedDate";
const PROPS_FUEL_ADDED_UNIT_GUID = "fuelAddedUnitGuid";
const PROPS_FUEL_ADDED_UNIT_NAME = "fuelAddedUnitName";
const PROPS_NOTES = "notes";

class FuelingEventList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        needs: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchDropdownData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
            record: [],
            [unitService.UNIT_VOLUME]: prepareSelectableOptions(props[unitService.UNIT_VOLUME], {
                guid: GUID,
                label: NAME,
            }),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(this.state.record) !== JSON.stringify(this.props.record) &&
            nextProps[unitService.UNIT_VOLUME]
        ) {
            this.setState({
                record: this.initializeUsageReadingList(nextProps),
            });
        }

        this.initializeFuelAddedUnit(nextProps);
    }

    initializeUsageReadingList = (nextProps) => {
        const { record } = nextProps;
        if (record) {
            const usageOptions = nextProps[unitService.UNIT_VOLUME];

            return record.map((recordItem) => {
                const selectedUsage = usageOptions.find(
                    (usageItem) => usageItem.guid === recordItem[PROPS_FUEL_ADDED_UNIT_GUID]
                );
                recordItem[unitService.UNIT_VOLUME] = selectedUsage && selectedUsage[NAME];
                return recordItem;
            });
        }
        return record;
    };

    initializeFuelAddedUnit = (nextProps) => {
        if (nextProps[unitService.UNIT_VOLUME] !== this.props[unitService.UNIT_VOLUME]) {
            this.setState({
                [unitService.UNIT_VOLUME]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_VOLUME],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    onEdit = (record, index) => {
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_FUEL_ADDED_UNIT_GUID],
                key: GUID,
            },
            {
                name: [PROPS_FUEL_ADDED_DATE],
                key: NAME,
            },
            {
                name: [PROPS_FUEL_ADDED],
                key: NAME,
            },
            {
                name: [PROPS_NOTES],
                key: NAME,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                action="save"
                className="fueling-event-dialog"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                actionDisabled={
                    !editData[PROPS_FUEL_ADDED_DATE] ||
                    !editData[PROPS_FUEL_ADDED] ||
                    !editData[PROPS_FUEL_ADDED_UNIT_GUID]
                }
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.fuelingEvent
                )}
            >
                <div className="column-3-gutter-dialog-box">
                    <DateInput
                        required
                        autoFocus
                        onChange={(value) =>
                            onChange({
                                [PROPS_FUEL_ADDED_DATE]: formatDate(value, ""),
                            })
                        }
                        value={
                            editData[PROPS_FUEL_ADDED_DATE]
                                ? moment(editData[PROPS_FUEL_ADDED_DATE])
                                : null
                        }
                        placeholderText={formatMessage(messages.fuelingDate)}
                        defaultValue={null}
                    />
                    <NumericInput
                        required
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.fuelAdded)}
                        onChange={(value) => {
                            onChange({
                                [PROPS_FUEL_ADDED]: value,
                            });
                        }}
                        value={editData[PROPS_FUEL_ADDED]}
                    />
                    <SelectInput
                        required
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        clearable={false}
                        options={this.state[unitService.UNIT_VOLUME]}
                        value={mapToPicklistValue({
                            options: this.state[unitService.UNIT_VOLUME],
                            selectedGuid: editData[PROPS_FUEL_ADDED_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_FUEL_ADDED_UNIT_GUID]: value.guid,
                                [PROPS_FUEL_ADDED_UNIT_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.fuelAddedUnit)}
                    />
                </div>
                <div className="description-cont">
                    <TextArea
                        maxLength={2000}
                        containerClassNames={["fueling-event-textarea"]}
                        value={editData[PROPS_NOTES]}
                        onChange={(value) => {
                            onChange({
                                [PROPS_NOTES]: value,
                            });
                        }}
                        placeholderText={formatMessage(messages.notes)}
                    />
                </div>
            </DialogBox>
        );
    };

    printFuelingDate = (value) => {
        if (value) {
            const formattedDate = formatDate(value, "M/D/YYYY");
            return (
                <TableCell key="formatted-date-cell" title={formattedDate}>
                    {formattedDate}
                </TableCell>
            );
        }
    };

    render() {
        const { formatMessage } = this.props;
        const { record } = this.state;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_FUEL_ADDED_DATE]: {
                                title: formatMessage(messages.date),
                                printer: this.printFuelingDate,
                            },
                            [PROPS_FUEL_ADDED]: {
                                title: formatMessage(messages.fuelAdded),
                            },
                            [PROPS_FUEL_ADDED_UNIT_NAME]: {
                                title: formatMessage(messages.unit),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.fuelingEvent)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(FuelingEventList);
