import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const CircleIcon = withSvgIcon(
    <g id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
        <circle
            style={{
                fill: "none",
                strokeWidth: "1.5px",
                strokeMiterlimit: 10,
                strokeDasharray: "6 2",
            }}
            cx="13.457"
            cy="14.905"
            r="12.955"
        />
        <circle cx="13.457" cy="1.733" r="1.264" />
        <path
            d="M15.405,3.852A1.733,1.733,0,1,1,17.137,2.12,1.735,1.735,0,0,1,15.405,3.852Zm0-2.527a.795.795,0,1,0,.8.8A.8.8,0,0,0,15.405,1.325Z"
            transform="translate(-1.948 -0.387)"
        />
    </g>,
    "0 0 26.914 28.362",
    "disabled-stroke"
);
