import * as model from "./model";
import { createService } from "~/hocs/crud";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import { defaultEquipmentSetupRecord } from "./default-record";
import { EquipmentProfileAPI } from "@ai360/core";
import { HierarchyAPI } from "@ai360/core";

export const MODEL_NAME = "equipmentSetup";
export const URL = EquipmentProfileAPI.FETCH_URL;
export const CREATE = EquipmentProfileAPI.CREATE;
export const UPDATE = EquipmentProfileAPI.UPDATE;
export const GETRECORD = EquipmentProfileAPI.GETRECORD;
export const DELETE = EquipmentProfileAPI.DELETE;
export const AUTO_SEARCH_URL = EquipmentProfileAPI.AUTO_SEARCH_URL;
export const EQUIPMENT_FILTER_URL = EquipmentProfileAPI.EQUIPMENT_FILTER_URL;
export const MANUFACTURER_FILTER_URL = EquipmentProfileAPI.GET_MANUFACTURER_FILTER_LIST;
export const MODEL_FILTER_URL = EquipmentProfileAPI.GET_EQUIPMENT_AGBYTES_LIST;
export const GET_AGBYTES_EQUIPMENT_URL = EquipmentProfileAPI.GET_AGBYTES_EQUIPMENT_URL;
export const REQUEST_ORG_LEVEL = HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS;
export const ONSITE_USER_SEARCH_URL = EquipmentProfileAPI.GET_ONSITE_USER_LIST;

export const EQUIPMENT_FILTER = "equipmentFilter";
export const MANUFACTURER_FILTER = "manufacturerFilter";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "equipmentProfileFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "equipmentprofileSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "equipmentProfilePageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

export const EQUIPMENT_PARENT_ID_KEYSET = {
    Accessories: "2",
    Applicator: "1",
    Harvesting: "3",
    HayOrForage: "4",
    Irrigation: "5",
    Other: "9",
    Planting: "6",
    Tillage: "7",
    Tractor: "8",
};

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        equipmentName: "",
        equipmentParentType: "",
        equipmentType: "",
        isActive: "",
        manufacturer: "",
        modelName: "",
        modifiedDate: "",
        orgLevelGuid: null,
        ownerOperatorName: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "EquipmentParentType",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const { getPickListCode } = picklistService;
export const pickLists = {
    [picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE]: getPickListCode(
        picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE
    ),
    [picklistService.PICKLIST_CLASS_SIZE]: getPickListCode(picklistService.PICKLIST_CLASS_SIZE),
    [picklistService.PICKLIST_DRIVE_TRAIN]: getPickListCode(picklistService.PICKLIST_DRIVE_TRAIN),
    [picklistService.PICKLIST_FUEL_TYPE]: getPickListCode(picklistService.PICKLIST_FUEL_TYPE),
    [picklistService.PICKLIST_HITCH_TYPE]: getPickListCode(picklistService.PICKLIST_HITCH_TYPE),
    [picklistService.PICKLIST_MAINTENANCE_TYPE]: getPickListCode(
        picklistService.PICKLIST_MAINTENANCE_TYPE
    ),
    [picklistService.PICKLIST_PHYSICAL_STATE]: getPickListCode(
        picklistService.PICKLIST_PHYSICAL_STATE
    ),
    [picklistService.PICKLIST_TIRES_TYPE]: getPickListCode(picklistService.PICKLIST_TIRES_TYPE),
    [picklistService.PICKLIST_TRANSMISSION_TYPE]: getPickListCode(
        picklistService.PICKLIST_TRANSMISSION_TYPE
    ),
    [picklistService.PICKLIST_USAGE_UNIT]: getPickListCode(picklistService.PICKLIST_USAGE_UNIT),
    [picklistService.PICKLIST_WELL_TYPE]: getPickListCode(picklistService.PICKLIST_WELL_TYPE),
};

const { getUnitCode } = unitService;
export const unitTypes = {
    [unitService.UNIT_AREA]: getUnitCode(unitService.UNIT_AREA),
    [unitService.UNIT_FLOW_RATE]: getUnitCode(unitService.UNIT_FLOW_RATE),
    [unitService.UNIT_FUEL_EFFICIENCY]: getUnitCode(unitService.UNIT_FUEL_EFFICIENCY),
    [unitService.UNIT_LENGTH]: getUnitCode(unitService.UNIT_LENGTH),
    [unitService.UNIT_POWER]: getUnitCode(unitService.UNIT_POWER),
    [unitService.UNIT_PRESSURE]: getUnitCode(unitService.UNIT_PRESSURE),
    [unitService.UNIT_SPEED]: getUnitCode(unitService.UNIT_SPEED),
    [unitService.UNIT_TIME]: getUnitCode(unitService.UNIT_TIME),
    [unitService.UNIT_VOLUME]: getUnitCode(unitService.UNIT_VOLUME),
    [unitService.UNIT_WEIGHT]: getUnitCode(unitService.UNIT_WEIGHT),
};

export const dropdowns = {
    [model.PROPS_MANUFACTURER]: {
        url: MANUFACTURER_FILTER_URL,
        model: {
            EquipmentParentType: "",
            EquipmentType: "",
        },
    },
    [model.PROPS_MODEL_NAME]: {
        url: MODEL_FILTER_URL,
        model: {
            equipmentTypeValue: "",
            manufacturerValue: "",
            modelNameValue: "",
        },
    },
    [EQUIPMENT_FILTER]: {
        url: EQUIPMENT_FILTER_URL,
        model: {
            EquipmentParentTypeId: "2",
            EquipmentType: "",
        },
    },
    [MANUFACTURER_FILTER]: {
        url: MANUFACTURER_FILTER_URL,
        model: {
            EquipmentParentTypeId: "2",
            EquipmentType: "",
        },
    },
    [model.PROPS_ORG_LEVEL]: { url: REQUEST_ORG_LEVEL },
    [model.PROPS_ONSITE_USER_LIST]: { url: ONSITE_USER_SEARCH_URL, model: "_" },
};

export const service = createService({
    id: model.PROPS_EQUIPMENT_ID,
    guid: model.PROPS_EQUIPMENT_PROFILE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    EQUIPMENT_PARENT_ID_KEYSET,
    urls: {
        URL,
        CREATE,
        UPDATE,
        AUTO_SEARCH_URL,
        DELETE,
        GETRECORD,
        MODEL_FILTER_URL,
        MANUFACTURER_FILTER_URL,
    },
    pickLists,
    unitTypes,
    _defaultLabels: {
        [model.PROPS_EQUIPMENT_NAME]: { label: "equipmentName", gridCol: 15 },
        [model.PROPS_MANUFACTURER]: { label: "manufacturer", gridCol: 10 },
        [model.PROPS_MODEL_NAME]: { label: "modelName", gridCol: 10 },
        [model.PROPS_OWNER_OPERATOR_NAME]: {
            label: "ownerAndOperator",
            gridCol: 10,
        },
        [model.PROPS_EQUIPMENT_PARENT_TYPE]: {
            label: "equipmentParentType",
            gridCol: 15,
        },
        [model.PROPS_EQUIPMENT_TYPE]: {
            label: "equipmentTypeName",
            gridCol: 10,
        },
        [model.ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "lastModified" },
    },
    getDefaultRecord: () => Object.assign({}, defaultEquipmentSetupRecord()),
    defaultSort,
});
