import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl, FormattingHelpers } from "@ai360/core";

import {
    PICKLIST_CROP_PURPOSE,
    PICKLIST_APPLICATION_TIMING,
    PICKLIST_APPLICATION_METHOD,
    PICKLIST_SOIL_TEXTURE,
    PICKLIST_TILLAGE_METHOD,
    PICKLIST_INCORPORATION_DEPTH,
    PICKLIST_BUILD_FACTOR,
    PICKLIST_TARGET_PH,
    PICKLIST_REC_OPTION,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";

export const MODEL_NAME = "equationList";
export const PRODUCT_DROPDOWN = "product";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "equationFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "equationSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "equationPageOptions";
export const REQUEST_PAYLOAD_PARENT_GUID = "equationGroupGuid";

export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetEquationAutoSearchList");
export const CREATE = apiUrl("AgBytes/AddEquation");
export const DELETE = apiUrl("AgBytes/DeleteEquationList");
export const GETRECORD = apiUrl("AgBytes/GetEquation");
export const NEXT_ID = apiUrl("AgBytes/getNextEquationId");
export const UPDATE = apiUrl("AgBytes/UpdateEquation");
export const URL = apiUrl("AgBytes/GetEquationList");

// Dropdowns
export const REQUEST_NUTRIENT = apiUrl("AgBytes/GetNutrientDropDownList");
export const REQUEST_CROP = apiUrl("AgBytes/GetCropAgBytesList");
export const REQUEST_CROP_CLASS = apiUrl("AgBytes/GetCropClassAgBytesList");
export const REQUEST_PRODUCT = apiUrl("AgBytes/GetProductAgBytesList");

export const YES_NO = "YesNo";
export const DECIMAL = "Decimal";
export const TEXT = "Text";

const parameterTypes = {
    yesNo: YES_NO,
    decimal: DECIMAL,
    text: TEXT,
};

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        ModifiedDate: "",
        canDelete: "",
        cropClassName: "",
        cropName: "",
        cropPurpose: "",
        equationFilters: "",
        equationId: "",
        equationName: "",
        isActive: "",
        nutrientName: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_PARENT_GUID]: "",
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "EquationName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const defaultEquation = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_CAN_DELETE]: false,
    [model.PROPS_CROP_LIST]: [],
    [model.PROPS_DESCRIPTION]: "",
    [model.PROPS_EQUATION]: "",
    [model.PROPS_EQUATION_GROUP_GUID]: "",
    [model.PROPS_EQUATION_GROUP_ID]: "",
    [model.PROPS_EQUATION_GROUP_NAME]: "",
    [model.PROPS_EQUATION_GROUP_TYPE]: "",
    [model.PROPS_EQUATION_GUID]: "",
    [model.PROPS_EQUATION_NAME]: "",
    [model.PROPS_IMPORT_ATTRIBUTE_LIST]: [],
    [model.PROPS_IS_OXIDE]: false,
    [model.PROPS_NUTRIENT_GUID]: "",
    [model.PROPS_PARAMETER_LIST]: [],
    [model.PROPS_PRODUCT_LIST]: [],
    [model.PROPS_REC_OUTPUT]: "",
    [model.PROPS_APPLICATION_TIMING_GUID]: "",
    [model.PROPS_APPLICATION_METHOD_GUID]: "",
    [model.PROPS_SOIL_TEXTURE_GUID]: "",
    [model.PROPS_TILLAGE_METHOD_GUID]: "",
    [model.PROPS_INCORPORATION_DEPTH_GUID]: "",
    [model.PROPS_BUILD_FACTOR_GUID]: "",
    [model.PROPS_TARGET_PH_GUID]: "",
    [model.PROPS_REC_OPTION_GUID]: "",
});

export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
    [PICKLIST_APPLICATION_TIMING]: getPickListCode(PICKLIST_APPLICATION_TIMING),
    [PICKLIST_APPLICATION_METHOD]: getPickListCode(PICKLIST_APPLICATION_METHOD),
    [PICKLIST_SOIL_TEXTURE]: getPickListCode(PICKLIST_SOIL_TEXTURE),
    [PICKLIST_TILLAGE_METHOD]: getPickListCode(PICKLIST_TILLAGE_METHOD),
    [PICKLIST_INCORPORATION_DEPTH]: getPickListCode(PICKLIST_INCORPORATION_DEPTH),
    [PICKLIST_BUILD_FACTOR]: getPickListCode(PICKLIST_BUILD_FACTOR),
    [PICKLIST_TARGET_PH]: getPickListCode(PICKLIST_TARGET_PH),
    [PICKLIST_REC_OPTION]: getPickListCode(PICKLIST_REC_OPTION),
};

export const dropdowns = {
    [model.PROPS_NUTRIENT_NAME]: REQUEST_NUTRIENT,
    [model.PROPS_CROP_NAME]: REQUEST_CROP,
    [model.PROPS_CROP_CLASS_NAME]: {
        url: REQUEST_CROP_CLASS,
        model: "00000000-0000-0000-0000-000000000000",
    },
    [PRODUCT_DROPDOWN]: REQUEST_PRODUCT,
};

const printer = {
    [model.PROPS_CROP_PURPOSE]: (value) => {
        return FormattingHelpers.formatDelimiterString(value);
    },
    [model.PROPS_CROP_CLASS_NAME]: (value) => {
        return FormattingHelpers.formatDelimiterString(value);
    },
    [model.PROPS_CROP_NAME]: (value) => {
        return FormattingHelpers.formatDelimiterString(value);
    },
};

export const service = createService({
    guid: model.PROPS_EQUATION_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    parameterTypes,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    REQUEST_PAYLOAD_PARENT_GUID,
    urls: {
        AUTO_SEARCH_URL,
        CREATE,
        DELETE,
        GETRECORD,
        NEXT_ID,
        UPDATE,
        URL,
    },
    dropdowns,
    printer,
    _defaultLabels: {
        [model.PROPS_EQUATION_ID]: { label: "equationId", gridCol: 5 },
        [model.PROPS_EQUATION_NAME]: { label: "equationName", gridCol: 20 },
        [model.PROPS_CROP_NAME]: { label: "cropName", gridCol: 15 },
        [model.PROPS_CROP_CLASS_NAME]: { label: "cropClassName", gridCol: 10 },
        [model.PROPS_CROP_PURPOSE]: { label: "cropPurpose", gridCol: 10 },
        [model.PROPS_DESCRIPTION]: { label: "description", gridCol: 25 },
        [model.PROPS_EQUATION_FILTERS]: {
            label: "equationFilters",
            gridCol: 10,
        },
        [model.PROPS_NUTRIENT_ELEMENT]: { label: "nutrient", gridCol: 5 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: { label: "canDelete", gridCol: 5 },
    },
    getDefaultRecord: () => ({ ...defaultEquation() }),
    defaultSort,
});
