import React, { Component } from "react";
import classNames from "classnames";
import { Menu, NoLink } from "~/core";
import { FileImportAPI, UserAPI } from "@ai360/core";
import { keywords } from "~/action-panel/components/common/planting/keywords";
import { config as intlConfig } from "~/intl-provider/config";
import { messages } from "./filters/i18n-messages";
import { withUser } from "~/hocs";
import { injectIntl, intlShape } from "react-intl";
import "./import-filter.css";
import { logFirebaseEvent } from "~/utils/firebase";

interface ItemProperty {
    cellSize?: number;
    format?: string;
    propertyName: string | string[];
    propertyGuid: string | string[];
    isReqException?: boolean;
    isRequired: boolean;
    label: string;
}

interface ImportFilterProps {
    additionalValidation?: () => void;
    alterEditColumn?: boolean;
    isApplication?: boolean;
    isHarvest?: boolean;
    isPlanting?: boolean;
    filterTypeName?: string;
    className?: string;
    itemProperties: ItemProperty[];
    filterItems:
        | FileImportAPI.CropFilterItem[]
        | FileImportAPI.EquipmentFilterItem[]
        | FileImportAPI.LayerFilterItem[]
        | FileImportAPI.ProductFilterItem[]
        | FileImportAPI.SeasonFilterItem[];
    selectItem: (
        item:
            | FileImportAPI.CropFilterItem
            | FileImportAPI.EquipmentFilterItem
            | FileImportAPI.LayerFilterItem
            | FileImportAPI.ProductFilterItem
            | FileImportAPI.SeasonFilterItem,
        itemIndex: number
    ) => void;
    onEditAttribute: (
        item:
            | FileImportAPI.CropFilterItem
            | FileImportAPI.EquipmentFilterItem
            | FileImportAPI.LayerFilterItem
            | FileImportAPI.ProductFilterItem
            | FileImportAPI.SeasonFilterItem
    ) => void;
    onAddAlias: (item: FileImportAPI.CropFilterItem, index: number) => void;
    updateFilterStatus: (hasNoMatch: boolean) => void;
    userInfo: UserAPI.IUser;
    title: string;
    selectedFieldGuid: string;
    intl: intlShape;
    isEquipmentInfo: boolean;
    handleEquipmentAddEdit: (
        item:
            | FileImportAPI.CropFilterItem
            | FileImportAPI.EquipmentFilterItem
            | FileImportAPI.LayerFilterItem
            | FileImportAPI.ProductFilterItem
            | FileImportAPI.SeasonFilterItem
    ) => void;
    equipmentAddEditChecker: (
        item:
            | FileImportAPI.CropFilterItem[]
            | FileImportAPI.EquipmentFilterItem[]
            | FileImportAPI.LayerFilterItem[]
            | FileImportAPI.ProductFilterItem[]
            | FileImportAPI.SeasonFilterItem[]
    ) => void;
    removeImportFiles: (importFileGuidList: string[]) => void;
    assignMatchedToUnmatched: (index: number) => void;
    userRole: UserAPI.IUserRole;
}

class ImportFilter_ extends Component<ImportFilterProps> {
    static defaultProps = {
        selectedFieldGuid: "",
    };

    static hasNoMatchProp(props) {
        const { additionalValidation } = props;
        return props.filterItems.some((item) => {
            return (
                (additionalValidation && !additionalValidation(item)) ||
                props.itemProperties.some((itemProp) => {
                    if (Array.isArray(itemProp.propertyGuid)) {
                        return (
                            itemProp.isRequired &&
                            itemProp.propertyGuid.some((prop) => {
                                return !item[prop];
                            })
                        );
                    }
                    return itemProp.isRequired && !item[itemProp.propertyGuid];
                })
            );
        });
    }

    componentDidMount() {
        this.props.updateFilterStatus(!ImportFilter_.hasNoMatchProp(this.props));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.props.updateFilterStatus(!ImportFilter_.hasNoMatchProp(nextProps));
    }

    getHeaderItems() {
        return this.props.itemProperties.map((itemProp, index) => {
            return (
                <div
                    key={"header-" + index}
                    className={classNames("import-filter-cell", "import-cell-" + itemProp.cellSize)}
                >
                    <span
                        className={classNames("import-filter-header-label", {
                            required: itemProp.isRequired,
                        })}
                    >
                        {!itemProp.isRequired ? null : <span className="red-star">* </span>}
                        {itemProp.label}
                    </span>
                </div>
            );
        });
    }

    getBodyItems() {
        const { formatMessage, formatNumber } = this.props.intl;
        const { isEquipmentInfo } = this.props;
        const filterItems = this.props.selectedFieldGuid
            ? [...this.props.filterItems].filter((item) => {
                  return (
                      typeof item.fieldGuid === "undefined" ||
                      item.fieldGuid === this.props.selectedFieldGuid
                  );
              })
            : this.props.filterItems;

        return filterItems.map((item, itemIndex) => {
            let hasNoMatchProps = false;
            const rowItems = this.props.itemProperties.map((itemProp, itemPropIndex) => {
                const prefix =
                    this.props.isPlanting &&
                    itemProp.label === messages.brandVarietyText.defaultMessage &&
                    (item as FileImportAPI.CropFilterItem).aliasVarietyHybridName
                        ? "*"
                        : "";
                const propName = Array.isArray(itemProp.propertyName)
                    ? itemProp.propertyName
                          .reduce((acc, prop) => {
                              return acc !== "" ? acc + " - " + item[prop] : item[prop];
                          }, "")
                          .trim()
                    : item[itemProp.propertyName];

                let formattedValue = prefix && propName ? prefix + propName : propName;
                const noMatch = Boolean(
                    (itemProp.isRequired || itemProp.isReqException) &&
                        ((Array.isArray(itemProp.propertyGuid) &&
                            itemProp.propertyGuid.some((prop) => !item[prop])) ||
                            (!Array.isArray(itemProp.propertyGuid) &&
                                itemProp.propertyGuid &&
                                !item[itemProp.propertyGuid]))
                );

                if (noMatch && !propName && itemPropIndex === 0) {
                    formattedValue = formatMessage(messages.noMatchText);
                } else if (
                    itemProp.format &&
                    itemProp.format.toLowerCase() === "numeric" &&
                    propName
                ) {
                    formattedValue =
                        this.#isSeedingRate(item) &&
                        this.#isSeedPerAcreWithValue(item as FileImportAPI.CropFilterItem)
                            ? formatNumber(propName, intlConfig.wholeNumberFormatOptions)
                            : formatNumber(propName);
                }
                hasNoMatchProps = hasNoMatchProps || noMatch;
                return (
                    <div
                        key={"body-" + itemIndex + "-" + itemPropIndex}
                        className={classNames(
                            "import-filter-cell",
                            "import-cell-" + itemProp.cellSize,
                            {
                                "no-match": noMatch,
                                "equipment-info-no-match": this.checkMatch(
                                    item,
                                    formattedValue,
                                    itemPropIndex
                                ),
                            }
                        )}
                        title={formattedValue}
                    >
                        <div className="import-cell-text">{formattedValue}</div>
                    </div>
                );
            });
            const editColumnClassName = this.props.alterEditColumn
                ? "import-cell-10"
                : "import-cell-15";
            return (
                <div
                    key={"filter-item-" + itemIndex}
                    className={classNames(
                        "import-filter-item",
                        {
                            "no-match":
                                (item.hasLookupMatch !== undefined && !item.hasLookupMatch) ||
                                hasNoMatchProps,
                        },
                        { selected: item.isSelected }
                    )}
                    onClick={(evt: any) => {
                        if (evt.target.closest(".context-menu") == null) {
                            this.props.selectItem(item, itemIndex);
                        }
                    }}
                >
                    <div className={classNames("filter-link-container", editColumnClassName)}>
                        {!this.editChecker(item) ? null : (
                            <NoLink
                                className="filter-link"
                                label={
                                    isEquipmentInfo
                                        ? formatMessage(messages.addText)
                                        : formatMessage(messages.editText)
                                }
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    isEquipmentInfo
                                        ? this.props.handleEquipmentAddEdit(item)
                                        : this.props.onEditAttribute(item);
                                }}
                            />
                        )}
                    </div>
                    {rowItems}
                    <div className={classNames("import-filter-item-delete", "import-cell-3")}>
                        <Menu
                            className="context-menu"
                            isDotMenu={true}
                            getMenuItems={() => this._getMenuItems(item, itemIndex)}
                        />
                    </div>
                </div>
            );
        });
    }

    #isSeedingRate(
        item:
            | FileImportAPI.CropFilterItem
            | FileImportAPI.EquipmentFilterItem
            | FileImportAPI.LayerFilterItem
            | FileImportAPI.ProductFilterItem
            | FileImportAPI.SeasonFilterItem
    ): item is FileImportAPI.CropFilterItem {
        return "seedingRate" in item;
    }

    #isSeedPerAcreWithValue(item: FileImportAPI.CropFilterItem) {
        const isSeedingRate = item.seedingRate;
        const isSeedPerAcre =
            item.seedingRateUnitAbbreviation &&
            item.seedingRateUnitAbbreviation === keywords.seedPerAc;
        return isSeedingRate && isSeedPerAcre;
    }
    _isSingleImportFile() {
        const importFileSet = new Set();
        this.props.filterItems.forEach((item) => {
            item.importFileGuidList.forEach((importFileGuid) => {
                importFileSet.add(importFileGuid);
            });
        });
        return this.props.filterItems.length <= 1 || importFileSet.size <= 1;
    }

    _getUnmatchedItems() {
        return [...this.props.filterItems].filter((x) => !x.hasLookupMatch);
    }

    _needsAssignToAll(index) {
        return (
            (this.props.isApplication ||
                this.props.isHarvest ||
                this.props.isPlanting ||
                this.props.isEquipmentInfo) &&
            this.props.assignMatchedToUnmatched != null &&
            this._getUnmatchedItems().length > 0 &&
            this.props.filterItems[index].hasLookupMatch &&
            this.props.userRole.assignToAll
        );
    }

    _needsAddAlias(item) {
        return (
            this.props.isPlanting &&
            this.props.userRole.importAliasName &&
            this.props.onAddAlias &&
            item.varietyHybridGuid != null
        );
    }

    _getMenuItems(item, index) {
        const { formatMessage } = this.props.intl;
        const menuItems = [];

        if (this._needsAssignToAll(index)) {
            menuItems.push({
                label: formatMessage(messages.assignToAllText),
                action: () => {
                    logFirebaseEvent("import_assign_to_all");
                    this.onAssignToAll(index);
                },
            });
        }

        if (this._needsAddAlias(item)) {
            const labelText = item.aliasVarietyHybridName
                ? messages.editAliasText
                : messages.addAliasText;
            menuItems.push({
                label: formatMessage(labelText),
                action: () => this.props.onAddAlias(item, index),
            });
        }

        menuItems.push({
            label: formatMessage(messages.removeText),
            action: () => this.onRemoveImportFilterItem(index),
            disabled: this._isSingleImportFile(),
        });

        return menuItems.map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    onRemoveImportFilterItem(index) {
        this.props.removeImportFiles(this.props.filterItems[index].importFileGuidList);
    }

    onAssignToAll(index) {
        this.props.assignMatchedToUnmatched(index);
    }

    checkMatch(item, formattedValue, itemPropIndex) {
        const { formatMessage } = this.props.intl;
        const { isEquipmentInfo, itemProperties } = this.props;
        if (
            isEquipmentInfo &&
            formattedValue === formatMessage(messages.noMatchText) &&
            !itemPropIndex &&
            !item[itemProperties[1].propertyName as string]
        ) {
            return true;
        }
        return false;
    }

    editChecker(item) {
        const { isEquipmentInfo } = this.props;
        if (item.isSelected) {
            if (isEquipmentInfo) {
                return this.props.equipmentAddEditChecker(item);
            }
            return true;
        }
    }

    render() {
        const headerItems = this.getHeaderItems();
        const bodyItems = this.getBodyItems();
        return (
            <div className={classNames("import-filter", this.props.className)}>
                {!this.props.title ? null : (
                    <div className="import-filter-title">{this.props.title}</div>
                )}
                <div className="import-filter-header">
                    <div
                        className={this.props.alterEditColumn ? "import-cell-10" : "import-cell-15"}
                    ></div>
                    {headerItems}
                    <div className={"import-cell-3"}></div>
                </div>
                <div className="import-filter-body">{bodyItems}</div>
            </div>
        );
    }
}
export const ImportFilter = injectIntl(withUser(ImportFilter_));
