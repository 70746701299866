import React, { ReactNode, MouseEvent as ReactMouseEvent, ReactElement } from "react";
import classnames from "classnames";

export interface ITableCellProps {
    children?: ReactNode | ReactNode[];
    position?: string;
    title?: string;
    className?: string;
    onClick?: (event: ReactMouseEvent) => void;
}

const TableCell = ({
    children,
    position,
    className,
    title,
    onClick,
}: ITableCellProps): ReactElement => {
    const baseClassName = position ? "data-table-cell-absolute" : "data-table-cell-relative";
    return (
        <div
            className={classnames("data-table-cell", baseClassName, className)}
            title={title}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default TableCell;
