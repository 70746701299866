import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { onNumberChange, onTextChange, preventBubbleUp } from "~/admin/utils";
import { Button, NumericInput, Section } from "~/core";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { getDefaultRecord, model } from "./data";
import { messages } from "../i18n-messages";
import { keywords } from "~/admin/containers/keywords";

// Style
import "./import-filter-settings.css";

export class ImportFilterSettings extends Component {
    static propTypes = {
        closeSlidingPanel: PropTypes.func,
        fetchImportFilter: PropTypes.func,
        needs: PropTypes.func,
        saveImportFilter: PropTypes.func,
        importFilterData: PropTypes.object,
        apiError: PropTypes.object,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.importFilterData = {};
        this.importFilterSaveRequestId = null;
    }

    componentDidMount() {
        this.importFilterData = getDefaultRecord();
        const { needs } = this.props;
        needs([this.props.fetchImportFilter()]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.importFilterData !== this.props.importFilterData) {
            this.importFilterData = nextProps.importFilterData;
        }
        this._processSaveImportFilterData(nextProps);
    }

    _processSaveImportFilterData = (nextProps) => {
        if (nextProps.requestIds[this.importFilterSaveRequestId] === keywords.SUCCESS) {
            this.props.closeSlidingPanel();
            this.importFilterSaveRequestId = null;
        } else if (nextProps.requestIds[this.importFilterSaveRequestId] === keywords.FAILURE) {
            this.importFilterSaveRequestId = null;
        }
    };

    _onNumberChange = (formKey, value, callback) => {
        this.importFilterData = onNumberChange(
            this.importFilterData,
            { formKey: [formKey], value },
            callback
        );
    };

    _onTextChange = (formKey, value, callback) => {
        this.importFilterData = onTextChange(
            this.importFilterData,
            { formKey: [formKey], value },
            callback
        );
    };

    _onSave = () => {
        if (this.importFilterSaveRequestId === null) {
            const { needs, saveImportFilter } = this.props;
            this.importFilterSaveRequestId = needs([saveImportFilter(this.importFilterData)]);
        }
    };

    _renderDetailInfo1 = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.yield)}
                >
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.yieldUpperPercentile)}
                        labelText={formatMessage(messages.yieldUpperPercentile)}
                        value={this.importFilterData[model.PROPS_YIELD_UPPER_PERCENTILE]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_YIELD_UPPER_PERCENTILE, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.yieldLowerPercentile)}
                        labelText={formatMessage(messages.yieldLowerPercentile)}
                        value={this.importFilterData[model.PROPS_YIELD_LOWER_PERCENTILE]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_YIELD_LOWER_PERCENTILE, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.yieldScale)}
                        labelText={formatMessage(messages.yieldScale)}
                        value={this.importFilterData[model.PROPS_YIELD_SCALE]}
                        onChange={(value) => this._onNumberChange(model.PROPS_YIELD_SCALE, value)}
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.yieldMinimumAbsolute)}
                        labelText={formatMessage(messages.yieldMinimumAbsolute)}
                        value={this.importFilterData[model.PROPS_YIELD_MINIMUM_ABSOLUTE]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_YIELD_MINIMUM_ABSOLUTE, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.minimumSwathWidth)}
                        labelText={formatMessage(messages.minimumSwathWidth)}
                        value={this.importFilterData[model.PROPS_MINIMUM_SWATH_WIDTH]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_MINIMUM_SWATH_WIDTH, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.startPassDelay)}
                        labelText={formatMessage(messages.startPassDelay)}
                        value={this.importFilterData[model.PROPS_START_PASS_DELAY_SECONDS]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_START_PASS_DELAY_SECONDS, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.endPassDelay)}
                        labelText={formatMessage(messages.endPassDelay)}
                        value={this.importFilterData[model.PROPS_END_PASS_DELAY_SECONDS]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_END_PASS_DELAY_SECONDS, value)
                        }
                        required
                    />
                </Section>
            </div>
        );
    };

    _renderDetailInfo2 = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.speed)}
                >
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.speedUpperPercentile)}
                        labelText={formatMessage(messages.speedUpperPercentile)}
                        value={this.importFilterData[model.PROPS_SPEED_UPPER_PERCENTILE]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_SPEED_UPPER_PERCENTILE, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.speedLowerPercentile)}
                        labelText={formatMessage(messages.speedLowerPercentile)}
                        value={this.importFilterData[model.PROPS_SPEED_LOWER_PERCENTILE]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_SPEED_LOWER_PERCENTILE, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.speedScale)}
                        labelText={formatMessage(messages.speedScale)}
                        value={this.importFilterData[model.PROPS_SPEED_SCALE]}
                        onChange={(value) => this._onNumberChange(model.PROPS_SPEED_SCALE, value)}
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.speedMinimumAbsolute)}
                        labelText={formatMessage(messages.speedMinimumAbsolute)}
                        value={this.importFilterData[model.PROPS_SPEED_MINIMUM_ABSOLUTE]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_SPEED_MINIMUM_ABSOLUTE, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.yieldSmoothVelocity)}
                        labelText={formatMessage(messages.yieldSmoothVelocity)}
                        value={this.importFilterData[model.PROPS_YIELD_SMOOTH_VELOCITY]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_YIELD_SMOOTH_VELOCITY, value)
                        }
                        required
                    />
                </Section>
            </div>
        );
    };

    _renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.standardDeviation)}
                >
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.yieldStandardDeviation)}
                        labelText={formatMessage(messages.yieldStandardDeviation)}
                        value={this.importFilterData[model.PROPS_YIELD_STANDARD_DEVIATION]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_YIELD_STANDARD_DEVIATION, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.ecDataStandardDeviation)}
                        labelText={formatMessage(messages.ecDataStandardDeviation)}
                        value={this.importFilterData[model.PROPS_EC_DATA_STANDARD_DEVIATION]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_EC_DATA_STANDARD_DEVIATION, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.applicationStandardDeviation)}
                        value={this.importFilterData[model.PROPS_APPLICATION_STANDARD_DEVIATION]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_APPLICATION_STANDARD_DEVIATION, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.plantingStandardDeviation)}
                        value={this.importFilterData[model.PROPS_PLANTING_STANDARD_DEVIATION]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_PLANTING_STANDARD_DEVIATION, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.standardDeviationThreshold)}
                        value={this.importFilterData[model.PROPS_STANDARD_DEVIATION_THRESHOLD]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_STANDARD_DEVIATION_THRESHOLD, value)
                        }
                        required
                    />
                </Section>
                <div className="vertical-section-spacer"></div>
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.maxDistanceAndSwathWidth)}
                >
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.maxDistance)}
                        labelText={formatMessage(messages.maxDistance)}
                        value={this.importFilterData[model.PROPS_MAXIMUM_DISTANCE]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_MAXIMUM_DISTANCE, value)
                        }
                        required
                    />
                    <NumericInput
                        scale={7}
                        precision={16}
                        placeholderText={formatMessage(messages.maxSwathWidth)}
                        labelText={formatMessage(messages.maxSwathWidth)}
                        value={this.importFilterData[model.PROPS_MAXIMUM_SWATH_WIDTH]}
                        onChange={(value) =>
                            this._onNumberChange(model.PROPS_MAXIMUM_SWATH_WIDTH, value)
                        }
                        required
                    />
                </Section>
            </div>
        );
    };

    _renderImportFilterSetting = () => {
        return (
            <div className="add-edit-panel">
                <div className="section-container">
                    {this._renderDetailInfo1()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo2()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo3()}
                </div>
            </div>
        );
    };

    render() {
        const navigateTo = { parentNameCode: "101", childNameCode: "187" };
        return (
            <div className="import-filter-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    <SlidingPanel
                        component={this._renderImportFilterSetting}
                        navigateTo={navigateTo}
                    >
                        <Button type="save" forceSubmit onClick={this._onSave} />
                        <Button
                            type="cancel"
                            className="import-filter-cancel"
                            onClick={this.props.closeSlidingPanel}
                        />
                    </SlidingPanel>
                </form>
            </div>
        );
    }
}

export default injectIntl(ImportFilterSettings);
