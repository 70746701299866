import React, { Component } from "react";
import { messages } from "../../i18n-messages";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { model, service } from "../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";

// Components
import {
    Checkbox,
    NumericInput,
    Section,
    SelectInput,
    SubSection,
    TextArea,
    TextInput,
} from "~/core";
import {
    PICKLIST_BRAND_ORGANIZATION,
    PICKLIST_HERBICIDE_TOLERANCE,
} from "~/core/picklist/picklist-names";
import TraitEvents from "../components/trait-events";
import TraitGenes from "../components/trait-gene";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
// Style
import "./add-edit-panel.css";
// Types
import { IAddEditProps, IAddEditPanelState, TraitGene } from "../interfaces";

export class AddEditPanel extends Component<IAddEditProps & InjectedIntlProps, IAddEditPanelState> {
    trait: TraitGene;
    constructor(props) {
        super(props);
        this.trait = props.record || {};
        this.state = {
            [PICKLIST_BRAND_ORGANIZATION]: [],
            [model.PROPS_CROP_NAME]: [],
            [model.PROPS_TRAIT_GENES]: [],
            nextId: null,
            [model.PROPS_HERBICIDE_TOLERANCE]: [],
        };
    }

    componentDidMount() {
        this.setupTraitRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(),
            this.props.fetchPicklistData(),
            this.props.fetchDropdownData(),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.trait);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.trait = nextProps.record;
            }
        }

        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.trait[model.PROPS_TRAIT_ID] = "" + nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupTraitRecord = () => {
        const { addEditPanel } = this.props;
        this.trait = this.props.record || {};
        if (addEditPanel.mode === "ADD") {
            this.trait = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps) => {
        this.initializeCrop(nextProps);
        this.initializeBrandOrganization(nextProps);
        this.initializeGeneTraits(nextProps);
        this.initializeHerbicideTolerance(nextProps);
    };

    initializeBrandOrganization = (nextProps) => {
        if (nextProps[PICKLIST_BRAND_ORGANIZATION]) {
            this.setState({
                [PICKLIST_BRAND_ORGANIZATION]: prepareSelectableOptions(
                    nextProps[PICKLIST_BRAND_ORGANIZATION],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeHerbicideTolerance = (nextProps) => {
        if (nextProps[PICKLIST_HERBICIDE_TOLERANCE]) {
            this.setState({
                [PICKLIST_HERBICIDE_TOLERANCE]: prepareSelectableOptions(
                    nextProps[PICKLIST_HERBICIDE_TOLERANCE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeGeneTraits = (nextProps) => {
        if (nextProps[model.PROPS_TRAIT_GENES]) {
            this.setState({
                [model.PROPS_TRAIT_GENES]: nextProps[model.PROPS_TRAIT_GENES],
            });
        }
    };

    initializeCrop = (nextProps) => {
        if (nextProps[model.PROPS_CROP_NAME]) {
            this.setState({
                [model.PROPS_CROP_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
                ),
            });
        }
    };

    onPicklistChange = ({ type, guid }, value, callback?: () => void) => {
        this.trait = handlePicklistChange(this.trait, { type, guid, value }, callback, true);
    };

    onTextChange = (formKey, value, callback?: () => void) => {
        if (formKey === model.PROPS_TRAIT_EVENTS || formKey === model.PROPS_TRAIT_GENES) {
            const valueArr = [];
            value.forEach((val) => {
                for (const prop in val) {
                    valueArr.push(val[prop]);
                }
            });
            this.trait = onTextChange(
                this.trait,
                { formKey: [formKey], value: valueArr },
                callback
            );
        } else {
            this.trait = onTextChange(this.trait, { formKey: [formKey], value }, callback);
        }
    };

    onNumberChange = (formKey, value, callback?: () => void) => {
        this.trait = onNumberChange(this.trait, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.trait[model.PROPS_TRAIT_NAME]]);
    };

    renderTraitInfo = () => {
        const { formatMessage } = this.props.intl;
        const { trait } = this;
        return (
            <div className="section-container trait-section">
                <div className="section-container section-column trait-sub-section">
                    <Section>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                scale={0}
                                precision={9}
                                suppressFormatting
                                containerClassNames={getAgBytesErrorClassNames(
                                    [187, 188],
                                    this.props.apiErrors,
                                    ["form-input-id"]
                                )}
                                placeholderText={formatMessage(messages.traitId)}
                                labelText={formatMessage(messages.traitId)}
                                value={trait[model.PROPS_TRAIT_ID]}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_TRAIT_ID, value)
                                }
                                required
                            />
                            {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                                <Checkbox
                                    className="active-checkbox"
                                    value={trait[model.PROPS_ACTIVE_YN]}
                                    label={formatMessage(messages.active)}
                                    onChange={(value) =>
                                        this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                    }
                                />
                            )}
                        </SubSection>
                        <SubSection className="trait-container">
                            <Checkbox
                                value={trait[model.PROPS_TRAIT_GMO]}
                                label={formatMessage(messages.gmo)}
                                autoFocus={true}
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_TRAIT_GMO, value)
                                }
                            />
                            <TextInput
                                tabIndex={0}
                                maxLength={50}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [185, 186],
                                    this.props.apiErrors
                                )}
                                placeholderText={formatMessage(messages.traitName)}
                                value={trait[model.PROPS_TRAIT_NAME] || ""}
                                onChange={(value) =>
                                    this.onTextChange(
                                        model.PROPS_TRAIT_NAME,
                                        value,
                                        this.setHeaderText
                                    )
                                }
                                required
                            />
                        </SubSection>
                    </Section>
                </div>
                <div className="section-container section-column trait-section-align trait-sub-section">
                    <Section>
                        <SubSection className="form-section-row-2">
                            <TextInput
                                tabIndex={0}
                                maxLength={25}
                                placeholderText={formatMessage(messages.abbreviation)}
                                value={trait[model.PROPS_TRAIT_ABBREVIATION] || ""}
                                onChange={(value) =>
                                    this.onTextChange(model.PROPS_TRAIT_ABBREVIATION, value)
                                }
                            />
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[PICKLIST_BRAND_ORGANIZATION]}
                                placeholderText={formatMessage(messages.brand)}
                                value={mapToPicklistValue({
                                    options: this.state[PICKLIST_BRAND_ORGANIZATION],
                                    selectedGuid: trait[model.PROPS_TRAIT_BRAND_ORGANIZATION_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_TRAIT_BRAND_ORGANIZATION,
                                            guid: model.PROPS_TRAIT_BRAND_ORGANIZATION_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        </SubSection>
                    </Section>
                </div>
                <div className="section-container section-column trait-section-align trait-sub-section">
                    <Section>
                        <SubSection className="form-section-row-2">
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[PICKLIST_HERBICIDE_TOLERANCE]}
                                placeholderText={formatMessage(messages.herbicideTolerance)}
                                value={mapToPicklistValue({
                                    options: this.state[PICKLIST_HERBICIDE_TOLERANCE],
                                    selectedGuid: trait[model.PROPS_HERBICIDE_TOLERANCE_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_HERBICIDE_TOLERANCE,
                                            guid: model.PROPS_HERBICIDE_TOLERANCE_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        </SubSection>
                    </Section>
                </div>
            </div>
        );
    };
    renderDetailInfo1() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.refugeDetails)}>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                140,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_CROP_NAME]}
                            placeholderText={formatMessage(messages.cropIdName)}
                            required
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_CROP_NAME],
                                selectedGuid: this.trait[model.PROPS_CROP_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_CROP_NAME,
                                        guid: model.PROPS_CROP_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={400}
                            placeholderText={formatMessage(messages.refugeRequirements)}
                            value={this.trait[model.PROPS_REFUGE_REQUIREMENTS] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_REFUGE_REQUIREMENTS, value)
                            }
                        />
                    </SubSection>

                    <SubSection>
                        <TextArea
                            maxLength={4000}
                            placeholderText={formatMessage(messages.notes)}
                            value={this.trait[model.PROPS_NOTES || ""]}
                            onChange={(value) => this.onTextChange(model.PROPS_NOTES, value)}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    renderDetailInfo2() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.traitGene, { count: 0 })}
                >
                    <SubSection>
                        <TraitGenes
                            formatMessage={formatMessage}
                            options={this.state[model.PROPS_TRAIT_GENES]}
                            record={(this.trait[model.PROPS_TRAIT_GENES] || []).map((geneName) => ({
                                geneName,
                            }))}
                            itemListAlias={model.PROPS_TRAIT_GENES}
                            onTextChange={(e, value) =>
                                this.onTextChange(model.PROPS_TRAIT_GENES, value)
                            }
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    renderDetailInfo3() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.traitEvent, {
                        count: 0,
                    })}
                >
                    <SubSection>
                        <TraitEvents
                            formatMessage={formatMessage}
                            record={(this.trait[model.PROPS_TRAIT_EVENTS] || []).map(
                                (eventName) => ({ eventName })
                            )}
                            itemListAlias={model.PROPS_TRAIT_EVENTS}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }
    render() {
        return (
            <div className="add-edit-panel">
                {this.renderTraitInfo()}
                <div className="section-container">
                    {this.renderDetailInfo1()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo2()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo3()}
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
