import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { Button, TextInput } from "~/core";
import { AuthenticationAPI, urlBase } from "@ai360/core";

import * as actions from "../actions";
import { getSecurityInfo } from "../selectors";
import { messages } from "../i18n-messages";
import { ISecurityInfo } from "../interfaces";

const { LoginState } = actions;

export const isValidPassword = (pwd: string): boolean => {
    let count = 0;
    count += +new RegExp(/[A-Z]+/).test(pwd);
    count += +new RegExp(/[a-z]+/).test(pwd);
    count += +new RegExp(/[0-9]+/).test(pwd);
    count += +new RegExp(/[!@#$%^&*()_+\-=[\]{}|<>?~.,]+/).test(pwd);

    return pwd.length >= 8 && count >= 3;
};

export interface IResetPassword_Props {
    history: any;
    intl: intlShape;
    onError: (error: Error) => void;
    onLogin: (user: string) => void;
    onSetLoginState: (action: number) => void;
    onSetUsers: (user: string) => void;
    onStartProcessing: () => void;
    onStopProcessing: () => void;
    securityInfo: ISecurityInfo;
}

export interface IResetPassword_State {
    password1: string;
    password2: string;
}

class ResetPassword_ extends Component<IResetPassword_Props, IResetPassword_State> {
    txtPassword1: Record<string, any>;
    txtPassword2: Record<string, any>;

    constructor(props) {
        super(props);
        this.state = {
            password1: "",
            password2: "",
        };
    }

    private _handleError(error) {
        if (this.props.onError) {
            this.props.onError(error);
        }
        this.txtPassword2.input.focus();
        this.props.onStopProcessing();
    }

    private _handleKeyDown(event) {
        if (event.key === "Enter") {
            this.submit();
        }
    }

    private _handleLoginResponse(response) {
        if (response.theUser) {
            this.props.onLogin(response.theUser);
            this.props.history.replace(urlBase);
        } else if (response.theUsers) {
            this.props.onSetUsers(response.theUsers);
            this.props.onSetLoginState(LoginState.SELECT_COMPANY);
            this.props.onStopProcessing();
        }
    }

    public submit() {
        this.props.onStartProcessing();
        const { formatMessage } = this.props.intl;

        if (
            this.state.password1 === this.state.password2 &&
            isValidPassword(this.state.password1)
        ) {
            AuthenticationAPI.resetPassword(this.props.securityInfo.email, this.state.password1)
                .then((response) => this._handleLoginResponse(response))
                .catch((error) => this._handleError(error));
        } else if (this.state.password1 !== this.state.password2) {
            this._handleError(formatMessage(messages.mustMatch));
        } else if (this.state.password1.length === 0) {
            this._handleError(formatMessage(messages.enterNew));
        } else {
            console.assert(!isValidPassword(this.state.password1));
            this._handleError(
                <div>
                    {formatMessage(messages.validChars)}
                    <ul>
                        <li>{formatMessage(messages.validCharsUpperCase)}</li>
                        <li>{formatMessage(messages.validCharsLowerCase)}</li>
                        <li>{formatMessage(messages.validCharsNumber)}</li>
                        <li>{formatMessage(messages.validCharsSpecial)}</li>
                    </ul>
                </div>
            );
        }
    }

    componentDidMount() {
        this.txtPassword1.input.focus();
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <div className="login-input-container">
                    <div className="display-label">{formatMessage(messages.resetLabel)}</div>
                    <TextInput
                        value={this.state.password1}
                        password
                        placeholderText={formatMessage(messages.passwordLbl)}
                        onKeyDown={(e) => this._handleKeyDown(e)}
                        onChange={(password1) => this.setState({ password1 })}
                        ref={(input) => {
                            this.txtPassword1 = input;
                        }}
                    />
                    <TextInput
                        value={this.state.password2}
                        password
                        placeholderText={formatMessage(messages.confirmPasswordLbl)}
                        onKeyDown={(e) => this._handleKeyDown(e)}
                        onChange={(password2) => this.setState({ password2 })}
                        ref={(input) => {
                            this.txtPassword2 = input;
                        }}
                    />
                </div>
                <div className="login-center">
                    <Button
                        className="login-btn"
                        type="submitTextOnly"
                        onClick={() => this.submit()}
                    />
                </div>
            </div>
        );
    }
}
export const ResetPassword = injectIntl(ResetPassword_);

const mapStateToProps = (state) => {
    return {
        securityInfo: getSecurityInfo(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (theUser) => dispatch(actions.setUserInfo(theUser)),
        onSetLoginState: (loginState) => dispatch(actions.setLoginState(loginState)),
        onSetUsers: (theUsers) => dispatch(actions.setUsersInfo(theUsers)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export default connect<
    Partial<IResetPassword_Props>,
    Partial<IResetPassword_Props>,
    Partial<IResetPassword_Props>
>(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
