import PropTypes from "prop-types";
import AgrianId from "./agrian-id";
import Photo from "./photo";
import { model } from "~/admin/agBytes/crop/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_AGGATEWAY_ID]: PropTypes.string,
    [model.PROPS_AGRIAN_ID]: PropTypes.arrayOf(AgrianId),
    [model.PROPS_AGX_ID]: PropTypes.string,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_CROP_ALIAS]: PropTypes.arrayOf(PropTypes.string),
    [model.PROPS_CROP_CLASS_ID]: PropTypes.number,
    [model.PROPS_CROP_CLASS_NAME]: PropTypes.string,
    [model.PROPS_CROP_GUID]: PropTypes.string,
    [model.PROPS_CROP_ID]: PropTypes.number,
    [model.PROPS_CROP_NAME]: PropTypes.string,
    [model.PROPS_CROP_GROUP]: PropTypes.string,
    [model.PROPS_CROP_PHOTO_LIST]: PropTypes.arrayOf(Photo),
    [model.PROPS_DEFAULT_MAP_COLOR]: PropTypes.string,
    [model.PROPS_DIVISION]: PropTypes.string,
    [model.PROPS_FAMILY]: PropTypes.string,
    [model.PROPS_FARM_MARKET_CROP_CODE]: PropTypes.string,
    [model.PROPS_FCICCROP_CODE]: PropTypes.string,
    [model.PROPS_GENUS]: PropTypes.string,
    [model.PROPS_GROUP_NAME]: PropTypes.string,
    [model.PROPS_GROWTH_STAGE_GROUP_ID]: PropTypes.string,
    [model.PROPS_LIFESPAN]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_MORPHOLOGY_TYPE]: PropTypes.string,
    [model.PROPS_OPTIMAL_MOISTURE_AT_HARVEST]: PropTypes.number,
    [model.PROPS_PLANTING_TEMPERATURE_MINIMUM]: PropTypes.string,
    [model.PROPS_SCIENTIFIC_NAME]: PropTypes.string,
    [model.PROPS_TAXONOMICAL_CLASS]: PropTypes.string,
    [model.PROPS_TAXONOMICAL_ORDER]: PropTypes.string,
    [model.PROPS_TEST_WEIGHT]: PropTypes.number,
    [model.PROPS_TISSUE_GROUP_NAME]: PropTypes.string,
    [model.PROPS_TISSUE_GROWTH_STAGE_GROUPID]: PropTypes.string,
};

export default PropTypes.shape(shape);
