import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";

import { Loader, SelectInput, ZeroToInfiniteGrid } from "~/core";
import { messages } from "../../../../i18n-messages";

import "./event-surface-stats-table.css";
import { LayerAPI } from "@ai360/core";

interface IEventSurfaceStatsTableProps {
    tableRecords: any[];
    hidden: boolean;
    id: string;
    intl: intlShape;
    isLoading: boolean;
}

interface IEventSurfaceStatsTableState {
    filteredRecords: any;
    layer: LayerAPI.ILayer;
    layerTitle: string;
    season: any;
    seasonText: string;
    surfaceTitle: string;
    surface: LayerAPI.ISubLayer;
}

class EventSurfaceStatsTable_ extends Component<
    IEventSurfaceStatsTableProps,
    IEventSurfaceStatsTableState
> {
    constructor(props) {
        super(props);
        this.state = {
            layer: null,
            layerTitle: null,
            season: null,
            seasonText: null,
            surface: null,
            surfaceTitle: null,
            filteredRecords: null,
        };
    }

    _layerOptions() {
        const { tableRecords } = this.props;
        const { filteredRecords, season, surface } = this.state;
        if (filteredRecords == null) {
            return [];
        }
        const records = season || surface ? filteredRecords : tableRecords;
        return records
            .reduce((layers, rec) => {
                const existingItem = layers.find((s) => s.value === rec.layer);
                if (!existingItem) {
                    layers.push({
                        label: rec.layer,
                        value: rec.layer,
                    });
                }
                return layers;
            }, [])
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    _seasonOptions() {
        const { tableRecords } = this.props;
        const { filteredRecords, layer, surface } = this.state;
        if (filteredRecords == null) {
            return [];
        }
        const records = layer || surface ? filteredRecords : tableRecords;
        return records
            .reduce((seasons, rec) => {
                const existingItem = seasons.find((s) => s.value === rec.season);
                if (!existingItem) {
                    seasons.push({
                        label: rec.season,
                        value: rec.season,
                    });
                }
                return seasons;
            }, [])
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    _selectLayerOption(layer) {
        this.setState(
            {
                layer,
            },
            () => this._filterRecords()
        );
    }

    _selectLayerText(layerTitle) {
        this.setState({ layerTitle });
    }

    _selectSeasonOption(season) {
        this.setState(
            {
                season,
            },
            () => this._filterRecords()
        );
    }

    _selectSeasonText(seasonText) {
        this.setState({ seasonText });
    }

    _selectSurfaceOption(surface) {
        this.setState(
            {
                surface,
            },
            () => this._filterRecords()
        );
    }

    _selectSurfaceText(surfaceTitle) {
        this.setState({ surfaceTitle });
    }

    _surfaceOptions() {
        const { tableRecords } = this.props;
        const { filteredRecords, layer, season } = this.state;
        if (filteredRecords == null) {
            return [];
        }
        const records = layer || season ? filteredRecords : tableRecords;
        return records
            .reduce((surfaces, rec) => {
                const existingItem = surfaces.find((s) => s.value === rec.surface);
                if (!existingItem) {
                    surfaces.push({
                        label: rec.surface,
                        value: rec.surface,
                    });
                }
                return surfaces;
            }, [])
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { tableRecords } = newProps;
        if (tableRecords.length) {
            this._filterRecords();
        }
    }

    _filterRecords() {
        const { season, layer, surface } = this.state;
        const { tableRecords } = this.props;
        if (tableRecords.length) {
            const filteredRecords = tableRecords.filter((r) => {
                return (
                    (!season || r.season === season) &&
                    (!layer || r.layer === layer) &&
                    (!surface || r.surface === surface)
                );
            });
            this.setState({
                filteredRecords,
            });
        } else {
            this.setState({
                season: null,
                layer: null,
                surface: null,
                filteredRecords: null,
            });
        }
    }

    _getColumns() {
        const { tableRecords } = this.props;
        if (!tableRecords.length) {
            return {};
        }
        const columnNames = Object.keys(tableRecords[0]);
        return columnNames.reduce((columns, col) => {
            columns[col] = {
                title: col,
                label: col,
                className: col,
            };
            return columns;
        }, {});
    }

    render() {
        const { hidden, isLoading, tableRecords } = this.props;
        const { layer, season, surface, filteredRecords } = this.state;
        const { formatMessage } = this.props.intl;
        const selectRow = (
            <div
                key="select-row"
                className={classnames("surface-display-select-row", {
                    "right-aligned-row": true,
                })}
            >
                <SelectInput
                    containerClassNames={["surface-display-layer-list"]}
                    placeholderText={formatMessage(messages.splitScreenSeasonLbl)}
                    value={season}
                    options={this._seasonOptions()}
                    onChange={(s) => this._selectSeasonOption(s)}
                    onInputChange={(s) => this._selectSeasonText(s)}
                />
                <SelectInput
                    containerClassNames={["surface-display-layer-list"]}
                    placeholderText={formatMessage(messages.splitScreenLayerLbl)}
                    value={layer}
                    options={this._layerOptions()}
                    onChange={(l) => this._selectLayerOption(l)}
                    onInputChange={(l) => this._selectLayerText(l)}
                />
                <SelectInput
                    containerClassNames={["surface-display-layer-list"]}
                    placeholderText={formatMessage(messages.splitScreenSurfaceLbl)}
                    value={surface}
                    options={this._surfaceOptions()}
                    onChange={(s) => this._selectSurfaceOption(s)}
                    onInputChange={(s) => this._selectSurfaceText(s)}
                />
            </div>
        );
        const table = tableRecords.length ? (
            <ZeroToInfiniteGrid
                columns={this._getColumns()}
                records={filteredRecords}
                className={classnames("event-surface-stats-table", isLoading ? "hide" : "")}
            />
        ) : null;

        return (
            <div className={classnames("surface-display-container", hidden ? "hide" : "")}>
                {selectRow}
                {table}
                {isLoading ? <Loader className="loader" /> : null}
            </div>
        );
    }
}

export const EventSurfaceStatsTable = injectIntl(EventSurfaceStatsTable_);
