import { getModuleState as getEventInfoModuleState } from "../../selectors";

export const SCOUTING_STATE_KEY = "SCOUTING_STATE";

export const getModuleState = (state: unknown): any =>
    getEventInfoModuleState(state)[SCOUTING_STATE_KEY];

export const getObservationData = (state: unknown): any => getModuleState(state).observationData;

export const getGrowthStageData = (state: unknown): any => getModuleState(state).growthStageData;

export const getGrowthStageObservationData = (state: unknown): any =>
    getModuleState(state).growthStageObservationData;

export const getScoutingDataLoading = (state: unknown): any =>
    getModuleState(state).scoutingDataIsLoading;

export const getScoutingTemplate = (state: unknown): any => getModuleState(state).scoutingTemplate;

export const getScoutingTemplateLoading = (state: unknown): any =>
    getModuleState(state).scoutingTemplateIsLoading;

export const getOriginalScoutingDetailList = (state: unknown): any =>
    getModuleState(state).originalScoutingDetailList;
