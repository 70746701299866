import { all, put, select, takeEvery } from "redux-saga/effects";

import { eventModuleSaga } from "./components/event-module";
import { fieldModuleSaga } from "./components/field-module";
import { importModuleSaga } from "./components/import-module";
import { layerModuleSaga } from "./components/layer-module";
import { recModuleSaga } from "./components/rec-module";
import { varietyHybridListSaga } from "./components/common/planting/variety-hybrid/data/sagas";

import { actions as recsEventsActions } from "~/recs-events";

import * as selectors from "./selectors";
import * as actions from "./actions";

export const onCreateEvent = function* () {
    const activeModule = yield select(selectors.getActiveModule);
    if (activeModule === actions.ActionPanelModuleList.EVENT) {
        return;
    }
    yield put(actions.setActiveModule(actions.ActionPanelModuleList.EVENT));
};

export const onCreateRec = function* () {
    const activeModule = yield select(selectors.getActiveModule);
    if (activeModule === actions.ActionPanelModuleList.REC) {
        return;
    }
    yield put(actions.setActiveModule(actions.ActionPanelModuleList.REC));
};

export const actionPanelSaga = function* () {
    const createEventActions = [
        recsEventsActions.CONVERT_REC_TO_EVENT_SUCCEEDED,
        recsEventsActions.CREATE_NEW_BATCH_EVENT,
        recsEventsActions.CREATE_COPY_BATCH_EVENT_DETAILS,
        recsEventsActions.CREATE_NEW_EVENT_DETAILS,
        recsEventsActions.CREATE_NEW_CLASSIFIED_EVENT_DETAILS,
    ];
    const createRecActions = [
        recsEventsActions.CREATE_NEW_BATCH_REC,
        recsEventsActions.CREATE_NEW_REC_DETAILS,
        recsEventsActions.CREATE_NEW_CLASSIFIED_REC_DETAILS,
    ];

    yield all([
        takeEvery(createEventActions, onCreateEvent),
        takeEvery(createRecActions, onCreateRec),
        eventModuleSaga(),
        fieldModuleSaga(),
        importModuleSaga(),
        layerModuleSaga(),
        recModuleSaga(),
        varietyHybridListSaga(),
    ]);
};
