import { SET_LOCALE, SET_LOCALE_ERRMSG } from "./actions";

const initialState = {
    locale: "en-US",
    errMessage: null,
};

export function localeReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOCALE:
            return {
                ...state,
                locale: action.payload.locale,
            };
        case SET_LOCALE_ERRMSG:
            return {
                ...state,
                errMessage: action.payload.errMessage,
            };
        default:
            return state;
    }
}
