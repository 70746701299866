import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { injectIntl, InjectedIntlProps } from "react-intl";

import { DialogBox, NoLink } from "~/core";
import { ImportTemplate } from "~/file-import/model";

import * as actions from "../actions";
import * as selectors from "../selectors";
import { messages } from "../i18n-messages";

import { UploadFileList } from "./file-list";
import { DragDropIcon } from "./drag-drop";

import "./drag-and-drop-file-uploader.css";

interface DragAndDropFileUploaderStateProps {
    importFileCount?: number;
    isDuplicateFileError?: boolean;
    isPreparingUpload?: boolean;
    selectedTemplate?: ImportTemplate;
    validExtensions?: string[];
}

interface DragAndDropFileUploaderDispatchProps {
    onAddFileList: (fileList: FileList) => void;
    onClearFileList: () => void;
    onSetDuplicateFileError: (isDuplicateFileError: boolean) => void;
}

interface DragAndDropFileUploaderOwnProps {
    stateKey: string;
    dragAndDropNode?: Element;
}

interface DragAndDropFileUploaderState {
    isLoading: boolean;
}

class DragAndDropFileUploader_ extends PureComponent<
    DragAndDropFileUploaderStateProps &
        DragAndDropFileUploaderDispatchProps &
        DragAndDropFileUploaderOwnProps &
        InjectedIntlProps,
    DragAndDropFileUploaderState
> {
    _fileSelectorInput = null;

    constructor(props) {
        super(props);
        this._handleDragOver = this._handleDragOver.bind(this);
        this._handleDrop = this._handleDrop.bind(this);
    }

    componentDidMount(): void {
        this.addEventListeners(this.props.dragAndDropNode);
    }

    componentWillUnmount() {
        this.removeEventListeners(this.props.dragAndDropNode);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dragAndDropNode !== this.props.dragAndDropNode) {
            // It's more likely that we just didn't have the node before, but
            // we'll remove any old listeners just in case.
            this.removeEventListeners(prevProps.dragAndDropNode);
            this.addEventListeners(this.props.dragAndDropNode);
        }
    }

    addEventListeners(dragAndDropNode?: Element) {
        if (!dragAndDropNode) {
            return;
        }
        dragAndDropNode.addEventListener("drop", this._handleDrop, false);
        window.addEventListener("dragover", this._handleDragOver, false);
    }

    removeEventListeners(dragAndDropNode?: Element) {
        if (!dragAndDropNode) {
            return;
        }
        dragAndDropNode.removeEventListener("drop", this._handleDrop, false);
        window.removeEventListener("dragover", this._handleDragOver, false);
    }

    _handleDrop = (event) => {
        if (
            event.target.closest(".drop-message") ||
            event.target.closest(".files-list-container")
        ) {
            event.preventDefault();
            if (event.dataTransfer) {
                this._addFiles(event.dataTransfer.files);
            }
        }
    };

    _handleDragOver = (event) => {
        if (
            event.target.closest(".drop-message") ||
            event.target.closest(".files-list-container")
        ) {
            event.preventDefault();
        }
    };

    _addFiles(browserFileList: FileList): Promise<void> {
        return new Promise<void>((resolve) => {
            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    setTimeout(() => {
                        this.props.onAddFileList(browserFileList);
                        this.setState(
                            {
                                isLoading: false,
                            },
                            () => resolve()
                        );
                    });
                }
            );
        });
    }

    _showFileSelectorInput(): void {
        this._fileSelectorInput.click();
    }

    _getFileList(): JSX.Element {
        return (
            <UploadFileList
                importFileCount={this.props.importFileCount}
                onClearFileList={this.props.onClearFileList}
                onShowFileSelector={() => this._showFileSelectorInput()}
                stateKey={this.props.stateKey}
            />
        );
    }

    _getDropFilesMessage(): JSX.Element {
        const { formatMessage } = this.props.intl;

        return (
            <div className="drop-message drop-enable">
                <DragDropIcon />
                <div className="greyed-text">{formatMessage(messages.dropMessageLine1)}</div>
                <div className="greyed-text">{formatMessage(messages.dropMessageLine2)}</div>
                <NoLink
                    label={formatMessage(messages.dropMessageLine3)}
                    onClick={() => this._showFileSelectorInput()}
                />
            </div>
        );
    }

    render(): JSX.Element {
        const {
            importFileCount,
            isDuplicateFileError,
            isPreparingUpload,
            onSetDuplicateFileError,
            validExtensions,
        } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className="drag-and-drop-file-uploader-container">
                <input
                    ref={(e) => (this._fileSelectorInput = e)}
                    style={{ display: "none" }}
                    type="file"
                    multiple={true}
                    accept={validExtensions.join(",")}
                    onChange={() => {
                        this._addFiles(this._fileSelectorInput.files).then(
                            () => (this._fileSelectorInput.value = "")
                        );
                    }}
                />
                {importFileCount > 0 || isPreparingUpload
                    ? this._getFileList()
                    : this._getDropFilesMessage()}
                {!isDuplicateFileError ? null : (
                    <DialogBox
                        className="error-message-dialog-box"
                        title={formatMessage(messages.alertText)}
                        isOpen={isDuplicateFileError}
                        onClose={() => onSetDuplicateFileError(false)}
                    >
                        {formatMessage(messages.noDuplicateFilesText)}
                    </DialogBox>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps: DragAndDropFileUploaderOwnProps) => ({
    onAddFileList: (fileList) => dispatch(actions.addBrowserFileList(ownProps.stateKey, fileList)),
    onClearFileList: () => dispatch(actions.clearUploadFileList(ownProps.stateKey)),
    onSetDuplicateFileError: (isDuplicateFileError) =>
        dispatch(actions.setDuplicateFileError(ownProps.stateKey, isDuplicateFileError)),
});

const mapStateToProps = (state, ownProps: DragAndDropFileUploaderOwnProps) => {
    const moduleState = selectors.getModuleState(state, ownProps.stateKey);
    const {
        importFileCount,
        isDuplicateFileError,
        isPreparingUpload,
        selectedTemplate,
        validExtensions,
    } = moduleState;
    return {
        importFileCount,
        isDuplicateFileError,
        isPreparingUpload,
        selectedTemplate,
        validExtensions,
    };
};

export const DragAndDropFileUploader = connect<
    DragAndDropFileUploaderStateProps,
    DragAndDropFileUploaderDispatchProps,
    DragAndDropFileUploaderOwnProps
>(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DragAndDropFileUploader_));
