import React, { Component } from "react";
import PropTypes from "prop-types";
import { AttributeEditModal } from "./attribute-edit-modal";
import { ImportFilter } from "../import-filter";
import { SelectInput, Loader } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { ACTIVE_YN } from "~/core/picklist";
import * as actions from "../../../../../actions";
import { messages } from "./i18n-messages";
import {
    PICKLIST_CROPPING_SEASON,
    PICKLIST_CROPPING_SEASON_CYCLE,
} from "~/core/picklist/picklist-names";

class ImportSeasonFilter_ extends Component {
    static propTypes = {
        importFileGuidList: PropTypes.array,
        picklistData: PropTypes.object.isRequired,
        filterItems: PropTypes.array.isRequired,
        selectItem: PropTypes.func.isRequired,
        updateFilterStatus: PropTypes.func.isRequired,
        updateAttribute: PropTypes.func.isRequired,
        selectedFieldGuid: PropTypes.string,
        intl: intlShape.isRequired,
        removeImportFiles: PropTypes.func,
        assignMatchedToUnmatched: PropTypes.func,
    };
    static defaultProps = {
        importFileGuidList: [],
        selectedFieldGuid: null,
    };

    constructor(props) {
        super(props);
        const croppingSeasonOptions = ImportSeasonFilter_.getOptionsFromPicklist(
            props.picklistData[PICKLIST_CROPPING_SEASON]
        );
        const croppingSeasonCycleOptions = ImportSeasonFilter_.getOptionsFromPicklist(
            props.picklistData[PICKLIST_CROPPING_SEASON_CYCLE]
        );
        this.state = {
            editItem: null,
            croppingSeasonOptions: croppingSeasonOptions,
            croppingSeasonCycleOptions: croppingSeasonCycleOptions,
            croppingSeasonGuid: null,
            croppingSeason: "",
            croppingSeasonCycleGuid: null,
            croppingSeasonCycleName: "",
            isSaving: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps[PICKLIST_CROPPING_SEASON] !==
            this.props.picklistData[PICKLIST_CROPPING_SEASON]
        ) {
            const croppingSeasonOptions = ImportSeasonFilter_.getOptionsFromPicklist(
                nextProps.picklistData[PICKLIST_CROPPING_SEASON]
            );
            this.setState({
                croppingSeasonOptions,
            });
        }
        if (
            nextProps[PICKLIST_CROPPING_SEASON_CYCLE] !==
            this.props.picklistData[PICKLIST_CROPPING_SEASON_CYCLE]
        ) {
            const croppingSeasonCycleOptions = ImportSeasonFilter_.getOptionsFromPicklist(
                nextProps.picklistData[PICKLIST_CROPPING_SEASON_CYCLE]
            );
            this.setState({
                croppingSeasonCycleOptions,
            });
        }
    }

    static getOptionsFromPicklist(picklistData) {
        if (!picklistData) {
            return [];
        }
        return picklistData.map((item) => {
            return {
                value: item.picklistValueGuid,
                label: item.value,
            };
        });
    }

    filterProperties = [
        {
            isRequired: true,
            label: this.props.intl.formatMessage(messages.croppingSeasonText),
            propertyName: "croppingSeason",
            propertyGuid: "croppingSeasonGuid",
            cellSize: 30,
        },
        {
            isRequired: false,
            label: this.props.intl.formatMessage(messages.croppingSeasonCycleText),
            propertyName: "croppingSeasonCycleName",
            propertyGuid: "croppingSeasonCycleGuid",
            cellSize: 30,
        },
        {
            isRequired: false,
            label: this.props.intl.formatMessage(messages.featuresText),
            propertyName: "pointCount",
            format: "numeric",
            cellSize: 20,
        },
    ];

    getLabel = ({ options = [], guid = "" }, valueKey = "value", labelKey = "label") => {
        if (options.length) {
            const selectedOption = options.filter((option) => {
                return guid === option[valueKey];
            });
            const label = selectedOption.length && selectedOption[0][labelKey];
            return label;
        }
        return null;
    };

    onEditAttribute = (item) => {
        this.setState({
            editItem: item,
            croppingSeasonGuid: item.croppingSeasonGuid,
            croppingSeasonCycleGuid: item.croppingSeasonCycleGuid,
        });
    };

    onSaveEdit = () => {
        const { editItem } = this.state;
        const updateItems = [];
        editItem.items.forEach((item) => {
            const updateItem = {
                ...item,
                croppingSeason: this.state.croppingSeason,
                croppingSeasonGuid: this.state.croppingSeasonGuid,
                croppingSeasonCycleName: this.state.croppingSeasonCycleName,
                croppingSeasonCycleGuid: this.state.croppingSeasonCycleGuid,
            };
            updateItems.push(updateItem);
        });
        this.props.updateAttribute(updateItems);
        this.onCloseEdit();
    };

    onCloseEdit = () => {
        this.setState({
            editItem: null,
            croppingSeason: null,
            croppingSeasonGuid: null,
            croppingSeasonCycleName: null,
            croppingSeasonCycleGuid: null,
            isSaving: false,
        });
    };

    canSave = () => {
        return Boolean(this.state.croppingSeasonGuid);
    };

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <ImportFilter
                    title={formatMessage(messages.seasonFilterTitle)}
                    itemProperties={this.filterProperties}
                    filterItems={this.props.filterItems}
                    filterTypeName={actions.ImportFilterTypes.CROPPING_SEASONS}
                    selectItem={(item) => {
                        this.props.selectItem(item);
                    }}
                    onEditAttribute={(item) => {
                        this.onEditAttribute(item);
                    }}
                    updateFilterStatus={(isComplete) => this.props.updateFilterStatus(isComplete)}
                    selectedFieldGuid={this.props.selectedFieldGuid}
                    removeImportFiles={(importFileGuidList) =>
                        this.props.removeImportFiles(importFileGuidList)
                    }
                    assignMatchedToUnmatched={null}
                />
                {!this.state.editItem ? null : (
                    <AttributeEditModal
                        className="season-edit-modal"
                        title={formatMessage(messages.editCroppingSeasonText)}
                        isOpen={true}
                        canSave={this.canSave()}
                        onSaveEdit={this.onSaveEdit}
                        onCloseEdit={this.onCloseEdit}
                    >
                        {this.state.isSaving ? <Loader /> : null}
                        <div className="edit-modal-row">
                            <SelectInput
                                autoFocus
                                openOnFocus={false}
                                value={this.state.croppingSeasonGuid}
                                optionIsHiddenKey={ACTIVE_YN}
                                options={this.state.croppingSeasonOptions}
                                required={true}
                                clearable={false}
                                placeholderText={formatMessage(messages.croppingSeasonText)}
                                initialFilterStr={this.state.editItem.croppingSeason}
                                onChange={(value) => {
                                    this.setState({
                                        croppingSeasonGuid: value,
                                        croppingSeason: this.getLabel({
                                            options: this.state.croppingSeasonOptions,
                                            guid: value,
                                        }),
                                    });
                                }}
                            />
                            {/* Remove the conditional once the backend can support cropping season cycle */}
                            {/**<SelectInput
                                            disabled={true}
                                            optionIsHiddenKey={ACTIVE_YN}
                                            value={this.state.croppingSeasonCycleGuid}
                                            options={this.state.croppingSeasonCycleOptions}
                                            placeholderText={formatMessage(messages.croppingSeasonCycleText)}
                                            initialFilterStr={this.state.editItem.croppingSeasonCycleName}
                                            onChange={(value) => this.setState({ croppingSeasonCycleGuid: value })}
                                        />*/}
                        </div>
                    </AttributeEditModal>
                )}
            </div>
        );
    }
}

export const ImportSeasonFilter = injectIntl(ImportSeasonFilter_);
