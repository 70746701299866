import React, { Component } from "react";
import PropTypes from "prop-types";
import { messages } from "../../../i18n-messages";
import {
    Bucket,
    BucketHeader,
    Checkbox,
    NumericInput,
    Section,
    SelectInput,
    SubSection,
    TextInput,
} from "~/core";
import { model } from "../../data";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import * as unitService from "~/core/units/unit-names";
import * as picklistService from "~/core/picklist/picklist-names";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";

export default class Irrigation extends Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        onSelectInputChange: PropTypes.func,
        onTextChange: PropTypes.func,
        onNumberChange: PropTypes.func,
        equipmentSetup: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            [unitService.UNIT_LENGTH]: prepareSelectableOptions(props[unitService.UNIT_LENGTH], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_FLOW_RATE]: prepareSelectableOptions(
                props[unitService.UNIT_FLOW_RATE],
                { guid: GUID, label: NAME }
            ),
            [picklistService.PICKLIST_WELL_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_WELL_TYPE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initializeUnitLength(nextProps);
        this.initializeUnitFlowRate(nextProps);
        this.initializePicklistWellType(nextProps);
    }

    initializeUnitLength = (nextProps) => {
        if (nextProps[unitService.UNIT_LENGTH] !== this.props[unitService.UNIT_LENGTH]) {
            this.setState({
                [unitService.UNIT_LENGTH]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_LENGTH],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    initializeUnitFlowRate = (nextProps) => {
        if (nextProps[unitService.UNIT_FLOW_RATE] !== this.props[unitService.UNIT_FLOW_RATE]) {
            this.setState({
                [unitService.UNIT_FLOW_RATE]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_FLOW_RATE],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    initializePicklistWellType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_WELL_TYPE] !==
            this.props[picklistService.PICKLIST_WELL_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_WELL_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_WELL_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    render() {
        const { formatMessage, equipmentSetup, onSelectInputChange, onTextChange, onNumberChange } =
            this.props;
        return (
            <Bucket
                className="form-section-bucket purchase-history"
                showSymbol={true}
                isCollapsible={true}
                isExpanded={false}
            >
                <BucketHeader>{formatMessage(messages.irrigation)}</BucketHeader>
                <Section>
                    <SubSection>
                        <Checkbox
                            onChange={(e, value) => onTextChange(model.PROPS_FERTIGATION, value)}
                            value={equipmentSetup[model.PROPS_FERTIGATION]}
                            label={formatMessage(messages.fertigation)}
                        />
                        <Checkbox
                            onChange={(e, value) => onTextChange(model.PROPS_CHEMIGATION, value)}
                            value={equipmentSetup[model.PROPS_CHEMIGATION]}
                            label={formatMessage(messages.chemigation)}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={0}
                            precision={4}
                            onChange={(value) =>
                                onNumberChange(model.PROPS_NUMBER_OF_SPRINKLERS, value)
                            }
                            value={equipmentSetup[model.PROPS_NUMBER_OF_SPRINKLERS]}
                            placeholderText={formatMessage(messages.numberOfSprinklers)}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            onChange={(value) => onNumberChange(model.PROPS_PIVOT_SIZE, value)}
                            value={equipmentSetup[model.PROPS_PIVOT_SIZE]}
                            placeholderText={formatMessage(messages.pivotSize)}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.pivotSizeUnit)}
                            options={this.state[unitService.UNIT_LENGTH]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid: equipmentSetup[model.PROPS_PIVOT_SIZE_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    { guid: model.PROPS_PIVOT_SIZE_UNIT_GUID },
                                    value
                                );
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            onChange={(value) => onNumberChange(model.PROPS_TUBING_SIZE, value)}
                            value={equipmentSetup[model.PROPS_TUBING_SIZE]}
                            placeholderText={formatMessage(messages.tubingSize)}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.tubingSizeUnit)}
                            options={this.state[unitService.UNIT_LENGTH]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid: equipmentSetup[model.PROPS_TUBING_SIZE_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    { guid: model.PROPS_TUBING_SIZE_UNIT_GUID },
                                    value
                                );
                            }}
                        />
                        <TextInput
                            value={equipmentSetup[model.PROPS_WELL]}
                            placeholderText={formatMessage(messages.well)}
                            onChange={(value) => {
                                onTextChange(model.PROPS_WELL, value);
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            onChange={(value) => onNumberChange(model.PROPS_WELL_DEPTH, value)}
                            value={equipmentSetup[model.PROPS_WELL_DEPTH]}
                            placeholderText={formatMessage(messages.depth)}
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.depthUnit)}
                            options={this.state[unitService.UNIT_LENGTH]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid: equipmentSetup[model.PROPS_WELL_DEPTH_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    { guid: model.PROPS_WELL_DEPTH_UNIT_GUID },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.wellType)}
                            options={this.state[picklistService.PICKLIST_WELL_TYPE]}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_WELL_TYPE],
                                selectedGuid: equipmentSetup[model.PROPS_WELL_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange({ guid: model.PROPS_WELL_TYPE_GUID }, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            onChange={(value) => onNumberChange(model.PROPS_WELL_OUTPUT, value)}
                            value={equipmentSetup[model.PROPS_WELL_OUTPUT]}
                            placeholderText={formatMessage(messages.wellOutput)}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.wellOutputUnit)}
                            options={this.state[unitService.UNIT_FLOW_RATE]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_FLOW_RATE],
                                selectedGuid: equipmentSetup[model.PROPS_WELL_OUTPUT_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    { guid: model.PROPS_WELL_OUTPUT_UNIT_GUID },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
            </Bucket>
        );
    }
}
