export const FETCH_RELEASE_NOTES_DATA = "setupReleaseNotes/FETCH_RELEASE_NOTES_DATA";
export const FETCH_RELEASE_NOTES_SUCCESS = "setupReleaseNotes/FETCH_RELEASE_NOTES_SUCCESS";
export const FETCH_RELEASE_NOTES_FAILURE = "setupReleaseNotes/FETCH_RELEASE_NOTES_FAILURE";
export const UPDATE_RELEASE_NOTES = "setupReleaseNotes/UPDATE_RELEASE_NOTES_DATA";
export const SAVE_RELEASE_NOTES_SUCCESS = "setupReleaseNotes/UPDATE_RELEASE_NOTES_SUCCESS";
export const SAVE_RELEASE_NOTES_FAILURE = "setupReleaseNotes/UPDATE_RELEASE_NOTES_FAILURE";

export interface ReducerAction {
    type: string;
    releaseNotes?: string;
}

export const getReleaseNotes = (): ReducerAction => ({
    type: FETCH_RELEASE_NOTES_DATA,
});

export const updateReleaseNotes = (releaseNotes: string): ReducerAction => ({
    type: UPDATE_RELEASE_NOTES,
    releaseNotes,
});

export const setReleaseNotesSuccess = (releaseNotes: string): ReducerAction => ({
    type: FETCH_RELEASE_NOTES_SUCCESS,
    releaseNotes,
});

export const setReleaseNotesFailure = (): ReducerAction => ({
    type: FETCH_RELEASE_NOTES_FAILURE,
});

export const saveReleaseNotesSuccess = (): ReducerAction => ({
    type: SAVE_RELEASE_NOTES_SUCCESS,
});

export const saveReleaseNotesFailed = (): ReducerAction => ({
    type: SAVE_RELEASE_NOTES_FAILURE,
});
