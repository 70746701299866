import * as model from "./model";

const vendorDefaultRecord = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_GLN_IDENTIFIER]: "",
    [model.PROPS_LAB_IDENTIFIER]: "",
    [model.PROPS_NOTES]: "",
    [model.PROPS_SUBMITTER_ID]: "",
    [model.PROPS_TESTING_LAB_FROM_EMAIL_LIST]: [],
    [model.PROPS_TESTING_LAB_TO_EMAIL_LIST]: [],
    [model.PROPS_TEST_PACKAGE_LIST]: [],
    [model.PROPS_VENDOR_ADDRESS_LIST]: [],
    [model.PROPS_VENDOR_PHONE_NUMBER_LIST]: [],
    [model.PROPS_VENDOR_URL_LIST]: [],
    [model.PROPS_VENDOR_TYPE_LIST]: [],
    [model.PROPS_VENDOR_GUID]: "",
    [model.PROPS_VENDOR_NAME]: "",
});

export default vendorDefaultRecord;
