import React, { Component } from "react";
import PropTypes from "prop-types";
import { AutoSearchInput } from "~/core/components/auto-search-input/auto-search-input";

export default class EntitySearch extends Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        onSearchChange: PropTypes.func,
        renderListItem: PropTypes.func,
        searchEntity: PropTypes.func,
        selectedCompany: PropTypes.object,
        onItemSelection: PropTypes.func,
        onSearchSuccessfull: PropTypes.func,
        filteredData: PropTypes.array,
        initialValue: PropTypes.string,
        labelKey: PropTypes.string,
        placeholderText: PropTypes.string,
    };

    static defaultProps = {
        filteredData: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            initialValue: "",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialValue) {
            this.setState({
                initialValue: nextProps.initialValue,
            });
        }
        if (nextProps.filteredData !== this.props.filteredData && this.props.onSearchSuccessfull) {
            this.props.onSearchSuccessfull(nextProps.filteredData);
        }
    }

    getSearchedEntities = (value = "") => {
        if (this.props.onItemSelection) {
            this.props.onItemSelection({
                [this.props.labelKey]: value,
            });
        }
    };

    onSearchChange = (value) => {
        if (value && this.props.searchEntity) {
            const { selectedCompany } = this.props;
            this.props.searchEntity(value, selectedCompany.userGuid);
        } else {
            // Reset entities if value is empty
            this.getSearchedEntities();
        }
        if (this.props.onSearchChange) {
            this.props.onSearchChange(value);
        }
    };

    onSearchInputKeyDown = (event, value) => {
        if (event.key === "Tab" || event.key === "Enter") {
            this.getSearchedEntities(value);
        }
    };

    onItemSelection = (data) => {
        this.setState(
            {
                initialValue: data[this.props.labelKey],
            },
            () => {
                if (this.props.onItemSelection) {
                    this.props.onItemSelection(data);
                }
            }
        );
    };

    renderListItem = (data) => {
        return <div className="autosearch-list-content">{data[this.props.labelKey]}</div>;
    };

    render() {
        return (
            <div className="entity-search">
                <AutoSearchInput
                    placeholderText={this.props.placeholderText}
                    renderListItem={this.props.renderListItem || this.renderListItem}
                    onSearchChange={this.onSearchChange}
                    onKeyDown={this.onSearchInputKeyDown}
                    filteredData={this.props.filteredData}
                    onItemSelection={this.onItemSelection}
                    value={this.state.initialValue || ""}
                />
            </div>
        );
    }
}
