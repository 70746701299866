import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { Menu } from "~/core";
import { withUser } from "~/hocs";
import { messages } from "../../../../i18n-messages";

class ContextMenu_ extends Component {
    static propTypes = {
        showSampleZoneInfo: PropTypes.func.isRequired,
        hideSampleZoneInfo: PropTypes.func,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isContextMenuExpanded: false,
            isDeleteConfirmationOpen: false,
        };
    }

    _getMenuItems() {
        const { showSampleZoneInfo, hideSampleZoneInfo } = this.props;
        const { formatMessage } = this.props.intl;

        const menuItems = [
            {
                key: 0,
                label: formatMessage(messages.editSampleZoneInfoLayerProperties),
                action: () => showSampleZoneInfo(),
            },
        ];
        if (hideSampleZoneInfo) {
            menuItems.push({
                key: 1,
                label: formatMessage(messages.removeText),
                action: () => hideSampleZoneInfo(),
            });
        }
        return menuItems;
    }

    _onClick(event) {
        // prevent expand/collapse of accordion item
        event.preventDefault();
    }

    render() {
        const contextMenuClassNames = classNames("context-menu", {
            expanded: this.state.isContextMenuExpanded,
        });
        return (
            <div className="context-menu-container" onClick={(event) => this._onClick(event)}>
                <Menu
                    className={contextMenuClassNames}
                    isDotMenu={true}
                    getMenuItems={() => this._getMenuItems()}
                    onExpand={() => this.setState({ isContextMenuExpanded: true })}
                    onCollapse={() => this.setState({ isContextMenuExpanded: false })}
                />
            </div>
        );
    }
}

export const ContextMenu = injectIntl(withUser(ContextMenu_));
