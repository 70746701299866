import { buffers, delay, eventChannel, END } from "redux-saga";
import { actionChannel, call, fork, join, put, select, take, takeEvery } from "redux-saga/effects";
import { v4 as uuid } from "uuid";

import { zip } from "~/utils/zip";
import { actions as accordionActions, model as accordionModel } from "~/accordion";
import { actions as fileImportActions, models as fileImportModels } from "~/file-import";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { FileImportAPI } from "@ai360/core";
import * as rxFileImportModels from "~/action-panel/components/rec-module/components/rec-info/components/rec-equation-application/rx-file-import/models";

import { createAccordionItems } from "./components/file-list";
import * as models from "./models";
import * as actions from "./actions";
import * as selectors from "./selectors";
import { messages } from "./i18n-messages";

const ignoreExtensions = [".mcd", ".cpg", ".sbn", ".sbx", ".shp.xml", ".qpj"];

const getAddImportFileXhrUploadToS3Promise = (
    formData,
    progressCallback,
    preSignedS3Url,
    method = "PUT"
) => {
    return new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            if (xhr.status !== 200) {
                reject(new Error(`Server returned status ${xhr.status}`));
            }
            resolve();
        };
        xhr.onerror = (e) => {
            reject(e);
        };
        xhr.upload.onprogress = (e) => {
            if (e.lengthComputable && e.loaded !== e.total) {
                progressCallback(e.loaded / e.total);
            }
        };

        xhr.open(method, preSignedS3Url, true);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.setRequestHeader("Accept", "application/json");
        xhr.send(formData);
    });
};

const getReadZipPromise = (uploadFileInfo) => {
    return new Promise((resolve) => {
        console.assert(uploadFileInfo.isZipFile());
        // don't unpack nested .zip files
        const validExtensionsExceptZip = uploadFileInfo.validExtensions.filter(
            (ext) => ext !== ".zip"
        );
        zip.createReader(
            new zip.BlobReader(uploadFileInfo.fileObj),
            (reader) => {
                reader.getEntries((entries) => {
                    const zipChildren = entries
                        .filter((zipEntry) => !zipEntry.directory)
                        .map((zipEntry) =>
                            models.UploadFileInfo.fromZipEntry(zipEntry, validExtensionsExceptZip)
                        );
                    uploadFileInfo.children = zipChildren;
                    for (const child of zipChildren) {
                        child.parent = uploadFileInfo;
                    }
                    resolve(uploadFileInfo);
                });
            },
            function (error) {
                uploadFileInfo.errorMessages.push(String(error));
                resolve(uploadFileInfo);
            }
        );
    });
};

const groupShapeFiles = (importFileInfoList, validFileNameExtensions) => {
    const importFileInfoListNonShpFiles = [];
    const importFileInfoListOnlyShpFiles = [];
    const shpFileBaseNameToPartsMap = new Map();

    for (const uploadFileInfo of importFileInfoList) {
        if (uploadFileInfo.isZipFile()) {
            const zipChildren = uploadFileInfo.children;
            const validExtensionsExceptZip = validFileNameExtensions.filter(
                (ext) => ext !== ".zip"
            );
            uploadFileInfo.children = groupShapeFiles(zipChildren, validExtensionsExceptZip);
            uploadFileInfo.children.sort((a, b) =>
                a.fileName < b.fileName ? -1 : a.fileName > b.fileName ? 1 : 0
            );
            importFileInfoListNonShpFiles.push(uploadFileInfo);
            continue;
        }
        if (!ignoreExtensions.includes(uploadFileInfo.getFilenameExt())) {
            if (!uploadFileInfo.isShape() && !uploadFileInfo.isShapePart()) {
                importFileInfoListNonShpFiles.push(uploadFileInfo);
                continue;
            }

            const fn = uploadFileInfo.fileName;
            const fnWithoutExt = fn.slice(0, fn.length - 4).toLowerCase();
            if (shpFileBaseNameToPartsMap.has(fnWithoutExt)) {
                shpFileBaseNameToPartsMap.get(fnWithoutExt).push(uploadFileInfo);
            } else {
                shpFileBaseNameToPartsMap.set(fnWithoutExt, [uploadFileInfo]);
            }
        }
    }

    for (const baseFileName of shpFileBaseNameToPartsMap.keys()) {
        // Find the main .shp file and the other file parts
        let shpFileInfo;
        const shpFileParts = [];
        for (const uploadFileInfo of shpFileBaseNameToPartsMap.get(baseFileName)) {
            if (uploadFileInfo.isShapePart()) {
                shpFileParts.push(uploadFileInfo);
            } else {
                shpFileInfo = uploadFileInfo;
            }
        }
        // If there is no main .shp file, we'll still create a parent ".shp" ImportFileInfo
        //   to contain all the parts
        if (shpFileInfo == null) {
            shpFileInfo = new models.UploadFileInfo(baseFileName + ".shp", validFileNameExtensions);
            shpFileInfo.errorMessages.push([messages.missingShapePart, { ext: ".shp" }]);
        }
        shpFileParts.sort((a, b) =>
            a.fileName < b.fileName ? -1 : a.fileName > b.fileName ? 1 : 0
        );
        shpFileInfo.children = shpFileParts;
        for (const shpFilePart of shpFileParts) {
            shpFilePart.parent = shpFileInfo;
        }
        importFileInfoListOnlyShpFiles.push(shpFileInfo);
    }

    for (const shpUploadFileInfo of importFileInfoListOnlyShpFiles) {
        checkShapeFilePartsPresent(shpUploadFileInfo);
    }

    return importFileInfoListNonShpFiles.concat(importFileInfoListOnlyShpFiles);
};

const checkShapeFilePartsPresent = (shpUploadFileInfo) => {
    console.assert(shpUploadFileInfo.isShape());

    const childExtensions = new Set(
        shpUploadFileInfo.children.map((childUfi) => childUfi.getFilenameExt())
    );

    const extensions = [...models.UploadFileInfo.SHAPE_PART_EXTENSIONS].filter(
        (ext) => ext !== ".prj" && ext !== ".shp"
    );
    for (const ext of extensions) {
        if (!childExtensions.has(ext)) {
            shpUploadFileInfo.errorMessages.push([messages.missingShapePart, { ext }]);
        }
    }
};

const checkValidExtension = (uploadFileInfo) => {
    const ext = uploadFileInfo.getFilenameExt();
    if (!uploadFileInfo.validExtensions.includes(ext)) {
        uploadFileInfo.errorMessages.push([messages.invalidExtension, { ext }]);
    }
};

const setComputedSize = (uploadFileInfo) => {
    const size = !uploadFileInfo.isShape()
        ? uploadFileInfo.fileSize
        : uploadFileInfo.children
              .filter((c) => c.isShapePart())
              .reduce((accum, c) => accum + c.fileSize, uploadFileInfo.fileSize);
    uploadFileInfo.computedSize =
        size > 1048576
            ? (size / 1048576).toFixed(2) + " mb"
            : size > 1024
            ? (size / 1024).toFixed(2) + " kb"
            : size.toFixed(0) + " bytes";
};

const readZip = function* (uploadFileInfo) {
    yield call(getReadZipPromise, uploadFileInfo);
};

const onAddBrowserFileList = function* (action) {
    const { browserFileList, stateKey } = action.payload;
    const { uploadFileInfoMap, validExtensions }: models.IDragDropFileUploaderState = yield select(
        selectors.getModuleState,
        stateKey
    );

    const isZipNativeFormat = validExtensions.length === 1 && validExtensions[0] === ".zip";
    const uploadFileInfoList = [];
    const zipReadTaskList = [];

    for (let i = 0; i < browserFileList.length; i++) {
        const file = browserFileList[i];
        const uploadFileInfo = models.UploadFileInfo.fromFile(file, validExtensions);
        uploadFileInfoList.push(uploadFileInfo);
        if (!isZipNativeFormat && uploadFileInfo.isZipFile()) {
            zipReadTaskList.push(yield fork(readZip, uploadFileInfo));
            continue;
        }
    }

    for (const task of zipReadTaskList) {
        yield join(task);
    }

    const groupedUploadFileInfoList = groupShapeFiles(uploadFileInfoList, validExtensions);

    // remove duplicates
    const filteredUploadFileInfoList = groupedUploadFileInfoList.filter((file) => {
        const hasDuplicate = Array.from(uploadFileInfoMap.values()).some((existingFile) => {
            return (
                (existingFile.fileName === file.fileName &&
                    existingFile.fileSize === file.fileSize &&
                    existingFile.children.length === file.children.length) ||
                file.children.some((subFile) => {
                    return (
                        existingFile.fileName === subFile.fileName &&
                        existingFile.fileSize === subFile.fileSize &&
                        existingFile.children.length === subFile.children.length
                    );
                })
            );
        });
        return !hasDuplicate;
    });

    if (filteredUploadFileInfoList.length !== 0) {
        for (const uploadFileInfo of models.flattenUploadFileList(filteredUploadFileInfoList)) {
            checkValidExtension(uploadFileInfo);
            setComputedSize(uploadFileInfo);
        }

        yield put(actions.addUploadFileInfoList(stateKey, filteredUploadFileInfoList));
    }
    if (groupedUploadFileInfoList.length !== filteredUploadFileInfoList.length) {
        yield put(actions.setDuplicateFileError(stateKey, true));
    }
};

const sanitizeFileName = (fileName) => {
    const regex = /(.+)(\.[^.]+)$/;
    const match = fileName.match(regex);
    return match ? match[1] + match[2].toLowerCase() : fileName;
};

export const recAddUploadFileInfoList = function* (accordionId, uploadFileList, dimIdx) {
    const items = createAccordionItems(
        uploadFileList.filter((uploadFileInfo) => !uploadFileInfo.isShapePart())
    );
    if (items.length === 0) {
        return;
    }
    yield put(accordionActions.addAccordionItemArray(accordionId, items, dimIdx.concat(-1)));
    for (const [idx, uploadFileInfo] of uploadFileList.entries()) {
        if (uploadFileInfo.children.length > 0) {
            yield* recAddUploadFileInfoList(
                accordionId,
                uploadFileInfo.children,
                dimIdx.concat(idx)
            );
        }
    }
};

export const onAddUploadFileInfoList = function* (action) {
    const { stateKey, uploadFileList } = action.payload;
    const { accordionId, items }: accordionModel.IAccordionState = yield select(
        selectors.getAccordionState,
        stateKey
    );
    const { uploadFileInfoMap }: models.IDragDropFileUploaderState = yield select(
        selectors.getModuleState,
        stateKey
    );

    // sort the full uploadFileList
    const fullUploadFileList = uploadFileList.concat(
        items.map((item) => uploadFileInfoMap.get(item.payload.guid))
    );
    fullUploadFileList.sort((a, b) =>
        a.fileName < b.fileName ? -1 : a.fileName > b.fileName ? 1 : 0
    );
    yield put(accordionActions.removeAllAccordionItems(accordionId));
    yield* recAddUploadFileInfoList(accordionId, fullUploadFileList, []);
};

export const onremoveFileFromList = function* (action) {
    const { itemDimIdx, stateKey } = action.payload;
    const { accordionId, items }: accordionModel.IAccordionState = yield select(
        selectors.getAccordionState,
        stateKey
    );
    const { uploadFileInfoMap }: models.IDragDropFileUploaderState = yield select(
        selectors.getModuleState,
        stateKey
    );
    yield put(accordionActions.removeAccordionItem(accordionId, itemDimIdx));
    // Check if parent was removed by reducer ..
    if (itemDimIdx.length > 1) {
        const parentDimIdx = itemDimIdx.slice(0, -1);
        const parentGuid = accordionModel.getItem(items, parentDimIdx).payload.guid;
        if (!uploadFileInfoMap.has(parentGuid)) {
            yield put(accordionActions.removeAccordionItem(accordionId, parentDimIdx));
        }
    }
};

export const onClearUploadFileList = function* (action) {
    const { stateKey } = action.payload;
    const { accordionId }: accordionModel.IAccordionState = yield select(
        selectors.getAccordionState,
        stateKey
    );
    yield put(accordionActions.removeAllAccordionItems(accordionId));
};

export const onAddAndUploadFileInfo = function* (action) {
    const { importTypeGuid, stateKey, template, uploadFilesInfoList, uploadingStatusGuid } =
        action.payload;

    const isZipNativeFormat = template.isZipNativeFormat();
    const templateGuid = template.templateGuid;

    const userGuid = yield select(getTheUserGuid);

    const batchGuid = uuid().toString();

    const getImportFileInfo = (uploadFileInfo, zipFileGuid = null) => ({
        fileSizeBytes: uploadFileInfo.fileSize,
        importFileName: uploadFileInfo.fileName,
        importFileGuid: uploadFileInfo.guid,
        importFileStatusGuid: uploadingStatusGuid,
        importTypeGuid,
        importZipTemplateGuid: isZipNativeFormat ? templateGuid : "",
        templateGuid: !isZipNativeFormat ? templateGuid : "",
        parentImportFileGuid: zipFileGuid,
        importBatchGuid: batchGuid,
    });

    const completeUploadFileInfoList = uploadFilesInfoList.reduce((result, uploadFileInfo) => {
        if (uploadFileInfo.isShapePart()) {
            uploadFileInfo.guid = uploadFileInfo.parent.guid;
        }
        result.push(getImportFileInfo(uploadFileInfo));
        if (uploadFileInfo.isZipFile() && template != null && !template.isZipNativeFormat()) {
            result.push(
                ...uploadFileInfo.children.map((zipChild) =>
                    getImportFileInfo(zipChild, uploadFileInfo.guid)
                )
            );
        }
        return result;
    }, []);

    let s3UploadUrls = [];
    try {
        s3UploadUrls = yield call(
            FileImportAPI.addImportFileList,
            userGuid,
            completeUploadFileInfoList
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.fileUploadInfoAddFailed));
        yield put(actions.setIsPreparingUpload(stateKey, false));
        return;
    }

    // We've added all "uploading" files to system; re-populate the import panel
    if (template.name !== rxFileImportModels.RX_FILE_IMPORT_TYPE_NAME) {
        yield put(fileImportActions.refreshImportData(new Set([templateGuid])));
        yield take(fileImportActions.REFRESH_IMPORT_DATA_COMPLETE);
    }
    yield put(actions.setIsPreparingUpload(stateKey, false));

    for (const uploadFileInfo of uploadFilesInfoList) {
        // Do not upload files that are part of a shp
        if (!uploadFileInfo.isShapePart()) {
            yield put(
                actions.uploadFile(
                    stateKey,
                    uploadFileInfo,
                    importTypeGuid,
                    templateGuid,
                    s3UploadUrls
                )
            );
        }
    }
};

export const onProcessUploads = function* (action) {
    const { stateKey } = action.payload;
    const {
        filesUploaded,
        selectedTemplate,
        selectedImportType,
        uploadFileInfoMap,
    }: models.IDragDropFileUploaderState = yield select(selectors.getModuleState, stateKey);

    yield put(actions.setIsPreparingUpload(stateKey, true));

    const userGuid = yield select(getTheUserGuid);

    let waitingToUploadStatus;
    try {
        const importStatusList = yield call(FileImportAPI.getImportStatusList, userGuid);
        waitingToUploadStatus = importStatusList.find(
            (status) => status.name === fileImportModels.WAITING_TO_UPLOAD_STATUS_NAME
        );
        if (waitingToUploadStatus == null) {
            throw new Error(`Status "${fileImportModels.WAITING_TO_UPLOAD_STATUS_NAME}" not found`);
        }
    } catch (error) {
        yield put(notificationActions.apiCallError(error, null, messages.uploadingStatusNotFound));
        return;
    }

    // Do not add or upload files that:
    // - have errors
    // - part of a zip
    // - have already been uploaded
    const hasError = (ifo) =>
        ifo.errorMessages.length > 0 || (ifo.parent != null && ifo.parent.errorMessages.length > 0);
    const isZipContent = (ifo) => ifo.parent != null && ifo.parent.isZipFile();
    const getParent = (ifo) => (ifo.parent != null ? getParent(ifo.parent) : ifo);
    const hasBeenUploaded = (ifo) =>
        filesUploaded.has(ifo.guid) || filesUploaded.has(getParent(ifo).guid);

    const uploadFilesInfoList = [...uploadFileInfoMap.values()].filter(
        (uploadFileInfo) =>
            !hasError(uploadFileInfo) &&
            !isZipContent(uploadFileInfo) &&
            !hasBeenUploaded(uploadFileInfo)
    );

    yield put(
        actions.addAndUploadFileInfo(
            stateKey,
            selectedImportType.guid,
            selectedTemplate,
            waitingToUploadStatus.importFileStatusGuid,
            uploadFilesInfoList
        )
    );
};

export const createUploadProgressActionChannel = (uploadFileInfo) => {
    let progressCallback;
    const channel = eventChannel((emit) => {
        const shapeCalcPercentComplete = (pctComplete) => {
            const m = getShapePartsPercentCompleteMap(uploadFileInfo);
            m.set(uploadFileInfo.getFilenameExt(), {
                fileSize: uploadFileInfo.fileSize,
                pctComplete,
            });
            const totalSize = [...m.values()].reduce((accum, { fileSize }) => accum + fileSize, 0);
            const completeSize = [...m.values()].reduce(
                (accum, { fileSize, pctComplete }) => accum + fileSize * pctComplete,
                0
            );
            const totalPctComplete = completeSize / totalSize;
            return totalPctComplete;
        };

        progressCallback = (percentComplete) => {
            if (uploadFileInfo.isZipFile() && !uploadFileInfo.isZipNativeFormat()) {
                // zip'd files are uploaded as one, so share the % complete updates
                console.assert(uploadFileInfo.children.length > 0);
                const batchAction = fileImportActions.batchActions(
                    uploadFileInfo.children.map((childFileInfo) =>
                        fileImportActions.updateIfoStatusPercentComplete(
                            childFileInfo.guid,
                            percentComplete
                        )
                    )
                );
                emit(batchAction);
            } else if (uploadFileInfo.isShape() || uploadFileInfo.isShapePart()) {
                // % complete of .shp is sum of parts
                percentComplete = shapeCalcPercentComplete(percentComplete);
                emit(
                    fileImportActions.updateIfoStatusPercentComplete(
                        uploadFileInfo.isShapePart()
                            ? uploadFileInfo.parent.guid
                            : uploadFileInfo.guid,
                        percentComplete
                    )
                );
            } else {
                emit(
                    fileImportActions.updateIfoStatusPercentComplete(
                        uploadFileInfo.guid,
                        percentComplete
                    )
                );
            }

            if (percentComplete === 1) {
                emit(END); // dispose the channel
            }
        };
        return () => null;
    }, buffers.sliding(1));

    return [channel, progressCallback];
};

const shapePartsPercentComplete = new Map();
const getShapePartsPercentCompleteMap = (uploadFileInfo) => {
    console.assert(uploadFileInfo.isShape() || uploadFileInfo.isShapePart());
    const shpUploadFileInfo = uploadFileInfo.isShape() ? uploadFileInfo : uploadFileInfo.parent;
    if (!shapePartsPercentComplete.has(shpUploadFileInfo.guid)) {
        const shpAndParts = shpUploadFileInfo.children
            .filter((ufi) => ufi.errorMessages.length === 0)
            .concat(shpUploadFileInfo);
        const percentCompleteMap = new Map(
            shpAndParts.map((uploadFileInfo) => [
                uploadFileInfo.getFilenameExt(),
                { fileSize: uploadFileInfo.fileSize, pctComplete: 0 },
            ])
        );
        shapePartsPercentComplete.set(shpUploadFileInfo.guid, percentCompleteMap);
    }
    return shapePartsPercentComplete.get(shpUploadFileInfo.guid);
};

export const onUploadFileChannelListener = function* (actionChan) {
    while (true) {
        const action = yield take(actionChan);
        const { signedUrlDict, stateKey, uploadFileInfo } = action.payload;

        // files that are part of a zip or .shp are not uploaded individually
        console.assert(uploadFileInfo.fileObj != null && !uploadFileInfo.isShapePart());

        if (uploadFileInfo.isZipFile() && !uploadFileInfo.isZipNativeFormat()) {
            console.assert(uploadFileInfo.children.length > 0);
            const batchAction = fileImportActions.batchActions(
                models
                    .flattenUploadFileList(uploadFileInfo.children)
                    .map((childIfo) =>
                        fileImportActions.updateIfoStatusCode(
                            childIfo.guid,
                            fileImportModels.UPLOADING_STATUS_CODE
                        )
                    )
            );
            yield put(batchAction);
        } else {
            yield put(
                fileImportActions.updateIfoStatusCode(
                    uploadFileInfo.guid,
                    fileImportModels.UPLOADING_STATUS_CODE
                )
            );
        }

        // If this is a shape file, upload its children as well
        const uploadFileInfoList = !uploadFileInfo.isShape()
            ? [uploadFileInfo]
            : uploadFileInfo.children
                  .filter((ufi) => ufi.errorMessages.length === 0)
                  .concat(uploadFileInfo);

        for (const ufi of uploadFileInfoList) {
            const [channel, progressCallback] = createUploadProgressActionChannel(ufi);

            yield fork(function* () {
                while (true) {
                    const updateUploadProgressAction = yield take(channel);
                    yield fork(put, updateUploadProgressAction);
                    yield call(delay, 250); // limit to 4 progress updates/second/upload
                }
            });

            try {
                const findFileName = sanitizeFileName(ufi.fileName);
                const found = signedUrlDict.find(
                    (x) => sanitizeFileName(x.fileName) === findFileName
                );
                if (!found) {
                    const message = `Could not find uploaded file name: ${findFileName}`;
                    console.error(message);
                    throw Error(message);
                }

                const preSignedS3Url = found.url;
                yield call(
                    getAddImportFileXhrUploadToS3Promise,
                    ufi.fileObj,
                    progressCallback,
                    preSignedS3Url
                );
            } catch (err) {
                yield put(
                    notificationActions.apiCallError(err, action, messages.fileUploadFailed, {
                        fileName: uploadFileInfo.fileName,
                    })
                );
                break;
            }

            progressCallback(1);
            if (ufi.isShape()) {
                shapePartsPercentComplete.delete(ufi.guid);
            } else if (!ufi.isShapePart()) {
                yield put(fileImportActions.uploadCompleted(ufi.guid));
                yield put(actions.uploadCompleted(stateKey, ufi.guid));
            }
        }
        if (uploadFileInfo.isShape()) {
            yield put(fileImportActions.uploadCompleted(uploadFileInfo.guid));
            yield put(actions.uploadCompleted(stateKey, uploadFileInfo.guid));
        }
    }
};

export const onUpdateUploadFileInfos = function* (action) {
    const { stateKey } = action.payload;
    const { accordionId, items }: accordionModel.IAccordionState = yield select(
        selectors.getAccordionState,
        stateKey
    );
    const { uploadFileInfoMap }: models.IDragDropFileUploaderState = yield select(
        selectors.getModuleState,
        stateKey
    );

    // sort the full uploadFileList
    const fullUploadFileList = items.map((item) => uploadFileInfoMap.get(item.payload.guid));
    fullUploadFileList.sort((a, b) =>
        a.fileName < b.fileName ? -1 : a.fileName > b.fileName ? 1 : 0
    );
    yield put(accordionActions.removeAllAccordionItems(accordionId));
    yield* recAddUploadFileInfoList(accordionId, fullUploadFileList, []);
};

export const dragAndDropFileUploaderSaga = function* () {
    yield takeEvery(
        [actions.SET_SELECTED_TEMPLATE, actions.CLEAR_UPLOAD_FILE_LIST],
        onClearUploadFileList
    );
    yield takeEvery(actions.ADD_AND_UPLOAD_FILE_INFO, onAddAndUploadFileInfo);
    yield takeEvery(actions.ADD_BROWSER_FILELIST, onAddBrowserFileList);
    yield takeEvery(actions.ADD_UPLOAD_INFO, onAddUploadFileInfoList);
    yield takeEvery(actions.REMOVE_FILE_FROM_LIST, onremoveFileFromList);
    yield takeEvery(actions.PROCESS_UPLOADS, onProcessUploads);
    yield takeEvery(actions.UPDATE_UPLOAD_FILE_INFOS, onUpdateUploadFileInfos);

    const concurrentUploads = 2; // Most browsers have max of 6 before queueing
    //  other requests to the same domain
    const channel = yield actionChannel(actions.UPLOAD_FILE, buffers.expanding(10));
    for (let i = 0; i < concurrentUploads; i++) {
        yield fork(onUploadFileChannelListener, channel);
    }
};
