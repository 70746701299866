import { takeLatest, put, select } from "redux-saga/effects";

import { setApiResult } from "~/core/api/actions";
import { getUser } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { UserRoleAPI, APIError } from "@ai360/core";

import { createUserRole, createUserRoleSuccess, createUserRoleFailed } from "./actions";

export function* requestCreateUserRole(action) {
    const { payload } = action;
    if (payload) {
        const LoggedInUser = yield select(getUser);
        const requestOptions = { LoggedInUser, Model: payload };
        try {
            yield UserRoleAPI.addUserRole(requestOptions);
            yield put(createUserRoleSuccess());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(createUserRoleFailed({ error }));
        }
    } else {
        yield put(createUserRoleFailed("No Data"));
    }
}

export default function* watchCreateUserRole() {
    yield takeLatest(createUserRole, requestCreateUserRole);
}
