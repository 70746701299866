import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ZoneClassifyIcon = withSvgIcon(
    <g>
        <rect width="8.336" height="8.336" rx="0.72" />
        <rect y="10.832" width="8.336" height="8.336" rx="0.72" />
        <rect y="21.664" width="8.336" height="8.336" rx="0.72" />
        <path
            d="M28.427,5.168H13.438a1,1,0,0,1,0-2H28.427a1,1,0,0,1,0,2Z"
            transform="translate(-0.573 0)"
        />
        <path
            d="M24.938,16h-11.5a1,1,0,0,1,0-2h11.5a1,1,0,1,1,0,2Z"
            transform="translate(-0.573 0)"
        />
        <path
            d="M28.427,26.832H13.438a1,1,0,0,1,0-2H28.427a1,1,0,0,1,0,2Z"
            transform="translate(-0.573 0)"
        />
    </g>,
    "-2.5 -2.5 35 35",
    "disabled-stroke"
);
