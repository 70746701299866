import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_ALLOW_NEGATIVES = "allowNegatives";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_DECIMAL_PLACES = "decimalPlaces";
export const PROPS_EQUATION_PARAMETER_DATA_TYPE = "equationParameterDataType";
export const PROPS_EQUATION_PARAMETER_DATA_TYPE_GUID = "equationParameterDataTypeGuid";
export const PROPS_EQUATION_PARAMETER_GUID = "equationParameterGuid";
export const PROPS_EQUATION_PARAMETER_LABEL = "equationParameterLabel";
export const PROPS_EQUATION_PARAMETER_NAME = "equationParameterName";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_PRECISION = "precision";
