import { action } from "typesafe-actions";
import { AccordionItem, DimArray, MultiDimIdx } from "./model";

export const ADD_ITEM = "accordion/ADD_ITEM";
export const ADD_ITEM_ARRAY = "accordion/ADD_ITEM_ARRAY";
export const INIT = "accordion/INIT";
export const REMOVE_ITEM = "accordion/REMOVE_ITEM";
export const REMOVE_ITEM_RANGE = "accordion/REMOVE_ITEM_RANGE";
export const REMOVE_DIMIDX_LIST = "accordion/REMOVE_DIMIDX_LIST";
export const REMOVE_ALL_ITEMS = "accordion/REMOVE_ALL_ITEMS";
export const REPLACE_ALL_ITEMS = "accordion/REPLACE_ALL_ITEMS";
export const UPDATE_ITEM = "accordion/UPDATE_ITEM";
export const EXPAND = "accordionItem/EXPAND";
export const COLLAPSE = "accordionItem/COLLAPSE";
export const EXPAND_ALL = "accordionItem/EXPAND_ALL";
export const COLLAPSE_ALL = "accordionItem/COLLAPSE_ALL";
export const EXPAND_CHILDREN = "accordionItem/EXPAND_CHILDREN";
export const COLLAPSE_CHILDREN = "accordionItem/COLLAPSE_CHILDREN";

export type ItemIdx = number | MultiDimIdx;

export const addAccordionItem = (accordionId: number, item: AccordionItem, index?: ItemIdx) =>
    action(ADD_ITEM, { accordionId, index, item });

export const addAccordionItemArray = (
    accordionId: number,
    items: AccordionItem[],
    index?: ItemIdx
) => action(ADD_ITEM_ARRAY, { accordionId, index, items });

export const init = () => action(INIT, {});

export const removeAccordionItem = (accordionId: number, index: ItemIdx) =>
    action(REMOVE_ITEM, { accordionId, index });

export const removeAccordionItemRange = (
    accordionId: number,
    index: ItemIdx,
    removeCount: number = Number.MAX_SAFE_INTEGER
) => action(REMOVE_ITEM_RANGE, { accordionId, index, removeCount });

export const removeAccordionItemDimIdxList = (accordionId: number, itemIdxList: ItemIdx[]) =>
    action(REMOVE_DIMIDX_LIST, { accordionId, itemIdxList });

export const removeAllAccordionItems = (accordionId: number) =>
    action(REMOVE_ALL_ITEMS, { accordionId });

export const replaceAllAccordionItems = (accordionId: number, items: AccordionItem[]) =>
    action(REPLACE_ALL_ITEMS, { accordionId, items });

export const updateAccordionItem = (
    accordionId: number,
    index: ItemIdx,
    newProps: Partial<AccordionItem>
) => action(UPDATE_ITEM, { accordionId, index, newProps });

export const expandAccordionItem = (accordionId: number, index: ItemIdx) =>
    action(EXPAND, { accordionId, index });

export const collapseAccordionItem = (accordionId: number, index: ItemIdx) =>
    action(COLLAPSE, { accordionId, index });

export const expandAllAccordionItems = (accordionId: number, dimensions: DimArray) =>
    action(EXPAND_ALL, { accordionId, dimensions });

export const collapseAllAccordionItems = (accordionId: number, dimensions: DimArray) =>
    action(COLLAPSE_ALL, { accordionId, dimensions });

export const expandAccordionItemChildren = (
    accordionId: number,
    index: ItemIdx,
    dimensions?: DimArray
) => action(EXPAND_CHILDREN, { accordionId, index, dimensions });

export const collapseAccordionItemChildren = (
    accordionId: number,
    index: ItemIdx,
    dimensions?: DimArray
) => action(COLLAPSE_CHILDREN, { accordionId, index, dimensions });
