import { connect } from "react-redux";
import { Equipment as EquipmentView } from "./equipment";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { getDropdown } from "~/admin/selectors";
import { model } from "./data";

const mapStateToProps = (state) => ({
    [model.PROPS_ORG_LEVEL]: getDropdown(model.PROPS_ORG_LEVEL, state),
});

const mapDispatchToProps = () => ({
    // Note - This action will be dispatched using needs hoc
    fetchDropdownData: (payload) => fetchDropdownData(payload),
});

export const Equipment = connect(mapStateToProps, mapDispatchToProps)(EquipmentView);
