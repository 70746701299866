import { call, fork, put, select, take, actionChannel } from "redux-saga/effects";
import {
    getModelNameToState,
    getRequestOptions,
    BOOLEAN_CONSTANTS_ARRAY,
    flattenParameterObject,
} from "./utils";
import { Request } from "@ai360/core";
import { actionsConstants, defaultActions } from "./actions";
import {
    convertAgiisRequest,
    convertAgrianRequest,
    create,
    deleteRequest,
    exportPackageRequest,
    exportPlantBackRestrictionRequest,
    exportRequest,
    fetch,
    fetchRecord,
    getAgrianConversionData,
    getNextId,
    importPackageRequest,
    importPlantBackRestrictionRequest,
    importRequest,
    importValidPackageRequest,
    importValidPlantBackRestrictionRequest,
    importValidRequest,
    saveAgrianConversionData,
    update,
} from "./saga";
import { getTheUserGuid } from "~/login";
import { addAppropriateGuidWithPayload } from "./utils";

function getAutoSearchListWithBoolean(searchString) {
    if (searchString) {
        const canDeleteValues = ["Yes", "No"];
        return canDeleteValues
            .filter((value) => value.toUpperCase().indexOf(searchString.toUpperCase()) > -1)
            .map((value) => ({ value }));
    }
}

function* autoSearchRequest(
    action = {},
    { urls, modelName, REQUEST_PAYLOAD_PARENT_GUID, defaultRequestFilters, useNewApi }
) {
    const UserGuid = yield select(getTheUserGuid);
    const payload = yield addAppropriateGuidWithPayload(action.payload, defaultRequestFilters);
    const requestOptions = { UserGuid: UserGuid, Model: payload };
    const { searchName, searchString } = action.payload;
    if (action && searchName) {
        try {
            let response = {};
            if (BOOLEAN_CONSTANTS_ARRAY.includes(searchName.toUpperCase())) {
                response = yield getAutoSearchListWithBoolean(searchString);
            } else {
                if (!useNewApi) {
                    response = yield call(Request.post, urls.AUTO_SEARCH_URL, requestOptions);
                } else {
                    response = yield call(Request.get, urls.AUTO_SEARCH_URL, [
                        { key: "userGuid", value: UserGuid },
                        ...flattenParameterObject(requestOptions),
                        { key: "searchValue", value: searchString },
                    ]);
                }
            }
            if (response) {
                const parentGuid = action.payload[REQUEST_PAYLOAD_PARENT_GUID];
                const model = getModelNameToState(modelName);
                if (parentGuid) {
                    yield put({
                        type: actionsConstants.nestedAutoSearch,
                        payload: {
                            response,
                            searchName,
                            parentGuid,
                            modelName: model,
                        },
                    });
                }
                yield put({
                    type: actionsConstants.autoSearchSuccess,
                    payload: { response, searchName, modelName: model },
                });
            }
        } catch (error) {
            console.error(
                `Failed to process action ${action.type} with payload ${JSON.stringify(
                    requestOptions
                )}`,
                error
            );
            yield put(defaultActions.failed());
        }
    }
}

function* selectAllRequest(action = {}, service) {
    const { urls, modelName, useNewApi } = service;
    const requestOptions = yield getRequestOptions(action.payload, service, useNewApi);
    const UserGuid = yield select(getTheUserGuid);
    if (action) {
        try {
            let response = null;
            if (!useNewApi) {
                response = yield call(Request.post, urls.SELECT_ALL, requestOptions);
            } else {
                response = yield call(Request.get, urls.SELECT_ALL, [
                    { key: "userGuid", value: UserGuid },
                    ...requestOptions,
                ]);
            }
            if (response) {
                yield put({
                    type: actionsConstants.selectAllSuccess,
                    payload: {
                        response,
                        modelName: getModelNameToState(modelName),
                    },
                });
            }
        } catch (error) {
            console.error(
                `Failed to process action ${action.type} with payload ${JSON.stringify(
                    requestOptions
                )}`,
                error
            );
            yield put(defaultActions.failed());
        }
    }
}

/**
 * Creating action channel for
 * @param {action_redux_act} actionType - it's generated action type using default action + modelName
 * @param {generator} workerSaga - it's generated action type using default action + modelName
 * @param {createService} service - Optional
 */
const createActionChannel = (workerSaga, actionType, service = {}, actions = {}) =>
    function* () {
        const channel = yield actionChannel(actionType);
        while (true) {
            const action = yield take(channel);
            yield call(workerSaga, action, service, actions);
        }
    };

export const createSagas = (service = {}, actions = {}) =>
    function* () {
        if (service && actions) {
            // Create action channels for get / export / import / autoSearch / more...
            const watchAgiisConvert = createActionChannel(
                convertAgiisRequest,
                actions.convertAgiisData,
                service,
                actions
            );
            const watchAgrianConvert = createActionChannel(
                convertAgrianRequest,
                actions.convertAgrianData,
                service,
                actions
            );
            const watchAutoSearch = createActionChannel(
                autoSearchRequest,
                actions.autoSearchList,
                service
            );
            const watchCreate = createActionChannel(create, actions.create, service, actions);
            const watchDelete = createActionChannel(
                deleteRequest,
                actions.deleteRequest,
                service,
                actions
            );
            const watchExport = createActionChannel(
                exportRequest,
                actions.exportData,
                service,
                actions
            );
            const watchFetch = createActionChannel(fetch, actions.fetch, service);
            const watchFetchRecord = createActionChannel(fetchRecord, actions.fetchRecord, service);
            const watchGetAgrianConversionData = createActionChannel(
                getAgrianConversionData,
                actions.getAgrianConversionData,
                service,
                actions
            );
            const watchGetNextId = createActionChannel(getNextId, actions.getNextId, service);
            const watchImport = createActionChannel(
                importRequest,
                actions.importData,
                service,
                actions
            );
            const watchImportValid = createActionChannel(
                importValidRequest,
                actions.importValidData,
                service
            );
            const watchImportValidPackage = createActionChannel(
                importValidPackageRequest,
                actions.importValidPackageData,
                service
            );
            const watchImportValidPlantBackRestriction = createActionChannel(
                importValidPlantBackRestrictionRequest,
                actions.importValidPlantBackRestrictionData,
                service
            );
            const watchPackageExport = createActionChannel(
                exportPackageRequest,
                actions.exportPackageData,
                service,
                actions
            );
            const watchPackageImport = createActionChannel(
                importPackageRequest,
                actions.importPackageData,
                service,
                actions
            );
            const watchPlantBackRestrictionExport = createActionChannel(
                exportPlantBackRestrictionRequest,
                actions.exportPlantBackRestrictionData,
                service,
                actions
            );
            const watchPlantBackRestrictionImport = createActionChannel(
                importPlantBackRestrictionRequest,
                actions.importPlantBackRestrictionData,
                service,
                actions
            );
            const watchSaveAgrianConversionData = createActionChannel(
                saveAgrianConversionData,
                actions.saveAgrianConversionData,
                service,
                actions
            );
            const watchSelectAll = createActionChannel(
                selectAllRequest,
                actions.selectAll,
                service
            );
            const watchUpdate = createActionChannel(update, actions.update, service, actions);
            yield fork(watchAgiisConvert);
            yield fork(watchAgrianConvert);
            yield fork(watchAutoSearch);
            yield fork(watchCreate);
            yield fork(watchDelete);
            yield fork(watchExport);
            yield fork(watchFetch);
            yield fork(watchFetchRecord);
            yield fork(watchGetAgrianConversionData);
            yield fork(watchGetNextId);
            yield fork(watchImport);
            yield fork(watchImportValid);
            yield fork(watchImportValidPackage);
            yield fork(watchImportValidPlantBackRestriction);
            yield fork(watchPackageExport);
            yield fork(watchPackageImport);
            yield fork(watchPlantBackRestrictionExport);
            yield fork(watchPlantBackRestrictionImport);
            yield fork(watchSaveAgrianConversionData);
            yield fork(watchSelectAll);
            yield fork(watchUpdate);
        }
    };
