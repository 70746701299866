import React, { Component } from "react";
import "./module-filters.css";
export * from "./filters-toggle";

export interface IModuleFiltersProps {
    children: JSX.Element[];
}
export class ModuleFilters extends Component<IModuleFiltersProps> {
    static defaultProps = {
        children: [],
    };

    render(): JSX.Element {
        return <div className="module-filters-inputs">{this.props.children}</div>;
    }
}
