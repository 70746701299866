import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    TextInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
    NumericInput,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "./../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
const PROPS_ACTIVE_INGREDIENT_NAME = "ingredientName";
const PROPS_ACTIVE_INGREDIENT_PERCENTAGE = "ingredientPercent";

class ActiveIngredientList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
        };
    }

    onEdit = (record, index) => {
        const keysToUpdate = [PROPS_ACTIVE_INGREDIENT_NAME, PROPS_ACTIVE_INGREDIENT_PERCENTAGE];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                hideCloseX
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.activeIngredient
                )}
                actionDisabled={
                    !editData[PROPS_ACTIVE_INGREDIENT_PERCENTAGE] ||
                    !editData[PROPS_ACTIVE_INGREDIENT_NAME]
                }
                onClose={() => toggleModal("isEditing", false)}
                onAction={() => onAddOrEditItem()}
                isOpen={isEditing}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
            >
                <div className="dialog-box-body">
                    <div className="photo-list-item">
                        <div className="item-container">
                            <TextInput
                                required
                                tabIndex={0}
                                maxLength={50}
                                autoFocus
                                placeholderText={formatMessage(messages.activeIngredientName)}
                                labelText={formatMessage(messages.activeIngredientName)}
                                value={formData[PROPS_ACTIVE_INGREDIENT_NAME] || ""}
                                onChange={(value) =>
                                    onChange({
                                        [PROPS_ACTIVE_INGREDIENT_NAME]: value,
                                    })
                                }
                            />
                            <NumericInput
                                tabIndex={0}
                                placeholderText={formatMessage(messages.activeIngredientPercentage)}
                                labelText={formatMessage(messages.activeIngredientPercentage)}
                                value={formData[PROPS_ACTIVE_INGREDIENT_PERCENTAGE] || ""}
                                scale={5}
                                precision={8}
                                onChange={(v, f, numericValue) =>
                                    onChange({
                                        [PROPS_ACTIVE_INGREDIENT_PERCENTAGE]: numericValue,
                                    })
                                }
                                required
                            />
                        </div>
                    </div>
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_ACTIVE_INGREDIENT_NAME]: {
                                title: formatMessage(messages.name),
                            },
                            [PROPS_ACTIVE_INGREDIENT_PERCENTAGE]: {
                                title: formatMessage(messages.percentage),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.activeIngredient)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(ActiveIngredientList);
