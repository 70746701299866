import React from "react";
import classNames from "classnames";

import { GeneralIcon } from "~/core/icons";

import "./field-information-menu.css";

export class FieldInformationMenu extends React.Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {};
    static defaultProps = {};
    /// ----------------------------------------- ///

    /// Event Handlers ///

    /// ------------- ///

    render() {
        const generalText = "General";

        return (
            <div className={classNames("information-menu", "field-information-menu")}>
                <div
                    className={classNames("information-menu-tab", "general-tab", "active-tab")}
                    title={generalText}
                >
                    <GeneralIcon className="side-menu-icon" />
                    <div className="information-menu-tab-text">{generalText}</div>
                </div>
            </div>
        );
    }
}
