import React, { Component } from "react";
import _ from "lodash";
import { SelectInput, withIgnoreUnderScore } from "~/core";
import { constants, model } from "./../../data";
import { adminData } from "~/admin/data";
import { ImportAttribute, SampleAttribute, SelectableAttribute } from "../../data/model";

interface UpdateManualImportAttributesValue {
    importAttributeGuid: string;
    columnOrderInFile: number;
}

interface UpdateManualImportAttributes {
    value: UpdateManualImportAttributesValue;
}

interface ITemplateManagerManualGridProps {
    sampleAttributes: SampleAttribute[];
    importAttributes: ImportAttribute[];
    updateManualImportAttributes: (payload: UpdateManualImportAttributes) => void;
}

interface ITemplateManagerManualGridState {
    sampleAttributes: SelectableAttribute[];
    selectedSampleAttributes: ImportAttribute[];
}

export class TemplateManagerManualGrid extends Component<
    ITemplateManagerManualGridProps,
    ITemplateManagerManualGridState
> {
    NewSelectInput: typeof Component;

    constructor(props: ITemplateManagerManualGridProps) {
        super(props);
        this.state = {
            sampleAttributes: this.prepareSelectableOptions(props.sampleAttributes || []),
            selectedSampleAttributes: props.importAttributes || [],
        };
        this.NewSelectInput = withIgnoreUnderScore(SelectInput);
    }

    shouldComponentUpdate(nextProps: ITemplateManagerManualGridProps): boolean {
        if (
            nextProps.sampleAttributes !== this.props.sampleAttributes ||
            nextProps.importAttributes !== this.props.importAttributes
        ) {
            return true;
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(nextProps: ITemplateManagerManualGridProps): void {
        this.initializeSampleAttributes(nextProps);

        if (!_.isEqual(nextProps.importAttributes, this.props.importAttributes)) {
            this.setState({
                selectedSampleAttributes: nextProps.importAttributes,
            });
        }
    }

    prepareSelectableOptions = (options: SampleAttribute[]): model.SelectableAttribute[] =>
        options.reduce((result: SelectableAttribute[], option) => {
            result.push({
                value: option.sampleAttributeGuid,
                label: option.sampleAttributeName,
            });
            return result;
        }, []);

    initializeSampleAttributes = (nextProps: ITemplateManagerManualGridProps): void => {
        if (nextProps.sampleAttributes !== this.props.sampleAttributes) {
            this.setState({
                [constants.SAMPLE_ATTRIBUTES]: this.prepareSelectableOptions(
                    nextProps.sampleAttributes
                ),
            });
        }
    };

    onSampleAttributeChange = (importAttributeGuid: string, columnOrderInFile: number): void => {
        this.props.updateManualImportAttributes({
            value: {
                importAttributeGuid: importAttributeGuid || "",
                columnOrderInFile,
            },
        });
    };

    getSelectableOptions = (importAttributeGuid: string): model.SelectableAttribute[] => {
        const selectedSampleAttributes = this.props.importAttributes;
        const sampleAttributes = this.state.sampleAttributes.slice();
        _.remove(sampleAttributes, (attr) => {
            for (const key in selectedSampleAttributes) {
                if (
                    attr.value ===
                        selectedSampleAttributes[key][model.PROPS_IMPORT_ATTRIBUTE_GUID] &&
                    attr.value !== importAttributeGuid
                ) {
                    return true;
                }
            }
            return false;
        });
        return sampleAttributes;
    };

    getImportAttributeValue = (importAttributeGuid: string): string => {
        const importAttribute = this.props.importAttributes.find((attr) => {
            return attr.importAttributeGuid === importAttributeGuid;
        });
        return importAttribute ? importAttribute.importAttributeGuid : null;
    };

    renderManualAttributes = (): JSX.Element[] => {
        const attributesToDisplay = this.props.importAttributes.slice();
        const { NewSelectInput } = this;
        // Add a blank/empty attribute if the last attribute isn't already blank and
        // there are still more attributesl to select.
        if (
            attributesToDisplay.length === 0 ||
            (attributesToDisplay[attributesToDisplay.length - 1].importAttributeGuid !== "" &&
                attributesToDisplay.length !== this.props.sampleAttributes.length)
        ) {
            const newImportAttribute = new ImportAttribute("", null);
            newImportAttribute.columnOrderInFile = attributesToDisplay.length;
            attributesToDisplay.push(newImportAttribute);
        }
        return attributesToDisplay
            .sort((a, b) => a.columnOrderInFile - b.columnOrderInFile)
            .map(({ importAttributeGuid }, index) => {
                const displayCount = index + 1;
                return (
                    <div className="import-attribute-cont" key={`${importAttributeGuid}-${index}`}>
                        <span className="counter-cont">{displayCount}</span>
                        <NewSelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            value={importAttributeGuid}
                            options={this.getSelectableOptions(importAttributeGuid)}
                            onChange={(value) => this.onSampleAttributeChange(value, index)}
                            placeholderText={""}
                        />
                    </div>
                );
            });
    };

    render(): JSX.Element {
        return (
            <div className="template-manager-grid form-section">
                <div className="template-manager-grid-container">
                    <div className="template-manager-grid-table">
                        <div className="template-manager-attributes">
                            {this.props.sampleAttributes && this.props.sampleAttributes.length > 0
                                ? this.renderManualAttributes()
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
