import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { getTheUserGuid } from "~/login/selectors";
import { mapToolsActions } from "~/map";
import { actions as notificationActions } from "~/notifications";
import { eventsModels } from "~/recs-events";

import { AgEventAPI, PicklistAPI, UserAPI } from "@ai360/core";
import * as eventInfoActions from "../../actions";
import * as actions from "./actions";
import { messages } from "../../i18n-messages";
import { setAutoTraceDefault } from "./actions";
import { getAutoTraceDefault } from "./selectors";

const _requestAutoTracePreferences = function (userGuid) {
    return UserAPI.getUserPreferenceByKey(
        userGuid,
        UserAPI.UserPreferenceKeys.autoTraceDefault
    ).then(({ value }) => (value != null && value.toLowerCase() === "true" ? true : false));
};

const getAutoTracePreferences = function* (action) {
    const autoTraceDefault = yield select(getAutoTraceDefault);
    if (autoTraceDefault != null) {
        return;
    }
    const userGuid = yield select(getTheUserGuid);
    try {
        const preferences = yield call(_requestAutoTracePreferences, userGuid);
        yield put(setAutoTraceDefault(preferences));
    } catch (err) {
        yield put(
            notificationActions.apiCallError(err, action, messages.errorGettingAutoTracePreferences)
        );
    }
};

const saveAutoTracePreferences = function* (action) {
    try {
        const { autoTraceDefault } = action.payload;
        const userGuid = yield select(getTheUserGuid);
        yield put(
            mapToolsActions.setActiveToolsetPayloadOnly({
                auto: autoTraceDefault,
            })
        );
        yield put(actions.setAutoTraceDefault(autoTraceDefault));
        yield call(
            UserAPI.saveUserPreference,
            userGuid,
            UserAPI.UserPreferenceKeys.autoTraceDefault,
            autoTraceDefault
        );
    } catch (err) {
        yield put(
            notificationActions.apiCallError(err, action, messages.errorSavingAutoTracePreferences)
        );
    }
};

export const parseSampleSoilDepthUnitPicklist = (picklistValues) => {
    return picklistValues.map((val) => ({
        label: val.abbreviation,
        value: val.importAttributeGuid,
        activeYn: val.activeYn,
    }));
};

export const onFetchSampleSoilEventDefaults = function* (action) {
    yield put(actions.setUserGridSettings(null));

    const userGuid = yield select(getTheUserGuid);
    let userDepthConfig;
    let userGridSettings;
    try {
        userDepthConfig = yield call(AgEventAPI.getUserDepthConfig, userGuid);
        userGridSettings = yield call(AgEventAPI.getDefaultUserGridSettings, userGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }

    if (userGridSettings.areaDimension == null || userGridSettings.areaDimension === "") {
        userGridSettings.areaDimension = eventsModels.AreaDimension.AREA;
    }
    if (userGridSettings.pointPlacement == null || userGridSettings.pointPlacement === "") {
        userGridSettings.pointPlacement = eventsModels.PointPlacement.CENTROID;
    }
    if (userGridSettings.height == null) {
        userGridSettings.height = 330;
    }
    if (userGridSettings.width == null) {
        userGridSettings.width = 330;
    }
    if (userGridSettings.gridSize == null) {
        userGridSettings.gridSize = 2.5;
    }
    if (userGridSettings.rotation == null) {
        userGridSettings.rotation = 0;
    }
    if (userGridSettings.offsetX == null) {
        userGridSettings.offsetX = 0;
    }
    if (userGridSettings.offsetY == null) {
        userGridSettings.offsetY = 0;
    }

    yield put(actions.setUserDepthConfig(userDepthConfig));
    yield put(actions.setUserGridSettings(userGridSettings));
};

export const onFetchSampleSoilDepthUnitPicklists = function* (action) {
    const userGuid = yield select(getTheUserGuid);
    let sampleSoilDepthUnitOptions;
    try {
        const picklistValues = yield call(
            PicklistAPI.getUnitPicklistBySystemAttributeAndEventType,
            userGuid,
            "Sample Depth",
            "Sampling"
        );
        sampleSoilDepthUnitOptions = parseSampleSoilDepthUnitPicklist(picklistValues);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
    yield put(
        eventInfoActions.updateEventInfoPicklists({
            sampleSoilDepthUnitOptions,
        })
    );
};

export const onUpdateUserDepthConfig = function* (action) {
    const { userDepthConfig } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(AgEventAPI.setUserDepthConfig, userGuid, userDepthConfig);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
    yield put(actions.setUserDepthConfig(userDepthConfig));
};

export const sampleSoilSaga = function* () {
    yield all([
        takeLatest(actions.FETCH_AUTO_TRACE_PREFERENCES, getAutoTracePreferences),
        takeLatest(actions.FETCH_SAMPLE_SOIL_EVENT_DEFAULTS, onFetchSampleSoilEventDefaults),
        takeLatest(actions.FETCH_SAMPLE_SOIL_FORM_PICKLISTS, onFetchSampleSoilDepthUnitPicklists),
        takeLatest(actions.UPDATE_AUTO_TRACE_PREFERENCES, saveAutoTracePreferences),
        takeLatest(actions.UPDATE_USER_DEPTH_CONFIG, onUpdateUserDepthConfig),
    ]);
};
