import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Messages } from "../i18n-messages";
import { Menu, NoLink } from "../../core";
import "./navigation.css";
import { getNavList } from "../selectors";
import { getTheOwnerBrandName, getUser } from "~/login/selectors";
import { getUserPreference } from "~/admin/setup/preference/data/selectors";
import { updateNotificationsRead } from "~/notifications/actions";
import {
    getNotificationUnReadReportsCount,
    getNotificationUnReadSamplingCount,
} from "~/notifications/selectors";
import { injectIntl, intlShape } from "react-intl";
import { NavigationAPI, SortingHelpers, PreferenceAPI, UserAPI } from "@ai360/core";
import { getIsEnabled } from "~/action-panel";

interface INavigationProps {
    systemName: string;
    isEnabled: boolean;
    navigationItems: NavigationAPI.INavigation[];
    notificationReportsCount: number;
    notificationSamplingCount: number;
    loadDetailPage: (childNameCode: string, parentNameCode: string, slidingPanelProps?) => void;
    intl: intlShape;
    updateNotificationsRead: () => void;
    userInfo: UserAPI.IUser;
    userPreference: PreferenceAPI.IUserPreference;
}

interface INavigationState {
    navigationItems: NavigationAPI.INavigation[];
}
class NavigationPresentation_ extends Component<INavigationProps, INavigationState> {
    constructor(props) {
        super(props);
        this.state = {
            navigationItems: this.#fillNavigationList(props),
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: INavigationProps) {
        this.setState({
            navigationItems: this.#fillNavigationList(nextProps),
        });
    }

    #fillNavigationList = (props: INavigationProps): NavigationAPI.INavigation[] => {
        const { formatMessage } = props.intl;
        const unsortedParentArray = [];
        props.navigationItems.forEach(function (item) {
            const unsortedChildArray = [];
            let sortedChildArray = [];
            item.label = item.displayText ? formatMessage(item.displayText) : "";
            if (item.gearboxList) {
                if (item.gearboxList.length > 0) {
                    item.gearboxList.forEach(function (childItem) {
                        const { softwareFunctionNameCode } = childItem;
                        childItem.action = () => {
                            props.loadDetailPage(
                                softwareFunctionNameCode,
                                item.softwareFunctionNameCode
                            );
                        };
                        // Proprietary Reports (224) System Name is used for context menu label
                        childItem.label =
                            childItem.softwareFunctionNameCode === "224"
                                ? props.systemName
                                : formatMessage(Messages[softwareFunctionNameCode]); //Todo: this should display the Owner Branding Name over the new Branding Name

                        unsortedChildArray.push(childItem);
                    });
                    sortedChildArray = SortingHelpers.sortValues(unsortedChildArray, "label");

                    //ensure branding is always first in list
                    if (
                        unsortedChildArray.some((item) => item.softwareFunctionNameCode === "224")
                    ) {
                        const brandingIndex = sortedChildArray.findIndex(
                            (x) => x.softwareFunctionNameCode === "224"
                        );
                        const element = sortedChildArray[brandingIndex];
                        sortedChildArray.splice(brandingIndex, 1);
                        sortedChildArray.splice(0, 0, element);
                    }
                } else {
                    sortedChildArray = null;
                }
            }
            unsortedParentArray.push({
                ...item,
                gearboxList: sortedChildArray,
            });
        });
        return unsortedParentArray.sort(
            (a, b) => this.#getParentOrder(a.label) - this.#getParentOrder(b.label)
        );
    };

    #forceClose = (code: string): void => {
        this.props.loadDetailPage(null, null, {});
        this.#onClose(code);
    };

    #getBadge = (notificationCount: number): JSX.Element => {
        return (
            <span className="notification-value-count" title={notificationCount?.toString()}>
                {notificationCount}
            </span>
        );
    };

    #getParentOrder = (label: string): number => {
        const order = new Map([
            ["AgBytes", 1],
            ["Notifications", 2],
            ["Analytics", 3],
            ["Reports", 4],
            ["Setup", 5],
        ]);
        return order.get(label);
    };

    #getTotalNotifications = (includeReports: boolean, includeSampling: boolean): number => {
        const { notificationReportsCount, notificationSamplingCount } = this.props;
        let total = 0;
        if (includeReports) {
            total += notificationReportsCount;
        }
        if (includeSampling) {
            total += notificationSamplingCount;
        }
        return total;
    };

    #onClose = (code: string): void => {
        if (code !== "429") {
            return;
        }
        this.props.updateNotificationsRead();
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { userInfo, userPreference } = this.props;
        const totalNotificationCount = this.#getTotalNotifications(
            userPreference?.receiveWebNotificationsForReports,
            userPreference?.receiveWebNotificationsForSamplingResults
        );
        const displayBadge = totalNotificationCount > 0;
        const navigationList = this.state.navigationItems.map((item) => {
            const navigationItem = item.softwareFunctionNameCode === "429";
            const itemClassnames =
                navigationItem && displayBadge
                    ? classnames("notification-link", {
                          disabled: !this.props.isEnabled,
                      })
                    : classnames("link-item-text", {
                          disabled: !this.props.isEnabled,
                      });
            return navigationItem && !userInfo?.role.notifications ? null : (
                <li className="top-navigation" key={item.softwareFunctionGuid}>
                    {item.gearboxList ? (
                        /*if child list create menu and display arrow*/
                        <Menu
                            className={classnames("nav-menu", {
                                disabled: !this.props.isEnabled,
                            })}
                            label={item.displayText ? formatMessage(item.displayText) : ""}
                            menuItems={this.props.isEnabled ? item.gearboxList : []}
                        />
                    ) : (
                        /*display link when no children*/
                        <div>
                            <NoLink
                                className={itemClassnames}
                                onClick={() =>
                                    this.props.loadDetailPage(
                                        item.softwareFunctionNameCode,
                                        "100",
                                        {
                                            forceClose: this.#forceClose.bind(
                                                this,
                                                item.softwareFunctionNameCode
                                            ),
                                            close: this.#onClose.bind(
                                                this,
                                                item.softwareFunctionNameCode
                                            ),
                                        }
                                    )
                                }
                                disabled={!this.props.isEnabled}
                                label={item.displayText ? formatMessage(item.displayText) : ""}
                            />
                            {!displayBadge || item.softwareFunctionNameCode !== "429"
                                ? null
                                : this.#getBadge(totalNotificationCount)}
                        </div>
                    )}
                </li>
            );
        });

        return (
            <div className="user-menu-container nav-container">
                <ul>{navigationList}</ul>
            </div>
        );
    }
}
export const NavigationPresentation = injectIntl(NavigationPresentation_);
const mapStateToProps = (state) => {
    return {
        navigationItems: getNavList(state),
        notificationReportsCount: getNotificationUnReadReportsCount(state),
        notificationSamplingCount: getNotificationUnReadSamplingCount(state),
        isEnabled: getIsEnabled(state),
        systemName: getTheOwnerBrandName(state),
        userInfo: getUser(state),
        userPreference: getUserPreference(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateNotificationsRead: () => {
            dispatch(updateNotificationsRead());
        },
    };
};

export const Navigation = connect(mapStateToProps, mapDispatchToProps)(NavigationPresentation);
