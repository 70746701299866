import React, { Component } from "react";
import classnames from "classnames";
import {
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
    SelectInput,
    SubSection,
    NumericInput,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { ACTIVE_YN } from "~/core/picklist";
import { keywords as attributeKeywords } from "~/action-panel/components/event-module/keywords";

export interface BaseYieldListProps {
    record?: Record<string, any>[];
    formatMessage?: (str: any) => string;
    renderDeleteModal?: () => void;
    toggleModal?: (act: string, open: boolean) => void;
    onChange?: (change: any) => void;
    onAdd?: () => void;
    onEdit?: ({ record, index, keysToUpdate }) => void;
    onDelete?: ({ record, index }) => void;
    onAddOrEditItem?: () => void;
    deleteItem?: () => void;
    isEditing?: boolean;
    isDeleting?: boolean;
    editData?: Record<string, any>;
    crops?: Record<string, any>[];
    cropPurpose?: Record<string, any>[];
    addGoalMessage?: Record<string, any>[];
    goalPlaceholderMessage?: Record<string, any>;
    // props specific to Break Even mode
    breakEvenMode: boolean;
    dryYieldUnit?: Record<string, any>[];
    fetchSystemAttributePicklist?: (attr: Record<string, any>[]) => void;
    lintYieldUnit?: Record<string, any>[];
}
class BaseYieldList extends Component<BaseYieldListProps> {
    static PROPS_YIELD_GOAL = "yieldGoal";
    static PROPS_CROP_NAME = "cropName";
    static PROPS_CROP_PURPOSE = "cropPurpose";
    static PROPS_CROP_GUID = "cropGuid";
    static PROPS_CROP_PURPOSE_GUID = "cropPurposeGuid";
    static PROPS_YIELD_UNIT = "yieldUnit";
    static PROPS_YIELD_UNIT_GUID = "yieldUnitGuid";
    static COTTON_CROP_ID = "7";

    keysToUpdate = [
        BaseYieldList.PROPS_CROP_GUID,
        BaseYieldList.PROPS_CROP_PURPOSE_GUID,
        BaseYieldList.PROPS_YIELD_GOAL,
        BaseYieldList.PROPS_CROP_NAME,
        BaseYieldList.PROPS_CROP_PURPOSE,
    ];

    constructor(props) {
        super(props);

        if (props.breakEvenMode) {
            this.keysToUpdate.push(BaseYieldList.PROPS_YIELD_UNIT_GUID);
            this.keysToUpdate.push(BaseYieldList.PROPS_YIELD_UNIT);
        }
    }

    public onEdit = (record, index): void => {
        this.props.onEdit({ record, index, keysToUpdate: this.keysToUpdate });
    };
    public onDelete = (record, index): void => {
        this.props.onDelete({ record, index });
    };
    public onAdd = (): void => {
        this.props.onAdd();
    };

    componentDidMount(): void {
        if (this.props.breakEvenMode) {
            const attributesToFetch = [];
            if (!this.props.dryYieldUnit) {
                attributesToFetch.push({
                    model: attributeKeywords.dryYieldUnit,
                    systemAttribute: attributeKeywords.dryYield,
                    eventType: attributeKeywords.harvest,
                    forceRaw: true,
                });
            }
            if (!this.props.lintYieldUnit) {
                attributesToFetch.push({
                    model: attributeKeywords.lintYieldUnit,
                    systemAttribute: attributeKeywords.lintYield,
                    eventType: attributeKeywords.harvest,
                    forceRaw: true,
                });
            }

            if (attributesToFetch.length > 0) {
                this.props.fetchSystemAttributePicklist(attributesToFetch);
            }
        }
    }

    _getYieldUnitList = () => {
        const { editData } = this.props;
        if (!editData[BaseYieldList.PROPS_CROP_GUID]) {
            return [];
        }

        const selectedCrop = this.props.crops.find(
            (c) => c.value.cropGuid === editData[BaseYieldList.PROPS_CROP_GUID]
        );

        return (
            selectedCrop.value.cropId === BaseYieldList.COTTON_CROP_ID
                ? this.props.lintYieldUnit
                : this.props.dryYieldUnit
        )
            .filter((u) => u.activeYn)
            .map((u) => ({ label: u.abbreviation, value: u.unitGuid }));
    };

    renderAddEditModal = () => {
        const {
            formatMessage,
            isEditing,
            editData,
            onAddOrEditItem,
            onChange,
            toggleModal,
            addGoalMessage,
            goalPlaceholderMessage,
            breakEvenMode,
        } = this.props;
        const formData = { ...editData };

        const yieldUnitList = isEditing && breakEvenMode ? this._getYieldUnitList() : [];

        return (
            <DialogBox
                className="crop-yield-dialog"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={
                    !editData[BaseYieldList.PROPS_YIELD_GOAL] ||
                    !editData[BaseYieldList.PROPS_CROP_GUID]
                }
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(isEditing, formatMessage, addGoalMessage)}
            >
                <SubSection>
                    <SelectInput
                        autoFocus
                        openOnFocus={false}
                        optionIsHiddenKey={ACTIVE_YN}
                        value={formData[BaseYieldList.PROPS_CROP_GUID] || ""}
                        options={this.props.crops
                            .filter((cp) => cp[ACTIVE_YN])
                            .map((cp) => ({
                                label: cp.label,
                                value: cp.value.cropGuid,
                            }))}
                        required
                        onChange={(value) => {
                            const selectedLabel = this.props.crops.find(
                                (c) => c.value.cropGuid === value
                            );
                            onChange({
                                [BaseYieldList.PROPS_CROP_GUID]: value,
                                [BaseYieldList.PROPS_CROP_NAME]: selectedLabel
                                    ? selectedLabel.label
                                    : "",
                            });
                        }}
                        placeholderText={formatMessage(messages.cropPlaceholderText)}
                    />
                    <SelectInput
                        openOnFocus={false}
                        optionIsHiddenKey={ACTIVE_YN}
                        value={formData[BaseYieldList.PROPS_CROP_PURPOSE_GUID] || ""}
                        options={this.props.cropPurpose}
                        onChange={(value) => {
                            const selectedLabel = this.props.cropPurpose.find(
                                (c) => c.value === value
                            );
                            onChange({
                                [BaseYieldList.PROPS_CROP_PURPOSE_GUID]: value,
                                [BaseYieldList.PROPS_CROP_PURPOSE]: selectedLabel
                                    ? selectedLabel.label
                                    : "",
                            });
                        }}
                        placeholderText={formatMessage(messages.cropPurposePlaceholderText)}
                    />
                </SubSection>
                <SubSection>
                    <NumericInput
                        maxLength={50}
                        required
                        placeholderText={formatMessage(goalPlaceholderMessage)}
                        labelText={formatMessage(goalPlaceholderMessage)}
                        value={formData[BaseYieldList.PROPS_YIELD_GOAL] || ""}
                        onChange={(value) =>
                            onChange({
                                [BaseYieldList.PROPS_YIELD_GOAL]: value,
                            })
                        }
                    />
                    {breakEvenMode && (
                        <SelectInput
                            openOnFocus={false}
                            value={formData[BaseYieldList.PROPS_YIELD_UNIT_GUID] || ""}
                            options={yieldUnitList}
                            disabled={yieldUnitList.length === 0}
                            required
                            onChange={(value) => {
                                const selectedLabel = yieldUnitList.find((c) => c.value === value);
                                onChange({
                                    [BaseYieldList.PROPS_YIELD_UNIT_GUID]: value,
                                    [BaseYieldList.PROPS_YIELD_UNIT]: selectedLabel
                                        ? selectedLabel.label
                                        : "",
                                });
                            }}
                            placeholderText={formatMessage(messages.yieldUnit)}
                        />
                    )}
                </SubSection>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal, addGoalMessage } = this.props;

        return (
            <div
                className={classnames({
                    "form-section-child-stretch mini-grid": record && record.length > 0,
                    "crop-yield-link-empty": !record || record.length === 0,
                })}
            >
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [BaseYieldList.PROPS_CROP_NAME]: {
                                title: formatMessage(messages.cropPlaceholderText),
                            },
                            [BaseYieldList.PROPS_CROP_PURPOSE]: {
                                title: formatMessage(messages.cropPurposePlaceholderText),
                            },
                            [BaseYieldList.PROPS_YIELD_GOAL]: {
                                title: formatMessage(messages.yieldGoal),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={true}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className={classnames("add-link", {
                            "categories-link": record.length === 0,
                        })}
                        label={createAddLinkLabelText(formatMessage, addGoalMessage)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(BaseYieldList);
