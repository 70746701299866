import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import { Button, Tabs, Pane, Loader } from "~/core";
import { preventBubbleUp } from "~/admin/utils";
import { messages } from "../i18n-messages";
import { Checkbox, Section, SubSection, RadioButtonGroup, RadioButton } from "~/core";
import { adminData, GUID, NAME, LABELNAME } from "~/admin/data";
import { SelectInput, defaultOptionRenderer } from "~/core";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import {
    ANALYSIS_LAYERS_CATEGORY,
    ANALYSIS_LAYERS_CATEGORY_INDEX,
    ANALYTICS_CATEGORY,
    ANALYTICS_CATEGORY_INDEX,
    DEFAULT_RADIO_VALUE,
    EQUATION_APPLICATION_CATEGORY,
    EQUATION_APPLICATION_CATEGORY_INDEX,
    EQUATION_PLANTING_CATEGORY,
    EQUATION_PLANTING_CATEGORY_INDEX,
    LOCATION_RADIO_VALUE,
    OTHERS_CATEGORY,
    REPORTS_CATEGORY,
    REPORTS_CATEGORY_INDEX,
    TEMPLATES_CATEGORY,
    TEMPLATE_CATEGORY_INDEX,
    USER_RADIO_VALUE,
    USER_ROLE_RADIO_VALUE,
    VENDORS_CATEGORY,
    VENDOR_CATEGORY_INDEX,
} from "./data/constants";
import * as model from "./data/model";
import { UserEntities, UserRole, LocationEntity } from "./components";
import "./batch-zapper.css";
import TreeView from "~/core/components/tree-view/tree-view";
import EntitySearch from "./components/entity-search";

export class BatchZapper_ extends Component {
    static propTypes = {
        attributes: PropTypes.array,
        attributesTree: PropTypes.array,
        closeSlidingPanel: PropTypes.func,
        fetchAttributes: PropTypes.func,
        fetchTreeAttributes: PropTypes.func,
        fetchUserCompanyList: PropTypes.func,
        intl: intlShape.isRequired,
        lastUsedOwnerGuid: PropTypes.object,
        needs: PropTypes.func,
        requestIds: PropTypes.object,
        saveAssignment: PropTypes.func,
        saveAssignmentAttribute: PropTypes.func,
        setBreadcrumbs: PropTypes.func,
        setCompanyUserPreference: PropTypes.func,
        userCompanyList: PropTypes.array,
    };

    static CATEGORY_INDEX = "categoryIndex";
    static USE_ONLY = "useOnly";

    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            attributeList: this.prepareSelectableOptions(props.attributes, {
                guid: GUID,
                label: NAME,
                id: BatchZapper_.CATEGORY_INDEX,
                labelName: LABELNAME,
            }),
            attributeTabKey: "attribute",
            entityTabKey: "entity",
            dropdownSelectedEntity: "",
            itemSelected: false,
            isLoading: false,
            searchValue: "",
            searchableAttributeList: [],
            selectedTab: 0,
            selectedAttribute: {},
            selectedEntityFromDropdown: {},
            selectedCompany: {},
            updatedAttributes: [],
            updatedEntities: {
                hierarchyEntities: [],
                users: [],
                userRoles: [],
            },
            selectedEntity: DEFAULT_RADIO_VALUE,
            selectedUserCompanyList: null,
            useOnly: null,
        };
        this.useOnlySupportedAttributes = new Set([
            EQUATION_APPLICATION_CATEGORY_INDEX,
            EQUATION_PLANTING_CATEGORY_INDEX,
            TEMPLATE_CATEGORY_INDEX,
            VENDOR_CATEGORY_INDEX,
        ]);
    }

    componentDidMount() {
        this.props.setBreadcrumbs();
        this.props.fetchAttributes();
        this.props.needs([this.props.fetchUserCompanyList()]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.attributes !== this.props.attributes) {
            this.setState({
                attributeList: this.prepareSelectableOptions(nextProps.attributes, {
                    guid: GUID,
                    label: NAME,
                    id: BatchZapper_.CATEGORY_INDEX,
                    useOnly: BatchZapper_.USE_ONLY,
                    labelName: LABELNAME,
                }),
                attributes: nextProps.attributes,
                isLoading: false,
            });
        }
        if (nextProps.userCompanyList !== this.props.userCompanyList) {
            this.setState(
                {
                    userCompanyList: nextProps.userCompanyList.map(
                        ({ companyName, companyGuid, userGuid }) => ({
                            label: companyName,
                            value: {
                                companyGuid,
                                userGuid,
                            },
                        })
                    ),
                },
                this.setDefaultCompany
            );
        }

        if (nextProps.lastUsedOwnerGuid !== this.props.lastUsedOwnerGuid) {
            this.setState({
                selectedCompany: nextProps.lastUsedOwnerGuid,
            });
        }
    }

    setDefaultCompany = () => {
        if (this.state.userCompanyList.length === 1) {
            this.onCompanyNameChange(this.state.userCompanyList[0].value);
        }
    };

    prepareOptions = (options, { guid, label, id, useOnly }) =>
        _.reduce(
            options,
            (result, option) => {
                result.push({
                    label: option[label],
                    value: {
                        guid: option[guid],
                        id: option[id],
                        name: option[label],
                        useOnly: option[useOnly],
                        categoryIndex: option[BatchZapper_.CATEGORY_INDEX],
                        labelName: option.labelName,
                    },
                });
                return result;
            },
            []
        );

    /* eslint-disable react/prop-types */
    optionRenderer = ({ option, isHeader, isSelected, isHighlighted, matchPos, matchLen }) => {
        if (
            option.value &&
            (option.value.categoryIndex === 7 || option.value.categoryIndex === 8)
        ) {
            let titleValue = option.value.labelName;
            return defaultOptionRenderer({
                option,
                isHeader,
                isSelected,
                isHighlighted,
                matchPos,
                matchLen,
                titleValue,
            });
        }
        if (!isHeader) {
            return defaultOptionRenderer({
                option,
                isHeader,
                isSelected,
                isHighlighted,
                matchPos,
                matchLen,
            });
        }
        return (
            <div className="select-form-sub-header">
                <span>{option.label}</span>
            </div>
        );
    };

    getCategoryGroupName(categoryIndex) {
        switch (categoryIndex) {
            case 2:
                return ANALYSIS_LAYERS_CATEGORY;
            case 3:
                return REPORTS_CATEGORY;
            case 4:
                return TEMPLATES_CATEGORY;
            case 5:
                return VENDORS_CATEGORY;
            case 6:
                return ANALYTICS_CATEGORY;
            case 7:
                return EQUATION_APPLICATION_CATEGORY;
            case 8:
                return EQUATION_PLANTING_CATEGORY;
            default:
                return OTHERS_CATEGORY;
        }
    }

    prepareSelectableOptions = (options, { guid, label, id, useOnly, labelName }) => {
        let categories = [
            ANALYSIS_LAYERS_CATEGORY_INDEX,
            ANALYTICS_CATEGORY_INDEX,
            EQUATION_APPLICATION_CATEGORY_INDEX,
            EQUATION_PLANTING_CATEGORY_INDEX,
            REPORTS_CATEGORY_INDEX,
            TEMPLATE_CATEGORY_INDEX,
            VENDOR_CATEGORY_INDEX,
        ];
        categories = categories.map((category) => {
            let categoryArray = options.filter((option) => option.categoryIndex === category);
            if (categoryArray.length > 0) {
                return [
                    {
                        label: this.getCategoryGroupName(category),
                        category,
                        header: true,
                        [adminData.PROPS_ACTIVE_YN]: true,
                    },
                    ...this.prepareOptions(categoryArray, {
                        guid,
                        label,
                        id,
                        useOnly,
                        labelName,
                    }),
                ];
            }
            return [];
        });
        return _.flatten(categories);
    };

    onAttributeChange = (selectedAttribute) => {
        const useOnly = selectedAttribute ? selectedAttribute.useOnly : false;
        this.setState({
            selectedAttribute,
            useOnly,
        });
    };

    onEntityChange = (value) => {
        const useOnly = this.state.selectedAttribute ? this.state.selectedAttribute.useOnly : false;
        this.setState({
            entityValue: value,
            selectedEntityFromDropdown: {},
            useOnly,
        });
    };

    onCompanyNameChange = (value) => {
        this.props.setCompanyUserPreference(value.companyGuid);
        this.setState({
            selectedCompany: {
                userGuid: value.userGuid,
                companyGuid: value.companyGuid,
            },
            selectedEntityFromDropdown: {},
        });
    };

    onPermissionChange = (entityType, data) => {
        const { updatedEntities } = this.state;
        // Clear the array if a new entity type is changed
        if (!updatedEntities[entityType]) {
            updatedEntities[entityType] = [];
        }
        const currEntityType = updatedEntities[entityType];
        const entityToBeUpdatedIdx = currEntityType.findIndex(
            (entity) => entity.entityGuid === data.entityGuid
        );
        if (entityToBeUpdatedIdx > -1) {
            currEntityType.splice(entityToBeUpdatedIdx, 1, data);
        } else {
            currEntityType.push(data);
        }
        updatedEntities[entityType] = currEntityType;
        this.setState({
            updatedEntities,
        });
    };

    onUseOnlyChange = (value) => {
        this.setState({
            useOnly: value,
        });
    };

    onSave = () => {
        const { selectedEntityFromDropdown } = this.state;
        const { guid, id } = this.state.selectedAttribute;
        const entityType = this.state.entityValue;
        const useOnly =
            this.state.useOnly !== null ? this.state.useOnly : this.state.selectedAttribute.useOnly;
        const companyGuid = this.state.selectedCompany
            ? this.state.selectedCompany.companyGuid
            : "";

        let attributeList = this.state.updatedAttributes.map((values) => {
            return {
                attributeGuid: values.guid,
                attributeType: values.categoryName,
                isAssigned: values.isAssigned,
            };
        });

        if (!this.state.selectedTab) {
            this.props.needs([
                this.props.saveAssignment({
                    attributeGuid: guid,
                    attributeType: id,
                    useOnly,
                    companyGuid,
                    entityType,
                    ...this.state.updatedEntities,
                }),
            ]);
        } else {
            this.props.needs([
                this.props.saveAssignmentAttribute({
                    attribute: attributeList,
                    useOnly,
                    companyGuid,
                    entityType,
                    entity: selectedEntityFromDropdown,
                }),
            ]);
        }
    };

    getEntityDropdownValue = (data) => {
        if (data) {
            this.setState({
                selectedEntityFromDropdown: data,
                searchValue: "",
                isLoading: true,
            });
        } else {
            this.setState({ selectedEntityFromDropdown: {}, searchValue: "" });
            return;
        }

        const fetchAttributesData = {
            entityGuid: data,
            entityType: this.state.entityValue,
            companyGuid: this.state.selectedCompany.companyGuid,
        };

        this.props.fetchTreeAttributes(fetchAttributesData);
    };

    _setCurrentEntities = (newEntities) => {
        if (newEntities.some((entity) => entity.useOnly)) {
            this.setState({
                useOnly: true,
            });
        }
    };

    getEntityComponent = () => {
        const { entityValue } = this.state;
        const { formatMessage } = this.props.intl;
        switch (entityValue) {
            case LOCATION_RADIO_VALUE:
                return (
                    <LocationEntity
                        labelKey={model.PROPS_FULL_NAME}
                        guidKey={model.PROPS_USER_GUID}
                        entityType={model.PROPS_HIERARCHY_ENTITIES}
                        getEntityDropdownValue={this.getEntityDropdownValue}
                        isEntityTab={this.state.selectedTab}
                        placeholderText={formatMessage(messages.searchLocation)}
                        formatMessage={formatMessage}
                        selectedAttribute={this.state.selectedAttribute}
                        useOnly={this.state.useOnly}
                        onPermissionChange={this.onPermissionChange}
                        onUseOnlyChange={this.onUseOnlyChange}
                        useOnlySupportedAttributes={this.useOnlySupportedAttributes}
                        needs={this.props.needs}
                        requestIds={this.props.requestIds}
                        selectedCompany={this.state.selectedCompany}
                        setCurrentEntities={this._setCurrentEntities}
                    />
                );
            case USER_RADIO_VALUE:
                return (
                    <UserEntities
                        labelKey={model.PROPS_FULL_NAME}
                        guidKey={model.PROPS_USER_GUID}
                        entityType={model.PROPS_USERS}
                        getEntityDropdownValue={this.getEntityDropdownValue}
                        isEntityTab={this.state.selectedTab}
                        placeholderText={formatMessage(messages.userSearch)}
                        formatMessage={formatMessage}
                        selectedAttribute={this.state.selectedAttribute}
                        useOnly={this.state.useOnly}
                        onPermissionChange={this.onPermissionChange}
                        onUseOnlyChange={this.onUseOnlyChange}
                        useOnlySupportedAttributes={this.useOnlySupportedAttributes}
                        needs={this.props.needs}
                        selectedCompany={this.state.selectedCompany}
                        setCurrentEntities={this._setCurrentEntities}
                    />
                );
            case USER_ROLE_RADIO_VALUE:
                return (
                    <UserRole
                        labelKey={model.PROPS_VALUE}
                        guidKey={model.PROPS_GUID}
                        entityType={model.PROPS_USER_ROLES}
                        getEntityDropdownValue={this.getEntityDropdownValue}
                        isEntityTab={this.state.selectedTab}
                        placeholderText={formatMessage(messages.userRoleSearch)}
                        formatMessage={formatMessage}
                        selectedAttribute={this.state.selectedAttribute}
                        useOnly={this.state.useOnly}
                        onPermissionChange={this.onPermissionChange}
                        onUseOnlyChange={this.onUseOnlyChange}
                        useOnlySupportedAttributes={this.useOnlySupportedAttributes}
                        needs={this.props.needs}
                        selectedCompany={this.state.selectedCompany}
                        setCurrentEntities={this._setCurrentEntities}
                    />
                );
            default:
                return null;
        }
    };

    filterOptions(filterVal, options, isHeaderOption) {
        const matchingIdxList = [],
            existingHeaderOptions = [];
        const optionMatchPositions = [];
        const filteredOptListIdxMap = new Map();
        filterVal = filterVal == null ? "" : filterVal.toLowerCase();
        const headerOptions = options.reduce((acc, option, index) => {
            if (isHeaderOption(option)) {
                acc.push({ index, categoryIndex: option.category });
            }
            return acc;
        }, []);
        for (let optionIdx = 0, matchCount = 0; optionIdx < options.length; optionIdx++) {
            const option = options[optionIdx];
            const labelVal = option.label.toLowerCase();
            const idx = labelVal.indexOf(filterVal);
            optionMatchPositions.push(idx);
            if (labelVal === filterVal && matchingIdxList.length < 2) {
                matchingIdxList.push(optionIdx);
            }
            if (idx !== -1) {
                if (option.category) {
                    existingHeaderOptions.push(optionIdx);
                } else {
                    const headerIdx = headerOptions.find((item) => {
                        if (!option.category) {
                            return item.categoryIndex === option.value.categoryIndex;
                        }
                        return false;
                    });
                    if (
                        filterVal.length > 0 &&
                        headerIdx &&
                        existingHeaderOptions.indexOf(headerIdx.index) === -1
                    ) {
                        filteredOptListIdxMap.set(matchCount++, headerIdx.index);
                        existingHeaderOptions.push(headerIdx.index);
                    }
                }
                filteredOptListIdxMap.set(matchCount++, optionIdx);
            }
        }

        const exactMatchIdx = matchingIdxList.length === 1 ? matchingIdxList[0] : null;
        return [optionMatchPositions, filteredOptListIdxMap, exactMatchIdx];
    }

    _getIsSaveDisabled = () => {
        const { hierarchyEntities, users, userRoles } = this.state.updatedEntities;
        const areEntitiesUpdated = !hierarchyEntities.length && !users.length && !userRoles.length;

        return this.state.useOnly === null ? areEntitiesUpdated : !this.state.entityValue;
    };

    searchedAttribute = (searchedAttribute) => {
        this.setState({ selectedAttribute: searchedAttribute });
    };

    renderSelectAttribute = () => {
        const { formatMessage } = this.props.intl;
        const { selectedTab } = this.state;
        let header;
        if (selectedTab === 0) {
            header = messages.selectAttributeAttributeTab;
        } else if (selectedTab === 1) {
            header = messages.selectAttributeEntityTab;
        }
        return (
            <div className="section-container">
                <Section headerText={formatMessage(header)}>
                    <SubSection>
                        {!this.state.selectedTab ? (
                            <SelectInput
                                required
                                clearable={false}
                                autoFocus
                                openOnFocus={false}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                placeholderText={formatMessage(messages.attribute)}
                                value={this.state.selectedAttribute}
                                options={this.state.attributeList}
                                onChange={(value) => {
                                    this.onAttributeChange(value);
                                }}
                                containerClassNames={["batch-zapper-attribute"]}
                                optionIsHeaderKey="header"
                                optionRenderer={this.optionRenderer}
                                filterOptions={this.filterOptions}
                            />
                        ) : (
                            this.renderEntityTabAttributes()
                        )}
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section className="use-only-cont">
                    <SubSection>
                        {this.useOnlySupportedAttributes.has(
                            this.state.selectedAttribute.categoryIndex
                        ) && !selectedTab ? (
                            <Checkbox
                                onChange={(e, value) => this.onUseOnlyChange(value)}
                                value={this.state.useOnly || this.state.selectedAttribute.useOnly}
                                label={formatMessage(messages.useOnly)}
                                disabled={this.state.selectedAttribute.useOnly}
                            />
                        ) : null}
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderCheckbox = (data) => {
        let checkedProp = { value: data.isAssigned };
        if (Object.hasOwn(data, "children")) {
            const fewChecked = data.children.some((item) => item.isAssigned);
            const allUnchecked = data.children.every((item) => !item.isAssigned);
            const allChecked = data.children.every((item) => item.isAssigned);

            if (allChecked) {
                checkedProp = { value: true };
            } else if (fewChecked) {
                checkedProp = { someChecked: true, value: true };
            } else if (allUnchecked) {
                checkedProp = { value: false };
            }
        }

        return (
            <Checkbox
                onChange={(e, value) => this.checkboxPermissionChange(data, value)}
                {...checkedProp}
            />
        );
    };

    checkboxPermissionChange = (data) => {
        const { updatedAttributes, attributes } = this.state;
        const newUpdatedAttributes = [...updatedAttributes];
        const newAttributes = [...attributes];
        data.isAssigned = !data.isAssigned;

        if (Object.hasOwn(data, "children")) {
            const updatedChildren = data.children.map((child) => {
                return {
                    ...child,
                    isAssigned: data.isAssigned,
                };
            });

            for (let i = 0; i < updatedChildren.length; i++) {
                let childrenObject = updatedChildren[i];

                const attributeToBeUpdatedIdx = newUpdatedAttributes.findIndex(
                    (attribute) => attribute.guid === childrenObject.guid
                );
                if (attributeToBeUpdatedIdx > -1) {
                    newUpdatedAttributes.splice(attributeToBeUpdatedIdx, 1, childrenObject);
                } else {
                    newUpdatedAttributes.push(childrenObject);
                }
            }

            const parentIdx = newAttributes.findIndex(
                (parent) => parent.labelName === data.labelName
            );
            newAttributes.splice(parentIdx, 1, {
                ...data,
                isAssigned: data.isAssigned,
                children: updatedChildren,
            });

            this.setState({
                updatedAttributes: newUpdatedAttributes,
                attributes: newAttributes,
            });
        } else {
            let newAttributesList = newAttributes.map((parent) => {
                return {
                    ...parent,
                    children: parent.children.map((child) => {
                        return child.guid === data.guid ? data : child;
                    }),
                };
            });

            const attributeToBeUpdatedIdx = newUpdatedAttributes.findIndex(
                (attribute) => attribute.guid === data.guid
            );
            if (attributeToBeUpdatedIdx > -1) {
                newUpdatedAttributes.splice(attributeToBeUpdatedIdx, 1, data);
            } else {
                newUpdatedAttributes.push(data);
            }

            this.setState({
                updatedAttributes: newUpdatedAttributes,
                attributes: newAttributesList,
            });
        }
    };

    onSearchChange = (searchValue) => {
        this.setState({
            searchValue,
        });
    };

    renderEntityTabAttributes = () => {
        const { formatMessage } = this.props.intl;
        const { selectedEntityFromDropdown, searchValue, isLoading } = this.state;

        const filteredAttributes = searchValue.toLowerCase()
            ? this.state.attributes
                  .filter((parent) => {
                      return parent.children.some((child) => {
                          return (
                              child.labelName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                          );
                      });
                  })
                  .map((parent) => {
                      return {
                          ...parent,
                          children: parent.children.filter(
                              (child) =>
                                  child.labelName.toLowerCase().indexOf(searchValue.toLowerCase()) >
                                  -1
                          ),
                      };
                  })
            : this.state.attributes;

        return selectedEntityFromDropdown.length == null ? null : isLoading ? (
            <Loader />
        ) : (
            <div>
                <div className="select-company-container">
                    <EntitySearch
                        labelKey="labelName"
                        placeholderText={formatMessage(messages.searchAttribute)}
                        selectedCompany={this.state.selectedCompany}
                        onSearchChange={(value) => this.onSearchChange(value)}
                        filteredData={filteredAttributes.flatMap((results) => results.children)}
                    />
                </div>
                <TreeView
                    rowRenderer={this.renderCheckbox}
                    data={filteredAttributes}
                    displayLabel="labelName"
                />
            </div>
        );
    };

    renderSelectEntities = () => {
        const { formatMessage } = this.props.intl;
        const { selectedTab } = this.state;

        let header;
        if (selectedTab === 0) {
            header = messages.selectEntitiesAttributeTab;
        } else if (selectedTab === 1) {
            header = messages.selectEntitiesEntityTab;
        }

        return (
            <Section headerText={formatMessage(header)}>
                {this.state.userCompanyList && this.state.userCompanyList.length === 1 ? null : (
                    <SubSection>
                        <div className="select-company-container">
                            <span>{formatMessage(messages.selectACompanyMessage)}</span>
                            <SelectInput
                                required
                                clearable={false}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                placeholderText={formatMessage(messages.companyName)}
                                value={this.state.selectedCompany}
                                options={this.state.userCompanyList}
                                onChange={this.onCompanyNameChange}
                            />
                        </div>
                    </SubSection>
                )}

                <SubSection>
                    <RadioButtonGroup
                        afterOnChange={this.onEntityChange}
                        selectedValue={this.state.entityValue}
                        disabled={
                            !this.state.selectedCompany.companyGuid || !this.state.selectedTab
                                ? _.isEmpty(this.state.selectedAttribute)
                                : null
                        }
                    >
                        <RadioButton
                            tabIndex={0}
                            value={LOCATION_RADIO_VALUE}
                            label={formatMessage(messages.location)}
                        />
                        <RadioButton
                            tabIndex={0}
                            value={USER_RADIO_VALUE}
                            label={formatMessage(messages.user)}
                        />
                        <RadioButton
                            tabIndex={0}
                            value={USER_ROLE_RADIO_VALUE}
                            label={formatMessage(messages.userRole)}
                        />
                    </RadioButtonGroup>
                </SubSection>
            </Section>
        );
    };

    getPanes = () => {
        const { attributeTabKey, entityTabKey } = this.state;
        const { formatMessage } = this.props.intl;
        const panes = [];

        panes.push(
            <Pane key={attributeTabKey} label={formatMessage(messages.attribute)}>
                <div className="batch-zapper-container">
                    {this.renderSelectAttribute()}
                    {this.renderSelectEntities()}
                    {this.renderEntities()}
                </div>
            </Pane>
        );
        panes.push(
            <Pane key={entityTabKey} label={formatMessage(messages.entity)}>
                <div className="batch-zapper-container">
                    {this.renderSelectEntities()}
                    {this.renderEntities()}
                    {this.renderSelectAttribute()}
                </div>
            </Pane>
        );

        return panes;
    };

    renderEntities = () => {
        return (
            <Section>
                <SubSection>{this.getEntityComponent()}</SubSection>
            </Section>
        );
    };

    changeBatchZapperTab = (idx) => {
        this.setState({
            selectedTab: idx,
            selectedAttribute: "",
            updatedEntities: {
                hierarchyEntities: [],
                users: [],
                userRoles: [],
            },
            entityValue: 0,
            useOnly: null,
        });

        if (this.state.selectedTab) {
            this.props.fetchAttributes();
        }
    };

    renderBatchZapper = () => {
        return (
            <div className="content-zapper-container">
                <div className="batch-zapper-tabs">
                    <Tabs
                        className="batch-zapper-tabs"
                        selected={this.state.selectedTab}
                        onTabSelect={(idx) => this.changeBatchZapperTab(idx)}
                    >
                        {this.getPanes()}
                    </Tabs>
                </div>
            </div>
        );
    };

    render() {
        /// Property set up, etc. ///
        return (
            <div className="batch-zapper-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    <SlidingPanel
                        component={this.renderBatchZapper}
                        navigateTo={{ parentNameCode: 101, childNameCode: 180 }}
                    >
                        <Button
                            forceSubmit
                            type="save"
                            disabled={this._getIsSaveDisabled()}
                            onClick={this.onSave}
                        />
                        <Button type="close" onClick={this.props.closeSlidingPanel} />
                    </SlidingPanel>
                </form>
            </div>
        );
    }
}
export default injectIntl(BatchZapper_);
