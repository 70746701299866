import { adminData } from "~/admin/data";

export const PROPS_ACTIVE_YN = adminData.PROPS_IS_ACTIVE;
export const PROPS_UNIT_ID = adminData.PROPS_INTEGER_ID;
export const PROPS_UNIT_GUID = adminData.PROPS_ID;
export const PROPS_UNIT_NAME = adminData.PROPS_NAME;
export const PROPS_UNIT_TYPE = "type";
export const PROPS_UNIT_ABBREVIATION = "abbreviation";
export const PROPS_UNIT_CONVERSION_FACTOR = "conversionFactor";
export const PROPS_UNIT_SYSTEM = "systems";
export const PROPS_UNIT_FACTOR_UNIT = "factorUnit";
