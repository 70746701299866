import { combineReducers } from "redux";

import { API_ERROR_DATA_KEY, apiReducer } from "./api/reducer";
import { PICKLIST_DATA_KEY, picklistReducer } from "./picklist";
import { UNITDATA_DATA_KEY, unitsReducer } from "./units";

export const CORE_STATE_KEY = "CORE_STATE";

export const coreReducer = combineReducers({
    [API_ERROR_DATA_KEY]: apiReducer,
    [PICKLIST_DATA_KEY]: picklistReducer,
    [UNITDATA_DATA_KEY]: unitsReducer,
});
