import React, { Component, MouseEvent } from "react";
import classnames from "classnames";
import "./button.css";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";

export enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset",
    cancel = "cancel",
    ok = "ok",
    no = "no",
    close = "close",
}
export interface IButtonProps {
    className?: string;
    disabled?: boolean;
    forceSubmit?: boolean;
    onClick: (e: MouseEvent) => void;
    tabIndex?: number;
    type: ButtonTypes;
    value?: string;
    intl: intlShape;
    buttonProps?: Partial<HTMLButtonElement>;
}

export interface IButtonState {
    isDisabled?: boolean;
    hasFocus?: boolean;
}
export class Button_ extends Component<IButtonProps, IButtonState> {
    intl: intlShape;

    public static defaultProps = {
        disabled: false,
        tabIndex: 0,
        type: ButtonTypes.button,
        value: "",
    };
    ///----------------------------///
    constructor(props: IButtonProps) {
        super(props);
        this.state = {
            hasFocus: false,
        };
    }

    private _getValueText(): string {
        const { formatMessage } = this.props.intl;
        switch (this.props.type === ButtonTypes.button ? this.props.value : this.props.type) {
            case "accept":
                return formatMessage(messages.accept);
            case "back":
                return formatMessage(messages.backButtonText);
            case "cancel":
                return formatMessage(messages.cancelButtonText);
            case "close":
                return formatMessage(messages.closeButtonText);
            case "copy":
                return formatMessage(messages.copyButtonText);
            case "copyRole":
                return formatMessage(messages.copyRoleButtonText);
            case "createReports":
                return formatMessage(messages.createReportsText);
            case "delete":
                return formatMessage(messages.deleteButtonText);
            case "import":
                return formatMessage(messages.import);
            case "login":
                return formatMessage(messages.loginButtonText);
            case "migrate":
                return formatMessage(messages.migrateButtonText);
            case "next":
                return formatMessage(messages.nextButtonText);
            case "no":
                return formatMessage(messages.noButtonText);
            case "ok":
                return formatMessage(messages.okButtonText);
            case "resendVerification":
                return formatMessage(messages.resendVerificationButtonText);
            case "run":
                return formatMessage(messages.runButtonText);
            case "save":
                return formatMessage(messages.saveButtonText);
            case "selectFile":
                return formatMessage(messages.selectFile);
            case "send":
                return formatMessage(messages.sendButtonText);
            case "sendAuthentication":
                return formatMessage(messages.sendAuthenticationButtonText);
            case "sendVerification":
                return formatMessage(messages.sendVerificationButtonText);
            case "submit":
                return formatMessage(messages.doneButtonText);
            case "submitTextOnly":
                return formatMessage(messages.submitButtonText);
            case "test":
                return formatMessage(messages.test);
            case "upload":
                return formatMessage(messages.uploadButtonText);
            case "validate":
                return formatMessage(messages.validate);
            case "verify":
                return formatMessage(messages.verify);
            case "yes":
                return formatMessage(messages.yesButtonText);
            default:
                return this.props.value;
        }
    }

    private _hasCancelClass(): boolean {
        if (
            this.props.type === "cancel" ||
            this.props.type === "close" ||
            this.props.type === "no" ||
            this.props.type === "ok"
        ) {
            return true;
        }
        return false;
    }
    /// Event Handlers ///
    public handleOnFocus = (): void => {
        if (!this.state.isDisabled) {
            this.setState({ hasFocus: true });
        }
    };

    ///--------------------------///
    render(): JSX.Element {
        /// Property set up, etc. ///

        const { disabled } = this.props;

        const tabIndex = disabled ? -1 : this.props.tabIndex;

        const classNames = {
            "default-button": true,
            "cancel-button": this._hasCancelClass(),
            focus: this.state.hasFocus && !disabled,
            "disabled-button": disabled,
            [this.props.className]: this.props.className != null,
        };

        const buttonProps = {
            disabled,
            onClick: (e: MouseEvent): void => this.props.onClick(e),
            onFocus: this.handleOnFocus,
            tabIndex: tabIndex,
            type: (this.props.type === ButtonTypes.submit || this.props.forceSubmit
                ? ButtonTypes.submit
                : ButtonTypes.button) as "submit" | "reset" | "button",
        };
        return (
            <button className={classnames(classNames)} {...buttonProps}>
                {this._getValueText()}
            </button>
        );
    }
}
export const Button = injectIntl(Button_);
