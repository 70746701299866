import { createSelector } from "reselect";

import { CORE_STATE_KEY } from "~/core";
import { PicklistAPI } from "@ai360/core";

import { PICKLIST_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[CORE_STATE_KEY][PICKLIST_DATA_KEY];

export const getPicklistState = (state) => _getModuleState(state);

export const getPicklistFromCode = (state, picklistId) => _getModuleState(state)[picklistId];

export const getRawPicklistOptionsFromCode = createSelector(
    getPicklistFromCode,
    (picklistValues) => picklistValues || []
);

export const getPicklistOptionsFromCode = createSelector(getPicklistFromCode, (picklistValues) =>
    PicklistAPI.parsePicklistResp(picklistValues || [])
);
