import { IAgEventModelMethods } from "../model";

export class AgEventIrrigation implements IAgEventModelMethods {
    agEventGuid = null;
    doubleDripLines = false;
    duration = "";
    durationUnitGuid = "";
    estDeficitWaterNeed = "";
    estDeficitWaterNeedUnitGuid = "";
    estWaterNeed = "";
    estWaterNeedUnitGuid = "";
    frequency = "";
    frequencyUnitGuid = "";
    installationDepth = null;
    installationDepthUnitGuid = "";
    irrigationAmount = "";
    irrigationAmountUnitGuid = "";
    irrigationTypeGuid = "";
    rainfallDepthUnitGuid = "";
    rainfallSinceLast = "";
    reductionPercentForDeficit = "";
    subsurface = false;
    timeSinceLast = "";
    timeUnitGuid = "";
    waterSource = "";

    get isAllRequiredFieldsSet(): boolean {
        return this.irrigationTypeGuid && this.irrigationTypeGuid !== "";
    }
    get isAnyRequiredFieldSet(): boolean {
        return this.isAllRequiredFieldsSet;
    }
    updateAgEventModel(newProps: Partial<AgEventIrrigation>): AgEventIrrigation {
        return Object.assign(new AgEventIrrigation(), this, newProps);
    }
}
