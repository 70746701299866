export const PROPS_CONNECTION_GUID = "onsiteConnectionGuid";
export const PROPS_CONNECTION_NAME = "onsiteConnectionName";
export const PROPS_CONNECTION_TYPES = "connectionTypeList";
export const PROPS_ONSITE_CONNECTIONS_LIST = "onsiteConnectionsList";
export const PROPS_ONSITE_PARAM_LIST = "onsiteParamList";
export const PROPS_ONSITE_PASSWORD = "onsitePassword";
export const PROPS_SYNC_FILES_FROM_DATE = "syncFilesFromDate";
export const PROPS_USER_CONNECTION_NAME = "userConnectionName";
export const PROPS_USER_CONNECTION_GUID = "userOnsiteConnectionGuid";
export const PROPS_AUTO_EXCHANGE = "autoExchange";

export const onsiteEditMode = {
    ADD: "add",
    DELETE: "delete",
    UPDATE: "update",
};

// Data Exchange Connection Names
export const JOHN_DEERE = "MyJohnDeere";
export const CLIMATE = "Climate FieldView";
export const RAVEN = "Raven Slingshot";
export const FARMOBILE = "Farmobile";
