import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { Menu } from "~/core";
import { messages } from "../i18n-messages";

interface IStandardLayerContextMenuProps {
    intl: intlShape;
    onLayerPreferences: () => void;
}

class StandardLayerContextMenu_ extends Component<IStandardLayerContextMenuProps> {
    render() {
        const { formatMessage } = this.props.intl;

        const menuItems = [
            {
                key: 0,
                label: formatMessage(messages.layerPreferences),
                action: () => this.props.onLayerPreferences(),
            },
        ];

        return (
            <div className="context-menu-container" onClick={(evt) => evt.stopPropagation()}>
                <Menu className="context-menu" isDotMenu={true} menuItems={menuItems} />
            </div>
        );
    }
}

export const StandardLayerContextMenu = injectIntl(StandardLayerContextMenu_);
