import PropTypes from "prop-types";
import * as model from "~/admin/agBytes/gene/data/model";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_GENE_ID]: PropTypes.number,
    [model.PROPS_DEVELOPER_NAMES]: PropTypes.array,
    [model.PROPS_GENE_GUID]: PropTypes.string,
    [model.PROPS_GENE_NAME]: PropTypes.string,
    [model.PROPS_TRAIT_CHARACTERISTIC_NAMES]: PropTypes.array,
    [model.PROPS_TRAIT_SPECIFIC_NAMES]: PropTypes.array,
};

export default PropTypes.shape(shape);
