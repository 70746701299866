import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { EventList } from "./event-list/components/event-list";
import { EventInfo } from "./event-info/components/event-info";

import * as actions from "../actions";
import * as selectors from "../selectors";

import "./event-module.css";

export class EventModule_ extends Component {
    static propTypes = {
        activePage: PropTypes.string.isRequired,
    };

    _getCurrentPage() {
        const { activePage } = this.props;

        if (activePage === actions.EventModulePages.EVENT_LIST) {
            return <EventList />;
        }
        console.assert(activePage === actions.EventModulePages.EVENT_INFORMATION);
        return <EventInfo />;
    }

    render() {
        return <div className="event-module">{this._getCurrentPage()}</div>;
    }
}

const mapStateToProps = (state) => {
    const { activePage } = selectors.getModuleState(state);
    return { activePage };
};

export const EventModule = connect(mapStateToProps, null)(EventModule_);
