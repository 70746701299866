import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, service, dropdowns, unitTypes } from "./../data";

export default AddEditPanelContainer(AddEditPanel, {
    pickLists,
    service,
    dropdowns,
    unitTypes,
});
