import React, { MouseEvent as ReactMouseEvent } from "react";
import { IMultiActionList } from "./dialog-box";
import { Button } from "../button/button";

export enum DialogBoxFooterType {
    NONE,
    ACTION_CANCEL,
    YES_NO,
    MULTI_ACTION_CANCEL,
    IMPORT_CANCEL,
    ACTION_CLOSE,
    MULTI_ACTION,
}

type DialogBoxProps = {
    action?: string;
    className?: string;
    actionDisabled?: boolean;
    multiActionList?: IMultiActionList[];
    onClose: (e: MouseEvent) => void;
    onAction?: (e: MouseEvent | ReactMouseEvent) => void;
    footerType?: DialogBoxFooterType;
};

const DialogBoxFooter = ({
    action,
    actionDisabled,
    multiActionList = [],
    onClose,
    onAction = () => null,
    footerType,
}: DialogBoxProps): JSX.Element => {
    const buttons: JSX.Element[] = [];
    switch (footerType) {
        case DialogBoxFooterType.MULTI_ACTION:
            multiActionList.forEach((actionObj) => {
                if (actionObj.action && actionObj.action.length) {
                    buttons.push(
                        <Button
                            key={actionObj.action}
                            disabled={actionObj.actionDisabled}
                            className={actionObj.isCancelStyle ? "cancel-button" : ""}
                            value={actionObj.action}
                            onClick={() => actionObj.onAction()}
                        />
                    );
                } else {
                    buttons.push(
                        <Button
                            key="save"
                            disabled={actionObj.actionDisabled}
                            className={actionObj.isCancelStyle ? "cancel-button" : ""}
                            onClick={() => actionObj.onAction()}
                        />
                    );
                }
            });
            break;
        case DialogBoxFooterType.MULTI_ACTION_CANCEL:
            multiActionList.forEach((actionObj) => {
                if (actionObj.action && actionObj.action.length) {
                    buttons.push(
                        <Button
                            key={actionObj.action}
                            disabled={actionObj.actionDisabled}
                            className={actionObj.isCancelStyle ? "cancel-button" : ""}
                            value={actionObj.action}
                            onClick={() => actionObj.onAction()}
                        />
                    );
                } else {
                    buttons.push(
                        <Button
                            key="save"
                            disabled={actionObj.actionDisabled}
                            className={actionObj.isCancelStyle ? "cancel-button" : ""}
                            onClick={() => actionObj.onAction()}
                        />
                    );
                }
            });
            buttons.push(
                <Button key="cancel" type="cancel" onClick={(e: MouseEvent) => onClose(e)} />
            );
            break;
        case DialogBoxFooterType.ACTION_CANCEL:
            if (action && action.length) {
                buttons.push(
                    <Button
                        key={action}
                        disabled={actionDisabled}
                        value={action}
                        onClick={(e: MouseEvent) => onAction(e)}
                    />
                );
            } else {
                buttons.push(
                    <Button
                        key="save"
                        disabled={actionDisabled}
                        type="save"
                        onClick={(e: MouseEvent) => onAction(e)}
                    />
                );
            }
            buttons.push(
                <Button key="cancel" type="cancel" onClick={(e: MouseEvent) => onClose(e)} />
            );
            break;
        case DialogBoxFooterType.YES_NO: {
            buttons.push(
                <Button
                    key="yes"
                    disabled={actionDisabled}
                    type="yes"
                    onClick={(e: MouseEvent) => onAction(e)}
                />
            );
            buttons.push(<Button key="no" type="no" onClick={(e: MouseEvent) => onClose(e)} />);
            break;
        }
        case DialogBoxFooterType.IMPORT_CANCEL: {
            buttons.push(
                <Button
                    key="yes"
                    disabled={actionDisabled}
                    type="import"
                    onClick={(e: MouseEvent) => onAction(e)}
                />
            );
            buttons.push(<Button key="no" type="cancel" onClick={(e: MouseEvent) => onClose(e)} />);
            break;
        }
        case DialogBoxFooterType.ACTION_CLOSE:
            if (action && action.length) {
                buttons.push(
                    <Button
                        key={action}
                        disabled={actionDisabled}
                        value={action}
                        onClick={(e: MouseEvent) => onAction(e)}
                    />
                );
            } else {
                buttons.push(
                    <Button
                        key="save"
                        disabled={actionDisabled}
                        type="save"
                        onClick={(e: MouseEvent) => onAction(e)}
                    />
                );
            }
            buttons.push(
                <Button key="close" type="close" onClick={(e: MouseEvent) => onClose(e)} />
            );
            break;
        default:
            return <></>;
    }
    return <div className="dialog-box-footer">{buttons}</div>;
};

export default DialogBoxFooter;
