import React, { ReactElement } from "react";

import { Toolbar, ToolbarMenu, ToolbarButton } from "~/core";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";
import { ExtentTools } from "@ai360/core";

export class ExtentToolSet extends ExtentTools {
    getToolbar(): ReactElement {
        const { formatMessage } = this.options;
        const messages = this.options.messages as any;
        return (
            <ToolbarMenu
                className="extent"
                label={formatMessage(messages.extentLbl)}
                icon={ToolbarIcon(IconType.EXTENT)}
            >
                <Toolbar className="extent-tools">
                    <ToolbarButton
                        className="previous-extent"
                        disabled={!this.history.canUndo()}
                        icon={ToolbarIcon(IconType.PREVIOUS)}
                        label={formatMessage(messages.extentPreviousLbl)}
                        onClick={() => this.history.undo()}
                    />
                    <ToolbarButton
                        className="next-extent"
                        disabled={!this.history.canRedo()}
                        icon={ToolbarIcon(IconType.NEXT)}
                        label={formatMessage(messages.extentNextLbl)}
                        onClick={() => this.history.redo()}
                    />
                    <ToolbarButton
                        className="full-extent"
                        icon={ToolbarIcon(IconType.FULL_EXTENT)}
                        label={formatMessage(messages.extentFullLbl)}
                        onClick={() => this.full()}
                    />
                </Toolbar>
            </ToolbarMenu>
        );
    }
}
