import { AgBytesItem, IObservationData } from "./models";
import { ScoutingTemplate } from "~/recs-events/events/model";
import { AgEventAPI } from "@ai360/core";
import { action } from "typesafe-actions";

export const FETCH_APPLY_TEMPLATE = "event-info/scouting/FETCH_APPLY_TEMPLATE";
export const FETCH_GROWTH_STAGE_DATA = "event-info/FETCH_GROWTH_STAGE_DATA";
export const FETCH_GROWTH_STAGE_DATA_SUCCEEDED = "event-info/FETCH_GROWTH_STAGE_DATA_SUCCEEDED";
export const FETCH_OBSERVATION_DATA = "event-info/FETCH_OBSERVATION_DATA";
export const FETCH_OBSERVATION_DATA_SUCCEEDED = "event-info/FETCH_OBSERVATION_DATA_SUCCEEDED";
export const FETCH_GROWTH_STAGE_OBSERVATION_DATA = "event-info/FETCH_GROWTH_STAGE_OBSERVATION_DATA";
export const FETCH_GROWTH_STAGE_OBSERVATION_DATA_SUCCEEDED =
    "event-info/FETCH_GROWTH_STAGE_OBSERVATION_DATA_SUCCEEDED";
export const FETCH_SCOUTING_DEFAULTS = "event-info/scouting/FETCH_SCOUTING_DEFAULTS";
export const FETCH_SCOUTING_FORM_PICKLISTS = "event-info/scouting/FETCH_SCOUTING_FORM_PICKLISTS";
export const FETCH_SCOUTING_TEMPLATE = "event-info/scouting/FETCH_SCOUTING_TEMPLATE";
export const FETCH_SCOUTING_TEMPLATE_SUCCESS =
    "event-info/scouting/FETCH_SCOUTING_TEMPLATE_SUCCESS";
export const INTEGRATE_CROP = "event-info/scouting/INTEGRATE_CROP";
export const INTEGRATE_CROP_INFO = "event-info/scouting/INTEGRATE_CROP_INFO";
export const INTEGRATE_OBSERVATION_INFO = "event-info/scouting/INTEGRATE_OBSERVATION_INFO";
export const RESET_SCOUTING_TEMPLATE = "event-info/scouting/RESET_SCOUTING_TEMPLATE";
export const SAVE_SCOUTING_TEMPLATE = "event-info/scouting/SAVE_SCOUTING_TEMPLATE";
export const SET_ORIGINAL_SCOUTING_DETAIL_LIST =
    "event-info/scouting/SET_ORIGINAL_SCOUTING_DETAIL_LIST";
export const SET_SCOUTING_DATA_IS_LOADING = "event-info/scouting/SET_SCOUTING_DATA_IS_LOADING";
export const SET_SCOUTING_TEMPLATE_IS_LOADING =
    "event-info/scouting/SET_SCOUTING_TEMPLATE_IS_LOADING";
export const UPDATE_SCOUTING_TEMPLATE = "event-info/scouting/UPDATE_SCOUTING_TEMPLATE";

export const fetchScoutingEventDefaults = () => action(FETCH_SCOUTING_DEFAULTS, null);

export const fetchScoutingPicklists = () => action(FETCH_SCOUTING_FORM_PICKLISTS, null);

export const fetchObservationData = () => action(FETCH_OBSERVATION_DATA, null);

export const fetchObservationDataSucceeded = (observationData: IObservationData[]) =>
    action(FETCH_OBSERVATION_DATA_SUCCEEDED, { observationData });

export const fetchGrowthStageObservationData = (payload: Record<string, any>) =>
    action(FETCH_GROWTH_STAGE_OBSERVATION_DATA, payload);

export const fetchGrowthStageObservationDataSucceeded = (payload: Record<string, any>) =>
    action(FETCH_GROWTH_STAGE_OBSERVATION_DATA_SUCCEEDED, payload);

export const fetchGrowthStageData = (cropGuid: string) =>
    action(FETCH_GROWTH_STAGE_DATA, { cropGuid });

export const fetchGrowthStageDataSucceeded = (growthStageData: AgBytesItem[]) =>
    action(FETCH_GROWTH_STAGE_DATA_SUCCEEDED, { growthStageData });

export const fetchScoutingTemplate = (cropGuid: string, cropPurposeGuid: string) =>
    action(FETCH_SCOUTING_TEMPLATE, { cropGuid, cropPurposeGuid });

export const fetchApplyTemplate = (
    cropGuid: string,
    cropPurposeGuid: string,
    currentZoneOnly: boolean,
    overwriteValues: boolean
) => action(FETCH_APPLY_TEMPLATE, { cropGuid, cropPurposeGuid, currentZoneOnly, overwriteValues });

export const fetchScoutingTemplateSuccess = (scoutingTemplateJsonObj: ScoutingTemplate) =>
    action(FETCH_SCOUTING_TEMPLATE_SUCCESS, { scoutingTemplateJsonObj });

export const integrateCrop = (cropGuid: string, cropPurposeGuid: string) =>
    action(INTEGRATE_CROP, { cropGuid, cropPurposeGuid });

export const integrateCropInfo = (
    scoutingTemplate: ScoutingTemplate,
    currentZoneOnly: boolean,
    templateOverwrite: boolean
) => action(INTEGRATE_CROP_INFO, { scoutingTemplate, currentZoneOnly, templateOverwrite });

export const integrateObservationInfo = (
    scoutingTemplate: ScoutingTemplate,
    currentZoneOnly: boolean,
    templateSave: boolean
) => action(INTEGRATE_OBSERVATION_INFO, { scoutingTemplate, currentZoneOnly, templateSave });

export const resetScoutingTemplate = () => action(RESET_SCOUTING_TEMPLATE, null);

export const setOriginalScoutingDetailList = (
    agEventGuid: string,
    scoutingDetailList: Array<AgEventAPI.IScoutingDetail>
) => action(SET_ORIGINAL_SCOUTING_DETAIL_LIST, { agEventGuid, scoutingDetailList });

export const saveScoutingTemplate = () => action(SAVE_SCOUTING_TEMPLATE, null);

export const setScoutingDataLoading = <TSetScoutingDataLoading>(
    isLoading: TSetScoutingDataLoading
) => action(SET_SCOUTING_DATA_IS_LOADING, { isLoading });

export const setScoutingTemplateIsLoading = (isLoading: boolean) =>
    action(SET_SCOUTING_TEMPLATE_IS_LOADING, { isLoading });

export const updateScoutingTemplate = (newProps: Partial<ScoutingTemplate>) =>
    action(UPDATE_SCOUTING_TEMPLATE, { newProps });
