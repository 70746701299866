import React from "react";
import { connect } from "react-redux";
import { InjectedIntl, injectIntl } from "react-intl";

import { DialogBox, Loader } from "~/core";

import { getErrorMessages } from "~/i18n-error-messages";
import { FileImportAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { messages } from "./i18n-messages";

import "./error-details-dialog.css";

export const ErrorDetailsContextMenuLabel = messages.contextMenuLabel;

interface IErrorDetailsDialogProps {
    onClose: () => void;
    userGuid: string;
    correlationId: string;
    logType: string;
    onApiCallErr: (err) => void;
    intl: InjectedIntl;
}

interface IErrorContextDetails {
    context: string;
    errorCode: number;
    message: string;
}

interface IErrorDetails {
    timestamp: Date;
    correlationId: string;
    correlationSource: string;
    logType: string;
    errorContextList: IErrorContextDetails[];
}

interface IErrorDetailsDialogState {
    errorDetails: IErrorDetails;
}

class ErrorDetailsDialog_ extends React.Component<
    IErrorDetailsDialogProps,
    IErrorDetailsDialogState
> {
    constructor(props: Readonly<IErrorDetailsDialogProps>) {
        super(props);
        this.state = {
            errorDetails: null,
        };
        this._fetchErrorDetails();
    }

    _fetchErrorDetails() {
        const { correlationId, onApiCallErr, userGuid, logType } = this.props;
        const { formatMessage } = this.props.intl;
        FileImportAPI.getErrorDetails(userGuid, logType, correlationId)
            .then((result: IErrorDetails) => {
                const errorDetails = result;
                errorDetails.errorContextList = errorDetails.errorContextList.map((context) => {
                    if (!context.message || context.message === "") {
                        if (context.errorCode) {
                            context.message = getErrorMessages(
                                formatMessage,
                                this._translateErrorCodeToErrObject([context.errorCode])
                            );
                        } else {
                            context.message = getErrorMessages(
                                formatMessage,
                                this._translateErrorCodeToErrObject([])
                            );
                        }
                    }
                    return context;
                });
                this.setState({ errorDetails });
            })
            .catch((err) => onApiCallErr(err));
    }

    _translateErrorCodeToErrObject(errorCodeList: number[]) {
        return {
            errorCodeList: errorCodeList,
            apiResultObj: null,
        };
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <DialogBox
                draggable={true}
                isOpen
                closeOnClickOff
                closeOnEscape
                unrestricted
                title={formatMessage(messages.errorDetailsTitle)}
                onClose={this.props.onClose}
                className="err-dialog"
            >
                {this.state.errorDetails ? this.renderError() : <Loader />}
            </DialogBox>
        );
    }

    renderError() {
        const { formatDate, formatMessage, formatTime } = this.props.intl;
        const { errorDetails } = this.state;

        return [
            <div key="err-message" className="message">
                {errorDetails.errorContextList.length > 1
                    ? this.renderMultiContext()
                    : this.renderSingleContext()}
            </div>,
            <div key="err-metadata" className="metadata">
                {formatMessage(messages.errorDetailsMetadata, {
                    timestamp: `${formatDate(errorDetails.timestamp)} ${formatTime(
                        errorDetails.timestamp
                    )}`,
                    correlationId: errorDetails.correlationId,
                })}
            </div>,
        ];
    }

    renderMultiContext() {
        const { formatMessage } = this.props.intl;
        const { errorDetails } = this.state;
        return [
            <span key="err-source">
                {errorDetails.correlationSource
                    ? formatMessage(messages.errorDetailsSource, errorDetails)
                    : null}
            </span>,
            <ul key="err-context">
                {errorDetails.errorContextList.map((context, idx) => {
                    return <li key={`context-${idx}`}>{this.renderContext(context)}</li>;
                })}
            </ul>,
        ];
    }

    renderSingleContext() {
        const { formatMessage } = this.props.intl;
        const { errorDetails } = this.state;

        let errorText = errorDetails.correlationSource
            ? formatMessage(messages.errorDetailsSource, errorDetails)
            : "";

        if (errorDetails.errorContextList?.length > 0) {
            errorText += this.renderContext(errorDetails.errorContextList[0]);
        }

        return <span>{errorText}</span>;
    }

    renderContext(context: IErrorContextDetails) {
        let rowText = "";
        if (context.context) {
            rowText += `${context.context}: `;
        }
        rowText += context.message;

        if (context.errorCode) {
            rowText += ` [${context.errorCode}]`;
        }

        return rowText;
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiCallErr: (error) => dispatch(notificationActions.apiCallError(error)),
});

const mapStateToProps = (state) => {
    return {
        userGuid: getTheUserGuid(state),
    };
};

export const ErrorDetailsDialog = connect<
    Partial<IErrorDetailsDialogProps>,
    Partial<IErrorDetailsDialogProps>,
    Partial<IErrorDetailsDialogProps>
>(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ErrorDetailsDialog_));
