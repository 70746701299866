import { defineMessages } from "react-intl";

export const messages = defineMessages({
    cancelledLayerViewCreate: {
        id: "cancelled:layerview-create",
        defaultMessage: "layerview creation cancelled",
    },
    duplicateBoundary: {
        id: "map.duplicateBoundary",
        defaultMessage: "Duplicate Boundary",
    },
    duplicateFieldName: {
        id: "map.duplicateFieldName",
        defaultMessage: "Duplicate Field Name",
    },
    errorGettingStandardLayerPreferences: {
        id: "map.errorGettingStandardLayerPreferences",
        defaultMessage: "Error getting saved user preferences for the standard layers.",
    },
    errorSavingStandardLayerPreferences: {
        id: "map.errorSavingStandardLayerPreferences",
        defaultMessage: "Error saving user preferences for the standard layers.",
    },
    failedToLoadSampleSites: {
        id: "map.failedToLoadSampleSites",
        defaultMessage: "Failed to load Sample Sites.",
    },
    failedToLoadSurface: {
        id: "map.failedToLoadSurface",
        defaultMessage: "Failed to load Surface.",
    },
    freehandPolyTooltip: {
        id: "map.freehandPolyTooltip",
        defaultMessage: "Press down to start and let go to finish.",
    },
    recoverWebGLContext: {
        id: "map.recoverWebGLContext",
        defaultMessage: "MapView has lost its WebGL context. Attempting to recover.",
    },
});
