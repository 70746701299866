import { ADMIN_STATE_KEY } from "~/admin";
import { SETUP_REPORT_BOOKS_DATA_KEY } from "~/admin/setup/report-books/data/reducer";

const _getModuleState = (state: unknown): any =>
    state[ADMIN_STATE_KEY][SETUP_REPORT_BOOKS_DATA_KEY];

export const getFilteredLocationList = (state: unknown): any =>
    _getModuleState(state).filteredLocationList;
export const getLoadingModal = (state: unknown): any => _getModuleState(state).loadingModal;
export const getLoadingOrgLevelGuids = (state: unknown): any =>
    _getModuleState(state).loadingOrgLevelGuids;
export const getLoadingReportBookReports = (state: unknown): any =>
    _getModuleState(state).loadingReportBookReports;
export const getLoadingReportBooks = (state: unknown): any =>
    _getModuleState(state).loadingReportBooks;
export const getMergeStatus = (state: unknown): any => _getModuleState(state).mergeStatus;
export const getReportBookNames = (state: unknown): any => _getModuleState(state).reportBookNames;
export const getReportBookOrgLevelGuids = (state: unknown): any =>
    _getModuleState(state).reportBookOrgLevelGuids;
export const getReportBookReports = (state: unknown): any =>
    _getModuleState(state).reportBookReports;
export const getReportBooks = (state: unknown): any => _getModuleState(state).reportBooks;
export const getReportBookOptionErrors = (state: unknown): any =>
    _getModuleState(state).reportBookOptionErrors;
export const getReportOptions = (state: unknown): any => _getModuleState(state).reportOptions;
export const getReportTypes = (state: unknown): any => _getModuleState(state).reportTypes;
export const getSelectedReportBookTypeGuid = (state: unknown): any =>
    _getModuleState(state).selectedReportBookTypeGuid;
export const getSelectedReportOptionGuid = (state: unknown): any =>
    _getModuleState(state).selectedReportOptionGuid;
export const getSoilTypeAttributes = (state: unknown): any =>
    _getModuleState(state).soilTypeAttributes;
