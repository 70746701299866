import { defineMessages } from "react-intl";

export const messages = defineMessages({
    clear: {
        id: "actionPanel.recEventCommon.clear",
        defaultMessage: "Clear",
    },
    clearZoneData: {
        id: "actionPanel.recEventCommon.clearZoneData",
        defaultMessage: "Clear Zone Data",
    },
    create: {
        id: "actionPanel.recEventCommon.create",
        defaultMessage: "Create",
    },
    clearZoneDataDlgMsg: {
        id: "actionPanel.recEventCommon.clearZoneDataDlgMsg",
        defaultMessage: "Are you sure you want to clear all the data for this field zone?",
    },
    editTemplate: {
        id: "actionPanel.recEventCommon.editTemplate",
        defaultMessage: "Edit Template",
    },
    recommendation: {
        id: "actionPanel.recEventCommon.recommendation",
        defaultMessage: "Recommendation",
    },
    filterTabLbl: {
        id: "actionPanel.recEventCommon.filterTabLbl",
        defaultMessage: "Filters",
    },
    generalTabLbl: {
        id: "actionPanel.recEventCommon.generalTabLbl",
        defaultMessage: "General",
    },
    mergeEvents: {
        id: "recEventInfo.mergeBtnText",
        defaultMessage: "Merge",
    },
    noZonesSelected: {
        id: "recEventInfo.zoneInfo.noZonesSelected",
        defaultMessage: "There are no zones selected",
    },
    nullZoneSelected: {
        id: "recEventInfo.zoneInfo.nullZoneSelected",
        defaultMessage: "The selected zone contains no data",
    },
    runBtnText: {
        id: "recEventInfo.runBtnText",
        defaultMessage: "Run",
    },
    update: {
        id: "actionPanel.recEventCommon.update",
        defaultMessage: "Update",
    },
    zoneCopyApplyBtnTxt: {
        id: "recEventInfo.zoneInfo.zoneCopyApplyBtnTxt",
        defaultMessage: "Apply",
    },
    zoneCopyDialogMsg: {
        id: "recEventInfo.zoneInfo.zoneCopyDialogMsg",
        defaultMessage: "On the map, click the zones that you would like to copy these values to.",
    },
    zoneCopyDialogTitle: {
        id: "recEventInfo.zoneInfo.zoneCopyDialogTitle",
        defaultMessage: "Copy Values",
    },
    zoneCopySelectAllZonesLbl: {
        id: "recEventInfo.zoneInfo.zoneCopySelectAllZonesLbl",
        defaultMessage: "Select All Zones",
    },
    zoneCopyText: {
        id: "recEventInfo.zoneInfo.zoneCopyText",
        defaultMessage: "Copy",
    },
    zoneClearText: {
        id: "recEventInfo.zoneInfo.zoneClearText",
        defaultMessage: "Clear",
    },
    zoneName: {
        id: "recEventInfo.zoneInfo.zoneName",
        defaultMessage: "Zone {areaId, number}:",
    },
    zoneNameEntireField: {
        id: "recEventInfo.zoneInfo.zoneNameEntireField",
        defaultMessage: "Entire Field:",
    },
    zoneScoutingName: {
        id: "recEventInfo.zoneInfo.zoneScoutingName",
        defaultMessage: "Site {areaId, number}:",
    },
    zoneSize: {
        id: "recEventInfo.zoneInfo.zoneSize",
        defaultMessage: "{calculatedArea, number} Acres",
    },
    zoneSizeScoutingPoint: {
        id: "recEventInfo.zoneInfo.zoneSizeScoutingPoint",
        defaultMessage: "Point",
    },
});
