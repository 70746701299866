import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { plantingModule } from "../rec-manual-planting";

import { defineMessages, injectIntl, intlShape } from "react-intl";
import { NoLink } from "~/core";
import { recsModels, actions as recsEventsActions, recsSelectors } from "~/recs-events";
import * as selectors from "../../selectors";
import "../../../../../common/rec-event-info/rec-event-info.css";
import "../rec-equation-application/rec-equation-application-form.css";

const messages = defineMessages({
    changeText: {
        id: "recModule.recInfo.plantingEquationRecSummary.changeText",
        defaultMessage: "Change",
    },
});

interface IRecEquationPlantingSummaryProps {
    batchRecSummariesForEdit: any[];
    currentArea: any;
    equationGroupList: any[];
    fieldGuid: string;
    isBatchTemplate: boolean;
    recDetails: any;
    onUpdateRecDetails: (newProps) => void;
    intl: intlShape;
}

export class RecEquationPlantingSummary_ extends PureComponent<
    IRecEquationPlantingSummaryProps,
    null
> {
    static defaultProps = {
        isBatchTemplate: false,
    };

    _clearEquationGroupGuid = () => {
        const { recDetails, onUpdateRecDetails } = this.props;

        const recAreaList = recDetails.recAreaList.map((recArea) => {
            return recsModels.RecArea.updateRecArea(recArea, {
                applyRecToArea: false,
                recs: recArea.recs.map((rec) => {
                    const equationProps = new recsModels.RecEquationFilter();
                    const recProps = new recsModels.Rec();
                    return rec.updateRecModel({
                        ...recProps,
                        recGuid: rec.recGuid,
                        recGeneralGuid: rec.recGeneralGuid,
                        equationGroupGuid: null,
                        equationFilters: {
                            ...equationProps,
                            recGuid: rec.equationFilters.recGuid,
                            recEquationFilterGuid: rec.equationFilters.recEquationFilterGuid,
                            activeEquationGroupGuid: null,
                        },
                        isIncluded: false,
                    });
                }),
            });
        });
        onUpdateRecDetails({ recAreaList });
    };

    render() {
        const { fieldGuid, currentArea, recDetails, equationGroupList } = this.props;
        const { formatMessage } = this.props.intl;
        const firstActiveRec = recDetails.recAreaList[0].recs[0];
        const activeEquationGroup = equationGroupList.find(
            (equationGroup) => equationGroup.equationGroupGuid === firstActiveRec.equationGroupGuid
        );
        const hasBeenRun = Boolean(recDetails.recGeneralGuid);

        return !activeEquationGroup ? null : (
            <div className="rec-equation-application-summary">
                {!hasBeenRun ? null : (
                    <plantingModule.RecManualPlantingSummary
                        fieldGuid={fieldGuid}
                        currentArea={currentArea}
                        isEquation={true}
                    />
                )}
                <div className="change-equation-group">
                    {hasBeenRun ? null : (
                        <NoLink
                            className="change-equation-group-nolink"
                            label={formatMessage(messages.changeText)}
                            onClick={() => this._clearEquationGroupGuid()}
                        />
                    )}
                    <div className="active-equation-group-name">
                        {activeEquationGroup.groupName}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { recSummary } = selectors.getModuleState(state);
    const { fieldGuid } = recSummary;
    const { fieldGuidToRecDetails } = recsSelectors.getModuleState(state);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    return {
        equationGroupList: selectors.getEquationGroupList(state),
        fieldGuid,
        recDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onUpdateRecDetails: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateRecDetails(fieldGuid, newProps)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateRecDetails: (newProps) =>
        dispatchProps.onUpdateRecDetails(stateProps.fieldGuid, newProps),
});

export const RecEquationPlantingSummary = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecEquationPlantingSummary_));
