import { connect } from "react-redux";
import { FileMetadata as FileMetadataView } from "./file-metadata";
import { constants, actions, selectors } from "../../data";
import { getDropdown } from "~/admin/selectors";
import { fetchDropdownData } from "~/core/dropdowns/actions";

const mapStateToProps = (state) => {
    return {
        fileMetadata: selectors.getMergedFileMetaData(state),
        importSampleTemplates: getDropdown(constants.IMPORT_SAMPLE_TEMPLATES, state),
        importTypes: getDropdown(constants.IMPORT_TYPES, state),
        vendorData: getDropdown(constants.VENDOR_DATA, state),
        store: selectors.getCurrentTabTemplate(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    resetImportTemplateListData: (payload) =>
        dispatch(actions.resetImportTemplateListData(payload)),
    resetTemplate: (payload) => dispatch(actions.resetTemplate(payload)),
    setFileMetadata: (payload) => dispatch(actions.setFileMetadata(payload)),
    setIsMonitorFileFlag: (payload) => dispatch(actions.setIsMonitorFileFlag(payload)),
    updateFileType: (payload) => dispatch(actions.updateFileType(payload)),
    updateImportTemplateList: (payload) => dispatch(actions.updateImportTemplateList(payload)),
    updateTemplate: (payload) => dispatch(actions.updateTemplate(payload)),
    // Note: Actions requires needs hoc for the dispatch
    fetchDropdownData: (payload) => fetchDropdownData(payload),
    verifyTemplateFile: (payload) => actions.verifyTemplateFile(payload),
});

export const FileMetadata = connect(mapStateToProps, mapDispatchToProps)(FileMetadataView);
