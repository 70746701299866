export const keywords = {
    LIQUID_STATE: "1",
    DRY_STATE: "2",
    AC: "ac",
    EVENT_PLANTING_VARIETY_HYBRID_LIST: "eventPlantingVarietyHybridList",
    LB: "lb",
    REC_PLANTING_VARIETY_HYBRID_LIST: "recPlantingVarietyHybridList",
    UNIT: "unit",
    DRY_PRICE_UNIT: "229",
    LIQUID_PRICE_UNIT: "235",
    cropAge: "Crop Age",
    ksdsPerAc: "ksds/ac",
    name: "Name",
    plantSpacing: "Plant Spacing",
    planting: "Planting",
    plantingDepth: "Planting Depth",
    poundPerAc: "lb/ac",
    kilogramPerAc: "kg/ac",
    metrictonPerAc: "mt/ac",
    rowSpacing: "Row Spacing",
    seedPerAc: "seed/ac",
    seedingRate: "Seeding Rate",
    transplantRate: "Transplant Rate",
};
