import { select } from "redux-saga/effects";
import _ from "lodash";
import { AppHelpers, LocalStorageHelpers } from "@ai360/core";
// Selectors
import { getTheUserGuid, getTheUserCompanyGuid } from "~/login";

export const BOOLEAN_CONSTANTS_ARRAY = [
    "CANDELETE",
    "ISACTIVE",
    "LOGIC",
    "GMO",
    "GENETICALLYMODIFIED",
];

export const getModelNameToState = (modelName, mode = "ADD") => {
    if (mode === "EDIT") {
        return `${modelName}EditData`;
    }
    return `${modelName}Data`;
};

export const createDefaultFilter = (payload, defaultRequestFilters) => (modelType) => {
    if (
        payload &&
        payload[modelType] &&
        // Check if passed filter is an array and empty.
        (!Array.isArray(payload[modelType]) || AppHelpers.isEmptyArray(payload, modelType))
    ) {
        return payload[modelType];
    } else {
        return defaultRequestFilters[modelType];
    }
};

// Maps first possible value for the searchedString
export function getValueForBooleanColumns(searchedString = "") {
    switch (searchedString.toUpperCase()) {
        case "YES":
            return true.toString();
        case "NO":
            return false.toString();
        default:
            return "";
    }
}

function prepBoolRequestOptions(requestOptions = {}, filterKey = "") {
    if (requestOptions && filterKey) {
        let filterOptions = requestOptions[filterKey];
        for (let key in filterOptions) {
            if (BOOLEAN_CONSTANTS_ARRAY.includes(key.toUpperCase())) {
                filterOptions[key] = getValueForBooleanColumns(filterOptions[key]);
            }
        }
        requestOptions[filterKey] = filterOptions;
    }
    return requestOptions;
}
export function addActiveOnlyWithRequestFilters(defaultRequestFilters, requestOptions) {
    if (requestOptions && "activeOnly" in requestOptions) {
        const activeOnly = requestOptions.activeOnly;
        return { ...defaultRequestFilters, activeOnly };
    }
    return defaultRequestFilters;
}
// Check and add appropriate guid to the key inside the defaultRequestFilters
export function* addAppropriateGuidWithRequestFilters(defaultRequestFilters) {
    if ("userGuid" in defaultRequestFilters) {
        const userGuid = yield select(getTheUserGuid);
        return { ...defaultRequestFilters, userGuid };
    }
    if ("companyGuid" in defaultRequestFilters) {
        const companyGuid = yield select(getTheUserCompanyGuid);
        return { ...defaultRequestFilters, companyGuid };
    }
    return defaultRequestFilters;
}

// Check and add appropriate guid to the key inside any given object
export function* addAppropriateGuidWithPayload(payload, defaultRequestFilters) {
    if ("userGuid" in defaultRequestFilters) {
        const userGuid = yield select(getTheUserGuid);
        return { ...payload, ...{ userGuid } };
    }
    if ("companyGuid" in defaultRequestFilters) {
        const companyGuid = yield select(getTheUserCompanyGuid);
        return { ...payload, ...{ companyGuid } };
    }
    if ("fieldGuidList" in defaultRequestFilters) {
        return { ...defaultRequestFilters, ...payload };
    }
    return payload;
}

export function* getRequestOptions(
    requestOptions,
    { defaultRequestFilters, REQUEST_PAYLOAD_FILTER, REQUEST_PAYLOAD_PAGE_OPTIONS, isModalWindow },
    useNewApi = false
) {
    const UserGuid = yield select(getTheUserGuid);
    defaultRequestFilters = yield addAppropriateGuidWithRequestFilters(defaultRequestFilters);
    defaultRequestFilters = yield addActiveOnlyWithRequestFilters(
        defaultRequestFilters,
        requestOptions
    );
    requestOptions = yield prepBoolRequestOptions(requestOptions, REQUEST_PAYLOAD_FILTER);
    const getFilter = createDefaultFilter(requestOptions, defaultRequestFilters);
    const result = _.reduce(
        Object.keys(defaultRequestFilters),
        (result, filterKey) => ({
            ...result,
            [filterKey]: getFilter(filterKey),
        }),
        {}
    );
    result[REQUEST_PAYLOAD_PAGE_OPTIONS].pageSize =
        LocalStorageHelpers.getStoredPageSize(isModalWindow);
    result[REQUEST_PAYLOAD_PAGE_OPTIONS].pageNumber =
        result[REQUEST_PAYLOAD_PAGE_OPTIONS].skip /
            LocalStorageHelpers.getStoredPageSize(isModalWindow) +
        1;
    if (!useNewApi) {
        return {
            UserGuid,
            Model: {
                ...result,
            },
        };
    } else {
        return flattenParameterObject(result);
    }
}

export function flattenParameterObject(result) {
    const paramList = [];
    for (const propGroup in result) {
        if (Object.hasOwn(result, propGroup)) {
            if (typeof result[propGroup] === "object") {
                for (const prop in result[propGroup]) {
                    if (Object.hasOwn(result[propGroup], prop) && result[propGroup][prop]) {
                        if (typeof result[propGroup][prop] !== "object") {
                            paramList.push({
                                key: prop,
                                value: result[propGroup][prop],
                            });
                        } else if (result[propGroup][prop].FieldName !== undefined) {
                            paramList.push({
                                key: "SortBy",
                                value: result[propGroup][prop].FieldName,
                            });
                            if (result[propGroup][prop].Sort.Direction === "DESC") {
                                paramList.push({
                                    key: "SortDesc",
                                    value: true,
                                });
                            }
                        }
                    }
                }
            } else if (result[propGroup] !== undefined) {
                paramList.push({
                    key: propGroup,
                    value: result[propGroup],
                });
            }
        }
    }
    return paramList;
}
