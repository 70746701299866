import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { SelectInput, optionPropType } from "~/core";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { actions as recsEventsActions, eventsModels } from "~/recs-events";

import "../../../../../common/rec-event-info/rec-event-info.css";
import "./event-application-form.css";

const messages = defineMessages({
    applicationMethodPlaceholderText: {
        id: "eventModule.eventInfo.applicationMethodPlaceholderText",
        defaultMessage: "Application Method",
    },
    applicationTimingPlaceholderText: {
        id: "eventModule.eventInfo.applicationTimingPlaceholderText",
        defaultMessage: "Application Timing",
    },
});

const { getPickListCode, PICKLIST_APPLICATION_METHOD, PICKLIST_APPLICATION_TIMING } = picklistNames;

export class EventApplicationFieldData_ extends PureComponent {
    static propTypes = {
        recEventDetails: PropTypes.object,
        fieldGuid: PropTypes.string,
        onFetchPicklists: PropTypes.func.isRequired,
        onUpdateEventDetails: PropTypes.func.isRequired,
        picklistOptionsApplicationMethod: PropTypes.arrayOf(optionPropType),
        picklistOptionsApplicationTiming: PropTypes.arrayOf(optionPropType),
        intl: intlShape.isRequired,
    };

    UNSAFE_componentWillMount() {
        const fetchPicklistNames = [PICKLIST_APPLICATION_METHOD, PICKLIST_APPLICATION_TIMING];

        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = getPickListCode(key);
            return accum;
        }, {});

        this.props.onFetchPicklists(picklistFetchArgObj);
    }

    _updateEventFieldData(newProps) {
        const { recEventDetails, onUpdateEventDetails } = this.props;

        const eventAreaList = recEventDetails.eventAreaList.map((eventArea) => {
            return eventsModels.AgEventArea.updateAgEventArea(eventArea, {
                agEventList: eventArea.agEventList.map((agEvent) => {
                    return eventsModels.AgEvent.updateAgEvent(agEvent, {
                        agEventModel: agEvent.agEventModel.updateAgEventModel({
                            ...newProps,
                        }),
                    });
                }),
            });
        });
        onUpdateEventDetails({ eventAreaList });
    }

    render() {
        const {
            recEventDetails,
            picklistOptionsApplicationMethod,
            picklistOptionsApplicationTiming,
        } = this.props;
        const { formatMessage } = this.props.intl;

        let firstApplicationEvent = null;
        recEventDetails.eventAreaList.some((eventArea) => {
            return eventArea.agEventList.some((agEvent) => {
                const isApplication =
                    agEvent.agEventModel instanceof eventsModels.AgEventApplication;
                if (isApplication) {
                    firstApplicationEvent = agEvent;
                }
                return isApplication;
            });
        });
        const { applicationMethodGuid, applicationTimingGuid } = firstApplicationEvent.agEventModel;

        if (!firstApplicationEvent) {
            return null;
        }

        return (
            <div className="rec-manual-field-data">
                <div className="input-row">
                    <SelectInput
                        onChange={(v) =>
                            this._updateEventFieldData({
                                applicationMethodGuid: v,
                            })
                        }
                        optionIsHiddenKey={ACTIVE_YN}
                        options={picklistOptionsApplicationMethod}
                        placeholderText={formatMessage(messages.applicationMethodPlaceholderText)}
                        value={applicationMethodGuid}
                    />
                    <SelectInput
                        onChange={(v) =>
                            this._updateEventFieldData({
                                applicationTimingGuid: v,
                            })
                        }
                        optionIsHiddenKey={ACTIVE_YN}
                        options={picklistOptionsApplicationTiming}
                        placeholderText={formatMessage(messages.applicationTimingPlaceholderText)}
                        value={applicationTimingGuid}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const picklistOptionsApplicationMethod = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_APPLICATION_METHOD)
    );
    const picklistOptionsApplicationTiming = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_APPLICATION_TIMING)
    );

    return {
        picklistOptionsApplicationMethod,
        picklistOptionsApplicationTiming,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onUpdateEventDetails: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateEventDetails(fieldGuid, newProps)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateEventDetails: (newProps) =>
        dispatchProps.onUpdateEventDetails(ownProps.fieldGuid, newProps),
});

export const EventApplicationFieldData = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(EventApplicationFieldData_));
