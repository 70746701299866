import { SelectEffect, select } from "redux-saga/effects";
import { JohnDeereAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login/selectors";

export function* receivedRefreshToken(value): Generator<Promise<any> | SelectEffect, void, string> {
    const refreshToken = yield JohnDeereAPI.exchangeForRefreshToken(value.code);
    if (refreshToken == null) {
        console.error("Invalid refresh token received from API.");
        return;
    }

    const userGuid = yield select(getTheUserGuid);
    yield JohnDeereAPI.saveRefreshToken(userGuid, refreshToken);
}

export function* fetchRegistrationUrl(
    userGuid: string,
    state: string
): Generator<Promise<any>, any, unknown> {
    return yield JohnDeereAPI.fetchRegistrationUrl(userGuid, state);
}

export function* fetchSelectOrganizationsUrl(state: string): Generator<Promise<any>, any, unknown> {
    return yield JohnDeereAPI.fetchSelectOrganizationsUrl(state);
}
