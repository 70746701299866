import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import {
    TextInput,
    Bucket,
    BucketHeader,
    SelectInput,
    Checkbox,
    NumericInput,
    Loader,
} from "~/core";
import * as actions from "./actions";
import { IErrorCodeList } from "./interfaces";
import { ImageryIcon } from "~/core/icons/imagery";
import {
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";
import { PicklistAPI } from "@ai360/core";

import "./analysis-imagery-setup-form.css";
import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import { IAnalysisImagerySetupSummary } from "~/recs-events/analysis/model";
import { messages } from "../i18n-messages";
import useLoginUserPreferences from "~/core/components/login-user-preferences";

class IAnalysisImagerySetupProps {
    analysisSummary: IAnalysisImagerySetupSummary;
    croppingSeasonOptions: PicklistAPI.IPicklistItem[];
    fetchPicklistData: (picklists) => void;
    imageryTypeOptions: PicklistAPI.IPicklistItem[];
    intl: intlShape;
    updateAnalysisSummary: (newProps) => void;
}

export const formLabelMessage = messages.imagerySidebar;
export const formLabelIcon = ImageryIcon;

const SATELLITE_IMAGERY_SETUP = "SatelliteImagerySetup";
const MAX_CLOUD_PCT = "maxCloudPct";

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText],
    [2806, messages.analysisDuplicateName],
]);

const monthNumbers = [
    [1, "January"],
    [2, "February"],
    [3, "March"],
    [4, "April"],
    [5, "May"],
    [6, "June"],
    [7, "July"],
    [8, "August"],
    [9, "September"],
    [10, "October"],
    [11, "November"],
    [12, "December"],
];

export const errorCodesApply = (errorCodeList: IErrorCodeList): number => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

const AnalysisImagerySetupForm = (props: IAnalysisImagerySetupProps): JSX.Element => {
    const {
        analysisSummary,
        croppingSeasonOptions,
        fetchPicklistData,
        imageryTypeOptions,
        updateAnalysisSummary,
    } = props;
    const { formatMessage } = props.intl;

    //state variables

    const [initialMaxClouds, setInitialMaxClouds] = useState<number>(0);

    //Fetch Preferences

    const getLoginMaxCloudPctPreference = () => {
        const { loginUserPreferences, isLoading } = useLoginUserPreferences(
            SATELLITE_IMAGERY_SETUP,
            [MAX_CLOUD_PCT]
        );
        if (isLoading) {
            return null;
        }
        return loginUserPreferences?.maxCloudPct ? loginUserPreferences.maxCloudPct : 50;
    };

    const getUserMaxClouds = () => {
        if (!analysisSummary.isNew) {
            return initialMaxClouds;
        }
        const loginPref = getLoginMaxCloudPctPreference();
        return loginPref ? Number(loginPref) : null;
    };

    const userMaxCloudPref = getUserMaxClouds();

    useEffect(() => {
        fetchPicklistData({
            [picklistNames.PICKLIST_CROPPING_SEASON]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROPPING_SEASON
            ),
            [picklistNames.PICKLIST_IMAGERY_TYPE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_IMAGERY_TYPE
            ),
        });
    }, []);

    useEffect(() => {
        setInitialMaxClouds(analysisSummary.maximumPercentCloudsAllowed);
    }, []);

    useEffect(() => {
        if (!analysisSummary.maximumPercentCloudsAllowed && userMaxCloudPref != null) {
            updateImagerySetup({ maximumPercentCloudsAllowed: userMaxCloudPref });
        }
    }, [userMaxCloudPref]);

    const updateImagerySetup = (data) => {
        updateAnalysisSummary({ ...data, hasChanged: true });
    };

    const updateSelectedImageryTypes = (imageryTypeGuid: string, value: boolean) => {
        const existingSelectedList = analysisSummary.imageryTypeGuidList || [];
        let newSelectedList = existingSelectedList;
        if (value) {
            newSelectedList.push(imageryTypeGuid);
        } else {
            newSelectedList = newSelectedList.filter((i) => i !== imageryTypeGuid);
        }
        updateImagerySetup({ imageryTypeGuidList: newSelectedList });
    };

    const getTypeCheckboxes = () => {
        const checkboxes = [];
        const filteredImageryTypeOptions = imageryTypeOptions.filter(
            (option) => option.activeYn === true
        );
        filteredImageryTypeOptions.forEach((option) => {
            checkboxes.push(
                <Checkbox
                    className="imagery-setup-checkbox"
                    key={option.value}
                    label={option.label}
                    value={analysisSummary.imageryTypeGuidList?.includes(option.value as string)}
                    onChange={(evt, v) => updateSelectedImageryTypes(option.value as string, v)}
                />
            );
        });
        return checkboxes;
    };

    const isCalculateAbsoluteNdviEnabled = () => {
        const filteredImageryTypeOptions = imageryTypeOptions.filter(
            (option) => option.activeYn === true && option.label.includes("NDVI")
        );

        const ndviGuids = filteredImageryTypeOptions.map((option) => option.value);
        return analysisSummary.imageryTypeGuidList?.some((g) => ndviGuids.includes(g));
    };

    const getClassificationOptions = () => {
        return (
            <Checkbox
                className="imagery-setup-checkbox"
                disabled={!isCalculateAbsoluteNdviEnabled()}
                label={formatMessage(messages.standardizedLegend)}
                value={analysisSummary.calculateAbsoluteNdvi}
                onChange={(evt, v) => updateImagerySetup({ calculateAbsoluteNdvi: v })}
            />
        );
    };

    const getStartMonthOptions = () => {
        return monthNumbers.map((m) => {
            return {
                activeYn: true,
                label: m[1].toString(),
                value: m[0],
                sortOrder: Number(m[0]),
            };
        });
    };

    const getEndMonthOptions = () => {
        const unfilteredMonths = monthNumbers.map((m) => {
            return {
                activeYn: true,
                label: m[1].toString(),
                value: m[0],
                sortOrder: Number(m[0]),
            };
        });
        return unfilteredMonths.filter((m) => m.value >= analysisSummary.startMonth);
    };

    const isLoading = analysisSummary.isNew && !userMaxCloudPref && userMaxCloudPref !== 0;

    if (isLoading) return <Loader />;

    return (
        <div className="rec-event-info-form">
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.analysisFormLabelText)}</BucketHeader>
                <div className="analysis-layer-imagery-setup-grid">
                    <TextInput
                        required={true}
                        autoFocus={!analysisSummary.name}
                        containerClassNames={["analysis-layer-imagery-setup-input"]}
                        onChange={(v) => updateImagerySetup({ name: v })}
                        placeholderText={formatMessage(messages.imagerySetupNamePlaceholder)}
                        value={analysisSummary.name}
                        maxLength={50}
                    />
                    <SelectInput
                        disabled={false}
                        clearable={false}
                        required={true}
                        containerClassNames={["analysis-layer-imagery-setup-input"]}
                        onChange={(v) =>
                            updateImagerySetup({
                                croppingSeasonGuid: v,
                                endMonth: null,
                                startMonth: null,
                            })
                        }
                        options={croppingSeasonOptions}
                        placeholderText={formatMessage(messages.season)}
                        value={analysisSummary.croppingSeasonGuid}
                    />
                    <SelectInput
                        disabled={false}
                        clearable={false}
                        required={true}
                        containerClassNames={["analysis-layer-imagery-setup-input"]}
                        onChange={(v) =>
                            updateImagerySetup({
                                startMonth: v,
                                endMonth: null,
                            })
                        }
                        options={getStartMonthOptions()}
                        placeholderText={formatMessage(messages.startMonthPlaceholder)}
                        value={analysisSummary.startMonth}
                    />
                    <SelectInput
                        disabled={!analysisSummary.startMonth}
                        clearable={false}
                        required={true}
                        containerClassNames={["analysis-layer-imagery-setup-input"]}
                        onChange={(v) =>
                            updateImagerySetup({
                                endMonth: v,
                            })
                        }
                        options={getEndMonthOptions()}
                        placeholderText={formatMessage(messages.endMonthPlaceholder)}
                        value={analysisSummary.endMonth}
                    />
                    <NumericInput
                        required={true}
                        autoFocus={!analysisSummary.maximumPercentCloudsAllowed}
                        containerClassNames={["analysis-layer-imagery-setup-input"]}
                        onChange={(v) => updateImagerySetup({ maximumPercentCloudsAllowed: v })}
                        placeholderText={formatMessage(
                            messages.maximumPercentCloudsAllowedPlaceholder
                        )}
                        value={analysisSummary.maximumPercentCloudsAllowed}
                        maxLength={3}
                        precision={3}
                        scale={0}
                    />
                </div>
            </Bucket>
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader className="analysis-info-bucket-header">
                    {formatMessage(messages.imageryOptions)}
                </BucketHeader>
                {getTypeCheckboxes()}
            </Bucket>
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader className="analysis-info-bucket-header">
                    {formatMessage(messages.classification)}
                </BucketHeader>
                {getClassificationOptions()}
            </Bucket>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateAnalysisSummary: (newProps) => dispatch(actions.updateImageryAnalysisSummary(newProps)),
    fetchPicklistData: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
});

const mapStateToProps = (state) => {
    const croppingSeasonOptions = picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
    );
    const imageryTypeOptions = picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_IMAGERY_TYPE)
    );
    return {
        croppingSeasonOptions,
        imageryTypeOptions,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnalysisImagerySetupForm));
