import React from "react";
import { MeasureTools } from "@ai360/core";

import { NoLink, Pane, SelectInput, Tabs, ToolbarMenu, ToolbarMenuContent } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";

export class MeasureToolSet extends MeasureTools {
    getToolbar(): JSX.Element {
        const { messages, formatMessage } = this.options;
        return (
            <ToolbarMenu
                className="measure"
                label={formatMessage(messages.measureLbl)}
                icon={ToolbarIcon(IconType.MEASURE)}
                onToggle={(evt) => this.toggle(evt)}
                activeCount={this.nbrMeasureGraphics}
            >
                <ToolbarMenuContent className="measure-tools">
                    <Tabs
                        selected={this.selectedTab}
                        onTabSelect={(index) => this.selectTab(index)}
                    >
                        <Pane label={formatMessage(messages.areaTabLbl)}>
                            <div className="measure-pane-content">
                                <SelectInput
                                    options={this.areaUnits}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    clearable={false}
                                    placeholderText={formatMessage(messages.areaUnitLbl)}
                                    onChange={(value) => this._updateAreaUnits(value)}
                                    value={this.selectedAreaUnit}
                                />
                                <div className="measure-info">{this.area}</div>
                            </div>
                        </Pane>
                        <Pane label={formatMessage(messages.lengthTabLbl)}>
                            <div className="measure-pane-content">
                                <SelectInput
                                    options={this.lengthUnits}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    clearable={false}
                                    placeholderText={formatMessage(messages.lengthUnitLbl)}
                                    onChange={(options) => this._updateLengthUnits(options)}
                                    value={this.selectedLengthUnit}
                                />
                                <div className="measure-info">{this.length}</div>
                            </div>
                        </Pane>
                        <Pane label={formatMessage(messages.locationTabLbl)}>
                            <div className="measure-pane-content">
                                <SelectInput
                                    options={this.coordinateFormat}
                                    optionIsHiddenKey={ACTIVE_YN}
                                    clearable={false}
                                    placeholderText={formatMessage(messages.coordinateFormatLbl)}
                                    onChange={(options) => this._updateCoordinateFormat(options)}
                                    value={this.selectedCoordinateFormat}
                                />
                                <div className="measure-info">{this.coordinate}</div>
                            </div>
                        </Pane>
                    </Tabs>
                    <div className="clear-measure">
                        <NoLink
                            label={formatMessage(messages.clearMeasurementsLbl)}
                            onClick={() => this._clearMeasurements()}
                        />
                    </div>
                </ToolbarMenuContent>
            </ToolbarMenu>
        );
    }
}
