import { connect } from "react-redux";
import ApplicationView from "./application";
import { getUnit } from "~/admin/selectors";
import * as unitService from "~/core/units/unit-names";

const mapStateToProps = (state) => ({
    [unitService.UNIT_LENGTH]: getUnit(unitService.getUnitCode(unitService.UNIT_LENGTH), state),
});

export const Application = connect(mapStateToProps, null)(ApplicationView);
