export const APPLICATION_SAMPLE_ATTRIBUTES = "applicationSampleAttributes";
export const HARVEST_SAMPLE_ATTRIBUTES = "harvestSampleAttributes";
export const IMPORT_SAMPLE_TEMPLATES = "importSampleTemplates";
export const IMPORT_TYPES = "importTypes";
export const PLANTING_SAMPLE_ATTRIBUTES = "plantingSampleAttributes";
export const REQUEST_OPTION_FILE0 = "file0";
export const REQUEST_OPTION_FILENAME = "fileName";
export const REQUEST_OPTION_FILESIZE = "fileSize";
export const REQUEST_OPTION_LOGGED_IN_USER_GUID = "loggedInUserGuid";
export const REQUEST_OPTION_TYPEGUID = "typeGuid";
export const SAMPLE_ATTRIBUTES = "sampleAttributes";
export const TEMPLATE_TYPE_APPLICATION = "Application";
export const TEMPLATE_TYPE_HARVEST = "Harvest";
export const TEMPLATE_TYPE_PLANTING = "Planting";
export const VENDOR_DATA = "vendorData";
