import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDropdowns } from "./../../selectors";
import { getTheUserCompanyGuid, getTheOwnerBrandName } from "~/login";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { SortingHelpers, UserRoleAPI } from "@ai360/core";
import { Messages } from "~/navigation/i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../i18n-messages";

import TableCell from "~/core/components/tables/data-table/components/table-cell";

export const DROPDOWN_NAME = "userRoleMatrixList";
export const COMPANY_GUID = "companyGuid";
export const ROLE_NAME = "userRoleName";
export const ROLE_NAME_LIST = "userRoleNameList";
export const GROUP_NAME_CODE = "groupNameCode";
export const PERMISSION_LIST = "permissionList";
export const PERMISSION_NAME = "permissionName";
export const PERMISSION_NAME_CODE = "permissionNameCode";
export const ROLE_PERMISSION_LIST = "rolePermissionList";
export const HAS_PERMISSION = "hasPermission";
export const LABEL = "label";

class UserRoleMatrix extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        companyGuid: PropTypes.string,
        fetchData: PropTypes.func,
        needs: PropTypes.func,
        setBreadcrumbs: PropTypes.func,
        systemName: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            permissionList: [],
            userRoleNameList: [],
        };
    }

    UNSAFE_componentWillMount() {
        const { companyGuid, fetchData, needs } = this.props;
        const { formatMessage } = this.props.intl;
        this.props.setBreadcrumbs([formatMessage(messages.userRoleMatrix)]);
        if (companyGuid) {
            needs([
                fetchData({
                    [DROPDOWN_NAME]: {
                        url: UserRoleAPI.GET_ROLE_LIST,
                        model: {
                            userRoleMatrixFilter: {
                                UserRoleName: "",
                                isActive: "",
                            },
                            userRoleMatrixSort: [
                                {
                                    Sort: {
                                        Direction: "ASC",
                                        Order: 0,
                                    },
                                },
                            ],
                            userRoleMatrixPageOptions: {
                                pageSize: 10,
                                skip: 0,
                            },
                            [COMPANY_GUID]: companyGuid,
                        },
                    },
                }),
            ]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps[DROPDOWN_NAME] !== this.props[DROPDOWN_NAME]) {
            this.setState({
                ...nextProps[DROPDOWN_NAME],
            });
        }
    }

    sortLists = (permissionArray) => {
        //Adds the labels for the parent and child permissions and sorts the lists
        const { formatMessage } = this.props.intl;
        for (let i = 0; i < permissionArray.length; i++) {
            permissionArray[i][LABEL] = formatMessage(
                Messages[permissionArray[i][GROUP_NAME_CODE]]
            );
            for (let j = 0; j < permissionArray[i][PERMISSION_LIST].length; j++) {
                const permission = permissionArray[i][PERMISSION_LIST][j];
                permission[LABEL] =
                    permission[PERMISSION_NAME_CODE] === 224
                        ? this.props.systemName
                        : formatMessage(Messages[permission[PERMISSION_NAME_CODE]]);
            }
            // Child permission list
            permissionArray[i][PERMISSION_LIST] = SortingHelpers.sortValues(
                permissionArray[i][PERMISSION_LIST],
                LABEL
            );
        }
        //Parent permission list
        return SortingHelpers.sortValues(permissionArray, LABEL);
    };

    renderMatrixRole = () => {
        const { userRoleNameList, permissionList } = this.state;
        let permissionArray = this.sortLists([...permissionList]);
        return (
            <div className="matrix-grid">
                <div className="grid-head">
                    <TableCell className="column-header-label">{"User Role Name"}</TableCell>
                    {userRoleNameList.map((userRole, index) => (
                        <TableCell key={index} className="column-header">
                            {userRole[ROLE_NAME]}
                        </TableCell>
                    ))}
                </div>
                <div className="grid-body">
                    {permissionArray.map((permission, index) => (
                        <div key={index}>
                            <div className="column-sub-header">{permission[LABEL]}</div>
                            {permission[PERMISSION_LIST].map((childPermision, index) => (
                                <div className="grid-row" key={index}>
                                    <TableCell className="column-label">
                                        {childPermision[LABEL]}
                                    </TableCell>
                                    {childPermision[ROLE_PERMISSION_LIST].map(
                                        (rolePermission, index) => {
                                            if (rolePermission[HAS_PERMISSION]) {
                                                return (
                                                    <TableCell key={index} className="row-data">
                                                        {"\u2713"}
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={index} className="row-no-data">
                                                        {"\u00A0"}
                                                    </TableCell>
                                                );
                                            }
                                        }
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        const { userRoleNameList, permissionList } = this.state;
        return (
            <div className="user-role-matrix">
                {userRoleNameList.length && permissionList.length ? this.renderMatrixRole() : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchData: (payload) => dispatch(fetchDropdownData(payload)),
});

const mapStateToProps = (state) => ({
    ...getDropdowns([DROPDOWN_NAME], state),
    [COMPANY_GUID]: getTheUserCompanyGuid(state),
    systemName: getTheOwnerBrandName(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserRoleMatrix));
