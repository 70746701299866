import { CORE_STATE_KEY } from "~/core";
import { API_ERROR_DATA_KEY } from "./reducer";
import RootState from "~/types/store";

const _getModuleState = (state: RootState) => state[CORE_STATE_KEY][API_ERROR_DATA_KEY];

export const getApiError = (state: RootState) => _getModuleState(state);

export const getApiErrorCodeList = (state: RootState) => _getModuleState(state).errorCodeList || [];

export const getNewApiErrorCodeList = (state: RootState) => {
    const newApiResults = _getModuleState(state).newApiResults;
    if (!newApiResults || typeof newApiResults.errors === "undefined") {
        return [];
    }

    return newApiResults.errors.map((x) => x.code);
};

export const getApiErrorModel = (state: RootState) => {
    const { apiResultObj } = _getModuleState(state);
    return apiResultObj && apiResultObj.model ? apiResultObj.model : {};
};
