import { connect } from "react-redux";
import OrgLevelContainer from "~/admin/containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, dropdowns, service, actions, selectors } from "../data";
import { getTheUserPersonalityId } from "~/login/selectors";

const mapStateToProps = (state) => ({
    orgLevelRecord: selectors.getOrgLevelRecord(state),
    orgTypeName: selectors.getOrgTypeName(state),
    equipmentList: selectors.getOrgLevelEquipmentList(state),
    personalityId: getTheUserPersonalityId(state),
    duplicateDatabaseIdOrgLevels: selectors.getDuplicateDatabaseIdOrgLevels(state),
    agvanceLocations: selectors.getAgvanceLocations(state),
});

const mapDispatchToProps = () => ({
    getActiveOrgTypeName: (payload) => actions.getActiveOrgTypeName(payload),
    getOrgLevel: (payload) => actions.getOrgLevel(payload),
    createEquipment: (payload) => actions.createEquipment(payload),
    fetchOrgLevelEquipmentList: (payload) => actions.fetchOrgLevelEquipmentList(payload),
    getOrgLevelWithAgvanceDatabaseId: (payload) =>
        actions.getOrgLevelWithAgvanceDatabaseId(payload),
    getAgvanceLocations: (payload) => actions.getAgvanceLocations(payload),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgLevelContainer(AddEditPanel, { pickLists, dropdowns, service }));
