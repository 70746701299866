import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import { plantingModule } from "~/action-panel/components/common/planting";

import {
    RecManualPlantingForm as RecManualPlantingFormProvider,
    RecManualPlantingSummary as RecManualPlantingSummaryProvider,
} from "./providers";
import { AgEventPlanting } from "~/recs-events/events/models";

const { PlantingEventForm } = plantingModule;
const messages = defineMessages({
    formLabelMessage: {
        id: "recManualPlanting.formLabelMessage",
        defaultMessage: "Manual",
    },
});

const RecManualPlantingForm_ = (props) => {
    const onUpdateModel = (newProps) => {
        const existingPlantingModel = props.recModel.recPlanting || new AgEventPlanting();
        props.onUpdateModel({
            ...props.recModel,
            equationFilters: null,
            recPlanting: {
                ...existingPlantingModel,
                ...newProps,
            },
        });
    };

    return (
        <PlantingEventForm
            model={props.recModel.recPlanting}
            {...props}
            onUpdateModel={onUpdateModel}
        />
    );
};

RecManualPlantingForm_.propTypes = {
    recModel: PropTypes.object,
    onUpdateModel: PropTypes.func,
};
export const errorCodesApply = plantingModule.errorCodesApply;
export const errorMessages = plantingModule.errorMessages;
export const errorCodeToMessageIdSetMap = plantingModule.errorCodeToMessageIdSetMap;
export const formLabelMessage = messages.formLabelMessage;
export const formLabelIcon = plantingModule.formLabelIcon;
export const RecManualPlantingForm = RecManualPlantingFormProvider(RecManualPlantingForm_);
export const RecManualPlantingSummary = RecManualPlantingSummaryProvider(
    plantingModule.SummarySection
);
