import { adminData } from "~/admin/data";

export const PROPS_ADDRESS = "theAddress";
export const PROPS_ADDRESS_IS_PRIMARY = "isPrimary";
export const PROPS_ADDRESS_NAME = "addressName";
export const PROPS_ADDRESS_TYPE_GUID = "addressTypeGuid";
export const PROPS_ADDRESS_TYPE_LIST = "addressTypeList";
export const PROPS_ADDRESS_TYPE_NAME = "addressTypeName";
export const PROPS_CITY = "city";
export const PROPS_COMPONENT_KEY = "personAddressList";
export const PROPS_COUNRTY_NAME = "countryName";
export const PROPS_COUNTRY_GUID = "countryGuid";
export const PROPS_COUNTRY_LIST = "countriesList";
export const PROPS_READ_ONLY_YN = "readOnlyYn";
export const PROPS_STATE_GUID = "stateGuid";
export const PROPS_STATE_LIST = "stateList";
export const PROPS_STATE_NAME = "stateName";
export const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;
export const PROPS_STREET_ONE = "street1";
export const PROPS_ZIP_CODE = "zipCode";
