import { createReducer } from "redux-act";
import * as actions from "./actions";

export const IMPORT_FILTER_DATA_KEY = "IMPORT_FILTER_DATA";
const initialState = {
    importFilterData: {},
};

export const importFilterReducer = createReducer(
    {
        [actions.fetchImportFilterSuccess]: (state, importFilterData) => ({
            ...state,
            importFilterData,
        }),
    },
    initialState
);
