import PropTypes from "prop-types";
import { model } from "~/admin/agBytes/trait/data";

export const shape = {
    [model.PROPS_TRAIT_ABBREVIATION]: PropTypes.string,
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_CAN_DELETE]: PropTypes.string,
    [model.PROPS_CROP_NAME]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_TRAIT_BRAND_ORGANIZATION]: PropTypes.string,
    [model.PROPS_TRAIT_GMO]: PropTypes.bool,
    [model.PROPS_TRAIT_GUID]: PropTypes.string,
    [model.PROPS_TRAIT_ID]: PropTypes.number,
    [model.PROPS_TRAIT_NAME]: PropTypes.string,
};

export default PropTypes.shape(shape);
