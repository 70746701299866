import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import deepEqual from "fast-deep-equal";
import { connect } from "react-redux";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import { ProductBlendModal } from "~/action-panel/components/common/product-blend-modal";
import {
    ProductMixTypes,
    PhysicalStates,
    setEventRecCustomProductGuids,
} from "~/action-panel/components/common/product-blend-modal/actions";

import { defineMessages, injectIntl, intlShape } from "react-intl";
import { config as intlConfig } from "~/intl-provider/config";

import {
    Bucket,
    BucketHeader,
    Checkbox,
    Loader,
    Menu,
    NoLink,
    NumericInput,
    RadioButton,
    SelectInput,
    SelectInputMulti,
} from "~/core";

import { ACTIVE_YN } from "~/core/picklist";

import * as selectors from "../../selectors";
import * as actions from "../../actions";
import * as utils from "./rec-equation-application-form-utils";

import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";

import {
    models as recsEventsModels,
    recsModels,
    actions as recsEventsActions,
    recsSelectors,
    recsActions,
} from "~/recs-events";

import { selectors as blendingSelectors } from "~/action-panel/components/common/product-blend-modal";

import { getSetValuesForErrorCodeList } from "../../../../../common/validation-utils";

import { EquationIcon } from "../icons/equation";

import "../../../../../common/rec-event-info/rec-event-info.css";
import "./rec-equation-application-form.css";
import {
    EVENT_TYPE_NAME_HARVEST,
    EVENT_TYPE_NAME_SAMPLING_SOIL,
    EVENT_TYPE_NAME_SAMPLING_TISSUE,
} from "~/recs-events/events/model";
import { messages as fieldMessages } from "~/action-panel/components/field-module/components/field-list/i18n-messages";
import { models as rxFileImportModels, RxFileImport } from "./rx-file-import";

const MANAGEMENT_AREA = "Management_Area";
const NORMALIZED_YIELD = "Normalized_Yield";

const messages = defineMessages({
    applicationEquationFormLabelText: {
        id: "recModule.recInfo.applicationEquationFormLabelText",
        defaultMessage: "Equation",
    },
    missingTestResultsText: {
        id: "recModule.recInfo.missingTestResultsText",
        defaultMessage: "Missing Test Results For ",
    },
    parametersText: {
        id: "recModule.recInfo.parametersText",
        defaultMessage: "Parameters",
    },
    nutrientsText: {
        id: "recModule.recInfo.nutrientsText",
        defaultMessage: "Nutrients & Products",
    },
    cropText: {
        id: "recModule.recInfo.cropText",
        defaultMessage: "Crop",
    },
    cropClassText: {
        id: "recModule.recInfo.cropClassText",
        defaultMessage: "Crop Class",
    },
    cropPurposeText: {
        id: "recModule.recInfo.cropPurposeText",
        defaultMessage: "Crop Purpose",
    },
    previousCropText: {
        id: "recModule.recInfo.previousCropText",
        defaultMessage: "Previous Crop",
    },
    nextCropText: {
        id: "recModule.recInfo.nextCropText",
        defaultMessage: "Next Crop",
    },
    soilTextureText: {
        id: "recModule.recInfo.soilTextureText",
        defaultMessage: "Soil Texture",
    },
    tillageMethodText: {
        id: "recModule.recInfo.tillageMethodText",
        defaultMessage: "Tillage Method",
    },
    applicationTimingText: {
        id: "recModule.recInfo.applicationTimingText",
        defaultMessage: "Application Timing",
    },
    applicationMethodText: {
        id: "recModule.recInfo.applicationMethodText",
        defaultMessage: "Application Method",
    },
    processingText: {
        id: "recModule.recInfo.processingText",
        defaultMessage: "Processing",
    },
    productText: {
        id: "recModule.recInfo.productText",
        defaultMessage: "Product",
    },
    incorporationDepthText: {
        id: "recModule.recInfo.incorporationDepthText",
        defaultMessage: "Incorporation Depth",
    },
    buildFactorText: {
        id: "recModule.recInfo.buildFactorText",
        defaultMessage: "Build Factor",
    },
    targetpHText: {
        id: "recModule.recInfo.targetpHText",
        defaultMessage: "Target pH",
    },
    recOptionText: {
        id: "recModule.recInfo.recOptionText",
        defaultMessage: "Rec Option",
    },
    samplingDepthText: {
        id: "recModule.recInfo.samplingDepthText",
        defaultMessage: "Sampling Depth",
    },
    setDefaultText: {
        id: "recModule.recInfo.setDefaultText",
        defaultMessage: "Set Default",
    },
    setProductText: {
        id: "recModule.recInfo.setProductText",
        defaultMessage: "Set Product",
    },
    managementAreaText: {
        id: "recModule.recInfo.managementAreaText",
        defaultMessage: "Management Area",
    },
    normalizedYieldText: {
        id: "recModule.recInfo.normalizedYieldText",
        defaultMessage: "Normalized Yield",
    },
});

export const formLabelMessage = messages.applicationEquationFormLabelText;
export const formLabelIcon = EquationIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2813, null], // ErrorCodes.RecommendationSoilTestRequired
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class RecEquationApplicationForm_ extends PureComponent {
    static propTypes = {
        activeNutrientGuid: PropTypes.string,
        addDefaultProduct: PropTypes.func,
        addRecAdjustments: PropTypes.func,
        addUpdateRecProduct: PropTypes.func,
        availableProducts: PropTypes.array,
        batchAddRecAdjustments: PropTypes.func,
        batchRecDetailsForEdit: PropTypes.array,
        classBreaksCount: PropTypes.number.isRequired,
        classBreaksHaveOnlyOneZone: PropTypes.bool.isRequired,
        closeRecInfo: PropTypes.func,
        conversionFactors: PropTypes.object.isRequired,
        customProducts: PropTypes.array,
        recModel: PropTypes.object,
        fieldGuid: PropTypes.string,
        calculatedArea: PropTypes.number,
        equationFilterLists: PropTypes.object,
        equationFilterRequired: PropTypes.object,
        filteredEquationGroupList: PropTypes.arrayOf(
            PropTypes.shape({
                activeStatus: PropTypes.string,
                description: PropTypes.string,
                equationGroupGuid: PropTypes.string.isRequired,
                equations: PropTypes.arrayOf(
                    PropTypes.shape({
                        activeStatus: PropTypes.string,
                        cropGuid: PropTypes.string,
                        defaultProduct: PropTypes.object,
                        equation: PropTypes.string,
                        equationDescription: PropTypes.string,
                        equationGroupGuid: PropTypes.string.isRequired,
                        equationGuid: PropTypes.string.isRequired,
                        equationName: PropTypes.string.isRequired,
                        gridParameters: PropTypes.array,
                        isSelected: PropTypes.bool,
                        nutrientGuid: PropTypes.string.isRequired,
                        nutrientName: PropTypes.string.isRequired,
                        optionalUserParameters: PropTypes.arrayOf(
                            PropTypes.shape({
                                allowNegatives: PropTypes.bool,
                                analysisLayerGuid: PropTypes.string,
                                dataType: PropTypes.string,
                                dataTypeGuid: PropTypes.string,
                                decimalPlaces: PropTypes.string,
                                defaultValue: PropTypes.string,
                                equationParameterGuid: PropTypes.string,
                                label: PropTypes.string.isRequired,
                                managementAreaAnalysisLayerGuids: PropTypes.string,
                                name: PropTypes.string.isRequired,
                                precision: PropTypes.string,
                            })
                        ),
                        requiredUserParameters: PropTypes.arrayOf(
                            PropTypes.shape({
                                allowNegatives: PropTypes.bool,
                                analysisLayerGuid: PropTypes.string,
                                dataType: PropTypes.string,
                                dataTypeGuid: PropTypes.string,
                                decimalPlaces: PropTypes.string,
                                defaultValue: PropTypes.string,
                                equationParameterGuid: PropTypes.string,
                                label: PropTypes.string.isRequired,
                                managementAreaAnalysisLayerGuids: PropTypes.string,
                                name: PropTypes.string.isRequired,
                                precision: PropTypes.string,
                            })
                        ),
                        vendorGuid: PropTypes.string,
                    })
                ),
                gridParameters: PropTypes.arrayOf(
                    PropTypes.shape({
                        displayName: PropTypes.string.isRequired,
                        hasData: PropTypes.bool.isRequired,
                        name: PropTypes.string.isRequired,
                    })
                ),
                groupName: PropTypes.string.isRequired,
            })
        ),
        isBatch: PropTypes.bool.isRequired,
        isBatchEditRecsModalActive: PropTypes.bool.isRequired,
        isEquationsLoading: PropTypes.bool.isRequired,
        isLoadingStatus: PropTypes.bool.isRequired,
        isProcessingRecNutrient: PropTypes.bool.isRequired,
        onUpdateCurrentRecAreaRec: PropTypes.func.isRequired,
        productBlendPicklists: PropTypes.object,
        recAreaId: PropTypes.any,
        recDetails: PropTypes.any,
        recNutrientsInProcessing: PropTypes.array,
        refreshEquationFilterLists: PropTypes.func.isRequired,
        samplingDepthList: PropTypes.array,
        setActiveNutrient: PropTypes.func.isRequired,
        setIsProcessingRecNutrient: PropTypes.func.isRequired,
        setPermanentAdjustments: PropTypes.func,
        intl: intlShape.isRequired,
        saveRecDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        saveRecDetailsErrorFieldGuidList: PropTypes.arrayOf(PropTypes.string),
        setCustomProductGuids: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { recModel } = props;
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            isParametersExpanded: true,
            isNutrientsExpanded: true,
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalRecNutrientList:
                recModel && recModel.recNutrientList ? [...recModel.recNutrientList] : [],
        };
    }

    componentDidMount() {
        this.props.refreshEquationFilterLists(this.props.fieldGuid);
    }

    _toggleParametersBucket() {
        this.setState({
            isParametersExpanded: !this.state.isParametersExpanded,
        });
    }
    _toggleNutrientsBucket() {
        this.setState({
            isNutrientsExpanded: !this.state.isNutrientsExpanded,
        });
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveRecDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveRecDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    onEditProductMix(index) {
        const { recModel } = this.props;
        const originalRecNutrientList = [...recModel.recNutrientList];
        const eventRecCustomProductGuids =
            recModel.recNutrientList[index]?.recNutrientProductMix.products.map(
                (p) => p.customProductGuid
            ) || [];
        this.props.setCustomProductGuids(eventRecCustomProductGuids);
        this.setState({
            isProductBlendingActive: true,
            activeProductBlend: index,
            originalRecNutrientList,
        });
    }

    onUpdateProductBlending(productMix) {
        if (!this.props.isProcessingRecNutrient) {
            const recNutrientList = [...this.props.recModel.recNutrientList];

            if (
                JSON.stringify(productMix) !==
                JSON.stringify(recNutrientList[this.state.activeProductBlend])
            ) {
                recNutrientList.splice(this.state.activeProductBlend, 1, {
                    ...recNutrientList[this.state.activeProductBlend],
                    productBlendGuid: productMix.productBlendGuid,
                    productBlendName: productMix.productBlendName,
                    recNutrientProductMix: productMix,
                    unitGuid: productMix.targetRateUnitGuid,
                });
                this._updateRec({ recNutrientList });
            }
        }
    }
    onUpdateRecNutrient(recNutrient, updateRecProducts) {
        const recNutrientList = [...this.props.recModel.recNutrientList];
        const originalRecNutrient =
            this.props.recModel.recNutrientList[this.state.activeProductBlend];
        const originalGA = originalRecNutrient.recNutrientProductMix.guaranteedAnalysis;
        recNutrientList.splice(this.state.activeProductBlend, 1, recNutrient);

        if (recNutrient.recNutrientGuid && updateRecProducts) {
            this.props.addUpdateRecProduct(
                recNutrient.recNutrientGuid,
                recNutrientList,
                originalGA
            );
        } else {
            this._updateRec({ recNutrientList });
        }
        //TODO: If the desire pre-run is for blends with the same GA to be updated across nutrients, then it needs to follow addUpdateRecProduct through the UI, but not call the WebApi
    }

    onSaveProductBlending() {
        const recNutrientList = [...this.props.recModel.recNutrientList];
        const newRecNutrient = recNutrientList[this.state.activeProductBlend];
        const productBlendGuid = newRecNutrient.recNutrientProductMix.productBlendGuid;

        if (newRecNutrient.recNutrientGuid) {
            this.props.addRecAdjustments({
                ...newRecNutrient,
                productBlendGuid,
            });
        }

        this.props.setPermanentAdjustments(true);
        this.onCloseProductBlending();
    }
    onCancelProductBlending() {
        this._updateRec({
            recNutrientList: [...this.state.originalRecNutrientList],
        });
        this.onCloseProductBlending();
    }

    onCloseProductBlending() {
        this.setState({
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalRecNutrientList: [],
        });
    }

    _updateRec(newProps) {
        const isEquationRunEnabled =
            (newProps.isIncluded == null || newProps.isIncluded) &&
            utils.allRequiredFieldsSet(this.props);

        this.props.onUpdateCurrentRecAreaRec({
            ...newProps,
            isEquationRunEnabled,
        });
    }

    _updateRecNutrient(index, newProps) {
        const { recModel } = this.props;
        const { recNutrientList } = recModel;

        const newRecNutrientList = [...recNutrientList];
        const newRecNutrient = {
            ...newRecNutrientList[index],
            ...newProps,
        };
        const productBlendGuid = newRecNutrient.recNutrientProductMix.productBlendGuid;
        newRecNutrientList.splice(index, 1, {
            ...newRecNutrient,
            productBlendGuid,
        });
        this._updateRec({
            recNutrientList: newRecNutrientList,
            isRecDirty:
                recModel.isRecDirty ||
                (JSON.stringify(recModel.recNutrientList) !== JSON.stringify(newRecNutrientList) &&
                    newProps.exportYn == null),
        });
    }

    _updateEquationFilters(newProps) {
        const { recModel } = this.props;

        const existingProps = newProps.cropGuid
            ? new recsModels.RecEquationFilter()
            : recModel.equationFilters;
        const newEquationParameters = newProps.cropGuid ? [] : recModel.equationParameters;
        const newEquationFilters = {
            ...existingProps,
            ...newProps,
            activeEquationGroupGuid: recModel.equationGroupGuid,
            recEquationFilterGuid: recModel.equationFilters.recEquationFilterGuid,
            recGuid: recModel.recGuid,
        };
        this._updateRec({
            equationFilters: newEquationFilters,
            equationParameters: newEquationParameters,
            isRecDirty:
                recModel.isRecDirty ||
                JSON.stringify(recModel.equationFilters) !== JSON.stringify(newEquationFilters),
            isFilterChanged: true,
        });
    }

    _updateEquationParameter(name, value) {
        const { recModel } = this.props;

        const newEquationParameters = [...recModel.equationParameters];
        const paramIndex = recModel.equationParameters.findIndex((param) => param.name === name);

        if (paramIndex !== -1 && recModel.equationParameters[paramIndex].value !== value) {
            const dataType = recModel.equationParameters[paramIndex].dataType
                ? recModel.equationParameters[paramIndex].dataType.toLowerCase()
                : "";
            const isRecDirty = recModel.isRecDirty
                ? true
                : ["decimal", "integer"].includes(dataType)
                ? Number(recModel.equationParameters[paramIndex].value) !== Number(value)
                : recModel.equationParameters[paramIndex].value !== value;

            const newEquationParameter = {
                ...newEquationParameters[paramIndex],
                value: name === MANAGEMENT_AREA ? value.join(",") : value,
                analysisLayerGuid: name === NORMALIZED_YIELD ? value : null,
                managementAreaAnalysisLayerGuids: name === MANAGEMENT_AREA ? value : null,
            };
            newEquationParameters.splice(paramIndex, 1, newEquationParameter);

            this._updateRec({
                equationParameters: newEquationParameters,
                isRecDirty,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.saveRecDetailsErrorCodeList !== this.props.saveRecDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(nextProps);
            this.setState({ errorMessagePlaceholderSet });
        }
        if (!utils.allRequiredFieldsSet(this.props) && utils.allRequiredFieldsSet(nextProps)) {
            this._updateRec({ isIncluded: true });
        }
        if (
            nextProps.samplingDepthList != null &&
            nextProps.samplingDepthList.length > 1 &&
            nextProps.recModel.depthId === null
        ) {
            this._updateRec({
                depthId: nextProps.samplingDepthList[0].value,
                isRecDirty: true,
            });
        } else if (
            nextProps.samplingDepthList != null &&
            nextProps.samplingDepthList.length < 1 &&
            nextProps.recModel.depthId !== null
        ) {
            this._updateRec({ depthId: null });
        }
        // Reset originalRecNutrientList if moving to a new area.
        let { originalRecNutrientList } = this.state;
        if (this.props.recAreaId !== nextProps.recAreaId) {
            originalRecNutrientList =
                nextProps.recModel && nextProps.recModel.recNutrientList
                    ? [...nextProps.recModel.recNutrientList]
                    : [];
            this.setState({
                originalRecNutrientList,
            });
        }
        for (const param of nextProps.recModel.equationParameters) {
            const dataType = param.dataType ? param.dataType.toLowerCase() : "";
            if (
                ["decimal", "integer"].includes(dataType) &&
                param.value != null &&
                param.value !== ""
            ) {
                param.value = Number(param.value);
            }
        }
        const equationsFinishedLoading =
            this.props.isEquationsLoading !== nextProps.isEquationsLoading &&
            nextProps.isEquationsLoading === false;
        const equationParametersChanged = !deepEqual(
            this.props.recModel.equationParameters,
            nextProps.recModel.equationParameters
        );
        const equationFiltersChanged = !deepEqual(
            this.props.recModel.equationFilters,
            nextProps.recModel.equationFilters
        );
        const recIsDirtyChanged = this.props.recModel.isRecDirty !== nextProps.recModel.isRecDirty;
        const filteredEquationGroupListChanged =
            JSON.stringify(this.props.filteredEquationGroupList) !==
            JSON.stringify(nextProps.filteredEquationGroupList);
        const isDifferentZone = this.props.recAreaId !== nextProps.recAreaId;
        const equationParametersNotSetup =
            nextProps.recModel.equationParameters.filter((eq) => !Object.hasOwn(eq, "required"))
                .length > 0;

        if (
            !isDifferentZone && // prevents a race condition when switching zones
            (equationParametersChanged ||
                (equationFiltersChanged && equationsFinishedLoading) ||
                recIsDirtyChanged ||
                filteredEquationGroupListChanged ||
                equationsFinishedLoading ||
                (equationParametersNotSetup && equationsFinishedLoading))
        ) {
            const {
                availableProducts,
                customProducts,
                fieldGuid,
                conversionFactors,
                calculatedArea,
                productBlendPicklists,
            } = nextProps;
            const { equationGroupGuid, equationParameters, recNutrientList } = nextProps.recModel;
            const activeEquationGroup = nextProps.filteredEquationGroupList.find(
                (eg) => eg.equationGroupGuid === equationGroupGuid
            );

            if (activeEquationGroup) {
                const isReady =
                    utils.allRequiredFiltersSet(nextProps) && !nextProps.isEquationsLoading;
                const filteredEquations = utils.getFilteredEquations(
                    activeEquationGroup.equations,
                    nextProps.recModel
                );
                const { isProductBlendingActive } = this.state;

                const newRecNutrientList =
                    !isReady || isProductBlendingActive
                        ? equationFiltersChanged && !isReady
                            ? []
                            : recNutrientList
                        : filteredEquations.map((equation) => {
                              const { dryRateUnits, physicalState, productMixTypes, densityUnits } =
                                  productBlendPicklists;

                              const {
                                  defaultProduct,
                                  nutrientGuid,
                                  nutrientName,
                                  equationGuid,
                                  equationName,
                                  isSelected,
                              } = equation;
                              const isLime = nutrientName.toLowerCase() === "lime";

                              const currentRecNutrient = nextProps.recModel.recNutrientList.find(
                                  (recNutrient) => recNutrient.nutrientGuid === nutrientGuid
                              );
                              const originalRecNutrient =
                                  originalRecNutrientList.find(
                                      (recNutrient) => recNutrient.nutrientGuid === nutrientGuid
                                  ) || {};
                              const emptyRecNutrient = new recsModels.RecNutrient();
                              const currentRecNutrientProductMix = currentRecNutrient
                                  ? currentRecNutrient.recNutrientProductMix
                                  : {};

                              const { recNutrientGuid, recNutrientProductMix = {} } =
                                  originalRecNutrient;

                              const isZeroedOut =
                                  +currentRecNutrient?.recNutrientProductMix?.targetRate === 0 &&
                                  currentRecNutrient?.recNutrientProductMix?.guaranteedAnalysis !==
                                      "0-0-0" &&
                                  !nextProps.recModel.isRecDirty;

                              // After some research, the only real problem with these actually appears to be the missing guid, so fill in the guid
                              // Along with a small change on the backend to more accurately determine add from update, this got the case of
                              // HW-9835 to successfully run - CH (1 Feb 23)
                              const recNutrientProductMixGuid = recNutrientProductMix
                                  ? recNutrientProductMix.recNutrientProductMixGuid
                                  : currentRecNutrient.recNutrientGuid &&
                                    !recNutrientProductMix?.recNutrientProductMixGuid
                                  ? uuid()
                                  : null;

                              const emptyRecNutrientProductMix =
                                  new recsModels.RecNutrientProductMix();
                              const credits = !currentRecNutrient
                                  ? []
                                  : currentRecNutrient.credits.map((credit) => {
                                        return {
                                            ...credit,
                                            isCredited: true,
                                            isOriginalCredit: true,
                                        };
                                    });
                              const recNutrientParameters = !currentRecNutrient
                                  ? {}
                                  : {
                                        ...currentRecNutrient.recNutrientParameters,
                                        minimumRate: isZeroedOut
                                            ? 0
                                            : currentRecNutrient.recNutrientParameters
                                                  .minimumRate ||
                                              currentRecNutrient.averageAdjustedRecNutrientResult
                                                  .minRate ||
                                              currentRecNutrient.averageRecNutrientResult.minRate,
                                        maximumRate: isZeroedOut
                                            ? 0
                                            : currentRecNutrient.recNutrientParameters
                                                  .maximumRate ||
                                              currentRecNutrient.averageAdjustedRecNutrientResult
                                                  .maxRate ||
                                              currentRecNutrient.averageRecNutrientResult.maxRate,
                                        percentAdjustment: isZeroedOut
                                            ? 0
                                            : currentRecNutrient.recNutrientParameters
                                                  .percentAdjustment ||
                                              currentRecNutrient.averageAdjustedRecNutrientResult
                                                  .percentAdjustment ||
                                              currentRecNutrient.averageRecNutrientResult
                                                  .percentAdjustment,
                                        minimumExcludeZeros:
                                            currentRecNutrient.recNutrientParameters
                                                .minimumExcludeZeros ||
                                            (!currentRecNutrient.recNutrientParameters
                                                .minimumExcludeZeros &&
                                                !currentRecNutrient.recNutrientParameters
                                                    .minimumIncludeZeros &&
                                                !currentRecNutrient.recNutrientParameters
                                                    .zerosBelowMinimum &&
                                                !currentRecNutrient.recNutrientParameters
                                                    .zerosBelowSwitchRate),
                                    };

                              const newRecNutrient = {
                                  ...emptyRecNutrient,
                                  recNutrientGuid,
                                  ...currentRecNutrient,
                                  nutrientGuid,
                                  nutrientName,
                                  equationGuid,
                                  equationName,
                                  isSelected,
                                  recNutrientProductMix: {
                                      ...emptyRecNutrientProductMix,
                                      ...currentRecNutrientProductMix,
                                      recNutrientProductMixGuid,
                                  },
                                  credits,
                                  recNutrientParameters,
                                  recGuid: this.props.recModel.recGuid,
                              };

                              const emptyRecNutrientProduct = new recsModels.RecNutrientProduct();
                              newRecNutrient.unitGuid =
                                  newRecNutrient.unitGuid ||
                                  (defaultProduct ? defaultProduct.rateUnitGuid : null);

                              if (!newRecNutrient.recNutrientProductMix.products.length) {
                                  if (defaultProduct && defaultProduct.productGuid) {
                                      const fullProduct = availableProducts.find(
                                          (product) =>
                                              product.productGuid === defaultProduct.productGuid
                                      );

                                      const newRecNutrientProductMixProduct = {
                                          ...emptyRecNutrientProduct,
                                          ...defaultProduct,
                                          ...fullProduct,
                                          costUnitGuid:
                                              defaultProduct.costUnitGuid ||
                                              fullProduct.defaultPriceUnitGuid,
                                          limeEfficiency: isLime
                                              ? defaultProduct.limeEfficiency || 90
                                              : null,
                                      };

                                      newRecNutrient.recNutrientProductMix.limeEfficiency =
                                          newRecNutrientProductMixProduct.limeEfficiency;
                                      newRecNutrient.recNutrientProductMix.products.push(
                                          newRecNutrientProductMixProduct
                                      );
                                      newRecNutrient.recNutrientProductMix.name =
                                          newRecNutrientProductMixProduct.productName;
                                      newRecNutrient.recNutrientProductMix.targetCostUnitGuid =
                                          newRecNutrientProductMixProduct.costUnitGuid;
                                  } else if (defaultProduct && defaultProduct.customProductGuid) {
                                      const fullProduct = customProducts.find(
                                          (product) =>
                                              product.customProductGuid ===
                                              defaultProduct.customProductGuid
                                      );
                                      const newRecNutrientProductMixProduct = {
                                          ...emptyRecNutrientProduct,
                                          ...defaultProduct,
                                          ...fullProduct,
                                      };
                                      newRecNutrient.recNutrientProductMix.products.push(
                                          newRecNutrientProductMixProduct
                                      );

                                      if (!newRecNutrient.recNutrientProductMix.name) {
                                          newRecNutrient.recNutrientProductMix.name = `${newRecNutrientProductMixProduct.customProductType} - ${newRecNutrientProductMixProduct.customProductName}`;
                                      }
                                  } else if (defaultProduct && defaultProduct.productBlendGuid) {
                                      newRecNutrient.productBlendGuid =
                                          defaultProduct.productBlendGuid;
                                      newRecNutrient.productBlendName =
                                          defaultProduct.productBlendName;
                                      newRecNutrient.recNutrientParameters.minimumIncludeZeros =
                                          defaultProduct.minimumIncludeZeros;
                                      newRecNutrient.recNutrientParameters.minimumExcludeZeros =
                                          defaultProduct.minimumExcludeZeros;
                                      newRecNutrient.recNutrientParameters.zerosBelowMinimum =
                                          defaultProduct.zerosBelowMinimum;
                                      newRecNutrient.recNutrientParameters.zerosBelowSwitchRate =
                                          defaultProduct.zerosBelowSwitchRate;
                                      newRecNutrient.recNutrientParameters.limeEfficiency =
                                          defaultProduct.limeEfficiency;
                                      newRecNutrient.recNutrientProductMix = {
                                          ...newRecNutrient.recNutrientProductMix,
                                          ...defaultProduct.productBlend,
                                          limeEfficiency: defaultProduct.limeEfficiency,
                                      };
                                      newRecNutrient.recNutrientProductMix.name =
                                          defaultProduct.productBlendName;
                                      newRecNutrient.recNutrientProductMix.hasSavedBlend = true;

                                      const props = {
                                          availableProducts,
                                          conversionFactors,
                                          calculatedArea,
                                          productBlendPicklists,
                                      };

                                      newRecNutrient.recNutrientProductMix.products =
                                          newRecNutrient.recNutrientProductMix.products.map((p) => {
                                              const fullProduct = availableProducts.find(
                                                  (fullProduct) =>
                                                      fullProduct.productGuid === p.productGuid
                                              );
                                              const isLimeProduct = Boolean(
                                                  isLime &&
                                                      (
                                                          fullProduct.nutrientList ||
                                                          fullProduct.nutrients ||
                                                          []
                                                      ).some(
                                                          (nutrient) =>
                                                              nutrient.nutrientGuid === nutrientGuid
                                                      )
                                              );
                                              return blending.initializeProduct(
                                                  newRecNutrient.recNutrientProductMix,
                                                  {
                                                      ...p,
                                                      rate:
                                                          fullProduct.productParentType ===
                                                          "Service"
                                                              ? null
                                                              : p.rate,
                                                      limeEfficiency: isLimeProduct
                                                          ? defaultProduct.limeEfficiency
                                                          : null,
                                                  },
                                                  props
                                              );
                                          });
                                      const targetCostUnitGuid = blending.getEffectiveCostUnitGuid(
                                          newRecNutrient.recNutrientProductMix,
                                          newRecNutrient.recNutrientProductMix.products
                                      );
                                      newRecNutrient.recNutrientProductMix.targetRate =
                                          nextProps.recModel.isFilterChanged &&
                                          nextProps.recModel.recGuid
                                              ? 0
                                              : blending.getTargetRateFromProducts(
                                                    defaultProduct.rateUnitGuid,
                                                    newRecNutrient.recNutrientProductMix.products,
                                                    props
                                                );
                                      newRecNutrient.recNutrientProductMix.targetRateUnit =
                                          defaultProduct.rateUnitName;
                                      newRecNutrient.recNutrientProductMix.targetRateUnitGuid =
                                          defaultProduct.rateUnitGuid;
                                      newRecNutrient.recNutrientProductMix.targetCostUnitGuid =
                                          targetCostUnitGuid;
                                  }
                                  // The sticky min/max will be in the same place for all of the default product scenarios
                                  if (defaultProduct) {
                                      newRecNutrient.recNutrientParameters.minimumLock =
                                          defaultProduct.minimumLock;
                                      newRecNutrient.recNutrientParameters.maximumLock =
                                          defaultProduct.maximumLock;
                                      newRecNutrient.recNutrientParameters.minimumRate =
                                          defaultProduct.minimumRate;
                                      newRecNutrient.recNutrientParameters.maximumRate =
                                          defaultProduct.maximumRate;
                                      newRecNutrient.recNutrientParameters.switchRate =
                                          defaultProduct.switchRate;
                                      newRecNutrient.recNutrientParameters.minimumExcludeZeros =
                                          defaultProduct.minimumExcludeZeros;
                                      newRecNutrient.recNutrientParameters.minimumIncludeZeros =
                                          defaultProduct.minimumIncludeZeros;
                                      newRecNutrient.recNutrientParameters.zerosBelowMinimum =
                                          defaultProduct.zerosBelowMinimum;
                                      newRecNutrient.recNutrientParameters.zerosBelowSwitchRate =
                                          defaultProduct.zerosBelowSwitchRate;
                                      if (
                                          !defaultProduct.minimumExcludeZeros &&
                                          !defaultProduct.minimumIncludeZeros &&
                                          !defaultProduct.zerosBelowMinimum &&
                                          !defaultProduct.zerosBelowSwitchRate
                                      ) {
                                          newRecNutrient.recNutrientParameters.minimumExcludeZeros = true;
                                      }
                                  }
                              } else {
                                  const fieldRate =
                                      +newRecNutrient.averageAdjustedRecNutrientResult
                                          .productRate === 0
                                          ? 0
                                          : Number(
                                                newRecNutrient.averageAdjustedRecNutrientResult
                                                    .productRate
                                            ) ||
                                            Number(
                                                newRecNutrient.averageRecNutrientResult.productRate
                                            );

                                  newRecNutrient.recNutrientProductMix.targetRate =
                                      fieldRate || newRecNutrient.recNutrientProductMix.targetRate;
                                  newRecNutrient.recNutrientProductMix.products =
                                      newRecNutrient.recNutrientProductMix.products.map(
                                          (product) => {
                                              const fullProduct = availableProducts.find(
                                                  (fullProduct) =>
                                                      fullProduct.productGuid ===
                                                      product.productGuid
                                              );

                                              const props = {
                                                  availableProducts,
                                                  conversionFactors,
                                                  calculatedArea,
                                                  productBlendPicklists,
                                              };
                                              const initializedProduct = blending.initializeProduct(
                                                  newRecNutrient.recNutrientProductMix,
                                                  product,
                                                  props
                                              );
                                              const isServiceProduct =
                                                  fullProduct?.productParentType === "Service";
                                              return {
                                                  ...product,
                                                  ...fullProduct,
                                                  rate:
                                                      newRecNutrient.recNutrientProductMix.products
                                                          .length === 1
                                                          ? newRecNutrient.recNutrientProductMix
                                                                .targetRate
                                                          : isServiceProduct
                                                          ? initializedProduct.rate
                                                          : 0,
                                                  totalProduct: null,
                                                  limeEfficiency: isLime
                                                      ? newRecNutrient.recNutrientProductMix
                                                            .limeEfficiency ||
                                                        defaultProduct?.limeEfficiency ||
                                                        90
                                                      : null,
                                              };
                                          }
                                      );
                              }

                              newRecNutrient.recNutrientProductMix.density =
                                  newRecNutrient.recNutrientProductMix.products.length === 1
                                      ? newRecNutrient.recNutrientProductMix.products[0].density ||
                                        newRecNutrient.recNutrientProductMix.density
                                      : newRecNutrient.recNutrientProductMix.density;

                              if (!newRecNutrient.recNutrientProductMix.targetRateUnitGuid) {
                                  newRecNutrient.recNutrientProductMix.targetRateUnitGuid =
                                      newRecNutrient.unitGuid;
                                  newRecNutrient.recNutrientProductMix.targetRateUnit =
                                      newRecNutrient.unitName;
                              }

                              const isDry = dryRateUnits.find(
                                  (unit) => unit.value === newRecNutrient.unitGuid
                              );
                              const physicalStateUnit = physicalState.find((unit) =>
                                  isDry
                                      ? unit.label.toLowerCase() === PhysicalStates.DRY
                                      : unit.label.toLowerCase() === PhysicalStates.LIQUID
                              );
                              if (physicalStateUnit) {
                                  newRecNutrient.recNutrientProductMix.physicalStateGuid =
                                      physicalStateUnit.value;
                                  newRecNutrient.recNutrientProductMix.physicalState =
                                      physicalStateUnit.label;
                              }

                              const productMixType =
                                  newRecNutrient.recNutrientProductMix.products.length > 0
                                      ? newRecNutrient.recNutrientProductMix.products[0].productGuid
                                          ? productMixTypes.find(
                                                (mixType) =>
                                                    mixType.label.toLowerCase() ===
                                                    ProductMixTypes.FERTILIZER
                                            )
                                          : newRecNutrient.recNutrientProductMix.products[0]
                                                .customProductGuid
                                          ? productMixTypes.find(
                                                (mixType) =>
                                                    mixType.label.toLowerCase() ===
                                                    ProductMixTypes.MANURE
                                            )
                                          : null
                                      : null;

                              if (productMixType) {
                                  newRecNutrient.recNutrientProductMix.productMixTypeGuid =
                                      productMixType.value;
                                  newRecNutrient.recNutrientProductMix.productMixType =
                                      productMixType.label;
                              }

                              const densityUnit = !physicalStateUnit
                                  ? null
                                  : densityUnits.find((unit) =>
                                        physicalStateUnit.label.toLowerCase() === PhysicalStates.DRY
                                            ? unit.label === "lb/ft3"
                                            : unit.label === "lb/gal"
                                    );
                              if (densityUnit) {
                                  newRecNutrient.recNutrientProductMix.densityUnitGuid =
                                      densityUnit.value;
                                  newRecNutrient.recNutrientProductMix.densityUnit =
                                      densityUnit.label;
                              }

                              newRecNutrient.recNutrientProductMix.nutrients = [];
                              newRecNutrient.recNutrientProductMix.products.forEach((product) => {
                                  const fullProduct = product.productGuid
                                      ? availableProducts.find(
                                            (fProduct) =>
                                                fProduct.productGuid === product.productGuid
                                        )
                                      : product.customProductGuid
                                      ? customProducts.find(
                                            (cProduct) =>
                                                cProduct.customProductGuid ===
                                                product.customProductGuid
                                        )
                                      : null;
                                  if (fullProduct) {
                                      const { nutrientList, nutrients } = fullProduct;
                                      const workingList = nutrientList || nutrients || [];
                                      workingList.forEach((productNutrient) => {
                                          let nutrientIndex = -1;

                                          const existingNutrient =
                                              newRecNutrient.recNutrientProductMix.nutrients.find(
                                                  (nutrient, index) => {
                                                      const isMatch =
                                                          nutrient.nutrientGuid ===
                                                          productNutrient.nutrientGuid;
                                                      if (isMatch) {
                                                          nutrientIndex = index;
                                                      }
                                                      return isMatch;
                                                  }
                                              );

                                          const percentOfMix =
                                              newRecNutrient.recNutrientProductMix.products
                                                  .length === 1
                                                  ? 1
                                                  : product.percentOfMix
                                                  ? product.percentOfMix
                                                  : product.rate &&
                                                    newRecNutrient.recNutrientProductMix.targetRate
                                                  ? product.rate /
                                                    newRecNutrient.recNutrientProductMix.targetRate
                                                  : 1;

                                          const newNutrient = {
                                              ...existingNutrient,
                                              ...productNutrient,
                                              nameKey: productNutrient.name
                                                  .toLowerCase()
                                                  .replace(/ /g, ""),
                                              nutrientPercent: existingNutrient
                                                  ? existingNutrient.nutrientPercent +
                                                    productNutrient.percent * percentOfMix
                                                  : productNutrient.percent * percentOfMix,
                                          };
                                          if (nutrientIndex === -1) {
                                              newRecNutrient.recNutrientProductMix.nutrients.push(
                                                  newNutrient
                                              );
                                          } else {
                                              newRecNutrient.recNutrientProductMix.nutrients.splice(
                                                  nutrientIndex,
                                                  1,
                                                  newNutrient
                                              );
                                          }
                                      });
                                  }
                              });

                              newRecNutrient.recNutrientProductMix.guaranteedAnalysis =
                                  blending.getGuaranteedAnalysis(
                                      newRecNutrient.recNutrientProductMix.nutrients
                                  );

                              if (
                                  isZeroedOut &&
                                  currentRecNutrient &&
                                  currentRecNutrient?.recNutrientProductMix
                              ) {
                                  //newRecNutrient.recNutrientProductMix.nutrients = currentRecNutrient.recNutrientProductMix.nutrients;
                                  newRecNutrient.recNutrientProductMix.density =
                                      currentRecNutrient.recNutrientProductMix.density;
                                  newRecNutrient.recNutrientProductMix.guaranteedAnalysis =
                                      currentRecNutrient.recNutrientProductMix.guaranteedAnalysis;
                              }

                              return newRecNutrient;
                          });

                const newRequiredParameters = [];
                const newOptionalParameters = [];
                filteredEquations.forEach((equation) => {
                    const { requiredUserParameters, optionalUserParameters } = equation;

                    requiredUserParameters.forEach((param) => {
                        const existingParameter = equationParameters.find(
                            (eqParam) =>
                                param.name === eqParam.name ||
                                (eqParam.analysisLayerGuid && param.name === NORMALIZED_YIELD) ||
                                (eqParam.managementAreaAnalysisLayerGuids &&
                                    param.name === MANAGEMENT_AREA)
                        );
                        const isUsedAlready = newRequiredParameters.some(
                            (eqParam) => param.name === eqParam.name
                        );
                        if (!existingParameter && !isUsedAlready) {
                            newRequiredParameters.push({
                                ...param,
                                value:
                                    param.name === NORMALIZED_YIELD ||
                                    param.name === MANAGEMENT_AREA
                                        ? null
                                        : param.defaultValue,
                                required: true,
                            });
                        } else if (!isUsedAlready) {
                            if (param.name === NORMALIZED_YIELD) {
                                newRequiredParameters.push({
                                    ...param,
                                    analysisLayerGuid: existingParameter
                                        ? existingParameter.analysisLayerGuid
                                        : null,
                                    value: existingParameter
                                        ? existingParameter.analysisLayerGuid
                                        : null,
                                    required: true,
                                });
                            } else if (param.name === MANAGEMENT_AREA) {
                                newRequiredParameters.push({
                                    ...param,
                                    managementAreaAnalysisLayerGuids: existingParameter
                                        ? existingParameter.managementAreaAnalysisLayerGuids
                                        : null,
                                    value:
                                        existingParameter &&
                                        existingParameter.managementAreaAnalysisLayerGuids
                                            ? existingParameter.managementAreaAnalysisLayerGuids.join(
                                                  ","
                                              )
                                            : null,
                                    required: true,
                                });
                            } else {
                                const defaultValueChange =
                                    param.defaultValue &&
                                    existingParameter.defaultValue &&
                                    param.defaultValue !== existingParameter.defaultValue;
                                newRequiredParameters.push({
                                    ...param,
                                    value:
                                        existingParameter && !defaultValueChange
                                            ? existingParameter.value
                                            : param.defaultValue,
                                    required: true,
                                });
                            }
                        }
                    });
                    optionalUserParameters.forEach((param) => {
                        const existingParameter = equationParameters.find(
                            (eqParam) => param.name === eqParam.name
                        );
                        const isUsedAlready = newOptionalParameters.some(
                            (eqParam) => param.name === eqParam.name
                        );
                        if (!existingParameter && !isUsedAlready) {
                            newOptionalParameters.push({
                                ...param,
                                value: param.defaultValue,
                                required: false,
                            });
                        } else if (!isUsedAlready) {
                            newOptionalParameters.push({
                                ...param,
                                value: existingParameter
                                    ? existingParameter.value
                                    : param.defaultValue,
                                required: false,
                            });
                        }
                    });
                });

                const newEquationParameters = [...newRequiredParameters, ...newOptionalParameters];
                const isEquationRunEnabled = utils.allRequiredFieldsSet(nextProps);

                const managementAreaParameter = newEquationParameters.find(
                    (param) => param.name === MANAGEMENT_AREA
                );
                const { managementAreaLayerOptions } = nextProps.equationFilterLists;

                if (managementAreaParameter != null) {
                    if (fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID) {
                        managementAreaParameter.required = false;
                    } else if (
                        managementAreaParameter.value == null &&
                        managementAreaLayerOptions.length === 1
                    ) {
                        managementAreaParameter.managementAreaAnalysisLayerGuids = [
                            managementAreaLayerOptions[0].value,
                        ];
                        managementAreaParameter.value = managementAreaLayerOptions[0].value;
                    }
                }

                const isRxFileImport =
                    activeEquationGroup &&
                    activeEquationGroup.groupName === rxFileImportModels.RX_FILE_IMPORT_TYPE_NAME;

                const updateProps = {
                    equationParameters: newEquationParameters,
                    isEquationRunEnabled,
                };
                if (
                    isRxFileImport &&
                    recNutrientList.length !== newRecNutrientList.length &&
                    nextProps.recModel.isAnyEquationRun
                ) {
                    const existingNutrients = new Set(recNutrientList.map((n) => n.equationName));
                    updateProps.recNutrientList = newRecNutrientList.filter((n) =>
                        existingNutrients.has(n.equationName)
                    );
                } else {
                    updateProps.recNutrientList = newRecNutrientList;
                }

                this._updateRec(updateProps);
                if (isEquationRunEnabled && newRecNutrientList.length > 0) {
                    this.setState({
                        isNutrientsExpanded: true,
                    });
                }
            }
        }
        if (
            utils.allRequiredFieldsSet(nextProps) &&
            !nextProps.recModel.isIncluded &&
            nextProps.recModel.isEquationRunEnabled &&
            (!nextProps.recModel.recGuid || nextProps.recModel.isRecDirty) &&
            nextProps.recModel.recNutrientList.length === 0
        ) {
            this._updateRec({
                isIncluded: true,
            });
        }
        if (
            utils.allRequiredFieldsSet(nextProps) &&
            (!nextProps.recModel.recGuid || nextProps.recModel.isRecDirty) &&
            (!nextProps.recModel.isRecDirty || !nextProps.recModel.isEquationRunEnabled)
        ) {
            this._updateRec({
                isRecDirty: true,
                isEquationRunEnabled: true,
            });
        } else if (
            !utils.allRequiredFieldsSet(nextProps) &&
            nextProps.recModel.isEquationRunEnabled
        ) {
            this._updateRec({
                isEquationRunEnabled: false,
            });
        }
    }

    _buildFilterInputRows() {
        const {
            recModel,
            equationFilterLists,
            equationFilterRequired,
            filteredEquationGroupList,
            samplingDepthList,
        } = this.props;
        const { equationFilters, equationParameters } = recModel;
        const { formatMessage } = this.props.intl;

        const {
            cropListOptions,
            cropClassListOptions,
            cropPurposeListOptions,
            previousCropListOptions,
            nextCropListOptions,
            soilTextureListOptions,
            tillageMethodListOptions,
            applicationTimingListOptions,
            applicationMethodListOptions,
            productListOptions,
            incorporationDepthListOptions,
            buildFactorListOptions,
            targetpHListOptions,
            recOptionListOptions,
        } = equationFilterLists;

        const {
            cropRequired,
            cropClassRequired,
            cropPurposeRequired,
            previousCropRequired,
            nextCropRequired,
            soilTextureRequired,
            tillageMethodRequired,
            applicationTimingRequired,
            applicationMethodRequired,
            productRequired,
            incorporationDepthRequired,
            buildFactorRequired,
            targetpHRequired,
            recOptionRequired,
        } = equationFilterRequired;

        const cropFilterApplied = equationFilters.cropGuid || cropListOptions.length === 0;
        const activeEquationGroup = filteredEquationGroupList.find(
            (eg) => eg.equationGroupGuid === recModel.equationGroupGuid
        );
        const {
            cropListVisible,
            cropClassListVisible,
            cropPurposeListVisible,
            previousCropListVisible,
            nextCropListVisible,
            soilTextureListVisible,
            tillageMethodListVisible,
            applicationTimingListVisible,
            applicationMethodListVisible,
            productListVisible,
            incorporationDepthListVisible,
            buildFactorListVisible,
            targetpHListVisible,
            recOptionListVisible,
        } = utils.getEquationFilterVisible(
            equationFilterLists,
            equationFilterRequired,
            cropFilterApplied
        );
        const filterInputs = [];

        if (samplingDepthList != null && samplingDepthList.length > 1) {
            filterInputs.push(
                <SelectInput
                    required={true}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={false}
                    onChange={(v) => this._updateRec({ depthId: v, isRecDirty: true })}
                    options={samplingDepthList}
                    placeholderText={formatMessage(messages.samplingDepthText)}
                    value={recModel.depthId}
                />
            );
        }

        if (cropListVisible) {
            filterInputs.push(
                <SelectInput
                    required={cropRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!cropRequired}
                    onChange={(v) => this._updateEquationFilters({ cropGuid: v })}
                    options={cropListOptions}
                    placeholderText={formatMessage(messages.cropText)}
                    value={equationFilters.cropGuid}
                />
            );
        }
        if (cropClassListVisible) {
            filterInputs.push(
                <SelectInput
                    required={cropClassRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!cropClassRequired}
                    onChange={(v) => this._updateEquationFilters({ cropClassNameGuid: v })}
                    options={cropClassListOptions}
                    placeholderText={formatMessage(messages.cropClassText)}
                    value={equationFilters.cropClassNameGuid}
                />
            );
        }
        if (cropPurposeListVisible) {
            filterInputs.push(
                <SelectInput
                    required={cropPurposeRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!cropPurposeRequired}
                    onChange={(v) => this._updateEquationFilters({ cropPurposeGuid: v })}
                    options={cropPurposeListOptions}
                    placeholderText={formatMessage(messages.cropPurposeText)}
                    value={equationFilters.cropPurposeGuid}
                />
            );
        }
        if (previousCropListVisible) {
            filterInputs.push(
                <SelectInput
                    required={previousCropRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!previousCropRequired}
                    onChange={(v) => this._updateEquationFilters({ previousCropGuid: v })}
                    options={previousCropListOptions}
                    placeholderText={formatMessage(messages.previousCropText)}
                    value={equationFilters.previousCropGuid}
                />
            );
        }
        if (nextCropListVisible) {
            filterInputs.push(
                <SelectInput
                    required={nextCropRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!nextCropRequired}
                    onChange={(v) => this._updateEquationFilters({ nextCropGuid: v })}
                    options={nextCropListOptions}
                    placeholderText={formatMessage(messages.nextCropText)}
                    value={equationFilters.nextCropGuid}
                />
            );
        }
        if (soilTextureListVisible) {
            filterInputs.push(
                <SelectInput
                    required={soilTextureRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!soilTextureRequired}
                    onChange={(v) => this._updateEquationFilters({ soilTextureGuid: v })}
                    options={soilTextureListOptions}
                    placeholderText={formatMessage(messages.soilTextureText)}
                    value={equationFilters.soilTextureGuid}
                />
            );
        }
        if (tillageMethodListVisible) {
            filterInputs.push(
                <SelectInput
                    required={tillageMethodRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!tillageMethodRequired}
                    onChange={(v) => this._updateEquationFilters({ tillageMethodGuid: v })}
                    options={tillageMethodListOptions}
                    placeholderText={formatMessage(messages.tillageMethodText)}
                    value={equationFilters.tillageMethodGuid}
                />
            );
        }
        if (applicationTimingListVisible) {
            filterInputs.push(
                <SelectInput
                    required={applicationTimingRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!applicationTimingRequired}
                    onChange={(v) =>
                        this._updateEquationFilters({
                            applicationTimingGuid: v,
                        })
                    }
                    options={applicationTimingListOptions}
                    placeholderText={formatMessage(messages.applicationTimingText)}
                    value={equationFilters.applicationTimingGuid}
                />
            );
        }
        if (applicationMethodListVisible) {
            filterInputs.push(
                <SelectInput
                    required={applicationMethodRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!applicationMethodRequired}
                    onChange={(v) =>
                        this._updateEquationFilters({
                            applicationMethodGuid: v,
                        })
                    }
                    options={applicationMethodListOptions}
                    placeholderText={formatMessage(messages.applicationMethodText)}
                    value={equationFilters.applicationMethodGuid}
                />
            );
        }
        if (productListVisible) {
            filterInputs.push(
                <SelectInput
                    required={productRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!productRequired}
                    onChange={(v) => this._updateEquationFilters({ productGuid: v })}
                    options={productListOptions}
                    placeholderText={formatMessage(messages.productText)}
                    value={equationFilters.productGuid}
                />
            );
        }
        if (incorporationDepthListVisible) {
            filterInputs.push(
                <SelectInput
                    required={incorporationDepthRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!incorporationDepthRequired}
                    onChange={(v) =>
                        this._updateEquationFilters({
                            incorporationDepthGuid: v,
                        })
                    }
                    options={incorporationDepthListOptions}
                    placeholderText={formatMessage(messages.incorporationDepthText)}
                    value={equationFilters.incorporationDepthGuid}
                />
            );
        }
        if (buildFactorListVisible) {
            filterInputs.push(
                <SelectInput
                    required={buildFactorRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!buildFactorRequired}
                    onChange={(v) => this._updateEquationFilters({ buildFactorGuid: v })}
                    options={buildFactorListOptions}
                    placeholderText={formatMessage(messages.buildFactorText)}
                    value={equationFilters.buildFactorGuid}
                />
            );
        }
        if (targetpHListVisible) {
            filterInputs.push(
                <SelectInput
                    required={targetpHRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!targetpHRequired}
                    onChange={(v) => this._updateEquationFilters({ targetpHGuid: v })}
                    options={targetpHListOptions}
                    placeholderText={formatMessage(messages.targetpHText)}
                    value={equationFilters.targetpHGuid}
                />
            );
        }
        if (recOptionListVisible) {
            filterInputs.push(
                <SelectInput
                    required={recOptionRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!recOptionRequired}
                    onChange={(v) => this._updateEquationFilters({ recOptionGuid: v })}
                    options={recOptionListOptions}
                    placeholderText={formatMessage(messages.recOptionText)}
                    value={equationFilters.recOptionGuid}
                />
            );
        }

        if (cropFilterApplied && activeEquationGroup) {
            equationParameters.forEach((param) => {
                if (Object.hasOwn(param, "required")) {
                    filterInputs.push(this._buildParameterInput(param));
                }
            });
        }
        const filterInputRows = [];
        for (let i = 0; i < filterInputs.length; i += 2) {
            const isHalf = !filterInputs[i + 1];
            filterInputRows.push(
                <div
                    key={`filter-row-${filterInputRows.length}`}
                    className={classnames("input-row", { half: isHalf })}
                >
                    {!filterInputs[i] ? null : filterInputs[i]}
                    {!filterInputs[i + 1] ? null : filterInputs[i + 1]}
                </div>
            );
        }
        return filterInputRows;
    }

    _buildParameterInput(param) {
        const { equationFilterLists, fieldGuid } = this.props;
        const { managementAreaLayerOptions, normalizedYieldLayerOptions } = equationFilterLists;
        const { formatMessage } = this.props.intl;

        const { dataType, name } = param;
        const inputType = dataType ? dataType.toLowerCase() : name ? name.toLowerCase() : "";

        switch (inputType) {
            case "decimal":
                return (
                    <NumericInput
                        placeholderText={param.label}
                        value={param.value != null && param.value !== "" ? Number(param.value) : ""}
                        required={param.required}
                        precision={Number(param.precision)}
                        scale={Number(param.decimalPlaces)}
                        onChange={(strVal, formattedVal, numVal) =>
                            this._updateEquationParameter(param.name, numVal)
                        }
                    />
                );
            case "yesno":
                return (
                    <Checkbox
                        onChange={(evt, v) => this._updateEquationParameter(param.name, v)}
                        value={
                            param.value == null || Number(param.value) === 0
                                ? false
                                : Boolean(param.value)
                        }
                        label={param.label}
                    />
                );
            case "integer":
                return (
                    <NumericInput
                        placeholderText={param.label}
                        value={param.value != null && param.value !== "" ? Number(param.value) : ""}
                        required={param.required}
                        precision={Number(param.precision)}
                        scale={0}
                        onChange={(strVal, formattedVal, numVal) =>
                            this._updateEquationParameter(param.name, numVal)
                        }
                    />
                );
            case MANAGEMENT_AREA.toLowerCase():
                return fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID ? (
                    <SelectInputMulti
                        required={true}
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        onChange={(v) => this._updateEquationParameter(param.name, v)}
                        options={managementAreaLayerOptions}
                        placeholder={formatMessage(messages.managementAreaText)}
                        value={param.managementAreaAnalysisLayerGuids}
                    />
                ) : null;
            case NORMALIZED_YIELD.toLowerCase():
                return (
                    <SelectInput
                        required={true}
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        onChange={(v) => this._updateEquationParameter(param.name, v)}
                        options={normalizedYieldLayerOptions}
                        placeholderText={formatMessage(messages.normalizedYieldText)}
                        value={param.value}
                    />
                );
            default:
                return null;
        }
    }

    _getMenuItems(index) {
        return [
            {
                label: "Edit Product Rec",
                action: () => {
                    this.onEditProductMix(index);
                },
            },
        ].map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    _toggleNutrientSurface(nutrientGuid) {
        this.props.setActiveNutrient(
            nutrientGuid === this.props.activeNutrientGuid ? null : nutrientGuid
        );
    }

    _getRateUnitName(rateUnitGuid) {
        const { dryRateUnits, liquidRateUnits } = this.props.productBlendPicklists;
        const allRateUnits = [...dryRateUnits, ...liquidRateUnits];
        return allRateUnits.find((r) => r.value === rateUnitGuid)?.label;
    }

    _buildNutrientProductDisplay() {
        const { activeNutrientGuid, batchRecDetailsForEdit, recModel, recNutrientsInProcessing } =
            this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        const isBatchRecEdit = batchRecDetailsForEdit && batchRecDetailsForEdit.length > 0;

        return (
            <div className="nutrient-product-display">
                {recModel.recNutrientList.map((recNutrient, index) => {
                    const hasProductMix = recNutrient.recNutrientProductMix != null;
                    const missingEquationAttributesList = recNutrient.missingEquationAttributes.map(
                        (missingAttribute) => missingAttribute.description
                    );
                    const missingEquationAttributeMessage =
                        missingEquationAttributesList.length > 0
                            ? `${recNutrient.equationName}:  ${missingEquationAttributesList.join(
                                  ", "
                              )}`
                            : "";
                    let showErrorBox = false;
                    if (missingEquationAttributeMessage) {
                        showErrorBox = true;
                    }
                    const editBlockedByFailure =
                        ["rec failed"].includes(recModel.recStatus.toLowerCase()) ||
                        (!recNutrient.equationSuccess && missingEquationAttributeMessage);
                    return (
                        <div key={`nutrient-product-${index}`} className="nutrient-product">
                            <div className="product-info-section">
                                {!["complete", "exported"].includes(
                                    recModel.recStatus.toLowerCase()
                                ) || isBatchRecEdit ? null : (
                                    <div className="product-surface-radio">
                                        <RadioButton
                                            value={recNutrient.nutrientGuid}
                                            checked={
                                                activeNutrientGuid === recNutrient.nutrientGuid
                                            }
                                            onChange={(evt, value) =>
                                                this._toggleNutrientSurface(value)
                                            }
                                            ternary={true}
                                        />
                                    </div>
                                )}
                                <div
                                    className={classnames("nutrient-name", {
                                        "no-surface": !recModel.recGuid,
                                    })}
                                >
                                    {recNutrient.equationName}
                                </div>
                                {!recNutrient.averageAdjustedRecNutrientResult ||
                                !recNutrient.averageAdjustedRecNutrientResult
                                    .appliedNutrientRate ? null : (
                                    <div className="nutrient-rate">
                                        {`Rec: ${recNutrient.averageAdjustedRecNutrientResult.appliedNutrientRate} lb/ac`}
                                    </div>
                                )}

                                {recNutrient.productBlendName ||
                                (hasProductMix &&
                                    recNutrient.recNutrientProductMix &&
                                    (recNutrient.recNutrientProductMix.name ||
                                        recNutrient.recNutrientProductMix.products.length >
                                            0)) ? null : (
                                    <NoLink
                                        className="add-default-nolink"
                                        label={
                                            !isBatchRecEdit
                                                ? formatMessage(messages.setDefaultText)
                                                : formatMessage(messages.setProductText)
                                        }
                                        onClick={() => this.onEditProductMix(index)}
                                    />
                                )}
                            </div>

                            <div
                                className={classnames("product-blend-section", {
                                    "no-blend-name":
                                        !hasProductMix ||
                                        (!recNutrient.recNutrientProductMix.name &&
                                            !recNutrient.productBlendName) ||
                                        (recNutrient.recNutrientProductMix.products.length === 1 &&
                                            recNutrient.productBlendName ===
                                                recNutrient.recNutrientProductMix.products[0]
                                                    .productName) ||
                                        (recNutrient.recNutrientProductMix.products.length === 1 &&
                                            recNutrient.recNutrientProductMix.products[0]
                                                .customProductName),
                                })}
                            >
                                {!hasProductMix ||
                                (recNutrient.recNutrientProductMix.products.length === 1 &&
                                    recNutrient.productBlendName ===
                                        recNutrient.recNutrientProductMix.products[0].productName &&
                                    !recNutrient.recNutrientProductMix.products[0]
                                        .customProductName) ? null : (
                                    <div className="product-blend">
                                        {!hasProductMix ||
                                        (recNutrient.recNutrientProductMix.products.length === 1 &&
                                            (recNutrient.recNutrientProductMix.name ===
                                                recNutrient.recNutrientProductMix.products[0]
                                                    .productName ||
                                                recNutrient.recNutrientProductMix.products[0]
                                                    .customProductName)) ? null : (
                                            <div className="product-mix-name">
                                                {hasProductMix
                                                    ? recNutrient.recNutrientProductMix.name
                                                    : recNutrient.productBlendName}
                                            </div>
                                        )}
                                        <div className="product-mix-products">
                                            {!hasProductMix
                                                ? null
                                                : recNutrient.recNutrientProductMix.products.map(
                                                      (product, pIndex) => {
                                                          return product.customProductName ? (
                                                              <div
                                                                  key={`product-${index}-${pIndex}`}
                                                                  className="custom-product-name"
                                                              >
                                                                  {
                                                                      recNutrient
                                                                          .recNutrientProductMix
                                                                          .name
                                                                  }
                                                              </div>
                                                          ) : (
                                                              <div
                                                                  key={`product-${index}-${pIndex}`}
                                                                  className="product-name"
                                                              >
                                                                  {product.productName}
                                                              </div>
                                                          );
                                                      }
                                                  )}
                                        </div>
                                    </div>
                                )}

                                {!recModel.recGuid ||
                                recModel.isFilterChanged ||
                                !hasProductMix ||
                                (hasProductMix &&
                                    recNutrient.recNutrientProductMix.products.length ===
                                        0) ? null : (
                                    <div className="product-rec-value">
                                        {recNutrient.recNutrientGuid &&
                                        recNutrientsInProcessing.includes(
                                            recNutrient.recNutrientGuid
                                        ) ? (
                                            <div className="status import-status-processing">
                                                {formatMessage(messages.processingText)}
                                            </div>
                                        ) : (
                                            `${formatNumber(
                                                blending.isNumeric(
                                                    recNutrient.recNutrientProductMix.targetRate
                                                )
                                                    ? recNutrient.recNutrientProductMix.targetRate
                                                    : recNutrient.recNutrientParameters
                                                          .minimumIncludeZeros
                                                    ? recNutrient.averageAdjustedRecNutrientResult
                                                          .productRate ||
                                                      recNutrient.averageRecNutrientResult
                                                          .productRate
                                                    : recNutrient.averageAdjustedRecNutrientResult
                                                          .productRate ||
                                                      recNutrient.averageRecNutrientResult
                                                          .productRate ||
                                                      0,
                                                intlConfig.numberFormatOptions
                                            )} ${
                                                recNutrient.recNutrientProductMix.targetRateUnit ||
                                                recNutrient.unitName ||
                                                this._getRateUnitName(recNutrient.unitGuid)
                                            }`
                                        )}
                                    </div>
                                )}

                                {recNutrient.isRecAdjustmentProcessing ||
                                editBlockedByFailure ||
                                (recNutrient.recNutrientGuid &&
                                    recNutrientsInProcessing.includes(
                                        recNutrient.recNutrientGuid
                                    )) ||
                                !(
                                    recNutrient.productBlendGuid ||
                                    (hasProductMix &&
                                        (recNutrient.recNutrientProductMix.name ||
                                            recNutrient.recNutrientProductMix.products.length > 0))
                                ) ? null : (
                                    <div className="mix-context-menu">
                                        <Menu
                                            className="context-menu"
                                            isDotMenu={true}
                                            getMenuItems={() => this._getMenuItems(index)}
                                        />
                                    </div>
                                )}
                            </div>

                            {!showErrorBox ? null : (
                                <div
                                    className={classnames(
                                        "missing-equation-attribute-warning-message",
                                        {
                                            "missing-equation-attribute-error-message":
                                                !recNutrient.equationSuccess,
                                        }
                                    )}
                                >
                                    {`${formatMessage(
                                        messages.missingTestResultsText
                                    )} ${missingEquationAttributeMessage}`}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { formatMessage } = this.props.intl;
        const {
            batchRecDetailsForEdit,
            classBreaksCount,
            classBreaksHaveOnlyOneZone,
            filteredEquationGroupList,
            recModel,
            recDetails,
            fieldGuid,
            calculatedArea,
            isBatch,
            isEquationsLoading,
            isLoadingStatus,
        } = this.props;

        // TODO: use portal to render into RecEventZoneInfo after React 16 upgrade
        //       https://codesandbox.io/s/82kmnvk0vj
        const isIncludedCbStyle = {
            position: "relative",
            left: "76%",
            marginTop: -35,
            top:
                classBreaksCount === 0
                    ? 0
                    : classBreaksCount * -28 - (classBreaksHaveOnlyOneZone ? 2 : 15),
        };

        const activeEquationGroup = filteredEquationGroupList.find(
            (equationGroup) => equationGroup.equationGroupGuid === recModel.equationGroupGuid
        );

        const isBatchTemplate = fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;
        const isBatchRecEdit = batchRecDetailsForEdit && batchRecDetailsForEdit.length > 0;
        const isRegularLoader = !isBatch || isBatchTemplate;
        const isRxFileImport =
            activeEquationGroup &&
            activeEquationGroup.groupName === rxFileImportModels.RX_FILE_IMPORT_TYPE_NAME;

        const eventSeasonString = [
            ...new Set(
                recDetails.eventSelectionList
                    .filter(
                        (re) =>
                            re.croppingSeasonName &&
                            [
                                EVENT_TYPE_NAME_HARVEST,
                                EVENT_TYPE_NAME_SAMPLING_SOIL,
                                EVENT_TYPE_NAME_SAMPLING_TISSUE,
                            ].indexOf(re.eventTypeName) > -1
                    )
                    .sort((a, b) => (a.croppingSeasonName < b.croppingSeasonName ? -1 : 1))
                    .map((re) => `${re.croppingSeasonName} (${re.eventTypeName.substring(0, 1)})`)
            ),
        ].reduce((acc, s) => `${acc}${acc ? ", " : ""}${s}`, "");

        return (
            <div className="rec-equation-application-form">
                {isEquationsLoading || isLoadingStatus ? (
                    <Loader
                        className={classnames(
                            { "batch-equation-rec-loader": !isRegularLoader },
                            { "equation-rec-loader": isRegularLoader }
                        )}
                    />
                ) : null}
                {recModel.recGuid || isBatchTemplate ? null : (
                    <div className="is-included-checkbox" style={isIncludedCbStyle}>
                        <Checkbox
                            onChange={(evt, v) => this._updateRec({ isIncluded: v })}
                            label={"Include"}
                            reverseLabel={true}
                            value={recModel.isIncluded}
                        />
                    </div>
                )}
                {(recModel.recGuid && !recModel.isIncluded) ||
                isBatchRecEdit ||
                isRxFileImport ? null : (
                    <Bucket
                        className="equation-application-bucket"
                        isExpanded={this.state.isParametersExpanded}
                        onBucketToggle={(isExpanded) => this._toggleParametersBucket(isExpanded)}
                    >
                        <BucketHeader className="equation-application-bucket-header">
                            <div className="param-bucket-title">
                                <div className="param-title">
                                    {formatMessage(messages.parametersText)}
                                </div>
                                {!eventSeasonString ? null : (
                                    <div className="param-events" title={eventSeasonString}>
                                        {formatMessage(fieldMessages.eventText, {
                                            count: recDetails.eventSelectionList?.length,
                                        })}
                                        : {eventSeasonString}
                                    </div>
                                )}
                            </div>
                        </BucketHeader>
                        {this.props.isEquationsLoading && !recModel.isAnyRecAdjustmentProcessing
                            ? null
                            : this._buildFilterInputRows()}
                    </Bucket>
                )}
                {!isRxFileImport ? null : (
                    <Bucket
                        className="equation-application-bucket"
                        isExpanded={this.state.isNutrientsExpanded}
                        onBucketToggle={(isExpanded) => this._toggleNutrientsBucket(isExpanded)}
                    >
                        <BucketHeader className="equation-application-bucket-header">
                            {formatMessage(messages.nutrientsText)}
                        </BucketHeader>
                        <RxFileImport
                            fieldGuid={fieldGuid}
                            isBatch={isBatch}
                            onEditProductMix={this.onEditProductMix.bind(this)}
                            recNutrientsInProcessing={this.props.recNutrientsInProcessing}
                            recModel={this.props.recModel}
                        />
                    </Bucket>
                )}
                {(recModel.recGuid && !recModel.isIncluded) || isRxFileImport ? null : (
                    <Bucket
                        className="equation-application-bucket"
                        isExpanded={this.state.isNutrientsExpanded}
                        onBucketToggle={(isExpanded) => this._toggleNutrientsBucket(isExpanded)}
                    >
                        <BucketHeader className="equation-application-bucket-header">
                            {formatMessage(messages.nutrientsText)}
                        </BucketHeader>
                        {this.props.isEquationsLoading && !recModel.isAnyRecAdjustmentProcessing
                            ? null
                            : this._buildNutrientProductDisplay()}
                    </Bucket>
                )}
                {!this.state.isProductBlendingActive ? null : (
                    <ProductBlendModal
                        isProcessingRecNutrient={this.props.isProcessingRecNutrient}
                        setIsProcessingRecNutrient={(isProcessingRecNutrient) =>
                            this.props.setIsProcessingRecNutrient(isProcessingRecNutrient)
                        }
                        recNutrient={recModel.recNutrientList[this.state.activeProductBlend]}
                        productMix={
                            recModel.recNutrientList[this.state.activeProductBlend]
                                .recNutrientProductMix
                        }
                        calculatedArea={isBatchTemplate && !isBatchRecEdit ? 1 : calculatedArea}
                        batchRecDetailsForEdit={batchRecDetailsForEdit}
                        isBatchRecEdit={isBatchRecEdit}
                        isOpen={this.state.isProductBlendingActive}
                        isEquation={true}
                        isLime={
                            recModel.recNutrientList[
                                this.state.activeProductBlend
                            ].nutrientName.toLowerCase() === "lime"
                        }
                        isEquationComplete={
                            recModel.isFilterChanged
                                ? false
                                : Boolean(recModel.recGuid) || isBatchRecEdit
                        }
                        nutrientGuid={
                            recModel.recNutrientList[this.state.activeProductBlend].nutrientGuid
                        }
                        nutrientName={
                            recModel.recNutrientList[this.state.activeProductBlend].nutrientName
                        }
                        activeProductMixIdx={this.state.activeProductBlend}
                        onUpdate={(productMix) => this.onUpdateProductBlending(productMix)}
                        onUpdateRecNutrient={(recNutrient, isBlendingActive) =>
                            this.onUpdateRecNutrient(recNutrient, isBlendingActive)
                        }
                        onSave={() => this.onSaveProductBlending()}
                        onCancel={() => this.onCancelProductBlending()}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { fieldGuidToRecDetails, saveRecDetailsErrorCodeList, saveRecDetailsErrorFieldGuidList } =
        recsSelectors.getModuleState(state);
    const recDetails = fieldGuidToRecDetails.get(ownProps.fieldGuid);

    const classBreakIdSet = new Set(
        recDetails.recAreaList
            .filter((area) => area.recAreaClassBreak != null)
            .map((area) => area.recAreaClassBreak.classId)
    );
    const classBreaksCount = classBreakIdSet.size;

    const classBreaksHaveOnlyOneZone = classBreaksCount === recDetails.recAreaList.length;

    const isLoadingStatus = !(
        recsEventsModels.TERMINAL_STATUS_CODES.includes(recDetails.importedStatus) ||
        !recDetails.recAreaList.some((recArea) =>
            recArea.recs.some((rec) => rec.isAnyRecAdjustmentProcessing)
        )
    );

    return {
        classBreaksCount,
        classBreaksHaveOnlyOneZone,
        saveRecDetailsErrorCodeList,
        saveRecDetailsErrorFieldGuidList,
        batchRecDetailsForEdit: selectors.getBatchRecDetailsForEdit(state),
        activeNutrientGuid: selectors.getActiveNutrientGuid(state),
        availableProducts: blendingSelectors.getAvailableProducts(state),
        conversionFactors: blendingSelectors.getConversionFactors(state),
        customProducts: blendingSelectors.getCustomProducts(state),
        filteredEquationGroupList: selectors.getFilteredEquationGroupList(state),
        equationFilterLists: selectors.getEquationFilterLists(state),
        equationFilterRequired: selectors.getEquationFilterRequired(state),
        isBatch: fieldGuidToRecDetails.size > 1,
        isBatchEditRecsModalActive: selectors.getIsBatchEditRecsModalActive(state),
        isEquationsLoading: selectors.getEquationsLoading(state),
        isLoadingStatus,
        isProcessingRecNutrient: selectors.getIsProcessingRecNutrient(state),
        productBlendPicklists: blendingSelectors.getProductBlendPicklists(state),
        recDetails,
        recNutrientsInProcessing: selectors.getRecNutrientsInProcessing(state),
        samplingDepthList: selectors.getSamplingDepthList(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    addDefaultProduct: (fieldGuid, recNutrient, recNutrientIdx, recNutrientList) =>
        dispatch(
            recsActions.addDefaultProduct(fieldGuid, recNutrient, recNutrientIdx, recNutrientList)
        ),
    addRecAdjustments: (recNutrient) => dispatch(recsActions.addRecAdjustments(recNutrient)),
    addUpdateRecProduct: (fieldGuid, recNutrientGuid, recNutrientList, originalGA) =>
        dispatch(
            recsActions.addUpdateRecProduct(fieldGuid, recNutrientGuid, recNutrientList, originalGA)
        ),
    batchAddRecAdjustments: (recNutrient) =>
        dispatch(recsActions.batchAddRecAdjustments(recNutrient)),
    closeRecInfo: (isCancel = false) => dispatch(actions.closeRecInfo(isCancel)),
    onUpdateCurrentRecAreaRec: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateCurrentRecAreaRec(fieldGuid, newProps)),
    refreshEquationFilterLists: (fieldGuid) =>
        dispatch(actions.refreshEquationFilterLists(fieldGuid)),
    setActiveNutrient: (fieldGuid, nutrientGuid) =>
        dispatch(actions.setActiveNutrient(fieldGuid, nutrientGuid)),
    setCustomProductGuids: (guids) => dispatch(setEventRecCustomProductGuids(guids)),
    setIsProcessingRecNutrient: (isProcessingRecNutrient) =>
        dispatch(actions.setIsProcessingRecNutrient(isProcessingRecNutrient)),
    setPermanentAdjustments: (status) => dispatch(actions.setPermanentAdjustments(status)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    addDefaultProduct: (recNutrient, recNutrientIdx, recNutrientList) =>
        dispatchProps.addDefaultProduct(
            ownProps.fieldGuid,
            recNutrient,
            recNutrientIdx,
            recNutrientList
        ),
    addUpdateRecProduct: (recNutrientGuid, recNutrientList, originalGA) =>
        dispatchProps.addUpdateRecProduct(
            ownProps.fieldGuid,
            recNutrientGuid,
            recNutrientList,
            originalGA
        ),
    onUpdateCurrentRecAreaRec: (newProps) =>
        dispatchProps.onUpdateCurrentRecAreaRec(ownProps.fieldGuid, newProps),
    setActiveNutrient: (nutrientGuid) =>
        dispatchProps.setActiveNutrient(ownProps.fieldGuid, nutrientGuid),
});

export const RecEquationApplicationForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecEquationApplicationForm_));
