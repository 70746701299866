import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Bucket, BucketHeader } from "~/core";
import { config as intlConfig } from "~/intl-provider/config";
import { injectIntl, intlShape, defineMessages } from "react-intl";
import TreeView from "~/core/components/tree-view/tree-view";
import { Checkbox } from "~/core";

import { selectors as mapSelectors } from "~/map/components/map-control";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { actions as recsEventsActions } from "~/recs-events";
import { FormattingHelpers } from "@ai360/core";
import { logFirebaseEvent } from "~/utils/firebase";

import "../../../../common/rec-event-info/rec-event-info.css";

import "./event-filter-form.css";
import {
    AgEventFilterSummary,
    EventDetails,
    EVENT_TYPE_NAME_HARVEST,
} from "~/recs-events/events/model";

const filterSortOrder = {
    min: 0,
    max: 1,
    stdDev: 2,
    deepStdDev: 3,
    shallowStdDev: 4,
    speedMinMax: 5,
    velocitySmoothing: 6,
    swathWidthMin: 7,
    passStartEnd: 8,
    headerPosition: 9,
};

const messages = defineMessages({
    summaryStatisticsBucketTitle: {
        id: "eventModule.eventInfo.summaryStatisticsBucketTitle",
        defaultMessage: "Summary Statistics",
    },
    filterStatisticsBucketTitle: {
        id: "eventModule.eventInfo.filterStatisticsBucketTitle",
        defaultMessage: "Filtering Statistics",
    },
    filterBucketTitle: {
        id: "eventModule.eventInfo.filterBucketTitle",
        defaultMessage: "Filters",
    },
    allFiltersLabel: {
        id: "eventModule.eventInfo.allFiltersLabel",
        defaultMessage: "All Filters",
    },
    summaryStatLabel: {
        id: "eventModule.eventInfo.summaryStatLabel",
        defaultMessage:
            "{column, select, CalibratedDryYield {Yield} DryRate {Rate} EcDeep {EC Deep} EcShallow {EC Shallow} LiquidRate {Rate} SeedRate {Seeding Rate}} ({units})",
    },
    summaryStatsUnavailable: {
        id: "eventModule.eventInfo.summaryStatsUnavailable",
        defaultMessage: "Unavailable",
    },
    columnLabel: {
        id: "eventModule.eventInfo.columnLabel",
        defaultMessage:
            "{column, select, CalibratedDryYield {Yield} DryRate {Rate} LiquidRate {Rate} SeedRate {Seeding Rate}}",
    },
    filterInfoItem: {
        id: "eventModule.eventInfo.filterInfoItem",
        defaultMessage: "{label}: {value}",
    },
    filterLabel: {
        id: "eventModule.eventInfo.filterLabel",
        defaultMessage:
            "{filter, select, all {All Filters} deepStdDev {EC Deep Standard Deviation} max {Max {column}} min {Min {column}} passStartEnd {Start/End Pass Delay} shallowStdDev {EC Shallow Standard Deviation} speedMinMax {Speed Outliers} stdDev {{column} Standard Deviation} swathWidthMin {Min Swath Width} velocitySmoothing {Speed Smoothing} headerPosition {Header Down Required} distanceMax {Max Distance} swathWidthMax {Max Swath Width}}",
    },
    label: {
        id: "eventModule.eventInfo.label",
        defaultMessage: "{label}",
    },
});

interface IEventFilterFormProps {
    agEventTransactionTypeName: string;
    fieldGuid: string;
    eventFilterData: AgEventFilterSummary;
    filteredPointCount: number;
    intl: intlShape;
    setShowFilterPoints: (status: boolean, fieldGuid: string) => void;
    onUpdateEventDetails: (details: Partial<EventDetails>) => void;
    onUpdateFilters: (filterData: AgEventFilterSummary) => void;
}

interface IEventFilterFormState {
    filters: any;
}

export class FilterForm_ extends Component<IEventFilterFormProps, IEventFilterFormState> {
    constructor(props) {
        super(props);
        this.state = {
            filters: { ...props.eventFilterData.filters },
        };
    }

    componentDidMount() {
        logFirebaseEvent("event_filters");
        this.props.setShowFilterPoints(true, this.props.fieldGuid);
    }

    componentWillUnmount() {
        this.props.setShowFilterPoints(false, this.props.fieldGuid);
    }

    onStatusChange = (node, value) => {
        const { filters } = this.state;
        const updatedFilters = { ...filters };
        // NOTE: This assumes only 1-level deep representation ... any deeper will require
        // refactoring to utilize a recursive algorithm
        if (node.children) {
            node.children.forEach((child) => {
                const { id } = child;
                updatedFilters[id] = value;
            });
        } else {
            updatedFilters[node.id] = value;
        }
        this.setState(
            {
                filters: updatedFilters,
            },
            () => {
                this.props.onUpdateEventDetails({ filters: updatedFilters });
                this.props.onUpdateFilters({
                    ...this.props.eventFilterData,
                    filters: updatedFilters,
                });
            }
        );
    };

    renderSummary = () => {
        const { stats } = this.props.eventFilterData;
        const { formatMessage, formatNumber } = this.props.intl;
        const isHarvest = this.props.agEventTransactionTypeName === EVENT_TYPE_NAME_HARVEST;

        const statRows = stats.map((s) => {
            const decimals = isHarvest
                ? FormattingHelpers.progressiveRoundingDecimals(s.avg)
                : FormattingHelpers.progressiveRoundingDecimalsThreeTier(s.avg);
            return (
                // No units indicate summary data is incomplete
                s.units ? (
                    <tr key={s.column}>
                        <td className="label-col">
                            {formatMessage(messages.summaryStatLabel, {
                                column: s.column,
                                units: s.units,
                            })}
                        </td>
                        <td>{formatNumber(s.min, intlConfig.customFormatOptions(decimals))}</td>
                        <td>{formatNumber(s.avg, intlConfig.customFormatOptions(decimals))}</td>
                        <td>{formatNumber(s.max, intlConfig.customFormatOptions(decimals))}</td>
                    </tr>
                ) : (
                    <tr key={s.column}>
                        <td className="label-col">
                            {formatMessage(messages.summaryStatLabel, {
                                column: s.column,
                                units: s.units,
                            })}
                        </td>
                        <td colSpan={3} className="label-col">
                            {formatMessage(messages.summaryStatsUnavailable)}
                        </td>
                    </tr>
                )
            );
        });
        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.summaryStatisticsBucketTitle)}:</BucketHeader>
                <table className="filter-form-table">
                    <thead>
                        <tr>
                            <th className="label-col"></th>
                            <th>
                                {formatMessage(messages.label, {
                                    label: "Min",
                                })}
                            </th>
                            <th>
                                {formatMessage(messages.label, {
                                    label: "Avg",
                                })}
                            </th>
                            <th>
                                {formatMessage(messages.label, {
                                    label: "Max",
                                })}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{statRows}</tbody>
                </table>
            </Bucket>
        );
    };

    renderFilterSummary = () => {
        const { eventFilterData, filteredPointCount } = this.props;
        const { stats } = eventFilterData;
        const { formatMessage, formatNumber } = this.props.intl;

        // Get total point count from first summary record
        const total = stats[0].total;

        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.filterStatisticsBucketTitle)}:</BucketHeader>
                <table className="filter-form-table">
                    <thead>
                        <tr>
                            <th className="label-col"></th>
                            <th>
                                {formatMessage(messages.label, {
                                    label: "Total",
                                })}
                            </th>
                            <th>
                                {formatMessage(messages.label, {
                                    label: "Included",
                                })}
                            </th>
                            <th>
                                {formatMessage(messages.label, {
                                    label: "Outliers",
                                })}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="label-col">
                                {formatMessage(messages.label, {
                                    label: "Event Points",
                                })}
                            </td>
                            <td>{formatNumber(total)}</td>
                            <td>{formatNumber(total - filteredPointCount)}</td>
                            <td>{formatNumber(filteredPointCount)}</td>
                        </tr>
                    </tbody>
                </table>
            </Bucket>
        );
    };

    renderCheckbox = (node) => {
        const { filters } = this.state;
        let className = "checkbox-child";
        let checkedProp = { someChecked: null, value: filters[node.id] };
        if (node.children && node.children.length > 0) {
            const allUnchecked = node.children.every((item) => !filters[item.id]);
            const someChecked = node.children.some((item) => {
                return filters[item.id];
            });
            const allChecked = node.children.every((item) => filters[item.id]);

            if (allChecked) {
                checkedProp = { someChecked: null, value: true };
            } else if (someChecked) {
                checkedProp = { someChecked: true, value: true };
            } else if (allUnchecked) {
                checkedProp = { someChecked: null, value: false };
            }
            className = "checkbox-parent";
        } else {
            checkedProp = { someChecked: null, value: filters[node.id] };
        }
        return (
            <Checkbox
                onChange={(evt, value) => this.onStatusChange(node, value)}
                {...checkedProp}
                className={className}
            />
        );
    };

    renderRowLabel = (row) => {
        const { formatMessage, formatNumber } = this.props.intl;

        if (row.id === "all") {
            return (
                <div className="filter-row">
                    {formatMessage(messages.filterLabel, { filter: row.id })}
                </div>
            );
        }

        const infoItems = Object.keys(row.filterInfo).map((k) => {
            return formatMessage(messages.filterInfoItem, {
                label: _.startCase(k),
                value: formatNumber(row.filterInfo[k], intlConfig.numberFormatOptions),
            });
        });

        // Use column name from first summary record - EC Data is only
        // type with more than one and doesn't use the column name for labels
        const { column } = this.props.eventFilterData.stats[0];
        const columnLabel = formatMessage(messages.columnLabel, { column });
        return (
            <div className="filter-row" title={infoItems.join(", ")}>
                <span>
                    {formatMessage(messages.filterLabel, {
                        filter: row.id,
                        column: columnLabel,
                    })}
                </span>
                <span>{formatNumber(row.count)}</span>
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { filterData } = this.props.eventFilterData;

        let filterTree = null;
        if (filterData.length > 1) {
            filterTree = [
                {
                    id: "all",
                    isOpen: true,
                    children: filterData.sort(
                        (a, b) => filterSortOrder[a["id"]] - filterSortOrder[b["id"]]
                    ),
                },
            ];
        } else {
            filterTree = [
                {
                    isOpen: true,
                    ...filterData[0],
                },
            ];
        }

        return (
            <div className="rec-event-info-form event-filter-form">
                {this.renderSummary()}
                {this.renderFilterSummary()}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.filterBucketTitle)}:</BucketHeader>
                    <div className="filter-tree-view">
                        <TreeView
                            rowRenderer={this.renderCheckbox}
                            data={filterTree}
                            renderRowLabel={this.renderRowLabel}
                        />
                    </div>
                </Bucket>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filteredPointCount: mapSelectors.getFilteredPointCount(state),
        eventFilterData: selectors.getModuleState(state).eventFilterData,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onUpdateEventDetails: (newProps) =>
        dispatch(recsEventsActions.updateEventDetails(ownProps.fieldGuid, newProps)),
    onUpdateFilters: (filterData) => dispatch(actions.setEventFilterData(filterData)),
    setShowFilterPoints: (showFilterPoints, fieldGuid) =>
        dispatch(actions.setShowFilterPoints(showFilterPoints, fieldGuid)),
});

export const FilterForm = connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterForm_));
