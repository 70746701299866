import { ICrop } from "@ai360/core/dist/4x/es/api/field/field";
import { ReportAPI } from "@ai360/core";
import reports from "~/reports/reports";
import { getReportDataFromPreferences } from "~/reports/utils";

import * as actions from "./actions";
import { DEFAULT_SELECTED_BASEMAP } from "./constants";
import { IFileNameFormat, IReportType, IReportTypePage, IYieldByCategory } from "./interfaces";

interface ReducerAction {
    cropDropdown: ICrop[];
    defaultFileNameFormat: string;
    defaultMultiFieldRec: boolean;
    fileNameFormatList: IFileNameFormat[];
    pageList: IReportTypePage[];
    payload: any;
    reportPreferences: Record<string, any>;
    reportStatus: string[];
    type: string;
    typeList: IReportType[];
    yieldByCompareByOptions: IYieldByCategory;
    fields: ReportAPI.IField[];
}

const initialState = {
    cropDropdown: [],
    typeList: [],
    yieldCategories: {},
    yieldByCompareByOptions: {},
    reportStatus: [],
    defaultFileNameFormat: null,
    defaultMultiFieldRec: false,
    fileNameFormatList: [],
    isTableLoading: false,
    reportPreferences: {
        ageOfSample: "3",
        breakEvenCropList: [],
        fieldLabelConfig: null,
        reportTypePagesPref: null,
        useCropPlanningSummary: false,
        useEventIdBarcode: false,
        useOverviewMap: true,
        useProductRecs: false,
        selectedBasemap: DEFAULT_SELECTED_BASEMAP,
        showNonFieldFeature: false,
        showGuaranteedAnalysis: false,
        showLoadsheetProductDetail: false,
        showPhotos: false,
        showProductRecCostDetail: false,
        showProductRecSummaryCostDetail: false,
        showRecSummary: false,
        useImportDate: false,
        showScoutingDetails: true,
    },
    fields: [],
    reportData: {},
};

export function reportsReducer(
    state = initialState,
    action: ReducerAction = {
        cropDropdown: [],
        defaultFileNameFormat: null,
        defaultMultiFieldRec: false,
        fileNameFormatList: [],
        pageList: [],
        payload: null,
        reportPreferences: {
            ageOfSample: "3",
            breakEvenCropList: [],
            fieldLabelConfig: null,
            reportTypePagesPref: null,
            useCropPlanningSummary: false,
            useEventIdBarcode: false,
            useOverviewMap: true,
            useProductRecs: false,
            selectedBasemap: DEFAULT_SELECTED_BASEMAP,
            showNonFieldFeature: false,
            showGuaranteedAnalysis: false,
            showLoadsheetProductDetail: false,
            showPhotos: false,
            showProductRecCostDetail: false,
            showProductRecSummaryCostDetail: false,
            showRecSummary: false,
            useImportDate: false,
            showScoutingDetails: true,
        },
        reportStatus: [],
        type: null,
        typeList: [],
        yieldByCompareByOptions: {} as IYieldByCategory,
        fields: [],
    }
): Record<string, unknown> {
    switch (action.type) {
        case actions.REPORTS_GET_CROP_DROPDOWN_SUCCESS:
            return {
                ...state,
                cropDropdown: action.payload,
            };
        case actions.REPORTS_GET_FILE_NAME_FORMAT_SUCCESS:
            return {
                ...state,
                fileNameFormatList: action.payload,
            };
        case actions.REPORTS_GET_REPORT_PREF_SUCCESS: {
            const newState = {
                ...state,
                reportPreferences: action.payload,
                reportData: { ...state.reportData },
            };
            const reportPreferenceReportData = getReportDataFromPreferences(action.payload);
            Object.keys(newState.reportData).forEach((reportType) => {
                if (reportType in reports) {
                    return;
                }
                Object.keys(reportPreferenceReportData).forEach((key) => {
                    if (key in newState.reportData[reportType]) {
                        newState.reportData[reportType][key] = reportPreferenceReportData[key];
                    }
                });
            });
            return newState;
        }
        case actions.REPORTS_GET_REPORT_TYPE_SUCCESS:
            return {
                ...state,
                typeList: action.payload,
            };
        case actions.REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS:
            return {
                ...state,
                pageList: action.payload,
            };
        case actions.REPORTS_GET_USER_FILE_FORMAT_SUCCESS:
            return {
                ...state,
                defaultFileNameFormat: action.payload,
            };
        case actions.REPORTS_GET_USER_MULTI_FIELD_REC_SUCCESS:
            return {
                ...state,
                defaultMultiFieldRec: action.payload,
            };
        case actions.REPORTS_GET_YIELD_CATEGORY_SUCCESS:
            return {
                ...state,
                yieldCategories: action.payload,
            };
        case actions.REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS:
            return {
                ...state,
                yieldByCompareByOptions: action.payload,
            };
        case actions.REPORTS_CREATE_REPORT_HUB_SUCCESS: {
            return {
                ...state,
                reportStatus: action.payload,
            };
        }
        case actions.REPORTS_CLEAR_REPORT_HUB_DATA: {
            return {
                ...state,
                reportStatus: [],
            };
        }
        case actions.REPORTS_SET_FIELDS: {
            return {
                ...state,
                fields: action.payload.fields,
            };
        }
        case actions.REPORTS_SET_REPORT_DATA_VALUE: {
            const newState = { ...state, reportData: { ...state.reportData } };
            const reportType = action.payload.reportType || "default";
            const existingReportState =
                newState.reportData[reportType] || newState.reportData["default"] || {};
            newState.reportData[reportType] = { ...existingReportState };
            newState.reportData[reportType][action.payload.key] = action.payload.value;
            return newState;
        }
        case actions.REPORTS_MERGE_REPORT_DATA: {
            const reportType = action.payload.reportType || "default";
            let existingReportData = state.reportData[reportType];
            if (reportType === "default" && !existingReportData) {
                existingReportData = {};
            } else if (reportType != "default" && !(reportType in reports) && !existingReportData) {
                existingReportData = state.reportData["default"] || {};
            }
            return {
                ...state,
                reportData: {
                    ...state.reportData,
                    [reportType]: { ...existingReportData, ...action.payload.reportData },
                },
            };
        }
        case actions.REPORTS_RESET_REPORT_DATA: {
            if (action.payload?.reportType) {
                return {
                    ...state,
                    reportData: {
                        ...state.reportData,
                        [action.payload.reportType]: undefined,
                    },
                };
            }
            return {
                ...state,
                reportData: {},
            };
        }
        case actions.REPORTS_MERGE_DATA:
        case actions.REPORTS_DOWNLOAD_DATA: {
            return {
                ...state,
                isTableLoading: true,
            };
        }
        case actions.REPORTS_MERGE_SUCCESS:
        case actions.REPORTS_MERGE_FAILED:
        case actions.REPORTS_DOWNLOAD_SUCCESS:
        case actions.REPORTS_DOWNLOAD_FAILED: {
            return {
                ...state,
                isTableLoading: false,
            };
        }
        default:
            return state;
    }
}
