export const ACTIVATE_EVENT_FROM_DIMIDX = "event-module/ACTIVATE_EVENT_FROM_DIMIDX";
export const CLEAR_EVENT_FILTER = "event-module/CLEAR_EVENT_FILTER";
export const CONFIGURE_EVENT_FILTERS = "event-module/CONFIGURE_EVENT_FILTERS";
export const DELETE_EVENTS_FROM_DIMIDX_LIST = "event-module/DELETE_EVENTS_FROM_DIMIDX_LIST";
export const DESELECT_EVENTS_FOR_FIELD_GUIDS = "event-module/DESELECT_EVENTS_FOR_FIELD_GUIDS";
export const DESELECT_EVENTS_FROM_ACCORDION = "event-module/DESELECT_EVENTS_FROM_ACCORDION";
export const DESELECT_EVENTS_FROM_DIMIDX_LIST = "event-module/DESELECT_EVENTS_FROM_DIMIDX_LIST";
export const EXPORT_EVENT_DATA = "event-module/EXPORT_EVENT_DATA";
export const EXPORT_SURFACE_DATA = "event-module/EXPORT_SURFACE_DATA";
export const EXPORT_SAMPLING_POINTS = "event-module/EXPORT_SAMPLING_POINTS";
export const SHOW_ERRORS = "event-module/SHOW_ERRORS";
export const HIDE_ERRORS = "event-module/HIDE_ERRORS";
export const SHOW_ERROR_DETAILS = "event-module/SHOW_ERROR_DETAILS";
export const HIDE_ERROR_DETAILS = "event-module/HIDE_ERROR_DETAILS";
export const RESET_ALL_ACCORDION = "event-module/RESET_ALL_ACCORDION";
export const RESET_INACTIVE_ACCORDION = "event-module/RESET_INACTIVE_ACCORDION";
export const SELECT_EVENTS_FROM_ACCORDION = "event-module/SELECT_EVENTS_FROM_ACCORDION";
export const SELECT_EVENTS_FOR_FIELD_GUID = "event-module/SELECT_EVENTS_FOR_FIELD_GUID";
export const SET_EVENT_FILTER = "event-module/SET_EVENT_FILTER";
export const SET_EVENT_FILTER_INFO = "event-module/SET_EVENT_FILTER_INFO";
export const SET_EVENT_FILTER_OPTIONS = "event-module/SET_EVENT_FILTER_OPTIONS";
export const SET_EVENT_PANEL_LOADING = "event-module/SET_EVENT_PANEL_LOADING";
export const SET_IS_EVENT_LOADING = "event-module/SET_IS_EVENT_LOADING";
export const SET_INIT_SCROLL_TOP = "event-module/SET_INIT_SCROLL_TOP";
export const SET_LAST_CLICKED_DIMIDX = "event-module/SET_LAST_CLICKED_DIMIDX";
export const SET_SHOW_FILTERS = "event-module/SET_SHOW_FILTERS";
export const UPDATE_EXPANDED_FIELD_GUID_SET = "event-module/UPDATE_EXPANDED_FIELD_GUID_SET";
export const UPDATE_SEARCH = "event-module/UPDATE_SEARCH";
export const DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID =
    "event-module/DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID";

export const activateEventFromDimIdx = (toActivateDimIdx) => ({
    type: ACTIVATE_EVENT_FROM_DIMIDX,
    payload: { toActivateDimIdx },
});

export const clearEventFilter = () => ({
    type: CLEAR_EVENT_FILTER,
});

export const configureEventFilters = (filters) => ({
    type: CONFIGURE_EVENT_FILTERS,
    payload: { filters },
});

export const deleteEventsFromDimIdxList = (toDeleteDimIdxList) => ({
    type: DELETE_EVENTS_FROM_DIMIDX_LIST,
    payload: { toDeleteDimIdxList },
});

export const deselectEventsFromAccordion = (startDimIdx, endDimIdx) => ({
    type: DESELECT_EVENTS_FROM_ACCORDION,
    payload: { startDimIdx, endDimIdx },
});

export const deselectEventsForFieldGuids = (fieldGuidsToRemove) => ({
    type: DESELECT_EVENTS_FOR_FIELD_GUIDS,
    payload: { fieldGuidsToRemove },
});

export const deselectEventsFromDimIdxList = (toDeselectDimIdxList) => ({
    type: DESELECT_EVENTS_FROM_DIMIDX_LIST,
    payload: { toDeselectDimIdxList },
});

export const exportSurfaceData = (eventSummary) => ({
    type: EXPORT_SURFACE_DATA,
    payload: { eventSummary },
});

export const exportEventData = (eventSummary) => ({
    type: EXPORT_EVENT_DATA,
    payload: { eventSummary },
});

export const exportSamplingPoints = (eventSummary) => ({
    type: EXPORT_SAMPLING_POINTS,
    payload: { eventSummary },
});

export const showErrors = (errorCodes) => ({
    type: SHOW_ERRORS,
    payload: { errorCodes },
});

export const hideErrors = () => ({
    type: HIDE_ERRORS,
});

export const showErrorDetails = (eventSummary) => ({
    type: SHOW_ERROR_DETAILS,
    payload: eventSummary,
});

export const hideErrorDetails = () => ({
    type: HIDE_ERROR_DETAILS,
});

export const resetAllAccordion = () => ({
    type: RESET_ALL_ACCORDION,
});

export const resetInactiveAccordion = () => ({
    type: RESET_INACTIVE_ACCORDION,
});

export const selectEventsFromAccordion = (startDimIdx, endDimIdx) => ({
    type: SELECT_EVENTS_FROM_ACCORDION,
    payload: { startDimIdx, endDimIdx },
});

export const selectEventsForFieldGuid = (fieldGuid, selectedEventGuids) => ({
    type: SELECT_EVENTS_FOR_FIELD_GUID,
    payload: { fieldGuid, selectedEventGuids },
});

export const setEventFilter = (eventFilter) => ({
    type: SET_EVENT_FILTER,
    payload: { eventFilter },
});
export const setEventFilterInfo = (filterInfo) => ({
    type: SET_EVENT_FILTER_INFO,
    payload: { filterInfo },
});
export const setEventFilterOptions = (filterOptions) => ({
    type: SET_EVENT_FILTER_OPTIONS,
    payload: { filterOptions },
});
export const setEventPanelLoading = (isPanelLoading) => ({
    type: SET_EVENT_PANEL_LOADING,
    payload: { isPanelLoading },
});
export const setIsEventLoading = (isEventLoading) => ({
    type: SET_IS_EVENT_LOADING,
    payload: { isEventLoading },
});

export const setInitScrollTop = (activeTab, scrollTop) => ({
    type: SET_INIT_SCROLL_TOP,
    payload: { activeTab, scrollTop },
});

export const setLastClickedDimIdx = (itemDimIdx) => ({
    type: SET_LAST_CLICKED_DIMIDX,
    payload: { itemDimIdx },
});

export const setShowFilters = (showFilters) => ({
    type: SET_SHOW_FILTERS,
    payload: { showFilters },
});

export const updateExpandedFieldGuidSet = (fieldGuid, isExpanded) => ({
    type: UPDATE_EXPANDED_FIELD_GUID_SET,
    payload: { fieldGuid, isExpanded },
});

export const LOAD_YIELD_CALIBRATION = "event-module/LOAD_YIELD_CALIBRATION";
export const loadYieldCalibration = (agEventGeneralGuidList) => ({
    type: LOAD_YIELD_CALIBRATION,
    payload: { agEventGeneralGuidList },
});

export const TOGGLE_YIELD_MODAL = "event-module/TOGGLE_YIELD_MODAL";
export const showHideYieldCalibrationModal = (isVisible) => ({
    type: TOGGLE_YIELD_MODAL,
    payload: { isVisible },
});

export const SHOW_MERGE_MODAL = "event-module/SHOW_MERGE_MODAL";
export const showMergeModal = () => ({
    type: SHOW_MERGE_MODAL,
});

export const HIDE_MERGE_MODAL = "event-module/HIDE_MERGE_MODAL";
export const hideMergeModal = () => ({
    type: HIDE_MERGE_MODAL,
});

export const SET_MERGEABLE_EVENTS = "event-module/SET_MERGEABLE_EVENTS";
export const setMergeableEvents = (mergeableEvents) => ({
    type: SET_MERGEABLE_EVENTS,
    payload: { mergeableEvents },
});

export const YIELD_CALIBRATION_INPROGRESS = "event-module/YIELD_CALIBRATION_INPROGRESS";
export const yieldCalibrationInProgress = (yieldCalibrationInProgress) => ({
    type: YIELD_CALIBRATION_INPROGRESS,
    payload: { yieldCalibrationInProgress },
});

export const destroyEventsByAgEventGeneralGuid = (agEventGeneralGuids) => ({
    type: DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID,
    payload: { agEventGeneralGuids },
});

export const updateSearchValue = (searchValue) => ({
    type: UPDATE_SEARCH,
    payload: { searchValue },
});
