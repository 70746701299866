import React, { Component, ReactNode } from "react";

import moment from "moment";
import { Column, SortDirection, SortDirectionType, Table } from "react-virtualized";

import "react-virtualized/styles.css";
import "./simple-table.css";
import { IColumnData, IRecord } from "../interfaces";

export const ColumnDataType = {
    STRING: 0,
    NUMBER: 1,
    DATE_TIME: 2,
};

export interface ISimpleTableProps {
    columns: IColumnData[];
    headerHeight: number;
    height: number;
    onSelectRow?: (selRow?: IRecord) => void;
    records: IRecord[];
    recordsMayMutate: boolean;
    rowHeight: number;
    sortBy?: string;
    sortDirection: SortDirectionType;
    width?: number;
    className?: string;
}

export interface ISimpleTableState {
    records: IRecord[];
    selectedRow: IRecord;
    sortBy: string;
    sortDirection: SortDirectionType;
}

export class SimpleTable extends Component<ISimpleTableProps, ISimpleTableState> {
    static defaultProps = {
        recordsMayMutate: false,
    };

    constructor(props: ISimpleTableProps) {
        super(props);
        this.state = {
            records: [...props.records],
            selectedRow: null,
            sortBy: props.sortBy,
            sortDirection: props.sortDirection,
        };
    }

    private _getRowClassName({ index }): string {
        const { selectedRow } = this.state;
        return selectedRow === index ? "row-selected" : null;
    }

    private _sort({ sortBy, sortDirection }): void {
        const { columns, records } = this.props;
        const column = columns.find((c: IColumnData) => c.dataKey === sortBy);
        switch (column.type) {
            case ColumnDataType.NUMBER:
                records.sort((a, b) => a[sortBy] - b[sortBy]);
                break;
            case ColumnDataType.DATE_TIME:
                records.sort((a, b) => moment(a[sortBy]).diff(moment(b[sortBy])));
                break;
            default:
                records.sort((a, b) => {
                    if (a[sortBy] < b[sortBy]) {
                        return -1;
                    }
                    if (a[sortBy] > b[sortBy]) {
                        return 1;
                    }
                    return 0;
                });
        }
        if (sortDirection === SortDirection.DESC) {
            records.reverse();
        }

        this.setState({
            records,
            sortBy,
            sortDirection,
        });
    }

    private _toggleRowSelection({ index, rowData }): void {
        const { onSelectRow } = this.props;
        const { selectedRow } = this.state;
        this.setState({
            selectedRow: selectedRow === index ? null : index,
        });
        if (onSelectRow) {
            onSelectRow(selectedRow === index ? null : rowData);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISimpleTableProps): void {
        const { records, recordsMayMutate } = nextProps;
        const recordsChanged = recordsMayMutate
            ? JSON.stringify(records) !== JSON.stringify(this.state.records)
            : records !== this.state.records;
        if (recordsChanged) {
            this.setState(
                {
                    records,
                },
                () => {
                    const { sortBy, sortDirection } = this.state;
                    if (sortBy != null && sortDirection != null) {
                        this._sort({ sortBy, sortDirection });
                    }
                }
            );
        }
    }

    render(): ReactNode {
        const { columns, headerHeight, height, rowHeight, width } = this.props;
        const { records, sortBy, sortDirection } = this.state;
        const tableProps = {
            headerHeight,
            height,
            rowHeight,
            sortBy,
            sortDirection,
            width,
        };

        return (
            <Table
                {...tableProps}
                className={"simple-table"}
                sort={(e) => this._sort(e)}
                onRowClick={(e) => this._toggleRowSelection(e)}
                rowClassName={(e) => this._getRowClassName(e)}
                rowCount={records.length}
                rowGetter={({ index }) => records[index]}
            >
                {columns.map((props, i) => (
                    <Column {...props} key={`col${i}`} />
                ))}
            </Table>
        );
    }
}
