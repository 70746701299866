import React, { Component } from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import classnames from "classnames";

import { adminData, GUID, ID, NAME } from "~/admin/data";
import {
    formatPlainTextLabel,
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
// Components
import { Checkbox, NumericInput, Section, SelectInput, SubSection, TextInput } from "~/core";

import { messages } from "../../i18n-messages";
import { model, service } from "../data";
import PackageGtinList from "../components/package-gtin-list";
import BackOfficeCode from "../components/back-office-list";
import { ProductPackageList, IAddEditPanelProps, IAddEditPanelState } from "../interfaces";

export class AddEditPanel extends Component<
    IAddEditPanelProps & InjectedIntlProps,
    IAddEditPanelState
> {
    productPackage: ProductPackageList;
    constructor(props) {
        super(props);
        this.productPackage = props.record || {};
        this.state = {
            nextId: null,
        };
    }

    componentDidMount() {
        this.setupPicklistRecord();
        this.props.setBreadcrumbs([""]);
        const { needs, parentGuid } = this.props;
        needs([this.props.getNextId(parentGuid), this.props.fetchDropdownData()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.productPackage);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.productPackage = nextProps.record;
            }
        }

        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.productPackage[model.PROPS_PRODUCT_PACKAGE_ID] = nextProps.nextId;
            this.productPackage[model.PROPS_PRODUCT_ID] = "" + nextProps.parentId;
            this.productPackage[model.PROPS_PRODUCT_GUID] = "" + nextProps.parentGuid;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupPicklistRecord = () => {
        const { addEditPanel } = this.props;
        this.productPackage = this.props.record || {};
        if (addEditPanel.mode === "ADD") {
            this.productPackage = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps) => {
        this.initializeProductPackageUnit(nextProps);
        this.initializeSeedCountUnit(nextProps);
    };

    initializeProductPackageUnit = (nextProps) => {
        if (
            this.productPackage[model.PROPS_PACKAGE_UNIT_GUID] != null &&
            nextProps[model.PROPS_PACKAGE_UNIT_NAME]
        ) {
            this.setState({
                [model.PROPS_PACKAGE_UNIT_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_PACKAGE_UNIT_NAME],
                    { guid: GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeSeedCountUnit = (nextProps) => {
        if (
            this.productPackage[model.PROPS_SEED_COUNT_UNIT_GUID] != null &&
            nextProps[model.PROPS_PACKAGE_UNIT_NAME]
        ) {
            this.setState({
                [model.PROPS_SEED_COUNT_UNIT_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_PACKAGE_UNIT_NAME],
                    { guid: GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    onPicklistChange = ({ type, guid }, value, callback?: () => void) => {
        this.productPackage = handlePicklistChange(
            this.productPackage,
            { type, guid, value },
            callback
        );
    };

    onTextChange = (formKey, value, callback?: () => void) => {
        this.productPackage = onTextChange(
            this.productPackage,
            { formKey: [formKey], value },
            callback
        );
    };

    onNumberChange = (formKey, value = "", callback?: () => void) => {
        this.productPackage = onNumberChange(
            this.productPackage,
            { formKey: [formKey], value },
            callback
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.productPackage[model.PROPS_LONG_DESCRIPTION]]);
    };

    onValidationClose = () => {
        this.props.toggleFetchData(false);
    };

    renderProductInfo = () => {
        const { formatMessage } = this.props.intl;
        const { productPackage } = this;
        return (
            <div className="section-container">
                <Section>
                    <div className="form-sub-section section-column">
                        <div className="form-sub-section section-column">
                            <span>
                                {formatPlainTextLabel(
                                    formatMessage,
                                    messages.productId,
                                    this.props.parentId
                                )}
                            </span>
                            <span>
                                {formatPlainTextLabel(
                                    formatMessage,
                                    messages.productName,
                                    this.props.parentName
                                )}
                            </span>
                        </div>
                    </div>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            suppressFormatting
                            containerClassNames={getAgBytesErrorClassNames(
                                [23],
                                this.props.apiErrors,
                                ["form-input-id"]
                            )}
                            placeholderText={formatMessage(messages.productPackageId)}
                            labelText={formatMessage(messages.productPackageId)}
                            value={productPackage[model.PROPS_PRODUCT_PACKAGE_ID]}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_PRODUCT_PACKAGE_ID, value)
                            }
                            required
                        />
                        {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className={classnames(
                                    "active-checkbox",
                                    getAgBytesErrorClassNames(811, this.props.apiErrors)
                                )}
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={productPackage[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={150}
                            required
                            containerClassNames={getAgBytesErrorClassNames(
                                11,
                                this.props.apiErrors,
                                ["form-long-input"]
                            )}
                            autoFocus={true}
                            placeholderText={formatMessage(messages.longDescription)}
                            value={productPackage[model.PROPS_LONG_DESCRIPTION]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_LONG_DESCRIPTION,
                                    value,
                                    this.setHeaderText
                                )
                            }
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderMiscInfo = () => {
        const { formatMessage } = this.props.intl;
        const { productPackage } = this;
        return (
            <div className="section-container">
                <Section headerText={formatMessage(messages.miscellaneousInfo)}>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={4}
                            precision={9}
                            containerClassNames={getAgBytesErrorClassNames(
                                739,
                                this.props.apiErrors
                            )}
                            placeholderText={formatMessage(messages.packageSize)}
                            labelText={formatMessage(messages.packageSize)}
                            value={productPackage[model.PROPS_PACKAGE_SIZE]}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_PACKAGE_SIZE, value)
                            }
                        />
                        <SelectInput
                            initialFilterStr={""}
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                738,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_PACKAGE_UNIT_NAME]}
                            placeholderText={formatMessage(messages.packageUnit)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_PACKAGE_UNIT_NAME],
                                selectedGuid: productPackage[model.PROPS_PACKAGE_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_PACKAGE_UNIT_NAME,
                                        guid: model.PROPS_PACKAGE_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.manufacturerPartNumber)}
                            value={productPackage[model.PROPS_MFR_PART_NUMBER]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_MFR_PART_NUMBER, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.upcCode)}
                            value={productPackage[model.PROPS_UPC_CODE]}
                            onChange={(value) => this.onTextChange(model.PROPS_UPC_CODE, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.agGatewayPackageId)}
                            value={productPackage[model.PROPS_AGGATEWAY_PACKAGE_ID]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_AGGATEWAY_PACKAGE_ID, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.agrianPackageId)}
                            value={productPackage[model.PROPS_AGRIAN_PACKAGE_ID]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_AGRIAN_PACKAGE_ID, value)
                            }
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={6}
                            containerClassNames={getAgBytesErrorClassNames(
                                741,
                                this.props.apiErrors
                            )}
                            placeholderText={formatMessage(messages.seedCount)}
                            value={productPackage[model.PROPS_SEED_COUNT]}
                            onChange={(value) => this.onNumberChange(model.PROPS_SEED_COUNT, value)}
                        />
                        <SelectInput
                            initialFilterStr={""}
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                740,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_SEED_COUNT_UNIT_NAME]}
                            placeholderText={formatMessage(messages.seedCountUnit)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_SEED_COUNT_UNIT_NAME],
                                selectedGuid: productPackage[model.PROPS_SEED_COUNT_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_SEED_COUNT_UNIT_NAME,
                                        guid: model.PROPS_SEED_COUNT_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.seedSize)}
                            value={productPackage[model.PROPS_SEED_SIZE]}
                            onChange={(value) => this.onTextChange(model.PROPS_SEED_SIZE, value)}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.dotShippingDescription)}
                            value={productPackage[model.PROPS_DOT_SHIPPING_DESCRIPTION]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_DOT_SHIPPING_DESCRIPTION, value)
                            }
                        />
                    </SubSection>
                </Section>
                <span className="bar section-fence" />
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.productPackageGtin)}
                >
                    <SubSection>
                        <PackageGtinList
                            formatMessage={formatMessage}
                            record={this.productPackage[model.PROPS_PRODUCT_PACKAGE_GTINS]}
                            itemListAlias={model.PROPS_PRODUCT_PACKAGE_GTINS}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
                <span className="bar section-fence" />
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.backOfficeCode)}
                >
                    <SubSection>
                        <BackOfficeCode
                            formatMessage={formatMessage}
                            record={
                                this.productPackage[
                                    model.PROPS_PRODUCT_PACKAGE_BACKOFFICE_PRODUCT_CODES
                                ]
                            }
                            itemListAlias={model.PROPS_PRODUCT_PACKAGE_BACKOFFICE_PRODUCT_CODES}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        return (
            <div className="add-edit-panel">
                {this.renderProductInfo()}
                <div className="section-container">{this.renderMiscInfo()}</div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
