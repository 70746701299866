import React, { Component, ReactElement, ReactNode, MouseEvent as ReactMouseEvent } from "react";
import classnames from "classnames";
import "./zero-to-infinite.css";
import { NoLink, DialogBoxFooterType, DialogBox } from "~/core";
import { messages } from "~/i18n-messages";
import { injectIntl, intlShape } from "react-intl";

export interface IZeroToInfiniteProps {
    addButton?: ReactElement;
    // items are different for each item that is added or changed.
    addItem?: (event: ReactMouseEvent, items: any, index: number) => void;
    addText?: string;
    children?: ReactElement;
    containerClassNames?: Record<string, unknown>[];
    deleteText?: string;
    deleteWarn?: (index: number) => boolean;
    // the item object here is going to be a different object depending on the child.
    getChildProps?: (item: Record<string, unknown>, index: string) => Record<string, unknown>;
    hideAddAndDelete?: boolean;
    initialValue?: any;
    isDisabled?: boolean;
    // items are different for each item that is added or changed.
    items?: any;
    limit?: number;
    // items are different for each item that is added or changed.
    onDelete?: (event: ReactMouseEvent, items: any, index: number) => void;
    required?: boolean;
    intl: intlShape;
}

export interface IZeroToInfiniteState {
    // items are different for each item that is added or changed.
    items: any;
    isDeleting: boolean;
    index: number;
}

export class ZeroToInfinite_ extends Component<IZeroToInfiniteProps, IZeroToInfiniteState> {
    intl: intlShape;

    static defaultProps = {
        items: [],
        addText: "+",
        children: [],
        deleteText: "-",
        containerClassNames: [],
        initialValue: null,
        hideAddAndDelete: false,
        getChildProps: (): IZeroToInfiniteProps => null,
    };

    constructor(props: IZeroToInfiniteProps) {
        super(props);
        this.state = {
            items: props.items || [],
            isDeleting: false,
            index: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IZeroToInfiniteProps): void {
        if (nextProps.items !== this.props.items) {
            this.setState({
                items: nextProps.items,
            });
        }
    }

    public addChild = (event: ReactMouseEvent): void => {
        const { items } = this.state;
        const index = (items as Record<string, unknown>[]).length;
        items.push(this.props.initialValue);
        this.setState({
            items,
        });
        if (this.props.addItem) {
            this.props.addItem(event, items, index);
        }
    };

    public onDelete(event: ReactMouseEvent): void {
        const { items, index } = this.state;
        if (index > -1) {
            items.splice(index, 1);
            this.setState({
                items,
            });
        }
        if (this.props.onDelete) {
            this.props.onDelete(event, items, index);
        }
        this.toggleModal("isDeleting", false);
    }

    public toggleModal = (modal: string, force: boolean): void => {
        this.setState({
            ...this.state,
            [modal]: force != null ? force : !this.state[modal],
        });
    };

    public renderDeleteModal = (): ReactNode => {
        const { formatMessage } = this.props.intl;
        const { isDeleting } = this.state;
        return (
            <DialogBox
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={isDeleting}
                onAction={(item) => this.onDelete(item as ReactMouseEvent)}
                onClose={() => this.toggleModal("isDeleting", false)}
                title={formatMessage(messages.confirmTitle)}
            >
                {formatMessage(messages.deleteConfirmationMessage)}
            </DialogBox>
        );
    };

    public onDeleteItem = (event: ReactMouseEvent, index: number): void => {
        if (this.props.deleteWarn) {
            this.props.deleteWarn(index) && this.toggleModal("isDeleting", true);
        } else {
            this.toggleModal("isDeleting", true);
        }
        this.setState({ index });
    };

    public childItems = (): ReactNode => {
        const { deleteText, children, getChildProps, addText, hideAddAndDelete, required } =
            this.props;
        const { items } = this.state;
        const childItems = items.map((item, index) => {
            return (
                <div className="child-row" key={`${addText}-${index}`}>
                    {hideAddAndDelete || (items.length === 1 && required) ? null : (
                        <div className="delete-link-container">
                            <NoLink
                                label={deleteText}
                                className="delete-link"
                                onClick={(event) => this.onDeleteItem(event, index)}
                            />
                        </div>
                    )}
                    {React.cloneElement(children, {
                        ...getChildProps(item, index),
                        index,
                    })}
                </div>
            );
        });
        return childItems;
    };

    render(): ReactElement {
        const { addText, containerClassNames, addButton, isDisabled, hideAddAndDelete } =
            this.props;

        return (
            <div className={classnames("zero-to-infinite-cont", ...containerClassNames)}>
                {this.childItems()}
                {hideAddAndDelete
                    ? null
                    : addButton || (
                          <div className="add-link-container">
                              <NoLink
                                  label={addText}
                                  className="add-link"
                                  onClick={this.addChild}
                                  disabled={isDisabled}
                              ></NoLink>
                          </div>
                      )}
                {this.renderDeleteModal()}
            </div>
        );
    }
}
export const ZeroToInfinite = injectIntl(ZeroToInfinite_);
