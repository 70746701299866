import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, InjectedIntl } from "react-intl";
import { messages } from "./i18n-messages";
import { Bucket, BucketHeader, ZeroToInfinite, Checkbox } from "~/core";
import { actions as unitActions, selectors as unitSelectors, unitNames } from "~/core/units";
import ScoutingDetailItem from "./scouting-detail-item";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as eventsSelectors from "~/recs-events/events/selectors";
import * as utils from "./event-scouting-utils";
import { picklistNames, selectors as picklistSelectors } from "~/core/picklist";
import { AgEventAPI, ImageUtilsAPI } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";

const { getUnitCode, UNIT_LENGTH } = unitNames;
const CATEGORY_CROP = 1;
const CATEGORY_DISEASE = 2;
const CATEGORY_INSECT = 3;
const CATEGORY_WEED = 4;
const CATEGORY_OTHER = 5;
const CATEGORY_NEMATODE = 6;

export const categories = [
    CATEGORY_CROP,
    CATEGORY_DISEASE,
    CATEGORY_INSECT,
    CATEGORY_WEED,
    CATEGORY_OTHER,
    CATEGORY_NEMATODE,
];

const CROP = "Crop";
const DISEASE = "Disease";
const INSECT = "Insect";
const WEED = "Weed";
const OTHER = "Other";
const NEMATODE = "Nematode";

export const getCategoryGroupName = (categoryIndex: number): string => {
    switch (categoryIndex) {
        case 1:
            return CROP;
        case 2:
            return DISEASE;
        case 3:
            return INSECT;
        case 4:
            return WEED;
        case 5:
            return OTHER;
        case 6:
            return NEMATODE;
        default:
            return OTHER;
    }
};
interface IScoutingDetailProps {
    countUnitData?: Array<any>;
    cropGuid: string;
    densityData?: Array<any>;
    errorCodesForEventArea: any;
    growthStageObservationData?: Map<string, Array<any>>;
    observationData?: Array<any>;
    plantLocationData?: Array<any>;
    intl: InjectedIntl;
    fetchObservationData?: () => void;
    fetchGrowthStageObservationData?: (payload: any) => void;
    fetchPicklistData: (picklists: any) => void;
    fetchUnitData?: (newProps: any) => void;
    onChildComponentChange: (newProps: any) => void;
    onNoIssuesFoundChange: (noIssuesFound: boolean) => void;
    onPushToasterMessage: (message: any, type: any) => void;
    scoutingDetailList: Array<AgEventAPI.IScoutingDetail>;
    scoutingPhotoPresignedUrlMap: Map<string, string>;
    scoutingTemplate?: any;
    unitLength?: Array<any>;
    noIssuesFound: boolean;
    disableNoIssuesFound: boolean;
}

interface IScoutingDetailState {
    errorMessagePlaceholderSet: Set<string>;
    photoMediaTypeGuid: string;
    scoutingDetailList: Array<AgEventAPI.IScoutingDetail>;
}

export class ScoutingDetail_ extends Component<IScoutingDetailProps, IScoutingDetailState> {
    constructor(props: IScoutingDetailProps) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: null,
            photoMediaTypeGuid: null,
            scoutingDetailList: [],
        };
    }

    private _getGrowthStageData(observationData, scoutingDetailList) {
        const observationGuidList = [];
        for (const observation of scoutingDetailList) {
            const categoryName = utils.getCategoryName(observationData, observation);
            if (categoryName === "weed" || categoryName === "insect") {
                observationGuidList.push(observation.observationGuid);
            }
        }
        if (observationGuidList.length > 0) {
            this.props.fetchGrowthStageObservationData({ observationGuidList });
        }
    }

    private _sortScoutingDetailsByTemplate(
        scoutingDetails: Array<AgEventAPI.IScoutingDetail>,
        templateObservations: Array<any>
    ) {
        const findTemplateOrderId = (detail: AgEventAPI.IScoutingDetail) => {
            const matchingTemplate =
                templateObservations.find((o) => o.observationGuid === detail.observationGuid) ||
                {};
            return matchingTemplate.observationOrderId != null
                ? matchingTemplate.observationOrderId
                : 100;
        };
        scoutingDetails.sort(
            (a: AgEventAPI.IScoutingDetail, b: AgEventAPI.IScoutingDetail) =>
                findTemplateOrderId(a) - findTemplateOrderId(b)
        );
        return scoutingDetails;
    }

    public onChange = (
        type: string,
        value: string,
        index: number,
        cb: (string: string) => any
    ): void => {
        const scoutingDetailList = [...this.state.scoutingDetailList];
        if (type) {
            const newScoutingDetail = {
                ...scoutingDetailList[index],
                [type]: value,
            };
            if (type === "observationGuid") {
                this.props.fetchGrowthStageObservationData({
                    observationGuidList: [value],
                });
                newScoutingDetail.growthStageGuid = "";
            }
            scoutingDetailList.splice(index, 1, newScoutingDetail);
        }

        this.setState({ scoutingDetailList }, () => {
            this.props.onChildComponentChange(this.state.scoutingDetailList);
            if (typeof cb === "function") {
                cb(value);
            }
        });
    };

    public componentDidMount(): void {
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_COUNT_UNIT]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_COUNT_UNIT
            ),
            [picklistNames.PICKLIST_NUMERIC_RATING]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_NUMERIC_RATING
            ),
            [picklistNames.PICKLIST_DENSITY_RATING]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_DENSITY_RATING
            ),
            [picklistNames.PICKLIST_LOCATIONON_PLANT]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_LOCATIONON_PLANT
            ),
            [picklistNames.PICKLIST_OBSERVATION_TYPE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_OBSERVATION_TYPE
            ),
        });
        this.props.fetchUnitData({
            [unitNames.UNIT_LENGTH]: unitNames.getUnitCode(unitNames.UNIT_LENGTH),
            [unitNames.UNIT_CROP_POPULATION]: unitNames.getUnitCode(unitNames.UNIT_CROP_POPULATION),
        });
        this.props.fetchObservationData();

        ImageUtilsAPI.getPhotoMediaTypeGuid().then((guid) => {
            this.setState({ photoMediaTypeGuid: guid });
        });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IScoutingDetailProps): void {
        if (
            nextProps.scoutingDetailList !== this.state.scoutingDetailList &&
            nextProps.scoutingDetailList
        ) {
            this.setState({
                scoutingDetailList: this._sortScoutingDetailsByTemplate(
                    nextProps.scoutingDetailList,
                    this.props.scoutingTemplate.observationInfo
                ),
            });
            this._getGrowthStageData(nextProps.observationData, nextProps.scoutingDetailList);
        }
        if (nextProps.observationData !== this.props.observationData) {
            this._getGrowthStageData(nextProps.observationData, nextProps.scoutingDetailList);
        }
        if (
            JSON.stringify(nextProps.scoutingTemplate.observationInfo) !==
            JSON.stringify(this.props.scoutingTemplate.observationInfo)
        ) {
            this.setState({
                scoutingDetailList: this._sortScoutingDetailsByTemplate(
                    this.props.scoutingDetailList,
                    nextProps.scoutingTemplate.observationInfo
                ),
            });
        }
    }

    public render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const {
            countUnitData,
            densityData,
            errorCodesForEventArea,
            growthStageObservationData,
            observationData,
            onPushToasterMessage,
            plantLocationData,
            scoutingPhotoPresignedUrlMap,
            scoutingTemplate,
            unitLength,
            noIssuesFound,
            disableNoIssuesFound,
        } = this.props;

        const { errorMessagePlaceholderSet, photoMediaTypeGuid, scoutingDetailList } = this.state;
        const { onChange } = this;
        const initialValue = {
            countUnitsGuid: "",
            eventScoutingDetailGuid: "",
            densityRatingGuid: "",
            observationCount: "",
            observationGuid: "",
            growthStageGuid: "",
            photoList: [],
            notes: "",
            trapID: "",
            weedHeight: "",
            weedHeightUnitsGuid: "",
        };

        const props = {
            countUnitData,
            densityData,
            errorCodesForEventArea,
            errorMessagePlaceholderSet,
            formatMessage,
            growthStageObservationData,
            messages,
            observationData,
            onChange,
            onPushToasterMessage,
            plantLocationData,
            photoMediaTypeGuid,
            scoutingPhotoPresignedUrlMap,
            scoutingDetailList,
            scoutingTemplate,
            unitLength,
            noIssuesFound,
        };
        return (
            <div className="scouting-event-container">
                <Bucket className="observation-section-bucket" isExpanded>
                    <BucketHeader className="scouting-event-section-header">
                        <div className="observation-header">
                            {formatMessage(messages.observationInfo)}
                        </div>
                    </BucketHeader>
                    <div className="no-issues-found">
                        <Checkbox
                            label="No Issues Found"
                            disabled={disableNoIssuesFound}
                            value={noIssuesFound}
                            onChange={(evt, value) => this.props.onNoIssuesFoundChange(value)}
                        />
                    </div>
                    <ZeroToInfinite
                        formatMessage={formatMessage}
                        getChildProps={() => null}
                        hideAddAndDelete={true}
                        items={scoutingDetailList}
                        initialValue={initialValue}
                    >
                        <ScoutingDetailItem {...props} />
                    </ZeroToInfinite>
                </Bucket>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    countUnitData: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_COUNT_UNIT)
    ),
    densityData: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_DENSITY_RATING)
    ),
    growthStageObservationData: selectors.getGrowthStageObservationData(state),
    observationData: selectors.getObservationData(state),
    plantLocationData: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_LOCATIONON_PLANT)
    ),
    scoutingTemplate: selectors.getScoutingTemplate(state),
    scoutingPhotoPresignedUrlMap: eventsSelectors.getScoutingPhotoPresignedUrlMap(state),
    unitLength: unitSelectors.getUnitPicklistOptionsFromCode(state, getUnitCode(UNIT_LENGTH)),
});

const mapDispatchToProps = (dispatch) => ({
    fetchGrowthStageObservationData: (payload) =>
        dispatch(actions.fetchGrowthStageObservationData(payload)),
    fetchObservationData: () => dispatch(actions.fetchObservationData()),
    fetchUnitData: (payload) => dispatch(unitActions.fetchUnitData(payload)),
    onPushToasterMessage: (message: any, type: any) =>
        dispatch(notificationActions.pushToasterMessage(message, type)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export const ScoutingDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(ScoutingDetail_));
