import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { ToastContainer, toast } from "react-toastify";

import { CloseIcon } from "~/core/icons";

import { ErrorDialog } from "./err-dialog";
import { messages } from "./i18n-messages";
import "./notification-container.css";

const CloseButton = ({ closeToast }) => (
    <button className="toastify__close" onClick={closeToast}>
        <CloseIcon />
    </button>
);
CloseButton.propTypes = {
    closeToast: PropTypes.func,
};

export class NotificationContainer_ extends PureComponent {
    static MAX_ERR_COUNT = 10;

    static propTypes = {
        intl: intlShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            errorList: [],
        };
    }

    _getErrDialog() {
        const { errorList } = this.state;
        if (errorList.length === 0) {
            return;
        }

        const error = errorList.slice(-1)[0];
        const onClose = () =>
            this.setState({
                errorList: errorList.slice(0, -1),
            });

        if (!Array.isArray(error)) {
            return <ErrorDialog err={error} onClose={onClose} />;
        }

        const [err, retryAction, intlMessageObj, intlMessageValues] = error;
        return (
            <ErrorDialog
                err={err}
                retryAction={retryAction}
                intlMessageObj={intlMessageObj}
                intlMessageValues={intlMessageValues}
                onClose={onClose}
            />
        );
    }

    _addApiError = ({ detail }) => {
        const { err, retryAction, intlMessageObj, intlMessageValues } = detail;
        const error = [err, retryAction, intlMessageObj, intlMessageValues];
        this.setState({ errorList: [error, ...this.state.errorList] });
    };

    _addError = ({ error }) => {
        if (error != null && this.state.errorList.length < NotificationContainer_.MAX_ERR_COUNT) {
            this.setState({ errorList: [error, ...this.state.errorList] });
        }
    };

    _addUnhandledRejection = ({ reason }) => {
        if (this.state.errorList.length < NotificationContainer_.MAX_ERR_COUNT) {
            if (reason.name === "AbortError") {
                /** Warn on aborted requests instead of throwing a blocking error */
                // When DOM requests (i.e. `fetch`) are aborted the Promise will reject
                // with an AbortError. When these promises have no rejection handler in place
                // the Window will receive an `unhandledrejection` event and end up here.
                console.warn(`${this.props.intl.formatMessage(messages.abortErrorMsg)}\n${reason}`);
            } else {
                this.setState({ errorList: [reason, ...this.state.errorList] });
            }
        }
    };

    componentDidMount() {
        window.addEventListener("api-error", this._addApiError, false);
        window.addEventListener("error", this._addError, false);
        window.addEventListener("unhandledrejection", this._addUnhandledRejection, false);
    }

    componentWillUnmount() {
        window.removeEventListener("api-error", this._addApiError, false);
        window.removeEventListener("error", this._addError, false);
        window.removeEventListener("unhandledrejection", this._addUnhandledRejection, false);
    }

    render() {
        return (
            <div className="notification-msg">
                <ToastContainer
                    bodyClassName="toastify__body"
                    className="toastify toastify--bottom-right"
                    closeButton={<CloseButton />}
                    position={toast.POSITION.BOTTOM_RIGHT}
                    progressClassName="toastify__progress"
                    toastClassName="toastify-content"
                />
                {this._getErrDialog()}
            </div>
        );
    }
}

export const NotificationContainer = injectIntl(NotificationContainer_);
