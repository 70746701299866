import { connect } from "react-redux";

import { selectors as fileImportSelectors } from "~/file-import";
import { ContextMenu } from "../../../../context-menus/item-selection-context-menu";

import * as selectors from "../../../selectors";
import * as actions from "../../../actions";

import { messages } from "../../i18n-messages";

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSelectAll: (childIfoGuidSet) =>
        dispatch(actions.selectAllImportFileInfoItems(childIfoGuidSet)),
    onClearSelected: (childIfoGuidSet) =>
        dispatch(actions.deselectAllImportFileInfoItems(childIfoGuidSet)),
    onDeleteSelected: () =>
        dispatch(actions.deleteSelectedImportFileInfoItems(ownProps.itemDimIdx)),
});

const mapStateToProps = (state, ownProps) => {
    const { canDeleteStatusCodes } = selectors.getModuleState(state);
    const dimIdxToIfoGuidSetMapSelector = selectors.getDimIdxToImportFileGuidSetMapSelector(
        ownProps.itemDimIdx
    );
    const selectedChildGuidSetSelector = selectors.getSelectedChildGuidSetSelector(
        ownProps.itemDimIdx
    );

    const getChildIfoGuidSet = () => dimIdxToIfoGuidSetMapSelector(state);
    const getSelectedChildGuidSet = () => selectedChildGuidSetSelector(state);

    const getStatusCode = (ifoGuid) =>
        fileImportSelectors.getImportFileInfo(state, ifoGuid).statusCode;

    return {
        canDeleteStatusCodes,
        getSelectAllArg: getChildIfoGuidSet,
        getSelectAllEnabled: () => getSelectedChildGuidSet().size !== getChildIfoGuidSet().size,
        getSelectedChildGuidSet,
        getStatusCode,
        getClearSelectedArg: getChildIfoGuidSet,
        getClearSelectedEnabled: () => getSelectedChildGuidSet().size > 0,
        deleteConfirmationMessage: messages.deleteFileConfirmation,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getDeleteSelectedEnabled: () => {
        const canDeleteStatusCodesSet = new Set(stateProps.canDeleteStatusCodes);
        const selectedChildGuidSet = stateProps.getSelectedChildGuidSet();
        return (
            selectedChildGuidSet.size > 0 &&
            Array.from(selectedChildGuidSet).every((ifoGuid) =>
                canDeleteStatusCodesSet.has(stateProps.getStatusCode(ifoGuid))
            )
        );
    },
});

export const AccordionItemContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ContextMenu);
