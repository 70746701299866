import { call, put, select } from "redux-saga/effects";
import { defaultActions } from "./../actions";
import { Request, APIError } from "@ai360/core";
import { getModelNameToState } from "./../utils";
import { setApiResult } from "~/core/api/actions";
import { actions as notificationActions } from "~/notifications";
// selectors
import { getTheUserGuid } from "~/login";
import { v1 as uuid } from "uuid";

export function* getNextId(action = {}, { urls, modelName, useNewApi }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: action.payload };
    try {
        let response = null;
        if (!useNewApi) {
            response = yield call(Request.post, urls.NEXT_ID, requestOptions);
        } else {
            response = yield call(Request.get, urls.NEXT_ID);
            response = response.nextId;
        }

        if (response) {
            yield put(
                defaultActions.nextIdSuccess({
                    modelName: getModelNameToState(modelName),
                    response,
                })
            );
        }
    } catch (error) {
        yield put(defaultActions.failed());
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}

export function* create(action = {}, { urls, useNewApi }) {
    if (useNewApi) {
        try {
            yield call(Request.put, `${urls.CREATE}/${uuid()}`, action.payload);
            yield put(defaultActions.success());
        } catch (error) {
            if (error instanceof APIError) {
                if (typeof error.apiResultObj.status !== "undefined") {
                    yield put(setApiResult({ newApiResults: error.apiResultObj }));
                } else {
                    yield put(setApiResult(error));
                }
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(defaultActions.failed());
        }
    } else {
        try {
            const UserGuid = yield select(getTheUserGuid);
            const requestOptions = {
                UserGuid: UserGuid,
                Model: action.payload,
            };

            yield call(Request.post, urls.CREATE, requestOptions);
            yield put(defaultActions.success());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
                yield put(notificationActions.apiCallError(error, action));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(defaultActions.failed());
        }
    }
}
