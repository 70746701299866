import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const SamplingIcon = withSvgIcon(
    <g>
        <path
            d={
                "M28.8,0.1L28.6,0l-2.2,2.2l5.2,5.2L0.1,38.8L0,38.9l2.2,2.2L33.8,9.5l5,5l0.1,0.1l2.2-2.2L28.8,0.1z" +
                "M27.9,14c0,0.2-0.1,0.4-0.2,0.6L4.3,37.9C4,38.3,3.5,38.3,3.2,38c-0.3-0.3-0.4-0.8-0.1-1.1c0,0,0.1-0.1,0.1-0.1l23.4-23.3" +
                "c0.3-0.3,0.8-0.3,1.1,0C27.8,13.6,27.9,13.8,27.9,14L27.9,14z"
            }
        />
        <path
            d={
                "M43.5,30.1L43.5,30.1c-0.1-0.2-0.3-0.4-0.5-0.5c0.2-5.4-2.8-9.8-8.9-9.8s-9,4.5-8.9,9.8" +
                "c-0.2,0.1-0.4,0.2-0.6,0.5l0,0v0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2l2.5,16.2H41l2.5-16.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1" +
                "L43.5,30.1z M25.2,29.3c0,0.2,0-0.8,0.1-1.1c0.2-1.1,0.5-2.2,1.1-3.3c1.6-2.7,4.6-3.7,7.6-3.7s6,1,7.6,3.7c0.5,1,0.9,2.1,1.1,3.3" +
                "c0,0.1,0.1,0.8,0.1,1s0,0.3,0,0.4c-1-0.4-3.3-0.8-8.8-0.8s-7.8,0.4-8.8,0.8C25.3,29.5,25.3,29.4,25.2,29.3L25.2,29.3z M41.5,30.3" +
                "c-2.5,0.3-5,0.5-7.5,0.4c-2.5,0.1-5,0-7.5-0.3c0.5-0.1,1.2-0.2,2-0.2c1.4-0.1,3.3-0.2,5.5-0.2s4.1,0.1,5.5,0.2" +
                "C40.4,30.2,41,30.2,41.5,30.3L41.5,30.3z M40,45.6H28.1l-2.4-14.4c1.2,0.3,3.6,0.6,8.3,0.6s7.1-0.3,8.3-0.6L40,45.6z"
            }
        />
    </g>,
    "0 0 43.5 46.7"
);
