import * as constants from "./constants";

export interface IDefaultValue {
    [val: string]: string | number;
}
export const defaultValue = (): IDefaultValue => ({
    [constants.PROPS_AG_EVENT_GUID]: null,
    [constants.PROPS_BRAND_ORGANIZATION_GUID]: null,
    [constants.PROPS_BRAND_ORGANIZATION_NAME]: null,
    [constants.PROPS_EVENT_PLANTING_VARIETY_HYBRID_GUID]: null,
    [constants.PROPS_IS_SEED_TREAT_INFO_EXPANDED]: null,
    [constants.PROPS_LOT_NUMBER]: null,
    [constants.PROPS_PERCENT_OF_PLANTER]: 100.0,
    [constants.PROPS_PLANTS_PER_AREA]: null,
    [constants.PROPS_PLANTS_PER_AREA_IA_GUID]: null,
    [constants.PROPS_PRICE_PER_UNIT_GUID]: null,
    [constants.PROPS_PRICE_PER_UNIT_TEXT]: null,
    [constants.PROPS_ROOTSTOCK_NAME]: null,
    [constants.PROPS_SEEDING_RATE]: "",
    [constants.PROPS_SEEDING_RATE_IA_GUID]: "",
    [constants.PROPS_SEED_COUNT]: "",
    [constants.PROPS_VARIETY_HYBRID_GUID]: null,
    [constants.PROPS_VARIETY_HYBRID_NAME]: null,
    [constants.PROPS_PRICE_PER_UNIT]: "",
});
