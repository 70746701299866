import React, { Component } from "react";
import PropTypes from "prop-types";
import { AutoReportSearch } from "./auto-report-search";
import { ZeroToInfiniteGrid } from "~/core";
import { PersonAPI } from "@ai360/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";

const PROPS_REPORT_GUID = "reportTypeGuid";
const PROPS_REPORT_NAME = "name";

class AutoReportList_ extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        classNames: PropTypes.array,
        intl: intlShape.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        personGuid: PropTypes.string,
        record: PropTypes.array,
        deleteConfirmation: PropTypes.func,
        ownerLevel: PropTypes.object,
    };
    static defaultProps = {
        record: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            currentAutoReportList: [],
            itemList: [],
        };
        this.getAutoCreateOptions();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const recordChanged =
            JSON.stringify(nextProps.record) !== JSON.stringify(this.props.record);
        if (recordChanged) {
            this.setState({
                currentAutoReportList: this.getSelectedOptionList(
                    this.state.itemList,
                    nextProps.record,
                    false
                ),
            });
        }
    }

    getAutoCreateOptions() {
        const { personGuid } = this.props;
        const userGuid = ""; //userGuid is not needed, but personGuid is needed
        if (personGuid == null) {
            return;
        }
        PersonAPI.getAutoCreateReportsList(userGuid, personGuid).then((result) => {
            this.setState({
                itemList: result,
                currentAutoReportList: this.getSelectedOptionList(result, this.props.record, false),
            });
        });
    }

    prepareAffiliatedRecords(records) {
        return records.map((record) => {
            const hasAccess = this.state.itemList.some(
                (it) => it.reportTypeGuid === record[PROPS_REPORT_GUID]
            );
            if (!hasAccess) {
                return { ...record, restrictEditDelete: true };
            }
            return record;
        });
    }

    getSelectedOptionList = (itemList, record, selected = true) => {
        let selectedItems = [];
        if (record.length > 0) {
            selectedItems = record;
        }

        const optionList = itemList.filter((item) => {
            const match = selectedItems.some((selectedItem) => {
                return selectedItem[PROPS_REPORT_GUID] === item[PROPS_REPORT_GUID];
            });
            return (selected && match) || (!selected && !match);
        });
        return optionList || [];
    };

    onChange = (item, isAdd) => {
        if (item == null) {
            return;
        }
        const { record } = this.props;
        const { itemList } = this.state;
        const value = item[PROPS_REPORT_GUID];
        let selectedValues = [...record];
        let selectedOrgLevelIndex = -1;
        let selectedOrgLevelIndexArr = [];
        const isAlreadyInList = selectedValues.some((orgLevel, index) => {
            if (orgLevel[PROPS_REPORT_GUID] === value) {
                selectedOrgLevelIndex = index;
            }
            return orgLevel[PROPS_REPORT_GUID] === value;
        });
        const removedOrgLevel = selectedValues[selectedOrgLevelIndex];
        const newOrgLevel = itemList.find((orgLevel) => {
            return orgLevel[PROPS_REPORT_GUID] === value;
        });
        if (isAdd && selectedOrgLevelIndexArr.length) {
            selectedValues = selectedValues.filter(
                (items, index) => !selectedOrgLevelIndexArr.includes(index)
            );
            selectedValues.push(newOrgLevel);
        } else if (isAdd && !isAlreadyInList) {
            selectedValues.push(newOrgLevel);
        } else {
            selectedValues.splice(selectedOrgLevelIndex, 1);
        }
        this.props.onSelectionChange(selectedValues, removedOrgLevel);
    };

    render() {
        const { apiErrors, classNames, record, deleteConfirmation } = this.props;
        const { formatMessage } = this.props.intl;
        const { currentAutoReportList, selectedValue } = this.state;
        const { onChange } = this;
        return (
            <div className={"form-section-child-stretch mini-grid auto-search-list-container"}>
                {currentAutoReportList.length === 0 ? null : (
                    <AutoReportSearch
                        classNames={classNames}
                        apiErrors={apiErrors}
                        clearOnSelection
                        containerClassName={"org-level-search-container"}
                        itemList={currentAutoReportList}
                        onSelection={(value) => this.onChange(value, true)}
                        placeholderText={formatMessage(messages.reportTypes)}
                        required
                        selectedValue={selectedValue}
                    />
                )}
                {!(record && record.length > 0) ? null : (
                    <ZeroToInfiniteGrid
                        records={this.prepareAffiliatedRecords(record)}
                        columns={{
                            [PROPS_REPORT_NAME]: {
                                // title: formatMessage(messages.name),
                                className: "org-level-name",
                            },
                        }}
                        className="org-level-grid"
                        onDelete={
                            deleteConfirmation
                                ? (option) => deleteConfirmation(option, onChange)
                                : (option) => onChange(option, false)
                        }
                        showHeader={false}
                    />
                )}
            </div>
        );
    }
}

export const AutoReportList = injectIntl(AutoReportList_);
