export const PROPS_ACTIVE_YN = "activeYn";
export const PROPS_AREA_LIMIT = "areaLimit";
export const PROPS_BRANDING_YN = "brandingYn";
export const PROPS_COPYRIGHT_HOLDER = "copyrightHolder";
export const PROPS_ID = "id";
export const PROPS_PRIVACY_POLICY_TEXT = "privacyPolicyText";
export const PROPS_PRIVACY_POLICY_TITLE = "privacyPolicyTitle";
export const PROPS_PRIVACY_POLICY_UPDATED_DATE = "privacyPolicyUpdatedDate";
export const PROPS_SYSTEM_FAVICON = "systemFavicon";
export const PROPS_SYSTEM_GUID = "systemGuid";
export const PROPS_SYSTEM_LOGO = "brandLogo";
export const PROPS_SYSTEM_LOGO_DARK = "systemLogoDark";
export const PROPS_SYSTEM_LOGO_LIGHT = "systemLogoLight";
export const PROPS_SYSTEM_NAME = "systemName";
export const PROPS_BRANDING_NAME = "brandingName";
export const PROPS_TEST_RESULT_ISSUES_EMAIL_LIST = "testResultIssuesEmailList";
export const PROPS_EMAIL_ADDRESS = "emailAddress";
export const PROPS_SEND_RESULTS_TO_EMAIL = "sendResultsToEmailAddress";
