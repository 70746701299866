import type MapView from "@arcgis/core/views/MapView";
import {
    MultiLayer,
    ILayerOptions,
    Definition as LayerDefinition,
    Datum as LayerDatum,
} from "@ai360/core";
import Extent from "@arcgis/core/geometry/Extent";

import { ReferenceAPI } from "@ai360/core";

export class CadastralDataManager {
    get previousExtent(): Extent {
        return this.previousExtentValue;
    }

    private readonly definitions: Map<string, Definition>;

    private readonly layer: MultiLayer;

    private previousExtentValue: Extent;

    constructor(definitions: LayerDefinition[], map: MapView, baseOptions: ILayerOptions) {
        this.definitions = new Map<string, Definition>(
            definitions.map((definition) => [definition.name, definition] as [string, Definition])
        );
        this.layer = new MultiLayer("Cadastral", map, { ...baseOptions }, definitions);
    }

    update(extent: Extent): Promise<unknown> {
        this.previousExtentValue = extent;

        const fetchers: Promise<LayerDatum>[] = this.layer
            .allVisible()
            .map((name) => this.definitions.get(name))
            .filter((d) =>
                d.items.some((i) => i.geometry && i.geometry.minScale >= this.layer.mapView.scale)
            )
            .map((definition) =>
                ReferenceAPI[definition.fetchFromApi](extent).then((fetched) => ({
                    name: definition.name,
                    values: fetched,
                }))
            );

        return Promise.all(fetchers).then((data) => this.layer.setData(data));
    }

    updateLayerInfos(layerInfos: unknown): void {
        this.layer.updateLayerInfos(layerInfos);
    }
}

export interface Definition extends LayerDefinition {
    fetchFromApi: string;
}
