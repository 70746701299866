import PropTypes from "prop-types";
import { model } from "~/admin/agBytes/observation/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_OBSERVATION_ID]: PropTypes.number,
    [model.PROPS_OBSERVATION_GUID]: PropTypes.string,
    [model.PROPS_OBSERVATION_NAME]: PropTypes.string,
    [model.PROPS_OBSERVATION_PARENT_TYPE]: PropTypes.string,
    [model.PROPS_OBSERVATION_TYPE]: PropTypes.string,
    [model.PROPS_OBSERVATION_PARENT_NAME]: PropTypes.string,
    [model.PROPS_OBSERVATION_GROUP_NAME]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
};

export default PropTypes.shape(shape);
