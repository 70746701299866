import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { getAgBytesErrorClassNames } from "~/admin/utils";
import { messages } from "../../../../i18n-messages";

// Components
import { SubSection, TextInput } from "~/core";

//Style
import "./email.css";

export const EMAIL_ERROR_CODE_LIST = [2085, 17];

const INVALID_EMAIL = "Invalid Email";

interface EmailItemProps {
    apiErrors: any[];
    apiErrorModel: any;
    intl: intlShape;
    index: number;
    email: any[];
    onChange?: (type, value: string, index: number) => void;
    onPrimaryChange: () => void;
    emailListAlias: string;
    emailAlias: string;
    required: boolean;
}

export class EmailItem extends Component<EmailItemProps, null> {
    getContainerClass = (type) => {
        const { email, index } = this.props;
        if (email[index].isError) {
            const { apiErrors, apiErrorModel } = this.props;
            let isApiError = false;
            if (apiErrorModel) {
                isApiError = EMAIL_ERROR_CODE_LIST.some(
                    (code) => apiErrorModel[code] && apiErrorModel[code].includes(type)
                );
            }
            return [
                classNames(
                    "email-container",
                    isApiError
                        ? getAgBytesErrorClassNames(EMAIL_ERROR_CODE_LIST, apiErrors)
                        : "form-input-error"
                ),
            ];
        }
        return ["email-container"];
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { index, onChange, required, email } = this.props;
        const hasError = email[index].isError;
        return (
            <SubSection>
                <TextInput
                    required={required}
                    errorText={hasError ? INVALID_EMAIL : null}
                    hasError={hasError}
                    tabIndex={0}
                    maxLength={254}
                    placeholderText={formatMessage(messages.email)}
                    onChange={(value) => onChange({ type: this.props.emailAlias }, value, index)}
                    value={email[index][this.props.emailAlias]}
                    containerClassNames={this.getContainerClass(
                        email[index][this.props.emailAlias]
                    )}
                />
            </SubSection>
        );
    }
}

export default injectIntl(EmailItem);
