export const OPEN = "window/OPEN";
export const OPENED = "window/OPENED";
export const REDIRECT = "window/REDIRECT";
export const CLOSE = "window/CLOSE";
export const CLOSING = "window/CLOSING";

export interface IWindowAction {
    type: string;
    url?: string;
    name?: string;
    window?: Window;
}

export const open = (name: string, url: string): IWindowAction => ({
    type: OPEN,
    name,
    url,
});

export const opened = (name: string, window: Window): IWindowAction => ({
    type: OPEN,
    name,
    window,
});

export const redirect = (name: string, url: string): IWindowAction => ({
    type: REDIRECT,
    name,
    url,
});

export const close = (name: string): IWindowAction => ({
    type: CLOSE,
    name,
});

export const closing = (name: string): IWindowAction => ({
    type: CLOSING,
    name,
});
