import { connect } from "react-redux";
import TelematicsSetupView from "./telematic-setup";
import * as actions from "~/admin/setup/account/data/actions";
import * as selectors from "~/admin/setup/account/data/selectors";
import { getTheUserGuid } from "~/login/selectors";

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
    onsiteEquipmentList: selectors.getOnsiteEquipmentList(state),
});
const mapDispatchToProps = () => ({
    getOnsiteEquipmentList: actions.getOnSiteEquipmentList,
});
export const TelematicsSetup = connect(mapStateToProps, mapDispatchToProps)(TelematicsSetupView);
