import React, { Component } from "react";
import PropTypes from "prop-types";
import { intlShape } from "react-intl";
import classnames from "classnames";
import EsriMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Extent from "@arcgis/core/geometry/Extent";
import Draw from "@arcgis/core/views/draw/Draw";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import Popup from "@arcgis/core/widgets/Popup";

import { Loader, LoaderTypes } from "~/core";
import { MSGTYPE } from "~/notifications";

import { LinkedMapGroupManagerType } from "../linked-map-group-manager";
import { messages } from "../../map-control/i18n-messages";
import {
    ListenerManager,
    BasemapUtils,
    TileLayer,
    SampleSitesLayer,
    SurfaceLayer,
    MapConfig,
    DggLayer,
    Cursor,
    GeometryMath,
    SymbolUtils,
    JSONUtils,
} from "@ai360/core";

import { AppHelpers } from "@ai360/core";

import "./linked-map-control.css";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import { logFirebaseEvent } from "~/utils/firebase";

export class LinkedMapControl_ extends Component {
    static propTypes = {
        basemap: PropTypes.string,
        children: PropTypes.node,
        className: PropTypes.string,
        fieldGuid: PropTypes.string,
        id: PropTypes.string,
        imageryTileBucketName: PropTypes.string.isRequired,
        intl: intlShape.isRequired,
        isLoading: PropTypes.bool,
        layer: PropTypes.object,
        linkedMapGroupManager: LinkedMapGroupManagerType.isRequired,
        onPushToasterMessage: PropTypes.func.isRequired,
        onUpdateDgg: PropTypes.func,
        onUpdateDggGroup: PropTypes.func,
        onUpdateEventSurfaceStats: PropTypes.func,
        onFetchTableRecords: PropTypes.func,
        showSampleSites: PropTypes.bool,
        surface: PropTypes.object,
        updateTableRecords: PropTypes.bool,
        userGuid: PropTypes.string.isRequired,
        activateZoneSplit: PropTypes.bool,
        onSetActivateZoneSplit: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this._listeners = new ListenerManager();
        this.isInfoMode = false;
        this.isDrawing = false;

        this._handleError = this._handleError.bind(this);
        this.state = {
            isLoading: true,
            isLoadingSampling: false,
            currentMapPoint: null,
        };
        this.currentDrawGraphic = null;
        this.isPointerDown = false;
        this.points = [];
        this.isValid = true;
        this.lineSymbol = SymbolUtils.fromJSON(
            JSONUtils.createSimpleLineSymbolJSON([64, 255, 255, 180], 2)
        );
        this.invalidSymbol = SymbolUtils.fromJSON(
            JSONUtils.createSimpleFillSymbolJSON([64, 255, 255, 180], [255, 0, 0], 2, "esriSLSDash")
        );
    }

    _clearDgg() {
        const { linkedMapGroupManager } = this.props;
        this.updateDgg(null);
        if (!linkedMapGroupManager.userDefined) {
            this.updateDggGroup(null);
        }
    }

    _createLayers(fieldGuid) {
        const { linkedMapGroupManager, imageryTileBucketName, intl } = this.props;
        const { formatMessage } = intl;
        const baseOptions = this._getBaseLayerOptions();

        this.surfaceLayer = new SurfaceLayer(`SL_${fieldGuid}`, this.mapView, {
            ...baseOptions,
            fieldGuid,
            bypassBoundaryFetch: true,
        });
        this.tileLayer = new TileLayer(`TL_${fieldGuid}`, this.mapView, {
            ...baseOptions,
            fieldGuid,
            imageryLayerGuid: "00000000-0000-0000-0000-000000000000",
            imageryTileBucketName,
        });
        this.sampleSitesLayer = new SampleSitesLayer(`SS_${fieldGuid}`, this.mapView, {
            ...baseOptions,
        });
        this.dggLayer = new DggLayer(`DGG_${fieldGuid}`, this.mapView, {
            ...baseOptions,
            onUpdateMGRS: (mgrs) => linkedMapGroupManager.updateMGRS(mgrs, this.props.id),
            onUpdateMGRSGroup: (graphicProperties) =>
                linkedMapGroupManager.updateMGRSGroup(
                    graphicProperties,
                    this.props.id,
                    linkedMapGroupManager.mapHasZonePolygon || this.props.activateZoneSplit,
                    !linkedMapGroupManager.clearFreeHandPolygon
                ),
        });
        this.mapView.popup = new Popup({
            content: formatMessage(messages.freehandPolyTooltip),
            alignment: "bottom-right",
        });
        this.mapView.popup.actions = [];

        this._setLoading(false);
    }

    _getBaseLayerOptions() {
        const { onPushToasterMessage, userGuid } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        return {
            formatMessage,
            formatNumber,
            onPushToasterMessage,
            userGuid,
        };
    }

    _handleError(error) {
        console.error(error);
    }

    _removeListeners() {
        this._listeners.removeAll();
        this.toolbar = null;
    }

    _setLoading(isLoading) {
        this.setState({ isLoading });
    }

    _setLoadingSampling(isLoadingSampling) {
        this.setState({ isLoadingSampling });
    }

    checkEnableInfo = (evt) => {
        if (evt.type === "key-down" && !this.isPointerDown) {
            const { altKey, ctrlKey } = evt.native;
            if ((altKey || ctrlKey) && !this.isInfoMode) {
                const { currentMapPoint } = this.state;
                const { linkedMapGroupManager } = this.props;
                this.isInfoMode = true;
                if (linkedMapGroupManager.mapHasZonePolygon && ctrlKey) {
                    linkedMapGroupManager.setMapHasZonePolygon(false);
                    this.updateMGRSGroup(this._initializeGraphicProperties());
                }
                this.props.onSetActivateZoneSplit(false);
                this._initializeDrawTool();
                if (currentMapPoint) {
                    this.mapView.popup.location = currentMapPoint;
                    this.mapView.popup.visible = true;
                    this.updateMGRS.apply(this, linkedMapGroupManager.getMGRSCell(currentMapPoint));
                }
            }
        }
    };

    _handleVertexAdd = (evt) => {
        if (evt.vertexIndex === 0) {
            this.isDrawing = true;
            this.mapView.popup.visible = false;
        }
        this.mapView.graphics.remove(this.currentDrawGraphic);
        const poly = {
            type: "polygon",
            rings: evt.vertices,
            spatialReference: this.mapView.spatialReference,
        };

        this.currentDrawGraphic = new Graphic({
            geometry: poly,
            symbol: this.dggLayer.userDefinedPolygonSymbol,
        });

        this.mapView.graphics.add(this.currentDrawGraphic);
    };

    // create a new graphic presenting the polyline that is being drawn on the view
    drawZoneSplit(event) {
        let symbol = this.dggLayer.userDefinedPolylineSymbol;

        this.mapView.graphics.remove(this.currentDrawGraphic);
        const zoneSplitPolyline = {
            type: "polyline",
            paths: event.vertices,
            spatialReference: this.mapView.spatialReference,
        };

        this.currentDrawGraphic = new Graphic({
            geometry: zoneSplitPolyline,
        });

        if (event.vertices.length > 1) {
            if (this._isSelfIntersecting(this.currentDrawGraphic.geometry)) {
                this.isValid = false;
                symbol = this.invalidSymbol;
            } else {
                this.isValid = true;
            }

            if (this._isCrossingFieldBoundary(this.currentDrawGraphic.geometry)) {
                symbol = this.lineSymbol;
            }
        }

        if (this.currentDrawGraphic) {
            this.currentDrawGraphic.symbol = symbol;
        }

        this.mapView.graphics.add(this.currentDrawGraphic);
    }

    _initializeGraphicProperties() {
        const graphicNullProperties = {
            mgrss: null,
            polygon: null,
            cells: null,
            symbol: null,
            attributes: null,
        };

        return graphicNullProperties;
    }

    _initializeZoneSplitDrawTool = () => {
        const { linkedMapGroupManager } = this.props;

        this.drawAction = this.toolbar.create("polyline");

        if (linkedMapGroupManager.mapHasFreeHandPolygon) {
            linkedMapGroupManager.setMapHasFreeHandPolygon(false);
            this.updateMGRSGroup(this._initializeGraphicProperties());
        }

        this.drawAction.on(["vertex-add", "cursor-update"], (evt) => {
            this.drawZoneSplit(evt);
        });

        this.mapView.on("drag", (evt) => {
            if (this.props.activateZoneSplit) {
                // prevents panning with the mouse drag event
                evt.stopPropagation();
            }
        });

        this.mapView.on("double-click", (evt) => {
            if (
                this.props.linkedMapGroupManager.mapHasZonePolygon ||
                this.props.activateZoneSplit
            ) {
                evt.stopPropagation();
            }
        });
    };

    _isCrossingFieldBoundary(polyline) {
        const { linkedMapGroupManager } = this.props;
        if (polyline.paths && polyline.paths[0].length < 3) {
            return false;
        }
        const fieldBoundary = geometryEngine.union(
            linkedMapGroupManager.features.map((feat) => {
                return feat.geometry;
            })
        );
        return geometryEngine.crosses(fieldBoundary, polyline);
    }

    _startEndOutsideFieldBoundary(polyline, isBoth) {
        const { linkedMapGroupManager } = this.props;
        if (polyline.paths && polyline.paths[0].length < 2) {
            return false;
        }
        const fieldBoundary = geometryEngine.union(
            linkedMapGroupManager.features.map((feat) => {
                return feat.geometry;
            })
        );

        const endOutside = !geometryEngine.contains(
            fieldBoundary,
            polyline.getPoint(0, polyline.paths[0].length - 1)
        );
        const startOutside = !geometryEngine.contains(fieldBoundary, polyline.getPoint(0, 0));

        return isBoth ? startOutside && endOutside : startOutside || endOutside;
    }

    _isSelfIntersecting(polyline) {
        if (polyline.paths && polyline.paths[0].length < 3) {
            return false;
        }
        const line = polyline.clone();
        //get the last segment from the polyline that is being drawn
        const lastSegment = this.getLastSegment(polyline);
        line.removePoint(0, line.paths[0].length - 1);
        // returns true if the line intersects itself, false otherwise
        return geometryEngine.crosses(lastSegment, line);
    }

    getLastSegment(polyline) {
        const line = polyline.clone();
        const lastXYPoint = line.removePoint(0, line.paths[0].length - 1);
        const existingLineFinalPoint = line.getPoint(0, line.paths[0].length - 1);

        return {
            type: "polyline",
            spatialReference: this.mapView.spatialReference,
            hasZ: false,
            paths: [
                [
                    [existingLineFinalPoint.x, existingLineFinalPoint.y],
                    [lastXYPoint.x, lastXYPoint.y],
                ],
            ],
        };
    }

    _addMouseLocation(evt) {
        const { linkedMapGroupManager } = this.props;
        if (linkedMapGroupManager.mapHasFreeHandPolygon || this.props.activateZoneSplit) {
            let currentPoint = this.mapView.toMap(evt);
            const dblClick =
                Math.abs(this.clickX - evt.native.screenX) <= 4 &&
                Math.abs(this.clickY - evt.native.screenY) <= 4;
            if (!this.isValid) {
                evt.stopPropagation();
                return;
            }
            if (dblClick) {
                this.clickX = undefined;
                this.clickY = undefined;
            } else {
                this.clickX = evt.native.screenX;
                this.clickY = evt.native.screenY;
            }

            const originalPolyline = this._getPolyline(
                [...this.points],
                this.mapView.spatialReference
            );

            if (
                !dblClick ||
                (this._startEndOutsideFieldBoundary(originalPolyline, false) &&
                    !this._startEndOutsideFieldBoundary(originalPolyline, true))
            ) {
                this._addPoint(currentPoint);
            } else {
                if (this.points && this.points.length > 1) {
                    let poly;

                    if (
                        this.points.length > 2 &&
                        !this._startEndOutsideFieldBoundary(originalPolyline, true)
                    ) {
                        poly = this._getPolyline(
                            [...this.points, this.points[0]],
                            this.mapView.spatialReference
                        );
                    } else {
                        poly = originalPolyline;
                    }
                    logFirebaseEvent("split_screen_compare_split_tool_stats");
                    this._setLoading(true);
                    setTimeout(() => {
                        this._ZoneSplitPolygons(poly);
                        this._setLoading(false);
                    }, 250);
                }
            }
        }
    }

    _ZoneSplitPolygons(poly) {
        const { linkedMapGroupManager } = this.props;
        const polygonObjects = linkedMapGroupManager.getMGRSZoneCells(poly);
        if (polygonObjects && polygonObjects.length > 0) {
            if (polygonObjects.every((x) => x !== null)) {
                const result = polygonObjects.sort(
                    (b, a) =>
                        GeometryMath.calculateArea(a.polygon) -
                        GeometryMath.calculateArea(b.polygon)
                );
                linkedMapGroupManager.userDefinedPolygons = [];
                this.updateMGRSGroup(this._initializeGraphicProperties());
                result.forEach((element) => {
                    if (element) {
                        const graphicProperties = {
                            mgrss: element.mgrss,
                            rawMGRS: element.rawMGRS,
                            polygon: element.polygon,
                            cells: element.cells,
                            rawCells: element.rawCells,
                            symbol: element.symbol,
                            attributes: element.attributes,
                        };

                        this.updateMGRSGroup(graphicProperties);
                    }
                });
            }
        }

        if (this.currentDrawGraphic) {
            this.mapView.graphics.remove(this.currentDrawGraphic);
            this.currentDrawGraphic = null;
        }
        this.props.onSetActivateZoneSplit(false);
        linkedMapGroupManager.setMapHasZonePolygon(true);
        this.points = [];
        this.drawAction?.destroy();
        this.drawAction = null;
    }

    _initializeDrawTool = () => {
        this.drawAction = this.toolbar.create("polygon", { mode: "freehand" });

        this.drawAction.on("vertex-add", (evt) => {
            this._handleVertexAdd(evt);
        });

        this.drawAction.on("draw-complete", (evt) => {
            logFirebaseEvent("split_screen_compare_ctrl_key_stats");
            this.isDrawing = false;
            if (evt.vertices.length > 3) {
                const { linkedMapGroupManager } = this.props;
                const poly = new Polygon({
                    type: "polygon",
                    rings: evt.vertices,
                    spatialReference: this.mapView.spatialReference,
                });
                linkedMapGroupManager.setMapHasFreeHandPolygon(true);
                this.updateMGRSGroup.apply(this, linkedMapGroupManager.getMGRSCells(poly));
            }
            if (this.currentDrawGraphic) {
                this.mapView.graphics.remove(this.currentDrawGraphic);
                this.currentDrawGraphic = null;
            }
            this.drawAction?.destroy();
            this.drawAction = null;
        });
    };

    _setCurrentMapPoint = (currentMapPoint) => {
        this.setState({ currentMapPoint });
    };

    disableInfoMode = (evt) => {
        const { altKey, ctrlKey, buttons } = evt.native;
        if (!(altKey || ctrlKey) || buttons === 0) {
            if (this.isInfoMode) {
                if (!this.isDrawing && this.drawAction) {
                    this.drawAction.destroy();
                    this.drawAction = null;
                }
                this.isInfoMode = false;
                this.dggLayer.clear();
                this.mapView.popup.visible = false;
            }
        }
    };

    _focusMapView(evt) {
        evt.native.target.focus();
    }

    _setupPolyLineListeners() {
        this.toolbar = new Draw({
            view: this.mapView,
        });

        this._initializeZoneSplitDrawTool();

        this.mapView.on("pointer-enter", this._focusMapView);

        if (this.activateZoneSplit) {
            this.mapView.container.style.cursor = Cursor.crosshair;
        }
    }

    _setupListeners() {
        this.toolbar = new Draw({
            view: this.mapView,
        });

        this.mapView.on(
            "pointer-move",
            AppHelpers.debounce((evt) => {
                const mapPoint = this.mapView.toMap(evt);
                this._setCurrentMapPoint(mapPoint);
                if (this.isInfoMode) {
                    const { altKey, ctrlKey, buttons } = evt.native;
                    if (altKey || ctrlKey) {
                        if (buttons === 0) {
                            this.mapView.popup.location = mapPoint;
                            this.updateMGRS.apply(
                                this,
                                this.props.linkedMapGroupManager.getMGRSCell(mapPoint)
                            );
                        }
                    }
                } else if (!this.isDrawing) {
                    if (this.dggLayer.crosshairLayer.graphics.length) {
                        this.dggLayer.clear();
                    }
                }
            }),
            0
        );

        this.mapView.on("mouse-wheel", (evt) => {
            if (this.isInfoMode) {
                evt.stopPropagation();
            } else {
                this._setCurrentMapPoint(this.mapView.toMap(evt));
            }
        });

        this.mapView.on("pointer-up", (evt) => {
            this.isPointerDown = false;
            this.disableInfoMode(evt);
        });
        this.mapView.on("key-up", this.disableInfoMode);
        this.mapView.on("pointer-leave", this.disableInfoMode);
        this.mapView.on("pointer-enter", this._focusMapView);
        this.mapView.on("key-down", this.checkEnableInfo);

        this.mapView.on("pointer-down", () => {
            this.isPointerDown = true;
            //IE has a problem losing focus on inputs when interacting with the map, so we have to manually do it:
            if (document.activeElement && document.activeElement.blur) {
                document.activeElement.blur();
            }
            if (this.isInfoMode) {
                this.dggLayer.clear();
            }
        });
    }

    _updateVisibleSampleSitesLayers(props) {
        this.mapView.when(() => {
            const { layer, onPushToasterMessage, showSampleSites, surface } = props;
            const { formatMessage } = this.props.intl;

            if (layer && layer.isSampling && showSampleSites) {
                this._setLoadingSampling(true);
                const { depthId, surfaceGuid } = surface
                    ? surface
                    : { depthId: null, surfaceGuid: "" };
                this.sampleSitesLayer
                    .update(layer, surfaceGuid, depthId)
                    .catch((err) => {
                        console.warn(err);
                        onPushToasterMessage(
                            formatMessage(messages.failedToLoadSampleSites),
                            MSGTYPE.ERROR
                        );
                    })
                    .finally(() => this._setLoadingSampling(false));
            } else if (this.sampleSitesLayer != null) {
                this.sampleSitesLayer.hide();
            }
        });
    }

    _updateVisibleSurface(props) {
        const { linkedMapGroupManager, surface, onPushToasterMessage, fieldGuid } = props;
        const { formatMessage } = this.props.intl;

        if (surface != null) {
            this._setLoading(true);
            const promises = [];
            if (surface.imageryLayerGuid) {
                if (this.surfaceLayer) {
                    this.surfaceLayer.clear();
                }
                if (this.tileLayer) {
                    promises.push(
                        this.tileLayer.update(surface.importFileGuid, fieldGuid).catch((err) => {
                            console.warn(err);
                            onPushToasterMessage(
                                formatMessage(messages.failedToLoadSurface),
                                MSGTYPE.ERROR
                            );
                        })
                    );
                }
            } else {
                if (this.tileLayer) {
                    this.tileLayer.hide();
                }

                promises.push(linkedMapGroupManager.addSurfaceDgg(surface));
                if (this.surfaceLayer) {
                    promises.push(
                        this.surfaceLayer.update(surface).catch((err) => {
                            console.warn(err);
                            onPushToasterMessage(
                                formatMessage(messages.failedToLoadSurface),
                                MSGTYPE.ERROR
                            );
                        })
                    );
                }
            }

            Promise.all(promises).finally(() => {
                this._clearDgg();
                const { userDefined, mapHasZonePolygon } = linkedMapGroupManager;
                if (userDefined && userDefined.mgrss != null) {
                    const graphicProperties = {
                        mgrss: userDefined.mgrss,
                        polygon: userDefined.polygon,
                        cells: userDefined.cells,
                        symbol: userDefined.symbol,
                    };
                    linkedMapGroupManager.updateMGRSGroup(
                        graphicProperties,
                        null,
                        mapHasZonePolygon,
                        false
                    );
                }
                if (this.sampleSitesLayer) {
                    this._updateVisibleSampleSitesLayers(props);
                }
                this._setLoading(false);
            });
        } else {
            if (this.tileLayer) {
                this.tileLayer.hide();
            }
            if (this.surfaceLayer) {
                this.surfaceLayer.clear();
            }
        }
    }

    setFieldFeatures(fieldGuid, features) {
        if (this.surfaceLayer) {
            this.surfaceLayer.setFieldBoundaryFeatures(features);
        }
    }

    updateCrosshairs(graphic) {
        if (this.dggLayer) {
            this.dggLayer.updateCrosshairs(graphic);
        }
    }

    updateDgg(dggInfo) {
        const { onUpdateDgg } = this.props;
        if (onUpdateDgg) {
            onUpdateDgg(dggInfo);
        }
    }

    updateDggGroup(dggInfo) {
        const {
            linkedMapGroupManager,
            onUpdateDggGroup,
            onUpdateEventSurfaceStats,
            updateTableRecords,
        } = this.props;

        if (onUpdateDggGroup) {
            if (updateTableRecords) {
                onUpdateEventSurfaceStats(linkedMapGroupManager.getUserDefinedPolyJson());
            }
            onUpdateDggGroup(dggInfo);
        }
    }

    updateMGRS(mgrs, cell) {
        if (this.dggLayer) {
            this.dggLayer.updateMGRS(mgrs, cell);
        }
    }

    updateMGRSGroup(graphicProperties) {
        if (this.dggLayer) {
            this.dggLayer.updateMGRSGroup(graphicProperties);
        }
    }

    updateUserDefinedPoly(graphicProperties) {
        if (this.dggLayer) {
            this.dggLayer.updateUserDefinedPoly(graphicProperties);
        }
    }

    componentDidMount() {
        const { fieldGuid, id, linkedMapGroupManager } = this.props;

        this.map = new EsriMap({
            autoResize: true,
            basemap: BasemapUtils.getBasemapById(this.props.basemap),
            // Although `extent` is "optional" in the documentation, without it you get
            //  an (arguably harmless) exception because `map.spatialReference` is undefined
            extent: new Extent({ spatialReference: { wkid: 102100 } }),
            center: MapConfig.defaults.center,
            zoom: MapConfig.defaults.zoom,
        });

        this.mapView = new MapView({
            id,
            map: this.map,
            container: this.mapDiv,
            center: MapConfig.defaults.center,
            zoom: MapConfig.defaults.zoom,
            constraints: {
                rotationEnabled: false,
                snapToZoom: true,
                lods: MapConfig.lods,
            },
            ui: {
                components: ["attribution"],
            },
            navigation: {
                momentumEnabled: false,
            },
        });

        if (id != null && window.process_env.NODE_ENV === "development") {
            window[id] = this.map;
        }

        linkedMapGroupManager.addMap(this);

        this.mapView.when(() => {
            this._createLayers(fieldGuid);
            linkedMapGroupManager.mapsLoadedCount++;
            linkedMapGroupManager._setMapFieldFeatures();
            this._setupListeners();
            this._listeners.add(this.mapView.on("pointer-up", this._addMouseLocation.bind(this)));
            if (this.props.surface == null) {
                this._setLoading(false);
            } else {
                //Surface is pre-loaded:
                this._updateVisibleSurface(this.props);
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const visibleSurfaceStr = JSON.stringify(nextProps.surface);
        let visibleSurfaceChanged = false;
        if (this.visibleSurface !== visibleSurfaceStr) {
            visibleSurfaceChanged = true;
            this.visibleSurface = visibleSurfaceStr;
            this._updateVisibleSurface(nextProps);
        }

        if (nextProps.showSampleSites !== this.props.showSampleSites || visibleSurfaceChanged) {
            this._updateVisibleSampleSitesLayers(nextProps);
        }

        if (nextProps.layer !== this.props.layer && this.sampleSitesLayer) {
            if (!nextProps.layer) {
                this.sampleSitesLayer.clear();
            } else if (nextProps.layer.isSampling) {
                this._updateVisibleSampleSitesLayers(nextProps);
            }
        }

        if (nextProps.activateZoneSplit !== this.props.activateZoneSplit) {
            if (nextProps.activateZoneSplit) {
                this.activateZoneSplit = true;
                this._setupPolyLineListeners();
                this.points = [];
            } else if (!nextProps.activateZoneSplit) {
                this.activateZoneSplit = false;
                this.drawAction?.destroy();
                this.mapView.container.style.cursor = Cursor.pointer;
            }
        }
    }

    componentWillUnmount() {
        this._removeListeners();
        this.props.linkedMapGroupManager.deleteMap(this);
        this.mapView.destroy();
    }

    render() {
        const { basemap, children, className } = this.props;
        const { isLoading, isLoadingSampling } = this.state;
        return (
            <div className={classnames("map-container", className, `${basemap}-basemap`)}>
                {children}
                <div className="linked-map-div" ref={(m) => (this.mapDiv = m)} />
                {!isLoading && !isLoadingSampling ? null : (
                    <Loader type={LoaderTypes.BALL_SCALE_RIPPLE_MULTIPLE} />
                )}
            </div>
        );
    }

    _getPolyline(points, spatialReference) {
        return new Polyline({
            paths: [
                points.map((pt) => {
                    return [pt.x, pt.y];
                }),
            ],
            spatialReference: spatialReference,
        });
    }

    _addPoint(point) {
        this.points.push(point);
    }
}
