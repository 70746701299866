import React, { Component, ReactElement } from "react";
import classnames from "classnames";
import { IToolbarTool } from "@ai360/core";

export interface ToggleEvent {
    index?: number;
    expanded?: boolean;
    selected?: boolean;
    tool?: ToolbarTool;
}
export interface IToolbarToolProps {
    _index?: number;
    _selected?: boolean;
    _onToggle?: (evt: ToggleEvent) => void;
    className: string;
    disabled: boolean;
    icon?: ReactElement;
    label?: string;
    onToggle?: (evt: ToggleEvent) => void;
}
export class ToolbarTool extends Component<IToolbarToolProps> implements IToolbarTool {
    static defaultProps = {
        className: "",
        disabled: false,
        label: "Tool",
    };

    public isSelected(): boolean {
        return !!this.props._selected;
    }

    public toggle(forceSelected?: boolean, silent = false): void {
        const evt = {
            index: this.props._index,
            selected: forceSelected != null ? forceSelected : !this.props._selected,
            tool: this,
        };
        if (!silent && !this.props.disabled && this.props.onToggle) {
            this.props.onToggle(evt);
        }
        if (!this.props.disabled && this.props._onToggle) {
            this.props._onToggle(evt);
        }
    }

    public select(): void {
        this.toggle(true);
    }

    public unselect(silent = false): void {
        this.toggle(false, silent);
    }

    render(): JSX.Element {
        const { _selected, className, disabled, icon, label } = this.props;
        return (
            <div
                className={classnames(
                    "toolbar-item",
                    "toolbar-tool",
                    { selected: _selected },
                    { disabled }
                )}
                onClick={() => this.toggle()}
            >
                <div className={classnames("toolbar-item-icon", className)}>{icon}</div>
                <span className="toolbar-item-text">{label}</span>
            </div>
        );
    }
}
