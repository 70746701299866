/** react & basic imports */
import React, { Component } from "react";
import PropTypes from "prop-types";

/** grouped library imports */
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";

/** ~ imports */
import { getAgBytesErrorClassNames, onTextChange } from "~/admin/utils";
import { TextInput, Section, SubSection, TreeView, Checkbox, SelectInput } from "~/core";
import { Messages } from "~/navigation/i18n-messages";
import { SUCCESS, FAILURE, PENDING } from "~/hocs/needs/utils";

/** relative imports */
import { model } from "./../data";
import { defaultTreeObject } from "./default-tree";
import { messages } from "../../i18n-messages";
import CustomPropTypes from "~/utils/proptypes";

/** css imports */
import "./add-edit-panel.css";

export class AddEditPanel extends Component {
    /** static variables/functions */
    static propTypes = {
        apiErrors: PropTypes.array,
        actions: PropTypes.object,
        addEditPanel: PropTypes.object.isRequired,
        apiError: PropTypes.object,
        closeSidePanel: PropTypes.func,
        copyRole: PropTypes.bool,
        copyRoleMode: PropTypes.bool,
        createUserRole: PropTypes.func,
        fetchData: PropTypes.bool,
        fetchRecord: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.userRole,
        recordGuid: PropTypes.string,
        requestIds: PropTypes.object,
        setBreadcrumbs: PropTypes.func,
        status: PropTypes.string,
        systemName: PropTypes.string,
        toggleCopyRole: PropTypes.func,
        toggleCopyRoleMode: PropTypes.func,
        toggleFetchData: PropTypes.func,
    };
    static accessLevelDetails = {
        1: { label: 1 },
        2: { label: 2 },
        3: { label: 3 },
        4: { label: 4 },
        5: { label: 5 },
        6: { label: 6 },
        7: { label: 7 },
        8: { label: 8 },
        9: { label: 9 },
        10: { label: 10 },
    };
    /** constructor */
    constructor(props) {
        super(props);
        this.userRole = {};
        this.state = {
            treeObject: [],
            apiErrors: [],
        };
    }

    /** public functions */
    formTreeObject = (userRoleList) => {
        const treeObject = this.setPath(userRoleList);
        this.setState({ treeObject }, () => {
            this.getUserRoleLabels(treeObject);
        });
    };

    getAccessLevelOptions = () => {
        return Object.keys(AddEditPanel.accessLevelDetails).map((a) => ({
            label: AddEditPanel.accessLevelDetails[a].label,
            value: parseInt(a),
        }));
    };

    getIsAssigned = (record) => {
        const { children } = record;
        return !children.every(({ value }) => value === true);
    };

    getUserRoleLabels = (userRoleList) => {
        userRoleList.forEach(({ name, value, label, labelKey, children }) => {
            this.userRole[name] = value;
            this.userRole[labelKey] = label;
            if (children) {
                this.getUserRoleLabels(children);
            }
        });
    };

    liftRecordData = (data = {}) => {
        if (data && this.props.status !== PENDING) {
            if (this.props.addEditPanel.mode === "ADD") {
                this.addEditRequestId = this.props.needs([this.props.createUserRole(data)]);
            } else {
                this.addEditRequestId = this.props.needs([this.props.actions.update(data)]);
            }
        }
    };

    mapAccessLevelOptionsToValue = (options = [], selectedValue = "") => {
        if (selectedValue) {
            const selectedOption = options.filter((o) => {
                return selectedValue === o.value;
            });
            return selectedOption[0].value;
        } else {
            return options[0].value;
        }
    };

    mapTreeObjectWithUserRoles = (treeObject, record) => {
        treeObject.forEach((data) => {
            const { name, children } = data;
            data.value = record[name];
            this.userRole[name] = record[name];
            if (children) {
                this.mapTreeObjectWithUserRoles(children, record);
            }
        });
        this.setState({ treeObject });
    };

    onCheckboxChange = (record, assignValue) => {
        const { treeObject } = this.state;
        const { name, path, children } = record;
        // Checks the record is a parent or not (i.e) Parent record path is a numeric string and Child's path is alphaNumeric string.
        if (/^\d+$/.test(path)) {
            record.value = assignValue;
            record.children = children && this.onParentSelection(children, assignValue);
            treeObject[path] = record;
            this.userRole[name] = assignValue;
            this.setState({ treeObject });
        } else if (children) {
            const updatedChildren = this.onParentSelection(record.children, assignValue);
            _.update(treeObject, record.path, (data) => {
                this.userRole[name] = assignValue;
                data.value = assignValue;
                data.children = updatedChildren;
                return data;
            });
            this.setState({ treeObject }, () => {
                this.onChildSelection(record, assignValue);
            });
        } else {
            this.onChildSelection(record, assignValue);
        }
    };

    onChildSelection = (children, assignValue) => {
        let indexPathList = children.path.split(model.PROPS_CHILD_PATH);
        // Takes the parent index
        const parentIndex = indexPathList[indexPathList.length - 2];
        const { treeObject } = this.state;
        if (!children.children) {
            _.update(treeObject, children.path, (data) => {
                this.userRole[data.name] = assignValue;
                data.value = assignValue;
                return data;
            });
        } else {
            _.update(treeObject, children.path, (data) => {
                if (!data.value && data.children.some(({ value }) => value === true)) {
                    data.value = true;
                }
                this.userRole[data.name] = data.value;
                return data;
            });
        }
        if (indexPathList.length > 2) {
            // Removes the last indexPath so that on next call it goes to its parent
            indexPathList.pop();
            const children = {
                ..._.get(treeObject, indexPathList.join(model.PROPS_CHILD_PATH)),
            };
            this.onChildSelection(children, assignValue);
        } else {
            if (
                !treeObject[parentIndex].value &&
                treeObject[parentIndex].children.some((data) => data.value === true)
            ) {
                treeObject[parentIndex].value = true;
            }
        }
        this.userRole[treeObject[parentIndex].name] = treeObject[parentIndex].value;
        this.setState({ treeObject });
    };

    onParentSelection = (record, assignValue) => {
        return record.map((data) => {
            const { name, children } = data;
            this.userRole[name] = assignValue;
            data.value = assignValue;
            if (children) {
                this.onParentSelection(data.children, assignValue);
            }
            return data;
        });
    };

    onTextChange = (formKey, value, callback) => {
        this.userRole = onTextChange(this.userRole, { formKey: [formKey], value }, callback);
    };

    renderCheckbox = (record) => {
        const { value, children } = record;
        let props = { value: value };
        if (
            value &&
            children &&
            this.getIsAssigned(record) &&
            record.children.some(({ value }) => value === true)
        ) {
            props = { someChecked: true, value: true };
        }
        return (
            <Checkbox onChange={(e, value) => this.onCheckboxChange(record, value)} {...props} />
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.userRole[model.PROPS_NAME]]);
    };

    setPath = (userRoleList, path) => {
        const { formatMessage } = this.props.intl;
        userRoleList.forEach((record, index) => {
            record.path = path ? `${path}${model.PROPS_CHILD_PATH}${index}` : `${index}`;
            // Proprietary reports should display the system name on the user role page
            record.label =
                record.nameCode === "224"
                    ? this.props.systemName
                    : formatMessage(Messages[record.nameCode]); //Todo: this should display the Owner Branding Name over the new Branding Name
            if (record.children) {
                this.setPath(record.children, record.path);
            }
        });
        return userRoleList;
    };

    /** Life cycle functions */
    componentDidMount() {
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillMount() {
        this.formTreeObject(_.cloneDeep(defaultTreeObject));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.addEditPanel.mode === "ADD") {
            if (!this.userRole[model.PROPS_ACCESS_LEVEL]) {
                this.userRole[model.PROPS_ACCESS_LEVEL] = this.getAccessLevelOptions()[0].value;
            }
            if (nextProps.nextId) {
                this.setState({
                    nextId: nextProps.nextId,
                });
            }
        } else {
            if (
                nextProps.record &&
                (nextProps.record !== this.props.record || this.props.copyRoleMode)
            ) {
                this.userRole[model.PROPS_NAME] = nextProps.record[model.PROPS_NAME];
                this.userRole[model.PROPS_DESCRIPTION] = nextProps.record[model.PROPS_DESCRIPTION];
                this.userRole[model.PROPS_ACCESS_LEVEL] =
                    nextProps.record[model.PROPS_ACCESS_LEVEL];
                this.userRole[model.PROPS_USER_ROLE_GUID] =
                    nextProps.record[model.PROPS_USER_ROLE_GUID];
                this.mapTreeObjectWithUserRoles(this.state.treeObject, nextProps.record);
                this.props.toggleCopyRoleMode(false);
            }
        }
        if (this.props.copyRole) {
            this.userRole[model.PROPS_NAME] = "";
            this.userRole[model.PROPS_DESCRIPTION] = "";
            this.userRole[model.PROPS_ACCESS_LEVEL] = this.getAccessLevelOptions()[0].value;
            this.userRole[model.PROPS_USER_ROLE_GUID] = model.PROPS_DEFAULT_GUID;
            this.props.toggleCopyRole(false);
        }
        if (nextProps.requestIds && this.addEditRequestId) {
            if (nextProps.requestIds[this.addEditRequestId] === SUCCESS) {
                this.setState({
                    apiErrors: [],
                });
                this.props.closeSidePanel();
                this.addEditRequestId = null;
            } else if (nextProps.requestIds[this.addEditRequestId] === FAILURE) {
                if (nextProps.apiErrors.length) {
                    this.setState({
                        apiErrors: nextProps.apiErrors,
                    });
                }
                this.addEditRequestId = null;
            }
        } else if (!this.addEditRequestId && nextProps.fetchData) {
            this.liftRecordData(this.userRole);
            this.props.toggleFetchData(false);
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { userRole } = this;
        const { treeObject, apiErrors } = this.state;
        const accessLevelOptions = this.getAccessLevelOptions();
        return (
            <div className="add-edit-panel user-role-details">
                <Section>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.roleName)}
                            labelText={formatMessage(messages.roleName)}
                            value={userRole[model.PROPS_NAME] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_NAME, value, this.setHeaderText)
                            }
                            containerClassNames={getAgBytesErrorClassNames(40, apiErrors)}
                            autoFocus
                            required
                        />
                        <TextInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.description)}
                            labelText={formatMessage(messages.description)}
                            value={userRole[model.PROPS_DESCRIPTION] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_DESCRIPTION, value)}
                            containerClassNames={getAgBytesErrorClassNames(41, apiErrors)}
                            required
                        />
                        <Section>
                            <SelectInput
                                tabIndex={0}
                                placeholderText={formatMessage(messages.accessLevel)}
                                labelText={formatMessage(messages.accessLevel)}
                                options={accessLevelOptions}
                                onChange={(value) =>
                                    this.onTextChange(model.PROPS_ACCESS_LEVEL, value)
                                }
                                value={this.mapAccessLevelOptionsToValue(
                                    accessLevelOptions,
                                    userRole[model.PROPS_ACCESS_LEVEL]
                                )}
                                clearable={false}
                                containerClassNames={[
                                    "accesslevel-container",
                                    getAgBytesErrorClassNames(2801, apiErrors),
                                ]}
                                required
                            />
                        </Section>
                    </SubSection>
                </Section>
                <Section>
                    <div className="header-section">
                        <span className="red-star">* </span>
                        <span>{formatMessage(messages.userRoleTreeHeader)}</span>
                    </div>
                    <div className="header-section">
                        <span className="sub-header">
                            {formatMessage(messages.userRoleTreeSubHeader)}
                        </span>
                    </div>
                </Section>
                <div className="section-container">
                    <Section className="section-container section-column">
                        <TreeView
                            rowRenderer={this.renderCheckbox}
                            data={treeObject.slice(0, 4)}
                            displayLabel="label"
                            childrenKey="children"
                        />
                    </Section>
                    <Section className="section-container section-column">
                        <TreeView
                            rowRenderer={this.renderCheckbox}
                            data={treeObject.slice(4, 8)}
                            displayLabel="label"
                            childrenKey="children"
                        />
                    </Section>
                    <Section className="section-container section-column">
                        <TreeView
                            rowRenderer={this.renderCheckbox}
                            data={treeObject.slice(8, 16)}
                            displayLabel="label"
                            childrenKey="children"
                        />
                    </Section>
                    <Section className="section-container section-column">
                        <TreeView
                            rowRenderer={this.renderCheckbox}
                            data={treeObject.slice(16, 17)}
                            displayLabel="label"
                            childrenKey="children"
                        />
                    </Section>
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
