export const importGroomingRequest = Object.freeze({
    groomingSessionGuid: null,
    importFileGuidList: [],
    isNameMatch: false,
    fieldGuidsToIgnoreList: [],
    selectedEventIdList: [],
    selectedFieldGuidList: [],
    selectedCropGuidList: [],
    selectedCroppingSeasonGuidList: [],
    selectedCustomerFieldList: [],
    selectAll: true,
    statFilter: true,
    yieldFilter: true,
    featuresCount: 0,
    filteredFieldImportFileList: [],
});

export const importEventPreviewRequest = Object.freeze({
    statFilter: true,
    yieldFilter: true,
    manualFilteredList: [],
    fieldList: [],
    filteredFieldImportFileList: [],
});

export const importFilterListData = Object.freeze({
    crops: [],
    croppingSeasons: [],
    fieldBoundaryList: [],
    fieldList: [],
    equipmentProfiles: [],
    products: [],
});

export const importFieldStrip = Object.freeze({
    customerGuid: "",
    customerName: "",
    farmName: "",
    features: 0,
    fieldGuid: "",
    fieldName: "",
    hasImported: false,
    importFileGuidList: [],
    noMatch: true,
});
