import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withMasked, withCrud, withNestedGrid, withApiResult } from "~/hocs";
import { service, actions } from "./data";
import { preventBubbleUp } from "~/admin/utils";
import { messages } from "./../i18n-messages";

// Components
import { GrowthStageLog } from "./growth-stage-log/growth-stage-log";
import { DialogBox } from "~/core";
import { ImportExportHeader } from "../components/import-export-header";
import { GrowthOrderList } from "./../growth-order-list/growth-order-list";
import { NestedDataTable, Button } from "~/core";
import AddEditPanel from "./add-edit/add-edit-container";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { IAgBytesProps, IAgBytesState } from "../interfaces";
import { IDataTableFooterOptions, IRecord } from "~/core/components/tables/interfaces";

export class GrowthStages extends Component<
    IAgBytesProps<typeof actions, typeof AddEditPanel>,
    IAgBytesState
> {
    tableFooterOptions: IDataTableFooterOptions[];

    constructor(props) {
        super(props);
        this.tableFooterOptions = [
            {
                label: "Delete Selected",
                action: this.props.deleteSelected,
            },
        ];
        this.state = {
            isModalOpen: false,
            selectedRow: null,
            showInactive: false,
        };
    }

    onRowSelection = (event, selectedRow: IRecord): void => {
        this.setState({
            selectedRow: selectedRow === this.state.selectedRow ? null : selectedRow,
        });
        if (this.props.setLastEditedRow) {
            this.props.setLastEditedRow();
        }
    };

    onToggleModalClick = (): void => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    toggleActiveInactive = (): void => {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            () => {
                this.props.toggleActiveInactive();
            }
        );
    };

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        return (
            <div className="content-table-container">
                <ImportExportHeader
                    {...this.props}
                    service={service}
                    onToggleModalClick={this.onToggleModalClick}
                    toggleActiveInactive={this.toggleActiveInactive}
                />
                <NestedDataTable
                    service={service}
                    messages={messages}
                    footerOptions={this.tableFooterOptions}
                    {...this.props}
                    onRowSelection={this.onRowSelection}
                    isCheckbox
                    isEditable
                    selectedRow={this.state.selectedRow}
                >
                    <GrowthOrderList
                        toggleActiveInactive={this.props.toggleActiveInactive}
                        showInactiveProp={this.state.showInactive}
                        onRowSelection={this.onRowSelection}
                    />
                </NestedDataTable>
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "102",
                                childNameCode: "136",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={() => this.props.onSubmit()} />
                            <Button type="cancel" onClick={() => this.props.closeSidePanel()} />
                        </SlidingPanel>
                    </form>
                )}
                <DialogBox
                    className="view-log-dialog-box"
                    isOpen={this.state.isModalOpen}
                    draggable
                    unrestricted
                    onClose={() => this.onToggleModalClick()}
                    title={formatMessage(messages.growthStagesLogHistory)}
                >
                    <GrowthStageLog />
                </DialogBox>
            </div>
        );
    }
}

export default injectIntl(
    withMasked(
        withApiResult(withNestedGrid(withCrud(GrowthStages, service, actions)), actions.importData)
    )
);
