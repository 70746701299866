import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import moment from "moment";
import classNames from "classnames";
import {
    Button,
    DateInput,
    NumericInput,
    Section,
    SubSection,
    TextEditor,
    TextInput,
} from "~/core";
import {
    formatDate,
    onNumberChange,
    onTextChange,
    preventBubbleUp,
    getAgBytesErrorClassNames,
} from "~/admin/utils";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { messages } from "../i18n-messages";
import { getDefaultRecords, model } from "./data";
import { PhotoSimple } from "~/admin/agBytes/components/photo-simple";
import { keywords } from "~/admin/containers/keywords";
import Email, { defaultValue } from "../component/email";

// Style
import "./system-profile.css";
const SVG = "svg";

export class SystemProfile_ extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        intl: intlShape.isRequired,
        closeSlidingPanel: PropTypes.func,
        needs: PropTypes.func,
        fetchSystemSettings: PropTypes.func,
        updateSystemSettings: PropTypes.func,
        systemSettings: PropTypes.object,
        setBreadcrumbs: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
        };
        this.systemSettings = {};
        this.systemSettingsAddRequestId = null;
    }

    componentDidMount() {
        this.systemSettings = getDefaultRecords();
        this.props.setBreadcrumbs();
        this.props.needs([this.props.fetchSystemSettings()]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.systemSettings !== this.props.systemSettings) {
            this.systemSettings = nextProps.systemSettings;
        }
        this._processAddSystemSettingsRequest(nextProps);
    }

    _addCurrentTime = (value) => {
        const currentDateTime = moment();
        const hours = currentDateTime.format("HH");
        const minutes = currentDateTime.format("mm");
        return value.set({ h: hours, m: minutes, s: 0 });
    };

    _processAddSystemSettingsRequest = (nextProps) => {
        if (nextProps.requestIds[this.systemSettingsAddRequestId] === keywords.SUCCESS) {
            this.setState({
                apiErrors: [],
            });
            this.props.closeSlidingPanel();
            this.systemSettingsAddRequestId = null;
        } else if (nextProps.requestIds[this.systemSettingsAddRequestId] === keywords.FAILURE) {
            if (nextProps.apiErrors.length) {
                this.setState({
                    apiErrors: nextProps.apiErrors,
                });
            }
            this.systemSettingsAddRequestId = null;
        }
    };

    _onTextChange = (formKey, value, callback) => {
        this.systemSettings = onTextChange(
            this.systemSettings,
            { formKey: [formKey], value },
            callback
        );
    };
    _onNumericTextChange = (formKey, value, callback) => {
        this.systemSettings = onNumberChange(
            this.systemSettings,
            { formKey: [formKey], value },
            callback
        );
    };

    _onSaveSystemProfile = () => {
        if (this.systemSettingsAddRequestId === null) {
            this.systemSettingsAddRequestId = this.props.needs([
                this.props.updateSystemSettings(this.systemSettings),
            ]);
        }
    };
    _getLogoErrorClassName = (errorCodes = []) => {
        const { apiErrors } = this.state;
        return getAgBytesErrorClassNames(errorCodes, apiErrors).length
            ? "photo-simple-error"
            : null;
    };

    _renderDetailInfo1 = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column system-profile-section">
                <Section required headerText={formatMessage(messages.systemLogoDarkTheme)}>
                    <PhotoSimple
                        className={this._getLogoErrorClassName([2869])}
                        formatMessage={formatMessage}
                        itemListAlias={model.PROPS_SYSTEM_LOGO_DARK}
                        onChange={(value) =>
                            this._onTextChange(model.PROPS_SYSTEM_LOGO_DARK, value)
                        }
                        record={this.systemSettings[model.PROPS_SYSTEM_LOGO_DARK]}
                        title={formatMessage(messages.recommendedImageSize)}
                        id={model.PROPS_SYSTEM_LOGO_DARK}
                        fileType={SVG}
                    />
                </Section>
                <Section required headerText={formatMessage(messages.systemLogoLightTheme)}>
                    <PhotoSimple
                        className={this._getLogoErrorClassName([2870])}
                        formatMessage={formatMessage}
                        itemListAlias={model.PROPS_SYSTEM_LOGO_LIGHT}
                        onChange={(value) =>
                            this._onTextChange(model.PROPS_SYSTEM_LOGO_LIGHT, value)
                        }
                        record={this.systemSettings[model.PROPS_SYSTEM_LOGO_LIGHT]}
                        title={formatMessage(messages.recommendedImageSize)}
                        id={model.PROPS_SYSTEM_LOGO_LIGHT}
                        fileType={SVG}
                    />
                </Section>
                <Section required headerText={formatMessage(messages.systemReportLogo)}>
                    <PhotoSimple
                        className={this._getLogoErrorClassName([3004])}
                        formatMessage={formatMessage}
                        itemListAlias={model.PROPS_SYSTEM_LOGO}
                        onChange={(value) => this._onTextChange(model.PROPS_SYSTEM_LOGO, value)}
                        record={this.systemSettings[model.PROPS_SYSTEM_LOGO]}
                        title={formatMessage(messages.recommendedImageSize)}
                        fileType={"bmp"}
                        id={model.PROPS_SYSTEM_LOGO}
                    />
                </Section>
                <Section required headerText={formatMessage(messages.browserTabIcon)}>
                    <PhotoSimple
                        className={classNames([this._getLogoErrorClassName([93]), "favicon"])}
                        formatMessage={formatMessage}
                        itemListAlias={model.PROPS_SYSTEM_FAVICON}
                        onChange={(value) => this._onTextChange(model.PROPS_SYSTEM_FAVICON, value)}
                        record={this.systemSettings[model.PROPS_SYSTEM_FAVICON]}
                        title={formatMessage(messages.recommendedFaviconSize)}
                        fileType={"ico"}
                        id={model.PROPS_SYSTEM_FAVICON}
                    />
                </Section>
            </div>
        );
    };

    _renderDetailInfo2 = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.state;
        return (
            <div className="section-container section-column">
                <Section required headerText={formatMessage(messages.copyrightAndPolicyDetail)}>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={80}
                            containerClassNames={[
                                "copyright-text",
                                getAgBytesErrorClassNames([2868], apiErrors),
                            ]}
                            placeholderText={formatMessage(messages.copyrightText)}
                            labelText={formatMessage(messages.copyrightText)}
                            value={this.systemSettings[model.PROPS_COPYRIGHT_HOLDER]}
                            onChange={(value) =>
                                this._onTextChange(model.PROPS_COPYRIGHT_HOLDER, value)
                            }
                            required
                        />
                    </SubSection>
                    <SubSection className="copyright-policy-sub-section">
                        <TextInput
                            tabIndex={0}
                            containerClassNames={[
                                "copyright-text",
                                getAgBytesErrorClassNames([2867], apiErrors),
                            ]}
                            placeholderText={formatMessage(messages.privacyPolicyTitle)}
                            labelText={formatMessage(messages.privacyPolicyTitle)}
                            value={this.systemSettings[model.PROPS_PRIVACY_POLICY_TITLE]}
                            onChange={(value) =>
                                this._onTextChange(model.PROPS_PRIVACY_POLICY_TITLE, value)
                            }
                            required
                        />
                        <DateInput
                            placeholderText={formatMessage(messages.policyLastUpdated)}
                            containerClassNames={[
                                "policy-last-updated",
                                getAgBytesErrorClassNames([2872], apiErrors),
                            ]}
                            value={
                                this.systemSettings[model.PROPS_PRIVACY_POLICY_UPDATED_DATE]
                                    ? moment(
                                          this.systemSettings[
                                              model.PROPS_PRIVACY_POLICY_UPDATED_DATE
                                          ]
                                      )
                                    : null
                            }
                            onChange={(value) => {
                                this._onTextChange(
                                    model.PROPS_PRIVACY_POLICY_UPDATED_DATE,
                                    this._addCurrentTime(value) ? formatDate(value, "") : ""
                                );
                            }}
                            defaultValue={null}
                            required
                        />
                    </SubSection>
                    <Section headerText={formatMessage(messages.policyBodyContent)} required>
                        <TextEditor
                            containerClassName={getAgBytesErrorClassNames([2871], apiErrors)}
                            value={this.systemSettings[model.PROPS_PRIVACY_POLICY_TEXT]}
                            onChange={(value) =>
                                this._onTextChange(model.PROPS_PRIVACY_POLICY_TEXT, value)
                            }
                        />
                    </Section>
                </Section>
            </div>
        );
    };
    _renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.state;
        const { apiErrorModel } = this.props;
        const props = {
            addEditPanel: { mode: "EDIT" },
            apiErrors,
            apiErrorModel,
        };
        return (
            <div className="section-container section-column">
                <Section required headerText={formatMessage(messages.fieldSizeLimit)}>
                    <SubSection>
                        <NumericInput
                            required
                            scale={0}
                            precision={5}
                            containerClassNames={[getAgBytesErrorClassNames([3005], apiErrors)]}
                            onChange={(rawValue, value) =>
                                this._onNumericTextChange(model.PROPS_AREA_LIMIT, value)
                            }
                            value={this.systemSettings[model.PROPS_AREA_LIMIT]}
                            placeholderText={formatMessage(messages.areaLimit)}
                            labelText={formatMessage(messages.areaLimit)}
                        />
                    </SubSection>
                </Section>
                <Section required headerText={formatMessage(messages.sendResultsToEmail)}>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            containerClassNames={[
                                getAgBytesErrorClassNames([2891, 2892], apiErrors),
                                "copyright-text",
                            ]}
                            placeholderText={formatMessage(messages.sendResultsToEmail)}
                            labelText={formatMessage(messages.sendResultsToEmail)}
                            value={this.systemSettings[model.PROPS_SEND_RESULTS_TO_EMAIL]}
                            onChange={(value) =>
                                this._onTextChange(model.PROPS_SEND_RESULTS_TO_EMAIL, value)
                            }
                            required
                        />
                    </SubSection>
                </Section>
                <Email
                    required
                    defaultValue={[defaultValue()]}
                    emailErrorCodeList={[]}
                    emailHeaderText={messages.automatedTestResultEmail}
                    emailList={this.systemSettings[model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]}
                    emailAlias={model.PROPS_EMAIL_ADDRESS}
                    emailPropKey={model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST}
                    onChildComponentChange={this._onTextChange}
                    {...props}
                />
            </div>
        );
    };
    _renderSystemProfileInfo = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.state;
        return (
            <Section>
                <SubSection>
                    <TextInput
                        tabIndex={0}
                        maxLength={50}
                        containerClassNames={[getAgBytesErrorClassNames([2866], apiErrors)]}
                        placeholderText={formatMessage(messages.systemName)}
                        value={this.systemSettings[model.PROPS_SYSTEM_NAME]}
                        onChange={(value) => this._onTextChange(model.PROPS_SYSTEM_NAME, value)}
                        required
                    />
                    <TextInput
                        maxLength={50}
                        containerClassNames={[getAgBytesErrorClassNames([72], apiErrors)]}
                        placeholderText={formatMessage(messages.brandingName)}
                        value={this.systemSettings[model.PROPS_BRANDING_NAME]}
                        onChange={(value) => this._onTextChange(model.PROPS_BRANDING_NAME, value)}
                        required
                    />
                </SubSection>
            </Section>
        );
    };

    _renderSystemProfile = () => {
        return (
            <div className="add-edit-panel system-settings-cont">
                {this._renderSystemProfileInfo()}
                <div className="section-container">
                    {this._renderDetailInfo1()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo2()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo3()}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="system-profile-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    <SlidingPanel
                        component={this._renderSystemProfile}
                        navigateTo={{ parentNameCode: 101, childNameCode: 233 }}
                    >
                        <Button type="save" forceSubmit onClick={this._onSaveSystemProfile} />
                        <Button type="cancel" onClick={this.props.closeSlidingPanel} />
                    </SlidingPanel>
                </form>
            </div>
        );
    }
}
export default injectIntl(SystemProfile_);
