import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import { HierarchyAPI, EquipmentProfileAPI, OwnerAPI, Request, APIError } from "@ai360/core";
import * as actions from "./actions";
import { actions as notificationActions } from "~/notifications";
import { setApiResult } from "~/core/api/actions";
// selectors
import { getTheUserGuid, getTheUserCompanyGuid, getUser } from "~/login/selectors";
import { orgLevelSagas as defaultSagas } from "./controller";

export function* fetchAffiliationsParentList() {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid };
    try {
        const response = yield call(
            Request.post,
            HierarchyAPI.GET_AFFILIATIONS_PARENT_LIST,
            requestOptions
        );
        yield put(actions.getAffiliationsParentSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.getAffiliationsParent().type}`);
        yield put(notificationActions.apiCallError(error, actions.getAffiliationsParent));
        yield put(actions.getAffiliationsParentFailed(error));
    }
}

export function* watchGetAffiliationsParent() {
    yield takeEvery(actions.getAffiliationsParent, fetchAffiliationsParentList);
}

export function* fetchCompanyRecord({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(
            Request.post,
            HierarchyAPI.GET_COMPANY_RECORD_GRID,
            requestOptions
        );
        yield put(actions.fetchCompanyRecordSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.fetchCompanyRecord().type}`);
        yield put(notificationActions.apiCallError(error, actions.fetchCompanyRecord));
        yield put(actions.fetchCompanyRecordFailed(error));
    }
}

export function* watchFetchCompanyRecord() {
    yield takeEvery(actions.fetchCompanyRecord, fetchCompanyRecord);
}

export function* fetchOrgLevelData({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(
            Request.post,
            HierarchyAPI.GET_ORG_LEVEL_CHILDREN,
            requestOptions
        );
        yield put(actions.getOrgLevelDataSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.fetchCompanyRecord().type}`);
        yield put(notificationActions.apiCallError(error, actions.getOrgLevelData));
        yield put(actions.getOrgLevelDataFailed(error));
    }
}

export function* watchOrgLevelData() {
    yield takeEvery(actions.getOrgLevelData, fetchOrgLevelData);
}

export function* fetchHierarchyFilterListData({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(
            Request.post,
            HierarchyAPI.GET_HIERARCHY_FILTER_LIST_WITH_SEARCH,
            requestOptions
        );
        yield put(actions.getHierarchyFilterListSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.getHierarchyFilterListData().type}`);
        yield put(notificationActions.apiCallError(error, actions.getHierarchyFilterListData));
        yield put(actions.getHierarchyFilterListFailed(error));
    }
}

export function* watchFetchHierarchyFilterList() {
    yield takeEvery(actions.getHierarchyFilterListData, fetchHierarchyFilterListData);
}

export function* fetchActiveOrgTypeName({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const companyGuid = yield select(getTheUserCompanyGuid);
    const newPayload = {
        companyGuid,
        parentGuid: payload,
    };
    const requestOptions = { UserGuid, Model: newPayload };
    try {
        const response = yield HierarchyAPI.getActiveOrgTypeName(requestOptions);
        yield put(actions.getActiveOrgTypeNameSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.getActiveOrgTypeName().type}`);
        yield put(notificationActions.apiCallError(error, actions.getActiveOrgTypeName));
        yield put(actions.getActiveOrgTypeNameFailed(error));
    }
}

export function* watchFetchActiveOrgTypeName() {
    yield takeEvery(actions.getActiveOrgTypeName, fetchActiveOrgTypeName);
}

export function* requestAddOrgLevel({ payload }) {
    const LoggedInUser = yield select(getUser);
    const requestOptions = { LoggedInUser, Model: payload };
    try {
        const response = yield HierarchyAPI.addOrgLevel(requestOptions);
        yield put(actions.addOrgLevelSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, actions.addOrgLevel));
        }
        yield put(actions.addOrgLevelFailed(error));
    }
}

export function* watchAddOrgLevel() {
    yield takeEvery(actions.addOrgLevel, requestAddOrgLevel);
}

export function* requestUpdateOrgLevel({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield HierarchyAPI.updateOrgLevel(requestOptions);
        yield put(actions.updateOrgLevelSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, actions.updateOrgLevel));
        }
        yield put(actions.updateOrgLevelFailed(error));
    }
}

export function* watchUpdateOrgLevel() {
    yield takeEvery(actions.updateOrgLevel, requestUpdateOrgLevel);
}

export function* requestGetOrgLevel({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield HierarchyAPI.getOrgLevel(requestOptions);
        yield put(actions.getOrgLevelSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.getOrgLevel().type}`);
        yield put(notificationActions.apiCallError(error, actions.getOrgLevel));
        yield put(actions.getOrgLevelFailed(error));
    }
}

export function* watchGetOrgLevel() {
    yield takeEvery(actions.getOrgLevel, requestGetOrgLevel);
}

export function* requestOrgLevelEquipmentList({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield EquipmentProfileAPI.getOwnerOperatorEquipmentList(requestOptions);
        yield put(actions.fetchOrgLevelEquipmentListSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.fetchOrgLevelEquipmentList().type}`);
        yield put(notificationActions.apiCallError(error, actions.fetchOrgLevelEquipmentList));
        yield put(actions.fetchOrgLevelEquipmentListFailed(error));
    }
}

export function* watchFetchOrgLevelEquipmentList() {
    yield takeEvery(actions.fetchOrgLevelEquipmentList, requestOrgLevelEquipmentList);
}

export function* requestCreateEquipment(action) {
    const { payload } = action;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield EquipmentProfileAPI.createEquipmentProfile(requestOptions);
        yield put(actions.createEquipmentSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, actions.createEquipment));
        }
        yield put(actions.createEquipmentFailed(error));
    }
}

export function* watchCreateEquipment() {
    yield takeEvery(actions.createEquipment, requestCreateEquipment);
}

export function* fetchOrgLevelWithGuidData() {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: "_" };
    try {
        const response = yield call(
            Request.post,
            HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS_GUID,
            requestOptions
        );
        yield put(actions.getOrgLevelWithGuidSuccess(response));
    } catch (error) {
        console.warn(`Failed to process action ${actions.fetchCompanyRecord().type}`);
        yield put(notificationActions.apiCallError(error, actions.getOrgLevelWithGuidData));
        yield put(actions.getOrgLevelWithGuidFailed(error));
    }
}

export function* watchFetchOrgLevelWithGuidData() {
    yield takeEvery(actions.getOrgLevelWithGuidData, fetchOrgLevelWithGuidData);
}

export function* fetchOrgLevelChildrenData({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const responseArray = [];
        let level = 1;
        for (const key of Object.keys(payload)) {
            const requestOptions = { UserGuid, Model: payload[key] };
            const response = yield call(
                Request.post,
                HierarchyAPI.GET_ORG_LEVEL_CHILDREN,
                requestOptions
            );
            responseArray.push({
                level: level++,
                guid: payload[key].parentOrgLevelGuid,
                ...response,
            });
        }
        yield put(actions.getOrgLevelChildrenSuccess(responseArray));
    } catch (error) {
        console.warn(`Failed to process action ${actions.fetchCompanyRecord().type}`);
        yield put(notificationActions.apiCallError(error, actions.getOrgLevelChildrenData));
        yield put(actions.getOrgLevelChildrenFailed(error));
    }
}

export function* watchFetchOrgLevelChildrenData() {
    yield takeEvery(actions.getOrgLevelChildrenData, fetchOrgLevelChildrenData);
}

export function* setNewParentLocation({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const requestOptions = { UserGuid, Model: payload };
        yield call(Request.post, HierarchyAPI.MOVE_LOCATION_URL, requestOptions);
        yield put(actions.setNewParentSuccess());
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        yield put(actions.setNewParentFailed(error));
    }
}

export function* watchSetNewParentLocation() {
    yield takeEvery(actions.setNewParentLocation, setNewParentLocation);
}

export function* updateOwner({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const requestOptions = { UserGuid, Model: payload };
        yield call(Request.post, OwnerAPI.UPDATE, requestOptions);
        yield put(actions.updateOwnerSuccess());
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, actions.updateOwnerFailed));
        }
        yield put(actions.updateOwnerFailed(error));
    }
}

export function* watchUpdateOwner() {
    yield takeEvery(actions.updateOwner, updateOwner);
}

export function* getOrgLevelWithAgvanceDatabaseId({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const requestOptions = { UserGuid, Model: payload };
        const response = yield call(
            Request.post,
            HierarchyAPI.GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID,
            requestOptions
        );
        yield put(actions.getOrgLevelWithAgvanceDatabaseIdSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.getOrgLevelWithAgvanceDatabaseIdFailed
                )
            );
        }
        yield put(actions.getOrgLevelWithAgvanceDatabaseIdFailed(error));
    }
}

export function* getAgvanceLocations({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const requestOptions = { UserGuid, Model: payload };
        const response = yield call(Request.post, HierarchyAPI.GET_LOCATION_LIST, requestOptions);
        yield put(actions.getAgvanceLocationsSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, actions.getAgvanceLocationsFailed));
        }
        yield put(actions.getAgvanceLocationsFailed(error));
    }
}

export function* watchGetOrgLevelWithAgvanceDatabaseId() {
    yield takeEvery(actions.getOrgLevelWithAgvanceDatabaseId, getOrgLevelWithAgvanceDatabaseId);
}

export function* watchGetAgvanceLocations() {
    yield takeEvery(actions.getAgvanceLocations, getAgvanceLocations);
}

export const orgLevelSagas = function* () {
    yield all([
        fork(watchGetAffiliationsParent),
        fork(watchFetchCompanyRecord),
        fork(watchOrgLevelData),
        fork(watchFetchHierarchyFilterList),
        fork(watchFetchActiveOrgTypeName),
        fork(watchAddOrgLevel),
        fork(watchUpdateOrgLevel),
        fork(watchGetOrgLevel),
        fork(watchFetchOrgLevelEquipmentList),
        fork(watchCreateEquipment),
        fork(watchFetchOrgLevelWithGuidData),
        fork(watchFetchOrgLevelChildrenData),
        fork(watchSetNewParentLocation),
        fork(watchUpdateOwner),
        fork(watchGetOrgLevelWithAgvanceDatabaseId),
        fork(watchGetAgvanceLocations),
        fork(defaultSagas),
    ]);
};
