export const REPORT_BOOKS_DATA_KEY = "REPORT_BOOKS_DATA";

export const getClearReportBookTypes = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].clearReportBookTypes;

export const getLoadingReportBook = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].loadingReportBook;

export const getRefreshReportBooks = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].refreshReportBooks;

export const getReportBookErrorsMap = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].reportBookErrorsMap;

export const getReportBooks = (state: unknown): any => state[REPORT_BOOKS_DATA_KEY].reportBooks;

export const getReportBooksAutoComplete = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].reportBooksAutoComplete;

export const getReportBooksSelectAll = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].reportBooksSelectAll;

export const getReportBooksTotalCount = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].reportBooksTotalCount;

export const getReportBookTypes = (state: unknown): any =>
    state[REPORT_BOOKS_DATA_KEY].reportBookTypes;
