import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { messages } from "../../i18n-messages";
import TreeView from "~/core/components/tree-view/tree-view";
import { Checkbox } from "~/core";
import EntitySearch from "./entity-search";
import { SUCCESS } from "~/hocs/needs/utils";
import HierarchyFilter from "~/admin/setup/component/hierarchy-filter";

class LocationEntity extends Component {
    static propTypes = {
        getEntityDropdownValue: PropTypes.func,
        isEntityTab: PropTypes.bool,
        selectedCompany: PropTypes.object,
        selectedAttribute: PropTypes.object,
        useOnly: PropTypes.object,
        formatMessage: PropTypes.func,
        needs: PropTypes.func,
        getLocationEntities: PropTypes.func,
        searchLocationHierarchy: PropTypes.func,
        onPermissionChange: PropTypes.func,
        onUseOnlyChange: PropTypes.func,
        useOnlySupportedAttributes: PropTypes.func,
        locationEntities: PropTypes.array,
        filteredLocations: PropTypes.array,
        requestIds: PropTypes.object,
        setCurrentEntities: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            locationEntities: [],
            node: {},
            tree: {},
            itemSelected: false,
        };
        this.customTree = {};
        this.customTreeIndex = 0;
        this.locationEntitySelectedRequestId = null;
        this.currentNodeClicked = null;
    }

    componentDidMount() {
        const { selectedAttribute, selectedCompany } = this.props;
        this.getLocationEntities(selectedAttribute, selectedCompany);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { itemSelected } = this.state;
        if (
            this.props.selectedAttribute !== nextProps.selectedAttribute ||
            this.props.selectedCompany !== nextProps.selectedCompany
        ) {
            this.setState({
                tree: {},
                node: {},
            });
            this.getLocationEntities(nextProps.selectedAttribute, nextProps.selectedCompany);
        } else if (this.locationEntitySelectedRequestId && itemSelected) {
            this.processLocationSearchRequest(nextProps);
        } else if (nextProps.locationEntities !== this.props.locationEntities) {
            this.setupLocationEntities(nextProps);
        }
    }

    resetTree = () => {
        const { selectedAttribute, selectedCompany } = this.props;
        this.getLocationEntities(selectedAttribute, selectedCompany);
        this.setState({
            locationEntities: [],
            node: {},
            tree: {},
            itemSelected: false,
        });
        this.customTree = {};
        this.customTreeIndex = 0;
        this.locationEntitySelectedRequestId = null;
        this.currentNodeClicked = null;
    };

    processLocationSearchRequest = (nextProps) => {
        if (nextProps.requestIds[this.locationEntitySelectedRequestId] === SUCCESS) {
            if (nextProps.locationEntities[0]) {
                const { entityGuid, hasChildren } = nextProps.locationEntities[0];
                const { guid, id } = this.props.selectedAttribute;
                const { orgLevelGuid, orgLevelName } = this.state;
                // Add response to the last tree node
                this.customTree = this.buildCustomTree(
                    nextProps.locationEntities[0],
                    this.customTreeIndex
                );
                if (hasChildren) {
                    // Request new children to complete the tree
                    this.locationEntitySelectedRequestId = this.props.needs([
                        this.props.getLocationEntities({
                            attributeGuid: guid,
                            attributeType: id,
                            entityGuid,
                            selectionGuid: orgLevelGuid,
                            searchValue: orgLevelName,
                        }),
                    ]);
                    this.customTreeIndex++;
                    return;
                }
            }
            // Since the last request didn't return any children complete the custom tree
            this.locationEntitySelectedRequestId = null;
            this.setState({
                locationEntities: [{ ...this.customTree }],
            });
        }
    };

    getLocationEntities = (selectedAttribute, selectedCompany = {}) => {
        const { needs, getLocationEntities, isEntityTab } = this.props;
        const { guid, id } = selectedAttribute;
        needs([
            getLocationEntities(
                {
                    attributeGuid: guid,
                    attributeType: id,
                    isDropdown: isEntityTab,
                    searchValue: "",
                    selectionGuid: "",
                },
                selectedCompany.userGuid
            ),
        ]);
    };

    buildCustomTree(data, customTreeIndex) {
        if (_.isEmpty(this.customTree)) {
            this.customTree = { ...data, isOpen: true };
        } else {
            let path = "entityChildren";
            for (let index = 0; index < customTreeIndex - 1; index++) {
                path = `${path}[0].entityChildren`;
            }
            _.set(this.customTree, path, [{ ...data, isOpen: true }]);
        }
        return this.customTree;
    }

    setupLocationEntities = ({ locationEntities }) => {
        const { node, tree } = this.state;
        let newLocationEntities = [...locationEntities];
        if (node.nodeIndex) {
            const clickedNode = _.get(tree, node.nodeIndex) || null;
            if (clickedNode) {
                // If it's the clicked node, assign children same value as the parent (clicked node)
                if (
                    this.currentNodeClicked &&
                    clickedNode.nodeIndex.includes(this.currentNodeClicked)
                ) {
                    newLocationEntities = newLocationEntities.map((entity) => ({
                        ...entity,
                        isAssigned: clickedNode.isAssigned,
                        isNewAssigned: clickedNode.isAssigned,
                    }));
                }
                clickedNode.entityChildren = newLocationEntities;
                _.set(tree, node.nodeIndex, clickedNode);
                this.setState({
                    locationEntities: tree,
                });
            }
        } else {
            this.props.setCurrentEntities(locationEntities);
            this.setState({
                locationEntities: newLocationEntities,
            });
        }
    };

    onPermissionChange = (data, value) => {
        if (data) {
            const { locationEntities } = this.state;
            this.currentNodeClicked = data.nodeIndex;
            const updatedLocationEntities = this.selectChildren(data, value);
            _.set(locationEntities, data.nodeIndex, updatedLocationEntities);
            this.setState({
                locationEntities,
            });
            if (this.props.onPermissionChange) {
                this.props.onPermissionChange("hierarchyEntities", {
                    ...data,
                    isAssigned: value,
                });
            }
        }
    };

    selectChildren = (data, value) => {
        if (data) {
            data.isAssigned = value;
            data.isNewAssigned = value;
        }
        if (data && data.hasChildren && data.entityChildren.length > 0) {
            for (const key of Object.keys(data.entityChildren)) {
                data.entityChildren[key].isAssigned = value;
                data.entityChildren[key].isNewAssigned = value;
                this.selectChildren(data.entityChildren[key], value);
            }
        }
        return data;
    };

    isChildAssigned(data) {
        for (const key of Object.keys(data.entityChildren)) {
            const currNode = data.entityChildren[key];
            if (
                currNode.hasChildren &&
                !currNode.entityChildren.length &&
                currNode.isChildAssigned
            ) {
                return true;
            }
            if (this.isChildAssigned(currNode)) {
                return true;
            }
        }
        return data.isAssigned;
    }

    getIsAssigned = (data) => {
        if (!data.isAssigned && data.hasChildren && this.currentNodeClicked !== data.nodeIndex) {
            if (!data.entityChildren.length) {
                return data.isChildAssigned;
            }
            return this.isChildAssigned(data);
        }
        return data.isAssigned;
    };

    expandParentEntity = ({ node, tree }) => {
        const { entityGuid } = node;
        const { guid, id } = this.props.selectedAttribute;
        if (!node.entityChildren.length) {
            this.props.needs([
                this.props.getLocationEntities({
                    attributeGuid: guid,
                    attributeType: id,
                    entityGuid,
                    selectionGuid: "",
                }),
            ]);
        }
        this.setState({
            node,
            tree,
        });
    };

    onItemSelection = ({ orgLevelGuid, orgLevelName }) => {
        const { guid, id } = this.props.selectedAttribute;
        if (!orgLevelGuid) {
            this.resetTree();
            return;
        }
        this.setState({
            itemSelected: true,
            orgLevelGuid,
            orgLevelName,
        });
        this.locationEntitySelectedRequestId = this.props.needs([
            this.props.getLocationEntities(
                {
                    attributeGuid: guid,
                    attributeType: id,
                    selectionGuid: orgLevelGuid,
                    searchValue: orgLevelName,
                },
                this.props.selectedCompany.userGuid
            ),
        ]);
    };

    onSearchChange = () => {
        this.setState({
            itemSelected: false,
        });
        this.customTree = {};
        this.customTreeIndex = 0;
    };

    renderCheckbox = (data) => {
        let checkedProp = { value: data.isAssigned };
        if (data.entityChildren.length > 0) {
            const fewChecked = data.entityChildren.some((item) => {
                if (data.isNewAssigned === undefined && item.hasChildren && item.isChildAssigned) {
                    return true;
                }
                return item.isAssigned;
            });
            const allUnchecked = data.entityChildren.every((item) => !item.isAssigned);
            const allChecked = data.entityChildren.every((item) => item.isAssigned);

            if (allChecked) {
                checkedProp = { value: true };
            } else if (fewChecked || this.getIsAssigned(data)) {
                checkedProp = { someChecked: true, value: true };
            } else if (allUnchecked) {
                checkedProp = { value: false };
            }
        } else if (
            !data.isAssigned &&
            data.isNewAssigned === undefined &&
            this.getIsAssigned(data)
        ) {
            if (data.hasChildren && data.areAllChildrenAssigned) {
                checkedProp = { value: true };
            } else {
                checkedProp = { someChecked: true, value: true };
            }
        }
        return (
            <Checkbox
                onChange={(e, value) => this.onPermissionChange(data, value)}
                {...checkedProp}
            />
        );
    };

    onFilterChange = (dropdownSelectedEntity) => {
        const { getEntityDropdownValue } = this.props;

        if (dropdownSelectedEntity) {
            getEntityDropdownValue(dropdownSelectedEntity.orgLevelGuid);
        } else {
            getEntityDropdownValue(null);
        }
    };

    render() {
        const { formatMessage, isEntityTab, selectedCompany } = this.props;

        return isEntityTab ? (
            <div>
                <div className="filter-container">
                    <HierarchyFilter
                        required
                        needs={this.props.needs}
                        onFilterChange={(selectedValue) => this.onFilterChange(selectedValue)}
                        placeholderText={formatMessage(messages.location)}
                        selectedCompanyUserGuid={selectedCompany.userGuid}
                    />
                </div>

                {this.props.useOnlySupportedAttributes.has(
                    this.props.selectedAttribute.categoryIndex
                ) ? (
                    <div className="batch-zapper-use-only">
                        <Checkbox
                            onChange={(e, value) => this.props.onUseOnlyChange(value)}
                            value={this.props.useOnly || this.props.selectedAttribute.useOnly}
                            label={formatMessage(messages.useOnly)}
                            disabled={this.props.selectedAttribute.useOnly}
                        />
                    </div>
                ) : null}
            </div>
        ) : (
            <div className="location-entity-container">
                <EntitySearch
                    labelKey="orgLevelName"
                    onItemSelection={this.onItemSelection}
                    placeholderText={formatMessage(messages.searchLocation)}
                    searchEntity={this.props.searchLocationHierarchy}
                    onSearchChange={this.onSearchChange}
                    filteredData={this.props.filteredLocations}
                    selectedCompany={this.props.selectedCompany}
                />
                <TreeView
                    rowRenderer={this.renderCheckbox}
                    data={this.state.locationEntities}
                    onNodeClicked={!this.state.itemSelected && this.expandParentEntity}
                    displayLabel="entityName"
                    childrenKey="entityChildren"
                />
            </div>
        );
    }
}

export default LocationEntity;
