export const noMutateMapDelete = (map, key) => {
    const rv = new Map(map);
    rv.delete(key);
    return rv;
};

export const noMutateMapDeleteKeys = (map, keys) => {
    const rv = new Map(map);
    for (let k of keys) {
        rv.delete(k);
    }
    return rv;
};

export const noMutateMapSet = (map, key, value) => {
    const rv = new Map(map);
    rv.set(key, value);
    return rv;
};

export const noMutateMapSetAll = (map, mapAdd) => {
    const rv = new Map(map);
    for (const [key, value] of mapAdd) {
        rv.set(key, value);
    }
    return rv;
};

export const noMutateSetAdd = (set, value) => {
    const rv = new Set(set);
    rv.add(value);
    return rv;
};

export const noMutateSetDelete = (set, value) => {
    const rv = new Set(set);
    rv.delete(value);
    return rv;
};

export const noMutateSetAddAll = (set, values) => {
    const rv = new Set(set);
    for (let val of values) {
        rv.add(val);
    }
    return rv;
};

export const noMutateSetDeleteAll = (set, values) => {
    if (!values) {
        return new Set();
    }
    const rv = new Set(set);
    for (let val of values) {
        rv.delete(val);
    }
    return rv;
};

/**
 * Adds or Deletes objects from the set without mutating the original set
 * @param {bool} isAdd if true the values are added to the new set, else they are removed
 * @param {Set} set the set we are adding/deleting from (without mutating)
 * @param {any|iterable} values either a single value or an iterable of values
 */
export const noMutateSetChange = (isAdd, set, values) => {
    const args = [set, values];
    return typeof values[Symbol.iterator] === "function" && typeof values === "object"
        ? isAdd
            ? noMutateSetAddAll(...args)
            : noMutateSetDeleteAll(...args)
        : isAdd
        ? noMutateSetAdd(...args)
        : noMutateSetDelete(...args);
};
