import { FieldAPI } from "@ai360/core";

export const BATCH_TEMPLATE_FIELD_GUID = "BATCH_TEMPLATE";
export const MAX_FETCH_FIELDS = 5000;

export interface IChangedMessage {
    fieldGuid: string;
}

export interface IRecChangedMessage extends IChangedMessage {
    recGeneralGuid: string;
    recData: FieldAPI.IRecSummary;
}

export interface IEventChangedMessage extends IChangedMessage {
    agEventGeneralGuid: string;
    eventData: FieldAPI.IAgEventSummary;
}
