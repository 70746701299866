import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { IntlProvider, addLocaleData, defineMessages } from "react-intl";
import IntlMessageFormat from "intl-messageformat";

import { getLocale } from "../selectors";
import { locales, localeInfoMap, setLocaleError } from "../actions";

const messages = defineMessages({
    invalidLocale: {
        id: "intlprovider.invalidLocale",
        defaultMessage: 'Invalid locale "{locale}"',
    },
    unableToFetch: {
        id: "intlprovider.unableToFetch",
        defaultMessage: "Unable to fetch locale messages (HTTP {status})",
    },
});

export class WrappedIntlProvider extends Component {
    static propTypes = {
        locale: PropTypes.oneOf(locales).isRequired,
        onSetError: PropTypes.func,
        onSetSuccess: PropTypes.func,
        children: PropTypes.element.isRequired,
    };

    static defaultProps = {
        locale: "en-US",
        onSetError: () => null,
        onSetSuccess: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            messages: {},
        };
        this._setLocaleState(props.locale);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.locale === nextProps.locale) {
            return;
        }
        this.setState({ fetching: true }, () => this._setLocaleState(nextProps.locale));
    }

    render() {
        return (
            <IntlProvider locale={this.props.locale} messages={this.state.messages}>
                {this.state.fetching ? <div /> : this.props.children}
            </IntlProvider>
        );
    }

    _formatMessage(messageDescriptor, values) {
        const msgId = messageDescriptor.id;
        const msg =
            msgId in this.state.messages
                ? this.state.messages[msgId]
                : messageDescriptor.defaultMessage;
        // locale doesn't really matter here;
        //   only the messages for this module are ever formatted this way
        const formatter = new IntlMessageFormat(msg, this.props.locale);
        return formatter.format(values);
    }

    _setLocaleState(locale) {
        if (!(locale in localeInfoMap)) {
            this.setState({ fetching: false }, () =>
                this.props.onSetError(this._formatMessage(messages.invalidLocale, { locale }))
            );
            return;
        }

        const localeInfo = localeInfoMap[locale];
        fetch(localeInfo.messagesUrl)
            .then((resp) => {
                if (resp.status !== 200) {
                    throw new Error(
                        this._formatMessage(messages.unableToFetch, {
                            status: resp.status,
                        })
                    );
                }
                return resp.json();
            })
            .then((messages) => {
                addLocaleData(localeInfo.getLocaleData());
                moment.locale(localeInfo.momentLocaleName);
                this.setState(
                    {
                        fetching: false,
                        messages,
                    },
                    this.props.onSetSuccess
                );
            })
            .catch((err) => {
                this.setState(
                    {
                        fetching: false,
                    },
                    () => this.props.onSetError(err)
                );
            });
    }
}

const mapStateToProps = (state) => {
    return {
        locale: getLocale(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetError: (errMsg) => dispatch(setLocaleError(errMsg)),
    };
};

export const ConnectedIntlProvider = connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedIntlProvider);
