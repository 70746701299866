import { messages } from "../../i18n-messages";
import { keywords } from "../../keywords";

export interface IChildObject {
    id: string;
    name: string;
}

export interface IYieldDataSet {
    name?: string;
    id?: string;
    isOpen?: boolean;
    children?: IChildObject[];
}

export const yieldByDataSet = (formatMessage: (msg: any) => void): Record<string, any> => [
    {
        name: formatMessage(messages.yieldByPlanting),
        id: keywords.planting,
        isOpen: true,
        children: [
            {
                id: keywords.varietyHybrid,
                name: formatMessage(messages.varietyHybrid),
            },
            {
                id: keywords.seedingRate,
                name: formatMessage(messages.seedingRate),
            },
        ],
    },
    {
        name: formatMessage(messages.yieldByApplication),
        id: keywords.application,
        isOpen: true,
        children: [
            {
                id: keywords.product,
                name: formatMessage(messages.product),
            },
            {
                id: keywords.productRate,
                name: formatMessage(messages.productRate),
            },
        ],
    },
    {
        name: formatMessage(messages.yieldByTillage),
        id: keywords.tillage,
        isOpen: true,
        children: [
            {
                id: keywords.tillageMethod,
                name: formatMessage(messages.tillageMethod),
            },
            {
                id: keywords.tillageDepth,
                name: formatMessage(messages.tillageDepth),
            },
        ],
    },
    {
        name: formatMessage(messages.yieldBySoilType),
        id: keywords.soilType,
        isOpen: true,
        children: [
            {
                id: keywords.soilTypeName,
                name: formatMessage(messages.soilTypeName),
            },
        ],
    },
];
