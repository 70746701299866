import moment from "moment";

export function formatEventStripText(eventDetails, croppingSeason) {
    const displayNameParts = [
        eventDetails.agEventTypeList.map((x) => x.agEventTransactionTypeName).join(" + "),
        croppingSeason,
        moment(eventDetails.momentStartDate).format("M/D/YY"),
    ].filter((p) => p);
    if (eventDetails.eventName) {
        displayNameParts.push(eventDetails.eventName);
    }
    return displayNameParts.join(" - ");
}
