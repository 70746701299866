import { connect } from "react-redux";
import MaintenanceListView from "./maintenance-event-list";
import { getPicklist } from "~/admin/selectors";
import * as picklistService from "~/core/picklist/picklist-names";

const mapStateToProps = (state) => ({
    [picklistService.PICKLIST_MAINTENANCE_TYPE]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_MAINTENANCE_TYPE),
        state
    ),
    [picklistService.PICKLIST_USAGE_UNIT]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_USAGE_UNIT),
        state
    ),
});

export const MaintenanceList = connect(mapStateToProps, null)(MaintenanceListView);
