import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { importWizardSelectors } from "~/action-panel/components/import-module";
import { getTheUserGuid, getTheUserLockCustomer } from "~/login";
import { actions as notificationActions } from "~/notifications";

import { EditEventIdModal_ } from "../components/edit-event-id-modal";

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
    importFileGuidList: importWizardSelectors.getImportFileGuidList(state),
    isLockCustomer: getTheUserLockCustomer(state),
});

const mapDispatchToProps = (dispatch) => ({
    apiCallError: (error) => dispatch(notificationActions.apiCallError(error)),
});

export const EditEventIdModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EditEventIdModal_));
