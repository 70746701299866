import React, {
    Children,
    Component,
    ReactNode,
    MouseEvent as ReactMouseEvent,
    ReactElement,
} from "react";
import classnames from "classnames";

import { Loader, LoaderTypes } from "../loader/loader";
import { ToggleEvent, ToolbarTool } from "./toolbar-tool";
import { ToolbarMenu } from "./toolbar-menu";

import "./toolbar.css";

export interface IActiveTool {
    className: string;
    disabled: boolean;
    icon: ReactElement;
    label: string;
    onToggle: (evt: ToggleEvent) => void;
    _index: number;
    _onToggle: (e: ToggleEvent) => void;
    _selected: string;
}

export interface IToolbarProps {
    _activeTool?: IActiveTool;
    _activeToolIndex?: number;
    _includeClose?: boolean;
    _onClose?: (e: MouseEvent | ReactMouseEvent) => void;
    _onToggleMenuTool?: (e: ToggleEvent) => void;
    className?: string;
    children?: ReactNode;
    isProcessing?: boolean;
    onToggleMenu?: (e: ToggleEvent) => void;
    onToggleTool?: (e: ToggleEvent) => void;
    rtl?: boolean;
}

export interface IToolbarState {
    openMenuIndex: number;
    selectedToolIndex: number;
}

export class Toolbar extends Component<IToolbarProps, IToolbarState> {
    static defaultProps = {
        _activeTool: null,
        _activeToolIndex: null,
        _includeClose: undefined,
        className: "",
        children: [],
        isProcessing: false,
        rtl: false,
    };

    constructor(props: IToolbarProps) {
        super(props);
        this.state = {
            openMenuIndex: null,
            selectedToolIndex: props._activeToolIndex,
        };
    }

    private _handleClose(e: MouseEvent | ReactMouseEvent): void {
        if (this.props._onClose) {
            this.props._onClose(e);
        }
    }

    private _onToggleMenu(e: ToggleEvent): void {
        if (this.props.onToggleMenu) {
            this.props.onToggleMenu(e);
        }
    }

    public toggleMenu(e: ToggleEvent): void {
        this.setState(
            {
                openMenuIndex: e.expanded ? e.index : null,
            },
            () => this._onToggleMenu(e)
        );
    }

    public toggleTool(e: ToggleEvent): void {
        this.setState({ selectedToolIndex: e.selected ? e.index : null });
        if (this.props._onToggleMenuTool) {
            this.props._onToggleMenuTool(e);
        }
        if (this.props.onToggleTool) {
            this.props.onToggleTool(e);
        }
    }

    static getDerivedStateFromProps(
        newProps: IToolbarProps,
        state: IToolbarState
    ): Partial<IToolbarState> {
        if (
            newProps._activeToolIndex != null &&
            state.selectedToolIndex !== newProps._activeToolIndex
        ) {
            return {
                selectedToolIndex: newProps._activeToolIndex,
            };
        }
        return null;
    }

    render(): ReactElement {
        const { _activeTool, _includeClose, className, isProcessing, rtl } = this.props;
        const children = Children.map(this.props.children as ReactElement[], (child, i) => {
            if (child.type === ToolbarMenu) {
                child = React.cloneElement(child, {
                    _index: i,
                    _expanded: i === this.state.openMenuIndex,
                    _onToggle: (e: ToggleEvent) => this.toggleMenu(e),
                });
            } else if (child.type === ToolbarTool) {
                child = React.cloneElement(child, {
                    _index: i,
                    _onToggle: (e: ToggleEvent) => this.toggleTool(e),
                    _selected:
                        i === this.state.selectedToolIndex ||
                        (_activeTool && _activeTool._index === i),
                });
            }
            return <li>{child}</li>;
        });
        if (isProcessing) {
            children.push(
                <li key="loader" className="toolbar-loader">
                    <Loader type={LoaderTypes.LINE_SCALE} />
                </li>
            );
        }
        if (_includeClose) {
            const closebar = (
                <li
                    key="close"
                    className={classnames("toolbar-close-bar", { rtl })}
                    onClick={(e: MouseEvent | ReactMouseEvent) => this._handleClose(e)}
                >
                    X
                </li>
            );
            if (rtl) {
                children.unshift(closebar);
            } else {
                children.push(closebar);
            }
        }

        return <ul className={classnames("toolbar", className)}>{children}</ul>;
    }
}
