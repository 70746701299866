import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";

// Components
import { SubSection, TextInput } from "~/core";

export class UrlItem extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        index: PropTypes.number,
        urls: PropTypes.array,
        onChange: PropTypes.func,
    };
    render() {
        const { formatMessage } = this.props.intl;
        const { index, onChange } = this.props;
        return (
            <SubSection className="url-item">
                <TextInput
                    tabIndex={0}
                    maxLength={200}
                    placeholderText={formatMessage(messages.url, { count: 1 })}
                    labelText={formatMessage(messages.url, { count: 1 })}
                    onChange={(value) => onChange({ type: "url" }, value, index)}
                    value={this.props.urls[index].url}
                />
            </SubSection>
        );
    }
}

export default injectIntl(UrlItem);
