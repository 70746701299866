import { AppHelpers } from "@ai360/core";
import { CountryState } from "./country-state/country-state";

import GrowthStage from "./growth-stages/growth-stages";
import { Crop } from "./crop/crop";

import { Equipment } from "./equipment/equipment";
import { Gene } from "./gene/gene";
import { Nutrient } from "./nutrient/nutrient";
import { Observation } from "./observation/observation";
import { Picklist } from "./picklist/picklist";
import Product from "./product/product-container";
import { SampleAttribute } from "./sample-attribute/sample-attribute";
import { Trait } from "./trait/trait";
import { Unit } from "./unit/unit";
import { VarietyHybrid } from "./variety-hybrid/variety-hybrid";
import { VarietyHybridAttribute } from "./variety-hybrid-attribute/variety-hybrid-attribute";

const AgBytesViewResolver = (childNameCode) => {
    if (AppHelpers.isNothing(childNameCode)) {
        return;
    }
    switch (childNameCode) {
        case "126":
            return {
                component: Crop,
            };
        case "134":
            return {
                component: CountryState,
            };
        case "125":
            return {
                component: Product,
            };
        case "127":
            return {
                component: Observation,
            };
        case "128":
            return {
                component: Equipment,
            };
        case "129":
            return {
                component: Unit,
            };
        case "130":
            return {
                component: Picklist,
            };
        case "133":
            return {
                component: Nutrient,
            };
        case "135":
            return {
                component: VarietyHybrid,
            };
        case "136":
            return {
                component: GrowthStage,
            };
        case "176":
            return {
                component: SampleAttribute,
            };
        case "177":
            return {
                component: VarietyHybridAttribute,
            };
        case "178":
            return {
                component: Gene,
            };
        case "179":
            return {
                component: Trait,
            };
        default:
            return;
    }
};

export default AgBytesViewResolver;
