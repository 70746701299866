import { defineMessages } from "react-intl";

export const messages = defineMessages({
    contextMenuLabel: {
        id: "errorDetailsDialog.contextMenuLabel",
        defaultMessage: "Error Details",
    },
    errorDetailsTitle: {
        id: "errorDetailsDialog.errorDetailsTitle",
        defaultMessage: "Error Details",
    },
    errorDetailsSource: {
        id: "errorDetailsDialog.errorDetailsSource",
        defaultMessage: "{correlationSource}: ",
    },
    errorDetailsMetadata: {
        id: "errorDetailsDialog.errorDetailsMetadata",
        defaultMessage: "Time: {timestamp} - Correlation ID: {correlationId}",
    },
});
