import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";

export class ToasterMessage_ extends PureComponent {
    static propTypes = {
        intl: intlShape.isRequired,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        values: PropTypes.object,
    };

    render() {
        const { message, values } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                {React.isValidElement(message) || typeof message === "string"
                    ? message
                    : formatMessage(message, values)}
            </div>
        );
    }
}

export const ToasterMessage = injectIntl(ToasterMessage_);
