import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
    preventBubbleUp,
} from "~/admin/utils";
import { PICKLIST_PHONE_TYPE } from "~/core/picklist/picklist-names";
import { messages } from "../../i18n-messages";
import * as picklistService from "~/core/picklist/picklist-names";
import { createAddLinkLabelText } from "~/i18n-messages";

// Components
import {
    Button,
    TextInput,
    SelectInput,
    Checkbox,
    Section,
    SubSection,
    TextArea,
    NumericInput,
    NoLink,
    ZeroToInfiniteGrid,
} from "~/core";
import {
    ABBREVIATION,
    ADD,
    EDIT,
    adminData,
    GUID,
    ID,
    NAME,
    PICKLIST_GUID,
    VALUE,
} from "~/admin/data";
import Address from "../../component/address/address";
import Urls from "../../component/urls/urls";
import Phone from "../../component/phone/phone";
import Email from "./components/email/email";
import EquipmentAddEdit from "../../equipment/add-edit/add-edit-container";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import TestPackage from "../../component/testpackage";

// Style
import "./add-edit-panel.css";
import "../../add-edit-shared-responsive.css";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        createEquipment: PropTypes.func,
        equipmentList: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        fetchVendorEquipmentList: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.person,
        recordGuid: PropTypes.string,
        removeVendorRecord: PropTypes.func,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    static ADDRESS_TYPE_GUID = "addressTypeGuid";
    static COUNTRY_GUID = "countryGuid";
    static STATE_GUID = "stateGuid";
    static VENDOR_TYPE_TESTING_LAB = "12";

    constructor(props) {
        super(props);
        this.vendorData = {};
        this.equipmentAddRequestId = null;
        this.state = {
            [PICKLIST_PHONE_TYPE]: [],
            [model.PROPS_ADDRESS_LIST]: [],
            [model.PROPS_COUNTRIES_LIST]: [],
            [model.PROPS_PERSON_ADDRESS_LIST]: [],
            [model.PROPS_VENDOR_URL_LIST]: [],
            [model.PROPS_PHONE_NUMBER_LIST]: [],
            showEquipmentScreen: false,
            saveEquipmentData: false,
            equipmentListData: [],
        };
    }

    componentDidMount() {
        const { needs, addEditPanel } = this.props;
        addEditPanel.mode === "ADD" && this.props.setBreadcrumbs([""]);
        this.vendorData = service.getDefaultRecord();
        needs([this.props.fetchPicklistData(), this.props.fetchDropdownData()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.vendorData[model.PROPS_VENDOR_EQUIPMENT_LIST] = this.state.equipmentListData;
            this.props.liftRecordData(this.vendorData);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            if (nextProps.nextId) {
                this.setState({
                    nextId: nextProps.nextId,
                });
            }
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.vendorData = nextProps.record;
                this.setState({
                    equipmentListData: this.vendorData.vendorEquipmentList,
                });
            }
            if (nextProps.equipmentList.length) {
                this.vendorData.vendorEquipmentList = nextProps.equipmentList;
                this.setState({
                    equipmentListData: nextProps.equipmentList,
                });
            }
        }
        this.initializeDropdowns(nextProps);
        this.processAddEquipmentRequest(nextProps);
    }

    componentWillUnmount() {
        this.props.removeVendorRecord();
    }

    processAddEquipmentRequest = (nextProps) => {
        if (nextProps.requestIds[this.equipmentAddRequestId] === "SUCCESS") {
            if (this.state.currentEquipmentData) {
                this.setState({ currentEquipmentData: null });
                const { needs } = this.props;
                needs([
                    this.props.fetchVendorEquipmentList(this.vendorData[model.PROPS_VENDOR_GUID]),
                ]);
            }
            this.equipmentAddRequestId = null;
            this.toggleEquipmentAddEditPanel();
        } else if (nextProps.requestIds[this.equipmentAddRequestId] === "FAILURE") {
            this.equipmentAddRequestId = null;
        }
    };

    initializeDropdowns = (nextProps) => {
        this.initializeVendorType(nextProps);
        this.initializeAddressType(nextProps);
        this.initializeCountryList(nextProps);
        this.initializeStateList(nextProps);
        this.initializePhone(nextProps);
    };

    initializeVendorType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_VENDOR_TYPE] !==
            this.state[picklistService.PICKLIST_VENDOR_TYPE]
        ) {
            this.setState(
                {
                    [picklistService.PICKLIST_VENDOR_TYPE]: prepareSelectableOptions(
                        nextProps[picklistService.PICKLIST_VENDOR_TYPE],
                        { guid: PICKLIST_GUID, label: VALUE, id: ID }
                    ),
                },
                () => {
                    if (
                        nextProps.addEditPanel.mode === EDIT &&
                        nextProps.record &&
                        nextProps.record[model.PROPS_VENDOR_TYPE_GUID]
                    ) {
                        this.setState({
                            vendorTypeId: this.getID(
                                this.state[picklistService.PICKLIST_VENDOR_TYPE],
                                nextProps.record[model.PROPS_VENDOR_TYPE_GUID]
                            ),
                        });
                    }
                }
            );
        }
    };

    initializeAddressType = (nextProps) => {
        if (nextProps[model.PROPS_ADDRESS_LIST] !== this.state[model.PROPS_ADDRESS_LIST]) {
            this.setState({
                [model.PROPS_ADDRESS_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_ADDRESS_LIST],
                    { guid: AddEditPanel.ADDRESS_TYPE_GUID, label: NAME }
                ),
            });
        }
    };

    initializeCountryList = (nextProps) => {
        if (nextProps[model.PROPS_COUNTRIES_LIST] !== this.state[model.PROPS_COUNTRIES_LIST]) {
            this.setState({
                [model.PROPS_COUNTRIES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_COUNTRIES_LIST],
                    { guid: AddEditPanel.COUNTRY_GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeStateList = (nextProps) => {
        if (nextProps[model.PROPS_STATES_LIST] !== this.state[model.PROPS_STATES_LIST]) {
            this.setState({
                [model.PROPS_STATES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_STATES_LIST],
                    { guid: AddEditPanel.STATE_GUID, label: ABBREVIATION }
                ),
            });
        }
    };

    initializePhone = (nextProps) => {
        if (nextProps[PICKLIST_PHONE_TYPE] !== this.state[PICKLIST_PHONE_TYPE]) {
            this.setState({
                [PICKLIST_PHONE_TYPE]: prepareSelectableOptions(nextProps[PICKLIST_PHONE_TYPE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.vendorData = handlePicklistChange(this.vendorData, { type, guid, value }, callback);
    };

    onNumberChange = (formKey, value, callback) => {
        this.vendorData = onNumberChange(this.vendorData, { formKey: [formKey], value }, callback);
    };

    onTextChange = (formKey, value, callback) => {
        this.vendorData = onTextChange(this.vendorData, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.vendorData[model.PROPS_VENDOR_NAME]]);
    };

    onVendorTypeChange = (id) => {
        this.setState({
            vendorTypeId: id,
        });
    };

    toggleEquipmentAddEditPanel = () => {
        this.setState({
            showEquipmentScreen: !this.state.showEquipmentScreen,
        });
        this.setHeaderText("Add");
    };

    toggleEquipmentSave = () => {
        this.setState({
            saveEquipmentData: !this.state.saveEquipmentData,
        });
    };

    onSaveEquipmentData = (data) => {
        this.toggleEquipmentSave();
        if (this.equipmentAddRequestId === null) {
            this.setState(
                {
                    currentEquipmentData: data,
                },
                () => {
                    this.equipmentAddRequestId = this.props.needs([
                        this.props.createEquipment(data),
                    ]);
                }
            );
        }
    };

    mapSelectedCountry = ({ options = [], guid }) => {
        if (guid && options.length) {
            return mapToPicklistValue({ options, selectedGuid: guid });
        }
        return null;
    };

    getID = (options = [], guid) => {
        if (options.length && guid) {
            const selectedOption = options.filter((option) => {
                return guid === option.value[GUID];
            });
            const { id } = selectedOption.length && selectedOption[0].value;
            return id;
        }
        return null;
    };

    renderAddress = () => {
        const { state, mapSelectedCountry } = this;
        const { addEditPanel, apiErrors, needs } = this.props;
        const props = {
            addEditPanel,
            apiErrors,
            mapSelectedCountry,
            needs,
        };
        return (
            <Section>
                <Address
                    addressList={this.vendorData[model.PROPS_VENDOR_ADDRESS_LIST]}
                    addressTypeList={state[model.PROPS_ADDRESS_LIST]}
                    countriesList={state[model.PROPS_COUNTRIES_LIST]}
                    stateList={state[model.PROPS_STATES_LIST]}
                    onChildComponentChange={this.onTextChange}
                    addressPropKey={model.PROPS_VENDOR_ADDRESS_LIST}
                    {...props}
                />
            </Section>
        );
    };

    renderVendorInfo = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.props;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <TextInput
                            required
                            autoFocus
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames(394, apiErrors)}
                            placeholderText={formatMessage(messages.vendorName)}
                            labelText={formatMessage(messages.vendorName)}
                            value={this.vendorData[model.PROPS_VENDOR_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_VENDOR_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                        />
                        <SelectInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(395, apiErrors)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_VENDOR_TYPE]}
                            placeholderText={formatMessage(messages.vendorType)}
                            labelText={formatMessage(messages.vendorType)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_VENDOR_TYPE],
                                selectedGuid: this.vendorData[model.PROPS_VENDOR_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.vendorData[model.PROPS_VENDOR_TYPE_GUID] = value.guid;
                                this.onVendorTypeChange(value.id);
                            }}
                            clearable={false}
                            required
                        />
                    </SubSection>
                </Section>
                <span className="section-fence no-bar" />
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={13}
                            value={this.vendorData[model.PROPS_GLN_IDENTIFIER]}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_GLN_IDENTIFIER, value)
                            }
                            placeholderText={formatMessage(messages.glnIdentifier)}
                            labelText={formatMessage(messages.glnIdentifier)}
                        />
                        {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ||
                        this.props.addEditPanel.mode === ADD ? null : (
                            <Checkbox
                                onChange={(evt, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={this.vendorData[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderLocationAffliation = () => {
        const { addEditPanel, needs, apiErrors } = this.props;
        const props = { addEditPanel, needs, apiErrors };
        return (
            <Section>
                <div>
                    <SubSection>
                        <Phone
                            phoneList={this.vendorData[model.PROPS_VENDOR_PHONE_NUMBER_LIST]}
                            phoneTypeList={this.state[PICKLIST_PHONE_TYPE]}
                            phonePropKey={model.PROPS_VENDOR_PHONE_NUMBER_LIST}
                            onChildComponentChange={this.onTextChange}
                            {...props}
                        />
                    </SubSection>
                    <SubSection>
                        <Urls
                            urlList={this.vendorData[model.PROPS_VENDOR_URL_LIST]}
                            urlListAlias={model.PROPS_VENDOR_URL_LIST}
                            onChildComponentChange={this.onTextChange}
                            {...props}
                        />
                    </SubSection>
                </div>
            </Section>
        );
    };

    renderNotes = () => {
        const { formatMessage } = this.props.intl;
        const { addEditPanel, needs, apiErrors, apiErrorModel } = this.props;
        const props = { addEditPanel, needs, apiErrors, apiErrorModel };
        return (
            <Section>
                <div>
                    {addEditPanel.mode === ADD ? null : (
                        <Section
                            className="grid-section"
                            headerText={formatMessage(messages.equipment)}
                        >
                            <SubSection className={"form-section-child-stretch mini-grid"}>
                                {!(this.state.equipmentListData.length > 0) ? null : (
                                    <ZeroToInfiniteGrid
                                        records={this.state.equipmentListData}
                                        columns={{
                                            equipmentName: {
                                                title: formatMessage(messages.name),
                                                width: 150,
                                            },
                                            equipmentParentType: {
                                                title: formatMessage(messages.parentType),
                                                width: 100,
                                            },
                                            equipmentType: {
                                                title: formatMessage(messages.typeName),
                                                width: 150,
                                            },
                                            manufacturer: {
                                                title: formatMessage(messages.manufacturer),
                                                width: 150,
                                            },
                                        }}
                                    />
                                )}
                                <div className="add-link-container">
                                    <NoLink
                                        className="add-link"
                                        label={createAddLinkLabelText(
                                            formatMessage,
                                            messages.equipment
                                        )}
                                        onClick={this.toggleEquipmentAddEditPanel}
                                    />
                                </div>
                            </SubSection>
                        </Section>
                    )}
                    <Section
                        headerText={formatMessage(messages.notes)}
                        className="form-section-child-stretch"
                    >
                        <SubSection>
                            <TextArea
                                placeholderText={formatMessage(messages.notes)}
                                labelText={formatMessage(messages.notes)}
                                value={this.vendorData[model.PROPS_NOTES]}
                                onChange={(value) => this.onTextChange(model.PROPS_NOTES, value)}
                            />
                        </SubSection>
                    </Section>
                    {!this.props.userRole[model.PROPS_TESTING_LAB] ||
                    this.state.vendorTypeId !== AddEditPanel.VENDOR_TYPE_TESTING_LAB ? null : (
                        <div>
                            <Section
                                headerText={formatMessage(messages.testingLab)}
                                className="form-section-child-stretch"
                            >
                                <SubSection>
                                    <TextInput
                                        maxLength={50}
                                        containerClassNames={["full-width-input"]}
                                        placeholderText={formatMessage(messages.labIdentifier)}
                                        labelText={formatMessage(messages.labIdentifier)}
                                        value={this.vendorData[model.PROPS_LAB_IDENTIFIER]}
                                        onChange={(value) =>
                                            this.onTextChange(model.PROPS_LAB_IDENTIFIER, value)
                                        }
                                    />
                                </SubSection>
                                <SubSection>
                                    <TextInput
                                        maxLength={50}
                                        containerClassNames={["full-width-input"]}
                                        placeholderText={formatMessage(messages.submitterId)}
                                        labelText={formatMessage(messages.submitterId)}
                                        value={this.vendorData[model.PROPS_SUBMITTER_ID]}
                                        onChange={(value) =>
                                            this.onTextChange(model.PROPS_SUBMITTER_ID, value)
                                        }
                                    />
                                </SubSection>
                            </Section>
                            <Email
                                className="extra-margin"
                                emailAlias={model.PROPS_EMAIL_ADDRESS}
                                formKey={model.PROPS_TESTING_LAB_FROM_EMAIL_LIST}
                                emailHeaderText={messages.sendFromEmail}
                                emailList={this.vendorData[model.PROPS_TESTING_LAB_FROM_EMAIL_LIST]}
                                onChildComponentChange={this.onTextChange}
                                {...props}
                            />
                            <Email
                                className="extra-margin"
                                emailHeaderText={messages.reportIssuesToEmail}
                                emailList={this.vendorData[model.PROPS_TESTING_LAB_TO_EMAIL_LIST]}
                                emailAlias={model.PROPS_EMAIL_ADDRESS}
                                formKey={model.PROPS_TESTING_LAB_TO_EMAIL_LIST}
                                onChildComponentChange={this.onTextChange}
                                {...props}
                            />
                            <Section
                                headerText={formatMessage(messages.testPackage)}
                                className="grid-section"
                            >
                                <TestPackage
                                    formatMessage={formatMessage}
                                    record={this.vendorData[model.PROPS_TEST_PACKAGE_LIST]}
                                    itemListAlias={model.PROPS_TEST_PACKAGE_LIST}
                                    onTextChange={(e, value) => {
                                        this.onTextChange(model.PROPS_TEST_PACKAGE_LIST, value);
                                    }}
                                />
                            </Section>
                        </div>
                    )}
                </div>
            </Section>
        );
    };

    renderEquipmentAddEdit = () => {
        const ownerOperatorData = {
            ownerOperatorVendorGuid: this.vendorData[model.PROPS_VENDOR_GUID],
            ownerOperatorName: this.vendorData[model.PROPS_VENDOR_NAME],
            ownerOperatorCity: this.vendorData[model.PROPS_OWNER_OPERATOR_CITY],
            ownerOperatorStateAbbreviation:
                this.vendorData[model.PROPS_OWNER_OPERATOR_STATE_ABBREVIATION],
        };
        return (
            <div className="equipment-add-edit-page">
                <EquipmentAddEdit
                    addEditPanel={{
                        mode: "ADD",
                    }}
                    needs={this.props.needs}
                    ownerOperatorData={ownerOperatorData}
                    fetchData={this.state.saveEquipmentData}
                    liftRecordData={this.onSaveEquipmentData}
                />
            </div>
        );
    };

    render() {
        return (
            <div className="add-edit-panel vendor-setup-cont">
                {this.renderVendorInfo()}
                <div className="section-container">
                    {this.renderAddress()}
                    <span className="section-fence bar" />
                    {this.renderLocationAffliation()}
                    <span className="section-fence bar" />
                    {this.renderNotes()}
                </div>
                {!this.state.showEquipmentScreen ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            component={this.renderEquipmentAddEdit}
                            navigateTo={{
                                parentNameCode: 101,
                                childNameCode: 140,
                            }}
                        >
                            <Button forceSubmit type="save" onClick={this.toggleEquipmentSave} />
                            <Button type="cancel" onClick={this.toggleEquipmentAddEditPanel} />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
