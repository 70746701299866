import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ReshapeIcon = withSvgIcon(
    <g>
        <path d="M61.5 376h81.7v19.9H61.5zM40.9 292.1h41.2v19.5H40.9zM102.3 292.1h41.2v19.5h-41.2zM187.3 292.1h-20.6v19.5h20.6v21.7h19.5v-21.7h1.1v-19.5h-1.1zM187.3 355.4h19.5v41.2h-19.5zM187.3 418.7h19.5v41.2h-19.5zM333 .4h41.2v19.5H333zM394.4.4h41.2v19.5h-41.2zM500 .4h-41.2v19.5h20.6v21.7h19.5V19.9h1.1zM479.4 63.6h19.5v41.2h-19.5zM479.4 126.9h19.5v41.2h-19.5zM479.4 206.4h-21.7V226h21.7v1h19.5v-41.2h-19.5zM289.2 21h21.7V1.4h-21.7v-1h-19.5v41.2h19.5z" />
        <path d="M290.3 62.2H0v437.5h437.5V206.4H290.3V62.2zM416.8 227v252H20.6V82.8h249.1V227h147.1z" />
        <path d="M395.2 62.6h-20v42.2H333v20.1h42.2v42.2h20v-42.2h42.3v-20.1h-42.3z" />
    </g>
);
