import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
import {
    PICKLIST_JOB_TITLE,
    PICKLIST_PHONE_TYPE,
    PICKLIST_PAY_TYPE,
    PICKLIST_LICENSE_TYPE,
} from "~/core/picklist/picklist-names";
import { UNIT_PAY_RATE } from "~/core/units/unit-names";
import { messages } from "../../i18n-messages";
import { ABBREVIATION, ADD, adminData, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
// Components
import {
    TextInput,
    SelectInput,
    Checkbox,
    Section,
    SubSection,
    DialogBox,
    DialogBoxFooterType,
} from "~/core";
import Address from "../../component/address/address";
import { AutoReportList } from "../../component/report/auto-report-list";
import Email from "../../component/email/email";
import Phone from "../../component/phone/phone";
import License from "../../component/license/license";
import EmployeeInfo from "../../component/employee-info";
import { OrgLevelList, PROPS_STATE_ABBREVIATION } from "~/admin/agBytes/components/org-level-list";
import SalesPersonId from "../../component/salesperson-id";

// Style
import "./add-edit-panel.css";
import "../../add-edit-shared-responsive.css";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.person,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    static ADDRESS_TYPE_GUID = "addressTypeGuid";
    static COUNTRY_GUID = "countryGuid";
    static STATE_GUID = "stateGuid";

    constructor(props) {
        super(props);
        this.person = {};
        this.state = {
            [PICKLIST_JOB_TITLE]: [],
            [PICKLIST_LICENSE_TYPE]: [],
            [PICKLIST_PHONE_TYPE]: [],
            [UNIT_PAY_RATE]: [],
            [model.PROPS_ADDRESS_TYPE_LIST]: [],
            [model.PROPS_COUNTRIES_LIST]: [],
            [model.PROPS_EMAIL_LIST]: [],
            [model.PROPS_LICENSE_LIST]: [],
            [model.PROPS_ORG_LEVEL_LIST]: [],
            [model.PROPS_PERSON_ADDRESS_LIST]: [],
            [model.PROPS_PERSON_ORG_LIST]: [],
            [model.PROPS_PHONE_NUMBER_LIST]: [],
            [model.PROPS_PERSON_AUTO_REPORT_LIST]: [],
            showIsActiveDialog: false,
            isPersonUser: false,
        };
    }

    componentDidMount() {
        this.props.setBreadcrumbs([""]);
        this.person = service.getDefaultRecord();
        const { needs } = this.props;
        needs([
            this.props.fetchUnitData(),
            this.props.fetchPicklistData(),
            this.props.fetchDropdownData(),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.person);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            if (nextProps.nextId) {
                this.setState({
                    nextId: nextProps.nextId,
                });
            }
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.person = { ...this.person, ...nextProps.record };
                // On edit, if there is no value removing the following key to avoid error 665
                if (!this.person[model.PROPS_PAY_RATE_UNIT_GUID]) {
                    delete this.person[model.PROPS_PAY_RATE_UNIT_GUID];
                }
                this.setState({
                    [model.PROPS_PERSON_ADDRESS_LIST]:
                        nextProps.record[model.PROPS_PERSON_ADDRESS_LIST],
                    [model.PROPS_EMAIL_LIST]: nextProps.record[model.PROPS_EMAIL_LIST],
                    [model.PROPS_PHONE_NUMBER_LIST]:
                        nextProps.record[model.PROPS_PHONE_NUMBER_LIST],
                    [model.PROPS_LICENSE_LIST]: nextProps.record[model.PROPS_LICENSE_LIST],
                    [model.PROPS_PERSON_ORG_LIST]: nextProps.record[model.PROPS_PERSON_ORG_LIST],
                    [model.PROPS_PERSON_AUTO_REPORT_LIST]:
                        nextProps.record[model.PROPS_PERSON_AUTO_REPORT_LIST],
                    isPersonUser: nextProps.record.isUser,
                });
            }
        }
        this.initializeDropdowns(nextProps);
    }

    initializeDropdowns = (nextProps) => {
        this.initializeJobTitle(nextProps);
        this.initializeAddressType(nextProps);
        this.initializeCountryList(nextProps);
        this.initializeStateList(nextProps);
        this.initializePhone(nextProps);
        this.initializePayType(nextProps);
        this.initializePayRate(nextProps);
        this.initializeLicense(nextProps);
        this.initializeOrgLevel(nextProps);
    };

    initializeJobTitle = (nextProps) => {
        if (nextProps[PICKLIST_JOB_TITLE] !== this.state[PICKLIST_JOB_TITLE]) {
            this.setState({
                [PICKLIST_JOB_TITLE]: prepareSelectableOptions(nextProps[PICKLIST_JOB_TITLE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    initializeAddressType = (nextProps) => {
        if (
            nextProps[model.PROPS_ADDRESS_TYPE_LIST] !== this.state[model.PROPS_ADDRESS_TYPE_LIST]
        ) {
            this.setState({
                [model.PROPS_ADDRESS_TYPE_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_ADDRESS_TYPE_LIST],
                    { guid: AddEditPanel.ADDRESS_TYPE_GUID, label: NAME }
                ),
            });
        }
    };

    initializeCountryList = (nextProps) => {
        if (nextProps[model.PROPS_COUNTRIES_LIST] !== this.state[model.PROPS_COUNTRIES_LIST]) {
            this.setState({
                [model.PROPS_COUNTRIES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_COUNTRIES_LIST],
                    { guid: AddEditPanel.COUNTRY_GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeStateList = (nextProps) => {
        if (nextProps[model.PROPS_STATES_LIST] !== this.state[model.PROPS_STATES_LIST]) {
            this.setState({
                [model.PROPS_STATES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_STATES_LIST],
                    { guid: AddEditPanel.STATE_GUID, label: ABBREVIATION }
                ),
            });
        }
    };

    initializePhone = (nextProps) => {
        if (nextProps[PICKLIST_PHONE_TYPE] !== this.state[PICKLIST_PHONE_TYPE]) {
            this.setState({
                [PICKLIST_PHONE_TYPE]: prepareSelectableOptions(nextProps[PICKLIST_PHONE_TYPE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    initializePayType = (nextProps) => {
        if (nextProps[PICKLIST_PAY_TYPE] !== this.state[PICKLIST_PAY_TYPE]) {
            this.setState({
                [PICKLIST_PAY_TYPE]: prepareSelectableOptions(nextProps[PICKLIST_PAY_TYPE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    initializePayRate = (nextProps) => {
        if (nextProps[UNIT_PAY_RATE] !== this.state[UNIT_PAY_RATE]) {
            this.setState({
                [UNIT_PAY_RATE]: prepareSelectableOptions(nextProps[UNIT_PAY_RATE], {
                    guid: "guid",
                    label: "name",
                }),
            });
        }
    };

    initializeLicense = (nextProps) => {
        if (nextProps[PICKLIST_LICENSE_TYPE] !== this.state[PICKLIST_LICENSE_TYPE]) {
            this.setState({
                [PICKLIST_LICENSE_TYPE]: prepareSelectableOptions(
                    nextProps[PICKLIST_LICENSE_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    initializeOrgLevel = (nextProps) => {
        if (nextProps[model.PROPS_ORG_LEVEL_LIST] !== this.state[model.PROPS_ORG_LEVEL]) {
            this.setState({
                [model.PROPS_ORG_LEVEL_LIST]: nextProps[model.PROPS_ORG_LEVEL_LIST],
            });
        }
    };

    onChildComponentChange = (childKey, value) => {
        this.person[childKey] = value;
        if (childKey === model.PROPS_EMAIL_LIST) {
            this.person[model.PROPS_ADD_EMAIL_VISIBLE] = value.length ? true : false;
        }
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.person = handlePicklistChange(this.person, { type, guid, value }, callback);
    };

    onTextChange = (formKey, value, callback) => {
        this.person = onTextChange(this.person, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        const firstName = this.person[model.PROPS_PERSON_FIRST_NAME] || "";
        const lastName = this.person[model.PROPS_PERSON_LAST_NAME] || "";
        this.props.setBreadcrumbs([`${firstName} ${lastName}`]);
    };

    mapSelectedCountry = ({ options = [], guid }) => {
        if (guid && options.length) {
            return mapToPicklistValue({ options, selectedGuid: guid });
        }
        return null;
    };

    showIsActiveDialog = (showIsActiveDialog) => this.setState({ showIsActiveDialog });

    onPersonInActive = () => {
        this.showIsActiveDialog(false);
        this.onTextChange(model.PROPS_ACTIVE_YN, false);
        this.onTextChange(model.PROPS_USER_ACTIVEYN, false);
        this.onTextChange(model.PROPS_DELETE_USER, true);
        this.onTextChange(model.PROPS_ROLE_GUID, "");
    };

    renderPersonInfo = () => {
        const { formatMessage } = this.props.intl;
        const { person } = this;
        const { apiErrors, userRole } = this.props;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.firstName)}
                            labelText={formatMessage(messages.firstName)}
                            value={person[model.PROPS_PERSON_FIRST_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_PERSON_FIRST_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            containerClassNames={getAgBytesErrorClassNames(26, apiErrors)}
                            autoFocus
                            required
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.lastName)}
                            labelText={formatMessage(messages.lastName)}
                            value={person[model.PROPS_PERSON_LAST_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_PERSON_LAST_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            containerClassNames={getAgBytesErrorClassNames(27, apiErrors)}
                            required
                        />
                    </SubSection>
                </Section>
                <Section className="person-section">
                    <SubSection className="job-container">
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[PICKLIST_JOB_TITLE]}
                            value={mapToPicklistValue({
                                options: this.state[PICKLIST_JOB_TITLE],
                                selectedGuid: person[model.PROPS_PERSON_JOB_GUID],
                            })}
                            onChange={(value) =>
                                (this.person[model.PROPS_PERSON_JOB_GUID] = value.guid)
                            }
                            placeholderText={formatMessage(messages.jobTitle)}
                            labelText={formatMessage(messages.jobTitle)}
                            containerClassNames={[
                                "jobtitle-container",
                                getAgBytesErrorClassNames(28, apiErrors),
                            ]}
                            clearable={false}
                            required
                        />
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ||
                        this.props.addEditPanel.mode === ADD ? null : (
                            <Checkbox
                                onChange={(e, value) => {
                                    if (!value && this.person[model.PROPS_USER_ACTIVEYN]) {
                                        this.showIsActiveDialog(true);
                                        return;
                                    }
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value);
                                }}
                                value={
                                    person[model.PROPS_ACTIVE_YN] != null
                                        ? person[model.PROPS_ACTIVE_YN]
                                        : true
                                }
                                label={formatMessage(messages.active)}
                                className="person-is-active"
                            />
                        )}
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderDetailInfo1 = () => {
        const { state, onChildComponentChange, mapSelectedCountry } = this;
        const { addEditPanel, needs, apiErrors } = this.props;
        const props = {
            onChildComponentChange,
            mapSelectedCountry,
            addEditPanel,
            needs,
            apiErrors,
        };
        return (
            <Address
                addressList={state[model.PROPS_PERSON_ADDRESS_LIST]}
                addressTypeList={state[model.PROPS_ADDRESS_TYPE_LIST]}
                countriesList={state[model.PROPS_COUNTRIES_LIST]}
                stateList={state[model.PROPS_STATES_LIST]}
                {...props}
            />
        );
    };

    renderDetailInfo2 = () => {
        const { state } = this;
        const { addEditPanel, apiErrors, apiErrorModel, userRole } = this.props;
        const props = { addEditPanel, apiErrors, apiErrorModel };
        const { formatMessage } = this.props.intl;
        return (
            <Section>
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.locationAffiliations)}
                >
                    {state[model.PROPS_ORG_LEVEL_LIST] ? (
                        <OrgLevelList
                            classNames={getAgBytesErrorClassNames(2033, apiErrors)}
                            itemList={state[model.PROPS_ORG_LEVEL_LIST]}
                            onSelectionChange={(value) => {
                                this.onTextChange(model.PROPS_PERSON_ORG_LIST, value, () =>
                                    this.setState({
                                        [model.PROPS_PERSON_ORG_LIST]: value,
                                    })
                                );
                            }}
                            record={state[model.PROPS_PERSON_ORG_LIST]}
                            statePropName={PROPS_STATE_ABBREVIATION}
                            isUser={state.isPersonUser}
                        />
                    ) : null}
                </Section>
                {!userRole.personSalesperson ? null : (
                    <Section
                        headerText={formatMessage(messages.salespersonIDHeading)}
                        className="grid-section"
                    >
                        <SubSection>
                            <SalesPersonId
                                formatMessage={formatMessage}
                                record={this.person[model.PROPS_PERSON_SALES_PERSON_LIST]}
                                itemListAlias={model.PROPS_PERSON_SALES_PERSON_LIST}
                                onTextChange={(e, value) => {
                                    this.onTextChange(model.PROPS_PERSON_SALES_PERSON_LIST, value);
                                }}
                                classNames={getAgBytesErrorClassNames(
                                    2628,
                                    apiErrors,
                                    [],
                                    "sales-person-id-error"
                                )}
                            />
                        </SubSection>
                    </Section>
                )}
                <Email
                    emailList={state[model.PROPS_EMAIL_LIST]}
                    onChildComponentChange={this.onChildComponentChange}
                    {...props}
                />
                <Phone
                    phoneList={state[model.PROPS_PHONE_NUMBER_LIST]}
                    phoneTypeList={state[PICKLIST_PHONE_TYPE]}
                    onChildComponentChange={this.onChildComponentChange}
                    {...props}
                />
            </Section>
        );
    };

    renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        const { state, onTextChange } = this;
        const { needs, addEditPanel, apiErrors, recordGuid, userRole } = this.props;
        const props = {
            needs,
            addEditPanel,
            onTextChange,
            apiErrors,
        };
        return (
            <Section>
                {!userRole.autoCreateReports ? null : (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.autoCreateReports)}
                    >
                        <AutoReportList
                            classNames={getAgBytesErrorClassNames(2033, apiErrors)}
                            onSelectionChange={(value) => {
                                this.onTextChange(model.PROPS_PERSON_AUTO_REPORT_LIST, value, () =>
                                    this.setState({
                                        [model.PROPS_PERSON_AUTO_REPORT_LIST]: value,
                                    })
                                );
                            }}
                            personGuid={recordGuid}
                            record={state[model.PROPS_PERSON_AUTO_REPORT_LIST]}
                        />
                    </Section>
                )}
                <License
                    formatMessage={formatMessage}
                    licenseType={state[PICKLIST_LICENSE_TYPE]}
                    stateList={state[model.PROPS_STATES_LIST]}
                    countriesList={state[model.PROPS_COUNTRIES_LIST]}
                    record={this.person[model.PROPS_LICENSE_LIST]}
                    itemListAlias={model.PROPS_LICENSE_LIST}
                    {...props}
                />
                <EmployeeInfo
                    employeeInfo={this.person}
                    onTextChange={this.onTextChange}
                    payType={state[PICKLIST_PAY_TYPE]}
                    payRate={state[UNIT_PAY_RATE]}
                    {...props}
                />
            </Section>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { showIsActiveDialog } = this.state;
        return (
            <div className="add-edit-panel person-setup-cont">
                {this.renderPersonInfo()}
                <div className="section-container">
                    {this.renderDetailInfo1()}
                    <span className="bar section-fence" />
                    {this.renderDetailInfo2()}
                    <span className="bar section-fence" />
                    {this.renderDetailInfo3()}
                </div>
                <DialogBox
                    forceOverflow
                    title={formatMessage(messages.confirm)}
                    footerType={DialogBoxFooterType.YES_NO}
                    onClose={() => this.showIsActiveDialog(false)}
                    onAction={this.onPersonInActive}
                    isOpen={showIsActiveDialog}
                >
                    {formatMessage(messages.userInActiveConfirm)}
                </DialogBox>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
