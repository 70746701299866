import React, { PureComponent, ReactElement } from "react";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";

import { Button, DialogBox, Loader, Menu, Tabs } from "~/core";

import { AddIcon, FilterIcon, GeneralIcon } from "~/core/icons";

import { eventsModels, messages as recsEventsMessages } from "~/recs-events";

import { errorCodeMessages } from "~/i18n-error-messages";

import { messages } from "./i18n-messages";

import "./rec-event-info.css";
export interface IFilterPaneLabel_Props {
    intl: intlShape;
}
export const FilterPaneLabel_ = ({ intl }: IFilterPaneLabel_Props): JSX.Element => {
    return (
        <div className={classnames("rec-event-info-tab-label")}>
            <FilterIcon />
            <div className="tab-nav-text">{intl.formatMessage(messages.filterTabLbl)}</div>
        </div>
    );
};

FilterPaneLabel_.propTypes = {
    intl: intlShape.isRequired,
};

export const FilterPaneLabel = injectIntl(FilterPaneLabel_);

export interface IGeneralPaneLabel_Props {
    hasError: boolean;
    intl: intlShape;
}

export const GeneralPaneLabel_ = ({ intl, hasError }: IGeneralPaneLabel_Props): JSX.Element => {
    return (
        <div
            className={classnames("rec-event-info-tab-label", {
                "has-error": hasError,
            })}
        >
            <GeneralIcon />
            <div className="tab-nav-text">{intl.formatMessage(messages.generalTabLbl)}</div>
        </div>
    );
};

export const GeneralPaneLabel = injectIntl(GeneralPaneLabel_);

export interface IRecEventsInfo_Props {
    addItemTitle: string;
    addMenuItems: string[];
    canAddRecEvent?: boolean;
    enableRun: boolean;
    enableSave: boolean;
    errorCodeList?: number[];
    errorFieldList?: Record<string, any>[];
    eventRecItemStrip?: ReactElement;
    informationPanes: any;
    intl: intlShape;
    isAnalysis?: boolean;
    isBatch?: boolean;
    isBatchEdit: boolean;
    isBatchTemplate: boolean;
    isBatchRecEdit?: boolean;
    isCancelEnabled?: boolean;
    isCloseEnabled?: boolean;
    isCloseVisible?: boolean;
    isEquation?: boolean;
    isLoading: boolean;
    isManagementArea?: boolean;
    isMergeEvent: boolean;
    isNew: boolean;
    isRxFileImport: boolean;
    isRxFileImportComplete: boolean;
    isRxFileImportNextActionEnabled: boolean;
    isRxFileImportUploadStep: boolean;
    onBack?: () => void;
    onCancel: () => void;
    onSave: () => void;
}

export interface IRecEventsInfo_State {
    displayErrors: boolean;
    selectedTab: number;
}

export class RecEventInfo_ extends PureComponent<IRecEventsInfo_Props, IRecEventsInfo_State> {
    static defaultProps = {
        enableBack: false,
        enableRun: false,
        errorFieldList: [],
        canAddRecEvent: true,
        isAnalysis: false,
        isBatch: false,
        isBatchEdit: false,
        isCancelEnabled: true,
        isCloseEnabled: false,
        isCloseVisible: false,
        isEquation: false,
        isMergeEvent: false,
        isNew: false,
        onRun: (): any => null,
    };

    constructor(props: IRecEventsInfo_Props) {
        super(props);

        this.state = {
            displayErrors: false,
            selectedTab:
                (!props.isNew && props.informationPanes.length > 1) || props.isBatchEdit ? 1 : 0,
        };
    }

    // FIXME: put this function in the "~/i18n-error-messages" module
    // is this still applicable? ^^^^

    private _getErrorMessage(errCode, isPlural = false) {
        const { formatMessage } = this.props.intl;
        if (errCode in errorCodeMessages) {
            return formatMessage(errorCodeMessages[errCode]);
        }
        return formatMessage(recsEventsMessages.validationErrorMsg, {
            errCode,
            count: isPlural ? 0 : 1,
        });
    }

    private _renderAddItemEl() {
        const { addItemTitle, addMenuItems, canAddRecEvent } = this.props;
        return !canAddRecEvent || addMenuItems.length === 0 ? null : (
            <div className="rec-event-info-tab-label add-item" title={addItemTitle}>
                <Menu
                    isDotMenu={true}
                    dotMenuRenderer={() => <AddIcon />}
                    menuItems={addMenuItems}
                />
            </div>
        );
    }

    private _renderErrorDialog() {
        const { errorCodeList, errorFieldList, informationPanes } = this.props;

        const { formatMessage } = this.props.intl;

        if (errorCodeList.length === 0 || !this.state.displayErrors) {
            return;
        }
        const isApplication = informationPanes.find(
            (pane) => pane.key === eventsModels.EVENT_TYPE_NAME_APPLICATION
        ); //Only display the field list if the event type is application
        const errorMessageDivs = Array.from(new Set(errorCodeList)).map((errCode) => (
            <div key={errCode}>
                {this._getErrorMessage(errCode, errorFieldList && errorFieldList.length > 1)}
            </div>
        ));
        return (
            <DialogBox
                className="error-message-dialog-box"
                title={formatMessage(recsEventsMessages.validationDlgTitle)}
                isOpen
                onClose={() => this.setState({ displayErrors: false })}
            >
                {errorMessageDivs}
                {!errorFieldList || errorFieldList.length === 0 || !isApplication ? null : (
                    <div className="error-field-list">{errorFieldList}</div>
                )}
            </DialogBox>
        );
    }

    private _renderSaveCancelBtns() {
        const {
            enableRun,
            enableSave,
            isAnalysis,
            isBatch,
            isBatchTemplate,
            isBatchRecEdit,
            isCancelEnabled,
            isCloseEnabled,
            isCloseVisible,
            isEquation,
            isManagementArea,
            isMergeEvent,
            isNew,
            isRxFileImport,
            isRxFileImportComplete,
            isRxFileImportNextActionEnabled,
            isRxFileImportUploadStep,
            onBack,
            onCancel,
            onSave,
        } = this.props;
        const { formatMessage } = this.props.intl;

        const saveCancelBtns = [];
        if (!(isBatch && !isBatchTemplate && !isMergeEvent)) {
            if (onBack && !(isEquation && (isCloseVisible || !enableRun))) {
                saveCancelBtns.push(<Button key="back" type="back" onClick={onBack} />);
            }
            if (isManagementArea) {
                saveCancelBtns.push(
                    <Button key="save" type="save" disabled={!enableSave} onClick={onSave} />
                );
            } else if (isAnalysis) {
                const buttonLabel = isNew ? messages.create : messages.update;
                saveCancelBtns.push(
                    <Button
                        key="run"
                        type="button"
                        disabled={!enableSave}
                        onClick={onSave}
                        value={formatMessage(buttonLabel)}
                    />
                );
            } else if (isMergeEvent) {
                saveCancelBtns.push(
                    <Button
                        key="merge"
                        type="button"
                        disabled={false}
                        onClick={onSave}
                        value={formatMessage(messages.mergeEvents)}
                    />
                );
            } else if (!isEquation) {
                saveCancelBtns.push(
                    <Button
                        key="save"
                        type={isBatchTemplate ? "next" : "save"}
                        disabled={!enableSave}
                        onClick={onSave}
                    />
                );
            } else if (isBatchRecEdit) {
                saveCancelBtns.push(
                    <Button key="close" type="close" disabled={false} onClick={onCancel} />
                );
            } else {
                const runBtnProps = {
                    type: "",
                    value: "",
                    key: "run",
                    disabled: !enableRun,
                    onClick: onSave,
                };
                if (isRxFileImport) {
                    runBtnProps.disabled = !isRxFileImportComplete
                        ? !isRxFileImportNextActionEnabled
                        : !enableRun;
                    runBtnProps.type = isRxFileImportUploadStep || isBatchTemplate ? "next" : "run";
                } else if (isBatchTemplate) {
                    runBtnProps.type = "next";
                } else {
                    runBtnProps.type = "button";
                    runBtnProps.value = formatMessage(messages.runBtnText);
                }
                saveCancelBtns.push(<Button {...runBtnProps} />);
                if (isCloseVisible) {
                    saveCancelBtns.push(
                        <Button
                            key="close"
                            type="close"
                            disabled={!isCloseEnabled || !enableSave}
                            onClick={onSave}
                        />
                    );
                }
            }
            if (!isBatchRecEdit) {
                saveCancelBtns.push(
                    <Button
                        key="cancel"
                        type="cancel"
                        onClick={onCancel}
                        disabled={!isCancelEnabled}
                    />
                );
            }
        }
        return <div className="save-cancel-btns">{saveCancelBtns}</div>;
    }

    UNSAFE_componentWillReceiveProps(newProps: IRecEventsInfo_Props): void {
        const getAllPanesKey = (panes) => panes.map((pane) => pane.key).join("");
        if (newProps.errorCodeList !== this.props.errorCodeList) {
            const firstErrorIdx = newProps.informationPanes.findIndex(
                (pane) => pane.props.label.props.hasError
            );
            const newState = { displayErrors: true };
            this.setState(firstErrorIdx === -1 ? newState : { ...newState });
            return;
        }
        if (
            newProps.informationPanes.length !== this.props.informationPanes.length ||
            getAllPanesKey(newProps.informationPanes) !==
                getAllPanesKey(this.props.informationPanes)
        ) {
            if (this.props.isLoading && !newProps.isLoading) {
                // auto-select the first non-general pane once loading completes ..
                this.setState({
                    selectedTab: !newProps.isNew && newProps.informationPanes.length > 1 ? 1 : 0,
                });
                return;
            }

            // if we added a tab, select it
            if (newProps.informationPanes.length > this.props.informationPanes.length) {
                const existingTabKeys = new Set(this.props.informationPanes.map((tab) => tab.key));
                this.setState({
                    selectedTab: newProps.informationPanes.findIndex(
                        (tab) => !existingTabKeys.has(tab.key)
                    ),
                });
                return;
            }

            // if we deleted the current tab, set `selectedTab === 0`,
            //   otherwise set selectedTab based on current key
            const curTab = this.props.informationPanes[this.state.selectedTab];
            const newSelectedTabIdx = newProps.informationPanes.findIndex(
                (newTab) => newTab.key === curTab.key
            );
            this.setState({
                selectedTab: newSelectedTabIdx !== -1 ? newSelectedTabIdx : 0,
            });
        }
    }

    render(): JSX.Element {
        const { eventRecItemStrip, isBatch, isLoading, informationPanes } = this.props;

        return (
            <div
                className={classnames("rec-event-info", {
                    "batch-rec-event-info": isBatch,
                })}
            >
                {eventRecItemStrip}
                <Tabs
                    className="information-tabs"
                    labelContainerRightElements={[this._renderAddItemEl()]}
                    selected={this.state.selectedTab}
                    onTabSelect={(idx) => this.setState({ selectedTab: idx })}
                >
                    {informationPanes}
                </Tabs>
                {!isLoading ? null : <Loader />}
                {this._renderErrorDialog()}
                {this._renderSaveCancelBtns()}
            </div>
        );
    }
}

export const RecEventInfo = injectIntl(RecEventInfo_);
