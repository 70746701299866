import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { service, actions, model } from "./data";
import { withMasked, withApiResult, withCrud } from "~/hocs";
import { preventBubbleUp } from "~/admin/utils";
import { DataTable, Button, DialogBox, DialogBoxFooterType, TextInput } from "~/core";
import { messages } from "../i18n-messages";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import "./template-manager.css";
// Components
import AddEditPanel from "./add-edit/add-edit-container";
import { keywords } from "./keywords";

export class TemplateManagerView extends Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        actions: PropTypes.object,
        addEditPanel: PropTypes.object,
        closeSidePanel: PropTypes.func,
        resetTemplate: PropTypes.func,
        deleteSelected: PropTypes.func,
        fileMetaData: PropTypes.object,
        importTemplateList: PropTypes.array,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        onSubmit: PropTypes.func,
        openAddEditPanel: PropTypes.func,
        records: PropTypes.array,
        toggleActiveInactive: PropTypes.func,
    };
    static defaultProps = {};
    ///----------------------------///
    constructor(props) {
        super(props);
        const { formatMessage } = this.props.intl;
        this.state = {
            copyTemplate: false,
            liftCopyRecord: false,
            newCopyTemplateName: "",
            showConfirmationModal: false,
        };
        this.tableFooterOptions = [
            {
                label: formatMessage(messages.deleteSelected),
                action: this.props.deleteSelected,
            },
        ];
    }
    onToggleModalClick = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };
    onSelectAll = () => {
        const { records } = this.props;
        this.setState({
            selectAll: records.map(
                (record) =>
                    record[model.PROPS_CONTROLLER_TEMPLATE_GUID] ||
                    record[model.PROPS_TEMPLATE_GUID]
            ),
        });
    };
    toggleOnCopy = () => {
        this.setState({
            copyTemplate: !this.state.copyTemplate,
        });
    };
    onCopyTemplateNameChange = (value) => {
        this.setState({
            newCopyTemplateName: value,
        });
    };
    onCopyTemplate = () => {
        this.setState({
            liftCopyRecord: true,
        });
    };
    onSubmit = () => {
        if (this.shouldShowConfirmationModal(this.props.fileMetaData.importTemplateList)) {
            this.setState({
                showConfirmationModal: true,
            });
        } else {
            this.props.onSubmit();
        }
    };
    toggleCopyRequest = () => {
        this.setState({
            liftCopyRecord: false,
            newCopyTemplateName: "",
            copyTemplate: false,
        });
    };
    getNonEditableFileTypeCheck = () => {
        return (
            this.props.fileMetaData[model.PROPS_TEMPLATE_NAME] === keywords.MODUS_FILE_NAME ||
            this.props.fileMetaData[model.PROPS_TEMPLATE_NAME] === keywords.IMAGERY_FILE_NAME
        );
    };
    onConfirmationModalAction = () => {
        this.setState({
            showConfirmationModal: false,
        });
        this.props.onSubmit();
    };
    onConfirmationModalClose = () => {
        this.setState({
            showConfirmationModal: false,
        });
    };
    shouldShowConfirmationModal = (importTemplateList) => {
        if (!importTemplateList) {
            return false;
        }

        const hasImportAttributeWithNameLike = (name) =>
            importTemplateList.some((template) =>
                template.importAttributes.some((importAttribute) =>
                    importAttribute.columnNameInFile?.includes(name)
                )
            );

        const hasFlow = hasImportAttributeWithNameLike("Flow");
        const hasYield = hasImportAttributeWithNameLike("Yld");

        return hasFlow && hasYield;
    };
    render() {
        const { formatMessage } = this.props.intl;
        const { showAddEditPanel } = this.props.addEditPanel;
        const { liftCopyRecord, newCopyTemplateName } = this.state;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
                onSubmit: this.onSubmit,
                liftCopyRecord,
                newCopyTemplateName,
                toggleCopyRequest: this.toggleCopyRequest,
            };
        }
        const disableSaveForManualFlag =
            this.props.fileMetaData[model.PROPS_IMPORT_TEMPLATE_LIST][0][model.PROPS_MANUAL_ONLY] &&
            this.props.fileMetaData[model.PROPS_IMPORT_TEMPLATE_LIST][0][
                model.PROPS_IMPORT_ATTRIBUTES
            ].length === 0;
        return (
            <div className="content-table-container template-manager-cont">
                <DataTable
                    isEditable
                    isCheckbox
                    service={service}
                    messages={messages}
                    {...this.props}
                    onSelectAll={this.onSelectAll}
                    selectAll={this.state.selectAll}
                    footerOptions={this.tableFooterOptions}
                    forcedRender={false}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "107",
                            }}
                        >
                            <Button
                                forceSubmit
                                disabled={
                                    !this.props.fileMetaData[model.PROPS_TEMPLATE_NAME] ||
                                    disableSaveForManualFlag
                                }
                                type="save"
                                onClick={() =>
                                    this.onSubmit(
                                        this.props.importTemplateList,
                                        this.props.onSubmit
                                    )
                                }
                            />
                            {this.props.addEditPanel.mode !== "EDIT" ||
                            this.getNonEditableFileTypeCheck() ? null : (
                                <Button
                                    type="copy"
                                    onClick={() => {
                                        this.setState(
                                            {
                                                newCopyTemplateName:
                                                    this.props.fileMetaData[
                                                        model.PROPS_TEMPLATE_NAME
                                                    ],
                                            },
                                            this.toggleOnCopy
                                        );
                                    }}
                                />
                            )}
                            <Button
                                type="cancel"
                                onClick={() => {
                                    this.props.resetTemplate();
                                    this.props.closeSidePanel();
                                }}
                            />
                        </SlidingPanel>
                    </form>
                )}
                <DialogBox
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    isOpen={this.state.copyTemplate}
                    title={formatMessage(messages.newTemplateName)}
                    actionDisabled={!this.state.newCopyTemplateName}
                    onAction={this.onCopyTemplate}
                    onClose={this.state.copyTemplate ? this.toggleOnCopy : () => null}
                >
                    <TextInput
                        maxLength={50}
                        placeholderText={formatMessage(messages.newTemplateName)}
                        labelText={formatMessage(messages.templateName)}
                        onChange={this.onCopyTemplateNameChange}
                        value={this.state.newCopyTemplateName}
                    />
                </DialogBox>
                <DialogBox
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    isOpen={this.state.showConfirmationModal}
                    title={formatMessage(messages.confirmationModalTitle)}
                    onAction={this.onConfirmationModalAction}
                    onClose={this.onConfirmationModalClose}
                >
                    {formatMessage(messages.confirmationModalBody)}
                </DialogBox>
            </div>
        );
    }
}
export const TemplateManager = injectIntl(
    withMasked(withApiResult(withCrud(TemplateManagerView, service, actions), actions.importData))
);
