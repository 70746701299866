import { intlShape } from "react-intl";
import { getSplitErrorMessages } from "~/i18n-error-messages";
import { IApiErrorData } from "~/core/api/reducer";
import { newApiErrorsToErrorDetails } from "~/utils/api/new-api";

const useApiErrors = (apiError: IApiErrorData, intl: intlShape) => {
    let errorMessageCount = 0;
    const legacyErrorMessages: string[] = [];
    const newApiErrorMessages: string[] = [];
    let serverErrorApiResultObject = null;

    const allErrors = [];
    if (apiError.errorCodeList) {
        // Contains Legacy API Error
        allErrors.push(apiError);
    }
    if (apiError.newApiResults && apiError.newApiResults.errors) {
        // Contains New API Error
        allErrors.push(apiError.newApiResults);
    }
    if (apiError.apiResults) {
        // Contains Multiple API Errors
        apiError.apiResults.forEach((errorObject) => {
            if (errorObject.errorCodeList) {
                allErrors.push(errorObject);
            } else if (typeof errorObject.apiResultObj.errors !== "undefined") {
                allErrors.push(errorObject.apiResultObj);
            } else if (typeof errorObject.apiResultObj["0"] !== "undefined") {
                // Handle case where an array was returned. Current New API response gets dropped
                // into an object, so that array indices end up as keys. This is workaround until
                // a better solution is implemented on the new API side.
                Object.keys(errorObject.apiResultObj).forEach((key) => {
                    if (!isNaN(parseInt(key)) && errorObject.apiResultObj[key].errors) {
                        allErrors.push(errorObject.apiResultObj[key]);
                    }
                });
            } else if (
                errorObject.apiResultObj &&
                errorObject.apiResultObj.status &&
                (errorObject.apiResultObj.exceptionMessage || errorObject.apiResultObj.message)
            ) {
                allErrors.push(errorObject.apiResultObj);
            }
        });
    } else if (
        apiError.apiResultObj &&
        apiError.apiResultObj.status &&
        (apiError.apiResultObj.exceptionMessage || apiError.apiResultObj.message)
    ) {
        // Contains legacy API error
        allErrors.push(apiError.apiResultObj);
    }

    allErrors.forEach((error) => {
        // Legacy API Errors
        if (error.errorCodeList && error.errorCodeList.length > 0) {
            errorMessageCount = error.errorCodeList.length;
            legacyErrorMessages.push(...getSplitErrorMessages(intl.formatMessage, error, "<br/>"));
        }

        // New API Errors
        if (error.errors) {
            const newApiErrorDetails = newApiErrorsToErrorDetails(error.errors, intl);
            errorMessageCount += newApiErrorDetails.details.length;
            newApiErrorMessages.push(...newApiErrorDetails.details);
        }

        if (error.status && (error.exceptionMessage || error.message)) {
            serverErrorApiResultObject = error;
        }
    });

    return {
        errorMessageCount,
        legacyErrorMessages,
        newApiErrorMessages,
        serverErrorApiResultObject,
    };
};

export default useApiErrors;
