export const NAVLIST_FETCH_INIT = "navigation/NAVLIST_FETCH_INIT";
export const NAVLIST_FETCH_SUCCEEDED = "navigation/NAVLIST_FETCH_SUCCEEDED";

export const fetchNavlist = () => ({
    type: NAVLIST_FETCH_INIT,
    payload: {},
});

export const navlistFetchSucceeded = (navList) => ({
    type: NAVLIST_FETCH_SUCCEEDED,
    payload: { navList },
});
