import * as models from "./models";

export const ON_NEXT_STEP = "rx-file-import/ON_NEXT_STEP";
export const PREPARE_RECS = "rx-file-import/PREPARE_RECS";
export const RX_FILE_IMPORT_RESET = "rx-file-import/RX_FILE_IMPORT_RESET";
export const SET_COMPLETE_STEP = "rx-file-import/SET_COMPLETE_STEP";
export const SET_MATCH_STEP = "rx-file-import/SET_MATCH_STEP";
export const SET_UPLOAD_STEP = "rx-file-import/SET_UPLOAD_STEP";
export const SET_IMPORT_COLUMN = "rx-file-import/SET_IMPORT_COLUMN";
export const SET_IMPORT_PRODUCT_REC_MATCH_RESULT =
    "rx-file-import/SET_IMPORT_PRODUCT_REC_MATCH_RESULT";
export const SET_RX_FILE_IMPORT_LOADING = "rx-file-import/SET_RX_FILE_IMPORT_LOADING";
export const SETUP_RX_FILE_IMPORT = "rx-file-import/SETUP_RX_FILE_IMPORT";

export const onNextStep = () => ({
    type: ON_NEXT_STEP,
});

export const prepareRecs = () => ({
    type: PREPARE_RECS,
});

export const rxFileImportReset = () => ({
    type: RX_FILE_IMPORT_RESET,
});

export const setCompleteStep = () => ({
    type: SET_COMPLETE_STEP,
});

export const setMatchStep = () => ({
    type: SET_MATCH_STEP,
});

export const setUploadStep = () => ({
    type: SET_UPLOAD_STEP,
});

export const setImportColumn = (
    recNutrientEquationName: string,
    column: models.RXFileImportSelectableColumnValue
) => ({
    type: SET_IMPORT_COLUMN,
    payload: {
        column,
        recNutrientEquationName,
    },
});

export const setImportProductRecMatchResult = (importProductRecMatchResult) => ({
    type: SET_IMPORT_PRODUCT_REC_MATCH_RESULT,
    payload: {
        importProductRecMatchResult,
    },
});

export const setRxFileImportLoading = (isLoading) => ({
    type: SET_RX_FILE_IMPORT_LOADING,
    payload: {
        isLoading,
    },
});

export const setupRxFileImport = () => ({
    type: SETUP_RX_FILE_IMPORT,
});
