import React, { Component } from "react";
import _ from "lodash";
import { SelectInput, defaultOptionRenderer } from "~/core";
import { GUID, NAME } from "~/admin/data";
import { injectIntl } from "react-intl";
import {
    ICategorySelectInputState,
    ICategorySelectInputProps,
    ICategoryAttribute,
    ISelectOption,
    IOptionRendererProps,
} from "../select-input/model";

import "./select-input-category.css";

export class SelectInputCategory_ extends Component<
    ICategorySelectInputProps<ICategoryAttribute>,
    ICategorySelectInputState
> {
    static defaultProps = {
        autoFocus: false,
        clearFilterInputOnBlur: true,
        clearOnEmptyFilterStr: false,
        containerClassNames: [],
        openOnFocus: true,
        optionRenderer: defaultOptionRenderer,
        options: [],
        placeholderText: "",
        required: false,
        value: undefined,
        visible: true,
    };
    static CATEGORY_INDEX = "categoryIndex";
    static USE_ONLY = "useOnly";

    constructor(props: ICategorySelectInputProps<ICategoryAttribute>) {
        super(props);
        this.state = {
            attributeList: this.prepareSelectableOptions(
                props.options as ISelectOption<ICategoryAttribute>[],
                {
                    guid: GUID,
                    label: NAME,
                    id: SelectInputCategory_.CATEGORY_INDEX,
                }
            ),
            selectedAttribute: null,
            useOnly: null,
        };
    }

    UNSAFE_componentWillReceiveProps(
        nextProps: ICategorySelectInputProps<ICategoryAttribute>
    ): void {
        if (nextProps.options !== this.props.options) {
            this.setState({
                attributeList: this.prepareSelectableOptions(
                    nextProps.options as ISelectOption<ICategoryAttribute>[],
                    {
                        guid: GUID,
                        label: NAME,
                        id: SelectInputCategory_.CATEGORY_INDEX,
                        useOnly: SelectInputCategory_.USE_ONLY,
                    }
                ),
            });
        }
        if (nextProps.value !== this.props.value) {
            this.setState({ selectedAttribute: nextProps.value });
        }
    }

    filterOptions(
        filterVal: string,
        options: ISelectOption<ICategoryAttribute>[],
        isHeaderOption: (option: ISelectOption<ICategoryAttribute>) => boolean
    ): (number | number[] | Map<number, number>)[] {
        const matchingIdxList = [],
            existingHeaderOptions = [];
        const optionMatchPositions = [];
        const filteredOptListIdxMap = new Map();
        filterVal = filterVal == null ? "" : filterVal.toLowerCase();
        const headerOptions = options.reduce((acc, option, index) => {
            if (isHeaderOption(option)) {
                acc.push({ index, categoryIndex: option.category });
            }
            return acc;
        }, []);
        for (let optionIdx = 0, matchCount = 0; optionIdx < options.length; optionIdx++) {
            const option = options[optionIdx];
            const labelVal = option.label.toLowerCase();
            const idx = labelVal.indexOf(filterVal);
            optionMatchPositions.push(idx);
            if (labelVal === filterVal && matchingIdxList.length < 2) {
                matchingIdxList.push(optionIdx);
            }
            if (idx !== -1) {
                if (option.category) {
                    existingHeaderOptions.push(optionIdx);
                } else {
                    const headerIdx = headerOptions.find((item) => {
                        if (!option.category) {
                            return item.categoryIndex === option.value.categoryIndex;
                        }
                        return false;
                    });
                    if (
                        filterVal.length > 0 &&
                        headerIdx &&
                        existingHeaderOptions.indexOf(headerIdx.index) === -1
                    ) {
                        filteredOptListIdxMap.set(matchCount++, headerIdx.index);
                        existingHeaderOptions.push(headerIdx.index);
                    }
                }
                filteredOptListIdxMap.set(matchCount++, optionIdx);
            }
        }
        const exactMatchIdx = matchingIdxList.length === 1 ? matchingIdxList[0] : null;
        return [optionMatchPositions, filteredOptListIdxMap, exactMatchIdx];
    }

    findSelectedOptionByGuid = (value: ICategoryAttribute): any => {
        //for populating the initial selected item, sometimes only the guid is available
        if (typeof value === "object") {
            const matchingItem = this.state.attributeList
                .filter((attribute) => attribute.header !== true)
                .find((option) => option.value.guid === value.guid);
            return matchingItem ? matchingItem.value : value;
        }
        return value;
    };

    prepareOptions = (
        options: ISelectOption<ICategoryAttribute>[],
        { guid, label, id, useOnly }: ICategoryAttribute
    ): ISelectOption<ICategoryAttribute>[] =>
        _.reduce(
            options,
            (result, option) => {
                const { optionIsHiddenKey } = this.props;
                const formattedOption = {
                    label: option[label],
                    value: {
                        guid: option[guid],
                        id: option[id],
                        name: option[label],
                        useOnly: option[useOnly],
                        categoryIndex: option[SelectInputCategory_.CATEGORY_INDEX],
                    },
                };
                if (optionIsHiddenKey) {
                    formattedOption.value[optionIsHiddenKey] = option[optionIsHiddenKey];
                }
                result.push(formattedOption);
                return result;
            },
            []
        );

    optionRenderer = ({
        option,
        isHeader,
        isSelected,
        isHighlighted,
        matchPos,
        matchLen,
    }: IOptionRendererProps<ICategoryAttribute>): JSX.Element => {
        if (!isHeader) {
            return defaultOptionRenderer({
                option,
                isHeader,
                isSelected,
                isHighlighted,
                matchPos,
                matchLen,
            });
        }
        return (
            <div className="select-form-sub-header">
                <span>{option.label}</span>
            </div>
        );
    };

    prepareSelectableOptions = (
        options: ISelectOption<ICategoryAttribute>[],
        { guid, label, id, useOnly }: ICategoryAttribute
    ): ISelectOption<ICategoryAttribute>[] => {
        const { categories, getCategoryGroupName, optionIsHiddenKey } = this.props;
        let availableOptions = options;
        if (optionIsHiddenKey) {
            availableOptions = options.filter((option) => option[optionIsHiddenKey] === true);
        }
        const preparedCategories = categories.map((category) => {
            const categoryArray = availableOptions.filter(
                (option) => option.categoryIndex === category
            );
            if (categoryArray.length > 0) {
                return [
                    {
                        label: getCategoryGroupName(category),
                        category,
                        header: true,
                        activeYn: true,
                    },
                    ...this.prepareOptions(categoryArray, {
                        guid,
                        label,
                        id,
                        useOnly,
                    }),
                ];
            }
            return [];
        });
        return _.flatten(preparedCategories);
    };

    onAttributeChange = (selectedAttribute: ICategoryAttribute): void => {
        const useOnly = Boolean(selectedAttribute ? selectedAttribute.useOnly : false);
        this.setState({
            selectedAttribute,
            useOnly,
        });
    };

    render(): JSX.Element {
        const {
            autoFocus,
            clearable,
            clearFilterInputOnBlur,
            clearOnEmptyFilterStr,
            containerClassNames,
            onChange,
            openOnFocus,
            optionIsHeaderKey,
            placeholderText,
            required,
            value,
            visible,
        } = this.props;
        const { attributeList } = this.state;
        const formattedValue = this.findSelectedOptionByGuid(value);
        return (
            <div className="select-input-category-container">
                <SelectInput<ICategoryAttribute>
                    autoFocus={autoFocus}
                    required={required}
                    clearable={clearable}
                    clearFilterInputOnBlur={clearFilterInputOnBlur}
                    clearOnEmptyFilterStr={clearOnEmptyFilterStr}
                    openOnFocus={openOnFocus}
                    placeholderText={placeholderText}
                    value={formattedValue}
                    options={attributeList}
                    onChange={(value) => {
                        onChange(value);
                    }}
                    containerClassNames={containerClassNames}
                    optionIsHeaderKey={optionIsHeaderKey}
                    optionRenderer={this.optionRenderer}
                    filterOptions={this.filterOptions}
                    visible={visible}
                />
            </div>
        );
    }
}
export const SelectInputCategory = injectIntl(SelectInputCategory_);
