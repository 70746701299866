export const ActionPanelModuleList = {
    FIELD: "FIELD_MODULE",
    EVENT: "EVENT_MODULE",
    REC: "REC_MODULE",
    LAYER: "LAYER_MODULE",
    IMPORT: "IMPORT_MODULE",
};

export const ACTIONPANEL_SET_ACTIVEMODULE = "actionpanel/SET_ACTIVEMODULE";
export const ACTIONPANEL_SET_ISEXPANDED = "actionpanel/SET_ISEXPANDED";
export const ACTIONPANEL_SET_ISENABLED = "actionpanel/SET_ISENABLED";

export const setActiveModule = (newValue) => ({
    type: ACTIONPANEL_SET_ACTIVEMODULE,
    payload: newValue,
});
export const setIsExpanded = (newValue) => ({
    type: ACTIONPANEL_SET_ISEXPANDED,
    payload: newValue,
});
export const setIsEnabled = (newValue) => ({
    type: ACTIONPANEL_SET_ISENABLED,
    payload: newValue,
});
