export const config = {
    currencyFormatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "USD",
    },
    customFormatOptions: (fractionDigits) => ({
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }),
    utcDateFormatOptions: {
        timeZone: "UTC",
        year: "2-digit",
        month: "numeric",
        day: "numeric",
    },
    nonGroupedNumberFormatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
    },
    numberFormatOptions: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    decimalFormatOptions: {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    },
    wholeNumberFormatOptions: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
};
