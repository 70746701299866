import { all } from "redux-saga/effects";
import createSagas from "./create";
import updateSagas from "./update";
import { templateManagerSagas } from "../controller";

const TemplateManagerDataSagas = function* () {
    yield all([createSagas(), updateSagas(), templateManagerSagas()]);
};

export default TemplateManagerDataSagas;
