import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { ZoomInIcon } from "../../assets/zoom-in";
import { ZoomOutIcon } from "../../assets/zoom-out";

import { MapConfig } from "@ai360/core";

export class ZoomControl extends Component {
    static propTypes = {
        zoom: PropTypes.number.isRequired,
        onSetZoomLevel: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.maxZoomLevel = _.maxBy(MapConfig.lods, (lod) => {
            return lod.level;
        }).level;
    }

    _zoomIn() {
        if (this.props.zoom < this.maxZoomLevel) {
            this.props.onSetZoomLevel(this.props.zoom + 1);
        }
    }

    _zoomOut() {
        if (this.props.zoom > 0) {
            this.props.onSetZoomLevel(this.props.zoom - 1);
        }
    }

    render() {
        return (
            <div className="zoom-container">
                <div className="zoom-plus" onClick={() => this._zoomIn()}>
                    <ZoomInIcon />
                </div>
                <div className="zoom-minus" onClick={() => this._zoomOut()}>
                    <ZoomOutIcon />
                </div>
            </div>
        );
    }
}
