export const CLOSE_IMAGERY_LAYER = "imagery/CLOSE_IMAGERY_LAYER";
export const DELETE_IMAGERY_LAYER = "imagery/DELETE_IMAGERY_LAYER";
export const FETCH_IMAGERY_LAYER = "imagery/FETCH_IMAGERY_LAYER";
export const SAVE_IMAGERY_LAYER = "imagery/SAVE_IMAGERY_LAYER";
export const SET_IMAGERY_LAYER_SUMMARY = "imagery/SET_IMAGERY_LAYER_SUMMARY";
export const SET_IMAGERY_DETAILS_LOADING = "imagery/SET_IMAGERY_DETAILS_LOADING";
export const SHOW_IMAGERY_LAYER = "imagery/SHOW_IMAGERY_LAYER";
export const UPDATE_IMAGERY_LAYER = "imagery/UPDATE_IMAGERY_LAYER";

export const closeImageryLayer = () => ({
    type: CLOSE_IMAGERY_LAYER,
});

export const deleteImageryLayer = (imageryLayerGuid, fieldGuid) => ({
    type: DELETE_IMAGERY_LAYER,
    payload: { imageryLayerGuid, fieldGuid },
});

export const fetchImageryLayer = (imageryLayerGuid) => ({
    type: FETCH_IMAGERY_LAYER,
    payload: { imageryLayerGuid },
});

export const saveImageryLayer = () => ({
    type: SAVE_IMAGERY_LAYER,
});

export const setImageryLayerSummary = (imageryLayerSummary) => ({
    type: SET_IMAGERY_LAYER_SUMMARY,
    payload: { imageryLayerSummary },
});

export const setImageryDetailsLoading = (isLoading) => ({
    type: SET_IMAGERY_DETAILS_LOADING,
    payload: { isLoading },
});

export const showImageryLayer = (surfaceInfo, fieldGuid) => ({
    type: SHOW_IMAGERY_LAYER,
    payload: { surfaceInfo, fieldGuid },
});

export const updateImageryLayer = (newProps) => ({
    type: UPDATE_IMAGERY_LAYER,
    payload: { newProps },
});
