import { connect } from "react-redux";
import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { dropdowns, service, constants, actions, selectors } from "./../data";
import { getDropdown } from "~/admin/selectors";
import { fetchDropdownData } from "~/core/dropdowns/actions";

const mapStateToProps = (state) => ({
    applicationSampleAttributes: getDropdown(constants.APPLICATION_SAMPLE_ATTRIBUTES, state),
    fileMetadata: selectors.getFileMetaData(state),
    gridData: selectors.getModuleState(state).gridData,
    harvestSampleAttributes: getDropdown(constants.HARVEST_SAMPLE_ATTRIBUTES, state),
    isMonitorType: selectors.getModuleState(state).isMonitorType,
    plantingSampleAttributes: getDropdown(constants.PLANTING_SAMPLE_ATTRIBUTES, state),
    sampleAttributes: getDropdown(constants.SAMPLE_ATTRIBUTES, state),
    templateData: selectors.getImportTemplateList(state),
    currentTab: selectors.getModuleState(state).currentTab,
});

const mapDispatchToProps = (dispatch) => ({
    clearTemplateData: (payload) => dispatch(actions.clearTemplateData(payload)),
    setCurrentTab: (payload) => dispatch(actions.setCurrentTab(payload)),
    setFileMetadata: (payload) => dispatch(actions.setFileMetadata(payload)),
    setIsMonitorFileFlag: (payload) => dispatch(actions.setIsMonitorFileFlag(payload)),
    updateFileType: (payload) => dispatch(actions.updateFileType(payload)),
    updateImportAttributes: (payload) => dispatch(actions.updateImportAttributes(payload)),
    updateManualImportAttributes: (payload) =>
        dispatch(actions.updateManualImportAttributes(payload)),
    updateImportTemplateList: (payload) => dispatch(actions.updateImportTemplateList(payload)),
    updateTemplate: (payload) => dispatch(actions.updateTemplate(payload)),
    // Note: Actions requires needs hoc to dispatch / process
    fetchDropdownData: (payload) => fetchDropdownData(payload),
    getImportControllerTemplate: (payload) => actions.getImportControllerTemplate(payload),
    getImportTemplate: (payload) => actions.getImportTemplate(payload),
    getTemplateGridData: (payload) => actions.getTemplateGridData(payload),
    verifyTemplateFile: (payload) => actions.verifyTemplateFile(payload),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditPanelContainer(AddEditPanel, { dropdowns, service }));
