import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_DESCRIPTION = "description";
export const PROPS_EQUATION_GROUP_GUID = "equationGroupGuid";
export const PROPS_EQUATION_GROUP_ID = "equationGroupId";
export const PROPS_EQUATION_GROUP_NAME = "equationGroupName";
export const PROPS_EQUATION_GROUP_TYPE = "equationGroupType";
export const PROPS_GROUP_NAME = "groupName";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_SAMPLING_EVENT_REQUIRED = "samplingEventRequired";
export const PROPS_VENDOR_GUID = "vendorGuid";
export const PROPS_VENDOR_NAME = "vendorName";
