import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "~/core";
import { messages } from "../i18n-messages";
import { IReportTypes } from "../data/interfaces";

export const ReportTypes = ({
    reportTypes,
    selectReportType,
    selectedReportTypes,
    formatMessage,
}: IReportTypes): JSX.Element => {
    const isReportTypeSelected = ({ reportTypeGuid }) => {
        return selectedReportTypes.indexOf(reportTypeGuid) > -1;
    };

    const getLabelString = () => {
        return `1. ${formatMessage(messages.selectReports)}:`;
    };

    return (
        <div className="report-types">
            <div className="options-header">{getLabelString()}</div>
            {reportTypes?.map((reportType, index) => (
                <Checkbox
                    key={index}
                    value={isReportTypeSelected(reportType)}
                    label={reportType.reportTypeDisplayName}
                    onChange={() =>
                        selectReportType({
                            name: reportType.reportTypeName,
                            guid: reportType.reportTypeGuid,
                        })
                    }
                />
            ))}
        </div>
    );
};

ReportTypes.propTypes = {
    formatMessage: PropTypes.func,
    reportTypes: PropTypes.array,
    selectReportType: PropTypes.func,
    selectedReportTypes: PropTypes.array,
};
