import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const BroadcastIcon = withSvgIcon(
    <g>
        <path d="M7.8 19.4c-.5.6-1 1.1-1.6 1.7-5.2-5-5.5-14.5 0-20 .5.6 1 1.1 1.6 1.7-4.3 5.4-4.3 10.9 0 16.6zM12 14.9c-.4.6-.8 1.2-1.1 1.7-2.2-2.8-2.2-8.2.1-11.4.1.3.3.7.5 1 .2.3.4.5.6.8-1.5 2.7-1.5 5.3-.1 7.9z" />
        <path className="st0" d="M19.5 14.3L7.1 47.1M22.7 12.8l12.7 34.3" />
        <path className="st1" d="M11 36.6l20.1-.4M15.4 25.1l12-.2" />
        <circle className="st0" cx="21.2" cy="10.9" r="4" />
        <path d="M33.3 2.8c.5-.6 1-1.1 1.6-1.7 5.2 5 5.5 14.5 0 20-.5-.6-1-1.1-1.6-1.7 4.3-5.4 4.3-10.9 0-16.6zM29.1 7.3c.4-.6.8-1.2 1.1-1.7 2.1 2.8 2.1 8.2-.1 11.4-.1-.3-.3-.7-.5-1-.2-.3-.4-.5-.6-.8 1.5-2.7 1.5-5.3.1-7.9z" />
    </g>,
    "0 0 41.4 50"
);
