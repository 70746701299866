import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BaseYieldList from "./base-yield-list";
import { messages } from "../i18n-messages";
import { selectors as picklistSelectors, picklistNames } from "~/core/picklist"; //actions as picklistActions,

import * as actions from "~/action-panel/components/event-module/components/event-info/actions";
import { keywords } from "~/action-panel/components/event-module/keywords";

class BreakEvenYieldList_ extends React.Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        record: PropTypes.array,
        onTextChange: PropTypes.func,
        crops: PropTypes.array,
        cropPurpose: PropTypes.array,
        dryYieldUnit: PropTypes.array,
        lintYieldUnit: PropTypes.array,
        fetchSystemAttributePicklist: PropTypes.func,
    };

    componentDidMount() {
        let unitsToFetch = [];
        if (!this._dryUnitsAvailable()) {
            unitsToFetch.push({
                model: keywords.dryYieldUnit,
                systemAttribute: keywords.dryYield,
                eventType: keywords.harvest,
                forceRaw: true,
            });
        }

        if (!this._lintUnitsAvailable()) {
            unitsToFetch.push({
                model: keywords.lintYieldUnit,
                systemAttribute: keywords.lintYield,
                eventType: keywords.harvest,
                forceRaw: true,
            });
        }
        if (unitsToFetch.length > 0) {
            this.props.fetchSystemAttributePicklist(unitsToFetch);
        }
    }

    _dryUnitsAvailable() {
        return this.props.dryYieldUnit && this.props.dryYieldUnit.length > 0;
    }

    _lintUnitsAvailable() {
        return this.props.lintYieldUnit && this.props.lintYieldUnit.length > 0;
    }

    render() {
        return (
            <BaseYieldList
                addGoalMessage={messages.breakEvenYield}
                goalPlaceholderMessage={messages.breakEvenYield}
                breakEvenMode={true}
                dryYieldUnit={this.props.dryYieldUnit}
                lintYieldUnit={this.props.lintYieldUnit}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    dryYieldUnit: picklistSelectors.getRawPicklistOptionsFromCode(state, keywords.dryYieldUnit),
    lintYieldUnit: picklistSelectors.getRawPicklistOptionsFromCode(state, keywords.lintYieldUnit),
    cropPurpose: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
    ),
});

const mapDispatchToProps = (dispatch) => ({
    fetchSystemAttributePicklist: (payload) =>
        dispatch(actions.fetchSystemAttributePicklist(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BreakEvenYieldList_);
