export const ADD_EQUIPMENT_IMPORT_FILTER = "import-wizard/ADD_EQUIPMENT_IMPORT_FILTER_DATA";
export const ADD_EQUIPMENT_IMPORT_FILTER_FAILURE =
    "import-wizard/ADD_EQUIPMENT_IMPORT_FILTER_FAILED";
export const ADD_EQUIPMENT_IMPORT_FILTER_SUCCESS =
    "import-wizard/ADD_EQUIPMENT_IMPORT_FILTER_SUCCESS";
export const ASSIGN_MATCHED_TO_UNMATCHED = "import-wizard/ASSIGN_MATCHED_TO_UNMATCHED";
export const CANCEL_IMPORT_EVENT = "import-wizard/CANCEL_IMPORT_EVENT";
export const COMPLETE_FIELD_MATCH = "import-wizard/COMPLETE_FIELD_MATCH";
export const CREATE_IMPORT_EVENT = "import-wizard/CREATE_IMPORT_EVENT";
export const FETCH_AGVANCE_FIELD_LIST = "import-wizard/FETCH_AGVANCE_FIELD_LIST";
export const FETCH_AGVANCE_FIELD_LIST_SUCCEEDED =
    "import-wizard/FETCH_AGVANCE_FIELD_LIST_SUCCEEDED";
export const FETCH_AGVANCE_ORG_LEVEL_LIST = "import-wizard/FETCH_AGVANCE_ORG_LEVEL_LIST";
export const FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED =
    "import-wizard/FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED";
export const FETCH_CURRENT_FIELD_DATA = "import-wizard/FETCH_CURRENT_FIELD_DATA";
export const SET_CURRENT_FIELD_DATA = "import-wizard/SET_CURRENT_FIELD_DATA";
export const FETCH_EVENT_STRIP_PREVIEW_DATA = "import-wizard/FETCH_EVENT_STRIP_PREVIEW_DATA";
export const FETCH_IMPORT_FIELD_STRIP_DATA = "import-wizard/FETCH_IMPORT_FIELD_STRIP_DATA";
export const FETCH_IMPORT_FILTER_LIST_DATA = "import-wizard/FETCH_IMPORT_FILTER_LIST_DATA";
export const FETCH_IMPORT_SAMPLING_POINTS = "import-wizard/FETCH_IMPORT_SAMPLING_POINTS";
export const FETCH_USER_CROP_IMPORT_PREFERENCES =
    "import-wizard/FETCH_USER_CROP_IMPORT_PREFERENCES";
export const REMOVE_IMPORT_FILES = "import-wizard/REMOVE_IMPORT_FILES";
export const RERUN_MATCHING = "import-wizard/RERUN_MATCHING";
export const RESET_IMPORT_WIZARD = "import-wizard/RESET_IMPORT_WIZARD";
export const SET_API_ERROR_RESULT_LIST = "import-wizard/SET_API_ERROR_RESULT_LIST";
export const SET_APPEND_LIST = "import-wizard/SET_APPEND_LIST";
export const SET_DROPDOWN_DATA = "import-wizard/SET_DROPDOWN_DATA";
export const SET_EVENT_STRIP_PREVIEW_DATA = "import-wizard/SET_EVENT_STRIP_PREVIEW_DATA";
export const SET_FIELD_MATCHING_COMPLETE = "import-wizard/SET_FIELD_MATCHING_COMPLETE";
export const SET_FIELD_MATCHING_LOADING = "import-wizard/SET_FIELD_MATCHING_LOADING";
export const SET_FILTERED_FIELD_IMPORT_FILE_LIST =
    "import-wizard/SET_FILTERED_FIELD_IMPORT_FILE_LIST";
export const SET_FORCE_UPDATE = "import-wizard/SET_FORCE_UPDATE";
export const SET_HAS_SUBMITTED = "import-wizard/SET_HAS_SUBMITTED";
export const SET_IGNORE_FARM = "import-wizard/SET_IGNORE_FARM";
export const SET_IGNORE_SELECTED_FIELD = "import-wizard/SET_IGNORE_SELECTED_FIELD";
export const SET_IMPORT_CONVEX_HULLS = "import-wizard/SET_IMPORT_CONVEX_HULLS";
export const SET_IMPORT_FIELD_BOUNDARIES = "import-wizard/SET_IMPORT_FIELD_BOUNDARIES";
export const SET_IMPORT_FIELD_LIST = "import-wizard/SET_IMPORT_FIELD_LIST";
export const SET_IMPORT_FIELD_STRIP_DATA = "import-wizard/SET_IMPORT_FIELD_STRIP_DATA";
export const SET_IMPORT_FILTER_LIST_DATA = "import-wizard/SET_IMPORT_FILTER_LIST_DATA";
export const SET_IMPORT_GROOMING_REQUEST = "import-wizard/SET_IMPORT_GROOMING_REQUEST";
export const SET_IMPORT_SAMPLING_POINTS = "import-wizard/SET_IMPORT_SAMPLING_POINTS";
export const SET_IMPORT_WIZARD_STEP = "import-wizard/SET_IMPORT_WIZARD_STEP";
export const SET_IS_FILTERING_VISIBLE = "import-wizard/SET_IS_FILTERING_VISIBILE";
export const SET_IS_LOADING = "import-wizard/SET_IS_LOADING";
export const SET_NAME_MATCHES_VALIDATING = "import-wizard/SET_NAME_MATCHES_VALIDATING";
export const SET_SELECTED_IMPORT_FIELD_INDEX = "import-wizard/SET_SELECTED_IMPORT_FIELD_INDEX";
export const SET_SELECTED_MATCHED_FIELD_GUID = "import-wizard/SET_SELECTED_MATCHED_FIELD_GUID";
export const SET_SHOW_IMPORT_POINTS = "import-wizard/SET_SHOW_IMPORT_POINTS";
export const SET_SHOW_PROCESSING = "import-wizard/SET_SHOW_PROCESSING";
export const SET_SUCCESSFUL_NAME_MATCH_IMPORTS = "import-wizard/SET_SUCCESSFUL_NAME_MATCH_IMPORTS";
export const SET_USER_CROP_IMPORT_PREFERENCES = "import-wizard/SET_USER_CROP_IMPORT_PREFERENCES";
export const SET_VALIDATING_STATUS = "import-wizard/SET_VALIDATING_STATUS";
export const SUBMIT_FIELD_BOUNDARY = "import-wizard/SUBMIT_FIELD_BOUNDARY";
export const UNMATCH_FIELD_FILE = "import-wizard/UNMATCH_FIELD_FILE";
export const UPDATE_ATTRIBUTE = "import-wizard/UPDATE_ATTRIBUTE";
export const UPDATE_EVENT_ID = "import-wizard/UPDATE_EVENT_ID";
export const UPDATE_IMAGERY_ATTRIBUTE = "import-wizard/UPDATE_IMAGERY_ATTRIBUTE";
export const UPDATE_IMPORT_BOUNDARY = "import-wizard/UPDATE_IMPORT_BOUNDARY";
export const UPDATE_PROPOSED_EVENT_STATUS = "import-wizard/UPDATE_PROPOSED_EVENT_STATUS";
export const UPDATE_USER_CROP_IMPORT_PREFERENCES =
    "import-wizard/UPDATE_USER_CROP_IMPORT_PREFERENCES";
export const VALIDATE_IMPORT_EVENT = "import-wizard/VALIDATE_IMPORT_EVENT";

export const PanelTypes = {
    FIELD_BOUNDARY_PANEL: "FieldBoundaryPanel",
    FILES_PANEL: "FilesPanel",
    FIELDS_PANEL: "FieldsPanel",
    EVENTS_PANEL: "EventsPanel",
};
export const ImportTypes = {
    APPLICATION: "application",
    ECDATA: "ec data",
    FIELD_BOUNDARY: "field boundary",
    HARVEST: "harvest",
    IMAGERY: "imagery",
    IRRIGATION: "irrigation",
    PLANTING: "planting",
    SAMPLING_FERTILIZER: "sampling - fertilizer",
    SAMPLING_MANURE: "sampling - manure",
    SAMPLING_NEMATODE: "sampling - nematode",
    SAMPLING_SOIL: "sampling - soil",
    SAMPLING_TISSUE: "sampling - tissue",
    SAMPLING_WATER: "sampling - water",
    TILLAGE: "tillage",
};
export const SamplingImportTypes = [
    ImportTypes.SAMPLING_FERTILIZER,
    ImportTypes.SAMPLING_MANURE,
    ImportTypes.SAMPLING_NEMATODE,
    ImportTypes.SAMPLING_SOIL,
    ImportTypes.SAMPLING_TISSUE,
    ImportTypes.SAMPLING_WATER,
];

export const ImportFilterTypes = {
    CROPS: "crops",
    CROPPING_SEASONS: "croppingSeasons",
    EQUIPMENT_PROFILES: "equipmentProfiles",
    PRODUCTS: "products",
    LAYER_LIST: "layerList",
};

export const addEquipmentImportFilter = (model) => ({
    type: ADD_EQUIPMENT_IMPORT_FILTER,
    payload: { model },
});

export const addEquipmentImportFilterSuccess = (model) => ({
    type: ADD_EQUIPMENT_IMPORT_FILTER_SUCCESS,
    payload: { model },
});

export const addEquipmentImportFilterFailure = (model) => ({
    type: ADD_EQUIPMENT_IMPORT_FILTER_FAILURE,
    payload: { model },
});

export const cancelImportEvent = (importFileGuidList) => ({
    type: CANCEL_IMPORT_EVENT,
    payload: { importFileGuidList },
});

export const completeFieldMatch = (payload) => ({
    type: COMPLETE_FIELD_MATCH,
    payload,
});

export const createImportEvent = (requestList) => ({
    type: CREATE_IMPORT_EVENT,
    payload: { requestList },
});

export const fetchAgvanceFieldList = (customerGuid) => ({
    type: FETCH_AGVANCE_FIELD_LIST,
    payload: customerGuid,
});

export const fetchAgvanceFieldListSucceeded = (agvanceFieldList) => ({
    type: FETCH_AGVANCE_FIELD_LIST_SUCCEEDED,
    payload: { agvanceFieldList },
});

export const fetchAgvanceOrgLevelList = (customerGuid) => ({
    type: FETCH_AGVANCE_ORG_LEVEL_LIST,
    payload: customerGuid,
});

export const fetchAgvanceOrgLevelListSucceeded = (agvanceOrgLevelList) => ({
    type: FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED,
    payload: { agvanceOrgLevelList },
});

export const fetchCurrentFieldData = (fieldGuid) => ({
    type: FETCH_CURRENT_FIELD_DATA,
    payload: { fieldGuid },
});

export const removeImportFiles = (importFileGuidList) => ({
    type: REMOVE_IMPORT_FILES,
    payload: { importFileGuidList },
});

export const assignMatchedToUnmatched = (index, assignment) => ({
    type: ASSIGN_MATCHED_TO_UNMATCHED,
    payload: { index, assignment },
});

export const setCurrentFieldData = (currentFieldData) => ({
    type: SET_CURRENT_FIELD_DATA,
    payload: { currentFieldData },
});

export const fetchEventStripPreviewData = () => ({
    type: FETCH_EVENT_STRIP_PREVIEW_DATA,
    payload: {},
});

export const fetchImportFieldStripData = (importGroomingRequest) => ({
    type: FETCH_IMPORT_FIELD_STRIP_DATA,
    payload: { importGroomingRequest },
});

export const fetchImportFilterListData = (importGroomingRequest) => ({
    type: FETCH_IMPORT_FILTER_LIST_DATA,
    payload: { importGroomingRequest },
});

export const fetchImportSamplingPoints = (importFileGuidList) => ({
    type: FETCH_IMPORT_SAMPLING_POINTS,
    payload: { importFileGuidList },
});

export const fetchUserCropImportPreferences = (cropGuid, yieldIaGuid) => ({
    type: FETCH_USER_CROP_IMPORT_PREFERENCES,
    payload: { cropGuid, yieldIaGuid },
});

export const rerunMatching = () => ({
    type: RERUN_MATCHING,
    payload: {},
});

export const resetImportWizard = () => ({
    type: RESET_IMPORT_WIZARD,
    payload: {},
});

export const setApiErrorResultList = (apiErrorResultList) => ({
    type: SET_API_ERROR_RESULT_LIST,
    payload: { apiErrorResultList },
});

export const setAppendList = (appendList) => ({
    type: SET_APPEND_LIST,
    payload: { appendList },
});

export const setDropdownData = (payload) => ({
    type: SET_DROPDOWN_DATA,
    payload,
});

export const setEventStripPreviewData = (eventStripPreviewData) => ({
    type: SET_EVENT_STRIP_PREVIEW_DATA,
    payload: { eventStripPreviewData },
});

export const setFieldMatchingComplete = (isFieldMatchingComplete) => ({
    type: SET_FIELD_MATCHING_COMPLETE,
    payload: { isFieldMatchingComplete },
});

export const setFieldMatchingLoading = (isFieldMatchingLoading) => ({
    type: SET_FIELD_MATCHING_LOADING,
    payload: { isFieldMatchingLoading },
});

export const setFilteredFieldImportFileList = (filteredFieldImportFileList) => ({
    type: SET_FILTERED_FIELD_IMPORT_FILE_LIST,
    payload: { filteredFieldImportFileList },
});

export const setForceUpdate = (forceUpdate) => ({
    type: SET_FORCE_UPDATE,
    payload: { forceUpdate },
});

export const setIgnoreFarm = (importFileGuidList, ignoreFarm) => ({
    type: SET_IGNORE_FARM,
    payload: { importFileGuidList, ignoreFarm },
});

export const setIgnoreSelectedField = (ignoreSelectedField) => ({
    type: SET_IGNORE_SELECTED_FIELD,
    payload: { ignoreSelectedField },
});

export const setImportConvexHulls = (convexHulls) => ({
    type: SET_IMPORT_CONVEX_HULLS,
    payload: { convexHulls },
});

export const setImportFieldBoundaries = (importFieldBoundaries) => ({
    type: SET_IMPORT_FIELD_BOUNDARIES,
    payload: { importFieldBoundaries },
});

export const setImportFieldList = (importFieldList) => ({
    type: SET_IMPORT_FIELD_LIST,
    payload: { importFieldList },
});

export const setImportFieldStripData = (importFieldStripData) => ({
    type: SET_IMPORT_FIELD_STRIP_DATA,
    payload: { importFieldStripData },
});

export const setImportFilterListData = (importFilterListData) => ({
    type: SET_IMPORT_FILTER_LIST_DATA,
    payload: { importFilterListData },
});

export const setImportGroomingRequest = (importGroomingRequest) => ({
    type: SET_IMPORT_GROOMING_REQUEST,
    payload: { importGroomingRequest },
});

export const setImportSamplingPoints = (importSamplingPoints) => ({
    type: SET_IMPORT_SAMPLING_POINTS,
    payload: { importSamplingPoints },
});

export const setImportWizardStep = (importWizardStep) => ({
    type: SET_IMPORT_WIZARD_STEP,
    payload: { importWizardStep },
});

export const setIsFilteringVisible = (isFilteringVisible) => ({
    type: SET_IS_FILTERING_VISIBLE,
    payload: { isFilteringVisible },
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: { isLoading },
});

export const setNameMatchesValidating = () => ({
    type: SET_NAME_MATCHES_VALIDATING,
    payload: {},
});

export const setSelectedImportFieldIndex = (selectedImportFieldIndex) => ({
    type: SET_SELECTED_IMPORT_FIELD_INDEX,
    payload: { selectedImportFieldIndex },
});

export const setSelectedMatchedFieldGuid = (selectedMatchedFieldGuid) => ({
    type: SET_SELECTED_MATCHED_FIELD_GUID,
    payload: { selectedMatchedFieldGuid },
});

export const setShowImportPoints = (showImportPoints) => ({
    type: SET_SHOW_IMPORT_POINTS,
    payload: { showImportPoints },
});

export const setShowProcessing = (showProcessing) => ({
    type: SET_SHOW_PROCESSING,
    payload: { showProcessing },
});

export const setUserCropImportPreferences = (userCropImportPreferences) => ({
    type: SET_USER_CROP_IMPORT_PREFERENCES,
    payload: { userCropImportPreferences },
});

export const setValidatingStatus = () => ({
    type: SET_VALIDATING_STATUS,
    payload: {},
});

export const setSuccessfulNameMatchImports = (successfulNameMatchImports) => ({
    type: SET_SUCCESSFUL_NAME_MATCH_IMPORTS,
    payload: { successfulNameMatchImports },
});

export const submitFieldBoundary = () => ({
    type: SUBMIT_FIELD_BOUNDARY,
    payload: {},
});

export const unmatchFieldFile = (field, file) => ({
    type: UNMATCH_FIELD_FILE,
    payload: { field, file },
});

export const updateEventId = (field, file, newEventId) => ({
    type: UPDATE_EVENT_ID,
    payload: { field, file, newEventId },
});

export const updateImageryAttribute = (request, callback) => ({
    type: UPDATE_IMAGERY_ATTRIBUTE,
    payload: { request, callback },
});

export const updateImportBoundary = (request, callback) => ({
    type: UPDATE_IMPORT_BOUNDARY,
    payload: { request, callback },
});

export const updateProposedEventStatus = (payload) => ({
    type: UPDATE_PROPOSED_EVENT_STATUS,
    payload,
});

export const updateUserCropImportPreferences = (
    cropGuid,
    yieldIaGuid,
    averageTestWeight,
    averageUnitWeight
) => ({
    type: UPDATE_USER_CROP_IMPORT_PREFERENCES,
    payload: { cropGuid, yieldIaGuid, averageTestWeight, averageUnitWeight },
});

export const validateImportEvent = () => ({
    type: VALIDATE_IMPORT_EVENT,
    payload: {},
});
