import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

const CloudIcon_ = (
    <g>
        <path d="M18 22h6.001A5.003 5.003 0 0029 17a4.997 4.997 0 00-3.117-4.634 5.503 5.503 0 00-7.789-3.813 7 7 0 00-13.082 3.859A5.007 5.007 0 002 17c0 2.761 2.232 5 4.999 5H13" />
    </g>
);

export const CloudIcon = withSvgIcon(CloudIcon_, "0 0 32 32", "cloud-icon");
