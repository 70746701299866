import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ZoneBufferIcon = withSvgIcon(
    <g>
        <path d="M20.177,1h1V0h-1.5a.5.5,0,0,0-.5.5V2h1Z" transform="translate(0 0)" />
        <rect x="22.873" width="3.393" height="1" />
        <path d="M29.462,0h-1.5V1h1V2h1V.5A.5.5,0,0,0,29.462,0Z" transform="translate(0 0)" />
        <rect x="28.962" y="3.696" width="1" height="3.393" />
        <path d="M28.962,9.785h-1v1h1.5a.5.5,0,0,0,.5-.5v-1.5h-1Z" transform="translate(0 0)" />
        <path
            d="M22.873,9.785h-2.7V3.7h-1v1.2H.5a.5.5,0,0,0-.5.5v24.07a.5.5,0,0,0,.5.5H24.569a.5.5,0,0,0,.5-.5V10.785h1.2v-1H22.873Zm1.2,19.177H8.626v-1h-1v1H1V22.336H2v-1H1V5.892H19.177v4.393a.5.5,0,0,0,.5.5h4.392Z"
            transform="translate(0 0)"
        />
        <rect x="3.157" y="21.336" width="2.313" height="1" />
        <path
            d="M8.126,21.336h-1.5v1h1v1h1v-1.5A.5.5,0,0,0,8.126,21.336Z"
            transform="translate(0 0)"
        />
        <rect x="7.626" y="24.492" width="1" height="2.313" />
        <path
            d="M26.426,4.893H25.069V3.536a.5.5,0,0,0-1,0V4.893H22.713a.5.5,0,0,0,0,1h1.356V7.249a.5.5,0,0,0,1,0V5.893h1.357a.5.5,0,0,0,0-1Z"
            transform="translate(0 0)"
        />
        <path
            d="M6.169,25.149H2.457a.5.5,0,0,0,0,1H6.169a.5.5,0,1,0,0-1Z"
            transform="translate(0 0)"
        />
    </g>,
    "0 0 30 30",
    "disabled-stroke"
);
