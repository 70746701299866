import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "~/admin/agBytes/i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    prepareSelectableOptions,
    onNumberChange,
    onTextChange,
} from "~/admin/utils";
// constants
import * as picklistService from "~/core/picklist/picklist-names";
// Components
import { Checkbox, NumericInput, Section, SelectInput, SubSection, TextInput } from "~/core";
import { adminData, GUID, ID, PICKLIST_GUID, VALUE } from "~/admin/data";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        record: CustomPropTypes.equationParameter,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    static DATATYPE_DECIMAL_ID = "3";
    static DATATYPE_INTEGER_ID = "1";

    constructor(props) {
        super(props);
        this.equationParameter = {};
        this.state = {
            dataType: "",
            [picklistService.PICKLIST_DATA_TYPE]: [],
        };
    }

    componentDidMount() {
        this.setupEquationParameterRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([this.props.fetchPicklistData()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.equationParameter);
        }

        if (nextProps.record && nextProps.record !== this.props.record) {
            this.equationParameter = nextProps.record;
        }

        this.initializeDataType(nextProps);
    }

    setupEquationParameterRecord = () => {
        const { addEditPanel } = this.props;
        this.equationParameter = {};
        if (addEditPanel.mode === "ADD") {
            this.equationParameter = service.getDefaultRecord();
        }
    };

    initializeDataType = (nextProps) => {
        if (
            this.equationParameter[model.PROPS_EQUATION_PARAMETER_DATA_TYPE_GUID] != null &&
            nextProps[picklistService.PICKLIST_DATA_TYPE]
        ) {
            this.setState(
                {
                    [picklistService.PICKLIST_DATA_TYPE]: prepareSelectableOptions(
                        nextProps[picklistService.PICKLIST_DATA_TYPE],
                        { guid: PICKLIST_GUID, label: VALUE, id: ID },
                        this.equationParameter[model.PROPS_EQUATION_PARAMETER_DATA_TYPE_GUID]
                    ),
                },
                () => {
                    this.setDataTypeState(this.state[[picklistService.PICKLIST_DATA_TYPE]]);
                }
            );
        }
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.equationParameter = handlePicklistChange(
            this.equationParameter,
            { type, guid, value },
            callback
        );
    };

    onDataTypeChange = ({ type, guid }, options) => {
        this.setDataTypeState(options);
        this.onPicklistChange({ type, guid }, options);
    };
    getSelectedDataType() {
        return this.state[picklistService.PICKLIST_DATA_TYPE].find((option) => {
            return (
                this.equationParameter[model.PROPS_EQUATION_PARAMETER_DATA_TYPE_GUID] ===
                option[VALUE][GUID]
            );
        });
    }

    setDataTypeState = () => {
        let status;
        const option = this.getSelectedDataType();
        if (option && option.value && option.value.id) {
            status =
                option.value.id === AddEditPanel.DATATYPE_DECIMAL_ID
                    ? AddEditPanel.DATATYPE_DECIMAL_ID
                    : option.value.id === AddEditPanel.DATATYPE_INTEGER_ID
                    ? AddEditPanel.DATATYPE_INTEGER_ID
                    : "";
        }
        this.setState({
            dataTypeStatus: status,
        });
    };

    onNumberChange = (formKey, value, callback) => {
        this.equationParameter = onNumberChange(
            this.equationParameter,
            { formKey: [formKey], value },
            callback
        );
    };

    onTextChange = (formKey, value, callback) => {
        this.equationParameter = onTextChange(
            this.equationParameter,
            { formKey: [formKey], value },
            callback
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.equationParameter[model.PROPS_EQUATION_PARAMETER_NAME]]);
    };

    renderEquationParameterInfo = () => {
        const { formatMessage } = this.props.intl;
        const { equationParameter } = this;
        const { dataTypeStatus } = this.state;
        return (
            <div className="section-container form-sub-section-stretch">
                <Section>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            autoFocus
                            containerClassNames={getAgBytesErrorClassNames(
                                [531, 532],
                                this.props.apiErrors
                            )}
                            placeholderText={formatMessage(messages.equationParameterName)}
                            labelText={formatMessage(messages.equationParameterName)}
                            value={equationParameter[model.PROPS_EQUATION_PARAMETER_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_EQUATION_PARAMETER_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            required
                        />
                        <TextInput
                            maxLength={50}
                            tabIndex={0}
                            placeholderText={formatMessage(messages.equationParameterLabel)}
                            labelText={formatMessage(messages.equationParameterLabel)}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_EQUATION_PARAMETER_LABEL, value)
                            }
                            value={equationParameter[model.PROPS_EQUATION_PARAMETER_LABEL]}
                        />
                    </SubSection>
                    {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                        <SubSection>
                            <Checkbox
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={equationParameter[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        </SubSection>
                    )}
                </Section>
                <Section>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            required
                            containerClassNames={getAgBytesErrorClassNames(
                                533,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_DATA_TYPE]}
                            placeholderText={formatMessage(messages.equationParameterDataType)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_DATA_TYPE],
                                selectedGuid:
                                    equationParameter[
                                        model.PROPS_EQUATION_PARAMETER_DATA_TYPE_GUID
                                    ],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_EQUATION_PARAMETER_DATA_TYPE,
                                        guid: model.PROPS_EQUATION_PARAMETER_DATA_TYPE_GUID,
                                    },
                                    value,
                                    this.setDataTypeState
                                );
                            }}
                        />
                        {![
                            AddEditPanel.DATATYPE_INTEGER_ID,
                            AddEditPanel.DATATYPE_DECIMAL_ID,
                        ].includes(dataTypeStatus) ? null : (
                            <Checkbox
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ALLOW_NEGATIVES, value)
                                }
                                value={equationParameter[model.PROPS_ALLOW_NEGATIVES]}
                                label={formatMessage(messages.allowNegatives)}
                            />
                        )}
                    </SubSection>
                </Section>
                <Section>
                    {dataTypeStatus !== AddEditPanel.DATATYPE_DECIMAL_ID ? null : (
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                scale={0}
                                precision={9}
                                placeholderText={formatMessage(messages.decimalPlaces)}
                                labelText={formatMessage(messages.decimalPlaces)}
                                value={equationParameter[model.PROPS_DECIMAL_PLACES]}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_DECIMAL_PLACES, value)
                                }
                                required
                            />
                            <NumericInput
                                tabIndex={0}
                                scale={0}
                                precision={9}
                                placeholderText={formatMessage(messages.precision)}
                                labelText={formatMessage(messages.precision)}
                                value={equationParameter[model.PROPS_PRECISION]}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_PRECISION, value)
                                }
                                required
                            />
                        </SubSection>
                    )}
                </Section>
            </div>
        );
    };

    render() {
        return <div className="add-edit-panel">{this.renderEquationParameterInfo()}</div>;
    }
}

export default injectIntl(AddEditPanel);
