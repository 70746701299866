import { takeEvery, put, select } from "redux-saga/effects";
import { fetchState, fetchStateFailed, fetchStateSuccess } from "./actions";
import { AddressAPI } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
// selectors
import { getTheUserGuid } from "~/login/selectors";

export function* fetchStateRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield AddressAPI.getStateList(requestOptions);
            if (response) {
                yield put(fetchStateSuccess({ guid: payload, response }));
            } else {
                yield put(fetchStateFailed("No Data"));
            }
        } catch (error) {
            yield put(fetchStateFailed({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(fetchStateFailed("No Data"));
        console.error("No Data");
    }
}

const AddressSagas = function* () {
    yield takeEvery(fetchState, fetchStateRequest);
};

export default AddressSagas;
