import PropTypes from "prop-types";
import * as model from "~/admin/setup/equation-sets/application/data/model";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_DESCRIPTION]: PropTypes.string,
    [model.PROPS_EQUATION_GROUP_GUID]: PropTypes.string,
    [model.PROPS_GROUP_NAME]: PropTypes.string,
    [model.PROPS_SAMPLING_EVENT_REQUIRED]: PropTypes.bool,
    [model.PROPS_VENDOR_GUID]: PropTypes.string,
    [model.PROPS_VENDOR_NAME]: PropTypes.string,
};

export default PropTypes.shape(shape);
