import React from "react";
import { connect } from "react-redux";

import { eventsSelectors } from "~/recs-events";

import { RecEventItem } from "../../../../../common/accordion/rec-event-accordion-item";
import { getEventStatusMessageId } from "../../../../../common/status-messages";
import { getClassNameForStatusFromEvent } from "../../../../../common/status-codes";

import * as selectors from "../../selectors";
import * as actions from "../../actions";
import { RecEventListTabs } from "~/recs-events/actions";

import { ContextMenu } from "./event-accordion-item-context-menu";

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deselectItemsFromDimIdx: (startDimIdx, endDimIdx) => {
            dispatch(actions.deselectEventsFromAccordion(startDimIdx, endDimIdx));
        },
        selectItemsFromDimIdx: (startDimIdx, endDimIdx) => {
            dispatch(actions.selectEventsFromAccordion(startDimIdx, endDimIdx));
        },
        setLastClickedDimIdx: () => {
            dispatch(actions.setLastClickedDimIdx(ownProps.itemDimIdx));
        },
    };
};

const mapStateToProps = (state, ownProps) => {
    const moduleState = selectors.getModuleState(state);
    const eventTypeInfoList = eventsSelectors.getEventTypeInfoList(state);
    const isInactiveTab = moduleState.filter.activeTab === RecEventListTabs.INACTIVE;
    const eventGuidToEventSummaryMap = isInactiveTab
        ? eventsSelectors.getEventGeneralGuidToInactiveEventSummaryMap(state)
        : eventsSelectors.getEventGeneralGuidToEventSummaryMap(state);

    let lastClickedDimIdx;
    switch (moduleState.filter.activeTab) {
        case RecEventListTabs.ACTIVE:
            lastClickedDimIdx = moduleState.allAccordionInit.lastClickedDimIdx;
            break;
        case RecEventListTabs.INACTIVE:
            lastClickedDimIdx = moduleState.inactiveAccordionInit.lastClickedDimIdx;
            break;
        case RecEventListTabs.SELECTED:
            lastClickedDimIdx = moduleState.selectedAccordionInit.lastClickedDimIdx;
            break;
        default:
            throw new Error("unreachable: unknown selected tab");
    }

    const eventSummary = eventGuidToEventSummaryMap.get(ownProps.objGuid);
    const eventTypeInfo = eventSummary
        ? eventTypeInfoList.find((typeInfo) => {
              return (
                  typeInfo.agEventTransactionTypeGuid === eventSummary.agEventTransactionTypeGuid &&
                  typeInfo.agEventTransactionTypeName === eventSummary.agEventTypeName
              );
          })
        : undefined;

    const displayName = eventSummary ? eventSummary.agEventDisplayName : undefined;
    const modifiedUserDetails = eventSummary
        ? `${eventSummary.modifiedBy} - ${eventSummary.modifiedDate.toLowerCase()}`
        : undefined;
    const summary = eventSummary
        ? [eventSummary.croppingSeasonName, eventSummary.cropDisplayName, eventSummary.gridArea]
              .filter((v) => v && v !== "")
              .join(" - ")
        : undefined;
    const statusCodeClassName = eventSummary
        ? getClassNameForStatusFromEvent(eventSummary, eventTypeInfo)
        : undefined;
    const statusMessageArg = eventSummary
        ? { ...eventSummary, statusCode: eventSummary.importedStatus }
        : undefined;
    const statusMessageId = eventSummary
        ? getEventStatusMessageId(eventSummary, eventTypeInfo)
        : undefined;
    const isSelected = eventSummary
        ? selectors.getSelectedEventGuidSet(state).has(ownProps.objGuid)
        : undefined;

    const contextMenu = eventSummary ? (
        <ContextMenu
            itemDimIdx={ownProps.itemDimIdx}
            objGuid={ownProps.objGuid}
            activeTab={moduleState.filter.activeTab}
        />
    ) : null;

    const props = {
        contextMenu,
        displayName,
        lastClickedDimIdx,
        isSelected,
        modifiedUserDetails,
        summary,
        statusCodeClassName,
        statusMessageArg,
        statusMessageId,
        isInactiveTab,
    };

    if (statusMessageId) {
        props.statusMessageArg = statusMessageArg;
        props.statusMessageId = statusMessageId;
    } else {
        props.status = "";
    }

    return props;
};

export const EventItem = connect(mapStateToProps, mapDispatchToProps)(RecEventItem);
