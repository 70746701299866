import React from "react";
import { Bucket, BucketHeader } from "~/core";
import { useAppSelector } from "~/store/hooks";
import Reports from "~/reports/reports";

import { getReportTypes } from "../data/selectors";
import { IReportOptionsProps, ReportOptions } from "./report-options-redux";
import ReportOptionsWrapper from "../reports/report-options-wrapper";

const ReportOptionsAccordionItem = (props: IReportOptionsProps) => {
    const reportTypes = useAppSelector(getReportTypes);

    if (props.selectedReportTypes.length === 0 || props.selectedReportTypes.length > 1) {
        throw new Error("Invalid number of selected report types");
    }

    const reportTypeName = props.selectedReportTypes[0];
    const reportType = reportTypes.find((type) => type.reportTypeName === reportTypeName);
    const CustomOptionsComponent = Reports[reportTypeName];
    return (
        <Bucket isExpanded showSymbol={true} isCollapsible={true}>
            <BucketHeader>{reportType.reportTypeDisplayName}</BucketHeader>
            {!CustomOptionsComponent ? (
                <div>
                    <ReportOptions {...props} />
                </div>
            ) : (
                <ReportOptionsWrapper
                    ReportOptions={CustomOptionsComponent}
                    hideDefaultOptions={props.hideDefaultOptions}
                />
            )}
        </Bucket>
    );
};

export default ReportOptionsAccordionItem;
