import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    SelectInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    NumericInput,
    ZeroToInfiniteGrid,
    TextInput,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import { messages } from "../../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";

const APPLICATION_NOZZLE_TYPE_GUID = "applicationNozzleTypeGuid";
const NOZZLE_SIZE = "nozzleSize";
const ORIFICE_DIAMETER = "orificeDiameter";
const ORIFICE_DIAMETER_UNIT_GUID = "orificeDiameterUnitGuid";
const SPRAY_WIDTH = "sprayWidth";
const SPRAY_WIDTH_UNIT_GUID = "sprayWidthUnitGuid";
const SPRAY_WIDTH_UNIT_NAME = "sprayWidthUnitName";
const APPLICATION_NOZZLE_TYPE_NAME = "nozzleName";
const ORIFICE_DIAMETER_UNIT_NAME = "orificeDiameterUnitName";

class NozzleList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        needs: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchDropdownData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
            [picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [unitService.UNIT_LENGTH]: prepareSelectableOptions(props[unitService.UNIT_LENGTH], {
                guid: GUID,
                label: NAME,
            }),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record && record.length > 0) {
            if (
                nextProps[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE] &&
                nextProps[unitService.UNIT_LENGTH]
            ) {
                this.initializeNozzleList(nextProps);
            }
        }

        this.initializeNozzleTypes(nextProps);
        this.initializeLengthUnit(nextProps);
    }

    initializeNozzleList = (nextProps) => {
        const { record } = nextProps;
        if (record) {
            const nozzleOptions = nextProps[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE];

            return record.map((recordItem) => {
                const selectedNozzle = nozzleOptions.find(
                    (nozzle) =>
                        nozzle.picklistValueGuid === recordItem[APPLICATION_NOZZLE_TYPE_GUID]
                );
                recordItem[APPLICATION_NOZZLE_TYPE_NAME] = selectedNozzle && selectedNozzle.value;
                return recordItem;
            });
        }
        return record;
    };

    initializeNozzleTypes = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE] !==
            this.props[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    initializeLengthUnit = (nextProps) => {
        if (nextProps[unitService.UNIT_LENGTH] !== this.props[unitService.UNIT_LENGTH]) {
            this.setState({
                [unitService.UNIT_LENGTH]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_LENGTH],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    onEdit = (record, index) => {
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [APPLICATION_NOZZLE_TYPE_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [NOZZLE_SIZE],
            },
            {
                name: [ORIFICE_DIAMETER],
            },
            {
                name: [ORIFICE_DIAMETER_UNIT_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [SPRAY_WIDTH_UNIT_GUID],
                key: GUID,
            },
            {
                name: [SPRAY_WIDTH],
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                className="nozzle-dialog"
                action="save"
                actionDisabled={!editData[APPLICATION_NOZZLE_TYPE_GUID]}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.nozzle,
                    { count: 1 }
                )}
            >
                <div className="accessories-group">
                    <SelectInput
                        clearable={false}
                        autoFocus
                        openOnFocus={false}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE]}
                        value={mapToPicklistValue({
                            options: this.state[picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE],
                            selectedGuid: editData[APPLICATION_NOZZLE_TYPE_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [APPLICATION_NOZZLE_TYPE_GUID]: value.guid,
                                [APPLICATION_NOZZLE_TYPE_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.nozzleType)}
                        required
                    />
                    <TextInput
                        maxLength={50}
                        placeholderText={formatMessage(messages.nozzleSize)}
                        value={editData[NOZZLE_SIZE]}
                        onChange={(value) => {
                            onChange({
                                [NOZZLE_SIZE]: value,
                            });
                        }}
                    />
                </div>
                <div className="accessories-group">
                    <NumericInput
                        tabIndex={0}
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.sprayWidth)}
                        labelText={formatMessage(messages.sprayWidth)}
                        value={editData[SPRAY_WIDTH] || ""}
                        onChange={(value) => onChange({ [SPRAY_WIDTH]: value })}
                    />
                    <SelectInput
                        clearable={false}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[unitService.UNIT_LENGTH]}
                        value={mapToPicklistValue({
                            options: this.state[unitService.UNIT_LENGTH],
                            selectedGuid: editData[ORIFICE_DIAMETER_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [ORIFICE_DIAMETER_UNIT_GUID]: value.guid,
                                [ORIFICE_DIAMETER_UNIT_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.sprayWidthUnit)}
                    />
                </div>
                <div className="accessories-group">
                    <NumericInput
                        tabIndex={0}
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.orificeDiameter)}
                        labelText={formatMessage(messages.orificeDiameter)}
                        value={editData[ORIFICE_DIAMETER] || ""}
                        onChange={(value) => onChange({ [ORIFICE_DIAMETER]: value })}
                    />
                    <SelectInput
                        clearable={false}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[unitService.UNIT_LENGTH]}
                        value={mapToPicklistValue({
                            options: this.state[unitService.UNIT_LENGTH],
                            selectedGuid: editData[SPRAY_WIDTH_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [SPRAY_WIDTH_UNIT_GUID]: value.guid,
                                [SPRAY_WIDTH_UNIT_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.orificeDiameterUnit)}
                    />
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [APPLICATION_NOZZLE_TYPE_NAME]: {
                                title: formatMessage(messages.type),
                            },
                            [NOZZLE_SIZE]: {
                                title: formatMessage(messages.nozzleSize),
                            },
                            [SPRAY_WIDTH]: {
                                title: formatMessage(messages.sprayWidth),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.nozzle, { count: 1 })}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(NozzleList);
