import { ACTION_PANEL_STATE_KEY } from "../../../../../selectors";

import { COMMON_STATE_KEY } from "../../..";
import { VARIETY_HYBRID_DATA_KEY } from "./reducer";

const _getModuleState = (state) =>
    state[ACTION_PANEL_STATE_KEY][COMMON_STATE_KEY][VARIETY_HYBRID_DATA_KEY];

export const getBrandVarietyHybridList = (state: Record<string, any>): any =>
    _getModuleState(state).brandVarietyHybridList;
