import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { TextInput, Bucket, BucketHeader, SelectInput, Checkbox } from "~/core";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import { messages } from "../i18n-messages";

import { AnalysisIcon } from "./icons/analysis";
import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import "./analysis-info.css";
import { eventListSelectors } from "../../../event-module";
import { eventsSelectors } from "~/recs-events";
import { IAnalysisNormaliedYieldSummary } from "~/recs-events/analysis/model";
import { AgEventSummary } from "~/recs-events/events/model";
import { LayerAPI, PicklistAPI } from "@ai360/core";

export const formLabelMessage = messages.analysisFormSideBarText;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText], // ErrorCode.AnalysisLayerNameIsRequired
    [2800, messages.analysisMethodNoYieldLayers], // ErrorCode.AnalysisNoYieldDataExists
    [2806, messages.analysisDuplicateName],
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

class AnalysisLayerFormProps {
    analysisSummary: IAnalysisNormaliedYieldSummary;
    intl: intlShape;
    normalizedEventYieldOptions: LayerAPI.INormalizedYieldSeason[];
    pickListNumClasses: PicklistAPI.IPicklistItem[];
    analysisDetailsErrorCodeList: number[];
    onUpdateCurrentAnalysisSummary: (props: Partial<IAnalysisNormaliedYieldSummary>) => void;
    isBatchNormalizedYield: boolean;
    selectedEventGuidSet: Set<string>;
    selectedEventSummary: Map<string, AgEventSummary>;
}

const AnalysisNormalizedYieldForm = (props: AnalysisLayerFormProps): JSX.Element => {
    const {
        analysisSummary,
        normalizedEventYieldOptions,
        pickListNumClasses,
        analysisDetailsErrorCodeList,
        onUpdateCurrentAnalysisSummary,
        isBatchNormalizedYield,
        selectedEventGuidSet,
        selectedEventSummary,
    } = props;
    const { formatMessage } = props.intl;

    //state variables
    const [errorMessagePlaceholderSet, setErrorMessagePlaceholderSet] = useState<Set<any>>(
        new Set()
    );

    useEffect(() => {
        if (!analysisDetailsErrorCodeList) {
            return;
        }

        const newErrorMessagePlaceholderSet = _getErrorMessagePlaceholderSet();
        setErrorMessagePlaceholderSet(newErrorMessagePlaceholderSet);
    }, [analysisDetailsErrorCodeList]);

    const _getAnalysisErrors = (errorMessagePlaceholderSet, formatMessage) => {
        if (errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0) {
            return null;
        }
        const errorMessages = [];
        errorMessagePlaceholderSet.forEach((errorEntry) => {
            errorMessages.push(
                <div className="analysis-error-message">{formatMessage(errorEntry)}</div>
            );
        });
        return errorMessages;
    };

    const _getErrorMessagePlaceholderSet = () => {
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    };

    const _updateAnalysisToggleEvent = (guid, val) => {
        const { agEventGeneralGuidList } = analysisSummary;
        const list = agEventGeneralGuidList.filter(function (item) {
            return item !== guid;
        });
        if (val) {
            list.push(guid);
        }
        _updateAnalysisSummary({ agEventGeneralGuidList: list });
    };

    const _updateAnalysisSummary = (newProps) => {
        onUpdateCurrentAnalysisSummary(newProps);
    };

    const _getYieldOptionLabel = (event) => {
        if (event.calculatedArea) {
            return `${event.displayName} - ${formatMessage(messages.analysisLayerCalculatedArea, {
                calculatedArea: event.calculatedArea,
            })}`;
        }
        return `${event.displayName}`;
    };

    const _removeCottonDuplicates = (events) => {
        return events.reduce((acc, evt) => {
            const duplicateEvtIdx = acc.findIndex(
                (a) => a.agEventGeneralGuid === evt.agEventGeneralGuid
            );
            if (events[duplicateEvtIdx]) {
                if (evt.displayName.indexOf("Cotton") > -1 && evt.attributeName === "Lint Yield") {
                    acc.splice(duplicateEvtIdx, 1, evt);
                }
            } else {
                acc.push(evt);
            }
            return acc;
        }, []);
    };

    const _renderPostLabel = (event) => {
        return (
            <div className="analysis-yield-label-cont">
                <div className="checkbox-label-text-span">{_getYieldOptionLabel(event)}</div>
                <div className="analysis-yield-label">
                    {event.attributeName} - {event.avgYield} {event.yieldUnit}
                </div>
            </div>
        );
    };

    const _checkHarvestEvent = (event) => {
        return event.agEventTypeName === "Harvest" && event.isImportedYn;
    };

    const _getSelectedSeasons = () => {
        const seasons = [];

        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = selectedEventSummary.get(eventGeneralGuid);
            if (_checkHarvestEvent(event)) {
                seasons.push(event?.croppingSeasonNum);
            }
        }
        return seasons;
    };

    const _renderBatchNormalizedYield = () => {
        const selectedSeasons = _getSelectedSeasons();
        return (
            <div>
                <div className="event-count">
                    {formatMessage(messages.harvestEvents)} {selectedSeasons.length}
                </div>
                <div className="seasons">
                    {formatMessage(messages.seasons)}{" "}
                    {Array.from([...new Set(selectedSeasons)]).join(",")}
                </div>
            </div>
        );
    };

    const agEventGeneralGuidList = isBatchNormalizedYield
        ? Array.from(selectedEventGuidSet)
        : analysisSummary.agEventGeneralGuidList;
    let eventCheckboxes;
    if (!isBatchNormalizedYield) {
        eventCheckboxes = _removeCottonDuplicates(normalizedEventYieldOptions).map((event, key) => {
            const checked = agEventGeneralGuidList.some(function (guid) {
                return guid === event.agEventGeneralGuid;
            });
            return (
                <Checkbox
                    className="analysis-input"
                    key={key}
                    value={checked}
                    onChange={(evt, val) =>
                        _updateAnalysisToggleEvent(event.agEventGeneralGuid, val)
                    }
                    renderLabel={() => _renderPostLabel(event)}
                />
            );
        });
    }

    const analysisErrors = _getAnalysisErrors(errorMessagePlaceholderSet, formatMessage);

    return (
        <div className="rec-event-info-form">
            {analysisErrors}
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.analysisFormLabelText)}</BucketHeader>
                <TextInput
                    containerClassNames={[
                        "analysis-form-input",
                        {
                            "form-input-error": errorMessagePlaceholderSet.has(
                                messages.analysisMethodPlaceholderText
                            ),
                        },
                    ]}
                    onChange={(v) => _updateAnalysisSummary({ name: v })}
                    placeholderText={formatMessage(messages.analysisNamePlaceholderText)}
                    required={true}
                    value={analysisSummary.name}
                    maxLength={50}
                />
            </Bucket>
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>
                    {formatMessage(messages.analysisSymbologyInfoPlaceholderText)}
                </BucketHeader>
                <SelectInput
                    containerClassNames={["analysis-form-input"]}
                    clearable={false}
                    onChange={(v) => _updateAnalysisSummary({ numClasses: v })}
                    options={pickListNumClasses}
                    placeholderText={formatMessage(messages.analysisNumClassesPlaceholderText)}
                    required={true}
                    value={analysisSummary.numClasses}
                />
            </Bucket>
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader className="analysis-info-bucket-header">
                    {formatMessage(messages.analysisEventsPlaceholderText)}
                </BucketHeader>
                {!isBatchNormalizedYield ? (
                    eventCheckboxes
                ) : (
                    <div>{_renderBatchNormalizedYield()}</div>
                )}
            </Bucket>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onUpdateCurrentAnalysisSummary: (newProps) =>
        dispatch(actions.updateNormalizedYieldAnalysisSummary(newProps)),
});

const mapStateToProps = (state) => {
    const normalizedEventYieldOptions = selectors.getNormalizedYieldOptions(state);
    const isBatchNormalizedYield = selectors.getBatchNormalizedYield(state);
    const selectedEventGuidSet = eventListSelectors.getSelectedEventGuidSet(state);
    const selectedEventSummary = eventsSelectors.getEventGeneralGuidToEventSummaryMap(state);
    return {
        normalizedEventYieldOptions,
        isBatchNormalizedYield,
        selectedEventSummary,
        selectedEventGuidSet,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(injectIntl(AnalysisNormalizedYieldForm));
