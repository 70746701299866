import React, { Component } from "react";
import classnames from "classnames";
import _ from "lodash";

import "loaders.css/loaders.min.css";
import "./loader.css";

// examples of each `LoaderTypes` can be seen here: https://connoratherton.com/loaders
export enum LoaderTypes {
    BALL_BEAT = "ball-beat",
    BALL_CLIP_ROTATE = "ball-clip-rotate",
    BALL_CLIP_ROTATE_MULTIPLE = "ball-clip-rotate-multiple",
    BALL_CLIP_ROTATE_PULSE = "ball-clip-rotate-pulse",
    BALL_GRID_BEAT = "ball-grid-beat",
    BALL_GRID_PULSE = "ball-grid-pulse",
    BALL_PULSE = "ball-pulse",
    BALL_PULSE_RISE = "ball-pulse-rise",
    BALL_PULSE_SYNC = "ball-pulse-sync",
    BALL_ROTATE = "ball-rotate",
    BALL_SCALE = "ball-scale",
    BALL_SCALE_MULTIPLE = "ball-scale-multiple",
    BALL_SCALE_RIPPLE = "ball-scale-ripple",
    BALL_SCALE_RIPPLE_MULTIPLE = "ball-scale-ripple-multiple",
    BALL_SPIN_FADE_LOADER = "ball-spin-fade-loader",
    BALL_TRIANGLE_PATH = "ball-triangle-path",
    BALL_ZIG_ZAG = "ball-zig-zag",
    BALL_ZIG_ZAG_DEFLECT = "ball-zig-zag-deflect",
    CUBE_TRANSITION = "cube-transition",
    LINE_SCALE = "line-scale",
    LINE_SCALE_PARTY = "line-scale-party",
    LINE_SCALE_PULSE_OUT = "line-scale-pulse-out",
    LINE_SCALE_PULSE_OUT_RAPID = "line-scale-pulse-out-rapid",
    LINE_SPIN_FADE_LOADER = "line-spin-fade-loader",
    PACMAN = "pacman",
    SEMI_CIRCLE_SPIN = "semi-circle-spin",
    SQUARE_SPIN = "square-spin",
    TRIANGLE_SKEW_SPIN = "triangle-skew-spin",
}

interface ILoaderProps {
    className?: string;
    type?: LoaderTypes;
}

export class Loader extends Component<ILoaderProps> {
    public static defaultProps = {
        type: LoaderTypes.LINE_SCALE_PULSE_OUT,
    };

    render(): JSX.Element {
        let divCount = 0;
        switch (this.props.type) {
            case LoaderTypes.BALL_CLIP_ROTATE:
            case LoaderTypes.BALL_CLIP_ROTATE_PULSE:
            case LoaderTypes.BALL_ROTATE:
            case LoaderTypes.BALL_SCALE:
            case LoaderTypes.BALL_SCALE_RIPPLE:
            case LoaderTypes.SEMI_CIRCLE_SPIN:
            case LoaderTypes.SQUARE_SPIN:
            case LoaderTypes.TRIANGLE_SKEW_SPIN:
                divCount = 1;
                break;
            case LoaderTypes.BALL_CLIP_ROTATE_MULTIPLE:
            case LoaderTypes.BALL_ZIG_ZAG:
            case LoaderTypes.BALL_ZIG_ZAG_DEFLECT:
            case LoaderTypes.CUBE_TRANSITION:
                divCount = 2;
                break;
            case LoaderTypes.BALL_BEAT:
            case LoaderTypes.BALL_PULSE:
            case LoaderTypes.BALL_PULSE_SYNC:
            case LoaderTypes.BALL_SCALE_MULTIPLE:
            case LoaderTypes.BALL_SCALE_RIPPLE_MULTIPLE:
            case LoaderTypes.BALL_TRIANGLE_PATH:
                divCount = 3;
                break;
            case LoaderTypes.LINE_SCALE_PARTY:
                divCount = 4;
                break;
            case LoaderTypes.BALL_PULSE_RISE:
            case LoaderTypes.LINE_SCALE:
            case LoaderTypes.LINE_SCALE_PULSE_OUT:
            case LoaderTypes.LINE_SCALE_PULSE_OUT_RAPID:
            case LoaderTypes.PACMAN:
                divCount = 5;
                break;
            case LoaderTypes.BALL_SPIN_FADE_LOADER:
            case LoaderTypes.LINE_SPIN_FADE_LOADER:
                divCount = 8;
                break;
            case LoaderTypes.BALL_GRID_BEAT:
            case LoaderTypes.BALL_GRID_PULSE:
                divCount = 9;
                break;
            default:
                divCount = 5;
        }
        return (
            <div className={classnames("loader-container", this.props.className)}>
                <div>
                    <div className={this.props.type}>
                        {_.times(divCount, (i) => (
                            <div key={i} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
