import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";

import { Menu } from "~/core";
import { selectors as fileImportSelectors } from "~/file-import";
import { messages as globalMessages } from "~/i18n-messages";

import { DeleteConfDialog } from "../../../../context-menus/item-delete-confirmation-dialog";

import * as actions from "../../../actions";
import * as selectors from "../../../selectors";
import { messages } from "../../i18n-messages";

import {
    ErrorDetailsDialog,
    ErrorDetailsContextMenuLabel,
} from "~/action-panel/components/common/error-details-dialog/error-details-dialog";

class ContextMenu_ extends Component {
    static propTypes = {
        canDeleteStatusCodes: PropTypes.array,
        getImportTemplate: PropTypes.func,
        importFileInfo: PropTypes.object.isRequired,
        intl: intlShape.isRequired,
        onDeleteFile: PropTypes.func.isRequired,
        onImportFile: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isContextMenuExpanded: false,
            isDeleteConfirmationOpen: false,
            isErrorDetailDialogOpen: false,
        };
    }

    _onClick(event) {
        // prevent expand/collapse of accordion item
        event.preventDefault();
    }

    _getMenuItems() {
        const { formatMessage } = this.props.intl;

        const { canDeleteStatusCodes, getImportTemplate, importFileInfo, onImportFile } =
            this.props;

        const menuItems = [
            {
                key: 0,
                label: formatMessage(messages.importFile),
                action: onImportFile,
                disabled: !importFileInfo.isReadyForImport(),
            },
            {
                key: 1,
                label: formatMessage(messages.deleteFile),
                action: () => this.setState({ isDeleteConfirmationOpen: true }),
                disabled: !canDeleteStatusCodes.includes(importFileInfo.statusCode),
            },
        ];

        const importTemplate = getImportTemplate(importFileInfo.templateGuid);
        if (importTemplate && importTemplate.name === "Monitor Data (*.zip)") {
            menuItems.splice(0, 1);
        }

        if (importFileInfo.hasError()) {
            menuItems.push({
                key: 2,
                label: formatMessage(ErrorDetailsContextMenuLabel),
                action: () => this.setState({ isErrorDetailDialogOpen: true }),
            });
        }

        return menuItems;
    }

    render() {
        const { formatMessage } = this.props.intl;
        const deleteConfDialog = !this.state.isDeleteConfirmationOpen
            ? null
            : DeleteConfDialog({
                  formatMessage,
                  deleteConfirmationTitle: globalMessages.confirmTitle,
                  deleteConfirmationMessage: messages.deleteFileConfirmation,
                  onConfirmationClick: (confirmation) => {
                      const afterClose = !confirmation ? () => null : this.props.onDeleteFile;
                      this.setState({ isDeleteConfirmationOpen: false }, afterClose);
                  },
              });
        const errorDetailDialog = !this.state.isErrorDetailDialogOpen ? null : (
            <ErrorDetailsDialog
                onClose={() => this.setState({ isErrorDetailDialogOpen: false })}
                correlationId={this.props.importFileInfo.guid}
                logType="import"
            />
        );

        const contextMenuClassNames = classNames("context-menu", {
            expanded: this.state.isContextMenuExpanded,
        });
        return (
            <div className="context-menu-container" onClick={(event) => this._onClick(event)}>
                <Menu
                    className={contextMenuClassNames}
                    isDotMenu={true}
                    getMenuItems={() => this._getMenuItems()}
                    onExpand={() => this.setState({ isContextMenuExpanded: true })}
                    onCollapse={() => this.setState({ isContextMenuExpanded: false })}
                />
                {deleteConfDialog}
                {errorDetailDialog}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadImportWizard: (importFileGuid, importType, importTemplate) =>
        dispatch(actions.loadImportWizard([importFileGuid], importType, importTemplate)),
    // FIXME: disable delete for files with statusCode = 1
    deleteItem: (itemDimIdx) => dispatch(actions.deleteImportFileInfoItem(itemDimIdx)),
});

const mapStateToProps = (state) => ({
    canDeleteStatusCodes: selectors.getModuleState(state).canDeleteStatusCodes,
    getImportTemplate: (importTemplateGuid) =>
        fileImportSelectors.getImportTemplate(state, importTemplateGuid),
    getImportType: (importTypeGuid) => fileImportSelectors.getImportType(state, importTypeGuid),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onDeleteFile: () => dispatchProps.deleteItem(ownProps.itemDimIdx),
    onImportFile: () => {
        const { getImportTemplate, getImportType } = stateProps;

        const { importFileInfo } = ownProps;
        const importTemplate = getImportTemplate(importFileInfo.templateGuid);
        dispatchProps.loadImportWizard(
            importFileInfo.guid,
            getImportType(importTemplate.importTypeGuid),
            importTemplate
        );
    },
});

export const ContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(ContextMenu_));
