import React from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { ReportAPI } from "@ai360/core";

import { DateInput, NoLink, Section, SubSection } from "~/core";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import { messages } from "~/reports/i18n-messages";
import { model } from "~/reports/data";
import {
    getReportDataByReportType,
    getReportTypePages,
    getSelectedNonFieldFeaturesGuids,
} from "~/reports/data/selectors";
import { getSelectedCustomerGuids } from "~/customer-data/selectors";
import { useAppSelector } from "~/store/hooks";

import { ReportOptionsComponent, ReportOptionsComponentProps } from ".";
import { ReportPageSelection } from "../components/report-page-selection";

function AquaLenz({
    intl: { formatMessage },
    onInputChange,
    initialPreferences,
}: ReportOptionsComponentProps) {
    const [showPageSelection, setShowPageSelection] = React.useState(false);
    const reportData = useAppSelector(getReportDataByReportType(AquaLenz.reportName));
    const reportPageTypePrefs = initialPreferences[model.PROPS_REPORT_TYPE_PAGE_SELECTION]
        ? JSON.parse(initialPreferences[model.PROPS_REPORT_TYPE_PAGE_SELECTION])
        : {};
    const reportTypePages = useAppSelector((state) => getReportTypePages(state));
    const nonFieldFeatures = useAppSelector(getSelectedNonFieldFeaturesGuids);
    const selectedCustomerGuids = useAppSelector(getSelectedCustomerGuids);
    const reportPageSelection = reportData[model.PROPS_REPORT_TYPE_PAGE_SELECTION]
        ? JSON.parse(reportData[model.PROPS_REPORT_TYPE_PAGE_SELECTION])
        : {};

    React.useEffect(() => {
        onInputChange(model.PROPS_NON_FIELD_FEATURE_GUIDS, Array.from(nonFieldFeatures));
    }, [nonFieldFeatures.size]);

    React.useEffect(() => {
        onInputChange(model.PROPS_CUSTOMER_GUIDS, Array.from(selectedCustomerGuids));
    }, [selectedCustomerGuids.length]);

    const croppingSeasonDisabled =
        reportData[model.PROPS_START_DATE] || reportData[model.PROPS_END_DATE];

    return (
        <div className="report-options">
            <Section>
                <SubSection>
                    <CroppingSeason
                        disabled={croppingSeasonDisabled}
                        required={!croppingSeasonDisabled}
                        reportType={AquaLenz.reportName}
                    />
                </SubSection>
                <SubSection>
                    <DateInput
                        placeholderText={formatMessage(messages.startDate)}
                        onChange={(value) => onInputChange(model.PROPS_START_DATE, value)}
                        timeFormat={false}
                        value={reportData[model.PROPS_START_DATE]}
                    />
                    <DateInput
                        placeholderText={formatMessage(messages.endDate)}
                        onChange={(value) => onInputChange(model.PROPS_END_DATE, value)}
                        timeFormat={false}
                        value={reportData[model.PROPS_END_DATE]}
                    />
                </SubSection>
                <SubSection>
                    <NoLink
                        className="categories-link report-options-modal-link page-selection"
                        label={formatMessage(messages.pageSelection)}
                        onClick={() => setShowPageSelection(true)}
                    />
                    <ReportPageSelection
                        isOpen={showPageSelection}
                        onChange={(value) => {
                            onInputChange(
                                model.PROPS_REPORT_TYPE_PAGE_SELECTION,
                                JSON.stringify(value)
                            );
                            setShowPageSelection(false);
                        }}
                        onClose={() => setShowPageSelection(false)}
                        title={formatMessage(messages.pageSelection)}
                        reportTypePages={reportTypePages}
                        reportTypePagesPrefs={reportPageTypePrefs}
                        selectedReportTypes={[AquaLenz.reportName]}
                        selectedReportTypePages={reportPageSelection}
                    />
                </SubSection>
            </Section>
        </div>
    );
}

AquaLenz.propTypes = {
    intl: intlShape,
    reportPreferences: PropTypes.object,
};

AquaLenz.defaultReportOptions = {
    [model.PROPS_END_DATE]: null,
    [model.PROPS_START_DATE]: null,
    [model.PROPS_REPORT_TYPE_PAGE_SELECTION]: null,
};

AquaLenz.reportPreferences = [model.PROPS_REPORT_TYPE_PAGE_SELECTION];

AquaLenz.reportName = ReportAPI.ReportNames.AQUALENZ;

export default injectIntl(AquaLenz) as ReportOptionsComponent<ReportOptionsComponentProps>;
