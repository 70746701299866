import Polygon from "@arcgis/core/geometry/Polygon";
import { ClassBreak, GeometryMath, GeometryUtils, ZoneGeometryInfo, SearchAPI } from "@ai360/core";

import { OrderedMap } from "immutable";
import Immutable from "immutable";

import * as commonModels from "../model";
import { batchUpdateFieldRecEventMap } from "../reducer";
import * as actions from "./actions";
import * as models from "./model";
import { ActionType } from "typesafe-actions";
import { logFirebaseEvent } from "~/utils/firebase";
import { IAnalysisLayerSummaryItem } from "../analysis/model";

export interface IRecsState {
    batchToAnalysisLayerMap: Immutable.OrderedMap<string, IAnalysisLayerSummaryItem[]>;
    batchFieldGuid: string;
    clearedRecDetails: Immutable.OrderedMap<string, models.RecDetails>;
    recTypeInfoList: models.RecTypeInfo[];
    fieldGuidToRecListMap: Immutable.OrderedMap<string, models.RecSummary[]>;
    fieldGuidToInactiveRecListMap: Immutable.OrderedMap<string, models.RecSummary[]>;
    fieldGuidToRecDetails: Immutable.OrderedMap<string, models.RecDetails>;
    fieldGuidToFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>;
    fieldGuidToInactiveFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>;
    saveRecDetailsErrorCodeList: number[];
    saveRecDetailsErrorFieldGuidList: string[];
}

const newInitialState: IRecsState = Object.freeze({
    batchToAnalysisLayerMap: OrderedMap<string, IAnalysisLayerSummaryItem[]>(),
    batchFieldGuid: "",
    clearedRecDetails: OrderedMap<string, models.RecDetails>(),
    recTypeInfoList: [],
    fieldGuidToRecListMap: OrderedMap<string, models.RecSummary[]>(),
    fieldGuidToInactiveRecListMap: OrderedMap<string, models.RecSummary[]>(),
    fieldGuidToRecDetails: OrderedMap<string, models.RecDetails>(),
    fieldGuidToFieldMap: OrderedMap<string, SearchAPI.IFieldResult>(),
    fieldGuidToInactiveFieldMap: OrderedMap<string, SearchAPI.IFieldResult>(),
    saveRecDetailsErrorCodeList: [],
    saveRecDetailsErrorFieldGuidList: [],
});
const _getSkipUpdateToZone = (
    isSelectedZone,
    newRecNutrientList,
    rec,
    hasBeenRun,
    isPlantingEquation = false
) => {
    // For zones that are not selected, there is no new nutrient list, and already have needed props, skip update
    if (!isSelectedZone && (hasBeenRun || isPlantingEquation)) {
        return true;
    }
    // Remove this once run Recs product zone copy has been implemented
    return Boolean(
        !isSelectedZone &&
            ((!newRecNutrientList &&
                rec.equationGroupGuid &&
                (rec.equationParameters.length > 0 || rec.equationFilters.cropGuid)) ||
                (rec.recPlanting && !rec.equationGroupGuid))
    );
};
const _getUpdatedPropsForRecModel = (
    isSelectedZone,
    rec,
    recArea,
    newRecNutrientList,
    newProps
) => {
    // For the selected zone, include all the props. For the other zones, check to see if it has equation parameters already
    // If so, just update the recNutrientList
    const updatedProps = isSelectedZone
        ? {
              ...newProps,
              isIncluded:
                  newProps.isIncluded != null ? newProps.isIncluded : recArea.applyRecToArea,
              recNutrientList: newRecNutrientList,
          }
        : !rec.equationGroupGuid || rec.equationParameters.length === 0
        ? {
              ...newProps,
              isIncluded: recArea.applyRecToArea,
              recNutrientList: newRecNutrientList,
          }
        : { recNutrientList: newRecNutrientList };

    return updatedProps;
};
const _getNewRecArea = (rec, recArea, updatedProps) => {
    const recModel = rec.updateRecModel(updatedProps);
    const newRec = models.Rec.updateRec(recArea.recs[0], {
        ...recModel,
    });
    const newRecList = [...recArea.recs];
    newRecList.splice(0, 1, newRec);
    const applyRecToArea = newRecList.some((rec) => rec.isAllRequiredFieldsSet);

    return models.RecArea.updateRecArea(recArea, {
        applyRecToArea,
        recs: newRecList,
    });
};
const _getNutrientListForRecModel = (newRecNutrientList, rec) => {
    let revisedNutrientList = [];
    if (
        !newRecNutrientList ||
        (newRecNutrientList &&
            newRecNutrientList.length < 1 &&
            rec.recStatus === models.PROCESSING_SURFACES)
    ) {
        // If there is not a new recNutrientList in newProps, return the original
        return rec.recNutrientList;
    } else {
        // If the rec has not been run, apply the chosen new product across all zones
        // Need to apply recNutrientProductMix
        revisedNutrientList = newRecNutrientList;
    }

    return revisedNutrientList;
};

export const recsReducer = (
    state: IRecsState = newInitialState,
    action: ActionType<typeof actions>
): IRecsState => {
    switch (action.type) {
        case actions.SET_REC_TYPE_INFO: {
            const { recTypeInfoList } = action.payload;
            return Object.freeze({
                ...state,
                recTypeInfoList,
            });
        }
        case actions.CANCEL_REC_DETAILS: {
            const clearedRecDetails = state.fieldGuidToRecDetails.filter(
                (recDetails) =>
                    recDetails.recType === models.REC_TYPE_NAME_EQUATION_APPLICATION ||
                    recDetails.recType === models.REC_TYPE_NAME_EQUATION_PLANTING
            );
            return Object.freeze({
                ...state,
                clearedRecDetails,
            });
        }
        case actions.CLEAR_REC_DETAILS: {
            const fieldGuidToRecDetails = state.fieldGuidToRecDetails.clear();
            const saveRecDetailsErrorCodeList = [];
            const saveRecDetailsErrorFieldGuidList = [];
            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
                saveRecDetailsErrorCodeList,
                saveRecDetailsErrorFieldGuidList,
            });
        }
        case actions.COPY_BATCH_TEMPLATE_TO_RECS: {
            const fieldGuidToRecDetails = state.fieldGuidToRecDetails.asMutable();
            const templateRecDetails = fieldGuidToRecDetails.get(
                commonModels.BATCH_TEMPLATE_FIELD_GUID
            );
            for (const [fieldGuid, eventDetails] of fieldGuidToRecDetails.entries()) {
                if (fieldGuid === commonModels.BATCH_TEMPLATE_FIELD_GUID) {
                    fieldGuidToRecDetails.set(fieldGuid, eventDetails);
                    continue;
                }
                console.assert(fieldGuid === eventDetails.fieldGuid);
                const { fieldBoundaryGuid, eventSelectionList } = eventDetails;
                fieldGuidToRecDetails.set(
                    fieldGuid,
                    models.RecDetails.updateRecDetails(templateRecDetails, {
                        fieldGuid,
                        fieldBoundaryGuid,
                        eventSelectionList,
                    })
                );
            }
            return Object.freeze({
                ...state,
                fieldGuidToRecDetails: fieldGuidToRecDetails.asImmutable(),
            });
        }
        case actions.CREATE_NEW_REC_DETAILS: {
            const {
                fieldGuidList,
                fieldGuidToBoundaryGuidMap,
                recTypeInfo,
                recAreaList,
                croppingSeasonGuid,
            }: {
                fieldGuidList: string[];
                fieldGuidToBoundaryGuidMap: Map<string, string>;
                recTypeInfo: models.RecTypeInfo;
                recAreaList?: models.RecArea[];
                croppingSeasonGuid: string;
            } = action.payload;
            if (fieldGuidList && fieldGuidList.length > 1) {
                switch (recTypeInfo.name) {
                    case models.REC_TYPE_NAME_EQUATION_APPLICATION:
                        logFirebaseEvent("new_batch_rec_application_equation");
                        break;
                    case models.REC_TYPE_NAME_MANUAL_APPLICATION:
                        logFirebaseEvent("new_batch_rec_application_manual");
                        break;
                    case models.REC_TYPE_NAME_EQUATION_PLANTING:
                        logFirebaseEvent("new_batch_rec_planting_equation");
                        break;
                    case models.REC_TYPE_NAME_MANUAL_PLANTING:
                        logFirebaseEvent("new_batch_rec_planting_manual");
                        break;
                }
            }
            const fieldGuidToRecDetails = OrderedMap<string, models.RecDetails>(
                fieldGuidList.map((fieldGuid): [string, Readonly<models.RecDetails>] => {
                    const fieldBoundaryGuid = fieldGuidToBoundaryGuidMap.get(fieldGuid);
                    let recDetails = Object.freeze(
                        new models.RecDetails(
                            fieldGuid,
                            fieldBoundaryGuid,
                            [recTypeInfo],
                            croppingSeasonGuid
                        )
                    );
                    if (recAreaList != null) {
                        const { recs } = recDetails.recAreaList[0];
                        const newRecAreaList = recAreaList.map((recArea) =>
                            models.RecArea.updateRecArea(recArea, { recs })
                        );
                        recDetails = models.RecDetails.updateRecDetails(recDetails, {
                            recAreaList: newRecAreaList,
                        });
                    }
                    return [fieldGuid, recDetails];
                })
            );

            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
            });
        }
        case actions.DELETE_RECS: {
            logFirebaseEvent("delete_rec");
            const { recGeneralGuidList } = action.payload;
            const removedRecGeneralGuidListSet = new Set(recGeneralGuidList);
            const fieldGuidToRecListMap = state.fieldGuidToRecListMap.withMutations((map) => {
                for (const [fieldGuid, recSummaryList] of state.fieldGuidToRecListMap) {
                    map.set(
                        fieldGuid,
                        recSummaryList.filter(
                            (recSummary) =>
                                !removedRecGeneralGuidListSet.has(recSummary.recGeneralGuid)
                        )
                    );
                }
            });

            return Object.freeze({
                ...state,
                fieldGuidToRecListMap,
            });
        }
        case actions.FETCH_RECS_SUCCEEDED: {
            const {
                fieldGuidToRecListMap,
                fieldGuidToInactiveRecListMap,
                fieldGuidToFieldMap,
                fieldGuidToInactiveFieldMap,
            } = action.payload;
            return Object.freeze({
                ...state,
                fieldGuidToRecListMap,
                fieldGuidToInactiveRecListMap,
                fieldGuidToFieldMap,
                fieldGuidToInactiveFieldMap,
            });
        }
        case actions.UPDATE_SELECTED_FIELD_RECS: {
            const { fieldGuidToRecListMap, fieldGuidToInactiveRecListMap } = action.payload;
            return Object.freeze({
                ...state,
                fieldGuidToRecListMap,
                fieldGuidToInactiveRecListMap,
            });
        }
        case actions.FETCH_REC_DETAILS_SUCCEEDED: {
            const { recDetails } = action.payload;
            const fieldGuidToRecDetails = OrderedMap([[recDetails.fieldGuid, recDetails]]);
            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
            });
        }
        case actions.REMOVE_REC_GENERAL:
        case actions.REPLACE_REC_GENERAL: {
            const { changes, fields } = action.payload;

            return batchUpdateFieldRecEventMap(
                state,
                changes.map((rec) => ({
                    fieldGuid: rec.fieldGuid,
                    recEventGeneralGuid: rec.recGeneralGuid,
                    recEventData: rec.recData,
                })),
                fields,
                {
                    keyProp: "recGeneralGuid",
                    mapProp: "fieldGuidToRecListMap",
                    model: models.RecSummary,
                }
            ) as IRecsState;
        }
        case actions.REMOVE_INACTIVE_REC_GENERAL:
        case actions.REPLACE_INACTIVE_REC_GENERAL: {
            const { changes, fields } = action.payload;

            return batchUpdateFieldRecEventMap(
                state,
                changes.map((rec) => ({
                    fieldGuid: rec.fieldGuid,
                    recEventGeneralGuid: rec.recGeneralGuid,
                    recEventData: rec.recData,
                })),
                fields,
                {
                    keyProp: "recGeneralGuid",
                    mapProp: "fieldGuidToInactiveRecListMap",
                    model: models.RecSummary,
                }
            ) as IRecsState;
        }
        case actions.RESET_REC_AREA_POLYGONS:
        case actions.RESET_CLASSIFIED_REC_AREA_POLYGONS: {
            const { fieldGuid, areaIdToPolygonMap, areaIdToClassBreaksMap } = action.payload;
            const recDetails: models.RecDetails = state.fieldGuidToRecDetails.get(fieldGuid);
            const recGeneralGuid: string = recDetails.recGeneralGuid;
            const recs: models.Rec[] =
                recDetails.recAreaList[0].recs.length > 0
                    ? [recDetails.recAreaList[0].recs[0]]
                    : [new models.Rec()];
            const applyRecToArea: boolean =
                recDetails.recAreaList.length > 0
                    ? recDetails.recAreaList[0].applyRecToArea
                    : false;
            const recAreaList: models.RecArea[] = [...areaIdToPolygonMap.entries()].map(
                ([newRecAreaId, newAreaPolygon]) => {
                    const calculatedArea =
                        newAreaPolygon != null
                            ? GeometryUtils.calculateArea(newAreaPolygon.shape)
                            : null;
                    const recArea: models.RecArea =
                        recDetails.recAreaList.find((rec) => rec.recAreaId === newRecAreaId) ||
                        new models.RecArea(null, recs, calculatedArea, applyRecToArea);
                    const recAreaPolygons =
                        newAreaPolygon != null
                            ? GeometryMath.getSimplifiedPolygonParts(newAreaPolygon.shape).map(
                                  (poly: Polygon) => new models.RecAreaPolygon(poly)
                              )
                            : null;
                    const recAreaClassBreak: ClassBreak =
                        recAreaPolygons != null && areaIdToClassBreaksMap
                            ? areaIdToClassBreaksMap.get(newRecAreaId)
                            : null;
                    return models.RecArea.updateRecArea(recArea, {
                        calculatedArea,
                        fieldBoundaryGuid:
                            newAreaPolygon != null ? newAreaPolygon.fieldBoundaryGuid : null,
                        recs: recs.map((rec) =>
                            models.Rec.updateRec(rec, {
                                recGuid:
                                    recArea.recAreaGuid && recArea.recAreaGuid.length
                                        ? rec.recGuid
                                        : "",
                                recAreaGuid: recArea.recAreaGuid,
                            })
                        ),
                        recAreaId: newRecAreaId,
                        zonePolygons: recAreaPolygons,
                        recAreaClassBreak,
                        applyRecToArea,
                        recGeneralGuid,
                    });
                }
            );

            const updatedRecDetails = models.RecDetails.updateRecDetails(recDetails, {
                recAreaList,
            });
            const fieldGuidToRecDetails = state.fieldGuidToRecDetails.set(
                fieldGuid,
                updatedRecDetails
            );

            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
            });
        }
        case actions.SAVE_REC_DETAILS: {
            logFirebaseEvent("rec_save");
            return Object.freeze({
                ...state,
                saveRecDetailsErrorCodeList: [],
                saveRecDetailsErrorFieldGuidList: [],
            });
        }
        case actions.SAVE_REC_DETAILS_FAILED: {
            const { errorCodeList, errorFieldGuids } = action.payload;
            return Object.freeze({
                ...state,
                saveRecDetailsErrorCodeList: errorCodeList,
                saveRecDetailsErrorFieldGuidList: errorFieldGuids,
            });
        }
        case actions.SET_LAST_REC_BATCH_FIELD_GUID: {
            const { batchFieldGuid } = action.payload;
            return Object.freeze({
                ...state,
                batchFieldGuid,
            });
        }
        case actions.SET_REC_MODEL: {
            const { fieldGuid, areaId, newProps } = action.payload;
            const recDetails = state.fieldGuidToRecDetails.get(fieldGuid);
            const hasBeenRun = Boolean(recDetails.recGeneralGuid);
            const isPlantingEquation =
                recDetails.recType === models.REC_TYPE_NAME_EQUATION_PLANTING;

            const recAreaList = [...recDetails.recAreaList];
            for (const [recAreaIdx, recArea] of recAreaList.entries()) {
                const selectedRecAreaIdx = recDetails.recAreaList.findIndex(
                    (recArea) => recArea.recAreaId === areaId
                );
                const isSelectedZone = Boolean(recAreaIdx === selectedRecAreaIdx);
                const rec = recArea.recs[0];
                const skipUpdateToZone = _getSkipUpdateToZone(
                    isSelectedZone,
                    newProps.recNutrientList,
                    rec,
                    hasBeenRun,
                    isPlantingEquation
                );
                if (skipUpdateToZone) {
                    continue;
                }
                // in the case where it isSelecteZone is false, but it makes it though, either apply everything if it is new, or just the recNutrientList
                const newRecNutrientList = _getNutrientListForRecModel(
                    newProps.recNutrientList,
                    rec
                );
                const updatedProps = _getUpdatedPropsForRecModel(
                    isSelectedZone,
                    rec,
                    recArea,
                    newRecNutrientList,
                    newProps
                );
                const newRecArea = _getNewRecArea(rec, recArea, updatedProps);
                recAreaList.splice(recAreaIdx, 1, newRecArea);
            }

            const updatedRecDetails = models.RecDetails.updateRecDetails(recDetails, {
                recAreaList,
            });
            const fieldGuidToRecDetails = state.fieldGuidToRecDetails.set(
                fieldGuid,
                updatedRecDetails
            );
            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
            });
        }
        case actions.UPDATE_CANCELLED_RECS: {
            const { clearedRecDetails } = action.payload;
            return Object.freeze({
                ...state,
                clearedRecDetails,
            });
        }
        case actions.UPDATE_REC_AREA_POLYGONS: {
            const {
                fieldGuid,
                areaIdToNewAreaIdPolygonMap,
            }: {
                fieldGuid: string;
                areaIdToNewAreaIdPolygonMap: Map<number, Map<number, ZoneGeometryInfo>>;
            } = action.payload;
            const recDetails = state.fieldGuidToRecDetails.get(fieldGuid);
            const replacedAreaIdSet = new Set(areaIdToNewAreaIdPolygonMap.keys());
            const recAreaList = recDetails.recAreaList.filter(
                (recArea) => !replacedAreaIdSet.has(recArea.recAreaId)
            );
            for (const [
                replaceAreaId,
                newAreaIdToPolygonMap,
            ] of areaIdToNewAreaIdPolygonMap.entries()) {
                const replaceArea = recDetails.recAreaList.find(
                    (recArea) => recArea.recAreaId === replaceAreaId
                );
                if (replaceArea == null) {
                    throw new Error("invalid `recAreaId` key in `areaIdToNewAreaIdPolygonMap`");
                }
                const newRecAreas = [...newAreaIdToPolygonMap.entries()].map(
                    ([newAreaId, newAreaPolygon], index) => {
                        const isNewZone = index > 0;
                        const calculatedArea =
                            newAreaPolygon != null
                                ? GeometryUtils.calculateArea(newAreaPolygon.shape)
                                : null;
                        const newRecAreaPolygons =
                            newAreaPolygon != null
                                ? GeometryMath.getSimplifiedPolygonParts(newAreaPolygon.shape).map(
                                      (poly: Polygon) => new models.RecAreaPolygon(poly)
                                  )
                                : null;
                        // reset the class breaks when setting the polygon to null
                        const recAreaClassBreak =
                            newAreaPolygon != null ? replaceArea.recAreaClassBreak : null;
                        const recs = replaceArea.recs;
                        return models.RecArea.updateRecArea(replaceArea, {
                            calculatedArea,
                            fieldBoundaryGuid:
                                newAreaPolygon != null ? newAreaPolygon.fieldBoundaryGuid : null,
                            recs: recs.map((rec) =>
                                models.Rec.updateRec(rec, {
                                    recGuid: isNewZone ? null : rec.recGuid,
                                    recAreaGuid: isNewZone ? null : rec.recAreaGuid,
                                })
                            ),
                            recAreaGuid:
                                replaceArea.recAreaGuid && !isNewZone
                                    ? replaceArea.recAreaGuid
                                    : "",
                            recAreaClassBreak, // Update this to reset the recGuid and recAreaGuid properly
                            recAreaId: newAreaId,
                            zonePolygons: newRecAreaPolygons,
                        });
                    }
                );
                if ((window as any).process_env.NODE_ENV !== "production") {
                    const areaIdCountMap = new Map();
                    for (const { recAreaId } of newRecAreas) {
                        const curCount = areaIdCountMap.has(recAreaId)
                            ? areaIdCountMap.get(recAreaId)
                            : 0;
                        areaIdCountMap.set(recAreaId, curCount + 1);
                    }
                    if ([...areaIdCountMap.values()].some((count) => count !== 1)) {
                        throw new Error("invalid `areaIdToNewAreaIdPolygonMap`");
                    }
                }
                recAreaList.push(...newRecAreas);
            }

            const updatedRecDetails = models.RecDetails.updateRecDetails(recDetails, {
                recAreaList,
            });
            const fieldGuidToRecDetails = state.fieldGuidToRecDetails.set(
                fieldGuid,
                updatedRecDetails
            );

            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
            });
        }
        case actions.UPDATE_REC_DETAILS: {
            const { fieldGuid, newProps } = action.payload;
            const targetRecDetails = state.fieldGuidToRecDetails.get(fieldGuid);
            if (!targetRecDetails) {
                return Object.freeze({
                    ...state,
                });
            }
            const recDetails = models.RecDetails.updateRecDetails(targetRecDetails, newProps);
            const fieldGuidToRecDetails = state.fieldGuidToRecDetails.set(fieldGuid, recDetails);
            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
            });
        }

        case actions.BATCH_UPDATE_REC_DETAILS: {
            const fieldGuidToRecDetails = state.fieldGuidToRecDetails.withMutations((map) => {
                // error here will need to be addressed
                // Type 'IReducerPayload' must have a '[Symbol.iterator]()' method that returns an iterator.ts(2488)
                // most solutions seem to be tsconfig fix
                for (const recUpdate of action.payload as any) {
                    const { fieldGuid, newProps } = recUpdate;
                    map.set(
                        fieldGuid,
                        models.RecDetails.updateRecDetails(
                            state.fieldGuidToRecDetails.get(fieldGuid),
                            newProps
                        )
                    );
                }
            });

            return Object.freeze({
                ...state,
                fieldGuidToRecDetails,
            });
        }

        case actions.UPDATE_REC_SUMMARY_IMPORTED_STATUS: {
            const { fieldGuid, recGeneralGuid, importedStatus } = action.payload;

            const recSummaryList = state.fieldGuidToRecListMap.get(fieldGuid);
            if (recSummaryList == null) {
                return state;
            }
            const recSummaryIdx = recSummaryList.findIndex(
                (summary) => summary.recGeneralGuid === recGeneralGuid
            );
            if (recSummaryIdx === -1) {
                return state;
            }
            const fieldGuidToRecListMap = state.fieldGuidToRecListMap.set(fieldGuid, [
                ...recSummaryList.slice(0, recSummaryIdx),
                recSummaryList[recSummaryIdx].updateImportedStatus(importedStatus),
                ...recSummaryList.slice(recSummaryIdx + 1),
            ]);
            return Object.freeze({
                ...state,
                fieldGuidToRecListMap,
            });
        }

        case actions.UPDATE_REC_SUMMARY_STATUS: {
            const { fieldGuid, recGeneralGuid, recStatus } = action.payload;

            const recSummaryList = state.fieldGuidToRecListMap.get(fieldGuid);
            if (recSummaryList == null) {
                return state;
            }
            const recSummaryIdx = recSummaryList.findIndex(
                (summary) => summary.recGeneralGuid === recGeneralGuid
            );
            if (recSummaryIdx === -1) {
                return state;
            }
            const fieldGuidToRecListMap = state.fieldGuidToRecListMap.set(fieldGuid, [
                ...recSummaryList.slice(0, recSummaryIdx),
                recSummaryList[recSummaryIdx].updateRecStatus(recStatus as string),
                ...recSummaryList.slice(recSummaryIdx + 1),
            ]);
            return Object.freeze({
                ...state,
                fieldGuidToRecListMap,
            });
        }

        case actions.SET_BATCH_ANALYSIS_LAYERS: {
            const { batchToAnalysisLayerMap } = action.payload;
            return Object.freeze({
                ...state,
                batchToAnalysisLayerMap,
            });
        }

        default:
            return state;
    }
};
