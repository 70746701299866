import { connect } from "react-redux";
import Product from "./product";
//import { getValidEquationFlag } from "./data/selectors";
import { setProductImportType } from "./data/actions";

const mapDispatchToProps = (dispatch) => ({
    setProductImportType: (payload) => dispatch(setProductImportType(payload)),
});

export default connect(null, mapDispatchToProps)(Product);
