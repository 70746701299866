import { combineReducers } from "redux";

import { PRODUCT_IMPORT_STATE_KEY, productImportReducer } from "./agBytes/product/data/reducer";
import { ACCOUNT_DATA_KEY, accountReducer } from "./setup/account/data/reducer";
import { BATCH_ZAPPER_DATA_KEY, batchZapperReducer } from "./setup/batch-zapper/data/reducer";
import { ADDRESS_STATE_DATA_KEY, addressReducer } from "./setup/component/address/data/reducer";
import { LICENSE_STATE_DATA_KEY, licenseReducer } from "./setup/component/license/data/reducer";
import { CUSTOMER_DATA_KEY, customerReducer } from "./setup/customer/data/reducer";
import { EQUATION_LIST_DATA_KEY, equationListReducer } from "./setup/equation-list/data/reducer";
import { EQUIPMENT_SETUP_DATA_KEY, equipmentSetupReducer } from "./setup/equipment/data/reducer";
import {
    IMPORT_FILTER_DATA_KEY,
    importFilterReducer,
} from "./setup/import-filter-settings/data/reducer";
import { ORG_LEVEL_SETUP_DATA_KEY, orgLevelSetupReducer } from "./setup/org-level/data/reducer";
import { USER_PREFERENCE_DATA_KEY, userPreferenceReducer } from "./setup/preference/data/reducer";
import {
    SETUP_REPORT_BOOKS_DATA_KEY,
    setupReportBooksReducer,
} from "./setup/report-books/data/reducer";
import {
    SURFACE_DEFAULTS_DATA_KEY,
    surfaceDefaultsReducer,
} from "./setup/surface-defaults/data/reducer";
import { SYSTEM_DATA_KEY, systemReducer } from "./setup/system-profile/data/reducer";
import {
    TEMPLATE_MANAGER_DATA_KEY,
    templateManagerReducer,
} from "./setup/template-manager/data/reducer";
import { VENDOR_DATA_KEY, vendorReducer } from "./setup/vendor/data/reducer";
import {
    LOCATION_MIGRATION_DATA_KEY,
    locationMigrationSetupReducer,
} from "./setup/location-migration/data/reducer";
import {
    SETUP_RELEASE_NOTES_DATA_KEY,
    setupReleaseNotesReducer,
} from "./setup/release-notes/data/reducer";

export const ADMIN_STATE_KEY = "ADMIN_STATE";

export const adminReducer = combineReducers({
    [ACCOUNT_DATA_KEY]: accountReducer,
    [ADDRESS_STATE_DATA_KEY]: addressReducer,
    [BATCH_ZAPPER_DATA_KEY]: batchZapperReducer,
    [CUSTOMER_DATA_KEY]: customerReducer,
    [EQUATION_LIST_DATA_KEY]: equationListReducer,
    [EQUIPMENT_SETUP_DATA_KEY]: equipmentSetupReducer,
    [IMPORT_FILTER_DATA_KEY]: importFilterReducer,
    [LICENSE_STATE_DATA_KEY]: licenseReducer,
    [ORG_LEVEL_SETUP_DATA_KEY]: orgLevelSetupReducer,
    [PRODUCT_IMPORT_STATE_KEY]: productImportReducer,
    [SETUP_REPORT_BOOKS_DATA_KEY]: setupReportBooksReducer,
    [SURFACE_DEFAULTS_DATA_KEY]: surfaceDefaultsReducer,
    [SYSTEM_DATA_KEY]: systemReducer,
    [TEMPLATE_MANAGER_DATA_KEY]: templateManagerReducer,
    [USER_PREFERENCE_DATA_KEY]: userPreferenceReducer,
    [VENDOR_DATA_KEY]: vendorReducer,
    [LOCATION_MIGRATION_DATA_KEY]: locationMigrationSetupReducer,
    [SETUP_RELEASE_NOTES_DATA_KEY]: setupReleaseNotesReducer,
});
