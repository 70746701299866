import { IAgEventModelMethods } from "../model";

export class AgEventUnknown implements IAgEventModelMethods {
    get isAllRequiredFieldsSet(): boolean {
        return true;
    }

    get isAnyRequiredFieldSet(): boolean {
        return false;
    }

    updateAgEventModel(newProps: Partial<AgEventUnknown>): AgEventUnknown {
        return Object.assign(new AgEventUnknown(), newProps);
    }
}
