import React, { Component } from "react";
import PropTypes from "prop-types";
import { intlShape } from "react-intl";
import { Loader } from "~/core";
import { ImportWizardHeader } from "./import-wizard-header/import-wizard-header";
import { ImportWizardPanel } from "./import-wizard-panel/import-wizard-panel";
import { ImportWizardFooter } from "./import-wizard-footer/import-wizard-footer";

import { ImportTypes, PanelTypes } from "../actions";
import { messages } from "../i18n-messages";

import "./import-wizard.css";

const _getStepList = (formatMessage, importType, isSingleStep) => {
    switch (importType.name.toLowerCase()) {
        case ImportTypes.FIELD_BOUNDARY: {
            return [
                {
                    id: 0,
                    label: formatMessage(messages.fieldBoundaryImportText),
                    panelType: PanelTypes.FIELD_BOUNDARY_PANEL,
                    options: {},
                },
            ];
        }
        case ImportTypes.SAMPLING_SOIL:
        case ImportTypes.SAMPLING_TISSUE: {
            return isSingleStep
                ? [
                      {
                          id: 0,
                          label: formatMessage(
                              messages.importWizardCreateEventsSamplingCentroidText
                          ),
                          panelType: PanelTypes.EVENTS_PANEL,
                          options: {
                              ignoreSelectedField: true,
                              isFilteringVisible: true,
                              isSingleStep: true,
                          },
                      },
                  ]
                : [
                      {
                          id: 0,
                          label: formatMessage(messages.importWizardFilesText),
                          panelType: PanelTypes.FILES_PANEL,
                          options: {
                              ignoreSelectedField: true,
                              isFilteringVisible: false,
                          },
                      },
                      {
                          id: 1,
                          label: formatMessage(messages.importWizardFieldsText),
                          panelType: PanelTypes.FIELDS_PANEL,
                          options: {
                              ignoreSelectedField: false,
                              isFilteringVisible: true,
                          },
                      },
                      {
                          id: 2,
                          label: formatMessage(messages.importWizardEventsText),
                          panelType: PanelTypes.EVENTS_PANEL,
                          options: {
                              ignoreSelectedField: true,
                              isFilteringVisible: true,
                          },
                      },
                  ];
        }
        default: {
            return [
                {
                    id: 0,
                    label: formatMessage(messages.importWizardFilesText),
                    panelType: PanelTypes.FILES_PANEL,
                    options: {
                        ignoreSelectedField: true,
                        isFilteringVisible: false,
                    },
                },
                {
                    id: 1,
                    label: formatMessage(messages.importWizardFieldsText),
                    panelType: PanelTypes.FIELDS_PANEL,
                    options: {
                        ignoreSelectedField: false,
                        isFilteringVisible: true,
                    },
                },
                {
                    id: 2,
                    label:
                        importType.name.toLowerCase() !== ImportTypes.IMAGERY
                            ? formatMessage(messages.importWizardEventsText)
                            : formatMessage(messages.importWizardLayersText, {
                                  count: 2,
                              }),
                    panelType: PanelTypes.EVENTS_PANEL,
                    options: {
                        ignoreSelectedField: true,
                        isFilteringVisible: true,
                    },
                },
            ];
        }
    }
};
const _getSubmitLabel = (formatMessage, importType, importFieldList, eventStripCount = 0) => {
    switch (importType.name.toLowerCase()) {
        case ImportTypes.FIELD_BOUNDARY:
            return formatMessage(messages.importWizardAddFieldText, {
                count: importFieldList.length,
            });
        case ImportTypes.IMAGERY:
            return formatMessage(messages.importWizardCreateLayersText, {
                count: eventStripCount,
            });
        default:
            return formatMessage(messages.importWizardCreateEventsText, {
                count: eventStripCount,
            });
    }
};
const _getActiveStep = (stepId, stepList) => {
    return stepList.find((step) => {
        return step.id === stepId;
    });
};

export class ImportWizard_ extends Component {
    static propTypes = {
        changeStep: PropTypes.func.isRequired,
        closeImportWizard: PropTypes.func.isRequired,
        currentStep: PropTypes.number.isRequired,
        eventStripCount: PropTypes.number,
        hasSubmitted: PropTypes.bool.isRequired,
        ignoreSelectedField: PropTypes.bool.isRequired,
        isFilteringVisible: PropTypes.bool.isRequired,
        isFieldMatchingComplete: PropTypes.bool.isRequired,
        isFieldMatchingLoading: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        importTemplates: PropTypes.object,
        isValidating: PropTypes.bool.isRequired,
        importFieldStripData: PropTypes.array.isRequired,
        importFieldList: PropTypes.array.isRequired,
        importFileGuidList: PropTypes.array,
        importFiles: PropTypes.object,
        importType: PropTypes.object.isRequired,
        setIgnoreSelectedField: PropTypes.func.isRequired,
        setIsFilteringVisible: PropTypes.func.isRequired,
        setShowImportPoints: PropTypes.func.isRequired,
        showImportPoints: PropTypes.bool.isRequired,
        submitFieldBoundary: PropTypes.func.isRequired,
        validateImportEvent: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        loadDetailPage: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            canContinue: false,
            stepList: _getStepList(
                props.intl.formatMessage,
                props.importType,
                this._getIsSingleStep()
            ),
        };
    }

    _getIsSingleStep = () => {
        const { importFileGuidList, importFiles, importTemplates, importType } = this.props;
        const applicableImportTypes = [ImportTypes.SAMPLING_SOIL, ImportTypes.SAMPLING_TISSUE];
        if (!applicableImportTypes.includes(importType.name.toLowerCase())) {
            return false;
        }

        for (const guid of importFileGuidList) {
            const targetFile = importFiles.get(guid);
            if (!targetFile) {
                continue;
            }
            const targetTemplate = importTemplates.get(targetFile.templateGuid);
            if (targetTemplate.isNameMatch) {
                //Some templates only require one import wizard step
                return true;
            }
        }
        return false;
    };

    onSubmitClick = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
            });
            switch (this.props.importType.name.toLowerCase()) {
                case ImportTypes.FIELD_BOUNDARY:
                    this.props.submitFieldBoundary();
                    break;
                default:
                    this.props.validateImportEvent();
            }
        }
    };
    updateCanContinue = (canContinue) => {
        if (this.state.canContinue !== canContinue) {
            this.setState({
                canContinue,
            });
        }
    };
    updateCanSubmit = (canSubmit) => {
        if (this.state.canSubmit !== canSubmit) {
            this.setState({
                canSubmit,
            });
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.importType !== this.props.importType) {
            this.setState({
                stepList: _getStepList(
                    this.props.intl.formatMessage,
                    nextProps.importType,
                    this._getIsSingleStep()
                ),
            });
        }
        if (nextProps.currentStep !== this.props.currentStep) {
            this.updateCanContinue(false);
            const activeStep = _getActiveStep(nextProps.currentStep, this.state.stepList);

            if (activeStep.options) {
                if (activeStep.options.ignoreSelectedField !== nextProps.ignoreSelectedField) {
                    this.props.setIgnoreSelectedField(activeStep.options.ignoreSelectedField);
                }
                if (activeStep.options.isFilteringVisible !== nextProps.isFilteringVisible) {
                    this.props.setIsFilteringVisible(activeStep.options.isFilteringVisible);
                }
            }
        }
    }

    render() {
        const {
            changeStep,
            closeImportWizard,
            currentStep,
            eventStripCount,
            hasSubmitted,
            importFieldStripData,
            importFieldList,
            importType,
            isFieldMatchingComplete,
            isFieldMatchingLoading,
            isLoading,
            isValidating,
            setShowImportPoints,
            showImportPoints,
        } = this.props;
        const { canContinue, canSubmit, stepList } = this.state;
        const { formatMessage } = this.props.intl;
        const submitLabel = _getSubmitLabel(
            formatMessage,
            importType,
            importFieldList,
            eventStripCount
        );
        const activeStep = _getActiveStep(currentStep, stepList);
        const fieldPanelActive = activeStep.panelType === PanelTypes.FIELDS_PANEL;
        const isSingleStep = this._getIsSingleStep();
        return (
            <div className="import-wizard">
                {isLoading ||
                (isFieldMatchingLoading && (fieldPanelActive || isSingleStep)) ||
                isValidating ? (
                    <Loader />
                ) : null}
                <ImportWizardHeader
                    currentStep={currentStep}
                    importFieldStripData={importFieldStripData}
                    importType={importType.name.toLowerCase()}
                    stepList={stepList}
                    setShowImportPoints={setShowImportPoints}
                    showImportPoints={showImportPoints}
                    isFieldMatchingComplete={isFieldMatchingComplete}
                />
                <ImportWizardPanel
                    canContinue={canContinue}
                    canSubmit={canSubmit}
                    panelType={activeStep.panelType}
                    updateCanContinue={(c) => this.updateCanContinue(c)}
                    updateCanSubmit={(c) => this.updateCanSubmit(c)}
                    loadDetailPage={this.props.loadDetailPage}
                    importType={importType}
                />
                <ImportWizardFooter
                    canContinue={canContinue}
                    canSubmit={canSubmit}
                    currentStep={currentStep}
                    hasSubmitted={hasSubmitted}
                    importType={importType.name.toLowerCase()}
                    stepList={stepList}
                    submitLabel={submitLabel}
                    onBackClick={() => changeStep(currentStep - 1)}
                    onNextClick={() => changeStep(currentStep + 1)}
                    onSubmitClick={() => this.onSubmitClick()}
                    onCloseClick={(isCancel) => closeImportWizard(isCancel)}
                />
            </div>
        );
    }
}
