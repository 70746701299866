import React, { Children, Component, ReactNode, ReactElement } from "react";
import classnames from "classnames";

import "./tabs.css";

export interface ITabsProps {
    className?: string;
    children?: ReactNode | ReactNode[];
    onPreTabSelect?: (index: number) => void;
    onTabSelect?: (index: number) => void;
    selected?: number;
    labelContainerLeftElements?: ReactElement[];
    labelContainerRightElements?: ReactElement[];
}

export interface ITabsState {
    selected: number;
}
export default class Tabs extends Component<ITabsProps, ITabsState> {
    static defaultProps = {
        className: "",
        children: [],
        onPreTabSelect: (): void => null,
        onTabSelect: (): void => null,
    };

    constructor(props: ITabsProps) {
        super(props);
        this.state = {
            selected: props.selected || 0,
        };
    }

    UNSAFE_componentWillReceiveProps(props: ITabsProps): void {
        if (props.selected != null && props.selected !== this.state.selected) {
            this.setState({
                selected: props.selected,
            });
        }
    }
    _handleTabSelect(index: number, isDisabled: boolean): void {
        if (index === this.state.selected || isDisabled) {
            return; //Tab already selected or disabled
        }
        this.props.onPreTabSelect(index);
        this.setState(
            {
                selected: index,
            },
            () => {
                this.props.onTabSelect(index);
            }
        );
    }

    _renderTitles(): ReactElement {
        const labelcn = "tab-label";
        const leftItems = Children.map(this.props.labelContainerLeftElements, (child, index) => {
            return (
                <li className={classnames(labelcn, "left-non-label")} key={index}>
                    {child}
                </li>
            );
        });
        const titles = Children.map(this.props.children as ReactElement, (child, index) => {
            if (child && child.props) {
                const { disabled, label } = child.props;
                return (
                    <li
                        className={classnames(
                            labelcn,
                            { active: this.state.selected === index },
                            { disabled }
                        )}
                        key={index}
                        onClick={() => this._handleTabSelect(index, disabled)}
                    >
                        {label}
                    </li>
                );
            }
            return undefined;
        });
        const rightItems = Children.map(this.props.labelContainerRightElements, (child, index) => {
            return (
                <li className={classnames(labelcn, "right-non-label")} key={index}>
                    {child}
                </li>
            );
        });
        return (
            <ul className="tabs-labels">
                {leftItems}
                {titles}
                {rightItems}
            </ul>
        );
    }

    _renderContent(): ReactElement {
        return this.props.children[this.state.selected];
    }

    render(): ReactElement {
        const { className } = this.props;
        return (
            <div className={classnames("tabs", className)}>
                {this._renderTitles()}
                {this._renderContent()}
            </div>
        );
    }
}
