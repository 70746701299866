// Constants
import * as constants from "./constants";

export const defaultValue = () => ({
    [constants.PROPS_ADDRESS_IS_PRIMARY]: true,
    [constants.PROPS_ADDRESS_TYPE_GUID]: "",
    [constants.PROPS_ADDRESS_TYPE_NAME]: "",
    [constants.PROPS_ADDRESS]: {
        [constants.PROPS_ADDRESS_NAME]: "",
        [constants.PROPS_CITY]: "",
        [constants.PROPS_COUNRTY_NAME]: "",
        [constants.PROPS_COUNTRY_GUID]: "",
        [constants.PROPS_READ_ONLY_YN]: false,
        [constants.PROPS_STATE_GUID]: "",
        [constants.PROPS_STATE_NAME]: "",
        [constants.PROPS_STREET_ONE]: "",
        [constants.PROPS_ZIP_CODE]: "",
    },
});
