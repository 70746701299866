import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    Section,
    SelectInput,
    SubSection,
    ZeroToInfiniteGrid,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import { adminData, GUID, NAME } from "~/admin/data";
import { messages } from "./../i18n-messages";
import { createAddEditModalTitle, createAddLinkLabelText } from "~/i18n-messages";
import * as picklistService from "~/core/picklist/picklist-names";

const PROPS_PLANT_PART = "plantParts";
const PROPS_PLANT_PART_GUID = "plantPartGuid";

class PlantPartList extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        deleteItem: PropTypes.func,
        editData: PropTypes.object,
        formatMessage: PropTypes.func,
        getUpdatedLists: PropTypes.func,
        isDeleting: PropTypes.bool,
        isEditing: PropTypes.bool,
        onAdd: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        picklistOptions: PropTypes.object,
        record: PropTypes.array,
        renderDeleteModal: PropTypes.func,
        toggleModal: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
            plantPart: [],
        };
    }

    initializeDropdowns = (options) => {
        this.setState({
            plantPart: prepareSelectableOptions(
                options[picklistService.PICKLIST_PLANT_PART].map((o) => o.value),
                { guid: GUID, label: NAME }
            ),
        });
    };

    initializePlantPartData = ({ record = [], picklistOptions }) => {
        if (record) {
            const plantPart = picklistOptions[picklistService.PICKLIST_PLANT_PART];
            return record.map((recordItem) => {
                const selectedPlantPart = plantPart.find((plantPartItem) => {
                    return plantPartItem.value.guid === recordItem[PROPS_PLANT_PART_GUID];
                });
                recordItem[PROPS_PLANT_PART] = selectedPlantPart && selectedPlantPart.value[NAME];
                return recordItem;
            });
        }
        return record;
    };

    onAdd = () => {
        this.initializeDropdowns(this.props.picklistOptions, {});
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onEdit = (record, index) => {
        this.initializeDropdowns(this.props.picklistOptions, record);
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_PLANT_PART_GUID],
                key: GUID,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record && record.length > 0) {
            if (nextProps.picklistOptions[picklistService.PICKLIST_PLANT_PART]) {
                this.initializePlantPartData(nextProps);
            }
        }
    }

    renderAddEditModal = () => {
        const { editData, formatMessage, isEditing, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                forceOverflow
                hideCloseX
                actionDisabled={!editData[PROPS_PLANT_PART_GUID]}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.plantPart
                )}
            >
                <div className="dialog-box-select">
                    <Section>
                        <SubSection>
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state.plantPart}
                                placeholderText={formatMessage(messages.plantPart)}
                                value={mapToPicklistValue({
                                    options: this.state.plantPart,
                                    selectedGuid: formData[PROPS_PLANT_PART_GUID],
                                })}
                                onChange={(newValue) => {
                                    onChange({
                                        [PROPS_PLANT_PART_GUID]: newValue ? newValue.guid : null,
                                    });
                                }}
                            />
                        </SubSection>
                    </Section>
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {!(record && record.length > 0) ? null : (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_PLANT_PART]: {
                                title: formatMessage(messages.plantPart),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        label={createAddLinkLabelText(formatMessage, messages.plantPart)}
                        onClick={this.onAdd}
                    />
                </div>
            </div>
        );
    }
}

export default withEditableGrid(PlantPartList);
