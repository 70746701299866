import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";

import { DialogBox, DialogBoxFooterType, Menu, NoLink } from "~/core";
import { ClearIcon, CopyIcon } from "~/core/icons";

import { FormattingHelpers, AgEventAPI } from "@ai360/core";
import {
    actions as recsEventsActions,
    eventsSelectors,
    models as recsEventsModels,
    recsSelectors,
    selectors as recsEventsSelectors,
    eventsModels,
} from "~/recs-events";

import { EventScoutingTemplateModal } from "~/action-panel/components/event-module/components/event-info/components/event-scouting/event-scouting-template-modal";

import * as eventInfoSelectors from "../../event-module/components/event-info/selectors";
import * as recInfoSelectors from "../../rec-module/components/rec-info/selectors";

import { EventAreaIcon } from "./icons/event-area";

import "./rec-event-zone-info.css";

import { messages } from "./i18n-messages";
import { EventScoutingRecommendationModal } from "../../event-module/components/event-info/components/event-scouting/event-scouting-recommendation-modal";
import { EventDetails } from "~/recs-events/events/models";
import { logFirebaseEvent } from "~/utils/firebase";

export interface IMenuItem {
    key: number;
    label: string;
    disabled?: boolean;
    action: () => void;
}

export interface IRecEventZoneInfo_Props {
    SummarySection?: any; // own prop
    EditForm?: any; // own prop
    modelId: Record<string, any>; // For events, this will be the { agEventTransactionTypeGuid }, for recs { recTypeGuid }
    copyZonesFromToMap: Record<string, any>;
    currentArea?: Record<string, any>;
    recEventDetails?: Record<string, any>;
    fieldAcres?: number;
    fieldGuid?: string;
    importedAggregateEvent?: any;
    intl: intlShape;
    isBatchTemplate: boolean;
    isFromEquationRec?: boolean;
    isEquation?: boolean;
    isEventAction: boolean;
    isImportedYn?: any;
    isPlantingEquation?: boolean;
    isScoutingEvent: boolean;
    isLoading: boolean;
    onCancelZoneCopy: () => void;
    onClear: () => void;
    onCopyZones: () => void;
    onSetCurrentAreaId: (zoneIdList: number[]) => void;
    onSetCopyAllZones: () => void;
    onStartZoneCopy: () => void;
}

export interface IRecEventZoneInfo_State {
    cbLabelToZoneIdMap: Map<any, any>;
    showClearDialog: boolean;
    showScoutingTemplateModal: boolean;
    showRecommendationModal: boolean;
}

export class RecEventZoneInfo_ extends PureComponent<
    IRecEventZoneInfo_Props,
    IRecEventZoneInfo_State
> {
    static defaultProps = {
        isEquation: false,
    };

    constructor(props: IRecEventZoneInfo_Props) {
        super(props);
        this.state = {
            cbLabelToZoneIdMap: this._getCbLabelToZoneIdMap(props),
            showClearDialog: false,
            showScoutingTemplateModal: false,
            showRecommendationModal: false,
        };
    }

    _getCbLabelToZoneIdMap(props: IRecEventZoneInfo_Props): Map<any, any> {
        const { recEventDetails, isEventAction } = props;
        if (!recEventDetails) {
            return;
        }

        const areaClassBreakProp = isEventAction ? "eventAreaClassBreak" : "recAreaClassBreak";
        const areaList = isEventAction
            ? recEventDetails.eventAreaList
            : recEventDetails.recAreaList;
        if (!areaList.some((area) => area[areaClassBreakProp] != null)) {
            return;
        }

        // if any zone is classified, all of them should be
        console.assert(areaList.every((area) => area[areaClassBreakProp] != null));

        const rv = new Map();
        for (const area of areaList) {
            const areaClassBreak = area[areaClassBreakProp];
            const areaId = isEventAction ? area.eventAreaId : area.recAreaId;
            const key = areaClassBreak.label;
            if (!rv.has(key)) {
                rv.set(key, []);
            }
            rv.get(key).push(areaId);
        }
        return rv;
    }

    private _getZoneClassLegend() {
        const { cbLabelToZoneIdMap } = this.state;
        if (cbLabelToZoneIdMap == null) {
            return;
        }

        const { recEventDetails, currentArea, isEventAction } = this.props;
        const areaList = isEventAction
            ? recEventDetails.eventAreaList
            : recEventDetails.recAreaList;
        const areaClassBreakProp = isEventAction ? "eventAreaClassBreak" : "recAreaClassBreak";

        const { formatMessage } = this.props.intl;

        const classBreaksHaveMultipleZones = [...cbLabelToZoneIdMap.values()].some(
            (zoneIdList) => zoneIdList.length > 1
        );

        const cbLabelToTotalAcresMap = new Map();
        for (const area of areaList) {
            const areaClassBreak = area[areaClassBreakProp];
            const { calculatedArea } = area;
            const key = areaClassBreak.label;
            const accum = cbLabelToTotalAcresMap.has(key) ? cbLabelToTotalAcresMap.get(key) : 0;
            cbLabelToTotalAcresMap.set(key, accum + calculatedArea);
        }

        const activeRgbCss = currentArea[areaClassBreakProp].rgbCssStr;

        const cbLabelToColorMap = areaList.reduce((cbLabelToColorMap, area) => {
            const classBreak = area[areaClassBreakProp];
            if (!cbLabelToColorMap.has(classBreak.label)) {
                cbLabelToColorMap.set(classBreak.label, classBreak.rgbCssStr);
            }
            return cbLabelToColorMap;
        }, new Map());

        const legendElements = [...cbLabelToColorMap.keys()].map((label) => {
            const rgbCssStr = cbLabelToColorMap.get(label);
            const legendClassName = classnames("event-zone-classbreaks-legend", {
                "event-zone-legend-active": rgbCssStr === activeRgbCss,
            });
            const legendColorStyle = { backgroundColor: rgbCssStr };
            const zoneSize = formatMessage(messages.zoneSize, {
                calculatedArea: FormattingHelpers.formatNumber(cbLabelToTotalAcresMap.get(label)),
            });
            return (
                <div
                    key={label}
                    className={legendClassName}
                    onClick={() => this._onSetAreaIdFromLabel(label)}
                >
                    <div className="event-zone-legend-color" style={legendColorStyle} />
                    <div className="event-zone-legend-label">
                        <span className="label-text" title={label}>
                            {label}
                        </span>
                        <span className="label-size"> ({zoneSize})</span>
                    </div>
                </div>
            );
        });

        if (classBreaksHaveMultipleZones) {
            const headingTxt = currentArea[areaClassBreakProp].heading;
            const heading = (
                <div key={headingTxt} className="zone-legend-heading">
                    {headingTxt}
                </div>
            );
            legendElements.splice(0, 0, heading);
        }
        return legendElements;
    }

    private _getZoneNameAndSize() {
        const { currentArea, fieldAcres, isEventAction, isScoutingEvent, recEventDetails } =
            this.props;
        const { formatMessage } = this.props.intl;

        const { cbLabelToZoneIdMap } = this.state;

        const classBreaksHaveOnlyOneZone =
            cbLabelToZoneIdMap != null &&
            [...cbLabelToZoneIdMap.values()].every((zoneIdList) => zoneIdList.length === 1);

        if (classBreaksHaveOnlyOneZone) {
            return (
                <div className="zone-info-heading">
                    <div className="zone-legend-heading">
                        {isEventAction
                            ? currentArea.eventAreaClassBreak.heading
                            : currentArea.recAreaClassBreak.heading}
                    </div>
                </div>
            );
        }

        const zoneCount = isEventAction
            ? recEventDetails.eventAreaList.length
            : recEventDetails.recAreaList.length;
        const areaId = isEventAction ? currentArea.eventAreaId : currentArea.recAreaId;
        const { calculatedArea } = currentArea;

        const isNonPointZone = calculatedArea.toFixed(2) > 0;
        const zoneAreaMatchesFieldArea =
            fieldAcres && calculatedArea && fieldAcres.toFixed(2) === calculatedArea.toFixed(2);

        const zoneName =
            zoneCount === 1 && (!isScoutingEvent || zoneAreaMatchesFieldArea)
                ? formatMessage(messages.zoneNameEntireField)
                : isScoutingEvent
                ? formatMessage(messages.zoneScoutingName, { areaId })
                : formatMessage(messages.zoneName, { areaId });
        const zoneSize = isNonPointZone
            ? formatMessage(messages.zoneSize, {
                  calculatedArea: FormattingHelpers.formatNumber(calculatedArea),
              })
            : formatMessage(messages.zoneSizeScoutingPoint);

        return (
            <div className="zone-info-heading">
                <EventAreaIcon className="event-area-icon" />
                <div className="zone-name">{zoneName}</div>
                <div className="zone-size">{zoneSize}</div>
            </div>
        );
    }

    private _getCopyDialog() {
        const { copyZonesFromToMap, onSetCopyAllZones, onCancelZoneCopy, onCopyZones } = this.props;
        const { formatMessage } = this.props.intl;

        if (copyZonesFromToMap.size === 0) {
            return;
        }

        console.assert(copyZonesFromToMap.size === 1);
        const copyToZonesSet = copyZonesFromToMap.values().next().value;
        return (
            <DialogBox
                action={formatMessage(messages.zoneCopyApplyBtnTxt)}
                actionDisabled={copyToZonesSet.size === 0}
                className="copy-zone-modal"
                draggable={true}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                isOpen={true}
                onAction={onCopyZones}
                onClose={onCancelZoneCopy}
                title={formatMessage(messages.zoneCopyDialogTitle)}
            >
                <div className="copy-zone-msg">{formatMessage(messages.zoneCopyDialogMsg)}</div>
                <NoLink
                    label={formatMessage(messages.zoneCopySelectAllZonesLbl)}
                    onClick={onSetCopyAllZones}
                />
            </DialogBox>
        );
    }

    private _getClearDialog() {
        const { formatMessage } = this.props.intl;
        const { showClearDialog } = this.state;
        return (
            <DialogBox
                action={formatMessage(messages.clear)}
                draggable={true}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                isOpen={showClearDialog}
                onAction={() => {
                    this.props.onClear();
                    this._toggleClearDialog(false);
                }}
                onClose={() => this._toggleClearDialog(false)}
                title={formatMessage(messages.clearZoneData)}
            >
                {formatMessage(messages.clearZoneDataDlgMsg)}
            </DialogBox>
        );
    }

    private _onSetAreaIdFromLabel(label) {
        const { onSetCurrentAreaId, currentArea, isEventAction } = this.props;
        const { cbLabelToZoneIdMap } = this.state;

        const zoneIdList = cbLabelToZoneIdMap.get(label);
        if (zoneIdList.length === 1 || currentArea == null) {
            if (
                currentArea != null &&
                zoneIdList[0] === (isEventAction ? currentArea.eventAreaId : currentArea.recAreaId)
            ) {
                return; // don't bother setting it to the same value
            }
            onSetCurrentAreaId(zoneIdList[0]);
            return;
        }

        const zoneIdListIdx = zoneIdList.indexOf(
            isEventAction ? currentArea.eventAreaId : currentArea.recAreaId
        );
        onSetCurrentAreaId(
            zoneIdList[zoneIdListIdx === zoneIdList.length - 1 ? 0 : zoneIdListIdx + 1]
        );
    }

    private _toggleClearDialog = (show) => this.setState({ showClearDialog: show });

    private _toggleScoutingTemplateDialog = (show) =>
        this.setState({ showScoutingTemplateModal: show });

    private _toggleRecommendationDialog = (show) =>
        this.setState({ showRecommendationModal: show });

    UNSAFE_componentWillReceiveProps(nextProps: IRecEventZoneInfo_Props): void {
        const { recEventDetails } = nextProps;
        if (recEventDetails === this.props.recEventDetails) {
            return;
        }
        this.setState({
            cbLabelToZoneIdMap: this._getCbLabelToZoneIdMap(nextProps),
        });
    }

    public _getMenuItems(disableEditTemplate: boolean): IMenuItem[] {
        const { formatMessage } = this.props.intl;

        const menuItems = [
            {
                key: 0,
                label: formatMessage(messages.editTemplate),
                disabled: disableEditTemplate,
                action: () => {
                    logFirebaseEvent("scouting_edit_template");
                    this._toggleScoutingTemplateDialog(true);
                },
            },
            {
                key: 1,
                label: formatMessage(messages.recommendation),
                action: () => {
                    logFirebaseEvent("scouting_recommendation");
                    this._toggleRecommendationDialog(true);
                },
            },
        ];
        return menuItems;
    }

    public _handleAggregatedEvent(currentArea: Record<string, any>): boolean {
        const { recEventDetails, isEventAction } = this.props;
        if (isEventAction) {
            if (recEventDetails.creationType === "Imported") {
                if (currentArea.agEventList.length > 1) {
                    const hasAnyData = currentArea.agEventList.some(
                        (agEvent) => agEvent.agEventModel.isAnyRequiredFieldSet
                    );
                    return !hasAnyData;
                }
            }
        }
        return true;
    }

    render(): JSX.Element {
        const {
            SummarySection,
            EditForm,
            currentArea,
            fieldGuid,
            isBatchTemplate,
            isEventAction,
            isEquation,
            isFromEquationRec,
            isImportedYn,
            isLoading,
            isPlantingEquation,
            isScoutingEvent,
            modelId,
            onStartZoneCopy,
            recEventDetails,
        } = this.props;
        const { formatMessage } = this.props.intl;

        if (isLoading) {
            return null;
        }

        if (currentArea == null || (!isBatchTemplate && currentArea.zonePolygons == null)) {
            return (
                <div className="rec-event-info-form">
                    <div className="event-zone-info">
                        <div className="no-zone-msg">{formatMessage(messages.noZonesSelected)}</div>
                    </div>
                </div>
            );
        }

        const model = isEventAction
            ? currentArea.agEventList.find(
                  (agEvent) =>
                      agEvent.agEventTransactionTypeGuid === modelId.agEventTransactionTypeGuid
              ).agEventModel
            : currentArea.recs[0];
        if (
            isImportedYn &&
            !model.isAnyRequiredFieldSet &&
            this._handleAggregatedEvent(currentArea)
        ) {
            return (
                <div className="rec-event-info-form">
                    <div className="event-zone-info">
                        <div className="no-zone-msg">
                            {formatMessage(messages.nullZoneSelected)}
                        </div>
                    </div>
                </div>
            );
        }

        if (isBatchTemplate) {
            return (
                <div className="rec-event-info-form">
                    {!SummarySection || isEventAction || !isEquation ? null : (
                        <SummarySection isBatchTemplate={isBatchTemplate} />
                    )}
                    {isEventAction ? (
                        <EditForm
                            agEventModel={model}
                            agEventTransactionTypeGuid={modelId.agEventTransactionTypeGuid}
                            fieldGuid={fieldGuid}
                            isBatchTemplate={isBatchTemplate}
                            isEventType
                        />
                    ) : (
                        <EditForm
                            recModel={model}
                            recTypeGuid={modelId.recTypeGuid}
                            fieldGuid={fieldGuid}
                            isBatchTemplate={isBatchTemplate}
                            calculatedArea={currentArea.calculatedArea}
                        />
                    )}
                </div>
            );
        }

        const areaList = isEventAction
            ? recEventDetails.eventAreaList
            : recEventDetails.recAreaList;

        const hasBeenRun = Boolean(
            isEventAction ? recEventDetails.agEventGeneralGuid : recEventDetails.recGeneralGuid
        );

        const copyClass = !isEquation || hasBeenRun ? "" : "copy-equation-btn";
        const copy =
            areaList.length === 1 ||
            !model.isAllRequiredFieldsSet ||
            (isEquation && hasBeenRun) ||
            isScoutingEvent ||
            isImportedYn ||
            isFromEquationRec ? null : (
                <div className={copyClass} onClick={onStartZoneCopy}>
                    <CopyIcon />
                    {formatMessage(messages.zoneCopyText)}
                </div>
            );

        const clear =
            !model.isAllRequiredFieldsSet ||
            isEquation ||
            isScoutingEvent ||
            isImportedYn ? null : (
                <div onClick={() => this._toggleClearDialog(true)}>
                    <ClearIcon />
                    {formatMessage(messages.zoneClearText)}
                </div>
            );

        return (
            <div className="rec-event-info-form">
                {!SummarySection ? null : isEventAction || !isEquation || isPlantingEquation ? (
                    <SummarySection
                        fieldGuid={this.props.fieldGuid}
                        currentArea={this.props.currentArea}
                    />
                ) : (
                    <SummarySection />
                )}
                <div className="event-zone-info">
                    {this._getZoneNameAndSize()}
                    <div className="copy-btn">{copy}</div>
                    {isFromEquationRec ? null : <div className="clear-btn">{clear}</div>}
                    {this._getCopyDialog()}
                    {this._getClearDialog()}
                    {!isScoutingEvent ? null : (
                        <Menu
                            isDotMenu={true}
                            getMenuItems={() => this._getMenuItems(model.noIssuesFound)}
                        />
                    )}
                    {!isScoutingEvent || !this.state.showScoutingTemplateModal ? null : (
                        <EventScoutingTemplateModal
                            agEventModel={model}
                            isOpen={this.state.showScoutingTemplateModal}
                            onClose={() => this._toggleScoutingTemplateDialog(false)}
                        />
                    )}
                    {!isScoutingEvent ? null : (
                        <EventScoutingRecommendationModal
                            isOpen={this.state.showRecommendationModal}
                            onClose={() => this._toggleRecommendationDialog(false)}
                            fieldGuid={fieldGuid}
                            agEventTransactionTypeGuid={modelId.agEventTransactionTypeGuid}
                            recEventDetails={recEventDetails}
                        />
                    )}
                </div>
                {this._getZoneClassLegend()}
                {isEventAction ? (
                    <EditForm
                        agEventModel={model}
                        agEventTransactionTypeGuid={modelId.agEventTransactionTypeGuid}
                        fieldGuid={fieldGuid}
                        calculatedArea={
                            model.coverageArea
                                ? Number(model.coverageArea)
                                : currentArea.calculatedArea
                        }
                        isEventType
                    />
                ) : (
                    <EditForm
                        recAreaId={areaList.length > 1 ? currentArea.recAreaId : null}
                        recModel={model}
                        recTypeGuid={modelId.recTypeGuid}
                        fieldGuid={fieldGuid}
                        calculatedArea={currentArea.calculatedArea}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onCancelZoneCopy: () => dispatch(recsEventsActions.setCopyFromAreaId(null)),
    onClearEvent: (fieldGuid, agEventTransactionTypeGuid) =>
        dispatch(
            recsEventsActions.resetCurrentAgEventAreaAgEvent(fieldGuid, agEventTransactionTypeGuid)
        ),
    onClearRec: (fieldGuid, recTypeGuid) =>
        dispatch(recsEventsActions.resetCurrentRecAreaRec(fieldGuid, recTypeGuid)),
    onCopyZones: (fieldGuid, modelTypeGuid) =>
        dispatch(recsEventsActions.copyAreas(fieldGuid, modelTypeGuid)),
    setCopyFromAreaId: (areaId) => dispatch(recsEventsActions.setCopyFromAreaId(areaId)),
    setCopyToAreaIdSet: (areaIdSet) => dispatch(recsEventsActions.setCopyToAreaIdSet(areaIdSet)),
    setCurrentAreaId: (fieldGuid, areaId) =>
        dispatch(recsEventsActions.setCurrentAreaId(fieldGuid, areaId)),
});

const mapStateToProps = (state) => {
    const eventInfoState = eventInfoSelectors.getModuleState(state);
    const { eventSummary } = eventInfoState;
    const { fieldGuidToEventDetails } = eventsSelectors.getModuleState(state);

    const recInfoState = recInfoSelectors.getModuleState(state);
    const { recSummary } = recInfoState;
    const { fieldGuidToRecDetails } = recsSelectors.getModuleState(state);

    const isEventAction = fieldGuidToEventDetails.size > 0;

    const { isLoading } = isEventAction ? eventInfoState : recInfoState;

    const { batchFieldGuid } = recsEventsSelectors.getZonesState(state);

    const fieldGuid =
        batchFieldGuid != null
            ? batchFieldGuid
            : isEventAction
            ? eventSummary.fieldGuid
            : recSummary.fieldGuid;
    const recEventDetails = isEventAction
        ? fieldGuidToEventDetails.get(fieldGuid)
        : fieldGuidToRecDetails.get(fieldGuid);
    const { copyZonesFromToMap } = recsEventsSelectors.getZonesState(state);
    const currentArea = isEventAction
        ? eventsSelectors.getCurrentAgEventArea(state, fieldGuid)
        : recsSelectors.getCurrentRecArea(state, fieldGuid);

    const isBatchTemplate = batchFieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;

    const isScoutingEvent =
        isEventAction &&
        currentArea != null &&
        (currentArea as AgEventAPI.IAgEventArea).agEventList.some(
            (agEvent: eventsModels.AgEvent) =>
                agEvent.agEventTypeInfo.agEventTransactionTypeName ===
                recsEventsModels.EVENT_TYPE_NAME_SCOUTING
        );

    const isFromEquationRec = isEventAction && (recEventDetails as EventDetails).isFromEquationRec;

    const fieldAcres = isBatchTemplate
        ? null
        : isEventAction
        ? eventSummary.fieldAcres
        : recSummary.fieldAcres;

    const isImportedYn = isEventAction
        ? eventSummary.isImportedYn || (recEventDetails as EventDetails).creationType === "Imported"
        : false;

    return {
        copyZonesFromToMap,
        currentArea,
        recEventDetails,
        fieldAcres,
        fieldGuid,
        isBatchTemplate,
        isLoading,
        isEventAction,
        isImportedYn,
        isScoutingEvent,
        isFromEquationRec,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onClear: () =>
        stateProps.isEventAction
            ? dispatchProps.onClearEvent(
                  stateProps.fieldGuid,
                  ownProps.modelId.agEventTransactionTypeGuid
              )
            : dispatchProps.onClearRec(stateProps.fieldGuid, ownProps.modelId.recTypeGuid),
    onCopyZones: () =>
        dispatchProps.onCopyZones(
            stateProps.fieldGuid,
            stateProps.isEventAction
                ? ownProps.modelId.agEventTransactionTypeGuid
                : ownProps.modelId.recTypeGuid
        ),
    onStartZoneCopy: () =>
        dispatchProps.setCopyFromAreaId(
            stateProps.isEventAction
                ? stateProps.currentArea.eventAreaId
                : stateProps.currentArea.recAreaId
        ),
    onSetCopyAllZones: () => {
        const { currentArea, isEventAction, recEventDetails } = stateProps;
        const areaList = isEventAction
            ? recEventDetails.eventAreaList
            : recEventDetails.recAreaList;
        const fromArea = isEventAction ? currentArea.eventAreaId : currentArea.recAreaId;
        const allOtherAreaIds = new Set(
            areaList
                .map((zone) => (isEventAction ? zone.eventAreaId : zone.recAreaId))
                .filter((areaId) => areaId !== fromArea)
        );
        dispatchProps.setCopyToAreaIdSet(allOtherAreaIds);
    },
    onSetCurrentAreaId: (areaId) => {
        dispatchProps.setCurrentAreaId(stateProps.fieldGuid, areaId);
    },
});

export const RecEventZoneInfo = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecEventZoneInfo_));
