import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ECDataIcon = withSvgIcon(
    <g>
        <path d="M15.25 7.07L0 15.89l15.26 8L30 15.47zm0 1.11l12.8 7.29-7.82 4.45a1.64 1.64 0 0 0-.28-.62 2.33 2.33 0 0 0-1-.73c-.57-.26-.79-.36-.55-1.36.43-1.79-1.22-2.67-2.55-3.38-1-.52-2-1.06-2.18-1.81a3.9 3.9 0 0 1 .64-3.29zm0 14.59L2 15.85 12.19 10a4.51 4.51 0 0 0 .06 2.45c.37 1.37 1.72 2.09 2.91 2.73 1.34.72 2 1.13 1.81 1.74-.52 2.18.7 2.74 1.36 3a1.56 1.56 0 0 1 .43.24.77.77 0 0 1 0 .62z" />
        <path
            className="cls-1"
            d="M10.2 14.8a1.09 1.09 0 0 0-1.54.73c-.13.47 0 1-.14 1.23a.73.73 0 0 0 1 .95c.84-.42.33-.58 1.28-.81 1.68-.04.69-1.71-.6-2.1zM21.74 16.13a3.12 3.12 0 0 0 .61.23s1.15.18 1.62-.43a.75.75 0 0 0-.09-1.17c-.27-.2-.64-.26-.76-.39a.43.43 0 0 0-.17-.11H22.78a.56.56 0 0 0-.18 0c-.62.18-.3.35-1 .38-1.13-.21-.68 1.06.14 1.49zM14.09 18.26c-.28-.08-.59 0-.73-.07a.43.43 0 0 0-.55.62c.25.49.35.19.49.75 0 1 1 .37 1.23-.39a.65.65 0 0 0-.44-.91z"
        />
    </g>,
    "0 -5 35 35",
    "event-label-icon"
);
