import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import {
    fetchAgvanceDatabaseId,
    fetchAgvanceDatabaseIdSuccess,
    fetchAgvanceDatabaseIdFailed,
    postValidation,
    postValidationSuccess,
    postValidationFailed,
    postMigration,
    postMigrationSuccess,
    postMigrationFailed,
    getLocations,
    getLocationsSuccess,
    getLocationsFailed,
    postInvalidLocationCheck,
    postInvalidLocationCheckSuccess,
    postInvalidLocationCheckFailed,
    setAgvanceDatabaseId,
} from "./actions";
import { HierarchyAPI, LocationMigrationAPI, ZapperAPI, Request } from "@ai360/core";

// selectors
import { getTheUserGuid } from "~/login/selectors";

export function* fetchAgvanceDatabaseRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(Request.post, HierarchyAPI.GET_ORG_LEVEL, requestOptions);
        if (response) {
            yield put(fetchAgvanceDatabaseIdSuccess(response));
        } else {
            yield put(fetchAgvanceDatabaseIdFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${
                fetchAgvanceDatabaseId().type
            } with payload ${JSON.stringify(requestOptions)}`,
            error
        );
        yield put(fetchAgvanceDatabaseIdFailed(error));
    }
}

export function* postValidationRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        yield put(
            setAgvanceDatabaseId({
                agvanceDatabaseId: payload.newAgvanceDatabaseId,
            })
        );
        const response = yield call(
            Request.post,
            LocationMigrationAPI.POST_VALIDATE,
            requestOptions
        );
        if (response) {
            yield put(postValidationSuccess(response));
        } else {
            yield put(postValidationFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${postValidationSuccess().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(postValidationFailed(error));
    }
}

export function* postMigrationRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(
            Request.post,
            LocationMigrationAPI.POST_MIGRATE,
            requestOptions
        );
        if (response) {
            yield put(postMigrationSuccess(response));
        } else {
            yield put(postMigrationFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${postMigrationSuccess().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(postMigrationFailed(error));
    }
}

export function* postInvalidLocationCheckRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(
            Request.post,
            LocationMigrationAPI.GET_INVALID_LOCATIONS,
            requestOptions
        );
        if (response) {
            yield put(postInvalidLocationCheckSuccess(response));
        } else {
            yield put(postInvalidLocationCheckFailed("No Data"));
        }
    } catch (error) {
        console.error(
            `Failed to process action ${
                postInvalidLocationCheckSuccess().type
            } with payload ${JSON.stringify(requestOptions)}`,
            error
        );
        yield put(postInvalidLocationCheckFailed(error));
    }
}

export function* getLocationsRequest({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid };
    try {
        const attributes = yield call(Request.post, ZapperAPI.FETCH_ATTRIBUTES_URL, requestOptions);
        if (attributes.length > 0) {
            const tempRequestOptions = {
                UserGuid,
                Model: {
                    attributeGuid: attributes[0].guid,
                    attributeType: attributes[0].categoryIndex,
                    entityGuid: payload.entityGuid || "",
                    selectionGuid: payload.selectionGuid || "",
                    searchValue: payload.searchValue || "",
                },
            };
            const response = yield call(
                Request.post,
                ZapperAPI.FETCH_LOCATION_HIERARCHY_ENTITIES_URL,
                tempRequestOptions
            );
            if (response) {
                yield put(getLocationsSuccess(response));
            } else {
                yield put(getLocationsFailed("No Data"));
            }
        }
    } catch (error) {
        console.error(
            `Failed to process action ${getLocationsSuccess().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(getLocationsFailed(error));
    }
}

export function* watchFetchAgvanceDatabaseId() {
    yield takeEvery(fetchAgvanceDatabaseId, fetchAgvanceDatabaseRequest);
}

export function* watchPostValidation() {
    yield takeEvery(postValidation, postValidationRequest);
}

export function* watchPostMigration() {
    yield takeEvery(postMigration, postMigrationRequest);
}

export function* watchGetLocations() {
    yield takeEvery(getLocations, getLocationsRequest);
}

export function* watchPostInvalidLocationCheckRequest() {
    yield takeEvery(postInvalidLocationCheck, postInvalidLocationCheckRequest);
}

const LocationMigrationSagas = function* () {
    yield all([
        fork(watchFetchAgvanceDatabaseId),
        fork(watchPostValidation),
        fork(watchPostMigration),
        fork(watchGetLocations),
        fork(watchPostInvalidLocationCheckRequest),
    ]);
};

export default LocationMigrationSagas;
