import React from "react";
import PropTypes from "prop-types";
import "./field-information-title-bar.css";

export class FieldInformationTitleBar extends React.Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        className: PropTypes.string,
        fieldName: PropTypes.string,
        customerName: PropTypes.string,
        farmName: PropTypes.string,
        acres: PropTypes.number,
    };
    static defaultProps = {
        fieldName: "",
        customerName: "",
        farmName: "",
        acres: 0,
    };
    /// ----------------------------------------- ///

    /// Event Handlers ///

    /// ------------- ///

    render() {
        const { fieldName, customerName, farmName, acres } = this.props;
        const farmNameVisible = farmName !== "" && farmName != null;

        return (
            <div className="field-information-title-bar title-bar">
                <div className="first-line">{fieldName}</div>
                <div className="second-line">{customerName}</div>
                <div className="third-line">
                    {farmNameVisible && <span>{farmName}, </span>}
                    <span>{acres.toFixed(2)} acres</span>
                </div>
            </div>
        );
    }
}
