import React, { Component } from "react";
import { messages } from "./../../i18n-messages";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { model, service } from "./../data";
import { PROPS_GROWTH_STAGE_GUID } from "./../../growth-stages/data/model";
import { getAgBytesErrorClassNames, onNumberChange, onTextChange } from "~/admin/utils";
// Components
import { TextInput, NumericInput, Section, SubSection, Checkbox, TextArea } from "~/core";
import { GrowthStageOrder, IAddEditPanelState, IAddEditProps } from "../interfaces";

export class AddEditPanel extends Component<IAddEditProps & InjectedIntlProps, IAddEditPanelState> {
    growthStageOrder: GrowthStageOrder;
    constructor(props) {
        super(props);
        this.growthStageOrder = props.record || {};
        this.state = {
            nextId: null,
        };
    }

    componentDidMount(): void {
        this.setupGrowthStageRecord();
        this.props.setBreadcrumbs([""]);
        const { needs, parentGuid, recordGuid } = this.props;
        needs([this.props.getNextId(parentGuid)]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAddEditProps & InjectedIntlProps): void {
        if (nextProps.fetchData) {
            this.growthStageOrder[model.PROPS_GROWING_DEGREE_DAYS_LOW] = this.growthStageOrder[
                model.PROPS_GROWING_DEGREE_DAYS_LOW
            ]
                ? this.growthStageOrder[model.PROPS_GROWING_DEGREE_DAYS_LOW]
                : 0;
            this.growthStageOrder[model.PROPS_GROWING_DEGREE_DAYS_HIGH] = this.growthStageOrder[
                model.PROPS_GROWING_DEGREE_DAYS_HIGH
            ]
                ? this.growthStageOrder[model.PROPS_GROWING_DEGREE_DAYS_HIGH]
                : 0;
            this.props.liftRecordData(this.growthStageOrder);
        }

        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.growthStageOrder = nextProps.record;
            }
        }
    }

    setupNextId = (nextProps: IAddEditProps & InjectedIntlProps): void => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.growthStageOrder[model.PROPS_GROWTH_STAGE_ORDER] = "" + nextProps.nextId;
            this.growthStageOrder[PROPS_GROWTH_STAGE_GUID] = "" + nextProps.parentGuid;
            this.growthStageOrder[model.PROPS_GROWTH_STAGE_NAME] = "" + nextProps.parentName;
            this.growthStageOrder[model.PROPS_GROWTH_STAGE_ID] = "" + nextProps.parentId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupGrowthStageRecord = (): void => {
        const { addEditPanel, record } = this.props;
        this.growthStageOrder = record || {};
        if (addEditPanel.mode === "ADD") {
            this.growthStageOrder = service.getDefaultRecord();
        }
    };

    onTextChange = (formKey: string, value: string | boolean, callback?: () => void): void => {
        this.growthStageOrder = onTextChange(
            this.growthStageOrder,
            { formKey: [formKey], value },
            callback
        );
    };

    onNumberChange = (formKey: string, value: string | number, callback?: () => void): void => {
        this.growthStageOrder = onNumberChange(
            this.growthStageOrder,
            { formKey: [formKey], value },
            callback
        );
    };

    setHeaderText = (): void => {
        this.props.setBreadcrumbs([this.growthStageOrder[model.PROPS_GROWTH_STAGE_ORDER_NAME]]);
    };

    renderGeneInfo = (): JSX.Element => {
        const { apiErrors, userRole } = this.props;
        const { formatMessage } = this.props.intl;
        const { growthStageOrder } = this;
        return (
            <div className="section-container">
                <Section>
                    <div className="form-sub-section section-column">
                        <span>Growth Stage Group ID: {this.props.parentId}</span>
                        <span>Growth Stage Group Name: {this.props.parentName}</span>
                    </div>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            containerClassNames={getAgBytesErrorClassNames([166, 167], apiErrors, [
                                "form-input-id",
                            ])}
                            suppressFormatting
                            placeholderText={formatMessage(messages.growthStageOrderID)}
                            value={"" + growthStageOrder[model.PROPS_GROWTH_STAGE_ORDER]}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_GROWTH_STAGE_ORDER, value)
                            }
                            required
                        />
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className="active-checkbox"
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={growthStageOrder[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames([164, 165], apiErrors)}
                            placeholderText={formatMessage(messages.growthStageOrderName)}
                            value={growthStageOrder[model.PROPS_GROWTH_STAGE_ORDER_NAME]}
                            autoFocus
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_GROWTH_STAGE_ORDER_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            required
                        />
                        <TextInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(253, apiErrors)}
                            placeholderText={formatMessage(messages.description)}
                            value={growthStageOrder[model.PROPS_DESCRIPTION]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_DESCRIPTION,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            required
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render(): JSX.Element {
        const { apiErrors } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className="add-edit-panel">
                {this.renderGeneInfo()}
                <Section headerText={formatMessage(messages.miscellaneousInfo)}>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={5}
                            containerClassNames={getAgBytesErrorClassNames(178, apiErrors)}
                            placeholderText={formatMessage(messages.growingDegreeDaysLowLabel)}
                            value={
                                this.growthStageOrder[model.PROPS_GROWING_DEGREE_DAYS_LOW] || "0"
                            }
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_GROWING_DEGREE_DAYS_LOW, value)
                            }
                        />
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={5}
                            containerClassNames={getAgBytesErrorClassNames(178, apiErrors)}
                            placeholderText={formatMessage(messages.growingDegreeDaysHighLabel)}
                            value={
                                this.growthStageOrder[model.PROPS_GROWING_DEGREE_DAYS_HIGH] || "0"
                            }
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_GROWING_DEGREE_DAYS_HIGH, value)
                            }
                        />
                    </SubSection>
                    <SubSection>
                        <TextArea
                            value={this.growthStageOrder[model.PROPS_GROWTH_STAGE_NOTES]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_GROWTH_STAGE_NOTES, value)
                            }
                            placeholderText={formatMessage(messages.notes)}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
