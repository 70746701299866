import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { actions as accordionActions } from "~/accordion";
import { Loader, LoaderTypes } from "~/core";

import { actions as fileImportActions, selectors as fileImportSelectors } from "~/file-import";

import * as selectors from "../../../selectors";
import * as actions from "../../../actions";

import { messages } from "../../i18n-messages";

import { AccordionItemContextMenu } from "./item-context-menu-container";

import "./import-template-accordion-item.css";

export const IMPORT_TEMPLATE_HEIGHT = 35;

export class ImportTemplateItem_ extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        accordionId: PropTypes.number.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        itemDimIdx: PropTypes.arrayOf(PropTypes.number),
        templateGuid: PropTypes.string.isRequired,
        getImportSelectedEnabled: PropTypes.func.isRequired,
        getImportTemplate: PropTypes.func.isRequired,
        getImportType: PropTypes.func.isRequired,
        getSelectedChildGuidSet: PropTypes.func.isRequired,
        expandItem: PropTypes.func.isRequired,
        collapseItem: PropTypes.func.isRequired,
        onImportSelected: PropTypes.func.isRequired,
    };

    _onClick(event) {
        const { templateGuid, getImportTemplate } = this.props;
        const importTemplate = getImportTemplate(templateGuid);
        if (importTemplate.isFetchingFiles || event.isDefaultPrevented()) {
            return;
        }

        const { isExpanded, expandItem, collapseItem, accordionId, itemDimIdx } = this.props;

        if (isExpanded) {
            collapseItem(accordionId, itemDimIdx, importTemplate);
        } else {
            expandItem(accordionId, itemDimIdx, importTemplate);
        }
    }

    _addImportSelected(menuItems) {
        const { formatMessage } = this.props.intl;
        const {
            getImportSelectedEnabled,
            getImportTemplate,
            getImportType,
            getSelectedChildGuidSet,
            onImportSelected,
            templateGuid,
        } = this.props;

        const importTemplate = getImportTemplate(templateGuid);
        if (importTemplate && importTemplate.name === "Monitor Data (*.zip)") {
            return menuItems;
        }

        return [
            {
                label: formatMessage(messages.importSelected),
                action: () =>
                    onImportSelected(
                        Array.from(getSelectedChildGuidSet()),
                        getImportType(),
                        getImportTemplate(templateGuid)
                    ),
                disabled: !getImportSelectedEnabled(),
            },
            ...menuItems,
        ];
    }

    render() {
        const { templateGuid, getImportTemplate, itemDimIdx } = this.props;
        const importTemplate = getImportTemplate(templateGuid);
        if (!importTemplate) {
            return null;
        }
        const loader = !importTemplate.isFetchingFiles ? null : (
            <Loader className="template-loader" type={LoaderTypes.LINE_SCALE_PULSE_OUT_RAPID} />
        );

        return (
            <div
                className="import-template-accordion-item"
                onClick={(event) => this._onClick(event)}
            >
                <div className="template-name">{importTemplate.name}</div>
                <AccordionItemContextMenu
                    itemDimIdx={itemDimIdx}
                    modifyMenuItems={(menuItems) => this._addImportSelected(menuItems)}
                />
                {loader}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    expandItem: (accordionId, dimIdx, importTemplate) => {
        dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx));
        dispatch(fileImportActions.setTemplateExpanded(importTemplate.templateGuid, true));
    },
    collapseItem: (accordionId, dimIdx, importTemplate) => {
        dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx));
        dispatch(fileImportActions.setTemplateExpanded(importTemplate.templateGuid, false));
    },
    onImportSelected: (templateSelectedImportFileGuids, importType, importTemplate) =>
        dispatch(
            actions.loadImportWizard(templateSelectedImportFileGuids, importType, importTemplate)
        ),
});

const mapStateToProps = (state, ownProps) => {
    const getImportTemplate = () =>
        fileImportSelectors.getImportTemplate(state, ownProps.templateGuid);
    const getImportType = () =>
        fileImportSelectors.getImportType(state, getImportTemplate().importTypeGuid);

    const selectedChildGuidSetSelector = selectors.getSelectedChildGuidSetSelector(
        ownProps.itemDimIdx
    );
    const getSelectedChildGuidSet = () => selectedChildGuidSetSelector(state);

    const getImportSelectedEnabled = () => {
        const getImportFile = (ifoGuid) => fileImportSelectors.getImportFileInfo(state, ifoGuid);
        const selectedChildGuidSet = getSelectedChildGuidSet();
        if (selectedChildGuidSet.size === 0) {
            return false;
        }
        for (let ifoGuid of selectedChildGuidSet) {
            if (!getImportFile(ifoGuid).isReadyForImport()) {
                return false;
            }
        }
        return true;
    };

    return {
        getSelectedChildGuidSet,
        getImportSelectedEnabled,
        getImportTemplate,
        getImportType,
    };
};

export const ImportTemplateItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ImportTemplateItem_));
