import { IActionData, IEula } from "./interfaces";

export const AUTO_LOGIN = "login/AUTO_LOGIN";
export const CONTINUE_RESET = "login/CONTINUE_RESET";
export const FETCH_ENROLLMENT_FORM = "login/FETCH_ENROLLMENT_FORM";
export const FETCH_FILTERED_CUSTOMER_LIST = "login/FETCH_FILTERED_CUSTOMER_LIST";
export const INITIAL_ENROLLMENT_LOAD = "login/INITIAL_ENROLLMENT_LOAD";
export const INITIAL_MOBILE_LOAD = "login/INITIAL_MOBILE_LOAD";
export const LOGIN = "login/LOGIN";
export const LOGOUT_USER = "login/LOGOUT_USER";
export const RELOGIN_USER = "login/RELOGIN_USER";
export const RESET_APP = "login/RESET_APP";
export const RESET_LOGIN = "login/RESET_LOGIN";
export const SET_LOGIN_BACKGROUND_URL = "login/SET_LOGIN_BACKGROUND_URL";
export const SET_ERROR = "login/SET_ERROR";
export const SET_EULA_INFO = "login/SET_EULA_INFO";
export const SET_FILTERED_CUSTOMERS = "login/SET_FILTERED_CUSTOMERS";
export const SET_IMAGERY_TILE_BUCKET_NAME = "login/SET_IMAGERY_TILE_BUCKET_NAME";
export const SET_LOGIN_STATE = "login/SET_LOGIN_STATE";
export const SET_LOGIN_GUID = "login/SET_LOGIN_GUID";
export const SET_MOBILE_APP_DETAILS = "login/SET_MOBILE_APP_DETAILS";
export const SET_PROCESSING = "login/SET_PROCESSING";
export const SET_SECURITY_INFO = "login/SET_SECURITY_INFO";
export const SET_SECURITY_MESSAGE = "login/SET_SECURITY_MESSAGE";
export const SET_USER_INFO = "login/SET_USER_INFO";
export const SET_USERS_INFO = "login/SET_USERS_INFO";
export const SET_USER_INFO_COMPLETE = "login/SET_USER_INFO_COMPLETE";
export const FETCH_LAYER_TYPES_ACCESS = "login/FETCH_LAYER_TYPES_ACCESS";
export const SET_LAYER_TYPES_ACCESS = "login/SET_LAYER_TYPES_ACCESS";
export const SET_SYSTEM_FAVICON_URL = "login/SET_SYSTEM_FAVICON_URL";
export const SET_RELEASE_NOTES = "login/SET_RELEASE_NOTES";

export const LoginState = {
    NONE: 0,
    LOGIN_FORM: 1,
    SELECT_SECURITY_QUESTION: 2,
    RESET_PASSWORD: 3,
    SELECT_COMPANY: 4,
    RECOVER_PASSWORD: 5,
    SECURITY_QUESTION: 6,
    SECURITY_MESSAGE: 7,
    EULA: 8,
};

export const LoginErrors = {
    INVALID_PARAMETER: "InvalidParameterException",
};

export const autoLogin = (key: string, history: Record<string, any>): IActionData => ({
    type: AUTO_LOGIN,
    key,
    history,
});

export const continueReset = (): IActionData => ({
    type: CONTINUE_RESET,
});

export const fetchEnrollmentForm = (customerGuid: string): IActionData => ({
    type: FETCH_ENROLLMENT_FORM,
    customerGuid,
});

export const fetchFilteredCustomerList = (orgLevelGuid: string, userGuid: string): IActionData => ({
    type: FETCH_FILTERED_CUSTOMER_LIST,
    orgLevelGuid,
    userGuid,
});

export const initialEnrollmentLoad = (): IActionData => ({
    type: INITIAL_ENROLLMENT_LOAD,
});

export const initialMobileLoad = (): IActionData => ({
    type: INITIAL_MOBILE_LOAD,
});

export const login = (
    email: string,
    password: string,
    history: Record<string, any>
): IActionData => ({
    type: LOGIN,
    email,
    history,
    password,
});

export const logoutUser = (): IActionData => ({
    type: LOGOUT_USER,
});

export const reloginUser = (history: Record<string, any>): IActionData => ({
    type: RELOGIN_USER,
    history,
});

export const resetLogin = (): IActionData => ({
    type: RESET_LOGIN,
});

export const resetApp = (): IActionData => ({
    type: RESET_APP,
});

export const setLoginBackgroundUrl = (url: string): IActionData => ({
    type: SET_LOGIN_BACKGROUND_URL,
    url,
});

export const setMobileAppDetails = (mobileAppDetails: Record<string, any>): IActionData => ({
    type: SET_MOBILE_APP_DETAILS,
    mobileAppDetails,
});

export const setSystemFaviconUrl = (url: string): IActionData => ({
    type: SET_SYSTEM_FAVICON_URL,
    url,
});

export const setError = (error: Error): IActionData => ({
    type: SET_ERROR,
    error,
});

export const setEulaInfo = (
    eula: IEula,
    loginGuid: string,
    isFirstTimeLogin: boolean
): IActionData => ({
    type: SET_EULA_INFO,
    eula: { ...eula, loginGuid, isFirstTimeLogin },
});

export const setFilteredCustomers = (
    filteredCustomers: Record<string, any>,
    lastUsedCustomerGuid: string
): IActionData => ({
    type: SET_FILTERED_CUSTOMERS,
    payload: { filteredCustomers, lastUsedCustomerGuid },
});

export const setImageryTileBucketName = (bucketName: string): IActionData => ({
    type: SET_IMAGERY_TILE_BUCKET_NAME,
    payload: { bucketName },
});

export const setLoginGuid = (loginGuid: string): IActionData => ({
    type: SET_LOGIN_GUID,
    payload: { loginGuid },
});

export const setLoginState = (loginState: unknown): IActionData => ({
    type: SET_LOGIN_STATE,
    loginState,
});

export const setProcessing = (isProcessing: boolean): IActionData => ({
    type: SET_PROCESSING,
    isProcessing,
});

export const setSecurityInfo = (email: string, question?: string): IActionData => ({
    type: SET_SECURITY_INFO,
    securityInfo: { email, question },
});

export const setSecurityMessage = (code: string): IActionData => ({
    type: SET_SECURITY_MESSAGE,
    code,
});

export const setUserInfo = (
    theUser: Record<string, any>,
    selectedCompanyGuid?: string,
    selectedLocationGuid?: string,
    selectedCustomerGuid?: string,
    isReset = false
): IActionData => ({
    type: SET_USER_INFO,
    theUser,
    selectedCompanyGuid,
    selectedCustomerGuid,
    selectedLocationGuid,
    isReset,
});

export const setUsersInfo = (theUsers: readonly Record<string, any>[]): IActionData => ({
    type: SET_USERS_INFO,
    theUsers,
});

export const setUserInfoComplete = (theUser: Record<string, any>): IActionData => ({
    type: SET_USER_INFO_COMPLETE,
    theUser,
});

export const setLayerTypesAccess = (layerTypesAccess: Record<string, any>): IActionData => ({
    type: SET_LAYER_TYPES_ACCESS,
    layerTypesAccess,
});

export const fetchLayerTypesAccess = (): IActionData => ({
    type: FETCH_LAYER_TYPES_ACCESS,
});

export const setReleaseNotes = (releaseNotes: string[]): IActionData => ({
    type: SET_RELEASE_NOTES,
    releaseNotes,
});
