import { IAction } from "./interfaces";

export const LISTEN = "authentication/LISTEN";
export const RECEIVED = "authentication/RECEIVED";

export const listen = (name: string): IAction => ({
    type: LISTEN,
    name,
});

export const received = (name: string, value: unknown): IAction => ({
    type: RECEIVED,
    name,
    value,
});
