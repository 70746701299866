import { createController, prepActions } from "~/hocs/crud";
import { defaultActions } from "~/hocs/crud/actions";
import { service } from "./service";
import { put, select, take } from "redux-saga/effects";
import { getProductImportType } from "./selector";
import { importProduct } from "./actions";
import { APIError } from "@ai360/core";
import { setApiResult } from "~/core/api/actions";
import { actions as notificationActions } from "~/notifications";

export const actions = prepActions(service);
export const productSagas = createController(service, actions);

export function* importProductSaga() {
    while (true) {
        try {
            yield take(importProduct);
            const productImportType = yield select(getProductImportType);
            yield put(productImportType());
        } catch (error) {
            yield put(defaultActions.failed());
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error));
            }
        }
    }
}
