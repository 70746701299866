import * as model from "./model";

const preferenceDefaultRecord = () => ({
    [model.PROPS_COLOR_SCHEME]: "",
    [model.PROPS_RECEIVE_EMAIL_NOTIFICATIONS_FOR_REPORTS]: true,
    [model.PROPS_RECEIVE_WEB_NOTIFICATIONS_FOR_REPORTS]: true,
    [model.PROPS_RECEIVE_IN_APP_NOTIFICATIONS_FOR_REPORTS]: true,
    [model.PROPS_RECEIVE_WEB_NOTIFICATIONS_FOR_SAMPLING_RESULTS]: true,
    [model.PROPS_RECEIVE_IN_APP_NOTIFICATIONS_FOR_SAMPLING_RESULTS]: true,
    [model.PROPS_USER_PREFERENCE_GUID]: "",
    [model.PROPS_SEASON_FILTER_LIST]: [],
    [model.PROPS_AUTO_IMPORT_CONFIRMATION]: false,
    [model.PROPS_AUTO_IMPORT_DATA]: "",
});

export default preferenceDefaultRecord;
