import { selectors } from "~/core/picklist";

export const getPicklists = (keys = {}, state) => {
    const picklistData = selectors.getPicklistState(state);
    return Object.keys(keys).reduce((map, key) => {
        map[key] = picklistData[keys[key]];
        return map;
    }, {});
};

export const getPicklist = (key, state) => selectors.getPicklistFromCode(state, key);
