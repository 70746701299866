import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { messages } from "~/admin/agBytes/i18n-messages";
import { TextInput, Section, SubSection, Checkbox, NumericInput } from "~/core";
import { model, service } from "./../../data";

export default class Parameters extends Component {
    static propTypes = {
        equationParametersPicklist: PropTypes.object, //Map
        equationText: PropTypes.string,
        formatMessage: PropTypes.func.isRequired,
        onTextChange: PropTypes.func,
        parameters: PropTypes.array,
        updateTestButtonStatus: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            importAttributes: [],
        };
        this.equationParametersPicklist = new Map(props.equationParametersPicklist);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.parameters && nextProps.parameters !== this.props.parameters) {
            this.getParameterList(nextProps);
        }

        if (nextProps.equationText !== this.props.equationText) {
            this.getParameterList(nextProps);
        }

        if (nextProps.equationParametersPicklist !== this.props.equationParametersPicklist) {
            this.equationParametersPicklist = new Map(nextProps.equationParametersPicklist);
        }
    }

    parseParameter(parameter) {
        if (parameter) {
            const parameterArray = parameter.split("|");
            return {
                parsedParameter: parameterArray[0].replace(/[$!]/g, ""),
                value: parameterArray[1] ? parameterArray[1].replace(/[$!]/g, "") : "",
            };
        }
    }

    getParameterList = (nextProps) => {
        const { parameters, equationText } = nextProps;
        const parametersFromText = equationText.match(/[$!](.*?)[!$]/g);
        if (parameters && parametersFromText) {
            const updatedParametersFromText = parametersFromText.reduce((acc, attr) => {
                // Handle empty parameter - restrict the push unless we have a value
                if (attr !== "$$") {
                    acc.push(String(attr));
                }
                return acc;
            }, []);
            const updatedParameters =
                (updatedParametersFromText.length > 0 &&
                    _.uniqBy(
                        updatedParametersFromText.map((parameter) => {
                            const { parsedParameter, value } = this.parseParameter(parameter);
                            const parameterType =
                                this.equationParametersPicklist.get(parsedParameter);
                            if (parsedParameter) {
                                const index = _.findIndex(parameters, ({ equationParameter }) => {
                                    return (
                                        equationParameter.toUpperCase() ===
                                        parsedParameter.toUpperCase()
                                    );
                                });
                                if (index === -1) {
                                    return {
                                        equationParameter: parsedParameter,
                                        value,
                                        parameterType,
                                    };
                                } else {
                                    return parameters[index];
                                }
                            }
                            return null;
                        }),
                        (attr) => attr.equationParameter
                    )) ||
                [];
            this.setState(
                {
                    parametersState: updatedParameters,
                },
                () => {
                    this.props.onTextChange(model.PROPS_PARAMETER_LIST, updatedParameters);
                }
            );
        } else {
            this.setState(
                {
                    parametersState: [],
                },
                () => {
                    this.props.onTextChange(model.PROPS_PARAMETER_LIST, []);
                }
            );
        }
    };

    parameterChange = ({ key = "", value = "" }) => {
        if (key) {
            const { parameters } = this.props;
            if (parameters && Array.isArray(parameters)) {
                const index = _.findIndex(parameters, (parameter) => {
                    return parameter.equationParameter.toUpperCase() === key.toUpperCase();
                });
                if (index > -1) {
                    parameters[index].value = value;
                    parameters[index].equationParameter = key;
                } else {
                    parameters.push({
                        equationParameter: key,
                        value,
                    });
                }
            }
            this.props.onTextChange(model.PROPS_PARAMETER_LIST, parameters);
        }
        this.props.updateTestButtonStatus();
    };

    renderParameterInput = (parameterType, equationParameter, value) => {
        const { formatMessage } = this.props;
        switch (parameterType) {
            case service.parameterTypes.yesNo:
                return (
                    <Checkbox
                        value={Boolean(value)}
                        onChange={(evt, value) =>
                            this.parameterChange({
                                key: equationParameter,
                                value,
                            })
                        }
                    />
                );
            case service.parameterTypes.text:
                return (
                    <TextInput
                        placeholderText={formatMessage(messages.parameterValue)}
                        labelText={formatMessage(messages.parameterValue)}
                        value={value}
                        onChange={(value) =>
                            this.parameterChange({
                                key: equationParameter,
                                value,
                            })
                        }
                    />
                );
            case service.parameterTypes.decimal:
                return (
                    <NumericInput
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.parameterValue)}
                        labelText={formatMessage(messages.parameterValue)}
                        value={value}
                        onChange={(value) =>
                            this.parameterChange({
                                key: equationParameter,
                                value,
                            })
                        }
                    />
                );
            default:
                return null;
        }
    };

    render() {
        const { formatMessage } = this.props;
        const { parametersState } = this.state;
        return (
            <Section className="grid-section" headerText={formatMessage(messages.parameter)}>
                {parametersState &&
                    parametersState.length > 0 &&
                    parametersState.map(({ equationParameter, value, parameterType }) => (
                        <SubSection key={equationParameter} className="parameter-section">
                            <div className="parameter-section-label">{equationParameter}</div>
                            {this.renderParameterInput(parameterType, equationParameter, value)}
                        </SubSection>
                    ))}
            </Section>
        );
    }
}
