import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "./../../i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, isChemicalType } from "./../data";
import {
    PICKLIST_CHEMICAL_FAMILY,
    PICKLIST_PRODUCT_ACTION_METHOD,
} from "~/core/picklist/picklist-names";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";

// Components
import { NumericInput, Section, SelectInput, SubSection, TextArea, TextInput } from "~/core";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import ActiveIngredientList from "./active-ingredient-list";
import { UNIT_PRODUCT_RATE_DRY, UNIT_PRODUCT_RATE_LIQUID } from "~/core/units/unit-names";

class ChemicalFertilizerInfo extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        product: CustomPropTypes.product,
        onPicklistChange: PropTypes.func,
        onTextChange: PropTypes.func,
        onNumberChange: PropTypes.func,
        productParentType: PropTypes.string,
    };

    static CHEMICAL_PRODUCT_TYPE = "1";
    static FERTILIZER_PRODUCT_TYPE = "2";

    constructor(props) {
        super(props);
        this.state = {
            [PICKLIST_CHEMICAL_FAMILY]: prepareSelectableOptions(props[PICKLIST_CHEMICAL_FAMILY], {
                guid: PICKLIST_GUID,
                label: VALUE,
                id: ID,
            }),
            [PICKLIST_PRODUCT_ACTION_METHOD]: prepareSelectableOptions(
                props[PICKLIST_PRODUCT_ACTION_METHOD],
                { guid: PICKLIST_GUID, label: VALUE, id: ID }
            ),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.product[model.PROPS_CHEMICAL_FAMILY_GUID] != null &&
            nextProps[PICKLIST_CHEMICAL_FAMILY]
        ) {
            this.setState({
                [PICKLIST_CHEMICAL_FAMILY]: prepareSelectableOptions(
                    nextProps[PICKLIST_CHEMICAL_FAMILY],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }

        if (
            nextProps.product[model.PROPS_PRODUCT_ACTION_METHOD_GUID] != null &&
            nextProps[PICKLIST_PRODUCT_ACTION_METHOD]
        ) {
            this.setState({
                [PICKLIST_PRODUCT_ACTION_METHOD]: prepareSelectableOptions(
                    nextProps[PICKLIST_PRODUCT_ACTION_METHOD],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { onNumberChange, productParentType, product } = this.props;

        const rateUnitList = prepareSelectableOptions(
            (this.props[UNIT_PRODUCT_RATE_DRY] || []).concat(
                this.props[UNIT_PRODUCT_RATE_LIQUID] || []
            ),
            { guid: GUID, label: NAME, id: ID }
        );

        return (
            <Section
                className="grid-section"
                headerText={productParentType && formatMessage(messages.chemicalFertilizerInfo)}
            >
                {!productParentType ? null : (
                    <ActiveIngredientList
                        formatMessage={formatMessage}
                        record={this.props.product[model.PROPS_INGREDIENT_LIST]}
                        itemListAlias={model.PROPS_INGREDIENT_LIST}
                        onTextChange={this.props.onTextChange}
                    />
                )}
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        initialFilterStr=""
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[PICKLIST_CHEMICAL_FAMILY]}
                        placeholderText={formatMessage(messages.chemicalFamily)}
                        value={mapToPicklistValue({
                            options: this.state[PICKLIST_CHEMICAL_FAMILY],
                            selectedGuid: product[model.PROPS_CHEMICAL_FAMILY_GUID],
                        })}
                        onChange={(value) => {
                            this.props.onPicklistChange(
                                {
                                    type: model.PROPS_CHEMICAL_FAMILY_NAME,
                                    guid: model.PROPS_CHEMICAL_FAMILY_GUID,
                                },
                                value
                            );
                        }}
                    />
                    {!isChemicalType(productParentType) ? null : (
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.modeOfAction)}
                            labelText={formatMessage(messages.modeOfAction)}
                            value={this.props.product[model.PROPS_MODE_OF_ACTION] || ""}
                            onChange={(value) =>
                                this.props.onTextChange(model.PROPS_MODE_OF_ACTION, value)
                            }
                        />
                    )}
                </SubSection>
                {!productParentType ? null : (
                    <SubSection className="form-section-child-stretch">
                        <TextArea
                            tabIndex={0}
                            placeholderText={formatMessage(messages.specialInstructions)}
                            value={this.props.product[model.PROPS_SPECIAL_INSTRUCTIONS]}
                            onChange={(value) =>
                                this.props.onTextChange(model.PROPS_SPECIAL_INSTRUCTIONS, value)
                            }
                        />
                    </SubSection>
                )}
                <SubSection className="form-section-child-stretch">
                    <TextArea
                        tabIndex={0}
                        maxLength={500}
                        placeholderText={formatMessage(messages.pestInjurySymptoms)}
                        value={this.props.product[model.PROPS_PEST_INJURY_SYMPTOMS]}
                        onChange={(value) =>
                            this.props.onTextChange(model.PROPS_PEST_INJURY_SYMPTOMS, value)
                        }
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        initialFilterStr=""
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[PICKLIST_PRODUCT_ACTION_METHOD]}
                        placeholderText={formatMessage(messages.productActionMethod)}
                        value={mapToPicklistValue({
                            options: this.state[PICKLIST_PRODUCT_ACTION_METHOD],
                            selectedGuid: product[model.PROPS_PRODUCT_ACTION_METHOD_GUID],
                        })}
                        onChange={(value) => {
                            this.props.onPicklistChange(
                                {
                                    type: model.PROPS_PRODUCT_ACTION_METHOD_NAME,
                                    guid: model.PROPS_PRODUCT_ACTION_METHOD_GUID,
                                },
                                value
                            );
                        }}
                    />
                    {!productParentType ? null : (
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={17}
                            suppressFormatting
                            placeholderText={"UFTRS Code"}
                            labelText={"UFTRS Code"}
                            value={product[model.PROPS_UFTRS_CODE]}
                            onChange={(value) => onNumberChange(model.PROPS_UFTRS_CODE, value)}
                        />
                    )}
                </SubSection>
                {!productParentType ? null : (
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={4}
                            precision={17}
                            placeholderText={"Standard Rate"}
                            labelText={"Standard Rate"}
                            value={product[model.PROPS_STANDARD_RATE]}
                            onChange={(value) => onNumberChange(model.PROPS_STANDARD_RATE, value)}
                        />
                        <SelectInput
                            tabIndex={0}
                            initialFilterStr=""
                            options={rateUnitList}
                            placeholderText={"Rate Unit"}
                            value={mapToPicklistValue({
                                options: rateUnitList,
                                selectedGuid: product[model.PROPS_STANDARD_RATE_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.props.onPicklistChange(
                                    {
                                        guid: model.PROPS_STANDARD_RATE_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                )}
            </Section>
        );
    }
}

export default injectIntl(ChemicalFertilizerInfo);
