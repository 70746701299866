import { createSelector } from "reselect";
import { getUser } from "~/login";
import { RECS_EVENTS_DATA_KEY, getZonesState, getMapToolsState } from "../selectors";
import { BATCH_TEMPLATE_FIELD_GUID } from "../model";
import * as models from "./model";
import { IRecsState } from "./reducer";
import { processingStatusCodes } from "~/action-panel/components/common/status-codes";
import { IAnalysisLayerSummaryItem } from "../analysis/model";

export const RECS_STATE_KEY = "RECS_STATE";
export const getModuleState = (state: unknown): IRecsState =>
    state[RECS_EVENTS_DATA_KEY][RECS_STATE_KEY];
export const getBatchToAnalysisLayerMap = (
    state: unknown
): Immutable.OrderedMap<string, IAnalysisLayerSummaryItem[]> =>
    getModuleState(state).batchToAnalysisLayerMap;
export const getClearedRecDetails = (state: unknown): any =>
    getModuleState(state).clearedRecDetails;
export const getFieldGuidToRecDetails = (state: unknown): any =>
    getModuleState(state).fieldGuidToRecDetails;
export const getFieldGuidHasDataSet = createSelector(
    (state) => getModuleState(state).fieldGuidToRecDetails,
    (state) => (fieldGuid) => getEnableSave(state, fieldGuid),
    (fieldGuidToRecDetails, getEnableSaveForFieldGuid) => {
        const fieldGuidList = [...fieldGuidToRecDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== BATCH_TEMPLATE_FIELD_GUID
        );
        if (
            fieldGuidToRecDetails.has(BATCH_TEMPLATE_FIELD_GUID) &&
            getEnableSaveForFieldGuid(BATCH_TEMPLATE_FIELD_GUID)
        ) {
            return new Set(fieldGuidList);
        }
        return new Set(fieldGuidList.filter((fieldGuid) => getEnableSaveForFieldGuid(fieldGuid)));
    }
);
export const getRecTypeInfoList = (state: unknown): models.RecTypeInfo[] =>
    getModuleState(state).recTypeInfoList;
export const getFieldGuidToRecListMap = (state: unknown): any =>
    getModuleState(state).fieldGuidToRecListMap;
export const getFieldGuidToInactiveRecListMap = (state: unknown): any =>
    getModuleState(state).fieldGuidToInactiveRecListMap;
export const getFieldGuidToFieldMap = (state: unknown): any =>
    getModuleState(state).fieldGuidToFieldMap;
export const getFieldGuidToInactiveFieldMap = (state: unknown): any =>
    getModuleState(state).fieldGuidToInactiveFieldMap;
export const getRecGeneralGuidToRecSummaryMap = createSelector(
    getFieldGuidToRecListMap,
    (fieldGuidToRecListMap) => {
        const recGeneralGuidToRecMap = new Map<string, models.RecSummary>();
        for (const recList of fieldGuidToRecListMap.values()) {
            for (const rec of recList) {
                recGeneralGuidToRecMap.set(rec.recGeneralGuid, rec);
            }
        }
        return recGeneralGuidToRecMap;
    }
);
export const getRecGeneralGuidToInactiveRecSummaryMap = createSelector(
    getFieldGuidToInactiveRecListMap,
    (fieldGuidToRecListMap) => {
        const recGeneralGuidToRecMap = new Map();
        for (const recList of fieldGuidToRecListMap.values()) {
            for (const rec of recList) {
                recGeneralGuidToRecMap.set(rec.recGeneralGuid, rec);
            }
        }
        return recGeneralGuidToRecMap;
    }
);

/** Determines if the current map tools are targeting an equation rec.
 * @param {object} state - The current application state.
 * @returns {boolean}
 */
export const getIsEquationRec = (state: unknown): any => {
    const { recEventDetails } = getMapToolsState(state).toolsetPayload;
    return (
        recEventDetails != null &&
        (recEventDetails.recType === models.REC_TYPE_NAME_EQUATION_APPLICATION ||
            recEventDetails.recType === models.REC_TYPE_NAME_EQUATION_PLANTING)
    );
};
/** Determines if the current map tools are targeting an *imported* rec.
 * @param {object} state - The current application state.
 * @returns {boolean}
 */
export const getIsImported = createSelector(
    (state) => getMapToolsState(state).toolsetPayload.recEventDetails,
    getRecGeneralGuidToRecSummaryMap,
    (recEventDetails, recGuidToRecSummaryMap) => {
        const recSummary = recGuidToRecSummaryMap.get(
            recEventDetails && recEventDetails.recGeneralGuid
        );
        return recSummary && recSummary.isImportedYn != null ? recSummary.isImportedYn : false;
    }
);
/** Determines if the current map tools are targeting a rec that is being processed.
 * @param {object} state - The current application state.
 * @returns {boolean}
 */
export const getIsEquationRecProcessing = createSelector(
    (state) => getMapToolsState(state).toolsetPayload.recEventDetails,
    getRecGeneralGuidToRecSummaryMap,
    getIsEquationRec,
    (recEventDetails, recGuidToRecSummaryMap, isEquationRec) => {
        const recSummary = recGuidToRecSummaryMap.get(
            recEventDetails && recEventDetails.recGeneralGuid
        );
        return isEquationRec && recSummary
            ? processingStatusCodes.includes(recSummary.importedStatus)
            : false;
    }
);
export const getExportRecSummaryArray = createSelector(
    (state, exportRecGuidList) => {
        const exportRecSummaries = [];
        exportRecGuidList.forEach((recGuid) => {
            const recSummary = getRecGeneralGuidToRecSummaryMap(state).get(recGuid);
            exportRecSummaries.push(recSummary);
        });
        return exportRecSummaries;
    },
    (exportRecSummaries) => exportRecSummaries
);

export const getRecTypeOptions = createSelector(getRecTypeInfoList, (recTypeInfoList) =>
    recTypeInfoList.map((recType) => ({
        label: recType.name,
        value: recType,
    }))
);
export const getNewableRecTypeInfoList = createSelector(
    getUser,
    getRecTypeInfoList,
    (user, recTypeInfoList) => {
        const { role } = user;
        return recTypeInfoList.filter((recType) => {
            return (
                models.NEWABLE_REC_TYPES.has(recType.name) &&
                models.NEWABLE_REC_TYPES.get(recType.name)(role)
            );
        });
    }
);
export const getNewableRecTypeOptions = createSelector(
    getNewableRecTypeInfoList,
    (recTypeInfoList) => {
        return recTypeInfoList.map((recType) => ({
            label: recType.name,
            value: recType,
        }));
    }
);
export const getCurrentRecArea = createSelector(
    (state, fieldGuid) => getZonesState(state).fieldGuidToCurrentAreaId.get(fieldGuid),
    (state, fieldGuid) => getModuleState(state).fieldGuidToRecDetails.get(fieldGuid),
    (recAreaId, recDetails) => {
        if (!recDetails || !recAreaId) {
            return;
        }
        return recDetails.recAreaList.find((recArea) => recArea.recAreaId === recAreaId);
    }
);
export const getEnableSave = createSelector(
    (state, fieldGuid) => getModuleState(state).fieldGuidToRecDetails.get(fieldGuid),
    (recDetails) => {
        /**
         * have at least 1 area where applyRecToArea is true
         * AND for all such areas:
         *   1. the polygons have not been updated or their geometry is defined
         *   2. every agEvent in the area has the required fields set
         */
        if (!recDetails) {
            return false;
        }
        const zoneGeometryDefined = (zone) =>
            zone.zonePolygons != null && zone.zonePolygons.every((p) => p.shape != null);
        const zonesWithData = recDetails.recAreaList.filter((zone) => zone.applyRecToArea);
        if (recDetails.fieldGuid === BATCH_TEMPLATE_FIELD_GUID) {
            return (
                zonesWithData.length > 0 &&
                zonesWithData.every((zone) =>
                    zone.recs.every((rec) => rec.isAllRequiredFieldsSet || !rec.isIncluded)
                )
            );
        } else {
            return (
                zonesWithData.length > 0 &&
                zonesWithData.every(
                    (zone) =>
                        zoneGeometryDefined(zone) &&
                        zone.recs.every(
                            (rec) =>
                                (rec.isAllRequiredFieldsSet || !rec.isIncluded) &&
                                (!rec.isRecDirty || rec.isAnyRecAdjustmentProcessing)
                        )
                )
            );
        }
    }
);
export const getLastRecBatchFieldGuid = (state: unknown): any => {
    return getModuleState(state).batchFieldGuid;
};
export const getRecAreaIdToHasDataMap = createSelector(
    (state, fieldGuid) => getModuleState(state).fieldGuidToRecDetails.get(fieldGuid),
    (recDetails) => {
        return (
            recDetails &&
            new Map(
                recDetails.recAreaList.map((recArea) => [recArea.recAreaId, recArea.applyRecToArea])
            )
        );
    }
);
//Need to be able to determine whether equation recs are ready to run in the saga rather than just in the rec-info
export const getRecEquationRunReady = createSelector(
    (state, fieldGuid) => getModuleState(state).fieldGuidToRecDetails.get(fieldGuid),
    (recDetails) => {
        /**
         * have at least 1 area where applyRecToArea is true
         * AND for all such areas:
         *   1. the polygons have not been updated or their geometry is defined
         *   2. every agEvent in the area has the required fields set
         */
        if (!recDetails) {
            return false;
        }
        const zoneGeometryDefined = (zone) =>
            zone.zonePolygons != null && zone.zonePolygons.every((p) => p.shape != null);
        const zonesWithData = recDetails.recAreaList.filter((zone) => zone.applyRecToArea);
        return (
            zonesWithData.length > 0 &&
            zonesWithData.every(
                (zone) =>
                    (recDetails.fieldGuid === BATCH_TEMPLATE_FIELD_GUID ||
                        zoneGeometryDefined(zone)) &&
                    zone.recs.every(
                        (rec) =>
                            (rec.isAllRequiredFieldsSet &&
                                rec.isEquationRunEnabled &&
                                rec.isRecDirty) ||
                            !rec.isIncluded
                    )
            )
        );
    }
);
