import { defineMessages } from "react-intl";

export const messages = defineMessages({
    doubleDripLine: {
        id: "eventModule.irrigationEvent.doubleDripLine",
        defaultMessage: "Double Drip Lines",
    },
    duration: {
        id: "eventModule.irrigationEvent.duration",
        defaultMessage: "Duration",
    },
    estimatedDeficitWaterNeed: {
        id: "eventModule.irrigationEvent.estimatedDeficitWaterNeed",
        defaultMessage: "Estimated Deficit Water Need",
    },
    estimatedWaterNeed: {
        id: "eventModule.irrigationEvent.estimatedWaterNeed",
        defaultMessage: "Estimated Water Need",
    },
    frequency: {
        id: "eventModule.irrigationEvent.frequency",
        defaultMessage: "Frequency",
    },
    installationDepth: {
        id: "eventModule.irrigationEvent.installationDepth",
        defaultMessage: "Installation Depth",
    },
    irrigationAmount: {
        id: "eventModule.irrigationEvent.irrigationAmount",
        defaultMessage: "Irrigation Amount",
    },
    irrigationFormLabelText: {
        id: "eventModule.irrigationEvent.irrigationFormLabelText",
        defaultMessage: "Irrigation",
    },
    irrigationType: {
        id: "eventModule.irrigationEvent.irrigationType",
        defaultMessage: "Irrigation Type",
    },
    irrigationWaterBudget: {
        id: "eventModule.irrigationEvent.irrigationWaterBudget",
        defaultMessage: "Irrigation Water Budget",
    },
    rainfallSinceLast: {
        id: "eventModule.irrigationEvent.rainfallSinceLast",
        defaultMessage: "Rainfall Since Last",
    },
    reductionPercentForDeficit: {
        id: "eventModule.irrigationEvent.reductionPercentForDeficit",
        defaultMessage: "Reduction % For Deficit",
    },
    subSurface: {
        id: "eventModule.irrigationEvent.subSurface",
        defaultMessage: "Subsurface",
    },
    timeSinceLast: {
        id: "eventModule.irrigationEvent.timeSinceLast",
        defaultMessage: "Time Since Last",
    },
    unit: { id: "eventModule.irrigationEvent.unit", defaultMessage: "Unit" },
    waterSource: {
        id: "eventModule.irrigationEvent.waterSource",
        defaultMessage: "Water Source",
    },
});
