import * as actions from "./actions";
import { IActionData, ILoginState, IReducerActions } from "./interfaces";

const { LoginState } = actions;

const initialState: ILoginState = {
    loginBackgroundUrl: null,
    theUser: null,
    theUsers: null,
    error: null,
    eula: null,
    filteredCustomers: [],
    imageryTileBucketName: null,
    isProcessing: false,
    loginGuid: null,
    loginState: LoginState.NONE,
    securityInfo: {
        email: null,
        question: null,
    },
    securityMessageCode: null,
    mobileAppDetails: {},
};

export function loginReducer(state = initialState, action: IActionData): IReducerActions {
    switch (action.type) {
        case actions.SET_LOGIN_BACKGROUND_URL:
            return {
                ...state,
                loginBackgroundUrl: action.url,
            };
        case actions.SET_SYSTEM_FAVICON_URL:
            return {
                ...state,
                systemFaviconUrl: action.url,
            };
        case actions.RESET_LOGIN:
            return {
                ...initialState,
                loginState: LoginState.LOGIN_FORM,
                loginBackgroundUrl: state.loginBackgroundUrl,
            };
        case actions.SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case actions.SET_EULA_INFO:
            return {
                ...state,
                eula: action.eula,
            };
        case actions.SET_FILTERED_CUSTOMERS:
            return {
                ...state,
                filteredCustomers: action.payload.filteredCustomers,
                lastUsedCustomerGuid: action.payload.lastUsedCustomerGuid,
            };
        case actions.SET_LOGIN_GUID:
            return {
                ...state,
                loginGuid: action.payload.loginGuid,
            };
        case actions.SET_LOGIN_STATE:
            return {
                ...state,
                loginState: action.loginState,
            };
        case actions.SET_IMAGERY_TILE_BUCKET_NAME: {
            const { bucketName } = action.payload;
            return Object.freeze({
                ...state,
                imageryTileBucketName: bucketName,
            });
        }
        case actions.SET_PROCESSING:
            return {
                ...state,
                isProcessing: action.isProcessing,
            };
        case actions.SET_MOBILE_APP_DETAILS:
            return {
                ...state,
                mobileAppDetails: action.mobileAppDetails,
            };
        case actions.SET_SECURITY_INFO:
            return {
                ...state,
                securityInfo: { ...action.securityInfo },
            };
        case actions.SET_SECURITY_MESSAGE:
            return {
                ...state,
                securityMessageCode: action.code,
                loginState: LoginState.SECURITY_MESSAGE,
                isProcessing: false,
            };
        case actions.SET_USER_INFO:
            return {
                ...state,
                theUser: Object.freeze(action.theUser),
            };
        case actions.SET_USERS_INFO:
            return {
                ...state,
                theUsers: Object.freeze(action.theUsers),
            };
        case actions.SET_LAYER_TYPES_ACCESS:
            return {
                ...state,
                theUser: {
                    ...state.theUser,
                    layerTypesAccess: action.layerTypesAccess,
                },
            };
        case actions.SET_RELEASE_NOTES: {
            return Object.freeze({
                ...state,
                releaseNotes: action.releaseNotes,
            });
        }
        default:
            return state;
    }
}
