export const BATCH_CONVERT_REC_TO_EVENT = "rec-info/BATCH_CONVERT_REC_TO_EVENT";
export const BATCH_UPDATE_PRODUCT_BLEND = "rec-info/BATCH_UPDATE_PRODUCT_BLEND";
export const CLEAR_EQUATION_GROUP_GUID = "rec-info/CLEAR_EQUATION_GROUP_GUID";
export const CLOSE_REC_INFO = "rec-info/CLOSE_REC_INFO";
export const COPY_REC_INFO = "rec-info/COPY_REC_INFO";
export const SET_BATCH_REC_DETAILS_FOR_EDIT = "rec-info/SET_BATCH_REC_DETAILS_FOR_EDIT";
export const SET_BATCH_REC_SUMMARIES_FOR_EDIT = "rec-info/SET_BATCH_REC_SUMMARIES_FOR_EDIT";
export const SET_GENERAL_ADJUSTMENTS = "rec-info/SET_GENERAL_ADJUSTMENTS";
export const SET_MANAGEMENT_AREA_LAYER_OPTIONS_MAP =
    "rec-info/SET_MANAGEMENT_AREA_LAYER_OPTIONS_MAP";
export const SET_PERMANENT_ADJUSTMENTS = "rec-info/SET_PERMANENT_ADJUSTMENTS";
export const SET_REC_SUMMARY = "rec-info/SET_REC_SUMMARY";
export const SAVE_REC_INFO = "rec-info/SAVE_REC_INFO";
export const SET_REC_DETAILS_LOADING = "rec-info/SET_REC_DETAILS_LOADING";
export const SET_REC_INFO_PICKLISTS = "rec-info/SET_REC_INFO_PICKLISTS";
export const SHOW_REC_INFO = "rec-info/SHOW_REC_INFO";
export const SET_ACTIVE_NUTRIENT = "rec-info/SET_ACTIVE_NUTRIENT";
export const SET_ACTIVE_EQUATION_GROUP = "rec-info/SET_ACTIVE_EQUATION_GROUP";
export const SET_EQUATION_GROUP_LIST = "rec-info/SET_EQUATION_GROUP_LIST";
export const SET_FILTERED_EQUATION_GROUP_LIST = "rec-info/SET_FILTERED_EQUATION_GROUP_LIST";
export const SET_EQUATION_FILTER_LISTS = "rec-info/SET_EQUATION_FILTER_LISTS";
export const SET_EQUATION_FILTER_REQUIRED = "rec-info/SET_EQUATION_FILTER_REQUIRED";
export const SET_EQUATIONS_LOADING = "rec-info/SET_EQUATIONS_LOADING";
export const SET_IS_PROCESSING_REC_NUTRIENT = "rec-info/SET_IS_PROCESSING_REC_NUTRIENT";
export const SET_REC_NUTRIENTS_IN_PROCESSING = "rec-info/SET_REC_NUTRIENTS_IN_PROCESSING";
export const REFRESH_EQUATION_FILTER_LISTS = "rec-info/REFRESH_EQUATION_FILTER_LISTS";
export const FETCHED_DROPDOWN_DATA = "rec-info/FETCHED_DROPDOWN_DATA";
export const SET_SAMPLING_DEPTH_LIST = "rec-info/SET_SAMPLING_DEPTH_LIST";
export const TOGGLE_BATCH_EDIT_RECS_MODAL = "rec-module/TOGGLE_BATCH_EDIT_RECS_MODAL";
export const UPDATE_REC_SEASON_DEPENDENCIES = "rec-info/UPDATE_REC_SEASON_DEPENDENCIES";

export const batchConvertRecToEvent = (agEventGeneralGuid, recGeneralGuid, fieldGuid) => ({
    type: BATCH_CONVERT_REC_TO_EVENT,
    payload: {
        agEventGeneralGuid,
        recGeneralGuid,
        fieldGuid,
    },
});

export const batchUpdateProductBlend = (productMix, previousMixGA) => ({
    type: BATCH_UPDATE_PRODUCT_BLEND,
    payload: {
        productMix,
        previousMixGA,
    },
});

export const clearEquationGroupGuid = () => ({
    type: CLEAR_EQUATION_GROUP_GUID,
});

export const closeRecInfo = (isCancel = false, isSaved = false) => ({
    type: CLOSE_REC_INFO,
    payload: { isCancel, isSaved },
});

export const copyRecInfo = (recSummary) => ({
    type: COPY_REC_INFO,
    payload: { recSummary },
});

export const fetchedDropdownData = (payload) => ({
    type: FETCHED_DROPDOWN_DATA,
    payload,
});

export const setRecSummary = (recSummary) => ({
    type: SET_REC_SUMMARY,
    payload: { recSummary },
});

export const saveRecInfo = () => ({
    type: SAVE_REC_INFO,
});

export const setActiveNutrient = (fieldGuid, nutrientGuid) => ({
    type: SET_ACTIVE_NUTRIENT,
    payload: { fieldGuid, nutrientGuid },
});

export const setActiveEquationGroup = (equationGroup, updatePreferences, recEventDetails) => ({
    type: SET_ACTIVE_EQUATION_GROUP,
    payload: { equationGroup, recEventDetails, updatePreferences },
});

export const setBatchRecDetailsForEdit = (batchRecDetailsForEdit) => ({
    type: SET_BATCH_REC_DETAILS_FOR_EDIT,
    payload: { batchRecDetailsForEdit },
});

export const setBatchRecSummariesForEdit = (batchRecSummariesForEdit) => ({
    type: SET_BATCH_REC_SUMMARIES_FOR_EDIT,
    payload: { batchRecSummariesForEdit },
});

export const setGeneralAdjustments = (generalAdjustments) => ({
    type: SET_GENERAL_ADJUSTMENTS,
    payload: { generalAdjustments },
});

export const setManagementAreaLayerOptionsMap = (managementAreaLayerOptionsMap) => ({
    type: SET_MANAGEMENT_AREA_LAYER_OPTIONS_MAP,
    payload: { managementAreaLayerOptionsMap },
});

export const setPermanentAdjustments = (permanentAdjustments) => ({
    type: SET_PERMANENT_ADJUSTMENTS,
    payload: { permanentAdjustments },
});

export const setRecDetailsLoading = (isLoading) => ({
    type: SET_REC_DETAILS_LOADING,
    payload: { isLoading },
});

export const setRecInfoPicklists = (recInfoPicklists) => ({
    type: SET_REC_INFO_PICKLISTS,
    payload: { recInfoPicklists },
});

export const showRecInfo = (recSummary) => ({
    type: SHOW_REC_INFO,
    payload: { recSummary },
});

export const setEquationGroupList = (equationGroupList) => ({
    type: SET_EQUATION_GROUP_LIST,
    payload: { equationGroupList },
});

export const setFilteredEquationGroupList = (filteredEquationGroupList) => ({
    type: SET_FILTERED_EQUATION_GROUP_LIST,
    payload: { filteredEquationGroupList },
});

export const refreshEquationFilterLists = (fieldGuid) => ({
    type: REFRESH_EQUATION_FILTER_LISTS,
    payload: { fieldGuid },
});

export const setEquationFilterLists = (equationFilterLists) => ({
    type: SET_EQUATION_FILTER_LISTS,
    payload: { equationFilterLists },
});

export const setEquationFilterRequired = (equationFilterRequired) => ({
    type: SET_EQUATION_FILTER_REQUIRED,
    payload: { equationFilterRequired },
});

export const setEquationsLoading = (isEquationsLoading) => ({
    type: SET_EQUATIONS_LOADING,
    payload: { isEquationsLoading },
});
export const setIsProcessingRecNutrient = (isProcessingRecNutrient) => ({
    type: SET_IS_PROCESSING_REC_NUTRIENT,
    payload: { isProcessingRecNutrient },
});
export const setRecNutrientsInProcessing = (recNutrientsInProcessing) => ({
    type: SET_REC_NUTRIENTS_IN_PROCESSING,
    payload: { recNutrientsInProcessing },
});

export const setSamplingDepthList = (samplingDepthList) => ({
    type: SET_SAMPLING_DEPTH_LIST,
    payload: { samplingDepthList },
});

export const toggleBatchEditRecsModal = (isBatchEditRecsModalActive) => ({
    type: TOGGLE_BATCH_EDIT_RECS_MODAL,
    payload: { isBatchEditRecsModalActive },
});

export const updateRecSeasonDependencies = (fieldGuid, seasonName) => ({
    type: UPDATE_REC_SEASON_DEPENDENCIES,
    payload: { fieldGuid, seasonName },
});
