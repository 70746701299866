export const infos = [
    {
        layerId: 0,
        layerName: "Counties",
        layerType: "Feature Layer",
        minScale: 2500000,
        maxScale: 0,
        legend: [
            {
                contentType: "image/png",
                height: 20,
                width: 20,
                imageData:
                    "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAHJJREFUOI3dVEEOwCAIw4Sf8q6+1V3G4oIsZCuX9UatFVNFhQwVEQEwGWZmNnQpUiGAsL7jrg4XwUhOfuQATK81KD+idOVXhizQDD2kvg6zZ1BFSPn/odAN6Sn3f70WQ8ZcvBnuZl7GZ5yeG8pzMOOdOwCbjzQUb4AXgwAAAABJRU5ErkJggg==",
                label: "",
                url: "815385d55b7a0790fed2ef912d042353",
            },
        ],
    },
    {
        layerId: 1,
        layerName: "Townships",
        layerType: "Feature Layer",
        minScale: 750000,
        maxScale: 0,
        legend: [
            {
                contentType: "image/png",
                height: 20,
                width: 20,
                imageData:
                    "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEhJREFUOI1jYWBgYGhqavrPQAVQV1fHyEItwxgYIA5joZZhMEB7A+vq6hhJMQA9yIagl0cNHDVwWBpIaXE2BLxcV1fHSM0qAAAQHhVbBnhYAgAAAABJRU5ErkJggg==",
                label: "",
                url: "8b5783a33742a0211e06effac16cc980",
            },
        ],
    },
    {
        layerId: 2,
        layerName: "Sections",
        layerType: "Feature Layer",
        minScale: 500000,
        maxScale: 0,
        legend: [
            {
                contentType: "image/png",
                height: 20,
                width: 20,
                imageData:
                    "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAENJREFUOI1jYaAyYGFgYGCYO3fuf2oYlpyczMhCDYOQAYqBycnJjOQYguxD2rpw1MBRA0cNHB4GUqNcpI0LyS0HsQEAOwgMUbm5euEAAAAASUVORK5CYII=",
                label: "",
                url: "6aaa536232381f665d4a0069e6c199bd",
            },
        ],
    },
];
