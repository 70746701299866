export class ScoutingTemplateCrop {
    eventScoutingTemplateCropGuid: string = null;
    eventScoutingTemplateGuid: string = null;
    showBrandOrganization = false;
    brandOrganizationGuid: string = null;
    showVarietyHybrid = false;
    varietyHybridGuid: string = null;
    showCropGrowthStage = false;
    cropGrowthStageGuid: string = null;
    showCropCondition = false;
    cropConditionGuid: string = null;
    showCropInjuryRating = false;
    cropInjuryRatingGuid: string = null;
    showDaysAfterCropDamage = false;
    daysAfterCropDamage: number | null = null;
    showCropHeight = false;
    cropHeight: number | null = null;
    cropHeightUnitGuid: string = null;
    showCropPopulation = false;
    cropPopulation: number | null = null;
    cropPopulationUnitGuid: string = null;
    showYieldPercentLoss = false;
    yieldPercentLoss: number | null = null;
    showStandPercentLoss = false;
    standPercentLoss: number | null = null;
    showLeafPercentLoss = false;
    leafPercentLoss: number | null = null;

    static fromJsonObj(jsonObj: Partial<ScoutingTemplateCrop>): ScoutingTemplateCrop {
        return Object.assign(new ScoutingTemplateCrop(), jsonObj);
    }
}
