import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "~/core";
import "./field-information-button-bar.css";

export class FieldInformationButtonBar extends React.Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        saveIsEnabled: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };
    static defaultProps = {};
    /// ----------------------------------------- ///

    /// Event Handlers ///

    /// ------------- ///

    render() {
        return (
            <div className={classNames("information-button-bar", "field-information-button-bar")}>
                <Button
                    type="save"
                    disabled={!this.props.saveIsEnabled}
                    onClick={() => {
                        this.props.onSave();
                    }}
                />
                <Button
                    type="cancel"
                    onClick={() => {
                        this.props.onCancel();
                    }}
                />
            </div>
        );
    }
}
