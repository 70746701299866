import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ZoneLoadIcon = withSvgIcon(
    <g>
        <path
            d={
                "M403,252.5V57.6H231.8v-40H213v40H39v364h174v38.8h18.8v-38.8h26.6C277,467.6,322," +
                "500,374.6,500c69.2,0,125.4-56.1,125.4-125.4C500,315.1,458.6,265.3,403,252.5z M59," +
                "401.6v-324h324v171.9c-2.8-0.2-5.6-0.3-8.4-0.3c-69.2,0-125.4,56.1-125.4,125.4c0,9.3,1," +
                "18.3,2.9,27H59z M374.6,478c-57,0-103.4-46.4-103.4-103.4s46.4-103.4,103.4-103.4S478," +
                "317.6,478,374.6S431.6,478,374.6,478z"
            }
        />
        <polygon
            points={
                "385.6,389.1 385.6,310.3 363.6,310.3 363.6,389.1 345.3,365.6 328,379.1 374.6," +
                "438.9 421.3,379.1 404,365.6"
            }
        />
        <rect x="213" y="172" width="18.8" height="57.8" />
        <rect x="213" y="249" width="18.8" height="57.8" />
        <rect x="213" y="345" width="18.8" height="38.8" />
        <rect x="213" y="94.6" width="18.8" height="39.1" />
    </g>,
    "-20 30 470 470"
);
