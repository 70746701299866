import { createSelector } from "reselect";
import * as models from "./model";
// possible answer for function return [models.AccordionItem, models.MultiDimIdx]
const _accordionItemFromFlatIdx = (
    rootItemList: models.AccordionItem[],
    recItemCount: number,
    flatIdx: models.FlatIdx
): [models.AccordionItem, models.MultiDimIdx] => {
    if (typeof flatIdx !== "number" || flatIdx < 0 || flatIdx >= recItemCount) {
        throw new ReferenceError(`Invalid \`flatIdx\` (${flatIdx})`);
    }

    return models.itemFromFlatIdx(rootItemList, flatIdx);
};

const _itemCountSelector = (state: models.IAccordionState): number => state.recItemCount;
const _itemsSelector = (state: models.IAccordionState): models.AccordionItem[] => state.items;

export const dimIdxMapSelector = createSelector(_itemsSelector, (rootItemList) => {
    const memoized = new Map<models.MultiDimIdx, models.AccordionItem>();
    return (dimIdx: models.MultiDimIdx) => {
        if (memoized.has(dimIdx)) {
            if ("memoizedCount" in forTest) {
                forTest.memoizedCount++;
            }
            return memoized.get(dimIdx);
        }
        memoized.set(dimIdx, models.getItem(rootItemList, dimIdx));
        return memoized.get(dimIdx);
    };
});

export const flatIdxMapSelector = createSelector(
    _itemsSelector,
    _itemCountSelector,
    (rootItemList, recItemCount) => {
        const memoized = new Map<models.FlatIdx, [models.AccordionItem, models.MultiDimIdx]>();
        return (flatIdx: models.FlatIdx) => {
            if (memoized.has(flatIdx)) {
                if ("memoizedCount" in forTest) {
                    forTest.memoizedCount++;
                }
                return memoized.get(flatIdx);
            }
            memoized.set(flatIdx, _accordionItemFromFlatIdx(rootItemList, recItemCount, flatIdx));
            return memoized.get(flatIdx);
        };
    }
);

export const forTest: any = {
    _accordionItemFromFlatIdx,
};

export const itemCountSelector = _itemCountSelector;
export const itemsSelector = _itemsSelector;
