import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_AGGATEWAY_ID = "agGatewayId";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CLASS_SIZE = "classSize";
export const PROPS_CLASS_SIZE_GUID = "classSizeGuid";
export const PROPS_CONTAINER_SIZE = "containerSize";
export const PROPS_CONTAINER_SIZE_UNIT = "containerSizeUnit";
export const PROPS_CONTAINER_SIZE_UNIT_GUID = "containerSizeUnitGuid";
export const PROPS_CYLINDERS = "cylinders";
export const PROPS_DESCRIPTION = "description";
export const PROPS_DIMENSION_UNIT = "dimensionUnit";
export const PROPS_DIMENSION_UNIT_GUID = "dimensionUnitGuid";
export const PROPS_DRIVE_TRAIN = "driveTrain";
export const PROPS_DRIVE_TRAIN_GUID = "driveTrainGuid";
export const PROPS_ENGINE_DISPLACEMENT = "engineDisplacement";
export const PROPS_EQUIPMENT_GUID = "equipmentGuid";
export const PROPS_EQUIPMENT_ID = "equipmentId";
export const PROPS_EQUIPMENT_MANUFACTURER = "manufacturer";
export const PROPS_EQUIPMENT_NAME = "equipmentName";
export const PROPS_EQUIPMENT_PARENT_TYPE = "equipmentParentType";
export const PROPS_EQUIPMENT_PARENT_TYPE_GUID = "equipmentParentTypeGuid";
export const PROPS_EQUIPMENT_PHOTOLIST = "equipmentPhotoList";
export const PROPS_EQUIPMENT_TYPE = "equipmentType";
export const PROPS_EQUIPMENT_TYPE_ALIASLIST = "equipmentTypeAliasList";
export const PROPS_EQUIPMENT_TYPE_GUID = "equipmentTypeGuid";
export const PROPS_FUEL_CAPACITY = "fuelCapacity";
export const PROPS_FUEL_CAPACITY_UNIT = "fuelCapacityUnit";
export const PROPS_FUEL_CAPACITY_UNIT_GUID = "fuelCapacityUnitGuid";
export const PROPS_FUEL_EFFICIENCY = "fuelEfficiency";
export const PROPS_FUEL_EFFICIENCY_UNIT = "fuelEfficiencyUnit";
export const PROPS_FUEL_EFFICIENCY_UNIT_GUID = "fuelEfficiencyUnitGuid";
export const PROPS_FUEL_TYPE = "fuelType";
export const PROPS_FUEL_TYPE_GUID = "fuelTypeGuid";
export const PROPS_HEIGHT = "height";
export const PROPS_HORSE_POWER = "horsePower";
export const PROPS_HORSE_POWER_UNIT = "horsePowerUnit";
export const PROPS_HORSE_POWER_UNIT_GUID = "horsePowerUnitGuid";
export const PROPS_LENGTH = "length";
export const PROPS_MANUFACTURER = "manufacturer";
export const PROPS_MANUFACTURER_GUID = "manufacturerGuid";
export const PROPS_MAX_GROSS_WEIGHT = "maxGrossWeight";
export const PROPS_MAX_SPEED = "maxSpeed";
export const PROPS_MAX_SPEED_UNIT = "maxSpeedUnit";
export const PROPS_MAX_SPEED_UNIT_GUID = "maxSpeedUnitGuid";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_PHYSICAL_STATE_GUID = "physicalStateGuid";
export const PROPS_TARE_WEIGHT = "tareWeight";
export const PROPS_TOWING_CAPACITY = "towingCapacity";
export const PROPS_TOWING_CAPACITY_UNIT = "towingCapacityUnit";
export const PROPS_TOWING_CAPACITY_UNIT_GUID = "towingCapacityUnitGuid";
export const PROPS_TRANSMISSION_TYPE = "transmissionType";
export const PROPS_TRANSMISSION_TYPE_GUID = "transmissionTypeGuid";
export const PROPS_WEIGHT_UNIT = "weightUnit";
export const PROPS_WEIGHT_UNIT_GUID = "weightUnitGuid";
export const PROPS_WIDTH = "width";
