import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { SelectInput, Checkbox, RadioButtonGroup, RadioButton, withIgnoreUnderScore } from "~/core";
import { constants, service, model } from "./../../data";
import { adminData } from "~/admin/data";
import { messages } from "./../../../i18n-messages";
import { TemplateManagerRows } from "./template-manager-rows";

export class TemplateManagerGrid extends Component {
    static propTypes = {
        columns: PropTypes.array,
        currentTab: PropTypes.number,
        data: PropTypes.array,
        fileMetadata: PropTypes.object,
        formatMessage: PropTypes.func,
        importAttributes: PropTypes.array,
        sampleAttributes: PropTypes.array,
        updateImportAttributes: PropTypes.func,
        updateImportTemplateList: PropTypes.func,
    };

    static SAMPLE_ATTRIBUTE_GUID = "sampleAttributeGuid";
    static SAMPLE_ATTRIBUTE_NAME = "sampleAttributeName";

    constructor(props) {
        super(props);
        this.state = {
            sampleAttributes:
                this.prepareSelectableOptions(props.sampleAttributes, {
                    guid: TemplateManagerGrid.SAMPLE_ATTRIBUTE_GUID,
                    label: TemplateManagerGrid.SAMPLE_ATTRIBUTE_NAME,
                }) || [],
            selectedSampleAttributes: props.importAttributes || [],
            showMappedAttributes: false,
            isMonitorType: false,
            headerRow: Boolean(props.fileMetadata[model.PROPS_HEADER_ROW]),
            matchHeaderName: Boolean(props.fileMetadata[model.PROPS_MATCH_HEADER_NAME]),
        };
        this.NewSelectInput = withIgnoreUnderScore(SelectInput);
        this.isShapeFile = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data) ||
            nextProps.sampleAttributes !== this.props.sampleAttributes ||
            nextProps.importAttributes !== this.props.importAttributes ||
            nextState.showMappedAttributes !== this.state.showMappedAttributes ||
            nextState.headerRow !== this.state.headerRow ||
            nextState.matchHeaderName !== this.state.matchHeaderName
        ) {
            return true;
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initializeSampleAttributes(nextProps);

        if (!_.isEqual(nextProps.importAttributes, this.props.importAttributes)) {
            this.setState({
                selectedSampleAttributes: nextProps.importAttributes,
            });
        }
        if (nextProps.fileMetadata !== this.props.fileMetadata) {
            if (this.isShapeFileFormat(nextProps.fileMetadata.fileTypes)) {
                this.isShapeFile = false;
            }
            this.setState({
                headerRow: Boolean(nextProps.fileMetadata[model.PROPS_HEADER_ROW]),
                matchHeaderName: Boolean(nextProps.fileMetadata[model.PROPS_MATCH_HEADER_NAME]),
            });
        }
    }

    prepareSelectableOptions = (options, { guid, label }) =>
        _.reduce(
            options,
            (result, option) => {
                result.push({ value: option[guid], label: option[label] });
                return result;
            },
            []
        );

    initializeSampleAttributes = (nextProps) => {
        if (nextProps.sampleAttributes !== this.props.sampleAttributes) {
            this.setState({
                [constants.SAMPLE_ATTRIBUTES]: this.prepareSelectableOptions(
                    nextProps.sampleAttributes,
                    {
                        guid: TemplateManagerGrid.SAMPLE_ATTRIBUTE_GUID,
                        label: TemplateManagerGrid.SAMPLE_ATTRIBUTE_NAME,
                    }
                ),
            });
        }
    };

    onSampleAttributeChange = (value, importFileColumnName, columnOrderInFile) => {
        this.props.updateImportAttributes({
            importTemplateListIndex: this.props.currentTab,
            value: {
                importAttributeGuid: value || "",
                columnNameInFile: importFileColumnName,
                columnOrderInFile,
                isShapeFile: this.isShapeFile,
            },
        });
    };

    isShapeFileFormat(fileTypes) {
        const textFileFormats = [".txt", ".csv"];
        return textFileFormats.indexOf(fileTypes) > -1;
    }

    getSelectableOptions = (value) => {
        const selectedSampleAttributes = this.props.importAttributes;
        const sampleAttributes = this.state.sampleAttributes.slice();
        const propToBeCompared = this.isShapeFile
            ? model.PROPS_COLUMN_NAME_IN_FILE
            : model.PROPS_COLUMN_ORDER_IN_FILE;
        _.remove(sampleAttributes, (attr) => {
            for (let key in selectedSampleAttributes) {
                if (
                    value !== selectedSampleAttributes[key][propToBeCompared] &&
                    attr.value === selectedSampleAttributes[key].importAttributeGuid
                ) {
                    return true;
                }
            }
            return false;
        });
        return sampleAttributes;
    };

    getImportAttributeValue = (importFileColumnName, orderInFile) => {
        const importAttribute = this.props.importAttributes.find((attr) => {
            return this.isShapeFile
                ? attr.columnNameInFile === importFileColumnName
                : attr.columnOrderInFile === orderInFile;
        });
        return importAttribute ? importAttribute.importAttributeGuid : null;
    };

    onRadioButtonChange = (value) => {
        this.setState({
            showMappedAttributes: value === "showMapped",
        });
    };

    renderAttributes = () => {
        const columns = this.getFilteredColumns(this.props.columns);
        const { NewSelectInput } = this;
        return (
            columns &&
            columns.map(({ importFileColumnName, orderInFile }, index) => {
                const displayCount = index + 1;
                return (
                    <div
                        className="import-attribute-cont"
                        key={`${importFileColumnName}-${displayCount}`}
                    >
                        <span className="counter-cont">{displayCount}</span>
                        <NewSelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            value={this.getImportAttributeValue(importFileColumnName, orderInFile)}
                            options={this.getSelectableOptions(
                                this.isShapeFile ? importFileColumnName : orderInFile
                            )}
                            onChange={(value) =>
                                this.onSampleAttributeChange(
                                    value,
                                    importFileColumnName,
                                    orderInFile,
                                    this.isShapeFile
                                )
                            }
                            placeholderText={""}
                        />
                    </div>
                );
            })
        );
    };

    getFilteredColumns = (data) => {
        const { selectedSampleAttributes, showMappedAttributes } = this.state;
        if (showMappedAttributes) {
            const newData =
                data &&
                data.filter(({ importFileColumnName }) => {
                    for (let key in selectedSampleAttributes) {
                        if (
                            selectedSampleAttributes[key].columnNameInFile === importFileColumnName
                        ) {
                            return true;
                        }
                    }
                    return false;
                });
            return newData;
        } else {
            return data;
        }
    };

    isHeaderRowDisabled = () => {
        const { fileMetadata } = this.props;
        return service.isTextFileFormat(fileMetadata.fileTypes);
    };

    onChangeHeaderRow = (event, value) => {
        this.setState(
            {
                headerRow: value,
            },
            () =>
                this.props.updateImportTemplateList({
                    key: model.PROPS_HEADER_ROW,
                    value: value ? 1 : 0,
                })
        );
    };

    isMatchHeaderNameDisabled = () => {
        const { fileMetadata } = this.props;
        return service.isTextFileFormat(fileMetadata.fileTypes);
    };

    onChangeMatchHeaderName = (event, value) => {
        this.setState(
            {
                matchHeaderName: value,
            },
            () =>
                this.props.updateImportTemplateList({
                    key: model.PROPS_MATCH_HEADER_NAME,
                    value: value ? 1 : 0,
                })
        );
    };

    render() {
        const { formatMessage, data } = this.props;
        const { showMappedAttributes } = this.state;
        return (
            <div className="template-manager-grid form-section">
                {data && data.length > 0 && (
                    <div className="grid-header">
                        <Checkbox
                            label={formatMessage(messages.headerRow)}
                            disabled={this.isHeaderRowDisabled()}
                            value={this.state.headerRow}
                            onChange={this.onChangeHeaderRow}
                        />
                        <Checkbox
                            label={formatMessage(messages.matchHeaderName)}
                            disabled={this.isMatchHeaderNameDisabled()}
                            visible={this.state.headerRow}
                            value={this.state.matchHeaderName}
                            onChange={this.onChangeMatchHeaderName}
                        />
                        <RadioButtonGroup
                            className={"grid-header-radio-group"}
                            selectedValue={showMappedAttributes ? "showMapped" : "showAll"}
                            afterOnChange={this.onRadioButtonChange}
                        >
                            <RadioButton
                                tabIndex={0}
                                value={"showAll"}
                                label={formatMessage(messages.showAll)}
                            />
                            <RadioButton
                                tabIndex={0}
                                value={"showMapped"}
                                label={formatMessage(messages.showMapped)}
                            />
                        </RadioButtonGroup>
                    </div>
                )}
                <div className="template-manager-grid-container">
                    <div className="template-manager-grid-table">
                        <div className="template-manager-attributes">{this.renderAttributes()}</div>
                        <div className="template-manager-rows">
                            <TemplateManagerRows
                                data={this.props.data}
                                headerRow={this.state.headerRow}
                                selectedSampleAttributes={this.state.selectedSampleAttributes}
                                showMappedAttributes={this.state.showMappedAttributes}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
