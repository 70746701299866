import * as model from "./model";
import { createService } from "~/hocs/crud";
import { aggregateUrlGenerator } from "@ai360/core";

const AGGREGATE_NAME = "nutrient";
const ENDPOINT_NAME = "agbytes/nutrient";
const API_VERSION = "v1.0";

const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const EXPORT_FILE_NAME = "NutrientExport";
export const URL = aggregateUrl("list");
export const ADD_URL = aggregateUrl();
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const SELECT_ALL = aggregateUrl("selectAll");
// Request payload
export const REQUEST_PAYLOAD_FILTER = "nutrientFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "nutrientSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "nutrientPageOptions";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        nutrientId: "",
        nutrientName: "",
        element: "",
        oxideBasis: "",
        equivalentWeight: "",
        ncdaFactor: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
};

export const defaultSort = {
    FieldName: "NutrientName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const service = createService({
    id: model.PROPS_NUTRIENT_ID,
    guid: model.PROPS_NUTRIENT_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    urls: {
        URL,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
    },
    _defaultLabels: {
        [model.PROPS_NUTRIENT_ID]: { label: "nutrientId", gridCol: 10 },
        [model.PROPS_NUTRIENT_NAME]: { label: "nutrientName", gridCol: 20 },
        [model.PROPS_ELEMENT]: { label: "element", gridCol: 20 },
        [model.PROPS_OXIDE_BASIS]: { label: "oxideBasis", gridCol: 10 },
        [model.PROPS_EQUIVALENT_WEIGHT]: {
            label: "equivalentWeight",
            gridCol: 10,
        },
        [model.PROPS_NCDA_FACTOR]: { label: "ncdaFactor" },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            className: "col-shift-15",
            visible: false,
            sortNameOverRide: "isActive",
        },
    },
    defaultSort,
    useNewApi: true,
});
