import * as model from "./model";
import { defaultValue } from "../../component/address/default-records";

const customerDefaultRecord = () => ({
    [model.ACTIVE_YN]: true,
    [model.PROPS_CUSTOMER_ADDRESS_LIST]: [defaultValue()],
    [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: [],
    [model.PROPS_CUSTOMER_ALIAS_NAME]: null,
    [model.PROPS_CUSTOMER_EQUIPMENT_LIST]: [],
    [model.PROPS_CUSTOMER_GUID]: "",
    [model.PROPS_CUSTOMER_ORG_LEVEL_LIST]: [],
    [model.PROPS_DATA_POOLING]: false,
    [model.PROPS_NAME]: "",
    [model.PROPS_NOTES]: "",
});

export default customerDefaultRecord;
