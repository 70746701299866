import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { model } from "./../data";
import {
    TemplateManagerGrid,
    TemplateManagerManualGrid,
    FileMetadata,
    TemplateTabs,
} from "./components";
import { messages as globalMessages } from "~/i18n-messages";
import { SUCCESS, FAILURE } from "~/hocs/needs/utils";
import { APIError } from "@ai360/core";
import { ErrorDialog } from "~/notifications/components/err-dialog";
import { keywords as adminKeywords } from "~/admin/containers/keywords";

export class AddEditPanel extends Component {
    static propTypes = {
        actions: PropTypes.object,
        addEditPanel: PropTypes.object.isRequired,
        apiError: PropTypes.object,
        applicationSampleAttributes: PropTypes.array,
        currentTab: PropTypes.number,
        changeMode: PropTypes.func,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fileMetadata: PropTypes.object,
        getImportControllerTemplate: PropTypes.func,
        getImportTemplate: PropTypes.func,
        gridData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        harvestSampleAttributes: PropTypes.array,
        importSampleTemplates: PropTypes.array,
        intl: intlShape.isRequired,
        isMonitorType: PropTypes.bool,
        liftCopyRecord: PropTypes.bool,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        newCopyTemplateName: PropTypes.string,
        plantingSampleAttributes: PropTypes.array,
        recordGuid: PropTypes.object,
        requestIds: PropTypes.object,
        resetTemplate: PropTypes.func,
        sampleAttributes: PropTypes.array,
        setBreadcrumbs: PropTypes.func,
        setCurrentTab: PropTypes.func,
        setIsMonitorFileFlag: PropTypes.func,
        templateData: PropTypes.array,
        toggleCopyRequest: PropTypes.func,
        toggleFetchData: PropTypes.func,
        updateImportAttributes: PropTypes.func,
        updateManualImportAttributes: PropTypes.func,
        updateImportTemplateList: PropTypes.func,
        updateTemplate: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            renderErrorDialog: false,
        };
        this.copyRequestId = null;
    }

    _filterSampleAttributes = () => {
        const { currentTab, importSampleTemplates, sampleAttributes, templateData } = this.props;
        const nameMatchCentroid = importSampleTemplates
            ? importSampleTemplates.find((temp) => temp.name === "Name Match Centroid") || {}
            : {};
        const isNameMatchCentroid =
            nameMatchCentroid.importSampleTemplateGuid &&
            templateData[currentTab].importSampleTemplateGuid ===
                nameMatchCentroid.importSampleTemplateGuid;
        if (isNameMatchCentroid && sampleAttributes) {
            const ids = ["Event_ID", "Sample_ID"];
            return sampleAttributes.filter((att) => !ids.includes(att.sampleAttributeName));
        }
        return sampleAttributes;
    };

    _toggleErrorDialog = (renderErrorDialog) => this.setState({ renderErrorDialog });

    _renderErrorDialog = () => {
        return (
            <ErrorDialog
                err={new APIError(this.props.apiError)}
                intl={this.props.intl}
                retryAction={null}
                onRetry={() => null}
                onClose={() => this._toggleErrorDialog(false)}
            />
        );
    };

    copyImportTemplate = (nextProps) => {
        if (nextProps.requestIds && this.copyRequestId) {
            if (nextProps.requestIds[this.copyRequestId] === SUCCESS) {
                this.props.toggleCopyRequest(SUCCESS);
                this.copyRequestId = null;
            } else if (nextProps.requestIds[this.copyRequestId] === FAILURE) {
                if (nextProps.apiError === this.props.apiError) {
                    this._toggleErrorDialog(true);
                }
                this.props.toggleCopyRequest(FAILURE);
                this.copyRequestId = null;
            }
        } else {
            this.props.updateImportTemplateList({
                key: model.PROPS_TEMPLATE_GUID,
                value: null,
            });
            this.props.updateImportTemplateList({
                key: model.PROPS_TEMPLATE_NAME,
                value: nextProps.newCopyTemplateName,
            });
            this.props.updateTemplate({
                key: model.PROPS_TEMPLATE_NAME,
                value: nextProps.newCopyTemplateName,
            });

            this.props.changeMode(adminKeywords.ADD);
            this.props.toggleCopyRequest(SUCCESS);
        }
    };

    componentDidMount() {
        const { needs } = this.props;
        if (this.props.recordGuid) {
            const { guid, isControllerTemplate } = this.props.recordGuid;
            if (isControllerTemplate) {
                this.props.setIsMonitorFileFlag(true);
                needs([this.props.getImportControllerTemplate(guid)]);
            } else {
                this.props.setIsMonitorFileFlag(false);
                needs([this.props.getImportTemplate(guid)]);
            }
            this.props.setBreadcrumbs([globalMessages.edit]);
        } else {
            this.props.setBreadcrumbs([globalMessages.add]);
        }
        this.props.needs([this.props.fetchDropdownData()]);
        this.props.resetTemplate();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(nextProps.fileMetadata);
            this.props.toggleFetchData(false);
        }
        if (nextProps.liftCopyRecord) {
            this.copyImportTemplate(nextProps);
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const {
            addEditPanel,
            applicationSampleAttributes,
            currentTab,
            gridData,
            harvestSampleAttributes,
            isMonitorType,
            needs,
            plantingSampleAttributes,
            recordGuid,
            sampleAttributes,
            setCurrentTab,
            templateData,
            updateImportAttributes,
            updateManualImportAttributes,
            updateImportTemplateList,
        } = this.props;
        const { renderErrorDialog } = this.state;
        const isSampling = templateData[currentTab].className === "SamplingTemplate";
        const filteredSampleAttributes = isSampling
            ? this._filterSampleAttributes()
            : sampleAttributes;
        return (
            <div className="section-container template-manager-cont">
                <FileMetadata
                    formatMessage={formatMessage}
                    mode={addEditPanel.mode}
                    needs={needs}
                    onImportAttributeChange={this.onImportAttributeChange}
                    onImportSampleTemplatesChange={this.onImportSampleTemplatesChange}
                    templateGuid={recordGuid ? recordGuid.guid : ""}
                />
                {renderErrorDialog ? this._renderErrorDialog() : null}
                {isMonitorType ? (
                    <TemplateTabs
                        applicationSampleAttributes={applicationSampleAttributes}
                        currentTab={currentTab}
                        data={gridData}
                        fileMetadata={templateData}
                        formatMessage={formatMessage}
                        harvestSampleAttributes={harvestSampleAttributes}
                        importAttributes={templateData[model.PROPS_IMPORT_ATTRIBUTES] || []}
                        plantingSampleAttributes={plantingSampleAttributes}
                        sampleAttributes={sampleAttributes}
                        setCurrentTab={setCurrentTab}
                        updateImportAttributes={updateImportAttributes}
                        updateImportTemplateList={updateImportTemplateList}
                    />
                ) : templateData[currentTab][model.PROPS_MANUAL_ONLY] ? (
                    <TemplateManagerManualGrid
                        importAttributes={templateData[currentTab][model.PROPS_IMPORT_ATTRIBUTES]}
                        sampleAttributes={filteredSampleAttributes}
                        updateManualImportAttributes={updateManualImportAttributes}
                    />
                ) : (
                    <TemplateManagerGrid
                        columns={gridData.standardColumnHeaders}
                        data={gridData.standardGridData}
                        fileMetadata={templateData[currentTab]}
                        formatMessage={formatMessage}
                        importAttributes={templateData[currentTab][model.PROPS_IMPORT_ATTRIBUTES]}
                        sampleAttributes={filteredSampleAttributes}
                        updateImportAttributes={updateImportAttributes}
                        updateImportTemplateList={updateImportTemplateList}
                    />
                )}
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
