import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const SplitTwo = withSvgIcon(
    [
        <circle
            key="c"
            cx="12"
            cy="12"
            r="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />,
        <line
            key="ln1"
            x1="12"
            y1="2"
            x2="12"
            y2="22"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />,
    ],
    "0 0 24 24",
    "inverted-svg-icon"
);
