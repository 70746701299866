import { connect } from "react-redux";
import { fetchLocationHierarchy, fetchLocationHierarchyEntities } from "./../data/actions";
import { getFilteredLocations, getLocationEntities } from "./../data/selectors";
import LocationEntityView from "./location-entity";

const mapDispatchToProps = (dispatch) => ({
    getLocationEntities: (model, loggedInUserGuid) =>
        fetchLocationHierarchyEntities({ model, loggedInUserGuid }),
    searchLocationHierarchy: (searchedVal, companyUserGuid) =>
        dispatch(
            fetchLocationHierarchy({
                searchedVal,
                companyUserGuid,
            })
        ),
});

const mapStateToProps = (state, ownProps) => ({
    locationEntities: getLocationEntities(state),
    filteredLocations: getFilteredLocations(state),
    ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationEntityView);
