import { defineMessages } from "react-intl";

export const messages = defineMessages({
    dialogTitle: {
        id: "fieldResurfacePrompt.dialogTitle",
        defaultMessage: "Resurfacing Options",
    },
    prompt: {
        id: "fieldResurfacePrompt.prompt",
        defaultMessage:
            "Would you like to resurface all of your " +
            "existing imported data on this field to this new boundary edit?",
    },
    inactivePrompt: {
        id: "fieldResurfacePrompt.inactivePrompt",
        defaultMessage:
            "A boundary edit was made during a previous Field save on this field and the " +
            "resurfacing of imported data was not executed.  Would you now like to resurface all imported " +
            "data to that previous boundary edit?",
    },
    standardInterpolationMessage: {
        id: "fieldResurfacePrompt.standardInterpolationMessage",
        defaultMessage:
            "The following is a list of imported Events for this field where you can individually " +
            "deselect any Events you do not want to resurface:",
    },
    zoneInterpolationMessage: {
        id: "fieldResurfacePrompt.zoneInterpolationMessage",
        defaultMessage:
            "The following is a list of imported Sampling Events that includes zones that will be removed if " +
            "you select any of these for resurfacing. If selected, you will need to edit these Events after this " +
            "resurfacing and select the Display Layer or appropriate zones needed:",
    },
});
