export const PROPS_ACTIVE_INACTIVE = "activeInactive";
export const PROPS_ACTIVE_YN = "activeYN";
export const PROPS_CONFIGURATION = "Configuration";
export const PROPS_COVER_PAGE = "coverPage";
export const PROPS_COVER_PAGE_URL = "coverPageUrl";
export const PROPS_CREATED_BY = "CreatedBy";
export const PROPS_CREATED_BY_DATE = "CreatedByDate";
export const PROPS_CREATED_BY_GUID = "CreatedByGuid";
export const PROPS_FIELD_GUID = "FieldGuid";
export const PROPS_JSON = "Json";
export const PROPS_LOGO = "Logo";
export const PROPS_MEMO = "memo";
export const PROPS_MODIFIED_BY = "ModifiedBy";
export const PROPS_MODIFIED_BY_DATE = "ModifiedByDate";
export const PROPS_MODIFIED_BY_GUID = "ModifiedByGuid";
export const PROPS_NAME = "name";
export const PROPS_ORG_LEVEL_GUID = "OrgLevelGuid";
export const PROPS_OWNER_GUID = "OwnerGuid";
export const PROPS_STATUS = "Status";
export const PROPS_REPORT_BOOK_GUID = "ReportBookGuid";
export const PROPS_REPORT_BOOK_FIELD_MAPPING_GUID = "ReportBookFieldMappingGuid";
export const PROPS_REPORT_BOOK_INFO_GUID = "ReportBookInfoGuid";
export const PROPS_REPORT_BOOK_OPTIONS_GUID = "ReportBookOptionsGuid";
export const PROPS_REPORT_BOOK_REPORT_GUID = "ReportBookReportGuid";
export const PROPS_REPORT_BOOK_TYPE_GUID = "ReportBookTypeGuid";
export const PROPS_REPORT_BOOK_TYPE_ORG_LEVEL_GUID = "ReportBookTypeOrgLevelGuid";
export const PROPS_REPORT_TYPE_GUID = "ReportTypeGuid";
export const PROPS_REPORT_TYPES_AND_OPTIONS = "ReportTypesAndOptions";
export const PROPS_SORT_ORDER = "SortOrder";

export const PROPS_CONFIGURATION_NAME = "configurationName";
export const PROPS_REPORT = "report";
export const PROPS_REPORT_NAME = "reportName";
export const PROPS_REPORT_BOOK_NAME = "name";
export const PROPS_LOCATION_ACTIVE_YN = "activeYn";
export const PROPS_STATE_ABBREVIATION = "state";

export const PROPS_CAN_DELETE = "canDelete";
export const PROPS_DO_NOT_EDIT = "doNotEdit";
export const PROPS_ADD_EDIT_TABLE_DOWN_BUTTON = "downButton";
export const PROPS_ADD_EDIT_TABLE_UP_BUTTON = "upButton";
export const PROPS_ADD_EDIT_TABLE_DATA = "data";
export const PROPS_REPORT_OPTIONS_TABLE_DATA = "data";
export const PROPS_SETUP_REPORT_BOOKS_TABLE_DATA = "data";
