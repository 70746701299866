import PropTypes from "prop-types";
import Photo from "./photo";
import { model } from "~/admin/agBytes/equipment/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_AGGATEWAY_ID]: PropTypes.string,
    [model.PROPS_CAN_DELETE]: PropTypes.string,
    [model.PROPS_CLASS_SIZE]: PropTypes.string,
    [model.PROPS_CLASS_SIZE_GUID]: PropTypes.string,
    [model.PROPS_CONTAINER_SIZE]: PropTypes.number,
    [model.PROPS_CONTAINER_SIZE_UNIT]: PropTypes.string,
    [model.PROPS_CONTAINER_SIZE_UNIT_GUID]: PropTypes.string,
    [model.PROPS_CYLINDERS]: PropTypes.number,
    [model.PROPS_DESCRIPTION]: PropTypes.string,
    [model.PROPS_DIMENSION_UNIT]: PropTypes.string,
    [model.PROPS_DIMENSION_UNIT_GUID]: PropTypes.string,
    [model.PROPS_DRIVE_TRAIN]: PropTypes.string,
    [model.PROPS_DRIVE_TRAIN_GUID]: PropTypes.string,
    [model.PROPS_ENGINE_DISPLACEMENT]: PropTypes.number,
    [model.PROPS_EQUIPMENT_GUID]: PropTypes.string,
    [model.PROPS_EQUIPMENT_ID]: PropTypes.number,
    [model.PROPS_EQUIPMENT_MANUFACTURER]: PropTypes.string,
    [model.PROPS_EQUIPMENT_NAME]: PropTypes.string,
    [model.PROPS_EQUIPMENT_PARENT_TYPE]: PropTypes.string,
    [model.PROPS_EQUIPMENT_PARENT_TYPE_GUID]: PropTypes.string,
    [model.PROPS_EQUIPMENT_PHOTOLIST]: PropTypes.arrayOf(Photo),
    [model.PROPS_EQUIPMENT_TYPE]: PropTypes.string,
    [model.PROPS_EQUIPMENT_TYPE_ALIASLIST]: PropTypes.arrayOf(PropTypes.object),
    [model.PROPS_EQUIPMENT_TYPE_GUID]: PropTypes.string,
    [model.PROPS_FUEL_CAPACITY]: PropTypes.number,
    [model.PROPS_FUEL_CAPACITY_UNIT]: PropTypes.string,
    [model.PROPS_FUEL_CAPACITY_UNIT_GUID]: PropTypes.string,
    [model.PROPS_FUEL_EFFICIENCY]: PropTypes.number,
    [model.PROPS_FUEL_EFFICIENCY_UNIT]: PropTypes.string,
    [model.PROPS_FUEL_EFFICIENCY_UNIT_GUID]: PropTypes.string,
    [model.PROPS_FUEL_TYPE]: PropTypes.string,
    [model.PROPS_FUEL_TYPE_GUID]: PropTypes.string,
    [model.PROPS_HEIGHT]: PropTypes.number,
    [model.PROPS_HORSE_POWER]: PropTypes.number,
    [model.PROPS_HORSE_POWER_UNIT]: PropTypes.string,
    [model.PROPS_HORSE_POWER_UNIT_GUID]: PropTypes.string,
    [model.PROPS_LENGTH]: PropTypes.number,
    [model.PROPS_MANUFACTURER]: PropTypes.string,
    [model.PROPS_MANUFACTURER_GUID]: PropTypes.string,
    [model.PROPS_MAX_GROSS_WEIGHT]: PropTypes.number,
    [model.PROPS_MAX_SPEED]: PropTypes.number,
    [model.PROPS_MAX_SPEED_UNIT]: PropTypes.string,
    [model.PROPS_MAX_SPEED_UNIT_GUID]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_PHYSICAL_STATE_GUID]: PropTypes.string,
    [model.PROPS_TARE_WEIGHT]: PropTypes.number,
    [model.PROPS_TOWING_CAPACITY]: PropTypes.number,
    [model.PROPS_TOWING_CAPACITY_UNIT]: PropTypes.string,
    [model.PROPS_TOWING_CAPACITY_UNIT_GUID]: PropTypes.string,
    [model.PROPS_TRANSMISSION_TYPE]: PropTypes.string,
    [model.PROPS_TRANSMISSION_TYPE_GUID]: PropTypes.string,
    [model.PROPS_WEIGHT_UNIT]: PropTypes.string,
    [model.PROPS_WEIGHT_UNIT_GUID]: PropTypes.string,
    [model.PROPS_WIDTH]: PropTypes.number,
};

export default PropTypes.shape(shape);
