import React, { Component } from "react";
import { TextInput, DialogBox, DialogBoxFooterType, ZeroToInfiniteGrid, NoLink } from "~/core";
import { InjectedIntlProps } from "react-intl";
import { withEditableGrid } from "~/hocs";
import { messages } from "../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { IPackageGtinListProps, IListState } from "../interfaces";

class PackageGtinList extends Component<IPackageGtinListProps & InjectedIntlProps, IListState> {
    static PROPS_PACKAGE_GTIN = "gtin";

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
        };
    }

    onEdit = (record, index) => {
        const keysToUpdate = [PackageGtinList.PROPS_PACKAGE_GTIN];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };
    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.gtin
                )}
                action="save"
                actionDisabled={!editData[PackageGtinList.PROPS_PACKAGE_GTIN]}
                onClose={() => toggleModal("isEditing", false)}
                onAction={() => onAddOrEditItem()}
                isOpen={isEditing}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
            >
                <TextInput
                    tabIndex={0}
                    maxLength={50}
                    required
                    autoFocus
                    placeholderText={formatMessage(messages.packageGtin)}
                    value={formData[PackageGtinList.PROPS_PACKAGE_GTIN] || ""}
                    onChange={(value) =>
                        onChange({
                            [PackageGtinList.PROPS_PACKAGE_GTIN]: value,
                        })
                    }
                />
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PackageGtinList.PROPS_PACKAGE_GTIN]: {
                                title: formatMessage(messages.gtin),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.gtin)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(PackageGtinList);
