import React, { Component } from "react";
import PropTypes from "prop-types";
import { DialogBox, DialogBoxFooterType, NoLink, FileSelector } from "~/core";
import "./component.css";
import { AppHelpers } from "@ai360/core";
import { messages } from "../i18n-messages";
import { createAddLinkLabelText, messages as globalMessages } from "~/i18n-messages";
import classNames from "classnames";

// constants
export const PROPS_BASE64STRING = "base64String";
export const DEFAULT_IMAGE_TYPE = "*";
export const SVG = "svg";
export const SVG_XML = "image/svg+xml";
export const ICO = "ico";

export class PhotoSimple extends Component {
    static propTypes = {
        className: PropTypes.string,
        formatMessage: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        isDeleting: PropTypes.bool,
        title: PropTypes.string,
        record: PropTypes.string,
        isDeleteModalRequired: PropTypes.bool,
        fileType: PropTypes.string,
        id: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isDeleteModalRequired: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            isDeleting: false,
            previewImageData: null,
            showValidIcoModal: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { fileType } = this.props;
        if (nextProps.record) {
            this.setState({
                previewImageData: !AppHelpers.fileIsSvgMarkup(nextProps.record)
                    ? AppHelpers.base64StringToBase64Image(nextProps.record, fileType)
                    : nextProps.record,
            });
        }
    }

    onDelete = () => {
        this.props.onChange("");
        this.setState({
            previewImageData: null,
            isDeleting: false,
        });
    };

    onAdd = ({ files }) => {
        const file = files[0];
        if (file.type === SVG_XML) {
            if (this.props.fileType === ICO) {
                this.setState({
                    showValidIcoModal: true,
                });
            } else {
                AppHelpers.fileToXML(file).then((xml) => {
                    if (xml) {
                        this.setViewport(xml);
                    }
                });
            }
        } else {
            AppHelpers.fileToBase64(file).then((base64) => {
                if (base64) {
                    const image = this.props.fileType
                        ? AppHelpers.base64ImageToBase64string(base64)
                        : base64;
                    this.props.onChange(image);
                    this.setState({
                        previewImageData: base64,
                    });
                }
            });
        }
    };

    setViewport = (imageString) => {
        this.setState({
            previewImageData: imageString,
        });
        this.props.onChange(imageString);
    };

    toggleModal = (modal, force) => {
        this.setState({
            [modal]: force != null ? force : !this.state[modal],
        });
    };

    renderValidIcoModal = () => {
        const { formatMessage } = this.props;
        return (
            <DialogBox
                isOpen={this.state.showValidIcoModal}
                onClose={() => this.toggleModal("showValidIcoModal", false)}
                title={formatMessage(messages.invalidFileType)}
            >
                {formatMessage(messages.selectValidIcoFile)}
            </DialogBox>
        );
    };

    renderDeleteModal = () => {
        const { formatMessage } = this.props;
        return (
            <DialogBox
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={this.state.isDeleting}
                onAction={() => this.onDelete()}
                onClose={() => this.toggleModal("isDeleting", false)}
                title={formatMessage(globalMessages.confirmTitle)}
            >
                {formatMessage(globalMessages.deleteConfirmationMessage)}
            </DialogBox>
        );
    };
    render() {
        const { formatMessage, title, isDeleteModalRequired, fileType, className } = this.props;
        const imageType = fileType ? fileType : DEFAULT_IMAGE_TYPE;
        return (
            <div className={classNames("photo-simple", className)}>
                {!this.state.previewImageData ? null : (
                    <div>
                        <div className="delete-link-container">
                            <NoLink
                                className="delete-link"
                                label={formatMessage(messages.delete)}
                                onClick={() => this.toggleModal("isDeleting", true)}
                            />
                        </div>
                        {AppHelpers.fileIsSvgMarkup(this.state.previewImageData) ? (
                            <div
                                className={"image-thumbnail"}
                                dangerouslySetInnerHTML={{
                                    __html: this.state.previewImageData,
                                }}
                            ></div>
                        ) : (
                            <img
                                alt=""
                                src={this.state.previewImageData}
                                className="image-thumbnail"
                            />
                        )}
                    </div>
                )}
                {!isDeleteModalRequired ? null : this.renderDeleteModal()}
                {!this.state.showValidIcoModal ? null : this.renderValidIcoModal()}
                {this.state.previewImageData ? null : (
                    <FileSelector
                        fileType={`image/${imageType}`}
                        allowMultiple={true}
                        label={createAddLinkLabelText(formatMessage, messages.photo, { count: 1 })}
                        fileCompletedCallback={this.onAdd}
                        id={this.props.id}
                        className="add-link"
                        title={title}
                    />
                )}
            </div>
        );
    }
}
