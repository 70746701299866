import { createService } from "~/hocs/crud";
import * as model from "./model";
import defaultPersonRecord from "./default-record";
import { UserRoleAPI } from "@ai360/core";

// Module Name
export const MODEL_NAME = "userRole";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "userRoleFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "userRoleSort";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "userRolePageOptions";

// URLs
export const AUTO_SEARCH_URL = UserRoleAPI.AUTO_SEARCH_URL;
export const CREATE = UserRoleAPI.ADD_USER_ROLE;
export const DELETE = UserRoleAPI.DELETE_USER_ROLE;
export const GETRECORD = UserRoleAPI.GET_USER_ROLE;
export const UPDATE = UserRoleAPI.UPDATE_USER_ROLE;
export const URL = UserRoleAPI.GET_USER_ROLE_LIST;

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        name: "",
        description: "",
        accessLevel: "",
        isAssigned: "",
        canDelete: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            FieldName: "Name",
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    companyGuid: "",
};

// Service
export const service = createService({
    id: model.PROPS_USER_ROLE_ID,
    guid: model.PROPS_USER_ROLE_GUID,
    name: model.PROPS_USER_ROLE_NAME,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    urls: {
        URL,
        AUTO_SEARCH_URL,
        GETRECORD,
        CREATE,
        UPDATE,
        DELETE,
    },
    _defaultLabels: {
        [model.PROPS_NAME]: { label: "roleName", gridCol: 30 },
        [model.PROPS_DESCRIPTION]: { label: "description", gridCol: 30 },
        [model.PROPS_ACCESS_LEVEL]: { label: "accessLevel", gridCol: 20 },
        [model.PROPS_IS_ASSIGNED]: { label: "isAssigned", gridCol: 20 },
        [model.PROPS_CAN_DELETE]: { label: "canDelete", gridCol: 10 },
    },
    getDefaultRecord: () => ({ ...defaultPersonRecord() }),
    useUpdatePut: true,
    defaultSort: defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
});
