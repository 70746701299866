import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, service, dropdowns, unitTypes } from "./../data";
import { connect } from "react-redux";
import { createUserRole } from "./../data/actions";
import { getApiErrorCodeList } from "~/core/api/selectors";
import { getTheOwnerBrandName } from "~/login/selectors";

const mapStateToProps = (state) => ({
    apiErrors: getApiErrorCodeList(state),
    systemName: getTheOwnerBrandName(state),
});

const mapDispatchToProps = () => ({
    createUserRole,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AddEditPanelContainer(AddEditPanel, {
        pickLists,
        service,
        dropdowns,
        unitTypes,
    })
);
