import React, { Component, ReactNode } from "react";
import classnames from "classnames";
import { DialogBox, DialogBoxFooterType } from "~/core";

import { injectIntl, intlShape } from "react-intl";
import { messages } from "./i18n-messages";
import "./attribute-edit-modal.css";

interface AttributeEditModalProps {
    className?: string;
    title?: string;
    isOpen: boolean;
    canSave: boolean;
    onSaveEdit: () => void;
    onCloseEdit: () => void;
    intl: intlShape;
    children?: ReactNode;
    unrestricted?: boolean;
}

class AttributeEditModal_ extends Component<AttributeEditModalProps> {
    static defaultProps = {
        title: messages.editAttributeText.defaultMessage,
        unrestricted: false,
    };

    render() {
        return (
            <DialogBox
                className={classnames("attribute-edit-modal", this.props.className)}
                forceOverflow={false}
                isOpen={this.props.isOpen}
                isModal={true}
                title={this.props.title}
                draggable={true}
                hideCloseX={true}
                actionDisabled={!this.props.canSave}
                onAction={() => this.props.onSaveEdit()}
                onClose={() => this.props.onCloseEdit()}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                unrestricted={this.props.unrestricted}
            >
                {this.props.children}
            </DialogBox>
        );
    }
}

export const AttributeEditModal = injectIntl(AttributeEditModal_);
