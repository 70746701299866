import React, { Component } from "react";
import { intlShape } from "react-intl";
import _ from "lodash";
import Immutable from "immutable";

import esriConfig from "@arcgis/core/config";
import EsriMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Extent from "@arcgis/core/geometry/Extent";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

import { SearchAPI } from "@ai360/core";
import { MSGTYPE } from "~/notifications";

import { BasemapSelector } from "./basemap-selector";
import { ZoomControl } from "./zoom-control";
import { messages } from "../i18n-messages";
import { CadastralDataManager } from "../cadastral-data-manager";
import {
    BasemapUtils,
    GeometryUtils,
    ListenerManager,
    CanvasLayerUtils,
    Toolset,
    MapConfig,
    LegacyCanvasLayer,
    BigDataLayer,
    FieldsLayer,
    NonFieldFeaturesLayer,
    FieldBoundaryImportLayer,
    ImportDataConvexHullLayer,
    TileLayer,
    SampleSitesLayer,
    SurfaceLayer,
    SurfaceType,
} from "@ai360/core";

import * as FieldsLayerUpdateWorker from "../layers/workers/fields-layer-update.worker";

import "./map-control.css";

// This utility overwrites the global esri.symbol.SimpleFillSymbol.prototype.getFill function so it can be colorized.
// It will be used in multiple layers. At this time, this appears to be the best place to initiate it.
import "../utils/fill-pattern";
import { HaasMapView } from "../../map-tools/components/map-tools.4x";
import { EventDetails } from "~/recs-events/events/models";
import { RecDetails } from "~/recs-events/recs/model";

interface IListeners {
    keydown: (event: KeyboardEvent) => void;
    keyup: () => void;
}

export interface IImportData {
    filteredFieldImportFileList: any[];
    forceUpdate: boolean;
    ignoreFarm: boolean;
    ignoreSelectedField: boolean;
    importFieldList: any[];
    importFileGuidList: string[];
    importMatchedFieldGuidList: string[];
    importSamplingPoints: any[];
    isFilteringVisible: boolean;
    selectedImportFieldIndex: number;
    selectedMatchedFieldGuid: string;
    showImportPoints: boolean;
    statFilterOn: boolean;
    yieldFilterOn: boolean;
    convexHulls: any;
    isFieldMatchingLoading: boolean;
}

export interface ISetMapRef {
    map: MapView;
    getFieldBoundaryImportLayer: any;
    getFieldsLayer: any;
    getImportDataConvexHullLayer: any;
}

export interface ILayerInfoShape {
    name: string;
    showLabels: boolean;
    visible: boolean;
}

export interface IFieldBoundaryLabelsShape {
    customerName: boolean;
    farmName: boolean;
    fieldArea: boolean;
    fieldName: boolean;
}

export interface IFieldBoundaryLayerInfoShape {
    showLabels: IFieldBoundaryLabelsShape;
    visible: boolean;
}

export interface IProps {
    activeMapTool: string;
    activeModule: string;
    activeToolset: Toolset;
    basemap: string;
    batchFieldGuid: string;
    cadastralLayerInfos: ILayerInfoShape[];
    clearInvalidatedFields: () => void;
    fetchNonFieldFeaturesForUser: () => void;
    fieldBoundaryLayerInfo: IFieldBoundaryLayerInfoShape;
    fieldGuidToEventDetails: Immutable.OrderedMap<string, EventDetails>;
    fieldGuidToRecDetails: Immutable.OrderedMap<string, RecDetails>;
    fieldGuidToAnalysisDetails: Map<string, any>;
    fieldGuidHasDataSet: Set<any>;
    fieldsBackgroundOnly: boolean;
    fieldsBackgroundOnlyBatch: boolean;
    filterData: any;
    filteredFieldGuids: Immutable.Set<string>;
    forceRefreshFlag: boolean;
    getCustomer: () => any;
    highlightedNonFieldFeatures: Immutable.Set<any>;
    imageryTileBucketName: string;
    latestUpdatedEvent: any[];
    importData: IImportData;
    importWizardType: any;
    intl: intlShape;
    invalidatedFieldGuids: Set<string>;
    isInBatchWorkflow: boolean;
    isLoading: boolean;
    nonFieldFeaturesCanShowTooltip: boolean;
    nonFieldFeatureLayerIsVisible: boolean;
    onClearForceRefresh: () => void;
    onClearForceUpdate: () => void;
    onClearFieldSelection: () => void;
    onClearZoomToCustomer: () => void;
    onClearZoomToFarm: () => void;
    onClearZoomToField: () => void;
    onClearZoomToFieldList: () => void;
    onClearZoomToNonFieldFeatures: () => void;
    onFetchLayerPreferences: (userGuid: string) => void;
    onMapReady: () => void;
    onPushToasterMessage: (message: string, type: string) => void;
    onSelectFields: () => void;
    onSelectImportFieldBoundary: () => void;
    onSetBasemap: () => void;
    onSetIsCanvasLoading: (isCanvasLoading: boolean) => void;
    onSetImportFieldBoundaries: () => void;
    onSetIsLoading: (isLoading: boolean, force?: boolean) => void;
    onSetMap: (e: ISetMapRef) => void;
    onSetSelectedMatchedFieldGuid: () => void;
    onSetToolsProcessing: (isProcessing: boolean) => void;
    onSetZoomAndScale: (zoom: any, scale: any) => void;
    onUpdateLoadingSampleSites: (agEventGeneralGuid: string, isLoading: boolean) => void;
    onUpdateLoadingSurfaces: (surfaceGuid: string, isLoading: boolean) => void;
    scale: number;
    selectedFieldGuids: Immutable.Set<string>;
    selectedMatchedFieldGuid: string;
    setFilteredPointCount: (pointCount: number) => void;
    toolsetPayload: any;
    userGuid: string;
    visibleNonFieldFeatures: Immutable.Set<any>;
    visibleSampleSites: Map<any, any>;
    visibleSurfaces: Map<any, any>;
    zoom: number;
    zoomToCustomerGuid: string;
    zoomToFarmName: string;
    zoomToFieldGuid: string;
    zoomToFieldGuidList: string[];
    zoomToNonFieldFeatures: string[];
}

const FIELD_BOUNDARY_IMPORT_LAYER = "FieldBoundaryImport";
const FIELD_CENTROIDS_CANVAS_LAYER = "FieldCentroids";
const FIELDS_LAYER = "Fields";
const FILTER_POINT_CANVAS_LAYER = "FilterPoints";
const IMPORT_DATA_CONVEX_HULL_LAYER = "ImportDataConvexHull";
const NON_FIELD_FEATURES_LAYER = "NonFieldFeatures";
const IMPORT_DATA_SAMPLE_SITES_LAYER = "ImportDataSampleSites";
const IMPORT_DATA_POINT_CANVAS_LAYER = "ImportDataPoints";

const DOUBLECLICK_THRESHOLD_MS = 500;
const listeners: IListeners = {
    keydown: null,
    keyup: null,
};

export class MapControl extends Component<IProps> {
    private listeners: ListenerManager;
    private canvasLayers: Map<string, any>;
    private layers: Map<string, any>;
    private fieldsLayer: FieldsLayer;
    private fieldBoundaryImportsLayer: FieldBoundaryImportLayer;
    private nonFieldFeaturesLayer: NonFieldFeaturesLayer;
    private importDataConvexHullLayer: ImportDataConvexHullLayer;
    private importSampleSitesLayer: SampleSitesLayer;
    private sampleSiteLayers: Map<string, SampleSitesLayer>;
    private surfaceLayers: Map<string, SurfaceLayer>;
    private tileLayers: Map<string, TileLayer>;
    private mapView: MapView;
    private map: EsriMap & HaasMapView;

    private cadastralDataManager: CadastralDataManager;

    private visibleSampleSites: string;
    private filteredFieldGuids: Immutable.Set<string>;
    private selectedFieldGuids: Immutable.Set<string>;
    private importFieldList: any[];
    private importFileGuidList: string[];
    private matchedFieldGuids: any[];
    private selectedMatchedFieldGuid: string;
    private convexHulls: any;
    private importSamplingPointsStr: string;
    private ignoreSelectedField: boolean;
    private fieldCentroisUpdateTimer: NodeJS.Timeout;

    private isLoadingImportPoints: boolean;
    private isLoadingFilterPoints: boolean;

    private isFirstMouseDown: boolean;

    private mapDiv: HTMLDivElement;

    constructor(props: IProps) {
        super(props);
        this.listeners = new ListenerManager();

        this.canvasLayers = new Map<string, any>();
        this.layers = new Map<string, any>();
        this.sampleSiteLayers = new Map<string, SampleSitesLayer>();
        this.surfaceLayers = new Map<string, SurfaceLayer>();
        this.tileLayers = new Map<string, TileLayer>();

        this.visibleSampleSites = JSON.stringify(new Map());
        this.filteredFieldGuids = Immutable.Set<string>();
        this.selectedFieldGuids = Immutable.Set<string>();
        this.importFieldList = [];
        this.importFileGuidList = [];
        this.matchedFieldGuids = [];
        this.selectedMatchedFieldGuid = null;
        this.convexHulls = {};

        this.isLoadingImportPoints = false;
        this.isLoadingFilterPoints = false;

        this.isFirstMouseDown = false;
        // not required for 4x?
        // BasemapUtils.setupBasemapsAndEsriConfigProxy();

        this._handleError = this._handleError.bind(this);
    }

    #getBaseLayerOptions() {
        const { activeMapTool, activeModule, activeToolset, onPushToasterMessage, userGuid } =
            this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        return {
            activeMapTool,
            activeModule,
            activeToolset,
            formatMessage,
            formatNumber,
            messages,
            onPushToasterMessage,
            userGuid,
        };
    }

    #getCanvasLayer(id: string, options?: any, newLayer = false) {
        if (options) {
            if (this.canvasLayers.has(id)) {
                if (options.rendererOptions) {
                    this.canvasLayers.get(id).setRenderer(options.rendererOptions);
                }
            } else if (newLayer === true) {
                const layer = new BigDataLayer(id, this.mapView, options);
                this.canvasLayers.set(id, layer);
            } else {
                this.canvasLayers.set(id, new LegacyCanvasLayer(id, this.mapView, options));
            }
        }
        return this.canvasLayers.get(id);
    }

    #getFocusedFieldGuidSet(props: IProps) {
        return new Set([
            ...props.fieldGuidToEventDetails.keys(),
            ...props.fieldGuidToRecDetails.keys(),
            ...props.fieldGuidToAnalysisDetails.keys(),
            ...props.importData.importMatchedFieldGuidList,
            ...props.visibleSurfaces.keys(),
        ]);
    }

    _handleError(error: any) {
        console.error(error);
        if (this.props.isLoading) {
            this.props.onSetIsLoading(false, true);
        }
    }

    #loadLayers() {
        const {
            cadastralLayerInfos,
            fieldBoundaryLayerInfo,
            fieldsBackgroundOnly,
            onClearFieldSelection,
            onSelectFields,
            onSetIsLoading,
            userGuid,
            importData,
            onSelectImportFieldBoundary,
            onSetImportFieldBoundaries,
            onSetSelectedMatchedFieldGuid,
        } = this.props;

        const {
            filteredFieldImportFileList,
            ignoreFarm,
            importFieldList,
            importFileGuidList,
            selectedImportFieldIndex,
            selectedMatchedFieldGuid,
        } = importData;

        const baseOptions = this.#getBaseLayerOptions();

        const fieldsLayer = new FieldsLayer(
            FIELDS_LAYER,
            this.map,
            this.mapView,
            {
                ...baseOptions,
                fieldBoundaryLayerInfo,
                fieldsBackgroundOnly,
                selectedMatchedFieldGuid,
                fieldsBackgroundOnlyBatch: undefined,
                onClearFieldSelection,
                onSelectFields,
                onSetSelectedMatchedFieldGuid,
            },
            FieldsLayerUpdateWorker
        );
        this.fieldsLayer = fieldsLayer;
        this.layers.set(FIELDS_LAYER, fieldsLayer);

        const fieldBoundaryImportsLayer = new FieldBoundaryImportLayer(
            FIELD_BOUNDARY_IMPORT_LAYER,
            this.mapView,
            {
                ...baseOptions,
                importFieldList,
                ignoreFarm,
                selectedImportFieldIndex,
                onSelectFields: onSelectImportFieldBoundary,
                onSetImportFieldBoundaries,
            }
        );
        this.fieldBoundaryImportsLayer = fieldBoundaryImportsLayer;
        this.layers.set(FIELD_BOUNDARY_IMPORT_LAYER, fieldBoundaryImportsLayer);

        const nonFieldFeaturesLayer = new NonFieldFeaturesLayer(
            NON_FIELD_FEATURES_LAYER,
            this.mapView,
            {
                ...baseOptions,
            }
        );
        this.nonFieldFeaturesLayer = nonFieldFeaturesLayer;
        this.layers.set(NON_FIELD_FEATURES_LAYER, nonFieldFeaturesLayer);

        //Load Field Centroids Layer
        CanvasLayerUtils.getFieldCentroids(userGuid)
            .then((response) => {
                const { data, extent, rendererOptions } = response;
                if (extent != null) {
                    this.mapView.extent = (extent as Extent).expand(1.5);
                }
                const fieldCentroids = this.#getCanvasLayer(FIELD_CENTROIDS_CANVAS_LAYER, {
                    maxScale: MapConfig.layers.fields.minScale,
                    rendererOptions: rendererOptions,
                });
                fieldCentroids.setData(data);
                fieldsLayer.setCentroidsLayer(fieldCentroids);

                this.map.isReady = true;
                this.map.emit("ready");
                this.props.onMapReady();

                this.props.fetchNonFieldFeaturesForUser();
                this.props.onFetchLayerPreferences(this.props.userGuid);
            })
            .catch((err) => this._handleError(err))
            .finally(() => onSetIsLoading(false));

        this.cadastralDataManager = new CadastralDataManager(
            MapConfig.cadastral.definitions as any,
            this.mapView,
            baseOptions
        );
        this.cadastralDataManager.updateLayerInfos(cadastralLayerInfos);

        //load less critical layers after a short delay so as not to impede initial app load performance
        setTimeout(() => {
            const importDataConvexHullLayer = new ImportDataConvexHullLayer(
                IMPORT_DATA_CONVEX_HULL_LAYER,
                this.mapView,
                {
                    ...baseOptions,
                    importFileGuidList,
                    filteredFieldImportFileList,
                    selectedMatchedFieldGuid,
                }
            );
            this.importDataConvexHullLayer = importDataConvexHullLayer;
            this.layers.set(IMPORT_DATA_CONVEX_HULL_LAYER, importDataConvexHullLayer);

            const importSampleSitesLayer = new SampleSitesLayer(
                IMPORT_DATA_SAMPLE_SITES_LAYER,
                this.mapView,
                {
                    ...baseOptions,
                    getFieldBoundary: (fieldGuid) => fieldsLayer.getFieldBoundary(fieldGuid),
                    isForImports: true,
                    hideLabels: false,
                }
            );
            this.importSampleSitesLayer = importSampleSitesLayer;
            this.layers.set(IMPORT_DATA_SAMPLE_SITES_LAYER, importSampleSitesLayer);
        }, 1000);
    }

    #removeListeners() {
        this.listeners.removeAll();
        for (const [key, value] of Object.entries(listeners)) {
            document.removeEventListener(key, value, false);
        }
    }

    #setupExtensions() {
        const getFieldsLayer = function (layers /** Map<string, BaseLayer> */) {
            return layers.get(FIELDS_LAYER);
        }.bind(null, this.layers);
        const getFieldBoundaryImportLayer = function (layers /** Map<string, BaseLayer> */) {
            return layers.get(FIELD_BOUNDARY_IMPORT_LAYER);
        }.bind(null, this.layers);
        const getImportDataConvexHullLayer = function (layers /** Map<string, BaseLayer> */) {
            return layers.get(IMPORT_DATA_CONVEX_HULL_LAYER);
        }.bind(null, this.layers);
        return {
            getFieldsLayer,
            getFieldBoundaryImportLayer,
            getImportDataConvexHullLayer,
        };
        // MapUtils.addSetExtentFast(mapView.map, ToolConfig.autoPan);
    }

    #setupListeners(mapView: MapView) {
        const { formatMessage } = this.props.intl;

        listeners.keydown = (evt) => {
            if (this.props.activeToolset === Toolset.DEFAULT && (evt.ctrlKey || evt.altKey)) {
                mapView.container.style.cursor = "crosshair";
                // TODO: Address map navigation
                // MapUtils.disableZooming(mapView).forEach(l => this._mapNavigation.add);
                // mapView.map.disableMapNavigation();
            }

            // This should help enforce that we only allow keyboard navigation when inputs are not focused
            if (
                evt.key === "Backspace" &&
                (evt.target as HTMLElement).tagName.toString().toLowerCase() !== "input" &&
                (evt.target as HTMLElement).tagName.toString().toLowerCase() !== "textarea" &&
                (evt.target as HTMLElement).className !== "ql-editor"
            ) {
                // To allow backspace for TextEditor
                evt.preventDefault(); //We want to prevent accidental browser navigation
            }
        };
        listeners.keyup = () => {
            if (this.props.activeToolset === Toolset.DEFAULT) {
                // TODO: Address map navigation
                // mapView.map.enableMapNavigation();
                mapView.container.style.cursor = "default";
            }
        };
        this.#removeListeners();
        document.addEventListener("keydown", listeners.keydown, false);
        document.addEventListener("keyup", listeners.keyup, false);

        this.listeners.add(
            mapView.on("pointer-down", (evt) => {
                if (evt.detail === 1) {
                    this.isFirstMouseDown = true;
                    setTimeout(() => {
                        this.isFirstMouseDown = false;
                    }, DOUBLECLICK_THRESHOLD_MS);
                } else if (evt.detail > 1) {
                    if (!this.isFirstMouseDown) {
                        // Map registered the second click of a double-click, but not the first click - which
                        // means the double-click originated on another control over top of the map. Avoid
                        // a phantom zoom by temporarily disabling.
                        this.#temporarilyDisableDoubleClickZoom();
                    }
                    this.isFirstMouseDown = false;
                }

                //IE has a problem losing focus on inputs when interacting with the map, so we have to manually do it:
                const activeElement = document.activeElement as HTMLElement;
                if (activeElement && activeElement.blur) {
                    activeElement.blur();
                }
            })
        );

        this.listeners.add(
            mapView.watch("stationary", (newValue) => {
                if (newValue === true) {
                    this.#setViewableLayers(this.props, {
                        extent: this.mapView.extent && this.mapView.extent.clone(),
                        lod: {
                            scale: this.mapView.scale,
                            level: this.mapView.zoom,
                        },
                        clearBoundaryLayer: this.props.zoom !== this.mapView.zoom,
                    });
                }
                if (
                    this.props.zoom !== this.mapView.zoom ||
                    this.props.scale !== this.mapView.scale
                ) {
                    this.props.onSetZoomAndScale(this.mapView.zoom, this.mapView.scale);
                }
            })
        );

        // this._listeners.add(mapView.on("layerview-create", (e) => {
        //     const { layer } = e;
        //     if (layer instanceof ArcGISTiledMapServiceLayer || layer instanceof VectorTileLayer) {
        //         BasemapUtils.addMissingLodsAndScales(layer, layer instanceof VectorTileLayer);
        //         const currentLod = _.find(layer.tileInfo.lods, (lod) => {
        //             return lod.level === this.props.zoom;
        //         });
        //         if (!currentLod || currentLod.isCustom) {
        //             this.fixExtentAfterChange = .5;
        //             mapView.extent = mapView.extent.expand(1.25);
        //         }
        //     }
        // }));
        this.listeners.add(
            mapView.watch("fatalError", (error) => {
                if (error) {
                    console.warn(formatMessage(messages.recoverWebGLContext));
                    mapView.tryFatalErrorRecovery();
                }
            })
        );

        this.mapView.when(() => {
            // TODO: Re-evaluate for 4x
            // mapView.resizeDelay = 300;

            this.#loadLayers();
            this.#setViewableLayers(this.props);
        });
    }

    #setViewableLayers(props: IProps, evt?: any) {
        return new Promise((resolve) => {
            this.props.onSetToolsProcessing(true);
            const promises = [];

            const focusedFieldGuids = this.#getFocusedFieldGuidSet(props);
            if (this.fieldsLayer) {
                promises.push(
                    this.fieldsLayer.update(
                        evt && evt.clearBoundaryLayer,
                        evt && evt.extent,
                        evt && evt.lod,
                        props.filteredFieldGuids,
                        props.selectedFieldGuids as any,
                        focusedFieldGuids,
                        props.fieldGuidHasDataSet,
                        props.batchFieldGuid,
                        props.isInBatchWorkflow,
                        props.importData.selectedMatchedFieldGuid,
                        props.forceRefreshFlag
                    )
                );
            }

            if (this.cadastralDataManager) {
                promises.push(this.cadastralDataManager.update(this.mapView.extent));
            }

            if (this.fieldBoundaryImportsLayer) {
                promises.push(
                    this.fieldBoundaryImportsLayer.update(
                        false,
                        props.importData.importFieldList,
                        props.importData.selectedImportFieldIndex
                    )
                );
            }

            if (this.importDataConvexHullLayer) {
                promises.push(
                    this.importDataConvexHullLayer.update(
                        false,
                        props.importData.convexHulls,
                        props.importData.importFileGuidList,
                        props.importData.filteredFieldImportFileList,
                        props.importData.selectedMatchedFieldGuid,
                        props.importWizardType
                    )
                );
            }
            Promise.all(promises)
                .then(resolve)
                .catch(this._handleError)
                .finally(() => {
                    this.props.onSetIsLoading(false);
                    this.props.onSetToolsProcessing(false);
                });
        });
    }

    #temporarilyDisableDoubleClickZoom() {
        GeometryUtils.temporarilyDisableDoubleClickZoom(this.mapView, DOUBLECLICK_THRESHOLD_MS);
    }

    #updateVisibleImportSamplePoints(props: IProps) {
        if (!this.importSampleSitesLayer) {
            return;
        }
        const { ignoreSelectedField, importSamplingPoints, selectedMatchedFieldGuid } =
            props.importData;
        this.importSampleSitesLayer.setSites(
            importSamplingPoints,
            ignoreSelectedField ? null : selectedMatchedFieldGuid
        );
    }

    #updateVisibleLayers(
        props: IProps,
        localLayerMap: Map<string, any>,
        localTileLayerMap: Map<string, any>,
        visibleLayersMap: any,
        updateAction: (fieldGuid: string, info: any) => void
    ) {
        const { onSetIsLoading, visibleSampleSites, visibleSurfaces } = props;

        if (visibleLayersMap.size > 0) {
            onSetIsLoading(true);

            const fieldGuids = Array.from(visibleSampleSites.keys()).concat(
                Array.from(visibleSurfaces.keys())
            );
            this.fieldsLayer
                .zoomToFields(fieldGuids)
                .then(() => this.#setViewableLayers(props)) //Only here for case where zoom doesn't trigger `extent-changed` map event
                .then(() => {
                    onSetIsLoading(false);

                    for (const [fieldGuid, info] of visibleLayersMap.entries()) {
                        updateAction(fieldGuid, info);
                    }

                    for (const [fieldGuid, sLayer] of localLayerMap.entries()) {
                        const fieldHasVisibleLayer = visibleLayersMap.has(fieldGuid);
                        const visibleFieldLayer = !fieldHasVisibleLayer
                            ? null
                            : visibleLayersMap.get(fieldGuid);
                        if (
                            !visibleFieldLayer ||
                            (sLayer.surfaceInfo &&
                                sLayer.surfaceInfo.surfaceGuid !== visibleFieldLayer.surfaceGuid)
                        ) {
                            sLayer.hide();
                        }
                    }
                    for (const [fieldGuid, tLayer] of this.tileLayers.entries()) {
                        const fieldHasVisibleLayer = this.props.visibleSurfaces.has(fieldGuid);
                        const visibleFieldLayer = !fieldHasVisibleLayer
                            ? null
                            : this.props.visibleSurfaces.get(fieldGuid);
                        if (
                            !visibleFieldLayer ||
                            tLayer.imageryLayerGuid !== visibleFieldLayer.surfaceGuid
                        ) {
                            tLayer.hide();
                        }
                    }
                });
        } else {
            for (const sLayer of localLayerMap.values()) {
                sLayer.hide();
            }
            for (const tLayer of localTileLayerMap.values()) {
                tLayer.hide();
            }
            this.#setViewableLayers(props);
        }
    }

    #updateVisibleSampleSitesLayers(props: IProps) {
        const {
            onPushToasterMessage,
            onUpdateLoadingSampleSites,
            visibleSampleSites,
            visibleSurfaces,
            latestUpdatedEvent,
        } = props;
        const { formatMessage } = this.props.intl;

        const baseOptions = this.#getBaseLayerOptions();
        this.#updateVisibleLayers(
            props,
            this.sampleSiteLayers,
            new Map(),
            visibleSampleSites,
            (fieldGuid, info) => {
                onUpdateLoadingSampleSites(info.agEventGeneralGuid, true);
                let ssLayer = this.sampleSiteLayers.get(fieldGuid);
                if (!ssLayer) {
                    ssLayer = new SampleSitesLayer(`SS_${fieldGuid}`, this.mapView, {
                        ...baseOptions,
                        getFieldBoundary: undefined,
                        isForImports: false,
                        hideLabels: false,
                    });
                    this.sampleSiteLayers.set(fieldGuid, ssLayer);
                }
                const { depthId, surfaceGuid } = visibleSurfaces.has(fieldGuid)
                    ? visibleSurfaces.get(fieldGuid)
                    : { depthId: null, surfaceGuid: "" };
                ssLayer
                    .update(info, surfaceGuid, depthId, null, latestUpdatedEvent)
                    .then((lyr) => {
                        onUpdateLoadingSampleSites(lyr.agEventGeneralGuid, false);
                    })
                    .catch((err) => {
                        console.warn(err);
                        onPushToasterMessage(
                            formatMessage(messages.failedToLoadSampleSites),
                            MSGTYPE.ERROR
                        );
                        onUpdateLoadingSampleSites(info.surfaceGuid, false);
                    });
            }
        );
    }

    #updateVisibleSurfaceLayers(props: IProps) {
        const {
            imageryTileBucketName,
            onPushToasterMessage,
            onUpdateLoadingSurfaces,
            visibleSurfaces,
        } = props;

        const baseOptions = this.#getBaseLayerOptions();

        this.#updateVisibleLayers(
            props,
            this.surfaceLayers,
            this.tileLayers,
            visibleSurfaces,
            (fieldGuid, info) => {
                if (!info.imageryLayerGuid) {
                    onUpdateLoadingSurfaces(info.surfaceGuid, true);
                    let sLayer = this.surfaceLayers.get(fieldGuid);
                    if (!sLayer) {
                        sLayer = new SurfaceLayer(`SL_${fieldGuid}`, this.mapView, {
                            ...baseOptions,
                            fieldGuid,
                        });
                        this.surfaceLayers.set(fieldGuid, sLayer);
                    }
                    props.onSetIsCanvasLoading(true);
                    sLayer
                        .update(info)
                        .then((si) => {
                            // Sometimes the surface finishes up _after_ the sample sites, and covers it up
                            const ssLayer = this.sampleSiteLayers.get(fieldGuid);
                            if (ssLayer) {
                                ssLayer._moveLayerToTop(ssLayer.layer);
                                ssLayer._moveLayerToTop(ssLayer.labelLayer);
                            }
                            const type = SurfaceType.parse(info.surfaceType, info.classBreaks);
                            switch (type) {
                                case SurfaceType.CanvasLayer:
                                    if (sLayer.canvasLayer) {
                                        sLayer.mapView
                                            .whenLayerView(sLayer.canvasLayer.layer)
                                            .then((lv) => {
                                                this.#nonFeatureLayerRenderCompleted(lv);
                                            })
                                            .catch(this.#onLayerViewError.bind(this));
                                    }
                                    break;
                                case SurfaceType.FeatureLayer:
                                    if (sLayer.layer) {
                                        sLayer.mapView
                                            .whenLayerView(sLayer.layer)
                                            .then((lv) => {
                                                this.#featureLayerRenderCompleted(lv);
                                            })
                                            .catch(this.#onLayerViewError.bind(this));
                                    }
                                    break;
                                case SurfaceType.RasterLayer:
                                    if (sLayer.rasterLayer) {
                                        sLayer.mapView
                                            .whenLayerView(sLayer.rasterLayer.layer)
                                            .then((lv) => {
                                                this.#nonFeatureLayerRenderCompleted(lv);
                                            })
                                            .catch(this.#onLayerViewError.bind(this));
                                    }
                                    break;
                                default:
                                    break;
                            }
                            onUpdateLoadingSurfaces(si.surfaceGuid, false);
                        })
                        .catch((err) => {
                            console.warn(err);
                            onPushToasterMessage(messages.failedToLoadSurface, MSGTYPE.ERROR);
                            onUpdateLoadingSurfaces(info.surfaceGuid, false);
                            props.onSetIsCanvasLoading(false);
                        });
                } else {
                    onUpdateLoadingSurfaces(info.surfaceGuid, true);
                    let tLayer = this.tileLayers.get(fieldGuid);

                    if (!tLayer) {
                        tLayer = new TileLayer(`TL_${fieldGuid}`, this.mapView, {
                            ...baseOptions,
                            imageryLayerGuid: info.importFileGuid,
                            imageryTileBucketName,
                        });

                        this.tileLayers.set(fieldGuid, tLayer);
                    } else {
                        tLayer.update(info.importFileGuid);
                    }

                    onUpdateLoadingSurfaces(info.surfaceGuid, false);
                }
            }
        );
    }

    #onLayerViewError(err: any) {
        console.warn(err);

        // eat the cancellation error because it's arguably not an error
        if (err?.name !== messages.cancelledLayerViewCreate.id) {
            this.props.onPushToasterMessage(err.message, MSGTYPE.ERROR);
        }
    }

    #featureLayerRenderCompleted(lv: __esri.GraphicsLayerView) {
        watchUtils.whenFalseOnce(lv, "updating", () => {
            this.props.onSetIsCanvasLoading(false);
        });
    }

    #nonFeatureLayerRenderCompleted(lv: __esri.LayerView) {
        watchUtils.whenTrueOnce(lv, "ready", () => {
            this.props.onSetIsCanvasLoading(false);
        });
    }

    #updateCadastralDataManager(
        dataManager: CadastralDataManager,
        previousLayerInfos: ILayerInfoShape[],
        nextLayerInfos: ILayerInfoShape[],
        extent: Extent
    ) {
        const previousExtent = dataManager.previousExtent;

        if (!_.isEqual(previousLayerInfos, nextLayerInfos)) {
            dataManager.updateLayerInfos(nextLayerInfos);
            return dataManager.update(extent);
        } else if (previousExtent && !geometryEngine.equals(this.mapView.extent, previousExtent)) {
            return dataManager.update(extent);
        }

        return Promise.resolve();
    }

    componentDidMount() {
        esriConfig.apiKey = window.process_env.ESRI_API_KEY;

        const mapOptions = {
            autoResize: true,
            basemap: BasemapUtils.getBasemapById(this.props.basemap),
            // Although `extent` is "optional" in the documentation, without it you get
            //  an (arguably harmless) exception because `map.spatialReference` is undefined
            extent: new Extent({ spatialReference: { wkid: 102100 } }),
        };
        (this.map as any) = new EsriMap({
            ...mapOptions,
        });
        this.mapView = new MapView({
            map: this.map,
            container: this.mapDiv,
            center: MapConfig.defaults.center,
            zoom: this.props.zoom,
            constraints: {
                rotationEnabled: false,
                snapToZoom: true,
                lods: MapConfig.lods as any,
            },
            ui: {
                components: ["attribution"],
            },
            navigation: {
                momentumEnabled: false,
            },
        });

        if (window.process_env.NODE_ENV === "development") {
            (window as any).map = this.map;
        }
        this.#setupListeners(this.mapView);
        const extensions = this.#setupExtensions();
        if (this.props.onSetMap) {
            this.props.onSetMap({ map: this.mapView, ...extensions });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        const {
            activeToolset,
            basemap,
            cadastralLayerInfos,
            fieldBoundaryLayerInfo,
            forceRefreshFlag,
            onClearForceRefresh,
            onSetIsLoading,
            onSetIsCanvasLoading,
            zoom,
        } = this.props;
        const { filteredFieldGuids, selectedFieldGuids, importData } = nextProps;
        const {
            convexHulls,
            ignoreSelectedField,
            importFieldList,
            importFileGuidList,
            importMatchedFieldGuidList,
            importSamplingPoints,
            selectedMatchedFieldGuid,
        } = importData;

        if (zoom !== nextProps.zoom && nextProps.zoom !== this.mapView.zoom) {
            this.mapView.zoom = nextProps.zoom;
        }
        if (basemap !== nextProps.basemap) {
            this.map.basemap = BasemapUtils.getBasemapById(nextProps.basemap);
        }
        if (forceRefreshFlag && !nextProps.forceRefreshFlag) {
            //don't need to call this all again, since this is only true when we are clearing the refresh flag
            return;
        }

        const fieldCentroidsLayer = this.#getCanvasLayer(FIELD_CENTROIDS_CANVAS_LAYER);

        if (this.fieldsLayer) {
            this.fieldsLayer.getCustomer = async (customerGuid) => {
                const response = await SearchAPI.getCustomers({
                    userGuid: nextProps.userGuid,
                    customerGuid: [customerGuid],
                });
                return response.length > 0 ? response[0] : null;
            };
        }

        let fieldGuidListsUpdated = false;
        if (this.filteredFieldGuids !== filteredFieldGuids) {
            this.filteredFieldGuids = filteredFieldGuids;
            fieldGuidListsUpdated = true;
        }
        if (this.selectedFieldGuids !== selectedFieldGuids) {
            this.selectedFieldGuids = selectedFieldGuids;
            fieldGuidListsUpdated = true;
        }

        let importFieldListUpdated = false;
        if (this.matchedFieldGuids !== importMatchedFieldGuidList) {
            this.matchedFieldGuids = importMatchedFieldGuidList;
            fieldGuidListsUpdated = true;
        }
        if (JSON.stringify(this.importFieldList) !== JSON.stringify(importFieldList)) {
            this.importFieldList = importFieldList;
            importFieldListUpdated = true;
        }

        const importSamplingPointsStr = JSON.stringify(importSamplingPoints);
        if (
            this.importSamplingPointsStr !== importSamplingPointsStr ||
            this.selectedMatchedFieldGuid !== selectedMatchedFieldGuid ||
            this.ignoreSelectedField !== ignoreSelectedField
        ) {
            this.importSamplingPointsStr = importSamplingPointsStr;
            this.ignoreSelectedField = ignoreSelectedField;
            this.#updateVisibleImportSamplePoints(nextProps);
        }

        if (
            fieldGuidListsUpdated ||
            this.importFileGuidList !== importFileGuidList ||
            this.selectedMatchedFieldGuid !== selectedMatchedFieldGuid
        ) {
            this.importFileGuidList = importFileGuidList;
            if (
                selectedMatchedFieldGuid ||
                (importSamplingPoints.length === 0 && importMatchedFieldGuidList.length > 0)
            ) {
                const fieldGuids = selectedMatchedFieldGuid
                    ? [selectedMatchedFieldGuid]
                    : importMatchedFieldGuidList;
                onSetIsLoading(true);
                this.fieldsLayer
                    .zoomToFields(fieldGuids)
                    .then(() => {
                        this.#setViewableLayers(nextProps); //Only here for case where zoom doesn't trigger `extent-changed` map event
                    })
                    .finally(() => onSetIsLoading(false));
            }
            this.selectedMatchedFieldGuid = selectedMatchedFieldGuid;
        }

        const focusedFieldGuidSet = this.#getFocusedFieldGuidSet(nextProps);
        const shouldUpdateStatus =
            this.fieldsLayer &&
            (this.props.fieldGuidToEventDetails !== nextProps.fieldGuidToEventDetails ||
                this.props.fieldGuidToRecDetails !== nextProps.fieldGuidToRecDetails ||
                this.props.fieldGuidToAnalysisDetails !== nextProps.fieldGuidToAnalysisDetails ||
                this.props.fieldGuidHasDataSet !== nextProps.fieldGuidHasDataSet);
        if (shouldUpdateStatus) {
            this.fieldsLayer.updateStatus(
                filteredFieldGuids,
                selectedFieldGuids as any,
                focusedFieldGuidSet,
                nextProps.fieldGuidHasDataSet,
                nextProps.batchFieldGuid,
                nextProps.isInBatchWorkflow
            );
        }

        if (this.fieldsLayer && (fieldGuidListsUpdated || importFieldListUpdated)) {
            GeometryUtils.temporarilyDisablePan(this.map);
            clearTimeout(this.fieldCentroisUpdateTimer);
            this.fieldCentroisUpdateTimer = setTimeout(() => {
                CanvasLayerUtils.updateFieldCentroids(
                    fieldCentroidsLayer,
                    filteredFieldGuids,
                    selectedFieldGuids
                );
            }, 150);
            this.fieldsLayer.updateStatus(
                filteredFieldGuids,
                selectedFieldGuids as any,
                focusedFieldGuidSet,
                nextProps.fieldGuidHasDataSet,
                nextProps.batchFieldGuid,
                nextProps.isInBatchWorkflow
            );
        }

        if (
            this.importDataConvexHullLayer &&
            !_.isEqual(this.convexHulls, convexHulls) &&
            !nextProps.isLoading
        ) {
            this.convexHulls = convexHulls;
            onSetIsLoading(true);
            this.importDataConvexHullLayer
                .update(
                    true,
                    nextProps.importData.convexHulls,
                    nextProps.importData.importFileGuidList,
                    nextProps.importData.filteredFieldImportFileList,
                    nextProps.importData.selectedMatchedFieldGuid,
                    nextProps.importWizardType
                )
                .finally(() => onSetIsLoading(false));
        }

        this.layers.forEach((layer) => {
            if (layer.setActiveMapTool) {
                layer.setActiveMapTool(nextProps.activeMapTool);
            }
            if (layer.setActiveModule) {
                layer.setActiveModule(nextProps.activeModule);
            }
            if (layer.setActiveToolset) {
                layer.setActiveToolset(nextProps.activeToolset, nextProps.toolsetPayload);
            }
            if (layer.setIgnoreFarm) {
                layer.setIgnoreFarm(nextProps.importData.ignoreFarm);
            }
        });
        this.canvasLayers.forEach((layer) => {
            if (layer.setActiveMapTool) {
                layer.setActiveMapTool(nextProps.activeMapTool);
            }
        });

        if (
            this.fieldsLayer &&
            !nextProps.isLoading &&
            (nextProps.zoomToCustomerGuid ||
                nextProps.zoomToFieldGuid ||
                nextProps.zoomToFieldGuidList)
        ) {
            onSetIsLoading(true);
            if (nextProps.zoomToCustomerGuid && nextProps.zoomToFarmName) {
                this.fieldsLayer
                    .zoomToFarm(nextProps.zoomToCustomerGuid, nextProps.zoomToFarmName)
                    .then(() => {
                        this.props.onClearZoomToFarm();
                    })
                    .finally(() => onSetIsLoading(false));
            } else if (nextProps.zoomToCustomerGuid) {
                this.fieldsLayer
                    .zoomToCustomer(nextProps.zoomToCustomerGuid, true)
                    .then(() => {
                        this.props.onClearZoomToCustomer();
                    })
                    .finally(() => onSetIsLoading(false));
            } else if (nextProps.zoomToFieldGuidList) {
                this.fieldsLayer
                    .zoomToFields(nextProps.zoomToFieldGuidList)
                    .then(() => {
                        this.props.onClearZoomToFieldList();
                    })
                    .finally(() => onSetIsLoading(false));
            } else {
                console.assert(nextProps.zoomToFieldGuid);
                this.fieldsLayer
                    .zoomToField(nextProps.zoomToFieldGuid)
                    .then(() => {
                        this.props.onClearZoomToField();
                    })
                    .finally(() => onSetIsLoading(false));
            }
        }

        if (this.nonFieldFeaturesLayer && nextProps.zoomToNonFieldFeatures) {
            this.nonFieldFeaturesLayer.zoomToFeatures(nextProps.zoomToNonFieldFeatures as any);
            this.props.onClearZoomToNonFieldFeatures();
        }

        if (this.fieldsLayer) {
            this.fieldsLayer.setBackgroundOnly(nextProps.fieldsBackgroundOnly);
            this.fieldsLayer.setBackgroundOnlyBatch(nextProps.fieldsBackgroundOnlyBatch);
        }

        if (
            this.fieldsLayer &&
            !_.isEqual(fieldBoundaryLayerInfo, nextProps.fieldBoundaryLayerInfo)
        ) {
            this.fieldsLayer.updateFieldBoundaryLayerInfo(nextProps.fieldBoundaryLayerInfo);
        }

        if (this.cadastralDataManager) {
            this.#updateCadastralDataManager(
                this.cadastralDataManager,
                cadastralLayerInfos,
                nextProps.cadastralLayerInfos,
                this.mapView.extent
            );
        }

        if (
            this.fieldBoundaryImportsLayer &&
            (importFieldListUpdated ||
                nextProps.importData.selectedImportFieldIndex !==
                    this.props.importData.selectedImportFieldIndex ||
                nextProps.importData.ignoreFarm !== this.props.importData.ignoreFarm)
        ) {
            onSetIsLoading(true);
            const lessImportFields =
                nextProps.importData.importFieldList.length <
                this.props.importData.importFieldList.length;
            this.fieldBoundaryImportsLayer
                .update(
                    nextProps.importData.importFieldList.length > 0 &&
                        this.props.importData.importFieldList.length === 0,
                    nextProps.importData.importFieldList,
                    nextProps.importData.selectedImportFieldIndex
                )
                .finally(() => {
                    if (this.fieldsLayer && importFieldListUpdated && lessImportFields) {
                        this.#setViewableLayers(nextProps).finally(() => onSetIsLoading(false));
                    } else {
                        onSetIsLoading(false);
                    }
                });
        }

        // Import Data Point Canvas Layer
        const importDataPointLayer = this.#getCanvasLayer(IMPORT_DATA_POINT_CANVAS_LAYER);
        const importDataPointLayerHasData = !importDataPointLayer
            ? false
            : importDataPointLayer.getData().length > 0;

        if (nextProps.importData.importFileGuidList.length === 0 && importDataPointLayerHasData) {
            importDataPointLayer.setData([]);
            importDataPointLayer.setVisibility(false);
        } else if (nextProps.importData.showImportPoints) {
            if (
                importDataPointLayerHasData &&
                !this.isLoadingImportPoints &&
                !nextProps.importData.forceUpdate
            ) {
                if (!_.isEqual(nextProps.importData, this.props.importData)) {
                    this.isLoadingImportPoints = true;
                    onSetIsCanvasLoading(true);
                    CanvasLayerUtils.updateImportPointData(
                        importDataPointLayer.layer,
                        importData
                    ).then(() => {
                        this.isLoadingImportPoints = false;
                        this.mapView.whenLayerView(importDataPointLayer.layer).then((lv) => {
                            watchUtils.whenTrueOnce(lv, "ready", () => {
                                this.props.onSetIsCanvasLoading(false);
                            });
                        });
                        importDataPointLayer.setVisibility(true);
                    });
                }
            } else if (
                (!this.isLoadingImportPoints || nextProps.importData.forceUpdate) &&
                !nextProps.importData.isFieldMatchingLoading
            ) {
                this.isLoadingImportPoints = true;
                this.props.onClearForceUpdate();
                onSetIsCanvasLoading(true);
                CanvasLayerUtils.getImportPointData(nextProps.userGuid, importData)
                    .then((response) => {
                        const importDataPoints = this.#getCanvasLayer(
                            IMPORT_DATA_POINT_CANVAS_LAYER,
                            {
                                rendererOptions: response.rendererOptions,
                            },
                            true
                        );
                        this.mapView.whenLayerView(importDataPoints.layer).then((lv) => {
                            watchUtils.whenTrueOnce(lv, "ready", () => {
                                this.props.onSetIsCanvasLoading(false);
                            });
                        });
                        importDataPoints.setData(response.data);
                        this.isLoadingImportPoints = false;
                        importDataPoints.setVisibility(true);
                    })
                    .catch(this._handleError);
            }
            this.importDataConvexHullLayer.setVisibility(false);
        } else if (!nextProps.importData.showImportPoints && importDataPointLayer) {
            importDataPointLayer.setVisibility(false);
            this.importDataConvexHullLayer.setVisibility(true);
        }

        const filterPointLayer = this.#getCanvasLayer(FILTER_POINT_CANVAS_LAYER);
        const filterPointLayerHasData = !filterPointLayer
            ? false
            : filterPointLayer.getData().length > 0;
        if (!_.isEqual(nextProps.filterData, this.props.filterData)) {
            if (!nextProps.filterData && filterPointLayerHasData) {
                filterPointLayer.setData([]);
                this.props.setFilteredPointCount(0);
                filterPointLayer.setVisibility(false);
            } else if (nextProps.filterData) {
                if (nextProps.filterData.showFilterPoints) {
                    if (filterPointLayerHasData && !this.isLoadingFilterPoints) {
                        this.isLoadingFilterPoints = true;
                        onSetIsCanvasLoading(true);
                        CanvasLayerUtils.updateFilterPointData(
                            filterPointLayer.layer,
                            nextProps.filterData
                        ).then(() => {
                            const filteredCount = filterPointLayer.getData().reduce((sum, pnt) => {
                                return (sum += pnt.attributes.Filtered);
                            }, 0);
                            this.props.setFilteredPointCount(filteredCount);
                            this.isLoadingFilterPoints = false;
                            this.mapView.whenLayerView(filterPointLayer.layer).then((lv) => {
                                watchUtils.whenTrueOnce(lv, "ready", () => {
                                    this.props.onSetIsCanvasLoading(false);
                                });
                            });
                            filterPointLayer.setVisibility(true);
                        });
                    } else if (!this.isLoadingFilterPoints) {
                        this.isLoadingFilterPoints = true;
                        this.props.onClearForceUpdate();
                        onSetIsCanvasLoading(true);
                        CanvasLayerUtils.getFilterPointData(nextProps.filterData)
                            .then((response) => {
                                const filterPoints = this.#getCanvasLayer(
                                    FILTER_POINT_CANVAS_LAYER,
                                    {
                                        rendererOptions: response.rendererOptions,
                                    },
                                    true
                                );
                                this.mapView.whenLayerView(filterPoints.layer).then((lv) => {
                                    watchUtils.whenTrueOnce(lv, "ready", () => {
                                        this.props.onSetIsCanvasLoading(false);
                                    });
                                });
                                filterPoints.setData(response.data);
                                const filteredCount = filterPoints.getData().reduce((sum, pnt) => {
                                    return (sum += pnt.attributes.Filtered);
                                }, 0);
                                this.props.setFilteredPointCount(filteredCount);
                                this.isLoadingFilterPoints = false;
                                filterPoints.setVisibility(true);
                            })
                            .catch(this._handleError);
                    }
                } else if (!nextProps.filterData.showFilterPoints && filterPointLayerHasData) {
                    this.props.setFilteredPointCount(0);
                    filterPointLayer.setVisibility(false);
                }
            }
        }

        const visibleSurfacesStr = JSON.stringify(Array.from(nextProps.visibleSurfaces.entries()));
        const prevVisibleSurfacesStr = JSON.stringify(
            Array.from(this.props.visibleSurfaces.entries())
        );
        let visibleSurfaceChanged = false;
        if (prevVisibleSurfacesStr !== visibleSurfacesStr) {
            fieldGuidListsUpdated = true;
            visibleSurfaceChanged = true;
            this.#updateVisibleSurfaceLayers(nextProps);
        }

        const visibleSampleSitesStr = JSON.stringify(
            Array.from(nextProps.visibleSampleSites.entries())
        );
        if (
            this.visibleSampleSites !== visibleSampleSitesStr ||
            visibleSurfaceChanged ||
            this.props.latestUpdatedEvent !== nextProps.latestUpdatedEvent
        ) {
            fieldGuidListsUpdated = true;
            this.visibleSampleSites = visibleSampleSitesStr;
            this.#updateVisibleSampleSitesLayers(nextProps);
        }

        if (nextProps.forceRefreshFlag) {
            onClearForceRefresh();
            onSetIsLoading(true);
            this.#setViewableLayers(nextProps).finally(() => onSetIsLoading(false));
        } else if (!fieldGuidListsUpdated && activeToolset !== nextProps.activeToolset) {
            this.#setViewableLayers(nextProps);
            //TODO: consider wrapping `_setViewableLayers` with a loading spinner when we have time to do full regression testing
        }

        if (
            nextProps.fieldsBackgroundOnlyBatch !== this.props.fieldsBackgroundOnlyBatch &&
            this.fieldsLayer
        ) {
            this.fieldsLayer.forceRedraw(nextProps.fieldsBackgroundOnlyBatch);
        }

        if (nextProps.invalidatedFieldGuids && nextProps.invalidatedFieldGuids.size > 0) {
            nextProps.invalidatedFieldGuids.forEach((guid) => {
                const layer = this.surfaceLayers.get(guid);
                if (layer) {
                    layer.isInvalid = true;
                    const layerInfo = nextProps.visibleSurfaces.get(guid);
                    if (layerInfo) {
                        layer.update(layerInfo);
                    }
                }
            });
            if (nextProps.clearInvalidatedFields) {
                nextProps.clearInvalidatedFields();
            }
        }

        if (this.nonFieldFeaturesLayer) {
            this.nonFieldFeaturesLayer.setFeatures(nextProps.visibleNonFieldFeatures);
            this.nonFieldFeaturesLayer.setHighlighted(nextProps.highlightedNonFieldFeatures);
            this.nonFieldFeaturesLayer.setCanShowTooltip(nextProps.nonFieldFeaturesCanShowTooltip);
            if (nextProps.nonFieldFeatureLayerIsVisible) {
                this.nonFieldFeaturesLayer.show();
            } else {
                this.nonFieldFeaturesLayer.hide();
            }
        }
    }

    componentWillUnmount() {
        this.#removeListeners();
        this.map.destroy();
    }

    render() {
        return (
            <div className="map-div" ref={(m) => (this.mapDiv = m)}>
                <ZoomControl zoom={this.props.zoom} onSetZoomLevel={this.props.onSetZoomAndScale} />
                <BasemapSelector
                    basemap={this.props.basemap}
                    onSetBasemap={this.props.onSetBasemap}
                />
            </div>
        );
    }
}
