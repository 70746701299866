import React, { useState } from "react";
import { connect } from "react-redux";
import { InjectedIntl, injectIntl } from "react-intl";
import { getActiveModule } from "~/action-panel";
import { Menu } from "~/core";
import { getUser } from "~/login";
import { Confirm } from "@ai360/core";
import { LayerAPI, UserAPI } from "@ai360/core";
import * as analysisActions from "../analysis-info/actions";
import { messages } from "../i18n-messages";
import "./layer-item-context-menu.css";
import AnalysisBatchDeleteDialogBox from "../analysis-info/analysis-batch-delete-dialog-modal";
import AnalysisDeleteDialogBox from "../analysis-info/analysis-delete-dialog-modal";

interface ILayerItemContextMenuProps {
    fieldGuid: string;
    onEditProps: () => void;
    layer: LayerAPI.ILayer;
    onDeleteAnalysisLayer: (
        analysisLayerGuid: string,
        fieldGuid: string,
        layerType: string
    ) => void;
    type: number;
    activeModule: string;
    onShowAnalysisLayer: (analysisInfoGuid: string) => void;
    userInfo: UserAPI.IUser;
    intl: InjectedIntl;
}

const LayerItemContextMenu = (props: ILayerItemContextMenuProps): JSX.Element => {
    //state variables
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [analysisLayerGuid, setAnalysisLayerGuid] = useState<string>("");
    const [confirmationType, setConfirmationType] = useState<Confirm>(null);

    const onDeleteSingle = () => {
        const { fieldGuid, layer } = props;
        if (confirmationType) {
            if (analysisLayerGuid) {
                props.onDeleteAnalysisLayer(analysisLayerGuid, fieldGuid, layer.layerType);
            }
        }
        closeConfirmDialog();
    };

    const closeConfirmDialog = () => {
        setAnalysisLayerGuid("");
        setConfirmationType(Confirm.NONE);
        setIsConfirmationModalOpen(false);
    };

    const getImagerySetupItems = () => {
        const { layer, onShowAnalysisLayer } = props;
        const { formatMessage } = props.intl;
        return [
            {
                label: formatMessage(messages.editImagerySetup),
                action: () => onShowAnalysisLayer(layer.analysisLayerGuid),
            },
            {
                label: formatMessage(messages.deleteImagerySetup),
                action: () => openConfirmDialog(Confirm.DELETE, layer.analysisLayerGuid),
            },
        ];
    };

    const getMenuItems = () => {
        const { layer, userInfo } = props;
        const editMenuItems = [];

        if (userInfo.role.layerAnalysis && isAnalysisLayer(layer)) {
            if (userInfo.role.analysisLayers) {
                editMenuItems.push(...getImagerySetupItems());
            }
        }

        return editMenuItems.map((menuItem, key) => {
            return { ...menuItem, key };
        });
    };

    const openConfirmDialog = (confirmationType, analysisLayerGuid) => {
        setAnalysisLayerGuid(analysisLayerGuid ? analysisLayerGuid : null);
        setConfirmationType(confirmationType);
        setIsConfirmationModalOpen(true);
    };

    const isBatched = () => {
        const { layer } = props;
        const surfaceInfo = layer?.subLayers[0];
        return (
            surfaceInfo &&
            isAnalysisLayer(layer) &&
            surfaceInfo.analysisInBatch &&
            surfaceInfo.analysisInBatch.length > 1
        );
    };

    const isAnalysisLayer = (layer: LayerAPI.ILayer) => {
        return layer.analysisLayerGuid && layer.analysisLayerGuid.length > 0;
    };

    const deleteDialogBox = () => {
        const { layer } = props;
        const surfaceInfo = layer?.subLayers[0];
        const useBatchDialogue = isBatched();
        if (surfaceInfo && useBatchDialogue) {
            return (
                <AnalysisBatchDeleteDialogBox
                    closeConfirmDialog={() => closeConfirmDialog()}
                    isConfirmationModalOpen={isConfirmationModalOpen}
                    onDeleteSingle={() => onDeleteSingle()}
                    surfaceInfo={surfaceInfo}
                ></AnalysisBatchDeleteDialogBox>
            );
        }
        return (
            <AnalysisDeleteDialogBox
                closeConfirmDialog={() => closeConfirmDialog()}
                isConfirmationModalOpen={isConfirmationModalOpen}
                onDeleteSingle={() => onDeleteSingle()}
            ></AnalysisDeleteDialogBox>
        );
    };

    return (
        <div className="context-menu-container">
            <Menu
                className="context-menu layer-context-menu"
                isDotMenu={true}
                getMenuItems={() => getMenuItems()}
            />
            {deleteDialogBox()}
        </div>
    );
};

const mapStateToProps = (state, props: Partial<ILayerItemContextMenuProps>) => ({
    ...props,
    activeModule: getActiveModule(state),
    userInfo: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onShowAnalysisLayer: (analysisInfoGuid) =>
        dispatch(analysisActions.showAnalysisInfo(analysisInfoGuid)),
    onDeleteAnalysisLayer: (analysisLayerGuid, fieldGuid, layerType) =>
        dispatch(analysisActions.deleteAnalysisLayer(analysisLayerGuid, fieldGuid, layerType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LayerItemContextMenu));
