import React, { Component, ReactElement, ReactNode } from "react";
import { RadioButton } from "./radio-button";

export interface IRadioButtonGroupProps {
    selectedValue?: string | boolean;
    afterOnChange?: (targetValue: string | boolean) => void;
    children?: ReactNode;
    required?: boolean;
    ternary?: boolean;
    disabled?: boolean;
    className?: string;
}

export interface IRadioButtonGroupState {
    selectedValue: string | boolean;
    disabled: boolean;
}

export class RadioButtonGroup extends Component<IRadioButtonGroupProps, IRadioButtonGroupState> {
    static defaultProps = {
        selectedValue: "",
        disabled: false,
        required: false,
        ternary: false,
    };

    constructor(props: IRadioButtonGroupProps) {
        super(props);
        this.state = {
            selectedValue: this.props.selectedValue,
            disabled: this.props.disabled,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IRadioButtonGroupProps): void {
        if (
            nextProps.selectedValue !== this.state.selectedValue ||
            nextProps.selectedValue !== this.props.selectedValue
        ) {
            this.setState({
                selectedValue: nextProps.selectedValue,
            });
        }

        if (nextProps.disabled !== this.props.disabled) {
            this.setState({
                disabled: nextProps.disabled,
            });
        }
    }

    onChange = (event: MouseEvent, targetValue?: boolean): void => {
        this.setState({ selectedValue: targetValue });
        if (this.props.afterOnChange) {
            this.props.afterOnChange(targetValue);
        }
    };

    render(): ReactElement {
        const childrenWithProps = React.Children.map(
            this.props.children as ReactElement[],
            (child) => {
                if (child.type === RadioButton) {
                    return React.cloneElement(child, {
                        checked: this.state.selectedValue === child.props.value,
                        selectedValue: this.state.selectedValue,
                        onChange: this.onChange,
                        disabled: child.props.disabled ? child.props.disabled : this.state.disabled,
                        ternary: this.props.ternary,
                    });
                } else {
                    return child;
                }
            }
        );

        return <div className={this.props.className}>{childrenWithProps}</div>;
    }
}
