import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import viewResolver from "~/utils/routing/view-resolver";
import SlidingPanelHeader from "./data/header-container";
import "./sliding-panel.css";
import "./sliding-panel-responsive.css";
import { getIsEnabled, getIsExpanded } from "~/action-panel/selectors";
import { getTheOwnerBrandName } from "~/login/selectors";
import { Messages } from "~/navigation/i18n-messages";
import { injectIntl, intlShape } from "react-intl";

interface ISlidingPanelProps {
    children?: JSX.Element[];
    close: () => void;
    component: any;
    currentThemeName?: string;
    closeReportViewer?: () => void;
    data?: Record<string, any>;
    detailPageTitle?: string;
    formatMessage?: (id: string) => string;
    intl: intlShape;
    isEnabled?: boolean;
    isExpanded?: boolean;
    navigateTo: Record<string, any>;
    noHeader?: boolean;
    overRideChildNameCode?: string;
    systemName?: string;
}

interface ISlidingPanelState {
    parentNameCode: string;
    childNameCode: string;
}

export class SlidingPanelView_ extends Component<ISlidingPanelProps, ISlidingPanelState> {
    constructor(props) {
        super(props);
        if (props.navigateTo) {
            const { parentNameCode, childNameCode } = props.navigateTo;
            this.state = {
                parentNameCode,
                childNameCode,
            };
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.navigateTo !== this.props.navigateTo) {
            const { parentNameCode, childNameCode } = nextProps.navigateTo;
            const fromNotification = this.props.navigateTo.childNameCode === "429";
            if (fromNotification && this.props.close) {
                this.props.close();
            }
            this.setState({
                parentNameCode,
                childNameCode,
            });
        }
    }

    closeSidePanel = () => {
        this.setState({
            parentNameCode: null,
            childNameCode: null,
        });
        if (this.props.close) {
            this.props.close();
        }
    };

    getViewComponent = () => {
        if (this.props.component) {
            return {
                component: this.props.component,
                halfScreen: false,
            };
        }
        if (this.state.parentNameCode && this.state.childNameCode) {
            return viewResolver(this.state.parentNameCode, this.state.childNameCode);
        }
        return {
            component: null,
            halfScreen: null,
        };
    };

    render() {
        const { component, halfScreen } = this.getViewComponent() as any;
        if (component) {
            const { children, isEnabled, isExpanded, noHeader, systemName } = this.props;
            let className = "slide";
            if (halfScreen && isExpanded) {
                className = "slide-half";
            }
            const { formatMessage } = this.props.intl;
            const parentName = formatMessage(Messages[this.state.parentNameCode]);
            const { childNameCode, parentNameCode } = this.state;
            const childName = this.props.overRideChildNameCode
                ? formatMessage(Messages[this.props.overRideChildNameCode])
                : formatMessage(Messages[this.state.childNameCode]);
            return (
                <div className={classnames("slide-container", className)}>
                    {noHeader ? null : (
                        <SlidingPanelHeader
                            isEnabled={isEnabled}
                            close={this.closeSidePanel}
                            formatMessage={formatMessage}
                            navigateTo={{
                                parentName,
                                childName,
                                parentNameCode,
                                childNameCode,
                            }}
                            systemName={systemName} //Todo: this should display the Owner Branding Name over the new Branding Name
                        >
                            {children}
                        </SlidingPanelHeader>
                    )}
                    <div className="slide-content-container">
                        {React.createElement(component, { ...this.props })}
                    </div>
                </div>
            );
        }
        return null;
    }
}
export const SlidingPanel = injectIntl(SlidingPanelView_);
const mapStateToProps = (state) => {
    return {
        isEnabled: getIsEnabled(state),
        isExpanded: getIsExpanded(state),
        systemName: getTheOwnerBrandName(state),
    };
};

export default connect<Partial<ISlidingPanelProps>, null, Partial<ISlidingPanelProps>>(
    mapStateToProps
)(SlidingPanel);
