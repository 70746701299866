import React, { Component } from "react";
import PropTypes from "prop-types";
import { messages } from "../../../i18n-messages";
import { Section, SubSection, SelectInput, NumericInput, Bucket, BucketHeader } from "~/core";
import { model } from "../../data";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import * as unitService from "~/core/units/unit-names";
import { adminData, GUID, NAME } from "~/admin/data";
export default class Application extends Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        onSelectInputChange: PropTypes.func,
        onTextChange: PropTypes.func,
        onNumberChange: PropTypes.func,
        equipmentSetup: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            equipmentSetup: {},
            [unitService.UNIT_LENGTH]: prepareSelectableOptions(props[unitService.UNIT_LENGTH], {
                guid: GUID,
                label: NAME,
            }),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.equipmentSetup) !== JSON.stringify(this.props.equipmentSetup)
        ) {
            this.setState({
                equipmentSetup: nextProps.equipmentSetup,
            });
        }
        if (nextProps[unitService.UNIT_LENGTH] !== this.props[unitService.UNIT_LENGTH]) {
            this.setState({
                [unitService.UNIT_LENGTH]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_LENGTH],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    }

    render() {
        const { formatMessage, onSelectInputChange, onTextChange, onNumberChange } = this.props;
        const { equipmentSetup } = this.state;
        return (
            <Bucket
                className="form-section-bucket"
                showSymbol={true}
                isCollapsible={true}
                isExpanded={false}
            >
                <BucketHeader>{formatMessage(messages.application)}</BucketHeader>
                <Section>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.totalApplicationWidth)}
                            value={equipmentSetup[model.PROPS_TOTAL_APPLICATION_WIDTH]}
                            onChange={(value) => {
                                onTextChange(model.PROPS_TOTAL_APPLICATION_WIDTH, value);
                            }}
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.totalApplicationWidthUnit)}
                            options={this.state[unitService.UNIT_LENGTH]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_TOTAL_APPLICATION_WIDTH_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_TOTAL_APPLICATION_WIDTH_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection className="column-3-gutter">
                        <NumericInput
                            scale={0}
                            precision={4}
                            placeholderText={formatMessage(messages.numberOfSections)}
                            value={equipmentSetup[model.PROPS_BOOM_NUMBER_OF_SECTIONS]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_BOOM_NUMBER_OF_SECTIONS, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.sectionLength)}
                            value={equipmentSetup[model.PROPS_BOOM_SECTION_LENGTH]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_BOOM_SECTION_LENGTH, value);
                            }}
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.sectionLengthUnit)}
                            options={this.state[unitService.UNIT_LENGTH]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_BOOM_SECTION_LENGTH_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_BOOM_SECTION_LENGTH_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection className="column-3-gutter">
                        <NumericInput
                            scale={0}
                            precision={4}
                            placeholderText={formatMessage(messages.nozzlesPerSection)}
                            value={equipmentSetup[model.PROPS_BOOM_NOZZLES_PER_SECTION]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_BOOM_NOZZLES_PER_SECTION, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.nozzleSpacing)}
                            value={equipmentSetup[model.PROPS_NOZZLE_SPACING]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_NOZZLE_SPACING, value);
                            }}
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.unit)}
                            options={this.state[unitService.UNIT_LENGTH]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid: equipmentSetup[model.PROPS_NOZZLE_SPACING_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_NOZZLE_SPACING_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
            </Bucket>
        );
    }
}
