import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { Loader } from "~/core";
import { ThemeRoot } from "~/theme";

import * as actions from "../actions";
import * as selectors from "../selectors";

import "./login.css";

export interface IGetMobileApp_Props {
    location?: any;
    mobileAppDetails?: any;
    onInitialMobileLoad?: () => void;
    intl: intlShape;
    history?: any;
}
class GetMobileApp_ extends Component<IGetMobileApp_Props> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.onInitialMobileLoad();
    }

    render() {
        const { mobileAppDetails } = this.props;
        const iOS =
            (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream) ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
        const urlPrefix =
            mobileAppDetails.iOS_Url &&
            mobileAppDetails.iOS_Url.toLowerCase().indexOf(".plist") > -1
                ? "itms-services://?action=download-manifest&url="
                : "";

        return (
            <ThemeRoot className="mobile-root">
                {!mobileAppDetails || !mobileAppDetails.iOS_Url ? (
                    <Loader />
                ) : (
                    <div className="info">
                        <div className="box-title">Get Mobile App</div>
                        {!(iOS && mobileAppDetails.iOS_Url) ? null : (
                            <button className="allowedDownload">
                                <a
                                    href={`${urlPrefix}${mobileAppDetails.iOS_Url}`}
                                    className="downloadLink"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Tap to Install
                                </a>
                            </button>
                        )}
                        {iOS ? null : (
                            <div className="blockedDownload">
                                Must be on an iOS device to install
                            </div>
                        )}
                        {!mobileAppDetails.build_Version ? null : (
                            <div className="version">
                                <div>{`Build: ${mobileAppDetails.build_Version}`}</div>
                                {mobileAppDetails.build_Date === "" ? null : (
                                    <div>{`(${mobileAppDetails.build_Date})`}</div>
                                )}
                            </div>
                        )}
                        <div className="message">
                            If you already have the latest version of the mobile app installed then
                            close the browser and launch the app to login.
                        </div>
                    </div>
                )}
            </ThemeRoot>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mobileAppDetails: selectors.getMobileAppDetails(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitialMobileLoad: () => dispatch(actions.initialMobileLoad()),
    };
};

export const GetMobileApp = connect(mapStateToProps, mapDispatchToProps)(injectIntl(GetMobileApp_));
