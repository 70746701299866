export const updateSelectedItemGuidSet = (
    dimIdxIter: Record<string, any>[],
    getFieldGuidFromDimIdx: (number) => string,
    getItemGuidFromDimIdx: (number) => string,
    selectedItemGuidSet: Record<string, any>[],
    fieldGuidToSelectedItemGuidSetMap: Map<any, any>,
    updateSet: (prevSet, selectedSet) => any
): Record<string, any>[] => {
    const allGuidsList = [];
    const fieldGuidToItemGuidSetMap = new Map();
    for (const dimIdx of dimIdxIter) {
        console.assert(dimIdx.length === 2);
        const fieldGuid = getFieldGuidFromDimIdx(dimIdx.slice(0, 1));
        const itemGuid = getItemGuidFromDimIdx(dimIdx);
        if (!fieldGuidToItemGuidSetMap.has(fieldGuid)) {
            fieldGuidToItemGuidSetMap.set(fieldGuid, []);
        }
        fieldGuidToItemGuidSetMap.get(fieldGuid).push(itemGuid);
        allGuidsList.push(itemGuid);
    }

    fieldGuidToSelectedItemGuidSetMap = new Map(fieldGuidToSelectedItemGuidSetMap);
    for (const [fieldGuid, selectedSet] of fieldGuidToItemGuidSetMap.entries()) {
        const prevSelected = fieldGuidToSelectedItemGuidSetMap.get(fieldGuid) || new Set();
        fieldGuidToSelectedItemGuidSetMap.set(fieldGuid, updateSet(prevSelected, selectedSet));
    }

    selectedItemGuidSet = updateSet(selectedItemGuidSet, allGuidsList);
    return [selectedItemGuidSet, fieldGuidToSelectedItemGuidSetMap];
};
