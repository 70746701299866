import { all, fork, put, select, takeEvery } from "redux-saga/effects";

import { OwnerAPI, APIError } from "@ai360/core";
import * as actions from "./actions";
import { setApiResult } from "~/core/api/actions";
// selectors
import { getUser } from "~/login/selectors";
import { ownerSagas as defaultSagas } from "./controller";

export function* requestAddOwner({ payload }) {
    const LoggedInUser = yield select(getUser);
    const requestOptions = { LoggedInUser, Model: payload };
    try {
        const response = yield OwnerAPI.addOwner(requestOptions);
        yield put(actions.addOwnerSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        console.warn(`Failed to process action ${actions.addOwner().type}`);
        yield put(actions.addOwnerFailed(error));
    }
}

export function* watchAddOwner() {
    yield takeEvery(actions.addOwner, requestAddOwner);
}

export const ownerSagas = function* () {
    yield all([fork(defaultSagas), fork(watchAddOwner)]);
};
