import PropTypes from "prop-types";
import { model } from "~/admin/setup/equation-parameter/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_CAN_DELETE]: PropTypes.string,
    [model.PROPS_EQUATION_PARAMETER_DATA_TYPE]: PropTypes.string,
    [model.PROPS_EQUATION_PARAMETER_GUID]: PropTypes.string,
    [model.PROPS_EQUATION_PARAMETER_LABEL]: PropTypes.string,
    [model.PROPS_EQUATION_PARAMETER_NAME]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
};

export default PropTypes.shape(shape);
