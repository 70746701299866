import { defineMessages } from "react-intl";

export const messages = defineMessages({
    accountForcefullyReset: {
        id: "login.accountForcefullyReset",
        defaultMessage:
            "Unfortunately, this account's password has been reset due to maximum number of incorrect attempts.",
    },
    accountInactive: {
        id: "login.accountInactive",
        defaultMessage: "Unfortunately, this account has been marked as inactive.",
    },
    accountLocked: {
        id: "login.accountLocked",
        defaultMessage: "This account is locked.",
    },
    answerRequired: {
        id: "login.answerRequired",
        defaultMessage: "Please provide an answer for the security question.",
    },
    companyNameLbl: {
        id: "login.companyNameLbl",
        defaultMessage: "Company Name",
    },
    confirmPasswordLbl: {
        id: "login.confirmPasswordLbl",
        defaultMessage: "Confirm Password",
    },
    enterNew: {
        id: "login.enterNew",
        defaultMessage: "Please enter your new password.",
    },
    eulaTitle: { id: "login.eulaTitle", defaultMessage: "EULA" },
    forgotPasswordLnk: {
        id: "login.forgotPasswordLnk",
        defaultMessage: "Forgot Password?",
    },
    incorrectAnswer: {
        id: "login.incorrectAnswer",
        defaultMessage: "Incorrect answer, please try again.",
    },
    incorrectAnswers: {
        id: "login.incorrectAnswers",
        defaultMessage:
            "Instructions to reset the password have been sent to the account login email address.",
    },
    inputEmail: {
        id: "login.inputEmail",
        defaultMessage: "Please give us your email to begin the password recovery process.",
    },
    locationText: { id: "login.locationText", defaultMessage: "Location" },
    loginTimeoutWarning: {
        id: "login.loginTimeoutWarning",
        defaultMessage:
            "Warning! The application is about to log you out if you remain inactive for the next {seconds} seconds.",
    },
    mustMatch: {
        id: "login.mustMatch",
        defaultMessage: "Passwords must match.",
    },
    minLength: {
        id: "login.minLength",
        defaultMessage: "Your answer must be at least {count} characters long.",
    },
    noActiveHierarchyAffiliations: {
        id: "login.noActiveHierarchyAffiliations",
        defaultMessage:
            "Unfortunately, this user account is not associated with any active Hierarchy Entities.",
    },
    noneSelected: {
        id: "login.noneSelected",
        defaultMessage: "Please select a Security Question.",
    },
    passwordLbl: { id: "login.passwordLbl", defaultMessage: "Password" },
    passwordRequired: {
        id: "login.passwordRequired",
        defaultMessage: "Please provide a password.",
    },
    passwordReset: {
        id: "login.passwordReset",
        defaultMessage:
            "Please click on the link within the email you were sent to reset your Password.",
    },
    rememberMeLbl: { id: "login.rememberMeLbl", defaultMessage: "Remember Me" },
    resetLabel: {
        id: "login.resetLabel",
        defaultMessage: "Reset your password:",
    },
    securityQuestionLbl: {
        id: "login.securityQuestionLbl",
        defaultMessage: "Security Question:",
    },
    selectCompany: {
        id: "login.selectCompany",
        defaultMessage: "Please select a company.",
    },
    selectSecurityQuestion: {
        id: "login.selectSecurityQuestion",
        defaultMessage: "Please select a security question and provide your answer below.",
    },
    unableToKeepAlive: {
        id: "login.unableToKeepAlive",
        defaultMessage:
            "Warning! Unable to keep session alive ... you will be logged out in {seconds} seconds.",
    },
    unableToRetrieve: {
        id: "login.unableToRetrieve",
        defaultMessage:
            "Unable to get security questions. Please try again later. " +
            "If problem persists, please contact an administrator.",
    },
    unableToResetPassword: {
        id: "login.unableToResetPassword",
        defaultMessage:
            "Unable to reset your password at this time. Please try again later. " +
            "If problem persists, please contact an administrator.",
    },
    updatedText: {
        id: "login.policyUpdatedText",
        defaultMessage: "This EULA was last updated {date}.",
    },
    unknownCode: {
        id: "login.unknownCode",
        defaultMessage: "Unknown security message code",
    },
    usernameLbl: { id: "login.usernameLbl", defaultMessage: "Username" },
    usernameRequired: {
        id: "login.usernameRequired",
        defaultMessage: "Please provide a username.",
    },
    validChars: {
        id: "login.validChars",
        defaultMessage:
            "Password must be at least 8 characters long and contain characters from " +
            "three of the following four categories:",
    },
    validCharsLowerCase: {
        id: "login.validCharsLowerCase",
        defaultMessage: "Lowercase (a through z)",
    },
    validCharsNumber: {
        id: "login.validCharsNumber",
        defaultMessage: "Number (0 through 9)",
    },
    validCharsSpecial: {
        id: "login.validCharsSpecial",
        defaultMessage: "Special Character (@,&,*,!,$,#,%..etc)",
    },
    validCharsUpperCase: {
        id: "login.validCharsUpperCase",
        defaultMessage: "Uppercase (A through Z)",
    },
    validEmail: {
        id: "login.validEmail",
        defaultMessage: "This is not a valid email address.",
    },
});
