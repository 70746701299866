import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";
import classNames from "classnames";
import { connect } from "react-redux";

import { Menu } from "~/core";
import * as selectors from "~/action-panel/components/field-module/selectors";
import * as actions from "~/action-panel/components/field-module/actions";
import { selectors as cdSelectors } from "~/customer-data";
import * as eventListSelectors from "~/action-panel/components/event-module/components/event-list/selectors";
import * as recListSelectors from "~/action-panel/components/rec-module/components/rec-list/selectors";
import * as analysisModels from "~/recs-events/analysis/model";
import { UserAPI } from "@ai360/core";
import { messages as globalMessages } from "~/i18n-messages";

import { DeleteConfDialog } from "../item-delete-confirmation-dialog";
import { ICustomerFieldMapping } from "~/customer-data/models";

export interface IBatchEdit {
    certifiedOrganic: boolean;
    cropGuid: string;
    cropPurposeGuid: string;
    drainageTile: unknown;
    irrigated: boolean;
    irrigationTypeGuid: string;
}

export interface IFieldAccordionContextMenuProps {
    fieldSelectionCount?: number;
    eventSelectionCount?: number;
    recSelectionCount?: number;
    isActiveTabOperationTab?: boolean;
    onAddAnalysisLayer: (fieldGuids: string[], analysisLayerType: string) => void;
    onDeleteSelected?: (ignoreAgvanceConfirmation?: boolean) => boolean;
    fieldDeleteAgvanceOverrideFieldGuids: string[];
    onClearFieldDeleteAgvanceOverride: () => void;
    intl?: Record<string, any>;
    onSelectAll?: () => void;
    onClearAll?: () => void;
    onRecSelection?: () => void;
    onEventSelection?: () => void;
    onExpandAll?: () => void;
    onCollapseAll?: () => void;
    onExportBoundaries?: () => void;
    onExportDetails?: () => void;
    userRole?: UserAPI.IUserRole;
    onBatchEditDetails?: (vals: IBatchEdit) => void;
    fetchingFilteredFieldGuids: boolean;
    filteredFieldGuids: Immutable.Set<string>;
    selectedCustomerFields: ICustomerFieldMapping[];
}

export interface IFieldAccordionContextMenuState {
    isDeleteConfirmationOpen: boolean;
}

const messages = defineMessages({
    selectAllText: {
        id: "fieldAccordion.selectAllText",
        defaultMessage: "Select All",
    },
    batchEditText: {
        id: "fieldAccordion.batchEditText",
        defaultMessage: "Batch Edit Details",
    },
    batchImagerySetupText: {
        id: "fieldAccordion.batchImagerySetupText",
        defaultMessage: "Batch Imagery Setup",
    },
    clearAllText: {
        id: "fieldAccordion.clearAllText",
        defaultMessage: "Clear Selected",
    },
    recSelectionText: {
        id: "fieldAccordion.recSelectionText",
        defaultMessage: "Rec Selection",
    },
    eventSelectionText: {
        id: "fieldAccordion.eventSelectionText",
        defaultMessage: "Event Selection",
    },
    expandAllText: {
        id: "fieldAccordion.expandAllText",
        defaultMessage: "Expand All",
    },
    collapseAllText: {
        id: "fieldAccordion.collapseAllText",
        defaultMessage: "Collapse All",
    },
    deleteSelected: {
        id: "fieldAccordion.deleteSelected",
        defaultMessage: "Delete Selected",
    },
    exportDetails: {
        id: "fieldAccordion.exportDetails",
        defaultMessage: "Export Field Details",
    },
    exportBoundaries: {
        id: "fieldAccordion.exportBoundaries",
        defaultMessage: "Export Boundaries",
    },
    deleteConfirmationMessage: {
        id: "fieldAccordion.deleteConfirmationMessage",
        defaultMessage:
            "Deleting these fields will remove all of their associated data. Are you sure you want to delete the selected fields?",
    },
    deleteConfirmationIgnoreAgvance: {
        id: "fieldAccordion.deleteFieldConfirmationIgnoreAgvance",
        defaultMessage:
            "One or more of these Fields is in an invalid state and no longer connected to Agvance. Are you sure want to delete these Fields?",
    },
});

class FieldAccordionContextMenu_ extends Component<
    IFieldAccordionContextMenuProps,
    IFieldAccordionContextMenuState
> {
    constructor(props: IFieldAccordionContextMenuProps) {
        super(props);
        this.state = {
            isDeleteConfirmationOpen: false,
        };
    }

    getBatchOptions() {
        const { onAddAnalysisLayer, selectedCustomerFields, userRole } = this.props;
        const fieldGuids = selectedCustomerFields.map((x) => x.fieldGuid);
        const { formatMessage } = this.props.intl;
        const options = [];
        options.push({
            key: 7,
            label: formatMessage(messages.batchEditText),
            action: () =>
                this.props.onBatchEditDetails({
                    certifiedOrganic: null,
                    cropGuid: null,
                    cropPurposeGuid: null,
                    drainageTile: null,
                    irrigated: null,
                    irrigationTypeGuid: null,
                }),
        });
        if (userRole.batchImagerySetup) {
            options.push({
                key: 8,
                label: formatMessage(messages.batchImagerySetupText),
                action: () =>
                    onAddAnalysisLayer(
                        Array.from(fieldGuids),
                        analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP
                    ),
            });
        }
        return options;
    }

    render() {
        const {
            fieldSelectionCount,
            eventSelectionCount,
            recSelectionCount,
            isActiveTabOperationTab,
            onDeleteSelected,
            fetchingFilteredFieldGuids,
            filteredFieldGuids,
            fieldDeleteAgvanceOverrideFieldGuids,
            onClearFieldDeleteAgvanceOverride,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const ignoreAgvanceConfirmation = Boolean(fieldDeleteAgvanceOverrideFieldGuids?.length);

        const deleteConfDialog =
            !this.state.isDeleteConfirmationOpen &&
            !(ignoreAgvanceConfirmation && fieldDeleteAgvanceOverrideFieldGuids?.length > 1)
                ? null
                : DeleteConfDialog({
                      className: "dialog-expanded",
                      formatMessage,
                      deleteConfirmationTitle: globalMessages.confirmTitle,
                      deleteConfirmationMessage: ignoreAgvanceConfirmation
                          ? messages.deleteConfirmationIgnoreAgvance
                          : messages.deleteConfirmationMessage,
                      onConfirmationClick: (confirmation) => {
                          const afterClose = !confirmation
                              ? () => null
                              : () => onDeleteSelected(ignoreAgvanceConfirmation);
                          if (ignoreAgvanceConfirmation) {
                              onClearFieldDeleteAgvanceOverride();
                          }
                          this.setState({ isDeleteConfirmationOpen: false }, afterClose);
                      },
                  });

        const contextMenuItems = [];
        if (isActiveTabOperationTab) {
            contextMenuItems.push({
                key: 0,
                disabled: fetchingFilteredFieldGuids || filteredFieldGuids.count() > 5000,
                label: formatMessage(messages.selectAllText),
                action: () => this.props.onSelectAll(),
            });
        }
        contextMenuItems.push({
            key: 1,
            disabled: fieldSelectionCount === 0,
            label: formatMessage(messages.clearAllText),
            action: () => this.props.onClearAll(),
        });

        if (
            recSelectionCount > 0 &&
            isActiveTabOperationTab &&
            this.props.userRole.recommendations
        ) {
            contextMenuItems.push({
                key: 2,
                label: formatMessage(messages.recSelectionText),
                action: () => this.props.onRecSelection(),
            });
        }
        if (eventSelectionCount > 0 && isActiveTabOperationTab && this.props.userRole.agEvents) {
            contextMenuItems.push({
                key: 3,
                label: formatMessage(messages.eventSelectionText),
                action: () => this.props.onEventSelection(),
            });
        }
        contextMenuItems.push({
            key: 4,
            label: formatMessage(messages.expandAllText),
            action: () => this.props.onExpandAll(),
        });
        contextMenuItems.push({
            key: 5,
            label: formatMessage(messages.collapseAllText),
            action: () => this.props.onCollapseAll(),
        });
        if (fieldSelectionCount > 1) {
            contextMenuItems.push(...this.getBatchOptions());
        }

        if (isActiveTabOperationTab && this.props.userRole.manageFields) {
            contextMenuItems.push({
                key: 6,
                disabled: fieldSelectionCount === 0,
                label: formatMessage(messages.deleteSelected),
                action: () => this.setState({ isDeleteConfirmationOpen: true }),
            });
            if (this.props.userRole.exportFieldDetails) {
                contextMenuItems.push({
                    key: 9,
                    disabled: fieldSelectionCount === 0,
                    label: formatMessage(messages.exportDetails),
                    action: () => this.props.onExportDetails(),
                });
            }
            contextMenuItems.push({
                key: 10,
                disabled: fieldSelectionCount === 0,
                label: formatMessage(messages.exportBoundaries),
                action: () => this.props.onExportBoundaries(),
            });
        }
        contextMenuItems.sort((a, b) => a.key - b.key);

        return (
            <div className="context-menu-container">
                <Menu
                    className={classNames(
                        "context-menu",
                        "accordion-context-menu",
                        "field-accordion-context-menu"
                    )}
                    isDotMenu={true}
                    menuItems={contextMenuItems}
                />
                {deleteConfDialog}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isActiveTabOperationTab: selectors.getActiveTabIsOperationTab(state),
    fieldSelectionCount: cdSelectors.getSelectedFieldsCount(state),
    eventSelectionCount: eventListSelectors.getSelectedEventGuidSet(state).size,
    recSelectionCount: recListSelectors.getSelectedRecGuidSet(state).size,
    fetchingFilteredFieldGuids: cdSelectors.getFetchingFilteredFieldGuids(state),
    filteredFieldGuids: cdSelectors.getFilteredFieldGuids(state),
    fieldDeleteAgvanceOverrideFieldGuids: selectors.getFieldDeleteAgvanceOverrideFieldGuids(state),
    selectedCustomerFields: cdSelectors.getSelectedCustomerFields(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClearFieldDeleteAgvanceOverride: () =>
        dispatch(actions.setFieldDeleteAgvanceOverrideModalOpen([])),
    onAddAnalysisLayer: (fieldGuids, analysisLayerType) =>
        dispatch(actions.addAnalysisLayer(fieldGuids, analysisLayerType)),
});

export const FieldAccordionContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FieldAccordionContextMenu_));
