import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const MeasureIcon = withSvgIcon(
    <g>
        <path d="M19 172v155h461V172H19zm442 19v78h-19v39h-19v-39h-20v39h-19v-60h-20v60h-18v-39h-20v39h-19v-39h-20v39h-18v-60h-39v60h-19v-39h-20v39h-18v-39h-20v39h-19v-60h-20v60H96v-39H76v39H57v-39H38v-78h423z" />
        <path d="M403.7 211.3H442v19.2h-38.3z" />
    </g>,
    "70 70 350 350"
);
