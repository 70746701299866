import { adminData } from "~/admin/data";
// Model

export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_AGRIAN_ID = "observationAgrianIdList";
export const PROPS_AGX_ID = "agxId";
export const PROPS_AG_GATEWAY_ID = "agGatewayId";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CLASS = "taxonomicalClass";
export const PROPS_CROP_LIST = "observationCropList";
export const PROPS_DESCRIPTION_SYMPTOMS = "descriptionSymptoms";
export const PROPS_DIVISION = "division";
export const PROPS_FAMILY = "family";
export const PROPS_FAVORABLE_CONDITIONS = "favorableConditions";
export const PROPS_GENUS = "genus";
export const PROPS_GROWTH_STAGE_GROUPID = "growthStageGroupId";
export const PROPS_GROWTH_STAGE_GROUP_GUID = "growthStageGuid";
export const PROPS_KINGDOM = "kingdom";
export const PROPS_LIFESPAN = "lifespan";
export const PROPS_LIFESPAN_GUID = "lifespanGuid";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_MORPHOLOGY_GUID = "morphologyTypeGuid";
export const PROPS_MORPHOLOGY_TYPE = "morphologyType";
export const PROPS_OBSERVATION_ALIAS_LIST = "observationAliasList";
export const PROPS_OBSERVATION_GROUP_NAME = "growthStageGroupName";
export const PROPS_OBSERVATION_GUID = "observationGuid";
export const PROPS_OBSERVATION_ID = "observationId";
export const PROPS_OBSERVATION_NAME = "observationName";
export const PROPS_OBSERVATION_PARENT_NAME = "observationParentName";
export const PROPS_OBSERVATION_PARENT_TYPE = "observationParentType";
export const PROPS_OBSERVATION_PARENT_TYPE_GUID = "observationParentTypeGuid";
export const PROPS_OBSERVATION_PHOTO_LIST = "observationPhotoList";
export const PROPS_OBSERVATION_TYPE = "observationType";
export const PROPS_OBSERVATION_TYPE_GUID = "observationTypeGuid";
export const PROPS_ORDER = "taxonomicalOrder";
export const PROPS_ORGANISM_TYPE_LIST = "observationOrganismTypeList";
export const PROPS_SCIENTIFIC_NAME = "scientificName";
