import React, { Component, MouseEvent, ReactElement } from "react";
import classnames from "classnames";

export interface IToolbarButtonProps {
    className?: string;
    disabled?: boolean;
    icon?: ReactElement;
    label?: string;
    onClick: (e: MouseEvent) => void;
    parentClassName?: string;
}
export class ToolbarButton extends Component<IToolbarButtonProps> {
    static defaultProps = {
        className: "",
        disabled: false,
        label: "Button",
        onClick: (e: MouseEvent): void => {
            console.warn(
                `onClick event for \`${
                    (e.target as HTMLDivElement).innerText
                }\` has not been defined.`
            );
        },
        parentClassName: "",
    };

    _handleClick(e: MouseEvent): void {
        if (!this.props.disabled) {
            this.props.onClick(e);
        }
    }

    render(): JSX.Element {
        const { className, disabled, icon, label, parentClassName } = this.props;
        return (
            <div
                className={classnames(
                    "toolbar-item",
                    "toolbar-button",
                    disabled ? "disabled" : null,
                    parentClassName
                )}
                onClick={(e: MouseEvent) => this._handleClick(e)}
            >
                <div className={classnames("toolbar-item-icon", className)}>{icon}</div>
                <span className="toolbar-item-text">{label}</span>
            </div>
        );
    }
}
