import { IThemeState } from "./reducer";

export const THEME_STATE_KEY = "THEME_STATE";

export const getCurrentTheme = (state: IThemeState): string => {
    return state[THEME_STATE_KEY].themeClassName;
};

export const getIsLoading = (state: IThemeState): boolean => {
    return state[THEME_STATE_KEY].isLoading;
};
