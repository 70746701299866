import { recListReducer } from "./components/rec-list/reducer";
import { REC_LIST_STATE_KEY } from "./components/rec-list/selectors";
import { recInfoReducer } from "./components/rec-info/reducer";
import { REC_INFO_STATE_KEY } from "./components/rec-info/selectors";

import * as actions from "./actions";

export const recModuleInitialState = {
    activePage: actions.RecModulePages.REC_LIST,
    triggeredPage: null,
    [REC_INFO_STATE_KEY]: recInfoReducer(undefined, { type: "INIT" }),
    [REC_LIST_STATE_KEY]: recListReducer(undefined, { type: "INIT" }),
};

export const recModuleReducer = (state = recModuleInitialState, action) => {
    switch (action.type) {
        case actions.SET_ACTIVE_PAGE: {
            const { activePage } = action.payload;
            return Object.freeze({
                ...state,
                activePage,
            });
        }
        case actions.SET_TRIGGERED_PAGE: {
            const { triggeredPage } = action.payload;
            return Object.freeze({
                ...state,
                triggeredPage,
            });
        }
        default: {
            const recInfoState = recInfoReducer(state[REC_INFO_STATE_KEY], action);
            if (recInfoState !== state[REC_INFO_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [REC_INFO_STATE_KEY]: recInfoState,
                });
            }
            const recListState = recListReducer(state[REC_LIST_STATE_KEY], action);
            if (recListState !== state[REC_LIST_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [REC_LIST_STATE_KEY]: recListState,
                });
            }
            return state;
        }
    }
};
