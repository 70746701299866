import { connect } from "react-redux";
import { LOGIN_STATE_KEY } from "../login";

const mapStateToProps = (state) => {
    return {
        userInfo: state[LOGIN_STATE_KEY] && state[LOGIN_STATE_KEY].theUser,
        userRole:
            state[LOGIN_STATE_KEY] && state[LOGIN_STATE_KEY].theUser
                ? state[LOGIN_STATE_KEY].theUser.role
                : {},
    };
};

export const withUser = (View) => connect(mapStateToProps)(View);
