import { getAnalytics, logEvent } from "firebase/analytics";

export const logFirebaseEvent = (eventName) => {
    try {
        const firebaseIsConfigured =
            window.process_env.FB_API_KEY && window.process_env.FB_API_KEY !== "%FB_API_KEY%";
        if (firebaseIsConfigured) {
            const analytics = getAnalytics();
            //_web is appended to all firebase events generated by the app
            logEvent(analytics, `${eventName}_web`, { environment: window.process_env.ENV_NAME });
        }
    } catch (err) {
        /**
            And it's no, nay, never
            No, nay, never, no more
            Will I play the wild rover
            No, never, no more
         */
    }
};
