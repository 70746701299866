import { connect } from "react-redux";
import { actions } from "../data";
import { actions as orgLevelActions, selectors as orgLevelSelectors } from "../../org-level/data";
import { addOwner } from "../data/actions";
import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, service, dropdowns } from "./../data";

const mapStateToProps = (state) => ({
    duplicateDatabaseIdOrgLevels: orgLevelSelectors.getDuplicateDatabaseIdOrgLevels(state),
});

const mapDispatchToProps = () => ({
    // Action to be dispatched using needs hoc
    createOwner: (payload) => actions.createOwner(payload),
    addOwner,
    getOrgLevelWithAgvanceDatabaseId: (payload) =>
        orgLevelActions.getOrgLevelWithAgvanceDatabaseId(payload),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditPanelContainer(AddEditPanel, { service, pickLists, dropdowns }));
