import { createReducer } from "redux-act";
import {
    validateCropListToggle,
    validateEquationToggle,
    testEquationSuccess,
    testEquationFailed,
    setEquationParametersPicklist,
} from "./actions";

export const EQUATION_LIST_DATA_KEY = "EQUATION_LIST_DATA";

export const equationListReducer = createReducer(
    {
        [validateCropListToggle]: (state, payload = false) => ({
            ...state,
            validCropList: payload,
        }),
        [validateEquationToggle]: (state, payload = false) => ({
            ...state,
            validEquation: payload,
        }),
        [testEquationSuccess]: (state, payload) => ({
            ...state,
            testValue: payload,
        }),
        [testEquationFailed]: (state) => ({
            ...state,
            testValue: "",
        }),
        [setEquationParametersPicklist]: (state, payload) => ({
            ...state,
            equationParametersPicklist: payload,
        }),
    },
    {
        testValue: "",
        validCropList: false,
        validEquation: {},
        equationParametersPicklist: new Map(),
    }
);
