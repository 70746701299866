import { all, call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import Immutable from "immutable";

import { actions as accordionActions } from "~/accordion";
import * as actionPanelActions from "~/action-panel/actions";
import {
    setVisibleSurface,
    removeAllVisibleSurfaces,
} from "~/action-panel/components/layer-module/components/layer-list/actions";
import {
    PICKLIST_CROP_PURPOSE,
    PICKLIST_SOIL_TEXTURE,
    PICKLIST_TILLAGE_METHOD,
    PICKLIST_APPLICATION_METHOD,
    PICKLIST_APPLICATION_TIMING,
    PICKLIST_INCORPORATION_DEPTH,
    PICKLIST_BUILD_FACTOR,
    PICKLIST_TARGET_PH,
    PICKLIST_REC_OPTION,
    getPickListCode,
} from "~/core/picklist/picklist-names";

import { getTheUserCompanyGuid, getTheUserGuid } from "~/login";
import { mapActions, mapToolsActions } from "~/map";
import {
    setFieldsBackgroundOnly,
    setFieldsBackgroundOnlyBatch,
} from "~/map/components/map-control/actions";
import { actions as notificationActions } from "~/notifications";
import {
    actions as recsEventsActions,
    models as recsEventsModels,
    recsSelectors,
    selectors as recsEventsSelectors,
    recsModels,
} from "~/recs-events";
import * as recActions from "~/recs-events/recs/actions";
import {
    AnalysisLayerAPI,
    LayerUtilsAPI,
    RecAPI,
    SearchAPI,
    EventAPI,
    LayerAPI,
} from "@ai360/core";
import { Toolset } from "@ai360/core";

import * as recListActions from "../../components/rec-list/actions";
import * as recInfoActions from "../../components/rec-info/actions";
import * as recInfoSelectors from "../../components/rec-info/selectors";
import * as recModuleActions from "../../actions";
import * as actions from "./actions";
import { createBatchRecAccordionItems } from "./components/batch-recs-list";
import * as selectors from "./selectors";
import * as analysisModels from "~/recs-events/analysis/model";
import { BATCH_TEMPLATE_FIELD_GUID } from "~/recs-events/model";
import { RecEquationFilter } from "~/recs-events/recs/model";
import * as eventListActions from "~/action-panel/components/event-module/components/event-list/actions";
import { rxFileImportSaga } from "./components/rec-equation-application/rx-file-import/sagas";
import * as rxFileImportActions from "./components/rec-equation-application/rx-file-import/actions";
import { IAnalysisLayerSummaryItem } from "~/recs-events/analysis/model";

export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
    [PICKLIST_SOIL_TEXTURE]: getPickListCode(PICKLIST_SOIL_TEXTURE),
    [PICKLIST_TILLAGE_METHOD]: getPickListCode(PICKLIST_TILLAGE_METHOD),
    [PICKLIST_APPLICATION_METHOD]: getPickListCode(PICKLIST_APPLICATION_METHOD),
    [PICKLIST_APPLICATION_TIMING]: getPickListCode(PICKLIST_APPLICATION_TIMING),
    [PICKLIST_INCORPORATION_DEPTH]: getPickListCode(PICKLIST_INCORPORATION_DEPTH),
    [PICKLIST_BUILD_FACTOR]: getPickListCode(PICKLIST_BUILD_FACTOR),
    [PICKLIST_TARGET_PH]: getPickListCode(PICKLIST_TARGET_PH),
    [PICKLIST_REC_OPTION]: getPickListCode(PICKLIST_REC_OPTION),
};

export interface LayerItem {
    activeYn?: boolean;
    fullName?: string;
    guid: string;
    id?: string;
    name: string;
    parentGuid?: string;
    physicalStateId?: string;
}

export interface EquationFilterRequest {
    croppingSeasonGuid: string;
    fieldGuid: string;
    equationFilters: RecEquationFilter;
    recType: string;
}

const recDetailsToFieldToolset = (recDetails) => ({
    fieldGuid: recDetails.fieldGuid,
    fieldBoundaryGuid: recDetails.fieldBoundaryGuid,
    customerGuid: recDetails.customerGuid,
});

const fetchRecUnitLists = function* (recType) {
    yield put(recListActions.setRecPanelLoading(true));
    try {
        const userGuid = yield select(getTheUserGuid);
        const isPlantingEquation = recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING;
        const isApplicationEquation =
            recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION;
        const isEquation = isPlantingEquation || isApplicationEquation;

        if (isEquation) {
            yield put(actions.setEquationsLoading(true));
            const equationGroups = yield call(RecAPI.getEquationGroups, userGuid, recType);
            yield put(actions.setEquationGroupList(equationGroups));
            yield put(actions.setFilteredEquationGroupList(equationGroups));
        }
    } catch (error) {
        yield put(notificationActions.apiCallError(error));
    } finally {
        yield put(actions.setRecDetailsLoading(false));
        yield put(actions.setEquationsLoading(false));
    }
};

const getBatchNormalizedLayersMap = (
    layerSummary: IAnalysisLayerSummaryItem[]
): Map<string, IAnalysisLayerSummaryItem[]> => {
    const batchLayerMap = new Map<string, IAnalysisLayerSummaryItem[]>();
    for (const layer of layerSummary) {
        if (batchLayerMap.has(layer.batchGuid)) {
            batchLayerMap.get(layer.batchGuid).push(layer);
        } else {
            batchLayerMap.set(layer.batchGuid, [layer]);
        }
    }
    return batchLayerMap;
};

const getCropListOptions = (cropList, recDetails) => {
    const filteredCropList = recDetails.recGeneralGuid
        ? cropList
        : cropList.filter((opt) => opt.activeYn === true);
    return filteredCropList.map((opt) => ({
        label: opt.name,
        value: opt.guid,
        activeYn: opt.activeYn,
    }));
};

const formatAnalysisToNormalizedLayers = (
    layerSummary: IAnalysisLayerSummaryItem[]
): LayerItem[] => {
    if (!layerSummary || layerSummary.length === 0) {
        return [];
    }
    const uniqueBatchLayers = [
        ...new Map(layerSummary.map((item) => [item.batchGuid, item])).values(),
    ];
    return uniqueBatchLayers.map((x) => {
        return {
            guid: x.batchGuid,
            name: x.name,
        };
    });
};

const getBatchNormalizedLayers = function* (fieldGuidList: string[], userGuid: string) {
    const companyGuid = yield select(getTheUserCompanyGuid);
    const analysisLayerTypes = yield call(LayerAPI.getAnalysisLayerTypes, companyGuid, userGuid);
    const normalizedYieldTypeGuid = analysisLayerTypes.find(
        (t) => t.name === analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD
    )?.analysisLayerTypeGuid;
    const layerSummary: IAnalysisLayerSummaryItem[] = yield call(
        AnalysisLayerAPI.getAnalysisLayerSummary,
        {
            analysisLayerTypeGuid: normalizedYieldTypeGuid,
            field: fieldGuidList,
            batchMatch: true,
        },
        userGuid
    );
    const formattedNormalizedLayers = formatAnalysisToNormalizedLayers(layerSummary);
    const batchNormalizedLayers = getBatchNormalizedLayersMap(layerSummary);
    yield put(recsEventsActions.setBatchAnalysisLayerMap(batchNormalizedLayers));
    return formattedNormalizedLayers;
};

const getNormalizedYieldLayers = function* (
    fieldGuid: string,
    fieldGuidList: string[],
    equationFilterRequest: EquationFilterRequest
) {
    const userGuid = yield select(getTheUserGuid);
    if (fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID) {
        return yield* getBatchNormalizedLayers(fieldGuidList, userGuid);
    } else {
        return yield call(
            RecAPI.getNormalizedYieldLayersByFilteredEquationGroup,
            userGuid,
            equationFilterRequest
        );
    }
};

const onBatchUpdateProductBlend = function* (action) {
    const { productMix, previousMixGA } = action.payload;
    const { fieldGuidToCurrentAreaId } = yield select(recsEventsSelectors.getZonesState);
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);

    const recDetailsToUpdate = [];
    let newRecAreaList = [];

    Array.from(fieldGuidToRecDetails.keys()).forEach((fieldGuid: string) => {
        if (fieldGuid === BATCH_TEMPLATE_FIELD_GUID) {
            return;
        }
        const recDetails = fieldGuidToRecDetails.get(fieldGuid);
        const currentAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);

        const hasProductMixToUpdate = recDetails.recAreaList.some((recArea) =>
            recArea.recs.some((rec) =>
                rec.productMixList?.some(
                    (recProductMix) =>
                        recProductMix.products.length > 1 &&
                        previousMixGA &&
                        ((recProductMix.guaranteedAnalysis === previousMixGA &&
                            productMix.guaranteedAnalysis !== previousMixGA) ||
                            (recProductMix.name === productMix.name &&
                                recArea.recAreaId !== currentAreaId) ||
                            (recProductMix.guaranteedAnalysis === productMix.guaranteedAnalysis &&
                                recProductMix.name !== productMix.name))
                )
            )
        );

        const isPricingUpdate = recDetails.recAreaList.some((recArea) =>
            recArea.recs.some((rec) =>
                rec.productMixList?.some(
                    (recProductMix) =>
                        recProductMix.guaranteedAnalysis === previousMixGA &&
                        productMix.guaranteedAnalysis === previousMixGA &&
                        recProductMix.products.some((rp) => {
                            const matchingProduct = productMix.products.find(
                                (p) => p.productGuid === rp.productGuid
                            );
                            return (
                                matchingProduct.cost !== rp.cost ||
                                matchingProduct.costUnitGuid !== rp.costUnitGuid
                            );
                        })
                )
            )
        );

        if (!hasProductMixToUpdate && !isPricingUpdate) {
            return;
        } else if (isPricingUpdate) {
            newRecAreaList = recDetails.recAreaList.map((recArea) => {
                const newRecList = recArea.recs.map((rec) => {
                    const newRecProductMixList = rec.productMixList?.map((recProductMix) => {
                        if (recProductMix.guaranteedAnalysis !== previousMixGA) {
                            return recProductMix;
                        } else {
                            const newProductsList = recProductMix.products.map((rpmp) => {
                                const matchingProduct = productMix.products.find(
                                    (p) => p.productGuid === rpmp.productGuid
                                );
                                return {
                                    ...rpmp,
                                    cost: matchingProduct.cost,
                                    costUnitGuid: matchingProduct.costUnitGuid,
                                    costUnit: matchingProduct.costUnit,
                                };
                            });
                            return {
                                ...recProductMix,
                                products: newProductsList,
                                targetCost: productMix.targetCost,
                                targetCostUnitGuid: productMix.targetCostUnitGuid,
                            };
                        }
                    });
                    return recsModels.Rec.updateRec(rec, { productMixList: newRecProductMixList });
                });
                return recsModels.RecArea.updateRecArea(recArea, { recs: newRecList });
            });
        } else {
            newRecAreaList = recDetails.recAreaList.map((recArea) => {
                const newRecList = recArea.recs.map((rec) => {
                    const newRecProductMixList = rec.productMixList?.map((recProductMix) => {
                        const shouldOverwrite =
                            (recProductMix.guaranteedAnalysis === previousMixGA ||
                                recProductMix.name === productMix.name) &&
                            recArea.recAreaId !== currentAreaId &&
                            productMix.guaranteedAnalysis !== previousMixGA;

                        const shouldUpdateName =
                            recProductMix.guaranteedAnalysis === previousMixGA &&
                            recProductMix.name !== productMix.name;

                        if (!shouldOverwrite && !shouldUpdateName) {
                            return recProductMix;
                        } else if (!shouldOverwrite && shouldUpdateName) {
                            return {
                                ...recProductMix,
                                name: productMix.name,
                            };
                        } else {
                            return {
                                ...productMix,
                                recGuid: recProductMix.recGuid,
                                productMixGuid: recProductMix.productMixGuid,
                                products: productMix.products.map((p) => ({
                                    ...p,
                                    productMixProductGuid: null,
                                })),
                                nutrients: productMix.nutrients.map((p) => ({
                                    ...p,
                                    productMixNutrientGuid: null,
                                })),
                            };
                        }
                    });
                    return recsModels.Rec.updateRec(rec, { productMixList: newRecProductMixList });
                });
                return recsModels.RecArea.updateRecArea(recArea, { recs: newRecList });
            });
        }

        recDetailsToUpdate.push(
            put(recsEventsActions.updateRecDetails(fieldGuid, { recAreaList: newRecAreaList }))
        );
    });

    if (recDetailsToUpdate.length > 0) {
        yield all(recDetailsToUpdate);
    }
};

const onChangeArea = function* (action) {
    const { fieldGuid, areaId } = action.payload;
    const batchFieldGuid = (yield select(recsEventsSelectors.getZonesState)).batchFieldGuid;
    if (areaId == null) {
        return;
    }
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    if (
        !recDetails ||
        !(
            recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
            recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING
        )
    ) {
        return;
    }

    const recArea = recDetails.recAreaList.find((recArea) => recArea.recAreaId === areaId);

    if (!recArea) {
        return;
    }
    const rec = recArea.recs[0];
    const payload = {
        fieldGuid,
        newProps: { equationFilters: { ...rec.equationFilters } },
    };
    // FIXME: do we really want to issue 12 API calls every time the user switches zones?
    if (
        fieldGuidToRecDetails.size === 1 ||
        (batchFieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID &&
            fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID)
    ) {
        yield call(onUpdateEquationFilterLists, { payload });
    }
};

const onCloseRecInfo = function* (action) {
    const { isCancel } = action.payload;
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const firstRecDetails = [...fieldGuidToRecDetails.values()][0];
    const isEquation =
        firstRecDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
        firstRecDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING;

    if (isEquation) {
        yield put(
            eventListActions.deselectEventsForFieldGuids(
                Array.from(fieldGuidToRecDetails.keys()).filter(
                    (k) => k !== BATCH_TEMPLATE_FIELD_GUID
                )
            )
        );
    }

    if (isCancel) {
        yield put(recsEventsActions.processCancelRec());
    } else {
        yield put(recsEventsActions.clearRecDetails());
    }

    yield put(recInfoActions.setActiveNutrient(null, null));
    yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
    yield put(actionPanelActions.setIsEnabled(true));
    yield put(recInfoActions.setIsProcessingRecNutrient(false));
    yield put(recModuleActions.setActivePage(recModuleActions.RecModulePages.REC_LIST));
    yield put(rxFileImportActions.rxFileImportReset());

    yield put(notificationActions.clearToasterMessages());

    const batchRecInfoAccordionItemCount = yield select(selectors.getAccordionItemsCount);
    if (batchRecInfoAccordionItemCount > 0) {
        const { accordionId } = yield select(selectors.getAccordionState);
        yield put(accordionActions.removeAllAccordionItems(accordionId));
    }

    yield put(setFieldsBackgroundOnly(false));
    yield put(setFieldsBackgroundOnlyBatch(false));
    yield put(actions.setRecDetailsLoading(false));
    yield put(actions.setEquationsLoading(false));
    yield put(mapActions.setIsLoading(false, true));
};

const onCopyBatchTemplateToRecs = function* () {
    yield put(actions.setRecDetailsLoading(true));
    yield take([
        recsEventsActions.SAVE_REC_DETAILS_FAILED,
        recsEventsActions.INITIALIZE_RECS_ZONES_SUCCEEDED,
    ]);

    yield put(actions.setRecDetailsLoading(false));
};

const onCopyRecInfo = function* (action) {
    const { recSummary } = action.payload;
    const { recGeneralGuid } = recSummary;
    yield put(actions.setRecSummary(recSummary.updateImportedStatus(0)));
    yield put(actions.setRecDetailsLoading(true));
    yield put(recModuleActions.setActivePage(recModuleActions.RecModulePages.REC_INFORMATION));
    yield put(recsEventsActions.copyRecDetails(recGeneralGuid));
};

const onCreateNewClassifiedRec = function* () {
    yield put(actions.setRecDetailsLoading(true));
    yield put(recModuleActions.setActivePage(recModuleActions.RecModulePages.REC_INFORMATION));
    yield take(recsEventsActions.CREATE_NEW_REC_DETAILS);
    yield put(actions.setRecDetailsLoading(false));
};

const onCreateNewRecDetails = function* () {
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const { batchRecSummariesForEdit } = yield select(selectors.getModuleState);
    if (fieldGuidToRecDetails.size < 1) {
        return;
    }
    yield put(actionPanelActions.setIsEnabled(false));
    const recDetails = fieldGuidToRecDetails.values().next().value;

    if (batchRecSummariesForEdit && batchRecSummariesForEdit.length > 0) {
        yield put(
            recsEventsActions.setCurrentBatchFieldGuid(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID)
        );
        yield put(
            recsEventsActions.updateRecDetails(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID, {
                recGeneralGuid: "BATCH_REC_GENERAL_GUID",
            })
        );
        yield call(fetchRecUnitLists, recDetails.recType);
        yield put(recModuleActions.setActivePage(recModuleActions.RecModulePages.REC_INFORMATION));
        return;
    }
    if (fieldGuidToRecDetails.size > 1) {
        yield put(
            recsEventsActions.setCurrentBatchFieldGuid(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID)
        );
        const { accordionId } = yield select(selectors.getAccordionState);
        const fieldGuidList = [...fieldGuidToRecDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
        );
        yield put(
            accordionActions.addAccordionItemArray(
                accordionId,
                createBatchRecAccordionItems(fieldGuidList)
            )
        );
        yield put(recModuleActions.setActivePage(recModuleActions.RecModulePages.REC_INFORMATION));

        yield call(fetchRecUnitLists, recDetails.recType);

        return;
    }

    const userGuid = yield select(getTheUserGuid);
    const fields: SearchAPI.IFieldResult[] = yield SearchAPI.getFields({
        userGuid,
        fieldGuid: [recDetails.fieldGuid],
    });

    const field = fields[0];

    const recSummary = recsEventsModels.RecSummary.fromRecDetails(
        recDetails,
        field.boundaryId,
        field.name,
        field.farmName,
        field.acres,
        field.customerName
    );

    yield put(actions.setRecSummary(recSummary));
    yield put(recModuleActions.setActivePage(recModuleActions.RecModulePages.REC_INFORMATION));
    yield put(
        mapToolsActions.setActiveToolset(Toolset.ZONE_EDIT, {
            recEventDetails: recDetails,
            field: recDetailsToFieldToolset(recDetails),
        })
    );

    yield call(fetchRecUnitLists, recDetails.recType);

    if (
        recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
        recDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING
    ) {
        const payload = {
            fieldGuid: recSummary.fieldGuid,
            newProps: {
                equationFilters: new recsEventsModels.RecEquationFilter(),
            },
        };
        yield call(onUpdateEquationFilterLists, { payload });
    }
};

const onFetchRecDetailsSucceeded = function* (action) {
    yield put(recModuleActions.setActivePage(recModuleActions.RecModulePages.REC_INFORMATION));
    const { recDetails } = action.payload;
    const field = recDetailsToFieldToolset(recDetails);
    yield put(
        mapToolsActions.setActiveToolset(Toolset.ZONE_EDIT, {
            recEventDetails: recDetails,
            field,
        })
    );
    yield put(recListActions.setRecPanelLoading(false));
    yield put(actions.setRecDetailsLoading(false));
};

const onRefreshEquationFilterLists = function* (action) {
    const { fieldGuid } = action.payload;
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const { fieldGuidToCurrentAreaId } = yield select(recsEventsSelectors.getZonesState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    const currentAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);

    const firstActiveRec = recDetails.recAreaList.find(
        (recArea) => recArea.recAreaId === currentAreaId
    ).recs[0];

    yield call(onUpdateEquationFilterLists, {
        type: action.type,
        payload: {
            fieldGuid,
            newProps: {
                equationFilters: {
                    ...firstActiveRec.equationFilters,
                },
            },
        },
    });
};

const onSaveRecInfo = function* () {
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const firstRecDetails = [...fieldGuidToRecDetails.values()][0];
    const isEquation =
        firstRecDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
        firstRecDetails.recType === recsEventsModels.REC_TYPE_NAME_EQUATION_PLANTING;

    if (!isEquation) {
        yield put(actions.setRecDetailsLoading(true));
    } else {
        yield put(actions.setEquationsLoading(true));
        yield put(mapToolsActions.setToolsetDisabled(true));
        yield put(
            eventListActions.deselectEventsForFieldGuids(
                Array.from(fieldGuidToRecDetails.keys()).filter(
                    (k) => k !== BATCH_TEMPLATE_FIELD_GUID
                )
            )
        );
    }

    yield put(recsEventsActions.saveRecDetails());
    const resultAction = yield take([
        recsEventsActions.SAVE_REC_DETAILS_FAILED,
        recsEventsActions.SAVE_REC_DETAILS_SUCCEEDED,
    ]);
    if (
        resultAction.type === recsEventsActions.SAVE_REC_DETAILS_SUCCEEDED &&
        !resultAction.payload.isEquation
    ) {
        yield put(actions.setGeneralAdjustments(false));
        yield put(actions.setPermanentAdjustments(false));
        yield put(actions.closeRecInfo(false, true));
    }
    yield put(actions.setRecDetailsLoading(false));
    yield put(actions.setEquationsLoading(false));
    if (isEquation) {
        yield put(mapToolsActions.setToolsetDisabled(false));
    }
};

const onSetActiveNutrient = function* (action) {
    const { fieldGuid, nutrientGuid } = action.payload;

    try {
        if (nutrientGuid) {
            const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
            const userGuid = yield select(getTheUserGuid);
            const recDetails = fieldGuidToRecDetails.get(fieldGuid);

            if (recDetails && recDetails.recGeneralGuid) {
                const nutrientSubLayer = yield call(RecAPI.getRecSurfaceSymbology, userGuid, {
                    recGeneralGuid: recDetails.recGeneralGuid,
                    nutrientGuid,
                });
                if (nutrientSubLayer) {
                    yield put(
                        setVisibleSurface(
                            fieldGuid,
                            LayerUtilsAPI.getSlimSurfaceInfo(nutrientSubLayer)
                        )
                    );
                } else {
                    yield put(actions.setActiveNutrient(null, null));
                }
            }
        } else {
            yield put(removeAllVisibleSurfaces());
        }
    } catch (err) {
        // Suppressing this error to hide the lack of surface symbology for a rec/nutrient that had a successful run but
        // for some reason still lacks symbology. -CH (DBI 6967)
        //yield put(notificationActions.apiCallError(err, action));
    }
};

const onSetActiveEquationGroup = function* (action) {
    const { equationGroup, recEventDetails, updatePreferences } = action.payload;
    if (updatePreferences) {
        const userGuid = yield select(getTheUserGuid);
        try {
            yield call(RecAPI.updateUserEquationPreferences, userGuid, equationGroup);
        } catch (err) {
            yield put(notificationActions.apiCallError(err, action));
        }
    }
    const { equationGroupGuid } = equationGroup;
    const samplingDepthList = yield select(recInfoSelectors.getSamplingDepthList);
    const depthId =
        samplingDepthList && samplingDepthList.length > 1 ? samplingDepthList[0].value : null;
    const recAreaList = recEventDetails.recAreaList.map((recArea) => {
        return recsModels.RecArea.updateRecArea(recArea, {
            recs: recArea.recs.map((rec) => {
                return rec.updateRecModel({
                    equationGroupGuid,
                    equationFilters: {
                        ...rec.equationFilters,
                        activeEquationGroupGuid: equationGroupGuid,
                    },
                    depthId: depthId,
                });
            }),
        });
    });
    yield put(recsEventsActions.updateRecDetails(recEventDetails.fieldGuid, { recAreaList }));
};

const onSetCurrentBatchFieldGuid = function* (action) {
    const { batchFieldGuid } = action.payload;
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);

    if (!fieldGuidToRecDetails.has(batchFieldGuid)) {
        return;
    }
    const isBatchTemplate = batchFieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;

    const recDetails = fieldGuidToRecDetails.get(batchFieldGuid);
    const userGuid = yield select(getTheUserGuid);
    const fields: SearchAPI.IFieldResult[] = isBatchTemplate
        ? []
        : yield SearchAPI.getFields({
              userGuid,
              fieldGuid: [recDetails.fieldGuid],
          });

    const fieldInfo = isBatchTemplate ? ({} as SearchAPI.IFieldResult) : fields[0];

    const recSummary = recsEventsModels.RecSummary.fromRecDetails(
        recDetails,
        fieldInfo.boundaryId,
        fieldInfo.name,
        fieldInfo.farmName,
        fieldInfo.acres,
        fieldInfo.customerName
    );
    yield put(actions.setRecSummary(recSummary));
    yield put(recActions.setLastRecBatchFieldGuid(batchFieldGuid));
    if (isBatchTemplate) {
        const fieldGuidList = [...fieldGuidToRecDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
        );
        yield put(mapActions.setZoomToFieldList(fieldGuidList));
        yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
        return;
    }

    const field = recDetailsToFieldToolset(recDetails);
    const toolsetPayload = { recEventDetails: recDetails, field };
    yield put(mapToolsActions.setActiveToolset(Toolset.ZONE_EDIT, toolsetPayload));
};

const onShowRecInfo = function* (action) {
    const { recSummary } = action.payload;
    const { recGeneralGuid } = recSummary;
    yield put(actionPanelActions.setIsEnabled(false));
    yield put(recListActions.setRecPanelLoading(true));
    yield put(actions.setRecDetailsLoading(true));
    yield put(actions.setEquationsLoading(true));

    yield call(fetchRecUnitLists, recSummary.recType);
    yield put(actions.setRecSummary(recSummary));

    if (recGeneralGuid) {
        yield put(recsEventsActions.fetchRecDetails(recGeneralGuid));
    }
    yield put(setFieldsBackgroundOnly(true));
};

const onUpdateRecModel = function* (action) {
    const { isCopy, newProps } = action.payload;

    if (!isCopy && newProps.equationFilters) {
        yield call(onUpdateEquationFilterLists, action);
    }
};

const onUpdateEquationFilterLists = function* (action) {
    const { fieldGuid, newProps } = action.payload;
    if (
        !fieldGuid ||
        !newProps.equationFilters ||
        !newProps.equationFilters.activeEquationGroupGuid
    ) {
        return;
    }
    const userGuid = yield select(getTheUserGuid);

    yield put(actions.setEquationsLoading(true));

    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    const { croppingSeasonGuid } = recDetails;
    let fieldGuidList = [];
    if (fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID) {
        fieldGuidList = Array.from(fieldGuidToRecDetails.keys()).filter(
            (guid) => guid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
        );
    } else {
        fieldGuidList.push(fieldGuid);
    }
    const agEventGeneralGuidList = fieldGuidList
        .map((guid) => fieldGuidToRecDetails.get(guid).eventSelectionList.find((e) => e))
        .filter((eventSelection) => eventSelection !== undefined)
        .map((eventSelection) => eventSelection.agEventGeneralGuid);

    // Add Picklist ID as needed below
    const equationFilterRequest = {
        croppingSeasonGuid,
        fieldGuid,
        equationFilters: newProps.equationFilters,
        recType: recDetails.recType,
    };

    yield put(actions.setSamplingDepthList(null));

    let fetchResults;
    try {
        fetchResults = yield all([
            call(RecAPI.getCropListByFilteredEquationGroup, userGuid, equationFilterRequest),
            call(
                RecAPI.getCropClassNameListByFilteredEquationGroup,
                userGuid,
                equationFilterRequest
            ),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_CROP_PURPOSE],
            }),
            call(
                RecAPI.getPreviousCropListByFilteredEquationGroup,
                userGuid,
                equationFilterRequest
            ),
            call(RecAPI.getNextCropListByFilteredEquationGroup, userGuid, equationFilterRequest),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_SOIL_TEXTURE],
            }),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_TILLAGE_METHOD],
            }),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_APPLICATION_METHOD],
            }),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_APPLICATION_TIMING],
            }),
            call(RecAPI.getProductListByFilteredEquationGroup, userGuid, equationFilterRequest),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_INCORPORATION_DEPTH],
            }),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_BUILD_FACTOR],
            }),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_TARGET_PH],
            }),
            call(RecAPI.getPicklistByIdAndFilteredEquationGroup, userGuid, {
                ...equationFilterRequest,
                picklistId: pickLists[PICKLIST_REC_OPTION],
            }),
            all(
                fieldGuidList.map((fieldGuid) =>
                    call(RecAPI.getManagementAreaLayersByFilteredEquationGroup, userGuid, {
                        ...equationFilterRequest,
                        fieldGuid: fieldGuid,
                    })
                )
            ),
            call(getNormalizedYieldLayers, fieldGuid, fieldGuidList, equationFilterRequest),
            newProps.equationFilters.activeEquationGroupGuid
                ? call(RecAPI.getFilteredEquationGroups, userGuid, equationFilterRequest)
                : call(() => Promise.resolve([])),
            call(EventAPI.samplingDepthOptions, {
                fieldGuids: fieldGuidList,
                agEventGeneralGuids: agEventGeneralGuidList,
                croppingSeasonGuid: croppingSeasonGuid,
            }),
        ]);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
    if (fetchResults != null) {
        const [
            cropList,
            cropClassList,
            cropPurposeList,
            previousCropList,
            nextCropList,
            soilTextureList,
            tillageMethodList,
            applicationMethodList,
            applicationTimingList,
            productList,
            incorporationDepthList,
            buildFactorList,
            targetpHList,
            recOptionList,
            managementAreaLayerLists,
            normalizedYieldLayerList,
            filteredEquationGroups,
            samplingDepthList,
        ] = fetchResults;

        const newEquationFilterLists = {
            cropListOptions: getCropListOptions(cropList, recDetails),
            cropClassListOptions: cropClassList.map((opt) => ({
                label: opt.name,
                value: opt.guid,
                activeYn: opt.activeYn,
            })),
            cropPurposeListOptions: cropPurposeList
                .filter((opt) => opt.picklistValueGuid)
                .map((opt) => ({
                    label: opt.value,
                    value: opt.picklistValueGuid,
                    activeYn: opt.activeYn,
                })),
            previousCropListOptions: previousCropList.map((opt) => ({
                label: opt.name,
                value: opt.guid,
                activeYn: opt.activeYn,
            })),
            nextCropListOptions: nextCropList.map((opt) => ({
                label: opt.name,
                value: opt.guid,
                activeYn: opt.activeYn,
            })),
            soilTextureListOptions: soilTextureList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            tillageMethodListOptions: tillageMethodList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            applicationMethodListOptions: applicationMethodList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            applicationTimingListOptions: applicationTimingList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            productListOptions: productList.map((opt) => ({
                label: opt.name,
                value: opt.guid,
                activeYn: opt.activeYn,
            })),
            incorporationDepthListOptions: incorporationDepthList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            buildFactorListOptions: buildFactorList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            targetpHListOptions: targetpHList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            recOptionListOptions: recOptionList.map((opt) => ({
                label: opt.value,
                value: opt.picklistValueGuid,
                activeYn: opt.activeYn,
            })),
            managementAreaLayerOptions: managementAreaLayerLists.flat().map((opt) => ({
                label: opt.name,
                value: opt.guid,
                activeYn: opt.activeYn,
            })),
            normalizedYieldLayerOptions: normalizedYieldLayerList.map((opt) => ({
                label: opt.name,
                value: opt.guid,
                activeYn: opt.activeYn,
            })),
        };

        const samplingDepthListOptions = samplingDepthList.map((opt) => ({
            label: `${opt.depthId} ${
                opt.depthUnitName != null
                    ? `: ${opt.startDepth}-${opt.endDepth} ${opt.depthUnitName}`
                    : ""
            }`,
            value: opt.depthId,
            activeYn: true,
        }));

        // The filter is required if the options list both has length and there is not the option
        //   to clear the selection (represented in the data by an option with a value equal to
        //   the empty string).
        const filterIsRequired = (optionsList) =>
            optionsList.length > 0 && !optionsList.some((opt) => opt.value === "");
        const newEquationFilterRequired = {
            cropRequired: filterIsRequired(newEquationFilterLists.cropListOptions),
            cropClassRequired: false,
            cropPurposeRequired: filterIsRequired(newEquationFilterLists.cropPurposeListOptions),
            previousCropRequired: filterIsRequired(newEquationFilterLists.previousCropListOptions),
            nextCropRequired: filterIsRequired(newEquationFilterLists.nextCropListOptions),
            soilTextureRequired: filterIsRequired(newEquationFilterLists.soilTextureListOptions),
            tillageMethodRequired: filterIsRequired(
                newEquationFilterLists.tillageMethodListOptions
            ),
            applicationTimingRequired: filterIsRequired(
                newEquationFilterLists.applicationTimingListOptions
            ),
            applicationMethodRequired: filterIsRequired(
                newEquationFilterLists.applicationMethodListOptions
            ),
            productRequired: filterIsRequired(newEquationFilterLists.productListOptions),
            incorporationDepthRequired: filterIsRequired(
                newEquationFilterLists.incorporationDepthListOptions
            ),
            buildFactorRequired: filterIsRequired(newEquationFilterLists.buildFactorListOptions),
            targetpHRequired: filterIsRequired(newEquationFilterLists.targetpHListOptions),
            recOptionRequired: filterIsRequired(newEquationFilterLists.recOptionListOptions),
        };

        const managementAreaLayerOptionsMap = Immutable.Map(
            managementAreaLayerLists.map((layerList, i) => [
                fieldGuidList[i],
                layerList.map((opt) => ({
                    label: opt.name,
                    value: opt.guid,
                    activeYn: opt.activeYn,
                })),
            ])
        );

        yield put(actions.setSamplingDepthList(samplingDepthListOptions));
        yield put(actions.setEquationFilterLists(newEquationFilterLists));
        yield put(actions.setEquationFilterRequired(newEquationFilterRequired));
        yield put(actions.setFilteredEquationGroupList(filteredEquationGroups));
        yield put(actions.setManagementAreaLayerOptionsMap(managementAreaLayerOptionsMap));
    }
    yield put(actions.setEquationsLoading(false));
};

const onClearEquationGroupGuid = function* () {
    const { recSummary } = yield select(selectors.getModuleState);
    const { fieldGuid } = recSummary;
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);

    const recAreaList = recDetails.recAreaList.map((recArea) => {
        return recsModels.RecArea.updateRecArea(recArea, {
            recs: recArea.recs.map((rec) => {
                const equationProps = new recsModels.RecEquationFilter();
                const recProps = new recsModels.Rec();
                return rec.updateRecModel({
                    ...recProps,
                    recGuid: rec.recGuid,
                    recGeneralGuid: rec.recGeneralGuid,
                    equationGroupGuid: null,
                    equationFilters: {
                        ...equationProps,
                        recGuid: rec.equationFilters.recGuid,
                        recEquationFilterGuid: rec.equationFilters.recEquationFilterGuid,
                        activeEquationGroupGuid: null,
                    },
                });
            }),
        });
    });

    yield put(recsEventsActions.updateRecDetails(fieldGuid, { recAreaList }));
};

export const recInfoSaga = function* () {
    yield all([
        takeEvery(actions.BATCH_UPDATE_PRODUCT_BLEND, onBatchUpdateProductBlend),
        takeEvery(actions.CLEAR_EQUATION_GROUP_GUID, onClearEquationGroupGuid),
        takeLatest(actions.SHOW_REC_INFO, onShowRecInfo),
        takeLatest(actions.CLOSE_REC_INFO, onCloseRecInfo),
        takeLatest(actions.COPY_REC_INFO, onCopyRecInfo),
        takeLatest(recsEventsActions.CREATE_NEW_CLASSIFIED_REC_DETAILS, onCreateNewClassifiedRec),
        takeLatest(recsEventsActions.COPY_BATCH_TEMPLATE_TO_RECS, onCopyBatchTemplateToRecs),
        takeLatest(actions.SAVE_REC_INFO, onSaveRecInfo),
        takeLatest(recsEventsActions.CURRENT_AREA_IDS_SET, onCreateNewRecDetails),
        takeLatest(recsEventsActions.FETCH_REC_DETAILS_SUCCEEDED, onFetchRecDetailsSucceeded),
        takeEvery(recsEventsActions.UPDATE_REC_MODEL, onUpdateRecModel),
        takeEvery(actions.SET_ACTIVE_NUTRIENT, onSetActiveNutrient),
        takeLatest(actions.SET_ACTIVE_EQUATION_GROUP, onSetActiveEquationGroup),
        takeLatest(recsEventsActions.SET_CURRENT_AREA_ID, onChangeArea),
        takeLatest(recsEventsActions.SET_CURRENT_BATCH_FIELD_GUID, onSetCurrentBatchFieldGuid),
        takeLatest(actions.REFRESH_EQUATION_FILTER_LISTS, onRefreshEquationFilterLists),
        rxFileImportSaga(),
    ]);
};
