import React, { Component } from "react";
import { intlShape } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DialogBox, DialogBoxFooterType } from "~/core";
import {
    getApiErrorCodeList,
    getNewApiErrorCodeList,
    getApiErrorModel,
} from "~/core/api/selectors";
import { withUser } from "~/hocs/with-user";
import { getConfirmMessages } from "~/i18n-error-messages";
import { messages as globalMessages } from "~/i18n-messages";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { AppHelpers } from "@ai360/core";

import Messages from "./i18n-messages";
import { createSagas } from "./sagas";
import { getGridData } from "./selectors";
import { getModelNameToState } from "./utils";
import { SUCCESS, FAILURE, PENDING } from "../needs/utils";

export const createController = (service, actions) => {
    return createSagas(service, actions);
};

export const crudContainer = ({ View, actions, service, useNewApi = false }) => {
    const mapStateToProps = (state, ownProps) => {
        const gridData = getGridData(state);
        let records = {};
        const modelName = getModelNameToState(service.modelName);
        if (gridData && gridData[modelName]) {
            const { gridRows, totalCount, autoSearchList, selectAll, nextId } = gridData[modelName];
            records = {
                records,
                ...{
                    records: gridRows,
                    totalCount,
                    selectAll,
                    autoSearchList,
                    nextId,
                },
            };
        }

        return {
            ...records,
            apiErrors: useNewApi ? getNewApiErrorCodeList(state) : getApiErrorCodeList(state),
            apiErrorModel: getApiErrorModel(state),
            ...ownProps,
        };
    };

    const mapDispatchToProps = (dispatch) => ({
        actions,
        setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
        callActionCreator: (action) => dispatch(action),
    });

    return connect(mapStateToProps, mapDispatchToProps)(withUser(View));
};

export const withCrud = (WrapperComponent, service, actions, useNewApi = false) => {
    class View extends Component {
        static propTypes = {
            actions: PropTypes.object,
            apiErrors: PropTypes.array,
            apiErrorModel: PropTypes.object,
            callActionCreator: PropTypes.func,
            className: PropTypes.string,
            initialRequestOptions: PropTypes.object,
            intl: intlShape.isRequired,
            needs: PropTypes.func,
            parentGuid: PropTypes.string,
            records: PropTypes.array,
            requestIds: PropTypes.object,
            setBreadcrumbs: PropTypes.func,
            showInActive: PropTypes.bool,
            status: PropTypes.string,
            viewBox: PropTypes.string,
        };

        static defaultProps = {
            // Request Options to be used during componentDidMount Operation
            // For other operations either override the operation or use the
            // this.props.fetchRecords
            initialRequestOptions: {},
            showInActive: false,
        };

        static ACTIVE_ONLY = "activeOnly";

        constructor(props) {
            super(props);
            this.state = {
                selectedItems: [],
                isModalOpen: false,
                addEditPanel: {
                    showAddEditPanel: false,
                },
                showActive: !props.showInActive,
                fetchData: false,
                showNoSelectedItemsDialog: false,
                showSelectedItemsDialog: false,
                deleteSelectedOptions: {},
                recordName: "",
                deleteMessage: null,
            };
            this.addEditRequestId = null;
            this.deleteRequestId = null;
            // By default, the active only records should not be fetched
            this.toggleActiveInService(props.showInActive);
        }

        componentDidMount() {
            // If this.props.initialRequestOptions is empty or default
            // static object from service defaultRequestOptions will be used
            this.fetchRecords(this.props.initialRequestOptions);
            this.props.setBreadcrumbs();
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.requestIds && this.addEditRequestId) {
                if (nextProps.requestIds[this.addEditRequestId] === SUCCESS) {
                    this.setState(
                        {
                            fetchData: false,
                        },
                        this.closeSidePanel
                    );
                    this.addEditRequestId = null;
                } else if (nextProps.requestIds[this.addEditRequestId] === FAILURE) {
                    this.setState({
                        fetchData: false,
                        apiErrors: nextProps.apiErrors,
                        apiErrorModel: nextProps.apiErrorModel,
                    });
                    this.addEditRequestId = null;
                }
            }
            this.proccessDeleteRequest(nextProps);
        }

        toggleFetchData = (forced = false) => {
            this.setState({
                fetchData: forced ? forced : !this.state.fetchData,
            });
        };

        proccessDeleteRequest = (nextProps) => {
            if (nextProps.requestIds && this.deleteRequestId) {
                if (nextProps.requestIds[this.deleteRequestId] === SUCCESS) {
                    this.fetchRecords();
                    this.deleteRequestId = null;
                } else if (nextProps.requestIds[this.deleteRequestId] === FAILURE) {
                    this.deleteRequestId = null;
                }
            }
        };

        withNeeds = (action, payload) => {
            const { needs } = this.props;
            if (needs) {
                return needs([action(payload)]);
            } else {
                console.error("Please include withMasked to your component");
            }
        };

        fetchRecords = (requestOptions) => {
            const { parentGuid, actions } = this.props;
            // Because parent guid will only exist for child grids and not the parent grids
            // the parent grid can fetch the data without any payload
            if (Object.hasOwn(this.props, "parentGuid")) {
                const { REQUEST_PAYLOAD_PARENT_GUID } = service;
                this.withNeeds(actions.fetch, {
                    ...requestOptions,
                    [REQUEST_PAYLOAD_PARENT_GUID]: parentGuid,
                });
            } else {
                this.withNeeds(actions.fetch, { ...requestOptions });
            }
        };

        exportData = (selectedItems, clearSelectedList = true) => {
            this.withNeeds(actions.exportData, selectedItems);
            if (clearSelectedList) {
                this.setState({
                    selectedItems: [],
                });
            }
        };
        exportPlantBackRestrictionData = (selectedItems, clearSelectedList = false) => {
            this.withNeeds(actions.exportPlantBackRestrictionData, selectedItems);
            if (clearSelectedList) {
                this.setState({
                    selectedItems: [],
                });
            }
        };
        exportPackageData = (selectedItems, clearSelectedList = false) => {
            this.withNeeds(actions.exportPackageData, selectedItems);
            if (clearSelectedList) {
                this.setState({
                    selectedItems: [],
                });
            }
        };

        onFilterChange = (searchName, searchString, filterQuery) => {
            this.setState({ filterQuery });
            const sortOptions = this.state.sortOptions ? [this.state.sortOptions] : null;
            this.fetchRecords({
                [service.REQUEST_PAYLOAD_PAGE_OPTIONS]: {
                    skip: 0,
                },
                [service.REQUEST_PAYLOAD_FILTER]: filterQuery,
                [View.ACTIVE_ONLY]: this.state.showActive,
                [service.REQUEST_PAYLOAD_SORT_LIST]: sortOptions,
            });
        };

        onPaginatorChange = ({ skip = 0, pageSize = 50, filterQuery = {}, sortOptions = {} }) => {
            this.setState({ skip, pageSize, filterQuery, sortOptions });
            this.fetchRecords({
                [service.REQUEST_PAYLOAD_PAGE_OPTIONS]: {
                    pageSize,
                    skip,
                },
                [service.REQUEST_PAYLOAD_SORT_LIST]: [sortOptions],
                [service.REQUEST_PAYLOAD_FILTER]: filterQuery,
                [View.ACTIVE_ONLY]: this.state.showActive,
            });
        };

        onSortOption = ({ sortOptions, filterQuery, pageSize, skip }) => {
            this.setState({ skip, pageSize, filterQuery, sortOptions });
            this.fetchRecords({
                [service.REQUEST_PAYLOAD_SORT_LIST]: [sortOptions],
                [service.REQUEST_PAYLOAD_FILTER]: filterQuery,
                [service.REQUEST_PAYLOAD_PAGE_OPTIONS]: {
                    pageSize,
                    skip,
                },
                [View.ACTIVE_ONLY]: this.state.showActive,
            });
        };

        getAutoSearchListWithParentGuid = (searchName, searchString, filter) => {
            if (searchName && searchString) {
                const { REQUEST_PAYLOAD_PARENT_GUID, REQUEST_PAYLOAD_FILTER } = service;
                const { parentGuid, actions } = this.props;
                this.withNeeds(actions.autoSearchList, {
                    searchName,
                    searchString,
                    [REQUEST_PAYLOAD_FILTER]: filter,
                    [REQUEST_PAYLOAD_PARENT_GUID]: parentGuid,
                    [View.ACTIVE_ONLY]: this.state.showActive,
                });
            }
        };

        getAutoSearchList = (searchName = "", searchString = "", filter) => {
            if (Object.hasOwn(this.props, "parentGuid")) {
                this.getAutoSearchListWithParentGuid(searchName, searchString, filter);
            } else {
                const { actions } = this.props;
                this.withNeeds(actions.autoSearchList, {
                    searchName,
                    searchString,
                    [service.REQUEST_PAYLOAD_FILTER]: filter,
                    [View.ACTIVE_ONLY]: this.state.showActive,
                });
            }
        };

        onSelect = (selectedItems) => {
            this.setState({ selectedItems });
        };

        openAddEditPanel = (modelName = null, mode = "ADD", recordGuid = null, recordName) => {
            if (modelName) {
                if (mode === "EDIT") {
                    this.setState({
                        addEditPanel: {
                            showAddEditPanel: true,
                            mode,
                            recordGuid,
                            recordName,
                        },
                        apiErrors: [],
                    });
                    return;
                }
                this.setState({
                    addEditPanel: {
                        showAddEditPanel: true,
                        mode,
                    },
                    apiErrors: [],
                });
            }
            return;
        };

        changeMode = (mode = "ADD") => {
            this.setState({
                addEditPanel: {
                    ...this.state.addEditPanel,
                    mode,
                },
            });
            return;
        };

        createRequestOptions = () => {
            const { skip = 0, pageSize = 0, filterQuery = null, sortOptions = null } = this.state;
            const sortOptionVal = sortOptions ? [sortOptions] : null;
            const newOptions = {
                [service.REQUEST_PAYLOAD_SORT_LIST]: sortOptionVal,
                [service.REQUEST_PAYLOAD_FILTER]: filterQuery,
                [service.REQUEST_PAYLOAD_PAGE_OPTIONS]: {
                    pageSize,
                    skip,
                },
            };

            return Object.entries(newOptions).reduce((acc, option) => {
                if (option[1] && !AppHelpers.isEmptyObject(option[1])) {
                    return {
                        ...acc,
                        [option[0]]: option[1],
                    };
                }
                return acc;
            }, {});
        };

        setLastEditedRow = (recordGuid = null) => {
            const lastSelectedRow =
                this.props.records && recordGuid
                    ? this.props.records.find(
                          (record) => service.getDefaultGuid(record) === recordGuid
                      )
                    : null;
            this.setState({
                lastSelectedRow,
            });
        };

        closeSidePanel = () => {
            this.setLastEditedRow(this.state.addEditPanel.recordGuid);
            this.setState(
                {
                    addEditPanel: {
                        showAddEditPanel: false,
                    },
                },
                () => {
                    const newRequestOptions = this.createRequestOptions();
                    this.fetchRecords({
                        [View.ACTIVE_ONLY]: this.state.showActive,
                        ...newRequestOptions,
                    });
                    this.props.setBreadcrumbs();
                }
            );
        };

        // NOTE: This is to be done because submit button lies on the side panel
        // header. To lift state will be expensive for huge forms which is reason
        // fetching the state on submit would be better solution.
        onSubmit = () => {
            this.setState({
                fetchData: true,
            });
        };

        liftRecordData = (data = {}) => {
            if (data && this.props.status !== PENDING) {
                if (this.state.addEditPanel.mode === "ADD") {
                    this.addEditRequestId = this.props.needs([this.props.actions.create(data)]);
                } else {
                    this.addEditRequestId = this.props.needs([this.props.actions.update(data)]);
                }
            }
        };

        toggleActiveInService(value) {
            const activeColumnName = service.activeColumnName || "activeYn";
            if (service._defaultLabels[activeColumnName]) {
                service.toggleColumnVisibility(activeColumnName, value);
            }
        }

        // Since, the records are inconsistent with the activeYn key
        // API Call has to be made to filter the active / inactive records
        toggleActiveInactive = () => {
            const { showActive } = this.state;
            this.setState(
                {
                    showActive: !showActive,
                },
                () => {
                    const newRequestOptions = this.createRequestOptions();
                    this.fetchRecords({
                        [View.ACTIVE_ONLY]: this.state.showActive,
                        ...newRequestOptions,
                    });
                    this.toggleActiveInService(!this.state.showActive);
                }
            );
        };

        onSelectAll = ({ sortOptions, filterQuery }) => {
            this.withNeeds(actions.selectAll, {
                [service.REQUEST_PAYLOAD_SORT_LIST]: [sortOptions],
                [service.REQUEST_PAYLOAD_FILTER]: filterQuery,
                [View.ACTIVE_ONLY]: this.state.showActive,
            });
        };

        toggleModal = (flag) => {
            this.setState({
                [flag]: false,
            });
        };

        getDeleteConfirmMessage = () => {
            const { selectedItems, deleteFromRow, deleteMessage } =
                this.state.deleteSelectedOptions;
            if (deleteMessage) {
                return getConfirmMessages(this.props.intl.formatMessage, deleteMessage);
            } else if (deleteFromRow) {
                return getConfirmMessages(this.props.intl.formatMessage, "confirmDeleteMsg");
            } else {
                return getConfirmMessages(this.props.intl.formatMessage, "validDelete", {
                    count: selectedItems ? selectedItems.length : 0,
                });
            }
        };

        confirmDelete = () => {
            const { filterQuery, sortOptions, selectedItems, pageSize, skip } =
                this.state.deleteSelectedOptions;
            const { withNeeds } = this;
            this.setState(
                {
                    showSelectedItemsDialog: false,
                    deleteSelectedOptions: [],
                    selectedItems: [],
                },
                () => {
                    this.deleteRequestId = withNeeds(actions.deleteRequest, {
                        selectedItems,
                        fetchRequestOptions: {
                            [service.REQUEST_PAYLOAD_PAGE_OPTIONS]: {
                                pageSize,
                                skip,
                            },
                            [service.REQUEST_PAYLOAD_SORT_LIST]: sortOptions ? [sortOptions] : [],
                            [service.REQUEST_PAYLOAD_FILTER]: filterQuery,
                            [View.ACTIVE_ONLY]: this.state.showActive,
                        },
                    });
                }
            );
        };

        deleteSelected = (options) => {
            const { selectedItems, deleteMessage } = options;
            if (selectedItems.length === 0) {
                this.setState({
                    showNoSelectedItemsDialog: true,
                    deleteMessage,
                });
            } else {
                this.setState({
                    showSelectedItemsDialog: true,
                    deleteSelectedOptions: options,
                    deleteMessage,
                });
            }
        };

        render() {
            const {
                changeMode,
                closeSidePanel,
                deleteSelected,
                exportData,
                exportPackageData,
                exportPlantBackRestrictionData,
                fetchRecords,
                getAutoSearchList,
                liftRecordData,
                onFilterChange,
                onPaginatorChange,
                onSelect,
                onSelectAll,
                onSortOption,
                onSubmit,
                openAddEditPanel,
                setLastEditedRow,
                toggleActiveInactive,
                toggleFetchData,
            } = this;
            const {
                addEditPanel,
                apiErrors,
                apiErrorModel,
                fetchData,
                isModalOpen,
                lastSelectedRow,
                recordName,
                selectedItems,
                showNoSelectedItemsDialog,
                showSelectedItemsDialog,
            } = this.state;
            const props = {
                ...this.props,
                addEditPanel,
                apiErrors,
                apiErrorModel,
                changeMode,
                closeSidePanel,
                deleteSelected,
                exportData,
                exportPackageData,
                exportPlantBackRestrictionData,
                fetchData,
                fetchRecords,
                getAutoSearchList,
                isModalOpen,
                lastSelectedRow,
                liftRecordData,
                onFilterChange,
                onPaginatorChange,
                onSelect,
                onSelectAll,
                onSortOption,
                onSubmit,
                openAddEditPanel,
                recordName,
                selectedItems,
                setLastEditedRow,
                toggleActiveInactive,
                toggleFetchData,
            };
            const { formatMessage } = this.props.intl;
            return (
                <div className="content-table-container">
                    <WrapperComponent {...props} />
                    <DialogBox
                        isOpen={showNoSelectedItemsDialog}
                        onClose={() => this.toggleModal("showNoSelectedItemsDialog")}
                        title={formatMessage(globalMessages.alertTitle)}
                    >
                        {formatMessage(Messages.deleteValidation)}
                    </DialogBox>
                    <DialogBox
                        action="delete"
                        unrestricted
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        className="delete-confirm-container"
                        isOpen={showSelectedItemsDialog}
                        onAction={() => this.confirmDelete()}
                        onClose={() => this.toggleModal("showSelectedItemsDialog")}
                        title={formatMessage(globalMessages.confirmTitle)}
                    >
                        <div
                            className="dialog-delete-confirm"
                            dangerouslySetInnerHTML={{
                                __html: this.getDeleteConfirmMessage(),
                            }}
                        />
                    </DialogBox>
                </div>
            );
        }
    }

    return crudContainer({ View, service, actions, useNewApi });
};
