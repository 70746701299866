import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const DragDropIcon = withSvgIcon(
    <g transform="matrix(5,0,0,5,0,0)">
        <polygon
            id="arrow"
            points="71.2,69.7 57.2,55.7 43.1,69.8 46,72.7 55.1,63.6 55.1,100 59.2,100 59.2,63.6 68.3,72.6"
        />
        <path
            id="box"
            d="M71.7,14.6V0H14.4v71.6h13.9v14.6H43v-4.1H32.4V71.6v-4.1V18.7h35.2h4.1h9.8v63.4H70.9v4.1h14.7V14.6H71.7zM28.3,14.6v52.9h-9.8V4.1h49v10.5H28.3z"
        />
    </g>
);
