import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { preventBubbleUp } from "~/admin/utils";
import { DataTable, Button } from "~/core";
import { nestedGridContainer, withMasked, withCrud } from "~/hocs";
import SlidingPanel from "~/sliding-panel/sliding-panel";

import AddEditPanel from "./add-edit/add-edit-container";
import { service, actions } from "./data";
import { messages } from "../i18n-messages";
import { IAgBytesProps, IAgBytesState } from "../interfaces";

class GrowthOrderList_ extends Component<
    IAgBytesProps<typeof actions, typeof AddEditPanel>,
    IAgBytesState
> {
    constructor(props: IAgBytesProps<typeof actions, typeof AddEditPanel>) {
        super(props);
        this.state = {
            showInactive: false,
        };
    }

    UNSAFE_componentWillReceiveProps(
        nextProps: IAgBytesProps<typeof actions, typeof AddEditPanel>
    ): void {
        if (
            nextProps.showInactiveProp !== this.state.showInactive &&
            this.props.toggleActiveInactive
        ) {
            this.setState({
                showInactive: nextProps.showInactiveProp,
            });
            this.props.toggleActiveInactive();
        }
    }

    render(): JSX.Element {
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        return (
            <div>
                <DataTable
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    onRowSelection={this.props.onRowSelection}
                    {...this.props}
                ></DataTable>
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "102",
                                childNameCode: "136-child",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={this.props.onSubmit} />
                            <Button type="cancel" onClick={this.props.closeSidePanel} />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}

export const GrowthOrderList = injectIntl(
    nestedGridContainer(withMasked(withCrud(GrowthOrderList_, service, actions)), service.modelName)
);
