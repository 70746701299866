import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { NumericInput, SelectInput, optionPropType } from "~/core";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";

import { getSetValuesForErrorCodeList } from "../../../../../common/validation-utils";

import { fetchTillageFormPicklists } from "../../actions";
import { getModuleState } from "../../selectors";

import { TillageIcon } from "../icons/tillage";

import "../../../../../common/rec-event-info/rec-event-info.css";
import "./event-tillage-form.css";

const messages = defineMessages({
    tillageFormLabelText: {
        id: "eventModule.eventInfo.tillageFormLabelText",
        defaultMessage: "Tillage",
    },
    tillageMethodPlaceholderText: {
        id: "eventModule.eventInfo.tillageMethodPlaceholderText",
        defaultMessage: "Tillage Method",
    },
    tillageDirectionPlaceholderText: {
        id: "eventModule.eventInfo.tillageDirectionPlaceholderText",
        defaultMessage: "Tillage Direction",
    },
    tillageDepthPlaceholderText: {
        id: "eventModule.eventInfo.tillageDepthPlaceholderText",
        defaultMessage: "Tillage Depth",
    },
    tillageDepthUnitsPlaceholderText: {
        id: "eventModule.eventInfo.tillageDepthUnitsPlaceholderText",
        defaultMessage: "Unit",
    },
});

const { getPickListCode, PICKLIST_ROW_ORIENTATION, PICKLIST_TILLAGE_METHOD } = picklistNames;

export const formLabelMessage = messages.tillageFormLabelText;
export const formLabelIcon = TillageIcon;

const errorCodeToMessageIdSetMap = new Map([
    [327, messages.tillageMethodPlaceholderText], // ErrorCode.EventTillageTillageTypeRequired
    [699, messages.tillageDepthUnitsPlaceholderText], // ErrorCode.TillageDepthUnitRequired
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class EventTillageForm_ extends PureComponent {
    static propTypes = {
        agEventModel: PropTypes.object,
        intl: intlShape.isRequired,
        onFetchPicklists: PropTypes.func.isRequired,
        onFetchTillagePicklists: PropTypes.func.isRequired,
        onUpdateCurrentAgEventAreaAgEventModel: PropTypes.func.isRequired,
        picklistOptionsRowOrientation: PropTypes.arrayOf(optionPropType),
        picklistOptionsTillageMethod: PropTypes.arrayOf(optionPropType),
        picklistOptionsUnits: PropTypes.arrayOf(optionPropType),
        saveEventDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _updateAgEvent(newProps) {
        this.props.onUpdateCurrentAgEventAreaAgEventModel(newProps);
    }

    UNSAFE_componentWillMount() {
        const fetchPicklistNames = [PICKLIST_ROW_ORIENTATION, PICKLIST_TILLAGE_METHOD];

        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = getPickListCode(key);
            return accum;
        }, {});

        this.props.onFetchPicklists(picklistFetchArgObj);

        if (this.props.picklistOptionsUnits.length === 0) {
            this.props.onFetchTillagePicklists();
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    render() {
        const {
            agEventModel,
            /** @type IAgEventTillage */ picklistOptionsRowOrientation,
            picklistOptionsTillageMethod,
            picklistOptionsUnits,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;

        return (
            <div className="event-tillage-form">
                <div className="input-row">
                    <SelectInput
                        required
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        containerClassNames={[
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    messages.tillageMethodPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) => this._updateAgEvent({ tillageMethodGuid: v })}
                        options={picklistOptionsTillageMethod}
                        placeholderText={formatMessage(messages.tillageMethodPlaceholderText)}
                        value={agEventModel.tillageMethodGuid}
                    />
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(v) => this._updateAgEvent({ directionGuid: v })}
                        options={picklistOptionsRowOrientation}
                        placeholderText={formatMessage(messages.tillageDirectionPlaceholderText)}
                        value={agEventModel.directionGuid}
                    />
                </div>
                <div className="input-row">
                    <div>
                        <NumericInput
                            scale={2}
                            precision={5}
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        messages.tillageDepthPlaceholderText
                                    ),
                                },
                            ]}
                            onChange={(strVal, formattedVal, numVal) =>
                                this._updateAgEvent({ depth: numVal })
                            }
                            placeholderText={formatMessage(messages.tillageDepthPlaceholderText)}
                            value={agEventModel.depth}
                        />
                    </div>
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        containerClassNames={[
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    messages.tillageDepthUnitsPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) => this._updateAgEvent({ depthIAGuid: v })}
                        options={picklistOptionsUnits}
                        placeholderText={formatMessage(messages.tillageDepthUnitsPlaceholderText)}
                        value={agEventModel.depthIAGuid}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onFetchTillagePicklists: () => dispatch(fetchTillageFormPicklists()),
    onUpdateCurrentAgEventAreaAgEventModel: (fieldGuid, agEventTransactionTypeGuid, newProps) =>
        dispatch(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                fieldGuid,
                agEventTransactionTypeGuid,
                newProps
            )
        ),
});

const mapStateToProps = (state) => {
    const picklistOptionsRowOrientation = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_ROW_ORIENTATION)
    );
    const picklistOptionsTillageMethod = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_TILLAGE_METHOD)
    );

    const { saveEventDetailsErrorCodeList } = eventsSelectors.getModuleState(state);

    const { eventInfoPicklists } = getModuleState(state);
    const picklistOptionsUnits = eventInfoPicklists.tillageDepthUnitOptions || [];

    return {
        picklistOptionsRowOrientation,
        picklistOptionsTillageMethod,
        picklistOptionsUnits,
        saveEventDetailsErrorCodeList,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateCurrentAgEventAreaAgEventModel: (newProps) =>
        dispatchProps.onUpdateCurrentAgEventAreaAgEventModel(
            ownProps.fieldGuid,
            ownProps.agEventTransactionTypeGuid,
            newProps
        ),
});

export const EventTillageForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(EventTillageForm_));
