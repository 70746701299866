import React from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import classnames from "classnames";

import { NoLink } from "~/core";
import { IAccordionProps, IGetAccordionItemElArgs } from "~/accordion/components/accordion";
import { CloseIcon } from "~/core/icons";

import { model as accordionModels, selectors as accordionSelectors, Accordion } from "~/accordion";

import { messages } from "../i18n-messages";

import * as actions from "../actions";
import * as selectors from "../selectors";
import * as models from "../models";

import "./file-list.css";

const ACCORDION_ITEM_HEIGHT = 22;

export const createAccordionItems = (
    uploadFileInfoList: models.UploadFileInfo[]
): accordionModels.AccordionItem[] => {
    return uploadFileInfoList.map((uploadFileInfo) => {
        const payload = { guid: uploadFileInfo.guid };
        const height = ACCORDION_ITEM_HEIGHT * (uploadFileInfo.errorMessages.length + 1);
        return new accordionModels.AccordionItem(height, true, payload);
    });
};

interface FileUploadAccordionItemProps extends IAccordionProps {
    intl: intlShape;
    onRemoveFile: (itemDimIdx: accordionModels.MultiDimIdx, uploadFileInfoGuid: string) => void;
    uploadFileInfoMap: Map<string, models.UploadFileInfo>;
}

interface GetAccordionItemElProps extends IGetAccordionItemElArgs {
    componentProps: FileUploadAccordionItemProps;
}

const getAccordionItemEl: React.FC<GetAccordionItemElProps> = ({
    key,
    style,
    accordionItem,
    accordionItemDimIdx,
    isStuck,
    componentProps,
}) => {
    const { formatMessage } = componentProps.intl;
    const classNames = ["drag-and-drop-file-uploader-accordion-item", { sticky: isStuck }];

    const { uploadFileInfoMap, onRemoveFile } = componentProps;
    const uploadFileInfo = uploadFileInfoMap.get(accordionItem.payload.guid);
    const nestedDepth = uploadFileInfo.getNestedDepth();
    const spacerWidth = 15 * nestedDepth;
    return (
        <div key={key} style={style} className={classnames(classNames)}>
            {nestedDepth === 0 ? null : (
                <div style={{ minWidth: spacerWidth, maxWidth: spacerWidth }} />
            )}
            <div
                className="remove-icon"
                onClick={() => onRemoveFile(accordionItemDimIdx, accordionItem.payload.guid)}
            >
                <CloseIcon />
            </div>
            <div className="upload-filename-errs" title={uploadFileInfo.fileName}>
                <div>{uploadFileInfo.fileName}</div>
                {uploadFileInfo.errorMessages.map((msgDescriptorFormatArgsPair, idx) => {
                    const [msgDescriptor, formatArgs] = msgDescriptorFormatArgsPair;
                    let msg = msgDescriptor;
                    try {
                        msg = formatMessage(msgDescriptor, formatArgs);
                    } catch (e) {
                        console.error(e);
                    }

                    //const msg = formatMessage(msgDescriptor, formatArgs);
                    return (
                        <div key={idx} className="upload-file-error">
                            {msg}
                        </div>
                    );
                })}
            </div>
            <div className="upload-filesize">{uploadFileInfo.computedSize}</div>
        </div>
    );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onRemoveFile: (itemDimIdx, uploadFileInfoGuid) =>
        dispatch(actions.removeFileFromList(ownProps.stateKey, itemDimIdx, uploadFileInfoGuid)),
});

const mapStateToProps = (state, ownProps) => {
    const moduleState = selectors.getModuleState(state, ownProps.stateKey);
    const accordionState = selectors.getAccordionState(state, ownProps.stateKey);
    const flatIdxMapSelector =
        accordionState != null ? accordionSelectors.flatIdxMapSelector(accordionState) : () => null;
    const dimIdxMapSelector =
        accordionState != null ? accordionSelectors.dimIdxMapSelector(accordionState) : () => null;

    const stateToProps = {
        itemCount: accordionState.recItemCount,
        itemList: accordionState.items,
        totalHeight: accordionState.recHeight,
        getAccordionItemFromFlatIdx: (idx) => flatIdxMapSelector(idx),
        getAccordionItemFromDimIdx: (dimIdx) => dimIdxMapSelector(dimIdx),
        getAccordionItemEl: getAccordionItemEl,

        // pass-thru prop; <Accordion /> doesn't care about these but they're passed-thru
        //  to `getAccordionItemEl`
        uploadFileInfoMap: moduleState.uploadFileInfoMap,
    };
    return stateToProps;
};

const FileListAccordion = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Accordion));

interface UploadFileListProps {
    importFileCount: number;
    intl: intlShape;
    onClearFileList: () => void;
    onShowFileSelector: () => void;
    stateKey: string;
}

export const UploadFileList_: React.FC<UploadFileListProps> = (props: UploadFileListProps) => {
    const { formatMessage } = props.intl;
    const { importFileCount, onClearFileList, onShowFileSelector, stateKey } = props;

    return (
        <div className="files-list-container drop-enable">
            <div className="files-list-header">
                <span className="files-count">{importFileCount}</span>
                <span>{formatMessage(messages.filesLabel)}</span>
                <NoLink
                    className="clear-link"
                    label={formatMessage(messages.clearAllLink)}
                    onClick={onClearFileList}
                />
                <NoLink label={formatMessage(messages.addMoreLink)} onClick={onShowFileSelector} />
            </div>
            <hr />
            <div className="files-list-body">
                <FileListAccordion stateKey={stateKey} />
            </div>
        </div>
    );
};

export const UploadFileList = injectIntl(UploadFileList_);
