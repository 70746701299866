import { call, put, select, takeLatest } from "redux-saga/effects";

import { actions as notificationActions } from "~/notifications";
import { AnalyticsAPI, HierarchyAPI } from "@ai360/core";

import * as actions from "./actions";
import { getTheUserGuid } from "~/login";

function* onFetchAnalyticDashboardTypes() {
    const userGuid = yield select(getTheUserGuid);

    try {
        const response = yield call(AnalyticsAPI.getAnalyticDashboardTypes, userGuid);
        yield put(actions.fetchAnalyticDashboardTypesSuccess(response));
    } catch (e) {
        yield put(notificationActions.apiCallError(e));
    }
}

function* onFetchDashboardUrl(action) {
    const { analyticDashboardTypeGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    try {
        const response = yield call(
            AnalyticsAPI.getQuickSightDashboard,
            analyticDashboardTypeGuid,
            userGuid
        );
        yield put(actions.fetchDashboardUrlSuccess(response));
    } catch (e) {
        yield put(notificationActions.apiCallError(e));
    }
}

function* onFetchOrgLevelList() {
    const userGuid = yield select(getTheUserGuid);

    try {
        const response = yield call(HierarchyAPI.getOrgLevelList, userGuid);
        yield put(actions.fetchOrgLevelListSuccess(response));
    } catch (e) {
        yield put(notificationActions.apiCallError(e));
    }
}

export function* analyticsSaga() {
    yield takeLatest(actions.FETCH_DASHBOARD_URL, onFetchDashboardUrl);
    yield takeLatest(actions.FETCH_DASHBOARD_LIST, onFetchAnalyticDashboardTypes);
    yield takeLatest(actions.FETCH_ORG_LEVEL_LIST, onFetchOrgLevelList);
}
