import { ActionType } from "typesafe-actions";

import { accordionReducer, actions as accordionActions } from "~/accordion";
import { actions as fileImportActions } from "~/file-import";

import { UPLOAD_MODAL_STATE_KEY as UPLOAD_MODAL_STATE_KEY } from "~/action-panel/components/import-module/components/upload-modal/upload-modal";
import { RX_FILE_IMPORT_STATE_KEY } from "~/action-panel/components/rec-module/components/rec-info/components/rec-equation-application/rx-file-import/models";

import * as actions from "./actions";
import * as models from "./models";
import { getComputedFileCount, getSupportFileTypesFromTemplate } from "./utils";

const INITIAL_STATE_KEYS = [UPLOAD_MODAL_STATE_KEY, RX_FILE_IMPORT_STATE_KEY];

export type DragDropFileUploaderAction = ActionType<typeof actions>;
export const DRAG_AND_DROP_FILE_UPLOADER_DATA_STATE_KEY = "DRAG_AND_DROP_FILE_UPLOADER_DATA";

const newInitialChildState = (stateKey: string): models.IDragDropFileUploaderState => ({
    filesUploaded: new Set<string>(),
    filesWithErrorsCount: 0,
    fileUploadComplete: false,
    importFileCount: 0,
    isPreparingUpload: false,
    isDuplicateFileError: false,
    selectedImportType: null,
    selectedTemplate: null,
    stateKey,
    uploadFileInfoMap: new Map(),
    validExtensions: [],
    [models.ACCORDION_KEY]: accordionReducer(undefined, accordionActions.init()),
});

const dragDropFileUploaderChildReducer = (
    state: models.IDragDropFileUploaderState,
    action
): models.IDragDropFileUploaderState => {
    switch (action.type) {
        case actions.SET_IS_PREPARING_UPLOAD: {
            const { isPreparingUpload, stateKey } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            return Object.freeze({
                ...state,
                isPreparingUpload,
                fileUploadComplete: false,
            });
        }
        case actions.SET_SELECTED_IMPORT_TYPE: {
            const { selectedImportType, stateKey } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            return Object.freeze({
                ...state,
                selectedImportType,
                selectedTemplate: null,
            });
        }
        case actions.SET_SELECTED_TEMPLATE: {
            const { selectedTemplate, stateKey } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            return Object.freeze({
                ...state,
                selectedTemplate,
                uploadFileInfoMap: new Map(),
                importFileCount: 0,
                filesWithErrorsCount: 0,
                validExtensions: getSupportFileTypesFromTemplate(selectedTemplate),
            });
        }
        case actions.SET_VALID_EXTENSIONS: {
            const { validExtensions, stateKey } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            return Object.freeze({
                ...state,
                validExtensions,
            });
        }
        case actions.ADD_UPLOAD_INFO: {
            const { stateKey, uploadFileList } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            const flattenedUploadFileList = [
                ...state.uploadFileInfoMap.values(),
                ...models.flattenUploadFileList(uploadFileList),
            ];
            const uploadFileInfoMap = new Map(state.uploadFileInfoMap);
            for (const uploadFileInfo of flattenedUploadFileList) {
                uploadFileInfoMap.set(uploadFileInfo.originalGuid, uploadFileInfo);
            }
            const [importFileCount, filesWithErrorsCount] = getComputedFileCount(
                flattenedUploadFileList,
                state.selectedTemplate
            );
            return Object.freeze({
                ...state,
                fileUploadComplete: false,
                filesWithErrorsCount,
                importFileCount,
                uploadFileInfoMap,
            });
        }
        case actions.CLEAR_UPLOAD_FILE_LIST: {
            const { stateKey } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            return Object.freeze({
                ...state,
                importFileCount: 0,
                filesUploaded: new Set<string>(),
                filesWithErrorsCount: 0,
                fileUploadComplete: false,
                uploadFileInfoMap: new Map(),
            });
        }
        case actions.REMOVE_FILE_FROM_LIST: {
            // remove item and all children; if item's parent is a zip
            //  and we're removing the last child, remove zip as well.
            const { stateKey, uploadFileInfoGuid } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            const filesUploaded = new Set(state.filesUploaded);
            const uploadFileInfoMap = new Map(state.uploadFileInfoMap);
            const guidsToRemove = new Set<string>();
            const itemToDelete = uploadFileInfoMap.get(uploadFileInfoGuid);
            const children = models.flattenUploadFileList(itemToDelete.children);
            if (
                itemToDelete.parent &&
                itemToDelete.parent.isZipFile() &&
                itemToDelete.parent.children.length === 1
            ) {
                guidsToRemove.add(itemToDelete.parent.originalGuid);
            }
            // Remove the child shp from the zip file as well, if necessary
            if (
                itemToDelete.parent &&
                itemToDelete.parent.isZipFile() &&
                itemToDelete.parent.children.some((child) => child.guid === itemToDelete.guid)
            ) {
                const replacementObj = uploadFileInfoMap.get(itemToDelete.parent.guid);
                replacementObj.children = replacementObj.children.filter(
                    (child) => child.guid !== itemToDelete.guid
                );
                uploadFileInfoMap.set(itemToDelete.parent.guid, replacementObj);
            }
            guidsToRemove.add(itemToDelete.originalGuid);
            for (const childItem of children) {
                guidsToRemove.add(childItem.originalGuid);
            }
            for (const guid of guidsToRemove) {
                uploadFileInfoMap.delete(guid);
                filesUploaded.delete(guid);
            }
            const [importFileCount, filesWithErrorsCount] = getComputedFileCount(
                [...uploadFileInfoMap.values()],
                state.selectedTemplate
            );
            return Object.freeze({
                ...state,
                filesUploaded,
                filesWithErrorsCount,
                importFileCount,
                uploadFileInfoMap,
            });
        }
        case actions.SET_DUPLICATE_FILE_ERROR: {
            const { isDuplicateFileError, stateKey } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            return Object.freeze({
                ...state,
                isDuplicateFileError,
            });
        }
        case actions.UPDATE_UPLOAD_FILE_INFOS: {
            const { stateKey, uploadFileInfos } = action.payload;
            if (stateKey !== state.stateKey) {
                return state;
            }
            const uploadFileInfoMap = new Map(state.uploadFileInfoMap);
            for (const uploadFileInfo of uploadFileInfos) {
                uploadFileInfoMap.set(uploadFileInfo.guid, uploadFileInfo);
            }
            const [importFileCount, filesWithErrorsCount] = getComputedFileCount(
                [...uploadFileInfoMap.values()],
                state.selectedTemplate
            );
            return Object.freeze({
                ...state,
                importFileCount,
                filesWithErrorsCount,
                uploadFileInfoMap,
            });
        }
        case fileImportActions.UPLOAD_COMPLETED: {
            const uploadedFileGuid = action.payload;
            if (!state.uploadFileInfoMap.has(uploadedFileGuid)) {
                return state;
            }
            const filesUploaded = new Set(state.filesUploaded);
            filesUploaded.add(uploadedFileGuid);
            const getParent = (uploadFile) =>
                uploadFile.parent ? getParent(uploadFile.parent) : uploadFile;
            const actualImportFileCount = new Set(
                [...state.uploadFileInfoMap.values()].map(getParent).map((ufi) => ufi.guid)
            );
            return Object.freeze({
                ...state,
                filesUploaded,
                fileUploadComplete: filesUploaded.size === actualImportFileCount.size,
            });
        }
        default: {
            const accordionState = accordionReducer(state[models.ACCORDION_KEY], action);
            if (accordionState === state[models.ACCORDION_KEY]) {
                return state;
            }
            return Object.freeze({
                ...state,
                [models.ACCORDION_KEY]: accordionState,
            });
        }
    }
};

const newInitialState = (): models.IDragDropFileUploaderCoreState => {
    const initialState = {};
    INITIAL_STATE_KEYS.forEach((stateKey) => {
        initialState[stateKey] = newInitialChildState(stateKey);
    });
    return initialState;
};

export const dragAndDropFileUploaderReducer = (
    state = newInitialState(),
    action
): models.IDragDropFileUploaderCoreState => {
    switch (action.type) {
        default: {
            for (const stateKey in state) {
                const newChildState = dragDropFileUploaderChildReducer(state[stateKey], action);
                if (newChildState !== state[stateKey]) {
                    return Object.freeze({
                        ...state,
                        [stateKey]: newChildState,
                    });
                }
            }
            return state;
        }
    }
};
