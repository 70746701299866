import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { InjectedIntl, injectIntl } from "react-intl";

import { FormattingHelpers, LayerUtilsAPI, UserAPI } from "@ai360/core";
import { ILayer, ISubLayer } from "@ai360/core/dist/4x/es/api/layer";

import { Checkbox, Loader, SelectInput } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";

import { getTheUserGuid } from "~/login";
import { getUser } from "~/login/selectors";

import {
    actions as recsEventsActions,
    eventsSelectors,
    models as recsEventsModel,
} from "~/recs-events";
import { IAgEventModelMethods } from "~/recs-events/events/model";

import { messages } from "~/action-panel/components/event-module/components/event-info/i18n-messages";
import { messages as commonMessages } from "~/action-panel/components/common/rec-event-info/i18n-messages";
import { messages as layerMessages } from "~/action-panel/components/layer-module/components/i18n-messages";
import * as actions from "~/action-panel/components/event-module/components/event-info/actions";
import * as layerSelectors from "~/action-panel/components/layer-module/components/layer-list/selectors";
import * as layerActions from "~/action-panel/components/layer-module/components/layer-list/actions";
import * as eventInfoSelectors from "~/action-panel/components/event-module/components/event-info/selectors";

import { ContextMenu } from "./sampling-zone-context-menu";
import EditLayerProperties from "./edit-layer-properties";

import "~/action-panel/components/common/rec-event-info/rec-event-zone-info.css";
import "./sampling-zone-info.css";
import { CloseIcon } from "~/core/icons";
import { logFirebaseEvent } from "~/utils/firebase";

interface ISamplingZoneInfoProps {
    EditForm: any;
    eventDetails: any;
    fieldGuid: string;
    layerNameToSurfaceInfoMap: Map<string, any>;
    intl: InjectedIntl;
    isLoading: boolean;
    userInfo: UserAPI.IUser;
    agEventModel: IAgEventModelMethods;
    layerInfos: ILayer[];
    loadingSurfacesSet: Set<any>;
    userGuid: string;
    showSurface: (props: any) => void;
    removeSurface: () => void;
    setDataLoading: (isDataLoading: boolean) => void;
    onChangeSurfaceLayerProps: (props: any) => void;
    onChangeManualLayerProps: (props: any) => void;
    onFetchLayerNameToSurfaceInfoMap: (fieldGuid: string) => void;
    onResetZones: () => void;
    onSetEventAreaIsIncluded: (eventAreaId: number, isIncluded: boolean) => void;
    onSetEventZonesFromLayer: (surfaceInfoOption: any) => void;
    onSetZoneInterpolation: (setZoneInterpolation: boolean) => void;
}
interface IState {
    isEditData: boolean;
    hideContext: boolean;
    updateLegend: boolean;
    selectedLayer: ILayer;
    selectedSurface: ISubLayer;
}

export class SamplingZoneInfo_ extends PureComponent<ISamplingZoneInfoProps, IState> {
    constructor(props: ISamplingZoneInfoProps) {
        super(props);
        this.state = {
            isEditData: false,
            hideContext: false,
            updateLegend: false,
            selectedLayer: null,
            selectedSurface: null,
        };
    }

    public UNSAFE_componentWillMount(): void {
        const {
            eventDetails,
            onSetEventAreaIsIncluded,
            fieldGuid,
            onFetchLayerNameToSurfaceInfoMap,
        } = this.props;
        const haveClassBreaks = eventDetails.eventAreaList.some(
            (area) => area.eventAreaClassBreak != null
        );
        if (fieldGuid !== recsEventsModel.BATCH_TEMPLATE_FIELD_GUID) {
            onFetchLayerNameToSurfaceInfoMap(fieldGuid);
        }
        if (haveClassBreaks) {
            eventDetails.eventAreaList.forEach((eventArea) =>
                onSetEventAreaIsIncluded(eventArea.eventAreaId, eventArea.applyEventToArea)
            );
            const type =
                this.state.selectedLayer && LayerUtilsAPI.getLayerType(this.state.selectedLayer);
            this.setState({
                hideContext: !this.state.selectedLayer || type === LayerUtilsAPI.LayerType.ANALYSIS,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISamplingZoneInfoProps): void {
        const { updateLegend, selectedLayer } = this.state;
        const { setDataLoading, fieldGuid, onFetchLayerNameToSurfaceInfoMap } = this.props;
        if (updateLegend && selectedLayer) {
            const nextLayer = this._getLayerFromInfos(nextProps.layerInfos);
            const nextSurface = this._getSelectedSublayer(nextLayer) as ISubLayer;
            setDataLoading(true);
            this._onSelectDisplayLayer(nextSurface);
            this.setState({
                selectedLayer: nextLayer,
                selectedSurface: nextSurface,
                updateLegend: false,
            });
        }
        if (
            nextProps.fieldGuid !== recsEventsModel.BATCH_TEMPLATE_FIELD_GUID &&
            nextProps.fieldGuid !== fieldGuid
        ) {
            onFetchLayerNameToSurfaceInfoMap(nextProps.fieldGuid);
        }
    }

    public render(): JSX.Element {
        const { EditForm, eventDetails, isLoading, fieldGuid } = this.props;

        if (isLoading) {
            return null;
        }
        console.assert(eventDetails.eventAreaList.length > 0);
        const area = eventDetails.eventAreaList[0];
        console.assert(area.agEventList.length > 0);
        const { agEventModel } = area.agEventList[0];

        return (
            <div className="rec-event-info-form sampling-tissue-zone-info">
                {this._getZoneClassLegend()}
                <EditForm agEventModel={agEventModel} fieldGuid={fieldGuid} />
            </div>
        );
    }

    _getSelectedSublayer(dataLayer) {
        if (!dataLayer || !dataLayer.subLayers) {
            return null;
        }

        const type = LayerUtilsAPI.getLayerType(dataLayer);
        switch (type) {
            case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
            case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
                return dataLayer.subLayers.find((layer) => {
                    return (
                        layer.importAttributeGuid === dataLayer.selectedAttributeGuid &&
                        layer.surfaceTypeGuid === dataLayer.selectedSurfaceTypeGuid
                    );
                });
            case LayerUtilsAPI.LayerType.ANALYSIS:
                return dataLayer.subLayers.find((layer) => {
                    return layer.analysisLayerGuid === dataLayer.analysisLayerGuid;
                });
            case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
            case LayerUtilsAPI.LayerType.EVENT_MANUAL:
            case LayerUtilsAPI.LayerType.SOIL:
            case LayerUtilsAPI.LayerType.REC:
                return dataLayer.subLayers.find((layer) => {
                    return (
                        layer.surfaceGuid === dataLayer.selectedSurfaceGuid &&
                        layer.surfaceTypeGuid === dataLayer.selectedSurfaceTypeGuid
                    );
                });
            default:
                return null;
        }
    }

    _getClassBreakData(layer) {
        if (!layer) {
            return null;
        }
        const { classBreaks } = layer;
        const classBreakRows = classBreaks.map((classBreak) => {
            const { classId, color, displayName, acreage } = classBreak;
            return (
                <div key={classId} className="break-row">
                    <div className="symbolBox" style={{ backgroundColor: `#${color.hexCode}` }} />
                    {`${displayName} (${acreage} acres)`}
                </div>
            );
        });
        return classBreakRows;
    }

    _onCloseDataEdit() {
        this.setState({ isEditData: false });
    }

    _onSaveDataEdit({
        layer,
        surface,
        attributeGuid,
        classificationMethodGuid,
        colorRampGuid,
        colorSchemeGuid,
        numberOfClasses,
        classBreaks,
        surfaceTypeGuid,
        surfaceGuid,
    }) {
        const { fieldGuid, onChangeSurfaceLayerProps, onChangeManualLayerProps, setDataLoading } =
            this.props;

        this._onCloseDataEdit();
        setDataLoading(true);

        layer.selectedSurfaceTypeGuid = surfaceTypeGuid;
        layer.selectedAttributeGuid = attributeGuid;
        layer.selectedSurfaceGuid = surfaceGuid;
        layer.analysisLayerGuid = surface.analysisLayerGuid;

        const type = LayerUtilsAPI.getLayerType(layer);
        if (type != LayerUtilsAPI.LayerType.ANALYSIS) {
            if (layer.isManual) {
                onChangeManualLayerProps({
                    itemDimIdx: -1,
                    fieldGuid: fieldGuid,
                    layer,
                    surface,
                    attributeGuid,
                    colorSchemeGuid,
                });
            } else {
                onChangeSurfaceLayerProps({
                    itemDimIdx: -1,
                    fieldGuid,
                    layer: layer,
                    surface: surface,
                    attributeGuid,
                    classificationMethodGuid,
                    colorRampGuid,
                    colorSchemeGuid,
                    numberOfClasses,
                    surfaceTypeGuid,
                    classBreaks,
                });
            }
            this.setState({ selectedLayer: layer, updateLegend: true });
        } else {
            this._onSelectDisplayLayer(surface);
            this.setState({
                selectedLayer: layer,
                selectedSurface: surface,
            });
        }
    }

    private _getDisplayLayerSelectInput(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { isEditData, selectedLayer, selectedSurface } = this.state;
        const { layerInfos, fieldGuid, isLoading, loadingSurfacesSet, layerNameToSurfaceInfoMap } =
            this.props;

        if (fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID) {
            return null;
        }
        const options =
            layerNameToSurfaceInfoMap == null
                ? []
                : [...layerNameToSurfaceInfoMap.entries()].map(([layerName, surfaceInfo]) => ({
                      label: layerName,
                      value: surfaceInfo,
                  }));

        const dataLoading =
            (selectedSurface && loadingSurfacesSet.has(selectedSurface.surfaceGuid)) || isLoading;
        const type = selectedLayer && LayerUtilsAPI.getLayerType(selectedLayer);
        const letterIcon = formatMessage(layerMessages.layerTypeEvent);

        return (
            <div className="sample-zone-info-layer-controls">
                <SelectInput
                    containerClassNames={["display-layer-select-input"]}
                    disabled={layerNameToSurfaceInfoMap == null}
                    optionIsHiddenKey={ACTIVE_YN}
                    placeholderText={formatMessage(messages.displayLayerPlaceholderTxt)}
                    options={options}
                    onChange={(selection) => {
                        selection && this._onSelectDisplayLayer(selection as ISubLayer);
                    }}
                />
                <ContextMenu
                    className={"sample-zone-context-menu"}
                    fieldGuid={fieldGuid}
                    showSampleZoneInfo={() => {
                        this.setState({ isEditData: true });
                    }}
                />
                {isEditData && (
                    <EditLayerProperties
                        isOpen={isEditData}
                        selectedLayer={selectedLayer}
                        selectedSurface={selectedSurface}
                        showLayerSelect={true}
                        layerInfos={layerInfos}
                        letterIcon={letterIcon}
                        onSave={(evt) => this._onSaveDataEdit(evt)}
                        onClose={() => this._onCloseDataEdit()}
                        type={type}
                    />
                )}
                {dataLoading ? <Loader /> : null}
            </div>
        );
    }

    private _getInterpolationType(): number {
        const { userInfo } = this.props;
        const { role } = userInfo;
        if (role.zoneInterpolation) {
            return recsEventsModel.InterpolationType.ZONE_INTERPOLATION;
        } else if (role.zoneSampling) {
            return recsEventsModel.InterpolationType.ZONE_SAMPLING;
        } else {
            return recsEventsModel.InterpolationType.STANDARD;
        }
    }

    private _getInterpolationLabel(interpolationType): string {
        const { formatMessage } = this.props.intl;
        switch (interpolationType) {
            case recsEventsModel.InterpolationType.ZONE_INTERPOLATION:
                return formatMessage(messages.zoneInterpolationCbLabel);
            case recsEventsModel.InterpolationType.ZONE_SAMPLING:
                return formatMessage(messages.zoneSamplingCbLabel);
            default:
                return null;
        }
    }

    private _getLayerFromInfos(layerInfos: ILayer[]): ILayer {
        const { selectedLayer } = this.state;
        const layerType = selectedLayer && LayerUtilsAPI.getLayerType(selectedLayer);

        switch (layerType) {
            case LayerUtilsAPI.LayerType.SOIL:
                return layerInfos.find((layer) => {
                    return layer.soilsFieldGuid === selectedLayer.soilsFieldGuid;
                });
            case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
            case LayerUtilsAPI.LayerType.ANALYSIS:
                return layerInfos.find((layer) => {
                    return layer.analysisLayerGuid === selectedLayer.analysisLayerGuid;
                });
            case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
            case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
            case LayerUtilsAPI.LayerType.EVENT_MANUAL:
                return layerInfos.find((layer) => {
                    return layer.agEventGeneralGuid === selectedLayer.agEventGeneralGuid;
                });
            case LayerUtilsAPI.LayerType.REC:
                return layerInfos.find((layer) => {
                    return layer.recGeneralGuid === selectedLayer.recGeneralGuid;
                });
            default:
                return null;
        }
    }

    private _getZoneClassLegend(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { isEditData, selectedLayer, selectedSurface } = this.state;
        const { layerInfos, eventDetails, fieldGuid, isLoading, loadingSurfacesSet } = this.props;

        const haveClassBreaks = eventDetails.eventAreaList.some(
            (area) => area.eventAreaClassBreak != null
        );

        if (!haveClassBreaks) {
            return this._getDisplayLayerSelectInput();
        }

        const cbLabelToTotalAcresMap = new Map();
        for (const area of eventDetails.eventAreaList) {
            const areaClassBreak = area.eventAreaClassBreak;
            const { calculatedArea } = area;
            const key = areaClassBreak.label;
            const accum = cbLabelToTotalAcresMap.has(key) ? cbLabelToTotalAcresMap.get(key) : 0;
            cbLabelToTotalAcresMap.set(key, accum + calculatedArea);
        }

        const cbLabelToColorMap = eventDetails.eventAreaList.reduce((cbLabelToColorMap, area) => {
            const classBreak = area.eventAreaClassBreak;
            if (!cbLabelToColorMap.has(classBreak.label)) {
                cbLabelToColorMap.set(classBreak.label, classBreak.rgbCssStr);
            }
            return cbLabelToColorMap;
        }, new Map());

        const legendElements = [...cbLabelToColorMap.keys()].map((label) => {
            const rgbCssStr = cbLabelToColorMap.get(label);
            const legendColorStyle = { backgroundColor: rgbCssStr };
            const zoneSize = formatMessage(commonMessages.zoneSize, {
                calculatedArea: FormattingHelpers.formatNumber(cbLabelToTotalAcresMap.get(label)),
            });
            return (
                <div key={label} className="event-zone-classbreaks-legend">
                    <div className="event-zone-legend-color" style={legendColorStyle} />
                    <div className="event-zone-legend-label">
                        <span className="label-text" title={label}>
                            {label}
                        </span>
                        <span className="label-size"> ({zoneSize})</span>
                    </div>
                </div>
            );
        });

        console.assert(
            new Set(eventDetails.eventAreaList.map((area) => area.eventAreaClassBreak.heading))
                .size === 1
        );
        const legendHeading = eventDetails.eventAreaList[0].eventAreaClassBreak.heading;
        const { interpolationTypeId } = eventDetails.eventAreaList[0].agEventList[0].agEventModel;
        const interpolationType = this._getInterpolationType();
        const interpolate =
            interpolationTypeId !== recsEventsModel.InterpolationType.STANDARD &&
            interpolationTypeId === interpolationType;

        const type = selectedLayer && LayerUtilsAPI.getLayerType(selectedLayer);
        const dataLoading =
            (selectedSurface && loadingSurfacesSet.has(selectedSurface.surfaceGuid)) || isLoading;
        const letterIcon = formatMessage(layerMessages.layerTypeEvent);

        return (
            <div className="zone-legend-container">
                <div className="sample-zone-info-layer-controls">
                    <div className="zone-legend-heading-container sample-zone-info-layer-controls">
                        <div className="zone-legend-heading">{legendHeading}</div>
                        {this.state.hideContext ? (
                            <span onClick={this._onClose}>
                                <CloseIcon />
                            </span>
                        ) : (
                            <ContextMenu
                                className={"sample-zone-context-menu"}
                                fieldGuid={fieldGuid}
                                showSampleZoneInfo={() => {
                                    this.setState({ isEditData: true });
                                }}
                                hideSampleZoneInfo={this._onClose}
                            />
                        )}
                    </div>
                    {isEditData && (
                        <EditLayerProperties
                            isOpen={isEditData}
                            selectedLayer={selectedLayer}
                            selectedSurface={selectedSurface}
                            showLayerSelect={false}
                            layerInfos={layerInfos}
                            letterIcon={letterIcon}
                            onSave={(evt) => this._onSaveDataEdit(evt)}
                            onClose={() => this._onCloseDataEdit()}
                            type={type}
                        />
                    )}
                </div>
                {legendElements}
                {interpolationType === recsEventsModel.InterpolationType.STANDARD ? null : (
                    <Checkbox
                        className="interpolation-cb"
                        label={this._getInterpolationLabel(interpolationType)}
                        value={interpolate}
                        onChange={(evt, cbValue) => this._onSetZoneInterpolation(cbValue)}
                    />
                )}
                {dataLoading ? <Loader /> : null}
            </div>
        );
    }

    private _onClose = (): void => {
        this._onSetZoneInterpolation(false);
        this.props.onResetZones();
        this.setState({ selectedLayer: null, selectedSurface: null });
    };

    private _onSelectDisplayLayer = (surfaceInfo: ISubLayer): void => {
        const { onSetEventZonesFromLayer, layerInfos } = this.props;
        this._onSetZoneInterpolation(true);
        onSetEventZonesFromLayer(surfaceInfo);

        logFirebaseEvent("sampling_display_layer");
        const dataLayer =
            layerInfos &&
            layerInfos.find((lyr) => {
                if (
                    surfaceInfo.agEventGeneralGuid &&
                    surfaceInfo.agEventGeneralGuid === lyr.agEventGeneralGuid
                ) {
                    return true;
                }
                if (
                    surfaceInfo.surfaceGuid &&
                    surfaceInfo.selectedSurfaceTypeGuid &&
                    lyr.selectedSurfaceTypeGuid === surfaceInfo.surfaceTypeGuid &&
                    lyr.selectedSurfaceGuid === surfaceInfo.surfaceGuid
                ) {
                    return true;
                }
                if (
                    surfaceInfo.analysisLayerGuid &&
                    lyr.analysisLayerGuid === surfaceInfo.analysisLayerGuid
                ) {
                    return true;
                }
                if (
                    surfaceInfo.importAttributeGuid &&
                    surfaceInfo.surfaceTypeGuid &&
                    lyr.selectedAttributeGuid === surfaceInfo.importAttributeGuid &&
                    lyr.selectedSurfaceTypeGuid === surfaceInfo.surfaceTypeGuid
                ) {
                    return true;
                }
                return false;
            });
        const layerType = dataLayer && LayerUtilsAPI.getLayerType(dataLayer);
        this.setState({
            selectedLayer: dataLayer,
            selectedSurface: surfaceInfo,
            hideContext: !layerType || layerType === LayerUtilsAPI.LayerType.ANALYSIS,
        });
    };

    private _onSetZoneInterpolation(cbValue): void {
        const { eventDetails, onSetZoneInterpolation } = this.props;
        const interpolationTypeId = cbValue
            ? this._getInterpolationType()
            : recsEventsModel.InterpolationType.STANDARD;
        const eventAreaList = eventDetails.eventAreaList.map((eventArea) => {
            return recsEventsModel.AgEventArea.updateAgEventArea(eventArea, {
                agEventList: eventArea.agEventList.map((agEvent) => {
                    return recsEventsModel.AgEvent.updateAgEvent(agEvent, {
                        agEventModel: agEvent.agEventModel.updateAgEventModel({
                            interpolationTypeId: interpolationTypeId,
                        }),
                    });
                }),
            });
        });
        onSetZoneInterpolation(eventAreaList);
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFetchLayerNameToSurfaceInfoMap: (fieldGuid: string) =>
        dispatch(recsEventsActions.fetchLayerNameToSurfaceInfoMap(fieldGuid)),
    onUpdateEventDetails: (fieldGuid: string, newProps: any) =>
        dispatch(recsEventsActions.updateEventDetails(fieldGuid, newProps)),
    setEventZonesFromLayer: (fieldGuid: string, surfaceInfo: any) =>
        dispatch(recsEventsActions.setEventZonesFromLayer(fieldGuid, surfaceInfo)),
    resetEventAreas: (fieldGuid: string) => dispatch(recsEventsActions.resetEventAreas(fieldGuid)),
    setEventAreaIsIncluded: (fieldGuid: string, areaId: number, isIncluded: boolean) =>
        dispatch(recsEventsActions.setAreaIsIncluded(fieldGuid, areaId, isIncluded)),
    onChangeSurfaceLayerProps: (payload) => dispatch(layerActions.changeSurfaceLayerProps(payload)),
    onChangeManualLayerProps: (payload) => dispatch(layerActions.changeManualLayerProps(payload)),
    setDataLoading: (isLoading) => dispatch(actions.setECDataLoading(isLoading)),
});

const mapStateToProps = (state) => {
    const { fieldGuidToEventDetails } = eventsSelectors.getModuleState(state);
    const { eventSummary, isLoading } = eventInfoSelectors.getModuleState(state);
    const { fieldGuid } = eventSummary;
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const { layerNameToSurfaceInfoMap } = eventsSelectors.getModuleState(state);
    const layerInfos = layerSelectors.getLayerInfos(state).get(fieldGuid);

    return {
        eventDetails,
        fieldGuid,
        layerNameToSurfaceInfoMap,
        isLoading,
        layerInfos,
        userGuid: getTheUserGuid(state),
        userInfo: getUser(state),
        loadingSurfacesSet: layerSelectors.getLoadingSurfacesSet(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const onSetEventZonesFromLayer = (surfaceInfo) =>
        dispatchProps.setEventZonesFromLayer(stateProps.fieldGuid, surfaceInfo);

    const onResetZones = () => dispatchProps.resetEventAreas(stateProps.fieldGuid);

    const onSetZoneInterpolation = (eventAreaList) =>
        dispatchProps.onUpdateEventDetails(stateProps.fieldGuid, {
            eventAreaList,
        });

    const onSetEventAreaIsIncluded = (areaId: number, isIncluded: boolean) =>
        dispatchProps.setEventAreaIsIncluded(stateProps.fieldGuid, areaId, isIncluded);

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onResetZones,
        onSetEventAreaIsIncluded,
        onSetEventZonesFromLayer,
        onSetZoneInterpolation,
    };
};

export const SamplingZoneInfo = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SamplingZoneInfo_));
