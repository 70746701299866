import * as model from "./model";

const systemProfileDefaultRecords = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_AREA_LIMIT]: "",
    [model.PROPS_BRANDING_YN]: false,
    [model.PROPS_COPYRIGHT_HOLDER]: "",
    [model.PROPS_ID]: null,
    [model.PROPS_PRIVACY_POLICY_TEXT]: "",
    [model.PROPS_PRIVACY_POLICY_TITLE]: "",
    [model.PROPS_PRIVACY_POLICY_UPDATED_DATE]: "",
    [model.PROPS_SYSTEM_GUID]: "",
    [model.PROPS_SYSTEM_LOGO]: "",
    [model.PROPS_SYSTEM_LOGO_DARK]: "",
    [model.PROPS_SYSTEM_LOGO_LIGHT]: "",
    [model.PROPS_SYSTEM_NAME]: "",
    [model.PROPS_BRANDING_NAME]: "",
    [model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]: [],
});

export default systemProfileDefaultRecords;
