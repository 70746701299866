import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import * as utils from "./event-scouting-utils";
import { Checkbox, NumericInput, SelectInput, TextInput, SelectInputCategory } from "~/core";

import { ACTIVE_YN } from "~/core/picklist";

export class EventScoutingTemplateObsItem_ extends Component {
    static propTypes = {
        categories: PropTypes.array,
        countData: PropTypes.array,
        densityData: PropTypes.array,
        fetchGrowthStageObservationData: PropTypes.func,
        filteredObservationData: PropTypes.array,
        formatMessage: PropTypes.func,
        getCategoryGroupName: PropTypes.func,
        growthStageObservationData: PropTypes.object,
        index: PropTypes.number,
        messages: PropTypes.object,
        observationData: PropTypes.array,
        onChange: PropTypes.func,
        plantLocationData: PropTypes.array,
        observationInfo: PropTypes.array,
        unitLength: PropTypes.array,
    };
    static defaultProps = {
        observationData: [],
    };

    _refreshGrowthStageObservationData(index, newObs) {
        const observationGuidList = this.props.observationInfo.map((ob, ind) => {
            return ind === index ? newObs.guid : ob.observationGuid;
        });
        this.props.fetchGrowthStageObservationData({ observationGuidList });
    }

    render() {
        const {
            categories,
            countData,
            densityData,
            filteredObservationData,
            formatMessage,
            getCategoryGroupName,
            growthStageObservationData,
            index,
            messages,
            observationData,
            onChange,
            plantLocationData,
            observationInfo,
            unitLength,
        } = this.props;
        const currentScoutingdetail = observationInfo[index];
        const selectedObservation = utils.formatSelectedObservation(
            this.props.observationData,
            currentScoutingdetail
        );
        const applicableGrowthStageData =
            growthStageObservationData.get(currentScoutingdetail.observationGuid) || [];
        const categoryName = getCategoryGroupName(selectedObservation.categoryIndex).toLowerCase();
        const lineObservationData = selectedObservation
            ? [selectedObservation, ...filteredObservationData]
            : filteredObservationData;
        const isWeed = categoryName === "weed";
        const isDisease = categoryName === "disease";
        const isInsect = categoryName === "insect";

        return (
            <div className="scouting-template-observations">
                <div className="template-observation-header">Observation: {index + 1}</div>
                <div className="template-input-row">
                    <div className="template-input-item no-toggle">
                        <SelectInputCategory
                            autoFocus={false}
                            categories={categories}
                            clearable={false}
                            getCategoryGroupName={getCategoryGroupName}
                            onChange={(value) => {
                                onChange("observationGuid", value.guid, index);
                                this.setState({
                                    selectedObservation: utils.formatSelectedObservation(
                                        observationData,
                                        { observationGuid: value.guid }
                                    ),
                                });
                                this._refreshGrowthStageObservationData(index, value);
                            }}
                            optionIsHeaderKey="header"
                            optionIsHiddenKey={ACTIVE_YN}
                            options={lineObservationData}
                            placeholderText={formatMessage(messages.observation)}
                            required
                            value={selectedObservation}
                        />
                    </div>
                    <div className="template-input-item no-toggle">
                        <SelectInput
                            options={densityData}
                            onChange={(v) => onChange("densityRatingGuid", v, index)}
                            placeholderText={formatMessage(messages.pressure)}
                            value={observationInfo[index].densityRatingGuid}
                        />
                    </div>
                    {!isWeed && !isInsect ? null : (
                        <div className="template-input-item">
                            <Checkbox
                                onChange={(e, v) => {
                                    onChange("showGrowthStage", v, index, () => {
                                        onChange("growthStageGuid", null, index);
                                    });
                                }}
                                value={observationInfo[index].showGrowthStage}
                            />
                            <SelectInput
                                disabled={!observationInfo[index].showGrowthStage}
                                options={[...applicableGrowthStageData]}
                                onChange={(v) => onChange("growthStageGuid", v, index)}
                                placeholderText={formatMessage(messages.growthStage)}
                                value={observationInfo[index].growthStageGuid}
                            />
                        </div>
                    )}
                    {!isDisease ? null : (
                        <div className="template-input-item">
                            <Checkbox
                                onChange={(e, v) => {
                                    onChange("showPlantLocation", v, index, () => {
                                        onChange("plantLocationGuid", null, index);
                                    });
                                }}
                                value={observationInfo[index].showPlantLocation}
                            />
                            <SelectInput
                                disabled={!observationInfo[index].showPlantLocation}
                                options={[...plantLocationData]}
                                onChange={(v) => onChange("plantLocationGuid", v, index)}
                                placeholderText={formatMessage(messages.plantLocation)}
                                value={observationInfo[index].plantLocationGuid}
                            />
                        </div>
                    )}
                </div>

                <div className="template-input-row">
                    <div className="template-input-item">
                        <Checkbox
                            onChange={(e, v) => {
                                onChange("showObservationCount", v, index, () => {
                                    onChange("observationCount", null, index, () => {
                                        onChange("countUnitsGuid", null, index);
                                    });
                                });
                            }}
                            value={observationInfo[index].showObservationCount}
                        />
                        <NumericInput
                            disabled={!observationInfo[index].showObservationCount}
                            containerClassNames={["template-input-numeric"]}
                            scale={0}
                            precision={8}
                            placeholderText={formatMessage(messages.count)}
                            onChange={(v) => onChange("observationCount", v, index)}
                            value={observationInfo[index].observationCount}
                        />
                    </div>
                    <div className="template-input-item no-toggle">
                        <SelectInput
                            disabled={!observationInfo[index].showObservationCount}
                            options={[...countData]}
                            onChange={(v) => onChange("countUnitsGuid", v, index)}
                            placeholderText={formatMessage(messages.countUnit)}
                            value={observationInfo[index].countUnitsGuid}
                        />
                    </div>

                    {!isInsect ? null : (
                        <div className="template-input-item">
                            <Checkbox
                                onChange={(e, v) => {
                                    onChange("showTrapId", v, index, () => {
                                        onChange("trapId", null, index);
                                    });
                                }}
                                value={observationInfo[index].showTrapId}
                            />
                            <TextInput
                                containerClassNames={["template-input-text"]}
                                disabled={!observationInfo[index].showTrapId}
                                maxLength={50}
                                placeholderText={formatMessage(messages.trapId)}
                                value={observationInfo[index].trapId}
                                onChange={(v) => onChange("trapId", v, index)}
                            />
                        </div>
                    )}
                </div>

                <div className="template-input-row">
                    {!isWeed ? null : (
                        <div className="template-input-item">
                            <Checkbox
                                onChange={(e, v) => {
                                    onChange("showWeedHeight", v, index, () => {
                                        onChange("weedHeight", null, index, () => {
                                            onChange("weedHeightUnitsGuid", null, index);
                                        });
                                    });
                                }}
                                value={observationInfo[index].showWeedHeight}
                            />
                            <NumericInput
                                disabled={!observationInfo[index].showWeedHeight}
                                containerClassNames={["template-input-numeric"]}
                                scale={8}
                                precision={38}
                                placeholderText={formatMessage(messages.weedHeight)}
                                onChange={(v) => onChange("weedHeight", v, index)}
                                value={observationInfo[index].weedHeight}
                            />
                        </div>
                    )}
                    {!isWeed ? null : (
                        <div className="template-input-item no-toggle">
                            <SelectInput
                                disabled={!observationInfo[index].showWeedHeight}
                                options={[...unitLength]}
                                onChange={(v) => onChange("weedHeightUnitsGuid", v, index)}
                                placeholderText={formatMessage(messages.units)}
                                value={observationInfo[index].weedHeightUnitsGuid}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const EventScoutingTemplateObsItem = injectIntl(EventScoutingTemplateObsItem_);
