import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

const EquationIcon_ = (
    <g>
        <path d="M 3 35.4 c -1 0 -2 -0.1 -3 -0.4 v -4.2 c 0.6 0.2 1.3 0.4 2 0.4 c 0.7 0 1.3 -0.2 1.8 -0.7 c 0.6 -0.6 1 -1.4 1.1 -2.2 l 3.5 -16.5 H 5.6 l 0.5 -2.5 l 3.1 -1.5 l 0.3 -1.4 c 0.3 -1.8 1.1 -3.5 2.4 -4.8 c 1.3 -1.1 3 -1.6 4.7 -1.5 c 1.4 0 2.7 0.3 4 0.8 l -1.3 3.9 c -0.7 -0.3 -1.5 -0.5 -2.3 -0.5 c -0.6 0 -1.2 0.2 -1.6 0.7 c -0.4 0.5 -0.7 1.1 -0.8 1.8 l -0.2 1 h 3.8 l -0.9 4 h -3.8 L 9.9 29 C 9 33.3 6.7 35.4 3 35.4 Z" />
        <path d="M 18.3 24.3 c 0 -3 0.5 -6 1.7 -8.7 c 1.3 -2.8 3 -5.4 5.2 -7.6 h 2.9 c -4.6 5 -6.9 10.5 -6.9 16.4 c 0 2.8 0.5 5.6 1.6 8.2 h -2.5 C 18.9 30 18.2 27.1 18.3 24.3 Z" />
        <path d="M 32.8 22.2 l -3.3 4.2 h -2.9 l 5.1 -6.1 l -2.5 -5.8 h 2.6 l 1.6 4 l 3.1 -4 h 3 l -5 5.9 l 2.7 6 h -2.7 L 32.8 22.2 Z" />
        <path d="M 47.9 16.2 c 0 3 -0.5 5.9 -1.7 8.7 c -1.3 2.8 -3 5.4 -5.3 7.6 h -2.9 c 4.6 -5 6.9 -10.5 6.9 -16.4 c 0 -2.8 -0.5 -5.6 -1.6 -8.2 h 2.5 C 47.2 10.5 47.9 13.3 47.9 16.2 Z" />
    </g>
);

export const EquationIcon = withSvgIcon(EquationIcon_, "0 0 47.9 35.4");
