import { AgEventAPI } from "@ai360/core";
import { IAgEventModelMethods } from "../model";

export class AgEventEcData implements AgEventAPI.IAgEventEcData, IAgEventModelMethods {
    agEventGuid = "";
    distance = "";
    duration = "";
    ecDeep = "";
    ecShallow = "";
    elevation = "";
    eventAreaGuid: string | null = null;
    eventEcDataGuid = "";
    heading = "";
    productivity = "";
    speed = "";
    swathWidth = "";

    get isAllRequiredFieldsSet(): boolean {
        return this.agEventGuid && this.agEventGuid !== "";
    }
    get isAnyRequiredFieldSet(): boolean {
        return this.isAllRequiredFieldsSet;
    }
    public updateAgEventModel(newProps: Partial<AgEventEcData>): AgEventEcData {
        return Object.assign(new AgEventEcData(), this, newProps);
    }
}
