import React from "react";
import BaseYieldList from "./base-yield-list";
import { messages } from "../i18n-messages";

const CropYieldList = (props: Record<string, any>): JSX.Element => (
    <BaseYieldList
        addGoalMessage={messages.addCropYieldGoal}
        goalPlaceholderMessage={messages.yieldGoal}
        breakEvenMode={false}
        {...props}
    />
);
export default CropYieldList;
