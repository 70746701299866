import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import classnames from "classnames";

import { Button, SelectInput, withAsyncOptions, withAutoWidth } from "~/core";

import { SearchIcon } from "~/core/icons";

import { actions as fileImportActions, selectors as fileImportSelectors } from "~/file-import";

import { FileImportAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";

import * as actions from "../../../actions";
import * as selectors from "../../../selectors";

import "./search-user.css";

const messages = defineMessages({
    inputPlaceholderTxt: {
        id: "filterSelectBar.searchUser.inputPlaceholderTxt",
        defaultMessage: "Search User",
    },
    getFilesBtnText: {
        id: "filterSelectBar.searchUser.getFilesBtnText",
        defaultMessage: "Get Files",
    },
});

const searchIcon = (
    <span key="search" className="search-icon">
        <SearchIcon />
    </span>
);

export const optionRenderer = ({ option, isSelected, isHighlighted }) => {
    const className = classnames("select-form-input-option", {
        selected: isSelected,
        "filter-match": isHighlighted,
    });

    const userObj = option.value;
    const cityAndState = [userObj.city, userObj.stateAbbreviation].join(", ");
    const optionTile = `${userObj.personName} - ${userObj.jobTitleName} - ${cityAndState}`;
    return (
        <div className={className} title={optionTile}>
            <div className="user-info">
                <div className="name-loc">
                    <div className="name">
                        <span>{userObj.personName}</span>
                    </div>
                    <div className="loc">
                        <span>{cityAndState}</span>
                    </div>
                </div>
                <div className="title">
                    <span>{userObj.jobTitleName}</span>
                </div>
            </div>
        </div>
    );
};

optionRenderer.propTypes = {
    option: PropTypes.object,
    isSelected: PropTypes.bool,
    isHighlighted: PropTypes.bool,
};

class SearchUser_ extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        isLoading: PropTypes.bool.isRequired,
        userGuid: PropTypes.string.isRequired,
        userFilterStr: PropTypes.string,
        userFilterObj: PropTypes.object,
        onApiCallErr: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        onSelectUser: PropTypes.func.isRequired,
        fetchImportFileTypes: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            userFilterStr: props.userFilterStr,
            userFilterObj: props.userFilterObj,
        };

        this.AutoWidthSelectInput = withAsyncOptions(
            withAutoWidth(SelectInput, 775),
            (userFilterStr) => this._getUserOptions(userFilterStr)
        );
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (
            props.userFilterStr === this.state.userFilterStr &&
            isEqual(props.userFilterObj, this.state.userFilterObj)
        ) {
            return;
        }
        this.setState({
            userFilterStr: props.userFilterStr,
            userFilterObj: props.userFilterObj,
        });
    }

    componentWillUnmount() {
        this.props.onSelectUser(this.state.userFilterObj);
    }

    _getUserOptions = (userFilterStr) => {
        if (userFilterStr === "") {
            return Promise.resolve([]);
        }

        const { userGuid, onApiCallErr } = this.props;

        return FileImportAPI.searchUsers(userGuid, userFilterStr)
            .then((results) =>
                results.map((userFilterObj) => ({
                    value: userFilterObj,
                    label: userFilterObj.personName,
                }))
            )
            .catch((err) => {
                onApiCallErr(err);
                return [];
            });
    };

    _onChange(userFilterObj) {
        this.setState({
            userFilterObj,
            userFilterStr: userFilterObj ? userFilterObj.personName : "",
        });
    }

    _onInputChange(userFilterStr) {
        this.setState({ userFilterStr, userFilterObj: null }, () => {
            if (userFilterStr === "") {
                this.props.onSelectUser(null);
                this.props.onClear();
            }
        });
    }

    render() {
        const { isLoading } = this.props;
        const { AutoWidthSelectInput } = this;
        const { formatMessage } = this.props.intl;

        return (
            <div className="search-user">
                <div className="search-user-input">
                    <AutoWidthSelectInput
                        clearFilterInputOnBlur={false}
                        initialFilterStr={this.state.userFilterStr}
                        value={this.state.userFilterObj && this.state.userFilterObj.personGuid}
                        placeholderText={formatMessage(messages.inputPlaceholderTxt)}
                        inputContainerLeftElements={[searchIcon]}
                        arrowRenderer={() => null}
                        onChange={(val) => this._onChange(val)}
                        onInputChange={(val) => this._onInputChange(val)}
                        optionRenderer={optionRenderer}
                        optionHeight={42}
                        allowEmptyOptions
                    />
                </div>
                <Button
                    disabled={this.state.userFilterObj == null || isLoading}
                    value={formatMessage(messages.getFilesBtnText)}
                    onClick={() => {
                        this.props.onSelectUser(this.state.userFilterObj);
                        this.props.fetchImportFileTypes();
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { isLoading } = selectors.getModuleState(state);
    const { userFilterStr, userFilterObj } = fileImportSelectors.getFetchAllUsersFilter(state);
    return {
        isLoading: isLoading || fileImportSelectors.isFetchingTypes(state),
        userGuid: getTheUserGuid(state),
        userFilterStr,
        userFilterObj,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onApiCallErr: (error) => dispatch(notificationActions.apiCallError(error)),
    onClear: () => dispatch(actions.clearAllUsersTabFiles()),
    onSelectUser: (userFilterObj) =>
        dispatch(
            fileImportActions.setFetchAllUsersFilter(
                userFilterObj != null ? userFilterObj.personName : "",
                userFilterObj
            )
        ),
    fetchImportFileTypes: () => dispatch(fileImportActions.fetchImportFileTypes()),
});

export const SearchUser = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchUser_));
