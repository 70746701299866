import { defineMessages } from "react-intl";

export const messages = defineMessages({
    hideFilter: {
        id: "actionPanel.common.hideFilter",
        defaultMessage: "Hide Filter",
    },
    showFilter: {
        id: "actionPanel.common.showFilter",
        defaultMessage: "Show Filter",
    },
    hideFilters: {
        id: "actionPanel.common.hideFilters",
        defaultMessage: "Hide Filters",
    },
    showFilters: {
        id: "actionPanel.common.showFilters",
        defaultMessage: "Show Filters",
    },
});
