import React, { Component, ReactNode } from "react";
import { SelectInput } from "../../../select-input/select-input";
import { NoLink } from "~/core";
import { getNumOfRecords } from "../utils";
import classnames from "classnames";
import { LocalStorageHelpers } from "@ai360/core";
import { IIndex } from "../../interfaces";

export interface IPaginatorProps {
    totalRecords?: number;
    range?: number;
    onPaginatorChange?: (index: IIndex) => void;
    numOfRecords?: number;
    isModalWindow?: boolean;
}

export interface IPaginatorState {
    rangeIndex: number;
    skip: number;
    pageSize: string;
    activeIndex: number;
}

class Paginator extends Component<IPaginatorProps, IPaginatorState> {
    static defaultProps = {
        range: 4,
    };

    constructor(props: IPaginatorProps) {
        super(props);
        this.state = {
            rangeIndex: props.range,
            skip: 0,
            pageSize: LocalStorageHelpers.getStoredPageSize(props.isModalWindow),
            activeIndex: 1,
        };
        getNumOfRecords().length == props.numOfRecords ? props.numOfRecords : getNumOfRecords();
    }

    private paginatorChange = (): void => {
        if (this.props.onPaginatorChange) {
            const { skip, pageSize } = this.state;
            this.props.onPaginatorChange({ skip, pageSize });
        }
    };

    private onPaginatorChange = (index: number): void => {
        const { pageSize } = this.state;
        this.setState(
            {
                skip: index * +pageSize,
                activeIndex: index + 1,
            },
            this.paginatorChange
        );
    };

    private onPageSizeChange = (pageSize: string): void => {
        if (this.props.isModalWindow) {
            LocalStorageHelpers.set(LocalStorageHelpers.GRID_MODAL_PAGE_SIZE, pageSize);
        } else {
            LocalStorageHelpers.set(LocalStorageHelpers.GRID_FULL_PAGE_SIZE, pageSize);
        }
        this.setState(
            {
                pageSize,
            },
            this.paginatorChange
        );
    };

    private getLastIndex = (currentPageEnd: number): number => {
        const { pageSize } = this.state;
        const { totalRecords } = this.props;
        const lastPageRecords = totalRecords % +pageSize;

        if (currentPageEnd > totalRecords) {
            return currentPageEnd - +pageSize + lastPageRecords;
        }
        return currentPageEnd;
    };

    private renderPageData = (): ReactNode => {
        const { totalRecords } = this.props;
        const { skip, pageSize } = this.state;
        const currentPageStart = skip || 1;
        const currentPageEnd = skip + +pageSize;
        return (
            <div>
                Displaying {currentPageStart} - {this.getLastIndex(currentPageEnd)} of{" "}
                {totalRecords}:
            </div>
        );
    };

    private getPrevSet = (gotoPage: number): void => {
        this.setState(
            {
                rangeIndex: this.state.rangeIndex - this.props.range,
            },
            () => {
                this.onPaginatorChange(--gotoPage);
            }
        );
    };

    private getNextSet = (gotoPage: number): void => {
        this.setState(
            {
                rangeIndex: this.state.rangeIndex + this.props.range,
            },
            () => {
                this.onPaginatorChange(gotoPage);
            }
        );
    };

    private getGotoPrev = (pageIndexes: ReactNode[], skip: number): ReactNode[] => {
        if (this.state.rangeIndex > this.props.range) {
            pageIndexes.push(
                <li className="line-item" key={0}>
                    <NoLink
                        label={"..."}
                        className={skip.toString() || "selected-page-index"}
                        onClick={() => this.getPrevSet(this.state.rangeIndex - this.props.range)}
                    />
                </li>
            );
        }
        return pageIndexes;
    };

    private getCurrentIndices = (pageIndexes: ReactNode[], numOfPages: number): ReactNode[] => {
        for (let index = 1; index <= numOfPages; index++) {
            if (
                index / this.state.rangeIndex <= 1 &&
                this.state.rangeIndex - index < this.props.range
            ) {
                pageIndexes.push(
                    <li className="line-item" key={index}>
                        <NoLink
                            className={classnames({
                                "selected-page-index": this.state.activeIndex === index,
                            })}
                            label={index.toString()}
                            onClick={() => this.onPaginatorChange(index - 1)}
                        />
                    </li>
                );
            }
        }
        return pageIndexes;
    };

    private getGotoNext = (
        pageIndexes: ReactNode[],
        numOfPages: number,
        lastPageRecords: number
    ): ReactNode[] => {
        if (this.state.rangeIndex < numOfPages) {
            pageIndexes.push(
                <li className="line-item" key={numOfPages}>
                    <NoLink label={"..."} onClick={() => this.getNextSet(this.state.rangeIndex)} />
                </li>
            );
        } else if (lastPageRecords) {
            pageIndexes.push(
                <li className="line-item" key={numOfPages + 1}>
                    <NoLink
                        className={classnames({
                            "selected-page-index": this.state.activeIndex === numOfPages + 1,
                        })}
                        label={(numOfPages + 1).toString()}
                        onClick={() => this.onPaginatorChange(numOfPages)}
                    />
                </li>
            );
        }
        return pageIndexes;
    };

    private renderPageIndexes = (): ReactNode[] => {
        const { totalRecords } = this.props;
        const { skip, pageSize } = this.state;
        const lastPageRecords = totalRecords % +pageSize;
        const numOfPages = Math.floor(totalRecords / +pageSize);

        let pageIndexes = [];
        pageIndexes = this.getGotoNext(
            this.getCurrentIndices(this.getGotoPrev(pageIndexes, skip), numOfPages),
            numOfPages,
            lastPageRecords
        );
        return pageIndexes;
    };

    private onSelectionChange = (option: string): void => {
        this.onPageSizeChange(option);
    };

    render(): ReactNode {
        return (
            <div className="data-table-paginator">
                <div className="data-table-paginator-indices">
                    {this.renderPageData()}
                    <ul>{this.renderPageIndexes()}</ul>
                </div>
                <div className="data-table-paginator-input">
                    <span>Display:</span>
                    <SelectInput<string>
                        containerClassNames={["filter-input"]}
                        filterable={false}
                        options={getNumOfRecords()}
                        onChange={this.onSelectionChange}
                        placeholderText=""
                        clearable={false}
                        value={`${this.state.pageSize}`}
                    />
                </div>
            </div>
        );
    }
}

export default Paginator;
