import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { service, actions, model } from "./data";
import { preventBubbleUp } from "~/admin/utils";
import { withApiResult, withMasked, withCrud } from "~/hocs";
// Components
import AddEditPanel from "./add-edit/add-edit-container";
import { DataTable, Button } from "~/core";
import { messages } from "../i18n-messages";
import { defaultRequestFilters, REQUEST_PAYLOAD_FILTER } from "./data/service";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { SUCCESS, FAILURE, PENDING } from "~/hocs/needs/utils";
import { ADD } from "~/admin/data";
import { getApiErrorCodeList } from "~/core/api/selectors";
import "./owner.css";

export class Owner_ extends Component {
    static propTypes = {
        actions: PropTypes.object,
        addEditPanel: PropTypes.object,
        addOwner: PropTypes.func,
        apiErrors: PropTypes.array,
        closeSidePanel: PropTypes.func,
        fetchRecords: PropTypes.func,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        onSubmit: PropTypes.func,
        openAddEditPanel: PropTypes.func,
        records: PropTypes.array,
        requestIds: PropTypes.array,
        setBreadcrumbs: PropTypes.func,
        status: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
            isModalOpen: false,
            isCreate: false,
            showAddEditPanel: false,
            recordGuid: "",
            mode: "",
        };
    }

    onFilterChange = (data) => {
        const { needs, actions } = this.props;
        const requestOptions = {
            ...defaultRequestFilters,
            [REQUEST_PAYLOAD_FILTER]: {
                ...defaultRequestFilters[REQUEST_PAYLOAD_FILTER],
                [model.PROPS_ORG_LEVEL_GUID]: data ? data.orgLevelGuid : null,
            },
        };
        needs([actions.fetch(requestOptions)]);
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.requestIds && this.addEditRequestId) {
            if (nextProps.requestIds[this.addEditRequestId] === SUCCESS) {
                this.setState({
                    apiErrors: [],
                });
                this._toggleFetchData(false);
                this._toggleAddEdit("", false);
                this.props.fetchRecords(service.defaultRequestFilters);
                this.addEditRequestId = null;
            } else if (nextProps.requestIds[this.addEditRequestId] === FAILURE) {
                if (nextProps.apiErrors.length) {
                    this.setState({
                        apiErrors: nextProps.apiErrors,
                    });
                }
                this._toggleFetchData(false);
                this.addEditRequestId = null;
            }
        }
    }

    _toggleAddEdit = (mode, showAddEditPanel) => {
        this.setState({ mode, showAddEditPanel });
        this.props.setBreadcrumbs();
    };

    _toggleFetchData = (fetchData) => {
        this.setState({ fetchData });
    };

    _openAddEditPanel = (modelName, mode, recordGuid = "") => {
        this.setState({
            recordGuid,
        });
        this._toggleAddEdit(mode, true);
    };

    _onCancel = () => {
        this.setState({
            apiErrors: [],
        });
        this._toggleAddEdit("", false);
    };

    liftRecordData = (data = {}) => {
        this._toggleFetchData(false);
        if (data && this.props.status !== PENDING) {
            if (this.state.mode === ADD) {
                this.addEditRequestId = this.props.needs([actions.addOwner(data)]);
            } else {
                this.addEditRequestId = this.props.needs([actions.update(data)]);
            }
        }
    };

    render() {
        const { fetchData, mode, showAddEditPanel, recordGuid, apiErrors } = this.state;
        const { liftRecordData } = this;
        let slidingPanelProps = { ...this.props, apiErrors };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                fetchData,
                liftRecordData,
                ...this.props.addEditPanel,
                addEditPanel: { mode },
                recordGuid,
            };
        }
        return (
            <div className="content-table-container owner-table">
                <DataTable
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    {...this.props}
                    records={this.props.records}
                    openAddEditPanel={this._openAddEditPanel}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "234",
                            }}
                        >
                            <Button
                                type="save"
                                forceSubmit
                                onClick={() => this._toggleFetchData(true)}
                            />
                            <Button
                                type="cancel"
                                className="owner-admin-cancel"
                                onClick={this._onCancel}
                            />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    apiErrors: getApiErrorCodeList(state),
});

const mapDispatchToProps = () => ({
    addOwner: (payload) => actions.addOwner(payload),
});
export const Owner = injectIntl(
    withMasked(
        withApiResult(
            withCrud(connect(mapStateToProps, mapDispatchToProps)(Owner_), service, actions)
        )
    )
);
