import { connect } from "react-redux";
import { EquationList } from "./equation-list";
import { getValidEquationResponse, getValidCropList } from "./data/selectors";
import {
    validateEquationToggle,
    testEquationSuccess,
    validateCropListToggle,
} from "./data/actions";

const mapStateToProps = (state) => ({
    validCropList: getValidCropList(state),
    validEquationResponse: getValidEquationResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
    validateCropListToggle: (payload) => dispatch(validateCropListToggle(payload)),
    validateEquationToggle: (payload) => dispatch(validateEquationToggle(payload)),
    testEquationSuccess: (payload) => dispatch(testEquationSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EquationList);
