import React, { ReactElement } from "react";
import PropTypes from "prop-types";
import "./merge-events.css";
import { messages } from "../../../i18n-messages";
import { MergeableEvent, MergeableEventType, MergeableEvents } from "~/data/mergeable-events";

import { DialogBox, DialogBoxFooterType } from "~/core";

const applicationEventDisplay = (event: MergeableEvent): string => {
    return `
        ${event.croppingSeasonName} -
        ${event.agEventDisplayName} -
        (${event.calculatedArea.toFixed(2)})`;
};

const soilEventDisplay = (event: MergeableEvent): string =>
    `${event.croppingSeasonName} - ${event.agEventDisplayName} - (${event.totalPoints})`;

type MergeEventsModalProps = {
    formatMessage: any;
    onAction: any;
    onClose: any;
    mergeableEvents: MergeableEvents;
};

export const MergeEventsModal = ({
    formatMessage,
    onAction,
    onClose,
    mergeableEvents,
}: MergeEventsModalProps): ReactElement => {
    const toDisplay = ((): ((event: MergeableEvent) => string) => {
        switch (mergeableEvents.type) {
            case MergeableEventType.Application:
                return applicationEventDisplay;
            case MergeableEventType.Soil:
                return soilEventDisplay;
            default:
                return undefined;
        }
    })();
    const displayElements = mergeableEvents.all.map(toDisplay).map((display, i) => (
        <span className="event-list-item" key={i}>
            {display}
        </span>
    ));
    return (
        <DialogBox
            unrestricted
            footerType={DialogBoxFooterType.YES_NO}
            isOpen={true}
            onAction={() => onAction(mergeableEvents)}
            onClose={() => onClose(mergeableEvents[0])}
            title="Confirm"
            className="merge-events-modal"
        >
            {formatMessage(messages.mergeEventsConfirmation)}
            <div className="event-list-box">{displayElements}</div>
        </DialogBox>
    );
};

MergeEventsModal.propTypes = {
    className: PropTypes.string,
    formatMessage: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    mergeableEvents: PropTypes.object,
};
