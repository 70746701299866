import React, { useEffect, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import natsort from "natsort";
import { v4 as uuid } from "uuid";
import { FieldAPI, GeometryUtils } from "@ai360/core";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

import { DialogBox, DialogBoxFooterType, RadioButton, RadioButtonGroup, SelectInput } from "~/core";
import { useAppDispatch, useAppSelector } from "~/store/hooks";

import { initialFieldModel } from "~/action-panel/components/field-module/reducer";
import { IFieldModel } from "~/action-panel/components/field-module/models";
import {
    getAgvanceFieldList,
    getFieldBoundaryTransferFieldModel,
    getFieldBoundaryTransferGeometries,
    getFieldInfoModel,
    getIsFieldBoundaryTransferOpen,
} from "~/action-panel/components/field-module/selectors";
import { getFieldEditGeometries } from "~/map/components/map-tools/selectors";
import { setFieldEditGeometries } from "~/map/components/map-tools/actions";
import {
    fetchResurfaceEvents,
    removeResurfaceEvents,
    setFieldBoundaryTransferFieldModel,
    setFieldBoundaryTransferModalOpen,
    setFieldBoundaryTransferGeometries,
    clearResurfaceEvents,
} from "~/action-panel/components/field-module/actions";
import SearchAgvanceField from "~/action-panel/components/common/search-agvance-field";

import messages from "./i18n-messages";
import "./field-boundary-transfer.css";

function FieldBoundaryTransfer({ intl: { formatMessage } }) {
    const dispatch = useAppDispatch();
    const [transferToExistingField, setTransferToExistingField] = useState(true);
    const fieldInfo = useAppSelector(getFieldInfoModel);
    const isOpen = useAppSelector(getIsFieldBoundaryTransferOpen);
    const fieldEditGeometries = useAppSelector(getFieldEditGeometries);
    const transferToFieldModel = useAppSelector(getFieldBoundaryTransferFieldModel);
    const fieldBoundaryTransferGeometries = useAppSelector(getFieldBoundaryTransferGeometries);
    const agvanceFieldList = useAppSelector(getAgvanceFieldList);
    const [action, setAction] = useState<"cancel" | "transfer">("cancel");
    const [originalFieldGeometries, setOriginalFieldGeometries] = useState([]);
    const [fieldOptions, setFieldOptions] = useState([]);
    const [existingFieldGuid, setExistingFieldGuid] = useState(null);
    const [newFieldName, setNewFieldName] = useState("");
    const [agvanceFieldGuid, setAgvanceFieldGuid] = useState<string>(null);
    const [farmName, setFarmName] = useState("");
    const [farmNameOptions, setFarmNameOptions] = useState([]);
    const [existingFields, setExistingFields] = useState([] as IFieldModel[]);
    const [existingFarmFieldNames, setExistingFarmFieldNames] = useState(new Set<string>());
    const [existingBoundaries, setExistingBoundaries] = useState(
        {} as { [key: string]: FieldAPI.IFieldBoundary[] }
    );

    useEffect(() => {
        if (
            fieldInfo.fieldGuid &&
            fieldEditGeometries.length > 0 &&
            !originalFieldGeometries.length
        ) {
            setOriginalFieldGeometries([...fieldEditGeometries]);
        }
    }, [fieldInfo.fieldGuid, fieldEditGeometries]);

    useEffect(() => {
        if (fieldInfo.customerGuid) {
            const setFieldOptionsData = (fields: FieldAPI.IFieldResult[]) => {
                const naturalSorter = natsort({ insensitive: true });
                const fieldsWithFarms = fields
                    .filter((field) => field.farmName)
                    .sort((a, b) => {
                        const farmNameComp = naturalSorter(a.farmName, b.farmName);
                        return farmNameComp !== 0
                            ? farmNameComp
                            : naturalSorter(a.fieldName, b.fieldName);
                    });
                const fieldsWithoutFarms = fields
                    .filter((field) => !field.farmName)
                    .sort((a, b) => naturalSorter(a.fieldName, b.fieldName));

                const newFieldOptions = [...fieldsWithoutFarms, ...fieldsWithFarms].map(
                    (field) => ({
                        label: field.farmName
                            ? `${field.farmName} - ${field.fieldName}`
                            : field.fieldName,
                        value: field.fieldGuid,
                    })
                );
                const farmNames = new Set(fields.map((field) => field.farmName).filter(Boolean));
                setFarmNameOptions(
                    [...farmNames].map((farmName) => ({ label: farmName, value: farmName }))
                );
                setFieldOptions(newFieldOptions);
            };
            const getFieldData = async () => {
                const fields = await FieldAPI.getFields({
                    customerGuid: fieldInfo.customerGuid,
                });
                setExistingFarmFieldNames(
                    new Set(fields.map((field) => `${field.farmName || ""}${field.fieldName}`))
                );
                const fieldsOtherThanCurrent = fields.filter(
                    (field) => field.fieldGuid != fieldInfo.fieldGuid && field.activeYn
                );
                setFieldOptionsData(fieldsOtherThanCurrent);
                const fieldGuids = fieldsOtherThanCurrent.map((field) => field.fieldGuid);
                const boundaries = await FieldAPI.fetchFieldBoundariesByFieldGuid(
                    fieldGuids,
                    null,
                    null,
                    true
                );
                const groupedBoundaries = boundaries.reduce((fieldMap, boundary) => {
                    fieldMap[boundary.fieldGuid] = fieldMap[boundary.fieldGuid] || [];
                    fieldMap[boundary.fieldGuid].push(boundary);
                    return fieldMap;
                }, {} as { [key: string]: FieldAPI.IFieldBoundary[] });
                setExistingFields(fields as unknown as IFieldModel[]);
                setExistingBoundaries(groupedBoundaries);
            };
            getFieldData();
        }
    }, [fieldInfo.customerGuid]);

    const onTransferBoundaries = () => {
        let fieldModel = null;
        if (transferToExistingField) {
            const existingFieldModel = existingFields.find(
                (field) => field.fieldGuid === existingFieldGuid
            );
            fieldModel = {
                ...existingFieldModel,
            };
            dispatch(fetchResurfaceEvents(existingFieldGuid, true));
        } else {
            fieldModel = {
                ...initialFieldModel,
                customerGuid: fieldInfo.customerGuid,
                farmName: farmName,
                fieldName: newFieldName,
                fieldGuid: uuid(),
                events: 0,
                recs: 0,
            };

            if (agvanceFieldGuid) {
                const agvanceField = agvanceFieldList.filter(
                    (a) => a.agvanceGuid === agvanceFieldGuid
                )[0];
                fieldModel.agvanceAutoMapField = true;
                fieldModel.agvanceFieldOrgLevelGuid = agvanceField.orgLevelGuid;
                fieldModel.agvanceCustomerGuid = agvanceField.agvanceGrowerGuid;
                fieldModel.agvanceFieldGuid = agvanceField.agvanceGuid;
                fieldModel.agvanceFieldId = agvanceField.fieldId;
            } else {
                fieldModel.agvanceAutoMapField = false;
                fieldModel.agvanceFieldOrgLevelGuid = fieldInfo.agvanceFieldOrgLevelGuid;
                fieldModel.agvanceCustomerGuid = fieldInfo.agvanceCustomerGuid;
                fieldModel.agvanceFieldGuid = null;
                fieldModel.agvanceFieldId = null;
            }
        }
        const fieldBoundaryGuid = uuid();
        const fieldBoundaryPolygons = [];
        let calculatedArea = 0;
        const fieldBoundaryGeometries = [...fieldBoundaryTransferGeometries];
        if (existingBoundaries[fieldModel.fieldGuid]) {
            fieldBoundaryGeometries.push(
                ...existingBoundaries[fieldModel.fieldGuid].map(
                    (boundary) =>
                        FieldAPI.fieldBoundaryToGraphic(boundary).geometry as __esri.Polygon
                )
            );
        }
        fieldBoundaryGeometries.forEach((geometry) => {
            fieldBoundaryPolygons.push({
                fieldBoundaryGuid,
                fieldBoundaryPolygonGuid: uuid(),
                fieldGuidsToCombine: null,
                importFieldBoundaryGuids: null,
                shapes: [JSON.stringify(geometry.toJSON())],
            });
            calculatedArea += GeometryUtils.calculateArea(geometry);
        });
        fieldModel.fieldBoundaryGuid = fieldBoundaryGuid;
        fieldModel.fieldBoundary = {
            ...fieldModel.fieldBoundary,
            fieldGuid: fieldModel.fieldGuid,
            fieldBoundaryPolygons,
            calculatedArea,
        };
        fieldModel.acres = calculatedArea;
        dispatch(setFieldBoundaryTransferFieldModel(fieldModel));
    };

    const onCancel = () => {
        const newFieldEditGeometries = [...fieldEditGeometries, ...fieldBoundaryTransferGeometries];
        if (
            originalFieldGeometries.every((og) =>
                newFieldEditGeometries.some((eg) => geometryEngine.equals(eg, og))
            )
        ) {
            dispatch(clearResurfaceEvents());
        } else if (transferToFieldModel && transferToFieldModel.fieldGuid) {
            dispatch(removeResurfaceEvents(transferToFieldModel.fieldGuid));
        }
        dispatch(setFieldBoundaryTransferGeometries([]));
        dispatch(setFieldEditGeometries(newFieldEditGeometries));
        dispatch(setFieldBoundaryTransferFieldModel(null));
        setExistingFieldGuid(null);
        setNewFieldName("");
        setFarmName("");
        setAgvanceFieldGuid(null);
        setTransferToExistingField(true);
    };

    const onNewFieldNameChange = (
        fieldName: string,
        agvanceGuid?: string,
        fieldId?: string,
        farmName?: string
    ) => {
        setNewFieldName(fieldName);
        if (farmName) {
            setFarmName(farmName);
        }
        if (agvanceGuid) {
            setAgvanceFieldGuid(agvanceGuid);
        } else {
            setAgvanceFieldGuid(null);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            if (action === "transfer") {
                onTransferBoundaries();
            } else {
                onCancel();
            }
        }
    }, [isOpen, action]);

    const farmAndFieldAlreadyExist =
        farmName &&
        newFieldName &&
        existingFarmFieldNames.has(`${farmName.trim()}${newFieldName.trim()}`);

    const fieldSet = transferToExistingField
        ? existingFieldGuid
        : newFieldName && !farmAndFieldAlreadyExist;
    const validGeometriesSelected =
        fieldBoundaryTransferGeometries.length > 0 && fieldEditGeometries.length > 0;

    const actionList = [
        {
            action: formatMessage(messages.transfer),
            actionDisabled: !fieldSet || !validGeometriesSelected,
            onAction: () => {
                setAction("transfer");
                dispatch(setFieldBoundaryTransferModalOpen(false));
            },
        },
        {
            action: formatMessage(messages.cancel),
            isCancelStyle: true,
            onAction: () => {
                setAction("cancel");
                dispatch(setFieldBoundaryTransferModalOpen(false));
            },
        },
    ];
    return (
        <div className="field-boundary-transfer">
            <DialogBox
                multiActionList={actionList}
                footerType={DialogBoxFooterType.MULTI_ACTION}
                isOpen={isOpen}
                title={formatMessage(messages.title)}
            >
                <div className="field-boundary-transfer-action-description">
                    {formatMessage(
                        transferToExistingField
                            ? messages.existingFieldTransferDescription
                            : messages.newFieldTransferDescription
                    )}
                </div>
                <RadioButtonGroup
                    className="field-boundary-transfer-radio-group"
                    selectedValue={transferToExistingField}
                    afterOnChange={(value) => {
                        setTransferToExistingField(value as boolean);
                        if (!value) {
                            setExistingFieldGuid(null);
                        } else {
                            setNewFieldName("");
                            setFarmName("");
                            setAgvanceFieldGuid(null);
                        }
                    }}
                >
                    <RadioButton
                        label={formatMessage(messages.existingField)}
                        value={true}
                        defaultValue={true}
                    />
                    <RadioButton
                        label={formatMessage(messages.newField)}
                        value={false}
                        defaultValue={false}
                    />
                </RadioButtonGroup>
                {transferToExistingField ? (
                    <div className="field-boundary-transfer-existing-field">
                        <SelectInput
                            clearFilterInputOnBlur={false}
                            noOptionsRenderer={null}
                            onChange={(guid) => {
                                setExistingFieldGuid(guid);
                            }}
                            options={fieldOptions}
                            placeholderText={formatMessage(messages.farmFieldName)}
                            value={existingFieldGuid}
                        />
                    </div>
                ) : (
                    <div className="field-boundary-transfer-new-field">
                        <SearchAgvanceField
                            autoFocus
                            openOnFocus={false}
                            value={newFieldName}
                            hasError={existingFarmFieldNames.has(`${farmName}${newFieldName}`)}
                            errorText={formatMessage(messages.fieldNameExists)}
                            onInputChange={onNewFieldNameChange}
                            onChange={onNewFieldNameChange}
                            records={agvanceFieldList}
                        />
                        <SelectInput
                            clearFilterInputOnBlur={false}
                            options={farmNameOptions}
                            onChange={setFarmName}
                            onInputChange={setFarmName}
                            placeholderText={formatMessage(messages.farmName)}
                            value={farmName}
                            initialFilterStr={farmName}
                            noOptionsRenderer={null}
                            allowEmptyOptions
                            maxLength={50}
                        />
                    </div>
                )}
            </DialogBox>
        </div>
    );
}

FieldBoundaryTransfer.propTypes = {
    intl: intlShape,
};

export default injectIntl(FieldBoundaryTransfer);
