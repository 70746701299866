import { model } from "./data";
import { PersonalityTypes } from "~/utils/keywords";

export class AgvanceUtils {
    static AGVANCE_CUSTOMER_SEARCH_AUTOCOMPLETE_LIMIT = 2;

    static getPrimaryCustomer(recordList) {
        return recordList.filter((data) => data[model.PROPS_IS_PRIMARY]);
    }

    static prepareAgvanceCustomerList(records) {
        if (records.length) {
            const newList = [];
            records.forEach((o) => {
                const newRecord = { ...o };

                const isDuplicateGrower = newList.some((og) => o.agvanceGuid === og.agvanceGuid);
                if (isDuplicateGrower) {
                    return;
                }

                const isDuplicate = records.some((og) => {
                    if (
                        o.orgLevelGuid !== og.orgLevelGuid &&
                        o.oracleId === og.oracleId &&
                        o.agvanceGuid !== og.agvanceGuid
                    ) {
                        return true;
                    }
                    return false;
                });
                if (
                    !newRecord[model.PROPS_AGVANCE_CUSTOMER_GUID] &&
                    !newRecord[model.PROPS_AGVANCE_CUSTOMER_GUID] &&
                    !newRecord[model.PROPS_AGVANCE_GROWER_NAME]
                ) {
                    newRecord[model.PROPS_AGVANCE_CUSTOMER_GUID] = o.agvanceGuid;
                    newRecord[model.PROPS_AGVANCE_GROWER_ID] = o.growerId;
                    newRecord[model.PROPS_AGVANCE_GROWER_NAME] = o.growerName;
                }
                newRecord[model.PROPS_IS_PRIMARY] = false;
                newList.push({ ...newRecord, isDuplicate });
            });
            return newList;
        }
        return records;
    }

    static prepareCustomerAgvanceRefList(crossRefListData) {
        return crossRefListData.reduce((acc, item) => {
            if (!item[model.PROPS_IS_PRIMARY]) {
                acc.push(item);
            }
            return acc;
        }, []);
    }

    static getNonPrimaryCustomerList(recordList) {
        return recordList.filter((data) => !data[model.PROPS_IS_PRIMARY]);
    }

    static isAgvanceConnected(personalityId) {
        return (
            personalityId === PersonalityTypes.AGVANCE_STANDARD ||
            personalityId === PersonalityTypes.HELENA_CORPORATE
        );
    }

    static isAgvanceStandard(personalityId) {
        return personalityId === PersonalityTypes.AGVANCE_STANDARD;
    }
}
