import React, { memo, ReactNode, useEffect, useState } from "react";
import _ from "lodash";
import TableHeader from "./components/header";
import Paginator from "./components/paginator";
import TableCell from "./components/table-cell";
import TableRows from "./components/table-rows";
import classnames from "classnames";
import { NoLink } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages as dataTableMessages } from "~/i18n-messages";
import "./data-table.css";
import {
    ITableService,
    IIndex,
    IFilterQuery,
    ISortOptions,
    IDataTableFooterOptions,
    IMessages,
    IRecord,
    ISearchData,
} from "../interfaces";

export interface IDataTableProps {
    autoSearchList?: ISearchData;
    classNames?: string;
    debounceFilterInput: boolean;
    deleteSelected?: (params: IIndex) => void;
    disableSelectAll?: boolean;
    disableSort: boolean;
    footerOptions?: IDataTableFooterOptions[];
    formatMessage?: (message: string) => void;
    forcedRender: boolean; // This will allow scenarios like reports to update in realtime
    getAutoSearchList?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    getAutoSearchListAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    intl: intlShape;
    invisibleAddEdit: boolean;
    isCheckbox: boolean;
    isEditable?: boolean;
    messages?: IMessages[];
    onEnter?: (attr: string, value: string) => void;
    onFilterChange?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    onFilterChangeAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    onPaginatorChange?: (index: IIndex) => void;
    onPaginatorChangeAlt?: (index: IIndex) => void;
    onRowSelection?: () => void;
    onRowUnselect?: () => void;
    onSelect?: (SelItems: IRecord | IRecord[]) => void;
    onSelectAll?: (obj: IIndex) => void;
    onSelectAllAlt?: (obj: IIndex, bool?: boolean) => void;
    onSortOption?: (obj: ISortOptions) => void;
    onSortOptionAlt?: (obj?: ISortOptions) => void;
    openAddEditPanel?: () => void;
    records: IRecord[];
    renderRows?: (selectItems, deleteSelected) => ReactNode | ReactNode[];
    selectAll?: IRecord[];
    selectedItems: IRecord[];
    selectedRow: IRecord;
    service: ITableService;
    showFilterInput: boolean;
    showHideAddButton: boolean;
    totalCount?: number;
    lastSelectedRow?: IRecord;
    setLastEditedRow?: () => void;
}

const areEqual = (prevProps, nextProps) => {
    return (
        prevProps.records === nextProps.records &&
        prevProps.autoSearchList === nextProps.autoSearchList &&
        prevProps.selectAll === nextProps.selectAll &&
        prevProps.selectedItems?.length === nextProps.selectedItems?.length &&
        prevProps.selectedRow === nextProps.selectedRow &&
        prevProps.reportStatus === nextProps.reportStatus &&
        prevProps.orgLevelData === nextProps.orgLevelData &&
        !nextProps.forcedRender
    );
};

const DataTable = memo((props: IDataTableProps): JSX.Element => {
    const {
        getAutoSearchList,
        getAutoSearchListAlt,
        classNames,
        deleteSelected,
        disableSort,
        disableSelectAll,
        onFilterChange,
        onFilterChangeAlt,
        onPaginatorChange,
        onPaginatorChangeAlt,
        onSelect,
        onSelectAll,
        onSelectAllAlt,
        onSortOption,
        onSortOptionAlt,
        selectAll,
        selectedRow,
        service,
        debounceFilterInput,
        autoSearchList,
        openAddEditPanel,
        intl,
        invisibleAddEdit,
        isEditable,
        messages,
        totalCount,
        records,
        renderRows,
        selectedItems,
        onRowSelection,
        lastSelectedRow,
        setLastEditedRow,
        footerOptions,
    } = props;

    const isCheckbox = props.isCheckbox !== undefined ? props.isCheckbox : true;
    const showFilterInput = props.showFilterInput !== undefined ? props.showFilterInput : true;
    const showHideAddButton =
        props.showHideAddButton !== undefined ? props.showHideAddButton : true;

    //state variables

    const [selectedItemsState, setSelectedItemsState] = useState<IRecord[]>([]);
    const [filterQuery, setFilterQuery] = useState<IFilterQuery>({});
    const [sortOptions, setSortOptions] = useState<ISortOptions>({});
    const [skip, setSkip] = useState<number>(0);
    const [pageSize, setPageSize] = useState<string>(`50`);
    const [selectedRowState, setSelectedRowState] = useState<IRecord>(null);

    useEffect(() => {
        setSelectedItemsState(selectAll || []);
        onItemSelection(selectAll || []);
    }, [selectAll]);

    useEffect(() => {
        setSelectedItemsState(selectedItems as IRecord[]);
        onItemSelection(selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        if (selectedRow !== selectedRowState) {
            setSelectedRowState(selectedRow);
        }
    }, [selectedRow]);

    const getColumns = (): string[] => {
        return service.getDefaultColumns ? service.getDefaultColumns() : [];
    };

    const toggleSelectAll = (): void => {
        if (selectedItemsState.length === 0) {
            onSelectAllAlt != null
                ? onSelectAllAlt({ filterQuery, sortOptions })
                : onSelectAll({ filterQuery, sortOptions });
        } else {
            if (onSelectAllAlt != null) {
                onSelectAllAlt({ filterQuery, sortOptions }, true);
            }
            setSelectedItemsState([]);
            onItemSelection([]);
        }
    };

    const onItemSelection = (newSelectedItems: IRecord[]): void => {
        if (onSelect) {
            onSelect(newSelectedItems);
        }
    };

    const onSortOptionAction = (newProps): void => {
        const newSortOptions = newProps.sortOptions;
        const newFilterQuery = newProps.filterQuery;
        if (disableSort) {
            return;
        }
        if (newFilterQuery) {
            setFilterQuery(newFilterQuery);
            setSortOptions(newSortOptions);
            if (onSortOptionAlt != null) {
                onSortOptionAlt({
                    sortOptions: newSortOptions,
                    filterQuery: newFilterQuery,
                    skip,
                    pageSize,
                });
            } else {
                onSortOption({
                    sortOptions: newSortOptions,
                    filterQuery: newFilterQuery,
                    skip,
                    pageSize,
                });
            }
        } else {
            setSortOptions(newSortOptions);
            if (onSortOptionAlt != null) {
                onSortOptionAlt({
                    sortOptions: newSortOptions,
                    skip,
                    pageSize,
                });
            } else {
                onSortOption({ sortOptions: newSortOptions, skip, pageSize });
            }
        }
    };

    const getAutoSearchListAction = (
        attr: string,
        value: string,
        newFilterQuery: IFilterQuery
    ): void => {
        setFilterQuery(newFilterQuery);
        if (getAutoSearchListAlt != null) {
            getAutoSearchListAlt(attr, value, newFilterQuery);
        } else {
            getAutoSearchList(attr, value, newFilterQuery);
        }
    };

    const onEnter = (attr: string, value: string) => {
        onFilterChange(attr, value, filterQuery);
    };

    const onFilterChangeAction = (
        attr: string,
        value: string,
        newFilterQuery: IFilterQuery
    ): void => {
        setFilterQuery(newFilterQuery);

        if (onFilterChangeAlt != null) {
            onFilterChangeAlt(attr, value, newFilterQuery);
        } else {
            onFilterChange(attr, value, newFilterQuery);
        }
    };

    const onPaginatorChangeAction = (newProps): void => {
        const newSkip = newProps.skip;
        const newPageSize = newProps.pageSize;
        setSkip(newSkip);
        setPageSize(newPageSize);

        if (onPaginatorChangeAlt != null) {
            onPaginatorChangeAlt({
                filterQuery,
                sortOptions,
                skip: newSkip,
                pageSize: newPageSize,
            });
        } else {
            onPaginatorChange({
                filterQuery,
                sortOptions,
                skip: newSkip,
                pageSize: newPageSize,
            });
        }
    };

    const deleteSelectedAction = (
        newSelectedItems: IRecord | IRecord[],
        deleteFromRow?: boolean
    ): void => {
        if (deleteSelected) {
            deleteSelected({
                filterQuery,
                sortOptions,
                selectedItems: newSelectedItems,
                skip,
                pageSize,
                deleteFromRow,
            });
        }
    };

    const selectItems = (record: IRecord, value: boolean): void => {
        const selectedRecord = service.getDefaultGuid(record);
        const targetSelectedItems = [...selectedItemsState];
        if (!value) {
            // Remove item if it's already available in the array
            _.remove(targetSelectedItems, (item) => {
                return service.isItemSelected(item, selectedRecord);
            });
        } else {
            targetSelectedItems.push(selectedRecord);
        }
        setSelectedItemsState(targetSelectedItems);
        onItemSelection(targetSelectedItems);
    };

    const isFooterButtonDisabled = (
        disableIfNotSelected: boolean,
        disableIfNotSuccessful = false
    ): boolean => {
        return service.isFooterButtonDisabled(
            disableIfNotSelected,
            selectedItemsState,
            disableIfNotSuccessful
        );
    };

    const renderHeader = (columns = getColumns()): ReactNode => {
        return (
            <TableHeader
                columns={columns}
                debounceFilterInput={debounceFilterInput}
                disableSort={disableSort}
                service={service}
                invisibleAddEdit={invisibleAddEdit}
                isCheckbox={isCheckbox}
                isEditable={isEditable}
                showFilterInput={showFilterInput}
                autoSearchData={autoSearchList}
                getAutoSearchList={getAutoSearchListAction}
                onEnter={onEnter}
                onFilterChange={onFilterChangeAction}
                onSortOption={onSortOptionAction}
                openAddEditPanel={openAddEditPanel}
                messages={messages}
                formatMessage={intl.formatMessage}
                showHideAddButton={showHideAddButton}
            />
        );
    };

    const renderPaginator = (): ReactNode => {
        return (
            <Paginator
                totalRecords={totalCount}
                onPaginatorChange={onPaginatorChangeAction}
                numOfRecords={service.numOfRecords}
                isModalWindow={service.isModalWindow}
            />
        );
    };

    const renderRowsAction = (columns = getColumns()): ReactNode | ReactNode[] => {
        return (
            <TableRows
                records={records || []}
                columns={columns}
                isCheckbox={isCheckbox}
                isEditable={isEditable}
                service={service}
                onItemSelection={selectItems}
                selectedItems={selectedItemsState}
                openAddEditPanel={openAddEditPanel}
                deleteSelected={deleteSelectedAction}
                onRowSelection={onRowSelection}
                selectedRow={selectedRow}
                lastSelectedRow={lastSelectedRow}
                setLastEditedRow={setLastEditedRow}
                isModalWindow={service.isModalWindow}
            />
        );
    };

    const renderFooter = (): ReactNode => {
        const { formatMessage } = intl;
        let areAnySelected = false;
        if (disableSelectAll && records?.length === 0) {
            return null;
        }
        if (selectedItems && totalCount) {
            areAnySelected = selectedItems.length > 0;
        }
        return (
            <div className={"data-table-footer"}>
                {!disableSelectAll ? null : <TableCell className="justify-links" />}
                {isCheckbox && (
                    <TableCell key="SelectAll">
                        <NoLink
                            className={disableSelectAll ? "disabled-link" : ""}
                            label={
                                areAnySelected
                                    ? formatMessage(dataTableMessages.deselectAll)
                                    : formatMessage(dataTableMessages.selectAll)
                            }
                            onClick={!disableSelectAll ? () => toggleSelectAll() : () => null}
                        />
                        {footerOptions && <span className="bar" />}
                    </TableCell>
                )}
                {footerOptions &&
                    footerOptions.map(
                        (
                            {
                                label,
                                action,
                                disableIfNotSelected = false,
                                disableIfNotSuccessful = false,
                            },
                            index
                        ) => (
                            <TableCell key={`data-table-footer-${index}`}>
                                <NoLink
                                    className={
                                        isFooterButtonDisabled(
                                            disableIfNotSelected,
                                            disableIfNotSuccessful
                                        )
                                            ? "disabled-link"
                                            : ""
                                    }
                                    label={label}
                                    onClick={() =>
                                        !isFooterButtonDisabled(
                                            disableIfNotSelected,
                                            disableIfNotSuccessful
                                        )
                                            ? action({
                                                  filterQuery,
                                                  sortOptions,
                                                  selectedItems,
                                                  skip,
                                                  pageSize,
                                              })
                                            : () => null
                                    }
                                />
                                {index !== footerOptions.length - 1 && <span className="bar" />}
                            </TableCell>
                        )
                    )}
            </div>
        );
    };

    const header = renderHeader();
    const footer = renderFooter();
    let rows: ReactNode | ReactNode[];
    if (renderRows) {
        rows = renderRows(selectItems, deleteSelected);
    } else {
        rows = renderRowsAction();
    }
    const paginator = renderPaginator();
    const className = classnames("content-table-container data-table-cont", classNames);
    return (
        <div className={className}>
            {header}
            {rows}
            {footer}
            {paginator}
        </div>
    );
}, areEqual);

export default injectIntl(DataTable);
