import * as actions from "./actions";

export const imageryInitialState = {
    isLoading: false,
    imageryLayerSummary: {},
};

export const imageryReducer = (state = imageryInitialState, action) => {
    switch (action.type) {
        case actions.SET_IMAGERY_DETAILS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case actions.SET_IMAGERY_LAYER_SUMMARY: {
            const { imageryLayerSummary } = action.payload;
            return Object.freeze({
                ...state,
                imageryLayerSummary,
            });
        }
        case actions.UPDATE_IMAGERY_LAYER: {
            const { newProps } = action.payload;
            return Object.freeze({
                ...state,
                imageryLayerSummary: {
                    ...state.imageryLayerSummary,
                    ...newProps,
                },
            });
        }
        default: {
            return Object.freeze({
                ...state,
            });
        }
    }
};
