import { AgEventAPI } from "@ai360/core";

export class ScoutingSitePhoto implements AgEventAPI.IScoutingSitePhoto {
    constructor(
        public photo: string | null,
        public fullsizePhoto?: string | null,
        public eventScoutingPhotoGuid?: string | null,
        public agEventGuid?: string | null,
        public notes?: string | null,
        public mediaTypeGuid?: string | null
    ) {}
}
