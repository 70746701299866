import React from "react";
import { connect } from "react-redux";

import { recsSelectors } from "~/recs-events";

import { RecEventItem } from "../../../../../common/accordion/rec-event-accordion-item";
import { getEventStatusMessageId } from "../../../../../common/status-messages";
import { getClassNameForStatusFromEvent } from "../../../../../common/status-codes";

import * as selectors from "../../selectors";
import * as actions from "../../actions";
import { RecEventListTabs } from "~/recs-events/actions";

import { ContextMenu } from "./rec-accordion-item-context-menu";

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deselectItemsFromDimIdx: (startDimIdx, endDimIdx) => {
            dispatch(actions.deselectRecsFromAccordion(startDimIdx, endDimIdx));
        },
        selectItemsFromDimIdx: (startDimIdx, endDimIdx) => {
            dispatch(actions.selectRecsFromAccordion(startDimIdx, endDimIdx));
        },
        setLastClickedDimIdx: () => {
            dispatch(actions.setLastClickedDimIdx(ownProps.itemDimIdx));
        },
    };
};

const mapStateToProps = (state, ownProps) => {
    const moduleState = selectors.getModuleState(state);

    const recTypeInfoList = recsSelectors.getRecTypeInfoList(state);
    const isInactiveTab = moduleState.filter.activeTab === RecEventListTabs.INACTIVE;
    const recGuidToRecSummaryMap = isInactiveTab
        ? recsSelectors.getRecGeneralGuidToInactiveRecSummaryMap(state)
        : recsSelectors.getRecGeneralGuidToRecSummaryMap(state);

    let lastClickedDimIdx;
    switch (moduleState.filter.activeTab) {
        case RecEventListTabs.ACTIVE:
            lastClickedDimIdx = moduleState.allAccordionInit.lastClickedDimIdx;
            break;
        case RecEventListTabs.INACTIVE:
            lastClickedDimIdx = moduleState.inactiveAccordionInit.lastClickedDimIdx;
            break;
        case RecEventListTabs.SELECTED:
            lastClickedDimIdx = moduleState.selectedAccordionInit.lastClickedDimIdx;
            break;
        default:
            throw new Error("unreachable: unknown selected tab");
    }

    const recSummary = recGuidToRecSummaryMap.get(ownProps.objGuid);
    const recTypeInfo = recSummary
        ? recTypeInfoList.find((typeInfo) => typeInfo.recTypeGuid === recSummary.recTypeGuid)
        : undefined;

    const displayName = recSummary ? recSummary.recDisplayName : undefined;
    const modifiedUserDetails = recSummary
        ? [recSummary.modifiedBy, recSummary.modifiedDate.toLowerCase()]
              .filter((x) => x)
              .join(" - ")
        : undefined;
    const summary = recSummary
        ? [recSummary.croppingSeasonName, recSummary.cropDisplayName]
              .filter((v) => v && v !== "")
              .join(" - ")
        : undefined;
    const statusCodeClassName = recSummary
        ? getClassNameForStatusFromEvent(recSummary, recTypeInfo)
        : undefined;
    const statusMessageArg = recSummary
        ? { ...recSummary, statusCode: recSummary.importedStatus }
        : undefined;
    const statusMessageId = recSummary
        ? recSummary.importedStatus === -1
            ? undefined
            : getEventStatusMessageId(recSummary, recTypeInfo)
        : undefined;
    const isSelected = recSummary
        ? moduleState.selectedRecGuidSet.has(ownProps.objGuid)
        : undefined;

    const contextMenu = recSummary ? (
        <ContextMenu
            itemDimIdx={ownProps.itemDimIdx}
            objGuid={ownProps.objGuid}
            activeTab={moduleState.filter.activeTab}
        />
    ) : null;

    const props = {
        contextMenu,
        displayName,
        lastClickedDimIdx,
        isSelected,
        modifiedUserDetails,
        summary,
        statusCodeClassName,
        statusMessageArg,
        statusMessageId,
        isInactiveTab,
    };

    if (statusMessageId) {
        props.statusMessageArg = statusMessageArg;
        props.statusMessageId = statusMessageId;
    } else {
        props.status = "";
    }

    return props;
};

export const RecItem = connect(mapStateToProps, mapDispatchToProps)(RecEventItem);
