import { MAP_STATE_KEY } from "../../selectors";
import { IBufferOptions } from "@ai360/core";
export const MAP_TOOLS_STATE_KEY = "MAP_TOOLS_STATE";

const _mapTools = (state) => state[MAP_STATE_KEY][MAP_TOOLS_STATE_KEY];

export const getActiveMapTool = (state: unknown): any => _mapTools(state).activeMapTool;

export const getActiveToolset = (state: unknown): any => _mapTools(state).activeToolset;

export const getAreaUnits = (state: unknown): any => _mapTools(state).areaUnits;

export const getBatchReset = (state: unknown): any => _mapTools(state).batchReset;

export const getBufferOptions = (state: unknown): IBufferOptions => _mapTools(state).bufferOptions;

export const getBufferReady = (state: unknown): boolean => _mapTools(state).bufferReady;

export const getCurrentProcessCounts = (state: unknown): any =>
    _mapTools(state).currentProcessCounts;

export const getDrawToolFlags = (state: unknown): any => _mapTools(state).drawToolFlags;

export const getFieldEditGeometries = (state: unknown): any =>
    _mapTools(state).currentFieldEdits.geometries;

export const getFieldEditsChanged = (state: unknown): any =>
    _mapTools(state).currentFieldEdits.changed;

export const getFieldEditsLoaded = (state: unknown): any =>
    _mapTools(state).currentFieldEdits.loaded;

export const getGridRemoveFlag = (state: unknown): any => _mapTools(state).gridRemoveFlag;

export const getLengthUnits = (state: unknown): any => _mapTools(state).lengthUnits;

export const getToolsProcessing = (state: unknown): any => _mapTools(state).isProcessing;

export const getToolsetDisabled = (state: unknown): any => _mapTools(state).toolsetDisabled;

export const getToolsetPayload = (state: unknown): any => _mapTools(state).toolsetPayload;

export const getTraceApplyFlag = (state: unknown): any => _mapTools(state).traceFlags.apply;

export const getTraceResetFlag = (state: unknown): any => _mapTools(state).traceFlags.reset;

export const getVisibleToolbarMenuDimensions = (state: unknown): any =>
    _mapTools(state).visibleToolbarMenuDimensions;
