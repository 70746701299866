import React, { Component, ReactElement, ReactNode } from "react";
import classnames from "classnames";

export interface IPaneProps {
    className?: string;
    children?: ReactNode | ReactNode[];
    label?: string | JSX.Element;
    disabled?: boolean;
}

export default class Pane extends Component<IPaneProps> {
    static defaultProps = {
        className: "",
        children: [],
    };

    render(): ReactElement {
        return (
            <div className={classnames("tabs-pane", this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}
