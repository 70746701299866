import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";
import { messages as globalMessages } from "~/i18n-messages";
import { Loader, SelectInput } from "~/core";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";
import { recsSelectors, actions as recsEventsActions } from "~/recs-events";
import { DeleteConfDialog } from "../../../../context-menus/item-delete-confirmation-dialog";

import { ModuleFiltersToggle, ModuleFilters } from "../../../../module-filters";
import { ModuleSearch } from "../../../../module-search";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { ErrorDetailsDialog } from "~/action-panel/components/common/error-details-dialog/error-details-dialog";
import ExportControllerFileModal from "./dialog-boxes/export-controller-file-modal";
import { RecFilterTabs } from "./rec-filter-tabs/rec-filter-tabs";
import { RecAccordion } from "./rec-accordion/rec-accordion";

import "./rec-list.css";
import { selectors as cdSelectors } from "~/customer-data";
import { getTheUserLockCustomer } from "~/login/selectors";
import { LockIcon } from "~/core/icons";
import { logFirebaseEvent } from "~/utils/firebase";

const messages = defineMessages({
    convertRecToEventConfirmation: {
        id: "recList.confirmationDialog.convertRecToEventConfirmation",
        defaultMessage: "Are you sure you want to create a new Event from this Rec?",
    },
    convertRecsToEventsConfirmation: {
        id: "recList.confirmationDialog.convertRecsToEventsConfirmation",
        defaultMessage: "Are you sure you want to create new Events from the selected Recs?",
    },
    cropPlaceholder: {
        id: "recList.filterTabs.cropPlaceholder",
        defaultMessage: "Crop",
    },
    seasonPlaceholder: {
        id: "recList.filterTabs.seasonPlaceholder",
        defaultMessage: "Season",
    },
    recTypePlaceholder: {
        id: "recList.filterTabs.recTypePlaceholder",
        defaultMessage: "Rec Type",
    },
    allFieldsLocked: {
        id: "recList.allFieldsLocked",
        defaultMessage: "All selected Fields are not Enrolled",
    },
});

const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;

interface IProps {
    closeExportControllerFileModal: () => void;
    errorDetailsToShow: string;
    convertRecsToEventsGuidMap: any;
    exportControllerFile: (exportRecRequest: any) => void;
    exportRecGeneralGuidSet: any;
    exportRecSummaryArray: any[];
    filterValueCount: string;
    hideErrorDetails: () => void;
    intl: any;
    isLockIconShowing: boolean;
    isPanelLoading: boolean;
    onClearConvertRecsToEventsGuidMap: () => void;
    onConvertRecsToEvents: (convertRecsToEventsGuidMap: any) => void;
    onFetchPicklists: () => void;
    onToggleFilters: (value: any) => void;
    picklistOptionsCroppingSeason: any[];
    recFilter: any;
    recFilterOptions: any;
    searchValue: string;
    setRecFilter: (filter: any) => void;
    showFilters: boolean;
    isRecLoading: boolean;
    updateSearchValue: (value: any) => void;
}

interface IState {
    lastSearchValue: any;
}

export class RecList_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            lastSearchValue: null,
        };
    }

    private onSearchChange(newValue: any) {
        if (this.state.lastSearchValue !== newValue) {
            logFirebaseEvent("search_recs");
            this.setState(
                {
                    lastSearchValue: newValue,
                },
                () => this.props.updateSearchValue(newValue)
            );
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const {
            closeExportControllerFileModal,
            errorDetailsToShow,
            convertRecsToEventsGuidMap,
            exportControllerFile,
            exportRecGeneralGuidSet,
            exportRecSummaryArray,
            filterValueCount,
            isLockIconShowing,
            isPanelLoading,
            onClearConvertRecsToEventsGuidMap,
            onConvertRecsToEvents,
            onToggleFilters,
            recFilter,
            recFilterOptions,
            searchValue,
            setRecFilter,
            showFilters,
            isRecLoading,
        } = this.props;
        const accordion = (
            <div
                className={classNames("pane-content", {
                    "pane-content-loader": isPanelLoading,
                })}
            >
                {isPanelLoading ? (
                    <Loader />
                ) : isLockIconShowing ? (
                    <LockIcon className="lock-icon" />
                ) : (
                    <RecAccordion />
                )}
                {!isPanelLoading && isLockIconShowing ? (
                    <div className="lock-icon-text">{formatMessage(messages.allFieldsLocked)}</div>
                ) : null}
            </div>
        );

        const filterInputEls = [
            <SelectInput
                key={0}
                optionIsHiddenKey={ACTIVE_YN}
                options={recFilterOptions.croppingSeasonOptions}
                placeholderText={formatMessage(messages.seasonPlaceholder)}
                onChange={(seasonGuid) => setRecFilter({ seasonGuid })}
                value={recFilter.seasonGuid}
            />,
            <SelectInput
                key={1}
                optionIsHiddenKey={ACTIVE_YN}
                options={recFilterOptions.recTypeOptions}
                placeholderText={formatMessage(messages.recTypePlaceholder)}
                onChange={(recTypeGuid) => setRecFilter({ recTypeGuid })}
                value={recFilter.recTypeGuid}
            />,
            <SelectInput
                key={2}
                optionIsHiddenKey={ACTIVE_YN}
                options={recFilterOptions.cropOptions}
                placeholderText={formatMessage(messages.cropPlaceholder)}
                onChange={(cropGuid) => setRecFilter({ cropGuid })}
                value={recFilter.cropGuid}
            />,
        ];

        const convertRecToEventConfDialog =
            convertRecsToEventsGuidMap.size > 0
                ? DeleteConfDialog({
                      formatMessage,
                      deleteConfirmationTitle: globalMessages.confirmTitle,
                      deleteConfirmationMessage:
                          convertRecsToEventsGuidMap.size > 1
                              ? messages.convertRecsToEventsConfirmation
                              : messages.convertRecToEventConfirmation,
                      onConfirmationClick: (confirmation) => {
                          if (confirmation) {
                              onConvertRecsToEvents(convertRecsToEventsGuidMap);
                          }
                          onClearConvertRecsToEventsGuidMap();
                      },
                      className: undefined,
                  })
                : null;

        const exportControllerFileModalProps = {
            isOpen: exportRecGeneralGuidSet.size > 0,
            onAction: (exportRecRequest) => exportControllerFile(exportRecRequest),
            onClose: () => closeExportControllerFileModal(),
            recGeneralGuidList: Array.from(exportRecGeneralGuidSet),
            recSummaries: exportRecSummaryArray,
        };

        const errorDetailsDialogProps = {
            onClose: () => this.props.hideErrorDetails(),
            correlationId: errorDetailsToShow,
            logType: "createrec",
        };

        return (
            <div className={classNames("rec-list")}>
                {!isRecLoading ? null : <Loader className="module-loader" />}
                <div className="module-filter-row">
                    <ModuleSearch
                        searchValue={searchValue}
                        onSearchChange={(newValue) => this.onSearchChange(newValue)}
                    />
                    <ModuleFiltersToggle
                        filterValueCount={filterValueCount}
                        filterVisible={showFilters}
                        onToggleFilters={(newValue) => {
                            logFirebaseEvent("filters_recs");
                            onToggleFilters(newValue);
                        }}
                        showFilterValueCount={true}
                    />
                </div>
                {!showFilters ? null : (
                    <div className="module-filter-row module-filters">
                        <ModuleFilters>{filterInputEls}</ModuleFilters>
                    </div>
                )}

                <RecFilterTabs>{accordion}</RecFilterTabs>
                <ExportControllerFileModal {...exportControllerFileModalProps} />
                {!errorDetailsToShow ? null : <ErrorDetailsDialog {...errorDetailsDialogProps} />}
                {convertRecToEventConfDialog}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const recsModuleState = selectors.getModuleState(state);
    const recGeneralGuidToRecMap = recsSelectors.getRecGeneralGuidToRecSummaryMap(state);

    const selectedFieldGuids = cdSelectors.getSelectedFieldGuids(state);
    const lockCustomersNotEnrolled = getTheUserLockCustomer(state);

    const showLockIcon =
        lockCustomersNotEnrolled &&
        selectedFieldGuids.count() > 0 &&
        Array.from(recGeneralGuidToRecMap.values()).some(
            (e) => selectedFieldGuids.has(e.fieldGuid) && !e.customerEnrolled
        );

    return {
        errorDetailsToShow: selectors.getErrorToShow(state),
        convertRecsToEventsGuidMap: selectors.getConvertRecsToEventsGuidMap(state),
        exportRecGeneralGuidSet: selectors.getExportRecGeneralGuiSet(state),
        exportRecSummaryArray: recsSelectors.getExportRecSummaryArray(
            state,
            recsModuleState.exportRecGeneralGuidSet
        ),
        filterValueCount: recsModuleState.filterValueCount,
        isPanelLoading: recsModuleState.isPanelLoading,
        isRecLoading: recsModuleState.isRecLoading,
        picklistOptionsCroppingSeason: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_CROPPING_SEASON)
        ),
        isLockIconShowing: showLockIcon,
        recFilter: recsModuleState.filter,
        recFilterOptions: recsModuleState.filterOptions,
        showFilters: selectors.getShowFilters(state),
        searchValue: selectors.getSearchValue(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeExportControllerFileModal: () => dispatch(actions.clearExportRecGeneralGuidSet()),
        exportControllerFile: (exportRecRequest) =>
            dispatch(actions.exportControllerFile(exportRecRequest)),
        hideErrorDetails: () => dispatch(actions.hideErrorDetails()),
        onClearConvertRecsToEventsGuidMap: () =>
            dispatch(actions.clearConvertRecsToEventsGuidMap()),
        onConvertRecsToEvents: (convertRecsToEventsGuidMap) =>
            dispatch(recsEventsActions.convertRecsToEvents(convertRecsToEventsGuidMap)),
        onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
        onToggleFilters: (showFilters) => dispatch(actions.setShowFilters(showFilters)),
        setRecFilter: (filter) => dispatch(actions.setRecFilter(filter)),
        updateSearchValue: (newSearchValue) => dispatch(actions.updateSearchValue(newSearchValue)),
    };
};

export const RecList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecList_));
