import React, { Component } from "react";
import { CompactPicker, RGBColor } from "react-color";
import { Checkboard } from "react-color/lib/components/common";
import { CompactColorTransparency } from "./compact-color-transparency";
import { DownArrowIcon, UpArrowIcon } from "~/core/icons";
import classname from "classnames";
import "./color-picker.css";

export interface IColorPickerProps {
    className: string;
    hex?: string;
    tabIndex?: number;
    transparencyLabel?: string;
    type?: string;
    value?: any;
    onChange?: (event: string | RGBColor) => void;
}

export interface IColorPickerState {
    value: any;
    hasFocus: boolean;
    hex: string;
    displayColorPicker: boolean;
    suppressError: boolean;
}

export interface IColor {
    rgb: RGBColor;
    hex: string;
}
export class ColorPicker extends Component<IColorPickerProps, IColorPickerState> {
    static defaultColor = "#000000";
    static defaultType = "compact";
    static defaultProps = {
        tabIndex: 0,
        type: ColorPicker.defaultType,
        value: ColorPicker.defaultColor,
        className: "",
    };

    ///----------------------------///
    constructor(props: IColorPickerProps) {
        super(props);
        this.state = {
            hasFocus: false,
            hex: props.hex,
            value: props.value,
            displayColorPicker: false,
            suppressError: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IColorPickerProps): void {
        if (nextProps.value !== this.state.value || nextProps.hex !== this.state.hex) {
            this.setState({
                value: nextProps.value,
                hex: nextProps.hex,
            });
        }
    }

    handleOnFocus = (): void => {
        this.setState({ hasFocus: true });
    };

    handleOnBlur = (): void => {
        this.setState({ hasFocus: false });
    };

    handleClick = (): void => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleChangeComplete = (color: IColor): void => {
        if (this.props.onChange) {
            this.props.onChange(this.isTypeCompactTransparency() ? color.rgb : color.hex);
        }
        this.setState({
            value: color.rgb,
            hex: color.hex,
        });
    };

    handleClose = (): void => {
        this.setState({ displayColorPicker: false, hasFocus: false });
    };

    isTypeCompactTransparency = (): boolean => {
        return this.props.type === "compacttransparency";
    };

    ///--------------------------///
    render(): JSX.Element {
        const showError = false; //this.props.errorCodes.length > 0 ? false : false;
        const colorPickerContClass = [
            "color-picker-container",
            {
                focus: this.state.hasFocus || this.state.displayColorPicker,
                "error-validation": showError && !this.state.suppressError,
            },
        ];
        const colorPickerContProps = {
            onFocus: this.handleOnFocus,
            onBlur: this.handleOnBlur,
        };
        const defaultColorArray = [
            "#000000",
            "#FF0000",
            "#FA6300",
            "#ffff00",
            "#006600",
            "#002300",
            "#0078A2",
            "#1136FF",
            "#002060",
            "#300351",
            "#808080",
            "#FF3535",
            "#FA8235",
            "#FFFB5B",
            "#4E7933",
            "#2B431D",
            "#00A1DA",
            "#1E52FA",
            "#4027FD",
            "#5C069C",
            "#C0C0C0",
            "#FF8380",
            "#FAA26D",
            "#FFEC8F",
            "#87BF65",
            "#42672B",
            "#8BE1FF",
            "#527DF8",
            "#6D59FD",
            "#991EF6",
            "#FFFFFF",
            "#FFB3B0",
            "#FAC2A4",
            "#F5FFCD",
            "#C5FFC5",
            "#759775",
            "#C5F0FF",
            "#8DBAE3",
            "#A194FE",
            "#7571DD",
        ];
        const buttonStyle = {
            backgroundColor: this.state.hex
                ? `rgba(${this.state.value.r}, ${this.state.value.g}, ${this.state.value.b}, ${this.state.value.a})`
                : this.state.value
                ? this.state.value
                : ColorPicker.defaultColor,
        };
        return (
            <div className={classname(colorPickerContClass)} {...colorPickerContProps}>
                <div className="button-container" onClick={this.handleClick}>
                    <div className="color-selector-wrap">
                        <Checkboard white="#fff" grey="#aaa" />
                        <div className="color-selector-button" style={buttonStyle}></div>
                    </div>
                    {this.state.displayColorPicker ? <UpArrowIcon /> : <DownArrowIcon />}
                </div>
                {!this.state.displayColorPicker ? null : (
                    <div className="popover">
                        <div className="cover" onClick={this.handleClose} />
                        {this.isTypeCompactTransparency() ? (
                            <CompactColorTransparency
                                colors={defaultColorArray}
                                color={this.state.value}
                                hex={this.state.hex}
                                rgb={this.state.value}
                                onChangeComplete={this.handleChangeComplete}
                                transparencyLabel={this.props.transparencyLabel}
                            />
                        ) : (
                            <CompactPicker
                                colors={defaultColorArray}
                                color={this.state.value}
                                onChangeComplete={this.handleChangeComplete}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
