import { createService } from "~/hocs/crud";
import * as model from "./model";
import defaultPersonRecord from "./default-record";
import { AddressAPI, VendorAPI, HierarchyAPI } from "@ai360/core";
import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
import {
    PICKLIST_VENDOR_TYPE,
    PICKLIST_PHONE_TYPE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { adminData } from "~/admin/data";
// Module Name
export const MODEL_NAME = "vendor";

//  Request payload
export const REQUEST_PAYLOAD_FILTER = "vendorFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "vendorSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "vendorPageOptions";

// URLs
export const URL = VendorAPI.GET_VENDOR_LIST;
export const REQUEST_ORG_LEVEL = HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS_GUID;
export const AUTO_SEARCH_URL = VendorAPI.AUTO_SEARCH_URL;
export const CREATE = VendorAPI.CREATE;
export const DELETE = VendorAPI.DELETE;
export const GETRECORD = VendorAPI.GETRECORD;
export const UPDATE = VendorAPI.UPDATE;

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        City: "",
        IsActive: "",
        StateAbbreviation: "",
        VendorGuid: "",
        VendorName: "",
        VendorTypeGuid: "",
        VendorTypeName: "",
        stateGuid: "",
        orgLevelGuid: null,
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
    userGuid: null,
};

export const defaultSort = {
    FieldName: "VendorName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const dropdowns = {
    [model.PROPS_ADDRESS_LIST]: AddressAPI.REQUEST_ADDRESS_TYPE,
    [model.PROPS_COUNTRIES_LIST]: AddressAPI.REQUEST_COUNTRIES,
    [model.PROPS_STATES_LIST]: { url: AddressAPI.REQUEST_STATES, model: "" },
    [model.PROPS_ORG_LEVEL_LIST]: { url: REQUEST_ORG_LEVEL, model: "_" },
};
//
export const pickLists = {
    [PICKLIST_VENDOR_TYPE]: getPickListCode(PICKLIST_VENDOR_TYPE),
    [PICKLIST_PHONE_TYPE]: getPickListCode(PICKLIST_PHONE_TYPE),
};

// Service
export const service = createService({
    guid: model.PROPS_VENDOR_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    urls: {
        URL,
        AUTO_SEARCH_URL,
        GETRECORD,
        UPDATE,
        DELETE,
        CREATE,
    },
    pickLists,
    activeColumnName: adminData.PROPS_IS_ACTIVE,
    _defaultLabels: {
        [model.PROPS_VENDOR_NAME]: { label: "vendorName", gridCol: 25 },
        [model.PROPS_VENDOR_TYPE_NAME]: { label: "vendorType", gridCol: 25 },
        [model.PROPS_CITY]: { label: "city", gridCol: 25 },
        [model.PROPS_STATE_ABBREVIATION]: { label: "state", gridCol: 15 },
        [model.PROPS_IS_ACTIVE]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
        },
    },
    getDefaultRecord: () => ({ ...defaultPersonRecord() }),
    defaultSort,
});
