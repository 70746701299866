import { ITextInputProps, ITextInputState, TextInput } from "../text-input/text-input";
import { injectIntl, intlShape, defineMessages } from "react-intl";

const messages = defineMessages({
    allowNegatives: {
        id: "numericInput.allowNegativesTitle",
        defaultMessage: " Negative numbers are allowed.",
    },
    numericTitleText: {
        id: "numericInput.numericInputTitle",
        defaultMessage: "Limited to {whole} whole numbers and {scale} decimal places.",
    },
});

export interface INumericInputProps extends ITextInputProps {
    allowNegatives: boolean;
    defaultValue: string;
    intl: intlShape;
    maxlength: number;
    precision: number;
    scale: number;
    suppressError: boolean;
    suppressFormatting: boolean;
    useRawTitle: boolean;
    useValueTitle: boolean;
}

export interface INumericInputState extends ITextInputState {
    cursorPosition: number;
}

class NumericInput_ extends TextInput<INumericInputProps, INumericInputState> {
    public static defaultProps = {
        ...TextInput.defaultProps,
        allowNegatives: false,
        defaultValue: undefined,
        placeholderText: "",
        precision: 17,
        scale: 7,
        suppressFormatting: false,
        useRawTitle: true,
        useValueTitle: false,
    };

    constructor(props: INumericInputProps) {
        super(props);
        this.state = {
            ...super.state,
            cursorPosition: 0,
        };
    }

    protected formatValue = (value: string): string => {
        if (value == null) {
            return "";
        }
        let returnString: string;
        value = (value + "").trim();
        value = value.replace(/,/g, "");

        const halves: string[] = value.split(".");
        if (!this.props.suppressFormatting) {
            halves[0] = halves[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (this.props.scale === 0) {
            returnString = halves[0];
        } else {
            if (typeof halves[1] !== "undefined") {
                halves[1].replace(/./g, "");
                if (halves.length > 2) {
                    halves.length = 2;
                }
            }
            returnString = halves.join(".");
        }

        return returnString;
    };

    protected parseValue = (value: string, previousValue?: string): string => {
        if (value == null) {
            return "";
        }
        const { scale } = this.props;
        let parsedString: string =
            value || Number(value) === 0 ? String(value).replace(new RegExp(",", "g"), "") : "";

        // There is no mathematical representation of `0.` in numeric input and the actual value will remain as 0
        // In order to display `0.` when string starts with `.` this condition will replace the formatted value with `0.`
        if (previousValue === "0.") {
            return "0.";
        }
        if (parsedString.length > 0 && scale) {
            const halves =
                parsedString.substring(0, 1) === "."
                    ? ["0"].concat(parsedString.split(".")[0])
                    : parsedString.split(".");
            if (halves[1] && halves[1].length > scale) {
                const scaleFactor: number = Math.pow(10, scale);
                const safeParsedValue: string =
                    parsedString.substring(0, 1) === "." ? "0".concat(parsedString) : parsedString;
                const roundedValue: string = (
                    Math.round(Number(safeParsedValue) * scaleFactor) / scaleFactor
                ).toString();
                halves[0] = roundedValue.split(".")[0];
                halves[1] =
                    roundedValue.split(".")[1] == null
                        ? "00"
                        : roundedValue.split(".")[1].substr(0, scale);
            }
            parsedString = halves.join(".");
        }
        //return undefined for empty strings so not misinterpreted as a 0:
        return parsedString.length > 0 ? parsedString : this.props.defaultValue;
    };

    protected validateValue = (value: string): boolean => {
        if (value == null) {
            return true;
        }
        if (value.length > this.props.maxlength) {
            return false;
        }
        let strRegEx: string;
        const allowNegativeRegEx: string = this.props.allowNegatives ? "|\\-" : "";
        if (this.props.scale === 0) {
            strRegEx = "^[\\+" + allowNegativeRegEx + "]?(?:\\d{0," + this.props.precision + "})?$";
        } else {
            strRegEx =
                "^[\\+" +
                allowNegativeRegEx +
                "]?(?:\\d{0," +
                (this.props.precision - this.props.scale) +
                "})(?:\\.\\d{0," +
                this.props.scale +
                "})?$";
        }

        return new RegExp(strRegEx).test(value);
    };

    protected override valueToState(rawValue: string, oldValue?: string): void {
        const newValue: string = this.parseValue(rawValue, oldValue);
        const formattedValue: string = this.formatValue(newValue);
        const isValidValue: boolean = this.validateValue(newValue);

        if (this.state.hasFocus) {
            this.setState({
                cursorPosition: !isValidValue
                    ? this.input.selectionStart - 1
                    : this.preserveCursorPosition(
                          rawValue,
                          this.input.selectionStart,
                          this.state.value
                      ),
            });
        }

        const stateAndFormattedAreEmpty = formattedValue === "" && !this.state.value;
        if (isValidValue && formattedValue !== this.state.value && !stateAndFormattedAreEmpty) {
            this.setState({ value: formattedValue }, () => {
                if (this.props.onChange) {
                    this.props.onChange(
                        newValue,
                        formattedValue,
                        newValue ? Number(newValue) : null
                    );
                }
            });
        }
    }

    protected override getTitle = (): string => {
        if (this.props.useRawTitle || this.props.useValueTitle) {
            const rawTitle: string = this.props.title || this.props.placeholderText;
            return rawTitle && !this.props.useValueTitle ? rawTitle : `${this.props.value}`;
        }
        const titleText = this.props.intl.formatMessage(messages.numericTitleText, {
            whole: this.props.precision - this.props.scale,
            scale: `${this.props.scale}`,
        });
        return this.props.allowNegatives
            ? titleText + this.props.intl.formatMessage(messages.allowNegatives)
            : titleText;
    };

    protected preserveCursorPosition = (
        rawValue: string,
        currentPosition: number,
        previousValue: string
    ) => {
        const safeRawVal = rawValue == null ? "" : rawValue;
        const safeOldVal = previousValue == null ? "" : previousValue.toString();

        const parsedValue = this.parseValue(safeRawVal);

        if (parsedValue) {
            const formattedVal = this.formatValue(parsedValue);
            const separatorCount = (formattedVal.substr(0, currentPosition - 1).match(/,/g) || [])
                .length;
            const oldSeparatorCount = (safeOldVal.substr(0, currentPosition - 1).match(/,/g) || [])
                .length;
            if (safeRawVal.length > safeOldVal.length) {
                // Added new char
                return (
                    currentPosition +
                    (separatorCount - oldSeparatorCount) +
                    (safeRawVal.toString().substr(0, 1) === "." ? 1 : 0)
                );
            }
            // Removed char
            return (
                currentPosition -
                (oldSeparatorCount - separatorCount) +
                (safeRawVal.toString().substr(0, 1) === "." ? 1 : 0)
            );
        }
        return null;
    };

    protected restoreCursorPosition(): void {
        if (this.state.hasFocus && this.input) {
            this.input.setSelectionRange(this.state.cursorPosition, this.state.cursorPosition);
        }
    }
}
export const NumericInput = injectIntl(NumericInput_);
