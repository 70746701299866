import React, { Component } from "react";
import PropTypes from "prop-types";
import { model, service } from "../../data";
import { messages } from "../../../i18n-messages";
import { Bucket, BucketHeader, NumericInput, Section, SelectInput, SubSection } from "~/core";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { prepareSelectableOptions, mapToPicklistValue } from "~/admin/utils";
import * as unitService from "~/core/units/unit-names";
import * as picklistService from "~/core/picklist/picklist-names";
export default class Specifications extends Component {
    static propTypes = {
        equipmentParentType: PropTypes.string,
        equipmentSetup: PropTypes.object,
        formatMessage: PropTypes.func,
        onNumberChange: PropTypes.func,
        onSelectInputChange: PropTypes.func,
        onTextChange: PropTypes.func,
        weight: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            [unitService.UNIT_WEIGHT]: prepareSelectableOptions(props[unitService.UNIT_WEIGHT], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_LENGTH]: prepareSelectableOptions(props[unitService.UNIT_LENGTH], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_VOLUME]: prepareSelectableOptions(props[unitService.UNIT_VOLUME], {
                guid: GUID,
                label: NAME,
            }),
            [picklistService.PICKLIST_CLASS_SIZE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_CLASS_SIZE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [picklistService.PICKLIST_FUEL_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_FUEL_TYPE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [picklistService.PICKLIST_DRIVE_TRAIN]: prepareSelectableOptions(
                props[picklistService.PICKLIST_DRIVE_TRAIN],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [picklistService.PICKLIST_TRANSMISSION_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_TRANSMISSION_TYPE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [unitService.UNIT_SPEED]: prepareSelectableOptions(props[unitService.UNIT_SPEED], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_POWER]: prepareSelectableOptions(props[unitService.UNIT_POWER], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_FUEL_EFFICIENCY]: prepareSelectableOptions(
                props[unitService.UNIT_FUEL_EFFICIENCY],
                { guid: GUID, label: NAME }
            ),
            equipmentSetup: props.equipmentSetup || {},
            equipmentParentType: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps[unitService.UNIT_WEIGHT] !== this.props[unitService.UNIT_WEIGHT]) {
            this.setState({
                [unitService.UNIT_WEIGHT]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_WEIGHT],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (nextProps[unitService.UNIT_LENGTH] !== this.props[unitService.UNIT_LENGTH]) {
            this.setState({
                [unitService.UNIT_LENGTH]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_LENGTH],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (nextProps[unitService.UNIT_VOLUME] !== this.props[unitService.UNIT_VOLUME]) {
            this.setState({
                [unitService.UNIT_VOLUME]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_VOLUME],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (
            nextProps[picklistService.PICKLIST_CLASS_SIZE] !==
            this.props[picklistService.PICKLIST_CLASS_SIZE]
        ) {
            this.setState({
                [picklistService.PICKLIST_CLASS_SIZE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_CLASS_SIZE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
        if (
            nextProps[picklistService.PICKLIST_FUEL_TYPE] !==
            this.props[picklistService.PICKLIST_FUEL_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_FUEL_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_FUEL_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
        if (
            nextProps[picklistService.PICKLIST_DRIVE_TRAIN] !==
            this.props[picklistService.PICKLIST_DRIVE_TRAIN]
        ) {
            this.setState({
                [picklistService.PICKLIST_DRIVE_TRAIN]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_DRIVE_TRAIN],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
        if (
            nextProps[picklistService.PICKLIST_TRANSMISSION_TYPE] !==
            this.props[picklistService.PICKLIST_TRANSMISSION_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_TRANSMISSION_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_TRANSMISSION_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
        if (nextProps[unitService.UNIT_SPEED] !== this.props[unitService.UNIT_SPEED]) {
            this.setState({
                [unitService.UNIT_SPEED]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_SPEED],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (nextProps[unitService.UNIT_POWER] !== this.props[unitService.UNIT_POWER]) {
            this.setState({
                [unitService.UNIT_POWER]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_POWER],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (
            nextProps[unitService.UNIT_FUEL_EFFICIENCY] !==
            this.props[unitService.UNIT_FUEL_EFFICIENCY]
        ) {
            this.setState({
                [unitService.UNIT_FUEL_EFFICIENCY]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_FUEL_EFFICIENCY],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (
            JSON.stringify(nextProps.equipmentSetup) !== JSON.stringify(this.props.equipmentSetup)
        ) {
            this.setState({
                equipmentSetup: nextProps.equipmentSetup,
            });
        }
        if (nextProps.equipmentParentType !== this.props.equipmentParentType) {
            this.setState({
                equipmentParentType: nextProps.equipmentParentType,
            });
        }
    }

    showHiddenFields = (equipmentParentId) => {
        const { EQUIPMENT_PARENT_ID_KEYSET } = service;
        const reducedInputIds = [
            EQUIPMENT_PARENT_ID_KEYSET.Accessories,
            EQUIPMENT_PARENT_ID_KEYSET.Irrigation,
            EQUIPMENT_PARENT_ID_KEYSET.Planting,
            EQUIPMENT_PARENT_ID_KEYSET.Tillage,
        ];
        if (!equipmentParentId) {
            return false;
        }
        if (reducedInputIds.indexOf(equipmentParentId) > -1) {
            return false;
        }
        return true;
    };

    render() {
        const { formatMessage, onNumberChange, onSelectInputChange } = this.props;
        const { equipmentParentType, equipmentSetup } = this.state;
        return (
            <Bucket
                className="form-section-bucket"
                showSymbol={true}
                isCollapsible={true}
                isExpanded={false}
            >
                <BucketHeader>{formatMessage(messages.specifications)}</BucketHeader>
                <Section>
                    <SubSection className="column-3-gutter">
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.maxGrossWeight)}
                            value={equipmentSetup[model.PROPS_MAX_GROSS_WEIGHT]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_MAX_GROSS_WEIGHT, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.tareWeight)}
                            value={equipmentSetup[model.PROPS_TARE_WEIGHT]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_TARE_WEIGHT, value);
                            }}
                        />
                        <SelectInput
                            options={this.state[unitService.UNIT_WEIGHT]}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_WEIGHT],
                                selectedGuid: equipmentSetup[model.PROPS_WEIGHT_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_WEIGHT_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                            placeholderText={formatMessage(messages.weightUnit)}
                        />
                    </SubSection>
                    <SubSection className="column-3-gutter">
                        <NumericInput
                            scale={2}
                            precision={9}
                            value={equipmentSetup[model.PROPS_WIDTH]}
                            placeholderText={formatMessage(messages.width)}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_WIDTH, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            value={equipmentSetup[model.PROPS_LENGTH]}
                            placeholderText={formatMessage(messages.length)}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_LENGTH, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            value={equipmentSetup[model.PROPS_HEIGHT]}
                            placeholderText={formatMessage(messages.height)}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_HEIGHT, value);
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            value={equipmentSetup[model.PROPS_SWATH_WIDTH]}
                            placeholderText={formatMessage(messages.swathWidth)}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_SWATH_WIDTH, value);
                            }}
                        />
                        <SelectInput
                            options={this.state[unitService.UNIT_LENGTH]}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid: equipmentSetup[model.PROPS_DIMENSION_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_DIMENSION_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                            placeholderText={formatMessage(messages.dimensionUnit)}
                        />
                    </SubSection>
                    <SubSection className="column-3-gutter">
                        <SelectInput
                            options={this.state[picklistService.PICKLIST_CLASS_SIZE]}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_CLASS_SIZE],
                                selectedGuid: equipmentSetup[model.PROPS_CLASS_SIZE_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_CLASS_SIZE_GUID,
                                    },
                                    value
                                );
                            }}
                            placeholderText={formatMessage(messages.classSize)}
                        />
                        {!this.showHiddenFields(equipmentParentType) ? null : (
                            <NumericInput
                                scale={2}
                                precision={9}
                                value={equipmentSetup[model.PROPS_TOWING_CAPACITY]}
                                placeholderText={formatMessage(messages.towingCapacity)}
                                onChange={(value) => {
                                    onNumberChange(model.PROPS_TOWING_CAPACITY, value);
                                }}
                            />
                        )}
                        {!this.showHiddenFields(equipmentParentType) ? null : (
                            <SelectInput
                                options={this.state[unitService.UNIT_WEIGHT]}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                value={mapToPicklistValue({
                                    options: this.state[unitService.UNIT_WEIGHT],
                                    selectedGuid:
                                        equipmentSetup[model.PROPS_TOWING_CAPACITY_UNIT_GUID],
                                })}
                                placeholderText={formatMessage(messages.towingCapacityUnit)}
                                onChange={(value) => {
                                    onSelectInputChange(
                                        {
                                            guid: model.PROPS_TOWING_CAPACITY_UNIT_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        )}
                    </SubSection>
                    {!this.showHiddenFields(equipmentParentType) ? null : (
                        <div>
                            <SubSection>
                                <NumericInput
                                    scale={2}
                                    precision={9}
                                    value={equipmentSetup[model.PROPS_MAX_SPEED]}
                                    placeholderText={formatMessage(messages.maximumSpeed)}
                                    onChange={(value) => {
                                        onNumberChange(model.PROPS_MAX_SPEED, value);
                                    }}
                                />
                                <SelectInput
                                    options={this.state[unitService.UNIT_SPEED]}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    value={mapToPicklistValue({
                                        options: this.state[unitService.UNIT_SPEED],
                                        selectedGuid:
                                            equipmentSetup[model.PROPS_MAX_SPEED_UNIT_GUID],
                                    })}
                                    placeholderText={formatMessage(messages.maximumSpeedUnit)}
                                    onChange={(value) => {
                                        onSelectInputChange(
                                            {
                                                guid: model.PROPS_MAX_SPEED_UNIT_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />
                            </SubSection>
                            <SubSection className="column-3-gutter">
                                <SelectInput
                                    options={this.state[picklistService.PICKLIST_FUEL_TYPE]}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    value={mapToPicklistValue({
                                        options: this.state[picklistService.PICKLIST_FUEL_TYPE],
                                        selectedGuid: equipmentSetup[model.PROPS_FUEL_TYPE_GUID],
                                    })}
                                    placeholderText={formatMessage(messages.fuelType)}
                                    onChange={(value) => {
                                        onSelectInputChange(
                                            {
                                                guid: model.PROPS_FUEL_TYPE_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />
                                <NumericInput
                                    scale={2}
                                    precision={9}
                                    value={equipmentSetup[model.PROPS_FUEL_CAPACITY]}
                                    placeholderText={formatMessage(messages.fuelCapacity)}
                                    onChange={(value) => {
                                        onNumberChange(model.PROPS_FUEL_CAPACITY, value);
                                    }}
                                />
                                <SelectInput
                                    options={this.state[unitService.UNIT_VOLUME]}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    value={mapToPicklistValue({
                                        options: this.state[unitService.UNIT_VOLUME],
                                        selectedGuid:
                                            equipmentSetup[model.PROPS_FUEL_CAPACITY_UNIT_GUID],
                                    })}
                                    placeholderText={formatMessage(messages.fuelCapacityUnit)}
                                    onChange={(value) => {
                                        onSelectInputChange(
                                            {
                                                guid: model.PROPS_FUEL_CAPACITY_UNIT_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />
                            </SubSection>
                            <SubSection>
                                <SelectInput
                                    options={this.state[picklistService.PICKLIST_DRIVE_TRAIN]}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    value={mapToPicklistValue({
                                        options: this.state[picklistService.PICKLIST_DRIVE_TRAIN],
                                        selectedGuid: equipmentSetup[model.PROPS_DRIVE_TRAIN_GUID],
                                    })}
                                    placeholderText={formatMessage(messages.driveTrain)}
                                    onChange={(value) => {
                                        onSelectInputChange(
                                            {
                                                guid: model.PROPS_DRIVE_TRAIN_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />
                                <SelectInput
                                    options={this.state[picklistService.PICKLIST_TRANSMISSION_TYPE]}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    value={mapToPicklistValue({
                                        options:
                                            this.state[picklistService.PICKLIST_TRANSMISSION_TYPE],
                                        selectedGuid:
                                            equipmentSetup[model.PROPS_TRANSMISSION_TYPE_GUID],
                                    })}
                                    placeholderText={formatMessage(messages.transmission)}
                                    onChange={(value) => {
                                        onSelectInputChange(
                                            {
                                                guid: model.PROPS_TRANSMISSION_TYPE_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />
                            </SubSection>
                            <SubSection>
                                <NumericInput
                                    scale={2}
                                    precision={9}
                                    value={equipmentSetup[model.PROPS_FUEL_EFFICIENCY]}
                                    placeholderText={formatMessage(messages.fuelEfficiency)}
                                    onChange={(value) => {
                                        onNumberChange(model.PROPS_FUEL_EFFICIENCY, value);
                                    }}
                                />
                                <SelectInput
                                    options={this.state[unitService.UNIT_FUEL_EFFICIENCY]}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    value={mapToPicklistValue({
                                        options: this.state[unitService.UNIT_FUEL_EFFICIENCY],
                                        selectedGuid:
                                            equipmentSetup[model.PROPS_FUEL_EFFICIENCY_UNIT_GUID],
                                    })}
                                    placeholderText={formatMessage(messages.fuelEfficiencyUnit)}
                                    onChange={(value) => {
                                        onSelectInputChange(
                                            {
                                                guid: model.PROPS_FUEL_EFFICIENCY_UNIT_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />
                            </SubSection>
                            <SubSection>
                                <NumericInput
                                    scale={2}
                                    precision={9}
                                    value={equipmentSetup[model.PROPS_HORSE_POWER]}
                                    placeholderText={formatMessage(messages.horsepower)}
                                    onChange={(value) => {
                                        onNumberChange(model.PROPS_HORSE_POWER, value);
                                    }}
                                />
                                <SelectInput
                                    options={this.state[unitService.UNIT_POWER]}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    value={mapToPicklistValue({
                                        options: this.state[unitService.UNIT_POWER],
                                        selectedGuid:
                                            equipmentSetup[model.PROPS_HORSE_POWER_UNIT_GUID],
                                    })}
                                    placeholderText={formatMessage(messages.horsepowerUnit)}
                                    onChange={(value) => {
                                        onSelectInputChange(
                                            {
                                                guid: model.PROPS_HORSE_POWER_UNIT_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />
                            </SubSection>
                            <SubSection>
                                <NumericInput
                                    scale={0}
                                    precision={4}
                                    value={equipmentSetup[model.PROPS_CYLINDERS]}
                                    placeholderText={formatMessage(messages.numberOfCylinders)}
                                    onChange={(value) => {
                                        onNumberChange(model.PROPS_CYLINDERS, value);
                                    }}
                                />
                                <NumericInput
                                    scale={2}
                                    precision={9}
                                    value={equipmentSetup[model.PROPS_ENGINE_DISPLACEMENT]}
                                    placeholderText={formatMessage(messages.engineDisplacement)}
                                    onChange={(value) => {
                                        onNumberChange(model.PROPS_ENGINE_DISPLACEMENT, value);
                                    }}
                                />
                            </SubSection>
                        </div>
                    )}
                </Section>
            </Bucket>
        );
    }
}
