import { SearchAPI } from "@ai360/core";

export interface ISummary {
    fieldGuid: string;
    activeYn: boolean;
}

export interface IField<TSummary extends ISummary> extends SearchAPI.IFieldResult {
    summaries: TSummary[];
}

export async function fetchFieldsWithSummaries<TSummary extends ISummary>(
    fieldGuids: string[],
    userGuid: string,
    fetchSummaries: (fieldGuids: string[]) => Promise<TSummary[]>
): Promise<IField<TSummary>[]> {
    const fieldsPromise = SearchAPI.getFields({
        userGuid,
        fieldGuid: fieldGuids,
    });
    const summariesPromise = fetchSummaries(fieldGuids);

    const fields = await fieldsPromise;
    const summaries = await summariesPromise;
    return fields.map((field) => ({
        ...field,
        summaries: summaries.filter((x) => x.fieldGuid === field.id),
    }));
}
