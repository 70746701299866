import React, { Component } from "react";
import { withCrud, withMasked } from "~/hocs";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { service, actions } from "./data";
import { messages } from "../i18n-messages";
import { nestedGridContainer } from "~/hocs";
import { preventBubbleUp } from "~/admin/utils";
// Components
import { DataTable, Button } from "~/core";
import AddEditPanel from "./add-edit/add-edit-container";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { IAgBytesProps } from "../interfaces";

interface IProductPackageListProps extends IAgBytesProps<typeof actions, typeof AddEditPanel> {
    onRowSelection: () => void;
    showInactiveProp: boolean;
}
interface IProductPackageListState {
    showInactive: boolean;
}

export class ProductPackageList_ extends Component<
    IProductPackageListProps & InjectedIntlProps,
    IProductPackageListState
> {
    static defaultProps = {
        showInactiveProp: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            showInactive: false,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.showInactiveProp !== this.state.showInactive &&
            this.props.toggleActiveInactive
        ) {
            this.setState({
                showInactive: nextProps.showInactiveProp,
            });
            this.props.toggleActiveInactive();
        }
    }

    render() {
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        return (
            <div>
                <DataTable
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    onRowSelection={this.props.onRowSelection}
                    {...this.props}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "102",
                                childNameCode: "125-child",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={this.props.onSubmit} />
                            <Button type="cancel" onClick={this.props.closeSidePanel} />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}
export const ProductPackageList = injectIntl(
    nestedGridContainer(
        withMasked(withCrud(ProductPackageList_, service, actions)),
        service.modelName
    )
);
