import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { defineMessages } from "react-intl";
import { AppHelpers } from "@ai360/core";
import { actions as loginActions, getUser } from "~/login";

import { DialogBox, DialogBoxFooterType, Menu, NoLink } from "../../core";
import { getFirstName, getUserSystemProperties } from "../../login";
import { Navigation } from "../../navigation/components/navigation";
import SelectCompany from "../../login/components/select-company";
import { ThemeClassNames, getCurrentTheme } from "../../theme";
import { getIsEnabled } from "~/action-panel/selectors";

import { LocationIcon } from "~/core/icons";

import "./header.css";

const messages = defineMessages({
    greeting: { id: "header.greeting", defaultMessage: "Hello" },
    about: { id: "header.about", defaultMessage: "About" },
    account: { id: "header.account", defaultMessage: "Account" },
    orgLevelQuickChanger: {
        id: "header.orgLevelQuickChanger",
        defaultMessage: "Org Level Quick Changer",
    },
    logOut: { id: "header.logOut", defaultMessage: "Log Out" },
    preferences: { id: "header.preferences", defaultMessage: "Preferences" },
});

class Header_ extends Component {
    static propTypes = {
        closeSidePanel: PropTypes.func,
        firstName: PropTypes.string,
        intl: intlShape.isRequired,
        loadDetailPage: PropTypes.func,
        onLogoutUser: PropTypes.func,
        onReloginUser: PropTypes.func,
        themeClassName: PropTypes.oneOf(
            Object.keys(ThemeClassNames).map((k) => ThemeClassNames[k])
        ),
        isEnabled: PropTypes.bool,
        selectedCustomer: PropTypes.object,
        systemProperties: PropTypes.object,
        userRole: PropTypes.object,
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isSelectCompanyOpen: false,
        };
    }

    _onSelectCustomer = (customer) => {
        this.setState({
            selectedCustomer: customer,
        });
    };

    _toggleOpenSelectCompany = (isSelectCompanyOpen) => {
        this.setState({
            isSelectCompanyOpen,
        });
    };

    onRelogin = (history) => {
        this._toggleOpenSelectCompany(false);
        this.props.onReloginUser(history);
    };

    render() {
        const {
            themeClassName,
            firstName,
            onLogoutUser,
            loadDetailPage,
            closeSidePanel,
            intl,
            isEnabled,
            systemProperties,
            userRole,
            user,
        } = this.props;

        const { formatMessage } = intl;

        const menuItems = [
            {
                key: 0,
                label: formatMessage(messages.preferences),
                disabled: !isEnabled,
                action: () => loadDetailPage("1", "101"),
            },
        ];
        if (userRole.account) {
            menuItems.push({
                key: 1,
                label: formatMessage(messages.account),
                action: () => loadDetailPage("3", "101"),
            });
        }
        menuItems.push({
            key: 2,
            label: formatMessage(messages.about),
            action: () => loadDetailPage("4", "100"),
        });
        menuItems.push({
            key: 3,
            label: formatMessage(messages.logOut),
            action: () => onLogoutUser(),
        });

        const formatImage = (image) => {
            return !AppHelpers.fileIsSvgMarkup(image)
                ? AppHelpers.base64StringToBase64Image(image)
                : image;
        };
        const displayLogo =
            themeClassName.indexOf("-light") !== -1
                ? formatImage(systemProperties.systemLogoLight)
                : formatImage(systemProperties.systemLogoDark);
        const selectedCustomerName =
            !userRole.orgLevelQuickChanger || user.orgLevelList.length === 0
                ? null
                : user.lastUsedCustomerName;
        const selectedLocationName =
            !userRole.orgLevelQuickChanger || user.orgLevelList.length === 0
                ? ""
                : selectedCustomerName != null && selectedCustomerName != ""
                ? selectedCustomerName
                : user.lastUsedLocationGuid == null ||
                  user.lastUsedLocationGuid === "" ||
                  user.orgLevelList.find((org) => org.orgLevelGuid === user.lastUsedLocationGuid) ==
                      null
                ? user.companyName
                : user.orgLevelList.find((org) => org.orgLevelGuid === user.lastUsedLocationGuid)
                      .orgLevelName;

        return (
            <div className="header">
                <div className="logo-row">
                    <span onClick={closeSidePanel}>
                        <div className={"logo"}>
                            {AppHelpers.fileIsSvgMarkup(displayLogo) ? (
                                <div
                                    className={"system-logo svg-image"}
                                    dangerouslySetInnerHTML={{
                                        __html: displayLogo,
                                    }}
                                ></div>
                            ) : (
                                <div className={"system-logo"}>
                                    <img src={displayLogo} alt={systemProperties.systemName} />
                                </div>
                            )}
                        </div>
                    </span>
                    <div className="nav-user-container">
                        <div className="user-menu-container">
                            <span>{formatMessage(messages.greeting)}</span>
                            <Menu className="user-menu" label={firstName} menuItems={menuItems} />
                        </div>
                        {!userRole.orgLevelQuickChanger || user.orgLevelList.length === 0 ? null : (
                            <div className="quick-changer-nolink-container">
                                <div onClick={() => this._toggleOpenSelectCompany(true)}>
                                    <LocationIcon />
                                </div>
                                <NoLink
                                    className="quick-changer-nolink"
                                    onClick={() => this._toggleOpenSelectCompany(true)}
                                    label={selectedLocationName}
                                    title={user.companyName}
                                />
                            </div>
                        )}
                        <Navigation loadDetailPage={loadDetailPage} />
                    </div>
                </div>

                {!this.state.isSelectCompanyOpen ? null : (
                    <DialogBox
                        footerType={DialogBoxFooterType.NONE}
                        isOpen={this.state.isSelectCompanyOpen}
                        onClose={() => this._toggleOpenSelectCompany(false)}
                        title={formatMessage(messages.orgLevelQuickChanger)}
                    >
                        <SelectCompany
                            onAction={(history) => this.onRelogin(history)}
                            isQuickChanger={true}
                        />
                    </DialogBox>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        themeClassName: getCurrentTheme(state),
        firstName: getFirstName(state),
        isEnabled: getIsEnabled(state),
        systemProperties: getUserSystemProperties(state),
        userRole: getUser(state).role,
        user: getUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(loginActions.logoutUser()),
        onReloginUser: (history) => dispatch(loginActions.reloginUser(history)),
    };
};
export const Header = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header_));
