import { NonFieldFeatureAPI } from "@ai360/core";

export const START_ADDING_FEATURE = "non-field-feature-information/START_ADDING_FEATURE";
export const START_EDITING_FEATURE = "non-field-feature-information/START_EDITING_FEATURE";
export const STOP_EDITING_FEATURE = "non-field-feature-information/STOP_EDITING_FEATURE";
export const SET_ACTIVE_FEATURE = "non-field-feature-information/SET_ACTIVE_FEATURE";
export const SET_FEATURE_GEOMETRIES = "non-field-feature-information/SET_FEATURE_GEOMETRIES";
export const SET_FIELDS = "non-field-feature-information/SET_FIELDS";

export const startAddingFeature = (customer) => {
    const featureToEdit = new NonFieldFeatureAPI.NonFieldFeature();
    featureToEdit.customerName = customer.name;
    featureToEdit.customerId = customer.customerGuid;

    return {
        type: START_ADDING_FEATURE,
        featureToEdit,
        customer,
    };
};

export const startEditingFeature = (feature, customer) => {
    const featureToEdit = feature.clone();

    return {
        type: START_EDITING_FEATURE,
        originalFeature: feature,
        featureToEdit,
        customer,
    };
};

export const stopEditingFeature = (savedFeature) => ({
    type: STOP_EDITING_FEATURE,
    savedFeature,
});

export const setActiveFeature = (feature) => ({
    type: SET_ACTIVE_FEATURE,
    feature,
});

export const setFeatureGeometries = (geometries) => ({
    type: SET_FEATURE_GEOMETRIES,
    geometries,
});

export const setFields = (fields) => ({
    type: SET_FIELDS,
    fields,
});
