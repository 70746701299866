export function generateNonFixedColors() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return [r, g, b];
}

export function generateRandomBrightColors(): number[][] {
    const fixedColors: number[][] = [];
    fixedColors.push([255, 0, 0]);
    fixedColors.push([0, 255, 0]);
    fixedColors.push([0, 0, 255]);
    fixedColors.push([255, 255, 0]);
    fixedColors.push([0, 255, 255]);
    fixedColors.push([255, 0, 255]);
    fixedColors.push([128, 0, 0]);
    fixedColors.push([128, 128, 0]);
    fixedColors.push([0, 128, 0]);
    fixedColors.push([128, 0, 128]);
    fixedColors.push([0, 0, 128]);
    fixedColors.push([165, 42, 42]);
    fixedColors.push([255, 140, 0]);
    fixedColors.push([218, 165, 32]);
    fixedColors.push([154, 205, 50]);
    fixedColors.push([85, 107, 47]);
    fixedColors.push([46, 139, 87]);
    fixedColors.push([32, 178, 170]);
    fixedColors.push([0, 139, 139]);
    fixedColors.push([100, 149, 237]);
    fixedColors.push([0, 191, 255]);
    fixedColors.push([30, 144, 255]);
    fixedColors.push([138, 43, 226]);
    fixedColors.push([160, 82, 45]);
    fixedColors.push([210, 105, 30]);
    fixedColors.push([188, 143, 143]);
    fixedColors.push([255, 20, 147]);
    fixedColors.push([255, 105, 180]);
    fixedColors.push([205, 92, 92]);
    return fixedColors;
}
