import { defineMessages } from "react-intl";

export const messages = defineMessages({
    events: { id: "actionPanel.nav.events", defaultMessage: "Events" },
    farmName: {
        id: "actionPanel.general.farmName",
        defaultMessage: "Farm Name",
    },
    farmFieldName: {
        id: "actionPanel.general.farmFieldName",
        defaultMessage: "Farm-Field Name",
    },
    fields: { id: "actionPanel.nav.fields", defaultMessage: "Fields" },
    import: { id: "actionPanel.nav.import", defaultMessage: "Import" },
    layers: { id: "actionPanel.nav.layers", defaultMessage: "Layers" },
    recs: { id: "actionPanel.nav.recs", defaultMessage: "Recs" },
});
