import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { Checkbox } from "~/core";
import { StandardLayerContextMenu } from "../../context-menus/standard-layer-context-menu";
import { getRgba } from "../../../utils";

interface IGraphicsLayerProps {
    checked: boolean;
    intl: intlShape;
    onChecked: ({ evt, checked }) => void;
    onLayerPreferences: () => void;
    subLegendLayer: boolean;
    symbols: any[];
}

class GraphicsLayer_ extends Component<IGraphicsLayerProps> {
    static defaultProps = {
        checked: true,
        subLegendLayer: false,
    };

    _onClick(evt) {
        if (this.props.onChecked) {
            this.props.onChecked({
                ...evt,
                checked: !this.props.checked,
            });
        }
        evt.preventDefault();
    }

    render() {
        const { checked, onLayerPreferences, subLegendLayer, symbols } = this.props;

        let defaultLegendStyle = {};
        if (symbols.length > 0) {
            const s = symbols[0].symbol;
            defaultLegendStyle = {
                border: `${s.outline.width}px solid rgba(${getRgba(s.outline.color)})`,
                backgroundColor: `rgba(${getRgba(s.color)})`,
            };
        }

        const subLegendLayers = [];
        for (let i = 1; i < symbols.length; i++) {
            subLegendLayers.push(<GraphicsLayer key={i} subLegendLayer symbols={[symbols[i]]} />);
        }
        const subLegend = subLegendLayers.length ? (
            <div className="sub-legend-layers">{subLegendLayers}</div>
        ) : null;

        return (
            <div className="graphics-layer" onClick={(evt) => this._onClick(evt)}>
                {subLegendLayer ? null : (
                    <Checkbox onChange={(evt) => this._onClick(evt)} value={checked} />
                )}
                <div className="legend-icon" style={defaultLegendStyle} />
                <div className="legend-label">{symbols[0].label}</div>
                {subLegendLayer ? null : (
                    <StandardLayerContextMenu onLayerPreferences={() => onLayerPreferences()} />
                )}
                {subLegend}
            </div>
        );
    }
}

export const GraphicsLayer = injectIntl(GraphicsLayer_);
