import Immutable from "immutable";
import { accordionReducer } from "~/accordion";
import { recsModels } from "~/recs-events";

import * as actions from "./actions";
import * as selectors from "./selectors";

import { RX_FILE_IMPORT_KEY } from "~/action-panel/components/rec-module/components/rec-info/components/rec-equation-application/rx-file-import/selectors";
import { rxFileImportReducer } from "~/action-panel/components/rec-module/components/rec-info/components/rec-equation-application/rx-file-import/reducer";

export const recInfoInitialState = {
    activeNutrientGuid: null,
    batchRecDetailsForEdit: null,
    batchRecSummariesForEdit: null,
    isBatchEditRecsModalActive: false,
    isLoading: false,
    isEquationsLoading: false,
    isProcessingRecNutrient: false,
    generalAdjustments: false,
    permanentAdjustments: false,
    recNutrientsInProcessing: [],
    recSummary: null,
    recInfoPicklists: {},
    equationGroupList: [],
    filteredEquationGroupList: [],
    equationFilterLists: {
        cropListOptions: [],
        cropClassListOptions: [],
        cropPurposeListOptions: [],
        previousCropListOptions: [],
        nextCropListOptions: [],
        soilTextureListOptions: [],
        tillageMethodListOptions: [],
        applicationTimingListOptions: [],
        applicationMethodListOptions: [],
        productListOptions: [],
        incorporationDepthListOptions: [],
        buildFactorListOptions: [],
        targetpHListOptions: [],
        recOptionListOptions: [],
        normalizedYieldLayerOptions: [],
        managementAreaLayerOptions: [],
    },
    equationFilterRequired: {
        cropRequired: false,
        cropClassRequired: false,
        cropPurposeRequired: false,
        previousCropRequired: false,
        nextCropRequired: false,
        soilTextureRequired: false,
        tillageMethodRequired: false,
        applicationTimingRequired: false,
        applicationMethodRequired: false,
        productRequired: false,
        incorporationDepthRequired: false,
        buildFactorRequired: false,
        targetpHRequired: false,
        recOptionRequired: false,
    },
    managementAreaLayerOptionsMap: Immutable.Map(),
    samplingDepthList: null,
    [selectors.ACCORDION_KEY]: accordionReducer(undefined, { type: "INIT" } as any),
    [selectors.DROPDOWNS_KEY]: {
        cropList: [],
        cropClassList: [],
        brandList: [],
        varietyHybridList: [],
    },
    [RX_FILE_IMPORT_KEY]: rxFileImportReducer(undefined, { type: "INIT" }),
};

export const recInfoReducer = (state = recInfoInitialState, action) => {
    switch (action.type) {
        case actions.FETCHED_DROPDOWN_DATA: {
            const { recSummary } = state;
            const isManual = recSummary.recType === recsModels.REC_TYPE_NAME_MANUAL_APPLICATION;
            const isLoading = isManual ? false : state.isLoading;
            return Object.freeze({
                ...state,
                [selectors.DROPDOWNS_KEY]: {
                    ...state[selectors.DROPDOWNS_KEY],
                    ...action.payload,
                },
                isLoading,
            });
        }
        case actions.SET_ACTIVE_NUTRIENT: {
            const { nutrientGuid } = action.payload;
            return Object.freeze({
                ...state,
                activeNutrientGuid: nutrientGuid,
            });
        }
        case actions.SET_BATCH_REC_DETAILS_FOR_EDIT: {
            const { batchRecDetailsForEdit } = action.payload;
            return Object.freeze({
                ...state,
                batchRecDetailsForEdit,
            });
        }
        case actions.SET_BATCH_REC_SUMMARIES_FOR_EDIT: {
            const { batchRecSummariesForEdit } = action.payload;
            return Object.freeze({
                ...state,
                batchRecSummariesForEdit,
            });
        }
        case actions.SET_REC_SUMMARY: {
            const { recSummary } = action.payload;
            return Object.freeze({
                ...state,
                recSummary,
            });
        }
        case actions.SET_REC_DETAILS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case actions.SET_EQUATIONS_LOADING: {
            const { isEquationsLoading } = action.payload;
            return Object.freeze({
                ...state,
                isEquationsLoading,
            });
        }
        case actions.SET_IS_PROCESSING_REC_NUTRIENT: {
            const { isProcessingRecNutrient } = action.payload;
            return Object.freeze({
                ...state,
                isProcessingRecNutrient,
            });
        }
        case actions.SET_REC_INFO_PICKLISTS: {
            const { recInfoPicklists } = action.payload;
            return Object.freeze({
                ...state,
                recInfoPicklists,
            });
        }
        case actions.SET_EQUATION_GROUP_LIST: {
            const { equationGroupList } = action.payload;
            return Object.freeze({
                ...state,
                equationGroupList,
            });
        }
        case actions.SET_FILTERED_EQUATION_GROUP_LIST: {
            const { filteredEquationGroupList } = action.payload;
            return Object.freeze({
                ...state,
                filteredEquationGroupList,
            });
        }
        case actions.SET_EQUATION_FILTER_LISTS: {
            const { equationFilterLists } = action.payload;
            return Object.freeze({
                ...state,
                equationFilterLists,
            });
        }
        case actions.SET_EQUATION_FILTER_REQUIRED: {
            const { equationFilterRequired } = action.payload;
            return Object.freeze({
                ...state,
                equationFilterRequired,
            });
        }
        case actions.SET_MANAGEMENT_AREA_LAYER_OPTIONS_MAP: {
            const { managementAreaLayerOptionsMap } = action.payload;
            return Object.freeze({
                ...state,
                managementAreaLayerOptionsMap,
            });
        }
        case actions.SET_PERMANENT_ADJUSTMENTS: {
            const { permanentAdjustments } = action.payload;
            return Object.freeze({
                ...state,
                permanentAdjustments,
            });
        }
        case actions.SET_GENERAL_ADJUSTMENTS: {
            const { generalAdjustments } = action.payload;
            return Object.freeze({
                ...state,
                generalAdjustments,
            });
        }
        case actions.SET_REC_NUTRIENTS_IN_PROCESSING: {
            const { recNutrientsInProcessing } = action.payload;
            return Object.freeze({
                ...state,
                recNutrientsInProcessing,
            });
        }
        case actions.SET_SAMPLING_DEPTH_LIST: {
            const { samplingDepthList } = action.payload;
            return Object.freeze({
                ...state,
                samplingDepthList,
            });
        }
        case actions.TOGGLE_BATCH_EDIT_RECS_MODAL: {
            const { isBatchEditRecsModalActive } = action.payload;
            return Object.freeze({
                ...state,
                isBatchEditRecsModalActive,
            });
        }
        default: {
            const rxFileImportState = rxFileImportReducer(state[RX_FILE_IMPORT_KEY], action);
            if (rxFileImportState !== state[RX_FILE_IMPORT_KEY]) {
                return Object.freeze({
                    ...state,
                    [RX_FILE_IMPORT_KEY]: rxFileImportState,
                });
            }
            const accordionState = accordionReducer(state[selectors.ACCORDION_KEY], action);
            if (accordionState === state[selectors.ACCORDION_KEY]) {
                return state;
            }
            return Object.freeze({
                ...state,
                [selectors.ACCORDION_KEY]: accordionState,
            });
        }
    }
};
