import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";

import { getLoginBackgroundUrl } from "~/login";

import { Loader } from "~/core";

import "./theme-root.css";
import { getIsLoading } from "../selectors";

export interface IThemeRootPresentationProps {
    className?: string;
    children?: JSX.Element[] | JSX.Element;
    hideBackground?: boolean;
    intl: intlShape;
    isLoading?: boolean;
    loginBackgroundImage?: string;
}

export class ThemeRootPresentation extends Component<IThemeRootPresentationProps> {
    static defaultProps = {
        hideBackground: false,
    };

    render(): JSX.Element {
        const { className, children, loginBackgroundImage, hideBackground } = this.props;
        const styles =
            hideBackground || loginBackgroundImage == null
                ? null
                : { backgroundImage: `url(${loginBackgroundImage})` };
        return (
            <div className={classNames("theme-root", className)} style={styles}>
                {!this.props.isLoading ? null : <Loader />}
                {children}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: getIsLoading(state),
        loginBackgroundImage: getLoginBackgroundUrl(state),
    };
};
export const ThemeRoot = connect<
    Partial<IThemeRootPresentationProps>,
    Partial<IThemeRootPresentationProps>,
    Partial<IThemeRootPresentationProps>
>(
    mapStateToProps,
    null
)(injectIntl(ThemeRootPresentation));
