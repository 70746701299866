import React, { Component } from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions";
import { ImportWizardProgressBar } from "./import-wizard-progress-bar/import-wizard-progress-bar";
import { Button, DialogBox, DialogBoxFooterType } from "~/core";
import { defineMessages, injectIntl, intlShape } from "react-intl";

import "./import-wizard-header.css";

const messages = defineMessages({
    importWizardDownloadPointDataText: {
        id: "importWizard.importWizardDownloadPointDataText",
        defaultMessage: "Display Point Data",
    },
    importWizardShowDataRubberbandText: {
        id: "importWizard.importWizardShowDataRubberbandText",
        defaultMessage: "Display Rubberband Area",
    },
    pointCountWarningLine1: {
        id: "importWizard.pointCountWarningLine1",
        defaultMessage:
            "This process is very bandwidth and RAM intensive and can " +
            "crash your browser if you don't have enough resources.",
    },
    pointCountWarningLine2: {
        id: "importWizard.pointCountWarningLine2",
        defaultMessage: "Are you sure you want to continue?",
    },
    pointCountWarningPointCount: {
        id: "importWizard.pointCountWarningPointCount",
        defaultMessage: "Point Count: {pointCount, number}",
    },
    pointCountWarningSize: {
        id: "importWizard.pointCountWarningSize",
        defaultMessage: "Estimated Size: {estimatedSize, number} {units}",
    },
    pointCountWarningTitle: {
        id: "importWizard.pointCountWarningTitle",
        defaultMessage: "Warning",
    },
});

const BYTES_PER_IMPORT_FILE = 200;
const BYTES_PER_FEATURE = 110;
const MAX_MB_NO_WARNING = 10;
const MAX_POINTS_NO_WARNING = 100000;

class ImportWizardHeader_ extends Component {
    static propTypes = {
        currentStep: PropTypes.number.isRequired,
        importFieldStripData: PropTypes.array.isRequired,
        importType: PropTypes.oneOf(
            Object.keys(actions.ImportTypes).map((k) => actions.ImportTypes[k])
        ).isRequired,
        isFieldMatchingComplete: PropTypes.bool.isRequired,
        intl: intlShape.isRequired,
        setShowImportPoints: PropTypes.func.isRequired,
        showImportPoints: PropTypes.bool.isRequired,
        stepList: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            havePoints: false,
            showPointCountWarningDialog: false,
        };
    }

    _getPointDataSizeInfo() {
        const { importFieldStripData } = this.props;

        const pointCount = importFieldStripData.reduce(
            (pointCount, fieldStrip) => pointCount + fieldStrip.pointCount,
            0
        );

        const importFileGuidSet = importFieldStripData.reduce((importFileGuidSet, fieldStrip) => {
            fieldStrip.importFileGuidList.forEach((guid) => importFileGuidSet.add(guid));
            return importFileGuidSet;
        }, new Set());

        const estimatedBytes =
            importFileGuidSet.size * BYTES_PER_IMPORT_FILE + pointCount * BYTES_PER_FEATURE;

        return [pointCount, Math.round(estimatedBytes / 1024 / 1024)];
    }

    _renderPointCountWarningDialog() {
        if (!this.state.showPointCountWarningDialog) {
            return;
        }
        const { formatMessage } = this.props.intl;

        const closePointCountWarningDialog = (showPoints) => {
            this.setState(
                {
                    havePoints: showPoints,
                    showPointCountWarningDialog: false,
                },
                () => {
                    if (showPoints) {
                        this.props.setShowImportPoints(true);
                    }
                }
            );
        };

        const [pointCount, estimatedMB] = this._getPointDataSizeInfo();
        const estimatedSize = estimatedMB > 1024 ? estimatedMB / 1024 : estimatedMB;
        const units = estimatedMB > 1024 ? "GB" : "MB";
        return (
            <DialogBox
                isOpen
                unrestricted
                footerType={DialogBoxFooterType.YES_NO}
                onAction={() => closePointCountWarningDialog(true)}
                onClose={() => closePointCountWarningDialog(false)}
                title={formatMessage(messages.pointCountWarningTitle)}
            >
                <div>{formatMessage(messages.pointCountWarningLine1)}</div>
                <div className="size-info">
                    <div>
                        {formatMessage(messages.pointCountWarningPointCount, {
                            pointCount,
                        })}
                    </div>
                    <div>
                        {formatMessage(messages.pointCountWarningSize, {
                            estimatedSize,
                            units,
                        })}
                    </div>
                </div>
                <div>{formatMessage(messages.pointCountWarningLine2)}</div>
            </DialogBox>
        );
    }

    _toggleShowPoints = () => {
        const [pointCount, estimatedMB] = this._getPointDataSizeInfo();
        if (
            !this.state.havePoints &&
            (pointCount > MAX_POINTS_NO_WARNING || estimatedMB > MAX_MB_NO_WARNING)
        ) {
            this.setState({ showPointCountWarningDialog: true });
            return;
        }
        this.props.setShowImportPoints(!this.props.showImportPoints);
    };

    render() {
        const { currentStep, importType, isFieldMatchingComplete, showImportPoints, stepList } =
            this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className="import-wizard-header">
                {this._renderPointCountWarningDialog()}
                <ImportWizardProgressBar currentStep={currentStep} stepList={stepList} />
                {importType === actions.ImportTypes.FIELD_BOUNDARY ||
                importType === actions.ImportTypes.SAMPLING_SOIL ||
                importType === actions.ImportTypes.SAMPLING_TISSUE ||
                importType === actions.ImportTypes.IMAGERY ? null : (
                    <div className="import-wizard-header-action">
                        <Button
                            className="wide-button"
                            disabled={!isFieldMatchingComplete}
                            onClick={this._toggleShowPoints}
                            value={formatMessage(
                                showImportPoints
                                    ? messages.importWizardShowDataRubberbandText
                                    : messages.importWizardDownloadPointDataText
                            )}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export const ImportWizardHeader = injectIntl(ImportWizardHeader_);
