import React, { Component } from "react";
import PropTypes from "prop-types";
import { messages } from "../../../i18n-messages";
import {
    Bucket,
    BucketHeader,
    DateInput,
    NumericInput,
    Section,
    SelectInput,
    SubSection,
} from "~/core";
import { model } from "../../data";
import { adminData, GUID, NAME } from "~/admin/data";
import * as unitService from "~/core/units/unit-names";
import { mapToPicklistValue, prepareSelectableOptions, formatDate } from "~/admin/utils";
const dateFormat = "M/D/YYYY hh:mm:ss A";

export default class PurchaseHistory extends Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        onSelectInputChange: PropTypes.func,
        onTextChange: PropTypes.func,
        onNumberChange: PropTypes.func,
        equipmentSetup: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            [unitService.UNIT_AREA]: prepareSelectableOptions(props[unitService.UNIT_AREA], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_TIME]: prepareSelectableOptions(props[unitService.UNIT_TIME], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_VOLUME]: prepareSelectableOptions(props[unitService.UNIT_VOLUME], {
                guid: GUID,
                label: NAME,
            }),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initializeUnitArea(nextProps);
        this.initializeUnitTime(nextProps);
        this.initializeUnitVolume(nextProps);
    }

    initializeUnitArea = (nextProps) => {
        if (nextProps[unitService.UNIT_AREA] !== this.props[unitService.UNIT_AREA]) {
            this.setState({
                [unitService.UNIT_AREA]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_AREA],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    initializeUnitTime = (nextProps) => {
        if (nextProps[unitService.UNIT_TIME] !== this.props[unitService.UNIT_TIME]) {
            this.setState({
                [unitService.UNIT_TIME]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_TIME],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    initializeUnitVolume = (nextProps) => {
        if (nextProps[unitService.UNIT_VOLUME] !== this.props[unitService.UNIT_VOLUME]) {
            this.setState({
                [unitService.UNIT_VOLUME]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_VOLUME],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    render() {
        const { formatMessage, equipmentSetup, onSelectInputChange, onTextChange, onNumberChange } =
            this.props;
        return (
            <Bucket
                className="form-section-bucket purchase-history"
                showSymbol={true}
                isCollapsible={true}
                isExpanded={false}
            >
                <BucketHeader>{formatMessage(messages.purchaseHistory)}</BucketHeader>
                <Section>
                    <SubSection>
                        <DateInput
                            onChange={(value) => {
                                onTextChange(model.PROPS_PUR_DATE, formatDate(value, dateFormat));
                            }}
                            openDirection="left"
                            timeFormat={this.state.includeTime ? "hh:mm" : false}
                            placeholderText={formatMessage(messages.purchaseDate)}
                            value={equipmentSetup[model.PROPS_PUR_DATE] || null}
                            defaultValue={null}
                        />
                        <NumericInput
                            containerClassNames={["purchase-history-date-input"]}
                            scale={2}
                            precision={9}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_PUR_PRICE, value);
                            }}
                            value={equipmentSetup[model.PROPS_PUR_PRICE]}
                            placeholderText={formatMessage(messages.purchasePrice)}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_PUR_DATE_USAGE_TOTALAREA, value);
                            }}
                            value={equipmentSetup[model.PROPS_PUR_DATE_USAGE_TOTALAREA]}
                            placeholderText={formatMessage(messages.usageTotalArea)}
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_AREA]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_AREA],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_PUR_DATE_USAGE_TOTAL_AREA_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_PUR_DATE_USAGE_TOTAL_AREA_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                            placeholderText={formatMessage(messages.usageTotalAreaUnit)}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_PUR_DATE_USAGE_TOTAL_USAGE, value);
                            }}
                            value={equipmentSetup[model.PROPS_PUR_DATE_USAGE_TOTAL_USAGE]}
                            placeholderText={formatMessage(messages.usageTotalHours)}
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_TIME]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_TIME],
                                selectedGuid:
                                    equipmentSetup[
                                        model.PROPS_PUR_DATE_USAGE_TOTAL_USAGE_UNIT_GUID
                                    ],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_PUR_DATE_USAGE_TOTAL_USAGE_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                            placeholderText={formatMessage(messages.usageTotalHoursUnit)}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_PUR_DATE_USAGE_TOTAL_VOLUME, value);
                            }}
                            value={equipmentSetup[model.PROPS_PUR_DATE_USAGE_TOTAL_VOLUME]}
                            placeholderText={formatMessage(messages.usageTotalVolume)}
                        />
                        <SelectInput
                            options={this.state[unitService.UNIT_VOLUME]}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_PUR_DATE_USAGE_TOTAL_VOLUME_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_VOLUME],
                                selectedGuid:
                                    equipmentSetup[
                                        model.PROPS_PUR_DATE_USAGE_TOTAL_VOLUME_UNIT_GUID
                                    ],
                            })}
                            placeholderText={formatMessage(messages.usageTotalVolumeUnit)}
                        />
                    </SubSection>
                    <SubSection>
                        <DateInput
                            openDirection="left"
                            onChange={(value) => {
                                onTextChange(
                                    model.PROPS_RETIRED_DATE,
                                    formatDate(value, dateFormat)
                                );
                            }}
                            timeFormat={this.state.includeTime ? "hh:mm" : false}
                            placeholderText={formatMessage(messages.retiredDate)}
                            value={equipmentSetup[model.PROPS_RETIRED_DATE] || null}
                            defaultValue={null}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            containerClassNames={["purchase-history-date-input"]}
                            onChange={(value) => {
                                onNumberChange(
                                    model.PROPS_ACCUM_DEPRECIATION_AS_OF_YEAR_END,
                                    value
                                );
                            }}
                            value={equipmentSetup[model.PROPS_ACCUM_DEPRECIATION_AS_OF_YEAR_END]}
                            placeholderText={formatMessage(
                                messages.accumulatedDepreciationAsOfYearEnd
                            )}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            value={equipmentSetup[model.PROPS_BOOK_VALUE_AS_OF_YEAR_END]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_BOOK_VALUE_AS_OF_YEAR_END, value);
                            }}
                            placeholderText={formatMessage(messages.bookValueAsOfYearEndLabel)}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            value={equipmentSetup[model.PROPS_CURRENT_YEAR_DEPRECIATION]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_CURRENT_YEAR_DEPRECIATION, value);
                            }}
                            placeholderText={formatMessage(messages.currentYearDepreciation)}
                        />
                    </SubSection>
                </Section>
            </Bucket>
        );
    }
}
