import { zip } from "../utils/zip";

import * as geometryJsonUtils from "@arcgis/core/geometry/support/jsonUtils";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import { geojsonToArcGIS } from "@terraformer/arcgis";
import { BBox } from "geojson";

const shpRegEx = /\.shp$/;
const zipRegEx = /\.zip$/;

const _isValidShapefile = (files) => {
    let dbf = false,
        shp = false,
        shx = false,
        unknown = false,
        diffName = false;
    let rootName = null;
    for (const file of files) {
        const match = file.name.toLowerCase().match(/(.*)\.(dbf|prj|shp|shx)$/);
        if (match != null) {
            if (rootName == null) {
                rootName = match[1];
            } else if (rootName !== match[1]) {
                diffName = true;
            }
            switch (match[2]) {
                case "dbf":
                    dbf = true;
                    break;
                case "prj":
                    break;
                case "shp":
                    shp = true;
                    break;
                case "shx":
                    shx = true;
                    break;
                default:
                    unknown = true;
            }
        }
    }
    return !diffName && !unknown && shp && shx && dbf;
};

const _getReadZipPromise = (zipFile) => {
    return new Promise((resolve, reject) => {
        zip.createReader(
            new zip.BlobReader(zipFile),
            (reader) =>
                reader.getEntries((entries) => {
                    resolve(
                        entries.map((zipEntry) => ({
                            name: zipEntry.filename,
                        }))
                    );
                }),
            (error) => reject(error)
        );
    });
};

/**
 * Checks to see if the list of files contains the necessary files for a shapefile
 * @param {file[]} files array of files (ideally from an upload input)
 */
export const checkIfShapefileValid = (files: FileList): Promise<unknown> => {
    return new Promise((resolve, reject) => {
        if (files.length === 1 && files[0].name.toLowerCase().match(zipRegEx) != null) {
            _getReadZipPromise(files[0])
                .then((zipFiles) => resolve(_isValidShapefile(zipFiles)))
                .catch((err) => reject(err));
        } else if (files.length === 3 || files.length === 4) {
            resolve(_isValidShapefile(files));
        } else {
            resolve(false);
        }
    });
};

/**
 * Returns either the zip file name or the .shp file name
 * @param {file[]} files array of files (ideally from an upload input)
 */
export const getPrimaryFile = (files: FileList): Record<string, any> => {
    if (files.length === 1 && files[0].name.toLowerCase().match(zipRegEx) != null) {
        return files[0];
    } else if (files.length > 0) {
        for (const file of files) {
            if (file.name.toLowerCase().match(shpRegEx) != null) {
                return file;
            }
        }
    }
    return null;
};

export const convertToEsriGeometry = (
    feature: GeoJSON.Feature
): {
    geometry: __esri.Geometry;
    type: "Feature";
    id?: string | number;
    properties: {
        [name: string]: any;
    };
    bbox?: BBox;
} => {
    const geomJson = geojsonToArcGIS(feature.geometry);
    const geometry = webMercatorUtils.geographicToWebMercator(geometryJsonUtils.fromJSON(geomJson));
    return { ...feature, geometry };
};
