import { call, fork, put, select, takeEvery } from "redux-saga/effects";

import { Request, apiUrl } from "@ai360/core";

import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid } from "~/login/selectors";

import { fetchPicklistData, fetchedPicklistData } from "./actions";
import { getPicklistState } from "./selectors";

export const FETCH_PICKLIST_URL = apiUrl("Picklist/GetPicklistValuesById");

export function* fetchPicklistRequest(action) {
    const { picklistKey, url = FETCH_PICKLIST_URL, model, forcedRequest } = action.payload;
    const Model = model || picklistKey;
    const UserGuid = yield select(getTheUserGuid);

    const picklistState = yield select(getPicklistState);
    if (!forcedRequest && picklistKey in picklistState) {
        yield put(fetchedPicklistData());
        return;
    }

    try {
        const response = yield call(Request.post, url, { UserGuid, Model });
        yield put(fetchedPicklistData({ [picklistKey]: response }));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
}

export function* fetchPicklist(action) {
    const { payload } = action;
    if (payload.model) {
        yield fetchPicklistRequest(action);
        return;
    }

    for (let key of Object.keys(payload)) {
        const picklistKey = payload[key];
        yield fork(fetchPicklistRequest, fetchPicklistData({ picklistKey }));
    }
}

export function* picklistSaga() {
    yield takeEvery(fetchPicklistData, fetchPicklist);
}
