import { defineMessages } from "react-intl";

export const messages = defineMessages({
    addEventTooltip: {
        id: "eventModule.eventInfo.addEventTooltip",
        defaultMessage: "Add Event",
    },
    batchSummaryLine1: {
        id: "eventModule.eventInfo.batchSummaryLine1",
        defaultMessage: "Create Events",
    },
    batchSummaryLine2: {
        id: "eventModule.eventInfo.batchSummaryLine2",
        defaultMessage: "Batching {batchSize} Events",
    },
    batchCopySummaryLine2: {
        id: "eventModule.eventInfo.batchCopySummaryLine2",
        defaultMessage: "Copying {batchSize} Events",
    },
    application: {
        id: "eventModule.eventInfo.application",
        defaultMessage: "Application",
    },
    depthColLabel: {
        id: "eventModule.eventInfo.depthColLabel",
        defaultMessage: "Depth",
    },
    displayLayerPlaceholderTxt: {
        id: "eventModule.eventInfo.displayLayerPlaceholderTxt",
        defaultMessage: "Display Layer",
    },
    errorGettingAutoTracePreferences: {
        id: "eventModule.eventInfo.errorGettingAutoTracePreferences",
        defaultMessage: "Error retrieving auto trace preferences.",
    },
    errorSavingAutoTracePreferences: {
        id: "eventModule.eventInfo.errorSavingAutoTracePreferences",
        defaultMessage: "Error saving auto trace preferences.",
    },
    harvest: { id: "eventModule.eventInfo.harvest", defaultMessage: "Harvest" },
    idColLabel: {
        id: "eventModule.eventInfo.idColLabel",
        defaultMessage: "ID",
    },
    ratingTxt: {
        id: "eventModule.eventInfo.ratingTxt",
        defaultMessage: "Rating",
    },
    unimplementedFormLabel: {
        id: "eventModule.eventInfo.unimplementedFormLabel",
        defaultMessage: "Unknown",
    },
    zoneInterpolationCbLabel: {
        id: "eventModule.eventInfo.zoneInterpolationCbLabel",
        defaultMessage: "Zone Interpolation",
    },
    zoneSamplingCbLabel: {
        id: "eventModule.eventInfo.zoneSamplingCbLabel",
        defaultMessage: "Zone Sampling",
    },
});
