import * as immutableUtils from "~/utils/immutable";

import { mapNoTryOnlyGet } from "~/utils/strict-null";

import * as actions from "./actions";
import * as models from "./model";
import { ActionType } from "typesafe-actions";

const newInitialState = (): Readonly<models.IImportFileState> =>
    Object.freeze({
        filter: {
            includeOtherUsers: false,
            userFilterStr: "",
            userFilterObj: null,
        },
        fetchingTypes: false,
        importTypes: new Map<string, models.IImportType>(),
        importTemplates: new Map<string, models.ImportTemplate>(),
        importFiles: new Map<string, models.ImportFileInfo>(),
        isTelematicsUser: false,
        telematicsCounts: {
            completeCount: 0,
            pendingCount: 0,
            errorCount: 0,
        },
        searchValue: "",
    });

const fileImportReducer_ = (
    state: models.IImportFileState,
    action: any
): models.IImportFileState => {
    if (state == null) {
        return newInitialState();
    }

    switch (action.type) {
        case actions.DELETE_IMPORT_FILE_INFO_LIST: {
            const { importFileInfoGuidList } = action.payload;
            const importFiles = new Map(state.importFiles);
            for (const ifoGuid of importFileInfoGuidList) {
                importFiles.delete(ifoGuid);
            }
            const newState = {
                ...state,
                importFiles,
            };

            // check if we removed the only file for a template
            const allTemplateGuids = new Set(
                [...importFiles.values()].map((ifo) => ifo.templateGuid)
            );
            const templatesWithoutFilesGuidList = [...state.importTemplates.values()]
                .filter(
                    (template) =>
                        template.haveFetched && !allTemplateGuids.has(template.templateGuid)
                )
                .map((template) => template.templateGuid);
            if (templatesWithoutFilesGuidList.length > 0) {
                const importTemplates = new Map(state.importTemplates);
                for (const templateGuid of templatesWithoutFilesGuidList) {
                    importTemplates.delete(templateGuid);
                }
                newState.importTemplates = importTemplates;

                // do the same thing for the type
                const allTypeGuids = new Set(
                    [...importTemplates.values()].map((template) => template.importTypeGuid)
                );
                const typesWithoutTemplatesGuidList = [...state.importTypes.values()]
                    .map((impType) => impType.guid)
                    .filter((impTypeGuid) => !allTypeGuids.has(impTypeGuid));
                if (typesWithoutTemplatesGuidList.length > 0) {
                    const importTypes = new Map(state.importTypes);
                    for (const impTypeGuid of typesWithoutTemplatesGuidList) {
                        importTypes.delete(impTypeGuid);
                    }
                    newState.importTypes = importTypes;
                }
            }
            return Object.freeze(newState);
        }
        case actions.IMPORT_FILE_INFO_FETCH_SUCCEEDED: {
            const { templateGuid, importFileInfoList } = action.payload;
            console.assert(importFileInfoList.every((ifo) => ifo.templateGuid === templateGuid));
            const importFiles = new Map(state.importFiles);
            for (const importFileInfo of importFileInfoList) {
                importFiles.set(importFileInfo.guid, importFileInfo);
            }
            const importTemplates = new Map(state.importTemplates);
            const template = Object.freeze(
                mapNoTryOnlyGet(importTemplates, templateGuid).clone({
                    haveFetched: true,
                })
            );
            importTemplates.set(templateGuid, template);
            return Object.freeze({
                ...state,
                importFiles,
                importTemplates,
            });
        }
        case actions.IMPORT_TYPES_TMPLTS_FETCH_SUCCEEDED: {
            const importTypes = new Map();
            const importTemplates = new Map();
            for (const ft of action.payload.fileImportTypes) {
                const { name, guid, isExpanded, templateList } = ft;
                importTypes.set(guid, { name, guid, isExpanded });
                for (const template of templateList) {
                    importTemplates.set(template.templateGuid, template);
                }
            }
            return Object.freeze({
                ...state,
                importTypes,
                importTemplates,
                importFiles: new Map(),
            });
        }
        case actions.IMPORT_TYPES_TMPLTS_FILTER_COMPLETE: {
            const importTypes = new Map();
            const importTemplates = new Map();
            for (const { name, guid, isExpanded, templateList } of action.payload.fileImportTypes) {
                importTypes.set(guid, { name, guid, isExpanded });
                for (const template of templateList) {
                    console.assert(template instanceof models.ImportTemplate);
                    importTemplates.set(template.templateGuid, template);
                }
            }
            const importFiles = new Map(
                [...state.importFiles.entries()].filter(([, importFileInfo]) =>
                    importTemplates.has(importFileInfo.templateGuid)
                )
            );
            return Object.freeze({
                ...state,
                importTypes,
                importTemplates,
                importFiles,
            });
        }
        case actions.REFRESH_IMPORT_TYPE_TEMPLATE_INFO_COMPLETE: {
            const importTypes = new Map();
            const importTemplates = new Map(state.importTemplates);
            for (const { name, guid, isExpanded, templateList } of action.payload.fileImportTypes) {
                importTypes.set(guid, { name, guid, isExpanded });
                for (const template of templateList) {
                    if (!importTemplates.has(template.templateGuid)) {
                        console.assert(template instanceof models.ImportTemplate);
                        importTemplates.set(template.templateGuid, template);
                    }
                }
            }
            const importFiles = new Map(
                [...state.importFiles.entries()].filter(([, importFileInfo]) =>
                    importTemplates.has(importFileInfo.templateGuid)
                )
            );
            return Object.freeze({
                ...state,
                importTypes,
                importTemplates,
                importFiles,
            });
        }
        case actions.REMOVE_ALL_DATA: {
            return Object.freeze({
                ...state,
                importTypes: new Map(),
                importTemplates: new Map(),
                importFiles: new Map(),
            });
        }
        case actions.SET_FETCH_ALL_USERS_FILTER: {
            const { userFilterStr, userFilterObj } = action.payload;
            return Object.freeze({
                ...state,
                filter: {
                    ...state.filter,
                    userFilterStr,
                    userFilterObj,
                },
            });
        }
        case actions.SET_FETCHING_TYPES_STATUS: {
            const { fetchingTypes } = action.payload;
            return Object.freeze({
                ...state,
                fetchingTypes,
            });
        }
        case actions.SET_FILTER_INCLUDE_OTHER_USERS: {
            return Object.freeze({
                ...state,
                filter: {
                    ...state.filter,
                    includeOtherUsers: action.payload.includeOtherUsers,
                },
            });
        }
        case actions.SET_INFO_FETCHING_STATUS: {
            const { templateGuids, isFetching } = action.payload;
            const importTemplates = new Map(state.importTemplates);
            templateGuids.forEach((guid) => {
                if (importTemplates.has(guid)) {
                    const template = Object.freeze(
                        mapNoTryOnlyGet(importTemplates, guid).clone({
                            isFetchingFiles: isFetching,
                        })
                    );
                    importTemplates.set(guid, template);
                }
            });
            return Object.freeze({
                ...state,
                importTemplates,
            });
        }
        case actions.SET_IS_TELEMATICS_PROCESSING: {
            return Object.freeze({
                ...state,
                ...action.payload,
            });
        }
        case actions.SET_IS_TELEMATICS_USER: {
            const { isTelematicsUser } = action.payload;
            return Object.freeze({
                ...state,
                isTelematicsUser,
            });
        }
        case actions.SET_TEMPLATE_EXPANDED: {
            const { templateGuid, expanded } = action.payload;
            const template = mapNoTryOnlyGet(state.importTemplates, templateGuid).clone({
                isExpanded: expanded,
            });
            const importTemplates = immutableUtils.noMutateMapSet(
                state.importTemplates,
                templateGuid,
                template
            );
            return Object.freeze({
                ...state,
                importTemplates,
            });
        }
        case actions.SET_TYPE_EXPANDED: {
            const { typeGuid, expanded } = action.payload;
            const importType = {
                ...state.importTypes.get(typeGuid),
                isExpanded: expanded,
            };
            const importTypes = immutableUtils.noMutateMapSet(
                state.importTypes,
                typeGuid,
                importType
            );
            return Object.freeze({
                ...state,
                importTypes,
            });
        }
        case actions.UPDATE_IFO_STATUS_CODE: {
            const { ifoGuid, statusCode } = action.payload;
            const importFiles = new Map(state.importFiles);
            if (!importFiles.has(ifoGuid)) {
                return state;
            }
            const importFileInfo = mapNoTryOnlyGet(importFiles, ifoGuid).clone({
                statusCode,
            });
            importFiles.set(ifoGuid, importFileInfo);
            return Object.freeze({
                ...state,
                importFiles,
            });
        }
        case actions.UPDATE_IFO_STATUS_PERCENT_COMPLETE: {
            const { ifoGuid, statusPercentComplete } = action.payload;
            const importFiles = new Map(state.importFiles);
            if (!importFiles.has(ifoGuid)) {
                return state;
            }
            const importFileInfo = mapNoTryOnlyGet(importFiles, ifoGuid).clone({
                statusCode: models.UPLOADING_STATUS_CODE,
                statusPercentComplete,
            });
            importFiles.set(ifoGuid, importFileInfo);
            return Object.freeze({
                ...state,
                importFiles,
            });
        }
        case actions.UPDATE_IMPORT_FILE_INFOS: {
            const { importFileInfos } = action.payload;
            const importFiles = new Map(state.importFiles);
            for (const importFileInfo of importFileInfos) {
                importFiles.set(importFileInfo.guid, importFileInfo);
            }
            return Object.freeze({
                ...state,
                importFiles,
            });
        }
        case actions.UPDATE_SEARCH: {
            const { searchValue } = action.payload;
            return Object.freeze({
                ...state,
                searchValue: searchValue,
            });
        }
        case actions.UPDATE_TELEMATICS_COUNTS: {
            return Object.freeze({
                ...state,
                ...action.payload,
            });
        }
        default:
            return state;
    }
};

export const fileImportReducer = (
    state: models.IImportFileState,
    action: ActionType<typeof actions>
): any => {
    if (action && action.type === actions.CUSTOM_BATCH) {
        return action.payload.actionList.reduce(fileImportReducer_, state);
    }
    return fileImportReducer_(state, action);
};
