import { createAction } from "redux-act";

const BASE_STR = "BATCH_ZAPPER";

// Attributes
export const FETCH_ATTRIBUTES = `${BASE_STR}_FETCH_ATTRIBUTES_DATA`;
export const FETCH_ATTRIBUTES_SUCCESS = `${BASE_STR}_FETCH_ATTRIBUTES_SUCCESS`;
export const FETCH_ATTRIBUTES_FAILED = `${BASE_STR}_FETCH_ATTRIBUTES_FAILED`;

// Tree Attributes
export const FETCH_TREE_ATTRIBUTES = `${BASE_STR}_FETCH_TREE_ATTRIBUTES_DATA`;
export const FETCH_TREE_ATTRIBUTES_SUCCESS = `${BASE_STR}_FETCH_TREE_ATTRIBUTES_SUCCESS`;
export const FETCH_TREE_ATTRIBUTES_FAILED = `${BASE_STR}_FETCH_TREE_ATTRIBUTES_FAILED`;

// Save Assignments
export const SAVE_ASSIGNMENT = `${BASE_STR}_SAVE_ASSIGNMENT_DATA`;
export const SAVE_ASSIGNMENT_SUCCESS = `${BASE_STR}_SAVE_ASSIGNMENT_SUCCESS`;
export const SAVE_ASSIGNMENT_FAILED = `${BASE_STR}_SAVE_ASSIGNMENT_FAILED_FAILED`;

//Save Assignments Attribute
export const SAVE_ASSIGNMENT_ATTRIBUTE = `${BASE_STR}_SAVE_ASSIGNMENT_ATTRIBUTE_DATA`;
export const SAVE_ASSIGNMENT_ATTRIBUTE_SUCCESS = `${BASE_STR}_SAVE_ASSIGNMENT_ATTRIBUTE_SUCCESS`;
export const SAVE_ASSIGNMENT_ATTRIBUTE_FAILED = `${BASE_STR}_SAVE_ASSIGNMENT_ATTRIBUTE_FAILED`;

// Location hierarchy
export const FETCH_LOCATION_HIERARCHY = `${BASE_STR}_FETCH_LOCATION_HIERARCHY_DATA`;
export const FETCH_LOCATION_HIERARCHY_SUCCESS = `${BASE_STR}_FETCH_LOCATION_HIERARCHY_SUCCESS`;
export const FETCH_LOCATION_HIERARCHY_FAILED = `${BASE_STR}_FETCH_LOCATION_HIERARCHY_FAILED`;

export const FETCH_LOCATION_ENTITIES_HIERARCHY = `${BASE_STR}_FETCH_LOCATION_ENTITIES_HIERARCHY_DATA`;
export const FETCH_LOCATION_ENTITIES_HIERARCHY_SUCCESS = `${BASE_STR}_FETCH_LOCATION_ENTITIES_HIERARCHY_SUCCESS`;
export const FETCH_LOCATION_ENTITIES_HIERARCHY_FAILED = `${BASE_STR}_FETCH_LOCATION_ENTITIES_HIERARCHY_FAILED`;

export const REQUEST_SEARCH_STRING = `${BASE_STR}_REQUEST_SEARCH_STRING`;
export const REQUEST_SEARCH_STRING_SUCCESS = `${BASE_STR}_REQUEST_SEARCH_STRING_SUCCESS`;
export const REQUEST_SEARCH_STRING_FAILED = `${BASE_STR}_REQUEST_SEARCH_STRING_FAILED`;

export const FETCH_ENTITIES = `${BASE_STR}_FETCH_ENTITIES_DATA`;
export const FETCH_ENTITIES_SUCCESS = `${BASE_STR}_FETCH_ENTITIES_SUCCESS`;
export const FETCH_ENTITIES_FAILED = `${BASE_STR}_FETCH_ENTITIES_FAILED`;

export const FETCH_USER_COMPANY_LIST_DATA = `${BASE_STR}_FETCH_USER_COMPANY_LIST_DATA`;
export const FETCH_USER_COMPANY_LIST_SUCCESS = `${BASE_STR}_FETCH_USER_COMPANY_LIST_SUCCESS`;
export const FETCH_USER_COMPANY_LIST_FAILED = `${BASE_STR}_FETCH_USER_COMPANY_LIST_FAILED`;

export const SET_COMPANY_USER_PREFERENCE = `${BASE_STR}_SET_COMPANY_USER_PREFERENCE`;

// Auto search actions
export const requestSearchString = createAction(REQUEST_SEARCH_STRING);
export const requestSearchStringSuccess = createAction(REQUEST_SEARCH_STRING_SUCCESS);
export const requestSearchStringFailed = createAction(REQUEST_SEARCH_STRING_FAILED);

// Fetch entities actions
export const fetchEntities = createAction(FETCH_ENTITIES);
export const fetchEntitiesSuccess = createAction(FETCH_ENTITIES_SUCCESS);
export const fetchEntitiesFailed = createAction(FETCH_ENTITIES_FAILED);

// Attributes actions
export const fetchAttributes = createAction(FETCH_ATTRIBUTES);
export const fetchAttributesSuccess = createAction(FETCH_ATTRIBUTES_SUCCESS);
export const fetchAttributesFailed = createAction(FETCH_ATTRIBUTES_FAILED);

// Attributes Tree actions
export const fetchTreeAttributes = createAction(FETCH_TREE_ATTRIBUTES);
export const fetchTreeAttributesSuccess = createAction(FETCH_TREE_ATTRIBUTES_SUCCESS);
export const fetchTreeAttributesFailed = createAction(FETCH_TREE_ATTRIBUTES_FAILED);

// Save Assignments actions
export const saveAssignment = createAction(SAVE_ASSIGNMENT);
export const saveAssignmentSuccess = createAction(SAVE_ASSIGNMENT_SUCCESS);
export const saveAssignmentFailed = createAction(SAVE_ASSIGNMENT_FAILED);

// Save Assignments Attributes actions
export const saveAssignmentAttribute = createAction(SAVE_ASSIGNMENT_ATTRIBUTE);
export const saveAssignmentAttributeSuccess = createAction(SAVE_ASSIGNMENT_ATTRIBUTE_SUCCESS);
export const saveAssignmentAttributeFailed = createAction(SAVE_ASSIGNMENT_ATTRIBUTE_FAILED);

// Location hierarchy actions
export const fetchLocationHierarchy = createAction(FETCH_LOCATION_HIERARCHY);
export const fetchLocationHierarchySuccess = createAction(FETCH_LOCATION_HIERARCHY_SUCCESS);
export const fetchLocationHierarchyFailed = createAction(FETCH_LOCATION_HIERARCHY_FAILED);

export const fetchLocationHierarchyEntities = createAction(FETCH_LOCATION_ENTITIES_HIERARCHY);
export const fetchLocationHierarchyEntitiesSuccess = createAction(
    FETCH_LOCATION_ENTITIES_HIERARCHY_SUCCESS
);
export const fetchLocationHierarchyEntitiesFailed = createAction(
    FETCH_LOCATION_ENTITIES_HIERARCHY_FAILED
);

export const fetchUserCompanyList = createAction(FETCH_USER_COMPANY_LIST_DATA);
export const fetchUserCompanyListSuccess = createAction(FETCH_USER_COMPANY_LIST_SUCCESS);
export const fetchUserCompanyListFailed = createAction(FETCH_USER_COMPANY_LIST_FAILED);

export const setCompanyUserPreference = createAction(
    SET_COMPANY_USER_PREFERENCE,
    (companyGuid) => companyGuid
);
