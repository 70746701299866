import React, { Component } from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions";
import { Button } from "~/core";

import "./import-wizard-footer.css";

export class ImportWizardFooter extends Component {
    static propTypes = {
        stepList: PropTypes.array.isRequired,
        currentStep: PropTypes.number.isRequired,
        importType: PropTypes.oneOf(
            Object.keys(actions.ImportTypes).map((k) => actions.ImportTypes[k])
        ).isRequired,
        onBackClick: PropTypes.func.isRequired,
        onNextClick: PropTypes.func.isRequired,
        onSubmitClick: PropTypes.func.isRequired,
        onCloseClick: PropTypes.func.isRequired,
        canContinue: PropTypes.bool.isRequired,
        canSubmit: PropTypes.bool.isRequired,
        hasSubmitted: PropTypes.bool.isRequired,
        submitLabel: PropTypes.string.isRequired,
    };

    render() {
        const {
            canContinue,
            canSubmit,
            currentStep,
            hasSubmitted,
            stepList,
            submitLabel,
            onBackClick,
            onNextClick,
            onCloseClick,
            onSubmitClick,
        } = this.props;
        return (
            <div className="import-wizard-footer">
                {this.props.stepList.length > 1 && (
                    <Button
                        disabled={currentStep === 0 || hasSubmitted}
                        onClick={() => onBackClick()}
                        type="back"
                    />
                )}
                {currentStep !== stepList.length - 1 && (
                    <Button
                        disabled={!canContinue || hasSubmitted}
                        onClick={() => onNextClick()}
                        type="next"
                    />
                )}
                {currentStep === stepList.length - 1 && (
                    <Button
                        disabled={!canSubmit || hasSubmitted}
                        onClick={() => onSubmitClick()}
                        value={submitLabel}
                    />
                )}
                <Button
                    onClick={() => onCloseClick(!hasSubmitted)}
                    type={hasSubmitted ? "close" : "cancel"}
                />
            </div>
        );
    }
}
