import React, { Component } from "react";
import { apiUrl } from "@ai360/core";
import { messages } from "../../i18n-messages";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { dropdowns, model, service } from "../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    hasOnlyInActiveOptions,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
// constants
import { ADD, adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import * as picklistService from "~/core/picklist/picklist-names";
// Components
import { Checkbox, NumericInput, Section, SelectInput, SubSection, TextInput } from "~/core";
// Style
import "./add-edit-panel.css";
// Types
import { IAddEditPanelProps, IAddEditPanelState, VarietyHybrid } from "../interfaces";
import CommodityList from "~/admin/agBytes/components/commodity-list";
import { PROPS_MYAGDATA_COMMODITY } from "~/admin/agBytes/variety-hybrid/data/model";

export class AddEditPanel extends Component<
    IAddEditPanelProps & InjectedIntlProps,
    IAddEditPanelState
> {
    static defaultProps = {
        [model.PROPS_CROP_CLASS_NAME_LIST]: [],
    };
    varietyHybrid: VarietyHybrid;
    constructor(props) {
        super(props);
        this.varietyHybrid = props.record || {};
        this.state = {
            [model.PROPS_CROP_NAME_LIST]: prepareSelectableOptions(
                props[model.PROPS_CROP_NAME_LIST],
                { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
            ),
            [model.PROPS_GROWTH_STAGE_GROUP_LIST]: prepareSelectableOptions(
                props[model.PROPS_GROWTH_STAGE_GROUP_LIST],
                { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
            ),
            [picklistService.PICKLIST_BRAND_ORGANIZATION]: prepareSelectableOptions(
                props[picklistService.PICKLIST_BRAND_ORGANIZATION],
                { guid: PICKLIST_GUID as any, label: VALUE, id: ID }
            ),
            [model.PROPS_CROP_CLASS_NAME_LIST]: [],
            [model.PROPS_TRAIT_LIST]: prepareSelectableOptions(props[model.PROPS_TRAIT_LIST], {
                guid: GUID,
                label: NAME,
                id: ID,
                appendIdToLabel: true,
            }),
            [picklistService.PICKLIST_COMMODITY]: prepareSelectableOptions(
                props[picklistService.PICKLIST_COMMODITY],
                { guid: PICKLIST_GUID as any, label: VALUE, id: ID }
            ),
            [picklistService.PICKLIST_COMMODITY_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_COMMODITY_TYPE],
                { guid: PICKLIST_GUID as any, label: VALUE, id: ID }
            ),
            [picklistService.PICKLIST_INTENDED_USE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_INTENDED_USE],
                { guid: PICKLIST_GUID as any, label: VALUE, id: ID }
            ),
            nextId: null,
        };
    }

    componentDidMount() {
        this.setupVarietyHybridRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(),
            this.props.fetchDropdownData({
                [model.PROPS_CROP_NAME_LIST]: {
                    url: dropdowns[model.PROPS_CROP_NAME_LIST],
                    model: "Name",
                },
                [model.PROPS_GROWTH_STAGE_GROUP_LIST]: {
                    url: dropdowns[model.PROPS_GROWTH_STAGE_GROUP_LIST],
                    model: "GrowthStageId",
                },
                [model.PROPS_TRAIT_LIST]: {
                    url: dropdowns[model.PROPS_TRAIT_LIST],
                    model: "TraitId",
                },
            }),
            this.props.fetchPicklistData(),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.varietyHybrid);
        }
        if (nextProps.addEditPanel.mode === ADD) {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.varietyHybrid = nextProps.record;
                this.props.needs([
                    this.props.fetchDropdownData({
                        [model.PROPS_CROP_CLASS_NAME_LIST]: {
                            url: apiUrl("AgBytes/GetCropClassAgBytesList"),
                            model: this.varietyHybrid[model.PROPS_CROP_GUID],
                        },
                    }),
                ]);
            }
        }

        this.initializeDropdowns(nextProps, this.props);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.varietyHybrid[model.PROPS_VARIETY_HYBRID_ID] = nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupVarietyHybridRecord = () => {
        const { addEditPanel } = this.props;
        this.varietyHybrid = this.props.record || {};
        if (addEditPanel.mode === ADD) {
            this.varietyHybrid = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps, props) => {
        this.initializeCropName(nextProps, props);
        this.initializeBrand(nextProps, props);
        this.initializeGroupStage(nextProps, props);
        this.initializeTraits(nextProps, props);
        this.initializeCropClass(nextProps, props);
        this.initializeMyAgData(nextProps, props);
    };

    initializeCropName = (nextProps, props) => {
        if (nextProps[model.PROPS_CROP_NAME_LIST] !== props[model.PROPS_CROP_NAME_LIST]) {
            this.setState({
                [model.PROPS_CROP_NAME_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME_LIST],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
                ),
            });
        }
    };

    initializeBrand = (nextProps, props) => {
        if (
            nextProps[picklistService.PICKLIST_BRAND_ORGANIZATION] !==
            props[picklistService.PICKLIST_BRAND_ORGANIZATION]
        ) {
            this.setState({
                [picklistService.PICKLIST_BRAND_ORGANIZATION]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_BRAND_ORGANIZATION],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeGroupStage = (nextProps, props) => {
        if (
            nextProps[model.PROPS_GROWTH_STAGE_GROUP_LIST] !==
            props[model.PROPS_GROWTH_STAGE_GROUP_LIST]
        ) {
            this.setState({
                [model.PROPS_GROWTH_STAGE_GROUP_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_GROWTH_STAGE_GROUP_LIST],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
                ),
            });
        }
    };

    initializeTraits = (nextProps, props) => {
        if (nextProps[model.PROPS_TRAIT_LIST] !== props[model.PROPS_TRAIT_LIST]) {
            this.setState({
                [model.PROPS_TRAIT_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_TRAIT_LIST],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
                ),
            });
        }
    };

    initializeCropClass = (nextProps, props) => {
        if (
            nextProps[model.PROPS_CROP_CLASS_NAME_LIST] !== props[model.PROPS_CROP_CLASS_NAME_LIST]
        ) {
            this.setState({
                [model.PROPS_CROP_CLASS_NAME_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_CLASS_NAME_LIST],
                    { guid: GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeMyAgData = (nextProps, props) => {
        if (
            nextProps[picklistService.PICKLIST_COMMODITY] !==
            props[picklistService.PICKLIST_COMMODITY]
        ) {
            this.setState({
                [picklistService.PICKLIST_COMMODITY]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_COMMODITY],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }

        if (
            nextProps[picklistService.PICKLIST_COMMODITY_TYPE] !==
            props[picklistService.PICKLIST_COMMODITY_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_COMMODITY_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_COMMODITY_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }

        if (
            nextProps[picklistService.PICKLIST_INTENDED_USE] !==
            props[picklistService.PICKLIST_INTENDED_USE]
        ) {
            this.setState({
                [picklistService.PICKLIST_INTENDED_USE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_INTENDED_USE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    onChange = (formKey, value, callback?: () => void) => {
        this.varietyHybrid = onTextChange(
            this.varietyHybrid,
            { formKey: [formKey], value },
            callback
        );
    };

    onNumberChange = (formKey, value, callback?: () => void) => {
        this.varietyHybrid = onNumberChange(
            this.varietyHybrid,
            { formKey: [formKey], value },
            callback
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.varietyHybrid[model.PROPS_VARIETY_HYBRID_NAME]]);
    };

    onSelectChange = ({ type, guid }, value, callback?: () => void) => {
        this.varietyHybrid = handlePicklistChange(
            this.varietyHybrid,
            { type, guid, value },
            callback
        );
    };

    onCropNameChange = () => {
        if (this.varietyHybrid[model.PROPS_CROP_GUID]) {
            this.varietyHybrid[model.PROPS_CROP_CLASS_GUID] = "";
            this.varietyHybrid[model.PROPS_CROP_CLASS_NAME] = "";
            this.props.needs([
                this.props.fetchDropdownData({
                    [model.PROPS_CROP_CLASS_NAME_LIST]: {
                        url: dropdowns[model.PROPS_CROP_CLASS_NAME_LIST],
                        model: this.varietyHybrid[model.PROPS_CROP_GUID],
                    },
                }),
            ]);
        } else {
            this.setState(
                {
                    [model.PROPS_CROP_CLASS_NAME_LIST]: [],
                },
                () => {
                    this.varietyHybrid[model.PROPS_CROP_CLASS_NAME_LIST] = [];
                    this.varietyHybrid[model.PROPS_CROP_CLASS_GUID] = "";
                    this.varietyHybrid[model.PROPS_CROP_CLASS_NAME] = "";
                }
            );
        }
    };

    private onChangeMyAgData = (formKey, value) => {
        this.varietyHybrid[model.PROPS_MYAGDATA_COMMODITY] = value;
    };

    renderCropClass = () => {
        const { formatMessage } = this.props.intl;
        const { varietyHybrid } = this;
        return (
            <SubSection>
                <SelectInput
                    tabIndex={0}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    options={this.state[model.PROPS_CROP_CLASS_NAME_LIST]}
                    placeholderText={formatMessage(messages.cropClassIdName)}
                    value={mapToPicklistValue({
                        options: this.state[model.PROPS_CROP_CLASS_NAME_LIST],
                        selectedGuid: varietyHybrid[model.PROPS_CROP_CLASS_GUID],
                    })}
                    onChange={(value) =>
                        this.onSelectChange(
                            {
                                guid: model.PROPS_CROP_CLASS_GUID,
                                type: model.PROPS_CROP_CLASS_NAME,
                            },
                            value
                        )
                    }
                    containerClassNames={getAgBytesErrorClassNames(476, this.props.apiErrors, [
                        "variety-hybrid-select-input",
                    ])}
                    initialFilterStr={""}
                />
            </SubSection>
        );
    };

    renderVarietyHybridInfo = () => {
        const { formatMessage } = this.props.intl;
        const { varietyHybrid } = this;
        const { nextId } = this.state;
        return (
            <div className="section-container variety-hybrid-container">
                <div className="section-container section-column variety-hybrid-container">
                    <Section>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                scale={0}
                                precision={9}
                                suppressFormatting
                                containerClassNames={getAgBytesErrorClassNames(
                                    [199, 200],
                                    this.props.apiErrors,
                                    ["form-input-id"]
                                )}
                                placeholderText={formatMessage(messages.varietyHybridId)}
                                labelText={formatMessage(messages.varietyHybridId)}
                                value={nextId || varietyHybrid[model.PROPS_VARIETY_HYBRID_ID]}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_VARIETY_HYBRID_ID, value)
                                }
                                required
                            />
                            {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                                <Checkbox
                                    className="active-checkbox"
                                    onChange={(e, value) =>
                                        this.onChange(model.PROPS_ACTIVE_YN, value)
                                    }
                                    value={varietyHybrid[model.PROPS_ACTIVE_YN]}
                                    label={formatMessage(messages.active)}
                                />
                            )}
                        </SubSection>
                        <SubSection className="variety-hybrid-section-container">
                            <SelectInput
                                autoFocus
                                tabIndex={0}
                                openOnFocus={false}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[model.PROPS_CROP_NAME_LIST]}
                                placeholderText={formatMessage(messages.cropIdName)}
                                value={mapToPicklistValue({
                                    options: this.state[model.PROPS_CROP_NAME_LIST],
                                    selectedGuid: varietyHybrid[model.PROPS_CROP_GUID],
                                })}
                                onChange={(value) =>
                                    this.onSelectChange(
                                        {
                                            guid: model.PROPS_CROP_GUID,
                                            type: model.PROPS_CROP_NAME,
                                        },
                                        value,
                                        this.onCropNameChange
                                    )
                                }
                                containerClassNames={getAgBytesErrorClassNames(
                                    [155, 476],
                                    this.props.apiErrors,
                                    ["variety-hybrid-select-input"]
                                )}
                                initialFilterStr={""}
                                required
                            />
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[picklistService.PICKLIST_BRAND_ORGANIZATION]}
                                placeholderText={formatMessage(messages.brand)}
                                value={mapToPicklistValue({
                                    options:
                                        this.state[picklistService.PICKLIST_BRAND_ORGANIZATION],
                                    selectedGuid:
                                        varietyHybrid[model.PROPS_VARIETY_HYBRID_BRAND_GUID],
                                })}
                                onChange={(value) =>
                                    this.onSelectChange(
                                        {
                                            type: picklistService.PICKLIST_BRAND_ORGANIZATION,
                                            guid: model.PROPS_VARIETY_HYBRID_BRAND_GUID,
                                        },
                                        value
                                    )
                                }
                                containerClassNames={getAgBytesErrorClassNames(
                                    [193, 476],
                                    this.props.apiErrors,
                                    ["variety-hybrid-select-input"]
                                )}
                                initialFilterStr={""}
                                required
                            />
                        </SubSection>
                        {this.state[model.PROPS_CROP_CLASS_NAME_LIST].length &&
                        !hasOnlyInActiveOptions(this.state[model.PROPS_CROP_CLASS_NAME_LIST])
                            ? this.renderCropClass()
                            : null}
                    </Section>
                </div>
                <span className="no-bar section-fence"></span>
                <div className="section-container section-column">
                    <Section>
                        <SubSection className="form-section-row-2">
                            <TextInput
                                tabIndex={0}
                                maxLength={50}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [201, 476],
                                    this.props.apiErrors
                                )}
                                placeholderText={formatMessage(messages.varietyHybridName)}
                                value={varietyHybrid[model.PROPS_VARIETY_HYBRID_NAME] || ""}
                                onChange={(value) =>
                                    this.onChange(
                                        model.PROPS_VARIETY_HYBRID_NAME,
                                        value,
                                        this.setHeaderText
                                    )
                                }
                                required
                            />
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[model.PROPS_GROWTH_STAGE_GROUP_LIST]}
                                placeholderText={formatMessage(messages.growthStageGroupIDName)}
                                value={mapToPicklistValue({
                                    options: this.state[model.PROPS_GROWTH_STAGE_GROUP_LIST],
                                    selectedGuid:
                                        varietyHybrid[model.PROPS_GROWTH_STAGE_GROUP_GUID],
                                })}
                                onChange={(value) =>
                                    this.onSelectChange(
                                        {
                                            type: model.PROPS_GROWTH_STAGE_GROUP_NAME,
                                            guid: model.PROPS_GROWTH_STAGE_GROUP_GUID,
                                        },
                                        value
                                    )
                                }
                                containerClassNames={["variety-hybrid-select-input"]}
                                initialFilterStr={""}
                            />
                        </SubSection>
                    </Section>
                </div>
                <span className="no-bar section-fence"></span>
                <div className="section-container section-column variety-hybrid-container">
                    <Section>
                        <SubSection className="form-section-row-2 variety-hybrid-section-container">
                            <Checkbox
                                className="gmo-checkbox"
                                onChange={(e, value) =>
                                    this.onChange(model.PROPS_VARIETY_HYBRID_GMO, value)
                                }
                                value={varietyHybrid[model.PROPS_VARIETY_HYBRID_GMO] || false}
                                label={formatMessage(messages.geneticallyModified)}
                            />
                        </SubSection>
                    </Section>
                </div>
            </div>
        );
    };

    renderDetailInfo() {
        const { formatMessage } = this.props.intl;
        const { varietyHybrid } = this;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.miscellaneousInfo)}>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.relativeMaturity)}
                            value={varietyHybrid[model.PROPS_RELATIVE_MATURITY] || ""}
                            onChange={(value) =>
                                this.onChange(model.PROPS_RELATIVE_MATURITY, value)
                            }
                        />
                        <TextInput
                            maxLength={50}
                            tabIndex={0}
                            placeholderText={formatMessage(messages.gduToMaturity)}
                            value={varietyHybrid[model.PROPS_GDU_TO_MATURITY] || ""}
                            onChange={(value) => this.onChange(model.PROPS_GDU_TO_MATURITY, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={6}
                            placeholderText={formatMessage(messages.chillHoursLow)}
                            value={varietyHybrid[model.PROPS_CHILL_HOURS_LOW] || ""}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_CHILL_HOURS_LOW, value)
                            }
                        />
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={6}
                            placeholderText={formatMessage(messages.chillHoursHigh)}
                            value={varietyHybrid[model.PROPS_CHILL_HOURS_HIGH] || ""}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_CHILL_HOURS_HIGH, value)
                            }
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_TRAIT_LIST]}
                            placeholderText={formatMessage(messages.traitIdName)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_TRAIT_LIST],
                                selectedGuid: varietyHybrid[model.PROPS_TRAIT_GUID],
                            })}
                            onChange={(value) =>
                                this.onSelectChange(
                                    {
                                        type: model.PROPS_VARIETY_HYBRID_TRAIT_NAME,
                                        guid: model.PROPS_TRAIT_GUID,
                                    },
                                    value
                                )
                            }
                            containerClassNames={["variety-hybrid-trait-select-input"]}
                            initialFilterStr={""}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    renderMyAgData() {
        const { formatMessage } = this.props.intl;
        const { commodityType, commodity, intendedUse } = this.state;

        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.myAgData)} className="grid-section">
                    <SubSection>
                        <CommodityList
                            commodityList={commodity}
                            formatMessage={formatMessage}
                            commodityTypeList={commodityType}
                            intendedUseList={intendedUse}
                            onTextChange={this.onChangeMyAgData}
                            itemListAlias={PROPS_MYAGDATA_COMMODITY}
                            record={this.varietyHybrid[PROPS_MYAGDATA_COMMODITY] ?? []}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    render() {
        return (
            <div className="add-edit-panel">
                {this.renderVarietyHybridInfo()}
                <div className="section-container variety-hybrid-container">
                    {this.renderDetailInfo()}
                    <span className="no-bar section-fence"></span>
                    {this.renderMyAgData()}
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
