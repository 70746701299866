import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import {
    PICKLIST_BRAND_ORGANIZATION,
    getPickListCode,
    PICKLIST_INTENDED_USE,
    PICKLIST_COMMODITY,
    PICKLIST_COMMODITY_TYPE,
} from "~/core/picklist/picklist-names";

export const MODEL_NAME = "varietyHybrid";
export const URL = apiUrl("AgBytes/GetVarietyHybridList");
export const GETRECORD = apiUrl("AgBytes/GetVarietyHybrid");
export const NEXT_ID = apiUrl("AgBytes/GetNextVarietyHybridId");
export const CREATE = apiUrl("AgBytes/AddVarietyHybrid");
export const UPDATE = apiUrl("AgBytes/UpdateVarietyHybrid");
export const DELETE = apiUrl("AgBytes/DeleteVarietyHybridList");
export const EXPORT_FILE_NAME = "VarietyHybridExport";
export const EXPORT_URL = apiUrl("AgBytes/ExportVarietyHybridList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetVarietyHybridAutoSearchList");
export const IMPORT_URL = apiUrl("AgBytes/VarietyHybridImport");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidVarietyHybridImport");
export const SELECT_ALL = apiUrl("AgBytes/GetVarietyHybridSelectAllList");
export const MODEL_ID = "models/agBytes/agBytesFullVarietyHybrid";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "varietyHybridFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "varietyHybridSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "varietyHybridPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

// Dropdowns
export const REQUEST_CROP_NAME = apiUrl("AgBytes/GetCropAgBytesList");
export const REQUEST_GROUP_STAGE = apiUrl("AgBytes/GetGrowthStageAgBytesList");
export const REQUEST_TRAITS = apiUrl("AgBytes/GetTraitAgBytesList");
export const REQUEST_CROP_CLASS = apiUrl("AgBytes/GetCropClassAgBytesList");

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        varietyHybridId: "",
        varietyHybridName: "",
        cropName: "",
        cropClassName: "",
        brandOrganization: "",
        geneticallyModified: "",
        traitName: "",
        modifiedDate: "",
        canDelete: "",
        isActive: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "BrandOrganization",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultVarietyHybrid = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_VARIETY_HYBRID_BRAND]: "",
    [model.PROPS_VARIETY_HYBRID_BRAND_GUID]: "",
    [model.PROPS_CAN_DELETE]: false,
    [model.PROPS_CHILL_HOURS_LOW]: "",
    [model.PROPS_CHILL_HOURS_HIGH]: "",
    [model.PROPS_CROP_NAME]: "",
    [model.PROPS_CROP_GUID]: "",
    [model.PROPS_CROP_ID]: "",
    [model.PROPS_GDU_TO_MATURITY]: "",
    [model.PROPS_VARIETY_HYBRID_GMO]: false,
    [model.PROPS_GROWTH_STAGE_GROUP_ID]: "",
    [model.PROPS_GROWTH_STAGE_GROUP_GUID]: "",
    [model.PROPS_GROWTH_STAGE_GROUP_NAME]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_RELATIVE_MATURITY]: "",
    [model.PROPS_TRAIT_ID]: "",
    [model.PROPS_TRAIT_GUID]: "",
    [model.PROPS_VARIETY_HYBRID_TRAIT_NAME]: "",
    [model.PROPS_VARIETY_HYBRID_ID]: "",
    [model.PROPS_VARIETY_HYBRID_GUID]: "",
    [model.PROPS_VARIETY_HYBRID_NAME]: "",
    [model.PROPS_CROP_NAME_LIST]: [],
    [model.PROPS_CROP_CLASS_NAME_LIST]: [],
    [model.PROPS_MODULE_ID]: MODEL_ID,
    [model.PROPS_MYAGDATA_COMMODITY]: [],
});

export const pickLists = {
    [PICKLIST_BRAND_ORGANIZATION]: getPickListCode(PICKLIST_BRAND_ORGANIZATION),
    [PICKLIST_INTENDED_USE]: getPickListCode(PICKLIST_INTENDED_USE),
    [PICKLIST_COMMODITY]: getPickListCode(PICKLIST_COMMODITY),
    [PICKLIST_COMMODITY_TYPE]: getPickListCode(PICKLIST_COMMODITY_TYPE),
};

export const dropdowns = {
    [model.PROPS_CROP_NAME_LIST]: REQUEST_CROP_NAME,
    [model.PROPS_GROWTH_STAGE_GROUP_LIST]: REQUEST_GROUP_STAGE,
    [model.PROPS_TRAIT_LIST]: REQUEST_TRAITS,
    [model.PROPS_CROP_CLASS_NAME_LIST]: REQUEST_CROP_CLASS,
};

export const service = createService({
    id: model.PROPS_VARIETY_HYBRID_ID,
    guid: model.PROPS_VARIETY_HYBRID_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    dropdowns,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_VARIETY_HYBRID_ID]: {
            label: "varietyHybridId",
            gridCol: 5,
        },
        [model.PROPS_CROP_NAME]: { label: "cropName", gridCol: 10 },
        [model.PROPS_CROP_CLASS_NAME]: { label: "cropClassName", gridCol: 10 },
        [model.PROPS_VARIETY_HYBRID_BRAND]: { label: "brand", gridCol: 10 },
        [model.PROPS_VARIETY_HYBRID_NAME]: {
            label: "varietyHybridName",
            gridCol: 10,
        },
        [model.PROPS_VARIETY_HYBRID_GMO]: {
            label: "geneticallyModified",
            gridCol: 5,
        },
        [model.PROPS_VARIETY_HYBRID_TRAIT_NAME]: {
            label: "traitName",
            gridCol: 10,
        },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => Object.assign({}, defaultVarietyHybrid()),
    defaultSort,
});
