import { connect } from "react-redux";
import { withMasked, withApiResult } from "~/hocs";
import { actions } from "./data";
import * as selectors from "./data/selectors";
import ImportFilterSettings from "./import-filter-settings";
import { getApiError } from "~/core/api/selectors";

const mapStateToProps = (state) => ({
    importFilterData: selectors.getImportFilterData(state),
    apiError: getApiError(state),
});

const mapDispatchToProps = () => ({
    // Actions to be dispatched using needs
    fetchImportFilter: actions.fetchImportFilter,
    saveImportFilter: actions.saveImportFilter,
});

export default withMasked(
    withApiResult(connect(mapStateToProps, mapDispatchToProps)(ImportFilterSettings))
);
