import { createReducer } from "redux-act";
import { fetchStateSuccess, fetchStateFailed } from "./actions";

export const ADDRESS_STATE_DATA_KEY = "ADDRESS_STATE_DATA";

export const addressReducer = createReducer(
    {
        [fetchStateSuccess]: (state, payload) => ({
            ...state,
            countryStateList: {
                ...state.countryStateList,
                [payload.guid]: payload.response,
            },
        }),
        [fetchStateFailed]: (state) => ({
            ...state,
            countryStateList: {},
        }),
    },
    {
        countryStateList: {},
    }
);
