import { defineMessages } from "react-intl";

export const messages = defineMessages({
    errorTitle: {
        id: "recModule.recInfo.rxFileImport.errorTitle",
        defaultMessage: "Error",
    },
    missingTestResultsText: {
        id: "recModule.recInfo.rxFileImport.missingTestResultsText",
        defaultMessage: "Missing Test Results For ",
    },
    noRxFileImportTemplate: {
        id: "recModule.recInfo.rxFileImport.noRxFileImportTemplate",
        defaultMessage: "Missing RX File Import Template",
    },
    notEnoughColumnsForEachField: {
        id: "recModule.recInfo.rxFileImport.notEnoughColumnsForEachField",
        defaultMessage: "Not enough columns for each field",
    },
    processingText: {
        id: "recModule.recInfo.rxFileImport.processingText",
        defaultMessage: "Processing",
    },
    setProductText: {
        id: "recModule.recInfo.rxFileImport.setProductText",
        defaultMessage: "Set Product",
    },
    uploadBtnText: {
        id: "recModule.recInfo.rxFileImport.rxFileImport.uploadBtnText",
        defaultMessage: "Upload",
    },
    validationNoMatchingFieldsText: {
        id: "recModule.recInfo.rxFileImport.validationNoMatchingFieldsText",
        defaultMessage: "File does not match selected field(s).",
    },
    validationFieldsWithoutFilesText: {
        id: "recModule.recInfo.rxFileImport.validationFieldsWithoutFilesText",
        defaultMessage: "The following fields were not matched to any files: {fieldNames}.",
    },
    errorProcessingFile: {
        id: "recModule.recInfo.rxFileImport.errorProcessingFile",
        defaultMessage: "Error processing file",
    },
});
