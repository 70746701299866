import React, { Component } from "react";
import PropTypes from "prop-types";

import { OrgLevelSearch } from "~/admin/agBytes/components/org-level-search";
import PhotoList from "~/admin/agBytes/components/photo-list";
import { Section, SubSection, TextArea, RadioButtonGroup, RadioButton } from "~/core";
import { SearchAPI } from "@ai360/core";

import { VendorSearch } from "../../../../common";
import { CustomerSearch } from "../../../component/customer-search";
import { messages } from "../../../i18n-messages";
import { model } from "../../data";
import { Specifications } from "./specifications-container";
import { createAddEditModalTitle } from "~/i18n-messages";
import { getAgBytesErrorClassNames } from "~/admin/utils";

const CUSTOMER_USER_TYPE_ID = 2;

export class OwnerOperator extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        equipmentParentType: PropTypes.string,
        equipmentSetup: PropTypes.object,
        userInfo: PropTypes.object,
        formatMessage: PropTypes.func,
        onNumberChange: PropTypes.func,
        onOwnerOperatorChange: PropTypes.func,
        onSelectInputChange: PropTypes.func,
        onTextChange: PropTypes.func,
        orgLevel: PropTypes.array,
        ownerOperatorData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            ownerOperatorKey: 1,
            assignedToText: this.getAssignedToText(1),
            labelObj: {},
            equipmentSetup: {},
            userInfo: props.userInfo,
            equipmentParentType: null,
            orgLevel: props.orgLevel,
        };
    }

    ownerOperatorKeys = {
        1: {
            ownerOperatorCity: SearchAPI.Props.CUSTOMER_CITY,
            ownerOperatorGuid: SearchAPI.Props.CUSTOMER_GUID,
            ownerOperatorName: SearchAPI.Props.CUSTOMER_NAME,
            ownerOperatorStateAbbreviation: SearchAPI.Props.STATE_ABBR,
            ownerOperatorGridKey: "ownerOperatorCustomerGuid",
        },
        2: {
            ownerOperatorCity: OrgLevelSearch.ORG_LEVEL_CITY,
            ownerOperatorGuid: OrgLevelSearch.ORG_LEVEL_GUID,
            ownerOperatorName: OrgLevelSearch.ORG_LEVEL_NAME,
            ownerOperatorStateAbbreviation: OrgLevelSearch.ORG_LEVEL_STATE,
            ownerOperatorGridKey: "ownerOperatorOrgLevelGuid",
        },
        3: {
            ownerOperatorCity: VendorSearch.VENDOR_CITY,
            ownerOperatorGuid: VendorSearch.VENDOR_GUID,
            ownerOperatorName: VendorSearch.VENDOR_NAME,
            ownerOperatorStateAbbreviation: VendorSearch.VENDOR_STATE_ABBR,
            ownerOperatorGridKey: "ownerOperatorVendorGuid",
        },
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.equipmentSetup) !== JSON.stringify(this.props.equipmentSetup)
        ) {
            const { ownerOperatorCity, ownerOperatorStateAbbreviation, ownerOperatorName } =
                nextProps.equipmentSetup;
            this.setState({
                equipmentSetup: nextProps.equipmentSetup,
                labelObj: {
                    ownerOperatorCity,
                    ownerOperatorStateAbbreviation,
                    ownerOperatorName,
                },
                ownerOperatorKey: this.getSelectedOwnerOperatorKey(nextProps.equipmentSetup),
            });
        }
        if (nextProps.equipmentParentType !== this.props.equipmentParentType) {
            this.setState({
                equipmentParentType: nextProps.equipmentParentType,
            });
        }
        if (nextProps.userInfo !== this.props.userInfo) {
            this.setState({
                userInfo: nextProps.userInfo,
            });
        }
        if (JSON.stringify(nextProps.orgLevel) !== JSON.stringify(this.props.orgLevel)) {
            this.setState({
                orgLevel: nextProps.orgLevel,
            });
        }
    }

    getSelectedOwnerOperatorKey = (equipmentSetup) => {
        if (equipmentSetup.ownerOperatorCustomerGuid) {
            return 1;
        } else if (equipmentSetup.ownerOperatorOrgLevelGuid) {
            return 2;
        } else if (equipmentSetup.ownerOperatorVendorGuid) {
            return 3;
        } else {
            return 1;
        }
    };

    onRadioButtonChange = (ownerOperatorKey) => {
        const assignedToText = this.getAssignedToText(ownerOperatorKey);
        const prevOwnerOperatorKey = this.state.ownerOperatorKey;
        this.setState(
            {
                ownerOperatorKey,
                assignedToText,
                labelObj: {},
            },
            () => {
                if (this.props.onOwnerOperatorChange) {
                    this.props.onOwnerOperatorChange({
                        ownerOperatorCity: "",
                        [this.ownerOperatorKeys[prevOwnerOperatorKey].ownerOperatorGridKey]: "",
                        ownerOperatorName: "",
                        ownerOperatorStateAbbreviation: "",
                    });
                }
            }
        );
    };

    onOwnerOperatorChange = (data) => {
        if (data) {
            const { ownerOperatorKey } = this.state;
            const {
                ownerOperatorCity,
                ownerOperatorName,
                ownerOperatorStateAbbreviation,
                ownerOperatorGuid,
                ownerOperatorGridKey,
            } = this.ownerOperatorKeys[ownerOperatorKey];
            let labelObj = {
                ownerOperatorCity: data[ownerOperatorCity],
                ownerOperatorName: data[ownerOperatorName],
                ownerOperatorStateAbbreviation: data[ownerOperatorStateAbbreviation],
                [ownerOperatorGridKey]: data[ownerOperatorGuid],
            };

            if (this.props.onOwnerOperatorChange) {
                this.props.onOwnerOperatorChange(labelObj);
            }
            this.setState({ labelObj });
        } else {
            const labelObj = {
                ownerOperatorCity: "",
                ownerOperatorName: "",
                ownerOperatorStateAbbreviation: "",
            };
            if (this.props.onOwnerOperatorChange) {
                this.props.onOwnerOperatorChange(labelObj);
            }
            this.setState({ labelObj });
        }
    };

    getOwnerInfoComponent = (ownerOperatorKey) => {
        const { apiErrors } = this.props;
        const props = {
            onSelection: this.onOwnerOperatorChange,
            formatMessage: this.props.formatMessage,
            clearable: false,
            name: this.state.labelObj.ownerOperatorName,
            clearOnSelection: true,
            required: true,
            orgLevelList: this.state.orgLevel?.map(({ orgLevelGuid }) => orgLevelGuid),
        };
        switch (ownerOperatorKey) {
            case 1:
                return (
                    <CustomerSearch
                        {...props}
                        classNames={getAgBytesErrorClassNames(575, apiErrors)}
                    />
                );
            case 2:
                return (
                    <div>
                        {!this.state.orgLevel ? null : (
                            <OrgLevelSearch
                                {...props}
                                classNames={getAgBytesErrorClassNames(575, apiErrors)}
                                itemList={this.state.orgLevel}
                                placeholderText={createAddEditModalTitle(
                                    false,
                                    this.props.formatMessage,
                                    messages.location
                                )}
                            />
                        )}
                    </div>
                );
            case 3:
                return (
                    <VendorSearch
                        {...props}
                        classNames={getAgBytesErrorClassNames(575, apiErrors)}
                    />
                );
            default:
                return null;
        }
    };

    getAssignedToText = (ownerOperatorKey) => {
        const { formatMessage } = this.props;
        let ownerOperatorLabel = "";
        switch (ownerOperatorKey) {
            case 1:
                ownerOperatorLabel = formatMessage(messages.customer);
                break;
            case 2:
                ownerOperatorLabel = formatMessage(messages.location);
                break;
            case 3:
                ownerOperatorLabel = formatMessage(messages.vendor);
                break;
            default:
                break;
        }
        return {
            ownerOperatorLabel,
        };
    };

    renderSelectedOwnerOperator = () => {
        const { labelObj } = this.state;
        const { ownerOperatorCity, ownerOperatorStateAbbreviation } = labelObj;
        let cityStateText =
            ownerOperatorCity && ownerOperatorStateAbbreviation
                ? `${labelObj.ownerOperatorCity}, ${labelObj.ownerOperatorStateAbbreviation}`
                : "";
        return (
            <div className="selected-owner-content">
                <span>{labelObj.ownerOperatorName}</span>
                <span className="italic-text">{cityStateText}</span>
            </div>
        );
    };

    renderOwnerOperator = () => {
        const { formatMessage, userInfo } = this.props;
        return (
            <Section required headerText={formatMessage(messages.ownerOperator)}>
                <SubSection className="owner-operator-cont">
                    <RadioButtonGroup
                        className="owner-operator-radio-group"
                        selectedValue={this.state.ownerOperatorKey}
                        afterOnChange={this.onRadioButtonChange}
                    >
                        <RadioButton
                            tabIndex={0}
                            value={1}
                            label={formatMessage(messages.customer)}
                        />
                        <RadioButton
                            tabIndex={0}
                            value={2}
                            label={formatMessage(messages.location)}
                            visible={
                                userInfo && userInfo.userTypeId === CUSTOMER_USER_TYPE_ID
                                    ? false
                                    : true
                            }
                        />
                        <RadioButton
                            tabIndex={0}
                            value={3}
                            label={formatMessage(messages.vendor)}
                            visible={userInfo && userInfo.role.setupVendorSetup ? true : false}
                        />
                    </RadioButtonGroup>
                    <div className="owner-search-section">
                        {this.getOwnerInfoComponent(this.state.ownerOperatorKey)}
                        <div className="assigned-to-cont">
                            {formatMessage(messages.assignedTo, this.state.assignedToText)}:
                            {this.renderSelectedOwnerOperator()}
                        </div>
                    </div>
                </SubSection>
            </Section>
        );
    };

    renderPhotoList = () => {
        const { formatMessage } = this.props;
        const { equipmentSetup } = this.state;
        const photoList =
            equipmentSetup[model.PROPS_EQUIPMENT_PHOTO_LIST] ||
            equipmentSetup[model.PROPS_PHOTO_LIST];
        return (
            <Section className="grid-section" headerText={formatMessage(messages.photos)}>
                <SubSection>
                    <PhotoList
                        formatMessage={formatMessage}
                        record={photoList}
                        itemListAlias={model.PROPS_PHOTO_LIST}
                        onTextChange={this.props.onTextChange}
                    />
                </SubSection>
                <SubSection className="equipment-notes equipment-description">
                    <TextArea
                        maxLength={4000}
                        value={equipmentSetup[model.PROPS_DESCRIPTION]}
                        onChange={(value) => {
                            this.props.onTextChange(model.PROPS_DESCRIPTION, value);
                        }}
                        placeholderText={formatMessage(messages.description)}
                    />
                </SubSection>
            </Section>
        );
    };

    renderSpecifications = () => {
        return (
            <Specifications
                formatMessage={this.props.formatMessage}
                equipmentSetup={this.state.equipmentSetup}
                onTextChange={this.props.onTextChange}
                onNumberChange={this.props.onNumberChange}
                onSelectInputChange={this.props.onSelectInputChange}
                equipmentParentType={this.state.equipmentParentType}
            />
        );
    };

    render() {
        return (
            <div className="section-container section-column">
                {this.renderOwnerOperator()}
                {this.renderPhotoList()}
                {this.renderSpecifications()}
            </div>
        );
    }
}
