import * as actions from "./actions";
import { NonFieldFeatureAPI } from "@ai360/core";
import { logFirebaseEvent } from "~/utils/firebase";

const initialState = {
    isEditing: false,
    activeFeature: null,
    activeCustomer: null,
    fields: [],
};

export const nonFieldFeatureInformationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.START_ADDING_FEATURE:
        case actions.START_EDITING_FEATURE:
            if (action.type === actions.START_ADDING_FEATURE) {
                logFirebaseEvent("nff_add_feature");
            } else {
                logFirebaseEvent("nff_edit_feature");
            }
            return Object.freeze({
                ...state,
                isEditing: true,
                activeFeature: action.featureToEdit,
                activeCustomer: action.customer,
            });
        case actions.STOP_EDITING_FEATURE:
            return Object.freeze({
                ...state,
                isEditing: false,
                activeFeature: null,
                activeCustomer: null,
            });
        case actions.SET_ACTIVE_FEATURE:
            return Object.freeze({
                ...state,
                activeFeature: action.feature ? action.feature.clone() : null,
            });
        case actions.SET_FEATURE_GEOMETRIES: {
            const activeFeature = Object.assign(new NonFieldFeatureAPI.NonFieldFeature(), {
                ...state.activeFeature,
                geometries: action.geometries,
            });
            return Object.freeze({
                ...state,
                activeFeature,
            });
        }
        case actions.SET_FIELDS: {
            return Object.freeze({
                ...state,
                fields: action.fields,
            });
        }
        default:
            return state;
    }
};
