import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Accordion, model as accordionModel, selectors as accordionSelectors } from "~/accordion";
import { Button, DialogBox, Loader } from "~/core";
import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";

import { picklistNames, selectors as picklistSelectors } from "~/core/picklist";

import { formatEventStripText } from "../../common";
import { messages as statusMessages } from "../../../../common/status-messages";

import { BatchRecEventStrip } from "../../../../common/accordion/batch-rec-event-strip";
import {
    RecEventItem,
    REC_EVENT_HEIGHT,
} from "../../../../common/accordion/rec-event-accordion-item";
import { BATCH_TEMPLATE_FIELD_GUID } from "~/recs-events/model";
import { EventDetails } from "~/recs-events/events/models/event-details";
import { AgEventSummary } from "~/recs-events/events/models/event-summary";

import * as selectors from "../selectors";

import "./batch-events-list.css";
import { SearchAPI } from "@ai360/core";
import { AccordionItem } from "~/accordion/model";
import { IField } from "~/accordion/interfaces";
import { errorCodeMessages } from "~/i18n-error-messages";

import { messages as recsEventsMessages } from "~/recs-events";

const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;

interface IProps {
    accordionProps: any;
    fieldGuidToEventDetails: Immutable.OrderedMap<string, EventDetails>;
    fieldGuidToEventListMap: Immutable.OrderedMap<string, AgEventSummary[]>;
    isLoading: boolean;
    lastEventBatchFieldGuid: string;
    onBack: () => void;
    onCancel: () => void;
    onSave: () => void;
    onSetCurrentBatchFieldGuid: (fieldGuid: string) => void;
    intl: any;
    picklistOptionsCroppingSeason: any[];
    saveEventDetailsErrorCodeList: number[];
}

interface IState {
    displayErrors: boolean;
}

export const createBatchEventAccordionItems = (
    fields: SearchAPI.IFieldResult[] | IField[]
): AccordionItem[] => {
    return fields.map((field) => {
        return new accordionModel.AccordionItem(REC_EVENT_HEIGHT, false, field);
    });
};

export class BatchEventsList_ extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            displayErrors: false,
        };
    }

    private getErrorMessage(errCode: number, isPlural = false) {
        const { formatMessage } = this.props.intl;
        if (errCode in errorCodeMessages) {
            return formatMessage(errorCodeMessages[errCode]);
        }
        return formatMessage(recsEventsMessages.validationErrorMsg, {
            errCode,
            count: isPlural ? 0 : 1,
        });
    }

    UNSAFE_componentWillReceiveProps(newProps: IProps) {
        if (newProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList) {
            this.setState({ displayErrors: true });
        }
    }

    private getEventStripItem(
        eventDetails: EventDetails,
        eventSummary: AgEventSummary,
        field: SearchAPI.IFieldResult,
        fieldGuid: string
    ) {
        const { picklistOptionsCroppingSeason } = this.props;
        const croppingSeasonOption = picklistOptionsCroppingSeason.find(
            (option) => option.value === eventDetails.croppingSeasonGuid
        );
        const displayText = formatEventStripText(eventDetails, croppingSeasonOption?.label);
        const itemProps = {
            isSelected: fieldGuid === this.props.lastEventBatchFieldGuid,
            displayName: displayText,
            summary: [field.customerName, field.farmName, field.name]
                .filter((v) => v && v !== "")
                .join(", "),
            statusMessageArg: undefined,
            statusMessageId: undefined,
        };
        if (eventDetails.isSamplingEvent) {
            const agEventModel = this.getSampleAgEventFromEventDetails(eventDetails);
            const eventId = agEventModel.eventId;
            const totalPoints = agEventModel.samplePoints.length;
            itemProps.statusMessageArg = {
                ...eventSummary,
                fieldGuid: eventDetails.fieldGuid,
                statusCode: 0,
                isBatch: true,
                eventId,
                totalPoints,
            };
            itemProps.statusMessageId = statusMessages.eventStatusSampling;
        }
        return <RecEventItem {...itemProps} />;
    }

    private getAccordionItemEl = ({ key, style, accordionItem }) => {
        const { fieldGuidToEventListMap, fieldGuidToEventDetails, onSetCurrentBatchFieldGuid } =
            this.props;
        const { id: fieldGuid } = accordionItem.payload;
        const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
        const eventSummary = fieldGuidToEventListMap.get(fieldGuid)[0];
        const onClick = () => onSetCurrentBatchFieldGuid(fieldGuid);

        return (
            <div key={key} style={style} className="accordion-item" onClick={onClick}>
                {this.getEventStripItem(
                    eventDetails,
                    eventSummary,
                    accordionItem.payload,
                    fieldGuid
                )}
            </div>
        );
    };

    private getSampleAgEventFromEventDetails(eventDetails) {
        const area = eventDetails.eventAreaList[0];
        const { agEventModel } = area.agEventList[0];
        return agEventModel;
    }

    private getNonSamplingEvents(allEventDetails: EventDetails[]) {
        const eventsOfType = allEventDetails
            .map((d) => ({
                ...d,
                events: d.agEventTypeList.filter(
                    (t) =>
                        t.agEventTransactionTypeName === "Planting" ||
                        t.agEventTransactionTypeName === "Harvest" ||
                        t.agEventTransactionTypeName === "Application" ||
                        t.agEventTransactionTypeName === "Irrigation" ||
                        t.agEventTransactionTypeName === "Tillage"
                ),
            }))
            .filter((item) => item.events.length > 0);
        return eventsOfType;
    }

    private getTissueSamplingEvents(allEventDetails: EventDetails[]) {
        const eventsOfType = allEventDetails
            .filter((a) => a.fieldGuid !== BATCH_TEMPLATE_FIELD_GUID)
            .map((d) => ({
                ...d,
                events: d.agEventTypeList.filter(
                    (t) => t.agEventTransactionTypeName === "Sampling - Tissue"
                ),
            }))
            .filter((item) => item.events.length > 0);
        return eventsOfType;
    }

    render(): JSX.Element {
        const {
            accordionProps,
            isLoading,
            onBack,
            onCancel,
            onSave,
            saveEventDetailsErrorCodeList,
        } = this.props;

        const { formatMessage } = this.props.intl;

        if (saveEventDetailsErrorCodeList.length > 0 && this.state.displayErrors) {
            const errorMessageDivs = saveEventDetailsErrorCodeList.map((errCode) => (
                <div key={errCode}>
                    {this.getErrorMessage(
                        errCode,
                        saveEventDetailsErrorCodeList && saveEventDetailsErrorCodeList.length > 1
                    )}
                </div>
            ));

            return (
                <DialogBox
                    className="error-message-dialog-box"
                    title={formatMessage(recsEventsMessages.validationDlgTitle)}
                    isOpen
                    onClose={() => this.setState({ displayErrors: false })}
                >
                    {errorMessageDivs}
                </DialogBox>
            );
        }

        accordionProps.getAccordionItemEl = this.getAccordionItemEl;
        const allEventDetails = [...this.props.fieldGuidToEventDetails.values()];

        const nonSamplingEvents = this.getNonSamplingEvents(allEventDetails);
        const tissueSamplingEvents = this.getTissueSamplingEvents(allEventDetails);

        const nonSamplingFieldsWithSomeEmptyZones = nonSamplingEvents.filter((a) =>
            a.eventAreaList.some((b) => b.applyEventToArea === false)
        );
        const nonSamplingFieldsWithAllEmptyZones = nonSamplingEvents.filter((a) =>
            a.eventAreaList.every((b) => b.applyEventToArea === false)
        );

        let enableSave = true;

        if (nonSamplingEvents.length > 0) {
            enableSave = nonSamplingEvents.every((d) =>
                d.eventAreaList.every((a) =>
                    a.agEventList.every((m) => m.agEventModel.isAllRequiredFieldsSet)
                )
            );

            enableSave = nonSamplingFieldsWithSomeEmptyZones.every((d) =>
                d.eventAreaList
                    .filter((x) => x.applyEventToArea === true)
                    .every((a) => a.agEventList.every((m) => m.agEventModel.isAllRequiredFieldsSet))
            );

            if (nonSamplingFieldsWithAllEmptyZones.length > 0) {
                enableSave = false;
            }
        }

        if (tissueSamplingEvents.length > 0) {
            enableSave = tissueSamplingEvents.every((d) =>
                d.eventAreaList.every((a) =>
                    a.agEventList.every((m) => m.agEventModel.isAllRequiredFieldsSet)
                )
            );
        }

        return (
            <div className="rec-event-batch-list">
                {!isLoading ? null : <Loader />}
                <div className="rec-event-info">
                    <BatchRecEventStrip />
                </div>
                <div className="rec-event-batch-list-accordion">
                    <Accordion {...accordionProps} />
                </div>
                <div className="save-cancel-btns">
                    <Button type="back" onClick={onBack} />
                    <Button type="save" onClick={onSave} disabled={!enableSave} />
                    <Button type="cancel" onClick={onCancel} />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onSetCurrentBatchFieldGuid: (fieldGuid) =>
        dispatch(recsEventsActions.setCurrentBatchFieldGuid(fieldGuid)),
});

const mapStateToProps = (state) => {
    const { fieldGuidToEventDetails, saveEventDetailsErrorCodeList } =
        eventsSelectors.getModuleState(state);
    console.assert(fieldGuidToEventDetails.size > 1);

    const fieldGuidToEventListMap = eventsSelectors.getFieldGuidToEventListMap(state);

    const accordionState = selectors.getAccordionState(state);

    const flatIdxMapSelector = accordionSelectors.flatIdxMapSelector(accordionState);
    const dimIdxMapSelector = accordionSelectors.dimIdxMapSelector(accordionState);
    const lastEventBatchFieldGuid = eventsSelectors.getLastEventBatchFieldGuid(state);

    const accordionProps = {
        itemCount: accordionState.recItemCount,
        totalHeight: accordionState.recHeight,
        getAccordionItemFromFlatIdx: (idx) => flatIdxMapSelector(idx),
        getAccordionItemFromDimIdx: (dimIdx) => dimIdxMapSelector(dimIdx),
    };

    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );

    return {
        accordionProps,
        fieldGuidToEventDetails,
        fieldGuidToEventListMap,
        lastEventBatchFieldGuid,
        picklistOptionsCroppingSeason,
        saveEventDetailsErrorCodeList,
    };
};

export const BatchEventsList = connect(mapStateToProps, mapDispatchToProps)(BatchEventsList_);
