import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { getIsLoading } from "~/action-panel/components/import-module/components/import-wizard/selectors";

import * as actions from "../../../actions";
import { getIgnoreFarm } from "../../../selectors";
import { AddEditNameMatchModal_ } from "../components/add-edit-name-match-modal";
import { getUser, getTheUserLockCustomer } from "~/login/selectors";

const mapStateToProps = (state) => ({
    ignoreFarm: getIgnoreFarm(state),
    isLoading: getIsLoading(state),
    isLockCustomer: getTheUserLockCustomer(state),
    userGuid: getUser(state).userGuid,
});

const mapDispatchToProps = (dispatch) => ({
    setIsLoading: (isLoading) => dispatch(actions.setIsLoading(isLoading)),
});
export const AddEditNameMatchModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AddEditNameMatchModal_));
