import React from "react";
import { connect } from "react-redux";
import { InjectedIntl, injectIntl } from "react-intl";
import { DialogBox, DialogBoxFooterType } from "~/core";
import * as analysisActions from "../analysis-info/actions";
import { messages } from "../i18n-messages";

interface IAnalysisDeleteDialogBoxProps {
    closeConfirmDialog: () => void;
    intl: InjectedIntl;
    isConfirmationModalOpen: boolean;
    onDeleteSingle: () => void;
}

const AnalysisDeleteDialogBox = (props: IAnalysisDeleteDialogBoxProps): JSX.Element => {
    const { closeConfirmDialog, isConfirmationModalOpen, intl, onDeleteSingle } = props;
    const { formatMessage } = intl;
    return (
        <DialogBox
            draggable
            footerType={DialogBoxFooterType.YES_NO}
            isOpen={isConfirmationModalOpen}
            onAction={() => onDeleteSingle()}
            onClose={() => closeConfirmDialog()}
            title={formatMessage(messages.confirmDeleteTitle)}
        >
            {formatMessage(messages.confirmDeleteMsg)}
        </DialogBox>
    );
};
const mapStateToProps = (state, props: Partial<IAnalysisDeleteDialogBoxProps>) => ({
    ...props,
});

const mapDispatchToProps = (dispatch) => ({
    onShowAnalysisLayer: (analysisInfoGuid) =>
        dispatch(analysisActions.showAnalysisInfo(analysisInfoGuid)),
    onDeleteAnalysisLayer: (analysisLayerGuid, fieldGuid, layerType) =>
        dispatch(analysisActions.deleteAnalysisLayer(analysisLayerGuid, fieldGuid, layerType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnalysisDeleteDialogBox));
