import { connect } from "react-redux";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { actions as picklistActions } from "~/core/picklist";
import { withMasked } from "~/hocs";
import { getTheUserGuid } from "~/login";
import { actions as notificationActions } from "~/notifications";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";

import { fetchUserCompanyList, setCompanyUserPreference } from "../batch-zapper/data/actions";
import { getLastUsedOwnerGuid, getUserCompanyList } from "../batch-zapper/data/selectors";
import { fetchFilteredLocationList } from "../surface-defaults/data/actions";
import { getFilteredLocationList } from "../surface-defaults/data/selectors";

import { getDropdowns, getPicklists } from "../../selectors";
import AdminScriptsView from "./admin-scripts";
import { dropdowns, picklists } from "./data/services";

const mapStateToProps = (state) => ({
    ...getDropdowns(Object.keys(dropdowns), state),
    ...getPicklists(picklists, state),
    filteredLocationList: getFilteredLocationList(state),
    lastUsedOwnerGuid: getLastUsedOwnerGuid(state),
    loggedInUserGuid: getTheUserGuid(state),
    userCompanyList: getUserCompanyList(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchDropdownData: (payload) => dispatch(fetchDropdownData(payload || dropdowns)),
    fetchFilteredLocationList: (userGuid) => fetchFilteredLocationList(userGuid),
    fetchPicklists: (payload) => dispatch(picklistActions.fetchPicklistData(payload || picklists)),
    fetchUserCompanyList: () => fetchUserCompanyList(),
    onApiCallErr: (error) => dispatch(notificationActions.apiCallError(error)),
    onPushToasterMessage: (message: any, type: any) =>
        dispatch(notificationActions.pushToasterMessage(message, type)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    setCompanyUserPreference: (companyGuid) => dispatch(setCompanyUserPreference(companyGuid)),
});

export default withMasked(connect(mapStateToProps, mapDispatchToProps)(AdminScriptsView));
