export const PROPS_ACTIVE_ONLY = "activeOnly";
export const PROPS_CUSTOMER_NAME = "customerName";
export const PROPS_EMAIL_ADDRESS_LIST = "emailAddressList";
export const PROPS_FARM_NAME = "farmName";
export const PROPS_FIELD_GUID = "fieldGuid";
export const PROPS_FIELD_GUID_LIST = "fieldGuidList";
export const PROPS_FIELD_NAME = "fieldName";
export const PROPS_FILE_EXISTS = "fileExists";
export const PROPS_REPORT_DATE = "reportDate";
export const PROPS_REPORT_EMAIL = "reportEmail";
export const PROPS_REPORT_OBJECT_LIST = "reportObjectList";
export const PROPS_REPORT_FILTER = "reportFilter";
export const PROPS_REPORT_GUID = "reportGuid";
export const PROPS_REPORT_LINK = "reportLink";
export const PROPS_REPORT_PAGE_OPTIONS = "reportPageOptions";
export const PROPS_REPORT_SEASON = "reportSeason";
export const PROPS_REPORT_SORT_LIST = "reportSortList";
export const PROPS_REPORT_STATUS = "reportStatus";
export const PROPS_REPORT_STATUS_TEXT = "reportStatusText";
export const PROPS_REPORT_TYPE_NAME = "reportTypeName";
export const PROPS_REPORT_USER_GUID = "reportUserGuid";
