import React, { Component } from "react";

import { initializeApp } from "firebase/app";
import { initializePerformance } from "firebase/performance";
import { initializeAnalytics } from "firebase/analytics";

import SlidingPanel from "~/sliding-panel/sliding-panel";
import viewResolver from "~/utils/routing/view-resolver";

import { ActionPanel } from "./action-panel";
import { ErrorBoundary } from "./error-boundary";
import { Header } from "./header/components/header";
import { MapContainer } from "./map";
import { NotificationContainer } from "./notifications";
import { ThemeRoot } from "./theme";

import "./root-component.css";

export class RootComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentNameCode: null,
            childNameCode: null,
            slidingPanelProps: {},
        };
        try {
            const firebaseIsConfigured =
                window.process_env.FB_API_KEY && window.process_env.FB_API_KEY !== "%FB_API_KEY%";
            if (firebaseIsConfigured) {
                const firebaseConfig = {
                    apiKey: window.process_env.FB_API_KEY,
                    authDomain: window.process_env.FB_AUTH_DOMAIN,
                    databaseURL: window.process_env.FB_DB_URL,
                    projectId: window.process_env.FB_PROJECT_ID,
                    storageBucket: window.process_env.FB_STORAGE_BUCKET,
                    messagingSenderId: window.process_env.FB_MESSAGING_SENDER_ID,
                    appId: window.process_env.FB_APP_ID,
                    measurementId: window.process_env.FB_MEASUREMENT_ID,
                };

                const firebaseApp = initializeApp(firebaseConfig);
                initializePerformance(firebaseApp);
                initializeAnalytics(firebaseApp);
            } else {
                console.warn("Firebase is not configured");
            }
        } catch (err) {
            console.error("Error initializing Firebase: ", err);
        }
    }

    closeSlidingPanel = () => {
        this.setState({
            parentNameCode: null,
            childNameCode: null,
            slidingPanelProps: {},
        });
    };

    loadDetailPage = (childNameCode, parentNameCode, slidingPanelProps) => {
        this.setState({ childNameCode, parentNameCode, slidingPanelProps });
    };

    renderSlidingPanel = (parentNameCode = null, childNameCode = null, slidingPanelProps = {}) => {
        if (parentNameCode) {
            const { withSlidingPanel, component } = viewResolver(
                this.state.parentNameCode,
                this.state.childNameCode
            );
            if (withSlidingPanel) {
                return React.createElement(component, {
                    closeSlidingPanel: this.closeSlidingPanel,
                    ...slidingPanelProps,
                });
            }
            return (
                <SlidingPanel
                    close={this.closeSlidingPanel}
                    navigateTo={{ parentNameCode, childNameCode }}
                    {...slidingPanelProps}
                />
            );
        }
    };

    render() {
        const { parentNameCode, childNameCode, slidingPanelProps } = this.state;
        return (
            <ThemeRoot className="root-component" hideBackground>
                <ErrorBoundary>
                    <Header
                        loadDetailPage={this.loadDetailPage}
                        closeSidePanel={this.closeSlidingPanel}
                    />
                    <div className="main-content-container">
                        <ActionPanel loadDetailPage={this.loadDetailPage} />
                        <MapContainer />
                        {this.renderSlidingPanel(parentNameCode, childNameCode, slidingPanelProps)}
                    </div>
                </ErrorBoundary>
                <NotificationContainer />
            </ThemeRoot>
        );
    }
}
