import PropTypes from "prop-types";
import { model } from "~/admin/agBytes/picklist/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_CAN_DELETE]: PropTypes.string,
    [model.PROPS_PICKLIST_ID]: PropTypes.string,
    [model.PROPS_PICKLIST_GUID]: PropTypes.string,
    [model.PROPS_PICKLIST_VALUE_GUID]: PropTypes.string,
    [model.PROPS_PICKLIST_NAME]: PropTypes.string,
    [model.PROPS_PICKLIST_VALUE_ID]: PropTypes.string,
    [model.PROPS_PICKLIST_VALUE]: PropTypes.string,
    [model.PROPS_PICKLIST_CATEGORY]: PropTypes.string,
    [model.PROPS_PICKLIST_PARENT_ID]: PropTypes.string,
    [model.PROPS_PICKLIST_PARENT_VALUE]: PropTypes.string,
    [model.PROPS_PICKLIST_LOGIC]: PropTypes.bool,
    [model.PROPS_PICKLIST_LOGIC_TYPE]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
};

export default PropTypes.shape(shape);
