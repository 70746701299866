import React, { Component } from "react";
import { connect } from "react-redux";
import {
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    PicklistSelectInput,
    ZeroToInfiniteGrid,
    SelectInput,
} from "~/core";
import { PICKLIST_SEASON_FILTER_TYPE } from "~/core/picklist/picklist-names";
import { withEditableGrid } from "~/hocs";
import { IWithEditableGridProps } from "~/hocs/interfaces";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";

import { messages } from "../i18n-messages";
import { PreferenceAPI } from "@ai360/core";
import "./season-filter.css";

// populate layer type options
import { getEventTypeInfoList } from "~/recs-events/events/selectors";
import { getNewableRecTypeOptions } from "~/recs-events/recs/selectors";
import { getUser } from "~/login";
import { ANALYSIS_INFO_NAME_PROFIT_LOSS } from "~/recs-events/analysis/model";

interface ISeasonFilterProps {
    formatMessage: (key: string, params?: any) => null;
    eventTypeList?: any[];
    recTypeOptions?: any[];
    analysisTypeList?: any[];
}

class SeasonFilter extends Component<
    ISeasonFilterProps & IWithEditableGridProps<PreferenceAPI.ISeasonFilter>
> {
    static PROP_TARGET_TYPE_LABEL = "targetType";
    static PROP_TARGET_TYPE_GUID = "targetTypeGuid";
    static PROP_TARGET_TYPE_CATEGORY = "targetTypeGroup";
    static PROP_FILTER_TYPE_LABEL = "filterType";
    static PROP_FILTER_TYPE_GUID = "filterTypeGuid";

    static keysToUpdate = [
        SeasonFilter.PROP_TARGET_TYPE_LABEL,
        SeasonFilter.PROP_FILTER_TYPE_LABEL,
        SeasonFilter.PROP_TARGET_TYPE_GUID,
        SeasonFilter.PROP_TARGET_TYPE_CATEGORY,
        SeasonFilter.PROP_FILTER_TYPE_GUID,
    ];

    static getLayerTypeKey(item: PreferenceAPI.ISeasonFilter) {
        if (!item.targetTypeGroup) {
            return "";
        } else {
            return `${item.targetTypeGroup},${item.targetTypeGuid || ""}`;
        }
    }

    onEdit(record, index) {
        this.props.onEdit({
            record,
            index,
            keysToUpdate: SeasonFilter.keysToUpdate,
        });
    }
    onDelete(record, index) {
        this.props.onDelete({ index });
    }
    onAdd() {
        this.props.onAdd();
    }

    isAddMode(): boolean {
        return this.props.isEditing && this.props.editingIndex === null;
    }

    isPicklistLoaded(): boolean {
        return (
            this.props.eventTypeList.length > 0 ||
            this.props.recTypeOptions.length > 0 ||
            this.props.analysisTypeList.length > 0
        );
    }

    getTargetTypeOptions(): any[] {
        const { eventTypeList, recTypeOptions, analysisTypeList } = this.props;
        return [
            { label: "All Layer Types", value: "All," },
            ...recTypeOptions.map((rec) => ({
                label: rec.label,
                value: `Rec,${rec.value.recTypeGuid}`,
            })),
            ...eventTypeList.map((event) => ({
                label: event.agEventTransactionTypeName,
                value: `Event,${event.sampleTypeGuid || event.agEventTransactionTypeGuid}`,
            })),
            ...analysisTypeList
                .filter((layer) => layer.name === ANALYSIS_INFO_NAME_PROFIT_LOSS)
                .map((layer) => ({
                    label: layer.name,
                    value: `Layer,${layer.analysisLayerTypeGuid}`,
                })),
        ];
    }

    getFilteredLayerTypes(rawTypeList: any[]): any[] {
        const { record, editingIndex } = this.props;

        if (editingIndex === null) {
            const layersInUse = record.map((row) => SeasonFilter.getLayerTypeKey(row));
            return rawTypeList.filter((opt) => !layersInUse.includes(opt.value));
        } else {
            return rawTypeList;
        }
    }

    #renderAddEditModal() {
        if (!this.isPicklistLoaded()) {
            return null;
        }

        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;

        const layerTypeOptions = this.getTargetTypeOptions();

        return (
            <DialogBox
                className="crop-yield-dialog"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={!editData.targetType || !editData.filterTypeGuid}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    isEditing,
                    formatMessage,
                    messages.seasonFilterSingular
                )}
            >
                <SelectInput
                    autoFocus
                    clearable={false}
                    openOnFocus={false}
                    value={SeasonFilter.getLayerTypeKey(editData)}
                    options={this.getFilteredLayerTypes(layerTypeOptions)}
                    required
                    placeholderText={formatMessage(messages.seasonLayerType)}
                    onChange={(value) => {
                        const selectedItem = layerTypeOptions.find((c) => c.value === value);
                        const splitValue = value.split(",");
                        onChange({
                            [SeasonFilter.PROP_TARGET_TYPE_CATEGORY]: splitValue[0],
                            [SeasonFilter.PROP_TARGET_TYPE_GUID]: !splitValue[1]
                                ? null
                                : splitValue[1],
                            [SeasonFilter.PROP_TARGET_TYPE_LABEL]: selectedItem
                                ? selectedItem.label
                                : "",
                        });
                    }}
                />

                <PicklistSelectInput
                    picklistName={PICKLIST_SEASON_FILTER_TYPE}
                    required
                    placeholderText={formatMessage(messages.seasonFilterOption)}
                    value={editData.filterTypeGuid}
                    onChange={(value, label) => {
                        onChange({
                            [SeasonFilter.PROP_FILTER_TYPE_LABEL]: label,
                            [SeasonFilter.PROP_FILTER_TYPE_GUID]: value,
                        });
                    }}
                />
            </DialogBox>
        );
    }

    public render() {
        const { record, renderDeleteModal, formatMessage } = this.props;
        const hasRows = record && record.length > 0;

        return (
            <div className="setup-season-filter">
                {hasRows && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [SeasonFilter.PROP_TARGET_TYPE_LABEL]: {
                                title: formatMessage(messages.seasonLayerType),
                                className: "target-type",
                            },
                            [SeasonFilter.PROP_FILTER_TYPE_LABEL]: {
                                title: formatMessage(messages.seasonFilterOption),
                                className: "filter-type",
                            },
                        }}
                        className={"season-filter-grid"} // cell-stretch
                        onEdit={this.onEdit.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        showHeader={true}
                    />
                )}
                {this.#renderAddEditModal()}
                {renderDeleteModal()}
                {this.isPicklistLoaded() && (
                    <div className={`add-link-container ${hasRows ? "" : "no-table"}`}>
                        <NoLink
                            className={"add-link"}
                            label={createAddLinkLabelText(
                                formatMessage,
                                messages.seasonFilterSingular
                            )}
                            onClick={this.onAdd.bind(this)}
                        ></NoLink>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const userInfo = getUser(state);

    const recTypeOptions = getNewableRecTypeOptions(state);

    return {
        analysisTypeList: userInfo.layerTypesAccess || [],
        recTypeOptions,
        eventTypeList: getEventTypeInfoList(state),
    };
};

export default withEditableGrid(connect(mapStateToProps, null)(SeasonFilter));
