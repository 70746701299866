import { service } from "./service";
import { actions as defaultActions } from "./controller";

import * as interfaces from "./interfaces";
import * as model from "./model";
import { setupReportBooksSaga } from "./sagas";
import * as customActions from "./actions";
import * as selectors from "./selectors";

const actions = {
    ...defaultActions,
    ...customActions,
};

export { actions, interfaces, model, selectors, setupReportBooksSaga, service };
