import * as model from "./model";
import { createService } from "~/hocs/crud";
import { aggregateUrlGenerator } from "@ai360/core";

import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
import {
    PICKLIST_TRAIT_CHARACTERISTICS,
    PICKLIST_TRAIT_SPECIFICS,
    PICKLIST_DEVELOPER,
    getPickListCode,
} from "~/core/picklist/picklist-names";

const AGGREGATE_NAME = "gene";
const ENDPOINT_NAME = "agbytes/gene";
const API_VERSION = "v1.0";
const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const URL = aggregateUrl("list");
export const GETRECORD = aggregateUrl();
export const NEXT_ID = aggregateUrl("nextId");
export const CREATE = aggregateUrl();
export const UPDATE = aggregateUrl();
export const DELETE = aggregateUrl();
export const EXPORT_FILE_NAME = "GeneExport";
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const SELECT_ALL = aggregateUrl("selectAll");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "geneFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "geneSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "genePageOptions";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        geneId: "",
        geneName: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            FieldName: "Name",
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "Name",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultGene = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_GENE_ID]: null,
    [model.PROPS_GENE_NAME]: "",
    [model.PROPS_DEVELOPER_NAMES]: [],
    [model.PROPS_TRAIT_CHARACTERISTIC_NAMES]: [],
    [model.PROPS_TRAIT_SPECIFIC_NAMES]: [],
});

export const pickLists = {
    [PICKLIST_TRAIT_CHARACTERISTICS]: getPickListCode(PICKLIST_TRAIT_CHARACTERISTICS),
    [PICKLIST_TRAIT_SPECIFICS]: getPickListCode(PICKLIST_TRAIT_SPECIFICS),
    [PICKLIST_DEVELOPER]: getPickListCode(PICKLIST_DEVELOPER),
};

export const service = createService({
    id: model.PROPS_GENE_ID,
    guid: model.PROPS_GENE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    activeColumnName: model.PROPS_ACTIVE_YN,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_GENE_ID]: { label: "geneId", gridCol: 10 },
        [model.PROPS_GENE_NAME]: { label: "geneName", gridCol: 20 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
    },
    getDefaultRecord: () => Object.assign({}, defaultGene()),
    defaultSort,
    useNewApi: true,
});
