import React, { ChangeEvent, CSSProperties } from "react";
import reactCSS from "reactcss";

import ColorWrap from "react-color/lib/components/common/ColorWrap";
import Raised from "react-color/lib/components/common/Raised";
import Alpha from "react-color/lib/components/common/Alpha";
import ChromePointer from "react-color/lib/components/chrome/ChromePointer";
import CompactColor from "react-color/lib/components/compact/CompactColor";
import CompactFields from "react-color/lib/components/compact/CompactFields";
import color from "react-color/lib/helpers/color";

interface ICompactColorTransparencyProps {
    onChange?: (data: { hex?: string; source?: string }, evt: ChangeEvent) => void;
    onSwatchHover?: () => void;
    colors?: string[];
    hex?: string;
    rgb?: Record<string, any>;
    defaultView?: "hex" | "rgb" | "hsl";
    disableAlpha?: boolean;
    styles?: Record<string, any>;
    transparencyLabel?: string;
}

const CompactColorTransparency_ = (colorProps: ICompactColorTransparencyProps) => {
    const {
        onChange,
        onSwatchHover,
        colors,
        disableAlpha,
        hex,
        rgb,
        styles: passedStyles = {},
        transparencyLabel,
    } = colorProps;

    const styles = reactCSS(
        {
            default: {
                color: {
                    width: "22px",
                },
                alpha: {
                    height: "10px",
                    position: "relative",
                    background: "#ffffff",
                    border: "1px solid #ffffff",
                    borderRadius: "4px",
                    display: "block",
                },
                Alpha: {
                    backgroundColor: "#ffffff",
                },
                Compact: {
                    background: "#f6f6f6",
                    radius: "4px",
                },
                compact: {
                    paddingTop: "5px",
                    paddingLeft: "5px",
                    boxSizing: "initial",
                    width: "240px",
                },
                clear: {
                    clear: "both",
                    marginBottom: "5px",
                },
                hue: {
                    marginBottom: "0px",
                },
                swatch: {
                    width: "10px",
                    height: "10px",
                    marginTop: "0px",
                },
            },
            disableAlpha: {
                color: {
                    width: "22px",
                },
                alpha: {
                    height: "10px",
                    position: "relative",
                    background: "#ffffff",
                    border: "1px solid #ffffff",
                    borderRadius: "4px",
                    display: "none",
                },
                Alpha: {
                    backgroundColor: "#ffffff",
                },
                Compact: {
                    background: "#f6f6f6",
                    radius: "4px",
                },
                compact: {
                    paddingTop: "5px",
                    paddingLeft: "5px",
                    boxSizing: "initial",
                    width: "240px",
                },
                clear: {
                    clear: "both",
                    marginBottom: "5px",
                },
                hue: {
                    marginBottom: "0px",
                },
                swatch: {
                    width: "10px",
                    height: "10px",
                    marginTop: "0px",
                },
            },
            ...passedStyles,
        },
        { disableAlpha }
    );

    const handleChange = (data, e) => {
        if (data.hex) {
            color.isValidHex(data.hex) &&
                onChange(
                    {
                        hex: data.hex,
                        source: "hex",
                    },
                    e
                );
        } else {
            onChange(data, e);
        }
    };

    return (
        <Raised style={styles.Compact} styles={passedStyles}>
            <div style={styles.compact as CSSProperties} className={"compact-transparency-picker"}>
                <div className="compact-swatches">
                    {colors.map((c) => (
                        <CompactColor
                            key={c}
                            color={c}
                            active={c.toLowerCase() === hex}
                            onClick={handleChange}
                            onSwatchHover={onSwatchHover}
                        />
                    ))}
                    <div style={styles.clear as CSSProperties} />
                </div>
                <div>{transparencyLabel}</div>
                <div style={styles.alpha as CSSProperties} className="compact-transparent-slider">
                    <Alpha {...colorProps} pointer={ChromePointer} onChange={onChange} />
                </div>
                <div style={styles.clear as CSSProperties} />
                <div style={styles.clear as CSSProperties} />
                <div className="compact-swatches">
                    <div style={styles.clear as CSSProperties} />
                </div>
                <CompactFields hex={hex} rgb={rgb} onChange={handleChange} />
            </div>
        </Raised>
    );
};

CompactColorTransparency_.defaultProps = {
    colors: [
        "#4D4D4D",
        "#999999",
        "#FFFFFF",
        "#F44E3B",
        "#FE9200",
        "#FCDC00",
        "#DBDF00",
        "#A4DD00",
        "#68CCCA",
        "#73D8FF",
        "#AEA1FF",
        "#FDA1FF",
        "#333333",
        "#808080",
        "#cccccc",
        "#D33115",
        "#E27300",
        "#FCC400",
        "#B0BC00",
        "#68BC00",
        "#16A5A5",
        "#009CE0",
        "#7B64FF",
        "#FA28FF",
        "#000000",
        "#666666",
        "#B3B3B3",
        "#9F0500",
        "#C45100",
        "#FB9E00",
        "#808900",
        "#194D33",
        "#0C797D",
        "#0062B1",
        "#653294",
        "#AB149E",
    ],
    disableAlpha: false,
    styles: {},
};

export const CompactColorTransparency = ColorWrap(CompactColorTransparency_);
