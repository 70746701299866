import { model as accordionModel } from "~/accordion";
import { IField } from "~/accordion/interfaces";
import { AccordionItem } from "~/accordion/model";

export const FIELD_HEIGHT = 56;

export interface IStatusMessageArg {
    eventId?: string;
    fieldGuid?: string;
    hasSoilSampleResults?: boolean;
    isBatch?: boolean;
    isWaterSample?: boolean;
    statusCode: string;
    recTypeGuid?: string;
    totalPoints?: number;
}

export const createFieldAccordionItems = (
    fields: IField[],
    expandedGuids: Set<string>
): AccordionItem[] =>
    fields.map(
        (field) =>
            new accordionModel.AccordionItem(
                FIELD_HEIGHT,
                expandedGuids.has(field.fieldGuid),
                field
            )
    );

export const getFieldGuidFromDimIdx = (accordionItems: AccordionItem[], dimIdx: number[]): string =>
    accordionModel.getItem(accordionItems, dimIdx).payload.fieldGuid;
