import { call, put, select } from "redux-saga/effects";
import { getModelNameToState } from "./../utils";
import { defaultActions } from "./../actions";

import { actionsConstants } from "./../actions";
import { setApiResult } from "~/core/api/actions";
import { AppHelpers, UserAPI, Request, APIError } from "@ai360/core";
import { CommandStatus } from "~/utils/api/new-api";
// selectors
import { getTheUserGuid } from "~/login";
import { actions as notificationActions } from "~/notifications";

let importFile = null; //stores the file info between validity check and import

//Imports
export function* importPackageRequest(action, service) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: importFile };
    try {
        yield call(Request.post, service.urls.IMPORT_PACKAGE_URL, requestOptions);
        yield put({
            type: `${actionsConstants.fetch}_${service.modelName.toUpperCase()}`,
        });
    } catch (error) {
        yield put(defaultActions.failed());
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}

export function* importPlantBackRestrictionRequest(action, service) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: importFile };
    try {
        yield call(Request.post, service.urls.IMPORT_PLANT_BACK_RESTRICTION_URL, requestOptions);
        yield put({
            type: `${actionsConstants.fetch}_${service.modelName.toUpperCase()}`,
        });
    } catch (error) {
        yield put(defaultActions.failed());
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}

export function* importRequest(action, service) {
    const { useNewApi } = service;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = !useNewApi
        ? { UserGuid: UserGuid, Model: importFile }
        : { loggedInUserGuid: UserGuid, base64: importFile };
    try {
        yield call(Request.post, service.urls.IMPORT_URL, requestOptions);

        yield put({
            type: `${actionsConstants.fetch}_${service.modelName.toUpperCase()}`,
        });
    } catch (error) {
        yield put(defaultActions.failed());
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}

//Validity Checks
export function* importValidPackageRequest(action, service) {
    importFile = null;
    const UserGuid = yield select(getTheUserGuid);
    let arr = yield call(AppHelpers.processFileDialog, action.payload);
    const requestOptions = { UserGuid: UserGuid, Model: arr[0] };
    const modelName = getModelNameToState(service.modelName);
    try {
        const response = yield call(
            Request.post,
            service.urls.IMPORT_VALID_PACKAGE_URL,
            requestOptions
        );
        importFile = arr[0];
        yield put(setApiResult({ model: response }));
        yield put(defaultActions.success({ model: modelName, data: {} }));
    } catch (error) {
        if (error instanceof APIError) {
            error.setErrorFileName(service.getImportErrorsFileName());
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(defaultActions.failed());
    }
}

export function* importValidPlantBackRestrictionRequest(action, service) {
    importFile = null;
    const UserGuid = yield select(getTheUserGuid);
    let arr = yield call(AppHelpers.processFileDialog, action.payload);
    const requestOptions = { UserGuid: UserGuid, Model: arr[0] };
    const modelName = getModelNameToState(service.modelName);
    try {
        const response = yield call(
            Request.post,
            service.urls.IMPORT_VALID_PLANT_BACK_RESTRICTION_URL,
            requestOptions
        );
        importFile = arr[0];
        yield put(setApiResult({ model: response }));
        yield put(defaultActions.success({ model: modelName, data: {} }));
    } catch (error) {
        if (error instanceof APIError) {
            error.setErrorFileName(service.getImportErrorsFileName());
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(defaultActions.failed());
    }
}

export function* importValidRequest(action, service) {
    importFile = null;
    const UserGuid = yield select(getTheUserGuid);
    let arr = yield call(AppHelpers.processFileDialog, action.payload);

    const modelName = getModelNameToState(service.modelName);
    if (service.useNewApi) {
        const requestOptions = { base64: arr[0] };

        try {
            importFile = arr[0];
            const response = yield call(
                Request.post,
                service.urls.IMPORT_VALID_URL,
                requestOptions
            );

            yield put(setApiResult({ validateImportResults: response }));
            yield put(defaultActions.success({ model: modelName, data: {} }));
        } catch (error) {
            if (error instanceof APIError) {
                error.setErrorFileName(service.getImportErrorsFileName());
                if (typeof error.apiResultObj.status !== "undefined") {
                    error.apiResultObj.status === CommandStatus.Accepted
                        ? yield put(
                              setApiResult({
                                  validateImportResults: error.apiResultObj,
                              })
                          )
                        : yield put(
                              setApiResult({
                                  newApiResults: error.apiResultObj,
                              })
                          );
                } else {
                    yield put(setApiResult(error));
                }
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(defaultActions.failed());
        }
    } else {
        const requestOptions = { UserGuid: UserGuid, Model: arr[0] };

        try {
            const response = yield call(
                Request.post,
                service.urls.IMPORT_VALID_URL,
                requestOptions
            );
            importFile = arr[0];

            yield put(setApiResult({ model: response }));
            yield put(defaultActions.success({ model: modelName, data: {} }));
        } catch (error) {
            if (error instanceof APIError) {
                error.setErrorFileName(service.getImportErrorsFileName());
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(defaultActions.failed());
        }
    }
}

export function* saveAgrianConversionData(action, service) {
    const UserGuid = yield select(getTheUserGuid);
    const { date, time } = action.payload;
    try {
        yield call(
            UserAPI.saveUserPreference,
            UserGuid,
            UserAPI.UserPreferenceKeys.agrianLastConversionDate,
            date
        );
        yield call(
            UserAPI.saveUserPreference,
            UserGuid,
            UserAPI.UserPreferenceKeys.agrianLastConversionTime,
            time
        );
        yield put(
            defaultActions.setAgrianConversionData({
                agrianLastConversionDate: date,
                agrianLastConversionTime: time,
            })
        );
    } catch (error) {
        if (error instanceof APIError) {
            error.setErrorFileName(service.getImportErrorsFileName());
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(defaultActions.failed());
    }
}

export function* getAgrianConversionData(action, service) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const modelName = getModelNameToState(service.modelName);
        const agrianLastConversionDate = (yield call(
            UserAPI.getUserPreferenceByKey,
            UserGuid,
            UserAPI.UserPreferenceKeys.agrianLastConversionDate
        )).value;
        const agrianLastConversionTime = (yield call(
            UserAPI.getUserPreferenceByKey,
            UserGuid,
            UserAPI.UserPreferenceKeys.agrianLastConversionTime
        )).value;
        yield put(
            defaultActions.setAgrianConversionData({
                agrianLastConversionDate,
                agrianLastConversionTime,
            })
        );
        yield put(defaultActions.success({ model: modelName, data: {} }));
    } catch (error) {
        if (error instanceof APIError) {
            error.setErrorFileName(service.getImportErrorsFileName());
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(defaultActions.failed());
    }
}
