import React, { Component } from "react";
import PropTypes from "prop-types";
import { withCrud, withMasked } from "~/hocs";
import { injectIntl, intlShape } from "react-intl";
import { service, actions } from "./data";
import { preventBubbleUp } from "~/admin/utils";
import { messages } from "~/admin/agBytes/i18n-messages";
import { DataTable, Button } from "~/core";
import { nestedGridContainer } from "~/hocs";
import SlidingPanel from "~/sliding-panel/sliding-panel";
// Components
import AddEditPanel from "./add-edit/add-edit-container";

class EquationList_ extends Component {
    static propTypes = {
        actions: PropTypes.object,
        addEditPanel: PropTypes.object,
        closeSidePanel: PropTypes.func,
        deleteSelected: PropTypes.func,
        equationGroupType: PropTypes.string,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        onRowSelection: PropTypes.func,
        onSubmit: PropTypes.func,
        showInactiveProp: PropTypes.bool,
        testEquationSuccess: PropTypes.func,
        toggleActiveInactive: PropTypes.func,
        validCropList: PropTypes.bool,
        validEquationResponse: PropTypes.object,
        validateCropListToggle: PropTypes.func,
        validateEquationToggle: PropTypes.func,
    };

    static defaultProps = {
        showInactiveProp: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isEquationValidated: false,
            showInactive: false,
        };
        this.cropValid = false;
        this.equationValid = false;
        if (this.props.equationGroupType === "Planting") {
            service.toggleColumnVisibility("equationName", false);
            service.toggleColumnVisibility("nutrientElement", false);
            service.toggleColumnVisibility("description", true);
        } else {
            service.toggleColumnVisibility("equationName", true);
            service.toggleColumnVisibility("nutrientElement", true);
            service.toggleColumnVisibility("description", false);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.validEquationResponse &&
            nextProps.validEquationResponse.isValid !== this.props.validEquationResponse.isValid
        ) {
            this.equationValid = nextProps.validEquationResponse.isValid;
        }
        if (
            nextProps.showInactiveProp !== this.state.showInactive &&
            this.props.toggleActiveInactive
        ) {
            this.setState({
                showInactive: nextProps.showInactiveProp,
            });
            this.props.toggleActiveInactive();
        }
    }

    toggleEquationValidState = (flag = false) => {
        if (this.props.validateEquationToggle) {
            this.props.validateEquationToggle(flag);
            this.props.testEquationSuccess("0");
            this.equationValid = flag;
        }
    };

    onSave = () => {
        if (this.props.onSubmit) {
            this.props.validateCropListToggle();
            this.toggleEquationValidState();
            this.props.onSubmit();
        }
    };

    onCancel = () => {
        if (this.props.closeSidePanel) {
            this.props.validateCropListToggle();
            this.toggleEquationValidState();
            this.props.closeSidePanel();
        }
    };

    render() {
        const { showAddEditPanel } = this.props.addEditPanel;
        const { toggleEquationValidState } = this;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                toggleEquationValidState,
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        const isPlanting = this.props.equationGroupType === "Planting";
        const enableSave = isPlanting
            ? this.equationValid && this.props.validCropList
            : this.equationValid;
        return (
            <div>
                <DataTable
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    onRowSelection={this.props.onRowSelection}
                    {...this.props}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "123-child",
                            }}
                        >
                            <Button
                                forceSubmit
                                type="save"
                                onClick={this.onSave}
                                disabled={!enableSave}
                            />
                            <Button type="cancel" onClick={this.onCancel} />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}
export const EquationList = injectIntl(
    nestedGridContainer(withMasked(withCrud(EquationList_, service, actions)), service.modelName)
);
