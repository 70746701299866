import { all, call, put, select, take, takeLatest } from "redux-saga/effects";

import { actions as accordionActions } from "~/accordion";

import { createLayerAccordionItems } from "../layer-list/layer-accordion/layer-item";

import { LayerAPI, LayerUtilsAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login";

import * as actions from "./actions";

import * as layerListActions from "../layer-list/actions";
import * as layerSelectors from "../layer-list/selectors";

import * as actionPanelActions from "~/action-panel/actions";

import { mapToolsActions } from "~/map";
import { Toolset } from "@ai360/core";

import * as selectors from "./selectors";

import * as layerModuleActions from "../../actions";

import { actions as notificationActions } from "~/notifications";
import { PICKLIST_CROPPING_SEASON, getPickListCode } from "~/core/picklist/picklist-names";
import { actions as picklistActions } from "~/core/picklist";

export const pickLists = {
    [PICKLIST_CROPPING_SEASON]: getPickListCode(PICKLIST_CROPPING_SEASON),
};

const onShowImageryLayer = function* (action) {
    yield put(actions.setImageryDetailsLoading(true));
    const { surfaceInfo, fieldGuid } = action.payload;

    yield put(layerModuleActions.setActivePage(layerModuleActions.LayerModulePages.IMAGERY_LAYER));
    yield put(actions.fetchImageryLayer(surfaceInfo.imageryLayerGuid));
    if (surfaceInfo) {
        yield put(
            layerListActions.setVisibleSurface(
                fieldGuid,
                LayerUtilsAPI.getSlimSurfaceInfo(surfaceInfo)
            )
        );
    }
    yield put(actionPanelActions.setIsEnabled(false));
};

const onFetchImageryLayer = function* (action) {
    const { imageryLayerGuid } = action.payload;
    if (!imageryLayerGuid) {
        yield put(actions.setImageryLayerSummary({}));
        return;
    }
    const userGuid = yield select(getTheUserGuid);
    try {
        yield put(picklistActions.fetchPicklistData(pickLists));
        yield take(picklistActions.fetchedPicklistData);
        const imageryLayerSummary = yield call(
            LayerAPI.getImageryLayer,
            userGuid,
            imageryLayerGuid
        );
        yield put(actions.setImageryLayerSummary(imageryLayerSummary));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setImageryDetailsLoading(false));
    }
};

const onDeleteImageryLayer = function* (action) {
    const { imageryLayerGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    yield call(LayerAPI.deleteImageryLayer, imageryLayerGuid, userGuid);

    const layerInfos = yield select(layerSelectors.getLayerInfos);
    const accordionId = yield select(layerSelectors.getAccordionId);
    const items = yield select(layerSelectors.getAccordionItems);

    if (layerInfos) {
        for (const [fieldGuid, layers] of layerInfos.entries()) {
            if (imageryLayerGuid && imageryLayerGuid !== "") {
                const layerIdx = layers.findIndex((li) => li.imageryLayerGuid === imageryLayerGuid);
                yield put(layerListActions.removeVisibleSurfaces([fieldGuid]));
                if (layerIdx >= 0) {
                    layers.splice(layerIdx, 1);
                }
            }
            const idx = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
            if (idx > -1) {
                yield put(
                    accordionActions.updateAccordionItem(accordionId, [idx], {
                        children: createLayerAccordionItems(layers),
                    })
                );
            }
            yield put(layerListActions.setLayerInfo(fieldGuid, layers));
        }
    }
};

const onCloseImageryLayer = function* () {
    yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
    yield put(actionPanelActions.setIsEnabled(true));
    yield put(layerModuleActions.setActivePage(layerModuleActions.LayerModulePages.LAYER_LIST));
};

const onSaveImageryLayer = function* () {
    yield put(actions.setImageryDetailsLoading(true));
    const imageryLayerSummary = yield select(selectors.getImageryLayerSummary);

    const userGuid = yield select(getTheUserGuid);
    const { imageryLayerGuid, name, croppingSeasonGuid, imageDate, isDefaultImagery } =
        imageryLayerSummary;
    try {
        yield call(
            LayerAPI.updateImageryLayer,
            {
                imageryLayerGuid,
                name,
                croppingSeasonGuid,
                imageDate,
                isDefaultImagery,
            },
            userGuid
        );
        const layerInfos = yield select(layerSelectors.getLayerInfos);
        yield call(_updateLayerInfos, layerInfos, imageryLayerSummary);
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    } finally {
        yield put(actions.setImageryDetailsLoading(false));
        yield put(actions.closeImageryLayer());
    }
};

const _updateLayerInfos = function* (
    layerInfos: Map<string, LayerAPI.ILayer[]>,
    imageryLayerSummary
) {
    const { imageryLayerGuid, name, croppingSeasonName, imageDate, isDefaultImagery } =
        imageryLayerSummary;
    const accordionId = yield select(layerSelectors.getAccordionId);
    const items = yield select(layerSelectors.getAccordionItems);

    for (const [fieldGuid, layers] of layerInfos.entries()) {
        const layerIdx = layers.findIndex((li) => li.imageryLayerGuid === imageryLayerGuid);

        if (layerIdx === -1) {
            continue;
        }

        Object.entries(layers).forEach(([key, value]) => {
            //sets customImageryDefault to false for all imagery layers in field
            if (value.layerType === "Imagery") {
                const imageryLayer = {
                    ...layers[key],
                    isDefaultImagery: false,
                };
                layers.splice(Number(key), 1, imageryLayer);
            }
        });

        const updatedImageryLayer = {
            ...layers[layerIdx],
            displayName: `Imagery - ${croppingSeasonName} - ${imageDate}`,
            isDefaultImagery: isDefaultImagery, //updates customImageryDefault to new value for the edited imagery layer
            subLayers: [
                ...layers[layerIdx].subLayers.map((sl) => ({
                    ...sl,
                    displayName: name,
                })),
            ],
        };
        layers.splice(layerIdx, 1, updatedImageryLayer);

        const idx = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);

        if (idx > -1) {
            yield put(
                accordionActions.updateAccordionItem(accordionId, [idx], {
                    children: createLayerAccordionItems(layers),
                })
            );
        }
        yield put(layerListActions.setLayerInfo(fieldGuid, layers));
    }
};

export const imagerySaga = function* () {
    yield all([
        takeLatest(actions.CLOSE_IMAGERY_LAYER, onCloseImageryLayer),
        takeLatest(actions.DELETE_IMAGERY_LAYER, onDeleteImageryLayer),
        takeLatest(actions.FETCH_IMAGERY_LAYER, onFetchImageryLayer),
        takeLatest(actions.SAVE_IMAGERY_LAYER, onSaveImageryLayer),
        takeLatest(actions.SHOW_IMAGERY_LAYER, onShowImageryLayer),
    ]);
};
