import React, { Component } from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions";

import { FilesPanel } from "./containers/files-panel";
import { FieldsPanel } from "./containers/fields-panel";
import { EventsPanel } from "./containers/events-panel";
import { FieldBoundaryPanel } from "./containers/field-boundary-panel";

import "./import-wizard-panel.css";

export class ImportWizardPanel extends Component {
    static propTypes = {
        panelType: PropTypes.oneOf(
            Object.keys(actions.PanelTypes).map((k) => actions.PanelTypes[k])
        ),
        canSubmit: PropTypes.bool.isRequired,
        updateCanSubmit: PropTypes.func.isRequired,
        canContinue: PropTypes.bool.isRequired,
        updateCanContinue: PropTypes.func.isRequired,
        loadDetailPage: PropTypes.func,
        importType: PropTypes.object,
    };

    render() {
        const { importType, panelType } = this.props;

        let activePanel = null;

        switch (panelType) {
            case actions.PanelTypes.FILES_PANEL:
                activePanel = (
                    <FilesPanel
                        canContinue={this.props.canContinue}
                        updateCanContinue={(c) => this.props.updateCanContinue(c)}
                        loadDetailPage={this.props.loadDetailPage}
                        importType={importType}
                    />
                );
                break;
            case actions.PanelTypes.FIELDS_PANEL:
                activePanel = (
                    <FieldsPanel
                        canContinue={this.props.canContinue}
                        loadDetailPage={this.props.loadDetailPage}
                        updateCanContinue={(c) => this.props.updateCanContinue(c)}
                    />
                );
                break;
            case actions.PanelTypes.EVENTS_PANEL:
                activePanel = (
                    <EventsPanel
                        canSubmit={this.props.canSubmit}
                        updateCanSubmit={(c) => this.props.updateCanSubmit(c)}
                    />
                );
                break;
            case actions.PanelTypes.FIELD_BOUNDARY_PANEL:
                activePanel = (
                    <FieldBoundaryPanel
                        canSubmit={this.props.canSubmit}
                        updateCanSubmit={(c) => this.props.updateCanSubmit(c)}
                        loadDetailPage={this.props.loadDetailPage}
                    />
                );
                break;
            default:
                break;
        }

        return <div className="import-wizard-panel">{activePanel}</div>;
    }
}
