import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { SortDirection } from "react-virtualized";

import { messages as actionMessages } from "~/action-panel/i18n-messages";
import {
    ColumnDataType,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    LoaderTypes,
    Pane,
    SimpleTable,
    Tabs,
} from "~/core";
import { getTheUserGuid } from "~/login";
import { AgEventAPI, RecAPI } from "@ai360/core";
import { IFormatter } from "@ai360/core";
import { messages } from "../../i18n-messages";
import { IRecord } from "~/core/components/tables/interfaces";

export interface ILoadZonesModalProps {
    fieldBoundaryGuid?: string;
    intl?: IFormatter;
    isOpen?: boolean;
    onAction?(e): void;
    onClose?(): void;
    userGuid: string;
}
export interface ILoadZonesModalState {
    events: Record<string, any>[];
    isLoading: boolean;
    recs: Record<string, any>[];
    selectedRow: IRecord;
    tabIdx: number;
}

class LoadZonesModal_ extends Component<ILoadZonesModalProps, ILoadZonesModalState> {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            isLoading: false,
            recs: [],
            selectedRow: null,
            tabIdx: 0,
        };
    }

    _getPastZones(fieldBoundaryGuid) {
        this.setState({ isLoading: true }, () => {
            const promises = [
                AgEventAPI.getPastZones(fieldBoundaryGuid),
                RecAPI.getPastZones(fieldBoundaryGuid),
            ];
            Promise.all(promises).then(([eventPastZones, recPastZones]) =>
                this.setState({
                    events: eventPastZones.map((e) => ({
                        agEventGeneralGuid: e.generalGuid,
                        date: e.dateString,
                        name: e.name,
                        type: e.type,
                    })),
                    isLoading: false,
                    recs: recPastZones.map((e) => ({
                        date: e.dateString,
                        name: e.name,
                        recGeneralGuid: e.generalGuid,
                        type: e.type,
                    })),
                    selectedRow: null,
                })
            );
        });
    }

    _onAction() {
        const { selectedRow } = this.state;
        this.props.onAction(selectedRow);
    }

    _table(tab, records) {
        const { formatMessage } = this.props.intl;
        const columns = [
            {
                dataKey: "name",
                label: formatMessage(messages.nameLbl),
                width: tab === 0 ? 150 : 125,
            },
            {
                dataKey: "date",
                label: formatMessage(messages.dateLbl),
                width: 100,
                type: ColumnDataType.DATE_TIME,
            },
            {
                dataKey: "type",
                label: formatMessage(messages.typeLbl),
                width: tab === 0 ? 150 : 175,
            },
        ];
        return (
            <SimpleTable
                key={tab}
                height={250}
                width={400}
                headerHeight={30}
                rowHeight={25}
                columns={columns}
                onSelectRow={(selectedRow) => this.setState({ selectedRow })}
                records={records}
                sortBy="date"
                sortDirection={SortDirection.DESC}
            />
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { fieldBoundaryGuid } = nextProps;
        if (fieldBoundaryGuid && this.props.fieldBoundaryGuid !== fieldBoundaryGuid) {
            this._getPastZones(fieldBoundaryGuid);
        } else if (!fieldBoundaryGuid) {
            this.setState({
                events: [],
                isLoading: false,
                selectedRow: null,
                recs: [],
            });
        }
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        const { events, isLoading, recs, selectedRow, tabIdx } = this.state;

        return (
            <DialogBox
                draggable
                action={formatMessage(messages.loadLbl)}
                actionDisabled={selectedRow == null}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                isOpen={isOpen}
                onAction={() => this._onAction()}
                onClose={() => onClose()}
                title={formatMessage(messages.loadZonesLbl)}
            >
                <Tabs
                    selected={tabIdx}
                    onTabSelect={(tabIdx) => this.setState({ tabIdx, selectedRow: null })}
                >
                    <Pane label={formatMessage(actionMessages.events)}>
                        <br />
                        {this._table(0, events)}
                    </Pane>
                    <Pane label={formatMessage(actionMessages.recs)}>
                        <br />
                        {this._table(1, recs)}
                    </Pane>
                </Tabs>
                {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
            </DialogBox>
        );
    }
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export const LoadZonesModal = connect(mapStateToProps, {}, mergeProps)(injectIntl(LoadZonesModal_));
