import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <Fragment>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </Fragment>
            );
        }
        return this.props.children;
    }
}
