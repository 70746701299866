import { ACTION_PANEL_STATE_KEY } from "../../../../selectors";
import { LAYER_MODULE_STATE_KEY } from "../../selectors";

export const NON_FIELD_FEATURE_INFORMATION_STATE_KEY = "NON_FIELD_FEATURE_INFORMATION_STATE";

const focusedState = (state) =>
    state[ACTION_PANEL_STATE_KEY][LAYER_MODULE_STATE_KEY][NON_FIELD_FEATURE_INFORMATION_STATE_KEY];

export const getIsEditing = (state) => {
    return focusedState(state).isEditing;
};

export const getActiveFeature = (state) => {
    return focusedState(state).activeFeature;
};

export const getActiveCustomer = (state) => {
    return focusedState(state).activeCustomer;
};

export const getFields = (state) => {
    return focusedState(state).fields;
};
