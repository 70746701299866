import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as constants from "../constants";
import * as actions from "../actions";
import { getFileMetaData } from "../selectors";
import { service } from "../service";
import { getTheUserGuid } from "~/login/selectors";
import { getDropdown } from "../../../../selectors";
import { Request } from "@ai360/core";
import { processEditImportTemplateResponse, getParsedFileMetadata } from "./common";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { actions as notificationActions } from "~/notifications";
import * as model from "../model";

function getMonitorTypeRequest({ importTemplateList }) {
    return importTemplateList ? importTemplateList.map((obj) => obj.templateGuid) : [];
}

export function* importTemplateDataSuccess(gridData) {
    yield put(
        actions.setTemplateGridData({
            ...gridData[0],
        })
    );
    yield put(
        fetchDropdownData({
            [constants.SAMPLE_ATTRIBUTES]: {
                url: service.urls.GET_SAMPLE_ATTRIBUTES_URL,
                model: gridData[0].importTypeGuid,
            },
        })
    );
    yield put(actions.getTemplateGridSuccess());
}

export function* processImportTemplate({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = {
        UserGuid,
        Model: payload,
    };
    try {
        const importTemplateData = yield call(
            Request.post,
            service.urls.GET_IMPORT_TEMPLATE_URL,
            requestOptions
        );
        const gridData = yield fetchExistingGridData([
            importTemplateData.importTemplateList[0].templateGuid,
        ]);
        const { importTypeGuid, templateName } = importTemplateData.importTemplateList[0];
        const state = yield select();
        // TODO - Modify the getDropdown selector to accept state as the first parameter
        const importType = getDropdown(constants.IMPORT_TYPES, state);
        const selectedImportType = importType.find((item) => item.guid === importTypeGuid);

        yield put(
            actions.updateFileType({
                fileType: {
                    label: selectedImportType ? selectedImportType.name : "",
                    guid: selectedImportType ? selectedImportType.guid : "",
                },
            })
        );
        const importTemplate = yield select(
            (state) => getFileMetaData(state).importTemplateList[0]
        );
        const updatedImportTemplate = model.ImportTemplateFactory.createImportTemplateList(
            importTemplate,
            { ...importTemplateData.importTemplateList[0] }
        );
        yield put(
            actions.updateTemplate({
                key: model.PROPS_IMPORT_TEMPLATE_LIST,
                value: new Array(updatedImportTemplate),
            })
        );
        yield put(
            actions.updateTemplate({
                key: model.PROPS_IMPORT_TYPE_GUID,
                value: importTypeGuid,
            })
        );
        yield put(
            actions.updateTemplate({
                key: model.PROPS_TEMPLATE_NAME,
                value: templateName,
            })
        );
        yield put(actions.getImportTemplateSuccess());
        yield put(
            actions.setFileMetadata({
                ...importTemplateData.importTemplateList[0],
            })
        );
        yield importTemplateDataSuccess(gridData);
    } catch (error) {
        yield put(notificationActions.apiCallError(error, actions.getTemplateGridData));
        yield put(actions.getImportTemplateFailed(error));
    }
}

export function* processImportControllerTemplate({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = {
        UserGuid,
        Model: payload,
    };
    try {
        const importTemplateData = yield call(
            Request.post,
            service.urls.GET_IMPORT_CONTROLLER_TEMPLATE_URL,
            requestOptions
        );
        const gridData = yield fetchExistingGridData(getMonitorTypeRequest(importTemplateData));
        const { importTypeGuid, importZipTemplateGuid, templateName } = importTemplateData;

        yield put(
            actions.updateTemplate({
                key: model.PROPS_IMPORT_TYPE_GUID,
                value: importTypeGuid,
            })
        );
        yield put(
            actions.updateTemplate({
                key: model.PROPS_IMPORT_ZIP_TEMPLATE_GUID,
                value: importZipTemplateGuid,
            })
        );
        yield put(
            actions.updateTemplate({
                key: model.PROPS_TEMPLATE_NAME,
                value: templateName,
            })
        );

        const state = yield select();
        const importType = getDropdown(constants.IMPORT_TYPES, state);
        const selectedImportType = importType.find((item) => item.guid === importTypeGuid);
        yield put(
            actions.updateFileType({
                fileType: {
                    label: selectedImportType ? selectedImportType.name : "",
                    guid: selectedImportType ? selectedImportType.guid : "",
                },
            })
        );
        const importTemplate = yield select(
            (state) => getFileMetaData(state).importTemplateList[0]
        );
        const updatedImportTemplateArray = [];
        for (let i = 0; i < importTemplateData.importTemplateList.length; i++) {
            updatedImportTemplateArray.push(
                model.ImportTemplateFactory.createImportTemplateList(importTemplate, {
                    ...importTemplateData.importTemplateList[i],
                })
            );
        }
        yield put(
            actions.updateTemplate({
                key: model.PROPS_IMPORT_TEMPLATE_LIST,
                value: updatedImportTemplateArray,
            })
        );
        yield put(actions.getImportTemplateSuccess());
        yield put(
            actions.setFileMetadata({
                ...importTemplateData,
                ...getParsedFileMetadata(importTemplateData.importTemplateList),
            })
        );
        yield put(actions.setIsMonitorFileFlag(true));
        yield processEditImportTemplateResponse(gridData, [
            constants.TEMPLATE_TYPE_PLANTING,
            constants.TEMPLATE_TYPE_APPLICATION,
            constants.TEMPLATE_TYPE_HARVEST,
        ]);
        yield put(actions.getImportControllerTemplateSuccess());
    } catch (error) {
        yield put(notificationActions.apiCallError(error, actions.getImportTemplate));
        yield put(actions.getImportControllerTemplateFailed());
    }
}

export function* fetchExistingGridData(importFilePayload) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = {
        UserGuid,
        Model: importFilePayload,
    };
    try {
        return yield call(
            Request.post,
            service.urls.GET_EXISTING_TEMPLATE_GRID_DATA_BY_TEMPLATE_GUID_URL,
            requestOptions
        );
    } catch (error) {
        yield put(notificationActions.apiCallError(error, actions.getTemplateGridData));
        yield put(actions.getImportTemplateFailed(error));
    }
}

function* watchImportFileTemplate() {
    yield takeEvery(actions.getImportTemplate, processImportTemplate);
    yield takeEvery(actions.getImportControllerTemplate, processImportControllerTemplate);
}

const UpdateSagas = function* () {
    yield all([fork(watchImportFileTemplate)]);
};

export default UpdateSagas;
