import { createAction, types } from "redux-act";

// Constants
const AUTO_SEARCH = "AUTO_SEARCH";
const AUTO_SEARCH_SUCCESS = "AUTO_SEARCH_SUCCESS";
const CONVERT_AGIIS = "CONVERT_AGIIS";
const CONVERT_AGRIAN = "CONVERT_AGRIAN";
const CREATE = "CREATE_REQUEST";
const CREATE_SUCCESS = "CREATE_SUCCESS";
const DELETE = "DELETE_REQUEST";
const EXPORT = "EXPORT_REQUEST";
const EXPORT_PACKAGE = "EXPORT_PACKAGE_REQUEST";
const EXPORT_PLANT_BACK_RESTRICTION = "EXPORT_PLANT_BACK_RESTRICTION_REQUEST";
const FAILURE = "FAILURE";
const FETCH = "FETCH_REQUEST";
const FETCH_RECORD = "FETCH_RECORD";
const FETCH_RECORD_SUCCESS = "FETCH_RECORD_SUCCESS";
const GET_AGRIAN_CONVERSION_DATA = "GET_AGRIAN_CONVERSION_DATA";
const GET_NEXT_ID = "GET_NEXT_ID";
const IMPORT = "IMPORT";
const IMPORT_PACKAGE = "IMPORT_PACKAGE";
const IMPORT_PLANT_BACK_RESTRICTION = "IMPORT_PLANT_BACK_RESTRICTION";
const IMPORT_VALID = "IMPORT_VALID";
const IMPORT_VALID_PACKAGE = "IMPORT_VALID_PACKAGE";
const IMPORT_VALID_PLANT_BACK_RESTRICTION = "IMPORT_VALID_PLANT_BACK_RESTRICTION";
const NESTED_AUTO_SEARCH = "NESTED_AUTO_SEARCH";
const NESTED_GRID_SUCCESS = "NESTED_GRID_SUCCESS";
const NEXT_ID_SUCCESS = "GET_NEXT_ID_SUCCESS";
const SAVE_AGRIAN_CONVERSION_DATA = "SAVE_AGRIAN_CONVERSION_DATA";
const SELECT_ALL = "SELECT_ALL";
const SELECT_ALL_SUCCESS = "SELECT_ALL_SUCCESS";
const SET_AGRIAN_CONVERSION_DATA = "SET_AGRIAN_CONVERSION_DATA_SUCCESS";
const SUCCESS = "SUCCESS";
const UPDATE = "UPDATE";
const VIEW_LOG = "VIEW_LOG_REQUEST";

// Default Actions Constants
export const actionsConstants = {
    autoSearchList: AUTO_SEARCH,
    autoSearchSuccess: AUTO_SEARCH_SUCCESS,
    convertAgiisData: CONVERT_AGIIS,
    convertAgrianData: CONVERT_AGRIAN,
    create: CREATE,
    createSuccess: CREATE_SUCCESS,
    deleteRequest: DELETE,
    exportData: EXPORT,
    exportPackageData: EXPORT_PACKAGE,
    exportPlantBackRestrictionData: EXPORT_PLANT_BACK_RESTRICTION,
    failed: FAILURE,
    fetch: FETCH,
    fetchRecord: FETCH_RECORD,
    fetchRecordSuccess: FETCH_RECORD_SUCCESS,
    getAgrianConversionData: GET_AGRIAN_CONVERSION_DATA,
    getNextId: GET_NEXT_ID,
    importData: IMPORT,
    importPackageData: IMPORT_PACKAGE,
    importPlantBackRestrictionData: IMPORT_PLANT_BACK_RESTRICTION,
    importValidData: IMPORT_VALID,
    importValidPackageData: IMPORT_VALID_PACKAGE,
    importValidPlantBackRestrictionData: IMPORT_VALID_PLANT_BACK_RESTRICTION,
    nestedAutoSearch: NESTED_AUTO_SEARCH,
    nestedGridsuccess: NESTED_GRID_SUCCESS,
    nextIdSuccess: NEXT_ID_SUCCESS,
    saveAgrianConversionData: SAVE_AGRIAN_CONVERSION_DATA,
    selectAll: SELECT_ALL,
    selectAllSuccess: SELECT_ALL_SUCCESS,
    setAgrianConversionData: SET_AGRIAN_CONVERSION_DATA,
    success: SUCCESS,
    update: UPDATE,
    viewLogData: VIEW_LOG,
};

// Default Actions - TODO: Handle it and log the payload
export const defaultActions = {
    autoSearchList: createAction(actionsConstants.autoSearchList),
    autoSearchSuccess: createAction(actionsConstants.autoSearchSuccess),
    convertAgiisData: createAction(actionsConstants.convertAgiisData),
    convertAgrianData: createAction(actionsConstants.convertAgrianData),
    create: createAction(actionsConstants.create),
    createSuccess: createAction(actionsConstants.createSuccess),
    deleteRequest: createAction(actionsConstants.deleteRequest),
    exportData: createAction(actionsConstants.exportData),
    exportPackageData: createAction(actionsConstants.exportPackageData),
    exportPlantBackRestrictionData: createAction(actionsConstants.exportPlantBackRestrictionData),
    failed: createAction(actionsConstants.failed),
    fetch: createAction(actionsConstants.fetch),
    fetchRecord: createAction(actionsConstants.fetchRecord),
    fetchRecordSuccess: createAction(actionsConstants.fetchRecordSuccess),
    getAgrianConversionData: createAction(actionsConstants.getAgrianConversionData),
    getNextId: createAction(actionsConstants.getNextId),
    importData: createAction(actionsConstants.importData),
    importPackageData: createAction(actionsConstants.importPackageData),
    importPlantBackRestrictionData: createAction(actionsConstants.importPlantBackRestrictionData),
    importValidData: createAction(actionsConstants.importValidData),
    importValidPackageData: createAction(actionsConstants.importValidPackageData),
    importValidPlantBackRestrictionData: createAction(
        actionsConstants.importValidPlantBackRestrictionData
    ),
    nestedAutoSearch: createAction(actionsConstants.nestedAutoSearch),
    nestedGridsuccess: createAction(actionsConstants.nestedGridsuccess),
    nextIdSuccess: createAction(actionsConstants.nextIdSuccess),
    saveAgrianConversionData: createAction(actionsConstants.saveAgrianConversionData),
    selectAll: createAction(actionsConstants.selectAll),
    selectAllSuccess: createAction(actionsConstants.selectAllSuccess),
    setAgrianConversionData: createAction(actionsConstants.setAgrianConversionData),
    success: createAction(actionsConstants.success),
    update: createAction(actionsConstants.update),
    viewLogData: createAction(actionsConstants.viewLogData),
};

/**
 * create actions for the parameter service object
 * @param {object} service - it's an object generated using createService method
 */
export const prepActions = (service) => {
    if (!service || !service.modelName) {
        return defaultActions;
    }
    // Else modify the action strings
    const modelName = service.modelName.toUpperCase();
    let result = {};
    const genericActions = [
        defaultActions.autoSearchSuccess,
        defaultActions.createSuccess,
        defaultActions.failed,
        defaultActions.fetchRecordSuccess,
        defaultActions.nestedAutoSearch,
        defaultActions.nestedGridsuccess,
        defaultActions.nextIdSuccess,
        defaultActions.success,
    ];

    // TODO: Convert it to reduce implementation
    for (let action of Object.keys(defaultActions)) {
        let newAction = action;
        if (genericActions.indexOf(action)) {
            const ACTION_STRING = `${actionsConstants[action]}_${modelName}`;
            if (!types.has(ACTION_STRING)) {
                newAction = { [action]: createAction(ACTION_STRING) };
            }
        }
        result = Object.assign({}, result, newAction);
    }
    return result;
};
