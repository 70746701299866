import * as model from "./model";

const ownerDefaultRecord = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_ADMIN_LOGIN]: "",
    [model.PROPS_AGBYTES_ADMIN_EMAIL_LIST]: [],
    [model.PROPS_AGVANCE_API_URL]: "",
    [model.PROPS_AGVANCE_DATABASE_ID]: "",
    [model.PROPS_AGVANCE_USER_ID]: "",
    [model.PROPS_AGVANCE_USER_PASSWORD]: "",
    [model.PROPS_COMPANY_GUID]: "",
    [model.PROPS_COMPANY_ID]: "",
    [model.PROPS_COMPANY_LOCATION_ID]: 0,
    [model.PROPS_COMPANY_LOGO]: "",
    [model.PROPS_COMPANY_TYPE_GUID]: "",
    [model.PROPS_COMPANY_TYPE_LIST]: [],
    [model.PROPS_COMPANY_UI_LABEL_LIST]: [],
    [model.PROPS_COST_CENTER_NUMBER]: 0,
    [model.PROPS_DATA_POOLING]: true,
    [model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT]: true,
    [model.PROPS_ERROR_MESSAGE]: "",
    [model.PROPS_HAS_CHILDREN]: false,
    [model.PROPS_IS_ACTIVE]: true,
    [model.PROPS_MOBILE_COMPANY_ICON]: "",
    [model.PROPS_MOBILE_UI_THEME_GUID]: "",
    [model.PROPS_NOTES]: "",
    [model.PROPS_ORG_LEVEL_ADDRESS_LIST]: [],
    [model.PROPS_ORG_LEVEL_CITY]: "",
    [model.PROPS_ORG_LEVEL_GUID]: "",
    [model.PROPS_ORG_LEVEL_NAME]: "",
    [model.PROPS_ORG_LEVEL_STATE_ABBR]: "",
    [model.PROPS_ORG_LEVEL_URL_LIST]: [],
    [model.PROPS_ORG_TYPE_LIST]: [],
    [model.PROPS_OWNER_PERSONALITY_GUID]: "",
    [model.PROPS_OWNER_PHONE_NUMBER_LIST]: [],
    [model.PROPS_PERSON_ORG_LEVEL_LIST]: [],
    [model.PROPS_RESPONSE_FROM_EMAIL]: "",
    [model.PROPS_SEND_RESULTS_TO_EMAIL_ADDRESS]: "",
    [model.PROPS_SYSTEM_SETTINGS]: {
        [model.PROPS_BRANDING_YN]: false,
        [model.PROPS_PRIVACY_POLICY_TEXT]: "",
        [model.PROPS_PRIVACY_POLICY_TITLE]: "",
    },
    [model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]: [],
    [model.PROPS_THEME_LIST]: [],
    [model.PROPS_UNIT_SYSTEM_GUID]: "",
    [model.PROPS_WEB_COMPANY_ICON]: "",
    [model.PROPS_WEB_UI_THEME_GUID]: "",
    [model.PROPS_REQUIRE_CUSTOMER_SEARCH]: false,
});

export default ownerDefaultRecord;
