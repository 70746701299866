import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const HarvestIcon = withSvgIcon(
    <g>
        <path
            d={
                "M7.7,14.9c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5l0,0C8.8,12.4,7.7,13.5,7.7,14.9z" +
                "M8.7,14.9c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5S8.7,15.7,8.7,14.9C8.7,14.9,8.7,14.9,8.7,14.9z"
            }
        />
        <circle cx="10.2" cy="14.9" r="1" />
        <path
            d={
                "M27.4,13.9c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4c0,0,0,0,0,0" +
                "C28.9,10.5,27.4,12,27.4,13.9z M28.8,13.9c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2s-0.9,2-2,2l0,0C29.7,15.9,28.8,15,28.8,13.9z"
            }
        />
        <circle cx="30.8" cy="13.9" r="1.4" />
        <path
            d={
                "M0,2h1.6l0.6-0.9h25.4v0.3H6.6c-0.4,1-0.7,2-0.9,3h19.9l-1.5,1.3H5.4c0,0.3,0,0.5,0,0.8" +
                "c0.1,1.9,0.4,3.7,0.7,5.6h2.3l0.2,0.4c1.3-0.9,3-0.5,3.9,0.8c0.1,0.1,0.1,0.2,0.2,0.3l3.2-1.5h11.6c1-1.8,3.2-2.5,5.1-1.6" +
                "c1.4,0.8,2.2,2.4,1.9,4h1.2v2.3h11.5l-7.8-4h-1.9l-2.7-1.5L37.8,0H1.5L0,2z M33.8,7.9L35.2,1h2l-1.9,6.9H33.8z M29.4,1h5.2l-1.2,6.9" +
                "h-3.1L29.4,1z"
            }
        />
    </g>,
    "5 -10 40 40",
    "event-label-icon"
);
