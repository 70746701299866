import * as models from "./models";
import * as actions from "./actions";
import * as panelActions from "../../actions";
import { initialFieldModel } from "~/action-panel/components/field-module/reducer";

export const importWizardInitialState = Object.freeze({
    apiErrorResultList: [],
    appendList: [],
    agvanceFieldList: [],
    agvanceOrgLevelList: [],
    currentFieldData: { ...initialFieldModel },
    convexHulls: {},
    dropdowns: {},
    equipmentFilter: {
        equipmentGuid: null,
        errorCodeLIst: [],
    },
    eventStripPreviewData: [],
    filteredFieldImportFileList: [],
    forceUpdate: false,
    hasSubmitted: false,
    ignoreFarm: false,
    ignoreSelectedField: true,
    importFileGuidList: [],
    importFieldBoundaries: [],
    importFieldList: [],
    importFieldStripData: [],
    importFilterListData: { ...models.importFilterListData },
    importGroomingRequest: { ...models.importGroomingRequest },
    importSamplingPoints: [],
    importTemplate: {},
    importType: {},
    importWizardStep: -1,
    isFilteringVisible: false,
    isFieldMatchingComplete: false,
    isFieldMatchingLoading: false,
    isLoading: false,
    isValidating: false,
    selectedImportFieldIndex: -1,
    selectedMatchedFieldGuid: null,
    showImportPoints: false,
    showProcessing: false,
    successfulNameMatchImports: [],
    userCropImportPreferences: {
        averageUnitWeight: "",
        cropGuid: "",
        userCropImportPreferenceGuid: "",
        userGuid: "",
        yieldUnitIaGuid: "",
    },
});

export const importWizardReducer = (state = importWizardInitialState, action) => {
    switch (action.type) {
        case panelActions.LOAD_IMPORT_WIZARD: {
            const { importFileGuidList, importType, importTemplate } = action.payload;
            return Object.freeze({
                ...state,
                importFileGuidList,
                importTemplate,
                importType,
                importWizardStep: 0,
            });
        }
        case actions.REMOVE_IMPORT_FILES: {
            const { importFileGuidList } = action.payload;
            const newImportFileGuidList = state.importFileGuidList.filter(
                (importFileGuid) =>
                    !importFileGuidList.some((filteredGuid) => filteredGuid === importFileGuid)
            );
            return Object.freeze({
                ...state,
                importFileGuidList: newImportFileGuidList,
                importGroomingRequest: {
                    ...state.importGroomingRequest,
                    importFileGuidList: newImportFileGuidList,
                },
            });
        }
        case actions.RESET_IMPORT_WIZARD: {
            return Object.freeze({
                ...importWizardInitialState,
            });
        }
        case actions.SET_DROPDOWN_DATA:
            return Object.freeze({
                ...state,
                dropdowns: {
                    ...state.dropdowns,
                    ...action.payload,
                },
            });
        case actions.SET_EVENT_STRIP_PREVIEW_DATA:
            return Object.freeze({
                ...state,
                eventStripPreviewData: action.payload.eventStripPreviewData,
                isValidating: action.payload.eventStripPreviewData.some((event) => {
                    return event.status.statusCode === 48 && event.isNameMatch === false;
                }),
                hasSubmitted: action.payload.eventStripPreviewData.some((event) => {
                    return ![0, 5].includes(event.status.statusCode);
                }),
            });
        case actions.SET_API_ERROR_RESULT_LIST:
        case actions.SET_APPEND_LIST:
        case actions.SET_FILTERED_FIELD_IMPORT_FILE_LIST:
        case actions.SET_FORCE_UPDATE:
        case actions.SET_HAS_SUBMITTED:
        case actions.SET_IGNORE_FARM:
        case actions.SET_IGNORE_SELECTED_FIELD:
        case actions.SET_IMPORT_CONVEX_HULLS:
        case actions.SET_IMPORT_FIELD_BOUNDARIES:
        case actions.SET_IMPORT_FIELD_LIST:
        case actions.SET_IMPORT_FIELD_STRIP_DATA:
        case actions.SET_IMPORT_FILTER_LIST_DATA:
        case actions.SET_IMPORT_GROOMING_REQUEST:
        case actions.SET_IMPORT_SAMPLING_POINTS:
        case actions.SET_IMPORT_WIZARD_STEP:
        case actions.SET_IS_FILTERING_VISIBLE:
        case actions.SET_FIELD_MATCHING_COMPLETE:
        case actions.SET_FIELD_MATCHING_LOADING:
        case actions.SET_IS_LOADING:
        case actions.SET_SELECTED_IMPORT_FIELD_INDEX:
        case actions.SET_SELECTED_MATCHED_FIELD_GUID:
        case actions.SET_SHOW_IMPORT_POINTS:
        case actions.SET_SHOW_PROCESSING:
        case actions.SET_SUCCESSFUL_NAME_MATCH_IMPORTS:
        case actions.SET_USER_CROP_IMPORT_PREFERENCES:
        case actions.FETCH_AGVANCE_FIELD_LIST_SUCCEEDED:
        case actions.FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED:
        case actions.SET_CURRENT_FIELD_DATA:
            return Object.freeze({
                ...state,
                ...action.payload,
            });
        case actions.ADD_EQUIPMENT_IMPORT_FILTER_SUCCESS:
            return Object.freeze({
                ...state,
                equipmentFilter: {
                    ...action.payload,
                    errorCodeList: [],
                },
            });
        case actions.ADD_EQUIPMENT_IMPORT_FILTER_FAILURE:
            return Object.freeze({
                ...state,
                equipmentFilter: {
                    equipmentGuid: null,
                    errorCodeList: action.payload.errorCodeList,
                },
            });
        case actions.UPDATE_PROPOSED_EVENT_STATUS: {
            const proposedEvents = [...state.eventStripPreviewData];
            const proposedEventIndex = proposedEvents.findIndex(
                (p) => p.agEventGeneralGuid === action.payload.agEventGeneralGuid
            );
            if (proposedEventIndex === -1) {
                return state;
            }
            const updatedProposedEvent = {
                ...proposedEvents[proposedEventIndex],
                status: action.payload,
            };
            const newEventStripData = [
                ...proposedEvents.slice(0, proposedEventIndex),
                updatedProposedEvent,
                ...proposedEvents.slice(proposedEventIndex + 1),
            ];
            const isNameMatch = proposedEvents[0].isNameMatch;
            if (isNameMatch) {
                if (action.payload.statusCode === 8 || action.payload.statusCode === 28) {
                    newEventStripData.splice(proposedEventIndex, 1);
                }
                const hasSubmitted =
                    newEventStripData.every(
                        (event) => event.status.statusCode === 0 || event.status.statusCode === 5
                    ) || newEventStripData.length === 0;
                return Object.freeze({
                    ...state,
                    eventStripPreviewData: newEventStripData,
                    isValidating: newEventStripData.some((event) => {
                        return event.status.statusCode === 48;
                    }),
                    hasSubmitted,
                    isLoading: !hasSubmitted,
                });
            }
            return Object.freeze({
                ...state,
                eventStripPreviewData: newEventStripData,
                isValidating: newEventStripData.some((event) => {
                    return event.status.statusCode === 48;
                }),
                hasSubmitted: newEventStripData.some((event) => {
                    return ![0, 5].includes(event.status.statusCode);
                }),
            });
        }
        case actions.SET_VALIDATING_STATUS: {
            const proposedEvents = [...state.eventStripPreviewData];
            const newEventStripData = proposedEvents.map((event) => {
                return {
                    ...event,
                    status: { ...event.status, statusCode: 48 },
                };
            });

            return Object.freeze({
                ...state,
                eventStripPreviewData: newEventStripData,
                isValidating: true,
            });
        }
        case actions.SET_NAME_MATCHES_VALIDATING: {
            const proposedEvents = [...state.eventStripPreviewData];
            const newEventStripData = proposedEvents.map((event) => {
                return {
                    ...event,
                    status: event.agEventGeneralGuid
                        ? { ...event.status, statusCode: 48 }
                        : event.status,
                };
            });

            return Object.freeze({
                ...state,
                eventStripPreviewData: newEventStripData,
                isValidating: true,
            });
        }

        default:
            return state;
    }
};
