import React, { Component } from "react";
import { injectIntl, intlShape, defineMessages } from "react-intl";
import { SelectInput, withAsyncOptions } from "~/core";
import "./search-agvance-field.css";

export const messages = defineMessages({
    farm: { id: "searchAgvanceField.farm", defaultMessage: "Farm:" },
    field: { id: "searchAgvanceField.field", defaultMessage: "Field:" },
    fieldNamePlaceholderText: {
        id: "searchAgvanceField.fieldNamePlaceholderText",
        defaultMessage: "Field Name",
    },
    location: {
        id: "searchAgvanceField.location",
        defaultMessage: "Location:",
    },
});

export interface ISearchAgvanceFieldProps {
    errorCodes?: Record<string, any>[];
    intl: intlShape;
    onChange?: (
        name?: Record<string, any>,
        guid?: string,
        fieldId?: string,
        farmName?: string
    ) => void;
    value?: string;
    className?: string;
    autoFocus?: boolean;
    records?: Record<string, any>[];
    onInputChange?: (val: string) => void;
}

export interface ISearchAgvanceFieldState {
    records: Record<string, any>[];
    value: string;
    errorCodes: number[];
    resetOptions: boolean;
}
class SearchAgvanceField extends Component<ISearchAgvanceFieldProps, ISearchAgvanceFieldState> {
    AsyncSelectInput: any;

    static defaultProps = {
        autoFocus: false,
        className: "",
        value: "",
        onInputChange: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            records: this.prepSearchData(props.records),
            errorCodes: [],
            resetOptions: false,
        };
        this.AsyncSelectInput = withAsyncOptions(SelectInput, () => this.getUserOptions());
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value,
            });
        }
        if (nextProps.records !== this.props.records) {
            this.setState({
                records: this.prepSearchData(nextProps.records),
                resetOptions: true,
            });
        }
        if (nextProps.errorCodes !== this.props.errorCodes) {
            this.setState({
                errorCodes: nextProps.errorCodes,
            });
        }
    }

    onChange = (val) => {
        if (val) {
            const { agvanceGuid, farmName } = val;
            const selectedValue = this.state.records.filter((record) => {
                return record.agvanceGuid === agvanceGuid;
            });
            if (selectedValue.length && this.props.onChange) {
                this.props.onChange(
                    selectedValue[0].name,
                    selectedValue[0].agvanceGuid,
                    selectedValue[0].fieldId,
                    farmName
                );
            }
        } else {
            this.props.onChange();
        }
    };

    prepSearchData = (records = []) => {
        return records.map((record) => {
            const { agvanceGuid, orgLevelGuid, farmName } = record;
            return {
                ...record,
                label: record.name,
                value: {
                    agvanceGuid,
                    orgLevelGuid,
                    farmName,
                },
            };
        });
    };

    onInputChange = (value) => {
        this.props.onInputChange(value);
        this.setState({ value });
    };

    getUserOptions = () => {
        if (this.state.resetOptions) {
            this.setState({
                resetOptions: false,
            });
        }
        return Promise.resolve(this.state.records);
    };

    optionRenderer = (data) => {
        const { formatMessage } = this.props.intl;
        const { name, farmName, orgLevelName } = data.option;
        const farmLabel = farmName ? `${formatMessage(messages.farm)} ${farmName},` : "";
        return (
            <div
                className="select-form-input-option agvance-search-option"
                title={`${formatMessage(messages.field)} ${name}, ${farmLabel} ${formatMessage(
                    messages.location
                )} ${orgLevelName}`}
            >
                <div className="first-line">
                    <span>{name}</span>
                </div>
                {!farmName ? null : (
                    <div className="second-line">
                        <span>{`${formatMessage(messages.farm)} ${farmName}`}</span>
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { AsyncSelectInput } = this;
        return (
            <AsyncSelectInput
                required
                allowEmptyOptions
                {...this.props}
                containerClassNames={[
                    {
                        "form-input-error": this.state.errorCodes.indexOf(62) > -1,
                    },
                ]}
                noOptionsRenderer={null}
                optionRenderer={this.optionRenderer}
                clearFilterInputOnBlur={false}
                initialFilterStr={this.state.value}
                value={this.state.value}
                resetOptions={this.state.resetOptions}
                placeholderText={formatMessage(messages.fieldNamePlaceholderText)}
                arrowRenderer={() => null}
                onChange={(val) => this.onChange(val)}
                onInputChange={(val) => this.onInputChange(val)}
                optionHeight={42}
                maxLength={30}
            />
        );
    }
}

export default injectIntl(SearchAgvanceField);
