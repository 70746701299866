import React, { Component } from "react";
import PropTypes from "prop-types";
import { DialogBox, DialogBoxFooterType, NoLink, SelectInput, ZeroToInfiniteGrid } from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "./../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import { adminData, ID, PICKLIST_GUID, VALUE } from "~/admin/data";

const PROPS_ORGANISM_TYPE = "organismTypeName";
const PROPS_ORGANISM_GUID = "organismTypeGuid";

class OrganismType extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        renderDeleteModal: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editOptions: [],
            editingItem: false,
        };
    }

    initializeOrganismType = (options) => {
        this.setState({
            editOptions: prepareSelectableOptions(options, {
                guid: PICKLIST_GUID,
                label: VALUE,
                id: ID,
            }),
        });
    };

    onEdit = (record, index) => {
        this.initializeOrganismType(this.props.options, record);
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_ORGANISM_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [PROPS_ORGANISM_TYPE],
                key: VALUE,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    initializeOrganismTypeData = ({ record = [], options }) => {
        if (record) {
            return record.map((recordItem) => {
                const selectedOrganismType = options.find((organismTypeItem) => {
                    return organismTypeItem[PICKLIST_GUID] === recordItem[PROPS_ORGANISM_GUID];
                });
                recordItem[PROPS_ORGANISM_TYPE] =
                    selectedOrganismType && selectedOrganismType[VALUE];
                return recordItem;
            });
        }
        return record;
    };

    onAdd = () => {
        this.initializeOrganismType(this.props.options);
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record && record.length > 0) {
            if (nextProps.options) {
                this.initializeOrganismTypeData(nextProps);
            }
        }
    }
    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                action="save"
                actionDisabled={!editData[PROPS_ORGANISM_TYPE]}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.organismType
                )}
            >
                <SelectInput
                    tabIndex={0}
                    autoFocus
                    openOnFocus={false}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    options={this.state.editOptions}
                    placeholderText={formatMessage(messages.organismType)}
                    value={mapToPicklistValue({
                        options: this.state.editOptions,
                        selectedGuid: editData[PROPS_ORGANISM_GUID],
                    })}
                    onChange={(value) => {
                        onChange({
                            [PROPS_ORGANISM_GUID]: value ? value.guid : "",
                            [PROPS_ORGANISM_TYPE]: value ? value.name : "",
                        });
                    }}
                />
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_ORGANISM_TYPE]: {
                                title: formatMessage(messages.name),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.organismType)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(OrganismType);
