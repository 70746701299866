import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { model as accordionModel } from "~/accordion";
import { selectors as fileImportSelectors } from "~/file-import";

import {
    getClassNameFromStatusCode,
    readyStatusCodes,
    showPointCountStatusCodes,
} from "../../../../common/status-codes";
import { messages as statusMessages } from "../../../../common/status-messages";

import * as selectors from "../../../selectors";
import * as actions from "../../../actions";

import { messages } from "../../i18n-messages";

import { ContextMenu } from "./import-file-info-context-menu";

import "./import-file-info-accordion-item.css";

export const IMPORT_FILE_INFO_HEIGHT = 45;

export class ImportFileInfoItem_ extends Component {
    static propTypes = {
        importFileInfo: PropTypes.object,
        intl: intlShape,
        isExpanded: PropTypes.bool.isRequired,
        itemDimIdx: PropTypes.arrayOf(PropTypes.number),
        selectedImportFileInfoGuidSet: PropTypes.object.isRequired,
        onShiftClick: PropTypes.func.isRequired,
        onToggleSelected: PropTypes.func.isRequired,
    };

    _onClick(event) {
        const { onShiftClick, onToggleSelected } = this.props;

        if (event.isDefaultPrevented()) {
            return;
        }

        event.preventDefault();

        if (event.getModifierState("Shift")) {
            onShiftClick();
            return;
        }

        onToggleSelected();
    }

    shouldComponentUpdate(nextProps) {
        const { importFileInfo, selectedImportFileInfoGuidSet } = this.props;
        return (
            nextProps.importFileInfo !== importFileInfo ||
            (nextProps.selectedImportFileInfoGuidSet !== selectedImportFileInfoGuidSet &&
                nextProps.selectedImportFileInfoGuidSet.has(importFileInfo.guid) !==
                    selectedImportFileInfoGuidSet.has(importFileInfo.guid))
        );
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { importFileInfo, itemDimIdx, selectedImportFileInfoGuidSet } = this.props;

        if (!importFileInfo) {
            return null;
        }
        const importFileGuid = importFileInfo.guid;

        const isSelected = selectedImportFileInfoGuidSet.has(importFileGuid);

        const uploadTimeStr = !importFileInfo.uploadDatetime
            ? null
            : formatMessage(messages.uploadDt, {
                  uploadTimeStr: moment(importFileInfo.uploadDatetime).format("M/D/YY - h:mm A"),
              });

        const statusCodeClassName = getClassNameFromStatusCode(importFileInfo.statusCode);

        const showPointCount =
            readyStatusCodes.includes(importFileInfo.statusCode) ||
            showPointCountStatusCodes.includes(importFileInfo.statusCode);

        const pointCountMsg = !showPointCount
            ? null
            : importFileInfo.isFieldBoundary
            ? formatMessage(messages.polygonCount, importFileInfo)
            : importFileInfo.isImagery
            ? formatMessage(messages.layerCount, importFileInfo)
            : formatMessage(messages.importCount, importFileInfo);

        return (
            <div
                className={classNames([
                    "import-file-info-accordion-item",
                    { selected: isSelected },
                ])}
                onClick={(event) => this._onClick(event)}
            >
                <div className="spacer" />
                <div className="file-info">
                    <span className="file-name" title={importFileInfo.fileName}>
                        {importFileInfo.fileName}
                    </span>
                    <span className="file-upload-dt">{uploadTimeStr}</span>
                </div>
                <div className="import-info">
                    <span className="point-count">{pointCountMsg}</span>
                    <span className={classNames("import-status", statusCodeClassName)}>
                        {formatMessage(statusMessages.importStatus, importFileInfo)}
                    </span>
                </div>
                <ContextMenu importFileInfo={importFileInfo} itemDimIdx={itemDimIdx} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    deselectedItem: (importFileGuid) =>
        dispatch(actions.deselectAllImportFileInfoItems(new Set([importFileGuid]))),
    selectItem: (importFileGuid) => dispatch(actions.selectImportFileInfoItem(importFileGuid)),
    setItemSelection: (fileIfoGuidSet) =>
        dispatch(actions.selectAllImportFileInfoItems(fileIfoGuidSet)),
    onSetLastClicked: (itemDimIdx) =>
        dispatch(actions.setLastImportFileItemClickedDimIdx(itemDimIdx)),
});

const mapStateToProps = (state, ownProps) => ({
    accordionItemList: selectors.getAccordionItems(state),
    importFileInfo: fileImportSelectors.getImportFileInfo(state, ownProps.importFileGuid),
    lastImportFileItemClickedDimIdx: selectors.getLastImportFileItemClickedDimIdx(state),
    selectedImportFileInfoGuidSet: selectors.getSelectedImportFileInfoGuidSet(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onShiftClick: () => {
        const { accordionItemList, lastImportFileItemClickedDimIdx } = stateProps;
        const { itemDimIdx } = ownProps;

        const compareResult = accordionModel.dimIdxCompare(
            itemDimIdx,
            lastImportFileItemClickedDimIdx
        );
        if (compareResult === 0) {
            // shift-clicked the same item ...
            return;
        }

        const [startDimIdx, endDimIdx] =
            compareResult === -1
                ? [itemDimIdx, lastImportFileItemClickedDimIdx]
                : [lastImportFileItemClickedDimIdx, itemDimIdx];
        const dimIdxIter = accordionModel.getDimIdxSliceIter(
            accordionItemList,
            startDimIdx,
            endDimIdx,
            true,
            [3]
        );

        const getFileGuidFromDimIdx = (dimIdx) =>
            accordionModel.getItem(accordionItemList, dimIdx).payload.importFileGuid;

        const selectedFileGuidSet = new Set([...dimIdxIter].map(getFileGuidFromDimIdx));
        dispatchProps.setItemSelection(selectedFileGuidSet);
    },
    onToggleSelected: () => {
        const { importFileGuid, itemDimIdx } = ownProps;
        dispatchProps.onSetLastClicked(itemDimIdx);
        if (stateProps.selectedImportFileInfoGuidSet.has(importFileGuid)) {
            dispatchProps.deselectedItem(importFileGuid);
        } else {
            dispatchProps.selectItem(importFileGuid);
        }
    },
});
export const ImportFileInfoItem = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(ImportFileInfoItem_));
