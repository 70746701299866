import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SelectInput, NumericInput, Checkbox, Bucket, BucketHeader, TextInput } from "~/core";

import { ACTIVE_YN, picklistNames } from "~/core/picklist";
import { unitNames } from "~/core/units";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import "./event-irrigation-form.css";
import { messages } from "./i18n-messages";

import {
    //actions as recsEventsActions,
    eventsSelectors,
} from "~/recs-events";

import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";

const errorMessages = defineMessages({
    amountUnitError: {
        id: "eventModule.eventInfo.irrigationAmountUnitError",
        defaultMessage: "Amount Unit",
    },
    durationUnitError: {
        id: "eventModule.eventInfo.irrigationDurationUnitError",
        defaultMessage: "Duration Unit",
    },
    estDeficitWaterNeedUnitError: {
        id: "eventModule.eventInfo.estDeficitWaterNeedUnitError",
        defaultMessage: "Est Deficit Water Need Unit",
    },
    estWaterNeedRequiredIfEstWaterNeedUnitError: {
        id: "eventModule.eventInfo.irrigationEstWaterNeedRequiredIfEstWaterNeedUnitError",
        defaultMessage: "Est Water Need Water Need Unit",
    },
    frequencyUnitError: {
        id: "eventModule.eventInfo.irrigationFrequencyUnitError",
        defaultMessage: "Frequency Unit",
    },
    installationDepthUnitError: {
        id: "eventModule.eventInfo.irrigationInstallationDepthUnitError",
        defaultMessage: "Installation Depth Unit",
    },
    rainfallSinceUnitError: {
        id: "eventModule.eventInfo.irrigationRainfallSinceUnitError",
        defaultMessage: "Rainfall Since Last Unit",
    },
    timeSinceLastUnitError: {
        id: "eventModule.eventInfo.irrigationTimeSinceLastUnitError",
        defaultMessage: "Time Since Last Unit",
    },
});

const errorCodeToMessageIdSetMap = new Map([
    [657, errorMessages.amountUnitError], // ErrorCode.EventIrrigationAmountUnitRequiredIfAmount
    [658, errorMessages.durationUnitError], // ErrorCode.EventIrrigationDurationUnitRequiredIfDuration
    [659, errorMessages.frequencyUnitError], // ErrorCode.EventIrrigationFrequencyUnitRequiredIfFrequency
    [660, errorMessages.timeSinceLastUnitError], // ErrorCode.EventIrrigationTimeSinceLastUnitRequiredIfTimeSinceLast
    [661, errorMessages.rainfallSinceUnitError], // ErrorCode.EventIrrigationRainfallSinceUnitRequiredIfRainfallSince
    [663, errorMessages.installationDepthUnitError], // ErrorCode.EventIrrigationInstallationDepthUnitRequiredIfInstallationDepth
    [664, errorMessages.estWaterNeedRequiredIfEstWaterNeedUnitError], // ErrorCode.EventIrrigationEstWaterNeedUnitRequiredIfEstWaterNeed
    [828, errorMessages.estDeficitWaterNeedUnitError], //EventIrrigationEstDeficitWaterNeedUnitRequiredIfEstDeficitWaterNeed
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

class IrrigationEventForm_ extends PureComponent {
    static propTypes = {
        agEventModel: PropTypes.object,
        fetchPicklistData: PropTypes.func,
        fetchUnitData: PropTypes.func,
        fieldModel: PropTypes.object,
        intl: intlShape.isRequired,
        irrigationType: PropTypes.array,
        irrigationUnit: PropTypes.array,
        onUpdateCurrentAgEventAreaAgEventModel: PropTypes.func,
        saveEventDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        unitLength: PropTypes.array,
        unitTime: PropTypes.array,
    };

    static IRRIGATION_TYPE_DRIP = "4";

    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _updateAgEvent(newProps) {
        this.props.onUpdateCurrentAgEventAreaAgEventModel(newProps);
    }

    _isIrrigationDripType() {
        const { agEventModel, irrigationType } = this.props;
        if (agEventModel.irrigationTypeGuid && irrigationType && irrigationType.length > 0) {
            const dripType = irrigationType.find(
                (data) => data.value === agEventModel.irrigationTypeGuid
            );
            if (dripType && dripType.id === IrrigationEventForm_.IRRIGATION_TYPE_DRIP) {
                return true;
            }
        }
        return false;
    }

    _onIrrigationTypeChange(value) {
        this._updateAgEvent({ irrigationTypeGuid: value });
    }

    componentDidMount() {
        const { agEventModel, fieldModel } = this.props;
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_IRRIGATION_TYPE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_IRRIGATION_TYPE
            ),
        });
        this.props.fetchUnitData({
            [unitNames.UNIT_IRRIGATION_VOLUME]: unitNames.getUnitCode(
                unitNames.UNIT_IRRIGATION_VOLUME
            ),
            [unitNames.UNIT_TIME]: unitNames.getUnitCode(unitNames.UNIT_TIME),
            [unitNames.UNIT_LENGTH]: unitNames.getUnitCode(unitNames.UNIT_LENGTH),
        });
        if (agEventModel.irrigationTypeGuid === "" && fieldModel && fieldModel.irrigationTypeGuid) {
            this._updateAgEvent({
                irrigationTypeGuid: fieldModel.irrigationTypeGuid,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { agEventModel } = this.props;
        const { irrigationType, irrigationUnit, unitTime, unitLength } = this.props;
        const { errorMessagePlaceholderSet } = this.state;
        const dripTypeFlag = this._isIrrigationDripType();
        return (
            <div className="irrigation-event-container">
                <div className="input-row">
                    <SelectInput
                        clearable={false}
                        optionIsHiddenKey={ACTIVE_YN}
                        containerClassNames={["irrigation-type"]}
                        options={irrigationType}
                        onChange={(value) => this._onIrrigationTypeChange(value)}
                        placeholderText={formatMessage(messages.irrigationType)}
                        required
                        value={agEventModel.irrigationTypeGuid}
                    />
                    <NumericInput
                        containerClassNames={[
                            "width-25",
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.installationDepth
                                ),
                            },
                        ]}
                        onChange={(value) => this._updateAgEvent({ irrigationAmount: value })}
                        placeholderText={formatMessage(messages.irrigationAmount)}
                        precision={10}
                        scale={2}
                        value={agEventModel.irrigationAmount}
                    />
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        containerClassNames={[
                            "width-25",
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    errorMessages.amountUnitError
                                ),
                            },
                        ]}
                        options={irrigationUnit}
                        onChange={(value) =>
                            this._updateAgEvent({
                                irrigationAmountUnitGuid: value,
                            })
                        }
                        placeholderText={formatMessage(messages.unit)}
                        value={agEventModel.irrigationAmountUnitGuid}
                    />
                </div>
                <div className="input-row">
                    {!dripTypeFlag ? null : (
                        <div className="drip-checkboxes">
                            <Checkbox
                                label={formatMessage(messages.doubleDripLine)}
                                onChange={(evt, value) =>
                                    this._updateAgEvent({
                                        doubleDripLines: value,
                                    })
                                }
                                value={agEventModel.doubleDripLines}
                            />
                            <Checkbox
                                label={formatMessage(messages.subSurface)}
                                onChange={(evt, value) =>
                                    this._updateAgEvent({
                                        subsurface: value,
                                    })
                                }
                                value={agEventModel.subsurface}
                            />
                        </div>
                    )}
                </div>
                <div className="input-row">
                    {!agEventModel.subsurface ? null : (
                        <div className="input-row">
                            <NumericInput
                                containerClassNames={[
                                    "installation-type",
                                    {
                                        "form-input-error": errorMessagePlaceholderSet.has(
                                            messages.installationDepth
                                        ),
                                    },
                                ]}
                                scale={2}
                                precision={10}
                                value={agEventModel.installationDepth}
                                placeholderText={formatMessage(messages.installationDepth)}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        installationDepth: value,
                                    })
                                }
                            />
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                containerClassNames={[
                                    "width-30",
                                    {
                                        "select-form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.installationDepthUnitError
                                        ),
                                    },
                                ]}
                                placeholderText={formatMessage(messages.unit)}
                                value={agEventModel.installationDepthUnitGuid}
                                options={unitLength}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        installationDepthUnitGuid: value,
                                    })
                                }
                            />
                        </div>
                    )}
                </div>
                <div className="input-row">
                    <NumericInput
                        containerClassNames={[
                            "width-20",
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.duration
                                ),
                            },
                        ]}
                        onChange={(value) => this._updateAgEvent({ duration: value })}
                        placeholderText={formatMessage(messages.duration)}
                        precision={10}
                        scale={2}
                        value={agEventModel.duration}
                    />
                    <SelectInput
                        containerClassNames={[
                            "width-25",
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    errorMessages.durationUnitError
                                ),
                            },
                        ]}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.unit)}
                        value={agEventModel.durationUnitGuid}
                        options={unitTime}
                        onChange={(value) => this._updateAgEvent({ durationUnitGuid: value })}
                    />
                    <NumericInput
                        containerClassNames={[
                            "width-25",
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.frequency
                                ),
                            },
                        ]}
                        scale={2}
                        precision={10}
                        value={agEventModel.frequency}
                        placeholderText={formatMessage(messages.frequency)}
                        onChange={(value) => this._updateAgEvent({ frequency: value })}
                    />
                    <SelectInput
                        containerClassNames={[
                            "width-25",
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    errorMessages.frequencyUnitError
                                ),
                            },
                        ]}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.unit)}
                        value={agEventModel.frequencyUnitGuid}
                        options={unitTime}
                        onChange={(value) => this._updateAgEvent({ frequencyUnitGuid: value })}
                    />
                </div>
                <div className="input-row">
                    <NumericInput
                        containerClassNames={[
                            "width-20",
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.timeSinceLast
                                ),
                            },
                        ]}
                        scale={2}
                        precision={10}
                        value={agEventModel.timeSinceLast}
                        placeholderText={formatMessage(messages.timeSinceLast)}
                        onChange={(value) => this._updateAgEvent({ timeSinceLast: value })}
                    />
                    <SelectInput
                        containerClassNames={[
                            "width-25",
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    errorMessages.timeSinceLastUnitError
                                ),
                            },
                        ]}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.unit)}
                        value={agEventModel.timeUnitGuid}
                        options={unitTime}
                        onChange={(value) => this._updateAgEvent({ timeUnitGuid: value })}
                    />
                    <NumericInput
                        containerClassNames={[
                            "width-25",
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.rainfallSinceLast
                                ),
                            },
                        ]}
                        scale={2}
                        precision={10}
                        value={agEventModel.rainfallSinceLast}
                        placeholderText={formatMessage(messages.rainfallSinceLast)}
                        onChange={(value) => this._updateAgEvent({ rainfallSinceLast: value })}
                    />
                    <SelectInput
                        containerClassNames={[
                            "width-25",
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    errorMessages.rainfallSinceUnitError
                                ),
                            },
                        ]}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.unit)}
                        value={agEventModel.rainfallDepthUnitGuid}
                        options={irrigationUnit}
                        onChange={(value) =>
                            this._updateAgEvent({
                                rainfallDepthUnitGuid: value,
                            })
                        }
                    />
                </div>
                <div className="input-row">
                    <TextInput
                        value={agEventModel.waterSource}
                        maxLength={50}
                        placeholderText={formatMessage(messages.waterSource)}
                        onChange={(value) =>
                            this._updateAgEvent({
                                waterSource: value,
                            })
                        }
                    />
                </div>
                <Bucket
                    className="import-field-section-bucket"
                    isExpanded={
                        errorMessagePlaceholderSet.has(errorMessages.estWaterNeedDependentError) ||
                        errorMessagePlaceholderSet.has(
                            errorMessages.estWaterNeedRequiredIfEstWaterNeedUnitError
                        ) ||
                        errorMessagePlaceholderSet.has(messages.estimatedDeficitWaterNeed) ||
                        errorMessagePlaceholderSet.has(errorMessages.estDeficitWaterNeedUnitError)
                    }
                >
                    <BucketHeader className="irrigation-event-section-header">
                        <div className="create-events-header">
                            {formatMessage(messages.irrigationWaterBudget)}
                        </div>
                    </BucketHeader>
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={[
                                "width-40",
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.estWaterNeedDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={10}
                            value={agEventModel.estWaterNeed}
                            placeholderText={formatMessage(messages.estimatedWaterNeed)}
                            onChange={(value) => this._updateAgEvent({ estWaterNeed: value })}
                        />
                        <SelectInput
                            containerClassNames={[
                                "width-25",
                                {
                                    "select-form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.estWaterNeedRequiredIfEstWaterNeedUnitError
                                    ),
                                },
                            ]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.unit)}
                            value={agEventModel.estWaterNeedUnitGuid}
                            options={irrigationUnit}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    estWaterNeedUnitGuid: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={[
                                "width-40",
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.estWaterNeedDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={10}
                            value={agEventModel.reductionPercentForDeficit}
                            placeholderText={formatMessage(messages.reductionPercentForDeficit)}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    reductionPercentForDeficit: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={[
                                "width-40",
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        messages.estimatedDeficitWaterNeed
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={10}
                            value={agEventModel.estDeficitWaterNeed}
                            placeholderText={formatMessage(messages.estimatedDeficitWaterNeed)}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    estDeficitWaterNeed: value,
                                })
                            }
                        />
                        <SelectInput
                            containerClassNames={[
                                "width-25",
                                {
                                    "select-form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.estDeficitWaterNeedUnitError
                                    ),
                                },
                            ]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.unit)}
                            value={agEventModel.estDeficitWaterNeedUnitGuid}
                            options={irrigationUnit}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    estDeficitWaterNeedUnitGuid: value,
                                })
                            }
                        />
                    </div>
                </Bucket>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { saveEventDetailsErrorCodeList } = eventsSelectors.getModuleState(state);

    return {
        saveEventDetailsErrorCodeList,
    };
};
export const IrrigationEventForm = connect(mapStateToProps, null)(injectIntl(IrrigationEventForm_));
