export const keywords = {
    APPLICATION: "Application",
    EC_DATA: "EC Data",
    FIELD_BOUNDARY: "Field Boundary",
    HARVEST: "Harvest",
    IMAGERY: "Imagery",
    PLANTING: "Planting",
    SAMPLING: "Sampling",
    SAMPLING_SOIL: "Sampling - Soil",
    SAMPLING_TISSUE: "Sampling - Tissue",
    ZONE: "Zone",
};
