import { ImportTemplate } from "~/file-import/model";

import * as models from "./models";

type ComputedFileCount = [importFileCount: number, filesWithErrorsCount: number];

export const getComputedFileCount = (
    flattenedUploadFileList: models.UploadFileInfo[],
    selectedTemplate: ImportTemplate
): ComputedFileCount => {
    let filesWithErrorsCount = 0;
    let importFileCount = 0;
    for (const uploadFileInfo of flattenedUploadFileList) {
        if (
            (!selectedTemplate || !selectedTemplate.isZipNativeFormat()) &&
            uploadFileInfo.isZipFile() &&
            !uploadFileInfo.errorMessages.length
        ) {
            continue;
        }
        if (uploadFileInfo.isShapePart()) {
            continue;
        }
        if (uploadFileInfo.errorMessages.length > 0) {
            filesWithErrorsCount++;
        }
        importFileCount++;
    }
    return [importFileCount, filesWithErrorsCount];
};

export const getSupportFileTypesFromTemplate = (template: ImportTemplate): string[] => {
    const fileTypes = template.fileTypes.split(",");
    return template.isZipNativeFormat() ? fileTypes : [".zip", ...fileTypes];
};
