import { defineMessages } from "react-intl";

export default defineMessages({
    fieldText: {
        id: "actionPanel.fieldText",
        defaultMessage: "{count, plural, one {Field} other {Fields}}",
    },
    eventText: {
        id: "actionPanel.eventText",
        defaultMessage: "{count, plural, one {Event} other {Events}}",
    },
    recText: {
        id: "actionPanel.recText",
        defaultMessage: "{count, plural, one {Rec} other {Recs}}",
    },
    acreText: {
        id: "actionPanel.acreText",
        defaultMessage: "{count, plural, one {Acre} other {Acres}}",
    },
    customerText: {
        id: "actionPanel.customerText",
        defaultMessage: "{count, plural, one {Customer} other {Customers}}",
    },
    linkText: {
        id: "qsb.privacyPolicyText",
        defaultMessage: "EULA",
    },
    updatedText: {
        id: "qsb.policyUpdatedText",
        defaultMessage: "Last updated {date}.",
    },
    titleText: {
        id: "qsb.titleText",
        defaultMessage: "Quick Summary",
    },
});
