import { takeLatest, call, put, select } from "redux-saga/effects";
import { apiUrl } from "@ai360/core";

import {
    validateEquation,
    validateEquationFailed,
    validateEquationSuccess,
    testEquation,
    testEquationFailed,
    testEquationSuccess,
    validateEquationToggle,
    setEquationParametersPicklist,
    fetchEquationParameterPickList,
} from "./actions";
import { setApiResult } from "~/core/api/actions";
import { Request, SetupAPI, APIError } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { messages } from "~/admin/agBytes/i18n-messages";
// selectors
import { getTheUserGuid } from "~/login/selectors";

export const VALIDATE_EQUATION_URL = apiUrl("AgBytes/ValidEquation");
export const TEST_EQUATION_URL = apiUrl("AgBytes/TestEquationWithValues");
export const TEST_FAILED_CODE = 577;

export function* validEquationRequest({ payload = "" }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        const response = yield call(Request.post, VALIDATE_EQUATION_URL, requestOptions);
        if (response) {
            yield put(validateEquationToggle(true, response.equationRepaired, response.equation));
            yield put(validateEquationSuccess());
        } else {
            yield put(validateEquationSuccess("No Data"));
            yield put(validateEquationToggle(true));
        }
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        yield put(validateEquationToggle(false));
        console.error(
            `Failed to process action ${validateEquation().type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(validateEquationFailed({ error }));
    }
}

export function* testEquationRequest({ payload }) {
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield call(Request.post, TEST_EQUATION_URL, requestOptions);
            if (response) {
                yield put(testEquationSuccess(response));
                yield put(setApiResult(response));
            } else {
                yield put(testEquationFailed("No Data"));
                yield put(setApiResult([TEST_FAILED_CODE]));
            }
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            console.error(
                `Failed to process action ${validateEquation().type} with payload ${JSON.stringify(
                    requestOptions
                )}`,
                error
            );
            yield put(testEquationFailed({ error }));
        }
    } else {
        console.error("No Data");
        yield put(testEquationFailed("No Data"));
    }
}

export function* fetchEquationParameters(action) {
    try {
        const UserGuid = yield select(getTheUserGuid);
        const response = yield call(SetupAPI.getEquationParameterPickList, UserGuid);
        const result = new Map();
        response.forEach((item) => {
            result.set(item.name, item.dataType);
        });
        yield put(setEquationParametersPicklist(result));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    action,
                    messages.failedToFetchEquationParameters
                )
            );
        }
    }
}

export default function* watchEquationText() {
    yield takeLatest(validateEquation, validEquationRequest);
    yield takeLatest(testEquation, testEquationRequest);
    yield takeLatest(fetchEquationParameterPickList, fetchEquationParameters);
}
