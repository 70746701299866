import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ZoneCreateIcon = withSvgIcon(
    <g>
        <path
            d={
                "M472.4,254.4l-32.7-32.2c-2.1-2.1-4.9-3.1-7.7-3.1s-5.6,1-7.7,3.1l-11.2,11.1l-5.9," +
                "5.8l-7.9,7.8l-5.9,5.8l-121.5,120l-7,6.9l-0.1,0.1l-0.1,0.1l0,0L252,437.4l-2,9.1l8.9-2.7l53.7-16.5l0," +
                "0l0.1-0.1l6.6-6.5l0,0l122.5-120.9l5.9-5.8l7.9-7.8l5.9-5.8l10.9-10.8C476.6,265.5,476.6,258.6,472.4," +
                "254.4z M281.9,428.3l-16.6-13.2l6.3-28.6l0.1,0.1l34.5,34.3L281.9,428.3z M312.7,417.5l-38-37.8l123.8-" +
                "122.2l38.4,37.5L312.7,417.5z M442.7,289.1l-38.4-37.5l7.9-7.8l38.4,37.5L442.7,289.1z M467.5,264.7l-11," +
                "10.9l-38.4-37.5l11.1-11c0.7-0.7,1.7-1.1,2.8-1.1c1.1,0,2.1,0.4,2.8,1.1l32.7,32.2c1,1,1.1,2.1,1.1," +
                "2.7C468.6,262.6,468.4,263.7,467.5,264.7z"
            }
        />
        <rect x="193" y="173" width="18.8" height="57.8" />
        <rect x="193" y="250" width="18.8" height="57.8" />
        <rect x="193" y="346" width="18.8" height="38.8" />
        <rect x="193" y="95.6" width="18.8" height="39.1" />
        <polygon
            points={
                "39,402.6 39,78.6 363,78.6 363,272.1 383,252.9 383,58.6 211.8,58.6 211.8,18.6 193," +
                "18.6 193,58.6 19,58.6 19,422.6 193,422.6 193,461.4 211.8,461.4 211.8,422.6 246.3,422.6 250.5,402.6"
            }
        />
    </g>,
    "-40 10 460 460"
);
