import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const LocationIcon = withSvgIcon(
    <g fillRule="evenodd">
        <path d="M960.607 107.796c-25.605 0-51.45 1.52-77.455 4.56-274.293 32.087-504.338 252.61-546.986 524.342-27.765 176.914 16.403 351.028 124.504 490.095l498.977 676.45 477.932-649.005h-.08l9.842-13.283.56.4c90.017-115.142 143.467-264.37 143.467-401.837 0-180.195-77.294-352.308-212.04-472.091-117.143-104.34-263.571-159.631-418.721-159.631m-.88 1810.91c-30.006 0-58.492-14.403-76.255-38.488l-508.018-688.853c-125.624-161.551-177.474-364.87-144.988-571.31 50.81-323.343 314.06-575.791 640.203-613.959 211.241-24.725 422.482 41.368 579.872 181.395 157.39 140.107 247.808 341.266 247.808 552.027 0 165.232-65.533 344.226-175.314 479.132l-487.134 661.488c-17.763 24.165-46.249 38.567-76.174 38.567" />
        <path d="M959.703 515.53c-111.622 0-202.44 90.818-202.44 202.44 0 111.621 90.818 202.439 202.44 202.439 111.621 0 202.439-90.818 202.439-202.44 0-111.62-90.818-202.438-202.44-202.438m0 511.778c-170.592 0-309.42-138.746-309.42-309.34 0-170.592 138.828-309.42 309.42-309.42 170.593 0 309.42 138.828 309.42 309.42 0 170.594-138.827 309.34-309.42 309.34" />
    </g>,
    "0 400 2300 700"
);
