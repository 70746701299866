import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { ZeroToInfiniteGrid } from "~/core";
import { messages } from "./../i18n-messages";
import { adminData } from "~/admin/data";

//const PROPS_PERSON_GUID = "personGuid";
const PROPS_PERSON_NAME = "personName";
const PROPS_CITY = "city";
const PROPS_STATE_ABBR = "stateAbbr";
const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;
const PROPS_JOB_TITLE_NAME = "jobTitleName";

class PersonAffiliationsFlatList_ extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        record: PropTypes.array,
        statePropName: PropTypes.string,
    };

    prepareRecords(records) {
        if (PROPS_STATE_ABBR !== this.props.statePropName) {
            return records.map((record) => {
                let newRecord = { ...record };
                if (!record[this.props.statePropName]) {
                    newRecord[this.props.statePropName] = record[PROPS_STATE_ABBR];
                }
                return newRecord;
            });
        }
        return records;
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { record } = this.props;
        return (
            <div className={"auto-search-list-container person-affliliation"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_PERSON_NAME]: {
                                title: formatMessage(messages.name),
                                className: "person-name",
                            },
                            [PROPS_JOB_TITLE_NAME]: {
                                title: formatMessage(messages.jobTitle),
                            },
                            [PROPS_CITY]: {
                                title: formatMessage(messages.city),
                                className: "person-city",
                            },
                            [PROPS_STATE_ABBREVIATION]: {
                                title: formatMessage(messages.stateAbbr),
                            },
                        }}
                        className={"affiliation-table-stretch"}
                    />
                )}
            </div>
        );
    }
}

export const PersonAffiliationsFlatList = injectIntl(PersonAffiliationsFlatList_);
