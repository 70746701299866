import { crudSelectors } from "~/hocs";

export const getDropdowns = (dropdownKeys = [], state) => {
    const dropdowns = crudSelectors.getDropdowns(state);
    return dropdownKeys.reduce((map, key) => {
        map[key] = dropdowns[key];
        return map;
    }, {});
};

export const getDropdown = (dropdownKey, state) => crudSelectors.getDropdowns(state)[dropdownKey];
