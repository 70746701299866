export const keywords = {
    application: "Application",
    cropAge: "Crop Age",
    dryYield: "Dry Yield",
    dryYieldUnit: "dryYieldUnit",
    ecData: "EC Data",
    harvest: "Harvest",
    lintYield: "Lint Yield",
    lintYieldUnit: "lintYieldUnit",
    name: "Name",
    plantSpacing: "Plant Spacing",
    planting: "Planting",
    plantingDepth: "Planting Depth",
    price: "Price",
    priceUnit: "priceUnit",
    rowSpacing: "Row Spacing",
    samplingTissue: "Sampling - Tissue",
    scouting: "Scouting",
    seedingRate: "Seeding Rate",
};
