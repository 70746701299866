import React, { Component, MouseEvent, FocusEvent, CSSProperties, ReactElement } from "react";
import classnames from "classnames";
import "./radio-button.css";

export interface IRadioButtonProps {
    checked?: boolean;
    className?: string;
    defaultValue?: boolean;
    disabled?: boolean;
    displayTitle?: boolean;
    label?: ReactElement | string;
    onBlur?: (event: FocusEvent) => void;
    onChange?: (event: MouseEvent, value?: string | boolean) => void;
    onFocus?: (event: FocusEvent) => void;
    required?: boolean;
    selectedValue?: string | boolean;
    tabIndex?: number;
    ternary?: boolean;
    value?: string | boolean;
    visible?: boolean;
    labelClassName?: string;
    afterOnChange?: (newValue: string | boolean) => void;
}

export interface IRadioButtonState {
    checked: boolean;
    hasFocus: boolean;
    isDisabled: boolean;
    isRequired: boolean;
    isVisible: boolean;
    selectedValue: string | boolean;
    value: string | boolean;
}

export class RadioButton extends Component<IRadioButtonProps, IRadioButtonState> {
    static defaultProps = {
        checked: false,
        defaultValue: "",
        disabled: false,
        displayTitle: false,
        required: false,
        tabIndex: 0,
        ternary: false,
        value: null,
        visible: true,
    };

    constructor(props: IRadioButtonProps) {
        super(props);
        this.state = {
            checked: props.ternary ? props.checked : props.selectedValue === props.value,
            hasFocus: false,
            isDisabled: props.disabled,
            isRequired: props.required,
            isVisible: props.visible,
            selectedValue: props.selectedValue,
            value: !props.value ? props.defaultValue : props.value,
        };
    }

    private handleOnFocus = (event?: FocusEvent): void => {
        if (!this.state.isDisabled) {
            this.setState({ hasFocus: true });
            if (this.props.onBlur) {
                this.props.onBlur(event);
            }
        }
    };

    private handleOnBlur = (event?: FocusEvent): void => {
        this.setState({ hasFocus: false });
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    ///--------------------------///
    /// Helper functions ///
    toggle = (event?: MouseEvent): void => {
        if (this.state.isDisabled) {
            return;
        }

        const { defaultValue, ternary } = this.props;
        const checked = ternary
            ? this.state.checked
            : this.state.selectedValue === this.state.value;

        this.setState(
            {
                selectedValue: ternary
                    ? checked
                        ? defaultValue
                        : this.state.value
                    : this.state.selectedValue,
                checked: ternary ? !checked : true,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(
                        event,
                        this.state.checked ? this.state.value : defaultValue
                    );
                }
            }
        );
    };

    UNSAFE_componentWillReceiveProps(newProps: IRadioButtonProps): void {
        if (this.props.ternary || newProps.selectedValue !== this.props.selectedValue) {
            this.setState({
                selectedValue: newProps.selectedValue,
                checked: newProps.ternary
                    ? newProps.checked
                    : newProps.selectedValue === this.state.value,
            });
        }

        if (newProps.disabled !== this.props.disabled) {
            this.setState({
                isDisabled: newProps.disabled,
            });
        }
    }

    render(): ReactElement {
        const { className, displayTitle, label, labelClassName, tabIndex, visible } = this.props;
        const { checked, hasFocus, isDisabled } = this.state;

        const circleText = "\u25cf";
        const title: string = displayTitle && typeof label === "string" ? label : undefined;
        const hasFocusClass = {
            focus: hasFocus,
        };
        const disabledClass = {
            "disabled-radio": isDisabled,
        };
        const circleStyle: CSSProperties = {
            visibility: checked ? "visible" : "hidden",
        };

        if (!visible) {
            return null;
        }
        return (
            <div
                className={classnames("radio-div", className)}
                onClick={() => this.toggle()}
                title={title}
            >
                <div
                    className={classnames(
                        "radio-container",
                        "radio-button-container",
                        hasFocusClass
                    )}
                    tabIndex={isDisabled ? -1 : tabIndex}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                >
                    <div className={classnames("radio-display", disabledClass)} style={circleStyle}>
                        {circleText}
                    </div>
                </div>
                <div className={classnames("radio-label", labelClassName)}>
                    <span className={classnames("radio-label-text-span", disabledClass)}>
                        {label}
                    </span>
                </div>
            </div>
        );
    }
}
