import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ClipIcon = withSvgIcon(
    <g>
        <path
            d={
                "M480.6,19.4H173.1v153.8H19.4v307.5h307.5V326.9h153.8V19.4z M307.9,461.6H38.4V192.1h134.8v134.8h134.8V461.6z" +
                "M461.6,307.9H326.9V173.1H192.1V38.4h269.5V307.9z"
            }
        />
    </g>
);
