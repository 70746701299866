import { createService } from "~/hocs/crud";
import * as model from "./model";
import defaultCustomerRecord from "./default-record";

import { AddressAPI, HierarchyAPI } from "@ai360/core";

// Module Name
export const MODEL_NAME = "customer";

export const dropdowns = {
    [model.PROPS_ORG_LEVEL_LIST]: {
        url: HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS_GUID,
        model: "_",
    },
    [model.PROPS_ADDRESS_TYPE_LIST]: AddressAPI.REQUEST_ADDRESS_TYPE,
    [model.PROPS_COUNTRIES_LIST]: AddressAPI.REQUEST_COUNTRIES,
    [model.PROPS_STATE_LIST]: { url: AddressAPI.REQUEST_STATES, model: "" },
};

// Service
export const service = createService({
    id: model.PROPS_CUSTOMER_ID,
    guid: model.PROPS_CUSTOMER_GUID,
    name: model.PROPS_CUSTOMER_NAME,
    modelName: MODEL_NAME,
    dropdowns,
    getDefaultRecord: () => ({ ...defaultCustomerRecord() }),
});
