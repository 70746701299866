import * as model from "./model";
import { createService } from "~/hocs/crud";
import { aggregateUrlGenerator } from "@ai360/core";

const AGGREGATE_NAME = "sampleAttribute";
const ENDPOINT_NAME = "agbytes/importattribute";
const API_VERSION = "v1.0";

const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const URL = aggregateUrl("list");
export const EXPORT_FILE_NAME = "SampleAttributeExport";
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const SELECT_ALL = aggregateUrl("selectAll");
// Request payload
export const REQUEST_PAYLOAD_FILTER = "sampleAttributeFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "sampleAttributeSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "sampleAttributePageOptions";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        sampleAttributeName: "",
        sampleAttributeDescription: "",
        sampleAttributeSampleTypes: "",
        systemAttributeName: "",
        testType: "",
        systemUnit: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
};

export const defaultSort = {
    FieldName: "SampleAttributeName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const printer = {
    [model.PROPS_SAMPLE_ATTRIBUTE_TYPE]: (value) => {
        return value.join("; ");
    },
};
export const service = createService({
    id: model.PROPS_SAMPLE_ATTRIBUTE_GUID,
    guid: model.PROPS_SAMPLE_ATTRIBUTE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    urls: {
        URL,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
    },
    printer,
    _defaultLabels: {
        [model.PROPS_SAMPLE_ATTRIBUTE_NAME]: {
            label: "attributeName",
            gridCol: 15,
        },
        [model.PROPS_SYSTEM_ATTRIBUTE_NAME]: {
            label: "systemAttributeName",
            gridCol: 15,
        },
        [model.PROPS_SAMPLE_ATTRIBUTE_TYPE]: {
            label: "sampleAttributeSampleTypes",
            gridCol: 15,
            sortNameOverRide: "SampleAttributeSampleTypes",
        },
        [model.PROPS_TEST_TYPE]: { label: "testType", gridCol: 15 },
        [model.PROPS_SYSTEM_UNIT]: { label: "systemUnit", gridCol: 15 },
        [model.PROPS_SAMPLE_ATTRIBUTE_DESCRIPTION]: {
            label: "sampleAttributeDescription",
        },
    },
    defaultSort,
    useNewApi: true,
});
