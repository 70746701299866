import PropTypes from "prop-types";
export const PROPS_BASE64STRING = "base64String";
export const PROPS_CITATION = "citation";
export const PROPS_FILENAME = "fileName";
export const PROPS_FILESIZE = "fileSize";
export const PROPS_IMAGE_THUMBNAIL_HASFOCUS = "imageThumbnailHasFocus";
export const PROPS_PHOTO_TITLE = "photoTitle";

export const shape = {
    [PROPS_BASE64STRING]: PropTypes.string,
    [PROPS_CITATION]: PropTypes.string,
    [PROPS_PHOTO_TITLE]: PropTypes.string,
    [PROPS_IMAGE_THUMBNAIL_HASFOCUS]: PropTypes.bool,
    [PROPS_FILENAME]: PropTypes.string,
    [PROPS_FILESIZE]: PropTypes.string,
};

export default PropTypes.shape(shape);
