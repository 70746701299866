import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import { messages } from "~/admin/agBytes/i18n-messages";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import {
    CropList,
    ProductList,
    EquationFilters,
    EquationTextArea,
    ImportAttributes,
    Parameters,
} from "~/admin/setup/equation-list/components";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
import {
    Checkbox,
    NumericInput,
    RadioButton,
    RadioButtonGroup,
    Section,
    SelectInput,
    SubSection,
    TextArea,
    TextInput,
} from "~/core";
import * as picklistService from "~/core/picklist/picklist-names";

import { model, service, PRODUCT_DROPDOWN, REQUEST_CROP_CLASS } from "../data";
import "./add-edit-panel.css";

const NUTRIENT_GUID = "nutrientGuid";
const NUTRIENT_NAME = "nutrientName";
const NUTRIENT_ID = "nutrientId";
const ELEMENTAL = "elemental";
const OXIDE = "oxide";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        equationGroupType: PropTypes.string,
        equationParametersPicklist: PropTypes.object, //Map
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchEquationParameterPickList: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        parentGuid: PropTypes.string,
        parentId: PropTypes.number,
        parentName: PropTypes.string,
        record: PropTypes.object,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        testEquation: PropTypes.func.isRequired,
        toggleCropValidState: PropTypes.func,
        toggleEquationValidState: PropTypes.func.isRequired,
        validateCropListToggle: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.equation = {};
        this.parameters = [];
        this.importAttributes = [];
        this.state = {
            nextId: null,
            equationText: "",
            isEquationValidated: false,
            parameters: [],
            importAttributes: [],
        };
    }

    componentDidMount() {
        this.setupEquationRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(this.props.parentGuid),
            this.props.fetchDropdownData(),
            this.props.fetchPicklistData(),
        ]);
        this.props.fetchEquationParameterPickList();
        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.equation);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                if (nextProps.record.cropList && nextProps.record.cropList.length > 0) {
                    this.props.validateCropListToggle(true);
                }
                this.equation = nextProps.record;
                this.setState(
                    {
                        equationText: nextProps.record[model.PROPS_EQUATION],
                        [model.PROPS_PREVIOUS_CROP_GUID]:
                            nextProps.record[model.PROPS_PREVIOUS_CROP_GUID],
                        [model.PROPS_NEXT_CROP_GUID]: nextProps.record[model.PROPS_NEXT_CROP_GUID],
                        [model.PROPS_APPLICATION_TIMING_GUID]:
                            nextProps.record[model.PROPS_APPLICATION_TIMING_GUID],
                        [model.PROPS_APPLICATION_METHOD_GUID]:
                            nextProps.record[model.PROPS_APPLICATION_METHOD_GUID],
                        [model.PROPS_SOIL_TEXTURE_GUID]:
                            nextProps.record[model.PROPS_SOIL_TEXTURE_GUID],
                        [model.PROPS_TILLAGE_METHOD_GUID]:
                            nextProps.record[model.PROPS_TILLAGE_METHOD_GUID],
                        [model.PROPS_INCORPORATION_DEPTH_GUID]:
                            nextProps.record[model.PROPS_INCORPORATION_DEPTH_GUID],
                        [model.PROPS_BUILD_FACTOR_GUID]:
                            nextProps.record[model.PROPS_BUILD_FACTOR_GUID],
                        [model.PROPS_TARGET_PH_GUID]: nextProps.record[model.PROPS_TARGET_PH_GUID],
                        [model.PROPS_REC_OPTION_GUID]:
                            nextProps.record[model.PROPS_REC_OPTION_GUID],
                    },
                    () => {
                        this.parameters = nextProps.record[model.PROPS_PARAMETER_LIST] || [];
                        this.importAttributes =
                            nextProps.record[model.PROPS_IMPORT_ATTRIBUTE_LIST] || [];
                    }
                );
                this.onTextChange([model.PROPS_EQUATION_GROUP_TYPE], this.props.equationGroupType);
            }
        }
        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.equation[model.PROPS_EQUATION_ID] = "" + nextProps.nextId;
            this.equation[model.PROPS_EQUATION_GROUP_GUID] = "" + nextProps.parentGuid;
            this.equation[model.PROPS_EQUATION_GROUP_TYPE] = nextProps.equationGroupType;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupEquationRecord = () => {
        const { addEditPanel } = this.props;
        this.equation = {};
        if (addEditPanel.mode === "ADD") {
            this.equation = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps) => {
        this.initializeNutrients(nextProps);
        this.initializeCrops(nextProps);
        this.initializeCropPurpose(nextProps);
        this.initializeCropClassName(nextProps);
        this.initializeProducts(nextProps);
        this.initializeEquationFilters(nextProps);
    };

    initializeNutrients = (nextProps) => {
        if (
            this.equation[model.PROPS_NUTRIENT_GUID] != null &&
            nextProps[model.PROPS_NUTRIENT_NAME]
        ) {
            this.setState({
                [model.PROPS_NUTRIENT_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_NUTRIENT_NAME],
                    {
                        guid: NUTRIENT_GUID,
                        label: NUTRIENT_NAME,
                        id: NUTRIENT_ID,
                    },
                    this.equation[model.PROPS_NUTRIENT_GUID]
                ),
            });
        }
    };

    initializeCrops = (nextProps) => {
        if (this.equation[model.PROPS_CROP_LIST] != null && nextProps[model.PROPS_CROP_NAME]) {
            this.setState({
                [model.PROPS_CROP_NAME]: nextProps[model.PROPS_CROP_NAME],
                [model.PROPS_PREVIOUS_CROP]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME],
                    { guid: GUID, label: NAME, id: ID },
                    this.equation[model.PROPS_PREVIOUS_CROP_GUID]
                ),
                [model.PROPS_NEXT_CROP]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME],
                    { guid: GUID, label: NAME, id: ID },
                    this.equation[model.PROPS_NEXT_CROP_GUID]
                ),
            });
        }
    };

    initializeCropPurpose = (nextProps) => {
        if (
            this.equation[model.PROPS_CROP_LIST] != null &&
            nextProps[picklistService.PICKLIST_CROP_PURPOSE]
        ) {
            this.setState({
                [picklistService.PICKLIST_CROP_PURPOSE]:
                    nextProps[picklistService.PICKLIST_CROP_PURPOSE],
            });
        }
    };

    initializeCropClassName = (nextProps) => {
        if (
            this.equation[model.PROPS_CROP_LIST] != null &&
            nextProps[model.PROPS_CROP_CLASS_NAME]
        ) {
            this.setState({
                [model.PROPS_CROP_CLASS_NAME]: nextProps[model.PROPS_CROP_CLASS_NAME],
            });
        }
    };

    initializeProducts = (nextProps) => {
        if (this.equation[model.PROPS_PRODUCT_LIST] != null && nextProps[PRODUCT_DROPDOWN]) {
            this.setState({
                [PRODUCT_DROPDOWN]: nextProps[PRODUCT_DROPDOWN],
            });
        }
    };

    initializeEquationFilters = (nextProps) => {
        this.initializeApplicationTiming(nextProps);
        this.initializeApplicationMethod(nextProps);
        this.initializeSoilTexture(nextProps);
        this.initializeTillageMethod(nextProps);
        this.initializeIncorporationDepth(nextProps);
        this.initializeBuildFactor(nextProps);
        this.initializeTargetPh(nextProps);
        this.initializeRecOptions(nextProps);
    };

    initializeApplicationTiming = (nextProps) => {
        if (
            this.equation[model.PROPS_APPLICATION_TIMING_GUID] != null &&
            nextProps[picklistService.PICKLIST_APPLICATION_TIMING]
        ) {
            this.setState({
                [picklistService.PICKLIST_APPLICATION_TIMING]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_APPLICATION_TIMING],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_APPLICATION_TIMING_GUID]
                ),
            });
        }
    };

    initializeApplicationMethod = (nextProps) => {
        if (
            this.equation[model.PROPS_APPLICATION_METHOD_GUID] != null &&
            nextProps[picklistService.PICKLIST_APPLICATION_METHOD]
        ) {
            this.setState({
                [picklistService.PICKLIST_APPLICATION_METHOD]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_APPLICATION_METHOD],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_APPLICATION_METHOD_GUID]
                ),
            });
        }
    };

    initializeSoilTexture = (nextProps) => {
        if (
            this.equation[model.PROPS_SOIL_TEXTURE_GUID] != null &&
            nextProps[picklistService.PICKLIST_SOIL_TEXTURE]
        ) {
            this.setState({
                [picklistService.PICKLIST_SOIL_TEXTURE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_SOIL_TEXTURE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_SOIL_TEXTURE_GUID]
                ),
            });
        }
    };

    initializeTillageMethod = (nextProps) => {
        if (
            this.equation[model.PROPS_TILLAGE_METHOD_GUID] != null &&
            nextProps[picklistService.PICKLIST_TILLAGE_METHOD]
        ) {
            this.setState({
                [picklistService.PICKLIST_TILLAGE_METHOD]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_TILLAGE_METHOD],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_TILLAGE_METHOD_GUID]
                ),
            });
        }
    };

    initializeIncorporationDepth = (nextProps) => {
        if (
            this.equation[model.PROPS_INCORPORATION_DEPTH_GUID] != null &&
            nextProps[picklistService.PICKLIST_INCORPORATION_DEPTH]
        ) {
            this.setState({
                [picklistService.PICKLIST_INCORPORATION_DEPTH]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_INCORPORATION_DEPTH],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_INCORPORATION_DEPTH_GUID]
                ),
            });
        }
    };

    initializeBuildFactor = (nextProps) => {
        if (
            this.equation[model.PROPS_BUILD_FACTOR_GUID] != null &&
            nextProps[picklistService.PICKLIST_BUILD_FACTOR]
        ) {
            this.setState({
                [picklistService.PICKLIST_BUILD_FACTOR]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_BUILD_FACTOR],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_BUILD_FACTOR_GUID]
                ),
            });
        }
    };

    initializeTargetPh = (nextProps) => {
        if (
            this.equation[model.PROPS_TARGET_PH_GUID] != null &&
            nextProps[picklistService.PICKLIST_TARGET_PH]
        ) {
            this.setState({
                [picklistService.PICKLIST_TARGET_PH]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_TARGET_PH],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_TARGET_PH_GUID]
                ),
            });
        }
    };

    initializeRecOptions = (nextProps) => {
        if (
            this.equation[model.PROPS_REC_OPTION_GUID] != null &&
            nextProps[picklistService.PICKLIST_REC_OPTION]
        ) {
            this.setState({
                [picklistService.PICKLIST_REC_OPTION]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_REC_OPTION],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equation[model.PROPS_REC_OPTION_GUID]
                ),
            });
        }
    };

    onTextChange = (formKey, value, callback) => {
        this.equation = onTextChange(this.equation, { formKey: [formKey], value }, callback);
    };

    onNumberChange = (formKey, value, callback) => {
        this.equation = onNumberChange(this.equation, { formKey: [formKey], value }, callback);
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.equation = handlePicklistChange(this.equation, { type, guid, value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.equation[model.PROPS_EQUATION_NAME]]);
    };

    onTestParameterChange = (key, value) => {
        if (key === model.PROPS_IMPORT_ATTRIBUTE_LIST) {
            this.importAttributes = value;
            this.onTextChange(key, value);
        }

        if (key === model.PROPS_PARAMETER_LIST) {
            this.parameters = value;
        }
    };

    getNutrientType = () => {
        return this.equation[model.PROPS_IS_OXIDE];
    };

    getCropClassNames = ({ cropGuid = "" }) => {
        if (cropGuid) {
            this.props.needs([
                this.props.fetchDropdownData({
                    [model.PROPS_CROP_CLASS_NAME]: {
                        url: REQUEST_CROP_CLASS,
                        model: cropGuid,
                    },
                }),
            ]);
        } else {
            this.setState({
                [model.PROPS_CROP_CLASS_NAME]: [],
            });
        }
    };

    onEquationFilterChange = ({ type = "", guid = "", value = "", stateName = "" }) => {
        if (type && guid && stateName) {
            this.onPicklistChange(
                {
                    type,
                    guid,
                },
                value
            );
            const recordGuid = value ? value.guid : "";
            this.setState({
                [guid]: recordGuid,
            });
        }
    };

    onRadioButtonChange = (value) => {
        if (value && value === OXIDE) {
            this.onTextChange([model.PROPS_IS_OXIDE], true);
        } else {
            this.onTextChange([model.PROPS_IS_OXIDE], false);
        }
    };

    getEquationType(value = false) {
        if (value) {
            return ELEMENTAL;
        } else {
            return OXIDE;
        }
    }

    testEquation = () => {
        if (this.props.testEquation) {
            this.props.needs([
                this.props.testEquation({
                    [model.PROPS_EQUATION]: this.equation[model.PROPS_EQUATION],
                    importAttributeValues: this.importAttributes,
                    parameterValues: this.parameters,
                }),
            ]);
        }
    };

    renderEquationInfo = () => {
        const { formatMessage } = this.props.intl;
        const { equation } = this;
        const isPlanting = this.props.equationGroupType === "Planting";
        return (
            <Section>
                <div className="form-sub-section section-column read-only-content">
                    <span>
                        {formatMessage(messages.equationSetId)}: {this.props.parentId}
                    </span>
                    {isPlanting ? null : (
                        <span>
                            {formatMessage(messages.equationSetName)}: {this.props.parentName}
                        </span>
                    )}
                </div>
                <SubSection className="form-section-child-stretch">
                    <NumericInput
                        tabIndex={0}
                        scale={0}
                        precision={9}
                        suppressFormatting
                        containerClassNames={getAgBytesErrorClassNames(
                            [667, 668],
                            this.props.apiErrors,
                            ["equation-input-id"]
                        )}
                        placeholderText={formatMessage(messages.equationId)}
                        labelText={formatMessage(messages.equationId)}
                        value={this.equation[model.PROPS_EQUATION_ID]}
                        onChange={(value) => this.onNumberChange(model.PROPS_EQUATION_ID, value)}
                        required
                    />
                    {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                        <Checkbox
                            className={classnames(
                                getAgBytesErrorClassNames(811, this.props.apiErrors)
                            )}
                            onChange={(e, value) => this.onTextChange(model.PROPS_ACTIVE_YN, value)}
                            value={equation[model.PROPS_ACTIVE_YN]}
                            label={formatMessage(messages.active)}
                        />
                    )}
                </SubSection>
                {isPlanting ? null : (
                    <SubSection className="form-section-child-stretch">
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames(
                                599,
                                this.props.apiErrors,
                                ["equation-input-id"]
                            )}
                            placeholderText={formatMessage(messages.name)}
                            labelText={formatMessage(messages.name)}
                            autoFocus
                            value={equation[model.PROPS_EQUATION_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_EQUATION_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            required
                        />
                        <RadioButtonGroup
                            className={"elemental-oxide-radio-group"}
                            selectedValue={this.getEquationType(
                                this.equation[model.PROPS_IS_OXIDE]
                            )}
                            afterOnChange={this.onRadioButtonChange}
                        >
                            <RadioButton
                                tabIndex={0}
                                value={ELEMENTAL}
                                label={formatMessage(messages.elemental)}
                            />
                            <RadioButton
                                tabIndex={0}
                                value={OXIDE}
                                label={formatMessage(messages.oxide)}
                            />
                        </RadioButtonGroup>
                    </SubSection>
                )}
                <SubSection className="form-section-child-stretch">
                    <TextArea
                        maxLength={50000}
                        tabIndex={0}
                        placeholderText={formatMessage(messages.description)}
                        value={equation[model.PROPS_DESCRIPTION]}
                        onChange={(value) => this.onTextChange(model.PROPS_DESCRIPTION, value)}
                    />
                </SubSection>
                {isPlanting ? null : (
                    <SubSection className="form-section-child-stretch">
                        <SelectInput
                            tabIndex={0}
                            required
                            containerClassNames={getAgBytesErrorClassNames(
                                623,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_NUTRIENT_NAME]}
                            placeholderText={formatMessage(messages.nutrient)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_NUTRIENT_NAME],
                                selectedGuid: equation[model.PROPS_NUTRIENT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_NUTRIENT_NAME,
                                        guid: model.PROPS_NUTRIENT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                )}
            </Section>
        );
    };

    renderEquationSection1 = () => {
        const { formatMessage } = this.props.intl;
        const isPlanting = this.props.equationGroupType === "Planting";
        return (
            <div className="section-container section-column">
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.cropPurposeList)}
                    required={isPlanting}
                >
                    <SubSection>
                        <CropList
                            formatMessage={formatMessage}
                            getCropClassNames={this.getCropClassNames}
                            options={{
                                cropName: this.state[model.PROPS_CROP_NAME],
                                cropClassName: this.state[model.PROPS_CROP_CLASS_NAME],
                                cropPurpose: this.state[picklistService.PICKLIST_CROP_PURPOSE],
                            }}
                            toggleCropValidState={this.props.validateCropListToggle}
                            record={this.equation[model.PROPS_CROP_LIST]}
                            itemListAlias={model.PROPS_CROP_LIST}
                            onTextChange={(e, value) =>
                                this.onTextChange(model.PROPS_CROP_LIST, value)
                            }
                        />
                    </SubSection>
                </Section>
                {isPlanting ? null : (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.productsHeader)}
                    >
                        <SubSection>
                            <ProductList
                                formatMessage={formatMessage}
                                options={this.state[PRODUCT_DROPDOWN]}
                                record={this.equation[model.PROPS_PRODUCT_LIST]}
                                itemListAlias={model.PROPS_PRODUCT_LIST}
                                onTextChange={(e, value) =>
                                    this.onTextChange(model.PROPS_PRODUCT_LIST, value)
                                }
                            />
                        </SubSection>
                    </Section>
                )}
            </div>
        );
    };

    renderEquationSection2 = () => {
        const { formatMessage } = this.props.intl;
        const {
            PICKLIST_APPLICATION_TIMING,
            PICKLIST_APPLICATION_METHOD,
            PICKLIST_SOIL_TEXTURE,
            PICKLIST_TILLAGE_METHOD,
            PICKLIST_INCORPORATION_DEPTH,
            PICKLIST_BUILD_FACTOR,
            PICKLIST_TARGET_PH,
            PICKLIST_REC_OPTION,
        } = picklistService;
        const dropdowns = ({
            [PICKLIST_APPLICATION_TIMING]: this.state[PICKLIST_APPLICATION_TIMING],
            [PICKLIST_APPLICATION_METHOD]: this.state[PICKLIST_APPLICATION_METHOD],
            [PICKLIST_SOIL_TEXTURE]: this.state[PICKLIST_SOIL_TEXTURE],
            [PICKLIST_TILLAGE_METHOD]: this.state[PICKLIST_TILLAGE_METHOD],
            [PICKLIST_INCORPORATION_DEPTH]: this.state[PICKLIST_INCORPORATION_DEPTH],
            [PICKLIST_BUILD_FACTOR]: this.state[PICKLIST_BUILD_FACTOR],
            [PICKLIST_TARGET_PH]: this.state[PICKLIST_TARGET_PH],
            [PICKLIST_REC_OPTION]: this.state[PICKLIST_REC_OPTION],
            [model.PROPS_NEXT_CROP]: this.state[model.PROPS_NEXT_CROP],
            [model.PROPS_PREVIOUS_CROP]: this.state[model.PROPS_PREVIOUS_CROP],
        } = this.state);
        const records = {
            [model.PROPS_PREVIOUS_CROP_GUID]: this.state[model.PROPS_PREVIOUS_CROP_GUID],
            [model.PROPS_NEXT_CROP_GUID]: this.state[model.PROPS_NEXT_CROP_GUID],
            [model.PROPS_APPLICATION_TIMING_GUID]: this.state[model.PROPS_APPLICATION_TIMING_GUID],
            [model.PROPS_APPLICATION_METHOD_GUID]: this.state[model.PROPS_APPLICATION_METHOD_GUID],
            [model.PROPS_SOIL_TEXTURE_GUID]: this.state[model.PROPS_SOIL_TEXTURE_GUID],
            [model.PROPS_TILLAGE_METHOD_GUID]: this.state[model.PROPS_TILLAGE_METHOD_GUID],
            [model.PROPS_INCORPORATION_DEPTH_GUID]:
                this.state[model.PROPS_INCORPORATION_DEPTH_GUID],
            [model.PROPS_BUILD_FACTOR_GUID]: this.state[model.PROPS_BUILD_FACTOR_GUID],
            [model.PROPS_TARGET_PH_GUID]: this.state[model.PROPS_TARGET_PH_GUID],
            [model.PROPS_REC_OPTION_GUID]: this.state[model.PROPS_REC_OPTION_GUID],
        };
        return EquationFilters({
            dropdowns,
            formatMessage,
            onSelectionChange: this.onEquationFilterChange,
            records,
        });
    };

    onEquationTextAreaChange = (value) => {
        this.onTextChange(model.PROPS_EQUATION, value);
        this.setState({
            equationText: value,
        });
    };

    updateTestButtonStatus = () => {
        let importAttributesValueArray = [],
            paramsHasValueArray = [];
        if (this.importAttributes && Array.isArray(this.importAttributes)) {
            importAttributesValueArray = this.importAttributes.filter(({ value }) => {
                return !value;
            });
        }

        if (this.parameters && Array.isArray(this.parameters)) {
            paramsHasValueArray = this.parameters.filter(({ value }) => {
                return !value;
            });
        }
        //KB - No earthly idea what this function is supposed to be doing, but it appears to be doing nothing
        //not sure if it was supposed to be a place holder for something or just an oversight ... but eslint
        //threw a warning that `importAttributesValueArray` and `paramsHasValueArray` were assigned values and
        //never used, so I added this return ... though it technically doesn't do anything either other than
        //get rid of the warning
        //TODO: FIXME!!!!!!
        return [importAttributesValueArray, paramsHasValueArray];
    };

    renderEquationSection3 = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <ImportAttributes
                    formatMessage={formatMessage}
                    importAttributes={this.importAttributes}
                    onTextChange={this.onTestParameterChange}
                    updateTestButtonStatus={this.updateTestButtonStatus}
                    equationText={this.state.equationText}
                />
                <Parameters
                    formatMessage={formatMessage}
                    parameters={this.parameters}
                    onTextChange={this.onTestParameterChange}
                    updateTestButtonStatus={this.updateTestButtonStatus}
                    equationText={this.state.equationText}
                    equationParametersPicklist={this.props.equationParametersPicklist}
                />
            </div>
        );
    };

    renderEquationTextArea = () => {
        const { formatMessage } = this.props.intl;
        return (
            <EquationTextArea
                apiErrors={this.props.apiErrors}
                equation={this.equation}
                formatMessage={formatMessage}
                needs={this.props.needs}
                onEquationTextAreaChange={this.onEquationTextAreaChange}
                testEquation={this.testEquation}
                toggleEquationValidState={this.props.toggleEquationValidState}
                updateTestButtonStatus={this.updateTestButtonStatus}
            />
        );
    };

    render() {
        return (
            <div className="add-edit-panel">
                <div className="section-container section-container-equation">
                    {this.renderEquationInfo()}
                    <span className="no-bar section-fence"></span>
                    {this.renderEquationTextArea()}
                </div>
                <div className="section-container">
                    {this.renderEquationSection1()}
                    <span className="bar section-fence" />
                    {this.renderEquationSection2()}
                    <span className="bar section-fence" />
                    {this.renderEquationSection3()}
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
