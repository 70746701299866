import { AgEventAPI } from "@ai360/core";
import { IAgEventModelMethods } from "../model";

export class AgEventTillage implements AgEventAPI.IAgEventTillage, IAgEventModelMethods {
    agEventGuid = "";
    tillageMethodGuid = "";
    directionGuid = "";
    depth = null;
    depthIAGuid = "";

    get isAllRequiredFieldsSet(): boolean {
        return this.tillageMethodGuid && this.tillageMethodGuid !== "";
    }

    get isAnyRequiredFieldSet(): boolean {
        return this.isAllRequiredFieldsSet;
    }
    updateAgEventModel(newProps: Partial<AgEventTillage>): AgEventTillage {
        return Object.assign(new AgEventTillage(), this, newProps);
    }
}
