import { combineReducers } from "redux";
import { mapControlReducer } from "./components/map-control/reducer";
import { MAP_CONTROL_STATE_KEY } from "./components/map-control/selectors";
import { mapToolsReducer } from "./components/map-tools/reducer";
import { MAP_TOOLS_STATE_KEY } from "./components/map-tools/selectors";

export const mapReducer = combineReducers({
    [MAP_CONTROL_STATE_KEY]: mapControlReducer,
    [MAP_TOOLS_STATE_KEY]: mapToolsReducer,
});
