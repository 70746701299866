import React, { Component } from "react";
import _ from "lodash";
import { DialogBox, DialogBoxFooterType, NoLink, SelectInput, ZeroToInfiniteGrid } from "~/core";
import { withEditableGrid } from "~/hocs";
import {
    getAgBytesErrorClassNames,
    mapToPicklistValue,
    prepareSelectableOptions,
} from "~/admin/utils";
import { adminData, PICKLIST_GUID, VALUE } from "~/admin/data";
import { messages } from "../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";

const PROPS_PRODUCT_NAME = "name";
const PROPS_PRODUCT_GUID = "productTypeGuid";

export interface ProductListProps {
    apiErrors: string[];
    record: string[];
    formatMessage: (messge: string, object?: Record<string, any>) => string;
    toggleModal: (message: string, toggle: boolean) => void;
    onChange: (obj: any) => void;
    options: string[];
    onAdd: () => void;
    onEdit: (EditObject: Record<string, any>) => void;
    onAddOrEditItem: () => void;
    isEditing: boolean;
    isDeleting: boolean;
    disabled: boolean;
    editData: Record<string, any>;
    addProductTitle: any;
    addProductLinkText: Record<string, any>;
}
export interface ProductListState {
    editOptions: any;
    editingItem: boolean;
}

class ProductList extends Component<ProductListProps, ProductListState> {
    constructor(props) {
        super(props);
        this.state = {
            editOptions: [],
            editingItem: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { record, options } = nextProps;
        if (record && record.length > 0) {
            if (options) {
                this.initializeProductData(nextProps);
            }
        }
    }

    initializeProductData = ({ record = [], options }) => {
        return record.map((recordItem) => {
            const selectedProduct = _.find(
                options,
                (product) => product[PICKLIST_GUID] === recordItem[PROPS_PRODUCT_GUID]
            );
            recordItem[PROPS_PRODUCT_NAME] = selectedProduct && selectedProduct[VALUE];
            return recordItem;
        });
    };

    initializeProducts = (options) => {
        this.setState({
            editOptions: prepareSelectableOptions(options, {
                guid: PICKLIST_GUID,
                label: VALUE,
                id: null,
                appendIdToLabel: false,
                details: null,
            }),
        });
    };

    onEdit = (record, index) => {
        this.initializeProducts(this.props.options);
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_PRODUCT_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [PROPS_PRODUCT_NAME],
                key: VALUE,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onAdd = () => {
        this.initializeProducts(this.props.options);
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const {
            addProductTitle,
            formatMessage,
            isEditing,
            editData,
            onAddOrEditItem,
            onChange,
            toggleModal,
        } = this.props;
        const titleText = addProductTitle
            ? createAddEditModalTitle(this.state.editingItem, formatMessage, addProductTitle)
            : createAddEditModalTitle(this.state.editingItem, formatMessage, messages.product);
        const selectPlaceholderText = addProductTitle
            ? formatMessage(addProductTitle as string, { count: 1 })
            : formatMessage(messages.productName);
        return (
            <DialogBox
                actionDisabled={!editData[PROPS_PRODUCT_GUID]}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                hideCloseX
                isOpen={isEditing}
                onClose={() => toggleModal("isEditing", false)}
                onAction={() => onAddOrEditItem()}
                title={titleText}
            >
                <SelectInput
                    autoFocus
                    openOnFocus={false}
                    tabIndex={0}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    initialFilterStr=""
                    options={this.state.editOptions}
                    placeholderText={selectPlaceholderText}
                    value={mapToPicklistValue({
                        options: this.state.editOptions,
                        selectedGuid: editData[PROPS_PRODUCT_GUID],
                    })}
                    onChange={(value) => {
                        onChange({
                            [PROPS_PRODUCT_GUID]: value ? value.guid : "",
                            [PROPS_PRODUCT_NAME]: value ? value.name : "",
                        });
                    }}
                    required
                />
            </DialogBox>
        );
    };

    render() {
        const { addProductLinkText, apiErrors, disabled, formatMessage, record } = this.props;
        const disabledClassName = disabled ? "disabled-link" : "";
        const linkText = addProductLinkText
            ? createAddLinkLabelText(formatMessage, addProductLinkText)
            : createAddLinkLabelText(formatMessage, messages.product);
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_PRODUCT_NAME]: {
                                title: formatMessage(messages.name),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                <div className="add-link-container">
                    <NoLink
                        classNames={getAgBytesErrorClassNames(364, apiErrors, [
                            "add-link",
                            disabledClassName,
                        ])}
                        label={linkText}
                        onClick={!disabled ? () => this.onAdd() : () => null}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(ProductList);
