import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CHILL_HOURS_HIGH = "chillHoursHigh";
export const PROPS_CHILL_HOURS_LOW = "chillHoursLow";
export const PROPS_CROP_CLASS_GUID = "cropClassNameGuid";
export const PROPS_CROP_CLASS_NAME = "cropClassName";
export const PROPS_CROP_CLASS_NAME_LIST = "cropClassNameList";
export const PROPS_CROP_GUID = "cropGuid";
export const PROPS_CROP_ID = "cropId";
export const PROPS_CROP_NAME = "cropName";
export const PROPS_CROP_NAME_LIST = "cropNameList";
export const PROPS_GDU_TO_MATURITY = "gduToMaturity";
export const PROPS_GROWTH_STAGE_GROUP_GUID = "growthStageGuid";
export const PROPS_GROWTH_STAGE_GROUP_ID = "growthStageGroupId";
export const PROPS_GROWTH_STAGE_GROUP_LIST = "growthStageList";
export const PROPS_GROWTH_STAGE_GROUP_NAME = "growthStageName";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_MODULE_ID = "__moduleId__";
export const PROPS_RELATIVE_MATURITY = "relativeMaturity";
export const PROPS_TRAIT_GUID = "traitGuid";
export const PROPS_TRAIT_ID = "traitId";
export const PROPS_TRAIT_LIST = "traitNameList";
export const PROPS_VARIETY_HYBRID_BRAND = "brandOrganization";
export const PROPS_VARIETY_HYBRID_BRAND_GUID = "brandOrganizationGuid";
export const PROPS_VARIETY_HYBRID_GMO = "geneticallyModified";
export const PROPS_VARIETY_HYBRID_GUID = "varietyHybridGuid";
export const PROPS_VARIETY_HYBRID_ID = "varietyHybridId";
export const PROPS_VARIETY_HYBRID_NAME = "varietyHybridName";
export const PROPS_VARIETY_HYBRID_TRAIT_NAME = "traitName";
export const PROPS_MYAGDATA_COMMODITY = "myAgDataCommodities";
