import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_IS_ACTIVE;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CROPS_ID = "id";
export const PROPS_CROP_GUID = "cropGuid";
export const PROPS_CROP_NAME = "crop";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_NOTES = "notes";
export const PROPS_REFUGE_REQUIREMENTS = "refugeRequirement";
export const PROPS_TRAIT_ABBREVIATION = "abbreviation";
export const PROPS_TRAIT_BRAND_ORGANIZATION = "brandOrganization";
export const PROPS_TRAIT_BRAND_ORGANIZATION_GUID = "brandOrganizationGuid";
export const PROPS_TRAIT_EVENTS = "events";
export const PROPS_TRAIT_GENES = "genes";
export const PROPS_TRAIT_GENE_GUID = "geneGuid";
export const PROPS_GENE_NAME = "geneName";
export const PROPS_TRAIT_GMO = "gmo";
export const PROPS_TRAIT_GUID = adminData.PROPS_ID;
export const PROPS_TRAIT_ID = adminData.PROPS_INTEGER_ID;
export const PROPS_TRAIT_NAME = adminData.PROPS_NAME;
export const PROPS_HERBICIDE_TOLERANCE = "herbicideTolerance";
export const PROPS_HERBICIDE_TOLERANCE_GUID = "herbicideToleranceGuid";
