// Model
export const PROPS_ACTIVE_YN = "activeYn";
export const PROPS_ADDRESS_LIST = "addressList";
export const PROPS_ADDRESS_TYPE_LIST = "addressTypeList";
export const PROPS_ADMIN_LOGIN = "adminLogin";
export const PROPS_AGBYTES_ADMIN_EMAIL_LIST = "agBytesAdminEmailList";
export const PROPS_AGVANCE_API_URL = "agvanceApiUrl";
export const PROPS_AGVANCE_DATABASE_ID = "agvanceDatabaseId";
export const PROPS_AGVANCE_USER_ID = "agvanceUserId";
export const PROPS_AGVANCE_USER_PASSWORD = "agvanceUserPassword";
export const PROPS_BRANDING_YN = "brandingYn";
export const PROPS_COMPANY_GUID = "companyGuid";
export const PROPS_COMPANY_ID = "id";
export const PROPS_COMPANY_LOCATION_ID = "locationId";
export const PROPS_COMPANY_LOGO = "companyLogo";
export const PROPS_COMPANY_TYPE_GUID = "companyTypeGuid";
export const PROPS_COMPANY_TYPE_LIST = "companyTypeList";
export const PROPS_COMPANY_UI_LABEL_LIST = "companyUiLabelList";
export const PROPS_COST_CENTER_NUMBER = "costCenterNumber";
export const PROPS_COUNTRIES_LIST = "countriesList";
export const PROPS_DATA_POOLING = "dataPooling";
export const PROPS_DATA_POOLING_CUSTOMER_DEFAULT = "dataPoolingCustomerDefault";
export const PROPS_EMAIL_ADDRESS = "emailAddress";
export const PROPS_ENROLLMENT_REQUIRED = "enrollmentRequired";
export const PROPS_ENROLLMENT_LOCK_CUSTOMERS = "lockCustomersNotEnrolled";
export const PROPS_ERROR_MESSAGE = "errorMessage";
export const PROPS_HAS_CHILDREN = "hasChildren";
export const PROPS_IS_ACTIVE = "isActive";
export const PROPS_MOBILE_COMPANY_ICON = "mobileCompanyIcon";
export const PROPS_MOBILE_UI_THEME_GUID = "mobileUiThemeGuid";
export const PROPS_NOTES = "notes";
export const PROPS_ORG_LEVEL_ADDRESS_LIST = "companyAddressList";
export const PROPS_ORG_LEVEL_CITY = "city";
export const PROPS_ORG_LEVEL_GUID = "orgLevelGuid";
export const PROPS_ORG_LEVEL_NAME = "orgLevelName";
export const PROPS_ORG_LEVEL_STATE_ABBR = "stateAbbr";
export const PROPS_ORG_LEVEL_URL_LIST = "companyUrlList";
export const PROPS_ORG_TYPE_LIST = "orgTypeList";
export const PROPS_OWNER_ADMIN_USER_LIST = "ownerAdminUserList";
export const PROPS_OWNER_ADMIN_USER_GUID = "ownerAdminUserGuid";
export const PROPS_OWNER_BRANDING_NAME = "systemName";
export const PROPS_OWNER_PERSONALITY_GUID = "ownerPersonalityGuid";
export const PROPS_OWNER_PHONE_NUMBER_LIST = "companyPhoneNumberList";
export const PROPS_PERSONALITIES_LIST = "personalitiesList";
export const PROPS_PERSON_AFFILIATION_LIST = "personAffiliationList";
export const PROPS_PERSON_ORG_LEVEL_LIST = "personOrgLevelList";
export const PROPS_POLICY_LAST_UPDATED = "privacyPolicyUpdatedDate";
export const PROPS_PRIVACY_POLICY_TEXT = "privacyPolicyText";
export const PROPS_PRIVACY_POLICY_TITLE = "privacyPolicyTitle";
export const PROPS_RESPONSE_FROM_EMAIL = "responseFromEmail";
export const PROPS_SEND_RESULTS_TO_EMAIL_ADDRESS = "sendResultsToEmailAddress";
export const PROPS_STATES_LIST = "stateList";
export const PROPS_SYSTEM_FAVICON = "systemFavicon";
export const PROPS_SYSTEM_LOGO = "brandLogo";
export const PROPS_SYSTEM_LOGO_DARK = "systemLogoDark";
export const PROPS_SYSTEM_LOGO_LIGHT = "systemLogoLight";
export const PROPS_SYSTEM_SETTINGS = "systemProperties";
export const PROPS_TEST_RESULT_ISSUES_EMAIL_LIST = "testResultIssuesEmailList";
export const PROPS_THEME_LIST = "themeList";
export const PROPS_UI_THEME_LIST = "uiThemeList";
export const PROPS_UNIT_SYSTEM_GUID = "unitSystemGuid";
export const PROPS_WEB_COMPANY_ICON = "webCompanyIcon";
export const PROPS_WEB_UI_THEME_GUID = "webUiThemeGuid";
export const PROPS_CAN_EDIT_REPORT_LOGO = "canEditReportLogo";
export const PROPS_UPDATED_AGVANCE_ORG_LEVEL_GUID = "updatedAgvanceOrgLevelGuid";
export const PROPS_REQUIRE_CUSTOMER_SEARCH = "requireCustomerSearch";
