import React, { ReactElement } from "react";
import classnames from "classnames";
import "./section.css";
import "./section-responsive.css";

export interface ISectionProps {
    children?: ReactElement | ReactElement[];
    headerText?: string;
    required?: boolean;
    className?: string;
}

const section = ({ children, headerText, required, className }: ISectionProps): ReactElement => {
    return (
        <div className={classnames("form-section", className)}>
            {headerText && (
                <div className="form-section-header">
                    {required && <span className="red-star">*</span>}
                    <span>{headerText}</span>
                </div>
            )}
            <div className="form-section-children">{children}</div>
        </div>
    );
};

export default section;
