export const EventModulePages = {
    EVENT_LIST: "EVENT_LIST",
    EVENT_INFORMATION: "EVENT_INFORMATION",
};

export const SET_ACTIVE_PAGE = "event-module/SET_ACTIVE_PAGE";
export const SET_TRIGGERED_PAGE = "event-module/SET_TRIGGERED_PAGE";

export const setActivePage = (activePage) => ({
    type: SET_ACTIVE_PAGE,
    payload: { activePage },
});

export const setTriggeredPage = (triggeredPage) => ({
    type: SET_TRIGGERED_PAGE,
    payload: { triggeredPage },
});
