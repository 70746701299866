import React, { Component, ReactElement, ReactNode } from "react";

import classnames from "classnames";
import TableCell from "../../data-table/components/table-cell";
// icons
import { DownArrowIcon, UpArrowIcon } from "~/core/icons";
import { IColumnData, ISortOptions, IRecord, IIndex } from "../../interfaces";

const DESC = "DESC";
const ASC = "ASC";

export interface IHeaderCellProps {
    columnName: IColumnData | IColumnData[];
    sortOptions?: ISortOptions;
    classNames?: string;
    onSortOption?: (columnName: IColumnData) => void;
}

export const HeaderCell = ({
    columnName,
    sortOptions,
    classNames = "",
    onSortOption,
}: IHeaderCellProps): ReactElement => {
    const { sortColumnName, sortDirection } = sortOptions;
    const className = classnames("data-header-cell", classNames);
    function renderSortIcon(columnName: IColumnData): ReactElement {
        if (sortDirection && sortColumnName.toUpperCase() === columnName.dataKey.toUpperCase()) {
            if (sortDirection === DESC) {
                return <DownArrowIcon className="data-header-sorting-icon" />;
            } else {
                return <UpArrowIcon className="data-header-sorting-icon" />;
            }
        }
        return <p />;
    }

    return (
        <div
            className={classnames(className)}
            onClick={() => onSortOption(columnName as IColumnData)}
        >
            <TableCell title={(columnName as IColumnData).title}>
                {(columnName as IColumnData).title}
            </TableCell>
            <a className="plain-data-table-sort-icon " href="#/">
                {renderSortIcon(columnName as IColumnData)}
            </a>
        </div>
    );
};

export interface IPlainTableHeaderProps {
    sortRecords?: (sort: ISortOptions) => void;
    columns: IColumnData | IColumnData[];
    classNames?: string;
    onDelete?: (record: IRecord, index: IIndex) => void;
    onEdit?: (record: IRecord, index: IIndex) => void;
}

export interface IPlainTableHeaderState {
    sortOptions: ISortOptions;
}

export default class PlainTableHeader extends Component<
    IPlainTableHeaderProps,
    IPlainTableHeaderState
> {
    constructor(props: IPlainTableHeaderProps) {
        super(props);
        this.state = {
            sortOptions: {},
        };
    }

    private onSortOption = ({ dataKey }): void => {
        const { sortOptions } = this.state;
        this.setState(
            {
                sortOptions: {
                    sortColumnName: dataKey,
                    sortDirection:
                        sortOptions.sortColumnName === dataKey && sortOptions.sortDirection === ASC
                            ? DESC
                            : ASC,
                },
            },
            () => {
                const { sortOptions } = this.state;
                const { sortColumnName } = sortOptions;
                this.props.sortRecords && this.props.sortRecords({ sortOptions, sortColumnName });
            }
        );
    };

    render(): ReactNode {
        const { columns, classNames, onDelete, onEdit } = this.props;
        const columnKeys = Object.keys(columns);
        const className = classnames("data-table-row", "data-table-header", classNames);
        return (
            <div className={className}>
                {!onDelete ? null : <TableCell className={"z-n-icon"}></TableCell>}
                {!onEdit ? null : <TableCell className={"z-n-icon"}></TableCell>}
                {columnKeys.map((columnName) => (
                    <HeaderCell
                        key={`header-cell-${columnName}`}
                        sortOptions={this.state.sortOptions}
                        onSortOption={this.onSortOption}
                        columnName={{ dataKey: columnName, ...columns[columnName] }}
                        classNames={columns[columnName].className}
                    />
                ))}
            </div>
        );
    }
}
