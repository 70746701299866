import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ImportWizard_ } from "../components/import-wizard";

import * as selectors from "../selectors";
import * as actions from "../actions";
import * as panelActions from "../../../actions";
import { getImportTemplates, getImportFiles } from "~/file-import/selectors";

const mapStateToProps = (state) => ({
    isFieldMatchingLoading: selectors.getIsFieldMatchingLoading(state),
    isFieldMatchingComplete: selectors.getIsFieldMatchingComplete(state),
    isLoading: selectors.getIsLoading(state),
    currentStep: selectors.getImportWizardStep(state),
    eventStripCount: selectors.getEventStripCount(state),
    hasSubmitted: selectors.getHasSubmitted(state),
    importFieldStripData: selectors.getImportFieldStripData(state),
    importFieldList: selectors.getImportFieldList(state),
    importFileGuidList: selectors.getImportFileGuidList(state),
    importFiles: getImportFiles(state),
    importType: selectors.getImportWizardType(state) || {},
    ignoreSelectedField: selectors.getIgnoreSelectedField(state),
    isFilteringVisible: selectors.getIsFilteringVisible(state),
    importTemplates: getImportTemplates(state),
    isValidating: selectors.getIsValidating(state),
    showImportPoints: selectors.getShowImportPoints(state),
});

const mapDispatchToProps = (dispatch) => ({
    setIsLoading: (isLoading) => dispatch(actions.setIsLoading(isLoading)),
    changeStep: (nextStep) => dispatch(actions.setImportWizardStep(nextStep)),
    closeImportWizard: (isCancel) => dispatch(panelActions.closeImportWizard(isCancel)),
    submitFieldBoundary: () => dispatch(actions.submitFieldBoundary()),
    setIgnoreSelectedField: (ignoreSelectedField) =>
        dispatch(actions.setIgnoreSelectedField(ignoreSelectedField)),
    setIsFilteringVisible: (isFilteringVisible) =>
        dispatch(actions.setIsFilteringVisible(isFilteringVisible)),
    setShowImportPoints: (showImportPoints) =>
        dispatch(actions.setShowImportPoints(showImportPoints)),
    validateImportEvent: () => dispatch(actions.validateImportEvent()),
});

export const ImportWizard = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImportWizard_));
