import { actions as uploaderActions } from "~/action-panel/components/common/drag-and-drop-file-uploader";

import * as actions from "./actions";
import * as models from "./models";

export const newInitialState: models.IRXFileImportState = {
    currentStep: "upload",
    filesValidated: false,
    isLoading: false,
    matchData: null,
    matchDataCurrent: false,
    selectedColumns: [],
};

export const rxFileImportReducer = (state = newInitialState, action): models.IRXFileImportState => {
    switch (action.type) {
        case actions.RX_FILE_IMPORT_RESET: {
            return Object.freeze({
                ...newInitialState,
            });
        }
        case actions.SET_IMPORT_COLUMN: {
            const {
                column,
                recNutrientEquationName,
            }: {
                column: models.RXFileImportSelectableColumnValue;
                recNutrientEquationName: string;
            } = action.payload;
            const { selectedColumns } = state;
            const newSelectedColumns = selectedColumns.filter(
                (r) => r.recNutrientEquationName !== recNutrientEquationName
            );
            if (column) {
                newSelectedColumns.push({
                    recNutrientEquationName,
                    column,
                });
            }
            return Object.freeze({
                ...state,
                selectedColumns: newSelectedColumns,
            });
        }
        case actions.SET_IMPORT_PRODUCT_REC_MATCH_RESULT: {
            const { importProductRecMatchResult } = action.payload;
            const filesValidated =
                importProductRecMatchResult.invalid.length == 0 &&
                importProductRecMatchResult.nonMatchingFiles.length == 0 &&
                importProductRecMatchResult.nonMatchingFields.length == 0;
            return Object.freeze({
                ...state,
                filesValidated,
                matchData: importProductRecMatchResult,
                matchDataCurrent: true,
            });
        }
        case actions.SET_COMPLETE_STEP: {
            return Object.freeze({
                ...state,
                currentStep: "complete",
            });
        }
        case actions.SET_MATCH_STEP: {
            return Object.freeze({
                ...state,
                currentStep: "match",
            });
        }
        case actions.SET_UPLOAD_STEP: {
            return Object.freeze({
                ...state,
                currentStep: "upload",
            });
        }
        case actions.SET_RX_FILE_IMPORT_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case uploaderActions.CLEAR_UPLOAD_FILE_LIST: {
            return Object.freeze({
                ...newInitialState,
            });
        }
        case uploaderActions.ADD_UPLOAD_INFO: {
            return Object.freeze({
                ...state,
                filesValidated: false,
                matchData: null,
                matchDataCurrent: false,
            });
        }
        case uploaderActions.REMOVE_FILE_FROM_LIST: {
            // No match data to check on
            if (!state.matchData) {
                return state;
            }
            // Remove any files that are no longer in the list
            const { uploadFileInfoGuid } = action.payload;
            const { matchData, matchDataCurrent } = state;
            const newMatchData = {
                ...matchData,
            };
            newMatchData.invalid = newMatchData.invalid.filter(
                (invalidFile) => invalidFile.importFileGuid !== uploadFileInfoGuid
            );
            newMatchData.nonMatchingFiles = newMatchData.nonMatchingFiles.filter(
                (importFileGuid) => importFileGuid !== uploadFileInfoGuid
            );
            newMatchData.matches = newMatchData.matches.filter(
                (match) => match.importFileGuid !== uploadFileInfoGuid
            );
            const filesValidated =
                newMatchData.invalid.length == 0 &&
                newMatchData.nonMatchingFiles.length == 0 &&
                newMatchData.nonMatchingFields.length == 0;
            return Object.freeze({
                ...state,
                filesValidated,
                matchData: newMatchData,
                matchDataCurrent: newMatchData.matches.length > 0 ? matchDataCurrent : false,
            });
        }
        default:
            return state;
    }
};
