import { defineMessages } from "react-intl";

export const messages = defineMessages({
    importTypePlaceholderText: {
        id: "importModule.uploadModal.importTypePlaceholderText",
        defaultMessage: "Data Type",
    },
    importTemplatePlaceholderText: {
        id: "importModule.uploadModal.importTemplatePlaceholderText",
        defaultMessage: "Format Type",
    },
    nonImportFilesErrDialogTitle: {
        id: "importModule.uploadModal.nonImportFilesErrDialogTitle",
        defaultMessage: "Warning",
    },
    nonImportFilesErrDialogMsg: {
        id: "importModule.uploadModal.nonImportFilesErrDialogMsg",
        defaultMessage: "{errorCount, number} files are not importable",
    },
    supportedFileTypes: {
        id: "importModule.uploadModal.supportedFileTypes",
        defaultMessage: "Supported File Types: {typesStr}",
    },
    uploadBtnText: {
        id: "importModule.uploadModal.uploadBtnText",
        defaultMessage: "Upload",
    },
    uploadModalTitle: {
        id: "importModule.uploadModal.uploadModalTitle",
        defaultMessage: "Select Format and File(s) To Upload",
    },
});
