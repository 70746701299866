import { createService } from "~/hocs/crud";
import * as model from "./model";
import { AddressAPI, HierarchyAPI } from "@ai360/core";
import { PICKLIST_PHONE_TYPE, getPickListCode } from "~/core/picklist/picklist-names";
import defaultOrgLevelRecord from "./default-record";
import { adminData } from "~/admin/data";

// Module Name
export const MODEL_NAME = "orgLevel";

// Request payload
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";
export const REQUEST_PAYLOAD_FILTER = "orgLevelFilter";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "orgLevelPageOptions";
export const REQUEST_PAYLOAD_SORT_LIST = "orgLevelSortList";
export const REQUEST_PAYLOAD_PARENT_GUID = "parentOrgLevelGuid";

// URLs
export const AUTO_SEARCH_URL = HierarchyAPI.AUTO_SEARCH_URL;
export const CREATE = HierarchyAPI.CREATE;
export const UPDATE = HierarchyAPI.UPDATE;
export const DELETE = HierarchyAPI.DELETE;
export const URL = HierarchyAPI.GET_ORG_LEVEL_CHILDREN;
export const URL_CHILDREN_WITH_PARENT_GUID = HierarchyAPI.URL_CHILDREN_WITH_PARENT_GUID;

export const dropdowns = {
    [model.PROPS_ADDRESS_TYPE_LIST]: AddressAPI.REQUEST_ADDRESS_TYPE,
    [model.PROPS_COUNTRIES_LIST]: AddressAPI.REQUEST_COUNTRIES,
    [model.PROPS_STATE_LIST]: { url: AddressAPI.REQUEST_STATES, model: "" },
};

export const pickLists = {
    [PICKLIST_PHONE_TYPE]: getPickListCode(PICKLIST_PHONE_TYPE),
};

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        orgLevelName: "",
        city: "",
        stateAbbreviation: "",
        isActive: "",
        orgLevelGuid: null,
        canDelete: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            FieldName: "OrgLevelName",
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
    [model.PROPS_PARENT_ORG_LEVEL_GUID]: "",
};

// Service
export const service = createService({
    id: model.PROPS_ORG_LEVEL_ID,
    guid: model.PROPS_ORG_LEVEL_GUID,
    REQUEST_PAYLOAD_PARENT_GUID: model.PROPS_PARENT_ORG_LEVEL_GUID,
    name: model.PROPS_ORG_LEVEL_NAME,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    dropdowns,
    pickLists,
    activeColumnName: adminData.PROPS_IS_ACTIVE,
    urls: {
        AUTO_SEARCH_URL,
        CREATE,
        DELETE,
        UPDATE,
        URL,
    },
    _defaultLabels: {
        [model.PROPS_ORG_LEVEL_NAME]: { label: "name", gridCol: 20 },
        [model.PROPS_CITY]: { label: "city", gridCol: 20 },
        [model.PROPS_STATE_ABBREVIATION]: { label: "stateAbbr", gridCol: 20 },
        [model.PROPS_IS_ACTIVE]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
        },
        [model.PROPS_CAN_DELETE]: { label: "canDelete" },
    },
    getDefaultRecord: () => ({ ...defaultOrgLevelRecord() }),
    defaultSort: defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
});
