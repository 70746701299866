// Model

export const PROPS_APPLICATION_STANDARD_DEVIATION = "applicationStandardDeviation";
export const PROPS_EC_DATA_STANDARD_DEVIATION = "ecDataStandardDeviation";
export const PROPS_END_PASS_DELAY_SECONDS = "endPassDelaySeconds";
export const PROPS_FIELD_MATCH_THRESHOLD_PERCENT = "fieldMatchThresholdPercent";
export const PROPS_IMPORT_DATA_FILTER_GUID = "importDataFilterGuid";
export const PROPS_MINIMUM_SWATH_WIDTH = "minimumSwathWidth";
export const PROPS_MAXIMUM_DISTANCE = "maximumDistance";
export const PROPS_MAXIMUM_SWATH_WIDTH = "maximumSwathWidth";
export const PROPS_PLANTING_STANDARD_DEVIATION = "plantingStandardDeviation";
export const PROPS_SPEED_LOWER_PERCENTILE = "speedLowerPercentile";
export const PROPS_SPEED_MINIMUM_ABSOLUTE = "speedMinimumAbsolute";
export const PROPS_SPEED_SCALE = "speedScale";
export const PROPS_SPEED_UPPER_PERCENTILE = "speedUpperPercentile";
export const PROPS_STANDARD_DEVIATION_THRESHOLD = "standardDeviationThreshold";
export const PROPS_START_PASS_DELAY_SECONDS = "startPassDelaySeconds";
export const PROPS_YIELD_LOWER_PERCENTILE = "yieldLowerPercentile";
export const PROPS_YIELD_MINIMUM_ABSOLUTE = "yieldMinimumAbsolute";
export const PROPS_YIELD_SCALE = "yieldScale";
export const PROPS_YIELD_SMOOTH_VELOCITY = "yieldSmoothVelocity";
export const PROPS_YIELD_STANDARD_DEVIATION = "yieldStandardDeviation";
export const PROPS_YIELD_UPPER_PERCENTILE = "yieldUpperPercentile";
