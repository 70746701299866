import PropTypes from "prop-types";
import { model } from "~/admin/setup/nutrient-removal-rate/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_CROP_CLASS_NAME]: PropTypes.string,
    [model.PROPS_CROP_CLASS_GUID]: PropTypes.string,
    [model.PROPS_CROP_GUID]: PropTypes.string,
    [model.PROPS_CROP_ID]: PropTypes.number,
    [model.PROPS_CROP_NAME]: PropTypes.string,
    [model.PROPS_CROP_PURPOSE]: PropTypes.string,
    [model.PROPS_CROP_PURPOSE_GUID]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_NUTRIENT_GUID]: PropTypes.string,
    [model.PROPS_NUTRIENT_ID]: PropTypes.number,
    [model.PROPS_NUTRIENT_NAME]: PropTypes.string,
    [model.PROPS_NUTRIENT_REMOVAL_RATE_GUID]: PropTypes.string,
    [model.PROPS_NUTRIENT_REMOVAL_RATE_ID]: PropTypes.number,
    [model.PROPS_TRANSMISSION_TYPE]: PropTypes.string,
    [model.PROPS_TRANSMISSION_TYPE_GUID]: PropTypes.string,
    [model.PROPS_RATE]: PropTypes.number,
    [model.PROPS_RATE_UNIT]: PropTypes.number,
    [model.PROPS_RATE_UNIT_GUID]: PropTypes.string,
};

export default PropTypes.shape(shape);
