import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    Section,
    SelectInput,
    SubSection,
    ZeroToInfiniteGrid,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import {
    getAgBytesErrorClassNames,
    mapToPicklistValue,
    prepareSelectableOptions,
    hasOnlyInActiveOptions,
} from "~/admin/utils";
import { adminData, GUID, NAME, VALUE } from "~/admin/data";
import { messages } from "./../i18n-messages";
import { createAddEditModalTitle, createAddLinkLabelText } from "~/i18n-messages";

const PROPS_CROP_CLASS_NAME = "cropClassName";
const PROPS_CROP_CLASS_NAME_GUID = "cropClassNameGuid";
const PROPS_CROP_GUID = "cropGuid";
const PROPS_CROP_NAME = "cropName";
const PROPS_CROP_PURPOSE = "cropPurpose";
const PROPS_CROP_PURPOSE_GUID = "cropPurposeGuid";
const PROPS_CROP_PURPOSE_NAME = "cropPurposeName";
const PROPS_GROWTH_STAGE_ORDER = "growthStageOrder";
const PROPS_GROWTH_STAGE_ORDER_GUID = "growthStageOrderGuid";
const PROPS_GROWTH_STAGE_ORDER_NAME = "growthStageOrderName";

class CropList extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        deleteItem: PropTypes.func,
        editData: PropTypes.object,
        fetchCropClassData: PropTypes.func,
        fetchGrowthStageData: PropTypes.func,
        formatMessage: PropTypes.func,
        getUpdatedLists: PropTypes.func,
        isDeleting: PropTypes.bool,
        isEditing: PropTypes.bool,
        onAdd: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        picklistOptions: PropTypes.object,
        record: PropTypes.array,
        renderDeleteModal: PropTypes.func,
        toggleModal: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            cropClassName: [],
            cropName: [],
            cropPurpose: [],
            editingItem: false,
            growthStageOrder: [],
        };
    }

    initializeCropListData = ({ record = [], picklistOptions }) => {
        if (record) {
            const { cropClassName, cropName, cropPurpose, growthStageOrder } = picklistOptions;
            return record.map((recordItem) => {
                const selectedCropName = cropName.find((cropNameItem) => {
                    return cropNameItem.guid === recordItem[PROPS_CROP_GUID];
                });
                recordItem[PROPS_CROP_GUID] = selectedCropName && selectedCropName[GUID];
                const selectedCropPurpose = cropPurpose.find((cropPurposeItem) => {
                    return cropPurposeItem.value.guid === recordItem[PROPS_CROP_PURPOSE_GUID];
                });
                recordItem[PROPS_CROP_PURPOSE_NAME] =
                    selectedCropPurpose && selectedCropPurpose[VALUE][NAME];
                const selectedCropClassName = cropClassName.find((cropClassNameItem) => {
                    return cropClassNameItem.guid === recordItem[PROPS_CROP_CLASS_NAME_GUID];
                });
                recordItem[PROPS_CROP_CLASS_NAME_GUID] = selectedCropClassName
                    ? selectedCropClassName[GUID]
                    : recordItem[PROPS_CROP_CLASS_NAME_GUID];
                const selectedGrowthStageOrder = growthStageOrder.find((growthStageOrderItem) => {
                    return growthStageOrderItem.guid === recordItem[PROPS_GROWTH_STAGE_ORDER_GUID];
                });
                recordItem[PROPS_GROWTH_STAGE_ORDER_GUID] = selectedGrowthStageOrder
                    ? selectedGrowthStageOrder[GUID]
                    : recordItem[PROPS_GROWTH_STAGE_ORDER_GUID];
                return recordItem;
            });
        }
        return record;
    };

    initializeDropdowns = (options) => {
        this.setState({
            cropName: prepareSelectableOptions(options[PROPS_CROP_NAME], {
                guid: GUID,
                label: NAME,
            }),
            cropClassName: prepareSelectableOptions(options[PROPS_CROP_CLASS_NAME], {
                guid: GUID,
                label: NAME,
            }),
            cropPurpose: options[PROPS_CROP_PURPOSE],
            growthStageOrder: prepareSelectableOptions(options[PROPS_GROWTH_STAGE_ORDER], {
                guid: GUID,
                label: NAME,
            }),
        });
    };

    onAdd = () => {
        this.clearDependentLists();
        this.initializeDropdowns(this.props.picklistOptions, {});
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    onCropNameChange = (selectedCrop) => {
        if (selectedCrop && this.props.getUpdatedLists) {
            this.props.getUpdatedLists(selectedCrop);
        }
    };
    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onEdit = (record, index) => {
        if (record[PROPS_CROP_GUID]) {
            this.props.fetchGrowthStageData(record[PROPS_CROP_GUID]);
            this.props.fetchCropClassData(record[PROPS_CROP_GUID]);
        }
        this.initializeDropdowns(this.props.picklistOptions, record);
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_CROP_GUID],
                key: [GUID],
            },
            {
                name: [PROPS_CROP_NAME],
                key: [NAME],
            },
            {
                name: [PROPS_CROP_PURPOSE_GUID],
                key: [GUID],
            },
            {
                name: [PROPS_CROP_PURPOSE_NAME],
                key: [NAME],
            },
            {
                name: [PROPS_CROP_CLASS_NAME_GUID],
                key: [GUID],
            },
            {
                name: [PROPS_CROP_CLASS_NAME],
                key: [NAME],
            },
            {
                name: [PROPS_GROWTH_STAGE_ORDER_GUID],
                key: [GUID],
            },
            {
                name: [PROPS_GROWTH_STAGE_ORDER_NAME],
                key: [NAME],
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.picklistOptions[PROPS_CROP_CLASS_NAME] !==
                nextProps.picklistOptions[PROPS_CROP_CLASS_NAME] ||
            this.props.picklistOptions[PROPS_GROWTH_STAGE_ORDER] !==
                nextProps.picklistOptions[PROPS_GROWTH_STAGE_ORDER]
        ) {
            this.initializeCropListData(nextProps);
        }
        if (this.props.isEditing === nextProps.isEditing) {
            if (
                nextProps.picklistOptions[PROPS_CROP_CLASS_NAME] ||
                nextProps.picklistOptions[PROPS_GROWTH_STAGE_ORDER]
            ) {
                this.initializeDropdowns(nextProps.picklistOptions);
            }
        }
    }
    clearDependentLists = () => {
        this.props.picklistOptions[PROPS_CROP_CLASS_NAME] = [];
        this.props.picklistOptions[PROPS_GROWTH_STAGE_ORDER] = [];
        this.setState({
            [PROPS_CROP_CLASS_NAME]: [],
            [PROPS_GROWTH_STAGE_ORDER]: [],
        });
    };
    renderAddEditModal = () => {
        const { editData, formatMessage, isEditing, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                unrestricted={true}
                forceOverflow
                actionDisabled={!formData[PROPS_CROP_GUID]}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.crop
                )}
            >
                <div className="dialog-box-select">
                    <Section>
                        <SubSection>
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                autoFocus
                                openOnFocus={false}
                                initialFilterStr=""
                                options={this.state.cropName}
                                placeholderText={formatMessage(messages.cropName)}
                                value={mapToPicklistValue({
                                    options: this.state.cropName,
                                    selectedGuid: formData[PROPS_CROP_GUID],
                                })}
                                onChange={(value = "") => {
                                    const { guid, name } = value;
                                    onChange(
                                        {
                                            [PROPS_CROP_GUID]: guid,
                                            [PROPS_CROP_NAME]: name,
                                        },
                                        this.onCropNameChange(guid)
                                    );
                                }}
                                required
                            />
                            {!this.state.cropPurpose ? null : (
                                <SelectInput
                                    tabIndex={0}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    options={this.state.cropPurpose}
                                    placeholderText={formatMessage(messages.cropPurpose)}
                                    value={mapToPicklistValue({
                                        options: this.state.cropPurpose,
                                        selectedGuid: formData[PROPS_CROP_PURPOSE_GUID],
                                    })}
                                    onChange={(value = "") => {
                                        const { guid, name } = value;
                                        onChange({
                                            [PROPS_CROP_PURPOSE_GUID]: guid,
                                            [PROPS_CROP_PURPOSE_NAME]: name,
                                        });
                                    }}
                                />
                            )}
                        </SubSection>
                        <SubSection>
                            {!(this.state.cropClassName.length > 0) ||
                            hasOnlyInActiveOptions(this.state.cropClassName) ? null : (
                                <SelectInput
                                    tabIndex={0}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    initialFilterStr=""
                                    options={this.state.cropClassName}
                                    placeholderText={formatMessage(messages.cropClassIdName)}
                                    value={mapToPicklistValue({
                                        options: this.state.cropClassName,
                                        selectedGuid: formData[PROPS_CROP_CLASS_NAME_GUID],
                                    })}
                                    onChange={(value = "") => {
                                        const { guid, name } = value;
                                        onChange({
                                            [PROPS_CROP_CLASS_NAME_GUID]: guid,
                                            [PROPS_CROP_CLASS_NAME]: name,
                                        });
                                    }}
                                />
                            )}
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                initialFilterStr=""
                                disabled={
                                    !formData[PROPS_CROP_GUID] ||
                                    this.state.growthStageOrder.length < 1
                                }
                                options={this.state.growthStageOrder}
                                placeholderText={formatMessage(messages.growthStageOrderIdName)}
                                value={mapToPicklistValue({
                                    options: this.state.growthStageOrder,
                                    selectedGuid: formData[PROPS_GROWTH_STAGE_ORDER_GUID],
                                })}
                                onChange={(value = "") => {
                                    const { guid, name } = value;
                                    onChange({
                                        [PROPS_GROWTH_STAGE_ORDER_GUID]: guid,
                                        [PROPS_GROWTH_STAGE_ORDER_NAME]: name,
                                    });
                                }}
                            />
                        </SubSection>
                    </Section>
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid crop-list-container"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_CROP_NAME]: {
                                title: formatMessage(messages.crop),
                            },
                            [PROPS_CROP_PURPOSE_NAME]: {
                                title: formatMessage(messages.cropPurpose),
                            },
                            [PROPS_GROWTH_STAGE_ORDER_NAME]: {
                                title: formatMessage(messages.growthStageOrderIdName),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        classNames={getAgBytesErrorClassNames(140, this.props.apiErrors, [
                            "add-link",
                        ])}
                        label={createAddLinkLabelText(formatMessage, messages.crop)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(CropList);
