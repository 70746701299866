import React, { Component } from "react";
import PropTypes from "prop-types";
import { DialogBox, DialogBoxFooterType, NoLink, SelectInput, ZeroToInfiniteGrid } from "~/core";
import { adminData, GUID } from "~/admin/data";
import { withEditableGrid } from "~/hocs";
import { prepareSelectableOptions } from "~/admin/utils";
import { messages } from "../../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";

const PROPS_VENDOR_GUID = "vendorGuid";
const PROPS_VENDOR_NAME = "vendorName";

class VendorList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            editOptions: [],
            editingItem: false,
        };
    }

    initializeVendors = (options) => {
        this.setState({
            editOptions: prepareSelectableOptions(options, {
                guid: PROPS_VENDOR_GUID,
                label: PROPS_VENDOR_NAME,
            }),
        });
    };

    onEdit = (record, index) => {
        this.initializeVendors(this.props.options, record);
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_VENDOR_NAME],
                key: PROPS_VENDOR_NAME,
            },
            {
                name: [PROPS_VENDOR_GUID],
                key: PROPS_VENDOR_GUID,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.initializeVendors(this.props.options, {});
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    getVendorValue = ({ options, selectedGuid }) => {
        if (options.length > 0 && selectedGuid) {
            const selectedOption = options.filter((option) => selectedGuid === option.value[GUID]);
            return selectedOption.length > 0 ? selectedOption[0].value : null;
        }
        return null;
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                draggable={true}
                forceOverflow
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={!editData[PROPS_VENDOR_GUID]}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.vendor
                )}
                className="overflow-content"
            >
                <div className="dialog-box-select">
                    <SelectInput
                        autoFocus={this.state.editOptions.length > 0}
                        openOnFocus={false}
                        tabIndex={0}
                        initialFilterStr=""
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state.editOptions}
                        disabled={this.state.editOptions.length === 0}
                        placeholderText={formatMessage(messages.vendor)}
                        value={this.getVendorValue({
                            options: this.state.editOptions,
                            selectedGuid: editData[PROPS_VENDOR_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_VENDOR_NAME]: value ? value.name : "",
                                [PROPS_VENDOR_GUID]: value ? value.guid : "",
                            });
                        }}
                    />
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_VENDOR_NAME]: {
                                title: formatMessage(messages.vendorName),
                            },
                        }}
                        className={"cell-stretch"}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.vendor)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(VendorList);
