import { ClassBreak, ZoneGeometryInfo } from "@ai360/core";
import { AgEventArea } from "./events/model";
import { RecArea } from "./recs/model";

import { AreaIdType } from "./reducer";
import { action } from "typesafe-actions";

export enum RecEventListTabs {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    SELECTED = "SELECTED",
}

export * from "./events/actions";
export * from "./recs/actions";
export * from "./analysis/actions";

export const CLEAR_ANALYSIS_DETAILS = "recs-events/CLEAR_ANALYSIS_DETAILS";
export const CONVERT_RECS_TO_EVENTS = "recs-events/CONVERT_RECS_TO_EVENTS";
export const CONVERT_REC_TO_EVENT_SUCCEEDED = "recs-events/CONVERT_REC_TO_EVENT_SUCCEEDED";
export const COPY_AREAS = "recs-events/COPY_AREAS";
export const REFRESH_FILTER_OPTIONS = "recs-events/REFRESH_FILTER_OPTIONS";
export const REMOVE_CHANGED_LAYER_ITEMS = "recs-events/REMOVE_CHANGED_LAYER_ITEMS";
export const RESET_CLASSIFIED_AREA_POLYGONS = "recs-events/RESET_CLASSIFIED_AREA_POLYGONS";
export const RESET_AREA_POLYGONS = "recs-events/RESET_AREA_POLYGONS";
export const RESTORE_AREA_LIST = "recs-events/RESTORE_AREA_LIST";
export const SAVE_AREA_LIST = "recs-events/SAVE_AREA_LIST";
export const SET_COPY_FROM_AREA_ID = "recs-events/SET_COPY_FROM_AREA_ID";
export const SET_COPY_TO_AREA_ID_SET = "recs-events/SET_COPY_TO_AREA_ID_SET";
export const SET_CURRENT_AREA_ID = "recs-events/SET_CURRENT_AREA_ID";
export const SET_CURRENT_BATCH_FIELD_GUID = "recs-events/SET_CURRENT_BATCH_FIELD_GUID";
export const SET_LAYERS_TO_UPDATE = "recs-events/SET_LAYERS_TO_UPDATE";
export const SET_ZONE_FILE_GUID = "recs-events/SET_ZONE_FILE_GUID";
export const SET_ZONES_LOADING = "recs-events/SET_ZONES_LOADING";
export const UPDATE_AREA_POLYGONS = "recs-events/UPDATE_AREA_POLYGONS";
export const UPDATE_SAVED_AREA_LISTS = "recs-events/UPDATE_SAVED_AREA_LISTS";

export const clearAnalysisDetails = () => action(CLEAR_ANALYSIS_DETAILS, null);

export const convertRecsToEvents = (recGeneralGuidToFieldGuidMap: Map<string, string>) =>
    action(CONVERT_RECS_TO_EVENTS, { recGeneralGuidToFieldGuidMap });

export const convertRecToEventSucceeded = () => action(CONVERT_REC_TO_EVENT_SUCCEEDED, null);

export const copyAreas = (fieldGuid: string, modelTypeGuid: string) =>
    action(COPY_AREAS, { fieldGuid, modelTypeGuid });

export const removeChangedLayerItems = (fieldGuids: Set<string>) =>
    action(REMOVE_CHANGED_LAYER_ITEMS, { fieldGuids });

export const refreshFilterOptions = () => action(REFRESH_FILTER_OPTIONS, null);

export const resetClassifiedAreaPolygons = (
    fieldGuid: string,
    areaIdToPolygonMap: Map<AreaIdType, ZoneGeometryInfo>,
    areaIdToClassBreaksMap: Map<number, ClassBreak>
) =>
    action(RESET_CLASSIFIED_AREA_POLYGONS, {
        fieldGuid,
        areaIdToPolygonMap,
        areaIdToClassBreaksMap,
    });

export const resetAreaPolygons = (
    fieldGuid: string,
    areaIdToPolygonMap: Map<AreaIdType, ZoneGeometryInfo>
) => action(RESET_AREA_POLYGONS, { fieldGuid, areaIdToPolygonMap });

export const restoreAreaList = (fieldGuid: string, restoreId: boolean) =>
    action(RESTORE_AREA_LIST, { fieldGuid, restoreId });
export const saveAreaList = (fieldGuid: string, restoreId: boolean) =>
    action(SAVE_AREA_LIST, { fieldGuid, restoreId });

export const setChangedLayerItems = (fieldGuids: Set<string>) =>
    action(SET_LAYERS_TO_UPDATE, { fieldGuids });

export const setCopyFromAreaId = (areaId: AreaIdType) => action(SET_COPY_FROM_AREA_ID, { areaId });

export const setCopyToAreaIdSet = (areaIdSet: Set<AreaIdType>) =>
    action(SET_COPY_TO_AREA_ID_SET, { areaIdSet });

export const setCurrentAreaId = (fieldGuid: string, areaId: AreaIdType) =>
    action(SET_CURRENT_AREA_ID, { fieldGuid, areaId });

export const setCurrentBatchFieldGuid = (batchFieldGuid: string) =>
    action(SET_CURRENT_BATCH_FIELD_GUID, { batchFieldGuid });

export const setZoneFileGuid = (fieldGuid: string, zoneFileGuid: string) =>
    action(SET_ZONE_FILE_GUID, { fieldGuid, zoneFileGuid });

export const setZonesLoading = (loading: boolean) => action(SET_ZONES_LOADING, { loading });

export const updateAreaPolygons = (
    fieldGuid: string,
    areaIdToNewAreaIdPolygonMap: Map<AreaIdType, Map<AreaIdType, ZoneGeometryInfo>>
) => action(UPDATE_AREA_POLYGONS, { fieldGuid, areaIdToNewAreaIdPolygonMap });

export const updateSavedAreaLists = (
    fieldGuid: string,
    restoreId: boolean,
    areaList: AgEventArea[] | RecArea[]
) => action(UPDATE_SAVED_AREA_LISTS, { fieldGuid, restoreId, areaList });
