import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";

import { Checkbox, DialogBox } from "~/core";
import { IFieldBoundaryLabelsShape } from "~/map/components/map-control";
import { messages } from "../../../i18n-messages";

export const FieldLabels = {
    CUSTOMER_NAME: 0,
    FARM_NAME: 1,
    FIELD_AREA: 2,
    FIELD_NAME: 3,
};

interface IFieldsLayerPreferencesProps {
    intl: intlShape;
    isOpen?: boolean;
    layerName?: string;
    onChangeShowLabels: (evt, label) => void;
    onClose: () => void;
    showLabels: IFieldBoundaryLabelsShape;
}

class FieldsLayerPreferences_ extends Component<IFieldsLayerPreferencesProps> {
    render() {
        const { isOpen, layerName, onChangeShowLabels, onClose, showLabels } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <DialogBox
                closeOnClickOff
                draggable
                isOpen={isOpen}
                onClose={() => onClose()}
                title={`${layerName} - ${formatMessage(messages.layerPreferences)}`}
            >
                <h1>{`${formatMessage(messages.showLabels)}:`}</h1>
                <div className="layer-props-section">
                    <Checkbox
                        label={formatMessage(messages.customerName)}
                        onChange={(evt) => onChangeShowLabels(evt, FieldLabels.CUSTOMER_NAME)}
                        value={showLabels.customerName}
                    />
                    <Checkbox
                        label={formatMessage(messages.farmName)}
                        onChange={(evt) => onChangeShowLabels(evt, FieldLabels.FARM_NAME)}
                        value={showLabels.farmName}
                    />
                    <Checkbox
                        label={formatMessage(messages.fieldName)}
                        onChange={(evt) => onChangeShowLabels(evt, FieldLabels.FIELD_NAME)}
                        value={showLabels.fieldName}
                    />
                    <Checkbox
                        label={formatMessage(messages.fieldArea)}
                        onChange={(evt) => onChangeShowLabels(evt, FieldLabels.FIELD_AREA)}
                        value={showLabels.fieldArea}
                    />
                </div>
            </DialogBox>
        );
    }
}

export const FieldsLayerPreferences = injectIntl(FieldsLayerPreferences_);
