import { connect } from "react-redux";
import ChemicalFertilizerInfo from "./chemical-fertilizer";
import { getPicklist } from "./../../../selectors";
import { getUnit } from "~/admin/selectors";
import {
    PICKLIST_CHEMICAL_FAMILY,
    PICKLIST_PRODUCT_ACTION_METHOD,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import {
    UNIT_PRODUCT_RATE_DRY,
    UNIT_PRODUCT_RATE_LIQUID,
    getUnitCode,
} from "~/core/units/unit-names";

const mapStateToProps = (state) => ({
    [PICKLIST_CHEMICAL_FAMILY]: getPicklist(getPickListCode(PICKLIST_CHEMICAL_FAMILY), state),
    [PICKLIST_PRODUCT_ACTION_METHOD]: getPicklist(
        getPickListCode(PICKLIST_PRODUCT_ACTION_METHOD),
        state
    ),
    [UNIT_PRODUCT_RATE_DRY]: getUnit(getUnitCode(UNIT_PRODUCT_RATE_DRY), state),
    [UNIT_PRODUCT_RATE_LIQUID]: getUnit(getUnitCode(UNIT_PRODUCT_RATE_LIQUID), state),
});

export default connect(mapStateToProps, null)(ChemicalFertilizerInfo);
