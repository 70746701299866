import { connect } from "react-redux";
import UsageReadingListView from "./usage-reading-list";
import { getPicklist, getUnit } from "~/admin/selectors";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";

const mapStateToProps = (state) => ({
    [picklistService.PICKLIST_USAGE_UNIT]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_USAGE_UNIT),
        state
    ),
    [unitService.UNIT_AREA]: getUnit(unitService.getUnitCode(unitService.UNIT_AREA), state),
});

export const UsageReadingList = connect(mapStateToProps, null)(UsageReadingListView);
