import { createSelector } from "reselect";

import { getModuleState as getEventModuleState } from "../../selectors";

import { getIgnorableEventGeneralGuids } from "~/recs-events/events/selectors";

import { filterOutEventsFromSetMap, filterOutEventsFromSet } from "./data";

export const EVENT_LIST_STATE_KEY = "EVENT_LIST_STATE";
export const ACCORDION_KEY = "ACCORDION";

export const getModuleState = (state) => getEventModuleState(state)[EVENT_LIST_STATE_KEY];

export const getAccordionState = (state) => getModuleState(state)[ACCORDION_KEY];

export const getAccordionItems = (state) => getAccordionState(state).items;

export const getFieldGuidToSelectedEventGuidSetMap = createSelector(
    (state) => getModuleState(state).fieldGuidToSelectedEventGuidSetMap,
    getIgnorableEventGeneralGuids,
    filterOutEventsFromSetMap
);

export const getFieldGuidToDimIdxMap = createSelector(getAccordionItems, (itemList) => {
    const fieldGuidToDimIdxMap = new Map();
    itemList.forEach((fieldItem, i) => {
        fieldGuidToDimIdxMap.set(fieldItem.payload.fieldGuid, [i]);
    });
    return fieldGuidToDimIdxMap;
});

export const getAccordionId = (state) => getAccordionState(state).accordionId;

export const getEventFilter = (state) => getModuleState(state).filter;

export const getExpandedFieldGuidSet = (state) => getModuleState(state).expandedFieldGuidSet;

export const getEventPanelLoading = (state) => getModuleState(state).isPanelLoading;

export const getErrorCodes = (state) => getModuleState(state).errorCodes;

export const getErrorToShow = (state) => getModuleState(state).errorDetailsToShow;

export const getIsEventLoading = (state) => getModuleState(state).isEventLoading;

export const getSelectedEventGuidSet = createSelector(
    (state) => getModuleState(state).selectedEventGuidSet,
    getIgnorableEventGeneralGuids,
    filterOutEventsFromSet
);

export const getSelectedEventFieldGuidSet = createSelector(
    getFieldGuidToSelectedEventGuidSetMap,
    (fieldGuidToSelectedEventGuidSetMap) =>
        [...fieldGuidToSelectedEventGuidSetMap.entries()].reduce((accumulator, item) => {
            const [fieldGuid, eventGuidSet] = item;
            if (eventGuidSet.size > 0) {
                accumulator.add(fieldGuid);
            }
            return accumulator;
        }, new Set())
);

export const getYieldCalibrationLoading = (state) =>
    getModuleState(state).yieldCalibrationInProgress;

export const getShowMergeEventsModal = (state) => getModuleState(state).showMergeEventsModal;

export const getShowFilters = (state) => getModuleState(state).showFilters;
export const getSearchValue = (state) => getModuleState(state).searchValue;
