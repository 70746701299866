import React from "react";
import { Button } from "~/core";
import "./button-bar.css";

interface IButtonBarProps {
    saveIsEnabled: boolean;
    onSave: () => void;
    onCancel: () => void;
}

export class ButtonBar extends React.Component<IButtonBarProps> {
    render() {
        return (
            <div className="non-field-feature-information-button-bar">
                <Button
                    type="save"
                    disabled={!this.props.saveIsEnabled}
                    onClick={() => {
                        this.props.onSave();
                    }}
                />
                <Button
                    type="cancel"
                    onClick={() => {
                        this.props.onCancel();
                    }}
                />
            </div>
        );
    }
}
