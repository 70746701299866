import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";

import { messages } from "../../i18n-messages";

import { Checkbox, DialogBox, DialogBoxFooterType, SelectInput } from "~/core";
import { ZoneClassificationUtils } from "@ai360/core";

import "./import-zones-modal.css";

class ImportZonesModal_ extends Component {
    static propTypes = {
        classFields: PropTypes.arrayOf(PropTypes.string),
        features: PropTypes.array,
        intl: intlShape.isRequired,
        isOpen: PropTypes.bool,
        onAction: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedField: null,
            isNumeric: false,
            numberOfZones: 0,
            notValidForClassifying: false,
            allAreNullOrEmpty: false,
            useNaturalBreaks: false,
            useUniqueValues: false,
        };
    }

    _updateClassificationProps(field, useUniqueValues) {
        const { features } = this.props;
        const zoneClassificationProps = ZoneClassificationUtils.getZoneClassificationProperties(
            features,
            field
        );
        const uniqueValues = field ? zoneClassificationProps.uniqueFieldValues : [];
        const notValidForClassifying =
            zoneClassificationProps.allAreNullOrEmpty ||
            (uniqueValues.length > ZoneClassificationUtils.MAX_UNIQUE_VALUE_THRESHOLD &&
                (useUniqueValues || !zoneClassificationProps.allAreNumeric));
        const useNaturalBreaks =
            !useUniqueValues &&
            uniqueValues.length >
                ZoneClassificationUtils.NUMBER_OF_CLASSES_UNIQUE_VALUE_THRESHOLD &&
            zoneClassificationProps.allAreNumeric;
        const numberOfZones = useNaturalBreaks
            ? ZoneClassificationUtils.NATURAL_BREAKS_NUMBER_OF_CLASSES
            : uniqueValues.length;
        this.setState({
            selectedField: field,
            isNumeric: zoneClassificationProps.allAreNumeric,
            numberOfZones,
            notValidForClassifying,
            allAreNullOrEmpty: zoneClassificationProps.allAreNullOrEmpty,
            useNaturalBreaks,
            useUniqueValues: useUniqueValues,
        });
    }

    _onAction() {
        const { classFields } = this.props;
        const { selectedField, useNaturalBreaks } = this.state;
        this.props.onAction(classFields ? selectedField : null, useNaturalBreaks);
    }

    _onClassFieldChanged(field) {
        this._updateClassificationProps(field, false);
    }

    _onClose() {
        this.setState({
            selectedField: null,
            isNumeric: false,
            numberOfZones: 0,
            notValidForClassifying: false,
            allAreNullOrEmpty: false,
            useNaturalBreaks: false,
            useUniqueValues: false,
        });
        this.props.onClose();
    }

    _onUseUniqueValuesChanged(useUniqueValues) {
        const { selectedField } = this.state;
        this._updateClassificationProps(selectedField, useUniqueValues);
    }

    _renderCheckbox() {
        const { formatMessage } = this.props.intl;
        const { features } = this.props;
        const { selectedField, isNumeric, notValidForClassifying, useUniqueValues } = this.state;
        const uniqueValuesText = formatMessage(messages.useUniqueValues);
        const zoneClassificationProps = ZoneClassificationUtils.getZoneClassificationProperties(
            features,
            selectedField
        );
        const uniqueValues = selectedField ? zoneClassificationProps.uniqueFieldValues : [];
        const showCheckbox =
            selectedField &&
            isNumeric &&
            uniqueValues.length >
                ZoneClassificationUtils.NUMBER_OF_CLASSES_UNIQUE_VALUE_THRESHOLD &&
            (!notValidForClassifying || useUniqueValues);

        return (
            showCheckbox && (
                <Checkbox
                    label={uniqueValuesText}
                    value={useUniqueValues}
                    onChange={(e, v) => this._onUseUniqueValuesChanged(v)}
                />
            )
        );
    }

    _saveEnabled() {
        const { selectedField, notValidForClassifying } = this.state;
        return !selectedField || !notValidForClassifying;
    }

    render() {
        const { classFields, features, isOpen } = this.props;
        const { numberOfZones, selectedField, notValidForClassifying, allAreNullOrEmpty } =
            this.state;
        const { formatMessage } = this.props.intl;
        const confirmationText = formatMessage(messages.promptImportZoneReplaceMsg);
        const confirmationTitle = formatMessage(messages.promptImportZoneReplaceTitle);
        const numberOfFeatures = features ? features.length : 0;
        const classifyOptionText = formatMessage(messages.promptImportZoneClassifyOptionText, {
            count: numberOfFeatures,
        });
        const classifySelectPlaceholderText = formatMessage(
            messages.promptImportZoneClassifySelectPlaceholderText
        );
        const outputNumberOfZones = selectedField ? numberOfZones : numberOfFeatures;
        const numberOfZonesText =
            selectedField && notValidForClassifying
                ? allAreNullOrEmpty
                    ? formatMessage(messages.promptImportZoneNoDataText)
                    : formatMessage(messages.promptImportZoneInvalidSelectionText, {
                          count: ZoneClassificationUtils.MAX_UNIQUE_VALUE_THRESHOLD,
                      })
                : formatMessage(messages.promptImportZoneNumberToBeCreatedText, {
                      count: outputNumberOfZones,
                  });

        return (
            <DialogBox
                draggable
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={isOpen}
                actionDisabled={!this._saveEnabled()}
                onAction={() => this._onAction()}
                onClose={() => this._onClose()}
                title={confirmationTitle}
            >
                {confirmationText}
                {classFields ? (
                    <div className="import-zones-modal-form">
                        <br />
                        {classifyOptionText}
                        <br />
                        <div className="inputs">
                            <SelectInput
                                disabled={false}
                                options={classFields.map((f) => ({
                                    value: f,
                                    label: f,
                                }))}
                                onChange={(f) => this._onClassFieldChanged(f)}
                                placeholderText={classifySelectPlaceholderText}
                                value={selectedField}
                            />
                            <div className="checkbox-input">{this._renderCheckbox()}</div>
                        </div>
                        <br />
                        <div
                            className={classNames([
                                "nbr-zones-msg",
                                { error: notValidForClassifying },
                            ])}
                        >
                            {numberOfZonesText}
                        </div>
                    </div>
                ) : null}
            </DialogBox>
        );
    }
}

export const ImportZonesModal = injectIntl(ImportZonesModal_);
