import React, { Component } from "react";
import PropTypes from "prop-types";
import { messages } from "../../../i18n-messages";
import {
    Bucket,
    BucketHeader,
    Checkbox,
    NumericInput,
    Section,
    SelectInput,
    SubSection,
    TextInput,
} from "~/core";
import { model, service } from "../../data";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";

export default class Vehicle extends Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        onTextChange: PropTypes.func,
        onNumberChange: PropTypes.func,
        onSelectInputChange: PropTypes.func,
        equipmentSetup: PropTypes.object,
        equipmentParentId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            equipmentSetup: {},
            [picklistService.PICKLIST_HITCH_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_HITCH_TYPE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [picklistService.PICKLIST_TIRES_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_TIRES_TYPE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [unitService.UNIT_WEIGHT]: prepareSelectableOptions(props[unitService.UNIT_WEIGHT], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_LENGTH]: prepareSelectableOptions(props[unitService.UNIT_LENGTH], {
                guid: GUID,
                label: NAME,
            }),
            [unitService.UNIT_PRESSURE]: prepareSelectableOptions(
                props[unitService.UNIT_PRESSURE],
                { guid: GUID, label: NAME }
            ),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps[picklistService.PICKLIST_HITCH_TYPE] !==
            this.props[picklistService.PICKLIST_TIRES_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_HITCH_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_HITCH_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
        if (
            nextProps[picklistService.PICKLIST_TIRES_TYPE] !==
            this.props[picklistService.PICKLIST_TIRES_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_TIRES_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_TIRES_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
        if (nextProps[unitService.UNIT_WEIGHT] !== this.props[unitService.UNIT_WEIGHT]) {
            this.setState({
                [unitService.UNIT_WEIGHT]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_WEIGHT],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (nextProps[unitService.UNIT_LENGTH] !== this.props[unitService.UNIT_LENGTH]) {
            this.setState({
                [unitService.UNIT_LENGTH]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_LENGTH],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (nextProps[unitService.UNIT_PRESSURE] !== this.props[unitService.UNIT_PRESSURE]) {
            this.setState({
                [unitService.UNIT_PRESSURE]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_PRESSURE],
                    { guid: GUID, label: NAME }
                ),
            });
        }
        if (
            JSON.stringify(nextProps.equipmentSetup) !== JSON.stringify(this.props.equipmentSetup)
        ) {
            this.setState({
                equipmentSetup: nextProps.equipmentSetup,
            });
        }
    }

    render() {
        const {
            formatMessage,
            onTextChange,
            onNumberChange,
            onSelectInputChange,
            equipmentParentId,
        } = this.props;
        const { equipmentSetup } = this.state;
        const { EQUIPMENT_PARENT_ID_KEYSET } = service;
        return (
            <Bucket
                className="form-section-bucket"
                showSymbol={true}
                isCollapsible={true}
                isExpanded={false}
            >
                <BucketHeader>{formatMessage(messages.vehicle)}</BucketHeader>
                <Section>
                    {equipmentParentId !== EQUIPMENT_PARENT_ID_KEYSET.Tractor ? null : (
                        <SubSection>
                            <Checkbox
                                value={equipmentSetup[model.PROPS_CAB]}
                                onChange={(e, value) =>
                                    this.props.onTextChange(model.PROPS_CAB, value)
                                }
                                label={formatMessage(messages.cab)}
                            />
                        </SubSection>
                    )}
                    <SubSection>
                        <SelectInput
                            placeholderText={formatMessage(messages.hitchType)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_HITCH_TYPE]}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_HITCH_TYPE],
                                selectedGuid: equipmentSetup[model.PROPS_HITCH_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_HITCH_TYPE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <SelectInput
                            placeholderText={formatMessage(messages.tireType)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_TIRES_TYPE]}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_TIRES_TYPE],
                                selectedGuid: equipmentSetup[model.PROPS_TIRES_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_TIRES_TYPE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection className="column-3-gutter">
                        <TextInput
                            maxLength={2}
                            placeholderText={formatMessage(messages.speedRating)}
                            value={equipmentSetup[model.PROPS_TIRES_SPEED_RATING]}
                            onChange={(value) => {
                                onTextChange(model.PROPS_TIRES_SPEED_RATING, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.loadRating)}
                            value={equipmentSetup[model.PROPS_TIRES_LOAD_RATING]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_TIRES_LOAD_RATING, value);
                            }}
                        />
                        <SelectInput
                            placeholderText={formatMessage(messages.loadRatingUnit)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_WEIGHT]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_WEIGHT],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_TIRES_LOAD_RATING_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_TIRES_LOAD_RATING_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.recommendedPressure)}
                            value={equipmentSetup[model.PROPS_TIRES_REC_PRESSURE]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_TIRES_REC_PRESSURE, value);
                            }}
                        />
                        <SelectInput
                            placeholderText={formatMessage(messages.pressureUnit)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_PRESSURE]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_PRESSURE],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_TIRES_REC_PRESSURE_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_TIRES_REC_PRESSURE_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection className="column-3-gutter">
                        <NumericInput
                            scale={0}
                            precision={4}
                            placeholderText={formatMessage(messages.numberOfTires)}
                            value={equipmentSetup[model.PROPS_TIRES_NUMBER_OF_TIRES]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_TIRES_NUMBER_OF_TIRES, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.rimDiameter)}
                            value={equipmentSetup[model.PROPS_TIRES_RIM_DIAMETER]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_TIRES_RIM_DIAMETER, value);
                            }}
                        />
                        <SelectInput
                            placeholderText={formatMessage(messages.rimDiameterUnit)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_LENGTH]}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_TIRES_RIM_DIAMETER_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_TIRES_RIM_DIAMETER_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.aspectRatio)}
                            value={equipmentSetup[model.PROPS_TIRES_ASPECT_RATIO]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_TIRES_ASPECT_RATIO, value);
                            }}
                        />
                        <NumericInput
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.tireWidth)}
                            value={equipmentSetup[model.PROPS_TIRE_WIDTH]}
                            onChange={(value) => {
                                onNumberChange(model.PROPS_TIRE_WIDTH, value);
                            }}
                        />
                    </SubSection>
                </Section>
            </Bucket>
        );
    }
}
