import React from "react";
import { useOnScreen } from "~/utils/use-on-screen";
import { Loader } from "~/core";

import "./trigger.css";

export interface IProps {
    onVisible: () => void;
    loading: boolean;
}

export function Trigger(props: IProps) {
    const elementRef = React.useRef<HTMLDivElement>(null);
    useOnScreen(elementRef, 0.05, (value) => {
        if (value) {
            props.onVisible();
        }
    });

    return (
        <div className="infinite-scroll-trigger" ref={elementRef}>
            {props.loading ? <Loader /> : null}
        </div>
    );
}
