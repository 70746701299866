import { ScoutingTemplateCrop, ScoutingTemplateObservation } from ".";

export class ScoutingTemplate {
    eventScoutingTemplateGuid: string | null = "";
    loginGuid: string | null = "";
    cropGuid: string | null = "";
    cropPurposeGuid: string | null = "";
    allowPhotos = true;
    cropInfo = new ScoutingTemplateCrop();
    observationInfo: ScoutingTemplateObservation[] = [];

    get isAllRequiredFieldsSet(): boolean {
        return Boolean(this.cropGuid);
    }

    static fromJsonObj(jsonObj: Partial<ScoutingTemplate>): ScoutingTemplate {
        const scoutingTemplate = Object.assign(new ScoutingTemplate(), this, jsonObj);
        const cropInfo = ScoutingTemplateCrop.fromJsonObj(jsonObj.cropInfo);
        const observationInfo = jsonObj.observationInfo
            .map((obs) => ScoutingTemplateObservation.fromJsonObj(obs))
            .sort((a, b) => a.observationOrderId - b.observationOrderId);
        scoutingTemplate.cropInfo = cropInfo;
        scoutingTemplate.observationInfo = observationInfo;
        return Object.freeze(scoutingTemplate);
    }

    update(newProps: Partial<ScoutingTemplate>): any {
        return Object.assign(new ScoutingTemplate(), this, newProps);
    }

    updateObservation(index: number, newProps: ScoutingTemplateObservation[]): any {
        const repObs = ScoutingTemplateObservation.fromJsonObj({
            ...newProps,
            observationOrderId: index,
        });
        const observationInfo = [...this.observationInfo].splice(index, 1, repObs);
        const updatedScoutingTemplate = this.update({ observationInfo });
        return updatedScoutingTemplate;
    }

    updateObservationInfo(newProps: ScoutingTemplateObservation[]): ScoutingTemplate {
        return this.update({ observationInfo: newProps });
    }
    reset(): ScoutingTemplate {
        return Object.assign(new ScoutingTemplate(), {
            cropGuid: this.cropGuid,
            cropPurposeGuid: this.cropPurposeGuid,
            allowPhotos: true,
        });
    }
}
