import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import moment from "moment";
import { messages } from "../i18n-messages";
import { getAgBytesErrorClassNames, mapToPicklistValue } from "~/admin/utils";
import { PICKLIST_PAY_TYPE } from "~/core/picklist/picklist-names";
import { UNIT_PAY_RATE } from "~/core/units/unit-names";

import { DateInput, NumericInput, Section, SelectInput, SubSection, TextArea } from "~/core";
import { adminData } from "~/admin/data";
import SearchSupervisor from "./search-supervisor";

import "./component.css";

export const PROPS_START_DATE = "startDate";
export const PROPS_END_DATE = "endDate";
export const PROPS_PAYRATE = "payRate";
export const PROPS_PAY_TYPE_GUID = "payTypeGuid";
export const PROPS_NOTES = "notes";
export const PROPS_PAY_RATE_UNIT_GUID = "payRateUnitGuid";
export const PROPS_SUPERVISOR_GUID = "supervisorGuid";
export const PROPS_SUPERVISOR_NAME = "supervisorName";

export class EmployeeInfo extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        onTextChange: PropTypes.func,
        employeeInfo: PropTypes.object,
        apiErrors: PropTypes.array,
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { onTextChange, employeeInfo, apiErrors } = this.props;
        return (
            <div>
                <Section headerText={formatMessage(messages.employeeInfo)}>
                    <SubSection>
                        <DateInput
                            placeholderText={formatMessage(messages.startDate)}
                            value={
                                employeeInfo[PROPS_START_DATE]
                                    ? moment(employeeInfo[PROPS_START_DATE])
                                    : null
                            }
                            onChange={(value) => onTextChange(PROPS_START_DATE, value)}
                            defaultValue={null}
                        />
                        <DateInput
                            placeholderText={formatMessage(messages.endDate)}
                            value={
                                employeeInfo[PROPS_END_DATE]
                                    ? moment(employeeInfo[PROPS_END_DATE])
                                    : null
                            }
                            containerClassNames={["employee-info-end-date"]}
                            onChange={(value) => onTextChange(PROPS_END_DATE, value)}
                            defaultValue={null}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.props[PICKLIST_PAY_TYPE]}
                            placeholderText={formatMessage(messages.payType)}
                            labelText={formatMessage(messages.payType)}
                            value={mapToPicklistValue({
                                options: this.props[PICKLIST_PAY_TYPE],
                                selectedGuid: this.props.employeeInfo[PROPS_PAY_TYPE_GUID],
                            })}
                            onChange={(value) =>
                                onTextChange(PROPS_PAY_TYPE_GUID, value ? value.guid : "")
                            }
                        />
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.payRate)}
                            labelText={formatMessage(messages.payRate)}
                            value={employeeInfo[PROPS_PAYRATE] || ""}
                            containerClassNames={getAgBytesErrorClassNames(665, apiErrors)}
                            onChange={(value) => onTextChange(PROPS_PAYRATE, value)}
                            scale={4}
                            precision={14}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.props[UNIT_PAY_RATE]}
                            placeholderText={formatMessage(messages.payRateUnit)}
                            labelText={formatMessage(messages.payRateUnit)}
                            value={mapToPicklistValue({
                                options: this.props[UNIT_PAY_RATE],
                                selectedGuid: employeeInfo[PROPS_PAY_RATE_UNIT_GUID],
                            })}
                            onChange={(value) =>
                                this.props.onTextChange(
                                    PROPS_PAY_RATE_UNIT_GUID,
                                    value ? value.guid : ""
                                )
                            }
                        />
                        <SearchSupervisor
                            initialValue={
                                employeeInfo[PROPS_SUPERVISOR_NAME]
                                    ? employeeInfo[PROPS_SUPERVISOR_NAME].trim()
                                    : ""
                            }
                            onSelection={(value) => onTextChange(PROPS_SUPERVISOR_GUID, value)}
                            className="user-supervisor-search"
                        />
                    </SubSection>
                </Section>
                <Section
                    headerText={formatMessage(messages.notes)}
                    className={"form-section-child-stretch"}
                >
                    <SubSection>
                        <TextArea
                            tabIndex={0}
                            placeholderText={formatMessage(messages.notes)}
                            labelText={formatMessage(messages.notes)}
                            value={employeeInfo[PROPS_NOTES]}
                            onChange={(value) => onTextChange(PROPS_NOTES, value)}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }
}

export default injectIntl(EmployeeInfo);
