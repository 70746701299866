import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import { PICKLIST_DATA_TYPE, getPickListCode } from "~/core/picklist/picklist-names";

export const MODEL_NAME = "equationParameter";
export const URL = apiUrl("AgBytes/GetEquationParameterList");
export const GETRECORD = apiUrl("AgBytes/GetEquationParameter");
export const CREATE = apiUrl("AgBytes/AddEquationParameter");
export const UPDATE = apiUrl("AgBytes/UpdateEquationParameter");
export const DELETE = apiUrl("AgBytes/DeleteEquationParameterList");
export const EXPORT_FILE_NAME = "EquationParameterExport";
export const EXPORT_URL = apiUrl("AgBytes/ExportEquationParameterList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetEquationParameterAutoSearchList");
export const IMPORT_URL = apiUrl("AgBytes/EquationParameterImport");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidEquationParameterImport");
export const SELECT_ALL = apiUrl("AgBytes/GetEquationParameterSelectAllList");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "equationParameterFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "equationParameterSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "equationParameterPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        equationParameterName: "",
        equationParameterLabel: "",
        equationParameterDataType: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "EquationParameterName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultEquationParameter = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_CAN_DELETE]: "",
    [model.PROPS_EQUATION_PARAMETER_DATA_TYPE]: "",
    [model.PROPS_EQUATION_PARAMETER_DATA_TYPE_GUID]: "",
    [model.PROPS_EQUATION_PARAMETER_GUID]: "",
    [model.PROPS_EQUATION_PARAMETER_LABEL]: "",
    [model.PROPS_EQUATION_PARAMETER_NAME]: "",
    [model.PROPS_MODIFIED_DATE]: "",
});

export const pickLists = {
    [PICKLIST_DATA_TYPE]: getPickListCode(PICKLIST_DATA_TYPE),
};

export const service = createService({
    id: model.PROPS_EQUATION_PARAMETER_GUID,
    guid: model.PROPS_EQUATION_PARAMETER_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    urls: {
        URL,
        DELETE,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_EQUATION_PARAMETER_NAME]: {
            label: "parameterName",
            gridCol: 20,
        },
        [model.PROPS_EQUATION_PARAMETER_LABEL]: {
            label: "parameterLabel",
            gridCol: 20,
        },
        [model.PROPS_EQUATION_PARAMETER_DATA_TYPE]: {
            label: "equationParameterDataType",
            gridCol: 10,
        },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 10,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => Object.assign({}, defaultEquationParameter()),
    defaultSort,
});
