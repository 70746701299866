import * as model from "./model";
import { createService } from "~/hocs/crud";
import { IMPORT_TYPES, IMPORT_SAMPLE_TEMPLATES } from "./constants";
import { TemplateAPI } from "@ai360/core";
import { keywords } from "../keywords";
import { GUID } from "./../../../data";

export const importSampleTemplates = [
    { label: "Spatial (with results)", value: 1 },
    { label: "Spatial (no results)", value: 2 },
    { label: "Results Only", value: 3 },
    { label: "Name Match Centroid", value: 4 },
];

export const defaultFileTypes = ".shp,.dbf,.shx,.prj";
export const MODEL_NAME = TemplateAPI.MODEL_NAME;

//URLS
export const AUTO_SEARCH_URL = TemplateAPI.AUTO_SEARCH_URL;
export const CREATE = TemplateAPI.CREATE;
export const DELETE = TemplateAPI.DELETE;
export const GET_EXISTING_TEMPLATE_GRID_DATA_URL = TemplateAPI.GET_EXISTING_TEMPLATE_GRID_DATA_URL;
export const GET_EXISTING_TEMPLATE_GRID_DATA_BY_TEMPLATE_GUID_URL =
    TemplateAPI.GET_EXISTING_TEMPLATE_GRID_DATA_BY_TEMPLATE_GUID_URL;
export const GET_IMPORT_CONTROLLER_TEMPLATE_URL = TemplateAPI.GET_IMPORT_CONTROLLER_TEMPLATE_URL;
export const GET_IMPORT_TEMPLATE_URL = TemplateAPI.GET_IMPORT_TEMPLATE_URL;
export const GET_NEW_TEMPLATE_URL = TemplateAPI.GET_NEW_TEMPLATE_URL;
export const GET_SAMPLE_ATTRIBUTES_URL = TemplateAPI.GET_SAMPLE_ATTRIBUTES_URL;
export const REQUEST_GET_IMPORT_SAMPLE_TEMPLATES = TemplateAPI.REQUEST_GET_IMPORT_SAMPLE_TEMPLATES;
export const REQUEST_GET_IMPORT_TYPE_LIST = TemplateAPI.REQUEST_GET_IMPORT_TYPE_LIST;
export const UPDATE = TemplateAPI.UPDATE;
export const URL = TemplateAPI.URL;
export const VENDOR_DATA_URL = TemplateAPI.VENDOR_DATA_URL;
export const VERIFY_TEMPLATE_URL = TemplateAPI.VERIFY_TEMPLATE_URL;

// Request payload
export const REQUEST_PAYLOAD_FILTER = "templateFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "templateSortOptions";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "templatePageOptions";

// Monitor File tabs
export const APPLICATION_TYPE = 0;
export const HARVEST_TYPE = 1;
export const PLANTING_TYPE = 2;

export const TemplateTabs = {
    APPLICATION_TYPE,
    HARVEST_TYPE,
    PLANTING_TYPE,
};

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        eventType: "",
        templateName: "",
        createdBy: "",
        lastModifiedBy: "",
        lastModifiedDate: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
};

export const defaultSort = {
    FieldName: "TemplateName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const getGuid = (record) => {
    if (record[model.PROPS_TEMPLATE_GUID]) {
        return {
            guid: record[model.PROPS_TEMPLATE_GUID],
            isControllerTemplate: false,
        };
    } else {
        return {
            guid: record[model.PROPS_CONTROLLER_TEMPLATE_GUID],
            isControllerTemplate: true,
        };
    }
};

function isECData(value) {
    if (value) {
        return value === keywords.EC_DATA;
    }
}

function getFileTypes(fileType) {
    switch (fileType) {
        case "text/plain":
            return ".txt";
        case "application/x-zip-compressed":
            return ".zip";
        case "text/csv":
        case "application/vnd.ms-excel":
            return ".csv";
        case "dat":
            return ".dat";
        case "DAT":
            return ".DAT";
        default:
            return defaultFileTypes;
    }
}

function getDefaultDelimeter() {
    return ",";
}

function isSampleFileType(value) {
    if (value) {
        return (
            value === keywords.SAMPLING_NEMATODE ||
            value === keywords.SAMPLING_SOIL ||
            value === keywords.SAMPLING_TISSUE ||
            value === keywords.SAMPLING_WATER
        );
    }
}

function isMonitorType(value) {
    if (value) {
        return (
            value === keywords.AG_LEADER ||
            value === keywords.JOHN_DEERE ||
            value === keywords.RAVEN
        );
    }
}

function isTextFileFormat(value) {
    const supportedTextFiles = [".txt", ".dat", ".DAT", ".csv"];
    if (value) {
        const typesArray = value.split(",");
        return !typesArray.some((item) => supportedTextFiles.includes(item));
    } else {
        return true;
    }
}

function getSupportedFileTypes() {
    return "*";
}
function isEditMode(mode) {
    return mode === keywords.EDIT;
}

function isManagementAreaType(value) {
    return value === keywords.MANAGEMENT_AREA;
}

export const isRegularMonitorFile = (data) => {
    const { APPLICATION_TYPE, HARVEST_TYPE, PLANTING_TYPE } = TemplateTabs;
    return (
        (data[APPLICATION_TYPE] && data[HARVEST_TYPE] && data[PLANTING_TYPE]) ||
        (data[APPLICATION_TYPE] && data[HARVEST_TYPE])
    );
};

export const isSingleTypeMonitorFile = (data) => {
    const { APPLICATION_TYPE, HARVEST_TYPE, PLANTING_TYPE } = TemplateTabs;
    return data[APPLICATION_TYPE] && !data[HARVEST_TYPE] && !data[PLANTING_TYPE];
};

function preparePayload(payload) {
    return {
        importTemplateList: [
            {
                vendorList: [],
                ...payload,
            },
        ],
        [model.PROPS_IMPORT_TYPE_GUID]: payload[model.PROPS_IMPORT_TYPE_GUID],
        [model.PROPS_IMPORT_ZIP_TEMPLATE_GUID]: payload[model.PROPS_IMPORT_ZIP_TEMPLATE_GUID] || "",
        [model.PROPS_TEMPLATE_NAME]: payload[model.PROPS_TEMPLATE_NAME],
        [model.PROPS_FILE_TYPES]:
            payload[model.PROPS_FILE_TYPES] ||
            payload.importTemplateList[0][model.PROPS_FILE_TYPES],
        [model.PROPS_DELIMITER]:
            payload[model.PROPS_DELIMITER] || payload.importTemplateList[0][model.PROPS_DELIMITER],
    };
}

const defaultFileMetadata = () => ({
    [model.PROPS_TEMPLATE_NAME]: "",
    [model.PROPS_FILE_TYPES]: "",
    [model.PROPS_DELIMITER]: "",
});

export const dropdowns = {
    [IMPORT_TYPES]: REQUEST_GET_IMPORT_TYPE_LIST,
    [IMPORT_SAMPLE_TEMPLATES]: REQUEST_GET_IMPORT_SAMPLE_TEMPLATES,
};

export const service = createService({
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    REQUEST_PAYLOAD_SORT_LIST,
    defaultRequestFilters,
    dropdowns,
    defaultFileTypes,
    getDefaultDelimeter,
    getFileTypes,
    getGuid,
    guid: GUID,
    getSupportedFileTypes,
    id: model.PROPS_TEMPLATE_GUID,
    isECData,
    isEditMode,
    isManagementAreaType,
    isMonitorType,
    isRegularMonitorFile,
    isSampleFileType,
    isSingleTypeMonitorFile,
    isTextFileFormat,
    modelName: TemplateAPI.MODEL_NAME,
    preparePayload,
    urls: {
        AUTO_SEARCH_URL,
        CREATE,
        DELETE,
        GET_EXISTING_TEMPLATE_GRID_DATA_URL,
        GET_EXISTING_TEMPLATE_GRID_DATA_BY_TEMPLATE_GUID_URL,
        GET_IMPORT_CONTROLLER_TEMPLATE_URL,
        GET_IMPORT_TEMPLATE_URL,
        GET_NEW_TEMPLATE_URL,
        GET_SAMPLE_ATTRIBUTES_URL,
        UPDATE,
        URL,
        VENDOR_DATA_URL,
        VERIFY_TEMPLATE_URL,
    },
    _defaultLabels: {
        [model.PROPS_EVENT_TYPE]: {
            label: "eventType",
            gridCol: 10,
            sortNameOverRide: "eventType",
        },
        [model.PROPS_TEMPLATE_NAME]: { label: "templateName", gridCol: 30 },
        [model.PROPS_CREATED_BY]: {
            label: "createdBy",
            gridCol: 10,
            sortNameOverRide: "createdBy",
        },
        [model.PROPS_LAST_MODIFIED_BY]: {
            label: "lastModifiedBy",
            gridCol: 10,
            sortNameOverRide: "lastModifiedBy",
        },
        [model.PROPS_LAST_MODIFIED_DATE]: { label: "lastModifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 10,
            className: "col-shift-15",
        },
    },
    defaultFileMetadata,
    defaultSort,
});
