import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const SamplingToolsIcon = withSvgIcon(
    <g>
        <path
            d={
                "M15.25,7.07,0,15.89l15.26,8L30,15.47Zm0,1.11,12.8,7.29-7.82,4.45a1.64,1.64,0,0,0-.28-.62," +
                "2.33,2.33,0,0,0-1-.73c-.57-.26-.79-.36-.55-1.36.43-1.79-1.22-2.67-2.55-3.38-1-.52-2-1.06-2.18-1.81" +
                "a3.9,3.9,0,0,1,.64-3.29h0Zm0,14.59L2,15.85,12.19,10a4.51,4.51,0,0,0,.06,2.45c.37,1.37,1.72,2.09," +
                "2.91,2.73,1.34.72,2,1.13,1.81,1.74-.52,2.18.7,2.74,1.36,3a1.56,1.56,0,0,1,.43.24.77.77,0,0,1,0,.62Z"
            }
            transform="translate(0 -7.07)"
        />
        <circle cx="9.85" cy="8.91" r="0.96" />
        <circle cx="13.66" cy="11.91" r="0.96" />
        <circle cx="22.56" cy="8.23" r="0.96" />
    </g>,
    "-1.5 -1 32 18.8",
    "no-stroke"
);
