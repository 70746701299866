import { all, call, fork, put, select, take, takeEvery } from "redux-saga/effects";
import * as actions from "../actions";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import * as constants from "../constants";
import { service } from "../service";
import { Request, APIError } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid } from "~/login/selectors";
import { setApiResult } from "~/core/api/actions";
import { processCreateImportTemplateResponse } from "./common";
import * as model from "../model";
import { getFileMetaData, getModuleState, getCurrentTabTemplate } from "../selectors";
import { keywords } from "../../keywords";

export function* processVerifyTemplateFile({ payload }) {
    const { fileObject } = payload;
    const { name } = fileObject.files[0];
    const UserGuid = yield select(getTheUserGuid);
    const { importTypeGuid } = yield select(getFileMetaData);
    const requestOptions = {
        UserGuid: UserGuid,
        Model: {
            [constants.REQUEST_OPTION_FILENAME]: name,
            [constants.REQUEST_OPTION_FILESIZE]: keywords.FILE_SIZE,
            [constants.REQUEST_OPTION_TYPEGUID]: importTypeGuid,
        },
    };
    try {
        yield call(Request.post, service.urls.VERIFY_TEMPLATE_URL, requestOptions);
        yield put(
            actions.getTemplateGridData({
                fileObject,
                typeGuid: importTypeGuid,
                isMonitorType: false,
            })
        );
        yield take(actions.getTemplateGridSuccess);
        yield put(actions.verifyTemplateFileSuccess());
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        yield put(notificationActions.apiCallError(error, actions.verifyTemplateFile()));
        yield put(actions.verifyTemplateFileFailed(error));
    }
}

export function* fetchTemplateGridData({ payload }) {
    const formData = new FormData();
    const { fileObject, typeGuid } = payload;
    const { name, size } = fileObject.files[0];
    const UserGuid = yield select(getTheUserGuid);
    formData.append(constants.REQUEST_OPTION_FILE0, fileObject.files[0]);
    formData.append(constants.REQUEST_OPTION_LOGGED_IN_USER_GUID, UserGuid);
    formData.append(constants.REQUEST_OPTION_TYPEGUID, typeGuid);
    formData.append(constants.REQUEST_OPTION_FILESIZE, size);
    formData.append(constants.REQUEST_OPTION_FILENAME, name);
    const headers = {
        Accept: "application/json, */*",
        enctype: "multipart/form-data",
    };
    try {
        const response = yield call(
            Request.post,
            service.urls.GET_NEW_TEMPLATE_URL,
            formData,
            "multipart/form-data",
            headers
        );
        const templateData = yield select(getModuleState);
        const isMonitorType = templateData.isMonitorType;
        if (isMonitorType) {
            yield processCreateImportTemplateResponse(response, name, [
                constants.TEMPLATE_TYPE_HARVEST,
                constants.TEMPLATE_TYPE_APPLICATION,
                constants.TEMPLATE_TYPE_PLANTING,
            ]);
        } else {
            const {
                standardColumnHeaders,
                standardGridData,
                importTemplateFileGuid,
                importTypeGuid,
                delimiter,
            } = response[0];
            yield put(
                fetchDropdownData({
                    [constants.SAMPLE_ATTRIBUTES]: {
                        url: service.urls.GET_SAMPLE_ATTRIBUTES_URL,
                        model: importTypeGuid,
                    },
                })
            );
            yield put(
                actions.updateTemplate({
                    key: model.PROPS_IMPORT_TYPE_GUID,
                    value: importTypeGuid,
                })
            );
            yield put(
                actions.updateTemplate({
                    key: model.PROPS_IMPORT_TEMPLATE_FILE_GUID,
                    value: importTemplateFileGuid,
                })
            );
            yield put(
                actions.updateTemplate({
                    key: model.PROPS_TEMPLATE_NAME,
                    value: name,
                })
            );
            const importTemplate = yield select(getCurrentTabTemplate);
            const newImportAttributes = standardColumnHeaders
                .filter((column) => column.attributeGuid)
                .map((column) => ({
                    importAttributeGuid: column.attributeGuid,
                    columnOrderInFile: column.orderInFile,
                    columnNameInFile: column.importFileColumnName,
                }));
            const newImportTemplateList = {
                templateName: name,
                importTemplateFileGuid,
                importTypeGuid,
                delimiter,
                matchHeaderName: true,
                importAttributes: newImportAttributes,
            };
            const updatedImportTemplate = model.ImportTemplateFactory.createImportTemplateList(
                importTemplate,
                { ...importTemplate, ...newImportTemplateList }
            );
            yield put(
                actions.updateTemplate({
                    key: model.PROPS_IMPORT_TEMPLATE_LIST,
                    value: new Array(updatedImportTemplate),
                })
            );
            yield put(
                actions.setTemplateGridData({
                    standardColumnHeaders,
                    standardGridData,
                    importTemplateFileGuid,
                    importTypeGuid,
                })
            );
        }
        yield put(actions.getTemplateGridSuccess());
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        yield put(notificationActions.apiCallError(error, actions.getTemplateGridData()));
        yield put(actions.verifyTemplateFileFailed(error));
        yield put(actions.getTemplateGridFailed(error));
    }
}

export function* watchGetTemplateGridData() {
    yield takeEvery(actions.getTemplateGridData, fetchTemplateGridData);
}

export function* watchImportFileTemplate() {
    yield takeEvery(actions.verifyTemplateFile, processVerifyTemplateFile);
}

const CreateSagas = function* () {
    yield all([fork(watchImportFileTemplate), fork(watchGetTemplateGridData)]);
};

export default CreateSagas;
