import { ADMIN_STATE_KEY } from "~/admin";
import { ORG_LEVEL_SETUP_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[ADMIN_STATE_KEY][ORG_LEVEL_SETUP_DATA_KEY];

export const getHierarchyFilterList = (state) => _getModuleState(state).hierarchyFilterList;
export const getOrgLevelData = (state) => _getModuleState(state).orgLevelData;
export const getOrgLevelEquipmentList = (state) => _getModuleState(state).equipmentList;
export const getOrgLevelGridData = (state) => _getModuleState(state).orgLevelGridData;
export const getOrgLevelRecord = (state) => _getModuleState(state).orgLevelRecord;
export const getOrgLevelWithGuid = (state) => _getModuleState(state).orgLevelWithGuid;
export const getOrgTypeName = (state) => _getModuleState(state).orgTypeName;
export const getParentGridData = (state) => _getModuleState(state).parentGridData;
export const getParentLevelGuid = (state) => _getModuleState(state).parentLevelGuid;
export const getDuplicateDatabaseIdOrgLevels = (state) =>
    _getModuleState(state).duplicateDatabaseIdOrgLevels;
export const getAgvanceLocations = (state) => _getModuleState(state).agvanceLocations;
