import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";
import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";

// Components
import { ZeroToInfinite, Section } from "~/core";
import EmailItem from "./email-item";

// Constants
import * as constants from "./constants";

//Style
import "./email.css";

export const EMAIL_ERROR_CODE_LIST = [2085, 17];

export class Email extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object,
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        defaultValue: PropTypes.array,
        emailAddText: PropTypes.object,
        emailHeaderText: PropTypes.object,
        emailList: PropTypes.array,
        emailErrorCodeList: PropTypes.array,
        emailPropKey: PropTypes.string,
        index: PropTypes.number,
        initialRenderCheck: PropTypes.func,
        intl: intlShape.isRequired,
        onChildComponentChange: PropTypes.func,
        required: PropTypes.bool,
    };

    static defaultProps = {
        emailErrorCodeList: EMAIL_ERROR_CODE_LIST,
        defaultValue: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            email: props.defaultValue,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.addEditPanel.mode === "EDIT" && nextProps.emailList !== this.state.email) {
            this.setState({
                email: nextProps.emailList,
            });
        }
        if (nextProps.apiErrors !== this.props.apiErrors) {
            const containsError = nextProps.emailErrorCodeList.some((errorCode) =>
                nextProps.apiErrors.includes(errorCode)
            );
            if (containsError) {
                const { email } = this.state;
                email.forEach((record) => {
                    record.isError = containsError;
                });
                this.setState({ email });
            }
        }
    }

    addItem = (e, items, index) => {
        if (index === 0) {
            items[index][constants.PROPS_IS_PRIMARY] = true;
        }
        if (this.state.email.length === 1) {
            items = this.props.initialRenderCheck ? this.props.initialRenderCheck(items) : items;
        }
        this.setState({ email: items });
    };

    onDelete = (e, items) => {
        if (items.length && !items.some(({ isPrimary }) => isPrimary)) {
            items[0][constants.PROPS_IS_PRIMARY] = true;
        }
        this.email = items;
    };

    onChange = ({ type }, value, index) => {
        let { email } = this.state;
        const emailPropKey = this.props.emailPropKey || constants.PROPS_COMPONENT_KEY;
        if (type) {
            email[index][constants.PROPS_EMAIL][type] = value;
        }
        this.setState({ email }, () =>
            this.props.onChildComponentChange(emailPropKey, this.state.email)
        );
    };

    onPrimaryChange = (value, index) => {
        const { email } = this.state;
        const emailPropKey = this.props.emailPropKey || constants.PROPS_COMPONENT_KEY;
        email.forEach((data, i) => {
            if (index === i) {
                data[constants.PROPS_IS_PRIMARY] = value;
            } else {
                data[constants.PROPS_IS_PRIMARY] = false;
            }
        });
        this.setState({ email }, () =>
            this.props.onChildComponentChange(emailPropKey, this.state.email)
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { email } = this.state;
        const initialValue = {
            [constants.PROPS_IS_PRIMARY]: false,
            [constants.PROPS_EMAIL]: {
                [constants.PROPS_EMAIL_ADDRESS]: "",
            },
        };
        const { onChange, onPrimaryChange } = this;
        const { apiErrors, apiErrorModel, emailErrorCodeList, required } = this.props;
        const props = {
            onChange,
            onPrimaryChange,
            email,
            required,
            apiErrors,
            apiErrorModel,
            emailErrorCodeList,
        };
        const emailHeaderText = this.props.emailHeaderText || messages.emailHeading;
        const emailAddText = this.props.emailAddText || messages.email;
        return (
            <Section
                required={this.props.required}
                headerText={formatMessage(emailHeaderText)}
                className="email-header"
            >
                <ZeroToInfinite
                    items={email}
                    initialValue={initialValue}
                    getChildProps={() => null}
                    onDelete={this.onDelete}
                    addItem={this.addItem}
                    addText={createAddLinkLabelText(formatMessage, emailAddText)}
                    deleteText={formatMessage(mainMessages.delete)}
                    formatMessage={formatMessage}
                    required={this.props.required}
                >
                    <EmailItem {...props} />
                </ZeroToInfinite>
            </Section>
        );
    }
}

export default injectIntl(Email);
