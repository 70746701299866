import { v4 as uuid } from "uuid";
import { IZipFile } from "~/action-panel/components/layer-module/components/analysis-info/interfaces";

import { IAccordionState } from "~/accordion/model";
import { IImportType, ImportTemplate } from "~/file-import/model";

export const ACCORDION_KEY = "FILE_LIST_ACCORDION";

export interface IDragDropFileUploaderCoreState {
    [key: string]: IDragDropFileUploaderState;
}

export interface IDragDropFileUploaderState {
    filesUploaded: Set<string>;
    filesWithErrorsCount: number;
    fileUploadComplete: boolean;
    importFileCount: number;
    isPreparingUpload: boolean;
    isDuplicateFileError: boolean;
    selectedImportType?: IImportType;
    selectedTemplate?: ImportTemplate;
    stateKey: string;
    uploadFileInfoMap: Map<string, UploadFileInfo>;
    validExtensions: string[];
    [ACCORDION_KEY]: IAccordionState;
}

export class UploadFileInfo {
    public static SHAPE_PART_EXTENSIONS = new Set([".shx", ".dbf", ".prj"]);
    public static FSEXT_REGEX = /.+(\.[^.]+)$/;

    public static fromFile(fileObj: File, validExtensions: string[]): UploadFileInfo {
        const rv = new UploadFileInfo(fileObj.name, validExtensions);
        rv.fileObj = fileObj;
        rv.fileSize = fileObj.size;
        return rv;
    }

    public static fromZipEntry(zipEntry: IZipFile, validExtensions: string[]): UploadFileInfo {
        const fn = zipEntry.filename.replace(/.*\//, "");
        const rv = new UploadFileInfo(fn, validExtensions);
        rv.fileSize = zipEntry.uncompressedSize;
        rv.archiveAbsFileName = zipEntry.filename;
        return rv;
    }

    public archiveAbsFileName?: string;
    public children: UploadFileInfo[];
    public computedSize?: number;
    public errorMessages: any[];
    public fileName: string;
    public fileObj?: File;
    public fileSize: number;
    public guid: string;
    public readonly originalGuid: string;
    public importFileInfoGuids: string[];
    public importBatchGuid: string;
    public parent?: UploadFileInfo;
    public validExtensions: string[];

    constructor(fileName: string, validExtensions: string[]) {
        this.fileName = fileName;
        this.validExtensions = validExtensions;
        this.errorMessages = [];
        this.fileSize = 0;
        this.guid = uuid();
        this.originalGuid = this.guid;
        this.importFileInfoGuids = [];
        this.children = [];
    }

    public getFilenameExt(): string {
        const m = this.fileName.match(UploadFileInfo.FSEXT_REGEX);
        return m ? m[1].toLowerCase() : this.fileName;
    }

    public getNestedDepth(): number {
        let depth = 0;
        let parent = this.parent;
        while (parent) {
            parent = parent.parent;
            depth++;
        }
        return depth;
    }

    public isShape(): boolean {
        return this.getFilenameExt() === ".shp";
    }

    public isShapePart(): boolean {
        return UploadFileInfo.SHAPE_PART_EXTENSIONS.has(this.getFilenameExt());
    }

    public isZipFile(): boolean {
        return this.getFilenameExt() === ".zip";
    }

    public isZipNativeFormat(): boolean {
        return this.validExtensions.length === 1 && this.validExtensions[0] === ".zip";
    }
}

export const flattenUploadFileList = (
    uploadFileList: UploadFileInfo[],
    accum: UploadFileInfo[] = []
): UploadFileInfo[] => {
    for (const ufi of uploadFileList) {
        accum.push(ufi);
        if (ufi.children.length > 0) {
            flattenUploadFileList(ufi.children, accum);
        }
    }
    return accum;
};
