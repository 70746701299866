import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { intlShape } from "react-intl";
import { v4 as uuid } from "uuid";

import { Bucket, BucketHeader, Menu } from "~/core";

import { messages } from "../../../i18n-messages";
import { AddEditFieldModal } from "../containers/add-edit-field-modal";
import { EditEventIdModal } from "../containers/edit-event-id-modal";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";
import { ImportTypes } from "../../../actions";
import { LockIcon } from "~/core/icons";

export class FieldsPanel_ extends Component {
    static propTypes = {
        agvanceFieldList: PropTypes.array,
        agvanceOrgLevelList: PropTypes.array,
        canContinue: PropTypes.bool.isRequired,
        fetchAgvanceFieldList: PropTypes.func,
        fetchAgvanceOrgLevelList: PropTypes.func,
        filteredFieldImportFileList: PropTypes.array.isRequired,
        getIsFieldMatchLoading: PropTypes.func,
        importFieldStripData: PropTypes.array.isRequired,
        importFileGuidList: PropTypes.array.isRequired,
        importGroomingRequest: PropTypes.shape({
            featuresCount: PropTypes.number,
            fieldGuidsToIgnoreList: PropTypes.array,
            filteredFieldImportFileList: PropTypes.array,
            importFileGuidList: PropTypes.array.isRequired,
            selectAll: PropTypes.bool,
            selectedCropGuidList: PropTypes.array,
            selectedCroppingSeasonGuidList: PropTypes.array,
            selectedCustomerFieldList: PropTypes.array,
            selectedEventIdList: PropTypes.array,
            selectedFieldGuidList: PropTypes.array,
            statFilter: PropTypes.bool.isRequired,
            yieldFilter: PropTypes.bool.isRequired,
        }),
        importType: PropTypes.object.isRequired,
        intl: intlShape.isRequired,
        isLockCustomer: PropTypes.bool,
        isResultsOnlySampling: PropTypes.bool.isRequired,
        loadDetailPage: PropTypes.func,
        personalityId: PropTypes.number.isRequired,
        rerunMatching: PropTypes.func.isRequired,
        saveFieldInformation: PropTypes.func.isRequired,
        selectedMatchedFieldGuid: PropTypes.string,
        setFilteredFieldImportFileList: PropTypes.func.isRequired,
        setImportFieldStripData: PropTypes.func.isRequired,
        setImportGroomingRequest: PropTypes.func.isRequired,
        setIsLoading: PropTypes.func.isRequired,
        setSelectedMatchedFieldGuid: PropTypes.func.isRequired,
        unmatchFieldFile: PropTypes.func.isRequired,
        updateCanContinue: PropTypes.func.isRequired,
        updateEventId: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            eventIdSelectField: null,
            eventIdSelectFile: null,
            eventIdSelectIsOpen: false,
            isAgvanceCustomer: AgvanceUtils.isAgvanceConnected(this.props.personalityId),
            showLockIcon: false,
        };
    }

    componentDidMount() {
        const filteredImportFieldStripData = this.props.isLockCustomer
            ? this.props.importFieldStripData.filter((field) => {
                  return field.enrolledYn === true || field.noMatch === true;
              })
            : this.props.importFieldStripData;

        const hasAnyMatch = filteredImportFieldStripData.some((field) => {
            return field.fieldGuid && field.fieldGuid !== "";
        });
        if (this.props.canContinue !== hasAnyMatch) {
            this.props.updateCanContinue(hasAnyMatch);
        }
        this._showingLockIcon(filteredImportFieldStripData);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.importFieldStripData !== this.props.importFieldStripData &&
            nextProps.importFieldStripData.length > 0
        ) {
            const filteredImportFieldStripData = this.props.isLockCustomer
                ? nextProps.importFieldStripData.filter((field) => {
                      return field.enrolledYn === true || field.noMatch === true;
                  })
                : nextProps.importFieldStripData;

            const hasAnyMatch = filteredImportFieldStripData.some((field) => {
                return field.fieldGuid && field.fieldGuid !== "";
            });
            if (nextProps.canContinue !== hasAnyMatch) {
                this.props.updateCanContinue(hasAnyMatch);
            }

            this._showingLockIcon(filteredImportFieldStripData);
        }
        if (nextProps.agvanceFieldList !== this.props.agvanceFieldList) {
            this.setState({
                agvanceFieldList: nextProps.agvanceFieldList,
            });
        }
        if (nextProps.agvanceOrgLevelList !== this.props.agvanceOrgLevelList) {
            this.setState({
                agvanceOrgLevelList: nextProps.agvanceOrgLevelList,
            });
        }
    }

    _showingLockIcon(filteredImportFieldStripData) {
        const showLockIcon =
            this.props.isLockCustomer && filteredImportFieldStripData.length < 1 ? true : false;
        this.setState({ showLockIcon });
    }

    _addEditBoundary(fieldGuid) {
        this.setState({
            editingFieldGuid: fieldGuid,
        });
    }

    _closeEditCustomerField(isCancel) {
        this.setState(
            {
                editingFieldGuid: null,
            },
            () => {
                if (!isCancel) {
                    this.props.rerunMatching();
                }
            }
        );
    }

    _closeEventIdSelector() {
        this.setState({
            eventIdSelectField: null,
            eventIdSelectFile: null,
            eventIdSelectIsOpen: false,
        });
    }

    _onFieldSelect(evt, fieldGuid) {
        if (
            evt.target.className.indexOf("context-menu") > -1 ||
            evt.target.className === "menu-dots" ||
            evt.target.className === "menu-dot" ||
            evt.target.className === "menu-item" ||
            evt.target.parentNode.className === "menu-item"
        ) {
            return;
        }
        if (this.props.selectedMatchedFieldGuid === fieldGuid) {
            this.props.setSelectedMatchedFieldGuid(null);
        } else {
            this.props.setSelectedMatchedFieldGuid(fieldGuid);
        }
    }

    _openEventIdSelector(eventIdSelectField, eventIdSelectFile) {
        this.setState({
            eventIdSelectField,
            eventIdSelectFile,
            eventIdSelectIsOpen: true,
        });
    }

    _processFieldEditGeometries(fieldGuid, fieldEditGeometries) {
        let fieldEditGeometriesJson = [];
        fieldEditGeometries.forEach((geometry) => {
            fieldEditGeometriesJson.push(JSON.stringify(geometry.toJSON()));
        });
        return fieldEditGeometriesJson;
    }

    _saveEditCustomerField(data, callback) {
        const fieldGuid = this.state.editingFieldGuid;
        const {
            editCustomerGuid,
            editCustomerName,
            editFarmName,
            editFieldName,
            fieldBoundaryGuid,
            fieldEditGeometries,
            agvanceFieldGuid,
            agvanceFieldOrgLevelGuid,
            agvanceCustomerGuid,
        } = data;

        const fieldBoundaryPolygons = fieldEditGeometries.map((geometry) => ({
            fieldBoundaryGuid: fieldBoundaryGuid,
            fieldBoundaryPolygonGuid: uuid(),
            importFieldBoundaryGuids: null,
            fieldGuidsToCombine: null,
            shapes: [JSON.stringify(geometry.toJSON())],
        }));

        let fieldToSave = {
            ...data,
            customerGuid: editCustomerGuid,
            customerName: editCustomerName,
            farmName: editFarmName,
            fieldGuid,
            fieldName: editFieldName,
            fieldBoundary: {
                ...data.fieldBoundary,
                fieldGuid,
                fieldBoundaryPolygons,
            },
        };

        if (this.state.isAgvanceCustomer) {
            fieldToSave = {
                ...fieldToSave,
                agvanceFieldGuid,
                agvanceFieldOrgLevelGuid,
                agvanceCustomerGuid,
            };
        }

        if (this._validFieldInformation(fieldToSave, fieldEditGeometries)) {
            this.props.saveFieldInformation(fieldToSave, callback);
        }
    }

    _updateEventId(newEventId) {
        this.props.updateEventId(
            this.state.eventIdSelectField,
            this.state.eventIdSelectFile,
            newEventId
        );
        this._closeEventIdSelector();
    }

    _validFieldInformation(fieldToValidate, fieldGeometries) {
        if (fieldToValidate.fieldName === "") {
            //console.error("Field Name cannot be blank");
            return false; //TODO: actually handle it
        }
        if (fieldGeometries && fieldGeometries.length === 0) {
            //console.error("Must have a field boundary");
            return false; //TODO: actually handle it
        }

        return true;
    }

    onCustomerClose = () => {
        this.setState({
            editingFieldGuid: this.state.prevEditingFieldGuid,
            prevEditingFieldGuid: -1,
        });
    };

    onCustomerSaveSuccess = (customerData) => {
        if (this.state.isAgvanceCustomer) {
            this.props.fetchAgvanceFieldList(customerData.customerGuid);
        }
        this.setState({
            customerGuid: customerData.customerGuid,
            customerName: customerData.name,
        });
    };

    onCustomerSaveFailed = (customerData) => {
        console.assert(Object.keys(customerData).length > 0);
        console.error("There was a problem while saving the customer", customerData);
    };

    onAddNewCustomer = () => {
        this.setState({
            editingFieldGuid: -1,
            prevEditingFieldGuid: this.state.editingFieldGuid,
        });
        this.props.loadDetailPage("213", "101", {
            onCloseSlidingPanel: this.onCustomerClose,
            onSaveSuccess: this.onCustomerSaveSuccess,
            onSaveFailed: this.onCustomerSaveFailed,
        });
    };

    renderImportFileSubSection(field, index) {
        const { importFieldStripData, importType, isResultsOnlySampling, unmatchFieldFile } =
            this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        const lastStrip = importFieldStripData.length === 1;

        const importTypeName = importType.name.toLowerCase();
        const isImagery = importTypeName === ImportTypes.IMAGERY;

        return field.importFileGuidList.map((file, fileIndex) => {
            const pointCount = file.pointCount;
            const pointsText = isImagery
                ? formatMessage(messages.importWizardLayersText, {
                      count: pointCount,
                  })
                : formatMessage(messages.pointsText);
            const subMenuItems = [];
            if (isResultsOnlySampling) {
                subMenuItems.push({
                    key: 0,
                    label: formatMessage(messages.editEventId),
                    action: () => this._openEventIdSelector(field, file),
                });
            }
            subMenuItems.push({
                key: 1,
                label: formatMessage(messages.removeText),
                disabled: lastStrip && field.importFileGuidList.length === 1,
                action: () => unmatchFieldFile(field, file),
            });
            const eventIdLabel = !file.eventId ? "" : `(${file.eventId})`;
            return (
                <div key={`file-${index}-${fileIndex}`} className="file-strip">
                    <div className="field-selected-column">
                        <div className="field-selected-div"></div>
                    </div>
                    <div className="file-name" title={file.importFileName}>
                        {file.importFileName}
                    </div>
                    <div className="file-feature-count">
                        {`${formatNumber(pointCount)} ${pointsText} ${eventIdLabel}`}
                    </div>
                    <div className="field-context-menu-container">
                        <Menu
                            className="field-context-menu"
                            isDotMenu={true}
                            menuItems={subMenuItems}
                        />
                    </div>
                </div>
            );
        });
    }

    renderFieldSection() {
        const {
            importFieldStripData,
            importType,
            isLockCustomer,
            rerunMatching,
            selectedMatchedFieldGuid,
            unmatchFieldFile,
        } = this.props;

        const { formatMessage, formatNumber } = this.props.intl;
        const filteredImportFieldStripData = isLockCustomer
            ? importFieldStripData.filter((field) => {
                  return field.enrolledYn === true || field.noMatch === true;
              })
            : importFieldStripData;

        const lastStrip = filteredImportFieldStripData.length === 1;

        return filteredImportFieldStripData.map((field, index) => {
            const contextMenuItems = [
                {
                    key: 0,
                    label: formatMessage(
                        field.noMatch ? messages.addBoundaryText : messages.editBoundaryText
                    ),
                    action: () => this._addEditBoundary(field.fieldGuid),
                },
            ];
            if (!field.noMatch || !lastStrip) {
                contextMenuItems.push({
                    key: 1,
                    label: formatMessage(
                        field.noMatch ? messages.removeText : messages.unmatchText
                    ),
                    action: () => unmatchFieldFile(field, null),
                });
            }

            if (field.noMatch) {
                contextMenuItems.push({
                    key: 2,
                    label: formatMessage(messages.rerunMatchingText),
                    action: () => rerunMatching(),
                });
            }

            if (
                field.fieldGuid === this.state.editingFieldGuid &&
                this.state.customerGuid &&
                this.state.customerName
            ) {
                field = {
                    ...field,
                    customerGuid: this.state.customerGuid,
                    customerName: this.state.customerName,
                };
            }

            const isSelected = selectedMatchedFieldGuid === field.fieldGuid;
            const importTypeName = importType.name.toLowerCase();
            const isImagery = importTypeName === ImportTypes.IMAGERY;
            const pointsNumber = field.importFileGuidList.reduce(
                (sum, f) => (sum += f.pointCount),
                0
            );
            const pointsText = isImagery
                ? formatMessage(messages.importWizardLayersText, {
                      count: pointsNumber,
                  })
                : formatMessage(messages.pointsText);

            const farmFieldText = field.noMatch
                ? !isImagery
                    ? formatMessage(messages.unmatchedPointsText)
                    : formatMessage(messages.unmatchedLayersText)
                : field.farmName
                ? `${field.farmName} - ${field.fieldName}`
                : field.fieldName;

            return (
                <div
                    key={"field-" + index}
                    className={classNames({
                        selected: isSelected,
                        nomatch: field.noMatch,
                        matched: !isSelected && !field.noMatch,
                    })}
                >
                    <div className="ifb-row">
                        <div
                            className="ifb-strip"
                            onClick={(evt) => this._onFieldSelect(evt, field.fieldGuid)}
                        >
                            <div className="field-selected-column">
                                <div className="field-selected-div"></div>
                            </div>
                            <div className="field-strip-content-cols">
                                <div className="field-strip-content-section">
                                    <div className="farm-field-text" title={farmFieldText}>
                                        {farmFieldText}
                                    </div>
                                    <div className="customer-name" title={field.customerName}>
                                        {field.customerName}
                                    </div>
                                </div>

                                <div className="field-strip-content-section">
                                    <div className="feature-count">
                                        {`${formatNumber(pointsNumber)} ${pointsText}`}
                                    </div>
                                </div>
                            </div>

                            <div className="field-context-menu-container">
                                <Menu
                                    className={classNames("context-menu field-context-menu")}
                                    isDotMenu={true}
                                    menuItems={contextMenuItems}
                                />
                            </div>
                        </div>
                        {!isSelected ? null : this.renderImportFileSubSection(field, index)}
                        {field.fieldGuid !== this.state.editingFieldGuid ? null : (
                            <AddEditFieldModal
                                locked={field.fieldGuid !== ""}
                                record={field}
                                onSave={(data, callback) =>
                                    this._saveEditCustomerField(data, callback)
                                }
                                onClose={(isCancel) => this._closeEditCustomerField(isCancel)}
                                toolsetPayload={{
                                    customerGuid: field.customerGuid,
                                    fieldGuid: field.fieldGuid,
                                }}
                                onAddNewCustomer={this.onAddNewCustomer}
                                isAgvanceCustomer={this.state.isAgvanceCustomer}
                                agvanceFieldList={this.state.agvanceFieldList}
                                fetchAgvanceOrgLevelList={this.props.fetchAgvanceOrgLevelList}
                                fetchAgvanceFieldList={this.props.fetchAgvanceFieldList}
                                agvanceOrgLevelList={this.state.agvanceOrgLevelList}
                            />
                        )}
                    </div>
                </div>
            );
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { eventIdSelectIsOpen } = this.state;
        const showLockIcon = this.state.showLockIcon && !this.props.getIsFieldMatchLoading();

        return (
            <div className="fields-panel">
                <div className="fields-section">
                    <Bucket
                        className="import-field-section-bucket fields-section-bucket"
                        showSymbol={false}
                        isExpanded={true}
                        isCollapsible={false}
                    >
                        <BucketHeader className="fields-section-header import-field-section-header">
                            {formatMessage(messages.importWizardFieldsText)}
                        </BucketHeader>
                        {
                            <div className="field-section-content">
                                {this.renderFieldSection()}
                                {showLockIcon ? (
                                    <div>
                                        <LockIcon className="lock-icon" />
                                        <div className="lock-icon-text">
                                            {formatMessage(messages.allFieldsLocked)}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        }
                    </Bucket>
                </div>
                <EditEventIdModal
                    isOpen={eventIdSelectIsOpen}
                    onSelect={(newEventId) => this._updateEventId(newEventId)}
                    onClose={() => this._closeEventIdSelector()}
                />
            </div>
        );
    }
}
