export const filterOutEventsFromSetMap: (
    events: any,
    agEventGeneralGuidsToFilter: string[]
) => any = (events, agEventGeneralGuidsToFilter) =>
    [...events.entries()].reduce((accumulator, item) => {
        const [fieldGuid, selectedEventGuidSet] = item;
        const filteredEventGuids = new Set(
            [...selectedEventGuidSet.values()].filter(
                (eventGuid) => !agEventGeneralGuidsToFilter.includes(eventGuid)
            )
        );
        accumulator.set(fieldGuid, filteredEventGuids);
        return accumulator;
    }, new Map());

export const filterOutEventsFromSet: (events: any, agEventGeneralGuidsToFilter: string[]) => any = (
    events,
    agEventGeneralGuidsToFilter
) =>
    new Set(
        [...events.values()].filter((eventGuid) => !agEventGeneralGuidsToFilter.includes(eventGuid))
    );
