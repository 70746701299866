export const PROPS_CAN_DELETE = "canDelete";
export const PROPS_CONTROLLER_TEMPLATE_GUID = "controllerTemplateGuid";
export const PROPS_CREATED_BY = "createdByName";
export const PROPS_DELIMITER = "delimiter";
export const PROPS_EVENT_TYPE = "eventTypeName";
export const PROPS_FILE_TYPES = "fileTypes";
export const PROPS_MATCH_HEADER_NAME = "matchHeaderName";
export const PROPS_HEADER_ROW = "headerRow";
export const PROPS_IGNORE_NEGATIVE_VALUES = "ignoreNegativeValues";
export const PROPS_IMPORT_ATTRIBUTES = "importAttributes";
export const PROPS_IMPORT_ATTRIBUTE_GUID = "importAttributeGuid";
export const PROPS_IMPORT_SAMPLE_TEMPLATE_GUID = "importSampleTemplateGuid";
export const PROPS_IMPORT_TEMPLATE_FILE_GUID = "importTemplateFileGuid";
export const PROPS_IMPORT_TEMPLATE_LIST = "importTemplateList";
export const PROPS_IMPORT_TYPE_GUID = "importTypeGuid";
export const PROPS_IMPORT_ZIP_TEMPLATE_GUID = "importZipTemplateGuid";
export const PROPS_LAST_MODIFIED_BY = "lastModifiedByName";
export const PROPS_LAST_MODIFIED_DATE = "lastModifiedDate";
export const PROPS_MANUAL_ONLY = "manualOnly";
export const PROPS_STATISTICAL_OUTLIERS_YN = "statisticalOutliersYn";
export const PROPS_TEMPLATE_GUID = "templateGuid";
export const PROPS_TEMPLATE_NAME = "templateName";
export const PROPS_VENDOR_LIST = "vendorList";
export const PROPS_COLUMN_NAME_IN_FILE = "columnNameInFile";
export const PROPS_COLUMN_ORDER_IN_FILE = "columnOrderInFile";

export class Template {
    importTypeGuid?: string;
    templateName?: string;
    importZipTemplateGuid?: string;
    importTemplateList?: ImportTemplateList[];

    constructor(options: Template = {}) {
        this.importTypeGuid = options.importTypeGuid || "";
        this.templateName = options.templateName || null;
        this.importZipTemplateGuid = options.importZipTemplateGuid || "";
        this.importTemplateList = options.importTemplateList || new Array(new ImportTemplateList());
    }

    static updateTemplate(
        template: Record<string, unknown>,
        newProps: ImportTemplateList
    ): Record<string, unknown> {
        console.assert(template instanceof Template);
        Object.assign(template, newProps);
        return Object.freeze(template);
    }
}
export class ImportTemplateList {
    className?: string;
    templateGuid?: string;
    templateName?: string;
    importTypeGuid?: string;
    importTemplateFileGuid?: string;
    delimiter?: string;
    fileTypes?: string;
    importAttributes?: ImportAttribute[];
    headerRow?: number;
    matchHeaderName?: number;
    vendorList?: any[];
    manualOnly?: boolean;

    constructor(options: ImportTemplateList = {}, className = null) {
        this.className = className || "ImportTemplateList";
        this.templateGuid = options.templateGuid || "";
        this.templateName = options.templateName || null;
        this.importTypeGuid = options.importTypeGuid || "";
        this.importTemplateFileGuid = options.importTemplateFileGuid || "";
        this.delimiter = options.delimiter || ",";
        this.fileTypes = options.fileTypes || "";
        this.importAttributes = options.importAttributes || [];
        this.headerRow = String(options.headerRow) === "0" ? 0 : 1;
        this.matchHeaderName = options.matchHeaderName;
        // Todo - post remediation remove the check for vendorList
        this.vendorList = options.vendorList || [];
        this.manualOnly = options.manualOnly;
    }
    static updateImportTemplateList(
        importTemplateList: Record<string, unknown>,
        newProps: ImportTemplateList
    ): Record<string, unknown> {
        console.assert(importTemplateList instanceof ImportTemplateList);
        Object.assign(importTemplateList, newProps);
        return Object.freeze(importTemplateList);
    }
}

export class SamplingTemplate extends ImportTemplateList {
    importSampleTemplateGuid?: string;

    constructor(options: SamplingTemplate = {}) {
        super(options, "SamplingTemplate");
        this.importSampleTemplateGuid = options.importSampleTemplateGuid || "";
    }
}

export class ECDataTemplate extends ImportTemplateList {
    statisticalOutliersYn?: boolean;
    ignoreNegativeValues?: boolean;

    constructor(options: ECDataTemplate) {
        options = {
            statisticalOutliersYn: true,
            ignoreNegativeValues: true,
            ...options,
        };
        super(options, "ECDataTemplate");
        this.statisticalOutliersYn = options.statisticalOutliersYn;
        this.ignoreNegativeValues = options.ignoreNegativeValues;
    }
}

export class ImportAttribute {
    importAttributeGuid: string;
    columnOrderInFile: number;
    columnNameInFile: string;
    attributeUnitGuid: string;
    dbfColumnName: string;
    haasColumnName: string;
    haasTableName: string;
    haasUnitColumnName: string;

    constructor(
        importAttributeGuid: string,
        columnNameInFile: string,
        columnOrderInFile = 0,
        attributeUnitGuid = null,
        dbfColumnName = null,
        haasColumnName = null,
        haasTableName = null,
        haasUnitColumnName = null
    ) {
        this.importAttributeGuid = importAttributeGuid;
        this.columnOrderInFile = columnOrderInFile;
        this.columnNameInFile = columnNameInFile;
        this.attributeUnitGuid = attributeUnitGuid;
        this.dbfColumnName = dbfColumnName;
        this.haasColumnName = haasColumnName;
        this.haasTableName = haasTableName;
        this.haasUnitColumnName = haasUnitColumnName;
    }
}

export const ImportTemplateFactory = {
    classTypes: {
        ImportTemplateList,
        SamplingTemplate,
        ECDataTemplate,
    },
    createImportTemplateList(existingImportTemplatelist: ImportTemplateList, options = []): any {
        const newClass = this.classTypes[existingImportTemplatelist.className];
        return new newClass({ ...options });
    },
};

export interface SampleAttribute {
    sampleAttributeGuid: string;
    sampleAttributeName: string;
}

export interface SelectableAttribute {
    value: string;
    label: string;
}
