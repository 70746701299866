import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "./../../i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, isFertilizerType } from "./../data";
import { PICKLIST_FORMULATION_TYPE, PICKLIST_PHYSICAL_STATE } from "~/core/picklist/picklist-names";
import { UNIT_DENSITY } from "~/core/units/unit-names";
import {
    getAgBytesErrorClassNames,
    mapToPicklistValue,
    prepareSelectableOptions,
} from "~/admin/utils";
// Components
import { NumericInput, Section, SelectInput, SubSection } from "~/core";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
class Properties extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        intl: intlShape.isRequired,
        onPicklistChange: PropTypes.func,
        onTextChange: PropTypes.func,
        product: CustomPropTypes.product,
        productParentType: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const physicalState = prepareSelectableOptions(props[PICKLIST_PHYSICAL_STATE], {
            guid: PICKLIST_GUID,
            label: VALUE,
            id: ID,
        });
        this.state = {
            [PICKLIST_FORMULATION_TYPE]: prepareSelectableOptions(
                props[PICKLIST_FORMULATION_TYPE],
                { guid: PICKLIST_GUID, label: VALUE, id: ID }
            ),
            [PICKLIST_PHYSICAL_STATE]: physicalState,
            physicalStateId:
                physicalState.length && props.product[model.PROPS_PHYSICAL_STATE_GUID]
                    ? this.getPhysicalStateId(physicalState)
                    : null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.product[model.PROPS_FORMULATION_TYPE_GUID] != null &&
            nextProps[PICKLIST_FORMULATION_TYPE]
        ) {
            this.setState({
                [PICKLIST_FORMULATION_TYPE]: prepareSelectableOptions(
                    nextProps[PICKLIST_FORMULATION_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
        if (
            nextProps.product[model.PROPS_PHYSICAL_STATE_GUID] != null &&
            nextProps[PICKLIST_PHYSICAL_STATE]
        ) {
            this.setState(
                {
                    [PICKLIST_PHYSICAL_STATE]: prepareSelectableOptions(
                        nextProps[PICKLIST_PHYSICAL_STATE],
                        { guid: PICKLIST_GUID, label: VALUE, id: ID }
                    ),
                },
                () => {
                    const physicalStateId = this.getPhysicalStateId(
                        this.state[PICKLIST_PHYSICAL_STATE]
                    );
                    this.setState({ physicalStateId });
                    this.getDensityUnit(physicalStateId);
                }
            );
        }
        if (nextProps.product[model.PROPS_DENSITY_UNIT_GUID] != null && nextProps[UNIT_DENSITY]) {
            this.setState(
                {
                    [UNIT_DENSITY]: prepareSelectableOptions(nextProps[UNIT_DENSITY], {
                        guid: GUID,
                        label: NAME,
                        id: ID,
                    }),
                },
                () => {
                    this.getDensityUnit(
                        this.getPhysicalStateId(this.state[PICKLIST_PHYSICAL_STATE])
                    );
                }
            );
        }
    }

    getDensityUnit(physicalStateId) {
        //keeps the physical state and density dropdowns in sync with one another
        let densityString = "";
        let densityId;
        if (physicalStateId === "2") {
            densityId = "156"; //"lb/ft3"
        } else if (physicalStateId === "1") {
            densityId = "155"; //lb/gal";
        }
        if (this.props[UNIT_DENSITY]) {
            const idx = this.props[UNIT_DENSITY].findIndex((obj) => obj[ID] === densityId);
            this.props.onTextChange(
                model.PROPS_DENSITY_UNIT_GUID,
                idx > -1 ? this.props[UNIT_DENSITY][idx][GUID] : ""
            );
            densityString = idx > -1 ? this.props[UNIT_DENSITY][idx][NAME] : "";
        }
        return densityString;
    }

    getPhysicalStateId = (physicalState) => {
        const value = mapToPicklistValue({
            options: physicalState,
            selectedGuid: this.props.product[model.PROPS_PHYSICAL_STATE_GUID],
        });
        return value ? value.id : null;
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { product, productParentType } = this.props;
        return (
            <Section headerText={formatMessage(messages.properties)}>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        initialFilterStr=""
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[PICKLIST_PHYSICAL_STATE]}
                        placeholderText={formatMessage(messages.physicalState)}
                        value={mapToPicklistValue({
                            options: this.state[PICKLIST_PHYSICAL_STATE],
                            selectedGuid: product[model.PROPS_PHYSICAL_STATE_GUID],
                        })}
                        onChange={(value) => {
                            this.props.onPicklistChange(
                                {
                                    type: model.PROPS_PHYSICAL_STATE_NAME,
                                    guid: model.PROPS_PHYSICAL_STATE_GUID,
                                },
                                value,
                                () => {
                                    this.setState({
                                        physicalStateId: value ? value.id : "",
                                    });
                                }
                            );
                        }}
                    />
                </SubSection>
                <SubSection>
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={17}
                        containerClassNames={getAgBytesErrorClassNames(724, this.props.apiErrors, [
                            "density-input",
                        ])}
                        placeholderText={formatMessage(messages.density)}
                        labelText={formatMessage(messages.density)}
                        value={product[model.PROPS_DENSITY]}
                        onChange={(value) => this.props.onTextChange(model.PROPS_DENSITY, value)}
                    />
                    <span className="section-label">
                        {this.getDensityUnit(this.state.physicalStateId)}
                    </span>
                </SubSection>
                {isFertilizerType(productParentType) ? (
                    <SubSection>
                        <NumericInput
                            containerClassNames={["density-input"]}
                            tabIndex={0}
                            scale={4}
                            precision={17}
                            placeholderText={formatMessage(messages.specificGravity)}
                            labelText={formatMessage(messages.specificGravity)}
                            value={product[model.PROPS_SPECIFIC_GRAVITY]}
                            onChange={(value) =>
                                this.props.onTextChange(model.PROPS_SPECIFIC_GRAVITY, value)
                            }
                        />
                    </SubSection>
                ) : null}
                <SubSection>
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={8}
                        allowNegatives
                        placeholderText={formatMessage(messages.freezingTemperature, { unit: "F" })}
                        labelText={formatMessage(messages.freezingTemperature, {
                            unit: "F",
                        })}
                        value={this.props.product[model.PROPS_FREEZING_TEMPERATURE]}
                        onChange={(value) =>
                            this.props.onTextChange(model.PROPS_FREEZING_TEMPERATURE, value)
                        }
                    />
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={8}
                        allowNegatives
                        placeholderText={formatMessage(messages.saltingOutTemperature, {
                            unit: "F",
                        })}
                        labelText={formatMessage(messages.saltingOutTemperature, { unit: "F" })}
                        value={this.props.product[model.PROPS_SALTING_OUT_TEMPERATURE]}
                        onChange={(value) =>
                            this.props.onTextChange(model.PROPS_SALTING_OUT_TEMPERATURE, value)
                        }
                    />
                </SubSection>
                <SubSection>
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={17}
                        placeholderText={formatMessage(messages.pH)}
                        labelText={formatMessage(messages.pH)}
                        value={this.props.product[model.PROPS_PH]}
                        onChange={(value) => this.props.onTextChange(model.PROPS_PH, value)}
                    />
                    <NumericInput
                        tabIndex={0}
                        scale={2}
                        precision={6}
                        placeholderText={formatMessage(messages.solubility)}
                        labelText={formatMessage(messages.solubility)}
                        value={this.props.product[model.PROPS_SOLUBILITY_IN_WATER]}
                        onChange={(value) =>
                            this.props.onTextChange(model.PROPS_SOLUBILITY_IN_WATER, value)
                        }
                    />
                </SubSection>
                <SubSection>
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={17}
                        placeholderText={formatMessage(messages.angleOfRepose, {
                            unit: "D",
                        })}
                        labelText={formatMessage(messages.angleOfRepose, {
                            unit: "D",
                        })}
                        value={this.props.product[model.PROPS_ANGLE_OF_REPOSE]}
                        onChange={(value) =>
                            this.props.onTextChange(model.PROPS_ANGLE_OF_REPOSE, value)
                        }
                    />
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={17}
                        placeholderText={formatMessage(messages.shelfLife)}
                        labelText={formatMessage(messages.shelfLife)}
                        value={this.props.product[model.PROPS_SHELF_LIFE]}
                        onChange={(value) => this.props.onTextChange(model.PROPS_SHELF_LIFE, value)}
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        initialFilterStr=""
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[PICKLIST_FORMULATION_TYPE]}
                        placeholderText={formatMessage(messages.formulationType)}
                        value={mapToPicklistValue({
                            options: this.state[PICKLIST_FORMULATION_TYPE],
                            selectedGuid: product[model.PROPS_FORMULATION_TYPE_GUID],
                        })}
                        onChange={(value) => {
                            this.props.onPicklistChange(
                                {
                                    type: model.PROPS_FORMULATION_TYPE_NAME,
                                    guid: model.PROPS_FORMULATION_TYPE_GUID,
                                },
                                value
                            );
                        }}
                    />
                </SubSection>
            </Section>
        );
    }
}

export default injectIntl(Properties);
