import { AppHelpers } from "@ai360/core";
import { AGRIntelligence } from "./agrintelligence/agrintelligence";
import { FieldReport } from "./field/field";
import { ReportReportBooks } from "../report-books/report-report-books";
import { SummaryReport } from "./summary/summary";

const ReportsViewResolver = (childNameCode) => {
    if (AppHelpers.isNothing(childNameCode)) {
        return;
    }
    switch (childNameCode) {
        case "224": //AGRIntelligence
            if (AppHelpers.isNothing(childNameCode)) {
                throw new Error("AGRIntelligenceReport View is not defined.");
            }
            return {
                component: AGRIntelligence,
                halfScreen: true,
            };
        case "225": //field
            if (AppHelpers.isNothing(childNameCode)) {
                throw new Error("FieldReport View is not defined.");
            }
            return {
                component: FieldReport,
                halfScreen: true,
            };
        case "226": //summary
            if (AppHelpers.isNothing(childNameCode)) {
                throw new Error("SummaryReport View is not defined.");
            }
            return {
                component: SummaryReport,
                halfScreen: true,
            };
        case "427": //summary
            if (AppHelpers.isNothing(childNameCode)) {
                throw new Error("Report Books View is not defined.");
            }
            return {
                component: ReportReportBooks,
                halfScreen: true,
            };
        default:
            break;
    }
};
export default ReportsViewResolver;
