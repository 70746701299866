import { connect } from "react-redux";
import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { dropdowns, service } from "./../data";
import * as selectors from "~/action-panel/components/layer-module/components/layer-list/selectors";
import {
    clearFilteredLocationList,
    fetchCompanyList,
    fetchFilteredLocationList,
} from "~/admin/setup/surface-defaults/data/actions";
import {
    getCompanyList,
    getFilteredLocationList,
} from "~/admin/setup/surface-defaults/data/selectors";

const mapStateToProps = (state) => ({
    colorRamps: selectors.getColorRamps(state),
    companyList: getCompanyList(state),
    filteredLocationList: getFilteredLocationList(state),
    numberOfClassesOptions: selectors.getNumberOfClasses(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearFilteredLocationList: () => dispatch(clearFilteredLocationList()),
    fetchCompanyList: () => fetchCompanyList(),
    fetchFilteredLocationList: (userGuid) => fetchFilteredLocationList(userGuid),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditPanelContainer(AddEditPanel, { dropdowns, service }));
