import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { DialogBox, DialogBoxFooterType, TextArea } from "~/core";

import { messages } from "./i18n-messages";

import "./event-scouting-recommendation-modal.css";
import { actions as recsEventsActions } from "~/recs-events";

export interface IEventScoutingRecommendationModal_Props {
    isOpen?: boolean;
    onClose: () => void;
    recommendation?: string;
    onUpdateEventDetails: (fieldGuid: string, props: Record<string, any>) => void;
    recEventDetails?: Record<string, any>;
    agEventTransactionTypeGuid?: string;
    fieldGuid?: string;
    agEventGeneralGuid?: string;
    intl: intlShape;
}

export interface IEventScoutingRecommendationModal_State {
    recommendation: string;
}

export class EventScoutingRecommendationModal_ extends Component<
    IEventScoutingRecommendationModal_Props,
    IEventScoutingRecommendationModal_State
> {
    constructor(props: IEventScoutingRecommendationModal_Props) {
        super(props);
        this.state = {
            recommendation: this.props.recEventDetails.recommendation,
        };
    }

    private _updateAgEvent(newProps: Partial<IEventScoutingRecommendationModal_Props>): void {
        this.props.onUpdateEventDetails(this.props.fieldGuid, newProps);
    }

    private _onSave() {
        this._updateAgEvent({ recommendation: this.state.recommendation });
        this._onClose();
    }

    private _onClose() {
        this.setState({
            recommendation: this.props.recEventDetails.recommendation,
        });
        this.props.onClose();
    }

    render(): JSX.Element {
        const { isOpen } = this.props;

        const { formatMessage } = this.props.intl;

        return (
            <DialogBox
                className="recommendation-dialog"
                draggable={true}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isOpen}
                isModal={true}
                onAction={() => this._onSave()}
                onClose={() => this._onClose()}
                unrestricted
                title={`${formatMessage(messages.scoutingRecommendationTitle)}`}
            >
                <div className="recommendation-content">
                    <div className="recommendation-top-bar">
                        <TextArea
                            className="recommendation-dialog text-area-form-input"
                            maxLength={1000}
                            placeholderText={formatMessage(
                                messages.scoutingRecommendationPlaceholderText
                            )}
                            value={this.state.recommendation}
                            onChange={(v) => {
                                this.setState({
                                    recommendation: v,
                                });
                            }}
                        />
                    </div>
                </div>
            </DialogBox>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    onUpdateEventDetails: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateEventDetails(fieldGuid, newProps)),
});

export const EventScoutingRecommendationModal = connect<
    Partial<IEventScoutingRecommendationModal_Props>,
    Partial<IEventScoutingRecommendationModal_Props>,
    Partial<IEventScoutingRecommendationModal_Props>
>(
    null,
    mapDispatchToProps
)(injectIntl(EventScoutingRecommendationModal_));
