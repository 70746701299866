import { createSelector } from "reselect";

import { CORE_STATE_KEY } from "~/core";

import { UNITDATA_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[CORE_STATE_KEY][UNITDATA_DATA_KEY];

export const getUnitsState = (state) => _getModuleState(state);

export const getUnitPicklistFromCode = (state, picklistId) => _getModuleState(state)[picklistId];

export const getRawUnitPicklistFromCode = createSelector(
    getUnitPicklistFromCode,
    (unitValues) => unitValues || []
);

export const getUnitPicklistOptionsFromCode = createSelector(
    getUnitPicklistFromCode,
    (picklistValues) => {
        if (picklistValues == null) {
            return [];
        }
        picklistValues.sort((a, b) => (Number(a.id) < Number(b.id) ? -1 : 1));
        return picklistValues.map((val) => ({
            label: val.name,
            value: val.guid,
        }));
    }
);
