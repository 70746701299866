import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { getErrorMessages } from "~/i18n-error-messages";
import { setApiResult, resetApiResult } from "~/core/api/actions";
import { getApiError } from "~/core/api/selectors";
import { newApiResultToErrorDetails } from "~/utils/api/new-api";
import { APIError } from "@ai360/core";
import "./with-new-api-result.css";
import { messages } from "~/i18n-messages";
import { ErrorDialog } from "~/notifications/components/err-dialog";

const WithNewApiResult = (View, actionCallback = null) => {
    class NewErrorsDialog extends Component {
        static propTypes = {
            details: PropTypes.array,
            detailHeader: PropTypes.string,
            intl: PropTypes.intlShape,
            onExit: PropTypes.func,
        };

        constructor(props) {
            super(props);

            const { formatMessage } = props.intl;
            const detailCount = props.details.length;
            this.title = formatMessage(messages.errorsTitle, {
                count: detailCount,
            });
        }

        render() {
            return (
                <DialogBox
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={true}
                    onAction={() => this.props.onExit()}
                    onClose={() => this.props.onExit()}
                    title={this.title}
                >
                    <div className="results-container">
                        {this._detailListRender(this.props.details)}
                    </div>
                </DialogBox>
            );
        }

        _detailListRender(details) {
            if (details.length === 0) {
                return null;
            }

            return (
                <div>
                    {this.props.detailHeader ? (
                        <span className="detail-item-list">{this.props.detailHeader}</span>
                    ) : null}

                    <ul className="detail-item-list">
                        {details.map((item, index) => (
                            <li className="detail-item" key={index}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
    }

    class ErrorCodeListDialog extends Component {
        static propTypes = {
            errors: PropTypes.object,
            errorCodeList: PropTypes.array,
            intl: PropTypes.intlShape,
            onExit: PropTypes.func,
        };

        constructor(props) {
            super(props);

            const { formatMessage } = props.intl;
            const errorCount = props.errorCodeList.length;
            this.title = formatMessage(messages.errorsTitle, {
                count: errorCount,
            });
        }

        render() {
            return (
                <DialogBox
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={true}
                    onAction={() => this.props.onExit()}
                    onClose={() => this.props.onExit()}
                    title={this.title}
                >
                    <div dangerouslySetInnerHTML={this.props.errors} />
                </DialogBox>
            );
        }
    }

    class ServerErrorDialog extends Component {
        static propTypes = {
            errors: PropTypes.object,
            intl: PropTypes.intlShape,
            onExit: PropTypes.func,
        };

        render() {
            return (
                <ErrorDialog
                    isOpen={true}
                    err={new APIError("", this.props.errors)}
                    intl={this.props.intl}
                    retryAction={null}
                    onRetry={() => null}
                    onClose={() => this.props.onExit()}
                />
            );
        }
    }

    class WrapperComponent extends Component {
        static propTypes = {
            intl: intlShape.isRequired,
            apiError: PropTypes.object,
            setApiResult: PropTypes.func,
            resetApiResult: PropTypes.func,
            actionCallback: PropTypes.func,
            needs: PropTypes.func,
        };

        constructor(props) {
            super(props);
            this.state = {
                dialog: null,
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (typeof nextProps.apiError.newApiResults !== "undefined") {
                const { intl } = this.props;

                if (typeof nextProps.apiError.newApiResults.errors === "undefined") {
                    return;
                }

                const errorDetail = newApiResultToErrorDetails(
                    nextProps.apiError.newApiResults,
                    intl
                );
                this.setState({
                    dialog: (
                        <NewErrorsDialog
                            details={errorDetail.details}
                            intl={intl}
                            onExit={() => this._onExit()}
                            detailHeader={errorDetail.detailHeader}
                        />
                    ),
                });
            } else {
                if (
                    nextProps.apiError.errorCodeList &&
                    nextProps.apiError.errorCodeList.length > 0
                ) {
                    const errors = getErrorMessages(
                        this.props.intl.formatMessage,
                        nextProps.apiError,
                        "<br/>"
                    );
                    this.setState({
                        dialog: (
                            <ErrorCodeListDialog
                                errors={errors}
                                intl={this.props.intl}
                                onExit={() => this._onExit()}
                            />
                        ),
                    });
                } else if (
                    nextProps.apiError.apiResultObj &&
                    nextProps.apiError.apiResultObj.status &&
                    (nextProps.apiError.apiResultObj.exceptionMessage ||
                        nextProps.apiError.apiResultObj.message)
                ) {
                    // Server errors other than with 200 status
                    this.setState({
                        dialog: (
                            <ServerErrorDialog
                                errors={nextProps.apiError.apiResultObj}
                                intl={this.props.intl}
                                onExit={() => this._onExit()}
                            />
                        ),
                    });
                }
            }
        }

        componentWillUnmount() {
            this.props.resetApiResult();
        }

        render() {
            const Dialog = this.state.dialog;
            return (
                <div className="content-table-container with-new-api-result">
                    {Dialog}
                    <View {...this.props} />
                </div>
            );
        }

        _onExit = () => {
            this.setState({
                dialog: null,
            });
            this._resetApiResult();
        };

        _resetApiResult = () => {
            this.props.setApiResult({
                errorCodeList: [],
                model: {},
                apiResultObj: {},
                newApiResults: undefined,
            });
        };
    }

    const mapStateToProps = (state) => {
        return {
            apiError: getApiError(state),
        };
    };
    const mapDispatchToProps = (dispatch) => ({
        setApiResult: (payload) => dispatch(setApiResult(payload)),
        resetApiResult: (payload) => dispatch(resetApiResult(payload)),
        actionCallback: (payload) => actionCallback(payload),
    });
    return connect(mapStateToProps, mapDispatchToProps)(injectIntl(WrapperComponent));
};

export default WithNewApiResult;
