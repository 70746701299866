export interface IAccordionState {
    accordionId: number;
    items: AccordionItem[];
    recItemCount: number;
    recHeight: number;
}

/**
 * A list of dimensions, 1 based.
 *
 * e.g:
 *  - to only process children of the root AccordionItem[]: DimArray[2]
 *  - to only process items in the root AccordionItem[]: DimArray[1]
 *  - to only process children and grandchildren of the root AccordionItem[]: DimArray[2, 3]
 *
 * See the test cases for further examples
 */
export type DimArray = number[];

/**
 * A multi-dimensional index
 *
 * e.g. `itemList` is some `AccordionItem[]`, then `MultiDimIdx`
 * `[0, 1, 2]` will reference `itemList[0].children[1].children[2]`
 */
export type MultiDimIdx = number[];

/**
 * A `FlatIdx` of an `AccordionItem[]` is the index that you would get
 * if you iterated the `AccordionItem[]` depth-first.
 *
 * e.g.
 *  [
 *      - AccordionItem
 *          - MultiDimIdx: [0]
 *          - FlatIdx: 0
 *          - Children [
 *              - AccordionItem
 *                  - MultiDimIdx: [0, 0]
 *                  - FlatIdx: 1
 *                  - Children [
 *                      - AccordionItem
 *                          - MultiDimIdx: [0, 0, 0]
 *                          - FlatIdx: 2
 *                          - Children []
 *                  ]
 *              - AccordionItem
 *                  - MultiDimIdx: [0, 1]
 *                  - FlatIdx: 3
 *                  - Children []
 *          ]
 *      - AccordionItem
 *          - MultiDimIdx: [1]
 *          - FlatIdx: 4
 *          - Children []
 *  ]
 */
export type FlatIdx = number;

export type RecursiveFilterPredicateFunc = (arg: {
    item: AccordionItem;
    dimIdx: MultiDimIdx;
}) => boolean;

export class AccordionItem {
    public static updateAccordionItem(
        item: AccordionItem,
        newProps: Partial<AccordionItem>
    ): AccordionItem {
        const rv = { ...item, ...newProps };
        Object.setPrototypeOf(rv, AccordionItem.prototype);
        rv.recChildCount = !rv.expanded
            ? 0
            : rv.children?.reduce((p, item) => p + item.recChildCount, rv.children.length);
        rv.recHeight = !rv.expanded
            ? rv.height
            : rv.children?.reduce((p, item) => p + item.recHeight, rv.height);
        return Object.freeze(rv);
    }

    public static recursiveUpdate(
        itemList: AccordionItem[],
        newProps: Partial<AccordionItem>,
        dimensions: DimArray,
        curDim = 1
    ): AccordionItem[] {
        return itemList.map((item) => {
            const children = AccordionItem.recursiveUpdate(
                item.children,
                newProps,
                dimensions,
                curDim + 1
            );
            const propsToUpdate = dimensions.includes(curDim)
                ? { ...newProps, children }
                : { children };
            return AccordionItem.updateAccordionItem(item, propsToUpdate);
        });
    }

    public static recursiveFilter(
        itemList: AccordionItem[],
        predicateCallback: RecursiveFilterPredicateFunc,
        parentDimIdx: MultiDimIdx = []
    ): AccordionItem[] {
        return itemList
            .filter((item, idx) => predicateCallback({ item, dimIdx: [...parentDimIdx, idx] }))
            .map((item, idx) => {
                const children = AccordionItem.recursiveFilter(item.children, predicateCallback, [
                    ...parentDimIdx,
                    idx,
                ]);
                return AccordionItem.updateAccordionItem(item, { children });
            });
    }

    public readonly children: AccordionItem[];
    public readonly recChildCount: number;
    public readonly recHeight: number;

    // will be changed from any on merge of 10306;
    constructor(readonly height: number, readonly expanded: boolean, readonly payload: any) {
        this.children = [];
        /* maintained by reducer: */
        this.recChildCount = 0;
        this.recHeight = height;
        Object.freeze(this);
    }
}

export const itemFromFlatIdx = (
    itemList: AccordionItem[],
    idx: FlatIdx
): [AccordionItem, MultiDimIdx] => {
    const recItemFromIdx = (
        itemList: AccordionItem[],
        targetFlatIdx: FlatIdx,
        curFlatIdx: FlatIdx,
        dimIdx: MultiDimIdx
    ): [AccordionItem, MultiDimIdx] => {
        for (const curItem of itemList) {
            curFlatIdx++;
            dimIdx[dimIdx.length - 1]++;
            if (targetFlatIdx === curFlatIdx) {
                return [curItem, dimIdx];
            }
            // lets say `curItem` has 1 child, and it's the target
            //  `curItem.recChildCount` will be 1; `targetFlatIdx` = 9; `curFlatIdx` = 8
            if (curItem.expanded) {
                if (targetFlatIdx - curFlatIdx <= curItem.recChildCount) {
                    // target item is a child of this one
                    dimIdx.push(-1);
                    return recItemFromIdx(curItem.children, targetFlatIdx, curFlatIdx, dimIdx);
                }
                // target item is a sibling of this one ..
                curFlatIdx += curItem.recChildCount;
            }
        }
        throw new Error("unreachable");
    };
    return recItemFromIdx(itemList, idx, -1, [-1]);
};

export const getAccordionItemFromFlatIdx = (
    state: IAccordionState,
    flatIdx: FlatIdx
): [AccordionItem, MultiDimIdx] => {
    if (flatIdx < 0 || flatIdx >= state.recItemCount) {
        throw new ReferenceError(`Invalid \`flatIdx\` (${flatIdx})`);
    }
    return itemFromFlatIdx(state.items, flatIdx);
};

/**
 * Return `true` iff `checkGreaterDimIdx > checkLowerDimIdx` ..
 * dimIdx is compared most significant to least significant dimension
 * @return true if `checkGreaterDimIdx` is "greater" (i.e., it's displayed lower in the
 *   accordion (it's a later item)) but is not a descendant of `checkLowerDimIdx`
 */
export const dimIdxIsGreaterNonDescendant = (
    checkGreaterDimIdx: MultiDimIdx,
    checkLowerDimIdx: MultiDimIdx
): boolean => {
    for (let i = 0; i < Math.min(checkGreaterDimIdx.length, checkLowerDimIdx.length); i++) {
        if (checkGreaterDimIdx[i] === checkLowerDimIdx[i]) {
            continue;
        }
        return checkGreaterDimIdx[i] > checkLowerDimIdx[i];
    }
    return false;
};

export const dimIdxIsDescendantOrHigherIdxSibling = (
    checkAncestor: MultiDimIdx,
    checkDescendant: MultiDimIdx
): boolean => {
    if (checkDescendant.length < checkAncestor.length) {
        return false;
    }
    for (let i = 0; i < checkAncestor.length - 1; i++) {
        if (checkDescendant[i] !== checkAncestor[i]) {
            return false;
        }
    }
    const i = checkAncestor.length - 1;
    return checkDescendant[i] >= checkAncestor[i];
};

export const dimIdxIsDescendant = (
    checkAncestor: MultiDimIdx,
    checkDescendant: MultiDimIdx
): boolean => {
    if (checkDescendant.length <= checkAncestor.length) {
        return false;
    }
    for (let i = 0; i < checkAncestor.length - 1; i++) {
        if (checkDescendant[i] !== checkAncestor[i]) {
            return false;
        }
    }
    return true;
};

/**
 * Compare dimIdx, suitable for `Array.sort`.
 */
export const dimIdxCompare = (lhsDimIdx: MultiDimIdx, rhsDimIdx: MultiDimIdx): -1 | 0 | 1 => {
    for (let i = 0; i < Math.min(lhsDimIdx.length, rhsDimIdx.length); i++) {
        if (lhsDimIdx[i] < rhsDimIdx[i]) {
            return -1;
        }
        if (lhsDimIdx[i] > rhsDimIdx[i]) {
            return 1;
        }
    }
    return lhsDimIdx.length < rhsDimIdx.length ? -1 : lhsDimIdx.length > rhsDimIdx.length ? 1 : 0;
};

/**
 * @return true if `dimIdx` is within range of `itemList`
 */
export const isValidDimIdx = (itemList: AccordionItem[], dimIdx: MultiDimIdx): boolean => {
    let idx = 0;
    for (; idx < dimIdx.length - 1; idx++) {
        if (dimIdx[idx] < 0 || dimIdx[idx] >= itemList.length) {
            return false;
        }
        const item = itemList[dimIdx[idx]];
        itemList = item.children;
    }
    return dimIdx[idx] >= 0 && dimIdx[idx] < itemList.length;
};

/**
 * @return the `dimIdx` of the item in `itemList` immediately following `dimIdx`, or
 *         `undefined` if `dimIdx` references the last item in `itemList`
 */
export const getNextDimIdx = (
    itemList: AccordionItem[],
    dimIdx: MultiDimIdx
): MultiDimIdx | undefined => {
    console.assert(isValidDimIdx(itemList, dimIdx));

    const rootItemList = itemList;

    let idx = 0;
    for (; idx < dimIdx.length - 1; idx++) {
        const item = itemList[dimIdx[idx]];
        itemList = item.children;
    }

    let nextDimIdx;
    if (itemList[dimIdx[idx]].children.length > 0) {
        nextDimIdx = dimIdx.concat([0]);
    } else {
        // no children, check for sibiling; if not, go to parent's next sibling
        nextDimIdx = [...dimIdx.slice(0, idx), dimIdx[idx] + 1];
        while (idx >= 0 && !isValidDimIdx(rootItemList, nextDimIdx)) {
            idx--;
            nextDimIdx = [...dimIdx.slice(0, idx), dimIdx[idx] + 1];
        }
    }
    return idx >= 0 ? nextDimIdx : undefined;
};

/**
 * @return the `dimIdx` of the next non-descendent item in `itemList` after `dimIdx`, or
 *         `undefined` if there are no non-descendant items after `dimIdx`
 *
 * This is useful for getting an `endIdx` to pass to `getDimIdxSliceIter` where you want
 *   all descendents of `itemList`
 */
export const getNextGreaterNonDescendantDimIdx = (
    itemList: AccordionItem[],
    dimIdx: MultiDimIdx
): MultiDimIdx | undefined => {
    const greaterNonDescendantDims = Array.from({ length: dimIdx.length }, (_, idx) => idx + 1);
    const dimIdxIter = getDimIdxSliceIter(
        itemList,
        dimIdx,
        undefined,
        false,
        greaterNonDescendantDims
    );
    dimIdxIter.next(); // startDimIdx is always inclusive
    return dimIdxIter.next().value;
};

export const getFlatIdxFromDimIdx = (itemList: AccordionItem[], dimIdx: MultiDimIdx): number => {
    const flatIdxFromDimIdx = (itemList, targetDimIdx, curDimIdx, curFlatIdx) => {
        for (const curItem of itemList) {
            curFlatIdx++;
            curDimIdx[curDimIdx.length - 1]++;

            if (dimIdxCompare(targetDimIdx, curDimIdx) === 0) {
                return curFlatIdx;
            } else if (targetDimIdx[curDimIdx.length - 1] === curDimIdx[curDimIdx.length - 1]) {
                return flatIdxFromDimIdx(
                    curItem.children,
                    targetDimIdx,
                    curDimIdx.concat([-1]),
                    curFlatIdx
                );
            } else {
                curFlatIdx += curItem.recChildCount;
            }
        }
    };
    return flatIdxFromDimIdx(itemList, dimIdx, [-1], -1);
};

/**
 * Get an item from it's `dimIdx`, e.g. [0, 1, 2] will return `itemList[0].children[1].children[2]`
 */
export const getItem = (itemList: AccordionItem[], dimIdx: MultiDimIdx): AccordionItem => {
    let idx = 0;
    for (; idx < dimIdx.length - 1; idx++) {
        const item = itemList[dimIdx[idx]];
        itemList = item.children;
    }
    return itemList[dimIdx[idx]];
};

/**
 * This will iterate thru the passed-in `itemList` recursively
 * `startIdx` is a single index into `itemList`, not a `MultiDimIdx`
 * `endDimIdx` can be `undefined` to continue to the end, or a "dimIdx" (i.e,
 *  an array of indices) to yield until.
 *  `endDimIdx` *must* be > `parentDimIdx.concat(startIdx)`
 *
 * This is meant to be efficient, so parameter sanity checks are not performed.
 * This generator function shouldn't be exported ...
 */
const getRecDimIdxSliceIter = function* (
    itemList: AccordionItem[],
    parentDimIdx: MultiDimIdx,
    startIdx: number,
    endDimIdx?: MultiDimIdx,
    includeDims?: DimArray
): IterableIterator<number[]> {
    const callerYieldNoMore =
        endDimIdx != null &&
        endDimIdx.length - 1 === parentDimIdx.length &&
        dimIdxCompare(endDimIdx.slice(0, parentDimIdx.length), parentDimIdx) === 0;
    const endIdx = callerYieldNoMore
        ? (endDimIdx as MultiDimIdx)[(endDimIdx as MultiDimIdx).length - 1]
        : itemList.length;

    const curDimIdx = parentDimIdx.concat(startIdx);
    const includeDim = includeDims == null || includeDims.includes(curDimIdx.length);
    if (includeDim || curDimIdx.length < Math.max(...includeDims)) {
        for (let i = startIdx; i < endIdx; i++, curDimIdx[curDimIdx.length - 1]++) {
            if (includeDim) {
                yield [...curDimIdx];
            }
            const item = itemList[i];
            if (item.children.length > 0) {
                const yieldNoMore = yield* getRecDimIdxSliceIter(
                    item.children,
                    curDimIdx,
                    0,
                    endDimIdx,
                    includeDims
                );
                if (yieldNoMore) {
                    return yieldNoMore;
                }
            }
        }
    }

    return callerYieldNoMore;
};

/**
 * Generator to return `dimIdx` values starting w/ `startDimIdx` and ending before or at `endDimIdx`
 * @param rootItemList generally the `items` element from an AccordionState instance
 * @param startDimIdx starting `MultiDimIdx` (always inclusive)
 * @param endDimIdx ending `MultiDimIdx` (optionally inclusive)
 * @param endIsInclusive whether `endDimIdx` should be inclusive (default false)
 * @param includeDims the dimensions to include; e.g. [1, 2] will return all `dimIdx` values with length === 1 or === 2
 */
export const getDimIdxSliceIter = function* (
    rootItemList: AccordionItem[],
    startDimIdx: MultiDimIdx,
    endDimIdx?: MultiDimIdx,
    endIsInclusive?: boolean,
    includeDims?: DimArray
): IterableIterator<number[]> {
    if (rootItemList.length === 0) {
        return;
    }

    console.assert(isValidDimIdx(rootItemList, startDimIdx));

    if (endDimIdx != null) {
        console.assert(isValidDimIdx(rootItemList, endDimIdx));
        console.assert(
            includeDims == null || !endIsInclusive || includeDims.includes(endDimIdx.length)
        );
    } else {
        console.assert(!endIsInclusive, "cannot have `endIsInclusive` without `endDimIdx`");
    }

    if (endDimIdx != null) {
        const dicmp = dimIdxCompare(startDimIdx, endDimIdx);
        if (dicmp === 1) {
            return;
        }
        if (dicmp === 0) {
            if (endIsInclusive) {
                yield endDimIdx;
            }
            return;
        }
        console.assert(dicmp === -1);
    }

    // this loop calls `getRecDimIdxSliceIter` for each dimension in `startDimIdx`
    for (let dim = startDimIdx.length - 1; dim >= 0; dim--) {
        const parentDimIdx = startDimIdx.slice(0, dim);
        const dimIdx = startDimIdx.slice(0, dim + 1);
        let itemList = rootItemList;
        for (let idx = 0; idx < dimIdx.length - 1; idx++) {
            const item = itemList[dimIdx[idx]];
            itemList = item.children;
        }

        let startIdx = dimIdx[dim];
        if (dim !== startDimIdx.length - 1) {
            startIdx++;
        }

        const yieldNoMore = yield* getRecDimIdxSliceIter(
            itemList,
            parentDimIdx,
            startIdx,
            endDimIdx,
            includeDims
        );
        if (yieldNoMore) {
            break;
        }
    }
    if (endIsInclusive) {
        yield endDimIdx as MultiDimIdx; // console.assert ensures above
    }
};
