import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { TextInput, Bucket, BucketHeader, SelectInput, Checkbox } from "~/core";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import { IAnalysisSeedStrongSummary } from "~/recs-events/analysis/model";
import { AnalysisIcon } from "./icons/analysis";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import { LayerAPI } from "@ai360/core";

const messages = defineMessages({
    analysisFormLabelText: {
        id: "layerModule.analysisInfo.formNormalizedYieldLabelText",
        defaultMessage: "General Information",
    },
    analysisFormSideBarText: {
        id: "layerModule.analysisInfo.formNormalizedYieldSideBarText",
        defaultMessage: "Analysis",
    },
    analysisNamePlaceholderText: {
        id: "layerModule.analysisInfo.nameNormalizedYieldPlaceholderText",
        defaultMessage: "Analysis Layer Name",
    },
    analysisNormalizedYieldPlaceholderText: {
        id: "layerModule.analysisInfo.normalizedYieldsPlaceholderText",
        defaultMessage: "Normalized Yield",
    },
    analysisECDataPlaceholderText: {
        id: "layerModule.analysisInfo.ecDataPlaceholderText",
        defaultMessage: "Use EC Data",
    },
    analysisDataRequired: {
        id: "layerModule.analysisInfo.analysisDataRequired",
        defaultMessage: "No Soil Sampling data exists for the selected areas",
    },
    analysisYieldRequired: {
        id: "layerModule.analysisInfo.analysisYieldRequired",
        defaultMessage: "No Yield data exists for the selected areas",
    },
    analysisDuplicateName: {
        id: "layerModule.analysisInfo.seedStrong.analysisDuplicateName",
        defaultMessage: "Cannot have duplicate layer names.",
    },
    seedStrongCecRequired: {
        id: "layerModule.analysisInfo.seedStrongCecRequired",
        defaultMessage: "Missing Test Results For CEC.",
    },
    seedStrongOmRequired: {
        id: "layerModule.analysisInfo.seedStrongOmRequired",
        defaultMessage: "Missing Test Results For OM (%) or Humic Matter (%).",
    },
});

export const formLabelMessage = messages.analysisFormSideBarText;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText], // ErrorCode.AnalysisLayerNameIsRequired
    [2836, messages.analysisDataRequired],
    [2837, messages.analysisYieldRequired],
    [2806, messages.analysisDuplicateName],
    [3008, messages.seedStrongOmRequired],
    [3009, messages.seedStrongCecRequired],
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

interface AnalysisSeedStrongFormProps {
    analysisSummary: IAnalysisSeedStrongSummary;
    intl: intlShape;
    seedStrongOptions: LayerAPI.ISeedStrongInfo;
    analysisDetailsErrorCodeList: number[];
    onUpdateCurrentAnalysisSummary: (newProps: Partial<IAnalysisSeedStrongSummary>) => void;
}

const AnalysisSeedStrongForm = (props: AnalysisSeedStrongFormProps): JSX.Element => {
    const {
        analysisSummary,
        seedStrongOptions,
        analysisDetailsErrorCodeList,
        onUpdateCurrentAnalysisSummary,
    } = props;
    const { formatMessage } = props.intl;

    //state variables
    const [errorMessagePlaceholderSet, setErrorMessagePlaceholderSet] = useState<Set<any>>(
        new Set()
    );

    useEffect(() => {
        if (!analysisDetailsErrorCodeList) {
            return;
        }

        const newErrorMessagePlaceholderSet = getErrorMessagePlaceholderSet();
        setErrorMessagePlaceholderSet(newErrorMessagePlaceholderSet);
    }, [analysisDetailsErrorCodeList]);

    const getErrorMessagePlaceholderSet = () => {
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    };

    const getAnalysisErrors = (errorMessagePlaceholderSet, formatMessage) => {
        if (errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0) {
            return null;
        }
        const errorMessages = [];
        errorMessagePlaceholderSet.forEach((errorEntry) => {
            errorMessages.push(
                <div className="analysis-error-message">{formatMessage(errorEntry)}</div>
            );
        });
        return errorMessages;
    };

    const updateAnalysisSummary = (newProps) => {
        onUpdateCurrentAnalysisSummary(newProps);
    };

    const analysisErrors = getAnalysisErrors(errorMessagePlaceholderSet, formatMessage);
    const seedStrongOptionsMap =
        seedStrongOptions && seedStrongOptions.normalizedYieldLayerList
            ? seedStrongOptions.normalizedYieldLayerList.map((item, key) => {
                  return {
                      label: item.name,
                      key: key,
                      value: item.analysisLayerGuid,
                  };
              })
            : [];

    return (
        <div className="rec-event-info-form">
            {analysisErrors}
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.analysisFormLabelText)}</BucketHeader>
                <TextInput
                    containerClassNames={[
                        "analysis-form-input",
                        {
                            "form-input-error": errorMessagePlaceholderSet.has(
                                messages.analysisMethodPlaceholderText
                            ),
                        },
                    ]}
                    onChange={(v) => updateAnalysisSummary({ name: v })}
                    placeholderText={formatMessage(messages.analysisNamePlaceholderText)}
                    required={true}
                    maxLength={50}
                    value={analysisSummary.name}
                />
            </Bucket>
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>
                    {formatMessage(messages.analysisNormalizedYieldPlaceholderText)}
                </BucketHeader>
                <SelectInput
                    clearable={false}
                    containerClassNames={["analysis-form-input"]}
                    onChange={(v) =>
                        updateAnalysisSummary({
                            normalizedYieldGuid: v,
                        })
                    }
                    options={seedStrongOptionsMap}
                    placeholderText={formatMessage(messages.analysisNormalizedYieldPlaceholderText)}
                    required={true}
                    value={analysisSummary.normalizedYieldGuid}
                />
            </Bucket>
            {!seedStrongOptions.ecDataExists ? null : (
                <Checkbox
                    className="analysis-input"
                    onChange={(evt, value) => updateAnalysisSummary({ useEcData: value })}
                    label={formatMessage(messages.analysisECDataPlaceholderText)}
                    value={analysisSummary.useEcData}
                />
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onUpdateCurrentAnalysisSummary: (newProps) =>
        dispatch(actions.updateSeedStrongAnalysisSummary(newProps)),
});

const mapStateToProps = (state) => {
    const seedStrongOptions = selectors.getSeedStrongOptions(state);
    return {
        seedStrongOptions,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnalysisSeedStrongForm));
