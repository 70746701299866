import { createAction } from "redux-act";
import { getRequestId } from "./utils";
import { SET_REQUEST_ID, SET_REQUEST_STATUS, CLEAR_REQUEST_IDS } from "./utils";

// Actions
export const setRequestId = createAction(SET_REQUEST_ID);
export const setRequestStatus = createAction(SET_REQUEST_STATUS);
export const clearRequestIds = createAction(CLEAR_REQUEST_IDS);

export const needs = (actions, dispatch) => {
    const requestId = getRequestId();
    dispatch(
        setRequestId({
            actions,
            requestId,
        })
    );
    return requestId;
};
