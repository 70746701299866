import * as models from "./model";

import Immutable from "immutable";

import { FieldAPI, RecAPI, SearchAPI } from "@ai360/core";
import { ClassBreak, ZoneGeometryInfo } from "@ai360/core";
import { IRecChangedMessage } from "../model";
import { action } from "typesafe-actions";
import { IFieldData } from "./interfaces";

export type recAreaIdType = number;

export const ACTIVATE_REC = "recs-events/ACTIVATE_REC";
export const ACTIVATE_REC_SUCCESSFUL = "recs-events/ACTIVATE_REC_SUCCESSFUL";
export const ADD_DEFAULT_PRODUCT = "recs-events/ADD_DEFAULT_PRODUCT";
export const ADD_REC_ADJUSTMENTS = "recs-events/ADD_REC_ADJUSTMENTS";
export const ADD_UPDATE_REC_PRODUCT = "recs-events/ADD_UPDATE_REC_PRODUCT";
export const BATCH_ADD_REC_ADJUSTMENTS = "recs-events/BATCH_ADD_REC_ADJUSTMENTS";
export const BATCH_UPDATE_REC_DETAILS = "recs-events/BATCH_UPDATE_REC_DETAILS";
export const CANCEL_REC_DETAILS = "recs-events/CANCEL_REC_DETAILS";
export const COPY_BATCH_TEMPLATE_TO_RECS = "recs-events/COPY_BATCH_TEMPLATE_TO_RECS";
export const COPY_REC_DETAILS = "recs-events/COPY_REC_DETAILS";
export const CLEAR_REC_DETAILS = "recs-events/CLEAR_REC_DETAILS";
export const CREATE_NEW_BATCH_REC = "recs-events/CREATE_NEW_BATCH_REC";
export const CREATE_NEW_REC_DETAILS = "recs-events/CREATE_NEW_REC_DETAILS";
export const CREATE_NEW_CLASSIFIED_REC_DETAILS = "recs-events/CREATE_NEW_CLASSIFIED_REC_DETAILS";
export const CURRENT_AREA_IDS_SET = "recs-events/CURRENT_AREA_IDS_SET";
export const DELETE_RECS = "rec-module/DELETE_RECS";
export const DELETE_RECS_SUCCESSFUL = "rec-module/DELETE_RECS_SUCCESSFUL";
export const FETCH_BATCH_REC_DETAILS = "recs-events/FETCH_BATCH_REC_DETAILS";
export const FETCH_REC_DETAILS = "recs-events/FETCH_REC_DETAILS";
export const FETCH_REC_DETAILS_SUCCEEDED = "recs-events/FETCH_REC_DETAILS_SUCCEEDED";
export const FETCH_RECS = "recs-events/FETCH_RECS";
export const FETCH_RECS_SUCCEEDED = "recs-events/FETCH_RECS_SUCCEEDED";
export const INITIALIZE_RECS_ZONES = "recs-events/INITIALIZE_RECS_ZONES";
export const INITIALIZE_RECS_ZONES_SUCCEEDED = "recs-events/INITIALIZE_RECS_ZONES_SUCCEEDED";
export const PROCESS_CANCEL_REC = "recs-events/PROCESS_CANCEL_REC";
export const REMOVE_INACTIVE_REC_GENERAL = "recs-events/REMOVE_INACTIVE_REC_GENERAL";
export const REPLACE_INACTIVE_REC_GENERAL = "recs-events/REPLACE_INACTIVE_REC_GENERAL";
export const REMOVE_REC_GENERAL = "recs-events/REMOVE_REC_GENERAL";
export const REPLACE_REC_GENERAL = "recs-events/REPLACE_REC_GENERAL";
export const RESET_CLASSIFIED_REC_AREA_POLYGONS = "recs-events/RESET_CLASSIFIED_REC_AREA_POLYGONS";
export const RESET_CURRENT_REC_AREA_REC = "recs-events/RESET_CURRENT_REC_AREA_REC";
export const RESET_REC_AREA_POLYGONS = "recs-events/RESET_REC_AREA_POLYGONS";
export const SAVE_REC_DETAILS = "recs-events/SAVE_REC_DETAILS";
export const SAVE_REC_DETAILS_FAILED = "recs-events/SAVE_REC_DETAILS_FAILED";
export const SAVE_REC_DETAILS_SUCCEEDED = "recs-events/SAVE_REC_DETAILS_SUCCEEDED";
export const SET_LAST_REC_BATCH_FIELD_GUID = "recs-events/SET_LAST_REC_BATCH_FIELD_GUID";
export const SET_BATCH_ANALYSIS_LAYERS = "recs-events/SET_BATCH_ANALYSIS_LAYERS";
export const SET_REC_TYPE_INFO = "recs-events/SET_REC_TYPE_INFO";
export const SET_REC_MODEL = "recs-events/SET_REC_MODEL";
export const SHOW_BATCH_RECS_EDIT = "recs-events/SHOW_BATCH_RECS_EDIT";
export const UPDATE_BATCH_REC_AREA_REC = "recs-events/UPDATE_BATCH_REC_AREA_REC";
export const UPDATE_BATCH_REC_DETAILS = "recs-events/UPDATE_BATCH_REC_DETAILS";
export const UPDATE_CANCELLED_RECS = "recs-events/UPDATE_CANCELLED_RECS";
export const UPDATE_CURRENT_REC_AREA_REC = "recs-events/UPDATE_CURRENT_REC_AREA_REC";
export const UPDATE_PLANTING_REC_MODEL = "recs-events/UPDATE_PLANTING_REC_MODEL";
export const UPDATE_REC_MODEL = "recs-events/UPDATE_REC_MODEL";
export const UPDATE_REC = "recs-events/UPDATE_REC";
export const UPDATE_REC_STATUS = "recs-events/UPDATE_REC_STATUS";
export const UPDATE_REC_AREA_POLYGONS = "recs-events/UPDATE_REC_AREA_POLYGONS";
export const UPDATE_REC_DETAILS = "recs-events/UPDATE_REC_DETAILS";
export const UPDATE_REC_SUMMARY_IMPORTED_STATUS = "rec-events/UPDATE_REC_SUMMARY_IMPORTED_STATUS";
export const UPDATE_REC_SUMMARY_STATUS = "rec-events/UPDATE_REC_SUMMARY_STATUS";
export const UPDATE_SELECTED_FIELD_RECS = "recs-events/UPDATE_SELECTED_FIELD_RECS";

export const activateRec = (agRecGeneralGuid: string) => action(ACTIVATE_REC, { agRecGeneralGuid });

export const activateRecSuccessful = () => action(ACTIVATE_REC_SUCCESSFUL);

export const addDefaultProduct = (
    fieldGuid: string,
    recNutrient: models.RecNutrient,
    recNutrientIdx: number,
    recNutrientList: models.RecNutrient[]
) => action(ADD_DEFAULT_PRODUCT, { fieldGuid, recNutrient, recNutrientIdx, recNutrientList });

export const addRecAdjustments = (recNutrient: models.RecNutrient) =>
    action(ADD_REC_ADJUSTMENTS, { recNutrient });

export const addUpdateRecProduct = (
    fieldGuid: string,
    recNutrientGuid: string,
    recNutrientList: models.RecNutrient[],
    originalGA: string
) => action(ADD_UPDATE_REC_PRODUCT, { fieldGuid, recNutrientGuid, recNutrientList, originalGA });

export const batchAddRecAdjustments = (recNutrient: models.RecNutrient) =>
    action(BATCH_ADD_REC_ADJUSTMENTS, { recNutrient });
export const batchUpdateRecDetails = (
    payload: Array<{ fieldGuid: string; newProps: Partial<models.RecDetails> }>
) => action(BATCH_UPDATE_REC_DETAILS, payload);

export const cancelRecDetails = () => action(CANCEL_REC_DETAILS);

export const clearRecDetails = () => action(CLEAR_REC_DETAILS);

export const copyBatchTemplateToRecs = () => action(COPY_BATCH_TEMPLATE_TO_RECS);

export const copyRecDetails = (recGeneralGuid: string) =>
    action(COPY_REC_DETAILS, { recGeneralGuid });

export const createNewBatchRec = (
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>,
    recTypeInfo: models.RecTypeInfo
) => action(CREATE_NEW_BATCH_REC, { fieldGuidList, fieldGuidToBoundaryGuidMap, recTypeInfo });

export const createNewClassifiedRec = (
    field: IFieldData,
    surfaceInfo: Record<string, any>,
    recTypeInfo: models.RecTypeInfo,
    triggeredPage: unknown
) => action(CREATE_NEW_CLASSIFIED_REC_DETAILS, { field, surfaceInfo, recTypeInfo, triggeredPage });

export const createNewRecDetails = (
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>,
    recTypeInfo: models.RecTypeInfo,
    recAreaList?: models.RecArea[] | null,
    croppingSeasonGuid?: string | null
) =>
    action(CREATE_NEW_REC_DETAILS, {
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
        recTypeInfo,
        recAreaList,
        croppingSeasonGuid,
    });

export const currentAreaIdsSet = () => action(CURRENT_AREA_IDS_SET);

export const deleteRecs = (recGeneralGuidList: string[]) =>
    action(DELETE_RECS, { recGeneralGuidList });

export const deleteRecsSuccessful = () => action(DELETE_RECS_SUCCESSFUL);

export const fetchRecDetails = (recGeneralGuid: string) =>
    action(FETCH_REC_DETAILS, { recGeneralGuid });

export const fetchBatchRecDetails = () => action(FETCH_BATCH_REC_DETAILS);

export const fetchRecDetailsSucceeded = (recDetails: models.RecDetails) =>
    action(FETCH_REC_DETAILS_SUCCEEDED, { recDetails });

export const fetchSelectedFieldRecs = () => action(FETCH_RECS);

export const fetchSelectedFieldRecsSucceeded = (
    fieldGuidToRecListMap: Immutable.OrderedMap<string, models.RecSummary[]>,
    fieldGuidToInactiveRecListMap: Immutable.OrderedMap<string, models.RecSummary[]>,
    fieldGuidToFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>,
    fieldGuidToInactiveFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>
) =>
    action(FETCH_RECS_SUCCEEDED, {
        fieldGuidToRecListMap,
        fieldGuidToInactiveRecListMap,
        fieldGuidToFieldMap,
        fieldGuidToInactiveFieldMap,
    });

export const initializeRecsZones = () => action(INITIALIZE_RECS_ZONES);

export const initializeRecsZonesSucceeded = () => action(INITIALIZE_RECS_ZONES_SUCCEEDED);

export const processCancelRec = () => action(PROCESS_CANCEL_REC);

export const removeInactiveRecGeneral = (
    changes: Array<{
        fieldGuid: string;
        recGeneralGuid: string;
        recData: FieldAPI.IRecSummary;
    }> = []
) => action(REMOVE_INACTIVE_REC_GENERAL, { changes, fields: null as SearchAPI.IFieldResult[] });

export const replaceInactiveRecGeneral = (
    changes: IRecChangedMessage[],
    fields: SearchAPI.IFieldResult[]
) => action(REPLACE_INACTIVE_REC_GENERAL, { changes, fields });

export const removeRecGeneral = (
    changes: Array<{
        fieldGuid: string;
        recGeneralGuid: string;
    }> = []
) => action(REMOVE_REC_GENERAL, { changes, fields: null as SearchAPI.IFieldResult[] });

export const replaceRecGeneral = (
    changes: IRecChangedMessage[],
    fields: SearchAPI.IFieldResult[]
) => action(REPLACE_REC_GENERAL, { changes, fields });

export const resetClassifiedRecAreaPolygons = (
    fieldGuid: string,
    areaIdToPolygonMap: Map<number, ZoneGeometryInfo>,
    areaIdToClassBreaksMap: Map<number, ClassBreak>
) =>
    action(RESET_CLASSIFIED_REC_AREA_POLYGONS, {
        fieldGuid,
        areaIdToPolygonMap,
        areaIdToClassBreaksMap,
    });

export const resetRecAreaPolygons = (
    fieldGuid: string,
    areaIdToPolygonMap: Map<number, ZoneGeometryInfo>
) =>
    action(RESET_REC_AREA_POLYGONS, {
        fieldGuid,
        areaIdToPolygonMap,
        areaIdToClassBreaksMap: null as Map<number, ClassBreak>,
    });

export const resetCurrentRecAreaRec = (fieldGuid: string, recTypeGuid: string) =>
    action(RESET_CURRENT_REC_AREA_REC, { fieldGuid, recTypeGuid });

export const setBatchAnalysisLayerMap = (batchToAnalysisLayerMap: any) =>
    action(SET_BATCH_ANALYSIS_LAYERS, { batchToAnalysisLayerMap });

export const saveRecDetails = () => action(SAVE_REC_DETAILS);

export const saveRecDetailsFailed = (errorCodeList: number[], errorFieldGuids: string[]) =>
    action(SAVE_REC_DETAILS_FAILED, { errorCodeList, errorFieldGuids });

export const saveRecDetailsSucceeded = (isEquation: boolean) =>
    action(SAVE_REC_DETAILS_SUCCEEDED, { isEquation });

export const setRecModel = (
    fieldGuid: string,
    areaId: recAreaIdType,
    newProps: Record<string, any>
) => action(SET_REC_MODEL, { fieldGuid, areaId, newProps });

export const setLastRecBatchFieldGuid = (batchFieldGuid: string) =>
    action(SET_LAST_REC_BATCH_FIELD_GUID, { batchFieldGuid });

export const setRecTypeInfo = (recTypeInfoList: RecAPI.IRecType[]) =>
    action(SET_REC_TYPE_INFO, { recTypeInfoList });
export const showBatchRecsEdit = (
    selectedRecGuidSet: string[],
    recGeneralGuidToRecMap: Map<string, string>,
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>
) =>
    action(SHOW_BATCH_RECS_EDIT, {
        selectedRecGuidSet,
        recGeneralGuidToRecMap,
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
    });

export const updateBatchRecAreaRec = (
    batchRecDetailsForEdit: string[],
    recNutrientList: models.RecNutrient[]
) => action(UPDATE_BATCH_REC_AREA_REC, { batchRecDetailsForEdit, recNutrientList });

export const updateBatchRecDetails = (recAreaList: models.RecArea[]) =>
    action(UPDATE_BATCH_REC_DETAILS, { recAreaList });

export const updateCancelledRecs = (clearedRecDetails: any) =>
    action(UPDATE_CANCELLED_RECS, { clearedRecDetails });

export const updateCurrentRecAreaRec = (fieldGuid: string, newProps: unknown) =>
    action(UPDATE_CURRENT_REC_AREA_REC, { fieldGuid, newProps });

export const updatePlantingRecModel = (payload: Record<string, any>) =>
    action(UPDATE_PLANTING_REC_MODEL, payload);

export const updateRecModel = (
    fieldGuid: string,
    areaId: recAreaIdType,
    isCopy: boolean,
    newProps: unknown
) => action(UPDATE_REC_MODEL, { fieldGuid, areaId, isCopy, newProps });

export const updateRec = (recSummary: models.RecSummary) => action(UPDATE_REC, { recSummary });

export const updateRecStatus = (
    fieldGuidToRecGeneralGuids: Map<string, string[]>,
    recStatus: number
) =>
    action(UPDATE_REC_STATUS, {
        fieldGuidToRecGeneralGuids,
        recStatus,
    });

export const updateRecAreaPolygons = (
    fieldGuid: string,
    areaIdToNewAreaIdPolygonMap: Map<recAreaIdType, Map<recAreaIdType, ZoneGeometryInfo>>
) => action(UPDATE_REC_AREA_POLYGONS, { fieldGuid, areaIdToNewAreaIdPolygonMap });

export const updateRecDetails = (fieldGuid: string, newProps: Partial<models.RecDetails>) =>
    action(UPDATE_REC_DETAILS, { fieldGuid, newProps });

export const updateRecSummaryImportedStatus = (
    fieldGuid: string,
    recGeneralGuid: string,
    importedStatus: number
) => action(UPDATE_REC_SUMMARY_IMPORTED_STATUS, { fieldGuid, recGeneralGuid, importedStatus });

export const updateRecSummaryStatus = (
    fieldGuid: string,
    recGeneralGuid: string,
    recStatus: string
) => action(UPDATE_REC_SUMMARY_STATUS, { fieldGuid, recGeneralGuid, recStatus });

export const updateSelectedFieldRecs = (
    fieldGuidToRecListMap: Immutable.OrderedMap<string, models.RecSummary[]>,
    fieldGuidToInactiveRecListMap: Immutable.OrderedMap<string, models.RecSummary[]>
) => action(UPDATE_SELECTED_FIELD_RECS, { fieldGuidToRecListMap, fieldGuidToInactiveRecListMap });
