import { createService } from "~/hocs/crud";
import * as model from "./model";

const getReportBookTypeGuid = (record) => {
    return {
        guid: record[model.PROPS_SETUP_REPORT_BOOKS_TABLE_DATA].reportBookTypeGuid,
    };
};

export const service = createService({
    activeColumnName: model.PROPS_ACTIVE_YN,
    _defaultLabels: {
        [model.PROPS_CAN_DELETE]: {
            visible: false,
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
        [model.PROPS_REPORT_BOOK_NAME]: {
            label: "reportBookName",
            gridCol: 40,
            sortNameOverRide: model.PROPS_REPORT_BOOK_NAME,
        },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 40,
            visible: false,
        },
        [model.PROPS_SETUP_REPORT_BOOKS_TABLE_DATA]: {
            label: "data",
            visible: false,
        },
    },
    getGuid: getReportBookTypeGuid,
    guid: "guid",
    id: "setupReportBooks",
    recordName: [model.PROPS_SETUP_REPORT_BOOKS_TABLE_DATA],
});

const getAddEditGuid = (record) => {
    return {
        guid: record[model.PROPS_ADD_EDIT_TABLE_DATA].sortOrder,
    };
};

export const addEditService = createService({
    _defaultLabels: {
        [model.PROPS_CAN_DELETE]: {
            visible: false,
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
        [model.PROPS_ADD_EDIT_TABLE_UP_BUTTON]: { label: "empty", gridCol: 5 },
        [model.PROPS_ADD_EDIT_TABLE_DOWN_BUTTON]: {
            label: "empty",
            gridCol: 5,
        },
        [model.PROPS_REPORT]: { label: "report", gridCol: 40 },
        [model.PROPS_CONFIGURATION]: { label: "configuration", gridCol: 40 },
        [model.PROPS_ADD_EDIT_TABLE_DATA]: { label: "data", visible: false },
    },
    getGuid: getAddEditGuid,
    guid: "guid",
    id: "setupReportBooksAddEditReports",
    modelName: "setupReportBookAddEditReportsData",
    recordName: [model.PROPS_ADD_EDIT_TABLE_DATA],
});

const getReportOptionsGuid = (record) => {
    return {
        guid: record[model.PROPS_SETUP_REPORT_BOOKS_TABLE_DATA].reportBookOptionsGuid,
    };
};

export const addReportOptionsService = createService({
    _defaultLabels: {
        [model.PROPS_CAN_DELETE]: {
            visible: false,
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
        [model.PROPS_DO_NOT_EDIT]: {
            visible: false,
            label: "doNotEdit",
            gridCol: 5,
            className: "col-shift-15",
        },
        [model.PROPS_REPORT]: { label: "report", gridCol: 25 },
        [model.PROPS_CONFIGURATION]: { label: "configuration", gridCol: 25 },
        [model.PROPS_CREATED_BY]: { label: "createdBy", gridCol: 15 },
        [model.PROPS_MODIFIED_BY]: { label: "lastModifiedBy", gridCol: 15 },
        [model.PROPS_MODIFIED_BY_DATE]: {
            label: "lastModifiedDate",
            gridCol: 15,
        },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 40,
            visible: false,
        },
        [model.PROPS_REPORT_OPTIONS_TABLE_DATA]: {
            label: "data",
            visible: false,
        },
    },
    getGuid: getReportOptionsGuid,
    guid: "guid",
    id: "setupReportBooksOptions",
    modelName: "setupReportBookOptionsData",
    recordName: [model.PROPS_REPORT_OPTIONS_TABLE_DATA],
});
