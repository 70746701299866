import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    TextInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
    FileSelector,
} from "~/core";
import TableCell from "~/core/components/tables/data-table/components/table-cell";
import "./component.css";
import { AppHelpers } from "@ai360/core";
import { messages } from "../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { withEditableGrid } from "~/hocs";

// constants
export const PROPS_PHOTO_GUID = "photoGuid";
export const PROPS_PHOTO_PARENT_GUID = "parentGuid";
export const PROPS_PHOTO_TITLE = "photoTitle";
export const PROPS_PHOTO_URL = "photoUrl";
export const PROPS_TITLE = "title";
export const PROPS_CITATION = "citation";
export const PROPS_URL = "url";
export const PROPS_BASE64STRING = "base64String";
export const PROPS_FULL_IMAGE_BASE64 = "fullImageBase64";
export const PROPS_FILENAME = "fileName";
export const PROPS_ID = "id";

const PhotoListItem = ({ value, onChange, formatMessage, useNewApi }) => {
    return (
        <div className="photo-list-item">
            <div className="item-container">
                <TextInput
                    maxLength={200}
                    tabIndex={0}
                    placeholderText={formatMessage(messages.photoTitle)}
                    labelText={formatMessage(messages.photoTitle)}
                    required
                    autoFocus
                    value={value[!useNewApi ? PROPS_PHOTO_TITLE : PROPS_TITLE]}
                    onChange={(value) =>
                        onChange({
                            [!useNewApi ? PROPS_PHOTO_TITLE : PROPS_TITLE]: value,
                        })
                    }
                />
                <TextInput
                    maxLength={4000}
                    tabIndex={0}
                    placeholderText={formatMessage(messages.citation)}
                    labelText={formatMessage(messages.citation)}
                    value={value[PROPS_CITATION]}
                    onChange={(value) => onChange({ [PROPS_CITATION]: value })}
                />
                <TextInput
                    maxLength={2000}
                    tabIndex={0}
                    placeholderText={formatMessage(messages.photoUrl)}
                    labelText={formatMessage(messages.photoUrl)}
                    value={value[!useNewApi ? PROPS_PHOTO_URL : PROPS_URL]}
                    onChange={(value) =>
                        onChange({
                            [!useNewApi ? PROPS_PHOTO_URL : PROPS_URL]: value,
                        })
                    }
                />
            </div>
            <div className="image-container">
                <img
                    alt=""
                    className="default-image"
                    src={`${
                        value[!useNewApi ? PROPS_BASE64STRING : PROPS_FULL_IMAGE_BASE64].indexOf(
                            "data:image"
                        ) >= 0
                            ? ""
                            : `data:image/${
                                  value[
                                      !useNewApi ? PROPS_BASE64STRING : PROPS_FULL_IMAGE_BASE64
                                  ].indexOf("iVBOR") >= 0
                                      ? "png"
                                      : "jpeg"
                              };base64,`
                    }${value[!useNewApi ? PROPS_BASE64STRING : PROPS_FULL_IMAGE_BASE64]}`}
                />
            </div>
        </div>
    );
};

PhotoListItem.propTypes = {
    value: PropTypes.object,
    index: PropTypes.number,
    onChange: PropTypes.func,
    formatMessage: PropTypes.func,
    useNewApi: PropTypes.bool,
};

class PhotoList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        useNewApi: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            isPreview: false,
            previewImageData: null,
            editingItem: false,
        };
        this.serviceObj = !props.useNewApi
            ? {
                  [PROPS_PHOTO_TITLE]: { title: "Photo Title" },
                  [PROPS_CITATION]: { title: "Citation" },
                  [PROPS_BASE64STRING]: {
                      title: "Image",
                      printer: this.printImage,
                  },
              }
            : {
                  [PROPS_TITLE]: { title: "Photo Title" },
                  [PROPS_CITATION]: { title: "Citation" },
                  [PROPS_FULL_IMAGE_BASE64]: { title: "Image", printer: this.printImage },
              };
    }
    onEdit = (record, index) => {
        const keysToUpdate = !this.props.useNewApi
            ? [
                  PROPS_PHOTO_TITLE,
                  PROPS_CITATION,
                  PROPS_PHOTO_URL,
                  PROPS_BASE64STRING,
                  PROPS_PHOTO_GUID,
                  PROPS_PHOTO_PARENT_GUID,
              ]
            : [
                  PROPS_TITLE,
                  PROPS_CITATION,
                  PROPS_URL,
                  PROPS_FULL_IMAGE_BASE64,
                  PROPS_FILENAME,
                  PROPS_ID,
              ];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };
    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = ({ files }) => {
        this.setState({ editingItem: false });
        const file = files[0];
        const { onAdd, useNewApi } = this.props;
        AppHelpers.fileToBase64(file).then((base64) => {
            if (base64) {
                onAdd({
                    [!useNewApi ? PROPS_BASE64STRING : PROPS_FULL_IMAGE_BASE64]: !useNewApi
                        ? base64
                        : base64.replace(`data:${file.type};base64,`, ""),
                    [!useNewApi ? PROPS_PHOTO_TITLE : PROPS_TITLE]: "",
                    citation: "",
                    [!useNewApi ? PROPS_PHOTO_URL : PROPS_URL]: "",
                    [PROPS_FILENAME]: file.name,
                });
            }
        });
    };

    previewImage = (event, previewImageData) => {
        event.preventDefault();
        event.stopPropagation();
        if (previewImageData) {
            this.setState({
                previewImageData:
                    !this.props.useNewApi || previewImageData.indexOf("data:image") >= 0
                        ? previewImageData
                        : `data:image/${
                              previewImageData.indexOf("iVBOR") >= 0 ? "png" : "jpeg"
                          };base64,${previewImageData}`,
                isPreview: true,
            });
        }
    };

    printImage = (value) => {
        return (
            <TableCell key="photolist-preview" title="Image preview">
                <NoLink label="Preview" onClick={(event) => this.previewImage(event, value)} />
            </TableCell>
        );
    };

    renderAddEditModal = () => {
        const {
            formatMessage,
            isEditing,
            editData,
            onAddOrEditItem,
            onChange,
            toggleModal,
            useNewApi,
        } = this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={!editData[!useNewApi ? PROPS_PHOTO_TITLE : PROPS_TITLE]}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.photo
                )}
            >
                <PhotoListItem
                    formatMessage={formatMessage}
                    value={formData}
                    onChange={onChange}
                    useNewApi={useNewApi}
                />
            </DialogBox>
        );
    };

    renderPreviewModal = () => {
        const { formatMessage } = this.props;
        const { isPreview, previewImageData } = this.state;
        return (
            <DialogBox
                isOpen={isPreview}
                closeOnClickOff
                closeOnEscape
                unrestricted
                onClose={() => this.setState({ isPreview: false })}
                title={formatMessage(messages.photoPreview)}
            >
                <img alt="" src={previewImageData} width="100%" />
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={this.serviceObj}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                {this.renderPreviewModal()}
                <div className="add-link-container">
                    <FileSelector
                        fileType="image/*"
                        allowMultiple={true}
                        label={createAddLinkLabelText(formatMessage, messages.photo)}
                        fileCompletedCallback={this.onAdd}
                        id={"photo-selector"}
                        className="add-link"
                    />
                </div>
            </div>
        );
    }
}

export default withEditableGrid(PhotoList);
