import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { defineMessages, InjectedIntl, injectIntl } from "react-intl";

import { Bucket, BucketHeader, Button, Checkbox, Pane, SelectInput, Tabs } from "~/core";

import {
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { ACTIVE_YN } from "~/core/picklist";

import { PICKLIST_CROP_PURPOSE, PICKLIST_PLANT_PART } from "~/core/picklist/picklist-names";

import { mapToolsActions } from "~/map";
import { Toolset, Tool } from "@ai360/core";
import {
    actions as recsEventsActions,
    models as recsEventsModel,
    eventsSelectors,
} from "~/recs-events";

import { SamplingTissueIcon } from "../../icons";

import * as actions from "../actions";
import { ICropGrowthStage, IPicklistOption, ISampleResults } from "../models";
import * as selectors from "../selectors";

import { SamplePointTable } from "./sample-point-table";
import { SampleResultsTable } from "~/action-panel/components/common/sampling/sample-results-table";

import "../../../../../../common/rec-event-info/rec-event-info.css";
import "./event-sample-tissue-form.css";
import { AgEventAPI } from "@ai360/core";

type ITissueSamplePoint = AgEventAPI.ITissueSamplePoint;

const messages = defineMessages({
    crop: {
        id: "eventModule.eventInfo.crop",
        defaultMessage: "Crop",
    },
    growthStage: {
        id: "eventModule.eventInfo.growthStage",
        defaultMessage: "Crop Growth Stage",
    },
    cropPurpose: {
        id: "eventModule.eventInfo.cropPurpose",
        defaultMessage: "Crop Purpose",
    },
    plantPart: {
        id: "eventModule.eventInfo.plantPart",
        defaultMessage: "Plant Part",
    },
    tissueDetails: {
        id: "eventModule.eventInfo.tissueDetails",
        defaultMessage: "Tissue Details",
    },
    samplePlacePointsTxt: {
        id: "eventModule.eventInfo.samplePlacePointsTxt",
        defaultMessage: "Place Points",
    },
    sampleTissueFormLabelText: {
        id: "eventModule.eventInfo.sampleTissueFormLabelText",
        defaultMessage: "Tissue",
    },
    sampleTissueProductivityRatingTxt: {
        id: "eventModule.eventInfo.sampleTissueProductivityRatingTxt",
        defaultMessage: "Productivity Rating",
    },
});

const { getPickListCode, PICKLIST_NUMERIC_RATING } = picklistNames;

const AUTO_METHOD_LABEL = "Auto";
const MANUAL_METHOD_LABEL = "Manual";

export const formLabelMessage = messages.sampleTissueFormLabelText;
export const formLabelIcon = SamplingTissueIcon;

const errorCodeToMessageIdSetMap = new Map([
    // TODO: the rest of the tissue errors (crop required, etc.)
    [105, []], // ErrorCode.SamplingSequenceIdRequired
    [145, []], // ErrorCode.SamplingEventIdAlreadyUsed
    [146, []], // ErrorCode.SamplingEventIdRequired
    [2104, []], // ErrorCode.SamplingStartGreaterThanEnd
    [2156, []], // ErrorCode.SamplingSamplePointRequired
]);

export const errorCodesApply = (errorCodeList: [number]): any => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

interface ISampleTissueFormProps {
    agEventModel: any;
    batchSize: number;
    cropGrowthStageGuid: string;
    cropGrowthStageList: ICropGrowthStage[];
    intl: InjectedIntl;
    fieldGuid: string;
    onAutoPlaceSamplingPoints: (cropGrowthStageGuid: string, plantPartGuid: string) => void;
    onEnableAddPointsTool: () => void;
    onFetchCropGrowthStageList: (fieldGuid: string) => void;
    onFetchPicklists: (picklists: any) => void;
    onSetCropGrowthStageGuid: (cropGrowthStageGuid: string) => void;
    onSetPlantPartGuid: (plantPartGuid: string) => void;
    onSetSamplingFieldPointsPlaced: (isPlaced: boolean) => void;
    onSetShowProductivityRating: (event: MouseEvent, showProductivityRating: boolean) => void;
    onSetSelectedPointSet: (selectedPointSet: Set<string>) => void;
    onUpdateBatchSamplingEvents: (isPointProps: boolean, newProps: any) => void;
    onUpdateSamplingAgEventModel: (newProps: any) => void;
    picklistOptionsProductivityRating: IPicklistOption[];
    picklistOptionsCropPurpose: IPicklistOption[];
    picklistOptionsPlantPart: IPicklistOption[];
    plantPartGuid: string;
    samplingFieldPointsPlaced: boolean;
    saveEventDetailsErrorCodeList: number[];
    selectedPointSet: Set<string>;
    showProductivityRating: boolean;
    tissueSampleResults: ISampleResults;
}

const SampleTissueForm_ = (props: ISampleTissueFormProps): JSX.Element => {
    const {
        onAutoPlaceSamplingPoints,
        cropGrowthStageGuid,
        cropGrowthStageList,
        intl,
        agEventModel,
        fieldGuid,
        tissueSampleResults,
        onEnableAddPointsTool,
        onFetchPicklists,
        onFetchCropGrowthStageList,
        onSetCropGrowthStageGuid,
        onSetPlantPartGuid,
        onSetSamplingFieldPointsPlaced,
        onSetShowProductivityRating,
        onSetSelectedPointSet,
        onUpdateBatchSamplingEvents,
        onUpdateSamplingAgEventModel,
        picklistOptionsCropPurpose,
        picklistOptionsPlantPart,
        picklistOptionsProductivityRating,
        plantPartGuid,
        samplingFieldPointsPlaced,
        selectedPointSet,
        showProductivityRating,
    } = props;

    const { formatMessage } = intl;

    //state variables

    const [cropId, setCropId] = useState<number>(null);

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const previousProps: any = usePrevious({ agEventModel });

    useEffect(() => {
        const fetchPicklistNames = [
            PICKLIST_NUMERIC_RATING,
            PICKLIST_CROP_PURPOSE,
            PICKLIST_PLANT_PART,
        ];
        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = getPickListCode(key);
            return accum;
        }, {});
        onFetchPicklists(picklistFetchArgObj);
        onFetchCropGrowthStageList(fieldGuid);

        if (
            (cropGrowthStageGuid == null || plantPartGuid == null) &&
            agEventModel.samplePoints.length > 0
        ) {
            const isDefaultCase =
                !agEventModel.samplePoints.some((p: ITissueSamplePoint) =>
                    Boolean(p.cropGrowthStageGuid || p.plantPartGuid)
                ) ||
                agEventModel.samplePoints.every(
                    (p: ITissueSamplePoint) =>
                        p.cropGrowthStageGuid ===
                            agEventModel.samplePoints[0].cropGrowthStageGuid &&
                        p.plantPartGuid === agEventModel.samplePoints[0].plantPartGuid
                );

            const targetCropGrowthStageGuid = agEventModel.samplePoints[0].cropGrowthStageGuid;
            const targetPlantPartGuid = agEventModel.samplePoints[0].plantPartGuid;

            if (
                isDefaultCase &&
                (targetCropGrowthStageGuid !== cropGrowthStageGuid ||
                    targetPlantPartGuid !== plantPartGuid)
            ) {
                onSetCropGrowthStageGuid(targetCropGrowthStageGuid);
                onSetPlantPartGuid(targetPlantPartGuid);
            }
        }
    }, []);

    useEffect(() => {
        if (cropGrowthStageList.length > 0 && agEventModel.cropGuid) {
            const cropRecord = cropGrowthStageList.find(
                (record) => record.cropGuid === agEventModel.cropGuid
            );
            if (cropRecord) {
                setCropId(cropRecord.cropId);
            }
        }
    }, [agEventModel == null ? null : agEventModel.cropGuid]);

    useEffect(() => {
        const previousAgEventModel = previousProps ? previousProps.agEventModel : {};
        const existingPointChange =
            previousAgEventModel?.samplePoints?.length === agEventModel.samplePoints.length &&
            agEventModel.samplePoints.length > 0;
        const pointAdded =
            agEventModel.samplePoints.length > previousAgEventModel?.samplePoints?.length &&
            agEventModel.samplePoints.length > 0;

        if (existingPointChange) {
            //Something changed with existing point(s)
            agEventModel.samplePoints.forEach((sp, index) => {
                const updatedPoint = previousAgEventModel?.samplePoints[index];
                if (sp.shape !== updatedPoint.shape) {
                    //handles point that was moved
                    const newCropGrowthStageGuid =
                        sp.cropGrowthStageGuid || updatedPoint.cropGrowthStageGuid;
                    const newPlantPartGuid = sp.plantPartGuid || updatedPoint.plantPartGuid;
                    const shape = sp.shape;
                    if (agEventModel?.samplePoints[0].plantPartGuid) {
                        _updateSamplePoint(index, {
                            cropGrowthStageGuid: newCropGrowthStageGuid,
                            plantPartGuid: newPlantPartGuid,
                            shape,
                        });
                    } else {
                        _updateSamplePoint(index, {
                            cropGrowthStageGuid: newCropGrowthStageGuid,
                            shape,
                        });
                    }
                }
            });
        } else if (pointAdded) {
            const highestSampleId = Math.max(
                ...previousAgEventModel.samplePoints.map((p: ITissueSamplePoint) => p.sampleId)
            );
            const newSamplePoint = agEventModel.samplePoints.filter(
                (p: ITissueSamplePoint) =>
                    previousAgEventModel.samplePoints.findIndex(
                        (p2: ITissueSamplePoint) =>
                            p2.eventSampleTissuePointGuid === p.eventSampleTissuePointGuid
                    ) === -1
            )[0];

            const highestSamplePointIdx = previousAgEventModel.samplePoints.findIndex(
                (p: ITissueSamplePoint) =>
                    p.sampleId === highestSampleId &&
                    p.cropGrowthStageGuid &&
                    p.eventSampleTissuePointGuid !== newSamplePoint.eventSampleTissuePointGuid
            );

            const highestSamplePoint = previousAgEventModel.samplePoints[highestSamplePointIdx];
            const newSamplePointIdx = agEventModel.samplePoints.findIndex(
                (p: ITissueSamplePoint) =>
                    p.eventSampleTissuePointGuid === newSamplePoint.eventSampleTissuePointGuid
            );

            if (
                agEventModel.samplePoints.length === 1 &&
                previousAgEventModel.samplePoints.length === 0
            ) {
                // Added the first point
                if (cropGrowthStageGuid || plantPartGuid) {
                    _updateSamplePoint(newSamplePointIdx, { cropGrowthStageGuid, plantPartGuid });
                }
            } else if (highestSamplePointIdx !== -1 && !newSamplePoint.cropGrowthStageGuid) {
                const newSamplePoint = agEventModel.samplePoints[newSamplePointIdx];
                const samplePointsWithSameId = agEventModel.samplePoints.filter(
                    (p: ITissueSamplePoint) => p.sampleId === newSamplePoint.sampleId
                );
                const isCompositePoint = samplePointsWithSameId.length > 1;

                if (isCompositePoint) {
                    const compositeMinimumSequenceId = Math.min(
                        ...agEventModel.samplePoints
                            .filter(
                                (p: ITissueSamplePoint) => p.sampleId === newSamplePoint.sampleId
                            )
                            .map((p: ITissueSamplePoint) => p.sequenceId)
                    );

                    const compositeParentPoint = agEventModel.samplePoints.find(
                        (p: ITissueSamplePoint) =>
                            p.sampleId === newSamplePoint.sampleId &&
                            p.sequenceId === compositeMinimumSequenceId
                    );

                    _updateSamplePoint(newSamplePointIdx, {
                        cropGrowthStageGuid: compositeParentPoint.cropGrowthStageGuid,
                        plantPartGuid: compositeParentPoint.plantPartGuid,
                    });
                } else {
                    if (agEventModel.samplePoints[0].plantPartGuid) {
                        _updateSamplePoint(newSamplePointIdx, {
                            cropGrowthStageGuid: highestSamplePoint.cropGrowthStageGuid,
                            plantPartGuid: highestSamplePoint.plantPartGuid,
                        });
                    } else {
                        _updateSamplePoint(newSamplePointIdx, {
                            cropGrowthStageGuid: highestSamplePoint.cropGrowthStageGuid,
                        });
                    }
                }
            }
        }
    }, [agEventModel.samplePoints]);

    const _onUpdate = (key: string, val: any): void => {
        const resetProps: any = {};
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        if (key === "cropId") {
            if (val === undefined) {
                setCropId(val);
            }
            if (!isBatchTemplate) {
                onSetCropGrowthStageGuid(null);
                _onUpdateSampling({
                    cropGrowthStageGuid: null,
                });
                onUpdateBatchSamplingEvents(false, {
                    cropGrowthStageGuid: null,
                });
                resetProps.cropGrowthStageGuid = null;
            }
            resetProps.cropPurposeGuid = null;

            const cropRecord = cropGrowthStageList.find((record) => {
                return record.cropId === val;
            });
            const revisedSamplePoints = _getCropClearedSamplePoints();
            if (!isBatchTemplate) {
                resetProps.samplePoints = revisedSamplePoints;
            }
            _onUpdateSampling({
                cropGuid: cropRecord ? cropRecord.cropGuid : null,
                cropGrowthStageGuid: null,
                plantPartGuid: null,
                ...resetProps,
            });
            if (isBatchTemplate) {
                onUpdateBatchSamplingEvents(true, {
                    cropGrowthStageGuid: null,
                });
            }
            onSetPlantPartGuid(null);
        } else if (key === "cropPurposeGuid") {
            const revisedSamplePoints = _getCropClearedSamplePoints();
            if (!isBatchTemplate) {
                resetProps.samplePoints = revisedSamplePoints;
            } else {
                onSetCropGrowthStageGuid(null);
                onUpdateBatchSamplingEvents(true, {
                    cropGrowthStageGuid: null,
                });
            }
            _onUpdateSampling({
                cropPurposeGuid: val,
                cropGrowthStageGuid: null,
                ...resetProps,
            });
            onSetCropGrowthStageGuid(null);
        } else {
            _onUpdateSampling({ [key]: val, ...resetProps });
        }
    };

    const _onUpdateSampling = (newProps) => {
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        if (isBatchTemplate) {
            onUpdateBatchSamplingEvents(false, newProps);
        }
        onUpdateSamplingAgEventModel(newProps);
    };

    const _getCropClearedSamplePoints = () => {
        return agEventModel.samplePoints.map((p: ITissueSamplePoint) => {
            return {
                ...p,
                cropGrowthStageGuid: null,
            };
        });
    };

    const _getDistinctList = (listObjects: IPicklistOption[]): IPicklistOption[] => {
        const returnList = [];
        listObjects.forEach((record) => {
            const existingRecord = returnList.find((retObj) => retObj.value === record.value);
            if (!existingRecord) {
                returnList.push(record);
            }
        });
        return returnList;
    };
    const _getMethodSelectionPart = (): JSX.Element => {
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        if (agEventModel == null || !isBatchTemplate) {
            return null;
        }
        const onTabSelect = () => {
            // Add logic when batch Manual becomes available
        };
        return (
            <Tabs className="method-selection" onTabSelect={onTabSelect} selected={0}>
                {!isBatchTemplate ? null : (
                    <Pane label={AUTO_METHOD_LABEL}>
                        <div className="manual-method-steps">
                            <Button
                                disabled={samplingFieldPointsPlaced}
                                value={formatMessage(messages.samplePlacePointsTxt)}
                                onClick={() => {
                                    onSetSamplingFieldPointsPlaced(true);
                                    onAutoPlaceSamplingPoints(cropGrowthStageGuid, plantPartGuid);
                                }}
                            />
                        </div>
                    </Pane>
                )}
                <Pane label={MANUAL_METHOD_LABEL} disabled={true}>
                    <div className="manual-method-steps"></div>
                </Pane>
            </Tabs>
        );
    };

    const _getTissueDetailPart = (): JSX.Element => {
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        const isCropDetermined = Boolean(agEventModel.cropGuid);
        const isTargetValueDriven: boolean =
            Boolean(cropGrowthStageList.find((record) => record.isTargetValue)) ||
            cropGrowthStageList.length === 0;

        const cropPlantPartRequired = false;

        const cropListOptions = _getDistinctList(
            cropGrowthStageList.map((record) => ({
                label: record.cropName,
                value: record.cropId.toString(),
            }))
        );

        useEffect(() => {
            if (cropId && isBatchTemplate) {
                const cropRecord = cropGrowthStageList.find((record) => {
                    return record.cropId === cropId;
                });

                _onUpdateSampling({
                    cropGuid: cropRecord ? cropRecord.cropGuid : null,
                    cropGrowthStageGuid: agEventModel?.cropGrowthStageGuid,
                    cropPurposeGuid: agEventModel?.cropPurposeGuid,
                    plantPartGuid: agEventModel?.plantPartGuid,
                });
                onUpdateBatchSamplingEvents(true, {
                    cropGrowthStageGuid: agEventModel?.cropGrowthStageGuid,
                    cropPurposeGuid: agEventModel?.cropPurposeGuid,
                    plantPartGuid: agEventModel?.plantPartGuid,
                });
                onSetPlantPartGuid(agEventModel?.plantPartGuid);
                onSetCropGrowthStageGuid(agEventModel?.cropGrowthStageGuid);
            }
        }, [cropId, cropGrowthStageGuid]);

        const cropPurposeListOptions =
            !isCropDetermined && isTargetValueDriven
                ? []
                : !isTargetValueDriven
                ? picklistOptionsCropPurpose
                : _getDistinctList(
                      cropGrowthStageList
                          .filter(
                              (record) =>
                                  record.cropGuid === agEventModel.cropGuid &&
                                  Boolean(record.cropPurposeGuid)
                          )
                          .map((record) => ({
                              label: picklistOptionsCropPurpose.find(
                                  (opt) => opt.value === record.cropPurposeGuid
                              )?.label,
                              value: record.cropPurposeGuid,
                          }))
                  );

        const growthStageOrderListOptions = !isCropDetermined
            ? []
            : _getDistinctList(
                  cropGrowthStageList
                      .filter(
                          (record) =>
                              record.cropGuid === agEventModel.cropGuid &&
                              ((!record.cropPurposeGuid && !agEventModel.cropPurposeGuid) ||
                                  record.cropPurposeGuid === agEventModel.cropPurposeGuid)
                      )
                      .map((record) => ({
                          label: record.growthStageOrderName,
                          value: record.growthStageOrderGuid,
                      }))
              );

        const plantPartListOptions =
            !isCropDetermined && isTargetValueDriven ? [] : picklistOptionsPlantPart;

        const currentSelectedPointIdx =
            selectedPointSet.size === 1
                ? agEventModel.samplePoints.findIndex((p: ITissueSamplePoint) =>
                      selectedPointSet.has(p.eventSampleTissuePointGuid)
                  )
                : -1;
        const currentSelectedPoint = agEventModel.samplePoints[currentSelectedPointIdx];

        // In the default case, we'll behave more or less like it did before.  Everything will be updated in unison until the user
        //  explicitly makes one of the points different.
        const isDefaultCase = _isDefaultCase();
        const combinedGrowthStageSet = new Set();
        const combinedPlantPartSet = new Set();

        const combinedGrowthStageName = agEventModel.samplePoints.reduce(
            (acc: string, p: ITissueSamplePoint) => {
                if (
                    selectedPointSet.size > 0 &&
                    !selectedPointSet.has(p.eventSampleTissuePointGuid)
                ) {
                    return acc;
                }
                const label = growthStageOrderListOptions.find(
                    (g) => g.value === p.cropGrowthStageGuid
                )?.label;
                if (combinedGrowthStageSet.has(p.cropGrowthStageGuid) || label == null) {
                    return acc;
                }
                combinedGrowthStageSet.add(p.cropGrowthStageGuid);
                return acc + (acc ? ", " : "") + label;
            },
            ""
        );

        const combinedPlantPartName = agEventModel.samplePoints.reduce(
            (acc: string, p: ITissueSamplePoint) => {
                if (
                    selectedPointSet.size > 0 &&
                    !selectedPointSet.has(p.eventSampleTissuePointGuid)
                ) {
                    return acc;
                }
                const label = plantPartListOptions.find((g) => g.value === p.plantPartGuid)?.label;
                if (combinedPlantPartSet.has(p.plantPartGuid) || label == null) {
                    return acc;
                }
                combinedPlantPartSet.add(p.plantPartGuid);
                return acc + (acc ? ", " : "") + label;
            },
            ""
        );

        const onCropGrowthChange = (newCropGrowthStageGuid) => {
            if (currentSelectedPoint) {
                if (!newCropGrowthStageGuid || isDefaultCase) {
                    onSetCropGrowthStageGuid(newCropGrowthStageGuid);
                }
                _updateSamplePoint(currentSelectedPointIdx, {
                    cropGrowthStageGuid: newCropGrowthStageGuid,
                });
            } else if (isBatchTemplate) {
                onSetCropGrowthStageGuid(newCropGrowthStageGuid);
                onUpdateBatchSamplingEvents(true, {
                    cropGrowthStageGuid: newCropGrowthStageGuid,
                });
                _onUpdateSampling({
                    cropGrowthStageGuid: newCropGrowthStageGuid,
                });
            } else if (isDefaultCase && agEventModel.samplePoints.length > 0) {
                onSetCropGrowthStageGuid(newCropGrowthStageGuid);
                _updateSamplePoint(
                    0,
                    { cropGrowthStageGuid: newCropGrowthStageGuid },
                    isDefaultCase
                );
            } else {
                onSetCropGrowthStageGuid(newCropGrowthStageGuid);
            }
        };

        const onPlantPartChange = (newPlantPartGuid) => {
            if (currentSelectedPoint) {
                if (!newPlantPartGuid || isDefaultCase) {
                    onSetPlantPartGuid(newPlantPartGuid);
                }
                _updateSamplePoint(currentSelectedPointIdx, {
                    plantPartGuid: newPlantPartGuid,
                });
            } else if (isBatchTemplate) {
                onSetPlantPartGuid(newPlantPartGuid);
                onUpdateBatchSamplingEvents(true, {
                    plantPartGuid: newPlantPartGuid,
                });
                _onUpdateSampling({
                    plantPartGuid: newPlantPartGuid,
                });
            } else if (isDefaultCase && agEventModel.samplePoints.length > 0) {
                onSetPlantPartGuid(newPlantPartGuid);
                _updateSamplePoint(0, { plantPartGuid: newPlantPartGuid }, isDefaultCase);
            } else {
                onSetPlantPartGuid(newPlantPartGuid);
            }
        };
        let cropGrowthStageGuidForDropdown = null;
        let plantPartGuidForDropdown = null;

        // if this is a batch template screen (umbrella step) or a single event with no points yet,
        // get the growth stage and plant part from the agEventModel
        if (isBatchTemplate || agEventModel?.samplePoints?.length == 0) {
            cropGrowthStageGuidForDropdown = agEventModel?.cropGrowthStageGuid;
            plantPartGuidForDropdown = agEventModel?.plantPartGuid;
        } else {
            cropGrowthStageGuidForDropdown = currentSelectedPoint
                ? currentSelectedPoint?.cropGrowthStageGuid
                : agEventModel?.samplePoints[0]?.cropGrowthStageGuid;
            plantPartGuidForDropdown = currentSelectedPoint
                ? currentSelectedPoint?.plantPartGuid
                : agEventModel?.samplePoints[0]?.plantPartGuid;
        }
        const isEdit = agEventModel?.samplePoints?.filter((x) => x.isNew === false).length > 0;

        return (
            <Bucket
                className="import-field-section-bucket"
                showSymbol={false}
                isCollapsible={false}
                isExpanded={true}
            >
                <BucketHeader className="import-field-section-header create-events-section-header">
                    <div className="create-events-header">
                        {formatMessage(messages.tissueDetails)}
                    </div>
                </BucketHeader>
                <div className="input-row">
                    <SelectInput
                        clearable={(isBatchTemplate || props.batchSize === 0) && !isEdit}
                        optionIsHiddenKey={ACTIVE_YN}
                        placeholderText={formatMessage(messages.crop)}
                        value={cropId}
                        options={cropListOptions}
                        required={true}
                        onChange={_onUpdate.bind(this, "cropId")}
                    />
                    {cropPurposeListOptions.length === 0 ? null : (
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.cropPurpose)}
                            value={agEventModel?.cropPurposeGuid}
                            options={cropPurposeListOptions}
                            required={false}
                            onChange={_onUpdate.bind(this, "cropPurposeGuid")}
                        />
                    )}
                </div>
                {!currentSelectedPoint && !isDefaultCase ? (
                    <div className="readonly-row">
                        <div className="readonly-label">
                            {formatMessage(messages.growthStage)}:{" "}
                        </div>
                        <div className="readonly-value">{combinedGrowthStageName}</div>

                        <div className="readonly-label">{formatMessage(messages.plantPart)}: </div>
                        <div className="readonly-value">{combinedPlantPartName}</div>
                    </div>
                ) : (
                    <div className="input-row">
                        {growthStageOrderListOptions.length === 0 && isTargetValueDriven ? null : (
                            <SelectInput
                                clearable={(isBatchTemplate || props.batchSize === 0) && !isEdit}
                                optionIsHiddenKey={ACTIVE_YN}
                                placeholderText={formatMessage(messages.growthStage)}
                                value={
                                    currentSelectedPoint?.cropGrowthStageGuid ||
                                    cropGrowthStageGuidForDropdown
                                }
                                options={growthStageOrderListOptions}
                                required={true}
                                onChange={(newCropGrowthStageGuid: string) => {
                                    _onUpdateSampling({
                                        cropGrowthStageGuid: newCropGrowthStageGuid,
                                    });
                                    onCropGrowthChange(newCropGrowthStageGuid);
                                }}
                            />
                        )}
                        {plantPartListOptions.length === 0 ? null : (
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                placeholderText={formatMessage(messages.plantPart)}
                                value={
                                    currentSelectedPoint?.plantPartGuid || plantPartGuidForDropdown
                                }
                                options={plantPartListOptions}
                                required={cropPlantPartRequired}
                                onChange={(newPlantPartGuid: string) => {
                                    _onUpdateSampling({
                                        plantPartGuid: newPlantPartGuid,
                                    });
                                    onPlantPartChange(newPlantPartGuid);
                                }}
                            />
                        )}
                    </div>
                )}
            </Bucket>
        );
    };

    const _getSamplePointsPart = (): JSX.Element => {
        const isBatchTemplate = fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID;
        const viewResultsMode = tissueSampleResults != null;
        const currentSelectedPointIdx =
            selectedPointSet.size === 1
                ? agEventModel.samplePoints.findIndex((p: ITissueSamplePoint) =>
                      selectedPointSet.has(p.eventSampleTissuePointGuid)
                  )
                : -1;
        const currentSelectedPoint = agEventModel.samplePoints[currentSelectedPointIdx];
        const isDefaultCase = _isDefaultCase();

        const sampleClassNameResults = agEventModel.cropGuid
            ? !currentSelectedPoint && !isDefaultCase
                ? "sample-points alt-results"
                : "sample-points crop-selected-results"
            : "sample-points no-crop-selected-results";
        const sampleClassNameNoResults = agEventModel.cropGuid
            ? !currentSelectedPoint && !isDefaultCase
                ? "sample-points alt-no-results"
                : "sample-points crop-selected-no-results"
            : "sample-points no-crop-selected-no-results";

        if (viewResultsMode) {
            return (
                <div className={sampleClassNameResults}>
                    <div className="table-controls">
                        <Checkbox
                            label={formatMessage(messages.sampleTissueProductivityRatingTxt)}
                            value={showProductivityRating}
                            onChange={onSetShowProductivityRating}
                        />
                    </div>
                    <SampleResultsTable
                        intl={intl}
                        selectedPointSet={selectedPointSet}
                        onSetSelectedPointSet={onSetSelectedPointSet}
                        onSetPointProdRating={(idx: number, val: string) =>
                            _updateSamplePoint(idx, {
                                productivityRatingGuid: val,
                            })
                        }
                        pointGuidAttributeName={"eventSampleTissuePointGuid"}
                        productivityRatingOptions={picklistOptionsProductivityRating}
                        samplePoints={agEventModel == null ? [] : agEventModel.samplePoints}
                        sampleResults={tissueSampleResults}
                        showProductivityRating={showProductivityRating}
                    />
                </div>
            );
        }
        if (isBatchTemplate) {
            return null;
        }

        return (
            <div className={sampleClassNameNoResults}>
                <div className="table-controls">
                    <Checkbox
                        label={formatMessage(messages.sampleTissueProductivityRatingTxt)}
                        value={showProductivityRating}
                        onChange={onSetShowProductivityRating}
                    />

                    <Button
                        className="btn-place-points"
                        value={formatMessage(messages.samplePlacePointsTxt)}
                        onClick={onEnableAddPointsTool}
                    />
                </div>
                <SamplePointTable
                    selectedPointSet={selectedPointSet}
                    onSetSelectedPointSet={onSetSelectedPointSet}
                    onSetPointProdRating={(idx: number, val: string) =>
                        _updateSamplePoint(idx, {
                            productivityRatingGuid: val,
                        })
                    }
                    productivityRatingOptions={picklistOptionsProductivityRating}
                    samplePoints={agEventModel == null ? [] : agEventModel.samplePoints}
                    showProductivityRating={showProductivityRating}
                />
            </div>
        );
    };

    const _isDefaultCase = (): boolean => {
        return (
            agEventModel.samplePoints.length === 0 ||
            !agEventModel.samplePoints.some((p: ITissueSamplePoint) =>
                Boolean(p.cropGrowthStageGuid || p.plantPartGuid)
            ) ||
            agEventModel.samplePoints.every(
                (p: ITissueSamplePoint) =>
                    p.cropGrowthStageGuid === agEventModel.samplePoints[0].cropGrowthStageGuid &&
                    p.plantPartGuid === agEventModel.samplePoints[0].plantPartGuid
            )
        );
    };

    const _updateSamplePoint = (
        samplePointIdx: number,
        newProps: Partial<ITissueSamplePoint>,
        applyToAllPoints = false
    ): void => {
        const samplePoints = [...agEventModel.samplePoints];
        const currentSamplePoint = agEventModel.samplePoints[samplePointIdx];
        const samplePointsWithSameId = agEventModel.samplePoints.filter(
            (p: ITissueSamplePoint) => p.sampleId === currentSamplePoint.sampleId
        );

        const cropGrowthStageGuid =
            newProps.cropGrowthStageGuid !== undefined
                ? newProps.cropGrowthStageGuid
                : samplePointsWithSameId[0].cropGrowthStageGuid;
        const plantPartGuid =
            newProps.plantPartGuid !== undefined
                ? newProps.plantPartGuid
                : samplePointsWithSameId[0].plantPartGuid;

        onUpdateSamplingAgEventModel({
            samplePoints: samplePoints.map((p) => ({
                ...p,
                ...(p.sampleId !== currentSamplePoint.sampleId && !applyToAllPoints
                    ? {
                          cropGrowthStageGuid: p.cropGrowthStageGuid || cropGrowthStageGuid,
                          plantPartGuid: p.plantPartGuid || plantPartGuid,
                      }
                    : {
                          cropGrowthStageGuid,
                          plantPartGuid,
                          ...(p.sequenceId !== currentSamplePoint.sequenceId ? {} : newProps),
                      }),
            })),
        });
    };

    return (
        <div className="event-sample-tissue-form">
            {_getTissueDetailPart()}
            {_getMethodSelectionPart()}
            {_getSamplePointsPart()}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onAutoPlaceSamplingPoints: (cropGrowthStageGuid, plantPartGuid) =>
        dispatch(
            mapToolsActions.setActiveToolset(Toolset.SAMPLING_AUTO_PLACE, {
                attributes: { cropGrowthStageGuid, plantPartGuid },
                placePoints: "A",
                isAuto: true,
                isTissue: true,
                fields: [{ fieldGuid: recsEventsModel.BATCH_TEMPLATE_FIELD_GUID }],
            })
        ),
    onEnableAddPointsTool: () => dispatch(mapToolsActions.setActiveMapTool(Tool.SamplingAddSelect)),
    onFetchCropGrowthStageList: (fieldGuid: string) =>
        dispatch(actions.fetchCropGrowthStageList(fieldGuid)),
    onFetchPicklists: (pickLists: any) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onSetCropGrowthStageGuid: (cropGrowthStageGuid: string) =>
        dispatch(actions.setCropGrowthStageGuid(cropGrowthStageGuid)),
    onSetPlantPartGuid: (plantPartGuid: string) =>
        dispatch(actions.setPlantPartGuid(plantPartGuid)),
    onSetSamplingFieldPointsPlaced: (isPlaced) =>
        dispatch(recsEventsActions.setSamplingFieldPointsPlaced(isPlaced)),
    onSetShowProductivityRating: (evt: any, showProductivityRating: boolean) =>
        dispatch(actions.setShowProductivityRating(showProductivityRating)),
    onSetSelectedPointSet: (selectedPointSet: Set<number>) =>
        dispatch(recsEventsActions.setSelectedSamplePoints(selectedPointSet)),
    onUpdateBatchSamplingEvents: (isPointProps: boolean, newProps: any) =>
        dispatch(recsEventsActions.updateBatchSamplingEvents(isPointProps, newProps)),
    onUpdateSamplingAgEventModel: (fieldGuid: string, newProps: any) =>
        dispatch(recsEventsActions.updateSamplingAgEventModel(fieldGuid, newProps)),
});

const mapStateToProps = (state) => {
    const { fieldGuidToEventDetails } = eventsSelectors.getModuleState(state);
    const batchSize = Math.max(fieldGuidToEventDetails.size - 1, 0);
    const sampleModuleState = selectors.getModuleState(state);
    const {
        samplingFieldPointsPlaced,
        saveEventDetailsErrorCodeList,
        selectedSamplePointGuidIdSet,
        tissueSampleResults,
    } = eventsSelectors.getModuleState(state);

    return {
        batchSize: batchSize,
        cropGrowthStageGuid: sampleModuleState.cropGrowthStageGuid,
        cropGrowthStageList: selectors
            .getCropGrowthStageList(state)
            .sort((a: ICropGrowthStage, b: ICropGrowthStage) =>
                a.cropName < b.cropName ? -1 : a.cropName > b.cropName ? 1 : 0
            ),
        picklistOptionsProductivityRating: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_NUMERIC_RATING)
        ),
        picklistOptionsCropPurpose: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_CROP_PURPOSE)
        ),
        picklistOptionsPlantPart: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_PLANT_PART)
        ),
        plantPartGuid: sampleModuleState.plantPartGuid,
        samplingFieldPointsPlaced,
        saveEventDetailsErrorCodeList,
        selectedPointSet: selectedSamplePointGuidIdSet,
        showProductivityRating: sampleModuleState.showProductivityRating,
        tissueSampleResults,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSetCropGrowthStageGuid: (cropGrowthStageGuid: string) =>
        dispatchProps.onSetCropGrowthStageGuid(cropGrowthStageGuid),
    onUpdateBatchSamplingEvents: (isPointProps: boolean, newProps: any) =>
        dispatchProps.onUpdateBatchSamplingEvents(isPointProps, newProps),
    onUpdateSamplingAgEventModel: (newProps: any) =>
        dispatchProps.onUpdateSamplingAgEventModel(ownProps.fieldGuid, newProps),
    onFetchCropGrowthStageList: (fieldGuid) => dispatchProps.onFetchCropGrowthStageList(fieldGuid),
});

export const SampleTissueForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SampleTissueForm_));
