import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const WarningIcon = withSvgIcon(
    <g>
        <path
            d={
                "M256,16C123.452,16,16,123.452,16,256s107.452,240,240,240c132.549,0,240-107.452,240-240S388.549,16,256,16z M256,444.583" +
                "c-23.195,0-41.998-18.803-41.998-41.998s18.803-41.998,41.998-41.998c23.195,0,41.998,18.803,41.998,41.998" +
                "S279.196,444.583,256,444.583z M297.747,300.588c0,16.568-13.432,30-30,30h-23.494c-16.568,0-30-13.432-30-30l-25-209.986" +
                "c0-16.568,13.432-30,30-30h73.494c16.568,0,30,13.432,30,30L297.747,300.588z"
            }
        />
    </g>,
    "0 0 512 512"
);
