import PropTypes from "prop-types";

import { model } from "~/admin/setup/person/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_ADD_EMAIL_VISIBLE]: PropTypes.bool,
    [model.PROPS_ADD_USER_ACCOUNT_VISIBLE]: PropTypes.bool,
    [model.PROPS_ADDRESS_LIST]: PropTypes.oneOf(["array", "object"]),
    [model.PROPS_DATE_OF_BIRTH]: PropTypes.oneOf(["string", "object"]),
    [model.PROPS_DELETE_USER]: PropTypes.bool,
    [model.PROPS_EMPLOYEE_GUID]: PropTypes.string,
    [model.PROPS_END_DATE]: PropTypes.string,
    [model.PROPS_PERSON_FIRST_NAME]: PropTypes.string,
    [model.PROPS_PERSON_FULL_NAME]: PropTypes.oneOf(["string", "object"]),
    [model.PROPS_GENDER]: PropTypes.oneOf(["string", "object"]),
    [model.PROPS_IS_USER]: PropTypes.bool,
    [model.PROPS_PERSON_JOB_GUID]: PropTypes.string,
    [model.PROPS_PERSON_LAST_NAME]: PropTypes.string,
    [model.PROPS_LOGIN_EMAIL]: PropTypes.string,
    [model.PROPS_LOGIN_GUID]: PropTypes.string,
    [model.PROPS_MIDDLE_NAME]: PropTypes.string,
    [model.PROPS_NOTES]: PropTypes.string,
    [model.PROPS_PAYRATE]: PropTypes.oneOf(["number", "string"]),
    [model.PROPS_PAY_RATE_UNIT_GUID]: PropTypes.string,
    [model.PROPS_PAY_TYPE_GUID]: PropTypes.string,
    [model.PROPS_PERSON_ADDRESS_LIST]: PropTypes.array,
    [model.PROPS_PERSON_ALIAS_GUID]: PropTypes.PropTypes.string,
    [model.PROPS_EMAIL_LIST]: PropTypes.array,
    [model.PROPS_PERSON_GUID]: PropTypes.string,
    [model.PROPS_LICENSE_LIST]: PropTypes.array,
    [model.PROPS_PERSON_ORG_LIST]: PropTypes.array,
    [model.PROPS_PHONE_NUMBER_LIST]: PropTypes.array,
    [model.PROPS_PERSON_URL_LIST]: PropTypes.array,
    [model.PROPS_RENDER_USER_PASSWORD]: PropTypes.bool,
    [model.PROPS_USER_ACCOUNT_PASSWORD]: PropTypes.bool,
    [model.PROPS_START_DATE]: PropTypes.string,
    [model.PROPS_SUFFIX]: PropTypes.oneOf(["string", "object"]),
    [model.PROPS_SUPERVISOR_GUID]: PropTypes.string,
    [model.PROPS_SUPERVISOR_NAME]: PropTypes.string,
    [model.PROPS_TIME_ZONE_GUID]: PropTypes.string,
    [model.PROPS_TITLE_GUID]: PropTypes.oneOf(["string", "object"]),
    [model.PROPS_USER_ACTIVEYN]: PropTypes.bool,
    [model.PROPS_USER_CUSTOMER_LIST]: PropTypes.array,
    [model.PROPS_USER_GUID]: PropTypes.string,
    [model.PROPS_ROLE_GUID]: PropTypes.string,
    [model.PROPS_USER_ROLES]: PropTypes.oneOf(["string", "object"]),
    [model.PROPS_PERSON_SALES_PERSON_LIST]: PropTypes.array,
    [model.PROPS_USER_TYPE_GUID]: PropTypes.string,
};

export default PropTypes.shape(shape);
