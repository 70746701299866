import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import * as actions from "../actions";
import { getSecurityInfo } from "../selectors";
import { messages } from "../i18n-messages";

import { Button, SelectInput, TextInput } from "../../core";
import { AuthenticationAPI, ISelectOption } from "@ai360/core";
import { ISecurityInfo } from "../interfaces";
const { LoginState } = actions;

export const MIN_SECURITY_ANSWER_LENGTH = 2;

export interface ISelectSecurityQuestion_Props {
    onError?: (error: Error) => void;
    securityInfo?: ISecurityInfo;
    onSetLoginState: (action: number) => void;
    onStartProcessing: () => void;
    onStopProcessing: () => void;
    intl: intlShape;
}

export interface ISelectSecurityQuestion_State {
    answer: string;
    question: string;
}
class SelectSecurityQuestion_ extends Component<
    ISelectSecurityQuestion_Props,
    ISelectSecurityQuestion_State
> {
    questions: ISelectOption<any>[];
    txtAnswer: Record<string, any>;

    static defaultProps = {
        onError: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            question: "",
            answer: "",
        };
    }

    componentDidMount() {
        this.props.onStartProcessing();
        const { formatMessage } = this.props.intl;

        AuthenticationAPI.getPossibleSecurityQuestions()
            .then((response) => {
                this.questions = response.map((q) => {
                    return {
                        value: q,
                        label: q,
                    };
                });
                this.setState({ question: "" });
                this.props.onStopProcessing();
            })
            .catch(() => {
                this._handleError(formatMessage(messages.unableToRetrieve));
            });
    }

    public submit() {
        const { formatMessage } = this.props.intl;
        const { question, answer } = this.state;
        const minLength = MIN_SECURITY_ANSWER_LENGTH;
        this.props.onStartProcessing();
        if (question && answer.length >= minLength) {
            AuthenticationAPI.setSecurityQuestion(this.props.securityInfo.email, question, answer)
                .then(() => {
                    this.props.onStopProcessing();
                    this.props.onSetLoginState(LoginState.RESET_PASSWORD);
                })
                .catch((error) => this._handleError(error));
        } else if (!question) {
            this._handleError(formatMessage(messages.noneSelected));
        } else {
            console.assert(answer.length < minLength);
            this._handleError(formatMessage(messages.minLength, { count: minLength }));
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <div className="login-input-container">
                    <div className="display-label">
                        {formatMessage(messages.selectSecurityQuestion)}
                    </div>
                    <SelectInput
                        tabIndex={1}
                        options={this.questions}
                        placeholderText="Your Question"
                        onChange={(value: string) => this.setState({ question: value })}
                    />
                    <TextInput
                        tabIndex={2}
                        value={this.state.answer}
                        placeholderText="Your Answer"
                        onKeyDown={(e) => this._handleKeyDown(e)}
                        onChange={(answer) => this.setState({ answer })}
                        ref={(input) => {
                            this.txtAnswer = input;
                        }}
                    />
                </div>
                <div className="login-center">
                    <Button
                        tabIndex={3}
                        className="login-btn"
                        type="submitTextOnly"
                        onClick={() => this.submit()}
                    />
                </div>
            </div>
        );
    }

    _handleError(error) {
        this.props.onError(error);
        this.props.onStopProcessing();
    }

    _handleKeyDown(event) {
        if (event.key === "Enter") {
            this.submit();
        }
    }
}
export const SelectSecurityQuestion = injectIntl(SelectSecurityQuestion_);

const mapStateToProps = (state) => {
    return {
        securityInfo: getSecurityInfo(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetLoginState: (loginState) => dispatch(actions.setLoginState(loginState)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSecurityQuestion);
