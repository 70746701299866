import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { config as intlConfig } from "~/intl-provider/config";
import { Bucket, BucketHeader, Menu } from "~/core";
import { ValidationErrorModal } from "./validation-error-modal";
import { AppendSelectModal } from "./append-select-modal";
import { intlShape } from "react-intl";
import { messages } from "../../../i18n-messages";
import { messages as statusMessages } from "~/action-panel/components/common/status-messages";
import { getClassNameFromStatusCode } from "~/action-panel/components/common/status-codes";
import {
    ErrorDetailsDialog,
    ErrorDetailsContextMenuLabel,
} from "~/action-panel/components/common/error-details-dialog/error-details-dialog";
import { StatusCodes } from "~/action-panel/components/common/status-messages";
import { ImportTypes } from "../../../actions";
import { AddEditNameMatchModal } from "../containers/add-edit-name-match-modal";
import { keywords } from "~/action-panel/components/import-module/keywords.js";

export class EventsPanel_ extends Component {
    static propTypes = {
        apiErrorResultList: PropTypes.array.isRequired,
        appendList: PropTypes.array.isRequired,
        canSubmit: PropTypes.bool.isRequired,
        updateCanSubmit: PropTypes.func.isRequired,
        eventStripPreviewData: PropTypes.array.isRequired,
        fetchAgvanceFieldList: PropTypes.func,
        fetchAgvanceOrgLevelList: PropTypes.func,
        loadDetailPage: PropTypes.func,
        importTemplate: PropTypes.object.isRequired,
        importType: PropTypes.object.isRequired,
        fetchEventStripPreviewData: PropTypes.func.isRequired,
        onCancelEvent: PropTypes.func.isRequired,
        onCreateEvent: PropTypes.func.isRequired,
        setApiErrorResultList: PropTypes.func.isRequired,
        setAppendList: PropTypes.func.isRequired,
        setEventStripPreviewData: PropTypes.func.isRequired,
        showProcessing: PropTypes.bool.isRequired,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            customerGuid: null,
            isErrorDetailDialogOpen: false,
            editingRecord: -1,
            errorDetailGuid: null,
            selectedImportEventIndex: -1,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.eventStripPreviewData) !==
            JSON.stringify(this.props.eventStripPreviewData)
        ) {
            const canSubmit =
                nextProps.eventStripPreviewData.length > 0 &&
                nextProps.eventStripPreviewData.some((e) => e.fieldGuid);
            this.props.updateCanSubmit(canSubmit);
        }
    }

    componentDidMount() {
        if (
            this.props.importType.name.toLowerCase() !== ImportTypes.IMAGERY &&
            !this.props.importTemplate.isNameMatch
        ) {
            this.props.fetchEventStripPreviewData();
        } else {
            this.props.updateCanSubmit(this.props.eventStripPreviewData.length > 0);
        }
    }
    _getBucketHeader = (isSingleStep, headerMessage) => {
        const { formatMessage } = this.props.intl;
        if (isSingleStep) {
            return (
                <BucketHeader className={"import-event-single-step-header"}>
                    <div className="space"></div>
                    <div className="customer-column">Customer</div>
                    <div className="farm-field-column">Farm</div>
                    <div className="farm-field-column">Field</div>
                    <div className="season-column">Season</div>
                    <div className="space"></div>
                </BucketHeader>
            );
        }
        return (
            <BucketHeader className="import-field-section-header create-events-section-header">
                <div className="create-events-header">
                    {formatMessage(headerMessage, { count: 0 })}
                </div>
            </BucketHeader>
        );
    };

    _getSingleStepAccordion = (event, index, listLength) => {
        const { formatMessage } = this.props.intl;
        const { display } = event;
        const eventRecord = {
            customerGuid: "",
            customerName: display.customerName,
            farmName: display.farmName,
            fieldName: display.fieldName,
            fieldGuid: event.fieldGuid,
        };
        const isEditing = this.state.editingRecord === index;
        const isNoMatch = !event.fieldGuid;
        const contextMenuItems = [
            {
                key: 0,
                label: formatMessage(messages.editCustomerFieldText),
                action: () => this.onEditCustomerField(index),
            },
            {
                key: 1,
                label: formatMessage(messages.removeText),
                disabled: listLength.length === 1,
                action: () => this._removeEvent(index),
            },
        ];

        return (
            <div
                key={index}
                className={classNames("ifb-row", {
                    selected: this.isRecordSelected(index),
                })}
            >
                <div
                    className={classNames("ifb-strip", {
                        editing: isEditing,
                        "no-match": isNoMatch,
                        selected: this.isRecordSelected(index),
                        matched: !isNoMatch,
                    })}
                    onClick={(evt) => this.toggleRecord(evt, index)}
                >
                    <div className="space field-selected-column">
                        <div className="field-selected-div">
                            <div className="field-selected-div"></div>
                        </div>
                    </div>
                    <div className="customer-column" title={display.customerName}>
                        {display.customerName}
                    </div>
                    <div className="farm-field-column" title={display.farmName}>
                        {display.farmName}
                    </div>
                    <div className="farm-field-column" title={display.fieldName}>
                        {display.fieldName}
                    </div>
                    <div className="season-column" title={display.croppingSeason}>
                        {display.croppingSeason}
                    </div>
                    <div className="space">
                        <Menu
                            className="field-context-menu context-menu"
                            isDotMenu={true}
                            menuItems={contextMenuItems}
                        />
                    </div>
                </div>
                {!this.isRecordSelected(index) ? null : (
                    <div key={`file-${index}-${0}`} className="file-strip">
                        <div className="field-selected-column">
                            <div className="field-selected-div"></div>
                        </div>
                        <div className="file-name" title={display.importFileName}>
                            {display.importFileName}
                        </div>
                    </div>
                )}
                {index !== this.state.editingRecord ? null : (
                    <AddEditNameMatchModal
                        record={eventRecord}
                        onSave={(data, callback) => this._saveEditCustomerField(data, callback())}
                        onClose={() => this.onCloseEditCustomerField()}
                        toolsetPayload={{
                            customerGuid: event.customerGuid,
                        }}
                        onAddNewCustomer={this.onAddNewCustomer}
                        fetchAgvanceFieldList={this.props.fetchAgvanceFieldList}
                        isAgvanceCustomer={this.state.isAgvanceCustomer}
                        agvanceFieldList={this.state.agvanceFieldList}
                        fetchAgvanceOrgLevelList={this.props.fetchAgvanceOrgLevelList}
                        agvanceOrgLevelList={this.state.agvanceOrgLevelList}
                        removeImportFieldBoundary={() =>
                            this.onRemoveField(this.state.editingRecord)
                        }
                    />
                )}
            </div>
        );
    };

    _removeEvent = (index) => {
        const { eventStripPreviewData } = this.props;
        const newEventList = [
            ...eventStripPreviewData.slice(0, index),
            ...eventStripPreviewData.slice(index + 1),
        ];
        if (newEventList.length === 0) {
            return;
        } else {
            this.props.setEventStripPreviewData(newEventList);
        }
    };
    isRecordSelected(index) {
        return index === this.state.selectedImportEventIndex;
    }

    onAddNewCustomer = (customerName) => {
        this.setState({
            editingRecord: -1,
            prevEditingRecord: this.state.editingRecord,
        });
        this.props.loadDetailPage("213", "101", {
            onCloseSlidingPanel: this.onCustomerClose,
            onSaveSuccess: this.onCustomerSaveSuccess,
            onSaveFailed: this.onCustomerSaveFailed,
            customerName,
        });
    };

    onCloseEditCustomerField() {
        this.setState({
            editingRecord: -1,
            customerGuid: "",
            customerName: "",
        });
    }

    onEditCustomerField(index) {
        this.setState({
            editingRecord: index,
        });
        if (this.state.selectedImportEventIndex !== index) {
            this.setState({ selectedImportEventIndex: index });
        }
    }

    onCustomerClose = () => {
        this.setState({
            editingRecord: this.state.prevEditingRecord,
            prevEditingRecord: -1,
        });
    };

    onCustomerSaveSuccess = (customerData) => {
        if (this.state.isAgvanceCustomer) {
            this.props.fetchAgvanceFieldList(customerData.customerGuid);
        }
        this.setState({
            customerGuid: customerData.customerGuid,
            customerName: customerData.name,
        });
    };

    onCustomerSaveFailed = (customerData) => {
        console.assert(Object.keys(customerData).length > 0);
        console.error("There was a problem while saving the customer", customerData);
    };

    _saveEditCustomerField(data) {
        const { editCustomerName, editFarmName, editFieldName, editFieldGuid } = data;
        const { eventStripPreviewData } = this.props;
        const index = this.state.editingRecord;
        const originalRecord = eventStripPreviewData[index];
        const updatedRecords = eventStripPreviewData.map((event, eventIndex) => ({
            ...event,
            display: {
                ...event.display,
                customerName:
                    event.display.customerName === originalRecord.display.customerName
                        ? editCustomerName
                        : event.display.customerName,
                fieldName:
                    (eventIndex === index || event.display.fieldName) &&
                    originalRecord.display.fieldName === event.display.fieldName
                        ? editFieldName
                        : event.display.fieldName,
                farmName:
                    (eventIndex === index || event.display.farmName) &&
                    originalRecord.display.farmName === event.display.farmName
                        ? editFarmName
                        : event.display.farmName,
            },
            fieldGuid:
                (eventIndex === index || event.display.fieldName) &&
                originalRecord.display.fieldName === event.display.fieldName
                    ? editFieldGuid
                    : event.fieldGuid,
        }));
        this.props.setEventStripPreviewData(updatedRecords);
    }

    toggleRecord(evt, index) {
        if (
            this.state.editingRecord === -1 &&
            !(
                evt.target.className.indexOf("context-menu") > -1 ||
                ["menu-dots", "menu-dot", "menu-item"].includes(evt.target.className) ||
                evt.target.parentNode.className === "menu-item"
            )
        ) {
            const newSelected = this.isRecordSelected(index) ? -1 : index;
            this.setState({ selectedImportEventIndex: newSelected });
        }
    }

    getEventDisplayName(importType, event) {
        const { display } = event;
        const { formatDate } = this.props.intl;
        let hasProductMixes = false;
        if (importType.name === keywords.PLANTING) {
            if (event.varieties) {
                hasProductMixes = event.varieties.some((x) => x.productMixes.some((y) => y));
            }
        }
        const displayName =
            display.eventName ||
            [
                importType.name === keywords.PLANTING && hasProductMixes
                    ? importType.name + " + " + keywords.APPLICATION
                    : importType.name,
                formatDate(event.userLocalTime, intlConfig.utcDateFormatOptions),
                event.eventDisplayName,
            ]
                .filter((n) => n)
                .join(" - ");
        return displayName;
    }

    render() {
        const {
            importType,
            importTemplate,
            apiErrorResultList,
            appendList,
            eventStripPreviewData,
            onCancelEvent,
            onCreateEvent,
            setApiErrorResultList,
            setAppendList,
            setEventStripPreviewData,
            showProcessing,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const eventStripPreviewDataFiltered = eventStripPreviewData.filter((event) => {
            const statusCode = event.statusCode === 0 ? 5 : event.statusCode;
            const statusClasses = getClassNameFromStatusCode(statusCode);
            return showProcessing ? true : !(statusClasses === "import-status-processing");
        });

        const errorDetailDialog = !this.state.isErrorDetailDialogOpen ? null : (
            <ErrorDetailsDialog
                onClose={() =>
                    this.setState({
                        isErrorDetailDialogOpen: false,
                        errorDetailGuid: null,
                    })
                }
                correlationId={this.state.errorDetailGuid}
                logType="createevent"
            />
        );
        const headerMessage =
            importType.name.toLowerCase() === ImportTypes.IMAGERY
                ? messages.importWizardCreateLayersText
                : messages.importWizardCreateEventsText;
        const isSingleStep = importTemplate.isNameMatch;
        const sectionClassName = isSingleStep ? "events-single-step-section" : "events-section";
        return (
            <div className="events-panel">
                <div className={sectionClassName}>
                    {errorDetailDialog}
                    <Bucket
                        className="import-field-section-bucket"
                        isExpanded={true}
                        isCollapsible={false}
                        showSymbol={false}
                    >
                        {this._getBucketHeader(isSingleStep, headerMessage)}
                        <div className="field-section-content">
                            {eventStripPreviewDataFiltered.map((event, index) => {
                                if (isSingleStep) {
                                    return this._getSingleStepAccordion(
                                        event,
                                        index,
                                        eventStripPreviewData.length
                                    );
                                } else {
                                    const statusCode =
                                        event.status.statusCode === 0
                                            ? StatusCodes.ReadyForImport
                                            : event.status.statusCode;
                                    const { display } = event;
                                    const contextMenuItems = [
                                        {
                                            key: 0,
                                            label: formatMessage(messages.removeText),
                                            disabled: eventStripPreviewDataFiltered.length === 1,
                                            action: () => this._removeEvent(index),
                                        },
                                    ];
                                    if (
                                        [
                                            StatusCodes.ErrorProcessingSurface,
                                            StatusCodes.ErrorCreatingEvent,
                                        ].includes(statusCode)
                                    ) {
                                        contextMenuItems.push({
                                            key: 1,
                                            label: formatMessage(ErrorDetailsContextMenuLabel),
                                            action: () =>
                                                this.setState({
                                                    isErrorDetailDialogOpen: true,
                                                    errorDetailGuid: event.agEventGeneralGuid,
                                                }),
                                        });
                                    }

                                    const displayDetails = [
                                        display.customerName,
                                        (display.farmName ? display.farmName + " - " : "") +
                                            display.fieldName,
                                        formatMessage(messages.acresText, {
                                            acres: display.acres,
                                        }),
                                    ].join(", ");
                                    const statusClasses = getClassNameFromStatusCode(statusCode);
                                    const statusStr = formatMessage(statusMessages.importStatus, {
                                        statusCode,
                                    });
                                    const importStatus =
                                        statusCode === StatusCodes.ReadyForImport
                                            ? formatMessage(messages.eventStripStatusText, {
                                                  pointCount: display.pointCount,
                                                  statusStr,
                                              })
                                            : statusStr;
                                    const attribute3 = display.cropName
                                        ? display.cropPurposeName
                                            ? `${display.cropName} (${display.cropPurposeName}) ${display.croppingSeason}`
                                            : `${display.cropName} ${display.croppingSeason}`
                                        : display.productMixName
                                        ? `${display.productMixName} ${display.croppingSeason}`
                                        : display.croppingSeason
                                        ? display.croppingSeason
                                        : display.eventId;
                                    const eventDisplayName = this.getEventDisplayName(
                                        importType,
                                        event
                                    );
                                    return (
                                        <div key={"event-" + index} className="ifb-row">
                                            <div className="ifb-strip">
                                                <div className="field-selected-column">
                                                    <div className="field-selected-div"></div>
                                                </div>
                                                <div className="field-strip-content">
                                                    <div className="field-strip-content-row">
                                                        <div
                                                            className="farm-field-text"
                                                            title={eventDisplayName}
                                                        >
                                                            {eventDisplayName}
                                                        </div>
                                                        <div className="season">{attribute3}</div>
                                                    </div>
                                                    <div className="field-strip-content-row">
                                                        <div
                                                            className="customer-name"
                                                            title={displayDetails}
                                                        >
                                                            {displayDetails}
                                                        </div>
                                                        <div
                                                            className={classNames(
                                                                "feature-count",
                                                                statusClasses
                                                            )}
                                                        >
                                                            {importStatus}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field-context-menu-container">
                                                    <Menu
                                                        className="field-context-menu context-menu"
                                                        isDotMenu={true}
                                                        menuItems={contextMenuItems}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </Bucket>
                </div>
                {apiErrorResultList.length === 0 ? null : (
                    <ValidationErrorModal
                        apiErrorResultList={apiErrorResultList}
                        setApiErrorResultList={setApiErrorResultList}
                    />
                )}
                {appendList.length === 0 || apiErrorResultList.length !== 0 ? null : (
                    <AppendSelectModal
                        appendList={appendList}
                        eventStripPreviewData={eventStripPreviewData}
                        setEventStripPreviewData={setEventStripPreviewData}
                        onCreateEvent={onCreateEvent}
                        onCancelEvent={onCancelEvent}
                        setAppendList={setAppendList}
                    />
                )}
            </div>
        );
    }
}
