import { createAction } from "redux-act";

export const FETCH_IMPORT_FILTER = "FETCH_IMPORT_FILTER_DATA";
export const FETCH_IMPORT_FILTER_SUCCESS = "FETCH_IMPORT_FILTER_SUCCESS";
export const FETCH_IMPORT_FILTER_FAILED = "FETCH_IMPORT_FILTER_FAILED";

export const SAVE_IMPORT_FILTER = "SAVE_IMPORT_FILTER_DATA";
export const SAVE_IMPORT_FILTER_SUCCESS = "SAVE_IMPORT_FILTER_SUCCESS";
export const SAVE_IMPORT_FILTER_FAILED = "SAVE_IMPORT_FILTER_FAILED";

export const fetchImportFilter = createAction(FETCH_IMPORT_FILTER);
export const fetchImportFilterSuccess = createAction(FETCH_IMPORT_FILTER_SUCCESS);
export const fetchImportFilterFailed = createAction(FETCH_IMPORT_FILTER_FAILED);

export const saveImportFilter = createAction(SAVE_IMPORT_FILTER);
export const saveImportFilterSuccess = createAction(SAVE_IMPORT_FILTER_SUCCESS);
export const saveImportFilterFailed = createAction(SAVE_IMPORT_FILTER_FAILED);
