import React from "react";
import PropTypes from "prop-types";
import {
    EventPlantingForm as EventPlantingFormProvider,
    EventPlantingSummary as EventPlantingSummaryProvider,
} from "./providers";
import { plantingModule } from "~/action-panel/components/common/planting";
const { PlantingEventForm } = plantingModule;

const EventPlantingForm_ = (props) => {
    return <PlantingEventForm {...props} model={props.agEventModel} />;
};

EventPlantingForm_.propTypes = {
    agEventModel: PropTypes.object,
};
export const errorCodesApply = plantingModule.errorCodesApply;
export const errorMessages = plantingModule.errorMessages;
export const errorCodeToMessageIdSetMap = plantingModule.errorCodeToMessageIdSetMap;
export const formLabelMessage = plantingModule.formLabelMessage;
export const formLabelIcon = plantingModule.formLabelIcon;
export const EventPlantingForm = EventPlantingFormProvider(EventPlantingForm_);
export const EventPlantingSummary = EventPlantingSummaryProvider(plantingModule.SummarySection);
