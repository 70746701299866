import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const PolylineIcon = withSvgIcon(
    <g id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="23.9"
            y="19.3"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.2075 24.6432)"
            width="1.5"
            height="10.8"
        />
        <rect
            x="13.7"
            y="9.1"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.0021 14.4904)"
            width="1.5"
            height="10.8"
        />
        <rect
            x="3.6"
            y="-1"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.7967 4.3376)"
            width="1.5"
            height="10.8"
        />
    </g>,
    "0 0 29 29",
    "no-stroke"
);
