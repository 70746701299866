import React, { Component } from "react";
import PropTypes from "prop-types";
import { intlShape, injectIntl } from "react-intl";
import { messages } from "../../i18n-messages";
import { getAgBytesErrorClassNames, mapToPicklistValue } from "~/admin/utils";
import classNames from "classnames";
import { adminData } from "~/admin/data";
// Components
import { SelectInput, SubSection, TextInput } from "~/core";

// Constants
import * as constants from "./constants";

export class PhoneItem extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        index: PropTypes.number,
        phone: PropTypes.array,
        onChange: PropTypes.func,
        onSelectChange: PropTypes.func,
        apiErrors: PropTypes.array,
    };

    getContainerClass = (className, errorCode, type) => {
        const { phone, index } = this.props;
        if (phone[index].isError) {
            const { apiErrors } = this.props;
            return [
                classNames(
                    className,
                    type ? false : getAgBytesErrorClassNames(errorCode, apiErrors)
                ),
            ];
        }
        return [className];
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { index, phone, onChange, onSelectChange } = this.props;
        return (
            <SubSection>
                <SelectInput
                    tabIndex={0}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    options={this.props[constants.PROPS_PHONE_TYPE_LIST]}
                    placeholderText={formatMessage(messages.phoneType)}
                    labelText={formatMessage(messages.phoneType)}
                    containerClassNames={this.getContainerClass(
                        "phone-type",
                        437,
                        phone[index][constants.PROPS_PHONE_TYPE_GUID]
                    )}
                    value={mapToPicklistValue({
                        options: this.props[constants.PROPS_PHONE_TYPE_LIST],
                        selectedGuid: phone[index][constants.PROPS_PHONE_TYPE_GUID],
                    })}
                    onChange={(value) =>
                        onSelectChange(
                            {
                                type: constants.PROPS_PHONE_TYPE_NAME,
                                guid: constants.PROPS_PHONE_TYPE_GUID,
                            },
                            value,
                            index
                        )
                    }
                />
                <TextInput
                    tabIndex={0}
                    maxLength={20}
                    placeholderText={formatMessage(messages.phoneNumber)}
                    labelText={formatMessage(messages.phoneNumber)}
                    containerClassNames={this.getContainerClass(
                        "phone-no",
                        527,
                        phone[index][constants.PROPS_PHONE_NUMBER]
                    )}
                    value={phone[index][constants.PROPS_PHONE_NUMBER]}
                    onChange={(value) =>
                        onChange(
                            {
                                type: constants.PROPS_PHONE_NUMBER,
                            },
                            value,
                            index
                        )
                    }
                />
            </SubSection>
        );
    }
}

export default injectIntl(PhoneItem);
