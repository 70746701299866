import { createService } from "~/hocs/crud";
import * as model from "./model";
import { PROPS_GROWTH_STAGE_GUID } from "./../../growth-stages/data/model";
import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
import { apiUrl } from "@ai360/core";

// Model name
export const MODEL_NAME = "growthStageOrderList";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "growthStageOrderFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "growthStageOrderSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "growthStageOrderPageOptions";
export const REQUEST_PAYLOAD_PARENT_GUID = "growthStageGuid";

// URLs
export const URL = apiUrl("AgBytes/GetGrowthStageOrderList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetGrowthStageOrderAutoSearchList");
export const GETRECORD = apiUrl("AgBytes/GetGrowthStageOrder");
export const NEXT_ID = apiUrl("AgBytes/GetNextStageOrderId");
export const CREATE = apiUrl("AgBytes/AddGrowthStageOrder");
export const UPDATE = apiUrl("AgBytes/UpdateGrowthStageOrder");
export const DELETE = apiUrl("AgBytes/DeleteGrowthStageOrder");

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        ModifiedDate: "",
        canDelete: "",
        description: "",
        growingDegreeDaysHigh: "",
        growingDegreeDaysLow: "",
        name: "",
        isActive: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_PARENT_GUID]: "",
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "StageOrder",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultGrowthStage = () => ({
    [model.PROPS_GROWTH_STAGE_ORDER]: null,
    [model.PROPS_GROWTH_STAGE_ORDER_NAME]: "",
    [model.PROPS_GROWTH_STAGE_NOTES]: "",
    [model.PROPS_GROWTH_STAGE_ORDER_GUID]: "",
    [model.PROPS_GROWING_DEGREE_DAYS_LOW]: "0",
    [model.PROPS_GROWING_DEGREE_DAYS_HIGH]: "0",
    [model.PROPS_DESCRIPTION]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_CAN_DELETE]: false,
    [model.PROPS_ACTIVE_YN]: true,
});

// Service
export const service = createService({
    id: model.PROPS_GROWTH_STAGE_ID,
    guid: model.PROPS_GROWTH_STAGE_ORDER_GUID,
    parentGuid: PROPS_GROWTH_STAGE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    REQUEST_PAYLOAD_PARENT_GUID,
    urls: {
        URL,
        GETRECORD,
        NEXT_ID,
        AUTO_SEARCH_URL,
        CREATE,
        UPDATE,
        DELETE,
    },
    _defaultLabels: {
        [model.PROPS_GROWTH_STAGE_ORDER]: {
            label: "growthStageOrderID",
            gridCol: 5,
        },
        [model.PROPS_GROWTH_STAGE_ORDER_NAME]: {
            label: "growthStageOrderName",
            gridCol: 15,
        },
        [model.PROPS_DESCRIPTION]: {
            label: "growthStageOrderDescription",
            gridCol: 20,
        },
        [model.PROPS_GROWING_DEGREE_DAYS_LOW]: {
            label: "growingDegreeDaysLow",
            gridCol: 10,
        },
        [model.PROPS_GROWING_DEGREE_DAYS_HIGH]: {
            label: "growingDegreeDaysHigh",
            gridCol: 10,
        },
        [model.PROPS_ACTIVE_YN]: {
            label: model.PROPS_IS_ACTIVE,
            gridCol: 10,
            visible: false,
            sortNameOverRide: model.PROPS_IS_ACTIVE,
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: { label: "canDelete", gridCol: 10 },
    },
    getDefaultRecord: () => ({ ...defaultGrowthStage() }),
    defaultSort,
});
