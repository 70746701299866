import { all, call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import fileSaver from "file-saver";
import { OrderedMap } from "immutable";

import { actions as accordionActions } from "~/accordion";
import {
    actions as layerListActions,
    selectors as layerListSelectors,
} from "~/action-panel/components/layer-module/components/layer-list";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getTheUserGuid } from "~/login";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { actions as messagingActions } from "~/messaging";
import { actions as eventsActions, eventsSelectors } from "~/recs-events";
import { AgEventSummary } from "~/recs-events/events/model";
import { MAX_FETCH_FIELDS } from "~/recs-events/model";
import { AgEventAPI, FileImportAPI } from "@ai360/core";

import { getFieldGuidFromDimIdx } from "../../../common/accordion/model";
import * as commonSagas from "../../../common/rec-event-sagas";
import { StatusCodes } from "../../../common/status-messages";

import {
    createEventAccordionItems,
    getEventGuidFromDimIdx,
} from "../../../common/accordion/rec-event-accordion-item";

import * as panelActions from "../../../../actions";
import * as eventInfoActions from "../event-info/actions";
import { messages } from "../i18n-messages";

import * as recsEventsActions from "~/recs-events/events/actions";

import * as selectors from "./selectors";
import * as actions from "./actions";
import { logFirebaseEvent } from "~/utils/firebase";
import { EventFilter } from "./reducer";

const expandItemOnSave = function* () {
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    if (fieldGuidToEventDetails.size === 1) {
        const fieldGuid = fieldGuidToEventDetails.keys().next().value;
        yield put(actions.updateExpandedFieldGuidSet(fieldGuid, true));
    }
};

const onBatchCopyEvents = function* () {
    yield put(actions.setEventPanelLoading(true));
};

const onExportEventData = function* (action) {
    const { eventSummary } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    eventSummary.length > 1
        ? logFirebaseEvent("export_data_batch")
        : logFirebaseEvent("export_data");

    const exportEventDataRequest = [];
    for (const event of eventSummary) {
        const trimmedEvent = {
            agEventGeneralGuid: event.agEventGeneralGuid,
            fieldGuid: event.fieldGuid,
        };
        exportEventDataRequest.push(trimmedEvent);
    }

    try {
        yield call(FileImportAPI.exportEventData, userGuid, exportEventDataRequest);
        for (const event of exportEventDataRequest) {
            yield put(
                eventsActions.updateEventSummaryImportedStatus(
                    event.fieldGuid,
                    event.agEventGeneralGuid,
                    StatusCodes.Exporting
                )
            );
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const onExportSurfaceData = function* (action) {
    const { eventSummary } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    eventSummary.length > 1
        ? logFirebaseEvent("export_surface_batch")
        : logFirebaseEvent("export_surface");

    const exportSurfaceDataRequest = [];
    for (const event of eventSummary) {
        const trimmedEvent = {
            agEventGeneralGuid: event.agEventGeneralGuid,
            fieldGuid: event.fieldGuid,
        };
        exportSurfaceDataRequest.push(trimmedEvent);
    }

    try {
        yield call(FileImportAPI.exportSurfaceData, userGuid, exportSurfaceDataRequest);
        for (const event of exportSurfaceDataRequest) {
            yield put(
                eventsActions.updateEventSummaryImportedStatus(
                    event.fieldGuid,
                    event.agEventGeneralGuid,
                    StatusCodes.Exporting
                )
            );
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const onExportSamplingPoints = function* (action) {
    const { eventSummary } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    const exportPointsRequest = [];

    eventSummary.length > 1
        ? logFirebaseEvent("export_points_batch")
        : logFirebaseEvent("export_points");

    for (const event of eventSummary) {
        const trimmedEvent = {
            agEventGuid: event.agEventGuid,
            fieldGuid: event.fieldGuid,
            agEventGeneralGuid: event.agEventGeneralGuid,
        };
        exportPointsRequest.push(trimmedEvent);
    }
    try {
        yield call(FileImportAPI.exportSamplingPoints, userGuid, exportPointsRequest);
        for (const event of exportPointsRequest) {
            yield put(
                eventsActions.updateEventSummaryImportedStatus(
                    event.fieldGuid,
                    event.agEventGeneralGuid,
                    StatusCodes.Exporting
                )
            );
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const messageSubscriptions = function* () {
    const handleExportEvent = function* (message) {
        if (message.exportFileUrl && message.exportFileName) {
            const resp = yield call(fetch, message.exportFileUrl);
            if (resp.status === 200) {
                // Response is OK
                resp.blob().then((blob) => fileSaver.saveAs(blob, message.exportFileName));
                yield put(
                    notificationActions.pushToasterMessage(messages.exportComplete, MSGTYPE.INFO)
                );
            } else {
                yield put(
                    notificationActions.pushToasterMessage(messages.exportFailed, MSGTYPE.ERROR)
                );
            }
        } else {
            switch (message.status) {
                case StatusCodes.Exported:
                    yield put(
                        eventsActions.updateEventSummaryImportedStatus(
                            message.fieldGuid,
                            message.agEventGeneralGuid,
                            StatusCodes.Exported
                        )
                    );
                    break;
                case StatusCodes.ExportFailed:
                    yield put(
                        eventsActions.updateEventSummaryImportedStatus(
                            message.fieldGuid,
                            message.agEventGeneralGuid,
                            StatusCodes.ExportFailed
                        )
                    );
                    break;
                default:
                    console.warn("Unhandled notification for exportEvent");
            }
        }
    };

    yield put(
        messagingActions.subscribe(0, {
            eventName: "exportEvent",
            generator: handleExportEvent,
        })
    );
};

const eventTypeMatchesFilter = (event: AgEventAPI.IAgEventSummary, eventType: string): boolean => {
    const typeNameList = event.agEventTypeName.split(" + ");
    if (event.isImportedYn === true && typeNameList.some((t) => isFilterableImportType(t))) {
        const adjustedTypeNameList = typeNameList.map((t, i) => (i === 0 ? `${t} (Imported)` : t));
        return adjustedTypeNameList.includes(eventType);
    }
    return typeNameList.includes(eventType);
};

const filterEventOptions = function* () {
    const { filter, filterInfo } = yield select(selectors.getModuleState);
    const eventTypeFilter = yield select(selectors.getEventFilter);
    const eventFilter = filter;
    const { crops, croppingSeasons, eventTypes, filters } = filterInfo;
    let cropOptions = [];
    const cropGuids = [];
    const uniqueCrops = [];
    let croppingSeasonOptions = [];
    const uniqueSeasons = [];
    const uniqueTypeOptions = [];
    let eventTypeOptions = [];
    if (filters && Object.keys(filters).length > 0) {
        const chkFilter = (list, guid, cat, filter) =>
            filter == null ||
            filter.length === 0 ||
            (list.has(guid) && list.get(guid)[cat].has(filter));
        const eTs = filters.filter((rf) => {
            return (
                chkFilter(
                    eventTypes,
                    rf.layerType,
                    "croppingSeasonGuids",
                    eventFilter.seasonGuid
                ) && chkFilter(eventTypes, rf.layerType, "cropGuids", eventFilter.cropGuid)
            );
        });
        const uniqueETs = new Map(eTs.map((et) => [et.layerType, et.layerType]));
        uniqueTypeOptions.push(
            ...Array.from(uniqueETs.entries()).map(([label, value]) => ({
                label,
                value,
            }))
        );
        uniqueTypeOptions.sort((t1, t2) =>
            t1.label < t2.label ? -1 : t1.label === t2.label ? 0 : 1
        );
        // Event Filter options formation
        // Get the current selected filter
        const eventListMapWithFilter =
            eventTypeFilter.activeTab === eventsActions.RecEventListTabs.ACTIVE
                ? yield select(eventsSelectors.getFieldGuidToEventListMap)
                : yield select(eventsSelectors.getFieldGuidToInactiveEventListMap);
        const allEventsList = [...eventListMapWithFilter.values()].flat();

        eventTypeOptions = uniqueTypeOptions.filter((t) =>
            allEventsList.some((e) => eventTypeMatchesFilter(e, t.value))
        );

        const ecs = filters.filter((rf) => {
            return (
                chkFilter(
                    croppingSeasons,
                    rf.croppingSeasonGuid,
                    "eventTypes",
                    eventFilter.eventType
                ) &&
                chkFilter(croppingSeasons, rf.croppingSeasonGuid, "cropGuids", eventFilter.cropGuid)
            );
        });
        const uniqueCSs = new Map(ecs.map((cs) => [cs.croppingSeasonGuid, `${cs.croppingSeason}`]));
        uniqueSeasons.push(
            ...Array.from(uniqueCSs.entries()).map(([value, label]) => ({
                label,
                value,
            }))
        );
        uniqueSeasons.sort((a, b) => a.label - b.label).reverse();
        croppingSeasonOptions = uniqueSeasons.filter((cs) =>
            allEventsList.some((e) => e.croppingSeasonGuid === cs.value)
        );

        const ec = filters.filter((c) => {
            return (
                chkFilter(crops, c.cropGuid, "croppingSeasonGuids", eventFilter.seasonGuid) &&
                chkFilter(crops, c.cropGuid, "eventTypes", eventFilter.eventType) &&
                c.crop &&
                c.cropGuid
            );
        });
        allEventsList.forEach((e) => e.cropGuids.forEach((cropGuid) => cropGuids.push(cropGuid)));
        const uniqueCs = new Map(ec.map((crop) => [crop.cropGuid, crop.crop]));
        uniqueCrops.push(
            ...Array.from(uniqueCs.entries()).map(([value, label]) => ({
                label,
                value,
            }))
        );
        uniqueCrops.sort((c1, c2) => (c1.label < c2.label ? -1 : c1.label === c2.label ? 0 : 1));
        cropOptions = uniqueCrops.filter((c) => cropGuids.includes(c.value));
    }
    yield put(
        actions.setEventFilterOptions({
            cropOptions,
            croppingSeasonOptions,
            eventTypeOptions,
        })
    );
};
const isFilterableImportType = (agEventTypeName: string): boolean => {
    return (
        agEventTypeName == "Harvest" ||
        agEventTypeName == "Application" ||
        agEventTypeName == "Planting"
    );
};

export const filterEventList = (
    eventList,
    eventFilter: EventFilter,
    selectedEventGuidSet,
    eventSearch = ""
) => {
    if (eventFilter) {
        const { cropGuid, eventType, onlySelected, seasonGuid } = eventFilter;
        return eventList.filter(
            (event) =>
                (!onlySelected || selectedEventGuidSet.has(event.agEventGeneralGuid)) &&
                (!eventSearch.length ||
                    event.agEventName.toLowerCase().includes(eventSearch) ||
                    event.eventId.toLowerCase().includes(eventSearch)) &&
                (!seasonGuid || event.croppingSeasonGuid === seasonGuid) &&
                (!eventType || // Cannot compare agEventTransactionTypeGuid because of Aggregate
                    eventTypeMatchesFilter(event, eventFilter.eventType)) &&
                (!cropGuid || (event.cropGuids && event.cropGuids.includes(cropGuid)))
        );
    } else {
        return eventList.filter(
            (event) =>
                event.agEventName.toLowerCase().includes(eventSearch) ||
                event.eventId.toLowerCase().includes(eventSearch)
        );
    }
};

export const addAccordionEventItems = function* () {
    const eventFilter = yield select(selectors.getEventFilter);
    const searchValue = yield select(selectors.getSearchValue);
    const eventListMapTemplate =
        eventFilter.activeTab === eventsActions.RecEventListTabs.INACTIVE
            ? yield select(eventsSelectors.getFieldGuidToInactiveEventListMap)
            : yield select(eventsSelectors.getFieldGuidToEventListMap);

    const fieldMap = yield select(eventsSelectors.getFieldGuidToFieldMap);

    const eventListMap = [...eventListMapTemplate.entries()].reduce((accumulator, item) => {
        const [fieldGuid, eventList] = item;
        accumulator.set(fieldGuid, eventList);
        return accumulator;
    }, new Map());

    const accordionId = yield select(selectors.getAccordionId);
    const expandedGuidSet = yield select(selectors.getExpandedFieldGuidSet);
    const selectedEventGuidSet = yield select(selectors.getSelectedEventGuidSet);

    const { onlySelected, seasonGuid, eventType, cropGuid } = eventFilter;
    const sendEventFilter =
        !onlySelected && !seasonGuid && !eventType && !cropGuid ? null : eventFilter;
    yield* commonSagas.addAccordionRecEventItems(
        accordionId,
        createEventAccordionItems,
        expandedGuidSet,
        sendEventFilter,
        searchValue,
        filterEventList,
        eventListMap,
        fieldMap,
        selectedEventGuidSet
    );
};

export const setupAccordionOnPanelActivated = function* () {
    do {
        const selectedFieldCount = yield select(cdSelectors.getSelectedFieldsCount);
        const waitForActions = [cdActions.ADD_SELECTED_FIELDS];
        if (selectedFieldCount > MAX_FETCH_FIELDS) {
            waitForActions.push(cdActions.CLEAR_SELECTED_FIELDS);
        }
        yield take(waitForActions);
        let activeModule;
        do {
            activeModule = (yield take(panelActions.ACTIONPANEL_SET_ACTIVEMODULE)).payload;
        } while (activeModule !== panelActions.ActionPanelModuleList.EVENT);

        yield put(actions.setEventPanelLoading(true));
        yield put(eventsActions.fetchSelectedFieldEvents());
        // Need to get recs also, so the count is correct.  Logic involving rec cancel alters the
        // count for the field based on the number of recs in the field/rec map.
        yield put(eventsActions.fetchSelectedFieldRecs());
    } while (true);
};

export const reloadEventsOnSave = function* () {
    yield put(actions.setEventPanelLoading(true));
    yield put(eventsActions.fetchSelectedFieldEvents());
    yield put(eventsActions.refreshFilterOptions());
};

export const onEventsFetched = function* () {
    yield* addAccordionEventItems();
    yield put(actions.setEventPanelLoading(false));
    yield put(actions.resetAllAccordion());
    yield put(actions.resetInactiveAccordion());
    yield call(filterEventOptions);
};

export const onFilterChanged = function* () {
    let isPanelLoading = yield select(selectors.getEventPanelLoading);
    if (isPanelLoading) {
        do {
            const action = yield take(actions.SET_EVENT_PANEL_LOADING);
            isPanelLoading = action.payload.isPanelLoading;
        } while (isPanelLoading);
    }
    yield call(filterEventOptions);
    yield* addAccordionEventItems();
};
const onConfigureEventFilters = function* (action) {
    const { filters } = action.payload;
    if (!filters) {
        return;
    }
    const eventSpecificFilters = filters.all.filter((i) => i.agEventTransactionTypeGuid);
    const uniqueCSs = new Map(
        eventSpecificFilters.map((cs) => [`${cs.croppingSeason}`, cs.croppingSeasonGuid])
    );
    const croppingSeasons = new Map();
    for (const [croppingSeason, croppingSeasonGuid] of uniqueCSs.entries()) {
        const fieldGuids = eventSpecificFilters
            .filter((event) => event.croppingSeasonGuid === croppingSeasonGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = eventSpecificFilters.filter(
            (event) => event.croppingSeasonGuid === croppingSeasonGuid
        );
        croppingSeasons.set(croppingSeasonGuid, {
            croppingSeason,
            fieldGuids,
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
            eventTypes: new Set(filter.map((lt) => lt.layerType).filter(Boolean)),
        });
    }
    const uniqueETs = new Set(eventSpecificFilters.map((lt) => lt.layerType));
    const eventTypes = new Map();
    for (const eventType of uniqueETs.values()) {
        const fieldGuids = eventSpecificFilters
            .filter((lt) => lt.layerType === eventType)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = eventSpecificFilters.filter((event) => event.layerType === eventType);
        eventTypes.set(eventType, {
            fieldGuids,
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
        });
    }
    const uniqueCs = new Map(
        eventSpecificFilters
            .filter((event) => event.crop && event.cropGuid)
            .map((crop) => [crop.crop, crop.cropGuid])
    );
    const crops = new Map();
    for (const [crop, cropGuid] of uniqueCs.entries()) {
        const fieldGuids = eventSpecificFilters
            .filter((event) => event.cropGuid === cropGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = eventSpecificFilters.filter((surface) => surface.cropGuid === cropGuid);
        crops.set(cropGuid, {
            crop,
            fieldGuids,
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
            eventTypes: new Set(filter.map((lt) => lt.layerType).filter(Boolean)),
        });
    }

    yield put(
        actions.setEventFilterInfo({
            croppingSeasons,
            crops,
            eventTypes,
            filters: eventSpecificFilters,
        })
    );
    yield call(filterEventOptions);
};

export const removeDeselectedIfOnlySelected = function* (action) {
    const { onlySelected } = yield select(selectors.getEventFilter);
    if (!onlySelected) {
        return;
    }
    const { startDimIdx, endDimIdx } = action.payload;
    const accordionId = yield select(selectors.getAccordionId);
    const accordionItems = yield select(selectors.getAccordionItems);
    const itemsToRemove = commonSagas.removeDeselectedIfOnlySelected(
        accordionItems,
        endDimIdx,
        startDimIdx
    );
    if (itemsToRemove.length > 0) {
        yield put(accordionActions.removeAccordionItemDimIdxList(accordionId, itemsToRemove));
    }
};

export const onExpandCollapse = function* (action) {
    const { accordionId, index } = action.payload;
    const eventAccordionId = yield select(selectors.getAccordionId);
    const isLoading = yield select(selectors.getEventPanelLoading);
    if (!isLoading && accordionId === eventAccordionId) {
        console.assert(index.length === 1);
        const items = yield select(selectors.getAccordionItems);
        const fieldGuid = getFieldGuidFromDimIdx(items, index);
        const isExpanded = action.type === accordionActions.EXPAND;
        yield put(actions.updateExpandedFieldGuidSet(fieldGuid, isExpanded));
    }
};

export const onExpandCollapseAll = function* (action) {
    const { accordionId, dimensions } = action.payload;
    const eventAccordionId = yield select(selectors.getAccordionId);
    const isLoading = yield select(selectors.getEventPanelLoading);
    if (!isLoading && accordionId === eventAccordionId) {
        console.assert(dimensions.length === 1 && dimensions[0] === 1);
        const items = yield select(selectors.getAccordionItems);
        const fieldGuids = items.map((item, idx) => getFieldGuidFromDimIdx(items, [idx]));
        const isExpanded = action.type === accordionActions.EXPAND_ALL;
        yield put(actions.updateExpandedFieldGuidSet(fieldGuids, isExpanded));
    }
};

export const onDeleteEventsFromAccordion = function* (action) {
    const { toDeleteDimIdxList } = action.payload;
    const accordionId = yield select(selectors.getAccordionId);
    const accordionItems = yield select(selectors.getAccordionItems);
    const fieldGuidToDeletedEventGuidsMap = new Map();

    logFirebaseEvent("delete_event");
    const agEventGeneralGuidList = toDeleteDimIdxList.map((itemDimIdx) => {
        const fieldGuid = getFieldGuidFromDimIdx(accordionItems, [itemDimIdx[0]]);
        const agEventGeneralGuid = getEventGuidFromDimIdx(accordionItems, itemDimIdx);
        if (!fieldGuidToDeletedEventGuidsMap.has(fieldGuid)) {
            fieldGuidToDeletedEventGuidsMap.set(fieldGuid, new Set());
        }
        const fieldEventGuidSet = fieldGuidToDeletedEventGuidsMap.get(fieldGuid);
        fieldEventGuidSet.add(agEventGeneralGuid);
        return agEventGeneralGuid;
    });

    yield put(actions.deselectEventsFromDimIdxList(toDeleteDimIdxList));
    yield put(accordionActions.removeAccordionItemDimIdxList(accordionId, toDeleteDimIdxList));

    const deleteEventsAction = eventsActions.deleteEvents(agEventGeneralGuidList);
    yield put(deleteEventsAction);

    let resultAction;
    do {
        resultAction = yield take([
            notificationActions.API_CALL_ERROR,
            eventsActions.DELETE_EVENTS_SUCCESSFUL,
        ]);
    } while (
        resultAction.type === notificationActions.API_CALL_ERROR &&
        resultAction.payload.retryAction !== deleteEventsAction
    );

    if (resultAction.type === eventsActions.DELETE_EVENTS_SUCCESSFUL) {
        const fieldGuidToEventListMap = yield select(eventsSelectors.getFieldGuidToEventListMap);

        for (const [fieldGuid] of fieldGuidToDeletedEventGuidsMap.entries()) {
            const fieldEventList = fieldGuidToEventListMap.get(fieldGuid);
            yield put(cdActions.updateFieldEventCount(fieldGuid, fieldEventList?.length));
        }
        // Remove any associated visible layer from the store
        const visibleSurfaces = yield select(layerListSelectors.getVisibleSurfacesMap);
        for (const [fieldGuid, surface] of visibleSurfaces.entries()) {
            if (agEventGeneralGuidList.some((guid) => surface.agEventGeneralGuid === guid)) {
                yield put(layerListActions.removeVisibleSurfaces([fieldGuid]));
            }
        }
    }
};

export const onActivateEventFromAccordion = function* (action) {
    const { toActivateDimIdx } = action.payload;
    const accordionItems = yield select(selectors.getAccordionItems);

    yield put(actions.setIsEventLoading(true));

    const fieldGuid = getFieldGuidFromDimIdx(accordionItems, [toActivateDimIdx[0]]);
    const agEventGeneralGuid = getEventGuidFromDimIdx(accordionItems, toActivateDimIdx);

    const activateEventAction = eventsActions.activateEvent(agEventGeneralGuid);
    yield put(activateEventAction);

    let resultAction;
    do {
        resultAction = yield take([
            notificationActions.API_CALL_ERROR,
            eventsActions.ACTIVATE_EVENT_SUCCESSFUL,
        ]);
    } while (
        resultAction.type === notificationActions.API_CALL_ERROR &&
        resultAction.payload.retryAction !== activateEventAction
    );

    if (resultAction.type === eventsActions.ACTIVATE_EVENT_SUCCESSFUL) {
        const fieldGuidToEventListMap = yield select(eventsSelectors.getFieldGuidToEventListMap);
        const fieldEventList = fieldGuidToEventListMap.get(fieldGuid);
        yield put(cdActions.updateFieldEventCount(fieldGuid, fieldEventList?.length));
    }

    yield put(actions.setIsEventLoading(false));
};

export const onLoadYieldCalibration = function* (action) {
    yield put(actions.showHideYieldCalibrationModal(true));
    yield put(actions.yieldCalibrationInProgress(true));
    const { agEventGeneralGuidList } = action.payload;

    agEventGeneralGuidList.length > 1
        ? logFirebaseEvent("yield_calibration_batch")
        : logFirebaseEvent("yield_calibration");

    const agEventSummaryMap = yield select(eventsSelectors.getEventGeneralGuidToEventSummaryMap);
    const agEventGuidList = agEventGeneralGuidList.map((agEventGeneralGuid) => {
        const { agEventGuid } = agEventSummaryMap.get(agEventGeneralGuid);
        return agEventGuid;
    });
    yield put(eventsActions.fetchYieldCalibration(agEventGuidList, true));
};

export const onYieldModalToggle = function* (action) {
    const { isVisible } = action.payload;
    if (!isVisible) {
        yield put(eventsActions.fetchYieldCalibrationCompleted(null));
    }
};

const onClearFields = function* () {
    yield* updateSelectedEvents();
    yield* updateSelectedFieldEvents();
};

const updateSelectedEvents = function* () {
    const fieldGuidToSelectedEventGuidSetMap = yield select(
        selectors.getFieldGuidToSelectedEventGuidSetMap
    );
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const fieldGuidsToRemove = [...fieldGuidToSelectedEventGuidSetMap.keys()].filter(
        (fieldGuid) => !selectedFieldGuidSet.has(fieldGuid)
    );
    if (fieldGuidsToRemove.length > 0) {
        yield put(actions.deselectEventsForFieldGuids(fieldGuidsToRemove));
    }
    yield call(addAccordionEventItems);
};

const updateSelectedFieldEvents = function* () {
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const eventListMap = yield select(eventsSelectors.getFieldGuidToEventListMap);
    const eventListInactiveMap = yield select(eventsSelectors.getFieldGuidToInactiveEventListMap);
    const fieldGuidsToInclude = [...eventListMap.keys()].filter((fieldGuid) =>
        selectedFieldGuidSet.has(fieldGuid)
    );
    const fieldGuidToParsedObjListMap = OrderedMap().withMutations((map) => {
        for (const fieldGuid of fieldGuidsToInclude) {
            map.set(fieldGuid, eventListMap.get(fieldGuid) || []);
        }
    });
    const fieldGuidToParsedObjInactiveListMap = OrderedMap().withMutations((map) => {
        for (const fieldGuid of fieldGuidsToInclude) {
            map.set(fieldGuid, eventListInactiveMap.get(fieldGuid) || []);
        }
    });

    yield put(
        eventsActions.updateSelectedFieldEvents(
            fieldGuidToParsedObjListMap as OrderedMap<string, AgEventSummary[]>,
            fieldGuidToParsedObjInactiveListMap as OrderedMap<string, AgEventSummary[]>
        )
    );
};

const onRecsEventsDestroyedEvents = function* (action) {
    const { agEventGeneralGuids } = action.payload;
    yield put(actions.destroyEventsByAgEventGeneralGuid(agEventGeneralGuids));
};

export const eventListSaga = function* () {
    yield all([
        messageSubscriptions(),
        takeLatest(eventsActions.FETCH_EVENTS_SUCCEEDED, onEventsFetched),
        takeLatest(
            [
                eventsActions.REMOVE_EVENT_GENERAL,
                eventsActions.REMOVE_INACTIVE_EVENT_GENERAL,
                eventsActions.REPLACE_EVENT_GENERAL,
                eventsActions.REPLACE_INACTIVE_EVENT_GENERAL,
                eventInfoActions.ADD_IN_FLIGHT_COLLAPSING_EVENTS,
                eventInfoActions.REMOVE_IN_FLIGHT_COLLAPSING_EVENTS,
            ],
            addAccordionEventItems
        ),
        takeLatest([actions.SET_EVENT_FILTER, actions.CLEAR_EVENT_FILTER], onFilterChanged),
        takeLatest(actions.CONFIGURE_EVENT_FILTERS, onConfigureEventFilters),
        takeEvery(actions.DESELECT_EVENTS_FROM_ACCORDION, removeDeselectedIfOnlySelected),
        takeEvery(actions.DELETE_EVENTS_FROM_DIMIDX_LIST, onDeleteEventsFromAccordion),
        takeEvery(actions.EXPORT_EVENT_DATA, onExportEventData),
        takeEvery(actions.EXPORT_SURFACE_DATA, onExportSurfaceData),
        takeEvery(actions.EXPORT_SAMPLING_POINTS, onExportSamplingPoints),
        takeEvery([accordionActions.EXPAND, accordionActions.COLLAPSE], onExpandCollapse),
        takeEvery(
            [accordionActions.EXPAND_ALL, accordionActions.COLLAPSE_ALL],
            onExpandCollapseAll
        ),
        takeLatest(eventInfoActions.CLOSE_EVENT_INFO, reloadEventsOnSave),
        takeEvery(eventInfoActions.SAVE_EVENT_INFO, expandItemOnSave),
        takeLatest(actions.LOAD_YIELD_CALIBRATION, onLoadYieldCalibration),
        takeEvery(actions.TOGGLE_YIELD_MODAL, onYieldModalToggle),
        takeEvery(
            [cdActions.CLEAR_ALL_SELECTED_FIELDS, cdActions.CLEAR_SELECTED_FIELDS],
            onClearFields
        ),
        takeEvery(actions.ACTIVATE_EVENT_FROM_DIMIDX, onActivateEventFromAccordion),
        takeLatest(recsEventsActions.BATCH_COPY_EVENTS, onBatchCopyEvents),
        takeEvery(
            recsEventsActions.DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID,
            onRecsEventsDestroyedEvents
        ),
        setupAccordionOnPanelActivated(),
        takeLatest(actions.UPDATE_SEARCH, onFilterChanged),
    ]);
};
