import React, { Component } from "react";

import { AutoSearch, DialogBox, DialogBoxFooterType, Loader, SelectInput } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";
import { SearchAPI } from "@ai360/core";
import { messages } from "../../../i18n-messages";
import _ from "lodash";

interface IRecord {
    customerGuid: string;
    customerName: string;
    farmName: string;
    fieldName: string;
    fieldGuid: string;
}

interface IProps {
    record: IRecord;
    isLoading: boolean;
    isLockCustomer: boolean;
    locked: boolean;
    onAddNewCustomer: () => void;
    onClose: (isCancel: boolean) => void;
    onSave: (data: any, callback: () => void) => void;
    removeImportFieldBoundary: () => void;
    setIsLoading: (isLoading: boolean) => void;
    userGuid: string;
    intl: any;
}

interface IState {
    editCustomerGuid: string;
    editFieldGuid: string;
    editCustomerName: string;
    editFarmName: string;
    editFieldName: string;
    searchValue: string;
    customerFarmOptions: any[];
    customerFieldOptions: any[];
}

export class AddEditNameMatchModal_ extends Component<IProps, IState> {
    static defaultProps = {
        locked: false,
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            editCustomerGuid: "",
            editFieldGuid: "",
            editCustomerName: "",
            editFarmName: "",
            editFieldName: "",
            searchValue: "",
            customerFarmOptions: [],
            customerFieldOptions: [],
        };
    }

    async componentDidMount() {
        const { customerGuid, customerName, farmName, fieldName, fieldGuid } = this.props.record;
        const { userGuid } = this.props;

        const useCustomerGuid =
            customerGuid == null
                ? await this.fetchCustomerGuid(customerName, userGuid)
                : customerGuid;

        await this.updateFieldState(
            useCustomerGuid,
            customerName,
            farmName,
            fieldGuid,
            fieldName,
            userGuid
        );
    }

    private canSaveEdits() {
        const { editCustomerGuid, editFieldGuid } = this.state;
        return editCustomerGuid && editFieldGuid;
    }

    private close(isCancel: boolean) {
        const { onClose, setIsLoading } = this.props;

        this.setState({
            editCustomerGuid: "",
            editCustomerName: "",
            editFarmName: "",
            editFieldName: "",
            customerFarmOptions: [],
        });
        setIsLoading(false);
        onClose(isCancel);
    }

    private save() {
        const { setIsLoading, onSave } = this.props;
        const { editCustomerName, editFarmName, editFieldName, editFieldGuid } = this.state;

        setIsLoading(true);
        const data = {
            editCustomerName,
            editFarmName,
            editFieldName,
            editFieldGuid,
        };

        onSave(data, () => this.close(false));
    }

    private updateCustomer(customer: SearchAPI.ILegacyCustomerResult) {
        if (!customer) {
            this.setState({
                editCustomerGuid: "",
                editCustomerName: "",
                customerFarmOptions: [],
            });
        } else if (customer.customerGuid !== this.state.editCustomerGuid) {
            const { customerGuid, customerName } = customer;
            const { userGuid } = this.props;
            this.updateFieldState(customerGuid, customerName, null, null, null, userGuid);
        }
    }

    private updateFarmName(editFarmName: string) {
        const { editCustomerGuid, editFieldGuid, editCustomerName } = this.state;
        const { userGuid } = this.props;
        this.updateFieldState(
            editCustomerGuid,
            editCustomerName,
            editFarmName,
            editFieldGuid,
            null,
            userGuid
        );
    }

    private updateFieldName(fieldGuid: string) {
        const { editCustomerGuid, editFarmName, editCustomerName } = this.state;
        const { userGuid } = this.props;
        const selectedField = this.state.customerFieldOptions.find((f) => f.value === fieldGuid);
        this.updateFieldState(
            editCustomerGuid,
            editCustomerName,
            editFarmName,
            fieldGuid,
            selectedField ? selectedField.label : null,
            userGuid
        );
    }

    private fetchCustomerOptions(userGuid: string, searchValue: string) {
        const { isLockCustomer } = this.props;
        const search = searchValue === "_" ? null : searchValue;
        return SearchAPI.getCustomers({
            userGuid,
            search,
            active: true,
            enrolled: isLockCustomer ? true : null,
            lastUsedCustomer: SearchAPI.LastUsedCustomer.Match,
        });
    }

    private async fetchCustomerGuid(name: string, userGuid: string) {
        const response = await SearchAPI.getCustomers({
            name,
            userGuid,
        });
        return response.length > 0 ? response[0].customerGuid : "";
    }

    private async updateFieldState(
        customerGuid: string,
        customerName: string,
        farmName: string,
        fieldGuid: string,
        fieldName: string,
        userGuid: string
    ) {
        const results =
            customerName != ""
                ? await SearchAPI.getFields({
                      customerGuid: customerGuid !== "" ? [customerGuid] : null,
                      customerName,
                      active: true,
                      userGuid,
                  })
                : [];
        const resultsToConsider = results.filter((x) => x.name != null && x.name !== "");
        const customerFieldOptions = _(resultsToConsider)
            .filter((x) => !farmName || x.farmName === farmName)
            .uniqBy((x) => x.name)
            .map((x) => ({
                label: x.name,
                value: x.id,
            }))
            .value();
        const customerFarmOptions = _(resultsToConsider)
            .filter(
                (x) => (!fieldGuid || x.id === fieldGuid) && (!fieldName || x.name === fieldName)
            )
            .uniqBy((x) => x.farmName)
            .map((x) => ({
                label: x.farmName,
                value: x.farmName,
            }))
            .value();
        const fieldsToConsider = resultsToConsider
            .filter((x) => !farmName || x.farmName === farmName)
            .filter((x) => !fieldGuid || x.id === fieldGuid)
            .filter((x) => !fieldName || x.name === fieldName);
        this.setState({
            customerFieldOptions,
            customerFarmOptions,
            editFieldGuid: fieldsToConsider.length === 1 ? fieldsToConsider[0].id : null,
            editFarmName: farmName,
            editCustomerGuid: customerGuid,
            editCustomerName: customerName,
        });
    }

    private onSearchChange(searchValue: string) {
        this.setState({ searchValue });
    }

    render() {
        const { editCustomerGuid, editFarmName, editFieldName, editFieldGuid } = this.state;
        const { record, locked, isLoading, userGuid } = this.props;
        const { customerName } = record;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <div className="edit-modal-container">
                    <DialogBox
                        action="save"
                        actionDisabled={!this.canSaveEdits()}
                        className="edit-field-modal"
                        closeOnClickOff={false}
                        closeOnEscape={false}
                        draggable
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        forceOverflow
                        hideCloseX
                        isOpen
                        isModal
                        onAction={this.save.bind(this)}
                        onClose={() => this.close(true)}
                        title={formatMessage(messages.editCustomerFieldText, {
                            count: record.fieldGuid ? 1 : 0,
                        })}
                    >
                        {isLoading ? <Loader /> : null}
                        <div className="edit-field-modal-body">
                            <AutoSearch
                                initialFilterStr={customerName}
                                getAutoSearchList={this.fetchCustomerOptions.bind(this)}
                                required={true}
                                disabled={locked}
                                itemList={[]}
                                selectedValue={editCustomerGuid}
                                onSelection={this.updateCustomer.bind(this)}
                                placeholderText={formatMessage(messages.customerName)}
                                keyProp={SearchAPI.Props.CUSTOMER_GUID}
                                nameProp={SearchAPI.Props.CUSTOMER_NAME}
                                secondaryPropList={[
                                    SearchAPI.Props.CUSTOMER_CITY,
                                    SearchAPI.Props.STATE_ABBR,
                                ]}
                                titlePropList={[
                                    SearchAPI.Props.CUSTOMER_NAME,
                                    SearchAPI.Props.CUSTOMER_STREET,
                                    SearchAPI.Props.CUSTOMER_CITY,
                                    SearchAPI.Props.STATE_ABBR,
                                ]}
                                noOptionsRenderer={null}
                                onSearchChange={this.onSearchChange.bind(this)}
                                autoFocus
                                openOnFocus={false}
                                userGuid={userGuid}
                            />
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                disabled={locked}
                                clearFilterInputOnBlur={false}
                                options={this.state.customerFarmOptions}
                                onChange={this.updateFarmName.bind(this)}
                                placeholderText={formatMessage(messages.farmNamePlaceholderText)}
                                value={editFarmName}
                                noOptionsRenderer={null}
                                initialFilterStr={editFarmName}
                                allowEmptyOptions
                            />
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                disabled={locked}
                                clearFilterInputOnBlur={false}
                                options={this.state.customerFieldOptions}
                                onChange={this.updateFieldName.bind(this)}
                                placeholderText={formatMessage(messages.fieldNamePlaceholderText)}
                                value={editFieldGuid}
                                noOptionsRenderer={null}
                                initialFilterStr={editFieldName}
                                required={true}
                            />
                        </div>
                    </DialogBox>
                </div>
            </div>
        );
    }
}
