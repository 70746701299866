import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import BaseMap from "@arcgis/core/Basemap";
import { BasemapUtils, MapConfig } from "@ai360/core";

interface IImageAttr {
    key: string;
    title: string;
    src: string;
}
interface IBasemapSelectorProps {
    basemap: string;
    onSetBasemap(basemap: string): void;
}
interface IBasemapSelectorState {
    expanded: boolean;
}
export class BasemapSelector extends Component<IBasemapSelectorProps, IBasemapSelectorState> {
    constructor(props: IBasemapSelectorProps) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    _expand(): void {
        this.setState({
            expanded: true,
        });
    }

    _collapse(): void {
        this.setState({
            expanded: false,
        });
    }

    _selectBasemap(basemap: string): void {
        this.props.onSetBasemap(basemap);
        this._collapse();
    }
    _getImgAttributes(basemap: BaseMap): IImageAttr {
        return {
            key: basemap.title,
            title: basemap.title,
            src: basemap.thumbnailUrl,
        };
    }

    render(): JSX.Element {
        const basemaps = [];
        if (this.state.expanded) {
            MapConfig.basemaps.forEach((basemap) => {
                const bm = BasemapUtils.getBasemapById(basemap.id);
                basemaps.push(
                    <img
                        alt={bm.title}
                        {...this._getImgAttributes(bm)}
                        onClick={() => this._selectBasemap(basemap.id)}
                    />
                );
            });
        } else {
            const bm = BasemapUtils.getBasemapById(this.props.basemap);
            basemaps.push(
                <img
                    alt={bm.title}
                    {...this._getImgAttributes(bm)}
                    onClick={() => this._expand()}
                />
            );
        }
        return (
            <TransitionGroup className="basemap-select-container">
                <CSSTransition
                    key="basemap-thumbs"
                    className="basemap-thumbnail"
                    timeout={{ enter: 0, exit: 0 }}
                >
                    <div>{basemaps}</div>
                </CSSTransition>
            </TransitionGroup>
        );
    }
}
