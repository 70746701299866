import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { picklistNames, selectors as picklistSelectors } from "~/core/picklist";

import { eventsSelectors, models as recsEventsModels, recsSelectors } from "~/recs-events";

import { RecEventItem } from "./rec-event-accordion-item";

const messages = defineMessages({
    batchCopyEventStrip: {
        id: "actionPanel.recEventCommon.accordion.batchCopyEventStrip",
        defaultMessage: "Copying {batchSize} Events",
    },
    batchEventStrip: {
        id: "actionPanel.recEventCommon.accordion.batchEventStrip",
        defaultMessage: "Batching {batchSize} Events",
    },
    batchRecStrip: {
        id: "actionPanel.recEventCommon.accordion.batchRecStrip",
        defaultMessage: "Batching {batchSize} Fields",
    },
});

export interface IBatchRecEventStrip_Props {
    batchSize: number;
    recEventDetailsTemplate?: Record<string, any>;
    intl: intlShape;
    isBatchCopy: boolean;
    picklistOptionsCroppingSeason?: Record<string, any>[];
}
export class BatchRecEventStrip_ extends PureComponent<IBatchRecEventStrip_Props> {
    render(): JSX.Element {
        const { batchSize, isBatchCopy, recEventDetailsTemplate, picklistOptionsCroppingSeason } =
            this.props;
        const { formatMessage } = this.props.intl;

        console.assert(recEventDetailsTemplate != null && batchSize > 0);

        const isEvent = recEventDetailsTemplate.agEventTypeList != null;

        let displayName = isEvent
            ? recEventDetailsTemplate.agEventTypeList.map((x) => x.typeName).join(" + ")
            : recEventDetailsTemplate.recType;

        const croppingSeasonOption = picklistOptionsCroppingSeason.find(
            (option) => option.value === recEventDetailsTemplate.croppingSeasonGuid
        );

        displayName += croppingSeasonOption != null ? ` - ${croppingSeasonOption.label}` : "";

        displayName += isEvent
            ? ` - ${recEventDetailsTemplate.momentStartDate.format("M/D/YY")}`
            : ` - ${recEventDetailsTemplate.momentCreatedDate.format("M/D/YY")}`;

        const summary = isEvent
            ? isBatchCopy
                ? formatMessage(messages.batchCopyEventStrip, { batchSize })
                : formatMessage(messages.batchEventStrip, { batchSize })
            : formatMessage(messages.batchRecStrip, { batchSize });

        return (
            <div className="item-container batch-strip">
                <RecEventItem isSelected displayName={displayName} summary={summary} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { fieldGuidToEventDetails, isBatchCopy } = eventsSelectors.getModuleState(state);
    const { fieldGuidToRecDetails } = recsSelectors.getModuleState(state);

    const fieldGuidToRecEventDetails = fieldGuidToEventDetails.has(
        recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
    )
        ? fieldGuidToEventDetails
        : fieldGuidToRecDetails;

    const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;
    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );

    const batchSize = Math.max(fieldGuidToRecEventDetails.size - 1, 0);
    const recEventDetailsTemplate = fieldGuidToRecEventDetails.get(
        recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
    );

    return {
        batchSize,
        recEventDetailsTemplate,
        picklistOptionsCroppingSeason,
        isBatchCopy,
    };
};

export const BatchRecEventStrip = connect(mapStateToProps)(injectIntl(BatchRecEventStrip_));
