import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { withMasked, withCrud, withNestedGrid, withApiResult } from "~/hocs";
import { actions, importProduct, service } from "./data";
import { preventBubbleUp } from "~/admin/utils";
import { messages } from "./../i18n-messages";

// Components
import AddEditPanel from "./add-edit/add-edit-container";
import { ProductLog } from "./product-log/product-log";
import ImportExportHeader from "../components/import-export-header";
import { ProductPackageList } from "./../product-package-list/product-package-list";
import { NestedDataTable, DialogBox, Button } from "~/core";
import SlidingPanel from "~/sliding-panel/sliding-panel";

export class Product extends Component {
    static propTypes = {
        actions: PropTypes.object,
        addEditPanel: PropTypes.object,
        closeSidePanel: PropTypes.func,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        onRowSelection: PropTypes.func,
        onSubmit: PropTypes.func,
        selectedGuid: PropTypes.string,
        setProductImportType: PropTypes.func,
        setLastEditedRow: PropTypes.func,
        toggleActiveInactive: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.tableFooterOptions = [];
        this.state = {
            isModalOpen: false,
            selectedRow: null,
            showInactive: false,
        };
        this.importCallbackFunction = null;
    }

    onToggleModalClick = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    onRowSelection = (event, selectedRow) => {
        this.setState({
            selectedRow: selectedRow === this.state.selectedRow ? null : selectedRow,
        });
        if (this.props.setLastEditedRow) {
            this.props.setLastEditedRow();
        }
    };

    toggleActiveInactive = () => {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            () => {
                this.props.toggleActiveInactive();
            }
        );
    };

    setImportCallback = (callback) => {
        this.props.setProductImportType(callback);
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        return (
            <div className="content-table-container">
                <ImportExportHeader
                    {...this.props}
                    isProduct={true}
                    service={service}
                    onToggleModalClick={this.onToggleModalClick}
                    toggleActiveInactive={this.toggleActiveInactive}
                    setImportCallback={this.setImportCallback}
                />
                <NestedDataTable
                    service={service}
                    messages={messages}
                    footerOptions={this.tableFooterOptions}
                    {...this.props}
                    onRowSelection={this.onRowSelection}
                    isCheckbox
                    isEditable
                    selectedRow={this.state.selectedRow}
                >
                    <ProductPackageList
                        toggleActiveInactive={this.props.toggleActiveInactive}
                        showInactiveProp={this.state.showInactive}
                        onRowSelection={this.onRowSelection}
                    />
                </NestedDataTable>
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "102",
                                childNameCode: "125",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={() => this.props.onSubmit()} />
                            <Button type="cancel" onClick={() => this.props.closeSidePanel()} />
                        </SlidingPanel>
                    </form>
                )}
                <DialogBox
                    className="view-log-dialog-box"
                    isOpen={this.state.isModalOpen}
                    draggable
                    unrestricted
                    onClose={() => this.onToggleModalClick()}
                    title={formatMessage(messages.productLogHistory)}
                >
                    <ProductLog needs={this.props.needs} />
                </DialogBox>
            </div>
        );
    }
}

export default injectIntl(
    withMasked(withApiResult(withNestedGrid(withCrud(Product, service, actions)), importProduct))
);
