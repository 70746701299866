import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { Checkbox, DialogBox, DialogBoxFooterType, Loader, LoaderTypes, SelectInput } from "~/core";
import { getTheUserGuid } from "~/login";
import { FileImportAPI } from "@ai360/core";

import { messages } from "../../../i18n-messages";

import "./export-controller-file-modal.css";
import { IGenericOption, IOption } from "~/core/components/select-input/model";
import {
    REC_TYPE_NAME_EQUATION_APPLICATION,
    REC_TYPE_NAME_MANUAL_APPLICATION,
    RecSummary,
} from "~/recs-events/recs/model";
import { IFileNameFormat } from "~/reports/data/interfaces";
import { logFirebaseEvent } from "~/utils/firebase";
import { ExportControllerCustomFilename } from "~/action-panel/components/rec-module/components/rec-list/components/dialog-boxes/export-controller-custom-filename";
import { isEqual } from "lodash";
import natsort from "natsort";

interface IProps {
    intl: intlShape;
    isOpen: boolean;
    onAction: (exportRecRequest: FileImportAPI.ExportRecRequest) => void;
    onClose: () => void;
    recGeneralGuidList: string[];
    recSummaries: RecSummary[];
    userGuid: string;
}

interface IControllerFileType {
    guid: string;
    sendThroughOnsite: boolean;
}

interface IState {
    controllerTypeOptions: IOption[];
    createLoadsheetYn: boolean;
    isLoading: boolean;
    mergeControllerFilesYn: boolean;
    multiProductYn: boolean;
    productFormatOptions: IFileNameFormat[];
    selectedControllerFileType: IControllerFileType | null;
    selectedControllerType: string | null;
    selectedExportFormat: string | null;
    validationWarnings: any[];
    controllerFileTypeOptions: IGenericOption<IControllerFileType>[];
    editFilenames: boolean;
    filenamesValid: boolean;
    lastFilenameResult: Partial<FileImportAPI.ExportRecRequest>;
    sortedSummaries: RecSummary[];
}

export class ExportControllerFileModal_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            controllerTypeOptions: [],
            createLoadsheetYn: false,
            isLoading: false,
            mergeControllerFilesYn: false,
            multiProductYn: false,
            productFormatOptions: [],
            selectedControllerFileType: null,
            selectedControllerType: null,
            selectedExportFormat: null,
            validationWarnings: [],
            controllerFileTypeOptions: [],
            editFilenames: false,
            filenamesValid: true,
            lastFilenameResult: null,
            sortedSummaries: [],
        };
    }

    private onAction() {
        const { onAction, recGeneralGuidList } = this.props;
        const {
            createLoadsheetYn,
            mergeControllerFilesYn,
            multiProductYn,
            selectedControllerFileType,
            selectedControllerType,
            selectedExportFormat,
            editFilenames,
            lastFilenameResult,
            filenamesValid,
        } = this.state;

        const isGeneric =
            this.state.controllerTypeOptions.find((x) => x.value === selectedControllerType)
                ?.label === "Generic" ?? false;

        const validationWarnings: string[] = [];

        if (mergeControllerFilesYn || selectedControllerFileType.sendThroughOnsite) {
            validationWarnings.push(...this.validateControllerFileMerge());
        }

        this.setState({
            validationWarnings,
        });

        if (validationWarnings.length > 0 || !filenamesValid) {
            return;
        }

        this.setState({ isLoading: true });

        if (editFilenames) {
            logFirebaseEvent("export_controller_edit_file_names");
        }

        const exportRecRequest = new FileImportAPI.ExportRecRequest(recGeneralGuidList);
        exportRecRequest.setProps({
            controllerFileTypeGuid: selectedControllerFileType.guid,
            controllerTypeGuid: selectedControllerType,
            fileNameFormatGuid: selectedExportFormat,
            loadsheetYn: createLoadsheetYn,
            mergeControllerFilesYn,
            multiProductYn,
            ...(isGeneric ? lastFilenameResult : {}),
        });
        onAction(exportRecRequest);
    }

    private fetchOptions(nextProps: IProps) {
        const { userGuid, recGeneralGuidList } = nextProps;

        const promises = [];
        promises.push(
            FileImportAPI.getControllerTypeList(userGuid).then((result) =>
                result.map((r) => ({
                    label: r.name,
                    value: r.guid,
                }))
            )
        );

        promises.push(FileImportAPI.getUserExportPreferences(userGuid));

        promises.push(
            FileImportAPI.getFileNameFormatList(userGuid).then((result) =>
                result.fileNameFormats.map((x) => ({
                    ...x,
                    name: x.name.replace("Customer", "Grower"),
                }))
            )
        );

        Promise.all(promises).then(([controllerTypeOptions, prefs, productFormatOptions]) => {
            const {
                controllerExportFormatGuid,
                controllerFileTypeGuid,
                controllerTypeGuid,
                createLoadsheetYn,
                mergeControllerFilesYn,
                multiProductYn,
            } = prefs;

            this.setState(
                {
                    controllerTypeOptions,
                    createLoadsheetYn,
                    mergeControllerFilesYn: recGeneralGuidList.length > 1 && mergeControllerFilesYn,
                    multiProductYn,
                    productFormatOptions,
                    selectedControllerType: controllerTypeGuid,
                    selectedExportFormat: controllerExportFormatGuid,
                    editFilenames: false,
                    lastFilenameResult: null,
                },
                () => {
                    this.onChangeControllerType(
                        controllerTypeGuid,
                        this.state.multiProductYn,
                        controllerFileTypeGuid
                    );
                }
            );
        });
    }

    private onChangeControllerType(
        newSelectedControllerType: string,
        multiProductYn = false,
        fileTypeGuid = false
    ) {
        const { selectedControllerType, selectedControllerFileType } = this.state;
        if (
            newSelectedControllerType === selectedControllerType &&
            selectedControllerFileType &&
            selectedControllerFileType.sendThroughOnsite
        ) {
            // if it's just a multiProductYn change and a filetype sent through onsite is already selected, don't reload the file type list
            this.setState({ isLoading: false });
            return;
        }

        const isGeneric =
            this.state.controllerTypeOptions.find((x) => x.value === selectedControllerType)
                ?.label === "Generic" ?? false;

        const { recGeneralGuidList } = this.props;
        this.setState(
            {
                selectedControllerType: newSelectedControllerType,
                editFilenames: isGeneric && this.state.editFilenames,
                isLoading: true,
            },
            () => {
                FileImportAPI.getControllerFileTypeList(
                    multiProductYn ? recGeneralGuidList : [],
                    newSelectedControllerType
                ).then((result) => {
                    const controllerFileTypeOptions = result.map((r) => ({
                        label: r.name,
                        value: {
                            guid: r.guid,
                            sendThroughOnsite: r.sendThroughOnsite,
                        },
                    }));
                    let selectedControllerFileType = null;
                    if (controllerFileTypeOptions.length === 1) {
                        selectedControllerFileType = controllerFileTypeOptions[0].value;
                    } else if (fileTypeGuid) {
                        selectedControllerFileType = controllerFileTypeOptions.filter(
                            (o) => o.value.guid === fileTypeGuid
                        )[0]?.value;
                    }
                    this.setState({
                        controllerFileTypeOptions,
                        isLoading: false,
                        selectedControllerFileType,
                    });
                });
            }
        );
    }

    private onChangeControllerFormat(selectedControllerFileType) {
        let { multiProductYn } = this.state;
        if (selectedControllerFileType.sendThroughOnsite) {
            multiProductYn = false;
        }

        this.setState({ selectedControllerFileType, multiProductYn });
    }

    private validateControllerFileMerge() {
        const { recSummaries } = this.props;
        const fields = new Set([...recSummaries.map((r) => r.fieldGuid)]);
        const recTypes = recSummaries.map((r) => r.recType);
        const warnings: string[] = [];
        if (fields.size !== recSummaries.length) {
            warnings.push(messages.exportValidationWarningOneRecPerField);
        }

        if (
            recTypes.some((r) => r.includes("Planting")) &&
            recTypes.some((r) => r.includes("Application"))
        ) {
            warnings.push(messages.exportValidationWarningOneRecType);
        }

        if (
            recTypes.some((r) => r === "Planting (Manual)") &&
            recTypes.some((r) => r === "Planting (Equation)")
        ) {
            warnings.push(messages.exportValidationWarningPlantingRecType);
        }

        return warnings;
    }

    private getValidationMessages() {
        const { validationWarnings } = this.state;
        const { formatMessage } = this.props.intl;

        return validationWarnings.map((warning) => {
            return (
                <div className="validation-message" key={warning.id}>
                    {formatMessage(warning)}
                </div>
            );
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (
            (nextProps.userGuid && this.props.userGuid !== nextProps.userGuid) ||
            (nextProps.isOpen && this.props.isOpen !== nextProps.isOpen)
        ) {
            this.setState(
                {
                    isLoading: true,
                    editFilenames: false,
                    filenamesValid: true,
                },
                () => this.fetchOptions(nextProps)
            );
        }

        if (!isEqual(nextProps.recSummaries, this.props.recSummaries)) {
            const naturalSorter = natsort({ insensitive: true });
            this.setState({
                sortedSummaries:
                    nextProps.recSummaries?.sort((l, r) => {
                        if (l == null || r == null) {
                            return 0;
                        }

                        // this works because it'll keep evaluating until it hits a non-zero value
                        return (
                            naturalSorter(l.customerName, r.customerName) ||
                            naturalSorter(l.farmName, r.farmName) ||
                            naturalSorter(l.fieldName, r.fieldName) ||
                            naturalSorter(l.recDate, r.recDate)
                        );
                    }) ?? [],
            });
        }
    }

    render() {
        const { recGeneralGuidList, isOpen, onClose, recSummaries } = this.props;
        const { formatMessage } = this.props.intl;
        const {
            controllerFileTypeOptions,
            controllerTypeOptions,
            createLoadsheetYn,
            isLoading,
            mergeControllerFilesYn,
            multiProductYn,
            productFormatOptions,
            selectedControllerFileType,
            selectedControllerType,
            selectedExportFormat,
            validationWarnings,
            editFilenames,
            filenamesValid,
        } = this.state;

        const isGenericType =
            controllerTypeOptions.find((x) => x.value === selectedControllerType)?.label ===
                "Generic" ?? false;

        const exportControllerFilesTitle = formatMessage(messages.exportControllerFile, {
            count: recGeneralGuidList.length,
        });
        const title =
            recGeneralGuidList.length > 1
                ? `${exportControllerFilesTitle} (${recGeneralGuidList.length})`
                : exportControllerFilesTitle;

        const sendThroughOnsite =
            (selectedControllerFileType && selectedControllerFileType.sendThroughOnsite) || false;

        const fileNameFormats = productFormatOptions.map((o) => {
            return { label: o.name, value: o.guid };
        });

        const exportFormat = fileNameFormats.find((o) => o.value === selectedExportFormat)
            ? selectedExportFormat
            : null;
        const exportFormatLabel = fileNameFormats.find(
            (o) => o.value === selectedExportFormat
        )?.label;

        const canEditFilenames =
            isGenericType &&
            recSummaries.some(
                (x) =>
                    x.recType === REC_TYPE_NAME_EQUATION_APPLICATION ||
                    x.recType === REC_TYPE_NAME_MANUAL_APPLICATION
            );

        const canExport =
            selectedControllerType != null &&
            selectedControllerFileType != null &&
            exportFormat != null &&
            filenamesValid;

        return (
            <DialogBox
                draggable
                action={formatMessage(messages.exportToFile)}
                actionDisabled={!canExport}
                className="export-controller-file-modal"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                isOpen={isOpen}
                onAction={() => this.onAction()}
                onClose={() => onClose()}
                title={title}
                unrestricted
            >
                <div className="row">
                    <SelectInput
                        clearable={false}
                        onChange={(value) => this.onChangeControllerType(value)}
                        options={controllerTypeOptions}
                        required
                        placeholderText="Controller Company"
                        value={selectedControllerType}
                    />
                    <SelectInput
                        clearable={false}
                        onChange={(value) => this.onChangeControllerFormat(value)}
                        options={controllerFileTypeOptions}
                        required
                        placeholderText="Controller Format"
                        value={selectedControllerFileType}
                    />
                </div>
                <div className="row">
                    <div className="checkboxes">
                        <Checkbox
                            label="Loadsheet"
                            onChange={(e, createLoadsheetYn) =>
                                this.setState({ createLoadsheetYn })
                            }
                            value={createLoadsheetYn}
                        />
                        <Checkbox
                            label="Multi-Product File"
                            onChange={(e, multiProductYn) => {
                                this.setState({ multiProductYn });
                                this.onChangeControllerType(
                                    this.state.selectedControllerType,
                                    multiProductYn
                                );
                            }}
                            value={multiProductYn}
                        />
                        {!sendThroughOnsite && recGeneralGuidList.length > 1 && (
                            <Checkbox
                                label="Merge Files"
                                onChange={(e, mergeControllerFilesYn) =>
                                    this.setState({ mergeControllerFilesYn })
                                }
                                value={mergeControllerFilesYn}
                            />
                        )}
                        {canEditFilenames && (
                            <Checkbox
                                label="Edit File Names"
                                value={editFilenames}
                                onChange={(_, editFilenames) => this.setState({ editFilenames })}
                            />
                        )}
                    </div>
                    <SelectInput
                        clearable={false}
                        onChange={(selectedExportFormat) => this.setState({ selectedExportFormat })}
                        options={fileNameFormats}
                        required={true}
                        placeholderText="File Name Format"
                        value={exportFormat}
                    />
                </div>
                {canEditFilenames && (
                    <ExportControllerCustomFilename
                        showInputs={this.state.editFilenames}
                        updateState={(lastFilenameResult, filenamesValid) =>
                            this.setState({ lastFilenameResult, filenamesValid })
                        }
                        exportFormat={exportFormatLabel}
                        recSummaries={this.state.sortedSummaries}
                        isGenericType={isGenericType}
                        mergeFiles={mergeControllerFilesYn}
                        multiProduct={multiProductYn}
                    />
                )}

                {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
                <DialogBox
                    title={formatMessage(messages.exportValidationWarningTitle, {
                        count: validationWarnings.length,
                    })}
                    isOpen={validationWarnings.length > 0}
                    onClose={() => this.setState({ validationWarnings: [] })}
                >
                    {<div>{this.getValidationMessages()}</div>}
                </DialogBox>
            </DialogBox>
        );
    }
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

const ExportControllerFileModal = connect(mapStateToProps)(injectIntl(ExportControllerFileModal_));
export default ExportControllerFileModal;
