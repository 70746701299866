import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { apiUrl, EquipmentProfileAPI, PicklistAPI } from "@ai360/core";
import { TrashcanIcon } from "~/core/icons";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    AutoSearch,
    Bucket,
    BucketHeader,
    DateInput,
    Loader,
    SelectInput,
    TextArea,
    TextInput,
} from "~/core";

import {
    actions as recsEventsActions,
    recsModels,
    recsSelectors,
    selectors as recsEventsSelectors,
} from "~/recs-events";

import { getTheUserGuid } from "~/login";
import { PersonAPI } from "@ai360/core";

import { adminData, GUID, NAME } from "~/admin/data";
import { fetchDropdownData } from "~/core/dropdowns/actions";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { getModuleState, getDropdownState, getEquationsLoading } from "../selectors";
import * as actions from "../actions";
import { getSetValuesForErrorCodeList } from "../../../../common/validation-utils";

import "../../../../common/rec-event-info/rec-event-info.css";

import "./rec-general-form.css";
import {
    RecDetails,
    REC_TYPE_NAME_EQUATION_PLANTING,
    REC_TYPE_NAME_MANUAL_PLANTING,
} from "~/recs-events/recs/model";

const messages = defineMessages({
    recNamePlaceholderText: {
        id: "recModule.recInfo.recNamePlaceholderText",
        defaultMessage: "Rec Name",
    },
    generalInfoBucketTitle: {
        id: "recModule.recInfo.generalInfoBucketTitle",
        defaultMessage: "General Information",
    },
    timingInfoBucketTitle: {
        id: "recModule.recInfo.timingInfoBucketTitle",
        defaultMessage: "Timing Info",
    },
    cropInfoBucketTitle: {
        id: "recModule.recInfo.cropInfoBucketTitle",
        defaultMessage: "Crop Info",
    },
    seasonPlaceholderText: {
        id: "recModule.recInfo.seasonPlaceholderText",
        defaultMessage: "Season",
    },
    cropCyclePlaceholderText: {
        id: "recModule.recInfo.cropCyclePlaceholderText",
        defaultMessage: "Crop Cycle",
    },
    createdDatePlaceholderText: {
        id: "recModule.recInfo.createdDatePlaceholderText",
        defaultMessage: "Date",
    },
    peopleBucketTitle: {
        id: "recModule.recInfo.peopleBucketTitle",
        defaultMessage: "People",
    },
    findPeoplePlaceholderText: {
        id: "recModule.recInfo.findPeoplePlaceholderText",
        defaultMessage: "Find People",
    },
    generalNotesBucketTitle: {
        id: "recModule.recInfo.generalNotesBucketTitle",
        defaultMessage: "General Notes",
    },
    notesPlaceholderText: {
        id: "recModule.recInfo.notesPlaceholderText",
        defaultMessage: "Notes",
    },
    cropPlaceholderText: {
        id: "recModule.recInfo.cropPlaceholderText",
        defaultMessage: "Crop",
    },
    cropPurposePlaceholderText: {
        id: "recModule.recInfo.cropPurposePlaceholderText",
        defaultMessage: "Crop Purpose",
    },
    brandPlaceholderText: {
        id: "recModule.recInfo.brandPlaceholderText",
        defaultMessage: "Brand/Organization",
    },
    varietyPlaceholderText: {
        id: "recModule.recInfo.varietyPlaceholderText",
        defaultMessage: "Variety/Hybrid",
    },
    plantingDatePlaceholderText: {
        id: "recModule.recInfo.plantingDatePlaceholderText",
        defaultMessage: "Crop Planting Date",
    },
    equipmentBucketTitle: {
        id: "recModule.recInfo.equipmentBucketTitle",
        defaultMessage: "Equipment",
    },
    findEquipmentPlaceholderText: {
        id: "recModule.recInfo.findEquipmentPlaceholderText",
        defaultMessage: "Find Equipment",
    },
});

const {
    getPickListCode,
    PICKLIST_CROP_PURPOSE,
    PICKLIST_CROPPING_SEASON,
    PICKLIST_CROPPING_SEASON_CYCLE,
} = picklistNames;

const errorCodeToMessageIdSetMap = new Map([
    [2899, [messages.recNamePlaceholderText, messages.createdDatePlaceholderText]], // ErrorCode.RecommendationNameDuplicated
    [498, null], // ErrorCode.RecAreaRequired
    [510, messages.recNamePlaceholderText], // ErrorCode.RecommendationNameRequired
    [518, null], // ErrorCode.RecommendationTypeRequired
    [519, messages.createdDatePlaceholderText], // ErrorCode.RecommendationCreatedDateRequired
    [80, messages.seasonPlaceholderText], // ErrorCode.AgEventCropSeasonRequired
    [2814, null], // ErrorCodes.FieldBoundaryMissingForWholeFieldZone
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

interface RecGeneralFormProps {
    recDetails: RecDetails;
    cropList: any[];
    brandList: any[];
    varietyHybridList: Record<string, any>[];
    isEquationsLoading: boolean;
    onFetchPicklists: (picklistFetchArgObj) => void;
    onRefreshDropdowns: (refreshDropdowns) => void;
    onUpdateRecDetails: (newProps) => void;
    picklistOptionsCropPurpose: PicklistAPI.IPicklistItem[];
    picklistOptionsCroppingSeason: PicklistAPI.IPicklistItem[];
    picklistOptionsCroppingSeasonCycle: PicklistAPI.IPicklistItem[];
    intl: intlShape;
    userGuid: string;
    saveRecDetailsErrorCodeList: number[];
    setGeneralAdjustments: () => void;
    updateRecSeasonDependencies: (newSeasonOptLabel: string) => void;
}

export const RecGeneralForm_ = (props: RecGeneralFormProps): JSX.Element => {
    const {
        recDetails,
        cropList,
        brandList,
        varietyHybridList,
        isEquationsLoading,
        onFetchPicklists,
        onRefreshDropdowns,
        onUpdateRecDetails,
        picklistOptionsCropPurpose,
        picklistOptionsCroppingSeason,
        picklistOptionsCroppingSeasonCycle,
        intl,
        userGuid,
        saveRecDetailsErrorCodeList,
        setGeneralAdjustments,
        updateRecSeasonDependencies,
    } = props;
    const { formatMessage } = intl;

    const [errorMessagePlaceholderSet, setErrorMessagePlaceholderSet] = useState<Set<any>>(
        new Set()
    );
    const [haveSetDefaultValues, setHaveSetDefaultValues] = useState<boolean>(false);

    useEffect(() => {
        setErrorMessagePlaceholderSet(_getErrorMessagePlaceholderSet());

        const fetchPicklistNames = [
            PICKLIST_CROP_PURPOSE,
            PICKLIST_CROPPING_SEASON,
            PICKLIST_CROPPING_SEASON_CYCLE,
        ];

        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = picklistNames.getPickListCode(key);
            return accum;
        }, {});

        onFetchPicklists(picklistFetchArgObj);

        if (
            recDetails != null &&
            recDetails.recType === recsModels.REC_TYPE_NAME_MANUAL_APPLICATION
        ) {
            refreshCropDropdownLists(recDetails.cropGuid, recDetails.brandOrganizationGuid, true);
        }
    }, []);

    useEffect(() => {
        setErrorMessagePlaceholderSet(_getErrorMessagePlaceholderSet());
    }, [saveRecDetailsErrorCodeList]);

    useEffect(() => {
        const setDefaultValues =
            !haveSetDefaultValues && recDetails != null && picklistOptionsCroppingSeason.length > 0;

        if (setDefaultValues) {
            setHaveSetDefaultValues(true);
            _setDefaultCroppingSeason();
        }
    }, [picklistOptionsCroppingSeason]);

    const refreshCropDropdownLists = (
        cropGuid: string,
        brandOrganizationGuid: string,
        isInit = false
    ) => {
        if (!cropGuid) {
            onRefreshDropdowns({
                cropList: { url: apiUrl("AgBytes/GetCropDropdownList") },
            });
        } else {
            const refreshDropdowns = {
                cropList: undefined,
                brandList: undefined,
                varietyHybridList: undefined,
            };
            if (isInit) {
                refreshDropdowns.cropList = {
                    url: apiUrl("AgBytes/GetCropDropdownList"),
                };
            }
            refreshDropdowns.brandList = {
                url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
                model: cropGuid,
            };
            if (brandOrganizationGuid) {
                refreshDropdowns.varietyHybridList = {
                    url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
                    model: {
                        cropId: cropGuid,
                        brandOrganization: brandOrganizationGuid,
                    },
                };
            }
            onRefreshDropdowns(refreshDropdowns);
        }
    };

    const _updateRecDetails = (newProps) => {
        if (newProps.cropGuid != null) {
            newProps.cropPurposeGuid = "";
            newProps.brandOrganizationGuid = "";
            newProps.varietyHybridGuid = "";
            refreshCropDropdownLists(newProps.cropGuid, "");
        } else if (newProps.brandOrganizationGuid != null) {
            newProps.varietyHybridGuid = "";
            refreshCropDropdownLists(recDetails.cropGuid, newProps.brandOrganizationGuid);
        }

        if (
            (recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_PLANTING) &&
            newProps.croppingSeasonGuid &&
            newProps.croppingSeasonGuid !== recDetails.croppingSeasonGuid
        ) {
            newProps.recAreaList = recDetails.recAreaList.map((recArea) => {
                return recsModels.RecArea.updateRecArea(recArea, {
                    recs: recArea.recs.map((rec) => {
                        return recsModels.Rec.updateRec(rec, {
                            isRecDirty: true,
                        });
                    }),
                });
            });
            // Also need to (possibly) update the default event selection
            const newSeasonOpt = picklistOptionsCroppingSeason.find(
                (option) => option.value === newProps.croppingSeasonGuid
            );
            updateRecSeasonDependencies(newSeasonOpt?.label);
        }
        setGeneralAdjustments();
        onUpdateRecDetails(newProps);
    };

    const _setDefaultCroppingSeason = () => {
        console.assert(recDetails != null && picklistOptionsCroppingSeason != null);
        if (!recDetails.croppingSeasonGuid) {
            const curYearStr = String(new Date().getFullYear());
            const defaultOption = picklistOptionsCroppingSeason.find(
                (option) => option.label === curYearStr
            );
            _updateRecDetails({
                croppingSeasonGuid: defaultOption && defaultOption.value,
            });
        }
    };

    const _getOptionList = (options = [], valuekey = GUID, labelKey = NAME) => {
        return options.map((option) => ({
            value: option[valuekey],
            label: option[labelKey],
            activeYn:
                option[adminData.PROPS_ACTIVE_YN] !== undefined
                    ? option[adminData.PROPS_ACTIVE_YN]
                    : true,
        }));
    };

    const _getErrorMessagePlaceholderSet = () => {
        return getSetValuesForErrorCodeList(
            saveRecDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    };

    const getRelatedAgEventTypeName = () => {
        const { recType } = recDetails;
        const isPlanting =
            recType === REC_TYPE_NAME_MANUAL_PLANTING ||
            recType === REC_TYPE_NAME_EQUATION_PLANTING;
        return isPlanting ? "Planting" : "Application";
    };

    const _addPerson = (person) => {
        const { personList } = recDetails;
        if (
            person &&
            !personList.some((listPerson) => listPerson.personGuid === person.personGuid)
        ) {
            _updateRecDetails({
                personList: [...recDetails.personList, person],
            });
        }
    };

    const _addEquipment = (equipment) => {
        const { equipmentList } = recDetails;
        if (
            equipment &&
            !equipmentList.some(
                (listEquipment) =>
                    listEquipment.equipmentProfileGuid === equipment.equipmentProfileGuid
            )
        ) {
            _updateRecDetails({
                equipmentList: [...recDetails.equipmentList, equipment],
            });
        }
    };

    const _removeEquipment = (index) => {
        const { equipmentList } = recDetails;
        const newEquipmentList = [...equipmentList];
        newEquipmentList.splice(index, 1);
        _updateRecDetails({ equipmentList: newEquipmentList });
    };

    const _removePerson = (index) => {
        const { personList } = recDetails;
        const newPersonList = [...personList];
        newPersonList.splice(index, 1);

        _updateRecDetails({ personList: newPersonList });
    };

    const getAutoSearchList = (
        userGuid,
        searchText
    ): Promise<EquipmentProfileAPI.IEquipmentProfile[]> => {
        const relatedAgEventTypeName = getRelatedAgEventTypeName();
        return EquipmentProfileAPI.searchEquipmentWithName(userGuid, {
            agEventTransactionTypeName: [relatedAgEventTypeName],
            searchText,
        });
    };

    const getToolTip = (equipment) => {
        let tooltiptext = `${equipment.equipmentParentTypeName} - ${equipment.equipmentType} - ${equipment.equipmentName} - ${equipment.ownerOperatorName}`;
        if (equipment.controllerId.length > 0) {
            tooltiptext = tooltiptext + " - " + equipment.controllerId;
        }
        return tooltiptext;
    };

    const _getPersonInfoPart = () => {
        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.peopleBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <AutoSearch
                        clearOnSelection={true}
                        required={false}
                        itemList={[]}
                        initialFilterStr=""
                        onSelection={(p) => _addPerson(p)}
                        getAutoSearchList={PersonAPI.searchPersonWithUser}
                        userGuid={userGuid}
                        placeholderText={formatMessage(messages.findPeoplePlaceholderText)}
                        keyProp="personGuid"
                        nameProp="personName"
                        secondaryPropList={["city", "stateAbbreviation", "jobTitleName"]}
                        excludedValues={recDetails.personList.map((person) => person.personGuid)}
                    />
                </div>
                <div className="input-row">
                    <div className="person-list">
                        {recDetails.personList.map((person, index) => {
                            return (
                                <div key={`person-${index}`} className="person-item">
                                    <div
                                        className="trashcan-icon-container"
                                        onClick={() => {
                                            _removePerson(index);
                                        }}
                                    >
                                        <TrashcanIcon />
                                    </div>
                                    <div className="person-info">{`${person.personName}`}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Bucket>
        );
    };

    const _getTimingInfoPart = () => {
        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.timingInfoBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <SelectInput
                        required
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        containerClassNames={[
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    messages.seasonPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) => _updateRecDetails({ croppingSeasonGuid: v })}
                        options={picklistOptionsCroppingSeason}
                        placeholderText={formatMessage(messages.seasonPlaceholderText)}
                        value={recDetails.croppingSeasonGuid}
                    />
                    <SelectInput
                        onChange={(v) =>
                            _updateRecDetails({
                                croppingSeasonCycleGuid: v,
                            })
                        }
                        optionIsHiddenKey={ACTIVE_YN}
                        options={picklistOptionsCroppingSeasonCycle}
                        placeholderText={formatMessage(messages.cropCyclePlaceholderText)}
                        value={recDetails.croppingSeasonCycleGuid}
                    />
                </div>
                <div className="input-row half">
                    <DateInput
                        required
                        clearDisabled
                        containerClassNames={[
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.createdDatePlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) =>
                            _updateRecDetails({
                                momentCreatedDate: moment(v),
                            })
                        }
                        openDirection="left"
                        timeFormat={false}
                        placeholderText={formatMessage(messages.createdDatePlaceholderText)}
                        value={recDetails.momentCreatedDate}
                    />
                </div>
            </Bucket>
        );
    };

    const _getCropInfoPart = () => {
        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.cropInfoBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <SelectInput
                        options={_getOptionList(cropList, "cropGuid", "cropName")}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.cropPlaceholderText)}
                        onChange={(v) => _updateRecDetails({ cropGuid: v || "" })}
                        value={recDetails.cropGuid}
                    />
                    <SelectInput
                        options={picklistOptionsCropPurpose}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.cropPurposePlaceholderText)}
                        onChange={(v) => _updateRecDetails({ cropPurposeGuid: v })}
                        value={recDetails.cropPurposeGuid}
                    />
                </div>
                <div className="input-row">
                    <SelectInput
                        options={_getOptionList(brandList)}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.brandPlaceholderText)}
                        disabled={!(brandList.length > 0 && recDetails.cropGuid)}
                        onChange={(v) =>
                            _updateRecDetails({
                                brandOrganizationGuid: v || "",
                            })
                        }
                        value={recDetails.brandOrganizationGuid}
                    />
                    <SelectInput
                        options={_getOptionList(varietyHybridList)}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        placeholderText={formatMessage(messages.varietyPlaceholderText)}
                        disabled={!(brandList.length > 0 && recDetails.brandOrganizationGuid)}
                        onChange={(v) => _updateRecDetails({ varietyHybridGuid: v })}
                        value={recDetails.varietyHybridGuid}
                    />
                </div>
                <div className="input-row half">
                    <DateInput
                        openDirection={"left"}
                        value={
                            recDetails.cropPlantingDate ? moment(recDetails.cropPlantingDate) : null
                        }
                        onChange={(v) => _updateRecDetails({ cropPlantingDate: v })}
                        placeholderText={formatMessage(messages.plantingDatePlaceholderText)}
                    />
                </div>
            </Bucket>
        );
    };

    const _getEquipmentInfoPart = () => {
        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.equipmentBucketTitle)}</BucketHeader>
                {recDetails.canEditEquipment ? (
                    <div className="input-row">
                        <AutoSearch
                            clearOnSelection={true}
                            required={false}
                            itemList={[]}
                            initialFilterStr=""
                            onSelection={(p) => _addEquipment(p)}
                            getAutoSearchList={getAutoSearchList}
                            userGuid={userGuid}
                            placeholderText={formatMessage(messages.findEquipmentPlaceholderText)}
                            keyProp="equipmentProfileGuid"
                            nameProp="equipmentName"
                            secondaryPropList={[
                                "equipmentParentTypeName",
                                "equipmentType",
                                "ownerOperatorName",
                            ]}
                            excludedValues={recDetails.equipmentList.map(
                                (e) => e.equipmentProfileGuid
                            )}
                        />
                    </div>
                ) : null}
                <div className="input-row">
                    <div className="equipment-list">
                        {recDetails.equipmentList.map((equipment, index) => {
                            return (
                                <div
                                    key={`equipment-${index}`}
                                    className="equipment-item"
                                    title={getToolTip(equipment)}
                                >
                                    {recDetails.canEditEquipment ? (
                                        <div
                                            className="equipment-trashcan-icon-container"
                                            onClick={() => {
                                                _removeEquipment(index);
                                            }}
                                        >
                                            <TrashcanIcon />
                                        </div>
                                    ) : (
                                        <div className="empty-container"></div>
                                    )}
                                    <div className="equipment-info">
                                        {equipment.equipmentParentTypeName + " - "}{" "}
                                        {equipment.equipmentName}{" "}
                                        {equipment.controllerId.length > 0
                                            ? " - " + equipment.controllerId
                                            : ""}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Bucket>
        );
    };

    if (!recDetails) {
        return null;
    }
    return (
        <div className="rec-event-info-form rec-general-form">
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.generalInfoBucketTitle)}</BucketHeader>
                <div className="input-row half">
                    <TextInput
                        maxLength={50}
                        autoFocus={!recDetails.name}
                        containerClassNames={[
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.recNamePlaceholderText
                                ),
                            },
                        ]}
                        value={recDetails.name}
                        onChange={(v) => _updateRecDetails({ name: v })}
                        placeholderText={formatMessage(messages.recNamePlaceholderText)}
                    />
                </div>
            </Bucket>
            {_getTimingInfoPart()}
            {recDetails.recType !== recsModels.REC_TYPE_NAME_MANUAL_APPLICATION
                ? null
                : _getCropInfoPart()}
            {_getPersonInfoPart()}
            {_getEquipmentInfoPart()}
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.generalNotesBucketTitle)}</BucketHeader>
                <TextArea
                    maxLength={4000}
                    value={recDetails.notes}
                    onChange={(v) => _updateRecDetails({ notes: v })}
                    placeholderText={formatMessage(messages.notesPlaceholderText)}
                />
            </Bucket>
            {isEquationsLoading &&
            (recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recDetails.recType === recsModels.REC_TYPE_NAME_EQUATION_PLANTING) ? (
                <Loader className="equation-rec-loader" />
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    const { recSummary, isLoading } = getModuleState(state);
    const { fieldGuidToRecDetails, saveRecDetailsErrorCodeList } =
        recsSelectors.getModuleState(state);
    const { batchFieldGuid } = recsEventsSelectors.getZonesState(state);

    const fieldGuid =
        batchFieldGuid != null ? batchFieldGuid : recSummary != null ? recSummary.fieldGuid : null;
    const recDetails = !isLoading ? fieldGuidToRecDetails.get(fieldGuid) : null;

    const dropdownData = getDropdownState(state);

    const picklistOptionsCropPurpose = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROP_PURPOSE)
    );
    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );
    const picklistOptionsCroppingSeasonCycle = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON_CYCLE)
    );

    return {
        recDetails,
        fieldGuid,
        picklistOptionsCropPurpose,
        picklistOptionsCroppingSeason,
        picklistOptionsCroppingSeasonCycle,
        ...dropdownData,
        saveRecDetailsErrorCodeList,
        userGuid: getTheUserGuid(state),
        isEquationsLoading: getEquationsLoading(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onRefreshDropdowns: (dropdowns) => {
        dispatch(
            fetchDropdownData({
                ...dropdowns,
                action: actions.fetchedDropdownData,
                async: false,
            })
        );
    },
    onUpdateRecDetails: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateRecDetails(fieldGuid, newProps)),
    setGeneralAdjustments: () => dispatch(actions.setGeneralAdjustments(true)),
    updateRecSeasonDependencies: (fieldGuid, croppingSeasonName) =>
        dispatch(actions.updateRecSeasonDependencies(fieldGuid, croppingSeasonName)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateRecDetails: (newProps) =>
        dispatchProps.onUpdateRecDetails(stateProps.fieldGuid, newProps),
    updateRecSeasonDependencies: (croppingSeasonName) =>
        dispatchProps.updateRecSeasonDependencies(stateProps.fieldGuid, croppingSeasonName),
});

export const RecGeneralForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecGeneralForm_));
