/** react & basic imports */
import React, { Component } from "react";
import PropTypes from "prop-types";

/** grouped library imports */
import { injectIntl, intlShape } from "react-intl";

/** ~ imports */
import { DataTable, Button } from "~/core";
import { withMasked, withApiResult, withCrud } from "~/hocs";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { preventBubbleUp } from "~/admin/utils";
/** relative imports */
import AddEditPanel from "./add-edit/add-edit-container";
import { service, actions, model } from "./data";
import { messages } from "../i18n-messages";

/** css imports */
import "./user-role.css";

export class UserRole_ extends Component {
    static propTypes = {
        actions: PropTypes.object,
        addEditPanel: PropTypes.object,
        closeSidePanel: PropTypes.func,
        deleteSelected: PropTypes.func,
        fetchRecords: PropTypes.func,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        openAddEditPanel: PropTypes.func,
        onSubmit: PropTypes.func,
        records: PropTypes.array,
    };

    /** constructor */
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            records: [],
            copyRole: false,
            copyRoleMode: false,
        };
    }

    /** public functions */
    deleteSelected = (options) => {
        let { selectedItems } = options;
        selectedItems = selectedItems[0];
        options = { ...options, selectedItems };
        this.props.deleteSelected(options);
    };

    onCancel = () => {
        const { copyRoleMode, modelName, recordGuid, recordName } = this.state;
        if (copyRoleMode) {
            this.props.openAddEditPanel(modelName, "EDIT", recordGuid, recordName);
        } else {
            this.props.closeSidePanel();
        }
    };

    onCopy = () => {
        this.setState(
            {
                copyRole: true,
                copyRoleMode: true,
            },
            () => this.props.openAddEditPanel(model.PROPS_USER_ROLE_NAME, "ADD")
        );
    };

    openAddEditPanel = (modelName, mode, recordGuid, recordName) => {
        this.setState({ modelName, mode, recordGuid, recordName }, () => {
            const { mode, recordGuid, recordName, modelName } = this.state;
            this.props.openAddEditPanel(modelName, mode, recordGuid, recordName);
        });
    };

    onToggleModalClick = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    toggleCopyRole = (copyRole) => this.setState({ copyRole });

    toggleCopyRoleMode = (copyRoleMode) => this.setState({ copyRoleMode });

    /** Life cycle functions */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.records !== this.props.records) {
            this.setState({
                records: nextProps.records,
            });
        }
    }

    render() {
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        const { toggleCopyRole, toggleCopyRoleMode } = this;
        const { copyRole, copyRoleMode } = this.state;
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
                copyRole,
                copyRoleMode,
                toggleCopyRole,
                toggleCopyRoleMode,
            };
        }
        return (
            <div className="content-table-container user-role-table">
                <DataTable
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    {...this.props}
                    deleteSelected={this.deleteSelected}
                    records={this.state.records}
                    openAddEditPanel={this.openAddEditPanel}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "121",
                            }}
                        >
                            <Button
                                type="save"
                                forceSubmit
                                onClick={() =>
                                    this.setState({ copyRole: false }, () => this.props.onSubmit())
                                }
                            />
                            {this.props.addEditPanel.mode === "EDIT" ? (
                                <Button type="copyRole" onClick={() => this.onCopy()} />
                            ) : null}
                            <Button type="cancel" onClick={this.onCancel} />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}
export const UserRole = injectIntl(
    withMasked(withApiResult(withCrud(UserRole_, service, actions), actions.importData))
);
