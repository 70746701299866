import { createAction } from "redux-act";

export const FETCH_SYSTEM_SETTINGS = "FETCH_SYSTEM_SETTINGS_DATA";
export const FETCH_SYSTEM_SETTINGS_FAILED = "FETCH_SYSTEM_SETTINGS_FAILED";
export const FETCH_SYSTEM_SETTINGS_SUCCESS = "FETCH_SYSTEM_SETTINGS_SUCCESS";

export const UPDATE_SYSTEM_SETTINGS = "UPDATE_SYSTEM_SETTINGS_DATA";
export const UPDATE_SYSTEM_SETTINGS_FAILED = "UPDATE_SYSTEM_SETTINGS_FAILED";
export const UPDATE_SYSTEM_SETTINGS_SUCCESS = "UPDATE_SYSTEM_SETTINGS_SUCCESS";

export const fetchSystemSettings = createAction(FETCH_SYSTEM_SETTINGS);
export const fetchSystemSettingsFailed = createAction(FETCH_SYSTEM_SETTINGS_FAILED);
export const fetchSystemSettingsSuccess = createAction(FETCH_SYSTEM_SETTINGS_SUCCESS);

export const updateSystemSettings = createAction(UPDATE_SYSTEM_SETTINGS);
export const updateSystemSettingsFailed = createAction(UPDATE_SYSTEM_SETTINGS_FAILED);
export const updateSystemSettingsSuccess = createAction(UPDATE_SYSTEM_SETTINGS_SUCCESS);
