import PropTypes from "prop-types";

import { model } from "~/admin/setup/user-role/data";

export const shape = {
    [model.PROPS_ACROSS_CUSTOMERS]: PropTypes.bool,
    [model.PROPS_ACTIVE_IN_ACTIVE]: PropTypes.bool,
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_ACCESS_LEVEL]: PropTypes.number,
    [model.PROPS_AGBYTES]: PropTypes.bool,
    [model.PROPS_AGBYTES_COUNTRY_STATE]: PropTypes.bool,
    [model.PROPS_AGBYTES_CROPS_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_EQUATION_PARAMETER_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_EQUATION_SET_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_EQUATION_SET_PLANTING_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_GENES]: PropTypes.bool,
    [model.PROPS_AGBYTES_GROWTH_STAGES]: PropTypes.bool,
    [model.PROPS_AGBYTES_NUTRIENTS]: PropTypes.bool,
    [model.PROPS_AGBYTES_NUTRIENT_REMOVE]: PropTypes.bool,
    [model.PROPS_AGBYTES_NUTRIENT_TARGETS]: PropTypes.bool,
    [model.PROPS_AGBYTES_OBSERVATIONS]: PropTypes.bool,
    [model.PROPS_AGBYTES_PICKLIST_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_PRODUCTS_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_SAMPLE_ATTRBUTES_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_TRAITS]: PropTypes.bool,
    [model.PROPS_AGBYTES_UNITS_SETUP]: PropTypes.bool,
    [model.PROPS_AGBYTES_VARIETY_HYBRID]: PropTypes.bool,
    [model.PROPS_AGBYTES_VARIETY_HYBRID_ATTRIBUTES]: PropTypes.bool,
    [model.PROPS_AGEVENTS]: PropTypes.bool,
    [model.PROPS_AGEVENTS_APPLICATION]: PropTypes.bool,
    [model.PROPS_AGEVENTS_CUSTOM]: PropTypes.bool,
    [model.PROPS_AGEVENTS_EC_DATA]: PropTypes.bool,
    [model.PROPS_AGEVENTS_HARVEST]: PropTypes.bool,
    [model.PROPS_AGEVENTS_IRRIGATION]: PropTypes.bool,
    [model.PROPS_AGEVENTS_PLANTING]: PropTypes.bool,
    [model.PROPS_AGEVENTS_TILLAGE]: PropTypes.bool,
    [model.PROPS_AGR_INELLIGENCE_REPORTS]: PropTypes.bool,
    [model.PROPS_ANALYSIS_LAYERS]: PropTypes.bool,
    [model.PROPS_BATCH]: PropTypes.bool,
    [model.PROPS_BATCH_EVENTS]: PropTypes.bool,
    [model.PROPS_BATCH_RECS]: PropTypes.bool,
    [model.PROPS_COMBINE_FIELDS]: PropTypes.bool,
    [model.PROPS_COMMUNITY_DATA]: PropTypes.bool,
    [model.PROPS_COMPANY_GUID]: PropTypes.string,
    [model.PROPS_CUSTOMER_ADD]: PropTypes.bool,
    [model.PROPS_CUSTOMER_DELETE]: PropTypes.bool,
    [model.PROPS_CUSTOMER_EDIT]: PropTypes.bool,
    [model.PROPS_CUSTOMER_SALESPERSON]: PropTypes.bool,
    [model.PROPS_DESCRIPTION]: PropTypes.string,
    [model.PROPS_EQUATION_APPLICATION]: PropTypes.bool,
    [model.PROPS_EQUIPMENT]: PropTypes.bool,
    [model.PROPS_ERROR_MESSAGE]: PropTypes.object,
    [model.PROPS_EXPORTS]: PropTypes.bool,
    [model.PROPS_EXPORT_EVENTS]: PropTypes.bool,
    [model.PROPS_EXPORT_FIELD_BOUNDARY]: PropTypes.bool,
    [model.PROPS_EXPORT_RECS]: PropTypes.bool,
    [model.PROPS_EXPORT_SAMPLING]: PropTypes.bool,
    [model.PROPS_EXPORT_SURFACES]: PropTypes.bool,
    [model.PROPS_FERTILIZER]: PropTypes.bool,
    [model.PROPS_FIELD_REPORTS]: PropTypes.bool,
    [model.PROPS_FINANCIALS]: PropTypes.bool,
    [model.PROPS_IMPORT_EXPORT]: PropTypes.bool,
    [model.PROPS_IMPORT_FILTERS]: PropTypes.bool,
    [model.PROPS_IMPORT_FILTERS_ASSIGN_TO_ALL]: PropTypes.bool,
    [model.PROPS_MANAGE_FIELDS]: PropTypes.bool,
    [model.PROPS_MANUAL_APPLICATION]: PropTypes.bool,
    [model.PROPS_MANUAL_PLANTING]: PropTypes.bool,
    [model.PROPS_MANURE_COMPOST]: PropTypes.bool,
    [model.PROPS_NAME]: PropTypes.string,
    [model.PROPS_NEMATODE]: PropTypes.bool,
    [model.PROPS_PERSON_IMPORT_EXPORT]: PropTypes.bool,
    [model.PROPS_PERSON_SALESPERSON]: PropTypes.bool,
    [model.PROPS_RECOMMENDATIONS]: PropTypes.bool,
    [model.PROPS_REPORT_WIZARD]: PropTypes.bool,
    [model.PROPS_SCOUTING]: PropTypes.bool,
    [model.PROPS_SETUP]: PropTypes.bool,
    [model.PROPS_SETUP_CUSTOMER_PROFILE]: PropTypes.bool,
    [model.PROPS_SETUP_EQUIPMENT]: PropTypes.bool,
    [model.PROPS_SETUP_HIERARCHY]: PropTypes.bool,
    [model.PROPS_SETUP_ADMIN_SCRIPTS]: PropTypes.bool,
    [model.PROPS_SETUP_HIERARCHY_ZAPPER]: PropTypes.bool,
    [model.PROPS_SETUP_OWNER_HIERARCHY]: PropTypes.bool,
    [model.PROPS_SETUP_PERSON_PROFILE]: PropTypes.bool,
    [model.PROPS_SETUP_UI_LABLES]: PropTypes.bool,
    [model.PROPS_SETUP_USER_ROLE]: PropTypes.bool,
    [model.PROPS_SETUP_USER_SETUP]: PropTypes.bool,
    [model.PROPS_SETUP_USER_IMPORT_EXPORT]: PropTypes.bool,
    [model.PROPS_SOIL]: PropTypes.bool,
    [model.PROPS_SUMMARY_REPORTS]: PropTypes.bool,
    [model.PROPS_SYSTEM_OWNER]: PropTypes.bool,
    [model.PROPS_SYSTEM_SETTINGS]: PropTypes.bool,
    [model.PROPS_TISSUE]: PropTypes.bool,
    [model.PROPS_USER_ROLE_GUID]: PropTypes.string,
    [model.PROPS_VENDOR_SETUP]: PropTypes.bool,
    [model.PROPS_VENDOR_TESTING_LAB]: PropTypes.bool,
    [model.PROPS_WATER]: PropTypes.bool,
    [model.PROPS_WORK_ORDERS]: PropTypes.bool,
    [model.PROPS_ZONE_INTERPOLATION]: PropTypes.bool,
    [model.PROPS_ZONE_SAMPLING]: PropTypes.bool,
};

export default PropTypes.shape(shape);
