import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CATEGORY = "category";
export const PROPS_CATEGORY_GUID = "picklistCategoryGuid";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_PICKLIST_CATEGORY = "category";
export const PROPS_PICKLIST_GUID = "picklistGuid";
export const PROPS_PICKLIST_ID = "picklistId";
export const PROPS_PICKLIST_LOGIC = "logic";
export const PROPS_PICKLIST_LOGIC_TYPE = "logicType";
export const PROPS_PICKLIST_NAME = "picklistName";
export const PROPS_PICKLIST_NAME_GUID = "picklistNameGuid";
export const PROPS_PICKLIST_PARENT_GUID = "parentPicklistValueGuid";
export const PROPS_PICKLIST_PARENT_ID = "parentPicklistId";
export const PROPS_PICKLIST_PARENT_NAME = "parentPicklistName";
export const PROPS_PICKLIST_PARENT_VALUE = "parentPicklistValue";
export const PROPS_PICKLIST_VALUE = "value";
export const PROPS_PICKLIST_VALUE_GUID = "picklistValueGuid";
export const PROPS_PICKLIST_VALUE_ID = "picklistValueId";
export const PROPS_VALUE_ID = "valueId";
