import { createReducer } from "redux-act";
import { setProductImportType } from "./actions";

export const PRODUCT_IMPORT_STATE_KEY = "PRODUCT_IMPORT_STATE";

export const productImportReducer = createReducer(
    {
        [setProductImportType]: (state, payload) => {
            return {
                ...state,
                importType: payload,
            };
        },
    },
    {
        importType: null,
    }
);
