import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { setApiResult, resetApiResult } from "~/core/api/actions";
import { getApiError } from "~/core/api/selectors";
import { messages } from "~/i18n-messages";
import { errorsToKeyedDetails, MergeOperation } from "~/utils/api/new-api";
import "./with-validate-import-results.css";

const WithValidateImportResults = (View, actionCallback = null) => {
    class WrapperComponent extends Component {
        static propTypes = {
            intl: intlShape.isRequired,
            apiError: PropTypes.object,
            actionCallback: PropTypes.func,
            needs: PropTypes.func,
            resetApiResult: PropTypes.func,
            setApiResult: PropTypes.func,
        };

        constructor(props) {
            super(props);
            this.state = {
                results: null,
            };

            this._title = this.props.intl.formatMessage(messages.validateImportResultsTitle);
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            const results = nextProps.apiError.validateImportResults;
            if (results === undefined || results === null) {
                return;
            }

            this.setState({
                results: results,
            });
        }

        componentWillUnmount() {
            this.props.resetApiResult();
        }

        render() {
            if (!this.state.results) {
                return (
                    <div className="content-table-container with-validate-import-result">
                        <View {...this.props} />
                    </div>
                );
            }

            const details = this._resultsToDetails(this.state.results);

            const onAction = () => {
                this.setState(
                    {
                        results: null,
                    },
                    () => {
                        if (actionCallback) {
                            this.props.needs([actionCallback()]);
                        }
                    }
                );
                this._resetApiResult();
            };

            const onClose = () => {
                this.setState({
                    results: null,
                });
                this._resetApiResult();
            };

            return (
                <div className="content-table-container with-validate-import-result">
                    <DialogBox
                        isOpen={true}
                        footerType={DialogBoxFooterType.IMPORT_CANCEL}
                        onAction={onAction}
                        onClose={onClose}
                        title={this._title}
                    >
                        <div>
                            Adds: {details.addCount}. Updates: {details.updateCount}. Failures:{" "}
                            {details.failureCount}. Total: {details.totalCount}.
                        </div>
                        <hr />
                        <div className="import-results-container">
                            {this._failureListRender(details.failures)}
                        </div>
                    </DialogBox>
                    <View {...this.props} />
                </div>
            );
        }

        _failureListRender(failures) {
            if (failures.length === 0) {
                return null;
            }

            return (
                <div>
                    <ul className="block-list">
                        {failures.map(([key, items]) => (
                            <li className="failure-block" key={key}>
                                <h1>{key}</h1>
                                <ul className="failure-item-list">
                                    {items.map((item, index) => (
                                        <li className="block-item" key={index}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }

        _resultsToDetails(results) {
            const categories = this._resultsToCategories(results);

            return {
                addCount: categories.adds.length,
                updateCount: categories.updates.length,
                failureCount: categories.failures.length,
                totalCount:
                    categories.adds.length + categories.updates.length + categories.failures.length,
                failures: errorsToKeyedDetails(
                    categories.failures.flatMap((x) => x.errors),
                    this.props.intl
                ),
            };
        }

        _resultsToCategories(results) {
            const { adds, updates, failures } = results.reduce(
                (accumulator, singleResult) => {
                    const pushTo =
                        singleResult.operation === MergeOperation.Failure
                            ? accumulator.failures
                            : singleResult.operation === MergeOperation.Add
                            ? accumulator.adds
                            : accumulator.updates;
                    pushTo.push(singleResult);
                    return accumulator;
                },
                {
                    adds: [],
                    updates: [],
                    failures: [],
                }
            );

            return {
                adds: adds,
                updates: updates,
                failures: failures,
            };
        }

        _resetApiResult() {
            this.props.setApiResult({
                errorCodeList: [],
                model: {},
                apiResultObj: {},
                validateImportResults: undefined,
            });
        }
    }

    const mapStateToProps = (state) => {
        return {
            apiError: getApiError(state),
        };
    };
    const mapDispatchToProps = (dispatch) => ({
        setApiResult: (payload) => dispatch(setApiResult(payload)),
        resetApiResult: (payload) => dispatch(resetApiResult(payload)),
        actionCallback: (payload) => actionCallback(payload),
    });
    return connect(mapStateToProps, mapDispatchToProps)(injectIntl(WrapperComponent));
};

export default WithValidateImportResults;
