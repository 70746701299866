import React, { Component } from "react";
import { NumericInput, DialogBox, DialogBoxFooterType, NoLink, ZeroToInfiniteGrid } from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
const PROPS_AGRIAN_ID_ITEM = "agrianId";

export interface AgrianListProps {
    record?: Record<string, any>;
    formatMessage?: (message: string) => string;
    renderDeleteModal?: () => void;
    toggleModal?: (message: string, toggle: boolean) => void;
    onChange?: (obj: Record<string, any>) => void;
    onAdd?: () => void;
    onEdit?: (record: Record<string, any>) => void;
    onDelete?: (record: Record<string, any>) => void;
    onAddOrEditItem?: () => void;
    deleteItem?: () => void;
    isEditing?: boolean;
    isDeleting?: boolean;
    editData?: Record<string, any>;
}

export interface AgarianListState {
    editingItem: boolean;
}

class AgrianList extends Component<AgrianListProps, AgarianListState> {
    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
        };
    }

    onEdit = (record, index) => {
        const keysToUpdate = [PROPS_AGRIAN_ID_ITEM];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };
    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };
    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={!editData[PROPS_AGRIAN_ID_ITEM]}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.agrianID
                )}
            >
                <NumericInput
                    scale={0}
                    precision={50}
                    tabIndex={0}
                    suppressFormatting={true}
                    required
                    autoFocus
                    placeholderText={formatMessage(messages.agrianID)}
                    labelText={formatMessage(messages.agrianID)}
                    value={formData[PROPS_AGRIAN_ID_ITEM]}
                    onChange={(value) => onChange({ [PROPS_AGRIAN_ID_ITEM]: value })}
                />
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_AGRIAN_ID_ITEM]: {
                                title: formatMessage(messages.agrianIdHeader),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.agrianID)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(AgrianList);
