export const importErrorFileName = "ImportErrors";

const CreateService = (serviceObject) => {
    function getModelName() {
        return `${serviceObject.modelName}Data`;
    }
    function getImportErrorsFileName() {
        if (serviceObject.importErrorFileName) {
            return serviceObject.importErrorFileName;
        }
        return importErrorFileName;
    }
    function getEditModelName() {
        return `${serviceObject.modelName}EditData`;
    }
    function getDefaultColumns() {
        return Object.entries(serviceObject._defaultLabels).map((item) => item[0]);
    }
    function getLabelValue(attr, messages, formatMessage) {
        return formatMessage(messages[serviceObject._defaultLabels[attr].label]);
    }
    function getParentGuid(data) {
        if (serviceObject.REQUEST_PAYLOAD_PARENT_GUID) {
            return data[serviceObject.REQUEST_PAYLOAD_PARENT_GUID];
        }
        return null;
    }
    function getVisible(attr) {
        return serviceObject._defaultLabels[attr].visible;
    }
    function getClassName(attr) {
        if (serviceObject._defaultLabels[attr] && serviceObject._defaultLabels[attr].className) {
            return serviceObject._defaultLabels[attr].className;
        }
        return null;
    }

    function isFooterButtonDisabled(disableIfNotSelected, selectedItems) {
        if (serviceObject.isFooterButtonDisabled) {
            return serviceObject.isFooterButtonDisabled(disableIfNotSelected, selectedItems);
        }
        if (disableIfNotSelected && selectedItems.length === 0) {
            return true;
        }
        return false;
    }

    function printLabelValue(data, attr) {
        if (serviceObject.printer && serviceObject.printer[attr]) {
            return serviceObject.printer[attr](data[attr]);
        }
        const value = data[attr];
        if (Array.isArray(value)) {
            return value.join(", ");
        } else if (typeof value === "boolean") {
            return value ? "Yes" : "No";
        }
        return data[attr];
    }

    function toggleColumnVisibility(attr, forcedVal) {
        if (forcedVal !== undefined) {
            serviceObject._defaultLabels[attr].visible = forcedVal;
        } else {
            serviceObject._defaultLabels[attr].visible =
                !serviceObject._defaultLabels[attr].visible;
        }
        return serviceObject;
    }

    function isItemSelected(item, record) {
        const { guid } = serviceObject;
        if (typeof record === "object") {
            if (typeof item === "object") {
                return record[guid] === item[guid];
            }
            return record.guid === item;
        }

        const selectedItem = item[guid];
        if (selectedItem) {
            return selectedItem === record[guid];
        }
        return record === item;
    }

    function getDefaultGuid(record) {
        if (serviceObject.getGuid) {
            return serviceObject.getGuid(record);
        } else {
            return record[serviceObject.guid];
        }
    }

    function getGridColSize(attr) {
        if (serviceObject._defaultLabels[attr] && serviceObject._defaultLabels[attr].gridCol) {
            return serviceObject._defaultLabels[attr].gridCol;
        }
        return null;
    }

    function getColumnSortOverRide(attr) {
        if (serviceObject._defaultLabels[attr] && serviceObject._defaultLabels[attr]) {
            return serviceObject._defaultLabels[attr].sortNameOverRide;
        }
        return null;
    }

    function compareRecords(record1, record2) {
        if (typeof record1 === "object" && typeof record2 === "object") {
            return this.isItemSelected(record1, record2);
        }
        return record1 === record2;
    }

    function isRowSelected({ selectedRow, currentRecord, lastSelectedRow = null }) {
        return selectedRow && Object.keys(selectedRow).length !== 0
            ? this.compareRecords(
                  this.getDefaultGuid(selectedRow),
                  this.getDefaultGuid(currentRecord)
              )
            : lastSelectedRow && Object.keys(lastSelectedRow).length !== 0
            ? this.compareRecords(
                  this.getDefaultGuid(lastSelectedRow),
                  this.getDefaultGuid(currentRecord)
              )
            : false;
    }

    const servicedObject = {
        ...serviceObject,
        compareRecords,
        getClassName,
        getColumnSortOverRide,
        getDefaultColumns,
        getDefaultGuid,
        getEditModelName,
        getGridColSize,
        getImportErrorsFileName,
        getLabelValue,
        getModelName,
        getParentGuid,
        getVisible,
        isItemSelected,
        isFooterButtonDisabled,
        isRowSelected,
        printLabelValue,
        toggleColumnVisibility,
    };
    return servicedObject;
};

export default CreateService;
