import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_ADDRESS_LIST = "addressList";
export const PROPS_ADDRESS_TYPE_LIST = "addressTypeList";
export const PROPS_ADD_EMAIL_VISIBLE = "addEmailVisible";
export const PROPS_ADD_USER_ACCOUNT_VISIBLE = "addUserAccountVisible";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_COUNTRIES_LIST = "countriesList";
export const PROPS_COUNTRY_GUID = "countryGuid";
export const PROPS_COUNTRY_NAME = "countryName";
export const PROPS_CUSTOMER_AFFILIATION_LIST = "customerAffilitaionList";
export const PROPS_DATE_OF_BIRTH = "dateOfBirth";
export const PROPS_DELETE_USER = "deleteUser";
export const PROPS_EMAIL = "theEmail";
export const PROPS_EMAIL_ADDRESS = "emailAddress";
export const PROPS_EMAIL_LIST = "personEmailList";
export const PROPS_EMPLOYEE_GUID = "employeeGuid";
export const PROPS_END_DATE = "endDate";
export const PROPS_GENDER = "gender";
export const PROPS_IS_ACTIVE = adminData.PROPS_IS_ACTIVE;
export const PROPS_IS_PRIMARY = "isPrimary";
export const PROPS_IS_USER = "isUser";
export const PROPS_LICENSE_LIST = "personLicenseList";
export const PROPS_LOGIN_EMAIL = "loginEmail";
export const PROPS_LOGIN_GUID = "loginGuid";
export const PROPS_MIDDLE_NAME = "middleName";
export const PROPS_NOTES = "notes";
export const PROPS_ORG_LEVEL_GUID = "orgLevelGuid";
export const PROPS_ORG_LEVEL_LIST = adminData.PROPS_ORG_LEVEL_LIST;
export const PROPS_PAYRATE = "payRate";
export const PROPS_PAY_RATE_UNIT_GUID = "payRateUnitGuid";
export const PROPS_PAY_TYPE_GUID = "payTypeGuid";
export const PROPS_PERSON_ADDRESS_LIST = "personAddressList";
export const PROPS_PERSON_ALIAS_GUID = "personAliasGuid";
export const PROPS_PERSON_AUTO_REPORT_LIST = "personAutoReportList";
export const PROPS_PERSON_CITY = "city";
export const PROPS_PERSON_FULL_NAME = "fullName";
export const PROPS_PERSON_GUID = "personGuid";
export const PROPS_PERSON_NAME = "personName";
export const PROPS_PERSON_ORG_LIST = "personOrgLevelList";
export const PROPS_PERSON_URL_LIST = "personUrlList";
export const PROPS_PHONE_NUMBER_LIST = "personPhoneNumberList";
export const PROPS_RENDER_USER_PASSWORD = "resendUserPassword";
export const PROPS_START_DATE = "startDate";
export const PROPS_STATES_LIST = "stateList";
export const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;
export const PROPS_SUFFIX = "suffix";
export const PROPS_SUPERVISOR_GUID = "supervisorGuid";
export const PROPS_SUPERVISOR_NAME = "supervisorName";
export const PROPS_TIME_ZONE_GUID = "timeZoneGuid";
export const PROPS_TIME_ZONE_LIST = "timezoneList";
export const PROPS_TITLE_GUID = "titleGuid";
export const PROPS_USER_ACCOUNT_PASSWORD = "resetUserAccountPassword";
export const PROPS_USER_ACTIVEYN = "userActiveYn";
export const PROPS_USER_CUSTOMER_LIST = "userCustomerList";
export const PROPS_USER_FIRST_NAME = "firstName";
export const PROPS_USER_GUID = "userGuid";
export const PROPS_USER_ID = "userID";
export const PROPS_USER_JOB = "jobTitleName";
export const PROPS_USER_JOB_GUID = "jobTitleGuid";
export const PROPS_USER_LAST_NAME = "lastName";
export const PROPS_USER_NAME = "fullName";
export const PROPS_USER_ROLE = "userRoleName";
export const PROPS_USER_ROLES = "userRoles";
export const PROPS_USER_ROLE_GUID = "userRoleGuid";
export const PROPS_USER_ROLE_LIST = "userRoleList";
export const PROPS_USER_ROLE_MATRIX_LIST = "userRoleMatrixList";
export const PROPS_USER_SALES_PERSON = "personSalespersonList";
export const PROPS_USER_STATE = "state";
export const PROPS_USER_STATE_ABBR = "stateAbbr";
export const PROPS_USER_TYPE = "userTypeName";
export const PROPS_USER_TYPE_GUID = "userTypeGuid";
