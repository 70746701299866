import { defineMessages } from "react-intl";

export const messages = defineMessages({
    inactiveTabText: {
        id: "actionPanel.filterTabs.inactiveTabText",
        defaultMessage: "Inactive",
    },
    activeTabText: {
        id: "actionPanel.filterTabs.activeTabText",
        defaultMessage: "Active",
    },
    allTabText: {
        id: "actionPanel.filterTabs.allTabText",
        defaultMessage: "All",
    },
    selectedTabText: {
        id: "actionPanel.filterTabs.selectedTabText",
        defaultMessage: "Selected",
    },
    tooManyFieldsWarning: {
        id: "actionPanel.filterTabs.tooManyFieldsWarning",
        defaultMessage: "Warning: Only showing results for {count} of {total} selected fields.",
    },
});
