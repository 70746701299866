import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { injectIntl, intlShape } from "react-intl";
import { TextArea } from "~/core";
import classnames from "classnames";
import { messages } from "../../i18n-messages";

import "react-quill/dist/quill.snow.css";
import "./text-editor.css";

export interface ITextEditorProps {
    containerClassName?: string;
    customToolbar?: () => void;
    formats?: Array<string>;
    modules?: Record<string, any>;
    onChange?: (event: any) => void;
    placeholder?: string;
    value?: string;
    showRaw?: string;
    rawValue?: string;
    readOnly?: boolean;
    tabIndex?: number;
    className?: string;
}

export interface ITextEditorState {
    value: string;
    showRaw: boolean;
    rawValue: string;
    quillContent: any;
}

export class TextEditor extends Component<ITextEditorProps, ITextEditorState> {
    formats: Array<string>;
    modules: Record<string, unknown>;

    public UNSAFE_componentWillReceiveProps = (nextProps: ITextEditorProps): void => {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value });
        }
    };

    public constructor(props: ITextEditorProps) {
        super(props);
        this.state = {
            quillContent: props.value || "",
            value: props.value || "",
            showRaw: false,
            rawValue: props.value || "",
        };
        this.modules = props.modules || {
            toolbar: {
                container: "#toolbar",
                handlers: {},
            },
        };
        this.formats = props.formats || [
            "header",
            "bold",
            "italic",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
        ];
    }
    // unused params, removing them could work if all usages are changed
    public handleChange = (value: string, delta, source, editor: Record<string, any>): void => {
        this.setState({ value });
        if (this.props.onChange) {
            // Sends empty string when the editor has empty value
            this.props.onChange(editor.getText().length === 1 ? "" : value);
        }
    };

    public onClickRaw = (): void => {
        this.setState({
            showRaw: !this.state.showRaw,
            rawValue: this.state.value,
        });
    };

    public onRawChange = (value: string): void => {
        this.setState({
            value: value,
            rawValue: value,
        });
    };

    render(): JSX.Element {
        return (
            <div
                className={classnames(
                    { "show-raw": this.state.showRaw },
                    this.props.containerClassName
                )}
            >
                {this.props.customToolbar ? (
                    this.props.customToolbar()
                ) : (
                    <CustomToolbar onClickRaw={this.onClickRaw} />
                )}
                <ReactQuill
                    tabIndex={this.props.tabIndex}
                    readOnly={this.props.readOnly}
                    theme="snow"
                    onChange={this.handleChange}
                    value={this.state.value}
                    formats={this.formats}
                    modules={this.modules}
                    placeholder={this.props.placeholder || ""}
                />
                <TextArea
                    containerClassNames={["raw-editor"]}
                    value={this.state.rawValue}
                    onChange={(value: string): void => this.onRawChange(value)}
                    showTopLabel={false}
                    maxLength={50000}
                />
            </div>
        );
    }
}

const CustomToolbar_ = ({ onClickRaw, intl: { formatMessage } }) => (
    <div id="toolbar">
        <select className="ql-header" title={formatMessage(messages.header)}>
            <option value="1" />
            <option value="2" />
            <option selected />
        </select>
        <button className="ql-bold" title={formatMessage(messages.bold)} />
        <button className="ql-italic" title={formatMessage(messages.italic)} />
        <button className="ql-strike" title={formatMessage(messages.strike)} />
        <button className="ql-blockquote" title={formatMessage(messages.blockquote)} />
        <button className="ql-list" value="ordered" title={formatMessage(messages.orderList)} />
        <button className="ql-list" value="bullet" title={formatMessage(messages.unorderList)} />
        <button className="ql-link" title={formatMessage(messages.link)} />
        <button className="ql-image" title={formatMessage(messages.image)} />
        <button className="ql-clean" title={formatMessage(messages.clearFormat)} />
        <button onClick={onClickRaw} title={formatMessage(messages.viewHtml)}>
            <span className="view-html">R</span>
        </button>
    </div>
);

CustomToolbar_.propTypes = {
    onClickRaw: PropTypes.func,
    intl: intlShape.isRequired,
};
const CustomToolbar = injectIntl(CustomToolbar_);
