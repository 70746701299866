import { selectors } from "~/core/units";

export const getUnits = (keys, state) => {
    const unitData = selectors.getUnitsState(state);
    let newData = {};
    if (unitData && keys) {
        for (const key of Object.keys(keys)) {
            newData = { ...newData, [key]: unitData[keys[key]] };
        }
    }
    return newData;
};

export const getUnit = (key, state) => selectors.getUnitPicklistFromCode(state, key);
