import { createReducer } from "redux-act";
import { fetchedPicklistData, clearPicklistState } from "./actions";
import _ from "lodash";

export const PICKLIST_DATA_KEY = "PICKLIST_DATA";

export const picklistReducer = createReducer(
    {
        [fetchedPicklistData]: (state, payload) => ({
            ...state,
            ...payload,
        }),
        [clearPicklistState]: (state, payload) => {
            return _.omit(state, [String(payload)]);
        },
    },
    {}
);
