import { connect } from "react-redux";
import { HarvestEventForm as HarvestEventFormView } from "./harvest-event-form";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import {
    actions as picklistActions,
    selectors as picklistSelectors,
    picklistNames,
} from "~/core/picklist";
import { actions as unitActions, selectors as unitSelectors, unitNames } from "~/core/units";
import { fetchedDropdownData } from "../../actions";
import * as selectors from "../../selectors";
import { actions as recsEventsActions } from "~/recs-events";
import * as actions from "../../actions";
import { messages } from "./i18n-messages";
import { HarvestIcon } from "../icons";
import { keywords } from "../../../../keywords";

const mapStateToProps = (state) => ({
    baleType: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_BALE_TYPE)
    ),
    brandOrgData: selectors.getBrandOrgState(state),
    cottonGrades: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_COTTON_COLOR_GRADE)
    ),
    cropData: selectors.getCropDropdownState(state),
    cropPurpose: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
    ),
    dryYieldUnit: picklistSelectors.getRawPicklistOptionsFromCode(state, keywords.dryYieldUnit),
    lintYieldUnit: picklistSelectors.getRawPicklistOptionsFromCode(state, keywords.lintYieldUnit),
    overallRating: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_NUMERIC_RATING)
    ),
    priceUnit: picklistSelectors.getRawPicklistOptionsFromCode(state, keywords.priceUnit),
    varietyHybridData: selectors.getVarietyHybridDropdownState(state),
    yieldUnit: unitSelectors.getRawUnitPicklistFromCode(
        state,
        unitNames.getUnitCode(unitNames.UNIT_YIELD)
    ),
});

const mapDispatchToProps = (dispatch) => ({
    fetchDropdownData: (dropdowns) =>
        dispatch(fetchDropdownData({ ...dropdowns, action: fetchedDropdownData })),
    fetchPicklistData: (payload) => dispatch(picklistActions.fetchPicklistData(payload)),
    fetchSystemAttributePicklist: (payload) =>
        dispatch(actions.fetchSystemAttributePicklist(payload)),
    fetchUnitData: (payload) => dispatch(unitActions.fetchUnitData(payload)),
    onUpdateCurrentAgEventAreaAgEventModel: (fieldGuid, agEventTransactionTypeGuid, newProps) =>
        dispatch(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                fieldGuid,
                agEventTransactionTypeGuid,
                newProps
            )
        ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onUpdateCurrentAgEventAreaAgEventModel: (newProps) =>
            dispatchProps.onUpdateCurrentAgEventAreaAgEventModel(
                ownProps.fieldGuid,
                ownProps.agEventTransactionTypeGuid,
                newProps
            ),
    };
};

export const formLabelMessage = messages.harvestFormLabelText;
export const formLabelIcon = HarvestIcon;

export const HarvestEventForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(HarvestEventFormView);
