import React, { Component } from "react";

import { TreeView, Checkbox } from "~/core";
import { IRecordObject } from "~/core/components/tree-view/tree-view";

interface ICategoryTreeProps {
    defaultNoMatch?: boolean;
    formatMessage?: (id: string) => string;
    onStatusChange?: (categories: Record<string, any>) => void;
    categories?: Record<string, any>;
    categoryDataSet?: IRecordObject[];
}

interface ICategoryTreeState {
    categories: Record<string, any>;
    dataSet: IRecordObject[];
}

export class CategoryTree extends Component<ICategoryTreeProps, ICategoryTreeState> {
    static defaultProps = {
        defaultNoMatch: false,
    };

    constructor(props: ICategoryTreeProps) {
        super(props);
        const { categories, categoryDataSet } = props;
        for (const cat of Object.keys(categories)) {
            if (!this._dataSetContainsCategory(categoryDataSet, cat)) {
                categories[cat] = props.defaultNoMatch;
            }
        }
        this.state = {
            categories,
            dataSet: categoryDataSet,
        };
    }

    _dataSetContainsCategory(dataSet, catId) {
        if (dataSet == null || catId == null) {
            return false;
        }
        if (dataSet.id === catId) {
            return true;
        }
        if (Array.isArray(dataSet) || Array.isArray(dataSet.children)) {
            const dSet = Array.isArray(dataSet) ? dataSet : dataSet.children;
            for (const data of dSet) {
                if (this._dataSetContainsCategory(data, catId)) {
                    return true;
                }
            }
        }
        return false;
    }

    onStatusChange = (node, value) => {
        const { nodeIndex } = node;
        const { categories, dataSet } = this.state;
        categories[node.id] = value;
        // NOTE: This assumes only 1-level deep category representation ... any deeper will require
        // refactoring to utilize a recursive algorithm
        if (nodeIndex.includes(".children.")) {
            const parentIndex = nodeIndex.split(".children.")[0];
            const parentNode = dataSet[parentIndex];
            if (!categories[parentNode.id]) {
                categories[parentNode.id] = value;
            }
        } else {
            node.children.forEach((child) => {
                const { id } = child;
                categories[id] = value;
            });
        }
        this.setState(
            {
                categories,
            },
            () => {
                if (this.props.onStatusChange) {
                    this.props.onStatusChange(this.state.categories);
                }
            }
        );
    };

    renderCheckbox = (node) => {
        return (
            <Checkbox
                value={this.state.categories[node.id] || node.partialSelect}
                onChange={(evt, value) => this.onStatusChange(node, value)}
            />
        );
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.categories) !== JSON.stringify(this.props.categories)) {
            this.setState({
                categories: nextProps.categories,
            });
        }
    }

    render() {
        return (
            <div className="category-tree">
                <TreeView
                    rowRenderer={this.renderCheckbox}
                    data={this.state.dataSet}
                    displayLabel={"name"}
                />
            </div>
        );
    }
}
