import { connect } from "react-redux";
import { recsSelectors } from "~/recs-events";
import { selectors as picklistSelectors } from "~/core/picklist";
import { selectors as productBlendSelector } from "~/action-panel/components/common/product-blend-modal";
import { selectors as unitSelectors, unitNames } from "~/core/units";

const getModel = (areaItem) => {
    const model = areaItem.recs[0].recPlanting;
    return {
        ...model,
        varietyHybridList: model.recPlantingVarietyHybridList,
    };
};

const mapStateToProps = (state, ownProps) => {
    const fieldGuidToDetailsMap = recsSelectors.getModuleState(state).fieldGuidToRecDetails;
    const fieldDetailsAreaList = fieldGuidToDetailsMap.get(ownProps.fieldGuid).recAreaList;
    return {
        conversionFactors: productBlendSelector.getConversionFactors(state),
        fieldDetailsAreaList,
        getModel,
        dryPriceUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRICE_UNIT_DRY)
        ),
        dryProductUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRODUCT_RATE_DRY)
        ),
        liquidPriceUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRICE_UNIT_LIQUID)
        ),
        liquidProducteUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRODUCT_RATE_LIQUID)
        ),
        seedingRate: picklistSelectors.getPicklistFromCode(state, "seedingRate"),
    };
};

export const RecManualPlantingSummary = (EventPlantingInfoView) =>
    connect(mapStateToProps)(EventPlantingInfoView);
