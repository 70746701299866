import React, { Component } from "react";
import PropTypes from "prop-types";
import { SalespersonCustomerSearch } from "./salesperson-customer-search";
import { ZeroToInfiniteGrid } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "./../i18n-messages";

const PROPS_SALESPERSON_GUID = "salespersonGuid";
const PROPS_SALESPERSON_ID = "salespersonId";
const PROPS_SALESPERSON_PERSON_NAME = "personName";

class SalespersonCustomerList_ extends Component {
    static propTypes = {
        classNames: PropTypes.string,
        intl: intlShape.isRequired,
        itemList: PropTypes.array,
        onSelectionChange: PropTypes.func.isRequired,
        record: PropTypes.array,
        deleteConfirmation: PropTypes.func,
    };
    static defaultProps = {
        record: [],
        itemList: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            currentList: props.itemList || [],
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.itemList) !== JSON.stringify(this.props.itemList) ||
            JSON.stringify(nextProps.record) !== JSON.stringify(this.props.record)
        ) {
            this.setState({
                currentList: this.getSelectedOptionList(
                    nextProps.itemList,
                    nextProps.record,
                    false
                ),
            });
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.itemList.length === 0) {
            this.setState({
                currentList: [],
            });
        }
    }

    getSelectedOptionList = (itemList, selectedItems, selected = true) => {
        const optionList = itemList.filter((item) => {
            const match = selectedItems.some((selectedItem) => {
                return selectedItem[PROPS_SALESPERSON_GUID] === item[PROPS_SALESPERSON_GUID];
            });
            return (selected && match) || (!selected && !match);
        });
        return optionList || [];
    };

    onChange = (item, isAdd) => {
        if (item == null) {
            return;
        }
        const { record } = this.props;
        const value = item[PROPS_SALESPERSON_GUID];
        let selectedValues = [...record];
        let selectedIndex = -1;
        const isAlreadyInList = selectedValues.some((listItem, index) => {
            if (listItem[PROPS_SALESPERSON_GUID] === value) {
                selectedIndex = index;
            }
            return listItem[PROPS_SALESPERSON_GUID] === value;
        });

        if (isAdd && !isAlreadyInList) {
            selectedValues.push(item);
        } else if (!isAdd && isAlreadyInList && !record.isPrimary) {
            selectedValues.splice(selectedIndex, 1);
        }
        this.props.onSelectionChange(selectedValues);
    };

    render() {
        const { classNames, record } = this.props;
        const { formatMessage } = this.props.intl;
        const { currentList, selectedValue } = this.state;
        const { onChange } = this;
        const gridRecord = record.map((item) => {
            return {
                ...item,
                restrictEditDelete: item.isPrimary,
            };
        });
        return (
            <div className="form-section-child-stretch mini-grid auto-search-list-container">
                {currentList.length === 0 ? null : (
                    <SalespersonCustomerSearch
                        classNames={classNames}
                        clearOnSelection
                        containerClassName={"org-level-search-container"}
                        itemList={currentList}
                        onSelection={(value) => this.onChange(value, true)}
                        placeholderText={formatMessage(messages.addSalesperson)}
                        selectedValue={selectedValue}
                    />
                )}
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={gridRecord}
                        columns={{
                            [PROPS_SALESPERSON_PERSON_NAME]: {
                                title: formatMessage(messages.name),
                                className: "org-level-name",
                            },
                            [PROPS_SALESPERSON_ID]: {
                                title: formatMessage(messages.id),
                                className: "org-level-city",
                            },
                        }}
                        className="org-level-grid"
                        onDelete={(option) => onChange(option, false)}
                    />
                )}
            </div>
        );
    }
}

export const SalespersonCustomerList = injectIntl(SalespersonCustomerList_);
