import { call, put, select, takeEvery } from "redux-saga/effects";

import { Request, apiUrl } from "@ai360/core";

import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid } from "~/login/selectors";

import { fetchUnitData, fetchedUnitData } from "./actions";
import { getUnitsState } from "./selectors";

export const FETCH_UNIT_URL = apiUrl("AgBytes/GetUnitListByType");

export function* fetchUnit({ payload }) {
    for (let key of Object.keys(payload)) {
        const unitTypeKey = payload[key];
        const unitsState = yield select(getUnitsState);
        if (unitTypeKey in unitsState) {
            yield put(fetchedUnitData());
            return;
        }

        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: unitTypeKey };
        try {
            const response = yield call(Request.post, FETCH_UNIT_URL, requestOptions);
            yield put(fetchedUnitData({ [unitTypeKey]: response }));
        } catch (err) {
            yield put(notificationActions.apiCallError(err, fetchUnit({ [key]: unitTypeKey })));
        }
    }
}

export function* unitsSaga() {
    yield takeEvery(fetchUnitData, fetchUnit);
}
