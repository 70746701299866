import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { actions as layerActions } from "~/action-panel/components/layer-module/components/layer-list";
import { getTheUserGuid, getImageryTileBucketName } from "~/login";
import { actions as notificationActions } from "~/notifications";

import { LinkedMapControl_ } from "../components/linked-map-control";
import * as mapControlSelectors from "../../map-control/selectors";

const mapStateToProps = (state, ownProps) => ({
    basemap: mapControlSelectors.getBasemap(state),
    children: ownProps.children,
    userGuid: getTheUserGuid(state),
    imageryTileBucketName: getImageryTileBucketName(state),
});

const mapDispatchToProps = (dispatch) => ({
    onUpdateEventSurfaceStats: (polyJson) =>
        dispatch(layerActions.updateEventSurfaceStats(polyJson)),
    onPushToasterMessage: (message, type) =>
        dispatch(notificationActions.pushToasterMessage(message, type)),
    onSetActivateZoneSplit: (activateZoneSplit) =>
        dispatch(layerActions.setActivateZoneSplit(activateZoneSplit)),
});

export const LinkedMapControl = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LinkedMapControl_));
