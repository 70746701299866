import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import * as actions from "../actions";
import { getSecurityInfo } from "../selectors";
import { messages } from "../i18n-messages";

import { Button, TextInput } from "../../core";
import { messages as globalMessages } from "../../i18n-messages";
import { AuthenticationAPI } from "@ai360/core";
import { ISecurityInfo } from "../interfaces";

export interface ISecurityQuestion_Props {
    securityInfo: ISecurityInfo;
    onError?: (error: Error) => void;
    onResetLogin: () => void;
    onSetSecurityMessage: (secCode: number) => void;
    onStartProcessing: () => void;
    onStopProcessing: () => void;
    intl: intlShape;
}

export interface ISecurityQuestion_State {
    answer: string;
}
class SecurityQuestion_ extends Component<ISecurityQuestion_Props, ISecurityQuestion_State> {
    txtAnswer: Record<string, any>;

    static propTypes = {
        onError: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            answer: "",
        };
    }

    private _handleError(error) {
        if (this.props.onError) {
            this.props.onError(error);
        }
        this.props.onStopProcessing();
        this.txtAnswer.input.focus();
    }

    private _handleKeyDown(event) {
        if (event.key === "Enter") {
            this.submit();
        }
    }

    public cancel() {
        this.props.onResetLogin();
    }

    public submit() {
        this.props.onStartProcessing();
        const { formatMessage } = this.props.intl;

        if (this.props.securityInfo && this.state.answer.length > 0) {
            AuthenticationAPI.checkSecurityAnswer(this.props.securityInfo.email, this.state.answer)
                .then((response) => {
                    if (response && typeof response.securityCode === "number") {
                        this.props.onSetSecurityMessage(response.securityCode);
                    } else {
                        this._handleError(formatMessage(globalMessages.unknownError));
                    }
                    this.props.onStopProcessing();
                })
                .catch((error) => {
                    const { apiResultObj } = error;
                    if (
                        apiResultObj &&
                        apiResultObj.model &&
                        typeof apiResultObj.model.securityCode === "number"
                    ) {
                        this.props.onSetSecurityMessage(apiResultObj.model.securityCode);
                    } else {
                        this._handleError(error);
                    }
                });
        } else {
            this._handleError(formatMessage(messages.answerRequired));
        }
    }

    componentDidMount() {
        this.txtAnswer.input.focus();
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <div className="login-input-container">
                    <div className="display-label">
                        {formatMessage(messages.securityQuestionLbl)}
                    </div>
                    <div className="display-question">{this.props.securityInfo.question}</div>
                    <TextInput
                        tabIndex={1}
                        value={this.state.answer}
                        placeholderText="Your Answer"
                        onKeyDown={(e) => this._handleKeyDown(e)}
                        onChange={(answer) => this.setState({ answer })}
                        ref={(input) => {
                            this.txtAnswer = input;
                        }}
                    />
                </div>
                <div className="login-center">
                    <Button
                        tabIndex={2}
                        className="login-btn"
                        type="submitTextOnly"
                        onClick={() => this.submit()}
                    />
                    <Button
                        tabIndex={3}
                        className="login-btn cancel-btn"
                        type="cancel"
                        onClick={() => this.cancel()}
                    />
                </div>
            </div>
        );
    }
}
export const SecurityQuestion = injectIntl(SecurityQuestion_);

const mapStateToProps = (state) => {
    return {
        securityInfo: getSecurityInfo(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onResetLogin: () => dispatch(actions.resetLogin()),
        onSetSecurityMessage: (code) => dispatch(actions.setSecurityMessage(code)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityQuestion);
