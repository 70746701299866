import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import * as model from "./model";

// Model name
export const MODEL_NAME = "productLog";

// Feature name
export const FEATURE_NAME = "Product";

// Request payload
export const REQUEST_FEATURE = "feature";
export const REQUEST_PAYLOAD_SORT_LIST = "agBytesLogSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "agBytesLogPageOptions";

// URL
export const URL = apiUrl("AgBytes/GetAgBytesLog");

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_FEATURE]: FEATURE_NAME,
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            FieldName: "ActivityDate",
            Sort: {
                Direction: "DESC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 20,
        skip: 0,
    },
};

// Service
export const service = createService({
    id: model.PROPS_AGBYTES_LOG_GUID,
    guid: model.PROPS_AGBYTES_LOG_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    isModalWindow: true,
    urls: {
        URL,
    },
    _defaultLabels: {
        [model.PROP_DATE]: { label: "date", gridCol: 40 },
        [model.PROPS_ACTIVITY]: { label: "activity", gridCol: 20 },
        [model.PROPS_DETAILS]: {
            label: "details",
            gridCol: 40,
            sortNameOverRide: model.PROPS_DETAILS_SORT_NAME,
        },
    },
    numOfRecords: [
        { value: "5", label: "5", selected: false },
        { value: "10", label: "10", selected: false },
        { value: "15", label: "15", selected: false },
        { value: "20", label: "20", selected: true },
    ],
    defaultSort: defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
});
