import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

const ImageryIcon_ = (
    <g>
        <path d="M250,34c29.2,0,57.5,5.7,84.1,17c25.7,10.9,48.8,26.5,68.7,46.3c19.8,19.8,35.4,43,46.3,68.7 c11.3,26.6,17,54.9,17,84.1s-5.7,57.5-17,84.1c-10.9,25.7-26.5,48.8-46.3,68.7c-19.8,19.8-43,35.4-68.7,46.3c-26.6,11.3-54.9,17-84.1,17s-57.5-5.7-84.1-17c-25.7-10.9-48.8-26.5-68.7-46.3c-19.8-19.8-35.4-43-46.3-68.7c-11.3-26.6-17-54.9-17-84.1s5.7-57.5,17-84.1c10.9-25.7,26.5-48.8,46.3-68.7c19.8-19.8,43-35.4,68.7-46.3C192.5,39.7,220.8,34,250,34 M250,0C111.9,0,0,111.9,0,250s111.9,250,250,250s250-111.9,250-250S388.1,0,250,0L250,0z" />
        <path d="M350.66662,407.13025H155.28871V98.300337h41.06664V370.62655h154.31127z" />
    </g>
);

export const ImageryIcon = withSvgIcon(ImageryIcon_, "0 0 500 500");
