export const SET_LOCALE = "intl-provider/SET_LOCALE";
export const SET_LOCALE_ERRMSG = "intl-provider/SET_LOCALE_ERRMSG";

if (!window.process_env) {
    window.process_env = {};
}

export const localeInfoMap = Object.freeze({
    "en-US": {
        messagesUrl: `${window.process_env.PUBLIC_URL}/i18n/en-US.json`,
        getLocaleData: () => require("react-intl/locale-data/en"),
        momentLocaleName: "en",
    },
    "en-UPPER": {
        messagesUrl: `${window.process_env.PUBLIC_URL}/i18n/en-UPPER.json`,
        getLocaleData: () => require("react-intl/locale-data/en"),
        momentLocaleName: "en",
    },
    es: {
        messagesUrl: `${window.process_env.PUBLIC_URL}/i18n/es.json`,
        getLocaleData: () => require("react-intl/locale-data/es"),
        momentLocaleName: "es",
    },
    fr: {
        messagesUrl: `${window.process_env.PUBLIC_URL}/i18n/fr.json`,
        getLocaleData: () => require("react-intl/locale-data/fr"),
        momentLocaleName: "fr",
    },
});

export const locales = Object.keys(localeInfoMap);

export const setLocale = (locale) => {
    return {
        type: SET_LOCALE,
        payload: { locale },
    };
};

export const setLocaleError = (errMessage) => {
    return {
        type: SET_LOCALE_ERRMSG,
        payload: { errMessage },
    };
};
