import React, { Component } from "react";
import { intlShape } from "react-intl";
import PropTypes from "prop-types";
import { messages as apMessages } from "~/action-panel/i18n-messages";
import { messages as setupMessages } from "~/admin/setup/i18n-messages";
import {
    AutoSearch,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    LoaderTypes,
    SelectInput,
} from "~/core";
import { SearchAPI, FieldAPI, FileImportAPI } from "@ai360/core";

import { messages } from "../../../i18n-messages";

export class EditEventIdModal_ extends Component {
    static propTypes = {
        apiCallError: PropTypes.func.isRequired,
        importFileGuidList: PropTypes.array,
        intl: intlShape.isRequired,
        isLockCustomer: PropTypes.bool,
        isOpen: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        userGuid: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            customerGuid: null,
            customerName: "",
            fieldGuid: "",
            fieldOptions: [],
            isLoading: false,
        };
    }

    _fetchData() {
        const { importFileGuidList } = this.props;
        const { customerGuid, fieldGuid } = this.state;
        const allEventIds = false;
        this.setState(
            {
                isLoading: true,
            },
            () => {
                const validEventIdsPromise = FileImportAPI.getValidEventIds(
                    this.props.userGuid,
                    "",
                    customerGuid,
                    "",
                    fieldGuid,
                    importFileGuidList,
                    allEventIds,
                    true // enrollment lock filter the event ids
                );

                const fieldsPromise =
                    customerGuid != null && customerGuid !== ""
                        ? FieldAPI.getFields({
                              customerGuid,
                              active: true,
                          })
                        : Promise.resolve([]);

                Promise.all([validEventIdsPromise, fieldsPromise])
                    .then((values) =>
                        this.setState({
                            validEventIds: values[0].sort().map((id) => ({
                                label: id.eventId,
                                value: id.eventId,
                            })),
                            fieldOptions: values[1].map((field) => ({
                                label: field.farmName
                                    ? `${field.farmName} - ${field.fieldName}`
                                    : field.fieldName,
                                value: field.fieldGuid,
                            })),
                        })
                    )
                    .catch((err) => this.props.apiCallError(err))
                    .finally(() => this.setState({ isLoading: false }));
            }
        );
    }

    _updateCustomerFarmList(customerGuid) {
        if (customerGuid == null) {
            this.setState(
                {
                    customerGuid,
                    fieldGuid: "",
                    fieldOptions: [],
                },
                () => this._fetchData()
            );
            return;
        }
        this.setState(
            {
                customerGuid,
                fieldGuid: "",
            },
            () => this._fetchData()
        );
    }

    _updateField(fieldGuid) {
        const { fieldOptions } = this.state;
        if (fieldOptions.some(({ value }) => value === fieldGuid)) {
            this.setState(
                {
                    fieldGuid,
                },
                () => this._fetchData()
            );
        }
    }

    _getAutoSearchList(userGuid, searchText) {
        const { isLockCustomer } = this.props;
        const search = searchText === "_" ? null : searchText;
        return SearchAPI.getCustomers({
            userGuid,
            search,
            enrolled: isLockCustomer ? true : null,
            userAccess: SearchAPI.UserAccess.UserHierarchyAccess,
            active: true,
            lastUsedCustomer: SearchAPI.LastUsedCustomer.Match,
        });
    }

    UNSAFE_componentWillMount() {
        this.setState({
            isLoading: false,
            newEventId: null,
            validEventIds: [],
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen && !this.props.isOpen) {
            this.setState(
                {
                    customerGuid: "",
                    fieldGuid: "",
                    newEventId: null,
                },
                () => this._fetchData()
            );
        }
    }

    render() {
        const { isOpen, onClose, onSelect, userGuid } = this.props;
        const { formatMessage } = this.props.intl;
        const { customerGuid, fieldGuid, fieldOptions, isLoading, newEventId, validEventIds } =
            this.state;

        const searchForCustomer = formatMessage(setupMessages.searchFor, {
            searchedFor: formatMessage(setupMessages.customer),
        });

        return (
            <DialogBox
                draggable
                actionDisabled={!newEventId}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isOpen}
                onAction={() => onSelect(newEventId)}
                onClose={() => onClose()}
                title={formatMessage(messages.selectEventId)}
                unrestricted
            >
                <AutoSearch
                    getAutoSearchList={this._getAutoSearchList.bind(this)}
                    keyProp={SearchAPI.Props.CUSTOMER_GUID}
                    nameProp={SearchAPI.Props.CUSTOMER_NAME}
                    itemList={[]}
                    onSelection={(item) =>
                        this._updateCustomerFarmList(item ? item.customerGuid : null)
                    }
                    placeholderText={searchForCustomer}
                    secondaryPropList={[SearchAPI.Props.CUSTOMER_CITY, SearchAPI.Props.STATE_ABBR]}
                    userGuid={userGuid}
                />
                <SelectInput
                    type="text"
                    clearFilterInputOnBlur={false}
                    disabled={!customerGuid}
                    initialFilterStr={fieldGuid}
                    noOptionsRenderer={null}
                    onInputChange={(guid) => this._updateField(guid)}
                    onChange={(guid) => this._updateField(guid)}
                    options={fieldOptions}
                    placeholderText={formatMessage(apMessages.farmFieldName)}
                    value={fieldGuid}
                />
                <SelectInput
                    required
                    placeholderText={formatMessage(messages.eventId)}
                    onChange={(newEventId) => this.setState({ newEventId })}
                    options={validEventIds}
                    value={newEventId}
                />
                {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
            </DialogBox>
        );
    }
}
