export const ANALYTICS_STATE_KEY = "ANALTYICS_STATE";

export const getEmbedDashboardUrl = (state) => {
    return state[ANALYTICS_STATE_KEY].embedDashboardUrl;
};

export const getAnalyticDashboardTypes = (state) => {
    return state[ANALYTICS_STATE_KEY].analyticDashboardTypes;
};

export const getOrgLevelList = (state) => state[ANALYTICS_STATE_KEY].orgLevelList;
