import { APIErrorWithCode, IAPIResultObj, IErrorFile } from "@ai360/core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const API_ERROR_DATA_KEY = "API_ERROR_DATA";

export interface IApiErrorData {
    apiResults?: APIErrorWithCode[];
    apiResultObj?: IAPIResultObj;
    detailedStatus?: any;
    errorCodeList?: any;
    errorFile?: IErrorFile;
    message?: string;
    model?: any;
    name?: string;
    newApiResults?: IAPIResultObj;
    stack?: string;
}

const initialState: IApiErrorData = {};

export const apiErrorDataSlice = createSlice({
    name: API_ERROR_DATA_KEY,
    initialState,
    reducers: {
        setApiResult: (state, action: PayloadAction<IApiErrorData>) => ({
            ...state,
            ...action.payload,
        }),
        resetApiResult: () => ({ ...initialState }),
        setApiResults: (state, action: PayloadAction<APIErrorWithCode[]>) => {
            state.apiResults = action.payload;
        },
    },
});

export const apiReducer = apiErrorDataSlice.reducer;
