import React, { Component } from "react";
import { connect } from "react-redux";
import { model as accordionModel } from "~/accordion";
import { ILegendClassBreak, LayerAPI, LayerUtilsAPI } from "@ai360/core";
import * as selectors from "../selectors";
import { LegendDisplay } from "./legend-display";
import "./legend-item.css";

export const LEGEND_LAYER_HEIGHT = 25;

export const createLegendAccordionItems = (classBreaks) => {
    return classBreaks
        ? classBreaks.map(() => {
              //dim index should be all that's needed to lookup classbreak legend info
              return new accordionModel.AccordionItem(LEGEND_LAYER_HEIGHT, false, null);
          })
        : [];
};

interface LegendItemProps {
    accordionId: number;
    attributeGuid: string;
    classBreak: ILegendClassBreak;
    fieldGuid: string;
    isLoading: boolean;
    itemDimIdx: number[];
    layer: LayerAPI.ILayer;
    surface: LayerAPI.ISubLayer;
    surfaceGuid: string;
}

class LegendItem_ extends Component<LegendItemProps> {
    render() {
        const { classBreak, isLoading } = this.props;
        if (!classBreak) {
            console.warn(
                "Something went very wrong with the surface/layer info, but we've recovered what we can.",
                this.props.layer
            );
            return (
                <div className="legend-accordion-item">
                    {!isLoading ? null : (
                        <div className="loader-container">
                            <div />
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div className="legend-accordion-item">
                <LegendDisplay classBreak={classBreak} />
                {!isLoading ? null : (
                    <div className="loader-container">
                        <div />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const layer = selectors.getLayerInfos(state).get(ownProps.fieldGuid)[ownProps.itemDimIdx[1]];
    const surface = LayerUtilsAPI.getSurfaceInfo(
        layer,
        ownProps.surfaceGuid,
        ownProps.attributeGuid
    );
    const classBreak = surface.classBreaks ? surface.classBreaks[ownProps.itemDimIdx[3]] : null;
    return {
        classBreak,
        layer,
        surface,
    };
};

export const LegendItem = connect(mapStateToProps)(LegendItem_);
