import { AppHelpers } from "@ai360/core";
import { agBytesViewResolver } from "~/admin/agBytes";
import { reportsViewResolver } from "../../reports";
import { setupViewResolver } from "~/admin/setup";
import { About } from "~/admin/about";
import { Analytics } from "~/analytics";
import { Notifications } from "~/notifications";

const defaultChildViewResolver = (childNameCode) => {
    if (AppHelpers.isNothing(childNameCode)) {
        return;
    }
    switch (childNameCode) {
        case "4": //About
            return {
                component: About,
            };
        case "257": // Analytics
            return {
                component: Analytics,
            };
        case "429": // Notifications
            return {
                component: Notifications,
                halfScreen: true,
            };
        default:
            break;
    }
};

const ViewResolver = (parentNameCode, childNameCode) => {
    if (AppHelpers.isNothing(parentNameCode)) {
        return;
    }
    switch (parentNameCode) {
        case "101": //setup
            return setupViewResolver(childNameCode);
        case "102": //agbytes
            return agBytesViewResolver(childNameCode);
        case "105": // Reports
            return reportsViewResolver(childNameCode);
        default:
            return defaultChildViewResolver(childNameCode);
    }
};
export default ViewResolver;
