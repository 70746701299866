import { connect } from "react-redux";
import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, service, dropdowns, unitTypes } from "./../data";
import { getTheUserCompanyGuid } from "~/login";
import { getTheUserGuid } from "~/login/selectors";
import { resetPassword } from "./../data/actions";
import { getDropdowns } from "./../../../selectors";
import * as model from "../data/model";

const mapStateToProps = (state) => ({
    companyGuid: getTheUserCompanyGuid(state),
    userGuid: getTheUserGuid(state),
    ...getDropdowns([model.PROPS_USER_ROLE_LIST], state),
});

const mapDispatchToProps = () => ({
    resetPassword: (payload) => resetPassword(payload), //Needs HOC is expected to dispatch this
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AddEditPanelContainer(AddEditPanel, {
        pickLists,
        service,
        dropdowns,
        unitTypes,
    })
);
