import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classnames from "classnames";
import * as utils from "./event-scouting-utils";
import { NumericInput, SelectInput, TextInput } from "~/core";
import ScoutingDetailPhotoPanel from "./scouting-detail-photo-panel";

import { ACTIVE_YN } from "~/core/picklist";
import { AgEventAPI } from "@ai360/core";
import { ScoutingTemplateObservation } from "~/recs-events/events/model";
import classNames from "classnames";

const eventObservationCountUnitRequired = 928; // ErrorCode.EventObservationCountUnitRequired
const eventObservationWeedHeightUnitRequired = 929; // ErrorCode.EventObservationWeedHeightUnitRequired
interface IScoutingDetailItemProps {
    countUnitData?: Array<any>;
    densityData?: Array<any>;
    errorCodesForEventArea: any;
    errorMessages?: any;
    formatMessage: (msg: any) => string;
    growthStageObservationData?: Map<string, Array<any>>;
    index: number;
    messages: any;
    observationData?: Array<any>;
    onChange: (type: string, value: any, index: number, cb?: any) => void;
    onPushToasterMessage: (message: any, type: any) => void;
    photoMediaTypeGuid: string;
    plantLocationData?: Array<any>;
    scoutingPhotoPresignedUrlMap: Map<string, string>;
    scoutingDetailList: Array<any>;
    scoutingTemplate?: any;
    unitLength?: Array<any>;
    noIssuesFound: boolean;
}
export class ScoutingDetailItem extends Component<IScoutingDetailItemProps> {
    static defaultProps = {
        observationData: [],
    };

    private _buildInputRows(
        currentDetail: AgEventAPI.IScoutingDetail,
        currentDetailTemplate: ScoutingTemplateObservation
    ) {
        const {
            densityData,
            formatMessage,
            growthStageObservationData,
            index,
            messages,
            onChange,
            plantLocationData,
            noIssuesFound,
        } = this.props;
        const densityVisible = true;
        const growthStageVisible =
            currentDetail.growthStageGuid || currentDetailTemplate.showGrowthStage;
        const plantLocationVisible =
            currentDetail.plantLocationGuid || currentDetailTemplate.showPlantLocation;
        const trapIdVisible = currentDetail.trapID || currentDetailTemplate.showTrapId;
        const applicableGrowthStageData =
            growthStageObservationData.get(currentDetail.observationGuid) || [];
        const filterInputs = [];

        if (densityVisible) {
            filterInputs.push(
                <SelectInput
                    containerClassNames={["pressure"]}
                    placeholderText={formatMessage(messages.pressure)}
                    value={currentDetail.densityRatingGuid}
                    options={densityData}
                    onChange={(value) => onChange("densityRatingGuid", value, index)}
                    disabled={noIssuesFound}
                />
            );
        }

        if (growthStageVisible) {
            filterInputs.push(
                <SelectInput
                    containerClassNames={["growth"]}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={true}
                    placeholderText={formatMessage(messages.growthStage)}
                    value={currentDetail.growthStageGuid}
                    options={applicableGrowthStageData}
                    onChange={(value) => onChange("growthStageGuid", value, index)}
                    disabled={noIssuesFound}
                />
            );
        }

        if (plantLocationVisible) {
            filterInputs.push(
                <SelectInput
                    placeholderText={formatMessage(messages.plantLocation)}
                    value={currentDetail.plantLocationGuid}
                    options={plantLocationData}
                    onChange={(value) => onChange("plantLocationGuid", value, index)}
                    disabled={noIssuesFound}
                />
            );
        }

        if (trapIdVisible) {
            filterInputs.push(
                <TextInput
                    containerClassNames={["value"]}
                    placeholderText={formatMessage(messages.trapId)}
                    value={currentDetail.trapID}
                    onChange={(value) => onChange("trapID", value, index)}
                />
            );
        }

        const filterInputRows = [];
        for (let i = 0; i < filterInputs.length; i += 2) {
            const isHalf = !filterInputs[i + 1];
            const className = isHalf ? "input-row" : "input-row-observation";
            filterInputRows.push(
                <div
                    key={`filter-row-${filterInputRows.length}`}
                    className={classnames(className, { half: isHalf })}
                >
                    {!filterInputs[i] ? null : filterInputs[i]}
                    {!filterInputs[i + 1] ? null : filterInputs[i + 1]}
                </div>
            );
        }
        return filterInputRows;
    }

    private _hasValidationError(code) {
        const { errorCodesForEventArea, index } = this.props;
        return errorCodesForEventArea.has(code)
            ? Array.isArray(errorCodesForEventArea.get(code)) &&
                  errorCodesForEventArea.get(code).includes(index.toString())
            : false;
    }

    public render(): JSX.Element {
        const {
            countUnitData,
            formatMessage,
            index,
            messages,
            observationData,
            onChange,
            onPushToasterMessage,
            photoMediaTypeGuid,
            scoutingPhotoPresignedUrlMap,
            scoutingDetailList,
            scoutingTemplate,
            unitLength,
            noIssuesFound,
        } = this.props;
        const currentDetail = scoutingDetailList[index];
        const currentDetailTemplate =
            scoutingTemplate.observationInfo.find(
                (o) => o.observationGuid === currentDetail.observationGuid
            ) || {};
        const weedHeightVisible =
            currentDetail.weedHeight ||
            currentDetail.weedHeight === 0 ||
            currentDetailTemplate.showWeedHeight;
        const observationCountVisible =
            currentDetail.observationCount ||
            currentDetail.observationCount === 0 ||
            currentDetailTemplate.showObservationCount;
        const selectedObservation = utils.formatSelectedObservation(observationData, currentDetail);

        const photosVisible =
            (currentDetail.photoList && currentDetail.photoList.length > 0) ||
            scoutingTemplate.allowPhotos;
        const photoPanelProps = {
            formatMessage,
            index,
            messages,
            onChange,
            onPushToasterMessage,
            photoList: currentDetail.photoList,
            photoMediaTypeGuid,
            scoutingPhotoPresignedUrlMap,
        };
        const containerClassNames = classNames("scouting-details", {
            disabled: noIssuesFound,
        });

        return (
            <div className={containerClassNames}>
                <div className="observation-item-header">
                    Observation: {index + 1}
                    {" - "}
                    {selectedObservation.name}
                </div>
                {this._buildInputRows(currentDetail, currentDetailTemplate)}
                <div className="input-row-observation">
                    {!weedHeightVisible ? null : (
                        <NumericInput
                            containerClassNames={["value"]}
                            placeholderText={formatMessage(messages.weedHeight)}
                            scale={8}
                            precision={38}
                            value={currentDetail.weedHeight}
                            onChange={(value) => onChange("weedHeight", value, index)}
                            disabled={noIssuesFound}
                        />
                    )}
                    {!weedHeightVisible ? null : (
                        <SelectInput
                            containerClassNames={[
                                "unit",
                                {
                                    "select-form-input-error": this._hasValidationError(
                                        eventObservationWeedHeightUnitRequired
                                    ),
                                },
                            ]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.units)}
                            value={currentDetail.weedHeightUnitsGuid}
                            options={unitLength}
                            onChange={(value) => onChange("weedHeightUnitsGuid", value, index)}
                            disabled={noIssuesFound}
                        />
                    )}
                </div>
                <div className="input-row-observation">
                    {!observationCountVisible ? null : (
                        <NumericInput
                            containerClassNames={["value"]}
                            placeholderText={formatMessage(messages.observationCount)}
                            scale={0}
                            precision={8}
                            value={currentDetail.observationCount}
                            onChange={(value) => onChange("observationCount", value, index)}
                            disabled={noIssuesFound}
                        />
                    )}
                    {!observationCountVisible ? null : (
                        <SelectInput
                            containerClassNames={[
                                "unit",
                                {
                                    "select-form-input-error": this._hasValidationError(
                                        eventObservationCountUnitRequired
                                    ),
                                },
                            ]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.units)}
                            value={currentDetail.countUnitsGuid}
                            options={countUnitData}
                            onChange={(value) => onChange("countUnitsGuid", value, index)}
                            disabled={noIssuesFound}
                        />
                    )}
                </div>
                {!photosVisible ? null : <ScoutingDetailPhotoPanel {...photoPanelProps} />}
            </div>
        );
    }
}

export default injectIntl(ScoutingDetailItem);
