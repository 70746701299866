import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ApplicationIcon = withSvgIcon(
    <g>
        <polygon points="25.8,10.9 25.8,11.3 15.6,11.3 8.2,12 3.9,14.2 3.4,13.9 9.3,10.9" />
        <path d="M 5.3 18.3 c 0 3.1 2.5 5.5 5.5 5.5 s 5.5 -2.5 5.5 -5.5 s -2.5 -5.5 -5.5 -5.5 S 5.3 15.2 5.3 18.3 L 5.3 18.3 Z M 8.5 18.3 c 0 -1.3 1 -2.3 2.3 -2.3 s 2.3 1 2.3 2.3 s -1 2.3 -2.3 2.3 l 0 0 C 9.6 20.6 8.5 19.5 8.5 18.3 Z" />
        <circle cx="10.9" cy="18.3" r="1.2" />
        <path d="M 38.4 19.8 c 0 2.3 1.8 4.1 4.1 4.1 s 4.1 -1.8 4.1 -4.1 s -1.8 -4.1 -4.1 -4.1 l 0 0 C 40.3 15.7 38.4 17.5 38.4 19.8 Z M 40.8 19.8 c 0 -0.9 0.8 -1.7 1.7 -1.7 s 1.7 0.8 1.7 1.7 c 0 0.9 -0.8 1.7 -1.7 1.7 l 0 0 C 41.6 21.5 40.8 20.7 40.8 19.8 Z" />
        <circle cx="42.5" cy="19.8" r="0.9" />
        <circle cx="22.2" cy="15.1" r="3.1" />
        <path d="M 27.4 12.7 h -0.6 v 7.6 h 5.3 v -7.6 h -0.6 v 2.4 h -4.1 C 27.4 15.1 27.4 12.7 27.4 12.7 Z M 27.4 18.1 h 4.1 v 1.7 h -4.1 V 18.1 Z M 27.4 15.7 h 4.1 v 1.8 h -4.1 V 15.7 Z" />
        <polygon points="47.8,13.7 47.8,14.2 47.8,15.2 39.8,15.2 38.8,16.3 37.8,19.3 37.2,19.3 38.7,13.7" />
        <rect x="45.9" y="10.2" width="1.5" height="3.1" />
        <polygon points="36.3,7 47.3,8.2 47.8,9.9 36.3,10.1" />
        <path d="M 35.9 4.5 h 0.2 v 8 h -1.2 v -8 h 0.2 c 0 -1 0.1 -3.2 0.1 -3.5 s 0.2 -0.9 0.6 -1 s 0.4 0.6 0.4 0.6 S 36 1.1 35.9 1.5 S 35.9 3.6 35.9 4.5 Z" />
        <polygon points="24.7,1.4 24.7,0.9 26.8,0 34.8,0.4 34.8,1 26.8,0.7" />
        <path d="M 26.8 1 l -1.5 0.4 l 1 1.7 v 8 h 8.2 V 1.4 L 26.8 1 Z M 26 1.6 l 0.8 -0.2 L 34 1.9 v 4.8 h -7.2 v -4 L 26 1.6 Z" />
        <path d="M 13.4 11.9 l 2.2 -0.3 h 3.2 v 6.9 h 6.8 l 0.2 -7 h 0.6 l 0 0 h 8.2 v 1.2 h 1.8 v -2.2 l 9.3 -0.4 v 3.1 h -7.2 l -0.1 0.4 l -1.5 4.8 h -4.3 v -6.1 h -6.2 L 26.2 19 h -7.9 v -3.1 h -1.4 c -0.1 -0.7 -0.4 -1.4 -0.7 -2 C 15.3 13.2 14.4 12.5 13.4 11.9 Z" />
        <path d="M 0 12.6 V 7 l 0 0 c 0 -0.7 0 -1.3 0 -1.9 V 4.9 C 0 4.2 0.1 3.6 0.2 3 s 0.1 -0.9 0.1 -1.2 V 1.6 l 0 0 c 0.1 -0.3 0.1 -0.5 0.2 -0.5 h 0.1 l 23.9 0.6 c 0 0 0.8 0 1.3 1.7 V 10 H 9.1 l -7.2 3.8 H 0 V 12.6 Z" />
    </g>,
    "0 0 50 26.4"
);
