import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ImageryIcon = withSvgIcon(
    <g>
        <path
            d="M27.917,5.265H24.091V4.581a1.861,1.861,0,0,0-1.858-1.859H7.816A1.86,1.86,0,0,0,5.958,4.581v.684H2.132A1.859,1.859,0,0,0,.274,7.123V24.9a1.86,1.86,0,0,0,1.858,1.858H27.917A1.861,1.861,0,0,0,29.776,24.9V7.123A1.86,1.86,0,0,0,27.917,5.265ZM28.776,24.9a.859.859,0,0,1-.859.858H2.132a.858.858,0,0,1-.858-.858V7.123a.859.859,0,0,1,.858-.858H6.958V4.581a.86.86,0,0,1,.858-.859H22.233a.86.86,0,0,1,.858.859V6.265h4.826a.859.859,0,0,1,.859.858Z"
            transform="translate(-0.274 -2.722)"
        />
        <path
            d="M15.025,8.456a7.555,7.555,0,1,0,7.554,7.555A7.564,7.564,0,0,0,15.025,8.456Zm0,14.11a6.555,6.555,0,1,1,6.554-6.555A6.563,6.563,0,0,1,15.025,22.566Z"
            transform="translate(-0.274 -2.722)"
        />
        <path
            d="M25.29,8.062a1.419,1.419,0,1,0,1.419,1.419A1.419,1.419,0,0,0,25.29,8.062Z"
            transform="translate(-0.274 -2.722)"
        />
    </g>,
    "0 0 30.502 24.034"
);
