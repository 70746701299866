import React, { Component, ReactElement } from "react";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { messages } from "~/i18n-messages";

interface Props {
    errors: string;
    onExit: () => void;
    intl: any;
}

export class ErrorsModal extends Component<Props> {
    private readonly title: string;

    constructor(props: Props) {
        super(props);

        const { formatMessage } = props.intl;
        const errorCount = props.errors.length;
        this.title = formatMessage(messages.errorsTitle, { count: errorCount });
    }

    render(): ReactElement {
        return (
            <DialogBox
                footerType={DialogBoxFooterType.NONE}
                isOpen={true}
                onAction={() => this.props.onExit()}
                onClose={() => this.props.onExit()}
                title={this.title}
            >
                {this.props.errors}
            </DialogBox>
        );
    }
}
