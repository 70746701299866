import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
import { messages } from "../../i18n-messages";
import { adminData, ADD, GUID, NAME, DETAILS } from "~/admin/data";
// Components
import { Section, SelectInput, SubSection } from "~/core";
import { OrgLevelList, PROPS_STATE_ABBREVIATION } from "~/admin/agBytes/components/org-level-list";
// Style
import "../../add-edit-shared-responsive.css";
import {
    colorOptionRenderer,
    getBackgroundGradientStyle,
} from "~/action-panel/components/layer-module/utils";
import "./add-edit-panel.css";

const DEFINED_LEGEND_NAME = "defined legend";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        classificationMethodName: PropTypes.array,
        colorRamps: PropTypes.array.isRequired,
        companyList: PropTypes.array,
        fetchCompanyList: PropTypes.func,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchFilteredLocationList: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        filteredLocationList: PropTypes.array,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        numberOfClassesOptions: PropTypes.array.isRequired,
        record: CustomPropTypes.systemDefaults,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.surfaceDefaults = {};
        this.state = {
            [model.PROPS_ORG_LEVEL_LIST]: [],
            colorRampGuid: "",
            isRec: false,
            numberOfClasses: "",
            selectedCompany: "",
            classificationMethodGuid: "",
            selectedCompanyList: null,
        };
    }

    componentDidMount() {
        this.props.setBreadcrumbs([""]);
        this.surfaceDefaults = service.getDefaultRecord();
        const { needs } = this.props;
        needs([this.props.fetchCompanyList(), this.props.fetchDropdownData()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.surfaceDefaults);
        }
        if (
            nextProps.addEditPanel.mode !== ADD &&
            nextProps.record &&
            nextProps.record !== this.props.record
        ) {
            this.surfaceDefaults = {
                ...this.surfaceDefaults,
                ...nextProps.record,
            };
            this.setState({
                classificationMethodGuid: nextProps.record.classificationMethodGuid || "",
                colorRampGuid: nextProps.record.colorRampGuid || "",
                isRec: nextProps.record.isRec || false,
                numberOfClasses: nextProps.record.numberOfClasses || "",
                selectedCompany: nextProps.record.companyGuid || "",
            });
            this._onCompanyChange(nextProps.record.companyGuid);
        }
        if (nextProps.companyList !== this.props.companyList) {
            this.setState({
                companyList: nextProps.companyList.map(({ name, guid }) => ({
                    label: name,
                    value: guid,
                })),
            });
        }

        if (nextProps.filteredLocationList !== this.props.filteredLocationList) {
            this.setState({
                filteredLocationList: nextProps.filteredLocationList.map(({ name, guid }) => ({
                    label: name,
                    value: guid,
                })),
            });
        }

        this._initializeDropdowns(nextProps);
    }

    _handleClassificationMethodChange(classificationMethodGuid) {
        let { numberOfClasses } = this.state;
        if (this._isDefinedLegend(classificationMethodGuid)) {
            numberOfClasses = "";
        }
        this.setState(
            {
                classificationMethodGuid,
            },
            () => this._updateNumClasses(numberOfClasses)
        );
    }

    _handleSystemAttributeChange(systemAttribute) {
        this.setState({
            isRec: systemAttribute.details.isRec,
        });
    }

    _initializeDropdowns = (nextProps) => {
        this._initializeClassificationMethods(nextProps);
        this._initializeSystemAttributes(nextProps);
        this._initializeOrgLevel(nextProps);
        this._initializeDisplayTypes(nextProps);
    };

    _initializeSystemAttributes = (nextProps) => {
        if (
            this.surfaceDefaults[model.PROPS_SYSTEM_ATTRIBUTE_GUID] != null &&
            nextProps[model.PROPS_SYSTEM_ATTRIBUTE_NAME]
        ) {
            this.setState({
                [model.PROPS_SYSTEM_ATTRIBUTE_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_SYSTEM_ATTRIBUTE_NAME],
                    { guid: GUID, label: NAME, details: DETAILS },
                    this.surfaceDefaults[model.PROPS_SYSTEM_ATTRIBUTE_GUID]
                ),
            });
        }
    };

    _initializeDisplayTypes = (nextProps) => {
        if (
            this.surfaceDefaults[model.PROPS_DISPLAY_TYPE_GUID] != null &&
            nextProps[model.PROPS_DISPLAY_TYPE_NAME]
        ) {
            this.setState({
                [model.PROPS_DISPLAY_TYPE_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_DISPLAY_TYPE_NAME],
                    { guid: GUID, label: NAME, details: DETAILS },
                    this.surfaceDefaults[model.PROPS_DISPLAY_TYPE_GUID]
                ),
            });
        }
    };

    _initializeClassificationMethods = (nextProps) => {
        if (
            this.surfaceDefaults[model.PROPS_CLASSIFICATION_METHOD_GUID] != null &&
            nextProps[model.PROPS_CLASSIFICATION_METHOD_NAME]
        ) {
            this.setState({
                [model.PROPS_CLASSIFICATION_METHOD_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_CLASSIFICATION_METHOD_NAME],
                    { guid: GUID, label: NAME },
                    this.surfaceDefaults[model.PROPS_CLASSIFICATION_METHOD_GUID]
                ),
            });
        }
    };

    _initializeOrgLevel = (nextProps) => {
        if (
            this.surfaceDefaults[model.PROPS_ORG_LEVEL_LIST] != null &&
            nextProps.filteredLocationList
        ) {
            this.setState({
                [model.PROPS_ORG_LEVEL_LIST]: nextProps.filteredLocationList || [],
            });
        }
    };

    _isDefinedLegend(classificationMethodGuid) {
        const { classificationMethodName } = this.props;
        const selectedClassificationMethod =
            classificationMethodName &&
            classificationMethodName.find((cm) => cm.guid === classificationMethodGuid);
        return (
            selectedClassificationMethod &&
            selectedClassificationMethod.name.toLowerCase() === DEFINED_LEGEND_NAME
        );
    }

    _onCompanyChange = (value) => {
        if (this.state.selectedCompany !== value) {
            this._onTextChange(model.PROPS_ORG_LEVEL_LIST, []);
        }
        this.setState(
            {
                selectedCompany: value,
                [model.PROPS_ORG_LEVEL_LIST]: [],
            },
            () => {
                this.props.needs(
                    [this.props.fetchFilteredLocationList(this.state.selectedCompany)],
                    this._onTextChange(model.PROPS_COMPANY_GUID, value)
                );
            }
        );
    };

    _onPicklistChange = ({ type, guid }, value, callback) => {
        this.surfaceDefaults = handlePicklistChange(
            this.surfaceDefaults,
            { type, guid, value },
            callback
        );
    };

    _onTextChange = (formKey, value, callback) => {
        this.surfaceDefaults = onTextChange(
            this.surfaceDefaults,
            { formKey: [formKey], value },
            callback
        );
    };

    _updateColorRamp(colorRampGuid) {
        this.setState({ colorRampGuid });
        this._onTextChange(model.PROPS_COLOR_RAMP_GUID, colorRampGuid);
    }

    _updateNumClasses(numberOfClasses) {
        this.setState({ numberOfClasses });
        this._onTextChange(model.PROPS_NUMBER_OF_CLASSES, numberOfClasses);
    }

    _renderSurfaceDefaultsInfo = () => {
        const { formatMessage } = this.props.intl;
        const { surfaceDefaults } = this;
        const { apiErrors, colorRamps, numberOfClassesOptions } = this.props;
        const { colorRampGuid, isRec, numberOfClasses, classificationMethodGuid } = this.state;

        const colorRampSelectOptions = colorRamps.map((cr) => ({
            background: getBackgroundGradientStyle(cr.layerFiles.slice(-1)[0]),
            label: " ",
            value: cr.colorRampGuid,
        }));

        const isDefinedLegend = this._isDefinedLegend(classificationMethodGuid);

        const numClassesOptions = numberOfClassesOptions.map((noc) => ({
            label: noc,
            value: noc,
        }));

        const displayTypeOptions =
            this.state[model.PROPS_DISPLAY_TYPE_NAME] &&
            this.state[model.PROPS_DISPLAY_TYPE_NAME].filter(
                (o) => o.value.details.isRec === isRec
            );

        numClassesOptions.shift(); // Remove first item in the list

        const colorRamp = this.props.colorRamps.find((cs) => cs.colorRampGuid === colorRampGuid);
        const backgroundImageStyle = colorRamp
            ? getBackgroundGradientStyle(colorRamp.layerFiles.slice(-1)[0])
            : null;
        const backgroundImageCss = {
            backgroundImage: `${backgroundImageStyle}`,
        };
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <SelectInput
                            inputCSS={backgroundImageCss}
                            filterable={false}
                            onChange={(e) => this._updateColorRamp(e)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={colorRampSelectOptions}
                            optionRenderer={colorOptionRenderer}
                            placeholderText={formatMessage(messages.colorRampName)}
                            ref={(ref) => (this.colorRampSelect = ref)}
                            value={colorRampGuid}
                            clearable={false}
                            containerClassNames={[getAgBytesErrorClassNames(158, apiErrors)]}
                            required
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_CLASSIFICATION_METHOD_NAME]}
                            autofocus
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_CLASSIFICATION_METHOD_NAME],
                                selectedGuid:
                                    surfaceDefaults[model.PROPS_CLASSIFICATION_METHOD_GUID],
                            })}
                            onChange={(value) => {
                                this._onPicklistChange(
                                    {
                                        type: model.PROPS_CLASSIFICATION_METHOD_NAME,
                                        guid: model.PROPS_CLASSIFICATION_METHOD_GUID,
                                    },
                                    value,
                                    () => this._handleClassificationMethodChange(value.guid)
                                );
                            }}
                            placeholderText={formatMessage(messages.classificationMethodName)}
                            containerClassNames={[getAgBytesErrorClassNames(906, apiErrors)]}
                            clearable={false}
                            required
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_SYSTEM_ATTRIBUTE_NAME]}
                            autofocus
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_SYSTEM_ATTRIBUTE_NAME],
                                selectedGuid: surfaceDefaults[model.PROPS_SYSTEM_ATTRIBUTE_GUID],
                            })}
                            onChange={(value) => {
                                this._onPicklistChange(
                                    {
                                        type: model.PROPS_SYSTEM_ATTRIBUTE_NAME,
                                        guid: model.PROPS_SYSTEM_ATTRIBUTE_GUID,
                                    },
                                    value,
                                    () => this._handleSystemAttributeChange(value)
                                );
                            }}
                            placeholderText={formatMessage(messages.systemAttributeName)}
                            containerClassNames={[getAgBytesErrorClassNames(225, apiErrors)]}
                            clearable={false}
                            required
                        />
                        {!isDefinedLegend && (
                            <SelectInput
                                filterable={false}
                                onChange={(e) => this._updateNumClasses(e)}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={numClassesOptions}
                                placeholderText={formatMessage(messages.numberOfClasses)}
                                value={numberOfClasses}
                                clearable={false}
                                required={true}
                                containerClassNames={[getAgBytesErrorClassNames(383, apiErrors)]}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={displayTypeOptions}
                            autofocus
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_DISPLAY_TYPE_NAME],
                                selectedGuid: surfaceDefaults[model.PROPS_DISPLAY_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this._onPicklistChange(
                                    {
                                        type: model.PROPS_DISPLAY_TYPE_NAME,
                                        guid: model.PROPS_DISPLAY_TYPE_GUID,
                                    },
                                    value
                                );
                            }}
                            placeholderText={formatMessage(messages.displayTypeName)}
                            containerClassNames={[getAgBytesErrorClassNames(225, apiErrors)]}
                            clearable={false}
                            required
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    _renderDetailInfo2 = () => {
        const { surfaceDefaults } = this;
        const { apiErrors } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <Section
                required
                className="grid-section"
                headerText={formatMessage(messages.orgLevelList)}
            >
                {this.state.companyList && this.state.companyList.length === 1 ? null : (
                    <SubSection className="select-company-container">
                        <div className="select-company-message">
                            {formatMessage(messages.selectACompanyMessage)}
                        </div>
                        <SelectInput
                            required
                            clearable={false}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.companyName)}
                            value={this.state.selectedCompany}
                            options={this.state.companyList}
                            onChange={(value) => {
                                this._onCompanyChange(value);
                            }}
                            containerClassNames={[getAgBytesErrorClassNames(909, apiErrors)]}
                        />
                    </SubSection>
                )}
                {!this.state[model.PROPS_ORG_LEVEL_LIST] ? null : (
                    <OrgLevelList
                        apiErrors={apiErrors}
                        itemList={this.state[model.PROPS_ORG_LEVEL_LIST]}
                        record={surfaceDefaults[model.PROPS_ORG_LEVEL_LIST]}
                        onSelectionChange={(value) => {
                            this._onTextChange(model.PROPS_ORG_LEVEL_LIST, value, () =>
                                this.forceUpdate()
                            );
                        }}
                        statePropName={PROPS_STATE_ABBREVIATION}
                    />
                )}
            </Section>
        );
    };

    render() {
        return (
            <div className="add-edit-panel">
                <div className="section-container">
                    {this._renderSurfaceDefaultsInfo()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo2()}
                    <span className="bar section-fence" />
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
