import PropTypes from "prop-types";
import { model } from "~/admin/setup/nutrient-removal-rate/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_ATTRIBUTE_NAME]: PropTypes.string,
    [model.PROPS_CAN_DELETE]: PropTypes.string,
    [model.PROPS_CROPS_CLASS_NAME]: PropTypes.string,
    [model.PROPS_CROP_NAME]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_NUTRIENT_TARGET_VALUE]: PropTypes.string,
    [model.PROPS_NUTRIENT_TARGET_VALUE_GUID]: PropTypes.string,
    [model.PROPS_NUTRIENT_TARGET_VALUE_ID]: PropTypes.string,
    [model.PROPS_ORG_LEVEL_NAME]: PropTypes.string,
    [model.PROPS_SAMPLE_TYPE_GUID]: PropTypes.string,
    [model.PROPS_SAMPLE_TYPE_NAME]: PropTypes.string,
};

export default PropTypes.shape(shape);
