import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import deepEqual from "fast-deep-equal";
import { connect } from "react-redux";
import classnames from "classnames";

import { adminData, GUID, NAME } from "~/admin/data";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { apiUrl } from "@ai360/core";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    Bucket,
    BucketHeader,
    Checkbox,
    Loader,
    NumericInput,
    RadioButton,
    SelectInput,
    SelectInputMulti,
} from "~/core";

import { ACTIVE_YN } from "~/core/picklist";

import * as selectors from "../../selectors";
import * as actions from "../../actions";

import {
    models as recsEventsModels,
    recsModels,
    actions as recsEventsActions,
    recsSelectors,
} from "~/recs-events";

import { getSetValuesForErrorCodeList } from "../../../../../common/validation-utils";

import { EquationIcon } from "../icons/equation";
import * as utils from "./rec-equation-planting-form-utils";
import "../../../../../common/rec-event-info/rec-event-info.css";
import "./rec-equation-planting-form.css";

const MANAGEMENT_AREA = "Management_Area";
const NORMALIZED_YIELD = "Normalized_Yield";

const messages = defineMessages({
    applicationEquationFormLabelText: {
        id: "recModule.recInfo.plantingEquation.formLabelText",
        defaultMessage: "Equation",
    },
    brandPlaceholderText: {
        id: "recModule.recInfo.plantingEquation.brandPlaceholderText",
        defaultMessage: "Brand/Organization",
    },
    varietyPlaceholderText: {
        id: "recModule.recInfo.plantingEquation.varietyPlaceholderText",
        defaultMessage: "Variety/Hybrid",
    },
    missingTestResultsText: {
        id: "recModule.recInfo.plantingEquation.missingTestResultsText",
        defaultMessage: "Missing Test Results For ",
    },
    parametersText: {
        id: "recModule.recInfo.plantingEquation.parametersText",
        defaultMessage: "Parameters",
    },
    detailText: {
        id: "recModule.recInfo.plantingEquation.detailText",
        defaultMessage: "Planting Detail",
    },
    cropText: {
        id: "recModule.recInfo.plantingEquation.cropText",
        defaultMessage: "Crop",
    },
    cropClassText: {
        id: "recModule.recInfo.plantingEquation.cropClassText",
        defaultMessage: "Crop Class",
    },
    cropPurposeText: {
        id: "recModule.recInfo.plantingEquation.cropPurposeText",
        defaultMessage: "Crop Purpose",
    },
    previousCropText: {
        id: "recModule.recInfo.plantingEquation.previousCropText",
        defaultMessage: "Previous Crop",
    },
    nextCropText: {
        id: "recModule.recInfo.plantingEquation.nextCropText",
        defaultMessage: "Next Crop",
    },
    soilTextureText: {
        id: "recModule.recInfo.plantingEquation.soilTextureText",
        defaultMessage: "Soil Texture",
    },
    tillageMethodText: {
        id: "recModule.recInfo.plantingEquation.tillageMethodText",
        defaultMessage: "Tillage Method",
    },
    applicationTimingText: {
        id: "recModule.recInfo.plantingEquation.applicationTimingText",
        defaultMessage: "Application Timing",
    },
    applicationMethodText: {
        id: "recModule.recInfo.plantingEquation.applicationMethodText",
        defaultMessage: "Application Method",
    },
    processingText: {
        id: "recModule.recInfo.plantingEquation.processingText",
        defaultMessage: "Processing",
    },
    productText: {
        id: "recModule.recInfo.plantingEquation.productText",
        defaultMessage: "Product",
    },
    incorporationDepthText: {
        id: "recModule.recInfo.plantingEquation.incorporationDepthText",
        defaultMessage: "Incorporation Depth",
    },
    buildFactorText: {
        id: "recModule.recInfo.plantingEquation.buildFactorText",
        defaultMessage: "Build Factor",
    },
    targetpHText: {
        id: "recModule.recInfo.plantingEquation.targetpHText",
        defaultMessage: "Target pH",
    },
    recOptionText: {
        id: "recModule.recInfo.plantingEquation.recOptionText",
        defaultMessage: "Rec Option",
    },
    samplingDepthText: {
        id: "recModule.recInfo.plantingEquation.samplingDepthText",
        defaultMessage: "Sampling Depth",
    },
    priceText: {
        id: "recModule.recInfo.plantingEquation.priceText",
        defaultMessage: "Price ($)",
    },
    seedCountText: {
        id: "recModule.recInfo.plantingEquation.seedCountText",
        defaultMessage: "Seed Count",
    },
    seedingRateText: {
        id: "recModule.recInfo.plantingEquation.seedingRateText",
        defaultMessage: "Seeding Rate",
    },
    managementAreaText: {
        id: "recModule.recInfo.plantingEquation.managementAreaText",
        defaultMessage: "Management Area",
    },
    normalizedYieldText: {
        id: "recModule.recInfo.plantingEquation.normalizedYieldText",
        defaultMessage: "Normalized Yield",
    },
});

export const formLabelMessage = messages.applicationEquationFormLabelText;
export const formLabelIcon = EquationIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2813, null], // ErrorCodes.RecommendationSoilTestRequired
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class RecEquationPlantingForm_ extends PureComponent {
    static propTypes = {
        activeCropGuid: PropTypes.string,
        brandList: PropTypes.array,
        calculatedArea: PropTypes.number,
        classBreaksCount: PropTypes.number.isRequired,
        classBreaksHaveOnlyOneZone: PropTypes.bool.isRequired,
        closeRecInfo: PropTypes.func,
        equationFilterLists: PropTypes.object,
        equationFilterRequired: PropTypes.object,
        fieldGuid: PropTypes.string,
        filteredEquationGroupList: PropTypes.arrayOf(
            PropTypes.shape({
                activeStatus: PropTypes.string,
                description: PropTypes.string,
                equationGroupGuid: PropTypes.string.isRequired,
                equations: PropTypes.arrayOf(
                    PropTypes.shape({
                        activeStatus: PropTypes.string,
                        cropGuid: PropTypes.string,
                        defaultProduct: PropTypes.object,
                        equation: PropTypes.string,
                        equationDescription: PropTypes.string,
                        equationGroupGuid: PropTypes.string.isRequired,
                        equationGuid: PropTypes.string.isRequired,
                        equationName: PropTypes.string.isRequired,
                        gridParameters: PropTypes.array,
                        isSelected: PropTypes.bool,
                        nutrientGuid: PropTypes.string.isRequired,
                        nutrientName: PropTypes.string.isRequired,
                        optionalUserParameters: PropTypes.arrayOf(
                            PropTypes.shape({
                                allowNegatives: PropTypes.bool,
                                analysisLayerGuid: PropTypes.string,
                                dataType: PropTypes.string,
                                dataTypeGuid: PropTypes.string,
                                decimalPlaces: PropTypes.string,
                                defaultValue: PropTypes.string,
                                equationParameterGuid: PropTypes.string,
                                label: PropTypes.string.isRequired,
                                managementAreaAnalysisLayerGuids: PropTypes.string,
                                name: PropTypes.string.isRequired,
                                precision: PropTypes.string,
                            })
                        ),
                        requiredUserParameters: PropTypes.arrayOf(
                            PropTypes.shape({
                                allowNegatives: PropTypes.bool,
                                analysisLayerGuid: PropTypes.string,
                                dataType: PropTypes.string,
                                dataTypeGuid: PropTypes.string,
                                decimalPlaces: PropTypes.string,
                                defaultValue: PropTypes.string,
                                equationParameterGuid: PropTypes.string,
                                label: PropTypes.string.isRequired,
                                managementAreaAnalysisLayerGuids: PropTypes.string,
                                name: PropTypes.string.isRequired,
                                precision: PropTypes.string,
                            })
                        ),
                        vendorGuid: PropTypes.string,
                    })
                ),
                gridParameters: PropTypes.arrayOf(
                    PropTypes.shape({
                        displayName: PropTypes.string.isRequired,
                        hasData: PropTypes.bool.isRequired,
                        name: PropTypes.string.isRequired,
                    })
                ),
                groupName: PropTypes.string.isRequired,
            })
        ),
        intl: intlShape.isRequired,
        isBatch: PropTypes.bool.isRequired,
        isEquationsLoading: PropTypes.bool.isRequired,
        isLoadingStatus: PropTypes.bool.isRequired,
        onRefreshDropdowns: PropTypes.func,
        onUpdateCurrentRecAreaRec: PropTypes.func.isRequired,
        recAreaId: PropTypes.any,
        recModel: PropTypes.object,
        refreshEquationFilterLists: PropTypes.func.isRequired,
        samplingDepthList: PropTypes.array,
        saveRecDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        saveRecDetailsErrorFieldGuidList: PropTypes.arrayOf(PropTypes.string),
        setActiveNutrient: PropTypes.func.isRequired,
        setGeneralAdjustments: PropTypes.func,
        varietyHybridList: PropTypes.array,
    };

    constructor(props) {
        super(props);
        const { recModel } = props;
        const { recPlanting } = recModel;
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            isParametersExpanded: true,
            isDetailExpanded: true,
            isSurfaceActive: false,
            isNew: !recModel.recGuid,
        };

        if (recPlanting.cropGuid) {
            const newBrandOrgGuid =
                recPlanting.recPlantingVarietyHybridList.length > 0
                    ? recPlanting.recPlantingVarietyHybridList[0].brandOrganizationGuid
                    : null;
            this.refreshCropDropdownLists(recPlanting.cropGuid, newBrandOrgGuid);
        }
    }

    UNSAFE_componentWillMount() {
        const { recModel } = this.props;
        const cropGuid = recModel.recPlanting != null ? recModel.recPlanting.cropGuid : null;
        const brandOrganizationGuid =
            recModel.recPlanting.recPlantingVarietyHybridList.length === 0
                ? null
                : recModel.recPlanting.recPlantingVarietyHybridList[0].brandOrganizationGuid;
        this.refreshCropDropdownLists(cropGuid, brandOrganizationGuid);
    }

    componentDidMount() {
        this.props.refreshEquationFilterLists(this.props.fieldGuid);
        this._updateRec({ isRecDirty: true }); // hack to turn the run button on to reprocess corrupt equation/grid data
    }

    _toggleParametersBucket() {
        this.setState({
            isParametersExpanded: !this.state.isParametersExpanded,
        });
    }

    _toggleDetailBucket() {
        this.setState({
            isDetailExpanded: !this.state.isDetailExpanded,
        });
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveRecDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveRecDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _getOptionList(options = [], valuekey = GUID, labelKey = NAME) {
        return options.map((option) => ({
            value: option[valuekey],
            label: option[labelKey],
            activeYn:
                option[adminData.PROPS_ACTIVE_YN] !== undefined
                    ? option[adminData.PROPS_ACTIVE_YN]
                    : true,
        }));
    }

    _updateRec(newProps) {
        this.props.onUpdateCurrentRecAreaRec({ ...newProps });
    }

    _updatePlantingDetail(targetProp, newProps, props = this.props) {
        const { recModel } = props;

        const { recPlantingVarietyHybridList } = recModel.recPlanting;
        if (recPlantingVarietyHybridList.length === 0) {
            return;
        }

        const vh = recPlantingVarietyHybridList[0];
        const valueUpdated = vh[targetProp] !== newProps[targetProp];
        this._updateRec({
            recPlanting: {
                ...recModel.recPlanting,
                recPlantingVarietyHybridList: [
                    {
                        ...vh,
                        ...newProps,
                    },
                ],
            },
        });
        if (valueUpdated) {
            this.props.setGeneralAdjustments(true);
        }
    }

    _updateEquationFilters(newProps) {
        const { recModel } = this.props;

        const existingProps = newProps.cropGuid
            ? new recsModels.RecEquationFilter()
            : recModel.equationFilters;
        const newEquationParameters = newProps.cropGuid ? [] : recModel.equationParameters;
        const newVarietyHybridSection =
            recModel.recPlanting.recPlantingVarietyHybridList.length === 0
                ? [new recsModels.RecPlantingVarietyHybrid()]
                : newProps.cropGuid
                ? [
                      {
                          ...recModel.recPlanting.recPlantingVarietyHybridList[0],
                          brandOrganizationGuid: null,
                          varietyHybridGuid: null,
                          seedingRate: null,
                          seedCount: null,
                          pricePerUnit: null,
                      },
                  ]
                : recModel.recPlanting.recPlantingVarietyHybridList;

        const newEquationFilters = {
            ...existingProps,
            ...newProps,
            activeEquationGroupGuid: recModel.equationGroupGuid,
            recEquationFilterGuid: recModel.equationFilters.recEquationFilterGuid,
            recGuid: recModel.recGuid,
        };

        this._updateRec({
            equationFilters: newEquationFilters,
            equationParameters: newEquationParameters,
            isRecDirty:
                recModel.isRecDirty ||
                JSON.stringify(recModel.equationFilters) !== JSON.stringify(newEquationFilters),
            recPlanting: {
                ...recModel.recPlanting,
                cropGuid:
                    newProps.cropGuid !== undefined
                        ? newProps.cropGuid
                        : recModel.recPlanting.cropGuid,
                recPlantingVarietyHybridList: newVarietyHybridSection,
            },
        });
    }

    _updateEquationParameter(name, value) {
        const { recModel } = this.props;

        const newEquationParameters = [...recModel.equationParameters];
        const paramIndex = recModel.equationParameters.findIndex((param) => param.name === name);

        if (paramIndex !== -1 && recModel.equationParameters[paramIndex].value !== value) {
            const dataType = recModel.equationParameters[paramIndex].dataType
                ? recModel.equationParameters[paramIndex].dataType.toLowerCase()
                : "";
            const isRecDirty = recModel.isRecDirty
                ? true
                : ["decimal", "integer"].includes(dataType)
                ? Number(recModel.equationParameters[paramIndex].value) !== Number(value)
                : recModel.equationParameters[paramIndex].value !== value;

            const newEquationParameter = {
                ...newEquationParameters[paramIndex],
                value: name === MANAGEMENT_AREA ? value.join(",") : value,
                analysisLayerGuid: name === NORMALIZED_YIELD ? value : null,
                managementAreaAnalysisLayerGuids: name === MANAGEMENT_AREA ? value : null,
            };
            newEquationParameters.splice(paramIndex, 1, newEquationParameter);

            this._updateRec({
                equationParameters: newEquationParameters,
                isRecDirty,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.saveRecDetailsErrorCodeList !== this.props.saveRecDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(nextProps);
            this.setState({ errorMessagePlaceholderSet });
        }

        for (const param of nextProps.recModel.equationParameters) {
            const dataType = param.dataType ? param.dataType.toLowerCase() : "";
            if (
                ["decimal", "integer"].includes(dataType) &&
                param.value != null &&
                param.value !== ""
            ) {
                param.value = Number(param.value);
            }
        }

        const equationsFinishedLoading =
            this.props.isEquationsLoading !== nextProps.isEquationsLoading &&
            nextProps.isEquationsLoading === false;
        const equationParametersChanged = !deepEqual(
            this.props.recModel.equationParameters,
            nextProps.recModel.equationParameters
        );
        const equationFiltersChanged = !deepEqual(
            this.props.recModel.equationFilters,
            nextProps.recModel.equationFilters
        );
        const recIsDirtyChanged = this.props.recModel.isRecDirty !== nextProps.recModel.isRecDirty;
        const filteredEquationGroupListChanged =
            JSON.stringify(this.props.filteredEquationGroupList) !==
            JSON.stringify(nextProps.filteredEquationGroupList);
        const isDifferentZone = this.props.recAreaId !== nextProps.recAreaId;
        const allRequiredChanged =
            !utils.allRequiredFieldsSet(this.props) && utils.allRequiredFieldsSet(nextProps);
        const equationParametersNotSetup =
            nextProps.recModel.equationParameters.filter((eq) => !Object.hasOwn(eq, "required"))
                .length > 0;
        // const hasBeenRun = Boolean(nextProps.recModel.recGuid);

        if (
            !isDifferentZone && // prevents a race condition when switching zones
            (equationParametersChanged ||
                equationFiltersChanged ||
                recIsDirtyChanged ||
                filteredEquationGroupListChanged ||
                equationsFinishedLoading ||
                (equationParametersNotSetup && equationsFinishedLoading) ||
                allRequiredChanged)
        ) {
            const { equationGroupGuid, equationParameters } = nextProps.recModel;
            const activeEquationGroup = nextProps.filteredEquationGroupList.find(
                (eg) => eg.equationGroupGuid === equationGroupGuid
            );
            const newProps = {};
            if (!equationsFinishedLoading) {
                newProps.isIncluded =
                    (!this.state.isNew &&
                        nextProps.recModel.recPlanting.recPlantingVarietyHybridList.length > 0) ||
                    (this.state.isNew &&
                        activeEquationGroup &&
                        (nextProps.recModel.isIncluded || allRequiredChanged));
            }
            newProps.isEquationRunEnabled =
                !nextProps.isEquationsLoading &&
                utils.allRequiredFieldsSet(nextProps) &&
                nextProps.recModel.isRecDirty;

            if (
                (activeEquationGroup && activeEquationGroup.equations.length === 1) ||
                equationParametersNotSetup
            ) {
                const equationGuid = activeEquationGroup.equations[0].equationGuid;
                const activeEquationParameters =
                    activeEquationGroup.equations[0].requiredUserParameters.concat(
                        activeEquationGroup.equations[0].optionalUserParameters
                    );
                const equationParametersMatch = activeEquationParameters.every(
                    (recParam) =>
                        equationParameters.find((param) => recParam.name === param.name) != null
                );
                if (
                    (nextProps.recModel.recPlanting.recPlantingVarietyHybridList.length > 0 &&
                        nextProps.recModel.recPlanting.recPlantingVarietyHybridList[0]
                            .equationGuid !== equationGuid) ||
                    !equationParametersMatch ||
                    equationParametersNotSetup
                ) {
                    const newRequiredParameters = [];
                    const newOptionalParameters = [];
                    activeEquationGroup.equations.forEach((equation) => {
                        const { requiredUserParameters, optionalUserParameters } = equation;

                        requiredUserParameters.forEach((param) => {
                            const existingParameter = equationParameters.find(
                                (eqParam) =>
                                    param.name === eqParam.name ||
                                    (eqParam.analysisLayerGuid &&
                                        param.name === NORMALIZED_YIELD) ||
                                    (eqParam.managementAreaAnalysisLayerGuids &&
                                        param.name === MANAGEMENT_AREA)
                            );
                            const isUsedAlready = newRequiredParameters.some(
                                (eqParam) => param.name === eqParam.name
                            );
                            if (!existingParameter && !isUsedAlready) {
                                newRequiredParameters.push({
                                    ...param,
                                    value:
                                        param.name === NORMALIZED_YIELD ||
                                        param.name === MANAGEMENT_AREA
                                            ? null
                                            : param.defaultValue,
                                    required: true,
                                });
                            } else if (!isUsedAlready) {
                                if (param.name === NORMALIZED_YIELD) {
                                    newRequiredParameters.push({
                                        ...param,
                                        analysisLayerGuid: existingParameter
                                            ? existingParameter.analysisLayerGuid
                                            : null,
                                        value: existingParameter
                                            ? existingParameter.analysisLayerGuid
                                            : null,
                                        required: true,
                                    });
                                } else if (param.name === MANAGEMENT_AREA) {
                                    newRequiredParameters.push({
                                        ...param,
                                        managementAreaAnalysisLayerGuids: existingParameter
                                            ? existingParameter.managementAreaAnalysisLayerGuids
                                            : null,
                                        value:
                                            existingParameter &&
                                            existingParameter.managementAreaAnalysisLayerGuids
                                                ? existingParameter.managementAreaAnalysisLayerGuids.join(
                                                      ","
                                                  )
                                                : null,
                                        required: true,
                                    });
                                } else {
                                    const defaultValueChange =
                                        param.defaultValue &&
                                        existingParameter.defaultValue &&
                                        param.defaultValue !== existingParameter.defaultValue;
                                    newRequiredParameters.push({
                                        ...param,
                                        value:
                                            existingParameter && !defaultValueChange
                                                ? existingParameter.value
                                                : param.defaultValue,
                                        required: true,
                                    });
                                }
                            }
                        });
                        optionalUserParameters.forEach((param) => {
                            const existingParameter = equationParameters.find(
                                (eqParam) => param.name === eqParam.name
                            );
                            const isUsedAlready = newOptionalParameters.some(
                                (eqParam) => param.name === eqParam.name
                            );
                            if (!existingParameter && !isUsedAlready) {
                                newOptionalParameters.push({
                                    ...param,
                                    value: param.defaultValue,
                                    required: false,
                                });
                            } else if (!isUsedAlready) {
                                newOptionalParameters.push({
                                    ...param,
                                    value: existingParameter
                                        ? existingParameter.value
                                        : param.defaultValue,
                                    required: false,
                                });
                            }
                        });
                    });

                    const newEquationParameters = [
                        ...newRequiredParameters,
                        ...newOptionalParameters,
                    ];

                    newProps.recPlanting = {
                        ...nextProps.recModel.recPlanting,
                        recPlantingVarietyHybridList: [
                            {
                                ...nextProps.recModel.recPlanting.recPlantingVarietyHybridList[0],
                                equationGuid,
                            },
                        ],
                    };

                    const managementAreaParameter = newEquationParameters.find(
                        (param) => param.name === MANAGEMENT_AREA
                    );
                    const { managementAreaLayerOptions } = nextProps.equationFilterLists;

                    if (managementAreaParameter != null) {
                        if (nextProps.fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID) {
                            managementAreaParameter.required = false;
                        } else if (
                            managementAreaParameter.value == null &&
                            managementAreaLayerOptions.length === 1
                        ) {
                            managementAreaParameter.managementAreaAnalysisLayerGuids = [
                                managementAreaLayerOptions[0].value,
                            ];
                            managementAreaParameter.value = managementAreaLayerOptions[0].value;
                        }
                    }

                    newProps.equationParameters = newEquationParameters;
                }
            }

            this._updateRec(newProps);
        }

        const { recModel } = nextProps;
        const { recPlanting } = recModel;
        if (
            (recPlanting.cropGuid !== this.props.recModel.recPlanting.cropGuid &&
                recPlanting.cropGuid) ||
            (recPlanting.recPlantingVarietyHybridList.length > 0 &&
                (this.props.recModel.recPlanting.recPlantingVarietyHybridList.length === 0 ||
                    recPlanting.recPlantingVarietyHybridList[0].brandOrganizationGuid !==
                        this.props.recModel.recPlanting.recPlantingVarietyHybridList[0]
                            .brandOrganizationGuid))
        ) {
            // Crop/brand changed, get the new list(s)
            const newBrandOrgGuid =
                recPlanting.recPlantingVarietyHybridList.length > 0
                    ? recPlanting.recPlantingVarietyHybridList[0].brandOrganizationGuid
                    : null;
            this.refreshCropDropdownLists(nextProps.recModel.recPlanting.cropGuid, newBrandOrgGuid);
        }
    }

    _buildFilterInputRows() {
        const {
            recModel,
            equationFilterLists,
            equationFilterRequired,
            filteredEquationGroupList,
            samplingDepthList,
        } = this.props;
        const { equationFilters, equationParameters } = recModel;
        const { formatMessage } = this.props.intl;

        const {
            cropListOptions,
            cropClassListOptions,
            cropPurposeListOptions,
            previousCropListOptions,
            nextCropListOptions,
            soilTextureListOptions,
            tillageMethodListOptions,
            applicationTimingListOptions,
            applicationMethodListOptions,
            productListOptions,
            incorporationDepthListOptions,
            buildFactorListOptions,
            targetpHListOptions,
            recOptionListOptions,
        } = equationFilterLists;

        const {
            cropRequired,
            cropClassRequired,
            cropPurposeRequired,
            previousCropRequired,
            nextCropRequired,
            soilTextureRequired,
            tillageMethodRequired,
            applicationTimingRequired,
            applicationMethodRequired,
            productRequired,
            incorporationDepthRequired,
            buildFactorRequired,
            targetpHRequired,
            recOptionRequired,
        } = equationFilterRequired;

        const cropFilterApplied = equationFilters.cropGuid || cropListOptions.length === 0;
        const activeEquationGroup = filteredEquationGroupList.find(
            (eg) => eg.equationGroupGuid === recModel.equationGroupGuid
        );
        const {
            cropListVisible,
            cropClassListVisible,
            cropPurposeListVisible,
            previousCropListVisible,
            nextCropListVisible,
            soilTextureListVisible,
            tillageMethodListVisible,
            applicationTimingListVisible,
            applicationMethodListVisible,
            productListVisible,
            incorporationDepthListVisible,
            buildFactorListVisible,
            targetpHListVisible,
            recOptionListVisible,
        } = utils.getEquationFilterVisible(
            equationFilterLists,
            equationFilterRequired,
            cropFilterApplied
        );
        const filterInputs = [];

        if (samplingDepthList.length > 1) {
            filterInputs.push(
                <SelectInput
                    required={true}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={false}
                    onChange={(v) => this._updateRec({ depthId: v, isRecDirty: true })}
                    options={samplingDepthList}
                    placeholderText={formatMessage(messages.samplingDepthText)}
                    value={recModel.depthId}
                />
            );
        }

        if (cropListVisible) {
            filterInputs.push(
                <SelectInput
                    required={cropRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!cropRequired}
                    onChange={(v) => this._updateEquationFilters({ cropGuid: v })}
                    options={cropListOptions}
                    placeholderText={formatMessage(messages.cropText)}
                    value={equationFilters.cropGuid}
                />
            );
        }
        if (cropClassListVisible) {
            filterInputs.push(
                <SelectInput
                    required={cropClassRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!cropClassRequired}
                    onChange={(v) => this._updateEquationFilters({ cropClassNameGuid: v })}
                    options={cropClassListOptions}
                    placeholderText={formatMessage(messages.cropClassText)}
                    value={equationFilters.cropClassNameGuid}
                />
            );
        }
        if (cropPurposeListVisible) {
            filterInputs.push(
                <SelectInput
                    required={cropPurposeRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!cropPurposeRequired}
                    onChange={(v) => this._updateEquationFilters({ cropPurposeGuid: v })}
                    options={cropPurposeListOptions}
                    placeholderText={formatMessage(messages.cropPurposeText)}
                    value={equationFilters.cropPurposeGuid}
                />
            );
        }
        if (previousCropListVisible) {
            filterInputs.push(
                <SelectInput
                    required={previousCropRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!previousCropRequired}
                    onChange={(v) => this._updateEquationFilters({ previousCropGuid: v })}
                    options={previousCropListOptions}
                    placeholderText={formatMessage(messages.previousCropText)}
                    value={equationFilters.previousCropGuid}
                />
            );
        }
        if (nextCropListVisible) {
            filterInputs.push(
                <SelectInput
                    required={nextCropRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!nextCropRequired}
                    onChange={(v) => this._updateEquationFilters({ nextCropGuid: v })}
                    options={nextCropListOptions}
                    placeholderText={formatMessage(messages.nextCropText)}
                    value={equationFilters.nextCropGuid}
                />
            );
        }
        if (soilTextureListVisible) {
            filterInputs.push(
                <SelectInput
                    required={soilTextureRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!soilTextureRequired}
                    onChange={(v) => this._updateEquationFilters({ soilTextureGuid: v })}
                    options={soilTextureListOptions}
                    placeholderText={formatMessage(messages.soilTextureText)}
                    value={equationFilters.soilTextureGuid}
                />
            );
        }
        if (tillageMethodListVisible) {
            filterInputs.push(
                <SelectInput
                    required={tillageMethodRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!tillageMethodRequired}
                    onChange={(v) => this._updateEquationFilters({ tillageMethodGuid: v })}
                    options={tillageMethodListOptions}
                    placeholderText={formatMessage(messages.tillageMethodText)}
                    value={equationFilters.tillageMethodGuid}
                />
            );
        }
        if (applicationTimingListVisible) {
            filterInputs.push(
                <SelectInput
                    required={applicationTimingRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!applicationTimingRequired}
                    onChange={(v) =>
                        this._updateEquationFilters({
                            applicationTimingGuid: v,
                        })
                    }
                    options={applicationTimingListOptions}
                    placeholderText={formatMessage(messages.applicationTimingText)}
                    value={equationFilters.applicationTimingGuid}
                />
            );
        }
        if (applicationMethodListVisible) {
            filterInputs.push(
                <SelectInput
                    required={applicationMethodRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!applicationMethodRequired}
                    onChange={(v) =>
                        this._updateEquationFilters({
                            applicationMethodGuid: v,
                        })
                    }
                    options={applicationMethodListOptions}
                    placeholderText={formatMessage(messages.applicationMethodText)}
                    value={equationFilters.applicationMethodGuid}
                />
            );
        }
        if (productListVisible) {
            filterInputs.push(
                <SelectInput
                    required={productRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!productRequired}
                    onChange={(v) => this._updateEquationFilters({ productGuid: v })}
                    options={productListOptions}
                    placeholderText={formatMessage(messages.productText)}
                    value={equationFilters.productGuid}
                />
            );
        }
        if (incorporationDepthListVisible) {
            filterInputs.push(
                <SelectInput
                    required={incorporationDepthRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!incorporationDepthRequired}
                    onChange={(v) =>
                        this._updateEquationFilters({
                            incorporationDepthGuid: v,
                        })
                    }
                    options={incorporationDepthListOptions}
                    placeholderText={formatMessage(messages.incorporationDepthText)}
                    value={equationFilters.incorporationDepthGuid}
                />
            );
        }
        if (buildFactorListVisible) {
            filterInputs.push(
                <SelectInput
                    required={buildFactorRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!buildFactorRequired}
                    onChange={(v) => this._updateEquationFilters({ buildFactorGuid: v })}
                    options={buildFactorListOptions}
                    placeholderText={formatMessage(messages.buildFactorText)}
                    value={equationFilters.buildFactorGuid}
                />
            );
        }
        if (targetpHListVisible) {
            filterInputs.push(
                <SelectInput
                    required={targetpHRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!targetpHRequired}
                    onChange={(v) => this._updateEquationFilters({ targetpHGuid: v })}
                    options={targetpHListOptions}
                    placeholderText={formatMessage(messages.targetpHText)}
                    value={equationFilters.targetpHGuid}
                />
            );
        }
        if (recOptionListVisible) {
            filterInputs.push(
                <SelectInput
                    required={recOptionRequired}
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={!recOptionRequired}
                    onChange={(v) => this._updateEquationFilters({ recOptionGuid: v })}
                    options={recOptionListOptions}
                    placeholderText={formatMessage(messages.recOptionText)}
                    value={equationFilters.recOptionGuid}
                />
            );
        }
        if (cropFilterApplied && activeEquationGroup) {
            equationParameters.forEach((param) => {
                if (Object.hasOwn(param, "required")) {
                    filterInputs.push(this._buildParameterInput(param));
                }
            });
        }
        const filterInputRows = [];
        for (let i = 0; i < filterInputs.length; i += 2) {
            const isHalf = !filterInputs[i + 1];
            filterInputRows.push(
                <div
                    key={`filter-row-${filterInputRows.length}`}
                    className={classnames("input-row", { half: isHalf })}
                >
                    {!filterInputs[i] ? null : filterInputs[i]}
                    {!filterInputs[i + 1] ? null : filterInputs[i + 1]}
                </div>
            );
        }
        return filterInputRows;
    }

    _buildParameterInput(param) {
        const { equationFilterLists, fieldGuid } = this.props;
        const { managementAreaLayerOptions, normalizedYieldLayerOptions } = equationFilterLists;
        const { formatMessage } = this.props.intl;

        const { dataType, name } = param;
        const inputType = dataType ? dataType.toLowerCase() : name ? name.toLowerCase() : "";

        switch (inputType) {
            case "decimal":
                return (
                    <NumericInput
                        placeholderText={param.label}
                        value={param.value != null && param.value !== "" ? Number(param.value) : ""}
                        required={param.required}
                        precision={Number(param.precision)}
                        scale={Number(param.decimalPlaces)}
                        onChange={(strVal, formattedVal, numVal) =>
                            this._updateEquationParameter(param.name, numVal)
                        }
                    />
                );
            case "yesno":
                return (
                    <Checkbox
                        onChange={(evt, v) => this._updateEquationParameter(param.name, v)}
                        value={
                            param.value == null || Number(param.value) === 0
                                ? false
                                : Boolean(param.value)
                        }
                        label={param.label}
                    />
                );
            case "integer":
                return (
                    <NumericInput
                        placeholderText={param.label}
                        value={param.value != null && param.value !== "" ? Number(param.value) : ""}
                        required={param.required}
                        precision={Number(param.precision)}
                        scale={0}
                        onChange={(strVal, formattedVal, numVal) =>
                            this._updateEquationParameter(param.name, numVal)
                        }
                    />
                );
            case MANAGEMENT_AREA.toLowerCase():
                return fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID ? (
                    <SelectInputMulti
                        required={true}
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        onChange={(v) => this._updateEquationParameter(param.name, v)}
                        options={managementAreaLayerOptions}
                        placeholder={formatMessage(messages.managementAreaText)}
                        value={param.managementAreaAnalysisLayerGuids}
                    />
                ) : null;
            case NORMALIZED_YIELD.toLowerCase():
                return (
                    <SelectInput
                        required={true}
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        onChange={(v) => this._updateEquationParameter(param.name, v)}
                        options={normalizedYieldLayerOptions}
                        placeholderText={formatMessage(messages.normalizedYieldText)}
                        value={param.value}
                    />
                );
            default:
                return null;
        }
    }

    _toggleSurface() {
        const cropGuid = this.props.recModel.recPlanting.cropGuid;
        this.props.setActiveNutrient(
            this.props.activeCropGuid && this.props.activeCropGuid === cropGuid ? null : cropGuid
        );
    }

    _buildDetailDisplay() {
        const { recModel, brandList, varietyHybridList } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        const { recPlanting } = recModel;
        const { recPlantingVarietyHybridList } = recPlanting;
        const varietyDetail =
            recPlantingVarietyHybridList[0] || new recsModels.RecPlantingVarietyHybrid();

        const missingEquationAttributesList = varietyDetail.missingEquationAttributes
            ? varietyDetail.missingEquationAttributes.map(
                  (missingAttribute) => missingAttribute.description
              )
            : [];
        const missingEquationAttributeMessage =
            missingEquationAttributesList.length > 0
                ? `${missingEquationAttributesList.join(", ")}`
                : "";
        let showErrorBox = Boolean(missingEquationAttributeMessage);

        return (
            <div className="nutrient-product-display">
                <div className="nutrient-product">
                    <div className="product-info-section">
                        {!["complete", "exported"].includes(recModel.recStatus.toLowerCase()) || (
                            <div className="product-surface-radio">
                                <RadioButton
                                    value={true}
                                    checked={this.props.activeCropGuid === recPlanting.cropGuid}
                                    onChange={(evt, value) => this._toggleSurface(value)}
                                    ternary={true}
                                />
                            </div>
                        )}
                        <div
                            className={classnames("nutrient-name", {
                                "no-surface": !recModel.recGuid,
                            })}
                        >
                            {formatMessage(messages.seedingRateText)}
                        </div>
                        {!varietyDetail.seedingRate ? null : (
                            <div className="nutrient-rate">
                                {`Rec: ${formatNumber(varietyDetail.seedingRate, {
                                    maximumFractionDigits: 2,
                                })} seed/ac`}
                            </div>
                        )}
                    </div>

                    <div className={classnames("product-blend-section")}>
                        <div className="product-blend">
                            <div className="input-row">
                                <SelectInput
                                    options={this._getOptionList(brandList)}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    placeholderText={formatMessage(messages.brandPlaceholderText)}
                                    disabled={!(brandList.length > 0 && recPlanting.cropGuid)}
                                    onChange={(v) =>
                                        this._updatePlantingDetail("brandOrganizationGuid", {
                                            brandOrganizationGuid: v || "",
                                        })
                                    }
                                    value={varietyDetail.brandOrganizationGuid}
                                />
                                <SelectInput
                                    options={this._getOptionList(varietyHybridList)}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    placeholderText={formatMessage(messages.varietyPlaceholderText)}
                                    disabled={
                                        !(
                                            brandList.length > 0 &&
                                            varietyDetail.brandOrganizationGuid
                                        )
                                    }
                                    onChange={(v) =>
                                        this._updatePlantingDetail("varietyHybridGuid", {
                                            varietyHybridGuid: v,
                                        })
                                    }
                                    value={varietyDetail.varietyHybridGuid}
                                />
                            </div>
                            <div className="input-row">
                                <NumericInput
                                    containerClassNames={["seed-count-input"]}
                                    scale={0}
                                    precision={9}
                                    placeholderText={formatMessage(messages.seedCountText)}
                                    onChange={(strVal, formattedVal, numVal) =>
                                        this._updatePlantingDetail("seedCount", {
                                            seedCount: numVal,
                                        })
                                    }
                                    value={varietyDetail.seedCount}
                                />

                                <NumericInput
                                    containerClassNames={["price-input"]}
                                    scale={2}
                                    precision={9}
                                    placeholderText={formatMessage(messages.priceText)}
                                    onChange={(strVal, formattedVal, numVal) => {
                                        this._updatePlantingDetail("pricePerUnit", {
                                            pricePerUnit: numVal,
                                        });
                                    }}
                                    value={varietyDetail.pricePerUnit}
                                />
                                <div className="price-unit">unit</div>
                            </div>
                        </div>
                    </div>

                    {!showErrorBox ? null : (
                        <div
                            className={classnames("missing-equation-attribute-warning-message", {
                                "missing-equation-attribute-error-message":
                                    !recPlanting.equationSuccess,
                            })}
                        >
                            {`${formatMessage(
                                messages.missingTestResultsText
                            )} ${missingEquationAttributeMessage}`}
                        </div>
                    )}
                </div>
            </div>
        );
    }
    refreshCropDropdownLists = (cropGuid, brandOrganizationGuid) => {
        const { onRefreshDropdowns } = this.props;
        if (!cropGuid) {
            return;
        } else {
            let refreshDropdowns = {};
            refreshDropdowns.brandList = {
                url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
                model: cropGuid,
            };
            if (brandOrganizationGuid) {
                refreshDropdowns.varietyHybridList = {
                    url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
                    model: {
                        cropId: cropGuid,
                        brandOrganization: brandOrganizationGuid,
                    },
                };
            }
            onRefreshDropdowns(refreshDropdowns);
        }
    };

    render() {
        const { formatMessage } = this.props.intl;
        const {
            classBreaksCount,
            classBreaksHaveOnlyOneZone,
            recModel,
            fieldGuid,
            isBatch,
            isEquationsLoading,
            isLoadingStatus,
        } = this.props;

        const isIncludedCbStyle = {
            position: "relative",
            left: "76%",
            marginTop: -35,
            top:
                classBreaksCount === 0
                    ? 0
                    : classBreaksCount * -28 - (classBreaksHaveOnlyOneZone ? 2 : 15),
        };

        const isBatchTemplate = fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;
        const isRegularLoader = !isBatch || isBatchTemplate;

        return (
            <div className="rec-equation-application-form">
                {isEquationsLoading || isLoadingStatus ? (
                    <Loader
                        className={classnames(
                            { "batch-equation-rec-loader": !isRegularLoader },
                            { "equation-rec-loader": isRegularLoader }
                        )}
                    />
                ) : null}
                {recModel.recGuid || isBatchTemplate ? null : (
                    <div className="is-included-checkbox" style={isIncludedCbStyle}>
                        <Checkbox
                            onChange={(evt, v) => this._updateRec({ isIncluded: v })}
                            label={"Include"}
                            reverseLabel={true}
                            value={recModel.isIncluded}
                            disabled={!utils.allRequiredFieldsSet(this.props)}
                        />
                    </div>
                )}
                {recModel.recGuid && !recModel.isIncluded ? null : (
                    <Bucket
                        className="equation-application-bucket"
                        isExpanded={this.state.isParametersExpanded}
                        onBucketToggle={(isExpanded) => this._toggleParametersBucket(isExpanded)}
                    >
                        <BucketHeader className="equation-application-bucket-header">
                            {formatMessage(messages.parametersText)}
                        </BucketHeader>
                        {this.props.isEquationsLoading ? null : this._buildFilterInputRows()}
                    </Bucket>
                )}
                {recModel.recGuid && !recModel.isIncluded ? null : (
                    <Bucket
                        className="equation-application-bucket"
                        isExpanded={this.state.isDetailExpanded}
                        onBucketToggle={(isExpanded) => this._toggleDetailBucket(isExpanded)}
                    >
                        <BucketHeader className="equation-application-bucket-header">
                            {formatMessage(messages.detailText)}
                        </BucketHeader>
                        {this.props.isEquationsLoading ? null : this._buildDetailDisplay()}
                    </Bucket>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { fieldGuidToRecDetails, saveRecDetailsErrorCodeList, saveRecDetailsErrorFieldGuidList } =
        recsSelectors.getModuleState(state);
    const recDetails = fieldGuidToRecDetails.get(ownProps.fieldGuid);

    const classBreakIdSet = new Set(
        recDetails.recAreaList
            .filter((area) => area.recAreaClassBreak != null)
            .map((area) => area.recAreaClassBreak.classId)
    );
    const classBreaksCount = classBreakIdSet.size;

    const classBreaksHaveOnlyOneZone = classBreaksCount === recDetails.recAreaList.length;

    const isLoadingStatus = !(
        recsEventsModels.TERMINAL_STATUS_CODES.includes(recDetails.importedStatus) ||
        !recDetails.recAreaList.some((recArea) =>
            recArea.recs.some((rec) => rec.isAnyRecAdjustmentProcessing)
        )
    );

    const dropdownData = selectors.getDropdownState(state);

    return {
        activeCropGuid: selectors.getActiveNutrientGuid(state),
        classBreaksCount,
        classBreaksHaveOnlyOneZone,
        ...dropdownData,
        saveRecDetailsErrorCodeList,
        saveRecDetailsErrorFieldGuidList,
        filteredEquationGroupList: selectors.getFilteredEquationGroupList(state),
        equationFilterLists: selectors.getEquationFilterLists(state),
        equationFilterRequired: selectors.getEquationFilterRequired(state),
        isBatch: fieldGuidToRecDetails.size > 1,
        isEquationsLoading: selectors.getEquationsLoading(state),
        isLoadingStatus,
        samplingDepthList: selectors.getSamplingDepthList(state) ?? [],
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeRecInfo: (isCancel = false) => dispatch(actions.closeRecInfo(isCancel)),
    onRefreshDropdowns: (dropdowns) => {
        dispatch(
            fetchDropdownData({
                ...dropdowns,
                action: actions.fetchedDropdownData,
                async: false,
            })
        );
    },
    onUpdateCurrentRecAreaRec: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateCurrentRecAreaRec(fieldGuid, newProps)),
    refreshEquationFilterLists: (fieldGuid) =>
        dispatch(actions.refreshEquationFilterLists(fieldGuid)),
    setActiveNutrient: (fieldGuid, cropGuid) =>
        dispatch(actions.setActiveNutrient(fieldGuid, cropGuid)),
    setGeneralAdjustments: (status) => dispatch(actions.setGeneralAdjustments(status)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateCurrentRecAreaRec: (newProps) =>
        dispatchProps.onUpdateCurrentRecAreaRec(ownProps.fieldGuid, newProps),
    setActiveNutrient: (cropGuid) => dispatchProps.setActiveNutrient(ownProps.fieldGuid, cropGuid),
});

export const RecEquationPlantingForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecEquationPlantingForm_));
