import { createSelector } from "reselect";

import { ACTION_PANEL_STATE_KEY } from "../../../../selectors";
import { IMPORT_MODULE_STATE_KEY } from "../../selectors";

export const IMPORT_WIZARD_KEY = "IMPORT_WIZARD";

const getImportWizardState = (state) => {
    return state[ACTION_PANEL_STATE_KEY][IMPORT_MODULE_STATE_KEY][IMPORT_WIZARD_KEY];
};

export const getAgvanceFieldList = (state) => getImportWizardState(state).agvanceFieldList;
export const getAgvanceOrgLevelList = (state) => getImportWizardState(state).agvanceOrgLevelList;
export const getApiErrorResultList = (state) => getImportWizardState(state).apiErrorResultList;
export const getAppendList = (state) => getImportWizardState(state).appendList;
export const getEventStripPreviewData = (state) =>
    getImportWizardState(state).eventStripPreviewData;
export const getCurrentFieldData = (state) => getImportWizardState(state).currentFieldData;
export const getFilteredFieldImportFileList = (state) =>
    getImportWizardState(state).filteredFieldImportFileList;
export const getForceUpdate = (state) => getImportWizardState(state).forceUpdate;
export const getHasSubmitted = (state) => getImportWizardState(state).hasSubmitted;
export const getIgnoreFarm = (state) => getImportWizardState(state).ignoreFarm;
export const getIgnoreSelectedField = (state) => getImportWizardState(state).ignoreSelectedField;
export const getImportConvexHulls = (state) => getImportWizardState(state).convexHulls;
export const getImportFieldBoundaries = (state) =>
    getImportWizardState(state).importFieldBoundaries;
export const getImportFieldList = (state) => getImportWizardState(state).importFieldList;
export const getImportFieldStripData = (state) => getImportWizardState(state).importFieldStripData;
export const getImportFileGuidList = (state) => getImportWizardState(state).importFileGuidList;
export const getImportFilterEquipmentData = (state) => getImportWizardState(state).equipmentFilter;
export const getImportFilterListData = (state) => getImportWizardState(state).importFilterListData;
export const getImportGroomingRequest = (state) =>
    getImportWizardState(state).importGroomingRequest;
export const getImportSamplingPoints = (state) => getImportWizardState(state).importSamplingPoints;
export const getImportTemplate = (state) => getImportWizardState(state).importTemplate;
export const getImportWizardDropdowns = (state) => getImportWizardState(state).dropdowns;
export const getImportWizardStep = (state) => getImportWizardState(state).importWizardStep;
export const getImportWizardType = (state) => getImportWizardState(state).importType;
export const getIsFilteringVisible = (state) => getImportWizardState(state).isFilteringVisible;
export const getIsFieldMatchingComplete = (state) =>
    getImportWizardState(state).isFieldMatchingComplete;
export const getIsFieldMatchingLoading = (state) =>
    getImportWizardState(state).isFieldMatchingLoading;
export const getIsLoading = (state) => getImportWizardState(state).isLoading;
export const getIsValidating = (state) => getImportWizardState(state).isValidating;
export const getSelectedImportFieldIndex = (state) =>
    getImportWizardState(state).selectedImportFieldIndex;
export const getSelectedMatchedFieldGuid = (state) =>
    getImportWizardState(state).selectedMatchedFieldGuid;
export const getShowImportPoints = (state) => getImportWizardState(state).showImportPoints;
export const getShowProcessing = (state) => getImportWizardState(state).showProcessing;
export const getSuccessfulNameMatchImports = (state) =>
    getImportWizardState(state).successfulNameMatchImports;
export const getUserCropImportPreferences = (state) =>
    getImportWizardState(state).userCropImportPreferences;

/**
 * Created Selectors:
 */
export const getEventStripCount = createSelector(
    [getEventStripPreviewData],
    (eventStripPreviewData) => {
        return eventStripPreviewData.length;
    }
);

export const getImportMatchedFieldGuidList = createSelector(
    [getImportFieldStripData, getFilteredFieldImportFileList],
    (importFieldStripData, filteredFieldImportFileList) => {
        const fieldGuidSet = new Set();
        filteredFieldImportFileList.forEach((entry) => {
            fieldGuidSet.add(entry.fieldGuid);
        });
        importFieldStripData.forEach((field) => {
            fieldGuidSet.add(field.fieldGuid);
        });
        fieldGuidSet.delete("");
        return Array.from(fieldGuidSet);
    }
);

export const getIsStatFilterOn = createSelector(
    [getImportGroomingRequest],
    (importGroomingRequest) => {
        return importGroomingRequest.statFilter;
    }
);

export const getIsYieldFilterOn = createSelector(
    [getImportGroomingRequest],
    (importGroomingRequest) => {
        return importGroomingRequest.yieldFilter;
    }
);

export const getIsSingleStep = createSelector([getImportFilterListData], (importFilterListData) => {
    return importFilterListData.isSingleStep;
});

export const getImportGroomingSessionGuid = createSelector(
    [getImportFilterListData],
    (importFilterListData) => {
        return importFilterListData.groomingSessionGuid;
    }
);
