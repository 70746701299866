import * as immutableUtils from "~/utils/immutable";

import { accordionReducer, model as accordionModel } from "~/accordion";

import { getFieldGuidFromDimIdx } from "../../../common/accordion/model";
import { getEventGuidFromDimIdx } from "../../../common/accordion/rec-event-accordion-item";
import * as commonReducer from "../../../common/rec-event-reducer";

import { RecEventListTabs } from "~/recs-events/actions";
import * as actions from "./actions";
import * as selectors from "./selectors";
import { actions as recsEventsActions } from "~/recs-events";
import * as eventActions from "~/recs-events/events/actions";

import { filterOutEventsFromSetMap, filterOutEventsFromSet } from "./data";
import { logFirebaseEvent } from "~/utils/firebase";

export const eventListInitialState = Object.freeze({
    showFilters: false,
    searchValue: "",
    isPanelLoading: false,
    isEventLoading: false,

    filter: {
        cropGuid: "",
        onlySelected: false,
        eventType: "",
        seasonGuid: "",
        activeTab: RecEventListTabs.ACTIVE,
    },
    filterInfo: {
        croppingSeasons: {},
        crops: {},
        filters: {},
        eventTypes: {},
    },
    filterOptions: {
        croppingSeasonOptions: [],
        cropOptions: [],
        eventTypeOptions: [],
    },
    filterValueCount: "",
    allAccordionInit: {
        initScrollTop: 0,
        lastClickedDimIdx: [0],
    },
    selectedAccordionInit: {
        initScrollTop: 0,
        lastClickedDimIdx: [0],
    },
    inactiveAccordionInit: {
        initScrollTop: 0,
        lastClickedDimIdx: [0],
    },

    mergeableEvents: null,
    showYieldCalibrationModal: false,
    showMergeEventsModal: false,
    yieldCalibrationInProgress: false,
    expandedFieldGuidSet: new Set(),
    selectedEventGuidSet: new Set(),
    fieldGuidToSelectedEventGuidSetMap: new Map(),
    errorDetailsToShow: null,
    errorCodes: null,

    [selectors.ACCORDION_KEY]: accordionReducer(undefined, { type: "INIT" }),
});

export const eventListReducer = (state = eventListInitialState, action) => {
    switch (action.type) {
        case actions.CLEAR_EVENT_FILTER: {
            const eventFilter = {
                cropGuid: null,
                eventType: null,
                seasonGuid: null,
            };
            const filter = {
                ...state.filter,
                ...eventFilter,
            };
            return Object.freeze({
                ...state,
                filter,
                filterValueCount: null,
            });
        }
        case actions.DESELECT_EVENTS_FOR_FIELD_GUIDS: {
            const { fieldGuidsToRemove } = action.payload;
            const fieldGuidToSelectedEventGuidSetMap = immutableUtils.noMutateMapDeleteKeys(
                state.fieldGuidToSelectedEventGuidSetMap,
                fieldGuidsToRemove
            );

            const deselectEventGuidList = fieldGuidsToRemove.reduce(
                (deselectEventGuidList, fieldGuid) => {
                    const selectedEventGuidSet =
                        state.fieldGuidToSelectedEventGuidSetMap.get(fieldGuid);
                    if (selectedEventGuidSet != null) {
                        deselectEventGuidList.push(...selectedEventGuidSet);
                    }
                    return deselectEventGuidList;
                },
                []
            );

            const selectedEventGuidSet = immutableUtils.noMutateSetDeleteAll(
                state.selectedEventGuidSet,
                deselectEventGuidList
            );
            return Object.freeze({
                ...state,
                fieldGuidToSelectedEventGuidSetMap,
                selectedEventGuidSet,
            });
        }
        case actions.SELECT_EVENTS_FOR_FIELD_GUID: {
            // For event selection in recs, replaces the field's selection with the one for the rec
            const { fieldGuid, selectedEventGuids } = action.payload;
            const fieldGuidToSelectedEventGuidSetMap = immutableUtils.noMutateMapDeleteKeys(
                state.fieldGuidToSelectedEventGuidSetMap,
                [fieldGuid]
            );

            const deselectEventGuidList = Array.from(
                state.fieldGuidToSelectedEventGuidSetMap.get(fieldGuid) || new Set()
            ).filter((eg) => selectedEventGuids.indexOf(eg) === -1);

            let selectedEventGuidSet = immutableUtils.noMutateSetDeleteAll(
                state.selectedEventGuidSet,
                deselectEventGuidList
            );
            selectedEventGuidSet = immutableUtils.noMutateSetAddAll(
                selectedEventGuidSet,
                selectedEventGuids
            );

            fieldGuidToSelectedEventGuidSetMap.set(fieldGuid, selectedEventGuids);
            return Object.freeze({
                ...state,
                fieldGuidToSelectedEventGuidSetMap,
                selectedEventGuidSet,
            });
        }
        case actions.SHOW_ERRORS: {
            const { errorCodes } = action.payload;
            return Object.freeze({
                ...state,
                errorCodes: errorCodes,
            });
        }
        case actions.HIDE_ERRORS: {
            return Object.freeze({
                ...state,
                errorCodes: null,
            });
        }
        case actions.SHOW_ERROR_DETAILS: {
            const { agEventGeneralGuid } = action.payload;
            return Object.freeze({
                ...state,
                errorDetailsToShow: agEventGeneralGuid,
            });
        }
        case actions.HIDE_ERROR_DETAILS: {
            return Object.freeze({
                ...state,
                errorDetailsToShow: null,
            });
        }
        case actions.RESET_ALL_ACCORDION: {
            const allAccordionInit = {
                initScrollTop: state.allAccordionInit.initScrollTop || 0,
                lastClickedDimIdx: [0],
            };
            return Object.freeze({
                ...state,
                allAccordionInit,
            });
        }
        case actions.RESET_INACTIVE_ACCORDION: {
            const inactiveAccordionInit = {
                initScrollTop: state.inactiveAccordionInit.initScrollTop || 0,
                lastClickedDimIdx: [0],
            };
            return Object.freeze({
                ...state,
                inactiveAccordionInit,
            });
        }
        case actions.SET_EVENT_FILTER: {
            const { eventFilter } = action.payload;
            if (eventFilter.activeTab === recsEventsActions.RecEventListTabs.SELECTED) {
                logFirebaseEvent("selected_tabs_events");
            }
            const filter = {
                ...state.filter,
                ...eventFilter,
            };
            let filterValueCount = 0;
            if (filter.seasonGuid) {
                filterValueCount++;
            }
            if (filter.eventType) {
                filterValueCount++;
            }
            if (filter.cropGuid) {
                filterValueCount++;
            }
            return Object.freeze({
                ...state,
                filter,
                filterValueCount: filterValueCount > 0 ? filterValueCount : null,
            });
        }
        case actions.SET_EVENT_FILTER_INFO: {
            const { filterInfo } = action.payload;
            return Object.freeze({
                ...state,
                filterInfo,
            });
        }
        case actions.SET_EVENT_FILTER_OPTIONS: {
            const { filterOptions } = action.payload;
            return Object.freeze({
                ...state,
                filterOptions,
            });
        }
        case actions.UPDATE_SEARCH: {
            const { searchValue } = action.payload;
            return Object.freeze({
                ...state,
                searchValue: searchValue,
            });
        }
        case actions.SET_SHOW_FILTERS: {
            const { showFilters } = action.payload;
            return Object.freeze({
                ...state,
                showFilters,
            });
        }
        case actions.SET_EVENT_PANEL_LOADING: {
            const { isPanelLoading } = action.payload;
            return Object.freeze({
                ...state,
                isPanelLoading,
            });
        }
        case actions.SET_IS_EVENT_LOADING: {
            const { isEventLoading } = action.payload;
            return Object.freeze({
                ...state,
                isEventLoading,
            });
        }
        case actions.SET_LAST_CLICKED_DIMIDX: {
            const { itemDimIdx } = action.payload;
            const newState = { ...state };
            switch (newState.filter.activeTab) {
                case recsEventsActions.RecEventListTabs.ACTIVE:
                    newState.allAccordionInit = {
                        ...newState.allAccordionInit,
                        lastClickedDimIdx: itemDimIdx,
                    };
                    break;
                case recsEventsActions.RecEventListTabs.INACTIVE:
                    newState.inactiveAccordionInit = {
                        ...newState.inactiveAccordionInit,
                        lastClickedDimIdx: itemDimIdx,
                    };
                    break;
                case recsEventsActions.RecEventListTabs.SELECTED:
                    newState.selectedAccordionInit = {
                        ...newState.selectedAccordionInit,
                        lastClickedDimIdx: itemDimIdx,
                    };
                    break;
                default:
                    throw new Error("unreachable: unknown selected tab");
            }
            return Object.freeze(newState);
        }
        case actions.SET_INIT_SCROLL_TOP: {
            const { scrollTop, activeTab } = action.payload;
            const newState = { ...state };
            switch (activeTab) {
                case recsEventsActions.RecEventListTabs.ACTIVE:
                    newState.allAccordionInit = {
                        ...newState.allAccordionInit,
                        initScrollTop: scrollTop,
                    };
                    break;
                case recsEventsActions.RecEventListTabs.INACTIVE:
                    newState.inactiveAccordionInit = {
                        ...newState.inactiveAccordionInit,
                        initScrollTop: scrollTop,
                    };
                    break;
                case recsEventsActions.RecEventListTabs.SELECTED:
                    newState.selectedAccordionInit = {
                        ...newState.selectedAccordionInit,
                        initScrollTop: scrollTop,
                    };
                    break;
                default:
                    throw new Error("unreachable: unknown selected tab");
            }
            return Object.freeze(newState);
        }
        case actions.TOGGLE_YIELD_MODAL: {
            const { isVisible } = action.payload;
            return Object.freeze({
                ...state,
                showYieldCalibrationModal: isVisible,
            });
        }
        case actions.UPDATE_EXPANDED_FIELD_GUID_SET: {
            const { fieldGuid, isExpanded } = action.payload;
            const expandedFieldGuidSet = immutableUtils.noMutateSetChange(
                isExpanded,
                state.expandedFieldGuidSet,
                fieldGuid
            );
            return Object.freeze({
                ...state,
                expandedFieldGuidSet,
            });
        }
        case eventActions.FETCH_YIELD_CALIBRATION_COMPLETED: {
            return Object.freeze({
                ...state,
                yieldCalibrationInProgress: false,
            });
        }
        case actions.YIELD_CALIBRATION_INPROGRESS: {
            const { yieldCalibrationInProgress } = action.payload;
            return Object.freeze({
                ...state,
                yieldCalibrationInProgress,
            });
        }
        case actions.SHOW_MERGE_MODAL: {
            return Object.freeze({
                ...state,
                showMergeEventsModal: true,
            });
        }
        case actions.HIDE_MERGE_MODAL: {
            return Object.freeze({
                ...state,
                showMergeEventsModal: false,
            });
        }
        case actions.SET_MERGEABLE_EVENTS: {
            const { mergeableEvents } = action.payload;
            return Object.freeze({
                ...state,
                mergeableEvents,
            });
        }
        case actions.SELECT_EVENTS_FROM_ACCORDION:
        case actions.DESELECT_EVENTS_FROM_ACCORDION:
        case actions.DESELECT_EVENTS_FROM_DIMIDX_LIST: {
            let dimIdxIter;
            if (action.type === actions.DESELECT_EVENTS_FROM_DIMIDX_LIST) {
                dimIdxIter = action.payload.toDeselectDimIdxList;
            } else {
                const { startDimIdx, endDimIdx } = action.payload;
                if (
                    action.type === actions.SELECT_EVENTS_FROM_ACCORDION &&
                    endDimIdx === undefined
                ) {
                    logFirebaseEvent("select_all_events");
                } else if (action.type === actions.DESELECT_EVENTS_FROM_ACCORDION) {
                    logFirebaseEvent("clear_selected_events");
                }
                dimIdxIter = accordionModel.getDimIdxSliceIter(
                    state[selectors.ACCORDION_KEY].items,
                    startDimIdx,
                    endDimIdx,
                    Boolean(endDimIdx),
                    [2]
                );
            }

            const [selectedEventGuidSet, fieldGuidToSelectedEventGuidSetMap] =
                commonReducer.updateSelectedItemGuidSet(
                    dimIdxIter,
                    (dimIdx) =>
                        getFieldGuidFromDimIdx(state[selectors.ACCORDION_KEY].items, dimIdx),
                    (dimIdx) =>
                        getEventGuidFromDimIdx(state[selectors.ACCORDION_KEY].items, dimIdx),
                    state.selectedEventGuidSet,
                    state.fieldGuidToSelectedEventGuidSetMap,
                    action.type === actions.SELECT_EVENTS_FROM_ACCORDION
                        ? immutableUtils.noMutateSetAddAll
                        : immutableUtils.noMutateSetDeleteAll
                );

            return Object.freeze({
                ...state,
                fieldGuidToSelectedEventGuidSetMap,
                selectedEventGuidSet,
            });
        }
        case actions.DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID: {
            const { agEventGeneralGuids } = action.payload;

            const filteredFieldGuidToSelectedEventGuidSetMap = filterOutEventsFromSetMap(
                state.fieldGuidToSelectedEventGuidSetMap,
                agEventGeneralGuids
            );
            const filteredSelectedEventGuidSet = filterOutEventsFromSet(
                state.selectedEventGuidSet,
                agEventGeneralGuids
            );

            return Object.freeze({
                ...state,
                fieldGuidToSelectedEventGuidSetMap: filteredFieldGuidToSelectedEventGuidSetMap,
                selectedEventGuidSet: filteredSelectedEventGuidSet,
            });
        }
        default: {
            const accordionState = accordionReducer(state[selectors.ACCORDION_KEY], action);
            if (accordionState === state[selectors.ACCORDION_KEY]) {
                return state;
            }
            return Object.freeze({
                ...state,
                [selectors.ACCORDION_KEY]: accordionState,
            });
        }
    }
};
