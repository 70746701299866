import React from "react";
import { connect } from "react-redux";

import { FilterTabs } from "../../../../../module-tabs/filter-tabs";

import { setEventFilter } from "../../actions";
import { getModuleState } from "../../selectors";
import * as selectors from "../../selectors";

import { TabsContextMenu } from "./tabs-context-menu-container";

import "./event-filter-tabs.css";

const mapStateToProps = (state) => {
    const eventModuleState = getModuleState(state);
    return {
        isSelectedFilterActive: eventModuleState.filter.onlySelected,
        activeTab: eventModuleState.filter.activeTab,
        hasSelectedItems: selectors.getSelectedEventGuidSet(state).size > 0,
        contextMenu: <TabsContextMenu />,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetSelectedFilterActive: (onlySelected, activeTab) =>
            dispatch(setEventFilter({ onlySelected, activeTab })),
    };
};

export const EventFilterTabs = connect(mapStateToProps, mapDispatchToProps)(FilterTabs);
