import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import _ from "lodash";
import { withUser } from "~/hocs";
import {
    handlePicklistChange,
    onTextChange,
    onNumberChange,
    prepareSelectableOptions,
    mapToPicklistValue,
} from "~/admin/utils";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import { messages } from "../../i18n-messages";
import { model, service } from "../data";
import { HierarchyAPI } from "@ai360/core";
import {
    Button,
    Checkbox,
    NumericInput,
    Section,
    SelectInput,
    SubSection,
    TextArea,
    TextInput,
} from "~/core";
import { ADD, EDIT, adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { getAgBytesErrorClassNames, preventBubbleUp } from "~/admin/utils";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import {
    AccessoriesList,
    Application,
    BinTankList,
    FuelingEventList,
    Irrigation,
    MaintenanceList,
    NozzlesList,
    OwnerOperator,
    PurchaseHistory,
    // TelematicsSetup,
    UsageReadingList,
    Vehicle,
} from "./components";
import {
    EVENT_TYPE_NAME_HARVEST,
    EVENT_TYPE_NAME_APPLICATION,
    EVENT_TYPE_NAME_PLANTING,
} from "~/recs-events/events/model";
import "./add-edit-panel.css";
import "./add-edit-responsive.css";
const APPLICATOR = "Applicator";
const HARVEST = "Harvest";
const PLANTING = "Planting";

const EQUIPMENT_TYPE_ID = {
    harvest: "9",
};
export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object,
        apiErrors: PropTypes.array,
        clearAgbytesEquipmentData: PropTypes.func,
        fetchAgbytesEquipmentData: PropTypes.func,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.string,
        orgLevel: PropTypes.array,
        ownerOperatorData: PropTypes.object,
        record: PropTypes.object,
        recordGuid: PropTypes.string,
        requestIds: PropTypes.object,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
        renderWithSlidingPanel: PropTypes.bool,
        equipmentName: PropTypes.string,
        closeSlidingPanel: PropTypes.func,
        controllerId: PropTypes.string,
        importType: PropTypes.object,
        userInfo: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.equipmentSetup = {};
        this.equipmentTypeRequest = null;
        this.manufacturerRequest = null;
        this.isLoading = true;
        this.state = {
            equipmentParentId: null,
            importType: null,
            userInfo: null,
            [picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE],
                { guid: PICKLIST_GUID, label: VALUE, id: ID }
            ),
            [picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]: prepareSelectableOptions(
                props[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME],
                { guid: PICKLIST_GUID, label: VALUE, id: ID }
            ),
            [unitService.UNIT_LENGTH]: prepareSelectableOptions(props[unitService.UNIT_LENGTH], {
                guid: GUID,
                label: NAME,
                id: ID,
            }),
            [model.PROPS_MODEL_NAME]: [],
            [model.PROPS_MANUFACTURER]: [],
            [model.PROPS_ORG_LEVEL]: props[model.PROPS_ORG_LEVEL],
        };
        this.addEditRequestId = null;
    }

    componentDidMount() {
        this.props.setBreadcrumbs([""]);
        this.setupEquipmentSetupRecord();
        const { needs } = this.props;
        needs([
            this.props.fetchPicklistData(),
            this.props.fetchPicklistData({
                picklistKey: picklistService.getPickListCode(
                    picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE
                ),
                forcedRequest: true,
            }),
            this.props.fetchDropdownData(),
            this.props.fetchUnitData(),
        ]);
        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
        this.props.clearAgbytesEquipmentData();
        if (
            this.props.addEditPanel.mode === ADD &&
            (this.props.equipmentName || this.props.controllerId)
        ) {
            this.equipmentSetup = {
                ...this.equipmentSetup,
                equipmentName: this.props.equipmentName || "",
                [model.PROPS_CONTROLLER_ID]: this.props.controllerId || "",
            };
        }
        if (!this.props.orgLevel) {
            needs([
                this.props.fetchDropdownData({
                    [model.PROPS_ORG_LEVEL]: {
                        url: HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS,
                    },
                }),
            ]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.orgLevel) {
            this.setState({ [model.PROPS_ORG_LEVEL]: nextProps.orgLevel });
        }
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.equipmentSetup);
        }
        if (nextProps.userInfo) {
            this.setState({ userInfo: nextProps.userInfo });
        }
        if (nextProps.addEditPanel.mode === ADD) {
            if (nextProps.nextId) {
                this.setState({
                    nextId: nextProps.nextId,
                });
            }
            if (nextProps.equipmentName) {
                this.equipmentSetup = {
                    ...this.equipmentSetup,
                    equipmentName: nextProps.equipmentName,
                };
            }
            if (nextProps.importType !== this.state.importType) {
                if (this.state[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE].length > 0) {
                    this.setDefaultValueEquipmentParent();
                }
                if (this.state[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME].length > 0) {
                    this.setDefaultValueEquipmentType();
                }
            }
        } else {
            if (nextProps.record) {
                if (nextProps.record !== this.props.record) {
                    this.equipmentSetup = nextProps.record;
                    this.processFetchDropdownData(nextProps);
                    this.setState({
                        [model.PROPS_MANUFACTURER_GUID]:
                            nextProps.record[model.PROPS_MANUFACTURER_GUID],
                    });
                }
                if (
                    this.isLoading &&
                    nextProps.record[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID] &&
                    nextProps[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE] &&
                    nextProps[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE].length > 0
                ) {
                    const selectedItem = nextProps[
                        picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE
                    ].filter((item) => {
                        return (
                            nextProps.record[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID] ===
                            item[PICKLIST_GUID]
                        );
                    });
                    if (selectedItem) {
                        this.setState({
                            equipmentParentId: selectedItem[0][ID],
                        });
                        this.isLoading = false;
                    }
                }
            }
        }

        this.initializeManufacturer(nextProps);
        this.initializeEquipmentType(nextProps);
        this.initializeEquipmentParentType(nextProps);
        this.initializeModels(nextProps);
        this.initializeUnitLength(nextProps);
        // Intialize using equipment agBytes data
        this.initializeAgbytesEquipmentData(nextProps);
    }

    processFetchDropdownData = () => {
        if (this.equipmentSetup) {
            if (this.state[model.PROPS_MODEL_NAME].length === 0) {
                const picklistKey = picklistService.getPickListCode(
                    picklistService.PICKLIST_EQUIPMENT_TYPE_NAME
                );
                this.props.needs([
                    this.props.fetchPicklistData({
                        url: picklistService.URL_GET_PICKLIST_CHILD_VALUE_LIST,
                        picklistKey,
                        model: {
                            parentPicklistName: "Equipment Parent Type",
                            picklistName: "Equipment Type Name",
                            picklistParentGuid:
                                this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID],
                        },
                        forcedRequest: true,
                    }),
                ]);
            }
            this.props.needs([
                this.props.fetchDropdownData({
                    [model.PROPS_MANUFACTURER]: {
                        url: service.urls.MANUFACTURER_FILTER_URL,
                        model: {
                            EquipmentParentType:
                                this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID],
                            EquipmentType: this.equipmentSetup[model.PROPS_EQUIPMENT_TYPE_GUID],
                        },
                    },
                }),
            ]);
        }
    };

    setDefaultValueEquipmentParent = () => {
        if (this.props.importType) {
            let id = null;
            switch (this.props.importType.name) {
                case APPLICATOR:
                    id = service.EQUIPMENT_PARENT_ID_KEYSET.Applicator;
                    break;
                case HARVEST:
                    id = service.EQUIPMENT_PARENT_ID_KEYSET.Harvesting;
                    break;
                case PLANTING:
                    id = service.EQUIPMENT_PARENT_ID_KEYSET.Planting;
                    break;
                default:
                    return;
            }
            this.setState(
                {
                    equipmentParentId: id,
                },
                () => {
                    const selectedOption = this.state[
                        picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE
                    ].find((option) => option.value.id === id);
                    if (
                        selectedOption &&
                        this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID] !==
                            selectedOption.value.guid &&
                        this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE] !==
                            selectedOption.value.name
                    ) {
                        this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID] =
                            selectedOption.value.guid;
                        this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE] =
                            selectedOption.value.name;
                        this.onEquipmentParentChange(selectedOption.value);
                    }
                }
            );
        }
    };
    setDefaultValueEquipmentType = () => {
        if (this.state.equipmentParentId === service.EQUIPMENT_PARENT_ID_KEYSET.Harvesting) {
            const selectedOption = this.state[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME].find(
                (option) => option.value.id === EQUIPMENT_TYPE_ID.harvest
                //option.value.name === "Combine"
            );
            if (selectedOption) {
                this.equipmentSetup[model.PROPS_EQUIPMENT_TYPE_GUID] = selectedOption.value.guid;
                this.equipmentSetup[model.PROPS_EQUIPMENT_TYPE] = selectedOption.value.name;
                this.onEquipmentTypeChange(selectedOption.value);
            }
            this.setState({
                importType: this.props.importType,
            });
        }
    };
    setupEquipmentSetupRecord = () => {
        const { addEditPanel } = this.props;
        this.equipmentSetup = {};
        if (addEditPanel.mode === "ADD") {
            this.equipmentSetup = service.getDefaultRecord();
        }
    };

    initializeAgbytesEquipmentData({ agBytesEquipmentData }) {
        if (!_.isEmpty(agBytesEquipmentData)) {
            this.equipmentSetup[model.PROPS_MAX_GROSS_WEIGHT] =
                agBytesEquipmentData[model.PROPS_MAX_GROSS_WEIGHT];
            this.equipmentSetup[model.PROPS_MAX_GROSS_WEIGHT] =
                agBytesEquipmentData[model.PROPS_MAX_GROSS_WEIGHT];
            this.equipmentSetup[model.PROPS_TARE_WEIGHT] =
                agBytesEquipmentData[model.PROPS_TARE_WEIGHT];
            this.equipmentSetup[model.PROPS_WEIGHT_UNIT_GUID] =
                agBytesEquipmentData[model.PROPS_WEIGHT_UNIT_GUID];
            this.equipmentSetup[model.PROPS_WIDTH] = agBytesEquipmentData[model.PROPS_WIDTH];
            this.equipmentSetup[model.PROPS_LENGTH] = agBytesEquipmentData[model.PROPS_LENGTH];
            this.equipmentSetup[model.PROPS_HEIGHT] = agBytesEquipmentData[model.PROPS_HEIGHT];
            this.equipmentSetup[model.PROPS_DIMENSION_UNIT_GUID] =
                agBytesEquipmentData[model.PROPS_DIMENSION_UNIT_GUID];
            this.equipmentSetup[model.PROPS_CLASS_SIZE_GUID] =
                agBytesEquipmentData[model.PROPS_CLASS_SIZE_GUID];
            this.equipmentSetup[model.PROPS_TOWING_CAPACITY] =
                agBytesEquipmentData[model.PROPS_TOWING_CAPACITY];
            this.equipmentSetup[model.PROPS_TOWING_CAPACITY_UNIT_GUID] =
                agBytesEquipmentData[model.PROPS_TOWING_CAPACITY_UNIT_GUID];
            this.equipmentSetup[model.PROPS_MAX_SPEED] =
                agBytesEquipmentData[model.PROPS_MAX_SPEED];
            this.equipmentSetup[model.PROPS_MAX_SPEED_UNIT_GUID] =
                agBytesEquipmentData[model.PROPS_MAX_SPEED_UNIT_GUID];
            this.equipmentSetup[model.PROPS_MAX_SPEED_UNIT] =
                agBytesEquipmentData[model.PROPS_MAX_SPEED_UNIT];
            this.equipmentSetup[model.PROPS_FUEL_TYPE_GUID] =
                agBytesEquipmentData[model.PROPS_FUEL_TYPE_GUID];
            this.equipmentSetup[model.PROPS_FUEL_CAPACITY] =
                agBytesEquipmentData[model.PROPS_FUEL_CAPACITY];
            this.equipmentSetup[model.PROPS_FUEL_CAPACITY_UNIT_GUID] =
                agBytesEquipmentData[model.PROPS_FUEL_CAPACITY_UNIT_GUID];
            this.equipmentSetup[model.PROPS_DRIVE_TRAIN_GUID] =
                agBytesEquipmentData[model.PROPS_DRIVE_TRAIN_GUID];
            this.equipmentSetup[model.PROPS_TRANSMISSION_TYPE_GUID] =
                agBytesEquipmentData[model.PROPS_TRANSMISSION_TYPE_GUID];
            this.equipmentSetup[model.PROPS_FUEL_EFFICIENCY] =
                agBytesEquipmentData[model.PROPS_FUEL_EFFICIENCY];
            this.equipmentSetup[model.PROPS_FUEL_EFFICIENCY_UNIT_GUID] =
                agBytesEquipmentData[model.PROPS_FUEL_EFFICIENCY_UNIT_GUID];
            this.equipmentSetup[model.PROPS_HORSE_POWER] =
                agBytesEquipmentData[model.PROPS_HORSE_POWER];
            this.equipmentSetup[model.PROPS_HORSE_POWER_UNIT_GUID] =
                agBytesEquipmentData[model.PROPS_HORSE_POWER_UNIT_GUID];
            this.equipmentSetup[model.PROPS_CYLINDERS] =
                agBytesEquipmentData[model.PROPS_CYLINDERS];
            this.equipmentSetup[model.PROPS_ENGINE_DISPLACEMENT] =
                agBytesEquipmentData[model.PROPS_ENGINE_DISPLACEMENT];
            this.equipmentSetup[model.PROPS_PHOTO_LIST] =
                agBytesEquipmentData[model.PROPS_EQUIPMENT_PHOTOLIST];
            this.equipmentSetup[model.PROPS_DESCRIPTION] =
                agBytesEquipmentData[model.PROPS_DESCRIPTION];
        }
    }

    initializeEquipmentParentType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE] !==
            this.props[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };
    initializeUnitLength = (nextProps) => {
        if (nextProps[unitService.UNIT_LENGTH] !== this.props[unitService.UNIT_LENGTH]) {
            this.setState({
                [unitService.UNIT_LENGTH]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_LENGTH],
                    { guid: GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeEquipmentType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME] !==
            this.props[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]
        ) {
            this.setState({
                [picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeManufacturer = (nextProps) => {
        if (nextProps[model.PROPS_MANUFACTURER] !== this.state[model.PROPS_MANUFACTURER]) {
            this.setState({
                [model.PROPS_MANUFACTURER]: prepareSelectableOptions(
                    nextProps[model.PROPS_MANUFACTURER],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    initializeModels = (nextProps) => {
        if (nextProps[model.PROPS_MODEL_NAME] !== this.props[model.PROPS_MODEL_NAME]) {
            this.setState({
                [model.PROPS_MODEL_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_MODEL_NAME],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    isEquipmentTypeDisabled = () => {
        return (
            !this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID] ||
            this.state[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME].length === 0
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.equipmentSetup[model.PROPS_EQUIPMENT_NAME]]);
    };

    onTextChange = (formKey, value, callback) => {
        this.equipmentSetup = onTextChange(
            this.equipmentSetup,
            { formKey: [formKey], value },
            callback
        );
    };

    onNumberChange = (formKey, value, callback) => {
        this.equipmentSetup = onNumberChange(
            this.equipmentSetup,
            { formKey: [formKey], value },
            callback
        );
    };

    onSelectInputChange = ({ type, guid }, value, callback) => {
        this.equipmentSetup = handlePicklistChange(
            this.equipmentSetup,
            { type, guid, value },
            callback
        );
    };

    onEquipmentParentChange = (value) => {
        const { guid, id } = value;
        const picklistKey = picklistService.getPickListCode(
            picklistService.PICKLIST_EQUIPMENT_TYPE_NAME
        );
        this.setState({
            equipmentParentId: id,
        });
        this.props.needs([
            this.props.fetchPicklistData({
                url: picklistService.URL_GET_PICKLIST_CHILD_VALUE_LIST,
                picklistKey,
                model: {
                    parentPicklistName: "Equipment Parent Type",
                    picklistName: "Equipment Type Name",
                    picklistParentGuid: guid,
                },
                forcedRequest: true,
            }),
            this.props.fetchDropdownData({
                [model.PROPS_MANUFACTURER]: {
                    url: service.urls.MANUFACTURER_FILTER_URL,
                    model: {
                        EquipmentParentType: guid,
                        EquipmentType: this.equipmentSetup[model.PROPS_EQUIPMENT_TYPE_GUID],
                    },
                },
            }),
        ]);
    };

    onEquipmentTypeChange = ({ guid }) => {
        this.props.needs([
            this.props.fetchDropdownData({
                [model.PROPS_MANUFACTURER]: {
                    url: service.urls.MANUFACTURER_FILTER_URL,
                    model: {
                        EquipmentParentType:
                            this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID],
                        EquipmentType: guid,
                    },
                },
            }),
        ]);
        this.setState({ [model.PROPS_MANUFACTURER_GUID]: null });
        this.equipmentSetup[model.PROPS_MANUFACTURER_GUID] = null;
        this.onManufacturerChange({ guid: "" });
    };

    onManufacturerChange = ({ guid }) => {
        this.props.needs([
            this.props.fetchDropdownData({
                [model.PROPS_MODEL_NAME]: {
                    url: service.urls.MODEL_FILTER_URL,
                    model: {
                        equipmentTypeGuid: this.equipmentSetup[model.PROPS_EQUIPMENT_TYPE_GUID],
                        equipmentParentTypeGuid:
                            this.equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID],
                        manufacturerGuid: guid,
                    },
                },
            }),
        ]);
        this.equipmentSetup[model.PROPS_EQUIPMENT_GUID] = null;
    };

    onOwnerOperatorChange = (data) => {
        this.equipmentSetup = {
            ...this.equipmentSetup,
            ...data,
        };
    };

    onSubmit = () => {
        this.props.liftRecordData(this.equipmentSetup, this.props.closeSlidingPanel);
    };

    getAutoFocusControl = (importType = {}) => {
        const { name } = importType;
        const { formatMessage } = this.props.intl;
        if (name === EVENT_TYPE_NAME_HARVEST) {
            return formatMessage(messages.equipmentName);
        } else if (name === EVENT_TYPE_NAME_PLANTING || name === EVENT_TYPE_NAME_APPLICATION) {
            return formatMessage(messages.equipmentType);
        }
        return null;
    };

    renderPlanter = () => {
        const { formatMessage } = this.props.intl;
        return (
            <Section headerText={formatMessage(messages.planter)}>
                <SubSection className="column-3-gutter">
                    <NumericInput
                        scale={2}
                        precision={9}
                        onChange={(value) =>
                            this.onNumberChange(model.PROPS_PLANTER_ROW_SPACING, value)
                        }
                        value={this.equipmentSetup[model.PROPS_PLANTER_ROW_SPACING]}
                        placeholderText={formatMessage(messages.rowSpacing)}
                        labelText={formatMessage(messages.rowSpacing)}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        clearable={false}
                        options={this.state[unitService.UNIT_LENGTH]}
                        value={mapToPicklistValue({
                            options: this.state[unitService.UNIT_LENGTH],
                            selectedGuid:
                                this.equipmentSetup[model.PROPS_PLANTER_ROW_SPACING_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            this.onSelectInputChange(
                                {
                                    guid: model.PROPS_PLANTER_ROW_SPACING_UNIT_GUID,
                                },
                                value
                            );
                        }}
                        placeholderText={formatMessage(messages.rowSpacingUnit)}
                        labelText={formatMessage(messages.rowSpacingUnit)}
                    />
                    <NumericInput
                        scale={0}
                        precision={4}
                        onChange={(value) =>
                            this.onNumberChange(model.PROPS_PLANTER_NUMBER_OF_ROWS, value)
                        }
                        value={this.equipmentSetup[model.PROPS_PLANTER_NUMBER_OF_ROWS]}
                        placeholderText={formatMessage(messages.numberOfRows)}
                        labelText={formatMessage(messages.numberOfRows)}
                    />
                </SubSection>
            </Section>
        );
    };

    renderAccessoriesPanel = () => {
        const { formatMessage } = this.props.intl;
        const { equipmentParentId } = this.state;
        const { EQUIPMENT_PARENT_ID_KEYSET } = service;
        return (
            <div className="section-container section-column">
                {equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Applicator ? (
                    <Application
                        formatMessage={formatMessage}
                        equipmentSetup={this.equipmentSetup}
                        onTextChange={this.onTextChange}
                        onNumberChange={this.onNumberChange}
                        onSelectInputChange={this.onSelectInputChange}
                    />
                ) : null}
                {equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Applicator ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Irrigation ? (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.nozzle, {
                            count: 0,
                        })}
                    >
                        <SubSection>
                            <NozzlesList
                                formatMessage={formatMessage}
                                record={this.equipmentSetup[model.PROPS_NOZZLE_LIST]}
                                aliasName={model.PROPS_NOZZLE_LIST}
                                onTextChange={this.onTextChange}
                                needs={this.props.needs}
                                itemListAlias={model.PROPS_NOZZLE_LIST}
                            />
                        </SubSection>
                    </Section>
                ) : null}
                {equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Applicator ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Harvesting ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.HayOrForage ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Other ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Tractor ? (
                    <Vehicle
                        formatMessage={formatMessage}
                        equipmentParentId={equipmentParentId}
                        equipmentSetup={this.equipmentSetup}
                        onTextChange={this.onTextChange}
                        onNumberChange={this.onNumberChange}
                        onSelectInputChange={this.onSelectInputChange}
                    />
                ) : null}
                {equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Harvesting ? (
                    <Section headerText={formatMessage(messages.yieldFilter)}>
                        <SubSection>
                            <NumericInput
                                scale={2}
                                precision={9}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_HARVESTER_FLOW_DELAY, value)
                                }
                                value={this.equipmentSetup[model.PROPS_HARVESTER_FLOW_DELAY]}
                                placeholderText={formatMessage(messages.flowDelay)}
                                labelText={formatMessage(messages.flowDelay)}
                            />
                        </SubSection>
                    </Section>
                ) : null}
                {equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Planting
                    ? this.renderPlanter()
                    : null}

                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.accessory, { count: 0 })}
                >
                    <SubSection>
                        <AccessoriesList
                            formatMessage={formatMessage}
                            record={this.equipmentSetup[model.PROPS_ACCESSORY_LIST]}
                            itemListAlias={model.PROPS_ACCESSORY_LIST}
                            onTextChange={this.onTextChange}
                            needs={this.props.needs}
                        />
                    </SubSection>
                </Section>
                {equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Applicator ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Harvesting ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Planting ? (
                    <Section className="grid-section" headerText={formatMessage(messages.binTank)}>
                        <SubSection>
                            <BinTankList
                                formatMessage={formatMessage}
                                record={this.equipmentSetup[model.PROPS_BIN_TANK_LIST]}
                                aliasName={model.PROPS_BIN_TANK_LIST}
                                itemListAlias={model.PROPS_BIN_TANK_LIST}
                                onTextChange={this.onTextChange}
                                needs={this.props.needs}
                            />
                        </SubSection>
                    </Section>
                ) : null}
            </div>
        );
    };

    renderPurchasePanel = () => {
        const { formatMessage } = this.props.intl;
        const { equipmentParentId } = this.state;
        const { EQUIPMENT_PARENT_ID_KEYSET } = service;
        return (
            <div className="section-container section-column">
                {!(equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Irrigation) ? null : (
                    <Irrigation
                        formatMessage={formatMessage}
                        equipmentSetup={this.equipmentSetup}
                        onNumberChange={this.onNumberChange}
                        onTextChange={this.onTextChange}
                        onSelectInputChange={this.onSelectInputChange}
                    />
                )}
                {/*
                    <TelematicsSetup
                        formatMessage={formatMessage}
                        equipmentSetup={this.equipmentSetup}
                        onTextChange={this.onTextChange}
                        onSelectInputChange={this.onSelectInputChange}
                        needs={this.props.needs}
                    /> */}
                <PurchaseHistory
                    formatMessage={formatMessage}
                    equipmentSetup={this.equipmentSetup}
                    onNumberChange={this.onNumberChange}
                    onTextChange={this.onTextChange}
                    onSelectInputChange={this.onSelectInputChange}
                />
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.maintenanceEvent)}
                >
                    <SubSection>
                        <MaintenanceList
                            formatMessage={formatMessage}
                            record={this.equipmentSetup[model.PROPS_MAINTENANCE_LIST]}
                            itemListAlias={model.PROPS_MAINTENANCE_LIST}
                            onTextChange={this.onTextChange}
                            needs={this.props.needs}
                        />
                    </SubSection>
                </Section>
                <Section className="grid-section" headerText={formatMessage(messages.usageReading)}>
                    <SubSection>
                        <UsageReadingList
                            formatMessage={formatMessage}
                            record={this.equipmentSetup[model.PROPS_USAGE_READING_LIST]}
                            itemListAlias={model.PROPS_USAGE_READING_LIST}
                            onTextChange={this.onTextChange}
                            needs={this.props.needs}
                        />
                    </SubSection>
                </Section>
                {equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Applicator ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Harvesting ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.HayOrForage ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Tractor ||
                equipmentParentId === EQUIPMENT_PARENT_ID_KEYSET.Other ? (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.fuelingEvent)}
                    >
                        <SubSection>
                            <FuelingEventList
                                formatMessage={formatMessage}
                                record={this.equipmentSetup[model.PROPS_FUELING_EVENT_LIST]}
                                aliasName={model.PROPS_FUELING_EVENT_LIST}
                                onTextChange={this.onTextChange}
                                needs={this.props.needs}
                            />
                        </SubSection>
                    </Section>
                ) : null}
            </div>
        );
    };

    renderEquipmentProfile = () => {
        const { equipmentSetup } = this;
        const { apiErrors, importType } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <SelectInput
                            clearable={false}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            autoFocus={!this.getAutoFocusControl(importType)}
                            openOnFocus={false}
                            options={this.state[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]}
                            containerClassNames={getAgBytesErrorClassNames(688, apiErrors)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onSelectInputChange(
                                    {
                                        guid: model.PROPS_EQUIPMENT_PARENT_TYPE_GUID,
                                    },
                                    value
                                );
                                equipmentSetup[model.PROPS_EQUIPMENT_PARENT_TYPE] = value.name;
                                equipmentSetup[model.PROPS_EQUIPMENT_TYPE] = "";
                                equipmentSetup[model.PROPS_EQUIPMENT_TYPE_GUID] = "";
                                this.onEquipmentParentChange(value);
                            }}
                            placeholderText={formatMessage(messages.equipmentParentType)}
                            required
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            clearable={false}
                            disabled={this.isEquipmentTypeDisabled()}
                            options={this.state[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]}
                            containerClassNames={getAgBytesErrorClassNames(689, apiErrors)}
                            autoFocus={
                                this.getAutoFocusControl(importType) ===
                                formatMessage(messages.equipmentType)
                            }
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME],
                                selectedGuid: equipmentSetup[model.PROPS_EQUIPMENT_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onSelectInputChange(
                                    {
                                        guid: model.PROPS_EQUIPMENT_TYPE_GUID,
                                    },
                                    value
                                );
                                equipmentSetup[model.PROPS_EQUIPMENT_TYPE] = value.name;
                                this.onEquipmentTypeChange(value);
                            }}
                            placeholderText={formatMessage(messages.equipmentType)}
                            required
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_MANUFACTURER]}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_MANUFACTURER],
                                selectedGuid: equipmentSetup[model.PROPS_MANUFACTURER_GUID],
                            })}
                            onChange={(value) => {
                                this.onSelectInputChange(
                                    {
                                        guid: model.PROPS_MANUFACTURER_GUID,
                                    },
                                    value,
                                    () =>
                                        this.setState({
                                            [model.PROPS_MANUFACTURER_GUID]: value
                                                ? value.guid
                                                : null,
                                        })
                                );
                                equipmentSetup[model.PROPS_MANUFACTURER] = value ? value.name : "";
                                if (value) {
                                    this.onManufacturerChange(value);
                                } else {
                                    this.onManufacturerChange({ guid: "" });
                                }
                            }}
                            placeholderText={formatMessage(messages.manufacturer)}
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_MODEL_NAME]}
                            disabled={
                                !this.state[model.PROPS_MANUFACTURER_GUID] ||
                                !this.state[model.PROPS_MODEL_NAME].length
                            }
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_MODEL_NAME],
                                selectedGuid: equipmentSetup[model.PROPS_EQUIPMENT_GUID],
                            })}
                            onChange={(value) => {
                                if (value) {
                                    this.onSelectInputChange(
                                        {
                                            guid: model.PROPS_EQUIPMENT_GUID,
                                        },
                                        value
                                    );
                                    this.props.needs([
                                        this.props.fetchAgbytesEquipmentData(value.guid),
                                    ]);
                                } else {
                                    this.onSelectInputChange(
                                        {
                                            guid: model.PROPS_EQUIPMENT_GUID,
                                        },
                                        ""
                                    );
                                }
                            }}
                            placeholderText={formatMessage(messages.modelName)}
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section className="equipment-section">
                    <SubSection>
                        <TextInput
                            required
                            maxLength={128}
                            containerClassNames={getAgBytesErrorClassNames(512, apiErrors)}
                            placeholderText={formatMessage(messages.equipmentName)}
                            value={equipmentSetup[model.PROPS_EQUIPMENT_NAME]}
                            autoFocus={
                                this.getAutoFocusControl(importType) ===
                                formatMessage(messages.equipmentName)
                            }
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_EQUIPMENT_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                        />
                        <NumericInput
                            scale={0}
                            precision={4}
                            suppressFormatting
                            placeholderText={formatMessage(messages.modelYear)}
                            value={equipmentSetup[model.PROPS_MODEL_YEAR]}
                            onChange={(value) => this.onNumberChange(model.PROPS_MODEL_YEAR, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            maxLength={50}
                            placeholderText={formatMessage(messages.equipmentId)}
                            value={equipmentSetup[model.PROPS_EQUIPMENT_ID]}
                            onChange={(value) => this.onTextChange(model.PROPS_EQUIPMENT_ID, value)}
                        />
                        <TextInput
                            maxLength={50}
                            placeholderText={formatMessage(messages.partId)}
                            value={equipmentSetup[model.PROPS_MODEL_NAME]}
                            onChange={(value) => this.onTextChange(model.PROPS_MODEL_NAME, value)}
                        />
                    </SubSection>
                    {this.props.addEditPanel.mode !== EDIT ? null : !this.props.userRole[
                          model.PROPS_ACTIVE_INACTIVE
                      ] || this.props.addEditPanel.mode === ADD ? null : (
                        <SubSection>
                            <Checkbox
                                onChange={(e, value) => this.onTextChange(model.ACTIVE_YN, value)}
                                value={equipmentSetup[model.ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        </SubSection>
                    )}
                </Section>
                <span className="no-bar section-fence"></span>
                <Section className="equipment-right-section">
                    <SubSection>
                        <TextInput
                            maxLength={50}
                            placeholderText={formatMessage(messages.serialNumber)}
                            value={equipmentSetup[model.PROPS_SERIAL_NUMBER]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_SERIAL_NUMBER, value)
                            }
                        />
                        <TextInput
                            maxLength={50}
                            placeholderText={formatMessage(messages.monitorId)}
                            value={equipmentSetup[model.PROPS_CONTROLLER_ID]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_CONTROLLER_ID, value)
                            }
                        />
                    </SubSection>
                    <SubSection className="equipment-notes">
                        <TextArea
                            maxLength={4000}
                            placeholderText={formatMessage(messages.notes)}
                            value={equipmentSetup[model.PROPS_NOTES]}
                            onChange={(value) => this.onTextChange(model.PROPS_NOTES, value)}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    _renderEquipment = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.props;
        if (!_.isEmpty(this.props.ownerOperatorData)) {
            this.equipmentSetup[model.PROPS_OWNER_OPERATOR_NAME] =
                this.props.ownerOperatorData[model.PROPS_OWNER_OPERATOR_NAME];
            this.equipmentSetup[model.PROPS_OWNER_OPERATOR_CITY] =
                this.props.ownerOperatorData[model.PROPS_OWNER_OPERATOR_CITY];
            this.equipmentSetup[model.PROPS_OWNER_OPERATOR_STATE_ABBREVIATION] =
                this.props.ownerOperatorData[model.PROPS_OWNER_OPERATOR_STATE_ABBREVIATION];
            if (model.PROPS_OWNER_OPERATOR_VENDOR_GUID in this.props.ownerOperatorData) {
                this.equipmentSetup[model.PROPS_OWNER_OPERATOR_VENDOR_GUID] =
                    this.props.ownerOperatorData[model.PROPS_OWNER_OPERATOR_VENDOR_GUID];
            } else if (model.PROPS_OWNER_OPERATOR_CUSTOMER_GUID in this.props.ownerOperatorData) {
                this.equipmentSetup[model.PROPS_OWNER_OPERATOR_CUSTOMER_GUID] =
                    this.props.ownerOperatorData[model.PROPS_OWNER_OPERATOR_CUSTOMER_GUID];
            } else if (model.PROPS_OWNER_OPERATOR_ORG_LEVEL_GUID in this.props.ownerOperatorData) {
                this.equipmentSetup[model.PROPS_OWNER_OPERATOR_ORG_LEVEL_GUID] =
                    this.props.ownerOperatorData[model.PROPS_OWNER_OPERATOR_ORG_LEVEL_GUID];
            }
        }
        return (
            <div className="add-edit-panel equipment-setup-cont">
                {this.renderEquipmentProfile()}
                <div className="section-container">
                    <OwnerOperator
                        apiErrors={apiErrors}
                        formatMessage={formatMessage}
                        equipmentParentType={this.state.equipmentParentId}
                        onOwnerOperatorChange={this.onOwnerOperatorChange}
                        equipmentSetup={this.equipmentSetup}
                        onTextChange={this.onTextChange}
                        onNumberChange={this.onNumberChange}
                        onSelectInputChange={this.onSelectInputChange}
                        orgLevel={this.state[model.PROPS_ORG_LEVEL]}
                        userInfo={this.state.userInfo}
                    />
                    <span className="bar section-fence"></span>
                    {this.renderAccessoriesPanel()}
                    <span className="bar section-fence"></span>
                    {this.renderPurchasePanel()}
                </div>
            </div>
        );
    };

    render() {
        if (this.props.renderWithSlidingPanel) {
            return (
                <div className="customer-main-container">
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            component={this._renderEquipment}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "2",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={() => this.onSubmit()} />
                            <Button type="cancel" onClick={() => this.props.closeSlidingPanel()} />
                        </SlidingPanel>
                    </form>
                </div>
            );
        }
        return this._renderEquipment();
    }
}

export default withUser(injectIntl(AddEditPanel));
