import { connect } from "react-redux";
import AccessoriesListView from "./accessories-list";
import { EQUIPMENT_FILTER, MANUFACTURER_FILTER } from "../../data/service";
import { getDropdown } from "~/admin/selectors";
import { model } from "../../data";
import { fetchDropdownData } from "~/core/dropdowns/actions";

const mapStateToProps = (state) => ({
    [EQUIPMENT_FILTER]: getDropdown(EQUIPMENT_FILTER, state),
    [MANUFACTURER_FILTER]: getDropdown(MANUFACTURER_FILTER, state),
    [model.PROPS_MODEL_NAME]: getDropdown(model.PROPS_MODEL_NAME, state),
});

const mapDispatchToProps = () => ({
    fetchDropdownData: (payload) => fetchDropdownData(payload),
});

export const AccessoriesList = connect(mapStateToProps, mapDispatchToProps)(AccessoriesListView);
