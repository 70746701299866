import { connect } from "react-redux";
import { getGridData } from "./crud/selectors";
import { getModelNameToState } from "./crud/utils";

const mapStateToProps = (modelName) => (state, ownProps) => {
    const gridData = getGridData(state);
    const { parentGuid } = ownProps;
    let records = {};
    if (gridData && gridData[modelName] && parentGuid && gridData[modelName][parentGuid]) {
        const { gridRows, totalCount, autoSearchList, selectAll } = gridData[modelName][parentGuid];
        records = {
            records,
            ...{ records: gridRows, totalCount, selectAll, autoSearchList },
        };
    }
    return {
        ...records,
        ...ownProps,
    };
};

const advanceConnect = (modelName, View) => connect(mapStateToProps(modelName))(View);

const NestedGridContainer = (View, modelName) =>
    advanceConnect(getModelNameToState(modelName), View);

export default NestedGridContainer;
