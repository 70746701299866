import { connect } from "react-redux";
import { withMasked } from "~/hocs";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import {
    fetchAttributes,
    fetchTreeAttributes,
    saveAssignment,
    saveAssignmentAttribute,
    fetchUserCompanyList,
    setCompanyUserPreference,
} from "./data/actions";
import { getAttributes, getUserCompanyList, getLastUsedOwnerGuid } from "./data/selectors";
import BatchZapperView from "./batch-zapper";

const mapStateToProps = (state) => ({
    attributes: getAttributes(state),
    userCompanyList: getUserCompanyList(state),
    lastUsedOwnerGuid: getLastUsedOwnerGuid(state),
});

const mapDispatchToProps = (dispatch) => ({
    setCompanyUserPreference: (companyGuid) => dispatch(setCompanyUserPreference(companyGuid)),
    fetchAttributes: (payload) => dispatch(fetchAttributes(payload)),
    fetchTreeAttributes: (payload) => dispatch(fetchTreeAttributes(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    // This action is dispatched using needs hoc
    saveAssignment: (payload) => saveAssignment(payload),
    saveAssignmentAttribute: (payload) => saveAssignmentAttribute(payload),
    fetchUserCompanyList: () => fetchUserCompanyList(),
});

export default withMasked(connect(mapStateToProps, mapDispatchToProps)(BatchZapperView));
