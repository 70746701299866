import React, { Component } from "react";
import { messages } from "../../i18n-messages";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { model, service } from "../data";

// Constants
import * as picklistService from "~/core/picklist/picklist-names";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
// Components
import { Checkbox, NumericInput, Section, SelectInput, SubSection, TextInput } from "~/core";
import AgrianId from "../../components/agrian-id-list";
import CropName from "../../components/crop-name";
import AliasList from "../../components/alias-list";
import OrganismType from "../../components/organism-type";
import PhotoList from "../../components/photo-list";
// Types
import { IAddEditPanelProps, IAddEditPanelState, Observation } from "../interfaces";
// Styles
import "./add-edit-panel.css";
const OBSERVATION_PARENT_TYPE = "Observation Parent Type";
const OBSERVATION_TYPE = "Observation Type";

export class AddEditPanel extends Component<
    IAddEditPanelProps & InjectedIntlProps,
    IAddEditPanelState
> {
    observation: Observation;
    constructor(props) {
        super(props);
        this.observation = props.record || {};
        this.state = {
            [picklistService.PICKLIST_OBSERVATION_PARENT_TYPE]: [],
            [picklistService.PICKLIST_OBSERVATION_TYPE]: [],
            [picklistService.PICKLIST_LIFESPAN]: [],
            [picklistService.PICKLIST_MORPHOLOGY_TYPE]: [],
            [picklistService.PICKLIST_ORGANISM_TYPE]: [],
            [model.PROPS_OBSERVATION_GROUP_NAME]: [],
            [model.PROPS_CROP_LIST]: [],
            nextId: null,
        };
    }

    componentDidMount(): void {
        this.setupObservationRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(),
            this.props.fetchPicklistData(),
            this.props.fetchUnitData(),
            this.props.fetchDropdownData(),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.observation);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.observation = nextProps.record;
            }
        }

        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.observation[model.PROPS_OBSERVATION_ID] = nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupObservationRecord = () => {
        const { addEditPanel } = this.props;
        this.observation = this.props.record || {};
        if (addEditPanel.mode === "ADD") {
            this.observation = service.getDefaultRecord();
        }
    };

    onNumberChange = (formKey, value, callback?: () => void) => {
        this.observation = onNumberChange(
            this.observation,
            { formKey: [formKey], value },
            callback
        );
    };

    initializeDropdowns = (nextProps) => {
        this.initializeObservationParentType(nextProps);
        this.initializeObservationType(nextProps);
        this.initializeLifespan(nextProps);
        this.initializeMorphology(nextProps);
        this.initializeGrowthStages(nextProps);
        this.initializeCropName(nextProps);
        this.initializeOrganismType(nextProps);
    };

    initializeOrganismType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_ORGANISM_TYPE] !==
            this.state[picklistService.PICKLIST_ORGANISM_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_ORGANISM_TYPE]:
                    nextProps[picklistService.PICKLIST_ORGANISM_TYPE],
            });
        }
    };

    initializeCropName = (nextProps) => {
        if (nextProps[model.PROPS_CROP_LIST] !== this.state[model.PROPS_CROP_LIST]) {
            this.setState({
                [model.PROPS_CROP_LIST]: nextProps[model.PROPS_CROP_LIST],
            });
        }
    };

    initializeGrowthStages = (nextProps) => {
        if (
            this.observation[model.PROPS_OBSERVATION_GROUP_NAME] != null &&
            nextProps[model.PROPS_OBSERVATION_GROUP_NAME]
        ) {
            this.setState({
                [model.PROPS_OBSERVATION_GROUP_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_OBSERVATION_GROUP_NAME],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
                ),
            });
        }
    };

    initializeMorphology = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_MORPHOLOGY_TYPE] !==
            this.state[picklistService.PICKLIST_MORPHOLOGY_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_MORPHOLOGY_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_MORPHOLOGY_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeLifespan = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_LIFESPAN] !==
            this.state[picklistService.PICKLIST_LIFESPAN]
        ) {
            this.setState({
                [picklistService.PICKLIST_LIFESPAN]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_LIFESPAN],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeObservationType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_OBSERVATION_TYPE] !==
            this.state[picklistService.PICKLIST_OBSERVATION_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_OBSERVATION_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_OBSERVATION_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeObservationParentType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_OBSERVATION_PARENT_TYPE] !==
            this.state[picklistService.PICKLIST_OBSERVATION_PARENT_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_OBSERVATION_PARENT_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_OBSERVATION_PARENT_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    onPicklistChange = ({ type, guid }, value, callback?: () => void) => {
        this.observation = handlePicklistChange(this.observation, { type, guid, value }, callback);
    };

    onTextChange = (formKey: string, value: string | boolean, callback?: () => void) => {
        this.observation = onTextChange(this.observation, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.observation[model.PROPS_OBSERVATION_NAME]]);
    };

    onObservationParentChange = () => {
        const picklistKey = picklistService.getPickListCode(
            picklistService.PICKLIST_OBSERVATION_TYPE
        );
        this.props.needs([
            this.props.fetchPicklistData({
                url: picklistService.URL_GET_PICKLIST_CHILD_VALUE_LIST,
                picklistKey,
                model: {
                    parentPicklistName: OBSERVATION_PARENT_TYPE,
                    picklistName: OBSERVATION_TYPE,
                    picklistParentGuid: this.observation[model.PROPS_OBSERVATION_PARENT_TYPE_GUID],
                },
                forcedRequest: true,
            }),
        ]);
    };

    renderObservationInfo = () => {
        const { formatMessage } = this.props.intl;
        const { observation } = this;
        const { nextId } = this.state;
        return (
            <div className="section-container observation-section">
                <div className="section-container section-column observation-section">
                    <Section>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                scale={0}
                                precision={9}
                                suppressFormatting
                                containerClassNames={getAgBytesErrorClassNames(
                                    [168, 169],
                                    this.props.apiErrors,
                                    ["form-input-id"]
                                )}
                                placeholderText={formatMessage(messages.observationId)}
                                labelText={formatMessage(messages.observationId)}
                                value={nextId || observation[model.PROPS_OBSERVATION_ID]}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_OBSERVATION_ID, value)
                                }
                                required
                            />
                            {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                                <Checkbox
                                    className="active-checkbox"
                                    onChange={(e, value) =>
                                        this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                    }
                                    value={this.observation[model.PROPS_ACTIVE_YN]}
                                    label={formatMessage(messages.active)}
                                />
                            )}
                        </SubSection>
                        <SubSection>
                            <SelectInput
                                tabIndex={0}
                                required
                                autoFocus
                                openOnFocus={false}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                clearable={false}
                                containerClassNames={getAgBytesErrorClassNames(
                                    172,
                                    this.props.apiErrors
                                )}
                                options={
                                    this.state[picklistService.PICKLIST_OBSERVATION_PARENT_TYPE]
                                }
                                placeholderText={formatMessage(messages.observationParentType)}
                                value={mapToPicklistValue({
                                    options:
                                        this.state[
                                            picklistService.PICKLIST_OBSERVATION_PARENT_TYPE
                                        ],
                                    selectedGuid:
                                        observation[model.PROPS_OBSERVATION_PARENT_TYPE_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_OBSERVATION_PARENT_TYPE,
                                            guid: model.PROPS_OBSERVATION_PARENT_TYPE_GUID,
                                        },
                                        value,
                                        this.onObservationParentChange
                                    );
                                }}
                            />
                            <SelectInput
                                tabIndex={0}
                                required
                                clearable={false}
                                containerClassNames={getAgBytesErrorClassNames(
                                    174,
                                    this.props.apiErrors
                                )}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                disabled={!observation[model.PROPS_OBSERVATION_PARENT_TYPE_GUID]}
                                options={this.state[picklistService.PICKLIST_OBSERVATION_TYPE]}
                                placeholderText={formatMessage(messages.observationType)}
                                value={mapToPicklistValue({
                                    options: this.state[picklistService.PICKLIST_OBSERVATION_TYPE],
                                    selectedGuid: observation[model.PROPS_OBSERVATION_TYPE_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_OBSERVATION_TYPE,
                                            guid: model.PROPS_OBSERVATION_TYPE_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        </SubSection>
                        <SubSection className="growth-stage-container">
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[model.PROPS_OBSERVATION_GROUP_NAME]}
                                placeholderText={formatMessage(messages.growthStageGroupIDName)}
                                value={mapToPicklistValue({
                                    options: this.state[model.PROPS_OBSERVATION_GROUP_NAME],
                                    selectedGuid: observation[model.PROPS_GROWTH_STAGE_GROUP_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_OBSERVATION_GROUP_NAME,
                                            guid: model.PROPS_GROWTH_STAGE_GROUP_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        </SubSection>
                    </Section>
                </div>
                <div className="section-container section-column observation-section observation-section-align">
                    <Section>
                        <SubSection className="form-section-row-2">
                            <TextInput
                                tabIndex={0}
                                maxLength={50}
                                placeholderText={formatMessage(messages.observationParentName)}
                                value={observation[model.PROPS_OBSERVATION_PARENT_NAME] || ""}
                                onChange={(value) =>
                                    this.onTextChange(model.PROPS_OBSERVATION_PARENT_NAME, value)
                                }
                            />
                            <TextInput
                                tabIndex={0}
                                maxLength={50}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [234, 235],
                                    this.props.apiErrors
                                )}
                                placeholderText={formatMessage(messages.observationName)}
                                value={observation[model.PROPS_OBSERVATION_NAME] || ""}
                                onChange={(value) =>
                                    this.onTextChange(
                                        model.PROPS_OBSERVATION_NAME,
                                        value,
                                        this.setHeaderText
                                    )
                                }
                                required
                            />
                        </SubSection>
                    </Section>
                </div>
                <div className="section-container section-column observation-section observation-section-align">
                    <Section>
                        <SubSection className="form-section-row-2">
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[picklistService.PICKLIST_LIFESPAN]}
                                placeholderText={formatMessage(messages.lifespan)}
                                value={mapToPicklistValue({
                                    options: this.state[picklistService.PICKLIST_LIFESPAN],
                                    selectedGuid: observation[model.PROPS_LIFESPAN_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_LIFESPAN,
                                            guid: model.PROPS_LIFESPAN_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[picklistService.PICKLIST_MORPHOLOGY_TYPE]}
                                placeholderText={formatMessage(messages.morphologyType)}
                                value={mapToPicklistValue({
                                    options: this.state[picklistService.PICKLIST_MORPHOLOGY_TYPE],
                                    selectedGuid: observation[model.PROPS_MORPHOLOGY_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_MORPHOLOGY_TYPE,
                                            guid: model.PROPS_MORPHOLOGY_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        </SubSection>
                    </Section>
                </div>
            </div>
        );
    };

    renderDetailInfo1() {
        const { formatMessage } = this.props.intl;
        const { observation } = this;
        return (
            <div className="section-container section-column">
                <Section>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.favorableConditions)}
                            value={observation[model.PROPS_FAVORABLE_CONDITIONS] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_FAVORABLE_CONDITIONS, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.observationSymptom)}
                            value={observation[model.PROPS_DESCRIPTION_SYMPTOMS] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_DESCRIPTION_SYMPTOMS, value)
                            }
                        />
                    </SubSection>
                </Section>
                <Section headerText={formatMessage(messages.taxonomicInfo)}>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.scientificName)}
                            value={observation[model.PROPS_SCIENTIFIC_NAME] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_SCIENTIFIC_NAME, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.genus)}
                            value={observation[model.PROPS_GENUS] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_GENUS, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.family)}
                            value={observation[model.PROPS_FAMILY] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_FAMILY, value)}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.order)}
                            value={observation[model.PROPS_ORDER] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_ORDER, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={100}
                            placeholderText={formatMessage(messages.class)}
                            value={observation[model.PROPS_CLASS] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_CLASS, value)}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.division)}
                            value={observation[model.PROPS_DIVISION] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_DIVISION, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.kingdom)}
                            value={observation[model.PROPS_KINGDOM] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_KINGDOM, value)}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }
    renderDetailInfo2() {
        const { formatMessage } = this.props.intl;
        const { observation } = this;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.crossReference)}>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.agXID)}
                            value={observation[model.PROPS_AGX_ID] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_AGX_ID, value)}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.agGatewayID)}
                            value={observation[model.PROPS_AG_GATEWAY_ID] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_AG_GATEWAY_ID, value)
                            }
                        />
                    </SubSection>
                </Section>
                <Section className="grid-section" headerText={formatMessage(messages.agrianID)}>
                    <SubSection>
                        <AgrianId
                            formatMessage={formatMessage}
                            record={this.observation[model.PROPS_AGRIAN_ID]}
                            itemListAlias={model.PROPS_AGRIAN_ID}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    renderDetailInfo3() {
        const { formatMessage } = this.props.intl;
        const { observation, onTextChange, state } = this;
        return (
            <div className="section-container section-column">
                <Section className="grid-section" headerText={formatMessage(messages.cropName)}>
                    <SubSection>
                        <CropName
                            formatMessage={formatMessage}
                            options={this.state[model.PROPS_CROP_LIST]}
                            record={observation[model.PROPS_CROP_LIST]}
                            itemListAlias={model.PROPS_CROP_LIST}
                            onTextChange={(e, value) => onTextChange(model.PROPS_CROP_LIST, value)}
                        />
                    </SubSection>
                </Section>
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.observationNameAlias)}
                >
                    <SubSection>
                        <AliasList
                            formatMessage={formatMessage}
                            aliasTextLabel={"observationNameAlias"}
                            record={observation[model.PROPS_OBSERVATION_ALIAS_LIST]}
                            itemListAlias={model.PROPS_OBSERVATION_ALIAS_LIST}
                            onTextChange={onTextChange}
                            aliasName={"observationAliasName"}
                        />
                    </SubSection>
                </Section>
                <Section className="grid-section" headerText={formatMessage(messages.organismType)}>
                    <SubSection>
                        <OrganismType
                            formatMessage={formatMessage}
                            options={state[picklistService.PICKLIST_ORGANISM_TYPE]}
                            record={observation[model.PROPS_ORGANISM_TYPE_LIST]}
                            itemListAlias={picklistService.PICKLIST_ORGANISM_TYPE}
                            onTextChange={onTextChange}
                        />
                    </SubSection>
                </Section>
                <Section className="grid-section" headerText={formatMessage(messages.photo)}>
                    <SubSection>
                        <PhotoList
                            formatMessage={formatMessage}
                            record={this.observation[model.PROPS_OBSERVATION_PHOTO_LIST]}
                            itemListAlias={model.PROPS_OBSERVATION_PHOTO_LIST}
                            onTextChange={onTextChange}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    render() {
        return (
            <div className="add-edit-panel">
                {this.renderObservationInfo()}
                <div className="section-container">
                    {this.renderDetailInfo1()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo2()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo3()}
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
