import * as model from "./model";

const userRoleDefaultRecord = () => ({
    [model.PROPS_NAME]: "",
    [model.PROPS_DESCRIPTION]: "",
    [model.PROPS_ACCESS_LEVEL]: "",
    [model.PROPS_IS_ASSIGNED]: false,
    [model.PROPS_CAN_DELETE]: true,
});

export default userRoleDefaultRecord;
