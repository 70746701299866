import { connect } from "react-redux";
import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, dropdowns, unitTypes, service } from "./../data";
import {
    testEquation,
    validateCropListToggle,
    fetchEquationParameterPickList,
} from "./../data/actions";
import { getEquationTestValue, getEquationParametersPicklist } from "./../data/selectors";

const mapStateToProps = (state) => ({
    testValue: getEquationTestValue(state),
    equationParametersPicklist: getEquationParametersPicklist(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchEquationParameterPickList: (payload) => dispatch(fetchEquationParameterPickList(payload)),
    testEquation: (payload) => dispatch(testEquation(payload)),
    validateCropListToggle: (payload) => dispatch(validateCropListToggle(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AddEditPanelContainer(AddEditPanel, {
        pickLists,
        dropdowns,
        unitTypes,
        service,
    })
);
