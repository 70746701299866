import { action } from "typesafe-actions";

export const SET_IS_LOADING = "theme/SET_IS_LOADING";
export const SWITCH_THEME = "theme/SWITCH_THEME";

export const ThemeClassNames = {
    DEFAULT: "default",
    DEFAULT_LIGHT: "default-light",
    SKY: "sky",
    SKY_LIGHT: "sky-light",
    GRASS: "grass",
    GRASS_LIGHT: "grass-light",
    MUD: "mud",
    MUD_LIGHT: "mud-light",
    ILLINI: "illini",
    ILLINI_LIGHT: "illini-light",
};

export const ThemeClassNamesDataBase = {
    SKY: "Color 2",
    GRASS: "Color 3",
    MUD: "Color 4",
    ILLINI: "Color 5",
};

export const switchTheme = (themeClassName: string) => action(SWITCH_THEME, themeClassName);

export const setIsLoading = (isLoading: boolean) => action(SET_IS_LOADING, isLoading);
