import { connect } from "react-redux";
import { actions as recsEventsActions, recsSelectors } from "~/recs-events";

const mapDispatchToProps = (dispatch, ownProps) => ({
    onUpdateModel: (newProps) =>
        dispatch(recsEventsActions.updateCurrentRecAreaRec(ownProps.fieldGuid, newProps)),
    updatePlantingModel: (payload) => dispatch(recsEventsActions.updatePlantingRecModel(payload)),
});

const mapStateToProps = (state) => {
    const { saveRecDetailsErrorCodeList } = recsSelectors.getModuleState(state);
    return { saveEventDetailsErrorCodeList: saveRecDetailsErrorCodeList };
};

export const RecManualPlantingForm = (EventPlantingFormView) =>
    connect(mapStateToProps, mapDispatchToProps)(EventPlantingFormView);
