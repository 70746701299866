import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    TextInput,
    SelectInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    NumericInput,
    ZeroToInfiniteGrid,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import { messages } from "../../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import * as unitService from "~/core/units/unit-names";
import * as picklistService from "~/core/picklist/picklist-names";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";

const PROPS_FUEL_ADDED = "fuelAdded";
const PROPS_FUEL_ADDED_DATE = "fuelAddedDate";
const PROPS_FUEL_ADDED_UNIT_GUID = "fuelAddedUnitGuid";
const PROPS_NOTES = "notes";

const PROPS_BIN_TANK_NAME = "binTankName";
const PROPS_CONTAINER_SIZE = "containerSize";
const PROPS_CONTAINER_SIZE_UNIT_GUID = "containerSizeUnitGuid";
const PROPS_PHYSICAL_STATE_GUID = "physicalStateGuid";
const PROPS_CONTAINER_SIZE_UNIT_NAME = "containerSizeUnitName";
const PROPS_PHYSICAL_STATE_NAME = "physicalStateName";

class BinTankList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        needs: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchDropdownData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
            record: [],
            [picklistService.PICKLIST_PHYSICAL_STATE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_PHYSICAL_STATE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [unitService.UNIT_VOLUME]: prepareSelectableOptions(props[unitService.UNIT_VOLUME], {
                guid: GUID,
                label: NAME,
            }),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(this.state.record) !== JSON.stringify(this.props.record) &&
            nextProps[picklistService.PICKLIST_PHYSICAL_STATE] &&
            nextProps[unitService.UNIT_VOLUME]
        ) {
            this.setState({
                record: this.initializeBinTankList(nextProps),
            });
        }

        this.initializeContainerSizeUnit(nextProps);
        this.initializePhysicalState(nextProps);
    }

    initializeBinTankList = (nextProps) => {
        const { record } = nextProps;
        if (record) {
            const physicalStateOptions = nextProps[picklistService.PICKLIST_PHYSICAL_STATE];
            const containerSizeOptions = nextProps[unitService.UNIT_VOLUME];

            return record.map((recordItem) => {
                const selectedPhysicalState = physicalStateOptions.find(
                    (physicalStateItem) =>
                        physicalStateItem.picklistValueGuid ===
                        recordItem[PROPS_PHYSICAL_STATE_GUID]
                );
                recordItem[PROPS_PHYSICAL_STATE_NAME] =
                    selectedPhysicalState && selectedPhysicalState[VALUE];
                const selectedContainerSize = containerSizeOptions.find(
                    (containerSizeItem) =>
                        containerSizeItem.guid === recordItem[PROPS_CONTAINER_SIZE_UNIT_GUID]
                );
                recordItem[PROPS_CONTAINER_SIZE_UNIT_NAME] =
                    selectedContainerSize && selectedContainerSize[NAME];
                return recordItem;
            });
        }
        return record;
    };

    initializePhysicalState = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_PHYSICAL_STATE] !==
            this.props[picklistService.PICKLIST_PHYSICAL_STATE]
        ) {
            this.setState({
                [picklistService.PICKLIST_PHYSICAL_STATE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_PHYSICAL_STATE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    initializeContainerSizeUnit = (nextProps) => {
        if (nextProps[unitService.UNIT_VOLUME] !== this.props[unitService.UNIT_VOLUME]) {
            this.setState({
                [unitService.UNIT_VOLUME]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_VOLUME],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    onEdit = (record, index) => {
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_FUEL_ADDED_UNIT_GUID],
                key: GUID,
            },
            {
                name: [PROPS_FUEL_ADDED_DATE],
            },
            {
                name: [PROPS_FUEL_ADDED],
            },
            {
                name: [PROPS_NOTES],
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                action="save"
                className="bin-tank-dialog"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                actionDisabled={!editData[PROPS_BIN_TANK_NAME]}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.binTank
                )}
            >
                <div className="accessories-group">
                    <TextInput
                        required
                        maxLength={50}
                        autoFocus
                        openOnFocus={false}
                        placeholderText={formatMessage(messages.binTankName)}
                        value={editData[PROPS_BIN_TANK_NAME]}
                        onChange={(value) =>
                            onChange({
                                [PROPS_BIN_TANK_NAME]: value,
                            })
                        }
                    />
                    <SelectInput
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[picklistService.PICKLIST_PHYSICAL_STATE]}
                        value={mapToPicklistValue({
                            options: this.state[picklistService.PICKLIST_PHYSICAL_STATE],
                            selectedGuid: editData[PROPS_PHYSICAL_STATE_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_PHYSICAL_STATE_GUID]: value.guid,
                                [PROPS_PHYSICAL_STATE_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.physicalState)}
                    />
                </div>
                <div className="accessories-group">
                    <NumericInput
                        tabIndex={0}
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.containerSize)}
                        labelText={formatMessage(messages.containerSize)}
                        value={editData[PROPS_CONTAINER_SIZE] || ""}
                        onChange={(value) => onChange({ [PROPS_CONTAINER_SIZE]: value })}
                    />
                    <SelectInput
                        options={this.state[unitService.UNIT_VOLUME]}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        value={mapToPicklistValue({
                            options: this.state[unitService.UNIT_VOLUME],
                            selectedGuid: editData[PROPS_CONTAINER_SIZE_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_CONTAINER_SIZE_UNIT_GUID]: value.guid,
                                [PROPS_CONTAINER_SIZE_UNIT_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.containerSizeUnit)}
                    />
                </div>
            </DialogBox>
        );
    };

    render() {
        const { formatMessage } = this.props;
        const { record } = this.state;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_BIN_TANK_NAME]: {
                                title: formatMessage(messages.name),
                            },
                            [PROPS_PHYSICAL_STATE_NAME]: {
                                title: formatMessage(messages.physicalState),
                            },
                            [PROPS_CONTAINER_SIZE]: {
                                title: formatMessage(messages.containerSize),
                            },
                            [PROPS_CONTAINER_SIZE_UNIT_NAME]: {
                                title: formatMessage(messages.unit),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.binTank)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(BinTankList);
