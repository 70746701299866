import { createService } from "~/hocs/crud";
import * as model from "./model";
import defaultPersonRecord from "./default-record";
import { adminData } from "~/admin/data";
import { PersonAPI, AddressAPI, HierarchyAPI } from "@ai360/core";

import {
    PICKLIST_JOB_TITLE,
    PICKLIST_PHONE_TYPE,
    PICKLIST_PAY_TYPE,
    PICKLIST_LICENSE_TYPE,
    getPickListCode,
} from "~/core/picklist/picklist-names";

import { UNIT_PAY_RATE, getUnitCode } from "~/core/units/unit-names";

// Module Name
export const MODEL_NAME = "person";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "personFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "personSort";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "personPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

// URLs
export const AUTO_SEARCH_URL = PersonAPI.AUTO_SEARCH_PERSON;
export const CREATE = PersonAPI.ADD_PERSON;
export const DELETE = PersonAPI.DELETE_PERSON;
export const EXPORT_FILE_NAME = "PersonExport";
export const EXPORT_URL = PersonAPI.EXPORT_PERSON_LIST;
export const GETRECORD = PersonAPI.GET_PERSON;
export const HIERARCHY_URL = HierarchyAPI.GET_HIERARCHY_FILTER_LIST_WITH_SEARCH;
export const IMPORT_URL = PersonAPI.IMPORT_PERSON_LIST;
export const IMPORT_VALID_URL = PersonAPI.IMPORT_PERSON_VALID_URL;
export const REQUEST_ORG_LEVEL = HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS_GUID;
export const SELECT_ALL = PersonAPI.SELECT_ALL_PERSON;
export const UPDATE = PersonAPI.UPDATE_PERSON;
export const URL = PersonAPI.GET_PERSONS;

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        City: "",
        FirstName: "",
        FullName: "",
        IsActive: "",
        JobTitleName: "",
        LastName: "",
        StateAbbr: "",
        orgLevelGuid: null,
        stateGuid: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            FieldName: "FullName",
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
    userGuid: "",
};

export const defaultSort = {
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
    FieldName: "FirstName",
};

export const dropdowns = {
    [model.PROPS_ADDRESS_TYPE_LIST]: AddressAPI.REQUEST_ADDRESS_TYPE,
    [model.PROPS_COUNTRIES_LIST]: AddressAPI.REQUEST_COUNTRIES,
    [model.PROPS_STATES_LIST]: { url: AddressAPI.REQUEST_STATES, model: "" },
    [model.PROPS_ORG_LEVEL_LIST]: { url: REQUEST_ORG_LEVEL, model: "_" },
};

export const pickLists = {
    [PICKLIST_JOB_TITLE]: getPickListCode(PICKLIST_JOB_TITLE),
    [PICKLIST_PHONE_TYPE]: getPickListCode(PICKLIST_PHONE_TYPE),
    [PICKLIST_PAY_TYPE]: getPickListCode(PICKLIST_PAY_TYPE),
    [PICKLIST_LICENSE_TYPE]: getPickListCode(PICKLIST_LICENSE_TYPE),
};

export const unitTypes = {
    [UNIT_PAY_RATE]: getUnitCode(UNIT_PAY_RATE),
};

// Service
export const service = createService({
    id: model.PROPS_PERSON_ID,
    guid: model.PROPS_PERSON_GUID,
    name: model.PROPS_PERSON_NAME,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    EXPORT_FILE_NAME,
    dropdowns,
    unitTypes,
    pickLists,
    activeColumnName: adminData.PROPS_IS_ACTIVE,
    urls: {
        URL,
        HIERARCHY_URL,
        EXPORT_URL,
        GETRECORD,
        DELETE,
        IMPORT_VALID_URL,
        IMPORT_URL,
        SELECT_ALL,
        AUTO_SEARCH_URL,
        CREATE,
        UPDATE,
    },
    _defaultLabels: {
        [model.PROPS_PERSON_FIRST_NAME]: { label: "firstName", gridCol: 15 },
        [model.PROPS_PERSON_LAST_NAME]: { label: "lastName", gridCol: 15 },
        [model.PROPS_PERSON_JOB]: { label: "jobTitle", gridCol: 15 },
        [model.PROPS_PERSON_CITY]: { label: "city", gridCol: 15 },
        [model.PROPS_PERSON_STATE_ABBR]: { label: "stateAbbr", gridCol: 10 },
        [model.PROPS_IS_ACTIVE]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => ({ ...defaultPersonRecord() }),
    defaultSort,
});
