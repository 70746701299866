import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";
import { PICKLIST_GROWTH_STAGE_GROUP_TYPE, getPickListCode } from "~/core/picklist/picklist-names";

import * as model from "./model";

// Module Name
export const MODEL_NAME = "growthStage";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "growthStageFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "growthStageSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "growthStagePageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";
// URLs
export const URL = apiUrl("AgBytes/GetGrowthStageList");
export const EXPORT_URL = apiUrl("AgBytes/ExportGrowthStageList");
export const IMPORT_URL = apiUrl("AgBytes/GrowthStageImport");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidGrowthStageImport");
export const EXPORT_FILE_NAME = "GrowthStageExport";
export const SELECT_ALL = apiUrl("AgBytes/GetGrowthStageSelectAllList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetGrowthStageAutoSearchList");
export const GETRECORD = apiUrl("AgBytes/GetGrowthStage");
export const NEXT_ID = apiUrl("AgBytes/GetNextGrowthStageId");
export const CREATE = apiUrl("AgBytes/AddGridGrowthStage");
export const UPDATE = apiUrl("AgBytes/UpdateGridGrowthStage");
export const DELETE = apiUrl("AgBytes/DeleteGrowthStageList");

const defaultGrowthStage = () => ({
    [model.PROPS_GROWTH_STAGE_GUID]: "",
    [model.PROPS_GROWTH_STAGE_ID]: null,
    [model.PROPS_GROWTH_STAGE_GROUP_TYPE]: "",
    [model.PROPS_GROUP_NAME]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_CAN_DELETE]: false,
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_STAGE_ORDER_LIST]: "",
});

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        canDelete: "",
        groupName: "",
        growthStageGroupType: "",
        growthStageId: "",
        isActive: "",
        modifiedDate: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "GrowthStageGroupType",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const pickLists = {
    [PICKLIST_GROWTH_STAGE_GROUP_TYPE]: getPickListCode(PICKLIST_GROWTH_STAGE_GROUP_TYPE),
};

// Service
export const service = createService({
    id: model.PROPS_GROWTH_STAGE_ID,
    guid: model.PROPS_GROWTH_STAGE_GUID,
    name: model.PROPS_GROUP_NAME,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    EXPORT_FILE_NAME,
    useUpdatePut: true,
    pickLists,
    urls: {
        URL,
        EXPORT_URL,
        IMPORT_VALID_URL,
        IMPORT_URL,
        SELECT_ALL,
        AUTO_SEARCH_URL,
        GETRECORD,
        NEXT_ID,
        CREATE,
        UPDATE,
        DELETE,
    },
    _defaultLabels: {
        [model.PROPS_GROWTH_STAGE_ID]: {
            label: "growthStageGroupID",
            gridCol: 10,
        },
        [model.PROPS_GROWTH_STAGE_GROUP_TYPE]: {
            label: "growthStageGroupType",
            gridCol: 10,
        },
        [model.PROPS_GROUP_NAME]: {
            label: "growthStageGroupName",
            gridCol: 30,
            className: "flex-grow-2",
        },
        [model.PROPS_ACTIVE_YN]: {
            label: model.PROPS_IS_ACTIVE,
            gridCol: 10,
            visible: false,
            sortNameOverRide: model.PROPS_IS_ACTIVE,
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 10,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => ({ ...defaultGrowthStage() }),
    defaultSort,
});
