// import * as model from "./model";
import locationMigrationSagas from "./sagas";
import * as customActions from "./actions";
import * as selectors from "./selectors";

const actions = {
    ...customActions,
};

export { actions, locationMigrationSagas, selectors };
