import React, { FocusEvent as ReactFocusEvent, Component, ReactElement } from "react";
import classnames from "classnames";
import "./text-area.css";

export interface ITextAreaInputProps {
    className?: string;
    showTopLabel?: boolean;
    labelText?: string;
    placeholderText?: string;
    required?: boolean;
    height?: number | string;
    width?: number | string;
    onKeyDown?: (event: any) => void;
    onFocus?: (event?: ReactFocusEvent) => void;
    onBlur?: (event: ReactFocusEvent) => void;
    disabled?: boolean;
    value: string;
    maxLength?: number;
    onChange?: (event: any) => void;
    containerClassNames?: Array<string>;
    readOnly?: boolean;
    id?: string;
}
export interface ITextAreaInputState {
    id?: string;
    value: string;
    hasFocus?: boolean;
}
export interface ISelectionRange {
    selectionStart: number;
    selectionEnd: number;
    selectionDirection: string;
}

class TextArea extends Component<ITextAreaInputProps, ITextAreaInputState> {
    public static defaultProps = {
        showTopLabel: true,
        labelText: "",
        placeholderText: "",
        required: false,
        maxLength: 256,
        containerClassNames: [],
    };

    static REQUIRED_TEXT = "*";

    textAreaRef = null;

    constructor(props: ITextAreaInputProps) {
        super(props);
        const { value } = props;
        this.state = {
            id: "",
            value,
            hasFocus: false,
        };
    }

    UNSAFE_componentWillReceiveProps({ value }: { value: string }): void {
        if (value !== this.props.value) {
            this.setState({
                value,
            });
        }
    }

    public focus = (selectionRange?: ISelectionRange): void => {
        this.textAreaRef?.focus();
        if (selectionRange != null && this.textAreaRef != null) {
            const { selectionStart, selectionEnd, selectionDirection } = selectionRange;
            setTimeout(() => {
                this.textAreaRef.setSelectionRange(
                    selectionStart,
                    selectionEnd,
                    selectionDirection
                );
                let selectionRow = 0;
                let runningIndex = 0;
                for (const row of this.textAreaRef.value.split("\n")) {
                    if (runningIndex > selectionStart) {
                        break;
                    }
                    selectionRow++;
                    runningIndex += row.length;
                }
                const lineHeight =
                    parseFloat(
                        document.defaultView
                            .getComputedStyle(this.textAreaRef, null)
                            .getPropertyValue("font-size")
                    ) + 5;
                this.textAreaRef.scrollTop = lineHeight * selectionRow;
            }, 0);
        }
    };

    public getSelectionRange = (delta: any = 0): any => {
        return {
            selectionStart: (this.textAreaRef?.selectionStart || 0) + delta,
            selectionEnd: (this.textAreaRef?.selectionEnd || 0) + delta,
            selectionDirection: this.textAreaRef?.selectionDirection || "none",
        };
    };

    public handleChange = ({ target }: { target: any }): void => {
        this.setState(
            {
                value: target.value,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.value);
                }
            }
        );
    };

    public toggleFocus = (): void => {
        this.setState({
            hasFocus: !this.state.hasFocus,
        });
    };

    public onKeyDown = (event: KeyboardEvent): void => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    public onFocus = (event: ReactFocusEvent): void => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }

        this.toggleFocus();
    };

    public onBlur = (event: ReactFocusEvent): void => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
        this.toggleFocus();
    };

    public prepStyleObject = (): Record<string, any> => {
        const { height, width } = this.props;
        const style = {
            height,
            width,
        };
        if (height) {
            style.height = height;
        }
        if (width) {
            style.width = width;
        }
        return style;
    };

    public prepContainerClassNames = (): string => {
        const defaultClassNames = "form-input textarea-form-input";
        const focusClass = "focus";
        const { hasFocus } = this.state;
        const { containerClassNames } = this.props;
        if (hasFocus) {
            return classnames(defaultClassNames, focusClass, ...containerClassNames);
        }
        return classnames(defaultClassNames, ...containerClassNames);
    };

    public prepTextAreaClassNames = (): string => {
        const defaultClassNames = "";
        const { required } = this.props;
        if (required) {
            return `${defaultClassNames} required-input`;
        }
        return `${defaultClassNames}`;
    };

    public getRequiredStar = (): Record<string, any> => (
        <span className="red-star">{TextArea.REQUIRED_TEXT}</span>
    );

    render(): ReactElement {
        const { required, disabled, showTopLabel, maxLength, placeholderText, readOnly } =
            this.props;
        const { hasFocus, value } = this.state;
        const labelText = this.props.labelText ? this.props.labelText : placeholderText;
        const placeholder = this.state.hasFocus ? "" : placeholderText;

        const style = this.prepStyleObject();
        const containerClassNames = this.prepContainerClassNames();
        const textAreaClassNames = this.prepTextAreaClassNames();

        return (
            <div className={containerClassNames} style={style}>
                <div className="input-label-container">
                    {showTopLabel && (hasFocus || value) && (
                        <label>
                            {required && this.getRequiredStar()}
                            {labelText + ":"}
                        </label>
                    )}
                </div>
                <div className="input-container">
                    {required && !(value || hasFocus) ? this.getRequiredStar() : null}
                    <textarea
                        ref={(ref) => (this.textAreaRef = ref)}
                        wrap="soft"
                        maxLength={maxLength}
                        disabled={disabled}
                        className={textAreaClassNames}
                        value={this.state.value}
                        onChange={!disabled ? this.handleChange : null}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        placeholder={placeholder}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        );
    }
}

export default TextArea;
