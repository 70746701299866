import * as model from "./model";
import { createService } from "~/hocs/crud";
import { aggregateUrlGenerator } from "@ai360/core";

const AGGREGATE_NAME = "unit";
const ENDPOINT_NAME = "agbytes/unit";
const API_VERSION = "v1.0";

const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const URL = aggregateUrl("list");
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const EXPORT_FILE_NAME = "UnitExport";
export const SELECT_ALL = aggregateUrl("selectAll");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "unitFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "unitSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "unitPageOptions";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        unitId: "",
        unitName: "",
        abbreviation: "",
        conversionFactor: "",
        unitSystem: "",
        factorUnit: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
};

export const defaultSort = {
    FieldName: "UnitType",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const service = createService({
    id: model.PROPS_UNIT_ID,
    guid: model.PROPS_UNIT_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    activeColumnName: model.PROPS_ACTIVE_YN,
    urls: {
        URL,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
    },
    _defaultLabels: {
        [model.PROPS_UNIT_ID]: { label: "unitId", gridCol: 10 },
        [model.PROPS_UNIT_TYPE]: { label: "unitType", gridCol: 15 },
        [model.PROPS_UNIT_NAME]: { label: "unitName", gridCol: 15 },
        [model.PROPS_UNIT_ABBREVIATION]: { label: "abbreviation", gridCol: 10 },
        [model.PROPS_UNIT_SYSTEM]: { label: "unitSystem", gridCol: 15, sortNameOverRide: "System" },
        [model.PROPS_UNIT_CONVERSION_FACTOR]: {
            label: "conversionFactor",
            gridCol: 10,
        },
        [model.PROPS_UNIT_FACTOR_UNIT]: { label: "factorUnit" },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
            className: "col-shift-15",
            sortNameOverRide: "isActive",
        },
    },
    defaultSort,
    useNewApi: true,
});
