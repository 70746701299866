import React from "react";
import { connect } from "react-redux";
import { InjectedIntl, injectIntl } from "react-intl";
import { DialogBox, DialogBoxFooterType } from "~/core";
import * as analysisActions from "../analysis-info/actions";
import { messages } from "../i18n-messages";
import { LayerAPI } from "@ai360/core";

interface IAnalysisBatchDeleteDialogBoxProps {
    closeConfirmDialog: () => void;
    intl: InjectedIntl;
    isConfirmationModalOpen: boolean;
    onDeleteSingle: () => void;
    onDeleteAnalysisLayerBatch: (
        batchGuid: string,
        analysisInBatch: LayerAPI.IAnalysisInBatch[]
    ) => void;
    surfaceInfo: LayerAPI.ISubLayer;
}

const AnalysisBatchDeleteDialogBox = (props: IAnalysisBatchDeleteDialogBoxProps): JSX.Element => {
    const {
        closeConfirmDialog,
        isConfirmationModalOpen,
        intl,
        onDeleteSingle,
        onDeleteAnalysisLayerBatch,
        surfaceInfo,
    } = props;
    const { formatMessage } = intl;

    const body = formatMessage(messages.deleteMultipleBody, {
        analysisLayerName: surfaceInfo.analysisLayerName,
        fieldName: surfaceInfo.fieldName,
    });
    const fieldNameElements = surfaceInfo.analysisInBatch.map((x, i) => (
        <li className="list-item" key={i}>
            {x.fieldName}
        </li>
    ));

    const onDeleteAll = () => {
        onDeleteAnalysisLayerBatch(surfaceInfo.batchGuid, surfaceInfo.analysisInBatch);
        closeConfirmDialog();
    };

    return (
        <DialogBox
            className="surface-layer-context-menu-batch-dialog-box"
            footerType={DialogBoxFooterType.MULTI_ACTION}
            isOpen={isConfirmationModalOpen}
            multiActionList={[
                {
                    action: formatMessage(messages.deleteAllButton),
                    actionDisabled: false,
                    onAction: () => onDeleteAll(),
                },
                {
                    action: formatMessage(messages.deleteSingleButton),
                    actionDisabled: false,
                    onAction: () => onDeleteSingle(),
                },
                {
                    action: formatMessage(messages.cancel),
                    actionDisabled: false,
                    isCancelStyle: true,
                    onAction: () => closeConfirmDialog(),
                },
            ]}
            title={formatMessage(messages.confirmDeleteTitle)}
        >
            <div className="body">{body}</div>
            <ul className="list">{fieldNameElements}</ul>
        </DialogBox>
    );
};
const mapStateToProps = (state, props: Partial<IAnalysisBatchDeleteDialogBoxProps>) => ({
    ...props,
});

const mapDispatchToProps = (dispatch) => ({
    onShowAnalysisLayer: (analysisInfoGuid) =>
        dispatch(analysisActions.showAnalysisInfo(analysisInfoGuid)),
    onDeleteAnalysisLayer: (analysisLayerGuid, fieldGuid, layerType) =>
        dispatch(analysisActions.deleteAnalysisLayer(analysisLayerGuid, fieldGuid, layerType)),
    onDeleteAnalysisLayerBatch: (batchGuid, analysisInBatch) =>
        dispatch(analysisActions.deleteAnalysisLayerBatch(batchGuid, analysisInBatch)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AnalysisBatchDeleteDialogBox));
