import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classnames from "classnames";

import { model as accordionModels, selectors as accordionSelectors, Accordion } from "~/accordion";

import * as actions from "../../../actions";
import * as selectors from "../../../selectors";

import { ImportTypeItem, IMPORT_TYPE_HEIGHT } from "./import-type-accordion-item";

import { ImportTemplateItem, IMPORT_TEMPLATE_HEIGHT } from "./import-template-accordion-item";

import { ImportFileInfoItem, IMPORT_FILE_INFO_HEIGHT } from "./import-file-info-accordion-item";

import "./import-accordion.css";

export const createImportTypeAccordionItems = (fileImportTypeList) => {
    return fileImportTypeList.map((impType) => {
        const payload = { guid: impType.guid };
        return new accordionModels.AccordionItem(IMPORT_TYPE_HEIGHT, impType.isExpanded, payload);
    });
};

export const createImportTemplateAccordionItems = (fileImportTemplates) => {
    return fileImportTemplates.map((impTemplate) => {
        const payload = { templateGuid: impTemplate.templateGuid };
        return new accordionModels.AccordionItem(
            IMPORT_TEMPLATE_HEIGHT,
            impTemplate.isExpanded,
            payload
        );
    });
};

export const createImportFileAccordionItems = (importFileInfoList) => {
    return importFileInfoList.map((importFileInfo) => {
        const payload = { importFileGuid: importFileInfo.guid };
        return new accordionModels.AccordionItem(IMPORT_FILE_INFO_HEIGHT, false, payload);
    });
};

const getAccordionItemEl = ({
    key,
    style,
    accordionItem,
    accordionItemDimIdx,
    isStuck,
    componentProps,
}) => {
    let contentEl;
    const classNames = ["import-accordion-item", { sticky: isStuck }];
    if (accordionItemDimIdx.length === 1) {
        contentEl = (
            <ImportTypeItem
                accordionId={componentProps.accordionId}
                isExpanded={accordionItem.expanded}
                itemDimIdx={accordionItemDimIdx}
                typeGuid={accordionItem.payload.guid}
            />
        );
    } else if (accordionItemDimIdx.length === 2) {
        contentEl = (
            <ImportTemplateItem
                accordionId={componentProps.accordionId}
                isExpanded={accordionItem.expanded}
                itemDimIdx={accordionItemDimIdx}
                templateGuid={accordionItem.payload.templateGuid}
            />
        );
    } else {
        console.assert(accordionItemDimIdx.length === 3);
        contentEl = (
            <ImportFileInfoItem
                accordionId={componentProps.accordionId}
                isExpanded={accordionItem.expanded}
                itemDimIdx={accordionItemDimIdx}
                importFileGuid={accordionItem.payload.importFileGuid}
            />
        );
    }

    return (
        <div key={key} style={style} className={classnames(classNames)}>
            {contentEl}
        </div>
    );
};

getAccordionItemEl.propTypes = {
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Unique key within array of rows
    style: PropTypes.object, // Style object to be applied to row (to position it)
    accordionItem: PropTypes.object,
    accordionItemDimIdx: PropTypes.arrayOf(PropTypes.number),
    isStuck: PropTypes.bool,
    componentProps: PropTypes.object, // Pass thru props from <Accordion />
};

const mapDispatchToProps = (dispatch) => ({
    onScrollTopChanged: (scrollTop) => dispatch(actions.setInitScrollTop(scrollTop)),
});

const mapStateToProps = (state) => {
    const accordionState = selectors.getAccordionState(state);
    const moduleState = selectors.getModuleState(state);

    const flatIdxMapSelector = accordionSelectors.flatIdxMapSelector(accordionState);
    const dimIdxMapSelector = accordionSelectors.dimIdxMapSelector(accordionState);

    return {
        itemCount: accordionState.recItemCount,
        itemList: accordionState.items,
        totalHeight: accordionState.recHeight,
        getAccordionItemFromFlatIdx: (idx) => flatIdxMapSelector(idx),
        getAccordionItemFromDimIdx: (dimIdx) => dimIdxMapSelector(dimIdx),
        getAccordionItemEl: getAccordionItemEl,
        scrollTop: moduleState.initScrollTop,
        scrollToIndex: moduleState.scrollToIndex,

        // pass-thru prop; <Accordion /> doesn't care about these but they're passed-thru
        //  to `getAccordionItemEl`
        accordionId: accordionState.accordionId,
    };
};

export const ImportAccordion = connect(mapStateToProps, mapDispatchToProps)(Accordion);
