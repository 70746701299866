import { ADMIN_STATE_KEY } from "~/admin";
import { BATCH_ZAPPER_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[ADMIN_STATE_KEY][BATCH_ZAPPER_DATA_KEY];

export const getAttributes = (state) => _getModuleState(state).attributes;
export const getFilteredLocations = (state) => _getModuleState(state).filteredLocations;
export const getFilteredUserRoles = (state) => _getModuleState(state).filteredUserRoles;
export const getFilteredUsers = (state) => _getModuleState(state).filteredUsers;
export const getLocationEntities = (state) => _getModuleState(state).locations;
export const getUserRoles = (state) => _getModuleState(state).userRoles;
export const getUsers = (state) => _getModuleState(state).users;
export const getUserCompanyList = (state) => _getModuleState(state).userCompanyList;
export const getLastUsedOwnerGuid = (state) => _getModuleState(state).lastUsedOwner;
