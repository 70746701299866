import React, { Component } from "react";
import PropTypes from "prop-types";
import { messages } from "../../../i18n-messages";
import { Bucket, BucketHeader, Checkbox, Section, SelectInput, SubSection } from "~/core";
import { model } from "../../data";
import { adminData, NAME } from "~/admin/data";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import SearchTelematicsUser from "./search-telematics-user";
const TELEMATIC_NODE_ID = "telematics_node_id";

export default class TelematicsSetup extends Component {
    static propTypes = {
        formatMessage: PropTypes.func,
        onSelectInputChange: PropTypes.func,
        onTextChange: PropTypes.func,
        onNumberChange: PropTypes.func,
        equipmentSetup: PropTypes.object,
        initialValue: PropTypes.string,
        userGuid: PropTypes.string,
        needs: PropTypes.func,
        getOnsiteEquipmentList: PropTypes.func,
        onsiteEquipmentList: PropTypes.array,
    };
    static defaultProps = {
        initialValue: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            [model.PROPS_ONSITE_EQUIPMENT_LIST]: [],
            initialValue: this.props.initialValue,
        };
    }
    onItemSelection = (value) => {
        if (this.props.onTextChange) {
            this.props.onTextChange(
                model.PROPS_TELEMATIC_PRIMARY_USER_GUID,
                value,
                this.getUserOnsiteEquipmentList
            );
        }
    };

    getUserOnsiteEquipmentList = () => {
        if (this.props.equipmentSetup[model.PROPS_TELEMATIC_PRIMARY_USER_GUID]) {
            this.props.needs([
                this.props.getOnsiteEquipmentList({
                    userGuid: this.props.equipmentSetup[model.PROPS_TELEMATIC_PRIMARY_USER_GUID],
                    onsiteConnectionGuid: null,
                }),
            ]);
        } else {
            this.setState({
                [model.PROPS_ONSITE_EQUIPMENT_LIST]: [],
            });
        }
        if (this.props.onSelectInputChange) {
            this.props.onSelectInputChange(
                { guid: [model.PROPS_TELEMATIC_EQUIPMENT_NODE_GUID] },
                { guid: "" }
            );
        }
    };

    initializeOnsiteUserEquipmentList = (nextProps) => {
        if (
            nextProps[model.PROPS_ONSITE_EQUIPMENT_LIST] !==
            this.state[model.PROPS_ONSITE_EQUIPMENT_LIST]
        ) {
            this.setState({
                [model.PROPS_ONSITE_EQUIPMENT_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_ONSITE_EQUIPMENT_LIST],
                    {
                        guid: TELEMATIC_NODE_ID,
                        label: NAME,
                        id: TELEMATIC_NODE_ID,
                        appendIdToLabel: true,
                    }
                ),
            });
        }
    };

    _updateActiveYN = (value) => {
        this.setState({
            [model.PROPS_TELEMATIC_ACTIVE_YN]: value,
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.equipmentSetup[model.PROPS_TELEMATIC_ACTIVE_YN] !==
            this.state[model.PROPS_TELEMATIC_ACTIVE_YN]
        ) {
            this._updateActiveYN(nextProps.equipmentSetup[model.PROPS_TELEMATIC_ACTIVE_YN]);
        }
        if (
            nextProps.equipmentSetup[model.PROPS_TELEMATIC_PRIMARY_USER_GUID] !==
            this.props.equipmentSetup[model.PROPS_TELEMATIC_PRIMARY_USER_GUID]
        ) {
            this.setState(
                {
                    initialValue: nextProps.equipmentSetup[model.PROPS_TELEMATIC_PRIMARY_USER_GUID],
                },
                this.getUserOnsiteEquipmentList
            );
        }
        this.initializeOnsiteUserEquipmentList(nextProps);
    }

    render() {
        const { formatMessage, equipmentSetup, onSelectInputChange, onTextChange } = this.props;
        return (
            <Bucket
                className="form-section-bucket"
                showSymbol={true}
                isCollapsible={true}
                isExpanded={true}
            >
                <BucketHeader>{formatMessage(messages.telematicsSetup)}</BucketHeader>
                <Section>
                    <SubSection>
                        <Checkbox
                            onChange={(e, value) => {
                                this._updateActiveYN(value);
                                onTextChange(model.PROPS_TELEMATIC_ACTIVE_YN, value);
                            }}
                            value={equipmentSetup[model.PROPS_TELEMATIC_ACTIVE_YN]}
                            label={formatMessage(messages.active)}
                        />
                    </SubSection>
                    <SubSection className="telematics-user-search">
                        <SearchTelematicsUser
                            initialValue={
                                this.props.equipmentSetup[model.PROPS_TELEMATIC_PRIMARY_USER_GUID]
                                    ? this.props.equipmentSetup[
                                          model.PROPS_TELEMATIC_PRIMARY_USER_GUID
                                      ]
                                    : ""
                            }
                            onSelection={(value) => this.onItemSelection(value)}
                            disabled={!this.state[model.PROPS_TELEMATIC_ACTIVE_YN]}
                        />
                        <SelectInput
                            tabIndex={0}
                            containerClassNames={["telematics-equipment-list"]}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_ONSITE_EQUIPMENT_LIST]}
                            placeholderText={formatMessage(messages.equipmentNode)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_ONSITE_EQUIPMENT_LIST],
                                selectedGuid:
                                    equipmentSetup[model.PROPS_TELEMATIC_EQUIPMENT_NODE_GUID],
                            })}
                            onChange={(value) => {
                                onSelectInputChange(
                                    {
                                        guid: model.PROPS_TELEMATIC_EQUIPMENT_NODE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
            </Bucket>
        );
    }
}
