import React from "react";

export function useOnScreen(
    ref: React.RefObject<HTMLElement>,
    threshold: number | number[],
    isOnScreen: (value: boolean) => void
) {
    const observerRef = React.useRef<IntersectionObserver | null>(null);

    React.useEffect(() => {
        const init: IntersectionObserverInit = {
            threshold,
        };

        observerRef.current = new IntersectionObserver(
            ([entry]) => isOnScreen(entry.isIntersecting),
            init
        );
    }, [threshold, isOnScreen]);

    React.useEffect(() => {
        observerRef.current.observe(ref.current);

        return () => {
            observerRef.current.disconnect();
        };
    }, [ref]);
}
