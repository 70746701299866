import { connect } from "react-redux";
import { selectors as picklistSelectors } from "~/core/picklist";
import * as recsEventInfoSelectors from "~/recs-events/events/selectors";
import { selectors as productBlendSelector } from "~/action-panel/components/common/product-blend-modal";
import { selectors as unitSelectors, unitNames } from "~/core/units";

const getModel = (areaItem) => {
    let index = 0;
    if (areaItem.agEventList.length > 1) {
        index = areaItem.agEventList.findIndex(
            (x) => x.agEventTypeInfo.agEventTransactionTypeName === "Planting"
        );
    }
    const model = areaItem.agEventList[index].agEventModel;
    return {
        ...model,
        varietyHybridList: model.eventPlantingVarietyHybridList,
    };
};

const mapStateToProps = (state, ownProps) => {
    const fieldGuidToDetailsMap =
        recsEventInfoSelectors.getModuleState(state).fieldGuidToEventDetails;
    const fieldDetailsAreaList = fieldGuidToDetailsMap.get(ownProps.fieldGuid).eventAreaList;
    return {
        conversionFactors: productBlendSelector.getConversionFactors(state),
        fieldDetailsAreaList,
        getModel,
        dryPriceUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRICE_UNIT_DRY)
        ),
        dryProductUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRODUCT_RATE_DRY)
        ),
        liquidPriceUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRICE_UNIT_LIQUID)
        ),
        liquidProducteUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRODUCT_RATE_LIQUID)
        ),
        seedingRate: picklistSelectors.getPicklistFromCode(state, "seedingRate"),
    };
};

export const EventPlantingSummary = (EventPlantingSummaryView) =>
    connect(mapStateToProps)(EventPlantingSummaryView);
