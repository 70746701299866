import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const AddSelectIcon = withSvgIcon(
    <g>
        <path d={"M100,100m0,-80a80,80,0,1,0,1,0Z m-1 30a50,50,0,1,1,-1,0Z"} />
        <path
            d={
                "M362.5,359.6l80-69.5L181.8,171.4L263.1,446l80-69.5L450.3,500l19.5-17L362.5,359.6z M276.5,400.1" +
                "l-53.7-181.7l172.4,78.5L276.5,400.1z"
            }
            transform="translate(-10,0)"
        />
    </g>
);
