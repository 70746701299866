import { call, put, select, takeEvery } from "redux-saga/effects";

import { Request } from "@ai360/core";
import { getTheUserGuid } from "~/login/selectors";

import { fetchDropdownData, fetchedDropdownData, fetchFailedDropdownData } from "./actions";

const _MODEL_NAME = "Name";

export function* fetchDropdown(fetchAction) {
    const payload = fetchAction.payload;
    const fetchedAction = payload.action ? payload.action : fetchedDropdownData;
    const async = typeof payload.async === "undefined" ? true : payload.async;
    const responses = {};

    const UserGuid = yield select(getTheUserGuid);

    for (let key in payload) {
        if (!Object.hasOwn(payload, key) || key === "action" || key === "async") {
            continue;
        }

        const url = typeof payload[key] === "object" ? payload[key].url : payload[key];
        const model = typeof payload[key] === "object" ? payload[key].model : _MODEL_NAME;
        const requestOptions = { UserGuid: UserGuid, Model: model };

        try {
            if (url) {
                const response = yield call(Request.post, url, requestOptions);
                if (async) {
                    yield put(fetchedAction({ [key]: response }));
                } else {
                    responses[key] = response;
                }
            } else {
                yield put(fetchFailedDropdownData("No Url"));
            }
        } catch (error) {
            console.error(
                `Failed to process action ${fetchDropdownData().type} with payload ${JSON.stringify(
                    requestOptions
                )}`,
                error
            );
            yield put(fetchFailedDropdownData(error));
        }
    }

    if (!async) {
        yield put(fetchedAction(responses));
    }
}

export function* dropDownSaga() {
    yield takeEvery(fetchDropdownData, fetchDropdown);
}
