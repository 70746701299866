import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_APPLICATION_METHOD = "applicationMethod";
export const PROPS_APPLICATION_METHOD_GUID = "applicationMethodGuid";
export const PROPS_APPLICATION_TIMING = "applicationTiming";
export const PROPS_APPLICATION_TIMING_GUID = "applicationTimingGuid";
export const PROPS_BUILD_FACTOR = "buildFactor";
export const PROPS_BUILD_FACTOR_GUID = "buildFactorGuid";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CROP_CLASS_NAME = "cropClassName";
export const PROPS_CROP_LIST = "cropList";
export const PROPS_CROP_NAME = "cropName";
export const PROPS_CROP_PURPOSE = "cropPurpose";
export const PROPS_DESCRIPTION = "description";
export const PROPS_EQUATION = "equation";
export const PROPS_EQUATION_FILTERS = "equationFilters";
export const PROPS_EQUATION_GROUP_GUID = "equationGroupGuid";
export const PROPS_EQUATION_GROUP_ID = "equationGroupId";
export const PROPS_EQUATION_GROUP_NAME = "equationGroupName";
export const PROPS_EQUATION_GROUP_TYPE = "equationGroupType";
export const PROPS_EQUATION_GUID = "equationGuid";
export const PROPS_EQUATION_ID = "equationId";
export const PROPS_EQUATION_NAME = "equationName";
export const PROPS_IMPORT_ATTRIBUTE = "importAttribute";
export const PROPS_IMPORT_ATTRIBUTE_LIST = "importAttributeList";
export const PROPS_INCORPORATION_DEPTH = "incorporationDepth";
export const PROPS_INCORPORATION_DEPTH_GUID = "incorporationDepthGuid";
export const PROPS_IS_OXIDE = "isOxide";
export const PROPS_IS_SELECTED = "isSelected";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_NEXT_CROP = "nextCrop";
export const PROPS_NEXT_CROP_GUID = "nextCropGuid";
export const PROPS_NUTRIENT_ELEMENT = "nutrientElement";
export const PROPS_NUTRIENT_GUID = "nutrientGuid";
export const PROPS_NUTRIENT_NAME = "nutrientName";
export const PROPS_PARAMETER_LIST = "parameterValues";
export const PROPS_PREVIOUS_CROP = "previousCrop";
export const PROPS_PREVIOUS_CROP_GUID = "previousCropGuid";
export const PROPS_PRODUCT = "product";
export const PROPS_PRODUCT_GUID = "productGuid";
export const PROPS_PRODUCT_LIST = "productList";
export const PROPS_REC_OPTION = "recOption";
export const PROPS_REC_OPTION_GUID = "recOptionGuid";
export const PROPS_REC_OUTPUT = "recOutput";
export const PROPS_SAMPLING_EVENT_REQUIRED = "samplingEventRequired";
export const PROPS_SOIL_TEXTURE = "soilTexture";
export const PROPS_SOIL_TEXTURE_GUID = "soilTextureGuid";
export const PROPS_TARGET_PH = "targetpH";
export const PROPS_TARGET_PH_GUID = "targetpHGuid";
export const PROPS_TILLAGE_METHOD = "tillageMethod";
export const PROPS_TILLAGE_METHOD_GUID = "tillageMethodGuid";
