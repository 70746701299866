import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as actionPanel from "~/action-panel";
import {
    importWizardSelectors,
    importWizardActions,
} from "~/action-panel/components/import-module";
import * as layerModuleActions from "~/action-panel/components/layer-module/components/layer-list/actions";
import * as layerModuleSelectors from "~/action-panel/components/layer-module/components/layer-list/selectors";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getImportWizardType } from "~/action-panel/components/import-module/components/import-wizard/selectors";
import { getTheUserGuid, getImageryTileBucketName } from "~/login";
import { actions as notificationActions } from "~/notifications";
import {
    analysisSelectors,
    eventsSelectors,
    recsSelectors,
    selectors as recsEventsSelectors,
} from "~/recs-events";
import { selectors as eventInfoSelectors } from "~/action-panel/components/event-module/components/event-info";
import * as actions from "../actions";
import { MapControl } from "../components/map-control.4x";
import * as selectors from "../selectors";
import { selectors as mapToolsSelectors } from "../../map-tools";
import { selectors as loginSelectors } from "~/login";
import * as analysisInfoSelectors from "~/action-panel/components/layer-module/components/analysis-info/selectors";
import { setToolsProcessing } from "~/map/components/map-tools/actions";

const getFieldGuidHasDataSet = (state) => {
    const eventsFieldGuidSet = eventsSelectors.getFieldGuidHasDataSet(state);
    if (eventsFieldGuidSet.size > 1) {
        return eventsFieldGuidSet;
    }
    const analysisFieldGuidSet = analysisInfoSelectors.getFieldGuidHasDataSet(state);
    if (analysisFieldGuidSet.size > 1) {
        return analysisFieldGuidSet;
    }

    return recsSelectors.getFieldGuidHasDataSet(state);
};

const mapStateToProps = (state) => ({
    activeMapTool: mapToolsSelectors.getActiveMapTool(state),
    activeModule: actionPanel.getActiveModule(state),
    activeToolset: mapToolsSelectors.getActiveToolset(state),
    basemap: selectors.getBasemap(state),
    batchFieldGuid: recsEventsSelectors.getZonesState(state).batchFieldGuid,
    cadastralLayerInfos: selectors.getCadastralLayerInfos(state),
    fieldBoundaryLayerInfo: selectors.getFieldBoundaryLayerInfo(state),
    fieldGuidToEventDetails: eventsSelectors.getModuleState(state).fieldGuidToEventDetails,
    fieldGuidToRecDetails: recsSelectors.getModuleState(state).fieldGuidToRecDetails,
    fieldGuidToAnalysisDetails: analysisSelectors.getFieldGuidToAnalysisSummaryMap(state),
    fieldGuidHasDataSet: getFieldGuidHasDataSet(state),
    fieldsBackgroundOnly: selectors.getFieldsBackgroundOnly(state),
    fieldsBackgroundOnlyBatch: selectors.getFieldsBackgroundOnlyBatch(state),
    filteredFieldGuids: cdSelectors.getFilteredFieldGuids(state),
    forceRefreshFlag: selectors.getForceRefreshFlag(state),
    imageryTileBucketName: getImageryTileBucketName(state),
    importWizardType: getImportWizardType(state),
    isInBatchWorkflow: recsEventsSelectors.isInBatchWorkflow(state),
    isLoading: selectors.getIsLoading(state),
    invalidatedFieldGuids: selectors.getInvalidatedFields(state),
    latestUpdatedEvent: eventsSelectors.getModuleState(state).latestUpdatedEvent,
    visibleNonFieldFeatures: cdSelectors.getVisibleNonFieldFeatures(state),
    nonFieldFeaturesCanShowTooltip: selectors.getNonFieldFeaturesCanShowTooltip(state),
    nonFieldFeatureLayerIsVisible: selectors.getNonFieldFeatureLayerIsVisible(state),
    highlightedNonFieldFeatures: cdSelectors.getHighlightedNonFieldFeatures(state),
    scale: selectors.getScale(state),
    selectedFieldGuids: cdSelectors.getSelectedFieldGuids(state),
    toolsetPayload: mapToolsSelectors.getToolsetPayload(state),
    userGuid: getTheUserGuid(state),
    userRole: loginSelectors.getUser(state).role,
    visibleSampleSites: layerModuleSelectors.getVisibleSampleSitesMap(state),
    visibleSurfaces: layerModuleSelectors.getVisibleSurfacesMap(state),
    zoom: selectors.getZoomLevel(state),
    zoomToCustomerGuid: selectors.getZoomToCustomer(state),
    zoomToFarmName: selectors.getZoomToFarm(state),
    zoomToFieldGuid: selectors.getZoomToField(state),
    zoomToFieldGuidList: selectors.getZoomToFieldList(state),
    zoomToNonFieldFeatures: selectors.getZoomToNonFieldFeatures(state),
    importData: {
        convexHulls: importWizardSelectors.getImportConvexHulls(state),
        filteredFieldImportFileList: importWizardSelectors.getFilteredFieldImportFileList(state),
        forceUpdate: importWizardSelectors.getForceUpdate(state),
        ignoreFarm: importWizardSelectors.getIgnoreFarm(state),
        ignoreSelectedField: importWizardSelectors.getIgnoreSelectedField(state),
        importFieldList: importWizardSelectors.getImportFieldList(state),
        importFileGuidList: importWizardSelectors.getImportFileGuidList(state),
        importMatchedFieldGuidList: importWizardSelectors.getImportMatchedFieldGuidList(state),
        importSamplingPoints: importWizardSelectors.getImportSamplingPoints(state),
        isFieldMatchingLoading: importWizardSelectors.getIsFieldMatchingLoading(state),
        isFilteringVisible: importWizardSelectors.getIsFilteringVisible(state),
        selectedImportFieldIndex: importWizardSelectors.getSelectedImportFieldIndex(state),
        selectedMatchedFieldGuid: importWizardSelectors.getSelectedMatchedFieldGuid(state),
        showImportPoints: importWizardSelectors.getShowImportPoints(state),
        statFilterOn: importWizardSelectors.getIsStatFilterOn(state),
        yieldFilterOn: importWizardSelectors.getIsYieldFilterOn(state),
    },
    filterData: eventInfoSelectors.getModuleState(state).eventFilterData,
});

const mapDispatchToProps = (dispatch) => ({
    clearInvalidatedFields: () => dispatch(actions.setFieldsToInvalidate([])),
    onClearForceRefresh: () => dispatch(actions.setForceRefreshFlag(false)),
    onClearForceUpdate: () => dispatch(importWizardActions.setForceUpdate(false)),
    onClearFieldSelection: (fieldGuids) => dispatch(cdActions.clearSelectedFields(fieldGuids)),
    onClearZoomToCustomer: () => dispatch(actions.setZoomToCustomer(null)),
    onClearZoomToFarm: () => dispatch(actions.setZoomToFarm(null, null)),
    onClearZoomToField: () => dispatch(actions.setZoomToField(null)),
    onClearZoomToFieldList: () => dispatch(actions.setZoomToFieldList(null)),
    onClearZoomToNonFieldFeatures: () => dispatch(actions.setZoomToNonFieldFeatures(null)),
    onFetchLayerPreferences: (userGuid) => dispatch(actions.fetchLayerPreferences(userGuid)),
    fetchNonFieldFeaturesForUser: () => dispatch(cdActions.fetchNonFieldFeaturesForUser()),
    onMapReady: () => dispatch(actions.setMapReady()),
    onPushToasterMessage: (message, type) =>
        dispatch(notificationActions.pushToasterMessage(message, type)),
    onSelectImportFieldBoundary: (index) =>
        dispatch(importWizardActions.setSelectedImportFieldIndex(index)),
    onSelectFields: (fieldGuids) => dispatch(cdActions.addSelectedFields(fieldGuids)),
    onSetBasemap: (basemap) => dispatch(actions.setBasemap(basemap)),
    onSetImportFieldBoundaries: (ifbList) =>
        dispatch(importWizardActions.setImportFieldBoundaries(ifbList)),
    onSetIsLoading: (isLoading, force) => dispatch(actions.setIsLoading(isLoading, force)),
    onSetIsCanvasLoading: (isCanvasLoading) =>
        dispatch(actions.setIsCanvasLoading(isCanvasLoading)),
    onSetSelectedMatchedFieldGuid: (fieldGuid) =>
        dispatch(importWizardActions.setSelectedMatchedFieldGuid(fieldGuid)),
    onSetToolsProcessing: (isProcessing) => dispatch(setToolsProcessing(isProcessing)),
    onSetZoomAndScale: (zoom, scale) => dispatch(actions.setZoomAndScale(zoom, scale)),
    onUpdateLoadingSampleSites: (agEventGeneralGuid, isLoading) =>
        dispatch(layerModuleActions.updateLoadingSampleSitesSet(agEventGeneralGuid, isLoading)),
    onUpdateLoadingSurfaces: (surfaceGuid, isLoading) =>
        dispatch(layerModuleActions.updateLoadingSurfacesSet(surfaceGuid, isLoading)),
    setFilteredPointCount: (pointCount) => dispatch(actions.setFilteredPointCount(pointCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MapControl));
