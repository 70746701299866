import { ProductMix } from "~/action-panel/components/common/product-blend-modal/model";
import { AgEventAPI } from "@ai360/core";
import { IAgEventModelMethods } from "../model";

export class AgEventApplication implements AgEventAPI.IAgEventApplication, IAgEventModelMethods {
    agEventGuid = "";
    eventAreaGuid = "";
    applicationMethodGuid = "";
    applicationTimingGuid = "";
    coverageArea = null;
    gridArea = null;
    productMixList: ProductMix[] = [];

    get isAllRequiredFieldsSet(): boolean {
        return this.productMixList != null && this.productMixList.length > 0;
    }

    get isAnyRequiredFieldSet(): boolean {
        return this.isAllRequiredFieldsSet;
    }

    public updateAgEventModel(newProps: Partial<AgEventApplication>): any {
        return Object.assign(new AgEventApplication(), this, newProps);
    }
}
