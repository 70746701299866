import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import { config as intlConfig } from "~/intl-provider/config";
import "./legend-display.css";
import { ILegendClassBreak, ILegendClassBreakColor } from "@ai360/core";

interface ILegendDisplayProps {
    classBreak: ILegendClassBreak;
    intl: intlShape;
}

interface ILegendDisplayState {
    hover: boolean;
}

class LegendDisplay_ extends Component<ILegendDisplayProps, ILegendDisplayState> {
    label: HTMLDivElement;
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
    }

    _onMouseEnter() {
        if (this.label) {
            const clientRect = this.label.getBoundingClientRect();
            this.label.style.top = `${clientRect.top}px`;
            this.setState({ hover: true });
        }
    }

    _onMouseLeave() {
        if (this.label) {
            this.label.style.top = undefined;
            this.setState({ hover: false });
        }
    }

    render() {
        const { classBreak, intl } = this.props;
        const { acreage, color, displayName } = classBreak;
        const { formatNumber } = intl;
        const { hover } = this.state;

        let acreageDisplay = formatNumber(acreage, intlConfig.numberFormatOptions);
        if (acreage == 0.01) {
            acreageDisplay = "< 0.01";
        }
        const label = `${displayName} (${acreageDisplay} ac)`;
        return [
            <div
                key="icon"
                className="legend-icon"
                style={{ backgroundColor: `#${(color as ILegendClassBreakColor).hexCode}` }}
            />,
            <div
                key="label"
                className={classnames("legend-label", {
                    "legend-label-hover": hover,
                })}
                onMouseEnter={() => this._onMouseEnter()}
                onMouseLeave={() => this._onMouseLeave()}
                ref={(lbl) => (this.label = lbl)}
            >
                {label}
            </div>,
            hover ? null : <div key="filler" className="legend-filler" />,
        ];
    }
}

export const LegendDisplay = injectIntl(LegendDisplay_);
