import { call, put, select } from "redux-saga/effects";
import { defaultActions } from "./../actions";
import { Request, APIError } from "@ai360/core";
import { setApiResult } from "~/core/api/actions";
import { actions as notificationActions } from "~/notifications";
// selectors
import { getTheUserGuid } from "~/login";

export function* update(action = {}, { urls, useUpdatePut, useNewApi }) {
    if (useNewApi) {
        try {
            yield call(Request.put, `${urls.CREATE}/${action.payload.id}`, action.payload);
            yield put(defaultActions.success());
        } catch (error) {
            if (error instanceof APIError) {
                if (typeof error.apiResultObj.status !== "undefined") {
                    yield put(setApiResult({ newApiResults: error.apiResultObj }));
                } else {
                    yield put(setApiResult(error));
                }
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(defaultActions.failed());
        }
    } else {
        try {
            const UserGuid = yield select(getTheUserGuid);
            const requestOptions = {
                UserGuid: UserGuid,
                Model: action.payload,
            };
            useUpdatePut
                ? yield call(Request.put, urls.UPDATE, requestOptions)
                : yield call(Request.post, urls.UPDATE, requestOptions);
            yield put(defaultActions.success());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(defaultActions.failed());
        }
    }
}
