import { SET_BREAD_CRUMBS } from "./actions";
export const BREADCRUMBS = "breadcrumbs";

const initialState = {};

export const SLIDING_PANEL_STATE_KEY = "SLIDING_PANEL_STATE";

export const slidingPanelReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_BREAD_CRUMBS:
            return {
                ...state,
                [BREADCRUMBS]: payload,
            };
        default:
            return state;
    }
};
