import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_DESCRIPTION = "description";
export const PROPS_GROWING_DEGREE_DAYS_HIGH = "growingDegreeDaysHigh";
export const PROPS_GROWING_DEGREE_DAYS_LOW = "growingDegreeDaysLow";
export const PROPS_GROWTH_STAGE_ID = "growthStageId";
export const PROPS_GROWTH_STAGE_NAME = "growthStageName";
export const PROPS_GROWTH_STAGE_NOTES = "notes";
export const PROPS_GROWTH_STAGE_ORDER = "stageOrder";
export const PROPS_GROWTH_STAGE_ORDER_GUID = "growthStageOrderGuid";
export const PROPS_GROWTH_STAGE_ORDER_NAME = "name";
export const PROPS_IS_ACTIVE = adminData.PROPS_IS_ACTIVE;
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
