import { call, put, select, takeLatest } from "redux-saga/effects";

import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";

import { PicklistAPI } from "@ai360/core";

import * as actions from "./actions";

const parseTillageDepthPicklist = (picklistValues) => {
    return picklistValues.map((val) => ({
        label: val.abbreviation,
        value: val.importAttributeGuid,
    }));
};

const onFetchTillageFormPicklists = function* (action) {
    const userGuid = yield select(getTheUserGuid);
    let tillageDepthUnitOptions;
    try {
        const picklistValues = yield call(
            PicklistAPI.getUnitPicklistBySystemAttributeAndEventType,
            userGuid,
            "Depth",
            "Tillage"
        );
        tillageDepthUnitOptions = parseTillageDepthPicklist(picklistValues);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
    yield put(actions.updateEventInfoPicklists({ tillageDepthUnitOptions }));
};

export const tillageSaga = function* () {
    yield takeLatest(actions.FETCH_TILLAGE_FORM_PICKLISTS, onFetchTillageFormPicklists);
};
