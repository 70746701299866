import { connect } from "react-redux";
import BinTankListView from "./bin-tank-list";
import { getUnit, getPicklist } from "~/admin/selectors";
import * as unitService from "~/core/units/unit-names";
import * as picklistService from "~/core/picklist/picklist-names";

const mapStateToProps = (state) => ({
    [picklistService.PICKLIST_PHYSICAL_STATE]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_PHYSICAL_STATE),
        state
    ),
    [unitService.UNIT_VOLUME]: getUnit(unitService.getUnitCode(unitService.UNIT_VOLUME), state),
});

export const BinTankList = connect(mapStateToProps, null)(BinTankListView);
