import React, { Component, ReactNode, MouseEvent as ReactMouseEvent } from "react";
import classnames from "classnames";

export interface IDialogToolProps {
    className?: string;
    onClick: (e: ReactMouseEvent) => void;
    children?: ReactNode[] | ReactNode;
}
export class DialogTool extends Component<IDialogToolProps> {
    render(): JSX.Element {
        const { className, children, onClick } = this.props;
        return (
            <span
                className={classnames("dialog-tool-icon", className)}
                onClick={(e: ReactMouseEvent) => onClick(e)}
            >
                {children}
            </span>
        );
    }
}
