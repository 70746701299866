import { connect } from "react-redux";
import { apiUrl } from "@ai360/core";

import { requestSearchString, fetchEntities } from "./../actions";
import { getUsers, getFilteredUsers } from "./../selectors";

const autoSearchUrl = apiUrl("SetupZapper/GetUsersAutoCompleteList");
const autoSearchModelName = "filteredUsers";

const entitiesUrl = apiUrl("SetupZapper/GetUsers");
const entitiesModelName = "users";

const mapDispatchToProps = (dispatch) => ({
    // This action is dispatched using needs hoc
    getEntities: (data) => fetchEntities({ data, url: entitiesUrl, modelName: entitiesModelName }),
    searchEntity: (searchString, companyUserGuid) =>
        dispatch(
            requestSearchString({
                searchString,
                companyUserGuid,
                url: autoSearchUrl,
                modelName: autoSearchModelName,
            })
        ),
});

const mapStateToProps = (state) => ({
    entities: getUsers(state),
    filteredData: getFilteredUsers(state),
});

const WithUserEntity = (View) => {
    return connect(mapStateToProps, mapDispatchToProps)(View);
};

export default WithUserEntity;
