import { createAction } from "redux-act";
const GET_IMPORT_CONTROLLER_TEMPLATE = "GET_IMPORT_CONTROLLER_TEMPLATE_DATA";
const GET_IMPORT_CONTROLLER_TEMPLATE_FAILED = "GET_IMPORT_CONTROLLER_TEMPLATE_FAILED";
const GET_IMPORT_CONTROLLER_TEMPLATE_SUCCESS = "GET_IMPORT_CONTROLLER_TEMPLATE_SUCCESS";
const GET_IMPORT_TEMPLATE = "GET_IMPORT_TEMPLATE_DATA";
const GET_IMPORT_TEMPLATE_FAILED = "GET_IMPORT_TEMPLATE_FAILED";
const GET_IMPORT_TEMPLATE_SUCCESS = "GET_IMPORT_TEMPLATE_SUCCESS";
const GET_TEMPLATE_GRID_DATA = "GET_TEMPLATE_GRID_DATA";
const GET_TEMPLATE_GRID_DATA_FAILED = "GET_TEMPLATE_GRID_FAILED";
const GET_TEMPLATE_GRID_DATA_SUCCESS = "GET_TEMPLATE_GRID_SUCCESS";
const RESET_IMPORT_TEMPLATE_LIST_DATA = "RESET_IMPORT_TEMPLATE_LIST_DATA";
const RESET_TEMPLATE = "RESET_TEMPLATE";
const SET_CURRENT_TAB = "SET_CURRENT_TAB";
const SET_FILE_METADATA = "SET_FILE_METADATA";
const SET_IS_MONITOR_FILE_FLAG = "SET_IS_MONITOR_FILE_FLAG";
const SET_TEMPLATE_GRID_DATA = "SET_TEMPLATE_GRID_DATA";
const UPDATE_FILE_TYPE = "UPDATE_FILE_TYPE";
const UPDATE_IMPORT_ATTRIBUTES = "UPDATE_IMPORT_ATTRIBUTES";
const UPDATE_MANUAL_IMPORT_ATTRIBUTES = "UPDATE_MANUAL_IMPORT_ATTRIBUTES";
const UPDATE_IMPORT_TEMPLATE_LIST = "UPDATE_IMPORT_TEMPLATE_LIST";
const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
const VERIFY_TEMPLATE_FILE_DATA = "VERIFY_TEMPLATE_FILE_DATA";
const VERIFY_TEMPLATE_FILE_FAILED = "VERIFY_TEMPLATE_FILE_FAILED";
const VERIFY_TEMPLATE_FILE_SUCCESS = "VERIFY_TEMPLATE_FILE_SUCCESS";

export const getImportControllerTemplate = createAction(GET_IMPORT_CONTROLLER_TEMPLATE);
export const getImportControllerTemplateFailed = createAction(
    GET_IMPORT_CONTROLLER_TEMPLATE_FAILED
);
export const getImportControllerTemplateSuccess = createAction(
    GET_IMPORT_CONTROLLER_TEMPLATE_SUCCESS
);
export const getImportTemplate = createAction(GET_IMPORT_TEMPLATE);
export const getImportTemplateFailed = createAction(GET_IMPORT_TEMPLATE_FAILED);
export const getImportTemplateSuccess = createAction(GET_IMPORT_TEMPLATE_SUCCESS);
export const getTemplateGridData = createAction(GET_TEMPLATE_GRID_DATA);
export const getTemplateGridFailed = createAction(GET_TEMPLATE_GRID_DATA_FAILED);
export const getTemplateGridSuccess = createAction(GET_TEMPLATE_GRID_DATA_SUCCESS);
export const resetImportTemplateListData = createAction(RESET_IMPORT_TEMPLATE_LIST_DATA);
export const resetTemplate = createAction(RESET_TEMPLATE);
export const setCurrentTab = createAction(SET_CURRENT_TAB);
export const setFileMetadata = createAction(SET_FILE_METADATA);
export const setIsMonitorFileFlag = createAction(SET_IS_MONITOR_FILE_FLAG);
export const setTemplateGridData = createAction(SET_TEMPLATE_GRID_DATA);
export const updateFileType = createAction(UPDATE_FILE_TYPE);
export const updateImportAttributes = createAction(UPDATE_IMPORT_ATTRIBUTES);
export const updateManualImportAttributes = createAction(UPDATE_MANUAL_IMPORT_ATTRIBUTES);
export const updateImportTemplateList = createAction(UPDATE_IMPORT_TEMPLATE_LIST);
export const updateTemplate = createAction(UPDATE_TEMPLATE);
export const verifyTemplateFile = createAction(VERIFY_TEMPLATE_FILE_DATA);
export const verifyTemplateFileFailed = createAction(VERIFY_TEMPLATE_FILE_FAILED);
export const verifyTemplateFileSuccess = createAction(VERIFY_TEMPLATE_FILE_SUCCESS);
