import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import { PersonAPI } from "@ai360/core";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
import {
    PICKLIST_JOB_TITLE,
    PICKLIST_PHONE_TYPE,
    PICKLIST_PAY_TYPE,
    PICKLIST_LICENSE_TYPE,
    PICKLIST_USER_TYPE,
} from "~/core/picklist/picklist-names";
import { UNIT_PAY_RATE } from "~/core/units/unit-names";
import { messages } from "../../i18n-messages";
import _ from "lodash";

// Components
import {
    TextInput,
    SelectInput,
    Checkbox,
    Section,
    SubSection,
    RadioButtonGroup,
    RadioButton,
    NoLink,
    DialogBox,
    DialogBoxFooterType,
} from "~/core";
import { ABBREVIATION, adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE, ADD } from "~/admin/data";
import { messages as globalMessages } from "~/i18n-messages";
import Address from "../../component/address/address";
import { AutoReportList } from "../../component/report/auto-report-list";
import Email from "../../component/email/email";
import Phone from "../../component/phone/phone";
import License from "../../component/license/license";
import EmployeeInfo from "../../component/employee-info";
import SalesPersonId from "../../component/salesperson-id";
import { CustomerAffiliations } from "../../component/customer-affiliations";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import UserRoleMatrix from "../../component/user-role-matrix";
import { OrgLevelList, PROPS_STATE_ABBREVIATION } from "~/admin/agBytes/components/org-level-list";

// Style
import "./add-edit-panel.css";
import "../../add-edit-shared-responsive.css";

const CUSTOMER_ID = "2";
const SALESPERSON_ID = "4";
const STANDARD_ID = "1";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.array,
        companyGuid: PropTypes.string,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        personAddMode: PropTypes.bool,
        personGuid: PropTypes.string,
        record: CustomPropTypes.person,
        recordGuid: PropTypes.string,
        resetPassword: PropTypes.func,
        searchValue: PropTypes.string,
        userGuid: PropTypes.string,
        userRoleList: PropTypes.array,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    static ADDRESS_TYPE_GUID = "addressTypeGuid";
    static COUNTRY_GUID = "countryGuid";
    static STATE_GUID = "stateGuid";
    static USER_ROLE_GUID = "userRoleGuid";

    constructor(props) {
        super(props);
        this.user = {};
        this.state = {
            [PICKLIST_JOB_TITLE]: [],
            [PICKLIST_LICENSE_TYPE]: [],
            [PICKLIST_PHONE_TYPE]: [],
            [PICKLIST_USER_TYPE]: [],
            [UNIT_PAY_RATE]: [],
            [model.PROPS_ACTIVE_YN]: false,
            [model.PROPS_ADDRESS_TYPE_LIST]: [],
            [model.PROPS_COUNTRIES_LIST]: [],
            [model.PROPS_EMAIL_LIST]: [],
            [model.PROPS_LICENSE_LIST]: [],
            [model.PROPS_ORG_LEVEL_LIST]: [],
            [model.PROPS_PERSON_ADDRESS_LIST]: [],
            [model.PROPS_PERSON_AUTO_REPORT_LIST]: [],
            [model.PROPS_PERSON_ORG_LIST]: [],
            [model.PROPS_PHONE_NUMBER_LIST]: [],
            [model.PROPS_TIME_ZONE_LIST]: [],
            [model.PROPS_USER_ACTIVEYN]: false,
            [model.PROPS_USER_CUSTOMER_LIST]: [],
            [model.PROPS_USER_ROLE_LIST]: [],
            matrixView: false,
            resetOptions: false,
            selectedUserType: null,
            selectedUserTypeID: STANDARD_ID,
            showIsActiveDialog: false,
            showOrgDeleteDialog: false,
            showUserTypeChangeDialog: false,
        };
        this.orgDeleteConfirmation = null;
        this.orgleveRecords = null;
        this.userTypeValue = null;
    }

    componentDidMount() {
        const { needs, addEditPanel, searchValue } = this.props;
        this.user = service.getDefaultRecord();
        addEditPanel.mode === ADD
            ? this.props.setBreadcrumbs([searchValue])
            : this.props.setBreadcrumbs([""]);
        needs([
            this.props.fetchUnitData(),
            this.props.fetchPicklistData(),
            this.props.fetchDropdownData(),
            this.props.fetchDropdownData({
                [model.PROPS_USER_ROLE_LIST]: {
                    url: PersonAPI.GET_USER_ROLES,
                    model: this.props.companyGuid,
                },
            }),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.user);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            if (nextProps.nextId) {
                this.setState({
                    nextId: nextProps.nextId,
                });
            }
            if (this.state.selectedUserType == null) {
                this.onRadioButtonChange(
                    this.getGuidOrID({
                        options: this.state[PICKLIST_USER_TYPE],
                        id: STANDARD_ID,
                    })
                );
            }
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.user = { ...this.user, ...nextProps.record };
                // On edit, if there is no value removing the following key to avoid error 665
                if (!this.user[model.PROPS_PAY_RATE_UNIT_GUID]) {
                    delete this.user[model.PROPS_PAY_RATE_UNIT_GUID];
                }
                // Person to User add, addUserAccountVisible should be true
                this.user[model.PROPS_ADD_USER_ACCOUNT_VISIBLE] = true;
                this.user[model.PROPS_USER_TYPE_GUID] =
                    nextProps.record[model.PROPS_USER_TYPE_GUID];
                this.setState({
                    [model.PROPS_PERSON_ADDRESS_LIST]:
                        nextProps.record[model.PROPS_PERSON_ADDRESS_LIST],
                    [model.PROPS_EMAIL_LIST]: nextProps.record[model.PROPS_EMAIL_LIST],
                    [model.PROPS_PHONE_NUMBER_LIST]:
                        nextProps.record[model.PROPS_PHONE_NUMBER_LIST],
                    [model.PROPS_LICENSE_LIST]: nextProps.record[model.PROPS_LICENSE_LIST],
                    [model.PROPS_PERSON_ORG_LIST]: nextProps.record[model.PROPS_PERSON_ORG_LIST],
                    [model.PROPS_USER_CUSTOMER_LIST]:
                        nextProps.record[model.PROPS_USER_CUSTOMER_LIST],
                    selectedUserType: nextProps.record[model.PROPS_USER_TYPE_GUID],
                    [model.PROPS_ACTIVE_YN]: nextProps.record[model.PROPS_ACTIVE_YN],
                    [model.PROPS_USER_ACTIVEYN]: nextProps.record[model.PROPS_USER_ACTIVEYN],
                    [model.PROPS_PERSON_AUTO_REPORT_LIST]:
                        nextProps.record[model.PROPS_PERSON_AUTO_REPORT_LIST],
                    selectedUserTypeID: nextProps.record[model.PROPS_USER_TYPE_GUID]
                        ? this.getGuidOrID({
                              options: this.state[PICKLIST_USER_TYPE],
                              guid: nextProps.record[model.PROPS_USER_TYPE_GUID],
                          })
                        : STANDARD_ID,
                });
                if (this.props.personAddMode) {
                    nextProps.record[model.PROPS_EMAIL_LIST].forEach((email) => {
                        if (email[model.PROPS_IS_PRIMARY]) {
                            this.user[model.PROPS_LOGIN_EMAIL] =
                                email[model.PROPS_EMAIL][model.PROPS_EMAIL_ADDRESS];
                        }
                    });
                }
                if (this.state.selectedUserType == null) {
                    this.onRadioButtonChange(nextProps.record[model.PROPS_USER_TYPE_GUID]);
                }
            }
        }
        this.initializeDropdowns(nextProps);
    }

    initializeDropdowns = (nextProps) => {
        this.initializeUserType(nextProps);
        this.initializeJobTitle(nextProps);
        this.initializeAddressType(nextProps);
        this.initializeCountryList(nextProps);
        this.initializeStateList(nextProps);
        this.initializePhone(nextProps);
        this.initializePayType(nextProps);
        this.initializePayRate(nextProps);
        this.initializeLicense(nextProps);
        this.initializeUserRoles(nextProps);
        this.initializeTimeZone(nextProps);
        this.initializeOrgLevel(nextProps);
    };

    initializeJobTitle = (nextProps) => {
        if (nextProps[PICKLIST_JOB_TITLE] !== this.state[PICKLIST_JOB_TITLE]) {
            this.setState({
                [PICKLIST_JOB_TITLE]: prepareSelectableOptions(nextProps[PICKLIST_JOB_TITLE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    initializeAddressType = (nextProps) => {
        if (
            nextProps[model.PROPS_ADDRESS_TYPE_LIST] !== this.state[model.PROPS_ADDRESS_TYPE_LIST]
        ) {
            this.setState({
                [model.PROPS_ADDRESS_TYPE_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_ADDRESS_TYPE_LIST],
                    { guid: AddEditPanel.ADDRESS_TYPE_GUID, label: NAME }
                ),
            });
        }
    };

    initializeCountryList = (nextProps) => {
        if (nextProps[model.PROPS_COUNTRIES_LIST] !== this.state[model.PROPS_COUNTRIES_LIST]) {
            this.setState({
                [model.PROPS_COUNTRIES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_COUNTRIES_LIST],
                    { guid: AddEditPanel.COUNTRY_GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeStateList = (nextProps) => {
        if (nextProps[model.PROPS_STATES_LIST] !== this.state[model.PROPS_STATES_LIST]) {
            this.setState({
                [model.PROPS_STATES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_STATES_LIST],
                    { guid: AddEditPanel.STATE_GUID, label: ABBREVIATION }
                ),
            });
        }
    };

    initializePhone = (nextProps) => {
        if (nextProps[PICKLIST_PHONE_TYPE] !== this.state[PICKLIST_PHONE_TYPE]) {
            this.setState({
                [PICKLIST_PHONE_TYPE]: prepareSelectableOptions(nextProps[PICKLIST_PHONE_TYPE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    initializePayType = (nextProps) => {
        if (nextProps[PICKLIST_PAY_TYPE] !== this.state[PICKLIST_PAY_TYPE]) {
            this.setState({
                [PICKLIST_PAY_TYPE]: prepareSelectableOptions(nextProps[PICKLIST_PAY_TYPE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    initializePayRate = (nextProps) => {
        if (nextProps[UNIT_PAY_RATE] !== this.state[UNIT_PAY_RATE]) {
            this.setState({
                [UNIT_PAY_RATE]: prepareSelectableOptions(nextProps[UNIT_PAY_RATE], {
                    guid: GUID,
                    label: NAME,
                }),
            });
        }
    };

    initializeLicense = (nextProps) => {
        if (nextProps[PICKLIST_LICENSE_TYPE] !== this.state[PICKLIST_LICENSE_TYPE]) {
            this.setState({
                [PICKLIST_LICENSE_TYPE]: prepareSelectableOptions(
                    nextProps[PICKLIST_LICENSE_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    initializeUserRoles = (nextProps) => {
        const { userRole, personAddMode, record } = nextProps;
        if (nextProps[model.PROPS_USER_ROLE_LIST] !== this.state[model.PROPS_USER_ROLE_LIST]) {
            let accessLevel;
            if (!personAddMode && record) {
                accessLevel =
                    record[model.PROPS_USER_GUID] === nextProps[model.PROPS_USER_GUID]
                        ? Array.isArray(nextProps[model.PROPS_USER_ROLE_LIST]) &&
                          nextProps[model.PROPS_USER_ROLE_LIST].find(
                              (role) => role.userRoleGuid === record.userRoleGuid
                          ).accessLevel
                        : userRole.accessLevel;
            } else {
                accessLevel = userRole.accessLevel;
            }
            this.setState({
                [model.PROPS_USER_ROLE_LIST]: prepareSelectableOptions(
                    Array.isArray(nextProps[model.PROPS_USER_ROLE_LIST])
                        ? nextProps[model.PROPS_USER_ROLE_LIST].filter(
                              (role) => role.accessLevel <= accessLevel
                          )
                        : nextProps[model.PROPS_USER_ROLE_LIST],
                    { guid: AddEditPanel.USER_ROLE_GUID, label: NAME }
                ),
            });
        }
    };

    initializeTimeZone = (nextProps) => {
        if (nextProps[model.PROPS_TIME_ZONE_LIST] !== this.state[model.PROPS_TIME_ZONE_LIST]) {
            this.setState(
                {
                    [model.PROPS_TIME_ZONE_LIST]: prepareSelectableOptions(
                        nextProps[model.PROPS_TIME_ZONE_LIST],
                        { guid: PICKLIST_GUID, label: VALUE }
                    ),
                },
                () => {
                    if (!this.user[model.PROPS_TIME_ZONE_GUID]) {
                        this.user[model.PROPS_TIME_ZONE_GUID] = this.getGuidOrID({
                            options: this.props[model.PROPS_TIME_ZONE_LIST],
                            id: "10",
                        });
                    }
                }
            );
        }
    };

    initializeUserType = (nextProps) => {
        if (nextProps[PICKLIST_USER_TYPE] !== this.state[PICKLIST_USER_TYPE]) {
            this.setState(
                {
                    [PICKLIST_USER_TYPE]: _.sortBy(
                        nextProps[PICKLIST_USER_TYPE],
                        (userType) => userType.id
                    ),
                },
                () => {
                    if (
                        nextProps.addEditPanel.mode === ADD ||
                        !this.user[model.PROPS_USER_TYPE_GUID]
                    ) {
                        this.user[model.PROPS_USER_TYPE_GUID] =
                            this.state.selectedUserType ||
                            this.getGuidOrID({
                                options: this.state[PICKLIST_USER_TYPE],
                                id: STANDARD_ID,
                            });
                    }
                }
            );
        }
    };

    initializeOrgLevel = (nextProps) => {
        if (nextProps[model.PROPS_ORG_LEVEL_LIST] !== this.state[model.PROPS_ORG_LEVEL]) {
            this.setState({
                [model.PROPS_ORG_LEVEL_LIST]: nextProps[model.PROPS_ORG_LEVEL_LIST],
            });
        }
    };

    disableUserRolesList = (personUserRoleGuid = "") => {
        const { personAddMode, userRole, userRoleList } = this.props;
        let IsDisabled = false;
        if (!personAddMode && userRoleList && personUserRoleGuid) {
            const personUserRoleDetails =
                Array.isArray(userRoleList) &&
                userRoleList.filter((role) => role.userRoleGuid === personUserRoleGuid)[0];
            if (personUserRoleDetails.accessLevel > userRole.accessLevel) {
                IsDisabled = true;
            }
        }
        return IsDisabled;
    };

    onChildComponentChange = (childKey, value) => {
        this.user[childKey] = value;
        if (childKey === model.PROPS_EMAIL_LIST) {
            this.user[model.PROPS_ADD_EMAIL_VISIBLE] = value.length ? true : false;
        }
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.user = handlePicklistChange(this.user, { type, guid, value }, callback);
    };

    onTextChange = (formKey, value, callback) => {
        this.user = onTextChange(this.user, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        const { searchValue } = this.props;
        const firstName = this.user[model.PROPS_USER_FIRST_NAME] || searchValue || "";
        const lastName = this.user[model.PROPS_USER_LAST_NAME] || "";
        this.props.setBreadcrumbs([`${firstName} ${lastName}`]);
    };

    mapSelectedCountry = ({ options = [], guid }) => {
        if (guid && options.length) {
            return mapToPicklistValue({ options, selectedGuid: guid });
        }
        return null;
    };

    onRadioButtonChange = (value) => {
        this.onTextChange(model.PROPS_USER_TYPE_GUID, value);
        this.setState({
            selectedUserType: value,
            selectedUserTypeID: value
                ? this.getGuidOrID({
                      options: this.state[PICKLIST_USER_TYPE],
                      guid: value,
                  })
                : STANDARD_ID,
        });
    };

    getGuidOrID = ({ options = [], id, guid }) => {
        if (options.length) {
            if (id) {
                const selectedOption = options.filter((option) => {
                    return id === option.id;
                });
                const guid = selectedOption.length && selectedOption[0][PICKLIST_GUID];
                return guid;
            } else if (guid) {
                const selectedOption = options.filter((option) => {
                    return guid === option[PICKLIST_GUID];
                });
                const { id } = selectedOption.length && selectedOption[0];
                return id;
            }
        }
        return null;
    };

    onResetPassword = () => {
        this.props.needs([
            this.props.resetPassword({
                email: this.user[model.PROPS_LOGIN_EMAIL],
                successMessage: globalMessages.operationSuccessful,
            }),
        ]);
    };

    initialRenderCheck = (items) => {
        if (this.user[model.PROPS_LOGIN_EMAIL] && !this.user[model.PROPS_EMAIL_LIST].length) {
            items[0][model.PROPS_EMAIL][model.PROPS_EMAIL_ADDRESS] =
                this.user[model.PROPS_LOGIN_EMAIL];
            return items;
        }
        return items;
    };

    setActiveInactiveUserState = (value) => {
        this.setState({
            [model.PROPS_USER_ACTIVEYN]: value,
        });
    };

    onDeleteConfirmation = (options, cb) => {
        const { selectedUserTypeID } = this.state;
        if (
            this.state[model.PROPS_USER_CUSTOMER_LIST].length &&
            (selectedUserTypeID === SALESPERSON_ID || selectedUserTypeID === CUSTOMER_ID)
        ) {
            this.toggleOrgDeleteDialog(true);
            this.orgDeleteConfirmation = cb;
            this.orgleveRecords = options;
        } else {
            cb(options);
        }
    };

    onPersonInActive = () => {
        this.showIsActiveDialog(false);
        this.onTextChange(model.PROPS_ACTIVE_YN, false);
        this.onTextChange(model.PROPS_USER_ACTIVEYN, false);
        this.setActiveInactiveUserState(false);
    };

    toggleOrgDeleteDialog = (show) => this.setState({ showOrgDeleteDialog: show });

    toggleUserTypeChangeDialog = (show) => this.setState({ showUserTypeChangeDialog: show });

    showIsActiveDialog = (showIsActiveDialog) => this.setState({ showIsActiveDialog });

    getOrgDeleteDialog() {
        const { formatMessage } = this.props.intl;
        const { showOrgDeleteDialog } = this.state;
        return (
            <DialogBox
                draggable={true}
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={showOrgDeleteDialog}
                onAction={() => {
                    this.orgDeleteConfirmation(this.orgleveRecords, false);
                    this.onTextChange(model.PROPS_USER_CUSTOMER_LIST, [], () =>
                        this.setState({ [model.PROPS_USER_CUSTOMER_LIST]: [] })
                    );
                    this.toggleOrgDeleteDialog(false);
                    this.orgDeleteConfirmation = null;
                    this.orgleveRecords = null;
                }}
                onClose={() => {
                    this.toggleOrgDeleteDialog(false);
                    this.orgDeleteConfirmation = null;
                    this.orgleveRecords = null;
                }}
                title={formatMessage(messages.deleteLocation)}
            >
                {formatMessage(messages.deleteLocationDlgMsg)}
            </DialogBox>
        );
    }

    getUserTypeChangeDialog() {
        const { formatMessage } = this.props.intl;
        const { selectedUserTypeID, showUserTypeChangeDialog } = this.state;
        return (
            <DialogBox
                draggable={true}
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={showUserTypeChangeDialog}
                onAction={() => {
                    this.onRadioButtonChange(this.userTypeValue);
                    const userTypeValueId = this.getGuidOrID({
                        options: this.state[PICKLIST_USER_TYPE],
                        guid: this.userTypeValue,
                    });
                    if (userTypeValueId !== CUSTOMER_ID) {
                        this.onTextChange(model.PROPS_USER_CUSTOMER_LIST, [], () =>
                            this.setState({
                                [model.PROPS_USER_CUSTOMER_LIST]: [],
                            })
                        );
                    }
                    if (userTypeValueId !== SALESPERSON_ID && userTypeValueId !== STANDARD_ID) {
                        this.onTextChange(model.PROPS_USER_SALES_PERSON, []);
                    }
                    this.toggleUserTypeChangeDialog(false);
                    this.userTypeValue = null;
                }}
                onClose={() => {
                    this.userTypeValue = null;
                    this.toggleUserTypeChangeDialog(false);
                }}
                title={formatMessage(messages.userTypeChange)}
            >
                {formatMessage(
                    selectedUserTypeID === CUSTOMER_ID
                        ? messages.userTypeChangeDlgMsg
                        : messages.userTypeChangeSalespersonDlgMsg
                )}
            </DialogBox>
        );
    }

    renderPersonInfo = () => {
        const { formatMessage } = this.props.intl;
        const { user } = this;
        const { apiErrors } = this.props;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.firstName)}
                            labelText={formatMessage(messages.firstName)}
                            value={user[model.PROPS_USER_FIRST_NAME] || this.props.searchValue}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_USER_FIRST_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            containerClassNames={getAgBytesErrorClassNames(26, apiErrors)}
                            required
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.lastName)}
                            labelText={formatMessage(messages.lastName)}
                            value={user[model.PROPS_USER_LAST_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_USER_LAST_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            containerClassNames={getAgBytesErrorClassNames(27, apiErrors)}
                            required
                        />
                    </SubSection>
                </Section>
                <Section className="person-section">
                    <SubSection className="job-container">
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[PICKLIST_JOB_TITLE]}
                            value={mapToPicklistValue({
                                options: this.state[PICKLIST_JOB_TITLE],
                                selectedGuid: user[model.PROPS_USER_JOB_GUID],
                            })}
                            onChange={(value) =>
                                (this.user[model.PROPS_USER_JOB_GUID] = value ? value.guid : "")
                            }
                            placeholderText={formatMessage(messages.jobTitle)}
                            labelText={formatMessage(messages.jobTitle)}
                            containerClassNames={[
                                "jobtitle-container",
                                getAgBytesErrorClassNames(28, apiErrors),
                            ]}
                            clearable={false}
                            required
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderDetailInfo1 = () => {
        const { formatMessage } = this.props.intl;
        const { state, onChildComponentChange, mapSelectedCountry } = this;
        const { addEditPanel, needs, personAddMode, apiErrors, userRole, apiErrorModel } =
            this.props;
        const props = {
            onChildComponentChange,
            mapSelectedCountry,
            addEditPanel,
            needs,
            apiErrors,
            apiErrorModel,
        };
        return (
            <Section>
                <Section headerText={formatMessage(messages.user)} required>
                    <SubSection className="view-user-role-container">
                        <NoLink
                            label={formatMessage(messages.viewUserRoleMatrix)}
                            onClick={() => this.setState({ matrixView: true })}
                        ></NoLink>
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_USER_ROLE_LIST]}
                            placeholderText={formatMessage(messages.userRole)}
                            labelText={formatMessage(messages.userRole)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_USER_ROLE_LIST],
                                selectedGuid: this.user[model.PROPS_USER_ROLE_GUID],
                            })}
                            clearable={false}
                            containerClassNames={getAgBytesErrorClassNames(20, apiErrors)}
                            onChange={(value) => {
                                this.user[model.PROPS_USER_ROLE_GUID] = value ? value.guid : "";
                                this.forceUpdate();
                            }}
                            disabled={
                                this.disableUserRolesList(this.user[model.PROPS_USER_ROLE_GUID]) ||
                                (!personAddMode && !state[model.PROPS_USER_ACTIVEYN])
                            }
                            required
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={254}
                            placeholderText={formatMessage(messages.loginEmail)}
                            labelText={formatMessage(messages.loginEmail)}
                            value={this.user[model.PROPS_LOGIN_EMAIL]}
                            containerClassNames={getAgBytesErrorClassNames(21, apiErrors)}
                            onChange={(value) => this.onTextChange(model.PROPS_LOGIN_EMAIL, value)}
                            disabled={!personAddMode && !state[model.PROPS_USER_ACTIVEYN]}
                            required
                        />
                    </SubSection>
                    <SubSection className="user-section">
                        {personAddMode ? null : (
                            <NoLink
                                label={formatMessage(messages.resetPassword)}
                                onClick={() => this.onResetPassword()}
                                className="user-request-password"
                            />
                        )}
                        {personAddMode && userRole[model.PROPS_ACTIVE_INACTIVE] ? (
                            <Checkbox
                                onChange={(e, value) => {
                                    this.user[model.PROPS_USER_ROLE_GUID] = "";
                                    this.onTextChange(model.PROPS_USER_ACTIVEYN, value);
                                    this.setState({
                                        [model.PROPS_USER_ACTIVEYN]: value,
                                    });
                                }}
                                value={
                                    personAddMode
                                        ? personAddMode
                                        : this.user[model.PROPS_USER_ACTIVEYN]
                                }
                                disabled={personAddMode}
                                label={formatMessage(messages.active)}
                                className="user-is-active"
                            />
                        ) : null}
                        <SelectInput
                            containerClassNames={["time-zone-select"]}
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_TIME_ZONE_LIST]}
                            placeholderText={formatMessage(messages.timeZone)}
                            labelText={formatMessage(messages.timeZone)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_TIME_ZONE_LIST],
                                selectedGuid:
                                    this.user[model.PROPS_TIME_ZONE_GUID] ||
                                    this.getGuidOrID({
                                        options: this.props[model.PROPS_TIME_ZONE_LIST],
                                        id: "10",
                                    }),
                            })}
                            onChange={(value) =>
                                (this.user[model.PROPS_TIME_ZONE_GUID] = value ? value.guid : "")
                            }
                            clearable={false}
                            disabled={!personAddMode && !state[model.PROPS_USER_ACTIVEYN]}
                            required
                        />
                    </SubSection>
                    {!personAddMode &&
                    userRole[model.PROPS_ACTIVE_INACTIVE] &&
                    state[model.PROPS_ACTIVE_YN] ? (
                        <SubSection>
                            <Checkbox
                                onChange={(e, value) => {
                                    if (!value) {
                                        this.showIsActiveDialog(true);
                                    } else {
                                        this.onTextChange(model.PROPS_USER_ACTIVEYN, value);
                                        this.setActiveInactiveUserState(value);
                                    }
                                }}
                                value={
                                    personAddMode
                                        ? personAddMode
                                        : this.user[model.PROPS_USER_ACTIVEYN]
                                }
                                label={formatMessage(messages.active)}
                                disabled={!state[model.PROPS_ACTIVE_YN]}
                                className="user-is-active"
                            />
                        </SubSection>
                    ) : null}
                </Section>
                <Address
                    addressList={state[model.PROPS_PERSON_ADDRESS_LIST]}
                    addressTypeList={state[model.PROPS_ADDRESS_TYPE_LIST]}
                    countriesList={state[model.PROPS_COUNTRIES_LIST]}
                    stateList={state[model.PROPS_STATES_LIST]}
                    {...props}
                />
            </Section>
        );
    };

    renderDetailInfo2 = () => {
        const { formatMessage } = this.props.intl;
        const { state } = this;
        const { addEditPanel, needs, apiErrors, apiErrorModel, userGuid } = this.props;
        const props = { addEditPanel, needs, apiErrors, apiErrorModel };
        const salespersonRequired =
            SALESPERSON_ID ===
            this.getGuidOrID({
                options: this.state[PICKLIST_USER_TYPE],
                guid: this.state.selectedUserType,
            });
        return (
            <Section>
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.locationAffiliations)}
                >
                    {!state[model.PROPS_ORG_LEVEL_LIST] ? null : (
                        <OrgLevelList
                            classNames={getAgBytesErrorClassNames(2033, apiErrors)}
                            itemList={state[model.PROPS_ORG_LEVEL_LIST]}
                            onSelectionChange={(value) => {
                                if (value !== this.state[model.PROPS_PERSON_ORG_LIST]) {
                                    this.setState({ resetOptions: true });
                                }
                                this.onTextChange(model.PROPS_PERSON_ORG_LIST, value, () =>
                                    this.setState({
                                        [model.PROPS_PERSON_ORG_LIST]: value,
                                    })
                                );
                            }}
                            record={this.state[model.PROPS_PERSON_ORG_LIST]}
                            statePropName={PROPS_STATE_ABBREVIATION}
                            deleteConfirmation={this.onDeleteConfirmation}
                        />
                    )}
                </Section>
                <Section headerText={formatMessage(messages.userType)} required>
                    <RadioButtonGroup
                        selectedValue={
                            state.selectedUserType ||
                            this.getGuidOrID({
                                options: state[PICKLIST_USER_TYPE],
                                id: STANDARD_ID,
                            })
                        }
                        afterOnChange={(value) => {
                            const userTypeID = this.getGuidOrID({
                                options: this.state[PICKLIST_USER_TYPE],
                                guid: value,
                            });
                            if (
                                (userTypeID !== CUSTOMER_ID &&
                                    this.state[model.PROPS_USER_CUSTOMER_LIST] &&
                                    this.state[model.PROPS_USER_CUSTOMER_LIST].length > 0) ||
                                (userTypeID !== SALESPERSON_ID &&
                                    userTypeID !== STANDARD_ID &&
                                    this.user[model.PROPS_USER_SALES_PERSON] &&
                                    this.user[model.PROPS_USER_SALES_PERSON].length > 0)
                            ) {
                                this.toggleUserTypeChangeDialog(true);
                                this.userTypeValue = value;
                            } else {
                                this.onRadioButtonChange(value);
                            }
                        }}
                    >
                        {state[PICKLIST_USER_TYPE].map((userType, index) => {
                            return (
                                <RadioButton
                                    key={index}
                                    tabIndex={0}
                                    value={userType[PICKLIST_GUID]}
                                    label={userType.value}
                                />
                            );
                        })}
                    </RadioButtonGroup>
                </Section>
                {state.selectedUserTypeID !== CUSTOMER_ID ? null : (
                    <Section
                        headerText={formatMessage(messages.customerAffiliationHeading)}
                        className="grid-section"
                        required
                    >
                        <CustomerAffiliations
                            disabled={state[model.PROPS_PERSON_ORG_LIST].length ? false : true}
                            orgLevelList={state[model.PROPS_PERSON_ORG_LIST].map(
                                ({ orgLevelGuid }) => orgLevelGuid
                            )}
                            userGuid={userGuid}
                            record={this.state[model.PROPS_USER_CUSTOMER_LIST]}
                            statePropName={model.PROPS_STATE_ABBREVIATION}
                            onSelectionChange={(value) => {
                                this.onTextChange(model.PROPS_USER_CUSTOMER_LIST, value, () =>
                                    this.setState({
                                        [model.PROPS_USER_CUSTOMER_LIST]: value,
                                    })
                                );
                            }}
                            classNames={getAgBytesErrorClassNames(2627, apiErrors)}
                            getOptionCB={() => this.setState({ resetOptions: false })}
                            resetOptions={this.state.resetOptions}
                        />
                    </Section>
                )}
                {![SALESPERSON_ID, STANDARD_ID].includes(state.selectedUserTypeID) ? null : (
                    <Section
                        headerText={formatMessage(messages.salespersonIDHeading)}
                        className="grid-section"
                        required={salespersonRequired}
                    >
                        <SubSection>
                            <SalesPersonId
                                formatMessage={formatMessage}
                                record={this.user[model.PROPS_USER_SALES_PERSON]}
                                itemListAlias={model.PROPS_USER_SALES_PERSON}
                                onTextChange={(e, value) => {
                                    this.onTextChange(model.PROPS_USER_SALES_PERSON, value);
                                }}
                                classNames={getAgBytesErrorClassNames(
                                    2628,
                                    apiErrors,
                                    [],
                                    "sales-person-id-error"
                                )}
                                required={salespersonRequired}
                            />
                        </SubSection>
                    </Section>
                )}
                <Email
                    emailList={state[model.PROPS_EMAIL_LIST]}
                    onChildComponentChange={this.onChildComponentChange}
                    initialRenderCheck={this.initialRenderCheck}
                    {...props}
                />
                <Phone
                    phoneList={state[model.PROPS_PHONE_NUMBER_LIST]}
                    phoneTypeList={state[PICKLIST_PHONE_TYPE]}
                    onChildComponentChange={this.onChildComponentChange}
                    {...props}
                />
            </Section>
        );
    };

    renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        const { state } = this;
        const { needs, addEditPanel, userRole, apiErrors, recordGuid } = this.props;
        const props = {
            needs,
            addEditPanel,
        };
        return (
            <Section>
                {!userRole.autoCreateReports ? null : (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.autoCreateReports)}
                    >
                        <AutoReportList
                            classNames={getAgBytesErrorClassNames(2033, apiErrors)}
                            onSelectionChange={(value) => {
                                this.onTextChange(model.PROPS_PERSON_AUTO_REPORT_LIST, value, () =>
                                    this.setState({
                                        [model.PROPS_PERSON_AUTO_REPORT_LIST]: value,
                                    })
                                );
                            }}
                            personGuid={recordGuid}
                            record={state[model.PROPS_PERSON_AUTO_REPORT_LIST]}
                        />
                    </Section>
                )}
                <License
                    formatMessage={formatMessage}
                    licenseType={state[PICKLIST_LICENSE_TYPE]}
                    stateList={state[model.PROPS_STATES_LIST]}
                    countriesList={state[model.PROPS_COUNTRIES_LIST]}
                    record={this.user[model.PROPS_LICENSE_LIST]}
                    itemListAlias={model.PROPS_LICENSE_LIST}
                    onTextChange={this.onTextChange}
                    {...props}
                />
                <EmployeeInfo
                    employeeInfo={this.user}
                    onTextChange={this.onTextChange}
                    payType={state[PICKLIST_PAY_TYPE]}
                    payRate={state[UNIT_PAY_RATE]}
                    {...props}
                />
            </Section>
        );
    };

    render() {
        const { matrixView } = this.state;
        const { needs, setBreadcrumbs } = this.props;
        const props = {
            needs,
            setBreadcrumbs,
        };
        const { formatMessage } = this.props.intl;
        const { showIsActiveDialog } = this.state;
        return (
            <div className="add-edit-panel user-setup-cont">
                {this.renderPersonInfo()}
                <div className="section-container">
                    {this.renderDetailInfo1()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo2()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo3()}
                </div>
                {matrixView && (
                    <SlidingPanel
                        close={() =>
                            this.setState({ matrixView: false }, () => this.setHeaderText())
                        }
                        component={UserRoleMatrix}
                        navigateTo={{
                            parentNameCode: "101",
                            childNameCode: "120",
                        }}
                        {...props}
                    ></SlidingPanel>
                )}
                {this.getOrgDeleteDialog()}
                {this.getUserTypeChangeDialog()}
                <DialogBox
                    forceOverflow
                    title={formatMessage(messages.confirm)}
                    footerType={DialogBoxFooterType.YES_NO}
                    onClose={() => this.showIsActiveDialog(false)}
                    onAction={this.onPersonInActive}
                    isOpen={showIsActiveDialog}
                >
                    {formatMessage(messages.personInActiveConfirm)}
                </DialogBox>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
