export const UNIT_AGE = "age";
export const UNIT_APPLICATION_NOZZLE_TYPE = "applicationNozzleType";
export const UNIT_AREA = "area";
export const UNIT_COST = "cost";
export const UNIT_CROP_POPULATION = "cropPopulation";
export const UNIT_CROP_PRICE = "cropPrice";
export const UNIT_CURRENCY = "currency";
export const UNIT_DENSITY = "density";
export const UNIT_DISTANCE = "distance";
export const UNIT_FLOW_RATE = "flowRate";
export const UNIT_FUEL_EFFICIENCY = "fuelEfficiency";
export const UNIT_IRRIGATION_NOZZLE_TYPE = "irrigationNozzleType";
export const UNIT_IRRIGATION_VOLUME = "irrigationVolume";
export const UNIT_LENGTH = "length";
export const UNIT_PACKAGE = "package";
export const UNIT_PAY_RATE = "payRate";
export const UNIT_POWER = "power";
export const UNIT_PRESSURE = "pressure";
export const UNIT_PRICE_UNIT_DRY = "priceUnitDry";
export const UNIT_PRICE_UNIT_LIQUID = "priceUnitLiquid";
export const UNIT_PRICE_UNIT_SERVICE = "priceUnitService";
export const UNIT_PRODUCT_RATE_DRY = "productRateDry";
export const UNIT_PRODUCT_RATE_LIQUID = "productRateLiquid";
export const UNIT_REPORTING_UNIT = "reportingUnit";
export const UNIT_ROW_SELECTION = "rowSelection";
export const UNIT_SEEDING_RATE = "seedingRate";
export const UNIT_SPEED = "speed";
export const UNIT_TEMPERATURE = "temperature";
export const UNIT_TEST_WEIGHT = "testWeight";
export const UNIT_TIME = "time";
export const UNIT_VELOCITY = "velocity";
export const UNIT_VOLUME = "volume";
export const UNIT_WEIGHT = "weight";
export const UNIT_YIELD = "yield";

const unitNameToCodeMap = {
    [UNIT_AGE]: 1,
    [UNIT_APPLICATION_NOZZLE_TYPE]: 111,
    [UNIT_AREA]: 2,
    [UNIT_COST]: 3,
    [UNIT_CROP_POPULATION]: 4,
    [UNIT_CROP_PRICE]: 31,
    [UNIT_CURRENCY]: 28,
    [UNIT_DENSITY]: 27,
    [UNIT_DISTANCE]: 5,
    [UNIT_FLOW_RATE]: 6,
    [UNIT_FUEL_EFFICIENCY]: 7,
    [UNIT_IRRIGATION_NOZZLE_TYPE]: 112,
    [UNIT_IRRIGATION_VOLUME]: 8,
    [UNIT_LENGTH]: 9,
    [UNIT_PACKAGE]: 20,
    [UNIT_PAY_RATE]: 10,
    [UNIT_POWER]: 11,
    [UNIT_PRESSURE]: 12,
    [UNIT_PRICE_UNIT_DRY]: 29,
    [UNIT_PRICE_UNIT_LIQUID]: 30,
    [UNIT_PRICE_UNIT_SERVICE]: 32,
    [UNIT_PRODUCT_RATE_DRY]: 21,
    [UNIT_PRODUCT_RATE_LIQUID]: 22,
    [UNIT_REPORTING_UNIT]: 13,
    [UNIT_ROW_SELECTION]: 116,
    [UNIT_SEEDING_RATE]: 25,
    [UNIT_SPEED]: 14,
    [UNIT_TEMPERATURE]: 15,
    [UNIT_TEST_WEIGHT]: 16,
    [UNIT_TIME]: 17,
    [UNIT_VELOCITY]: 13,
    [UNIT_VOLUME]: 18,
    [UNIT_WEIGHT]: 19,
    [UNIT_YIELD]: 26,
};

export const getUnitCode = (unitName) => {
    return unitNameToCodeMap[unitName];
};
