import { createReducer } from "redux-act";
import { fetchedUnitData } from "./actions";

export const UNITDATA_DATA_KEY = "UNITDATA_DATA";

export const unitsReducer = createReducer(
    {
        [fetchedUnitData]: (state, payload) => ({
            ...state,
            ...payload,
        }),
    },
    {}
);
