import {
    AllEffect,
    CallEffect,
    PutEffect,
    SelectEffect,
    all,
    call,
    put,
    select,
    takeLatest,
} from "redux-saga/effects";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { AgEventAPI } from "@ai360/core";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as utils from "./event-scouting-utils";
import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";
import * as eventInfoSelectors from "../../selectors";
import { ScoutingTemplateObservation } from "~/recs-events/events/model";

const _applyObservationTemplateValues = (
    newScoutingTemplateObservationInfo: Array<ScoutingTemplateObservation>,
    agEventModel: AgEventAPI.IAgEventModel,
    completeOverwrite?: boolean,
    fromTemplate?: boolean
) => {
    if (completeOverwrite) {
        //Add fresh new set of template observations to newScoutingDetails
        const newScoutingDetailsList = [];
        for (const observationItem of newScoutingTemplateObservationInfo) {
            newScoutingDetailsList.push(
                utils.applyTemplateNewObsValues(observationItem, null, true)
            );
        }
        return newScoutingDetailsList;
    } else {
        //Only update unit values of observations that match template observations, and add any missing
        const updatedEventObs = agEventModel.scoutingDetailList.map((observationItem) => {
            const matchingTemplateObservation = newScoutingTemplateObservationInfo.find(
                (ob) => ob.observationGuid === observationItem.observationGuid
            );
            if (matchingTemplateObservation) {
                return utils.applyTemplateNewObsValues(
                    matchingTemplateObservation,
                    observationItem,
                    false
                );
            } else {
                return observationItem;
            }
        });
        return utils.applyMissingTemplateObservations(
            newScoutingTemplateObservationInfo,
            updatedEventObs,
            fromTemplate
        );
    }
};

const _formatAgBytesListData = (picklistValues: any) => {
    return picklistValues && picklistValues.length > 0
        ? picklistValues.map((val) => ({
              label: val.name,
              value: val.guid,
          }))
        : [];
};

const _getEventAreaById = (eventAreaList: Array<AgEventAPI.IAgEventArea>, eventAreaId: number) => {
    return eventAreaList.find((a: AgEventAPI.IAgEventArea) => a.eventAreaId === eventAreaId);
};

export const integrateCrop = function* (
    action: ReturnType<typeof actions.integrateCrop>
): Generator<SelectEffect | SelectEffect[] | PutEffect<any>, any, any> {
    const { cropGuid, cropPurposeGuid } = action.payload;
    const { eventSummary } = yield select(eventInfoSelectors.getModuleState);
    if (eventSummary != null) {
        const integratedCropValues = {
            cropGuid: cropGuid,
            cropPurposeGuid: cropPurposeGuid,
        };
        //Always update the current zone
        yield put(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                eventSummary.fieldGuid,
                eventSummary.agEventTransactionTypeGuid,
                integratedCropValues
            )
        );
        const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
        const eventDetails = fieldGuidToEventDetails.get(eventSummary.fieldGuid);
        const eventAreaIds = yield select(eventsSelectors.getEventAreaIds, eventSummary.fieldGuid);
        for (const id of eventAreaIds) {
            const area = _getEventAreaById(eventDetails.eventAreaList, id);
            const agEvent = area.agEventList[0].agEventModel;
            if (agEvent.cropGuid) {
                //For any zones that already have a crop selected, skip updating
                continue;
            }
            yield put(
                recsEventsActions.updateAgEventModel(
                    eventSummary.fieldGuid,
                    area.eventAreaId,
                    eventSummary.agEventTransactionTypeGuid,
                    integratedCropValues
                )
            );
        }
    }
};

export const integrateCropInfo = function* (
    action: ReturnType<typeof actions.integrateCropInfo>
): Generator<SelectEffect | PutEffect<any>, any, any> {
    const { scoutingTemplate, currentZoneOnly, templateOverwrite } = action.payload;
    const { eventSummary } = yield select(eventInfoSelectors.getModuleState);
    if (eventSummary != null) {
        if (currentZoneOnly) {
            const currentArea = yield select(
                eventsSelectors.getCurrentAgEventArea,
                eventSummary.fieldGuid
            );
            const agEventModel = currentArea.agEventList[0].agEventModel;
            const integratedCropInfo = utils.applyCropTemplateValues(
                scoutingTemplate,
                agEventModel,
                templateOverwrite
            );
            yield put(
                recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                    eventSummary.fieldGuid,
                    eventSummary.agEventTransactionTypeGuid,
                    integratedCropInfo
                )
            );
            return;
        } else {
            const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
            const eventDetails = fieldGuidToEventDetails.get(eventSummary.fieldGuid);
            const eventAreaIds = yield select(
                eventsSelectors.getEventAreaIds,
                eventSummary.fieldGuid
            );
            for (const id of eventAreaIds) {
                const area = _getEventAreaById(eventDetails.eventAreaList, id);
                const agEvent = area.agEventList[0].agEventModel;
                if (
                    (agEvent.cropGuid && agEvent.cropGuid !== scoutingTemplate.cropGuid) ||
                    (agEvent.cropPurposeGuid || "") !== (scoutingTemplate.cropPurposeGuid || "")
                ) {
                    //Skip zones with different crops
                    continue;
                }
                const integratedCropInfo = utils.applyCropTemplateValues(
                    scoutingTemplate,
                    agEvent,
                    templateOverwrite
                );
                yield put(
                    recsEventsActions.updateAgEventModel(
                        eventSummary.fieldGuid,
                        area.eventAreaId,
                        eventSummary.agEventTransactionTypeGuid,
                        integratedCropInfo
                    )
                );
            }
        }
    }
};

export const integrateObservationInfo = function* (
    action: ReturnType<typeof actions.integrateObservationInfo>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { scoutingTemplate, currentZoneOnly, templateSave } = action.payload;
    const { eventSummary } = yield select(eventInfoSelectors.getModuleState);
    if (eventSummary != null) {
        if (currentZoneOnly) {
            const currentArea = yield select(
                eventsSelectors.getCurrentAgEventArea,
                eventSummary.fieldGuid
            );
            const agEventModel = currentArea.agEventList[0].agEventModel;
            const integratedObservationInfo = _applyObservationTemplateValues(
                scoutingTemplate.observationInfo,
                agEventModel,
                !templateSave,
                templateSave
            );
            yield put(
                recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                    eventSummary.fieldGuid,
                    eventSummary.agEventTransactionTypeGuid,
                    { scoutingDetailList: integratedObservationInfo }
                )
            );
        } else {
            const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
            const eventDetails = fieldGuidToEventDetails.get(eventSummary.fieldGuid);
            const eventAreaIds = yield select(
                eventsSelectors.getEventAreaIds,
                eventSummary.fieldGuid
            );
            for (const id of eventAreaIds) {
                const area = _getEventAreaById(eventDetails.eventAreaList, id);
                const agEvent = area.agEventList[0].agEventModel;
                if (
                    !agEvent.cropGuid ||
                    agEvent.cropGuid !== scoutingTemplate.cropGuid ||
                    (agEvent.cropPurposeGuid || "") !== (scoutingTemplate.cropPurposeGuid || "")
                ) {
                    //Skip zones with different crops
                    continue;
                }
                const integratedObservationInfo = _applyObservationTemplateValues(
                    scoutingTemplate.observationInfo,
                    agEvent,
                    !templateSave,
                    templateSave
                );
                yield put(
                    recsEventsActions.updateAgEventModel(
                        eventSummary.fieldGuid,
                        area.eventAreaId,
                        eventSummary.agEventTransactionTypeGuid,
                        { scoutingDetailList: integratedObservationInfo }
                    )
                );
            }
        }
    }
};

export const onFetchObservationData = function* (
    action: ReturnType<typeof actions.fetchObservationData>
): Generator<SelectEffect | PutEffect<any> | CallEffect, void, any> {
    const userGuid = yield select(getTheUserGuid);
    yield put(actions.setScoutingDataLoading(true));
    try {
        const observationList = yield call(AgEventAPI.getObservationPickList, userGuid);
        yield put(actions.fetchObservationDataSucceeded(observationList));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setScoutingDataLoading(false));
    }
};

export const onFetchGrowthStageData = function* (
    action: ReturnType<typeof actions.fetchApplyTemplate>
): Generator<SelectEffect | PutEffect<any> | CallEffect, void, any> {
    const { cropGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    yield put(actions.setScoutingDataLoading(true));
    try {
        const growthStageList = yield call(
            AgEventAPI.getScoutingCropGrowthStageList,
            userGuid,
            cropGuid
        );
        const formattedGrowthStageList = _formatAgBytesListData(growthStageList);
        yield put(actions.fetchGrowthStageDataSucceeded(formattedGrowthStageList));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setScoutingDataLoading(false));
    }
};

export const onFetchGrowthStageObservationData = function* (
    action: ReturnType<typeof actions.fetchGrowthStageObservationData>
): Generator<SelectEffect | CallEffect | PutEffect<any>, void, any> {
    const { observationGuidList } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    yield put(actions.setScoutingDataLoading(true));
    yield put(actions.setScoutingTemplateIsLoading(true));
    try {
        const growthStageObservationSet = yield call(
            AgEventAPI.getScoutingGrowthStageObservationList,
            userGuid,
            observationGuidList
        );
        yield put(
            actions.fetchGrowthStageObservationDataSucceeded({
                observationGuidList,
                growthStageObservationSet,
            })
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setScoutingDataLoading(false));
        yield put(actions.setScoutingTemplateIsLoading(false));
    }
};

export const onFetchScoutingTemplate = function* (
    action: ReturnType<typeof actions.fetchScoutingTemplate>
): Generator<SelectEffect | PutEffect<any> | CallEffect, void, any> {
    const { cropGuid, cropPurposeGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    yield put(actions.setScoutingTemplateIsLoading(true));
    try {
        const scoutingTemplate = yield call(
            AgEventAPI.getScoutingTemplate,
            userGuid,
            cropGuid,
            cropPurposeGuid
        );
        yield put(actions.fetchScoutingTemplateSuccess(scoutingTemplate));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setScoutingTemplateIsLoading(false));
    }
};

export const onFetchApplyTemplate = function* (
    action: ReturnType<typeof actions.fetchApplyTemplate>
): Generator<SelectEffect | PutEffect<any> | CallEffect, void, any> {
    const { cropGuid, cropPurposeGuid, currentZoneOnly, overwriteValues } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    const { eventSummary } = yield select(eventInfoSelectors.getModuleState);
    yield put(actions.setScoutingDataLoading(true));
    try {
        const scoutingTemplate = yield call(
            AgEventAPI.getScoutingTemplate,
            userGuid,
            cropGuid,
            cropPurposeGuid
        );
        yield put(actions.fetchScoutingTemplateSuccess(scoutingTemplate));
        if (overwriteValues) {
            yield put(actions.integrateCropInfo(scoutingTemplate, currentZoneOnly, true));
            yield put(actions.integrateObservationInfo(scoutingTemplate, currentZoneOnly, false));
        } else {
            //just apply any missing observations
            const currentArea = yield select(
                eventsSelectors.getCurrentAgEventArea,
                eventSummary.fieldGuid
            );
            if (currentArea) {
                const agEventModel = currentArea.agEventList[0].agEventModel;
                const templateObsApplied = utils.applyMissingTemplateObservations(
                    scoutingTemplate.observationInfo,
                    [...agEventModel.scoutingDetailList],
                    false
                );
                yield put(
                    recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                        eventSummary.fieldGuid,
                        eventSummary.agEventTransactionTypeGuid,
                        { scoutingDetailList: templateObsApplied }
                    )
                );
            }
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setScoutingDataLoading(false));
        yield put(actions.setScoutingTemplateIsLoading(false));
    }
};

export const onSaveScoutingTemplate = function* (
    action: ReturnType<typeof actions.saveScoutingTemplate>
): Generator<SelectEffect | PutEffect<any> | CallEffect, void, any> {
    const userGuid = yield select(getTheUserGuid);
    const scoutingTemplate = yield select(selectors.getScoutingTemplate);
    yield put(actions.setScoutingDataLoading(true));
    try {
        yield call(AgEventAPI.setScoutingTemplate, userGuid, scoutingTemplate);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.integrateCropInfo(scoutingTemplate, false, false));
        yield put(actions.integrateObservationInfo(scoutingTemplate, false, true));

        const { eventSummary } = yield select(eventInfoSelectors.getModuleState);
        const currentArea = yield select(
            eventsSelectors.getCurrentAgEventArea,
            eventSummary.fieldGuid
        );
        const agEventModel = currentArea.agEventList[0].agEventModel;
        if (
            agEventModel.cropGuid !== scoutingTemplate.cropGuid ||
            (agEventModel.cropPurposeGuid || "") !== (scoutingTemplate.cropPurposeGuid || "")
        ) {
            //Saved template does not match the current selected zone, so fetch the matching template
            yield put(
                actions.fetchScoutingTemplate(agEventModel.cropGuid, agEventModel.cropPurposeGuid)
            );
        }

        yield put(actions.setScoutingDataLoading(false));
    }
};

export const scoutingSaga = function* (): Generator<AllEffect, void, any> {
    yield all([
        takeLatest(actions.FETCH_OBSERVATION_DATA, onFetchObservationData),
        takeLatest(actions.FETCH_GROWTH_STAGE_DATA, onFetchGrowthStageData),
        takeLatest(actions.FETCH_GROWTH_STAGE_OBSERVATION_DATA, onFetchGrowthStageObservationData),
        takeLatest(actions.FETCH_SCOUTING_TEMPLATE, onFetchScoutingTemplate),
        takeLatest(actions.FETCH_APPLY_TEMPLATE, onFetchApplyTemplate),
        takeLatest(actions.SAVE_SCOUTING_TEMPLATE, onSaveScoutingTemplate),
        takeLatest(actions.INTEGRATE_CROP, integrateCrop),
        takeLatest(actions.INTEGRATE_CROP_INFO, integrateCropInfo),
        takeLatest(actions.INTEGRATE_OBSERVATION_INFO, integrateObservationInfo),
    ]);
};
