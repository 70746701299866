import { combineReducers } from "redux";

import {
    DRAG_AND_DROP_FILE_UPLOADER_DATA_STATE_KEY,
    dragAndDropFileUploaderReducer,
} from "./drag-and-drop-file-uploader/reducer";
import { PRODUCT_BLEND_STATE_KEY, productBlendReducer } from "./product-blend-modal/reducer";
import {
    VARIETY_HYBRID_DATA_KEY,
    brandVarietyHybridListReducer,
} from "./planting/variety-hybrid/data/reducer";

export const COMMON_STATE_KEY = "COMMON_STATE";

export const commonReducer = combineReducers({
    [DRAG_AND_DROP_FILE_UPLOADER_DATA_STATE_KEY]: dragAndDropFileUploaderReducer,
    [PRODUCT_BLEND_STATE_KEY]: productBlendReducer,
    [VARIETY_HYBRID_DATA_KEY]: brandVarietyHybridListReducer,
});
