import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { actions as accordionActions } from "~/accordion";

import { actions as fileImportActions, selectors as fileImportSelectors } from "~/file-import";

import { AccordionItemContextMenu } from "./item-context-menu-container";
import { keywords } from "../../../keywords";

import { ApplicationIcon, MonitorFileIcon, ImageryIcon } from "~/core/icons";
import {
    HarvestIcon,
    PlantingIcon,
    SamplingIcon,
    SamplingTissueIcon,
    ECDataIcon,
} from "~/action-panel/components/event-module/components/event-info/components/icons";
import { PolygonIcon } from "~/map/components/assets/polygon";

import "./import-type-accordion-item.css";

export const IMPORT_TYPE_HEIGHT = 42;

export const getImportTypeIcon = (importType = "") => {
    let returnValue = null;
    switch (importType) {
        case keywords.APPLICATION:
            returnValue = <ApplicationIcon />;
            break;
        case keywords.EC_DATA:
            returnValue = <ECDataIcon />;
            break;
        case keywords.FIELD_BOUNDARY:
            returnValue = <PolygonIcon className="svg-stroke-white" />;
            break;
        case keywords.HARVEST:
            returnValue = <HarvestIcon />;
            break;
        case keywords.PLANTING:
            returnValue = <PlantingIcon />;
            break;
        case keywords.SAMPLING_SOIL:
            returnValue = <SamplingIcon />;
            break;
        case keywords.SAMPLING_TISSUE:
            returnValue = <SamplingTissueIcon />;
            break;
        case keywords.IMAGERY:
            returnValue = <ImageryIcon />;
            break;
        default:
            returnValue = <MonitorFileIcon />;
            break;
    }
    return returnValue;
};

export class ImportTypeItem_ extends Component {
    static propTypes = {
        accordionId: PropTypes.number.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        itemDimIdx: PropTypes.arrayOf(PropTypes.number),
        typeGuid: PropTypes.string.isRequired,
        getImportType: PropTypes.func.isRequired,
        expandItem: PropTypes.func.isRequired,
        collapseItem: PropTypes.func.isRequired,
    };

    _onClick(event) {
        if (event.isDefaultPrevented()) {
            return;
        }

        const { isExpanded, expandItem, collapseItem, accordionId, itemDimIdx, typeGuid } =
            this.props;

        if (isExpanded) {
            collapseItem(accordionId, itemDimIdx, typeGuid);
        } else {
            expandItem(accordionId, itemDimIdx, typeGuid);
        }
    }

    render() {
        const { typeGuid, getImportType, itemDimIdx } = this.props;
        const importType = getImportType(typeGuid);
        if (!importType) {
            return null;
        }
        return (
            <div className="import-type-accordion-item" onClick={(event) => this._onClick(event)}>
                <div className="type-name">
                    <span
                        className={classNames([
                            "icon-container",
                            {
                                "field-boundary": keywords.FIELD_BOUNDARY === importType.name,
                            },
                        ])}
                    >
                        {getImportTypeIcon(importType.name)}
                    </span>
                    {importType.name}
                </div>
                <AccordionItemContextMenu itemDimIdx={itemDimIdx} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    expandItem: (accordionId, dimIdx, importTypeGuid) => {
        dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx));
        dispatch(fileImportActions.setTypeExpanded(importTypeGuid, true));
    },
    collapseItem: (accordionId, dimIdx, importTypeGuid) => {
        dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx));
        dispatch(fileImportActions.setTypeExpanded(importTypeGuid, false));
    },
});

const mapStateToProps = (state) => {
    return {
        getImportType: (guid) => fileImportSelectors.getImportType(state, guid),
    };
};

export const ImportTypeItem = connect(mapStateToProps, mapDispatchToProps)(ImportTypeItem_);
