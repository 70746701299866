import { put, take, takeEvery, race } from "redux-saga/effects";
import {
    prepareSuccessActionType,
    prepareFailureActionType,
    SUCCESS,
    FAILURE,
    PENDING,
    NESTED_GRID_SUCCESS,
} from "./utils";
import { setRequestId, setRequestStatus } from "./needs";

function* dispatchAction(action) {
    const { type } = action;
    yield put(action);
    return yield race({
        successAction: take(prepareSuccessActionType(type)),
        success: take(SUCCESS),
        nestedGridsuccess: take(NESTED_GRID_SUCCESS),
        failedAction: take(prepareFailureActionType(type)),
        failed: take(FAILURE),
    });
}

// Request saga to compose passed array of actions into a single request
// to process
export function* needsRequest({ payload }) {
    const { actions, requestId } = payload;
    yield put(
        setRequestStatus({
            status: PENDING,
            requestId,
        })
    );
    // These two request arrays will be used in future to notify the view
    // all the requests which are failed or succeeded
    const successRequests = [],
        failedRequests = [];
    for (const key of Object.keys(actions)) {
        const action = actions[key];
        const response = yield dispatchAction(action);
        if (response.failed || response.failedAction) {
            failedRequests.push({ action: action.type, requestId });
        } else {
            successRequests.push({
                action: action.type,
                result: response.payload,
                requestId,
            });
        }
    }
    if (failedRequests.length === actions.length) {
        yield put(
            setRequestStatus({
                status: FAILURE,
                requestId,
            })
        );
    } else {
        yield put(
            setRequestStatus({
                status: SUCCESS,
                requestId,
            })
        );
    }
}

export default function* needsWatcher() {
    yield takeEvery(setRequestId, needsRequest);
}
