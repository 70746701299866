import { createReducer } from "redux-act";
import * as actions from "./actions";

export const LOCATION_MIGRATION_DATA_KEY = "LOCATION_MIGRATION_DATA";

const initialState = {
    agvanceDatabaseId: null,
};

export const locationMigrationSetupReducer = createReducer(
    {
        [actions.fetchAgvanceDatabaseIdSuccess]: (state, payload) => ({
            ...state,
            agvanceDatabaseId: payload.agvanceDatabaseId,
        }),
        [actions.postValidationSuccess]: (state, payload) => ({
            ...state,
            validateResults: payload,
        }),
        [actions.postMigrationSuccess]: (state, payload) => ({
            ...state,
            migrationResults: payload,
        }),
        [actions.postInvalidLocationCheckSuccess]: (state, payload) => ({
            ...state,
            invalidCheckResult: payload,
        }),
        [actions.getLocationsSuccess]: (state, payload) => ({
            ...state,
            locationEntities: payload,
            agvanceDatabaseId: "",
        }),
        [actions.setAgvanceDatabaseId]: (state, payload) => ({
            ...state,
            agvanceDatabaseId: payload.agvanceDatabaseId,
        }),
    },
    initialState
);
