import { defineMessages } from "react-intl";

export default defineMessages({
    deleteValidation: {
        id: "gridCommon.deleteSelected",
        defaultMessage: "You must first select records.",
    },
    noResponse: {
        id: "gridCommon.noResponse",
        defaultMessage: "Received no response from the server",
    },
    exportToFileFailed: {
        id: "gridCommon.exportToFileFailed",
        defaultMessage: "Export failed.",
    },
});
