import { SearchAPI } from "@ai360/core";

export async function fetchOccupiedFarmNames(
    customerGuid: string,
    active: boolean | null,
    userGuid: string
) {
    const fields = await SearchAPI.getFields({
        customerGuid: [customerGuid],
        active,
        userGuid,
    });
    const set = new Set(fields.map((x) => x.farmName).filter((x) => x != null && x !== ""));
    return [...set];
}
