import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { MenuPositions, MenuItems } from "./menu-items";
import { DownArrowIcon } from "~/core/icons";
import "./menu.css";

const defaultDotMenuRenderer = () => {
    const dot = "\u25CF";
    return (
        <div>
            <div className="menu-dot">{dot}</div>
            <div className="menu-dot">{dot}</div>
            <div className="menu-dot">{dot}</div>
        </div>
    );
};

export class Menu extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        dotMenuRenderer: PropTypes.func,
        getMenuItems: PropTypes.func,
        isDotMenu: PropTypes.bool,
        label: PropTypes.string,
        menuItems: (props, propName, componentName, ...rest) => {
            const menuItemsProp = props[propName];
            if (menuItemsProp == null) {
                const getMenuItemsProp = props.getMenuItems;
                if (getMenuItemsProp == null) {
                    return new Error("Must define either `menuItems` or `getMenuItems`");
                }
                return null;
            }
            return PropTypes.array(props, propName, componentName, ...rest);
        },
        position: PropTypes.oneOf(Object.keys(MenuPositions).map((k) => MenuPositions[k])),
        onExpand: PropTypes.func,
        onCollapse: PropTypes.func,
    };

    static defaultProps = {
        className: "",
        dotMenuRenderer: defaultDotMenuRenderer,
        label: "Menu Placeholder",
        isDotMenu: false,
        position: undefined,
        onExpand: () => null,
        onCollapse: () => null,
    };

    constructor() {
        super();
        this.state = {
            expanded: false,
        };
        this.handleWindowClick = this.handleWindowClick.bind(this);
    }

    expand(evt) {
        this.setState({ expanded: true }, this.props.onExpand());
        evt.preventDefault();
    }

    handleWindowClick(evt) {
        if (this.rootNode && !this.rootNode.contains(evt.target)) {
            this.setState({ expanded: false }, this.props.onCollapse());
        }
    }

    render() {
        const clsNames = [this.props.className];
        const label = this.props.label;

        let position = this.props.position;
        if (position == null) {
            if (this.props.isDotMenu) {
                position = MenuPositions.RIGHT_SIDE;
            } else {
                position = MenuPositions.BOTTOM_RIGHT;
            }
        }

        const menuItems = !this.state.expanded ? null : (
            <MenuItems
                items={this.props.menuItems || this.props.getMenuItems()}
                onWindowClick={(evt) => this.handleWindowClick(evt)}
                parent={this.rootNode}
                position={position}
            />
        );

        let menuEl;
        if (this.props.isDotMenu) {
            clsNames.push("menu-dots");
            menuEl = this.props.dotMenuRenderer();
        } else {
            clsNames.push("menu-items");
            menuEl = (
                <div>
                    <a
                        className="menu-items-text"
                        href={"#" + label}
                        onClick={(evt) => evt.preventDefault()}
                    >
                        {label}
                    </a>
                    <span className="menu-items-arrow">
                        <DownArrowIcon />
                    </span>
                </div>
            );
        }
        return (
            <div
                className={classnames(clsNames, {
                    "menu-expanded": this.state.expanded,
                })}
                onClick={(evt) => this.expand(evt)}
                ref={(rootNode) => {
                    this.rootNode = rootNode;
                }}
            >
                {menuEl}
                {menuItems}
            </div>
        );
    }
}
