import * as model from "./model";

const accountDefaultRecord = () => ({
    [model.PROPS_CONNECTION_GUID]: "",
    [model.PROPS_CONNECTION_NAME]: "",
    [model.PROPS_ONSITE_PARAM_LIST]: [],
    [model.PROPS_ONSITE_PASSWORD]: "",
    [model.PROPS_USER_CONNECTION_NAME]: "",
    [model.PROPS_AUTO_EXCHANGE]: false,
});

export default accountDefaultRecord;
