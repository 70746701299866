import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classnames from "classnames";
import { getUser } from "~/login";
import { selectors as accordionSelectors, Accordion } from "~/accordion";
import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";

import * as actions from "../../actions";
import * as selectors from "../../selectors";

import { FieldItem } from "../../../../../common/accordion/field-accordion-item";
import { EventItem } from "./event-accordion-item-container";

const getAccordionItemEl = ({
    key,
    style,
    accordionItem,
    accordionItemDimIdx,
    isStuck,
    componentProps,
}) => {
    let contentEl;
    if (accordionItemDimIdx.length === 1) {
        contentEl = (
            <FieldItem
                accordionId={componentProps.accordionId}
                isExpanded={accordionItem.expanded}
                itemDimIdx={accordionItemDimIdx}
                field={accordionItem.payload}
                getMenuItems={componentProps.getFieldMenuItems}
                getSelectedItemCount={componentProps.getSelectedItemCount}
                hideRecCount={true}
                hideEventCount={false}
                activeTab={componentProps.activeTab}
            />
        );
    } else {
        console.assert(accordionItemDimIdx.length === 2);
        contentEl = (
            <EventItem
                accordionId={componentProps.accordionId}
                isExpanded={accordionItem.expanded}
                itemDimIdx={accordionItemDimIdx}
                objGuid={accordionItem.payload.agEventGeneralGuid}
            />
        );
    }

    const classNames = [
        "accordion-item",
        {
            "has-children": accordionItem.expanded && accordionItem.children.length > 1,
            sticky: isStuck,
        },
    ];
    return (
        <div key={key} style={style} className={classnames(classNames)}>
            {contentEl}
        </div>
    );
};

getAccordionItemEl.propTypes = {
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Unique key within array of rows
    style: PropTypes.object, // Style object to be applied to row (to position it)
    accordionItem: PropTypes.object,
    accordionItemDimIdx: PropTypes.arrayOf(PropTypes.number),
    isStuck: PropTypes.bool,
    componentProps: PropTypes.object, // Pass thru props from <Accordion />
};

const mapDispatchToProps = (dispatch) => ({
    onScrollTopChanged: (scrollTop, componentProps) => {
        const { activeTab } = componentProps.filter;
        dispatch(actions.setInitScrollTop(activeTab, scrollTop));
    },
    onCreateNewEvent: (fieldGuid, fieldBoundaryGuid, customerGuid, agEventTransactionTypeName) =>
        dispatch(
            recsEventsActions.createNewEventDetails(
                [fieldGuid],
                new Map([[fieldGuid, fieldBoundaryGuid]]),
                new Map([[fieldGuid, customerGuid]]),
                agEventTransactionTypeName
            )
        ),
});

const mapStateToProps = (state) => {
    const accordionState = selectors.getAccordionState(state);
    const moduleState = selectors.getModuleState(state);

    let scrollTop;
    switch (moduleState.filter.activeTab) {
        case recsEventsActions.RecEventListTabs.ACTIVE:
            scrollTop = moduleState.allAccordionInit.initScrollTop;
            break;
        case recsEventsActions.RecEventListTabs.INACTIVE:
            scrollTop = moduleState.inactiveAccordionInit.initScrollTop;
            break;
        case recsEventsActions.RecEventListTabs.SELECTED:
            scrollTop = moduleState.selectedAccordionInit.initScrollTop;
            break;
        default:
            throw new Error("unreachable: unknown selected tab");
    }

    const flatIdxMapSelector = accordionSelectors.flatIdxMapSelector(accordionState);
    const dimIdxMapSelector = accordionSelectors.dimIdxMapSelector(accordionState);
    const fieldGuidToSelectedEventGuidSetMap =
        selectors.getFieldGuidToSelectedEventGuidSetMap(state);
    const { filter } = moduleState;
    const eventTypeOptions = eventsSelectors.getNewableEventTypeOptions(state);

    const isInactiveTab =
        moduleState.filter.activeTab === recsEventsActions.RecEventListTabs.INACTIVE;

    const getSelectedItemCount = ({ fieldGuid }) =>
        !isInactiveTab && fieldGuidToSelectedEventGuidSetMap.has(fieldGuid)
            ? fieldGuidToSelectedEventGuidSetMap.get(fieldGuid).size
            : 0;

    return {
        itemCount: accordionState.recItemCount,
        itemList: accordionState.items,
        totalHeight: accordionState.recHeight,
        getAccordionItemFromFlatIdx: (idx) => flatIdxMapSelector(idx),
        getAccordionItemFromDimIdx: (dimIdx) => dimIdxMapSelector(dimIdx),
        getAccordionItemEl: getAccordionItemEl,
        scrollTop,

        // pass-thru prop; <Accordion /> doesn't care about these but they're passed-thru
        //  to `getAccordionItemEl` and `onScrollTopChanged`
        accordionId: accordionState.accordionId,
        filter,
        getSelectedItemCount,
        eventTypeOptions,
        isInactiveTab,
        activeTab: moduleState.filter.activeTab,
        userInfo: getUser(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getFieldMenuItems: ({ itemDimIdx }) => {
        if (stateProps.isInactiveTab) {
            return []; // Context menu for Field item = empty, when on inactive tab
        }
        const { eventTypeOptions, getAccordionItemFromDimIdx } = stateProps;
        const { onCreateNewEvent } = dispatchProps;
        const accordionItem = getAccordionItemFromDimIdx(itemDimIdx);
        const { fieldGuid, fieldBoundaryGuid, customerGuid } = accordionItem.payload;
        return eventTypeOptions.map((eventTypeOption, key) => {
            const eventTypeInfo = eventTypeOption.value;
            return {
                key,
                label: eventTypeOption.label,
                action: () =>
                    onCreateNewEvent(
                        fieldGuid,
                        fieldBoundaryGuid,
                        customerGuid,
                        eventTypeInfo.agEventTransactionTypeName
                    ),
            };
        });
    },
});

export const EventAccordion = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Accordion);
