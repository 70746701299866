import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import * as actions from "../actions";
import { messages } from "../i18n-messages";

import { Button, TextInput } from "../../core";
import { messages as globalMessages } from "../../i18n-messages";
import { AuthenticationAPI, LocalStorageHelpers } from "@ai360/core";

const { LoginState } = actions;

export const isValidEmail = (email: string): boolean => {
    const expression =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return new RegExp(expression).test(email);
};

export interface IRecoverPassword_Props {
    onError?: (error: Error) => void;
    onResetLogin: () => void;
    onSetLoginState: (action: number) => void;
    onSetSecurityInfo: (email: string, question: string) => void;
    onSetSecurityMessage: (secCode: number) => void;
    onStartProcessing: () => void;
    onStopProcessing: () => void;
    intl: intlShape;
}

export interface IRecoverPassword_State {
    email: string;
}

class RecoverPassword_ extends Component<IRecoverPassword_Props, IRecoverPassword_State> {
    txtEmail: Record<string, any>;

    constructor(props) {
        super(props);
        this.state = {
            email: LocalStorageHelpers.get(LocalStorageHelpers.USERNAME) || "",
        };
    }

    private _handleError(error?: Error) {
        if (this.props.onError) {
            this.props.onError(error);
        }
        this.props.onStopProcessing();
        this.txtEmail.input.focus();
    }

    private _handleKeyDown(event) {
        if (event.key === "Enter") {
            this.submit();
        }
    }

    public cancel() {
        this.props.onResetLogin();
    }

    public submit() {
        this.props.onStartProcessing();
        const { formatMessage } = this.props.intl;
        const isValid = isValidEmail(this.state.email);
        if (this.state.email && isValid) {
            AuthenticationAPI.getSecurityQuestion(this.state.email)
                .then((question) => {
                    if (question) {
                        this.props.onSetSecurityInfo(this.state.email, question);
                        this.props.onSetLoginState(LoginState.SECURITY_QUESTION);
                        this.props.onStopProcessing();
                    } else {
                        AuthenticationAPI.firstTimeUserResetPassword(this.state.email)
                            .then((response) => {
                                if (response && typeof response.securityCode === "number") {
                                    this.props.onSetSecurityMessage(response.securityCode);
                                } else {
                                    this._handleError(formatMessage(globalMessages.unknownError));
                                }
                            })
                            .catch((error) => {
                                const { apiResultObj } = error;
                                if (
                                    apiResultObj &&
                                    apiResultObj.model &&
                                    typeof apiResultObj.model.securityCode === "number"
                                ) {
                                    this.props.onSetSecurityMessage(
                                        apiResultObj.model.securityCode
                                    );
                                } else {
                                    this._handleError(
                                        formatMessage(messages.unableToResetPassword)
                                    );
                                }
                            });
                    }
                })
                .catch((error) => this._handleError(error));
        } else if (!isValid) {
            this._handleError(formatMessage(messages.validEmail));
        } else {
            this._handleError();
        }
    }

    componentDidMount() {
        this.txtEmail.input.focus();
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <div className="login-input-container">
                    <div className="display-label">{formatMessage(messages.inputEmail)}</div>
                    <TextInput
                        tabIndex={1}
                        value={this.state.email}
                        placeholderText={formatMessage(globalMessages.emailPlaceholder)}
                        onKeyDown={(e) => this._handleKeyDown(e)}
                        onChange={(email) => this.setState({ email })}
                        ref={(input) => {
                            this.txtEmail = input;
                        }}
                    />
                </div>
                <div className="login-center">
                    <Button
                        tabIndex={2}
                        className="login-btn"
                        type="submitTextOnly"
                        onClick={() => this.submit()}
                    />
                    <Button
                        tabIndex={3}
                        className="login-btn cancel-btn"
                        type="cancel"
                        onClick={() => this.cancel()}
                    />
                </div>
            </div>
        );
    }
}
export const RecoverPassword = injectIntl(RecoverPassword_);

const mapDispatchToProps = (dispatch) => {
    return {
        onResetLogin: () => dispatch(actions.resetLogin()),
        onSetLoginState: (loginState) => dispatch(actions.setLoginState(loginState)),
        onSetSecurityInfo: (email, question) => dispatch(actions.setSecurityInfo(email, question)),
        onSetSecurityMessage: (code) => dispatch(actions.setSecurityMessage(code)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export default connect(null, mapDispatchToProps)(RecoverPassword);
