import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { TrashcanIcon } from "~/core/icons";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    AutoSearch,
    Bucket,
    BucketHeader,
    Checkbox,
    DateInput,
    NumericInput,
    SelectInput,
    optionPropType,
    TextArea,
    TextInput,
} from "~/core";

import { getTheUserGuid } from "~/login";
import { PersonAPI, EquipmentProfileAPI } from "@ai360/core";
import { getAgEventTransactionTypeNameToFormMap } from "./transaction-type-form-map";

import {
    actions as recsEventsActions,
    eventsSelectors,
    selectors as recsEventsSelectors,
} from "~/recs-events";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { getModuleState } from "../selectors";
import { getSetValuesForErrorCodeList } from "../../../../common/validation-utils";

import "../../../../common/rec-event-info/rec-event-info.css";

import "./event-general-form.css";

const messages = defineMessages({
    eventNamePlaceholderText: {
        id: "eventModule.eventInfo.eventNamePlaceholderText",
        defaultMessage: "Event Name",
    },
    generalInfoBucketTitle: {
        id: "eventModule.eventInfo.generalInfoBucketTitle",
        defaultMessage: "General Information",
    },
    timingInfoBucketTitle: {
        id: "eventModule.eventInfo.timingInfoBucketTitle",
        defaultMessage: "Timing Info",
    },
    seasonPlaceholderText: {
        id: "eventModule.eventInfo.seasonPlaceholderText",
        defaultMessage: "Season",
    },
    cropCyclePlaceholderText: {
        id: "eventModule.eventInfo.cropCyclePlaceholderText",
        defaultMessage: "Crop Cycle",
    },
    startDatePlaceholderText: {
        id: "eventModule.eventInfo.startDatePlaceholderText",
        defaultMessage: "Start Date",
    },
    endDatePlaceholderText: {
        id: "eventModule.eventInfo.endDatePlaceholderText",
        defaultMessage: "End Date",
    },
    hasTimeLabel: {
        id: "eventModule.eventInfo.hasTimeLabel",
        defaultMessage: "Include Time",
    },
    weatherInfoBucketTitle: {
        id: "eventModule.eventInfo.weatherInfoBucketTitle",
        defaultMessage: "Weather Conditions",
    },
    weatherPlaceholderText: {
        id: "eventModule.eventInfo.weatherPlaceholderText",
        defaultMessage: "General Weather",
    },
    tempPlaceholderText: {
        id: "eventModule.eventInfo.tempPlaceholderText",
        defaultMessage: "Temp (F)",
    },
    humidityPlaceholderText: {
        id: "eventModule.eventInfo.humidity",
        defaultMessage: "Humidity (%)",
    },
    windDirectionPlaceholderText: {
        id: "eventModule.eventInfo.windDirection",
        defaultMessage: "Wind Direction",
    },
    windSpeedPlaceholderText: {
        id: "eventModule.eventInfo.windSpeedPlaceholderText",
        defaultMessage: "Wind Speed (mph)",
    },
    GDDPlaceholderText: {
        id: "eventModule.eventInfo.GDDPlaceholderText",
        defaultMessage: "GDD",
    },
    precipitationPlaceholderText: {
        id: "eventModule.eventInfo.precipitationPlaceholderText",
        defaultMessage: "Precipitation (in)",
    },
    cloudCoverPlaceholderText: {
        id: "eventModule.eventInfo.cloudCoverPlaceholderText",
        defaultMessage: "Cloud Cover (%)",
    },
    soilInfoBucketTitle: {
        id: "eventModule.eventInfo.soilInfoBucketTitle",
        defaultMessage: "Soil Conditions",
    },
    soilCondPlaceholderText: {
        id: "eventModule.eventInfo.soilCondPlaceholderText",
        defaultMessage: "General",
    },
    soilTempPlaceholderText: {
        id: "eventModule.eventInfo.soilTempPlaceholderText",
        defaultMessage: "Temp (F)",
    },
    soilCompactionPlaceholderText: {
        id: "eventModule.eventInfo.soilCompactionPlaceholderText",
        defaultMessage: "Soil Compaction",
    },
    peopleBucketTitle: {
        id: "eventModule.eventInfo.peopleBucketTitle",
        defaultMessage: "People",
    },
    findPeoplePlaceholderText: {
        id: "eventModule.eventInfo.findPeoplePlaceholderText",
        defaultMessage: "Find People",
    },
    generalNotesBucketTitle: {
        id: "eventModule.eventInfo.generalNotesBucketTitle",
        defaultMessage: "General Notes",
    },
    notesPlaceholderText: {
        id: "eventModule.eventInfo.notesPlaceholderText",
        defaultMessage: "Notes",
    },
    equipmentBucketTitle: {
        id: "eventModule.eventInfo.equipmentBucketTitle",
        defaultMessage: "Equipment",
    },
    findEquipmentPlaceholderText: {
        id: "eventModule.eventInfo.findEquipmentPlaceholderText",
        defaultMessage: "Find Equipment",
    },
});

const {
    getPickListCode,
    PICKLIST_CROPPING_SEASON,
    PICKLIST_CROPPING_SEASON_CYCLE,
    PICKLIST_GENERAL_WEATHER,
    PICKLIST_DIRECTION,
    PICKLIST_SOIL_CONDITION,
    PICKLIST_DENSITY_RATING,
} = picklistNames;

const errorCodeToMessageIdSetMap = new Map([
    [80, messages.seasonPlaceholderText], // ErrorCode.AgEventCropSeasonRequired
    [81, messages.startDatePlaceholderText], // ErrorCode.AgEventStartDatetimeRequired
    [2083, null], // ErrorCode.AgEventFieldRequired
    [2647, null], // ErrorCode.FieldBoundaryGuidRequired
    [2330, [messages.eventNamePlaceholderText, messages.startDatePlaceholderText]], // ErrorCode.AgEventTypeDateNameDuplicated
    [2380, null], // ErrorCode.EventAreaListEmpty
    [2383, null], // ErrorCode.EventAreaPolygonNotFound
    [2814, null], // ErrorCodes.FieldBoundaryMissingForWholeFieldZone
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};
const eventCreationType = "Imported";

export class EventGeneralForm_ extends PureComponent {
    static propTypes = {
        eventDetails: PropTypes.object,
        intl: intlShape.isRequired,
        onFetchPicklists: PropTypes.func.isRequired,
        onUpdateEventDetails: PropTypes.func.isRequired,
        picklistOptionsCroppingSeason: PropTypes.arrayOf(optionPropType),
        picklistOptionsCroppingSeasonCycle: PropTypes.arrayOf(optionPropType),
        picklistOptionsGeneralWeather: PropTypes.arrayOf(optionPropType),
        picklistOptionsDirection: PropTypes.arrayOf(optionPropType),
        picklistOptionsSoilCondition: PropTypes.arrayOf(optionPropType),
        picklistOptionsSoilCompaction: PropTypes.arrayOf(optionPropType),
        saveEventDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        userGuid: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            includeTime: false,
            haveSetDefaultValues: false,
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    _addPerson(person) {
        const { eventDetails } = this.props;
        const { personList } = eventDetails;

        if (
            person &&
            !personList.some((listPerson) => listPerson.personGuid === person.personGuid)
        ) {
            this._updateEventDetails({
                personList: [...eventDetails.personList, person],
            });
        }
    }

    _addEquipment(equipment) {
        const { eventDetails } = this.props;
        const { equipmentList } = eventDetails;
        if (
            equipment &&
            !equipmentList.some(
                (listEquipment) =>
                    listEquipment.equipmentProfileGuid === equipment.equipmentProfileGuid
            )
        ) {
            this._updateEventDetails({
                equipmentList: [...eventDetails.equipmentList, equipment],
            });
        }
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _getTimingInfoPart() {
        const { eventDetails, picklistOptionsCroppingSeason, picklistOptionsCroppingSeasonCycle } =
            this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;

        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.timingInfoBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <SelectInput
                        required
                        optionIsHiddenKey={ACTIVE_YN}
                        clearable={false}
                        containerClassNames={[
                            {
                                "select-form-input-error": errorMessagePlaceholderSet.has(
                                    messages.seasonPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) => this._updateEventDetails({ croppingSeasonGuid: v })}
                        options={picklistOptionsCroppingSeason}
                        placeholderText={formatMessage(messages.seasonPlaceholderText)}
                        value={eventDetails.croppingSeasonGuid}
                    />
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(v) =>
                            this._updateEventDetails({
                                croppingSeasonCycleGuid: v,
                            })
                        }
                        options={picklistOptionsCroppingSeasonCycle}
                        placeholderText={formatMessage(messages.cropCyclePlaceholderText)}
                        value={eventDetails.croppingSeasonCycleGuid}
                    />
                </div>
                <div className="input-row">
                    <DateInput
                        required
                        clearDisabled
                        containerClassNames={[
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.startDatePlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) =>
                            this._updateEventDetails({
                                momentStartDate: moment(v),
                                momentStartTime: moment(v),
                            })
                        }
                        openDirection="left"
                        timeFormat={this.state.includeTime ? "h:mm a" : false}
                        placeholderText={formatMessage(messages.startDatePlaceholderText)}
                        value={eventDetails.momentStartDate}
                    />
                    <DateInput
                        required
                        clearDisabled
                        onChange={(v) =>
                            this._updateEventDetails({
                                momentEndDate: moment(v),
                                momentEndTime: moment(v),
                            })
                        }
                        openDirection="right"
                        timeFormat={this.state.includeTime ? "h:mm a" : false}
                        placeholderText={formatMessage(messages.endDatePlaceholderText)}
                        value={eventDetails.momentEndDate}
                    />
                </div>
                <Checkbox
                    label={formatMessage(messages.hasTimeLabel)}
                    value={this.state.includeTime}
                    onChange={(evt, v) => this.setState({ includeTime: v })}
                />
            </Bucket>
        );
    }

    _getWeatherInfoPart() {
        const { eventDetails, picklistOptionsDirection, picklistOptionsGeneralWeather } =
            this.props;

        const { formatMessage } = this.props.intl;

        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.weatherInfoBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(v) => this._updateEventDetails({ generalWeatherGuid: v })}
                        options={picklistOptionsGeneralWeather}
                        placeholderText={formatMessage(messages.weatherPlaceholderText)}
                        value={eventDetails.generalWeatherGuid}
                    />
                    <NumericInput
                        precision={5}
                        scale={2}
                        allowNegatives={true}
                        containerClassNames={["temp-input"]}
                        value={eventDetails.airTemperature}
                        onChange={(v) => this._updateEventDetails({ airTemperature: v })}
                        placeholderText={formatMessage(messages.tempPlaceholderText)}
                    />
                    {/* Extra div so that the margin is included in the width calculation for flex-grow: 1 */}
                    <div>
                        <NumericInput
                            precision={5}
                            scale={2}
                            value={eventDetails.humidityPercentage}
                            onChange={(strVal, formattedVal, numVal) =>
                                this._updateEventDetails({
                                    humidityPercentage: numVal,
                                })
                            }
                            placeholderText={formatMessage(messages.humidityPlaceholderText)}
                        />
                    </div>
                </div>
                <div className="input-row">
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(v) => this._updateEventDetails({ directionGuid: v })}
                        options={picklistOptionsDirection}
                        placeholderText={formatMessage(messages.windDirectionPlaceholderText)}
                        value={eventDetails.directionGuid}
                    />
                    <div>
                        <NumericInput
                            precision={5}
                            scale={2}
                            value={eventDetails.windSpeed}
                            onChange={(strVal, formattedVal, numVal) =>
                                this._updateEventDetails({ windSpeed: numVal })
                            }
                            placeholderText={formatMessage(messages.windSpeedPlaceholderText)}
                        />
                    </div>
                    <div>
                        <NumericInput
                            precision={6}
                            scale={2}
                            value={eventDetails.cropGrowingDays}
                            onChange={(strVal, formattedVal, numVal) =>
                                this._updateEventDetails({
                                    cropGrowingDays: numVal,
                                })
                            }
                            placeholderText={formatMessage(messages.GDDPlaceholderText)}
                        />
                    </div>
                </div>
                <div className="input-row">
                    <NumericInput
                        precision={5}
                        scale={2}
                        value={eventDetails.precipitation}
                        onChange={(strVal, formattedVal, numVal) =>
                            this._updateEventDetails({ precipitation: numVal })
                        }
                        placeholderText={formatMessage(messages.precipitationPlaceholderText)}
                    />
                    <NumericInput
                        precision={5}
                        scale={2}
                        value={eventDetails.cloudCoverPercentage}
                        onChange={(strVal, formattedVal, numVal) =>
                            this._updateEventDetails({
                                cloudCoverPercentage: numVal,
                            })
                        }
                        placeholderText={formatMessage(messages.cloudCoverPlaceholderText)}
                    />
                </div>
            </Bucket>
        );
    }

    _getSoilConditionPart() {
        const { eventDetails, picklistOptionsSoilCondition, picklistOptionsSoilCompaction } =
            this.props;

        const { formatMessage } = this.props.intl;

        return (
            <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                <BucketHeader>{formatMessage(messages.soilInfoBucketTitle)}</BucketHeader>
                <div className="input-row">
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(v) => this._updateEventDetails({ soilConditionGuid: v })}
                        options={picklistOptionsSoilCondition}
                        placeholderText={formatMessage(messages.soilCondPlaceholderText)}
                        value={eventDetails.soilConditionGuid}
                    />
                    <NumericInput
                        precision={5}
                        scale={2}
                        allowNegatives={true}
                        containerClassNames={["temp-input"]}
                        onChange={(strVal, formattedVal, numVal) =>
                            this._updateEventDetails({
                                soilTemperature: numVal,
                            })
                        }
                        placeholderText={formatMessage(messages.soilTempPlaceholderText)}
                        value={eventDetails.soilTemperature}
                    />
                    <SelectInput
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(v) => this._updateEventDetails({ densityRatingGuid: v })}
                        options={picklistOptionsSoilCompaction}
                        placeholderText={formatMessage(messages.soilCompactionPlaceholderText)}
                        value={eventDetails.densityRatingGuid}
                    />
                </div>
            </Bucket>
        );
    }

    _removePerson(index) {
        const { eventDetails } = this.props;
        const { personList } = eventDetails;
        const newPersonList = [...personList];
        newPersonList.splice(index, 1);

        this._updateEventDetails({ personList: newPersonList });
    }

    _removeEquipment(index) {
        const { eventDetails } = this.props;
        const { equipmentList } = eventDetails;
        const newEquipmentList = [...equipmentList];
        newEquipmentList.splice(index, 1);
        this._updateEventDetails({ equipmentList: newEquipmentList });
    }

    _setDefaultCroppingSeason() {
        console.assert(
            this.props.eventDetails != null && this.props.picklistOptionsCroppingSeason != null
        );
        if (!this.props.eventDetails.croppingSeasonGuid) {
            const curYearStr = String(new Date().getFullYear());
            const defaultOption = this.props.picklistOptionsCroppingSeason.find(
                (option) => option.label === curYearStr
            );
            this._updateEventDetails({
                croppingSeasonGuid: defaultOption && defaultOption.value,
            });
        }
    }

    _updateEventDetails(newProps) {
        this.props.onUpdateEventDetails(newProps);
    }

    _updateSoilCondition(newProps) {
        const agEventSoilCondition = {
            ...this.props.eventDetails.agEventSoilCondition,
            ...newProps,
        };
        this._updateEventDetails({ agEventSoilCondition });
    }

    UNSAFE_componentWillMount() {
        const fetchPicklistNames = [
            PICKLIST_CROPPING_SEASON,
            PICKLIST_CROPPING_SEASON_CYCLE,
            PICKLIST_GENERAL_WEATHER,
            PICKLIST_DIRECTION,
            PICKLIST_SOIL_CONDITION,
            PICKLIST_DENSITY_RATING,
        ];

        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = picklistNames.getPickListCode(key);
            return accum;
        }, {});

        this.props.onFetchPicklists(picklistFetchArgObj);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newEventDetails = newProps.eventDetails !== this.props.eventDetails;
        const newSaveEventDetailsErrorCodeList =
            newProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList;
        const newPicklistOptionsCroppingSeason =
            newProps.picklistOptionsCroppingSeason !== this.props.picklistOptionsCroppingSeason;
        if (
            !newEventDetails &&
            !newSaveEventDetailsErrorCodeList &&
            !newPicklistOptionsCroppingSeason
        ) {
            return;
        }

        const newState = {};
        if (newSaveEventDetailsErrorCodeList) {
            newState.errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
        }

        const setDefaultValues =
            !this.state.haveSetDefaultValues &&
            newProps.eventDetails != null &&
            newProps.picklistOptionsCroppingSeason.length > 0;

        if (setDefaultValues) {
            newState.haveSetDefaultValues = true;
        }

        this.setState(newState, () => {
            if (setDefaultValues) {
                this._setDefaultCroppingSeason();
            }
        });
    }

    getAutoSearchList = (userGuid, searchText) => {
        const { eventDetails } = this.props;
        const agEventTypeGuidToNameMap = this._getEventTypeMap(eventDetails);
        return EquipmentProfileAPI.searchEquipmentWithName(userGuid, {
            agEventTransactionTypeName: Array.from(agEventTypeGuidToNameMap.values()),
            searchText,
        });
    };

    getAgEventTransactionTypeNameToFormMap() {
        if (this._agEventTransactionTypeNameToFormMap == null) {
            this._agEventTransactionTypeNameToFormMap = getAgEventTransactionTypeNameToFormMap();
        }
        return this._agEventTransactionTypeNameToFormMap;
    }

    _getEventTypeMap(eventDetails) {
        const agEventTypeGuidToNameMap = new Map(
            eventDetails.agEventTypeList
                .filter((agEventInfo) => {
                    const haveForm = this.getAgEventTransactionTypeNameToFormMap().has(
                        agEventInfo.agEventTransactionTypeName
                    );
                    if (!haveForm) {
                        console.warn(
                            "Ignoring unsupported `agEvent` type",
                            agEventInfo.typeName,
                            this.getAgEventTransactionTypeNameToFormMap()
                        );
                    }
                    return haveForm;
                })
                .map((agEventTypeInfo) => [
                    agEventTypeInfo.agEventTransactionTypeGuid,
                    agEventTypeInfo.agEventTransactionTypeName,
                ])
        );
        return agEventTypeGuidToNameMap;
    }

    getToolTip(equipment) {
        let tooltiptext = `${equipment.equipmentParentTypeName} - ${equipment.equipmentType} - ${equipment.equipmentName} - ${equipment.ownerOperatorName}`;
        if (equipment.controllerId.length > 0) {
            tooltiptext = tooltiptext + " - " + equipment.controllerId;
        }
        return tooltiptext;
    }

    render() {
        const { eventDetails, userGuid } = this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;
        if (!eventDetails) {
            return null;
        }

        return (
            <div className="rec-event-info-form event-general-form">
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.generalInfoBucketTitle)}</BucketHeader>
                    <div className="input-row half">
                        <TextInput
                            maxLength={50}
                            autoFocus={!eventDetails.eventName}
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        messages.eventNamePlaceholderText
                                    ),
                                },
                            ]}
                            value={eventDetails.eventName}
                            onChange={(v) => this._updateEventDetails({ eventName: v })}
                            placeholderText={formatMessage(messages.eventNamePlaceholderText)}
                        />
                    </div>
                </Bucket>
                {this._getTimingInfoPart()}
                {this._getWeatherInfoPart()}
                {this._getSoilConditionPart()}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.peopleBucketTitle)}</BucketHeader>
                    <div className="input-row">
                        <AutoSearch
                            clearOnSelection={true}
                            required={false}
                            itemList={[]}
                            maxLength={100}
                            initialFilterStr=""
                            onSelection={(p) => this._addPerson(p)}
                            getAutoSearchList={PersonAPI.searchPersonWithUser}
                            userGuid={userGuid}
                            placeholderText={formatMessage(messages.findPeoplePlaceholderText)}
                            keyProp="personGuid"
                            nameProp="personName"
                            secondaryPropList={["city", "stateAbbreviation", "jobTitleName"]}
                            excludedValues={eventDetails.personList.map(
                                (person) => person.personGuid
                            )}
                        />
                    </div>
                    <div className="input-row">
                        <div className="person-list">
                            {eventDetails.personList.map((person, index) => {
                                return (
                                    <div key={`person-${index}`} className="person-item">
                                        <div
                                            className="trashcan-icon-container"
                                            onClick={() => {
                                                this._removePerson(index);
                                            }}
                                        >
                                            <TrashcanIcon />
                                        </div>
                                        <div className="person-info">{`${person.personName}`}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Bucket>
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.equipmentBucketTitle)}</BucketHeader>
                    {eventDetails.creationType !== eventCreationType ||
                    eventDetails.canEditEquipment ? (
                        <div className="input-row">
                            <AutoSearch
                                clearOnSelection={true}
                                required={false}
                                itemList={[]}
                                maxLength={100}
                                initialFilterStr=""
                                onSelection={(p) => this._addEquipment(p)}
                                getAutoSearchList={this.getAutoSearchList}
                                userGuid={userGuid}
                                placeholderText={formatMessage(
                                    messages.findEquipmentPlaceholderText
                                )}
                                keyProp="equipmentProfileGuid"
                                nameProp="equipmentName"
                                secondaryPropList={[
                                    "equipmentParentTypeName",
                                    "equipmentType",
                                    "ownerOperatorName",
                                ]}
                                excludedValues={eventDetails.equipmentList.map(
                                    (e) => e.equipmentProfileGuid
                                )}
                            />
                        </div>
                    ) : null}
                    <div className="input-row">
                        <div className="equipment-list">
                            {eventDetails.equipmentList.map((equipment, index) => {
                                return (
                                    <div
                                        key={`equipment-${index}`}
                                        className="equipment-item"
                                        title={this.getToolTip(equipment)}
                                    >
                                        {eventDetails.creationType !== eventCreationType ||
                                        eventDetails.canEditEquipment ? (
                                            <div
                                                className="equipment-trashcan-icon-container"
                                                onClick={() => {
                                                    this._removeEquipment(index);
                                                }}
                                            >
                                                <TrashcanIcon />
                                            </div>
                                        ) : (
                                            <div className="empty-container"></div>
                                        )}
                                        <div className="equipment-info">
                                            {equipment.equipmentParentTypeName + " - "}{" "}
                                            {equipment.equipmentName}{" "}
                                            {equipment.controllerId.length > 0
                                                ? " - " + equipment.controllerId
                                                : ""}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Bucket>
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.generalNotesBucketTitle)}</BucketHeader>
                    <TextArea
                        value={eventDetails.notes}
                        onChange={(v) => this._updateEventDetails({ notes: v })}
                        placeholderText={formatMessage(messages.notesPlaceholderText)}
                        maxLength={1000}
                    />
                </Bucket>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onUpdateEventDetails: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateEventDetails(fieldGuid, newProps)),
});

const mapStateToProps = (state) => {
    const { eventSummary, isLoading } = getModuleState(state);
    const { fieldGuidToEventDetails, saveEventDetailsErrorCodeList } =
        eventsSelectors.getModuleState(state);
    const { batchFieldGuid } = recsEventsSelectors.getZonesState(state);

    const fieldGuid =
        batchFieldGuid != null
            ? batchFieldGuid
            : eventSummary != null
            ? eventSummary.fieldGuid
            : null;
    const eventDetails = !isLoading ? fieldGuidToEventDetails.get(fieldGuid) : null;

    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );
    const picklistOptionsCroppingSeasonCycle = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON_CYCLE)
    );
    const picklistOptionsGeneralWeather = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_GENERAL_WEATHER)
    );
    const picklistOptionsDirection = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_DIRECTION)
    );
    const picklistOptionsSoilCondition = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_SOIL_CONDITION)
    );
    const picklistOptionsSoilCompaction = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_DENSITY_RATING)
    );

    return {
        eventDetails,
        fieldGuid,
        picklistOptionsCroppingSeason,
        picklistOptionsCroppingSeasonCycle,
        picklistOptionsGeneralWeather,
        picklistOptionsDirection,
        picklistOptionsSoilCondition,
        picklistOptionsSoilCompaction,
        saveEventDetailsErrorCodeList,
        userGuid: getTheUserGuid(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateEventDetails: (newProps) =>
        dispatchProps.onUpdateEventDetails(stateProps.fieldGuid, newProps),
});

export const EventGeneralForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(EventGeneralForm_));
