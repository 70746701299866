import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

const EventAreaIcon_ = (
    <g>
        <path d="M37.3,28.2V6.3H15.5V0H0v15.5h6.2v21.9h22v6.3h15.5V28.2H37.3z M3,12.5V3h9.5v9.5C12.5,12.5,3,12.5,3,12.5zM9.2,34.4V15.5h6.3V9.3h18.8v18.9h-6.1v6.2L9.2,34.4z M40.7,40.6h-9.5v-9.5h9.5V40.6z" />
    </g>
);

export const EventAreaIcon = withSvgIcon(EventAreaIcon_, "0 0 43.7 43.7");
