import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { Bucket, BucketHeader, Loader, NumericInput, SelectInput, TextArea } from "~/core";
import {
    ACTIVE_YN,
    picklistNames,
    actions as picklistActions,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { selectors as unitSelectors, unitNames } from "~/core/units";

import { AgEventAPI, CropAPI, ImageUtilsAPI } from "@ai360/core";
import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";

import { keywords } from "../../../../keywords";
import { getSetValuesForErrorCodeList } from "../../../../../common/validation-utils";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { ScoutingIcon } from "../icons";
import { ScoutingDetail } from "./scouting-detail";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as utils from "./event-scouting-utils";
import {
    ScoutingTemplate,
    ScoutingTemplateCrop,
    ScoutingTemplateObservation,
} from "~/recs-events/events/model";

import * as eventInfoSelectors from "../../selectors";
import { fetchedDropdownData } from "../../actions";

import { messages } from "./i18n-messages";
import "../../../../../common/rec-event-info/rec-event-info.css";
import "./event-scouting-form.css";
import ScoutingSitePhotoPanel from "./scouting-site-photo-panel";
import { actions as notificationActions } from "~/notifications";
import { IErrorCodeList } from "./interfaces";
export const formLabelMessage = messages.scoutingFormLabelText;
export const formLabelIcon = ScoutingIcon;

const eventScoutingCropHeightUnitRequired = 926;
const eventScoutingCropPopulationUnitRequired = 927;

const errorCodeToMessageIdSetMap = new Map([
    [321, messages.cropError], // ErrorCode.EventPlantingCropRequired
    [328, messages.observationRequiredError], // ErrorCode.EventScoutingObservationRequired
]);

const { getUnitCode, UNIT_LENGTH, UNIT_CROP_POPULATION } = unitNames;

export const errorCodesApply = (errorCodeList: IErrorCodeList): boolean => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

interface IScoutingFormProps {
    agEventModel: any;
    agEventTransactionTypeGuid: string;
    brandOrgData: any[];
    cropData: any[];
    cropPurpose: any[];
    currentEventAreaId: number;
    eventAreaIds: number[];
    intl: intlShape;
    errorMessagePlaceholderSet: any;
    errorMessagesForEventArea: any;
    fetchDropdownData: (dropdowns: any) => void;
    fetchGrowthStageData: (newProps: any) => void;
    fetchPicklistData: (picklists: any) => void;
    fetchApplyTemplate: (
        cropGuid: string,
        cropPurposeGuid: string,
        currentZoneOnly: boolean,
        overwriteValues: boolean
    ) => void;
    fieldGuid: string;
    growthStageData: any[];
    isLoading: boolean;
    numericRating: any[];
    onUpdateAgEventModel: (
        fieldGuid: string,
        id: number,
        agEventTransactionTypeGuid: string,
        newProps: any
    ) => void;
    onUpdateCurrentAgEventAreaAgEventModel: (newProps: any) => void;
    originalScoutingDetailList: Map<string, AgEventAPI.IScoutingDetail[]>;
    saveEventDetailsErrorCodeList: any;
    saveEventDetailsErrorModel: any;
    setOriginalScoutingDetails: (
        agEventGuid: string,
        scoutingDetailList: Array<AgEventAPI.IScoutingDetail>
    ) => void;
    scoutingTemplate: any;
    unitCropPopulation: any[];
    unitLength: any[];
    updateScoutingTemplate: (newProps: any) => void;
    integrateCrop: (cropGuid: string, cropPurposeGuid: string) => void;
    varietyHybridData: any[];
    onPushToasterMessage: (message: any, type: any) => void;
    scoutingPhotoPresignedUrlMap: Map<string, string>;
    index: number;
    onChange: (type: string, value: any, index: number, cb?: any) => void;
}
interface IScoutingFormState {
    errorCodesForEventArea: any;
    errorMessagePlaceholderSet: Set<string>;
    originalCropGuid: string;
    originalCropPurposeGuid: string;
    templateDetailsApplied: boolean;
    templateApplyIsLoading: boolean;
    photoMediaTypeGuid: string;
}

export class ScoutingForm_ extends Component<IScoutingFormProps, IScoutingFormState> {
    constructor(props: IScoutingFormProps) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            errorCodesForEventArea: new Map(),
            originalCropGuid: props.agEventModel.cropGuid,
            originalCropPurposeGuid: props.agEventModel.cropPurposeGuid,
            templateDetailsApplied: false,
            templateApplyIsLoading: false,
            photoMediaTypeGuid: null,
        };
    }

    private _applyCurrentTemplateToZone(nextProps) {
        const cropInfoToBeApplied =
            this._cropInfoIsEmpty(nextProps.agEventModel, false) &&
            !this._cropInfoIsEmpty(nextProps.scoutingTemplate.cropInfo, true);
        const obsToBeApplied =
            nextProps.agEventModel.scoutingDetailList.length < 1 &&
            nextProps.scoutingTemplate.observationInfo.length > 0 &&
            !nextProps.agEventModel.noIssuesFound;
        return Boolean(
            (cropInfoToBeApplied || obsToBeApplied) &&
                this._matchingTemplate(nextProps.agEventModel, nextProps.scoutingTemplate)
        );
    }

    private _updateSiteWithTemplateSections(
        agEventModel: AgEventAPI.IAgEventScouting,
        scoutingTemplate: ScoutingTemplate
    ) {
        const cropInfoToBeApplied =
            this._cropInfoIsEmpty(agEventModel, false) &&
            !this._cropInfoIsEmpty(scoutingTemplate.cropInfo, true);
        const obsToBeApplied =
            agEventModel.scoutingDetailList.length < 1 &&
            scoutingTemplate.observationInfo.length > 0;

        if (!cropInfoToBeApplied && !obsToBeApplied) {
            return;
        }

        const templateCropValuesApplied = cropInfoToBeApplied
            ? utils.applyCropTemplateValues(scoutingTemplate, agEventModel, true)
            : agEventModel;
        const templateScoutingDetailValuesApplied = obsToBeApplied
            ? this._integrateTemplateDetailsValues(scoutingTemplate.observationInfo)
            : agEventModel.scoutingDetailList;

        this.setState(
            {
                templateApplyIsLoading: true,
            },
            () => {
                this._updateAgEvent({
                    ...templateCropValuesApplied,
                    scoutingDetailList: templateScoutingDetailValuesApplied,
                });
            }
        );
    }

    private _cropInfoIsEmpty(
        model: AgEventAPI.IAgEventScouting | ScoutingTemplateCrop,
        isTemplate
    ) {
        //templates have the properties using "unit" but agEventModel has "units"
        const cropUnit = isTemplate ? "cropHeightUnitGuid" : "cropHeightUnitsGuid";
        const cropPopulation = isTemplate ? "cropPopulationUnitGuid" : "cropPopulationUnitsGuid";
        return Boolean(
            !model.brandOrganizationGuid &&
                !model.varietyHybridGuid &&
                !model.cropGrowthStageGuid &&
                !model.cropConditionGuid &&
                !model.cropInjuryRatingGuid &&
                !model.daysAfterCropDamage &&
                !model.cropHeight &&
                !model[cropUnit] &&
                !model.cropPopulation &&
                !model[cropPopulation] &&
                !model.yieldPercentLoss &&
                !model.standPercentLoss &&
                !model.leafPercentLoss
        );
    }

    private _integrateTemplateDetailsValues(
        newScoutingTemplateObservationList: Array<ScoutingTemplateObservation>
    ) {
        return newScoutingTemplateObservationList.map((observationItem) =>
            utils.applyTemplateNewObsValues(observationItem, null, true)
        );
    }

    private _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    private _matchingTemplate(
        agEventModel: AgEventAPI.IAgEventScouting,
        scoutingTemplate: ScoutingTemplate
    ) {
        return (
            agEventModel.cropGuid === scoutingTemplate.cropGuid &&
            (agEventModel.cropPurposeGuid || "") === (scoutingTemplate.cropPurposeGuid || "")
        );
    }

    private _refreshBrandVarietyData(agEventModel) {
        const dropDownRequest = {};
        if (agEventModel.cropGuid) {
            Object.assign(dropDownRequest, {
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: agEventModel.cropGuid,
                },
            });
        }
        if (agEventModel.brandOrganizationGuid) {
            Object.assign(dropDownRequest, {
                varietyHybridData: {
                    url: CropAPI.REQUEST_VARIETY_HYBRID,
                    model: {
                        CropID: agEventModel.cropGuid,
                        BrandOrganization: agEventModel.brandOrganizationGuid,
                    },
                },
            });
        }
        if (Object.hasOwn(dropDownRequest, "brandOrgData")) {
            this.props.fetchDropdownData(dropDownRequest);
        }
    }

    private _updateAgEvent(newProps) {
        this.props.onUpdateCurrentAgEventAreaAgEventModel(newProps);
    }

    private _onBrandOrganizationChange(newProps) {
        const { agEventModel } = this.props;

        this.props.fetchDropdownData({
            varietyHybridData: {
                url: CropAPI.REQUEST_VARIETY_HYBRID,
                model: {
                    CropID: agEventModel.cropGuid,
                    BrandOrganization: newProps.brandOrganizationGuid,
                },
            },
        });
        this._updateAgEvent(newProps);
    }

    private _onCropChange = (newProps) => {
        const { agEventModel, fetchGrowthStageData, fetchApplyTemplate, integrateCrop } =
            this.props;
        if (newProps.cropGuid) {
            //in cases where the cropGuid is cleared do not fetch the growth stage data
            fetchGrowthStageData(newProps.cropGuid);
        }
        //Upon crop change, apply to current zone only, and overwrite values
        if (Object.hasOwn(newProps, "cropGuid")) {
            integrateCrop(newProps.cropGuid, null);
            fetchApplyTemplate(newProps.cropGuid, null, true, true);
            this.props.fetchDropdownData({
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: newProps.cropGuid,
                },
            });
        } else if (Object.hasOwn(newProps, "cropPurposeGuid") && agEventModel.cropGuid) {
            integrateCrop(agEventModel.cropGuid, newProps.cropPurposeGuid);
            fetchApplyTemplate(agEventModel.cropGuid, newProps.cropPurposeGuid, true, true);
        }
    };

    private processSite(nextProps) {
        if (
            nextProps.agEventModel.cropGuid !== this.props.agEventModel.cropGuid ||
            nextProps.agEventModel.cropPurposeGuid !== this.props.agEventModel.cropPurposeGuid
        ) {
            //selection changed to an existing zone with a different crop
            this.props.fetchApplyTemplate(
                nextProps.agEventModel.cropGuid,
                nextProps.agEventModel.cropPurposeGuid,
                true,
                false
            );
            this.props.fetchGrowthStageData(nextProps.agEventModel.cropGuid);
        } else {
            //selection matches current template
            const templateObsApplied = utils.applyMissingTemplateObservations(
                nextProps.scoutingTemplate.observationInfo,
                [...nextProps.agEventModel.scoutingDetailList],
                nextProps.agEventModel.noIssuesFound ? false : true
            );
            this._updateAgEvent({ scoutingDetailList: templateObsApplied });
        }
    }

    public componentDidMount(): void {
        this.props.fetchDropdownData({
            cropData: { url: CropAPI.REQUEST_CROP, model: keywords.name },
        });
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_CROP_PURPOSE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROP_PURPOSE
            ),
        });

        this._refreshBrandVarietyData(this.props.agEventModel);

        if (this.props.agEventModel.cropGuid) {
            this.props.fetchGrowthStageData(this.props.agEventModel.cropGuid);
            this.props.fetchApplyTemplate(
                this.props.agEventModel.cropGuid,
                this.props.agEventModel.cropPurposeGuid,
                true,
                false
            );
            this.props.setOriginalScoutingDetails(
                this.props.agEventModel.agEventGuid,
                this.props.agEventModel.scoutingDetailList
            );
        }

        ImageUtilsAPI.getPhotoMediaTypeGuid().then((guid) => {
            this.setState({ photoMediaTypeGuid: guid });
        });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IScoutingFormProps): void {
        if (nextProps.currentEventAreaId !== this.props.currentEventAreaId) {
            //zone selection change
            this._refreshBrandVarietyData(nextProps.agEventModel);
            if (this._applyCurrentTemplateToZone(nextProps)) {
                //selection changed to a new zone that has not had a template applied or is missing part of a matching template
                this._updateSiteWithTemplateSections(
                    nextProps.agEventModel,
                    nextProps.scoutingTemplate
                );
            } else {
                //Site already contains observations, so fetch matching template if necessary and apply missing observations
                this.processSite(nextProps);
            }

            if (
                Boolean(nextProps.agEventModel.agEventGuid) &&
                !nextProps.originalScoutingDetailList.has(nextProps.agEventModel.agEventGuid)
            ) {
                this.props.setOriginalScoutingDetails(
                    nextProps.agEventModel.agEventGuid,
                    nextProps.agEventModel.scoutingDetailList.filter(
                        (d) => d.eventScoutingDetailGuid
                    )
                );
            }
        }
        if (nextProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(nextProps);
            this.setState({ errorMessagePlaceholderSet });
        }
        if (nextProps.saveEventDetailsErrorModel) {
            const errorCodesForEventArea = new Map();
            const eventAreaId = nextProps.currentEventAreaId;
            for (const code in nextProps.saveEventDetailsErrorModel) {
                const codesArray = nextProps.saveEventDetailsErrorModel[code];
                // eslint-disable-next-line no-loop-func
                codesArray.forEach((c) => {
                    // Each token associated with a code will either be a single digit
                    // representing an Event Area Id (in the case of crop-level validation
                    // errors) or a digit representing an Event Area Id and a comma-delimited
                    // list of observation indicies, delimited by a semi-colon (in the case of
                    // observation-level errors).
                    if (c.includes(":")) {
                        const id = c.split(":")[0];
                        if (id === eventAreaId.toString()) {
                            const obsIndiciesString = c.split(":")[1];
                            const obsIndiciesArray = obsIndiciesString.split(",");
                            errorCodesForEventArea.set(Number(code), obsIndiciesArray); // store indicies for sub-component to use
                        }
                    } else {
                        if (c === eventAreaId.toString()) {
                            errorCodesForEventArea.set(Number(code), undefined); // only need the code to be present
                        }
                    }
                });
            }

            this.setState({ errorCodesForEventArea });
        }
        if (
            this.state.templateApplyIsLoading &&
            nextProps.agEventModel &&
            nextProps.scoutingTemplate &&
            (!this._cropInfoIsEmpty(nextProps.agEventModel, false) ||
                this._cropInfoIsEmpty(nextProps.scoutingTemplate.cropInfo, true) ||
                nextProps.agEventModel.scoutingDetailList.length !==
                    this.props.agEventModel.scoutingDetailList.length)
        ) {
            //scouting template values have been applied
            this.setState({ templateApplyIsLoading: false });
        }
        if (
            nextProps.agEventModel &&
            nextProps.agEventModel.brandOrganizationGuid &&
            nextProps.agEventModel.brandOrganizationGuid !==
                this.props.agEventModel.brandOrganizationGuid
        ) {
            this._refreshBrandVarietyData(nextProps.agEventModel);
        }
    }

    private _getCropSection() {
        const { formatMessage } = this.props.intl;
        const {
            agEventModel,
            brandOrgData,
            cropData,
            cropPurpose,
            growthStageData,
            numericRating,
            scoutingTemplate,
            unitCropPopulation,
            unitLength,
            varietyHybridData,
            onPushToasterMessage,
            scoutingPhotoPresignedUrlMap,
            index,
        } = this.props;
        const templateMatch =
            agEventModel.cropGuid === scoutingTemplate.cropGuid &&
            (agEventModel.cropPurposeGuid || "") === (scoutingTemplate.cropPurposeGuid || "");
        const { errorMessagePlaceholderSet, errorCodesForEventArea, photoMediaTypeGuid } =
            this.state;
        const hideBrandOrganization =
            !agEventModel.brandOrganizationGuid &&
            (!scoutingTemplate.cropGuid ||
                !templateMatch ||
                !scoutingTemplate.cropInfo.showBrandOrganization) &&
            !agEventModel.brandOrganizationGuid;
        const hideVarietyHybrid =
            !agEventModel.varietyHybridGuid &&
            (!templateMatch ||
                !scoutingTemplate.cropInfo.showVarietyHybrid ||
                !scoutingTemplate.cropInfo.showBrandOrganization) &&
            !agEventModel.varietyHybridGuid;
        const hideGrowthState =
            (!agEventModel.cropGrowthStageGuid &&
                (!templateMatch || !scoutingTemplate.cropInfo.showCropGrowthStage)) ||
            !scoutingTemplate.cropGuid;
        const hideCropCondition =
            !agEventModel.cropConditionGuid &&
            (!templateMatch || !scoutingTemplate.cropInfo.showCropCondition);
        const hideCropInjuryRating =
            !agEventModel.cropInjuryRatingGuid &&
            (!templateMatch || !scoutingTemplate.cropInfo.showCropInjuryRating);
        const hideDaysAfterCropDamage =
            !agEventModel.daysAfterCropDamage &&
            !(agEventModel.daysAfterCropDamage === 0) &&
            (!templateMatch || !scoutingTemplate.cropInfo.showDaysAfterCropDamage);
        const hideCropHeight =
            !agEventModel.cropHeight &&
            !(agEventModel.cropHeight === 0) &&
            (!templateMatch || !scoutingTemplate.cropInfo.showCropHeight);
        const hideCropPopulation =
            !agEventModel.cropPopulation &&
            !(agEventModel.cropPopulation === 0) &&
            (!templateMatch || !scoutingTemplate.cropInfo.showCropPopulation);
        const hideYieldPercentLoss =
            !agEventModel.yieldPercentLoss &&
            !(agEventModel.yieldPercentLoss === 0) &&
            (!templateMatch || !scoutingTemplate.cropInfo.showYieldPercentLoss);
        const hideStandPercentLoss =
            !agEventModel.standPercentLoss &&
            !(agEventModel.standPercentLoss === 0) &&
            (!templateMatch || !scoutingTemplate.cropInfo.showStandPercentLoss);
        const hideLeafPercentLoss =
            !agEventModel.leafPercentLoss &&
            !(agEventModel.leafPercentLoss === 0) &&
            (!templateMatch || !scoutingTemplate.cropInfo.showLeafPercentLoss);
        const isCropSelected = agEventModel.cropGuid !== "";

        const { onChange } = this;
        const photosVisible =
            (agEventModel.sitePhotoList && agEventModel.sitePhotoList.length > 0) ||
            scoutingTemplate.allowPhotos;

        const photoPanelProps = {
            formatMessage,
            index,
            messages,
            onChange,
            onPushToasterMessage,
            sitePhotoList: agEventModel.sitePhotoList,
            photoMediaTypeGuid,
            scoutingPhotoPresignedUrlMap,
            agEventGuid: agEventModel.agEventGuid,
        };
        return (
            <div className="scouting-event-container">
                <div>
                    <Bucket className="crop-info-section-bucket" isExpanded>
                        <BucketHeader className="scouting-event-section-header">
                            <div className="crop-info-header">
                                {formatMessage(messages.cropInfo)}
                            </div>
                        </BucketHeader>
                        <div className="input-row">
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                containerClassNames={[
                                    {
                                        "select-form-input-error": errorMessagePlaceholderSet.has(
                                            messages.cropError
                                        ),
                                    },
                                ]}
                                clearable={false}
                                placeholderText={formatMessage(messages.crop)}
                                value={agEventModel.cropGuid}
                                options={cropData.map(({ guid, name, activeYn }) => ({
                                    value: guid,
                                    label: name,
                                    activeYn,
                                }))}
                                required
                                onChange={(value) => this._onCropChange({ cropGuid: value })}
                            />
                            <SelectInput
                                placeholderText={formatMessage(messages.cropPurpose)}
                                value={agEventModel.cropPurposeGuid}
                                options={cropPurpose}
                                onChange={(value) =>
                                    this._onCropChange({
                                        cropPurposeGuid: value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-row">
                            {hideBrandOrganization ? null : (
                                <div className="input-item">
                                    <SelectInput
                                        options={brandOrgData.map(({ guid, name, activeYn }) => ({
                                            value: guid,
                                            label: name,
                                            activeYn,
                                        }))}
                                        onChange={(v) =>
                                            this._onBrandOrganizationChange({
                                                brandOrganizationGuid: v,
                                                varietyHybridGuid: null,
                                            })
                                        }
                                        placeholderText={formatMessage(messages.brandOrganization)}
                                        value={agEventModel.brandOrganizationGuid}
                                    />
                                </div>
                            )}
                            {hideVarietyHybrid ? null : (
                                <div className="input-item">
                                    <SelectInput
                                        options={varietyHybridData.map(
                                            ({ guid, name, activeYn }) => ({
                                                value: guid,
                                                label: name,
                                                activeYn,
                                            })
                                        )}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                varietyHybridGuid: v,
                                            })
                                        }
                                        placeholderText={formatMessage(messages.varietyHybrid)}
                                        value={agEventModel.varietyHybridGuid}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-row">
                            {hideGrowthState ? null : (
                                <div className="input-item">
                                    <SelectInput
                                        options={[...growthStageData]}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                cropGrowthStageGuid: v,
                                            })
                                        }
                                        placeholderText={formatMessage(messages.growthStage)}
                                        value={agEventModel.cropGrowthStageGuid}
                                    />
                                </div>
                            )}
                            {hideCropCondition ? null : (
                                <div className="input-item">
                                    <SelectInput
                                        options={numericRating}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                cropConditionGuid: v,
                                            })
                                        }
                                        placeholderText={formatMessage(messages.cropCondition)}
                                        value={agEventModel.cropConditionGuid}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-row">
                            {hideCropInjuryRating ? null : (
                                <div className="input-item">
                                    <SelectInput
                                        options={numericRating}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                cropInjuryRatingGuid: v,
                                            })
                                        }
                                        placeholderText={formatMessage(messages.cropInjury)}
                                        value={agEventModel.cropInjuryRatingGuid}
                                    />
                                </div>
                            )}
                            {hideDaysAfterCropDamage ? null : (
                                <div className="input-item">
                                    <NumericInput
                                        containerClassNames={["template-input-numeric"]}
                                        scale={8}
                                        precision={38}
                                        placeholderText={formatMessage(
                                            messages.daysAfterCropDamage
                                        )}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                daysAfterCropDamage: v,
                                            })
                                        }
                                        value={agEventModel.daysAfterCropDamage}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-row">
                            {hideCropHeight ? null : (
                                <div className="input-item">
                                    <NumericInput
                                        containerClassNames={["template-input-numeric"]}
                                        scale={8}
                                        precision={38}
                                        placeholderText={formatMessage(messages.cropHeight)}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                cropHeight: v,
                                            })
                                        }
                                        value={agEventModel.cropHeight}
                                    />
                                </div>
                            )}
                            {hideCropHeight ? null : (
                                <div className="input-item no-toggle">
                                    <SelectInput
                                        containerClassNames={[
                                            {
                                                "select-form-input-error":
                                                    errorCodesForEventArea.has(
                                                        eventScoutingCropHeightUnitRequired
                                                    ),
                                            },
                                        ]}
                                        options={unitLength}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                cropHeightUnitsGuid: v,
                                            })
                                        }
                                        placeholderText={formatMessage(messages.cropHeightUnit)}
                                        value={agEventModel.cropHeightUnitsGuid}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-row">
                            {hideCropPopulation ? null : (
                                <div className="input-item">
                                    <NumericInput
                                        containerClassNames={["template-input-numeric"]}
                                        scale={0}
                                        precision={38}
                                        placeholderText={formatMessage(messages.cropPopulation)}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                cropPopulation: v,
                                            })
                                        }
                                        value={agEventModel.cropPopulation}
                                    />
                                </div>
                            )}
                            {hideCropPopulation ? null : (
                                <div className="input-item no-toggle">
                                    <SelectInput
                                        containerClassNames={[
                                            {
                                                "select-form-input-error":
                                                    errorCodesForEventArea.has(
                                                        eventScoutingCropPopulationUnitRequired
                                                    ),
                                            },
                                        ]}
                                        options={unitCropPopulation}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                cropPopulationUnitsGuid: v,
                                            })
                                        }
                                        placeholderText={formatMessage(messages.cropPopulationUnit)}
                                        value={agEventModel.cropPopulationUnitsGuid}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-row">
                            {hideYieldPercentLoss ? null : (
                                <div className="input-item">
                                    <NumericInput
                                        containerClassNames={["template-input-numeric"]}
                                        scale={8}
                                        precision={38}
                                        placeholderText={formatMessage(messages.yieldLoss)}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                yieldPercentLoss: v,
                                            })
                                        }
                                        value={agEventModel.yieldPercentLoss}
                                    />
                                </div>
                            )}
                            {hideStandPercentLoss ? null : (
                                <div className="input-item">
                                    <NumericInput
                                        containerClassNames={["template-input-numeric"]}
                                        scale={8}
                                        precision={38}
                                        placeholderText={formatMessage(messages.standLoss)}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                standPercentLoss: v,
                                            })
                                        }
                                        value={agEventModel.standPercentLoss}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-row">
                            {hideLeafPercentLoss ? null : (
                                <div className="input-item">
                                    <NumericInput
                                        containerClassNames={["template-input-numeric"]}
                                        scale={8}
                                        precision={38}
                                        placeholderText={formatMessage(messages.leafLoss)}
                                        onChange={(v) =>
                                            this._updateAgEvent({
                                                leafPercentLoss: v,
                                            })
                                        }
                                        value={agEventModel.leafPercentLoss}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-row">
                            {isCropSelected ? (
                                <TextArea
                                    maxLength={1000}
                                    value={agEventModel.notes}
                                    onChange={(value) =>
                                        this._updateAgEvent({
                                            notes: value,
                                        })
                                    }
                                    placeholderText={formatMessage(messages.notes)}
                                />
                            ) : null}
                        </div>
                        {isCropSelected ? (
                            !photosVisible ? null : (
                                <div className="zero-to-infinite-cont add-link">
                                    <ScoutingSitePhotoPanel {...photoPanelProps} />
                                </div>
                            )
                        ) : null}
                    </Bucket>
                </div>
            </div>
        );
    }
    // index and CB are never read, remove?
    public onChange = (type: string, value: string): void => {
        const scoutingDetailList = this.props.agEventModel;
        if (type) {
            const newScoutingDetail = {
                ...scoutingDetailList,
                [type]: value,
            };
            this._updateAgEvent({
                sitePhotoList: newScoutingDetail.sitePhotoList,
            });
        }
    };

    private _getObservationSection() {
        const { agEventModel, fetchPicklistData } = this.props;
        const { errorCodesForEventArea } = this.state;
        return (
            <ScoutingDetail
                cropGuid={agEventModel.cropGuid}
                fetchPicklistData={fetchPicklistData}
                onChildComponentChange={(value) =>
                    this._updateAgEvent({ scoutingDetailList: value })
                }
                onNoIssuesFoundChange={(value) => this._updateAgEvent({ noIssuesFound: value })}
                scoutingDetailList={agEventModel.scoutingDetailList}
                errorCodesForEventArea={errorCodesForEventArea}
                noIssuesFound={agEventModel.noIssuesFound}
                disableNoIssuesFound={agEventModel.hasScoutingDetails}
            />
        );
    }

    public render(): JSX.Element {
        const { isLoading, agEventModel } = this.props;
        const { templateApplyIsLoading } = this.state;
        return (
            <div className="event-scouting-form">
                {isLoading ? <Loader /> : null}
                {templateApplyIsLoading ? null : this._getCropSection()}
                {agEventModel.cropGuid !== "" ? this._getObservationSection() : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchDropdownData: (dropdowns) =>
        dispatch(fetchDropdownData({ ...dropdowns, action: fetchedDropdownData })),
    fetchGrowthStageData: (payload) => dispatch(actions.fetchGrowthStageData(payload)),
    fetchPicklistData: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    fetchApplyTemplate: (cropGuid, cropPurposeGuid, currentZoneOnly, overwriteValues) =>
        dispatch(
            actions.fetchApplyTemplate(cropGuid, cropPurposeGuid, currentZoneOnly, overwriteValues)
        ),
    onUpdateCurrentAgEventAreaAgEventModel: (fieldGuid, agEventTransactionTypeGuid, newProps) =>
        dispatch(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                fieldGuid,
                agEventTransactionTypeGuid,
                newProps
            )
        ),
    onUpdateAgEventModel: (fieldGuid, agEventAreaId, agEventTransactionTypeGuid, newProps) =>
        dispatch(
            recsEventsActions.updateAgEventModel(
                fieldGuid,
                agEventAreaId,
                agEventTransactionTypeGuid,
                newProps
            )
        ),
    setOriginalScoutingDetails: (
        agEventGuid: string,
        scoutingDetailList: AgEventAPI.IScoutingDetail[]
    ) => dispatch(actions.setOriginalScoutingDetailList(agEventGuid, scoutingDetailList)),
    updateScoutingTemplate: (newProps) => dispatch(actions.updateScoutingTemplate(newProps)),
    integrateCrop: (cropGuid, cropPurposeGuid) =>
        dispatch(actions.integrateCrop(cropGuid, cropPurposeGuid)),
    onPushToasterMessage: (message: any, type: any) =>
        dispatch(notificationActions.pushToasterMessage(message, type)),
});

const mapStateToProps = (state) => {
    const { saveEventDetailsErrorCodeList, saveEventDetailsErrorModel } =
        eventsSelectors.getModuleState(state);
    const eventInfoState = eventInfoSelectors.getModuleState(state);
    const { eventSummary } = eventInfoState;
    const currentArea = eventsSelectors.getCurrentAgEventArea(state, eventSummary.fieldGuid);
    const currentEventAreaId = currentArea.eventAreaId;
    const eventAreaIds = eventsSelectors.getEventAreaIds(state, eventSummary.fieldGuid);

    return {
        brandOrgData: eventInfoSelectors.getBrandOrgState(state),
        cropData: eventInfoSelectors.getCropDropdownState(state),
        cropPurpose: picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
        ),
        currentEventAreaId,
        eventAreaIds,
        growthStageData: selectors.getGrowthStageData(state),
        isLoading: selectors.getScoutingDataLoading(state),
        numericRating: picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_NUMERIC_RATING)
        ),
        originalScoutingDetailList: selectors.getOriginalScoutingDetailList(state),
        saveEventDetailsErrorCodeList,
        saveEventDetailsErrorModel,
        scoutingTemplate: selectors.getScoutingTemplate(state),
        unitCropPopulation: unitSelectors.getUnitPicklistOptionsFromCode(
            state,
            getUnitCode(UNIT_CROP_POPULATION)
        ),
        unitLength: unitSelectors.getUnitPicklistOptionsFromCode(state, getUnitCode(UNIT_LENGTH)),
        varietyHybridData: eventInfoSelectors.getVarietyHybridDropdownState(state),
        scoutingPhotoPresignedUrlMap: eventsSelectors.getScoutingPhotoPresignedUrlMap(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateCurrentAgEventAreaAgEventModel: (newProps) =>
        dispatchProps.onUpdateCurrentAgEventAreaAgEventModel(
            ownProps.fieldGuid,
            ownProps.agEventTransactionTypeGuid,
            newProps
        ),
});

export const ScoutingForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(ScoutingForm_));
