export class ClassificationMethod {
    public name: string;
}

export class ClassificationMethods {
    public static naturalBreaks: ClassificationMethod = {
        name: "Natural Breaks",
    };
    public static equalInterval: ClassificationMethod = {
        name: "Equal Interval",
    };
    public static equalRecords: ClassificationMethod = {
        name: "Equal Records",
    };

    public static all(): ClassificationMethod[] {
        return [
            ClassificationMethods.naturalBreaks,
            ClassificationMethods.equalInterval,
            ClassificationMethods.equalRecords,
        ];
    }
}
