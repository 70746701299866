import { connect } from "react-redux";

import { needsSelectors } from "~/hocs";

import EquationTextAreaView from "./equation-text-area";
import { validateEquation } from "../../data/actions";
import { getEquationTestValue, getValidEquationResponse } from "../../data/selectors";

const mapStateToProps = (state) => ({
    requestIds: needsSelectors.getRequestIds(state),
    testValue: getEquationTestValue(state),
    validateResponse: getValidEquationResponse(state),
});

const mapDispatchToProps = () => {
    return {
        validateEquation: (payload) => validateEquation(payload),
        validateEquationToggle: (payload) => validateEquation(payload),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquationTextAreaView);
