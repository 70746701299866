import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TableCell from "~/core/components/tables/data-table/components/table-cell";

export class TemplateManagerRows extends PureComponent {
    static propTypes = {
        data: PropTypes.array,
        headerRow: PropTypes.bool,
        selectedSampleAttributes: PropTypes.array,
        showMappedAttributes: PropTypes.bool,
    };

    printRow = ({ valueList = [] }) => {
        const { headerRow } = this.props;
        return (
            valueList &&
            valueList.map((attr, index) => {
                const classNames = classnames({
                    "template-manager-grid-cell": true,
                    "is-header-row": headerRow && index === 0,
                });
                return (
                    <TableCell
                        key={`template-grid-${attr}-${index}`}
                        className={classNames}
                        title={attr}
                    >
                        {attr}
                    </TableCell>
                );
            })
        );
    };

    getFilteredRows = (data) => {
        const { selectedSampleAttributes, showMappedAttributes } = this.props;
        if (showMappedAttributes) {
            const newData =
                data &&
                data.filter(({ valueList }) => {
                    for (let key in selectedSampleAttributes) {
                        if (selectedSampleAttributes[key].columnNameInFile === valueList[0]) {
                            return true;
                        }
                    }
                    return false;
                });
            return newData;
        }
        return data;
    };

    render() {
        const data = this.getFilteredRows(this.props.data);
        return (
            <div className="template-manager-rows">
                {data &&
                    data.map(({ valueList }, index) => (
                        <div className="grid-row" key={`template-grid-row-${index}`}>
                            {this.printRow({ valueList })}
                        </div>
                    ))}
            </div>
        );
    }
}
