import { createService } from "~/hocs/crud";
import * as model from "./model";
import defaultUserRecord from "./default-record";
import { adminData } from "~/admin/data";
import { HierarchyAPI, AddressAPI, UserAPI, PersonAPI } from "@ai360/core";

import {
    PICKLIST_JOB_TITLE,
    PICKLIST_PHONE_TYPE,
    PICKLIST_PAY_TYPE,
    PICKLIST_LICENSE_TYPE,
    PICKLIST_USER_TYPE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { UNIT_PAY_RATE, getUnitCode } from "~/core/units/unit-names";

// Module Name
export const MODEL_NAME = "user";

//  Request payload
export const REQUEST_PAYLOAD_FILTER = "userFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "userSort";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "userPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

// URLs
export const AUTO_SEARCH_URL = UserAPI.AUTO_SEARCH_URL;
export const CREATE = PersonAPI.ADD_PERSON;
export const DELETE = UserAPI.USER_DEACTIVATE;
export const EXPORT_FILE_NAME = "UserExport";
export const EXPORT_URL = UserAPI.EXPORT_LIST;
export const GETRECORD = PersonAPI.GET_PERSON;
export const IMPORT_VALID_URL = UserAPI.IMPORT_USER_VALID_URL;
export const IMPORT_URL = UserAPI.IMPORT_USER_LIST;
export const HIERARCHY_URL = HierarchyAPI.GET_HIERARCHY_FILTER_LIST_WITH_SEARCH;
export const REQUEST_ORG_LEVEL = HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS_GUID;
export const UPDATE = PersonAPI.UPDATE_PERSON;
export const URL = UserAPI.GET_USERS_LIST;
export const SELECT_ALL = UserAPI.SELECT_ALL_USER;

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        FirstName: "",
        LastName: "",
        LoginEmail: "",
        UserRoleName: "",
        UserTypeName: "",
        isActive: "",
        modifiedDate: "",
        orgLevelGuid: null,
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "FirstName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const dropdowns = {
    [model.PROPS_ADDRESS_TYPE_LIST]: AddressAPI.REQUEST_ADDRESS_TYPE,
    [model.PROPS_COUNTRIES_LIST]: AddressAPI.REQUEST_COUNTRIES,
    [model.PROPS_STATES_LIST]: { url: AddressAPI.REQUEST_STATES, model: "" },
    [model.PROPS_TIME_ZONE_LIST]: UserAPI.GET_USER_TIMEZONE,
    [model.PROPS_ORG_LEVEL_LIST]: { url: REQUEST_ORG_LEVEL, model: "_" },
};

export const pickLists = {
    [PICKLIST_USER_TYPE]: getPickListCode(PICKLIST_USER_TYPE),
    [PICKLIST_JOB_TITLE]: getPickListCode(PICKLIST_JOB_TITLE),
    [PICKLIST_PHONE_TYPE]: getPickListCode(PICKLIST_PHONE_TYPE),
    [PICKLIST_PAY_TYPE]: getPickListCode(PICKLIST_PAY_TYPE),
    [PICKLIST_LICENSE_TYPE]: getPickListCode(PICKLIST_LICENSE_TYPE),
};

export const unitTypes = {
    [UNIT_PAY_RATE]: getUnitCode(UNIT_PAY_RATE),
};

// Service
export const service = createService({
    id: model.PROPS_USER_ID,
    guid: model.PROPS_PERSON_GUID,
    name: model.PROPS_USER_NAME,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    EXPORT_FILE_NAME,
    dropdowns,
    unitTypes,
    pickLists,
    activeColumnName: adminData.PROPS_IS_ACTIVE,
    urls: {
        URL,
        HIERARCHY_URL,
        AUTO_SEARCH_URL,
        EXPORT_URL,
        GETRECORD,
        IMPORT_VALID_URL,
        IMPORT_URL,
        DELETE,
        CREATE,
        SELECT_ALL,
        UPDATE,
    },
    _defaultLabels: {
        [model.PROPS_USER_FIRST_NAME]: { label: "firstName", gridCol: 15 },
        [model.PROPS_USER_LAST_NAME]: { label: "lastName", gridCol: 15 },
        [model.PROPS_USER_ROLE]: { label: "userRoleName", gridCol: 15 },
        [model.PROPS_USER_TYPE]: { label: "userTypeName", gridCol: 15 },
        [model.PROPS_LOGIN_EMAIL]: { label: "loginEmail", gridCol: 15 },
        [model.PROPS_IS_ACTIVE]: {
            label: "isActive",
            visible: false,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => ({ ...defaultUserRecord() }),
    defaultSort,
});
