import natsort from "natsort";
import { ProductMixTypes, PhysicalStates } from "./actions";
import { LB, GAL, OZ, FLOZ, PER100GAL, LBPERFT3, LBPERGAL, LBPERAC } from "./keywords";
import * as models from "~/recs-events/recs/model";
import { ProductMix, ProductMixProduct } from "./model";
import { RecNutrientParameters, RecNutrientResult } from "~/recs-events/recs/model";
import * as blendingModels from "./model";
import { RecAPI } from "@ai360/core";
export const minimumSettingsOptions = [
    { label: "Apply At Minimum (Except Zeros)", value: "minimumExcludeZeros" },
    { label: "Apply At Minimum (Include Zeros)", value: "minimumIncludeZeros" },
    { label: "Apply Zero When Below Minimum", value: "zerosBelowMinimum" },
    { label: "Apply Zero Below Switch Rate", value: "zerosBelowSwitchRate" },
];

const ACCEPTABLE_RATE_ERROR = 0.000001;
export const FT3_GAL = 7.480543; // cubic ft to gallons
export const GAL_FT3 = 0.1336801;
export const DENSITY_WATER_LB_GAL = 8.34; // At 55 deg. F
export const DENSITY_WATER_LB_FT3 = 62.39;

const naturalSorter = natsort({ insensitive: true });

export const Nutrients = {
    NITROGEN: "nitrogen",
    PHOSPHORUS: "phosphorus",
    POTASSIUM: "potassium",
    CALCIUM: "calcium",
    MAGNESIUM: "magnesium",
    SULFUR: "sulfur",
    IRON: "iron",
    ZINC: "zinc",
    COPPER: "copper",
    MANGANESE: "manganese",
    BORON: "boron",
    CHLORINE: "chlorine",
    MOLYBDENUM: "molybdenum",
    LIME: "lime",
};
export const mixNames = [
    ProductMixTypes.FERTILIZER,
    ProductMixTypes.CHEMICAL,
    ProductMixTypes.MANURE,
];
export const validationErrors = {
    ADJUST_OOB: 1,
    ADJUST_BELOW_LOCKED_MIN: 2,
    ADJUST_ABOVE_LOCKED_MAX: 3,
    MIN_MAX_INVALID: 4,
    MIN_TOO_HIGH: 5,
    MAX_TOO_LOW: 6,
    DRY_FERT_MISSING_SG: 7,
    MISSING_DENSITY: 8,
    SWITCH_TOO_HIGH: 9,
};

export const nutrientOrder = {
    nitrogen: 1,
    phosphorus: 2,
    potassium: 3,
    calcium: 4,
    magnesium: 5,
    sulfur: 6,
    iron: 7,
    zinc: 8,
    copper: 9,
    manganese: 10,
    boron: 11,
    chlorine: 12,
    molybdenum: 13,
    lime: 14,
};

const plantFoodWholeRoundingNutrients = [
    Nutrients.NITROGEN,
    Nutrients.PHOSPHORUS,
    Nutrients.POTASSIUM,
    Nutrients.SULFUR,
    Nutrients.CALCIUM,
    Nutrients.MAGNESIUM,
    Nutrients.LIME,
];

export const getGuaranteedAnalysis = (
    nutrientList: blendingModels.ProductMixNutrient[] | blendingModels.ProductMixProductNutrient[]
) => {
    if (!nutrientList) {
        return "0-0-0";
    }
    const guaranteedAnalysis = getGuaranteedAnalysisList(nutrientList);
    return guaranteedAnalysis.filter((nutrient) => nutrient != null).join("-");
};

export const getGuaranteedAnalysisList = (
    nutrientList: blendingModels.ProductMixNutrient[] | blendingModels.ProductMixProductNutrient[]
) => {
    const nutrientMap = getNutrientMap(nutrientList);
    const guaranteedAnalysis = [];

    guaranteedAnalysis[nutrientOrder[Nutrients.NITROGEN]] = "0";
    guaranteedAnalysis[nutrientOrder[Nutrients.PHOSPHORUS]] = "0";
    guaranteedAnalysis[nutrientOrder[Nutrients.POTASSIUM]] = "0";

    nutrientMap.forEach((value, key) => {
        const hideElement = [
            Nutrients.NITROGEN,
            Nutrients.PHOSPHORUS,
            Nutrients.POTASSIUM,
        ].includes(key);
        if (Number(value.percent) > 0) {
            guaranteedAnalysis[nutrientOrder[key]] = `${value.percent}${
                hideElement ? "" : value.element
            }`;
        }
    });
    return guaranteedAnalysis;
};

export const getNutrientMap = (
    nutrientList: blendingModels.ProductMixNutrient[] | blendingModels.ProductMixProductNutrient[]
) => {
    const nutrientMap = new Map<string, blendingModels.NutrientAnalysis>();
    nutrientList.forEach((nutrient) => {
        const applicablePercent = nutrient.nutrientPercent
            ? nutrient.nutrientPercent
            : nutrient.percent;
        nutrientMap.set(nutrient.nameKey, {
            percent: Math.round(Number(applicablePercent) * 1000) / 1000,
            element: nutrient.element,
        });
    });

    return nutrientMap;
};

export const getPlantFood = (
    rate: number,
    nutrientList: blendingModels.ProductMixNutrient[],
    density: number
) => {
    const plantMap = new Map();
    const plantFood = [];
    const nutrientMap = getNutrientMap(nutrientList);

    nutrientList.forEach((nutrient) => {
        const targetGA = nutrientMap.get(nutrient.nameKey);
        const targetGAPercent = targetGA ? targetGA.percent / 100 : 0;
        const applicableDensity = density || 1;
        const applicableRounding = plantFoodWholeRoundingNutrients.includes(nutrient.nameKey)
            ? 0
            : 2;
        plantMap.set(nutrient.nameKey, {
            percent: roundValue(targetGAPercent * rate * applicableDensity, applicableRounding),
            element: nutrient.element,
        });
    });

    plantFood[nutrientOrder[Nutrients.NITROGEN]] = "0";
    plantFood[nutrientOrder[Nutrients.PHOSPHORUS]] = "0";
    plantFood[nutrientOrder[Nutrients.POTASSIUM]] = "0";

    plantMap.forEach((value, key) => {
        const hideElement = [
            Nutrients.NITROGEN,
            Nutrients.PHOSPHORUS,
            Nutrients.POTASSIUM,
        ].includes(key);
        if (Number(value.percent) > 0) {
            plantFood[nutrientOrder[key]] = `${value.percent}${hideElement ? "" : value.element}`;
        }
    });
    return plantFood.filter((nutrient) => nutrient != null).join("-");
};

export const getProductDataToolTip = (
    product: ProductMixProduct,
    densityUnitLabel: string,
    isServiceProduct: boolean
) => {
    const guaranteedAnalysis = getGuaranteedAnalysis(product.nutrientList);
    const isDry = product.physicalState?.toLocaleLowerCase() === PhysicalStates.DRY;
    const densityDisplay = isDry ? Math.round(product.density) : roundValue(product.density, 4);
    const densityLine =
        product.density && densityUnitLabel ? densityDisplay + " " + densityUnitLabel : "0";

    return isServiceProduct
        ? product.productName || product.customProductName
        : guaranteedAnalysis !== "0-0-0"
        ? (product.productName || product.customProductName) +
          " \n " +
          guaranteedAnalysis +
          " \n " +
          densityLine
        : (product.productName || product.customProductName) + " \n " + densityLine;
};

export const getProductOptionToolTip = (productOption: any) => {
    const isDry = productOption.physicalState?.toLocaleLowerCase() === PhysicalStates.DRY;
    const densityDisplay = isDry
        ? Math.round(productOption.density)
        : roundValue(productOption.density, 4);
    const densityLine =
        productOption.density && productOption.densityUnit
            ? densityDisplay + " " + productOption.densityUnit
            : "0";

    return productOption.productParentType === "Service"
        ? productOption.name
        : productOption.guaranteedAnalysis !== "0-0-0"
        ? productOption.name + " \n " + productOption.guaranteedAnalysis + " \n " + densityLine
        : productOption.name + " \n " + densityLine;
};

export const sortCustomBlends = (a, b) => {
    return naturalSorter(a.name, b.name);
};

export const sortCustomProducts = (a, b) => {
    const format = (o) => `${o.customProductType} - ${o.guaranteedAnalysis}`;
    return naturalSorter(format(a), format(b));
};

export const roundValue = (value, scale = 9) => {
    const numValue = Number(value);
    if (numValue != null) {
        return Math.round(numValue * Math.pow(10, scale)) / Math.pow(10, scale);
    }
    return numValue;
};

export const getMinValue = (
    existingMin: number | string | null,
    newMin: number | string | null
): number => {
    const values = [
        ...(!existingMin && existingMin !== 0 ? [] : [Number(existingMin)]),
        ...(!newMin && newMin !== 0 ? [] : [Number(newMin)]),
    ];
    const minValue = Math.min(...values);
    return isNumeric(minValue) ? minValue : 0;
};

export const isNumeric = (value) => {
    return value != null && value !== "" && !isNaN(value) && isFinite(value);
};

export const getNumericValue = (value, defaultValue = 1) => {
    return isNumeric(value) ? value : defaultValue;
};
export const getProductRateInMix = (
    calculatedProductDensity: string,
    productMix: ProductMix | models.RecNutrientProductMix,
    product: ProductMixProduct,
    rateUnit,
    props
) => {
    if (!rateUnit) {
        return 0;
    }
    const poundUnit = getPriceUnitByName(LB, props);
    const targetRateToPoundsConversionFactor = getProductRateConversionFactor(
        productMix.targetRateUnitGuid,
        poundUnit.value,
        productMix.density,
        productMix.targetRate,
        props
    );

    const mixTotalPounds = Number(productMix.targetRate) * targetRateToPoundsConversionFactor;
    const lineRateToPoundsConversion = getProductRateConversionFactor(
        rateUnit.value,
        poundUnit.value,
        calculatedProductDensity,
        productMix.targetRate || 0,
        props
    );

    return isNumeric(product.rate) &&
        !(
            +product.rate === 0 &&
            isNumeric(productMix.targetRate) &&
            isNumeric(product.percentOfMix)
        )
        ? product.rate
        : mixTotalPounds * product.percentOfMix * (1 / lineRateToPoundsConversion);
};

export const getIsChanged = (newProps, existingProps) => {
    for (const prop in newProps) {
        if (Object.hasOwn(newProps, prop)) {
            if (isNumeric(newProps[prop])) {
                const roundingPrecision =
                    newProps[prop] < 0.05 && existingProps[prop] < 0.05 ? 5 : 2;
                const existingNumeric = Number(existingProps[prop]);
                if (
                    roundValue(newProps[prop], roundingPrecision) !==
                        roundValue(existingNumeric, 2) &&
                    roundValue(newProps[prop], roundingPrecision) !==
                        roundValue(
                            existingNumeric + 0.49 * (1 / Math.pow(10, roundingPrecision)),
                            roundingPrecision
                        ) &&
                    roundValue(newProps[prop], roundingPrecision) !==
                        roundValue(
                            existingNumeric - 0.5 * (1 / Math.pow(10, roundingPrecision)),
                            roundingPrecision
                        )
                ) {
                    return true;
                }
            } else if (JSON.stringify(existingProps[prop]) !== JSON.stringify(newProps[prop])) {
                return true;
            }
        }
    }
    return false;
};

export const getIsProductInitialized = (product: ProductMixProduct) => {
    return Boolean(
        isNumeric(product.costPerAcre) &&
            isNumeric(product.totalProduct) &&
            isNumeric(product.totalCost) &&
            (product.rateUnit || product.productParentType === "Service") &&
            product.costUnit &&
            isNumeric(product.rate) &&
            (product.productParentType === "Service" ||
                !(product.totalProduct === 0 && product.rate > 0))
    );
};

export const getIsProductMixInitialized = (productMix: ProductMix) => {
    const isProductMixCalculated =
        isNumeric(productMix.targetRate) &&
        isNumeric(productMix.targetCost) &&
        isNumeric(productMix.costPerAcre) &&
        isNumeric(productMix.totalProduct) &&
        isNumeric(productMix.totalCost);
    const isProductsCalculated =
        productMix.products != null &&
        !productMix.products.some((product) => !getIsProductInitialized(product));
    return Boolean(productMix.productMixTypeGuid && isProductMixCalculated && isProductsCalculated);
};

export const getRateQuantityUnitLabel = (rateUnit) => {
    return rateUnit.label.split("/")[0].replace(/ /g, "").replace(FLOZ, OZ);
};

export const getProductMixId = (productMix: ProductMix) => {
    return productMix.products
        .map(
            ({ customProductName, percentOfMix, productName }) =>
                `${(productName || customProductName).replace(/[ ,\-,%,:]/g, "")}${roundValue(
                    percentOfMix
                ).toFixed(2)}`
        )
        .join("_");
};

export const getProductMixIdForManure = (guaranteedAnalysis: string) => {
    return `${guaranteedAnalysis.replace(/-/g, "")}1.00`;
};

export const getRateUnit = (rateUnitGuid: string, props) => {
    const { productBlendPicklists } = props;
    const { dryRateUnits, liquidRateUnits } = productBlendPicklists;

    return (
        dryRateUnits.find((unit) => unit.value === rateUnitGuid) ||
        liquidRateUnits.find((unit) => unit.value === rateUnitGuid)
    );
};

export const getRateUnitByName = (name: string, props) => {
    const { productBlendPicklists } = props;
    const { dryRateUnits, liquidRateUnits } = productBlendPicklists;

    return (
        dryRateUnits.find((unit) => unit.label === name) ||
        liquidRateUnits.find((unit) => unit.label === name)
    );
};

export const getRateUnitPhysicalState = (rateUnitGuid: string, props) => {
    const { productBlendPicklists } = props;
    const { dryRateUnits, liquidRateUnits } = productBlendPicklists;
    return dryRateUnits.find((unit) => unit.value === rateUnitGuid)
        ? PhysicalStates.DRY
        : liquidRateUnits.find((unit) => unit.value === rateUnitGuid)
        ? PhysicalStates.LIQUID
        : "unknown";
};

export const getIsDataLoaded = (props) => {
    const { availableProducts, productBlendPicklists } = props;
    const { dryRateUnits, liquidRateUnits, dryPriceUnits, liquidPriceUnits } =
        productBlendPicklists;

    return (
        dryPriceUnits.length > 0 &&
        liquidPriceUnits.length > 0 &&
        dryRateUnits.length > 0 &&
        liquidRateUnits.length > 0 &&
        availableProducts.length > 0
    );
};

export const getPriceUnit = (costUnitGuid: string, props) => {
    const { productBlendPicklists } = props;
    const { dryPriceUnits, liquidPriceUnits, servicePriceUnits } = productBlendPicklists;

    return (
        servicePriceUnits.find((unit) => unit.value === costUnitGuid) ||
        dryPriceUnits.find((unit) => unit.value === costUnitGuid) ||
        liquidPriceUnits.find((unit) => unit.value === costUnitGuid)
    );
};

export const getPriceUnitByName = (costUnitName: string, props, physicalState = null) => {
    const { productBlendPicklists } = props;
    const { dryPriceUnits, liquidPriceUnits, servicePriceUnits } = productBlendPicklists;

    return physicalState === "Service"
        ? servicePriceUnits.find((unit) => unit.label === costUnitName)
        : physicalState === PhysicalStates.DRY
        ? dryPriceUnits.find((unit) => unit.label === costUnitName)
        : physicalState === PhysicalStates.LIQUID
        ? liquidPriceUnits.find((unit) => unit.label === costUnitName)
        : dryPriceUnits.find((unit) => unit.label === costUnitName) ||
          liquidPriceUnits.find((unit) => unit.label === costUnitName);
};

export const getPriceUnitFromRateUnit = (rateUnit, props) => {
    const { productBlendPicklists } = props;
    const { dryPriceUnits, liquidPriceUnits } = productBlendPicklists;

    if (!rateUnit) {
        return null;
    }
    const rateUnitLabel = getRateQuantityUnitLabel(rateUnit);
    const fromUnitPhysicalState = getRateUnitPhysicalState(rateUnit.value, props);

    return fromUnitPhysicalState === PhysicalStates.DRY
        ? dryPriceUnits.find((unit) => unit.label === rateUnitLabel)
        : liquidPriceUnits.find((unit) => unit.label === rateUnitLabel);
};

export const getPriceUnitPhysicalState = (costUnitGuid: string, props) => {
    const { productBlendPicklists } = props;
    const { dryPriceUnits, liquidPriceUnits } = productBlendPicklists;

    const costUnit = getPriceUnit(costUnitGuid, props);
    if (!costUnit) {
        return "";
    }

    return dryPriceUnits.find((unit) => unit.value === costUnitGuid)
        ? PhysicalStates.DRY
        : liquidPriceUnits.find((unit) => unit.value === costUnitGuid)
        ? PhysicalStates.LIQUID
        : "";
};

export const getProductMixUnits = (
    productMix: ProductMix | models.RecNutrientProductMix,
    products: ProductMixProduct[],
    props
) => {
    const hasTargetRateUnit =
        productMix.targetRateUnitGuid &&
        products.some((product) => product.rateUnitGuid === productMix.targetRateUnitGuid);
    const isChemical = productMix.productMixType.toLowerCase() === ProductMixTypes.CHEMICAL;

    const rateProduct = isChemical
        ? products.find((product) => product.isCarrier)
        : products.find((product) => product.rateUnitGuid);

    const targetRateUnitGuid =
        hasTargetRateUnit && !isChemical
            ? productMix.targetRateUnitGuid
            : rateProduct
            ? rateProduct.rateUnitGuid
            : null;
    if (!targetRateUnitGuid) {
        return {};
    }

    const targetRateUnitItem = getRateUnit(targetRateUnitGuid, props);
    const targetRateUnit = targetRateUnitItem.label;
    const targetRateQuantityUnit = getPriceUnitFromRateUnit(targetRateUnitItem, props);

    const targetCostUnitGuid = getEffectiveCostUnitGuid(productMix, products);
    const targetCostUnitItem = getPriceUnit(targetCostUnitGuid, props);
    const targetCostUnit = targetCostUnitItem.label;

    const physicalStateLabel = getPriceUnitPhysicalState(targetRateQuantityUnit.value, props);
    const densityUnits = getDensityUnits(physicalStateLabel, props);

    return {
        targetRateUnit,
        targetRateUnitGuid,
        targetCostUnit,
        targetCostUnitGuid,
        ...densityUnits,
    };
};

export const getProductMixDensity = (
    products: ProductMixProduct[],
    totalMixProduct: number[],
    targetRate: number,
    physicalState: string
) => {
    const isDry = physicalState === PhysicalStates.DRY;
    return isDry
        ? getDryProductsDensityWeighted(products, targetRate)
        : getLiquidProductsDensityWeighted(totalMixProduct);
};

export const getDryProductsDensityWeighted = (
    products: ProductMixProduct[],
    productMixRate: number
) => {
    let weightedDensity = 0;
    products.forEach((p) => (weightedDensity += (p.density * p.rate) / productMixRate));
    return weightedDensity;
};

export const getLiquidProductsDensityWeighted = (totalMixProduct) => {
    const totalMixProductPounds = totalMixProduct[0];
    const totalMixProductGallons = totalMixProduct[1];
    return totalMixProductPounds / totalMixProductGallons;
};

export const getDensityUnits = (physicalStateLabel: string, props) => {
    const { productBlendPicklists } = props;
    const { physicalState, densityUnits } = productBlendPicklists;

    const densityUnit =
        physicalStateLabel === PhysicalStates.DRY
            ? densityUnits.find((item) => item.label.toLowerCase() === LBPERFT3)
            : densityUnits.find((item) => item.label.toLowerCase() === LBPERGAL);
    const physicalStateUnit = physicalState.find(
        (item) => item.label.toLowerCase() === physicalStateLabel
    );

    return {
        physicalState: physicalStateUnit.label,
        physicalStateGuid: physicalStateUnit.value,
        densityUnit: densityUnit.label,
        densityUnitGuid: densityUnit.value,
    };
};

//Percent Adjustment

export const getInitialPercentAdjustmentValues = (
    newProps,
    recNutrient: models.RecNutrient,
    productMix: ProductMix,
    effectiveFieldRate: number,
    recalculateSummary: boolean,
    targetRateOfProductMix: string,
    minimumExcludeZeros: boolean,
    isMinLock: boolean,
    isMaxLock: boolean
) => {
    const hasChanged =
        newProps.percentAdjustment !== recNutrient.recNutrientParameters.percentAdjustment ||
        productMix.targetRate !== targetRateOfProductMix;

    const retainSummary = !recalculateSummary || !hasChanged;

    const newTargetRate =
        retainSummary || isZeroRec(recNutrient)
            ? productMix.targetRate
            : roundValue(effectiveFieldRate * (newProps.percentAdjustment / 100));

    const { applicableMinimum, applicableMaximum, applicableSwitchRate } =
        getApplicablePercentAdjustmentValues(recNutrient, newTargetRate, minimumExcludeZeros);

    const { newMinimum, newMaximum } = getNewMinMaxPercentAdjustmentValues(
        hasChanged,
        isZeroRec(recNutrient),
        isMinLock,
        isMaxLock,
        minimumExcludeZeros,
        applicableMinimum,
        applicableMaximum
    );

    return {
        hasChanged,
        retainSummary,
        newTargetRate,
        applicableMinimum,
        applicableMaximum,
        applicableSwitchRate,
        newMinimum,
        newMaximum,
    };
};

export const getApplicablePercentAdjustmentValues = (
    recNutrient: models.RecNutrient,
    newTargetRate: string | number,
    minimumExcludeZeros: boolean
) => {
    const applicableMinimum = isZeroRec(recNutrient)
        ? newTargetRate
        : minimumExcludeZeros && Number(recNutrient.recNutrientParameters.minimumRate) === 0
        ? recNutrient.averageRecNutrientResult.minRate
        : recNutrient.recNutrientParameters.minimumRate;
    const applicableMaximum = isZeroRec(recNutrient)
        ? newTargetRate
        : recNutrient.recNutrientParameters.maximumRate || 0;
    const applicableSwitchRate = isZeroRec(recNutrient)
        ? 0
        : recNutrient.recNutrientParameters.switchRate;

    return {
        applicableMinimum: Number(applicableMinimum),
        applicableMaximum: Number(applicableMaximum),
        applicableSwitchRate: Number(applicableSwitchRate),
    };
};

export const getNewMinMaxPercentAdjustmentValues = (
    hasChanged: boolean,
    isZeroRec: boolean,
    isMinLock: boolean,
    isMaxLock: boolean,
    minimumExcludeZeros: boolean,
    applicableMinimum: string | number,
    applicableMaximum: string | number
) => {
    const newMinimum =
        isMinLock ||
        (minimumExcludeZeros && Number(applicableMinimum) === 0) ||
        !hasChanged ||
        isZeroRec
            ? applicableMinimum
            : null;
    const newMaximum = isMaxLock || !hasChanged || isZeroRec ? applicableMaximum : 0;

    return {
        newMinimum: newMinimum,
        newMaximum: Number(newMaximum),
    };
};

export const calculate = (
    productMix: ProductMix | models.RecNutrientProductMix,
    product,
    newProps,
    newTargetRate = 0,
    props
) => {
    const { calculatedArea } = props;

    const lineRateToLineCostConversionFactor = getProductRateConversionFactor(
        newProps.rateUnitGuid || product.rateUnitGuid,
        newProps.costUnitGuid || product.costUnitGuid,
        product.density,
        newTargetRate || productMix.targetRate,
        props
    );

    const isInitialized = getIsProductInitialized(product);
    const isChanged = getIsChanged(newProps, product);
    const isRateUnitChange =
        newProps.rateUnitGuid && newProps.rateUnitGuid !== product.rateUnitGuid;
    const isSummaryChange = newProps.costPerAcre || newProps.totalProduct || newProps.totalCost;

    const isServiceProduct = getIsServiceProduct(props.availableProducts, product.productGuid);

    if (isServiceProduct) {
        const rate = product.rate || 1;
        const isArea =
            (newProps.costUnitGuid || product.costUnitGuid) ===
            getPriceUnitByName("ac", props, "Service")?.value;
        const isEach =
            (newProps.costUnitGuid || product.costUnitGuid) ===
            getPriceUnitByName("ea", props, "Service")?.value;

        const cost = isArea
            ? newProps.costPerAcre != null
                ? newProps.costPerAcre
                : newProps.totalCost != null
                ? product.cost * (newProps.totalCost / product.totalCost)
                : newProps.cost != null
                ? newProps.cost
                : product.cost || 0
            : isEach
            ? newProps.costPerAcre != null
                ? product.cost * (newProps.costPerAcre / product.costPerAcre)
                : newProps.totalCost != null
                ? product.cost * (newProps.totalCost / product.totalCost)
                : newProps.totalProduct != null
                ? newProps.cost != null
                    ? newProps.cost
                    : product.cost || 0
                : newProps.cost != null
                ? newProps.cost
                : product.cost || 0
            : newProps.cost != null
            ? newProps.cost
            : product.cost || 0;

        const isCostUnitChange = newProps.costUnitGuid != null;

        const totalProduct =
            rate !== 1 && newProps.totalProduct == null && !isCostUnitChange
                ? rate
                : isArea && isCostUnitChange
                ? calculatedArea
                : isEach && isCostUnitChange
                ? 1
                : !isArea && !isEach && isCostUnitChange
                ? Number(productMix.totalProduct) *
                  getServiceProductCostConversionFactor(
                      getEffectiveCostUnitGuid(productMix, productMix.products),
                      newProps.costUnitGuid,
                      productMix.density,
                      props
                  )
                : newProps.totalProduct != null
                ? newProps.totalProduct
                : product.totalProduct;

        const totalCost = totalProduct * cost;
        const costPerAcre = totalCost / calculatedArea;

        return {
            rate: roundValue(totalProduct),
            cost,
            costPerAcre: roundValue(costPerAcre),
            totalProduct: roundValue(totalProduct),
            totalCost: roundValue(totalCost),
        };
    } else if (isSummaryChange && isInitialized && isChanged) {
        const changeRatio =
            newProps.costPerAcre != null
                ? isNumeric(newProps.costPerAcre / product.costPerAcre)
                    ? newProps.costPerAcre / product.costPerAcre
                    : 1
                : newProps.totalProduct != null
                ? isNumeric(newProps.totalProduct / product.totalProduct)
                    ? newProps.totalProduct / product.totalProduct
                    : 1
                : newProps.totalCost != null
                ? isNumeric(newProps.totalCost / product.totalCost)
                    ? newProps.totalCost / product.totalCost
                    : 1
                : 1;

        const costPerAcre =
            newProps.costPerAcre != null ? newProps.costPerAcre : product.costPerAcre * changeRatio;
        const totalCost =
            newProps.totalCost != null ? newProps.totalCost : product.totalCost * changeRatio;
        const totalProduct =
            newProps.totalProduct != null
                ? newProps.totalProduct
                : product.totalProduct * changeRatio;
        const rate = product.rate * changeRatio;

        return {
            rate: roundValue(rate),
            cost: product.cost,
            costPerAcre: roundValue(costPerAcre),
            totalProduct: roundValue(totalProduct),
            totalCost: roundValue(totalCost),
        };
    } else if (isRateUnitChange) {
        const rateUnit = getRateUnit(newProps.rateUnitGuid, props);
        const oldRateToNewRateFactor = getProductRateConversionFactor(
            product.rateUnitGuid,
            getPriceUnitFromRateUnit(rateUnit, props).value, //add check
            product.density,
            newTargetRate || productMix.targetRate,
            props
        );
        return {
            rate: product.rate * oldRateToNewRateFactor || 0,
        };
    } else {
        const rate = newProps.rate != null ? newProps.rate : product.rate || 0;
        const cost = newProps.cost != null ? newProps.cost : product.cost || 0;
        const totalProduct = rate * lineRateToLineCostConversionFactor * calculatedArea;
        const totalCost = totalProduct * cost;
        const costPerAcre = totalCost / calculatedArea;
        return {
            rate,
            cost,
            costPerAcre: roundValue(costPerAcre),
            totalProduct: roundValue(totalProduct),
            totalCost: roundValue(totalCost),
        };
    }
};

export const getIsDryAndLiquidUnit = (unitLabel, props) => {
    if (unitLabel === OZ) {
        return false;
    }
    const { productBlendPicklists } = props;
    const { dryPriceUnits, liquidPriceUnits } = productBlendPicklists;
    const isDryUnit = dryPriceUnits.find((unit) => unit.label === unitLabel);
    const isLiquidUnit = liquidPriceUnits.find((unit) => unit.label === unitLabel);
    return Boolean(isDryUnit && isLiquidUnit);
};

export const getServiceProductCostConversionFactor = (
    totalCostUnitGuid: string,
    serviceCostUnitGuid: string,
    density: string | number,
    props
) => {
    const { conversionFactors } = props;
    const fromCostUnit = getPriceUnit(totalCostUnitGuid, props);
    const fromUnitPhysicalState = getPriceUnitPhysicalState(totalCostUnitGuid, props);
    const toCostUnit = getPriceUnit(serviceCostUnitGuid, props);

    const serviceProductIsValid = ["ac", "ea"].indexOf(toCostUnit.label) === -1; // Only the rate based units are valid here;
    const serviceProductIsDry = ["lb", "ton"].indexOf(toCostUnit.label) !== -1;
    const fromUnitIsDry = fromUnitPhysicalState === PhysicalStates.DRY;

    if (serviceProductIsValid && fromCostUnit && toCostUnit) {
        const correctedUnitQuantityLabel = fromCostUnit.label === "oz" ? FLOZ : fromCostUnit.label;
        const correctedServiceUnitLabel = toCostUnit.label === "oz" ? FLOZ : toCostUnit.label;

        if (
            fromUnitIsDry === serviceProductIsDry &&
            correctedUnitQuantityLabel !== correctedServiceUnitLabel
        ) {
            // Dry to Dry or Liquid to Liquid
            return conversionFactors.get(fromCostUnit.label + toCostUnit.label);
        } else if (fromUnitIsDry && !serviceProductIsDry) {
            // Dry to Liquid
            const adjustedDensity = Number(density) * (1 / FT3_GAL);
            const rateUnitToPounds = conversionFactors.get(correctedUnitQuantityLabel + LB) || 1;
            const gallonsToCostUnit = conversionFactors.get(GAL + correctedServiceUnitLabel) || 1;
            return (rateUnitToPounds / adjustedDensity) * gallonsToCostUnit;
        } else if (!fromUnitIsDry && serviceProductIsDry) {
            // Liquid to Dry
            const rateUnitToGallons = conversionFactors.get(correctedUnitQuantityLabel + GAL) || 1;
            const poundsToCostUnit = conversionFactors.get(LB + correctedServiceUnitLabel) || 1;
            return rateUnitToGallons * Number(density) * poundsToCostUnit;
        }
    }

    return 1;
};

export const getProductRateConversionFactor = (
    rateUnitGuid: string,
    costUnitGuid: string,
    density: string | number,
    originalTargetRate: string | number,
    props
) => {
    const { conversionFactors } = props;
    const rateUnit = getRateUnit(rateUnitGuid, props);
    const fromUnitPhysicalState = getRateUnitPhysicalState(rateUnitGuid, props);
    const costUnit = getPriceUnit(costUnitGuid, props);
    const toUnitPhysicalState = getPriceUnitPhysicalState(costUnitGuid, props);

    if (rateUnit && costUnit) {
        const rateUnitQuantity = rateUnit.label.split("/")[0].replace(/ /g, ""); // This is all for fl oz
        const rateCostUnitQuantity = getRateQuantityUnitLabel(rateUnit);
        const isRateDependent = rateUnit.label.split("/")[1].toLowerCase() === PER100GAL;
        const rateConversion =
            isRateDependent && originalTargetRate ? Number(originalTargetRate) / 100 : 1;
        const isDryAndLiquidUnit = getIsDryAndLiquidUnit(costUnit.label, props);

        if (
            (fromUnitPhysicalState === toUnitPhysicalState ||
                (fromUnitPhysicalState === PhysicalStates.DRY && isDryAndLiquidUnit)) &&
            (fromUnitPhysicalState !== PhysicalStates.LIQUID || !isDryAndLiquidUnit)
        ) {
            if (costUnit.label !== rateCostUnitQuantity) {
                const correctedRateUnitQuantity =
                    fromUnitPhysicalState === PhysicalStates.LIQUID && rateUnitQuantity === OZ
                        ? FLOZ
                        : rateUnitQuantity;
                const correctedCostUnitQuantity =
                    toUnitPhysicalState === PhysicalStates.LIQUID && costUnit.label === OZ
                        ? FLOZ
                        : costUnit.label.replace(/ /g, "");
                const conversionName = correctedRateUnitQuantity + correctedCostUnitQuantity;
                return conversionFactors.get(conversionName) * rateConversion;
            }
            return rateConversion;
        } else if (fromUnitPhysicalState === PhysicalStates.DRY) {
            const adjustedDensity = Number(density) * (1 / FT3_GAL); // need to divide b/c we're actually converting the denominator (e.g. lb/ft3 to lb/gal)
            const rateUnitToPounds = conversionFactors.get(rateUnitQuantity + LB) || 1;
            const gallonsToCostUnit = conversionFactors.get(GAL + costUnit.label) || 1;
            return (rateUnitToPounds / adjustedDensity) * gallonsToCostUnit * rateConversion; // (lb/<unit> / (lb/gal)) * <unit>/gal
        } else if (fromUnitPhysicalState === PhysicalStates.LIQUID) {
            const correctedRateUnitQuantity = rateUnitQuantity === "oz" ? FLOZ : rateUnitQuantity;
            const rateUnitToGallons = conversionFactors.get(correctedRateUnitQuantity + GAL) || 1;
            const poundsToCostUnit = conversionFactors.get(LB + costUnit.label) || 1;
            return rateUnitToGallons * Number(density) * poundsToCostUnit; // this doesn't need to be adjusted like the dry product, b/c the gallons unit cancels (e.g. gal/<unit> * lb/gallon * <unit>/lb
        }
    }
    return 1;
};

export const getProductDensityValues = (
    fullProductInfo,
    product,
    targetRateUnitGuid: string,
    rateUnitGuid: string,
    props
) => {
    const productMixPhysicalState = getRateUnitPhysicalState(targetRateUnitGuid, props);
    const productPhysicalState = getRateUnitPhysicalState(rateUnitGuid, props);
    const densityOfWater =
        productPhysicalState === PhysicalStates.DRY ? DENSITY_WATER_LB_FT3 : DENSITY_WATER_LB_GAL;
    const dryProductInLiquidMix =
        productPhysicalState === PhysicalStates.DRY &&
        productMixPhysicalState === PhysicalStates.LIQUID;
    const productParentType = fullProductInfo?.productParentType.toLowerCase();
    const isDryFertilizerProduct =
        productPhysicalState === PhysicalStates.DRY &&
        productParentType === ProductMixTypes.FERTILIZER;
    const isDryChemicalProduct =
        productPhysicalState === PhysicalStates.DRY &&
        productParentType === ProductMixTypes.CHEMICAL;

    let density = fullProductInfo ? fullProductInfo.density : product.density;
    let specificGravity = fullProductInfo
        ? fullProductInfo.specificGravity
        : product.specificGravity;

    // If going into a Liquid mix, Dry Chemical products *require* density, Dry Fertilizer products *require* specific gravity
    if (dryProductInLiquidMix && isDryFertilizerProduct) {
        if (fullProductInfo == null || !fullProductInfo.specificGravity) {
            density = null;
            specificGravity = -1;
        } else {
            density = fullProductInfo.specificGravity * densityOfWater;
            specificGravity = fullProductInfo.specificGravity;
        }
    } else if (dryProductInLiquidMix && isDryChemicalProduct) {
        if (fullProductInfo == null || !fullProductInfo.density) {
            density = -1;
            specificGravity = null;
        } else {
            density = fullProductInfo.density;
            specificGravity = density / densityOfWater;
        }
    } else if (
        productPhysicalState === PhysicalStates.LIQUID &&
        (density === "" || density == null)
    ) {
        //Liquid products *require* density
        density = -1;
    }
    return { density, specificGravity };
};

export const getIsServiceProduct = (availableProducts, productGuid: string) => {
    const fullProductInfo = availableProducts.find(
        (product) => product.productGuid === productGuid
    );
    return Boolean(fullProductInfo?.productParentType === "Service");
};

export const getIsZeroedOut = (
    productMix: ProductMix,
    products: Record<string, any>,
    isEquation: boolean,
    isEquationComplete: boolean,
    targetRate: number
) => {
    return (
        +productMix.targetRate === 0 &&
        (targetRate == null || targetRate === 0) &&
        products.length === productMix.products.length &&
        products.every((p) => productMix.products.some((p2) => p2.productGuid === p.productGuid)) &&
        productMix.guaranteedAnalysis != null &&
        productMix.guaranteedAnalysis !== "0-0-0" &&
        isEquation &&
        isEquationComplete
    );
};

export const getTargetRateFromProducts = (
    productMixRateUnitGuid: string,
    productList: ProductMixProduct[],
    props
) => {
    let targetRateInLbs = null;
    let targetRateInBlendUnit = null;
    const lbCostUnit = getPriceUnitByName(LB, props, PhysicalStates.DRY);
    const lbRateUnit = getRateUnitByName(LBPERAC, props);
    const productRateUnit = getRateUnit(productMixRateUnitGuid, props);
    for (const product of productList) {
        if (getIsServiceProduct(props.availableProducts, product.productGuid)) {
            continue;
        }
        const lineRateToLineCostConversionFactor = getProductRateConversionFactor(
            product.rateUnitGuid,
            lbCostUnit.value,
            product.density,
            product.rate,
            props
        );
        targetRateInLbs += product.rate * lineRateToLineCostConversionFactor;
    }
    for (const product of productList) {
        if (getIsServiceProduct(props.availableProducts, product.productGuid)) {
            continue;
        }
        const productPhysicalState = getRateUnitPhysicalState(product.rateUnitGuid, props);
        const revisedDensity =
            productPhysicalState === PhysicalStates.LIQUID
                ? product.density * FT3_GAL
                : product.density;
        const poundsOfProduct = targetRateInLbs * product.percentOfMix;
        const blendCostUnit = getPriceUnitFromRateUnit(productRateUnit, props);
        const poundsToBlendUnitConversion = getProductRateConversionFactor(
            lbRateUnit.value,
            blendCostUnit.value,
            revisedDensity,
            product.rate,
            props
        );
        targetRateInBlendUnit += poundsOfProduct * poundsToBlendUnitConversion;
    }
    return targetRateInBlendUnit;
};
export const mergeRecDetailInfo = (batchRecDetailsForEdit: RecAPI.IRecDetails[]) => {
    const batchRecAreaPolygonArea = batchRecDetailsForEdit.reduce((batchArea, recDetails) => {
        return (
            batchArea +
            recDetails.recAreaList.reduce((recPolygonArea, recArea) => {
                return recPolygonArea + (recArea.applyRecToArea ? recArea.calculatedArea : 0);
            }, 0)
        );
    }, 0);

    const aggregatedRec = new models.Rec();
    const nutrientGuidToBatchRecNutrient = new Map();
    const nutrientGuidToAppliedArea = new Map();

    for (const recDetails of batchRecDetailsForEdit) {
        for (const recArea of recDetails.recAreaList) {
            if (!recArea.applyRecToArea) {
                continue;
            }
            for (const rec of recArea.recs) {
                for (const recNutrient of rec.recNutrientList) {
                    const existingNutrientAppliedArea = nutrientGuidToAppliedArea.has(
                        recNutrient.nutrientGuid
                    )
                        ? nutrientGuidToAppliedArea.get(recNutrient.nutrientGuid)
                        : 0;
                    nutrientGuidToAppliedArea.set(
                        recNutrient.nutrientGuid,
                        existingNutrientAppliedArea +
                            +recNutrient.averageAdjustedRecNutrientResult.appliedArea
                    );
                }
            }
        }
    }

    for (const recDetails of batchRecDetailsForEdit) {
        for (const recArea of recDetails.recAreaList) {
            if (!recArea.applyRecToArea) {
                continue;
            }
            const recAreaWeight = recArea.calculatedArea / batchRecAreaPolygonArea;

            for (const rec of recArea.recs) {
                for (const recNutrient of rec.recNutrientList) {
                    const applicableAppliedArea = +recNutrient.averageAdjustedRecNutrientResult
                        .appliedArea
                        ? +recNutrient.averageAdjustedRecNutrientResult.appliedArea
                        : 0;
                    const applicableNutrientForArea =
                        nutrientGuidToAppliedArea.get(recNutrient.nutrientGuid) || 0;
                    const recNutrientAppliedAreaWeight =
                        applicableAppliedArea / applicableNutrientForArea || 0;

                    const batchRecNutrientExistsAlready = nutrientGuidToBatchRecNutrient.has(
                        recNutrient.nutrientGuid
                    );
                    const existingBatchRecNutrient = batchRecNutrientExistsAlready
                        ? nutrientGuidToBatchRecNutrient.get(recNutrient.nutrientGuid)
                        : {
                              ...recNutrient,
                              recNutrientGuid: "BATCH_REC_NUTRIENT_" + recNutrient.nutrientGuid,
                              recGuid: "BATCH_REC",
                              averageRecNutrientResult: new models.RecNutrientResult(),
                              averageCreditRecNutrientResult: new models.RecNutrientResult(),
                              averageAdjustedRecNutrientResult: new models.RecNutrientResult(),
                              recNutrientProductMix: new models.RecNutrientProductMix(),
                              creditedGridCells: [],
                              equationGuid: "BATCH_EQUATION_" + recNutrient.nutrientGuid,
                          };

                    // It's been cleared already from a product mismatch, carry on
                    if (batchRecNutrientExistsAlready && existingBatchRecNutrient.recGuid === "") {
                        continue;
                    } else if (
                        batchRecNutrientExistsAlready &&
                        (existingBatchRecNutrient.recNutrientProductMix.name !==
                            recNutrient.recNutrientProductMix.name ||
                            existingBatchRecNutrient.unitGuid !== recNutrient.unitGuid)
                    ) {
                        // Mismatch, clear it all out
                        const clearedBatchRecNutrient = new models.RecNutrient();
                        clearedBatchRecNutrient.recNutrientGuid =
                            "BATCH_REC_NUTRIENT_" + existingBatchRecNutrient.nutrientGuid;
                        clearedBatchRecNutrient.nutrientGuid =
                            existingBatchRecNutrient.nutrientGuid;
                        clearedBatchRecNutrient.equationGuid =
                            "BATCH_EQUATION_" + existingBatchRecNutrient.nutrientGuid;
                        clearedBatchRecNutrient.nutrientName =
                            existingBatchRecNutrient.nutrientName;
                        clearedBatchRecNutrient.equationName =
                            existingBatchRecNutrient.equationName;
                        nutrientGuidToBatchRecNutrient.set(
                            recNutrient.nutrientGuid,
                            clearedBatchRecNutrient
                        );
                    } else {
                        // Add or aggregate the values
                        aggregatedRec.equationGroupGuid = rec.equationGroupGuid;
                        const averageRecNutrientResult = {
                            recNutrientGuid:
                                "BATCH_REC_NUTRIENT_" + existingBatchRecNutrient.equationGuid,
                            productRate:
                                (+existingBatchRecNutrient.averageRecNutrientResult.productRate ||
                                    0) +
                                (+recNutrient.averageRecNutrientResult.productRate || 0) *
                                    recAreaWeight,
                            nutrientRate:
                                (+existingBatchRecNutrient.averageRecNutrientResult.nutrientRate ||
                                    0) +
                                (+recNutrient.averageRecNutrientResult.nutrientRate || 0) *
                                    recAreaWeight,
                            totalProduct:
                                (+existingBatchRecNutrient.averageRecNutrientResult.totalProduct ||
                                    0) + (+recNutrient.averageRecNutrientResult.totalProduct || 0),
                            area:
                                (+existingBatchRecNutrient.averageRecNutrientResult.area || 0) +
                                (+recNutrient.averageRecNutrientResult.area || 0),
                            minRate: getMinValue(
                                existingBatchRecNutrient.averageRecNutrientResult.minRate,
                                recNutrient.averageRecNutrientResult.minRate
                            ),
                            maxRate: Math.max(
                                +existingBatchRecNutrient.averageRecNutrientResult.maxRate || 0,
                                +recNutrient.averageRecNutrientResult.maxRate || 0
                            ),
                            appliedNutrientRate: (
                                (+existingBatchRecNutrient.averageRecNutrientResult
                                    .appliedNutrientRate || 0) +
                                (+recNutrient.averageRecNutrientResult.appliedNutrientRate || 0) *
                                    recNutrientAppliedAreaWeight
                            ).toFixed(2),
                            appliedProductRate: (
                                (+existingBatchRecNutrient.averageRecNutrientResult
                                    .appliedProductRate || 0) +
                                (+recNutrient.averageRecNutrientResult.appliedProductRate || 0) *
                                    recNutrientAppliedAreaWeight
                            ).toFixed(2),
                            appliedArea:
                                (+existingBatchRecNutrient.averageRecNutrientResult.appliedArea ||
                                    0) + (+recNutrient.averageRecNutrientResult.appliedArea || 0),
                        };
                        const averageCreditRecNutrientResult = {
                            recNutrientGuid:
                                "BATCH_REC_NUTRIENT_" + existingBatchRecNutrient.nutrientGuid,
                            productRate:
                                (+existingBatchRecNutrient.averageCreditRecNutrientResult
                                    .productRate || 0) +
                                (+recNutrient.averageCreditRecNutrientResult.productRate || 0) *
                                    recAreaWeight,
                            nutrientRate:
                                (+existingBatchRecNutrient.averageCreditRecNutrientResult
                                    .nutrientRate || 0) +
                                (+recNutrient.averageCreditRecNutrientResult.nutrientRate || 0) *
                                    recAreaWeight,
                            totalProduct:
                                (+existingBatchRecNutrient.averageCreditRecNutrientResult
                                    .totalProduct || 0) +
                                (+recNutrient.averageCreditRecNutrientResult.totalProduct || 0),
                            area:
                                (+existingBatchRecNutrient.averageCreditRecNutrientResult.area ||
                                    0) + (+recNutrient.averageCreditRecNutrientResult.area || 0),
                            minRate: getMinValue(
                                existingBatchRecNutrient.averageCreditRecNutrientResult.minRate,
                                recNutrient.averageCreditRecNutrientResult.minRate
                            ),
                            maxRate: Math.max(
                                +existingBatchRecNutrient.averageCreditRecNutrientResult.maxRate ||
                                    0,
                                +recNutrient.averageCreditRecNutrientResult.maxRate || 0
                            ),
                            appliedNutrientRate: (
                                (+existingBatchRecNutrient.averageCreditRecNutrientResult
                                    .appliedNutrientRate || 0) +
                                (+recNutrient.averageCreditRecNutrientResult.appliedNutrientRate ||
                                    0) *
                                    recNutrientAppliedAreaWeight
                            ).toFixed(2),
                            appliedProductRate: (
                                (+existingBatchRecNutrient.averageCreditRecNutrientResult
                                    .appliedProductRate || 0) +
                                (+recNutrient.averageCreditRecNutrientResult.appliedProductRate ||
                                    0) *
                                    recNutrientAppliedAreaWeight
                            ).toFixed(2),
                            appliedArea:
                                (+existingBatchRecNutrient.averageCreditRecNutrientResult
                                    .appliedArea || 0) +
                                (+recNutrient.averageCreditRecNutrientResult.appliedArea || 0),
                        };
                        const averageAdjustedRecNutrientResult = {
                            recNutrientGuid:
                                "BATCH_REC_NUTRIENT_" + existingBatchRecNutrient.nutrientGuid,
                            productRate:
                                (+existingBatchRecNutrient.averageAdjustedRecNutrientResult
                                    .productRate || 0) +
                                (+recNutrient.averageAdjustedRecNutrientResult.productRate || 0) *
                                    recAreaWeight,
                            nutrientRate:
                                (+existingBatchRecNutrient.averageAdjustedRecNutrientResult
                                    .nutrientRate || 0) +
                                (+recNutrient.averageAdjustedRecNutrientResult.nutrientRate || 0) *
                                    recAreaWeight,
                            totalProduct:
                                (+existingBatchRecNutrient.averageAdjustedRecNutrientResult
                                    .totalProduct || 0) +
                                (+recNutrient.averageAdjustedRecNutrientResult.totalProduct || 0),
                            area:
                                (existingBatchRecNutrient.averageAdjustedRecNutrientResult.area ||
                                    0) + (+recNutrient.averageAdjustedRecNutrientResult.area || 0),
                            minRate: getMinValue(
                                existingBatchRecNutrient.averageAdjustedRecNutrientResult.minRate,
                                recNutrient.averageAdjustedRecNutrientResult.minRate
                            ),
                            maxRate:
                                Math.max(
                                    +existingBatchRecNutrient.averageAdjustedRecNutrientResult
                                        .maxRate || 0,
                                    +recNutrient.averageAdjustedRecNutrientResult.maxRate || 0
                                ) + "",
                            appliedNutrientRate: (
                                (+existingBatchRecNutrient.averageAdjustedRecNutrientResult
                                    .appliedNutrientRate || 0) +
                                (+recNutrient.averageAdjustedRecNutrientResult
                                    .appliedNutrientRate || 0) *
                                    recNutrientAppliedAreaWeight
                            ).toFixed(2),
                            appliedProductRate: (
                                (+existingBatchRecNutrient.averageAdjustedRecNutrientResult
                                    .appliedProductRate || 0) +
                                (+recNutrient.averageAdjustedRecNutrientResult.appliedProductRate ||
                                    0) *
                                    recNutrientAppliedAreaWeight
                            ).toFixed(2),
                            appliedArea:
                                (+existingBatchRecNutrient.averageAdjustedRecNutrientResult
                                    .appliedArea || 0) +
                                (+recNutrient.averageAdjustedRecNutrientResult.appliedArea || 0),
                            minimumExcludeZeros:
                                existingBatchRecNutrient.recNutrientParameters.minimumExcludeZeros,
                            minimumIncludeZeros:
                                existingBatchRecNutrient.recNutrientParameters.minimumIncludeZeros,
                            zerosBelowMinimum:
                                existingBatchRecNutrient.recNutrientParameters.zerosBelowMinimum,
                        };
                        const newTargetRate =
                            (+existingBatchRecNutrient.recNutrientProductMix.targetRate || 0) +
                            (+recNutrient.recNutrientProductMix.targetRate || 0) * recAreaWeight;

                        const recNutrientProductMix = {
                            ...recNutrient.recNutrientProductMix,
                            recNutrientProductMixGuid:
                                "BATCH_REC_NUTRIENT_PRODUCT_MIX_" +
                                existingBatchRecNutrient.nutrientGuid,
                            targetRate: newTargetRate,
                            limeEfficiency:
                                recNutrient.recNutrientProductMix.limeEfficiency != null
                                    ? +existingBatchRecNutrient.recNutrientProductMix
                                          .limeEfficiency +
                                      +recNutrient.recNutrientProductMix.limeEfficiency *
                                          recAreaWeight
                                    : null,
                            products: recNutrient.recNutrientProductMix.products.map((prod) => {
                                const existingProduct =
                                    existingBatchRecNutrient.recNutrientProductMix.products.find(
                                        (p) => p.productGuid === prod.productGuid
                                    ) || {};
                                const existingProductRate = +existingProduct.rate || 0;
                                const thisProductRate =
                                    recNutrient.recNutrientProductMix.products.length === 1
                                        ? Number(
                                              +recNutrient.recNutrientProductMix.targetRate *
                                                  recAreaWeight
                                          )
                                        : Number(Number(prod.rate) * recAreaWeight);
                                const revisedRate = existingProductRate + thisProductRate;

                                return {
                                    ...prod,
                                    rate: revisedRate,
                                    limeEfficiency:
                                        recNutrient.recNutrientProductMix.limeEfficiency != null
                                            ? +existingBatchRecNutrient.recNutrientProductMix
                                                  .limeEfficiency +
                                              +recNutrient.recNutrientProductMix.limeEfficiency *
                                                  recAreaWeight
                                            : null,
                                };
                            }),
                        };
                        const recNutrientParameters = new models.RecNutrientParameters();
                        recNutrientParameters.minimumRate =
                            averageAdjustedRecNutrientResult.minRate?.toString();
                        recNutrientParameters.maximumRate =
                            averageAdjustedRecNutrientResult.maxRate;
                        recNutrientParameters.switchRate =
                            existingBatchRecNutrient.recNutrientParameters.switchRate;
                        recNutrientParameters.minimumExcludeZeros =
                            averageAdjustedRecNutrientResult.minimumExcludeZeros;
                        recNutrientParameters.minimumIncludeZeros =
                            averageAdjustedRecNutrientResult.minimumIncludeZeros;
                        recNutrientParameters.zerosBelowMinimum =
                            averageAdjustedRecNutrientResult.zerosBelowMinimum;
                        recNutrientParameters.zerosBelowSwitchRate =
                            existingBatchRecNutrient.recNutrientParameters.zerosBelowSwitchRate;
                        recNutrientParameters.percentAdjustment =
                            (averageAdjustedRecNutrientResult.productRate /
                                averageCreditRecNutrientResult.productRate) *
                                100 +
                            "";

                        const newBatchRecNutrient = {
                            ...existingBatchRecNutrient,
                            recNutrientGuid:
                                "BATCH_REC_NUTRIENT_" + existingBatchRecNutrient.nutrientGuid,
                            creditedGridCells: existingBatchRecNutrient.creditedGridCells.concat(
                                ...(recNutrient.creditedGridCells || [])
                            ),
                            averageRecNutrientResult,
                            averageCreditRecNutrientResult,
                            averageAdjustedRecNutrientResult,
                            recNutrientParameters,
                            recNutrientProductMix,
                            equationGuid: "BATCH_EQUATION_" + existingBatchRecNutrient.nutrientGuid,
                        };

                        nutrientGuidToBatchRecNutrient.set(
                            recNutrient.nutrientGuid,
                            newBatchRecNutrient
                        );
                    }
                }
            }
        }
    }

    aggregatedRec.recNutrientList = Array.from(nutrientGuidToBatchRecNutrient.values());
    aggregatedRec.recGuid = "BATCH_REC";
    aggregatedRec.isIncluded = true;
    const batchRecArea = new models.RecArea(null, [aggregatedRec], batchRecAreaPolygonArea);
    batchRecArea.applyRecToArea = true;
    return [batchRecArea];
};
export const validateDensityValues = (density: number, specificGravity: number) => {
    if (density === -1) {
        return validationErrors.MISSING_DENSITY;
    } else if (specificGravity === -1) {
        return validationErrors.DRY_FERT_MISSING_SG;
    }
    return 0;
};

export const initializeProduct = (
    productMix: ProductMix | models.RecNutrientProductMix,
    product,
    props
) => {
    const { availableProducts, calculatedArea, productBlendPicklists } = props;
    const { productMixTypes } = productBlendPicklists;

    const isCustomProduct = Boolean(product.customProductGuid);
    const fullProductInfo = availableProducts.find(
        (fullProduct) => fullProduct.productGuid === product.productGuid
    );

    const isServiceProduct = getIsServiceProduct(props.availableProducts, product.productGuid);

    const hasCostUnit = Boolean(product.costUnitGuid);
    const rateUnit = isServiceProduct
        ? null
        : getRateUnit(product.rateUnitGuid || fullProductInfo.defaultRateUnitGuid, props);

    const costUnit = hasCostUnit
        ? getPriceUnit(product.costUnitGuid, props)
        : isServiceProduct
        ? getPriceUnitByName("ac", props, "Service")
        : fullProductInfo != null && fullProductInfo.defaultPriceUnitGuid
        ? getPriceUnit(fullProductInfo.defaultPriceUnitGuid, props)
        : getPriceUnitFromRateUnit(rateUnit, props);

    // eslint-disable-next-line prefer-const
    let { density, specificGravity } = isServiceProduct
        ? { density: null, specificGravity: null }
        : getProductDensityValues(
              fullProductInfo,
              product,
              productMix.targetRateUnitGuid,
              rateUnit.value,
              props
          );
    // This is just a bit of back-protection for any existing blends that could have bunk data (which would then be un-editable
    // if we break in the initialization.  If they add or update _anything_ it will throw an error and remove the bad products.
    // The only time density will be -1 is for a dry product in a liquid mix that is missing the correct information.
    density = density || 1;
    if (isCustomProduct) {
        density = productMix.density || product.density;
    }

    const nutrientList = isServiceProduct
        ? []
        : isCustomProduct
        ? productMix.nutrients
        : product.nutrients || (fullProductInfo != null ? fullProductInfo.nutrientList : []);

    const isArea = product.costUnitGuid === getPriceUnitByName("ac", props, "Service")?.value;
    const isEach = product.costUnitGuid === getPriceUnitByName("ea", props, "Service")?.value;

    const productMixType = productMixTypes.find(
        (type) => type.value === productMix.productMixTypeGuid
    )?.label;

    const defaultServiceProductCostUnitGuid = getPriceUnitByName("ac", props, "Service")?.value;

    const serviceProductDefaultRate = !isServiceProduct
        ? null
        : isArea
        ? calculatedArea
        : isEach
        ? 1
        : !isArea && !isEach
        ? Number(productMix.totalProduct) *
          getServiceProductCostConversionFactor(
              getEffectiveCostUnitGuid({ ...productMix, productMixType }, productMix.products),
              product.costUnitGuid || defaultServiceProductCostUnitGuid,
              productMix.density,
              props
          )
        : product.totalProduct;

    const productRate = isServiceProduct
        ? product.rate || serviceProductDefaultRate
        : getProductRateInMix(density, productMix, product, rateUnit, props);

    const newProduct = {
        ...product,
        rate: roundValue(productRate),
        rateUnitGuid: isServiceProduct ? null : rateUnit.value,
        rateUnit: isServiceProduct ? null : rateUnit.label,
        costUnit: costUnit ? costUnit.label : "",
        costUnitGuid:
            isServiceProduct && !product.costUnitGuid
                ? defaultServiceProductCostUnitGuid
                : costUnit
                ? costUnit.value
                : null,
        density:
            (!density || !product.density) && productMix.products.length === 1
                ? productMix.density
                : density,
        densityUnitGuid:
            product.densityUnitGuid || (fullProductInfo ? fullProductInfo.densityUnitGuid : null),
        physicalStateGuid:
            product.physicalStateGuid ||
            (fullProductInfo ? fullProductInfo.physicalStateGuid : null),
        physicalState: fullProductInfo
            ? fullProductInfo.physicalState
            : product.physicalState || null,
        productParentType: fullProductInfo?.productParentType,
        nutrientList: [...nutrientList],
        specificGravity,
        productName: fullProductInfo ? fullProductInfo.name : product.name,
    };
    return {
        ...newProduct,
        ...calculate(productMix, newProduct, {}, 0, props), // Just a recalc, nothing changed
    };
};

export const initializeRecNutrientParameters = (
    averageAdjustedRecNutrientResult: RecNutrientResult,
    recNutrientParameters: RecNutrientParameters,
    creditsApplied: boolean
): RecNutrientParameters => {
    const hasBeenAdjusted =
        roundValue(Number(averageAdjustedRecNutrientResult.percentAdjustment), 1) !== 100 ||
        creditsApplied;
    const trueMin =
        hasBeenAdjusted || recNutrientParameters.minimumLock
            ? averageAdjustedRecNutrientResult.minRate
            : averageAdjustedRecNutrientResult.baseProductMin;

    const trueMax =
        hasBeenAdjusted || recNutrientParameters.maximumLock
            ? averageAdjustedRecNutrientResult.maxRate
            : averageAdjustedRecNutrientResult.baseProductMax;
    return {
        ...recNutrientParameters,
        minimumRate: trueMin,
        maximumRate: trueMax,
    };
};

export const isZeroRec = (recNutrient: models.RecNutrient) => {
    return Boolean(
        recNutrient &&
            recNutrient.averageRecNutrientResult &&
            Number(recNutrient.averageRecNutrientResult.productRate) === 0
    );
};

export const getEffectiveCostUnitGuid = (
    productMix: ProductMix | models.RecNutrientProductMix,
    products
) => {
    const effectiveCostProduct =
        productMix.productMixType.toLowerCase() === ProductMixTypes.CHEMICAL
            ? products.find((product) => product.isCarrier)
            : products.find(
                  (product) => product.productParentType !== "Service" && product.costUnitGuid
              );
    return effectiveCostProduct ? effectiveCostProduct.costUnitGuid : null;
};

export const rectifyAdjustment = (
    initialGridCalcResult,
    prevAppliedRate: number,
    calculatedArea: number,
    prevNonMinMaxSumRate: number,
    effectiveFieldRate: number,
    minimumIncludeZeros: boolean,
    desiredPercentAdjustment: number,
    isMinLock: boolean,
    isMaxLock: boolean
) => {
    const { gridCells, minimumRate, maximumRate, switchRate } = initialGridCalcResult;
    let newMinimum = isMinLock ? minimumRate : null;
    let newMaximum = isMaxLock ? maximumRate : 0;
    let newSwitchRate = isMinLock ? switchRate : null;

    // Calculate the current (as of the last round) adjustment percent using the summations from the previous iteration.
    const currentPercentAdjustment = roundValue(prevAppliedRate / effectiveFieldRate) * 100;

    // If we're already good to go, return the grid cells.
    if (Math.abs(desiredPercentAdjustment - currentPercentAdjustment) < ACCEPTABLE_RATE_ERROR) {
        return initialGridCalcResult;
    }

    // The applied sum rate and cell count are the summation of all applied cells (and the number of them)
    let appliedProduct = 0;

    // The non min/max sum and cell count are the summation of all cells not capped by a min/max ruling (and the number of them)
    let nonMinMaxSumRate = 0;

    // The rate discrepancy is the difference between the grid calculated adjustment that satisfies the grid values
    // and the value requested by the user (between the min and max values)
    const rateDiscrepancy = (currentPercentAdjustment - desiredPercentAdjustment) / 100;
    const shouldDecrease = rateDiscrepancy > 0;
    const rateSumDifference = rateDiscrepancy * effectiveFieldRate * calculatedArea;

    let finalGridCalcResult = {
        gridCells,
        minimumRate,
        maximumRate,
        switchRate,
    };

    const newCreditedGridCells = gridCells.map((gridCell) => {
        // It's already been capped at the bottom/top, so let it be. (MIN/MAX LOCK)
        if (
            gridCell.productRate === 0 ||
            (isMinLock && gridCell.productRate === minimumRate && shouldDecrease) ||
            (isMaxLock && gridCell.productRate === maximumRate && !shouldDecrease)
        ) {
            appliedProduct += Number(gridCell.productRate) * Number(gridCell.cellArea);

            return {
                ...gridCell,
            };
        }

        // Get the proportion of the non-capped sum value that this cell represents
        // This will be used as the weight with which it's share of the adjustment will be determined.
        const proportionOfNonMinMaxRate =
            (gridCell.productRate * gridCell.cellArea) / prevNonMinMaxSumRate;
        const necessaryAdjustment = rateSumDifference * proportionOfNonMinMaxRate;
        let adjustedProductRate =
            (gridCell.productRate * gridCell.cellArea - necessaryAdjustment) / gridCell.cellArea;
        let productRate = adjustedProductRate;

        // It's very likely that the necessary adjustment could take it below/above the min/max,
        // so we still need to cap the value at those boundaries (if that bound is locked).
        // Obviously, if that's the case, it means that the rates are still going to be diverged,
        // which means we'll have to circle the airstrip another time.
        // However, if one or the other is unlocked, we need to adjust it per the new cell rate.
        if (!isMaxLock) {
            newMaximum = Math.max(newMaximum, adjustedProductRate);
        } else {
            productRate = Math.min(maximumRate, adjustedProductRate);
            adjustedProductRate = productRate;
        }
        if (!isMinLock) {
            newMinimum =
                newMinimum == null
                    ? adjustedProductRate
                    : Math.min(newMinimum, adjustedProductRate);
            newSwitchRate =
                newSwitchRate == null
                    ? switchRate * (newMinimum / minimumRate)
                    : Math.min(newSwitchRate, adjustedProductRate);
        } else {
            productRate = Math.max(minimumRate, adjustedProductRate);
        }

        // If the new rate wasn't caught at the min and max, add it to the summation for the next round (if needed).
        // We can count something that's at the boundary, iff the adjustment is moving away from said boundary
        // i.e. If the values are increasing, being at the minimum doesn't discount the cell from being adjusted in future iterations (or vice versa).
        if (
            (!isMinLock || productRate !== newMinimum || !shouldDecrease) &&
            (!isMaxLock || productRate !== newMaximum || shouldDecrease)
        ) {
            nonMinMaxSumRate += Number(productRate) * Number(gridCell.cellArea);
        }

        appliedProduct += Number(productRate) * Number(gridCell.cellArea);
        return {
            ...gridCell,
            productRate,
        };
    });

    // Calculate what the percent adjust would be with this grid set, using the summation of the rate and the applied cell count.
    const newRate = roundValue(appliedProduct / calculatedArea);
    const newPercentAdjustment = roundValue((newRate / effectiveFieldRate) * 100);

    // If there's some issue with the calculation, we really don't want to crawl down the rabbit hole (since it will never converge).
    // Ditto if it's gotten to a point where it's no longer changing
    if (!isNaN(newPercentAdjustment) && currentPercentAdjustment !== newPercentAdjustment) {
        // If the percent adjusts are still diverged, rectify with the information gathered in this iteration.
        if (
            !(newRate === prevAppliedRate) &&
            Math.abs(desiredPercentAdjustment - newPercentAdjustment) > ACCEPTABLE_RATE_ERROR
        ) {
            const intermediateGridCalcResults = {
                gridCells: newCreditedGridCells,
                minimumRate: newMinimum,
                maximumRate: newMaximum,
                switchRate: newSwitchRate,
            };

            finalGridCalcResult = rectifyAdjustment(
                intermediateGridCalcResults,
                newRate,
                calculatedArea,
                nonMinMaxSumRate,
                effectiveFieldRate,
                minimumIncludeZeros,
                desiredPercentAdjustment,
                isMinLock,
                isMaxLock
            );
        } else {
            // Looks like we made it, set the final, and return it to the primary adjustment function
            finalGridCalcResult = {
                gridCells: newCreditedGridCells,
                minimumRate: newMinimum,
                maximumRate: newMaximum,
                switchRate: newSwitchRate,
            };
        }
    }
    return finalGridCalcResult;
};

export const replaceRecNutrients = (
    batchRecDetailsForEdit: RecAPI.IRecDetails[],
    newCreditedRecNutrients: models.RecNutrient[]
) => {
    const revisedBatchRecDetailsForEdit = [];
    for (const recDetails of batchRecDetailsForEdit) {
        const newRecAreaList = [];
        for (const recArea of recDetails.recAreaList) {
            if (!recArea.applyRecToArea) {
                continue;
            }
            let revisedRec: any = {};
            for (const rec of recArea.recs) {
                const newRecNutrientList = rec.recNutrientList.map(
                    (recNutrient) =>
                        newCreditedRecNutrients.find(
                            (newRecNutrient) =>
                                newRecNutrient.recNutrientGuid === recNutrient.recNutrientGuid
                        ) || recNutrient
                );
                revisedRec = {
                    ...rec,
                    recNutrientList: newRecNutrientList,
                };
            }
            const newRecs = recArea.recs.map((rec) =>
                rec.recGuid === revisedRec.recGuid ? revisedRec : rec
            );
            const revisedRecArea = {
                ...recArea,
                recs: newRecs,
            };
            newRecAreaList.push(revisedRecArea);
        }

        const revisedRecDetail = {
            ...recDetails,
            recAreaList: newRecAreaList,
        };
        revisedBatchRecDetailsForEdit.push(revisedRecDetail);
    }
    return revisedBatchRecDetailsForEdit;
};

export const validateAdjustment = (
    newProps,
    minimumRate: number,
    isMinLock: boolean,
    maximumRate: number,
    isMaxLock: boolean,
    calculatedArea: number,
    appliedArea: number,
    effectiveFieldRate: number,
    switchRate: number
) => {
    const validationCodes = [];
    if (
        newProps.minimumRate != null ||
        newProps.maximumRate != null ||
        newProps.switchRate != null
    ) {
        if (+maximumRate < +minimumRate) {
            // User changed the minimum, so tailor the error to that fact
            if (newProps.minimumRate) {
                validationCodes.push(validationErrors.MIN_TOO_HIGH);
            } else if (newProps.maximumRate) {
                // same as above, with max rate
                validationCodes.push(validationErrors.MAX_TOO_LOW);
            }
        }
        if (+switchRate > +minimumRate) {
            validationCodes.push(validationErrors.SWITCH_TOO_HIGH);
        }
    } else if (newProps.targetRate) {
        const fieldAppliedProduct = effectiveFieldRate * calculatedArea;
        const effectiveAdjustPercent = roundValue((newProps.targetRate / effectiveFieldRate) * 100);

        const hardMinimumAdjustPercent = roundValue(
            ((minimumRate * appliedArea) / fieldAppliedProduct) * 100
        );
        const hardMaximumAdjustPercent = roundValue(
            ((maximumRate * appliedArea) / fieldAppliedProduct) * 100
        );

        // Sometimes the effective adjust percent and the calculated hard minimum have some rounding issues that
        // cause the numbers to not exactly correlate to reality.  e.g. the minimum is locked at '60' and the hard
        // minimum, having been rounded, would put the corresponding minimum target rate at '60.01' or some such.
        // Luckily, with the minimum, we can short-circuit the check and accept that the rate can only be too low
        // if it _does not_ equal the locked minimum.  If all cells are applied, the lowest possible target rate would be
        // the same as the minimum, and if there are zero areas, the lowest rate would be lower, so for an adjustment to be _too_
        // low, it would have to be at or below the minimum rate, regardless of the applied area.
        const isAdjustTooLow =
            isMinLock &&
            Number(newProps.targetRate) !== Number(minimumRate) &&
            effectiveAdjustPercent < hardMinimumAdjustPercent;
        const isAdjustTooHigh = isMaxLock && effectiveAdjustPercent > hardMaximumAdjustPercent;

        if (isAdjustTooLow) {
            validationCodes.push(validationErrors.ADJUST_BELOW_LOCKED_MIN);
        }
        if (isAdjustTooHigh) {
            validationCodes.push(validationErrors.ADJUST_ABOVE_LOCKED_MAX);
        }
    } else if (newProps.percentAdjustment != null) {
        const fieldAppliedProduct = effectiveFieldRate * calculatedArea;

        const hardMinimumAdjustPercent = roundValue(
            ((minimumRate * appliedArea) / fieldAppliedProduct) * 100
        );
        const hardMaximumAdjustPercent = roundValue(
            ((maximumRate * appliedArea) / fieldAppliedProduct) * 100
        );

        const isAdjustTooLow = isMinLock && newProps.percentAdjustment < hardMinimumAdjustPercent;
        const isAdjustTooHigh = isMaxLock && newProps.percentAdjustment > hardMaximumAdjustPercent;

        if (isAdjustTooLow) {
            validationCodes.push(validationErrors.ADJUST_BELOW_LOCKED_MIN);
        }
        if (isAdjustTooHigh) {
            validationCodes.push(validationErrors.ADJUST_ABOVE_LOCKED_MAX);
        }
    }
    return validationCodes;
};

const sortMap = new Map<string, number>([
    // Rates
    ["oz/ac", 1],
    ["lb/ac", 2],
    ["ton/ac", 3],
    ["fl oz/ac", 30],
    ["pt/ac", 31],
    ["qt/ac", 32],
    ["gal/ac", 33],
    ["oz/100gal", 34],
    ["lb/100gal", 35],
    ["pt/100gal", 36],
    ["qt/100gal", 37],
    ["gal/100gal", 38],
    ["mL/ac", 39],
    ["L/ac", 40],
    ["g/ac", 41],
    ["kg/ac", 42],
    ["mt/ac", 43],
    // Total
    ["fl oz", 45],
    ["oz", 46],
    ["pt", 47],
    ["qt", 48],
    ["gal", 49],
    ["oz", 50],
    ["lb", 51],
    ["ton", 52],
    ["mL", 53],
    ["L", 54],
    ["g", 55],
    ["kg", 56],
    ["mt", 57],
]);

export const sortUnits = (a, b) => {
    const aValue = sortMap.get(a.name) || 100;
    const bValue = sortMap.get(b.name) || 100;
    if (aValue === bValue) {
        const aId = Number(a.id);
        const bId = Number(b.id);
        return aId < bId ? -1 : bId < aId ? 1 : 0;
    }
    return aValue - bValue;
};
