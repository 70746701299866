import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CROP_CLASS_GUID = "cropClassNameGuid";
export const PROPS_CROP_CLASS_ID = "cropClassId";
export const PROPS_CROP_CLASS_NAME = "cropClassName";
export const PROPS_CROP_CLASS_NAME_LIST = "cropClassNameList";
export const PROPS_CROP_GUID = "cropGuid";
export const PROPS_CROP_ID = "cropId";
export const PROPS_CROP_NAME = "cropName";
export const PROPS_CROP_PURPOSE = "cropPurpose";
export const PROPS_CROP_PURPOSE_GUID = "cropPurposeGuid";
export const PROPS_CROP_PURPOSE_ID = "cropPurposeId";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_NUTRIENT_GUID = "nutrientGuid";
export const PROPS_NUTRIENT_ID = "nutrientId";
export const PROPS_NUTRIENT_NAME = "nutrientName";
export const PROPS_NUTRIENT_REMOVAL_RATE_GUID = adminData.PROPS_ID;
export const PROPS_NUTRIENT_REMOVAL_RATE_ID = adminData.PROPS_INTEGER_ID;
export const PROPS_RATE = "rate";
export const PROPS_RATE_UNIT = "rateUnit";
export const PROPS_RATE_UNIT_GUID = "rateUnitGuid";
