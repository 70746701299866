import { connect } from "react-redux";
import { withMasked, withApiResult } from "~/hocs";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { actions } from "./data";
import * as selectors from "./data/selectors";
import SystemProfilerView from "./system-profile";
import { getApiErrorCodeList } from "~/core/api/selectors";

const mapStateToProps = (state) => ({
    apiErrors: getApiErrorCodeList(state),
    systemSettings: selectors.getSystemSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchSystemSettings: actions.fetchSystemSettings,
    updateSystemSettings: actions.updateSystemSettings,
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
});

export default withMasked(
    withApiResult(connect(mapStateToProps, mapDispatchToProps)(SystemProfilerView))
);
