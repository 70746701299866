export const allRequiredFiltersSet = (props) => {
    const { recModel, equationFilterRequired, filteredEquationGroupList } = props;
    const { equationFilters } = recModel;

    const {
        cropRequired,
        cropClassRequired,
        cropPurposeRequired,
        previousCropRequired,
        nextCropRequired,
        soilTextureRequired,
        tillageMethodRequired,
        applicationTimingRequired,
        applicationMethodRequired,
        productRequired,
        incorporationDepthRequired,
        buildFactorRequired,
        targetpHRequired,
        recOptionRequired,
    } = equationFilterRequired;

    const activeEquationGroup = filteredEquationGroupList.find(
        (eg) => eg.equationGroupGuid === recModel.equationGroupGuid
    );

    if (activeEquationGroup) {
        return Boolean(
            (!cropRequired || equationFilters.cropGuid) &&
                (!cropClassRequired || equationFilters.cropClassNameGuid) &&
                (!cropPurposeRequired || equationFilters.cropPurposeGuid) &&
                (!previousCropRequired || equationFilters.previousCropGuid) &&
                (!nextCropRequired || equationFilters.nextCropGuid) &&
                (!soilTextureRequired || equationFilters.soilTextureGuid) &&
                (!tillageMethodRequired || equationFilters.tillageMethodGuid) &&
                (!applicationTimingRequired || equationFilters.applicationTimingGuid) &&
                (!applicationMethodRequired || equationFilters.applicationMethodGuid) &&
                (!productRequired || equationFilters.productGuid) &&
                (!incorporationDepthRequired || equationFilters.incorporationDepthGuid) &&
                (!buildFactorRequired || equationFilters.buildFactorGuid) &&
                (!targetpHRequired || equationFilters.targetpHGuid) &&
                (!recOptionRequired || equationFilters.recOptionGuid)
        );
    }

    return false;
};

export const allRequiredFieldsSet = (props) => {
    const { recModel, filteredEquationGroupList } = props;
    const { equationParameters } = recModel;

    const activeEquationGroup = filteredEquationGroupList.find(
        (eg) => eg.equationGroupGuid === recModel.equationGroupGuid
    );

    if (activeEquationGroup) {
        return (
            allRequiredFiltersSet(props) &&
            !equationParameters.some((param) => param.required && param.value == null)
        );
    }

    return false;
};

export const getEquationFilterVisible = (
    equationFilterLists,
    equationFilterRequired,
    cropFilterApplied
) => {
    const {
        cropListOptions,
        cropClassListOptions,
        cropPurposeListOptions,
        previousCropListOptions,
        nextCropListOptions,
        soilTextureListOptions,
        tillageMethodListOptions,
        applicationTimingListOptions,
        applicationMethodListOptions,
        productListOptions,
        incorporationDepthListOptions,
        buildFactorListOptions,
        targetpHListOptions,
        recOptionListOptions,
    } = equationFilterLists;

    const {
        cropRequired,
        cropPurposeRequired,
        previousCropRequired,
        nextCropRequired,
        soilTextureRequired,
        tillageMethodRequired,
        applicationTimingRequired,
        applicationMethodRequired,
        productRequired,
        incorporationDepthRequired,
        buildFactorRequired,
        targetpHRequired,
        recOptionRequired,
    } = equationFilterRequired;

    // 'Required' as for these filters means that the API returned a list of options where there was NOT an empty
    // option in the list.  The empty option signifies the presence of equations wherein that value is empty (thus the field is not required)
    // On the other hand, if all of the set's equations have values for that filter, then there is no scenario where the empty choice narrows the
    // list to a distinct set of equations (and thus the field *is* required)
    // For example, if a set had 3 equations for P2O5 for a Wheat crop, and two of the equations did *not* have a Previous Crop, that filter would *not*
    // be required.  If all three have a previous crop, then it would be required.
    return {
        cropListVisible: cropListOptions.length > (cropRequired ? 0 : 1),
        cropClassListVisible:
            cropClassListOptions.length > cropClassListOptions.some((opt) => opt.guid),
        cropPurposeListVisible:
            cropFilterApplied && cropPurposeListOptions.length > (cropPurposeRequired ? 0 : 1),
        previousCropListVisible:
            cropFilterApplied && previousCropListOptions.length > (previousCropRequired ? 0 : 1),
        nextCropListVisible:
            cropFilterApplied && nextCropListOptions.length > (nextCropRequired ? 0 : 1),
        soilTextureListVisible:
            cropFilterApplied && soilTextureListOptions.length > (soilTextureRequired ? 0 : 1),
        tillageMethodListVisible:
            cropFilterApplied && tillageMethodListOptions.length > (tillageMethodRequired ? 0 : 1),
        applicationTimingListVisible:
            cropFilterApplied &&
            applicationTimingListOptions.length > (applicationTimingRequired ? 0 : 1),
        applicationMethodListVisible:
            cropFilterApplied &&
            applicationMethodListOptions.length > (applicationMethodRequired ? 0 : 1),
        productListVisible:
            cropFilterApplied && productListOptions.length > (productRequired ? 0 : 1),
        incorporationDepthListVisible:
            cropFilterApplied &&
            incorporationDepthListOptions.length > (incorporationDepthRequired ? 0 : 1),
        buildFactorListVisible:
            cropFilterApplied && buildFactorListOptions.length > (buildFactorRequired ? 0 : 1),
        targetpHListVisible:
            cropFilterApplied && targetpHListOptions.length > (targetpHRequired ? 0 : 1),
        recOptionListVisible:
            cropFilterApplied && recOptionListOptions.length > (recOptionRequired ? 0 : 1),
    };
};
