import { ADMIN_STATE_KEY } from "~/admin";
import { LOCATION_MIGRATION_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[ADMIN_STATE_KEY][LOCATION_MIGRATION_DATA_KEY];

export const getAgvanceDatabaseId = (state) => _getModuleState(state).agvanceDatabaseId;
export const getValidateResults = (state) => _getModuleState(state).validateResults;
export const getMigrationResults = (state) => _getModuleState(state).migrationResults;
export const getLocationEntities = (state) => _getModuleState(state).locationEntities;
export const getInvalidCheckResult = (state) => _getModuleState(state).invalidCheckResult;
