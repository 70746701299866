import React, { ReactElement, ReactNode } from "react";
import classnames from "classnames";

export interface ISubSectionProps {
    children?: ReactNode | ReactNode[];
    className?: string;
}

const subSection = ({ children, className }: ISubSectionProps): ReactElement => {
    return <div className={classnames("form-sub-section", className)}>{children}</div>;
};

export default subSection;
