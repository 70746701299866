import { AgEventAPI } from "@ai360/core";

export class AvailableProduct implements AgEventAPI.IAvailableProduct {
    defaultPriceUnitGuid: string;
    defaultRateUnitGuid: string;
    density: string;
    densityUnit: string;
    densityUnitGuid: string;
    guarenteedAnalysis: string;
    manufacturerName: string;
    name: string;
    nutrientList: any[];
    packageList: any[];
    physicalState: string;
    productGuid: string;
    productParentType: string;
    productTypeList: string[];
    specificGravity: string;
    productParentTypeGuid: string;
    productTypeGuidList: string[];
    manufacturerGuid: string;
    physicalStateGuid: string;
}
export interface ProductBlendPicklists {
    productParentType: any[];
    productType: any[];
    manufacturer: any[];
    physicalState: any[];
    dryRateUnits: any[];
    liquidRateUnits: any[];
    dryPriceUnits: any[];
    servicePriceUnits: any[];
    liquidPriceUnits: any[];
    productMixTypes: any[];
    nutrients: any[];
    densityUnits: any[];
    customProductTypes: any[];
}

export class ProductMix implements AgEventAPI.IProductMix {
    public actualRate: string | null;
    public actualRateUnit: string | null;
    public actualRateIaGuid: string | null;
    public actualRateUnitGuid: string | null;
    public costPerAcre: number | null;
    public customProductGuid: string | null;
    public density: string | null;
    public densityUnitGuid: string | null;
    public eventRecGuid: string | null;
    public guaranteedAnalysis: string | null;
    public hasSavedBlend: boolean;
    public isCarrier: boolean;
    public isImport: boolean | null;
    public isLockGuaranteedAnalysis: boolean;
    public isLockProductRatios: boolean;
    public limeEfficiency: number | null;
    public name: string | null;
    public nutrients: ProductMixNutrient[];
    public physicalStateGuid: string | null;
    public plantFood: string | null;
    public productBlendGuid: string | null;
    public productBlendName: string | null;
    public productMixId: string | null;
    public productMixGuid: string | null;
    public productMixType: string | null;
    public productMixTypeGuid: string | null;
    public products: ProductMixProduct[];
    public recProductMixGuid: string | null;
    public targetCost: number | null;
    public targetRate: string | null;
    public targetRateUnit: string | null;
    public targetRateUnitGuid: string | null;
    public totalCost: number | null;
    public totalProduct: number | null;
    public targetCostUnitGuid: string | null;

    constructor(eventRecGuid = "") {
        this.isImport = false;
        this.density = "";
        this.densityUnitGuid = "";
        this.isCarrier = false;
        this.isLockGuaranteedAnalysis = false;
        this.isLockProductRatios = false;
        this.nutrients = [];
        this.physicalStateGuid = "";
        this.targetRate = "";
        this.targetRateUnit = "";
        this.targetRateUnitGuid = "";
        this.actualRate = "";
        this.actualRateUnit = "";
        this.actualRateUnitGuid = "";
        this.actualRateIaGuid = "";
        this.productMixGuid = "";
        this.eventRecGuid = eventRecGuid;
        this.name = "";
        this.productMixId = "";
        this.productMixType = "";
        this.productMixTypeGuid = "";
        this.products = [];
    }
}
export class ProductMixProduct implements AgEventAPI.IProductMixProduct {
    public cost: number | null;
    public costPerAcre: number | null;
    public costUnitGuid: string | null;
    public costUnit: string | null;
    public customProductGuid: string | null;
    public customProductName: string | null;
    public density: number | null;
    public densityUnitGuid: string | null;
    public isCarrier: boolean;
    public limeEfficiency: number | null;
    public nutrientList: ProductMixProductNutrient[] | null;
    public nutrients: any[] | null;
    public productMixProductGuid: string | null;
    public productMixGuid: string | null;
    public productGuid: string | null;
    public productName: string | null;
    public percentOfMix: number | null;
    public physicalState: string | null;
    public productParentType: string | null;
    public rate: number | null;
    public rateUnitGuid: string | null;
    public rateUnit: string | null;
    public totalCost: number | null;
    public totalProduct: number | null;
    constructor() {
        this.productMixProductGuid = null;
        this.productMixGuid = null;
        this.productGuid = null;
        this.productName = "";
        this.customProductGuid = null;
        this.customProductName = "";
        this.percentOfMix = 1;
        this.isCarrier = false;
        this.rate = 0;
        this.rateUnitGuid = null;
        this.rateUnit = "";
        this.cost = 0;
        this.costUnitGuid = null;
        this.costUnit = "";
        this.nutrientList = [];
    }
}
export class ProductMixNutrient implements AgEventAPI.IProductMixNutrient {
    public element?: string = null;
    public name?: string | null = null;
    public nameKey?: string = null;
    public nutrientGuid: string | null = null;
    public nutrientPercent: string | null = null;
    public productMixGuid: string | null = null;
    public productMixNutrientGuid: string | null = null;
}

export class ProductMixProductNutrient {
    public element?: string = null;
    public equivalentWeight?: number = null;
    public name: string | null = null;
    public nameKey: string = null;
    public nutrientGuid: string | null = null;
    public oxideBasis: string | null = null;
    public percent: number | null = null;
}

export interface NutrientAnalysis {
    element: string;
    percent: number;
}
