import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { config as intlConfig } from "~/intl-provider/config";
import { messages } from "../i18n-messages";
import { keywords } from "../keywords";
import { ABBREVIATION, GUID, NAME } from "~/admin/data";
import { FormattingHelpers } from "@ai360/core";
// Components
import { NumericInput, SelectInput, TextInput } from "~/core";

// Constants
import * as constants from "./constants";
import { ACTIVE_YN } from "~/core/picklist";
import { IGetGuid, IOnSeedRateUnitChange } from "../interfaces";
import { VarietyHybrid } from "./variety-hybrid";
import { AgBytesItem } from "~/action-panel/components/event-module/components/event-info/components/event-scouting/models";
import { ISelectOption } from "~/core/components/select-input/model";
import { sortBy } from "lodash";

export interface IVarietyHybridItemsProps {
    apiErrors?: Error[];
    brandOrgData?: Record<string, any>[];
    brandOrganizationGuid?: string;
    brandVarietyHybridList?: Record<string, AgBytesItem[]>;
    dryPriceUnit?: Record<string, any>[];
    dryProductUnit?: Record<string, any>[];
    errorMessagePlaceholderSet?: Record<string, any>;
    errorMessages?: Record<string, any>;
    getLabel?: (val: Record<string, any>, defaultString?: string, abbr?: string) => string;
    getPhysicalState?: () => void;
    index?: number;
    intl: intlShape;
    isImportedYn?: boolean;
    liquidPriceUnit?: Record<string, any>[];
    liquidProducteUnit?: Record<string, any>[];
    onChange?: (constant?: string, guid?: string, idx?: number, anonFunc?: () => void) => void;
    onChangeMultiple?: () => void;
    onSeedingRateUnitChange?: (data: IOnSeedRateUnitChange) => void;
    onSelectChange?: () => void;
    packageUnit?: Record<string, any>[];
    requestVarietyHybrid?: (guid: string) => void;
    saveEventDetailsErrorCodeList?: Record<string, any>[];
    seedingRate?: Record<string, any>[];
    transplant?: boolean;
    transplantRate?: Record<string, any>[];
    varietyHybridList?: VarietyHybrid[];
}

export interface IVarietyHybridItemsState {
    seedingRate: Record<string, any>[];
    packageUnit: Record<string, any>[];
    pricePerUnit: string;
}

function varietyToDropdown(item: AgBytesItem): ISelectOption<string> {
    return {
        value: item.guid,
        label: item.name,
        activeYn: item.activeYn,
    };
}

export class VarietyHybridItems extends Component<
    IVarietyHybridItemsProps,
    IVarietyHybridItemsState
> {
    static POUND_ID = "224";
    static UNIT_ID = "102";

    constructor(props: IVarietyHybridItemsProps) {
        super(props);
        this.state = {
            seedingRate: props.seedingRate || [],
            packageUnit: props.packageUnit || [],
            pricePerUnit: "",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IVarietyHybridItemsProps): void {
        //set the pricePerUnitText on load
        if (
            nextProps.varietyHybridList[nextProps.index][constants.PROPS_SEEDING_RATE_IA_GUID] !=
                null &&
            nextProps.varietyHybridList[nextProps.index][constants.PROPS_SEEDING_RATE_IA_GUID] !==
                "" &&
            nextProps.seedingRate.length > 0
        ) {
            this._setPricePerUnit(nextProps);
        }
        if (nextProps.seedingRate !== this.props.seedingRate) {
            this.setState({
                seedingRate: nextProps.seedingRate,
            });
        }
        if (nextProps.packageUnit !== this.props.packageUnit) {
            this.setState({
                packageUnit: nextProps.packageUnit,
            });
        }
    }

    private _formatSeedingRate = (value: string, userEnteredSeedingRate: boolean): string => {
        const { formatNumber } = this.props.intl;
        const { isImportedYn } = this.props;
        if (isImportedYn && !userEnteredSeedingRate) {
            const decimals = FormattingHelpers.progressiveRoundingDecimalsThreeTier(value);
            return formatNumber(value, intlConfig.customFormatOptions(decimals));
        }
        return value;
    };

    private _isSeedingRateDisabled = (
        isImportedYn: boolean,
        userEnteredSeedingRate: boolean,
        seedingRateValue: string | number
    ): boolean => {
        return (
            isImportedYn &&
            !userEnteredSeedingRate &&
            seedingRateValue !== 0 &&
            seedingRateValue !== "0" &&
            seedingRateValue !== null
        );
    };

    private _setPricePerUnit = (nextProps: IVarietyHybridItemsProps): void => {
        const seedingRateText = this.props.getLabel(
            {
                guid: nextProps.varietyHybridList[nextProps.index][
                    constants.PROPS_SEEDING_RATE_IA_GUID
                ],
                options: this.props.seedingRate,
            },
            constants.IMPORT_ATTR_GUID,
            ABBREVIATION
        );
        let pricePerUnit = "";
        if (seedingRateText === keywords.poundPerAc) {
            pricePerUnit = keywords.LB;
        } else if (
            seedingRateText === keywords.seedPerAc ||
            seedingRateText === keywords.ksdsPerAc
        ) {
            pricePerUnit = keywords.UNIT;
        }
        this.setState({
            pricePerUnit,
        });
    };

    public getVarietyHybrid = (index: number): ISelectOption<string>[] => {
        const guid = this.props.varietyHybridList[index][constants.PROPS_BRAND_ORGANIZATION_GUID];

        const varietyList = guid
            ? this.props.brandVarietyHybridList[guid]
            : sortBy(
                  Object.values(this.props.brandVarietyHybridList)
                      .flat()
                      .filter((x) => x.name !== "_Unspecified"),
                  (x) => x.name
              );

        return varietyList?.map(varietyToDropdown) ?? [];
    };

    public getGuid = ({ options = [], selectedId = "" }: IGetGuid): string => {
        if (options.length) {
            const selectedOption = options.filter((option) => {
                return selectedId === option.id;
            });
            return selectedOption.length ? selectedOption[0].guid : null;
        }
        return null;
    };

    public checkNullForNumericInput(value: string): string {
        return value === null ? "" : value;
    }

    onChangePricePerUnit = (value: string, index: number): void => {
        const seedingRateText = this.props.getLabel(
            { guid: value, options: this.props.seedingRate },
            constants.IMPORT_ATTR_GUID,
            ABBREVIATION
        );
        let pricePerUnit = "",
            id = "";
        if (seedingRateText === keywords.poundPerAc) {
            pricePerUnit = keywords.LB;
            id = VarietyHybridItems.POUND_ID;
        } else if (
            seedingRateText === keywords.seedPerAc ||
            seedingRateText === keywords.ksdsPerAc ||
            seedingRateText === keywords.kilogramPerAc ||
            seedingRateText === keywords.metrictonPerAc
        ) {
            pricePerUnit = keywords.UNIT;
            id = VarietyHybridItems.UNIT_ID;
        }
        const unitGuid = this.getGuid({
            options: this.props.packageUnit,
            selectedId: id,
        });
        if (unitGuid) {
            this.setState(
                {
                    pricePerUnit,
                },
                () => {
                    this.props.onSeedingRateUnitChange({
                        index,
                        pricePerUnitProp: constants.PROPS_PRICE_PER_UNIT_TEXT,
                        pricePerUnitGuidProp: constants.PROPS_PRICE_PER_UNIT_GUID,
                        unitGuid,
                        seedingRateUnitGuidProp: constants.PROPS_SEEDING_RATE_IA_GUID,
                        value,
                        seedingRateUnitProp: constants.PROPS_SEEDING_RATE_PER_UNIT_TEXT,
                        label: seedingRateText,
                    });
                }
            );
        }
    };

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const {
            brandOrgData,
            errorMessagePlaceholderSet,
            errorMessages,
            getLabel,
            index,
            isImportedYn,
            onChange,
            transplant,
            transplantRate,
            varietyHybridList,
        } = this.props;
        return (
            <div>
                <div className="input-row">
                    <SelectInput
                        placeholderText={formatMessage(messages.brandOrganization)}
                        options={brandOrgData.map(({ guid, name }) => ({
                            value: guid,
                            label: name,
                        }))}
                        onChange={(guid) => {
                            this.brandChanged(guid, index);
                        }}
                        required={isImportedYn}
                        value={varietyHybridList[index][constants.PROPS_BRAND_ORGANIZATION_GUID]}
                        disabled={!brandOrgData.length}
                    />
                    <SelectInput
                        placeholderText={formatMessage(messages.varietyHybrid)}
                        options={this.getVarietyHybrid(index)}
                        disabled={!this.getVarietyHybrid(index).length}
                        onChange={(guid) => {
                            this.varietyChanged(guid, index);
                        }}
                        required={isImportedYn}
                        value={varietyHybridList[index][constants.PROPS_VARIETY_HYBRID_GUID]}
                        optionIsHiddenKey={ACTIVE_YN}
                    />
                </div>
                {transplant ? (
                    <div className="input-row">
                        <TextInput
                            maxLength={50}
                            placeholderText={formatMessage(messages.rootstockName)}
                            onChange={(value) =>
                                onChange(constants.PROPS_ROOTSTOCK_NAME, value, index)
                            }
                            value={varietyHybridList[index][constants.PROPS_ROOTSTOCK_NAME]}
                            containerClassNames={["events-rootstock-name"]}
                        />
                        <div className="input-row">
                            <NumericInput
                                containerClassNames={[
                                    {
                                        "form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.plantPerAreaUnitDependentError
                                        ),
                                    },
                                ]}
                                scale={0}
                                precision={9}
                                placeholderText={formatMessage(messages.plantsPer)}
                                onChange={(strVal, formattedVal, numVal) =>
                                    onChange(constants.PROPS_PLANTS_PER_AREA, numVal, index)
                                }
                                value={varietyHybridList[index][constants.PROPS_PLANTS_PER_AREA]}
                            />
                            <SelectInput
                                containerClassNames={[
                                    {
                                        "select-form-input-error": errorMessagePlaceholderSet.has(
                                            errorMessages.plantPerAreaUnitError
                                        ),
                                    },
                                ]}
                                placeholderText={formatMessage(messages.unit)}
                                options={transplantRate?.map(
                                    ({ importAttributeGuid, abbreviation }) => ({
                                        value: importAttributeGuid,
                                        label: abbreviation,
                                    })
                                )}
                                onChange={(value) => {
                                    onChange(
                                        constants.PROPS_PLANTS_PER_AREA_IA_GUID,
                                        value,
                                        index,
                                        () => {
                                            onChange(
                                                constants.PROPS_PRICE_PER_UNIT_TEXT,
                                                getLabel({
                                                    guid: value,
                                                    options: transplantRate,
                                                }),
                                                index,
                                                () =>
                                                    this.setState({
                                                        pricePerUnit: getLabel({
                                                            guid: value,
                                                            options: transplantRate,
                                                        }),
                                                    })
                                            );
                                        }
                                    );
                                }}
                                value={
                                    varietyHybridList[index][
                                        constants.PROPS_PLANTS_PER_AREA_IA_GUID
                                    ]
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.seedingRateUnitDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(messages.seedingRate)}
                            disabled={this._isSeedingRateDisabled(
                                isImportedYn,
                                varietyHybridList[index][constants.PROPS_USER_ENTERED_SEEDING_RATE],
                                varietyHybridList[index][constants.PROPS_SEEDING_RATE]
                            )}
                            onChange={(numVal) =>
                                onChange(constants.PROPS_SEEDING_RATE, numVal, index)
                            }
                            value={this._formatSeedingRate(
                                this.checkNullForNumericInput(
                                    varietyHybridList[index][constants.PROPS_SEEDING_RATE]
                                ),
                                varietyHybridList[index][constants.PROPS_USER_ENTERED_SEEDING_RATE]
                            )}
                        />
                        <SelectInput
                            containerClassNames={[
                                {
                                    "select-form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.seedingRateUnitError
                                    ),
                                },
                            ]}
                            placeholderText={formatMessage(messages.unit)}
                            options={this.state.seedingRate.map(
                                ({ importAttributeGuid, abbreviation }) => ({
                                    value: importAttributeGuid,
                                    label: abbreviation,
                                })
                            )}
                            onChange={(value) => {
                                this.onChangePricePerUnit(value, index);
                            }}
                            value={varietyHybridList[index][constants.PROPS_SEEDING_RATE_IA_GUID]}
                        />
                        <NumericInput
                            containerClassNames={[
                                {
                                    "form-input-error":
                                        errorMessagePlaceholderSet.has(
                                            errorMessages.percentOfPlanterTotalError
                                        ) ||
                                        errorMessagePlaceholderSet.has(
                                            errorMessages.percentPlanterError
                                        ),
                                },
                            ]}
                            scale={2}
                            precision={7}
                            placeholderText={formatMessage(messages.percentageOfPlanter)}
                            onChange={(strVal, formattedVal, numVal) =>
                                onChange(constants.PROPS_PERCENT_OF_PLANTER, numVal, index)
                            }
                            value={varietyHybridList[index][constants.PROPS_PERCENT_OF_PLANTER]}
                        />
                    </div>
                )}
                <div className="input-row">
                    {transplant ? null : (
                        <NumericInput
                            scale={0}
                            precision={9}
                            placeholderText={formatMessage(messages.seedCount)}
                            onChange={(numVal) =>
                                onChange(constants.PROPS_SEED_COUNT, numVal, index)
                            }
                            value={this.checkNullForNumericInput(
                                varietyHybridList[index][constants.PROPS_SEED_COUNT]
                            )}
                        />
                    )}
                    <TextInput
                        maxLength={50}
                        placeholderText={formatMessage(messages.lot)}
                        onChange={(value) => onChange(constants.PROPS_LOT_NUMBER, value, index)}
                        value={varietyHybridList[index][constants.PROPS_LOT_NUMBER]}
                    />
                    <NumericInput
                        containerClassNames={[
                            { "price-per-unit": transplant },
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    errorMessages.pricePerUnitError
                                ),
                            },
                        ]}
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.price)}
                        onChange={(numVal) => {
                            onChange(constants.PROPS_PRICE_PER_UNIT, numVal, index);
                            this.onChangePricePerUnit(
                                varietyHybridList[index][constants.PROPS_SEEDING_RATE_IA_GUID],
                                index
                            );
                        }}
                        value={this.checkNullForNumericInput(
                            varietyHybridList[index][constants.PROPS_PRICE_PER_UNIT]
                        )}
                    />
                    <div className="price-unit">
                        {transplant ? formatMessage(messages.plant) : this.state.pricePerUnit}
                    </div>
                </div>
            </div>
        );
    }

    private varietyChanged(varietyGuid: string, index: number) {
        const { onChange, varietyHybridList, getLabel, brandVarietyHybridList } = this.props;

        onChange(constants.PROPS_VARIETY_HYBRID_GUID, varietyGuid, index, () =>
            onChange(
                constants.PROPS_VARIETY_HYBRID_NAME,
                getLabel({
                    options: this.getVarietyHybrid(index),
                    guid: varietyGuid,
                }),
                index,
                () => {
                    if (!varietyHybridList[index][constants.PROPS_BRAND_ORGANIZATION_GUID]) {
                        for (const brandGuid of Object.keys(brandVarietyHybridList)) {
                            if (
                                brandVarietyHybridList[brandGuid].find(
                                    (x) => x.guid === varietyGuid
                                )
                            ) {
                                this.brandChanged(brandGuid, index, false);
                                break;
                            }
                        }
                    }
                }
            )
        );
    }

    private brandChanged(guid: string, index: number, clearVariety = true) {
        const { onChange, brandOrgData, getLabel } = this.props;

        const followUp = clearVariety
            ? () =>
                  onChange(constants.PROPS_VARIETY_HYBRID_GUID, "", index, () =>
                      onChange(constants.PROPS_VARIETY_HYBRID_NAME, "", index)
                  )
            : () => null;

        onChange(constants.PROPS_BRAND_ORGANIZATION_GUID, guid, index, () =>
            onChange(
                constants.PROPS_BRAND_ORGANIZATION_NAME,
                getLabel({ options: brandOrgData, guid }, GUID, NAME),
                index,
                followUp
            )
        );
    }
}

export default injectIntl(VarietyHybridItems);
