import React, { PureComponent } from "react";
import { injectIntl, intlShape } from "react-intl";
import TableCell from "~/core/components/tables/data-table/components/table-cell";
import { config as intlConfig } from "~/intl-provider/config";
import { keywords } from "./keywords";
import { messages } from "./i18n-messages";
import { FormattingHelpers } from "@ai360/core";
import classNames from "classnames";

// Style
import "./event-planting-info.css";

const customCurrencyFormat = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "currency",
    currency: "USD",
};
export interface ICurrencyFormat {
    minimumFractionDigits: number;
    maximumFractionDigits: number;
    style: string;
    currency: string;
}
export interface ICropUnitTableRowProps {
    record: Record<string, any>;
    formatNumber: (number: number, format?: ICurrencyFormat) => string;
}
const CropUnitTableRow = ({ record, formatNumber }: ICropUnitTableRowProps) => {
    const cropPurposeNameLabel = record.cropPurposeName ? ` (${record.cropPurposeName}):` : ":";
    const brandOrganizationName = record.brandOrganizationName
        ? `${record.brandOrganizationName}`
        : "";
    const varietyHybridName = record.varietyHybridName ? ` - ${record.varietyHybridName}` : "";
    const varietyHybridLabel = `${brandOrganizationName}${varietyHybridName}`;
    const varietyHybridLabelText = `${record.cropName}${cropPurposeNameLabel} ${varietyHybridLabel}`;
    const vhUnitText = formatNumber(record.vhUnits);
    const vhTotalCost = formatNumber(record.vhTotalCost, customCurrencyFormat);
    return (
        <div>
            {!record.cropGuid ? null : (
                <div className="crop-unit-row">
                    <TableCell className="crop-section" title={varietyHybridLabelText}>
                        {<p>{varietyHybridLabelText}</p>}
                    </TableCell>
                    <TableCell className="section-label" title={vhUnitText}>
                        {<p>{vhUnitText}</p>}
                    </TableCell>
                    <TableCell className="section-label" title={vhTotalCost}>
                        {<p>{vhTotalCost}</p>}
                    </TableCell>
                </div>
            )}
        </div>
    );
};
export interface IEventPlantingInfoProps {
    conversionFactors?: Record<string, any>;
    currentArea?: Record<string, any>;
    dryPriceUnit?: Record<string, any>[];
    dryProductUnit?: Record<string, any>[];
    fieldDetailsAreaList?: Record<string, any>[];
    getModel?: (item: Record<string, any>) => Record<string, any>;
    intl: intlShape;
    isEquation?: boolean;
    isEventType?: boolean;
    isLoading?: boolean;
    liquidPriceUnit?: Record<string, any>[];
    liquidProducteUnit?: Record<string, any>[];
    modelId?: Record<string, any>;
    plantingData?: Record<string, any>;
    seedingRate?: string[];
}

class EventPlantingInfo_ extends PureComponent<IEventPlantingInfoProps> {
    totalCost: number | string;
    costPerArea: number;
    costByArea?: number;
    static defaultProps = {
        isEventType: false,
    };

    constructor(props) {
        super(props);
        this.totalCost = 0;
        this.costPerArea = 0;
    }

    public getVhUnits = (
        record: Record<string, any>,
        zoneAcres: number,
        transplantYn: boolean
    ): number | string => {
        const { isEquation } = this.props;
        const {
            seedingRatePerUnitText = this.getLabel(
                {
                    options: this.props.seedingRate,
                    guid: record.seedingRateIaGuid,
                },
                "importAttributeGuid",
                "abbreviation"
            ),
            seedingRate = 0,
            seedCount = 0,
            percentOfPlanter = 100,
            plantsPerArea,
        } = record;
        let returnValue = 0;

        if (transplantYn && plantsPerArea != null) {
            return Math.round(plantsPerArea * zoneAcres);
        }

        const applicableSeedingRatePerUnitText = !isEquation
            ? seedingRatePerUnitText
            : keywords.seedPerAc;

        switch (applicableSeedingRatePerUnitText) {
            case keywords.seedPerAc:
                return !seedCount
                    ? 0
                    : (
                          (+seedingRate * +zoneAcres * (+percentOfPlanter / 100)) /
                          +seedCount
                      ).toFixed(2);
            case keywords.poundPerAc:
                return Math.round(+seedingRate * +zoneAcres * (+percentOfPlanter / 100));
            case keywords.ksdsPerAc:
                return !seedCount
                    ? 0
                    : (
                          (+seedingRate * 1000 * +zoneAcres * (+percentOfPlanter / 100)) /
                          +seedCount
                      ).toFixed(2);
            default:
                returnValue = 0;
        }
        return returnValue;
    };

    public getVhTotalCost = (record, zoneAcres, transplantYn) => {
        let { pricePerUnit = 0 } = record;
        if (typeof pricePerUnit === "string") {
            pricePerUnit = FormattingHelpers.parseStringToInt(pricePerUnit);
        }
        return Math.round(
            (this.getVhUnits(record, zoneAcres, transplantYn) as number) * Number(pricePerUnit)
        );
    };

    public getLabel = ({ options = [], guid = "" }, valueKey = "value", labelKey = "label") => {
        if (options.length) {
            const selectedOption = options.filter((option) => {
                return guid === option[valueKey];
            });
            const label = selectedOption.length && selectedOption[0][labelKey];
            return label;
        }
        return null;
    };

    private _findIndexExistingVh = (vhArr, newVh) => {
        const { brandOrganizationGuid, varietyHybridGuid } = newVh;
        const idx = vhArr.findIndex((i) => {
            if (
                i.brandOrganizationGuid === brandOrganizationGuid &&
                i.varietyHybridGuid === varietyHybridGuid
            ) {
                return true;
            }
            return false;
        });
        return idx;
    };

    private _replaceExistingVh = (vhArr, index, newVh, calculatedArea, transplantYn) => {
        const vhUnits = this.getVhUnits(newVh, calculatedArea, transplantYn) as number;
        const vhTotalCost = this.getVhTotalCost(newVh, calculatedArea, transplantYn);
        const newVhUnits = +vhArr[index].vhUnits + +vhUnits;
        const newVhTotalCost = parseFloat(vhArr[index].vhTotalCost) + vhTotalCost;
        const newCalculatedArea =
            parseFloat(vhArr[index].calculatedArea) + parseFloat(calculatedArea);
        vhArr.splice(index, 1, {
            ...vhArr[index],
            vhUnits: `${newVhUnits}`,
            vhTotalCost: `${newVhTotalCost}`,
            calculatedArea: `${newCalculatedArea}`,
        });
    };

    private _getCalculatedArea = () => {
        return this.props.fieldDetailsAreaList.reduce((acc, eventArea) => {
            acc += eventArea.calculatedArea;
            return acc;
        }, 0);
    };

    private _getUniqueVHList = () => {
        return this.props.fieldDetailsAreaList.reduce((acc, item) => {
            const {
                varietyHybridList = [],
                cropGuid,
                cropName,
                cropPurposeName,
                transplantYn,
            } = this.props.getModel(item);
            const { calculatedArea } = item;
            varietyHybridList.forEach((vh) => {
                const existingVhIndex = this._findIndexExistingVh(acc, {
                    ...vh,
                });
                // fetch prices from the iterator and add it to existing item
                if (existingVhIndex > -1) {
                    this._replaceExistingVh(acc, existingVhIndex, vh, calculatedArea, transplantYn);
                } else {
                    const vhUnits = this.getVhUnits(vh, calculatedArea, transplantYn);
                    const vhTotalCost = this.getVhTotalCost(vh, calculatedArea, transplantYn);
                    acc.push({
                        cropGuid,
                        cropName,
                        cropPurposeName,
                        ...vh,
                        vhUnits,
                        vhTotalCost,
                        calculatedArea,
                    });
                }
            });
            return acc;
        }, []);
    };

    private _renderTotalCost = (calculatedArea) => {
        const { formatMessage, formatNumber } = this.props.intl;
        const costByArea = isNaN(this.totalCost as number)
            ? 0
            : (this.totalCost as number) / calculatedArea;
        return (
            <div className="total-cost-container">
                <div className="total-cost-items">
                    <span className="total-cost-label">{`${formatMessage(
                        messages.totalCost
                    )}: `}</span>
                    {formatNumber(this.totalCost, customCurrencyFormat)}
                </div>
                <div className="total-cost-items">
                    <span className="total-cost-label">{`${formatMessage(
                        messages.costPerAcre
                    )}: `}</span>
                    {formatNumber(costByArea, intlConfig.currencyFormatOptions)}
                </div>
            </div>
        );
    };

    private _renderCostUnitTable = (uniqueVhItems) => {
        // Clearing the Total cost on every render
        this.totalCost = 0;
        this.costByArea = 0;
        const { currentArea } = this.props;
        if (!currentArea) {
            return null;
        }
        const { formatNumber } = this.props.intl;
        return (
            uniqueVhItems &&
            uniqueVhItems.map((record, index) => {
                const vtTotalCost = isNaN(parseFloat(record.vhTotalCost))
                    ? 0
                    : Math.round(parseFloat(record.vhTotalCost));
                const stTotalCost = isNaN(parseFloat(record.stTotalCost))
                    ? 0
                    : Math.round(parseFloat(record.stTotalCost));
                this.totalCost = Math.round((this.totalCost as number) + vtTotalCost + stTotalCost);
                return <CropUnitTableRow record={record} key={index} formatNumber={formatNumber} />;
            })
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const calculatedArea = this._getCalculatedArea();
        const uniqueVhItems = this._getUniqueVHList();
        const zoneLabel =
            calculatedArea !== 0
                ? formatMessage(messages.zoneSize, {
                      calculatedArea: FormattingHelpers.formatNumber(calculatedArea),
                  })
                : formatMessage(messages.zoneSizeBatch);

        return (
            <div className="event-planting-info-form">
                <div className="field-table-container">
                    <div className="zone-info-heading">
                        <div className="zone-name">{formatMessage(messages.fieldTotals)}</div>
                        <div className="zone-size">{zoneLabel}</div>
                    </div>
                    <div className="crop-unit-table">
                        <div
                            className={classNames("crop-unit-header", {
                                "crop-unit-header-border": uniqueVhItems.length,
                            })}
                        >
                            <TableCell className="crop-section">
                                {formatMessage(messages.crop)}
                            </TableCell>
                            <TableCell className="section-label section-label-header">
                                {formatMessage(messages.units)}
                            </TableCell>
                            <TableCell className="section-label section-label-header">
                                {formatMessage(messages.totalCost)}
                            </TableCell>
                        </div>
                        {this._renderCostUnitTable(uniqueVhItems)}
                    </div>
                    {this._renderTotalCost(calculatedArea)}
                </div>
            </div>
        );
    }
}

export const EventPlantingInfo = injectIntl(EventPlantingInfo_);
