import React, { Component } from "react";
import { messages } from "../../i18n-messages";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { model, service } from "../data";
import { VARIETY_ATTRIBUTE_URL } from "../data/service";
import { getAgBytesErrorClassNames, onTextChange } from "~/admin/utils";
import _ from "lodash";
// constants
import * as picklistService from "~/core/picklist/picklist-names";
// Components
import { Checkbox, Section, SubSection, TextInput } from "~/core";
import { adminData } from "~/admin/data";
import { SelectInput, defaultOptionRenderer } from "~/core/components/select-input/select-input";
import VarietyHybridSearch from "../../components/variety-hybrid-search";
// Style
import "./add-edit-panel.css";
// Type
import { IAddEditPanelState, IAddEditProps, VarietyHybridAttribute } from "../interfaces";

export class AddEditPanel extends Component<IAddEditProps & InjectedIntlProps, IAddEditPanelState> {
    static PICKLIST_LABEL = "picklistValue";
    static PICKLIST_PARENT_GUID = "parentPicklistGuid";
    static PICKLIST_PARENT_GROUP_NAME = "parentPicklistValue";
    static VARIETY_ATTRIBUTE_MODEL_NAME = "variety attribute";

    varietyHybridAttribute: VarietyHybridAttribute;
    constructor(props) {
        super(props);
        this.varietyHybridAttribute = props.record || {};
        this.state = {
            [picklistService.PICKLIST_VARIETY_ATTRIBUTE]: [],
            varAttrPicklistIsInitialized: false,
            [model.PROPS_VARIETY_HYBRID_NAME]: "",
        };
    }

    componentDidMount() {
        this.setupVarietyHybridAttributeRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        const picklistKey = picklistService.getPickListCode(
            picklistService.PICKLIST_VARIETY_ATTRIBUTE
        );
        needs([
            this.props.fetchPicklistData({
                url: VARIETY_ATTRIBUTE_URL,
                picklistKey,
                model: AddEditPanel.VARIETY_ATTRIBUTE_MODEL_NAME,
            }),
        ]);
        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.varietyHybridAttribute);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.varietyHybridAttribute = nextProps.record;
                this.setState({
                    [model.PROPS_VARIETY_HYBRID_NAME]:
                        nextProps.record[model.PROPS_VARIETY_HYBRID_NAME] &&
                        nextProps.record[model.PROPS_VARIETY_HYBRID_ID]
                            ? `${nextProps.record[model.PROPS_VARIETY_HYBRID_ID]} - ${
                                  nextProps.record[model.PROPS_VARIETY_HYBRID_NAME]
                              }`
                            : "",
                });
            }
        }

        this.initializeDropdowns(nextProps, this.props);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.varietyHybridAttribute[model.PROPS_VARIETY_HYBRID_ID] = "" + nextProps.nextId; //todo this shouldbe attributeID
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };
    setupVarietyHybridAttributeRecord = () => {
        const { addEditPanel } = this.props;
        this.varietyHybridAttribute = this.props.record || {};
        if (addEditPanel.mode === "ADD") {
            this.varietyHybridAttribute = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps, props) => {
        if (
            nextProps[picklistService.PICKLIST_VARIETY_ATTRIBUTE] !==
                props[picklistService.PICKLIST_VARIETY_ATTRIBUTE] ||
            !this.state.varAttrPicklistIsInitialized
        ) {
            this.initializeVarietyAttributes(nextProps);
        }
    };

    prepareSelectableOptionsWithHeader = (options, { label, header }) => {
        let optionHeader;
        return _.reduce(
            options,
            (result, option) => {
                if (optionHeader !== option[header]) {
                    optionHeader = option[header];
                    result.push({
                        value: { name: option[header] },
                        label: option[header],
                        header: true,
                        [adminData.PROPS_ACTIVE_YN]: true,
                    });
                }
                result.push({
                    value: { groupBy: option[header], name: option[label] },
                    label: option[label],
                });
                return result;
            },
            []
        );
    };

    initializeVarietyAttributes = (nextProps) => {
        this.setState({
            [picklistService.PICKLIST_VARIETY_ATTRIBUTE]: this.prepareSelectableOptionsWithHeader(
                nextProps[picklistService.PICKLIST_VARIETY_ATTRIBUTE],
                {
                    label: AddEditPanel.PICKLIST_LABEL,
                    header: AddEditPanel.PICKLIST_PARENT_GROUP_NAME,
                }
            ),
            varAttrPicklistIsInitialized: true,
        });
    };

    mapToPicklistValue = ({ options = [], selectedName = "" }) => {
        if (options.length > 0 && selectedName) {
            const selectedOption = options.filter((option) => {
                return selectedName === option.value.name;
            });
            const { groupBy, name } = selectedOption.length > 0 && selectedOption[0].value;
            return { groupBy, name };
        }
        return null;
    };

    onTextChange = (formKey, value, callback?: () => void) => {
        this.varietyHybridAttribute = onTextChange(
            this.varietyHybridAttribute,
            { formKey: [formKey], value },
            callback
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.varietyHybridAttribute[model.PROPS_ATTRIBUTE_VALUE]]);
    };

    optionRenderer = ({ option, isHeader, isSelected, isHighlighted, matchPos, matchLen }) => {
        if (!isHeader) {
            return defaultOptionRenderer({
                option,
                isHeader,
                isSelected,
                isHighlighted,
                matchPos,
                matchLen,
            });
        }
        return (
            <div className="select-form-sub-header">
                <span>{option.label}</span>
            </div>
        );
    };

    onSelectChange = (value: any = {}) => {
        if (!_.isEmpty(value)) {
            const { groupBy, name } = value;
            this.varietyHybridAttribute[model.PROPS_VARIETY_ATTRIBUTE_CATEGORY] = groupBy;
            this.varietyHybridAttribute[model.PROPS_VARIETY_ATTRIBUTE_NAME] = name;
        } else {
            this.varietyHybridAttribute[model.PROPS_VARIETY_ATTRIBUTE_CATEGORY] = "";
            this.varietyHybridAttribute[model.PROPS_VARIETY_ATTRIBUTE_NAME] = "";
        }
    };

    filterOptions(filterVal, options, isHeaderOption) {
        const matchingIdxList = [],
            existingHeaderOptions = [];
        const optionMatchPositions = [];
        const filteredOptListIdxMap = new Map();
        filterVal = filterVal == null ? "" : filterVal.toLowerCase();
        const headerOptions = options.reduce((acc, option, index) => {
            if (isHeaderOption(option)) {
                acc.push({ index, groupBy: option.value.name });
            }
            return acc;
        }, []);
        for (let optionIdx = 0, matchCount = 0; optionIdx < options.length; optionIdx++) {
            const option = options[optionIdx];
            const labelVal = option.label.toLowerCase();
            const idx = labelVal.indexOf(filterVal);
            optionMatchPositions.push(idx);
            if (labelVal === filterVal && matchingIdxList.length < 2) {
                matchingIdxList.push(optionIdx);
            }
            if (idx !== -1) {
                const headerIdx = headerOptions.find((item) => {
                    if (!option.groupBy) {
                        return item.groupBy === option.value.groupBy;
                    }
                    return false;
                });
                if (
                    filterVal.length > 1 &&
                    headerIdx &&
                    existingHeaderOptions.indexOf(headerIdx.index) === -1
                ) {
                    filteredOptListIdxMap.set(matchCount++, headerIdx.index);
                    existingHeaderOptions.push(headerIdx.index);
                }
                filteredOptListIdxMap.set(matchCount++, optionIdx);
            }
        }

        const exactMatchIdx = matchingIdxList.length === 1 ? matchingIdxList[0] : null;
        return [optionMatchPositions, filteredOptListIdxMap, exactMatchIdx];
    }

    renderVarietyHybridAttributeInfo = () => {
        const { formatMessage } = this.props.intl;
        const { varietyHybridAttribute } = this;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <VarietyHybridSearch
                            apiErrors={this.props.apiErrors}
                            onSelection={this.onTextChange}
                            initialValue={this.state[model.PROPS_VARIETY_HYBRID_NAME]}
                            autoFocus
                            openOnFocus={false}
                            formatMessage={formatMessage}
                            classNames="variety-hybrid-search-container"
                        />
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.varietyAttribute)}
                            value={this.mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_VARIETY_ATTRIBUTE],
                                selectedName:
                                    varietyHybridAttribute[
                                        picklistService.PICKLIST_VARIETY_ATTRIBUTE
                                    ],
                            })}
                            containerClassNames={getAgBytesErrorClassNames(
                                [204, 255],
                                this.props.apiErrors,
                                ["variety-attribute-container"]
                            )}
                            onChange={(value) => this.onSelectChange(value)}
                            options={this.state[picklistService.PICKLIST_VARIETY_ATTRIBUTE]}
                            optionIsHeaderKey="header"
                            optionRenderer={this.optionRenderer}
                            filterOptions={this.filterOptions}
                            required
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames(
                                205,
                                this.props.apiErrors
                            )}
                            placeholderText={formatMessage(messages.attributeValue)}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_ATTRIBUTE_VALUE,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            value={varietyHybridAttribute[model.PROPS_ATTRIBUTE_VALUE] || ""}
                            required
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.agBytesValue)}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_AGBYTES_VALUE, value)
                            }
                            value={varietyHybridAttribute[model.PROPS_AGBYTES_VALUE] || ""}
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                    <Section>
                        <SubSection>
                            <Checkbox
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={varietyHybridAttribute[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        </SubSection>
                    </Section>
                )}
            </div>
        );
    };

    render() {
        return (
            <div className="add-edit-panel">
                <div className="section-container">{this.renderVarietyHybridAttributeInfo()}</div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
