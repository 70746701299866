export interface Subscription {
    eventName: string;
}

export interface ActionSubscription extends Subscription {
    action: any;
}

export interface GeneratorSubscription extends Subscription {
    generator: any;
}

export interface ActionAccumulateSubscription extends Subscription {
    actionAccumulate: any;
}

export interface GeneratorAccumulateSubscription extends Subscription {
    generatorAccumulate: any;
}

export type AnySubscription =
    | ActionSubscription
    | GeneratorSubscription
    | ActionAccumulateSubscription
    | GeneratorAccumulateSubscription;

export function isActionSubscription(
    subscription: AnySubscription
): subscription is ActionSubscription {
    return (subscription as ActionSubscription).action !== undefined;
}

export function isGeneratorSubscription(
    subscription: AnySubscription
): subscription is GeneratorSubscription {
    return (subscription as GeneratorSubscription).generator !== undefined;
}

export function isActionAccumulateSubscription(
    subscription: AnySubscription
): subscription is ActionAccumulateSubscription {
    return (subscription as ActionAccumulateSubscription).actionAccumulate !== undefined;
}

export function isGeneratorAccumulateSubscription(
    subscription: AnySubscription
): subscription is GeneratorAccumulateSubscription {
    return (subscription as GeneratorAccumulateSubscription).generatorAccumulate !== undefined;
}
