import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { Bucket, BucketHeader, Button, DialogBox, NoLink } from "~/core";
import { DetailedApiStatus, getErrorMessages } from "~/i18n-error-messages";
import { APIError } from "@ai360/core";

import { RequireRefreshError } from "../require-refresh-error";
import { messages } from "./i18n-messages";

import { newApiResultToErrorDetails } from "~/utils/api/new-api";

import "./err-dialog.css";

Error.stackTraceLimit = 150;

export class ErrorDialog_ extends PureComponent {
    static propTypes = {
        err: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onRetry: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        intlMessageObj: PropTypes.object,
        intlMessageValues: PropTypes.object,
        retryAction: PropTypes.object,
        isOpen: PropTypes.bool,
    };

    static defaultProps = {
        isOpen: true,
    };

    _getApiErrorBody() {
        const { err, intlMessageObj, intlMessageValues, onRetry, retryAction } = this.props;
        const { formatMessage } = this.props.intl;

        const rootError =
            err.apiResultObj && err.apiResultObj.isNewApiErrorFormat ? err.apiResultObj : err;
        const delimiter = "|";
        const errCodeMessages = getErrorMessages(formatMessage, rootError, delimiter);

        const messageToFormat =
            intlMessageObj ||
            (err.detailedStatus === DetailedApiStatus.ValidationError
                ? messages.warningApiErrorMsg
                : messages.defaultApiErrorMsg);

        const errMsg = formatMessage(messageToFormat, intlMessageValues);
        const retryLink =
            err.detailedStatus !== DetailedApiStatus.HttpError || !retryAction ? null : (
                <div className="retry-link">
                    <NoLink
                        onClick={() => onRetry()}
                        label={formatMessage(messages.retryLinkLabel)}
                    />
                </div>
            );

        return (
            <div>
                {err.detailedStatus === DetailedApiStatus.ValidationError ? null : (
                    <div className="err-header">
                        {formatMessage(messages.serverSideErrorHdrTxt)}
                    </div>
                )}
                <div className="err-msg">{errMsg}</div>
                {!errCodeMessages ? null : (
                    <ul>
                        {errCodeMessages.split(delimiter).map((s, i) => (
                            <li key={i}>{s}</li>
                        ))}
                    </ul>
                )}
                {retryLink}
                {this._getErrorDetailsBody()}
            </div>
        );
    }

    _getErrorDetailsBody() {
        const { err } = this.props;
        const { formatMessage } = this.props.intl;
        if (
            err.apiResultObj == null ||
            err.apiResultObj.message == null ||
            err.detailedStatus === DetailedApiStatus.ValidationError
        ) {
            return;
        }
        const isNewApiFailureResponse =
            typeof err.apiResultObj !== "undefined" &&
            typeof err.apiResultObj.status !== "undefined" &&
            err.apiResultObj.status !== 500;
        return (
            <Bucket className="error-detail-bucket">
                <BucketHeader>{formatMessage(messages.serverErrorDetailsHeader)}</BucketHeader>
                {isNewApiFailureResponse
                    ? this._getNewApiErrorDetails()
                    : this._getServerErrorDetails(err)}
            </Bucket>
        );
    }

    _getNewApiErrorDetails() {
        const { err, intl } = this.props;

        const errorDetail = newApiResultToErrorDetails(err.apiResultObj, intl);

        return (
            <div className="results-container">
                {this._getNewApiDetailListRender(errorDetail.details, errorDetail.detailHeader)}
            </div>
        );
    }

    _getNewApiDetailListRender(details, detailHeader) {
        if (details.length === 0) {
            return null;
        }

        return (
            <div>
                {detailHeader ? <span className="detail-item-list">{detailHeader}</span> : null}

                <ul className="detail-item-list">
                    {details.map((item, index) => (
                        <li className="detail-item" key={index}>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    _getRequireRefreshBody() {
        const { err } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className="refresh-body">
                <div className="err-header">{formatMessage(messages.mustRefreshErrorTxt)}</div>
                <div className="err-msg">
                    {err.i18nMessage == null
                        ? err.message
                        : formatMessage(err.i18nMessage, err.i18nMsgValues)}
                </div>
                <div className="refresh-btn-container">
                    <Button
                        onClick={() => this._onForceReload()}
                        value={formatMessage(messages.refreshBtnTxt)}
                    />
                </div>
            </div>
        );
    }

    _getServerErrorDetails(err) {
        return (
            <div>
                <div className="server-msg">{err.apiResultObj.message}</div>
                <div className="server-ex-msg">{err.apiResultObj.exceptionMessage}</div>
                <div className="server-ex-type">{err.apiResultObj.exceptionType}</div>
                <div className="server-stack-trace">{err.apiResultObj.stackTrace}</div>
            </div>
        );
    }

    _getDialogBody() {
        const { err } = this.props;
        const { formatMessage } = this.props.intl;

        if (err instanceof RequireRefreshError) {
            // display error & force refresh
            return this._getRequireRefreshBody();
        }
        if (err instanceof APIError) {
            return this._getApiErrorBody();
        }

        // Some unexpected client-side exception
        return (
            <div>
                <div className="err-header">{formatMessage(messages.clientSideErrorHdrTxt)}</div>

                <Bucket className="error-detail-bucket">
                    <BucketHeader>{err.toString()}</BucketHeader>
                    {err.stack}
                </Bucket>
            </div>
        );
    }

    _onForceReload() {
        window.onbeforeunload = null;
        window.location.reload(true);
    }

    render() {
        const { err, onClose } = this.props;
        const refreshRequired = err instanceof RequireRefreshError;

        const { formatMessage } = this.props.intl;
        return (
            <DialogBox
                className="notifications-err-dialog"
                draggable
                hideCloseX={refreshRequired}
                isOpen={this.props.isOpen}
                title={formatMessage(
                    err.detailedStatus === DetailedApiStatus.ValidationError
                        ? messages.warningModalTitle
                        : messages.modalTitle
                )}
                onClose={() => onClose()}
                unrestricted
            >
                {this._getDialogBody()}
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onRetry: () => {
        ownProps.onClose();
        dispatch(ownProps.retryAction);
    },
});

export const ErrorDialog = connect(null, mapDispatchToProps)(injectIntl(ErrorDialog_));
