import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { Loader } from "~/core";
import { DragAndDropFileUploader } from "~/action-panel/components/common/drag-and-drop-file-uploader";
import { selectors as uploaderSelectors } from "~/action-panel/components/common/drag-and-drop-file-uploader";
import { models as recsEventsModels } from "~/recs-events";

import * as recInfoActions from "../../../actions";
import { NutrientProductSelectionDisplay } from "./nutrient-product-selection-display";
import { actions, models, selectors } from "./";

import "./rx-file-import.css";

interface RxFileImportProps {
    currentStep: models.RXFileImportCurrentStep;
    fieldGuid: string;
    fieldMatchingErrors: [string, any][];
    intl: intlShape;
    isBatch: boolean;
    isLoading: boolean;
    onClearEquationGroupGuid: () => void;
    onEditProductMix: (index: number) => void;
    recModel: any;
    recNutrientsInProcessing: any[];
    setupRxFileImport: () => void;
}

interface RxFileImportState {
    dragAndDropNode: HTMLElement;
}

class RxFileImport_ extends PureComponent<RxFileImportProps, RxFileImportState> {
    _containerNode: HTMLDivElement = null;

    constructor(props) {
        super(props);
        this.props.setupRxFileImport();
        this.state = {
            dragAndDropNode: null,
        };
    }

    componentDidMount(): void {
        this.setState({
            dragAndDropNode: this._containerNode
                ? this._containerNode.closest(".rec-event-info-form")
                : null,
        });
    }

    render(): JSX.Element {
        const {
            currentStep,
            fieldGuid,
            fieldMatchingErrors,
            isBatch,
            isLoading,
            onEditProductMix,
            recModel,
            recNutrientsInProcessing,
        } = this.props;
        const { formatMessage } = this.props.intl;
        if (isLoading) {
            return <Loader />;
        }
        return (
            <div className="rx-file-import-container" ref={(d) => (this._containerNode = d)}>
                {currentStep === "match" || currentStep === "complete" ? (
                    <NutrientProductSelectionDisplay
                        disableColumnSelection={
                            isBatch && fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
                        }
                        fieldGuid={fieldGuid}
                        onEditProductMix={onEditProductMix}
                        recModel={recModel}
                        recNutrientsInProcessing={recNutrientsInProcessing}
                    />
                ) : (
                    <>
                        <DragAndDropFileUploader
                            stateKey={models.RX_FILE_IMPORT_STATE_KEY}
                            dragAndDropNode={this.state.dragAndDropNode}
                        />
                        {fieldMatchingErrors.length > 0
                            ? fieldMatchingErrors.map((error) => (
                                  <div className="rx-file-import-error" key={error[0]}>
                                      {formatMessage(error[0], error[1])}
                                  </div>
                              ))
                            : null}
                    </>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onClearEquationGroupGuid: () => dispatch(recInfoActions.clearEquationGroupGuid()),
    setupRxFileImport: () => dispatch(actions.setupRxFileImport()),
});

const mapStateToProps = (state) => {
    const { currentStep, isLoading } = selectors.getModuleState(state);
    const { filesWithErrorsCount } = uploaderSelectors.getModuleState(
        state,
        models.RX_FILE_IMPORT_STATE_KEY
    );
    const fieldMatchingErrors = selectors.getFieldMatchingErrors(state);
    return {
        currentStep,
        fieldMatchingErrors: filesWithErrorsCount > 0 ? [] : fieldMatchingErrors, // Only show field matching errors if there are no file errors
        isLoading,
    };
};

export const RxFileImport = connect(mapStateToProps, mapDispatchToProps)(injectIntl(RxFileImport_));
