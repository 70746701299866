import * as model from "./model";
import { defaultValue } from "../../component/address/default-records";

const orgLevelDefaultRecord = () => ({
    [model.PROPS_AGVANCE_DATABASE_ID]: "",
    [model.PROPS_AGVANCE_USER_ID]: "",
    [model.PROPS_AGVANCE_USER_PASSWORD]: "",
    [model.PROPS_CAN_MODIFY]: false,
    [model.PROPS_ERROR_MESSAGE]: null,
    [model.PROPS_HAS_CHILDREN]: false,
    [model.PROPS_LOCATION_ID]: 0,
    [model.PROPS_NOTES]: "",
    [model.PROPS_ORG_LEVEL_ACTIVE_YN]: true,
    [model.PROPS_ORG_LEVEL_ADDRESS]: null,
    [model.PROPS_ORG_LEVEL_ADDRESS_LIST]: [defaultValue()],
    [model.PROPS_ORG_LEVEL_EQUIPMENT_LIST]: [],
    [model.PROPS_ORG_LEVEL_ID]: null,
    [model.PROPS_ORG_LEVEL_NAME]: "",
    [model.PROPS_ORG_LEVEL_NOTES]: null,
    [model.PROPS_ORG_LEVEL_PHONE_NUMBER_LIST]: [],
    [model.PROPS_ORG_LEVEL_UI_LABEL_LIST]: [],
    [model.PROPS_ORG_TYPE_GUID]: null,
    [model.PROPS_ORG_TYPE_LEVEL]: 0,
    [model.PROPS_PARENT_ORG_LEVEL_GUID]: "",
    [model.PROPS_PERSON_ORG_LEVEL_LIST]: [],
    [model.PROPS_URL_LIST]: [],
    [model.PROPS_UPDATED_AGVANCE_ORG_LEVEL_GUID]: "",
});

export default orgLevelDefaultRecord;
