import * as actions from "./actions";
import { ActionType } from "typesafe-actions";

const initialState = {
    reportBooks: [],
    reportBookTypes: [],
};
export function reportBookReducer(state = initialState, action: ActionType<typeof actions>): any {
    switch (action.type) {
        case actions.REFRESH_REPORT_BOOKS:
            return Object.freeze({
                ...state,
                refreshReportBooks: action.payload,
            });
        case actions.SET_CLEAR_REPORT_BOOK_TYPES:
            return Object.freeze({
                ...state,
                clearReportBookTypes: action.payload,
            });
        case actions.SET_REPORT_BOOK_LOADING:
            return Object.freeze({
                ...state,
                loadingReportBook: action.payload,
            });
        case actions.SET_REPORT_BOOKS:
            return Object.freeze({
                ...state,
                reportBooks: action.payload,
            });
        case actions.SET_REPORT_BOOKS_ERRORS_MAP:
            return Object.freeze({
                ...state,
                reportBookErrorsMap: action.payload,
            });
        case actions.SET_REPORT_BOOKS_SELECT_ALL:
            return Object.freeze({
                ...state,
                reportBooksSelectAll: action.payload,
            });
        case actions.SET_REPORT_BOOKS_TOTAL_COUNT:
            return Object.freeze({
                ...state,
                reportBooksTotalCount: action.payload,
            });
        case actions.SET_REPORT_BOOKS_AUTO_COMPLETE:
            return Object.freeze({
                ...state,
                reportBooksAutoComplete: action.payload,
            });
        case actions.SET_REPORT_BOOK_TYPES:
            return Object.freeze({
                ...state,
                reportBookTypes: action.payload,
            });
        default:
            return state;
    }
}
