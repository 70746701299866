import { connect } from "react-redux";
import * as selectors from "../../../selectors";
import * as actions from "../../../actions";
import { saveFieldInformation } from "~/action-panel/components/field-module";
import { injectIntl } from "react-intl";

import { FieldsPanel_ } from "../components/fields-panel";
import { getTheUserPersonalityId, getTheUserLockCustomer } from "~/login/selectors";

const mapStateToProps = (state) => {
    const importType = selectors.getImportWizardType(state);
    const { eventIdList } = selectors.getImportFilterListData(state);
    //Only sampling events that already have an EventIds are Result Only type imports
    const isResultsOnlySampling =
        actions.SamplingImportTypes.includes(importType.name.toLowerCase()) &&
        eventIdList != null &&
        eventIdList.length > 0;
    return {
        importGroomingRequest: selectors.getImportGroomingRequest(state),
        importFileGuidList: selectors.getImportFileGuidList(state),
        importType,
        importFieldStripData: selectors.getImportFieldStripData(state),
        isLockCustomer: getTheUserLockCustomer(state),
        isResultsOnlySampling,
        getIsFieldMatchLoading: () => {
            return selectors.getIsFieldMatchingLoading(state);
        },
        filteredFieldImportFileList: selectors.getFilteredFieldImportFileList(state),
        selectedMatchedFieldGuid: selectors.getSelectedMatchedFieldGuid(state),
        personalityId: getTheUserPersonalityId(state),
        agvanceFieldList: selectors.getAgvanceFieldList(state),
        agvanceOrgLevelList: selectors.getAgvanceOrgLevelList(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    fetchAgvanceFieldList: (customerGuid) => dispatch(actions.fetchAgvanceFieldList(customerGuid)),
    fetchAgvanceOrgLevelList: (customerGuid) =>
        dispatch(actions.fetchAgvanceOrgLevelList(customerGuid)),
    rerunMatching: () => dispatch(actions.rerunMatching()),
    setIsLoading: (isLoading) => dispatch(actions.setIsLoading(isLoading)),
    setImportGroomingRequest: (importGroomingRequest) =>
        dispatch(actions.setImportGroomingRequest(importGroomingRequest)),
    setFilteredFieldImportFileList: (filteredFieldImportFileList) =>
        dispatch(actions.setFilteredFieldImportFileList(filteredFieldImportFileList)),
    saveFieldInformation: (field, callback) => dispatch(saveFieldInformation(field, callback)),
    setImportFieldStripData: (importFieldStripData) =>
        dispatch(actions.setImportFieldStripData(importFieldStripData)),
    setSelectedMatchedFieldGuid: (selectedMatchedFieldGuid) =>
        dispatch(actions.setSelectedMatchedFieldGuid(selectedMatchedFieldGuid)),
    unmatchFieldFile: (field, file) => dispatch(actions.unmatchFieldFile(field, file)),
    updateEventId: (field, file, newEventId) =>
        dispatch(actions.updateEventId(field, file, newEventId)),
});

export const FieldsPanel = connect(mapStateToProps, mapDispatchToProps)(injectIntl(FieldsPanel_));
