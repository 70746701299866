export const ACTIVATE_REC_FROM_DIMIDX = "rec-module/ACTIVATE_REC_FROM_DIMIDX";
export const CLEAR_EXPORT_REC_GENERAL_GUID_SET = "rec-module/CLEAR_EXPORT_REC_GENERAL_GUID_SET";
export const CLEAR_CONVERT_RECS_TO_EVENTS_GUID_MAP =
    "rec-module/CLEAR_CONVERT_RECS_TO_EVENTS_GUID_MAP";
export const CLEAR_REC_FILTER = "rec-module/CLEAR_REC_FILTER";
export const CONFIGURE_REC_FILTERS = "rec-module/CONFIGURE_REC_FILTERS";
export const DELETE_RECS_FROM_DIMIDX_LIST = "rec-module/DELETE_RECS_FROM_DIMIDX_LIST";
export const DESELECT_RECS_FROM_ACCORDION = "rec-module/DESELECT_RECS_FROM_ACCORDION";
export const DESELECT_RECS_FROM_DIMIDX_LIST = "rec-module/DESELECT_RECS_FROM_DIMIDX_LIST";
export const DESELECT_RECS_FOR_FIELD_GUIDS = "rec-module/DESELECT_RECS_FOR_FIELD_GUIDS";
export const EXPORT_CONTROLLER_FILE = "rec-module/EXPORT_CONTROLLER_FILE";
export const HIDE_ERROR_DETAILS = "rec-module/HIDE_ERROR_DETAILS";
export const SELECT_RECS_FROM_ACCORDION = "rec-module/SELECT_RECS_FROM_ACCORDION";
export const SET_EXPORT_REC_GENERAL_GUID_SET = "rec-module/SET_EXPORT_REC_GENERAL_GUID_SET";
export const SET_CONVERT_RECS_TO_EVENTS_GUID_MAP = "rec-module/SET_CONVERT_RECS_TO_EVENTS_GUID_MAP";
export const SET_IS_REC_LOADING = "event-module/SET_IS_REC_LOADING";
export const SET_INIT_SCROLL_TOP = "rec-module/SET_INIT_SCROLL_TOP";
export const SET_LAST_CLICKED_DIMIDX = "rec-module/SET_LAST_CLICKED_DIMIDX";
export const SET_REC_FILTER = "rec-module/SET_REC_FILTER";
export const SET_REC_FILTER_INFO = "rec-module/SET_REC_FILTER_INFO";
export const SET_REC_FILTER_OPTIONS = "rec-module/SET_REC_FILTER_OPTIONS";
export const SET_REC_PANEL_LOADING = "rec-module/SET_REC_PANEL_LOADING";
export const SET_SHOW_FILTERS = "rec-module/SET_SHOW_FILTERS";
export const SHOW_ERROR_DETAILS = "rec-module/SHOW_ERROR_DETAILS";
export const UPDATE_EXPANDED_FIELD_GUID_SET = "rec-module/UPDATE_EXPANDED_FIELD_GUID_SET";
export const UPDATE_SEARCH = "rec-module/UPDATE_SEARCH";

export const activateRecFromDimIdx = (toActivateDimIdx) => ({
    type: ACTIVATE_REC_FROM_DIMIDX,
    payload: { toActivateDimIdx },
});
export const configureRecFilters = (filters) => ({
    type: CONFIGURE_REC_FILTERS,
    payload: { filters },
});

export const clearExportRecGeneralGuidSet = () => ({
    type: CLEAR_EXPORT_REC_GENERAL_GUID_SET,
});

export const clearConvertRecsToEventsGuidMap = () => ({
    type: CLEAR_CONVERT_RECS_TO_EVENTS_GUID_MAP,
});

export const clearRecFilter = () => ({
    type: CLEAR_REC_FILTER,
});
export const deleteRecsFromDimIdxList = (toDeleteDimIdxList) => ({
    type: DELETE_RECS_FROM_DIMIDX_LIST,
    payload: { toDeleteDimIdxList },
});

export const deselectRecsFromAccordion = (startDimIdx, endDimIdx) => ({
    type: DESELECT_RECS_FROM_ACCORDION,
    payload: { startDimIdx, endDimIdx },
});

export const deselectRecsFromDimIdxList = (toDeselectDimIdxList) => ({
    type: DESELECT_RECS_FROM_DIMIDX_LIST,
    payload: { toDeselectDimIdxList },
});

export const deselectRecsForFieldGuids = (fieldGuidsToRemove) => ({
    type: DESELECT_RECS_FOR_FIELD_GUIDS,
    payload: { fieldGuidsToRemove },
});

export const exportControllerFile = (exportRecRequest) => ({
    type: EXPORT_CONTROLLER_FILE,
    payload: { exportRecRequest },
});

export const hideErrorDetails = () => ({
    type: HIDE_ERROR_DETAILS,
});

export const selectRecsFromAccordion = (startDimIdx, endDimIdx) => ({
    type: SELECT_RECS_FROM_ACCORDION,
    payload: { startDimIdx, endDimIdx },
});

export const setExportRecGeneralGuidSet = (recGeneralGuids) => ({
    type: SET_EXPORT_REC_GENERAL_GUID_SET,
    payload: { recGeneralGuids },
});

export const setConvertRecsToEventsGuidMap = (convertRecsToEventsGuidMap) => ({
    type: SET_CONVERT_RECS_TO_EVENTS_GUID_MAP,
    payload: { convertRecsToEventsGuidMap },
});

export const setIsRecLoading = (isRecLoading) => ({
    type: SET_IS_REC_LOADING,
    payload: { isRecLoading },
});

export const setInitScrollTop = (activeTab, scrollTop) => ({
    type: SET_INIT_SCROLL_TOP,
    payload: { activeTab, scrollTop },
});

export const setLastClickedDimIdx = (itemDimIdx) => ({
    type: SET_LAST_CLICKED_DIMIDX,
    payload: { itemDimIdx },
});

export const setRecFilter = (recFilter) => ({
    type: SET_REC_FILTER,
    payload: { recFilter },
});
export const setRecFilterInfo = (filterInfo) => ({
    type: SET_REC_FILTER_INFO,
    payload: { filterInfo },
});
export const setRecFilterOptions = (filterOptions) => ({
    type: SET_REC_FILTER_OPTIONS,
    payload: { filterOptions },
});
export const setRecPanelLoading = (isPanelLoading) => ({
    type: SET_REC_PANEL_LOADING,
    payload: { isPanelLoading },
});

export const setShowFilters = (showFilters) => ({
    type: SET_SHOW_FILTERS,
    payload: { showFilters },
});

export const showErrorDetails = (recSummary) => ({
    type: SHOW_ERROR_DETAILS,
    payload: { recSummary },
});

export const updateExpandedFieldGuidSet = (fieldGuid, isExpanded) => ({
    type: UPDATE_EXPANDED_FIELD_GUID_SET,
    payload: { fieldGuid, isExpanded },
});

export const updateSearchValue = (searchValue) => ({
    type: UPDATE_SEARCH,
    payload: { searchValue },
});
