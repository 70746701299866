import React, { Component, ComponentClass, CSSProperties } from "react";
import Measure from "react-measure";
import { WrapperClass, WrapperInstance } from "react-onclickoutside";

import { AppHelpers } from "@ai360/core";

import { defaultOptionRenderer, SelectInput_, SelectInput } from "./select-input";
import {
    IAsyncSelectInputProps,
    IAsyncSelectInputState,
    IAutoWidthSelectInputState,
    ISelectInputProps,
    ISelectInputState,
    ISelectOption,
} from "./model";

/**
 * Wrap a `SelectInput` component such that the `options` prop is set from the result
 *   of the `getOptions` parameter, which accepts the `textInputStr` as a parameter
 * @param {<SelectInput />} SelectInput
 * @param {(textInputStr) => Promise<option[]>} getOptions
 * @param {number} debounceInterval
 */
export const withAsyncOptions = (
    SelectInputComponent: typeof SelectInput,
    getOptions: (filterValue: string) => Promise<Record<string, any>[]>,
    debounceInterval = 250
): ComponentClass<IAsyncSelectInputProps<string>, IAsyncSelectInputState<string>> => {
    const getOptionsDebounced = AppHelpers.debouncePromise(getOptions, debounceInterval);

    return class AsyncSelectInput extends Component<
        IAsyncSelectInputProps<string>,
        IAsyncSelectInputState<string>
    > {
        static SYMBOL_ALL_OPTIONS = "_";

        constructor(props: IAsyncSelectInputProps<string>) {
            super(props);
            this.state = {
                options: [],
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps: IAsyncSelectInputProps<string>): void {
            if (nextProps.resetOptions) {
                getOptionsDebounced
                    .call(AsyncSelectInput.SYMBOL_ALL_OPTIONS)
                    .then((options: ISelectOption<string>[]) => {
                        this.setState({ options });
                    });
            }
        }

        onInputChange(inputVal: string): void {
            if (inputVal) {
                getOptionsDebounced.call(inputVal).then((options) => {
                    this.setState({ options });
                });
            } else {
                // Fetch all records when input is reset
                getOptionsDebounced.call(AsyncSelectInput.SYMBOL_ALL_OPTIONS).then((options) => {
                    this.setState({ options });
                });
            }
            if (this.props.onInputChange) {
                this.props.onInputChange(inputVal);
            }
        }

        componentDidMount(): void {
            getOptionsDebounced
                .call(AsyncSelectInput.SYMBOL_ALL_OPTIONS)
                .then((options: ISelectOption<string>[]) => {
                    this.setState({ options });
                });
        }

        componentWillUnmount(): void {
            getOptionsDebounced.cancel();
        }

        render(): JSX.Element {
            return (
                <SelectInputComponent
                    {...this.props}
                    options={this.state.options}
                    noOptionsRenderer={this.props.noOptionsRenderer}
                    onInputChange={(v) => this.onInputChange(v)}
                    onChange={(v) => {
                        if (this.props.clearOnSelection) {
                            this.onInputChange("");
                        }
                        if (this.props.onChange) {
                            this.props.onChange(v);
                        }
                    }}
                />
            );
        }
    };
};

/**
 * Wrap a `SelectInput` component such that the `width` prop is calculated by
 *  rendering all options to a node with `visibility: "hidden"`.  This should
 *  only be used when the list of options is small (< 100)
 *
 * @param {<SelectInput />} SelectInput
 * @param {number?} maxWidth
 * @param {number?} scrollbarWidth
 */
export const withAutoWidth = (
    SelectInput: WrapperClass<ISelectInputProps<string>, typeof SelectInput_>,
    maxWidth: number
): ComponentClass => {
    return class AutoWidthSelect extends Component<
        ISelectInputProps<string>,
        IAutoWidthSelectInputState
    > {
        private _input: WrapperInstance<ISelectInputProps<string>, typeof SelectInput_> | null;

        constructor(props: ISelectInputProps<string>) {
            super(props);
            this.state = { width: undefined };
        }

        #getOptions(): JSX.Element[] {
            const { options } = this.props;
            console.assert(
                options && options.length < 100,
                "option list too big for `withAutoWidth`"
            );

            const optionRenderer = this.props.optionRenderer || defaultOptionRenderer;
            if (options) {
                return (options as ISelectOption<string>[]).map((option, i) => {
                    const optionEl = optionRenderer({
                        option,
                        isSelected: false,
                        isHighlighted: false,
                        matchPos: -1,
                    });
                    return <div key={i}>{optionEl}</div>;
                });
            }
            return [];
        }

        #setWidth(width: number): void {
            if (maxWidth && width > maxWidth) {
                width = maxWidth;
            }
            this.setState({ width });
        }

        render(): JSX.Element {
            const hiddenStyle = {
                position: "fixed",
                visibility: "hidden",
            } as CSSProperties;

            const hiddenClassName = "form-input select-form-input-options";

            const { width } = this.state;

            return (
                <div>
                    <Measure onMeasure={({ width }) => this.#setWidth(width)}>
                        <div className={hiddenClassName} style={hiddenStyle}>
                            {this.#getOptions()}
                        </div>
                    </Measure>
                    <SelectInput
                        ref={(input) => (this._input = input)}
                        {...this.props}
                        minOptionsWidth={width}
                    />
                </div>
            );
        }
    };
};

export const withIgnoreUnderScore = (SelectInputComponent: typeof SelectInput): ComponentClass => {
    return class IgnoreUnderscoreSelect extends Component<
        ISelectInputProps<string>,
        ISelectInputState
    > {
        constructor(props: ISelectInputProps<string>) {
            super(props);
        }

        filterOptions(
            filterVal: string,
            options: ISelectOption<string>[],
            isHeaderOption: (opt: ISelectOption<string>) => boolean
        ): any[] {
            const matchingIdxList: number[] = [];
            const optionMatchPositions: number[] = [];
            const filteredOptListIdxMap: Map<number, number> = new Map();
            filterVal = filterVal == null ? "" : filterVal.toLowerCase().replace(/[_-]/g, " ");
            for (let optionIdx = 0, matchCount = 0; optionIdx < options.length; optionIdx++) {
                const option = options[optionIdx];
                if (isHeaderOption && isHeaderOption(option)) {
                    optionMatchPositions.push(-1);
                    filteredOptListIdxMap.set(matchCount++, optionIdx);
                    continue;
                }
                const labelVal = (option.label as string).toLowerCase().replace(/[_-]/g, " ");
                const idx = labelVal.indexOf(filterVal);
                optionMatchPositions.push(idx);
                if (labelVal === filterVal && matchingIdxList.length < 2) {
                    matchingIdxList.push(optionIdx);
                }
                if (idx !== -1) {
                    filteredOptListIdxMap.set(matchCount++, optionIdx);
                }
            }

            const exactMatchIdx = matchingIdxList.length === 1 ? matchingIdxList[0] : null;
            return [optionMatchPositions, filteredOptListIdxMap, exactMatchIdx];
        }

        render(): JSX.Element {
            return <SelectInputComponent {...this.props} filterOptions={this.filterOptions} />;
        }
    };
};
