import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    Section,
    SelectInput,
    SubSection,
    ZeroToInfiniteGrid,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import {
    mapToPicklistValue,
    prepareSelectableOptions,
    hasOnlyInActiveOptions,
} from "~/admin/utils";
import { messages } from "~/admin/agBytes/i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
const PROPS_CROP_CLASSNAME_GUID = "cropClassNameGuid";
const PROPS_CROP_CLASS_NAME = "cropClassName";
const PROPS_CROP_NAME = "cropName";
const PROPS_CROP_PURPOSE = "cropPurpose";
const PROPS_CROP_PURPOSE_GUID = "cropPurposeGuid";
const PROPS_CROP_PURPOSE_NAME = "cropPurposeName";
const PROP_CROP_GUID = "cropGuid";

class CropList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleCropValidState: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        getCropClassNames: PropTypes.func,
        options: PropTypes.object,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            cropName: [],
            cropPurpose: [],
            cropClassName: [],
            editingItem: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.options[PROPS_CROP_CLASS_NAME] !== nextProps.options[PROPS_CROP_CLASS_NAME]
        ) {
            this.initializeCropClassName(nextProps.options, nextProps.record);
        }

        const { record } = nextProps;
        if (record && record.length > 0) {
            if (nextProps.options[PROPS_CROP_NAME] && nextProps.options[PROPS_CROP_PURPOSE]) {
                this.initializeCropListData(nextProps);
            }
        }
        if (!nextProps.isDeleting && this.props.isDeleting && this.props.toggleCropValidState) {
            this.props.toggleCropValidState(nextProps.record.length > 0);
        }
    }

    initializeCropListData = ({ record = [], options }) => {
        if (record) {
            const { cropClassName, cropName, cropPurpose } = options;
            return record.map((recordItem) => {
                const selectedCropName = cropName.find((cropNameItem) => {
                    return cropNameItem.guid === recordItem[PROP_CROP_GUID];
                });
                recordItem[PROPS_CROP_NAME] = selectedCropName && selectedCropName[NAME];
                const selectedCropPurpose = cropPurpose.find((cropPurposeItem) => {
                    return cropPurposeItem[PICKLIST_GUID] === recordItem[PROPS_CROP_PURPOSE_GUID];
                });
                recordItem[PROPS_CROP_PURPOSE_NAME] =
                    selectedCropPurpose && selectedCropPurpose[VALUE];
                if (cropClassName) {
                    const selectedCropClassName = cropClassName.find((cropClassNameItem) => {
                        return cropClassNameItem.guid === recordItem[PROPS_CROP_CLASSNAME_GUID];
                    });
                    recordItem[PROPS_CROP_CLASS_NAME] =
                        selectedCropClassName && selectedCropClassName[NAME];
                }
                return recordItem;
            });
        }
        return record;
    };

    initializeDropdowns = (options, isAdd = false) => {
        this.initializeCropName(options);
        this.initializeCropPurpose(options);
        if (!isAdd) {
            this.initializeCropClassName(options);
        }
    };

    initializeCropClassName = (options) => {
        this.setState({
            cropClassName: prepareSelectableOptions(options[PROPS_CROP_CLASS_NAME], {
                guid: GUID,
                label: NAME,
                id: ID,
            }),
        });
    };

    initializeCropName = (options) => {
        this.setState({
            cropName: prepareSelectableOptions(options[PROPS_CROP_NAME], {
                guid: GUID,
                label: NAME,
                id: ID,
            }),
        });
    };

    initializeCropPurpose = (options) => {
        this.setState({
            cropPurpose: prepareSelectableOptions(options[PROPS_CROP_PURPOSE], {
                guid: PICKLIST_GUID,
                label: VALUE,
                id: ID,
            }),
        });
    };

    onEdit = (record, index) => {
        if (record[PROP_CROP_GUID] && this.props.getCropClassNames) {
            const cropGuid = record[PROP_CROP_GUID];
            this.props.getCropClassNames({ cropGuid });
        }
        this.initializeDropdowns(this.props.options);
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROP_CROP_GUID],
                key: GUID,
            },
            {
                name: [PROPS_CROP_NAME],
                key: NAME,
            },
            {
                name: [PROPS_CROP_PURPOSE_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [PROPS_CROP_PURPOSE_NAME],
                key: VALUE,
            },
            {
                name: [PROPS_CROP_CLASSNAME_GUID],
                key: GUID,
            },
            {
                name: [PROPS_CROP_CLASS_NAME],
                key: [NAME],
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onCropNameChange = (guid, name) => {
        this.props.onChange({
            [PROP_CROP_GUID]: guid,
            [PROPS_CROP_NAME]: name,
            [PROPS_CROP_CLASS_NAME]: "",
            [PROPS_CROP_CLASSNAME_GUID]: "",
        });
        if (this.props.getCropClassNames) {
            const cropGuid = guid;
            this.props.getCropClassNames({ cropGuid });
        }
    };

    onAdd = () => {
        this.initializeDropdowns(this.props.options, true);
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    onAction = () => {
        this.props.onAddOrEditItem();
        if (this.props.toggleCropValidState) {
            this.props.toggleCropValidState(true);
        }
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, toggleModal } = this.props;
        return (
            <DialogBox
                actionDisabled={!editData[PROP_CROP_GUID]}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                onAction={() => this.onAction()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.crop
                )}
            >
                <div className="dialog-box-select">
                    <Section>
                        <SubSection>
                            <SelectInput
                                required
                                tabIndex={0}
                                options={this.state.cropName}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                autoFocus
                                openOnFocus={false}
                                placeholderText={formatMessage(messages.crop)}
                                value={mapToPicklistValue({
                                    options: this.state.cropName,
                                    selectedGuid: editData[PROP_CROP_GUID],
                                })}
                                onChange={(value) => {
                                    const guid = value ? value.guid : "";
                                    const name = value ? value.name : "";
                                    this.props.onChange({
                                        [PROP_CROP_GUID]: guid,
                                    });
                                    this.onCropNameChange(guid, name);
                                }}
                            />
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state.cropPurpose}
                                placeholderText={formatMessage(messages.cropPurpose)}
                                value={mapToPicklistValue({
                                    options: this.state.cropPurpose,
                                    selectedGuid: editData[PROPS_CROP_PURPOSE_GUID],
                                })}
                                onChange={(value) => {
                                    this.props.onChange({
                                        [PROPS_CROP_PURPOSE_GUID]: value ? value.guid : "",
                                        [PROPS_CROP_PURPOSE_NAME]: value ? value.name : "",
                                    });
                                }}
                            />
                        </SubSection>
                        <SubSection>
                            {!this.state.cropClassName.length > 0 ||
                            hasOnlyInActiveOptions(this.state.cropClassName) ? null : (
                                <SelectInput
                                    tabIndex={0}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    options={this.state.cropClassName}
                                    placeholderText={formatMessage(messages.cropClassName)}
                                    value={mapToPicklistValue({
                                        options: this.state.cropClassName,
                                        selectedGuid: editData[PROPS_CROP_CLASSNAME_GUID],
                                    })}
                                    onChange={(value) => {
                                        this.props.onChange({
                                            [PROPS_CROP_CLASSNAME_GUID]: value ? value.guid : "",
                                            [PROPS_CROP_CLASS_NAME]: value ? value.name : "",
                                        });
                                    }}
                                />
                            )}
                        </SubSection>
                    </Section>
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {!(record && record.length > 0) ? null : (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_CROP_NAME]: {
                                title: formatMessage(messages.crop),
                            },
                            [PROPS_CROP_PURPOSE_NAME]: {
                                title: formatMessage(messages.cropPurpose),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.crop)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(CropList);
