import * as actions from "./actions";
import { device as Device } from "aws-iot-device-sdk";
import { ActionType } from "typesafe-actions";

const initialState: State = {
    device: null,
    host: null,
    receivers: [],
};

export interface State {
    device: Device;
    host: string | null;
    receivers: any[];
}

export function messagingReducer(state = initialState, action: ActionType<typeof actions>): State {
    switch (action.type) {
        case actions.START: {
            return {
                ...state,
                host: action.payload.host,
            };
        }
        case actions.SET_DEVICE: {
            return {
                ...state,
                device: action.payload.device,
            };
        }
        case actions.UPDATE_CREDENTIALS: {
            const credentials = action.payload.credentials;
            state.device.updateWebSocketCredentials(
                credentials.accessKeyId,
                credentials.secretKey,
                credentials.sessionToken
            );
            return {
                ...state,
            };
        }
        case actions.NEW_RECEIVER: {
            return {
                ...state,
                receivers: state.receivers.concat(action.payload.receiver),
            };
        }
        default:
            return state;
    }
}
