import React, { Component, MouseEvent } from "react";
import classnames from "classnames";
import "./no-link.css";

interface INoLinkProps {
    disabled?: boolean;
    className?: string;
    classNames?: string[];
    onClick?: (evt: MouseEvent) => void;
    label?: string | JSX.Element;
    title?: string;
}

export class NoLink extends Component<INoLinkProps> {
    link: HTMLAnchorElement;

    public static defaultProps = {
        className: null,
        classNames: [],
        disabled: false,
        label: "",
    };

    private _click(evt: MouseEvent): void {
        const { disabled, onClick } = this.props;
        if (disabled) {
            return;
        }
        if (onClick) {
            onClick(evt);
        }
        evt.preventDefault();
        if (this.link) {
            this.link.blur();
        }
    }

    render(): JSX.Element {
        const { className, classNames, disabled, label, title } = this.props;
        return (
            <a
                className={classnames(className, classNames, { disabled })}
                href={"#" + label}
                onClick={(evt: MouseEvent): void => this._click(evt)}
                ref={(ref) => (this.link = ref)}
                title={title}
            >
                {label}
            </a>
        );
    }
}
