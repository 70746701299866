export class ScoutingTemplateObservation {
    eventScoutingTemplateObservationGuid: string = null;
    eventScoutingTemplateGuid: string = null;
    observationOrderId: number | null = null;
    observationGuid: string = null;
    showGrowthStage = false;
    growthStageGuid: string = null;
    densityRatingGuid: string = null;
    showObservationCount = false;
    observationCount: number | null = null;
    countUnitsGuid: string = null;
    showPlantLocation = false;
    plantLocationGuid: string = null;
    showWeedHeight = false;
    weedHeight: number | null = null;
    weedHeightUnitsGuid: string = null;
    showTrapId = false;
    trapId: string = null;

    static fromJsonObj(jsonObj: Partial<ScoutingTemplateObservation>): ScoutingTemplateObservation {
        return Object.assign(new ScoutingTemplateObservation(), jsonObj);
    }

    update(newProps: unknown): ScoutingTemplateObservation {
        return Object.assign(new ScoutingTemplateObservation(), this, newProps);
    }
}
