import React from "react";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import "./panel-navigation.css";

import {
    CollapsedIcon,
    EventsIcon,
    ExpandedIcon,
    FieldIcon,
    ImportIcon,
    LayersIcon,
    RecsIcon,
} from "~/core/icons";

import { ActionPanelModuleList } from "../../actions";
import { messages } from "../../i18n-messages";

export interface IUserInfo {
    role?: Record<string, any>;
}
export interface IPanelNavigationProps {
    activeModule?: string;
    eventSelectionCount?: number | string;
    fieldSelectionCount?: number | string;
    importSelectionCount?: number | string;
    intl: intlShape.isRequired;
    isEnabled?: boolean;
    isExpanded?: boolean;
    onModuleChange: (val: string) => void;
    onToggleExpandCollapse: (val: MouseEvent) => void;
    recSelectionCount?: number | string;
    userInfo: IUserInfo;
}
class PanelNavigation_ extends React.Component<IPanelNavigationProps> {
    static defaultProps = {
        activeModule: ActionPanelModuleList.FIELD,
        eventSelectionCount: 0,
        importSelectionCount: 0,
        isEnabled: false,
        isExpanded: true,
        fieldSelectionCount: 0,
        recSelectionCount: 0,
    };
    /// ----------------------------------------- ///

    /// Event Handlers ///
    onToggleClick = (newValue) => {
        if (this.props.isEnabled) {
            this.props.onToggleExpandCollapse(newValue);
        }
    };
    onTabClick = (newModule: string) => {
        if (this.props.isEnabled) {
            this.props.onModuleChange(newModule);
        }
    };
    /// ------------- ///

    render() {
        const { formatMessage } = this.props.intl;
        const fieldsLbl = formatMessage(messages.fields);
        const recsLbl = formatMessage(messages.recs);
        const eventsLbl = formatMessage(messages.events);
        const layersLbl = formatMessage(messages.layers);
        const importLbl = formatMessage(messages.import);

        const fieldTabCountEl =
            this.props.fieldSelectionCount === 0 ? null : (
                <span
                    className={classNames("tab-count")}
                    title={this.props.fieldSelectionCount as string}
                >
                    {this.props.fieldSelectionCount}
                </span>
            );

        const recTabCountEl =
            !this.props.isEnabled || this.props.recSelectionCount === 0 ? null : (
                <span
                    className={classNames("tab-count")}
                    title={this.props.recSelectionCount as string}
                >
                    {this.props.recSelectionCount}
                </span>
            );

        const eventTabCountEl =
            this.props.eventSelectionCount === 0 ? null : (
                <span
                    className={classNames("tab-count")}
                    title={this.props.eventSelectionCount as string}
                >
                    {this.props.eventSelectionCount}
                </span>
            );

        // For import tab the selected badge should always show up with the exception of other action panel details
        const importTabCountEl =
            (this.props.activeModule !== ActionPanelModuleList.IMPORT && !this.props.isEnabled) ||
            this.props.importSelectionCount === 0 ? null : (
                <span
                    className={classNames("tab-count")}
                    title={this.props.importSelectionCount as string}
                >
                    {this.props.importSelectionCount}
                </span>
            );

        return (
            <div
                className={classNames(
                    "panel-navigation",
                    !this.props.isExpanded ? "tab-collapse" : "tab-expand",
                    !this.props.isEnabled ? "disabled" : null
                )}
            >
                <div className="module-navigation">
                    <div
                        className={classNames(
                            "panel-nav-tab",
                            this.props.activeModule === ActionPanelModuleList.FIELD
                                ? "active-nav"
                                : null
                        )}
                        onClick={() => {
                            this.onTabClick(ActionPanelModuleList.FIELD);
                        }}
                    >
                        <FieldIcon
                            className={classNames("panel-nav-icon", {
                                "active-icon":
                                    this.props.activeModule === ActionPanelModuleList.FIELD,
                            })}
                        />
                        <div className="text-and-count">
                            <span className="tab-text" title={fieldsLbl}>
                                {fieldsLbl}
                            </span>
                            {fieldTabCountEl}
                        </div>
                    </div>
                    {!this.props.userInfo.role.recommendations ? null : (
                        <div
                            className={classNames("panel-nav-tab", {
                                "active-nav": this.props.activeModule === ActionPanelModuleList.REC,
                            })}
                            onClick={() => {
                                this.onTabClick(ActionPanelModuleList.REC);
                            }}
                        >
                            <RecsIcon
                                className={classNames("panel-nav-icon", {
                                    "active-icon":
                                        this.props.activeModule === ActionPanelModuleList.REC,
                                })}
                            />
                            <div className="text-and-count">
                                <span className="tab-text" title={recsLbl}>
                                    {recsLbl}
                                </span>
                                {recTabCountEl}
                            </div>
                        </div>
                    )}
                    {!this.props.userInfo.role.agEvents ? null : (
                        <div
                            className={classNames("panel-nav-tab", {
                                "active-nav":
                                    this.props.activeModule === ActionPanelModuleList.EVENT,
                            })}
                            onClick={() => {
                                this.onTabClick(ActionPanelModuleList.EVENT);
                            }}
                        >
                            <EventsIcon
                                className={classNames("panel-nav-icon", {
                                    "active-icon":
                                        this.props.activeModule === ActionPanelModuleList.EVENT,
                                })}
                            />
                            <div className="text-and-count">
                                <span className="tab-text" title={eventsLbl}>
                                    {eventsLbl}
                                </span>
                                {eventTabCountEl}
                            </div>
                        </div>
                    )}
                    {!this.props.userInfo.role.layers ? null : (
                        <div
                            className={classNames("panel-nav-tab", {
                                "active-nav":
                                    this.props.activeModule === ActionPanelModuleList.LAYER,
                            })}
                            onClick={() => {
                                this.onTabClick(ActionPanelModuleList.LAYER);
                            }}
                        >
                            <LayersIcon
                                className={classNames("panel-nav-icon", {
                                    "active-icon":
                                        this.props.activeModule === ActionPanelModuleList.LAYER,
                                })}
                            />
                            <span className="tab-text" title={layersLbl}>
                                {layersLbl}
                            </span>
                        </div>
                    )}
                    {!this.props.userInfo.role.import ? null : (
                        <div
                            className={classNames("panel-nav-tab", {
                                "active-nav":
                                    this.props.activeModule === ActionPanelModuleList.IMPORT,
                            })}
                            onClick={() => this.onTabClick(ActionPanelModuleList.IMPORT)}
                        >
                            <ImportIcon
                                className={classNames("panel-nav-icon", {
                                    "active-icon":
                                        this.props.activeModule === ActionPanelModuleList.IMPORT,
                                })}
                            />
                            <div className="text-and-count">
                                <span className="tab-text" title={importLbl}>
                                    {importLbl}
                                </span>
                                {importTabCountEl}
                            </div>
                        </div>
                    )}
                    <div
                        className={classNames(
                            "collapse-button ",
                            this.props.isExpanded
                                ? "collapse-button-expanded"
                                : "collapse-button-collapsed"
                        )}
                        onClick={() => {
                            this.onToggleClick(!this.props.isExpanded);
                        }}
                    >
                        {this.props.isExpanded ? (
                            <ExpandedIcon className="panel-nav-icon" />
                        ) : (
                            <CollapsedIcon className="panel-nav-icon" />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export const PanelNavigation = injectIntl(PanelNavigation_);
