import { defineMessages } from "react-intl";

export const messages = defineMessages({
    acres: { id: "fieldList.acres", defaultMessage: "Acres: {acres}" },
    addCustomerText: {
        id: "fieldsModule.fieldList.addCustomerText",
        defaultMessage: "Add Customer",
    },
    addHint: { id: "fieldsModule.fieldList.addHint", defaultMessage: "Add" },
    batchEditDetails: {
        id: "fieldsModule.fieldList.batchEditDetails",
        defaultMessage: "Batch Edit Field Details ({fieldCount})",
    },
    eventText: {
        id: "fieldList.eventText",
        defaultMessage: "{count, plural, one {Event} other {Events}}",
    },
    failedToMoveFields: {
        id: "fieldsModule.dialogBoxes.failedToMove",
        defaultMessage: "An error occurred while attempting move the selected fields.",
    },
    farm: { id: "fieldList.farm", defaultMessage: "Farm: {farmName}" },
    fieldText: {
        id: "fieldList.fieldText",
        defaultMessage: "{count, plural, one {Field} other {Fields}}",
    },
    classification: {
        id: "fieldsModule.fieldList.classification",
        defaultMessage: "Field Classification",
    },
    cropPurpose: {
        id: "fieldsModule.fieldList.cropPurpose",
        defaultMessage: "Crop Purpose",
    },
    drainageTile: {
        id: "fieldsModule.fieldList.drainageTile",
        defaultMessage: "Drainage Tile",
    },
    filterClear: {
        id: "fieldsModule.fieldList.filterClear",
        defaultMessage: "Clear All Filters",
    },
    crop: {
        id: "fieldsModule.fieldList.crop",
        defaultMessage: "Crop",
    },
    filterEnrolled: {
        id: "fieldsModule.fieldList.filterEnrolled",
        defaultMessage: "Enrolled",
    },
    irrigated: {
        id: "fieldsModule.fieldList.irrigated",
        defaultMessage: "Irrigated",
    },
    irrigationType: {
        id: "fieldsModule.fieldList.irrigationType",
        defaultMessage: "Irrigation Type",
    },
    filterLocation: {
        id: "fieldsModule.fieldList.filterLocation",
        defaultMessage: "Location",
    },
    organic: {
        id: "fieldsModule.fieldList.organic",
        defaultMessage: "Certified Organic",
    },
    filterSalesperson: {
        id: "fieldsModule.fieldList.filterSalesperson",
        defaultMessage: "Salesperson",
    },
    filterTitle: {
        id: "fieldsModule.fieldList.filterTitle",
        defaultMessage: "Field Filters",
    },
    hideFilters: {
        id: "fieldsModule.fieldList.hideFilters",
        defaultMessage: "Hide Filter",
    },
    move: { id: "fieldsModule.dialogBoxes.move", defaultMessage: "Move" },
    moveTo: {
        id: "fieldsModule.dialogBoxes.moveTo",
        defaultMessage: "Move To",
    },
    recText: {
        id: "fieldList.recText",
        defaultMessage: "{count, plural, one {Rec} other {Recs}}",
    },
    showFilters: {
        id: "fieldsModule.fieldList.showFilters",
        defaultMessage: "Show Filter",
    },
    selectedField: {
        id: "fieldsModule.fieldList.selectedField",
        defaultMessage: "Selected {count, plural, one {Field} other {Fields}}",
    },
    totalAcres: { id: "fieldList.totalAcres", defaultMessage: "Total Acres" },
    combineTitle: {
        id: "fieldsModule.combineFields.combineTitle",
        defaultMessage: "Combine Fields",
    },
    combineWarning1: {
        id: "fieldsModule.combineFields.combineWarning1",
        defaultMessage:
            "This process cannot be reversed. " +
            "All existing Field Profile information on these fields will not be transferred to this new Field.  " +
            "Are you sure you want to combine the following fields into a single field?",
    },
    combineWarning2: {
        id: "fieldsModule.combineFields.combineWarning2",
        defaultMessage:
            "All existing data on each of these " +
            "fields will be added to this new combined field but all layers will remain unchanged.  " +
            "Please enter a Field Name and optional Farm below:",
    },
    combineButton: {
        id: "fieldsModule.combineFields.combineButton",
        defaultMessage: "Combine",
    },
    fieldFarmNamePlaceholderText: {
        id: "fieldsModule.combineFields.fieldFarmNamePlaceholderText",
        defaultMessage: "Farm Name",
    },
    combineFieldNameWithFarmName: {
        id: "fieldModule.combineFields.fieldNameWithFarmName",
        defaultMessage: "{customerName} - {farmName} - {fieldName} - {acres, number} ac",
    },
    combineFieldName: {
        id: "fieldModule.combineFields.fieldName",
        defaultMessage: "{customerName} - {fieldName} - {acres, number} ac",
    },
    combinedFieldTooLarge: {
        id: "fieldModule.combineFields.tooLarge",
        defaultMessage:
            "Total field size selected of {size, number} ac would exceed the limit of {limit, number} ac if combined",
    },
});
