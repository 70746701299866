import * as actions from "./actions";
import { messages } from "./i18n-messages";
import * as eventsModels from "./events/model";
import * as eventsSelectors from "./events/selectors";

import * as commonModels from "./model";
import * as recsModels from "./recs/model";
import * as recsSelectors from "./recs/selectors";
import * as recsActions from "./recs/actions";

import * as analysisModels from "./analysis/model";
import * as analysisSelectors from "./analysis/selectors";

import * as selectors from "./selectors";
import { recsEventsReducer } from "./reducer";
import { recsEventsSaga } from "./sagas";

const models = {
    ...commonModels,
    ...eventsModels,
    ...recsModels,
    ...analysisModels,
};

const { RECS_EVENTS_DATA_KEY } = selectors;

export {
    RECS_EVENTS_DATA_KEY,
    actions,
    analysisSelectors,
    messages,
    models,
    eventsModels,
    eventsSelectors,
    selectors,
    recsModels,
    recsSelectors,
    recsActions,
    recsEventsReducer,
    recsEventsSaga,
};
