import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_AGGATEWAY_PACKAGE_ID = "agGatewayPackageId";
export const PROPS_AGRIAN_PACKAGE_ID = "agrianPackageId";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_DOT_SHIPPING_DESCRIPTION = "dotShippingDescription";
export const PROPS_LONG_DESCRIPTION = "longDescription";
export const PROPS_MFR_PART_NUMBER = "mfrPartNumber";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_PACKAGE_SIZE = "packageSize";
export const PROPS_PACKAGE_UNIT_ABBREVIATION = "packageUnitAbbreviation";
export const PROPS_PACKAGE_UNIT_GUID = "packageUnitGuid";
export const PROPS_PACKAGE_UNIT_NAME = "packageSizeUnit";
export const PROPS_PRODUCT_GUID = "productGuid";
export const PROPS_PRODUCT_ID = "productId";
export const PROPS_PRODUCT_NAME = "productName";
export const PROPS_PRODUCT_PACKAGE_BACKOFFICE_PRODUCT_CODES =
    "productPackageBackOfficeProductCodes";
export const PROPS_PRODUCT_PACKAGE_GTINS = "productPackageGtins";
export const PROPS_PRODUCT_PACKAGE_GUID = "productPackageGuid";
export const PROPS_PRODUCT_PACKAGE_ID = "productPackageId";
export const PROPS_SEED_COUNT = "seedCount";
export const PROPS_SEED_COUNT_UNIT_ABBREVIATION = "seedCountUnitAbbreviation";
export const PROPS_SEED_COUNT_UNIT_GUID = "seedCountUnitGuid";
export const PROPS_SEED_COUNT_UNIT_NAME = "seedCountUnitName";
export const PROPS_SEED_SIZE = "seedSize";
export const PROPS_UPC_CODE = "upcCode";
