import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { RecList } from "./rec-list/components/rec-list";
import { RecInfo } from "./rec-info/components/rec-info";

import * as actions from "../actions";
import * as selectors from "../selectors";

import "./rec-module.css";

export class RecModule_ extends Component {
    static propTypes = {
        activePage: PropTypes.string.isRequired,
    };

    _getCurrentPage() {
        const { activePage } = this.props;

        if (activePage === actions.RecModulePages.REC_LIST) {
            return <RecList />;
        }
        console.assert(activePage === actions.RecModulePages.REC_INFORMATION);
        return <RecInfo />;
    }

    render() {
        return <div className="rec-module">{this._getCurrentPage()}</div>;
    }
}

const mapStateToProps = (state) => {
    const { activePage } = selectors.getModuleState(state);
    return { activePage };
};

export const RecModule = connect(mapStateToProps, null)(RecModule_);
