import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import {
    IFormatter,
    ISelectOption,
    MessageDescriptor,
    MessageFormatPrimitiveValue,
} from "@ai360/core/dist/4x/es/types";
import { LayerAPI, LayerUtilsAPI } from "@ai360/core";
import {
    IClassBreak,
    IColorRamp,
    IColorInfo,
    ILayer,
    ISubLayer,
    IClassification,
} from "@ai360/core/dist/4x/es/api/layer";

import "./edit-layer-properties-utils";

import { DialogBox } from "~/core/components/dialog-box/dialog-box";
import { DialogBoxFooterType } from "~/core/components/dialog-box/dialog-box-footer";
import { SelectInput } from "~/core/components/select-input/select-input";
import { ACTIVE_YN } from "~/core/picklist";
import { IOptionRendererProps } from "~/core/components/select-input/model";
import classnames from "classnames";
import { actions as notificationActions } from "~/notifications";

import * as selectors from "~/action-panel/components/layer-module/components/layer-list/selectors";
import {
    getLayerTypeLetter,
    canReclassifyRecSurface,
    colorOptionRenderer,
    getBackgroundGradientStyle,
    isAllSingleValueClasses,
} from "~/action-panel/components/layer-module/utils";

import { messages } from "~/action-panel/components/layer-module/components/i18n-messages";
import "./sampling-zone-info.css";
import {
    PropDialogType,
    getDefaultSubLayer,
    getPropsType,
    layerOptions,
    surfaceOptions,
} from "./edit-layer-properties-utils";
import { models } from "~/recs-events";

const DEFINED_LEGEND_LABEL = "defined legend";
const EQUAL_INTERVAL_LABEL = "equal interval";
const EQUAL_RECORDS_LABEL = "equal records";

type Statistics = {
    stats?: { avg: number; max: number; min: number };
    titles?: { avg: string; max: string; min: string };
    unitName?: string;
    values?: number[];
    isUserDefined?: boolean;
};

interface IEditLayerPropertiesProps {
    isOpen: boolean;
    selectedLayer: ILayer;
    selectedSurface: ISubLayer;
    layerInfos: ILayer[];
    letterIcon: string;
    type: number;
    colorRamps: IColorRamp[];
    colorSchemeOptions: IColorInfo[];
    classificationMethods: IClassification[];
    numberOfClassesOptions: number[];
    showLayerSelect: boolean;
    intl: IFormatter;
    onChange(
        stats: {
            isSampling: boolean;
            layerTitle: string;
            showSampleSites: boolean;
            stats: Statistics;
            surfaceInfo: LayerAPI.ISurfaceInfo;
            surfaceTitle: string;
        } | null
    ): void;
    onSave: (evt: {
        layer: ILayer;
        surface: ISubLayer;
        attributeGuid: string;
        classificationMethodGuid: string;
        colorRampGuid: string;
        colorSchemeGuid: string;
        numberOfClasses: string;
        classBreaks: IClassBreak[];
        surfaceTypeGuid: string;
        surfaceGuid: string;
    }) => void;
    onClose: () => void;
}

const EditLayerProperties = (props: IEditLayerPropertiesProps): JSX.Element => {
    const {
        isOpen,
        letterIcon,
        selectedLayer,
        selectedSurface,
        showLayerSelect,
        layerInfos,
        type,
        classificationMethods,
        colorRamps,
        colorSchemeOptions,
        numberOfClassesOptions,
        onSave,
        onClose,
    } = props;
    const { formatMessage } = props.intl;

    //state variables
    const [layer, setLayer] = useState<ILayer>(selectedLayer);
    const [surface, setSurface] = useState<LayerAPI.ISubLayer>(selectedSurface);

    const [numberOfClasses, setNumberOfClasses] = useState<string>(
        selectedSurface ? selectedSurface.numberOfClasses : null
    );
    const [numClassesOptions, setNumClassesOptions] = useState<ISelectOption<number>[]>();
    const [colorRampGuid, setColorRampGuid] = useState<string>(
        selectedSurface ? selectedSurface.colorRampGuid : null
    );
    const [colorSchemeGuid, setColorSchemeGuid] = useState<string>(
        selectedSurface ? selectedSurface.colorSchemeGuid : null
    );
    const [colorRampSelectOptions, setColorRampSelectOptions] =
        useState<{ background: string; label: string; value: string }[]>();
    const [colorSchemeSelectOptions, setColorSchemeSelectOptions] =
        useState<{ background: string; label: string; value: string }[]>();
    const [classBreaks, setClassBreaks] = useState<IClassBreak[]>();
    const [backgroundImageStyle, setBackgroundImageStyle] = useState<string>();

    const [selectedClassificationMethod, setSelectedClassificationMethod] = useState<{
        label: string;
        value: string;
    }>();
    const [classificationMethodGuid, setClassificationMethodGuid] = useState<string>(
        selectedSurface ? selectedSurface.classificationMethodGuid : null
    );
    const [classificationMethodOptions, setClassificationMethodOptions] =
        useState<{ label: string; value: string }[]>();
    const [hideClassificationMethod, setHideClassificationMethod] = useState<boolean>(false);
    const [disableClassControls, setDisableClassControls] = useState<boolean>(false);

    const [legendPreviewItems, setLegendPreviewItems] = useState<JSX.Element[]>();
    const [allowNoClassesDefinedLegend, setAllowNoClassesDefinedLegend] = useState<boolean>(false);
    const [isDefinedLegend, setIsDefinedLegend] = useState<boolean>(false);
    const [allowDefinedLegend, setAllowDefinedLegend] = useState<boolean>(false);
    const [isUsingColorScheme, setIsUsingColorScheme] = useState<boolean>(false);
    const [isManualLayer, setIsManualLayer] = useState<boolean>(false);

    //refs
    const dialogIsOpen = useRef<boolean>(false);
    const attributeGuid = useRef<string>(
        selectedLayer ? selectedLayer.selectedAttributeGuid : null
    );
    const layerIcon = useRef<string>(letterIcon);
    const layerType = useRef<number>(type);
    const surfaceTypeGuid = useRef<string>(
        selectedSurface ? selectedSurface.surfaceTypeGuid : null
    );

    // #region useEffects
    useEffect(() => {
        if (layerInfos && !layer) {
            const defaultLayer = layerInfos.find((layer) =>
                layer.subLayers.find(
                    (surface) =>
                        (surface.surfaceTypeDisplayName &&
                            !surface.surfaceTypeDisplayName.toLowerCase().includes("point") &&
                            !surface.surfaceTypeDisplayName.toLowerCase().includes("coverage")) ||
                        layerType.current === LayerUtilsAPI.LayerType.SOIL ||
                        layerType.current === LayerUtilsAPI.LayerType.ANALYSIS ||
                        layerType.current === LayerUtilsAPI.LayerType.MANAGEMENT_AREA
                )
            );
            setLayer(defaultLayer);
        }
    }, [layerInfos]);

    useEffect(() => {
        if (isOpen) {
            if (!dialogIsOpen.current) {
                setLayer(selectedLayer);
                setSurface(selectedSurface);
                dialogIsOpen.current = true;
            }
        } else {
            dialogIsOpen.current = false;
        }
    }, [isOpen]);

    useEffect(() => {
        if ((!colorRampGuid && !colorSchemeGuid) || !numberOfClasses) {
            return;
        }
        const colorRamp = colorRamps.find((cs) => cs.colorRampGuid === colorRampGuid);
        const colorScheme = colorSchemeOptions.find((cs) => cs.colorSchemeGuid === colorSchemeGuid);
        let layerFile =
            colorRamp &&
            colorRamp.layerFiles.find((f) => f.numberOfClasses === Number(numberOfClasses));

        if (layerFile == null) {
            layerFile = {
                layerFileGuid: "",
                numberOfClasses: Number(numberOfClasses),
                classificationMethodGuid,
                classBreaks: [],
            };
        }

        setColorRampSelectOptions(
            colorRamps.map((cr) => ({
                background: getBackgroundGradientStyle(cr.layerFiles.slice(-1)[0]),
                label: " ",
                value: cr.colorRampGuid,
            }))
        );
        setColorSchemeSelectOptions(
            colorSchemeOptions.map((cr) => ({
                background: getBackgroundGradientStyle(cr, true),
                label: " ",
                value: cr.colorSchemeGuid,
            }))
        );

        setClassBreaks(layerFile.classBreaks);
        setLegendPreviewItems(
            layerFile.classBreaks.map((cb) => (
                <div key={cb.classId} className="legend-preview-item">
                    <div
                        className="legend-icon"
                        style={{ backgroundColor: `#${cb.color.hexCode}` }}
                    />
                    <div className="legend-label">{cb.classId}</div>
                </div>
            ))
        );
        setBackgroundImageStyle(
            !isUsingColorScheme
                ? colorRamp && getBackgroundGradientStyle(colorRamp.layerFiles.slice(-1)[0])
                : colorScheme && getBackgroundGradientStyle(colorScheme, true)
        );
    }, [colorRampGuid, colorSchemeGuid, numberOfClasses, isUsingColorScheme]);

    useEffect(() => {
        if (!numberOfClassesOptions) {
            return;
        }
        setNumClassesOptions(
            numberOfClassesOptions.map(
                (noc) =>
                    ({
                        label: noc.toString(),
                        value: noc,
                    } as ISelectOption<number>)
            )
        );
    }, [numberOfClassesOptions]);

    useEffect(() => {
        if (!classificationMethods) {
            return;
        }
        const options =
            classificationMethods &&
            classificationMethods.map((cm) => ({
                label: cm.classificationMethod,
                value: cm.classificationMethodGuid,
            }));
        setClassificationMethodOptions(options);
        options &&
            setSelectedClassificationMethod(
                options.find((cm) => cm.value === classificationMethodGuid)
            );
    }, [classificationMethods]);

    useEffect(() => {
        if (!selectedClassificationMethod) {
            return;
        }
        setIsDefinedLegend(
            Boolean(
                classificationMethodGuid &&
                    selectedClassificationMethod &&
                    selectedClassificationMethod.label.toLowerCase() === DEFINED_LEGEND_LABEL
            )
        );
    }, [selectedClassificationMethod]);

    useEffect(() => {
        if (!layer) {
            return;
        }

        if (showLayerSelect) {
            const tempSurface = layer != null ? (getDefaultSubLayer(layer) as ISubLayer) : null;
            setSurface(tempSurface);
        }
        layerType.current = LayerUtilsAPI.getLayerType(layer);
        attributeGuid.current = layer.selectedAttributeGuid;
        layerIcon.current = getLayerTypeLetter(layerType.current, formatMessage);
        const propsType = getPropsType(layerType.current, layer);
        if (propsType === PropDialogType.Manual) {
            setIsManualLayer(true);
        } else {
            setIsManualLayer(false);
        }
    }, [layer]);

    useEffect(() => {
        if (!surface || !layer) {
            return;
        }
        const attributeGuidIsNotEditable =
            layerType.current === LayerUtilsAPI.LayerType.REC ||
            layerType.current === LayerUtilsAPI.LayerType.EVENT_IMPORTED ||
            layerType.current === LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC ||
            layerType.current === LayerUtilsAPI.LayerType.ANALYSIS ||
            (layer && layer.isSampling);

        const otherSurface = attributeGuidIsNotEditable
            ? surface
            : LayerUtilsAPI.getOtherSurface(
                  layer,
                  surface as ISubLayer,
                  layer.selectedAttributeGuid,
                  surface.surfaceTypeGuid
              );

        const useColorScheme =
            otherSurface == null ||
            otherSurface.layerFileGuid == null ||
            otherSurface.layerFileGuid.length === 0;

        const attributeName = LayerUtilsAPI.getAttributeGuidFieldName(layerType.current);

        const isAllSingle = isAllSingleValueClasses(otherSurface);
        const canReclassifyRec = canReclassifyRecSurface(otherSurface);

        let definedLegend = false;
        const classControls = (isAllSingle == null || isAllSingle) && !canReclassifyRec;
        if (layer.layerType === models.EVENT_TYPE_NAME_SAMPLING_TISSUE) {
            if (surface["allowDefinedLegend"]) {
                definedLegend = true;
            }
            if (classControls === true && surface["allowDefinedLegend"]) {
                definedLegend = true;
            }
        }

        attributeGuid.current = otherSurface[attributeName];
        surfaceTypeGuid.current = surface.surfaceTypeGuid;
        setDisableClassControls(classControls);
        setAllowDefinedLegend(definedLegend);
        setAllowNoClassesDefinedLegend(false);
        setHideClassificationMethod(isAllSingle && !definedLegend && !canReclassifyRec);
        setIsUsingColorScheme(useColorScheme);
        setColorRampGuid(surface.colorRampGuid);
        setColorSchemeGuid(surface.colorSchemeGuid);
        setNumberOfClasses(surface.numberOfClasses);
        setClassificationMethodGuid(
            surface.classificationMethodGuid || classificationMethods[0].classificationMethodGuid
        );
    }, [surface]);
    // #endregion

    // #region event handlers
    const selectLayerOption = (layer: LayerAPI.ILayer): void => {
        setLayer(layer);
    };

    const selectSurfaceOption = (surface: ISubLayer): void => {
        setSurface(surface);
    };

    const updateClassificationMethod = (classificationMethodGuid: string): void => {
        setClassificationMethodGuid(classificationMethodGuid);
    };

    const updateColorRamp = (colorRampGuid: string): void => {
        isUsingColorScheme ? setColorSchemeGuid(colorRampGuid) : setColorRampGuid(colorRampGuid);
    };

    const updateNumClasses = (numberOfClasses: string): void => {
        setNumberOfClasses(numberOfClasses);
    };

    const onDialogSave = () => {
        onSave({
            layer,
            surface,
            attributeGuid: attributeGuid.current,
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            classBreaks,
            surfaceTypeGuid: surfaceTypeGuid.current,
            surfaceGuid: surface.surfaceGuid,
        });
    };
    // #endregion

    // #region view components
    const optionRenderer = (
        { option, isSelected, isHighlighted }: IOptionRendererProps<Record<string, any>>,
        formatMessage: (
            descriptor: MessageDescriptor,
            values?: Record<string, MessageFormatPrimitiveValue>
        ) => string
    ): JSX.Element => {
        const className = classnames("select-form-input-option", {
            selected: isSelected,
            "filter-match": isHighlighted,
        });
        return (
            <div key={option.type} className={className} title={option.label}>
                <span className="select-letter-icon">
                    {getLayerTypeLetter(option.type, formatMessage)}
                </span>
                <span>{option.label}</span>
            </div>
        );
    };
    // #endregion

    return (
        <DialogBox
            className="surface-properties-dialog"
            draggable
            footerType={DialogBoxFooterType.ACTION_CANCEL}
            forceOverflow
            isOpen={isOpen}
            letterIcon={layerIcon.current}
            onAction={onDialogSave}
            onClose={onClose}
            title={layer ? `${layer.displayName}` : ""}
            actionDisabled={Boolean(!layer || !surface)}
            unrestricted
        >
            {showLayerSelect && (
                <SelectInput
                    containerClassNames={["surface-display-layer-list"]}
                    placeholderText={formatMessage(messages.splitScreenLayerLbl)}
                    value={layer as Record<string, any>}
                    options={layerInfos && layerOptions(layerInfos)}
                    optionRenderer={(val) => optionRenderer(val, formatMessage)}
                    onChange={(l: Record<string, any>) => selectLayerOption(l as LayerAPI.ILayer)}
                />
            )}
            <SelectInput
                disabled={layer == null}
                placeholderText={formatMessage(messages.splitScreenSurfaceLbl)}
                value={surface}
                options={surfaceOptions(layer)}
                onChange={(s: Record<string, any>) => selectSurfaceOption(s as ISubLayer)}
            />
            {layerType.current === LayerUtilsAPI.LayerType.ANALYSIS ? null : (
                <div className="color-ramp-section">
                    <div className="inputs-section">
                        <SelectInput
                            inputCSS={{ backgroundImage: backgroundImageStyle }}
                            clearable={false}
                            filterable={false}
                            onChange={(e) => updateColorRamp(e)}
                            optionIsHiddenKey={ACTIVE_YN}
                            options={
                                isUsingColorScheme
                                    ? colorSchemeSelectOptions
                                    : colorRampSelectOptions
                            }
                            optionRenderer={colorOptionRenderer}
                            placeholderText={
                                isUsingColorScheme
                                    ? formatMessage(messages.colorScheme)
                                    : formatMessage(messages.colorRamp)
                            }
                            value={isUsingColorScheme ? colorSchemeGuid : colorRampGuid}
                        />
                        {isDefinedLegend || isManualLayer ? null : (
                            <SelectInput
                                clearable={false}
                                filterable={false}
                                onChange={(e) => updateNumClasses(e)}
                                optionIsHiddenKey={ACTIVE_YN}
                                options={numClassesOptions}
                                placeholderText={formatMessage(messages.numberOfClasses)}
                                value={numberOfClasses}
                                disabled={disableClassControls}
                            />
                        )}
                        {hideClassificationMethod || isManualLayer ? null : (
                            <SelectInput
                                clearable={false}
                                filterable={false}
                                onChange={(e) => updateClassificationMethod(e)}
                                optionIsHiddenKey={ACTIVE_YN}
                                options={
                                    classificationMethodOptions
                                        ? allowNoClassesDefinedLegend
                                            ? classificationMethodOptions.filter(
                                                  (cm) =>
                                                      cm.label.toLowerCase() !==
                                                          EQUAL_RECORDS_LABEL &&
                                                      cm.label.toLowerCase() !==
                                                          EQUAL_INTERVAL_LABEL
                                              )
                                            : allowDefinedLegend
                                            ? classificationMethodOptions
                                            : classificationMethodOptions.filter(
                                                  (cm) =>
                                                      cm.label.toLowerCase() !==
                                                      DEFINED_LEGEND_LABEL
                                              )
                                        : []
                                }
                                placeholderText={formatMessage(messages.classificationMethods)}
                                value={classificationMethodGuid}
                            />
                        )}
                    </div>
                    <div className="legend-preview">
                        <div>{legendPreviewItems}</div>
                    </div>
                </div>
            )}
        </DialogBox>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
});

const mapStateToProps = (state, props: Partial<IEditLayerPropertiesProps>) => ({
    ...props,
    classificationMethods: selectors.getClassificationMethods(state),
    colorRamps: selectors.getColorRamps(state),
    colorSchemeOptions: selectors.getColorSchemeOptions(state),
    numberOfClassesOptions: selectors.getNumberOfClasses(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditLayerProperties));
