import React from "react";
import PropTypes from "prop-types";
import { messages } from "../../i18n-messages";
import { formatDate, mapToPicklistValue } from "~/admin/utils";
import moment from "moment";
import { adminData } from "~/admin/data";

// Components
import { DateInput, Section, SelectInput, SubSection, TextInput } from "~/core";

import * as constants from "./constants";

export const LicenseItem = ({
    licenseType,
    formatMessage,
    onChange,
    editData,
    getCountryGuid,
    getState,
    countriesList,
    onCountryChange,
    requestState,
}) => {
    return (
        <Section>
            <SubSection>
                <SelectInput
                    tabIndex={0}
                    autoFocus
                    openOnFocus={false}
                    options={licenseType}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    placeholderText={formatMessage(messages.licenseType)}
                    labelText={formatMessage(messages.licenseType)}
                    containerClassNames={["license-type"]}
                    onChange={(value) =>
                        onChange({
                            [constants.PROPS_LICENSE_TYPE_GUID]: value.guid,
                        })
                    }
                    value={mapToPicklistValue({
                        options: licenseType,
                        selectedGuid: editData[constants.PROPS_LICENSE_TYPE_GUID],
                    })}
                    clearable={false}
                />
                <TextInput
                    tabIndex={0}
                    maxLength={50}
                    placeholderText={formatMessage(messages.licenseNumber)}
                    labelText={formatMessage(messages.licenseNumber)}
                    containerClassNames={["license-number"]}
                    onChange={(value) => onChange({ [constants.PROPS_LICENSE_NUMBER]: value })}
                    value={editData[constants.PROPS_LICENSE_NUMBER]}
                />
            </SubSection>
            <SubSection>
                <SelectInput
                    tabIndex={0}
                    options={getState()}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    placeholderText={formatMessage(messages.stateAbbr)}
                    labelText={formatMessage(messages.stateAbbr)}
                    containerClassNames={["license-state"]}
                    onChange={(value) => onChange({ [constants.PROPS_STATE_GUID]: value.guid })}
                    value={mapToPicklistValue({
                        options: getState(),
                        selectedGuid: editData[constants.PROPS_STATE_GUID],
                    })}
                    clearable={false}
                />
                <SelectInput
                    tabIndex={0}
                    options={countriesList}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    placeholderText={formatMessage(messages.country)}
                    labelText={formatMessage(messages.country)}
                    containerClassNames={["license-country"]}
                    onChange={(value) => {
                        onCountryChange(value, requestState);
                        onChange({
                            [constants.PROPS_COUNTRY_GUID]: value.guid,
                            [constants.PROPS_STATE_GUID]: "",
                        });
                    }}
                    value={mapToPicklistValue({
                        options: countriesList,
                        selectedGuid:
                            editData[constants.PROPS_COUNTRY_GUID] ||
                            getCountryGuid({ options: countriesList }),
                    })}
                />
                <DateInput
                    placeholderText={formatMessage(messages.expirationDate)}
                    containerClassNames={["license-expiration-date"]}
                    onChange={(value) =>
                        onChange({
                            [constants.PROPS_EXPIRATION_DATE]: formatDate(value),
                        })
                    }
                    value={
                        editData[constants.PROPS_EXPIRATION_DATE]
                            ? moment(editData[constants.PROPS_EXPIRATION_DATE])
                            : null
                    }
                    defaultValue={null}
                />
            </SubSection>
        </Section>
    );
};

LicenseItem.propTypes = {
    licenseType: PropTypes.array,
    formatMessage: PropTypes.func,
    onChange: PropTypes.func,
    editData: PropTypes.object,
    getCountryGuid: PropTypes.func,
    getState: PropTypes.func,
    countriesList: PropTypes.array,
    onCountryChange: PropTypes.func,
    requestState: PropTypes.func,
};
