import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const MonitorFileIcon = withSvgIcon(
    <g>
        <path
            d="M443.4,41.6H56.6c-19.3,0-35.1,17.5-35.1,38.8v320.4c0,21.3,15.8,38.8,35.1,38.8h386.9c19.3,0,35.1-17.5,35.1-38.8V80.4C478.5,59.1,462.7,41.6,443.4,41.6z          M439.2,81.8v82.6H60.8V81.8H439.2z M260.6,253.3c-1.1-2.5-2.9-4.6-5.2-5.7v-73.2h47.9l18.2,78.9H260.6z M246.4,247.6c-2.3,1.2-4.1,3.2-5.2,5.7H126.5l-29.9,
            57.9H60.8V174.4h185.6V247.6z M131.7,263.4h109.5c1.1,2.5,2.9,4.6,5.2,5.7v57.5H192l-13.9-15.4h-71L131.7,263.4z M255.4,269.1c2.3-1.2,4.1-3.2,5.2-5.7h72.6l-20.6-89h126.5v137.1H323.4l-13.6,15.1h-54.4V269.1z M60.8,399.5v-14h378.4v14H60.8z"
        />
    </g>,
    "0 0 500 500"
);
