import { connect } from "react-redux";
import { model } from "./../data";
import { getDropdown } from "~/admin/selectors";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import SeedInformation from "./seed-info";

const mapStateToProps = (state) => ({
    [model.PROPS_CROP_NAME]: getDropdown(model.PROPS_CROP_NAME, state),
    [model.VARIETY_TYPE]: getDropdown(model.VARIETY_TYPE, state),
    [model.PROPS_BRAND_ORGANIZATION_NAME]: getDropdown(model.PROPS_BRAND_ORGANIZATION_NAME, state),
});
const mapDispatchToProps = () => ({
    fetchDropdownData,
});

export default connect(mapStateToProps, mapDispatchToProps)(SeedInformation);
