import React from "react";
import classNames from "classnames";

import { GeneralIcon } from "~/core/icons";

import "./menu.css";

export class Menu extends React.Component {
    render() {
        const generalText = "General";

        return (
            <div className={classNames("information-menu", "non-field-feature-information-menu")}>
                <div
                    className={classNames("information-menu-tab", "general-tab", "active-tab")}
                    title={generalText}
                >
                    <GeneralIcon className="side-menu-icon" />
                    <div className="information-menu-tab-text">{generalText}</div>
                </div>
            </div>
        );
    }
}
