import React, { Component } from "react";
import { TextInput, DialogBox, DialogBoxFooterType, NoLink, ZeroToInfiniteGrid } from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";

export interface AliasListProps {
    record?: Record<string, any>;
    formatMessage?: (message: string) => string;
    renderDeleteModal?: () => void;
    toggleModal?: (message: string, toggle: boolean) => void;
    onChange?: (obj: Record<string, any>) => void;
    onAdd?: () => void;
    onEdit?: (obj: Record<string, any>) => void;
    onDelete?: (obj: Record<string, any>) => void;
    onAddOrEditItem?: () => void;
    deleteItem?: () => void;
    isEditing?: boolean;
    isDeleting?: boolean;
    editData?: Record<string, any>;
    aliasTextLabel?: string;
    aliasName?: string;
}

export interface AliasListState {
    editingItem: boolean;
}

class AliasList extends Component<AliasListProps, AliasListState> {
    static PROPS_ALIAS_NAME;
    textLabel: string;

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
        };

        this.textLabel = props.aliasTextLabel || "aliasName";
        AliasList.PROPS_ALIAS_NAME = props.aliasName || "aliasName";
    }

    onEdit = (record, index) => {
        const keysToUpdate = [AliasList.PROPS_ALIAS_NAME];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };
    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };
    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={!editData[AliasList.PROPS_ALIAS_NAME]}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.alias
                )}
            >
                <TextInput
                    tabIndex={0}
                    maxLength={50}
                    required
                    autoFocus
                    placeholderText={formatMessage(messages[this.textLabel])}
                    value={formData[AliasList.PROPS_ALIAS_NAME] || ""}
                    onChange={(value) => onChange({ [AliasList.PROPS_ALIAS_NAME]: value })}
                />
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [AliasList.PROPS_ALIAS_NAME]: {
                                title: formatMessage(messages.aliasHeader),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.alias)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(AliasList);
