import { useMemo } from "react";
import { UserAPI } from "@ai360/core";
import { useAppSelector } from "~/store/hooks";
import { getLoginGuid } from "~/login/selectors";

const convertBoolean = (value) => {
    if (value === "True") return true;
    if (value === "False") return false;
    return value;
};

const useReportPreferences = (reportName, preferences) => {
    const loginGuid = useAppSelector(getLoginGuid);

    const preferenceNames = useMemo(
        () => preferences.map((pref) => `${reportName}_${pref}`),
        [preferences, reportName]
    );

    const { data, error, isLoading } = UserAPI.useGetLoginPreferencesQuery(
        {
            loginGuid,
            names: preferenceNames,
        },
        {
            skip: !loginGuid,
        }
    );

    const reportPreferences = useMemo(() => {
        return data?.reduce((acc, p) => {
            const key = p.id.name.replace(`${reportName}_`, "");
            acc[key] = convertBoolean(p.value);
            return acc;
        }, {});
    }, [data, reportName]);

    return { reportPreferences, error, isLoading };
};

export default useReportPreferences;
