import { call, put, select } from "redux-saga/effects";
import fileSaver from "file-saver";
import { getModelNameToState } from "./../utils";
import { defaultActions } from "./../actions";
import { Request, APIError } from "@ai360/core";
import { getTheUserGuid } from "~/login";
import { setApiResult } from "~/core/api/actions";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import messages from "../i18n-messages";

//Exports
const exportIsValid = (userGuid, selectedList) => {
    if (userGuid && selectedList.length > 0) {
        return true;
    } else {
        return false;
    }
};

export function* exportPackageRequest(action, service) {
    yield exportRequestNew(action, service, service.urls.EXPORT_PACKAGE_URL);
}

export function* exportPlantBackRestrictionRequest(action, service) {
    yield exportRequestNew(action, service, service.urls.EXPORT_PLANT_BACK_RESTRICTION_URL);
}

export function* exportRequest(action, service) {
    yield exportRequestNew(action, service, service.urls.EXPORT_URL);
}
export function* exportRequestNew(action, service, url) {
    const { useNewApi } = service;

    const UserGuid = yield select(getTheUserGuid);
    if (!exportIsValid(UserGuid, action.payload)) {
        yield put(defaultActions.failed());
    } else {
        try {
            let requestOptions = null;
            let response = null;
            if (!useNewApi) {
                requestOptions = { UserGuid: UserGuid, Model: action.payload };
                response = yield call(Request.post, url, requestOptions);
            } else {
                requestOptions = {
                    ids: [...action.payload],
                };
                response = yield call(Request.post, url, requestOptions);
            }

            const modelName = getModelNameToState(service.modelName);
            if (
                (!useNewApi && response.exportFileUrl && response.exportFileName) ||
                (useNewApi && response.url)
            ) {
                const resp = yield call(fetch, !useNewApi ? response.exportFileUrl : response.url);
                if (resp.status === 200) {
                    resp.blob().then((blob) =>
                        fileSaver.saveAs(
                            blob,
                            !useNewApi
                                ? response.exportFileName
                                : response.key.substring(response.key.lastIndexOf("/") + 1)
                        )
                    );
                    yield put(defaultActions.success({ model: modelName, data: {} }));
                } else {
                    yield put(
                        notificationActions.pushToasterMessage(
                            messages.exportToFileFailed,
                            MSGTYPE.ERROR
                        )
                    );
                    yield put(defaultActions.failed());
                }
            }
        } catch (error) {
            yield put(defaultActions.failed());
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
        }
    }
}
