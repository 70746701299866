import { ForkEffect, GenericAllEffect, all, put, select, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import * as actions from "./actions";
import * as selectors from "./selectors";

const storageChanged = function* () {
    const channel = eventChannel((emit) => {
        const listener = (event: StorageEvent) => {
            emit(event);
        };

        const event = "storage";
        window.addEventListener(event, listener);
        return () => {
            window.removeEventListener(event, listener);
        };
    });

    yield takeEvery(channel, function* (event: StorageEvent) {
        const name = event.key;
        const listeningNames = yield select(selectors.getListeningNames);
        if (listeningNames.includes(name)) {
            const value = JSON.parse(event.newValue);
            yield put(actions.received(name, value));
        }
    });
};

export const authenticationSaga = function* (): Generator<
    GenericAllEffect<Generator<ForkEffect, void, unknown>>,
    void,
    unknown
> {
    yield all([storageChanged()]);
};
