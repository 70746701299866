import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { actions as fileImportActions, selectors as fileImportSelectors } from "~/file-import";
import { getTheUserPersonalityId, getTheUserLockCustomer } from "~/login/selectors";

import * as selectors from "../../../selectors";
import * as actions from "../../../actions";
import * as panelActions from "../../../../../actions";
import { FieldBoundaryPanel_ } from "../components/field-boundary-panel";

const mapStateToProps = (state) => ({
    agvanceFieldList: selectors.getAgvanceFieldList(state),
    agvanceOrgLevelList: selectors.getAgvanceOrgLevelList(state),
    getImportFileInfo: (importFileGuid) =>
        fileImportSelectors.getImportFileInfo(state, importFileGuid),
    ignoreFarm: selectors.getIgnoreFarm(state),
    importFieldBoundaries: selectors.getImportFieldBoundaries(state),
    importFieldList: selectors.getImportFieldList(state),
    isLoading: selectors.getIsLoading(state),
    isLockCustomer: getTheUserLockCustomer(state),
    personalityId: getTheUserPersonalityId(state),
    selectedImportFieldIndex: selectors.getSelectedImportFieldIndex(state),
});
const mapDispatchToProps = (dispatch) => ({
    closeImportWizard: () => dispatch(panelActions.closeImportWizard()),
    fetchAgvanceFieldList: (customerGuid) => dispatch(actions.fetchAgvanceFieldList(customerGuid)),
    fetchAgvanceOrgLevelList: (customerGuid) =>
        dispatch(actions.fetchAgvanceOrgLevelList(customerGuid)),
    setIgnoreFarm: (guids, val) => dispatch(actions.setIgnoreFarm(guids, val)),
    setImportFieldList: (d) => dispatch(actions.setImportFieldList(d)),
    setSelectedImportFieldIndex: (guid) => dispatch(actions.setSelectedImportFieldIndex(guid)),
    updateImportBoundary: (r, c) => dispatch(actions.updateImportBoundary(r, c)),
    updateImportFileInfos: (ifo) => dispatch(fileImportActions.updateImportFileInfos(ifo)),
});

export const FieldBoundaryPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FieldBoundaryPanel_));
