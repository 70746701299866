export interface IStringData {
    length: () => number;
    splice: (n: number) => string;
}

export const getGridColClassName = (gridColSize: number): string => {
    switch (gridColSize) {
        case 5:
            return "grid-col-5";
        case 7:
            return "grid-col-7";
        case 10:
            return "grid-col-10";
        case 13:
            return "grid-col-13";
        case 15:
            return "grid-col-15";
        case 16:
            return "grid-col-16";
        case 18:
            return "grid-col-18";
        case 20:
            return "grid-col-20";
        case 25:
            return "grid-col-25";
        case 30:
            return "grid-col-30";
        case 40:
            return "grid-col-40";
        default:
            return "grid-expand";
    }
};

// TODO: create a data file for each module configuration  <---- still needed?
const numOfRecords = [
    { value: "50", label: "50", selected: false },
    { value: "75", label: "75", selected: false },
    { value: "100", label: "100", selected: true },
];

export const getNumOfRecords = (): {
    value: string;
    label: string;
    selected: boolean;
}[] => {
    return numOfRecords;
};

export const parseDelimiter = (stringVal: IStringData | string): void => {
    const length = (stringVal as IStringData).length();
    if (stringVal[0] === ";") {
        stringVal = (stringVal as IStringData).splice(1);
    }
    if (stringVal[length - 1] === ";") {
        stringVal = (stringVal as IStringData).splice(length);
    }
};
