import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import { fetchTelematicsCounts, fetchIsTelematicsUser } from "~/file-import/actions";
import { onsiteEditMode } from "./model";
import { AccountAPI, APIError, ClimateAPI, LocalStorageHelpers } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid } from "~/login/selectors";
import { setApiResult } from "~/core/api/actions";
import * as authenticationActions from "~/authentication/actions";
import * as windowActions from "~/window/actions";
import { authState } from "./auth-state";
import * as johnDeere from "./john-deere";
import { windows } from "./windows";
import { CLIMATE, JOHN_DEERE } from "./model";
const NO_DATA = "No Data";
const REGISTERED_STATUSES = ["Registered", "Registration Exists."];

export function* fetchAccount(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield AccountAPI.getUserAccount({ UserGuid });
        if (response) {
            yield put(actions.fetchAccountSuccess(response));
        } else {
            yield put(actions.fetchAccountFailure(NO_DATA));
        }
    } catch (error) {
        yield put(actions.fetchAccountFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchAccount() {
    yield takeEvery(actions.fetchAccount, fetchAccount);
}

export function* fetchConnectionTypeList(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield AccountAPI.getConnectionTypeList({ UserGuid });
        if (response) {
            yield put(actions.fetchConnectionTypeListSuccess(response));
        } else {
            yield put(actions.fetchConnectionTypeListFailure(NO_DATA));
        }
    } catch (error) {
        yield put(actions.fetchConnectionTypeListFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export const watchFetchConnectionTypeList = function* () {
    yield takeEvery(actions.fetchConnectionTypeList, fetchConnectionTypeList);
};

export function* fetchUrlForRegistration(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const connName = action.payload["onsiteConnectionName"];
        let registrationUrl;
        switch (connName) {
            case JOHN_DEERE:
                registrationUrl = yield johnDeere.fetchRegistrationUrl(
                    UserGuid,
                    authState.johnDeereApi.accessToken
                );
                break;
            case CLIMATE:
                registrationUrl = yield ClimateAPI.fetchRegistrationUrl(UserGuid);
                break;
            default:
                registrationUrl = yield AccountAPI.getUrlForRegistration({
                    UserGuid,
                    Model: action.payload,
                });
        }

        if (registrationUrl) {
            if (
                connName === JOHN_DEERE &&
                !action.payload.isEdit &&
                !REGISTERED_STATUSES.includes(registrationUrl)
            ) {
                yield put(windowActions.open(windows.johnDeereApiAuth, registrationUrl));
                yield put(authenticationActions.listen(authState.johnDeereApi.accessToken));
            }

            yield put(actions.fetchUrlForRegistrationSuccess(registrationUrl));
        } else {
            yield put(actions.fetchUrlForRegistrationFailure(NO_DATA));
        }
    } catch (error) {
        yield put(actions.fetchUrlForRegistrationFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchUrlForRegistration() {
    yield takeEvery(actions.fetchUrlForRegistration, fetchUrlForRegistration);
}

export function* getOnSiteEquipmentList(action) {
    const UserGuid = yield select(getTheUserGuid);
    const { payload } = action;
    try {
        const response = yield AccountAPI.getOnSiteEquipmentList({
            UserGuid,
            Model: payload,
        });
        if (response) {
            yield put(actions.getOnSiteEquipmentListSuccess(response));
        } else {
            yield put(actions.getOnSiteEquipmentListFailure(NO_DATA));
        }
    } catch (error) {
        yield put(actions.getOnSiteEquipmentListFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchGetOnSiteEquipmentList() {
    yield takeEvery(actions.getOnSiteEquipmentList, getOnSiteEquipmentList);
}

export function* saveAccount(action) {
    const { onsiteConnection, editMode } = action.payload;

    if (onsiteConnection) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: onsiteConnection };
        try {
            switch (editMode) {
                case onsiteEditMode.ADD:
                    yield call(AccountAPI.addUserOnsiteConnection, requestOptions);
                    break;
                case onsiteEditMode.DELETE:
                    yield call(AccountAPI.deleteUserOnsiteConnection, requestOptions);
                    break;
                case onsiteEditMode.UPDATE:
                    yield call(AccountAPI.updateUserOnsiteConnection, requestOptions);
                    break;
                default:
                    break;
            }
            yield put(actions.saveAccountSuccess());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.saveAccountFailure({ error }));
        }
    } else {
        yield put(actions.saveAccountFailure());
    }
}

export function* watchSaveAccount() {
    yield takeEvery(actions.saveAccount, saveAccount);
}

export function* onSaveAccountSuccess() {
    yield put(fetchIsTelematicsUser());
    yield put(fetchTelematicsCounts());
}

export function* watchSaveAccountSuccess() {
    yield takeEvery(actions.saveAccountSuccess, onSaveAccountSuccess);
}

export function* onAuthenticationReceived(action) {
    const { name, value } = action;
    if (value == null) {
        return;
    }

    if (name === authState.johnDeereApi.accessToken) {
        yield johnDeere.receivedRefreshToken(value);

        const selectOrganizationsUrl = yield johnDeere.fetchSelectOrganizationsUrl(
            authState.johnDeereApi.selectOrganizations
        );
        yield put(windowActions.redirect(windows.johnDeereApiAuth, selectOrganizationsUrl));
        yield put(authenticationActions.listen(authState.johnDeereApi.selectOrganizations));
    } else if (name === authState.johnDeereApi.selectOrganizations) {
        yield put(windowActions.close(windows.johnDeereApiAuth));
    }

    LocalStorageHelpers.remove(name);
}

export function* watchAuthenticationReceived() {
    yield takeEvery(authenticationActions.received, onAuthenticationReceived);
}

const AccountSagas = function* () {
    yield all([
        fork(watchFetchAccount),
        fork(watchFetchConnectionTypeList),
        fork(watchFetchUrlForRegistration),
        fork(watchGetOnSiteEquipmentList),
        fork(watchSaveAccount),
        fork(watchSaveAccountSuccess),
        fork(watchAuthenticationReceived),
    ]);
};

export default AccountSagas;
