import { createSelector } from "reselect";

import { getModuleState as getRecModuleState } from "../../selectors";

export const REC_LIST_STATE_KEY = "REC_LIST_STATE";
export const ACCORDION_KEY = "ACCORDION";
export const DISABLE_EXPORT_STATUSES = [
    "Rec Failed",
    "Error Processing Surfaces",
    "Processing Rec",
];
export const DISALLOW_REC_CONVERT_COPY_STATUSES = [
    "Rec Failed",
    "Error Processing Surfaces",
    "Saving",
];

export const getAccordionState = (state) => getModuleState(state)[ACCORDION_KEY];

export const getModuleState = (state) => getRecModuleState(state)[REC_LIST_STATE_KEY];

export const getAccordionItems = (state) => getAccordionState(state).items;

export const getFieldGuidToDimIdxMap = createSelector(getAccordionItems, (itemList) => {
    const fieldGuidToDimIdxMap = new Map();
    itemList.forEach((fieldItem, i) => {
        fieldGuidToDimIdxMap.set(fieldItem.payload.fieldGuid, [i]);
    });
    return fieldGuidToDimIdxMap;
});

export const getAccordionId = (state) => getAccordionState(state).accordionId;

export const getErrorToShow = (state) => getModuleState(state).errorDetailsToShow;
export const getConvertRecsToEventsGuidMap = (state) =>
    getModuleState(state).convertRecsToEventsGuidMap;

export const getExpandedFieldGuidSet = (state) => getModuleState(state).expandedFieldGuidSet;

export const getExportRecGeneralGuiSet = (state) => getModuleState(state).exportRecGeneralGuidSet;

export const getRecFilter = (state) => getModuleState(state).filter;

export const getRecPanelLoading = (state) => getModuleState(state).isPanelLoading;

export const getIsRecLoading = (state) => getModuleState(state).isRecLoading;

export const getSelectedRecGuidSet = (state) => getModuleState(state).selectedRecGuidSet;

export const getShowFilters = (state) => getModuleState(state).showFilters;

export const getSearchValue = (state) => getModuleState(state).searchValue;

export const getSelectedRecFieldGuidSet = (state) => {
    const fieldGuidToSelectedRecGuidSetMap = getModuleState(state).fieldGuidToSelectedRecGuidSetMap;
    const selectedRecFieldGuidSet = new Set();
    fieldGuidToSelectedRecGuidSetMap.forEach((recGuidSet, fieldGuid) => {
        if (recGuidSet.size > 0) {
            selectedRecFieldGuidSet.add(fieldGuid);
        }
    });
    return selectedRecFieldGuidSet;
};
