import { put } from "redux-saga/effects";
import { actions as notificationActions } from "~/notifications";

export const displayNotification = function* (call, action, intlMessageObj, intlMessageValues) {
    try {
        yield call();
    } catch (error) {
        yield put(
            notificationActions.apiCallError(error, action, intlMessageObj, intlMessageValues)
        );
    }
};
