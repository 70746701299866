import React, { useEffect, useRef, useState } from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";

import { Loader, SelectInput } from "~/core";
import { selectors as fileImportSelectors, actions as fileImportActions } from "~/file-import";
import { IImportType } from "~/file-import/model";
import { logFirebaseEvent } from "~/utils/firebase";

import { ModuleFiltersToggle, ModuleFilters } from "../../../module-filters";
import { ModuleSearch } from "../../../module-search";
import * as selectors from "../../selectors";
import * as actions from "../../actions";

import { ImportFilterTabs } from "./import-filter-tabs/import-filter-tabs";
import { ImportAccordion } from "./import-accordion/import-accordion";
import { ACTIVE_YN } from "~/core/picklist";
import "./import-list.css";

const messages = defineMessages({
    fileTypeInputPlaceholder: {
        id: "importModule.fileTypeInputPlaceholder",
        defaultMessage: "File Type",
    },
});

interface ImportTypeOption {
    label: string;
    value: string;
}

class IImportListProps {
    importTypeFilterGuid: string;
    importTypeOptions: ImportTypeOption[];
    isLoading: boolean;
    setTypeFilter: (guid: string) => void;
    fetchImportFileTypes: () => void;
    onToggleFilters: (v: string) => void;
    stateSearchValue: string | null;
    showFilters: boolean;
    intl: intlShape;
    updateSearchValue: (v: string) => void;
}

const ImportList = (props: IImportListProps): JSX.Element => {
    const {
        fetchImportFileTypes,
        importTypeFilterGuid,
        importTypeOptions,
        isLoading,
        onToggleFilters,
        setTypeFilter,
        showFilters,
        stateSearchValue,
        updateSearchValue,
    } = props;
    const { formatMessage } = props.intl;
    const firstRender = useRef(true);

    //state variables
    const [typedSearchValue, setTypedSearchValue] = useState<string>("");

    useEffect(() => {
        const fetchTypes = () => {
            if (importTypeOptions) {
                if (importTypeOptions.length === 1) {
                    fetchImportFileTypes();
                }
            }
        };
        fetchTypes();
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            if (stateSearchValue && !typedSearchValue) {
                setTypedSearchValue(stateSearchValue);
            }
            firstRender.current = false;
            return;
        }

        const delayedSearch = setTimeout(() => {
            if (typedSearchValue) {
                logFirebaseEvent("search_import_files");
            }
            updateSearchValue(typedSearchValue);
        }, 1000);

        return () => clearTimeout(delayedSearch);
    }, [typedSearchValue]);

    const accordion = (
        <div
            className={classNames("pane-content", {
                "pane-content-loader": isLoading,
            })}
        >
            {isLoading ? <Loader /> : <ImportAccordion />}
        </div>
    );

    const placeHolder = formatMessage(messages.fileTypeInputPlaceholder);
    const filterInputEls = [
        <SelectInput
            key={0}
            optionIsHiddenKey={ACTIVE_YN}
            required
            clearable={false}
            options={importTypeOptions}
            placeholderText={placeHolder}
            onChange={(guid) => setTypeFilter(guid)}
            value={importTypeFilterGuid}
        />,
    ];

    return (
        <div className={classNames("import-list")}>
            <div className="module-filter-row">
                <ModuleSearch
                    isLoading={isLoading}
                    onSearchChange={(newValue) => setTypedSearchValue(newValue)}
                    searchValue={typedSearchValue}
                />
                <ModuleFiltersToggle
                    filterVisible={showFilters}
                    onToggleFilters={(newValue) => onToggleFilters(newValue)}
                />
            </div>
            {!showFilters ? null : (
                <div className="module-filter-row module-filters">
                    <ModuleFilters>{filterInputEls}</ModuleFilters>
                </div>
            )}

            <ImportFilterTabs>{accordion}</ImportFilterTabs>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { importTypeFilterGuid, isLoading } = selectors.getModuleState(state);

    const importTypes: IImportType[] = Array.from(
        fileImportSelectors.getImportTypes(state).values()
    );
    importTypes.splice(0, 0, { name: "All", guid: "", isExpanded: false });
    const importTypeOptions = importTypes.map((importType) => {
        return {
            value: importType.guid,
            label: importType.name,
        };
    });

    return {
        importTypeFilterGuid: importTypeFilterGuid || "",
        importTypeOptions,
        isLoading: isLoading || fileImportSelectors.isFetchingTypes(state),
        showFilters: selectors.getShowFilters(state),
        stateSearchValue: fileImportSelectors.getSearchValue(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTypeFilter: (importTypeGuid) => dispatch(actions.setTypeFilter(importTypeGuid || null)),
        fetchImportFileTypes: () => dispatch(fileImportActions.fetchImportFileTypes()),
        onToggleFilters: (showFilters) => dispatch(actions.setShowFilters(showFilters)),
        updateSearchValue: (searchValue) =>
            dispatch(fileImportActions.updateSearchValue(searchValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImportList));
