import { ADMIN_STATE_KEY } from "~/admin";
import { ACCOUNT_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[ADMIN_STATE_KEY][ACCOUNT_DATA_KEY];

export const getConnectionTypeList = (state) => _getModuleState(state).connectionTypeList;
export const getOnsiteEquipmentList = (state) => _getModuleState(state).onsiteEquipmentList;
export const getOnsiteUserKey = (state) => _getModuleState(state).onsiteUserKey;
export const getUrlForRegistration = (state) => _getModuleState(state).registrationUrl;
export const getUserAccount = (state) => _getModuleState(state).userAccount;
