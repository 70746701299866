import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const IrrigationIcon = withSvgIcon(
    <g>
        <path
            d={
                "M35.4,36.2c1.6-0.2,3.1-0.9,4.3-1.9l-0.8-3.8l-2.4-0.4c0,0-1.3-7.4-7.3-8.7s-6.7-1.3-6.9-2.5" +
                "c-0.1-0.6,0-1.3,0.4-1.8h0.8v-4L20.9,12h-2V5.5c0.8-0.1,1.7-0.3,2.4-0.7c2.8,0.4,6.7,1,7.9,1c1.8,0,1.6-4,0-4.2c-0.2,0-0.5,0-0.7,0" +
                "c-1.6,0-4.8,0.3-7.5,0.6c-0.4-0.1-0.8-0.3-1-0.4c0,0,0-0.1,0-0.1c0-0.9-1.3-1.7-2.9-1.7c-1.6,0-2.8,0.7-2.9,1.7" +
                "c-0.5,0.2-1,0.4-1.4,0.5c-2.7-0.3-6.1-0.6-7.8-0.6c-0.2,0-0.5,0-0.7,0c-1.6,0.3-1.8,4.2,0,4.2c1.2,0,5.4-0.7,8.2-1.1" +
                "c0.8,0.3,1.7,0.5,2.6,0.6V12h-1.8l-2.5,1.1v4h0.4c0.5,0.5,0.8,1.1,0.9,1.8c-0.1,1-2,2.7-5.2,2.8H6.8c-3,0-2-2-4.4-2s-2.4,1-2.4,1" +
                "v11.1c0,0,0.2,0.8,2.5,0.8s2.3-1.4,2.3-1.4c0.7-0.6,1.7-1,2.6-1h0.1c1.7,0.1,10.6,1.3,10.6,1.3h0.3c4.2,0,5.3-1.2,7.1-1.2h0.3" +
                "c1.2,0.1,2.1,0.9,2.4,2.1l-1.7,1l0.8,3.1c1.2,0.2,2.4,0.2,3.6,0.2c0.8,0,1.6,0,2.5-0.1c-0.2,0.4-0.4,0.8-0.6,1.1" +
                "c-0.8,1.3-1,3.6,1.7,3.6s2.4-2.4,1.7-3.6C35.8,37.2,35.6,36.7,35.4,36.2z M33.3,35.5c-0.8,0.1-1.6,0.1-2.4,0.1" +
                "c-1.2,0-2.2-0.1-2.8-0.1l-0.5-1.7l1-0.6l0.6-0.4l-0.2-0.7c-0.3-1.6-1.7-2.7-3.3-2.9h-0.3c-0.8,0-1.6,0.2-2.3,0.5" +
                "c-1.5,0.5-3.2,0.8-4.8,0.8h-0.2c-0.9-0.1-9-1.2-10.6-1.3H7.3c-1.2,0-2.4,0.4-3.3,1.2l-0.3,0.3v0.4c0,0-0.1,0.5-1.3,0.5" +
                "c-0.5,0-1-0.1-1.4-0.2V20.9c0.4-0.2,0.9-0.2,1.4-0.2c0.5-0.1,0.9,0.1,1.1,0.5c0.8,1,2,1.5,3.3,1.4h0.2c3.5-0.1,6.1-2,6.2-3.7" +
                "c0-1-0.5-2-1.2-2.6h0v-2.6l1.7-0.8h2.6V4.5l-0.9-0.1c-0.8-0.1-1.6-0.3-2.3-0.6l-0.3-0.1h-0.3C8.2,4.4,5.3,4.8,4.5,4.8l0,0" +
                "C4.2,4.4,4.1,3.9,4.3,3.4c0.1-0.5,0.3-0.8,0.4-0.8c0.2,0,0.4,0,0.5,0c1.3,0,3.9,0.2,7.7,0.6h0.2l0.2-0.1c0.5-0.2,1-0.4,1.4-0.6" +
                "l0.6-0.3V1.7c0-0.2,0.7-0.7,1.9-0.7c0.7-0.1,1.4,0.2,1.9,0.7l0,0L19,2.3l0.7,0.3C20,2.8,20.3,2.9,20.7,3l0.2,0.1h0.2" +
                "c3.6-0.3,6.2-0.5,7.4-0.5c0.2,0,0.4,0,0.5,0c0.1,0,0.3,0.3,0.4,0.8c0.1,0.5,0,1-0.2,1.4c-0.8,0-3.6-0.4-7.7-1l-0.3,0L20.9,4" +
                "c-0.6,0.3-1.3,0.5-2.1,0.5l-0.9,0.1V13h2.8l1.7,0.7v2.4h-0.3l-0.3,0.5c-0.5,0.8-0.7,1.7-0.5,2.6c0.4,1.8,1.3,2,6.6,3.1l1.1,0.2" +
                "c5.2,1.1,6.5,7.8,6.5,7.9l0.2,0.7l0.7,0.1l1.7,0.3l0.5,2.5c-1,0.7-2.2,1.1-3.4,1.3l-0.9,0.2L33.3,35.5z M35.5,39.9" +
                "c-0.2,0.3-0.7,0.4-1.1,0.4s-0.9-0.1-1.1-0.4c-0.2-0.6-0.1-1.2,0.3-1.7c0.2-0.4,0.5-0.8,0.7-1.2l0.2-0.3l0,0c0.2,0.5,0.5,1,0.8,1.5" +
                "C35.6,38.6,35.7,39.3,35.5,39.9L35.5,39.9z"
            }
        />
        <path
            d={
                "M40.9,41.3c-0.6-1.2-1.2-2.4-1.7-3.7c-0.4,1.3-1,2.5-1.7,3.7c-0.8,1.3-1,3.6,1.7,3.6S41.7,42.5,40.9,41.3z" +
                "M40.3,43.5c-0.2,0.3-0.7,0.4-1.1,0.4s-0.9-0.1-1.1-0.4c-0.2-0.6-0.1-1.2,0.3-1.7c0.3-0.5,0.6-1,0.8-1.5c0.2,0.5,0.5,1.1,0.8,1.5" +
                "C40.4,42.3,40.5,42.9,40.3,43.5z"
            }
        />
        <path
            d={
                "M29.5,37.6c-0.4,1.3-1,2.5-1.7,3.7c-0.8,1.3-1,3.6,1.7,3.6s2.4-2.4,1.7-3.6C30.5,40.1,30,38.9,29.5,37.6z" +
                "M30.6,43.5c-0.2,0.3-0.7,0.4-1.1,0.4s-0.9-0.1-1.1-0.4c-0.2-0.6-0.1-1.2,0.3-1.7c0.3-0.5,0.6-1,0.8-1.5c0.2,0.5,0.5,1.1,0.8,1.5" +
                "C30.7,42.3,30.8,42.9,30.6,43.5z"
            }
        />
        <path
            d={
                "M34.4,42.7c-0.5,1.3-1,2.5-1.7,3.7c-0.8,1.3-1,3.6,1.7,3.6s2.4-2.4,1.7-3.6C35.4,45.2,34.8,44,34.4,42.7z" +
                "M35.5,48.6c-0.2,0.3-0.7,0.4-1.1,0.4s-0.9-0.1-1.1-0.4c-0.2-0.6-0.1-1.2,0.3-1.7c0.3-0.5,0.6-1,0.8-1.5c0.2,0.5,0.5,1.1,0.8,1.5" +
                "C35.5,47.4,35.6,48,35.5,48.6L35.5,48.6z"
            }
        />
    </g>,
    "0 0 40 50",
    "event-label-icon"
);
