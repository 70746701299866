import * as model from "./model";
import { defaultValue } from "../../component/address/default-records";

const personDefaultRecord = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_ADDRESS_LIST]: null,
    [model.PROPS_ADD_EMAIL_VISIBLE]: false,
    [model.PROPS_ADD_USER_ACCOUNT_VISIBLE]: false,
    [model.PROPS_DATE_OF_BIRTH]: null,
    [model.PROPS_DELETE_USER]: false,
    [model.PROPS_EMAIL_LIST]: [],
    [model.PROPS_EMPLOYEE_GUID]: "",
    [model.PROPS_END_DATE]: "",
    [model.PROPS_ERROR_MESSAGE]: null,
    [model.PROPS_GENDER]: null,
    [model.PROPS_IS_ACTIVE]: true,
    [model.PROPS_IS_USER]: false,
    [model.PROPS_LICENSE_LIST]: [],
    [model.PROPS_LOGIN_EMAIL]: "",
    [model.PROPS_LOGIN_GUID]: "",
    [model.PROPS_MIDDLE_NAME]: null,
    [model.PROPS_NOTES]: "",
    [model.PROPS_PAYRATE]: null,
    [model.PROPS_PERSON_ADDRESS_LIST]: [defaultValue()],
    [model.PROPS_PERSON_ALIAS_GUID]: null,
    [model.PROPS_PERSON_AUTO_REPORT_LIST]: [],
    [model.PROPS_PERSON_CITY]: "",
    [model.PROPS_PERSON_EMAIL_GUID]: null,
    [model.PROPS_PERSON_FIRST_NAME]: "",
    [model.PROPS_PERSON_FULL_NAME]: null,
    [model.PROPS_PERSON_GUID]: null,
    [model.PROPS_PERSON_GUID]: null,
    [model.PROPS_PERSON_JOB_GUID]: "",
    [model.PROPS_PERSON_LAST_NAME]: "",
    [model.PROPS_PERSON_ORG_LIST]: [],
    [model.PROPS_PERSON_SALES_PERSON_LIST]: [],
    [model.PROPS_PERSON_URL_LIST]: [],
    [model.PROPS_PHONE_NUMBER_LIST]: [],
    [model.PROPS_ROLE_GUID]: null,
    [model.PROPS_START_DATE]: "",
    [model.PROPS_SUFFIX]: null,
    [model.PROPS_SUPERVISOR_GUID]: "",
    [model.PROPS_SUPERVISOR_NAME]: "",
    [model.PROPS_TIME_ZONE_GUID]: "",
    [model.PROPS_TITLE_GUID]: null,
    [model.PROPS_TYPE]: "",
    [model.PROPS_USER_ACTIVEYN]: true,
    [model.PROPS_USER_CUSTOMER_LIST]: [],
    [model.PROPS_USER_GUID]: "",
    [model.PROPS_USER_ROLES]: [],
    [model.PROPS_USER_TYPE_GUID]: "",
});

export default personDefaultRecord;
