import { connect } from "react-redux";
import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";

const mapDispatchToProps = (dispatch, ownProps) => ({
    onUpdateModel: (newProps) =>
        dispatch(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                ownProps.fieldGuid,
                ownProps.agEventTransactionTypeGuid,
                newProps
            )
        ),
    updatePlantingModel: (payload) => dispatch(recsEventsActions.updatePlantingEventModel(payload)),
});
const mapStateToProps = (state) => {
    const { saveEventDetailsErrorCodeList } = eventsSelectors.getModuleState(state);

    return {
        saveEventDetailsErrorCodeList,
    };
};

export const EventPlantingForm = (EventPlantingFormView) =>
    connect(mapStateToProps, mapDispatchToProps)(EventPlantingFormView);
