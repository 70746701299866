import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
export const MODEL_NAME = "equationSetsPlanting";

// Request payload
export const REQUEST_PAYLOAD_FILTER = "equationGroupFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "equationGroupSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "equationGroupPageOptions";
export const REQUEST_PAYLOAD_EQUATION_TYPE = "equationGroupType";

export const URL = apiUrl("AgBytes/GetEquationGroupList");
export const GETRECORD = apiUrl("AgBytes/GetEquationGroup");
export const NEXT_ID = apiUrl("AgBytes/GetNextEquationGroupId");
export const CREATE = apiUrl("AgBytes/AddEquationGroup");
export const UPDATE = apiUrl("AgBytes/UpdateEquationGroup");
export const DELETE = apiUrl("AgBytes/DeleteEquationGroupList");
export const EXPORT_URL = apiUrl("AgBytes/ExportEquationList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetEquationGroupPlantingAutoSearchList");
export const IMPORT_URL = apiUrl("AgBytes/EquationImportPlanting");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidEquationImportPlanting");
export const EXPORT_FILE_NAME = "EquationGroupExport";
export const SELECT_ALL = apiUrl("AgBytes/GetEquationGroupSelectAllList");

export const defaultEquationSet = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_DESCRIPTION]: "",
    [model.PROPS_EQUATION_GROUP_GUID]: "",
    [model.PROPS_GROUP_NAME]: "",
    [model.PROPS_SAMPLING_EVENT_REQUIRED]: false,
    [model.PROPS_VENDOR_GUID]: "",
    [model.PROPS_VENDOR_NAME]: "",
    [model.PROPS_EQUATION_GROUP_TYPE]: "Planting",
});

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        Description: "",
        GroupName: "",
        ModifiedDate: "",
        VendorName: "",
        canDelete: "",
        equationGroupGuid: "",
        isActive: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
    [REQUEST_PAYLOAD_EQUATION_TYPE]: "Planting",
};

export const defaultSort = {
    FieldName: "GroupName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const service = createService({
    id: model.PROPS_EQUATION_GROUP_ID,
    guid: model.PROPS_EQUATION_GROUP_GUID,
    name: model.PROPS_EQUATION_GROUP_NAME,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_EQUATION_GROUP_ID]: {
            label: "equationSetId",
            gridCol: 10,
            sortNameOverRide: "EquationSetId",
        },
        [model.PROPS_EQUATION_GROUP_NAME]: {
            label: "name",
            gridCol: 25,
            sortNameOverRide: "GroupName",
        },
        [model.PROPS_DESCRIPTION]: { label: "description", gridCol: 25 },
        [model.PROPS_VENDOR_NAME]: { label: "vendorName", gridCol: 10 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => ({ ...defaultEquationSet() }),
    defaultSort,
});
