import React, { Component, KeyboardEvent, MouseEvent, FocusEvent } from "react";
import classname from "classnames";
import "./checkbox.css";
import classnames from "classnames";

export interface ICheckBoxProps {
    autoFocus?: boolean;
    className?: string;
    defaultValue?: boolean;
    disabled?: boolean;
    label?: string;
    labelColor?: string;
    onBlur?: (event: FocusEvent) => void;
    onChange?: (event: MouseEvent, value?: boolean) => void;
    onFocus?: (event: FocusEvent) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    renderLabel?: () => JSX.Element;
    required?: boolean;
    reverseLabel?: boolean;
    someChecked?: boolean;
    suppressError: boolean;
    tabIndex?: number;
    title?: string;
    value?: boolean;
    visible?: boolean;
    containerClassNames?: Array<string>;
}

export interface ICheckBoxState {
    value: boolean;
    isDisabled: boolean;
    title: string;
    isVisible: boolean;
    isRequired: boolean;
    someChecked: boolean;
    hasFocus: boolean;
    titleVisible: boolean;
    suppressError: boolean;
}
export class Checkbox extends Component<ICheckBoxProps, ICheckBoxState> {
    static REQUIRED_TEXT = "*";
    /// Prop type validation, Default prop values ///

    static defaultProps = {
        value: false,
        tabIndex: 0,
        required: false,
        disabled: false,
        visible: true,
        suppressError: false,
        defaultValue: false,
        className: "",
        reverseLabel: false,
    };
    ///----------------------------///
    constructor(props: ICheckBoxProps) {
        super(props);
        this.state = {
            title: props.title,
            value: props.value,
            isVisible: props.visible,
            isRequired: props.required,
            isDisabled: props.disabled,
            hasFocus: props.autoFocus || false,
            titleVisible: true,
            someChecked: props.someChecked,
            suppressError: false,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: ICheckBoxProps): void {
        this.setState({
            title: nextProps.title,
            value: nextProps.value,
            isVisible: nextProps.visible,
            isRequired: nextProps.required,
            isDisabled: nextProps.disabled,
            someChecked: nextProps.someChecked,
        });
    }
    /// Event Handlers ///
    public handleOnFocus = (event: FocusEvent): void => {
        if (!this.state.isDisabled) {
            this.setState({ hasFocus: true });
            if (this.props.onBlur) {
                this.props.onBlur(event);
            }
        }
    };
    public handleOnBlur = (event: FocusEvent): void => {
        this.setState({ hasFocus: false });
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };
    public handleOnKeyDown = (event: KeyboardEvent): void => {
        if (event.key === " ") {
            //spacebar
            this.toggle();
        }
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };
    ///--------------------------///
    /// Helper functions ///
    public toggle = (event?: MouseEvent): void => {
        if (!this.props.disabled) {
            this.setState({ value: !this.state.value });
            // Since, the onChange method was called inside this.setState
            // it was blocking bubbling up the synthetic event chain
            if (this.props.onChange) {
                this.props.onChange(event, !this.state.value);
            }
        }
    };
    ///-----------------------------///
    render(): JSX.Element {
        /// Property set up, etc. ///
        const label = this.props.label;
        //TODO: tie this into a returned error list and check against the codes to which this input responds
        const showError = false; //this.props.errorCodes.length > 0 ? false : false;
        const tabIndex = this.state.isDisabled ? -1 : this.props.tabIndex;
        const checkMarkText = this.state.someChecked ? "\u25cf" : this.state.value ? "\u2713" : "";
        const title = this.props.title
            ? this.props.title
            : this.state.titleVisible
            ? this.props.label
            : "";
        const labelColor = this.props.labelColor;
        const { className } = this.props;
        /// Checkbox Widget classes / styles ///
        // Outer div
        const divStyle: React.CSSProperties = {
            visibility: this.state.isVisible ? "visible" : "hidden",
        };

        //Checkbox Container
        const checkboxContProps = {
            tabIndex: tabIndex,
            onClick: (event?: MouseEvent): void => this.toggle(event),
            onKeyDown: (event: KeyboardEvent): void => this.handleOnKeyDown(event),
            onFocus: this.handleOnFocus,
            onBlur: this.handleOnBlur,
        };

        const checkboxContClass = [
            "checkbox-container",
            this.state.hasFocus ? "focus" : "",
            showError && !this.state.suppressError ? "error-validation" : "",
        ];
        // Label
        const checkboxLabelClass = [
            "checkbox-label-text-span",
            this.state.isDisabled ? "disabled-checkbox" : "",
        ];
        // Required text
        const requiredClass = "red-star";
        const requiredStyle = {
            display: this.state.isRequired ? "inline" : "none",
        };
        // Check Mark
        const checkboxDisplayClass = [
            "checkbox-display",
            labelColor ? "check-mark-color" : "",
            this.state.isDisabled ? "disabled-checkbox" : "",
        ];
        const checkMarkStyle: React.CSSProperties = {
            visibility: this.state.isVisible && this.state.value ? "visible" : "hidden",
            color: labelColor ? labelColor : "inherit",
        };

        const labelTxt = this.props.renderLabel ? (
            this.props.renderLabel()
        ) : (
            <span className={classname(checkboxLabelClass)}>{label}</span>
        );

        const checkboxLabel = (
            <div
                key="checkbox-label"
                className="checkbox-label"
                onClick={() => !this.state.isDisabled && this.toggle()}
            >
                <span className={requiredClass} style={requiredStyle}>
                    {Checkbox.REQUIRED_TEXT}
                </span>
                {labelTxt}
            </div>
        );
        const checkboxBox = (
            <div key="checkbox-box" className={classname(checkboxContClass)} {...checkboxContProps}>
                <div className={classname(checkboxDisplayClass)} style={checkMarkStyle}>
                    {checkMarkText}
                </div>
            </div>
        );

        const checkboxElements = !this.props.reverseLabel
            ? [checkboxBox, checkboxLabel]
            : [checkboxLabel, checkboxBox];

        return (
            <div className={classnames("checkbox-div", className)} style={divStyle} title={title}>
                {checkboxElements}
            </div>
        );
    }
}
