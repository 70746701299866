import { dropdowns, pickLists, service } from "./service";
import { actions as defaultActions } from "./controller";

import * as model from "./model";
import { ownerSagas } from "./sagas";
import * as customActions from "./actions";

const actions = {
    ...defaultActions,
    ...customActions,
};

const addOwnerSaga = undefined;

export { actions, addOwnerSaga, dropdowns, model, ownerSagas, pickLists, service };
