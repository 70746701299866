import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { getConfirmMessages, getErrorMessages } from "~/i18n-error-messages";
import { setApiResult, resetApiResult } from "~/core/api/actions";
import { getApiError } from "~/core/api/selectors";
import { AppHelpers, APIHelpers, FormattingHelpers, APIError } from "@ai360/core";
import "./with-api-result.css";
import { messages } from "~/i18n-messages";
import { ErrorDialog } from "~/notifications/components/err-dialog";

const WithApiResult = (View, actionCallback = null) => {
    class WrapperComponent extends Component {
        static propTypes = {
            intl: intlShape.isRequired,
            apiError: PropTypes.object,
            setApiResult: PropTypes.func,
            resetApiResult: PropTypes.func,
            actionCallback: PropTypes.func,
            needs: PropTypes.func,
        };
        constructor(props) {
            super(props);
            this.state = {
                showDialogBox: false,
                showServerError: false,
                showErrorFileDownload: false,
                errorFile: "",
            };
        }

        resetApiResult = () => {
            this.props.setApiResult({
                errorCodeList: [],
                model: {},
                apiResultObj: {},
            });
        };

        /// Event Handlers ///
        onToggleModalClick = (newValue) => {
            this.setState({
                showDialogBox: newValue,
                showServerError: newValue,
                errors: null,
                showErrorFileDownload: !this.state.errorFile ? false : true,
            });
            this.resetApiResult();
        };
        onConfirmClick = () => {
            this.setState(
                {
                    showDialogBox: false,
                    errors: null,
                },
                () => {
                    if (actionCallback) {
                        this.props.needs([actionCallback()]);
                    }
                }
            );
        };
        downloadErrorFile = () => {
            const { base64Data, fileName } = this.state.errorFile;
            APIHelpers.downloadBlob(
                base64Data,
                `${fileName}${FormattingHelpers.getTimeStamp()}`,
                "txt"
            );
            this.onToggleErrorFileModal(false);
            this.setState({
                errorFile: null,
            });
            this.props.setApiResult({
                errorCodeList: [],
                model: {},
                errorFile: null,
            });
        };
        onToggleErrorFileModal = (newValue) => {
            this.setState({
                errorFile: null,
                showErrorFileDownload: newValue,
            });
            this.props.setApiResult({ errorFile: null });
        };

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.apiError.errorCodeList && nextProps.apiError.errorCodeList.length > 0) {
                const errors = getErrorMessages(
                    this.props.intl.formatMessage,
                    nextProps.apiError,
                    "<br/>"
                );
                this.setState({
                    showDialogBox: true,
                    errors: { __html: errors },
                });
            } else if (
                nextProps.apiError.model &&
                !AppHelpers.isEmptyObject(nextProps.apiError.model)
            ) {
                const { addCount, updateCount } = nextProps.apiError.model;
                const errors = getConfirmMessages(this.props.intl.formatMessage, "validImport", {
                    addCount,
                    updateCount,
                });
                this.setState({
                    showDialogBox: true,
                    errors: { __html: errors },
                });
            } else if (
                nextProps.apiError.apiResultObj &&
                nextProps.apiError.apiResultObj.status &&
                (nextProps.apiError.apiResultObj.exceptionMessage ||
                    nextProps.apiError.apiResultObj.message)
            ) {
                // Server errors other than with 200 status
                this.setState({
                    showServerError: true,
                    errors: nextProps.apiError.apiResultObj,
                });
            }

            if (
                nextProps.apiError.errorFile &&
                nextProps.apiError.errorFile !== this.props.apiError.errorFile
            ) {
                this.setState({
                    errorFile: nextProps.apiError.errorFile,
                });
            }
        }

        componentWillUnmount() {
            this.props.resetApiResult();
        }

        render() {
            const { formatMessage } = this.props.intl;
            const isConfirmModal =
                actionCallback &&
                this.props.apiError.model &&
                typeof this.props.apiError.model === "object" &&
                (this.props.apiError.model.updateCount || this.props.apiError.model.addCount);
            const count =
                this.props.apiError && this.props.apiError.errorCodeList
                    ? this.props.apiError.errorCodeList.length
                    : 0;
            const title = isConfirmModal
                ? formatMessage(messages.confirmTitle)
                : formatMessage(messages.errorsTitle, { count });
            return (
                <div className="content-table-container with-api-result">
                    <DialogBox
                        footerType={
                            isConfirmModal ? DialogBoxFooterType.YES_NO : DialogBoxFooterType.NONE
                        }
                        isOpen={this.state.showDialogBox}
                        onAction={() => this.onConfirmClick()}
                        onClose={() => this.onToggleModalClick(false)}
                        title={title}
                    >
                        <div dangerouslySetInnerHTML={this.state.errors} />
                    </DialogBox>
                    <ErrorDialog
                        isOpen={this.state.showServerError}
                        err={new APIError("", this.state.errors)}
                        intl={this.props.intl}
                        retryAction={null}
                        onRetry={() => null}
                        onClose={() => this.onToggleModalClick(false)}
                    />
                    <DialogBox
                        footerType={DialogBoxFooterType.YES_NO}
                        isOpen={this.state.showErrorFileDownload}
                        onAction={() => this.downloadErrorFile()}
                        onClose={() => this.onToggleErrorFileModal(false)}
                        title={title}
                    >
                        {formatMessage(messages.saveErrorTextFile)}
                    </DialogBox>
                    <View {...this.props} />
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            apiError: getApiError(state),
        };
    };
    const mapDispatchToProps = (dispatch) => ({
        setApiResult: (payload) => dispatch(setApiResult(payload)),
        resetApiResult: (payload) => dispatch(resetApiResult(payload)),
        actionCallback: (payload) => actionCallback(payload),
    });
    return connect(mapStateToProps, mapDispatchToProps)(injectIntl(WrapperComponent));
};

export default WithApiResult;
