import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";
import { handlePicklistChange } from "~/admin/utils";
import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";

// Components
import { ZeroToInfinite, Section } from "~/core";
import PhoneItem from "./phone-item";

// Constants
import * as constants from "./constants";

// Styles
import "./phone.css";

export const PHONE_ERROR_CODE_LIST = [437, 527];

export class Phone extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object,
        apiErrors: PropTypes.array,
        index: PropTypes.number,
        intl: intlShape.isRequired,
        onChildComponentChange: PropTypes.func,
        phoneList: PropTypes.array,
        phonePropKey: PropTypes.string,
        phoneTypeList: PropTypes.array,
        required: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            phone: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.addEditPanel.mode === "EDIT" && nextProps.phoneList !== this.state.phone) {
            this.setState({
                phone: nextProps.phoneList,
            });
        }
        if (nextProps.apiErrors !== this.props.apiErrors) {
            const containsError = PHONE_ERROR_CODE_LIST.some((errorCode) =>
                nextProps.apiErrors.includes(errorCode)
            );
            if (containsError) {
                const { phone } = this.state;
                phone.forEach((record) => {
                    record.isError = containsError;
                });
                this.setState({ phone });
            }
        }
    }

    addItem = (e, items) => this.setState({ phone: items });

    onDelete = (e, items) => this.setState({ phone: items });

    onChange = ({ type }, value, index) => {
        const phone = [...this.state.phone];
        const phoneObj = { ...phone[index] };
        if (type) {
            phoneObj[type] = value;
        }
        const phonePropKey = this.props.phonePropKey || constants.PROPS_COMPONENT_KEY;
        phone[index] = phoneObj;
        this.setState({ phone }, () => this.props.onChildComponentChange(phonePropKey, phone));
    };

    onSelectChange = ({ type, guid }, value, index) => {
        const phone = [...this.state.phone];
        let phoneObj = { ...phone[index] };
        const phonePropKey = this.props.phonePropKey || constants.PROPS_COMPONENT_KEY;
        phoneObj = handlePicklistChange(phoneObj, { type, guid, value });
        phone[index] = phoneObj;
        this.setState({ phone }, () => this.props.onChildComponentChange(phonePropKey, phone));
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { onChange, onSelectChange } = this;
        const { phone } = this.state;
        const { phoneTypeList, apiErrors } = this.props;

        const props = {
            onChange,
            onSelectChange,
            phone,
            phoneTypeList,
            apiErrors,
        };

        const initialValue = {
            [constants.PROPS_PHONE_TYPE_NAME]: "",
            [constants.PROPS_PHONE_NUMBER]: "",
        };
        return (
            <Section
                headerText={formatMessage(messages.phoneNumberHeading)}
                className="phone-header"
            >
                <ZeroToInfinite
                    items={phone}
                    initialValue={initialValue}
                    getChildProps={() => null}
                    onDelete={this.onDelete}
                    addItem={this.addItem}
                    addText={createAddLinkLabelText(formatMessage, messages.phoneNumber)}
                    deleteText={formatMessage(mainMessages.delete)}
                    formatMessage={formatMessage}
                    required={this.props.required}
                >
                    <PhoneItem {...props} />
                </ZeroToInfinite>
            </Section>
        );
    }
}

export default injectIntl(Phone);
