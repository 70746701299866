import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
    fetchCompanyList,
    fetchCompanyListSuccess,
    fetchFilteredLocationList,
    fetchFilteredLocationListSuccess,
} from "./actions";
import { SurfaceDefaultsAPI, Request } from "@ai360/core";
// selectors
import { clearFilteredLocationList } from "./actions";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { messages } from "../../i18n-messages";

function* processFetchCompanyList(action) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid };
    yield put(clearFilteredLocationList());
    try {
        const companyList = yield call(
            Request.post,
            SurfaceDefaultsAPI.GET_COMPANY_LIST,
            requestOptions
        );
        yield put(fetchCompanyListSuccess({ companyList }));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action, messages.fetchCompanyListError));
    }
}

export function* watchFetchCompanyList() {
    yield takeEvery(fetchCompanyList, processFetchCompanyList);
}

export function* processFetchFilteredLocationList(action) {
    const requestOptions = { Model: action.payload };
    try {
        const filteredLocationList = yield call(
            Request.post,
            SurfaceDefaultsAPI.REQUEST_FILTERED_LOCATION_LIST,
            requestOptions
        );
        yield put(fetchFilteredLocationListSuccess({ filteredLocationList }));
    } catch (error) {
        yield put(
            notificationActions.apiCallError(error, action, messages.fetchFilteredLocationListError)
        );
    }
}

export function* watchFilteredLocationList() {
    yield takeEvery(fetchFilteredLocationList, processFetchFilteredLocationList);
}

const SurfaceDefaultsSagas = function* () {
    yield all([fork(watchFetchCompanyList), fork(watchFilteredLocationList)]);
};

export default SurfaceDefaultsSagas;
