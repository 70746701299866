import React, { Component } from "react";
import PropTypes from "prop-types";
import { AutoSearch } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";
import classnames from "classnames";
import { getAgBytesErrorClassNames } from "~/admin/utils";

class AutoReportSearch_ extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        classNames: PropTypes.array,
        clearOnSelection: PropTypes.bool,
        containerClassName: PropTypes.string,
        disabled: PropTypes.bool,
        initialFilterStr: PropTypes.string,
        intl: intlShape.isRequired,
        itemList: PropTypes.array,
        nameProp: PropTypes.string,
        onSearchChange: PropTypes.func,
        onSelection: PropTypes.func.isRequired,
        placeholderText: PropTypes.string,
        required: PropTypes.bool,
        selectedValue: PropTypes.string,
        showTopLabel: PropTypes.bool,
        subNameProps: PropTypes.arrayOf(PropTypes.string),
    };
    static defaultProps = {
        classNames: [],
        clearOnSelection: false,
        initialFilterStr: "",
        itemList: [],
        onSearchChange: null,
        required: false,
        selectedValue: "",
        showTopLabel: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            resetOptions: false,
        };
    }

    onResetOptions = () => {
        this.setState({
            resetOptions: false,
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.itemList !== this.props.itemList) {
            this.setState({
                resetOptions: true,
            });
        }
    }

    render() {
        const {
            classNames,
            clearOnSelection,
            containerClassName,
            disabled,
            initialFilterStr,
            itemList,
            nameProp,
            onSearchChange,
            onSelection,
            placeholderText,
            selectedValue,
            showTopLabel,
        } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={classnames("form-section-child-stretch", containerClassName)}>
                <AutoSearch
                    classNames={classNames.concat(
                        getAgBytesErrorClassNames(443, this.props.apiErrors)
                    )}
                    clearOnSelection={clearOnSelection}
                    disabled={disabled}
                    initialFilterStr={initialFilterStr}
                    itemList={itemList}
                    keyProp={AutoReportSearch.REPORT_GUID}
                    nameProp={nameProp != null ? nameProp : AutoReportSearch.REPORT_NAME}
                    onResetOptions={this.onResetOptions}
                    onSearchChange={onSearchChange}
                    onSelection={(item) => onSelection(item)}
                    placeholderText={placeholderText || formatMessage(messages.reportTypes)}
                    resetOptions={this.state.resetOptions}
                    selectedValue={selectedValue}
                    showTopLabel={showTopLabel}
                />
            </div>
        );
    }
}

export const AutoReportSearch = injectIntl(AutoReportSearch_);

AutoReportSearch.REPORT_GUID = "reportTypeGuid";
AutoReportSearch.REPORT_NAME = "name";
AutoReportSearch.ORG_LEVEL_PARENT = "orgLevelParents";
