import React, { Component } from "react";
import PropTypes from "prop-types";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { messages } from "../i18n-messages";

const WithEditableGrid = (View) =>
    class WrapperComponent extends Component {
        static propTypes = {
            itemListAlias: PropTypes.string.isRequired,
            record: PropTypes.array,
            onTextChange: PropTypes.func.isRequired,
            formatMessage: PropTypes.func,
            onRecordDeleted: PropTypes.func,
        };

        constructor(props) {
            super(props);
            this.state = {
                isEditing: false,
                isDeleting: false,
                record: props.record || [],
                editingIndex: null,
                editData: {},
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.record !== this.props.record) {
                this.setState({
                    record: nextProps.record,
                });
            }
        }

        toggleModal = (modal, force) => {
            this.setState({
                [modal]: force != null ? force : !this.state[modal],
            });
        };

        onAdd = (editData = {}, callback = null) => {
            this.setState(
                {
                    editingIndex: null,
                    editData,
                },
                () => {
                    callback && callback();
                }
            );
            this.toggleModal("isEditing", true);
        };

        onDelete = ({ index }, callback) => {
            this.setState(
                {
                    editingIndex: index,
                },
                () => {
                    callback && callback();
                }
            );
            this.toggleModal("isDeleting", true);
        };

        onEdit = ({ record, index, keysToUpdate }) => {
            const { editData } = this.state;
            if (record) {
                keysToUpdate &&
                    keysToUpdate.forEach((keyItem) => {
                        if (keyItem.key && keyItem.name) {
                            editData[keyItem.name] = record[keyItem.name];
                        } else {
                            editData[keyItem] = record[keyItem];
                        }
                    });
                this.setState({
                    editingIndex: index,
                    editData,
                });
                this.toggleModal("isEditing", true);
            }
        };

        addItem = (callback = null) => {
            const { record, editData } = this.state;
            if (record) {
                record.push({ ...editData });
                this.setState(
                    {
                        record,
                    },
                    () => {
                        this.toggleModal("isEditing", false);
                        this.props.onTextChange(this.props.itemListAlias, record);
                        callback && callback();
                    }
                );
            }
        };

        editItem = (callback = null) => {
            const { record, editingIndex, editData } = this.state;
            if (record && editingIndex != null) {
                record.splice(editingIndex, 1, { ...editData });
                this.setState(
                    {
                        record,
                    },
                    () => {
                        this.toggleModal("isEditing", false);
                        this.props.onTextChange(this.props.itemListAlias, record);
                        callback && callback();
                    }
                );
            }
        };

        deleteItem = (callback = null) => {
            const { record, editingIndex } = this.state;
            if (record && editingIndex > -1) {
                const deletedItem = record[editingIndex];
                record.splice(editingIndex, 1);
                this.setState(
                    {
                        record,
                    },
                    () => {
                        this.toggleModal("isDeleting", false);
                        this.props.onTextChange(this.props.itemListAlias, record);
                        if (this.props.onRecordDeleted) {
                            this.props.onRecordDeleted(deletedItem);
                        }
                        callback && callback();
                    }
                );
            }
        };

        onAddOrEditItem = (callback = null) => {
            const { editingIndex } = this.state;
            if (editingIndex != null) {
                this.editItem(callback);
            } else {
                this.addItem(callback);
            }
        };

        onChange = (value) => {
            const { editData } = this.state;
            this.setState({
                editData: {
                    ...editData,
                    ...value,
                },
            });
        };

        renderDeleteModal = () => {
            const { isDeleting } = this.state;
            const { formatMessage } = this.props;
            return (
                <DialogBox
                    footerType={DialogBoxFooterType.YES_NO}
                    isOpen={isDeleting}
                    onAction={() => this.deleteItem()}
                    onClose={() => this.toggleModal("isDeleting", false)}
                    title={formatMessage(messages.confirmTitle)}
                >
                    {formatMessage(messages.deleteConfirmationMessage)}
                </DialogBox>
            );
        };

        render() {
            const {
                toggleModal,
                onAdd,
                onChange,
                onEdit,
                onDelete,
                onAddOrEditItem,
                deleteItem,
                renderDeleteModal,
            } = this;
            const props = {
                ...this.props,
                ...this.state,
                toggleModal,
                onAdd,
                onChange,
                onEdit,
                onDelete,
                onAddOrEditItem,
                deleteItem,
                renderDeleteModal,
            };
            return <View {...props} />;
        }
    };

export default WithEditableGrid;
