import React, { Component, ReactElement, ReactNode } from "react";
import classNames from "classnames";
import { UpArrowIcon, DownArrowIcon } from "~/core/icons";

import "./bucket.css";

export interface IBucketProps {
    className?: string;
    children?: ReactNode;
    onBucketToggle?: (isExpanded: boolean) => void;
    isExpanded?: boolean;
    showSymbol?: boolean;
    title?: string;
    isCollapsible?: boolean;
}

export interface IBucketState {
    isExpanded: boolean;
}

export class BucketHeader extends Component<IBucketProps> {
    static defaultProps = {
        isExpanded: false,
        showSymbol: true,
    };

    #onBucketToggle(): void {
        this.props.onBucketToggle(!this.props.isExpanded);
    }

    render(): JSX.Element {
        const symbol = !this.props.showSymbol ? null : this.props.isExpanded ? (
            <UpArrowIcon className="bucket-arrow-icon" />
        ) : (
            <DownArrowIcon className="bucket-arrow-icon" />
        );

        return (
            <div
                className={classNames("bucket-header", this.props.className)}
                onClick={() => {
                    this.#onBucketToggle();
                }}
                title={this.props.title}
            >
                <div className={"bucket-symbol"}>{symbol}</div>
                {this.props.children}
            </div>
        );
    }
}

export class Bucket extends Component<IBucketProps, IBucketState> {
    static defaultProps = {
        isExpanded: false,
        isCollapsible: true,
        showSymbol: true,
    };

    constructor(props: IBucketProps) {
        super(props);
        this.state = {
            isExpanded: props.isExpanded,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: IBucketProps): void {
        if (newProps.isExpanded !== this.props.isExpanded) {
            this.setState({
                isExpanded: newProps.isExpanded,
            });
        }
    }

    #onBucketToggle(): void {
        if (this.props.isCollapsible) {
            this.setState({ isExpanded: !this.state.isExpanded }, () => {
                if (this.props.onBucketToggle) {
                    this.props.onBucketToggle(this.state.isExpanded);
                }
            });
        }
    }

    render(): JSX.Element {
        let bucketHeaderFound = false;

        const bucketHeaderMap = React.Children.map(this.props.children, (child: ReactElement) => {
            if (
                child &&
                Object.hasOwn(child, "type") &&
                child.type === BucketHeader &&
                !bucketHeaderFound
            ) {
                bucketHeaderFound = true;
                return React.cloneElement(child, {
                    showSymbol: this.props.showSymbol,
                    isExpanded: this.state.isExpanded,
                    onBucketToggle: this.#onBucketToggle.bind(this),
                    title: this.props.title,
                });
            }
        });
        const bucketHeader = bucketHeaderFound ? (
            bucketHeaderMap
        ) : (
            <BucketHeader
                title={this.props.title}
                showSymbol={this.props.showSymbol}
                isExpanded={this.state.isExpanded}
                onBucketToggle={() => {
                    this.#onBucketToggle();
                }}
            />
        );

        const bucketContentMap = React.Children.map(this.props.children, (child: ReactElement) => {
            if (child && child.type !== BucketHeader) {
                return child;
            }
        });

        return (
            <div
                className={classNames(
                    "bucket",
                    !this.props.isCollapsible ? "non-collapsible" : null,
                    this.props.className
                )}
            >
                {bucketHeader}
                {this.state.isExpanded && <div className="bucket-content">{bucketContentMap}</div>}
            </div>
        );
    }
}
