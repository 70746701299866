import { device as Device } from "aws-iot-device-sdk";
import { messaging } from "@ai360/core";
import { AnySubscription } from "./subscription";
import { v4 as uuid } from "uuid";
import { action } from "typesafe-actions";

export const SUBSCRIBE = "messaging/SUBSCRIBE";
export const START = "messaging/START";
export const UPDATE_CREDENTIALS = "messaging/UPDATE_CREDENTIALS";
export const SET_DEVICE = "messaging/SET_DEVICE";
export const NEW_RECEIVER = "messaging/NEW_RECEIVER";

export const subscribe = (
    batchMilliseconds: number,
    ...subscriptions: [AnySubscription, ...AnySubscription[]]
) => action(SUBSCRIBE, { batchMilliseconds, subscriptions });

export const start = (
    userGuid: uuid,
    orgLevelGuids: Array<uuid>,
    host: string,
    credentials: messaging.Credentials
) => action(START, { userGuid, orgLevelGuids, host, credentials });

export const updateCredentials = (credentials: messaging.Credentials) =>
    action(UPDATE_CREDENTIALS, { credentials });

export const setDevice = (device: Device) => action(SET_DEVICE, { device });

export const newReceiver = (receiver: unknown) => action(NEW_RECEIVER, { receiver });
