import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { createAddLinkLabelText } from "~/i18n-messages";
import { DialogBox, DialogBoxFooterType, FileSelector, Loader, TextInput } from "~/core";
import { MSGTYPE } from "~/notifications";
import { AppHelpers, AgEventAPI } from "@ai360/core";
import { ScoutingDetailPhoto } from "~/recs-events/events/model";
import { v4 as uuid } from "uuid";

import { IThumbnailCardProps, ThumbnailItem, IAddPhotoLinkItemProps } from "./models";

interface IScoutingDetailPhotoPanelProps {
    formatMessage: (msg: any) => string;
    index: number;
    messages: any;
    onChange: (type: string, value: any, index: number, cb?: any) => void;
    onPushToasterMessage: (message: any, type: any) => void;
    photoMediaTypeGuid: string;
    photoList: AgEventAPI.IScoutingDetailPhoto[];
    scoutingPhotoPresignedUrlMap: Map<string, string>;
}

interface IScoutingDetailPhotoPanelState {
    currentPhoto: AgEventAPI.IScoutingDetailPhoto;
    isDeleting: boolean;
    isFullsizeOpen: boolean;
    isImageError: boolean;
    origNotes: string;
    finalNotes: string;
    thumbnailItems: ThumbnailItem[];
}

class ScoutingDetailPhotoPanel extends Component<
    IScoutingDetailPhotoPanelProps,
    IScoutingDetailPhotoPanelState
> {
    constructor(props: Readonly<IScoutingDetailPhotoPanelProps>) {
        super(props);
        this.state = {
            currentPhoto: null,
            isDeleting: false,
            isFullsizeOpen: false,
            isImageError: false,
            origNotes: null,
            finalNotes: null,
            thumbnailItems: [],
        };
    }

    public static supportedFileTypes: string[] = ["image/png", "image/jpeg"];

    private _closeFullsizePhotoModalAndResetState() {
        this.setState({
            currentPhoto: null,
            isFullsizeOpen: false,
            isImageError: false,
            origNotes: null,
            finalNotes: null,
        });
    }

    private _renderDeleteConfirmationModal() {
        const { isDeleting } = this.state;
        const { formatMessage, messages } = this.props;

        const onDelete = () => {
            this._updatePhotoList(this.state.currentPhoto.eventScoutingDetailPhotoGuid);
            this._closeFullsizePhotoModalAndResetState();
        };

        return (
            <DialogBox
                isOpen={isDeleting}
                onAction={() => {
                    onDelete();
                    this.setState({ isDeleting: false });
                }}
                onClose={() => this.setState({ isDeleting: false })}
                title={formatMessage(messages.titleDeletePhotoConfirmation)}
                footerType={DialogBoxFooterType.YES_NO}
            >
                {formatMessage(messages.deletePhotoConfirmation)}
            </DialogBox>
        );
    }

    private _renderFullsizePhotoModal() {
        const { formatMessage, messages, scoutingPhotoPresignedUrlMap } = this.props;
        const { currentPhoto, isFullsizeOpen, isImageError } = this.state;

        if (!currentPhoto) {
            return null;
        }

        const fullsizePhoto: string = currentPhoto.fullsizePhoto;
        const fullsizeImageSrc = fullsizePhoto
            ? `data:image/${
                  fullsizePhoto.indexOf("iVBOR") >= 0 ? "png" : "jpeg"
              };base64,${fullsizePhoto}`
            : scoutingPhotoPresignedUrlMap.get(currentPhoto.eventScoutingDetailPhotoGuid);

        const confirmDelete = () => {
            this.setState({ isDeleting: true });
        };

        const onSave = () => {
            const { currentPhoto, finalNotes, origNotes } = this.state;
            if (finalNotes !== origNotes) {
                this._updatePhotoList(
                    currentPhoto.eventScoutingDetailPhotoGuid,
                    null,
                    finalNotes,
                    true
                );
            }
            this._closeFullsizePhotoModalAndResetState();
        };

        const actionList = [
            {
                action: formatMessage(messages.fullsizePhotoModalDeleteText),
                onAction: confirmDelete,
            },
            {
                action: formatMessage(messages.fullsizePhotoModalSaveText),
                actionDisabled: this.state.finalNotes === this.state.origNotes,
                onAction: onSave,
            },
        ];

        const onImageLoad = () => {
            this.setState({ isImageError: false });
        };

        const onImageError = () => {
            this.setState({ isImageError: true });
        };

        return (
            <DialogBox
                isOpen={isFullsizeOpen}
                unrestricted
                draggable={true}
                hideCloseX={true}
                isModal={true}
                title={formatMessage(messages.titleFullsizePhotoModal)}
                footerType={DialogBoxFooterType.MULTI_ACTION_CANCEL}
                multiActionList={actionList}
                onClose={() => this.setState({ isFullsizeOpen: false })}
            >
                <div className="description-input-row">
                    <TextInput
                        placeholderText={formatMessage(
                            messages.scoutingPhotoDescriptionPlaceholderText
                        )}
                        maxLength={256}
                        autoFocus={!this.state.finalNotes}
                        value={this.state.finalNotes}
                        onChange={(desc: string) => this._updateNotes(desc)}
                    />
                </div>
                <div className="fullsize-photo-row">
                    {isImageError || !fullsizeImageSrc ? (
                        <div className="fullsize-photo-not-found-msg">
                            {formatMessage(messages.fullsizePhotoNotFoundText)}
                        </div>
                    ) : (
                        <img
                            alt=""
                            src={fullsizeImageSrc}
                            onLoad={() => onImageLoad()}
                            onError={() => onImageError()}
                        />
                    )}
                </div>
            </DialogBox>
        );
    }

    private _updateNotes(notes: string) {
        this.setState({ finalNotes: notes });
    }

    private _updatePhotoList(
        guid: string,
        fullsizeBase64: string = null,
        notes: string = null,
        isUpdateNotes = false
    ) {
        const {
            formatMessage,
            index,
            messages,
            onChange,
            onPushToasterMessage,
            photoList,
            photoMediaTypeGuid,
        } = this.props;

        // If we are adding a photo, make sure photoMediaTypeGuid was retrieved successfully before proceeding
        if (fullsizeBase64 && photoMediaTypeGuid === null) {
            onPushToasterMessage(
                formatMessage(messages.errorMissingMediaTypeGuid),
                MSGTYPE.WARNING
            );
            return;
        }

        const addFullsizePhoto = () => {
            return [
                ...photoList,
                new ScoutingDetailPhoto(null, fullsizeBase64, guid, null, photoMediaTypeGuid),
            ];
        };

        const deletePhoto = () => {
            return photoList.filter((p) => p.eventScoutingDetailPhotoGuid !== guid);
        };

        const updateNotes = () => {
            const tempPhotoList = [...photoList];
            const photoIndex = tempPhotoList.findIndex(
                (p) => p.eventScoutingDetailPhotoGuid === guid
            );
            const updatedPhoto: AgEventAPI.IScoutingDetailPhoto = new ScoutingDetailPhoto(
                tempPhotoList[photoIndex].photo,
                tempPhotoList[photoIndex].fullsizePhoto,
                tempPhotoList[photoIndex].eventScoutingDetailPhotoGuid,
                notes,
                tempPhotoList[photoIndex].mediaTypeGuid
            );
            tempPhotoList.splice(photoIndex, 1, updatedPhoto);
            return tempPhotoList;
        };

        const updatedPhotoList = fullsizeBase64
            ? addFullsizePhoto()
            : isUpdateNotes
            ? updateNotes()
            : deletePhoto();

        onChange("photoList", updatedPhotoList, index);
    }

    private _viewFullsizePhotoModal(event: any, photo: any) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            currentPhoto: photo,
            isFullsizeOpen: true,
            isImageError: false,
            origNotes: photo.notes,
            finalNotes: photo.notes,
        });
    }

    public componentDidMount() {
        const existingPhotoItems = this.props.photoList
            ? this.props.photoList.map((p) => {
                  return new ThumbnailItem(p.eventScoutingDetailPhotoGuid, p);
              })
            : [];
        this.setState((previousState) => ({
            thumbnailItems: [...previousState.thumbnailItems, ...existingPhotoItems],
        }));
    }

    public UNSAFE_componentWillReceiveProps(newProps: IScoutingDetailPhotoPanelProps) {
        if (this.props.photoList !== newProps.photoList && newProps.photoList) {
            const newPhotoListGuids: string[] = newProps.photoList.map((p) => {
                return p.eventScoutingDetailPhotoGuid;
            });
            const remainingThumbnailItems = this.state.thumbnailItems.filter(
                (t) => newPhotoListGuids.includes(t.guid) || t.scoutingPhoto === null
            );
            // Update or add thumbnail items as needed - should never have to add here because a thumbnail item was
            // added (without the photo) when the file was first loaded.
            newProps.photoList.forEach((p) => {
                const existingItem = remainingThumbnailItems.find(
                    (c) => c.guid === p.eventScoutingDetailPhotoGuid
                );
                if (existingItem) {
                    existingItem.scoutingPhoto = p;
                } else {
                    remainingThumbnailItems.push(
                        new ThumbnailItem(p.eventScoutingDetailPhotoGuid, p)
                    );
                }
            });
            this.setState({
                thumbnailItems: remainingThumbnailItems,
            });
        }
    }

    public render() {
        const { formatMessage, index, messages, onPushToasterMessage } = this.props;

        const onAdd = ({ files }) => {
            const { messages } = this.props;

            for (const file of files) {
                if (!ScoutingDetailPhotoPanel.supportedFileTypes.includes(file.type)) {
                    onPushToasterMessage(messages.errorUnsupportedImageType, MSGTYPE.WARNING);
                    continue;
                }
                const newGuid = uuid();
                this.setState((previousState) => ({
                    thumbnailItems: [...previousState.thumbnailItems, new ThumbnailItem(newGuid)],
                }));

                AppHelpers.fileToBase64(file).then((base64String: string) => {
                    if (base64String) {
                        const base64WithoutHeader = base64String.replace(
                            `data:${file.type};base64,`,
                            ""
                        );
                        this._updatePhotoList(newGuid, base64WithoutHeader);
                    } else {
                        onPushToasterMessage(messages.errorConvertBase64, MSGTYPE.ERROR);
                        this.setState((previousState) => ({
                            thumbnailItems: previousState.thumbnailItems.filter(
                                (t) => t.guid !== newGuid
                            ),
                        }));
                    }
                });
            }
        };

        return (
            <div className="observation-photo-row">
                {this.state.thumbnailItems.map((t, i) => {
                    return (
                        <ThumbnailCard
                            thumbnailItem={t}
                            onClick={(e: any) => this._viewFullsizePhotoModal(e, t.scoutingPhoto)}
                            key={i}
                        />
                    );
                })}
                <AddPhotoLinkItem
                    formatMessage={formatMessage}
                    index={index}
                    messages={messages}
                    onAdd={onAdd}
                    itemCount={this.state.thumbnailItems.length}
                />
                {this._renderFullsizePhotoModal()}
                {this._renderDeleteConfirmationModal()}
            </div>
        );
    }
}

const AddPhotoLinkItem = ({
    formatMessage,
    index,
    messages,
    onAdd,
    itemCount,
}: IAddPhotoLinkItemProps) => {
    const photoLinkClassName = itemCount % 4 === 0 ? "add-photo-link-short" : "add-photo-link-tall";
    return (
        <span className={photoLinkClassName}>
            <FileSelector
                fileType={ScoutingDetailPhotoPanel.supportedFileTypes.toString()}
                allowMultiple={true}
                label={createAddLinkLabelText(formatMessage, messages.addPhoto)}
                fileCompletedCallback={onAdd}
                id={`photo-selector-${index}`}
                className="add-link"
            />
        </span>
    );
};

const ThumbnailCard = ({ onClick, thumbnailItem }: IThumbnailCardProps) => {
    const isLoaded =
        thumbnailItem.scoutingPhoto !== null && thumbnailItem.scoutingPhoto !== undefined;
    const thumbnail = !isLoaded
        ? null
        : thumbnailItem.scoutingPhoto?.photo
        ? thumbnailItem.scoutingPhoto?.photo
        : thumbnailItem.scoutingPhoto?.fullsizePhoto;
    return isLoaded ? (
        <img
            alt=""
            src={`data:image/${
                thumbnail.indexOf("iVBOR") >= 0 ? "png" : "jpeg"
            };base64,${thumbnail}`}
            className="observation-photo-thumbnail"
            onClick={onClick}
        />
    ) : (
        <span className="observation-photo-thumbnail-loader">
            <Loader />
        </span>
    );
};

export default injectIntl(ScoutingDetailPhotoPanel);
