import { ActionType } from "typesafe-actions";
import { SET_IS_LOADING, SWITCH_THEME, ThemeClassNames } from "./actions";
import * as actions from "./actions";

export interface IThemeState {
    themeClassName: string;
    isLoading: boolean;
}

const initialState: IThemeState = {
    themeClassName: ThemeClassNames.DEFAULT,
    isLoading: false,
};

export function themeReducer(
    state = initialState,
    action: ActionType<typeof actions>
): IThemeState {
    switch (action.type) {
        case SWITCH_THEME:
            return {
                ...state,
                themeClassName: action.payload,
            };
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
}
