import { all, call, put, select, SelectEffect, takeLatest } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import moment from "moment";
import * as actions from "./actions";
import * as selectors from "./selectors";

import * as nonFieldFeatureSelectors from "~/action-panel/components/layer-module/components/layer-list/non-field-data/selectors";
import * as layerModuleActions from "../../../layer-module/actions";
import { LayerModulePages } from "../../../layer-module/actions";
import * as layerListActions from "../../../layer-module/components/layer-list/actions";
import { defaultCroppingSeasonGuid } from "../analysis-info/sagas";
import { AgEventAPI, NonFieldFeatureAPI } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { getUser } from "~/login";
import { actions as accordionActions } from "~/accordion";
import { AccordionItem } from "~/accordion/model";
import { actions as cdActions } from "~/customer-data";
import { constructSampleAccordionItem } from "../layer-list/non-field-data/sagas";
import { getPickListCode, PICKLIST_CROPPING_SEASON } from "~/core/picklist/picklist-names";
import * as picklistSelectors from "~/core/picklist/selectors";
import { ISamplingEventIdRequest } from "@ai360/core/dist/4x/es/api/ag-event/ag-event.4x";

function* getDefaultWaterSampling(nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature) {
    const samplingEventIdRequest: ISamplingEventIdRequest = {
        blockSize: 1,
        deviceVendorId: null,
        isThirdParty: false,
    };
    const eventId = yield call(AgEventAPI.getNextEventId, samplingEventIdRequest);

    const newWaterSampling = {
        activeYN: true,
        createdByGuid: null,
        createdByClientId: null,
        createdDate: null,
        croppingSeasonGuid: yield defaultCroppingSeasonGuid(),
        eventId: eventId,
        eventName: null,
        importAttributeValues: [],
        importResultsDate: null,
        isAutomatedTestProcessor: null,
        modifiedByClientId: null,
        modifiedByGuid: null,
        modifiedDate: null,
        nonFieldFeatureGuid: nonFieldFeature.id,
        notes: "",
        sampleDate: moment(),
        testingLab: null,
        waterSamplingGuid: uuid(),
    };
    return newWaterSampling;
}

const isExisting = (sampling: AgEventAPI.IAgEventWaterSampling): boolean => {
    return sampling.createdDate !== null;
};

const getFullName = (user): string => {
    const firstName = user?.personProperties?.firstName;
    const middleName = user?.personProperties?.middleName;
    const lastName = user?.personProperties.lastName;
    return firstName + (middleName ? " " + middleName : "") + " " + lastName;
};

const getSummaryfromSampling = function* (
    sampling: AgEventAPI.IAgEventWaterSampling
): Generator<SelectEffect, AgEventAPI.IWaterSamplingSummary> {
    const croppingSeasonName = yield* getCroppingSeasonName(sampling.croppingSeasonGuid);
    const user = yield select(getUser);
    return {
        activeYN: sampling.activeYN,
        croppingSeasonGuid: sampling.croppingSeasonGuid,
        croppingSeasonName: croppingSeasonName,
        modifiedBy: getFullName(user),
        modifiedDate: moment().format("M/D/YY"),
        eventId: sampling.eventId,
        eventName: sampling.eventName,
        nonFieldFeatureGuid: sampling.nonFieldFeatureGuid,
        sampleDate: sampling.sampleDate ? moment(sampling.sampleDate).format("M/D/YY") : null,
        waterSamplingGuid: sampling.waterSamplingGuid,
        hasResults: sampling.importAttributeValues?.length > 0,
    };
};

const getCroppingSeasonName = function* (croppingSeasonGuid: string) {
    const picklistOptionsCroppingSeason = yield select(
        picklistSelectors.getPicklistOptionsFromCode,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );
    return picklistOptionsCroppingSeason.find((o) => o.value === croppingSeasonGuid).label;
};

const getTargetCustomerIndex = (previousAccordionItems: AccordionItem[], customerGuid: string) => {
    return previousAccordionItems.findIndex(
        (n) => n.payload?.customer?.customerGuid === customerGuid
    );
};

const getTargetNFFIndex = (targetCustomer: AccordionItem, nonFieldFeatureGuid: string) => {
    return targetCustomer.children.findIndex(
        (nff) => nff.payload?.feature.id === nonFieldFeatureGuid
    );
};

const getNFFAccordionChildren = (
    previousAccordionItems: AccordionItem[],
    nonFieldFeatureGuid: string,
    customerGuid: string
): AccordionItem[] => {
    const targetCustomer = previousAccordionItems.find(
        (n) => n.payload?.customer?.customerGuid === customerGuid
    );
    const targetNFF = targetCustomer.children.find(
        (nff) => nff.payload?.feature.id === nonFieldFeatureGuid
    );
    return targetNFF.children;
};

const getRevisedChildren = (
    existingChildren: AccordionItem[],
    samplingSummary: AgEventAPI.IWaterSamplingSummary,
    nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature,
    isNew: boolean
) => {
    const nffChildren = [...existingChildren];
    if (isNew) {
        nffChildren.push(constructSampleAccordionItem(nonFieldFeature, samplingSummary));
    } else {
        const targetSampleIndex = nffChildren.findIndex(
            (w) => w.payload?.sample.waterSamplingGuid === samplingSummary.waterSamplingGuid
        );
        nffChildren.splice(
            targetSampleIndex,
            1,
            constructSampleAccordionItem(nonFieldFeature, samplingSummary)
        );
    }
    return nffChildren;
};

const getSortedWaterSamplesAccordion = (waterSamplesAccordion: AccordionItem[]) => {
    return waterSamplesAccordion.sort((a, b) =>
        a.payload.croppingSeasonName < b.payload.croppingSeasonName
            ? 1
            : a.payload.sample.croppingSeasonName > b.payload.sample.croppingSeasonName
            ? -1
            : moment(a.payload.sample.sampleDate) < moment(b.payload.sample.sampleDate)
            ? 1
            : moment(a.payload.sample.sampleDate) > moment(b.payload.sample.sampleDate)
            ? -1
            : moment(a.payload.sample.createdDate) < moment(b.payload.sample.createdDate)
            ? 1
            : moment(a.payload.sample.createdDate) > moment(b.payload.sample.createdDate)
            ? -1
            : 0
    );
};

const addEditSampleInNFF = function* (
    sampling: AgEventAPI.IAgEventWaterSampling,
    nonFieldFeature: NonFieldFeatureAPI.NonFieldFeature
) {
    const isNew = !isExisting(sampling);
    const previousAccordionItems: AccordionItem[] = yield select(nonFieldFeatureSelectors.getItems);
    const targetCustomerIndex = getTargetCustomerIndex(
        previousAccordionItems,
        nonFieldFeature.customerId
    );
    const targetNFFIndex = getTargetNFFIndex(
        previousAccordionItems[targetCustomerIndex],
        nonFieldFeature.id
    );
    const targetNFF = previousAccordionItems[targetCustomerIndex].children[targetNFFIndex];
    const samplingSummary = yield* getSummaryfromSampling(sampling);
    const revisedChildren = getRevisedChildren(
        targetNFF.children,
        samplingSummary,
        nonFieldFeature,
        isNew
    );

    yield* updateSampleInNFFCustomerData(
        previousAccordionItems[targetCustomerIndex].children[targetNFFIndex],
        samplingSummary
    );
    yield* updateNFFAccordionChildren(revisedChildren, targetCustomerIndex, targetNFFIndex);
};

const removeSampleFromNFF = function* (
    waterSamplingGuid: string,
    nonFieldFeatureGuid: string,
    customerGuid: string
) {
    const previousAccordionItems: AccordionItem[] = yield select(nonFieldFeatureSelectors.getItems);
    const targetCustomerIndex = getTargetCustomerIndex(previousAccordionItems, customerGuid);
    const targetNFFIndex = getTargetNFFIndex(
        previousAccordionItems[targetCustomerIndex],
        nonFieldFeatureGuid
    );

    const waterSamplingAccordionItems = getNFFAccordionChildren(
        previousAccordionItems,
        nonFieldFeatureGuid,
        customerGuid
    );
    const indexToRemove = waterSamplingAccordionItems.findIndex(
        (w) => w.payload?.sample.waterSamplingGuid === waterSamplingGuid
    );
    yield* removeSampleFromNFFCustomerData(
        previousAccordionItems[targetCustomerIndex].children[targetNFFIndex],
        waterSamplingGuid
    );
    waterSamplingAccordionItems.splice(indexToRemove, 1);
    yield* updateNFFAccordionChildren(
        waterSamplingAccordionItems,
        targetCustomerIndex,
        targetNFFIndex
    );
    yield put(layerListActions.setPanelLoading(false));
};

const removeSampleFromNFFCustomerData = function* (
    originalNFFAccordion: AccordionItem,
    waterSamplingGuid: string
) {
    const originalFeature: NonFieldFeatureAPI.NonFieldFeature =
        originalNFFAccordion.payload.feature;
    const targetSampleIndex = originalFeature.waterSamples.findIndex(
        (w) => w.waterSamplingGuid === waterSamplingGuid
    );
    const updatedWaterSamples = [...originalFeature.waterSamples];
    updatedWaterSamples.splice(targetSampleIndex, 1);
    yield* modifyCustomerDataNFFWaterSamples(originalFeature, updatedWaterSamples);
};

const updateSampleInNFFCustomerData = function* (
    originalNFFAccordion: AccordionItem,
    samplingSummary: AgEventAPI.IWaterSamplingSummary
) {
    const originalFeature: NonFieldFeatureAPI.NonFieldFeature =
        originalNFFAccordion.payload.feature;
    const targetSampleIndex = originalFeature.waterSamples.findIndex(
        (w) => w.waterSamplingGuid === samplingSummary.waterSamplingGuid
    );
    const updatedWaterSamples = [...originalFeature.waterSamples];
    if (targetSampleIndex !== -1) {
        updatedWaterSamples.splice(targetSampleIndex, 1, samplingSummary);
    } else {
        updatedWaterSamples.push(samplingSummary);
    }
    yield* modifyCustomerDataNFFWaterSamples(originalFeature, updatedWaterSamples);
};

const modifyCustomerDataNFFWaterSamples = function* (
    originalFeature: NonFieldFeatureAPI.NonFieldFeature,
    updatedWaterSamples: AgEventAPI.IWaterSamplingSummary[]
) {
    const updatedFeature = {
        ...originalFeature,
        waterSamples: updatedWaterSamples,
    };

    yield put(
        cdActions.modifyNonFieldFeatures({
            replace: [{ source: originalFeature, target: updatedFeature }],
        })
    );
};

const updateNFFAccordionChildren = function* (
    children: AccordionItem[],
    targetCustomerIndex: number,
    targetNFFIndex: number
) {
    const accordionId = yield select(nonFieldFeatureSelectors.getId);
    const sortedSampleSummaryAccordion = getSortedWaterSamplesAccordion(children);
    yield put(
        accordionActions.updateAccordionItem(accordionId, [targetCustomerIndex, targetNFFIndex], {
            children: sortedSampleSummaryAccordion,
        })
    );
};

const onAddSampling = function* (action) {
    const { nonFieldFeature } = action.payload;
    const summary = yield getDefaultWaterSampling(nonFieldFeature);
    yield put(layerListActions.setPanelLoading(true));
    yield put(actions.setNonFieldFeature(nonFieldFeature));
    yield put(actions.setSampling(summary));
    yield put(layerModuleActions.setActivePage(LayerModulePages.WATER_SAMPLING));
    yield put(layerListActions.setPanelLoading(false));
};

const onCloseSampling = function* () {
    yield put(layerListActions.setPanelLoading(true));
    yield put(layerModuleActions.setActivePage(layerModuleActions.LayerModulePages.LAYER_LIST));
    yield put(actions.setNonFieldFeature(null));
    yield put(actions.setSampling(null));
    yield put(layerListActions.setPanelLoading(false));
};

const onDeleteSampling = function* (action) {
    const { waterSamplingGuid, nonFieldFeatureGuid, customerGuid } = action.payload;
    yield put(layerListActions.setPanelLoading(true));
    yield put(actions.setSampling(null));
    yield call(AgEventAPI.deactivateWaterSamplingItem, waterSamplingGuid);
    yield* removeSampleFromNFF(waterSamplingGuid, nonFieldFeatureGuid, customerGuid);
};

const onEditSampling = function* (action) {
    const { nonFieldFeature, waterSamplingGuid } = action.payload;
    yield put(layerListActions.setPanelLoading(true));
    yield put(actions.setSamplingDetailsLoading(true));
    yield put(actions.fetchSamplingInfo(waterSamplingGuid));
    yield put(actions.setNonFieldFeature(nonFieldFeature));
    yield put(layerListActions.setPanelLoading(false));
};

const onFetchSamplingInfo = function* (action) {
    const { waterSamplingGuid } = action.payload;
    try {
        const waterSampling = yield call(AgEventAPI.getWaterSamplingItem, waterSamplingGuid);
        waterSampling.sampleDate = waterSampling.sampleDate
            ? moment(waterSampling.sampleDate)
            : null;
        yield put(actions.setSampling(waterSampling));
        yield put(layerModuleActions.setActivePage(LayerModulePages.WATER_SAMPLING));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
    yield put(actions.setSamplingDetailsLoading(false));
};

const onSaveSampling = function* (action) {
    const sampling: AgEventAPI.IAgEventWaterSampling = yield select(selectors.getSampling);
    const nonFieldFeature = yield select(selectors.getNonFieldFeature);
    yield put(layerListActions.setPanelLoading(true));
    yield put(actions.closeSampling());
    try {
        yield call(AgEventAPI.mergeWaterSampling, sampling);
        yield* addEditSampleInNFF(sampling, nonFieldFeature);
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action, null, null));
    } finally {
        yield put(actions.setNonFieldFeature(null));
        yield put(layerListActions.setPanelLoading(false));
    }
};

export const samplingSaga = function* () {
    yield all([takeLatest(actions.ADD_SAMPLING, onAddSampling)]);
    yield all([takeLatest(actions.CLOSE_SAMPLING, onCloseSampling)]);
    yield all([takeLatest(actions.DELETE_SAMPLING, onDeleteSampling)]);
    yield all([takeLatest(actions.EDIT_SAMPLING, onEditSampling)]);
    yield all([takeLatest(actions.SAVE_SAMPLING, onSaveSampling)]);
    yield all([takeLatest(actions.FETCH_SAMPLING_INFO, onFetchSamplingInfo)]);
};
