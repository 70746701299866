import * as actions from "./actions";
import { ReducerAction } from "./actions";

const initialState = {
    releaseNotes: "",
};
export const SETUP_RELEASE_NOTES_DATA_KEY = "SETUP_RELEASE_NOTES_DATA_KEY";

export function setupReleaseNotesReducer(
    state = initialState,
    action: ReducerAction = {
        type: null,
        releaseNotes: "",
    }
): Record<string, unknown> {
    switch (action.type) {
        case actions.FETCH_RELEASE_NOTES_SUCCESS:
            return Object.freeze({
                ...state,
                releaseNotes: action.releaseNotes,
            });
        default:
            return state;
    }
}
