import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const PrintIcon = withSvgIcon(
    <g fillRule="evenodd">
        <path d="M1779.712 1322.25l-321.64.969V1132.67h138.917c26.138 0 47.274-21.136 47.274-47.274s-21.136-47.274-47.274-47.274H329.305c-26.138 0-47.274 21.136-47.274 47.274s21.136 47.274 47.274 47.274h156.988v190.548l-344.793-.968V568.368h1638.212v753.883zM580.84 1825.082h782.682v-692.41H580.841v692.41zM1790.602 473.74H130.61c-46.144 0-83.657 37.593-83.657 83.738v775.583c0 46.064 37.513 83.657 83.576 83.657l355.765 1.13v418.204c0 46.064 37.513 83.577 83.657 83.577h804.464c46.145 0 83.657-37.513 83.657-83.577v-418.205l332.531-1.129c46.145 0 83.577-37.593 83.577-83.657V557.478c0-46.145-37.432-83.738-83.577-83.738zM583.689 95.62h753.882v292.196h94.467V84.649c0-46.063-37.512-83.657-83.657-83.657H572.798c-46.145 0-83.576 37.594-83.576 83.657v303.167h94.467V95.62z" />
        <path d="M1539.276 878.787c54.534 0 98.743-44.208 98.743-98.743 0-54.534-44.209-98.742-98.743-98.742-54.534 0-98.824 44.208-98.824 98.742 0 54.535 44.29 98.743 98.824 98.743M742.637 1368.258h445.07c15.004 0 27.186-12.181 27.186-27.267s-12.182-27.348-27.187-27.348H742.637c-15.085 0-27.267 12.262-27.267 27.348 0 15.086 12.182 27.267 27.267 27.267M736.57 1626.974h445.07c15.005 0 27.186-12.181 27.186-27.267 0-15.005-12.181-27.267-27.186-27.267H736.57c-15.085 0-27.266 12.262-27.266 27.267 0 15.086 12.181 27.267 27.267 27.267" />
    </g>,
    "0 0 1920 1920"
);
