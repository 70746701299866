import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import {
    PICKLIST_OBSERVATION_PARENT_TYPE,
    PICKLIST_LIFESPAN,
    PICKLIST_MORPHOLOGY_TYPE,
    PICKLIST_ORGANISM_TYPE,
    PICKLIST_OBSERVATION_TYPE,
    getPickListCode,
} from "~/core/picklist/picklist-names";

import { UNIT_CROP_PRICE, getUnitCode } from "~/core/units/unit-names";

export const MODEL_NAME = "observation";
export const URL = apiUrl("AgBytes/GetObservationList");
export const GETRECORD = apiUrl("AgBytes/GetObservation");
export const NEXT_ID = apiUrl("AgBytes/GetNextObservationId");
export const CREATE = apiUrl("AgBytes/AddObservation");
export const UPDATE = apiUrl("AgBytes/UpdateObservation");
export const DELETE = apiUrl("AgBytes/DeleteObservationList");
export const EXPORT_FILE_NAME = "ObservationExport";
export const EXPORT_URL = apiUrl("AgBytes/ExportObservationList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetObservationAutoSearchList");
export const IMPORT_URL = apiUrl("AgBytes/ObservationImport");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidObservationImport");
export const SELECT_ALL = apiUrl("AgBytes/GetObservationSelectAllList");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "observationFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "observationSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "observationPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

// Dropdowns
export const REQUEST_GROWTH_STAGE = apiUrl("AgBytes/GetGrowthStageAgBytesList");
export const REQUEST_CROP_NAME = apiUrl("AgBytes/GetCropAgBytesList");

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        observationId: "",
        observationName: "",
        observationParentType: "",
        observationType: "",
        observationParentName: "",
        groupName: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};
export const defaultSort = {
    FieldName: "ObservationParentType",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

// TODO : Check for add/edit intialization
const defaultObservation = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_CAN_DELETE]: "",
    [model.PROPS_OBSERVATION_ID]: "",
    [model.PROPS_OBSERVATION_GUID]: "",
    [model.PROPS_OBSERVATION_NAME]: "",
    [model.PROPS_OBSERVATION_PARENT_TYPE]: "",
    [model.PROPS_OBSERVATION_TYPE]: "",
    [model.PROPS_OBSERVATION_PARENT_NAME]: "",
    [model.PROPS_OBSERVATION_GROUP_NAME]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_AGRIAN_ID]: [],
    [model.PROPS_CROP_LIST]: [],
    [model.PROPS_OBSERVATION_PHOTO_LIST]: [],
    [model.PROPS_AG_GATEWAY_ID]: "",
    [model.PROPS_DESCRIPTION_SYMPTOMS]: "",
    [model.PROPS_DIVISION]: "",
    [model.PROPS_FAMILY]: "",
    [model.PROPS_FAVORABLE_CONDITIONS]: "",
    [model.PROPS_GENUS]: "",
    [model.PROPS_KINGDOM]: "",
    [model.PROPS_LIFESPAN]: "",
    [model.PROPS_MORPHOLOGY_TYPE]: "",
    [model.PROPS_OBSERVATION_ALIAS_LIST]: [],
    [model.PROPS_ORGANISM_TYPE_LIST]: [],
    [model.PROPS_SCIENTIFIC_NAME]: "",
    [model.PROPS_ORDER]: "",
    [model.PROPS_CLASS]: "",
});

export const pickLists = {
    [PICKLIST_OBSERVATION_TYPE]: getPickListCode(PICKLIST_OBSERVATION_TYPE),
    [PICKLIST_OBSERVATION_PARENT_TYPE]: getPickListCode(PICKLIST_OBSERVATION_PARENT_TYPE),
    [PICKLIST_LIFESPAN]: getPickListCode(PICKLIST_LIFESPAN),
    [PICKLIST_MORPHOLOGY_TYPE]: getPickListCode(PICKLIST_MORPHOLOGY_TYPE),
    [PICKLIST_ORGANISM_TYPE]: getPickListCode(PICKLIST_ORGANISM_TYPE),
};

export const unitTypes = {
    [UNIT_CROP_PRICE]: getUnitCode(UNIT_CROP_PRICE),
};

export const dropdowns = {
    [model.PROPS_OBSERVATION_GROUP_NAME]: REQUEST_GROWTH_STAGE,
    [model.PROPS_CROP_LIST]: REQUEST_CROP_NAME,
};

export const service = createService({
    id: model.PROPS_OBSERVATION_ID,
    guid: model.PROPS_OBSERVATION_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    dropdowns,
    unitTypes,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_OBSERVATION_ID]: { label: "observationId", gridCol: 5 },
        [model.PROPS_OBSERVATION_PARENT_TYPE]: {
            label: "observationParentType",
            gridCol: 10,
        },
        [model.PROPS_OBSERVATION_TYPE]: {
            label: "observationType",
            gridCol: 10,
        },
        [model.PROPS_OBSERVATION_NAME]: {
            label: "observationName",
            gridCol: 15,
        },
        [model.PROPS_OBSERVATION_PARENT_NAME]: {
            label: "observationParentName",
            gridCol: 10,
        },
        [model.PROPS_OBSERVATION_GROUP_NAME]: {
            label: "growthStageGroupName",
            gridCol: 10,
            sortNameOverRide: "groupName",
        },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => Object.assign({}, defaultObservation()),
    defaultSort,
});
