import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { Tabs, Pane } from "~/core";
import { WarningIcon } from "~/core/icons";
import { selectors as cdSelectors } from "~/customer-data";
import { config as intlConfig } from "~/intl-provider/config";
import { MAX_FETCH_FIELDS } from "~/recs-events/model";
import { RecEventListTabs } from "~/recs-events/actions";
import { setIsEnabled } from "~/action-panel/actions";
import { messages } from "./i18n-messages";
import { getUser } from "~/login/selectors";

import "./filter-tabs.css";

export interface IFilterTabsProps {
    children: JSX.Element[];
    contextMenu: JSX.Element;
    intl: intlShape;
    isSelectedFilterActive: boolean;
    activeTab: string;
    onSetSelectedFilterActive: (b: boolean, tab: string) => void;
    selectedFieldCount: number;
    actionPanelSetIsEnabled?: (b: boolean) => void;
    hasSelectedItems: boolean;
    userInfo: any;
}

export class FilterTabs_ extends Component<IFilterTabsProps> {
    private getTabFromIdx = (tabIdx: number): string => {
        const hasInactivePermission = this.props.userInfo.role.activeInactive;
        switch (tabIdx) {
            case 0:
                return hasInactivePermission ? RecEventListTabs.INACTIVE : RecEventListTabs.ACTIVE;
            case 1:
                return hasInactivePermission ? RecEventListTabs.ACTIVE : RecEventListTabs.SELECTED;
            case 2:
                console.assert(hasInactivePermission);
                return RecEventListTabs.SELECTED;
            default:
                break;
        }
    };

    private getIdxFromTab = (tab: string): number => {
        const hasInactivePermission = this.props.userInfo.role.activeInactive;
        switch (tab) {
            case RecEventListTabs.INACTIVE:
                console.assert(hasInactivePermission);
                return 0;
            case RecEventListTabs.ACTIVE:
                return hasInactivePermission ? 1 : 0;
            case RecEventListTabs.SELECTED:
                return hasInactivePermission ? 2 : 1;
            default:
                break;
        }
    };

    render(): JSX.Element {
        const {
            contextMenu,
            children,
            onSetSelectedFilterActive,
            selectedFieldCount,
            userInfo,
            activeTab,
            actionPanelSetIsEnabled,
            hasSelectedItems,
        } = this.props;

        const { formatMessage, formatNumber } = this.props.intl;
        const hasInactivePermission = userInfo.role.activeInactive;

        const inactiveTabIndex = 0;
        const activeAllTabIndex = hasInactivePermission ? 1 : 0;
        const selectedTabIndex = hasInactivePermission ? 2 : 1;

        const panes = [];
        if (hasInactivePermission) {
            panes.push(
                <Pane key={inactiveTabIndex} label={formatMessage(messages.inactiveTabText)}>
                    {children}
                </Pane>
            );
        }
        const tabText = hasInactivePermission ? messages.activeTabText : messages.allTabText;
        panes.push(
            <Pane key={activeAllTabIndex} label={formatMessage(tabText)}>
                {children}
            </Pane>
        );
        panes.push(
            <Pane
                key={selectedTabIndex}
                label={formatMessage(messages.selectedTabText)}
                disabled={!hasSelectedItems}
            >
                {children}
            </Pane>
        );

        const selectedTabIdx = this.getIdxFromTab(activeTab);

        const labelContainerLeftElements = [];
        const labelContainerRightElements = [contextMenu];

        if (selectedFieldCount > MAX_FETCH_FIELDS) {
            labelContainerLeftElements.push(
                <WarningIcon
                    className="warning-icon"
                    title={formatMessage(messages.tooManyFieldsWarning, {
                        count: formatNumber(MAX_FETCH_FIELDS, intlConfig.wholeNumberFormatOptions),
                        total: formatNumber(
                            selectedFieldCount,
                            intlConfig.wholeNumberFormatOptions
                        ),
                    })}
                />
            );
        }
        labelContainerLeftElements.push(<div key={0} className="spacer" />);

        return (
            <Tabs
                className="filter-tabs"
                selected={selectedTabIdx}
                labelContainerLeftElements={labelContainerLeftElements}
                labelContainerRightElements={labelContainerRightElements}
                onTabSelect={(tabIdx) => {
                    onSetSelectedFilterActive(
                        tabIdx === selectedTabIndex,
                        this.getTabFromIdx(tabIdx)
                    );
                    actionPanelSetIsEnabled(
                        this.getTabFromIdx(tabIdx) !== RecEventListTabs.INACTIVE
                    );
                }}
            >
                {panes}
            </Tabs>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedFieldCount: cdSelectors.getSelectedFieldsCount(state),
    userInfo: getUser(state),
});

const mapDispatchToProps = (dispatch) => {
    return {
        actionPanelSetIsEnabled: (isEnabled) => dispatch(setIsEnabled(isEnabled)),
    };
};

export const FilterTabs = connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterTabs_));
