import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl, intlShape } from "react-intl";
import { Button } from "~/core";
import { prepareSelectableOptions, preventBubbleUp } from "~/admin/utils";
import { messages } from "../i18n-messages";
import {
    Checkbox,
    Section,
    SubSection,
    RadioButtonGroup,
    RadioButton,
    TextInput,
    TextArea,
} from "~/core";
import { SelectInput } from "~/core";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { connect } from "react-redux";
import { withMasked } from "~/hocs";
import "./location-migration.css";
import { actions as orgLevelActions, selectors as orgLevelSelectors } from "./../org-level/data";
import { actions, selectors } from "./data";
import TreeView from "~/core/components/tree-view/tree-view";
import EntitySearch from "./../batch-zapper/components/entity-search";
import { fetchLocationHierarchy } from "./../batch-zapper/data/actions";
import { getFilteredLocations } from "./../batch-zapper/data/selectors";
import { SUCCESS } from "~/hocs/needs/utils";
import { getTheUserGuid } from "~/login/selectors";
import { CloseIcon } from "~/core/icons";

export class LocationMigration_ extends Component {
    static propTypes = {
        agvanceDatabaseId: PropTypes.any,
        attributes: PropTypes.array,
        closeSlidingPanel: PropTypes.func,
        duplicateDatabaseIdOrgLevels: PropTypes.any,
        fetchAgvanceDatabaseId: PropTypes.func,
        fetchAttributes: PropTypes.func,
        fetchUserCompanyList: PropTypes.func,
        filteredLocations: PropTypes.any,
        getLocationEntities: PropTypes.func,
        getOrgLevelWithAgvanceDatabaseId: PropTypes.func,
        intl: intlShape.isRequired,
        invalidCheckResult: PropTypes.any,
        lastUsedOwnerGuid: PropTypes.object,
        locationEntities: PropTypes.array,
        migratedResults: PropTypes.object,
        migrationResults: PropTypes.any,
        needs: PropTypes.func,
        postInvalidLocationCheck: PropTypes.any,
        postMigration: PropTypes.any,
        postValidation: PropTypes.any,
        requestIds: PropTypes.object,
        saveAssignment: PropTypes.func,
        searchLocationHierarchy: PropTypes.any,
        setBreadcrumbs: PropTypes.func,
        setCompanyUserPreference: PropTypes.func,
        userCompanyList: PropTypes.array,
        validateResults: PropTypes.object,
        userGuid: PropTypes.any,
    };

    static migrationOptions = {
        location: 1,
        databaseId: 2,
        invalidCustomerFieldCheck: 3,
    };

    static parentNodeIndex = "0";

    constructor(props) {
        super(props);
        this.state = {
            locationEntities: [],
            node: {},
            tree: {},
            itemSelected: false,
            migrationOption: LocationMigration_.migrationOptions.location,
            validateString: "",
            isValidated: false,
            selectedLocations: [],
            isNonConsolidation: false,
            isInvalidDatabase: false,
        };
        this.customTree = {};
        this.customTreeIndex = 0;
        this.locationEntitySelectedRequestId = null;
        this.props.needs([this.props.getLocationEntities({})]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { itemSelected } = this.state;
        if (nextProps.locationEntities !== this.props.locationEntities) {
            this.setupLocationEntities(nextProps);
        } else if (this.locationEntitySelectedRequestId && itemSelected) {
            this.processLocationSearchRequest(nextProps);
        } else if (nextProps.validateResults !== this.props.validateResults) {
            this.setState({
                isValidated: true,
                validateString: this.getValidateResults(nextProps.validateResults),
            });
        } else if (nextProps.migrationResults !== this.props.migrationResults) {
            this.setState({
                validateString: this.getMigratedResults(nextProps.migrationResults),
            });
        } else if (nextProps.invalidCheckResult !== this.props.invalidCheckResult) {
            this.setState({
                validateString: this.getInvalidCheckResult(nextProps.invalidCheckResult),
            });
        } else if (
            nextProps.duplicateDatabaseIdOrgLevels !== this.props.duplicateDatabaseIdOrgLevels
        ) {
            this.setState({
                duplicateDatabaseIdOrgLevels: nextProps.duplicateDatabaseIdOrgLevels,
            });
        } else if (nextProps.agvanceDatabaseId !== this.state.agvanceDatabaseId) {
            this.setState({
                agvanceDatabaseId: nextProps.agvanceDatabaseId,
            });
        }
    }

    getMigratedResults = (migratedResults) => {
        const { formatMessage } = this.props.intl;
        if (!migratedResults.customers) {
            return formatMessage(messages.noDatabaseIdChange);
        } else {
            let unmatchedCustomersCount = 0,
                matchedCustomersCount = 0,
                unmatchedFieldsCount = 0,
                matchedFieldsCount = 0;
            const matchedResults = migratedResults.customers.reduce((v, i) => {
                if (i.isMatched) {
                    v += this._getMatchedCustomerName(i);
                    const matchedFields = i.fields
                        .filter(
                            (f) =>
                                f.isMatched &&
                                this._isAddtionalCustomerExists(i, f) &&
                                !this._getAdditionalAgvanceCustomer(f, i)
                        )
                        .map(
                            (v) =>
                                `Farm: ${v.farmName}, ${v.fieldName} (${
                                    v.activeYn ? "Active" : "Inactive"
                                })`
                        );
                    const matchedFieldsStr = matchedFields.join("\n\t");

                    const unMatchedFields = i.fields
                        .filter(
                            (f) =>
                                !f.isMatched &&
                                this._isAddtionalCustomerExists(i, f) &&
                                !this._getAdditionalAgvanceCustomer(f, i)
                        )
                        .map(
                            (v) =>
                                `Farm: ${v.farmName}, ${v.fieldName} (${
                                    v.activeYn ? "Active" : "Inactive"
                                })\n\tLog: ${v.log}\n`
                        );
                    const unMatchedFieldsStr = unMatchedFields.join("\n\t");

                    if (unMatchedFieldsStr.length > 0) {
                        v += `\t${formatMessage(
                            messages.newFieldsAdded
                        )}: \n\t${unMatchedFieldsStr}`;
                    }
                    if (matchedFieldsStr.length > 0) {
                        v += `\n\t${formatMessage(messages.linkedFields)}: \n\t${matchedFieldsStr}`;
                    }

                    matchedCustomersCount++;
                    matchedFieldsCount += matchedFields.length;
                    unmatchedFieldsCount += unMatchedFields.length;
                    const temp = this._getAdditionalAgvanceCustomersStr(
                        i,
                        matchedCustomersCount,
                        i.customerName
                    );
                    v += temp.str;
                    matchedCustomersCount = temp.matchedCustomersCount;
                } else {
                    // In some cases primary customer may have additional customers which are matched
                    // logging information about the additional customers here
                    const temp = this._getAdditionalAgvanceCustomersStr(
                        i,
                        matchedCustomersCount,
                        i.customerName,
                        unmatchedFieldsCount,
                        matchedFieldsCount
                    );
                    v += temp.str;
                    matchedCustomersCount = temp.matchedCustomersCount;
                    matchedFieldsCount = temp.matchedFieldsCount;
                    unmatchedFieldsCount = temp.unmatchedFieldsCount;
                    unmatchedCustomersCount++;
                }
                return v;
            }, "");
            let validateString =
                "\n-----------------------Summary--------------------------------\n";
            validateString += `\n${formatMessage(messages.totalCustomers)}: ${
                matchedCustomersCount + unmatchedCustomersCount
            }`;
            validateString += `\t\t${formatMessage(messages.totalFields)}: ${
                matchedFieldsCount + unmatchedFieldsCount
            }`;

            const isMigrationOptionDatabaseId =
                this.state.migrationOption === LocationMigration_.migrationOptions.databaseId;
            if (isMigrationOptionDatabaseId) {
                const inActiveCustomersCount = this._getInActiveCustomersCount(
                    migratedResults.customers
                );
                const inActiveFieldsCount = this._getInActiveFieldsCount(migratedResults.customers);
                const totalSecondaryCustomersCount = this._getTotalSecondaryCustomersCount(
                    migratedResults.customers
                );
                const totalMultiDatabaseCustomers = this._getTotalMultiDatabaseCustomersCount(
                    migratedResults.customers
                );
                validateString += `\n${formatMessage(
                    messages.totalMatchCustomers
                )}: ${matchedCustomersCount} \t${formatMessage(
                    messages.newFieldsAdded
                )}: ${unmatchedFieldsCount}`;
                validateString += `\n${formatMessage(
                    messages.totalUnmatchCustomers
                )}: ${unmatchedCustomersCount} \t${formatMessage(
                    messages.linkedFields
                )}: ${matchedFieldsCount}`;
                validateString += `\n${formatMessage(
                    messages.totalInactiveCustomers
                )}: ${inActiveCustomersCount} \t${formatMessage(
                    messages.totalInactiveFields
                )}: ${inActiveFieldsCount}`;
                validateString += `\n${formatMessage(
                    messages.totalSecondaryCustomers
                )}: ${totalSecondaryCustomersCount} \t${formatMessage(
                    messages.totalMultiDatabaseCustomers
                )}: ${totalMultiDatabaseCustomers}`;
            }
            validateString += "\n--------------------------------------------------------------\n";

            validateString += "\n-----------------------Details---------------------------------\n";
            validateString += `${formatMessage(messages.customerName)}: \n`;
            validateString += matchedResults || "";
            validateString +=
                "\n----------------------------------------------------------------\n";
            return validateString;
        }
    };

    getValidateResults = (validateResults) => {
        const { formatMessage } = this.props.intl;
        if (!validateResults.customers) {
            return formatMessage(messages.noDatabaseIdChange);
        } else {
            let unmatchedCustomersCount = 0,
                matchedCustomersCount = 0,
                unmatchedFieldsCount = 0,
                matchedFieldsCount = 0;
            const unMatchedResults = validateResults.customers.reduce((acc, v) => {
                const addnCustomers = v.additionalAgvanceCustomers
                    .filter((a) => !a.isMatched)
                    .map((a) => a.agvanceGrowerName);
                if (!v.isMatched) {
                    // Increase the unmatched customer length by 1 and add additional unmatched customer
                    // count
                    unmatchedCustomersCount = ++unmatchedCustomersCount + addnCustomers.length;
                    acc += `${v.customerName}\n${addnCustomers.join("\n\t")}\n`;
                    const unMatchedFields = v.fields
                        .filter((f) => !f.isMatched)
                        .map((f) => {
                            return `\tFarm: ${f.farmName}, ${f.fieldName}\n`;
                        });
                    const unMatchedFieldsStr = unMatchedFields.join("");
                    if (unMatchedFieldsStr.length > 0) {
                        acc += `${unMatchedFieldsStr}`;
                    }
                } else {
                    unmatchedCustomersCount += addnCustomers.length;
                }
                return acc;
            }, "");
            const matchedResults = validateResults.customers.reduce((v, i) => {
                if (i.isMatched) {
                    v += this._getMatchedCustomerName(i);
                    const matchedFields = i.fields
                        .filter(
                            (f) =>
                                f.isMatched &&
                                this._isAddtionalCustomerExists(i, f) &&
                                !f.isAdditionalAgvanceCustomer
                        )
                        .map(
                            (f) =>
                                `Farm: ${f.farmName}, ${f.fieldName} (${
                                    f.activeYn ? "Active" : "Inactive"
                                })`
                        );
                    const matchedFieldsStr = matchedFields.join("\n\t");
                    const unMatchedFields = i.fields
                        .filter(
                            (f) =>
                                !f.isMatched &&
                                this._isAddtionalCustomerExists(i, f) &&
                                !f.isAdditionalAgvanceCustomer
                        )
                        .map(
                            (f) =>
                                `Farm: ${f.farmName}, ${f.fieldName} (${
                                    f.activeYn ? "Active" : "Inactive"
                                })\n\tLog: ${f.log}\n`
                        );
                    const unMatchedFieldsStr = unMatchedFields.join("\n\t");
                    if (unMatchedFieldsStr.length > 0) {
                        v += `\t${formatMessage(
                            messages.unmatchFields
                        )}: \n\t${unMatchedFieldsStr}`;
                    }
                    if (matchedFieldsStr.length > 0) {
                        if (
                            this.state.migrationOption ===
                            LocationMigration_.migrationOptions.databaseId
                        ) {
                            v += `\n\t${formatMessage(
                                messages.matchFields
                            )}: \n\t${matchedFieldsStr}`;
                        } else {
                            v += `\n\t${formatMessage(messages.fields)}: \n\t${matchedFieldsStr}`;
                        }
                    }
                    matchedCustomersCount++;
                    matchedFieldsCount += matchedFields.length;
                    unmatchedFieldsCount += unMatchedFields.length;
                    const temp = this._getAdditionalAgvanceCustomersStr(
                        i,
                        matchedCustomersCount,
                        i.customerName
                    );
                    v += temp.str;
                    matchedCustomersCount = temp.matchedCustomersCount;
                } else {
                    // In some cases primary customer may have additional customers which are matched
                    // logging information about the additional customers here
                    const temp = this._getAdditionalAgvanceCustomersStr(
                        i,
                        matchedCustomersCount,
                        i.customerName,
                        unmatchedFieldsCount,
                        matchedFieldsCount
                    );
                    v += temp.str;
                    matchedCustomersCount = temp.matchedCustomersCount;
                    matchedFieldsCount = temp.matchedFieldsCount;
                    unmatchedFieldsCount = temp.unmatchedFieldsCount;
                }
                return v;
            }, "");
            let validateString =
                "\n-----------------------Summary--------------------------------\n";

            if (!validateResults.databaseConnectionStatus) {
                validateString += `\n${formatMessage(messages.migrateFromDatabaseStatus)}: ${
                    validateResults.databaseConnectionLog
                }`;
            }

            validateString += `\n${formatMessage(messages.totalCustomers)}: ${
                matchedCustomersCount + unmatchedCustomersCount
            }`;
            validateString += `\t\t${formatMessage(messages.totalFields)}: ${
                matchedFieldsCount + unmatchedFieldsCount
            }`;

            const isMigrationOptionDatabaseId =
                this.state.migrationOption === LocationMigration_.migrationOptions.databaseId;
            if (isMigrationOptionDatabaseId) {
                const inActiveCustomersCount = this._getInActiveCustomersCount(
                    validateResults.customers
                );
                const inActiveFieldsCount = this._getInActiveFieldsCount(validateResults.customers);
                const totalSecondaryCustomersCount = this._getTotalSecondaryCustomersCount(
                    validateResults.customers
                );
                const totalMultiDatabaseCustomers = this._getTotalMultiDatabaseCustomersCount(
                    validateResults.customers
                );
                validateString += `\n${formatMessage(
                    messages.totalMatchCustomers
                )}: ${matchedCustomersCount} \t${formatMessage(
                    messages.totalmatchFields
                )}: ${matchedFieldsCount}`;
                validateString += `\n${formatMessage(
                    messages.totalUnmatchCustomers
                )}: ${unmatchedCustomersCount} \t${formatMessage(
                    messages.totalUnmatchFields
                )}: ${unmatchedFieldsCount}`;
                validateString += `\n${formatMessage(
                    messages.totalInactiveCustomers
                )}: ${inActiveCustomersCount} \t${formatMessage(
                    messages.totalInactiveFields
                )}: ${inActiveFieldsCount}`;
                validateString += `\n${formatMessage(
                    messages.totalSecondaryCustomers
                )}: ${totalSecondaryCustomersCount} \t${formatMessage(
                    messages.totalMultiDatabaseCustomers
                )}: ${totalMultiDatabaseCustomers}`;
            }

            validateString += "\n--------------------------------------------------------------\n";

            validateString += "\n-----------------------Details---------------------------------\n";
            validateString +=
                this.state.migrationOption === LocationMigration_.migrationOptions.databaseId
                    ? `${formatMessage(messages.matchCustomers)}:`
                    : "";
            validateString += matchedResults || "";
            validateString +=
                this.state.migrationOption === LocationMigration_.migrationOptions.databaseId
                    ? `\n\n${formatMessage(messages.unmatchCustomers)}: \n\n`
                    : "";
            validateString += unMatchedResults || "";
            validateString +=
                "\n----------------------------------------------------------------\n";
            return validateString;
        }
    };

    getInvalidCheckResult = (result) => {
        const { formatMessage } = this.props.intl;
        let validateString = "";
        validateString += result.locations.reduce((acc, item, idx) => {
            if (idx === 0) {
                acc = `${formatMessage(messages.invalidLocations)}:\n`;
            }
            return `${acc}\t${item.orgLevelName}\n`;
        }, "");
        const groupByCustomers = _.groupBy(result.customers, "orgLevelName");
        validateString += `${formatMessage(messages.invalidCustomers)}:\n`;
        for (let loc in groupByCustomers) {
            let str = `\t${loc}`;
            str += groupByCustomers[loc].reduce((acc, item) => {
                return `${acc}\t\t${item.customerName}\n`;
            }, "\n");
            validateString += str;
        }

        const groupByFields = _.groupBy(result.fields, "orgLevelName");
        validateString += `${formatMessage(messages.invalidFields)}:\n`;
        for (let loc in groupByFields) {
            let str = `\t${loc}`;
            str += groupByFields[loc].reduce((acc, item) => {
                return `${acc}\t\t${item.agvanceCustomerName} - ${item.farmName} - ${item.fieldName}\n`;
            }, "\n");
            validateString += str;
        }
        return validateString;
    };

    _isAddtionalCustomerExists = (customer, field) => {
        let flag = true;
        if (field.isAdditionalAgvanceCustomer) {
            flag = customer.additionalAgvanceCustomers.some(
                (add) => add.agvanceCustomerGuid === field.agvanceCustomerGuid
            );
        }
        return flag;
    };

    _getInActiveCustomersCount = (customers) => {
        const f = customers.filter((c) => !c.activeYn);
        if (Array.isArray(f)) {
            return f.length;
        }
        return 0;
    };

    _getInActiveFieldsCount = (customers) => {
        return customers.reduce((acc, item) => {
            const t = item.fields.filter((f) => !f.activeYn);
            if (Array.isArray(t)) {
                acc += t.length;
            }
            return acc;
        }, 0);
    };

    _getTotalSecondaryCustomersCount = (customers) => {
        return customers.reduce((acc, item) => {
            if (Array.isArray(item.additionalAgvanceCustomers)) {
                acc += item.additionalAgvanceCustomers.length;
            }
            return acc;
        }, 0);
    };

    _getTotalMultiDatabaseCustomersCount = (customers) => {
        return customers.reduce((acc, item) => {
            item.additionalAgvanceCustomers.forEach((u) => {
                if (u.isDuplicateCustomer) acc++;
            });
            return acc;
        }, 0);
    };

    _getMatchedCustomerName = (customer) => {
        const { formatMessage } = this.props.intl;
        if (!customer.unmatchedPrimaryCustomer) {
            return `\n\n${customer.customerName} (${formatMessage(messages.primary)})\n`;
        }
        return `\n\n${customer.customerName} (${formatMessage(messages.secondaryOf)} ${
            customer.unmatchedPrimaryCustomer.agvanceGrowerName
        })\n`;
    };

    _getAdditionalAgvanceCustomersStr = (
        customer,
        matchedCustomersCount,
        primaryCustomerName,
        unmatchedFieldsCount,
        matchedFieldsCount
    ) => {
        if (!customer.additionalAgvanceCustomers.length) {
            return {
                str: "",
                matchedCustomersCount,
                matchedFieldsCount,
                unmatchedFieldsCount,
            };
        }
        const { formatMessage } = this.props.intl;
        return customer.additionalAgvanceCustomers.reduce((v, i) => {
            if (!i.isMatched) {
                return {
                    str: v,
                    matchedCustomersCount,
                    matchedFieldsCount,
                    unmatchedFieldsCount,
                };
            }

            v += `\n\n${i.agvanceGrowerName} (${formatMessage(
                messages.secondaryOf
            )} ${primaryCustomerName})\n`;

            if (i.isDuplicateCustomer) {
                v += `(${formatMessage(messages.duplicateCustomerMsg)})\n`;
            }
            const fields = customer.fields.filter(
                (f) =>
                    f.agvanceCustomerGuid === i.agvanceCustomerGuid && f.isAdditionalAgvanceCustomer
            );
            const matchedFields = fields
                .filter((f) => f.isMatched)
                .map((f) => {
                    return `Farm: ${f.farmName}, ${f.fieldName} (${
                        f.activeYn ? "Active" : "Inactive"
                    })`;
                });
            matchedFieldsCount += matchedFields.length;
            const matchedFieldsStr = matchedFields.join("\n\t");
            const unMatchedFields = fields
                .filter((f) => !f.isMatched)
                .map((f) => {
                    return `Farm: ${f.farmName}, ${f.fieldName} (${
                        f.activeYn ? "Active" : "Inactive"
                    })\n\tLog: ${f.log}\n`;
                });
            unmatchedFieldsCount += unMatchedFields.length;
            const unMatchedFieldsStr = unMatchedFields.join("\n\t");
            if (unMatchedFieldsStr.length > 0) {
                v += `\t${formatMessage(messages.unmatchFields)}: \n\t${unMatchedFieldsStr}`;
            }
            if (matchedFieldsStr.length > 0) {
                if (this.state.migrationOption === LocationMigration_.migrationOptions.databaseId) {
                    v += `\n\t${formatMessage(messages.matchFields)}: \n\t${matchedFieldsStr}`;
                } else {
                    v += `\n\t${formatMessage(messages.fields)}: \n\t${matchedFieldsStr}`;
                }
            }
            matchedCustomersCount++;
            return {
                str: v,
                matchedCustomersCount,
                matchedFieldsCount,
                unmatchedFieldsCount,
            };
        }, "");
    };

    _getAdditionalAgvanceCustomer = (field, customer) => {
        let additionalAgvanceCustomer = null;
        if (field.isAdditionalAgvanceCustomer) {
            const ac = customer.additionalAgvanceCustomers.find(
                (ac) => ac.agvanceCustomerGuid === field.agvanceCustomerGuid
            );
            if (ac) {
                additionalAgvanceCustomer = ac.agvanceGrowerName;
            }
        }
        return additionalAgvanceCustomer;
    };

    setupLocationEntities = ({ locationEntities }) => {
        const { node, tree } = this.state;
        let newLocationEntities = [...locationEntities];
        if (node.nodeIndex) {
            const clickedNode = _.get(tree, node.nodeIndex) || null;
            if (clickedNode) {
                if (clickedNode.isLocalAssigned) {
                    newLocationEntities = newLocationEntities.map((loc) => ({
                        ...loc,
                        isLocalAssigned: true,
                    }));
                }
                clickedNode.entityChildren = newLocationEntities;
                _.set(tree, node.nodeIndex, clickedNode);
                this.setState({
                    locationEntities: tree,
                });
            }
        } else {
            this.setState({
                locationEntities: newLocationEntities.map((t) => ({
                    ...t,
                    isLocalAssigned: false,
                })),
            });
        }
    };

    expandParentEntity = ({ node, tree }) => {
        const { entityGuid } = node;
        if (!node.entityChildren.length) {
            this.props.needs([this.props.getLocationEntities({ entityGuid })]);
        }
        this.setState({
            node,
            tree,
        });
    };

    buildCustomTree(data, customTreeIndex) {
        if (_.isEmpty(this.customTree)) {
            this.customTree = { ...data, isOpen: true };
        } else {
            let path = "entityChildren";
            for (let index = 0; index < customTreeIndex - 1; index++) {
                path = `${path}[0].entityChildren`;
            }
            _.set(this.customTree, path, [{ ...data, isOpen: true }]);
        }
        return this.customTree;
    }

    processLocationSearchRequest = (nextProps) => {
        if (nextProps.requestIds[this.locationEntitySelectedRequestId] === SUCCESS) {
            if (nextProps.locationEntities[0]) {
                const { entityGuid, hasChildren } = nextProps.locationEntities[0];
                const { orgLevelGuid, orgLevelName } = this.state;
                // Add response to the last tree node
                this.customTree = this.buildCustomTree(
                    nextProps.locationEntities[0],
                    this.customTreeIndex
                );
                if (hasChildren) {
                    // Request new children to complete the tree
                    this.locationEntitySelectedRequestId = this.props.needs([
                        this.props.getLocationEntities({
                            entityGuid,
                            selectionGuid: orgLevelGuid,
                            searchValue: orgLevelName,
                        }),
                    ]);
                    this.customTreeIndex++;
                    return;
                }
            }
            // Since the last request didn't return any children complete the custom tree
            this.locationEntitySelectedRequestId = null;
            this.setState({
                locationEntities: [{ ...this.customTree }],
            });
        }
    };

    resetTree = () => {
        this.props.needs([this.props.getLocationEntities({})]);
        this.setState({
            locationEntities: [],
            node: {},
            tree: {},
            itemSelected: false,
        });
        this.customTree = {};
        this.customTreeIndex = 0;
        this.locationEntitySelectedRequestId = null;
        this.currentNodeClicked = null;
    };

    onItemSelection = ({ orgLevelGuid, orgLevelName }) => {
        if (!orgLevelGuid) {
            this.resetTree();
            return;
        }
        this.setState({
            itemSelected: true,
            orgLevelGuid,
            orgLevelName,
        });
        this.locationEntitySelectedRequestId = this.props.needs([
            this.props.getLocationEntities({
                selectionGuid: orgLevelGuid,
                searchValue: orgLevelName,
            }),
        ]);
    };

    isChildAssigned(data) {
        for (const key of Object.keys(data.entityChildren)) {
            const currNode = data.entityChildren[key];
            if (
                currNode.hasChildren &&
                !currNode.entityChildren.length &&
                currNode.isChildAssignedNew
            ) {
                return true;
            }
            if (this.isChildAssigned(currNode)) {
                return true;
            }
        }
        return data.isAssignedNew;
    }

    getIsAssigned = (data) => {
        if (!data.isAssignedNew && data.hasChildren && this.currentNodeClicked !== data.nodeIndex) {
            if (!data.entityChildren.length) {
                return data.isChildAssignedNew;
            }
            return this.isChildAssigned(data);
        }
        return data.isAssignedNew;
    };

    onPermissionChange = (data, value) => {
        if (data) {
            const { locationEntities, selectedLocations } = this.state;
            let tempSelectedLocations = selectedLocations;
            this.currentNodeClicked = data.nodeIndex;
            const updatedLocationEntities = this.selectChildren(data, value);
            _.set(locationEntities, data.nodeIndex, updatedLocationEntities);
            this.setState({
                locationEntities,
                originalOrgLevelGuid: data.entityGuid,
                isValidated: false,
                validateString: "",
            });
            if (value) {
                tempSelectedLocations.push(data);
            } else {
                const deleteEleIdx = tempSelectedLocations.findIndex(
                    (v) => v.entityGuid === data.entityGuid
                );
                if (deleteEleIdx > -1) {
                    tempSelectedLocations.splice(deleteEleIdx, 1);
                }
                // Delete all selections in case top parent on unselection
                if (data.nodeIndex === LocationMigration_.parentNodeIndex) {
                    tempSelectedLocations = [];
                } else {
                    const modifiedTempSelectedLocations = [...tempSelectedLocations];
                    // Delete Children in case of a parent unselection
                    tempSelectedLocations.forEach((value) => {
                        const matchedChild = data.entityChildren.find(
                            (ec) => ec.entityGuid === value.entityGuid
                        );
                        if (matchedChild) {
                            const matchedChildIdx = modifiedTempSelectedLocations.findIndex(
                                (ec) => ec.entityGuid === value.entityGuid
                            );
                            modifiedTempSelectedLocations.splice(matchedChildIdx, 1);
                        }
                    });
                    tempSelectedLocations = modifiedTempSelectedLocations;
                }
            }
            if (tempSelectedLocations.length === 1) {
                if (this.state.migrationOption === LocationMigration_.migrationOptions.location) {
                    this.props.needs([
                        this.props.getOrgLevelWithAgvanceDatabaseId(
                            tempSelectedLocations[0].entityGuid
                        ),
                    ]);
                } else {
                    this.props.needs([
                        this.props.fetchAgvanceDatabaseId(tempSelectedLocations[0].entityGuid),
                    ]);
                }
            }
            this.setState({
                agvanceDatabaseId: "",
                duplicateDatabaseIdOrgLevels: [],
                selectedLocations: tempSelectedLocations,
            });
        }
    };

    selectChildren = (data, value) => {
        if (data) {
            data.isAssigned = value;
            data.isLocalAssigned = value;
        }
        if (data && data.hasChildren && data.entityChildren.length > 0) {
            for (const key of Object.keys(data.entityChildren)) {
                data.entityChildren[key].isAssigned = value;
                data.entityChildren[key].isLocalAssigned = value;
                this.selectChildren(data.entityChildren[key], value);
            }
        }
        return data;
    };

    renderCheckbox = (data) => {
        const checkedProp = { value: data.isLocalAssigned };
        return (
            <Checkbox
                onChange={(e, value) => this.onPermissionChange(data, value)}
                {...checkedProp}
            />
        );
    };

    _toggleRadioButton = (value) => {
        this.setState(
            {
                migrationOption: value,
                isValidated: false,
                validateString: "",
                modifiedOrgLevelGuid: "",
            },
            () => {
                if (this.state.originalOrgLevelGuid && this.state.selectedLocations.length === 1) {
                    if (
                        this.state.migrationOption === LocationMigration_.migrationOptions.location
                    ) {
                        this.props.needs([
                            this.props.getOrgLevelWithAgvanceDatabaseId(
                                this.state.originalOrgLevelGuid
                            ),
                        ]);
                    } else if (
                        this.state.migrationOption ===
                        LocationMigration_.migrationOptions.databaseId
                    ) {
                        this.props.needs([
                            this.props.fetchAgvanceDatabaseId(this.state.originalOrgLevelGuid),
                        ]);
                    }
                }
            }
        );
    };

    renderLocationOption = (intl) => {
        const { formatMessage } = intl;
        const ORG_LEVEL_GUID = "orgLevelGuid";
        const ORG_LEVEL_NAME = "orgLevelName";
        const _duplicateDatabsaeIdOrgLevels = prepareSelectableOptions(
            this.state.duplicateDatabaseIdOrgLevels,
            { guid: ORG_LEVEL_GUID, label: ORG_LEVEL_NAME }
        );
        return (
            <div className="migrate-options-cont">
                <div className="migrate-options-label">
                    {formatMessage(messages.selectMoveToLocation)}
                </div>
                <SelectInput
                    clearable={false}
                    options={_duplicateDatabsaeIdOrgLevels}
                    value=""
                    onChange={(value) => {
                        this.setState({
                            modifiedOrgLevelGuid: value.guid,
                        });
                    }}
                    placeholderText={formatMessage(messages.migrateCustomerToLocation)}
                    containerClassNames={["form-section-child-stretch"]}
                    required
                />
            </div>
        );
    };

    renderDatabaseIdOption = (intl) => {
        const { formatMessage } = intl;
        return (
            <div className="migrate-options-cont">
                <div className="migrate-options-label">
                    {formatMessage(messages.enterMigrateToDatabaseId)}
                </div>
                <TextInput
                    containerClassNames={["form-section-child-stretch"]}
                    placeholderText={formatMessage(messages.databaseId)}
                    maxLength={100}
                    onChange={(value) => {
                        this.setState({
                            modifiedAgvanceDatabaseId: value,
                        });
                    }}
                    value={this.state.agvanceDatabaseId}
                />
                <div className="migrate-options-checkboxes">
                    <Checkbox
                        value={this.state.isNonConsolidation}
                        onChange={(e, value) => {
                            this.setState({ isNonConsolidation: value });
                        }}
                        label={formatMessage(messages.nonConsolidation)}
                    />
                    <Checkbox
                        value={this.state.isInvalidDatabase}
                        onChange={(e, value) => {
                            this.setState({ isInvalidDatabase: value });
                        }}
                        label={formatMessage(messages.sourceDatabaseInvalid)}
                    />
                </div>
            </div>
        );
    };

    renderSubmitOptions = () => {
        return (
            <div className="submit-options">
                <Button
                    type="validate"
                    onClick={() => {
                        this.setState(
                            {
                                validateString: "",
                                isUnmatchedCustomerExists: false,
                            },
                            () => {
                                if (
                                    this.state.migrationOption ===
                                    LocationMigration_.migrationOptions.invalidCustomerFieldCheck
                                ) {
                                    this.props.needs([
                                        this.props.postInvalidLocationCheck({
                                            orgLevelGuids: this.state.selectedLocations.map(
                                                (sl) => sl.entityGuid
                                            ),
                                        }),
                                    ]);
                                } else {
                                    this.props.needs([
                                        this.props.postValidation({
                                            originalOrgLevelGuid: this.state.originalOrgLevelGuid,
                                            modifiedOrgLevelGuid: this.state.modifiedOrgLevelGuid,
                                            newAgvanceDatabaseId:
                                                this.state.modifiedAgvanceDatabaseId,
                                            originalOrgLevelGuids: this.state.selectedLocations.map(
                                                (sl) => sl.entityGuid
                                            ),
                                            isNonConsolidation: this.state.isNonConsolidation,
                                            isInvalidDatabase: this.state.isInvalidDatabase,
                                        }),
                                    ]);
                                }
                            }
                        );
                    }}
                />
                {this.state.migrationOption !==
                    LocationMigration_.migrationOptions.invalidCustomerFieldCheck && (
                    <Button
                        className="migrate-button"
                        type="migrate"
                        onClick={() => {
                            this.setState({ validateString: "" }, () => {
                                this.props.needs([
                                    this.props.postMigration({
                                        originalOrgLevelGuid: this.state.originalOrgLevelGuid,
                                        modifiedOrgLevelGuid: this.state.modifiedOrgLevelGuid,
                                        newAgvanceDatabaseId: this.state.modifiedAgvanceDatabaseId,
                                        originalOrgLevelGuids: this.state.selectedLocations.map(
                                            (sl) => sl.entityGuid
                                        ),
                                        isNonConsolidation: this.state.isNonConsolidation,
                                        isInvalidDatabase: this.state.isInvalidDatabase,
                                    }),
                                ]);
                            });
                        }}
                        disabled={!this.state.isValidated}
                    />
                )}
            </div>
        );
    };

    renderLocationMigration = ({ intl }) => {
        const { formatMessage } = intl;
        return (
            <div className="section-container">
                <Section className="select-location-section">
                    <div className="select-location-label">
                        {formatMessage(messages.selectMigrateFromLocations)}
                    </div>
                    <div>
                        <EntitySearch
                            labelKey="orgLevelName"
                            onItemSelection={this.onItemSelection}
                            placeholderText={formatMessage(messages.searchLocation)}
                            searchEntity={this.props.searchLocationHierarchy}
                            onSearchChange={this.onSearchChange}
                            filteredData={this.props.filteredLocations}
                            selectedCompany={{
                                userGuid: this.props.userGuid,
                                companyGuid: "",
                            }}
                        />
                        <TreeView
                            rowRenderer={this.renderCheckbox}
                            data={this.state.locationEntities}
                            onNodeClicked={!this.state.itemSelected && this.expandParentEntity}
                            displayLabel="entityName"
                            childrenKey="entityChildren"
                        />
                    </div>
                </Section>
                <span className="bar section-fence" />
                <div className="section-container section-column migrate-options-section">
                    <Section className="grid-section">
                        <SubSection>
                            <RadioButtonGroup
                                className={"radio-button-group"}
                                selectedValue={this.state.migrationOption}
                                afterOnChange={this._toggleRadioButton}
                            >
                                <RadioButton
                                    className="migrate-radio"
                                    value={LocationMigration_.migrationOptions.location}
                                    label={formatMessage(messages.moveToLocationOrg)}
                                ></RadioButton>
                                <RadioButton
                                    className="migrate-radio"
                                    value={LocationMigration_.migrationOptions.databaseId}
                                    label={formatMessage(messages.databaseIdChange)}
                                ></RadioButton>
                                <RadioButton
                                    className="migrate-radio"
                                    value={
                                        LocationMigration_.migrationOptions
                                            .invalidCustomerFieldCheck
                                    }
                                    label={formatMessage(messages.invalidCustomerFieldCheck)}
                                ></RadioButton>
                            </RadioButtonGroup>
                        </SubSection>
                    </Section>
                    <Section className="grid-section migrate-options-textarea-cont">
                        {this.state.migrationOption !==
                            LocationMigration_.migrationOptions.invalidCustomerFieldCheck && (
                            <SubSection>
                                {this.state.migrationOption ===
                                LocationMigration_.migrationOptions.location
                                    ? this.renderLocationOption(intl)
                                    : this.renderDatabaseIdOption(intl)}
                            </SubSection>
                        )}
                        <SubSection>{this.renderSubmitOptions()}</SubSection>
                        <SubSection className="migrate-options-textarea">
                            <TextArea
                                placeholderText={formatMessage(messages.validationOutput)}
                                labelText={formatMessage(messages.validationOutput)}
                                value={this.state.validateString}
                                readOnly
                            />
                        </SubSection>
                    </Section>
                </div>
            </div>
        );
    };

    render() {
        /// Property set up, etc. ///
        return (
            <div className="location-migration-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    <SlidingPanel
                        component={this.renderLocationMigration}
                        navigateTo={{ parentNameCode: 101, childNameCode: 256 }}
                    >
                        <span
                            className="close-button-container"
                            onClick={this.props.closeSlidingPanel}
                        >
                            <CloseIcon />
                        </span>
                    </SlidingPanel>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    orgLevelWithGuid: orgLevelSelectors.getOrgLevelWithGuid(state),
    locationEntities: selectors.getLocationEntities(state),
    filteredLocations: getFilteredLocations(state),
    agvanceDatabaseId: selectors.getAgvanceDatabaseId(state),
    duplicateDatabaseIdOrgLevels: orgLevelSelectors.getDuplicateDatabaseIdOrgLevels(state),
    validateResults: selectors.getValidateResults(state),
    migrationResults: selectors.getMigrationResults(state),
    userGuid: getTheUserGuid(state),
    invalidCheckResult: selectors.getInvalidCheckResult(state),
});

const mapDispatchToProps = (dispatch) => ({
    getLocationEntities: (model) => actions.getLocations(model),
    searchLocationHierarchy: (searchedVal, companyUserGuid) =>
        dispatch(
            fetchLocationHierarchy({
                searchedVal,
                companyUserGuid,
            })
        ),
    getOrgLevelWithAgvanceDatabaseId: (orgLevelGuid) =>
        orgLevelActions.getOrgLevelWithAgvanceDatabaseId(orgLevelGuid),
    fetchAgvanceDatabaseId: (orgLevelGuid) => actions.fetchAgvanceDatabaseId(orgLevelGuid),
    postValidation: (model) => actions.postValidation(model),
    postMigration: (model) => actions.postMigration(model),
    postInvalidLocationCheck: (model) => actions.postInvalidLocationCheck(model),
});

export const LocationMigration = withMasked(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(LocationMigration_))
);
