import React, { Component } from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { getTheUserGuid } from "~/login";
import * as actions from "../actions";
import { getIsLoading, getNotificationList } from "../selectors";
import { Bucket, BucketHeader, Loader, NoLink } from "~/core";
import { ErrorDetailsDialog } from "~/action-panel/components/common/error-details-dialog/error-details-dialog";
import { messages } from "./i18n-messages";
import { getUserPreference } from "~/admin/setup/preference/data/selectors";
import { pdfUrl, NotificationAPI, PreferenceAPI } from "@ai360/core";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import "./notifications.css";

interface INotificationsProps {
    clearBreadcrumbs: () => void;
    intl?: Record<string, any>;
    isLoading: boolean;
    loggedInUserGuid: string;
    notificationList: NotificationAPI.INotification[];
    fetchUserNotifications: () => void;
    openSamplingLayer: (fieldGuid: string, agEventGeneralGuid: string) => void;
    userPreference: PreferenceAPI.IUserPreference;
}

interface INotificationsState {
    isTodayExpanded: boolean;
    isYesterdayExpanded: boolean;
    isThisWeekExpanded: boolean;
    isThirtyDaysExpanded: boolean;
    reportErrorDetailsToShow: string;
}

class FormattedNotification {
    constructor(
        public dateTime: string,
        public detail: NotificationAPI.INotificationDetail,
        public title: string,
        public description: string,
        public fileNames: string[],
        public reports: NotificationAPI.INotificationReport[],
        public type: string,
        public read: boolean
    ) {}
}

const REPORT_COMPLETED = "reportCompleted";
const REPORT_FAILED = "reportFailed";
const SAMPLE_RESULTS_IMPORTED = "sampleResultsImported";
const WATER_SAMPLING_RESULTS_IMPORTED = "waterSamplingResultsImported";
const SAMPLE_RESULTS_FAILED = "sampleResultsImportFailed";
class Notifications_ extends Component<
    INotificationsProps & InjectedIntlProps,
    INotificationsState
> {
    constructor(props) {
        super(props);
        this.state = {
            isTodayExpanded: false,
            isYesterdayExpanded: false,
            isThisWeekExpanded: false,
            isThirtyDaysExpanded: false,
            reportErrorDetailsToShow: null,
        };
    }

    componentDidMount() {
        this.props.fetchUserNotifications();
        this.#toggleFirstBucket();
        this.props.clearBreadcrumbs();
    }

    #formatNotifications(
        notificationList: NotificationAPI.INotification[]
    ): FormattedNotification[] {
        const formattedList = notificationList
            .sort((n1, n2) =>
                n1.timestamp < n2.timestamp ? 1 : n1.timestamp === n2.timestamp ? 0 : -1
            )
            .map((n) => {
                const isReport =
                    n.detail?.type === REPORT_COMPLETED || n.detail?.type === REPORT_FAILED;
                const title = this.#getTitle(n.detail?.type);
                const description = this.#getDescription(n.detail);
                const fileNames = isReport ? this.#getReportFileNames(n.detail) : [];
                const reports = isReport ? this.#getReports(n.detail) : [];

                return new FormattedNotification(
                    moment(n.timestamp).format("M/D/YY - h:mm a"),
                    n.detail,
                    title,
                    description,
                    fileNames,
                    reports,
                    n.detail.type,
                    n.read
                );
            });
        return formattedList;
    }

    #getDescription(detail: NotificationAPI.INotificationDetail): string {
        const { formatMessage } = this.props.intl;
        switch (detail.type) {
            case REPORT_COMPLETED:
                return `${formatMessage(messages.reportClick)}: `;
            case REPORT_FAILED:
                return `${formatMessage(messages.reportFailedClick)}: `;
            case SAMPLE_RESULTS_IMPORTED:
                return `${formatMessage(messages.samplingClick)}: `;
            case WATER_SAMPLING_RESULTS_IMPORTED:
                return `${formatMessage(messages.waterSamplingClick)}: `;
            case SAMPLE_RESULTS_FAILED:
                return (detail as NotificationAPI.INotificationSampleResultsImportedFailed).title;
            default:
                return "";
        }
    }

    #getReports(
        detail: NotificationAPI.INotificationReportGroup
    ): NotificationAPI.INotificationReport[] {
        if (detail.type === REPORT_COMPLETED) {
            return detail.reports;
        } else if (detail.type === REPORT_FAILED) {
            return detail.reportsFailed;
        }
        return [];
    }

    #getReportLink(f: string, id: string): JSX.Element {
        return (
            <span className="click-me-link" key={`info-${id}`}>
                <a
                    href={this.#getReportViewerUrl(f)}
                    target={"_blank"}
                    rel="noopener noreferrer"
                    title={f}
                >
                    {f}
                </a>
            </span>
        );
    }

    #getReportFileNames(detail: NotificationAPI.INotificationDetail): string[] {
        if (detail.type === REPORT_COMPLETED) {
            const { reports } = detail as NotificationAPI.INotificationReportGroup;
            return reports?.map((r) => r.pdfLink);
        } else if (detail.type === REPORT_FAILED) {
            const { reportsFailed } = detail as NotificationAPI.INotificationReportGroup;
            return reportsFailed?.map((r) => r.pdfLink);
        }
        return [];
    }

    #getSamplingNames(detail: NotificationAPI.INotificationDetail): string {
        const { customer, field } = detail as NotificationAPI.INotificationSampleResultsImported;
        const farmField = field.farmName ? `${field.farmName} - ${field.name}` : field.name;
        return `${customer.name} - ${farmField}`;
    }

    #getListLinks(n: FormattedNotification): JSX.Element | JSX.Element[] {
        switch (n.type) {
            case REPORT_COMPLETED:
                return n.fileNames.map((f, idx2) => this.#getReportLink(f, idx2.toString()));
            case REPORT_FAILED:
                return n.reports.map((r) =>
                    this.#renderErrorInReportLabel(r.pdfLink, r.reportGuid)
                );
            case SAMPLE_RESULTS_IMPORTED:
                return this.#getSamplingLink(
                    n.detail as NotificationAPI.INotificationSampleResultsImported
                );
            case WATER_SAMPLING_RESULTS_IMPORTED:
                return this.#getWaterSamplingLabel(
                    n.detail as NotificationAPI.INotificationWaterSamplingResultsImported
                );
            case SAMPLE_RESULTS_FAILED:
                return null;
            default:
                return null;
        }
    }

    #getListItem(n: FormattedNotification, idx: number): JSX.Element {
        const { userPreference } = this.props;
        const isReport =
            n.detail?.type &&
            (n.detail?.type === REPORT_COMPLETED || n.detail?.type === REPORT_FAILED);
        const isSamplingImport =
            n.type === SAMPLE_RESULTS_IMPORTED ||
            n.type === SAMPLE_RESULTS_FAILED ||
            n.type === WATER_SAMPLING_RESULTS_IMPORTED;
        const userPreferenceValue = isReport
            ? userPreference.receiveWebNotificationsForReports
            : isSamplingImport
            ? userPreference.receiveWebNotificationsForSamplingResults
            : false;
        const showNewTag = !n.read && userPreferenceValue;
        const listClasses = isReport ? "list-item report-list" : "list-item";
        return (
            <div key={`notification-list-item-${idx}`}>
                {!showNewTag ? null : <div className="new-tag">New</div>}
                <Bucket key={`info-${idx}`}>
                    <BucketHeader className="list-bucket-header">
                        {`${n.dateTime} - ${n.title}`}
                    </BucketHeader>
                    <div className={listClasses}>
                        {n.description}
                        {this.#getListLinks(n)}
                    </div>
                </Bucket>
            </div>
        );
    }

    #getNewestNotificationCategory(): string {
        const nToday = this.#getNotificationsToday();
        if (nToday.length > 0) {
            return "today";
        }
        const nYesterday = this.#getNotificationsYesterday();
        if (nYesterday.length > 0) {
            return "yesterday";
        }
        const nWeek = this.#getNotificationsWeek();
        if (nWeek.length > 0) {
            return "thisWeek";
        }
        const nMonth = this.#getNotificationsMonth();
        if (nMonth.length > 0) {
            return "thirtyDays";
        }
        return null;
    }

    #getNotificationsToday(): FormattedNotification[] {
        const { notificationList } = this.props;
        const currentDay = moment().format("MM/DD/YYYY");
        const nToday = notificationList.filter((n) => {
            return moment(n.timestamp).format("MM/DD/YYYY") === currentDay;
        });
        return this.#formatNotifications(nToday);
    }

    #getNotificationsYesterday(): FormattedNotification[] {
        const { notificationList } = this.props;
        const yesterDay = moment().subtract(1, "days").format("MM/DD/YYYY");
        const nYesterday = notificationList.filter((n) => {
            return moment(n.timestamp).format("MM/DD/YYYY") === yesterDay;
        });

        return this.#formatNotifications(nYesterday);
    }

    #getNotificationsWeek(): FormattedNotification[] {
        const { notificationList } = this.props;
        const toDate = moment().subtract(2, "days");
        const range = [];
        let index = 0;
        while (index < 6) {
            range.push(moment(toDate).subtract(index, "days").format("MM/DD/YYYY"));
            index++;
        }
        const nthisWeek = notificationList.filter((n) => {
            return range.includes(moment(n.timestamp).format("MM/DD/YYYY"));
        });
        return this.#formatNotifications(nthisWeek);
    }

    #getNotificationsMonth(): FormattedNotification[] {
        const { notificationList } = this.props;
        const toDate = moment().subtract(8, "days");
        const range = [];
        let index = 0;
        while (index < 23) {
            range.push(moment(toDate).subtract(index, "days").format("MM/DD/YYYY"));
            index++;
        }
        const nMonth = notificationList.filter((n) => {
            return range.includes(moment(n.timestamp).format("MM/DD/YYYY"));
        });
        return this.#formatNotifications(nMonth);
    }

    #getReportViewerUrl = (fileName: string) => {
        return pdfUrl(
            `/RadPdf/Report/?loggedInUserGuid=${this.props.loggedInUserGuid}&filename=${fileName}`
        );
    };

    #getSamplingLink(detail: NotificationAPI.INotificationSampleResultsImported): JSX.Element {
        const names = this.#getSamplingNames(detail);
        return (
            <NoLink
                className={""}
                onClick={() => {
                    this.props.openSamplingLayer(detail.field.id, detail.event.id);
                    this.props.forceClose();
                }}
                label={names}
            />
        );
    }

    #getWaterSamplingLabel(
        detail: NotificationAPI.INotificationWaterSamplingResultsImported
    ): JSX.Element {
        const { sampleName } = detail as NotificationAPI.INotificationWaterSamplingResultsImported;
        return <div>{sampleName}</div>;
    }

    #getTitle(type: string): string {
        const { formatMessage } = this.props.intl;
        switch (type) {
            case REPORT_COMPLETED:
                return formatMessage(messages.reportReady);
            case REPORT_FAILED:
                return formatMessage(messages.reportFailedTitle);
            case SAMPLE_RESULTS_IMPORTED:
                return formatMessage(messages.samplingReady);
            case WATER_SAMPLING_RESULTS_IMPORTED:
                return formatMessage(messages.waterSamplingReady);
            case SAMPLE_RESULTS_FAILED:
                return formatMessage(messages.samplingResultsFailedTitle);
            default:
                return "";
        }
    }

    #renderErrorInReportLabel = (value: string, reportGuid: string): JSX.Element => {
        return (
            <NoLink
                onClick={() => this.setState({ reportErrorDetailsToShow: reportGuid })}
                className="report-text"
                label={value}
                title={value}
            />
        );
    };

    #toggleBucket(name: string): void {
        switch (name) {
            case "today":
                this.setState({
                    isTodayExpanded: !this.state.isTodayExpanded,
                });
                break;
            case "yesterday":
                this.setState({
                    isYesterdayExpanded: !this.state.isYesterdayExpanded,
                });
                break;
            case "thisWeek":
                this.setState({
                    isThisWeekExpanded: !this.state.isThisWeekExpanded,
                });
                break;
            case "thirtyDays":
                this.setState({
                    isThirtyDaysExpanded: !this.state.isThirtyDaysExpanded,
                });
                break;
        }
    }

    #toggleFirstBucket() {
        const newestCategory = this.#getNewestNotificationCategory();
        if (newestCategory) {
            this.#toggleBucket(newestCategory);
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { isLoading } = this.props;
        const nToday = this.#getNotificationsToday();
        const nYesterday = this.#getNotificationsYesterday();
        const nWeek = this.#getNotificationsWeek();
        const nMonth = this.#getNotificationsMonth();
        const noNotifications =
            nToday.length < 1 && nYesterday.length < 1 && nWeek.length < 1 && nMonth.length < 1;
        if (noNotifications) {
            return (
                <div className="notifications-main-container">
                    {formatMessage(messages.noNotifications)}
                </div>
            );
        }
        return (
            <div className="notifications-main-container">
                {isLoading ? <Loader /> : null}
                {nToday.length < 1 ? null : (
                    <Bucket
                        className="notification-bucket"
                        isExpanded={this.state.isTodayExpanded}
                        onBucketToggle={() => this.#toggleBucket("today")}
                    >
                        <BucketHeader className="notification-bucket-header form-section-header">
                            <div className="time-bucket-title">
                                <div className="time-title">{formatMessage(messages.today)}</div>
                            </div>
                        </BucketHeader>
                        {nToday.map((n, idx) => {
                            return this.#getListItem(n, idx);
                        })}
                    </Bucket>
                )}
                {nYesterday.length < 1 ? null : (
                    <Bucket
                        className="notification-bucket"
                        isExpanded={this.state.isYesterdayExpanded}
                        onBucketToggle={() => this.#toggleBucket("yesterday")}
                    >
                        <BucketHeader className="notification-bucket-header form-section-header">
                            <div className="time-bucket-title">
                                <div className="time-title">
                                    {formatMessage(messages.yesterday)}
                                </div>
                            </div>
                        </BucketHeader>
                        {nYesterday.map((n, idx) => {
                            return this.#getListItem(n, idx);
                        })}
                    </Bucket>
                )}
                {nWeek.length < 1 ? null : (
                    <Bucket
                        className="notification-bucket"
                        isExpanded={this.state.isThisWeekExpanded}
                        onBucketToggle={() => this.#toggleBucket("thisWeek")}
                    >
                        <BucketHeader className="notification-bucket-header form-section-header">
                            <div className="time-bucket-title">
                                <div className="time-title">{formatMessage(messages.thisWeek)}</div>
                            </div>
                        </BucketHeader>
                        {nWeek.map((n, idx) => {
                            return this.#getListItem(n, idx);
                        })}
                    </Bucket>
                )}
                {nMonth.length < 1 ? null : (
                    <Bucket
                        className="notification-bucket"
                        isExpanded={this.state.isThirtyDaysExpanded}
                        onBucketToggle={() => this.#toggleBucket("thirtyDays")}
                    >
                        <BucketHeader className="notification-bucket-header form-section-header">
                            <div className="time-bucket-title">
                                <div className="time-title">
                                    {formatMessage(messages.thirtyDays)}
                                </div>
                            </div>
                        </BucketHeader>
                        {nMonth.map((n, idx) => {
                            return this.#getListItem(n, idx);
                        })}
                    </Bucket>
                )}
                {!this.state.reportErrorDetailsToShow ? null : (
                    <ErrorDetailsDialog
                        onClose={() => this.setState({ reportErrorDetailsToShow: null })}
                        correlationId={this.state.reportErrorDetailsToShow}
                        logType="report"
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: getIsLoading(state),
    loggedInUserGuid: getTheUserGuid(state),
    notificationList: getNotificationList(state),
    userPreference: getUserPreference(state),
});
const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserNotifications: () => dispatch(actions.fetchUserNotifications()),
        openSamplingLayer: (fieldGuid, agEventGeneralGuid) =>
            dispatch(actions.openSamplingLayer(fieldGuid, agEventGeneralGuid)),
        clearBreadcrumbs: () => dispatch(setBreadcrumbs("")),
    };
};
export const Notifications = injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Notifications_)
);
