export enum myAgDataModel {
    PROPS_CROP_GUID = "cropGuid",
    PROPS_VARIETY_HYBRID_GUID = "varietyHybridGuid",
    PROPS_COMMODITY_GUID = "commodityGuid",
    PROPS_COMMODITY_NAME = "commodityName",
    PROPS_COMMODITY_TYPE_GUID = "commodityTypeGuid",
    PROPS_COMMODITY_TYPE_NAME = "commodityTypeName",
    PROPS_INTENDED_USE_GUID = "intendedUseGuid",
    PROPS_INTENDED_USE_NAME = "intendedUseName",
}

export interface MyAgDataCommodity {
    [myAgDataModel.PROPS_CROP_GUID]: string;
    [myAgDataModel.PROPS_VARIETY_HYBRID_GUID]: string | null;
    [myAgDataModel.PROPS_COMMODITY_GUID]: string;
    [myAgDataModel.PROPS_COMMODITY_NAME]: string;
    [myAgDataModel.PROPS_COMMODITY_TYPE_GUID]: string;
    [myAgDataModel.PROPS_COMMODITY_TYPE_NAME]: string;
    [myAgDataModel.PROPS_INTENDED_USE_GUID]: string | null;
    [myAgDataModel.PROPS_INTENDED_USE_NAME]: string | null;
}
