import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import * as model from "./model";

export type AccordionAction = ActionType<typeof actions>;

let stateCount = 0;
const newInitialState = (): model.IAccordionState => ({
    accordionId: stateCount++,
    items: [],
    recItemCount: 0,
    recHeight: 0,
});

/**
 * A helper function that converts an `ItemIdx` to a `MultiDimIdx` ..
 * The only difference is that if you're only getting items from the
 * first dimension, `ItemIdx` can be a plain `number` rather than `number[]`
 */
const itemIdxToDimIdx = (itemIdx: actions.ItemIdx): model.MultiDimIdx => {
    if (!(itemIdx instanceof Array)) {
        if (typeof itemIdx !== "number") {
            throw new ReferenceError(`invalid itemIdx: ${itemIdx}`);
        }
        return [itemIdx];
    }
    if (itemIdx.length < 1) {
        throw new ReferenceError(`invalid itemIdx: ${itemIdx}`);
    }
    return itemIdx;
};

/**
 * Similar to `Array.splice`, but returns a new state rather than mutating
 */
const replaceItems = (
    state: model.IAccordionState,
    newItems: model.AccordionItem[],
    dimIdx?: actions.ItemIdx,
    replaceCount = 0
) => {
    const replaceItemsRec = (accordionItem, dimIdx, newItems) => {
        const thisDimIdx =
            dimIdx[0] >= 0 ? dimIdx[0] : accordionItem.children.length + dimIdx[0] + 1;
        const updatingItem = dimIdx.length > 1;
        const newOrUpdateditems = updatingItem
            ? [replaceItemsRec(accordionItem.children[thisDimIdx], dimIdx.slice(1), newItems)]
            : newItems;
        const children = [
            ...accordionItem.children.slice(0, thisDimIdx),
            ...newOrUpdateditems,
            ...accordionItem.children.slice(thisDimIdx + (updatingItem ? 1 : replaceCount)),
        ];
        const recChildCount = !accordionItem.expanded
            ? 0
            : children.reduce((p, item) => {
                  return p + item.recChildCount;
              }, children.length);
        const recHeight = !accordionItem.expanded
            ? accordionItem.height
            : children.reduce((p, item) => {
                  return p + item.recHeight;
              }, accordionItem.height);
        return Object.freeze({
            ...accordionItem,
            children,
            recChildCount,
            recHeight,
        });
    };

    // Parameter sanitization
    if (dimIdx == null) {
        dimIdx = [state.items.length];
    }
    if (!(dimIdx instanceof Array)) {
        dimIdx = [dimIdx];
    }
    if (dimIdx.length === 0) {
        return state;
    }

    // state.items update
    const thisDimIdx = dimIdx[0] >= 0 ? dimIdx[0] : state.items.length + dimIdx[0] + 1;
    const updatingItem = dimIdx.length > 1;
    const newOrUpdateditems = updatingItem
        ? [replaceItemsRec(state.items[thisDimIdx], dimIdx.slice(1), newItems)]
        : newItems;
    const items = [
        ...state.items.slice(0, thisDimIdx),
        ...newOrUpdateditems,
        ...state.items.slice(thisDimIdx + (updatingItem ? 1 : replaceCount)),
    ];
    const recItemCount = items.reduce((p, item) => {
        return p + item.recChildCount;
    }, items.length);
    const recHeight = items.reduce((p, item) => {
        return p + item.recHeight;
    }, 0);
    return {
        accordionId: state.accordionId,
        items,
        recItemCount,
        recHeight,
    };
};

const addItems = (
    state: model.IAccordionState,
    newItems: model.AccordionItem[],
    dimIdx?: actions.ItemIdx
) => {
    return replaceItems(state, newItems, dimIdx, 0);
};

const removeItems = (state: model.IAccordionState, dimIdx?: actions.ItemIdx, removeCount = 0) => {
    return replaceItems(state, [], dimIdx, removeCount);
};

const getItemFromState = (state: model.IAccordionState, itemIdx: actions.ItemIdx) => {
    return model.getItem(state.items, itemIdxToDimIdx(itemIdx));
};

export const accordionReducer = (
    state: model.IAccordionState | null,
    action: AccordionAction
): model.IAccordionState => {
    if (state == null) {
        return newInitialState();
    }

    switch (action.type) {
        case actions.ADD_ITEM:
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            return addItems(state, [action.payload.item], action.payload.index);
        case actions.ADD_ITEM_ARRAY:
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            return addItems(state, action.payload.items, action.payload.index);
        case actions.REMOVE_ITEM:
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            return removeItems(state, action.payload.index, 1);
        case actions.REMOVE_ITEM_RANGE:
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            return removeItems(state, action.payload.index, action.payload.removeCount);
        case actions.REMOVE_DIMIDX_LIST: {
            const { accordionId, itemIdxList } = action.payload;
            if (accordionId !== state.accordionId) {
                return state;
            }
            const dimIdxSet = new Set(
                itemIdxList.map((itemIdx) => itemIdxToDimIdx(itemIdx).join(","))
            );
            const filteredItems = model.AccordionItem.recursiveFilter(
                state.items,
                ({ dimIdx }) => !dimIdxSet.has(dimIdx.join(","))
            );
            return replaceItems(state, filteredItems, 0, state.items.length);
        }
        case actions.REMOVE_ALL_ITEMS: {
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            return Object.freeze({
                ...state,
                items: [],
                recItemCount: 0,
                recHeight: 0,
            });
        }
        case actions.REPLACE_ALL_ITEMS: {
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            const emptyState = {
                ...state,
                items: [],
                recItemCount: 0,
                recHeight: 0,
            };
            return addItems(emptyState, action.payload.items);
        }
        case actions.EXPAND:
        case actions.COLLAPSE: {
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            const itemToUpdate = getItemFromState(state, action.payload.index);
            const newProps = { expanded: action.type === actions.EXPAND };
            const updatedItem = model.AccordionItem.updateAccordionItem(itemToUpdate, newProps);
            return replaceItems(state, [updatedItem], action.payload.index, 1);
        }
        case actions.UPDATE_ITEM: {
            if (action.payload.accordionId !== state.accordionId) {
                return state;
            }
            const itemToUpdate = getItemFromState(state, action.payload.index);
            const updatedItem = model.AccordionItem.updateAccordionItem(
                itemToUpdate,
                action.payload.newProps
            );
            return replaceItems(state, [updatedItem], action.payload.index, 1);
        }
        case actions.EXPAND_ALL:
        case actions.COLLAPSE_ALL: {
            const { accordionId, dimensions } = action.payload;
            if (accordionId !== state.accordionId) {
                return state;
            }
            const newProps = { expanded: action.type === actions.EXPAND_ALL };
            const updatedItems = model.AccordionItem.recursiveUpdate(
                state.items,
                newProps,
                dimensions
            );
            console.assert(updatedItems.length === state.items.length);
            return replaceItems(state, updatedItems, 0, updatedItems.length);
        }
        case actions.EXPAND_CHILDREN:
        case actions.COLLAPSE_CHILDREN: {
            const { accordionId, index, dimensions } = action.payload;
            if (accordionId !== state.accordionId) {
                return state;
            }
            const itemToUpdate = getItemFromState(state, index);
            const newProps = {
                expanded: action.type === actions.EXPAND_CHILDREN,
            };
            const dim = dimensions || [1];
            const children = model.AccordionItem.recursiveUpdate(
                itemToUpdate.children,
                newProps,
                dim
            );
            console.assert(children.length === itemToUpdate.children.length);
            const updatedItem = model.AccordionItem.updateAccordionItem(itemToUpdate, { children });
            return replaceItems(state, [updatedItem], index, 1);
        }
        default:
            return state;
    }
};
