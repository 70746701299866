import { createAction } from "redux-act";

const BASE_STR = "SURFACE_DEFAULTS";
export const FETCH_COMPANY_LIST_DATA = `${BASE_STR}_FETCH_COMPANY_LIST_DATA`;
export const FETCH_COMPANY_LIST_SUCCESS = `${BASE_STR}_FETCH_COMPANY_LIST_SUCCESS`;
export const FETCH_COMPANY_LIST_FAILED = `${BASE_STR}_FETCH_COMPANY_LIST_FAILED`;

export const CLEAR_FILTERED_LOCATION_LIST = "CLEAR_FILTERED_LOCATION_LIST";
export const FETCH_FILTERED_LOCATION_LIST_DATA = `${BASE_STR}_FETCH_FILTERED_LOCATION_LIST_DATA`;
export const FETCH_FILTERED_LOCATION_LIST_SUCCESS = `${BASE_STR}_FETCH_FILTERED_LOCATION_LIST_SUCCESS`;
export const FETCH_FILTERED_LOCATION_LIST_FAILED = `${BASE_STR}_FETCH_FILTERED_LOCATION_LIST_FAILED`;

export const clearFilteredLocationList = createAction(CLEAR_FILTERED_LOCATION_LIST);
export const fetchCompanyList = createAction(FETCH_COMPANY_LIST_DATA);
export const fetchCompanyListSuccess = createAction(FETCH_COMPANY_LIST_SUCCESS);
export const fetchCompanyListFailed = createAction(FETCH_COMPANY_LIST_FAILED);

export const fetchFilteredLocationList = createAction(
    FETCH_FILTERED_LOCATION_LIST_DATA,
    (userGuid) => userGuid
);
export const fetchFilteredLocationListSuccess = createAction(FETCH_FILTERED_LOCATION_LIST_SUCCESS);
export const fetchFilteredLocationListFailed = createAction(FETCH_FILTERED_LOCATION_LIST_FAILED);
