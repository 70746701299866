import { createReducer } from "redux-act";
import * as actions from "./actions";

export const ACCOUNT_DATA_KEY = "ACCOUNT_DATA";

const initialState = {
    connectionTypeList: null,
    onsiteEquipmentList: null,
    onsiteUserKey: null,
    registrationUrl: null,
    userAccount: {},
};

export const accountReducer = createReducer(
    {
        [actions.fetchAccountSuccess]: (state, userAccount) => ({
            ...state,
            userAccount,
        }),
        [actions.fetchConnectionTypeListSuccess]: (state, response) => ({
            ...state,
            connectionTypeList: response,
        }),
        [actions.fetchConnectionTypeListFailure]: (state) => ({
            ...state,
            connectionTypeList: [],
        }),
        [actions.fetchUrlForRegistrationSuccess]: (state, response) => ({
            ...state,
            registrationUrl: response,
        }),
        [actions.fetchUrlForRegistrationFailure]: (state) => ({
            ...state,
            registrationUrl: null,
        }),
        [actions.getOnSiteEquipmentListSuccess]: (state, response) => ({
            ...state,
            onsiteEquipmentList: response,
        }),
        [actions.getOnSiteEquipmentListFailure]: (state) => ({
            ...state,
            onsiteEquipmentList: null,
        }),
    },
    initialState
);
