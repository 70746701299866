import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { getTheUserGuid } from "~/login";
import { actions as notificationActions } from "~/notifications";
import { LocalStorageHelpers, UserAPI } from "@ai360/core";

import * as actions from "./actions";
import { messages } from "./i18n-messages";
import * as selectors from "./selectors";

import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";

const _requestLayerPreferences = function (userGuid) {
    return UserAPI.getUserPreferenceByKey(
        userGuid,
        UserAPI.UserPreferenceKeys.standardLayerPreferences
    ).then(({ value }) => (value ? JSON.parse(value) : null));
};

const getLayerPreferences = function* (action) {
    try {
        const preferences = yield call(_requestLayerPreferences, action.userGuid);
        yield put(actions.fetchLayerPreferencesSucceeded(preferences));
    } catch (err) {
        yield put(
            notificationActions.apiCallError(
                err,
                action,
                messages.errorGettingStandardLayerPreferences
            )
        );
    }
};

const saveBasemapSetting = (action) => {
    LocalStorageHelpers.set(LocalStorageHelpers.BASE_MAP, action.basemap);
};

const saveLayerPreferences = function* (action) {
    try {
        const userGuid = yield select(getTheUserGuid);
        const cadastralLayerInfos = yield select(selectors.getCadastralLayerInfos);
        const fieldBoundaryLayerInfo = yield select(selectors.getFieldBoundaryLayerInfo);
        const nonFieldFeatureLayerInfo = yield select(selectors.getNonFieldFeatureLayerInfo);
        yield call(
            UserAPI.saveUserPreference,
            userGuid,
            UserAPI.UserPreferenceKeys.standardLayerPreferences,
            {
                featureLayers: [
                    {
                        ...fieldBoundaryLayerInfo,
                        name: UserAPI.StandardFeatureLayerNames.fields,
                    },
                    {
                        ...nonFieldFeatureLayerInfo,
                        name: UserAPI.StandardFeatureLayerNames.nonFieldFeatures,
                    },
                ],
                dynamicLayers: cadastralLayerInfos,
            }
        );
    } catch (err) {
        yield put(
            notificationActions.apiCallError(
                err,
                action,
                messages.errorSavingStandardLayerPreferences
            )
        );
    }
};

const setLayerPreferences = function* (action) {
    //Note: payload is null for new users
    const { payload } = action;
    const dynamicLayers = payload && payload.dynamicLayers ? payload.dynamicLayers : [];
    const featureLayers = payload && payload.featureLayers ? payload.featureLayers : [];

    const fieldBoundaryLayerInfo = yield select(selectors.getFieldBoundaryLayerInfo);

    const fbli = featureLayers.find((lyr) => {
        return lyr.name === UserAPI.StandardFeatureLayerNames.fields;
    });
    //purposefully ignoring the fields visibility user preference for app usability:
    //ensuring the hidefield flag doesn't persist if user loses connection or closes app before completeing a rec or event
    const newFbli =
        fbli && fbli.showLabels
            ? {
                  ...fbli,
                  hideFields: false,
                  visible: true,
              }
            : fieldBoundaryLayerInfo;

    const fetchedNonFieldFeatureLayerInfo = featureLayers.find(
        (layer) => layer.name === UserAPI.StandardFeatureLayerNames.nonFieldFeatures
    );

    const newNonFieldFeatureLayerInfo = {
        visible: fetchedNonFieldFeatureLayerInfo ? fetchedNonFieldFeatureLayerInfo.visible : true,
    };

    yield put(actions.setLayerInfos(dynamicLayers, newFbli, newNonFieldFeatureLayerInfo));
};

const updateLayerSelections = function* (action) {
    const visible = action.type === actions.SELECT_ALL_LAYERS;
    const fieldBoundaryLayerInfo = yield select(selectors.getFieldBoundaryLayerInfo);
    const cadastralLayerInfos = yield select(selectors.getCadastralLayerInfos);

    const newCadastralLayers = [];
    for (let layerInfo of cadastralLayerInfos) {
        newCadastralLayers.push({
            ...layerInfo,
            visible,
        });
    }
    yield put(
        actions.setLayerInfosAndSave(newCadastralLayers, {
            ...fieldBoundaryLayerInfo,
            visible,
        })
    );
};

const onHighlightedNonFieldFeaturesChanged = function* () {
    const highlighted = yield select(cdSelectors.getHighlightedNonFieldFeatures);
    yield put(actions.setZoomToNonFieldFeatures(highlighted.toArray()));
};

export function* mapControlSaga() {
    yield all([
        takeLatest(actions.FETCH_LAYER_PREFERENCES_INIT, getLayerPreferences),
        takeLatest(actions.FETCH_LAYER_PREFERENCES_SUCCEEDED, setLayerPreferences),
        takeEvery([actions.SET_BASEMAP], saveBasemapSetting),
        takeEvery([actions.SELECT_ALL_LAYERS, actions.UNSELECT_ALL_LAYERS], updateLayerSelections),
        takeEvery(
            [
                actions.SET_FIELD_BOUNDARY_LAYER_INFO,
                actions.SET_NON_FIELD_FEATURE_LAYER_INFO,
                actions.SET_LAYER_INFOS_AND_SAVE,
                actions.UPDATE_CADASTRAL_LAYER_INFO,
            ],
            saveLayerPreferences
        ),

        takeEvery(cdActions.SET_NON_FIELD_FEATURES, onHighlightedNonFieldFeaturesChanged),
        takeEvery(cdActions.MODIFY_NON_FIELD_FEATURES, onHighlightedNonFieldFeaturesChanged),
        takeEvery(
            cdActions.SET_HIGHLIGHTED_NON_FIELD_FEATURES,
            onHighlightedNonFieldFeaturesChanged
        ),
        takeEvery(
            cdActions.MODIFY_HIGHLIGHTED_NON_FIELD_FEATURES,
            onHighlightedNonFieldFeaturesChanged
        ),
    ]);
}
