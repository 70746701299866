import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { service, REQUEST_CROP_CLASS } from "./../data";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../../agBytes/i18n-messages";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
// components
import { Checkbox, NumericInput, Section, SelectInput, SubSection } from "~/core";
// constants
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { model } from "./../data";

class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.nutrientRemovalRate,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    static defaultProps = {
        [model.PROPS_CROP_CLASS_NAME_LIST]: [],
    };

    constructor(props) {
        super(props);
        this.nutrientRemovalRate = {};
        this.state = {
            nextId: null,
            [model.PROPS_CROP_CLASS_NAME_LIST]: [],
            [model.PROPS_CROP_CLASS_GUID]: "",
        };
    }

    componentDidMount() {
        this.setupNutrientRemovalRateRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(),
            this.props.fetchUnitData(),
            this.props.fetchDropdownData(),
            this.props.fetchPicklistData(),
        ]);
        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    setupNutrientRemovalRateRecord = () => {
        const { addEditPanel } = this.props;
        this.nutrientRemovalRate = {};
        if (addEditPanel.mode === "ADD") {
            this.nutrientRemovalRate = service.getDefaultRecord();
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.nutrientRemovalRate);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.nutrientRemovalRate = nextProps.record;
                this.setHeaderText();
                this.setState({
                    [model.PROPS_CROP_CLASS_GUID]:
                        this.nutrientRemovalRate[model.PROPS_CROP_CLASS_GUID] || "",
                });
                this.props.needs([
                    this.props.fetchDropdownData({
                        [model.PROPS_CROP_CLASS_NAME_LIST]: {
                            url: REQUEST_CROP_CLASS,
                            model: this.nutrientRemovalRate[model.PROPS_CROP_GUID],
                        },
                    }),
                ]);
            }
        }

        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId) {
            this.setState(
                {
                    nextId: nextProps.nextId,
                },
                () => {
                    this.nutrientRemovalRate[model.PROPS_NUTRIENT_REMOVAL_RATE_ID] =
                        "" + nextProps.nextId;
                }
            );
        }
    };

    initializeDropdowns(nextProps) {
        this.initializeCropPurpose(nextProps);
        this.initializeCropClass(nextProps);
        this.initializeNutrient(nextProps);
        this.initializeCrop(nextProps);
        this.initializeRateUnit(nextProps);
    }

    initializeCropClass = (nextProps) => {
        if (
            nextProps[model.PROPS_CROP_CLASS_NAME_LIST] !==
            this.props[model.PROPS_CROP_CLASS_NAME_LIST]
        ) {
            this.setState({
                [model.PROPS_CROP_CLASS_NAME_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_CLASS_NAME_LIST],
                    { guid: GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeCropPurpose = (nextProps) => {
        if (nextProps[picklistService.PICKLIST_CROP_PURPOSE]) {
            this.setState({
                [picklistService.PICKLIST_CROP_PURPOSE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_CROP_PURPOSE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.nutrientRemovalRate[model.PROPS_CROP_PURPOSE_GUID]
                ),
            });
        }
    };

    initializeNutrient = (nextProps) => {
        if (nextProps[model.PROPS_NUTRIENT_NAME]) {
            this.setState({
                [model.PROPS_NUTRIENT_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_NUTRIENT_NAME],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true },
                    this.nutrientRemovalRate[model.PROPS_NUTRIENT_GUID]
                ),
            });
        }
    };

    initializeCrop = (nextProps) => {
        if (nextProps[model.PROPS_CROP_NAME]) {
            this.setState({
                [model.PROPS_CROP_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true },
                    this.nutrientRemovalRate[model.PROPS_CROP_GUID]
                ),
            });
        }
    };

    initializeRateUnit = (nextProps) => {
        if (nextProps[unitService.UNIT_CROP_PRICE]) {
            this.setState({
                [unitService.UNIT_CROP_PRICE]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_CROP_PRICE],
                    { guid: GUID, label: NAME, id: ID },
                    this.nutrientRemovalRate[model.PROPS_RATE_UNIT_GUID]
                ),
            });
        }
    };
    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.nutrientRemovalRate[model.PROPS_NUTRIENT_REMOVAL_RATE_ID] = "" + nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    onNumberChange = (formKey, value, callback) => {
        this.nutrientRemovalRate = onNumberChange(
            this.nutrientRemovalRate,
            { formKey: [formKey], value },
            callback
        );
    };

    onTextChange = (formKey, value, callback) => {
        this.nutrientRemovalRate = onTextChange(
            this.nutrientRemovalRate,
            { formKey: [formKey], value },
            callback
        );
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.nutrientRemovalRate = handlePicklistChange(
            this.nutrientRemovalRate,
            { type, guid, value },
            callback,
            true
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.nutrientRemovalRate[model.PROPS_NUTRIENT_NAME]]);
    };

    onCropNameChange = () => {
        if (this.nutrientRemovalRate[model.PROPS_CROP_GUID]) {
            this.props.needs([
                this.props.fetchDropdownData({
                    [model.PROPS_CROP_CLASS_NAME_LIST]: {
                        url: REQUEST_CROP_CLASS,
                        model: this.nutrientRemovalRate[model.PROPS_CROP_GUID],
                    },
                }),
            ]);
        } else {
            this.setState({
                [model.PROPS_CROP_CLASS_NAME_LIST]: [],
            });
        }
        this.nutrientRemovalRate[model.PROPS_CROP_CLASS_GUID] = null;
        this.nutrientRemovalRate[model.PROPS_CROP_CLASS_NAME] = "";
        this.nutrientRemovalRate[model.PROPS_CROP_CLASS_ID] = null;
        this.setState({ [model.PROPS_CROP_CLASS_GUID]: null });
    };

    renderNutrientRemovalRateInfo = () => {
        const { nextId } = this.state;
        const { nutrientRemovalRate } = this;
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            precision={9}
                            scale={0}
                            suppressFormatting
                            containerClassNames={getAgBytesErrorClassNames(
                                [227, 228],
                                this.props.apiErrors,
                                ["form-input-id"]
                            )}
                            placeholderText={formatMessage(messages.nutrientRemovalRateId)}
                            labelText={formatMessage(messages.nutrientRemovalRateId)}
                            value={
                                nextId || nutrientRemovalRate[model.PROPS_NUTRIENT_REMOVAL_RATE_ID]
                            }
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_NUTRIENT_REMOVAL_RATE_ID, value)
                            }
                            required
                        />
                        {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className="active-checkbox"
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={nutrientRemovalRate[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            required
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            tabIndex={0}
                            autoFocus
                            openOnFocus={false}
                            containerClassNames={getAgBytesErrorClassNames(
                                [149, 233],
                                this.props.apiErrors,
                                ["form-input-id"]
                            )}
                            options={this.state[model.PROPS_NUTRIENT_NAME]}
                            placeholderText={formatMessage(messages.nutrientIdName)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_NUTRIENT_NAME],
                                selectedGuid: nutrientRemovalRate[model.PROPS_NUTRIENT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_NUTRIENT_NAME,
                                        groupId: model.PROPS_NUTRIENT_ID,
                                        guid: model.PROPS_NUTRIENT_GUID,
                                    },
                                    value
                                );
                                this.setHeaderText();
                            }}
                        />
                        <SelectInput
                            required
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                [155, 233],
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_CROP_NAME]}
                            placeholderText={formatMessage(messages.cropIdName)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_CROP_NAME],
                                selectedGuid: nutrientRemovalRate[model.PROPS_CROP_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_CROP_NAME,
                                        guid: model.PROPS_CROP_GUID,
                                    },
                                    value,
                                    this.onCropNameChange
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            required
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                232,
                                this.props.apiErrors
                            )}
                            placeholderText={formatMessage(messages.rate)}
                            labelText={formatMessage(messages.rate)}
                            value={`${nutrientRemovalRate[model.PROPS_RATE]}`}
                            precision={6}
                            scale={3}
                            allowNegatives={false}
                            onChange={(value) => this.onNumberChange(model.PROPS_RATE, value)}
                        />
                        <SelectInput
                            required
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                230,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_CROP_PRICE]}
                            placeholderText={formatMessage(messages.rateUnit)}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_CROP_PRICE],
                                selectedGuid: nutrientRemovalRate[model.PROPS_RATE_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_RATE_UNIT,
                                        guid: model.PROPS_RATE_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section>
                    <SubSection className="form-section-row-2">
                        <SelectInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                233,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_CROP_PURPOSE]}
                            placeholderText={formatMessage(messages.cropPurpose)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_CROP_PURPOSE],
                                selectedGuid: nutrientRemovalRate[model.PROPS_CROP_PURPOSE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_CROP_PURPOSE,
                                        guid: model.PROPS_CROP_PURPOSE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <SelectInput
                            tabIndex={0}
                            options={this.state[model.PROPS_CROP_CLASS_NAME_LIST]}
                            disabled={
                                !nutrientRemovalRate[model.PROPS_CROP_NAME] ||
                                !this.state[model.PROPS_CROP_CLASS_NAME_LIST].length
                            }
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_CROP_CLASS_NAME_LIST],
                                selectedGuid: this.state[model.PROPS_CROP_CLASS_GUID],
                            })}
                            onChange={({ name, guid, id }) => {
                                nutrientRemovalRate[model.PROPS_CROP_CLASS_NAME] = name;
                                nutrientRemovalRate[model.PROPS_CROP_CLASS_ID] = id;
                                nutrientRemovalRate[model.PROPS_CROP_CLASS_GUID] = guid;
                                this.setState({
                                    [model.PROPS_CROP_CLASS_GUID]: guid,
                                });
                            }}
                            clearable={false}
                            placeholderText={formatMessage(messages.cropClassIdName)}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        return <div className="add-edit-panel">{this.renderNutrientRemovalRateInfo()}</div>;
    }
}

// Add Intl
export default injectIntl(AddEditPanel);
