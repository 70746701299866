import { all, takeEvery, put } from "redux-saga/effects";

import * as actionPanelActions from "~/action-panel/actions";

import { eventInfoSaga } from "./components/event-info/sagas";
import { eventListSaga } from "./components/event-list/sagas";

import * as actions from "./actions";

export const onSetActivePage = function* (action) {
    const { activePage } = action.payload;
    yield put(actionPanelActions.setIsEnabled(activePage === actions.EventModulePages.EVENT_LIST));
};

export const eventModuleSaga = function* () {
    yield all([
        takeEvery(actions.SET_ACTIVE_PAGE, onSetActivePage),
        eventInfoSaga(),
        eventListSaga(),
    ]);
};
