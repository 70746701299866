import React from "react";

import { DialogBox, DialogBoxFooterType } from "~/core";

export interface IDeleteConfDialog {
    deleteConfirmationTitle: string;
    deleteConfirmationMessage: string;
    formatMessage?: (string: string) => string;
    onConfirmationClick?: (confirmation: boolean) => void;
    className?: string;
}
export const DeleteConfDialog = ({
    deleteConfirmationTitle,
    deleteConfirmationMessage,
    formatMessage,
    onConfirmationClick,
    className,
}: IDeleteConfDialog): JSX.Element => {
    return (
        <DialogBox
            unrestricted
            footerType={DialogBoxFooterType.YES_NO}
            isOpen={true}
            onAction={() => onConfirmationClick(true)}
            onClose={() => onConfirmationClick(false)}
            title={formatMessage(deleteConfirmationTitle)}
            className={className}
        >
            {formatMessage(deleteConfirmationMessage)}
        </DialogBox>
    );
};
