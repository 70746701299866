import { adminData } from "~/admin/data";
// Model
export const ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_ACCESSORY_LIST = "accessoryList";
export const PROPS_ACCUM_DEPRECIATION_AS_OF_YEAR_END = "accumDepreciationAsOfYearEnd";
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_AGGATEWAY_ID = "agGateway_Id";
export const PROPS_BIN_TANK_LIST = "binTankList";
export const PROPS_BOOK_VALUE_AS_OF_YEAR_END = "bookValueAsOfYearEnd";
export const PROPS_BOOM_NOZZLES_PER_SECTION = "boomNozzlesPerSection";
export const PROPS_BOOM_NUMBER_OF_SECTIONS = "boomNumberOfSections";
export const PROPS_BOOM_SECTION_LENGTH = "boomSectionLength";
export const PROPS_BOOM_SECTION_LENGTH_UNIT_GUID = "boomSectionLengthUnitGuid";
export const PROPS_CAB = "cab";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CHEMIGATION = "chemigation";
export const PROPS_CLASS_SIZE = "classSize";
export const PROPS_CLASS_SIZE_GUID = "classSizeGuid";
export const PROPS_CONTAINER_SIZE = "containerSize";
export const PROPS_CONTAINER_SIZE_UNIT = "containerSizeUnit";
export const PROPS_CONTAINER_SIZE_UNIT_GUID = "containerSizeUnitGuid";
export const PROPS_CONTROLLER_ID = "controllerId";
export const PROPS_CURRENT_YEAR_DEPRECIATION = "currentYearDepreciation";
export const PROPS_CYLINDERS = "cylinders";
export const PROPS_DESCRIPTION = "description";
export const PROPS_DIMENSION_UNIT = "dimensionUnit";
export const PROPS_DIMENSION_UNIT_GUID = "dimensionUnitGuid";
export const PROPS_DRIVE_TRAIN = "driveTrain";
export const PROPS_DRIVE_TRAIN_GUID = "driveTrainGuid";
export const PROPS_ENGINE_DISPLACEMENT = "engineDisplacement";
export const PROPS_EQUIPMENT_GUID = "equipmentGuid";
export const PROPS_EQUIPMENT_ID = "equipmentId";
export const PROPS_EQUIPMENT_NAME = "equipmentName";
export const PROPS_EQUIPMENT_PARENT_TYPE = "equipmentParentType";
export const PROPS_EQUIPMENT_PARENT_TYPE_GUID = "equipmentParentTypeGuid";
export const PROPS_EQUIPMENT_PHOTOLIST = "equipmentPhotoList";
export const PROPS_EQUIPMENT_PHOTO_LIST = "equipmentPhotoList";
export const PROPS_EQUIPMENT_PROFILE_GUID = "equipmentProfileGuid";
export const PROPS_EQUIPMENT_PROFILE_MAINTENANCE_EVENT_GUID =
    "equipmentProfileMaintenanceEventGuid";
export const PROPS_EQUIPMENT_TYPE = "equipmentType";
export const PROPS_EQUIPMENT_TYPE_ALIAS_LIST = "equipmentTypeAliasList";
export const PROPS_EQUIPMENT_TYPE_GUID = "equipmentTypeGuid";
export const PROPS_FERTIGATION = "fertigation";
export const PROPS_FUELING_EVENT_LIST = "fuelingEventList";
export const PROPS_FUEL_CAPACITY = "fuelCapacity";
export const PROPS_FUEL_CAPACITY_UNIT = "fuelCapacityUnit";
export const PROPS_FUEL_CAPACITY_UNIT_GUID = "fuelCapacityUnitGuid";
export const PROPS_FUEL_EFFICIENCY = "fuelEfficiency";
export const PROPS_FUEL_EFFICIENCY_UNIT = "fuelEfficiencyUnit";
export const PROPS_FUEL_EFFICIENCY_UNIT_GUID = "fuelEfficiencyUnitGuid";
export const PROPS_FUEL_TYPE = "fuelType";
export const PROPS_FUEL_TYPE_GUID = "fuelTypeGuid";
export const PROPS_HARVESTER_FLOW_DELAY = "harvesterFlowDelay";
export const PROPS_HEIGHT = "height";
export const PROPS_HITCH_TYPE_GUID = "hitchTypeGuid";
export const PROPS_HORSE_POWER = "horsePower";
export const PROPS_HORSE_POWER_UNIT = "horsePowerUnit";
export const PROPS_HORSE_POWER_UNIT_GUID = "horsePowerUnitGuid";
export const PROPS_IS_ACTIVE = adminData.PROPS_IS_ACTIVE;
export const PROPS_LENGTH = "length";
export const PROPS_MAINTENANCE_DATE = "maintenanceDate";
export const PROPS_MAINTENANCE_DESCRIPTION = "maintenanceDescription";
export const PROPS_MAINTENANCE_LIST = "maintenanceEventList";
export const PROPS_MAINTENANCE_TYPE_GUID = "maintenanceTypeGuid";
export const PROPS_MAINTENANCE_TYPE_NAME = "maintenanceTypeName";
export const PROPS_MANUFACTURER = "manufacturer";
export const PROPS_MANUFACTURER_GUID = "manufacturerGuid";
export const PROPS_MAX_GROSS_WEIGHT = "maxGrossWeight";
export const PROPS_MAX_SPEED = "maxSpeed";
export const PROPS_MAX_SPEED_UNIT = "maxSpeedUnit";
export const PROPS_MAX_SPEED_UNIT_GUID = "maxSpeedUnitGuid";
export const PROPS_METER_READING = "meterReading";
export const PROPS_MODEL_NAME = "modelName";
export const PROPS_MODEL_YEAR = "modelYear";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_NOTES = "notes";
export const PROPS_NOZZLE_LIST = "nozzleList";
export const PROPS_NOZZLE_SPACING = "nozzleSpacing";
export const PROPS_NOZZLE_SPACING_UNIT_GUID = "nozzleSpacingUnitGuid";
export const PROPS_NUMBER_OF_SPRINKLERS = "numberOfSprinklers";
export const PROPS_ONSITE_EQUIPMENT_LIST = "onsiteEquipmentList";
export const PROPS_ONSITE_USER_LIST = "onsiteUserList";
export const PROPS_ORG_LEVEL = "orgLevel";
export const PROPS_ORG_LEVEL_GUID = "orgLevelGuid";
export const PROPS_ORG_LEVEL_PARENT = "orgLevelParents";
export const PROPS_OWNER_OPERATOR_CITY = "ownerOperatorCity";
export const PROPS_OWNER_OPERATOR_CUSTOMER_GUID = "ownerOperatorCustomerGuid";
export const PROPS_OWNER_OPERATOR_NAME = "ownerOperatorName";
export const PROPS_OWNER_OPERATOR_ORG_LEVEL_GUID = "ownerOperatorOrgLevelGuid";
export const PROPS_OWNER_OPERATOR_STATE_ABBREVIATION = "ownerOperatorStateAbbreviation";
export const PROPS_OWNER_OPERATOR_VENDOR_GUID = "ownerOperatorVendorGuid";
export const PROPS_PART_ID = "partId";
export const PROPS_PHOTO_LIST = "photoList";
export const PROPS_PHYSICAL_STATE_GUID = "physicalStateGuid";
export const PROPS_PIVOT_SIZE = "pivotSize";
export const PROPS_PIVOT_SIZE_UNIT_GUID = "pivotSizeUnitGuid";
export const PROPS_PLANTER_NUMBER_OF_ROWS = "planterNumberOfRows";
export const PROPS_PLANTER_ROW_SPACING = "planterRowSpacing";
export const PROPS_PLANTER_ROW_SPACING_UNIT_GUID = "planterRowSpacingUnitGuid";
export const PROPS_PUR_DATE = "purDate";
export const PROPS_PUR_DATE_USAGE_TOTALAREA = "purDateUsageTotalArea";
export const PROPS_PUR_DATE_USAGE_TOTAL_AREA_UNIT_GUID = "purDateUsageTotalAreaUnitGuid";
export const PROPS_PUR_DATE_USAGE_TOTAL_USAGE = "purDateUsageTotalUsage";
export const PROPS_PUR_DATE_USAGE_TOTAL_USAGE_UNIT_GUID = "purDateUsageTotalUsageUnitGuid";
export const PROPS_PUR_DATE_USAGE_TOTAL_VOLUME = "purDateUsageTotalVolume";
export const PROPS_PUR_DATE_USAGE_TOTAL_VOLUME_UNIT_GUID = "purDateUsageTotalVolumeUnitGuid";
export const PROPS_PUR_PRICE = "purPrice";
export const PROPS_RETIRED_DATE = "retiredDate";
export const PROPS_SERIAL_NUMBER = "serialNumber";
export const PROPS_SWATH_WIDTH = "swathWidth";
export const PROPS_TARE_WEIGHT = "tareWeight";
export const PROPS_TELEMATIC_ACTIVE_YN = "telematicsActiveYn";
export const PROPS_TELEMATIC_PRIMARY_USER_GUID = "telematicsPrimaryUserGuid";
export const PROPS_TELEMATIC_EQUIPMENT_NODE_GUID = "telematicsNodeId";
export const PROPS_TIRES_ASPECT_RATIO = "tiresAspectRatio";
export const PROPS_TIRES_LOAD_RATING = "tiresLoadRating";
export const PROPS_TIRES_LOAD_RATING_UNIT_GUID = "tiresLoadRatingUnitGuid";
export const PROPS_TIRES_NUMBER_OF_TIRES = "tiresNumberOfTires";
export const PROPS_TIRES_REC_PRESSURE = "tiresRecPressure";
export const PROPS_TIRES_REC_PRESSURE_UNIT_GUID = "tiresRecPressureUnitGuid";
export const PROPS_TIRES_RIM_DIAMETER = "tiresRimDiameter";
export const PROPS_TIRES_RIM_DIAMETER_UNIT_GUID = "tiresRimDiameterUnitGuid";
export const PROPS_TIRES_SPEED_RATING = "tiresSpeedRating";
export const PROPS_TIRES_TYPE_GUID = "tiresTypeGuid";
export const PROPS_TIRE_WIDTH = "tireWidth";
export const PROPS_TOTAL_APPLICATION_WIDTH = "totalApplicationWidth";
export const PROPS_TOTAL_APPLICATION_WIDTH_GUID = "totalApplicationWidthUnitGuid";
export const PROPS_TOTAL_COST = "totalCost";
export const PROPS_TOWING_CAPACITY = "towingCapacity";
export const PROPS_TOWING_CAPACITY_UNIT = "towingCapacityUnit";
export const PROPS_TOWING_CAPACITY_UNIT_GUID = "towingCapacityUnitGuid";
export const PROPS_TRANSMISSION_TYPE = "transmissionType";
export const PROPS_TRANSMISSION_TYPE_GUID = "transmissionTypeGuid";
export const PROPS_TUBING_SIZE = "tubingSize";
export const PROPS_TUBING_SIZE_UNIT_GUID = "tubingSizeUnitGuid";
export const PROPS_USAGE_READING_LIST = "usageReadingList";
export const PROPS_USAGE_UNIT_GUID = "usageUnitGuid";
export const PROPS_USAGE_UNIT_NAME = "usageUnitName";
export const PROPS_WEIGHT_UNIT = "weightUnit";
export const PROPS_WEIGHT_UNIT_GUID = "weightUnitGuid";
export const PROPS_WELL = "well";
export const PROPS_WELL_DEPTH = "wellDepth";
export const PROPS_WELL_DEPTH_UNIT_GUID = "wellDepthUnitGuid";
export const PROPS_WELL_OUTPUT = "wellOutput";
export const PROPS_WELL_OUTPUT_UNIT_GUID = "wellOutputUnitGuid";
export const PROPS_WELL_TYPE_GUID = "wellTypeGuid";
export const PROPS_WIDTH = "width";
