import React, { Component } from "react";
import PropTypes from "prop-types";
import { AutoSearch } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "./../i18n-messages";
import classnames from "classnames";

class SalespersonCustomerSearch_ extends Component {
    static propTypes = {
        classNames: PropTypes.string,
        clearOnSelection: PropTypes.bool,
        containerClassName: PropTypes.string,
        disabled: PropTypes.bool,
        initialFilterStr: PropTypes.string,
        intl: intlShape.isRequired,
        itemList: PropTypes.array,
        onSearchChange: PropTypes.func,
        onSelection: PropTypes.func.isRequired,
        placeholderText: PropTypes.string,
        required: PropTypes.bool,
        selectedValue: PropTypes.string,
        showTopLabel: PropTypes.bool,
    };
    static defaultProps = {
        clearOnSelection: false,
        initialFilterStr: "",
        itemList: [],
        onSearchChange: null,
        required: false,
        selectedValue: "",
        showTopLabel: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            resetOptions: false,
        };
    }

    onResetOptions = () => {
        this.setState({
            resetOptions: false,
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.itemList !== this.props.itemList) {
            this.setState({
                resetOptions: true,
            });
        }
    }

    render() {
        const {
            classNames,
            clearOnSelection,
            containerClassName,
            disabled,
            initialFilterStr,
            itemList,
            onSearchChange,
            onSelection,
            placeholderText,
            selectedValue,
            showTopLabel,
        } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={classnames("form-section-child-stretch", containerClassName)}>
                <AutoSearch
                    classNames={[classnames(classNames)]}
                    clearOnSelection={clearOnSelection}
                    disabled={disabled}
                    initialFilterStr={initialFilterStr}
                    itemList={itemList}
                    keyProp={SalespersonCustomerSearch.SALESPERSON_GUID}
                    nameProp={SalespersonCustomerSearch.SALESPERSON_PERSON_NAME}
                    onResetOptions={this.onResetOptions}
                    onSearchChange={onSearchChange}
                    onSelection={(item) => onSelection(item)}
                    placeholderText={placeholderText || formatMessage(messages.addSalesperson)}
                    secondaryPropList={[
                        SalespersonCustomerSearch.SALESPERSON_ID,
                        SalespersonCustomerSearch.SALESPERSON_CITY,
                        SalespersonCustomerSearch.SALESPERSON_STATE_ABR,
                    ]}
                    resetOptions={this.state.resetOptions}
                    selectedValue={selectedValue}
                    showTopLabel={showTopLabel}
                />
            </div>
        );
    }
}

export const SalespersonCustomerSearch = injectIntl(SalespersonCustomerSearch_);

SalespersonCustomerSearch.SALESPERSON_GUID = "salespersonGuid";
SalespersonCustomerSearch.SALESPERSON_PERSON_NAME = "personName";
SalespersonCustomerSearch.SALESPERSON_ID = "salespersonId";
SalespersonCustomerSearch.SALESPERSON_CITY = "city";
SalespersonCustomerSearch.SALESPERSON_STATE_ABR = "stateAbbreviation";
