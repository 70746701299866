import React, { Component } from "react";
import { InjectedIntl, injectIntl } from "react-intl";

import classnames from "classnames";
import { connect } from "react-redux";

import { actions as accordionActions, model as accordionModels } from "~/accordion";
import { CustomerContextMenu } from "~/action-panel/components/context-menus/customer-context-menu/customer-context-menu";
import { models, selectors as cdSelectors } from "~/customer-data";
import { config as intlConfig } from "~/intl-provider/config";

import * as actions from "../../actions";
import { messages } from "../../i18n-messages";
import * as selectors from "../../selectors";

import "./customer-item.css";
import { LockIcon } from "~/core/icons";
import { getTheUserLockCustomer } from "~/login/selectors";
import { CustomerInfo } from "~/customer-data/models";

interface ICustomerItemProps {
    collapseItem: () => void;
    customer: models.CustomerInfo;
    customerItem: accordionModels.AccordionItem;
    selectedFields: string[];
    expandItem: () => void;
    intl: InjectedIntl;
    isExpanded: boolean;
    loadDetailPage: (
        childNameCode: string,
        parentNameCode: string,
        slidingPanelProps: Record<string, any>
    ) => void;
    lockCustomersNotEnrolled: boolean;
    onAddEditField: (customer: CustomerInfo, fieldGuid: string) => void;
    isLast: boolean;
    isActiveInactive: boolean;
}

class CustomerItem_ extends Component<ICustomerItemProps> {
    public render() {
        const {
            customer,
            customerItem,
            selectedFields,
            isExpanded,
            loadDetailPage,
            lockCustomersNotEnrolled,
            onAddEditField,
            isLast,
            isActiveInactive,
        } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;

        if (customer == null || customerItem == null) {
            return null;
        }

        const cityState = `${customer.city}, ${customer.state}`;

        const fieldCount = customer.fields;

        const totalAcres = formatNumber(customer.acres, intlConfig.numberFormatOptions);

        const customerIsAliased =
            Boolean(customer.agvanceGrowerName) &&
            customer.name?.toLowerCase() !== customer.agvanceGrowerName?.toLowerCase();
        const addtlTitleNameInfo = !customerIsAliased ? "" : ` (${customer.agvanceGrowerName})`;
        const customerInfoTitle = `${customer.name}${addtlTitleNameInfo}\n${cityState}`;
        const fieldText = formatMessage(messages.fieldText, {
            count: fieldCount,
        });
        const totalAcresText = formatMessage(messages.totalAcres);

        const classes = {
            "is-expanded": isExpanded && customerItem.children.length > 0,
            "is-active-inactive": isActiveInactive,
            "is-last": isLast,
        };

        return (
            <div className={classnames("customer-accordion-item", classes)} onClick={this._onClick}>
                <div className="customer-info" title={customerInfoTitle}>
                    <div className="customer-name-container">
                        <div className="customer-name clip-overflow">{customer.name}</div>
                        {lockCustomersNotEnrolled && !customer.enrolledYn ? (
                            <LockIcon className="customer-enrolled-lock-icon" />
                        ) : null}
                        {selectedFields.length <= 0 ? null : (
                            <span className="selected-field-count">{selectedFields.length}</span>
                        )}
                    </div>
                    <div className="customer-city-state clip-overflow">{cityState}</div>
                </div>
                <div className="spacer" />
                <div className="field-count" title={`${fieldCount} ${fieldText}`}>
                    <div className="clip-overflow">{fieldCount}</div>
                    <div className="units">{fieldText}</div>
                </div>
                <div className="total-acres" title={`${totalAcres} ${totalAcresText}`}>
                    <div className="clip-overflow">{totalAcres}</div>
                    <div className="units">{totalAcresText}</div>
                </div>
                <CustomerContextMenu
                    customer={customer}
                    fieldCount={fieldCount}
                    onAddEditField={onAddEditField}
                    loadDetailPage={loadDetailPage}
                />
            </div>
        );
    }

    public shouldComponentUpdate(nextProps: ICustomerItemProps) {
        if (
            nextProps.customer !== this.props.customer ||
            nextProps.customerItem !== this.props.customerItem ||
            nextProps.isExpanded !== this.props.isExpanded ||
            nextProps.selectedFields.length !== this.props.selectedFields.length ||
            nextProps.isLast !== this.props.isLast ||
            nextProps.isActiveInactive !== this.props.isActiveInactive
        ) {
            return true;
        }
        for (const fieldGuid of this.props.selectedFields) {
            if (nextProps.selectedFields.find((x) => x === fieldGuid) == null) {
                return true;
            }
        }
        return false;
    }

    private _onClick = (event) => {
        if (event.isDefaultPrevented()) {
            return;
        }
        const { isExpanded, expandItem, collapseItem } = this.props;
        if (isExpanded) {
            collapseItem();
        } else {
            expandItem();
        }
    };
}

const mapDispatchToProps = (dispatch) => ({
    collapseItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx)),
    expandItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx)),
    setScrollToFlatIdx: (selectedTab, flatIdx) =>
        dispatch(actions.setScrollToFlatIdx(selectedTab, flatIdx)),
});

const mapStateToProps = (state, ownProps) => ({
    customerItem: selectors.getDimIdxMap(state)(ownProps.itemDimIdx),
    itemList: selectors.getAccordionItems(state),
    lockCustomersNotEnrolled: getTheUserLockCustomer(state),
    selectedFields: cdSelectors.getSelectedFieldGuidsForCustomer(
        state,
        ownProps.customer.customerGuid
    ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    collapseItem: () => {
        dispatchProps.collapseItem(ownProps.accordionId, ownProps.itemDimIdx);
        dispatchProps.setScrollToFlatIdx(stateProps.activeTab, null);
    },
    expandItem: () => {
        dispatchProps.expandItem(ownProps.accordionId, ownProps.itemDimIdx);
        const flatIdx = accordionModels.getFlatIdxFromDimIdx(
            stateProps.itemList,
            ownProps.itemDimIdx
        );
        dispatchProps.setScrollToFlatIdx(stateProps.activeTab, flatIdx);
    },
});

export const CustomerItem = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(CustomerItem_));
