import React, { Component, ReactNode } from "react";

import _ from "lodash";
import classnames from "classnames";
import { DEFAULT_ORDER_SORT_OPTIONS, ASC, DESC } from "./../constants";
import { getGridColClassName } from "./../utils";
import FilterInput from "./filter-input";
import TableCell from "./table-cell";
import { MiniDownArrowIcon, MiniUpArrowIcon, AddIcon } from "~/core/icons";
import { AppHelpers } from "@ai360/core";
import {
    ISearchData,
    ITableService,
    IFilterQuery,
    IMessages,
    ISortOptions,
} from "../../interfaces";
import { SortDirectionType } from "react-virtualized";

export interface ITableHeaderProps {
    disableSort?: boolean;
    service?: ITableService;
    invisibleAddEdit?: boolean;
    isCheckbox?: boolean;
    isEditable?: boolean;
    showFilterInput?: boolean;
    debounceFilterInput?: boolean;
    autoSearchData?: ISearchData;
    columns: string[];
    onEnter?: (attr: string, value: string) => void;
    onSortOption?: (sortOptions: ISortOptions, filterQuery?: IFilterQuery) => void;
    getAutoSearchList?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    onFilterChange?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    openAddEditPanel?: (modelName: string, method: string) => void;
    messages?: IMessages[];
    formatMessage?: () => void;
    showHideAddButton?: boolean;
}

export interface ITableHeaderState {
    sortOptions: ISortOptions;
    filterQuery: IFilterQuery;
    fieldName: string;
}

export class TableHeader extends Component<ITableHeaderProps, ITableHeaderState> {
    constructor(props: ITableHeaderProps) {
        super(props);
        const { service } = props;
        this.state = {
            sortOptions: service.defaultSort || {},
            filterQuery: {},
            fieldName: null,
        };
    }

    onDebounceTextChange = AppHelpers.debounce((value: string, attr: string) => {
        this.onTextChange(value, attr);
    }, 750);

    private onTextChange = (value: string, attr: string): void => {
        const { filterQuery } = this.state;
        this.setState(
            {
                filterQuery: {
                    ...filterQuery,
                    [attr]: value,
                },
            },
            () => {
                if (this.props.getAutoSearchList) {
                    this.props.getAutoSearchList(
                        this.columnNameToPascalCase(attr),
                        value,
                        this.state.filterQuery
                    );
                }
            }
        );
    };

    private onSelectionChange = (value: string, attr: string): void => {
        const { filterQuery } = this.state;
        this.setState(
            {
                filterQuery: {
                    ...filterQuery,
                    [attr]: value,
                },
            },
            () => {
                const { filterQuery } = this.state;
                if (this.props.onFilterChange) {
                    this.props.onFilterChange(attr, value, filterQuery);
                }
            }
        );
    };

    private columnNameToPascalCase = (columnName: string): string => {
        return columnName.charAt(0).toUpperCase() + columnName.slice(1);
    };

    private getSortDirection = (
        { Direction }: { Direction?: string },
        isFieldNameUnchanged: boolean
    ): SortDirectionType => {
        if (Direction && isFieldNameUnchanged) {
            return Direction === ASC ? DESC : ASC;
        } else {
            return ASC;
        }
    };

    private onSortOption = (columnName: string): void => {
        if (this.props.disableSort) {
            return;
        }
        const { sortOptions } = this.state;
        const { Sort } = sortOptions;
        const fieldName = this.columnNameToPascalCase(columnName);
        this.setState(
            {
                sortOptions: {
                    FieldName: fieldName,
                    Sort: {
                        Direction: this.getSortDirection(
                            Sort || {},
                            this.state.sortOptions.FieldName === fieldName
                        ),
                        Order: DEFAULT_ORDER_SORT_OPTIONS,
                    },
                },
            },
            () => {
                if (this.props.onSortOption) {
                    const { filterQuery, sortOptions } = this.state;

                    if (filterQuery) {
                        this.props.onSortOption({ sortOptions, filterQuery });
                    } else {
                        this.props.onSortOption({ sortOptions });
                    }
                }
            }
        );
    };

    private renderSortIcon = (column: string): ReactNode => {
        const { Sort, FieldName } = this.state.sortOptions;
        if (Sort && FieldName) {
            if (FieldName.toUpperCase() === column.toUpperCase()) {
                if (Sort.Direction === DESC) {
                    return <MiniDownArrowIcon className="data-header-sorting-icon" />;
                } else {
                    return <MiniUpArrowIcon className="data-header-sorting-icon" />;
                }
            }
        }
        return <p />;
    };

    private renderHeader = (column: string, index: number): ReactNode => {
        const {
            service,
            showFilterInput,
            debounceFilterInput,
            autoSearchData,
            messages,
            formatMessage,
            onEnter,
        } = this.props;
        let searchName = "";
        if (autoSearchData) {
            searchName = autoSearchData.searchName;
        }
        if (service.getVisible(column) === false) {
            return false;
        }
        const label = service.getLabelValue(column, messages, formatMessage);
        const columnClassName = service.getClassName(column);
        let sortColumnName = service.getColumnSortOverRide(column);
        sortColumnName = sortColumnName ? sortColumnName : column;
        const selectInputData =
            sortColumnName?.toUpperCase() === searchName.toUpperCase()
                ? autoSearchData.response
                : [];
        const classNames = classnames(
            getGridColClassName(service.getGridColSize(column)),
            "data-header-cell",
            columnClassName
        );
        const sortClassName = classnames(
            "data-header-sort",
            service.getGridColSize(column) === 5 ? " data-header-sort-shrinked" : ""
        );
        return (
            <div
                className={classNames}
                key={index}
                onClick={() => this.onSortOption(sortColumnName)}
            >
                <TableCell key={column} title={label}>
                    {showFilterInput ? (
                        <div className="filter-container" onClick={(e) => e.stopPropagation()}>
                            <FilterInput
                                onEnter={onEnter}
                                onTextChange={
                                    debounceFilterInput
                                        ? this.onDebounceTextChange
                                        : this.onTextChange
                                }
                                attr={sortColumnName}
                                label={label}
                                data={selectInputData}
                                onSelectionChange={this.onSelectionChange}
                            />
                        </div>
                    ) : (
                        label
                    )}
                </TableCell>
                {this.props.disableSort ? null : (
                    <a className={sortClassName} href="#/">
                        {this.renderSortIcon(sortColumnName)}
                    </a>
                )}
            </div>
        );
    };

    render(): ReactNode {
        const {
            service,
            invisibleAddEdit,
            isCheckbox,
            columns,
            openAddEditPanel,
            isEditable,
            showHideAddButton,
        } = this.props;

        const header = _.map(columns, (column: string, index: number) => {
            return this.renderHeader(column, index);
        });
        return (
            <div className="data-table-row data-table-header">
                {!(showHideAddButton && isEditable) ? null : (
                    <TableCell className={"data-table-container"}>
                        <div
                            className={classnames(
                                "data-table-add-icon",
                                invisibleAddEdit ? "invisible-add-edit-icon" : null
                            )}
                            onClick={() =>
                                showHideAddButton &&
                                isEditable &&
                                openAddEditPanel(service.modelName, "ADD")
                            }
                        >
                            <AddIcon />
                        </div>
                    </TableCell>
                )}
                {isCheckbox && (
                    <TableCell className={"data-table-container data-table-checkbox"}></TableCell>
                )}
                {header}
            </div>
        );
    }
}
export default TableHeader;
