import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./hocs.css";

const withSvgIcon = (svgXml, defaultViewBox = "0 0 500 500", className = "") =>
    class SvgIcon extends Component {
        static propTypes = {
            color: PropTypes.string,
            className: PropTypes.string,
            title: PropTypes.string,
            viewBox: PropTypes.string,
        };

        static defaultProps = {
            className: className,
            viewBox: defaultViewBox,
        };

        componentDidMount() {
            if (SVGElement.prototype.blur == null) {
                SVGElement.prototype.blur = () => null;
            }
        }

        shouldComponentUpdate(nextProps) {
            return (
                nextProps.className !== this.props.className ||
                nextProps.title !== this.props.title ||
                nextProps.viewBox !== this.props.viewBox
            );
        }

        render() {
            const { title, viewBox } = this.props;
            return (
                <div className="svg-container" title={title}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={viewBox}
                        overflow="scroll"
                        className={classnames(this.props.className, className, "default-svg-icon")}
                    >
                        {svgXml}
                    </svg>
                </div>
            );
        }
    };

export default withSvgIcon;
