import { createSelector } from "reselect";

import { ACTION_PANEL_STATE_KEY } from "../../../../selectors";
import { LAYER_MODULE_STATE_KEY } from "../../selectors";
import { selectors as accordionSelectors } from "~/accordion";

export const LAYER_LIST_STATE_KEY = "LAYER_LIST_STATE";
export const MAIN_REDUCER_KEY = "MAIN";
export const ACCORDION_KEY = "ACCORDION";

const _getAccordionState = (state) => _getLayerListState(state)[ACCORDION_KEY];
const _getLayerListState = (state) =>
    state[ACTION_PANEL_STATE_KEY][LAYER_MODULE_STATE_KEY][LAYER_LIST_STATE_KEY];
const _getModuleState = (state) => _getLayerListState(state)[MAIN_REDUCER_KEY];

export const getAccordionId = (state) => _getAccordionState(state).accordionId;
export const getAccordionItems = (state) =>
    accordionSelectors.itemsSelector(_getAccordionState(state));
export const getAccordionItemsCount = (state) =>
    accordionSelectors.itemsSelector(_getAccordionState(state)).length;
export const getAccordionRecHeight = (state) => _getAccordionState(state).recHeight;
export const getAccordionRecItemCount = (state) => _getAccordionState(state).recItemCount;
export const getAccordionScrollTop = (state) => _getModuleState(state).accordionInit.scrollTop;
export const getAccordionState = (state) => _getAccordionState(state);
export const getCadastralLegendInfos = (state) => _getModuleState(state).cadastralLegendInfos;
export const getClassificationMethods = (state) => _getModuleState(state).classificationMethods;
export const getColorRamps = (state) => _getModuleState(state).colorRamps;
export const getColorSchemeOptions = (state) => _getModuleState(state).colorSchemeOptions;
export const getCurrentTabIndex = (state) => _getModuleState(state).currentTabIndex;
export const getDimIdxMapSelector = (state) =>
    accordionSelectors.dimIdxMapSelector(_getAccordionState(state));
export const getExpandedFieldGuidSet = (state) => _getModuleState(state).expandedFieldGuidSet;
export const getFieldGuidToFieldMap = (state) => _getModuleState(state).fieldGuidToFieldMap;
export const getFlatIdxMapSelector = (state) =>
    accordionSelectors.flatIdxMapSelector(_getAccordionState(state));
export const getLayerFilter = (state) => _getModuleState(state).filter;
export const getLayerFilterInfo = (state) => _getModuleState(state).filterInfo;
export const getLayerFilterOptions = (state) => _getModuleState(state).filterOptions;
export const getLayerInfos = (state) => _getModuleState(state).layerInfos;
export const getLoadingFieldGuidSet = (state) => _getModuleState(state).loadingFieldGuidSet;
export const getLoadingSampleSitesSet = (state) => _getModuleState(state).loadingSampleSitesSet;
export const getLoadingSurfacesSet = (state) => _getModuleState(state).loadingSurfacesSet;
export const getNumberOfClasses = (state) => _getModuleState(state).numberOfClasses;
export const getPanelLoading = (state) => _getModuleState(state).layerPanelLoading;
export const getShowFilters = (state) => _getModuleState(state).showFilters;
export const getSplitScreenCompareTool = (state) => _getModuleState(state).splitScreenCompareTool;
export const getSplitScreenCompareToolIsOpen = (state) =>
    _getModuleState(state).splitScreenCompareTool.fieldGuid != null;
export const getVisibleSampleSitesMap = (state) => _getModuleState(state).visibleSampleSites;
export const getVisibleSurfacesMap = (state) => _getModuleState(state).visibleSurfaces;
export const getSearchValue = (state) => _getModuleState(state).searchValue;

export const getFilteredLayers = createSelector(
    getLayerFilter,
    getLayerFilterInfo,
    (filter, filterInfo) => {
        const matches = (f, r) => f == null || f.length === 0 || r === f;
        const searchTokens = [filter.croppingSeasonGuid, filter.cropGuid, filter.layerType].filter(
            (f) => f != null && f.length > 0
        );
        if (searchTokens.length > 0 && filterInfo.filters.all.length > 0) {
            return filterInfo.filters.all.filter(({ croppingSeasonGuid, cropGuid, layerType }) => {
                return (
                    matches(filter.croppingSeasonGuid, croppingSeasonGuid) &&
                    matches(filter.cropGuid, cropGuid) &&
                    matches(filter.layerType, layerType)
                );
            });
        }
        return null;
    }
);

export const getFilteredFieldGuids = createSelector(getFilteredLayers, (filteredLayers) => {
    if (filteredLayers != null) {
        return new Set(filteredLayers.map(({ fieldGuid }) => fieldGuid));
    }
    return null;
});
