import { defineMessages } from "react-intl";

export const messages = defineMessages({
    acresText: {
        id: "importWizard.acresText",
        defaultMessage: "{acres} {acres, plural, one {Acre} other {Acres}}",
    },
    addBoundaryMessage: {
        id: "importWizard.addBoundaryMessage",
        defaultMessage: "Add Boundary Using Draw Tools",
    },
    addBoundaryText: {
        id: "importWizard.addBoundaryText",
        defaultMessage: "Add Boundary",
    },
    addEditFieldBoundaryText: {
        id: "importWizard.addEditFieldText",
        defaultMessage: "{count, plural, one {Edit Field Boundary} other {Add Field Boundary}}",
    },
    addImportFieldsError: {
        id: "apiError.addImportFieldsError",
        defaultMessage: "Failed to add the import fields",
    },
    agvanceCustomer: {
        id: "importWizard.agvanceCustomer",
        defaultMessage: "Agvance Customer",
    },
    allFieldsLocked: {
        id: "importWizard.allFieldsLocked",
        defaultMessage: "All matching Fields are not Enrolled",
    },
    appendEventError: {
        id: "apiError.appendEventError",
        defaultMessage: "Failed to append the event",
    },
    appendSelectText: {
        id: "importWizard.appendSelectText",
        defaultMessage: "Potential Matches To Previously Saved Event(s)",
    },
    appendToTitle: {
        id: "importWizard.appendToTitle",
        defaultMessage: "2. Merge Previously Saved Event(s)",
    },
    cancelImportWizardError: {
        id: "apiError.cancelImportWizardError",
        defaultMessage: "Unable to cancel the import grooming session",
    },
    confirmSaveBelowMinAcreage: {
        id: "importWizard.confirmSaveBelowMinAcreage",
        defaultMessage:
            "One or more polygons are less than {minAcres, number} acres.  Save anyway?",
    },
    confirmSaveBelowTitle: {
        id: "importWizard.confirmSaveBelowTitle",
        defaultMessage: "Confirm",
    },
    createEventError: {
        id: "apiError.createEventError",
        defaultMessage: "Failed to create the event",
    },
    createText: { id: "importWizard.createText", defaultMessage: "Create New" },
    crop: { id: "importWizard.crop", defaultMessage: "Crop" },
    croppingSeason: {
        id: "importWizard.croppingSeason",
        defaultMessage: "Season",
    },
    currentEventTitle: {
        id: "importWizard.currentEventTitle",
        defaultMessage: "1. New Data Being Imported",
    },
    customer: { id: "importWizard.customer", defaultMessage: "Customer" },
    customerName: {
        id: "importWizard.customerNameText",
        defaultMessage: "Customer Name",
    },
    editBoundaryMessage: {
        id: "importWizard.editBoundaryMessage",
        defaultMessage: "Optionally Edit Boundary Using Draw Tools",
    },
    editBoundaryText: {
        id: "importWizard.editBoundaryText",
        defaultMessage: "Edit Boundary",
    },
    editCustomerFieldText: {
        id: "importWizard.editCustomerFieldText",
        defaultMessage: "Edit Customer/Field",
    },
    editEventId: {
        id: "importWizard.editEventId",
        defaultMessage: "Edit Event ID",
    },
    editEventName: {
        id: "importWizard.editEventName",
        defaultMessage: "Enter Event Name",
    },
    errorAboveMaxAcreage: {
        id: "importWizard.errorAboveMaxAcreage",
        defaultMessage:
            "This field is larger in area than the system limit of {maxAcres, number} acres.  You need to reduce the size of the field below this limit.",
    },
    errorAboveMaxAcreageTitle: {
        id: "importWizard.errorAboveMaxAcreageTitle",
        defaultMessage: "Error",
    },
    eventDate: { id: "importWizard.eventDate", defaultMessage: "Event Date" },
    eventId: { id: "importWizard.eventId", defaultMessage: "Event ID" },
    eventName: { id: "importWizard.eventName", defaultMessage: "Event Name" },
    eventStripStatusText: {
        id: "importWizard.eventStripStatusText",
        defaultMessage: "{pointCount, number} ({statusStr})",
    },
    eventType: { id: "importWizard.eventType", defaultMessage: "Event Type" },
    farmNamePlaceholderText: {
        id: "importWizard.farmNamePlaceholderText",
        defaultMessage: "Farm Name",
    },
    features: { id: "importWizard.features", defaultMessage: "Features" },
    fetchEventStripDataError: {
        id: "apiError.fetchEventStripDataError",
        defaultMessage: "Unable to fetch the event strip data",
    },
    fetchFieldStripDataError: {
        id: "apiError.fetchFieldStripDataError",
        defaultMessage: "Unable to fetch the field strip data",
    },
    fetchFieldDataError: {
        id: "apiError.fetchFieldDataError",
        defaultMessage: "Unable to fetch the field data",
    },
    fetchGroomingDataError: {
        id: "apiError.fetchGroomingDataError",
        defaultMessage: "Unable to fetch the grooming data",
    },
    fetchSamplingPointsError: {
        id: "apiError.fetchSamplingPointsError",
        defaultMessage: "Unable to fetch the sampling point data",
    },
    fetchUserCropImportPrefError: {
        id: "apiError.fetchUserCropImportPrefError",
        defaultMessage: "Failed to fetch the user crop import preferences",
    },
    fieldBoundaryImportText: {
        id: "importWizard.fieldBoundaryImportText",
        defaultMessage: "Field Boundary Import",
    },
    fieldBoundaryImportError: {
        id: "importWizard.fieldBoundaryImportError",
        defaultMessage: "Encountered a server error while trying to import {fieldName}.",
    },
    fieldMatchingFailed: {
        id: "importWizard.fieldMatchingFailed",
        defaultMessage: "Field Matching Failed!",
    },
    fieldName: { id: "importWizard.fieldName", defaultMessage: "Field Name" },
    fieldNamePlaceholderText: {
        id: "importWizard.fieldNamePlaceholderText",
        defaultMessage: "Field Name",
    },
    ignoreFarm: {
        id: "importWizard.ignoreFarm",
        defaultMessage: "Ignore Farm",
    },
    importWizardAddFieldText: {
        id: "importWizard.addFieldText",
        defaultMessage: "{count, plural, one {Add Field} other {Add Fields}}",
    },
    importWizardCreateEventsText: {
        id: "importWizard.createEventsText",
        defaultMessage: "{count, plural, one {Create Event} other {Create Events}}",
    },
    importWizardCreateEventsSamplingCentroidText: {
        id: "importWizard.CreateEventsSamplingCentroidText",
        defaultMessage: "Sampling Centroid Import",
    },
    importWizardCreateLayersText: {
        id: "importWizard.createLayersText",
        defaultMessage: "{count, plural, one {Create Layer} other {Create Layers}}",
    },
    importWizardEventsText: {
        id: "importWizard.eventsText",
        defaultMessage: "Events",
    },
    importWizardFieldsText: {
        id: "importWizard.fieldsText",
        defaultMessage: "Fields",
    },
    importWizardFilesText: {
        id: "importWizard.filesText",
        defaultMessage: "Files Info",
    },
    importWizardLayersText: {
        id: "importWizard.layersText",
        defaultMessage: "{count, plural, one {Layer} other {Layers}}",
    },
    initializeImportWizardError: {
        id: "apiError.initializeImportWizardError",
        defaultMessage: "Unable to initialize the Import Wizard",
    },
    intersectingShapesText: {
        id: "importWizard.intersectingShapesText",
        defaultMessage:
            "* The selected Event data has been imported before in the Event(s) in red and cannot be merged with the previously saved Event(s).",
    },
    mergeText: { id: "importWizard.mergeText", defaultMessage: "Merge" },
    mergeErrorText: {
        id: "importWizard.mergeErrorText",
        defaultMessage: "Merge Error",
    },
    noValidPolygonsMsg: {
        id: "importWizard.noValidPolygonsMsg",
        defaultMessage:
            "The current edits resulted in no valid polygons for this " +
            "field boundary. Would you like to remove it from the import list?",
    },
    noValidPolygonsTitle: {
        id: "importWizard.noValidPolygonsTitle",
        defaultMessage: "Confirm",
    },
    pointsText: { id: "importWizard.pointsText", defaultMessage: "Points" },
    removeFieldText: {
        id: "importWizard.removeFieldText",
        defaultMessage: "Remove",
    },
    removeStatisticalOutliersText: {
        id: "importWizard.removeStatisticalOutliersText",
        defaultMessage: "Remove Statistical Outliers",
    },
    removeText: { id: "importWizard.removeText", defaultMessage: "Remove" },
    removeYieldOutliersText: {
        id: "importWizard.removeYieldOutliersText",
        defaultMessage: "Remove Yield Outliers",
    },
    rerunMatchingError: {
        id: "apiError.rerunMatchingError",
        defaultMessage: "Failed to Rerun Matching",
    },
    rerunMatchingText: {
        id: "importWizard.rerunMatchingText",
        defaultMessage: "Rerun Matching",
    },
    selectEventId: {
        id: "importWizard.selectEventId",
        defaultMessage: "Select Event ID",
    },
    unmatchFieldFileError: {
        id: "apiError.unmatchFieldFileError",
        defaultMessage: "Failed to Unmatch/remove field file",
    },
    unmatchText: { id: "importWizard.unmatchText", defaultMessage: "Unmatch" },
    unmatchedLayersText: {
        id: "importWizard.unmatchedLayersText",
        defaultMessage: "Unmatched Layers",
    },
    unmatchedPointsText: {
        id: "importWizard.unmatchedPointsText",
        defaultMessage: "Unmatched Points",
    },
    updateAttributeError: {
        id: "apiError.updateAttributeError",
        defaultMessage: "Failed to update the attribute",
    },
    updateEventStatusError: {
        id: "apiError.updateEventStatusUpdateError",
        defaultMessage: "Failed to update the event status",
    },
    updateFieldBoundaryError: {
        id: "apiError.updateFieldBoundaryError",
        defaultMessage: "Unable to update the field boundary data",
    },
    updateUserCropImportPrefError: {
        id: "apiError.updateUserCropImportPrefError",
        defaultMessage: "Failed to update the user crop import preferences",
    },
    validateEventError: {
        id: "apiError.validateEventError",
        defaultMessage: "Failed to validate the event",
    },
    validationFailuresText: {
        id: "importWizard.validationFailuresText",
        defaultMessage: "Validation Failures",
    },
});
