import { defineMessages } from "react-intl";

export const messages = defineMessages({
    errorAboveMaxAcreage: {
        id: "nonFieldFeatureInformation.errorAboveMaxAcreage",
        defaultMessage:
            "This field is larger in area than the system limit of {maxAcres, number} acres.  You need to reduce the size of the field below this limit.",
    },
    errorAboveMaxAcreageTitle: {
        id: "nonFieldFeatureInformation.errorAboveMaxAcreageTitle",
        defaultMessage: "Error",
    },
    errorInvalidGeometriesTitle: {
        id: "nonFieldFeatureInformation.errorInvalidGeometriesTitle",
        defaultMessage: "Geometry Error",
    },
    errorInvalidGeometries: {
        id: "nonFieldFeatureInformation.errorInvalidGeometries",
        defaultMessage:
            "Geometries are intersecting.  Please clip any intersections before saving.",
    },
});
