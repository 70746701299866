import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { resetLogin } from "../actions";
import { getSecurityMessageCode } from "../selectors";
import { messages } from "../i18n-messages";

import { Button } from "../../core";

export interface ISecurityMessage_Props {
    securityMessageCode?: number;
    onResetLogin: () => void;
    intl: intlShape;
}
class SecurityMessage_ extends Component<ISecurityMessage_Props> {
    public close() {
        this.props.onResetLogin();
    }

    public getSecurityMessage() {
        const { formatMessage } = this.props.intl;
        switch (this.props.securityMessageCode) {
            case 0:
                return formatMessage(messages.accountLocked);
            case 1:
                return formatMessage(messages.accountForcefullyReset);
            case 2:
                return formatMessage(messages.accountInactive);
            case 3:
                return formatMessage(messages.noActiveHierarchyAffiliations);
            case 4:
                return formatMessage(messages.passwordReset);
            default:
                return formatMessage(messages.unknownCode);
        }
    }

    public getClosingMessage() {
        const { formatMessage } = this.props.intl;
        switch (this.props.securityMessageCode) {
            case 1:
                return formatMessage(messages.incorrectAnswers);
            default:
                return;
        }
    }

    render() {
        const message = this.getSecurityMessage();
        const closing = this.getClosingMessage();
        return (
            <div>
                <div className="login-input-container">
                    <div className="display-label">{message}</div>
                    <div className="display-label">{closing}</div>
                </div>
                <div className="login-center">
                    <Button
                        tabIndex={1}
                        className="login-btn cancel-btn"
                        type="close"
                        onClick={() => this.close()}
                    />
                </div>
            </div>
        );
    }
}
export const SecurityMessage = injectIntl(SecurityMessage_);

const mapStateToProps = (state) => {
    return {
        securityMessageCode: getSecurityMessageCode(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onResetLogin: () => dispatch(resetLogin()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityMessage);
