export const RecModulePages = {
    REC_LIST: "REC_LIST",
    REC_INFORMATION: "REC_INFORMATION",
};

export const SET_ACTIVE_PAGE = "rec-module/SET_ACTIVE_PAGE";
export const SET_TRIGGERED_PAGE = "rec-module/SET_TRIGGERED_PAGE";

export const setActivePage = (activePage) => ({
    type: SET_ACTIVE_PAGE,
    payload: { activePage },
});

export const setTriggeredPage = (triggeredPage) => ({
    type: SET_TRIGGERED_PAGE,
    payload: { triggeredPage },
});
