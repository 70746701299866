import { takeLatest, put, select } from "redux-saga/effects";

import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { PersonAPI } from "@ai360/core";

import * as actions from "./actions";

const requestResetPassword = function* (action) {
    const { email, successMessage } = action.payload;
    if (email) {
        const UserGuid = yield select(getTheUserGuid);
        try {
            yield PersonAPI.resetUserPassword(UserGuid, email);
            yield put(actions.resetPasswordSuccess());
            yield put(notificationActions.pushToasterMessage(successMessage, MSGTYPE.SUCCESS));
        } catch (error) {
            yield put(actions.resetPasswordFailed({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.resetPasswordFailed("No Data"));
    }
};

export const resetPasswordSaga = function* () {
    yield takeLatest(actions.resetPassword, requestResetPassword);
};
