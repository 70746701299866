import * as models from "./models";

export const ADD_AND_UPLOAD_FILE_INFO = "drag-and-drop-file-import/ADD_AND_UPLOAD_FILE_INFO";
export const ADD_BROWSER_FILELIST = "drag-and-drop-file-import/ADD_BROWSER_FILELIST";
export const ADD_UPLOAD_INFO = "drag-and-drop-file-import/ADD_UPLOAD_INFO";
export const CLEAR_UPLOAD_FILE_LIST = "drag-and-drop-file-import/CLEAR_UPLOAD_FILE_LIST";
export const PROCESS_UPLOADS = "drag-and-drop-file-import/PROCESS_UPLOADS";
export const REMOVE_FILE_FROM_LIST = "drag-and-drop-file-import/REMOVE_FILE_FROM_LIST";
export const SET_DUPLICATE_FILE_ERROR = "drag-and-drop-file-import/SET_DUPLICATE_FILE_ERROR";
export const SET_IS_PREPARING_UPLOAD = "drag-and-drop-file-import/SET_IS_PREPARING_UPLOAD";
export const SET_SELECTED_IMPORT_TYPE = "upload-modal/SET_SELECTED_IMPORT_TYPE";
export const SET_SELECTED_TEMPLATE = "drag-and-drop-file-import/SET_SELECTED_TEMPLATE";
export const SET_VALID_EXTENSIONS = "drag-and-drop-file-import/SET_VALID_EXTENSIONS";
export const SETUP_DRAG_AND_DROP_FILE_UPLOADER_CHILD_STATE =
    "drag-and-drop-file-import/SETUP_DRAG_AND_DROP_FILE_UPLOADER_CHILD_STATE";
export const UPDATE_UPLOAD_FILE_INFOS = "drag-and-drop-file-import/UPDATE_UPLOAD_FILE_INFOS";
export const UPLOAD_FILE = "drag-and-drop-file-import/UPLOAD_FILE";
export const UPLOAD_COMPLETED = "drag-and-drop-file-import/UPLOAD_COMPLETED";

export const addBrowserFileList = (stateKey: string, browserFileList) => ({
    type: ADD_BROWSER_FILELIST,
    payload: { browserFileList, stateKey },
});

export const addUploadFileInfoList = (stateKey: string, uploadFileList) => ({
    type: ADD_UPLOAD_INFO,
    payload: { stateKey, uploadFileList },
});

export const clearUploadFileList = (stateKey: string) => ({
    type: CLEAR_UPLOAD_FILE_LIST,
    payload: { stateKey },
});

export const processUploads = (stateKey: string) => ({
    type: PROCESS_UPLOADS,
    payload: { stateKey },
});

export const removeFileFromList = (stateKey: string, itemDimIdx, uploadFileInfoGuid) => ({
    type: REMOVE_FILE_FROM_LIST,
    payload: { itemDimIdx, stateKey, uploadFileInfoGuid },
});

export const setDuplicateFileError = (stateKey: string, isDuplicateFileError) => ({
    type: SET_DUPLICATE_FILE_ERROR,
    payload: { isDuplicateFileError, stateKey },
});

export const setIsPreparingUpload = (stateKey: string, isPreparingUpload) => ({
    type: SET_IS_PREPARING_UPLOAD,
    payload: { isPreparingUpload, stateKey },
});

export const setSelectedImportType = (stateKey: string, selectedImportType) => ({
    type: SET_SELECTED_IMPORT_TYPE,
    payload: { selectedImportType, stateKey },
});

export const setSelectedTemplate = (stateKey: string, selectedTemplate) => ({
    type: SET_SELECTED_TEMPLATE,
    payload: { selectedTemplate, stateKey },
});

export const setValidExtensions = (stateKey: string, validExtensions: string[]) => ({
    type: SET_VALID_EXTENSIONS,
    payload: { stateKey, validExtensions },
});

// saga only actions
export const addAndUploadFileInfo = (
    stateKey: string,
    importTypeGuid,
    template,
    uploadingStatusGuid,
    uploadFilesInfoList
) => ({
    type: ADD_AND_UPLOAD_FILE_INFO,
    payload: {
        importTypeGuid,
        stateKey,
        template,
        uploadFilesInfoList,
        uploadingStatusGuid,
    },
});

export const updateUploadFileInfos = (
    stateKey: string,
    uploadFileInfos: models.UploadFileInfo[]
) => ({
    type: UPDATE_UPLOAD_FILE_INFOS,
    payload: { stateKey, uploadFileInfos },
});

export const uploadFile = (
    stateKey: string,
    uploadFileInfo,
    importTypeGuid,
    templateGuid,
    signedUrlDict
) => ({
    type: UPLOAD_FILE,
    payload: { uploadFileInfo, importTypeGuid, signedUrlDict, stateKey, templateGuid },
});

export const uploadCompleted = (stateKey: string, importFileGuid) => ({
    type: UPLOAD_COMPLETED,
    payload: {
        importFileGuid,
        stateKey,
    },
});
