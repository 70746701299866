import { all, AllEffect, put, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import * as actions from "./actions";

const opened = function* (action: actions.IWindowAction) {
    const channel = eventChannel((emit) => {
        const listener = (event: BeforeUnloadEvent) => {
            emit(event);
        };

        const event = "beforeunload";
        action.window.addEventListener(event, listener);
        return () => {
            window.removeEventListener(event, listener);
        };
    });

    yield takeEvery(channel, function* () {
        const name = action.name;
        yield put(actions.closing(name));
        channel.close();
    });
};

const close = function* (action: actions.IWindowAction) {
    yield put(actions.closing(action.name));
};

export const windowSaga = function* (): Generator<AllEffect, void, unknown> {
    yield all([takeEvery(actions.OPENED, opened), takeEvery(actions.CLOSE, close)]);
};
