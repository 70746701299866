import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import {
    PICKLIST_EQUIPMENT_PARENT_TYPE,
    PICKLIST_MANUFACTURER,
    PICKLIST_PHYSICAL_STATE,
    PICKLIST_CLASS_SIZE,
    PICKLIST_TRANSMISSION_TYPE,
    PICKLIST_DRIVE_TRAIN,
    PICKLIST_FUEL_TYPE,
    PICKLIST_EQUIPMENT_TYPE_NAME,
    getPickListCode,
} from "~/core/picklist/picklist-names";

import {
    UNIT_WEIGHT,
    UNIT_LENGTH,
    UNIT_SPEED,
    UNIT_VOLUME,
    UNIT_FUEL_EFFICIENCY,
    UNIT_POWER,
    getUnitCode,
} from "~/core/units/unit-names";

export const MODEL_NAME = "equipment";
export const URL = apiUrl("AgBytes/GetEquipmentList");
export const GETRECORD = apiUrl("AgBytes/GetEquipment");
export const NEXT_ID = apiUrl("AgBytes/GetNextEquipmentId");
export const CREATE = apiUrl("AgBytes/AddEquipment");
export const UPDATE = apiUrl("AgBytes/UpdateEquipment");
export const DELETE = apiUrl("AgBytes/DeleteEquipmentList");
export const EXPORT_FILE_NAME = "EquipmentExport";
export const EXPORT_URL = apiUrl("AgBytes/ExportEquipmentList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetEquipmentAutoSearchList");
export const IMPORT_URL = apiUrl("AgBytes/EquipmentImport");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidEquipmentImport");
export const SELECT_ALL = apiUrl("AgBytes/GetEquipmentSelectAllList");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "equipmentFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "equipmentSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "equipmentPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        equipmentId: "",
        equipmentParentType: "",
        equipmentType: "",
        manufacturer: "",
        equipmentName: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "EquipmentParentType",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultEquipment = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_AGGATEWAY_ID]: "",
    [model.PROPS_CAN_DELETE]: "",
    [model.PROPS_CLASS_SIZE]: "",
    [model.PROPS_CLASS_SIZE_GUID]: "",
    [model.PROPS_CONTAINER_SIZE]: "",
    [model.PROPS_CONTAINER_SIZE_UNIT]: "",
    [model.PROPS_CONTAINER_SIZE_UNIT_GUID]: "",
    [model.PROPS_CYLINDERS]: "",
    [model.PROPS_DESCRIPTION]: "",
    [model.PROPS_DIMENSION_UNIT]: "",
    [model.PROPS_DIMENSION_UNIT_GUID]: "",
    [model.PROPS_DRIVE_TRAIN]: "",
    [model.PROPS_DRIVE_TRAIN_GUID]: "",
    [model.PROPS_ENGINE_DISPLACEMENT]: "",
    [model.PROPS_EQUIPMENT_GUID]: "",
    [model.PROPS_EQUIPMENT_ID]: "",
    [model.PROPS_EQUIPMENT_MANUFACTURER]: "",
    [model.PROPS_EQUIPMENT_NAME]: "",
    [model.PROPS_EQUIPMENT_PARENT_TYPE]: "",
    [model.PROPS_EQUIPMENT_PARENT_TYPE_GUID]: "",
    [model.PROPS_EQUIPMENT_PHOTOLIST]: [],
    [model.PROPS_EQUIPMENT_TYPE]: "",
    [model.PROPS_EQUIPMENT_TYPE_ALIASLIST]: [],
    [model.PROPS_EQUIPMENT_TYPE_GUID]: "",
    [model.PROPS_FUEL_CAPACITY]: "",
    [model.PROPS_FUEL_CAPACITY_UNIT]: "",
    [model.PROPS_FUEL_CAPACITY_UNIT_GUID]: "",
    [model.PROPS_FUEL_EFFICIENCY]: "",
    [model.PROPS_FUEL_EFFICIENCY_UNIT]: "",
    [model.PROPS_FUEL_EFFICIENCY_UNIT_GUID]: "",
    [model.PROPS_FUEL_TYPE]: "",
    [model.PROPS_FUEL_TYPE_GUID]: "",
    [model.PROPS_HEIGHT]: "",
    [model.PROPS_HORSE_POWER]: "",
    [model.PROPS_HORSE_POWER_UNIT]: "",
    [model.PROPS_HORSE_POWER_UNIT_GUID]: "",
    [model.PROPS_LENGTH]: "",
    [model.PROPS_MANUFACTURER]: "",
    [model.PROPS_MANUFACTURER_GUID]: "",
    [model.PROPS_MAX_GROSS_WEIGHT]: "",
    [model.PROPS_MAX_SPEED]: "",
    [model.PROPS_MAX_SPEED_UNIT]: "",
    [model.PROPS_MAX_SPEED_UNIT_GUID]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_PHYSICAL_STATE_GUID]: "",
    [model.PROPS_TARE_WEIGHT]: "",
    [model.PROPS_TOWING_CAPACITY]: "",
    [model.PROPS_TOWING_CAPACITY_UNIT]: "",
    [model.PROPS_TOWING_CAPACITY_UNIT_GUID]: "",
    [model.PROPS_TRANSMISSION_TYPE]: "",
    [model.PROPS_TRANSMISSION_TYPE_GUID]: "",
    [model.PROPS_WEIGHT_UNIT]: "",
    [model.PROPS_WEIGHT_UNIT_GUID]: "",
    [model.PROPS_WIDTH]: "",
});

export const pickLists = {
    [PICKLIST_EQUIPMENT_PARENT_TYPE]: getPickListCode(PICKLIST_EQUIPMENT_PARENT_TYPE),
    [PICKLIST_MANUFACTURER]: getPickListCode(PICKLIST_MANUFACTURER),
    [PICKLIST_PHYSICAL_STATE]: getPickListCode(PICKLIST_PHYSICAL_STATE),
    [PICKLIST_CLASS_SIZE]: getPickListCode(PICKLIST_CLASS_SIZE),
    [PICKLIST_TRANSMISSION_TYPE]: getPickListCode(PICKLIST_TRANSMISSION_TYPE),
    [PICKLIST_DRIVE_TRAIN]: getPickListCode(PICKLIST_DRIVE_TRAIN),
    [PICKLIST_FUEL_TYPE]: getPickListCode(PICKLIST_FUEL_TYPE),
    [PICKLIST_EQUIPMENT_TYPE_NAME]: getPickListCode(PICKLIST_EQUIPMENT_TYPE_NAME),
};

export const unitTypes = {
    [UNIT_WEIGHT]: getUnitCode(UNIT_WEIGHT),
    [UNIT_LENGTH]: getUnitCode(UNIT_LENGTH),
    [UNIT_SPEED]: getUnitCode(UNIT_SPEED),
    [UNIT_VOLUME]: getUnitCode(UNIT_VOLUME),
    [UNIT_FUEL_EFFICIENCY]: getUnitCode(UNIT_FUEL_EFFICIENCY),
    [UNIT_POWER]: getUnitCode(UNIT_POWER),
};

export const service = createService({
    id: model.PROPS_EQUIPMENT_ID,
    guid: model.PROPS_EQUIPMENT_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    unitTypes,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_EQUIPMENT_ID]: { label: "equipmentId", gridCol: 5 },
        [model.PROPS_EQUIPMENT_PARENT_TYPE]: {
            label: "equipmentParentType",
            gridCol: 10,
        },
        [model.PROPS_EQUIPMENT_TYPE]: { label: "equipmentType", gridCol: 15 },
        [model.PROPS_EQUIPMENT_MANUFACTURER]: {
            label: "manufacturer",
            gridCol: 15,
        },
        [model.PROPS_EQUIPMENT_NAME]: { label: "equipmentName", gridCol: 15 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => Object.assign({}, defaultEquipment()),
    defaultSort,
});
