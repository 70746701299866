import React, { Component } from "react";
import { NoLink } from "../..";
import classnames from "classnames";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";

const messages = defineMessages({
    importFileText: {
        id: "fileSelector.importFileText",
        defaultMessage: "Import",
    },
});

export interface IFileSelectorProps {
    className?: string;
    label?: string;
    fileType?: string;
    fileCompletedCallback?: (fileObject: Record<string, any>) => Record<string, any>;
    removeHeaderFromString?: boolean;
    reloadData?: (reload: boolean) => void;
    id?: string;
    value?: any;
    title?: string;
    allowMultiple?: boolean;
}
export class FileSelector_ extends Component<IFileSelectorProps> {
    input: HTMLInputElement;

    static REQUIRED_TEXT = "*";

    public static defaultProps = {
        className: "",
        fileType: ".csv",
        fileCompletedCallback: (): void => null,
        removeHeaderFromString: true,
        label: messages.defaultSelectFileText,
        value: "",
        id: "file",
        title: "",
    };

    public onChange(event: MouseEvent | Record<string, any>): void {
        const fileObject = {
            fileCompletedCallback: this.props.fileCompletedCallback,
            removeHeaderFromString: this.props.removeHeaderFromString,
            files: event.target.files,
            fileType: this.props.fileType,
            reloadData: this.props.reloadData,
        };
        this.props.fileCompletedCallback(fileObject);
    }

    public clickTheButton = (): void => {
        document.getElementById(this.props.id).click();
    };

    render(): JSX.Element {
        // label prop should be internationalized in the parent compoenent
        const formattedLabel = this.props.label || (
            <FormattedMessage {...messages.importFileText} />
        );
        const clsNames = ["file-selector-container", this.props.className];
        const clickCall = this.clickTheButton;
        const labelStyle = {
            display: "none",
        };

        const inputProps = {
            type: "file",
            id: this.props.id,
            value: this.props.value,
            accept: this.props.fileType,
            ref: (input) => (this.input = input),
            multiple: this.props.allowMultiple ? this.props.allowMultiple : false,
        };

        return (
            <div className={classnames(clsNames)}>
                <NoLink
                    className="menu-items-text"
                    title={this.props.title}
                    label={formattedLabel}
                    onClick={() => clickCall()}
                />
                <input
                    {...inputProps}
                    style={labelStyle}
                    name="file"
                    onChange={(evt) => this.onChange(evt)}
                />
            </div>
        );
    }
}
export const FileSelector = injectIntl(FileSelector_);
