import type { FieldAPI, ReportBooksAPI } from "@ai360/core";
import React, { Component } from "react";
import { messages } from "../i18n-messages";
import Reports from "~/reports/reports";
import { ReportOptions } from "./report-options";
import { ReportOptions as ReportOptionsRedux } from "./report-options-redux";
import { IFileNameFormat, IReportTypePage, ITotalingUnit } from "../data/interfaces";
import { ITestingLabVendor } from "~/vendor/model";
import { AgEventSummary } from "~/recs-events/events/models";
import { ISelectorCropDataReturn } from "../data/selectors";
import { RecSummary } from "~/recs-events/recs/model";
import ReportOptionsAccordionItem from "./report-options-accordion-item";
import { IReportData } from "../hocs/with-report";
import { Button } from "~/core";
import ReportOptionsWrapper from "../reports/report-options-wrapper";

interface IReportOptionsContainerProps {
    accordionStyle?: boolean;
    agEventSummaryMap?: Map<string, AgEventSummary>;
    alwaysShowDefaultOptions?: boolean;
    createReport: (reportData?: IReportData) => void;
    crops?: ISelectorCropDataReturn[];
    cropPurpose?: { label: string; value: string }[];
    defaultFileNameFormat?: string;
    defaultMultiFieldRec?: boolean;
    formatMessage: (message: string, messageOptions?: { count: number }) => string;
    fileNameFormats?: IFileNameFormat[];
    getTestPackageListData?: (guid: string) => void;
    hideCreateButton?: boolean;
    hideDefaultOptions?: boolean;
    hideOptionsHeader?: boolean;
    hideReportOptions?: boolean;
    legendType?: string;
    mergeReportData?: (payload: { reportType?: string; reportData: Record<string, any> }) => void;
    onClickCategories?: (type: number) => void;
    onInputChange?: (key: string, value: any, reportType?: string) => void;
    onReportOptionsConfigChange?: (config: Record<string, any>) => void;
    recGeneralGuidToRecMap?: Map<string, RecSummary>;
    reportData?: { [key: string]: IReportData };
    reportPreferences: any;
    reportTypePages: IReportTypePage[];
    seasons?: FieldAPI.ISeason[];
    selectedCrop?: FieldAPI.ICrop;
    selectedEventGuidSet?: Set<string>;
    selectedFieldCount?: number;
    selectedFields?: FieldAPI.IFieldInfo[] | ReportBooksAPI.IField[];
    selectedFileNameFormat?: string;
    selectedRecGuidSet?: Set<string>;
    selectedReportTypes: string[];
    showReportBookGroupBy?: boolean;
    showIndividualRBOption?: boolean;
    totalingUnit?: ITotalingUnit[];
    testingLabVendors?: ITestingLabVendor[];
    testPackages?: {
        testPackageGuid: string;
        testPackageId: string;
        vendorGuid: string;
        sampleTypeGuid: string;
        sampleTypeName: string;
        label: string;
        value: string;
    }[];
    useRedux?: boolean;
}

export class ReportOptionsContainer extends Component<IReportOptionsContainerProps> {
    constructor(props: IReportOptionsContainerProps) {
        super(props);
    }

    private onInputChange(key, value, reportType = undefined) {
        if (this.props.onInputChange != null) {
            this.props.onInputChange(key, value, reportType);
        }
    }

    private getReportOptionsProps(reportType: string) {
        const selectedReportTypes = reportType ? [reportType] : [];
        const effectiveReportType = reportType || "default";
        const reportData = this.props.reportData[effectiveReportType];
        return {
            alwaysShowDefaultOptions: false,
            crops: [],
            hideCreateButton: false,
            hideDefaultOptions: false,
            hideOptionsHeader: false,
            hideReportOptions: false,
            seasons: [],
            selectedEventGuidSet: new Set<string>(),
            selectedFieldCount: 0,
            selectedFields: [],
            selectedRecGuidSet: new Set<string>(),
            showReportBookGroupBy: false,
            showIndividualRBOption: false,
            ...this.props,
            reportData,
            selectedReportTypes: selectedReportTypes,
            onInputChange: (key, value) => {
                this.onInputChange(key, value, effectiveReportType);
            },
        };
    }

    private getReduxReportOptions() {
        if (this.props.selectedReportTypes.length > 1) {
            const reportTypes = [...this.props.selectedReportTypes].sort();
            return (
                <div className="report-options-accordion-container">
                    {reportTypes.map((selectedReportType) => {
                        return (
                            <ReportOptionsAccordionItem
                                key={selectedReportType}
                                {...this.getReportOptionsProps(selectedReportType)}
                            />
                        );
                    })}
                </div>
            );
        } else if (this.props.selectedReportTypes.length === 1) {
            const CustomOptionsComponent = Reports[this.props.selectedReportTypes[0]];
            if (CustomOptionsComponent) {
                return (
                    <ReportOptionsWrapper
                        ReportOptions={CustomOptionsComponent}
                        hideDefaultOptions={this.props.hideDefaultOptions}
                    />
                );
            }
        }
        return (
            <ReportOptionsRedux
                {...this.getReportOptionsProps(this.props.selectedReportTypes[0])}
            />
        );
    }

    getLabelString = (): string => {
        return `2. ${this.props.formatMessage(messages.options)}:`;
    };

    render() {
        if (this.props.useRedux) {
            return (
                <div className="report-options-inner-container">
                    <div className="report-options-header">
                        {!this.props.hideOptionsHeader ? (
                            <div className="options-header">{this.getLabelString()}</div>
                        ) : null}
                    </div>
                    {this.getReduxReportOptions()}
                    {!this.props.hideCreateButton ? (
                        <Button
                            className="create-button"
                            type="createReports"
                            onClick={this.props.createReport}
                        />
                    ) : null}
                </div>
            );
        }

        const reportOptions = {
            alwaysShowDefaultOptions: false,
            crops: [],
            hideCreateButton: false,
            hideDefaultOptions: false,
            hideOptionsHeader: false,
            hideReportOptions: false,
            seasons: [],
            selectedEventGuidSet: new Set(),
            selectedFieldCount: 0,
            selectedFields: [],
            selectedRecGuidSet: new Set(),
            showReportBookGroupBy: false,
            showIndividualRBOption: false,
            ...this.props,
            onInputChange: (key, value) => {
                this.onInputChange(key, value);
            },
        };
        return <ReportOptions {...reportOptions} />;
    }
}
