import AddEditPanel from "./add-edit/add-edit-container";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { injectIntl, intlShape } from "react-intl";
import { withMasked, withApiResult } from "~/hocs";
import { SUCCESS, FAILURE } from "~/hocs/needs/utils";
import { preventBubbleUp } from "~/admin/utils";
import { actions, model, service } from "./data";
import { messages } from "./../i18n-messages";
import { OrgLevelGrid } from "./org-level-grid";
import ActiveInactiveLink from "../component/active-inactive-link";
import HierarchyFilter from "../component/hierarchy-filter";
import { keywords } from "./keywords";
import { NoLink, DialogBox, DialogBoxFooterType, Button, Paginator } from "~/core";
import OwnerAddEdit from "../owner/add-edit/add-edit-container";
import { actions as ownerActions } from "../owner/data";
import SlidingPanel from "~/sliding-panel/sliding-panel";
// icons
import { AddIcon, DownArrowIcon, PencilIcon } from "~/core/icons";
import "./org-level.css";

export const OrgNestedGrids = ({
    hierarchyFilterList,
    maxOrgLevelType,
    needs,
    requestIds,
    onRowSelection,
    onRowUnselect,
    orgLevelData,
    parentGuid,
    parentLevelGuid,
    selectedRow,
    showInActive,
}) => {
    if (parentGuid !== null) {
        return (
            <OrgLevelGrid
                hierarchyFilterList={hierarchyFilterList}
                maxOrgLevelType={maxOrgLevelType}
                onRowSelection={onRowSelection}
                onRowUnselect={onRowUnselect}
                orgLevelData={orgLevelData}
                parentGuid={parentGuid}
                parentLevelGuid={parentLevelGuid}
                needs={needs}
                selectedRow={selectedRow}
                requestIds={requestIds}
                showInActive={showInActive}
            />
        );
    }
    return null;
};

OrgNestedGrids.propTypes = {
    hasOwnerLevelAccess: PropTypes.bool,
    hierarchyFilterList: PropTypes.array,
    isExpanded: PropTypes.bool,
    maxOrgLevelType: PropTypes.number,
    needs: PropTypes.func,
    onRowSelection: PropTypes.func,
    onRowUnselect: PropTypes.func,
    orgLevelData: PropTypes.object,
    parentGuid: PropTypes.string,
    parentLevelGuid: PropTypes.array,
    requestIds: PropTypes.object,
    selectedRow: PropTypes.object,
    showInActive: PropTypes.bool,
};

export class OrgLevel_ extends Component {
    static propTypes = {
        actions: PropTypes.object,
        addOrgLevel: PropTypes.func,
        closeSidePanel: PropTypes.func,
        companyGuid: PropTypes.string,
        deleteSelected: PropTypes.func,
        fetchCompanyRecord: PropTypes.func,
        fetchRecords: PropTypes.func,
        getAffiliationsParent: PropTypes.func,
        getHierarchyFilterListData: PropTypes.func,
        getOrgLevelChildrenData: PropTypes.func,
        getOrgLevelWithGuidData: PropTypes.func,
        hierarchyFilterList: PropTypes.array,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        onSubmit: PropTypes.func,
        orgLevelData: PropTypes.object,
        orgLevelGridData: PropTypes.object,
        orgLevelWithGuid: PropTypes.array,
        parentGridData: PropTypes.object,
        parentLevelGuid: PropTypes.array,
        requestIds: PropTypes.object,
        selectedGuid: PropTypes.string,
        setNewParentLocation: PropTypes.func,
        updateOrgLevel: PropTypes.func,
        updateOwner: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentOrgLevelData: {},
            isModalOpen: false,
            isExpanded: true,
            showAddEditPanel: false,
            mode: "",
            orgLevelGrids: [],
            orgLevelGridData: [],
            openMoveLocationModal: false,
            selectedRow: null,
            showOwnerScreen: false,
            saveOwnerData: false,
            saveOrgLevelData: false,
            gridsInitialized: false,
            toggleActiveInactiveFlag: false,
        };
        this.parentAffiliationRequestId = null;
        this.fetchCompanyRecordRequestId = null;
        this.searchOrgRequestId = null;
        this.selectedParentOrgLevelGuid = null;
        this.selectedParentGuid = null;
        this.setNewParentLocationRequestId = null;
        this.ownerAddEditRequestId = null;
        this.renderedGrids = [];
        this.orgLevelAddEditRequestId = null;
    }

    _getValueFromBool = (value) => {
        if (value) {
            return keywords.yes;
        }
        return keywords.no;
    };

    _getExpandCollapseButton = (stateName, selected, record, index) => {
        const stateKey = stateName || "isExpanded";
        const className = classnames(
            this.state[stateKey] ? "collapse-button-rotate-45" : "collapse-button-rotate-90",
            "expand-collapse-icon"
        );
        return (
            <div className={"data-table-container location-icon-box"}>
                {!(record && record.hierarchyExists) ? null : (
                    <div
                        className={"nested-row-icon"}
                        onClick={() => this.expandTable(stateKey, index)}
                    >
                        <DownArrowIcon className={className} />
                    </div>
                )}
                {!selected || !record.canModify ? null : (
                    <div className="data-table-icon-box">{this._renderEditDeleteIcons(record)}</div>
                )}
            </div>
        );
    };

    _getNameLabel = (data) => {
        const { orgTypeLevel } = data;
        const itemData = this.props.hierarchyFilterList.find(
            (data) => data.orgTypeLevel === orgTypeLevel
        );
        if (itemData) {
            return itemData.orgTypeName;
        }
        return this.props.intl.formatMessage(messages.name);
    };

    _getMoveLocationHeaderText = () => {
        const { formatMessage } = this.props.intl;
        const selectedOrgLevelName = this.state.selectedRow
            ? this.state.selectedRow.orgLevelName
            : "";
        return `${formatMessage(messages.moveLocation)} - ${selectedOrgLevelName} (${formatMessage(
            messages.selectNewParent
        )}):`;
    };

    _processUpdateOwnerRequest = (nextProps) => {
        if (nextProps.requestIds[this.ownerAddEditRequestId] === SUCCESS) {
            if (this.state.currentOwnerData) {
                this.setState({ currentOwnerData: null });
            }
            this.ownerAddEditRequestId = null;
            this._toggleOwnerAddEditPanel();
            this.setState({ showOwnerScreen: false });
        } else if (nextProps.requestIds[this.ownerAddEditRequestId] === FAILURE) {
            this.ownerAddEditRequestId = null;
        }
    };

    _processUpdateOrgLevelRequest = (nextProps) => {
        if (nextProps.requestIds[this.orgLevelAddEditRequestId] === SUCCESS) {
            if (this.state.currentOwnerData) {
                this.setState({ currentOwnerData: null });
            }
            this.orgLevelAddEditRequestId = null;
            this._toggleOrgLevelAddEditPanel({}, null);
            // this.props.closeSidePanel();
        } else if (nextProps.requestIds[this.orgLevelAddEditRequestId] === FAILURE) {
            this.orgLevelAddEditRequestId = null;
            // this.props.closeSidePanel();
        }
    };

    _processSetNewParent = (nextProps) => {
        if (nextProps.requestIds[this.setNewParentLocationRequestId] === SUCCESS) {
            this.setNewParentLocationRequestId = null;
            this.fetchCompanyRecordRequestId = this.props.needs([
                actions.fetch({
                    ...service.defaultRequestFilters,
                    [model.PROPS_PARENT_ORG_LEVEL_GUID]: this.selectedParentOrgLevelGuid,
                }),
                actions.fetch({
                    ...service.defaultRequestFilters,
                    [model.PROPS_PARENT_ORG_LEVEL_GUID]: this.selectedParentGuid,
                }),
            ]);
            this._resetMoveLocationConfig();
            this.setState({
                openMoveLocationModal: false,
            });
        } else if (nextProps.requestIds[this.setNewParentLocationRequestId] === FAILURE) {
            this.setNewParentLocationRequestId = null;
            this._resetMoveLocationConfig();
            this.setState({
                openMoveLocationModal: false,
            });
        }
    };

    /*
        This method recurssively searches and push the locations in descending order
        into the orgLevelGridData state. orgLevelGridData is used by render method to
        display the nested location table
    */
    _processSearchOrgLocation = (nextProps) => {
        if (this.searchOrgRequestId && nextProps.requestIds[this.searchOrgRequestId] === SUCCESS) {
            let isExpandedObj = {};
            const orgLevelGrid = nextProps.orgLevelGridData.reduce((acc, record, index) => {
                isExpandedObj = {
                    ...isExpandedObj,
                    [`isExpanded${index}`]: true,
                };
                // First record level is always the owner / company record
                if (
                    record.level === 1 &&
                    nextProps.parentGridData.gridRows[0].orgLevelGuid === record.guid
                ) {
                    acc.push(nextProps.parentGridData.gridRows[0]);
                    if (nextProps.orgLevelGridData.length === 1) {
                        const lastRecord = record.gridRows.find(
                            (data) =>
                                data.orgLevelGuid ===
                                this.state.searchedOrgLevelWithGuid.orgLevelGuid
                        );
                        if (lastRecord) {
                            acc.push(lastRecord);
                        }
                    }
                    return acc;
                } else {
                    const parentRecord = acc[acc.length - 1];
                    const { gridRows } = nextProps.orgLevelGridData.find(
                        (data) => data.guid === parentRecord.orgLevelGuid
                    );
                    const gridData = gridRows.find((data) => {
                        return data.orgLevelGuid === record.guid;
                    });
                    acc.push(gridData);

                    if (index === nextProps.orgLevelGridData.length - 1) {
                        const lastRecord = record.gridRows.find(
                            (data) =>
                                data.orgLevelGuid ===
                                this.state.searchedOrgLevelWithGuid.orgLevelGuid
                        );
                        if (lastRecord) {
                            acc.push(lastRecord);
                        }
                    }
                    return acc;
                }
            }, []);
            this.setState({
                orgLevelGridData: orgLevelGrid,
                searchedOrgLevelWithGuid: null,
                ...isExpandedObj,
            });
            this.fetchCompanyRecordRequestId = null;
            this.searchOrgRequestId = null;
        } else if (
            this.searchOrgRequestId &&
            nextProps.requestIds[this.searchOrgRequestId] === FAILURE
        ) {
            this.fetchCompanyRecordRequestId = null;
            this.searchOrgRequestId = null;
        }
    };

    _resetMoveLocationConfig = () => {
        this.setState({
            selectedRow: null,
        });
    };

    _toggleOwnerAddEditPanel = (mode) =>
        this.setState({
            showOwnerScreen: !this.state.showOwnerScreen,
            mode,
        });

    _toggleOwnerSave = () =>
        this.setState({
            saveOwnerData: !this.state.saveOwnerData,
        });

    _toggleOrgLevelSave = () =>
        this.setState({
            saveOrgLevelData: !this.state.saveOrgLevelData,
        });

    _onSaveOwnerData = (data) => {
        this._toggleOwnerSave();
        if (this.ownerAddEditRequestId === null) {
            this.setState(
                {
                    currentOwnerData: data,
                },
                () => {
                    this.ownerAddEditRequestId = this.props.needs([this.props.updateOwner(data)]);
                }
            );
        }
    };

    _onSaveOrgLevelData = (data) => {
        this._toggleOrgLevelSave();
        if (this.orgLevelAddEditRequestId === null) {
            this.setState(
                {
                    currentOrgLevelData: data,
                },
                () => {
                    if (this.state.orgLevelMode === keywords.EDIT) {
                        this.orgLevelAddEditRequestId = this.props.needs([
                            this.props.updateOrgLevel(data),
                        ]);
                    } else {
                        this.orgLevelAddEditRequestId = this.props.needs([
                            this.props.addOrgLevel(data),
                        ]);
                    }
                }
            );
        }
    };

    _renderEditDeleteIcons = (record) => {
        return (
            <div className={"data-table-icon-box"}>
                <div
                    onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        if (record.owner) {
                            this._toggleOwnerAddEditPanel(keywords.EDIT);
                        } else {
                            this._toggleOrgLevelAddEditPanel(record, keywords.EDIT);
                        }
                    }}
                >
                    <PencilIcon />
                </div>
            </div>
        );
    };

    _toggleOrgLevelAddEditPanel = (record, mode) => {
        this.setState({
            showOrgLevelScreen: !this.state.showOrgLevelScreen,
            orgLevelMode: mode,
            selectedOrgLevelRow: record,
        });
    };

    onSearchedNodeClick = (data) => {
        this.setState({
            searchClickedNode: data.orgLevelGuid,
            selectedRow: data,
        });
    };

    _getOrgLevelEditHierarchy = (data, index) => {
        if (
            this.state.orgLevelGridData &&
            this.state.orgLevelGridData.length - 1 > index &&
            data.orgTypeLevel < this.state.maxOrgLevelType
        ) {
            return true;
        }
        return false;
    };

    _renderOwnerAddEditPanel = () => {
        return (
            <div className="owner-add-edit-page">
                <OwnerAddEdit
                    addEditPanel={{ mode: this.state.mode }}
                    needs={this.props.needs}
                    apiError={{}}
                    fetchData={this.state.saveOwnerData}
                    liftRecordData={this._onSaveOwnerData}
                    recordGuid={
                        this.state.selectedRow
                            ? this.state.selectedRow[model.PROPS_ORG_LEVEL_GUID]
                            : null
                    }
                    actions={ownerActions}
                />
            </div>
        );
    };

    _renderOrgLevelAddEditPanel = () => {
        const recordGuid = this.state.selectedOrgLevelRow
            ? this.state.selectedOrgLevelRow.orgLevelGuid
            : null;
        const parentOrgLevelGuid = this.state.selectedOrgLevelRow
            ? this.state.selectedOrgLevelRow.parentOrgLevelGuid
            : null;
        return (
            <div className="owner-add-edit-page">
                <AddEditPanel
                    mode={this.state.orgLevelMode}
                    needs={this.props.needs}
                    apiError={{}}
                    fetchData={this.state.saveOrgLevelData}
                    liftRecordData={this._onSaveOrgLevelData}
                    recordGuid={recordGuid}
                    requestIds={this.props.requestIds}
                    parentGuid={parentOrgLevelGuid}
                />
            </div>
        );
    };

    _renderTableHeader = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="company-table-header data-table-row data-table-header">
                <div className="data-table-cell grid-col-10 empty-cell" />
                <div className="data-table-cell grid-col-20">{formatMessage(messages.name)}</div>
                <div className="data-table-cell grid-col-20">{formatMessage(messages.city)}</div>
                <div className="data-table-cell grid-col-20">
                    {formatMessage(messages.stateAbbr)}
                </div>
                <div className="data-table-cell grid-col-20">
                    {formatMessage(messages.canDelete)}
                </div>
            </div>
        );
    };

    _renderAddButton = (data) => {
        if (!data.canModify || data.orgTypeLevel >= this.state.maxOrgLevelType) {
            return null;
        }

        return (
            <div
                onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    this._toggleOrgLevelAddEditPanel(data, keywords.ADD);
                }}
            >
                <AddIcon />
            </div>
        );
    };

    _renderSearchedNestedGrids = (data, index) => {
        const { formatMessage } = this.props.intl;
        const expandedFlagKey = `isExpanded${index}`;
        const isSelectedRow =
            this.state.selectedRow && this.state.selectedRow.orgLevelGuid === data.orgLevelGuid;
        const recordDataForSave = {
            ...data,
            hierarchyExists: this._getOrgLevelEditHierarchy(data, index),
        };
        const marginLen = 20 * (index + 1) > 0 ? `${20 * (index + 1)}` : 0;
        const classNames = classnames(
            "data-table-row",
            `${isSelectedRow ? "data-table-row-selected" : ""}`
        );

        return (
            <div key={`searchedNestedGrids-${index}`}>
                {
                    <div
                        className="child-component"
                        style={{
                            marginLeft: `${marginLen}px`,
                            marginRight: `${marginLen}px`,
                        }}
                    >
                        <div className="data-table-row data-table-header">
                            <div className={"data-table-container"}>
                                <div className={"data-table-add-icon"}>
                                    {this._renderAddButton(data)}
                                </div>
                            </div>
                            <div className="data-table-cell grid-col-20">
                                {this._getNameLabel(data)}
                            </div>
                            <div className="data-table-cell grid-col-20">
                                {formatMessage(messages.city)}
                            </div>
                            <div className="data-table-cell grid-col-20">
                                {formatMessage(messages.stateAbbr)}
                            </div>
                            <div className="data-table-cell grid-col-20">
                                {formatMessage(messages.canDelete)}
                            </div>
                        </div>
                        <div
                            key={index}
                            onClick={() => this.onSearchedNodeClick(data)}
                            className={classNames}
                        >
                            {this._getExpandCollapseButton(
                                expandedFlagKey,
                                isSelectedRow,
                                recordDataForSave,
                                index
                            )}
                            <div className="data-table-cell data-table-cell-relative grid-col-20">
                                <p>{data[model.PROPS_ORG_LEVEL_NAME]}</p>
                            </div>
                            <div className="data-table-cell data-table-cell-relative grid-col-20">
                                <p>{data[model.PROPS_CITY]}</p>
                            </div>
                            <div className="data-table-cell data-table-cell-relative grid-col-20">
                                <p>{data[model.PROPS_STATE_ABBREVIATION]}</p>
                            </div>
                            <div className="data-table-cell data-table-cell-relative grid-col-20">
                                <p>{this._getValueFromBool(data[model.PROPS_CAN_DELETE])}</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };

    _renderSearchedRows = () => {
        let revIndx = this.state.orgLevelGridData.length;
        const searchedRows = [],
            paginatorFooters = [];
        this.state.orgLevelGridData.forEach((data, index) => {
            --revIndx;
            const marginLen = 20 * revIndx > 0 ? `${20 * revIndx}` : 0;
            let parentExpandedFlag = true;
            for (let i = index - 1; i >= 0; i--) {
                if (!this.state[`isExpanded${i}`]) {
                    parentExpandedFlag = false;
                }
            }
            // Ignoring first searched row since it's the company / owner name
            if (index > 0 && parentExpandedFlag) {
                searchedRows.push(this._renderSearchedNestedGrids(data, index));
                paginatorFooters.push(
                    <div
                        style={{
                            marginLeft: `${marginLen}px`,
                            marginRight: `${marginLen}px`,
                        }}
                    >
                        <Paginator totalRecords={1} isModalWindow={false} />
                        <div className="footer-bar" />
                    </div>
                );
            }
        });
        return (
            <div>
                {searchedRows}
                {paginatorFooters}
            </div>
        );
    };

    _renderTableRows = () => {
        const { gridRows } = this.props.parentGridData;
        const parentRecord = gridRows.length > 0 ? gridRows[0] : null; // Since there is going to be only single company

        if (!parentRecord) {
            return null;
        }
        const selected = this.state.selectedRow === parentRecord;
        const classNames = classnames("data-table-row", {
            "data-table-row-selected": selected,
        });
        return (
            <div className="org-level-nested-row">
                <LocationTableRow
                    key="parent-record"
                    className={classNames}
                    onRowSelection={this.onRowSelection}
                    onRowUnselect={this.onRowUnselect}
                    selected={selected}
                    record={parentRecord}
                    getExpandCollapseButton={this._getExpandCollapseButton}
                    getValueFromBool={this._getValueFromBool}
                    selectedRow={this.state.selectedRow}
                />
                {!parentRecord.hierarchyExists || !this.state.isExpanded ? null : this.state
                      .orgLevelGridData.length > 0 ? (
                    this._renderSearchedRows()
                ) : (
                    <OrgNestedGrids
                        isExpanded={this.state.isExpanded}
                        parentGuid={this.props.companyGuid}
                        orgLevelData={this.props.orgLevelData}
                        hierarchyFilterList={this.props.hierarchyFilterList}
                        onRowSelection={this.onRowSelection}
                        onRowUnselect={this.onRowUnselect}
                        parentLevelGuid={this.state.parentLevelGuid}
                        maxOrgLevelType={this.state.maxOrgLevelType}
                        hasOwnerLevelAccess={this.state.hasOwnerLevelAccess}
                        requestIds={this.props.requestIds}
                        needs={this.props.needs}
                        selectedRow={this.state.selectedRow}
                        showInActive={this.state.toggleActiveInactiveFlag}
                    />
                )}
                <Paginator totalRecords={1} isModalWindow={false} />
            </div>
        );
    };

    onRowSelection = (event, selectedRow) => {
        if (selectedRow === this.state.selectedRow) {
            this.onRowUnselect();
        } else {
            this.setState({
                selectedRow,
            });
        }
    };

    onRowUnselect = () => {
        if (!this.state.openMoveLocationModal) {
            this._resetMoveLocationConfig();
        }
    };

    onFilterChange = (selectedOrgLevel) => {
        if (selectedOrgLevel) {
            const searchedOrgLevelWithGuid = this.props.orgLevelWithGuid.find(
                (data) => data.orgLevelGuid === selectedOrgLevel.orgLevelGuid
            );
            const orgLevelRequest = [];
            searchedOrgLevelWithGuid.orgLevelParents.forEach((parentData) => {
                orgLevelRequest.push({
                    ...service.defaultRequestFilters,
                    [model.PROPS_PARENT_ORG_LEVEL_GUID]: parentData.guid,
                });
            });
            if (orgLevelRequest.length > 0) {
                this.searchOrgRequestId = this.props.needs([
                    this.props.getOrgLevelChildrenData(orgLevelRequest),
                ]);
            }
            this.setState({
                searchedOrgLevelWithGuid,
            });
        } else {
            this.setState({
                orgLevelGridData: [],
                selectedRow: null,
            });
        }
    };

    onMoveLocation = () => {
        if (this.state.selectedRow && this.state.selectedRow.orgLevelGuid) {
            this.setState({
                openMoveLocationModal: true,
            });
        }
    };

    setNewParentLocation = () => {
        const { orgLevelGuid } = this.state.selectedRow;
        this.setNewParentLocationRequestId = this.props.needs([
            this.props.setNewParentLocation({
                [model.PROPS_ORG_LEVEL_GUID]: orgLevelGuid,
                [model.PROPS_PARENT_ORG_LEVEL_GUID]: this.selectedParentGuid,
            }),
        ]);
        this.setState({
            openMoveLocationModal: false,
        });
    };

    onMoveLocationFilterChange = ({ orgLevelGuid }) => {
        if (orgLevelGuid) {
            this.selectedParentGuid = orgLevelGuid;
            this.selectedParentOrgLevelGuid = this.state.selectedRow
                ? this.state.selectedRow.parentOrgLevelGuid
                : "";
        }
    };

    expandTable = (stateKey) => {
        this.setState({
            [stateKey]: !this.state[stateKey],
        });
    };

    toggleActiveInactive = () => {
        this.setState({
            toggleActiveInactiveFlag: !this.state.toggleActiveInactiveFlag,
        });
    };

    componentDidMount() {
        this.parentAffiliationRequestId = this.props.needs([this.props.getAffiliationsParent()]);
        this.props.needs([
            this.props.getHierarchyFilterListData({
                orgLevelGuid: "",
            }),
            this.props.getOrgLevelWithGuidData(),
        ]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.requestIds[this.parentAffiliationRequestId] === SUCCESS) {
            this.fetchCompanyRecordRequestId = this.props.needs([
                this.props.fetchCompanyRecord(this.props.companyGuid),
            ]);
            this.parentAffiliationRequestId = null;
        } else if (nextProps.requestIds[this.parentAffiliationRequestId] === FAILURE) {
            this.parentAffiliationRequestId = null;
        }
        if (nextProps.requestIds[this.fetchCompanyRecordRequestId] === SUCCESS) {
            this.setState({
                isExpanded: true,
            });
            this.fetchCompanyRecordRequestId = null;
        } else if (nextProps.requestIds[this.fetchCompanyRecordRequestId] === FAILURE) {
            this.fetchCompanyRecordRequestId = null;
        }
        if (nextProps.parentLevelGuid !== this.props.parentLevelGuid) {
            let hasOwnerLevelAccess = false;
            if (
                nextProps.parentLevelGuid.length === 1 &&
                this.props.companyGuid === nextProps.parentLevelGuid.pop()
            ) {
                hasOwnerLevelAccess = true;
            }
            this.setState({
                parentLevelGuid: nextProps.parentLevelGuid,
                hasOwnerLevelAccess,
            });
        }
        if (nextProps.hierarchyFilterList !== this.props.hierarchyFilterList) {
            const maxOrgLevelType = nextProps.hierarchyFilterList.reduce((acc, data) => {
                if (data.orgTypeLevel > acc) {
                    return data.orgTypeLevel;
                }
                return acc;
            }, 0);
            this.setState({ maxOrgLevelType });
        }
        this._processSetNewParent(nextProps);
        this._processSearchOrgLocation(nextProps);
        this._processUpdateOwnerRequest(nextProps);
        this._processUpdateOrgLevelRequest(nextProps);
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="content-table-container org-level-grid-container">
                <div className="filter-header-container">
                    <DialogBox
                        forceOverflow
                        title={this._getMoveLocationHeaderText()}
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        isOpen={this.state.openMoveLocationModal}
                        onAction={() => this.setNewParentLocation()}
                        onClose={() => {
                            this.setState({ openMoveLocationModal: false });
                        }}
                    >
                        <HierarchyFilter
                            needs={this.props.needs}
                            onFilterChange={this.onMoveLocationFilterChange}
                            orgLevel={this.props.orgLevelWithGuid}
                        />
                    </DialogBox>
                    <div className="filter-container">
                        <HierarchyFilter
                            needs={this.props.needs}
                            onFilterChange={this.onFilterChange}
                            orgLevel={this.props.orgLevelWithGuid}
                        />
                    </div>
                    <div className="org-level-header-cont">
                        {this.state.selectedRow ? (
                            <div className="active-inactive-container">
                                <div className="active-inactive-header-container move-location-container">
                                    <NoLink
                                        onClick={this.onMoveLocation}
                                        label={formatMessage(messages.moveLocation)}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <div className="active-inactive-container">
                            <ActiveInactiveLink toggleActiveInactive={this.toggleActiveInactive} />
                        </div>
                    </div>
                </div>
                <div className="content-table-container">
                    {this._renderTableHeader()}
                    {this.state.parentLevelGuid ? this._renderTableRows() : null}
                    <span className="footer-bar" />
                </div>
                {!this.state.showOwnerScreen ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            component={this._renderOwnerAddEditPanel}
                            navigateTo={{
                                parentNameCode: 101,
                                childNameCode: 234,
                            }}
                        >
                            <Button type="save" forceSubmit onClick={this._toggleOwnerSave} />
                            <Button
                                type="cancel"
                                className="owner-cancel"
                                onClick={this._toggleOwnerAddEditPanel}
                            />
                        </SlidingPanel>
                    </form>
                )}
                {!this.state.showOrgLevelScreen ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            component={this._renderOrgLevelAddEditPanel}
                            navigateTo={{
                                parentNameCode: 101,
                                childNameCode: 117,
                            }}
                        >
                            <Button type="save" forceSubmit onClick={this._toggleOrgLevelSave} />
                            <Button
                                type="cancel"
                                className="owner-cancel"
                                onClick={this._toggleOrgLevelAddEditPanel}
                            />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}

const LocationTableRow = ({
    record,
    selected,
    className,
    onRowSelection,
    getExpandCollapseButton,
    getValueFromBool,
}) => {
    return (
        <div
            className={`location-table-row ${className}`}
            onClick={(e) => record.canModify && onRowSelection(e, record)}
        >
            {getExpandCollapseButton(null, selected, {
                ...record,
                owner: true,
            })}
            <div className="data-table-cell data-table-cell-relative grid-col-20">
                <p>{record[model.PROPS_ORG_LEVEL_NAME]}</p>
            </div>
            <div className="data-table-cell data-table-cell-relative grid-col-20">
                <p>{record[model.PROPS_CITY]}</p>
            </div>
            <div className="data-table-cell data-table-cell-relative grid-col-20">
                <p>{record[model.PROPS_STATE_ABBREVIATION]}</p>
            </div>
            <div className="data-table-cell data-table-cell-relative grid-col-20">
                <p>{getValueFromBool(record[model.PROPS_CAN_DELETE])}</p>
            </div>
        </div>
    );
};

LocationTableRow.propTypes = {
    record: PropTypes.object,
    className: PropTypes.string,
    selected: PropTypes.bool,
    onRowSelection: PropTypes.func,
    getExpandCollapseButton: PropTypes.func,
    getValueFromBool: PropTypes.func,
};

export const OrgLevel = injectIntl(withMasked(withApiResult(OrgLevel_, actions.importData)));
