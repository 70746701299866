import type { SetupReportBooksAPI } from "@ai360/core";
import {
    IMergeReportBook,
    IMergeReportOptions,
    IReportBook,
    IReportOptions,
    IReportType,
    MergeStatus,
} from "~/admin/setup/report-books/data/interfaces";
import { IAction } from "./interfaces";

export interface ReducerAction {
    filteredLocationList: any;
    loadingModal: boolean;
    loadingOrgLevelGuids: boolean;
    loadingReportBookReports: boolean;
    loadingReportBooks: boolean;
    mergeStatus: MergeStatus;
    reportBookOrgLevelGuids: SetupReportBooksAPI.IOrgLevelGuidRecord[];
    reportBookReports: SetupReportBooksAPI.IReportRecord[];
    reportBooks: IReportBook[];
    reportBookOptionErrors: string[];
    reportBookNames: string[];
    reportOptions: IReportOptions[];
    reportTypes: IReportType[];
    selectedReportBookTypeGuid: string;
    selectedReportOptionGuid: string;
    soilTypeAttributes: SetupReportBooksAPI.ISoilTypeAttributeRecord[];
    type: string;
}

export const GET_FILTERED_LOCATION_LIST = "setupReportBooks/GET_FILTERED_LOCATION_LIST";
export const SET_FILTERED_LOCATION_LIST = "setupReportBooks/SET_FILTERED_LOCATION_LIST";

export const GET_REPORT_BOOK_ORG_LEVEL_GUIDS = "setupReportBooks/GET_REPORT_BOOK_ORG_LEVEL_GUIDS";
export const SET_REPORT_BOOK_ORG_LEVEL_GUIDS = "setupReportBooks/SET_REPORT_BOOK_ORG_LEVEL_GUIDS";

export const GET_REPORT_BOOK_REPORTS = "setupReportBooks/GET_REPORT_BOOK_REPORTS";
export const SET_REPORT_BOOK_REPORTS = "setupReportBooks/SET_REPORT_BOOK_REPORTS";

export const GET_REPORT_BOOK_TYPE_NAMES = "setupReportBooks/GET_REPORT_BOOK_TYPE_NAMES";
export const SET_REPORT_BOOK_TYPE_NAMES = "setupReportBooks/SET_REPORT_BOOK_TYPE_NAMES";

export const DEACTIVATE_REPORT_BOOK_TYPES = "setupReportBooks/DEACTIVATE_REPORT_BOOK_TYPES";
export const GET_REPORT_BOOK_TYPES = "setupReportBooks/GET_REPORT_BOOK_TYPES";
export const MERGE_REPORT_BOOK_TYPES = "setupReportBooks/MERGE_REPORT_BOOK_TYPES";
export const SET_REPORT_BOOK_TYPES = "setupReportBooks/SET_REPORT_BOOK_TYPES";

export const GET_REPORT_TYPES = "setupReportBooks/GET_REPORT_TYPES";
export const SET_REPORT_TYPES = "setupReportBooks/SET_REPORT_TYPES";

export const DEACTIVATE_REPORT_OPTION = "setupReportBooks/DEACTIVATE_REPORT_OPTION";
export const GET_REPORT_OPTIONS = "setupReportBooks/GET_REPORT_OPTIONS";
export const MERGE_REPORT_OPTIONS = "setupReportBooks/MERGE_REPORT_OPTIONS";
export const SET_REPORT_OPTIONS = "setupReportBooks/SET_REPORT_OPTIONS";
export const SET_REPORT_OPTION_ERRORS = "setupReportBooks/SET_REPORT_OPTION_ERRORS";

export const GET_SOIL_TYPE_ATTRIBUTES = "setupReportBooks/GET_SOIL_TYPE_ATTRIBUTES";
export const SET_SOIL_TYPE_ATTRIBUTES = "setupReportBooks/SET_SOIL_TYPE_ATTRIBUTES";

export const SET_LOADING_MODAL = "setupReportBooks/SET_LOADING_MODAL";
export const SET_LOADING_ORG_LEVEL_GUIDS = "setupReportBooks/SET_LOADING_ORG_LEVEL_GUIDS";
export const SET_LOADING_REPORT_BOOK_REPORTS = "setupReportBooks/SET_LOADING_REPORT_BOOK_REPORTS";
export const SET_LOADING_REPORT_BOOKS = "setupReportBooks/SET_LOADING_REPORT_BOOKS";
export const SET_MERGE_STATUS = "setupReportBooks/SET_MERGE_STATUS";
export const SET_SELECTED_REPORT_BOOK_TYPE_GUID =
    "setupReportBooks/SET_SELECTED_REPORT_BOOK_TYPE_GUID";
export const SET_SELECTED_REPORT_OPTION_GUID = "setupReportBooks/SET_SELECTED_REPORT_OPTION_GUID";

export const deactivateReportBookType = (reportBookTypeGuid: string): IAction => ({
    type: DEACTIVATE_REPORT_BOOK_TYPES,
    reportBookTypeGuid,
});

export const deactivateReportOption = (reportBookOptionGuid: string): IAction => ({
    type: DEACTIVATE_REPORT_OPTION,
    reportBookOptionGuid,
});

export const getFilteredLocationList = (): IAction => ({
    type: GET_FILTERED_LOCATION_LIST,
});

export const getReportBookOrgLevelGuids = (reportBookTypeGuid: string): IAction => ({
    type: GET_REPORT_BOOK_ORG_LEVEL_GUIDS,
    reportBookTypeGuid,
});

export const getReportBookReports = (reportBookTypeGuid: string): IAction => ({
    type: GET_REPORT_BOOK_REPORTS,
    reportBookTypeGuid,
});

export const getReportBookTypeNames = (): IAction => ({
    type: GET_REPORT_BOOK_TYPE_NAMES,
});

export const getReportBookTypes = (includeInactive = false): IAction => ({
    type: GET_REPORT_BOOK_TYPES,
    includeInactive,
});

export const getReportOptions = (reportBookOptionsGuid: string): IAction => ({
    type: GET_REPORT_OPTIONS,
    reportBookOptionsGuid,
});

export const getReportTypes = (): IAction => ({
    type: GET_REPORT_TYPES,
});

export const getSoilTypeAttributes = (): IAction => ({
    type: GET_SOIL_TYPE_ATTRIBUTES,
});

export const mergeReportBookType = (data: IMergeReportBook): IAction => ({
    type: MERGE_REPORT_BOOK_TYPES,
    data,
});

export const mergeReportOptions = (data: IMergeReportOptions): IAction => ({
    type: MERGE_REPORT_OPTIONS,
    data,
});

export const setFilteredLocationList = (filteredLocationList: string[]): IAction => ({
    type: SET_FILTERED_LOCATION_LIST,
    filteredLocationList,
});

export const setLoadingModal = (loadingModal: boolean): IAction => ({
    type: SET_LOADING_MODAL,
    loadingModal,
});

export const setLoadingOrgLevelGuids = (loadingOrgLevelGuids: boolean): IAction => ({
    type: SET_LOADING_ORG_LEVEL_GUIDS,
    loadingOrgLevelGuids,
});

export const setLoadingReportBookReports = (loadingReportBookReports: boolean): IAction => ({
    type: SET_LOADING_REPORT_BOOK_REPORTS,
    loadingReportBookReports,
});

export const setLoadingReportBooks = (loadingReportBooks: boolean): IAction => ({
    type: SET_LOADING_REPORT_BOOKS,
    loadingReportBooks,
});

export const setMergeStatus = (mergeStatus: MergeStatus): IAction => ({
    type: SET_MERGE_STATUS,
    mergeStatus,
});

export const setReportBookOptionsErrors = (reportBookOptionErrors: string[]): IAction => ({
    type: SET_REPORT_OPTION_ERRORS,
    reportBookOptionErrors,
});

export const setReportBookOrgLevelGuids = (reportBookOrgLevelGuids: string[]): IAction => ({
    type: SET_REPORT_BOOK_ORG_LEVEL_GUIDS,
    reportBookOrgLevelGuids,
});

export const setReportBookReports = (
    reportBookReports: SetupReportBooksAPI.IReportRecord[]
): IAction => ({
    type: SET_REPORT_BOOK_REPORTS,
    reportBookReports,
});

export const setReportBookTypeNames = (
    reportBookNames: SetupReportBooksAPI.IReportBookNameResponse[]
): IAction => ({
    type: SET_REPORT_BOOK_TYPE_NAMES,
    reportBookNames,
});

export const setReportBookTypes = (
    reportBooks: SetupReportBooksAPI.IReportBookTypeRecord[]
): IAction => ({
    type: SET_REPORT_BOOK_TYPES,
    reportBooks,
});

export const setReportOptions = (
    reportOptions: SetupReportBooksAPI.IReportOptionsRecord[]
): IAction => ({
    type: SET_REPORT_OPTIONS,
    reportOptions,
});

export const setReportTypes = (reportTypes: SetupReportBooksAPI.IReportTypeRecord[]): IAction => ({
    type: SET_REPORT_TYPES,
    reportTypes,
});

export const setSoilTypeAttributes = (
    soilTypeAttributes: SetupReportBooksAPI.ISoilTypeAttributeRecord[]
): IAction => ({
    type: SET_SOIL_TYPE_ATTRIBUTES,
    soilTypeAttributes,
});

export const setSelectedReportBookTypeGuid = (selectedReportBookTypeGuid: string): IAction => ({
    type: SET_SELECTED_REPORT_BOOK_TYPE_GUID,
    selectedReportBookTypeGuid,
});

export const setSelectedReportOptionGuid = (selectedReportOptionGuid: string): IAction => ({
    type: SET_SELECTED_REPORT_OPTION_GUID,
    selectedReportOptionGuid,
});
