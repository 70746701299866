import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import { parse } from "qs";

import { Loader } from "~/core";
import { messages as coreMessages } from "~/core/i18n-messages";
import { getErrorMessages } from "~/i18n-error-messages";
import { messages } from "~/i18n-messages";
import { ThemeRoot } from "~/theme";
import { AuthenticationAPI, APIErrorWithCode } from "@ai360/core";
import {
    Button,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    Section,
    SubSection,
    TextInput,
} from "../../core";

import * as actions from "../actions";
import * as selectors from "../selectors";
import "./login.css";

export interface IEnrollmentPage_Props {
    error?: Error;
    location?: Record<string, any>;
    onFetchEnrollmentForm?: (customerGuid: string) => void;
    onInitialEnrollmentLoad?: () => void;
    onStartProcessing?: () => void;
    onStopProcessing?: () => void;
    isProcessing?: boolean;
    intl: intlShape;
}

const EnrollmentPage_ = (props: IEnrollmentPage_Props): JSX.Element => {
    const {
        error,
        location,
        onFetchEnrollmentForm,
        onStartProcessing,
        onStopProcessing,
        isProcessing,
        intl,
    } = props;
    const { formatMessage } = intl;
    const { customerGuid } = parse(location.search.slice(1));
    const { phoneNumber } = parse(location.search.slice(0));

    const [enrollmentComplete, setEnrollmentComplete] = useState<boolean>(null);
    const [enrollmentText, setEnrollmentText] = useState<string>(null);
    const [errMessage, setErrMessage] = useState<string>(null);
    const [shaking, setShaking] = useState<boolean>(null);
    const [privateOption, setPrivateOption] = useState<boolean>(null);
    const [verificationComplete, setVerificationComplete] = useState<boolean>(null);
    const [verificationCodeSent, setVerificationCodeSent] = useState<boolean>(null);
    const [showPrivacyConfirmModal, setShowPrivacyConfirmModal] = useState<boolean>(null);
    const [systemName, setSystemName] = useState<string>(null);
    const [verificationCode, setVerificationCode] = useState<string>(null);
    const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(null);

    const _accept = () => {
        setPrivateOption(true);
        _confirmAccept();
    };

    const _confirmAccept = () => {
        setShowPrivacyConfirmModal(false);
        _clearError();
        onStartProcessing();
        AuthenticationAPI.setCustomerEnrolled(customerGuid, !privateOption)
            .then(() => {
                setEnrollmentComplete(true);
                setEnrollmentText(formatMessage(messages.enrollmentComplete, { systemName }));
                onStopProcessing();
            })
            .catch((error) => _handleError(error));
    };

    function _clearError() {
        setErrMessage("");
    }

    function _clearCode() {
        setVerificationCode("");
    }

    function _getEnrollmentAgreementSection(enrollmentComplete, enrollmentText) {
        const { formatMessage } = props.intl;
        if (enrollmentComplete) {
            return <div>{enrollmentText}</div>;
        } else {
            const enrollmentTextParts = enrollmentText
                ? enrollmentText.split("privacyOptionCheckbox")
                : ["", ""];

            return (
                <div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: enrollmentTextParts[0],
                        }}
                    ></div>
                    <div className="privacy-section">
                        <Checkbox
                            className={"privacy-checkbox"}
                            label={formatMessage(messages.privateOption)}
                            onChange={(e, value) => {
                                setPrivateOption(value);
                                setShowPrivacyModal(value);
                            }}
                            value={privateOption}
                        />
                        <p className="privacy-paragraph">
                            {formatMessage(messages.enrollmentPrivacyParagraph)}
                        </p>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: enrollmentTextParts[1],
                        }}
                    ></div>
                </div>
            );
        }
    }

    function _getAgreeButton(verificationComplete, enrollmentComplete) {
        if (!verificationComplete || enrollmentComplete) {
            return null;
        }
        return (
            <div className="accept-control">
                <Button
                    tabIndex={2}
                    className="accept-btn"
                    type="accept"
                    onClick={() => _accept()}
                />
            </div>
        );
    }

    function _getEnrollmentStatus() {
        onStartProcessing();
        AuthenticationAPI.getCustomerEnrollmentStatus(customerGuid)
            .then((result) => {
                onStopProcessing();
                setVerificationCodeSent(result.enrolleredYn);
                setVerificationComplete(result.enrolledYn);
                setEnrollmentComplete(result.enrolledYn);
                setSystemName(result.systemName);
                setEnrollmentText(formatMessage(messages.enrollmentComplete, { systemName }));
            })
            .catch((error) => _handleError(error));
    }

    function _getFormLink(enrollmentComplete) {
        if (!enrollmentComplete) {
            return null;
        }
        return (
            <NoLink
                className="form-link"
                label={formatMessage(messages.enrollmentFormLink)}
                onClick={() => _viewEnrollmentForm()}
            />
        );
    }

    function _getVerifyInput(verificationCodeSent) {
        if (!verificationCodeSent) {
            return null;
        }
        return (
            <TextInput
                autoFocus={true}
                value={verificationCode}
                placeholderText={formatMessage(messages.enterVerificationCode)}
                onChange={(verificationCode) => setVerificationCode(verificationCode)}
            />
        );
    }

    function _getVerifyButton(verificationCodeSent) {
        if (!verificationCodeSent) {
            return null;
        }
        return (
            <Button
                tabIndex={2}
                className="verify-btn"
                type="verify"
                onClick={() => _verifyCode()}
            />
        );
    }

    function _getVerificationSection(verificationCodeSent) {
        const verifyButtonClass = verificationCodeSent
            ? "enrollment-resend-btn cancel-btn"
            : "enrollment-btn";
        return (
            <SubSection className={"form-section-child-stretch"}>
                <p className="verification-instructions">
                    {formatMessage(messages.enrollmentVerificationText, { systemName })}
                </p>
                {_getVerifyInput(verificationCodeSent)}
                {_getVerifyButton(verificationCodeSent)}
                <br />
                <Button
                    tabIndex={1}
                    className={verifyButtonClass}
                    type={verificationCodeSent ? "resendVerification" : "sendVerification"}
                    onClick={() => _sendCode()}
                />
            </SubSection>
        );
    }

    function _handleError(err) {
        const { formatMessage } = intl;
        let errMessage = "";
        if (err instanceof APIErrorWithCode) {
            errMessage = getErrorMessages(formatMessage, err);
        } else {
            errMessage = formatMessage(messages.unknownError);
        }
        setShaking(true);
        setErrMessage(errMessage);

        onStopProcessing();
    }

    function _sendCode() {
        onStartProcessing();
        _clearError();
        _clearCode();
        AuthenticationAPI.sendVerificationCode(customerGuid, phoneNumber)
            .then(() => {
                onStopProcessing();
                setVerificationCodeSent(true);
                onStopProcessing();
            })
            .catch((error) => _handleError(error));
    }

    function _verifyCode() {
        onStartProcessing();
        _clearError();
        _clearCode();
        AuthenticationAPI.confirmVerificationCode(customerGuid, verificationCode)
            .then((result) => {
                props.onStopProcessing();
                setVerificationComplete(true);
                setEnrollmentText(result);
            })
            .catch((error) => _handleError(error));
    }

    const _viewEnrollmentForm = () => {
        onFetchEnrollmentForm(customerGuid);
    };

    useEffect(() => {
        if (error) {
            _handleError(error);
        }
    }, [error]);

    useEffect(() => {
        _getEnrollmentStatus();
    }, []);

    const loginBoxClasses = [
        "login-box",
        {
            "login-err-animation": shaking,
        },
    ];
    const errMsgEl = Array.isArray(errMessage)
        ? errMessage.map((msg, idx) => <p key={idx}>{msg}</p>)
        : errMessage;
    const containerClassName = enrollmentComplete
        ? "enrollment-complete"
        : verificationComplete
        ? "enrollment-container"
        : "verification-container";
    return (
        <ThemeRoot className="login-root">
            {isProcessing ? <Loader /> : null}
            <div className="login-page-container">
                <div className={classnames(loginBoxClasses)}>
                    <div className="login-logo-div"></div>
                    <div className="validation-summary-errors">{errMsgEl}</div>
                    <div className={containerClassName}>
                        <div className="display-label">
                            {formatMessage(messages.enrollmentAgreement, { systemName })}
                        </div>
                        <Section>
                            <div className="enrollment-body">
                                {verificationComplete
                                    ? _getEnrollmentAgreementSection(
                                          enrollmentComplete,
                                          enrollmentText
                                      )
                                    : _getVerificationSection(verificationCodeSent)}
                                <br />
                            </div>
                        </Section>
                        {_getAgreeButton(verificationComplete, enrollmentComplete)}
                        {_getFormLink(enrollmentComplete)}
                        <DialogBox
                            title={formatMessage(messages.important)}
                            footerType={DialogBoxFooterType.NONE}
                            isOpen={showPrivacyModal}
                            onClose={() => setShowPrivacyModal(false)}
                        >
                            {" "}
                            {formatMessage(messages.privateOptionWarning)}
                        </DialogBox>
                        <DialogBox
                            action={formatMessage(coreMessages.doneButtonText)}
                            className="private-confirm-modal"
                            title={formatMessage(messages.confirmTitle)}
                            footerType={DialogBoxFooterType.ACTION_CANCEL}
                            isOpen={showPrivacyConfirmModal}
                            onAction={() => _confirmAccept()}
                            onClose={() => setShowPrivacyConfirmModal(false)}
                        >
                            {formatMessage(messages.privateOptionConfirmation, { systemName })}
                        </DialogBox>
                    </div>
                </div>
            </div>
        </ThemeRoot>
    );
};

const mapStateToProps = (state) => {
    return {
        error: selectors.getError(state),
        isProcessing: selectors.isProcessing(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitialEnrollmentLoad: () => dispatch(actions.initialEnrollmentLoad()),
        onFetchEnrollmentForm: (customerGuid) =>
            dispatch(actions.fetchEnrollmentForm(customerGuid)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export const EnrollmentPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EnrollmentPage_));
