import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import { PICKLIST_CATEGORY, getPickListCode } from "~/core/picklist/picklist-names";

import { UNIT_CROP_PRICE, getUnitCode } from "~/core/units/unit-names";

export const MODEL_NAME = "picklist";
export const URL = apiUrl("AgBytes/GetPicklistList");
export const GETRECORD = apiUrl("AgBytes/GetPicklist");
export const NEXT_ID = apiUrl("AgBytes/GetNextPicklistValueId");
export const CREATE = apiUrl("AgBytes/AddPicklist");
export const UPDATE = apiUrl("AgBytes/UpdatePicklist");
export const DELETE = apiUrl("AgBytes/DeletePicklistList");
export const EXPORT_FILE_NAME = "PicklistExport";
export const EXPORT_URL = apiUrl("AgBytes/ExportPicklistList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetPicklistAutoSearchList");
export const IMPORT_URL = apiUrl("AgBytes/PicklistImport");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidPicklistImport");
export const SELECT_ALL = apiUrl("AgBytes/GetPicklistSelectAllList");
export const MODEL_URL = "models/agBytes/agBytesFullPicklist";

// Dropdowns
export const REQUEST_CATEGORY = apiUrl("Picklist/GetCategoryList");
export const REQUEST_PICKLIST_NAME = apiUrl("Picklist/GetPicklistNameList");
export const REQUEST_PICKLIST_PARENT_VALUE = apiUrl("Picklist/GetPicklistParentValueList");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "picklistFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "picklistSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "picklistPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        picklistId: "",
        picklistGuid: "",
        picklistValueGuid: "",
        picklistName: "",
        value: "",
        category: "",
        parentPicklistId: "",
        parentPicklistValue: "",
        logic: "",
        logicType: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "Category",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultPicklist = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_CAN_DELETE]: "",
    [model.PROPS_CATEGORY_GUID]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_PICKLIST_CATEGORY]: "",
    [model.PROPS_PICKLIST_GUID]: "",
    [model.PROPS_PICKLIST_ID]: "",
    [model.PROPS_PICKLIST_NAME]: "",
    [model.PROPS_PICKLIST_NAME_GUID]: "",
    [model.PROPS_PICKLIST_PARENT_GUID]: "",
    [model.PROPS_PICKLIST_PARENT_ID]: "",
    [model.PROPS_PICKLIST_PARENT_NAME]: "",
    [model.PROPS_PICKLIST_PARENT_VALUE]: "",
    [model.PROPS_PICKLIST_VALUE]: "",
    [model.PROPS_PICKLIST_VALUE_GUID]: "",
    [model.PROPS_PICKLIST_VALUE_ID]: "",
    __moduleId__: MODEL_URL,
});

export const pickLists = {
    [PICKLIST_CATEGORY]: getPickListCode(PICKLIST_CATEGORY),
};

export const dropdowns = {
    [model.PROPS_CATEGORY]: REQUEST_CATEGORY,
    [model.PROPS_PICKLIST_NAME]: REQUEST_PICKLIST_NAME,
    [model.PROPS_PICKLIST_PARENT_VALUE]: REQUEST_PICKLIST_PARENT_VALUE,
};
export const unitTypes = {
    [UNIT_CROP_PRICE]: getUnitCode(UNIT_CROP_PRICE),
};
function getGuid(record) {
    return {
        [model.PROPS_PICKLIST_VALUE_GUID]: record[model.PROPS_PICKLIST_VALUE_GUID],
        [model.PROPS_PICKLIST_NAME]: record[model.PROPS_PICKLIST_NAME],
    };
}

export const service = createService({
    id: model.PROPS_PICKLIST_VALUE_ID,
    guid: model.PROPS_PICKLIST_VALUE_GUID,
    modelName: MODEL_NAME,
    recordName: model.PROPS_PICKLIST_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    dropdowns,
    getGuid,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_PICKLIST_ID]: { label: "picklistId", gridCol: 5 },
        [model.PROPS_PICKLIST_CATEGORY]: { label: "category", gridCol: 10 },
        [model.PROPS_PICKLIST_PARENT_ID]: {
            label: "parentPicklistId",
            gridCol: 10,
        },
        [model.PROPS_PICKLIST_PARENT_VALUE]: {
            label: "parentValue",
            gridCol: 10,
        },
        [model.PROPS_PICKLIST_NAME]: { label: "picklistName", gridCol: 10 },
        [model.PROPS_PICKLIST_VALUE]: { label: "value", gridCol: 10 },
        [model.PROPS_PICKLIST_LOGIC]: { label: "logic", gridCol: 5 },
        [model.PROPS_PICKLIST_LOGIC_TYPE]: { label: "logicType", gridCol: 5 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate", gridCol: 10 },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => Object.assign({}, defaultPicklist()),
    defaultSort,
});
