import { SetupReportBooksAPI } from "@ai360/core";
import React, { Component } from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { DataTable, DialogBox, DialogBoxFooterType, Loader, LoaderTypes } from "~/core";
import { messages } from "~/admin/setup/i18n-messages";
import { addReportOptionsService } from "~/admin/setup/report-books/data/service";
import { withMasked } from "~/hocs";
import { connect } from "react-redux";
import {
    IReportOptions,
    IReportOptionsTableData,
} from "~/admin/setup/report-books/data/interfaces";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { actions, model, selectors } from "~/admin/setup/report-books/data";
import { selectors as loginSelectors } from "~/login";
import "./report-book-options.css";

const DELETE_CONFIRMATION_MODAL = "deleteConfirmationModal";
const REPORT_BOOK_OPTIONS_ERRORS = "reportBookOptionsErrors";

interface ISetupReportBookOptionsProps {
    deactivateReportOption: (guid?: string) => void;
    getReportOptions: (guid?: string) => SetupReportBooksAPI.IReportOptionsResponse;
    loadingModal: boolean;
    reportBookOptionErrors: string[];
    reportOptions: IReportOptions[];
    userRole: any;
}

interface ISetupReportBookOptionsState {
    modals: IModalArray[];
    reportOptions: IReportOptions[];
    reportOptionsTableData: IReportOptionsTableData[];
    selectedReportBookOptionGuid: string;
    showInactive: boolean;
}

interface IModalArray {
    id: string;
    isOpen: boolean;
}

class SetupReportBookOptions_ extends Component<
    ISetupReportBookOptionsProps & InjectedIntlProps,
    ISetupReportBookOptionsState
> {
    constructor(props) {
        super(props);
        this.state = {
            modals: [
                { id: DELETE_CONFIRMATION_MODAL, isOpen: false },
                { id: REPORT_BOOK_OPTIONS_ERRORS, isOpen: false },
            ],
            reportOptions: [],
            reportOptionsTableData: [],
            selectedReportBookOptionGuid: null,
            showInactive: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.getReportOptions(null);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISetupReportBookOptionsProps) {
        const newState: Partial<ISetupReportBookOptionsState> = {};

        if (nextProps.reportBookOptionErrors?.length > 0) {
            this.openModal(REPORT_BOOK_OPTIONS_ERRORS);
        }

        if (nextProps.reportOptions !== this.props.reportOptions) {
            newState.reportOptions = nextProps.reportOptions.map((ro) => ({
                ...ro,
                label: ro.name,
                value: ro.reportBookOptionsGuid,
            }));
            newState.reportOptionsTableData = nextProps.reportOptions.map((ro) =>
                this.generateReportOptionsTableData(ro)
            );
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState as ISetupReportBookOptionsState);
        }
    }

    private closeModal(id: string) {
        const otherModals = this.state.modals.filter((modal) => {
            return modal.id !== id;
        });
        let modalToUpdate = this.state.modals.filter((modal) => {
            return modal.id === id;
        });
        modalToUpdate = [{ ...modalToUpdate[0], isOpen: false }];

        const updatedModals = [...otherModals].concat(modalToUpdate);

        const newState: Partial<ISetupReportBookOptionsState> = {};

        if (id === REPORT_BOOK_OPTIONS_ERRORS) {
            newState.selectedReportBookOptionGuid = null;
            this.props.setReportBookOptionsErrors([]);
        }

        newState.modals = updatedModals;

        this.setState(newState as ISetupReportBookOptionsState);
    }

    private confirmDelete = (options) => {
        const { selectedItems } = options;
        const selectedReportBookOptionGuid =
            typeof selectedItems[0] === "object" ? selectedItems[0].guid : selectedItems[0];
        this.setState({ selectedReportBookOptionGuid });
        this.openModal(DELETE_CONFIRMATION_MODAL);
    };

    private deleteSelected = () => {
        this.props.deactivateReportOption(this.state.selectedReportBookOptionGuid);
        this.closeModal(DELETE_CONFIRMATION_MODAL);
    };

    private generateReportOptionsTableData(reportOption: SetupReportBooksAPI.IReportOptionsRecord) {
        return {
            [model.PROPS_CAN_DELETE]: true,
            [model.PROPS_DO_NOT_EDIT]: true,
            [model.PROPS_REPORT]: reportOption.reportTypeDisplayName,
            [model.PROPS_CONFIGURATION]: reportOption.name,
            [model.PROPS_CREATED_BY]: reportOption.createdBy,
            [model.PROPS_MODIFIED_BY]: reportOption.modifiedBy,
            [model.PROPS_MODIFIED_BY_DATE]: reportOption.modifiedDate,
            [model.PROPS_ACTIVE_YN]: reportOption.activeYN,
            [model.PROPS_REPORT_OPTIONS_TABLE_DATA]: reportOption,
        };
    }

    private openModal(id: string) {
        const otherModals = this.state.modals.filter((modal) => {
            return modal.id !== id;
        });
        let modalToUpdate = this.state.modals.filter((modal) => {
            return modal.id === id;
        });
        modalToUpdate = [{ ...modalToUpdate[0], isOpen: true }];

        const updatedModals = [...otherModals].concat(modalToUpdate);

        this.setState({
            modals: updatedModals,
        });
    }

    render() {
        const { modals, reportOptionsTableData } = this.state;
        const { formatMessage } = this.props.intl;

        return (
            <div className="content-table-container report-book-options-table">
                {!this.props.loadingModal ? null : <Loader type={LoaderTypes.LINE_SCALE} />}

                <DataTable
                    disableSelectAll={true}
                    isEditable
                    isCheckbox={false}
                    showFilterInput={false}
                    service={addReportOptionsService}
                    {...this.props}
                    messages={messages}
                    records={reportOptionsTableData}
                    deleteSelected={this.confirmDelete}
                    invisibleAddEdit={true}
                    totalCount={reportOptionsTableData.length}
                    disableSort={true}
                />

                <DialogBox
                    action={"Delete"}
                    className="delete-confirmation-modal"
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    isOpen={
                        modals.filter((modal) => {
                            return modal.id === DELETE_CONFIRMATION_MODAL;
                        })[0].isOpen
                    }
                    onAction={() => this.deleteSelected()}
                    onClose={() => {
                        this.closeModal(DELETE_CONFIRMATION_MODAL);
                    }}
                    title={"Deactivate Report Configuration"}
                >
                    <p>{formatMessage(messages.deactivateConfiguration)}</p>
                </DialogBox>

                <DialogBox
                    className="report-book-options-error-modal"
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={
                        modals.filter((modal) => {
                            return modal.id === REPORT_BOOK_OPTIONS_ERRORS;
                        })[0].isOpen
                    }
                    onClose={() => this.closeModal(REPORT_BOOK_OPTIONS_ERRORS)}
                    title={"Report Book Options"}
                >
                    <div className="report-book-options-error-container">
                        <div className="report-book-options-error-header">
                            Report book(s) that use the
                            <span className="config-name">
                                {" "}
                                {
                                    this.state.reportOptions?.find(
                                        (ro) =>
                                            ro.reportBookOptionsGuid ===
                                            this.state.selectedReportBookOptionGuid
                                    )?.name
                                }{" "}
                            </span>
                            configuration.
                        </div>
                        {this.props.reportBookOptionErrors?.map((error, idx) => {
                            return <p key={`err-${idx}`}>{error}</p>;
                        })}
                    </div>
                </DialogBox>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loadingModal: selectors.getLoadingModal(state),
    reportBookOptionErrors: selectors.getReportBookOptionErrors(state),
    reportOptions: selectors.getReportOptions(state),
    userRole: loginSelectors.getUser(state).role,
});

const mapDispatchToProps = (dispatch) => ({
    deactivateReportOption: (payload) => dispatch(actions.deactivateReportOption(payload)),
    getReportOptions: (payload) => dispatch(actions.getReportOptions(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    setReportBookOptionsErrors: (payload) => dispatch(actions.setReportBookOptionsErrors(payload)),
});

export const SetupReportBookOptions = injectIntl(
    withMasked(connect(mapStateToProps, mapDispatchToProps)(SetupReportBookOptions_))
);
