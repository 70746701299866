import { connect } from "react-redux";
import SpecificationView from "./specifications";
import { getPicklist, getUnit } from "~/admin/selectors";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import { getAgBytesEquipmentData } from "../../data/selectors";

const mapStateToProps = (state) => {
    return {
        [unitService.UNIT_WEIGHT]: getUnit(unitService.getUnitCode(unitService.UNIT_WEIGHT), state),
        [unitService.UNIT_LENGTH]: getUnit(unitService.getUnitCode(unitService.UNIT_LENGTH), state),
        [unitService.UNIT_SPEED]: getUnit(unitService.getUnitCode(unitService.UNIT_SPEED), state),
        [unitService.UNIT_VOLUME]: getUnit(unitService.getUnitCode(unitService.UNIT_VOLUME), state),
        [unitService.UNIT_FUEL_EFFICIENCY]: getUnit(
            unitService.getUnitCode(unitService.UNIT_FUEL_EFFICIENCY),
            state
        ),
        [unitService.UNIT_POWER]: getUnit(unitService.getUnitCode(unitService.UNIT_POWER), state),
        [picklistService.PICKLIST_DRIVE_TRAIN]: getPicklist(
            picklistService.getPickListCode(picklistService.PICKLIST_DRIVE_TRAIN),
            state
        ),
        [picklistService.PICKLIST_CLASS_SIZE]: getPicklist(
            picklistService.getPickListCode(picklistService.PICKLIST_CLASS_SIZE),
            state
        ),
        [picklistService.PICKLIST_FUEL_TYPE]: getPicklist(
            picklistService.getPickListCode(picklistService.PICKLIST_FUEL_TYPE),
            state
        ),
        [picklistService.PICKLIST_TRANSMISSION_TYPE]: getPicklist(
            picklistService.getPickListCode(picklistService.PICKLIST_TRANSMISSION_TYPE),
            state
        ),
        agBytesEquipmentSetup: getAgBytesEquipmentData(state),
    };
};

export const Specifications = connect(mapStateToProps, null)(SpecificationView);
