import { service, pickLists, dropdowns, unitTypes } from "./service";
import equipmentSetupSagas from "./sagas";
import * as model from "./model";
import { actions as defaultActions } from "./controller";
import * as customActions from "./actions";

const actions = {
    ...defaultActions,
    ...customActions,
};

export { service, pickLists, dropdowns, actions, unitTypes, equipmentSetupSagas, model };
