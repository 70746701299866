import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_IS_ACTIVE;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_DEVELOPER_NAMES = "developerNames";
export const PROPS_GENE_DEVELOPER_GUID = "developerGuid";
export const PROPS_GENE_GUID = adminData.PROPS_ID;
export const PROPS_GENE_ID = adminData.PROPS_INTEGER_ID;
export const PROPS_GENE_NAME = adminData.PROPS_NAME;
export const PROPS_GENE_TRAIT_CHARACTERISTIC_GUID = "traitCharacteristicGuid";
export const PROPS_GENE_TRAIT_SPECIFIC_GUID = "traitSpecificGuid";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_TRAIT_CHARACTERISTIC_NAMES = "traitCharacteristicNames";
export const PROPS_TRAIT_CHARACTERISTIC_NAME = "traitCharacteristicName";
export const PROPS_TRAIT_SPECIFIC_NAMES = "traitSpecificNames";
export const PROPS_TRAIT_SPECIFIC_NAME = "traitSpecificName";
export const PROPS_DEVELOPER_NAME = "developerName";
