import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const TractorIcon = withSvgIcon(
    <path
        d={
            "M438,256.1v-69.5h-42.5v-61.5h-82.8v61.5H294V62.1H103v82.5h21V83.1h149v124.5h144v43.6" +
            "c-3.5-0.4-7-0.6-10.6-0.6c-40.8,0-75.5,26.1-88.3,62.5h-49.3c0.3-3.8,0.5-7.7,0.5-11.6c0-55.9-34-103.8-82.5-124.2V166l63.8,0.1" +
            "v-62.5H144v63.6c-3.1-0.2-6.2-0.3-9.4-0.3C60.3,166.9,0,227.1,0,301.5s60.3,134.6,134.6,134.6c64,0,117.6-44.7,131.2-104.5h47.7" +
            "c-0.6,4.1-0.9,8.3-0.9,12.6c0,51.7,41.9,93.6,93.6,93.6S500,396,500,344.3C500,303.6,474.1,269.1,438,256.1z M165,124.6h64.5v20.5" +
            "L165,145V124.6z M134.6,386.1c-46.7,0-84.6-38-84.6-84.6c0-46.7,38-84.6,84.6-84.6c46.7,0,84.6,38,84.6,84.6" +
            "C219.2,348.2,181.3,386.1,134.6,386.1z M406.4,387.9c-24.1,0-43.6-19.6-43.6-43.6s19.6-43.6,43.6-43.6s43.6,19.6,43.6,43.6" +
            "S430.4,387.9,406.4,387.9z"
        }
    />
);
