import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";
import classNames from "classnames";
import { getAgBytesErrorClassNames } from "~/admin/utils";

// Components
import { SubSection, Checkbox, TextInput } from "~/core";

// Constants
import * as constants from "./constants";

export class EmailItem extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        intl: intlShape.isRequired,
        index: PropTypes.number,
        email: PropTypes.array,
        emailErrorCodeList: PropTypes.array,
        onChange: PropTypes.func,
        onPrimaryChange: PropTypes.func,
        required: PropTypes.bool,
    };

    getContainerClass = (className, errorCodes, type) => {
        const { email, index, required } = this.props;
        if (email[index].isError) {
            const { apiErrors, apiErrorModel } = this.props;
            let isError = false;
            if (apiErrorModel) {
                isError = errorCodes.some(
                    (code) => apiErrorModel[code] && apiErrorModel[code].includes(type)
                );
            } else if (required) {
                isError = errorCodes.some((code) => apiErrors.includes(code));
            }
            return [
                classNames(
                    className,
                    isError ? getAgBytesErrorClassNames(errorCodes, apiErrors) : ""
                ),
            ];
        }
        return [className];
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { index, onChange, onPrimaryChange, required, email, emailErrorCodeList } =
            this.props;
        return (
            <SubSection>
                <TextInput
                    required={required}
                    tabIndex={0}
                    maxLength={254}
                    placeholderText={formatMessage(messages.email)}
                    labelText={formatMessage(messages.email)}
                    onChange={(value) =>
                        onChange({ type: constants.PROPS_EMAIL_ADDRESS }, value, index)
                    }
                    value={email[index][constants.PROPS_EMAIL][constants.PROPS_EMAIL_ADDRESS]}
                    containerClassNames={this.getContainerClass(
                        "email-container",
                        emailErrorCodeList,
                        email[index][constants.PROPS_EMAIL][constants.PROPS_EMAIL_ADDRESS]
                    )}
                />
                <Checkbox
                    label={formatMessage(messages.primary)}
                    value={email[index][constants.PROPS_IS_PRIMARY]}
                    onChange={(value) => onPrimaryChange(value, index)}
                    className="is-primary-container"
                />
            </SubSection>
        );
    }
}

export default injectIntl(EmailItem);
