import { LayerAPI, LayerUtilsAPI, ISelectOption } from "@ai360/core";
import { ILayer, ISubLayer } from "@ai360/core/dist/4x/es/api/layer";
import { ANALYSIS_INFO_NAME_IMAGERY_SETUP } from "~/recs-events/analysis/model";

export const PropDialogType = {
    Unknown: 0,
    Manual: 1,
    Surface: 2,
    Analysis: 3,
    ManagementArea: 4,
};

export const createLayerTitle = (layer: LayerAPI.ILayer): string => {
    let { displayName } = layer;
    const type = LayerUtilsAPI.getLayerType(layer);

    if (
        type === LayerUtilsAPI.LayerType.ANALYSIS &&
        layer.subLayers?.length > 0 &&
        layer.layerType !== ANALYSIS_INFO_NAME_IMAGERY_SETUP
    ) {
        displayName = `${LayerUtilsAPI.getSurfaceInfo(layer).displayName} - ${displayName}`;
    } else if (
        type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA &&
        layer.subLayers[0].croppingSeason !== ""
    ) {
        displayName = `${layer.layerType} - ${layer.subLayers[0].croppingSeason} - ${displayName}`;
    } else if (type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA) {
        displayName = `${layer.layerType} - ${displayName}`;
    }
    return displayName;
};

export const createSurfaceTitle = (
    layer: LayerAPI.ILayer,
    surface: Partial<LayerAPI.ISubLayer>
): string => {
    let { displayName } = surface;
    if (!layer.isManual && !layer.isSampling && surface.surfaceTypeDisplayName) {
        displayName = `${displayName} - ${surface.surfaceTypeDisplayName}`;
    }
    return displayName;
};

export const getDefaultSubLayer = (layer: LayerAPI.ILayer): ISubLayer | ISubLayer[] => {
    const type = LayerUtilsAPI.getLayerType(layer);
    const selectedSurface = layer.subLayers
        .filter(
            (surface) =>
                (surface.surfaceTypeDisplayName &&
                    !surface.surfaceTypeDisplayName?.toLowerCase().includes("point") &&
                    !surface.surfaceTypeDisplayName?.toLowerCase().includes("coverage")) ||
                type === LayerUtilsAPI.LayerType.SOIL ||
                (type === LayerUtilsAPI.LayerType.ANALYSIS && surface.classBreaks?.length > 0) ||
                type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA
        )
        .find((sl) => {
            return (
                (layer.selectedSurfaceGuid === sl.surfaceGuid ||
                    layer.analysisLayerGuid === sl.analysisLayerGuid) &&
                layer.selectedSurfaceTypeGuid === sl.surfaceTypeGuid
            );
        });

    return (
        selectedSurface ||
        layer.subLayers.find(
            (lyr) =>
                (!lyr.surfaceTypeDisplayName?.toLowerCase().includes("point") &&
                    !lyr.surfaceTypeDisplayName?.toLowerCase().includes("coverage")) ||
                type === LayerUtilsAPI.LayerType.SOIL ||
                type === LayerUtilsAPI.LayerType.ANALYSIS ||
                type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA
        )
    );
};

export const getPropsType = (type: number, layer: ILayer): number => {
    switch (type) {
        case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
        case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
            return PropDialogType.Surface;
        case LayerUtilsAPI.LayerType.EVENT_MANUAL:
        case LayerUtilsAPI.LayerType.SOIL:
            return PropDialogType.Manual;
        case LayerUtilsAPI.LayerType.REC:
            if (layer.isManual) {
                return PropDialogType.Manual;
            } else {
                return PropDialogType.Surface;
            }
        case LayerUtilsAPI.LayerType.ANALYSIS:
            return PropDialogType.Analysis;
        case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
            return PropDialogType.ManagementArea;
        default:
            break;
    }
    return PropDialogType.Unknown;
};

export const layerOptions = (layerInfos: ILayer[]): ISelectOption<LayerAPI.ILayer>[] => {
    if (layerInfos == null) {
        return [];
    }
    const filteredLayerInfos = layerInfos.filter((layer) => {
        const type = LayerUtilsAPI.getLayerType(layer);
        return layer.subLayers.some(
            (surface) =>
                (surface.surfaceTypeDisplayName &&
                    !surface.surfaceTypeDisplayName.toLowerCase().includes("point") &&
                    !surface.surfaceTypeDisplayName.toLowerCase().includes("coverage")) ||
                type === LayerUtilsAPI.LayerType.SOIL ||
                (type === LayerUtilsAPI.LayerType.ANALYSIS && surface.classBreaks?.length > 0) ||
                type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA
        );
    });
    return filteredLayerInfos.map((layer) => ({
        label: createLayerTitle(layer),
        value: layer,
        type: LayerUtilsAPI.getLayerType(layer),
    }));
};

export const surfaceOptions = (layer: LayerAPI.ILayer): ISelectOption<LayerAPI.ISubLayer>[] => {
    if (!layer || layer.subLayers === null) {
        return [];
    }
    const type = LayerUtilsAPI.getLayerType(layer);
    return (
        layer.subLayers &&
        layer.subLayers
            .filter(
                (surface) =>
                    (surface.surfaceTypeDisplayName &&
                        !surface.surfaceTypeDisplayName.toLowerCase().includes("point") &&
                        !surface.surfaceTypeDisplayName.toLowerCase().includes("coverage")) ||
                    type === LayerUtilsAPI.LayerType.SOIL ||
                    (type === LayerUtilsAPI.LayerType.ANALYSIS &&
                        surface.classBreaks?.length > 0) ||
                    type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA
            )
            .map((surface, idx) => ({
                label:
                    type === LayerUtilsAPI.LayerType.REC
                        ? `${surface.displayName} - ${surface.surfaceTypeDisplayName}`
                        : surface.displayName,
                value: surface,
                key: `${surface.displayName}_${idx}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
    );
};
