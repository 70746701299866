import React, { Component, KeyboardEvent, ReactElement } from "react";
import { TextInput } from "../text-input/text-input";
import classNames from "classnames";
import { CancelIcon, SearchIcon } from "~/core/icons";
import "./search-input.css";

export interface ISearchInputProps {
    required?: boolean;
    autoFocus?: boolean;
    onKeyDown?: (event?: KeyboardEvent<HTMLInputElement>, searchValue?: string) => void;
    onSearchChange: (newValue?: string) => void;
    placeholderText?: string;
    searchClass?: string;
    searchLeftElements?: () => Array<JSX.Element>;
    searchRightElements?: () => Array<JSX.Element>;
    searchValue?: string;
    showTopLabel?: boolean;
    triggerOnChange?: boolean;
}

export interface ISearchInputState {
    searchValue: string;
    searchHasFocus: boolean;
    searchIsActive: boolean;
}
export class SearchInput extends Component<ISearchInputProps, ISearchInputState> {
    /// Prop type validation, Default prop values ///

    static defaultProps = {
        searchClass: "",
        searchValue: "",
        triggerOnChange: true,
        showTopLabel: false,
        placeholderText: "Search...",
        autoFocus: false,
    };
    /// ----------------------------------------- ///

    constructor(props: ISearchInputProps) {
        super(props);
        this.state = {
            searchValue: this.props.searchValue,
            searchHasFocus: true,
            searchIsActive: true,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: ISearchInputProps): void {
        if (!this.state.searchIsActive && newProps.searchValue.length > 0) {
            this.setState({
                searchIsActive: true,
            });
        } else if (
            this.state.searchIsActive &&
            !this.state.searchHasFocus &&
            newProps.searchValue === ""
        ) {
            this.setState({
                searchIsActive: false,
            });
        }

        if (newProps.searchValue !== this.props.searchValue) {
            this.setState({
                searchValue: newProps.searchValue,
            });
        }
    }
    /// Event Handlers ///
    private onSearchChange = (newValue: string): void => {
        this.setState({
            searchValue: newValue,
        });
        if (this.props.triggerOnChange) {
            this.props.onSearchChange(newValue);
        }
    };
    private onSearchClear = (): void => {
        this.props.onSearchChange("");
        this.setState({
            searchValue: "",
        });
    };
    private onSearchFocus = (): void => {
        this.setState({
            searchIsActive: true,
            searchHasFocus: true,
        });
    };
    private onSearchBlur = (): void => {
        this.setState({
            searchIsActive: this.state.searchValue.length > 0,
            searchHasFocus: false,
        });
    };
    onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
        const { onKeyDown } = this.props;
        if (onKeyDown) {
            onKeyDown(event, this.state.searchValue);
        } else {
            if (event.key === "Tab" || event.key === "Enter") {
                this.props.onSearchChange(this.state.searchValue);
            }
        }
    };

    private prepareLeftElements(): Array<JSX.Element> {
        if (this.props.searchLeftElements) {
            return this.props.searchLeftElements();
        }
        return [
            <div key="search-img" className="search-input-left-container">
                <SearchIcon className="search-img" alt="" />
                {!(
                    this.state.searchValue.length === 0 &&
                    !this.state.searchHasFocus &&
                    this.props.required
                ) ? null : (
                    <span className="search-input-required">*</span>
                )}
            </div>,
        ];
    }

    private prepareRightElements() {
        if (this.props.searchRightElements) {
            return this.props.searchRightElements();
        }
        return this.state.searchIsActive && this.state.searchValue.length > 0
            ? [
                  <span
                      key="clear-img"
                      onClick={() => {
                          this.onSearchClear();
                      }}
                      data-alt=""
                  >
                      <CancelIcon className="clear-img" />
                  </span>,
              ]
            : [];
    }
    /// ------------- ///

    render(): ReactElement {
        const searchLeftElements = this.prepareLeftElements();
        const searchRightElements = this.prepareRightElements();
        return (
            <div className={classNames("search-input", this.props.searchClass)}>
                <TextInput
                    autoFocus={this.props.autoFocus}
                    required={this.props.required}
                    showTopLabel={this.props.showTopLabel}
                    value={this.state.searchValue}
                    placeholderText={this.props.placeholderText}
                    inputContainerLeftElements={searchLeftElements}
                    inputContainerRightElements={searchRightElements}
                    onChange={(v) => {
                        this.onSearchChange(v);
                    }}
                    onFocus={() => {
                        this.onSearchFocus();
                    }}
                    onBlur={() => {
                        this.onSearchBlur();
                    }}
                    onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                        this.onKeyDown(event);
                    }}
                />
            </div>
        );
    }
}
