import { ADMIN_STATE_KEY } from "~/admin";
import { EQUATION_LIST_DATA_KEY } from "./reducer";

const _getModuleState = (state) => state[ADMIN_STATE_KEY][EQUATION_LIST_DATA_KEY];

export const getEquationParametersPicklist = (state) =>
    _getModuleState(state).equationParametersPicklist;
export const getEquationTestValue = (state) => _getModuleState(state).testValue;
export const getValidCropList = (state) => _getModuleState(state).validCropList;
export const getValidEquationResponse = (state) => _getModuleState(state).validEquation;
