import React, { Component } from "react";

const WithNestedGrid = (View) =>
    class WrapperComponent extends Component {
        constructor(props) {
            super(props);
            this.state = {
                parentGuid: null,
            };
        }
        toggleChildComponent = (record, recordGuid) => {
            this.setState({
                selectedGuid: recordGuid,
            });
        };
        render() {
            const { toggleChildComponent } = this;
            const { selectedGuid } = this.state;
            const props = {
                ...this.props,
                toggleChildComponent,
                selectedGuid,
            };
            return <View {...props} />;
        }
    };

export default WithNestedGrid;
