import { createReducer } from "redux-act";
import * as actions from "./actions";

export const EQUIPMENT_SETUP_DATA_KEY = "EQUIPMENT_SETUP_DATA";
const initialState = {
    agBytesEquipmentData: {},
};

export const equipmentSetupReducer = createReducer(
    {
        [actions.fetchAgbytesEquipmentSuccess]: (state, payload) => ({
            ...state,
            agBytesEquipmentData: {
                ...payload,
            },
        }),
        [actions.clearAgbytesEquipmentData]: (state) => ({
            ...state,
            agBytesEquipmentData: {},
        }),
    },
    initialState
);
