import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";
import type Geometry from "@arcgis/core/geometry/Geometry";
import {
    DialogBox,
    DialogBoxFooterType,
    RadioButtonGroup,
    RadioButton,
    NumericInput,
    SelectInput,
} from "~/core";
import { getTheUserGuid } from "~/login";
import { selectors as cdSelectors } from "~/customer-data";
import { ACTIVE_YN } from "~/core/picklist";
import { NonFieldFeatureAPI } from "@ai360/core";
import { IFormatter, IBufferOptions, Unit, ISelectOption } from "@ai360/core";
import { messages } from "../../i18n-messages";
import { mapToolsSelectors, mapToolsActions } from "~/map";
import "./buffer-setup-modal.css";
export interface IBufferSetupDialogProps extends IBufferOptions {
    intl: IFormatter;
    lengthUnits: Unit[];
    onAction(): void;
    onClose(): void;
    onUpdate(options: Partial<IBufferOptions>): void;
    userGuid: string;
    visibleNonFieldFeatures: Immutable.Set<NonFieldFeatureAPI.NonFieldFeature>;
}

class BufferSetupModal_ extends Component<IBufferSetupDialogProps> {
    #nonFieldFeatureOptions: ISelectOption<string>[];
    #activeFeature: NonFieldFeatureAPI.NonFieldFeature;
    get isValid(): boolean {
        switch (this.props.source) {
            case "boundary":
            case "measure":
                return this.props.distance > 0 && this.props.unit != null;
            case "feature":
                return (
                    this.props.geometries?.length > 0 &&
                    this.props.distance > 0 &&
                    this.props.unit != null
                );
            default:
                return false;
        }
    }
    private getSources(): JSX.Element {
        const { formatMessage } = this.props.intl;
        return (
            <div className="buffer-source">
                <div className="radio-grp-lbl">
                    {this.props.intl.formatMessage(messages.bufferSourceLbl)}
                </div>
                <RadioButtonGroup
                    className="radio-grp-source"
                    selectedValue={this.props.source}
                    afterOnChange={(source) => this.props.onUpdate({ source } as IBufferOptions)}
                >
                    <RadioButton
                        value="boundary"
                        label={formatMessage(messages.bufferSourceBoundaryLbl)}
                    />
                    <RadioButton
                        value="measure"
                        label={formatMessage(messages.bufferSourceMeasureLbl)}
                    />
                    <RadioButton
                        value="feature"
                        label={formatMessage(messages.bufferSourceFeatureLbl)}
                    />
                </RadioButtonGroup>
            </div>
        );
    }
    private getDimensions(): JSX.Element {
        const { source, distance, unit, lengthUnits } = this.props;
        const { formatMessage } = this.props.intl;
        const lengthOptions = lengthUnits.map((lengthUnit: Unit, i: number) => {
            const esriUnit = this.getEsriUnit(lengthUnit.fullName);
            return {
                value: esriUnit,
                label: lengthUnit.name,
                selected: unit == null ? i === 0 : unit === esriUnit,
            };
        });
        let nonFieldFeatures = null;
        if (source === "feature") {
            nonFieldFeatures = (
                <div className="buffer-input-forms">
                    <SelectInput
                        required
                        clearable={false}
                        placeholderText={formatMessage(messages.bufferSourceFeatureLbl)}
                        onChange={(id) => this.setActiveFeature(id)}
                        options={this.#nonFieldFeatureOptions}
                        value={this.#activeFeature?.id}
                    />
                </div>
            );
        }
        return (
            <div className="buffer-dimensions">
                {nonFieldFeatures}
                <div className="buffer-input-forms">
                    <NumericInput
                        required
                        placeholderText={formatMessage(messages.bufferDistanceLbl)}
                        onChange={(newValue, formatted, distance) =>
                            this.props.onUpdate({ distance })
                        }
                        value={distance}
                    />
                    <SelectInput
                        required
                        clearable={false}
                        optionIsHiddenKey={ACTIVE_YN}
                        onChange={(unit) => this.props.onUpdate({ unit })}
                        placeholderText={formatMessage(messages.bufferUnitLbl)}
                        options={lengthOptions}
                        value={unit}
                    />
                </div>
            </div>
        );
    }
    private getEsriUnit(unit: string): __esri.LinearUnits {
        const unitName = unit.toUpperCase();
        // Supported linear units for `geometryEngine.buffer`
        // string units: https://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-geometryEngine.html#LinearUnits
        // linear units: https://developers.arcgis.com/java/api-reference/reference/com/esri/arcgisruntime/geometry/LinearUnitId.html#enum.constant.detail
        switch (true) {
            case unitName.indexOf("KILOMETER") >= 0:
                return "kilometers";
            case unitName.indexOf("CENTIMETER") >= 0:
                return 1033;
            case unitName.indexOf("MILLIMETER") >= 0:
                return 1025;
            case unitName.indexOf("METER") >= 0:
                return "meters";
            case unitName.indexOf("NAUTICAL") >= 0:
                return "nautical-miles";
            case unitName.indexOf("MILE") >= 0:
                return "miles";
            case unitName.indexOf("YARD") >= 0:
                return "yards";
            case unitName.indexOf("FOOT") >= 0:
            case unitName.indexOf("FEET") >= 0:
                return "feet";
            case unitName.indexOf("INCH") >= 0:
                return 109008;
            default:
                console.error(
                    this.props.intl.formatMessage(messages.bufferUnsupportedUnit, { unit }) +
                        "\nhttps://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-geometryEngine.html#LinearUnits"
                );
        }
    }
    private getInstructions() {
        const { source } = this.props;
        const instructions = this.props.intl.formatMessage(
            source === "boundary"
                ? messages.bufferSourceBoundaryDesc
                : source === "feature"
                ? messages.bufferSourceFeatureDesc
                : messages.bufferSourceMeasureDesc
        );
        return <div className="buffer-instructions">{instructions}</div>;
    }
    private setActiveFeature(id: string) {
        this.#activeFeature = this.props.visibleNonFieldFeatures.find((f) => f.id === id);
        const geometries = this.#activeFeature?.geometries.map(
            (geometry) => geometry.shape as Geometry
        );
        this.props.onUpdate({ geometries });
    }
    private sort(a: NonFieldFeatureAPI.NonFieldFeature, b: NonFieldFeatureAPI.NonFieldFeature) {
        return a.type < b.type
            ? -1
            : a.type > b.type
            ? 1
            : a.name < b.name
            ? -1
            : a.name > b.name
            ? 1
            : 0;
    }
    UNSAFE_componentWillReceiveProps(nextProps: IBufferSetupDialogProps) {
        const { customerGuid, visibleNonFieldFeatures } = nextProps;

        this.#nonFieldFeatureOptions = visibleNonFieldFeatures
            .toArray()
            .filter((feature) => feature.customerId === customerGuid)
            .sort(this.sort)
            .map((feature) => ({
                value: feature.id,
                label: `${feature.type} > ${feature.name}`,
            }));
    }

    render() {
        const { open, onClose } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div
                className={classNames("panel-modal buffer-setup", open ? "buffer-setup-open" : "")}
            >
                <DialogBox
                    unrestricted
                    draggable
                    isOpen={open}
                    className="buffer-setup-modal"
                    action={formatMessage(messages.bufferAddLbl)}
                    actionDisabled={!this.isValid}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    onAction={() => this.props.onAction()}
                    onClose={() => onClose()}
                    title={formatMessage(messages.bufferSetupLbl)}
                >
                    {this.getSources()}
                    {this.getDimensions()}
                    {this.getInstructions()}
                </DialogBox>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const bufferOptions = mapToolsSelectors.getBufferOptions(state);
    return {
        ...bufferOptions,
        lengthUnits: mapToolsSelectors.getLengthUnits(state),
        userGuid: getTheUserGuid(state),
        visibleNonFieldFeatures: cdSelectors.getVisibleNonFieldFeatures(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onAction: () => dispatch(mapToolsActions.setBufferReady(true)),
        onClose: () => dispatch(mapToolsActions.setBufferOptions({ open: false })),
        onUpdate: (e: Partial<IBufferOptions>) => dispatch(mapToolsActions.setBufferOptions(e)),
    };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(BufferSetupModal_));
