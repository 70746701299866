import React, { Component } from "react";
import PropTypes from "prop-types";
import { model } from "../../data";
import { EquipmentProfileAPI } from "@ai360/core";
import { SelectInput, DialogBox, DialogBoxFooterType, NoLink, ZeroToInfiniteGrid } from "~/core";
import { withEditableGrid } from "~/hocs";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import { messages } from "../../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE, LABEL } from "~/admin/data";

const PROPS_EQUIPMENT_TYPE_GUID = "equipmentTypeGuid";
const PROPS_MANUFACTURER_GUID = "manufacturerGuid";
const PROPS_EQUIPMENT_TYPE = "equipmentTypeName";
const PROPS_MANUFACTURER = "manufacturerName";
const PROPS_MODEL_NAME = "modelName";
const PROPS_MODEL_NAME_TEXT = "modelNameText";

const EQUIPMENT_FILTER = "equipmentFilter";
const MANUFACTURER_FILTER = "manufacturerFilter";

class AccessoriesList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        needs: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchDropdownData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            record: [],
            editingItem: false,
            [EQUIPMENT_FILTER]: prepareSelectableOptions(props[EQUIPMENT_FILTER], {
                guid: PICKLIST_GUID,
                label: VALUE,
            }),
            [MANUFACTURER_FILTER]: prepareSelectableOptions(props[MANUFACTURER_FILTER], {
                guid: PICKLIST_GUID,
                label: VALUE,
            }),
            [model.PROPS_MODEL_NAME]: prepareSelectableOptions(props[model.PROPS_MODEL_NAME], {
                guid: GUID,
                label: NAME,
            }),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({ record: nextProps.record });
        }
        this.initializeEquipmentFilterType(nextProps);
        this.initializeManufacturerFilter(nextProps);
        this.initializeModels(nextProps);
    }

    initializeAccessoriesList = (record) => {
        const manufacturerOptions = this.state[MANUFACTURER_FILTER];
        const equipmentOptions = this.state[EQUIPMENT_FILTER];
        const modelNameOptions = this.state[model.PROPS_MODEL_NAME];
        const newRecord = record.map((recordItem) => {
            const selectedManufacturer = manufacturerOptions.filter(
                ({ value }) => value.guid === recordItem[PROPS_MANUFACTURER_GUID]
            );
            recordItem[PROPS_MANUFACTURER] = selectedManufacturer.length
                ? selectedManufacturer[0][LABEL]
                : "";
            const selectedEquipmentParentType = equipmentOptions.filter(
                ({ value }) => value.guid === recordItem[PROPS_EQUIPMENT_TYPE_GUID]
            );
            recordItem[PROPS_EQUIPMENT_TYPE] = selectedEquipmentParentType.length
                ? selectedEquipmentParentType[0][LABEL]
                : "";
            const selectedModelName = modelNameOptions.filter(
                ({ value }) => value.guid === recordItem[PROPS_MODEL_NAME]
            );
            recordItem[PROPS_MODEL_NAME_TEXT] = selectedModelName.length
                ? selectedModelName[0][LABEL]
                : "";
            return recordItem;
        });
        this.setState({ record: newRecord });
    };

    initializeEquipmentFilterType = (nextProps) => {
        if (nextProps[EQUIPMENT_FILTER] !== this.props[EQUIPMENT_FILTER]) {
            this.setState({
                [EQUIPMENT_FILTER]: prepareSelectableOptions(nextProps[EQUIPMENT_FILTER], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    initializeManufacturerFilter = (nextProps) => {
        if (nextProps[MANUFACTURER_FILTER] !== this.props[MANUFACTURER_FILTER]) {
            this.setState(
                {
                    [MANUFACTURER_FILTER]: prepareSelectableOptions(
                        nextProps[MANUFACTURER_FILTER],
                        { guid: PICKLIST_GUID, label: VALUE }
                    ),
                },
                () => {
                    if (nextProps.record.length) {
                        this.initializeAccessoriesList(nextProps.record);
                    }
                }
            );
        }
    };

    initializeModels = (nextProps) => {
        if (nextProps[model.PROPS_MODEL_NAME] !== this.props[model.PROPS_MODEL_NAME]) {
            this.setState({
                [model.PROPS_MODEL_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_MODEL_NAME],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    onEdit = (record, index) => {
        this.setState({ editingItem: true });
        this.onEquipmentTypeChange(record[PROPS_EQUIPMENT_TYPE_GUID]);
        this.onManufacturerTypeChange(
            record[PROPS_EQUIPMENT_TYPE_GUID],
            record[PROPS_MANUFACTURER_GUID]
        );
        const keysToUpdate = [
            {
                name: [PROPS_MANUFACTURER_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [PROPS_EQUIPMENT_TYPE_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [PROPS_MODEL_NAME],
                key: GUID,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    onEquipmentTypeChange = ({ guid }) => {
        this.props.needs([
            this.props.fetchDropdownData({
                [MANUFACTURER_FILTER]: {
                    url: EquipmentProfileAPI.GET_MANUFACTURER_FILTER_LIST,
                    model: {
                        EquipmentParentTypeId: 2,
                        EquipmentType: guid,
                    },
                },
            }),
        ]);
    };

    onManufacturerTypeChange = (equipmentTypeGuid, manufacturerGuid) => {
        this.props.needs([
            this.props.fetchDropdownData({
                [model.PROPS_MODEL_NAME]: {
                    url: EquipmentProfileAPI.GET_EQUIPMENT_AGBYTES_LIST,
                    model: {
                        equipmentParentTypeGuid: null,
                        equipmentTypeGuid: equipmentTypeGuid,
                        manufacturerGuid: manufacturerGuid,
                    },
                },
            }),
        ]);
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                action="save"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                unrestricted
                className="accessories-dialog"
                actionDisabled={
                    !editData[PROPS_EQUIPMENT_TYPE_GUID] ||
                    !editData[PROPS_MANUFACTURER_GUID] ||
                    !editData[PROPS_MODEL_NAME]
                }
                isOpen={isEditing}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.accessory,
                    { count: 1 }
                )}
            >
                <div className="accessories-group">
                    <SelectInput
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        clearable={false}
                        autoFocus
                        openOnFocus={false}
                        options={this.state[EQUIPMENT_FILTER]}
                        value={mapToPicklistValue({
                            options: this.state[EQUIPMENT_FILTER],
                            selectedGuid: editData[PROPS_EQUIPMENT_TYPE_GUID],
                        })}
                        onChange={(value) => {
                            this.onEquipmentTypeChange(value);
                            onChange({
                                [PROPS_EQUIPMENT_TYPE_GUID]: value.guid,
                                [PROPS_EQUIPMENT_TYPE]: value.name,
                                [PROPS_MANUFACTURER_GUID]: "",
                                [PROPS_MANUFACTURER]: "",
                                [PROPS_MODEL_NAME]: "",
                                [PROPS_MODEL_NAME_TEXT]: "",
                            });
                        }}
                        placeholderText={formatMessage(messages.equipmentType)}
                        required
                    />
                    <SelectInput
                        clearable={false}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[MANUFACTURER_FILTER]}
                        value={mapToPicklistValue({
                            options: this.state[MANUFACTURER_FILTER],
                            selectedGuid: editData[PROPS_MANUFACTURER_GUID],
                        })}
                        onChange={(value) => {
                            this.onManufacturerTypeChange(
                                editData[PROPS_EQUIPMENT_TYPE_GUID],
                                value.guid
                            );
                            onChange({
                                [PROPS_MANUFACTURER_GUID]: value.guid,
                                [PROPS_MANUFACTURER]: value.name,
                                [PROPS_MODEL_NAME]: "",
                                [PROPS_MODEL_NAME_TEXT]: "",
                            });
                        }}
                        placeholderText={formatMessage(messages.manufacturer)}
                        required
                    />
                </div>
                <div className="accessories-group">
                    <SelectInput
                        clearable={false}
                        disabled={
                            editData[model.PROPS_MANUFACTURER_GUID] == null ||
                            editData[model.PROPS_MANUFACTURER_GUID] === "" ||
                            this.state[model.PROPS_MODEL_NAME].length === 0
                        }
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[model.PROPS_MODEL_NAME]}
                        value={mapToPicklistValue({
                            options: this.state[model.PROPS_MODEL_NAME],
                            selectedGuid: editData[PROPS_MODEL_NAME],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_MODEL_NAME]: value.guid,
                                [PROPS_MODEL_NAME_TEXT]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.modelName)}
                        required
                    />
                </div>
            </DialogBox>
        );
    };

    render() {
        const { formatMessage } = this.props;
        const { record } = this.state;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_EQUIPMENT_TYPE]: {
                                title: formatMessage(messages.equipmentParentType),
                            },
                            [PROPS_MANUFACTURER]: {
                                title: formatMessage(messages.manufacturer),
                            },
                            [PROPS_MODEL_NAME_TEXT]: {
                                title: formatMessage(messages.modelName),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.accessory, {
                            count: 1,
                        })}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(AccessoriesList);
