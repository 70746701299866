import { connect } from "react-redux";
import { IReportTable_Props, ReportTableView } from "./report-table";

import { setIsExpanded } from "~/action-panel/actions";
import { getTheUserGuid } from "~/login";
import { getCurrentTheme } from "~/theme";

import { actions, selectors } from "../../data";

const mapStateToProps = (state) => ({
    reportStatus: selectors.getReportStatus(state),
    userGuid: getTheUserGuid(state),
    currentThemeName: getCurrentTheme(state),
    isTableLoading: selectors.getIsTableLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    setIsExpanded: (v) => dispatch(setIsExpanded(v)),
    clearReportHubData: () => dispatch(actions.clearReportHubData()),
    sendEmail: (payload) => dispatch(actions.sendEmail(payload)),
    downloadReports: (payload) => dispatch(actions.downloadReports(payload)),
    mergeReports: (payload) => dispatch(actions.mergeReports(payload)),
    // Action to be dispatched using needs
    deleteSelectedReports: (payload) => actions.deleteSelectedReports(payload),
    printReports: (payload) => actions.printReports(payload),
});

export const ReportTable = connect<
    Partial<IReportTable_Props>,
    Partial<IReportTable_Props>,
    Partial<IReportTable_Props>
>(
    mapStateToProps,
    mapDispatchToProps
)(ReportTableView);
