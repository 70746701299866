import React from "react";
import type MapView from "@arcgis/core/views/MapView";
import { Toolbar, ToolbarButton, ToolbarMenu } from "~/core";
import { IToolbar, IToolbarOptions } from "@ai360/core";
import { ProcessGroup } from "../reducer";
import { Set } from "immutable";

interface IDimensions {
    top: number;
    right: number;
    width: number;
    height: number;
}
export interface IProcessCountTools extends IToolbarOptions {
    currentProcessCounts: Map<string, Set<string>>;
    rtl: boolean;
}
export class ProcessCountTools implements IToolbar {
    private currentProcessCounts: Map<string, Set<string>>;
    public map: MapView;
    public options: IProcessCountTools;

    /**
     * Tools for navigating to the previous and next extent as well as full extent
     * @param {esri.map} map an esri map object to bind to the toolbar
     * @param {object} options
     * @param {intl.defineMessages} options.messages react-intl defineMessages
     * @param {props.intl.formatMessage} options.formatMessage react-intl formatMessages from props
     * @param {function} options.forceUpdate function called when the toolbar needs to update
     * @param {object} options.currentProcessCounts
     * @param {boolean} options.rtl
     */
    constructor(map: MapView, options: IProcessCountTools) {
        this.map = map;
        this.options = options;
        this.currentProcessCounts = new Map<string, Set<string>>();
        this.setCurrentProcessCounts(options.currentProcessCounts);
    }

    private forceUpdate() {
        if (this.options.forceUpdate) {
            this.options.forceUpdate();
        }
    }

    private label(processGroup: string) {
        const { messages, formatMessage } = this.options;
        return formatMessage(messages.processCountsInfoLbl, {
            type: processGroup,
        });
    }

    private icon(count: number) {
        const display = count === 0 ? "-" : count.toString();
        return <div className="queue-status-info">{display}</div>;
    }

    destroy(): void {
        Object.keys(this).forEach((key) => {
            this[key] = null;
        });
    }

    getDimensions(): IDimensions {
        //hard coding this for now ... might come back and make dynamic later
        return {
            top: 0,
            right: 0,
            width: 430,
            height: 51,
        };
    }

    processIcon(processGroup: string): JSX.Element {
        return this.icon(this.currentProcessCounts.get(processGroup).size);
    }

    totalIcon(): JSX.Element {
        return this.icon(
            [...this.currentProcessCounts.values()].reduce(
                (accumulator, x) => accumulator + x.size,
                0
            )
        );
    }

    setCurrentProcessCounts(currentProcessCounts: Map<string, Set<string>>): void {
        this.currentProcessCounts = currentProcessCounts;
        this.forceUpdate();
    }

    getToolbar(): JSX.Element {
        const { messages, formatMessage, rtl } = this.options;

        const statuses = Object.keys(ProcessGroup).map((processGroup) => (
            <ToolbarButton
                className="queue-status-info"
                key={processGroup}
                icon={this.processIcon(processGroup)}
                label={this.label(processGroup)}
            />
        ));

        return (
            <ToolbarMenu
                className="queue-status"
                icon={this.totalIcon()}
                label={formatMessage(messages.processCountsLbl)}
                rtl={rtl}
            >
                <Toolbar className="process-count-tools" rtl={rtl}>
                    {statuses}
                </Toolbar>
            </ToolbarMenu>
        );
    }
}
