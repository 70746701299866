import { connect } from "react-redux";
import VehicleView from "./vehicle";
import { getPicklist, getUnit } from "~/admin/selectors";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";

const mapStateToProps = (state) => ({
    [picklistService.PICKLIST_HITCH_TYPE]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_HITCH_TYPE),
        state
    ),
    [picklistService.PICKLIST_TIRES_TYPE]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_TIRES_TYPE),
        state
    ),
    [unitService.UNIT_LENGTH]: getUnit(unitService.getUnitCode(unitService.UNIT_LENGTH), state),
    [unitService.UNIT_WEIGHT]: getUnit(unitService.getUnitCode(unitService.UNIT_WEIGHT), state),
    [unitService.UNIT_PRESSURE]: getUnit(unitService.getUnitCode(unitService.UNIT_PRESSURE), state),
});

export const Vehicle = connect(mapStateToProps, null)(VehicleView);
