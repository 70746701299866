import React, { Component, ReactNode, MouseEvent, ReactElement } from "react";
import classnames from "classnames";

export interface IToolbarMenuContentProps {
    _includeClose?: boolean;
    _onClose?: (e: MouseEvent) => void;
    className?: string;
    children?: ReactNode;
    rtl?: boolean;
}

export interface IToolbarMenuContentState {
    openMenuIndex: number;
    selectedToolIndex: number;
}

export class ToolbarMenuContent extends Component<
    IToolbarMenuContentProps,
    IToolbarMenuContentState
> {
    static defaultProps = {
        _includeClose: undefined,
        className: "",
        children: [],
        rtl: false,
    };

    constructor(props: IToolbarMenuContentProps) {
        super(props);
        this.state = {
            openMenuIndex: null,
            selectedToolIndex: null,
        };
    }

    private _handleClose(e: MouseEvent): void {
        if (this.props._onClose) {
            this.props._onClose(e);
        }
    }

    render(): ReactElement {
        const { children, _includeClose, rtl } = this.props;
        const closebar = (
            <div
                key="close"
                className={classnames("toolbar-close-bar", { rtl })}
                onClick={(e) => this._handleClose(e)}
            >
                X
            </div>
        );

        const items = [children];
        if (_includeClose) {
            if (rtl) {
                items.unshift(closebar);
            } else {
                items.push(closebar);
            }
        }
        return (
            <div className={classnames("toolbar-menu-content-wrapper", this.props.className)}>
                {items}
            </div>
        );
    }
}
