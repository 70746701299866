import React from "react";
import PropTypes from "prop-types";
import { CloseIcon } from "~/core/icons";
import "./sliding-panel.css";

const TOP_LEVEL_NAME_CODE = "100";

export const HeaderItem = ({ text, isFirst = false }) => (
    <div className="header-item" key={`header-item-${text}`}>
        {!isFirst && <span className="header-arrow">{">"}</span>}
        <span className="display-text">{text}</span>
    </div>
);

HeaderItem.propTypes = {
    text: PropTypes.string.isRequired,
    isFirst: PropTypes.bool,
};

export const SlidingPanelHeader = ({
    breadcrumbs,
    children,
    close,
    formatMessage,
    isEnabled,
    navigateTo,
    systemName,
}) => {
    function closeSidePanel() {
        if (close) {
            close();
        }
    }

    const getBreadCrumbTitle = (title) => {
        if (title instanceof Object) {
            return formatMessage(title);
        }
        return title;
    };

    function getBreadcrumbs() {
        return (
            breadcrumbs &&
            breadcrumbs.map((item) => {
                const title = getBreadCrumbTitle(item);
                return <HeaderItem key={title} text={title} />;
            })
        );
    }

    // parentNameCode 105 === "Reports" found at src/navigation/i18n-messages.js
    if (
        !isEnabled &&
        (navigateTo.parentNameCode?.toString() === "105" ||
            navigateTo.childNameCode?.toString() === "429")
    ) {
        //Close if Reports or Notifications are currently open
        closeSidePanel();
    }

    /**
     * Note:- The first two crumbs are fetched from the view-resolver so they're static.
     */
    const topLevelParent =
        navigateTo.parentNameCode && navigateTo.parentNameCode.toString() === TOP_LEVEL_NAME_CODE;
    const childName = navigateTo.childNameCode === "224" ? systemName : navigateTo.childName; //Todo: this should display the Owner Branding Name over the new Branding Name
    return (
        <div className="sliding-panel-header">
            <div className="display-container">
                <span className="display-text-container">
                    <div>
                        {topLevelParent ? null : (
                            <HeaderItem text={navigateTo.parentName} isFirst={!topLevelParent} />
                        )}
                        <HeaderItem text={childName} isFirst={topLevelParent} />
                        {getBreadcrumbs()}
                    </div>
                </span>
                <div className="button-container">
                    {children || (
                        <span className="close-button-container" onClick={closeSidePanel}>
                            <CloseIcon />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

SlidingPanelHeader.propTypes = {
    breadcrumbs: PropTypes.array,
    children: PropTypes.arrayOf(PropTypes.element),
    close: PropTypes.func,
    formatMessage: PropTypes.func,
    isEnabled: PropTypes.bool,
    navigateTo: PropTypes.object.isRequired,
    systemName: PropTypes.string,
};
