import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { EquipmentProfileAPI } from "@ai360/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../../i18n-messages";
import { SelectInput, withAsyncOptions, withAutoWidth } from "~/core";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { SearchIcon } from "~/core/icons";
import classnames from "classnames";

// Styles
//import "./component.css";

const searchIcon = (
    <span key="search" className="search-icon">
        <SearchIcon className="search-icon" />
    </span>
);

class SearchTelematicsUser extends Component {
    static propTypes = {
        className: PropTypes.string,
        disabled: PropTypes.bool,
        initialValue: PropTypes.string,
        intl: intlShape.isRequired,
        onApiCallErr: PropTypes.func,
        onSelection: PropTypes.func,
        userGuid: PropTypes.string,
    };

    static defaultProps = {
        filteredData: [],
        className: "",
    };

    static PERSON_NAME = "personName";
    static PERSON_JOB = "jobTitleName";
    static PERSON_CITY = "city";
    static USER_GUID = "userGuid";
    static PERSON_STATE_ABR = "stateAbbreviation";

    constructor(props) {
        super(props);
        this.state = {
            initialValue: "",
        };
        this.AsyncSelectInput = withAsyncOptions(withAutoWidth(SelectInput, 350), (userFilterStr) =>
            this.getUserOptions(userFilterStr)
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialValue !== this.props.initialValue) {
            this.setState({
                initialValue: nextProps.initialValue,
            });
        }
    }

    onItemSelection = (value) => {
        if (this.props.onSelection) {
            this.props.onSelection(value);
        }
        this.setState({
            initialValue: value,
        });
    };

    onInputChange = (initialValue) => this.setState({ initialValue });

    getUserOptions = (filterStr) => {
        if (filterStr === "") {
            return Promise.resolve([]);
        }

        const { userGuid, onApiCallErr } = this.props;

        return EquipmentProfileAPI.searchOnsiteUsers(userGuid, filterStr)
            .then((results) =>
                results.map((record) => ({
                    ...record,
                    label: record[SearchTelematicsUser.PERSON_NAME],
                }))
            )
            .catch((err) => {
                onApiCallErr(err);
                return [];
            });
    };

    optionRenderer = ({ option, isSelected, isHighlighted }) => {
        const className = classnames(
            "select-form-input-option",
            {
                selected: isSelected,
                "filter-match": isHighlighted,
            },
            "search-person-options"
        );
        const cityAndState = [
            option[SearchTelematicsUser.PERSON_CITY],
            option[SearchTelematicsUser.PERSON_STATE_ABR],
        ].join(", ");
        const optionTitle = [
            option[SearchTelematicsUser.PERSON_NAME],
            option[SearchTelematicsUser.PERSON_JOB],
            cityAndState,
        ].join(" ");
        return (
            <div className={className} title={optionTitle}>
                <div className="person-info">
                    <div className="person-name">{option[SearchTelematicsUser.PERSON_NAME]}</div>
                    <div className="person-jobtitle">{option[SearchTelematicsUser.PERSON_JOB]}</div>
                </div>
                <div className="person-state">{cityAndState} </div>
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { AsyncSelectInput } = this;
        return (
            <AsyncSelectInput
                clearFilterInputOnBlur={false}
                initialFilterStr={this.state.initialValue}
                value={this.state.initialValue}
                placeholderText={formatMessage(messages.searchFor, {
                    searchedFor: formatMessage(messages.primaryUser),
                })}
                inputContainerLeftElements={[searchIcon]}
                arrowRenderer={() => null}
                onChange={(val) => this.onItemSelection(val)}
                onInputChange={(val) => this.onInputChange(val)}
                optionRenderer={this.optionRenderer}
                optionHeight={42}
                valueKey={SearchTelematicsUser.USER_GUID}
                containerClassNames={["person-filter", this.props.className]}
                disabled={this.props.disabled}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiCallErr: (error) => dispatch(notificationActions.apiCallError(error)),
});

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchTelematicsUser));
