import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl, intlShape, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";

import { getUser } from "~/login";
import { Tabs, Pane, Button } from "~/core";
import { BroadcastIcon } from "~/core/icons";
import { selectors as fileImportSelectors, actions as fileImportActions } from "~/file-import";
import { selectors as uploaderSelectors } from "~/action-panel/components/common/drag-and-drop-file-uploader";

import { UPLOAD_MODAL_STATE_KEY, UploadModal } from "../../upload-modal/upload-modal";

import { TabsContextMenu } from "./tabs-context-menu-container";
import { SearchUser } from "./search-user";

import "../../../../module-tabs/filter-tabs.css";
import "./import-filter-tabs.css";

const messages = defineMessages({
    myFilesTabText: {
        id: "importFilterTabs.myFilesTabText",
        defaultMessage: "My Files",
    },
    allFilesTabText: {
        id: "importFilterTabs.allFilesTabText",
        defaultMessage: "All Files",
    },
    uploadFilesBtn: {
        id: "importFilterTabs.uploadFilesBtn",
        defaultMessage: "Upload File(s)",
    },
});

export class ImportFilterTabs_ extends Component {
    static propTypes = {
        includeOtherUsers: PropTypes.bool.isRequired,
        isPreparingUpload: PropTypes.bool.isRequired,
        isTelematicsUser: PropTypes.bool,
        isTelematicsProcessing: PropTypes.bool,
        setIncludeOtherUsers: PropTypes.func.isRequired,
        telematicsCounts: PropTypes.shape({
            completeCount: PropTypes.number,
            pendingCount: PropTypes.number,
            errorCount: PropTypes.number,
        }),
        userRole: PropTypes.object,
        intl: intlShape.isRequired,
        children: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            isUploadModalOpen: false,
        };
    }

    render() {
        const {
            includeOtherUsers,
            isPreparingUpload,
            isTelematicsProcessing,
            isTelematicsUser,
            telematicsCounts,
            setIncludeOtherUsers,
            userRole,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const myFilesPane = (
            <Pane key={0} label={<FormattedMessage {...messages.myFilesTabText} />}>
                {this.props.children}
            </Pane>
        );
        const allFilesPane = (
            <Pane key={1} label={<FormattedMessage {...messages.allFilesTabText} />}>
                <SearchUser />
                {this.props.children}
            </Pane>
        );

        const selectedTabIdx = includeOtherUsers ? 1 : 0;

        const labelContainerRightElements = [
            <div key={"import-filter-tabs-right"} className="import-filter-tabs-right">
                {!isTelematicsUser ? null : !userRole.telematics ? null : (
                    <div
                        className={classNames([
                            "onsite-telematics-status",
                            {
                                processing:
                                    telematicsCounts.pendingCount > 0 || isTelematicsProcessing,
                            },
                        ])}
                    >
                        <BroadcastIcon />
                        <div className="onsite-telematics-count">
                            {telematicsCounts.pendingCount}
                        </div>
                    </div>
                )}
                <Button
                    value={formatMessage(messages.uploadFilesBtn)}
                    onClick={() => this.setState({ isUploadModalOpen: true })}
                />
                <TabsContextMenu />
                {!this.state.isUploadModalOpen && !isPreparingUpload ? null : (
                    <UploadModal onClose={() => this.setState({ isUploadModalOpen: false })} />
                )}
            </div>,
        ];

        return (
            <Tabs
                className="filter-tabs"
                selected={selectedTabIdx}
                labelContainerRightElements={labelContainerRightElements}
                onTabSelect={(tabIdx) => setIncludeOtherUsers(tabIdx === 1)}
            >
                {myFilesPane}
                {allFilesPane}
            </Tabs>
        );
    }
}

const mapStateToProps = (state) => {
    const filter = fileImportSelectors.getFetchAllUsersFilter(state);
    const { isPreparingUpload } = uploaderSelectors.getModuleState(state, UPLOAD_MODAL_STATE_KEY);
    const telematicsCounts = fileImportSelectors.getTelematicsCounts(state);
    const isTelematicsProcessing = fileImportSelectors.getIsTelematicsProcessing(state);
    const isTelematicsUser = fileImportSelectors.getIsTelematicsUser(state);
    const userRole = getUser(state).role;

    return {
        includeOtherUsers: filter.includeOtherUsers,
        isPreparingUpload,
        isTelematicsProcessing,
        isTelematicsUser,
        telematicsCounts,
        userRole,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setIncludeOtherUsers: (includeOtherUsers) =>
        dispatch(fileImportActions.setFilterIncludeOtherUsers(includeOtherUsers)),
});

export const ImportFilterTabs = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ImportFilterTabs_));
