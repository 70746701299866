import { useMemo } from "react";
import { UserAPI } from "@ai360/core";
import { useAppSelector } from "~/store/hooks";
import { getLoginGuid } from "~/login/selectors";

interface ILoginUserPreference {
    maxCloudPct?: string;
}

const convertBoolean = (value) => {
    if (value === "True") return true;
    if (value === "False") return false;
    return value;
};

const useLoginUserPreferences = (prefix: string, preferences: string[]) => {
    const loginGuid = useAppSelector(getLoginGuid);

    const preferenceNames = useMemo(
        () => preferences.map((pref) => `${prefix}_${pref}`),
        [preferences, prefix]
    );

    const { data, error, isLoading } = UserAPI.useGetLoginPreferencesQuery(
        {
            loginGuid,
            names: preferenceNames,
        },
        {
            skip: !loginGuid,
        }
    );

    const loginUserPreferences: ILoginUserPreference = useMemo(() => {
        return data?.reduce((acc, p) => {
            const key = p.id.name.replace(`${prefix}_`, "");
            acc[key] = convertBoolean(p.value);
            return acc;
        }, {});
    }, [data, prefix]);

    return { loginUserPreferences, error, isLoading };
};

export default useLoginUserPreferences;
