import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { DownArrowIcon } from "~/core/icons";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../../i18n-messages";
import { getErrorMessages } from "~/i18n-error-messages";
import "./validation-error-modal.css";

const _parseApiErrorResult = (formatMessage, apiErrorResultList) => {
    return apiErrorResultList.map((errResult) => {
        return {
            context: errResult.model,
            childrenVisible: true,
            errors: errResult.errorCodeList.map(({ errorCode, errorContext }) => {
                return getErrorMessages(formatMessage, {
                    errorCodeList: [errorCode],
                    errorContext,
                });
            }),
        };
    });
};

export class ValidationErrorModal_ extends Component {
    static propTypes = {
        apiErrorResultList: PropTypes.array.isRequired,
        setApiErrorResultList: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            validationErrorList: _parseApiErrorResult(
                props.intl.formatMessage,
                props.apiErrorResultList
            ),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.apiErrorResultList) !==
            JSON.stringify(this.props.apiErrorResultList)
        ) {
            this.setState({
                validationErrorList: _parseApiErrorResult(
                    this.props.intl.formatMessage,
                    nextProps.apiErrorResultList
                ),
            });
        }
    }

    toggleError = (index) => {
        const newError = {
            ...this.state.validationErrorList[index],
            childrenVisible: !this.state.validationErrorList[index].childrenVisible,
        };
        this.setState({
            validationErrorList: [
                ...this.state.validationErrorList.slice(0, index),
                newError,
                ...this.state.validationErrorList.slice(index + 1),
            ],
        });
    };

    getExpandCollapseButton = (index, isExpanded) => {
        const className = classNames(
            isExpanded ? "collapse-button-rotate-45" : "collapse-button-rotate-90",
            "expand-collapse-icon"
        );
        return (
            <div className="nested-grid-expand-button" onClick={() => this.toggleError(index)}>
                <DownArrowIcon className={className} />
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <DialogBox
                className={classNames("import-validation-modal")}
                isOpen={true}
                isModal={true}
                draggable={true}
                title={formatMessage(messages.validationFailuresText)}
                footerType={DialogBoxFooterType.NONE}
                onClose={() => {
                    this.props.setApiErrorResultList([]);
                }}
            >
                {this.state.validationErrorList.map((err, errIndex) => {
                    return (
                        <div key={`"errList-"${errIndex}`} className="error-result">
                            {this.getExpandCollapseButton(errIndex, err.childrenVisible)}
                            <div
                                className="error-context"
                                onClick={() => this.toggleError(errIndex)}
                            >
                                {err.context}
                            </div>
                            {!err.childrenVisible ? null : (
                                <ul className="error-list">
                                    {err.errors.map((err, innerIndex) => {
                                        return <li key={"err-" + innerIndex}>{err}</li>;
                                    })}
                                </ul>
                            )}
                        </div>
                    );
                })}
            </DialogBox>
        );
    }
}
export const ValidationErrorModal = injectIntl(ValidationErrorModal_);
