import { createReducer } from "redux-act";
import {
    fetchAgvanceCustomerSuccess,
    fetchCustomerSuccess,
    removeCustomerRecord,
    createCustomerSuccess,
    updateCustomerSuccess,
    updateEnrollmentFormSuccess,
    fetchCustomerEquipmentListSuccess,
    fetchCustomerExistsSuccess,
    fetchOwnerCommunityDataSuccess,
    getAgvanceCustomerSuccess,
    fetchSalespersonListSuccess,
    clearAgvanceCustomerList,
    setOracleCustomer,
    setAgvanceCustomerListOutOfBound,
    setCustomerSearchIsLoading,
    setAdditionalAgvanceCustomerFlag,
} from "./actions";

export const CUSTOMER_DATA_KEY = "CUSTOMER_DATA";
const initialState = {
    agvanceCustomers: [],
    communityDataDefault: false,
    customerNameExists: false,
    customerRecord: {},
    equipmentList: [],
    newPrimaryCustomer: null,
    salespersonList: [],
    oracleCustomer: null,
    additionalAgvanceCustomerList: [],
};

export const customerReducer = createReducer(
    {
        [fetchCustomerExistsSuccess]: (state, payload) => ({
            ...state,
            customerNameExists: payload,
        }),
        [fetchCustomerSuccess]: (state, customerRecord) => ({
            ...state,
            customerRecord,
        }),
        [setAdditionalAgvanceCustomerFlag]: (state, payload) => {
            return { ...state, isAdditionalAgvanceCustomerSearch: payload };
        },
        [fetchAgvanceCustomerSuccess]: (
            state,
            { agvanceCustomers, isAdditionalAgvanceCustomer }
        ) => {
            return isAdditionalAgvanceCustomer
                ? { ...state, additionalAgvanceCustomerList: agvanceCustomers }
                : { ...state, agvanceCustomers };
        },
        [fetchCustomerEquipmentListSuccess]: (state, equipmentList) => ({
            ...state,
            equipmentList,
        }),
        [fetchOwnerCommunityDataSuccess]: (state, communityDataDefault) => ({
            ...state,
            communityDataDefault,
        }),
        [removeCustomerRecord]: (state) => ({
            ...state,
            customerRecord: {},
            equipmentList: [],
        }),
        [createCustomerSuccess]: (state) => ({
            ...state,
        }),

        [updateCustomerSuccess]: (state) => ({
            ...state,
        }),
        [updateEnrollmentFormSuccess]: (state, { customerRecord }) => ({
            ...state,
            customerRecord,
        }),
        [getAgvanceCustomerSuccess]: (state, payload) => ({
            ...state,
            newPrimaryCustomer: payload,
        }),
        [fetchSalespersonListSuccess]: (state, salespersonList) => ({
            ...state,
            salespersonList,
        }),
        [clearAgvanceCustomerList]: (state) => ({
            ...state,
            agvanceCustomers: [],
        }),
        [setOracleCustomer]: (state, payload) => ({
            ...state,
            oracleCustomer: payload,
        }),
        [setAgvanceCustomerListOutOfBound]: (state, payload) => ({
            ...state,
            isAgvanceCustomerListOutOfBound: payload.flag,
            agvanceCustomerListCount: payload.count,
        }),
        [setCustomerSearchIsLoading]: (state, payload) => ({
            ...state,
            customerSearchIsLoading: payload,
        }),
    },
    initialState
);
