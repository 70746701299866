import { service, dropdowns, defaultFileTypes } from "./service";
import { actions as defaultActions } from "./controller";
import * as customActions from "./actions";
import * as model from "./model";
import * as constants from "./constants";
import * as selectors from "./selectors";
import templateManagerSagas from "./sagas";

const actions = {
    ...defaultActions,
    ...customActions,
};

export {
    actions,
    constants,
    defaultFileTypes,
    dropdowns,
    model,
    selectors,
    service,
    templateManagerSagas,
};
