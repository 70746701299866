import type { SetupReportBooksAPI } from "@ai360/core";
import * as model from "~/admin/setup/report-books/data/model";

export enum MergeStatus {
    failed,
    pending,
    processing,
    success,
}

export interface IAutoSearchData {
    searchName: string;
    response: IAutoSearchResponse[];
}

export interface IAutoSearchResponse {
    value: string;
}

export interface IDataTableReport {
    [model.PROPS_CAN_DELETE]: boolean;
    [model.PROPS_ADD_EDIT_TABLE_UP_BUTTON]: JSX.Element;
    [model.PROPS_ADD_EDIT_TABLE_DOWN_BUTTON]: JSX.Element;
    [model.PROPS_REPORT]: string;
    [model.PROPS_CONFIGURATION]: string;
    [model.PROPS_ADD_EDIT_TABLE_DATA]: IReport;
}

export interface IMergeReportBook {
    data: IMergeReportBookData;
}

export interface IMergeReportOptions {
    data: IMergeReportOptionsData;
}

export interface IMergeReportBookData extends IReportBookInfo {
    reportBookTypeGuid: string;
    orgLevelGuids: string[];
    reports: IReport[];
}

export interface IMergeReportOptionsData {
    reportBookOptionsGuid: string;
    [model.PROPS_NAME]: string;
    options: string;
    orgLevelGuids: string[];
    reportTypeGuid: string;
}

export interface IReport {
    reportBookOptionsGuid: string;
    sortOrder: number;
}

export interface IReportBook {
    [model.PROPS_CAN_DELETE]: boolean;
    [model.PROPS_REPORT_BOOK_NAME]: string;
    [model.PROPS_ACTIVE_YN]: boolean;
    [model.PROPS_SETUP_REPORT_BOOKS_TABLE_DATA]: SetupReportBooksAPI.IReportBookTypeRecord;
}

export interface IReportBookInfo {
    [model.PROPS_ACTIVE_YN]: boolean;
    [model.PROPS_NAME]: string;
    [model.PROPS_COVER_PAGE]: boolean;
    [model.PROPS_COVER_PAGE_URL]: string;
    [model.PROPS_MEMO]: string;
}

export interface IReportOptions extends SetupReportBooksAPI.IReportOptionsRecord {
    label: string;
    value: string;
}

export interface IReportOptionsTableData {
    [model.PROPS_CAN_DELETE]: boolean;
    [model.PROPS_REPORT]: string;
    [model.PROPS_CONFIGURATION]: string;
    [model.PROPS_CREATED_BY]: string;
    [model.PROPS_MODIFIED_BY]: string;
    [model.PROPS_MODIFIED_BY_DATE]: string;
    [model.PROPS_ACTIVE_YN]: boolean;
    [model.PROPS_REPORT_OPTIONS_TABLE_DATA]: SetupReportBooksAPI.IReportOptionsRecord;
}

export interface IReportType extends SetupReportBooksAPI.IReportTypeRecord {
    label: string;
    value: string;
}

export interface ISoilTypeAttribute extends SetupReportBooksAPI.ISoilTypeAttributeRecord {
    label: string;
    value: string;
}

export interface IAction {
    type: string;
    reportBookTypeGuid?: string;
    reportBookOptionGuid?: string;
    includeInactive?: boolean;
    reportBookOptionsGuid?: string;
    data?: IMergeReportBook | IMergeReportOptions;
    filteredLocationList?: string[];
    loadingModal?: boolean;
    loadingOrgLevelGuids?: boolean;
    loadingReportBookReports?: boolean;
    loadingReportBooks?: boolean;
    mergeStatus?: MergeStatus;
    reportBookOptionErrors?: string[];
    reportBookOrgLevelGuids?: string[];
    reportBookReports?: SetupReportBooksAPI.IReportRecord[];
    reportBookNames?: SetupReportBooksAPI.IReportBookNameResponse[];
    reportBooks?: SetupReportBooksAPI.IReportBookTypeRecord[];
    reportTypes?: SetupReportBooksAPI.IReportTypeRecord[];
    reportOptions?: SetupReportBooksAPI.IReportOptionsRecord[];
    soilTypeAttributes?: SetupReportBooksAPI.ISoilTypeAttributeRecord[];
    selectedReportBookTypeGuid?: string;
    selectedReportOptionGuid?: string;
}
