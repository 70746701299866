import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, intlShape, defineMessages } from "react-intl";

import { Loader, RadioButton, RadioButtonGroup } from "~/core";
import { getTheUserGuid } from "~/login";

import { eventsSelectors } from "~/recs-events";

import { LayerUtilsAPI } from "@ai360/core";

import * as actions from "../../actions";
import * as selectors from "../../selectors";
import { ContextMenu } from "./ec-data-item-context-menu";

import * as layerSelectors from "../../../../../layer-module/components/layer-list/selectors";
import * as layerActions from "../../../../../layer-module/components/layer-list/actions";
import { SurfaceProperties } from "../../../../../layer-module/components/layer-list/layer-accordion/dialog-boxes/surface-properties";
import { messages as layerMessages } from "../../../../../layer-module/components/i18n-messages";

import { ECDataIcon } from "../icons/ecData";
import "./event-ec-data.css";

export const messages = defineMessages({
    ecDataFormLabelText: {
        id: "eventModule.eventInfo.ecDataFormLabelText",
        defaultMessage: "EC Data",
    },
    ecDataEvent: {
        id: "ecDataEvent",
        defaultMessage: "EC Data Event",
    },
});

export const formLabelMessage = messages.ecDataFormLabelText;
export const formLabelIcon = ECDataIcon;

class ECDataEventForm_ extends Component {
    static propTypes = {
        agEventModel: PropTypes.object.isRequired,
        eventDetails: PropTypes.object.isRequired,
        fieldGuid: PropTypes.string.isRequired,
        intl: intlShape.isRequired,
        isLoading: PropTypes.bool.isRequired,
        layerInfos: PropTypes.array.isRequired,
        loadingSurfacesSet: PropTypes.object.isRequired, //set
        onChangeSurfaceLayerProps: PropTypes.func.isRequired,
        removeSurface: PropTypes.func.isRequired,
        setDataLoading: PropTypes.func.isRequired,
        showSurface: PropTypes.func.isRequired,
        userGuid: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isEditData: false,
            isSelected: true,
        };
    }

    _getSelectedSublayer(ecDataLayer) {
        if (!ecDataLayer || !ecDataLayer.subLayers) {
            return null;
        }
        return ecDataLayer.subLayers.find((layer) => {
            return (
                layer.importAttributeGuid === ecDataLayer.selectedAttributeGuid &&
                layer.surfaceTypeGuid === ecDataLayer.selectedSurfaceTypeGuid
            );
        });
    }

    _getClassBreakData(layer) {
        if (!layer) {
            return null;
        }
        const { classBreaks } = layer;
        const classBreakRows = classBreaks.map((classBreak) => {
            const { classId, color, displayName, acreage } = classBreak;
            return (
                <div key={classId} className="break-row">
                    <div className="symbolBox" style={{ backgroundColor: `#${color.hexCode}` }} />
                    {`${displayName} (${acreage} acres)`}
                </div>
            );
        });
        return <div className="ec-data-layer-class-breaks">{classBreakRows}</div>;
    }

    _onCloseECDataEdit() {
        this.setState({ isEditData: false });
    }

    _onSaveECDataEdit({
        attributeGuid,
        classificationMethodGuid,
        colorRampGuid,
        numberOfClasses,
        surfaceTypeGuid,
    }) {
        this._onCloseECDataEdit();
        const { eventDetails, fieldGuid, layerInfos, onChangeSurfaceLayerProps, setDataLoading } =
            this.props;
        const ecDataLayer = layerInfos.find((layer) => {
            return layer.agEventGeneralGuid === eventDetails.agEventGeneralGuid;
        });
        const selectedDataLayer = this._getSelectedSublayer(ecDataLayer);
        setDataLoading(true);
        onChangeSurfaceLayerProps({
            itemDimIdx: -1,
            fieldGuid,
            layer: ecDataLayer,
            surface: selectedDataLayer,
            attributeGuid,
            classificationMethodGuid,
            colorRampGuid,
            numberOfClasses,
            surfaceTypeGuid,
        });
    }

    _onSelectECData = () => {
        const { fieldGuid, showSurface, layerInfos, eventDetails } = this.props;
        const ecDataLayer = layerInfos.find((layer) => {
            return layer.agEventGeneralGuid === eventDetails.agEventGeneralGuid;
        });
        const selectedDataLayer = this._getSelectedSublayer(ecDataLayer);
        const surfaceInfo = LayerUtilsAPI.getSlimSurfaceInfo(selectedDataLayer);
        showSurface(fieldGuid, surfaceInfo);
    };

    _setEditData() {
        this.setState({ isEditData: true });
    }

    _toggleRadioButton = () => {
        const { fieldGuid, removeSurface } = this.props;
        const isSelected = !this.state.isSelected;
        this.setState({ isSelected }, () => {
            if (!isSelected) {
                removeSurface(fieldGuid);
            } else {
                this._onSelectECData();
            }
        });
    };

    componentDidMount() {
        this._onSelectECData();
    }

    render() {
        const { layerInfos, eventDetails, fieldGuid, isLoading, loadingSurfacesSet } = this.props;
        const { formatMessage } = this.props.intl;
        const { isEditData, isSelected } = this.state;

        const ecDataLayer = layerInfos.find((layer) => {
            return layer.agEventGeneralGuid === eventDetails.agEventGeneralGuid;
        });
        const selectedDataLayer = this._getSelectedSublayer(ecDataLayer);

        const ecDataLoading = loadingSurfacesSet.has(selectedDataLayer.surfaceGuid) || isLoading;
        const classBreakData = this._getClassBreakData(selectedDataLayer);

        const type = LayerUtilsAPI.getLayerType(ecDataLayer);
        const letterIcon = formatMessage(layerMessages.layerTypeEvent);

        const displayLabel = `${selectedDataLayer.displayName} - ${selectedDataLayer.surfaceTypeDisplayName}`;

        return (
            <div className="ec-data-event-container">
                {!selectedDataLayer ? null : (
                    <div className="ec-data-layer">
                        <div className="ec-data-layer-controls">
                            <RadioButtonGroup
                                className={"ec-data-radio-btn-grp"}
                                selectedValue={isSelected}
                                afterOnChange={this._toggleRadioButton}
                            >
                                <RadioButton value={isSelected} label={displayLabel}></RadioButton>
                            </RadioButtonGroup>
                            <ContextMenu
                                className={"ec-data-context-menu"}
                                fieldGuid={fieldGuid}
                                showEcDataInfo={() => this._setEditData()}
                            />
                        </div>
                        {isSelected ? classBreakData : null}
                    </div>
                )}
                <SurfaceProperties
                    key="ecDataSurface"
                    isOpen={isEditData}
                    layer={ecDataLayer}
                    letterIcon={letterIcon}
                    onSave={(evt) => this._onSaveECDataEdit(evt)}
                    onClose={() => this._onCloseECDataEdit()}
                    surface={selectedDataLayer}
                    type={type}
                />
                {ecDataLoading ? <Loader /> : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onChangeSurfaceLayerProps: (payload) => dispatch(layerActions.changeSurfaceLayerProps(payload)),
    removeSurface: (fieldGuid) => dispatch(layerActions.removeVisibleSurfaces([fieldGuid])),
    showSurface: (fieldGuid, surface) =>
        dispatch(layerActions.setVisibleSurface(fieldGuid, surface)),
    setDataLoading: (isLoading) => dispatch(actions.setECDataLoading(isLoading)),
});

const mapStateToProps = (state, ownProps) => ({
    layerInfos: layerSelectors.getLayerInfos(state).get(ownProps.fieldGuid),
    eventDetails: eventsSelectors
        .getModuleState(state)
        .fieldGuidToEventDetails.get(ownProps.fieldGuid),
    isLoading: selectors.getECDataLoading(state),
    loadingSurfacesSet: layerSelectors.getLoadingSurfacesSet(state),
    userGuid: getTheUserGuid(state),
});

export const ECDataEventForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ECDataEventForm_));
