import { connect } from "react-redux";
import NozzlesListView from "./nozzles-list";
import { getPicklist, getUnit } from "~/admin/selectors";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";

const mapStateToProps = (state) => ({
    [picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_APPLICATION_NOZZLE_TYPE),
        state
    ),
    [unitService.UNIT_LENGTH]: getUnit(unitService.getUnitCode(unitService.UNIT_LENGTH), state),
});

export const NozzlesList = connect(mapStateToProps, null)(NozzlesListView);
