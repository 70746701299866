import { put, select } from "redux-saga/effects";
import * as constants from "../constants";
import * as actions from "../actions";
import { service } from "../service";
import { getModuleState, getFileMetaData } from "../selectors";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import * as model from "../model";

function getSampleAttributeType(importType) {
    let currentSampleType = "";
    switch (importType) {
        case constants.TEMPLATE_TYPE_APPLICATION:
            currentSampleType = constants.APPLICATION_SAMPLE_ATTRIBUTES;
            break;
        case constants.TEMPLATE_TYPE_HARVEST:
            currentSampleType = constants.HARVEST_SAMPLE_ATTRIBUTES;
            break;
        case constants.TEMPLATE_TYPE_PLANTING:
            currentSampleType = constants.PLANTING_SAMPLE_ATTRIBUTES;
            break;
        default:
    }
    return currentSampleType;
}

function getMonitorType(templateName) {
    return templateName
        ? templateName.substring(templateName.lastIndexOf("_") + 1, templateName.length)
        : "";
}

export function* processEditImportTemplateResponse(response) {
    let processedResponse = [];
    for (let index = 0; index < response.length; index++) {
        const { store } = yield select(getModuleState);
        const { importTemplateList } = store;
        if (importTemplateList.length > 0) {
            const type = getMonitorType(importTemplateList[index].templateName);
            const {
                standardColumnHeaders,
                standardGridData,
                importTemplateFileGuid,
                importTypeGuid,
            } = response[index];
            const sampleAttributeType = getSampleAttributeType(type);
            yield put(
                fetchDropdownData({
                    [sampleAttributeType]: {
                        url: service.urls.GET_SAMPLE_ATTRIBUTES_URL,
                        model: importTypeGuid,
                    },
                })
            );
            processedResponse.push({
                label: type,
                standardColumnHeaders,
                standardGridData,
                importTemplateFileGuid,
                importTypeGuid,
            });
        }
    }
    yield put(actions.setTemplateGridData(processedResponse));
}

export function* processCreateImportTemplateResponse(response, fileName, typesArray) {
    if (typesArray.length > 0) {
        let processedResponse = [];
        const updatedImportTemplateArray = [];
        const importTemplate = yield select(
            (state) => getFileMetaData(state).importTemplateList[0]
        );
        yield put(
            actions.updateTemplate({
                key: [model.PROPS_IMPORT_TEMPLATE_LIST],
                value: updatedImportTemplateArray,
            })
        );
        for (let index = 0; index < response.length; index++) {
            const type = typesArray[index];
            const {
                standardColumnHeaders,
                standardGridData,
                importTemplateFileGuid,
                importTypeGuid,
                delimiter,
            } = response[index];
            yield put(
                actions.updateTemplate({
                    key: [model.PROPS_TEMPLATE_NAME],
                    value: fileName,
                })
            );
            const sampleAttributeType = getSampleAttributeType(type);
            yield put(
                fetchDropdownData({
                    [sampleAttributeType]: {
                        url: service.urls.GET_SAMPLE_ATTRIBUTES_URL,
                        model: importTypeGuid,
                    },
                })
            );
            processedResponse.push({
                label: type,
                standardColumnHeaders,
                standardGridData,
                importTemplateFileGuid,
                importTypeGuid,
            });
            const newImportTemplateList = model.ImportTemplateFactory.createImportTemplateList(
                importTemplate,
                {
                    ...importTemplate,
                    templateName: `${fileName}_${type}`,
                    importTemplateFileGuid,
                    importTypeGuid,
                    delimiter,
                }
            );
            updatedImportTemplateArray.push(newImportTemplateList);
        }
        yield put(
            actions.updateTemplate({
                key: [model.PROPS_IMPORT_TEMPLATE_LIST],
                value: updatedImportTemplateArray,
            })
        );
        yield put(actions.setTemplateGridData(processedResponse));
    }
}

// Add extra file metadata to be fetched from one of the import types
export function getParsedFileMetadata(importTemplateData = null) {
    if (importTemplateData) {
        const { fileTypes, delimiter } = importTemplateData[0];
        return { fileTypes, delimiter };
    }
    return {};
}
