import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Checkbox, SelectInput } from "~/core";
import EntitySearch from "./entity-search";
import { adminData } from "~/admin/data";
import { messages } from "../../i18n-messages";

class Entities extends Component {
    static propTypes = {
        entities: PropTypes.array,
        selectedCompany: PropTypes.object,
        filteredData: PropTypes.array,
        formatMessage: PropTypes.func,
        getEntities: PropTypes.func,
        getEntityDropdownValue: PropTypes.func,
        isEntityTab: PropTypes.bool,
        updateEntities: PropTypes.func,
        onPermissionChange: PropTypes.func,
        onUseOnlyChange: PropTypes.func,
        selectedAttribute: PropTypes.object,
        useOnly: PropTypes.object,
        useOnlySupportedAttributes: PropTypes.func,
        placeholderText: PropTypes.string,
        labelKey: PropTypes.string,
        guidKey: PropTypes.string,
        entityType: PropTypes.string,
        needs: PropTypes.func,
        setCurrentEntities: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            entities: [],
            resetEntities: false,
            selectedEntity: "",
        };
    }

    componentDidMount() {
        const { selectedAttribute, selectedCompany } = this.props;
        this.getEntities(selectedAttribute, selectedCompany);
    }

    shouldComponentUpdate(nextProps) {
        if (
            nextProps.filteredData === this.props.filteredData &&
            nextProps.entities === this.props.entities
        ) {
            return false;
        }
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.selectedAttribute !== nextProps.selectedAttribute ||
            this.props.selectedCompany !== nextProps.selectedCompany
        ) {
            this.getEntities(nextProps.selectedAttribute, nextProps.selectedCompany);
        }
        if (nextProps.entities !== this.props.entities) {
            this.setState({
                entities: this.mergeEntities(
                    nextProps.entities,
                    nextProps.entities.filter((entity) => entity.isAssigned)
                ),
            });
            this.props.setCurrentEntities(nextProps.entities);
        }

        if (nextProps.filteredData !== this.props.filteredData) {
            this.setState({
                filteredData: nextProps.filteredData,
            });
        }
    }

    getEntities = (selectedAttribute, selectedCompany = {}) => {
        const { guid, id } = selectedAttribute;
        const { needs, getEntities, isEntityTab } = this.props;
        needs([
            getEntities({
                attributeGuid: guid,
                attributeType: id,
                isDropdown: isEntityTab,
                searchValue: "",
                selectionGuid: "",
                UserGuid: selectedCompany.userGuid,
                CompanyGuid: selectedCompany.companyGuid,
            }),
        ]);
    };

    onItemSelection = (data) => {
        const { guid, id } = this.props.selectedAttribute;
        const { labelKey, guidKey, needs, selectedCompany } = this.props;
        needs([
            this.props.getEntities({
                attributeGuid: guid,
                attributeType: id,
                searchValue: data[labelKey],
                selectionGuid: data[guidKey],
                userGuid: selectedCompany.userGuid,
                companyGuid: selectedCompany.companyGuid,
            }),
        ]);
    };

    _selectedEntity(selected) {
        if (selected == null) {
            return;
        }

        this.setState({ dropdownSelectedEntity: selected });
        this.props.getEntityDropdownValue(selected);
    }

    mergeEntities(filteredEntities, selectedEntities) {
        return _.sortedUniq(_.union(filteredEntities, selectedEntities));
    }

    onPermissionChange = (data, value) => {
        if (data && this.props.onPermissionChange) {
            this.props.onPermissionChange(this.props.entityType, {
                ...data,
                isAssigned: value,
            });
        }
    };

    render() {
        const { entities } = this.state;
        const { isEntityTab, formatMessage } = this.props;
        let list;
        let correctMessage;

        if (isEntityTab) {
            list = entities.map((values) => {
                return {
                    value: values.entityGuid,
                    label: values.entityName,
                    activeYN: values.activeYN,
                };
            });
        }

        if (this.props.entityType === "users") {
            correctMessage = messages.user;
        } else if (this.props.entityType === "userRoles") {
            correctMessage = messages.userRole;
        }

        return isEntityTab ? (
            <div className="select-company-container">
                <SelectInput
                    required
                    clearable={false}
                    autofocus
                    openOnFocus={false}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    onChange={(value) => {
                        this._selectedEntity(value);
                    }}
                    placeholderText={formatMessage(correctMessage)}
                    value={this.state.dropdownSelectedEntity}
                    options={list}
                />
                {this.props.useOnlySupportedAttributes.has(
                    this.props.selectedAttribute.categoryIndex
                ) ? (
                    <div className="batch-zapper-use-only">
                        <Checkbox
                            onChange={(e, value) => this.props.onUseOnlyChange(value)}
                            value={this.props.useOnly || this.props.selectedAttribute.useOnly}
                            label={formatMessage(messages.useOnly)}
                            disabled={this.props.selectedAttribute.useOnly}
                        />
                    </div>
                ) : null}
            </div>
        ) : (
            <div>
                <EntitySearch
                    onItemSelection={this.onItemSelection}
                    filteredData={this.state.filteredData}
                    {...this.props}
                />
                <div className="batch-zapper-entities">
                    {entities.map((userEntity, index) => (
                        <div key={index} className="entity-container">
                            <Checkbox
                                onChange={(e, value) => this.onPermissionChange(userEntity, value)}
                                value={userEntity.isAssigned || false}
                            />
                            <span title={userEntity.entityName}>{userEntity.entityName}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Entities;
