import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { OrgLevelSearch } from "~/admin/agBytes/components/org-level-search";
import { Section, SubSection } from "~/core";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { AppHelpers, HierarchyAPI } from "@ai360/core";

import _ from "lodash";
import { getDropdowns } from "./../../selectors";

const PROPS_ORG_LEVEL_PARENT = "orgLevelParents";
const DELIMITER = " > ";

export class HierarchyFilter extends Component {
    static propTypes = {
        selectedCompanyUserGuid: PropTypes.string,
        fetchDropdownData: PropTypes.func,
        needs: PropTypes.func,
        onFilterChange: PropTypes.func,
        placeholderText: PropTypes.string,
    };

    static ORG_LEVEL = "orgLevel";

    constructor(props) {
        super(props);
        this.state = {
            [HierarchyFilter.ORG_LEVEL]: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(nextProps.selectedCompanyUserGuid, this.props.selectedCompanyUserGuid)) {
            this.fetchDropdownData(nextProps.selectedCompanyUserGuid);
        }

        if (
            !_.isEqual(nextProps[HierarchyFilter.ORG_LEVEL], this.state[HierarchyFilter.ORG_LEVEL])
        ) {
            this.setState({
                [HierarchyFilter.ORG_LEVEL]: AppHelpers.parseOrgLevelList(
                    nextProps[HierarchyFilter.ORG_LEVEL],
                    PROPS_ORG_LEVEL_PARENT,
                    DELIMITER
                ),
            });
        }
    }

    componentDidMount() {
        this.fetchDropdownData(this.props.selectedCompanyUserGuid);
    }

    fetchDropdownData(selectedCompanyUserGuid) {
        let model = "_";
        if (selectedCompanyUserGuid) {
            model = selectedCompanyUserGuid;
        }
        this.props.needs([
            this.props.fetchDropdownData({
                [HierarchyFilter.ORG_LEVEL]: {
                    url: HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS,
                    model: model,
                },
            }),
        ]);
    }

    render() {
        return (
            <Section className="hierarchy-filter-container">
                <SubSection>
                    <OrgLevelSearch
                        itemList={this.state[HierarchyFilter.ORG_LEVEL]}
                        onSelection={this.props.onFilterChange}
                        placeholderText={this.props.placeholderText}
                    />
                </SubSection>
            </Section>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchDropdownData: (payload) => dispatch(fetchDropdownData(payload)),
});

const mapStateToProps = (state) => ({
    ...getDropdowns([HierarchyFilter.ORG_LEVEL], state),
});

export default connect(mapStateToProps, mapDispatchToProps)(HierarchyFilter);
