import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { Button, DialogBox, Loader, SelectInput } from "~/core";

import { getTheUserGuid } from "~/login/selectors";
import { FileImportAPI } from "@ai360/core";

import * as fileImportModels from "~/file-import/model";
import { actions as notificationActions } from "~/notifications";

import { DragAndDropFileUploader } from "~/action-panel/components/common/drag-and-drop-file-uploader";
import {
    actions as uploaderActions,
    selectors as uploaderSelectors,
} from "~/action-panel/components/common/drag-and-drop-file-uploader";

import { messages } from "./i18n-messages";
import { ACTIVE_YN } from "~/core/picklist";

import "./upload-modal.css";

export const UPLOAD_MODAL_STATE_KEY = "UPLOAD_MODAL";

export class UploadModal_ extends Component {
    static propTypes = {
        errorCount: PropTypes.number,
        getDataTypeOptions: PropTypes.func.isRequired,
        getTemplateOptions: PropTypes.func.isRequired,
        importFileCount: PropTypes.number,
        intl: intlShape.isRequired,
        isPreparingUpload: PropTypes.bool,
        onApiError: PropTypes.func.isRequired,
        onClearFileList: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onSetSelectedImportType: PropTypes.func.isRequired,
        onSetSelectedTemplate: PropTypes.func.isRequired,
        onUpload: PropTypes.func.isRequired,
        selectedImportType: PropTypes.object,
        selectedTemplate: PropTypes.object,
        validExtensions: PropTypes.string,
    };

    _modalBody = null;

    constructor(props) {
        super(props);
        this.state = {
            dataTypeOptions: undefined,
            templateOptions: undefined,
            resizeStartPos: null,
            width: 800,
            height: 375,
            posX: null,
            posY: null,
            isLoading: false,
            dragAndDropNode: null,
        };
        this._updateDataTypeOptions();
    }

    componentDidMount() {
        this.setState({ dragAndDropNode: this._modalBody.closest(".dialog-container") });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectedImportType !== this.props.selectedImportType) {
            this._updateTemplateOptions(nextProps.selectedImportType);
        }
    }

    _updateDataTypeOptions() {
        const { getDataTypeOptions, onApiError, selectedImportType } = this.props;

        getDataTypeOptions()
            .then((dataTypeOptions) => {
                this.setState({ dataTypeOptions }, () => {
                    if (selectedImportType) {
                        this._updateTemplateOptions(selectedImportType);
                    }
                });
            })
            .catch((err) => onApiError(err));
    }

    _updateTemplateOptions(selectedImportType) {
        const { getTemplateOptions, onApiError } = this.props;
        this.setState({ templateOptions: undefined }, () => {
            getTemplateOptions(selectedImportType.guid)
                .then((templateOptions) => {
                    this.setState({ templateOptions });
                })
                .catch((err) => onApiError(err));
        });
    }

    _getFileTypesElement() {
        const { selectedTemplate } = this.props;
        const { formatMessage } = this.props.intl;

        return !selectedTemplate ? null : (
            <div className="file-types">
                <div>
                    {formatMessage(messages.supportedFileTypes, {
                        typesStr: this.props.validExtensions,
                    })}
                </div>
            </div>
        );
    }

    _onClose() {
        this.props.onClearFileList();
        this.props.onClose();
    }

    _resizeStart(e) {
        const MIN_WIDTH = 550;
        const MIN_HEIGHT = 275;

        const _resize = (e) => {
            const [startx, starty, startWidth, startHeight, posX, posY] = this.state.resizeStartPos;

            const [newx, newy] = [e.clientX, e.clientY];

            const xDiff = newx - startx;
            const yDiff = newy - starty;

            this.setState({
                width: Math.max(startWidth + xDiff, MIN_WIDTH),
                height: Math.max(startHeight + yDiff, MIN_HEIGHT),
                posX: posX,
                posY: posY,
            });
        };

        const _resizeEnd = () => {
            window.removeEventListener("mouseup", _resizeEnd, false);
            window.removeEventListener("mousemove", _resize, false);
            this.setState({ posX: null, posY: null });
        };

        const { width, height } = this.state;
        const dialogBounds = this._dialogNode.getBoundingClientRect();
        const resizeStartPos = [
            e.clientX,
            e.clientY,
            width,
            height,
            dialogBounds.left,
            dialogBounds.top,
        ];

        this.setState({ resizeStartPos }, () => {
            window.addEventListener("mouseup", _resizeEnd, false);
            window.addEventListener("mousemove", _resize, false);
        });
    }

    _onUpload() {
        const { onUpload, errorCount } = this.props;
        const { errDialogDismissed } = this.state;
        if (errorCount > 0 && !errDialogDismissed) {
            this.setState({ errDialogVisible: true });
            return;
        }

        onUpload();
        this._onClose();
    }

    render() {
        const {
            errorCount,
            importFileCount,
            isPreparingUpload,
            onSetSelectedImportType,
            onSetSelectedTemplate,
            selectedImportType,
            selectedTemplate,
        } = this.props;

        const { formatMessage } = this.props.intl;

        const { errDialogVisible } = this.state;

        if (errDialogVisible) {
            return (
                <DialogBox
                    closeOnClickOff
                    draggable
                    isOpen
                    title={formatMessage(messages.nonImportFilesErrDialogTitle)}
                    onClose={() =>
                        this.setState({ errDialogDismissed: true }, () => this._onUpload())
                    }
                >
                    {formatMessage(messages.nonImportFilesErrDialogMsg, {
                        errorCount,
                    })}
                </DialogBox>
            );
        }

        const { dataTypeOptions, templateOptions, width, height, posX, posY, isLoading } =
            this.state;

        const dialogProps = {
            isOpen: true,
            hideCloseX: true,
            draggable: true,
            unrestricted: true,
            title: formatMessage(messages.uploadModalTitle),
            className: "upload-modal",
            posX,
            posY,
        };

        const sizeStyle = { width, height };

        return (
            <DialogBox {...dialogProps}>
                <div
                    className="upload-modal-body"
                    style={sizeStyle}
                    ref={(d) => (this._modalBody = d)}
                >
                    {!isPreparingUpload && !isLoading ? null : <Loader />}
                    <div className="type-template-select">
                        <SelectInput
                            placeholderText={formatMessage(messages.importTypePlaceholderText)}
                            optionIsHiddenKey={ACTIVE_YN}
                            required
                            clearable={false}
                            disabled={dataTypeOptions == null}
                            options={dataTypeOptions || []}
                            onChange={(dataType) => onSetSelectedImportType(dataType)}
                            value={selectedImportType}
                        />
                        <SelectInput
                            placeholderText={formatMessage(messages.importTemplatePlaceholderText)}
                            optionIsHiddenKey={ACTIVE_YN}
                            required
                            clearable={false}
                            disabled={templateOptions == null}
                            options={templateOptions || []}
                            onChange={(template) => onSetSelectedTemplate(template)}
                            value={selectedTemplate}
                        />
                    </div>
                    {selectedTemplate && (
                        <DragAndDropFileUploader
                            stateKey={UPLOAD_MODAL_STATE_KEY}
                            dragAndDropNode={this.state.dragAndDropNode}
                        />
                    )}
                </div>
                <div className="dialog-box-footer">
                    {this._getFileTypesElement()}
                    <Button
                        disabled={importFileCount === 0 || errorCount > 0}
                        value={formatMessage(messages.uploadBtnText)}
                        onClick={() => this._onUpload()}
                    />
                    <Button key="cancel" type="cancel" onClick={() => this._onClose()} />
                    <div className="resize-handle" onMouseDown={(e) => this._resizeStart(e)} />
                </div>
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err) => dispatch(notificationActions.apiCallError(err)),
    onClearFileList: () => dispatch(uploaderActions.clearUploadFileList(UPLOAD_MODAL_STATE_KEY)),
    onSetSelectedImportType: (selectedImportType) => {
        dispatch(uploaderActions.setSelectedImportType(UPLOAD_MODAL_STATE_KEY, selectedImportType));
    },
    onSetSelectedTemplate: (selectedTemplate) => {
        dispatch(uploaderActions.setSelectedTemplate(UPLOAD_MODAL_STATE_KEY, selectedTemplate));
    },
    onUpload: () => dispatch(uploaderActions.processUploads(UPLOAD_MODAL_STATE_KEY)),
});

const mapStateToProps = (state) => {
    const userGuid = getTheUserGuid(state);
    const uploaderModuleState = uploaderSelectors.getModuleState(state, UPLOAD_MODAL_STATE_KEY);
    const {
        filesWithErrorsCount,
        importFileCount,
        isDuplicateFileError,
        isPreparingUpload,
        selectedImportType,
        selectedTemplate,
        validExtensions,
    } = uploaderModuleState;

    return {
        errorCount: filesWithErrorsCount,
        getDataTypeOptions: () =>
            FileImportAPI.getOrderedImportTypeList(userGuid).then((importTypes) =>
                importTypes.map((importType) => {
                    return { value: importType, label: importType.name };
                })
            ),
        getTemplateOptions: (typeGuid) =>
            FileImportAPI.getTemplateList(userGuid, typeGuid).then((templateObjList) =>
                templateObjList.map((templateObj) => {
                    const template = fileImportModels.ImportTemplate.fromJsonObj(templateObj);
                    return { value: template, label: template.name };
                })
            ),
        selectedImportType,
        selectedTemplate,
        importFileCount,
        isDuplicateFileError,
        isPreparingUpload,
        validExtensions: validExtensions.join(","),
    };
};

export const UploadModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadModal_));
