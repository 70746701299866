import { connect } from "react-redux";
import AddEditPanelContainer from "../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, service, dropdowns, unitTypes, actions } from "../data";
import { picklistNames as picklistService, selectors as picklistSelectors } from "~/core/picklist";
import { getAgBytesEquipmentData } from "../data/selectors";

const mapStateToProps = (state) => ({
    [picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]: picklistSelectors.getPicklistFromCode(
        state,
        picklistService.getPickListCode(picklistService.PICKLIST_EQUIPMENT_TYPE_NAME)
    ),
    [picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]: picklistSelectors.getPicklistFromCode(
        state,
        picklistService.getPickListCode(picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE)
    ),
    agBytesEquipmentData: getAgBytesEquipmentData(state),
});

const mapDispatchToProps = (dispatch) => ({
    // Note: This action will be dispatched using the needs hoc
    fetchAgbytesEquipmentData: actions.fetchAgbytesEquipmentData,
    clearAgbytesEquipmentData: () => dispatch(actions.clearAgbytesEquipmentData()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AddEditPanelContainer(AddEditPanel, {
        pickLists,
        dropdowns,
        unitTypes,
        service,
    })
);
