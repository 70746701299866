import * as actions from "./actions";

const newInitialState = () => ({
    navList: [],
});

export const navigationReducer = (state, action) => {
    if (state == null) {
        return newInitialState();
    }

    switch (action.type) {
        case actions.NAVLIST_FETCH_SUCCEEDED:
            return {
                ...state,
                navList: action.payload.navList,
            };
        default:
            return state;
    }
};
