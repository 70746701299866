import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";

import { messages } from "~/admin/agBytes/i18n-messages";
import CustomPropTypes from "~/utils/proptypes";

import { model, REQUEST_CROP_CLASS, REQUEST_GROWTH_STAGE_ORDER, service } from "../data";
import * as picklistService from "~/core/picklist/picklist-names";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { Checkbox, NumericInput, Section, SelectInput, SubSection } from "~/core";
import CropList from "~/admin/agBytes/components/crop-info-list";
import ImportAttributeList from "~/admin/agBytes/components/import-attribute-list";
import PlantPartList from "~/admin/agBytes/components/plant-part-list";
import { OrgLevelList, PROPS_STATE_ABBREVIATION } from "~/admin/agBytes/components/org-level-list";
//Styles
import "./add-edit-panel.css";

const WATER_SAMPLE_TYPE = "Water";
export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchGrowthStage: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.nutrientTargetValue,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.nutrientTarget = {};
        this.state = {
            [model.PROPS_GROWTH_STAGE_ORDER]: [],
            [model.PROPS_ORG_LEVEL]: [],
            nextId: null,
            recordIsUpdated: false,
            [model.PROPS_IS_WATER_SAMPLE_TYPE]: false,
        };
    }

    initializeDropdowns = (nextProps) => {
        this.initializeSampleType(nextProps);
        this.initializeCrops(nextProps);
        this.initializeCropPurpose(nextProps);
        this.initializeCropClassName(nextProps);
        this.initializeGrowthStageOrder(nextProps);
        this.initializeImportAttribute(nextProps);
        this.initializePlantPart(nextProps);
        this.initializeOrgLevel(nextProps);
        this.initializeIrrigationCategory(nextProps);
        this.initializeWaterPurpose(nextProps);
    };

    initializeCropClassName = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_CROP_LIST] != null &&
            nextProps[model.PROPS_CROP_CLASS_NAME]
        ) {
            this.setState({
                [model.PROPS_CROP_CLASS_NAME]: nextProps[model.PROPS_CROP_CLASS_NAME],
            });
        }
    };

    initializeCropPurpose = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_CROP_LIST] != null &&
            nextProps[picklistService.PICKLIST_CROP_PURPOSE]
        ) {
            this.setState({
                [picklistService.PICKLIST_CROP_PURPOSE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_CROP_PURPOSE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeCrops = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_CROP_LIST] != null &&
            nextProps[model.PROPS_CROP_NAME]
        ) {
            this.setState({
                [model.PROPS_CROP_NAME]: nextProps[model.PROPS_CROP_NAME],
                [model.PROPS_PREVIOUS_CROP]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME],
                    { guid: GUID, label: NAME, id: ID }
                ),
                [model.PROPS_NEXT_CROP]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME],
                    { guid: GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeGrowthStageOrder = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_CROP_LIST] != null &&
            nextProps[model.PROPS_GROWTH_STAGE_ORDER]
        ) {
            this.setState({
                [model.PROPS_GROWTH_STAGE_ORDER]: nextProps[model.PROPS_GROWTH_STAGE_ORDER],
            });
        }
    };

    initializeImportAttribute = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_IMPORT_ATTRIBUTE_LIST] != null &&
            nextProps[model.PROPS_IMPORT_ATTRIBUTE]
        ) {
            this.setState({
                [model.PROPS_IMPORT_ATTRIBUTE]: nextProps[model.PROPS_IMPORT_ATTRIBUTE],
            });
        }
    };

    initializePlantPart = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_PLANT_PART] &&
            !this.state[picklistService.PICKLIST_PLANT_PART]
        ) {
            const selectableOptions = prepareSelectableOptions(
                nextProps[picklistService.PICKLIST_PLANT_PART],
                { guid: PICKLIST_GUID, label: VALUE, id: ID }
            );
            this.setState({
                [picklistService.PICKLIST_PLANT_PART]: selectableOptions,
            });
        }
    };

    initializeOrgLevel = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_ORG_LEVEL_LIST] != null &&
            nextProps[model.PROPS_ORG_LEVEL]
        ) {
            this.setState({
                [model.PROPS_ORG_LEVEL]: nextProps[model.PROPS_ORG_LEVEL] || [],
            });
        }
    };

    initializeSampleType = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_SAMPLE_TYPE_GUID] != null &&
            nextProps[picklistService.PICKLIST_SAMPLE_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_SAMPLE_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_SAMPLE_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.nutrientTarget[model.PROPS_SAMPLE_TYPE_GUID]
                ),
            });
        }
    };

    initializeIrrigationCategory = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_IRRIGATION_CATEGORY_GUID] != null &&
            nextProps[picklistService.PICKLIST_IRRIGATION_CATEGORY]
        ) {
            this.setState({
                [picklistService.PICKLIST_IRRIGATION_CATEGORY]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_IRRIGATION_CATEGORY],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.nutrientTarget[model.PROPS_IRRIGATION_CATEGORY_GUID]
                ),
            });
        }
    };

    initializeWaterPurpose = (nextProps) => {
        if (
            this.nutrientTarget[model.PROPS_WATER_PURPOSE_GUID] != null &&
            nextProps[picklistService.PICKLIST_WATER_PURPOSE]
        ) {
            this.setState({
                [picklistService.PICKLIST_WATER_PURPOSE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_WATER_PURPOSE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.nutrientTarget[model.PROPS_WATER_PURPOSE_GUID]
                ),
            });
        }
    };

    onNumberChange = (formKey, value, callback) => {
        this.nutrientTarget = onNumberChange(
            this.nutrientTarget,
            { formKey: [formKey], value },
            callback
        );
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.nutrientTarget = handlePicklistChange(
            this.nutrientTarget,
            { type, guid, value },
            callback
        );
    };

    onTextChange = (formKey, value, callback) => {
        this.nutrientTarget = onTextChange(
            this.nutrientTarget,
            { formKey: [formKey], value },
            callback
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([
            isNaN(this.nutrientTarget[model.PROPS_NUTRIENT_TARGET_VALUE])
                ? ""
                : this.nutrientTarget[model.PROPS_NUTRIENT_TARGET_VALUE].toString(),
        ]);
    };

    setupNutrientTargetValueRecord = () => {
        const { addEditPanel } = this.props;
        this.nutrientTarget = {};
        if (addEditPanel.mode === "ADD") {
            this.nutrientTarget = service.getDefaultRecord();
        }
    };

    fetchGrowthStageData = (cropGuid) => {
        this.props.needs([
            this.props.fetchGrowthStage({
                [model.PROPS_GROWTH_STAGE_ORDER]: {
                    url: REQUEST_GROWTH_STAGE_ORDER,
                    model: cropGuid,
                },
            }),
        ]);
    };

    fetchCropClassData = (cropGuid) => {
        this.props.needs([
            this.props.fetchDropdownData({
                [model.PROPS_CROPS_CLASS_NAME]: {
                    url: REQUEST_CROP_CLASS,
                    model: cropGuid,
                },
            }),
        ]);
    };

    componentDidMount() {
        this.setupNutrientTargetValueRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(),
            this.props.fetchPicklistData(),
            this.props.fetchDropdownData(),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.nutrientTarget);
        }
        if (
            nextProps[model.PROPS_GROWTH_STAGE_ORDER] === this.state[model.PROPS_GROWTH_STAGE_ORDER]
        ) {
            this.setState({
                [model.PROPS_GROWTH_STAGE_ORDER]: nextProps[model.PROPS_GROWTH_STAGE_ORDER],
            });
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.nutrientTarget = nextProps.record;
                this.setState({
                    recordIsUpdated: true,
                });
            }
        }

        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.nutrientTarget[model.PROPS_NUTRIENT_TARGET_VALUE_ID] = "" + nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    renderNutrientTargetInfo = () => {
        const { formatMessage } = this.props.intl;
        const { nutrientTarget } = this;
        const { nextId } = this.state;
        const { apiErrors, userRole } = this.props;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            suppressFormatting
                            containerClassNames={getAgBytesErrorClassNames([345, 346], apiErrors, [
                                "form-input-id",
                            ])}
                            placeholderText={formatMessage(messages.nutrientTargetValueId)}
                            labelText={formatMessage(messages.nutrientTargetValueId)}
                            value={nextId || nutrientTarget[model.PROPS_NUTRIENT_TARGET_VALUE_ID]}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_NUTRIENT_TARGET_VALUE_ID, value)
                            }
                            required
                        />
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className="active-checkbox"
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={nutrientTarget[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            required
                            clearable={false}
                            autoFocus
                            openOnFocus={false}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            containerClassNames={getAgBytesErrorClassNames(144, apiErrors)}
                            options={this.state[picklistService.PICKLIST_SAMPLE_TYPE]}
                            placeholderText={formatMessage(messages.sampleTypeName)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_SAMPLE_TYPE],
                                selectedGuid: nutrientTarget[model.PROPS_SAMPLE_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_SAMPLE_TYPE_NAME,
                                        guid: model.PROPS_SAMPLE_TYPE_GUID,
                                    },
                                    value,
                                    this.setSampleType(value)
                                );
                            }}
                        />
                        {this.isWaterSampleType() ? (
                            <SelectInput
                                tabIndex={0}
                                required
                                clearable={false}
                                autoFocus
                                openOnFocus={false}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                containerClassNames={getAgBytesErrorClassNames(3036, apiErrors)}
                                options={this.state[picklistService.PICKLIST_WATER_PURPOSE]}
                                placeholderText={formatMessage(messages.waterPurpose)}
                                value={mapToPicklistValue({
                                    options: this.state[picklistService.PICKLIST_WATER_PURPOSE],
                                    selectedGuid: nutrientTarget[model.PROPS_WATER_PURPOSE_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_WATER_PURPOSE,
                                            guid: model.PROPS_WATER_PURPOSE_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        ) : (
                            <NumericInput
                                tabIndex={0}
                                scale={2}
                                precision={7}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [535, 2876],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.nutrientTargetValue)}
                                labelText={formatMessage(messages.nutrientTargetValue)}
                                value={nutrientTarget[model.PROPS_NUTRIENT_TARGET_VALUE] || ""}
                                onChange={(value) =>
                                    this.onNumberChange(
                                        model.PROPS_NUTRIENT_TARGET_VALUE,
                                        value,
                                        this.setHeaderText
                                    )
                                }
                            />
                        )}
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section>
                    <SubSection>
                        {this.isWaterSampleType() ? (
                            <span className="set-margin">
                                <SelectInput
                                    tabIndex={0}
                                    clearable={true}
                                    openOnFocus={false}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    containerClassNames={getAgBytesErrorClassNames(144, apiErrors)}
                                    options={
                                        this.state[picklistService.PICKLIST_IRRIGATION_CATEGORY]
                                    }
                                    placeholderText={formatMessage(messages.irrigationCategory)}
                                    value={mapToPicklistValue({
                                        options:
                                            this.state[
                                                picklistService.PICKLIST_IRRIGATION_CATEGORY
                                            ],
                                        selectedGuid:
                                            nutrientTarget[model.PROPS_IRRIGATION_CATEGORY_GUID],
                                    })}
                                    onChange={(value) => {
                                        this.onPicklistChange(
                                            {
                                                type: model.PROPS_IRRIGATION_CATEGORY,
                                                guid: model.PROPS_IRRIGATION_CATEGORY_GUID,
                                            },
                                            value
                                        );
                                    }}
                                />{" "}
                            </span>
                        ) : null}
                    </SubSection>
                </Section>
            </div>
        );
    };

    setSampleType(sampleType) {
        if (sampleType.name === WATER_SAMPLE_TYPE) {
            this.setState({ [model.PROPS_IS_WATER_SAMPLE_TYPE]: true });
        } else {
            this.setState({ [model.PROPS_IS_WATER_SAMPLE_TYPE]: false });
        }
        this.clearNonRelatedInputType(sampleType.name);
    }

    renderDetailInfo1 = () => {
        const { formatMessage } = this.props.intl;
        const { nutrientTarget } = this;
        const { apiErrors } = this.props;
        if (this.isWaterSampleType()) {
            return (
                <div className="section-container section-column">
                    <Section>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [486, 487, 496],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.critical)}
                                labelText={formatMessage(messages.critical)}
                                value={nutrientTarget[model.PROPS_CRITICAL] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_CRITICAL, value)
                                }
                            />
                            <NumericInput
                                tabIndex={0}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [488, 489, 496],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.severe)}
                                labelText={formatMessage(messages.severe)}
                                value={nutrientTarget[model.PROPS_SEVERE] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) => this.onNumberChange(model.PROPS_SEVERE, value)}
                            />
                        </SubSection>
                    </Section>
                </div>
            );
        } else {
            return (
                <div className="section-container section-column">
                    <Section>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [486, 487, 496],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.veryLow)}
                                labelText={formatMessage(messages.veryLow)}
                                value={nutrientTarget[model.PROPS_VERY_LOW] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_VERY_LOW, value)
                                }
                            />
                            <NumericInput
                                tabIndex={0}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [488, 489, 496],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.low)}
                                labelText={formatMessage(messages.low)}
                                value={nutrientTarget[model.PROPS_LOW] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) => this.onNumberChange(model.PROPS_LOW, value)}
                            />
                        </SubSection>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [490, 491, 496],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.medium)}
                                labelText={formatMessage(messages.medium)}
                                value={nutrientTarget[model.PROPS_MEDIUM] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) => this.onNumberChange(model.PROPS_MEDIUM, value)}
                            />
                            <NumericInput
                                tabIndex={0}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [492, 493, 496],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.high)}
                                labelText={formatMessage(messages.high)}
                                value={nutrientTarget[model.PROPS_HIGH] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) => this.onNumberChange(model.PROPS_HIGH, value)}
                            />
                        </SubSection>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                containerClassNames={getAgBytesErrorClassNames(
                                    [494, 495, 496],
                                    apiErrors
                                )}
                                placeholderText={formatMessage(messages.veryHigh)}
                                labelText={formatMessage(messages.veryHigh)}
                                value={nutrientTarget[model.PROPS_VERY_HIGH] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_VERY_HIGH, value)
                                }
                            />
                            <NumericInput
                                tabIndex={0}
                                placeholderText={formatMessage(messages.toxic)}
                                labelText={formatMessage(messages.toxic)}
                                value={nutrientTarget[model.PROPS_TOXIC] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) => this.onNumberChange(model.PROPS_TOXIC, value)}
                            />
                        </SubSection>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                placeholderText={formatMessage(messages.cecMin)}
                                labelText={formatMessage(messages.cecMin)}
                                value={nutrientTarget[model.PROPS_CEC_MIN] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_CEC_MIN, value)
                                }
                            />
                            <NumericInput
                                tabIndex={0}
                                placeholderText={formatMessage(messages.cecMax)}
                                labelText={formatMessage(messages.cecMax)}
                                value={nutrientTarget[model.PROPS_CEC_MAX] || ""}
                                precision={7}
                                scale={2}
                                allowNegatives={false}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_CEC_MAX, value)
                                }
                            />
                        </SubSection>
                    </Section>
                </div>
            );
        }
    };

    renderDetailInfo2 = () => {
        const { formatMessage } = this.props.intl;
        const { nutrientTarget } = this;
        return (
            <div className="section-container section-column">
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.orgLevelList)}
                >
                    {this.state[model.PROPS_ORG_LEVEL] ? (
                        <OrgLevelList
                            apiErrors={this.props.apiErrors}
                            itemList={this.state[model.PROPS_ORG_LEVEL]}
                            record={nutrientTarget[model.PROPS_ORG_LEVEL_LIST]}
                            onSelectionChange={(value) => {
                                this.onTextChange(model.PROPS_ORG_LEVEL_LIST, value, () =>
                                    this.forceUpdate()
                                );
                            }}
                            statePropName={PROPS_STATE_ABBREVIATION}
                        />
                    ) : null}
                </Section>
            </div>
        );
    };

    getUpdatedLists = (cropGuid) => {
        if (cropGuid) {
            this.props.needs([
                this.props.fetchDropdownData({
                    [model.PROPS_CROP_CLASS_NAME]: {
                        url: REQUEST_CROP_CLASS,
                        model: cropGuid,
                    },
                }),
                this.props.fetchGrowthStage({
                    [model.PROPS_GROWTH_STAGE_ORDER]: {
                        url: REQUEST_GROWTH_STAGE_ORDER,
                        model: cropGuid,
                    },
                }),
            ]);
        } else {
            this.setState({
                [model.PROPS_GROWTH_STAGE_ORDER]: [],
                [model.PROPS_CROP_CLASS_NAME]: [],
            });
            this.nutrientTarget[model.PROPS_GROWTH_STAGE_ORDER] = "";
            this.nutrientTarget[model.PROPS_CROP_CLASS_NAME] = "";
        }
    };

    renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        const plantPartList = this.state[picklistService.PICKLIST_PLANT_PART];
        const isWaterSampleType = this.isWaterSampleType();
        return (
            <div className="section-container section-column">
                {isWaterSampleType ? null : (
                    <Section
                        required
                        className="grid-section"
                        headerText={formatMessage(messages.cropPurposeList)}
                    >
                        <SubSection>
                            <CropList
                                apiErrors={this.props.apiErrors}
                                formatMessage={formatMessage}
                                getUpdatedLists={this.getUpdatedLists}
                                picklistOptions={{
                                    [model.PROPS_CROP_NAME]: this.state[model.PROPS_CROP_NAME],
                                    [model.PROPS_CROP_CLASS_NAME]:
                                        this.state[model.PROPS_CROP_CLASS_NAME],
                                    [picklistService.PICKLIST_CROP_PURPOSE]:
                                        this.state[picklistService.PICKLIST_CROP_PURPOSE],
                                    [model.PROPS_GROWTH_STAGE_ORDER]:
                                        this.state[model.PROPS_GROWTH_STAGE_ORDER],
                                }}
                                record={this.nutrientTarget[model.PROPS_CROP_LIST]}
                                itemListAlias={model.PROPS_CROP_LIST}
                                onTextChange={(e, value) =>
                                    this.onTextChange(model.PROPS_CROP_LIST, value)
                                }
                                fetchGrowthStageData={this.fetchGrowthStageData}
                                fetchCropClassData={this.fetchCropClassData}
                            />
                        </SubSection>
                    </Section>
                )}
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.importAttribute)}
                >
                    <SubSection>
                        <ImportAttributeList
                            apiErrors={this.props.apiErrors}
                            formatMessage={formatMessage}
                            picklistOptions={{
                                [model.PROPS_IMPORT_ATTRIBUTE]:
                                    this.state[model.PROPS_IMPORT_ATTRIBUTE],
                            }}
                            record={this.nutrientTarget[model.PROPS_IMPORT_ATTRIBUTE_LIST]}
                            itemListAlias={model.PROPS_IMPORT_ATTRIBUTE_LIST}
                            onTextChange={(e, value) =>
                                this.onTextChange(model.PROPS_IMPORT_ATTRIBUTE_LIST, value)
                            }
                        />
                    </SubSection>
                </Section>
                {isWaterSampleType === true ? null : (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.plantPart)}
                    >
                        <SubSection>
                            <PlantPartList
                                apiErrors={this.props.apiErrors}
                                formatMessage={formatMessage}
                                picklistOptions={{
                                    [picklistService.PICKLIST_PLANT_PART]: plantPartList,
                                }}
                                record={this.nutrientTarget[model.PROPS_PLANT_PART_LIST]}
                                itemListAlias={model.PROPS_PLANT_PART_LIST}
                                onTextChange={(e, value) =>
                                    this.onTextChange(model.PROPS_PLANT_PART_LIST, value)
                                }
                            />
                        </SubSection>
                    </Section>
                )}
            </div>
        );
    };

    isWaterSampleType() {
        if (
            this.nutrientTarget[model.PROPS_SAMPLE_TYPE_NAME] === WATER_SAMPLE_TYPE ||
            this.state[model.PROPS_IS_WATER_SAMPLE_TYPE]
        ) {
            return true;
        } else {
            return false;
        }
    }

    clearNonRelatedInputType(sampleTypeName) {
        if (sampleTypeName === WATER_SAMPLE_TYPE) {
            this.nutrientTarget[model.PROPS_NUTRIENT_TARGET_VALUE] = "";
            this.nutrientTarget[model.PROPS_VERY_LOW] = "";
            this.nutrientTarget[model.PROPS_LOW] = "";
            this.nutrientTarget[model.PROPS_MEDIUM] = "";
            this.nutrientTarget[model.PROPS_HIGH] = "";
            this.nutrientTarget[model.PROPS_VERY_HIGH] = "";
            this.nutrientTarget[model.PROPS_TOXIC] = "";
            this.nutrientTarget[model.PROPS_CEC_MAX] = "";
            this.nutrientTarget[model.PROPS_CEC_MIN] = "";
            this.nutrientTarget[model.PROPS_CROP_LIST] = null;
            this.nutrientTarget[model.PROPS_PLANT_PART_LIST] = null;
        } else {
            this.nutrientTarget[model.PROPS_SEVERE] = "";
            this.nutrientTarget[model.PROPS_CRITICAL] = "";
            this.nutrientTarget[model.PROPS_IRRIGATION_CATEGORY] = null;
            this.nutrientTarget[model.PROPS_IRRIGATION_CATEGORY_GUID] = null;
        }
    }

    render() {
        return (
            <div className="add-edit-panel">
                {this.renderNutrientTargetInfo()}
                <div className="section-container">
                    {this.renderDetailInfo1()}
                    <span className="bar section-fence" />
                    {this.renderDetailInfo2()}
                    <span className="bar section-fence" />
                    {this.renderDetailInfo3()}
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
