import React, { Component } from "react";
import PropTypes from "prop-types";
import { MenuItems, MenuPositions } from "./menu-items";
import { NoLink } from "~/core";
import { Link } from "react-router-dom";
import { RightArrowIcon } from "~/core/icons";
export class MenuItem extends Component {
    static propTypes = {
        label: PropTypes.string,
        routeString: PropTypes.string,
        onWindowClick: PropTypes.func.isRequired,
        action: PropTypes.func,
        subMenuItems: PropTypes.array,
        disabled: PropTypes.bool,
        parent: PropTypes.any,
    };

    static defaultProps = {
        label: "Menu Item",
        routeString: null,
        action: undefined,
        subMenuItems: [],
        disabled: false,
    };

    constructor() {
        super();
        this.state = {
            expanded: false,
        };
        this.handleSubWindowClick = this.handleSubWindowClick.bind(this);
    }

    handleSubWindowClick(evt) {
        if (this.rootNode && !this.rootNode.contains(evt.target)) {
            this.collapse();
            if (this.props.parent && !this.props.parent.contains(evt.target)) {
                this.props.onWindowClick({});
            }
        }
    }

    expandAndAct(evt) {
        if (this.props.subMenuItems.length > 0) {
            this.expand();
            this.performAction();
        } else {
            this.performAction();
            this.props.onWindowClick && this.props.onWindowClick({});
            evt.stopPropagation();
        }
    }

    expand() {
        this.setState({ expanded: true });
    }
    collapse() {
        this.setState({ expanded: false });
    }

    performAction() {
        if (this.props.action != null) {
            this.props.action();
        }
    }

    componentDidMount() {
        if (window && this.props.subMenuItems.length > 0) {
            window.addEventListener("click", this.handleSubWindowClick, false);
        }
    }

    componentWillUnmount() {
        if (window && this.props.subMenuItems.length > 0) {
            window.removeEventListener("click", this.handleSubWindowClick, false);
        }
    }

    render() {
        const { label, routeString } = this.props;
        const arrow = (
            <div className="menu-item-right-arrow">
                <RightArrowIcon />
            </div>
        );
        const subMenuItems = (
            <MenuItems
                items={this.props.subMenuItems}
                onWindowClick={this.props.onWindowClick}
                parent={this.props.parent}
                position={MenuPositions.RIGHT_SIDE}
                yOffset={-1}
            />
        );
        const linkLabel = routeString ? (
            <Link to={routeString}>{label}</Link>
        ) : (
            <NoLink label={label} />
        );
        const disabledLabel = <span>{label}</span>;
        return (
            <li
                className="menu-item"
                label={label}
                onClick={this.props.disabled ? null : (e) => this.expandAndAct(e)}
                ref={(rootNode) => {
                    this.rootNode = rootNode;
                }}
            >
                {this.props.disabled ? disabledLabel : linkLabel}
                {this.props.subMenuItems.length > 0 ? arrow : null}
                {this.state.expanded ? subMenuItems : null}
            </li>
        );
    }
}
