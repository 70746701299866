import { defineMessages } from "react-intl";

export const messages = defineMessages({
    addPhoto: {
        id: "eventModule.eventInfo.eventScouting.addPhoto",
        defaultMessage: "Photo",
    },
    allowPhotos: {
        id: "eventModule.eventInfo.eventScouting.allowPhotos",
        defaultMessage: "Allow Photos",
    },
    brandOrganization: {
        id: "eventModule.eventInfo.eventScouting.brandOrganization",
        defaultMessage: "Brand/Organization",
    },
    count: {
        id: "eventModule.eventInfo.eventScouting.count",
        defaultMessage: "Count",
    },
    countUnit: {
        id: "eventModule.eventInfo.eventScouting.countUnit",
        defaultMessage: "Count Unit",
    },
    crop: {
        id: "eventModule.eventInfo.eventScouting.crop",
        defaultMessage: "Crop",
    },
    cropCondition: {
        id: "eventModule.eventInfo.eventScouting.cropCondition",
        defaultMessage: "Crop Condition",
    },
    cropError: {
        id: "eventModule.eventInfo.eventScouting.cropError",
        defaultMessage: "Crop Error",
    },
    cropHeight: {
        id: "eventModule.eventInfo.eventScouting.cropHeight",
        defaultMessage: "Crop Height",
    },
    cropHeightUnit: {
        id: "eventModule.eventInfo.eventScouting.cropHeightUnit",
        defaultMessage: "Crop Height Unit",
    },
    cropInjury: {
        id: "eventModule.eventInfo.eventScouting.cropInjury",
        defaultMessage: "Crop Injury",
    },
    cropInfo: {
        id: "eventModule.eventInfo.eventScouting.cropInfo",
        defaultMessage: "Crop Info",
    },
    cropPopulation: {
        id: "eventModule.eventInfo.eventScouting.cropPopulation",
        defaultMessage: "Crop Population",
    },
    cropPopulationUnit: {
        id: "eventModule.eventInfo.eventScouting.cropPopulationUnit",
        defaultMessage: "Crop Population Unit",
    },
    cropPurpose: {
        id: "eventModule.eventInfo.eventScouting.cropPurpose",
        defaultMessage: "Crop Purpose",
    },
    daysAfterCropDamage: {
        id: "eventModule.eventInfo.eventScouting.daysAfterCropDamage",
        defaultMessage: "Days After Crop Damage",
    },
    deletePhotoConfirmation: {
        id: "eventModule.eventInfo.eventScouting.deletePhotoConfirmation",
        defaultMessage: "Are you sure you want to delete this photo?",
    },
    errorConvertBase64: {
        id: "eventModule.eventInfo.eventScouting.errorConvertBase64",
        defaultMessage: "Error converting image file to base64",
    },
    errorGeneratingThumbnail: {
        id: "eventModule.eventInfo.eventScouting.errorGeneratingThumbnail",
        defaultMessage: "Error converting image and/or generating image thumbnail",
    },
    errorMissingMediaTypeGuid: {
        id: "eventModule.eventInfo.eventScouting.errorMissingMediaTypeGuid",
        defaultMessage: "Unable to add photo (Media-Type Guid request failed)",
    },
    errorUnsupportedImageType: {
        id: "eventModule.eventInfo.eventScouting.errorUnsupportedImageType",
        defaultMessage: "PNG and JPEG are the only supported image types",
    },
    fullsizePhotoModalDeleteText: {
        id: "eventModule.eventInfo.eventScouting.fullsizePhotoModalDeleteText",
        defaultMessage: "Delete",
    },
    fullsizePhotoModalSaveText: {
        id: "eventModule.eventInfo.eventScouting.fullsizePhotoModalSaveText",
        defaultMessage: "Save",
    },
    fullsizePhotoNotFoundText: {
        id: "eventModule.eventInfo.eventScouting.fullsizePhotoNotFoundText",
        defaultMessage: "Photo Missing",
    },
    growthStage: {
        id: "eventModule.eventInfo.eventScouting.growthStage",
        defaultMessage: "Growth Stage",
    },
    leafLoss: {
        id: "eventModule.eventInfo.eventScouting.leafLoss",
        defaultMessage: "Leaf Loss (%)",
    },
    notes: {
        id: "eventModule.eventInfo.eventScouting.notes",
        defaultMessage: "Notes",
    },
    observation: {
        id: "eventModule.eventInfo.eventScouting.observation",
        defaultMessage: "Observation",
    },
    observationCount: {
        id: "eventModule.eventInfo.eventScouting.observationCount",
        defaultMessage: "Observation Count",
    },
    observationInfo: {
        id: "eventModule.eventInfo.eventScouting.observationInfo",
        defaultMessage: "Observation Info",
    },
    observationRequiredError: {
        id: "eventModule.eventInfo.eventScouting.observationRequiredError",
        defaultMessage: "Observation is required",
    },
    plantLocation: {
        id: "eventModule.eventInfo.eventScouting.plantLocation",
        defaultMessage: "Plant Location",
    },
    pressure: {
        id: "eventModule.eventInfo.eventScouting.pressure",
        defaultMessage: "Pressure",
    },
    resetTemplateText: {
        id: "eventModule.eventInfo.eventScouting.resetTemplateText",
        defaultMessage: "Reset Template",
    },
    resetTemplateConfirmation: {
        id: "eventModule.eventInfo.eventScouting.resetTemplateConfirmation",
        defaultMessage: "Are you sure you want to reset the Template?",
    },
    scoutingFormLabelText: {
        id: "eventModule.eventInfo.eventScouting.scoutingFormLabelText",
        defaultMessage: "Scouting",
    },
    scoutingPhotoDescriptionPlaceholderText: {
        id: "eventModule.eventInfo.eventScouting.scoutingPhotoDescriptionPlaceholderText",
        defaultMessage: "Description",
    },
    scoutingTemplateTitle: {
        id: "eventModule.eventInfo.eventScouting.scoutingTemplateTitle",
        defaultMessage: "Scouting Template",
    },
    scoutingRecommendationTitle: {
        id: "eventModule.eventInfo.eventScouting.scoutingRecommendationTitle",
        defaultMessage: "Recommendation",
    },
    scoutingRecommendationPlaceholderText: {
        id: "eventModule.eventInfo.eventScouting.scoutingRecommendationPlaceholderText",
        defaultMessage: "Recommendation",
    },
    standLoss: {
        id: "eventModule.eventInfo.eventScouting.standLoss",
        defaultMessage: "Stand Loss (%)",
    },
    titleFullsizePhotoModal: {
        id: "eventModule.eventInfo.eventScouting.titleFullsizePhotoModal",
        defaultMessage: "Photo",
    },
    titleDeletePhotoConfirmation: {
        id: "eventModule.eventInfo.eventScouting.titleDeletePhotoconfirmation",
        defaultMessage: "Delete Photo",
    },
    trapId: {
        id: "eventModule.eventInfo.eventScouting.trapId",
        defaultMessage: "Trap ID",
    },
    units: {
        id: "eventModule.eventInfo.eventScouting.units",
        defaultMessage: "Units",
    },
    varietyHybrid: {
        id: "eventModule.eventInfo.eventScouting.varietyHybrid",
        defaultMessage: "Variety/Hybrid",
    },
    weedHeight: {
        id: "eventModule.eventInfo.eventScouting.weedHeight",
        defaultMessage: "Weed Height",
    },
    yieldLoss: {
        id: "eventModule.eventInfo.eventScouting.yieldLoss",
        defaultMessage: "Yield Loss (%)",
    },
});
