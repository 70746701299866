import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const AddMultipointIcon = withSvgIcon(
    <g>
        <path
            d={
                "M261.2,92.2c0-29-23.6-52.6-52.6-52.6c-29,0-52.6,23.6-52.6,52.6c0,29,23.6,52.6,52.6,52.6" +
                "C237.6,144.7,261.2,121.2,261.2,92.2z M181.9,92.2c0-14.7,12-26.7,26.7-26.7c14.7,0,26.7,12,26.7," +
                "26.7s-12,26.7-26.7,26.7C193.9,118.9,181.9,106.9,181.9,92.2z"
            }
        />
        <path
            d={
                "M94.6,128.6c-28.5,0-51.8,23.2-51.8,51.8c0,28.5,23.2,51.8,51.8,51.8c28.5,0,51.8-23.2,51.8-51.8" +
                "C146.3,151.8,123.1,128.6,94.6,128.6z M94.6,206.2c-14.3,0-25.9-11.6-25.9-25.9c0-14.3,11.6-25.9," +
                "25.9-25.9s25.9,11.6,25.9,25.9C120.5,194.6,108.9,206.2,94.6,206.2z"
            }
        />
        <path
            d={
                "M82.7,105.1c29,0,52.6-23.6,52.6-52.6S111.7,0,82.7,0c-29,0-52.6,23.6-52.6,52.6S53.7,105.1,82.7,105.1z" +
                "M82.7,25.9c14.7,0,26.7,12,26.7,26.7s-12,26.7-26.7,26.7C68,79.2,56,67.3,56,52.6S68,25.9,82.7,25.9z"
            }
        />
        <path
            d={
                "M362.5,359.6l80-69.5L181.8,171.4L263.1,446l80-69.5L450.3,500l19.5-17L362.5,359.6z M276.5,400.1" +
                "l-53.7-181.7l172.4,78.5L276.5,400.1z"
            }
        />
    </g>
);
