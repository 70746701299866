import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { service, actions, model } from "./data";
import { withMasked, withApiResult, withCrud } from "~/hocs";
import { defaultRequestFilters, REQUEST_PAYLOAD_FILTER } from "./data/service";
import { preventBubbleUp } from "~/admin/utils";
import _ from "lodash";
import "./equipment.css";
// Components
import AddEditPanel from "./add-edit/add-edit-container";
import { DataTable, Button } from "~/core";
import { messages } from "../i18n-messages";
import ActiveInactiveLink from "../component/active-inactive-link";
import HierarchyFilter from "../component/hierarchy-filter";
import { HierarchyAPI } from "@ai360/core";
import { SlidingPanel } from "~/sliding-panel/sliding-panel";
export class Equipment_ extends Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        intl: intlShape.isRequired,
        addEditPanel: PropTypes.object,
        closeSidePanel: PropTypes.func,
        actions: PropTypes.object,
        toggleActiveInactive: PropTypes.func,
        needs: PropTypes.func,
        onSubmit: PropTypes.func,
        deleteSelected: PropTypes.func,
        fetchDropdownData: PropTypes.func,
        fetchHierarchy: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };
    static defaultProps = {};
    ///----------------------------///
    constructor(props) {
        super(props);
        this.state = {
            orgLevel: [],
        };
    }

    componentDidMount() {
        this.props.needs([
            this.props.fetchDropdownData({
                [model.PROPS_ORG_LEVEL]: {
                    url: HierarchyAPI.REQUEST_ORG_LEVEL_WITH_PARENTS,
                },
            }),
        ]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(nextProps[model.PROPS_ORG_LEVEL], this.props[model.PROPS_ORG_LEVEL])) {
            this.setState({
                orgLevel: nextProps[model.PROPS_ORG_LEVEL],
            });
        }
    }

    toggleActiveInactive = () => {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            () => {
                this.props.toggleActiveInactive();
            }
        );
    };

    onFilterChange = (data) => {
        if (data) {
            const { orgLevelGuid } = data;
            const { needs, actions } = this.props;
            const requestOptions = {
                ...defaultRequestFilters,
                [REQUEST_PAYLOAD_FILTER]: {
                    ...defaultRequestFilters[REQUEST_PAYLOAD_FILTER],
                    [model.PROPS_ORG_LEVEL_GUID]: orgLevelGuid,
                },
            };
            needs([actions.fetch(requestOptions)]);
        } else {
            const { needs, actions } = this.props;
            const requestOptions = {
                ...defaultRequestFilters,
                [REQUEST_PAYLOAD_FILTER]: {
                    ...defaultRequestFilters[REQUEST_PAYLOAD_FILTER],
                    [model.PROPS_ORG_LEVEL_GUID]: null,
                },
            };
            needs([actions.fetch(requestOptions)]);
        }
    };

    render() {
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        return (
            <div className="content-table-container">
                <div className="filter-header-container">
                    <div className="filter-header-container">
                        <div className="filter-container">
                            <HierarchyFilter
                                needs={this.props.needs}
                                onFilterChange={this.onFilterChange}
                            />
                        </div>
                        {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <div className="active-inactive-container">
                                <ActiveInactiveLink
                                    toggleActiveInactive={this.toggleActiveInactive}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "140",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={() => this.props.onSubmit()} />
                            <Button
                                type="cancel"
                                className="equipment-cancel"
                                onClick={() => this.props.closeSidePanel()}
                            />
                        </SlidingPanel>
                    </form>
                )}
                <DataTable
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    {...this.props}
                    footerOptions={this.tableFooterOptions}
                />
            </div>
        );
    }
}
export const Equipment = injectIntl(
    withMasked(withApiResult(withCrud(Equipment_, service, actions), actions.importData))
);
