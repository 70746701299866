import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { SelectInput } from "~/core";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { actions as recsEventsActions, recsModels } from "~/recs-events";

import "../../../../../common/rec-event-info/rec-event-info.css";
import "./rec-manual-application-form.css";

const messages = defineMessages({
    applicationMethodPlaceholderText: {
        id: "recModule.recInfo.applicationMethodPlaceholderText",
        defaultMessage: "Application Method",
    },
    applicationTimingPlaceholderText: {
        id: "recModule.recInfo.applicationTimingPlaceholderText",
        defaultMessage: "Application Timing",
    },
});

const { getPickListCode, PICKLIST_APPLICATION_METHOD, PICKLIST_APPLICATION_TIMING } = picklistNames;

interface IRecManualApplicationFieldDataProps {
    recEventDetails: any;
    fieldGuid: string;
    onFetchPicklists: (picklistFetchArgObj) => void;
    onUpdateRecDetails: (newProps) => void;
    picklistOptionsApplicationMethod: any[];
    picklistOptionsApplicationTiming: any[];
    intl: intlShape;
}

export class RecManualApplicationFieldData_ extends PureComponent<
    IRecManualApplicationFieldDataProps,
    null
> {
    UNSAFE_componentWillMount() {
        const fetchPicklistNames = [PICKLIST_APPLICATION_METHOD, PICKLIST_APPLICATION_TIMING];

        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = getPickListCode(key);
            return accum;
        }, {});

        this.props.onFetchPicklists(picklistFetchArgObj);
    }

    _updateRecFieldData(newProps) {
        const { recEventDetails, onUpdateRecDetails } = this.props;

        const recAreaList = recEventDetails.recAreaList.map((recArea) => {
            return recsModels.RecArea.updateRecArea(recArea, {
                recs: recArea.recs.map((rec) => {
                    return rec.updateRecModel({ ...newProps });
                }),
            });
        });
        onUpdateRecDetails({ recAreaList });
    }

    render() {
        const {
            recEventDetails,
            picklistOptionsApplicationMethod,
            picklistOptionsApplicationTiming,
        } = this.props;
        const { formatMessage } = this.props.intl;

        const firstRec = recEventDetails.recAreaList[0].recs[0] || {};
        const applicationMethodGuid = firstRec.applicationMethodGuid;
        const applicationTimingGuid = firstRec.applicationTimingGuid;

        return (
            <div className="rec-manual-field-data">
                <div className="input-row">
                    <SelectInput
                        onChange={(v) =>
                            this._updateRecFieldData({
                                applicationMethodGuid: v,
                            })
                        }
                        optionIsHiddenKey={ACTIVE_YN}
                        options={picklistOptionsApplicationMethod}
                        placeholderText={formatMessage(messages.applicationMethodPlaceholderText)}
                        value={applicationMethodGuid}
                    />
                    <SelectInput
                        onChange={(v) =>
                            this._updateRecFieldData({
                                applicationTimingGuid: v,
                            })
                        }
                        optionIsHiddenKey={ACTIVE_YN}
                        options={picklistOptionsApplicationTiming}
                        placeholderText={formatMessage(messages.applicationTimingPlaceholderText)}
                        value={applicationTimingGuid}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const picklistOptionsApplicationMethod = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_APPLICATION_METHOD)
    );
    const picklistOptionsApplicationTiming = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_APPLICATION_TIMING)
    );

    return {
        picklistOptionsApplicationMethod,
        picklistOptionsApplicationTiming,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onUpdateRecDetails: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateRecDetails(fieldGuid, newProps)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateRecDetails: (newProps) =>
        dispatchProps.onUpdateRecDetails(ownProps.fieldGuid, newProps),
});

export const RecManualApplicationFieldData = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecManualApplicationFieldData_));
