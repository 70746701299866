import { defineMessages } from "react-intl";

export const messages = defineMessages({
    confirmSaveBelowMinAcreage: {
        id: "fieldInformation.confirmSaveBelowMinAcreage",
        defaultMessage:
            "One or more polygons are less than {minAcres, number} acres.  Save anyway?",
    },
    confirmSaveBelowTitle: {
        id: "fieldInformation.confirmSaveBelowTitle",
        defaultMessage: "Confirm",
    },
    errorAboveMaxAcreage: {
        id: "fieldInformation.errorAboveMaxAcreage",
        defaultMessage:
            "This field is larger in area than the system limit of {maxAcres, number} acres.  You need to reduce the size of the field below this limit.",
    },
    errorAboveMaxAcreageTitle: {
        id: "fieldInformation.errorAboveMaxAcreageTitle",
        defaultMessage: "Error",
    },
    errorInvalidGeometriesTitle: {
        id: "fieldInformation.errorInvalidGeometriesTitle",
        defaultMessage: "Geometry Error",
    },
    errorInvalidGeometries: {
        id: "fieldInformation.errorInvalidGeometries",
        defaultMessage:
            "Geometries are intersecting.  Please clip any intersections before saving.",
    },
});
