export interface MergeableEvent {
    activeYn: boolean;
    agEventDisplayName: string;
    agEventGeneralGuid: string;
    agEventGuid: string;
    agEventName: string;
    agEventTransactionTypeGuid: string;
    agEventTypeName: string;
    cropDisplayName: string;
    cropGuids: string[];
    croppingSeasonGuid: string;
    croppingSeasonName: string;
    eventDate: string;
    eventId: string;
    fieldGuid: string;
    hasSoilSampleResults: boolean;
    importedPoints: number;
    importedStatus: number;
    modifiedDate: string;
    numberOfSampleDepths: string;
    status: string;
    totalPoints: number;
    calculatedArea: number;
}

export enum MergeableEventType {
    Application,
    Soil,
}

export class MergeableApplicationEvents {
    type = MergeableEventType.Application;

    mergeInto: MergeableEvent;
    collapsing: MergeableEvent[];
    all: MergeableEvent[];

    constructor(mergeInto: MergeableEvent, collapsing: MergeableEvent[]) {
        this.mergeInto = mergeInto;
        this.collapsing = collapsing;
        this.all = [mergeInto, ...collapsing];
    }
}

export class MergeableSoilEvents {
    type = MergeableEventType.Soil;

    mergeInto: MergeableEvent;
    collapsing: MergeableEvent[];
    all: MergeableEvent[];

    hasSoilSampleResults: boolean;

    constructor(
        mergeInto: MergeableEvent,
        collapsing: MergeableEvent[],
        hasSoilSampleResults: boolean
    ) {
        this.mergeInto = mergeInto;
        this.collapsing = collapsing;
        this.all = [mergeInto, ...collapsing];
        this.hasSoilSampleResults = hasSoilSampleResults;
    }
}

export type MergeableEvents = MergeableApplicationEvents | MergeableSoilEvents;
