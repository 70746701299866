import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";

import { ImportDataFilterAPI, APIError } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { setApiResult } from "~/core/api/actions";
import { getTheUserGuid } from "~/login/selectors";

export function* fetchImportFilter() {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield ImportDataFilterAPI.getImportDataFilterValues({
            UserGuid,
        });
        if (response) {
            yield put(actions.fetchImportFilterSuccess(response));
        } else {
            yield put(actions.fetchImportFilterFailed("No Data"));
        }
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, actions.fetchImportFilter));
        }
    }
}

export function* watchFetchImportFilter() {
    yield takeEvery(actions.fetchImportFilter, fetchImportFilter);
}

export function* saveImportFilter(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            yield ImportDataFilterAPI.saveImportDataFilterValues(requestOptions);
            yield put(actions.saveImportFilterSuccess());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(actions.saveImportFilterFailed({ error }));
        }
    } else {
        yield put(actions.saveImportFilterFailed("No Data"));
    }
}

export function* watchSaveImportFilter() {
    yield takeEvery(actions.saveImportFilter, saveImportFilter);
}

const ImportFilterSettingSagas = function* () {
    yield all([fork(watchFetchImportFilter), fork(watchSaveImportFilter)]);
};

export default ImportFilterSettingSagas;
