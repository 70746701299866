import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { messages } from "../../i18n-messages";

import {
    getAgBytesErrorClassNames,
    mapToPicklistValue,
    prepareSelectableOptions,
} from "~/admin/utils";
import { ABBREVIATION, adminData } from "~/admin/data";
// Components
import { Checkbox, Section, SelectInput, SubSection, TextInput } from "~/core";

// Constants
import * as constants from "./constants";

export class AddressItem extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        person: PropTypes.object,
        onPicklistChange: PropTypes.func,
        onCountryChange: PropTypes.func,
        UserGuid: PropTypes.string,
        countryStateList: PropTypes.object,
        address: PropTypes.array,
        index: PropTypes.number,
        onAddressTypeChange: PropTypes.func,
        onAddressChange: PropTypes.func,
        onPrimaryChange: PropTypes.func,
        onStateChange: PropTypes.func,
        requestState: PropTypes.func,
        mapSelectedCountry: PropTypes.func,
        apiErrors: PropTypes.array,
        disabled: PropTypes.bool,
    };

    getState = (index) => {
        const guid =
            this.props.address[index][constants.PROPS_ADDRESS][constants.PROPS_COUNTRY_GUID];
        if (this.props.countryStateList[guid]) {
            return prepareSelectableOptions(this.props.countryStateList[guid], {
                guid: constants.PROPS_STATE_GUID,
                label: ABBREVIATION,
            });
        } else {
            if (
                this.props[constants.PROPS_STATE_LIST] &&
                this.props[constants.PROPS_STATE_LIST].length > 0 &&
                this.props[constants.PROPS_STATE_LIST][0].label
            ) {
                return this.props[constants.PROPS_STATE_LIST];
            }
            return prepareSelectableOptions(this.props[constants.PROPS_STATE_LIST], {
                guid: constants.PROPS_STATE_GUID,
                label: ABBREVIATION,
            });
        }
    };

    getContainerClass = (className, errorCode, type) => {
        const { address, index } = this.props;
        if (address[index].isError) {
            const { apiErrors } = this.props;
            return [
                classNames(
                    className,
                    type ? false : getAgBytesErrorClassNames(errorCode, apiErrors)
                ),
            ];
        }
        return [className];
    };

    render() {
        const { index } = this.props;
        const { formatMessage } = this.props.intl;
        const disabled =
            this.props.disabled ||
            this.props.address[index][constants.PROPS_ADDRESS][constants.PROPS_READ_ONLY_YN] ===
                true;
        return (
            <Section>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.props[constants.PROPS_ADDRESS_TYPE_LIST]}
                        placeholderText={formatMessage(messages.addressType)}
                        labelText={formatMessage(messages.addressType)}
                        value={mapToPicklistValue({
                            options: this.props[constants.PROPS_ADDRESS_TYPE_LIST],
                            selectedGuid:
                                this.props.address[index][constants.PROPS_ADDRESS_TYPE_GUID],
                        })}
                        onChange={(value) =>
                            this.props.onAddressTypeChange(
                                {
                                    type: [constants.PROPS_ADDRESS_TYPE_NAME],
                                    guid: [constants.PROPS_ADDRESS_TYPE_GUID],
                                },
                                value,
                                index
                            )
                        }
                        containerClassNames={this.getContainerClass(
                            "address-type",
                            7,
                            this.props.address[index][constants.PROPS_ADDRESS_TYPE_GUID]
                        )}
                        clearable={false}
                        required
                        disabled={disabled}
                    />
                    <Checkbox
                        onChange={(e, value) => this.props.onPrimaryChange(value, index)}
                        label={formatMessage(messages.primary)}
                        value={this.props.address[index][constants.PROPS_ADDRESS_IS_PRIMARY]}
                        className="address-is-primary"
                        disabled={this.props.address.length === 1 && index === 0 ? true : false}
                    />
                </SubSection>
                <SubSection>
                    <TextInput
                        tabIndex={0}
                        placeholderText={formatMessage(messages.address)}
                        labelText={formatMessage(messages.address)}
                        onChange={(value) =>
                            this.props.onAddressChange(
                                {
                                    type: [constants.PROPS_STREET_ONE],
                                },
                                value,
                                index
                            )
                        }
                        value={
                            this.props.address[index][constants.PROPS_ADDRESS][
                                constants.PROPS_STREET_ONE
                            ]
                        }
                        containerClassNames={this.getContainerClass(
                            "address-street-one",
                            8,
                            this.props.address[index][constants.PROPS_ADDRESS][
                                constants.PROPS_STREET_ONE
                            ]
                        )}
                        required
                        disabled={disabled}
                    />
                </SubSection>
                <SubSection>
                    <TextInput
                        tabIndex={0}
                        placeholderText={formatMessage(messages.city)}
                        labelText={formatMessage(messages.city)}
                        onChange={(value) =>
                            this.props.onAddressChange(
                                {
                                    type: [constants.PROPS_CITY],
                                },
                                value,
                                index
                            )
                        }
                        value={
                            this.props.address[index][constants.PROPS_ADDRESS][constants.PROPS_CITY]
                        }
                        containerClassNames={this.getContainerClass(
                            "address-city",
                            4,
                            this.props.address[index][constants.PROPS_ADDRESS][constants.PROPS_CITY]
                        )}
                        required
                        disabled={disabled}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.getState(index)}
                        placeholderText={formatMessage(messages.stateAbbr)}
                        labelText={formatMessage(messages.stateAbbr)}
                        onChange={(value) =>
                            this.props.onStateChange(
                                {
                                    guid: constants.PROPS_STATE_GUID,
                                },
                                value,
                                index
                            )
                        }
                        value={mapToPicklistValue({
                            options: this.getState(index),
                            selectedGuid:
                                this.props.address[index][constants.PROPS_ADDRESS][
                                    constants.PROPS_STATE_GUID
                                ],
                        })}
                        containerClassNames={this.getContainerClass(
                            "address-state",
                            5,
                            this.props.address[index][constants.PROPS_ADDRESS][
                                constants.PROPS_STATE_GUID
                            ]
                        )}
                        clearable={false}
                        required
                        disabled={disabled}
                    />
                    <TextInput
                        tabIndex={0}
                        maxLength={15}
                        placeholderText={formatMessage(messages.zipCode)}
                        labelText={formatMessage(messages.zipCode)}
                        onChange={(value) =>
                            this.props.onAddressChange(
                                {
                                    type: [constants.PROPS_ZIP_CODE],
                                },
                                value,
                                index
                            )
                        }
                        value={
                            this.props.address[index][constants.PROPS_ADDRESS][
                                constants.PROPS_ZIP_CODE
                            ]
                        }
                        containerClassNames={["address-zip-code"]}
                        disabled={disabled}
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.props[constants.PROPS_COUNTRY_LIST]}
                        placeholderText={formatMessage(messages.country)}
                        labelText={formatMessage(messages.country)}
                        value={this.props.mapSelectedCountry({
                            options: this.props[constants.PROPS_COUNTRY_LIST],
                            guid: this.props.address[index][constants.PROPS_ADDRESS][
                                constants.PROPS_COUNTRY_GUID
                            ],
                        })}
                        onChange={(value) =>
                            this.props.onCountryChange(
                                {
                                    type: constants.PROPS_COUNRTY_NAME,
                                    guid: constants.PROPS_COUNTRY_GUID,
                                },
                                value,
                                index,
                                this.props.requestState
                            )
                        }
                        clearable={false}
                        required
                        disabled={disabled}
                    />
                    <TextInput
                        tabIndex={0}
                        maxLength={50}
                        placeholderText={formatMessage(messages.addressName)}
                        labelText={formatMessage(messages.addressName)}
                        onChange={(value) =>
                            this.props.onAddressChange(
                                {
                                    type: [constants.PROPS_ADDRESS_NAME],
                                },
                                value,
                                index
                            )
                        }
                        value={
                            this.props.address[index][constants.PROPS_ADDRESS][
                                constants.PROPS_ADDRESS_NAME
                            ]
                        }
                        disabled={disabled}
                    />
                </SubSection>
            </Section>
        );
    }
}

export default injectIntl(AddressItem);
