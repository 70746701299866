import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";

import { SystemAPI, APIError } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid } from "~/login/selectors";
import { setApiResult } from "~/core/api/actions";

export function* fetchSystemSettings(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield SystemAPI.getSystemSettings({ UserGuid });
        if (response) {
            yield put(actions.fetchSystemSettingsSuccess(response));
        } else {
            yield put(actions.fetchSystemSettingsFailed("No Data"));
        }
    } catch (error) {
        yield put(actions.fetchSystemSettingsFailed({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchSystemSettings() {
    yield takeEvery(actions.fetchSystemSettings, fetchSystemSettings);
}

export function* updateSystemSettings(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            yield SystemAPI.updateSystemSettings(requestOptions);
            yield put(actions.updateSystemSettingsSuccess());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
            yield put(actions.updateSystemSettingsFailed({ error }));
        }
    } else {
        yield put(actions.updateSystemSettingsFailed("No Data"));
    }
}

export function* watchUpdateSystemSettings() {
    yield takeEvery(actions.updateSystemSettings, updateSystemSettings);
}

const SystemProfileSagas = function* () {
    yield all([fork(watchFetchSystemSettings), fork(watchUpdateSystemSettings)]);
};

export default SystemProfileSagas;
