import { State } from "./reducer";

export const MESSAGING_STATE_KEY = "MESSAGING_STATE";

const getModuleState = (state: Record<string, any>): State => state[MESSAGING_STATE_KEY];

export const getReceivers = (state: Record<string, unknown>): any =>
    getModuleState(state).receivers;

export const getHost = (state: Record<string, unknown>): any => getModuleState(state).host;
