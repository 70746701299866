import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import { MapTools } from "./map-tools/";
import { MapControl, selectors } from "./map-control";
import { Loader, LoaderTypes } from "~/core";

import "./map-container.css";

export class MapContainer extends Component {
    static propTypes = {
        basemap: PropTypes.string,
        isCanvasLoading: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            getFieldsLayer: null,
            getFieldBoundaryImportLayer: null,
            getImportDataConvexHullLayer: null,
        };
    }

    _setMapRef({ map, getFieldBoundaryImportLayer, getFieldsLayer, getImportDataConvexHullLayer }) {
        this.setState(
            {
                map,
                getFieldBoundaryImportLayer,
                getFieldsLayer,
                getImportDataConvexHullLayer,
            },
            this.forceUpdate
        );
    }

    render() {
        return (
            <div className={classnames("map-container", `${this.props.basemap}-basemap`)}>
                {!(this.props.isLoading || this.props.isCanvasLoading) ? null : (
                    <Loader type={LoaderTypes.BALL_SCALE_MULTIPLE} />
                )}
                {this.state.map == null ? null : (
                    <MapTools
                        map={this.state.map}
                        getFieldsLayer={this.state.getFieldsLayer}
                        getFieldBoundaryImportLayer={this.state.getFieldBoundaryImportLayer}
                        getImportDataConvexHullLayer={this.state.getImportDataConvexHullLayer}
                    />
                )}
                <MapControl onSetMap={(e) => this._setMapRef(e)} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        basemap: selectors.getBasemap(state),
        isLoading: selectors.getIsLoading(state),
        isCanvasLoading: selectors.getIsCanvasLoading(state),
    };
};

export default connect(mapStateToProps)(MapContainer);
