import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import * as loginActions from "~/login/actions";
import { SetupAPI } from "@ai360/core";
import { Toolset } from "@ai360/core";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { actions as messagingActions } from "../../../messaging";

const _requestUnitLists = function (userGuid) {
    return new Promise((resolve) => {
        const promises = [];
        promises.push(SetupAPI.getUnitAgBytesList(userGuid, "name", "Area"));
        promises.push(SetupAPI.getUnitAgBytesList(userGuid, "name", "Length"));
        Promise.all(promises).then((units) => {
            resolve({
                areaUnits: units[0],
                lengthUnits: units[1],
            });
        });
    });
};

const checkIfFieldEditGeometriesChanged = function* (action) {
    if (action.payload.geometries == null) {
        return;
    }
    const toolset = yield select(selectors.getActiveToolset);
    const isChanged = yield select(selectors.getFieldEditsChanged);
    const isLoaded = yield select(selectors.getFieldEditsLoaded);

    if (
        toolset !== Toolset.DEFAULT &&
        !isChanged &&
        (action.payload.geometries.length > 0 || isLoaded)
    ) {
        if (!isLoaded) {
            yield put(actions.setFieldEditsLoaded(true));
        } else {
            yield put(actions.setFieldEditsChanged(true));
        }
    } else if (toolset === Toolset.DEFAULT && (isChanged || isLoaded)) {
        yield put(actions.setFieldEditsChanged(false));
        yield put(actions.setFieldEditsLoaded(false));
    }
};

const getStarted = function* (action) {
    yield call(getUnitLists, action);
};

const getUnitLists = function* (action) {
    try {
        const unitLists = yield call(_requestUnitLists, action.theUser.userGuid);
        yield put(actions.unitsFetchSucceeded(unitLists.areaUnits, unitLists.lengthUnits));
    } catch (e) {
        yield put(actions.unitsFetchFailed(e.message));
    }
};

const messageSubscriptions = function* () {
    yield put(
        messagingActions.subscribe(1000, {
            eventName: "processCounterAction",
            actionAccumulate: actions.processCounterMessage,
        })
    );
};

export function* mapToolsSaga() {
    yield all([
        messageSubscriptions(),
        takeLatest(loginActions.SET_USER_INFO_COMPLETE, getStarted),
        takeEvery(actions.SET_FIELD_EDIT_GEOMETRIES, checkIfFieldEditGeometriesChanged),
    ]);
}
