import { IAccordionState } from "~/accordion/model";
import { ACTION_PANEL_STATE_KEY } from "~/action-panel/selectors";

import { COMMON_STATE_KEY } from "../reducer";
import { DRAG_AND_DROP_FILE_UPLOADER_DATA_STATE_KEY } from "./reducer";
import * as models from "./models";

const _getModuleState = (state) =>
    state[ACTION_PANEL_STATE_KEY][COMMON_STATE_KEY][DRAG_AND_DROP_FILE_UPLOADER_DATA_STATE_KEY];

export const getModuleState = (state: any, stateKey: string): models.IDragDropFileUploaderState => {
    const moduleState: models.IDragDropFileUploaderCoreState = _getModuleState(state);
    return moduleState[stateKey];
};

export const getAccordionState = (state: any, stateKey: string): IAccordionState | null => {
    const childState = getModuleState(state, stateKey);
    return childState[models.ACCORDION_KEY];
};
