import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import * as model from "./model";
import defaultProductPackageRecord from "./default-record";
import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";

// Model name
export const MODEL_NAME = "productPackageList";

export const REQUEST_PACKAGE_UNIT = apiUrl("AgBytes/GetUnitAgBytesList");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "productPackageFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "productPackageSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "productPackagePageOptions";
export const REQUEST_PAYLOAD_PARENT_GUID = "productGuid";

// URLs
export const URL = apiUrl("AgBytes/GetProductPackageList");
export const NEXT_ID = apiUrl("AgBytes/GetNextPackageId");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetProductPackageAutoSearchList");
export const GETRECORD = apiUrl("AgBytes/GetProductPackage");
export const CREATE = apiUrl("AgBytes/AddProductPackage");
export const UPDATE = apiUrl("AgBytes/UpdateProductPackage");
export const DELETE = apiUrl("AgBytes/DeleteProductPackage");

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        productPackageId: "",
        longDescription: "",
        packageSize: "",
        packageUnitName: "",
        isActive: "",
        canDelete: "",
        modifiedDate: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_PARENT_GUID]: "",
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "ProductPackageId",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const dropdowns = {
    [model.PROPS_PACKAGE_UNIT_NAME]: {
        url: REQUEST_PACKAGE_UNIT,
        model: {
            sortField: "name",
            unitType: "package",
        },
    },
};

// Service
export const service = createService({
    id: model.PROPS_PRODUCT_PACKAGE_ID,
    guid: model.PROPS_PRODUCT_PACKAGE_GUID,
    parentGuid: model.PROPS_PRODUCT_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    dropdowns,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    REQUEST_PAYLOAD_PARENT_GUID,
    urls: {
        URL,
        NEXT_ID,
        AUTO_SEARCH_URL,
        GETRECORD,
        CREATE,
        UPDATE,
        DELETE,
    },
    _defaultLabels: {
        [model.PROPS_PRODUCT_PACKAGE_ID]: {
            label: "productPackageId",
            gridCol: 5,
        },
        [model.PROPS_LONG_DESCRIPTION]: {
            label: "longDescription",
            gridCol: 30,
        },
        [model.PROPS_PACKAGE_SIZE]: { label: "packageSize", gridCol: 10 },
        [model.PROPS_PACKAGE_UNIT_NAME]: {
            label: "packageUnitName",
            gridCol: 10,
            sortNameOverRide: "packageUnitName",
        },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: { label: "canDelete", gridCol: 10 },
    },
    getDefaultRecord: () => ({ ...defaultProductPackageRecord() }),
    defaultSort,
});
