import React, { useEffect } from "react";
import { injectIntl, intlShape } from "react-intl";
import moment from "moment";
import PropTypes from "prop-types";

import { SelectInput, TextInput } from "~/core";
import { selectors as picklistSelectors, picklistNames } from "~/core/picklist";
import { model } from "~/reports/data";
import { setReportDataValue } from "~/reports/data/actions";
import { getReportDataItem } from "~/reports/data/selectors";
import { messages } from "~/reports/i18n-messages";
import { useAppDispatch, useAppSelector } from "~/store/hooks";

interface ICroppingSeasonProps {
    disabled?: boolean;
    intl?: intlShape;
    reportType: string;
    reportDataKey?: string;
    required?: boolean;
    valuesToDisplay?: string[];
}

function CroppingSeason({
    disabled = false,
    intl: { formatMessage },
    reportType,
    reportDataKey = model.PROPS_CROPPING_SEASON_GUID,
    required = true,
    valuesToDisplay = [],
}: ICroppingSeasonProps) {
    const dispatch = useAppDispatch();
    const croppingSeasonGuid = useAppSelector(getReportDataItem(reportType, reportDataKey));
    const seasons = useAppSelector((state) =>
        picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        )
    );

    useEffect(() => {
        if (seasons.length > 0 && croppingSeasonGuid == null) {
            const currentYear = moment(new Date(), "DD/MM/YYYY").year();
            const selectedSeason =
                seasons && seasons.filter((season) => season.label === `${currentYear}`);
            const selectedSeasonValue = selectedSeason.length > 0 ? selectedSeason[0].value : null;
            dispatch(
                setReportDataValue({
                    key: reportDataKey,
                    value: selectedSeasonValue,
                    reportType,
                })
            );
        }
    }, [croppingSeasonGuid, dispatch, reportDataKey, reportType, seasons]);

    if (!croppingSeasonGuid) return null;

    return valuesToDisplay.length === 0 ? (
        <SelectInput
            disabled={disabled}
            clearable={false}
            options={seasons}
            onChange={(value) => {
                dispatch(
                    setReportDataValue({
                        key: reportDataKey,
                        value,
                        reportType,
                    })
                );
            }}
            placeholderText={formatMessage(messages.season)}
            required={required}
            value={croppingSeasonGuid}
        />
    ) : (
        <TextInput
            disabled={disabled}
            required={required}
            placeholderText={formatMessage(messages.season)}
            value={valuesToDisplay.join(", ")}
        />
    );
}

CroppingSeason.propTypes = {
    disabled: PropTypes.bool,
    intl: intlShape,
    reportType: PropTypes.string.isRequired,
    reportDataKey: PropTypes.string,
    required: PropTypes.bool,
    valuesToDisplay: PropTypes.array,
};

export default injectIntl(CroppingSeason) as React.FC<ICroppingSeasonProps>;
