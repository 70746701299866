import { createSelector } from "reselect";

import { ACTION_PANEL_STATE_KEY } from "../../../selectors";

import { COMMON_STATE_KEY } from "../reducer";
import { AvailableProduct } from "./model";
import { PRODUCT_BLEND_STATE_KEY } from "./reducer";

export const getModuleState = (state) =>
    state[ACTION_PANEL_STATE_KEY][COMMON_STATE_KEY][PRODUCT_BLEND_STATE_KEY];

export const getAvailableCredits = (state) => getModuleState(state).availableCredits;
export const getAvailableProducts = (state): AvailableProduct[] =>
    getModuleState(state).availableProducts;
export const getBatchCreditedRecNutrients = (state) =>
    getModuleState(state).batchCreditedRecNutrients;
export const getConversionFactors = (state) => getModuleState(state).conversionFactors;
export const getCreditedGridCells = (state) => getModuleState(state).creditedGridCells;
export const getCreditedRecNutrient = (state) => getModuleState(state).creditedRecNutrient;
export const getCustomBlends = (state) => getModuleState(state).customBlends;
export const getCustomProducts = (state) => getModuleState(state).customProducts;
export const getDefaultCarrierGuid = (state) => getModuleState(state).defaultCarrierGuid;
export const getDefaultChemicalTargetRate = (state) =>
    getModuleState(state).defaultChemicalTargetRate;
export const getDefaultProductMixTypeGuid = (state) =>
    getModuleState(state).defaultProductMixTypeGuid;
export const getEventRecCustomProductGuids = (state) =>
    getModuleState(state).eventRecCustomProductGuids;
export const getFilteredProductBlendPicklists = (state) =>
    getModuleState(state).filteredProductBlendPicklists;
export const getIsLoading = (state) => getModuleState(state).isLoading;
export const getProductBlendGuid = (state) => getModuleState(state).productBlendGuid;
export const getProductBlendPicklists = (state) => getModuleState(state).productBlendPicklists;
export const getProductFilters = (state) => getModuleState(state).productFilters;

export const getCarrierProducts = createSelector(getAvailableProducts, (availableProducts) => {
    return availableProducts
        .filter((product) => {
            return product.productParentType === "Fertilizer" && product.physicalState === "Liquid";
        })
        .map((product) => ({
            label: product.name,
            value: product.productGuid,
        }));
});

export const getFilteredProducts = createSelector(
    getAvailableProducts,
    getProductFilters,
    (availableProducts, productFilters) => {
        return availableProducts
            .filter((product) => {
                const productParentTypeMatch =
                    !productFilters.productParentTypeGuid ||
                    product.productParentTypeGuid === productFilters.productParentTypeGuid;
                const productTypeMatch =
                    !productFilters.productTypeGuid ||
                    product.productTypeGuidList.includes(productFilters.productTypeGuid);
                const manufacturerMatch =
                    !productFilters.manufacturerGuid ||
                    product.manufacturerGuid === productFilters.manufacturerGuid;
                const physicalStateMatch =
                    !productFilters.physicalStateGuid ||
                    product.physicalStateGuid === productFilters.physicalStateGuid;
                return (
                    productParentTypeMatch &&
                    productTypeMatch &&
                    manufacturerMatch &&
                    physicalStateMatch
                );
            })
            .map((product) => ({
                label: product.name,
                value: product.productGuid,
            }));
    }
);
