import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { getTheUserGuid } from "~/login";
import { fetchState } from "./data/actions";
import { getCountryStateList } from "./data/selectors";
import { getCountryGuid } from "~/admin/utils";
import { messages } from "../../i18n-messages";
import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";

// Components
import { ZeroToInfinite, Section, DialogBox } from "~/core";
import AddressItem from "./address-item";

// Constants
import * as constants from "./constants";
import { defaultValue } from "./default-records";

// Style
import "./address.css";
import "./address-responsive.css";

export const ADDRESS_ERROR_CODE_LIST = [4, 5, 7, 8];
export const SHIP_TO = "Ship To";

export class Address extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        UserGuid: PropTypes.string,
        address: PropTypes.array,
        needs: PropTypes.func,
        fetchState: PropTypes.func,
        addressList: PropTypes.array,
        stateList: PropTypes.array,
        addressTypeList: PropTypes.array,
        countriesList: PropTypes.array,
        addEditPanel: PropTypes.object,
        onChildComponentChange: PropTypes.func,
        mapSelectedCountry: PropTypes.func,
        countryStateList: PropTypes.object,
        apiErrors: PropTypes.array,
        addressPropKey: PropTypes.string,
        forceUpdate: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            address: [defaultValue()],
            countryGuid: "",
            deleteWarn: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps[constants.PROPS_COUNTRY_LIST] !== this.props[constants.PROPS_COUNTRY_LIST]) {
            const { address } = this.state;
            let countryGuid;
            const guid = getCountryGuid({
                options: nextProps[constants.PROPS_COUNTRY_LIST],
            });
            const addressKey = this.props.addressPropKey || constants.PROPS_COMPONENT_KEY;
            if (guid && !address[0][constants.PROPS_ADDRESS][constants.PROPS_COUNTRY_GUID]) {
                address[0][constants.PROPS_ADDRESS][constants.PROPS_COUNTRY_GUID] = guid;
                countryGuid = guid;
                this.setState(
                    {
                        address,
                        countryGuid,
                    },
                    () => this.props.onChildComponentChange(addressKey, this.state.address)
                );
            }
        }
        if (
            (nextProps.addEditPanel.mode === "EDIT" || this.props.forceUpdate) &&
            nextProps.addressList !== this.state.address
        ) {
            if (nextProps.addressList !== this.props.addressList) {
                this.setState(
                    {
                        address: nextProps.addressList.length
                            ? nextProps.addressList
                            : [Object.assign({ isDefault: true }, defaultValue())],
                    },
                    () => {
                        this.state.address.forEach((value) => {
                            if (!value.isDefault) {
                                this.requestState(
                                    value[constants.PROPS_ADDRESS][constants.PROPS_COUNTRY_GUID]
                                );
                            }
                        });
                    }
                );
            }
        }
        if (nextProps.apiErrors !== this.props.apiErrors) {
            const containsError = ADDRESS_ERROR_CODE_LIST.some((errorCode) =>
                nextProps.apiErrors.includes(errorCode)
            );
            if (containsError) {
                const { address } = this.state;
                address.forEach((record) => {
                    record.isError = containsError;
                });
                this.setState({ address });
            }
        }
    }

    addItem = (e, items, index) => {
        if (index !== 0) {
            items[index][constants.PROPS_ADDRESS_IS_PRIMARY] = false;
        }
        this.setState({ address: items });
    };

    onDelete = (e, items) => {
        if (!items.some(({ isPrimary }) => isPrimary)) {
            items[0][constants.PROPS_ADDRESS_IS_PRIMARY] = true;
        }
        this.setState({ address: items });
    };

    requestState = (guid) => {
        if (guid) {
            this.props.needs([this.props.fetchState(guid)]);
        } else {
            console.error(`${guid} value is null`);
        }
    };

    onAddressTypeChange = ({ type, guid }, value, index) => {
        const { address } = this.state;
        const addressKey = this.props.addressPropKey || constants.PROPS_COMPONENT_KEY;
        if (!value) {
            address[index][type] = "";
            address[index][guid] = "";
        } else if (type || guid) {
            if (guid) {
                address[index][guid] = value.guid;
            }
            if (type) {
                address[index][type] = value.name;
            }
        }
        this.setState({ address }, () =>
            this.props.onChildComponentChange(addressKey, this.state.address)
        );
    };

    onCountryChange = ({ type, guid }, value, index, cb) => {
        const { address } = this.state;
        const addressKey = this.props.addressPropKey || constants.PROPS_COMPONENT_KEY;
        if (type && guid) {
            address[index][constants.PROPS_ADDRESS][guid] = value.guid;
            address[index][constants.PROPS_ADDRESS][type] = value.name;
            if (typeof cb === "function") {
                cb(value.guid);
            }
        }
        this.setState({ address }, () => this.props.onChildComponentChange(addressKey, address));
    };

    onAddressChange = ({ type }, value, index) => {
        const { address } = this.state;
        if (type) {
            address[index][constants.PROPS_ADDRESS][type] = value;
        }
        const addressKey = this.props.addressPropKey || constants.PROPS_COMPONENT_KEY;
        this.setState({ address }, () =>
            this.props.onChildComponentChange(addressKey, this.state.address)
        );
    };

    onStateChange = ({ guid }, value, index) => {
        const { address } = this.state;
        if (guid) {
            address[index][constants.PROPS_ADDRESS][guid] = value.guid;
        }
        const addressKey = this.props.addressPropKey || constants.PROPS_COMPONENT_KEY;
        this.setState({ address }, () =>
            this.props.onChildComponentChange(addressKey, this.state.address)
        );
    };

    onPrimaryChange = (value, index) => {
        const { address } = this.state;
        const addressKey = this.props.addressPropKey || constants.PROPS_COMPONENT_KEY;
        address.forEach((data, i) => {
            data[constants.PROPS_ADDRESS_IS_PRIMARY] = index === i ? value : false;
        });
        this.setState({ address, deleteWarn: false }, () =>
            this.props.onChildComponentChange(addressKey, this.state.address)
        );
    };

    renderDeleteModal = () => {
        const { deleteWarn } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <DialogBox
                isOpen={deleteWarn}
                title={formatMessage(mainMessages.alertTitle)}
                onClose={() => this.setState({ deleteWarn: false })}
            >
                {formatMessage(messages.addressWarn)}
            </DialogBox>
        );
    };

    deleteWarn = (index) => {
        const { address } = this.state;
        if (address.length > 2 && address[index][constants.PROPS_ADDRESS_IS_PRIMARY]) {
            this.setState({ deleteWarn: true });
            return false;
        }
        this.setState({ deleteWarn: false });
        return true;
    };

    render() {
        const { formatMessage } = this.props.intl;
        const defaultRecords = defaultValue();
        const initialRecords = Object.assign({}, defaultRecords, {
            [constants.PROPS_ADDRESS]: {
                ...defaultRecords[constants.PROPS_ADDRESS],
                [constants.PROPS_COUNTRY_GUID]: this.state.countryGuid,
            },
        });
        const {
            requestState,
            onAddressTypeChange,
            onCountryChange,
            onStateChange,
            onPrimaryChange,
            onAddressChange,
            deleteWarn,
        } = this;
        const { address } = this.state;
        const {
            countryStateList,
            mapSelectedCountry,
            addressTypeList,
            countriesList,
            stateList,
            apiErrors,
            disabled,
        } = this.props;
        const props = {
            requestState,
            onAddressTypeChange,
            onAddressChange,
            onCountryChange,
            onStateChange,
            onPrimaryChange,
            countryStateList,
            addressTypeList,
            countriesList,
            mapSelectedCountry,
            stateList,
            address,
            apiErrors,
            disabled,
        };
        return (
            <Section headerText={formatMessage(messages.addressHeading)} required>
                <ZeroToInfinite
                    items={address}
                    initialValue={initialRecords}
                    getChildProps={() => null}
                    onDelete={this.onDelete}
                    addItem={this.addItem}
                    addText={createAddLinkLabelText(formatMessage, messages.address)}
                    deleteText={formatMessage(mainMessages.delete)}
                    formatMessage={formatMessage}
                    deleteWarn={deleteWarn}
                    required
                >
                    <AddressItem {...props} />
                </ZeroToInfinite>
                {this.renderDeleteModal()}
            </Section>
        );
    }
}

const mapStateToProps = (state) => ({
    UserGuid: getTheUserGuid(state),
    countryStateList: getCountryStateList(state),
});
const mapDispatchToProps = (dispatch) => {
    return {
        fetchState: (v) => dispatch(fetchState(v)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Address));
