import { createSelector } from "reselect";
import { ANALYSIS_STATE_KEY, getZonesState, RECS_EVENTS_DATA_KEY } from "../selectors";
import { AnalysisLayerAPI } from "@ai360/core";

export const getModuleState = (state: Record<string, unknown>): any => {
    return state[RECS_EVENTS_DATA_KEY][ANALYSIS_STATE_KEY];
};

export const getAnalysisDetailsErrorCodeList = (state: Record<string, unknown>): any => {
    return getModuleState(state).analysisDetailsErrorCodeList;
};

export const getAnalysisErrorCodes = (state: Record<string, unknown>): any => {
    return getModuleState(state).analysisErrorCodesList;
};

export const getAnalysisLayerTypes = (state: Record<string, unknown>): any => {
    return getModuleState(state).analysisLayerTypes;
};

export const getFieldGuidToAnalysisSummaryMap = (state: Record<string, unknown>): any => {
    return getModuleState(state).fieldGuidToAnalysisSummaryMap;
};

export const getCurrentAnalysisArea = (
    state: Record<string, unknown>,
    analysisLayerAreaList: AnalysisLayerAPI.IAnalysisLayerZone[]
): AnalysisLayerAPI.IAnalysisLayerZone => {
    const { fieldGuidToCurrentAreaId } = getZonesState(state);
    const analysisAreaId =
        fieldGuidToCurrentAreaId == null || fieldGuidToCurrentAreaId.size === 0
            ? null
            : fieldGuidToCurrentAreaId.values().next().value;
    if (analysisAreaId == null || analysisLayerAreaList == null) {
        return null;
    }
    return analysisLayerAreaList.find(
        (analysisArea) => analysisArea.analysisLayerAreaId === analysisAreaId
    );
};

export const getManagementAreaIdToHasDataMap: (state, fieldGuid) => Map<number, boolean> =
    createSelector(
        (state, fieldGuid) => {
            return getFieldGuidToAnalysisSummaryMap(state).get(fieldGuid);
        },
        (analysisSummary): Map<number, boolean> => {
            return (
                analysisSummary &&
                new Map(
                    analysisSummary.analysisLayerAreaList.map((analysisArea): [number, boolean] => [
                        analysisArea.analysisLayerAreaId,
                        analysisArea.applyAnalysisToArea,
                    ])
                )
            );
        }
    );
