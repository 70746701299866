import React, { useEffect, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import { APIError } from "@ai360/core";

import { DialogBox, DialogBoxFooterType } from "~/core";
import { resetApiResult, setApiResult } from "~/core/api/actions";
import { getApiError } from "~/core/api/selectors";
import { messages } from "~/i18n-messages";
import { useAppDispatch, useAppSelector } from "~/store/hooks";

import useApiErrors from "../hooks/use-api-errors";
import "./with-report-errors.css";
import { ErrorDialog } from "~/notifications/components/err-dialog";

const WithReportErrors = (View, actionCallback = null) => {
    interface IWithReportErrorsProps {
        needs: (actions: any[]) => void;
        intl: intlShape;
        View: React.ComponentType<any>;
    }

    function ApiResult(props: IWithReportErrorsProps) {
        const { intl, needs } = props;
        const dispatch = useAppDispatch();
        const apiError = useAppSelector(getApiError);

        const [results, setResults] = useState({
            errorMessageCount: 0,
            legacyErrorMessages: [],
            newApiErrorMessages: [],
            serverErrorApiResultObject: null,
        });
        useEffect(() => {
            setResults(useApiErrors(apiError, intl));
        }, [apiError, intl]);

        const {
            errorMessageCount,
            legacyErrorMessages,
            newApiErrorMessages,
            serverErrorApiResultObject,
        } = results;

        const onResetApiResult = () => dispatch(resetApiResult());

        const onSetApiResult = (payload) => dispatch(setApiResult(payload));

        const onConfirmClick = () => {
            if (actionCallback) {
                needs([actionCallback()]);
            }
            onResetApiResult();
        };

        return (
            <div className="content-table-container api-result">
                <DialogBox
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={errorMessageCount > 0}
                    onAction={onConfirmClick}
                    onClose={onResetApiResult}
                    title={intl.formatMessage(messages.errorsTitle, { count: errorMessageCount })}
                >
                    {legacyErrorMessages || newApiErrorMessages ? (
                        <div className="results-container">
                            {legacyErrorMessages.map((item, index) => (
                                <div
                                    className="detail-item"
                                    key={`legacy_${index}`}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                ></div>
                            ))}
                            {newApiErrorMessages.map((item, index) => (
                                <div
                                    className="detail-item"
                                    key={`new_api_${index}`}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                ></div>
                            ))}
                        </div>
                    ) : null}
                </DialogBox>
                <ErrorDialog
                    isOpen={!!serverErrorApiResultObject}
                    err={new APIError("", serverErrorApiResultObject)}
                    intl={intl}
                    retryAction={null}
                    onRetry={() => null}
                    onClose={onResetApiResult}
                />
                <View
                    {...props}
                    actionCallback={(payload) => actionCallback(payload)}
                    resetApiResult={onResetApiResult}
                    setApiResult={onSetApiResult}
                />
            </div>
        );
    }

    ApiResult.propTypes = {
        intl: intlShape,
        View: PropTypes.object,
    };

    return injectIntl(ApiResult);
};

export default WithReportErrors;
