import { createReducer } from "redux-act";
import {
    clearFilteredLocationList,
    fetchCompanyListSuccess,
    fetchFilteredLocationListSuccess,
} from "./actions";

export const SURFACE_DEFAULTS_DATA_KEY = "SURFACE_DEFAULTS_DATA";
const initialState = {
    companyList: null,
    locationList: null,
};

export const surfaceDefaultsReducer = createReducer(
    {
        [clearFilteredLocationList]: (state) => ({
            ...state,
            filteredLocationList: [],
        }),
        [fetchCompanyListSuccess]: (state, { companyList }) => ({
            ...state,
            companyList,
        }),
        [fetchFilteredLocationListSuccess]: (state, { filteredLocationList }) => ({
            ...state,
            filteredLocationList,
        }),
    },
    initialState
);
