import { createReducer } from "redux-act";
import {
    fetchAttributesSuccess,
    fetchTreeAttributesSuccess,
    fetchLocationHierarchySuccess,
    fetchLocationHierarchyEntitiesSuccess,
    requestSearchStringSuccess,
    fetchEntitiesSuccess,
    fetchUserCompanyListSuccess,
} from "./actions";

export const BATCH_ZAPPER_DATA_KEY = "BATCH_ZAPPER_DATA";
const initialState = {
    attributes: [],
    attributesTree: [],
    filteredLocations: [],
    filteredUserRoles: [],
    filteredUsers: [],
    lastUsedOwner: null,
    locations: [],
    userCompanyList: null,
    userRoles: [],
    users: [],
};

export const batchZapperReducer = createReducer(
    {
        [fetchLocationHierarchyEntitiesSuccess]: (state, { response }) => ({
            ...state,
            locations: response,
        }),
        [fetchLocationHierarchySuccess]: (state, { response }) => ({
            ...state,
            filteredLocations: response,
        }),
        [requestSearchStringSuccess]: (state, payload) => ({
            ...state,
            ...payload,
        }),
        [fetchEntitiesSuccess]: (state, payload) => ({
            ...state,
            ...payload,
        }),
        [fetchAttributesSuccess]: (state, { response }) => ({
            ...state,
            attributes: response,
        }),
        [fetchTreeAttributesSuccess]: (state, { response }) => ({
            ...state,
            attributes: response,
        }),
        [fetchUserCompanyListSuccess]: (state, { userCompanyList, lastUsedOwner }) => ({
            ...state,
            userCompanyList,
            lastUsedOwner,
        }),
    },
    initialState
);
