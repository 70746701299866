import { ACTION_PANEL_STATE_KEY } from "../../../../../selectors";
import { LAYER_MODULE_STATE_KEY } from "../../../selectors";
import { LAYER_LIST_STATE_KEY } from "../selectors";

import { selectors as accordionSelectors } from "~/accordion";

export const ACCORDION_KEY = "NON_FIELD_DATA_ACCORDION";

export const getAccordionState = (state) =>
    state[ACTION_PANEL_STATE_KEY][LAYER_MODULE_STATE_KEY][LAYER_LIST_STATE_KEY][ACCORDION_KEY];

export const getId = (state) => getAccordionState(state).accordionId;
export const getItems = (state) => accordionSelectors.itemsSelector(getAccordionState(state));
export const getItemsCount = (state) =>
    accordionSelectors.itemsSelector(getAccordionState(state)).length;
export const getRecHeight = (state) => getAccordionState(state).recHeight;
export const getRecItemCount = (state) => getAccordionState(state).recItemCount;
export const getScrollTop = () => 0;

export const getDimIdxMapSelector = (state) =>
    accordionSelectors.dimIdxMapSelector(getAccordionState(state));
export const getFlatIdxMapSelector = (state) =>
    accordionSelectors.flatIdxMapSelector(getAccordionState(state));
