import React from "react";
import { connect } from "react-redux";

import { FilterTabs } from "../../../../../module-tabs/filter-tabs";

import { setRecFilter } from "../../actions";
import { getModuleState } from "../../selectors";

import { TabsContextMenu } from "./tabs-context-menu-container";

import "./rec-filter-tabs.css";

const mapStateToProps = (state) => {
    const recModuleState = getModuleState(state);
    return {
        isSelectedFilterActive: recModuleState.filter.onlySelected,
        activeTab: recModuleState.filter.activeTab,
        hasSelectedItems:
            recModuleState.selectedRecGuidSet && recModuleState.selectedRecGuidSet.size > 0,
        contextMenu: <TabsContextMenu />,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetSelectedFilterActive: (onlySelected, activeTab) =>
            dispatch(setRecFilter({ onlySelected, activeTab })),
    };
};

export const RecFilterTabs = connect(mapStateToProps, mapDispatchToProps)(FilterTabs);
