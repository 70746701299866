import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../i18n-messages";
import { SelectInput, Loader, LoaderTypes } from "~/core";
import { AgvanceUtils } from "../customer/agvance-utils.js";
import { AppHelpers } from "@ai360/core";
import { SearchIcon } from "~/core/icons";
import classnames from "classnames";

// Styles
import "./component.css";

const searchIcon = (
    <span key="search" className="search-icon">
        <SearchIcon className="search-icon" />
    </span>
);

export const loadingIndicator = (
    <div className="agvance-search-loading-container">
        {" "}
        <Loader type={LoaderTypes.LINE_SCALE_PARTY} />{" "}
    </div>
);

export const noOptionRenderer = ({
    formatMessage,
    isAgvanceCustomerListOutOfBound,
    initialValue,
}) => {
    return !initialValue ? (
        isAgvanceCustomerListOutOfBound ? (
            <span className="default-option">{formatMessage(messages.searchMustBeAtleast)}</span>
        ) : null
    ) : (
        <span className="default-option">
            {formatMessage(messages.noResultsMatch, { value: initialValue })}
        </span>
    );
};

class SearchAgvanceCustomer extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        companyGuid: PropTypes.string,
        customerList: PropTypes.array,
        customerSearchIsLoading: PropTypes.bool,
        initialValue: PropTypes.string,
        intl: intlShape.isRequired,
        onApiCallErr: PropTypes.func,
        onInputChange: PropTypes.func,
        onSelection: PropTypes.func,
        personalityId: PropTypes.any,
        placeholderText: PropTypes.string,
        userGuid: PropTypes.string,
        isAgvanceCustomerListOutOfBound: PropTypes.bool,
        valueTitle: PropTypes.string,
    };

    static defaultProps = {
        autoFocus: false,
        className: "",
        onInputChange: () => null,
    };

    static PERSON_NAME = "growerName";
    static PERSON_CITY = "city";
    static PERSON_GUID = "agvanceGuid";
    static PERSON_STATE_ABR = "state";
    static IS_DUPLICATE = "isDuplicate";
    static ORG_LEVEL_NAME = "orgLevelName";

    constructor(props) {
        super(props);
        this.state = {
            initialValue: "",
            customerList: this.formSearchData(props.customerList),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialValue !== this.props.initialValue) {
            this.setState({
                initialValue: nextProps.initialValue,
            });
        }
        if (nextProps.customerList !== this.props.customerList) {
            this.setState({
                customerList: this.formSearchData(nextProps.customerList),
            });
        }
    }

    onItemSelection = (guid) => {
        if (this.props.onSelection) {
            this.props.onSelection(guid);
        }
    };

    formDisplayAddress = (record) => {
        if (!record) {
            return null;
        }
        const { billToShipToAddresses } = record;
        const shippingAddress = billToShipToAddresses
            ? billToShipToAddresses.find((address) => address.addressType === "S")
            : null;
        let displayAddress = record.address1;
        if (AgvanceUtils.isAgvanceStandard(this.props.personalityId)) {
            displayAddress = shippingAddress
                ? AppHelpers.isNothing(shippingAddress.address4)
                    ? AppHelpers.isNothing(shippingAddress.address3)
                        ? shippingAddress.address2
                        : shippingAddress.address3
                    : shippingAddress.address4
                : AppHelpers.isNothing(record.address3)
                ? AppHelpers.isNothing(record.address2)
                    ? record.address1
                    : record.address2
                : record.address3;
        }
        return displayAddress;
    };

    formCityAndState = (record) => {
        if (!record) {
            return null;
        }
        const { billToShipToAddresses } = record;
        const shippingAddress = billToShipToAddresses
            ? billToShipToAddresses.find((address) => address.addressType === "S")
            : null;
        return shippingAddress
            ? [
                  shippingAddress[SearchAgvanceCustomer.PERSON_CITY],
                  shippingAddress[SearchAgvanceCustomer.PERSON_STATE_ABR],
              ].join(", ")
            : [
                  record[SearchAgvanceCustomer.PERSON_CITY],
                  record[SearchAgvanceCustomer.PERSON_STATE_ABR],
              ].join(", ");
    };

    formSearchData = (customerList) => {
        return customerList.map((record) => ({
            ...record,
            label: record[SearchAgvanceCustomer.PERSON_NAME],
        }));
    };

    onInputChange = (initialValue) => {
        this.props.onInputChange(initialValue);
        this.setState({ initialValue });
    };

    optionRenderer = ({ option, isSelected, isHighlighted }) => {
        const className = classnames(
            "select-form-input-option",
            {
                selected: isSelected,
                "filter-match": isHighlighted,
            },
            "search-person-options"
        );
        const cityAndState = this.formCityAndState(option);
        const locationText = option.isDuplicate
            ? `, Location - ${option[SearchAgvanceCustomer.ORG_LEVEL_NAME]}`
            : null;
        const addressText = this.formDisplayAddress(option);
        return (
            <div
                className={className}
                title={`${
                    option[SearchAgvanceCustomer.PERSON_NAME]
                }, ${addressText}, ${cityAndState}, [${option.alternateCustomerIdSelecting}]`}
            >
                <div>{option[SearchAgvanceCustomer.PERSON_NAME]}</div>
                <div className="person-state">
                    {cityAndState} {locationText}
                </div>
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { placeholderText, isAgvanceCustomerListOutOfBound } = this.props;
        const { initialValue } = this.state;
        return (
            <SelectInput
                required
                {...this.props}
                clearFilterInputOnBlur={false}
                initialFilterStr={this.state.initialValue}
                value={this.state.initialValue}
                placeholderText={
                    placeholderText ||
                    formatMessage(messages.searchFor, {
                        searchedFor: formatMessage(messages.customer),
                    })
                }
                inputContainerLeftElements={[searchIcon]}
                inputContainerRightElements={
                    this.props.customerSearchIsLoading ? [loadingIndicator] : null
                }
                arrowRenderer={() => null}
                onChange={(val) => this.onItemSelection(val)}
                onInputChange={(val) => this.onInputChange(val)}
                optionRenderer={this.optionRenderer}
                optionHeight={42}
                valueKey={SearchAgvanceCustomer.PERSON_GUID}
                valueTitle={this.props.valueTitle}
                containerClassNames={["person-filter", this.props.className]}
                options={this.state.customerList}
                preFixinputContainerRightElements
                noOptionsRenderer={() =>
                    noOptionRenderer({
                        formatMessage,
                        isAgvanceCustomerListOutOfBound,
                        initialValue,
                    })
                }
                maxHeight={500}
            />
        );
    }
}

export default injectIntl(SearchAgvanceCustomer);
