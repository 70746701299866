import React, { Component } from "react";
import { CloseIcon } from "~/core/icons";
import { Loader } from "~/core";
import { pdfUrl } from "@ai360/core";
import { messages } from "../../i18n-messages";

export interface IReportViewerProps {
    data?: Record<string, any>;
    closeReportViewer?: () => void;
    currentThemeName?: string;
    formatMessage?: (message: any) => string;
}

export interface IReportViewerState {
    showLoader: boolean;
    currentThemeName: string;
}

export class ReportViewer extends Component<IReportViewerProps, IReportViewerState> {
    static defaultProps = {
        isPrint: false,
    };

    static LIGHT_THEME = "light";

    constructor(props: IReportViewerProps) {
        super(props);
        this.state = {
            showLoader: true,
            currentThemeName: this.props.currentThemeName,
        };
    }

    private _getThemeFlag = (): number => {
        const { currentThemeName } = this.state;
        if (currentThemeName.includes(ReportViewer.LIGHT_THEME)) {
            return 0;
        }
        return 1;
    };

    public onIframeLoad = (): void => {
        this.setState({
            showLoader: false,
        });
    };

    public getReportViewerUrl = (): string => {
        const { fileName, loggedInUserGuid, reportUserGuid, isPrint } = this.props.data;
        return pdfUrl(
            `/RadPdf/Viewer/?loggedInUserGuid=${loggedInUserGuid}&reportUserGuid=${reportUserGuid}&filename=${fileName}&isPrint=${isPrint}&themeOption=${this._getThemeFlag()}`
        );
    };

    UNSAFE_componentWillReceiveProps(nextProps: IReportViewerProps): void {
        if (nextProps.currentThemeName !== this.props.currentThemeName) {
            this.setState({
                currentThemeName: nextProps.currentThemeName,
            });
        }
    }

    render(): JSX.Element {
        const url = this.getReportViewerUrl();
        return (
            <div className="report-viewer">
                {this.state.showLoader ? <Loader /> : null}
                <iframe title={"report-viewer"} key={url} src={url} onLoad={this.onIframeLoad} />
                <div
                    title={this.props.formatMessage(messages.close)}
                    className="report-viewer-close"
                    onClick={this.props.closeReportViewer}
                >
                    <CloseIcon />
                </div>
            </div>
        );
    }
}
