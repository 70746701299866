import { connect } from "react-redux";
import FuelingEventListView from "./fueling-event-list";
import { getUnit } from "~/admin/selectors";
import * as unitService from "~/core/units/unit-names";

const mapStateToProps = (state) => ({
    [unitService.UNIT_VOLUME]: getUnit(unitService.getUnitCode(unitService.UNIT_VOLUME), state),
});

export const FuelingEventList = connect(mapStateToProps, null)(FuelingEventListView);
