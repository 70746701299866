import { createReducer } from "redux-act";
import { defaultActions } from "./actions";
import { fetchedDropdownData } from "~/core/dropdowns/actions";

export const GRID_DATA_KEY = "GRID_DATA";

const initialState = {
    dropdowns: {},
    orgLevelData: {},
    reportTypeNameData: {},
    userPreference: {},
};

export const gridReducer = createReducer(
    {
        [defaultActions.success]: (state, payload = {}) => {
            const { model, data } = payload;
            return {
                ...state,
                [model]: {
                    ...state[model],
                    ...data,
                },
            };
        },
        [defaultActions.nestedGridsuccess]: (state, payload) => {
            const { model, response } = payload;
            return {
                ...state,
                [model]: {
                    ...state[model],
                    ...response,
                },
            };
        },
        [defaultActions.autoSearchSuccess]: (state, payload) => {
            const { modelName, response, searchName } = payload;
            return {
                ...state,
                [modelName]: {
                    ...state[modelName],
                    autoSearchList: {
                        response,
                        searchName,
                    },
                },
            };
        },
        [defaultActions.nestedAutoSearch]: (state, payload) => {
            const { modelName, response, parentGuid, searchName } = payload;
            return {
                ...state,
                [modelName]: {
                    ...state[modelName],
                    [parentGuid]: {
                        ...state[modelName][parentGuid],
                        autoSearchList: {
                            response,
                            searchName,
                        },
                    },
                },
            };
        },
        [defaultActions.failed]: (state, error) => {
            return {
                ...state,
                error,
            };
        },
        [defaultActions.selectAllSuccess]: (state, payload) => {
            const { modelName, response } = payload;
            return {
                ...state,
                [modelName]: {
                    ...state[modelName],
                    selectAll: response,
                },
            };
        },
        [fetchedDropdownData]: (state, payload) => {
            return {
                ...state,
                dropdowns: {
                    ...state.dropdowns,
                    ...payload,
                },
            };
        },
        [defaultActions.nextIdSuccess]: (state, payload) => {
            const { modelName, response } = payload;
            return {
                ...state,
                [modelName]: {
                    ...state[modelName],
                    nextId: response,
                },
            };
        },
        [defaultActions.createSuccess]: (state) => {
            return {
                ...state,
            };
        },
        [defaultActions.fetchRecordSuccess]: (state, payload) => {
            const { modelName, response } = payload;
            return {
                ...state,
                [modelName]: {
                    ...state[modelName],
                    ...response,
                },
            };
        },
        [defaultActions.setAgrianConversionData]: (state, payload) => {
            return {
                ...state,
                userPreference: {
                    ...state.userPreference,
                    ...payload,
                },
            };
        },
    },
    initialState
);
