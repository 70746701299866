import { ReportAPI } from "@ai360/core";
import { createSelector } from "reselect";
import { selectors as unitSelectors, unitNames } from "~/core/units";
import { getHighlightedNonFieldFeatures } from "~/customer-data/selectors";
import RootState from "~/types/store";
import { IReportType } from "./interfaces";

export interface ISelectorCropData {
    cropGuid?: string;
    cropId?: string;
    cropName?: string;
    activeYn?: boolean;
}

export interface ISelectorCropDataReturn {
    value: {
        cropGuid: string;
    };
    label: string;
    activeYn: boolean;
}
export interface ISelectorParsedTotalingData {
    guid?: string;
    name?: string;
    id?: string;
}

export interface ISelectorParsedTotalingData {
    value: {
        id: string;
        guid: string;
        name: string;
    };
    label: string;
}

export const REPORTS_STATE_KEY = "REPORTS_STATE";

export const getCropData = (state: unknown): any => state[REPORTS_STATE_KEY].cropDropdown;

export const getFileNameFormatData = (state: unknown): any =>
    state[REPORTS_STATE_KEY].fileNameFormatList;

export const getReportData = (state: unknown): any => state[REPORTS_STATE_KEY].reportData;

export const getReportDataByReportType = (reportType: string) =>
    createSelector(
        (state: RootState) => state[REPORTS_STATE_KEY].reportData,
        (reportData) => reportData && reportData[reportType]
    );

export const getReportDataItem = (reportType: string, key: string) =>
    createSelector(
        getReportDataByReportType(reportType),
        (reportData) => reportData && reportData[key]
    );

export const getReportPref = (state: unknown): any => state[REPORTS_STATE_KEY].reportPreferences;

export const getReportTypes = (state: unknown): IReportType[] => state[REPORTS_STATE_KEY].typeList;

export const getReportTypePages = (state: unknown): any => state[REPORTS_STATE_KEY].pageList;

export const getYieldCategories = (state: unknown): any => state[REPORTS_STATE_KEY].yieldCategories;

export const getIsTableLoading = (state: unknown): boolean =>
    state[REPORTS_STATE_KEY].isTableLoading;

export const getYieldByCompareByOptions = (state: unknown): any =>
    state[REPORTS_STATE_KEY].yieldByCompareByOptions;

export const selectUserExportFileNameFormatPreference = (state: unknown): any =>
    state[REPORTS_STATE_KEY].defaultFileNameFormat;

export const selectUserExportMultiFieldRecPreference = (state: unknown): any =>
    state[REPORTS_STATE_KEY].defaultMultiFieldRec;

export const selectNonFieldFeaturePreference = (state: unknown): any =>
    state[REPORTS_STATE_KEY].defaultShowNonFieldFeature;

export const getParsedCropData = (cropData: ISelectorCropData[]): ISelectorCropDataReturn[] => {
    return cropData.map((crop) => {
        const { cropGuid, cropId, cropName, activeYn } = crop;
        return {
            value: {
                cropGuid,
                cropId,
            },
            label: cropName,
            activeYn,
        };
    });
};

export const getCropOptionsData = createSelector(getCropData, (cropData) =>
    getParsedCropData(cropData || [])
);

export const getWeightUnitData = (state: unknown): ISelectorParsedTotalingData[] =>
    unitSelectors.getUnitPicklistFromCode(state, unitNames.getUnitCode(unitNames.UNIT_WEIGHT)) ||
    [];

export const getParsedTotalingData = (
    totalingData: ISelectorParsedTotalingData[]
): ISelectorParsedTotalingData[] => {
    return totalingData.map((item) => {
        const { guid, id, name } = item;
        return {
            value: {
                id,
                guid,
                name,
            },
            label: name,
        };
    });
};

export const getTotalingOptions = createSelector(getWeightUnitData, (totalingData) =>
    getParsedTotalingData(totalingData)
);

export const getReportStatus = (state: unknown): any => state[REPORTS_STATE_KEY].reportStatus;

export const getFields = (state: unknown): ReportAPI.IField[] => state[REPORTS_STATE_KEY].fields;

export const getSelectedNonFieldFeaturesGuids = createSelector(
    getHighlightedNonFieldFeatures,
    (selectedNonFieldFeaturesGuids) => selectedNonFieldFeaturesGuids.map((f) => f.id)
);
