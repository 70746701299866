import { createSelector } from "reselect";
import { MAP_STATE_KEY } from "../../selectors";
import * as nonFieldFeatureInformationSelectors from "~/action-panel/components/layer-module/components/non-field-feature-information/selectors";

export const MAP_CONTROL_STATE_KEY = "MAP_CONTROL_STATE";

const _mapControl = (state) => state[MAP_STATE_KEY][MAP_CONTROL_STATE_KEY];

export const getBasemap = (state) => _mapControl(state).basemap;

export const getCadastralLayerInfos = (state) => _mapControl(state).cadastralLayerInfos;

export const getFieldBoundaryLayerInfo = (state) => _mapControl(state).fieldBoundaryLayerInfo;

export const getFieldsBackgroundOnly = (state) => _mapControl(state).fieldsBackgroundOnly;

export const getFieldsBackgroundOnlyBatch = (state) => _mapControl(state).fieldsBackgroundOnlyBatch;

export const getFilteredPointCount = (state) => _mapControl(state).filteredPointCount;

export const getForceRefreshFlag = (state) => _mapControl(state).forceRefresh;

export const getInvalidatedFields = (state) => _mapControl(state).invalidatedFields;

export const getIsLoading = (state) =>
    !_mapControl(state).mapReady || _mapControl(state).isLoadingCount > 0;

export const getIsCanvasLoading = (state) => _mapControl(state).isCanvasLoading;

export const getNonFieldFeaturesCanShowTooltip = createSelector(
    nonFieldFeatureInformationSelectors.getIsEditing,
    (isEditing) => !isEditing
);

export const getNonFieldFeatureLayerInfo = (state) => _mapControl(state).nonFieldFeatureLayerInfo;

export const getNonFieldFeatureLayerIsVisible = createSelector(
    getNonFieldFeatureLayerInfo,
    (info) => info.visible
);

export const getScale = (state) => _mapControl(state).scale;

export const getSelectedStandardLayersCount = createSelector(
    getFieldBoundaryLayerInfo,
    getCadastralLayerInfos,
    (fbLayerInfo, cLayerInfos) =>
        Number(fbLayerInfo.visible) + cLayerInfos.filter((li) => li.visible).length
);

export const getTotalStandardLayersCount = (state) => 1 + getCadastralLayerInfos(state).length;

export const getZoomLevel = (state) => _mapControl(state).zoom;

export const getZoomToCustomer = (state) => _mapControl(state).zoomToCustomer;

export const getZoomToFarm = (state) => _mapControl(state).zoomToFarm;

export const getZoomToField = (state) => _mapControl(state).zoomToField;

export const getZoomToFieldList = (state) => _mapControl(state).zoomToFieldList;

export const getZoomToNonFieldFeatures = (state) => _mapControl(state).zoomToNonFieldFeatures;
