import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ZoneEditIcon = withSvgIcon(
    <g>
        <path d="M403.8,0v38.1h-48.5v20h48.5v38.1h38.1v48.5h20V96.3H500V0H403.8z M480,76.3h-56.3V20H480V76.3z" />
        <path d="M96.3,403.8H59.4v-48.5h-20v48.5H0V500h96.3v-38.2h48.5v-20H96.3V403.8z M76.3,480H20v-56.3h56.3V480z" />
        <path d="M39.4,144.8h20V96.3h38.1V58.1h47.3v-20H97.5V0H1.3v96.3h38.1V144.8z M21.3,20h56.3v56.3H21.3V20z" />
        <rect x="39.4" y="181.3" width="20" height="51.3" />
        <rect x="39.4" y="269.2" width="20" height="51.3" />
        <rect x="267.5" y="38.1" width="51.3" height="20" />
        <rect x="179.6" y="38.1" width="51.3" height="20" />
        <rect x="441.9" y="181.3" width="20" height="51.3" />
        <path d="M461.9,355.2h-20v48.5h-38.1v38h-48.5v20h48.5V500H500v-96.3h-38.1V355.2z M480,480h-56.3v-56.3H480V480z" />
        <rect x="441.9" y="269.2" width="20" height="51.3" />
        <rect x="267.5" y="441.8" width="51.3" height="20" />
        <rect x="179.6" y="441.8" width="51.3" height="20" />
        <path
            d={
                "M319.1,208.9l-190.7-82.1l64.5,196l49.5-67.4l92.4,94.1l14.3-14l-91.4-93.1L319.1,208.9z M161.2," +
                "162.8l112.2,48.3l-38.9,21.2l-34.8,47.4L161.2,162.8z"
            }
        />
    </g>
);
