import React, { useEffect, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import { defineMessages } from "react-intl";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import { AnalysisIcon } from "./icons/analysis";
import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import { connect } from "react-redux";

const messages = defineMessages({
    analysisFormSideBarText: {
        id: "layerModule.analysisInfo.formUnknownSideBarText",
        defaultMessage: "Analysis",
    },
});

export const formLabelMessage = messages.analysisFormSideBarText;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText], // ErrorCode.AnalysisLayerNameIsRequired
]);

export const errorCodesApply = (errorCodeList = []) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

interface AnalysisUnknownFormProps {
    analysisDetailsErrorCodeList: number[];
    intl: intlShape;
}

const AnalysisUnknownForm = (props: AnalysisUnknownFormProps): JSX.Element => {
    const { analysisDetailsErrorCodeList } = props;
    const { formatMessage } = props.intl;

    //state variables
    const [errorMessagePlaceholderSet, setErrorMessagePlaceholderSet] = useState<Set<any>>(
        new Set()
    );

    useEffect(() => {
        if (!analysisDetailsErrorCodeList) {
            return;
        }

        const newErrorMessagePlaceholderSet = getErrorMessagePlaceholderSet();
        setErrorMessagePlaceholderSet(newErrorMessagePlaceholderSet);
    }, [analysisDetailsErrorCodeList]);

    const getErrorMessagePlaceholderSet = () => {
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    };

    const getAnalysisErrors = (errorMessagePlaceholderSet, formatMessage) => {
        if (errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0) {
            return null;
        }
        const errorMessages = [];
        errorMessagePlaceholderSet.forEach((errorEntry) => {
            errorMessages.push(
                <div className="analysis-error-message">{formatMessage(errorEntry)}</div>
            );
        });
        return errorMessages;
    };

    const analysisErrors = getAnalysisErrors(errorMessagePlaceholderSet, formatMessage);

    return <div className="analysis-info-form">{analysisErrors}</div>;
};

export default connect(null, null, null)(injectIntl(AnalysisUnknownForm));
