import { AgEventAPI } from "@ai360/core";
import { IAgEventModelMethods } from "../model";

export class AgEventScouting implements AgEventAPI.IAgEventScouting, IAgEventModelMethods {
    agEventGuid = "";
    brandOrganizationGuid = "";
    cropConditionGuid = "";
    cropGrowthStageGuid = "";
    cropGuid = "";
    cropHeight = "";
    cropHeightUnitsGuid = "";
    cropInjuryRatingGuid = "";
    cropPopulation = "";
    cropPopulationUnitsGuid = "";
    cropPurposeGuid = "";
    daysAfterCropDamage = "";
    leafPercentLoss = "";
    notes = "";
    scoutingDetailList: AgEventAPI.IScoutingDetail[] = [];
    sitePhotoList: AgEventAPI.IScoutingSitePhoto[] = [];
    standPercentLoss = "";
    varietyHybridGuid = "";
    yieldPercentLoss = "";
    recommendation = "";
    noIssuesFound = false;

    get hasCropDetails(): boolean {
        // Note: Units properties have intentionally been left out - if a unit (only) is
        // set, then that doesn't constitute enough detail to be saved.
        //TODO: Reconcile differences of using integer vs. string for IScoutingDetail props and IAgEventScouting props
        //So that props can be compared properly and ignores can be removed
        return (
            Boolean(this.cropConditionGuid) ||
            Boolean(this.cropGrowthStageGuid) ||
            Boolean(this.cropHeight) ||
            Boolean(this.cropInjuryRatingGuid) ||
            Boolean(this.cropPopulation) ||
            Boolean(this.daysAfterCropDamage) ||
            Boolean(this.leafPercentLoss) ||
            Boolean(this.notes) ||
            Boolean(this.standPercentLoss) ||
            Boolean(this.sitePhotoList && this.sitePhotoList.length > 0) ||
            Boolean(this.yieldPercentLoss)
        );
    }

    get hasScoutingDetails(): boolean {
        if (!this.scoutingDetailList || this.scoutingDetailList.length === 0) {
            return false;
        }
        // Note: Units properties have intentionally been left out - if a unit (only) is
        // set, then that doesn't constitute enough detail to be saved.
        return this.scoutingDetailList.some(
            (detail) =>
                Boolean(detail.observationGuid) &&
                (Boolean(detail.densityRatingGuid) ||
                    Boolean(detail.growthStageGuid) ||
                    Boolean(detail.observationCount || `${detail.observationCount}` === "0") ||
                    Boolean(detail.photoList && detail.photoList.length > 0) ||
                    Boolean(detail.plantLocationGuid) ||
                    Boolean(detail.trapID) ||
                    Boolean(detail.weedHeight || `${detail.weedHeight}` === "0"))
        );
    }

    get isAllRequiredFieldsSet(): boolean {
        return (
            this.cropGuid &&
            this.cropGuid !== "" &&
            (this.hasCropDetails || this.hasScoutingDetails || this.noIssuesFound)
        );
    }

    get isAnyRequiredFieldSet(): boolean {
        return Boolean(this.cropGuid !== "" || this.hasCropDetails || this.hasScoutingDetails);
    }

    updateAgEventModel(newProps: Partial<AgEventAPI.IAgEventModel>): AgEventScouting {
        return Object.assign(new AgEventScouting(), this, newProps);
    }
}
