import { AgEventAPI } from "@ai360/core";

export class ScoutingDetailPhoto implements AgEventAPI.IScoutingDetailPhoto {
    constructor(
        public photo: string | null,
        public fullsizePhoto?: string | null,
        public eventScoutingDetailPhotoGuid?: string | null,
        public notes?: string | null,
        public mediaTypeGuid?: string | null
    ) {}
}
