export const FETCH_DASHBOARD_LIST = "analytics/FETCH_DASHBOARD_LIST";
export const FETCH_DASHBOARD_LIST_SUCCESS = "analytics/FETCH_DASHBOARD_LIST_SUCCESS";
export const FETCH_DASHBOARD_URL = "analytics/FETCH_DASHBOARD_URL";
export const FETCH_DASHBOARD_URL_SUCCESS = "analytics/FETCH_DASHBOARD_URL_SUCCESS";
export const FETCH_DASHBOARD_URL_FAILURE = "analytics/FETCH_DASHBOARD_URL_FAILURE";
export const FETCH_ORG_LEVEL_LIST = "analytics/FETCH_ORG_LEVEL_LIST";
export const FETCH_ORG_LEVEL_LIST_SUCCESS = "analytics/FETCH_ORG_LEVEL_LIST_SUCCESS";

export const fetchAnalyticDashboardTypes = () => ({
    type: FETCH_DASHBOARD_LIST,
});

export const fetchAnalyticDashboardTypesSuccess = (response) => ({
    type: FETCH_DASHBOARD_LIST_SUCCESS,
    payload: { response },
});

export const fetchDashboardUrl = (analyticDashboardTypeGuid) => ({
    type: FETCH_DASHBOARD_URL,
    payload: { analyticDashboardTypeGuid },
});

export const fetchDashboardUrlSuccess = (response) => ({
    type: FETCH_DASHBOARD_URL_SUCCESS,
    payload: { response },
});

export const fetchOrgLevelList = () => ({
    type: FETCH_ORG_LEVEL_LIST,
});

export const fetchOrgLevelListSuccess = (list) => ({
    type: FETCH_ORG_LEVEL_LIST_SUCCESS,
    payload: list,
});
