import { FileImportAPI } from "@ai360/core";

export const RX_FILE_IMPORT_STATE_KEY = "RX_FILE_IMPORT";
export const RX_FILE_IMPORT_TYPE_NAME = "RX File Import";

export type RXFileImportCurrentStep = "upload" | "match" | "complete";

export interface RXFileImportSelectableColumnValue {
    column: string;
    importFileGuid?: string;
}

export interface RXFileImportSelectableColumn {
    label: string;
    value: RXFileImportSelectableColumnValue;
}

export interface RXFileImportSelectedColumn {
    column: RXFileImportSelectableColumnValue;
    recNutrientEquationName: string;
}

export interface IRXFileImportState {
    currentStep: RXFileImportCurrentStep;
    filesValidated: boolean;
    isLoading: boolean;
    matchData: FileImportAPI.ImportProductRecFieldMatchingResponse;
    matchDataCurrent: boolean;
    selectedColumns: RXFileImportSelectedColumn[];
}
