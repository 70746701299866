import { createAction } from "redux-act";

const FETCH_AFFILIATIONS_PARENT_DATA = "FETCH_AFFILIATIONS_PARENT_DATA";
const FETCH_AFFILIATIONS_PARENT_SUCCESS = "FETCH_AFFILIATIONS_PARENT_SUCCESS";
const FETCH_AFFILIATIONS_PARENT_FAILED = "FETCH_AFFILIATIONS_PARENT_FAILED";
const FETCH_COMPANY_RECORD_DATA = "FETCH_COMPANY_RECORD_DATA";
const FETCH_COMPANY_RECORD_SUCCESS = "FETCH_COMPANY_RECORD_SUCCESS";
const FETCH_COMPANY_RECORD_FAILED = "FETCH_COMPANY_RECORD_FAILED";
const FETCH_ORG_LEVEL_DATA = "FETCH_ORG_LEVEL_DATA";
const FETCH_ORG_LEVEL_SUCCESS = "FETCH_ORG_LEVEL_SUCCESS";
const FETCH_ORG_LEVEL_FAILED = "FETCH_ORG_LEVEL_FAILED";
const FETCH_HIERARCHY_FILTER_LIST = "FETCH_HIERARCHY_FILTER_LIST";
const FETCH_HIERARCHY_FILTER_SUCCESS = "FETCH_HIERARCHY_FILTER_SUCCESS";
const FETCH_HIERARCHY_FILTER_FAILED = "FETCH_HIERARCHY_FILTER_FAILED";
const FETCH_ACTIVE_ORG_TYPE_NAME = "FETCH_ACTIVE_ORG_TYPE_NAME_DATA";
const FETCH_ACTIVE_ORG_TYPE_NAME_SUCCESS = "FETCH_ACTIVE_ORG_TYPE_NAME_SUCCESS";
const FETCH_ACTIVE_ORG_TYPE_NAME_FAILED = "FETCH_ACTIVE_ORG_TYPE_NAME_FAILED";
const ADD_ORG_LEVEL = "ADD_ORG_LEVEL_DATA";
const ADD_ORG_LEVEL_SUCCESS = "ADD_ORG_LEVEL_SUCCESS";
const ADD_ORG_LEVEL_FAILED = "ADD_ORG_LEVEL_FAILED";
const UPDATE_ORG_LEVEL = "UPDATE_ORG_LEVEL_DATA";
const UPDATE_ORG_LEVEL_SUCCESS = "UPDATE_ORG_LEVEL_SUCCESS";
const UPDATE_ORG_LEVEL_FAILED = "UPDATE_ORG_LEVEL_FAILED";
const GET_ORG_LEVEL = "GET_ORG_LEVEL_DATA";
const GET_ORG_LEVEL_SUCCESS = "GET_ORG_LEVEL_SUCCESS";
const GET_ORG_LEVEL_FAILED = "GET_ORG_LEVEL_FAILED";
const FETCH_ORG_LEVEL_EQUIPMENT_LIST = "FETCH_ORG_LEVEL_EQUIPMENT_LIST_DATA";
const FETCH_ORG_LEVEL_EQUIPMENT_LIST_SUCCESS = "FETCH_ORG_LEVEL_EQUIPMENT_LIST_SUCCESS";
const FETCH_ORG_LEVEL_EQUIPMENT_LIST_FAILED = "FETCH_ORG_LEVEL_EQUIPMENT_LIST_FAILED";
const SAVE_EQUIPMENT = "SAVE_EQUIPMENT_DATA";
const SAVE_EQUIPMENT_SUCCESS = "SAVE_EQUIPMENT_SUCCESS";
const SAVE_EQUIPMENT_FAILED = "SAVE_EQUIPMENT_FAILED";
const FETCH_ORG_LEVEL_WITH_GUID_DATA = "FETCH_ORG_LEVEL_WITH_GUID_DATA";
const FETCH_ORG_LEVEL_WITH_GUID_SUCCESS = "FETCH_ORG_LEVEL_WITH_GUID_SUCCESS";
const FETCH_ORG_LEVEL_WITH_GUID_FAILED = "FETCH_ORG_LEVEL_WITH_GUID_FAILED";
const FETCH_ORG_LEVEL_CHILDREN_DATA = "FETCH_ORG_LEVEL_CHILDREN_DATA";
const FETCH_ORG_LEVEL_CHILDREN_SUCCESS = "FETCH_ORG_LEVEL_CHILDREN_SUCCESS";
const FETCH_ORG_LEVEL_CHILDREN_FAILED = "FETCH_ORG_LEVEL_CHILDREN_FAILED";
const SET_NEW_PARENT_LOCATION = "SET_NEW_PARENT_LOCATION";
const SET_NEW_PARENT_SUCCESS = "SET_NEW_PARENT_SUCCESS";
const SET_NEW_PARENT_FAILED = "SET_NEW_PARENT_FAILED";
const UPDATE_OWNER = "UPDATE_OWNER_DATA";
const UPDATE_OWNER_SUCCESS = "UPDATE_OWNER_SUCCESS";
const UPDATE_OWNER_FAILED = "UPDATE_OWNER_FAILED";
const GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID = "GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID_DATA";
const GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID_SUCCESS =
    "GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID_SUCCESS";
const GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID_FAILED =
    "GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID_FAILED";
const GET_AGVANCE_LOCATIONS = "GET_AGVANCE_LOCATIONS_DATA";
const GET_AGVANCE_LOCATIONS_SUCCESS = "GET_AGVANCE_LOCATIONS_SUCCESS";
const GET_AGVANCE_LOCATIONS_FAILED = "GET_AGVANCE_LOCATIONS_FAILED";

export const getAffiliationsParent = createAction(FETCH_AFFILIATIONS_PARENT_DATA);
export const getAffiliationsParentSuccess = createAction(FETCH_AFFILIATIONS_PARENT_SUCCESS);
export const getAffiliationsParentFailed = createAction(FETCH_AFFILIATIONS_PARENT_FAILED);

export const fetchCompanyRecord = createAction(FETCH_COMPANY_RECORD_DATA);
export const fetchCompanyRecordSuccess = createAction(FETCH_COMPANY_RECORD_SUCCESS);
export const fetchCompanyRecordFailed = createAction(FETCH_COMPANY_RECORD_FAILED);

export const getOrgLevelData = createAction(FETCH_ORG_LEVEL_DATA);
export const getOrgLevelDataSuccess = createAction(FETCH_ORG_LEVEL_SUCCESS);
export const getOrgLevelDataFailed = createAction(FETCH_ORG_LEVEL_FAILED);

export const getHierarchyFilterListData = createAction(FETCH_HIERARCHY_FILTER_LIST);
export const getHierarchyFilterListSuccess = createAction(FETCH_HIERARCHY_FILTER_SUCCESS);
export const getHierarchyFilterListFailed = createAction(FETCH_HIERARCHY_FILTER_FAILED);

export const getActiveOrgTypeName = createAction(FETCH_ACTIVE_ORG_TYPE_NAME);
export const getActiveOrgTypeNameSuccess = createAction(FETCH_ACTIVE_ORG_TYPE_NAME_SUCCESS);
export const getActiveOrgTypeNameFailed = createAction(FETCH_ACTIVE_ORG_TYPE_NAME_FAILED);

export const addOrgLevel = createAction(ADD_ORG_LEVEL);
export const addOrgLevelSuccess = createAction(ADD_ORG_LEVEL_SUCCESS);
export const addOrgLevelFailed = createAction(ADD_ORG_LEVEL_FAILED);

export const updateOrgLevel = createAction(UPDATE_ORG_LEVEL);
export const updateOrgLevelSuccess = createAction(UPDATE_ORG_LEVEL_SUCCESS);
export const updateOrgLevelFailed = createAction(UPDATE_ORG_LEVEL_FAILED);

export const getOrgLevel = createAction(GET_ORG_LEVEL);
export const getOrgLevelSuccess = createAction(GET_ORG_LEVEL_SUCCESS);
export const getOrgLevelFailed = createAction(GET_ORG_LEVEL_FAILED);

export const fetchOrgLevelEquipmentList = createAction(FETCH_ORG_LEVEL_EQUIPMENT_LIST);
export const fetchOrgLevelEquipmentListSuccess = createAction(
    FETCH_ORG_LEVEL_EQUIPMENT_LIST_SUCCESS
);
export const fetchOrgLevelEquipmentListFailed = createAction(FETCH_ORG_LEVEL_EQUIPMENT_LIST_FAILED);

export const createEquipment = createAction(SAVE_EQUIPMENT);
export const createEquipmentSuccess = createAction(SAVE_EQUIPMENT_SUCCESS);
export const createEquipmentFailed = createAction(SAVE_EQUIPMENT_FAILED);

export const getOrgLevelWithGuidData = createAction(FETCH_ORG_LEVEL_WITH_GUID_DATA);
export const getOrgLevelWithGuidSuccess = createAction(FETCH_ORG_LEVEL_WITH_GUID_SUCCESS);
export const getOrgLevelWithGuidFailed = createAction(FETCH_ORG_LEVEL_WITH_GUID_FAILED);

export const getOrgLevelChildrenData = createAction(FETCH_ORG_LEVEL_CHILDREN_DATA);
export const getOrgLevelChildrenSuccess = createAction(FETCH_ORG_LEVEL_CHILDREN_SUCCESS);
export const getOrgLevelChildrenFailed = createAction(FETCH_ORG_LEVEL_CHILDREN_FAILED);

export const setNewParentLocation = createAction(SET_NEW_PARENT_LOCATION);
export const setNewParentSuccess = createAction(SET_NEW_PARENT_SUCCESS);
export const setNewParentFailed = createAction(SET_NEW_PARENT_FAILED);

export const updateOwner = createAction(UPDATE_OWNER);
export const updateOwnerSuccess = createAction(UPDATE_OWNER_SUCCESS);
export const updateOwnerFailed = createAction(UPDATE_OWNER_FAILED);

export const getOrgLevelWithAgvanceDatabaseId = createAction(
    GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID
);
export const getOrgLevelWithAgvanceDatabaseIdSuccess = createAction(
    GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID_SUCCESS
);
export const getOrgLevelWithAgvanceDatabaseIdFailed = createAction(
    GET_ORG_LEVEL_WITH_AGVANCE_DATABASE_ID_FAILED
);

export const getAgvanceLocations = createAction(GET_AGVANCE_LOCATIONS);
export const getAgvanceLocationsSuccess = createAction(GET_AGVANCE_LOCATIONS_SUCCESS);
export const getAgvanceLocationsFailed = createAction(GET_AGVANCE_LOCATIONS_FAILED);
