import { defineMessages } from "react-intl";

export const messages = defineMessages({
    accept: { id: "button.accept", defaultMessage: "Accept" },
    backButtonText: { id: "button.backButtonText", defaultMessage: "Back" },
    blockquote: { id: "button.blockquote", defaultMessage: "Blockquote" },
    bold: { id: "button.bold", defaultMessage: "Bold" },
    cancelButtonText: {
        id: "button.cancelButtonText",
        defaultMessage: "Cancel",
    },
    clearFormat: { id: "button.clearFormat", defaultMessage: "Clear Format" },
    closeButtonText: { id: "button.closeButtonText", defaultMessage: "Close" },
    copyButtonText: { id: "button.copyButtonText", defaultMessage: "Copy" },
    copyRoleButtonText: {
        id: "button.copyRoleButtonText",
        defaultMessage: "Copy Role",
    },
    createReportsText: {
        id: "button.createReportsText",
        defaultMessage: "Create Report",
    },
    deleteButtonText: {
        id: "button.deleteButtonText",
        defaultMessage: "Delete",
    },
    doneButtonText: { id: "button.doneButtonText", defaultMessage: "Done" },
    header: { id: "button.header", defaultMessage: "Header" },
    image: { id: "button.image", defaultMessage: "Image" },
    import: { id: "button.import", defaultMessage: "Import" },
    italic: { id: "button.italic", defaultMessage: "Italic" },
    link: { id: "button.link", defaultMessage: "Link" },
    loginButtonText: { id: "button.loginButtonText", defaultMessage: "Login" },
    nextButtonText: { id: "button.nextButtonText", defaultMessage: "Next" },
    migrateButtonText: {
        id: "button.migrateButtonText",
        defaultMessage: "Migrate",
    },
    noButtonText: { id: "button.noButtonText", defaultMessage: "No" },
    noOptions: { id: "button.noOptions", defaultMessage: "No Options" },
    noResultsMatch: {
        id: "button.noResultsMatch",
        defaultMessage: 'No results match "{typedVal}"',
    },
    okButtonText: { id: "button.okButtonText", defaultMessage: "Ok" },
    orderList: { id: "button.orderList", defaultMessage: "Order List" },
    resendVerificationButtonText: {
        id: "button.resendVerificationButtonText",
        defaultMessage: "Resend Code",
    },
    runButtonText: { id: "button.runButtonText", defaultMessage: "Run" },
    saveButtonText: { id: "button.saveButtonText", defaultMessage: "Save" },
    selectFile: { id: "button.selectFile", defaultMessage: "Select File" },
    sendButtonText: { id: "button.sendButtonText", defaultMessage: "Send" },
    sendAuthenticationButtonText: {
        id: "button.sendAuthenticationButtonText",
        defaultMessage: "Send Authentication",
    },
    sendVerificationButtonText: {
        id: "button.sendVerificationButtonText",
        defaultMessage: "Send Verification Code",
    },
    strike: { id: "button.strike", defaultMessage: "Strike" },
    submitButtonText: {
        id: "button.submitButtonText",
        defaultMessage: "Submit",
    },
    test: { id: "button.test", defaultMessage: "Test" },
    unorderList: { id: "button.unorderList", defaultMessage: "Unorder List" },
    uploadButtonText: {
        id: "button.uploadButtonText",
        defaultMessage: "Upload",
    },
    validate: { id: "button.validate", defaultMessage: "Validate" },
    verify: { id: "button.verify", defaultMessage: "Verify" },
    viewHtml: { id: "button.viewHtml", defaultMessage: "View Html" },
    yesButtonText: { id: "button.yesButtonText", defaultMessage: "Yes" },
});
