import { defineMessages } from "react-intl";

export const messages = defineMessages({
    abortErrorMsg: {
        id: "notifications.abortErrorMsg",
        defaultMessage:
            "A promise with no rejection handler was aborted. No further action is necessary.",
    },
    clientSideErrorHdrTxt: {
        id: "notifications.errModal.clientSideErrorHdrTxt",
        defaultMessage: "An unexpected error has occurred.",
    },
    defaultApiErrorMsg: {
        id: "notification.defaultApiErrorMsg",
        defaultMessage: "Unexpected Error",
    },
    findFieldError: {
        id: "notification.findFieldError",
        defaultMessage: "Field no longer exists or is on different Owner",
    },
    warningApiErrorMsg: {
        id: "notification.warningApiErrorMsg",
        defaultMessage: "Validation Issue",
    },
    modalTitle: {
        id: "notifications.errModal.title",
        defaultMessage: "Error",
    },
    warningModalTitle: {
        id: "notifications.warningModalTitle.title",
        defaultMessage: "Warning",
    },
    mustRefreshErrorTxt: {
        id: "notifications.errModal.mustRefreshErrorTxt",
        defaultMessage: "An error has occurred that requires the browser to be refreshed.",
    },
    noNotifications: {
        id: "notification.noNotifications",
        defaultMessage: "No Recent Notifications",
    },
    refreshBtnTxt: {
        id: "notification.refreshBtnTxt",
        defaultMessage: "Refresh",
    },
    retryLinkLabel: {
        id: "notification.retryLinkLabel",
        defaultMessage: "Retry",
    },
    serverErrorDetailsHeader: {
        id: "notifications.errModal.serverErrorDetailsHeader",
        defaultMessage: "Server Error Details",
    },
    serverSideErrorHdrTxt: {
        id: "notifications.errModal.serverSideErrorHdrTxt",
        defaultMessage: "Error communicating with the server.",
    },
    showErrDetailsLinkLabel: {
        id: "notification.showErrDetailsLinkLabel",
        defaultMessage: "Show Details",
    },
    today: {
        id: "notification.today",
        defaultMessage: "Today",
    },
    yesterday: {
        id: "notification.yesterday",
        defaultMessage: "Yesterday",
    },
    reportReady: {
        id: "notification.reportReady",
        defaultMessage: "Your report(s) are ready",
    },
    reportClick: {
        id: "notification.reportClick",
        defaultMessage: "Click the following link(s) to view your report(s)",
    },
    reportComplete: {
        id: "notification.reportComplete",
        defaultMessage: "Your report(s) are ready: {fileName}",
    },
    reportFailed: {
        id: "notification.reportFailed",
        defaultMessage: "The following report(s) failed to an error: {fileName}",
    },
    reportFailedClick: {
        id: "notification.reportFailedClick",
        defaultMessage: "The following report(s) failed to an error",
    },
    reportFailedTitle: {
        id: "notification.reportFailedTitle",
        defaultMessage: "Your report(s) failed",
    },
    samplingReady: {
        id: "notification.samplingReady",
        defaultMessage: "Test results successfully imported",
    },
    waterSamplingReady: {
        id: "notification.waterSamplingReady",
        defaultMessage: "Water sampling test results successfully imported",
    },
    samplingClick: {
        id: "notification.samplingClick",
        defaultMessage: "Successfully imported test results for the following field",
    },
    waterSamplingClick: {
        id: "notification.waterSamplingClick",
        defaultMessage: "Successfully imported test results for the following Non-Field Feature",
    },
    samplingResultsFailedTitle: {
        id: "notification.samplingResultsFailedTitle",
        defaultMessage: "Test results failed",
    },
    samplingResultsToast: {
        id: "notification.samplingResultsToast",
        defaultMessage: "Test results imported for field: {fileName}",
    },
    thisWeek: {
        id: "notification.thisWeek",
        defaultMessage: "This Week",
    },
    thirtyDays: {
        id: "notification.thirtyDays",
        defaultMessage: "Last 30 Days",
    },
});
