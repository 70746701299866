import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const PolygonIcon = withSvgIcon(
    <g id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.097" y="8.646" width="1.5" height="6" />
        <rect x="1.097" y="16.646" width="1.5" height="6" />
        <rect x="27.497" y="10.649" width="1.5" height="6" />
        <polygon points="2.597 24.646 1.097 24.646 1.097 28.997 4.247 28.997 4.247 27.497 2.597 27.497 2.597 24.646" />
        <rect x="14.247" y="27.497" width="6" height="1.5" />
        <rect x="21.049" y="1.097" width="6" height="1.5" />
        <rect x="27.497" y="18.649" width="1.5" height="6" />
        <rect x="6.247" y="27.497" width="6" height="1.5" />
        <rect x="13.049" y="1.097" width="6" height="1.5" />
        <polygon points="27.497 27.497 22.247 27.497 22.247 28.997 28.997 28.997 28.997 26.649 27.497 26.649 27.497 27.497" />
        <rect x="5.049" y="1.097" width="6" height="1.5" />
        <rect x="27.497" y="2.649" width="1.5" height="6" />
        <path
            d="M2.489.641a1.845,1.845,0,0,0-.751,3.532V7.287h1.5V4.173A1.845,1.845,0,0,0,2.489.641Zm0,2.7a.873.873,0,0,1-.848-.847.832.832,0,0,1,.1-.375.844.844,0,0,1,.376-.376.832.832,0,0,1,.375-.1.831.831,0,0,1,.374.1.841.841,0,0,1-.374,1.6Z"
            transform="translate(-0.641 -0.641)"
        />
    </g>,
    "0 0 28.997 28.997",
    "no-stroke"
);
