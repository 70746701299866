import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./import-wizard-progress-bar.css";

export class ImportWizardProgressBar extends Component {
    static propTypes = {
        stepList: PropTypes.array.isRequired,
        currentStep: PropTypes.number.isRequired,
    };

    render() {
        const steps = this.props.stepList.map((step) => {
            const isActive = step.id === this.props.currentStep;
            const stopSymbol = isActive ? (
                <div className="progress-stop-symbol progress-stop-active">
                    <div className="progress-active-symbol">&#x29bf;</div>
                </div>
            ) : this.props.currentStep > step.id ? (
                <div className="progress-stop-symbol progress-stop-complete">
                    <div className="progress-complete-symbol">&#x2713;</div>
                </div>
            ) : (
                <div className="progress-stop-symbol"></div>
            );

            const progressSection =
                step.id === 0 ? null : (
                    <div
                        className={classNames("progress-section", {
                            "progress-section-complete": this.props.currentStep >= step.id,
                        })}
                    />
                );

            return (
                <div key={step.id} className="progress-section-container">
                    {progressSection}
                    <div className="progress-stop-container">
                        <div className="progress-stop">{stopSymbol}</div>
                        <div
                            className={classNames("progress-stop-label", {
                                "progress-label-active": isActive,
                            })}
                        >
                            {step.label}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div
                className={classNames("import-wizard-progress-bar", {
                    "single-step": steps.length === 1,
                })}
            >
                {steps}
            </div>
        );
    }
}
