import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./customer-item.css";

export const CustomerItem = (props) => {
    const itemClassNames = classNames({
        "customer-item": true,
        "is-not-expanded": !props.isExpanded,
    });

    return (
        <div className={itemClassNames} onClick={props.onClick}>
            <div className="customer-name">{props.name}</div>
        </div>
    );
};

CustomerItem.propTypes = {
    isExpanded: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
};
