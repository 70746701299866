import { eventListReducer } from "./components/event-list/reducer";
import { EVENT_LIST_STATE_KEY } from "./components/event-list/selectors";
import { eventInfoReducer } from "./components/event-info/reducer";
import { EVENT_INFO_STATE_KEY } from "./components/event-info/selectors";

import * as actions from "./actions";

const eventModuleInitialState = {
    activePage: actions.EventModulePages.EVENT_LIST,
    triggeredPage: null,
    [EVENT_INFO_STATE_KEY]: eventInfoReducer(undefined, { type: "INIT" }),
    [EVENT_LIST_STATE_KEY]: eventListReducer(undefined, { type: "INIT" }),
};

export const eventModuleReducer = (state = eventModuleInitialState, action) => {
    switch (action.type) {
        case actions.SET_ACTIVE_PAGE: {
            const { activePage } = action.payload;
            return Object.freeze({
                ...state,
                activePage,
            });
        }
        case actions.SET_TRIGGERED_PAGE: {
            const { triggeredPage } = action.payload;
            return Object.freeze({
                ...state,
                triggeredPage,
            });
        }
        default: {
            const eventInfoState = eventInfoReducer(state[EVENT_INFO_STATE_KEY], action);
            if (eventInfoState !== state[EVENT_INFO_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [EVENT_INFO_STATE_KEY]: eventInfoState,
                });
            }
            const eventListState = eventListReducer(state[EVENT_LIST_STATE_KEY], action);
            if (eventListState !== state[EVENT_LIST_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [EVENT_LIST_STATE_KEY]: eventListState,
                });
            }
            return state;
        }
    }
};
