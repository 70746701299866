import { connect } from "react-redux";
import { getUser } from "~/login";
import { selectors as fileImportSelectors } from "~/file-import";

import { ContextMenu } from "../../../../context-menus/item-selection-context-menu";

import * as selectors from "../../../selectors";
import * as actions from "../../../actions";

import { messages } from "../../i18n-messages";

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectAll: (ifoGuidSet) => {
            dispatch(actions.selectAllImportFileInfoItems(ifoGuidSet));
        },
        onClearSelected: (ifoGuidSet) => {
            dispatch(actions.deselectAllImportFileInfoItems(ifoGuidSet));
        },
        onDeleteSelected: () => {
            dispatch(actions.deleteSelectedImportFileInfoItems([]));
        },
        onCheckDataExchange: () => {
            dispatch(actions.checkDataExchange());
        },
    };
};

const mapStateToProps = (state) => {
    const { canDeleteStatusCodes } = selectors.getModuleState(state);
    const getIfoGuidSet = () => new Set(fileImportSelectors.getImportFiles(state).keys());
    const getSelectedIfoGuidSet = () => selectors.getSelectedImportFileInfoGuidSet(state);

    const getStatusCode = (ifoGuid) =>
        fileImportSelectors.getImportFileInfo(state, ifoGuid).statusCode;

    const telematicsCounts = fileImportSelectors.getTelematicsCounts(state);
    const isTelematicsProcessing = fileImportSelectors.getIsTelematicsProcessing(state);
    const isTelematicsUser = fileImportSelectors.getIsTelematicsUser(state);
    const userRole = getUser(state).role;

    return {
        canDeleteStatusCodes,
        deleteConfirmationMessage: messages.deleteFileConfirmation,
        getClearSelectedArg: getIfoGuidSet,
        getClearSelectedEnabled: () => getSelectedIfoGuidSet().size > 0,
        getSelectAllArg: getIfoGuidSet,
        getSelectAllEnabled: () => getSelectedIfoGuidSet().size !== getIfoGuidSet().size,
        getSelectedIfoGuidSet,
        getStatusCode,
        isTelematicsProcessing,
        isTelematicsUser,
        telematicsCounts,
        userRole,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getDeleteSelectedEnabled: () => {
        const canDeleteStatusCodesSet = new Set(stateProps.canDeleteStatusCodes);
        const selectedIfoGuidSet = stateProps.getSelectedIfoGuidSet();
        return (
            selectedIfoGuidSet.size > 0 &&
            Array.from(selectedIfoGuidSet).every((ifoGuid) =>
                canDeleteStatusCodesSet.has(stateProps.getStatusCode(ifoGuid))
            )
        );
    },
    modifyMenuItems: (menuItems, props) => {
        const { formatMessage } = props.intl;

        const dataExchange =
            stateProps.isTelematicsUser === false
                ? null
                : !stateProps.userRole.telematics
                ? null
                : {
                      label: formatMessage(messages.dataExchange),
                      action: () => dispatchProps.onCheckDataExchange(),
                      disabled:
                          stateProps.telematicsCounts.pendingCount > 0 ||
                          stateProps.isTelematicsProcessing,
                  };
        const newMenuItems = [...menuItems];
        if (dataExchange) {
            newMenuItems.push(dataExchange);
        }
        return newMenuItems;
    },
});

export const TabsContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ContextMenu);
