import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    DateInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    NumericInput,
    SelectInput,
    TextArea,
    ZeroToInfiniteGrid,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { mapToPicklistValue, prepareSelectableOptions, formatDate } from "~/admin/utils";
import moment from "moment";
import { messages } from "../../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import TableCell from "~/core/components/tables/data-table/components/table-cell";

const PROPS_DATE_READ = "dateRead";
const PROPS_NOTES = "notes";
const PROPS_TOTAL_AREA = "totalArea";
const PROPS_TOTAL_AREA_UNIT_GUID = "totalAreaUnitGuid";
const PROPS_TOTAL_AREA_UNIT_NAME = "totalAreaUnitName";
const PROPS_USAGE = "usage";
const PROPS_USAGE_UNIT_GUID = "usageUnitGuid";
const PROPS_USAGE_UNIT_NAME = "usageUnitName";

class UsageReadingList extends Component {
    static propTypes = {
        deleteItem: PropTypes.func,
        editData: PropTypes.object,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        formatMessage: PropTypes.func,
        isDeleting: PropTypes.bool,
        isEditing: PropTypes.bool,
        needs: PropTypes.func,
        onAdd: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        options: PropTypes.array,
        record: PropTypes.array,
        renderDeleteModal: PropTypes.func,
        toggleModal: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
            record: [],
            [picklistService.PICKLIST_USAGE_UNIT]: prepareSelectableOptions(
                props[picklistService.PICKLIST_USAGE_UNIT],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [unitService.UNIT_AREA]: prepareSelectableOptions(props[unitService.UNIT_AREA], {
                guid: GUID,
                label: NAME,
            }),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(this.state.record) !== JSON.stringify(this.props.record) &&
            nextProps[picklistService.PICKLIST_USAGE_UNIT] &&
            nextProps[unitService.UNIT_AREA]
        ) {
            this.setState({
                record: this.initializeUsageReadingList(nextProps),
            });
        }

        this.initializeUsageOptions(nextProps);
        this.initializeAreaUnitOptions(nextProps);
    }

    initializeUsageReadingList = (nextProps) => {
        const { record } = nextProps;
        if (record) {
            const usageOptions = nextProps[picklistService.PICKLIST_USAGE_UNIT];
            const totalAreaOptions = nextProps[unitService.UNIT_AREA];

            return record.map((recordItem) => {
                const selectedUsage = usageOptions.find(
                    (usageItem) => usageItem.picklistValueGuid === recordItem[PROPS_USAGE_UNIT_GUID]
                );
                recordItem[PROPS_USAGE_UNIT_NAME] = selectedUsage && selectedUsage[VALUE];
                const selectedAreaUnit = totalAreaOptions.find(
                    (totalAreaItem) => totalAreaItem.guid === recordItem[PROPS_TOTAL_AREA_UNIT_GUID]
                );
                recordItem[PROPS_TOTAL_AREA_UNIT_NAME] =
                    selectedAreaUnit && selectedAreaUnit[VALUE];
                return recordItem;
            });
        }
        return record;
    };

    initializeUsageOptions = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_USAGE_UNIT] !==
            this.props[picklistService.PICKLIST_USAGE_UNIT]
        ) {
            this.setState({
                [picklistService.PICKLIST_USAGE_UNIT]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_USAGE_UNIT],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    initializeAreaUnitOptions = (nextProps) => {
        if (nextProps[unitService.UNIT_AREA] !== this.props[unitService.UNIT_AREA]) {
            this.setState({
                [unitService.UNIT_AREA]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_AREA],
                    { guid: GUID, label: NAME }
                ),
            });
        }
    };

    onEdit = (record, index) => {
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_USAGE_UNIT_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [PROPS_DATE_READ],
                key: NAME,
            },
            {
                name: [PROPS_NOTES],
                key: NAME,
            },
            {
                name: [PROPS_TOTAL_AREA],
                key: NAME,
            },
            {
                name: [PROPS_TOTAL_AREA_UNIT_GUID],
                key: GUID,
            },
            {
                name: [PROPS_USAGE],
                key: NAME,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                action="save"
                className="usage-reading-dialog"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isEditing}
                actionDisabled={
                    !editData[PROPS_DATE_READ] ||
                    !editData[PROPS_USAGE] ||
                    !editData[PROPS_USAGE_UNIT_GUID]
                }
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.usageReading
                )}
            >
                <div className="column-3-gutter-dialog-box">
                    <DateInput
                        required
                        autoFocus
                        onChange={(value) =>
                            onChange({
                                [PROPS_DATE_READ]: formatDate(value, ""),
                            })
                        }
                        value={editData[PROPS_DATE_READ] ? moment(editData[PROPS_DATE_READ]) : null}
                        placeholderText={formatMessage(messages.dateRead)}
                        defaultValue={null}
                    />
                    <NumericInput
                        required
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.usage)}
                        value={editData[PROPS_USAGE]}
                        onChange={(value) => onChange({ [PROPS_USAGE]: value })}
                    />
                    <SelectInput
                        required
                        clearable={false}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[picklistService.PICKLIST_USAGE_UNIT]}
                        value={mapToPicklistValue({
                            options: this.state[picklistService.PICKLIST_USAGE_UNIT],
                            selectedGuid: editData[PROPS_USAGE_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_USAGE_UNIT_GUID]: value.guid,
                                [PROPS_USAGE_UNIT_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.usageUnit)}
                    />
                </div>
                <div className="accessories-group">
                    <NumericInput
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.totalArea)}
                        onChange={(value) => onChange({ [PROPS_TOTAL_AREA]: value })}
                        value={editData[PROPS_TOTAL_AREA]}
                    />
                    <SelectInput
                        clearable={false}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[unitService.UNIT_AREA]}
                        value={mapToPicklistValue({
                            options: this.state[unitService.UNIT_AREA],
                            selectedGuid: editData[PROPS_TOTAL_AREA_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_TOTAL_AREA_UNIT_GUID]: value.guid,
                                [PROPS_TOTAL_AREA_UNIT_NAME]: value.name,
                            });
                        }}
                        placeholderText={formatMessage(messages.totalAreaUnit)}
                    />
                </div>
                <div className="column-3-gutter-dialog-box">
                    <TextArea
                        maxLength={2000}
                        containerClassNames={["usage-reading-textarea"]}
                        onChange={(value) => {
                            onChange({
                                [PROPS_NOTES]: value,
                            });
                        }}
                        placeholderText={formatMessage(messages.notes)}
                        value={editData[PROPS_NOTES]}
                    />
                </div>
            </DialogBox>
        );
    };

    printDateRead = (value) => {
        if (value) {
            const formattedDate = formatDate(value, "M/D/YYYY");
            return (
                <TableCell key="formatted-date-cell" title={formattedDate}>
                    {formattedDate}
                </TableCell>
            );
        }
    };

    render() {
        const { formatMessage } = this.props;
        const { record } = this.state;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_DATE_READ]: {
                                title: formatMessage(messages.dateRead),
                                printer: this.printDateRead,
                            },
                            [PROPS_USAGE]: {
                                title: formatMessage(messages.usage),
                            },
                            [PROPS_USAGE_UNIT_NAME]: {
                                title: formatMessage(messages.usageUnit),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.usageReading)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(UsageReadingList);
