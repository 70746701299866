import React, { Children, Component, ReactNode, ReactElement } from "react";
import classnames from "classnames";

import { Toolbar } from "./toolbar";
import { ToolbarMenuContent } from "./toolbar-menu-content";
import { DownArrowIcon } from "~/core/icons";
import { IToolbarTool } from "@ai360/core";

export interface IActiveTool extends IToolbarTool {
    props?: {
        label?: string;
        icon?: ReactElement;
        className?: string;
    };
}

export interface IToolEvent {
    selected?: boolean;
    tool?: IActiveTool;
}

export interface IToolBarMenuProps {
    _expanded?: boolean;
    _index?: number;
    _onToggle?: (e: MouseEvent) => void;
    children?: ReactNode | ReactNode[];
    className?: string;
    disabled?: boolean;
    icon?: ReactElement;
    label?: string;
    onToggle?: (e: MouseEvent) => void;
    onToggleTool?: (e: MouseEvent) => void;
    rtl?: boolean;
    activeCount?: string | number;
}

export interface IToolBarMenuState {
    activeTool: IActiveTool;
    activeToolIndex: number;
}

export class ToolbarMenu extends Component<IToolBarMenuProps, IToolBarMenuState> {
    static defaultProps = {
        className: "",
        disabled: false,
        label: "Menu",
        rtl: false,
    };

    constructor(props: IToolBarMenuProps) {
        super(props);
        this.state = {
            activeTool: null,
            activeToolIndex: null,
        };
    }

    public activateToolAtIndex(index: number): void {
        this.toggleTool(
            {
                selected: true,
                tool: this.props.children[index],
            },
            index
        );
    }

    public close(): void {
        this.toggle({
            expanded: false,
        });
    }
    public toggle(ev?: MouseEvent | { expanded: boolean }): void {
        const { _expanded, _index, _onToggle, disabled, onToggle } = this.props;
        const event =
            ev != null
                ? ev
                : {
                      expanded: !_expanded,
                  };
        if (!disabled && onToggle) {
            onToggle(event as MouseEvent);
        }
        if (!disabled && _onToggle) {
            _onToggle(
                Object.assign(event as MouseEvent, {
                    index: _index,
                })
            );
        }
    }
    public toggleTool(e: IToolEvent, activeToolIndex = null): void {
        if (!this.props.disabled) {
            this.setState({
                activeTool: e.selected ? e.tool : null,
                activeToolIndex,
            });
            if (this.props.onToggleTool) {
                this.props.onToggleTool(e as MouseEvent);
            }
        }
    }

    render(): ReactElement {
        const { _expanded, className, disabled, rtl } = this.props;
        let { icon, label } = this.props;
        const { activeCount } = this.props;

        const clsNames = ["toolbar-item", "toolbar-menu", className];

        const toolbarItemCountEl =
            typeof activeCount === "undefined" || activeCount === 0 ? null : (
                <div className="toolbar-item-count" title={activeCount as string}>
                    {activeCount}
                </div>
            );

        let content = null;
        if (!disabled && _expanded) {
            const children = Children.map(this.props.children as ReactElement[], (child) => {
                if (child.type === Toolbar && child.props._includeClose == null) {
                    child = React.cloneElement(child, {
                        _activeTool: this.state.activeTool,
                        _activeToolIndex: this.state.activeToolIndex,
                        _includeClose: true,
                        _onClose: () => this.toggle(),
                        _onToggleMenuTool: (e: IToolEvent) => this.toggleTool(e),
                        onToggle: child.props.onToggle,
                    });
                } else if (child.type === ToolbarMenuContent && child.props._includeClose == null) {
                    child = React.cloneElement(child, {
                        _includeClose: true,
                        _onClose: () => this.toggle(),
                    });
                }
                return child;
            });
            content = <div className={classnames("toolbar-menu-content", { rtl })}>{children}</div>;
        }

        if (!disabled && this.state.activeTool) {
            label = this.state.activeTool.props.label;
            icon = this.state.activeTool.props.icon;
            clsNames.push(this.state.activeTool.props.className);
            clsNames.push("active");
        }

        return (
            <div className={!disabled && _expanded ? "expanded" : null}>
                <div className={classnames(clsNames, { disabled })} onClick={() => this.toggle()}>
                    <div className="toolbar-item-icon-container">
                        {icon}
                        {toolbarItemCountEl}
                    </div>
                    <span className="toolbar-item-text">{label}</span>
                    <DownArrowIcon className="toolbar-item-arrow" />
                </div>
                {content}
            </div>
        );
    }
}
