import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";
import { config as intlConfig } from "~/intl-provider/config";
import { SelectInput, NumericInput, Bucket, BucketHeader, Checkbox, TextInput } from "~/core";
import { CropAPI, FormattingHelpers } from "@ai360/core";
import { ACTIVE_YN, picklistNames } from "~/core/picklist";
import { unitNames } from "~/core/units";
import "./harvest-event.css";
import { messages } from "./i18n-messages";
import { keywords } from "../../../../keywords";
import {
    //actions as recsEventsActions,
    eventsSelectors,
} from "~/recs-events";

import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";

const errorMessages = defineMessages({
    averageYieldUnitError: {
        id: "eventModule.eventInfo.averageYieldUnitError",
        defaultMessage: "Average Yield Unit",
    },
    ldpPaymentUnitError: {
        id: "eventModule.eventInfo.ldpPaymentUnitError",
        defaultMessage: "LDP Payment Unit",
    },
    priceUnitDependentError: {
        id: "eventModule.eventInfo.priceUnitDependentError",
        defaultMessage: "Price Dependent",
    },
    priceUnitError: {
        id: "eventModule.eventInfo.priceUnitError",
        defaultMessage: "Price Unit",
    },
});

const errorCodeToMessageIdSetMap = new Map([
    [815, errorMessages.priceUnitError], // ErrorCode.EventHarvestPriceUnitRequiredIfPrice
    [816, errorMessages.priceUnitDependentError], //EventHarvestPriceRequiredIfPriceUnit
    [817, errorMessages.ldpPaymentUnitError], //EventHarvestLdpPaymentUnitRequiredIfLdpPayment
    [819, errorMessages.averageYieldUnitError], // ErrorCode.HarvestEventAverageYieldUnitRequired
]);

const BU_PER_AC = "160";
const BAG_PER_AC = "211";
const BL_PER_AC = "210";
const SACK_PER_AC = "212";

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};
class HarvestEventForm_ extends Component {
    static propTypes = {
        agEventModel: PropTypes.object,
        baleType: PropTypes.array,
        brandOrgData: PropTypes.array,
        cottonGrades: PropTypes.array,
        cropData: PropTypes.array,
        cropPurpose: PropTypes.array,
        dryYieldUnit: PropTypes.array,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchSystemAttributePicklist: PropTypes.func,
        fetchUnitData: PropTypes.func,
        intl: intlShape.isRequired,
        lintYieldUnit: PropTypes.array,
        onUpdateCurrentAgEventAreaAgEventModel: PropTypes.func.isRequired,
        overallRating: PropTypes.array,
        priceUnit: PropTypes.array,
        saveEventDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        varietyHybridData: PropTypes.array,
        yieldUnit: PropTypes.array,
    };

    static CROP_TYPE_COTTON = "7";
    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }
    // Yield unit dropdown ids to show the Average test Weight input
    static yieldUnitIdsTest = [BU_PER_AC];
    // Yield unit dropdown ids to show the Average unit Weight input
    static yieldUnitIdsUnit = [BAG_PER_AC, BL_PER_AC, SACK_PER_AC];

    _updateAgEvent(newProps) {
        this.props.onUpdateCurrentAgEventAreaAgEventModel(newProps);
    }

    _isCropTypeCotton() {
        const { agEventModel, cropData } = this.props;
        if (agEventModel.cropGuid && cropData && cropData.length > 0) {
            const crop = cropData.find((data) => data.guid === agEventModel.cropGuid);
            if (crop && crop.id === HarvestEventForm_.CROP_TYPE_COTTON) {
                return true;
            }
        }
        return false;
    }

    _isAverageInput(dataIds = []) {
        const { agEventModel, lintYieldUnit, dryYieldUnit } = this.props;
        const yieldUnit = this._isCropTypeCotton() ? lintYieldUnit : dryYieldUnit;
        const tempYieldUnit = yieldUnit.find(
            ({ importAttributeGuid }) => agEventModel.averageYieldIAGuid === importAttributeGuid
        );
        if (tempYieldUnit && dataIds.indexOf(tempYieldUnit.unitId) > -1) {
            return true;
        }
        return false;
    }

    _renderAverageInputs() {
        const { formatMessage } = this.props.intl;
        const { agEventModel } = this.props;
        return (
            <div className="input-row col-3">
                {!this._isAverageInput(HarvestEventForm_.yieldUnitIdsTest) ? null : (
                    <NumericInput
                        required
                        scale={0}
                        precision={3}
                        placeholderText={formatMessage(messages.averageTestWeight)}
                        value={agEventModel.averageTestWeight}
                        onChange={(value) =>
                            this._updateAgEvent({
                                averageTestWeight: value,
                            })
                        }
                    />
                )}
                {!this._isAverageInput(HarvestEventForm_.yieldUnitIdsUnit) ? null : (
                    <NumericInput
                        required
                        scale={0}
                        precision={3}
                        placeholderText={formatMessage(messages.averageUnitWeight)}
                        value={agEventModel.averageUnitWeight}
                        onChange={(value) =>
                            this._updateAgEvent({
                                averageUnitWeight: value,
                            })
                        }
                    />
                )}
            </div>
        );
    }

    onCropNameChange = (value) => {
        if (value) {
            this.props.fetchDropdownData({
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: value.guid,
                },
            });
        }
        this._updateAgEvent({
            cropGuid: value ? value.guid : "",
        });
    };

    onBrandOrgChange = (value) => {
        if (value) {
            this.props.fetchDropdownData({
                varietyHybridData: {
                    url: CropAPI.REQUEST_VARIETY_HYBRID,
                    model: {
                        CropID: this.props.agEventModel.cropGuid,
                        BrandOrganization: value.guid,
                    },
                },
            });
        }
        this._updateAgEvent({
            brandOrganizationGuid: value ? value.guid : "",
        });
    };

    componentDidMount() {
        this.props.fetchDropdownData({
            cropData: {
                url: CropAPI.REQUEST_CROP,
                model: keywords.name,
            },
        });

        if (this.props.agEventModel.cropGuid) {
            this.props.fetchDropdownData({
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: this.props.agEventModel.cropGuid,
                },
            });
        }

        if (this.props.agEventModel.brandOrganizationGuid) {
            this.props.fetchDropdownData({
                varietyHybridData: {
                    url: CropAPI.REQUEST_VARIETY_HYBRID,
                    model: {
                        CropID: this.props.agEventModel.cropGuid,
                        BrandOrganization: this.props.agEventModel.brandOrganizationGuid,
                    },
                },
            });
        }

        this.props.fetchSystemAttributePicklist([
            {
                model: keywords.priceUnit,
                systemAttribute: keywords.price,
                eventType: keywords.harvest,
            },
            {
                model: keywords.dryYieldUnit,
                systemAttribute: keywords.dryYield,
                eventType: keywords.harvest,
                forceRaw: true,
            },
            {
                model: keywords.lintYieldUnit,
                systemAttribute: keywords.lintYield,
                eventType: keywords.harvest,
                forceRaw: true,
            },
        ]);
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_CROP_PURPOSE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROP_PURPOSE
            ),
            [picklistNames.PICKLIST_BALE_TYPE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_BALE_TYPE
            ),
            [picklistNames.PICKLIST_DENSITY_RATING]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_NUMERIC_RATING
            ),
            [picklistNames.PICKLIST_COTTON_COLOR_GRADE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_COTTON_COLOR_GRADE
            ),
        });
        this.props.fetchUnitData({
            [unitNames.UNIT_YIELD]: unitNames.getUnitCode(unitNames.UNIT_YIELD),
            [unitNames.UNIT_PRICE_UNIT_LIQUID]: unitNames.getUnitCode(unitNames.UNIT_WEIGHT),
        });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }
    setBucketExpanded = (bucketKey) => {
        this.setState({
            [bucketKey]: !this.state[bucketKey],
        });
    };
    render() {
        const { formatMessage, formatNumber } = this.props.intl;
        const { agEventModel } = this.props;
        const {
            baleType,
            brandOrgData,
            cottonGrades,
            cropData,
            cropPurpose,
            dryYieldUnit,
            lintYieldUnit,
            overallRating,
            priceUnit,
            varietyHybridData,
        } = this.props;
        const isCottonCrop = this._isCropTypeCotton();
        const { errorMessagePlaceholderSet } = this.state;
        const yieldUnit = isCottonCrop ? lintYieldUnit : dryYieldUnit;

        const avgYieldRawValue = !isCottonCrop
            ? agEventModel.averageYieldCleaned
            : agEventModel.cottonLint;
        const avgYieldFormatted = agEventModel.isImported
            ? formatNumber(
                  avgYieldRawValue,
                  intlConfig.customFormatOptions(
                      FormattingHelpers.progressiveRoundingDecimals(avgYieldRawValue)
                  )
              )
            : avgYieldRawValue;

        return (
            <div className="harvest-event-container">
                <Bucket
                    className="import-field-section-bucket"
                    showSymbol={false}
                    isCollapsible={false}
                    isExpanded
                >
                    <BucketHeader className="import-field-section-header create-events-section-header">
                        <div className="create-events-header">
                            {formatMessage(messages.averageYield)}
                        </div>
                    </BucketHeader>
                    <div className="input-row">
                        <SelectInput
                            required
                            optionIsHiddenKey={ACTIVE_YN}
                            clearable={false}
                            placeholderText={formatMessage(messages.crop)}
                            value={{ guid: agEventModel.cropGuid }}
                            options={cropData.map(({ guid, name, activeYn }) => ({
                                value: {
                                    guid,
                                },
                                label: name,
                                activeYn,
                            }))}
                            onChange={(value) => this.onCropNameChange(value)}
                        />
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.cropPurpose)}
                            value={agEventModel.cropPurposeGuid}
                            options={cropPurpose}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    cropPurposeGuid: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.brandOrganization)}
                            disabled={!agEventModel.cropGuid || brandOrgData.length === 0}
                            value={{ guid: agEventModel.brandOrganizationGuid }}
                            options={brandOrgData.map(({ guid, name }) => ({
                                value: {
                                    guid,
                                },
                                label: name,
                            }))}
                            onChange={(value) => this.onBrandOrgChange(value)}
                        />
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            disabled={
                                !agEventModel.brandOrganizationGuid || brandOrgData.length === 0
                            }
                            placeholderText={formatMessage(messages.varietyHybrid)}
                            value={{ guid: agEventModel.varietyHybridGuid }}
                            options={varietyHybridData.map(({ guid, name, activeYn }) => ({
                                value: {
                                    guid,
                                },
                                label: name,
                                activeYn,
                            }))}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    varietyHybridGuid: value ? value.guid : "",
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.averageYieldUnitDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={8}
                            placeholderText={
                                !isCottonCrop
                                    ? formatMessage(messages.averageYield)
                                    : formatMessage(messages.averageLintYield)
                            }
                            value={avgYieldFormatted}
                            onChange={(value) => {
                                !isCottonCrop
                                    ? this._updateAgEvent({
                                          averageYieldCleaned: value,
                                      })
                                    : this._updateAgEvent({
                                          cottonLint: value,
                                      });
                            }}
                            disabled={agEventModel.isImported}
                        />
                        <SelectInput
                            required={agEventModel.isImported}
                            containerClassNames={[
                                "width-25",
                                {
                                    "select-form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.averageYieldUnitError
                                    ),
                                },
                            ]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.yieldUnit)}
                            value={agEventModel.averageYieldIAGuid}
                            options={yieldUnit.map((val) => ({
                                label: val.abbreviation,
                                value: val.importAttributeGuid,
                            }))}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    averageYieldIAGuid: value,
                                    averageTestWeight: "",
                                    averageUnitWeight: "",
                                })
                            }
                        />
                        {!isCottonCrop ? (
                            <NumericInput
                                scale={2}
                                precision={5}
                                placeholderText={formatMessage(messages.averageMoisture)}
                                value={agEventModel.averageMoisturePercentOriginal}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        averageMoisturePercentOriginal: value,
                                    })
                                }
                                disabled={agEventModel.isImported}
                            />
                        ) : (
                            <NumericInput
                                scale={2}
                                precision={5}
                                placeholderText={formatMessage(messages.lintPercent)}
                                value={agEventModel.cottonGinTurnOutPercent}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cottonGinTurnOutPercent: value,
                                    })
                                }
                            />
                        )}
                    </div>
                    {isCottonCrop ? (
                        <div className="input-row col-3">
                            <TextInput
                                maxLength={15}
                                placeholderText={formatMessage(messages.module)}
                                value={agEventModel.cottonModuleNumber}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cottonModuleNumber: value,
                                    })
                                }
                            />
                        </div>
                    ) : (
                        this._renderAverageInputs()
                    )}
                </Bucket>
                {!isCottonCrop ? null : (
                    <Bucket className="import-field-section-bucket">
                        <BucketHeader className="import-field-section-header create-events-section-header">
                            <div className="create-events-header">
                                {formatMessage(messages.cottonGrade)}
                            </div>
                        </BucketHeader>
                        <div className="input-row">
                            <SelectInput
                                containerClassNames={["harvest-select-cont"]}
                                optionIsHiddenKey={ACTIVE_YN}
                                placeholderText={formatMessage(messages.colorGrade)}
                                value={agEventModel.cottonColor}
                                options={cottonGrades}
                                onChange={(value) => this._updateAgEvent({ cottonColor: value })}
                            />
                            <NumericInput
                                containerClassNames={["harvest-text-cont"]}
                                scale={0}
                                precision={3}
                                placeholderText={formatMessage(messages.leafGrade)}
                                value={agEventModel.cottonLeafGrade}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cottonLeafGrade: value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-row">
                            <NumericInput
                                scale={2}
                                precision={6}
                                placeholderText={formatMessage(messages.micronaire)}
                                value={agEventModel.cottonMicronaire}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cottonMicronaire: value,
                                    })
                                }
                            />
                            <NumericInput
                                scale={2}
                                precision={6}
                                placeholderText={formatMessage(messages.stapleLength)}
                                value={agEventModel.cottonFiberLength}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cottonFiberLength: value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-row">
                            <NumericInput
                                scale={2}
                                precision={6}
                                placeholderText={formatMessage(messages.uniformityIndex)}
                                value={agEventModel.cottonLengthUniformityPercent}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cottonLengthUniformityPercent: value,
                                    })
                                }
                            />
                            <NumericInput
                                scale={2}
                                precision={6}
                                placeholderText={formatMessage(messages.strength)}
                                value={agEventModel.cottonFiberStrength}
                                onChange={(value) =>
                                    this._updateAgEvent({
                                        cottonFiberStrength: value,
                                    })
                                }
                            />
                        </div>
                    </Bucket>
                )}
                <Bucket className="import-field-section-bucket">
                    <BucketHeader className="import-field-section-header create-events-section-header">
                        <div className="create-events-header">
                            {formatMessage(messages.balesWindrow)}
                        </div>
                    </BucketHeader>
                    <div className="input-row">
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.baleType)}
                            containerClassNames={["select-bales-type"]}
                            value={agEventModel.baleTypeGuid}
                            options={baleType}
                            onChange={(value) => this._updateAgEvent({ baleTypeGuid: value })}
                        />
                        <Checkbox
                            className="harvest-event-checkbox"
                            label={formatMessage(messages.rainedOnWindrow)}
                            value={agEventModel.windrowRainedOn}
                            onChange={(evt, value) =>
                                this._updateAgEvent({ windrowRainedOn: value })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            scale={0}
                            precision={6}
                            value={agEventModel.balesNumberOf}
                            placeholderText={formatMessage(messages.numberOfBales)}
                            onChange={(value) => this._updateAgEvent({ balesNumberOf: value })}
                        />
                        <NumericInput
                            scale={0}
                            precision={3}
                            containerClassNames={["harvest-event-single-input"]}
                            value={agEventModel.daysInWindrow}
                            placeholderText={formatMessage(messages.daysInWindrow)}
                            onChange={(value) => this._updateAgEvent({ daysInWindrow: value })}
                        />
                    </div>
                </Bucket>
                <Bucket className="import-field-section-bucket">
                    <BucketHeader className="import-field-section-header create-events-section-header">
                        <div className="create-events-header">
                            {formatMessage(messages.cropQualityRating)}
                        </div>
                    </BucketHeader>
                    <div className="input-row">
                        <SelectInput
                            containerClassNames={["harvest-select-cont"]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.overallRating)}
                            value={agEventModel.overallRatingGuid}
                            options={overallRating}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    overallRatingGuid: value,
                                })
                            }
                        />
                        <NumericInput
                            containerClassNames={["harvest-text-cont"]}
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.foreignMatter)}
                            value={agEventModel.qualityForeignMatterPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    qualityForeignMatterPercent: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.inertMatter)}
                            value={agEventModel.qualityInertMatterPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    qualityInertMatterPercent: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.damaged)}
                            value={agEventModel.qualityDamagePercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    qualityDamagePercent: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.largeSize)}
                            value={agEventModel.qualityLargeSizePercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    qualityLargeSizePercent: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.proteinContent)}
                            value={agEventModel.qualityProteinContentPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    qualityProteinContentPercent: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row half">
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.oilContent)}
                            value={agEventModel.qualityOilContentPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    qualityOilContentPercent: value,
                                })
                            }
                        />
                    </div>
                </Bucket>
                <Bucket
                    className="import-field-section-bucket"
                    isExpanded={
                        errorMessagePlaceholderSet.has(errorMessages.priceUnitError) ||
                        errorMessagePlaceholderSet.has(errorMessages.priceUnitDependentError)
                    }
                >
                    <BucketHeader className="import-field-section-header create-events-section-header">
                        <div className="create-events-header">{formatMessage(messages.prices)}</div>
                    </BucketHeader>
                    <div className="input-row">
                        <SelectInput
                            containerClassNames={[
                                "harvest-select-cont",
                                {
                                    "select-form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.priceUnitError
                                    ),
                                },
                            ]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.priceUnit)}
                            value={agEventModel.priceDefaultIAGuid}
                            options={priceUnit}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    priceDefaultIAGuid: value,
                                })
                            }
                        />
                        <NumericInput
                            containerClassNames={[
                                "harvest-text-cont",
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.priceUnitDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={6}
                            placeholderText={formatMessage(messages.currentPrice)}
                            value={agEventModel.priceCurrentPrice}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    priceCurrentPrice: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.priceUnitDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={6}
                            placeholderText={formatMessage(messages.soldPrice)}
                            value={agEventModel.pricesSoldPrice}
                            onChange={(value) => this._updateAgEvent({ pricesSoldPrice: value })}
                        />
                        <NumericInput
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.priceUnitDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={6}
                            placeholderText={formatMessage(messages.premiumSoldPrice)}
                            value={agEventModel.pricesPremiumSoldPrice}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    pricesPremiumSoldPrice: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row half">
                        <NumericInput
                            containerClassNames={[
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.priceUnitDependentError
                                    ),
                                },
                            ]}
                            scale={0}
                            precision={6}
                            placeholderText={formatMessage(messages.totalIncome)}
                            value={agEventModel.totalIncome}
                            onChange={(value) => this._updateAgEvent({ totalIncome: value })}
                        />
                    </div>
                </Bucket>
                <Bucket
                    className="import-field-section-bucket"
                    isExpanded={
                        errorMessagePlaceholderSet.has(
                            errorMessages.ldpPaymentUnitDependentError
                        ) || errorMessagePlaceholderSet.has(errorMessages.ldpPaymentUnitError)
                    }
                >
                    <BucketHeader className="import-field-section-header create-events-section-header">
                        <div className="create-events-header">
                            {formatMessage(messages.ldpPayment)}
                        </div>
                    </BucketHeader>
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={[
                                "harvest-text-cont",
                                {
                                    "form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.ldpPaymentUnitDependentError
                                    ),
                                },
                            ]}
                            scale={2}
                            precision={6}
                            placeholderText={formatMessage(messages.amount)}
                            value={agEventModel.ldpPaymentAmount}
                            onChange={(value) => this._updateAgEvent({ ldpPaymentAmount: value })}
                        />
                        <SelectInput
                            containerClassNames={[
                                "harvest-select-cont",
                                {
                                    "select-form-input-error": errorMessagePlaceholderSet.has(
                                        errorMessages.ldpPaymentUnitError
                                    ),
                                },
                            ]}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.unit)}
                            value={agEventModel.ldpPaymentIAGuid}
                            options={priceUnit}
                            onChange={(value) => this._updateAgEvent({ ldpPaymentIAGuid: value })}
                        />
                    </div>
                </Bucket>
                <Bucket className="import-field-section-bucket">
                    <BucketHeader className="import-field-section-header create-events-section-header">
                        <div className="create-events-header">
                            {formatMessage(messages.feedQuality)}
                        </div>
                    </BucketHeader>
                    <div className="input-row">
                        <NumericInput
                            scale={0}
                            precision={3}
                            placeholderText={formatMessage(messages.cutting)}
                            value={agEventModel.forageCuttingNumber}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageCuttingNumber: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.adf)}
                            value={agEventModel.forageQualityTestAdfPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestAdfPercent: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.ndf)}
                            value={agEventModel.forageQualityTestNdfPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestNdfPercent: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.rfq)}
                            value={agEventModel.forageQualityTestRfqPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestRfqPercent: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.rfv)}
                            value={agEventModel.forageQualityTestRfvPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestRfvPercent: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.tdn)}
                            value={agEventModel.forageQualityTestTdnPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestTdnPercent: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.cp)}
                            value={agEventModel.forageQualityTestCpPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestCpPercent: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.ca)}
                            value={agEventModel.forageQualityTestCaPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestCaPercent: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.p)}
                            value={agEventModel.forageQualityTestPPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestPPercent: value,
                                })
                            }
                        />
                    </div>
                    <div className="input-row">
                        <NumericInput
                            containerClassNames={["feed-quality-3"]}
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.ddm)}
                            value={agEventModel.forageQualityTestDdmPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestDdmPercent: value,
                                })
                            }
                        />
                        <NumericInput
                            containerClassNames={["feed-quality-3"]}
                            scale={2}
                            precision={5}
                            placeholderText={formatMessage(messages.dmi)}
                            value={agEventModel.forageQualityTestDmiPercent}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageQualityTestDmiPercent: value,
                                })
                            }
                        />
                    </div>
                </Bucket>
                <Bucket className="import-field-section-bucket">
                    <BucketHeader className="import-field-section-header create-events-section-header">
                        <div className="create-events-header">
                            {formatMessage(messages.netEnergy)}
                        </div>
                    </BucketHeader>
                    <div className="input-row">
                        <NumericInput
                            scale={0}
                            precision={3}
                            placeholderText={formatMessage(messages.lactation)}
                            value={agEventModel.forageNetEnergyGain}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageNetEnergyGain: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={0}
                            precision={3}
                            placeholderText={formatMessage(messages.gain)}
                            value={agEventModel.forageNetEnergyLactation}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageNetEnergyLactation: value,
                                })
                            }
                        />
                        <NumericInput
                            scale={0}
                            precision={3}
                            placeholderText={formatMessage(messages.maintenance)}
                            value={agEventModel.forageNetEnergyMaintenance}
                            onChange={(value) =>
                                this._updateAgEvent({
                                    forageNetEnergyMaintenance: value,
                                })
                            }
                        />
                    </div>
                </Bucket>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { saveEventDetailsErrorCodeList } = eventsSelectors.getModuleState(state);

    return {
        saveEventDetailsErrorCodeList,
    };
};
export const HarvestEventForm = connect(mapStateToProps, null)(injectIntl(HarvestEventForm_));
