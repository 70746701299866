import { takeEvery, put, select } from "redux-saga/effects";
import { requestState, requestStateFailed, requestStateSuccess } from "./actions";
import { AddressAPI } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
// selectors
import { getTheUserGuid } from "~/login/selectors";

export function* fetchStateRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield AddressAPI.getStateList(requestOptions);
            if (response) {
                yield put(requestStateSuccess({ guid: payload, response }));
            } else {
                yield put(requestStateFailed("No Data"));
            }
        } catch (error) {
            yield put(requestStateFailed({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(requestStateFailed("No Data"));
        console.error("No Data");
    }
}

const LicenseSagas = function* () {
    yield takeEvery(requestState, fetchStateRequest);
};

export default LicenseSagas;
