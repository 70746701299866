import { createReducer } from "redux-act";
import { setRequestStatus, clearRequestIds } from "./needs";

export const REQUEST_IDS_STATE_KEY = "REQUEST_IDS_STATE";

export const requestIdsReducer = createReducer(
    {
        [setRequestStatus]: (state, { requestId, status }) => {
            return {
                ...state,
                [requestId]: status,
            };
        },
        [clearRequestIds]: (state, { requestIds }) => {
            return {
                ...requestIds,
            };
        },
    },
    {}
);
