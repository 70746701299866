import React, { Component } from "react";
import { DialogBox, DialogBoxFooterType, NoLink, ZeroToInfiniteGrid, SelectInput } from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { ISelectOption } from "@ai360/core/dist/4x/es/types/tools";
import { adminData } from "~/admin/data";
import { mapToPicklistValue } from "~/admin/utils";
import { myAgDataModel } from "~/admin/agBytes/components/interfaces";

export interface CommodityListProps {
    record?: Record<string, any>[];
    formatMessage?: (message: string) => string;
    renderDeleteModal?: () => JSX.Element;
    toggleModal?: (message: string, toggle: boolean) => void;
    onChange?: (obj: Record<string, any>) => void;
    onAdd?: () => void;
    onEdit?: (obj: Record<string, any>) => void;
    onDelete?: (obj: Record<string, any>) => void;
    onAddOrEditItem?: () => void;
    deleteItem?: () => void;
    isEditing?: boolean;
    isDeleting?: boolean;
    editData?: Record<string, any>;
    aliasTextLabel?: string;
    aliasName?: string;
    commodityList: ISelectOption<any>[];
    commodityTypeList: ISelectOption<any>[];
    intendedUseList: ISelectOption<any>[];
}

export interface CommodityListState {
    editingIndex: number;
}

class CommodityList extends Component<CommodityListProps, CommodityListState> {
    constructor(props) {
        super(props);
        this.state = {
            editingIndex: -1,
        };
    }

    onEdit = (record, index) => {
        const keysToUpdate = [
            myAgDataModel.PROPS_INTENDED_USE_GUID,
            myAgDataModel.PROPS_INTENDED_USE_NAME,
            myAgDataModel.PROPS_COMMODITY_GUID,
            myAgDataModel.PROPS_COMMODITY_NAME,
            myAgDataModel.PROPS_COMMODITY_TYPE_GUID,
            myAgDataModel.PROPS_COMMODITY_TYPE_NAME,
        ];
        this.setState({ editingIndex: index });
        this.props.onEdit({ record, index, keysToUpdate });
    };
    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };
    onAdd = () => {
        this.setState({ editingIndex: -1 });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const {
            formatMessage,
            isEditing,
            editData,
            onAddOrEditItem,
            onChange,
            toggleModal,
            commodityTypeList,
            commodityList,
            intendedUseList,
            record,
        } = this.props;
        const { editingIndex } = this.state;

        const isDuplicate =
            editData &&
            (record?.findIndex(
                (value, index) =>
                    index !== editingIndex &&
                    value[myAgDataModel.PROPS_COMMODITY_GUID] ===
                        editData[myAgDataModel.PROPS_COMMODITY_GUID] &&
                    value[myAgDataModel.PROPS_COMMODITY_TYPE_GUID] ===
                        editData[myAgDataModel.PROPS_COMMODITY_TYPE_GUID] &&
                    (value[myAgDataModel.PROPS_INTENDED_USE_GUID] ?? "") ===
                        (editData[myAgDataModel.PROPS_INTENDED_USE_GUID] ?? "")
            ) > -1 ??
                false);

        const actionDisabled =
            !editData[myAgDataModel.PROPS_COMMODITY_GUID] ||
            !editData[myAgDataModel.PROPS_COMMODITY_TYPE_GUID] ||
            isDuplicate;

        return (
            <DialogBox
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={actionDisabled}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    editingIndex > -1,
                    formatMessage,
                    messages.commodity
                )}
            >
                <div className="commodity-add-form">
                    <SelectInput
                        tabIndex={0}
                        required={true}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={commodityList}
                        clearable={false}
                        placeholderText={formatMessage(messages.commodity)}
                        value={mapToPicklistValue({
                            options: commodityList,
                            selectedGuid: editData[myAgDataModel.PROPS_COMMODITY_GUID],
                        })}
                        onChange={(value) =>
                            onChange({
                                [myAgDataModel.PROPS_COMMODITY_GUID]: value.guid,
                                [myAgDataModel.PROPS_COMMODITY_NAME]: value.name,
                            })
                        }
                    />
                    <SelectInput
                        tabIndex={1}
                        required={true}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={commodityTypeList}
                        placeholderText={formatMessage(messages.commodityType)}
                        clearable={false}
                        value={mapToPicklistValue({
                            options: commodityTypeList,
                            selectedGuid: editData[myAgDataModel.PROPS_COMMODITY_TYPE_GUID],
                        })}
                        onChange={(value) =>
                            onChange({
                                [myAgDataModel.PROPS_COMMODITY_TYPE_GUID]: value.guid,
                                [myAgDataModel.PROPS_COMMODITY_TYPE_NAME]: value.name,
                            })
                        }
                    />
                    <SelectInput
                        tabIndex={2}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={intendedUseList}
                        placeholderText={formatMessage(messages.intendedUseCode)}
                        value={mapToPicklistValue({
                            options: intendedUseList,
                            selectedGuid: editData[myAgDataModel.PROPS_INTENDED_USE_GUID],
                        })}
                        onChange={(value) =>
                            onChange({
                                [myAgDataModel.PROPS_INTENDED_USE_GUID]: value?.guid,
                                [myAgDataModel.PROPS_INTENDED_USE_NAME]: value?.name,
                            })
                        }
                    />
                    {isDuplicate && (
                        <label className="error-text span-2">
                            {formatMessage(messages.duplicateCommodity)}
                        </label>
                    )}
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [myAgDataModel.PROPS_COMMODITY_NAME]: {
                                title: formatMessage(messages.commodity),
                            },
                            [myAgDataModel.PROPS_COMMODITY_TYPE_NAME]: {
                                title: formatMessage(messages.type),
                            },
                            [myAgDataModel.PROPS_INTENDED_USE_NAME]: {
                                title: formatMessage(messages.intendedUse),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={true}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.commodity)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(CommodityList);
