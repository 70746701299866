import { AppHelpers } from "@ai360/core";

import Account from "./account/account-container";
import AdminScripts from "./admin-scripts/admin-scripts-container";
import BatchZapper from "./batch-zapper/batch-zapper-container";
import Customer from "./customer/customer-container";
import EquationSets from "./equation-sets/application/equation-sets";
import EquationSetsPlanting from "./equation-sets/planting/equation-sets-planting";
import { EquationParameter } from "./equation-parameter/equation-parameter";
import { Equipment } from "./equipment/equipment-container";
import AddEquipment from "./equipment/add-edit/add-edit-container";
import ImportFilterSettings from "./import-filter-settings/import-filter-settings-container";
import { LocationMigration } from "./location-migration/location-migration";
import { OrgLevel } from "./org-level/org-level-container";
import { Owner } from "./owner/owner";
import { Person } from "./person/person";
import Preference from "./preference/preference-container";
import { ProductAssignment } from "./product-assignment/product-assignment";
import { User } from "./user/user";
import { UserRole } from "./user-role/user-role";
import { SurfaceDefaults } from "./surface-defaults/surface-defaults";
import SystemProfile from "./system-profile/system-profile-container";
import { TemplateManager } from "./template-manager";
import { Vendor } from "./vendor/vendor";
import { NutrientTargetValue } from "./nutrient-target-value/nutrient-target-value";
import { NutrientRemovalRate } from "./nutrient-removal-rate/nutrient-removal-rate";
import { SetupReportBookOptions } from "./report-books/report-book-options/report-book-options";
import { SetupReportBooks } from "./report-books/setup-report-books";
import { SetupReleaseNotes } from "./release-notes/setup-release-notes";

const SetupViewResolver = (childNameCode) => {
    if (AppHelpers.isNothing(childNameCode)) {
        return;
    }
    switch (childNameCode) {
        // TODO: Change preferences to correct childNameCode.
        case "1": //preferences
            return {
                component: Preference,
                withSlidingPanel: true,
            };
        case "2": //add equipment
            return {
                component: AddEquipment,
                withSlidingPanel: true,
            };
        case "3": //Account
            return {
                component: Account,
                withSlidingPanel: true,
            };
        case "140": //equipment
            return {
                component: Equipment,
            };
        case "117": //location
            return {
                component: OrgLevel,
            };
        case "118": //person
            return {
                component: Person,
            };
        case "107": //template manager
            return {
                component: TemplateManager,
            };
        case "120": //user
            return {
                component: User,
            };
        case "121": //user-role
            return {
                component: UserRole,
            };
        case "122": //vendor
            return {
                component: Vendor,
            };
        case "123":
            return {
                component: EquationSets,
            };
        case "131":
            return {
                component: NutrientRemovalRate,
            };
        case "132":
            return {
                component: NutrientTargetValue,
            };
        case "180": //batch-zapper
            return {
                component: BatchZapper,
                withSlidingPanel: true,
            };
        case "183":
            return {
                component: EquationParameter,
            };
        case "187": //import filter settings
            return {
                component: ImportFilterSettings,
                withSlidingPanel: true,
            };
        case "189": //admin-scripts
            return {
                component: AdminScripts,
                withSlidingPanel: true,
            };
        case "212": //Customer Add
            return {
                component: Customer,
                withSlidingPanel: true,
            };
        case "213": //Customer Edit
            return {
                component: Customer,
                withSlidingPanel: true,
            };
        case "233": //system-profile
            return {
                component: SystemProfile,
                withSlidingPanel: true,
            };
        case "234": //system-owner
            return {
                component: Owner,
            };
        case "253": //product assignment
            return {
                component: ProductAssignment,
            };
        case "255": //Surface Defaults
            return {
                component: SurfaceDefaults,
            };
        case "256": //Location Migration
            return {
                component: LocationMigration,
                withSlidingPanel: true,
            };
        case "259": //Setup report book options
            return {
                component: SetupReportBookOptions,
            };
        case "261":
            return {
                component: EquationSetsPlanting,
            };
        case "266": //Setup report books
            return {
                component: SetupReportBooks,
            };
        case "428": //Release Notes
            return {
                component: SetupReleaseNotes,
                withSlidingPanel: true,
            };
        default:
            return;
    }
};

export default SetupViewResolver;
