import { combineReducers } from "redux";

import { GRID_DATA_KEY, gridReducer } from "./crud/reducer";
import { REQUEST_IDS_STATE_KEY, requestIdsReducer } from "./needs/reducer";

export const HOCS_STATE_KEY = "HOCS_STATE";

export const hocsReducer = combineReducers({
    [GRID_DATA_KEY]: gridReducer,
    [REQUEST_IDS_STATE_KEY]: requestIdsReducer,
});
