import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl, aggregateUrlGenerator } from "@ai360/core";

import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
import {
    PICKLIST_CROP_PURPOSE,
    PICKLIST_BRAND_ORGANIZATION,
    getPickListCode,
    PICKLIST_HERBICIDE_TOLERANCE,
} from "~/core/picklist/picklist-names";

const AGGREGATE_NAME = "trait";
const ENDPOINT_NAME = "agbytes/trait";
const API_VERSION = "v1.0";

const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const URL = aggregateUrl("list");
export const GETRECORD = aggregateUrl();
export const NEXT_ID = aggregateUrl("nextId");
export const CREATE = aggregateUrl();
export const UPDATE = aggregateUrl();
export const DELETE = aggregateUrl();
export const EXPORT_FILE_NAME = "TraitExport";
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const SELECT_ALL = aggregateUrl("selectAll");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "traitFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "traitSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "traitPageOptions";

// Dropdowns
export const REQUEST_CROP_NAME = apiUrl("AgBytes/GetCropAgBytesList");
export const REQUEST_TRAIT_GENE = apiUrl("AgBytes/GetGeneAgBytesList");

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        traitId: "",
        traitName: "",
        abbreviation: "",
        brandOrganization: "",
        cropName: "",
        gmo: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "TraitName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultTrait = () => ({
    [model.PROPS_TRAIT_ABBREVIATION]: "",
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_CAN_DELETE]: "",
    [model.PROPS_CROP_GUID]: "",
    [model.PROPS_CROP_NAME]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_TRAIT_BRAND_ORGANIZATION]: "",
    [model.PROPS_TRAIT_BRAND_ORGANIZATION_GUID]: null,
    [model.PROPS_TRAIT_GMO]: false,
    [model.PROPS_TRAIT_GUID]: "",
    [model.PROPS_TRAIT_ID]: "",
    [model.PROPS_TRAIT_NAME]: "",
    [model.PROPS_TRAIT_EVENTS]: [],
    [model.PROPS_TRAIT_GENES]: [],
    [model.PROPS_HERBICIDE_TOLERANCE]: [],
});

export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
    [PICKLIST_BRAND_ORGANIZATION]: getPickListCode(PICKLIST_BRAND_ORGANIZATION),
    [PICKLIST_HERBICIDE_TOLERANCE]: getPickListCode(PICKLIST_HERBICIDE_TOLERANCE),
};

export const dropdowns = {
    [model.PROPS_CROP_NAME]: REQUEST_CROP_NAME,
    [model.PROPS_TRAIT_GENES]: REQUEST_TRAIT_GENE,
};

export const service = createService({
    id: model.PROPS_TRAIT_ID,
    guid: model.PROPS_TRAIT_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    dropdowns,
    activeColumnName: model.PROPS_ACTIVE_YN,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_TRAIT_ID]: { label: "traitId", gridCol: 5 },
        [model.PROPS_TRAIT_NAME]: { label: "traitName", gridCol: 20 },
        [model.PROPS_TRAIT_ABBREVIATION]: {
            label: "traitAbbreviation",
            gridCol: 15,
        },
        [model.PROPS_TRAIT_BRAND_ORGANIZATION]: { label: "brand", gridCol: 10 },
        [model.PROPS_CROP_NAME]: { label: "cropName", gridCol: 10 },
        [model.PROPS_TRAIT_GMO]: { label: "gmo", gridCol: 5 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
    },
    getDefaultRecord: () => Object.assign({}, defaultTrait()),
    defaultSort,
    useNewApi: true,
});
