import React, { Component } from "react";
import PropTypes from "prop-types";
import { messages } from "~/admin/agBytes/i18n-messages";
import { model } from "./../../data";
import { TextArea, Button, Section, SubSection, DialogBox } from "~/core";
import { getAgBytesErrorClassNames } from "~/admin/utils";
class EquationTextArea extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        equation: PropTypes.object,
        formatMessage: PropTypes.func,
        needs: PropTypes.func,
        onEquationTextAreaChange: PropTypes.func,
        onTextChange: PropTypes.func,
        requestIds: PropTypes.object,
        testEquation: PropTypes.func,
        testValue: PropTypes.string,
        testValues: PropTypes.func,
        toggleEquationValidState: PropTypes.func,
        updateTestButtonStatus: PropTypes.func,
        validateEquation: PropTypes.func,
        validateEquationToggle: PropTypes.func,
        validateResponse: PropTypes.object,
    };

    static defaultProps = {
        testValue: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            equationText: "",
            isEquationValidated: false,
            showValidEquationDialog: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.requestIds) {
            if (nextProps.requestIds[this.validateRequestId] === "SUCCESS") {
                this.setState(
                    {
                        showValidEquationDialog: true,
                    },
                    () => {
                        this.toggleEquationValidState(true);
                        this.props.updateTestButtonStatus();
                        this.validateRequestId = null;
                    }
                );
            } else if (nextProps.requestIds[this.validateRequestId] === "FAILURE") {
                this.validateRequestId = null;
            }
        }
        if (
            nextProps.equation &&
            (!this.props.equation || nextProps.equation.equation !== this.props.equation.equation)
        ) {
            this.setState({
                equationText: nextProps.equation[model.PROPS_EQUATION],
            });
        }
        if (
            nextProps.validateResponse &&
            nextProps.validateResponse !== this.props.validateResponse
        ) {
            if (nextProps.validateResponse.equationRepaired) {
                this.onChange(nextProps.validateResponse.equation);
            }
        }
    }

    toggleEquationValidState = (isEquationValidated = true) => {
        this.setState(
            {
                isEquationValidated,
            },
            () => {
                this.props.toggleEquationValidState &&
                    this.props.toggleEquationValidState(isEquationValidated);
            }
        );
    };

    onChange = (equationText = "") => {
        if (equationText.toUpperCase() !== this.state.equationText.toUpperCase()) {
            this.toggleEquationValidState(false);
            this.props.validateEquationToggle(false);
        }
        this.setState(
            {
                equationText,
            },
            () => {
                this.props.onEquationTextAreaChange(equationText, this.state.isEquationValidated);
            }
        );
    };

    toggleModal = (modalKey) => {
        this.setState({
            [modalKey]: false,
        });
    };

    onValidateEquation = () => {
        const { needs, validateEquation } = this.props;
        this.setState(
            {
                isEquationValidated: true,
            },
            () => {
                if (validateEquation) {
                    this.validateRequestId = needs([validateEquation(this.state.equationText)]);
                }
            }
        );
    };

    render() {
        const { formatMessage } = this.props;
        return (
            <Section className="equation-text-area-container">
                <SubSection className="form-section-child-stretch form-section-row-2">
                    <TextArea
                        required
                        tabIndex={0}
                        placeholderText={formatMessage(messages.formula)}
                        maxLength={10000000}
                        width={810}
                        value={this.state.equationText}
                        onChange={(value) => {
                            this.onChange(value);
                        }}
                        containerClassNames={[
                            "formula-text-area",
                            getAgBytesErrorClassNames(
                                [581, 582, 583, 584, 600],
                                this.props.apiErrors
                            ),
                        ]}
                    />
                    <div className="equation-text-button-container">
                        <Button type="validate" onClick={this.onValidateEquation} />
                        <div className="shift-button">
                            <Button
                                type="test"
                                disabled={!this.state.isEquationValidated}
                                onClick={this.props.testEquation}
                            />
                        </div>
                        <div className="rec-output-text">
                            {formatMessage(messages.recOutput)}: <span>{this.props.testValue}</span>
                        </div>
                    </div>
                </SubSection>
                <DialogBox
                    title=""
                    isOpen={this.state.showValidEquationDialog}
                    onClose={() => this.toggleModal("showValidEquationDialog")}
                >
                    {formatMessage(messages.equationValidConfirmation)}
                </DialogBox>
            </Section>
        );
    }
}

export default EquationTextArea;
