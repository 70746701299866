import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const PlantingIcon = withSvgIcon(
    <g>
        <path d={"M21.7,36l-0.8-0.3c-0.3-0.1-0.4,0.4-0.1,0.5l0.8,0.3C21.8,36.6,22,36.1,21.7,36z"} />
        <path
            d={
                "M17.5,34.8v-0.1l-0.4,0.2l0.1,0.3c0.1,0.3,0.6,0.2,0.5-0.1c-0.1-0.2-0.1-0.3-0.3-0.5c-0.1-0.2-0.4,0-0.4,0.2" +
                "v0.1c0,0.1,0.1,0.3,0.3,0.3C17.4,35.1,17.5,35,17.5,34.8L17.5,34.8z"
            }
        />
        <path
            d={
                "M35.5,40.8c-0.4,0-0.8-0.1-1.2-0.1c0.2-0.3,0.3-0.7,0.4-1v-0.1l0,0v-0.1c0.1-0.3-0.3-0.5-0.5-0.2l-0.5,1.1" +
                "l0,0l-0.1,0.2c-0.9,0.1-1.8,0.2-2.7,0.3c-0.3,0-0.6,0-0.8,0c-0.1,0-0.2,0-0.2,0c-0.7-0.4-1.4-0.6-2-1c-0.5-0.5-0.9-1.1-1.4-1.6h0.1" +
                "c1.9,0.4,3.9,0.5,5.8,0.3c0.1,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3c-2.7-0.2-5.4-0.8-7.9-1.8c-1.1-0.5-2.2-0.9-3.3-1.2" +
                "c-0.9-0.1-1.7-0.3-2.6-0.6v-11c-0.1-0.4-0.1-0.7,0-1.1c-0.1-0.4,0-0.8,0.1-1.1c0.3-0.6,0.7-1.2,1.1-1.7c0.2-0.6,0.6-1.1,1-1.5" +
                "c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0l0,0c1.1,0.2,3.3,0.3,4.7-0.8" +
                "c1.8-1.5,1.7-4.9,1.7-5.1v-0.4l-0.4-0.1c-0.1,0-3.5-0.8-5.3,0.7c-1.1,1.1-1.7,2.5-1.6,4.1c-0.5,0.4-1,0.9-1.4,1.4" +
                "c-0.5,0.6-0.9,1.1-1.2,1.5L18,12.2c1.2-1,3.4-3.3,3.4-5.8c0-3.3-3.8-6.2-4-6.3L17.2,0l-0.3,0.2c-0.2,0.1-4,3-4,6.3" +
                "c0,2.5,2.2,4.8,3.3,5.8l0.4,6.1c-0.8-1.2-1.8-2.1-2.9-2.9l-0.8-0.4c-0.1-1.5-0.6-4-2.3-5.2C8.3,8.1,4.3,9.2,4.1,9.3L3.8,9.4v0.4" +
                "c0,0.2,0,4.3,2.3,6.1c1.8,1.3,4.5,1,5.8,0.7c2.1,1.4,4.6,3.8,4.7,7.2c0.2,5.9,0.1,9.5,0,10.4c-3.4,5.7-12.2,2-16.6,6.8" +
                "c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.2,0.1,0.3,0c1.8-1,3.1-1.8,5.2-1.9c1.3-0.1,2.5,0,3.8-0.1c-0.6,1-0.7,2.3-1.3,3.2" +
                "c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0-0.3,0-0.4,0.1c-1.4,0-2.7,0-4.1,0.1c-0.3,0-0.3,0.5,0,0.5c1.3-0.1,2.7-0.2,4.1-0.1" +
                "c-0.9,1-2,1.8-3.3,2.2c-0.1,0-0.2,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.2c1.7-0.2,3.3-1,4.6-2.2c1.2-1.3,1.8-3.3,2.5-5" +
                "c0-0.1,0-0.1,0-0.2c1.9-0.4,3.7-1.3,5.2-2.7c0.1,0.4,0.2,0.8,0.4,1.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.4,0.2,0.9,0.4,1.3" +
                "c-1,0.4-1.9,0.9-2.8,1.5c0,0-0.1,0.1-0.1,0.1c-0.6,0.4-1.2,1-1.6,1.6c-1,1.2-1.6,2.7-1.7,4.2c-0.3,0.7-0.6,1.4-1.1,2" +
                "c-0.2,0.2,0.1,0.6,0.4,0.3c0.4-0.4,0.7-0.9,1-1.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.3,0-0.4c0.3-0.8,0.6-1.5,1-2.4" +
                "c0-0.1,0.1-0.1,0.1-0.2c0.1,0.4,0.3,0.7,0.5,1c0.3,0.4,0.8,1.3,0.6,1.8c0,0.1,0.1,0.3,0.2,0.3c0.1,0,0.2,0,0.3-0.2" +
                "c0.1-0.6-0.1-1.2-0.4-1.7c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.4-0.4-0.8-0.4-1.2c1.1-1.5,3.1-2.6,4.7-3.6c0.2,0.6,0.4,1.3,0.5,2" +
                "c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1h-0.1c-0.5,0.4-1.1,0.8-1.6,1.1c-0.5,0.3-1.1,0.5-1.7,0.6c-0.1,0-0.2,0.2-0.2,0.3" +
                "c0,0.1,0.1,0.1,0.2,0.2c0.3,0.1,0.6,0.1,0.9,0c0.7-0.1,1.4-0.4,2-0.9l0.6-0.4c0,0.1,0,0.1,0,0.2c-0.4,1.4-1.1,2.6-0.6,3.9" +
                "c0,0.3,0.1,0.5,0.1,0.8c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.5,0.1,0.5-0.1c-0.1-0.8,0-1.7,0.2-2.5" +
                "c0.5-1.2,0.9-2.4,1.1-3.7c0.4,0.7,0.8,1.4,1.3,2.1c0.3,0.4,0.7,0.7,1.1,1c-0.1,0-0.1,0.1-0.1,0.2c-0.2,0.8-0.7,1.4-1.4,1.8" +
                "c-0.3,0.1,0,0.6,0.3,0.4c0.8-0.4,1.4-1.1,1.6-2c0.8,0.7,1.6,1.4,1.5,2.3c0,0.3,0.4,0.5,0.5,0.1c0.2-1.2-0.5-1.8-1.5-2.5" +
                "c-1.4-1.2-2.6-2.6-3.3-4.3c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.2-0.5-0.3-0.8c1.3,0.1,2.6,0.4,3.8,0.7c0,0,0,0.1,0,0.1" +
                "c0.3,1.4,0.8,2.7,1.4,4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.2,0.5-0.1,0.4-0.3c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1-0.1-0.3-0.1-0.4" +
                "c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.6-0.3-1.2-0.3-1.8c0-0.1,0-0.2,0-0.3l0.4,0.3c0.6,0.6,1.2,1.4,1.6,2.1c0.1,0.1,0.2,0.2,0.3,0.1" +
                "c0.1-0.1,0.2-0.2,0.1-0.3c-0.3-1-0.9-1.8-1.7-2.3c-0.3-0.3-0.6-0.5-0.9-0.7c-1.5-0.9-3.4-1-5.1-1.3c0,0,0,0-0.1,0" +
                "c-0.1-0.5-0.3-0.9-0.4-1.3c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5c0-0.2,0.1-0.4,0.1-0.6c2,0.4,3.9,0.9,5.8,1.6c0,0.1,0,0.1,0,0.2" +
                "c0.4,0.5,0.8,0.9,1.3,1.3c0.9,1,1.9,1.8,3,2.5c0.6,0.4,1.3,0.8,2,1c0.8,0.3,1.8,0.3,2.6,0.6c0.4,0.2,0.7,0.4,1,0.8" +
                "c0.2,0.2,0.5-0.1,0.4-0.3l0,0l0,0l0,0c-0.2-0.3-0.5-0.5-0.8-0.7c-0.9-0.4-1.8-0.7-2.8-0.9c-0.3-0.1-0.7-0.2-1-0.4l1.1-0.2l1-0.1" +
                "c1.1-0.1,2.2-0.2,3.3-0.2C35.8,41.3,35.8,40.8,35.5,40.8z M22.3,15.8c0.3-0.6,2.8-1.5,2.8-1.5c-0.8,0.4-1.6,0.9-2.2,1.4" +
                "c-0.4,0.5-0.7,1-1,1.6C21.9,16.7,22.1,16.2,22.3,15.8z M10.5,13.6c-0.8-0.7-1.7-1.2-2.7-1.7c0,0,3,1.2,3.4,1.8" +
                "c0.3,0.6,0.5,1.1,0.5,1.8C11.4,14.8,11,14.1,10.5,13.6z M18.7,9.4c-0.3,0.9-0.7,1.7-1.3,2.4c0.4-1,0.6-2.2,0.7-3.3" +
                "c-0.1-1.6-0.5-3.1-1-4.7C17.1,3.9,19,8.3,18.7,9.4L18.7,9.4z M17.4,35L17.4,35l0.1-0.1l0,0L17.4,35L17.4,35z M21.4,36.2l-0.2-0.1" +
                "L21.4,36.2C21.4,36.2,21.4,36.2,21.4,36.2L21.4,36.2z"
            }
        />
    </g>,
    "0 0 40 50",
    "event-label-icon"
);
