import React, { Component, ReactElement, ReactNode } from "react";
import classnames from "classnames";
import _ from "lodash";
import { DownArrowIcon } from "../../icons";
import "./tree-view.css";

export interface IRecordObject {
    children?: IRecordChildObject[];
    isAssigned?: boolean;
    isOpen: boolean;
    labelName: string;
    nodeIndex?: string;
    id?: string;
}

export interface IRecordChildObject {
    categoryIndex?: number;
    categoryName?: number;
    guid?: string;
    isAssigned?: boolean;
    isOpen?: boolean;
    labelName?: string;
    name?: string;
    nodeIndex?: string;
    useOnly?: string;
}

export interface INodeClicked {
    record?: IRecordObject;
    node?: IRecordObject;
    tree?: IRecordObject[];
}

export interface ITreeNodeProps {
    childrenKey?: string;
    displayLabel?: string;
    hasChildrenKey?: string;
    isChild?: boolean;
    isOpenKey?: string;
    nodeItemContainerClass?: string;
    onNodeClicked?: (node: IRecordObject, tree: IRecordObject[]) => void;
    record?: IRecordObject;
    renderNodes?: (renderNodes?: unknown) => ReactNode;
    renderRowLabel?: (row) => JSX.Element;
    rowRenderer?: (record: IRecordObject) => void;
    tree?: IRecordObject[];
    nodeIndex?: string;
}

const TreeNode = ({
    childrenKey,
    displayLabel,
    hasChildrenKey,
    isChild,
    isOpenKey,
    nodeItemContainerClass,
    onNodeClicked,
    record,
    renderNodes,
    renderRowLabel,
    rowRenderer,
    tree,
}: ITreeNodeProps) => {
    const className = classnames(
        record[isOpenKey] ? "collapse-button-rotate-45" : "collapse-button-rotate-90",
        "expand-collapse-icon"
    );
    const isOpen = record[isOpenKey],
        children = record[childrenKey],
        { nodeIndex } = record;

    const childrenNodes = isOpen ? children : [];
    const hasChild = (children && children.length > 0) || record[hasChildrenKey];
    const childrenClassName = isChild ? "node-child-container" : "";
    return (
        <div
            className={classnames("tree-node-container", nodeItemContainerClass, childrenClassName)}
            key={nodeIndex}
        >
            <div className="tree-view-parent">
                {rowRenderer && rowRenderer(record)}
                {hasChild && (
                    <div className="expand-icon" onClick={() => onNodeClicked(record, tree)}>
                        <DownArrowIcon className={className} />
                    </div>
                )}
                {renderRowLabel ? (
                    renderRowLabel(record)
                ) : (
                    <div className="label">{record[displayLabel]}</div>
                )}
            </div>
            {renderNodes({ records: childrenNodes, isChild: true })}
        </div>
    );
};

export interface ITreeViewProps {
    childrenKey?: string;
    displayLabel?: string;
    hasChildrenKey?: string;
    isChild?: boolean;
    isOpenKey?: string;
    nodeItemContainerClass?: string;
    onNodeClicked?: (nodeClicked: INodeClicked) => void;
    record?: IRecordObject;
    renderNodes?: (renderNodes: Record<string, unknown>) => void;
    renderRowLabel?: (row) => JSX.Element;
    rowRenderer?: (node: ReactNode) => void;
    tree?: IRecordObject | IRecordObject[];
    data?: IRecordObject[];
    containerClassName?: string;
}

export interface ITreeViewState {
    tree: IRecordObject[];
}

class TreeView extends Component<ITreeViewProps, ITreeViewState> {
    static defaultProps = {
        isOpenKey: "isOpen",
        childrenKey: "children",
        hasChildrenKey: "hasChildren",
    };

    constructor(props: ITreeViewProps) {
        super(props);
        this.state = {
            tree: props.data,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ITreeViewProps): void {
        if (!_.isEqual(nextProps.data, this.props.data)) {
            this.setState({
                tree: nextProps.data,
            });
        }
        return null;
    }

    closeChildren = (node = null): void => {
        if (node) {
            const { childrenKey, isOpenKey } = this.props;
            node[childrenKey] &&
                _.reduce(node[childrenKey], (result, nodeItem) => {
                    nodeItem[isOpenKey] = false;
                    const nestedChildren = nodeItem[childrenKey];
                    if (nestedChildren) {
                        if (Array.isArray(nestedChildren)) {
                            for (const child of nestedChildren) {
                                this.closeChildren(child);
                            }
                        } else {
                            this.closeChildren(nestedChildren);
                        }
                    }
                });
        }
    };

    onNodeClicked = (node: IRecordObject, tree: IRecordObject[]): void => {
        if (node) {
            const { isOpenKey } = this.props;
            node[isOpenKey] = !node[isOpenKey];
            this.closeChildren(node);
            _.set(tree, node.nodeIndex, node);
            this.setState({ tree }, () => {
                if (this.props.onNodeClicked) {
                    this.props.onNodeClicked({ node, tree });
                }
            });
        }
    };

    public addIndexToRecord = (
        records: IRecordObject[] = [],
        parentIndex: number | string = 0
    ): ReactNode => {
        const { addIndexToRecord } = this;
        const { childrenKey } = this.props;
        let index = 0;
        return _.reduce(
            records,
            (tree, record) => {
                const parentIndexString = parentIndex
                    ? `${parentIndex}.${childrenKey}.${index++}`
                    : `${index++}`;
                let newRecord = { ...record, nodeIndex: parentIndexString };
                const children = record[childrenKey] || [];
                if (children.length > 0) {
                    newRecord = {
                        ...newRecord,
                        [childrenKey]: addIndexToRecord(children, `${parentIndexString}`),
                    };
                }
                tree.push(newRecord);
                return tree;
            },
            []
        );
    };

    private renderNodes = ({ records, isChild = false }): ReactNode[] => {
        const { tree } = this.state;
        const {
            nodeItemContainerClass,
            displayLabel,
            rowRenderer,
            renderRowLabel,
            isOpenKey,
            childrenKey,
            hasChildrenKey,
        } = this.props;
        const { onNodeClicked, renderNodes } = this;
        return (
            records &&
            records.map((record) =>
                TreeNode({
                    childrenKey,
                    displayLabel,
                    hasChildrenKey,
                    isChild,
                    isOpenKey,
                    nodeItemContainerClass,
                    onNodeClicked,
                    record,
                    renderNodes,
                    renderRowLabel,
                    rowRenderer,
                    tree,
                })
            )
        );
    };

    render(): ReactElement {
        const className = this.props.containerClassName;
        const nodes = this.addIndexToRecord(this.state.tree);
        return (
            <div className={classnames("tree-view-container", className)}>
                {this.renderNodes({ records: nodes })}
            </div>
        );
    }
}

export default TreeView;
