import { all } from "redux-saga/effects";

import { accountSagas } from "./setup/account/data";
import addressSaga from "./setup/component/address/data/sagas";
import { countryStateSagas } from "./agBytes/country-state/data";
import { cropSagas } from "./agBytes/crop/data";
import { cropLogSagas } from "./agBytes/crop/log-data";
import { customerSagas } from "./setup/customer/data";
import { equationParameterSagas } from "./setup/equation-parameter/data";
import { equationParameterLogSagas } from "./setup/equation-parameter/log-data";
import { equationSetsSagas } from "./setup/equation-sets/application/data";
import { equationLogSagas } from "./setup/equation-sets/common/equation-log/data";
import { equationSetsPlantingSagas } from "./setup/equation-sets/planting/data";
import { equationListSagas, equationTextSaga } from "./setup/equation-list/data";
import { equipmentSagas } from "./agBytes/equipment/data";
import { equipmentLogSagas } from "./agBytes/equipment/log-data";
import { geneLogSagas } from "./agBytes/gene/log-data";
import { geneSagas } from "./agBytes/gene/data";
import { growthOrderListSagas } from "./agBytes/growth-order-list/data";
import { growthStageLogSagas } from "./agBytes/growth-stages/growth-stage-log/data";
import { growthStageSagas } from "./agBytes/growth-stages/data";
import { importFilterSaga } from "./setup/import-filter-settings/data";
import licenseSaga from "./setup/component/license/data/sagas";
import { nutrientSagas } from "./agBytes/nutrient/data";
import { nutrientRemovalRateSagas } from "./setup/nutrient-removal-rate/data";
import { nutrientRemovalRateLogSagas } from "./setup/nutrient-removal-rate/log-data";
import { nutrientTargetValueSagas } from "./setup/nutrient-target-value/data";
import { nutrientTargetValueLogSagas } from "./setup/nutrient-target-value/log-data";
import { observationSagas } from "./agBytes/observation/data";
import { observationLogSagas } from "./agBytes/observation/log-data";
import { ownerSagas } from "./setup/owner/data";
import { personSagas } from "./setup/person/data";
import { picklistSagas } from "./agBytes/picklist/data";
import { picklistLogSagas } from "./agBytes/picklist/log-data";
import { importProductSaga, productSagas } from "./agBytes/product/data";
import { productAssignmentSagas } from "./setup/product-assignment/data";
import { productAssignmentLogSagas } from "./setup/product-assignment/log-data";
import { productLogSagas } from "./agBytes/product/product-log/data";
import { productPackageListSagas } from "./agBytes/product-package-list/data";
import { sampleAttributeSagas } from "./agBytes/sample-attribute/data";
import { setupReportBooksSaga } from "./setup/report-books/data/sagas";
import { surfaceDefaultsSagas } from "./setup/surface-defaults/data";
import { surfaceDefaultsLogSagas } from "./setup/surface-defaults/log-data";
import { systemProfileSaga } from "./setup/system-profile/data";
import { traitSagas } from "./agBytes/trait/data";
import { traitLogSagas } from "./agBytes/trait/log-data";
import { unitSagas } from "./agBytes/unit/data";
import { userSagas, resetPasswordSaga } from "./setup/user/data";
import { userRoleSagas, createUserRoleSaga } from "./setup/user-role/data";
import { vendorSagas } from "./setup/vendor/data";
import { varietyHybridSagas } from "./agBytes/variety-hybrid/data";
import { varietyHybridLogSagas } from "./agBytes/variety-hybrid/log-data";
import { varietyHybridAttributeSagas } from "./agBytes/variety-hybrid-attribute/data";
import { varietyHybridAttributeLogSagas } from "./agBytes/variety-hybrid-attribute/log-data";
import setupSagas from "./setup/batch-zapper/data/sagas";
import surfaceSagas from "./setup/surface-defaults/data/sagas";
import { templateManagerSagas } from "./setup/template-manager/data";
import { equipmentSetupSagas } from "./setup/equipment/data";
import { orgLevelSagas } from "./setup/org-level/data";
import { preferenceSagas } from "./setup/preference/data";
import { locationMigrationSagas } from "./setup/location-migration/data";
import { setupReleaseNotesSaga } from "./setup/release-notes/data";

const AdminSagas = function* () {
    yield all([
        accountSagas(),
        addressSaga(),
        countryStateSagas(),
        createUserRoleSaga(),
        cropLogSagas(),
        cropSagas(),
        customerSagas(),
        equationListSagas(),
        equationLogSagas(),
        equationParameterLogSagas(),
        equationParameterSagas(),
        equationSetsSagas(),
        equationSetsPlantingSagas(),
        equationTextSaga(),
        equipmentLogSagas(),
        equipmentSagas(),
        equipmentSetupSagas(),
        geneLogSagas(),
        geneSagas(),
        growthOrderListSagas(),
        growthStageLogSagas(),
        growthStageSagas(),
        importFilterSaga(),
        importProductSaga(),
        licenseSaga(),
        locationMigrationSagas(),
        nutrientRemovalRateLogSagas(),
        nutrientRemovalRateSagas(),
        nutrientSagas(),
        nutrientTargetValueLogSagas(),
        nutrientTargetValueSagas(),
        observationLogSagas(),
        observationSagas(),
        orgLevelSagas(),
        ownerSagas(),
        personSagas(),
        picklistLogSagas(),
        picklistSagas(),
        preferenceSagas(),
        productAssignmentSagas(),
        productAssignmentLogSagas(),
        productLogSagas(),
        productPackageListSagas(),
        productSagas(),
        resetPasswordSaga(),
        sampleAttributeSagas(),
        setupReportBooksSaga(),
        setupSagas(),
        surfaceDefaultsSagas(),
        surfaceDefaultsLogSagas(),
        surfaceSagas(),
        systemProfileSaga(),
        templateManagerSagas(),
        traitLogSagas(),
        traitSagas(),
        unitSagas(),
        userRoleSagas(),
        userSagas(),
        varietyHybridAttributeLogSagas(),
        varietyHybridAttributeSagas(),
        varietyHybridLogSagas(),
        varietyHybridSagas(),
        vendorSagas(),
        setupReleaseNotesSaga(),
    ]);
};

export default AdminSagas;
