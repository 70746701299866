import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../i18n-messages";
import { TextInput, Section, SubSection, Checkbox } from "~/core";
import "./component.css";

const API_URL = "apiUrl";

class AgvanceIntegration extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        isEditMode: PropTypes.any,
        onTextChange: PropTypes.func,
        aliasList: PropTypes.object,
        record: PropTypes.object,
        isAgvancePersonality: PropTypes.bool,
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { aliasList, isAgvancePersonality, onTextChange, record } = this.props;
        return (
            <Section
                headerText={formatMessage(messages.agvanceIntegration)}
                className="agvance-integration"
            >
                {!(API_URL in aliasList) ? null : (
                    <TextInput
                        placeholderText={formatMessage(messages.apiUrl)}
                        maxLength={400}
                        onChange={(value) => onTextChange(aliasList[API_URL], value)}
                        value={record[aliasList[API_URL]]}
                        containerClassNames={["text-input-stretch"]}
                    />
                )}
                <SubSection>
                    <TextInput
                        placeholderText={formatMessage(messages.databaseId)}
                        maxLength={50}
                        onChange={(value) => onTextChange(aliasList.databaseId, value)}
                        value={record[aliasList.databaseId]}
                        disabled={
                            this.props.isEditMode && record[aliasList.isAgvanceCustomerExists]
                        }
                    />
                    {!isAgvancePersonality ? (
                        <Checkbox
                            label={formatMessage(messages.requireCustomerSearch)}
                            onChange={(e, value) =>
                                onTextChange(aliasList.requireCustomerSearchFlag, value)
                            }
                            value={record[aliasList.requireCustomerSearchFlag]}
                        />
                    ) : null}
                    {!isAgvancePersonality ? null : (
                        <TextInput
                            placeholderText={formatMessage(messages.userId)}
                            maxLength={50}
                            onChange={(value) => onTextChange(aliasList.userId, value)}
                            value={record[aliasList.userId]}
                        />
                    )}
                </SubSection>
                {!isAgvancePersonality ? null : (
                    <SubSection className="agvance-integration-checkbox-cont">
                        <TextInput
                            placeholderText={formatMessage(messages.userPassword)}
                            maxLength={100}
                            password
                            onChange={(value) => onTextChange(aliasList.userPassword, value)}
                            value={record[aliasList.userPassword]}
                            containerClassNames={["text-input-stretch"]}
                        />
                        <Checkbox
                            label={formatMessage(messages.requireCustomerSearch)}
                            onChange={(e, value) =>
                                onTextChange(aliasList.requireCustomerSearchFlag, value)
                            }
                            value={record[aliasList.requireCustomerSearchFlag]}
                        />
                    </SubSection>
                )}
            </Section>
        );
    }
}

export default injectIntl(AgvanceIntegration);
