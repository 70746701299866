export const SUCCESS = "SUCCESS";
export const NESTED_GRID_SUCCESS = "NESTED_GRID_SUCCESS";
export const FAILURE = "FAILURE";
export const PENDING = "PENDING";
export const CLEAR_REQUEST_IDS = "CLEAR_REQUEST_IDS";
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS";
export const SET_REQUEST_ID = "SET_REQUEST_ID";
export let requestId;

export function initializeRequestId(value = 0) {
    requestId = value;
}

export function getRequestId() {
    return ++requestId;
}

export function prepareSuccessActionType(actionType = null) {
    return `${actionType.substring(0, actionType.lastIndexOf("_"))}_SUCCESS`;
}

export function prepareFailureActionType(actionType = null) {
    return `${actionType.substring(0, actionType.lastIndexOf("_"))}_FAILED`;
}
