import React, { Component } from "react";
import classnames from "classnames";
import { SelectInput, withAsyncOptions } from "~/core";
import { SearchIcon } from "~/core/icons";
import "./auto-search.css";

const searchIcon = (
    <span key="search" className="search-icon">
        <SearchIcon className="search-icon" />
    </span>
);

export interface IAutoSearchProps {
    allowEmptyOptions?: boolean;
    autoFocus?: boolean;
    classNames?: string[];
    clearOnFocus?: boolean;
    clearOnSelection?: boolean;
    clearable?: boolean;
    disabled?: boolean;
    excludedValues?: any[];
    //TODO: fix the type here after selectInput has been converted to ts
    getAutoSearchList?: (
        userguid: string,
        filterValue: string,
        secondarySearchFilter?: Record<string, any>
    ) => Promise<Record<string, any>[]>;
    initialFilterStr?: string;
    itemList: Record<string, any>[];
    isRepeatedlyRefreshed?: boolean;
    keyProp: string;
    nameProp: string;
    noOptionsRenderer?: () => void;
    onResetOptions?: () => void;
    // TODO: wfix the type here after selectInput has been converted to ts
    onSelection: (selected: Record<string, any>) => void;
    openOnFocus?: boolean;
    optionHeight?: number;
    placeholderText?: string;
    postPrimaryProp?: string[];
    required?: boolean;
    resetOptions?: boolean;
    secondaryPropList?: string[];
    titlePropList?: string[];
    selectedValue?: string;
    showTopLabel?: boolean;
    showSearchIcon?: boolean;
    userGuid?: string;
    onSearchChange?: (searchValue: string) => void;
    forceUpdate?: boolean;
    secondarySearchFilter?: Record<string, any>;
}

export interface IAutoSearchState {
    originalItemList: Record<string, any>[];
    filteredItemList: Record<string, any>[];
    searchValue: string;
    lastSelected: Record<string, any>;
    resetOptions: boolean;
}
export class AutoSearch extends Component<IAutoSearchProps, IAutoSearchState> {
    //TODO: fix the type here after selectInput has been converted to tsx
    AsyncSelectInput: any;

    static defaultProps = {
        allowEmptyOptions: true,
        clearOnFocus: false,
        clearOnSelection: false,
        clearable: true,
        disabled: false,
        excludedValues: [],
        initialFilterStr: "",
        isRepeatedlyRefreshed: false,
        optionHeight: 46,
        placeholderText: "",
        secondaryPropList: [],
        titlePropList: [],
        showTopLabel: true,
        showSearchIcon: true,
        userGuid: "",
        forceUpdate: false,
        secondarySearchFilter: {},
    };

    constructor(props: IAutoSearchProps) {
        super(props);
        this.AsyncSelectInput = withAsyncOptions(SelectInput, this.getOptions);

        const selected = props.selectedValue
            ? props.itemList.find((item) => {
                  return props.selectedValue === item[props.keyProp];
              })
            : null;

        this.state = {
            originalItemList: props.itemList,
            filteredItemList: props.itemList,
            searchValue: props.initialFilterStr,
            lastSelected: selected,
            resetOptions: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAutoSearchProps): void {
        if (!nextProps.selectedValue || nextProps.selectedValue !== this.props.selectedValue) {
            const lastSelected = nextProps.selectedValue
                ? this.state.originalItemList.find((item) => {
                      return nextProps.selectedValue === item[nextProps.keyProp];
                  })
                : null;
            this.setState({
                searchValue:
                    (nextProps.clearOnSelection || nextProps.forceUpdate) &&
                    !nextProps.isRepeatedlyRefreshed
                        ? nextProps.initialFilterStr
                        : this.state.searchValue,
                lastSelected,
            });
        }
        if (nextProps.itemList !== this.props.itemList) {
            this.setState({
                originalItemList: nextProps.itemList.filter(
                    (item) =>
                        !this.props.excludedValues.some((exc) => exc === item[this.props.keyProp])
                ),
            });
        }
        if (nextProps.resetOptions !== this.props.resetOptions) {
            this.setState({
                resetOptions: nextProps.resetOptions,
            });
        }
        if (nextProps.initialFilterStr !== this.props.initialFilterStr) {
            this.setState({
                searchValue: this.props.initialFilterStr,
            });
        }
    }

    private _defaultGetAutoSearchList = (
        userGuid: string,
        filterValue: string
    ): Promise<Record<string, any>[]> => {
        if (this.state.resetOptions) {
            this.props.onResetOptions();
        }
        return filterValue === this.AsyncSelectInput.SYMBOL_ALL_OPTIONS
            ? Promise.resolve(this.state.originalItemList)
            : Promise.resolve(
                  this.state.originalItemList.filter((item) => {
                      return (
                          (" " + item[this.props.nameProp])
                              .toLowerCase()
                              .indexOf(" " + filterValue.toLowerCase()) > -1
                      );
                  })
              );
    };

    private getOptions = (filterValue: string): Promise<Record<string, any>[]> => {
        if (this.props.clearOnSelection && filterValue.trim() === "") {
            return Promise.resolve([]);
        }
        //TODO: typing here needs to be changed along with  *getAutoSearchList()*
        const autoSearchFunction: any = this.props.getAutoSearchList
            ? this.props.getAutoSearchList
            : this._defaultGetAutoSearchList;

        return Promise.resolve(
            autoSearchFunction(
                this.props.userGuid,
                filterValue,
                this.props.secondarySearchFilter
            ).then((filteredData) => {
                this.setState({
                    filteredItemList: filteredData.filter(
                        (item) =>
                            !this.props.excludedValues.some(
                                (exc) => exc === item[this.props.keyProp]
                            )
                    ),
                });

                return filteredData
                    .filter(
                        (item) =>
                            !this.props.excludedValues.some(
                                (exc) => exc === item[this.props.keyProp]
                            )
                    )
                    .map((item) => {
                        const joinProps = (propList: string[]) =>
                            propList
                                .map((prop) => item[prop])
                                .filter((x) => x != null)
                                .join(", ");

                        const secondaryLabel = joinProps(this.props.secondaryPropList);
                        const title = joinProps(this.props.titlePropList);
                        const postPrimaryLabel = this.props.postPrimaryProp
                            ? joinProps(this.props.postPrimaryProp)
                            : "";

                        return {
                            value: item[this.props.keyProp],
                            label: item[this.props.nameProp],
                            postPrimaryLabel,
                            secondaryLabel,
                            title,
                        };
                    });
            })
        );
    };

    onSearchChange = (searchValue: string): void => {
        if (searchValue === "") {
            this.setState({
                lastSelected: null,
                searchValue,
            });
        } else {
            this.setState({
                searchValue,
            });
        }
        if (this.props.onSearchChange) {
            this.props.onSearchChange(searchValue);
        }
    };

    private onItemSelection = (keyValue: string): void => {
        const selected = this.state.filteredItemList.find((item) => {
            return item[this.props.keyProp] === keyValue;
        });
        this.setState(
            {
                lastSelected: selected,
            },
            () => {
                this.props.onSelection(selected);
            }
        );
    };

    private optionRenderer = ({ option, isSelected, isHighlighted, matchPos, matchLen }) => {
        const className = classnames("select-form-input-option", {
            selected: isSelected,
            "filter-match": isHighlighted,
        });

        const noFilter = matchPos === -1;
        const preMatch = noFilter ? option.label : option.label.slice(0, matchPos);
        const match = noFilter ? "" : option.label.slice(matchPos, matchPos + matchLen);
        const postMatch = noFilter ? "" : option.label.slice(matchPos + matchLen);
        return (
            <div className={className} title={option.title}>
                <div className="first-line">
                    <span className="pre-match">{preMatch}</span>
                    <span className="match">{match}</span>
                    <span className="post-match">{postMatch}</span>
                </div>
                <div className="second-line">{option.secondaryLabel}</div>
                <div className="post-primary-label">{option.postPrimaryLabel}</div>
            </div>
        );
    };

    render(): JSX.Element {
        const AsyncSelectInput = this.AsyncSelectInput;
        const { lastSelected, searchValue, resetOptions } = this.state;
        const {
            autoFocus,
            classNames,
            clearable,
            nameProp,
            noOptionsRenderer,
            openOnFocus,
            showTopLabel,
            showSearchIcon,
            allowEmptyOptions,
        } = this.props;
        const hasName = lastSelected && lastSelected[nameProp];
        const initialFilterStr = hasName ? lastSelected[nameProp] : searchValue;
        return (
            <div className="auto-search">
                <AsyncSelectInput
                    arrowRenderer={() => null}
                    autoFocus={autoFocus}
                    clearFilterInputOnBlur={false}
                    clearOnEmptyFilterString={false}
                    clearOnFocus={this.props.clearOnFocus}
                    clearOnSelection={this.props.clearOnSelection}
                    clearable={clearable}
                    containerClassNames={classNames}
                    disabled={this.props.disabled}
                    initialFilterStr={initialFilterStr}
                    inputContainerLeftElements={showSearchIcon ? [searchIcon] : []}
                    noOptionsRenderer={noOptionsRenderer}
                    onChange={this.onItemSelection}
                    onInputChange={this.onSearchChange}
                    openOnFocus={openOnFocus}
                    optionHeight={this.props.optionHeight}
                    optionRenderer={this.optionRenderer}
                    placeholderText={this.props.placeholderText}
                    required={this.props.required}
                    resetOptions={resetOptions}
                    showTopLabel={showTopLabel}
                    allowEmptyOptions={allowEmptyOptions}
                />
            </div>
        );
    }
}
