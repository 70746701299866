import { call, put, select } from "redux-saga/effects";
import { defaultActions } from "./../actions";
import { APIHelpers, FormattingHelpers, SetupAPI, Request, APIError } from "@ai360/core";
import { getModelNameToState } from "./../utils";
// selectors
import { getTheUserGuid } from "~/login";
import moment from "moment";
import "moment-timezone";
import { setApiResult } from "~/core/api/actions";
import { actions as notificationActions } from "~/notifications";
import messages from "../i18n-messages";

export function* convertAgiisRequest(action, service) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(SetupAPI.buildHaasAgiisFiles, action.payload.files, UserGuid);
        const productFileName = `${
            service.CONVERT_AGIIS_PRODUCT_FILE_NAME
        }${FormattingHelpers.getTimeStamp()}`;
        const packagefileName = `${
            service.CONVERT_AGIIS_PACKAGE_FILE_NAME
        }${FormattingHelpers.getTimeStamp()}`;
        const modelName = getModelNameToState(service.modelName);
        if (response) {
            if (response.productBase64String) {
                APIHelpers.downloadBlob(response.productBase64String, productFileName);
                yield put(defaultActions.success({ model: modelName, data: {} }));
            }
            if (response.productPackageBase64String) {
                APIHelpers.downloadBlob(response.productPackageBase64String, packagefileName);
                yield put(defaultActions.success({ model: modelName, data: {} }));
            }
        } else {
            yield put(notificationActions.apiCallError(messages.noResponse, action));
        }
    } catch (error) {
        yield put(defaultActions.failed());
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}

export function* convertAgrianRequest(action, service) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const timeDateString = moment(moment(action.payload._d).format())
            .tz("America/Los_Angeles")
            .format("ddd MMM DD YYYY HH:mm:ss");
        const requestOptions = { UserGuid: UserGuid, Model: timeDateString };
        const response = yield call(
            Request.post,
            service.urls.CONVERT_AGRIAN_URL,
            requestOptions,
            undefined,
            { Accept: "text/html, */*; q=0.01" }
        );
        const productFileName = `${
            service.CONVERT_AGRIAN_PRODUCT_FILE_NAME
        }${FormattingHelpers.getTimeStamp()}`;
        const packagefileName = `${
            service.CONVERT_AGRIAN_PACKAGE_FILE_NAME
        }${FormattingHelpers.getTimeStamp()}`;
        const plantBackfileName = `${
            service.CONVERT_AGRIAN_PLANT_BACK_FILE_NAME
        }${FormattingHelpers.getTimeStamp()}`;
        const modelName = getModelNameToState(service.modelName);
        if (response) {
            if (response.productBase64String) {
                APIHelpers.downloadBlob(response.productBase64String, productFileName);
                yield put(defaultActions.success({ model: modelName, data: {} }));
            }
            if (response.productPackageBase64String) {
                APIHelpers.downloadBlob(response.productPackageBase64String, packagefileName);
                yield put(defaultActions.success({ model: modelName, data: {} }));
            }
            if (response.productPlantBackRestrictionsBase64String) {
                APIHelpers.downloadBlob(
                    response.productPlantBackRestrictionsBase64String,
                    plantBackfileName
                );
                yield put(defaultActions.success({ model: modelName, data: {} }));
            }
        } else {
            yield put(defaultActions.failed());
            yield put(notificationActions.apiCallError(messages.noResponse, action));
        }
    } catch (error) {
        yield put(defaultActions.failed());
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}
