import { v4 as uuid } from "uuid";

import Geometry from "@arcgis/core/geometry/Geometry";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as esriProjection from "@arcgis/core/geometry/projection";
import { GeometryUtils } from "@ai360/core";
import * as models from "./models";
import { AgEventAPI, FieldAPI, EventAPI } from "@ai360/core";

export const EVENT_TYPE_NAME_APPLICATION = "Application";
export const EVENT_TYPE_NAME_EC_DATA = "EC Data";
export const EVENT_TYPE_NAME_HARVEST = "Harvest";
export const EVENT_TYPE_NAME_IRRIGATION = "Irrigation";
export const EVENT_TYPE_NAME_PLANTING = "Planting";
export const EVENT_TYPE_NAME_SAMPLING_SOIL = "Sampling - Soil";
export const EVENT_TYPE_NAME_SAMPLING_TISSUE = "Sampling - Tissue";
export const EVENT_TYPE_NAME_SCOUTING = "Scouting";
export const EVENT_TYPE_NAME_TILLAGE = "Tillage";
export * from "./models";

const { samplePointGuidProperty } = EventAPI;
export { samplePointGuidProperty };

export const NEWABLE_EVENT_TYPES = new Map([
    [EVENT_TYPE_NAME_APPLICATION, (role) => role.agEventsApplication],
    [EVENT_TYPE_NAME_HARVEST, (role) => role.agEventsHarvest],
    [EVENT_TYPE_NAME_IRRIGATION, (role) => role.agEventsIrrigation],
    [EVENT_TYPE_NAME_PLANTING, (role) => role.agEventsPlanting],
    [EVENT_TYPE_NAME_SAMPLING_SOIL, (role) => role.soil],
    [EVENT_TYPE_NAME_SAMPLING_TISSUE, (role) => role.tissue],
    [EVENT_TYPE_NAME_SCOUTING, (role) => role.scouting],
    [EVENT_TYPE_NAME_TILLAGE, (role) => role.agEventsTillage],
]);
/** Defines the methods/properties for all AgEvent classes. */
export interface IAgEventModelMethods extends AgEventAPI.IAgEventModel {
    /** The unique identifier for the model.
     * @type {string}
     */
    agEventGuid?: string;
    /** Determines if *all* of the required data fields have been set.
     * @type {boolean}
     */
    isAllRequiredFieldsSet: boolean;
    /** Determines if *any* of the required data fields have been set.
     * @type {boolean}
     */
    isAnyRequiredFieldSet: boolean;
    /** Resets the model to it's initial state. */
    reset?: () => IAgEventModelMethods;
    /** Updates the model with the input properites.
     * @param {object} newProps - The updated properties to set.
     */
    updateAgEventModel(newProps): IAgEventModelMethods;
}

export enum AreaDimension {
    AREA = "A",
    DIMENSION = "D",
}

export enum PointPlacement {
    CENTROID = "C",
    RANDOM = "R",
}

export enum InterpolationType {
    STANDARD = 0, // No interpolation
    ZONE_INTERPOLATION = 1,
    ZONE_SAMPLING = 2,
}

export class AgEvent implements AgEventAPI.IAgEvent {
    agEventGuid: string | null = "";
    eventAreaGuid: string | null = "";
    agEventTransactionTypeGuid: string;
    agEventModel: IAgEventModelMethods;

    static _getAgEventModelFromTypeInfo(
        agEventTypeInfo: models.AgEventTypeInfo
    ):
        | models.AgEventApplication
        | models.AgEventEcData
        | models.AgEventHarvest
        | models.AgEventPlanting
        | models.SampleSetup
        | models.AgEventTillage
        | models.AgEventScouting
        | models.AgEventUnknown {
        switch (agEventTypeInfo.agEventTransactionTypeName) {
            /* eslint-disable no-fallthrough */
            case EVENT_TYPE_NAME_APPLICATION:
                return Object.freeze(new models.AgEventApplication());
            case EVENT_TYPE_NAME_EC_DATA:
                return Object.freeze(new models.AgEventEcData());
            case EVENT_TYPE_NAME_HARVEST:
                return Object.freeze(new models.AgEventHarvest());
            case EVENT_TYPE_NAME_IRRIGATION:
                return Object.freeze(new models.AgEventIrrigation());
            case EVENT_TYPE_NAME_PLANTING:
                return Object.freeze(new models.AgEventPlanting());
            case EVENT_TYPE_NAME_SAMPLING_SOIL:
                return Object.freeze(new models.SampleSetup(agEventTypeInfo.sampleTypeGuid));
            case EVENT_TYPE_NAME_SAMPLING_TISSUE:
                return Object.freeze(new models.SampleSetup(agEventTypeInfo.sampleTypeGuid));
            case EVENT_TYPE_NAME_TILLAGE:
                return Object.freeze(new models.AgEventTillage());
            case EVENT_TYPE_NAME_SCOUTING:
                return Object.freeze(new models.AgEventScouting());
            default:
                console.error(
                    "Unknown agEventTypeInfo.agEventTransactionTypeName: " +
                        agEventTypeInfo.agEventTransactionTypeName
                );
                return Object.freeze(new models.AgEventUnknown());
        }
    }

    static resetEventModel(agEvent: AgEvent): AgEvent {
        const agEventModel =
            typeof agEvent.agEventModel.reset === "function"
                ? agEvent.agEventModel.reset()
                : AgEvent._getAgEventModelFromTypeInfo(agEvent.agEventTypeInfo);
        return AgEvent.updateAgEvent(agEvent, { agEventModel });
    }

    static updateAgEvent(
        agEvent: AgEvent,
        newProps: Partial<AgEventAPI.IAgEvent>
    ): Readonly<AgEvent> {
        console.assert(agEvent instanceof AgEvent);
        const rv = new AgEvent(agEvent.agEventTypeInfo);

        if (
            agEvent.agEventGuid &&
            newProps.agEventGuid == null &&
            (!newProps.agEventModel || newProps.agEventModel.agEventGuid == null)
        ) {
            // the guid has been cleared out b/c it's a new zone, we need to wipe the child table PK's
            if (agEvent.agEventTypeInfo.agEventTransactionTypeName === EVENT_TYPE_NAME_PLANTING) {
                newProps = {
                    ...newProps,
                    agEventModel: agEvent.agEventModel.updateAgEventModel({
                        ...newProps.agEventModel,
                        eventPlantingVarietyHybridList: (newProps.agEventModel &&
                        newProps.agEventModel.eventPlantingVarietyHybridList
                            ? newProps.agEventModel.eventPlantingVarietyHybridList
                            : agEvent.agEventModel.eventPlantingVarietyHybridList
                        ).map((vh) => ({
                            ...vh,
                            agEventGuid: null,
                            eventPlantingVarietyHybridGuid: null,
                        })),
                    }),
                };
            } else if (
                agEvent.agEventTypeInfo.agEventTransactionTypeName === EVENT_TYPE_NAME_APPLICATION
            ) {
                newProps = {
                    ...newProps,
                    agEventModel: agEvent.agEventModel.updateAgEventModel({
                        ...newProps.agEventModel,
                        productMixList: (newProps.agEventModel &&
                        newProps.agEventModel.productMixList
                            ? newProps.agEventModel.productMixList
                            : agEvent.agEventModel.productMixList
                        ).map((pm) => ({
                            ...pm,
                            productMixGuid: null,
                            eventRecGuid: null,
                            products: pm.products.map((p) => ({
                                ...p,
                                productMixProductGuid: null,
                                productMixGuid: null,
                            })),
                            nutrients: pm.nutrients.map((n) => ({
                                ...n,
                                productMixNutrientGuid: null,
                                productMixGuid: null,
                            })),
                        })),
                    }),
                };
            }
        }

        Object.assign(rv, agEvent, newProps);
        return Object.freeze(rv);
    }

    static prepareSamplingEvent(agEvent: AgEvent, newBatchGuid?: string): AgEvent {
        const samplePoints: AgEventAPI.ISoilSamplePoint[] | AgEventAPI.ITissueSamplePoint[] =
            agEvent.agEventModel.samplePoints;
        const isTissue = samplePoints.some(
            (sampleItem) => sampleItem.eventSampleTissuePointGuid !== undefined
        );
        const guidProp = isTissue ? samplePointGuidProperty.TISSUE : samplePointGuidProperty.SOIL;
        const isComposite = samplePoints.some((sp) =>
            samplePoints.some(
                (sp2) => sp2.sampleId === sp.sampleId && sp.sequenceId !== sp2.sequenceId
            )
        );

        samplePoints.forEach(
            (sampleItem: AgEventAPI.ISoilSamplePoint | AgEventAPI.ITissueSamplePoint) => {
                if (this.isTissueSamplePoint(sampleItem)) {
                    sampleItem.scanCode = "";
                }
                sampleItem.agEventGuid = "";
                sampleItem.batchSamplingEventGuid = newBatchGuid ? newBatchGuid : "";
                sampleItem.isNew = true;
                sampleItem[guidProp] = uuid();
                sampleItem.status = 0;
                sampleItem.labelMethod = isComposite
                    ? AgEventAPI.labelMethods.COMPOSITE
                    : AgEventAPI.labelMethods.DEFAULT;
            }
        );
        return agEvent;
    }

    static isTissueSamplePoint(
        sampleItem: AgEventAPI.ISoilSamplePoint | AgEventAPI.ITissueSamplePoint
    ): sampleItem is AgEventAPI.ITissueSamplePoint {
        //magic happens here
        return (sampleItem as AgEventAPI.ITissueSamplePoint).scanCode !== undefined;
    }

    constructor(public agEventTypeInfo: models.AgEventTypeInfo) {
        this.agEventTransactionTypeGuid = agEventTypeInfo.agEventTransactionTypeGuid;
        this.agEventModel = Object.freeze(AgEvent._getAgEventModelFromTypeInfo(agEventTypeInfo));
    }
}

export const transformSamplePointToApi = (point: AgEventAPI.ISamplePoint): void => {
    const geometry: Geometry = JSON.parse(point.shape);
    const projected = esriProjection.project(
        geometry,
        new SpatialReference({ wkid: FieldAPI.apiSpatialReference })
    ) as Geometry;
    point.shape = JSON.stringify(projected.toJSON()) as any;
};

export const transformSamplePointFromApi = (point: AgEventAPI.ISamplePoint): void => {
    const geometry = GeometryUtils.wktToGeometry(point.shape);
    const projected = esriProjection.project(
        geometry as Geometry,
        new SpatialReference({ wkid: FieldAPI.spatialReference })
    ) as Geometry;
    point.shape = JSON.stringify(projected.toJSON()) as any;
};
