import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as selectors from "../../../selectors";
import * as actions from "../../../actions";
import { EventsPanel_ } from "../components/events-panel";

const mapStateToProps = (state) => ({
    eventStripPreviewData: selectors.getEventStripPreviewData(state),
    importType: selectors.getImportWizardType(state),
    importTemplate: selectors.getImportTemplate(state),
    apiErrorResultList: selectors.getApiErrorResultList(state),
    appendList: selectors.getAppendList(state),
    showProcessing: selectors.getShowProcessing(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchEventStripPreviewData: () => dispatch(actions.fetchEventStripPreviewData()),
    onCancelEvent: (importFileGuidList) => dispatch(actions.cancelImportEvent(importFileGuidList)),
    onCreateEvent: (requestList) => dispatch(actions.createImportEvent(requestList)),
    setEventStripPreviewData: (eventStripPreviewData) =>
        dispatch(actions.setEventStripPreviewData(eventStripPreviewData)),
    setApiErrorResultList: (apiErrorResultList) =>
        dispatch(actions.setApiErrorResultList(apiErrorResultList)),
    setAppendList: (appendList) => dispatch(actions.setAppendList(appendList)),
});

export const EventsPanel = connect(mapStateToProps, mapDispatchToProps)(injectIntl(EventsPanel_));
