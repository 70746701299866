import { all, call, put, select } from "redux-saga/effects";
import { defaultActions } from "./../actions";
import { Request, APIError } from "@ai360/core";
// selectors
import { getTheUserGuid } from "~/login";
import { setApiResult } from "~/core/api/actions";
import { actions as notificationActions } from "~/notifications";

export function* deleteRequest(action = {}, { urls, parentGuid, useNewApi }) {
    const UserGuid = yield select(getTheUserGuid);
    const { selectedItems } = action.payload;

    if (!useNewApi) {
        let requestOptions = {};
        if (parentGuid) {
            requestOptions = {
                UserGuid,
                Model: selectedItems.length === 1 ? selectedItems[0] : selectedItems,
            };
        } else {
            requestOptions = { UserGuid, Model: selectedItems };
        }
        try {
            yield call(Request.delete, urls.DELETE, requestOptions);
            yield put(defaultActions.success());
        } catch (error) {
            yield put(defaultActions.failed());
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
        }
    } else {
        const requestList = selectedItems.map((selectedItem) => {
            return call(Request.delete, `${urls.DELETE}/${selectedItem}`);
        });

        try {
            yield all(requestList);
            yield put(defaultActions.success());
        } catch (error) {
            yield put(defaultActions.failed());
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            } else {
                yield put(notificationActions.apiCallError(error, action));
            }
        }
    }
}
