import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl, aggregateUrlGenerator } from "@ai360/core";

import { PICKLIST_CROP_PURPOSE, getPickListCode } from "~/core/picklist/picklist-names";

import { UNIT_CROP_PRICE, getUnitCode } from "~/core/units/unit-names";

const AGGREGATE_NAME = "nutrientremoval";
const ENDPOINT_NAME = "agbytes/nutrientremoval";
const API_VERSION = "v1.0";

const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const URL = aggregateUrl("list");
export const GETRECORD = aggregateUrl();
export const NEXT_ID = aggregateUrl("nextId");
export const CREATE = aggregateUrl();
export const UPDATE = aggregateUrl();
export const DELETE = aggregateUrl();
export const EXPORT_FILE_NAME = "NutrientRemovalRateExport";
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const SELECT_ALL = aggregateUrl("selectAll");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "nutrientRemovalRateFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "nutrientRemovalRateSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "nutrientRemovalRatePageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

//Dropdowns
export const REQUEST_NUTRIENT = apiUrl("AgBytes/GetNutrientAgBytesList");
export const REQUEST_CROP = apiUrl("AgBytes/GetCropAgBytesList");
export const REQUEST_CROP_CLASS = apiUrl("AgBytes/GetCropClassAgBytesList");

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        nutrientRemovalRateId: "",
        nutrientName: "",
        cropName: "",
        cropClassName: "",
        cropPurpose: "",
        rate: "",
        rateUnit: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "CropName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultNutrientRemovalRate = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_CAN_DELETE]: false,
    [model.PROPS_CROP_CLASS_ID]: null,
    [model.PROPS_CROP_CLASS_NAME]: "",
    [model.PROPS_CROP_CLASS_GUID]: null,
    [model.PROPS_CROP_GUID]: null,
    [model.PROPS_CROP_ID]: 0,
    [model.PROPS_CROP_NAME]: "",
    [model.PROPS_CROP_PURPOSE]: "",
    [model.PROPS_CROP_PURPOSE_GUID]: null,
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_NUTRIENT_GUID]: null,
    [model.PROPS_NUTRIENT_NAME]: "",
    [model.PROPS_NUTRIENT_REMOVAL_RATE_GUID]: null,
    [model.PROPS_NUTRIENT_REMOVAL_RATE_ID]: "",
    [model.PROPS_RATE]: 0,
    [model.PROPS_RATE_UNIT]: "",
    [model.PROPS_RATE_UNIT_GUID]: null,
});

export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
};

export const unitTypes = {
    [UNIT_CROP_PRICE]: getUnitCode(UNIT_CROP_PRICE),
};

export const dropdowns = {
    [model.PROPS_CROP_CLASS_NAME_LIST]: {
        url: REQUEST_CROP_CLASS,
        model: "00000000-0000-0000-0000-000000000000",
    },
    [model.PROPS_NUTRIENT_NAME]: REQUEST_NUTRIENT,
    [model.PROPS_CROP_NAME]: REQUEST_CROP,
};
export const service = createService({
    id: model.PROPS_NUTRIENT_REMOVAL_RATE_ID,
    guid: model.PROPS_NUTRIENT_REMOVAL_RATE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    unitTypes,
    dropdowns,
    useUpdatePut: true,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_NUTRIENT_REMOVAL_RATE_ID]: {
            label: "nutrientRemovalRateId",
            gridCol: 5,
        },
        [model.PROPS_NUTRIENT_NAME]: { label: "cropName", gridCol: 10 },
        [model.PROPS_CROP_NAME]: { label: "cropName", gridCol: 15 },
        [model.PROPS_CROP_CLASS_NAME]: { label: "cropClassName", gridCol: 10 },
        [model.PROPS_CROP_PURPOSE]: { label: "cropPurpose", gridCol: 10 },
        [model.PROPS_RATE]: { label: "rate", gridCol: 5 },
        [model.PROPS_RATE_UNIT]: { label: "rateUnit", gridCol: 5 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
    },
    getDefaultRecord: () => Object.assign({}, defaultNutrientRemovalRate()),
    defaultSort,
    useNewApi: true,
});
