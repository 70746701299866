import * as actions from "./actions";
import * as models from "~/recs-events/events/model";
import * as eventInfoActions from "../../actions";
import * as immutableUtils from "~/utils/immutable";
import { ActionType } from "typesafe-actions";

export const scoutingInitialState = {
    growthStageData: [],
    growthStageObservationData: new Map(),
    observationData: [],
    originalScoutingDetailList: new Map(),
    scoutingTemplate: new models.ScoutingTemplate(),
    scoutingTemplateIsLoading: false,
};

const _formatAgBytesListData = (picklistValues) => {
    return picklistValues && picklistValues.length > 0
        ? picklistValues.map((val) => ({
              label: val.name,
              value: val.guid,
          }))
        : [];
};

export const scoutingReducer = (
    state = scoutingInitialState,
    action: ActionType<typeof actions> | ActionType<typeof eventInfoActions>
): Record<string, unknown> => {
    switch (action.type) {
        case eventInfoActions.CLOSE_EVENT_INFO: {
            return Object.freeze({
                ...state,
                scoutingTemplate: new models.ScoutingTemplate(),
            });
        }
        case actions.FETCH_OBSERVATION_DATA_SUCCEEDED: {
            const { observationData } = action.payload;
            return Object.freeze({ ...state, observationData });
        }
        case actions.FETCH_GROWTH_STAGE_DATA_SUCCEEDED: {
            const { growthStageData } = action.payload;
            return Object.freeze({ ...state, growthStageData });
        }
        case actions.FETCH_GROWTH_STAGE_OBSERVATION_DATA_SUCCEEDED: {
            const { observationGuidList, growthStageObservationSet } = action.payload;
            const newGrowthStageObservationData = state.growthStageObservationData;
            for (const observationGuid of observationGuidList) {
                newGrowthStageObservationData.set(
                    observationGuid,
                    _formatAgBytesListData(growthStageObservationSet[observationGuid])
                );
            }
            return Object.freeze({
                ...state,
                growthStageObservationData: newGrowthStageObservationData,
            });
        }
        case actions.FETCH_SCOUTING_TEMPLATE_SUCCESS: {
            const { scoutingTemplateJsonObj } = action.payload;
            return Object.freeze({
                ...state,
                scoutingTemplate: models.ScoutingTemplate.fromJsonObj(scoutingTemplateJsonObj),
            });
        }
        case actions.RESET_SCOUTING_TEMPLATE: {
            return Object.freeze({
                ...state,
                scoutingTemplate: state.scoutingTemplate.reset(),
            });
        }
        case actions.SET_ORIGINAL_SCOUTING_DETAIL_LIST: {
            const { agEventGuid, scoutingDetailList } = action.payload;
            return Object.freeze({
                ...state,
                originalScoutingDetailList: immutableUtils.noMutateMapSet(
                    state.originalScoutingDetailList,
                    agEventGuid,
                    scoutingDetailList
                ),
            });
        }
        case actions.SET_SCOUTING_DATA_IS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                scoutingDataIsLoading: isLoading,
            });
        }
        case actions.SET_SCOUTING_TEMPLATE_IS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                scoutingTemplateIsLoading: isLoading,
            });
        }
        case actions.UPDATE_SCOUTING_TEMPLATE: {
            const { newProps } = action.payload;
            const scoutingTemplate = state.scoutingTemplate.update(newProps);
            return Object.freeze({ ...state, scoutingTemplate });
        }
        default:
            return state;
    }
};
