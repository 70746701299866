import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl, intlShape } from "react-intl";
import {
    DateInput,
    DialogBox,
    DialogBoxFooterType,
    FileSelector,
    Menu,
    MenuPositions,
    NoLink,
} from "~/core";
import "./import-export-header.css";
import { messages } from "./../i18n-messages";
import moment from "moment";
import { selectors } from "~/hocs/crud";
//testing
const productImportId = "product-import";
const productPackageImportId = "product-package-import";
const productPlantBackImportId = "product-plant-back-import";
const agiisImportId = "agiis-import";
const LTS_FORMAT = "LTS";
const L_FORMAT = "L";
export class ImportExportHeader extends Component {
    static propTypes = {
        actions: PropTypes.object,
        callActionCreator: PropTypes.func,
        className: PropTypes.string,
        exportData: PropTypes.func,
        exportPackageData: PropTypes.func,
        exportPlantBackRestrictionData: PropTypes.func,
        hideActiveRecordLink: PropTypes.bool,
        importData: PropTypes.func,
        importPackageData: PropTypes.func,
        importPlantBackRestrictionData: PropTypes.func,
        importValidData: PropTypes.func,
        importValidPackageData: PropTypes.func,
        importValidPlantBackRestrictionData: PropTypes.func,
        intl: intlShape.isRequired,
        isModalOpen: PropTypes.bool,
        isProduct: PropTypes.bool,
        needs: PropTypes.func,
        onToggleModalClick: PropTypes.func,
        onlyImportExportOptions: PropTypes.bool,
        reloadData: PropTypes.func,
        selectedItems: PropTypes.array,
        service: PropTypes.object,
        setImportCallback: PropTypes.func,
        toggleActiveInactive: PropTypes.func,
        toggleColumnVisibility: PropTypes.func,
        userRole: PropTypes.object.isRequired,
        agrianLastConversionDate: PropTypes.string,
        agrianLastConversionTime: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            showInactive: true,
            showNoSelectedItemsDialog: false,
            showInvalidImportDialog: false,
            showAgrianConversionDialog: false,
            agrianLastConversionDate: null,
            agrianLastConversionTime: null,
        };
        this.onClear = false;
    }
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            agrianLastConversionDate: nextProps.agrianLastConversionDate,
            agrianLastConversionTime: nextProps.agrianLastConversionTime,
        });
    };
    // TODO- Temp function, once all the grids will have the needs prop it can be removed
    withNeeds = (action, payload) => {
        const { needs } = this.props;
        if (needs) {
            needs([action(payload)]);
            return;
        }
        this.props.callActionCreator(action(payload));
    };

    toggleActiveInactive = () => {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            () => {
                this.props.toggleActiveInactive(this.state.showInactive);
            }
        );
    };

    getActiveText = (showInactive) => {
        const { formatMessage } = this.props.intl;
        if (showInactive) {
            return formatMessage(messages.showInactive);
        }
        return formatMessage(messages.hideInactive);
    };

    exportData = (exportDataFunction) => {
        const { selectedItems, exportData } = this.props;
        if (selectedItems && selectedItems.length > 0) {
            typeof exportDataFunction === "function"
                ? exportDataFunction(selectedItems, false)
                : exportData(selectedItems);
        } else {
            this.setState({
                showNoSelectedItemsDialog: true,
            });
        }
    };

    closeExportModal = () => {
        this.setState({
            showNoSelectedItemsDialog: false,
        });
    };

    showAgrianModal = (display) => {
        const { actions } = this.props;
        this.setState(
            {
                showAgrianConversionDialog: display,
            },
            () => {
                if (display) {
                    this.withNeeds(actions.getAgrianConversionData);
                } else {
                    this.setState({
                        agrianLastConversionDate: null,
                        agrianLastConversionTime: null,
                    });
                }
            }
        );
    };

    importValidation = (data) => {
        const { actions } = this.props;
        if (this.props.setImportCallback) {
            this.props.setImportCallback(actions.importData, data);
        }
        this.withNeeds(actions.importValidData, data);
    };

    importPlantBackRestrictionValidation = (data) => {
        const { actions } = this.props;
        if (this.props.setImportCallback) {
            this.props.setImportCallback(actions.importPlantBackRestrictionData, data);
        }
        this.withNeeds(actions.importValidPlantBackRestrictionData, data);
    };

    importPackageValidation = (data) => {
        const { actions } = this.props;
        if (this.props.setImportCallback) {
            this.props.setImportCallback(actions.importPackageData, data);
        }
        this.withNeeds(actions.importValidPackageData, data);
    };

    convertAgrianData = () => {
        const { actions } = this.props;
        this.showAgrianModal(false);
        const data = {
            date: moment().format(L_FORMAT),
            time: moment().format(LTS_FORMAT),
        };
        this.withNeeds(actions.saveAgrianConversionData, data);
        this.withNeeds(actions.convertAgrianData, this.getAgrianConversionDateTime());
        this.setState({
            agrianLastConversionDate: null,
            agrianLastConversionTime: null,
        });
    };

    convertAgiisFile = (data) => {
        const { actions } = this.props;
        this.withNeeds(actions.convertAgiisData, data);
    };

    getAgrianConversionDateTime = () => {
        if (this.onClear) {
            this.onClear = false;
            return null;
        } else {
            const { agrianLastConversionDate, agrianLastConversionTime } = this.props;
            const date =
                this.state.agrianLastConversionDate !== agrianLastConversionDate
                    ? this.state.agrianLastConversionDate
                    : agrianLastConversionDate;
            const time =
                this.state.agrianLastConversionTime !== agrianLastConversionTime
                    ? this.state.agrianLastConversionTime
                    : agrianLastConversionTime;
            const agrianConversionDateTime =
                date && time ? `${moment(date).format("L")} ${time}` : moment();
            this.onClear = false;
            return moment(agrianConversionDateTime);
        }
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { showInactive, showNoSelectedItemsDialog, showAgrianConversionDialog } = this.state;
        const exportLinkClassNames = {
            "user-info-container": true,
            "menu-items-text": true,
            "last-header-link":
                !this.props.userRole.activeInactive &&
                this.props.onlyImportExportOptions &&
                this.props.hideActiveRecordLink,
        };

        const showHideInactiveClassNames = {
            "menu-items-text": true,
            "last-header-link":
                this.props.userRole.activeInactive && !this.props.hideActiveRecordLink,
        };

        const importProductDataClick = (id) => {
            document.getElementById(id).click();
        };

        const exportMenuItems = [
            {
                key: 0,
                label: formatMessage(messages.product),
                action: () => this.exportData(this.props.exportData),
            },
            {
                key: 1,
                label: formatMessage(messages.plantBackRestriction),
                action: () => this.exportData(this.props.exportPlantBackRestrictionData),
            },
            {
                key: 2,
                label: formatMessage(messages.productPackage),
                action: () => this.exportData(this.props.exportPackageData),
            },
        ];
        const importMenuItems = [
            {
                key: 0,
                label: formatMessage(messages.agrianConversion),
                action: () => this.showAgrianModal(true),
            },
            {
                key: 1,
                label: formatMessage(messages.agiisConversion),
                action: () => importProductDataClick(agiisImportId),
            },
            {
                key: 2,
                label: formatMessage(messages.product),
                action: () => importProductDataClick(productImportId),
            },
            {
                key: 3,
                label: formatMessage(messages.plantBackRestriction),
                action: () => importProductDataClick(productPlantBackImportId),
            },
            {
                key: 4,
                label: formatMessage(messages.productPackage),
                action: () => importProductDataClick(productPackageImportId),
            },
        ];
        const renderProductImportControls = () => {
            return (
                <div className="product-import-header">
                    <ul className="hidden">
                        <li>
                            <FileSelector
                                fileType=".csv"
                                label={formatMessage(messages.agiisConversion)}
                                id={agiisImportId}
                                allowMultiple={false}
                                fileCompletedCallback={this.convertAgiisFile}
                                reloadData={this.props.reloadData}
                            />
                        </li>
                        <li>
                            <FileSelector
                                fileType=".csv"
                                label={formatMessage(messages.product)}
                                id={productImportId}
                                allowMultiple={false}
                                fileCompletedCallback={this.importValidation}
                                reloadData={this.props.reloadData}
                            />
                        </li>
                        <li>
                            <FileSelector
                                fileType=".csv"
                                label={formatMessage(messages.plantBackRestriction)}
                                id={productPlantBackImportId}
                                allowMultiple={false}
                                fileCompletedCallback={this.importPlantBackRestrictionValidation}
                                reloadData={this.props.reloadData}
                            />
                        </li>
                        <li>
                            <FileSelector
                                fileType=".csv"
                                label={formatMessage(messages.productPackage)}
                                id={productPackageImportId}
                                allowMultiple={false}
                                fileCompletedCallback={this.importPackageValidation}
                                reloadData={this.props.reloadData}
                            />
                        </li>
                    </ul>
                    <Menu
                        label={formatMessage(messages.import)}
                        menuItems={importMenuItems}
                        position={MenuPositions.BOTTOM_RIGHT}
                    />
                </div>
            );
        };

        const renderAgrianModal = () => {
            return (
                <DialogBox
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    forceOverflow
                    action={formatMessage(messages.startConversion)}
                    isOpen={showAgrianConversionDialog}
                    actionDisabled={false}
                    onAction={() => this.convertAgrianData()}
                    onClose={() => this.showAgrianModal(false)}
                    title={formatMessage(messages.agrianConversion)}
                >
                    <DateInput
                        clearable={false}
                        placeholderText={formatMessage(messages.agrianConversion)}
                        timeFormat="hh:mm:ss"
                        value={this.getAgrianConversionDateTime()}
                        onChange={(val) => {
                            this.onClear = !val;
                            this.setState({
                                agrianLastConversionDate: val ? moment(val).format(L_FORMAT) : null,
                                agrianLastConversionTime: val
                                    ? moment(val).format(LTS_FORMAT)
                                    : null,
                            });
                        }}
                    />
                </DialogBox>
            );
        };
        return (
            <div className={classNames("import-export-header-container", this.props.className)}>
                {this.props.isProduct ? null : (
                    <FileSelector
                        fileType=".csv"
                        allowMultiple={true}
                        fileCompletedCallback={this.importValidation}
                        reloadData={this.props.reloadData}
                    />
                )}
                {!this.props.isProduct ? null : renderProductImportControls()}
                <span className="bar" />
                {this.props.isProduct ? null : (
                    <NoLink
                        className={classNames(exportLinkClassNames)}
                        label={formatMessage(messages.export)}
                        onClick={this.exportData}
                    />
                )}
                {!this.props.isProduct ? null : (
                    <Menu
                        className={classNames(exportLinkClassNames)}
                        label={formatMessage(messages.export)}
                        menuItems={exportMenuItems}
                        position={MenuPositions.BOTTOM_RIGHT}
                    />
                )}
                {this.props.onlyImportExportOptions ? null : (
                    <div>
                        <span className="bar"></span>
                        <NoLink
                            className="menu-items-text"
                            label={formatMessage(messages.viewLog)}
                            onClick={this.props.onToggleModalClick}
                        />
                    </div>
                )}
                {!this.props.userRole.activeInactive || this.props.hideActiveRecordLink ? null : (
                    <div>
                        <span className="bar"></span>
                        <NoLink
                            className={classNames(showHideInactiveClassNames)}
                            label={this.getActiveText(showInactive)}
                            onClick={this.toggleActiveInactive}
                        />
                    </div>
                )}
                <DialogBox
                    isOpen={showNoSelectedItemsDialog}
                    className="confirm-dialog"
                    onClose={() => this.closeExportModal()}
                    title={formatMessage(messages.export)}
                >
                    {formatMessage(messages.exportValidation)}
                </DialogBox>
                {!this.props.isProduct ? null : renderAgrianModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { agrianLastConversionDate, agrianLastConversionTime } =
        selectors.getUserPreference(state);
    return {
        agrianLastConversionDate,
        agrianLastConversionTime,
    };
};

export default injectIntl(connect(mapStateToProps, null)(ImportExportHeader));
