import * as model from "./model";
import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";

import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
import { PICKLIST_VARIETY_ATTRIBUTE, getPickListCode } from "~/core/picklist/picklist-names";

export const MODEL_NAME = "varietyHybridAttribute";
export const URL = apiUrl("AgBytes/GetVarietyHybridAttributeList");
export const GETRECORD = apiUrl("AgBytes/GetVarietyHybridAttribute");
export const NEXT_ID = apiUrl("AgBytes/GetNextVarietyHybridAttributeId");
export const CREATE = apiUrl("AgBytes/AddGridVarietyHybridAttribute");
export const UPDATE = apiUrl("AgBytes/UpdateGridVarietyHybridAttribute");
export const DELETE = apiUrl("AgBytes/DeleteVarietyHybridAttributeList");
export const EXPORT_FILE_NAME = "VarietyHybridAttributeExport";
export const EXPORT_URL = apiUrl("AgBytes/ExportVarietyHybridAttributeList");
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetVarietyHybridAttributeAutoSearchList");
export const IMPORT_URL = apiUrl("AgBytes/VarietyHybridAttributeImport");
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidVarietyHybridAttributeImport");
export const SELECT_ALL = apiUrl("AgBytes/GetVarietyHybridAttributeSelectAllList");
export const MODULE_ID = "models/agBytes/agBytesVarietyHybridAttribute";
export const VARIETY_ATTRIBUTE_URL = apiUrl("Picklist/GetPicklistChildList");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "varietyHybridAttributeFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "varietyHybridAttributeSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "varietyHybridAttributePageOptions";
export const REQUEST_VARIETY_ATTRIBUTE_NAME = apiUrl("Picklist/GetPicklistChildList");

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        varietyHybridId: "",
        varietyHybridName: "",
        attributeCategory: "",
        varietyAttribute: "",
        attributeValue: "",
        agBytesValue: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "VarietyHybridName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

const defaultNutrientRemovalRate = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_ACTIVE_YN_TEXT]: "",
    [model.PROPS_CAN_DELETE]: false,
    [model.PROPS_VARIETY_HYBRID_ATTRIBUTE_GUID]: "",
    [model.PROPS_VARIETY_HYBRID_ID]: "",
    [model.PROPS_VARIETY_HYBRID_NAME]: "",
    [model.PROPS_VARIETY_ATTRIBUTE_CATEGORY]: "",
    [model.PROPS_VARIETY_ATTRIBUTE_NAME]: "",
    [model.PROPS_ATTRIBUTE_VALUE]: "",
    [model.PROPS_AGBYTES_VALUE]: "",
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_ADD_MODE]: false,
    __moduleId__: MODULE_ID,
});

// Remove the dropdown in service and container
export const dropDowns = {
    [model.PROPS_VARIETY_ATTRIBUTE_NAME]: REQUEST_VARIETY_ATTRIBUTE_NAME,
};

export const pickLists = {
    [PICKLIST_VARIETY_ATTRIBUTE]: getPickListCode(PICKLIST_VARIETY_ATTRIBUTE),
};

export const service = createService({
    id: model.PROPS_VARIETY_HYBRID_ATTRIBUTE_GUID,
    guid: model.PROPS_VARIETY_HYBRID_ATTRIBUTE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    useUpdatePut: true,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_VARIETY_HYBRID_ID]: {
            label: "varietyHybridId",
            gridCol: 5,
        },
        [model.PROPS_VARIETY_HYBRID_NAME]: {
            label: "varietyHybridName",
            gridCol: 10,
        },
        [model.PROPS_VARIETY_ATTRIBUTE_CATEGORY]: {
            label: "attributeCategory",
            gridCol: 10,
        },
        [model.PROPS_VARIETY_ATTRIBUTE_NAME]: {
            label: "varietyAttributeName",
            gridCol: 15,
        },
        [model.PROPS_ATTRIBUTE_VALUE]: { label: "attributeValue", gridCol: 10 },
        [model.PROPS_AGBYTES_VALUE]: { label: "agBytesValue", gridCol: 10 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
        [model.PROPS_CAN_DELETE]: {
            label: "canDelete",
            gridCol: 5,
            className: "col-shift-15",
        },
    },
    getDefaultRecord: () => Object.assign({}, defaultNutrientRemovalRate()),
    defaultSort,
});
