import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_GROUP_NAME = "groupName";
export const PROPS_GROWTH_STAGE_GROUP_TYPE = "growthStageGroupType";
export const PROPS_GROWTH_STAGE_GUID = "growthStageGuid";
export const PROPS_GROWTH_STAGE_ID = "growthStageId";
export const PROPS_IS_ACTIVE = adminData.PROPS_IS_ACTIVE;
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_STAGE_ORDER_LIST = "stageOrderList";
