import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tabs, Pane } from "~/core";
import { TemplateManagerGrid } from "./template-manager-grid";
import { constants } from "../../data";
import classnames from "classnames";

export class TemplateTabs extends Component {
    static propTypes = {
        applicationSampleAttributes: PropTypes.array,
        currentTab: PropTypes.number,
        data: PropTypes.array,
        fileMetadata: PropTypes.object,
        formatMessage: PropTypes.func,
        harvestSampleAttributes: PropTypes.array,
        importAttributes: PropTypes.array,
        plantingSampleAttributes: PropTypes.array,
        sampleAttributes: PropTypes.array,
        setCurrentTab: PropTypes.func,
        updateImportAttributes: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            applicationSampleAttributes: [],
            plantingSampleAttributes: [],
            harvestSampleAttributes: [],
            sampleAttributes: [],
            selectedImportAttributes: {
                [constants.TEMPLATE_TYPE_APPLICATION]: [],
                [constants.TEMPLATE_TYPE_HARVEST]: [],
                [constants.TEMPLATE_TYPE_PLANTING]: [],
            },
            selectedTab: 0,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.applicationSampleAttributes !== this.props.applicationSampleAttributes) {
            this.setState({
                applicationSampleAttributes: nextProps.applicationSampleAttributes,
            });
        }
        if (nextProps.plantingSampleAttributes !== this.props.plantingSampleAttributes) {
            this.setState({
                plantingSampleAttributes: nextProps.plantingSampleAttributes,
            });
        }
        if (nextProps.harvestSampleAttributes !== this.props.harvestSampleAttributes) {
            this.setState({
                harvestSampleAttributes: nextProps.harvestSampleAttributes,
            });
        }
        if (nextProps.sampleAttributes !== this.props.sampleAttributes) {
            this.setState({
                sampleAttributes: nextProps.sampleAttributes,
            });
        }
        if (nextProps.fileMetadata.importTemplateList) {
            const { importTemplateList } = nextProps.fileMetadata;
            const selectedImportAttributes = importTemplateList.map((obj) => obj.importAttributes);
            this.setState({
                selectedImportAttributes,
            });
        }
    }

    _isRegularMonitorFile = (data) => {
        const { APPLICATION_TYPE, HARVEST_TYPE, PLANTING_TYPE } = TemplateTabs;
        return (
            (data[APPLICATION_TYPE] && data[HARVEST_TYPE] && data[PLANTING_TYPE]) ||
            (data[APPLICATION_TYPE] && data[HARVEST_TYPE])
        );
    };

    _isSingleTypeMonitorFile = (data) => {
        const { APPLICATION_TYPE, HARVEST_TYPE, PLANTING_TYPE } = TemplateTabs;
        return data[APPLICATION_TYPE] && !data[HARVEST_TYPE] && !data[PLANTING_TYPE];
    };

    getSampleAttributes = (importType) => {
        const {
            applicationSampleAttributes,
            plantingSampleAttributes,
            harvestSampleAttributes,
            sampleAttributes,
        } = this.state;
        let currentSampleAttribute = [];
        switch (importType) {
            case constants.TEMPLATE_TYPE_APPLICATION:
                currentSampleAttribute = applicationSampleAttributes;
                break;
            case constants.TEMPLATE_TYPE_HARVEST:
                currentSampleAttribute = harvestSampleAttributes;
                break;
            case constants.TEMPLATE_TYPE_PLANTING:
                currentSampleAttribute = plantingSampleAttributes;
                break;
            default:
                currentSampleAttribute = sampleAttributes;
                break;
        }
        return currentSampleAttribute;
    };

    _sliceTemplateTypeFromName = (templateName = "") => {
        return templateName ? templateName.substring(templateName.lastIndexOf("_") + 1) : "";
    };

    _getSingleTypeSampleAttributes = ({ importTemplateList }) => {
        if (importTemplateList) {
            const { templateName } = importTemplateList[0];
            if (templateName) {
                const templateType = this._sliceTemplateTypeFromName(templateName);
                return this.getSampleAttributes(templateType);
            }
        }
        return this.getSampleAttributes();
    };

    getPanes = () => {
        const { data, formatMessage, fileMetadata } = this.props;
        const panes = [];
        let tabIndex = 0;
        for (const key of Object.keys(data)) {
            const { label } = data[key];
            panes.push(
                <Pane key={tabIndex} className={classnames("tab-pane")} label={label}>
                    <TemplateManagerGrid
                        formatMessage={formatMessage}
                        fileMetadata={fileMetadata[key]}
                        columns={data[key].standardColumnHeaders}
                        data={data[key].standardGridData}
                        importAttributes={fileMetadata[key].importAttributes || []}
                        sampleAttributes={this.getSampleAttributes(label)}
                        updateImportAttributes={this.props.updateImportAttributes}
                    />
                </Pane>
            );
            tabIndex++;
        }
        return panes;
    };

    renderTabs = () => {
        const panes = this.getPanes();
        return (
            <Tabs
                className="template-manager-tabs"
                selected={this.props.currentTab}
                onTabSelect={(index) => this.props.setCurrentTab(index)}
            >
                {panes}
            </Tabs>
        );
    };

    render() {
        const { data, formatMessage, fileMetadata } = this.props;
        // This renders regular monitor file (Application, Planting and Harvest)
        if (Object.keys(data).length > 1) {
            return this.renderTabs();
        } else if (data[0]) {
            return (
                <TemplateManagerGrid
                    formatMessage={formatMessage}
                    fileMetadata={fileMetadata[0]}
                    columns={data[0].standardColumnHeaders}
                    data={data[0].standardGridData}
                    importAttributes={fileMetadata[0].importAttributes || []}
                    sampleAttributes={this.getSampleAttributes(data[0].label)}
                    onSampleAttributeChange={(value) => this.onSampleAttributeChange(0, value)}
                    updateImportAttributes={this.props.updateImportAttributes}
                />
            );
        }
        return <div className="template-manager-grid form-section" />;
    }
}
