import { call, put, select } from "redux-saga/effects";
import { getRequestOptions, getModelNameToState } from "../utils";
import { defaultActions } from "../actions";
import { Request, APIError } from "@ai360/core";
import { actionsConstants } from "../actions";
// selectors
import { getTheUserGuid } from "~/login";
import { setApiResult } from "~/core/api/actions";
import { actions as notificationActions } from "~/notifications";

export function* fetch(action = {}, service) {
    const { useNewApi } = service;

    const requestOptions = yield getRequestOptions(action.payload, service, useNewApi);

    try {
        const { urls, modelName, getParentGuid } = service;
        let response = null;
        if (!useNewApi) {
            response = yield call(Request.post, urls.URL, requestOptions);
        } else {
            const paramList = [...requestOptions];
            response = yield call(Request.get, urls.URL, paramList);
            response = {
                totalCount: response.metaResponseData.totalRows,
                gridRows: response.data,
            };
        }

        if (response) {
            const model = getModelNameToState(modelName);
            if (action.payload) {
                const parentGuid = getParentGuid(action.payload);
                if (parentGuid) {
                    yield put({
                        type: actionsConstants.nestedGridsuccess,
                        payload: {
                            model,
                            response: {
                                [parentGuid]: response,
                            },
                        },
                    });
                } else {
                    yield put({
                        type: actionsConstants.success,
                        payload: { data: response, model },
                    });
                }
            } else {
                yield put({
                    type: actionsConstants.success,
                    payload: { data: response, model },
                });
            }
        }
    } catch (error) {
        yield put({ type: actionsConstants.failed });
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}

// API do not return certain keys when the request for all records is processed
// this function can be temporary until API Change occur
export function* fetchRecord(action = {}, { urls, modelName, useNewApi, transformGetResponse }) {
    try {
        let response = null;
        const UserGuid = yield select(getTheUserGuid);
        if (!useNewApi) {
            const requestOptions = {
                UserGuid: UserGuid,
                Model: action.payload,
            };
            response = yield call(Request.post, urls.GETRECORD, requestOptions);
        } else {
            response = yield call(Request.get, `${urls.GETRECORD}/${action.payload}`);

            if (transformGetResponse != null) {
                response = yield call(transformGetResponse, response);
            }
        }

        if (response) {
            yield put(
                defaultActions.fetchRecordSuccess({
                    response: {
                        [getModelNameToState(modelName, "EDIT")]: response,
                    },
                    modelName: getModelNameToState(modelName),
                })
            );
        }
    } catch (error) {
        yield put({ type: actionsConstants.failed });
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}
