import { createService } from "~/hocs/crud";
import * as model from "./model";
import { ReportAPI } from "@ai360/core";

const REQUEST_ACTIVE_ONLY = model.PROPS_ACTIVE_ONLY;
const REQUEST_PAYLOAD_FILTER = model.PROPS_REPORT_FILTER;
const REQUEST_PAYLOAD_SORT_LIST = model.PROPS_REPORT_SORT_LIST;
const REQUEST_PAYLOAD_PAGE_OPTIONS = model.PROPS_REPORT_PAGE_OPTIONS;

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        createdDate: "",
        customerName: "",
        farmName: "",
        fieldName: "",
        reportStatus: "",
        reportTypeName: "",
        seasonName: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "DESC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [model.PROPS_FIELD_GUID_LIST]: [],
    [REQUEST_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "createdDate",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

function isFooterButtonDisabled(disableIfNotSelected, selectedItems) {
    if (disableIfNotSelected && selectedItems.length === 0) {
        return true;
    }
    return false;
}

export const service = createService({
    guid: model.PROPS_REPORT_GUID,
    modelName: model.PROPS_REPORT_TYPE_NAME,
    REQUEST_ACTIVE_ONLY,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    defaultRequestFilters,
    isFooterButtonDisabled,
    defaultSort,
    urls: {
        AUTO_SEARCH_URL: ReportAPI.GET_REPORT_AUTO_SEARCH_URL,
        SELECT_ALL_URL: ReportAPI.GET_REPORT_SELECT_ALL_URL,
        URL: ReportAPI.GET_REPORT_LIST_URL,
    },
    _defaultLabels: {
        [model.PROPS_REPORT_TYPE_NAME]: { label: "reportType", gridCol: 15 },
        [model.PROPS_CUSTOMER_NAME]: { label: "customer", gridCol: 15 },
        [model.PROPS_FARM_NAME]: { label: "farm", gridCol: 15 },
        [model.PROPS_FIELD_NAME]: { label: "field", gridCol: 15 },
        [model.PROPS_REPORT_SEASON]: {
            label: "season",
            sortNameOverRide: "seasonName",
            gridCol: 10,
            className: "season-column",
        },
        [model.PROPS_REPORT_DATE]: {
            label: "date",
            sortNameOverRide: "createdDate",
            gridCol: 16,
        },
        [model.PROPS_REPORT_STATUS]: { label: "status", gridCol: 13 },
    },
});
