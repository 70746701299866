import { defineMessages } from "react-intl";

export const StatusCodes = {
    ReadyForImport: 5,
    Complete: 6,
    Imported: 7,
    ProcessingSurfaces: 8,
    CreatingEvent: 9,
    ErrorProcessingSurface: 25,
    ErrorCreatingRec: 31,
    WaitingForControllerProcess: 37,
    Exporting: 34,
    Exported: 35,
    ExportFailed: 36,
    WaitingForPreviousSurfacing: 46,
    WaitingForEquationProcesssing: 47,
    ErrorCreatingEvent: 40,
    EventProcessing: 42,
    Validating: 48,
    ErrorProcessingEvent: 49,
    CalibrationError: 61,
    ErrorAfterOnsiteConversion: 111,
    WaitingToProcess: 371,
};

export const messages = defineMessages({
    importStatus: {
        id: "actionPanel.common.statusCodes.importStatus",
        defaultMessage: `{statusCode, select,
            1 {Uploading {statusPercentComplete, number, percent}}
            2 {Uploaded}
            3 {Archived}
            4 {Processing File}
            5 {Ready}
            6 {Complete}
            7 {Imported}
            8 {Processing Surfaces}
            9 {Creating Event}
            10 {Error In Geoprocessing}
            11 {Error In Conversion}
            111 {Error After Onsite Conversion}
            12 {Unknown File Type}
            14 {Missing Shape File Parts}
            15 {Unknown File Error}
            16 {Invalid Data Types}
            17 {No Data To Surface}
            18 {Invalid Directory Structure}
            19 {Error in Post Processing}
            20 {Conversion Starting}
            21 {Converting {statusPercentComplete, number, percent}}
            22 {Transferring New Files}
            23 {Processing File}
            24 {Post Processing}
            25 {Error Processing Surfaces}
            26 {Error Processing Surfaces Grid}
            27 {Error Surfaces Timeout}
            28 {Waiting to Surface}
            29 {Waiting to Process}
            30 {Processing Rec}
            31 {Rec Failed}
            32 {Partially Complete}
            33 {Waiting to Process}
            34 {Exporting...}
            35 {Exported}
            36 {Export Failed}
            37 {Waiting for Controller Process}
            371 {Waiting to Export}
            38 {Controller File Processing}
            39 {Data Conflict}
            391 {Data Error}
            40 {Error Creating Event}
            41 {Waiting to Process}
            42 {Event Processing}
            43 {No Results}
            44 {Waiting to Upload}
            45 {Error Uploading}
            46 {Waiting For Previous Surfacing}
            47 {Waiting For Equation Processing}
            48 {Validating}
            49 {Error Processing Event}
            50 {No Field Match}
            51 {Multiple Field Match}
            52 {No Equipment Match}
            53 {Multiple Equipment Match}
            54 {No Crop Match}
            55 {No Variety Match}
            56 {No Product Match}
            57 {Would Create Duplicate}
            58 {Multiple Merge Candidates}
            59 {No Season Match}
            60 {Multiple Product Match}
            61 {Calibration Error}
            999 {Cancelling}
        }`,
    },
    eventStatusSampling: {
        id: "actionPanel.common.statusCodes.eventStatusSampling",
        defaultMessage: "{eventId} ({totalPoints, number} Points)",
    },
    eventStatusTotalPoints: {
        id: "actionPanel.common.statusCodes.eventStatusTotalPoints",
        defaultMessage: "({totalPoints, number} Points)",
    },
    eventStatusImportedPoints: {
        id: "actionPanel.common.statusCodes.eventStatusImportedPoints",
        defaultMessage: "{importedPoints, number} Points Imported",
    },
});

const eventStatusUseImportStatusSet = new Set([
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    40,
    41,
    42,
    999,
    8,
    9,
    10,
    StatusCodes.WaitingToProcess,
    StatusCodes.Exported,
    StatusCodes.ExportFailed,
    StatusCodes.Exporting,
    StatusCodes.WaitingForControllerProcess,
    StatusCodes.ErrorProcessingEvent,
    StatusCodes.CalibrationError,
]);

export const getEventStatusMessageId = (
    eventSummary: Record<string, any>,
    eventTypeInfo: Record<string, any>
): string => {
    const statusCode = eventSummary.importedStatus;

    if (eventStatusUseImportStatusSet.has(statusCode)) {
        return messages.importStatus;
    }

    if (
        (eventSummary.importedStatus === StatusCodes.Complete ||
            eventSummary.importedStatus === 0) &&
        eventSummary.eventId &&
        eventTypeInfo.sampleTypeGuid &&
        eventSummary.importedPoints === 0
    ) {
        return messages.eventStatusSampling;
    }

    if (eventSummary.importedStatus === StatusCodes.Complete) {
        return messages.importStatus;
    }

    if (eventSummary.importedPoints > 0) {
        return messages.eventStatusImportedPoints;
    }

    if (eventSummary.agEventTypeName && eventSummary.agEventTypeName.toLowerCase() === "scouting") {
        return undefined;
    }

    return messages.eventStatusTotalPoints;
};
