import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const LockIcon = withSvgIcon(
    <g>
        <path d="M959.421 1133.023c-91.448 0-165.82 74.372-165.82 165.82 0 91.447 74.372 165.82 165.82 165.82s165.82-74.373 165.82-165.82c0-91.448-74.372-165.82-165.82-165.82zm0 436.845c-149.473 0-271.026-121.553-271.026-271.025 0-149.392 121.553-271.026 271.026-271.026s271.025 121.634 271.025 271.026c0 149.472-121.552 271.025-271.025 271.025z" />
        <path d="M444.205 1814.09h1030.529V783.562H444.205V1814.09zm219.96-1413.637c0-162.826 132.479-295.304 295.224-295.304 162.906 0 295.303 132.478 295.303 295.304v277.985H664.166V400.453zm822.627 277.985h-126.894V400.453c0-220.851-179.659-400.429-400.51-400.429C738.62.024 558.96 179.602 558.96 400.453v277.985H432.066c-51.388 0-93.066 41.677-93.066 92.985V1826.23c0 51.307 41.678 93.066 93.066 93.066h1054.726c51.389 0 93.066-41.759 93.066-93.066V771.423c0-51.308-41.677-92.985-93.066-92.985z" />
    </g>,
    "-80 0 1760 1920"
);
