import { createReducer } from "redux-act";
import { requestStateSuccess, requestStateFailed } from "./actions";

export const LICENSE_STATE_DATA_KEY = "LICENSE_STATE_DATA";

export const licenseReducer = createReducer(
    {
        [requestStateSuccess]: (state, payload) => ({
            ...state,
            countryStateList: {
                ...state.countryStateList,
                [payload.guid]: payload.response,
            },
        }),
        [requestStateFailed]: (state) => ({
            ...state,
            countryStateList: {},
        }),
    },
    {
        countryStateList: {},
    }
);
