import PropTypes from "prop-types";
import * as model from "~/admin/agBytes/product-package-list/data/model";

export const shape = {
    [model.PROPS_PRODUCT_PACKAGE_GUID]: PropTypes.string,
    [model.PROPS_PRODUCT_PACKAGE_ID]: PropTypes.number,
    [model.PROPS_LONG_DESCRIPTION]: PropTypes.string,
    [model.PROPS_PACKAGE_SIZE]: PropTypes.string,
    [model.PROPS_PACKAGE_UNIT_NAME]: PropTypes.string,
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
};

export default PropTypes.shape(shape);
