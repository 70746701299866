import { createAction } from "redux-act";
import { AgBytesItem } from "~/action-panel/components/event-module/components/event-info/components/event-scouting/models";

const FETCH_VARIETY_HYBRID_LIST = "action-panel/FETCH_VARIETY_HYBRID_LIST_DATA";
const FETCH_VARIETY_HYBRID_LIST_FAILED = "action-panel/FETCH_VARIETY_HYBRID_LIST_FAILED";
const FETCH_VARIETY_HYBRID_LIST_SUCCESS = "action-panel/FETCH_VARIETY_HYBRID_LIST_SUCCESS";

export const fetchVarietyHybridList = createAction<string>(FETCH_VARIETY_HYBRID_LIST);
export const fetchVarietyHybridListFailed = createAction<string | null>(
    FETCH_VARIETY_HYBRID_LIST_FAILED
);
export const fetchVarietyHybridListSuccess = createAction<{
    guid: string;
    response: AgBytesItem[];
}>(FETCH_VARIETY_HYBRID_LIST_SUCCESS);
