export const CUSTOM_BATCH = "file-import/CUSTOM_BATCH";
export const DELETE_IMPORT_FILE_INFO_LIST = "file-import/DELETE_IMPORT_FILE_INFO_LIST";
export const FETCH_IMPORT_FILE_TYPES = "file-import/FETCH_IMPORT_FILE_TYPES";
export const FETCH_IMPORT_FILE_INFO = "file-import/FETCH_IMPORT_FILE_INFO";
export const FETCH_IS_TELEMATICS_USER = "file-import/FETCH_IS_TELEMATICS_USER";
export const FETCH_TELEMATICS_COUNTS = "file-import/FETCH_TELEMATICS_COUNTS";
export const IMPORT_FILE_INFO_FETCH_SUCCEEDED = "file-import/IMPORT_FILE_INFO_FETCH_SUCCEEDED";
export const IMPORT_TYPES_TMPLTS_FETCH_SUCCEEDED =
    "file-import/IMPORT_TYPES_TMPLTS_FETCH_SUCCEEDED";
export const IMPORT_TYPES_TMPLTS_FILTER_COMPLETE =
    "file-import/IMPORT_TYPES_TMPLTS_FILTER_COMPLETE";
export const REFRESH_IMPORT_DATA = "file-import/REFRESH_IMPORT_DATA";
export const REFRESH_IMPORT_DATA_COMPLETE = "file-import/REFRESH_IMPORT_DATA_COMPLETE";
export const REFRESH_IMPORT_TYPE_TEMPLATE_INFO_COMPLETE =
    "file-import/REFRESH_IMPORT_TYPE_TEMPLATE_INFO_COMPLETE";
export const REMOVE_ALL_DATA = "file-import/REMOVE_ALL_DATA";
export const SET_FETCHING_TYPES_STATUS = "file-import/SET_FETCHING_TYPES_STATUS";
export const SET_FETCH_ALL_USERS_FILTER = "file-import/SET_FETCH_ALL_USERS_FILTER";
export const SET_FILTER_INCLUDE_OTHER_USERS = "file-import/SET_FILTER_INCLUDE_OTHER_USERS";
export const SET_INFO_FETCHING_STATUS = "file-import/SET_INFO_FETCHING_STATUS";
export const SET_IS_TELEMATICS_PROCESSING = "file-import/SET_IS_TELEMATICS_PROCESSING";
export const SET_IS_TELEMATICS_USER = "file-import/SET_IS_TELEMATICS_USER";
export const SET_TEMPLATE_EXPANDED = "file-import/SET_TEMPLATE_EXPANDED";
export const SET_TYPE_EXPANDED = "file-import/SET_TYPE_EXPANDED";
export const UPDATE_IFO_STATUS_CODE = "file-import/UPDATE_IFO_STATUS_CODE";
export const UPDATE_IFO_STATUS_PERCENT_COMPLETE = "file-import/UPDATE_IFO_STATUS_PERCENT_COMPLETE";
export const UPDATE_IMPORT_FILE_INFOS = "file-import/UPDATE_IMPORT_FILE_INFOS";
export const UPDATE_TELEMATICS_COUNTS = "file-import/UPDATE_TELEMATICS_COUNTS";
export const UPLOAD_COMPLETED = "file-import/UPLOAD_COMPLETED";
export const UPLOADS_PROCESSED = "file-import/UPLOADS_PROCESSED";
export const UPDATE_SEARCH = "file-import/UPDATE_SEARCH";

export const batchActions = (actionList) => ({
    type: CUSTOM_BATCH,
    payload: { actionList },
});

export const deleteImportFileInfoList = (importFileInfoGuidList, clientStateOnly = false) => ({
    type: DELETE_IMPORT_FILE_INFO_LIST,
    payload: { importFileInfoGuidList, clientStateOnly },
});

export const fetchImportFileTypes = (search) => ({
    type: FETCH_IMPORT_FILE_TYPES,
    payload: { search },
});

export const fetchImportFileInfo = (templateGuid) => ({
    type: FETCH_IMPORT_FILE_INFO,
    payload: { templateGuid },
});

export const fetchIsTelematicsUser = () => ({
    type: FETCH_IS_TELEMATICS_USER,
});

export const fetchTelematicsCounts = () => ({
    type: FETCH_TELEMATICS_COUNTS,
});

export const importFileInfoFetchSucceeded = (importFileInfoList, templateGuid) => ({
    type: IMPORT_FILE_INFO_FETCH_SUCCEEDED,
    payload: { importFileInfoList, templateGuid },
});

export const importTypesTmpltsFetchSucceeded = (fileImportTypes) => ({
    type: IMPORT_TYPES_TMPLTS_FETCH_SUCCEEDED,
    payload: { fileImportTypes },
});

export const importTypesTmpltsFilterComplete = (fileImportTypes) => ({
    type: IMPORT_TYPES_TMPLTS_FILTER_COMPLETE,
    payload: { fileImportTypes },
});

export const refreshImportData = (forceRefreshTemplateGuidSet) => ({
    type: REFRESH_IMPORT_DATA,
    payload: { forceRefreshTemplateGuidSet },
});

export const refreshImportTypeTemplateInfoComplete = (fileImportTypes) => ({
    type: REFRESH_IMPORT_TYPE_TEMPLATE_INFO_COMPLETE,
    payload: { fileImportTypes },
});

export const refreshImportDataComplete = (forceRefreshTemplateGuidSet, newTemplateGuidSet) => ({
    type: REFRESH_IMPORT_DATA_COMPLETE,
    payload: { forceRefreshTemplateGuidSet, newTemplateGuidSet },
});

export const removeAllData = () => ({
    type: REMOVE_ALL_DATA,
});

export const setFetchingTypesStatus = (fetchingTypes) => ({
    type: SET_FETCHING_TYPES_STATUS,
    payload: { fetchingTypes },
});

export const setFetchAllUsersFilter = (userFilterStr, userFilterObj) => ({
    type: SET_FETCH_ALL_USERS_FILTER,
    payload: { userFilterStr, userFilterObj },
});

export const setFilterIncludeOtherUsers = (includeOtherUsers) => ({
    type: SET_FILTER_INCLUDE_OTHER_USERS,
    payload: { includeOtherUsers },
});

export const setInfoFetchingStatus = (templateGuids, isFetching) => ({
    type: SET_INFO_FETCHING_STATUS,
    payload: { templateGuids, isFetching },
});

export const setIsTelematicsProcessing = (isTelematicsProcessing) => ({
    type: SET_IS_TELEMATICS_PROCESSING,
    payload: { isTelematicsProcessing },
});

export const setIsTelematicsUser = (isTelematicsUser) => ({
    type: SET_IS_TELEMATICS_USER,
    payload: { isTelematicsUser },
});

export const setTemplateExpanded = (templateGuid, expanded) => ({
    type: SET_TEMPLATE_EXPANDED,
    payload: { templateGuid, expanded },
});

export const setTypeExpanded = (typeGuid, expanded) => ({
    type: SET_TYPE_EXPANDED,
    payload: { typeGuid, expanded },
});

export const updateIfoStatusCode = (ifoGuid, statusCode) => ({
    type: UPDATE_IFO_STATUS_CODE,
    payload: { ifoGuid, statusCode },
});

export const updateIfoStatusPercentComplete = (ifoGuid, statusPercentComplete) => ({
    type: UPDATE_IFO_STATUS_PERCENT_COMPLETE,
    payload: { ifoGuid, statusPercentComplete },
});

export const updateImportFileInfos = (importFileInfos) => ({
    type: UPDATE_IMPORT_FILE_INFOS,
    payload: { importFileInfos },
});

export const updateSearchValue = (searchValue) => ({
    type: UPDATE_SEARCH,
    payload: { searchValue },
});

export const updateTelematicsCounts = (telematicsCounts) => ({
    type: UPDATE_TELEMATICS_COUNTS,
    payload: { telematicsCounts },
});

export const uploadCompleted = (uploadedFileGuid) => ({
    type: UPLOAD_COMPLETED,
    payload: uploadedFileGuid,
});

export const uploadsProcessed = () => ({
    type: UPLOADS_PROCESSED,
});
