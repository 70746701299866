import { action } from "typesafe-actions";

import Immutable from "immutable";

import { model as accModels } from "~/accordion";
import { IFieldFilterSelections, IFieldFilterOptions } from "./models";

export const FETCH_FILTERS = "field-list/FETCH_FILTERS";
export const FETCH_FILTERS_SUCCESS = "field-list/FETCH_FILTERS_SUCCESS";
export const COLLAPSE_ALL_CUSTOMER_ITEMS = "field-list/COLLAPSE_ALL_CUSTOMER_ITEMS";
export const COLLAPSE_CUSTOMER_ITEM = "field-list/COLLAPSE_CUSTOMER_ITEM";
export const EXPAND_ALL_CUSTOMER_ITEMS = "field-list/EXPAND_ALL_CUSTOMER_ITEMS";
export const EXPAND_CUSTOMER_ITEM = "field-list/EXPAND_CUSTOMER_ITEM";
export const FETCH_BATCH_OPTIONS = "field-list/FETCH_BATCH_OPTIONS";
export const SAVE_BATCH_DETAILS = "fields-list/SAVE_BATCH_DETAILS";
export const SET_MANUAL_EXPANDED_CUSTOMERS = "field-list/SET_MANUAL_EXPANDED_CUSTOMERS";
export const SET_LAST_CLICKED_DIM_IDX = "field-list/SET_LAST_CLICKED_DIM_IDX";
export const SET_SCROLL_TOP = "field-list/SET_SCROLL_TOP";
export const SET_SCROLL_TO_FLAT_IDX = "field-list/SET_SCROLL_TO_FLAT_IDX";
export const SET_SHOW_BATCH_EDIT = "field-list/SET_SHOW_BATCH_EDIT";
export const SET_SHOW_FILTERS = "field-list/SET_SHOW_FILTERS";
export const SET_FILTER_SELECTIONS = "field-list/SET_FILTER_SELECTIONS";
export const SET_SALESPERSON_GUIDS = "field-list/SET_SALESPERSON_GUIDS";
export const UPDATE_BATCH_FIELD_DETAILS = "field-list/UPDATE_BATCH_FIELD_DETAILS";
export const UPDATE_SEARCH = "field-list/UPDATE_SEARCH";

export const fetchBatchOptions = () => action(FETCH_BATCH_OPTIONS, {});

export const fetchFilters = (locationOrgLevelGuid: string | null) =>
    action(FETCH_FILTERS, { locationOrgLevelGuid });

export const fetchFiltersSuccess = (options: IFieldFilterOptions) =>
    action(FETCH_FILTERS_SUCCESS, { options });

export const collapseAllCustomerItems = () => action(COLLAPSE_ALL_CUSTOMER_ITEMS);

export const collapseCustomerItem = (customerGuid: string) =>
    action(COLLAPSE_CUSTOMER_ITEM, { customerGuid });

export const expandAllCustomerItems = () => action(EXPAND_ALL_CUSTOMER_ITEMS);

export const expandCustomerItem = (customerGuid: string) =>
    action(EXPAND_CUSTOMER_ITEM, { customerGuid });

export const saveBatchDetails = () => action(SAVE_BATCH_DETAILS, {});

export const setManualExpandedCustomers = (customerGuids: Immutable.Set<string> | string[]) =>
    action(SET_MANUAL_EXPANDED_CUSTOMERS, { customerGuids });

export const setFilterSelections = (filterSelections: IFieldFilterSelections) =>
    action(SET_FILTER_SELECTIONS, { filterSelections });

export const setLastClickedDimIdx = (activeTab: string, lastClickedDimIdx: accModels.MultiDimIdx) =>
    action(SET_LAST_CLICKED_DIM_IDX, { activeTab, lastClickedDimIdx });

export const setScrollTop = (activeTab: string, initScrollTop: number) =>
    action(SET_SCROLL_TOP, { activeTab, initScrollTop });

export const setScrollToFlatIdx = (activeTab: string, scrollToFlatIdx: accModels.FlatIdx) =>
    action(SET_SCROLL_TO_FLAT_IDX, { activeTab, scrollToFlatIdx });

export const setShowBatchEdit = (showBatchEdit: boolean) =>
    action(SET_SHOW_BATCH_EDIT, { showBatchEdit });

export const setShowFilters = (showFilters: boolean) => action(SET_SHOW_FILTERS, { showFilters });

export const updateBatchFieldDetails = (newProps: Record<string, any>) =>
    action(UPDATE_BATCH_FIELD_DETAILS, { newProps });

export const updateSearchValue = (searchValue: string) => action(UPDATE_SEARCH, { searchValue });
