import { connect } from "react-redux";
import IrrigationView from "./irrigation";
import { getUnit, getPicklist } from "~/admin/selectors";
import * as unitService from "~/core/units/unit-names";
import * as picklistService from "~/core/picklist/picklist-names";

const mapStateToProps = (state) => ({
    [unitService.UNIT_LENGTH]: getUnit(unitService.getUnitCode(unitService.UNIT_LENGTH), state),
    [unitService.UNIT_FLOW_RATE]: getUnit(
        unitService.getUnitCode(unitService.UNIT_FLOW_RATE),
        state
    ),
    [picklistService.PICKLIST_WELL_TYPE]: getPicklist(
        picklistService.getPickListCode(picklistService.PICKLIST_WELL_TYPE),
        state
    ),
});

export const Irrigation = connect(mapStateToProps, null)(IrrigationView);
