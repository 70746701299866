import { createAction } from "redux-act";

const BASE_STR = "LOCATION_MIGRATION";

// Attributes
export const FETCH_AGVANCE_DATABASE_ID = `${BASE_STR}_FETCH_AGVANCE_DATABASE_ID_DATA`;
export const FETCH_AGVANCE_DATABASE_ID_SUCCESS = `${BASE_STR}_FETCH_AGVANCE_DATABASE_ID_SUCCESS`;
export const FETCH_AGVANCE_DATABASE_ID_FAILED = `${BASE_STR}_FETCH_AGVANCE_DATABASE_ID_FAILED`;

export const POST_VALIDATION = `${BASE_STR}_POST_VALIDATION_DATA`;
export const POST_VALIDATION_SUCCESS = `${BASE_STR}_POST_VALIDATION_SUCCESS`;
export const POST_VALIDATION_FAILED = `${BASE_STR}_POST_VALIDATION_FAILED`;

export const POST_MIGRATION = `${BASE_STR}_POST_MIGRATION_DATA`;
export const POST_MIGRATION_SUCCESS = `${BASE_STR}_POST_MIGRATION_SUCCESS`;
export const POST_MIGRATION_FAILED = `${BASE_STR}_POST_MIGRATION_FAILED`;

export const GET_LOCATIONS = `${BASE_STR}_GET_LOCATIONS_DATA`;
export const GET_LOCATIONS_SUCCESS = `${BASE_STR}_GET_LOCATIONS_SUCCESS`;
export const GET_LOCATIONS_FAILED = `${BASE_STR}_GET_LOCATIONS_FAILED`;

export const POST_INVALID_LOCATION_CHECK = `${BASE_STR}_POST_INVALID_LOCATION_CHECK_DATA`;
export const POST_INVALID_LOCATION_CHECK_SUCCESS = `${BASE_STR}_POST_INVALID_LOCATION_CHECK_SUCCESS`;
export const POST_INVALID_LOCATION_CHECK_FAILED = `${BASE_STR}_POST_INVALID_LOCATION_CHECK_FAILED`;

export const SET_AGVANCE_DATABASE_ID = `${BASE_STR}_SET_AGVANCE_DATABASE_ID`;

export const fetchAgvanceDatabaseId = createAction(FETCH_AGVANCE_DATABASE_ID);
export const fetchAgvanceDatabaseIdSuccess = createAction(FETCH_AGVANCE_DATABASE_ID_SUCCESS);
export const fetchAgvanceDatabaseIdFailed = createAction(FETCH_AGVANCE_DATABASE_ID_FAILED);

export const postValidation = createAction(POST_VALIDATION);
export const postValidationSuccess = createAction(POST_VALIDATION_SUCCESS);
export const postValidationFailed = createAction(POST_VALIDATION_FAILED);

export const postMigration = createAction(POST_MIGRATION);
export const postMigrationSuccess = createAction(POST_MIGRATION_SUCCESS);
export const postMigrationFailed = createAction(POST_MIGRATION_FAILED);

export const getLocations = createAction(GET_LOCATIONS);
export const getLocationsSuccess = createAction(GET_LOCATIONS_SUCCESS);
export const getLocationsFailed = createAction(GET_LOCATIONS_FAILED);

export const postInvalidLocationCheck = createAction(POST_INVALID_LOCATION_CHECK);
export const postInvalidLocationCheckSuccess = createAction(POST_INVALID_LOCATION_CHECK_SUCCESS);
export const postInvalidLocationCheckFailed = createAction(POST_INVALID_LOCATION_CHECK_FAILED);

export const setAgvanceDatabaseId = createAction(SET_AGVANCE_DATABASE_ID);
