import { createAction } from "redux-act";

export const REMOVE_VENDOR_RECORD = "REMOVE_VENDOR_RECORD";

const CREATE_EQUIPMENT_DATA = "CREATE_EQUIPMENT_DATA";
const CREATE_EQUIPMENT_SUCCESS = "CREATE_EQUIPMENT_SUCCESS";
const CREATE_EQUIPMENT_FAILED = "CREATE_EQUIPMENT_FAILED";

export const FETCH_VENDOR_EQUIPMENT_LIST = "FETCH_VENDOR_EQUIPMENT_LIST_DATA";
export const FETCH_VENDOR_EQUIPMENT_LIST_SUCCESS = "FETCH_VENDOR_EQUIPMENT_LIST_SUCCESS";
export const FETCH_VENDOR_EQUIPMENT_LIST_FAILED = "FETCH_VENDOR_EQUIPMENT_LIST_FAILED";

export const GET_TEST_PACKAGE_LIST_DATA = "GET_TEST_PACKAGE_LIST_DATA";
export const GET_TEST_PACKAGE_LIST_SUCCESS = "GET_TEST_PACKAGE_LIST_SUCCESS";
export const GET_TEST_PACKAGE_LIST_FAILED = "GET_TEST_PACKAGE_LIST_FAILED";

export const GET_TESTING_LAB_VENDOR_LIST_DATA = "GET_TESTING_LAB_VENDOR_LIST_DATA";
export const GET_TESTING_LAB_VENDOR_LIST_SUCCESS = "GET_TESTING_LAB_VENDOR_LIST_SUCCESS";
export const GET_TESTING_LAB_VENDOR_LIST_FAILED = "GET_TESTING_LAB_VENDOR_LIST_FAILED";

export const removeVendorRecord = createAction(REMOVE_VENDOR_RECORD);

export const fetchVendorEquipmentList = createAction(FETCH_VENDOR_EQUIPMENT_LIST);
export const fetchVendorEquipmentListSuccess = createAction(FETCH_VENDOR_EQUIPMENT_LIST_SUCCESS);
export const fetchVendorEquipmentListFailed = createAction(FETCH_VENDOR_EQUIPMENT_LIST_FAILED);

export const createEquipment = createAction(CREATE_EQUIPMENT_DATA);
export const createEquipmentSuccess = createAction(CREATE_EQUIPMENT_SUCCESS);
export const createEquipmentFailed = createAction(CREATE_EQUIPMENT_FAILED);

export const getTestPackageListData = createAction(GET_TEST_PACKAGE_LIST_DATA);
export const getTestPackageListSuccess = createAction(GET_TEST_PACKAGE_LIST_SUCCESS);
export const getTestPackageListFailed = createAction(GET_TEST_PACKAGE_LIST_FAILED);

export const getTestingLabVendorListData = createAction(GET_TESTING_LAB_VENDOR_LIST_DATA);
export const getTestingLabVendorListSuccess = createAction(GET_TESTING_LAB_VENDOR_LIST_SUCCESS);
export const getTestingLabVendorListFailed = createAction(GET_TESTING_LAB_VENDOR_LIST_FAILED);
