import React from "react";
import PropTypes from "prop-types";
import { Section, SelectInput, SubSection } from "~/core";
import { mapToPicklistValue } from "~/admin/utils";
import { adminData } from "~/admin/data";
import { messages } from "~/admin/agBytes/i18n-messages";
import { model } from "./../data";
import * as picklistService from "~/core/picklist/picklist-names";

const {
    PICKLIST_APPLICATION_TIMING,
    PICKLIST_APPLICATION_METHOD,
    PICKLIST_SOIL_TEXTURE,
    PICKLIST_TILLAGE_METHOD,
    PICKLIST_INCORPORATION_DEPTH,
    PICKLIST_BUILD_FACTOR,
    PICKLIST_TARGET_PH,
    PICKLIST_REC_OPTION,
} = picklistService;

const EquationFilters = ({ dropdowns, formatMessage, onSelectionChange, records }) => {
    return (
        <div className="section-container">
            <Section headerText={formatMessage(messages.equationFilters)}>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[model.PROPS_PREVIOUS_CROP]}
                        placeholderText={formatMessage(messages.previousCrop)}
                        value={mapToPicklistValue({
                            options: dropdowns[model.PROPS_PREVIOUS_CROP],
                            selectedGuid: records[model.PROPS_PREVIOUS_CROP_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: model.PROPS_PREVIOUS_CROP,
                                value,
                                type: model.PROPS_PREVIOUS_CROP,
                                guid: model.PROPS_PREVIOUS_CROP_GUID,
                            });
                        }}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[model.PROPS_NEXT_CROP]}
                        placeholderText={formatMessage(messages.nextCrop)}
                        value={mapToPicklistValue({
                            options: dropdowns[model.PROPS_NEXT_CROP],
                            selectedGuid: records[model.PROPS_NEXT_CROP_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: model.PROPS_NEXT_CROP,
                                value,
                                type: model.PROPS_NEXT_CROP,
                                guid: model.PROPS_NEXT_CROP_GUID,
                            });
                        }}
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_APPLICATION_TIMING]}
                        placeholderText={formatMessage(messages.applicationTiming)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_APPLICATION_TIMING],
                            selectedGuid: records[model.PROPS_APPLICATION_TIMING_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_APPLICATION_TIMING,
                                value,
                                type: model.PROPS_APPLICATION_TIMING,
                                guid: model.PROPS_APPLICATION_TIMING_GUID,
                            });
                        }}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_APPLICATION_METHOD]}
                        placeholderText={formatMessage(messages.applicationMethod)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_APPLICATION_METHOD],
                            selectedGuid: records[model.PROPS_APPLICATION_METHOD_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_APPLICATION_METHOD,
                                value,
                                type: model.PROPS_APPLICATION_METHOD,
                                guid: model.PROPS_APPLICATION_METHOD_GUID,
                            });
                        }}
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_SOIL_TEXTURE]}
                        placeholderText={formatMessage(messages.soilTexture)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_SOIL_TEXTURE],
                            selectedGuid: records[model.PROPS_SOIL_TEXTURE_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_SOIL_TEXTURE,
                                value,
                                type: model.PROPS_SOIL_TEXTURE,
                                guid: model.PROPS_SOIL_TEXTURE_GUID,
                            });
                        }}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_TILLAGE_METHOD]}
                        placeholderText={formatMessage(messages.tillageMethod)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_TILLAGE_METHOD],
                            selectedGuid: records[model.PROPS_TILLAGE_METHOD_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_TILLAGE_METHOD,
                                value,
                                type: model.PROPS_TILLAGE_METHOD,
                                guid: model.PROPS_TILLAGE_METHOD_GUID,
                            });
                        }}
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_INCORPORATION_DEPTH]}
                        placeholderText={formatMessage(messages.incorporationDepth)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_INCORPORATION_DEPTH],
                            selectedGuid: records[model.PROPS_INCORPORATION_DEPTH_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_INCORPORATION_DEPTH,
                                value,
                                type: model.PROPS_INCORPORATION_DEPTH,
                                guid: model.PROPS_INCORPORATION_DEPTH_GUID,
                            });
                        }}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_BUILD_FACTOR]}
                        placeholderText={formatMessage(messages.buildFactor)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_BUILD_FACTOR],
                            selectedGuid: records[model.PROPS_BUILD_FACTOR_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_BUILD_FACTOR,
                                value,
                                type: model.PROPS_BUILD_FACTOR,
                                guid: model.PROPS_BUILD_FACTOR_GUID,
                            });
                        }}
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_TARGET_PH]}
                        placeholderText={formatMessage(messages.targetPh)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_TARGET_PH],
                            selectedGuid: records[model.PROPS_TARGET_PH_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_TARGET_PH,
                                value,
                                type: model.PROPS_TARGET_PH,
                                guid: model.PROPS_TARGET_PH_GUID,
                            });
                        }}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={dropdowns[PICKLIST_REC_OPTION]}
                        placeholderText={formatMessage(messages.recOptions)}
                        value={mapToPicklistValue({
                            options: dropdowns[PICKLIST_REC_OPTION],
                            selectedGuid: records[model.PROPS_REC_OPTION_GUID],
                        })}
                        onChange={(value) => {
                            onSelectionChange({
                                stateName: PICKLIST_REC_OPTION,
                                value,
                                type: model.PROPS_REC_OPTION,
                                guid: model.PROPS_REC_OPTION_GUID,
                            });
                        }}
                    />
                </SubSection>
            </Section>
        </div>
    );
};

EquationFilters.propTypes = {
    dropdowns: PropTypes.array.isRequired,
    formatMessage: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    records: PropTypes.object.isRequired,
};

export default EquationFilters;
