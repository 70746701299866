import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { DialogBox, DialogBoxFooterType } from "~/core";
import { withUser } from "~/hocs";
import { mapToolsActions, mapToolsSelectors } from "~/map";
import { getSystemAreaLimit } from "~/login";
import { GeometryUtils, ToolConfig } from "@ai360/core";

import { getFieldInfoModel, getAgvanceFieldList } from "../../../selectors";
import { fetchFieldInformation, updateFieldInfo, saveFieldInformation } from "../../../actions";
import { FieldInformationTitleBar } from "../components/field-information-title-bar/field-information-title-bar";
import { FieldInformationMenu } from "../components/field-information-menu/field-information-menu";
import FieldInformationDetail from "../components/field-information-detail/field-information-detail";
import { FieldInformationButtonBar } from "../components/field-information-button-bar/field-information-button-bar";

import "./field-information.css";
import { messages } from "./i18n-messages";
import { setFieldsToInvalidate } from "~/map/components/map-control/actions";
import { FieldResurfacePrompt } from "../../../../common";

import { v4 as uuid } from "uuid";

class FieldInformation extends React.Component {
    static propTypes = {
        activeCustomer: PropTypes.object,
        activeField: PropTypes.object,
        agvanceFieldList: PropTypes.array,
        editFieldGuid: PropTypes.string,
        fetchFieldInformation: PropTypes.func,
        fieldEditGeometries: PropTypes.array,
        fieldEditGeometriesChanged: PropTypes.bool,
        intl: intlShape.isRequired,
        invalidateFieldGuids: PropTypes.func.isRequired,
        onCloseFieldInformation: PropTypes.func.isRequired,
        onSetToolsetDisabled: PropTypes.func.isRequired,
        maximumArea: PropTypes.number,
        saveFieldInformation: PropTypes.func.isRequired,
        updateFieldModel: PropTypes.func,
        userInfo: PropTypes.object,
    };
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            isConfirmationModalOpen: false,
            isInvalidGeometries: false,
            isMaxAreaModalOpen: false,
            yesAction: null,
            showResurfacePrompt: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.fetchFieldInformation(this.props.editFieldGuid);
    }

    _closeConfirmDialog() {
        this.setState({
            isConfirmationModalOpen: false,
            yesAction: null,
        });
    }

    _closeInvalidGeometries() {
        this.setState({
            isInvalidGeometries: false,
        });
    }

    _closeMaxAreaDialog() {
        this.setState({
            isMaxAreaModalOpen: false,
        });
    }

    _confirmYes() {
        if (this.state.yesAction) {
            this.state.yesAction();
        }
        this._closeConfirmDialog();
    }

    _getCustomerName = (customer) => {
        return customer ? customer.name : "";
    };
    _getFieldName = (field) => {
        return field ? field.fieldName : "";
    };
    _getFarmName = (field) => {
        return field ? field.farmName : "";
    };

    _saveFieldInformation(fieldToSave) {
        this.props.onSetToolsetDisabled(true);
        this.props.saveFieldInformation(fieldToSave, this.props.onCloseFieldInformation);
        if (this.props.invalidateFieldGuids) {
            this.props.invalidateFieldGuids(fieldToSave.fieldGuid);
        }
    }

    _showConfirmDialog(yesAction) {
        this.setState({
            isConfirmationModalOpen: true,
            yesAction,
        });
    }

    _showInvalidGeometriesDialog() {
        this.setState({
            isInvalidGeometries: true,
        });
    }

    _showMaxAreaDialog() {
        this.setState({
            isMaxAreaModalOpen: true,
        });
    }

    _showResurfacePrompt(fieldToSave) {
        this.setState({ showResurfacePrompt: true, fieldToSave });
    }
    _onResurfacePromptClosed = (resurfaceEventResult) => {
        const fieldToSave = this.state.fieldToSave;
        fieldToSave.resurfaceEventResult = resurfaceEventResult;

        this.setState({ showResurfacePrompt: false }, () =>
            this._saveFieldInformation(fieldToSave)
        );
    };

    _onResurfacePromptCanceled = () => {
        this.setState({ showResurfacePrompt: false, fieldToSave: null });
    };

    _validFieldInformation = (fieldToValidate, fieldGeometries) => {
        if (fieldToValidate.fieldName === "") {
            //console.error("Field Name cannot be blank");
            return false; //TODO: actually handle it
        } else if (fieldGeometries && fieldGeometries.length === 0) {
            //console.error("Must have a field boundary");
            return false; //TODO: actually handle it
        }

        return true;
    };

    onSaveFieldInformation = () => {
        const { activeField, fieldEditGeometries, fieldEditGeometriesChanged } = this.props;
        const fieldBoundaryPolygons = [];

        let fieldToSave = { ...activeField };
        const isNewField = fieldToSave.fieldGuid === "" || fieldToSave.fieldGuid == null;
        var newCalculatedArea = 0;
        if ((fieldEditGeometriesChanged || isNewField) && fieldEditGeometries) {
            fieldEditGeometries.forEach((geometry) => {
                fieldBoundaryPolygons.push({
                    fieldBoundaryPolygonGuid: uuid(),
                    importFieldBoundaryGuids: null,
                    fieldGuidsToCombine: null,
                    shapes: [JSON.stringify(geometry.toJSON())],
                });
                newCalculatedArea += GeometryUtils.calculateArea(geometry);
            });
        }

        fieldToSave.fieldBoundary = {
            ...fieldToSave.fieldBoundary,
            fieldGuid: fieldToSave.fieldGuid,
            fieldBoundaryPolygons,
        };
        // Always assign a new FieldBoundaryGuid for a new field.
        if (isNewField) {
            fieldToSave.fieldBoundary.fieldBoundaryGuid = uuid();
        }

        if (fieldBoundaryPolygons && fieldBoundaryPolygons.length > 0) {
            const fixedCalculatedArea = newCalculatedArea.toFixed(2);
            fieldToSave.acres = fixedCalculatedArea;
            fieldToSave.fieldBoundary.calculateArea = fixedCalculatedArea;
        }

        const filteredAgvanceField = this.props.agvanceFieldList.filter(
            (af) => af.name === fieldToSave.fieldName && af.farmName === fieldToSave.farmName
        );

        if (
            filteredAgvanceField.length > 0 &&
            !filteredAgvanceField[0].mappingGuid &&
            filteredAgvanceField[0].agvanceGrowerGuid === fieldToSave.agvanceCustomerGuid
        ) {
            fieldToSave.agvanceFieldGuid = filteredAgvanceField[0].agvanceGuid;
            fieldToSave.agvanceFieldId = filteredAgvanceField[0].fieldId;
            fieldToSave.agvanceFieldOrgLevelGuid = filteredAgvanceField[0].orgLevelGuid;
            fieldToSave.agvanceAutoMapField = true;
        }

        if (this._validFieldInformation(fieldToSave, fieldEditGeometries)) {
            if (GeometryUtils.verifyIntersections(fieldEditGeometries)) {
                this._showInvalidGeometriesDialog();
            } else if (GeometryUtils.checkMaxAcreage(fieldEditGeometries, this.props.maximumArea)) {
                this._showMaxAreaDialog();
            } else if (GeometryUtils.checkMinAcreage(fieldEditGeometries)) {
                this._showConfirmDialog(() => this._showResurfacePrompt(fieldToSave));
            } else {
                this._showResurfacePrompt(fieldToSave);
            }
        }
    };

    getAcresCalculation = () => {
        let fieldEditGeometries = this.props.fieldEditGeometries;
        let totalAcres = 0;
        if (fieldEditGeometries) {
            fieldEditGeometries.forEach((polygon) => {
                totalAcres += GeometryUtils.calculateArea(polygon);
            });
        }
        return totalAcres;
    };

    render() {
        const { activeCustomer, activeField } = this.props;
        const { formatMessage } = this.props.intl;
        const minAcres = ToolConfig.minimumAcreage;
        const maxAcres = this.props.maximumArea;
        const totalAcres = this.getAcresCalculation();

        return (
            <div className="field-information">
                <FieldInformationTitleBar
                    fieldName={this._getFieldName(activeField)}
                    customerName={this._getCustomerName(activeCustomer)}
                    farmName={this._getFarmName(activeField)}
                    acres={totalAcres}
                />
                <div className="field-information-content">
                    <FieldInformationMenu />
                    <FieldInformationDetail
                        activeField={this.props.activeField}
                        customerGuid={this.props.activeCustomer.customerGuid}
                        editFieldGuid={this.props.editFieldGuid}
                        hasActiveInactive={this.props.userInfo.role.activeInactive}
                        updateFieldModel={(f) => this.props.updateFieldModel(f)}
                    />
                </div>
                <FieldInformationButtonBar
                    saveIsEnabled={this._validFieldInformation(
                        this.props.activeField,
                        this.props.fieldEditGeometries
                    )}
                    onSave={() => this.onSaveFieldInformation()}
                    onCancel={() => this.props.onCloseFieldInformation()}
                />
                <DialogBox
                    title={formatMessage(messages.confirmSaveBelowTitle)}
                    footerType={DialogBoxFooterType.YES_NO}
                    isOpen={this.state.isConfirmationModalOpen}
                    onAction={() => this._confirmYes()}
                    onClose={() => this._closeConfirmDialog()}
                >
                    {formatMessage(messages.confirmSaveBelowMinAcreage, {
                        minAcres,
                    })}
                </DialogBox>
                <DialogBox
                    title={formatMessage(messages.errorAboveMaxAcreageTitle)}
                    isOpen={this.state.isMaxAreaModalOpen}
                    onAction={() => this._closeMaxAreaDialog()}
                    onClose={() => this._closeMaxAreaDialog()}
                >
                    {formatMessage(messages.errorAboveMaxAcreage, { maxAcres })}
                </DialogBox>
                <DialogBox
                    title={formatMessage(messages.errorInvalidGeometriesTitle)}
                    isOpen={this.state.isInvalidGeometries}
                    onClose={() => this._closeInvalidGeometries()}
                >
                    {formatMessage(messages.errorInvalidGeometries)}
                </DialogBox>
                {this.state.editFieldGuid !== "" && (
                    <FieldResurfacePrompt
                        fieldGuid={this.props.editFieldGuid}
                        isOpen={this.state.showResurfacePrompt}
                        onClose={this._onResurfacePromptClosed}
                        onCancel={this._onResurfacePromptCanceled}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeField: getFieldInfoModel(state),
    fieldEditGeometries: mapToolsSelectors.getFieldEditGeometries(state),
    fieldEditGeometriesChanged: mapToolsSelectors.getFieldEditsChanged(state),
    maximumArea: getSystemAreaLimit(state),
    agvanceFieldList: getAgvanceFieldList(state),
});
const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchFieldInformation: () =>
        dispatch(
            fetchFieldInformation(ownProps.activeCustomer.customerGuid, ownProps.editFieldGuid)
        ),
    invalidateFieldGuids: (guid) => dispatch(setFieldsToInvalidate(guid)),
    onSetToolsetDisabled: (flag) => dispatch(mapToolsActions.setToolsetDisabled(flag)),
    saveFieldInformation: (f, c) => dispatch(saveFieldInformation(f, c)),
    updateFieldModel: (f) => dispatch(updateFieldInfo(f)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withUser(injectIntl(FieldInformation)));
