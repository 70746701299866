import { Action } from "redux";
import { all, AllEffect, put, PutEffect, select, takeLatest } from "redux-saga/effects";

import { actions as loginActions, selectors as loginSelectors } from "~/login";

import * as actions from "./actions";

const COLOR_SCHEME_LIGHT = "1";

const FAVICON_ID = "dynamic-favicon";
const FAVICON_REL = "shortcut icon";

const getThemeClassName = (loginTheme) => {
    const { ThemeClassNamesDataBase, ThemeClassNames } = actions;
    switch (loginTheme.name) {
        case ThemeClassNamesDataBase.SKY:
            return loginTheme.colorScheme === COLOR_SCHEME_LIGHT
                ? ThemeClassNames.SKY_LIGHT
                : ThemeClassNames.SKY;
        case ThemeClassNamesDataBase.GRASS:
            return loginTheme.colorScheme === COLOR_SCHEME_LIGHT
                ? ThemeClassNames.GRASS_LIGHT
                : ThemeClassNames.GRASS;
        case ThemeClassNamesDataBase.MUD:
            return loginTheme.colorScheme === COLOR_SCHEME_LIGHT
                ? ThemeClassNames.MUD_LIGHT
                : ThemeClassNames.MUD;
        case ThemeClassNamesDataBase.ILLINI:
            return loginTheme.colorScheme === COLOR_SCHEME_LIGHT
                ? ThemeClassNames.ILLINI_LIGHT
                : ThemeClassNames.ILLINI;
        default:
            return loginTheme.colorScheme === COLOR_SCHEME_LIGHT
                ? ThemeClassNames.DEFAULT_LIGHT
                : ThemeClassNames.DEFAULT;
    }
};

const onSetSystemFaviconUrl = function (faviconUrl) {
    if (faviconUrl == null) {
        return;
    }

    const oldLink = document.getElementById(FAVICON_ID);
    if (oldLink) {
        document.head.removeChild(oldLink);
    }

    const link = document.createElement("link");
    link.id = FAVICON_ID;
    link.rel = FAVICON_REL;
    link.href = faviconUrl.url;
    document.head.appendChild(link);
};

const onSetUserInfo = function* () {
    const userInfo = yield select(loginSelectors.getUser);
    if (userInfo != null && userInfo.loginTheme != null) {
        const themeName = getThemeClassName(userInfo.loginTheme);
        yield put(actions.switchTheme(themeName));
    }
    const systemName = yield select(loginSelectors.getTheSystemName);
    document.title = systemName;
};

const onSwitchTheme = function (action) {
    for (const cn of Object.values(actions.ThemeClassNames)) {
        document.body.classList.remove(cn);
    }
    document.body.classList.add(action.payload);
};

export const themeSaga = function* (): Generator<AllEffect | PutEffect<Action>, void, unknown> {
    yield all([
        takeLatest(loginActions.SET_USER_INFO_COMPLETE, onSetUserInfo),
        takeLatest(loginActions.SET_SYSTEM_FAVICON_URL, onSetSystemFaviconUrl),
        takeLatest(actions.SWITCH_THEME, onSwitchTheme),
    ]);
    yield put(actions.switchTheme(actions.ThemeClassNames.DEFAULT));
};
