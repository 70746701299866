import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Loader } from "~/core";

import { clearRequestIds, needs } from "./needs";
import { getRequestIds } from "./selectors";
import { SUCCESS, FAILURE, PENDING, initializeRequestId } from "./utils";

const mapStateToProps = (state) => ({
    requestIds: getRequestIds(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearRequestIds: (payload) => dispatch(clearRequestIds(payload)),
    dispatch,
});

const withMaskedContainer = (View) => {
    return connect(mapStateToProps, mapDispatchToProps)(View);
};

const WithMasked = (View) => {
    class WithMasked extends Component {
        static propTypes = {
            dispatch: PropTypes.func,
            clearRequestIds: PropTypes.func,
            requestIds: PropTypes.object,
        };

        constructor(props) {
            super(props);
            this.state = {
                isProgress: false,
                failedRequests: [],
            };
            initializeRequestId();
        }

        getStatus = (requestCounter = 0, requestIds = {}, failedRequests = []) => {
            if (requestCounter === _.size(requestIds)) {
                if (failedRequests.length) {
                    return FAILURE;
                } else {
                    return SUCCESS;
                }
            } else {
                return PENDING;
            }
        };

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps !== this.props) {
                const successfulRequests = [],
                    failedRequests = [];
                let requestCounter = 0;
                for (let key of Object.keys(nextProps.requestIds)) {
                    if (nextProps.requestIds[key] === SUCCESS) {
                        successfulRequests.push(key);
                        requestCounter++;
                    }
                    if (nextProps.requestIds[key] === FAILURE) {
                        failedRequests.push(key);
                        requestCounter++;
                    }
                }
                this.setState({
                    status: this.getStatus(requestCounter, nextProps.requestIds, failedRequests),
                    failedRequests,
                });
            }
        }

        componentWillUnmount() {
            // Upon unmount dispacted an action to clear existing request ids
            // in the store
            this.props.clearRequestIds({});
        }

        needs = (actions) => {
            return needs(actions, this.props.dispatch);
        };

        render() {
            const { failedRequests, status } = this.state;
            const props = {
                ...this.props,
                failedRequests,
                needs: this.needs,
            };
            return (
                <div className="content-table-container with-masked-container">
                    {status === PENDING ? <Loader /> : null}
                    <View status={status} {...props} />
                </div>
            );
        }
    }
    return withMaskedContainer(WithMasked);
};

export default WithMasked;
