import { action } from "typesafe-actions";

export const ProductMixTypes = {
    FERTILIZER: "fertilizer",
    CHEMICAL: "chemical",
    MANURE: "manure",
};
export const PhysicalStates = {
    DRY: "dry",
    LIQUID: "liquid",
};

export const APPLY_BATCH_CREDITS = "productBlend/APPLY_BATCH_CREDITS";
export const APPLY_CREDITS = "productBlend/APPLY_CREDITS";
export const DEACTIVATE_PRODUCT_BLEND = "productBlend/DEACTIVATE_PRODUCT_BLEND";
export const FETCH_AVAILABLE_CREDITS = "productBlend/FETCH_AVAILABLE_CREDITS";
export const FETCH_CREDITED_GRID_CELLS = "productBlend/FETCH_CREDITED_GRID_CELLS";
export const FETCH_CUSTOM_BLENDS = "productBlend/FETCH_CUSTOM_BLENDS";
export const FETCH_CUSTOM_PRODUCTS = "productBlend/FETCH_CUSTOM_PRODUCTS";
export const INIT_PRODUCT_BLEND_MODAL = "productBlend/INIT_PRODUCT_BLEND_MODAL";
export const SAVE_PRODUCT_BLEND = "productBlend/SAVE_PRODUCT_BLEND";
export const SAVE_CUSTOM_PRODUCT = "productBlend/SAVE_CUSTOM_PRODUCT";
export const SET_AVAILABLE_CREDITS = "productBlend/SET_AVAILABLE_CREDITS";
export const SET_AVAILABLE_PRODUCTS = "productBlend/SET_AVAILABLE_PRODUCTS";
export const SET_PRODUCT_BLEND_GUID = "productBlend/SET_PRODUCT_BLEND_GUID";
export const SET_BATCH_CREDITED_REC_NUTRIENTS = "productBlend/SET_BATCH_CREDITED_REC_NUTRIENT";
export const SET_CONVERSION_FACTORS = "productBlend/SET_CONVERSION_FACTORS";
export const SET_CREDITED_GRID_CELLS = "productBlend/SET_CREDITED_GRID_CELLS";
export const SET_CREDITED_REC_NUTRIENT = "productBlend/SET_CREDITED_REC_NUTRIENT";
export const SET_CUSTOM_BLENDS = "productBlend/SET_CUSTOM_BLENDS";
export const SET_CUSTOM_PRODUCTS = "productBlend/SET_CUSTOM_PRODUCTS";
export const SET_DEFAULT_CARRIER_GUID = "productBlend/SET_DEFAULT_CARRIER_GUID";
export const SET_DEFAULT_CHEMICAL_TARGET_RATE = "productBlend/SET_DEFAULT_CHEMICAL_TARGET_RATE";
export const SET_DEFAULT_PRODUCT_MIX_TYPE_GUID = "productBlend/SET_DEFAULT_PRODUCT_MIX_TYPE_GUID";
export const SET_EVENT_REC_CUSTOM_PRODUCT_GUIDS = "productBlend/SET_EVENT_REC_CUSTOM_PRODUCT_GUIDS";
export const SET_FILTERED_PRODUCT_BLEND_PICKLISTS =
    "productBlend/SET_FILTERED_PRODUCT_BLEND_PICKLISTS";
export const SET_IS_LOADING = "productBlend/SET_IS_LOADING";
export const SET_PRODUCT_BLEND_PICKLISTS = "productBlend/SET_PRODUCT_BLEND_PICKLISTS";
export const SET_PRODUCT_FILTERS = "productBlend/SET_PRODUCT_FILTERS";
export const UPDATE_PRODUCT_FILTER = "productBlend/UPDATE_PRODUCT_FILTER";
export const UPDATE_BATCH_REC_DETAILS = "productBlend/UPDATE_BATCH_REC_DETAILS";

export const applyBatchCredits = (recNutrientGuidList, credits) =>
    action(APPLY_BATCH_CREDITS, { recNutrientGuidList, credits });

export const applyCredits = (recNutrientGuid, credits) =>
    action(APPLY_CREDITS, { recNutrientGuid, credits });
export const deactivateProductBlend = (productBlendGuid) =>
    action(DEACTIVATE_PRODUCT_BLEND, { productBlendGuid });
export const fetchAvailableCredits = (recNutrientGuid) =>
    action(FETCH_AVAILABLE_CREDITS, { recNutrientGuid });
export const fetchCreditedGridCells = (recNutrientGuidList) =>
    action(FETCH_CREDITED_GRID_CELLS, { recNutrientGuidList });
export const fetchCustomBlends = (productMixTypeGuid, nutrientGuid) =>
    action(FETCH_CUSTOM_BLENDS, { productMixTypeGuid, nutrientGuid });
export const fetchCustomProducts = () => action(FETCH_CUSTOM_PRODUCTS);
export const initProductBlendModal = () => action(INIT_PRODUCT_BLEND_MODAL);
export const saveProductBlend = (productBlend, isAuto = false, callback = null) =>
    action(SAVE_PRODUCT_BLEND, { productBlend, isAuto, callback });
export const saveCustomProduct = (customProduct) => action(SAVE_CUSTOM_PRODUCT, { customProduct });
export const setAvailableCredits = (availableCredits) =>
    action(SET_AVAILABLE_CREDITS, { availableCredits });
export const setAvailableProducts = (availableProducts) =>
    action(SET_AVAILABLE_PRODUCTS, { availableProducts });
export const setBatchCreditedRecNutrients = (batchCreditedRecNutrients) =>
    action(SET_BATCH_CREDITED_REC_NUTRIENTS, { batchCreditedRecNutrients });
export const setCreditedGridCells = (creditedGridCells) =>
    action(SET_CREDITED_GRID_CELLS, { creditedGridCells });
export const setCreditedRecNutrient = (creditedRecNutrient) =>
    action(SET_CREDITED_REC_NUTRIENT, { creditedRecNutrient });
export const setProductBlendGuid = (productBlendGuid) =>
    action(SET_PRODUCT_BLEND_GUID, { productBlendGuid });
export const setConversionFactors = (conversionFactors) =>
    action(SET_CONVERSION_FACTORS, { conversionFactors });
export const setCustomBlends = (customBlends) => action(SET_CUSTOM_BLENDS, { customBlends });
export const setCustomProducts = (customProducts) =>
    action(SET_CUSTOM_PRODUCTS, { customProducts });
export const setDefaultCarrierGuid = (defaultCarrierGuid) =>
    action(SET_DEFAULT_CARRIER_GUID, { defaultCarrierGuid });
export const setDefaultChemicalTargetRate = (defaultChemicalTargetRate) =>
    action(SET_DEFAULT_CHEMICAL_TARGET_RATE, { defaultChemicalTargetRate });
export const setDefaultProductMixTypeGuid = (defaultProductMixTypeGuid) =>
    action(SET_DEFAULT_PRODUCT_MIX_TYPE_GUID, { defaultProductMixTypeGuid });
export const setEventRecCustomProductGuids = (eventRecCustomProductGuids) =>
    action(SET_EVENT_REC_CUSTOM_PRODUCT_GUIDS, { eventRecCustomProductGuids });
export const setFilteredProductBlendPicklists = (filteredProductBlendPicklists) =>
    action(SET_FILTERED_PRODUCT_BLEND_PICKLISTS, { filteredProductBlendPicklists });
export const setIsLoading = (isLoading) => action(SET_IS_LOADING, { isLoading });
export const setProductBlendPicklists = (productBlendPicklists) =>
    action(SET_PRODUCT_BLEND_PICKLISTS, { productBlendPicklists });
export const setProductFilters = (productFilters) =>
    action(SET_PRODUCT_FILTERS, { productFilters });
export const updateProductFilter = (filterProperty: string, filterValue: any) =>
    action(UPDATE_PRODUCT_FILTER, { filterProperty, filterValue });
export const updateBatchRecDetails = (recAreaList) =>
    action(UPDATE_BATCH_REC_DETAILS, { recAreaList });
