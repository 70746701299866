import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_ACTIVE_YN_TEXT = "activeYnText";
export const PROPS_ADD_MODE = "addMode";
export const PROPS_AGBYTES_VALUE = "agBytesValue";
export const PROPS_ATTRIBUTE_VALUE = "attributeValue";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_MODULE_ID = "__moduleId__";
export const PROPS_VARIETY_ATTRIBUTE_CATEGORY = "attributeCategory";
export const PROPS_VARIETY_ATTRIBUTE_NAME = "varietyAttribute";
export const PROPS_VARIETY_HYBRID_ATTRIBUTE_GUID = "varietyHybridAttributeGuid";
export const PROPS_VARIETY_HYBRID_ID = "varietyHybridId";
export const PROPS_VARIETY_HYBRID_NAME = "varietyHybridName";
