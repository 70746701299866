export const FETCH_AUTO_TRACE_PREFERENCES =
    "event-info/sample-soil/FETCH_AUTO_TRACE_PREFERENCES_INIT";
export const FETCH_SAMPLE_SOIL_EVENT_DEFAULTS =
    "event-info/sample-soil/FETCH_SAMPLE_SOIL_EVENT_DEFAULTS";
export const FETCH_SAMPLE_SOIL_FORM_PICKLISTS =
    "event-info/sample-soil/FETCH_SAMPLE_SOIL_FORM_PICKLISTS";
export const SET_SHOW_PRODUCTIVITY_RATING = "event-info/sample-soil/SET_SHOW_PRODUCTIVITY_RATING";
export const SET_AUTO_TRACE_DEFAULT = "event-info/sample-soil/SET_AUTO_TRACE_DEFAULT";
export const SET_USER_DEPTH_CONFIG = "event-info/sample-soil/SET_USER_DEPTH_CONFIG";
export const SET_USER_GRID_SETTINGS = "event-info/sample-soil/SET_USER_GRID_SETTINGS";
export const UPDATE_AUTO_TRACE_PREFERENCES = "event-info/sample-soil/UPDATE_AUTO_TRACE_PREFERENCES";
export const UPDATE_USER_DEPTH_CONFIG = "event-info/sample-soil/UPDATE_USER_DEPTH_CONFIG";

export const fetchAutoTracePreferences = () => ({
    type: FETCH_AUTO_TRACE_PREFERENCES,
});

export const fetchSampleSoilEventDefaults = () => ({
    type: FETCH_SAMPLE_SOIL_EVENT_DEFAULTS,
});

export const fetchSampleSoilFormPicklists = () => ({
    type: FETCH_SAMPLE_SOIL_FORM_PICKLISTS,
});

//this action is to be used when the back end and database is updated to accomdate autoTrace as a user preference
//in the same way the user depth config is setup
export const setAutoTraceDefault = (autoTraceDefault) => ({
    type: SET_AUTO_TRACE_DEFAULT,
    payload: { autoTraceDefault },
});

export const setShowProductivityRating = (showProductivityRating) => ({
    type: SET_SHOW_PRODUCTIVITY_RATING,
    payload: { showProductivityRating },
});

export const setUserDepthConfig = (userDepthConfig) => ({
    type: SET_USER_DEPTH_CONFIG,
    payload: { userDepthConfig },
});

export const setUserGridSettings = (userGridSettings) => ({
    type: SET_USER_GRID_SETTINGS,
    payload: { userGridSettings },
});

export const updateAutoTracePreferences = (autoTraceDefault) => ({
    type: UPDATE_AUTO_TRACE_PREFERENCES,
    payload: { autoTraceDefault },
});

export const updateUserDepthConfig = (userDepthConfig) => ({
    type: UPDATE_USER_DEPTH_CONFIG,
    payload: { userDepthConfig },
});
