import * as actions from "./actions";

const newInitialState = () => ({
    embedDashboardUrl: null,
    analyticDashboardTypes: [],
    orgLevelList: null,
});

export const analyticsReducer = (state, action) => {
    if (state == null) {
        return newInitialState();
    }
    switch (action.type) {
        case actions.FETCH_DASHBOARD_URL_SUCCESS:
            return Object.freeze({
                ...state,
                embedDashboardUrl: action.payload.response.embedUrl,
            });
        case actions.FETCH_DASHBOARD_LIST_SUCCESS:
            return Object.freeze({
                ...state,
                analyticDashboardTypes: action.payload.response,
            });
        case actions.FETCH_ORG_LEVEL_LIST_SUCCESS:
            return Object.freeze({
                ...state,
                orgLevelList: action.payload,
            });
        default:
            return state;
    }
};
