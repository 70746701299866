import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { AutoSearch } from "~/core";
import { VendorAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login/selectors";

const messages = defineMessages({
    searchFor: {
        id: "admin.common.searchFor",
        defaultMessage: "Search For {searchedFor}",
    },
    vendor: { id: "admin.common.vendor", defaultMessage: "Vendor" },
});

export const VendorSearchView = ({
    formatMessage,
    initialValue,
    onSelection,
    userGuid,
    clearOnSelection,
    required,
    classNames,
}) => {
    return (
        <div className="vendor-search">
            <AutoSearch
                classNames={classNames}
                required={required}
                getAutoSearchList={VendorAPI.searchVendor}
                initialFilterStr={initialValue}
                keyProp={VendorSearchView.VENDOR_GUID}
                nameProp={VendorSearchView.VENDOR_NAME}
                itemList={[]}
                onSelection={onSelection}
                placeholderText={formatMessage(messages.searchFor, {
                    searchedFor: formatMessage(messages.vendor),
                })}
                secondaryPropList={[
                    VendorSearchView.VENDOR_CITY,
                    VendorSearchView.VENDOR_STATE_ABBR,
                ]}
                selectedValue={initialValue}
                userGuid={userGuid}
                clearOnSelection={clearOnSelection}
            />
        </div>
    );
};

VendorSearchView.VENDOR_CITY = "city";
VendorSearchView.VENDOR_STATE_ABBR = "stateAbbr";
VendorSearchView.VENDOR_GUID = "vendorGuid";
VendorSearchView.VENDOR_NAME = "vendorName";

VendorSearchView.propTypes = {
    classNames: PropTypes.array,
    clearOnSelection: PropTypes.bool,
    formatMessage: PropTypes.func,
    initialValue: PropTypes.string,
    onSelection: PropTypes.func,
    required: PropTypes.bool,
    userGuid: PropTypes.string,
};

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

export const VendorSearch = connect(mapStateToProps)(VendorSearchView);
