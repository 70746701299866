import { createService } from "~/hocs/crud";
import * as model from "./model";
import defaultOwnerRecord from "./default-record";
import { AddressAPI, OwnerAPI } from "@ai360/core"; //PersonAPI
import { adminData } from "~/admin/data";
import {
    PICKLIST_UNIT_SYSTEM,
    PICKLIST_PHONE_TYPE,
    getPickListCode,
} from "~/core/picklist/picklist-names";

// Module Name
export const MODEL_NAME = "owner";

//  Request payload
export const REQUEST_PAYLOAD_FILTER = "ownerFilter";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "ownerPageOptions";
export const REQUEST_PAYLOAD_SORT_LIST = "ownerSortList";
// URLs
export const AUTO_SEARCH_URL = OwnerAPI.AUTO_SEARCH_URL;
export const CREATE = OwnerAPI.ADD_OWNER;
export const DELETE = OwnerAPI.DELETE;
export const GETRECORD = OwnerAPI.GET_RECORD;
export const GET_THEME_LIST = OwnerAPI.GET_THEME_LIST;
export const UPDATE = OwnerAPI.UPDATE;
export const URL = OwnerAPI.GET_OWNER_LIST;

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        City: "",
        OrgLevelName: "",
        StateAbbr: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    activeOnly: true,
};

export const dropdowns = {
    async: false,
    [model.PROPS_ADDRESS_TYPE_LIST]: AddressAPI.REQUEST_ADDRESS_TYPE,
    [model.PROPS_COUNTRIES_LIST]: AddressAPI.REQUEST_COUNTRIES,
    [model.PROPS_STATES_LIST]: {
        url: AddressAPI.REQUEST_STATES,
        model: "",
    },
    [model.PROPS_THEME_LIST]: OwnerAPI.GET_THEME_LIST,
    [model.PROPS_PERSONALITIES_LIST]: OwnerAPI.GET_OWNER_PERSONALITY_LIST,
    [model.PROPS_OWNER_ADMIN_USER_LIST]: OwnerAPI.GET_OWNER_ADMIN_USER_LIST,
};
//
export const pickLists = {
    [PICKLIST_PHONE_TYPE]: getPickListCode(PICKLIST_PHONE_TYPE),
    [PICKLIST_UNIT_SYSTEM]: getPickListCode(PICKLIST_UNIT_SYSTEM),
};

// Service
export const service = createService({
    guid: model.PROPS_ORG_LEVEL_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    REQUEST_PAYLOAD_SORT_LIST,
    activeColumnName: adminData.PROPS_IS_ACTIVE,
    urls: {
        AUTO_SEARCH_URL,
        CREATE,
        GETRECORD,
        UPDATE,
        URL,
    },
    dropdowns,
    pickLists,
    _defaultLabels: {
        [model.PROPS_ORG_LEVEL_NAME]: { label: "ownerName", gridCol: 25 },
        [model.PROPS_ORG_LEVEL_CITY]: { label: "city", gridCol: 25 },
        [model.PROPS_ORG_LEVEL_STATE_ABBR]: {
            label: "state",
            gridCol: 25,
            sortNameOverRide: "stateAbbreviation",
        },
    },
    getDefaultRecord: () => ({ ...defaultOwnerRecord() }),
});
