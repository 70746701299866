import { defineMessages } from "react-intl";

export const messages = defineMessages({
    brandOrganization: {
        id: "eventModule.plantingEvent.brandOrganization",
        defaultMessage: "Brand/Organization",
    },
    costPerAcre: {
        id: "eventModule.plantingEvent.costPerAcre",
        defaultMessage: "Cost/Acre",
    },
    crop: { id: "eventModule.plantingEvent.crop", defaultMessage: "Crop" },
    plant: { id: "eventModule.plantingEvent.plant", defaultMessage: "Plant" },
    cropAgeWhenPlanted: {
        id: "eventModule.plantingEvent.cropAgeWhenPlanted",
        defaultMessage: "Crop Age When Planted",
    },
    cropInfo: {
        id: "eventModule.plantingEvent.cropInfo",
        defaultMessage: "Crop Info",
    },
    cropPurpose: {
        id: "eventModule.plantingEvent.cropPurpose",
        defaultMessage: "Crop Purpose",
    },
    crops: { id: "eventModule.plantingEvent.crops", defaultMessage: "Crops" },
    displayLayerPlaceholderTxt: {
        id: "eventModule.plantingEvent.displayLayerPlaceholderTxt",
        defaultMessage: "Display Layer",
    },
    fieldTotals: {
        id: "eventModule.plantingEvent.fieldTotals",
        defaultMessage: "Field Totals:",
    },
    lot: { id: "eventModule.plantingEvent.lot", defaultMessage: "Lot #" },
    percentageOfPlanter: {
        id: "eventModule.plantingEvent.percentageOfPlanter",
        defaultMessage: "% of Planter",
    },
    plantSpacing: {
        id: "eventModule.plantingEvent.plantSpacing",
        defaultMessage: "Plant Spacing",
    },
    plantingDepth: {
        id: "eventModule.plantingEvent.plantingDepth",
        defaultMessage: "Planting Depth",
    },
    plantingFormLabelText: {
        id: "eventModule.plantingEvent.plantingFormLabelText",
        defaultMessage: "Planting",
    },
    plantingStyle: {
        id: "eventModule.plantingEvent.plantingStyle",
        defaultMessage: "Planting Style",
    },
    plantsPer: {
        id: "eventModule.plantingEvent.plantsPer",
        defaultMessage: "Plants Per",
    },
    price: {
        id: "eventModule.plantingEvent.price",
        defaultMessage: "Price ($)",
    },
    product: {
        id: "eventModule.plantingEvent.product",
        defaultMessage: "Product",
    },
    rate: { id: "eventModule.plantingEvent.rate", defaultMessage: "Rate" },
    rootstockName: {
        id: "eventModule.plantingEvent.rootstockName",
        defaultMessage: "Rootstock Name",
    },
    rowOrientation: {
        id: "eventModule.plantingEvent.rowOrientation",
        defaultMessage: "Row Orientation",
    },
    rowSpacing: {
        id: "eventModule.plantingEvent.rowSpacing",
        defaultMessage: "Row Spacing",
    },
    seedCount: {
        id: "eventModule.plantingEvent.seedCount",
        defaultMessage: "Seed Count",
    },
    seedingRate: {
        id: "eventModule.plantingEvent.seedingRate",
        defaultMessage: "Seeding Rate",
    },
    totalCost: {
        id: "eventModule.plantingEvent.totalCost",
        defaultMessage: "Total Cost",
    },
    transplant: {
        id: "eventModule.plantingEvent.transplant",
        defaultMessage: "Transplant",
    },
    transplantType: {
        id: "eventModule.plantingEvent.transplantType",
        defaultMessage: "Transplant Type",
    },
    unit: { id: "eventModule.plantingEvent.unit", defaultMessage: "Unit" },
    units: { id: "eventModule.plantingEvent.units", defaultMessage: "Units" },
    varietyHybrid: {
        id: "eventModule.plantingEvent.varietyHybrid",
        defaultMessage: "Variety/Hybrid",
    },
    varietyHybridInfo: {
        id: "eventModule.plantingEvent.varietyHybridInfo",
        defaultMessage: "Variety/Hybrid Info",
    },
    zoneInterpolationCbLabel: {
        id: "eventModule.plantingEvent.zoneInterpolationCbLabel",
        defaultMessage: "Zone Interpolation",
    },
    zoneSize: {
        id: "eventModule.plantingEvent.zoneSize",
        defaultMessage: "{calculatedArea, number} Acres",
    },
    zoneSizeBatch: {
        id: "eventModule.plantingEvent.zoneSizeBatch",
        defaultMessage: "Batch Event",
    },
    myAgData: {
        id: "eventModule.plantingEvent.myAgData",
        defaultMessage: "MyAgData",
    },
    commodity: {
        id: "eventModule.plantingEvent.commodity",
        defaultMessage: "Commodity",
    },
    commodityType: {
        id: "eventModule.plantingEvent.commodityType",
        defaultMessage: "Commodity Type",
    },
    intendedUse: {
        id: "eventModule.plantingEvent.intendedUse",
        defaultMessage: "Intended Use",
    },
    croppingPractice: {
        id: "eventModule.plantingEvent.croppingPractice",
        defaultMessage: "Cropping Practice",
    },
});
