import { createAction } from "redux-act";

const FETCH_ACCOUNT = "FETCH_ACCOUNT_DATA";
const FETCH_ACCOUNT_FAILED = "FETCH_ACCOUNT_FAILED";
const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS";

const FETCH_CONNECTION_TYPE_LIST = "FETCH_CONNECTION_TYPE_LIST";
const FETCH_CONNECTION_TYPE_LIST_FAILED = "FETCH_CONNECTION_TYPE_LIST_FAILED";
const FETCH_CONNECTION_TYPE_LIST_SUCCESS = "FETCH_CONNECTION_TYPE_LIST_SUCCESS";

const FETCH_REGISTRATION_URL = "FETCH_REGISTRATION_URL";
const FETCH_REGISTRATION_URL_FAILED = "FETCH_REGISTRATION_FAILED";
const FETCH_REGISTRATION_URL_SUCCESS = "FETCH_REGISTRATION_SUCCESS";

const GET_USER_ONSITE_USER_KEY = "GET_USER_ONSITE_USER_KEY";
const GET_USER_ONSITE_USER_KEY_FAILED = "GET_USER_ONSITE_USER_FAILED";
const GET_USER_ONSITE_USER_KEY_SUCCESS = "GET_USER_ONSITE_USER_SUCCESS";

const SAVE_ACCOUNT = "SAVE_ACCOUNT_DATA";
const SAVE_ACCOUNT_FAILED = "SAVE_ACCOUNT_FAILED";
const SAVE_ACCOUNT_SUCCESS = "SAVE_ACCOUNT_SUCCESS";

export const fetchAccount = createAction(FETCH_ACCOUNT);
export const fetchAccountFailure = createAction(FETCH_ACCOUNT_FAILED);
export const fetchAccountSuccess = createAction(FETCH_ACCOUNT_SUCCESS);

export const fetchConnectionTypeList = createAction(FETCH_CONNECTION_TYPE_LIST);
export const fetchConnectionTypeListFailure = createAction(FETCH_CONNECTION_TYPE_LIST_FAILED);
export const fetchConnectionTypeListSuccess = createAction(FETCH_CONNECTION_TYPE_LIST_SUCCESS);

export const fetchUrlForRegistration = createAction(FETCH_REGISTRATION_URL);
export const fetchUrlForRegistrationFailure = createAction(FETCH_REGISTRATION_URL_FAILED);
export const fetchUrlForRegistrationSuccess = createAction(FETCH_REGISTRATION_URL_SUCCESS);

export const getOnSiteEquipmentList = createAction(GET_USER_ONSITE_USER_KEY);
export const getOnSiteEquipmentListFailure = createAction(GET_USER_ONSITE_USER_KEY_FAILED);
export const getOnSiteEquipmentListSuccess = createAction(GET_USER_ONSITE_USER_KEY_SUCCESS);

export const saveAccount = createAction(SAVE_ACCOUNT);
export const saveAccountFailure = createAction(SAVE_ACCOUNT_FAILED);
export const saveAccountSuccess = createAction(SAVE_ACCOUNT_SUCCESS);
