import { all, put, takeLatest, select, AllEffect } from "redux-saga/effects";
import * as actions from "./actions";
import { actions as notificationActions } from "~/notifications";
import { SystemAPI } from "@ai360/core";
import { loginActions } from ".";
import { getUser } from "~/login/selectors";

const fetchReleaseNotes = function* () {
    try {
        const response = yield SystemAPI.getReleaseNotes();
        yield put(actions.setReleaseNotesSuccess(response));
    } catch (err) {
        yield put(actions.setReleaseNotesFailure());
        yield put(notificationActions.apiCallError(err));
    }
};

const updateReleaseNotes = function* (payload) {
    try {
        const userInfo = yield select(getUser);
        const releaseNotes = { userGuid: userInfo.userGuid, Model: payload.releaseNotes };
        yield SystemAPI.UpdateReleaseNotes(releaseNotes);
        yield put(actions.saveReleaseNotesSuccess());
        const response = yield SystemAPI.getReleaseNotes();
        yield put(loginActions.setReleaseNotes(response));
    } catch (err) {
        yield put(actions.saveReleaseNotesFailed());
        yield put(notificationActions.apiCallError(err));
    }
};

export function* setupReleaseNotesSaga(): Generator<AllEffect, void, any> {
    yield all([
        takeLatest(actions.FETCH_RELEASE_NOTES_DATA, fetchReleaseNotes),
        takeLatest(actions.UPDATE_RELEASE_NOTES, updateReleaseNotes),
    ]);
}
