import { defineMessages } from "react-intl";

export const messages = defineMessages({
    dataExchange: {
        id: "importModule.contextMenu.dataExchange",
        defaultMessage: "Data Exchange",
    },
    importSelected: {
        id: "importModule.contextMenu.importSelected",
        defaultMessage: "Import Selected",
    },
    importFile: {
        id: "importModule.contextMenu.importFile",
        defaultMessage: "Import Single File",
    },
    deleteFile: {
        id: "importModule.contextMenu.deleteFile",
        defaultMessage: "Delete",
    },
    deleteFileConfirmation: {
        id: "importModule.confirmationDialog.deleteFileConfirmation",
        defaultMessage: "Are you sure you want to delete?",
    },
    uploadDt: {
        id: "importModule.fileInfoAccordionItem.uploadDt",
        defaultMessage: "Uploaded: {uploadTimeStr}",
    },
    importCount: {
        id: "importModule.fileInfoAccordionItem.importCount",
        defaultMessage: "{totalPoints, number} Point(s)",
    },
    polygonCount: {
        id: "importModule.fileInfoAccordionItem.polygonCount",
        defaultMessage: "{totalPoints, number} Polygon(s)",
    },
    layerCount: {
        id: "importModule.fileInfoAccordionItem.layerCount",
        defaultMessage: "1 Layer(s)",
    },
});
