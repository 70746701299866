import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const ZoneExplodeIcon = withSvgIcon(
    <g>
        <rect x="14.71" y="27.51" width="2.56" height="4.38" />
        <rect x="14.71" y="20.63" width="2.56" height="4.38" />
        <rect x="14.71" y="34.39" width="2.56" height="4.38" />
        <rect x="14.71" y="6.88" width="2.56" height="4.38" />
        <rect x="14.71" y="13.75" width="2.56" height="4.38" />
        <path
            d={
                "M42.86,40.74l6.17-1-5.93-2.22,2.57-3.41-4.28.67,3-8.68-4.81,6V3.31H17.78V0H15.23V3.31" +
                "H.52v39H15.23v3.31h2.56V42.33H30.16L27.1,44.86l7.56-2.09L32.85,50l4.8-6,3,3,.17-3.73L50,47.26ZM3.07," +
                "5.87H37V31.79l-2.17-2.7.47,5.25-7.74-4.08,5.66,6.55-4.34,1.68,3.42,1.28H3.07Z"
            }
            transform="translate(-0.52)"
        />
    </g>,
    "0 0 49.48 50"
);
