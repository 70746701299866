import { AgEventAPI } from "@ai360/core";
import { ScoutingTemplate, ScoutingTemplateObservation } from "~/recs-events/events/model";
import { IEventScouting } from "./interfaces";
import { IScoutingDetail } from "@ai360/core/dist/4x/es/api/ag-event/ag-event.4x";

const CROP = "Crop";
const DISEASE = "Disease";
const INSECT = "Insect";
const WEED = "Weed";
const OTHER = "Other";
const NEMATODE = "Nematode";

export const applyCropTemplateValues = (
    scoutingTemplate: ScoutingTemplate,
    agEventModel: AgEventAPI.IAgEventScouting,
    templateOverwrite: boolean
): IEventScouting => {
    //Brand and Variety are always overwritten with any applicable template values
    //Units are overwritten only if applicable template value exists

    const brandOrganizationGuid = scoutingTemplate.cropInfo.showBrandOrganization
        ? scoutingTemplate.cropInfo.brandOrganizationGuid
        : null;

    const varietyHybridGuid = scoutingTemplate.cropInfo.showVarietyHybrid
        ? scoutingTemplate.cropInfo.varietyHybridGuid
        : null;

    const cropHeightUnitsGuid =
        scoutingTemplate.cropInfo.showCropHeight && scoutingTemplate.cropInfo.cropHeightUnitGuid
            ? scoutingTemplate.cropInfo.cropHeightUnitGuid
            : agEventModel.cropHeightUnitsGuid
            ? agEventModel.cropHeightUnitsGuid
            : null;

    const cropPopulationUnitsGuid =
        scoutingTemplate.cropInfo.showCropPopulation &&
        scoutingTemplate.cropInfo.cropPopulationUnitGuid
            ? scoutingTemplate.cropInfo.cropPopulationUnitGuid
            : agEventModel.cropPopulationUnitsGuid
            ? agEventModel.cropPopulationUnitsGuid
            : null;

    return {
        brandOrganizationGuid,
        varietyHybridGuid,
        cropGrowthStageGuid: templateOverwrite
            ? scoutingTemplate.cropInfo.showCropGrowthStage
                ? scoutingTemplate.cropInfo.cropGrowthStageGuid
                : null
            : agEventModel.cropGrowthStageGuid,
        cropConditionGuid: templateOverwrite
            ? scoutingTemplate.cropInfo.showCropCondition
                ? scoutingTemplate.cropInfo.cropConditionGuid
                : null
            : agEventModel.cropConditionGuid,
        cropInjuryRatingGuid: templateOverwrite
            ? scoutingTemplate.cropInfo.showCropInjuryRating
                ? scoutingTemplate.cropInfo.cropInjuryRatingGuid
                : null
            : agEventModel.cropInjuryRatingGuid,
        daysAfterCropDamage: templateOverwrite
            ? scoutingTemplate.cropInfo.showDaysAfterCropDamage
                ? scoutingTemplate.cropInfo.daysAfterCropDamage
                : null
            : agEventModel.daysAfterCropDamage,
        cropHeight: templateOverwrite
            ? scoutingTemplate.cropInfo.showCropHeight
                ? scoutingTemplate.cropInfo.cropHeight
                : null
            : agEventModel.cropHeight,
        cropHeightUnitsGuid,
        cropPopulation: templateOverwrite
            ? scoutingTemplate.cropInfo.showCropPopulation
                ? scoutingTemplate.cropInfo.cropPopulation
                : null
            : agEventModel.cropPopulation,
        cropPopulationUnitsGuid,
        yieldPercentLoss: templateOverwrite
            ? scoutingTemplate.cropInfo.showYieldPercentLoss
                ? scoutingTemplate.cropInfo.yieldPercentLoss
                : null
            : agEventModel.yieldPercentLoss,
        standPercentLoss: templateOverwrite
            ? scoutingTemplate.cropInfo.showStandPercentLoss
                ? scoutingTemplate.cropInfo.standPercentLoss
                : null
            : agEventModel.standPercentLoss,
        leafPercentLoss: templateOverwrite
            ? scoutingTemplate.cropInfo.showLeafPercentLoss
                ? scoutingTemplate.cropInfo.leafPercentLoss
                : null
            : agEventModel.leafPercentLoss,
    };
};

export const applyTemplateNewObsValues = (
    templateObservationItem: ScoutingTemplateObservation,
    observation?: AgEventAPI.IScoutingDetail,
    completeOverwrite?: boolean
): IEventScouting => {
    const countUnitsGuid = templateObservationItem.countUnitsGuid
        ? templateObservationItem.countUnitsGuid
        : observation
        ? observation.countUnitsGuid
        : null;
    const weedHeightUnitsGuid = templateObservationItem.weedHeightUnitsGuid
        ? templateObservationItem.weedHeightUnitsGuid
        : observation
        ? observation.weedHeightUnitsGuid
        : null;
    return {
        eventScoutingDetailGuid: completeOverwrite
            ? ""
            : observation
            ? observation.eventScoutingDetailGuid
            : null,
        observationGuid: completeOverwrite
            ? templateObservationItem.observationGuid
            : observation
            ? observation.observationGuid
            : null,
        growthStageGuid: completeOverwrite
            ? templateObservationItem.growthStageGuid
            : observation
            ? observation.growthStageGuid
            : null,
        densityRatingGuid: completeOverwrite
            ? templateObservationItem.densityRatingGuid
            : observation
            ? observation.densityRatingGuid
            : null,
        observationCount: completeOverwrite
            ? templateObservationItem.observationCount
            : observation
            ? observation.observationCount
            : null,
        countUnitsGuid,
        photoList: completeOverwrite ? [] : observation ? observation.photoList : [],
        plantLocationGuid: completeOverwrite
            ? templateObservationItem.plantLocationGuid
            : observation
            ? observation.plantLocationGuid
            : null,
        weedHeight: completeOverwrite
            ? templateObservationItem.weedHeight
            : observation
            ? observation.weedHeight
            : null,
        weedHeightUnitsGuid,
        trapID: completeOverwrite
            ? templateObservationItem.trapId
            : observation
            ? observation.trapID
            : null,
    };
};

export const applyMissingTemplateObservations = (
    templateObservationInfo: ScoutingTemplateObservation[],
    eventObservations: AgEventAPI.IScoutingDetail[],
    fromTemplate: boolean
): IScoutingDetail[] => {
    templateObservationInfo.forEach((ob) => {
        const matchingEventObservation = eventObservations.find(
            (obs) => obs.observationGuid === ob.observationGuid
        );
        if (!matchingEventObservation) {
            //Observation exists on template that does not yet exist on Event
            if (fromTemplate) {
                eventObservations.push({
                    countUnitsGuid: ob.countUnitsGuid,
                    densityRatingGuid: ob.densityRatingGuid,
                    eventScoutingDetailGuid: null,
                    growthStageGuid: ob.growthStageGuid,
                    hasScoutingDetails: null,
                    photoList: [],
                    plantLocationGuid: ob.plantLocationGuid,
                    observationCount: ob.observationCount ? ob.observationCount.toString() : null,
                    observationGuid: ob.observationGuid,
                    trapID: ob.trapId,
                    weedHeight: ob.weedHeight ? ob.weedHeight.toString() : null,
                    weedHeightUnitsGuid: ob.weedHeightUnitsGuid,
                });
            } else {
                eventObservations.push({
                    countUnitsGuid: "",
                    densityRatingGuid: "",
                    eventScoutingDetailGuid: null,
                    growthStageGuid: "",
                    hasScoutingDetails: null,
                    photoList: [],
                    plantLocationGuid: "",
                    observationCount: null,
                    observationGuid: ob.observationGuid,
                    trapID: "",
                    weedHeight: null,
                    weedHeightUnitsGuid: "",
                });
            }
        }
    });
    return eventObservations;
};

export const formatSelectedObservation = (
    observationData: Array<any>,
    currentDetail: AgEventAPI.IScoutingDetail
): IEventScouting => {
    const observation =
        observationData.find((option) => option.guid === currentDetail.observationGuid) || {};
    return {
        activeYn: observation.activeYn,
        categoryIndex: observation.categoryIndex,
        categoryName: observation.categoryName,
        guid: currentDetail.observationGuid,
        id: observation.id,
        name: observation.name,
        useOnly: observation.useOnly,
    };
};

export const getCategoryName = (
    observationData: Array<any>,
    observation: AgEventAPI.IScoutingDetail
): string => {
    const selectedObservation =
        observationData.find((option) => option.guid === observation.observationGuid) || {};
    return getCategoryGroupName(selectedObservation.categoryIndex).toLowerCase();
};

export const getCategoryGroupName = (categoryIndex: number): string => {
    switch (categoryIndex) {
        case 1:
            return CROP;
        case 2:
            return DISEASE;
        case 3:
            return INSECT;
        case 4:
            return WEED;
        case 5:
            return OTHER;
        case 6:
            return NEMATODE;
        default:
            return OTHER;
    }
};
