import type { ReportBooksAPI, SetupReportBooksAPI } from "@ai360/core";
import { action } from "typesafe-actions";
import { IReportBookResultInfo } from "~/report-books/data/interface";

export interface ReducerAction {
    clearReportBookTypes: boolean;
    loadingReportBook: boolean;
    refreshReportBooks: string;
    reportBookErrorsMap: Map<string, IReportBookResultInfo>;
    reportBooks: ReportBooksAPI.IReportBookData[];
    reportBooksAutoComplete: ReportBooksAPI.IReportBookAutoCompleteResponse;
    reportBooksSelectAll: string[];
    reportBookTypes: SetupReportBooksAPI.IReportBookTypeRecord[];
    reportBooksTotalCount: number;
    payload: any;
    type: string;
}

export const DEACTIVATE_REPORT_BOOKS = "reportBooks/DEACTIVATE_REPORT_BOOKS";
export const GET_REPORT_BOOKS = "reportBooks/GET_REPORT_BOOKS";
export const MERGE_REPORT_BOOKS = "reportBooks/MERGE_REPORT_BOOKS";
export const SET_REPORT_BOOKS = "reportBooks/SET_REPORT_BOOKS";

export const GET_REPORT_BOOKS_AUTO_COMPLETE = "reportBooks/GET_REPORT_BOOKS_AUTO_COMPLETE";
export const SET_REPORT_BOOKS_AUTO_COMPLETE = "reportBooks/SET_REPORT_BOOKS_AUTO_COMPLETE";

export const GET_REPORT_BOOKS_SELECT_ALL = "reportBooks/GET_REPORT_BOOKS_SELECT_ALL";
export const SET_REPORT_BOOKS_SELECT_ALL = "reportBooks/SET_REPORT_BOOKS_SELECT_ALL";

export const SET_REPORT_BOOKS_TOTAL_COUNT = "reportBooks/SET_REPORT_BOOKS_TOTAL_COUNT";

export const GET_REPORT_BOOK_TYPES = "reportBooks/GET_REPORT_BOOK_TYPES";
export const SET_REPORT_BOOK_TYPES = "reportBooks/SET_REPORT_BOOK_TYPES";

export const SET_REPORT_BOOKS_ERRORS_MAP = "reportBooks/SET_REPORT_BOOKS_ERRORS_MAP";
export const SET_CLEAR_REPORT_BOOK_TYPES = "reportBooks/SET_CLEAR_REPORT_BOOK_TYPES";
export const SET_REPORT_BOOK_ERRORS = "reportBooks/SET_REPORT_BOOK_ERRORS";
export const SET_REPORT_BOOK_LOADING = "reportBooks/SET_REPORT_BOOK_LOADING";

export const REFRESH_REPORT_BOOKS = "reportBooks/REFRESH_REPORT_BOOKS";

export const deactivateReportBooks = (data: {
    reportBookGuids: string[];
    fieldGuids: string[];
    filterOptions: ReportBooksAPI.IReportBookFilterOptions;
}) =>
    action(DEACTIVATE_REPORT_BOOKS, {
        reportBookGuids: data.reportBookGuids,
        fieldGuids: data.fieldGuids,
        filterOptions: data.filterOptions,
    });

export const getReportBooks = (payload: {
    fieldGuids: string[];
    filterOptions: ReportBooksAPI.IReportBookFilterOptions;
}) =>
    action(GET_REPORT_BOOKS, {
        fieldGuids: payload.fieldGuids,
        filterOptions: payload.filterOptions,
    });

export const getReportBooksAutoComplete = (payload: {
    fieldGuids: string[];
    filterOptions: ReportBooksAPI.IReportBookFilterOptions;
    searchName: string;
    searchString: string;
}) =>
    action(GET_REPORT_BOOKS_AUTO_COMPLETE, {
        fieldGuids: payload.fieldGuids,
        filterOptions: payload.filterOptions,
        searchName: payload.searchName,
        searchString: payload.searchString,
    });

export const getReportBooksSelectAll = (payload: {
    fieldGuids: string[];
    filterOptions: ReportBooksAPI.IReportBookFilterOptions;
}) =>
    action(GET_REPORT_BOOKS_SELECT_ALL, {
        fieldGuids: payload.fieldGuids,
        filterOptions: payload.filterOptions,
    });

export const getReportBookTypes = () => action(GET_REPORT_BOOK_TYPES);

export const mergeReportBooks = (data: ReportBooksAPI.IMergeReportBookRequest) =>
    action(MERGE_REPORT_BOOKS, data);

export const refreshReportBooks = (trackingGuid: string) =>
    action(REFRESH_REPORT_BOOKS, trackingGuid);

export const setClearSelectedReportBookTypes = (clearReportBookTypes: boolean) =>
    action(SET_CLEAR_REPORT_BOOK_TYPES, clearReportBookTypes);

export const setReportBookErrorsMap = (reportBookErrorsMap: Map<string, IReportBookResultInfo>) =>
    action(SET_REPORT_BOOKS_ERRORS_MAP, reportBookErrorsMap);

export const setReportBookLoading = (loadingReportBook: boolean) =>
    action(SET_REPORT_BOOK_LOADING, loadingReportBook);

export const setReportBooks = (reportBooks: ReportBooksAPI.IReportBookData[]) =>
    action(SET_REPORT_BOOKS, reportBooks);

export const setReportBooksAutoComplete = (
    reportBooksAutoComplete: ReportBooksAPI.IReportBookAutoCompleteResponse
) => action(SET_REPORT_BOOKS_AUTO_COMPLETE, reportBooksAutoComplete);

export const setReportBooksSelectAll = (reportBooksSelectAll: string[]) =>
    action(SET_REPORT_BOOKS_SELECT_ALL, reportBooksSelectAll);

export const setReportBooksTotalCount = (reportBooksTotalCount: number) =>
    action(SET_REPORT_BOOKS_TOTAL_COUNT, reportBooksTotalCount);

export const setReportBookTypes = (reportBookTypes: SetupReportBooksAPI.IReportBookTypeRecord[]) =>
    action(SET_REPORT_BOOK_TYPES, reportBookTypes);
