import * as actions from "./actions";

const newInitialState = () => ({
    isLoading: false,
    notificationList: [],
    samplingId: null,
});

export const notificationReducer = (state, action) => {
    if (state == null) {
        return newInitialState();
    }

    switch (action.type) {
        case actions.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };
        case actions.UPDATE_NOTIFICATION_LIST:
            return {
                ...state,
                notificationList: action.payload.notificationList,
            };
        case actions.SET_SAMPLING_ID:
            return {
                ...state,
                samplingId: action.payload.samplingId,
            };
        default:
            return state;
    }
};
