import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { DataTable } from "~/core";
import { withCrud } from "~/hocs";
import { FormattingHelpers } from "@ai360/core";

import { service, actions } from "./log-data";
import { messages } from "../i18n-messages";

class VarietyHybridAttributeLog_ extends Component {
    static propTypes = {
        records: PropTypes.array,
        intl: intlShape.isRequired,
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.records) {
            for (const item of nextProps.records) {
                FormattingHelpers.formatLogDetailText(item);
            }
        }
    }
    render() {
        return (
            <div>
                <DataTable
                    service={service}
                    isCheckbox={false}
                    showFilterInput={false}
                    messages={messages}
                    {...this.props}
                />
            </div>
        );
    }
}
export const VarietyHybridAttributeLog = injectIntl(
    withCrud(VarietyHybridAttributeLog_, service, actions)
);
