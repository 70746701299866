import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { ReportTable, ReportOptionsContainer, ReportTypes } from "../components";
import { DialogBox, DialogBoxFooterType, Loader } from "~/core";
import { SUCCESS, FAILURE } from "~/hocs/needs/utils";
import { CategoryTree } from "../components";
import { yieldByDataSet } from "../components";
import { withReport } from "../hocs";
import { ReportAPI } from "@ai360/core";
import "../reports.css";
import { messages } from "../i18n-messages";
import { selectors, actions, model, categoryTypes } from "../data";
import { actions as vendorActions, selectors as vendorSelectors } from "~/admin/setup/vendor/data";
import { eventsSelectors } from "~/recs-events";
import { recsSelectors } from "~/recs-events";

const service = {
    REPORT_TYPE_LIST_URL: ReportAPI.GET_FIELD_REPORT_TYPE_LIST_URL,
    REPORT_TYPE_PAGE_LIST_URL: ReportAPI.GET_REPORT_TYPE_PAGE_LIST_URL,
};

export class FieldReport_ extends Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        agEventSummaryMap: PropTypes.instanceOf(Map),
        categories: PropTypes.object,
        createReport: PropTypes.func,
        createReportHubProgress: PropTypes.bool,
        crops: PropTypes.array,
        cropPurpose: PropTypes.array,
        dataSet: PropTypes.object,
        defaultFileNameFormat: PropTypes.string,
        defaultMultiFieldRec: PropTypes.bool,
        fileNameFormats: PropTypes.array,
        selectedFieldCount: PropTypes.number,
        selectedFields: PropTypes.array,
        getYieldByCompareByOption: PropTypes.func,
        getTestPackageListData: PropTypes.func,
        getTestingLabVendorListData: PropTypes.func,
        getFieldPreferences: PropTypes.func,
        initialRequestOptions: PropTypes.object,
        initializeReportData: PropTypes.func,
        intl: intlShape.isRequired,
        modalTitle: PropTypes.string,
        needs: PropTypes.func,
        onAllReportCreated: PropTypes.func,
        onInputChange: PropTypes.func,
        onStatusChange: PropTypes.func,
        recGeneralGuidToRecMap: PropTypes.instanceOf(Map),
        reportPreferences: PropTypes.object,
        reportTypes: PropTypes.array,
        reportTypePages: PropTypes.array,
        requestIds: PropTypes.object,
        seasons: PropTypes.array,
        selectReportType: PropTypes.func,
        selectedReportTypeNames: PropTypes.array,
        selectedReportTypes: PropTypes.array,
        selectedEventGuidSet: PropTypes.object,
        selectedRecGuidSet: PropTypes.object,
        showModal: PropTypes.bool,
        totalingUnit: PropTypes.array,
        testingLabVendors: PropTypes.array,
        testPackages: PropTypes.array,
        updateYieldByAnalysisCategory: PropTypes.func,
        updateYieldByCompareByOptions: PropTypes.func,
        yieldCategories: PropTypes.object,
        yieldByCompareByOptions: PropTypes.object,
    };
    static defaultProps = {
        reportTypePages: [],
    };
    ///----------------------------///
    constructor(props) {
        super(props);
        this.activeModal = 0;
        this.modalTitle = "";
        this.categories = {};
        this.dataSet = yieldByDataSet(this.props.intl.formatMessage);
        this.showModal = false;
        this.yieldByDataSet = yieldByDataSet(this.props.intl.formatMessage);

        this.state = {
            isLoading: false,
        };
        this.props.needs([
            this.props.getTestingLabVendorListData(),
            this.props.getYieldByCompareByOption(),
        ]);
        this._initializeLabSubmission();
    }
    _initializeLabSubmission = () => {
        if (this.props.reportPreferences.labSubmissionTestingLabVendorGuid) {
            this.getTestPackageListData(
                this.props.reportPreferences.labSubmissionTestingLabVendorGuid
            );
        }
        this.props.onInputChange(
            model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID,
            this.props.reportPreferences[model.reportPreferenceKeys.labSubmissionSampleTypeGuid]
        );
        this.props.onInputChange(
            model.PROPS_TEST_PACKAGE_GUID,
            this.props.reportPreferences.labSubmissionTestPackageGuid
        );
        this.props.onInputChange(
            model.PROPS_TESTING_LAB_VENDOR_GUID,
            this.props.reportPreferences.labSubmissionTestingLabVendorGuid
        );
        this.props.onInputChange(model.PROPS_LAB_ACCOUNT, this.props.reportPreferences.labAccount);
    };

    onClickCategories = (type) => {
        const { formatMessage } = this.props.intl;
        switch (type) {
            case categoryTypes.yieldBy:
                if (this.props.getYieldByCompareByOption) {
                    this.props.needs([this.props.getYieldByCompareByOption()]);
                }
                this.modalTitle = formatMessage(messages.yieldByCompareByOptions);
                this.showModal = true;
                this.categories = this.props.yieldByCompareByOptions;
                this.dataSet = this.yieldByDataSet;
                this.activeModal = categoryTypes.yieldBy;
                break;
            default:
                break;
        }
    };

    onModalSave = () => {
        this.setState({
            isLoading: true,
        });
        switch (this.activeModal) {
            case categoryTypes.yieldBy:
                this.yieldByCompareByOptionsSaveRequestId = this.props.needs([
                    this.props.updateYieldByCompareByOptions(this.categories),
                ]);
                if (this.props.getYieldByCompareByOption) {
                    this.props.needs([this.props.getYieldByCompareByOption()]);
                }
                this.yieldByCompareByOptions = this.categories;
                this.showModal = false;
                break;
            default:
                break;
        }
        this.setState({
            isLoading: false,
        });
    };

    onModalClose = () => {
        this.showModal = false;
    };

    onModalStatusChange = (categories) => {
        this.categories = categories;
    };

    onYieldByCompareByOptionsStatusChange = (yieldByCompareByOptions) => {
        this.yieldByCompareByOptions = yieldByCompareByOptions;
    };

    getTestPackageListData = (selectedTestingLab) => {
        this.props.needs([this.props.getTestPackageListData(selectedTestingLab)]);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.requestIds[this.categoriesSaveRequestId] === SUCCESS) {
            this.setState({ isLoading: false });
            this.categoriesSaveRequestId = null;
        } else if (nextProps.requestIds[this.categoriesSaveRequestId] === FAILURE) {
            this.categoriesSaveRequestId = null;
            this.setState({ isLoading: false });
        }
        if (
            !this.yieldCategories &&
            JSON.stringify(nextProps.yieldCategories) !== JSON.stringify(this.props.yieldCategories)
        ) {
            this.yieldCategories = nextProps.yieldCategories;
        }
        if (
            !this.yieldByCompareByOptions &&
            JSON.stringify(nextProps.yieldByCompareByOptions) !==
                JSON.stringify(this.props.yieldByCompareByOptions)
        ) {
            this.yieldByCompareByOptions = nextProps.yieldByCompareByOptions;
        }
        if (
            nextProps.reportPreferences &&
            nextProps.reportPreferences.labSubmissionTestingLabVendorGuid &&
            this.props.reportPreferences.labSubmissionTestingLabVendorGuid !==
                nextProps.reportPreferences.labSubmissionTestingLabVendorGuid
        ) {
            this.getTestPackageListData(
                nextProps.reportPreferences.labSubmissionTestingLabVendorGuid
            );
            this.props.onInputChange(
                model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID,
                this.props.reportPreferences[model.reportPreferenceKeys.labSubmissionSampleTypeGuid]
            );
            this.props.onInputChange(
                model.PROPS_TEST_PACKAGE_GUID,
                nextProps.reportPreferences.labSubmissionTestPackageGuid
            );
            this.props.onInputChange(
                model.PROPS_TESTING_LAB_VENDOR_GUID,
                nextProps.reportPreferences.labSubmissionTestingLabVendorGuid
            );
            this.props.onInputChange(
                model.PROPS_LAB_ACCOUNT,
                nextProps.reportPreferences.labAccount
            );
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="reports-container">
                <div className="reports-options-container">
                    <ReportTypes
                        selectReportType={this.props.selectReportType}
                        reportTypes={this.props.reportTypes}
                        selectedReportTypes={this.props.selectedReportTypes}
                        formatMessage={formatMessage}
                    />
                    <ReportOptionsContainer
                        agEventSummaryMap={this.props.agEventSummaryMap}
                        createReport={this.props.createReport}
                        crops={this.props.crops}
                        cropPurpose={this.props.cropPurpose}
                        defaultMultiFieldRec={this.props.defaultMultiFieldRec}
                        defaultFileNameFormat={this.props.defaultFileNameFormat}
                        fileNameFormats={this.props.fileNameFormats}
                        selectedFieldCount={this.props.selectedFieldCount}
                        selectedFields={this.props.selectedFields}
                        seasons={this.props.seasons}
                        formatMessage={formatMessage}
                        onInputChange={this.props.onInputChange}
                        recGeneralGuidToRecMap={this.props.recGeneralGuidToRecMap}
                        reportPreferences={this.props.reportPreferences}
                        reportTypePages={this.props.reportTypePages}
                        selectedReportTypes={this.props.selectedReportTypeNames}
                        selectedEventGuidSet={this.props.selectedEventGuidSet}
                        selectedRecGuidSet={this.props.selectedRecGuidSet}
                        onClickCategories={this.onClickCategories}
                        totalingUnit={this.props.totalingUnit}
                        testingLabVendors={this.props.testingLabVendors}
                        testPackages={this.props.testPackages}
                        getTestPackageListData={this.getTestPackageListData}
                    />
                </div>
                <ReportTable
                    createReportHubProgress={this.props.createReportHubProgress}
                    onAllReportCreated={this.props.onAllReportCreated}
                    url={ReportAPI.GET_FIELD_REPORT_LIST_URL}
                    autoSearchUrl={ReportAPI.GET_FIELD_REPORT_AUTOSEARCH_URL}
                    selectAllUrl={ReportAPI.GET_FIELD_SELECT_ALL_URL}
                    needs={this.props.needs}
                    initialRequestOptions={this.props.initialRequestOptions}
                />
                <DialogBox
                    unrestricted
                    title={this.modalTitle}
                    isOpen={this.showModal}
                    onAction={this.onModalSave}
                    onClose={this.onModalClose}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                >
                    {this.state.isLoading ? <Loader className="field-report-loader" /> : null}
                    <CategoryTree
                        onStatusChange={this.onModalStatusChange}
                        formatMessage={this.props.intl.formatMessage}
                        categories={this.categories}
                        categoryDataSet={this.dataSet}
                    />
                </DialogBox>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    agEventSummaryMap: eventsSelectors.getEventGeneralGuidToEventSummaryMap(state),
    testingLabVendors: vendorSelectors.getTestingLabVendors(state),
    testPackages: vendorSelectors.getTestPackages(state),
    yieldCategories: selectors.getYieldCategories(state),
    yieldByCompareByOptions: selectors.getYieldByCompareByOptions(state),
    recGeneralGuidToRecMap: recsSelectors.getRecGeneralGuidToRecSummaryMap(state),
});

const mapDispatchToProps = () => ({
    getTestPackageListData: (vendorGuid) => vendorActions.getTestPackageListData(vendorGuid),
    getTestingLabVendorListData: () => vendorActions.getTestingLabVendorListData(),
    getYieldByCompareByOption: () => actions.getYieldByCompareByOption(),
});

export const FieldReport = withReport(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(FieldReport_)),
    service
);
