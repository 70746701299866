import { models as recsEventsModels } from "~/recs-events";

import { RecEventZoneInfo } from "../../../../common/rec-event-info/rec-event-zone-info";
import { SamplingZoneInfo } from "./event-sample-common/components/sampling-zone-info";

import { FilterForm } from "./event-filter-form";
import * as ecDataEventForm from "./event-ec-data/event-ec-data-form";
import * as harvestModule from "./event-harvest/harvest-event-container";
import * as harvestEventForm from "./event-harvest/harvest-event-form";
import * as applicationModule from "./event-application";
import * as sampleSoilModule from "./event-sample-soil/components/event-sample-soil-form";
import * as sampleTissueModule from "./event-sample-tissue/components/event-sample-tissue-form";
import * as scoutingModule from "./event-scouting/event-scouting-form";
import * as tillageModule from "./event-tillage/event-tillage-form";
import { plantingModule } from "./event-planting";
import * as irrigationModule from "./event-irrigation/event-irrigation-container";
import * as irrigationForm from "./event-irrigation/event-irrigation-form";

export const getAgEventTransactionTypeNameToFormMap = () =>
    new Map([
        [
            recsEventsModels.EVENT_TYPE_NAME_APPLICATION,
            {
                EditForm: applicationModule.EventApplicationForm,
                errorCodesApply: () => false,
                labelMessage: applicationModule.formLabelMessage,
                LabelIcon: applicationModule.formLabelIcon,
                ZoneInfo: RecEventZoneInfo,
                SummarySection: applicationModule.EventApplicationSummary,
                FieldDataForm: applicationModule.EventApplicationFieldData,
                FilterForm: FilterForm,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_EC_DATA,
            {
                EditForm: ecDataEventForm.ECDataEventForm,
                errorCodesApply: () => false,
                labelMessage: ecDataEventForm.formLabelMessage,
                LabelIcon: ecDataEventForm.formLabelIcon,
                ZoneInfo: RecEventZoneInfo,
                FilterForm: FilterForm,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_HARVEST,
            {
                EditForm: harvestModule.HarvestEventForm,
                errorCodesApply: harvestEventForm.errorCodesApply,
                labelMessage: harvestModule.formLabelMessage,
                LabelIcon: harvestModule.formLabelIcon,
                ZoneInfo: RecEventZoneInfo,
                FilterForm: FilterForm,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_IRRIGATION,
            {
                EditForm: irrigationModule.EventIrrigationForm,
                errorCodesApply: irrigationForm.errorCodesApply,
                labelMessage: irrigationModule.formLabelMessage,
                LabelIcon: irrigationModule.formLabelIcon,
                ZoneInfo: RecEventZoneInfo,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_PLANTING,
            {
                EditForm: plantingModule.EventPlantingForm,
                errorCodesApply: plantingModule.errorCodesApply,
                labelMessage: plantingModule.formLabelMessage,
                LabelIcon: plantingModule.formLabelIcon,
                ZoneInfo: RecEventZoneInfo,
                SummarySection: plantingModule.EventPlantingSummary,
                FilterForm: FilterForm,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_SAMPLING_SOIL,
            {
                EditForm: sampleSoilModule.SampleSoilForm,
                errorCodesApply: sampleSoilModule.errorCodesApply,
                labelMessage: sampleSoilModule.formLabelMessage,
                LabelIcon: sampleSoilModule.formLabelIcon,
                ZoneInfo: SamplingZoneInfo,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_SAMPLING_TISSUE,
            {
                EditForm: sampleTissueModule.SampleTissueForm,
                errorCodesApply: sampleTissueModule.errorCodesApply,
                labelMessage: sampleTissueModule.formLabelMessage,
                LabelIcon: sampleTissueModule.formLabelIcon,
                ZoneInfo: SamplingZoneInfo,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_TILLAGE,
            {
                EditForm: tillageModule.EventTillageForm,
                errorCodesApply: tillageModule.errorCodesApply,
                labelMessage: tillageModule.formLabelMessage,
                LabelIcon: tillageModule.formLabelIcon,
                ZoneInfo: RecEventZoneInfo,
            },
        ],
        [
            recsEventsModels.EVENT_TYPE_NAME_SCOUTING,
            {
                EditForm: scoutingModule.ScoutingForm,
                errorCodesApply: scoutingModule.errorCodesApply,
                labelMessage: scoutingModule.formLabelMessage,
                LabelIcon: scoutingModule.formLabelIcon,
                ZoneInfo: RecEventZoneInfo,
            },
        ],
    ]);
