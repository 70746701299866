export class RequireRefreshError extends Error {
    constructor(message, i18nMessage, i18nMsgValues) {
        super(message);
        this.name = this.constructor.name;
        this.i18nMessage = i18nMessage;
        this.i18nMsgValues = i18nMsgValues;
        if (typeof Error.captureStackTrace === "function") {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
    }
}
