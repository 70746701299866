import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { messages } from "~/admin/agBytes/i18n-messages";
import { TextInput, Section, SubSection } from "~/core";
import { model } from "./../../data";

export default class ImportAttribute extends Component {
    static propTypes = {
        formatMessage: PropTypes.func.isRequired,
        onTextChange: PropTypes.func,
        updateTestButtonStatus: PropTypes.func,
        importAttributes: PropTypes.array,
        equationText: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            importAttributesState: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.importAttributes &&
            nextProps.importAttributes !== this.props.importAttributes
        ) {
            this.getImportAttributeList(nextProps);
        }

        if (nextProps.equationText !== this.props.equationText) {
            this.getImportAttributeList(nextProps);
        }
    }

    parseImportAttribute(parameter) {
        parameter = parameter.replace("]", "");
        return parameter.replace("[", "");
    }

    _getIsRequiredFlag(labelText) {
        return labelText.indexOf("~") === -1;
    }

    _getParsedImportAttributeText(labelText) {
        return labelText.replace("~", "");
    }

    getImportAttributeList = (nextProps) => {
        const { importAttributes, equationText } = nextProps;
        const importAttributesFromText = equationText.match(/\[(.*?)\]/g);
        if (importAttributes && importAttributesFromText) {
            const importAttributesFromTextSet = new Set(
                importAttributesFromText.filter((attr) => attr && attr !== "[]")
            );
            const updatedImportAttributes = Array.from(importAttributesFromTextSet).map((attr) => {
                const parsedAttr = this.parseImportAttribute(attr);
                const existingAttribute =
                    importAttributes.find(
                        (ia) =>
                            (ia.attributeName || ia.importAttribute).toLowerCase() ===
                            parsedAttr.toLowerCase()
                    ) || {};
                return {
                    ...existingAttribute,
                    attributeName: parsedAttr,
                    importAttribute: parsedAttr,
                };
            });
            this.setState(
                {
                    importAttributesState: updatedImportAttributes,
                },
                () => {
                    this.props.onTextChange(
                        model.PROPS_IMPORT_ATTRIBUTE_LIST,
                        updatedImportAttributes
                    );
                }
            );
        } else {
            this.setState(
                {
                    importAttributesState: [],
                },
                () => {
                    this.props.onTextChange(model.PROPS_IMPORT_ATTRIBUTE_LIST, []);
                }
            );
        }
    };

    importAttributeChange = ({ key = "", value = "" }) => {
        if (key) {
            const { importAttributes } = this.props;
            if (importAttributes && Array.isArray(importAttributes)) {
                const index = _.findIndex(
                    importAttributes,
                    ({ attributeName, importAttribute }) => {
                        if (attributeName) {
                            return attributeName.toUpperCase() === key.toUpperCase();
                        } else {
                            return importAttribute.toUpperCase() === key.toUpperCase();
                        }
                    }
                );
                if (index > -1) {
                    importAttributes[index].value = value;
                    importAttributes[index].importAttribute = key;
                } else {
                    importAttributes.push({
                        importAttribute: key,
                        value,
                    });
                }
            }
            this.props.onTextChange(model.PROPS_IMPORT_ATTRIBUTE_LIST, importAttributes);
        }
        this.props.updateTestButtonStatus();
    };

    render() {
        const { formatMessage } = this.props;
        const { importAttributesState } = this.state;
        return (
            <Section headerText={formatMessage(messages.importAttribute)}>
                {!(importAttributesState && importAttributesState.length > 0)
                    ? null
                    : importAttributesState.map(({ importAttribute, attributeName, value }) => (
                          <SubSection
                              key={importAttribute || attributeName}
                              className="parameter-section"
                          >
                              <span className="parameter-section-label">
                                  {this._getParsedImportAttributeText(
                                      importAttribute || attributeName
                                  )}
                              </span>
                              <TextInput
                                  tabIndex={0}
                                  scale={2}
                                  placeholderText={formatMessage(messages.testValue)}
                                  labelText={formatMessage(messages.testValue)}
                                  value={value}
                                  onChange={(value) =>
                                      this.importAttributeChange({
                                          key: importAttribute || attributeName,
                                          value,
                                      })
                                  }
                                  required={this._getIsRequiredFlag(
                                      importAttribute || attributeName
                                  )}
                              />
                          </SubSection>
                      ))}
            </Section>
        );
    }
}
