import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, defineMessages } from "react-intl";
import { messages as moduleMessages } from "../i18n-messages";
import classnames from "classnames";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { CloseIcon } from "~/core/icons";

const messages = defineMessages({
    deleteEventConfirmation: {
        id: "eventModule.eventInfo.deleteEventConfirmation",
        defaultMessage: "Are you sure you want to delete?",
    },
});

export class FormLabel_ extends PureComponent {
    static propTypes = {
        canRemove: PropTypes.bool,
        hasError: PropTypes.bool.isRequired,
        intl: intlShape,
        labelIconEl: PropTypes.element.isRequired,
        labelMessage: PropTypes.object.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    static defaultProps = {
        canRemove: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            showDeleteConf: false,
        };
    }

    _onRemoveIconClick(evt) {
        evt.preventDefault();
        this.setState({
            showDeleteConf: true,
        });
    }

    render() {
        const { canRemove, hasError, labelIconEl, labelMessage } = this.props;
        const { formatMessage } = this.props.intl;

        const removeIcon = !canRemove ? null : (
            <div
                className="remove-btn"
                title={formatMessage(moduleMessages.delete)}
                onClick={(evt) => this._onRemoveIconClick(evt)}
            >
                <CloseIcon />
            </div>
        );
        return (
            <div
                className={classnames("rec-event-info-tab-label", {
                    "has-error": hasError,
                })}
            >
                {removeIcon}
                {labelIconEl}
                <div className="tab-nav-text">{formatMessage(labelMessage)}</div>
                <DialogBox
                    title={""}
                    footerType={DialogBoxFooterType.YES_NO}
                    isOpen={this.state.showDeleteConf}
                    onAction={() => this.props.onRemove()}
                    onClose={() => this.setState({ showDeleteConf: false })}
                >
                    {formatMessage(messages.deleteEventConfirmation)}
                </DialogBox>
            </div>
        );
    }
}

export const FormLabel = injectIntl(FormLabel_);
