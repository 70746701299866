import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { service, actions, model } from "./data";
import { withCrud, withNestedGrid } from "~/hocs";
import { NestedDataTable, DataTable } from "~/core";
import { messages } from "../i18n-messages";
import { OrgNestedGrids } from "./org-level";
import { preventBubbleUp } from "~/admin/utils";
import { Button } from "~/core";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { SUCCESS, FAILURE, PENDING } from "~/hocs/needs/utils";
import { keywords } from "./keywords";
import { injectIntl, intlShape } from "react-intl";
import { getApiErrorCodeList } from "~/core/api/selectors";

import AddEditPanel from "./add-edit/add-edit-container";

class OrgLevelGrid_ extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        addEditPanel: PropTypes.object,
        addOrgLevel: PropTypes.func,
        children: PropTypes.object,
        deleteSelected: PropTypes.func,
        fetchRecords: PropTypes.func,
        hierarchyFilterList: PropTypes.array,
        intl: intlShape.isRequired,
        isEditable: PropTypes.bool,
        isExpanded: PropTypes.bool,
        maxOrgLevelType: PropTypes.number,
        needs: PropTypes.func,
        onRowSelection: PropTypes.func,
        onRowUnselect: PropTypes.func,
        orgLevelData: PropTypes.object,
        parentGuid: PropTypes.string,
        parentLevelGuid: PropTypes.array,
        renderNestedGrids: PropTypes.func,
        requestIds: PropTypes.object,
        selectedRow: PropTypes.object,
        setBreadcrumbs: PropTypes.func,
        status: PropTypes.string,
        toggleActiveInactive: PropTypes.func,
        showInActive: PropTypes.bool,
        updateOrgLevel: PropTypes.func,
    };

    static defaultProps = {
        isExpanded: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
            orgTypeName: null,
            mode: "",
            showAddEditPanel: false,
            fetchData: false,
            recordGuid: "",
            selectedRow: props.selectedRow || null,
        };
        this.service = {
            ...service,
            getLabelValue: this._printOrgTypeLabel,
        };
    }

    _printOrgTypeLabel = (attr, defaultMessages, formatMessage) => {
        if (
            attr === model.PROPS_ORG_LEVEL_NAME &&
            this.props.orgLevelData[this.props.parentGuid] &&
            this.props.hierarchyFilterList
        ) {
            const { gridRows } = this.props.orgLevelData[this.props.parentGuid];
            if (gridRows.length > 0) {
                const { orgTypeLevel } = gridRows[0];
                if (orgTypeLevel) {
                    const itemData = this.props.hierarchyFilterList.find(
                        (data) => data.orgTypeLevel === orgTypeLevel
                    );
                    if (itemData) {
                        return itemData.orgTypeName;
                    }
                }
            }
        }
        return formatMessage(messages[service._defaultLabels[attr].label]);
    };

    _openAddEditPanel = (modelName, mode, recordGuid = "") => {
        this.setState({ recordGuid });
        this._toggleAddEdit(mode, true);
    };

    _setLastEditedRow = (recordGuid = null) => {
        const records = this.props.orgLevelData[this.props.parentGuid].gridRows;
        const lastSelectedRow = records
            ? records.find((record) => service.getDefaultGuid(record) === recordGuid)
            : null;
        this.setState(
            {
                lastSelectedRow,
            },
            () => {
                this.props.onRowSelection(null, lastSelectedRow);
            }
        );
    };

    _toggleAddEdit = (mode, showAddEditPanel) => {
        // On close of the add edit panel initialize lastEditedRow and update parent's selected row
        if (!showAddEditPanel && this.props.orgLevelData[this.props.parentGuid]) {
            this._setLastEditedRow(this.state.recordGuid);
        }
        this.setState({ mode, showAddEditPanel });
        this.props.setBreadcrumbs();
    };

    _toggleFetchData = (fetchData) => this.setState({ fetchData });

    _getParentRecord = (orgData, parentGuid) => {
        let parentObj = null;
        Object.entries(orgData).forEach((data) => {
            if (data[1].gridRows) {
                const selectedRow = data[1].gridRows.find((row) => {
                    return row.orgLevelGuid === parentGuid;
                });
                if (selectedRow) {
                    parentObj = selectedRow;
                }
            }
        });
        return parentObj;
    };

    _renderDataTable = (
        records = [],
        totalCount = 0,
        isEditable = true,
        showHideAddButton = true,
        selectedRow = null
    ) => {
        const classNames = classnames("org-level-table", "child-component", {
            "non-editable-grid": !showHideAddButton,
        });
        return (
            <div className={classNames}>
                <DataTable
                    isCheckbox={false}
                    isEditable={isEditable}
                    messages={messages}
                    service={this.service}
                    parentGuid={this.props.parentGuid}
                    {...this.props}
                    openAddEditPanel={this._openAddEditPanel}
                    records={records}
                    totalCount={totalCount}
                    onRowSelection={this.props.onRowSelection}
                    onRowUnselect={this.props.onRowUnselect}
                    deleteSelected={this.deleteSelected}
                    isExpanded={this.props.isExpanded}
                    showHideAddButton={showHideAddButton}
                    selectedRow={selectedRow}
                    lastSelectedRow={this.state.lastSelectedRow}
                />
            </div>
        );
    };

    _renderNestedDataTable = (
        records = [],
        totalCount = 0,
        isEditable = true,
        showHideAddButton = true,
        selectedRow = null,
        showInActive
    ) => {
        const classNames = classnames("org-level-table", "child-component", {
            "non-editable-grid": !showHideAddButton || !isEditable,
        });
        return (
            <div className={classNames}>
                <NestedDataTable
                    {...this.props}
                    isCheckbox={false}
                    isEditable={isEditable}
                    messages={messages}
                    service={this.service}
                    parentGuid={this.props.parentGuid}
                    openAddEditPanel={this._openAddEditPanel}
                    records={records}
                    totalCount={totalCount}
                    onRowSelection={this.props.onRowSelection}
                    onRowUnselect={this.props.onRowUnselect}
                    deleteSelected={this.deleteSelected}
                    isExpandedRow={this.props.isExpanded}
                    showHideAddButton={showHideAddButton}
                    selectedRow={selectedRow}
                    lastSelectedRow={this.state.lastSelectedRow}
                >
                    <OrgNestedGrids
                        {...this.props}
                        orgLevelData={this.props.orgLevelData}
                        hierarchyFilterList={this.props.hierarchyFilterList}
                        onRowSelection={this.props.onRowSelection}
                        onRowUnselect={this.props.onRowUnselect}
                        parentLevelGuid={this.props.parentLevelGuid}
                        selectedRow={selectedRow}
                        showInActive={showInActive}
                    />
                </NestedDataTable>
            </div>
        );
    };

    liftRecordData = (data = {}) => {
        this._toggleFetchData(false);
        if (data && this.props.status !== PENDING) {
            if (this.state.mode === keywords.ADD) {
                this.addEditRequestId = this.props.needs([this.props.addOrgLevel(data)]);
            } else {
                this.addEditRequestId = this.props.needs([this.props.updateOrgLevel(data)]);
            }
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.requestIds && this.addEditRequestId) {
            if (nextProps.requestIds[this.addEditRequestId] === SUCCESS) {
                this.setState({
                    apiErrors: [],
                });
                this._toggleFetchData(false);
                this._toggleAddEdit("", false);
                this.props.fetchRecords(service.defaultRequestFilters);
                this.addEditRequestId = null;
            } else if (nextProps.requestIds[this.addEditRequestId] === FAILURE) {
                if (nextProps.apiErrors.length) {
                    this.setState({
                        apiErrors: nextProps.apiErrors,
                    });
                }
                this._toggleFetchData(false);
                this.addEditRequestId = null;
            }
        }
        if (this.state.selectedRow !== nextProps.selectedRow) {
            this.setState({
                selectedRow: nextProps.selectedRow,
            });
        }
        if (this.state.selectedRow !== this.state.lastSelectedRow) {
            this.setState({
                lastSelectedRow: null,
            });
        }

        if (nextProps.showInActive !== this.props.showInActive) {
            this.props.toggleActiveInactive();
        }
    }

    deleteSelected = (options) => {
        let { selectedItems } = options;
        selectedItems = selectedItems[0];
        options = { ...options, selectedItems };
        this.props.deleteSelected(options);
    };

    onCancel = () => {
        this.setState({
            apiErrors: [],
        });
        this._toggleAddEdit("", false);
    };

    render() {
        const { showAddEditPanel, mode, fetchData, recordGuid, selectedRow, apiErrors } =
            this.state;
        const { showInActive } = this.props;
        const { liftRecordData } = this;
        let parentObj = null;
        let slidingPanelProps = { ...this.props, apiErrors };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                fetchData,
                liftRecordData,
                recordGuid,
                mode,
            };
        }
        let records = [],
            totalCount = null,
            isEditable = true,
            showHideAddButton = false,
            gridComponent = null;
        if (this.props.orgLevelData && this.props.parentGuid) {
            records =
                this.props.orgLevelData[this.props.parentGuid] &&
                this.props.orgLevelData[this.props.parentGuid].gridRows;
            totalCount =
                this.props.orgLevelData[this.props.parentGuid] &&
                this.props.orgLevelData[this.props.parentGuid].totalCount;
            parentObj = this._getParentRecord(this.props.orgLevelData, this.props.parentGuid);
        }
        if (records && records.length !== 0) {
            isEditable = records[0].canModify;
            showHideAddButton = parentObj ? parentObj.canAddChildren : true;
            // OrgLevelType is the greater one and it's depth is 0 (i.e no child affiliations)
            // the grid should be displayed as a regular table instead of nested one
            if (records[0].orgTypeLevel >= this.props.maxOrgLevelType) {
                gridComponent = this._renderDataTable(
                    records,
                    totalCount,
                    isEditable,
                    showHideAddButton,
                    selectedRow
                );
            } else {
                gridComponent = this._renderNestedDataTable(
                    records,
                    totalCount,
                    isEditable,
                    showHideAddButton,
                    selectedRow,
                    showInActive
                );
            }
        } else {
            gridComponent = this._renderNestedDataTable(records, totalCount);
        }
        return (
            <div className="org-level-table child-component">
                {gridComponent}
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={() => this._toggleAddEdit("", false)}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "117",
                            }}
                        >
                            <Button
                                type="save"
                                forceSubmit
                                onClick={() => this._toggleFetchData(true)}
                            />
                            <Button
                                type="cancel"
                                className="org-level-cancel"
                                onClick={this.onCancel}
                            />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    apiErrors: getApiErrorCodeList(state),
});

const mapDispatchToProps = () => ({
    addOrgLevel: (payload) => actions.addOrgLevel(payload),
    updateOrgLevel: (payload) => actions.updateOrgLevel(payload),
});

export const OrgLevelGrid = injectIntl(
    withNestedGrid(
        withCrud(connect(mapStateToProps, mapDispatchToProps)(OrgLevelGrid_), service, actions)
    )
);
