import fileSaver from "file-saver";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { defineMessages } from "react-intl";
import { v4 as uuid } from "uuid";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { fetchPicklistData } from "~/core/picklist/actions";
import {
    PICKLIST_CROP_PURPOSE,
    PICKLIST_IRRIGATION_TYPE,
    PICKLIST_INSURANCE_POLICY_TYPE,
    PICKLIST_LAND_OWNERSHIP_TYPE,
    PICKLIST_PRODUCT_PARENT_TYPE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { fetchUnitData } from "~/core/units/actions";
import { UNIT_YIELD, getUnitCode } from "~/core/units/unit-names";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { actions as messagingActions } from "~/messaging";
import {
    actions as cdActions,
    models as cdModels,
    selectors as cdSelectors,
} from "~/customer-data";
import * as loginActions from "~/login/actions";
import { getTheUserGuid, getTheUserPersonalityId } from "~/login/selectors";
import * as mapActions from "~/map/components/map-control/actions"; // can't use root mapActions ref or we'll cause reference loop
import {
    apiUrl,
    CustomerAPI,
    EventAPI,
    FieldAPI,
    FileImportAPI,
    LayerAPI,
    AgvanceAPI,
    pagination,
    SearchAPI,
    GeometryUtils,
} from "@ai360/core";

import * as panelActions from "../../actions";
import * as analysisActions from "../layer-module/components/analysis-info/actions";
import { fieldListSaga } from "./components/field-list/sagas";
import { getBatchFieldDetails } from "./components/field-list/selectors";

import * as actions from "./actions";
import * as listActions from "./components/field-list/actions";
import * as layerActions from "~/action-panel/components/layer-module/components/layer-list/actions";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";
import {
    autoExpandedCustomerModifications,
    filteredCustomerFieldRequest,
} from "~/utils/api/search";
import { logFirebaseEvent } from "~/utils/firebase";
import {
    getFieldBoundaryTransferFieldModel,
    getFieldBoundaryTransferGeometries,
} from "./selectors";
import { FieldInfo } from "~/customer-data/models";

const messages = defineMessages({
    exportBoundaryComplete: {
        id: "fieldModule.exportBoundaryComplete",
        defaultMessage: "Export Complete",
    },
    exportBoundaryFailed: {
        id: "fieldModule.exportBoundaryFailed",
        defaultMessage: "Export Failed",
    },
    exportingBoundaries: {
        id: "fieldModule.exportingBoundaries",
        defaultMessage: "Exporting Boundaries",
    },
    exportingFieldDetails: {
        id: "fieldModule.exportingFieldDetails",
        defaultMessage: "Exporting Field Details",
    },
});

export const YesNoOptions = [
    { value: false, label: "No" },
    { value: true, label: "Yes" },
];

export const IrrigatedListOptions = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
    { value: 2, label: "Mix" },
];
export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
    [PICKLIST_IRRIGATION_TYPE]: getPickListCode(PICKLIST_IRRIGATION_TYPE),
    [PICKLIST_INSURANCE_POLICY_TYPE]: getPickListCode(PICKLIST_INSURANCE_POLICY_TYPE),
    [PICKLIST_LAND_OWNERSHIP_TYPE]: getPickListCode(PICKLIST_LAND_OWNERSHIP_TYPE),
    [PICKLIST_PRODUCT_PARENT_TYPE]: getPickListCode(PICKLIST_PRODUCT_PARENT_TYPE),
};
export const units = {
    [UNIT_YIELD]: getUnitCode(UNIT_YIELD),
};

export const DROPDOWN_CROP = "DROPDOWN_CROP";
export const DROPDOWN_BRANDORGANIZATION = "DROPDOWN_BRANDORGANIZATION";
export const DROPDOWN_VARIETYHYBRID = "DROPDOWN_VARIETYHYBRID";

export const dropdowns = {
    [DROPDOWN_CROP]: apiUrl("AgBytes/GetCropDropdownList"),
};

export const init = function* () {
    yield put(loginActions.fetchLayerTypesAccess());
};

const onAddAnalysisLayer = function* (action) {
    const { fieldGuids, analysisLayerType } = action.payload;
    yield put(cdActions.addSelectedFields(fieldGuids));
    yield put(panelActions.setActiveModule(panelActions.ActionPanelModuleList.LAYER));
    yield put(analysisActions.addAnalysisLayer(fieldGuids, analysisLayerType));
};

export const onActivateCustomer = function* (activateAction) {
    yield put(actions.setIsFieldLoading(true));
    const { customerGuid } = activateAction.payload;
    let result: any = {};
    try {
        const userGuid = yield select(getTheUserGuid);
        // activate field in backend
        result = yield call(CustomerAPI.activateCustomer, userGuid, customerGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, activateAction));
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
    // activate field in frontend
    yield put(cdActions.activateCustomer(customerGuid, result.activatedFieldGuids));
    yield put(mapActions.setForceRefreshFlag(true));
};

export const onActivateField = function* (activateAction) {
    yield put(actions.setIsFieldLoading(true));
    const { fieldGuid } = activateAction.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        // activate field in backend
        yield call(FieldAPI.activateField, userGuid, fieldGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, activateAction));
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
    // activate field in frontend
    yield put(cdActions.activateFields([fieldGuid]));
    yield put(mapActions.setForceRefreshFlag(true));
};

export const onSaveBatchDetails = function* (action) {
    yield put(actions.setIsFieldLoading(true));
    const userGuid = yield select(getTheUserGuid);
    const batchFieldDetails: Partial<FieldAPI.IField> = yield select(getBatchFieldDetails);
    batchFieldDetails.classifications = batchFieldDetails.classifications?.map(
        (c) => c.classificationKey
    );
    const selectedFieldGuids: string[] = yield select(cdSelectors.getSelectedFieldGuids);
    const fieldDetails: Partial<FieldAPI.IField>[] = [];
    for (const fieldGuid of selectedFieldGuids) {
        const fieldDetail: Partial<FieldAPI.IField> = {
            fieldGuid,
            ...batchFieldDetails,
        };
        fieldDetails.push(fieldDetail);
    }
    try {
        const saveResponse = yield call(FieldAPI.updateFieldDetails, fieldDetails, userGuid);
        yield put(actions.saveFieldInformationSucceeded(saveResponse));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
};

export const onCombineFields = function* (action) {
    yield put(actions.setIsFieldLoading(true));
    const userGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(FieldAPI.combineFields, userGuid, action.payload);
        yield put(actions.clearFullFieldRecords());
        yield put(actions.setCombineFieldsError(null));
        yield put(actions.setCombineFieldsModalOpen(false));
        yield put(actions.saveFieldInformationSucceeded(response));
        yield put(cdActions.deactivateFields(action.payload.fieldGuidList));
    } catch (err) {
        yield put(actions.setCombineFieldsError(err));
        yield put(mapActions.setForceRefreshFlag(true));
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
    yield put(mapActions.setForceRefreshFlag(true));
};

export const onDeleteCustomer = function* (deleteAction) {
    yield put(actions.setIsFieldLoading(true));
    const { customerGuid } = deleteAction.payload;
    const userGuid = yield select(getTheUserGuid);
    let result: any = {};
    try {
        result = yield call(CustomerAPI.deactivateCustomer, userGuid, customerGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, deleteAction));
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
    // deactivate customer in frontend
    yield put(cdActions.deleteCustomer(customerGuid, result.deactivatedFieldGuids));
    yield put(mapActions.setForceRefreshFlag(true));
};

export const onDeleteField = function* (deleteAction) {
    yield put(actions.setIsFieldLoading(true));
    const { fieldGuid, ignoreAgvance } = deleteAction.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        // deactivate field in backend
        if (ignoreAgvance) {
            yield call(FieldAPI.deactivateFieldsIgnoreAgvance, userGuid, [fieldGuid]);
        } else {
            yield call(FieldAPI.deactivateField, userGuid, fieldGuid);
        }
    } catch (err) {
        if (
            err.errorCodeList?.length === 1 &&
            err.errorCodeList[0] === 960 &&
            err.model["960"]?.length &&
            err.model["960"][0].indexOf(
                "ERROR: Field was not found in Agvance with the AgvanceGuid of "
            ) !== -1
        ) {
            // error deleting the field in Agvance, set a flag to trigger the override modal
            yield put(actions.setFieldDeleteAgvanceOverrideModalOpen([fieldGuid]));
        } else {
            yield put(notificationActions.apiCallError(err, deleteAction));
        }
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
    // deactivate field in frontend
    yield put(cdActions.deactivateFields([fieldGuid]));
    yield put(mapActions.setForceRefreshFlag(true));
};

const onDeleteSelectedFields = function* (action) {
    logFirebaseEvent("delete_selected_fields");
    const { ignoreAgvance } = action.payload;
    yield put(actions.setIsFieldLoading(true));
    let selectedFieldGuids;
    try {
        selectedFieldGuids = yield select(cdSelectors.getSelectedFieldGuids);
        const userGuid = yield select(getTheUserGuid);
        // deactivate field in backend
        if (ignoreAgvance) {
            yield call(FieldAPI.deactivateFieldsIgnoreAgvance, userGuid, [...selectedFieldGuids]);
        } else {
            yield call(FieldAPI.deactivateFields, userGuid, [...selectedFieldGuids]);
        }
    } catch (err) {
        console.warn(err.errorCodeList, err.model);
        if (
            err.errorCodeList?.length > 0 &&
            err.errorCodeList[0] === 960 &&
            err.model["960"]?.length &&
            err.model["960"][0].indexOf(
                "ERROR: Field was not found in Agvance with the AgvanceGuid of "
            ) !== -1
        ) {
            // error deleting the fields in Agvance, set a flag to trigger the override modal
            yield put(
                actions.setFieldDeleteAgvanceOverrideModalOpen(Array.from(selectedFieldGuids))
            );
        } else {
            yield put(notificationActions.apiCallError(err, action));
        }
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
    // deactivate field in frontend
    yield put(cdActions.deactivateFields(selectedFieldGuids));
    yield put(mapActions.setForceRefreshFlag(true));
};

export const onExportBoundary = function* (action) {
    try {
        logFirebaseEvent("export_boundaries");
        let fieldGuids;
        const userGuid = yield select(getTheUserGuid);
        if (action.payload && action.payload.fieldGuid) {
            fieldGuids = [action.payload.fieldGuid];
        } else {
            fieldGuids = [...(yield select(cdSelectors.getSelectedFieldGuids))];
        }
        yield put(notificationActions.pushToasterMessage(messages.exportingBoundaries));
        const response = yield call(FileImportAPI.exportFieldBoundary, userGuid, fieldGuids);
        if (response && !response.success) {
            yield put(
                notificationActions.pushToasterMessage(messages.exportBoundaryFailed, MSGTYPE.ERROR)
            );
        }
    } catch (err) {
        yield put(
            notificationActions.pushToasterMessage(messages.exportBoundaryFailed, MSGTYPE.ERROR)
        );
    }
};

export const onExportDetails = function* (action) {
    yield put(actions.setIsFieldLoading(true));
    logFirebaseEvent("export_field_details");
    let fieldGuids;
    const userGuid = yield select(getTheUserGuid);
    if (action.payload && action.payload.fieldGuid) {
        fieldGuids = [action.payload.fieldGuid];
    } else {
        fieldGuids = [...(yield select(cdSelectors.getSelectedFieldGuids))];
    }
    try {
        yield put(notificationActions.pushToasterMessage(messages.exportingFieldDetails));
        const response = yield call(FieldAPI.exportFieldDetails, userGuid, fieldGuids);
        if (response.url) {
            const resp = yield call(fetch, response.url);
            if (resp.status === 200) {
                resp.blob().then((blob) =>
                    fileSaver.saveAs(
                        blob,
                        response.key.substring(response.key.lastIndexOf("/") + 1)
                    )
                );
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.exportBoundaryComplete,
                        MSGTYPE.INFO
                    )
                );
            } else {
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.exportToFileFailed,
                        MSGTYPE.ERROR
                    )
                );
            }
        } else {
            yield put(
                notificationActions.pushToasterMessage(messages.exportToFileFailed, MSGTYPE.ERROR)
            );
        }
    } catch (err) {
        yield put(
            notificationActions.pushToasterMessage(messages.exportToFileFailed, MSGTYPE.ERROR)
        );
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
};

const messageSubscriptions = function* () {
    const handleExportBoundary = function* (message) {
        if (message.exportFileUrl && message.exportFileName) {
            const resp = yield call(fetch, message.exportFileUrl);
            if (resp.status === 200) {
                // Response is OK
                resp.blob().then((blob) => fileSaver.saveAs(blob, message.exportFileName));
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.exportBoundaryComplete,
                        MSGTYPE.INFO
                    )
                );
            } else {
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.exportBoundaryFailed,
                        MSGTYPE.ERROR
                    )
                );
            }
        } else {
            yield put(
                notificationActions.pushToasterMessage(messages.exportBoundaryFailed, MSGTYPE.ERROR)
            );
        }
    };

    yield put(
        messagingActions.subscribe(0, {
            eventName: "exportBoundary",
            generator: handleExportBoundary,
        })
    );
};

export const onClearField = function* () {
    yield put(notificationActions.clearToasterMessages());
};

export const onFetchAgvanceFieldList = function* (action) {
    const { payload } = action;
    if (payload) {
        try {
            const UserGuid = yield select(getTheUserGuid);
            const agvanceFieldList = yield AgvanceAPI.getFieldList(payload, UserGuid);
            yield put(actions.fetchAgvanceFieldListSucceeded(agvanceFieldList));
        } catch (err) {
            yield put(notificationActions.apiCallError(err, action));
        }
    }
};

export const onFetchAgvanceFieldClassificationList = function* (action) {
    const { payload } = action;
    if (payload) {
        try {
            yield put(actions.setIsFieldLoading(true));

            yield AgvanceAPI.refreshClassifications({
                orgLevelGuid: payload,
            });

            const agvanceFieldClassificationList = yield AgvanceAPI.getClassifications({
                orgLevelGuid: payload,
            });
            yield put(
                actions.fetchAgvanceFieldClassificationListSucceeded(agvanceFieldClassificationList)
            );
        } catch (err) {
            yield put(notificationActions.apiCallError(err, action));
        } finally {
            yield put(actions.setIsFieldLoading(false));
        }
    }
};

export const onFetchAgvanceOrgLevelList = function* (action) {
    const { payload } = action;
    if (payload) {
        try {
            yield put(actions.setIsFieldLoading(true));
            const UserGuid = yield select(getTheUserGuid);
            const additionalAgvanceCustomer = yield FieldAPI.getAgvanceCustomerList(
                UserGuid,
                payload
            );
            yield put(actions.fetchAgvanceOrgLevelListSucceeded(additionalAgvanceCustomer));
        } catch (err) {
            yield put(notificationActions.apiCallError(err, action));
        } finally {
            yield put(actions.setIsFieldLoading(false));
        }
    }
};

export const onFetchFullFieldRecords = function* (action) {
    const { payload } = action;
    if (payload) {
        try {
            const UserGuid = yield select(getTheUserGuid);
            const fullFieldRecords = yield FieldAPI.getFullFieldRecords(UserGuid, payload);
            yield put(actions.fetchFullFieldRecordSuccess(fullFieldRecords));
        } catch (err) {
            yield put(notificationActions.apiCallError(err, action));
        }
    }
};

export const onFetchLayerTypesAccess = function* () {
    const userGuid = yield select(getTheUserGuid);
    try {
        const layersTypeAccess = yield call(LayerAPI.getUserAnalysisLayerTypeAccess, userGuid);
        yield put(loginActions.setLayerTypesAccess(layersTypeAccess));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
};

export const onInitFetchFieldInformation = function* (fetchAction) {
    yield put(actions.setIsFieldLoading(true));
    const { activeCustomerGuid, activeFieldGuid } = fetchAction.payload;
    try {
        yield put(fetchPicklistData(pickLists));
        yield put(fetchUnitData(units));

        let fieldModel: Partial<FieldAPI.IField> = {};

        if (activeFieldGuid && activeFieldGuid !== "") {
            const userGuid = yield select(getTheUserGuid);
            const personalityId = yield select(getTheUserPersonalityId);

            const isAgvanceConnected = AgvanceUtils.isAgvanceConnected(personalityId);
            fieldModel = yield call(FieldAPI.getField, activeFieldGuid);
            if (isAgvanceConnected) {
                const agvanceFieldData = yield call(
                    FieldAPI.getAgvanceFieldData,
                    userGuid,
                    fieldModel.agvanceFieldGuid
                );
                yield put(actions.setAgvanceFieldData(agvanceFieldData));

                const agvanceFieldClassificationList = yield call(AgvanceAPI.getClassifications, {
                    orgLevelGuid: fieldModel.agvanceFieldOrgLevelGuid,
                    agvanceFieldGuid: fieldModel.agvanceFieldGuid,
                });

                fieldModel = {
                    ...fieldModel,
                    classifications: agvanceFieldClassificationList,
                };
            }

            const refreshDropdowns = {
                [DROPDOWN_CROP]: apiUrl("AgBytes/GetCropDropdownList"),
                [DROPDOWN_BRANDORGANIZATION]: {
                    url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
                    model: fieldModel.cropGuid,
                },
                [DROPDOWN_VARIETYHYBRID]: {
                    url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
                    model: {
                        cropId: fieldModel.cropGuid,
                        brandOrganization: fieldModel.brandOrganizationGuid,
                    },
                },
            };

            yield put(actions.fetchFieldInformationSucceeded(fieldModel, activeCustomerGuid));
            yield put(
                fetchDropdownData({
                    ...refreshDropdowns,
                    action: actions.fetchedDropdownData,
                })
            );
        } else {
            yield put(actions.fetchFieldInformationSucceeded(fieldModel, activeCustomerGuid));
            yield put(
                fetchDropdownData({
                    ...dropdowns,
                    action: actions.fetchedDropdownData,
                })
            );
        }
    } catch (err) {
        yield put(actions.fetchFieldInformationError());
        yield put(notificationActions.apiCallError(err, fetchAction));
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }
};

export const onFetchResurfaceEvents = function* (fetchAction) {
    try {
        if (
            Object.hasOwn(fetchAction.payload, "fieldGuid") &&
            fetchAction.payload.fieldGuid &&
            fetchAction.payload.fieldGuid !== ""
        ) {
            const resurfaceEvents = yield call(
                EventAPI.fetchResurfaceEventList,
                fetchAction.payload.fieldGuid
            );
            yield put(
                actions.fetchResurfaceEventsSuccess(
                    resurfaceEvents,
                    fetchAction.payload.keepExistingEvents
                )
            );
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, fetchAction));
    }
};

export const onMoveCustomerFields = function* (action) {
    yield put(actions.setIsMoveDialogLoading(true));
    const {
        userGuid,
        customerGuid,
        farmName,
        fieldGuidList,
        agvanceCustomerGuid,
        agvanceFieldOrgLevelGuid,
    } = action.payload;
    try {
        // move in backend
        yield call(
            CustomerAPI.moveCustomerFields,
            userGuid,
            customerGuid,
            farmName,
            fieldGuidList,
            agvanceCustomerGuid,
            agvanceFieldOrgLevelGuid
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    } finally {
        yield put(actions.setIsMoveDialogLoading(false));
    }
    // move in frontend
    yield put(cdActions.moveFields(customerGuid, farmName, fieldGuidList));
    yield put(actions.clearFieldsToMove());
    yield put(cdActions.addSelectedFields(fieldGuidList));
    yield put(cdActions.restartCustomerFields());
    yield put(mapActions.setForceRefreshFlag(true));
};

const getFieldInformation = (fieldModel: FieldAPI.IFieldResult, fieldMapModel?: FieldInfo) => ({
    ...fieldModel,
    farmName: fieldModel.farmName || "",
    events: fieldMapModel ? fieldMapModel.eventCount : 0,
    recs: fieldMapModel ? fieldMapModel.recCount : 0,
});

export const handleFieldBoundaryTransfer = function* (originalField) {
    const fieldBoundaryTransferFieldModel = yield select(getFieldBoundaryTransferFieldModel);
    if (!fieldBoundaryTransferFieldModel) {
        return;
    }

    const fieldMap = yield select(cdSelectors.getFieldMap);
    const userGuid = yield select(getTheUserGuid);

    try {
        yield call(FieldAPI.saveField, userGuid, fieldBoundaryTransferFieldModel);
        const updatedTransferField = yield call(
            FieldAPI.getField,
            fieldBoundaryTransferFieldModel.fieldGuid
        );
        const updatedTransferFieldData = getFieldInformation(
            updatedTransferField,
            fieldMap.get(updatedTransferField.fieldGuid)
        );
        yield put(actions.saveFieldInformationSucceeded(updatedTransferFieldData));
        yield put(actions.setFieldBoundaryTransferFieldModel(null));
        yield put(actions.setFieldBoundaryTransferGeometries([]));
    } catch (error) {
        // Reset original Field Boundary
        const fieldBoundaryTransferGeometries = yield select(getFieldBoundaryTransferGeometries);
        const fieldModel = yield call(FieldAPI.getField, originalField.fieldGuid);
        let calculatedArea = fieldModel.fieldBoundary.calculatedArea;
        const fieldBoundaryGuid = fieldModel.fieldBoundary.fieldBoundaryGuid;
        originalField.fieldBoundary.fieldBoundaryGuid = fieldBoundaryGuid;
        originalField.fieldBoundaryGuid = fieldBoundaryGuid;
        originalField.fieldBoundary.fieldBoundaryPolygons.forEach((fbp) => {
            fbp.fieldBoundaryGuid = fieldBoundaryGuid;
            fbp.fieldBoundaryPolygonGuid = uuid();
        });
        fieldBoundaryTransferGeometries.forEach((geometry) => {
            originalField.fieldBoundary.fieldBoundaryPolygons.push({
                fieldBoundaryGuid: fieldBoundaryGuid,
                fieldBoundaryPolygonGuid: uuid(),
                fieldGuidsToCombine: null,
                importFieldBoundaryGuids: null,
                shapes: [JSON.stringify(geometry.toJSON())],
            });
            calculatedArea += GeometryUtils.calculateArea(geometry);
        });
        originalField.fieldBoundary.calculatedArea = calculatedArea;
        yield call(FieldAPI.saveField, userGuid, originalField);
        throw error;
    }
};

export const onSaveFieldInit = function* (saveAction) {
    yield put(layerActions.removeAllVisibleSurfaces());
    yield put(actions.setIsFieldLoading(true));

    let activeField = saveAction.payload.fieldModel;
    const userGuid = yield select(getTheUserGuid);
    const fieldMap = yield select(cdSelectors.getFieldMap);

    if (activeField.fieldGuid == null || activeField.fieldGuid === "") {
        activeField.fieldGuid = uuid();
    }
    const fieldInfo = fieldMap.get(activeField.fieldGuid);
    activeField.events = fieldInfo ? fieldInfo.eventCount : 0;
    activeField.recs = fieldInfo ? fieldInfo.recCount : 0;

    try {
        // When called from View/Edit Field > Save, classifications is a collection of IAgvClassification
        // objects. When it is called from Import > Edit Field Boundary, it is a collection of
        // classification keys (numbers) only. In the former case, we need to convert back to
        // a collection of keys before calling FieldAPI.saveField.
        if (
            activeField.classifications?.length > 0 &&
            typeof activeField.classifications[0] !== "number"
        ) {
            activeField = {
                ...activeField,
                classifications: activeField.classifications.map(
                    (x: FieldAPI.IAgvClassification) => x.classificationKey
                ),
            };
        }

        yield call(FieldAPI.saveField, userGuid, activeField);

        yield call(handleFieldBoundaryTransfer, activeField);

        const updatedField = yield call(FieldAPI.getField, activeField.fieldGuid);
        const updatedFieldInformation = getFieldInformation(
            updatedField,
            fieldMap.get(updatedField.fieldGuid)
        );
        yield put(actions.saveFieldInformationSucceeded(updatedFieldInformation));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, saveAction));
        return;
    } finally {
        yield put(actions.setIsFieldLoading(false));
    }

    if (saveAction.payload.callback) {
        // FIXME: don't do this.  Look at `isLoading` or something
        //        similar in the state instead
        saveAction.payload.callback();
    }
};

export const onSaveFieldSucceeded = function* (action) {
    const { response } = action.payload;
    const model = new cdModels.FieldInfo(response);
    const customerFields: pagination.IPaginatedResponse<
        SearchAPI.ICustomerFieldResult,
        SearchAPI.ICustomerFieldPageId
    > = yield call(SearchAPI.getCustomerFields, yield filteredCustomerFieldRequest(null, 1, null));
    const customers = customerFields.results.map(cdModels.CustomerInfo.fromCustomerField);
    yield put(
        cdActions.addUpdateFields(
            [model],
            customers,
            autoExpandedCustomerModifications(customerFields.results)
        )
    );
};

export const fieldModuleSaga = function* () {
    yield all([
        messageSubscriptions(),
        fieldListSaga(),
        takeLatest(loginActions.SET_USER_INFO_COMPLETE, init),
        takeEvery(actions.ACTIVATE_CUSTOMER, onActivateCustomer),
        takeEvery(actions.ACTIVATE_FIELD, onActivateField),
        takeLatest(actions.ADD_ANALYSIS_LAYER, onAddAnalysisLayer),
        takeEvery(actions.COMBINE_FIELDS, onCombineFields),
        takeEvery(actions.FETCH_FULL_FIELD_RECORDS, onFetchFullFieldRecords),
        takeEvery(actions.DELETE_CUSTOMER, onDeleteCustomer),
        takeEvery(actions.DELETE_FIELD, onDeleteField),
        takeEvery(actions.DELETE_SELECTED_FIELDS, onDeleteSelectedFields),
        takeLatest(actions.EXPORT_BOUNDARY, onExportBoundary),
        takeLatest(actions.EXPORT_DETAILS, onExportDetails),
        takeEvery(actions.FETCH_AGVANCE_FIELD_LIST, onFetchAgvanceFieldList),
        takeEvery(
            actions.FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST,
            onFetchAgvanceFieldClassificationList
        ),
        takeEvery(actions.FETCH_AGVANCE_ORG_LEVEL_LIST, onFetchAgvanceOrgLevelList),
        takeEvery(loginActions.FETCH_LAYER_TYPES_ACCESS, onFetchLayerTypesAccess),
        takeLatest(actions.FETCH_FIELD_INFO_INIT, onInitFetchFieldInformation),
        takeEvery(actions.FETCH_RESURFACE_EVENTS, onFetchResurfaceEvents),
        takeLatest(listActions.SAVE_BATCH_DETAILS, onSaveBatchDetails),
        takeLatest(actions.SAVE_FIELD_INIT, onSaveFieldInit),
        takeEvery(actions.SAVE_FIELD_SUCCEEDED, onSaveFieldSucceeded),
        takeEvery(actions.MOVE_CUSTOMER_FIELDS, onMoveCustomerFields),
    ]);
};
