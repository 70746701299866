export class EcAttribute {
    public name: string;
    public attributeId: number;
}

export class EcAttributes {
    public static shallow: EcAttribute = {
        name: "EC Shallow",
        attributeId: 212,
    };
    public static deep: EcAttribute = { name: "EC Deep", attributeId: 211 };
    public static elevation: EcAttribute = {
        name: "Elevation",
        attributeId: 213,
    };

    public static all(): EcAttribute[] {
        return [EcAttributes.shallow, EcAttributes.deep, EcAttributes.elevation];
    }

    public static byName(): Map<string, EcAttribute> {
        return new Map<string, EcAttribute>(EcAttributes.all().map((x) => [x.name, x]));
    }

    public static byAttributeId(): Map<number, EcAttribute> {
        return new Map<number, EcAttribute>(EcAttributes.all().map((x) => [x.attributeId, x]));
    }
}
