import { IOptionRendererProps } from "~/core/components/select-input/model";
import { defaultOptionRenderer } from "~/core/components/select-input/select-input";
/**
 * For each error code in `errCodeList`, look up the values for that code
 *  in `errCodeToSetValsMap` and add them to the returned set
 * @param {number[]} errCodeList
 * @param {Map<number, T|T[]>} errCodeToSetValsMap
 */
export const getSetValuesForErrorCodeList = <T>(
    errCodeList: number[],
    errCodeToSetValsMap: Map<number, T | T[]>
): Set<T> => {
    return errCodeList
        .map((errorCode) => errCodeToSetValsMap.get(errorCode))
        .filter((setVal) => setVal != null)
        .reduce((allValues, setVal) => {
            if (Array.isArray(setVal)) {
                setVal.forEach((v) => allValues.add(v));
            } else {
                allValues.add(setVal);
            }
            return allValues;
        }, new Set<T>());
};

export const optionRendererWithCustomTitle = ({
    option,
    isHeader,
    isSelected,
    isHighlighted,
    matchPos,
    matchLen,
}: IOptionRendererProps<string>): JSX.Element => {
    const titleValue = option.titleValue;
    return defaultOptionRenderer({
        option,
        isHeader,
        isSelected,
        isHighlighted,
        matchPos,
        matchLen,
        titleValue,
    });
};
