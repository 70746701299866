import { all, takeEvery, put } from "redux-saga/effects";

import * as actionPanelActions from "~/action-panel/actions";

import { recInfoSaga } from "./components/rec-info/sagas";
import { recListSaga } from "./components/rec-list/sagas";

import * as actions from "./actions";

export const onSetActivePage = function* (action) {
    const { activePage } = action.payload;
    yield put(actionPanelActions.setIsEnabled(activePage === actions.RecModulePages.REC_LIST));
};

export const recModuleSaga = function* () {
    yield all([takeEvery(actions.SET_ACTIVE_PAGE, onSetActivePage), recInfoSaga(), recListSaga()]);
};
