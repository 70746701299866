import { defineMessages } from "react-intl";

export default defineMessages({
    associatedField: {
        id: "featureDetails.associatedField",
        defaultMessage: "Associated Field",
    },
    featureType: {
        id: "featureDetails.featureType",
        defaultMessage: "Feature Type",
    },
    featureName: {
        id: "featureDetails.featureName",
        defaultMessage: "Feature Name",
    },
    description: {
        id: "featureDetails.description",
        defaultMessage: "Description",
    },
    private: {
        id: "featureDetails.private",
        defaultMessage: "Private",
    },
    useExtent: {
        id: "featureDetails.useExtent",
        defaultMessage: "Use As Extent",
    },
});
