import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_IS_ACTIVE;
export const PROPS_NUTRIENT_ID = adminData.PROPS_INTEGER_ID;
export const PROPS_NUTRIENT_GUID = adminData.PROPS_ID;
export const PROPS_NUTRIENT_NAME = adminData.PROPS_NAME;
export const PROPS_ELEMENT = "element";
export const PROPS_OXIDE_BASIS = "oxideBasis";
export const PROPS_EQUIVALENT_WEIGHT = "equivalentWeight";
export const PROPS_NCDA_FACTOR = "ncdaFactor";
