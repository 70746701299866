import { defineMessages } from "react-intl";

export const messages = defineMessages({
    CEC: { id: "report.CEC", defaultMessage: "CEC" },
    KPPM: { id: "report.KPPM", defaultMessage: "K" },
    OM: { id: "report.OM", defaultMessage: "OM" },
    PH: { id: "report.PH", defaultMessage: "pH" },
    PM3: { id: "report.PM3", defaultMessage: "P" },
    breakEvenYield: {
        id: "report.breakEvenYield",
        defaultMessage: "Benchmark Yield",
    },
    addCropYieldGoal: {
        id: "report.addCropYieldGoal",
        defaultMessage: "Crop / Yield Goals",
    },
    additionalEmail: {
        id: "report.additionalEmail",
        defaultMessage: "Additional Email",
    },
    alias: { id: "report.alias", defaultMessage: "Alias" },
    ageOfSample: { id: "report.ageOfSample", defaultMessage: "Age of Samples" },
    agvanceCropColor: { id: "report.agvanceCropColor", defaultMessage: "Agvance Crop Color" },
    aliasVarietyHybrid: { id: "report.aliasVarietyHybrid", defaultMessage: "Alias Variety Hybrid" },
    applicationAttribute: {
        id: "report.applicationAttribute",
        defaultMessage: "Application Attribute",
    },
    applicationMethod: {
        id: "report.applicationMethod",
        defaultMessage: "Application Method",
    },
    applicationTiming: {
        id: "report.applicationTiming",
        defaultMessage: "Application Timing",
    },
    applicationRate: { id: "report.applicationRate", defaultMessage: "Rate" },
    attribute: { id: "report.attribute", defaultMessage: "Attribute" },
    attributeSelection: {
        id: "report.attributeSelection",
        defaultMessage: "Attribute Selection",
    },
    avery6572: {
        id: "report.avery6572",
        defaultMessage: 'Avery 6572 (2" x 2-5/8")',
    },
    avery8160: {
        id: "report.avery8160",
        defaultMessage: 'Avery 8160 (1" x 2-5/8")',
    },
    border: { id: "report.border", defaultMessage: "Border" },
    brand: { id: "report.brand", defaultMessage: "Brand" },
    crop: { id: "report.crop", defaultMessage: "Crop" },
    cropChemistry: { id: "report.cropChemistry", defaultMessage: "Crop Chemistry" },
    cropPlaceholderText: {
        id: "report.cropPlaceholderText",
        defaultMessage: "Crop",
    },
    cropPurposePlaceholderText: {
        id: "report.cropPurposePlaceholderText",
        defaultMessage: "Crop Purpose",
    },
    cropLabel: { id: "report.cropLabel", defaultMessage: "Crop Label" },
    cropZone: { id: "report.cropZone", defaultMessage: "Crop Zone" },
    chromelessReportComplete: {
        id: "report.chromelessReportComplete",
        defaultMessage: "{reportName} Report Complete: {fileName}",
    },
    chromelessReportFailed: {
        id: "report.chromelessReportFailed",
        defaultMessage: "{reportName} Report Failed: {errMsg}",
    },
    close: { id: "report.closeButton", defaultMessage: "Close" },
    color: { id: "report.color", defaultMessage: "Color" },
    confirmTitle: { id: "report.confirmTitle", defaultMessage: "Confirm" },
    costDetails: {
        id: "report.costDetails",
        defaultMessage: "{count, plural, =0 {Cost Detail} one {Cost Details}}",
    },
    coverage: { id: "report.coverage", defaultMessage: "Coverage" },
    customer: { id: "report.customer", defaultMessage: "Customer" },
    date: { id: "report.date", defaultMessage: "Date" },
    dateOrderError: {
        id: "report.dateOrderError",
        defaultMessage: "The end date must fall after the start date.",
    },
    deleteSelected: {
        id: "report.deleteSelected",
        defaultMessage: "Delete Selected",
    },
    detail: { id: "report.detail", defaultMessage: "Detail" },
    displayOptions: {
        id: "report.displayOptions",
        defaultMessage: "Display Options",
    },
    displayParameters: {
        id: "report.displayParameters",
        defaultMessage: "Display Parameters",
    },
    displayPhotos: {
        id: "report.displayPhotos",
        defaultMessage: "Display Photos",
    },
    displaySampleSites: {
        id: "report.displaySampleSites",
        defaultMessage: "Sample Sites",
    },
    displayFieldAcres: { id: "report.displayFieldAcres", defaultMessage: "Display Field Acres" },
    displayTraits: { id: "report.displayTraits", defaultMessage: "Display Traits" },
    displayType: { id: "report.displayType", defaultMessage: "Display Type" },
    downloadSelected: {
        id: "report.downloadSelected",
        defaultMessage: "Download Selected",
    },
    dryYield: { id: "chromeless.dryYield", defaultMessage: "Dry Yield" },
    dynamic: { id: "chromeless.dynamic", defaultMessage: "Dynamic" },
    elevation: { id: "report.elevation", defaultMessage: "Elevation" },
    emailId: { id: "report.emailId", defaultMessage: "Email Id:" },
    emailSelected: {
        id: "report.emailSelected",
        defaultMessage: "Email Selected",
    },
    endDate: { id: "report.endDate", defaultMessage: "End Date" },
    errors: { id: "report.errors", defaultMessage: "Errors" },
    equipment: { id: "report.equipment", defaultMessage: "Equipment" },
    farm: { id: "report.farm", defaultMessage: "Farm" },
    farmTotals: { id: "report.farmTotals", defaultMessage: "Farm Totals" },
    fertilityTypography: {
        id: "report.fertilityTypography",
        defaultMessage: "Fertility / Typography",
    },
    field: { id: "report.field", defaultMessage: "Field" },
    fieldLabelFieldsFarmName: {
        id: "report.fieldLabelFieldsFarmName",
        defaultMessage: "Farm Name",
    },
    fieldLabelFieldsFieldName: {
        id: "report.fieldLabelFieldsFieldName",
        defaultMessage: " Field Name",
    },
    fieldLabelFieldsFieldNameArea: {
        id: "report.fieldLabelFieldsFieldNameArea",
        defaultMessage: "Field Name / Area",
    },
    fieldLabelFieldsArea: {
        id: "report.fieldLabelFieldsArea",
        defaultMessage: "Area",
    },
    fieldLabelFieldsLatLong: {
        id: "report.fieldLabelFieldsLatLong",
        defaultMessage: "Lat/Long",
    },
    fieldLabelOptions: {
        id: "report.fieldLabelOptions",
        defaultMessage: "Label Options",
    },
    fieldsPerPageOptions: {
        id: "report.fieldsPerPageOptions",
        defaultMessage: "Per Page Options",
    },
    fieldSymbologyOptions: {
        id: "report.fieldSymbologyOptions",
        defaultMessage: "Label/Color Options",
    },
    fieldSymbologyBoundaryColor: {
        id: "report.fieldSymbologyBoundaryColor",
        defaultMessage: "Boundary Color",
    },
    fieldSymbologyBoundaryLabels: {
        id: "report.fieldSymbologyBoundaryLabels",
        defaultMessage: "Boundary Labels",
    },
    fieldSymbologyFillColor: {
        id: "report.fieldSymbologyFillColor",
        defaultMessage: "Fill Color",
    },
    fieldTotals: { id: "report.fieldTotals", defaultMessage: "Field Totals" },
    fileNameFormat: {
        id: "report.fileNameFormat",
        defaultMessage: "File Name Format",
    },
    fill: { id: "report.fill", defaultMessage: "Fill" },
    generalNotes: { id: "report.generalNotes", defaultMessage: "Notes" },
    genericGpServiceError: {
        id: "report.genericGpServiceError",
        defaultMessage: "Generic Gp Service Error",
    },
    growerTotals: {
        id: "report.growerTotals",
        defaultMessage: "Grower Totals",
    },
    guaranteedAnalysis: {
        id: "report.guaranteedAnalysis",
        defaultMessage: "Guaranteed Analysis",
    },
    herbicideTolerance: {
        id: "report.herbicideTolerance",
        defaultMessage: "Herbicide Tolerance",
    },
    includeTargetRate: {
        id: "report.includeTargetRate",
        defaultMessage: "Include Target Rate",
    },
    individualReportBook: {
        id: "report.individualReportBook",
        defaultMessage: "Individual Report Book",
    },
    inProgress: { id: "report.inProgress", defaultMessage: "In Progress" },
    invalidPageSelection: {
        id: "report.invalidPageSelection",
        defaultMessage: "Selections Required",
    },
    invalidPageSelectionMessage: {
        id: "report.invalidPageSelectionMessage",
        defaultMessage: "At least one page must be selected.",
    },
    invalidSymbology: {
        id: "report.invalidSymbology",
        defaultMessage: "Invalid Symbology",
    },
    invalidSymbologyMessage: {
        id: "report.invalidSymbologyMessage",
        defaultMessage: "At least one of either the border or the fill must be checked.",
    },
    isHourly: { id: "report.isHourly", defaultMessage: "Hourly" },
    labAccount: { id: "report.labAccount", defaultMessage: "Lab Account" },
    labelSize: { id: "report.labelSize", defaultMessage: "Label Size" },
    labelType: { id: "report.labelType", defaultMessage: "Label Type" },
    landscape: { id: "report.landscape", defaultMessage: "Landscape" },
    lint: { id: "report.lint", defaultMessage: "Lint Yield" },
    legendType: { id: "report.legendType", defaultMessage: "Legend Type" },
    lowRateLegend: {
        id: "report.lowRateLegend",
        defaultMessage: "Low Rate Legend",
    },
    mergeSelected: {
        id: "report.mergeSelected",
        defaultMessage: "Merge Selected",
    },
    moisture: { id: "chromeless.moisture", defaultMessage: "Moisture" },
    monitorProduct: { id: "chromeless.monitorProduct", defaultMessage: "Monitor Product" },
    monitorVariety: { id: "chromeless.monitorVariety", defaultMessage: "Monitor Variety Hybrid" },
    multiField: { id: "report.multiField", defaultMessage: "Multi-Field" },
    multiFieldRec: {
        id: "report.multiFieldRec",
        defaultMessage: "Multi-Field Rec",
    },
    multiGrower: {
        id: "report.multiGrower",
        defaultMessage: "Multi-Grower",
    },
    multipleMapsPerPage: {
        id: "report.multipleMapsPerPage",
        defaultMessage: "Multiple Maps per Page",
    },
    none: {
        id: "report.none",
        defaultMessage: "None",
    },
    noReportSelected: {
        id: "report.noReportSelected",
        defaultMessage: "Select a report for options.",
    },
    numberOfSeasons: {
        id: "report.numberOfSeasons",
        defaultMessage: "Number of Seasons",
    },
    nutrientSelection: {
        id: "report.nutrientSelection",
        defaultMessage: "Nutrient Selection",
    },
    nutrientTargetColors: {
        id: "report.nutrientTargetColors",
        defaultMessage: "Nutrient Target Colors",
    },
    options: { id: "setup.options", defaultMessage: "Options" },
    orientation: { id: "setup.orientation", defaultMessage: "Orientation" },
    originalFormat: {
        id: "setup.originalFormat",
        defaultMessage: "Original Format",
    },
    perSlope: { id: "report.perSlope", defaultMessage: "% Slope" },
    pageSelection: {
        id: "report.pageSelection",
        defaultMessage: "Page Selection",
    },
    pageSizeOptions: {
        id: "report.pageSizeOptions",
        defaultMessage: "Page Size",
    },
    planting: { id: "report.planting", defaultMessage: "Planting" },
    plantingAttribute: {
        id: "report.plantingAttribute",
        defaultMessage: "Planting Attribute",
    },
    plantingDate: {
        id: "report.plantingDate",
        defaultMessage: "Planting Date",
    },
    plssInfo: { id: "report.plssInfo", defaultMessage: "PLSS Info" },
    point: { id: "report.point", defaultMessage: "Point" },
    polygon: { id: "report.polygon", defaultMessage: "Polygon" },
    portrait: { id: "report.portrait", defaultMessage: "Portrait" },
    printSelected: {
        id: "report.printSelected",
        defaultMessage: "Print Selected",
    },
    product: { id: "report.product", defaultMessage: "Product" },
    productDetail: {
        id: "report.productDetail",
        defaultMessage: "Product Detail",
    },
    productMix: { id: "report.productMix", defaultMessage: "Product Mix" },
    productRate: { id: "report.productRate", defaultMessage: "Product Rate" },
    productRecap: {
        id: "report.productRecap",
        defaultMessage: "Product Recap",
    },
    radPdfError: {
        id: "report.radPdfError",
        defaultMessage:
            "Rad Pdf Not Generated due to Error in Crystal Report or folder access permissions",
    },
    relativeMaturity: {
        id: "report.relativeMaturity",
        defaultMessage: "Relative Maturity",
    },
    relativeMaturityList: {
        id: "report.relativeMaturityList",
        defaultMessage: "Relative Maturity List",
    },
    reportBookGroupByOption: {
        id: "report.reportBookGroupByOption",
        defaultMessage: "Group By Options",
    },
    reportTableHeader: {
        id: "report.reportTableHeader",
        defaultMessage: "{count, plural, one {Report} other {Reports}} Based on Field Selection",
    },
    reportType: { id: "report.reportType", defaultMessage: "Report Type" },
    required: { id: "report.required", defaultMessage: "Required" },
    resetToDefault: {
        id: "report.resetToDefault",
        defaultMessage: "Reset to default",
    },
    sampleSites: { id: "report.sampleSites", defaultMessage: "Sample Sites" },
    sampleType: { id: "report.sampleType", defaultMessage: "Sample Type" },
    season: { id: "report.season", defaultMessage: "Season" },
    seedingRate: { id: "report.seedingRate", defaultMessage: "Seeding Rate" },
    selectedBasemap: {
        id: "report.selectedBasemap",
        defaultMessage: "Basemap",
    },
    selectFieldError: {
        id: "report.selectFieldError",
        defaultMessage: "Please select at least 1 field before clicking Create Report.",
    },
    selectReportTypeError: {
        id: "report.selectReportTypeError",
        defaultMessage: "Please select the Report Type.",
    },
    selectReportBookTypeError: {
        id: "report.selectReportBookTypeError",
        defaultMessage: "Please select the Report Book Type.",
    },
    selectReports: {
        id: "report.selectReports",
        defaultMessage: "Select Report",
    },
    sendReportTo: {
        id: "report.sendReportTo",
        defaultMessage: "Send Report To",
    },
    showQrCode: { id: "report.showQrCode", defaultMessage: "Lat/Long QR Code" },
    showNonFieldFeatures: {
        id: "report.showNonFieldFeatures",
        defaultMessage: "Non-Field Features",
    },
    showOverviewMap: {
        id: "report.showOverviewMap",
        defaultMessage: "Overview Map",
    },
    showRecSummary: {
        id: "report.showRecSummary",
        defaultMessage: "Rec Summary",
    },
    useImportDate: {
        id: "report.useImportDate",
        defaultMessage: "Use Import Date",
    },
    soilType: { id: "report.soilType", defaultMessage: "Soil Type" },
    soilTypeAttribute: {
        id: "report.soilTypeAttribute",
        defaultMessage: "Soil Type Attribute",
    },
    soilTypeByVariety: {
        id: "report.soilTypeByVariety",
        defaultMessage: "Soil Type By Variety",
    },
    soilTypeByVarietyBySeedingRate: {
        id: "report.soilTypeByVarietyBySeedingRate",
        defaultMessage: "Soil Type By Variety By Seeding Rate",
    },
    soilTypeName: {
        id: "report.soilTypeName",
        defaultMessage: "Soil Type Name",
    },
    speed: { id: "chromeless.speed", defaultMessage: "Speed" },
    startDate: { id: "report.startDate", defaultMessage: "Start Date" },
    startPosition: {
        id: "report.startPosition",
        defaultMessage: "Start Position",
    },
    status: { id: "report.status", defaultMessage: "Status" },
    statusError: { id: "report.statusError", defaultMessage: "Status Error" },
    summary: { id: "report.summary", defaultMessage: "Summary" },
    summaryReportsHeader: {
        id: "report.summaryReportsHeader",
        defaultMessage: "{count, plural, one {Summary Report} other {Summary Reports}}",
    },
    targetRate: { id: "report.targetRate", defaultMessage: "Target Rate" },
    testingLab: { id: "report.testingLab", defaultMessage: "Testing Lab" },
    testPackages: {
        id: "report.testPackages",
        defaultMessage: "Test Packages",
    },
    thermal: {
        id: "report.thermal",
        defaultMessage: 'Thermal (1.25" x 2.25")',
    },
    tillageDepth: {
        id: "report.tillageDepth",
        defaultMessage: "Tillage Depth",
    },
    tillageMethod: {
        id: "report.tillageMethod",
        defaultMessage: "Tillage Method",
    },
    totalingUnit: {
        id: "report.totalingUnit",
        defaultMessage: "Totaling Unit",
    },
    transparency: { id: "report.transparency", defaultMessage: "Transparency" },
    useAllFields: {
        id: "report.useAllFields",
        defaultMessage:
            "You are creating a report that will use all {numFields} fields you have access to. Do you want to continue?",
    },
    useCustomImagery: {
        id: "report.useCustomImagery",
        defaultMessage: "Custom Imagery",
    },
    useEventIdBarcode: {
        id: "report.useEventIdBarcode",
        defaultMessage: "Event ID Barcode",
    },
    useMonitorName: {
        id: "report.useMonitorName",
        defaultMessage: "Use Monitor Name",
    },
    useProductRecs: {
        id: "report.useProductRecs",
        defaultMessage: "Product Rec",
    },
    variety: { id: "report.variety", defaultMessage: "Variety" },
    varietyBySeedingRate: {
        id: "report.varietyBySeedingRate",
        defaultMessage: "Variety By Seeding Rate",
    },
    varietyBySoilType: {
        id: "report.varietyBySoilType",
        defaultMessage: "Variety By Soil Type",
    },
    varietyBySoilTypeBySeedingRate: {
        id: "report.varietyBySoilTypeBySeedingRate",
        defaultMessage: "Variety By Soil Type By Seeding Rate",
    },
    varietyHybrid: {
        id: "report.varietyHybrid",
        defaultMessage: "Variety/Hybrid",
    },
    varietyHybridType: {
        id: "report.varietyHybridType",
        defaultMessage: "Variety Hybrid Type",
    },
    varietyLabel: {
        id: "report.varietyLabel",
        defaultMessage: "Variety Label",
    },
    viewReport: { id: "report.viewReport", defaultMessage: "View Report" },
    yieldAttribute: {
        id: "report.yieldAttribute",
        defaultMessage: "Yield Attribute",
    },
    yieldByApplication: {
        id: "report.yieldByApplication",
        defaultMessage: "Yield By Application",
    },
    yieldByCompareByOptions: {
        id: "report.yieldByCompareByOptions",
        defaultMessage: "Compare By Options",
    },
    yieldByPlanting: {
        id: "report.yieldByPlanting",
        defaultMessage: "Yield By Planting",
    },
    yieldBySoilType: {
        id: "report.yieldBySoilType",
        defaultMessage: "Yield By Soil Type",
    },
    yieldByTillage: {
        id: "report.yieldByTillage",
        defaultMessage: "Yield By Tillage",
    },
    yieldGoal: { id: "report.yieldGoal", defaultMessage: "Yield Goal" },
    yieldUnit: { id: "report.yieldUnit", defaultMessage: "Yield Unit" },
    zoomLevelOptions: {
        id: "report.zoomLevelOptions",
        defaultMessage: "Zoom Level",
    },
});
