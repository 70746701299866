import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { injectIntl, InjectedIntl } from "react-intl";

import { TextInput, SelectInput, Bucket, BucketHeader } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";

import * as actions from "./actions";
import * as layerSelectors from "../layer-list/selectors";
import * as analysisInfoSelectors from "../analysis-info/selectors";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import { messages } from "../i18n-messages";
import _ from "lodash";
import { colorOptionRenderer, getBackgroundGradientStyle } from "../../utils";

import { AnalysisIcon } from "./icons/analysis";

import { IAnalysisMultiFieldEcDataSummary } from "~/recs-events/analysis/model";
import { ClassificationMethods } from "~/models/classification-method";
import { EcAttributes } from "~/models/ec-attribute";

import { LayerAPI } from "@ai360/core";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import "./analysis-info.css";
import "./analysis-ec-data-form.css";

interface IProps {
    analysisSummary: IAnalysisMultiFieldEcDataSummary;
    intl: InjectedIntl;
    analysisDetailsErrorCodeList: number[];
    onUpdateCurrentAnalysisSummary: (newProps: Partial<IAnalysisMultiFieldEcDataSummary>) => void;
    colorRamps: LayerAPI.IColorRamp[];
    availableAttributes: number[];
}

interface IState {
    errorMessagePlaceholderSet: Set<string>;
}

const errorCodeToMessageIdSetMap = new Map<number, string>([
    [2805, messages.analysisMethodPlaceholderText],
    [2806, messages.analysisDuplicateName],
    [2906, messages.ecDataUnknownClassificationMethod],
    [2907, messages.batchAnalysisRequiresOneZonePerField],
]);

const numberOfClasses = _.range(2, 21);

export const formLabelMessage = messages.ecDataSidebar;
export const formLabelIcon = AnalysisIcon;

export const errorCodesApply = (errorCodeList: Array<number>): boolean => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class AnalysisEcDataForm_ extends PureComponent<IProps, IState> {
    private colorRampSelect: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessagePlaceholderSet: this.getErrorMessagePlaceholderSet(props),
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: IProps): void {
        if (newProps.analysisDetailsErrorCodeList !== this.props.analysisDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this.getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    private getErrorMessagePlaceholderSet(props) {
        const { analysisDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    private updateAnalysisSummary(newProps: Partial<IAnalysisMultiFieldEcDataSummary>) {
        this.props.onUpdateCurrentAnalysisSummary(newProps);
    }

    private renderErrors(errorMessagePlaceholderSet, formatMessage) {
        return errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0
            ? null
            : [...errorMessagePlaceholderSet].map((message, index) => (
                  <div className="analysis-error-message" key={index}>
                      {formatMessage(message)}
                  </div>
              ));
    }

    render(): JSX.Element {
        const { analysisSummary, colorRamps, availableAttributes } = this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;

        const colorRampSelectOptions = colorRamps.map((cr) => ({
            background: getBackgroundGradientStyle(cr.layerFiles.slice(-1)[0]),
            label: " ",
            value: cr.colorRampGuid,
        }));

        const colorRamp = this.props.colorRamps.find(
            (cs) => cs.colorRampGuid === analysisSummary.colorRampGuid
        );
        const backgroundImageStyle = colorRamp
            ? getBackgroundGradientStyle(colorRamp.layerFiles.slice(-1)[0])
            : null;
        const backgroundImageCss: React.CSSProperties = {
            backgroundImage: `${backgroundImageStyle}`,
        };

        return (
            <div className="rec-event-info-form">
                {this.renderErrors(errorMessagePlaceholderSet, formatMessage)}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader className="analysis-info-bucket-header">
                        {formatMessage(messages.ecDataGeneralInformation)}
                    </BucketHeader>
                    <div className="analysis-layer-ec-data-grid">
                        <TextInput
                            required={true}
                            autoFocus={!analysisSummary.name}
                            containerClassNames={["analysis-layer-ec-data-input"]}
                            onChange={(v: string) => this.updateAnalysisSummary({ name: v })}
                            placeholderText={formatMessage(messages.ecDataNamePlaceholder)}
                            value={analysisSummary.name}
                            maxLength={50}
                        />
                    </div>
                </Bucket>
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader className="analysis-info-bucket-header">
                        {formatMessage(messages.ecDataSymbologyInfo)}
                    </BucketHeader>
                    <div className="analysis-layer-ec-data-grid">
                        <SelectInput<string>
                            disabled={false}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-ec-data-input"]}
                            onChange={(v) =>
                                this.updateAnalysisSummary({
                                    attribute: EcAttributes.byName().get(v).attributeId,
                                })
                            }
                            options={availableAttributes.map((x) => ({
                                value: x,
                                label: `${x}`,
                            }))}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.ecDataAttributePlaceholder)}
                            value={EcAttributes.byAttributeId().get(analysisSummary.attribute).name}
                        />
                        <SelectInput
                            disabled={false}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-ec-data-input"]}
                            onChange={(v: string) => this.updateAnalysisSummary({ numClasses: v })}
                            options={numberOfClasses.map((x) => ({
                                value: x.toString(),
                                label: x.toString(),
                            }))}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(
                                messages.ecDataNumberOfClassesPlaceholder
                            )}
                            value={analysisSummary.numClasses}
                        />
                        <SelectInput
                            inputCSS={backgroundImageCss}
                            disabled={false}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-ec-data-input"]}
                            onChange={(v: string) =>
                                this.updateAnalysisSummary({ colorRampGuid: v })
                            }
                            options={colorRampSelectOptions}
                            optionRenderer={colorOptionRenderer}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.ecDataColorRampPlaceholder)}
                            ref={(ref) => (this.colorRampSelect = ref)}
                            value={analysisSummary.colorRampGuid}
                        />
                        <SelectInput
                            disabled={false}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-ec-data-input"]}
                            onChange={(v: string) =>
                                this.updateAnalysisSummary({
                                    classificationMethod: v,
                                })
                            }
                            options={ClassificationMethods.all().map((x) => ({
                                value: x.name,
                                label: x.name,
                            }))}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(
                                messages.ecDataClassificationMethodsPlaceholder
                            )}
                            value={analysisSummary.classificationMethod}
                        />
                    </div>
                </Bucket>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onUpdateCurrentAnalysisSummary: (newProps) =>
        dispatch(actions.updateEcDataAnalysisSummary(newProps)),
});

const mapStateToProps = (state) => ({
    colorRamps: layerSelectors.getColorRamps(state),
    availableAttributes: analysisInfoSelectors
        .getEcDataAttributes(state)
        .map((x) => EcAttributes.byAttributeId().get(x.attribute).name),
});

export const AnalysisEcDataForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(injectIntl(AnalysisEcDataForm_));
