import React from "react";
import { injectIntl, intlShape } from "react-intl";

import { NoLink } from "~/core";
import { messages } from "../common/i18n-messages";
import "./module-filters.css";

export interface IFilterToggleProps {
    filterVisible?: boolean;
    filterCount?: number;
    filterValueCount?: string | number;
    intl: intlShape;
    label?: string;
    onToggleFilters: (toggleFilter: boolean) => void;
    showFilterValueCount?: boolean;
}

class ModuleFiltersToggle_ extends React.Component<IFilterToggleProps> {
    static defaultProps = {
        filterVisible: false,
        filterCount: 0,
    };

    render() {
        const { formatMessage } = this.props.intl;
        const {
            filterCount,
            filterVisible,
            filterValueCount,
            label,
            onToggleFilters,
            showFilterValueCount,
        } = this.props;
        const msg = this.props.filterVisible
            ? filterCount === 1
                ? messages.hideFilter
                : messages.hideFilters
            : filterCount === 1
            ? messages.showFilter
            : messages.showFilters;
        return (
            <div className={showFilterValueCount ? "filter-toggle-with-badge" : "filter-toggle"}>
                <NoLink
                    label={label && label.length ? label : formatMessage(msg)}
                    onClick={() => onToggleFilters(!filterVisible)}
                />
                {!showFilterValueCount || (filterValueCount as number) < 1 ? null : (
                    <span className="filter-value-count" title={filterValueCount as string}>
                        {filterValueCount}
                    </span>
                )}
            </div>
        );
    }
}

export const ModuleFiltersToggle = injectIntl(ModuleFiltersToggle_);
