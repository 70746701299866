import { connect } from "react-redux";
import { TemplateManager } from "./template-manager";
import { actions, selectors } from "./data";

const mapStateToProps = (state) => ({
    fileMetaData: selectors.getModuleState(state).store,
});

const mapDispatchToProps = (dispatch) => ({
    resetTemplate: () => dispatch(actions.resetTemplate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManager);
