import React from "react";
import withSvgIcon from "~/hocs/with-svg-icon";

export const SamplingTissueIcon = withSvgIcon(
    <g>
        <path
            d={
                "M170.34,47.25a4.5,4.5,0,0,0-4.06-.83c-.26.08-26.64,7.43-62.06,8.56-21,.67-42.19,15.79-51.59,36.77-7.56,16.89-6.11,34.21,3.88,48.08a95.5,95.5,0,0,0-14.88,27,4.51,4.51,0,0,0,2.75,5.74,4.6,4.6,0,0,0,1.5.26,4.5,4.5,0,0,0,4.24-3,85.12,85.12,0,0,1,12.44-22.94,57.29,57.29,0,0,0,41.25,17.44c1.45,0,2.9-.05,4.37-.16,18.41-1.31,35.25-10.85,43.95-24.89,15.54-25.11,19.7-85.64,19.87-88.2A4.55,4.55,0,0,0,170.34,47.25Zm-25.87,87.26c-7.09,11.46-21.58,19.56-36.93,20.66a48.69,48.69,0,0,1-39.2-15.27c22.26-24.44,52.81-34.28,53.36-34.45a4.5,4.5,0,0,0-2.67-8.6c-1.77.55-32.89,10.49-56.45,35.86-6.9-10.89-7.59-24.21-1.73-37.28,8-18,26-30.89,43.66-31.45a308.79,308.79,0,0,0,58-7.34C161,72.7,156.1,115.73,144.47,134.51Z"
            }
        />
    </g>,
    "75 20 55 155",
    "event-label-icon"
);
