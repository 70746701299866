import PropTypes from "prop-types";
import * as actions from "./actions";
import { LocalStorageHelpers } from "@ai360/core";
import { BasemapUtils, MapConfig } from "@ai360/core";
import { REPLACE_EVENT_GENERAL } from "~/recs-events/events/actions";
import { REPLACE_REC_GENERAL } from "~/recs-events/recs/actions";
import { noMutateSetAddAll } from "~/utils/immutable";
import { logFirebaseEvent } from "~/utils/firebase";

export const fieldBoundaryLabelsShape = PropTypes.shape({
    customerName: PropTypes.bool.isRequired,
    farmName: PropTypes.bool.isRequired,
    fieldArea: PropTypes.bool.isRequired,
    fieldName: PropTypes.bool.isRequired,
});

export const fieldBoundaryLayerInfoShape = PropTypes.shape({
    showLabels: fieldBoundaryLabelsShape.isRequired,
    visible: PropTypes.bool.isRequired,
});

export const layerInfoShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    showLabels: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
});

function getBasemap() {
    let basemapId =
        LocalStorageHelpers.get(LocalStorageHelpers.BASE_MAP) || MapConfig.defaults.basemap;
    if (!BasemapUtils.basemapIdExists(basemapId)) {
        basemapId = MapConfig.defaults.basemap;
    }
    return basemapId;
}

const initialState = {
    basemap: getBasemap(),
    cadastralLayerInfos: [],
    fieldBoundaryLayerInfo: {
        showLabels: {
            customerName: true,
            farmName: true,
            fieldArea: true,
            fieldName: true,
        },
        visible: true,
    },
    fieldsBackgroundOnly: false,
    fieldsBackgroundOnlyBatch: false,
    filteredPointCount: 0,
    forceRefresh: false,
    invalidatedFields: new Set(),
    isLoadingCount: 1,
    isCanvasLoading: false,
    mapReady: false,
    nonFieldFeatureLayerInfo: {
        visible: true,
    },
    nonFieldFeaturesCanShowTooltip: true,
    scale: 18489297.737236,
    zoom: MapConfig.defaults.zoom,
    zoomToCustomer: null,
    zoomToFarm: null,
    zoomToField: null,
    zoomToFieldList: null,
    zoomToNonFieldFeatures: null,
};

function _updateLayerInfos(layerInfos, layerInfo) {
    const newLayerInfos = [];
    layerInfos.forEach((li) => {
        newLayerInfos.push(li.name === layerInfo.name ? { ...layerInfo } : { ...li });
    });
    if (!newLayerInfos.find((li) => li.name === layerInfo.name)) {
        newLayerInfos.push(layerInfo);
    }
    return newLayerInfos;
}

function _extractFieldGuidFromEventChanges(changeList) {
    // I'd filter to just the completed events, but turns out it leaves the eventData.status null
    return changeList.map((change) => change.fieldGuid);
}

export function mapControlReducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case actions.SET_MAP_READY:
            return Object.freeze({
                ...state,
                mapReady: true,
            });
        case actions.SET_FIELDS_BACKGROUND_ONLY:
            return Object.freeze({
                ...state,
                fieldsBackgroundOnly: action.fieldsBackgroundOnly,
            });
        case actions.SET_FIELDS_BACKGROUND_ONLY_BATCH:
            return Object.freeze({
                ...state,
                fieldsBackgroundOnlyBatch: action.fieldsBackgroundOnlyBatch,
            });
        case actions.SET_FILTERED_POINT_COUNT:
            return Object.freeze({
                ...state,
                filteredPointCount: action.filteredPointCount,
            });
        case actions.SET_BASEMAP:
            return Object.freeze({
                ...state,
                basemap: action.basemap,
            });
        case actions.SET_LAYER_INFOS:
        case actions.SET_LAYER_INFOS_AND_SAVE:
            return Object.freeze({
                ...state,
                cadastralLayerInfos: action.cadastralLayerInfos,
                fieldBoundaryLayerInfo: action.fieldBoundaryLayerInfo,
                nonFieldFeatureLayerInfo: action.nonFieldFeatureLayerInfo,
            });
        case actions.SET_FIELD_BOUNDARY_LAYER_INFO:
            return Object.freeze({
                ...state,
                fieldBoundaryLayerInfo: action.fieldBoundaryLayerInfo,
            });
        case actions.SET_FORCE_REFRESH_FLAG:
            return Object.freeze({
                ...state,
                forceRefresh: action.flag,
            });
        case actions.SET_MAP_LOADING:
            return Object.freeze({
                ...state,
                isLoadingCount: action.isLoading
                    ? state.isLoadingCount + 1
                    : action.force
                    ? 0
                    : Math.max(0, state.isLoadingCount - 1),
            });
        case actions.SET_CANVAS_LOADING:
            return Object.freeze({
                ...state,
                isCanvasLoading: action.isCanvasLoading,
            });
        case actions.SET_NON_FIELD_FEATURE_LAYER_INFO:
            return Object.freeze({
                ...state,
                nonFieldFeatureLayerInfo: { visible: action.visible },
            });
        case actions.SET_ZOOM_AND_SCALE: {
            const zoom = action.zoom != null ? action.zoom : state.zoom;
            const scale = action.scale != null ? action.scale : state.scale;
            return Object.freeze({
                ...state,
                zoom,
                scale,
            });
        }
        case actions.SET_ZOOM_TO_CUSTOMER:
            logFirebaseEvent("zoom_to_fields");
            return Object.freeze({
                ...state,
                zoomToCustomer: action.customerGuid,
            });
        case actions.SET_ZOOM_TO_FARM:
            logFirebaseEvent("zoom_to_farm");
            return Object.freeze({
                ...state,
                zoomToCustomer: action.customerGuid,
                zoomToFarm: action.farmName,
            });
        case actions.SET_ZOOM_TO_FIELD:
            logFirebaseEvent("zoom_to_field");
            return Object.freeze({
                ...state,
                zoomToField: action.fieldGuid,
            });
        case actions.SET_ZOOM_TO_FIELD_LIST:
            return Object.freeze({
                ...state,
                zoomToFieldList: action.fieldGuidList,
            });
        case actions.UPDATE_CADASTRAL_LAYER_INFO:
            return Object.freeze({
                ...state,
                cadastralLayerInfos: _updateLayerInfos(
                    state.cadastralLayerInfos,
                    action.cadastralLayerInfo
                ),
            });
        case actions.SET_FIELDS_TO_INVALIDATE:
            return Object.freeze({
                ...state,
                invalidatedFields: new Set(action.fieldGuidsToInvalidate),
            });
        case REPLACE_EVENT_GENERAL: // when an event accordion item is updated, make sure we're aware of the field guid
        case REPLACE_REC_GENERAL: // same for recs
            return Object.freeze({
                ...state,
                invalidatedFields: noMutateSetAddAll(
                    state.invalidatedFields,
                    _extractFieldGuidFromEventChanges(action.payload.changes)
                ),
            });
        case actions.SET_ZOOM_TO_NON_FIELD_FEATURES:
            return Object.freeze({
                ...state,
                zoomToNonFieldFeatures: action.features,
            });
        default:
            return Object.freeze(state);
    }
}
