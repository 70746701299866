import { Component } from "react";
import { LocalStorageHelpers } from "@ai360/core";

export class ReceiveAuthentication extends Component {
    private parseQueryString(queryString: string): [string, Record<string, any>] {
        const stateParameter = "state";

        const searchParameters = new URLSearchParams(queryString);
        if (!searchParameters.has(stateParameter)) {
            console.error(
                "Received authentication does not contain state. Cannot determine which key to use for local storage."
            );
            return null;
        }

        const state = searchParameters.get(stateParameter);
        const entries = [...searchParameters.entries()].filter(([key]) => key !== stateParameter);

        return [state, Object.fromEntries(entries)];
    }

    componentDidMount(): void {
        const parsed = this.parseQueryString(window.location.search);
        if (parsed != null) {
            const [state, value] = parsed;
            LocalStorageHelpers.set(state, JSON.stringify(value));
        }
    }

    render(): JSX.Element {
        return null;
    }
}
