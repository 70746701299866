import React, { Component } from "react";
import PropTypes from "prop-types";
import { NoLink } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "./../i18n-messages";
import "./component.css";

export class ActiveInactiveLink extends Component {
    static propTypes = {
        toggleActiveInactive: PropTypes.func,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showInactive: true,
        };
    }

    toggleActiveInactive = () => {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            () => {
                this.props.toggleActiveInactive();
            }
        );
    };

    getActiveText = (showInactive) => {
        const { formatMessage } = this.props.intl;
        if (showInactive) {
            return formatMessage(messages.showInactiveRecords);
        }
        return formatMessage(messages.hideInactiveRecords);
    };

    render() {
        const { showInactive } = this.state;
        return (
            <div className="active-inactive-header-container">
                <NoLink
                    label={this.getActiveText(showInactive)}
                    onClick={this.toggleActiveInactive}
                />
            </div>
        );
    }
}

export default injectIntl(ActiveInactiveLink);
