import { accordionReducer } from "~/accordion";

import { SAMPLE_SOIL_STATE_KEY } from "./components/event-sample-soil/selectors";
import { SAMPLE_TISSUE_STATE_KEY } from "./components/event-sample-tissue/selectors";
import { SCOUTING_STATE_KEY } from "./components/event-scouting/selectors";
import { sampleSoilReducer } from "./components/event-sample-soil/reducer";
import { sampleTissueReducer } from "./components/event-sample-tissue/reducer";
import { scoutingReducer } from "./components/event-scouting/reducer";

import * as actions from "./actions";
import * as selectors from "./selectors";

export const eventInfoInitialState = {
    isLoading: false,
    eventFilterData: null,
    eventSummary: null,
    eventInfoPicklists: {},
    ecDataLoading: false,
    showEcData: false,
    inFlightCollapsingEvents: [],
    [SAMPLE_SOIL_STATE_KEY]: sampleSoilReducer(undefined, { type: "INIT" }),
    [SAMPLE_TISSUE_STATE_KEY]: sampleTissueReducer(undefined, { type: "INIT" }),
    [SCOUTING_STATE_KEY]: scoutingReducer(undefined, { type: "INIT" }),
    [selectors.ACCORDION_KEY]: accordionReducer(undefined, { type: "INIT" }),
    [selectors.DROPDOWNS_KEY]: {
        brandOrgData: [],
        cropData: [],
        priceUnit: [],
        varietyHybridData: [],
        productData: [],
    },
    myAgDataCommodities: new Map(),
};

export const eventInfoReducer = (state = eventInfoInitialState, action) => {
    switch (action.type) {
        case actions.FETCHED_DROPDOWN_DATA:
            return Object.freeze({
                ...state,
                [selectors.DROPDOWNS_KEY]: {
                    ...state[selectors.DROPDOWNS_KEY],
                    ...action.payload,
                },
            });
        case actions.SET_EC_DATA_LOADING: {
            const { ecDataLoading } = action.payload;
            return Object.freeze({
                ...state,
                ecDataLoading,
            });
        }
        case actions.SET_EVENT_FILTER_DATA: {
            const { eventFilterData } = action.payload;
            return Object.freeze({
                ...state,
                eventFilterData,
            });
        }
        case actions.SET_EVENT_SUMMARY: {
            const { eventSummary } = action.payload;
            return Object.freeze({
                ...state,
                eventSummary,
            });
        }
        case actions.SET_EVENT_DETAILS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case actions.SET_SHOW_FILTER_POINTS: {
            const { showFilterPoints } = action.payload;
            return Object.freeze({
                ...state,
                eventFilterData: {
                    ...state.eventFilterData,
                    showFilterPoints,
                },
            });
        }
        case actions.SHOW_EC_DATA_INFO: {
            const { surfaceGuid } = action.payload;
            const showEcData = surfaceGuid && surfaceGuid !== "" ? true : false;
            return Object.freeze({
                ...state,
                showEcData,
            });
        }
        case actions.UPDATE_EVENT_INFO_PICKLISTS: {
            const { eventInfoPicklists } = action.payload;
            return Object.freeze({
                ...state,
                eventInfoPicklists: {
                    ...state.eventInfoPicklists,
                    ...eventInfoPicklists,
                },
            });
        }
        case actions.ADD_IN_FLIGHT_COLLAPSING_EVENTS: {
            const { eventSummaries } = action.payload;

            const concatenated = state.inFlightCollapsingEvents.concat(eventSummaries);

            return Object.freeze({
                ...state,
                inFlightCollapsingEvents: concatenated,
            });
        }
        case actions.REMOVE_IN_FLIGHT_COLLAPSING_EVENTS: {
            const { agEventGeneralGuids } = action.payload;

            const filtered = state.inFlightCollapsingEvents.filter(
                (eventSummary) => !agEventGeneralGuids.includes(eventSummary.agEventGeneralGuid)
            );

            return Object.freeze({
                ...state,
                inFlightCollapsingEvents: filtered,
            });
        }
        case actions.FETCH_MYAGDATA_COMMODITIES_SUCCESS: {
            return Object.freeze({
                ...state,
                myAgDataCommodities: action.payload ?? [],
            });
        }
        default: {
            const accordionState = accordionReducer(state[selectors.ACCORDION_KEY], action);
            if (accordionState !== state[selectors.ACCORDION_KEY]) {
                state = Object.freeze({
                    ...state,
                    [selectors.ACCORDION_KEY]: accordionState,
                });
            }
            const sampleSoilState = sampleSoilReducer(state[SAMPLE_SOIL_STATE_KEY], action);
            if (sampleSoilState !== state[SAMPLE_SOIL_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [SAMPLE_SOIL_STATE_KEY]: sampleSoilState,
                });
            }
            const sampleTissueState = sampleTissueReducer(state[SAMPLE_TISSUE_STATE_KEY], action);
            if (sampleTissueState !== state[SAMPLE_TISSUE_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [SAMPLE_TISSUE_STATE_KEY]: sampleTissueState,
                });
            }
            const scoutingState = scoutingReducer(state[SCOUTING_STATE_KEY], action);
            if (scoutingState !== state[SCOUTING_STATE_KEY]) {
                state = Object.freeze({
                    ...state,
                    [SCOUTING_STATE_KEY]: scoutingState,
                });
            }
            return state;
        }
    }
};
