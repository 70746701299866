import PropTypes from "prop-types";
import { model } from "~/admin/agBytes/variety-hybrid/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_VARIETY_HYBRID_ID]: PropTypes.number,
    [model.PROPS_VARIETY_HYBRID_GUID]: PropTypes.string,
    [model.PROPS_VARIETY_HYBRID_NAME]: PropTypes.string,
    [model.PROPS_CROP_NAME]: PropTypes.string,
    [model.PROPS_CROP_CLASS_NAME]: PropTypes.string,
    [model.PROPS_VARIETY_HYBRID_BRAND]: PropTypes.string,
    [model.PROPS_VARIETY_HYBRID_GMO]: PropTypes.bool,
    [model.PROPS_VARIETY_HYBRID_TRAIT_NAME]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
};

export default PropTypes.shape(shape);
