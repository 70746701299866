import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { config as intlConfig } from "~/intl-provider/config";
import { DialogBox, DialogBoxFooterType, TextInput } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../../i18n-messages";
import { codeToErrorMessage } from "~/i18n-error-messages";
import "./append-select-modal.css";
import { keywords } from "~/action-panel/components/import-module/keywords.js";

export class AppendSelectModal_ extends Component {
    static propTypes = {
        appendList: PropTypes.array.isRequired,
        eventStripPreviewData: PropTypes.array.isRequired,
        onCancelEvent: PropTypes.func.isRequired,
        onCreateEvent: PropTypes.func.isRequired,
        setAppendList: PropTypes.func.isRequired,
        setEventStripPreviewData: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            mergeError: null,
            selectedCurrentEventIndex: props.appendList.length > 0 ? 0 : -1,
            selectedAppendToIndex: -1,
            showEditEventNameModal: false,
            showMergeErrorModal: false,
            editEventName: "",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.appendList.length > 0 && this.props.appendList.length === 0) {
            this.setState({
                selectedCurrentEventIndex: 0,
                selectedAppendToIndex: -1,
                mergeError: null,
            });
        } else if (JSON.stringify(nextProps.appendList) !== JSON.stringify(this.props.appendList)) {
            this.setState({
                selectedCurrentEventIndex: nextProps.appendList.length > 0 ? 0 : -1,
                selectedAppendToIndex: -1,
                mergeError: null,
            });
        }
    }

    _selectCurrentEvent = (index) => {
        if (this.state.selectedCurrentEventIndex !== index) {
            this.setState({
                selectedCurrentEventIndex: index,
                selectedAppendToIndex: -1,
                mergeError: null,
            });
        } else {
            this.setState({
                selectedCurrentEventIndex: -1,
                selectedAppendToIndex: -1,
                mergeError: null,
            });
        }
    };

    _selectAppendToEvent = (index) => {
        const { selectedCurrentEventIndex, selectedAppendToIndex } = this.state;
        const { appendList } = this.props;
        if (selectedAppendToIndex !== index) {
            const selectedCurrentEvent = {
                ...appendList[selectedCurrentEventIndex],
            };
            const selectedAppendEvent = selectedCurrentEvent.agEventGeneralConflicts[index];
            this.setState({
                selectedAppendToIndex: index,
                mergeError: selectedAppendEvent.mergeError ? selectedAppendEvent.mergeError : null,
            });
        } else {
            this.setState({
                selectedAppendToIndex: -1,
                mergeError: null,
            });
        }
    };

    _getEventListSection = (isAppend) => {
        const { formatMessage, formatDate } = this.props.intl;
        const selectedIndex = !isAppend
            ? this.state.selectedCurrentEventIndex
            : this.state.selectedAppendToIndex;
        const list = !isAppend
            ? this.props.appendList
            : this.state.selectedCurrentEventIndex !== -1
            ? this.props.appendList[this.state.selectedCurrentEventIndex].agEventGeneralConflicts
            : [];
        const onSelect = !isAppend ? this._selectCurrentEvent : this._selectAppendToEvent;

        return (
            <div className="append-list-section">
                <div className="append-event-list-title">
                    {!isAppend
                        ? formatMessage(messages.currentEventTitle)
                        : formatMessage(messages.appendToTitle)}
                </div>
                <div className="append-event-list">
                    <div className="append-row append-header-row">
                        <div className="append-cell">{formatMessage(messages.eventDate)}</div>
                        <div className="append-cell">{formatMessage(messages.croppingSeason)}</div>
                        <div className="append-cell">
                            {!isAppend
                                ? formatMessage(messages.eventType)
                                : formatMessage(messages.eventName)}
                        </div>
                        <div className="append-cell">{formatMessage(messages.customerName)}</div>
                        <div className="append-cell">{formatMessage(messages.fieldName)}</div>
                        <div className="append-cell">{formatMessage(messages.crop)}</div>
                        <div className="append-cell">{formatMessage(messages.features)}</div>
                    </div>
                    {list.map((event, index) => {
                        const display = event.display ? event.display : event;
                        const isErrorText = isAppend && event.intersectingShapeExists;
                        return (
                            <div
                                key={"event-" + index}
                                className={classNames("append-row", {
                                    "selected-row": index === selectedIndex,
                                    "error-text": isErrorText,
                                })}
                                onClick={() => {
                                    !isErrorText && onSelect(index);
                                }}
                            >
                                <div className="append-cell">
                                    {formatDate(
                                        event.userLocalTime,
                                        intlConfig.utcDateFormatOptions
                                    )}
                                </div>
                                <div className="append-cell">{display.croppingSeason}</div>
                                <div className="append-cell">
                                    {!isAppend ? display.eventType : display.eventName}
                                </div>
                                <div className="append-cell">{display.customerName}</div>
                                <div className="append-cell">{display.fieldName}</div>
                                <div className="append-cell">{display.cropName}</div>
                                <div className="append-cell">{display.pointCount}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    _canCreate = () => {
        return this.state.selectedCurrentEventIndex !== -1;
    };

    _canMerge = () => {
        return this.state.selectedAppendToIndex !== -1;
    };

    _onCreate = () => {
        this.setState({
            showEditEventNameModal: true,
        });
    };

    getEventType(selectedImportEvent) {
        const { display } = selectedImportEvent;
        let hasProductMixes = false;
        if (display.eventType === keywords.PLANTING) {
            if (selectedImportEvent.varieties) {
                hasProductMixes = selectedImportEvent.varieties.some((x) =>
                    x.productMixes.some((y) => y)
                );
            }
        }
        return hasProductMixes
            ? display.eventType + " + " + keywords.APPLICATION
            : display.eventType;
    }

    _onSaveEditEventNameModal = () => {
        const { formatDate } = this.props.intl;
        const { appendList, onCreateEvent, setAppendList } = this.props;
        const { selectedCurrentEventIndex, editEventName } = this.state;
        const selectedImportEvent = appendList[selectedCurrentEventIndex];
        const { display } = selectedImportEvent;
        const newEvent = {
            ...selectedImportEvent,
            eventName: editEventName,
            display: {
                ...display,
                eventName: `${this.getEventType(selectedImportEvent)} - ${formatDate(
                    selectedImportEvent.userLocalTime,
                    intlConfig.utcDateFormatOptions
                )} - ${editEventName}`,
            },
            status: {
                ...selectedImportEvent.status,
                statusCode: 9,
            },
        };

        this._updateEventPreviewStrip(newEvent);

        const newAppendList = [
            ...appendList.slice(0, selectedCurrentEventIndex),
            ...appendList.slice(selectedCurrentEventIndex + 1),
        ];

        setAppendList(newAppendList);
        onCreateEvent([newEvent]);
        this._onCloseEditEventNameModal();
    };

    _onCloseEditEventNameModal = () => {
        this.setState({
            showEditEventNameModal: false,
            editEventName: "",
        });
    };

    _onCloseMergeErrorModal = () => {
        this.setState({
            showMergeErrorModal: false,
        });
    };

    _onMerge = () => {
        const { formatDate } = this.props.intl;
        const { selectedCurrentEventIndex, selectedAppendToIndex } = this.state;
        const { appendList, onCreateEvent, setAppendList } = this.props;

        if (selectedCurrentEventIndex === -1 || selectedAppendToIndex === -1) {
            return;
        }
        const selectedCurrentEvent = {
            ...appendList[selectedCurrentEventIndex],
        };
        const selectedAppendEvent =
            selectedCurrentEvent.agEventGeneralConflicts[selectedAppendToIndex];
        if (selectedAppendEvent.mergeError) {
            this.setState({
                showMergeErrorModal: true,
            });
            return;
        }
        const { display } = selectedCurrentEvent;
        const mergeEvent = {
            ...selectedCurrentEvent,
            isMerge: true,
            agEventGeneralGuid: selectedAppendEvent.agEventGeneralGuid,
            eventName: selectedAppendEvent.eventName,
            display: {
                ...display,
                eventName: `${display.eventType} - ${formatDate(
                    selectedCurrentEvent.userLocalTime,
                    intlConfig.utcDateFormatOptions
                )} - ${selectedAppendEvent.eventName}`,
            },
            status: {
                statusCode: 9,
                agEventGeneralGuid: selectedAppendEvent.agEventGeneralGuid,
            },
        };

        this._updateEventPreviewStrip(mergeEvent);

        const newAppendList = [
            ...appendList.splice(0, selectedCurrentEventIndex),
            ...appendList.splice(selectedCurrentEventIndex + 1),
        ];
        setAppendList(newAppendList);
        onCreateEvent([mergeEvent]);
    };

    _onClose = () => {
        const { appendList, onCancelEvent, setAppendList } = this.props;
        if (appendList.length > 0) {
            const importFileGuidSet = new Set();
            appendList.forEach((item) => importFileGuidSet.add(...item.importFileGuids));
            onCancelEvent(Array.from(importFileGuidSet));
        }
        setAppendList([]);
    };

    _isValidEventName = () => {
        const { appendList } = this.props;
        const { editEventName, selectedCurrentEventIndex } = this.state;
        if (selectedCurrentEventIndex === -1) {
            return;
        }
        const selectedCurrentEvent = appendList[selectedCurrentEventIndex];
        const duplicateName = selectedCurrentEvent.agEventGeneralConflicts.find((event) => {
            return (
                event.eventName === editEventName &&
                event.eventDate === selectedCurrentEvent.eventDate
            );
        });
        return !duplicateName && editEventName.trim();
    };

    _updateEventPreviewStrip = (updatedImportEvent) => {
        const { eventStripPreviewData, setEventStripPreviewData } = this.props;

        const eventStripIdx = eventStripPreviewData.findIndex(
            (event) =>
                event.fieldGuid === updatedImportEvent.fieldGuid &&
                event.eventDate === updatedImportEvent.eventDate &&
                updatedImportEvent.importFileGuids.some((guid) =>
                    event.importFileGuids.includes(guid)
                )
        );

        const newEventStripList = [
            ...eventStripPreviewData.slice(0, eventStripIdx),
            updatedImportEvent,
            ...eventStripPreviewData.slice(eventStripIdx + 1),
        ];

        setEventStripPreviewData(newEventStripList);
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { appendList } = this.props;
        const {
            editEventName,
            selectedCurrentEventIndex,
            showEditEventNameModal,
            showMergeErrorModal,
            mergeError,
        } = this.state;
        const appendToList =
            selectedCurrentEventIndex === -1
                ? []
                : appendList[selectedCurrentEventIndex].agEventGeneralConflicts;
        const isErrorText = appendToList.some((event) => event.intersectingShapeExists);

        return (
            <DialogBox
                className={classNames("import-append-modal")}
                isOpen={true}
                isModal={true}
                draggable={true}
                title={formatMessage(messages.appendSelectText)}
                footerType={DialogBoxFooterType.MULTI_ACTION_CANCEL}
                unrestricted={true}
                multiActionList={[
                    {
                        action: formatMessage(messages.mergeText),
                        actionDisabled: !this._canMerge(),
                        onAction: () => this._onMerge(),
                    },
                    {
                        action: formatMessage(messages.createText),
                        actionDisabled: !this._canCreate(),
                        onAction: () => this._onCreate(),
                        isCancelStyle: true,
                    },
                ]}
                onClose={() => this._onClose()}
            >
                {this._getEventListSection(false)}
                {this._getEventListSection(true)}

                {!isErrorText ? null : (
                    <div className="error-text">
                        {formatMessage(messages.intersectingShapesText)}
                    </div>
                )}

                <DialogBox
                    className="edit-event-name-modal"
                    isOpen={showEditEventNameModal}
                    isModal={true}
                    draggable={true}
                    title={formatMessage(messages.editEventName)}
                    onAction={() => this._onSaveEditEventNameModal()}
                    actionDisabled={!this._isValidEventName(editEventName)}
                    onClose={() => this._onCloseEditEventNameModal()}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                >
                    <TextInput
                        maxLength={50}
                        autoFocus
                        value={editEventName}
                        onChange={(value) => {
                            this.setState({ editEventName: value });
                        }}
                        required={true}
                        placeholderText={formatMessage(messages.eventName)}
                    />
                </DialogBox>
                <DialogBox
                    className={classNames("import-validation-modal")}
                    isOpen={showMergeErrorModal}
                    isModal={true}
                    draggable={true}
                    title={formatMessage(messages.mergeErrorText)}
                    footerType={DialogBoxFooterType.NONE}
                    onClose={() => {
                        this._onCloseMergeErrorModal();
                    }}
                >
                    <div>{codeToErrorMessage(formatMessage, mergeError)}</div>
                </DialogBox>
            </DialogBox>
        );
    }
}
export const AppendSelectModal = injectIntl(AppendSelectModal_);
