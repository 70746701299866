import { createSelector } from "reselect";

import { selectors as accSelectors } from "~/accordion";

import { getModuleState as getRecModuleState } from "../../selectors";

export const REC_INFO_STATE_KEY = "REC_INFO_STATE";
export const ACCORDION_KEY = "ACCORDION";
export const DROPDOWNS_KEY = "DROPDOWNS";

export const getModuleState = (state) => getRecModuleState(state)[REC_INFO_STATE_KEY];

export const getAccordionState = (state) => getModuleState(state)[ACCORDION_KEY];
export const getDropdownState = (state) => getModuleState(state)[DROPDOWNS_KEY];

export const getAccordionItems = (state) => accSelectors.itemsSelector(getAccordionState(state));
export const getActiveNutrientGuid = (state) => getModuleState(state).activeNutrientGuid;
export const getBatchRecDetailsForEdit = (state) => getModuleState(state).batchRecDetailsForEdit;
export const getEquationGroupList = (state) => getModuleState(state).equationGroupList;
export const getFilteredEquationGroupList = (state) =>
    getModuleState(state).filteredEquationGroupList;
export const getEquationFilterLists = (state) => getModuleState(state).equationFilterLists;
export const getEquationFilterRequired = (state) => getModuleState(state).equationFilterRequired;
export const getEquationsLoading = (state) => getModuleState(state).isEquationsLoading;
export const getIsBatchEditRecsModalActive = (state) =>
    getModuleState(state).isBatchEditRecsModalActive;
export const getIsProcessingRecNutrient = (state) => getModuleState(state).isProcessingRecNutrient;
export const getRecNutrientsInProcessing = (state) =>
    getModuleState(state).recNutrientsInProcessing;
export const getSamplingDepthList = (state) => getModuleState(state).samplingDepthList;

export const getAccordionItemsCount = createSelector(
    getAccordionItems,
    (accItems) => accItems.length
);
