import { LoginPage } from "./components/login";
import { GetMobileApp } from "./components/get-mobile-app";
import { EnrollmentPage } from "./components/enrollment";
import * as selectors from "./selectors";
import * as actions from "./actions";
import { loginReducer } from "./reducer";
import { userSaga } from "./sagas";

const {
    LOGIN_STATE_KEY,
    getLoginBackgroundUrl,
    getFirstName,
    getImageryTileBucketName,
    getLoginState,
    getSystemAreaLimit,
    getTheOwnerBrandName,
    getTheUserCompanyGuid,
    getTheUserGuid,
    getTheUserLockCustomer,
    getUser,
    getUserSystemProperties,
    isProcessing,
} = selectors;

export {
    LOGIN_STATE_KEY,
    LoginPage,
    GetMobileApp,
    EnrollmentPage,
    actions,
    getLoginBackgroundUrl,
    getFirstName,
    getImageryTileBucketName,
    getLoginState,
    getSystemAreaLimit,
    getTheOwnerBrandName,
    getTheUserCompanyGuid,
    getTheUserGuid,
    getTheUserLockCustomer,
    getUser,
    getUserSystemProperties,
    isProcessing,
    loginReducer,
    selectors,
    userSaga,
};
