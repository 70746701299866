import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import { equipmentSetupSagas } from "./controller";
// selectors
import { getTheUserGuid } from "~/login";
import { GET_AGBYTES_EQUIPMENT_URL } from "./service";
import { Request } from "@ai360/core";

function* processAgbytesEquipment({ payload }) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: payload };
    try {
        const response = yield call(Request.post, GET_AGBYTES_EQUIPMENT_URL, requestOptions);
        yield put(actions.fetchAgbytesEquipmentSuccess(response));
    } catch (error) {
        yield put(actions.fetchAgbytesEquipmentFailed());
    }
}

export function* watchImportFileTemplate() {
    yield takeEvery(actions.fetchAgbytesEquipmentData, processAgbytesEquipment);
}

const EquipmentSagas = function* () {
    yield all([fork(watchImportFileTemplate), fork(equipmentSetupSagas)]);
};

export default EquipmentSagas;
