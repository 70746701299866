export const ACTION_PANEL_STATE_KEY = "ACTION_PANEL_STATE";
export const PANEL_KEY = "PANEL";

export const getIsExpanded = (state) => {
    return state[ACTION_PANEL_STATE_KEY][PANEL_KEY].isExpanded;
};
export const getIsEnabled = (state) => {
    return state[ACTION_PANEL_STATE_KEY][PANEL_KEY].isEnabled;
};
export const getActiveModule = (state) => {
    return state[ACTION_PANEL_STATE_KEY][PANEL_KEY].activeModule;
};
