import { connect } from "react-redux";
import { OrgLevel as OrgLevelView } from "./org-level";
import { actions, selectors } from "./data";
import { getTheUserCompanyGuid } from "~/login/selectors";
import { getOrgLevelData } from "~/hocs/crud/selectors";

const mapStateToProps = (state) => ({
    parentLevelGuid: selectors.getParentLevelGuid(state),
    companyGuid: getTheUserCompanyGuid(state),
    parentGridData: selectors.getParentGridData(state),
    orgLevelData: getOrgLevelData(state),
    hierarchyFilterList: selectors.getHierarchyFilterList(state),
    orgLevelWithGuid: selectors.getOrgLevelWithGuid(state),
    orgLevelGridData: selectors.getOrgLevelGridData(state),
});

const mapDispatchToProps = () => ({
    // Note: These actions are to be dispatched using needs hoc
    addOrgLevel: (payload) => actions.addOrgLevel(payload),
    getAffiliationsParent: (payload) => actions.getAffiliationsParent(payload),
    setNewParentLocation: (payload) => actions.setNewParentLocation(payload),
    getOrgLevelData: (payload) => actions.getOrgLevelData(payload),
    fetchCompanyRecord: (payload) => actions.fetchCompanyRecord(payload),
    getHierarchyFilterListData: (payload) => actions.getHierarchyFilterListData(payload),
    getOrgLevelWithGuidData: (payload) => actions.getOrgLevelWithGuidData(payload),
    getOrgLevelChildrenData: (payload) => actions.getOrgLevelChildrenData(payload),
    updateOwner: (payload) => actions.updateOwner(payload),
    updateOrgLevel: (payload) => actions.updateOrgLevel(payload),
});

export const OrgLevel = connect(mapStateToProps, mapDispatchToProps)(OrgLevelView);
