import { IAgEventModelMethods } from "../model";

export class AgEventPlanting implements IAgEventModelMethods {
    agEventGuid = "";
    cropAgeWhenPlanted: any = null;
    cropClassName: any = null;
    cropGuid = "";
    cropName = "";
    cropPurposeGuid = "";
    cropPurposeName = "";
    eventPlantingVarietyHybridList: any[] = [];
    isImportedYn = false;
    plantSpacing: any = null;
    planterSections: any[] = [];
    plantingDepth: any = null;
    plantingDepthIaGuid: any = null;
    rowOrientationGuid: any = null;
    rowSpacing: any = null;
    rowSpacingIaGuid: any = null;
    transplantYn = false;
    plantSpacingIaGuid: any = null;
    transplantTypeGuid: any = null;
    plantingPatternGuid: any = null;
    cropAgeIaGuid: any = null;
    commodityGuid: string | null = null;
    commodityTypeGuid: string | null = null;
    intendedUseGuid: string | null = null;
    croppingPracticeGuid: string | null = null;

    get isAllRequiredFieldsSet(): boolean {
        return Boolean(this.cropGuid && this.checkImportedVH());
    }

    get isAnyRequiredFieldSet(): boolean {
        return Boolean(this.cropGuid && this.cropGuid.length);
    }

    checkImportedVH(): boolean {
        if (
            !this.isImportedYn ||
            !this.eventPlantingVarietyHybridList ||
            this.eventPlantingVarietyHybridList.length <= 0
        ) {
            return true;
        }
        return Boolean(
            this.eventPlantingVarietyHybridList.every(
                (vh) => vh.brandOrganizationGuid && vh.varietyHybridGuid
            )
        );
    }
    updateAgEventModel(newProps: Partial<AgEventPlanting>): AgEventPlanting {
        return Object.assign(new AgEventPlanting(), this, newProps);
    }
}
