import { REQUEST_PAYLOAD_ACTIVE_ONLY } from "~/admin/data";
import { createService } from "~/hocs/crud";
import { aggregateUrlGenerator } from "@ai360/core";

import * as model from "./model";

const AGGREGATE_NAME = "countrystate";
const ENDPOINT_NAME = "agbytes/countrystate";
const API_VERSION = "v1.0";

const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const URL = aggregateUrl("list");
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const EXPORT_FILE_NAME = "CountryStateExport";
export const SELECT_ALL = aggregateUrl("selectAll");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "stateFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "stateSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "statePageOptions";

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        stateId: "",
        countryName: "",
        stateName: "",
        stateAbbreviation: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            FieldName: "CountryName",
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "CountryName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const service = createService({
    id: model.PROPS_STATE_ID,
    guid: model.PROPS_STATE_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    activeColumnName: model.PROPS_ACTIVE_YN,
    urls: {
        URL,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
    },
    _defaultLabels: {
        [model.PROPS_STATE_ID]: { label: "stateId", gridCol: 10 },
        [model.PROPS_COUNTRY_NAME]: { label: "countryName", gridCol: 20 },
        [model.PROPS_STATE_ABBREVIATION]: {
            label: "stateAbbreviation",
            gridCol: 10,
        },
        [model.PROPS_STATE_NAME]: { label: "stateName" },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 10,
            className: "col-shift-15",
            visible: false,
            sortNameOverRide: "isActive",
        },
    },
    defaultSort,
    useNewApi: true,
});
