import PropTypes from "prop-types";
import _ from "lodash";
import Point from "@arcgis/core/geometry/Point";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import {
    LayerAPI,
    FieldAPI,
    AppHelpers,
    JSONUtils,
    GeometryMath,
    GeometryUtils,
    ListenerManager,
    SymbolUtils,
} from "@ai360/core";
import Polygon from "@arcgis/core/geometry/Polygon";
import * as utils from "./imageUtils";

export const LinkedMapGroupManagerType = PropTypes.shape({
    addMap: PropTypes.func.isRequired,
    deleteMap: PropTypes.func.isRequired,
    getLayer: PropTypes.func.isRequired,
    getLayerInfo: PropTypes.func.isRequired,
    setFieldInfo: PropTypes.func.isRequired,
    setLayerInfo: PropTypes.func.isRequired,
});

export class LinkedMapGroupManager {
    constructor() {
        this._listeners = new Map();
        this.maps = new Map();
        this.mgrsCells = new Map();
        this.dggData = new Map();
        this.clearSurfaceDggInfo = this.clearSurfaceDggInfo.bind(this);
        this.userDefined = null;
        this.mapDisplayCount = 0;
        this.mgrss = [];
        this.userDefinedPolygons = [];
        this.userDefinedMGRS = [];
        this.mapHasZonePolygon = false;
        this.mapHasFreeHandPolygon = false;
        this.randomColors = utils.generateRandomBrightColors();
        this.selectedColors = [];
        this.clearFreeHandPolygon = false;
    }

    _cleanupListeners(mapId) {
        let listeners = this._listeners.get(mapId);
        if (listeners != null) {
            listeners.removeAll();
        } else {
            listeners = new ListenerManager();
            this._listeners.set(mapId, listeners);
        }
        return listeners;
    }

    _cloneFeatures() {
        const features = [];
        for (var feat of this.features) {
            features.push(feat.clone());
        }
        return features;
    }

    _onSyncExtentUpdate(mapId, extent) {
        if (this.isLoading || extent == null) {
            return;
        }
        for (var [key, lm] of this.maps.entries()) {
            if (key !== mapId) {
                lm.mapView.extent = extent;
            }
        }
    }

    _setExtentForAll(extent) {
        this.isLoading = true;
        for (var lm of this.maps.values()) {
            lm.mapView.extent = extent;
        }
        setTimeout(() => {
            this.isLoading = false;
        }, 250);
    }

    _setMapFieldFeatures() {
        if (
            (this.mapsLoadedCount !== this.mapDisplayCount && this.mapsLoadedCount !== 4) ||
            this.maps.size !== this.mapCount ||
            !this.isNewField ||
            this.features == null ||
            this.fieldGuid == null
        ) {
            return;
        }

        const fieldExtent = geometryEngine
            .union(
                this.features.map((feat) => {
                    return feat.geometry;
                })
            )
            .extent.expand(1.5);
        for (let i = 0; i < this.maps.size; i++) {
            const lm = Array.from(this.maps.values())[i];
            if (i >= this.mapDisplayCount) {
                this._cleanupListeners(lm.mapView.id);
                continue;
            }
            lm.setFieldFeatures(this.fieldGuid, this._cloneFeatures());
            lm.mapView.extent = fieldExtent;
            this._setupListeners(lm.mapView);
            this._setupResizeListener(lm.mapView, fieldExtent);
        }

        this.isNewField = false;
        this.isLoading = false;
    }

    _setupListeners(mapView) {
        const listeners = this._cleanupListeners(mapView.id);

        const move = () => {
            this._onSyncExtentUpdate(mapView.id, mapView.extent);
        };

        const scale = AppHelpers.debounce(() => {
            this._onSyncExtentUpdate(mapView.id, mapView.extent);
        }, 1);

        listeners.add(mapView.watch("scale", scale));
        listeners.add(mapView.on("drag", move));
    }

    _setupResizeListener(mapView, fieldExtent) {
        const listeners = this._listeners.get(mapView.id);
        if (listeners) {
            listeners.add(
                mapView.on("resize", () => {
                    clearTimeout(this.resizeTimer);
                    this.resizeTimer = setTimeout(() => {
                        this._setExtentForAll(fieldExtent);
                    }, 250);
                })
            );
        }
    }

    addMap(linkedMap) {
        this.maps.set(linkedMap.mapView.id, linkedMap);
    }

    addSurfaceDgg(surface) {
        if (this.dggData.has(surface.rendererGuid)) {
            return Promise.resolve();
        }
        this.dggData.set(surface.rendererGuid, []);

        return LayerAPI.getSurfaceDGGInfo([surface.rendererGuid])
            .then((results) => {
                for (const mgrs in results.mgrsCells) {
                    if (!this.mgrsCells.has(mgrs)) {
                        const cellGeometry = GeometryUtils.wktToGeometry(results.mgrsCells[mgrs]);
                        const projectedCell = webMercatorUtils.project(
                            cellGeometry,
                            this.getSpatialReference()
                        );
                        this.mgrsCells.set(mgrs, projectedCell);
                    }
                }
                this.dggData.set(surface.rendererGuid, results.data[surface.rendererGuid]);
            })
            .catch((e) => {
                console.error("Error fetching Dgg Stat Info for Surface", e);
                this.dggData.set(surface.rendererGuid, []);
            });
    }

    clearSurfaceDggInfo() {
        this.mgrsCells.clear();
        this.dggData.clear();
    }

    clearSurfaceDggSummary(polygonIndex) {
        this.setMapHasFreeHandPolygon(false);
        if (
            polygonIndex === -1 ||
            (this.userDefinedPolygons && this.userDefinedPolygons.length === 2)
        ) {
            this.setMapHasZonePolygon(false);
            this.updateMGRSGroup(this._clearUserDefinedPolygons());
        } else {
            this.clearFreeHandPolygon = true;
            this.updateMGRSGroupPolygon(polygonIndex);
            this.clearFreeHandPolygon = false;
        }
    }

    deleteMap(linkedMap) {
        this.maps.delete(linkedMap.map.id);
    }

    getExtent() {
        return this.maps.entries().next().value[1].mapView.extent.toJSON();
    }

    getLayer(surfaceInfo) {
        if (this.layerInfo) {
            for (const layer of this.layerInfo) {
                if (
                    layer.subLayers &&
                    layer.subLayers.find((l) => {
                        return l.surfaceGuid === surfaceInfo.surfaceGuid;
                    })
                ) {
                    return layer;
                }
            }
        }
    }

    getLayerInfo() {
        return this.layerInfo;
    }

    getMGRSCell(mapPoint) {
        if (mapPoint) {
            const { longitude, latitude } = mapPoint;
            const point = new Point({
                x: longitude,
                y: latitude,
                spatialReference: { wkid: 4326 },
            });
            const mgrs = GeometryMath.getGridMgrs(point);
            if (this.mgrsCells.has(mgrs)) {
                return [mgrs, this.mgrsCells.get(mgrs)];
            }
        }
        return [null, null];
    }

    getMGRSCells(geometry) {
        const mgrss = [];
        const cells = [];
        const simplified = geometryEngine.simplify(geometry);
        for (const [mgrs, cell] of this.mgrsCells.entries()) {
            if (geometryEngine.intersects(simplified, cell)) {
                mgrss.push(mgrs);
                cells.push(cell);
            }
        }

        if (cells.length > 0) {
            const graphicProperties = {
                mgrss: new Set(mgrss),
                polygon: geometryEngine.union(cells),
                symbol: null,
                cells: null,
                rawCells: null,
                attributes: { index: -1 },
            };

            return [graphicProperties];
        }

        return [
            {
                mgrss: null,
                cells: null,
            },
        ];
    }

    getMGRSZoneCells(geometry) {
        let geometries = [];
        const geometryCells = [];
        const result = [];
        if (this.userDefinedPolygons != null && this.userDefinedPolygons.length > 0) {
            this.userDefinedPolygons.forEach((g) => {
                let idxAttribute = result.length;
                const results = geometryEngine.cut(g.polygon, geometry);
                if (results.length === 0) {
                    const graphicObject = this.createMgrssFromGeometry(
                        g.polygon,
                        geometryCells,
                        g.symbol,
                        idxAttribute,
                        g.rawMGRS
                    );
                    if (graphicObject) {
                        result.push(graphicObject);
                    }
                } else {
                    const allPolygonParts = GeometryMath.getAllPolygonParts(results);
                    allPolygonParts.forEach((element) => {
                        idxAttribute = result.length;
                        const symbol = this._generateGraphic();
                        const graphicObject = this.createMgrssFromGeometry(
                            element,
                            geometryCells,
                            symbol,
                            idxAttribute,
                            g.rawMGRS
                        );
                        if (graphicObject) {
                            result.push(graphicObject);
                        }
                    });
                }
            });
        } else {
            if (this.mgrsCells.size > 0) {
                const fieldBoundary = geometryEngine.union(
                    this.features.map((feat) => {
                        return feat.geometry;
                    })
                );
                geometries = geometryEngine.cut(fieldBoundary, geometry);
                const allPolygonParts = GeometryMath.getAllPolygonParts(geometries);
                allPolygonParts.forEach((element, index) => {
                    const symbol = this._generateGraphic();
                    const rawmgrs = [];
                    for (const [mgrs] of this.mgrsCells.entries()) {
                        rawmgrs.push(mgrs);
                    }
                    result.push(
                        this.createMgrssFromGeometry(element, geometryCells, symbol, index, rawmgrs)
                    );
                });
            }
        }
        if (result && result.length > 0) {
            return result;
        } else {
            return [null, null];
        }
    }

    _generateGraphic() {
        return SymbolUtils.fromJSON(
            JSONUtils.createSimpleFillSymbolJSON(
                [255, 255, 255, 50],
                this._generateRandomColor(),
                2
            )
        );
    }

    _generateRandomColor() {
        if (this.randomColors.length > 0) {
            const index = Math.floor(Math.random() * this.randomColors.length);
            const selected = this.randomColors[index];
            this.randomColors.splice(index, 1);
            return selected;
        } else {
            return utils.generateNonFixedColors();
        }
    }

    createMgrssFromGeometry(element, geometryCells, symbol, indexPosition, rawmgrs) {
        const simplified = geometryEngine.simplify(element);
        const mgrss = [];
        const cells = [];
        const rawCells = [];
        const rawMGRS = [];
        rawmgrs.forEach((element) => {
            const cell = this.mgrsCells.get(element);
            if (geometryEngine.intersects(simplified, cell)) {
                rawMGRS.push(element);
                rawCells.push(cell);
                if (!geometryCells.includes(cell)) {
                    mgrss.push(element);
                    cells.push(cell);
                }
            }
        });
        if (cells.length > 0) {
            Array.prototype.push.apply(geometryCells, cells);

            return {
                mgrss: new Set(mgrss),
                rawMGRS: new Set(rawMGRS),
                polygon: geometryEngine.union(cells),
                cells: cells,
                rawCells: rawCells,
                symbol: symbol,
                attributes: { index: indexPosition },
            };
        }
        return null;
    }

    getSpatialReference() {
        return this.maps.entries().next().value[1].mapView.spatialReference;
    }

    getUserDefinedPolyJson() {
        const _userDefinedPolygon = [];
        let polyUnion;
        if (this.userDefinedPolygons && this.userDefinedPolygons.length > 0) {
            this.userDefinedPolygons.every((x) => _userDefinedPolygon.push(new Polygon(x.polygon)));
            polyUnion = geometryEngine.union(_userDefinedPolygon);
            const wgs84Poly = webMercatorUtils.project(
                polyUnion,
                new SpatialReference({ wkid: 4326 })
            );
            return wgs84Poly.toJSON();
        }
        return null;
    }

    getUserDefinedPolyJsonForPrint() {
        const polyJsonForPrint = [];
        if (this.userDefinedPolygons && this.userDefinedPolygons.length > 0) {
            for (let index = 0; index < this.userDefinedPolygons.length; index++) {
                const element = this.userDefinedPolygons[index];
                const { polygon } = element;
                const wgs84Poly = webMercatorUtils.project(
                    polygon,
                    new SpatialReference({ wkid: 4326 })
                );
                const symbol = this.userDefinedPolygons[index].symbol;
                polyJsonForPrint.push({ symbol: symbol, polygon: wgs84Poly.toJSON() });
            }
            return polyJsonForPrint;
        }
        return null;
    }

    setFieldInfo(fieldGuid, mapCount) {
        Object.assign(this, { fieldGuid, mapCount });
        if (fieldGuid == null) {
            this.features = null;
            return;
        }
        this.isLoading = true;
        this.isNewField = true;
        this.mapsLoadedCount = 0;

        FieldAPI.fetchFieldBoundariesByFieldGuid([fieldGuid], null, null, true).then(
            (boundaries) => {
                this.features = boundaries.map(FieldAPI.fieldBoundaryToGraphic);
                this._setMapFieldFeatures();
            }
        );
    }

    setLayerInfo(layerInfo) {
        this.layerInfo = layerInfo;
    }

    updateMGRS(graphic, mapId) {
        const mgrs = graphic ? graphic.attributes.mgrs : null;
        if (this.mgrs === mgrs) {
            return;
        }
        this.mgrs = mgrs;
        if (mgrs == null) {
            for (const lm of this.maps.values()) {
                lm.updateCrosshairs(null);
                lm.updateDgg(null);
            }
            return;
        }
        const dggInfo = new Map();
        for (const [rendererGuid, data] of this.dggData.entries()) {
            dggInfo.set(rendererGuid, [data.filter((d) => d.mgrs === mgrs)]);
        }
        for (const lm of this.maps.values()) {
            if (lm.props.id !== mapId) {
                lm.updateCrosshairs(graphic);
            }
            lm.updateDgg(dggInfo);
        }
    }

    updateMGRSGroupPolygon(polygonIndex) {
        const selectedIndex = this.userDefinedPolygons.findIndex(
            (x) => x.attributes.index === polygonIndex
        );
        if (selectedIndex > -1) {
            let mgrss = [];
            let cells = [];
            let rawMGRSS = [];
            let rawCells = [];
            const toRemoveZone = this.userDefinedPolygons[selectedIndex];
            const toRemoveZonePolygon = geometryEngine.union(toRemoveZone.rawCells);
            const filteredList = this.userDefinedPolygons
                .filter((x) => x.attributes.index !== polygonIndex)
                .sort((a, b) => a.attributes.index - b.attributes.index);
            let toMergeIndex;
            for (const geometry of filteredList) {
                const overlapPolygon = geometryEngine.union(geometry.rawCells);
                const isOverlapped = geometryEngine.overlaps(toRemoveZonePolygon, overlapPolygon);
                if (isOverlapped) {
                    mgrss = Array.prototype.concat(
                        Array.from(toRemoveZone.mgrss),
                        Array.from(geometry.mgrss)
                    );
                    rawMGRSS = Array.prototype.concat(
                        Array.from(toRemoveZone.rawMGRS),
                        Array.from(geometry.rawMGRS)
                    );
                    cells = Array.prototype.concat(toRemoveZone.cells, geometry.cells);
                    rawCells = Array.prototype.concat(toRemoveZone.rawCells, geometry.rawCells);
                    toMergeIndex = geometry.attributes.index;
                    this.userDefinedPolygons.splice(selectedIndex, 1);
                    break;
                }
            }
            const results = this.userDefinedPolygons.map((udf, index) => {
                if (udf.attributes.index === toMergeIndex) {
                    return {
                        ...udf,
                        mgrss: new Set(mgrss),
                        rawMGRS: new Set(rawMGRSS),
                        polygon: geometryEngine.union(cells),
                        cells: cells,
                        rawCells: rawCells,
                        attributes: { index: index },
                    };
                } else {
                    return {
                        ...udf,
                        attributes: { index: index },
                    };
                }
            });

            this.updateMGRSGroup(this._clearUserDefinedPolygons());

            for (const lm of this.maps.values()) {
                results.forEach((element) => {
                    if (element) {
                        const graphicProperties = {
                            mgrss: element.mgrss,
                            rawMGRS: element.rawMGRS,
                            polygon: element.polygon,
                            cells: element.cells,
                            rawCells: element.rawCells,
                            symbol: element.symbol,
                            attributes: element.attributes,
                        };
                        lm.updateMGRSGroup(graphicProperties);
                    }
                });
            }
        }
    }

    updateMGRSGroup(graphicProperties, mapId = null, isZoneCreate, addUserDefined = true) {
        const { mgrss, rawMGRS, polygon, cells, rawCells, symbol, attributes } = graphicProperties;
        if (_.isEqual(this.mgrss, mgrss) && mapId) {
            return;
        }
        this.mgrss = mgrss;
        const dggInfo = new Map();
        if (mgrss == null) {
            this.userDefinedPolygons = [];
            for (const lm of this.maps.values()) {
                lm.updateUserDefinedPoly(this._clearUserDefinedPolygons());
                lm.updateDggGroup(null);
            }
            return;
        }

        if (this.shouldIncludeUserDefinedPolygon(mapId, addUserDefined)) {
            this.userDefined = null;
            this.userDefined = { mgrss, rawMGRS, polygon, cells, rawCells, symbol, attributes };
            this.userDefinedPolygons.push(this.userDefined);
        }

        if ((isZoneCreate && symbol !== null) || this.clearFreeHandPolygon) {
            for (const [rendererGuid, data] of this.dggData.entries()) {
                for (const udf of this.userDefinedPolygons) {
                    const mgrssResult = new Set();
                    const udPoly = udf.mgrss;
                    const color = udf.symbol.outline.color;
                    const polyIndex = udf.attributes ? udf.attributes.index : -1;
                    udPoly.forEach((value) => {
                        mgrssResult.add(value);
                    });
                    if (dggInfo.has(rendererGuid)) {
                        dggInfo.get(rendererGuid).push({
                            mgrsData: data.filter((d) => mgrssResult.has(d.mgrs)),
                            color: color,
                            polygonIndex: polyIndex,
                        });
                    } else {
                        dggInfo.set(rendererGuid, [
                            {
                                mgrsData: data.filter((d) => mgrssResult.has(d.mgrs)),
                                color: color,
                                polygonIndex: polyIndex,
                            },
                        ]);
                    }
                }
            }
        } else {
            const mgrssResult = new Set();
            for (const udf of this.userDefinedPolygons) {
                const udPoly = udf.mgrss;
                udPoly.forEach((value) => {
                    mgrssResult.add(value);
                });
            }
            for (const [rendererGuid, data] of this.dggData.entries()) {
                dggInfo.set(rendererGuid, [
                    { mgrsData: data.filter((d) => mgrssResult.has(d.mgrs)), polygonIndex: -1 },
                ]);
            }
        }

        for (const lm of this.maps.values()) {
            if (!this.clearFreeHandPolygon) {
                if (lm.props.id !== mapId) {
                    lm.updateUserDefinedPoly(this._clearUserDefinedPolygons());
                    lm.updateDggGroup(null);
                    for (let index = 0; index < this.userDefinedPolygons.length; index++) {
                        lm.updateUserDefinedPoly({
                            mgrss: this.userDefinedPolygons[index].mgrss,
                            rawMGRS: this.userDefinedPolygons[index].rawMGRS,
                            polygon: this.userDefinedPolygons[index].polygon,
                            symbol: this.userDefinedPolygons[index].symbol,
                            attributes: this.userDefinedPolygons[index].attributes,
                        });
                    }
                }
            }
            lm.updateDggGroup(dggInfo);
        }
    }

    shouldIncludeUserDefinedPolygon = (mapId, addUserDefined) => {
        return (this.clearFreeHandPolygon && mapId === "map1") || addUserDefined;
    };

    setMapHasZonePolygon(hasZonePolygon) {
        this.mapHasZonePolygon = hasZonePolygon;
    }

    setMapHasFreeHandPolygon(hasFreeHandPolygon) {
        this.mapHasFreeHandPolygon = hasFreeHandPolygon;
    }

    _clearUserDefinedPolygons() {
        return {
            mgrss: null,
            polygon: null,
            cells: null,
            symbol: null,
            attributes: null,
        };
    }
}
