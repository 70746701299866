import React, { Component } from "react";
import PropTypes from "prop-types";
import { OrgLevelSearch } from "./org-level-search";
import { ZeroToInfiniteGrid } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "./../i18n-messages";
import { AppHelpers } from "@ai360/core";
import { adminData } from "~/admin/data";

const PROPS_ORG_LEVEL_GUID = "orgLevelGuid";
const PROPS_ORG_LEVEL_NAME = "orgLevelName";
const PROPS_ORG_LEVEL_CITY = "city";
const PROPS_ORG_LEVEL_STATE = "state";
const PROPS_ORG_LEVEL_PARENT = "orgLevelParents";
const PROPS_ORG_LEVEL_PARENT_GUIDS = "orgLevelParentsGuids";
const DELIMITER = " > ";
export const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;

class OrgLevelList_ extends Component {
    static propTypes = {
        apiErrors: PropTypes.array,
        classNames: PropTypes.array,
        deleteConfirmation: PropTypes.func,
        disabled: PropTypes.bool,
        intl: intlShape.isRequired,
        itemList: PropTypes.array,
        onSelectionChange: PropTypes.func.isRequired,
        ownerLevel: PropTypes.object,
        record: PropTypes.array,
        required: PropTypes.bool,
        statePropName: PropTypes.string,
        isUser: PropTypes.bool,
    };
    static defaultProps = {
        disabled: false,
        itemList: [],
        record: [],
        required: true,
        statePropName: PROPS_ORG_LEVEL_STATE,
        isUser: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentOrgLevelList: props.itemList || [],
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.itemList) !== JSON.stringify(this.props.itemList) ||
            JSON.stringify(nextProps.record) !== JSON.stringify(this.props.record)
        ) {
            let convertedArray = [],
                hasOwnerlevel = false;
            if (nextProps.record.length > 0) {
                convertedArray = this.prepareRecords(nextProps.record);
                hasOwnerlevel = convertedArray.some(
                    ({ orgLevelParents }) => orgLevelParents && orgLevelParents.length === 0
                );
            }
            const modifiedItemList =
                JSON.stringify(nextProps.itemList) !== JSON.stringify(this.props.itemList)
                    ? this.prepareItemList(nextProps.itemList)
                    : nextProps.itemList;
            const itemListWithoutOwnerLevelAsParent = AppHelpers.parseOrgLevelList(
                modifiedItemList,
                PROPS_ORG_LEVEL_PARENT,
                DELIMITER,
                nextProps.ownerLevel
            );
            this.setState({
                currentOrgLevelList: this.removesOwnerLevel(
                    this.getSelectedOptionList(
                        itemListWithoutOwnerLevelAsParent,
                        convertedArray,
                        false
                    ),
                    hasOwnerlevel
                ),
            });
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.itemList.length === 1) {
            this.setState({
                currentOrgLevelList: [],
            });
        }
    }

    removesOwnerLevel = (itemList, hasOwnerlevel) => {
        return hasOwnerlevel
            ? itemList.filter(({ orgLevelParentsGuids }) => orgLevelParentsGuids.length !== 0)
            : itemList;
    };

    prepareItemList = (itemList) => {
        return itemList.map((item) => {
            let parentList = item[PROPS_ORG_LEVEL_PARENT];
            if (typeof parentList === "string") {
                item[PROPS_ORG_LEVEL_PARENT] = parentList;
                parentList = item[PROPS_ORG_LEVEL_PARENT_GUIDS] || [];
            } else if (parentList.length) {
                item[PROPS_ORG_LEVEL_PARENT] = parentList.map(({ name }) => name);
            }
            item[PROPS_ORG_LEVEL_PARENT_GUIDS] = parentList.map(({ guid }) => guid);
            return item;
        });
    };

    // Restrict the delete for non-affliated locations for the current user
    prepareAffiliatedRecords(records) {
        return records.map((record) => {
            const hasAccess = this.props.itemList.some(
                (it) => it.orgLevelGuid === record[PROPS_ORG_LEVEL_GUID]
            );
            if (!hasAccess) {
                return { ...record, restrictEditDelete: true };
            }
            return record;
        });
    }

    // Convert state object in the record to state abbrevation string
    prepareRecords(records) {
        if (PROPS_ORG_LEVEL_STATE !== this.props.statePropName) {
            return records.map((record) => {
                let newRecord = { ...record };
                if (!record[this.props.statePropName]) {
                    newRecord[this.props.statePropName] = record[PROPS_ORG_LEVEL_STATE];
                }
                return newRecord;
            });
        }
        return records;
    }

    getSelectedOptionList = (itemList, selectedItems, selected = true) => {
        const optionList = itemList.filter((item) => {
            const match = selectedItems.some((selectedItem) => {
                return selectedItem[PROPS_ORG_LEVEL_GUID] === item[PROPS_ORG_LEVEL_GUID];
            });
            return (selected && match) || (!selected && !match);
        });
        return optionList || [];
    };

    onChange = (item, isAdd) => {
        if (item == null) {
            return;
        }
        const { record, itemList } = this.props;
        const value = item[PROPS_ORG_LEVEL_GUID];
        let selectedValues = [...record];
        let selectedOrgLevelIndex = -1;
        let selectedOrgLevelIndexArr = [];
        const isAlreadyInList = selectedValues.some((orgLevel, index) => {
            if (orgLevel[PROPS_ORG_LEVEL_GUID] === value) {
                selectedOrgLevelIndex = index;
            }
            return orgLevel[PROPS_ORG_LEVEL_GUID] === value;
        });
        const removedOrgLevel = selectedValues[selectedOrgLevelIndex];
        selectedValues.forEach((orgLevel, index) => {
            let modifiedOrgLevel = orgLevel;
            if (!orgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS]) {
                modifiedOrgLevel = itemList.find(
                    ({ orgLevelGuid }) => orgLevelGuid === orgLevel[PROPS_ORG_LEVEL_GUID]
                );
            }
            // Remove the existing locations if - new location is child or parent of the existing location
            // Ignores the check, if the current user doesn't have access to the exisiting location
            if (
                isAdd &&
                modifiedOrgLevel &&
                (!modifiedOrgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS].length || // Removes highest orgLevel
                    modifiedOrgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS].some(
                        (guid) => guid === item[PROPS_ORG_LEVEL_GUID]
                    ))
            ) {
                // Removes child when parent is selected
                selectedOrgLevelIndexArr.push(index);
            }
        });
        const newOrgLevel = itemList.find((orgLevel) => {
            return orgLevel[PROPS_ORG_LEVEL_GUID] === value;
        });
        if (isAdd && newOrgLevel[PROPS_ORG_LEVEL_PARENT_GUIDS].length === 0) {
            //if the highest orglevel is selected, it should be the only value in the list
            selectedValues = [newOrgLevel];
        } else if (isAdd && selectedOrgLevelIndexArr.length) {
            selectedValues = selectedValues.filter(
                (items, index) => !selectedOrgLevelIndexArr.includes(index)
            );
            selectedValues.push(newOrgLevel);
        } else if (isAdd && !isAlreadyInList) {
            selectedValues.push(newOrgLevel);
        } else {
            selectedValues.splice(selectedOrgLevelIndex, 1);
        }
        selectedValues = this.prepareRecords(selectedValues);
        this.props.onSelectionChange(selectedValues, removedOrgLevel);
    };

    render() {
        const {
            apiErrors,
            classNames,
            deleteConfirmation,
            disabled,
            itemList,
            record,
            required,
            isUser,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { currentOrgLevelList, selectedValue } = this.state;
        const { onChange } = this;
        return (
            <div className={"form-section-child-stretch mini-grid auto-search-list-container"}>
                {currentOrgLevelList.length === 0 || isUser ? null : (
                    <OrgLevelSearch
                        disabled={disabled}
                        classNames={classNames}
                        apiErrors={apiErrors}
                        clearOnSelection
                        containerClassName={"org-level-search-container"}
                        itemList={currentOrgLevelList}
                        onSelection={(value) => this.onChange(value, true)}
                        placeholderText={formatMessage(messages.addLocation)}
                        required={required}
                        selectedValue={selectedValue}
                    />
                )}
                {!(record && record.length > 0) ? null : (
                    <ZeroToInfiniteGrid
                        disabled={disabled}
                        records={this.prepareAffiliatedRecords(record)}
                        columns={{
                            [PROPS_ORG_LEVEL_NAME]: {
                                title: formatMessage(messages.name),
                                className: "org-level-name",
                            },
                            [PROPS_ORG_LEVEL_CITY]: {
                                title: formatMessage(messages.city),
                                className: "org-level-city",
                            },
                            [this.props.statePropName]: {
                                title: formatMessage(messages.state),
                                className: "org-level-state",
                            },
                        }}
                        className="org-level-grid"
                        onDelete={
                            itemList.length === 1 || isUser
                                ? false
                                : deleteConfirmation
                                ? (option) => deleteConfirmation(option, onChange)
                                : (option) => onChange(option, false)
                        }
                    />
                )}
            </div>
        );
    }
}

export const OrgLevelList = injectIntl(OrgLevelList_);
