import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { Menu, NoLink, NumericInput, DialogBox, DialogBoxFooterType } from "~/core";

import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";

import { setEventRecCustomProductGuids } from "../../../../../common/product-blend-modal/actions";
import { getSetValuesForErrorCodeList } from "../../../../../common/validation-utils";

import { ApplicationIcon } from "~/core/icons";
import { config as intlConfig } from "~/intl-provider/config";

import { ProductBlendModal } from "~/action-panel/components/common/product-blend-modal";
import * as blendingModels from "~/action-panel/components/common/product-blend-modal/model";

import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";
import * as actions from "../../actions";

import "../../../../../common/rec-event-info/rec-event-info.css";
import "./event-application-form.css";

const messages = defineMessages({
    addProductMixText: {
        id: "eventModule.eventInfo.addProductMixText",
        defaultMessage: "+ Add Product Mix",
    },
    applicationFormLabelText: {
        id: "eventModule.eventInfo.applicationFormLabelText",
        defaultMessage: "Application",
    },
    editProductMixText: {
        id: "eventModule.eventInfo.editProductMixText",
        defaultMessage: "Edit Product Mix",
    },
    mixText: {
        id: "eventModule.eventInfo.mixText",
        defaultMessage: "Mix",
    },
    overwriteBlendRatesText: {
        id: "eventModule.eventInfo.overwriteBlendRatesText",
        defaultMessage:
            "Since this adjusted Blend is present on other Zones, this will reset all other Zones to the same adjusted Blend and rates as this Zone. Do you want to continue with these changes?",
    },
    removeProductMixText: {
        id: "eventModule.eventInfo.removeProductMixText",
        defaultMessage: "Remove Product Mix",
    },
    resetRatesText: {
        id: "eventModule.eventInfo.resetRatesText",
        defaultMessage: "Reset Other Zones",
    },
});

export const formLabelMessage = messages.applicationFormLabelText;
export const formLabelIcon = ApplicationIcon;

const errorCodeToMessageIdSetMap = new Map([[]]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class EventApplicationForm_ extends PureComponent {
    static propTypes = {
        classBreaksCount: PropTypes.number.isRequired,
        classBreaksHaveOnlyOneZone: PropTypes.bool.isRequired,
        agEventModel: PropTypes.object,
        fieldGuid: PropTypes.string,
        isBatchTemplate: PropTypes.bool,
        isFromEquationRec: PropTypes.bool,
        calculatedArea: PropTypes.number,
        onBatchUpdateProductBlend: PropTypes.func,
        onUpdateCurrentAgEventAreaAgEventModel: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        eventAreaId: PropTypes.number,
        eventDetails: PropTypes.object,
        saveEventDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        setCustomProductGuids: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalProductMixList: [],
            isSharedBlend: false,
            confirmSharedBlendOverwriteModalOpen: false,
            isBlendCleared: false,
        };
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveEventDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _updateEvent(newProps) {
        this.props.onUpdateCurrentAgEventAreaAgEventModel(newProps);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.saveEventDetailsErrorCodeList !== this.props.saveEventDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(nextProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    _getMenuItems(index) {
        const { formatMessage } = this.props.intl;
        const { productMixList } = this.props.agEventModel;
        const { isFromEquationRec } = this.props;
        const isImport = productMixList && productMixList.length && productMixList[0].isImport;
        return [
            {
                label: formatMessage(messages.editProductMixText),
                action: () => this.onAddEditProductMix(index),
            },
            {
                label: formatMessage(messages.removeProductMixText),
                action: () => this.onRemoveProductMix(index),
                disabled: isImport || (isFromEquationRec && productMixList?.length <= 1),
            },
        ].map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    _updateTargetRate(index, newTargetRate) {
        const { agEventModel, calculatedArea } = this.props;
        const { productMixList } = agEventModel;
        const newProductMixList = productMixList.map((mix, mixIndex) => {
            if (
                (mixIndex === index &&
                    blending.roundValue(newTargetRate, 2) !==
                        blending.roundValue(mix.targetRate, 2)) ||
                (mixIndex === index &&
                    !mix.isImport &&
                    blending.roundValue(newTargetRate, 2) !==
                        blending.roundValue(mix.actualRate, 2))
            ) {
                const lineRatio = newTargetRate / mix.targetRate;
                const newCostPerAcre = mix.costPerAcre * lineRatio;
                const newTotalProduct = mix.totalProduct * lineRatio;
                const newTotalCost = mix.totalCost * lineRatio;
                return {
                    ...mix,
                    actualRate: newTargetRate,
                    targetRate: newTargetRate,
                    costPerAcre: newCostPerAcre,
                    totalProduct: newTotalProduct,
                    totalCost: newTotalCost,
                    products: mix.products.map((product) => {
                        const rate = product.rate * lineRatio;
                        const totalProduct = product.totalProduct * lineRatio;
                        const totalCost = totalProduct * product.cost;
                        const costPerAcre = totalCost / calculatedArea;
                        return {
                            ...product,
                            rate,
                            costPerAcre,
                            totalProduct,
                            totalCost,
                        };
                    }),
                };
            }
            return mix;
        });
        this._updateEvent({ productMixList: newProductMixList });
    }

    onAddEditProductMix(index = -1) {
        const isAdd = index === -1;
        const { agEventModel, eventDetails } = this.props;
        let mixIndex = index;
        const originalProductMixList = [...agEventModel.productMixList];

        if (isAdd) {
            const productMixList = [
                ...agEventModel.productMixList,
                new blendingModels.ProductMix(agEventModel.agEventGuid),
            ];
            this._updateEvent({ productMixList });
            mixIndex = productMixList.length - 1;
        }
        const eventRecCustomProductGuids =
            agEventModel.productMixList[mixIndex]?.products.map((p) => p.customProductGuid) || [];
        this.props.setCustomProductGuids(eventRecCustomProductGuids);

        const eventAnalyses =
            eventDetails.eventAreaList
                ?.flatMap((ra) =>
                    ra.agEventList?.flatMap((r) =>
                        r.agEventModel.productMixList?.flatMap((rpm) => rpm.guaranteedAnalysis)
                    )
                )
                .filter((ga) => ga) || [];

        const isSharedBlend =
            eventAnalyses.filter(
                (ga) => ga === agEventModel.productMixList[mixIndex]?.guaranteedAnalysis
            ).length > 1;

        this.setState({
            isProductBlendingActive: true,
            activeProductBlend: mixIndex,
            originalProductMixList,
            isSharedBlend,
        });
    }

    onUpdateProductBlending(productMix) {
        const { originalProductMixList, activeProductBlend } = this.state;

        const productMixList = [...this.props.agEventModel.productMixList];
        productMixList.splice(this.state.activeProductBlend, 1, productMix);

        if (
            originalProductMixList[activeProductBlend]?.products.length &&
            !productMix.products.length
        ) {
            this.setState({ isBlendCleared: true });
        }

        this._updateEvent({ productMixList });
    }

    onSaveProductBlending() {
        const { eventDetails, agEventModel } = this.props;
        const { isSharedBlend, isBlendCleared, activeProductBlend, originalProductMixList } =
            this.state;

        const eventNames =
            eventDetails.eventAreaList?.flatMap((ra) =>
                ra.agEventList?.flatMap((r) =>
                    r.agEventModel.productMixList?.flatMap((rpm) => rpm.name)
                )
            ) || [];

        const isSharedNameBlend =
            eventNames.filter(
                (rn) =>
                    rn === agEventModel.productMixList[activeProductBlend].name &&
                    rn !== agEventModel.productMixList[activeProductBlend].guaranteedAnalysis
            ).length > 1;

        if (
            ((isSharedBlend && !isBlendCleared) || isSharedNameBlend) &&
            agEventModel.productMixList[activeProductBlend].guaranteedAnalysis !==
                originalProductMixList[activeProductBlend]?.guaranteedAnalysis &&
            originalProductMixList[activeProductBlend]?.guaranteedAnalysis
        ) {
            this.setState({ confirmSharedBlendOverwriteModalOpen: true });
        } else {
            this._onConfirmOverwrite();
        }
    }

    onCancelProductBlending() {
        this._updateEvent({
            productMixList: [...this.state.originalProductMixList],
        });
        this.onCloseProductBlending();
    }

    onCloseProductBlending() {
        this.setState({
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalProductMixList: [],
            confirmSharedBlendOverwriteModalOpen: false,
            isBlendCleared: false,
        });
    }

    _onConfirmOverwrite() {
        // Put an action to coerce any other mixes in the current event/batch
        this.props.onBatchUpdateProductBlend(
            this.props.agEventModel.productMixList[this.state.activeProductBlend],
            this.state.originalProductMixList?.length && !this.state.isBlendCleared
                ? this.state.originalProductMixList[this.state.activeProductBlend]
                      ?.guaranteedAnalysis
                : null
        );

        this.onCloseProductBlending();
    }

    _onCancelOverwrite() {
        this.setState({ confirmSharedBlendOverwriteModalOpen: false });
    }

    onRemoveProductMix(index) {
        const productMixList = [...this.props.agEventModel.productMixList];
        productMixList.splice(index, 1);

        this._updateEvent({ productMixList });
    }

    render() {
        const {
            classBreaksCount,
            classBreaksHaveOnlyOneZone,
            agEventModel,
            calculatedArea,
            isBatchTemplate,
            isFromEquationRec,
        } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        const { productMixList } = agEventModel;

        // TODO: use portal to render into RecEventZoneInfo after React 16 upgrade
        //       https://codesandbox.io/s/82kmnvk0vj
        const addMixStyle = {};

        if (productMixList.length === 0 && !isBatchTemplate) {
            addMixStyle.paddingTop = 0;
            addMixStyle.position = "relative";
            addMixStyle.top =
                -53 -
                (classBreaksCount === 0
                    ? 0
                    : classBreaksCount * 28 + (classBreaksHaveOnlyOneZone ? 2 : 15));
        }
        return (
            <div className="rec-manual-application-form">
                <div className="product-blend-list">
                    {productMixList.map((mix, index) => {
                        const mixNumber = index + 1;
                        const mixName =
                            mix.products.length <= 1 &&
                            !(
                                mix.products.length === 1 &&
                                (mix.products[0].customProductGuid || isFromEquationRec)
                            )
                                ? ""
                                : mix.name || "";
                        const mixTitle = `${formatMessage(
                            messages.mixText
                        )} ${mixNumber}:  ${mixName}`;
                        const isMixLocked =
                            mix.isImport ||
                            mix.isLockGuaranteedAnalysis ||
                            mix.isLockProductRatios ||
                            !this.state.isProductBlendingActive ||
                            isFromEquationRec;
                        return (
                            <div key={`mix-${index}`} className="product-blend-row input-row">
                                <div className="mix-information">
                                    <div className="mix-title" title={mixTitle}>
                                        {mixTitle}
                                    </div>
                                    <div className="mix-product-list">
                                        {mix.products
                                            .filter((product) => !product.customProductGuid)
                                            .map((product, pIndex) => (
                                                <div
                                                    key={`product-${index}-${pIndex}`}
                                                    className="product-row"
                                                >
                                                    {product.productName}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="rate-information">
                                    <div
                                        className={classNames("target-rate", {
                                            "is-locked": isMixLocked,
                                        })}
                                    >
                                        {isMixLocked && !mix.isImport && !isFromEquationRec ? (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateTargetRate(
                                                        index,
                                                        numValue ? numValue : 0
                                                    )
                                                }
                                                value={mix.targetRate}
                                                showTopLabel={false}
                                            />
                                        ) : (
                                            <div>
                                                {formatNumber(
                                                    mix.actualRate || mix.targetRate,
                                                    intlConfig.numberFormatOptions
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="target-rate-unit">{mix.targetRateUnit}</div>
                                </div>
                                <div className="mix-context-menu">
                                    <Menu
                                        className="context-menu"
                                        isDotMenu={true}
                                        getMenuItems={() => this._getMenuItems(index)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                {productMixList != null &&
                productMixList.length > 0 &&
                (productMixList[0].isImport || isFromEquationRec) ? null : (
                    <div className="add-mix" style={addMixStyle}>
                        <NoLink
                            label={formatMessage(messages.addProductMixText)}
                            onClick={() => this.onAddEditProductMix()}
                        />
                    </div>
                )}
                {!this.state.isProductBlendingActive ? null : (
                    <ProductBlendModal
                        areaId={this.props.eventAreaId}
                        productMix={agEventModel.productMixList[this.state.activeProductBlend]}
                        calculatedArea={isBatchTemplate ? 1 : calculatedArea}
                        isFromEquationRec={isFromEquationRec}
                        isOpen={this.state.isProductBlendingActive}
                        activeProductMixIdx={this.state.activeProductBlend}
                        onUpdate={(productMix) => this.onUpdateProductBlending(productMix)}
                        onSave={() => this.onSaveProductBlending()}
                        onCancel={() => this.onCancelProductBlending()}
                    />
                )}
                {!this.state.confirmSharedBlendOverwriteModalOpen ? null : (
                    <DialogBox
                        isOpen={this.state.confirmSharedBlendOverwriteModalOpen}
                        isModal={true}
                        title={formatMessage(messages.resetRatesText)}
                        draggable={true}
                        hideCloseX={true}
                        onAction={() => this._onConfirmOverwrite()}
                        onClose={() => this._onCancelOverwrite()}
                        footerType={DialogBoxFooterType.YES_NO}
                    >
                        {formatMessage(messages.overwriteBlendRatesText)}
                    </DialogBox>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { fieldGuidToEventDetails, saveEventDetailsErrorCodeList } =
        eventsSelectors.getModuleState(state);
    const eventDetails = fieldGuidToEventDetails.get(ownProps.fieldGuid);

    const classBreakIdSet = new Set(
        eventDetails.eventAreaList
            .filter((area) => area.eventAreaClassBreak != null)
            .map((area) => area.eventAreaClassBreak.classId)
    );
    const classBreaksCount = classBreakIdSet.size;

    const classBreaksHaveOnlyOneZone = classBreaksCount === eventDetails.eventAreaList.length;

    return {
        classBreaksCount,
        classBreaksHaveOnlyOneZone,
        eventDetails,
        isFromEquationRec: eventDetails.isFromEquationRec,
        saveEventDetailsErrorCodeList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onBatchUpdateProductBlend: (productMix, previousMixGA) =>
        dispatch(actions.batchUpdateProductBlend(productMix, previousMixGA)),
    onUpdateCurrentAgEventAreaAgEventModel: (fieldGuid, agEventTransactionTypeGuid, newProps) =>
        dispatch(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                fieldGuid,
                agEventTransactionTypeGuid,
                newProps
            )
        ),
    setCustomProductGuids: (guids) => dispatch(setEventRecCustomProductGuids(guids)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateCurrentAgEventAreaAgEventModel: (newProps) =>
        dispatchProps.onUpdateCurrentAgEventAreaAgEventModel(
            ownProps.fieldGuid,
            ownProps.agEventTransactionTypeGuid,
            newProps
        ),
});

export const EventApplicationForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(EventApplicationForm_));
