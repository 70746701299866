import { createAction } from "redux-act";

const FETCH_AGBYTES_EQUIPMENT_DATA = "FETCH_AGBYTES_EQUIPMENT_DATA";
const CLEAR_AGBYTES_EQUIPMENT_DATA = "CLEAR_AGBYTES_EQUIPMENT_DATA";
const FETCH_AGBYTES_EQUIPMENT_SUCCESS = "FETCH_AGBYTES_EQUIPMENT_SUCCESS";
const FETCH_AGBYTES_EQUIPMENT_FAILED = "FETCH_AGBYTES_EQUIPMENT_FAILED";

export const fetchAgbytesEquipmentData = createAction(FETCH_AGBYTES_EQUIPMENT_DATA);
export const clearAgbytesEquipmentData = createAction(CLEAR_AGBYTES_EQUIPMENT_DATA);
export const fetchAgbytesEquipmentSuccess = createAction(FETCH_AGBYTES_EQUIPMENT_SUCCESS);
export const fetchAgbytesEquipmentFailed = createAction(FETCH_AGBYTES_EQUIPMENT_FAILED);
