import {
    AllEffect,
    CallEffect,
    PutEffect,
    SelectEffect,
    all,
    call,
    put,
    select,
    takeLatest,
} from "redux-saga/effects";

import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";

import { AgEventAPI } from "@ai360/core";
import * as actions from "./actions";
import { eventsSelectors, models as recsEventsModels } from "~/recs-events";
import { IAction } from "./models";

export const onFetchCropGrowthStageList = function* (
    action: IAction
): Generator<CallEffect | SelectEffect | PutEffect<IAction>, void, any> {
    const { fieldGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    console.assert(fieldGuid != null, "Customer/Field required");

    let targetFieldGuid = fieldGuid;
    if (fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID) {
        //for batch cases, simply use the first field's fieldGuid
        const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
        const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
        );
        targetFieldGuid = fieldGuidList[0];
    }
    try {
        const cropGrowthStageList = yield call(
            AgEventAPI.getTissueCropGrowthStageList,
            userGuid,
            targetFieldGuid
        );
        yield put(actions.setCropGrowthStageList(cropGrowthStageList));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const sampleTissueSaga = function* (): Generator<AllEffect, void, unknown> {
    yield all([takeLatest(actions.FETCH_CROP_GROWTH_STAGE_LIST, onFetchCropGrowthStageList)]);
};
