import PropTypes from "prop-types";
import { model } from "~/admin/agBytes/crop/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_AGRIAN_ID]: PropTypes.string,
    [model.PROPS_CROPAGRIAN_ID_GUID]: PropTypes.string,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_CROPAGRIAN_ID_HAS_FOCUS]: PropTypes.bool,
};

export default PropTypes.shape(shape);
