import { defineMessages } from "react-intl";

export const messages = defineMessages({
    alertText: {
        id: "dragAndDropFileUploader.alertText",
        defaultMessage: "Alert",
    },
    addMoreLink: {
        id: "dragAndDropFileUploader.addMoreLink",
        defaultMessage: "+ Add More",
    },
    clearAllLink: {
        id: "dragAndDropFileUploader.clearAllLink",
        defaultMessage: "Clear All",
    },
    dropMessageLine1: {
        id: "dragAndDropFileUploader.dropMessageLine1",
        defaultMessage: "Drag Files Here",
    },
    dropMessageLine2: {
        id: "dragAndDropFileUploader.dropMessageLine2",
        defaultMessage: "Or",
    },
    dropMessageLine3: {
        id: "dragAndDropFileUploader.dropMessageLine3",
        defaultMessage: "Click Here To Browse To Files",
    },
    fileUploadInfoAddFailed: {
        id: "dragAndDropFileUploader.fileUploadInfoAddFailed",
        defaultMessage: "Error adding file info list",
    },
    filesLabel: {
        id: "dragAndDropFileUploader.filesLabel",
        defaultMessage: "Files",
    },
    invalidExtension: {
        id: "dragAndDropFileUploader.invalidExtension",
        defaultMessage: "Invalid file name extension: {ext}",
    },
    missingShapePart: {
        id: "dragAndDropFileUploader.missingShapePart",
        defaultMessage: "Missing part of shape file: {ext}",
    },
    noDuplicateFilesText: {
        id: "dragAndDropFileUploader.noDuplicatesText",
        defaultMessage: "You are not allowed to add duplicate files.",
    },
    uploadBtnText: {
        id: "dragAndDropFileUploader.uploadBtnText",
        defaultMessage: "Upload",
    },
    uploadingStatusNotFound: {
        id: "dragAndDropFileUploader.uploadingStatusNotFound",
        defaultMessage: "Unable to query status GUID for Uploading files",
    },
});
