import { ClassBreak } from "@ai360/core";
import { AgEventAPI } from "@ai360/core";
import { AgEvent, AgEventAreaPolygon } from "../model";

export class AgEventArea implements AgEventAPI.IAgEventArea {
    static updateAgEventArea(
        agEventArea: AgEventArea,
        newProps: Partial<AgEventAPI.IAgEventArea>
    ): Readonly<AgEventArea> {
        console.assert(agEventArea instanceof AgEventArea);
        const rv = new AgEventArea(null, null, 0, agEventArea.applyEventToArea);
        Object.assign(rv, agEventArea, newProps);
        return Object.freeze(rv);
    }
    eventAreaGuid = "";
    eventAreaId = 1;
    agEventGeneralGuid = ""; // ignored
    applyEventToArea = false;
    calculatedArea = 0;
    eventAreaClassBreak: ClassBreak | null = null;
    fieldBoundaryGuid: string | null = null;
    sourceEventAreaGuid?: string;

    constructor(
        public zonePolygons: AgEventAreaPolygon[] | null,
        public agEventList: AgEvent[] | null,
        calculatedArea = 0,
        applyEventToArea = false
    ) {
        this.applyEventToArea = applyEventToArea;
        this.calculatedArea = calculatedArea;
    }
}
