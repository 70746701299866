import { combineReducers } from "redux";

import { COMMON_STATE_KEY, commonReducer } from "./components/common";
import { EVENT_MODULE_STATE_KEY, eventModuleReducer } from "./components/event-module";
import { FIELD_MODULE_STATE_KEY, fieldModuleReducer } from "./components/field-module";
import { IMPORT_MODULE_STATE_KEY, importModuleReducer } from "./components/import-module";
import { LAYER_MODULE_STATE_KEY, layerModuleReducer } from "./components/layer-module";
import { REC_MODULE_STATE_KEY, recModuleReducer } from "./components/rec-module";
import { PANEL_KEY } from "./selectors";
import * as actions from "./actions";

const newInitialState = () => ({
    activeModule: actions.ActionPanelModuleList.FIELD,
    isExpanded: true,
    isEnabled: true,
});

export const mainReducer = (state, action) => {
    if (state == null) {
        return newInitialState();
    }

    switch (action.type) {
        case actions.ACTIONPANEL_SET_ACTIVEMODULE:
            return {
                ...state,
                activeModule: action.payload,
            };
        case actions.ACTIONPANEL_SET_ISEXPANDED:
            return {
                ...state,
                isExpanded: action.payload,
            };
        case actions.ACTIONPANEL_SET_ISENABLED:
            return {
                ...state,
                isEnabled: action.payload,
            };
        default:
            return state;
    }
};

export const actionPanelReducer = combineReducers({
    [COMMON_STATE_KEY]: commonReducer,
    [EVENT_MODULE_STATE_KEY]: eventModuleReducer,
    [FIELD_MODULE_STATE_KEY]: fieldModuleReducer,
    [IMPORT_MODULE_STATE_KEY]: importModuleReducer,
    [LAYER_MODULE_STATE_KEY]: layerModuleReducer,
    [PANEL_KEY]: mainReducer,
    [REC_MODULE_STATE_KEY]: recModuleReducer,
});
