import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_IS_ACTIVE;
export const PROPS_AGGATEWAY_ID = "agGatewayId";
export const PROPS_AGRIAN_ID = "agrianIds";
export const PROPS_AGX_ID = "agXId";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CROP_ALIAS = "aliases";
export const PROPS_CROP_CLASS_ID = "classId";
export const PROPS_CROP_CLASS_NAME = "className";
export const PROPS_CROP_GROUP = "group";
export const PROPS_CROP_GUID = adminData.PROPS_ID;
export const PROPS_CROP_ID = adminData.PROPS_INTEGER_ID;
export const PROPS_CROP_NAME = adminData.PROPS_NAME;
export const PROPS_CROP_PHOTO_LIST = "photos";
export const PROPS_DEFAULT_MAP_COLOR = "defaultMapColor";
export const PROPS_DIVISION = "division";
export const PROPS_FAMILY = "family";
export const PROPS_FARM_MARKET_CROP_CODE = "farmMarketCropCode";
export const PROPS_FCICCROP_CODE = "fcicCropCode";
export const PROPS_GENUS = "genus";
export const PROPS_GROUP_NAME = "groupName";
export const PROPS_GROWTH_STAGE_GROUPID = "growthStageGroupId";
export const PROPS_GROWTH_STAGE_GROUP_GUID = "growthStageGroupGuid";
export const PROPS_GROWTH_STAGE_GROUP_NAME = "growthStageGroupName";
export const PROPS_LIFESPAN = "lifespanName";
export const PROPS_LIFESPAN_GUID = "lifespanGuid";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_MORPHOLOGY_TYPE = "morphologyTypeName";
export const PROPS_MORPHOLOGY_TYPE_GUID = "morphologyTypeGuid";
export const PROPS_OPTIMAL_MOISTURE_AT_HARVEST = "optimalMoistureAtHarvest";
export const PROPS_PLANTING_TEMPERATURE_MINIMUM = "plantingTemperatureMinimum";
export const PROPS_SCIENTIFIC_NAME = "scientificName";
export const PROPS_TAXONOMICAL_CLASS = "class";
export const PROPS_TAXONOMICAL_ORDER = "taxonomicalOrder";
export const PROPS_TEST_WEIGHT = "testWeight";
export const PROPS_TISSUE_GROWTH_STAGE_GROUPID = "tissueGrowthStageGroupId";
export const PROPS_TISSUE_GROWTH_STAGE_GROUP_GUID = "tissueGrowthStageGroupGuid";
export const PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME = "tissueGrowthStageGroupName";
export const PROPS_MYAGDATA_COMMODITY = "myAgDataCommodities";
