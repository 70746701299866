import React, { Component } from "react";
import PropTypes from "prop-types";
import { model } from "../../data";
import {
    SelectInput,
    DateInput,
    TextArea,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
    NumericInput,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { mapToPicklistValue, prepareSelectableOptions, formatDate } from "~/admin/utils";
import moment from "moment";
import { messages } from "../../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import * as picklistService from "~/core/picklist/picklist-names";
import TableCell from "~/core/components/tables/data-table/components/table-cell";
import { adminData, PICKLIST_GUID, VALUE, NAME } from "~/admin/data";
const PROPS_MAINTENANCE_DATE = "maintenanceDate";
const PROPS_MAINTENANCE_DESCRIPTION = "maintenanceDescription";
const PROPS_MAINTENANCE_TYPE_GUID = "maintenanceTypeGuid";
const PROPS_MAINTENANCE_TYPE_NAME = "maintenanceTypeName";
const PROPS_METER_READING = "meterReading";
const PROPS_TOTAL_COST = "totalCost";
const PROPS_USAGE_UNIT_GUID = "usageUnitGuid";
const PROPS_USAGE_UNIT_NAME = "usageUnitName";

class MaintenanceList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
        needs: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchDropdownData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
            [picklistService.PICKLIST_MAINTENANCE_TYPE]: prepareSelectableOptions(
                props[picklistService.PICKLIST_MAINTENANCE_TYPE],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
            [picklistService.PICKLIST_USAGE_UNIT]: prepareSelectableOptions(
                props[picklistService.PICKLIST_USAGE_UNIT],
                { guid: PICKLIST_GUID, label: VALUE }
            ),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record && record.length > 0) {
            if (
                nextProps[picklistService.PICKLIST_MAINTENANCE_TYPE] &&
                nextProps[picklistService.PICKLIST_USAGE_UNIT]
            ) {
                this.initializeMaintenanceList(nextProps);
            }
        }

        this.initializeMaintenanceType(nextProps);
        this.initializeMeterReading(nextProps);
    }

    initializeMaintenanceList = (nextProps) => {
        const { record } = nextProps;
        if (record) {
            const maintenanceTypeOptions = nextProps[picklistService.PICKLIST_MAINTENANCE_TYPE];
            const usageOptions = nextProps[picklistService.PICKLIST_USAGE_UNIT];

            return nextProps.record.map((recordItem) => {
                const selectedMaintenance = maintenanceTypeOptions.find(
                    (maintenanceTypeItem) =>
                        maintenanceTypeItem.picklistValueGuid ===
                        recordItem[PROPS_MAINTENANCE_TYPE_GUID]
                );
                recordItem[PROPS_MAINTENANCE_TYPE_NAME] =
                    selectedMaintenance && selectedMaintenance[VALUE];
                const selectedUsageOption = usageOptions.find(
                    (meterReadingItem) =>
                        meterReadingItem.picklistValueGuid === recordItem[PROPS_USAGE_UNIT_GUID]
                );
                recordItem[PROPS_USAGE_UNIT_NAME] =
                    selectedUsageOption && selectedUsageOption[VALUE];
                return recordItem;
            });
        }
        return record;
    };

    initializeMaintenanceType = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_MAINTENANCE_TYPE] !==
            this.props[picklistService.PICKLIST_MAINTENANCE_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_MAINTENANCE_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_MAINTENANCE_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    initializeMeterReading = (nextProps) => {
        if (
            nextProps[picklistService.PICKLIST_USAGE_UNIT] !==
            this.props[picklistService.PICKLIST_USAGE_UNIT]
        ) {
            this.setState({
                [picklistService.PICKLIST_USAGE_UNIT]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_USAGE_UNIT],
                    { guid: PICKLIST_GUID, label: VALUE }
                ),
            });
        }
    };

    onEdit = (record, index) => {
        this.setState({ editingItem: true });
        const keysToUpdate = [
            {
                name: [PROPS_MAINTENANCE_TYPE_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [PROPS_USAGE_UNIT_GUID],
                key: PICKLIST_GUID,
            },
            {
                name: [model.PROPS_MAINTENANCE_DATE],
                key: NAME,
            },
            {
                name: [model.PROPS_MAINTENANCE_DESCRIPTION],
                key: NAME,
            },
            {
                name: [model.PROPS_METER_READING],
                key: NAME,
            },
            {
                name: [model.PROPS_TOTAL_COST],
                key: NAME,
            },
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                unrestricted
                action="save"
                className="maintenance-event-dialog"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                actionDisabled={
                    !editData[PROPS_MAINTENANCE_TYPE_GUID] || !editData[PROPS_MAINTENANCE_DATE]
                }
                isOpen={isEditing}
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.maintenanceEvent
                )}
            >
                <div className="accessories-group">
                    <SelectInput
                        required
                        autoFocus
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        openOnFocus={false}
                        options={this.state[picklistService.PICKLIST_MAINTENANCE_TYPE]}
                        value={mapToPicklistValue({
                            options: this.state[picklistService.PICKLIST_MAINTENANCE_TYPE],
                            selectedGuid: editData[PROPS_MAINTENANCE_TYPE_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_MAINTENANCE_TYPE_GUID]: value ? value.guid : "",
                                [PROPS_MAINTENANCE_TYPE_NAME]: value ? value.name : "",
                            });
                        }}
                        placeholderText={formatMessage(messages.maintenanceType)}
                    />
                    <NumericInput
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.totalCost)}
                        value={editData[PROPS_TOTAL_COST]}
                        onChange={(value) =>
                            onChange({
                                [PROPS_TOTAL_COST]: value,
                            })
                        }
                    />
                </div>
                <div className="accessories-group">
                    <NumericInput
                        scale={2}
                        precision={9}
                        placeholderText={formatMessage(messages.meterReading)}
                        value={editData[PROPS_METER_READING]}
                        onChange={(value) =>
                            onChange({
                                [PROPS_METER_READING]: value,
                            })
                        }
                    />
                    <SelectInput
                        options={this.state[picklistService.PICKLIST_USAGE_UNIT]}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        value={mapToPicklistValue({
                            options: this.state[picklistService.PICKLIST_USAGE_UNIT],
                            selectedGuid: editData[PROPS_USAGE_UNIT_GUID],
                        })}
                        onChange={(value) => {
                            onChange({
                                [PROPS_USAGE_UNIT_GUID]: value ? value.guid : "",
                                [PROPS_USAGE_UNIT_NAME]: value ? value.name : "",
                            });
                        }}
                        placeholderText={formatMessage(messages.meterReadingUnit)}
                    />
                </div>
                <div className="column-3-gutter">
                    <DateInput
                        required
                        timeFormat={this.state.includeTime ? "hh:mm" : false}
                        placeholderText={formatMessage(messages.date)}
                        onChange={(value) =>
                            onChange({
                                [PROPS_MAINTENANCE_DATE]: formatDate(value, ""),
                            })
                        }
                        value={
                            editData[PROPS_MAINTENANCE_DATE]
                                ? moment(editData[PROPS_MAINTENANCE_DATE])
                                : null
                        }
                        defaultValue={null}
                    />
                </div>
                <div className="description-cont">
                    <TextArea
                        maxLength={2000}
                        containerClassNames={["maintenance-event-textarea"]}
                        value={editData[PROPS_MAINTENANCE_DESCRIPTION]}
                        onChange={(value) => {
                            onChange({
                                [PROPS_MAINTENANCE_DESCRIPTION]: value,
                            });
                        }}
                        placeholderText={formatMessage(messages.description)}
                    />
                </div>
            </DialogBox>
        );
    };

    printMaintenanceDate = (value) => {
        if (value) {
            const formattedDate = formatDate(value, "M/D/YYYY");
            return (
                <TableCell key="formatted-date-cell" title={formattedDate}>
                    {formattedDate}
                </TableCell>
            );
        }
    };

    render() {
        const { record, formatMessage } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid maintenance-event"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_MAINTENANCE_DATE]: {
                                title: formatMessage(messages.date),
                                printer: this.printMaintenanceDate,
                            },
                            [PROPS_MAINTENANCE_TYPE_NAME]: {
                                title: formatMessage(messages.type),
                            },
                            [PROPS_MAINTENANCE_DESCRIPTION]: {
                                title: formatMessage(messages.description),
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {this.props.renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.maintenanceEvent)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(MaintenanceList);
