import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { getTheUserGuid } from "~/login/selectors";
import { messages } from "../i18n-messages";
import { PersonAPI } from "@ai360/core";
import { AutoSearch } from "~/core";

//Style
import "./component.css";

class SearchPerson extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        classNames: PropTypes.string,
        fetchData: PropTypes.func,
        initialValue: PropTypes.string,
        intl: intlShape.isRequired,
        onCreate: PropTypes.func,
        onSearchChange: PropTypes.func,
        onSelection: PropTypes.func,
        openOnFocus: PropTypes.bool,
        userGuid: PropTypes.string,
        varietyHybridList: PropTypes.array,
    };

    static defaultProps = {
        filteredData: [],
        autoFocus: false,
    };

    static PERSON_NAME = "personName";
    static PERSON_JOB = "jobTitleName";
    static PERSON_CITY = "city";
    static PERSON_STATE_ABR = "stateAbbreviation";
    static PERSON_GUID = "personGuid";
    static DROPDOWN_NAME = "personList";
    static SEARCH_URL = PersonAPI.SEARCH_PERSON_WITHOUT_USER;

    noOptionsRenderer = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="person-search-no-option" onClick={this.props.onCreate}>
                {formatMessage(messages.createNewPerson)}
            </div>
        );
    };

    onItemSelection = (data) => {
        if (this.props.onSelection) {
            this.props.onSelection(data[SearchPerson.PERSON_GUID]);
        }
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { autoFocus, initialValue, openOnFocus, userGuid } = this.props;
        return (
            <AutoSearch
                classNames={["user-container"]}
                autoFocus={autoFocus}
                clearable={false}
                getAutoSearchList={PersonAPI.searchPersonWithoutUser}
                initialFilterStr={initialValue}
                itemList={[]}
                keyProp={SearchPerson.PERSON_GUID}
                nameProp={SearchPerson.PERSON_NAME}
                noOptionsRenderer={this.noOptionsRenderer}
                onSelection={(item) => this.onItemSelection(item)}
                openOnFocus={openOnFocus}
                placeholderText={formatMessage(messages.findPeople)}
                postPrimaryProp={[SearchPerson.PERSON_JOB]}
                secondaryPropList={[SearchPerson.PERSON_CITY, SearchPerson.PERSON_STATE_ABR]}
                selectedValue={initialValue}
                userGuid={userGuid}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

export default injectIntl(connect(mapStateToProps)(SearchPerson));
