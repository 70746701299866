import Immutable from "immutable";

export const filterOutEvents: (events: any, agEventGeneralGuidsToFilter: string[]) => any = (
    events,
    agEventGeneralGuidsToFilter
) =>
    [...events.entries()].reduce((accumulator, item) => {
        const [key, eventSummaries] = item;
        const filteredEventGuids = [...eventSummaries.values()].filter(
            (eventSummary) => !agEventGeneralGuidsToFilter.includes(eventSummary.agEventGeneralGuid)
        );
        return accumulator.set(key, filteredEventGuids);
    }, Immutable.OrderedMap());
