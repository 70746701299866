import { all, call, put, select, takeLatest } from "redux-saga/effects";

import * as actionPanelActions from "~/action-panel/actions";
import * as fieldModuleActions from "~/action-panel/components/field-module/actions";
import { fetchPicklistData } from "~/core/picklist/actions";
import { fetchSystemAttributePicklist } from "~/action-panel/components/event-module/components/event-info/actions";
import { actions as fileImportActions } from "~/file-import";
import { getTheUserGuid } from "~/login/selectors";
import * as layerListActions from "~/action-panel/components/layer-module/components/layer-list/actions";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { actions as messagingActions } from "~/messaging";
import { setApiResult } from "~/core/api/actions";

import {
    AppHelpers,
    EquipmentProfileAPI,
    FileImportAPI,
    FieldAPI,
    APIError,
    APIErrorWithCode,
    AgvanceAPI,
} from "@ai360/core";

import * as panelActions from "../../actions";
import * as actions from "./actions";
import * as models from "./models";
import { messages } from "./i18n-messages";
import * as selectors from "./selectors";

import {
    PICKLIST_CROPPING_SEASON,
    PICKLIST_CROPPING_SEASON_CYCLE,
    PICKLIST_CROP_PURPOSE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { v4 as uuid } from "uuid";

const _prepNameMatchEvents = function* (allProposedEvents) {
    //Update the ready events with an assigned agEventGeneralGuid for status tracking
    const revisedProposedEvents = allProposedEvents.map((event) => {
        return {
            ...event,
            agEventGeneralGuid: event.fieldGuid ? uuid() : null,
        };
    });
    yield put(actions.setEventStripPreviewData(revisedProposedEvents));
    const readyProposedEvents = revisedProposedEvents.filter((event) => {
        return event.fieldGuid && (event.status.statusCode === 0 || event.status.statusCode === 5);
    });
    const importFileGuids = new Set(readyProposedEvents.map((event) => event.importFileGuids[0]));
    yield put(actions.setSuccessfulNameMatchImports(Array.from(importFileGuids)));
    yield put(actions.setIsLoading(true));
    return readyProposedEvents;
};

const _fetchSamplePoints = function* (importFileGuids) {
    const userGuid = yield select(getTheUserGuid);
    let importSamplingPoints = [];
    try {
        importSamplingPoints = yield call(
            FileImportAPI.getSamplePointsByFile,
            userGuid,
            importFileGuids
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, null, messages.fetchSamplingPointsError));
    }
    const filterList = yield select(selectors.getFilteredFieldImportFileList);
    const filteredImportSamplingPoints = importSamplingPoints.map((point) => ({
        ...point,
        matched:
            point.fieldGuid &&
            !filterList.some(
                (filter) =>
                    filter.fieldGuid === point.fieldGuid &&
                    filter.importFileGuid === point.importFileGuid
            ),
    }));
    yield put(actions.setImportSamplingPoints(filteredImportSamplingPoints));
};

const _fetchSamplePointsByEventId = function* (eventIds) {
    const userGuid = yield select(getTheUserGuid);
    const importSamplingPoints = yield call(
        FileImportAPI.getSamplePointsByEventIds,
        userGuid,
        eventIds
    );
    yield put(actions.setImportSamplingPoints(importSamplingPoints));
};

const _fetchImportWizardData = function* (
    importFileGuidList,
    importTemplate,
    reZoom = false,
    rematchFiles = false,
    reRunImagery = false
) {
    const importType = yield select(selectors.getImportWizardType);

    const request = {
        ...models.importGroomingRequest,
        importFileGuidList,
    };
    const importTypeName = importType.name.toLowerCase();
    if (importTemplate && importTemplate.isNameMatch) {
        // skip the spatial field match, instead match based on customer, farm, and field name
        const groomingSessionGuid = yield select(selectors.getImportGroomingSessionGuid);
        request.groomingSessionGuid = groomingSessionGuid;
        request.rematchFiles = rematchFiles;
        request.isNameMatch = true;
        yield put(actions.setFieldMatchingLoading(true));
        yield put(actions.setFieldMatchingComplete(false));
        yield call(fetchImportFilterListData, actions.fetchImportFilterListData(request));
        return;
    }
    if (
        importTypeName !== actions.ImportTypes.FIELD_BOUNDARY &&
        importTypeName !== actions.ImportTypes.IMAGERY
    ) {
        // we don't need to make another call for the imagery field matches
        const groomingSessionGuid = yield select(selectors.getImportGroomingSessionGuid);
        request.groomingSessionGuid = groomingSessionGuid;
        request.rematchFiles = rematchFiles;
        yield call(fetchImportFilterListData, actions.fetchImportFilterListData(request));
        yield call(fetchImportFieldStripData, actions.fetchImportFieldStripData(request));
        if (actions.SamplingImportTypes.includes(importTypeName)) {
            if (reZoom) {
                yield put(actions.setIgnoreSelectedField(true));
            }
        }
    } else {
        request.reRunImagery = reRunImagery;
        yield call(fetchImportFilterListData, actions.fetchImportFilterListData(request));
    }
};

const _isSamplingType = function* () {
    const importType = yield select(selectors.getImportWizardType);
    const importTypeName = importType.name ? importType.name.toLowerCase() : "";
    if (actions.SamplingImportTypes.includes(importTypeName)) {
        return true;
    }
    return false;
};

const _isSingleStep = function* () {
    return yield select(selectors.getIsSingleStep);
};

export const pickLists = {
    [PICKLIST_CROPPING_SEASON]: getPickListCode(PICKLIST_CROPPING_SEASON),
    [PICKLIST_CROPPING_SEASON_CYCLE]: getPickListCode(PICKLIST_CROPPING_SEASON_CYCLE),
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
};

export const initializeImportWizard = function* (action) {
    const { importFileGuidList, importTemplate } = action.payload;
    try {
        yield put(actions.setIsLoading(true));
        yield put(actionPanelActions.setIsEnabled(false));
        yield put(fetchPicklistData(pickLists));
        yield put(
            fetchSystemAttributePicklist([
                {
                    eventType: "Planting",
                    systemAttribute: "Seeding Rate",
                    model: "seedingRate",
                    forceRaw: true,
                },
            ])
        );
        yield call(_fetchImportWizardData, importFileGuidList, importTemplate);
    } catch (err) {
        yield put(
            notificationActions.apiCallError(err, action, messages.initializeImportWizardError)
        );
        yield put(actions.setIsLoading(false));
    }
};

export const closeImportWizard = function* (action) {
    const { isCancel } = action.payload;
    yield put(panelActions.setIsLoading(true));
    yield put(actions.setShowProcessing(false));
    const importFileGuidList = yield select(selectors.getImportFileGuidList);
    const eventStripPreviewData = yield select(selectors.getEventStripPreviewData);
    const successfulNameMatchImports = yield select(selectors.getSuccessfulNameMatchImports);
    yield put(actionPanelActions.setIsEnabled(true));
    try {
        yield put(panelActions.deselectAllImportFileInfoItems(new Set(importFileGuidList)));
        yield put(actions.resetImportWizard());
        if (!isCancel) {
            const completeImportFiles = new Set();

            eventStripPreviewData.forEach((strip) => {
                // Exclude strips with codes for Unknown (0), Data Conflict (39), and Data Error (391)
                const { statusCode } = strip.status;
                if (statusCode !== 0 && statusCode !== 39 && statusCode !== 391) {
                    for (let i = 0; i < strip.importFileGuids.length; i++) {
                        completeImportFiles.add(strip.importFileGuids[i]);
                    }
                }
            });
            if (successfulNameMatchImports.length > 0) {
                for (const importFileGuid of successfulNameMatchImports) {
                    completeImportFiles.add(importFileGuid);
                }
            }
            yield put(
                fileImportActions.deleteImportFileInfoList(Array.from(completeImportFiles), true)
            );
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.cancelImportWizardError));
    } finally {
        yield put(layerListActions.removeAllVisibleSurfaces());
        yield put(actions.setIsLoading(false));
        yield put(panelActions.setIsLoading(false));
    }
};

export const fetchImportFilterListData = function* (action) {
    const userGuid = yield select(getTheUserGuid);
    const importType = yield select(selectors.getImportWizardType);
    const importTypeName = importType.name.toLowerCase();

    try {
        const importGroomingData = yield importTypeName !== actions.ImportTypes.IMAGERY
            ? call(
                  FileImportAPI.getImportFilterLists,
                  userGuid,
                  action.payload.importGroomingRequest
              )
            : call(FileImportAPI.getImageryLayers, userGuid, {
                  importFileGuidList: action.payload.importGroomingRequest.importFileGuidList,
                  isReRunImagery: action.payload.importGroomingRequest.reRunImagery,
              });

        switch (importTypeName) {
            case actions.ImportTypes.FIELD_BOUNDARY: {
                yield put(
                    actions.setImportFieldList(importGroomingData.groomingSession.fieldBoundaryList)
                );
                break;
            }
            // Case for imagery to set field data/event strips
            case actions.ImportTypes.IMAGERY: {
                const unmatchedRow = {
                    fieldGuid: "",
                    fieldName: null,
                    customerName: null,
                    customerGuid: null,
                    farmName: null,
                    noMatch: true,
                    importFileGuidList: [],
                    pointCount: 0,
                };

                const matchedRows = [];
                const eventStripPreviews = [];
                importGroomingData.forEach((m) => {
                    const hasPartialMatches = m.fieldMatches.some((p) => p.partialMatch);
                    if (
                        m.fieldMatches.length === 0 ||
                        m.fieldMatches.some((fm) => !fm.fieldGuid) ||
                        hasPartialMatches
                    ) {
                        // unmatched case
                        unmatchedRow.pointCount++;
                        const existingFileIndex = unmatchedRow.importFileGuidList.findIndex(
                            (i) => i.importFileGuid === m.layer.importFileGuid
                        );
                        if (existingFileIndex >= 0) {
                            unmatchedRow.importFileGuidList[existingFileIndex].pointCount++;
                            if (!hasPartialMatches) {
                                return;
                            }
                        }
                        unmatchedRow.importFileGuidList.push({
                            importFileGuid: m.layer.importFileGuid,
                            importFileName: m.layer.importFileName,
                            pointCount: 1,
                        });
                        if (!hasPartialMatches) {
                            return;
                        }
                    }
                    m.fieldMatches.forEach((fieldMatch) => {
                        if (fieldMatch.fieldGuid == null) {
                            return;
                        }
                        const existingFieldIndex = matchedRows.findIndex(
                            (mr) => mr.fieldGuid === fieldMatch.fieldGuid
                        );

                        if (existingFieldIndex >= 0) {
                            matchedRows[existingFieldIndex].importFileGuidList.push({
                                importFileGuid: m.layer.importFileGuid,
                                importFileName: m.layer.name,
                                pointCount: 1,
                            });
                        } else {
                            matchedRows.push({
                                fieldGuid: fieldMatch.fieldGuid,
                                fieldName: fieldMatch.name,
                                customerName: fieldMatch.customerName,
                                customerGuid: fieldMatch.customerGuid,
                                farmName: fieldMatch.farmName,
                                importFileGuidList: [
                                    {
                                        importFileGuid: m.layer.importFileGuid,
                                        importFileName: m.layer.importFileName,
                                        pointCount: 1,
                                    },
                                ],
                                noMatch: false,
                                enrolledYn: fieldMatch.enrolledYn,
                            });
                        }

                        eventStripPreviews.push({
                            display: {
                                acres: fieldMatch.acres.toFixed(2),
                                croppingSeason: m.layer.croppingSeasonName,
                                customerName: fieldMatch.customerName,
                                eventName: m.layer.name,
                                farmName: fieldMatch.farmName,
                                fieldName: fieldMatch.name,
                                pointCount: 1,
                            },
                            croppingSeasonGuid: m.layer.croppingSeasonGuid,
                            fieldGuid: fieldMatch.fieldGuid,
                            eventDate: m.layer.imageDate,
                            statusCode: 0,
                            status: {
                                statusCode: 0,
                            },
                            importFileGuids: [m.layer.importFileGuid],
                        });
                    });
                });

                yield put(layerListActions.removeAllVisibleSurfaces());

                // Add the tile layers for the field/import
                let currentVisibleSurfaces = Object.values(importGroomingData).reduce(
                    function createVisibleSurfaceLayerMap(currentVisibleSurfaces, lyr) {
                        const lyrImportFileGuid = lyr.layer.importFileGuid;
                        const lyrImageryLayerGuid = lyr.layer.imageryLayerGuid;
                        currentVisibleSurfaces.set(lyrImportFileGuid, {
                            importFileGuid: lyrImportFileGuid,
                            imageryLayerGuid: lyrImageryLayerGuid,
                            surfaceGuid: lyrImportFileGuid,
                        });
                        return currentVisibleSurfaces;
                    },
                    new Map()
                );
                yield put(layerListActions.setVisibleSurfaces(currentVisibleSurfaces));

                if (unmatchedRow.pointCount > 0) {
                    matchedRows.push(unmatchedRow);
                }

                yield put(actions.setImportFieldStripData(matchedRows));
                yield put(actions.setEventStripPreviewData(eventStripPreviews));

                break;
            }
            case actions.ImportTypes.SAMPLING_SOIL:
            case actions.ImportTypes.SAMPLING_TISSUE: {
                if (action.payload.importGroomingRequest.isNameMatch) {
                    // field match based on name
                    const unmatchedRow = {
                        fieldGuid: "",
                        fieldName: null,
                        customerName: null,
                        customerGuid: null,
                        farmName: null,
                        noMatch: true,
                        importFileGuidList: [],
                    };

                    const matchedRows = [];
                    const eventStripPreviews = [];
                    const fieldGuidList = [];
                    importGroomingData.groomingSession.nameMatches.forEach((m) => {
                        if (!m.fieldGuid) {
                            // unmatched case
                            unmatchedRow.pointCount++;
                            unmatchedRow.importFileGuidList.push({
                                importFileGuid: m.importFileGuid,
                                importFileName: m.importFileName,
                                pointCount: 1,
                            });
                            eventStripPreviews.push({
                                display: {
                                    croppingSeason: m.croppingSeasonName,
                                    customerName: m.customerName,
                                    eventName: m.name,
                                    farmName: m.farmName,
                                    fieldName: m.fieldName,
                                    importFileName: m.importFileName,
                                    pointCount: 1,
                                },
                                croppingSeasonGuid: m.croppingSeasonGuid,
                                fieldGuid: null,
                                eventDate: m.eventDate,
                                statusCode: 0,
                                status: {
                                    statusCode: 0,
                                },
                                importFileGuids: [m.importFileGuid],
                                isNameMatch: true,
                            });
                            return;
                        } else {
                            fieldGuidList.push(m.fieldGuid);
                            const existingFieldIndex = matchedRows.findIndex(
                                (mr) => mr.fieldGuid === m.fieldGuid
                            );

                            if (existingFieldIndex >= 0) {
                                matchedRows[existingFieldIndex].importFileGuidList.push({
                                    importFileGuid: m.importFileGuid,
                                    importFileName: m.importFileName,
                                    pointCount: 1,
                                });
                            } else {
                                matchedRows.push({
                                    fieldGuid: m.fieldGuid,
                                    fieldName: m.fieldName,
                                    customerName: m.customerName,
                                    customerGuid: m.customerGuid,
                                    farmName: m.farmName,
                                    importFileGuidList: [
                                        {
                                            importFileGuid: m.importFileGuid,
                                            importFileName: m.importFileName,
                                            pointCount: 1,
                                        },
                                    ],
                                    noMatch: false,
                                    enrolledYn: m.enrolledYn,
                                });
                            }

                            eventStripPreviews.push({
                                display: {
                                    acres: m.acres.toFixed(2),
                                    croppingSeason: m.croppingSeasonName,
                                    customerName: m.customerName,
                                    eventName: m.name,
                                    farmName: m.farmName,
                                    fieldName: m.name,
                                    importFileName: m.importFileName,
                                    pointCount: 1,
                                },
                                croppingSeasonGuid: m.croppingSeasonGuid,
                                fieldGuid: m.fieldGuid,
                                eventDate: m.eventDate,
                                statusCode: 0,
                                status: {
                                    statusCode: 0,
                                },
                                importFileGuids: [m.importFileGuid],
                                isNameMatch: true,
                            });
                        }
                    });

                    matchedRows.push(unmatchedRow);

                    yield put(actions.setImportFieldStripData(matchedRows));
                    yield put(actions.setEventStripPreviewData(eventStripPreviews));
                    yield put(actions.setFieldMatchingLoading(false));
                    yield put(actions.setFieldMatchingComplete(true));
                }
                break;
            }
            default:
                break;
        }

        yield put(
            actions.setImportFilterListData(
                importTypeName !== actions.ImportTypes.IMAGERY
                    ? importGroomingData.groomingSession
                    : {
                          layerList: importGroomingData.map((l) => ({
                              importFileGuidList: [l.layer.importFileGuid],
                              ...l.layer,
                              layer: l.layer,
                              fieldMatches: l.fieldMatches,
                          })),
                      }
            )
        );

        if (importGroomingData.convexHulls) {
            yield put(actions.setImportConvexHulls(importGroomingData.convexHulls));
        } else if (importTypeName === actions.ImportTypes.IMAGERY) {
            const convexHulls = {};
            importGroomingData.forEach((l) => {
                convexHulls[l.layer.importFileGuid] = l.layer.convexHull;
            });
            yield put(actions.setImportConvexHulls(convexHulls));
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.fetchGroomingDataError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const fetchImportFieldStripData = function* (action) {
    const userGuid = yield select(getTheUserGuid);
    try {
        yield put(actions.setFieldMatchingLoading(true));
        yield put(actions.setFieldMatchingComplete(false));
        yield call(
            FileImportAPI.getImportFieldMatching,
            userGuid,
            action.payload.importGroomingRequest
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.fetchFieldStripDataError));
        yield put(actions.setFieldMatchingLoading(false));
    }
};

export const fetchFieldData = function* (action) {
    yield put(actions.setIsLoading(true));
    const { fieldGuid } = action.payload;
    try {
        const fieldData = yield call(FieldAPI.getField, fieldGuid);
        yield put(actions.setCurrentFieldData(fieldData));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.fetchFieldDataError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const fetchImportSamplingPoints = function* (action) {
    yield put(actions.setIsLoading(true));
    try {
        const importFilterListData = yield select(selectors.getImportFilterListData);
        const { eventIdList } = importFilterListData;
        if (eventIdList) {
            const fieldStrips = yield select(selectors.getImportFieldStripData);
            const unmatched = fieldStrips.find((i) => i.noMatch);
            let filteredEventIdList = eventIdList;
            if (unmatched) {
                const filterImportFileGuids = unmatched.importFileGuidList.map(
                    (u) => u.importFileGuid
                );
                filteredEventIdList = eventIdList.filter(
                    (e) => filterImportFileGuids.indexOf(e.importFileGuid) !== -1
                );
            }
            if (filteredEventIdList.length > 0) {
                yield call(
                    _fetchSamplePointsByEventId,
                    filteredEventIdList.map((e) => e.eventId)
                );
            }
        } else {
            yield call(_fetchSamplePoints, action.payload.importFileGuidList);
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.fetchSamplingPointsError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const fetchEventStripPreviewData = function* (action) {
    yield put(actions.setIsLoading(true));
    const userGuid = yield select(getTheUserGuid);
    const importType = yield select(selectors.getImportWizardType);
    const importTypeName = importType.name.toLowerCase();

    try {
        if (importTypeName !== actions.ImportTypes.IMAGERY) {
            const fieldStrips = yield select(selectors.getImportFieldStripData);
            const groomingSession = yield select(selectors.getImportFilterListData);
            const request = {
                fieldStrips,
                groomingSession,
            };
            const eventData = yield call(FileImportAPI.getImportEventPreview, userGuid, request);
            yield put(actions.setEventStripPreviewData(eventData));
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.fetchEventStripDataError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const validateImportEvent = function* (action) {
    yield put(actions.setIsLoading(true));
    const userGuid = yield select(getTheUserGuid);
    try {
        yield put(actions.setShowProcessing(true));
        const eventStripPreviewData = yield select(selectors.getEventStripPreviewData);
        const importType = yield select(selectors.getImportWizardType);
        const importTypeName = importType.name.toLowerCase();

        if (importTypeName !== actions.ImportTypes.IMAGERY) {
            const isNameMatch = eventStripPreviewData.some((e) => e.isNameMatch);
            const request = isNameMatch
                ? yield call(_prepNameMatchEvents, eventStripPreviewData)
                : eventStripPreviewData.filter((event) => {
                      return event.status.statusCode === 0 || event.status.statusCode === 5;
                  });
            if (!isNameMatch) {
                yield put(actions.setValidatingStatus());
            } else {
                yield put(actions.setNameMatchesValidating());
            }
            const response = yield call(FileImportAPI.validateImportAgEvent, userGuid, request);
            const { apiErrorResultList } = response;
            if (apiErrorResultList.length > 0) {
                yield put(actions.setApiErrorResultList(apiErrorResultList));
            }
        } else {
            // Just need to update the layers with the field matches here
            const importFilterListData = yield select(selectors.getImportFilterListData);
            yield call(
                FileImportAPI.updateImageryLayers,
                userGuid,
                importFilterListData.layerList
                    .filter(
                        (d) =>
                            d.fieldMatches.length > 0 &&
                            eventStripPreviewData.some((es) => {
                                return (
                                    d.fieldMatches.some((fm) => fm.fieldGuid === es.fieldGuid) &&
                                    es.importFileGuids.some((ifg) => ifg === d.layer.importFileGuid)
                                );
                            })
                    )
                    .map((d) => ({
                        layer: d.layer,
                        fieldMatches: d.fieldMatches.filter(
                            (d) =>
                                d.fieldGuid &&
                                eventStripPreviewData.some((es) => es.fieldGuid === d.fieldGuid)
                        ),
                    }))
            );
            const newEventStripData = eventStripPreviewData.map((eventStrip) => ({
                ...eventStrip,
                status: { statusCode: 6 },
            }));
            yield put(actions.setEventStripPreviewData(newEventStripData));
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.validateEventError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const createImportEvent = function* (action) {
    yield put(actions.setIsLoading(true));
    try {
        yield put(actions.setShowProcessing(true));
        const requestList = action.payload.requestList;
        const userGuid = yield select(getTheUserGuid);
        yield call(FileImportAPI.createImportAgEvent, userGuid, requestList);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.createEventError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const cancelImportEvent = function* (action) {
    yield put(actions.setIsLoading(true));
    try {
        yield put(actions.setShowProcessing(true));
        const { importFileGuidList } = action.payload;
        const userGuid = yield select(getTheUserGuid);

        yield call(FileImportAPI.cancelImportFileCreateEvent, userGuid, importFileGuidList);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const updateImportBoundary = function* (action) {
    const { request, callback } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const result = yield call(FileImportAPI.updateImportBoundary, userGuid, request);
        if (callback) {
            callback(result);
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.updateFieldBoundaryError));
    }
};

export const submitFieldBoundary = function* (action) {
    yield put(actions.setIsLoading(true));
    const userGuid = yield select(getTheUserGuid);
    const importFieldList = yield select(selectors.getImportFieldList);
    const importFieldBoundaries = yield select(selectors.getImportFieldBoundaries);
    const ignoreFarm = yield select(selectors.getIgnoreFarm);

    const importFieldsToSave = [];
    const newFieldList = [];
    importFieldList.forEach((field) => {
        const ifb = importFieldBoundaries.filter((boundary) => {
            return (
                field.importFieldBoundaryGuidList.includes(boundary.importFieldBoundaryGuid) &&
                !boundary.isSpatialConflict &&
                !boundary.isNameConflict
            );
        });
        ifb &&
        ifb.length === field.importFieldBoundaryGuidList.length &&
        field.customerGuid &&
        field.fieldName
            ? importFieldsToSave.push(field)
            : newFieldList.push(field);
    });
    const importFieldBoundariesToSave = importFieldBoundaries.filter((boundary) => {
        const importField = importFieldsToSave.some((field) => {
            return field.importFieldBoundaryGuidList.includes(boundary.importFieldBoundaryGuid);
        });
        return importField && !boundary.isSpatialConflict && !boundary.isNameConflict;
    });

    try {
        const request = {
            importFieldBoundaryGuidList: importFieldBoundariesToSave.map(
                (fb) => fb.importFieldBoundaryGuid
            ),
            importFileGuidList: importFieldsToSave.map((f) => f.importFileGuid),
            ignoreFarm,
        };

        const results = yield call(FileImportAPI.createFieldBoundary, userGuid, request);

        const successfulImports = [];
        for (let i = 0; i < results.length; i++) {
            if (results[i].success) {
                successfulImports.push(importFieldsToSave[i].importFileGuid);
            } else {
                yield put(
                    notificationActions.apiCallError(
                        new APIErrorWithCode(results[i]),
                        null,
                        messages.fieldBoundaryImportError,
                        { fieldName: importFieldsToSave[i].fieldName }
                    )
                );
            }
        }

        if (successfulImports.length > 0) {
            yield put(fileImportActions.deleteImportFileInfoList(successfulImports, true));
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.addImportFieldsError));
    } finally {
        yield put(
            actions.setImportFieldList(
                importFieldList.filter((field) => {
                    const match = (f) => f.importFileGuid === field.importFileGuid;
                    return newFieldList.some(match);
                })
            )
        );
        yield put(actions.setIsLoading(false));
    }
};

export const fetchUserCropImportPreferences = function* (action) {
    try {
        const { cropGuid, yieldIaGuid } = action.payload;
        const userGuid = yield select(getTheUserGuid);
        const response = yield call(
            FileImportAPI.getUserCropImportPreferences,
            userGuid,
            cropGuid,
            yieldIaGuid
        );

        yield put(actions.setUserCropImportPreferences(response));
    } catch (err) {
        yield put(
            notificationActions.apiCallError(err, action, messages.fetchUserCropImportPrefError)
        );
    }
};

export const updateUserCropImportPreferences = function* (action) {
    try {
        const { cropGuid, yieldIaGuid, averageTestWeight, averageUnitWeight } = action.payload;
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        yield call(
            FileImportAPI.updateUserCropImportPreferences,
            userGuid,
            cropGuid,
            yieldIaGuid,
            averageTestWeight,
            averageUnitWeight
        );
        yield put(actions.setIsLoading(false));
    } catch (err) {
        yield put(
            notificationActions.apiCallError(err, action, messages.updateUserCropImportPrefError)
        );
        yield put(actions.setIsLoading(false));
    }
};

export const onAssignMatchedToUnmatched = function* (action) {
    try {
        const { index, assignment } = action.payload;

        yield put(actions.setIsLoading(true));

        if (assignment != null) {
            const importFilterListData = yield select(selectors.getImportFilterListData);
            const newImportFilterListData = assignment(importFilterListData, index);
            yield put(actions.setImportFilterListData(newImportFilterListData));
        }
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const onRemoveImportFiles = function* (action) {
    yield put(actions.setIsLoading(true));
    const { importFileGuidList } = action.payload;
    const importFilterListData = yield select(selectors.getImportFilterListData);
    const convexHulls = yield select(selectors.getImportConvexHulls);
    const importFilterTypes = Object.keys(actions.ImportFilterTypes);
    const isSamplingType = yield call(_isSamplingType);
    if (isSamplingType) {
        importFilterTypes.push("eventIdList");
    }
    const newImportFilterListData = { ...importFilterListData };
    const newConvexHulls = {};
    importFileGuidList.forEach((removedImportFileGuid) => {
        Object.keys(convexHulls).forEach((c) => {
            if (c !== removedImportFileGuid) {
                newConvexHulls[c] = convexHulls[c];
            }
        });

        Object.values(actions.ImportFilterTypes).forEach((filterType) => {
            const f = newImportFilterListData[filterType];
            if (f && f.length) {
                newImportFilterListData[filterType] = f.reduce((items, item) => {
                    if (item.importFiles) {
                        const importFileGuidList = Object.keys(item.importFiles).filter(
                            (i) => i !== removedImportFileGuid
                        );
                        if (importFileGuidList.length > 0) {
                            const importFiles = {};
                            importFileGuidList.forEach((i) => {
                                importFiles[i] = item.importFiles[i];
                            });
                            item.importFiles = importFiles;
                            items.push(item);
                        }
                    }
                    return items;
                }, []);
            }
        });
    });

    yield put(actions.setImportFilterListData(newImportFilterListData));
    yield put(actions.setImportConvexHulls(newConvexHulls));
    yield put(actions.rerunMatching());
    yield put(actions.setIsLoading(false));
};

export const rerunMatching = function* (action) {
    try {
        const importType = yield select(selectors.getImportWizardType);
        const importTypeName = importType ? importType.name.toLowerCase() : "";
        const importFileGuidList = yield select(selectors.getImportFileGuidList);
        if (importTypeName !== actions.ImportTypes.IMAGERY) {
            const groomingSessionGuid = yield select(selectors.getImportGroomingSessionGuid);
            yield call(
                fetchImportFieldStripData,
                actions.fetchImportFieldStripData({
                    ...models.importGroomingRequest,
                    importFileGuidList,
                    groomingSessionGuid: groomingSessionGuid,
                    rematchFiles: true,
                })
            );
        } else {
            yield call(_fetchImportWizardData, importFileGuidList, null, false, false, true);
        }
        yield put(actions.setForceUpdate(true));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.rerunMatchingError));
    }
};

const fieldMatchMessageSubscriptions = function* () {
    const handleImportFieldMatchComplete = function* (payload) {
        const importWizardStep = yield select(selectors.getImportWizardStep);
        if (importWizardStep < 0) {
            yield put(actions.setFieldMatchingLoading(false));
            return;
        }
        if (payload.length) {
            yield put(actions.setImportFieldStripData(payload));
            yield put(actions.setFilteredFieldImportFileList([]));
            const isSingleStep = yield call(_isSingleStep);
            const isSamplingType = yield call(_isSamplingType);
            if (isSamplingType && !isSingleStep) {
                const importFileGuidList = yield select(selectors.getImportFileGuidList);
                yield call(
                    fetchImportSamplingPoints,
                    actions.fetchImportSamplingPoints(importFileGuidList)
                );
            }
            yield put(actions.setFieldMatchingComplete(true));
            yield put(actions.setFieldMatchingLoading(false));
        } else {
            // This isn't going to be able to update the status from this end
            //     but it will give the user a hint to retry the export.
            // This will only come up if there's an error fetching the file from the bucket.
            const isFieldMatchingLoading = yield select(selectors.getIsFieldMatchingLoading);
            if (isFieldMatchingLoading) {
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.fieldMatchingFailed,
                        MSGTYPE.ERROR
                    )
                );
            }
            yield put(actions.setFieldMatchingLoading(false));
        }
    };

    yield put(
        messagingActions.subscribe(0, {
            eventName: "importFieldMatchComplete",
            generator: handleImportFieldMatchComplete,
        })
    );
};

export const updateImageryAttribute = function* (action) {
    try {
        yield put(actions.setIsLoading(true));
        const userGuid = yield select(getTheUserGuid);
        const importFileGuidList = yield select(selectors.getImportFileGuidList);

        const { request, callback } = action.payload;
        const model = yield call(FileImportAPI.updateImageryLayers, userGuid, request);
        const filterListUpdateRequest = {
            ...models.importGroomingRequest,
            importFileGuidList,
        };
        yield call(
            fetchImportFilterListData,
            actions.fetchImportFilterListData(filterListUpdateRequest)
        );

        if (callback) {
            callback(model);
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.updateAttributeError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const unmatchFieldFile = function* (action) {
    try {
        yield put(actions.setIsLoading(true));
        const { field, file } = action.payload;
        const filteredFieldImportFileList = yield select(selectors.getFilteredFieldImportFileList);
        const importFieldStripData = yield select(selectors.getImportFieldStripData);
        // Unmatched strip has empty string fieldGuid
        const fieldGuidToStripMap = new Map(
            importFieldStripData.map((strip) => [strip.fieldGuid, strip])
        );
        const fieldStrip = { ...fieldGuidToStripMap.get(field.fieldGuid) };
        const importType = yield select(selectors.getImportWizardType);
        const importTypeName = importType.name.toLowerCase();
        const isImagery = importTypeName === actions.ImportTypes.IMAGERY;
        const eventStripPreviewData = yield select(selectors.getEventStripPreviewData); // only imagery uses this

        let removedFiles = [];
        let newFilterItems = [];
        if (file) {
            const newFileList = fieldStrip.importFileGuidList.filter(
                (f) => f.importFileGuid !== file.importFileGuid
            );
            removedFiles = fieldStrip.importFileGuidList.filter(
                (f) => f.importFileGuid === file.importFileGuid
            );
            newFilterItems = [
                {
                    fieldGuid: field.fieldGuid,
                    importFileGuid: file.importFileGuid,
                },
            ];
            if (newFileList.length !== 0) {
                const newFieldStrip = {
                    ...fieldStrip,
                    importFileGuidList: newFileList,
                };
                fieldGuidToStripMap.set(field.fieldGuid, newFieldStrip);
                if (isImagery) {
                    yield put(
                        actions.setEventStripPreviewData(
                            eventStripPreviewData.filter(
                                (es) =>
                                    es.fieldGuid !== field.fieldGuid ||
                                    !es.importFileGuids.some((ifg) => ifg === file.importFileGuid)
                            )
                        )
                    );
                }
            } else {
                fieldGuidToStripMap.delete(field.fieldGuid);
                yield put(actions.setSelectedMatchedFieldGuid(null));
                if (isImagery) {
                    yield put(
                        actions.setEventStripPreviewData(
                            eventStripPreviewData.filter((es) => es.fieldGuid !== field.fieldGuid)
                        )
                    );
                }
            }
        } else {
            removedFiles = fieldStrip.importFileGuidList;
            fieldGuidToStripMap.delete(field.fieldGuid);
            yield put(actions.setSelectedMatchedFieldGuid(null));
            newFilterItems = field.importFileGuidList.reduce((filterItems, i) => {
                const existingItem = filteredFieldImportFileList.find(
                    (f) => f.fieldGuid === field.fieldGuid && f.importFileGuid === i.importFileGuid
                );
                if (!existingItem) {
                    filterItems.push({
                        fieldGuid: AppHelpers.normalizeGuid(field.fieldGuid),
                        importFileGuid: i.importFileGuid,
                    });
                }
                return filterItems;
            }, []);
            if (isImagery) {
                yield put(
                    actions.setEventStripPreviewData(
                        eventStripPreviewData.filter((es) => es.fieldGuid !== field.fieldGuid)
                    )
                );
            }
        }

        if (!field.noMatch) {
            const unmatchedStrip = fieldGuidToStripMap.get("")
                ? { ...fieldGuidToStripMap.get("") }
                : { ...models.importFieldStrip };
            const updatedImportFileList = removedFiles.reduce(
                (fileList, r) => {
                    const unmatchedIndex = fileList.findIndex(
                        (u) => u.importFileGuid === r.importFileGuid
                    );
                    if (unmatchedIndex === -1) {
                        fileList.push(r);
                    } else {
                        fileList[unmatchedIndex].pointCount += r.pointCount;
                    }
                    return fileList;
                },
                [...unmatchedStrip.importFileGuidList]
            );

            const updatedUnmatched = {
                ...unmatchedStrip,
                importFileGuidList: updatedImportFileList,
            };
            fieldGuidToStripMap.set("", updatedUnmatched);
        } else {
            yield put(
                layerListActions.removeVisibleSurfaces(removedFiles.map((i) => i.importFileGuid))
            );
        }

        const newFilterList = [...filteredFieldImportFileList, ...newFilterItems];
        yield put(actions.setFilteredFieldImportFileList(newFilterList));

        const newImportFieldStripData = Array.from(fieldGuidToStripMap.values());
        yield put(actions.setImportFieldStripData(newImportFieldStripData));

        const importFileGuids = new Set();
        newImportFieldStripData.forEach((d) => {
            d.importFileGuidList.forEach((i) => {
                importFileGuids.add(i.importFileGuid);
            });
        });
        if (yield call(_isSamplingType)) {
            yield call(
                fetchImportSamplingPoints,
                actions.fetchImportSamplingPoints(Array.from(importFileGuids))
            );
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.unmatchFieldFileError));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const cancelLoader = function* () {
    yield put(actions.setIsLoading(false));
};

export const messageSubscriptions = function* () {
    yield put(
        messagingActions.subscribe(
            0,
            {
                eventName: "proposedEventCreateStatus",
                action: actions.updateProposedEventStatus,
            },
            {
                eventName: "proposedEventsValidated",
                action: actions.setAppendList,
            }
        )
    );
};

const updateImportWizardData = function* (action) {
    const importWizardStep = yield select(selectors.getImportWizardStep);
    if (importWizardStep < 0) {
        return;
    }
    const { fieldGuid } = action.payload;
    if (fieldGuid) {
        //AddField return (note that UpdateField uses `action.payload.response`)
        const newImportFieldStripData = [
            ...(yield select(selectors.getImportFieldStripData)),
            action.payload,
        ];
        yield put(actions.setImportFieldStripData(newImportFieldStripData));
    }
};

export function* addEquipmentImportFilter(action) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: action.payload.model };
    try {
        const response = yield call(EquipmentProfileAPI.createEquipmentProfile, requestOptions);
        const equipmentGuid = response;
        const { equipmentName, controllerId } = action.payload.model;
        yield put(
            actions.addEquipmentImportFilterSuccess({
                equipmentGuid,
                equipmentName,
                controllerId,
            })
        );
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        console.error(
            `Failed to process action ${action.type} with payload ${JSON.stringify(
                requestOptions
            )}`,
            error
        );
        yield put(actions.addEquipmentImportFilterFailure(error));
    }
}

const updateEventId = function* (action) {
    const { field, file, newEventId } = action.payload;
    const importFieldStripData = yield select(selectors.getImportFieldStripData);
    const fieldStripData = importFieldStripData.find((fsd) => {
        return (
            fsd.fieldGuid === field.fieldGuid &&
            fsd.importFileGuidList.some((fg) => fg.importFileGuid === file.importFileGuid)
        );
    });
    yield put(actions.setIsLoading(true));
    try {
        const { eventId } = fieldStripData.importFileGuidList.find(
            (fg) => fg.importFileGuid === file.importFileGuid && fg.eventId === file.eventId
        );
        const userGuid = yield select(getTheUserGuid);
        yield call(
            FileImportAPI.updateEventId,
            userGuid,
            [file.importFileGuid],
            eventId,
            newEventId
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        yield put(actions.setIsLoading(false));
        return;
    }

    const importFileGuidList = yield select(selectors.getImportFileGuidList);
    try {
        yield call(_fetchImportWizardData, importFileGuidList, null, true, true);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

const fetchAgvanceFieldList = function* (action) {
    const { payload } = action;
    if (payload) {
        try {
            const UserGuid = yield select(getTheUserGuid);
            const agvanceFieldList = yield AgvanceAPI.getFieldList(payload, UserGuid);
            yield put(actions.fetchAgvanceFieldListSucceeded(agvanceFieldList));
        } catch (err) {
            yield put(notificationActions.apiCallError(err, action));
        }
    }
};

const fetchAgvanceOrgLevelList = function* (action) {
    const { payload } = action;
    if (payload) {
        try {
            const UserGuid = yield select(getTheUserGuid);
            const additionalAgvanceCustomer = yield FieldAPI.getAgvanceCustomerList(
                UserGuid,
                payload
            );
            yield put(actions.fetchAgvanceOrgLevelListSucceeded(additionalAgvanceCustomer));
        } catch (err) {
            yield put(notificationActions.apiCallError(err, action));
        }
    }
};

function* setIgnoreFarm(action) {
    const { payload } = action;
    yield put(actions.setIsLoading(true));
    try {
        const UserGuid = yield select(getTheUserGuid);
        yield FieldAPI.ignoreFarmsMatching(
            UserGuid,
            payload.importFileGuidList,
            payload.ignoreFarm
        );
        yield put(actions.setIsLoading(false));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        yield put(actions.setIsLoading(false));
    }
}

export const importWizardSaga = function* () {
    yield all([
        takeLatest(actions.ASSIGN_MATCHED_TO_UNMATCHED, onAssignMatchedToUnmatched),
        takeLatest(actions.CANCEL_IMPORT_EVENT, cancelImportEvent),
        takeLatest(actions.CREATE_IMPORT_EVENT, createImportEvent),
        takeLatest(actions.FETCH_USER_CROP_IMPORT_PREFERENCES, fetchUserCropImportPreferences),
        takeLatest(actions.FETCH_EVENT_STRIP_PREVIEW_DATA, fetchEventStripPreviewData),
        takeLatest(actions.FETCH_CURRENT_FIELD_DATA, fetchFieldData),
        takeLatest(actions.FETCH_IMPORT_FIELD_STRIP_DATA, fetchImportFieldStripData),
        takeLatest(actions.FETCH_IMPORT_FILTER_LIST_DATA, fetchImportFilterListData),
        takeLatest(actions.FETCH_IMPORT_SAMPLING_POINTS, fetchImportSamplingPoints),
        takeLatest(actions.REMOVE_IMPORT_FILES, onRemoveImportFiles),
        takeLatest(actions.RERUN_MATCHING, rerunMatching),
        takeLatest(actions.SUBMIT_FIELD_BOUNDARY, submitFieldBoundary),
        takeLatest(actions.UNMATCH_FIELD_FILE, unmatchFieldFile),
        takeLatest(actions.UPDATE_EVENT_ID, updateEventId),
        takeLatest(actions.UPDATE_IMAGERY_ATTRIBUTE, updateImageryAttribute),
        takeLatest(actions.UPDATE_IMPORT_BOUNDARY, updateImportBoundary),
        takeLatest(actions.VALIDATE_IMPORT_EVENT, validateImportEvent),
        takeLatest(fieldModuleActions.SAVE_FIELD_SUCCEEDED, updateImportWizardData),
        takeLatest(notificationActions.API_CALL_ERROR, cancelLoader),
        takeLatest(panelActions.LOAD_IMPORT_WIZARD, initializeImportWizard),
        takeLatest(panelActions.CLOSE_IMPORT_WIZARD, closeImportWizard),
        takeLatest(actions.ADD_EQUIPMENT_IMPORT_FILTER, addEquipmentImportFilter),
        takeLatest(actions.FETCH_AGVANCE_FIELD_LIST, fetchAgvanceFieldList),
        takeLatest(actions.FETCH_AGVANCE_ORG_LEVEL_LIST, fetchAgvanceOrgLevelList),
        takeLatest(actions.SET_IGNORE_FARM, setIgnoreFarm),
        takeLatest(actions.UPDATE_USER_CROP_IMPORT_PREFERENCES, updateUserCropImportPreferences),
        messageSubscriptions(),
        fieldMatchMessageSubscriptions(),
    ]);
};
