import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import * as actions from "../actions";
import { getEulaInfo } from "../selectors";
import { messages } from "../i18n-messages";

import { Button, Section } from "../../core";
import { FormattingHelpers, AuthenticationAPI } from "@ai360/core";
const { LoginState } = actions;
export interface IEULA_Data {
    isFirstTimeLogin?: boolean;
    loginGuid?: string;
    privacyPolicyText?: string;
    privacyPolicyTitle?: string;
    privacyPolicyUpdatedDate?: string;
}

export interface IEULA_Props {
    eulaProps?: IEULA_Data;
    onError?: (error: Error) => void;
    onResetLogin: () => void;
    onSetLoginState: (action: number) => void;
    onStartProcessing: (action?: string) => void;
    onStopProcessing: () => void;
    intl: intlShape;
}

export interface IEULA_State {
    answer: string;
}
class EULA_ extends Component<IEULA_Props, IEULA_State> {
    txtAnswer: Record<string, any>;

    static propTypes = {
        onError: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            answer: "",
        };
    }

    private _handleError(error) {
        if (this.props.onError) {
            this.props.onError(error);
        }
        this.props.onStopProcessing();
        this.txtAnswer.input.focus();
    }

    public cancel() {
        this.props.onResetLogin();
    }

    public accept() {
        this.props.onStartProcessing();
        AuthenticationAPI.updateLastEulaAcceptance(this.props.eulaProps.loginGuid)
            .then(() => {
                this.props.onStopProcessing();
                if (this.props.eulaProps.isFirstTimeLogin) {
                    this.props.onSetLoginState(LoginState.SELECT_SECURITY_QUESTION);
                } else {
                    this.props.onSetLoginState(LoginState.SELECT_COMPANY);
                }
            })
            .catch((error) => this._handleError(error));
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { eulaProps } = this.props; // TODO: Remove login-logo-div and remove negative margin on login-eula-container
        return (
            <div className="login-eula-container">
                <div className="display-label">{eulaProps.privacyPolicyTitle}</div>
                <Section>
                    <div className="privacy-policy-body">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: eulaProps.privacyPolicyText,
                            }}
                        />
                        <br />
                        {formatMessage(messages.updatedText, {
                            date: FormattingHelpers.formatLongDate(
                                eulaProps.privacyPolicyUpdatedDate
                            ),
                        })}
                    </div>
                </Section>
                <div className="login-center">
                    <Button
                        tabIndex={2}
                        className="login-btn"
                        type="accept"
                        onClick={() => this.accept()}
                    />
                    <Button
                        tabIndex={3}
                        className="login-btn cancel-btn"
                        type="cancel"
                        onClick={() => this.cancel()}
                    />
                </div>
            </div>
        );
    }
}
export const EULA = injectIntl(EULA_);

const mapStateToProps = (state) => {
    return {
        eulaProps: getEulaInfo(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onResetLogin: () => dispatch(actions.resetLogin()),
        onSetLoginState: (loginState) => dispatch(actions.setLoginState(loginState)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EULA);
