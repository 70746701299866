import { createReducer } from "redux-act";
import * as actions from "./actions";
import { AgBytesItem } from "~/action-panel/components/event-module/components/event-info/components/event-scouting/models";
import { groupBy } from "lodash";

export const VARIETY_HYBRID_DATA_KEY = "VARIETY_HYBRID_DATA";

export const brandVarietyHybridListReducer = createReducer<{
    brandVarietyHybridList: Record<string, AgBytesItem[]>;
}>(
    {
        [actions.fetchVarietyHybridListSuccess as any]: (
            state,
            payload: { guid: string; response: AgBytesItem[] }
        ) => {
            if (payload.guid) {
                return {
                    ...state,
                    brandVarietyHybridList: {
                        ...state.brandVarietyHybridList,
                        [payload.guid]: payload.response,
                    },
                };
            }
            return {
                ...state,
                brandVarietyHybridList: {
                    ...groupBy(payload.response, (x) => x.parentGuid),
                },
            };
        },
        [actions.fetchVarietyHybridListFailed as any]: (state) => ({
            ...state,
            brandVarietyHybridList: {},
        }),
    },
    {
        brandVarietyHybridList: {},
    }
);
