import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { messages } from "./../i18n-messages";
import { getTheUserGuid } from "~/login/selectors";
import { VarietyHybridAPI } from "@ai360/core";
import { AutoSearch } from "~/core";
import { getAgBytesErrorClassNames } from "~/admin/utils";

export class VarietyHybridSearch extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        apiErrors: PropTypes.array,
        classNames: PropTypes.string,
        fetchData: PropTypes.func,
        formatMessage: PropTypes.func,
        initialValue: PropTypes.string,
        onSelection: PropTypes.func,
        openOnFocus: PropTypes.bool,
        userGuid: PropTypes.string,
        varietyHybridList: PropTypes.array,
    };

    static VARIETY_HYBRID_ID = "varietyHybridId";
    static VARIETY_HYBRID_NAME = "varietyHybridName";
    static VARIETY_HYBRID_GUID = "varietyHybridGuid";
    static DROPDOWN_NAME = "varietyHybridList";

    constructor(props) {
        super(props);
        this.state = {
            initialValue: "",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialValue) {
            this.setState({
                initialValue: nextProps.initialValue,
            });
        }
    }

    getAutoSearchList = (userGuid, searchText) => {
        return VarietyHybridAPI.searchVarietyHybrid(userGuid, {
            brandOrganizationGuid: "",
            cropGuid: "",
            searchText,
        }).then((filteredData) => {
            return filteredData.map((value) => {
                value.label = `${value[VarietyHybridSearch.VARIETY_HYBRID_ID]} - ${
                    value[VarietyHybridSearch.VARIETY_HYBRID_NAME]
                }`;
                return value;
            });
        });
    };

    onItemSelection = (data) => {
        if (data) {
            if (this.props.onSelection) {
                this.props.onSelection(
                    VarietyHybridSearch.VARIETY_HYBRID_ID,
                    data[VarietyHybridSearch.VARIETY_HYBRID_ID]
                );
                this.props.onSelection(
                    VarietyHybridSearch.VARIETY_HYBRID_NAME,
                    data[VarietyHybridSearch.VARIETY_HYBRID_NAME]
                );
                this.props.onSelection(
                    VarietyHybridSearch.VARIETY_HYBRID_GUID,
                    data[VarietyHybridSearch.VARIETY_HYBRID_GUID]
                );
            }
        } else {
            if (this.props.onSelection) {
                this.props.onSelection(VarietyHybridSearch.VARIETY_HYBRID_ID, "");
                this.props.onSelection(VarietyHybridSearch.VARIETY_HYBRID_NAME, "");
                this.props.onSelection(VarietyHybridSearch.VARIETY_HYBRID_GUID, "");
            }
        }
    };

    render() {
        const { autoFocus, classNames, openOnFocus, formatMessage, userGuid } = this.props;
        return (
            <AutoSearch
                classNames={getAgBytesErrorClassNames([201], this.props.apiErrors, [classNames])}
                autoFocus={autoFocus}
                openOnFocus={openOnFocus}
                getAutoSearchList={this.getAutoSearchList}
                initialFilterStr={this.state.initialValue}
                itemList={[]}
                keyProp={VarietyHybridSearch.VARIETY_HYBRID_ID}
                nameProp="label"
                onSelection={(item) => this.onItemSelection(item)}
                optionHeight={30}
                placeholderText={formatMessage(messages.searchVarietyHybrid)}
                required
                selectedValue={this.state.initialValue}
                userGuid={userGuid}
                forceUpdate
            />
        );
    }
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

export default connect(mapStateToProps, null)(VarietyHybridSearch);
