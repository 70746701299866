import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as selectors from "../../selectors";

import { DialogBox } from "~/core";

import "./batch-edit-recs-modal.css";

export class BatchEditRecsModal_ extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        batchRecDetailsForEdit: PropTypes.array.isRequired,
        equationGroupList: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }
    getPrimaryEquationSetText(rec, equationGroupList) {
        if (!rec) {
            return "";
        }
        const primaryEquationGuid = rec.recAreaList[0].recs[0].equationGroupGuid;
        const equationName = equationGroupList.find((eq) => {
            return eq.equationGroupGuid === primaryEquationGuid;
        })?.groupName;
        const displayEquationName = equationName ? equationName : "Unknown Equation";
        return (
            <div className="primary-rec">
                {rec.name}
                {" is using "}
                {displayEquationName}
            </div>
        );
    }

    getEquationSetText(batchRecs, equationGroupList) {
        if (!batchRecs || batchRecs.length < 1) {
            return "";
        }
        const primaryEquationGuid = batchRecs[0].recAreaList[0].recs[0].equationGroupGuid;
        const otherEquationNames = [];
        for (const rec of batchRecs) {
            const eqGuid = rec.recAreaList[0].recs[0].equationGroupGuid;
            if (eqGuid !== primaryEquationGuid) {
                const equation = equationGroupList.find((eq) => {
                    return eq.equationGroupGuid === eqGuid;
                });
                const equationName = equation ? equation.groupName : "an unknown equation set";
                otherEquationNames.push({
                    name: rec.name,
                    setName: equationName,
                    guid: eqGuid,
                });
            }
        }

        return (
            <ul className="secondary-list">
                {otherEquationNames.map((eq) => (
                    <li key={eq.setName}>
                        {eq.name}
                        {" is using "}
                        {eq.setName}
                    </li>
                ))}
            </ul>
        );
    }

    getValidationMessages() {
        const { validationWarnings } = this.state;
        const { formatMessage } = this.props.intl;

        return validationWarnings.map((warning) => {
            return (
                <div className="validation-message" key={warning.id}>
                    {formatMessage(warning)}
                </div>
            );
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen && this.props.isOpen !== nextProps.isOpen) {
            this.setState({ isLoading: true }, this.fetchOptions(nextProps));
        }
    }

    render() {
        const { isOpen, onClose, batchRecDetailsForEdit, equationGroupList } = this.props;
        // add function to get equation sets from each
        const msg = "All Recs selected must have the same Equation Set";
        const title = "Batch Edit Recs";
        const primaryEquation = this.getPrimaryEquationSetText(
            batchRecDetailsForEdit[0],
            equationGroupList
        );
        const otherEquations = this.getEquationSetText(batchRecDetailsForEdit, equationGroupList);
        return (
            <DialogBox
                draggable
                className="batch-edit-recs-modal"
                forceOverflow
                isOpen={isOpen}
                onClose={() => onClose()}
                title={title}
                unrestricted
            >
                {msg}
                {primaryEquation}
                <div>{"Unmatching Rec(s): "}</div>
                {otherEquations}
            </DialogBox>
        );
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
});

const mapStateToProps = (state) => ({
    equationGroupList: selectors.getEquationGroupList(state),
});

const BatchEditRecsModal = connect(mapStateToProps, mergeProps)(injectIntl(BatchEditRecsModal_));
export default BatchEditRecsModal;
