import * as model from "./model";

const importFilterSettingsDefaultRecord = () => ({
    [model.PROPS_APPLICATION_STANDARD_DEVIATION]: 2,
    [model.PROPS_EC_DATA_STANDARD_DEVIATION]: "",
    [model.PROPS_END_PASS_DELAY_SECONDS]: "",
    [model.PROPS_FIELD_MATCH_THRESHOLD_PERCENT]: "",
    [model.PROPS_IMPORT_DATA_FILTER_GUID]: "",
    [model.PROPS_MINIMUM_SWATH_WIDTH]: "",
    [model.PROPS_PLANTING_STANDARD_DEVIATION]: 2,
    [model.PROPS_START_PASS_DELAY_SECONDS]: "",
    [model.PROPS_SPEED_LOWER_PERCENTILE]: "",
    [model.PROPS_SPEED_MINIMUM_ABSOLUTE]: "",
    [model.PROPS_SPEED_SCALE]: "",
    [model.PROPS_SPEED_UPPER_PERCENTILE]: "",
    [model.PROPS_STANDARD_DEVIATION_THRESHOLD]: 1,
    [model.PROPS_YIELD_LOWER_PERCENTILE]: "",
    [model.PROPS_YIELD_MINIMUM_ABSOLUTE]: "",
    [model.PROPS_YIELD_SCALE]: "",
    [model.PROPS_YIELD_SMOOTH_VELOCITY]: "",
    [model.PROPS_YIELD_STANDARD_DEVIATION]: "",
    [model.PROPS_YIELD_UPPER_PERCENTILE]: "",
});

export default importFilterSettingsDefaultRecord;
