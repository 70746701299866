import React, { Component } from "react";
import { connect } from "react-redux";
import { AutoSearch } from "~/core";
import { getUser, getUsers } from "../../../login/selectors";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../i18n-messages";
import classnames from "classnames";
import { getAgBytesErrorClassNames } from "~/admin/utils";
import { OrgLevelInfo } from "~/customer-data/models";
import { UserAPI } from "@ai360/core";

interface IOrgLevelSearchProps {
    apiErrors: number[] | string[];
    classNames: string[];
    clearOnFocus: boolean;
    clearOnSelection: boolean;
    containerClassName: string;
    disabled: boolean;
    initialFilterStr: string;
    intl: intlShape.isRequired;
    itemList: OrgLevelInfo[];
    nameProp: string;
    onSearchChange: () => void;
    onSelection: (item: OrgLevelInfo) => void;
    placeholderText: string;
    required: boolean;
    selectedValue: string;
    showSearchIcon: boolean;
    showTopLabel: boolean;
    subNameProps: string[];
    user: UserAPI.IUser;
    theUsers: UserAPI.IUser[];
}

interface IOrgLevelSearchState {
    resetOptions: boolean;
}

class OrgLevelSearch_ extends Component<IOrgLevelSearchProps, IOrgLevelSearchState> {
    static defaultProps = {
        classNames: [],
        clearOnFocus: false,
        clearOnSelection: false,
        initialFilterStr: "",
        itemList: [],
        onSearchChange: null,
        required: false,
        selectedValue: "",
        showTopLabel: true,
        showSearchIcon: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            resetOptions: false,
        };
    }

    onResetOptions = () => {
        this.setState({
            resetOptions: false,
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.itemList !== this.props.itemList) {
            this.setState({
                resetOptions: true,
            });
        }
    }

    render() {
        const {
            classNames,
            clearOnFocus,
            clearOnSelection,
            containerClassName,
            disabled,
            initialFilterStr,
            itemList,
            nameProp,
            onSearchChange,
            onSelection,
            placeholderText,
            required,
            selectedValue,
            showTopLabel,
            showSearchIcon,
            subNameProps,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const filteredItemList = itemList.map((item) => {
            const filteredParents = item.orgLevelParents.slice(-2);
            return {
                ...item,
                parentsSummary: filteredParents?.join(" > "),
            };
        });

        return (
            <div className={classnames("form-section-child-stretch", containerClassName)}>
                <AutoSearch
                    classNames={classNames.concat(
                        getAgBytesErrorClassNames([443, 602], this.props.apiErrors)
                    )}
                    clearOnFocus={clearOnFocus}
                    clearOnSelection={clearOnSelection}
                    disabled={disabled}
                    initialFilterStr={initialFilterStr}
                    itemList={filteredItemList}
                    keyProp={OrgLevelSearch.ORG_LEVEL_GUID}
                    nameProp={nameProp != null ? nameProp : OrgLevelSearch.ORG_LEVEL_NAME}
                    onResetOptions={this.onResetOptions}
                    onSearchChange={onSearchChange}
                    onSelection={(item: OrgLevelInfo) => onSelection(item)}
                    placeholderText={placeholderText || formatMessage(messages.orgLevelFilter)}
                    required={required}
                    resetOptions={this.state.resetOptions}
                    secondaryPropList={subNameProps != null ? subNameProps : ["parentsSummary"]}
                    titlePropList={subNameProps != null ? subNameProps : ["parentsSummary"]}
                    selectedValue={selectedValue}
                    showTopLabel={showTopLabel}
                    showSearchIcon={showSearchIcon}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
        theUsers: getUsers(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export const OrgLevelSearch = connect(
    mapStateToProps,
    null,
    mergeProps
)(injectIntl(OrgLevelSearch_));

OrgLevelSearch.ORG_LEVEL_GUID = "orgLevelGuid";
OrgLevelSearch.ORG_LEVEL_NAME = "orgLevelName";
OrgLevelSearch.ORG_LEVEL_PARENT = "orgLevelParents";
OrgLevelSearch.ORG_LEVEL_CITY = "city";
OrgLevelSearch.ORG_LEVEL_STATE = "state";
