import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { DialogBox } from "~/core";
import { withUser } from "~/hocs";
import { GeometryUtils, GeometryMath } from "@ai360/core";
import { getSystemAreaLimit } from "~/login";

import { stopEditingFeature } from "./actions";
import * as selectors from "./selectors";
import { TitleBar } from "./components/title-bar/title-bar";
import { Menu } from "./components/menu/menu";
import Detail from "./components/detail/detail";
import { ButtonBar } from "./components/button-bar/button-bar";

import "./non-field-feature-information.css";
import { messages } from "./i18n-messages";

const isSavable = (feature) => {
    const hasOccupiedFeatureType = Boolean(feature.type) && feature.type.trim() !== "";
    if (!hasOccupiedFeatureType) {
        return false;
    }

    const featureHasName = feature.name.trim() !== "";
    if (!featureHasName) {
        return false;
    }

    const hasAtLeastOneGeometry = feature.geometries.length >= 1;
    if (!hasAtLeastOneGeometry) {
        return false;
    }

    return true;
};

const calculateTotalAcres = (geometries) => {
    return geometries.reduce((acres, geometry) => {
        if (geometry.shape.type === "polygon") {
            acres += GeometryUtils.calculateArea(geometry.shape);
        }
        return acres;
    }, 0);
};

const NonFieldFeatureInformation = (props) => {
    const { activeFeature, activeCustomer, maximumArea } = props;
    const { formatMessage } = props.intl;

    const [isInvalidGeometriesModalOpen, setIsInvalidGeometriesModalOpen] = useState(false);
    const [isMaxAreaModalOpen, setIsMaxAreaModalOpen] = useState(false);

    if (!activeFeature) {
        return null;
    }

    const totalAcres = calculateTotalAcres(activeFeature.geometries);

    const save = (feature) => {
        if (!isSavable(feature)) {
            return;
        }

        const anyPolygonsInvalid = feature.geometries
            .map((geometry) => geometry.shape)
            .filter((shape) => shape.type === "polygon")
            .some((shape) => !GeometryMath.isValidPolygon(shape));

        if (totalAcres > props.maximumArea) {
            setIsMaxAreaModalOpen(true);
        } else if (anyPolygonsInvalid) {
            setIsInvalidGeometriesModalOpen(true);
        } else {
            props.onClose(feature);
        }
    };

    const detailProps = { activeFeature, activeCustomer };

    return (
        <div className="non-field-feature-information">
            <TitleBar
                featureName={activeFeature.name}
                customerName={activeCustomer.name ? activeCustomer.name : ""}
                acres={totalAcres}
            />
            <div className="non-field-feature-information-content">
                <Menu />
                <Detail {...detailProps} />
            </div>
            <ButtonBar
                saveIsEnabled={isSavable(activeFeature)}
                onSave={() => save(activeFeature)}
                onCancel={() => props.onClose(null)}
            />
            <DialogBox
                title={formatMessage(messages.errorAboveMaxAcreageTitle)}
                isOpen={isMaxAreaModalOpen}
                onAction={() => setIsMaxAreaModalOpen(false)}
                onClose={() => setIsMaxAreaModalOpen(false)}
            >
                {formatMessage(messages.errorAboveMaxAcreage, {
                    maxAcres: maximumArea,
                })}
            </DialogBox>
            <DialogBox
                title={formatMessage(messages.errorInvalidGeometriesTitle)}
                isOpen={isInvalidGeometriesModalOpen}
                onClose={() => setIsInvalidGeometriesModalOpen(false)}
            >
                {formatMessage(messages.errorInvalidGeometries)}
            </DialogBox>
        </div>
    );
};

NonFieldFeatureInformation.propTypes = {
    activeCustomer: PropTypes.any,
    activeFeature: PropTypes.any,
    maximumArea: PropTypes.any,
    intl: intlShape.isRequired,
    onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
    activeFeature: selectors.getActiveFeature(state),
    activeCustomer: selectors.getActiveCustomer(state),
    maximumArea: getSystemAreaLimit(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: (saved) => dispatch(stopEditingFeature(saved)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withUser(injectIntl(NonFieldFeatureInformation)));
