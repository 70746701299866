import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { Menu, DialogBox, DialogBoxFooterType } from "~/core";
import { messages } from "../../../context-menus/i18n-messages";
import * as fieldActions from "../../../../components/field-module/actions";
import * as analysisModels from "~/recs-events/analysis/model";
import * as cdSelectors from "~/customer-data/selectors";
import { ICustomerFieldMapping } from "~/customer-data/models";
import { eventListSelectors } from "../../../event-module";
import { eventsSelectors } from "~/recs-events";
import * as analysisInfoActions from "../analysis-info/actions";
import { isImportedHarvest } from "../../utils";

import _ from "lodash";
import { AgEventSummary } from "~/recs-events/events/models";

const MAX_LAYER_ITEM_EXPAND_COUNT = 25;

interface IProps {
    accordionItemsCount: number;
    expandedCount: number;
    intl: intlShape;
    onAddAnalysisLayer: (fieldGuids: string[], analysisLayerType: string) => void;
    onClearSelected: () => void;
    onCollapseAll: () => void;
    onExpandAll: () => void;
    selectedCount: number;
    selectedCustomerFields: ICustomerFieldMapping[];
    userInfo: any;
    selectedEventGuidSet: Set<string>;
    selectedEventSummary: Map<string, AgEventSummary>;
    onSetBatchNormalizedYield: (isBatchNormalizedYield: boolean) => void;
}

interface IState {
    errorMessages: any[];
}

class OperationalLayersContextMenu_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessages: [],
        };
    }

    private isAnyImportedHarvestEventSelected() {
        const importHarvestFieldGuid: string[] = [];
        const errorMessages = [];
        const {
            selectedEventSummary,
            selectedEventGuidSet,
            onAddAnalysisLayer,
            onSetBatchNormalizedYield,
        } = this.props;

        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = selectedEventSummary.has(eventGeneralGuid)
                ? selectedEventSummary.get(eventGeneralGuid)
                : null;
            if (isImportedHarvest(event)) {
                importHarvestFieldGuid.push(event.fieldGuid);
            }
        }
        if (importHarvestFieldGuid.length > 0) {
            const uniqueFieldGuids = [...new Set(importHarvestFieldGuid)];
            onSetBatchNormalizedYield(true);
            onAddAnalysisLayer(
                uniqueFieldGuids,
                analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD
            );
        } else {
            errorMessages.push(messages.required);
            this.setState({ errorMessages: errorMessages });
        }
    }

    private onDialogBoxClose() {
        this.setState({
            errorMessages: [],
        });
    }

    private getSubMenuItems() {
        const { selectedCustomerFields, onAddAnalysisLayer, userInfo } = this.props;
        const { formatMessage } = this.props.intl;
        const customerGuids = selectedCustomerFields.map((x) => x.customerGuid);
        const fieldGuids = selectedCustomerFields.map((x) => x.fieldGuid);
        const layerTypes = userInfo.layerTypesAccess.map((x) => x.name);
        const subItems = [];

        if (layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_EC_DATA)) {
            subItems.push({
                disabled: _.uniq(customerGuids).length !== 1 || fieldGuids.length < 2,
                label: formatMessage(messages.multiFieldEcDataText),
                action: () =>
                    onAddAnalysisLayer(fieldGuids, analysisModels.ANALYSIS_INFO_NAME_EC_DATA),
            });
        }
        if (layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD)) {
            subItems.push({
                label: formatMessage(messages.normalizedYieldText),
                action: () => this.isAnyImportedHarvestEventSelected(),
            });
        }
        return subItems;
    }

    private getMenuItems() {
        const { accordionItemsCount, expandedCount, selectedCount, userInfo } = this.props;
        const { formatMessage } = this.props.intl;

        const items = [];
        const userRole = userInfo.role;
        const layerTypes = userInfo.layerTypesAccess.map((x) => x.name);

        if (userRole.batchAnalysisLayers) {
            if (
                layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_EC_DATA) ||
                layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD) ||
                layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP)
            ) {
                items.push({
                    label: formatMessage(messages.newBatchAnalysis),
                    subMenuItems: this.getSubMenuItems(),
                });
            }
        }

        return items.concat(
            [
                {
                    disabled:
                        accordionItemsCount > MAX_LAYER_ITEM_EXPAND_COUNT ||
                        accordionItemsCount === expandedCount,
                    label: formatMessage(messages.expandAll),
                    action: () => this.props.onExpandAll(),
                },
                {
                    disabled: expandedCount === 0,
                    label: formatMessage(messages.collapseAll),
                    action: () => this.props.onCollapseAll(),
                },
                {
                    disabled: selectedCount === 0,
                    label: formatMessage(messages.clearSelected),
                    action: () => this.props.onClearSelected(),
                },
            ].map((menuItem, key) => {
                return { ...menuItem, key };
            })
        );
    }

    render() {
        const { errorMessages } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div className="context-menu-container" onClick={(evt) => evt.preventDefault()}>
                <Menu
                    className="context-menu"
                    isDotMenu={true}
                    getMenuItems={() => this.getMenuItems()}
                />
                {errorMessages.length > 0 ? (
                    <DialogBox
                        footerType={DialogBoxFooterType.NONE}
                        isOpen={errorMessages.length > 0}
                        onClose={() => this.onDialogBoxClose()}
                        title={formatMessage(messages.required)}
                    >
                        <div>{formatMessage(messages.selectEventError)}</div>
                    </DialogBox>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedCustomerFields: cdSelectors.getSelectedCustomerFields(state),
    selectedEventGuidSet: eventListSelectors.getSelectedEventGuidSet(state),
    selectedEventSummary: eventsSelectors.getEventGeneralGuidToEventSummaryMap(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetBatchNormalizedYield: (isBatchNormalizedYield) =>
        dispatch(analysisInfoActions.setBatchNormalizedYield(isBatchNormalizedYield)),
    onAddAnalysisLayer: (fieldGuids, analysisLayerType) =>
        dispatch(fieldActions.addAnalysisLayer(fieldGuids, analysisLayerType)),
});

export const OperationalLayersContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(OperationalLayersContextMenu_));
