import React, { Component } from "react";
import "./draggable-list.css";
import { DraggableIcon, PencilIcon, TrashcanIcon } from "~/core/icons";
import Sortable from "sortablejs";
const PIXEL = "px";

export interface IDraggableListProps {
    data?: Array<any>;
    displayLabel?: string;
    editMode?: boolean;
    onDeleteItem?: (item: Record<string, any>, index: number) => void;
    onEditItem?: (item: Record<string, any>, index: number) => void;
    onListUpdate?: (data: Record<string, any>) => void;
    rowRenderer?: (item: Record<string, any>, index: number) => Record<string, any>;
    _toggleDisableSort?: (flag: boolean) => void;
}

export interface IDraggableListState {
    data: Array<any>;
}
export class DraggableList extends Component<IDraggableListProps, IDraggableListState> {
    _sortable: Sortable;

    static defaultProps = {
        data: [],
    };
    ///----------------------------///
    constructor(props: IDraggableListProps) {
        super(props);
        this.state = {
            data: props.data,
        };
    }

    private _updateListStyling = (): void => {
        this._sortable?.toArray().forEach((itemIndex, index) => {
            // Todo: Find a better way to do this -
            document.getElementById(itemIndex).style.paddingLeft = `${index * 10}${PIXEL}`;
        });
    };

    private _toggleDisableSort = (flag: boolean): void => {
        this._sortable?.option("disabled", flag);
    };

    private _onUpdate = (): void => {
        const data = this._sortable?.toArray().map((itemIndex, index) => {
            const existingItem = this.state.data.find((item) => {
                return itemIndex === item.name;
            });
            return {
                ...existingItem,
                level: index * 10,
            };
        });
        this.setState(
            {
                data,
            },
            () => {
                if (this.props.onListUpdate) {
                    this.props.onListUpdate(data);
                }
                this._sortable?.sort(data.map((item) => item.name));
                this._updateListStyling();
            }
        );
    };

    public sortableContainersDecorator = (componentBackingInstance: HTMLElement): void => {
        // check if backing instance not null
        if (componentBackingInstance) {
            const options = {
                handle: ".group", // Restricts sort start click/touch to the specified element
                dataIdAttr: "data-id",
                onUpdate: this._onUpdate,
            };
            this._sortable = Sortable.create(componentBackingInstance, options);
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps: IDraggableListProps): void {
        if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
            this.setState(
                {
                    data: nextProps.data,
                },
                () => {
                    this._sortable?.sort(nextProps.data.map((item) => item.name));
                    this._updateListStyling();
                }
            );
        }

        if (nextProps.editMode !== this.props.editMode) {
            this._toggleDisableSort(nextProps.editMode);
        }
    }

    componentDidMount(): void {
        this._updateListStyling();
    }

    render(): JSX.Element {
        const { displayLabel, rowRenderer, onEditItem, onDeleteItem } = this.props;
        return (
            <div className="draggable-list-cont" ref={this.sortableContainersDecorator}>
                {this.state.data.map((item, index) => (
                    <div
                        key={`draggable-${index}`}
                        data-id={item.name}
                        id={item[displayLabel]}
                        className="group"
                    >
                        {rowRenderer != null ? (
                            rowRenderer(item, index)
                        ) : (
                            <div className="draggable-list-row" key={index}>
                                <DraggableIcon className="draggable-icon" />
                                <div className="draggable-text">
                                    <span title={item.name}>{item.name}</span>
                                </div>
                                <div onClick={() => onEditItem(item, index)}>
                                    <PencilIcon className="draggable-pencil-icon" />
                                </div>
                                {!item.canDelete ? null : (
                                    <div onClick={() => onDeleteItem(item, index)}>
                                        <TrashcanIcon className="draggable-trashcan-icon" />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}
