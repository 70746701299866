import { all } from "redux-saga/effects";

import { actionPanelSaga } from "~/action-panel";
import adminSaga from "~/admin/saga";
import { authenticationSaga } from "~/authentication/sagas";
import { dragAndDropFileUploaderSaga } from "~/action-panel/components/common/drag-and-drop-file-uploader";
import { dropDownSaga } from "~/core/dropdowns/saga";
import { customerDataSaga } from "~/customer-data";
import { fileImportSaga } from "~/file-import";
import { userSaga } from "~/login";
import { themeSaga } from "~/theme";
import { mapSaga } from "~/map";
import { navSaga } from "~/navigation/sagas";
import { picklistSaga } from "~/core/picklist/saga";
import { productBlendSaga } from "~/action-panel/components/common/product-blend-modal";
import { recsEventsSaga } from "~/recs-events";
import { unitsSaga } from "~/core/units";
import needsRequest from "~/hocs/needs/saga";
import { notificationsSaga } from "~/notifications";
import { messagingSaga } from "~/messaging";
import { reportBooksSaga } from "~/report-books";
import { reportCoreSagas } from "~/reports";
import { analyticsSaga } from "~/analytics/sagas";
import { windowSaga } from "~/window/sagas";

export const rootSaga = function* () {
    yield all([
        messagingSaga(),
        actionPanelSaga(),
        adminSaga(),
        authenticationSaga(),
        analyticsSaga(),
        customerDataSaga(),
        dragAndDropFileUploaderSaga(),
        dropDownSaga(),
        fileImportSaga(),
        mapSaga(),
        navSaga(),
        needsRequest(),
        notificationsSaga(),
        picklistSaga(),
        productBlendSaga(),
        recsEventsSaga(),
        reportBooksSaga(),
        reportCoreSagas(),
        themeSaga(),
        unitsSaga(),
        userSaga(),
        windowSaga(),
    ]);
};
