import PropTypes from "prop-types";
import { model } from "~/admin/agBytes/variety-hybrid-attribute/data";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_CAN_DELETE]: PropTypes.bool,
    [model.PROPS_VARIETY_HYBRID_ATTRIBUTE_GUID]: PropTypes.string,
    [model.PROPS_VARIETY_HYBRID_ID]: PropTypes.number,
    [model.PROPS_VARIETY_HYBRID_NAME]: PropTypes.string,
    [model.PROPS_VARIETY_ATTRIBUTE_CATEGORY]: PropTypes.string,
    [model.PROPS_VARIETY_ATTRIBUTE_NAME]: PropTypes.string,
    [model.PROPS_ATTRIBUTE_VALUE]: PropTypes.string,
    [model.PROPS_AGBYTES_VALUE]: PropTypes.string,
    [model.PROPS_MODIFIED_DATE]: PropTypes.string,
};

export default PropTypes.shape(shape);
