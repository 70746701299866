import { AgEventAPI } from "@ai360/core";
import { IAgEventModelMethods } from "../model";

export class AgEventHarvest implements AgEventAPI.IAgEventHarvest, IAgEventModelMethods {
    agEventGuid = "";
    eventAreaGuid = "";
    averageMoisturePercentOriginal = "";
    averageMoisturePostCalibrated = "";
    averageTestWeight = "";
    averageTestWeightIAGuid = "";
    averageUnitWeight = "";
    averageYieldIAGuid = "";
    averageYieldCleaned = "";
    averageYieldOriginal = "";
    baleAverageWeight = "";
    baleAverageWeightIAGuid = "";
    baleMoisturePercent = "";
    balesNumberOf = "";
    baleTypeGuid = "";
    brandOrganizationGuid = "";
    cottonColor = "";
    cottonFiberLength = "";
    cottonFiberLengthIAGuid = "";
    cottonFiberStrength = "";
    cottonGinTurnOutPercent = "";
    cottonLeafGrade = "";
    cottonLengthUniformityPercent = "";
    cottonLint = null;
    cottonLintIAGuid = "";
    cottonMicronaire = "";
    cottonModuleNumber = "";
    cottonSeed = "";
    cottonSeedIAGuid = "";
    cottonTrash = "";
    cottonTrashIAGuid = "";
    cottonTrashPercent = "";
    cropGuid = "";
    cropPurposeGuid = "";
    daysInWindrow = "";
    forageCuttingNumber = "";
    forageNetEnergyGain = "";
    forageNetEnergyLactation = "";
    forageNetEnergyMaintenance = "";
    forageQualityTestAdfPercent = "";
    forageQualityTestCaPercent = "";
    forageQualityTestCpPercent = "";
    forageQualityTestDdmPercent = "";
    forageQualityTestDmiPercent = "";
    forageQualityTestNdfPercent = "";
    forageQualityTestPPercent = "";
    forageQualityTestRfqPercent = "";
    forageQualityTestRfvPercent = "";
    forageQualityTestTdnPercent = "";
    harvestResults = [];
    isImported = false;
    ldpPaymentAmount = "";
    ldpPaymentIAGuid = "";
    maxSwathWidth = "";
    maxSwathWidthIAGuid = "";
    overallRatingGuid = "";
    postCalibrated = "";
    priceCurrentPrice = "";
    priceDefaultIAGuid = "";
    pricesPremiumSoldPrice = "";
    pricesSoldPrice = "";
    qualityDamagePercent = "";
    qualityForeignMatterPercent = "";
    qualityInertMatterPercent = "";
    qualityLargeSizePercent = "";
    qualityOilContentPercent = "";
    qualityProteinContentPercent = "";
    totalIncome = "";
    varietyHybridGuid = "";
    windrowRainedOn = false;

    get isAllRequiredFieldsSet(): boolean {
        return this.cropGuid && this.cropGuid !== "";
    }

    get isAnyRequiredFieldSet(): boolean {
        return this.isAllRequiredFieldsSet;
    }
    updateAgEventModel(newProps: Partial<AgEventHarvest>): AgEventHarvest {
        return Object.assign(new AgEventHarvest(), this, newProps);
    }
}
