export const FETCH_LAYER_PREFERENCES_FAILED = "map/FETCH_LAYER_PREFERENCES_FAILED";
export const FETCH_LAYER_PREFERENCES_INIT = "map/FETCH_LAYER_PREFERENCES_INIT";
export const FETCH_LAYER_PREFERENCES_SUCCEEDED = "map/FETCH_LAYER_PREFERENCES_SUCCEEDED";
export const SELECT_ALL_LAYERS = "map/SELECT_ALL_LAYERS";
export const SET_BASEMAP = "map/SET_BASEMAP";
export const SET_FIELD_BOUNDARY_LAYER_INFO = "map/SET_FIELD_BOUNDARY_LAYER_INFO";
export const SET_FIELDS_BACKGROUND_ONLY = "map/SET_FIELDS_BACKGROUND_ONLY";
export const SET_FIELDS_BACKGROUND_ONLY_BATCH = "map/SET_FIELDS_BACKGROUND_ONLY_BATCH";
export const SET_FIELDS_TO_INVALIDATE = "map/SET_FIELDS_TO_INVALIDATE";
export const SET_FILTERED_POINT_COUNT = "map/SET_FILTERED_POINT_COUNT";
export const SET_FORCE_REFRESH_FLAG = "map/SET_FORCE_REFRESH_FLAG";
export const SET_LAYER_INFOS = "map/SET_LAYER_INFOS";
export const SET_LAYER_INFOS_AND_SAVE = "map/SET_LAYER_INFOS_AND_SAVE";
export const SET_MAP_LOADING = "map/SET_MAP_LOADING";
export const SET_CANVAS_LOADING = "map/SET_CANVAS_LOADING";
export const SET_MAP_READY = "map/SET_MAP_READY";
export const SET_NON_FIELD_FEATURE_LAYER_INFO = "map/SET_NON_FIELD_FEATURE_LAYER_INFO";
export const SET_ZOOM_AND_SCALE = "map/SET_ZOOM_AND_SCALE";
export const SET_ZOOM_TO_EXTENT = "map/SET_ZOOM_TO_EXTENT";
export const SET_ZOOM_TO_CUSTOMER = "map/SET_ZOOM_TO_CUSTOMER";
export const SET_ZOOM_TO_FARM = "map/SET_ZOOM_TO_FARM";
export const SET_ZOOM_TO_FIELD = "map/SET_ZOOM_TO_FIELD";
export const SET_ZOOM_TO_FIELD_LIST = "map/SET_ZOOM_TO_FIELD_LIST";
export const SET_ZOOM_TO_NON_FIELD_FEATURES = "map/SET_ZOOM_TO_NON_FIELD_FEATURES";
export const UNSELECT_ALL_LAYERS = "map/UNSELECT_ALL_LAYERS";
export const UPDATE_CADASTRAL_LAYER_INFO = "map/UPDATE_CADASTRAL_LAYER_INFO";

export const fetchLayerPreferences = (userGuid) => ({
    type: FETCH_LAYER_PREFERENCES_INIT,
    userGuid,
});

export const fetchLayerPreferencesSucceeded = (payload) => ({
    type: FETCH_LAYER_PREFERENCES_SUCCEEDED,
    payload,
});

export const setForceRefreshFlag = (flag) => ({
    type: SET_FORCE_REFRESH_FLAG,
    flag,
});

export const selectAllLayers = () => ({
    type: SELECT_ALL_LAYERS,
});

export const setBasemap = (basemap) => ({
    type: SET_BASEMAP,
    basemap,
});

export const setFieldBoundaryLayerInfo = ({ showLabels, visible }) => ({
    type: SET_FIELD_BOUNDARY_LAYER_INFO,
    fieldBoundaryLayerInfo: {
        showLabels: { ...showLabels },
        visible,
    },
});

export const setFieldsBackgroundOnly = (fieldsBackgroundOnly) => ({
    type: SET_FIELDS_BACKGROUND_ONLY,
    fieldsBackgroundOnly: fieldsBackgroundOnly,
});

export const setFieldsBackgroundOnlyBatch = (fieldsBackgroundOnlyBatch) => ({
    type: SET_FIELDS_BACKGROUND_ONLY_BATCH,
    fieldsBackgroundOnlyBatch: fieldsBackgroundOnlyBatch,
});

export const setFieldsToInvalidate = (fieldGuids) => ({
    type: SET_FIELDS_TO_INVALIDATE,
    fieldGuidsToInvalidate: Array.isArray(fieldGuids) ? fieldGuids : [fieldGuids],
});

export const setFilteredPointCount = (filteredPointCount) => ({
    type: SET_FILTERED_POINT_COUNT,
    filteredPointCount,
});

export const setIsLoading = (isLoading, force = false) => ({
    type: SET_MAP_LOADING,
    force,
    isLoading,
});

export const setIsCanvasLoading = (isCanvasLoading) => ({
    type: SET_CANVAS_LOADING,
    isCanvasLoading,
});

export const setLayerInfos = (
    cadastralLayerInfos,
    fieldBoundaryLayerInfo,
    nonFieldFeatureLayerInfo
) => ({
    type: SET_LAYER_INFOS,
    cadastralLayerInfos,
    fieldBoundaryLayerInfo,
    nonFieldFeatureLayerInfo,
});

export const setLayerInfosAndSave = (
    cadastralLayerInfos,
    fieldBoundaryLayerInfo,
    nonFieldFeatureLayerInfo
) => ({
    type: SET_LAYER_INFOS_AND_SAVE,
    cadastralLayerInfos,
    fieldBoundaryLayerInfo,
    nonFieldFeatureLayerInfo,
});

export const setMapReady = () => ({
    type: SET_MAP_READY,
});

export const setNonFieldFeatureLayerInfo = (visible) => ({
    type: SET_NON_FIELD_FEATURE_LAYER_INFO,
    visible,
});

export const setZoomAndScale = (zoom, scale) => ({
    type: SET_ZOOM_AND_SCALE,
    zoom,
    scale,
});

export const setZoomLevel = (zoom) => ({
    type: SET_ZOOM_AND_SCALE,
    zoom,
});

export const setZoomToCustomer = (customerGuid) => ({
    type: SET_ZOOM_TO_CUSTOMER,
    customerGuid,
});

export const setZoomToFarm = (customerGuid, farmName) => ({
    type: SET_ZOOM_TO_FARM,
    customerGuid,
    farmName,
});

export const setZoomToField = (fieldGuid) => ({
    type: SET_ZOOM_TO_FIELD,
    fieldGuid,
});

export const setZoomToFieldList = (fieldGuidList) => ({
    type: SET_ZOOM_TO_FIELD_LIST,
    fieldGuidList,
});

export const setZoomToNonFieldFeatures = (features) => ({
    type: SET_ZOOM_TO_NON_FIELD_FEATURES,
    features,
});

export const unselectAllLayers = () => ({
    type: UNSELECT_ALL_LAYERS,
});

export const updateCadastralLayerInfo = (cadastralLayerInfo) => ({
    type: UPDATE_CADASTRAL_LAYER_INFO,
    cadastralLayerInfo,
});
