import React, { Component } from "react";
import PropTypes from "prop-types";
import { ReportTable, ReportOptionsContainer, ReportTypes } from "../components";
import { injectIntl, intlShape } from "react-intl";
import { ReportAPI } from "@ai360/core";
import { withReport } from "../hocs";
import { keywords } from "../keywords";
import "../reports.css";

const service = {
    REPORT_TYPE_LIST_URL: ReportAPI.GET_SUMMARY_REPORT_TYPE_LIST_URL,
    REPORT_TYPE_PAGE_LIST_URL: ReportAPI.GET_REPORT_TYPE_PAGE_LIST_URL,
};

export class SummaryReport_ extends Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        createReport: PropTypes.func,
        createReportHubProgress: PropTypes.bool,
        crops: PropTypes.array,
        initialRequestOptions: PropTypes.object,
        initializeReportData: PropTypes.func,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        onAllReportCreated: PropTypes.func,
        onInputChange: PropTypes.func,
        reportPreferences: PropTypes.object,
        reportTypes: PropTypes.array,
        reportTypePages: PropTypes.array,
        seasons: PropTypes.array,
        selectReportType: PropTypes.func,
        selectedReportTypes: PropTypes.array,
    };
    static defaultProps = {
        reportTypePages: [],
    };

    /// Event Handlers ///
    ///--------------------------///
    render() {
        const { formatMessage } = this.props.intl;
        /// Property set up, etc. ///
        return (
            <div className="reports-container">
                <div className="reports-options-container">
                    <ReportTypes
                        selectReportType={this.props.selectReportType}
                        reportTypes={this.props.reportTypes}
                        selectedReportTypes={this.props.selectedReportTypes}
                        formatMessage={formatMessage}
                    />
                    <ReportOptionsContainer
                        createReport={this.props.createReport}
                        crops={this.props.crops}
                        seasons={this.props.seasons}
                        formatMessage={formatMessage}
                        onInputChange={this.props.onInputChange}
                        reportPreferences={this.props.reportPreferences}
                        reportTypePages={this.props.reportTypePages}
                        selectedReportTypes={this.props.selectedReportTypes}
                    />
                </div>
                <ReportTable
                    createReportHubProgress={this.props.createReportHubProgress}
                    initialRequestOptions={this.props.initialRequestOptions}
                    needs={this.props.needs}
                    onAllReportCreated={this.props.onAllReportCreated}
                    url={ReportAPI.GET_SUMMARY_REPORT_LIST_URL}
                    autoSearchUrl={ReportAPI.GET_SUMMARY_REPORT_AUTOSEARCH_URL}
                    selectAllUrl={ReportAPI.GET_SUMMARY_SELECT_ALL_URL}
                    reportName={keywords.summary}
                />
            </div>
        );
    }
}

export const SummaryReport = withReport(injectIntl(SummaryReport_), service);
