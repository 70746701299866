import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { DataTable } from "~/core";
import { withCrud, withNewApiResult, withValidateImportResults, withMasked } from "~/hocs";
import { IAgBytesProps, IAgBytesState } from "../interfaces";
import { actions, service } from "./data";
import { ImportExportHeader } from "../components/import-export-header";
import { messages } from "../i18n-messages";

class CountryState_ extends Component<IAgBytesProps<typeof actions, void>, IAgBytesState> {
    constructor(props: IAgBytesProps<typeof actions, void>) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    public onToggleModalClick = (): void => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    render(): JSX.Element {
        return (
            <div className="content-table-container">
                <ImportExportHeader
                    onlyImportExportOptions
                    {...this.props}
                    service={service}
                    onToggleModalClick={this.onToggleModalClick}
                    onToggleActiveClick={service.toggleColumnVisibility}
                />
                <DataTable service={service} messages={messages} {...this.props} isCheckbox />
            </div>
        );
    }
}

export const CountryState = injectIntl(
    withMasked(
        withNewApiResult(
            withValidateImportResults(
                withCrud(CountryState_, service, actions, true),
                actions.importData
            )
        )
    )
);
