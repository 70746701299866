import * as model from "./model";

export const defaultEquipmentSetupRecord = () => ({
    [model.ACTIVE_YN]: true,
    [model.PROPS_ACCESSORY_LIST]: [],
    [model.PROPS_ACCUM_DEPRECIATION_AS_OF_YEAR_END]: "",
    [model.PROPS_AGGATEWAY_ID]: "",
    [model.PROPS_BIN_TANK_LIST]: [],
    [model.PROPS_BOOK_VALUE_AS_OF_YEAR_END]: "",
    [model.PROPS_BOOM_NOZZLES_PER_SECTION]: "",
    [model.PROPS_BOOM_NUMBER_OF_SECTIONS]: "",
    [model.PROPS_BOOM_SECTION_LENGTH]: "",
    [model.PROPS_BOOM_SECTION_LENGTH_UNIT_GUID]: "",
    [model.PROPS_CAB]: false,
    [model.PROPS_CHEMIGATION]: false,
    [model.PROPS_CLASS_SIZE]: "",
    [model.PROPS_CLASS_SIZE_GUID]: "",
    [model.PROPS_CONTAINER_SIZE]: "",
    [model.PROPS_CONTAINER_SIZE_UNIT_GUID]: "",
    [model.PROPS_CONTROLLER_ID]: "",
    [model.PROPS_CURRENT_YEAR_DEPRECIATION]: "",
    [model.PROPS_CYLINDERS]: "",
    [model.PROPS_DESCRIPTION]: "",
    [model.PROPS_DIMENSION_UNIT_GUID]: "",
    [model.PROPS_DRIVE_TRAIN_GUID]: "",
    [model.PROPS_ENGINE_DISPLACEMENT]: "",
    [model.PROPS_EQUIPMENT_GUID]: "",
    [model.PROPS_EQUIPMENT_ID]: "",
    [model.PROPS_EQUIPMENT_NAME]: "",
    [model.PROPS_EQUIPMENT_PARENT_TYPE_GUID]: "",
    [model.PROPS_EQUIPMENT_PARENT_TYPE_GUID]: "",
    [model.PROPS_EQUIPMENT_PHOTOLIST]: [],
    [model.PROPS_EQUIPMENT_PHOTO_LIST]: [],
    [model.PROPS_EQUIPMENT_PROFILE_GUID]: "",
    [model.PROPS_EQUIPMENT_PROFILE_GUID]: "",
    [model.PROPS_EQUIPMENT_TYPE_ALIAS_LIST]: [],
    [model.PROPS_EQUIPMENT_TYPE_GUID]: "",
    [model.PROPS_EQUIPMENT_TYPE_GUID]: "",
    [model.PROPS_FERTIGATION]: false,
    [model.PROPS_FUELING_EVENT_LIST]: [],
    [model.PROPS_FUELING_EVENT_LIST]: [],
    [model.PROPS_FUEL_CAPACITY]: "",
    [model.PROPS_FUEL_CAPACITY_UNIT_GUID]: "",
    [model.PROPS_FUEL_EFFICIENCY]: "",
    [model.PROPS_FUEL_EFFICIENCY_UNIT_GUID]: "",
    [model.PROPS_FUEL_TYPE_GUID]: "",
    [model.PROPS_HEIGHT]: "",
    [model.PROPS_HITCH_TYPE_GUID]: "",
    [model.PROPS_HORSE_POWER]: "",
    [model.PROPS_HORSE_POWER_UNIT_GUID]: "",
    [model.PROPS_LENGTH]: "",
    [model.PROPS_MAINTENANCE_LIST]: [],
    [model.PROPS_MAINTENANCE_LIST]: [],
    [model.PROPS_MANUFACTURER_GUID]: "",
    [model.PROPS_MANUFACTURER_GUID]: "",
    [model.PROPS_MAX_GROSS_WEIGHT]: "",
    [model.PROPS_MAX_SPEED]: "",
    [model.PROPS_MAX_SPEED_UNIT_GUID]: "",
    [model.PROPS_MODEL_NAME]: "",
    [model.PROPS_MODEL_NAME]: "",
    [model.PROPS_MODEL_YEAR]: "",
    [model.PROPS_NOTES]: "",
    [model.PROPS_NOZZLE_LIST]: [],
    [model.PROPS_NOZZLE_LIST]: [],
    [model.PROPS_NOZZLE_SPACING]: "",
    [model.PROPS_NOZZLE_SPACING_UNIT_GUID]: "",
    [model.PROPS_NUMBER_OF_SPRINKLERS]: "",
    [model.PROPS_ORG_LEVEL]: "",
    [model.PROPS_ORG_LEVEL_GUID]: "",
    [model.PROPS_ORG_LEVEL_PARENT]: "",
    [model.PROPS_OWNER_OPERATOR_CITY]: "",
    [model.PROPS_OWNER_OPERATOR_CUSTOMER_GUID]: "",
    [model.PROPS_OWNER_OPERATOR_NAME]: "",
    [model.PROPS_OWNER_OPERATOR_ORG_LEVEL_GUID]: "",
    [model.PROPS_OWNER_OPERATOR_STATE_ABBREVIATION]: "",
    [model.PROPS_OWNER_OPERATOR_VENDOR_GUID]: "",
    [model.PROPS_PHOTO_LIST]: [],
    [model.PROPS_PHOTO_LIST]: [],
    [model.PROPS_PHYSICAL_STATE_GUID]: "",
    [model.PROPS_PIVOT_SIZE]: "",
    [model.PROPS_PIVOT_SIZE_UNIT_GUID]: "",
    [model.PROPS_PLANTER_NUMBER_OF_ROWS]: "",
    [model.PROPS_PLANTER_ROW_SPACING]: "",
    [model.PROPS_PLANTER_ROW_SPACING_UNIT_GUID]: "",
    [model.PROPS_PUR_DATE]: null,
    [model.PROPS_PUR_DATE_USAGE_TOTALAREA]: "",
    [model.PROPS_PUR_DATE_USAGE_TOTAL_AREA_UNIT_GUID]: "",
    [model.PROPS_PUR_DATE_USAGE_TOTAL_USAGE]: "",
    [model.PROPS_PUR_DATE_USAGE_TOTAL_USAGE_UNIT_GUID]: "",
    [model.PROPS_PUR_DATE_USAGE_TOTAL_VOLUME]: "",
    [model.PROPS_PUR_DATE_USAGE_TOTAL_VOLUME_UNIT_GUID]: "",
    [model.PROPS_PUR_PRICE]: "",
    [model.PROPS_RETIRED_DATE]: null,
    [model.PROPS_SERIAL_NUMBER]: "",
    [model.PROPS_SWATH_WIDTH]: "",
    [model.PROPS_TARE_WEIGHT]: "",
    [model.PROPS_TELEMATIC_ACTIVE_YN]: false,
    [model.PROPS_TELEMATIC_PRIMARY_USER_GUID]: "",
    [model.PROPS_TELEMATIC_EQUIPMENT_NODE_GUID]: "",
    [model.PROPS_TIRES_ASPECT_RATIO]: "",
    [model.PROPS_TIRES_LOAD_RATING]: "",
    [model.PROPS_TIRES_LOAD_RATING_UNIT_GUID]: "",
    [model.PROPS_TIRES_NUMBER_OF_TIRES]: "",
    [model.PROPS_TIRES_REC_PRESSURE]: "",
    [model.PROPS_TIRES_REC_PRESSURE_UNIT_GUID]: "",
    [model.PROPS_TIRES_RIM_DIAMETER]: "",
    [model.PROPS_TIRES_RIM_DIAMETER_UNIT_GUID]: "",
    [model.PROPS_TIRES_SPEED_RATING]: "",
    [model.PROPS_TIRES_TYPE_GUID]: "",
    [model.PROPS_TIRE_WIDTH]: "",
    [model.PROPS_TOTAL_APPLICATION_WIDTH]: "",
    [model.PROPS_TOTAL_APPLICATION_WIDTH_GUID]: "",
    [model.PROPS_TOWING_CAPACITY]: "",
    [model.PROPS_TOWING_CAPACITY_UNIT]: "",
    [model.PROPS_TOWING_CAPACITY_UNIT_GUID]: "",
    [model.PROPS_TRANSMISSION_TYPE]: "",
    [model.PROPS_TRANSMISSION_TYPE_GUID]: "",
    [model.PROPS_TUBING_SIZE]: "",
    [model.PROPS_TUBING_SIZE_UNIT_GUID]: "",
    [model.PROPS_USAGE_READING_LIST]: [],
    [model.PROPS_USAGE_READING_LIST]: [],
    [model.PROPS_WEIGHT_UNIT]: "",
    [model.PROPS_WEIGHT_UNIT_GUID]: "",
    [model.PROPS_WELL]: "",
    [model.PROPS_WELL_DEPTH]: "",
    [model.PROPS_WELL_DEPTH_UNIT_GUID]: "",
    [model.PROPS_WELL_OUTPUT]: "",
    [model.PROPS_WELL_OUTPUT_UNIT_GUID]: "",
    [model.PROPS_WELL_TYPE_GUID]: "",
    [model.PROPS_WIDTH]: "",
});
